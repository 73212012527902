import React from 'react'
export const Rebel = () =>
	<svg version="1.1" className="character_svg" id="Rebel" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		width="576px" height="828px" viewBox="0 0 576 828" enableBackground="new 0 0 576 828" space="preserve">
		<g id="eye_backing">
			<path fillRule="evenodd" clipRule="evenodd" fill="#D1D8DC" d="M299.28,268.604c0.08,0,0.16,0,0.24,0
		c3.458-1.774,6.762-3.86,9.6-6.24c0.048-0.039,0.187,0.032,0.24,0c0.038-1.034,0.578-2.051-0.24-2.64
		c-1.699-2.221-4.827-3.012-7.199-4.56c-0.08,0-0.16,0-0.24,0c-2.435-0.286-4.505-0.935-6.96-1.2
		c-3.605-0.085-6.946,0.094-10.08,0.48c-0.08,0-0.16,0-0.24,0c-1.144,0.936-2.342,1.817-3.84,2.4c-0.08,0-0.16,0-0.24,0
		c-0.611,0.749-1.546,1.174-2.4,1.68c-0.08,0-0.16,0-0.24,0c-0.414,0.947-1.747,0.973-2.16,1.92c-0.483,0.796-1.493,1.067-1.92,1.92
		c0,0.08,0,0.16,0,0.24c0.82,0.619,1.541,1.339,2.16,2.16c2.676,2.604,6.9,3.66,10.56,5.28c0.08,0,0.16,0,0.24,0
		c1.948,0.108,3.577-0.104,5.28-0.24c0.964-0.556,2.956-0.083,4.319-0.24C297.05,269.093,297.993,268.677,299.28,268.604z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#D1D8DC" d="M377.521,256.844c-1.401-1.078-3.608-1.351-5.52-1.92
		c-0.647-0.472-2.098-0.143-3.12-0.24c-0.568,0.552-2.154,0.086-3.12,0.24c-5.726,0.674-10.206,2.594-13.68,5.52
		c0.502,2.777,1.552,5.008,2.399,7.44c2.395,0.365,6.879,2.886,9.601,2.16c0.733,0.173,0.874-0.246,1.439-0.24
		c1.442-0.557,3.918-0.082,5.76-0.24c0.08,0,0.16,0,0.24,0c1.84-0.88,2.823-2.618,4.32-3.84c0-0.08,0-0.16,0-0.24
		c0.229-0.89,0.672-1.568,1.2-2.16c0-0.08,0-0.16,0-0.24c0.248-1.032,0.434-2.126,0.72-3.12
		C377.663,258.941,377.993,257.492,377.521,256.844z"/>
		</g>
		<g id="eye1">
			<circle fillRule="evenodd" clipRule="evenodd" cx="291.848" cy="259.707" r="9.271" />
		</g>
		<circle id="eye2" fillRule="evenodd" clipRule="evenodd" cx="363.521" cy="261.687" r="8.292" />
		<g id="main_body">
			<g id="dark_blue">
				<path fillRule="evenodd" clipRule="evenodd" fill="#344A5E" d="M430.081,404.205c-3.504-5.816-15.076-4.949-22.561-6.48
			c-14.648-2.996-26.82-10.118-39.12-15.36c0.226,6.415,1.442,11.837,1.681,18.24c-2.28,1.692-2.656,4.741-4.08,7.2
			c-4.176,7.214-7.959,14.403-12.48,20.88c-4.511,6.462-9.943,12.104-15.6,17.76c-5.515,5.515-11.078,11.117-17.28,16.08
			c-4.674,3.74-10.172,6.982-15.12,10.08c-1.544,0.967-3.376,2.731-5.52,2.4c0.821-6.154,0.847-16.111-0.24-23.521
			c-3.454,1.427-6.268,3.493-10.08,4.561c-1.192,11.048-1.75,22.73-2.16,34.56c-0.503,1.042-1.842,0.046-2.16,0.96
			c30.805-3.386,70.136,1.055,102.24-1.199c1.452,2.354,1.103,5.162,1.44,7.68c1.882,14.022,6.012,24.891,12,34.32
			c2.41,3.795,4.711,7.602,8.16,10.319c-3.48-11.032-5.516-24.399-6.48-38.16c-0.323-4.604-1.354-9.383,0-13.68
			c2.324,4.366,1.949,10.856,2.4,16.8c0.47,6.197,0.652,12.396,2.16,17.28c-0.389-8.845-0.339-19.126,0.96-27.12
			c0.54-3.329,0.626-7.93,3.84-8.399c-0.641,10.788-2.879,25.296-1.92,37.68c0.313,4.04,0.59,8.187,2.64,11.04
			c-1.207-17.871-0.41-35.164,2.4-51.12c0.686-3.896,1.794-7.649,2.159-11.521c0.394-4.167,0.55-8.423,0.96-12.72
			c0.81-8.469,0.594-17.111,1.2-26.16c0.961-14.316,8.193-22.995,15.12-31.68C433.2,404.375,431.54,402.677,430.081,404.205z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#344A5E" d="M160.08,499.965c10.134-0.946,19.897-2.396,30.48-3.36
			c7.33-0.668,14.819-1.671,22.32-2.399c19.558-1.901,40.759,1.423,59.28-0.96c0.357-8.12,1.299-17.331,2.16-25.44
			c0.538-5.07,1.501-10.096-1.92-12.24c-0.034,2.235-0.109,4.952-0.24,7.44c-0.123,2.338,0.26,5.381-1.44,6.479
			c-4.296-2.688-6.81-7.227-9.84-11.04c-3.094-3.892-6.26-7.427-10.08-10.8c-5.616-4.958-11.669-9.966-16.08-15.359
			c-1.628-1.992-2.189-4.401-3.6-6.961c-1.208-2.19-3.139-4.268-3.36-7.199c-0.171-2.266,1.108-5.028,0.48-7.2
			c-0.796-2.752-5.129-3.473-5.76-6.96c-7.101,2.541-15.92,5.258-23.52,7.2c-2.606,0.666-5.513,0.974-7.92,1.92
			c-1.03,0.405-2.137,1.371-3.36,1.92c-4.101,1.841-9.215,3.516-12.48,6c-3.867,2.942-4.708,6.746-6.72,11.76
			c-1.164,2.898-3.072,5.785-3.6,8.16c-0.371,1.67-0.057,3.362-0.48,5.04c-0.483,1.915-1.831,3.663-2.4,5.76
			c-0.424,1.562-0.271,3.229-0.72,4.8c-1.134,3.96-3.771,8.508-4.32,12.721c-0.643,4.938,0.48,10.476,0.48,15.84
			c0,12.042-1.765,22.136-3.12,31.92C156.125,511.299,158.979,505.604,160.08,499.965z"/>
			</g>
			<g id="gray">
				<g>
					<path fillRule="evenodd" clipRule="evenodd" fill="#CFD7DB" d="M275.28,418.364c0-0.079,0-0.159,0-0.239
				c0.041-2.76,0.689-4.911,0.72-7.68c0.132-1.313-0.282-4.436,0.24-4.56c0.355-0.044,0.22-0.58,0.24-0.96
				c-0.099-1.624-0.009-4.323,0.72-4.32c0.395-0.698,0.37-2.082,0.24-2.64c-0.08,0-0.16,0-0.24,0c-4.181-0.14-6.396-2.244-9.12-3.84
				c-0.675-0.045-0.657-0.783-1.2-0.96c0-0.08,0-0.16,0-0.24c-0.08,0-0.16,0-0.24,0c-0.894-0.466-1.603-1.117-2.88-1.2
				c-0.742-0.298-1.288-0.792-1.68-1.44c-1.14-0.94-2.18-1.98-3.12-3.12c-0.071-0.489-0.649-0.471-0.72-0.96c-0.08,0-0.16,0-0.24,0
				c-0.553-0.247-0.774-0.826-1.68-0.72c-0.877-0.082-1.098-0.821-1.44-1.44c-0.051-0.269-0.266-0.374-0.48-0.48
				c-0.627-0.572-1.514-0.886-1.92-1.68c-2.085-2.155-4.471-4.009-6.96-5.76c-0.343-0.057-0.405-0.395-0.72-0.48
				c-0.241,0.001-0.286-0.194-0.48-0.24c-0.977-0.463-1.226-1.655-2.4-1.92c-2.14,1.837,0.452,8.637-1.2,11.521
				c0,0.24,0,0.479,0,0.72c-0.211,2.509-0.211,5.229-1.2,6.96c0,0.08,0,0.16,0,0.24c-0.017,0.943-0.116,1.804-0.48,2.4
				c0,0.08,0,0.16,0,0.24c-0.147,2.413-0.813,4.308-0.96,6.72c-0.503,3.257-0.787,6.733-1.44,9.84c0,0.32,0,0.64,0,0.96
				c0.559,2.162,0.081,5.359,0.24,7.92c0.555,0.806,0.084,2.636,0.24,3.84c0.21,4.03,1.888,6.592,3.36,9.36
				c0.489,0.07,0.471,0.649,0.96,0.72c3.538,3.342,7.345,6.415,10.56,10.08c0.423,0.217,0.743,0.537,0.96,0.96
				c2.179,1.261,3.865,3.015,5.28,5.04c0.249,0.632,0.952,0.809,1.2,1.439c2.83,2.61,5.24,5.641,7.2,9.12
				c-0.175,0.655,0.477,0.483,0.48,0.96c0,0.08,0,0.16,0,0.24c0.868,0.332,0.883,1.518,1.68,1.92
				c0.786-5.295,0.622-12.818,1.44-17.28c0.688-0.032-0.207-1.647,0.48-1.68c0-0.08,0-0.16,0-0.24
				c0.371-10.109,3.032-17.928,4.32-27.12C275.121,418.364,275.201,418.364,275.28,418.364z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#CFD7DB" d="M285.121,433.965c-0.975-1.586-0.262-4.857-0.48-7.2
				c-0.134-0.586-0.929-0.512-0.72-1.44c-0.372-1.308-0.45-2.909-0.48-4.56c0-0.08,0-0.16,0-0.24
				c-0.954-3.846-1.249-8.351-2.64-11.76c0-0.08,0-0.16,0-0.24c-1.248-0.352-0.514-2.686-1.68-3.12c0,0.32,0,0.64,0,0.96
				c0,0.08,0,0.16,0,0.24c-0.15,1.291,0.39,3.27-0.48,3.84c0.004,1.044,0.079,2.159-0.24,2.88c-0.055,1.385-0.229,2.651-0.48,3.84
				c0.139,0.778-0.164,1.116-0.24,1.68c-0.334,1.906-0.245,4.235-1.2,5.52c0,0.08,0,0.16,0,0.24c-0.39,4.73-1.519,8.722-2.4,12.96
				c0,0.08,0,0.16,0,0.24c-0.3,3.859-0.681,7.639-1.2,11.28c0.244,3.437,1.985,5.375,4.32,6.72c1.333-0.268,2.768-0.432,4.32-0.479
				c0.08,0,0.16,0,0.24,0c0.329-0.471,1.47-0.13,1.92-0.48c0.577-1.503,0.97-3.19,1.68-4.56c0-0.08,0-0.16,0-0.24
				c-0.03-1.47,0.48-2.399,0.72-3.601C286.286,442.657,285.119,438.331,285.121,433.965z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#CFD7DB" d="M292.08,420.045c-0.33-0.31-0.237-1.043-0.239-1.681
				c-0.709-2.331-1.18-4.9-1.2-7.919c-1.94,1.9-1.463,6.217-3.36,8.16c0,0.08,0,0.16,0,0.24c-1.313,3.229-0.743,6.663,0,10.08
				c0.525,0.201,0.106,3.41,0.24,4.8c0,0.24,0,0.48,0,0.72c0.549,0.411,0.088,1.832,0.24,2.641c0.372,1.309,0.45,2.909,0.48,4.56
				c0.552,0.568,0.086,2.154,0.24,3.12c0,0.08,0,0.16,0,0.24c0.528,1.096,0.211,3.406,0,4.56c-0.655,0.175-0.483-0.477-0.96-0.479
				c-0.617,0.903-0.284,2.756-1.2,3.359c0,0.08,0,0.16,0,0.24c0.118,0.758-0.432,0.848-0.24,1.681c1.621,0.341,1.81-0.75,2.64-1.2
				c0.664-0.718,1.334,0.73,2.16,0c0.446-0.033,0.802-0.158,0.96-0.48c1.577-9.224,0.198-20.113,0.239-30
				C292.08,421.805,292.08,420.925,292.08,420.045z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#CFD7DB" d="M367.201,397.005c-1.056-0.545-0.632-2.569-0.721-4.08
				c-0.2-2.68-0.433-5.328-0.72-7.92c-0.156-1.284,0.315-3.195-0.24-4.08c-0.335-2.226-0.069-5.05-0.72-6.96c0-0.4,0-0.8,0-1.2
				c-0.834,0.051-1.955,1.882-2.64,2.88c-1.191,2.169-1.228,5.493-1.921,8.16c-1.441,2.018-3.141-0.692-3.84-1.68
				c-0.581,0.459-1.017,1.063-1.2,1.92c0,0.08,0,0.16,0,0.24c0.243,1.362-0.444,1.795-0.96,2.4c-0.33-0.01-0.526,0.113-0.72,0.24
				c-0.217,0.423-0.537,0.743-0.96,0.96c-2.368,1.071-4.224,2.656-7.439,2.88c-0.08,0-0.16,0-0.24,0
				c-0.411,0.549-1.832,0.088-2.641,0.24c-0.729,0.15-2.066-0.308-2.399,0.24c1.275,0.346-1.556,0.227-2.16,0.24
				c-1.92,0-3.84,0-5.76,0c-0.647,0.553-2.315,0.085-3.36,0.24c-0.88,0-1.76,0-2.64,0c-0.16,0-0.32,0-0.48,0
				c-0.492,0.398-2.628,0.398-3.12,0c-0.186-0.054-0.473-0.006-0.479-0.24c-0.204,0.044-0.227-0.094-0.24-0.24
				c-0.08,0-0.16,0-0.24,0c-3.263,0.063-5.65-0.75-8.16-1.44c-2.105,1.174-4.496,2.063-6.479,3.36
				c-1.644,0.677-3.025,1.615-5.04,1.92c-0.08,0-0.16,0-0.24,0c-1.33,0.91-2.944,1.536-4.8,1.92c-1.814,0.755,0.936,3.584,0.479,6
				c0.542,0.179,0.095,1.346,0.24,1.92c0.487,1.593,0.506,3.654,0.48,5.76c0,0.08,0,0.16,0,0.24c0.547,0.333,0.089,1.67,0.239,2.4
				c0,0.08,0,0.16,0,0.24c0.548,0.333,0.09,1.671,0.24,2.4c0,0.08,0,0.16,0,0.24c0.548,0.332,0.09,1.67,0.24,2.399
				c0,0.08,0,0.16,0,0.24c0.547,0.333,0.09,1.671,0.24,2.4c0,0.159,0,0.319,0,0.479c0.547,0.333,0.089,1.671,0.24,2.4
				c0,0.08,0,0.16,0,0.239c0.549,0.411,0.088,1.832,0.239,2.641c0,0.08,0,0.16,0,0.24c0.521-0.041,0.109,0.851,0.24,1.199
				c0.551,0.489,0.087,1.993,0.24,2.88c1.073,7.247,2.034,14.605,2.88,22.08c0.541,0.179,0.094,1.347,0.24,1.921
				c0.553,0.646,0.085,2.314,0.239,3.359c0,0.16,0,0.32,0,0.48c0.355,0.044,0.221,0.58,0.24,0.96c0.756,2.201,0.807,7.407,0,9.6
				c0,0.4,0,0.8,0,1.2c1.48-0.439,2.357-1.482,3.601-2.16c1.471-0.929,2.71-2.09,4.319-2.88c3.37-3.03,7.426-5.375,10.561-8.64
				c1.741-1.779,3.73-3.31,5.52-5.04c1.42-1.62,2.94-3.141,4.561-4.561c1.739-1.939,3.58-3.78,5.52-5.52
				c1.42-1.62,2.94-3.141,4.561-4.561c0.971-1.028,1.812-2.188,2.88-3.12c0.085-0.314,0.422-0.377,0.479-0.72
				c0.81-0.631,1.477-1.404,1.92-2.4c0.943-1.137,1.793-2.367,2.641-3.6c1.176-1.224,2.137-2.663,2.88-4.32
				c2.071-3.128,3.933-6.467,6-9.6c0.368-0.912,0.967-1.593,1.2-2.64c2.842-3.877,4.342-9.097,7.439-12.72
				C367.446,397.879,367.027,397.738,367.201,397.005z"/>
				</g>
			</g>
			<g id="gray_1_">
				<path fillRule="evenodd" clipRule="evenodd" fill="#A4B1BD" d="M284.4,468.765c-0.127,0.193-0.25,0.39-0.24,0.72
			c0.559,2.802,0.081,6.64,0.24,9.841c0.141,2.019-0.294,4.613,0.24,6.239c0.08,0,0.16,0,0.24,0
			c0.558-6.002,0.408-12.711,1.2-18.479c0-0.48,0-0.96,0-1.44C285.18,466.345,285.068,467.832,284.4,468.765z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#A4B1BD" d="M282,478.845c0-1.2,0-2.399,0-3.6
			c-0.554-0.727-0.084-2.476-0.24-3.601c-0.16,0-0.32,0-0.48,0c-0.512-0.111-0.457,0.343-0.48,0.721c0,0.08,0,0.159,0,0.239
			c0.537,0.104,0.097,1.183,0.24,1.681c0.558,1.602,0.082,4.238,0.24,6.239c0.125,2.205-0.083,4.077-1.44,4.801
			c-0.34-0.021-0.679-0.04-0.72,0.239c0.61,0.318-2.237,0.455-2.4,0c-0.194-0.045-0.239-0.24-0.48-0.239
			c-0.622,0.978-0.705,2.495-0.72,4.08c2.454,0.053,3.94-0.859,6.24-0.96C282.309,485.713,281.849,481.974,282,478.845z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#A4B1BD" d="M278.161,474.285c0.08,0,0.16,0,0.24,0c0-0.08,0-0.16,0-0.24
			c-0.188-0.988,0.369-1.231,0.24-2.16c-0.331-0.011-0.527,0.113-0.72,0.24c0,0.08,0,0.16,0,0.24
			C278.066,472.938,277.619,474.106,278.161,474.285z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#A4B1BD" d="M277.44,484.125c0.48,0,0.96,0,1.44,0c0.08,0,0.16,0,0.24,0
			c0.661-0.779-0.195-3.075,0.48-3.84c0-1.84,0-3.681,0-5.521c-0.187,0.053-0.279,0.201-0.24,0.48c-0.08,0-0.16,0-0.24,0
			c0.039,0.278-0.053,0.427-0.24,0.479c-0.569,2.552-1.165,5.075-1.68,7.681C277.48,483.445,277.461,483.784,277.44,484.125z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#A4B1BD" d="M179.041,414.765c-0.08,0-0.16,0-0.24,0
			c-15.131,6.389-30.912,12.128-46.8,17.76c-0.307,0.014-0.332,0.308-0.72,0.24c-0.003,0.317-0.421,0.22-0.48,0.48
			c-1.766,0.956-3.576,3.942-6.48,4.56c-0.27,0.051-0.374,0.267-0.48,0.48c0,0.08,0,0.159,0,0.239c-0.7,0.9-1.5,1.701-2.4,2.4
			c-0.343,0.058-0.405,0.396-0.72,0.479c-0.08,0-0.16,0-0.24,0c-0.939,1.141-1.979,2.181-3.12,3.12
			c-0.166,1.034-0.926,1.475-1.2,2.4c-0.523,0.757-0.653,1.907-1.44,2.4c-6.11,14.53-12.134,29.146-15.12,46.8
			c0.428,0.771,0.223,2.177,0.48,3.12c0.54,0.74,0.458,2.102,0.72,3.12c7.628-0.612,14.181-2.3,22.32-2.4c0.08,0,0.16,0,0.24,0
			c0-0.08,0-0.16,0-0.24c-1.137-0.399,0.925-0.275,0.48-0.24c5.247-0.425,11.66-0.658,16.32-0.239c0,0.08,0,0.16,0,0.239
			c2.321-0.08,4.063,0.417,6.24,0.48c0,0.08,0,0.16,0,0.24c0.08,0,0.16,0,0.24,0c0.32,0,0.64,0,0.96,0
			c1.124-0.557,3.277-0.083,4.8-0.24c1.401-4.918,0.597-12.043,1.2-17.76c0-0.24,0-0.48,0-0.721c-0.371-0.349-0.211-1.229-0.24-1.92
			c-0.08,0-0.16,0-0.24,0c-0.418,0.874-0.222-1.404-0.24-2.399c-0.025-1.409-0.16-3.034,0.24-3.601
			c0.156-1.124-0.313-2.873,0.24-3.6c0-0.08,0-0.16,0-0.24c-0.031-1.791,0.246-3.273,0.96-4.32c0.297-0.662,0.701-1.219,0.72-2.159
			c0.363-0.918,0.412-2.148,0.96-2.88c0-0.08,0-0.16,0-0.24c0.423-1.737,0.857-3.463,1.68-4.8c0.187-0.534,0.504-0.937,0.48-1.681
			c0.625-1.774,0.996-3.804,1.92-5.279c0.296-0.984,0.887-1.674,0.96-2.881c0.655-2.064,1.293-4.147,2.4-5.76
			c0.138-0.502,0.595-0.685,0.48-1.439c0.52-1.32,0.784-2.896,1.68-3.841c0.51-1.25,1.162-2.357,1.44-3.84
			c0.46-1.54,1.13-2.87,1.68-4.319c0.034-0.927,0.811-1.109,0.72-2.16c-0.632-1.438,1.447-2.229,2.16-3.12c0.08,0,0.16,0,0.24,0
			s0.16,0,0.24,0c0.867-1.134,2.112-1.888,3.12-2.88c0.16,0,0.32,0,0.48,0c0.08,0,0.16,0,0.24,0c0.385-0.734,1.057-1.184,1.92-1.44
			c0.08,0,0.16,0,0.24,0c0.597-0.923,1.946-1.095,2.88-1.68c0-0.16,0-0.32,0-0.48C180.611,414.896,179.731,414.736,179.041,414.765z
			"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#A4B1BD" d="M285.36,456.765c-0.637,0.003-1.371-0.091-1.68,0.24
			c-1.617,0.863-4.327,0.633-6.24,1.2c0.459,3.055-0.127,6.947,0.96,10.319c0.202-0.762,0.317,0.427,0.24,0.721
			c0.64,0,1.28,0,1.92,0c1.13,0.01,1.371-0.869,1.92-1.44c0.903-2.457,2.115-4.605,2.88-7.2
			C285.191,459.337,288.097,456.861,285.36,456.765z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#A4B1BD" d="M274.8,491.564c0.044,0.204-0.093,0.227-0.24,0.24
			c0,0.08,0,0.16,0,0.24c0,0.4,0,0.8,0,1.2c0.701,1.665-0.337,3.659-0.48,5.279c0.097,1.218-0.792,1.448-1.44,1.921
			c0,0.08,0,0.159,0,0.239c0,0.32,0,0.641,0,0.96c0.505,0.678,0.297,2.688-0.24,3.12c-0.146,0.574,0.301,1.741-0.24,1.92
			c0,0.08,0,0.16,0,0.24c0,0.24,0,0.48,0,0.72c0.505,0.678,0.297,2.688-0.24,3.12c-0.471,10.249-2.041,19.399-2.64,29.521
			c-0.08,0-0.16,0-0.24,0c0,0.08,0,0.16,0,0.24c0,0.319,0,0.64,0,0.96c0.267,3.627-0.271,8.528-0.48,12.479
			c-0.153,0.887,0.311,2.391-0.24,2.88c0,0.24,0,0.48,0,0.721c0.542,0.179,0.094,1.346,0.24,1.92c0.559,2.4,0.081,5.839,0.24,8.64
			c0,0.08,0,0.16,0,0.24c0.08,0,0.16,0,0.24,0c0.469-0.578,0.184,1.9,0.24,2.88c0.087,1.519,0.437,3.189,0.24,4.32
			c0.98,3.94-0.549,7.45-1.2,10.56c-0.08,0-0.16,0-0.24,0c-3.397,8.822-2.973,18.943-4.8,28.8c-1.984,10.704-5.67,21.214-5.76,33.12
			c-0.08,0-0.16,0-0.24,0c0,0.08,0,0.16,0,0.24c0,0.32,0,0.64,0,0.96c0.32,2.881,0,7.198-0.72,9.84
			c-0.481,11.199-3.182,20.179-4.08,30.96c-0.08,0-0.16,0-0.24,0c0,0.08,0,0.16,0,0.24c0,0.32,0,0.641,0,0.96
			c0.399,0.808,0.399,3.272,0,4.08c0,0.56,0,1.12,0,1.68c-0.159,2.562,0.319,5.759-0.24,7.921c0,0.479,0,0.959,0,1.439
			c-0.158,1.762,0.317,4.157-0.24,5.521c0,0.479,0,0.96,0,1.439c-0.158,1.683,0.317,3.997-0.24,5.28c0,0.56,0,1.12,0,1.68
			c-0.063,5.857-0.301,11.54-0.72,17.04c0,0.48,0,0.96,0,1.44c-0.084,5.676,0.032,11.552-0.96,16.319
			c-0.581,11.659-2.662,21.818-3.36,33.36c-0.08,0-0.16,0-0.24,0c0,0.08,0,0.16,0,0.24c0,0.4,0,0.8,0,1.2
			c0.419,2.66-0.012,6.546-0.24,9.359c0,0.48,0,0.96,0,1.44c0,1.6,0,3.2,0,4.8c1.84,0,3.68,0,5.52,0c0-0.08,0-0.16,0-0.24
			c0-0.8,0-1.6,0-2.399c0.432-5.328-0.048-11.567,0.48-16.801c0-0.399,0-0.8,0-1.199c-0.187-1.232,0.135-3.067,0.24-4.561
			c0.058-0.822-0.224-3.101,0.24-2.399c0.08,0,0.16,0,0.24,0c0.334-1.426,0.453-3.066,0.48-4.801c0.01-1.43,0.104-2.775,0.48-3.84
			c0.344-1.415,0.498-3.021,0.48-4.8c-0.176-2.097,0.391-3.448,0.24-5.52c0.089-4.391,0.347-8.613,0.72-12.721c0-2.96,0-5.92,0-8.88
			c0.16-5.04-0.32-10.72,0.24-15.36c0-0.64,0-1.279,0-1.92c0.095-1.16-0.229-5.112,0.24-4.319c0.08,0,0.16,0,0.24,0
			c0-0.08,0-0.16,0-0.24c0.153-0.887-0.311-2.391,0.24-2.88c0.333-1.827,0.62-3.701,0.72-5.76c0.085-1.355,0.295-2.586,0.48-3.841
			c0.08,0,0.16,0,0.24,0c0.331-0.309,0.237-1.042,0.24-1.68c0.048-1.792,0.491-3.189,0.72-4.8c0.08,0,0.16,0,0.24,0
			c0-0.08,0-0.16,0-0.24c0.196-0.764,0.153-1.767,0.48-2.4c0.08,0,0.16,0,0.24,0c0.62-1.699,1.228-3.412,1.44-5.52
			c0-2.72,0-5.44,0-8.16c0.13-1.489-0.486-2.233-0.48-3.6c-0.549-0.411-0.088-1.832-0.24-2.641c0.013-0.604-0.106-3.435,0.24-2.159
			c0.08,0,0.16,0,0.24,0c0.335-1.105,0.367-2.514,0.48-3.841c0.403-3.036,1.254-5.625,1.92-8.399
			c0.438-1.643,0.674-3.485,0.72-5.521c-0.01-1.37,0.092-2.628,0.48-3.6c0.402-0.398,0.191-1.409,0.24-2.16
			c0.067-1.532,0.253-2.946,0.72-4.08c0.464-2.016,0.86-4.1,0.96-6.479c0.01-1.431,0.104-2.776,0.48-3.841c0-0.079,0-0.16,0-0.239
			c0.159-2.642-0.319-5.919,0.24-8.16c0-0.561,0-1.12,0-1.681c0.018-0.86-0.113-4.899,0.24-3.119c0.08,0,0.16,0,0.24,0
			c0-0.08,0-0.16,0-0.24c0.152-0.809-0.309-2.229,0.24-2.641c0-0.079,0-0.16,0-0.239c-0.026-1.306,0.07-2.49,0.48-3.36
			c0-0.08,0-0.16,0-0.24c-0.029-1.148,0.068-2.172,0.48-2.88c0-0.08,0-0.16,0-0.24c0.156-1.684,0.405-3.274,0.96-4.56
			c0.201-0.278,0.316-0.644,0.24-1.2c-0.042-1.562,0.499-2.54,0.96-3.6c0.887-3.433,2.209-6.431,2.88-10.08
			c-0.097-1.137,0.428-1.651,0.24-2.88c0-0.881,0-1.761,0-2.641c0.443-6.597-0.054-14.134,0.48-20.64c0-0.96,0-1.92,0-2.88
			c0-2.16,0-4.32,0-6.48c0.447-1.553-0.013-4.013,0.48-5.52c0-0.8,0-1.601,0-2.4c-0.142-0.685,0.173-2.591,0.24-0.96
			c0.08,0,0.16,0,0.24,0c0-0.16,0-0.32,0-0.479c0.152-0.809-0.309-2.229,0.24-2.641c0-0.08,0-0.16,0-0.24
			c0.024-1.655,0.246-3.114,0.72-4.319c0-0.08,0-0.16,0-0.24c0.079-1.36,0.301-2.579,0.72-3.6c0-0.08,0-0.16,0-0.24
			c0.017-0.943,0.116-1.804,0.48-2.4c0.276-0.283,0.271-0.849,0.24-1.439c0.017-0.943,0.116-1.805,0.48-2.4c0-0.08,0-0.16,0-0.24
			c0.479-3.601,1.184-6.976,1.92-10.319c0-0.08,0-0.16,0-0.24c0.039-1.321,0.112-2.607,0.48-3.601c0-0.08,0-0.159,0-0.239
			c0.01-1.431,0.104-2.776,0.48-3.841c0-0.079,0-0.159,0-0.239c-0.012-1.212,0.094-2.307,0.48-3.12
			c0.331-0.31,0.237-1.043,0.24-1.681c0.014-1.105,0.111-2.129,0.48-2.88c0-0.08,0-0.159,0-0.239c0.014-1.106,0.111-2.13,0.48-2.881
			c0.371-0.349,0.211-1.229,0.24-1.92c0.034-1.485,0.11-2.93,0.48-4.08c0-0.079,0-0.159,0-0.239c0-1.12,0-2.24,0-3.36
			c-1.843,0.637-3.694,1.266-6,1.44C277.03,491.874,275.608,491.413,274.8,491.564z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#A4B1BD" d="M503.521,474.765c-0.237-1.122-0.722-1.998-0.96-3.12
			c-0.589-1.971-1.229-3.89-1.92-5.76c-0.266-1.014-0.764-1.796-0.96-2.88c-1.046-3.594-2.396-6.884-3.84-10.08
			c-0.124-1.155-0.677-1.883-1.2-2.64c-0.397-0.803-0.802-1.599-1.199-2.4c-0.719-2.001-1.788-3.652-2.641-5.52
			c-1.847-2.873-3.389-6.051-5.28-8.881c0.031-0.43-0.293-0.506-0.239-0.96c-0.302-0.098-0.491-0.31-0.48-0.72
			c-0.346-0.135-0.586-0.374-0.72-0.72c-0.557,0.076-0.922-0.039-1.2-0.24c-0.55-1.05-0.664-2.535-1.68-3.12
			c-0.279-0.039-0.428,0.053-0.48,0.24c-1.268-0.732-2.361-1.639-3.359-2.641c-0.652-0.147-1.124-0.476-1.44-0.96
			c-0.801,0.001-0.819-0.78-1.68-0.72c-0.315-0.085-0.378-0.422-0.721-0.479c-1.396-1.163-3.224-1.896-4.56-3.12
			c-0.08,0-0.16,0-0.24,0c-2.375-1.145-4.933-2.107-6.96-3.601c-0.33,0.011-0.526-0.112-0.72-0.239
			c-1.048,0.167-1.067-0.693-1.92-0.721c0-0.08,0-0.159,0-0.239c-0.676-0.205-0.985-0.776-1.92-0.72
			c-1.032-0.248-2.007-0.554-2.641-1.2c-2.446-0.994-4.764-2.116-7.439-2.88c-1.775-0.625-3.805-0.996-5.28-1.92
			c-0.08,0-0.16,0-0.24,0c-1.652-0.187-2.524-1.156-3.84-1.68c-4.952,3.368-8.125,8.516-11.04,13.92
			c-0.511,0.85-0.795,1.925-1.439,2.64c-1.694,3.667-2.4,8.32-3.36,12.721c0,0.08,0,0.16,0,0.239c0,0.4,0,0.801,0,1.2
			c0.399,2.083,0.399,5.838,0,7.92c0,0.641,0,1.28,0,1.92c-0.162,4.398,0.05,9.17-0.96,12.721c0.121,2.36-0.489,3.99-0.48,6.24
			c-0.08,0-0.159,0-0.239,0c0,0.08,0,0.159,0,0.239c0,0.24,0,0.48,0,0.721c0.405,1.929,0.111,5.229-0.48,6.96
			c-0.126,6.674-2.199,11.4-2.88,17.52c-0.08,0-0.16,0-0.24,0c-0.276,0.284-0.271,0.849-0.24,1.44c0.092,0.042,0.399-0.32,0.24-0.48
			c2.086,2.098,12.054-0.17,17.04,0.72c7.296-1.104,14.651-2.148,22.8-2.399c0.08,0,0.16,0,0.24,0c0-0.08,0-0.16,0-0.24
			c-1.223-0.208,0.081-0.226,0.24-0.24c4.576-0.408,11.644-0.181,17.04-0.239c1.36,0,2.72,0,4.08,0
			c0.885-0.556,2.796-0.084,4.08-0.24c3.58-0.899,7.11-1.85,10.8-2.641c2.051-0.349,3.986-0.813,6.48-0.72
			c0.649-0.398,2.95-0.398,3.6,0c1.674-0.154,2.374,0.666,3.84,0.72C506.081,482.925,504.683,478.963,503.521,474.765z"/>
			</g>
			<g id="orange">
				<path fillRule="evenodd" clipRule="evenodd" fill="#B2734F" d="M222.24,222.524c-0.371-0.349-0.504-0.936-0.48-1.68
			c-0.792-0.248,0.444-2.524-0.72-2.4c-0.7,1.54-1.699,2.781-2.64,4.08c0.551,4.733-2.057,9.971-2.16,15.6
			c0.041,0.622-0.207,1.909,0.48,1.2c2.336-4.224,4.167-8.953,4.08-15.6C220.982,223.026,221.986,223.15,222.24,222.524z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#B2734F" d="M221.041,211.964c-0.788,1.452-1.351,3.129-2.16,4.56
			c0.342-0.058,0.405-0.396,0.72-0.48C219.827,214.483,222.317,213.548,221.041,211.964z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#B2734F" d="M384.001,159.164c-1.517-1.204-2.946-2.494-5.04-3.12
			c-7.365-2.315-16.895-2.465-21.36-7.68c-0.08,0-0.16,0-0.24,0s-0.159,0-0.239,0c-1.54-1.901-3.501-3.379-5.04-5.28
			c-1.836-1.604-3.771-3.109-6.48-3.84c-0.783-0.336-1.629-0.611-2.16-1.2c-0.438-0.841-1.556-1.004-1.92-1.92c0-0.24,0-0.48,0-0.72
			c0.854-0.747,2.73-0.47,3.84-0.96c1.424-0.097,2.348-0.693,3.12-1.44c0.315-0.084,0.378-0.422,0.72-0.48
			c2.129-0.992,3.912-2.328,4.32-5.04c0.398-0.57,0.398-2.789,0-3.36c0-0.08,0-0.16,0-0.24c-0.048-1.232-0.634-1.926-0.72-3.12
			c-1.369,1.431-2.404,3.196-3.84,4.56c-0.78,0.98-1.66,1.86-2.641,2.64c-2.434,2.287-5.354,4.086-10.319,3.84
			c0.779,1.22,3.276,0.723,3.12,2.88c-0.214,0.106-0.43,0.211-0.48,0.48c-0.08,0-0.16,0-0.24,0c-2.978,0.338-4.385-0.895-6.479-1.44
			c-0.723-0.558-1.709-0.851-2.641-1.2c-2.979-1.5-6.309-2.651-9.359-4.08c-1.229-0.531-2.237-1.284-3.601-1.68
			c-0.933-0.587-1.846-1.194-2.88-1.68c-0.854-0.825-1.976-1.384-3.36-1.68c-0.194-0.046-0.238-0.241-0.479-0.24
			c-0.805-0.476-1.771-0.789-2.88-0.96c-1.478-0.603-3.192-0.968-5.04-1.2c-0.08,0-0.16,0-0.24,0
			c-1.366,0.167-1.993-0.406-3.36-0.24c-1.119,0-2.239,0-3.359,0c-7.863,0.537-12.672,4.128-21.12,4.08
			c-0.571,0.398-2.79,0.398-3.36,0c-1.044,0.245-1.104-0.497-1.68-0.72c-5.415,1.669-11.373,1.649-16.56,3.12
			c-5.172,1.467-9.119,4.217-12.72,6.96c-2.451,5.31-6.23,9.29-7.44,15.84c0.438,0.5,0.5-1.407,1.2-1.44
			c1.119-2.993,4.015-2.724,5.76-5.04c0.503-0.557,1.153-1.909,2.16-1.2c0.066,1.026-0.697,1.223-1.2,1.68
			c-2.475,4.005-5.875,7.085-7.92,11.52c0,0.08,0,0.16,0,0.24c1.213-0.733,0.627,1.072,0,1.2c-1.891,2.989-3.951,5.809-5.52,9.12
			c2.708-1.692,5.768-5.112,9.84-6.24c-2.434,4.047-6.687,6.273-9.84,9.6c-1.761,6.798-6.144,10.976-7.2,18.48
			c2.667-2.98,1.835-10.581,7.2-10.08c3.187-7.214,10.438-10.363,17.76-13.44c-0.95,1.85-3.791,1.81-5.52,2.88
			c-0.94,1.14-1.98,2.18-3.12,3.12c-5.606,7.994-11.752,15.448-15.6,25.2c0.297,0.903,1.163,1.238,1.44,2.16
			c1.538-0.409,1.34-1.913,2.88-2.16c1.022-0.164,1.582,0.844,2.64,0.72c1.629-0.19,0.869-1.27,2.64-1.92
			c1.069,5.348-3,9.611-3.6,14.88c0.028,1.252,2.176,0.384,1.92,1.92c2.182-3.658,3.301-8.379,5.04-12.48
			c2.888,5.28-2.772,9.699-3.12,14.88c0,1.6,0,3.2,0,4.8c0.295,2.185,0.213,4.747,0.96,6.48c0.424-7.016,5.806-9.074,8.4-13.92
			c2.35,0.561,0.921,3.167,0.48,3.84c-1.761,2.681-6.587,4.389-6.48,9.36c-0.151,0.729,0.307,2.067-0.24,2.4
			c0.121,9.317-5.556,0.704-4.8-4.08c-0.668,0.108,0.188-1.308-0.48-1.2c-0.054,0.187-0.007,0.474-0.24,0.48
			c-0.357,10.74-9.511,15.837-5.28,28.56c0.652-0.171-0.172,1.132,0.48,0.96c1.138-2.862,1.157-6.843,4.32-7.68
			c0.937,3.22-1.78,6.61-2.16,10.08c1.556,1.643,4.048,2.351,5.04,4.56c0.882,0.478,1.341,1.379,1.92,2.16
			c-0.282-1.865-2.709-3.918-1.44-5.76c2.378,0.797,2.587,3.75,3.36,5.76c2.631,6.843,3.601,14.561,4.8,22.8
			c1.552,10.664,6.506,19.779,6.24,30.72c-0.056,2.304-0.857,4.623-1.2,6.96c-0.35,2.382,0.08,5.083-1.44,6.96
			c-0.876,0.156-1.278-0.162-1.68-0.48c0.04,1.221-0.063,1.324-0.48,0.48c1.258,4.446,1.608,8.388,3.36,11.76
			c1.894,3.645,6.033,5.556,7.2,9.6c0.241-0.001,0.286,0.194,0.48,0.24c0-0.64,0-1.28,0-1.92c0.085-1.544-0.814-2.299,0-3.36
			c2.198-0.118,2.288,1.873,3.12,3.12c1.54,1.34,2.98,2.78,4.32,4.32c1.907,1.293,3.866,2.535,5.76,3.84
			c0.515,3.245,3.509,4.011,4.8,6.48c4.101-0.667,5.717,4.283,9.36,4.08c10.667,11.413,25.423,18.737,43.2,23.04
			c1.44,0,2.88,0,4.32,0c0.8,0,1.6,0,2.4,0c1.044-0.556,3.116-0.083,4.56-0.24c5.306-0.321,9.538,0.36,13.68-0.72
			c1.429-0.373,4.242-1.372,5.28-2.4c2.177-2.156,3.232-10.248,6.72-5.52c0.664-2.617,1.989-4.571,2.641-7.2
			c4.287-4.672,7.167-10.752,11.52-15.36c0.057-1.176-0.423-1.817-0.24-3.12c0.537-0.103,0.098-1.183,0.24-1.68
			c0.52-0.841,0.719-2.002,0.96-3.12c1.426-3.615,2.784-7.296,3.36-11.76c0-0.88,0-1.76,0-2.64c-0.319-0.036-0.725,0.976-0.96,0.24
			c-1.299,0.338-1.109-0.811-1.68-1.2c-0.33,2.07-0.44,4.36-0.96,6.24c-2.584,5.497-5.196,10.965-5.761,18.48
			c-0.349,1.091-0.844,2.036-1.2,3.12c-0.378,0.582-0.858,1.062-1.439,1.44c0.175-0.655-0.477-0.483-0.48-0.96
			c-1.006-1.794-2.763-2.836-3.359-5.04c-0.582,0.219-0.636,0.964-0.721,1.68c-1.24,1.879-0.74,5.499-2.88,6.48
			c-1.386-0.214-1.533-1.667-2.399-2.4c-1.061,1.58-1.767,3.514-3.36,4.56c-1.358-0.162-1.694-1.346-2.16-2.4
			c-0.08-0.56-0.246-1.034-0.72-1.2c-0.233-0.007-0.187-0.294-0.24-0.48c-0.261-0.06-0.162-0.478-0.479-0.48
			c-0.267-1.014-0.765-1.796-0.96-2.88c-0.439-0.521,0.045-1.965-0.48-2.4c0-0.64,0-1.28,0-1.92c-0.938,0.342-1.577,0.982-1.92,1.92
			c-0.582,0.378-1.025,0.895-2.16,0.72c-0.306-0.575-0.876-0.884-0.72-1.92c-0.651,0.171,0.172-1.132-0.48-0.96
			c-0.699,0.901-1.499,1.701-2.399,2.4c-0.895,1.399-1.276,3.604-2.88,3.36c-1.692-0.257-1.703-3.638-2.4-5.52
			c-0.989,0.051-0.586,1.494-1.44,1.68c-0.465,1.134-0.748,2.451-1.92,2.88c-0.852,0.826-1.295-1.319-1.92-0.72
			c-0.439,2.68-1.075,5.165-2.399,6.96c-2.587,0.753-6.068,4.151-8.88,1.68c-0.527-1.707,1.901-2.815,0.96-3.36
			c-3.912,0.291-8.404,2.417-12.48,0.96c-0.989-3.012,2.942-4.401,2.64-6.72c-1.119,0.56-2.169,1.19-3.84,1.2
			c-0.492,0.398-2.628,0.398-3.12,0c-3.53,0.251-2.942-3.617-5.52-4.32c-4.318,2.882-9.63,4.77-13.68,7.92
			c-4.485-4.544-0.378-12.233,0.24-18c-1.611,0.389-2.008,1.992-3.84,2.16c-3.839-9.23,8.461-12.397,9.12-20.16
			c-1.84,2.194-5.397,1.771-8.4,2.88c-1.931,0.713-3.202,1.863-5.04,2.16c-1.14-0.918-0.289-1.832,0.24-2.88
			c-0.751-1.245-0.322-2.906,0.24-4.08c0.36-1,0.681-2.039,1.44-2.64c0.687-2.354,2.718-4.64,2.88-6.72
			c-1.645,0.866-3.868,1.88-6,2.64c-1.987,0.708-4.565,2.328-6.24,0.96c1.158-2.841,3.051-4.949,3.36-8.64
			c0.155-1.045-0.313-2.713,0.24-3.36c-0.148-0.651,0.305-1.905-0.24-2.16c-1.04,1.399-1.651,3.229-2.4,5.04
			c-0.726,1.756-1.035,4.099-2.64,5.04c-0.956-0.404-1.426-1.294-1.44-2.64c-0.398-0.571-0.398-2.789,0-3.36
			c-0.053-1.573,0.233-2.806,0.48-4.08c-0.15-0.729,0.307-2.067-0.24-2.4c-1.262,2.018-0.479,12.028-3.36,8.16
			c-0.397-0.259-0.397-2.142,0-2.4c0-0.16,0-0.32,0-0.48c0-0.88,0-1.76,0-2.64c-2.097-3.996-4.331-8.688-7.2-13.2
			c-4.492-7.064-10.644-14.242-8.88-26.16c0.151-0.729-0.307-2.067,0.24-2.4c0.134-4.186,0.861-7.778,1.44-11.52
			c-0.27-0.556-0.93,0.339-1.2-0.48c1.476-10.256,3.239-18.164,7.44-24.96c2.374-3.839,6.326-7.119,6.48-12.96
			c0.037-0.843,1.468-0.292,1.44-1.2c-2.77-11.55-2.527-26.113-7.2-35.76c-0.33-0.759-0.052-0.802,0-1.68
			c-1.286-0.634-2.798-1.042-3.36-2.4c-0.481-0.399-1.116-0.645-1.2-1.44c0.146,0.415,1.139-0.018,1.2,0.48
			c4.1,0.758,6.509,2.563,10.32,4.56c9.093,4.767,19.96,9.562,21.6,20.4c0.397,0.336,0.397,2.304,0,2.64
			c0.197,4.037-3.493,4.187-5.52,6c-0.601,0.759-1.64,1.08-2.64,1.44c-1.847,1.193-4.073,2.007-4.56,4.56
			c2.028-1.012,4.029-2.051,6.96-2.16c0.713-0.346,0.922-2.14,1.92-1.44c1.316,1.072-0.006,2.615,0,4.08
			c0.018,4.524,3.51,8.464,8.16,7.92c-4.278-2.108-2.545-10.088,2.16-10.56c0.396,2.644,1.552,4.527,3.84,5.28
			c-0.935-1.245-3.296-5.904-0.24-6.72c0.955,0.681-0.2,2.395,0.479,3.6c0.454,0.027,0.167,0.793,0.721,0.72
			c0.037-0.282-0.041-0.681,0.24-0.72c-0.104-0.983,0.222-1.538,0.479-2.16c-0.136-1.195,1.687-1.376,0.96-2.4c0-0.24,0-0.48,0-0.72
			c0.699-0.9,1.5-1.701,2.4-2.4c1.222-2.298,2.185-4.855,2.64-7.92c0-0.8,0-1.6,0-2.4c-0.18-3.662-5.69-3.268-6-6
			c1.39-1.247,4.65,0.984,6.24,2.16c3.672,2.716,7.692,7.799,8.88,12.96c0.397,0.259,0.397,2.141,0,2.4
			c0.159,1.12-0.378,1.542-0.48,2.4c1.927-0.554,3.87-1.09,6-1.44c0.831,0.031,0.351-1.25,0.96-1.44
			c7.489,0.129,8.198-6.522,9.841-12.24c0.159,0,0.319,0,0.479,0c-0.835-0.682,0.674-1.077,0.72-0.24
			c5.191,0.544,5.497,8.253,2.4,11.52c-3.758,3.964-10.846,3.14-17.04,4.56c-3.116,0.714-8.139,5.617-8.4,9.36
			c-0.402,5.77,4.657,13.271,9.12,15.84c1.942,1.118,4.104,1.136,7.2,1.44c-6.007-0.833-11.187-5.095-10.8-12
			c0.172-3.065,2.109-5.549,5.52-6c0.333-0.547,1.671-0.089,2.4-0.24c4.319-0.641,9.541-0.379,11.521-3.36
			c0.803-2.317,1.265-4.975,3.84-5.52c0.282,2.782-2.378,4.648-2.4,7.92c0,0.8,0,1.6,0,2.4c1.521,5.6,4.913,9.327,11.28,10.08
			c-3.636-3.269-6.039-10.106-3.601-16.08c0.093-0.812-1.062-0.377-0.72-1.44c0.947-2.012,2.428-3.492,3.84-5.04
			c0.78-0.98,1.66-1.86,2.641-2.64c0.688-1.552,1.893-2.587,2.88-3.84c1.614-1.985,3.311-3.889,3.6-7.2c0-0.64,0-1.28,0-1.92
			c0.132-0.349-0.28-1.24,0.24-1.2c0.383-1.502-0.981-1.259-0.72-2.64c0-0.08,0-0.16,0-0.24c1.518-1.248,4.403,3.187,5.52,5.52
			c1.41,2.948,2.647,6.559,1.2,8.64c-1.492-0.461-1.148-2.412-1.44-3.6c-0.546-2.226-1.606-4.254-2.399-6
			c-0.343-0.058-0.405-0.396-0.72-0.48c-0.139,0.422,0.29,1.41-0.24,1.44c-0.605,6.515-4.947,9.292-7.92,13.44
			c-0.94,1.14-1.98,2.18-3.12,3.12c-0.254,0.754-1.002,1.013-0.48,1.92c0.355,0.044,0.221,0.58,0.24,0.96
			c0.539,0.367,1.18-1.212,1.92-1.44c0.087-0.973,1.399-1.663,2.16-1.2c1.042,1.599-0.966,2.033-1.2,3.36
			c-0.511,2.899,1.925,5.183,4.08,6.24c-0.925-2.122,0.142-4.89,1.44-6.72c0.239,0,0.479,0,0.72,0c0.727-0.554,2.476-0.084,3.6-0.24
			c7.544,0.344,13.339-1.062,15.601-6c0.127-0.193,0.25-0.39,0.24-0.72c0.194-0.045,0.239-0.241,0.479-0.24
			c-0.039-0.279,0.053-0.427,0.24-0.48c0.148-1.692,1.018-2.663,1.439-4.08c0.585-1.895,1.31-3.65,1.681-5.76
			c0.441-1.398,0.795-2.885,1.439-4.08c0.446-0.833,0.776-1.784,1.44-2.4c0.401-0.318,0.804-0.636,1.68-0.48
			c0.627,1.127,0.077,2.884-0.479,3.6c0.673,0.847,0.83,2.21,1.92,2.64c0.106,0.613,0.336,1.104,0.72,1.44
			c0.336,0.864,0.567,1.833,0.96,2.64c-0.067,0.387,0.227,0.413,0.24,0.72c0.172,0.055,0.307,0.044,0.414-0.019
			c0.021,0.006,0.041,0.023,0.065,0.019c0-0.027,0-0.054,0-0.081c0.64-0.593,0.01-3.491,0.24-4.719c0-0.32,0-0.64,0-0.96
			c-0.08,0-0.16,0-0.24,0c0-0.56,0-1.12,0-1.68c0-1.44,0-2.88,0-4.32c0-0.4,0-0.8,0-1.2c-0.08,0-0.159,0-0.239,0c0-0.8,0-1.6,0-2.4
			c-0.144-0.497,0.296-1.577-0.24-1.68c-0.02-1.181-0.471-1.929-0.72-2.88c-0.309-1.052-1.035-1.686-1.44-2.64
			c-0.347-1.254-1.145-2.055-1.92-2.88c-0.902-1.498-1.959-2.841-2.88-4.32c-0.131-0.989-0.836-1.405-1.2-2.16
			c-0.465-0.976-1.136-1.744-1.68-2.64c-0.535-1.545-1.547-2.613-2.4-3.84c0-0.32,0-0.64,0-0.96c0-0.16,0-0.32,0-0.48
			c2.144-1.181,4.366,1.275,6.72,1.44c0.809,0.152,2.229-0.309,2.641,0.24c2.123-0.123,3.802,0.198,5.52,0.48
			c1.26,0.021,2.145,0.416,3.12,0.72c0.16,0,0.32,0,0.48,0C385.816,160.549,384.543,160.222,384.001,159.164z M353.89,371.108
			c0.057,0.11,0.112,0.221,0.166,0.334C354.002,371.33,353.946,371.219,353.89,371.108z M354.17,371.694
			c0.048,0.106,0.094,0.212,0.138,0.319C354.264,371.906,354.218,371.8,354.17,371.694z M354.418,372.298
			c0.038,0.102,0.076,0.203,0.111,0.305C354.494,372.501,354.456,372.399,354.418,372.298z M354.627,372.914
			c0.03,0.097,0.06,0.194,0.086,0.292C354.687,373.108,354.656,373.011,354.627,372.914z M354.877,376.258
			c0.013-0.063,0.018-0.126,0.028-0.188C354.895,376.132,354.89,376.195,354.877,376.258z M354.963,375.657
			c0.007-0.076,0.006-0.153,0.01-0.23C354.969,375.504,354.97,375.581,354.963,375.657z M354.984,375.047
			c-0.001-0.084-0.009-0.168-0.014-0.252C354.976,374.879,354.983,374.963,354.984,375.047z M354.793,373.538
			c0.021,0.093,0.043,0.186,0.062,0.279C354.836,373.724,354.813,373.631,354.793,373.538z M354.909,374.165
			c0.013,0.089,0.027,0.179,0.037,0.268C354.937,374.343,354.922,374.254,354.909,374.165z M352.17,368.565
			c-0.106-0.121-0.213-0.237-0.319-0.347C351.957,368.328,352.063,368.444,352.17,368.565z M353.776,370.89
			c-0.063-0.116-0.127-0.231-0.192-0.344C353.649,370.66,353.714,370.774,353.776,370.89z M353.476,370.363
			c-0.072-0.12-0.146-0.238-0.22-0.354C353.33,370.125,353.403,370.243,353.476,370.363z M353.161,369.865
			c-0.082-0.124-0.165-0.245-0.25-0.363C352.996,369.62,353.079,369.741,353.161,369.865z M352.836,369.398
			c-0.091-0.125-0.183-0.247-0.275-0.365C352.653,369.151,352.745,369.273,352.836,369.398z M352.503,368.963
			c-0.099-0.124-0.199-0.245-0.299-0.359C352.305,368.719,352.403,368.838,352.503,368.963z M253.44,217.964
			c0.395-1.083-0.172-3.123,1.44-3.6c0.156,0.244,0.552,0.248,0.48,0.72c0.397,0.259,0.397,2.141,0,2.4
			c-0.48,3.052-2.649,5.802-4.56,7.44c-2.082,1.785-4.888,2.059-7.2,3.84c-2.295,1.768-2.868,5.562-2.88,8.88
			c-0.004,1.233,0.869,2.749-0.48,3.84c-2.78-0.878-4.171-6.455-4.08-10.08c0.099-3.93,2.314-8.372,4.32-11.04
			c1.462-1.945,3.664-2.862,4.32-5.52c0.342-0.618,0.563-1.358,1.44-1.44c0.325,0.409,0.864,1.838,0.24,2.4
			c-0.016,1.104-0.682,1.558-0.96,2.4c-0.78,0.98-1.66,1.86-2.64,2.64c-1.918,2.642-3.495,5.625-4.32,9.36c0,0.96,0,1.92,0,2.88
			c0,0.08,0,0.16,0,0.24C240.228,224.626,250.664,225.572,253.44,217.964z M360.343,165.126c-0.024-0.039-0.048-0.077-0.071-0.116
			c0.27,0.417,0.533,0.841,0.789,1.272C360.828,165.89,360.587,165.507,360.343,165.126z M357.543,161.019
			c-0.001-0.002-0.003-0.003-0.004-0.005c0.001,0.001,0.002,0.002,0.003,0.004C357.542,161.018,357.543,161.019,357.543,161.019z
			 M364.317,178.181c-0.159-0.301-0.282-0.605-0.381-0.91C364.035,177.576,364.159,177.879,364.317,178.181
			C364.317,178.181,364.317,178.181,364.317,178.181z M368.4,190.364c0.837-1.64,1.718-3.68,1.92-6
			c0.56-6.408-3.123-12.443-5.52-17.28c0-0.16,0-0.32,0-0.48c7.98,4.229,10.854,24.538,0.96,28.8
			C365.858,193.267,367.551,192.029,368.4,190.364z M337.052,142.674c3.491,1.621,6.545,4.047,10.25,4.655c0,0-0.001,0-0.001,0
			c0,0,0.001,0,0.001,0l0,0C343.597,146.721,340.542,144.298,337.052,142.674z M336.182,142.112
			c-0.094-0.036-0.18-0.079-0.273-0.115C336.002,142.033,336.088,142.076,336.182,142.112z M336.955,144.256
			c-0.204-0.099-0.404-0.205-0.6-0.319C336.551,144.051,336.751,144.157,336.955,144.256z M336.217,143.852
			c-0.2-0.122-0.396-0.251-0.582-0.395C335.821,143.601,336.017,143.73,336.217,143.852z M335.604,143.433
			c-0.188-0.146-0.361-0.309-0.528-0.482C335.242,143.124,335.416,143.287,335.604,143.433z M334.973,142.845
			c-0.162-0.178-0.312-0.371-0.448-0.581C334.661,142.474,334.811,142.667,334.973,142.845z M334.449,142.139
			c-0.138-0.227-0.265-0.466-0.368-0.734C334.185,141.672,334.312,141.912,334.449,142.139z M338.3,144.837
			c-0.081-0.032-0.163-0.066-0.245-0.099C338.137,144.771,338.219,144.805,338.3,144.837z M337.677,144.583
			c-0.181-0.076-0.359-0.156-0.537-0.239C337.317,144.426,337.497,144.506,337.677,144.583z M352.42,155.332
			c-1.33-1.441-2.57-2.884-3.994-4.225c-0.001,0-0.001,0-0.001-0.001C349.849,152.447,351.09,153.889,352.42,155.332z
			 M352.71,156.572c-0.063-0.076-0.125-0.152-0.188-0.229C352.585,156.42,352.646,156.497,352.71,156.572z M351.881,155.536
			c-0.013-0.017-0.025-0.033-0.038-0.05C351.855,155.502,351.868,155.519,351.881,155.536z M306.48,188.204
			c0.476-1.76,1.983-3.012,3.12-4.32c4.073-4.688,8.157-9.909,8.16-18.96c0.694,0.106,1.094,0.506,1.2,1.2
			c0.398,0.729,0.398,3.112,0,3.84c-0.435,6.686-3.886,10.354-6.96,14.4c4.312-2.126,10.76-7.58,11.04-14.16
			c0.055-1.291-0.909-2.692,0-3.84c1.221,0.138,1.506,1.214,1.439,2.64c0.397,0.259,0.397,2.141,0,2.4
			c-0.492,4.308-2.792,6.808-4.8,9.6c-0.779,0.98-1.659,1.86-2.64,2.64C314.234,185.599,310.236,188.783,306.48,188.204z
			 M324.48,187.724c-2.753,3.027-5.6,5.711-8.64,6.72c-1.271-0.591,0.381-2.091,0.96-2.4c1.084-0.756,1.799-1.881,3.12-2.4
			c1.18-1.38,2.46-2.66,3.84-3.84c2.246-2.874,4.003-6.236,5.04-10.32c1.12,0.11,1.157,1.501,1.2,2.16
			C330.235,181.248,326.854,185.114,324.48,187.724z M334.801,176.924c-2.388-4.185-3.242-10.099-5.76-14.16
			c-4.795-7.734-15.731-8.117-22.32-14.16c-0.089-0.408,0.188-0.453,0.479-0.48c8.211,4.223,17.813,5.614,23.04,12.72
			c3.161,4.298,3.804,10.935,6.721,15.6c1.084,1.734,2.982,2.054,3.359,4.32C337.685,181.131,335.742,178.574,334.801,176.924z
			 M345.453,198.457c0.336-0.559,0.625-1.175,0.875-1.829C346.078,197.282,345.791,197.898,345.453,198.457z M345.601,198.044
			c0.16-0.245,0.304-0.504,0.439-0.771C345.906,197.542,345.761,197.8,345.601,198.044z M346.409,196.434
			c-0.001,0.002-0.002,0.004-0.003,0.007c0,0,0,0,0,0c-0.001,0.003-0.003,0.006-0.004,0.009c-0.002,0.004-0.004,0.008-0.005,0.012
			c0.005-0.014,0.012-0.026,0.017-0.04c-0.001,0.002-0.001,0.004-0.002,0.006S346.41,196.432,346.409,196.434z M347.294,191.606
			c0.001-0.029,0.002-0.059,0.003-0.088C347.296,191.547,347.295,191.577,347.294,191.606z M346.923,185.916
			c0.005,0.034,0.011,0.066,0.016,0.1C346.934,185.982,346.928,185.949,346.923,185.916z M347.158,187.767
			c0.005,0.049,0.009,0.099,0.014,0.147C347.167,187.865,347.163,187.816,347.158,187.767z M346.85,195.009
			c0.001-0.003,0.001-0.006,0.002-0.009c-0.007,0.026-0.011,0.054-0.018,0.08C346.84,195.057,346.844,195.032,346.85,195.009z
			 M347.028,194.195c0-0.002,0.001-0.005,0.001-0.008c0.357-1.697,0.515-3.531,0.471-5.372
			C347.547,190.662,347.387,192.496,347.028,194.195z M346.641,184.313c-0.011-0.054-0.02-0.108-0.031-0.162
			C346.621,184.204,346.63,184.259,346.641,184.313z M346.276,182.65c-0.022-0.094-0.046-0.186-0.069-0.278
			C346.23,182.464,346.253,182.555,346.276,182.65z M348.961,171.884c-0.785-1.234-1.726-2.171-2.4-3.36
			c-1.46-1.26-2.82-2.62-4.08-4.08c-2.72-2.16-5.724-4.037-8.88-5.76c-0.934-0.266-1.736-0.664-2.4-1.2
			c-1.204-0.315-2.049-0.991-3.119-1.44c-1.473-0.607-3.008-1.153-4.08-2.16c-6.031-2.957-13.136-7.614-17.761-12.48
			c-0.986-1.038-1.994-2.559-2.399-4.08c-0.602-0.198-0.76-0.841-1.2-1.2c1.573,0.11,3.053,2.386,4.32,3.6
			c6.727,6.303,15.095,11.786,24.239,16.08c4.504,2.115,8.785,4.409,12.48,7.68c3.318,2.938,7.503,6.701,7.68,12
			C350.029,174.795,349.701,173.049,348.961,171.884z M359.28,198.044c-0.848,7.262-7.487,10.822-14.399,11.52
			c1.914-2.668,5.589-2.504,8.16-4.08c3.825-2.345,6.365-9.404,3.359-14.88c-0.204,0.044-0.227-0.093-0.239-0.24
			c0.282-1.028,1.557,0.745,1.92,1.2c0.46,1.14,1.289,1.911,1.199,3.6C359.679,195.578,359.679,197.63,359.28,198.044z
			 M361.904,167.891c0.008,0.016,0.016,0.031,0.023,0.046C361.92,167.922,361.912,167.907,361.904,167.891z M362.586,169.32
			c0.007,0.016,0.013,0.031,0.02,0.047C362.599,169.352,362.593,169.336,362.586,169.32z M363.93,177.25
			c-0.097-0.301-0.167-0.603-0.22-0.906C363.763,176.647,363.833,176.95,363.93,177.25z M363.705,176.313
			c-0.052-0.304-0.086-0.61-0.108-0.917C363.619,175.702,363.653,176.008,363.705,176.313z M363.596,175.38
			c-0.047-0.655-0.049-1.313-0.069-1.97C363.547,174.067,363.548,174.726,363.596,175.38z M363.485,172.614
			c-0.003-0.041-0.008-0.082-0.012-0.123C363.478,172.532,363.482,172.573,363.485,172.614z M363.364,171.64
			c-0.004-0.021-0.009-0.042-0.013-0.063C363.355,171.598,363.36,171.619,363.364,171.64z M367.44,203.804
			c-1.008,1.685-2.516,3.18-2.821,4.945C364.925,206.984,366.433,205.489,367.44,203.804c0.179-0.299,0.357-0.613,0.534-0.938
			C367.798,203.191,367.619,203.505,367.44,203.804z M368.499,201.859c-0.112,0.224-0.225,0.437-0.337,0.65
			C368.274,202.296,368.388,202.083,368.499,201.859z M368.732,201.372c0.093-0.192,0.186-0.385,0.276-0.584
			C368.918,200.987,368.825,201.179,368.732,201.372z M370.258,197.713c0.053-0.144,0.104-0.289,0.155-0.435
			C370.361,197.425,370.311,197.569,370.258,197.713z M369.267,200.206c0.078-0.18,0.156-0.359,0.233-0.543
			C369.423,199.847,369.345,200.025,369.267,200.206z M369.775,198.984c0.065-0.164,0.13-0.328,0.194-0.494
			C369.905,198.657,369.841,198.82,369.775,198.984z M371.536,193.573c-0.006,0.023-0.012,0.047-0.018,0.071
			C371.524,193.62,371.53,193.596,371.536,193.573z M371.203,194.791c-0.023,0.083-0.047,0.166-0.07,0.249
			C371.156,194.957,371.18,194.874,371.203,194.791z M370.825,196.041c-0.037,0.119-0.075,0.237-0.113,0.355
			C370.75,196.278,370.787,196.16,370.825,196.041z M371.521,194.924c0.233-0.817,0.427-1.653,0.603-2.496
			C371.948,193.271,371.754,194.107,371.521,194.924z M372.648,189.27c0.229-1.877,0.333-3.781,0.314-5.671
			C372.981,185.49,372.881,187.393,372.648,189.27z M372.962,183.621c0-0.011,0.001-0.021,0.001-0.032c0,0.003,0,0.005,0,0.008
			C372.963,183.605,372.962,183.613,372.962,183.621z M372.668,178.872c0-0.002,0-0.004-0.001-0.005c0-0.001,0-0.002,0-0.003
			C372.668,178.866,372.668,178.869,372.668,178.872z M370.103,170.039c-0.031-0.101-0.063-0.203-0.095-0.304
			C370.039,169.836,370.071,169.938,370.103,170.039z"/>
			</g>
			<g id="blue">
				<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M454.561,687.165c0.602-0.679,1.799-0.762,2.4-1.439
			c-1.045,0.154-2.713-0.313-3.36,0.239C454.131,686.155,453.913,687.094,454.561,687.165z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M399.841,634.845c7.565-1.234,16.136-1.464,23.28-3.12
			c0.649-0.47,1.592-0.646,2.64-0.72c0.08,0,0.16,0,0.24,0c0.892-1.908,1.288-4.312,2.399-6
			c-6.115-8.685-10.133-19.467-14.159-30.24c0.018-0.418-0.054-0.746-0.24-0.96c-0.904-1.575-1.415-3.545-1.92-5.52
			c0.081-1.201-0.562-1.679-0.48-2.88c-0.276-0.284-0.271-0.849-0.24-1.44c-0.466-1.134-0.652-2.547-0.72-4.08
			c-0.63-0.479,0.153-1.539-0.72-0.96c-5.116,3.394-10.925,9.373-16.32,11.04c5.577-5.942,12.159-10.881,17.76-16.8
			c0.389-0.491,0.188-1.573,0.721-1.92c0-0.08,0-0.16,0-0.24c0.119-1.32,0.74-2.14,0.96-3.36c0.04-0.52-0.852-0.108-1.2-0.239
			c-1.457-0.303-2.504-1.017-3.12-2.16c-7.551,4.609-14.896,9.424-23.28,13.2c-0.08,0-0.159,0-0.239,0
			c-0.664,0.536-1.467,0.934-2.4,1.199c-1.371,0.149-2.077,0.963-3.36,1.2c-0.08,0-0.159,0-0.239,0
			c-2.005,1.116-4.396,1.845-6.961,2.4c-0.08,0-0.159,0-0.239,0c-2.288,1.231-5.926,1.114-8.641,1.92
			c-0.472-1.357,2.592-1.245,3.36-2.16c5.188-1.771,10.049-3.871,14.88-6c0.825-0.615,1.786-1.094,2.88-1.439
			c0.331,0.01,0.527-0.113,0.721-0.24c0.876-0.724,2.109-1.091,3.119-1.681c0.08,0,0.16,0,0.24,0
			c7.523-4.957,16.754-8.206,22.32-15.119c-0.667-0.373-0.899-1.181-1.44-1.681c0-0.08,0-0.16,0-0.239
			c0.183-0.839-0.726-1.813,0-2.4c0-0.08,0-0.16,0-0.24c0.342-1.179,0.14-2.9,0.48-4.08c0.129-1.36-3.244-4.55-4.561-6.24
			c-4.86-6.24-9.589-12.751-12.479-19.92c-0.107-1.093-0.617-1.783-0.96-2.64c-0.584-0.536-0.673-1.566-0.96-2.4
			c-0.512-2.528-1.517-4.563-1.92-7.199c-0.238-0.643-0.495-1.266-0.48-2.16c-0.868-2.572-1.414-5.467-1.92-8.4
			c0-0.08,0-0.16,0-0.24c-0.553-1.447-0.737-3.263-0.72-5.279c0-0.08,0-0.16,0-0.24c-0.048-1.975-0.842-1.427-2.4-1.2
			c-2.662,0.388-9.584,0.547-12.479,0.48c-0.729-0.151-2.067,0.307-2.4-0.24c-0.887-0.153-2.391,0.311-2.88-0.24
			c-0.08,0-0.16,0-0.24,0c-4.8,0-9.6,0-14.399,0c-0.886,0.555-2.797,0.084-4.08,0.24c-4,0-8,0-12,0
			c-1.125-0.155-2.874,0.313-3.601-0.24c-1.92,0-3.84,0-5.76,0c-0.808-0.152-2.229,0.31-2.64-0.24
			c-3.481,0.042-6.462-0.418-10.08-0.24c-1.603-0.157-3.837,0.317-5.04-0.239c-2.801,0-5.601,0-8.4,0
			c-0.806,0.555-2.636,0.084-3.84,0.239c-2.915-0.034-5.832-0.071-8.16,0.48c-0.496,0.734-2.136-0.21-2.4,0.24
			c-0.686,4.594-1.123,9.437-2.16,13.68c-0.032,1.408-0.162,2.718-0.48,3.84c0.009,1.529-0.09,2.95-0.48,4.08
			c-0.049,0.751,0.162,1.762-0.24,2.16c-0.065,1.695-0.209,3.312-0.72,4.561c0,0.079,0,0.159,0,0.239
			c-0.017,0.943-0.116,1.804-0.48,2.4c0,0.08,0,0.16,0,0.24c-0.213,1.866-0.403,3.756-0.96,5.279c0,0.08,0,0.16,0,0.24
			c-0.079,1.361-0.301,2.579-0.72,3.601c0,0.08,0,0.159,0,0.239c-0.079,1.361-0.301,2.579-0.72,3.601c0,0.08,0,0.16,0,0.239
			c-0.017,0.943-0.116,1.805-0.48,2.4c0,0.08,0,0.16,0,0.24c0.074,1.354-0.426,2.134-0.24,3.6c-0.152,0.809,0.309,2.229-0.24,2.641
			c0,0.64,0,1.279,0,1.92c-0.152,0.808,0.309,2.229-0.24,2.64c0,1.36,0,2.72,0,4.08c-0.159,2.881,0.319,6.398-0.24,8.88
			c0,2.72,0,5.44,0,8.16c-0.159,2.801,0.319,6.239-0.24,8.64c0,0.48,0,0.96,0,1.44c-0.158,1.603,0.317,3.837-0.24,5.04
			c-0.122,0.838-0.108,1.812-0.48,2.4c0.057,1.017-0.221,1.699-0.48,2.399c0,0.24,0,0.48,0,0.72c-0.41,1.83-0.968,3.512-1.68,5.04
			c-0.188,1.519-2.556,4.865,0.24,5.04c0.08,0,0.16,0,0.24,0c4.114,0.206,7.938,0.702,12.24,0.721
			c0.808,0.151,2.229-0.31,2.64,0.239c0.32,0,0.64,0,0.96,0c2.48,0.159,5.599-0.318,7.68,0.24c0.16,0,0.32,0,0.479,0
			c1.592-0.365,5.12,0.265,2.881,1.44c-2.59-0.109-4.534,0.426-7.2,0.239c-2.722,0.4-7.119,0.4-9.84,0c-0.72,0-1.44,0-2.16,0
			c-0.729-0.15-2.067,0.308-2.4-0.239c-0.349-0.132-1.24,0.28-1.2-0.24c-0.08,0-0.16,0-0.24,0c-0.729-0.15-2.067,0.307-2.4-0.24
			c-0.349-0.371-1.229-0.211-1.92-0.24c-0.779,0.14-1.117-0.163-1.68-0.239c-0.601,0.599-0.728,1.672-0.72,2.88
			c0.66,0.379,1.665,0.414,2.4,0.72c1.894,0.746,3.654,1.626,5.28,2.64c2.396,1.045,4.705,2.176,7.44,2.881
			c1.426,0.653,3.103,1.058,3.601,2.64c-0.359-0.041-0.886,0.086-0.96-0.24c-4.057-0.583-7.235-2.044-10.8-3.12
			c-2.693-0.907-4.615-2.585-7.44-3.359c-0.041,0.358,0.085,0.886-0.24,0.96c-0.517,0.202,0.09,1.529-0.48,1.68
			c-0.029,0.691,0.131,1.571-0.24,1.92c-0.247,1.792,0.211,1.677,1.68,1.44c0.24,0,0.48,0,0.72,0c0.827-0.654,2.224,0.167,2.4,0.96
			c-0.256,0.544-0.728,0.872-1.44,0.96c-0.08,0-0.16,0-0.24,0c-0.763,0.59-2.501,0.11-3.36,0c-0.315,1.125-0.247,2.633-0.24,4.08
			c-0.154,0.966,0.312,2.552-0.24,3.119c0,0.721,0,1.44,0,2.16c-0.153,0.887,0.311,2.392-0.24,2.881
			c-0.304,1.616-0.22,3.619-0.72,5.039c0,0.08,0,0.16,0,0.24c-0.675,7.006-2.016,13.344-2.88,20.16
			c-0.049,0.751,0.162,1.762-0.24,2.16c-0.067,1.452-0.199,2.841-0.72,3.84c0,0.08,0,0.16,0,0.24
			c-0.161,1.438-0.474,2.726-0.96,3.84c0.032,0.592,0.037,1.156-0.24,1.44c0.056,1.176-0.423,1.816-0.24,3.12
			c-0.15,0.729,0.308,2.066-0.24,2.399c-0.146,0.574,0.301,1.741-0.24,1.92c0,0.16,0,0.32,0,0.48c0.428,0.771,0.223,2.177,0.48,3.12
			c0,0.079,0,0.16,0,0.239c0.372,0.588,0.358,1.563,0.48,2.4c0.551,0.489,0.087,1.993,0.24,2.88c-0.054,0.187-0.007,0.474-0.24,0.48
			c-0.078,0.882,0.205,2.124-0.24,2.64c-0.019,1.262-0.303,2.257-0.72,3.12c0,0.08,0,0.16,0,0.24
			c-0.154,0.885-0.246,1.833-0.72,2.399c0.005,1.365-0.407,2.313-0.72,3.36c-0.61,2.909-0.89,6.15-1.68,8.88c0,0.08,0,0.16,0,0.24
			c-0.245,3.275-1.095,5.945-0.96,9.6c-0.15,0.729,0.308,2.067-0.24,2.4c0,1.439,0,2.88,0,4.319c-0.159,2.082,0.318,4.799-0.24,6.48
			c0,6.08,0,12.16,0,18.24c-0.15,0.729,0.308,2.067-0.24,2.399c-0.27,4.131-0.32,8.48-0.96,12.24c0,0.08,0,0.16,0,0.24
			c-0.01,2.71-0.247,5.193-0.72,7.44c0,0.079,0,0.159,0,0.239c-0.01,1.431-0.104,2.776-0.48,3.84c0,0.08,0,0.16,0,0.24
			c-0.132,2.668-0.375,5.226-0.72,7.681c0,0.079,0,0.16,0,0.239c0,1.92,0,3.841,0,5.761c-0.157,1.442,0.316,3.516-0.24,4.56
			c0.186,4.905-0.325,9.115-0.24,13.92c11.12,0,22.24,0,33.36,0c41.76,0,83.521,0,125.28,0c1.36,0,2.72,0,4.08,0
			c0.24,0,0.479,0,0.72,0c0.32,0,0.641,0,0.96,0c0-0.08,0-0.16,0-0.24c1.842,0.158,4.317-0.317,5.76,0.24
			c0.827,0.187,1.146-0.134,0.961-0.96c0.529-0.029,0.102-1.018,0.239-1.439c0-0.961,0-1.921,0-2.881c0.08,0,0.16,0,0.24,0
			c0-1.199,0-2.399,0-3.6c0.159-2.401-0.318-5.438,0.24-7.44c0-0.319,0-0.64,0-0.96c-0.559-1.682-0.082-4.398-0.24-6.479
			c0-0.561,0-1.12,0-1.681c-0.08,0-0.16,0-0.24,0c0-0.72,0-1.439,0-2.159c0-0.24,0-0.48,0-0.721c0.177-2.816-0.273-5.006-0.239-7.68
			c-0.08,0-0.16,0-0.24,0c0-0.64,0-1.28,0-1.92c0-0.4,0-0.8,0-1.2c0-1.439,0-2.88,0-4.32c0-0.399,0-0.8,0-1.199
			c-0.703-1.778-0.893-4.068-1.2-6.24c0-0.641,0-1.28,0-1.92c-0.08,0-0.16,0-0.24,0c0-0.641,0-1.28,0-1.92c0-0.24,0-0.48,0-0.721
			c0-0.8,0-1.6,0-2.399c-1.062-2.138-2.311-4.089-2.16-7.44c-0.08,0-0.159,0-0.239,0c0-0.96,0-1.92,0-2.88c0.08,0,0.159,0,0.239,0
			c0.345-4.936,1.746-8.814,1.681-14.16c0.371-1.708,0.438-3.721,0.479-5.76c0-0.8,0-1.6,0-2.4c0-1.439,0-2.88,0-4.319
			c0-1.36,0-2.721,0-4.08c0-0.641,0-1.28,0-1.92c-0.08,0-0.16,0-0.24,0c0-0.561,0-1.12,0-1.681c0-0.239,0-0.479,0-0.72
			c0.021-2.82-0.065-5.534-0.479-7.92c0-0.72,0-1.44,0-2.16c-0.08,0-0.16,0-0.24,0c0.074-0.954-0.555-1.205-0.479-2.16
			c0-0.08,0-0.16,0-0.239c0.555-0.886,0.083-2.797,0.239-4.08c-0.143-0.497,0.297-1.577-0.239-1.681c0-0.16,0-0.319,0-0.479
			c0-0.24,0-0.48,0-0.72c0-2.16,0-4.32,0-6.48c0.071-2.328,0.378-4.421,0.239-6.96c0-0.64,0-1.28,0-1.92
			c-0.397-0.492-0.397-2.628,0-3.12c0.451-2.27,0.904-4.536,0.96-7.2c0.468-2.144,0.419-3.339,0.721-5.52
			c0.416-2.063,0.588-4.372,0.96-6.48c0.08,0,0.16,0,0.24,0c0-0.08,0-0.16,0-0.24c0-0.56,0-1.12,0-1.68
			c-0.138-1.817,0.348-3.012,0.72-4.32c0.312-1.048,0.481-2.238,0.479-3.6c0.063-0.737,0.521-1.079,0.721-1.68
			c-0.036-0.536-0.605,0.312-1.2,0c-0.08,0-0.16,0-0.24,0c-0.863,0.417-1.858,0.701-3.12,0.72c-1.517-0.077-2.264,0.616-3.84,0.479
			c-0.08,0-0.16,0-0.24,0c-0.972,0.389-2.229,0.491-3.6,0.48c-0.08,0-0.16,0-0.24,0c-1.289,0.391-2.871,0.488-4.56,0.479
			c-0.08,0-0.16,0-0.24,0c-1.449,0.392-3.192,0.488-5.04,0.48c-0.08,0-0.16,0-0.24,0c-0.701,0.499-2.311,0.089-3.12,0.48
			c-0.723,0.55-2.626,0.234-3.12-0.24C398.192,635.276,398.778,634.822,399.841,634.845z M308.881,678.766
			c-0.813,0.386-1.908,0.491-3.12,0.479c-0.08,0-0.16,0-0.24,0c-2.384,0.576-5.08,0.841-7.92,0.96c-0.08,0-0.16,0-0.24,0
			c-0.895,0.465-2.568,0.152-3.6,0.48c-1.046,0.398-3.755,0.398-4.8,0c-0.877,0.091-1.468-1.609-0.48-1.92
			c0.568-0.553,2.154-0.086,3.12-0.24c4.503,0.103,8.061-0.74,12.24-0.96c0.08,0,0.16,0,0.239,0c2.489-0.552,5.133-0.948,7.921-1.2
			c0.08,0,0.159,0,0.239,0c1.296-0.464,2.913-0.607,4.561-0.72c1.048-0.312,2.238-0.481,3.6-0.48c2.49-0.63,5.271-0.97,8.16-1.2
			c0.08,0,0.16,0,0.24,0c0.835-0.365,1.967-0.433,3.12-0.479c0.08,0,0.159,0,0.239,0c-0.044-0.204,0.094-0.227,0.24-0.24
			c5.795-0.616,10.777-4.568,16.561-3.6c-1.51,2.65-5.452,2.868-8.4,4.08c-0.08,0-0.16,0-0.24,0
			c-1.185,0.654-2.686,0.993-4.319,1.199c-0.08,0-0.16,0-0.24,0c-1.398,0.602-3.159,0.841-5.04,0.96
			c-6.945,0.575-13.115,1.926-19.92,2.641C310.451,678.896,309.572,678.736,308.881,678.766z M403.681,769.245
			c-5.036,4.08-12.121,7.604-18.96,11.761c-3.289,1.998-6.538,3.969-9.6,5.76c-3.434,2.007-6.646,4.406-9.841,5.52
			c-0.21,0.074-0.802,0.524-0.239-0.239c-0.688,0.432-1.607,0.632-2.16,1.199c-1.317,1.165-4.588,2.451-6.72,3.601
			c-0.16,0-0.32,0-0.48,0c-2.914-1.015,0.428-3.541,1.92-3.84c0.597,0.037,0.743-0.377,1.2-0.48c1.124-1.116,2.93-1.55,4.32-2.4
			c0.08,0,0.159,0,0.239,0c14.328-7.832,27.299-17.021,41.761-24.72c0.766,0.206,0.742-0.378,1.199-0.479c0-0.08,0-0.16,0-0.24
			c0.08,0,0.16,0,0.24,0c0.16,0,0.32,0,0.48,0C406.546,766.533,405.349,767.895,403.681,769.245z M418.081,745.245
			c-2.946,1.455-5.895,2.906-8.88,4.32c-0.598,0.442-1.502,0.579-2.16,0.96c-1.002,0.278-1.791,0.77-2.641,1.2
			c-0.797,0.482-1.758,0.801-2.64,1.2c-0.454-0.054-0.529,0.27-0.96,0.239c-0.601,0.199-0.942,0.658-1.68,0.721
			c-0.986,0.054-1.572,0.508-2.16,0.96c0.195,0.368-1.425,0.967-2.16,1.199c-1.104,0.016-1.558,0.683-2.4,0.961
			c-0.978,0.781-2.345,1.175-3.6,1.68c-1.596,0.324-2.758,1.082-4.08,1.68c-1.136,0.864-2.645,1.355-4.08,1.92
			c-3.173,1.067-5.954,2.525-8.88,3.84c-0.664,0.537-1.466,0.935-2.4,1.2c-0.08,0-0.159,0-0.239,0c-1.3,0.94-2.985,1.495-4.561,2.16
			c-0.08,0-0.16,0-0.24,0c-0.663,0.536-1.466,0.934-2.399,1.2c-0.08,0-0.16,0-0.24,0c-0.664,0.536-1.466,0.934-2.4,1.2
			c-1.104,0.016-1.558,0.682-2.399,0.96c-1.136,0.864-2.645,1.355-4.08,1.92c-0.08,0-0.16,0-0.24,0
			c-0.814,0.705-1.994,1.046-3.12,1.439c-2.075,0.502-4.224,1.772-6.479,2.641c-2.051,0.788-4.803,2.209-6.721,0.96
			c0.404-1.356,2.4-1.12,3.36-1.92c0.653-0.627,1.704-0.856,2.64-1.2c2.875-0.886,5.336-2.186,7.92-3.36
			c1.301-0.94,2.985-1.494,4.561-2.16c0.08,0,0.16,0,0.24,0c0.663-0.536,1.466-0.934,2.399-1.199c1.104-0.016,1.559-0.683,2.4-0.96
			c0.825-0.615,1.786-1.095,2.88-1.44c3.173-1.067,5.955-2.525,8.88-3.84c0.664-0.536,1.466-0.935,2.4-1.2
			c1.104-0.016,1.558-0.682,2.399-0.96c0.979-0.782,2.345-1.175,3.601-1.68c1.051,0.091,1.233-0.687,2.16-0.721
			c0.08,0,0.159,0,0.239,0c0.866-0.813,2.341-1.02,3.36-1.68c0.978-0.782,2.345-1.175,3.6-1.68c3.28-1.041,5.965-2.676,9.12-3.84
			c0.986-0.055,1.572-0.509,2.16-0.96c-0.195-0.369,1.425-0.967,2.16-1.2c1.857-0.383,3.025-1.455,4.8-1.92c0.08,0,0.16,0,0.24,0
			c0-0.08,0-0.16,0-0.24c0.836-0.845,2.373-0.987,3.36-1.681c2.32-0.879,4.292-2.107,6.479-3.119
			C418.12,743.286,418.106,744.26,418.081,745.245z M378.961,693.885c0.051-0.269,0.266-0.373,0.479-0.479
			c13.571-7.068,26.128-15.152,36.48-25.44c0.489-0.07,0.471-0.649,0.96-0.72c0.689-0.515,1.19,0.764,1.439,1.44
			c0,0.08,0,0.16,0,0.239c-0.779,0.98-1.659,1.86-2.64,2.641c-0.558,0.162-0.643,0.797-1.2,0.96
			c-1.039,0.721-1.692,1.827-2.88,2.399c0,0.08,0,0.16,0,0.24c-11.081,10.039-24.687,17.554-39.12,24.24c-0.08,0-0.16,0-0.24,0
			c-1.156,0.843-2.579,1.421-4.079,1.92c-1.138,0.143-1.892,0.669-2.881,0.96c-0.688,0.432-1.474,0.766-2.64,0.72
			c1.658-1.691,4.282-3.343,6.96-4.8c2.629-1.43,5.715-3.572,7.92-4.08c0.265-0.061,0.168,0.353,0,0.48
			C378.011,694.376,378.471,694.115,378.961,693.885z M410.161,700.846c0.001,0.169-0.013,0.325-0.034,0.473
			C410.147,701.17,410.162,701.016,410.161,700.846z M409.201,704.445c0.006-0.225,0.036-0.424,0.076-0.613
			C409.237,704.021,409.207,704.221,409.201,704.445z M409.324,703.644c0.031-0.118,0.066-0.233,0.106-0.343
			C409.391,703.411,409.355,703.525,409.324,703.644z M409.517,703.07c0.011-0.025,0.021-0.05,0.032-0.075
			C409.539,703.021,409.527,703.045,409.517,703.07z M409.917,702.131c0-0.001,0-0.002,0.001-0.003
			C409.917,702.129,409.917,702.129,409.917,702.131L409.917,702.131z M410.4,686.205c0,0.08,0,0.16,0,0.24
			c-10.696,10.184-21.641,20.119-34.319,28.32c-0.881,0.08-1.231-0.369-1.44-0.96c12.975-11.507,27.518-21.443,38.641-34.801
			c0.314-0.085,0.377-0.422,0.72-0.479c0.058-0.343,0.395-0.405,0.479-0.72c0.7-0.901,1.5-1.701,2.4-2.4
			c0.65-0.31,0.854-1.066,1.92-0.96C417.356,679.721,413.349,682.433,410.4,686.205z M421.681,652.605
			c-1.188,1.771-2.628,3.292-4.56,4.319c-0.592-0.031-1.156-0.036-1.44,0.24c-0.978,0.782-2.345,1.176-3.6,1.68
			c-2.456,0.744-4.513,1.889-6.721,2.881c-1.3,0.939-2.985,1.494-4.56,2.159c-1.586,0.335-2.719,1.122-4.08,1.681
			c-0.815,0.705-1.994,1.046-3.12,1.439c-3.308,1.093-6.237,2.563-9.36,3.841c-1.673,1.046-3.745,1.694-5.76,2.399
			c-1.889,0.511-3.571,1.229-5.279,1.92c-0.767,0.675-2.057,0.824-3.12,1.2c-1.896,0.265-3.146,1.175-5.04,1.44
			c-0.08,0-0.16,0-0.24,0c-4.316,1.683-9.214,2.786-14.16,3.84c-1.814,0.505-3.651,0.988-6,0.96c-1.063,0.537-3.207-0.008-4.08,0.72
			c-0.957,0.328-2.718,1.114-3.6,0.72c1.932-1.508,5.537-1.342,7.68-2.64c0.954,0.074,1.205-0.555,2.16-0.48c0.08,0,0.16,0,0.24,0
			c1.181-0.658,2.819-0.86,4.319-1.199c0.08,0,0.16,0,0.24,0c0.869-0.571,2.172-0.709,3.36-0.96c0.08,0,0.16,0,0.239,0
			c0.842-0.52,2.002-0.719,3.12-0.96c0.08,0,0.16,0,0.24,0c1.06-0.621,2.471-0.891,3.84-1.2c0.08,0,0.16,0,0.24,0
			c4.511-1.81,9.531-3.109,13.92-5.04c1.168-0.753,2.625-1.215,4.08-1.681c1.093-0.106,1.783-0.616,2.64-0.96
			c0.654-0.626,1.705-0.855,2.641-1.199c0.08,0,0.16,0,0.24,0c0.653-0.627,1.704-0.856,2.64-1.2c1.782-0.458,3.256-1.224,4.8-1.92
			c1.23-0.85,2.809-1.352,4.32-1.92c0.936,0.055,1.244-0.516,1.92-0.721c0.653-0.626,1.704-0.855,2.64-1.199
			c0.936,0.055,1.244-0.516,1.92-0.721c0.653-0.626,1.704-0.855,2.641-1.2c0.282-0.037,0.681,0.042,0.72-0.239
			c0.241,0,0.286-0.194,0.48-0.24c1.246-0.594,2.535-1.145,3.84-1.68c0.936-0.345,1.986-0.574,2.64-1.2
			c0.282-0.038,0.682,0.041,0.72-0.24c2.272-0.608,2.95-2.811,5.04-3.6C421.301,651.545,421.849,651.718,421.681,652.605z
			 M421.921,640.125c0.503,1.31-1.4,2.276-1.92,3.36c-0.691,0.028-1.571-0.131-1.92,0.24c-0.147,0.213-0.29,0.429,0,0.479
			c-1.719,1.881-4.183,3.018-6.721,4.08c-0.08,0-0.159,0-0.239,0c-2.005,1.275-4.439,2.121-6.721,3.12
			c-1.051-0.091-1.233,0.686-2.159,0.72c-0.08,0-0.16,0-0.24,0c-3.204,1.836-6.993,3.087-10.32,4.8
			c-1.521,1.039-3.479,1.641-5.28,2.4c-2.979,0.941-5.595,2.245-8.399,3.36c-1.276,0.884-2.943,1.376-4.561,1.92
			c-2.831,0.768-5.138,2.062-7.92,2.88c-0.08,0-0.16,0-0.24,0c-1.818,1.142-4.292,1.627-6.239,2.64
			c-0.758,0.603-1.834,0.887-2.88,1.2c-1.049-0.008-1.418,0.663-2.4,0.72c-0.08,0-0.16,0-0.24,0c-1.214,0.866-2.99,1.17-4.32,1.92
			c-1.167,0.753-2.625,1.215-4.079,1.681c-0.08,0-0.16,0-0.24,0c-0.758,0.603-1.834,0.887-2.88,1.2c-0.08,0-0.16,0-0.24,0
			c-0.758,0.602-1.833,0.886-2.88,1.199c-0.941,0.02-1.497,0.423-2.16,0.721c-1.225,0.775-2.803,1.197-4.32,1.68
			c-0.08,0-0.16,0-0.24,0c-0.766,0.674-2.056,0.824-3.119,1.2c-0.978,0.063-1.629,0.451-2.4,0.72c-1.337,0.822-3.063,1.257-4.8,1.68
			c-0.24,0-0.48,0-0.721,0c-0.622,0.258-1.177,0.583-2.159,0.48c0.044,0.204-0.094,0.227-0.24,0.24
			c-0.614,0.521-2.61,0.381-2.4-0.721c1.678-1.923,5.198-2.001,7.681-3.12c0.08,0,0.159,0,0.239,0
			c1.055-0.865,2.921-0.919,4.08-1.68c0.758-0.603,1.834-0.887,2.88-1.2c0.08,0,0.16,0,0.24,0c0.758-0.603,1.834-0.887,2.88-1.199
			c0.08,0,0.16,0,0.24,0c0.758-0.604,1.833-0.887,2.88-1.2c0.08,0,0.16,0,0.24,0c0.758-0.603,1.833-0.887,2.88-1.2
			c0.755,0.115,0.938-0.342,1.44-0.48c1.167-0.752,2.625-1.215,4.08-1.68c0.755,0.115,0.938-0.342,1.439-0.479
			c0.758-0.603,1.833-0.887,2.88-1.2c0.755,0.115,0.938-0.342,1.44-0.48c0.757-0.603,1.833-0.887,2.88-1.2c0.08,0,0.16,0,0.24,0
			c1.167-0.752,2.625-1.215,4.08-1.68c0.08,0,0.159,0,0.239,0c0.758-0.603,1.834-0.887,2.881-1.2
			c0.678,0.198,0.621-0.338,1.199-0.24c0.08,0,0.16,0,0.24,0c1.168-0.752,2.625-1.215,4.08-1.68c0.08,0,0.16,0,0.24,0
			c2.177-1.184,4.734-1.985,7.2-2.88c0.08,0,0.159,0,0.239,0c3.21-1.591,6.822-2.777,10.32-4.08c0.08,0,0.16,0,0.24,0
			c2.225-1.296,5.103-1.937,7.439-3.12c0.653-0.626,1.704-0.855,2.641-1.2c0.755,0.115,0.938-0.342,1.439-0.479
			c0.653-0.627,1.704-0.855,2.641-1.2c0.08,0,0.159,0,0.239,0c0.979-0.782,2.345-1.175,3.601-1.68c0.08,0,0.16,0,0.24,0
			c0.978-0.782,2.345-1.176,3.6-1.681c2.552-0.648,4.288-2.111,6.96-2.64c0.08,0,0.16,0,0.24,0c0.062-0.498,1.055-0.065,1.2-0.479
			c-0.041-0.324,1.22-0.48,0.479-0.721c-0.399,0-0.8,0-1.2,0c-0.08,0-0.159,0-0.239,0c-0.341,0.402-2.384,0.478-2.4-0.239
			c0-0.08,0-0.16,0-0.24c-0.281-0.039-0.202-0.438-0.24-0.72c-0.88,0-1.76,0-2.64,0c-0.08,0-0.16,0-0.24,0
			c-2.095,0.385-4.616,0.343-6.96,0.479c-0.333,0.547-1.671,0.09-2.4,0.24c-1.04,0-2.079,0-3.119,0
			c-0.568,0.552-2.154,0.086-3.12,0.24c-1.521,0-3.04,0-4.561,0c-2.321,0.399-6.317,0.399-8.64,0c-1.2,0-2.4,0-3.601,0
			c-1.522-0.158-3.676,0.316-4.8-0.24c-0.64,0-1.279,0-1.92,0c-0.808-0.152-2.229,0.309-2.64-0.24
			c-2.13-0.27-4.319-0.481-6.72-0.479c-1.041,0-2.08,0-3.12,0c0.398-1.201,1.919-1.281,3.359-1.44c0.16,0,0.32,0,0.48,0
			c3.761-0.4,9.199-0.399,12.96,0c5.68,0,11.359,0,17.04,0c0.333-0.547,1.67-0.09,2.399-0.24
			C403.883,641.128,412.264,639.987,421.921,640.125z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M254.641,654.285c0.094-4.226,0.399-8.241,0.96-12
			c0-0.08,0-0.16,0-0.24c0.062-2.178,0.221-4.258,0.72-6c0-0.08,0-0.16,0-0.24c0.067-1.532,0.254-2.946,0.72-4.08
			c0.271-0.768,0.488-1.592,0.48-2.64c0.085-1.115,0.308-2.092,0.72-2.88c0-0.08,0-0.16,0-0.24c0.32-2.08,0.798-4.002,1.44-5.76
			c0-0.08,0-0.16,0-0.24c0.392-2.408,0.779-4.82,1.44-6.96c0-0.08,0-0.16,0-0.24c0.075-1.604,0.296-3.063,0.72-4.319
			c0-0.08,0-0.16,0-0.24c0.008-4.812,0.543-9.63,1.92-13.68c0-0.08,0-0.16,0-0.24c1.273-4.967,3.426-9.054,3.12-15.601
			c0-0.079,0-0.159,0-0.239c-0.404-1.996-0.496-4.305-0.48-6.721c0-0.08,0-0.159,0-0.239c-0.554-0.727-0.084-2.476-0.24-3.601
			c0-0.08,0-0.16,0-0.24c-0.547-0.332-0.09-1.67-0.24-2.399c0-0.08,0-0.16,0-0.24c-0.556-1.123-0.083-3.277-0.24-4.8
			c-0.4-1.604-0.4-4.877,0-6.48c0.049-3.871,0.145-7.695,0.72-11.04c0-0.08,0-0.159,0-0.239c0.15-0.729-0.307-2.067,0.24-2.4
			c0-0.08,0-0.16,0-0.24c0.029-1.65,0.108-3.251,0.48-4.56c0-0.08,0-0.16,0-0.24c0.062-2.498,0.258-4.861,0.72-6.96
			c0.333-1.347,0.479-2.882,0.48-4.56c0.654-7.026,1.111-14.25,1.92-21.12c0-0.08,0-0.16,0-0.24c0.151-0.729-0.307-2.067,0.24-2.4
			c0-0.08,0-0.16,0-0.24c0-0.72,0-1.439,0-2.159c0.441-1.642-1.673-0.728-2.64-0.96c-2.725-0.166-4.905,0.215-7.2,0.479
			c-0.887,0.399-3.433,0.399-4.32,0c-1.2,0-2.4,0-3.6,0c-0.808-0.151-2.229,0.31-2.64-0.24c-1.12,0-2.24,0-3.36,0
			c-0.729-0.15-2.067,0.308-2.4-0.239c-0.96,0-1.92,0-2.88,0c-0.729-0.151-2.067,0.307-2.4-0.24c-0.96,0-1.92,0-2.88,0
			c-0.808-0.152-2.229,0.309-2.64-0.24c-3.92,0-7.84,0-11.76,0c-0.08,0-0.16,0-0.24,0c-1.789,0.371-3.9,0.42-6,0.48
			c-0.08,0-0.16,0-0.24,0c-1.308,0.371-2.909,0.45-4.56,0.479c-0.454,0.426-1.585,0.175-2.4,0.24c-1.064,0.376-2.41,0.47-3.84,0.479
			c-0.08,0-0.16,0-0.24,0c-4.215,0.745-8.751,1.169-13.44,1.44c-0.08,0-0.16,0-0.24,0c-4.26,0.62-8.618,1.143-13.2,1.44
			c-0.08,0-0.16,0-0.24,0c-2.058,0.502-4.462,0.657-6.72,0.96c-0.349,0.371-1.229,0.211-1.92,0.239
			c-0.014,0.146-0.036,0.284-0.24,0.24c-0.698,0.423-1.693,0.547-2.64,0.72c-1.134,0.226-2.759-0.039-3.36,0.721
			c0.353,0.349,0.353,1.57,0,1.92c0.04,1.319-0.443,2.116-0.96,2.88c-0.116,1.004-0.377,1.862-0.72,2.64
			c-0.485,2.074-0.811,4.31-1.68,6c0,0.08,0,0.16,0,0.24c-0.173,0.947-0.298,1.942-0.72,2.64c0,0.08,0,0.16,0,0.24
			c-0.088,1.832-0.872,2.969-0.96,4.8c-0.19,1.25-0.611,2.269-1.2,3.12c-1.708,3.572-2.788,7.772-4.32,11.521
			c-0.3,1.779-0.745,3.415-1.2,5.04c-0.017,0.943-0.116,1.804-0.48,2.399c0,0.08,0,0.16,0,0.24c-0.163,1.838-0.283,3.718-0.72,5.28
			c0,0.08,0,0.16,0,0.24c0.168,1.048-0.408,1.352-0.24,2.399c0,0.08,0,0.16,0,0.24c0.622,2.579,1.365,5.035,1.44,8.16
			c0.426,0.454,0.175,1.585,0.24,2.399c1.096,2.584,0.966,6.395,1.2,9.841c0,0.08,0,0.159,0,0.239
			c0.368,1.072,0.411,2.469,0.48,3.84c0,0.08,0,0.16,0,0.24c0.625,1.935,0.685,4.436,1.44,6.24c0.787,1.853,1.489,3.791,2.16,5.76
			c0.381,1.939,1.203,3.437,1.92,5.04c1.631,3.169,3.571,6.029,4.56,9.84c0.633,2.698,0.445,6.021,0,8.881c0,0.079,0,0.159,0,0.239
			c0,2.16,0,4.32,0,6.48c0,0.08,0,0.16,0,0.24c0.417,1.743,0.538,3.781,0.96,5.52c0,0.08,0,0.16,0,0.24
			c0.337,0.063,0.273,0.526,0.48,0.72c0.881,1.999,3.503,2.257,3.36,5.28c0,0.32,0,0.64,0,0.96c0,0.08,0,0.16,0,0.24
			c0.398,0.649,0.398,2.95,0,3.6c0,0.08,0,0.16,0,0.24c0.03,1.63-0.021,3.178-0.48,4.32c0,0.079,0,0.159,0,0.239
			c-0.072,1.849-0.293,3.547-0.72,5.04c0.268,1.708-0.813,2.067-0.48,3.84c0.556,1.044,0.083,3.117,0.24,4.561
			c-0.157,1.442,0.316,3.516-0.24,4.56c-0.327,0.634-0.284,1.637-0.48,2.4c1-1.64-0.042,1.428-0.48,1.92c-0.08,0-0.16,0-0.24,0
			c0,0.08,0,0.16,0,0.24c-0.49,2.548-4.118,3.431-2.64,6.479c0,0.08,0,0.16,0,0.24c6.002,10.879,15.026,18.734,22.32,28.32
			c-4.446-3.714-8.792-7.528-12.96-11.521c-0.16,0-0.32,0-0.48,0c-2.595-0.068-4.655-4.81-5.52-4.08
			c0.542,4.179,2.079,7.361,3.6,10.561c0.782,0.978,1.175,2.345,1.68,3.6c-0.017,1.217,0.849,1.552,0.96,2.641
			c0,0.08,0,0.16,0,0.239c0.645,0.715,0.93,1.791,1.44,2.641c0.614,0.515,1.03,2.206,0.48,3.12
			c-3.523-2.717-5.326-7.154-8.16-10.561c0-0.08,0-0.16,0-0.24c-1.165-1.075-1.767-2.713-2.64-4.08
			c-0.708,0.492,0.228,2.628-0.48,3.12c0,0.16,0,0.32,0,0.48c-0.153,0.887,0.311,2.391-0.24,2.88c0,0.08,0,0.16,0,0.24
			c-0.153,0.887,0.311,2.391-0.24,2.88c0,0.16,0,0.32,0,0.479c-0.154,0.967,0.312,2.553-0.24,3.12c0,0.16,0,0.32,0,0.48
			c-0.156,1.204,0.314,3.034-0.24,3.84c0,0.32,0,0.64,0,0.96c-0.155,1.125,0.314,2.874-0.24,3.6
			c-0.622,5.459-1.079,11.081-2.16,16.08c-0.064,1.696-0.364,3.156-0.72,4.561c-0.878,6.723-1.644,13.556-2.64,20.16
			c0,0.08,0,0.16,0,0.24c-0.027,3.412-0.649,6.229-0.48,9.84c-0.156,1.204,0.314,3.034-0.24,3.84c0.193,3.153-0.704,5.216-0.48,8.4
			c0.547,0.332,0.09,1.67,0.24,2.399c-0.068,6.549,0.561,12.398,0.96,18.48c0,0.079,0,0.16,0,0.239c0.5,1.421,0.417,3.424,0.72,5.04
			c28.4,0.4,58.48,0.4,86.88,0c0-0.8,0-1.6,0-2.399c0.152-0.809-0.309-2.229,0.24-2.641c-0.006-3.285,0.086-6.474,0.48-9.359
			c0-0.08,0-0.16,0-0.24c0.24-6.88,0.984-13.256,1.92-19.44c0.402-0.397,0.191-1.409,0.24-2.159c0.179-2.862,0.477-5.604,0.96-8.16
			c0-0.08,0-0.16,0-0.24c0.341-6.699,1.156-12.925,0.96-20.16c0.152-0.808-0.31-2.229,0.24-2.64c0.157-1.443-0.316-3.517,0.24-4.561
			c0-0.08,0-0.16,0-0.24c0.046-3.873,0.106-7.732,0.48-11.279c0-0.08,0-0.16,0-0.24c-0.024-3.624,0.051-7.149,0.48-10.32
			c0-0.08,0-0.16,0-0.239c0.157-1.443-0.316-3.517,0.24-4.561c0-0.08,0-0.16,0-0.24c0.159-3.04-0.319-6.719,0.24-9.359
			c0-0.08,0-0.16,0-0.24c0.011-2.789-0.129-5.729,0.48-7.92c0-0.08,0-0.16,0-0.24c0.211-3.469,0.643-6.718,1.44-9.6
			c0-0.08,0-0.16,0-0.24c0.122-1.398,0.253-2.786,0.72-3.84c0-0.08,0-0.16,0-0.24c1.059-6.382,1.719-13.162,2.4-19.92
			C254.641,654.445,254.641,654.365,254.641,654.285z M249.36,584.205c-0.08,0-0.16,0-0.24,0c-4.984,2.456-10.75,4.13-16.32,6
			c-1.931,0.389-3.55,1.09-5.28,1.68c-1.288,0.632-2.73,1.11-4.32,1.44c-1.419,0.101-2.18,0.859-3.6,0.96c-0.08,0-0.16,0-0.24,0
			c-2.814,1.187-5.979,2.021-9.12,2.88c-0.08,0-0.16,0-0.24,0c-2.068,1.053-4.796,1.444-6.96,2.4
			c-0.805,0.475-1.771,0.789-2.88,0.96c-2.297,0.422-4.139,1.301-6.48,1.68c-0.75,0.109-0.767-0.513-0.72-1.2
			c0.929-0.511,1.953-0.927,3.12-1.2c0.949-0.011,1.392-0.528,2.16-0.72c2.362-1.077,4.971-1.909,7.68-2.64
			c0.734,0.174,0.706-0.414,1.44-0.24c0.08,0,0.16,0,0.24,0c1.047-0.553,2.25-0.95,3.6-1.2c0.08,0,0.16,0,0.24,0
			c0.839-0.601,2.18-0.699,3.12-1.2c0.804-0.475,1.771-0.789,2.88-0.96c0.744,0.024,1.146-0.293,1.68-0.479
			c1.047-0.554,2.25-0.95,3.6-1.2c3.61-0.949,6.91-2.21,10.32-3.36c0.766-0.674,2.056-0.824,3.12-1.199c0.08,0,0.16,0,0.24,0
			c1.548-0.853,3.39-1.411,5.28-1.92c-0.075-0.326-0.601-0.199-0.96-0.24c-0.08,0-0.16,0-0.24,0c-0.596,0.363-1.457,0.463-2.4,0.479
			c-2.557,0.083-4.46,0.82-6.96,0.96c-0.31,0.331-1.042,0.238-1.68,0.24c-2.137,0.583-4.535,0.905-6.96,1.2
			c-0.31,0.33-1.042,0.237-1.68,0.24c-1.523,0.557-3.413,0.746-5.28,0.96c-0.08,0-0.16,0-0.24,0c-0.596,0.363-1.457,0.463-2.4,0.479
			c-0.08,0-0.16,0-0.24,0c-0.935,0.425-2.149,0.571-3.36,0.72c-0.284,0.276-0.848,0.272-1.44,0.24
			c-2.283,0.757-4.884,1.196-7.44,1.68c-0.08,0-0.16,0-0.24,0c-0.036,0.444-0.813,0.147-0.96,0.48
			c-2.044,0.958-1.596-2.451,0.48-1.92c1.185-0.016,1.695-0.705,2.88-0.72c0.08,0,0.16,0,0.24,0c3.151-1.169,6.799-1.841,10.56-2.4
			c0.08,0,0.16,0,0.24,0c0.596-0.364,1.457-0.463,2.4-0.479c0.08,0,0.16,0,0.24,0c1.523-0.557,3.413-0.747,5.28-0.961
			c0.08,0,0.16,0,0.24,0c3.043-0.796,6.397-1.283,9.84-1.68c0.309-0.33,1.042-0.237,1.68-0.24c2.373-0.667,5.028-1.052,7.68-1.439
			c0.08,0,0.16,0,0.24,0c0.062-0.498,1.055-0.065,1.2-0.48c0.873-0.345,2.697-0.797,4.32-1.199c1.099-0.273,3.957-1.09,2.64-0.24
			c1.466,0.025,1.782-1.098,3.36-0.96c1.74-0.74,3.398-1.563,6-1.44c0.4,0,0.8,0,1.2,0c-0.609,0.671-1.053,1.508-1.92,1.92
			c-1.132-0.012-1.465,0.774-2.64,0.721c-0.08,0-0.16,0-0.24,0c-0.176,0.224-0.437,0.363-0.72,0.479
			C253.212,582.616,251.314,583.438,249.36,584.205z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M154.32,685.965c0.405-1.524-2.097-0.144-2.4-0.96
			c-2.309,0.627-3.222-2.022-1.2-2.399c0.885-0.396,1.692-0.868,3.12-0.721c0.933,0.054,1.661-0.099,1.44-1.199
			c0.018-1.742,0.436-3.084,1.44-3.84c0-0.08,0-0.16,0-0.24c-0.872-3.514,0.317-7.042,1.44-9.36c0-0.08,0-0.16,0-0.24
			c0.257-1.263,0.544-2.495,1.2-3.359c0-0.08,0-0.16,0-0.24c0-0.16,0-0.32,0-0.48c-0.767-0.513-2.031-0.528-3.36-0.479
			c-0.08,0-0.16,0-0.24,0c-0.333,0.547-1.67,0.09-2.4,0.24c-1.46-0.181-2.165,0.395-3.36,0.479c-0.08,0-0.16,0-0.24,0
			c-0.676,0.444-1.497,0.743-2.4,0.96c-0.08,0-0.16,0-0.24,0c-1.276,0.884-2.943,1.377-4.56,1.92c-0.08,0-0.16,0-0.24,0
			c-0.757,0.603-1.833,0.887-2.88,1.2c-0.663,0.297-1.219,0.701-2.16,0.72c-1.934,1.187-4.711,1.529-7.2,2.16
			c-0.259,0.397-2.141,0.397-2.4,0c-0.08,0-0.16,0-0.24,0c-0.574-0.146-1.742,0.302-1.92-0.24c1.091-0.349,2.036-0.844,3.12-1.199
			c2.985-0.535,5.412-1.629,8.16-2.4c0.663-0.297,1.219-0.701,2.16-0.72c0.214-0.426,0.974-0.306,0.72-1.2
			c0.748-1.652,2.311-2.489,4.08-3.12c0.949-0.011,1.392-0.528,2.16-0.72c0.536-0.584,1.567-0.674,2.4-0.96
			c1.093-0.107,1.783-0.617,2.64-0.96c0.864-0.656,2.097-0.943,3.36-1.2c0.08,0,0.16,0,0.24,0c1.33-0.591,2.75-1.09,4.56-1.2
			c0.159-2.081-0.318-4.798,0.24-6.479c0-0.08,0-0.16,0-0.24c0-1.521,0-3.04,0-4.561c-0.489-0.551-1.993-0.087-2.88-0.239
			c-0.815,0.064-1.946-0.187-2.4,0.239c-0.333,0.548-1.67,0.09-2.4,0.24c-6.528-0.752-15.285,0.726-19.92-1.92
			c0.466-0.733,1.434-0.966,2.64-0.96c0.08,0,0.16,0,0.24,0c0.806-0.555,2.636-0.084,3.84-0.24c2.4,0,4.8,0,7.2,0
			c1.473-0.127,3.475,0.274,4.56-0.24c1.667-2.652,3.746-4.895,7.2-5.76c-1.314-2.907-1.819-7.795-3.36-11.279
			c-0.54-1.222-1.75-3.995-1.44-5.761c0.232-1.323,1.896-1.747,1.2-3.359c0.141-1.342-0.55-1.851-0.48-3.12
			c-0.238-0.643-0.495-1.266-0.48-2.16c-1.285-3.835-2.422-7.818-3.36-12c0-0.08,0-0.16,0-0.24c-0.704-1.455-1.073-3.247-1.44-5.04
			c-0.257-1.022-0.773-1.787-0.72-3.12c-1.298-4.541-2.588-9.092-3.36-14.16c0-0.08,0-0.159,0-0.239
			c-0.369-0.751-0.466-1.774-0.48-2.881c0-0.079,0-0.159,0-0.239c-0.408-1.192-0.501-2.699-0.48-4.32c0-0.08,0-0.16,0-0.24
			c-0.553-0.646-0.085-2.314-0.24-3.359c-0.4-2.162-0.4-5.998,0-8.16c0-0.08,0-0.16,0-0.24c-0.18-3.061,0.85-4.91,0.72-7.92
			c0-0.08,0-0.16,0-0.24c-0.549-0.41-0.088-1.832-0.24-2.64c0-0.08,0-0.16,0-0.24c-0.553-0.647-0.085-2.314-0.24-3.36
			c0-0.08,0-0.159,0-0.239c-0.556-1.124-0.083-3.277-0.24-4.801c0-0.159,0-0.319,0-0.479c-0.4-2.402-0.4-6.479,0-8.88
			c0-0.16,0-0.32,0-0.48c0.157-1.442-0.316-3.516,0.24-4.56c0-0.08,0-0.16,0-0.24c0.153-0.887-0.311-2.391,0.24-2.88
			c0-0.08,0-0.16,0-0.24c-0.023-1.383-0.034-2.754,0.48-3.6c0.216-0.904,0.071-2.169,0.48-2.88c0.259-0.701,0.537-1.383,0.48-2.4
			c0.135-1.065,0.069-2.331,1.2-2.4c0.408,2.072,0.186,4.773,0.24,7.2c-0.155,1.125,0.314,2.874-0.24,3.601c0,0.159,0,0.319,0,0.479
			c-0.156,1.125,0.313,2.874-0.24,3.601c0,0.079,0,0.159,0,0.239c-0.157,1.522,0.316,3.677-0.24,4.8c0,0.24,0,0.48,0,0.721
			c0,2.56,0,5.12,0,7.68c0,0.08,0,0.16,0,0.24c0.241-0.001,0.286,0.194,0.48,0.24c1.199-5.361,1.216-11.904,2.64-17.04
			c0.276-0.284,0.272-0.849,0.24-1.44c0.017-0.943,0.116-1.804,0.48-2.4c0-0.08,0-0.159,0-0.239c0.178-2.72,0.935-6.081,0.24-8.88
			c-1.286-1.057,1.54-3.528,1.44-1.2c0.01,1.27-0.148,2.708,0.72,3.12c0.52-0.681,0.121-2.28,0.72-2.881c0-0.079,0-0.159,0-0.239
			c0-0.641,0-1.28,0-1.92c-1.204-0.156-3.035,0.314-3.84-0.24c-0.08,0-0.16,0-0.24,0c-3.345-0.095-6.436-0.444-9.36-0.96
			c-0.08,0-0.16,0-0.24,0c-8.356-0.197-16.097,0.223-23.52,0.96c-0.08,0-0.16,0-0.24,0c-0.907,0.373-2.092,0.468-3.36,0.479
			c-0.08,0-0.16,0-0.24,0c-0.907,0.373-2.092,0.469-3.36,0.48c-0.08,0-0.16,0-0.24,0c-1.42,0.5-3.424,0.416-5.04,0.72
			c0.048,0.765,0.625,2.238,0,2.88c0,0.08,0,0.16,0,0.24c0.014,1.055-0.097,1.983-0.48,2.64c0.032,0.592,0.036,1.157-0.24,1.44
			c-0.297,1.782-0.514,3.646-0.96,5.28c-0.065,0.814,0.186,1.945-0.24,2.399c0,1.601,0,3.2,0,4.801
			c0.549,0.41,0.088,1.832,0.24,2.64c-0.179,1.539,0.28,2.439,0.24,3.84c0.398,0.649,0.398,2.95,0,3.601
			c-0.252,2.147-0.1,4.7-0.72,6.479c0,0.08,0,0.16,0,0.24c-0.071,1.209-0.186,2.374-0.72,3.12c0,0.08,0,0.16,0,0.239
			c0.074,0.955-0.555,1.206-0.48,2.16c-1.087,3.873-3.758,6.162-5.76,9.12c-2.32,4.049-3.611,10.414-2.4,16.561
			c0,0.08,0,0.16,0,0.239c0.64,1.04,0.277,3.084,0.72,4.32c0.628,2.55-0.049,5.869-1.92,6.72c-0.819,0.071-1.121-2.568-1.44-1.439
			c0,0.08,0,0.16,0,0.24c0,0.64,0,1.279,0,1.92c-0.157,1.363,0.316,3.355-0.24,4.319c0,0.08,0,0.16,0,0.24
			c-0.158,1.683,0.317,3.997-0.24,5.28c0,0.56,0,1.12,0,1.68c0,1.76,0,3.521,0,5.28c0,1.84,0,3.68,0,5.52
			c0.522,2.046,0.355,5.474-0.48,6.96c-0.131,0.35,0.28,1.241-0.24,1.2c0,0.08,0,0.16,0,0.24c-0.04,1.32-0.113,2.607-0.48,3.6
			c0,0.08,0,0.16,0,0.24c-0.175,2.415,0.394,4.087,0.24,6.48c0,0.08,0,0.16,0,0.239c0.521-0.04,0.109,0.852,0.24,1.2
			c0.553,0.647,0.085,2.315,0.24,3.36c0,0.4,0,0.8,0,1.2c0.554,0.726,0.084,2.476,0.24,3.6c0,0.48,0,0.96,0,1.44
			c0.555,0.885,0.083,2.796,0.24,4.08c0,0.64,0,1.279,0,1.92c0.4,4.96,0.4,11.6,0,16.56c0,1.28,0,2.561,0,3.84
			c-0.153,0.888,0.311,2.392-0.24,2.88c-0.218,0.662-0.075,1.686-0.48,2.16c0,0.08,0,0.16,0,0.24c0,0.32,0,0.641,0,0.96
			c-0.582,2.558-1.552,5.415-0.24,7.92c-0.403,0.957-0.789,1.932-1.44,2.641c0,0.079,0,0.16,0,0.239c-0.06,0.74-0.864,0.736-1.2,1.2
			c0.642,0.159,1.5,0.101,1.92,0.48c0.4,0,0.8,0,1.2,0c1.318,0.602,3.175,0.665,4.56,1.2c2.132,0.507,4.759,0.521,6.72,1.199
			c0.08,0,0.16,0,0.24,0c5.932,0.789,11.692,1.749,17.28,2.88c0.08,0,0.16,0,0.24,0c0.48,0,0.96,0,1.44,0
			c2.534,0.187,4.96,0.48,7.2,0.96c0.32,0,0.64,0,0.96,0c1.6,0,3.2,0,4.8,0c4.423-0.057,8.305-0.654,12.24-1.199
			c1.991-0.211,4.466-2.085,6.24-0.96c-0.29,2.27-2.7,2.42-4.8,2.88c-1.789,0.051-3.12,0.561-5.04,0.479
			c-0.454,0.426-1.585,0.175-2.4,0.24c-1.29,0.391-2.872,0.488-4.56,0.48c-0.08,0-0.16,0-0.24,0c-0.044,0.355-0.58,0.22-0.96,0.239
			c-1.364,0.399-4.396,0.399-5.76,0c-0.359-0.041-0.886,0.086-0.96-0.239c-0.08,0-0.16,0-0.24,0c-1.62,0.179-2.494-0.387-4.08-0.24
			c-1.211,0.012-2.306-0.094-3.12-0.48c-0.814-0.465-2.361-0.199-3.12-0.72c-0.31-0.331-1.042-0.237-1.68-0.24
			c-2.008-0.151-3.911-0.409-5.52-0.96c-0.309-0.33-1.042-0.237-1.68-0.24c-1.467-0.133-2.907-0.292-4.08-0.72
			c-0.08,0-0.16,0-0.24,0c-2.089-0.15-4.017-0.463-5.76-0.96c-0.08,0-0.16,0-0.24,0c-1.26,0.141-1.817-0.423-3.12-0.24
			c-0.965,0.165-1.37-0.229-2.16-0.239c0.303,1.457,0.997,2.522,1.2,4.08c1.159,0.521,2.658,0.702,3.6,1.439
			c1.088,0.512,2.22,0.98,3.6,1.2c1.87,0.61,3.851,1.108,5.52,1.92c0.847,0.272,1.496,0.744,2.64,0.72
			c0.605,0.076,2.998,0.514,1.92,0.721c1.974,0.186,3.224,1.096,5.28,1.199c-0.053-0.187-0.201-0.278-0.48-0.239
			c-0.24,0-0.48,0-0.72,0c0.977-0.489,2.863,0.17,3.84,0.479c0.08,0,0.16,0,0.24,0c1.054-0.014,1.983,0.097,2.64,0.48
			c0.481,0.398,1.78-0.021,2.16,0.479c0.08,0,0.16,0,0.24,0c3.125,0.075,5.68,0.72,8.64,0.96c0.584,0.296,1.558,0.202,1.68,0.96
			c-0.554,0.326-1.07,0.69-1.92,0.72c-3.997,0.077-7.609-0.23-10.8-0.96c-0.08,0-0.16,0-0.24,0c-0.48,0-0.96,0-1.44,0
			c-2.211-0.188-4.352-0.448-6-1.199c-1.136-0.545-2.86-0.5-3.84-1.2c-0.08,0-0.16,0-0.24,0c-1.442-0.238-2.762-0.598-3.84-1.2
			c-0.08,0-0.16,0-0.24,0c-1.657-0.183-2.663-1.017-4.32-1.2c-0.833-0.286-1.864-0.375-2.4-0.96c-0.324-0.075-0.458-0.342-0.96-0.24
			c-2.825-1.418-4.453-0.979-7.2-0.72c-1.011-0.349-1.906-0.813-3.12-0.96c-0.678,0.198-0.622-0.338-1.2-0.24
			c0.683,2.358,2.62,3.461,3.12,6c0,0.32,0,0.641,0,0.96c-0.782,1.184,0.706,0.887,1.2,1.44c0.241-0.001,0.286,0.194,0.48,0.24
			c-0.569,1.191-1.902,1.617-3.84,1.439c-0.08,0-0.16,0-0.24,0c-0.952,0.488-2.733,0.147-3.6,0.721
			c-1.564,1.075-3.147,2.133-4.56,3.359c0.032,2.288,0.833,3.807,0.72,6.24c0.402,0.398,0.191,1.409,0.24,2.16
			c0.398,0.414,0.398,2.466,0,2.88c0,0.08,0,0.16,0,0.24c0.141,1.341-0.55,1.85-0.48,3.12c-0.449,2.431-1.931,3.828-3.6,5.04
			c-0.515,1.885-0.516,4.283-1.2,6c0,0.079,0,0.16,0,0.239c-0.326,2.715-0.897,5.183-1.68,7.44c0,0.08,0,0.16,0,0.24
			c-0.566,3.434-1.876,6.123-2.64,9.359c-0.376,1.064-0.525,2.354-1.2,3.12c-0.362,0.438-0.255,1.346-0.72,1.681
			c-0.269,0.771-0.657,1.423-0.72,2.399c0.08,0,0.16,0,0.24,0c0.025-0.614,0.136-1.144,0.48-1.439
			c-0.17,1.029-0.392,2.008-0.96,2.64c-0.748,1.012-0.605,2.914-1.2,4.08c0,0.08,0,0.16,0,0.24c-0.139,2.341-0.283,4.677-0.72,6.72
			c0,0.08,0,0.16,0,0.24c-0.052,1.708-0.11,3.409-0.48,4.8c0,0.08,0,0.16,0,0.24c-0.152,0.808,0.309,2.229-0.24,2.64
			c0,0.08,0,0.16,0,0.24c0,1.439,0,2.88,0,4.319c-0.16,4.721,0.32,10.08-0.24,14.4c-0.167,2.153-0.055,4.586-0.72,6.24
			c0,0.08,0,0.16,0,0.24c-0.119,1.4-0.871,2.169-0.72,3.84c0.027,0.316-0.028,0.637,0.24,0.72c2.27,0.703,6.682,0,10.08,0
			c23.611,0,48.146,0,70.56,0c1.08-6.761,2.063-13.616,3.12-20.399c0-0.08,0-0.16,0-0.24c0.485-5.675,0.837-11.482,1.68-16.801
			c0-0.079,0-0.159,0-0.239c0.052-2.349,0.341-4.459,0.72-6.48c0-0.08,0-0.16,0-0.24c-0.007-2.167,0.088-4.231,0.48-6
			c0-0.079,0-0.159,0-0.239c0.096-2.704,0.56-5.041,1.2-7.2c0.276-0.284,0.271-0.849,0.24-1.44c0.057-0.983,0.118-1.962,0.48-2.64
			c0.371-0.349,0.211-1.229,0.24-1.92c0.069-1.371,0.112-2.769,0.48-3.84c0-0.08,0-0.16,0-0.24c0-0.56,0-1.12,0-1.68
			c0.04-1.321,0.112-2.608,0.48-3.601c0-0.08,0-0.16,0-0.24c0.224-1.909-0.534-3.362,0.24-4.56c0.331-0.31,0.237-1.042,0.24-1.68
			c0.017-0.943,0.116-1.805,0.48-2.4c0.445-0.516,0.162-1.758,0.24-2.64c0.158-1.922-0.318-4.479,0.24-6c0-0.16,0-0.32,0-0.48
			c0.158-1.603-0.316-3.837,0.24-5.04c0-0.16,0-0.319,0-0.479c0.156-1.204-0.314-3.035,0.24-3.84c0-0.24,0-0.48,0-0.721
			c0.155-1.045-0.313-2.713,0.24-3.359c0-0.08,0-0.16,0-0.24c0.153-0.887-0.311-2.391,0.24-2.88c0-0.08,0-0.16,0-0.24
			c0.152-0.808-0.309-2.229,0.24-2.64c0-0.08,0-0.16,0-0.24c0.15-0.729-0.307-2.067,0.24-2.4
			C153.959,690.244,154.243,688.208,154.32,685.965z M146.88,674.925c-1.76,0-3.52,0-5.28,0c-0.647,0.554-2.314,0.086-3.36,0.24
			c-1.169,0.11-2.811-0.251-3.6,0.24c-0.568,0.552-2.154,0.086-3.12,0.24c-1.2,0-2.4,0-3.6,0c-0.08,0-0.16,0-0.24,0
			c-0.179,0.541-1.346,0.094-1.92,0.239c-0.489,0.551-1.993,0.087-2.88,0.24c-0.72,0-1.44,0-2.16,0
			c-0.411,0.55-1.832,0.088-2.64,0.24c-0.56,0-1.12,0-1.68,0c-0.333,0.547-1.671,0.09-2.4,0.24c-0.48,0-0.96,0-1.44,0
			c-0.333,0.547-1.67,0.089-2.4,0.24c-0.48,0-0.96,0-1.44,0c-0.411,0.549-1.832,0.088-2.64,0.239c-0.56,0-1.12,0-1.68,0
			c-0.647,0.554-2.315,0.085-3.36,0.24c-0.48,0-0.96,0-1.44,0c-1.339,0.454-4.16,0.323-5.52,0c-0.221-0.604-0.46-1.646,0.48-1.68
			c3.421-0.259,6.695-0.665,10.32-0.721c0.08,0,0.16,0,0.24,0c0.647-0.553,2.315-0.085,3.36-0.239c0.08,0,0.16,0,0.24,0
			c0.647-0.554,2.315-0.086,3.36-0.24c0.08,0,0.16,0,0.24,0c0.647-0.553,2.314-0.085,3.36-0.24c0.08,0,0.16,0,0.24,0
			c5.154-0.526,10.597-0.763,16.08-0.96c0.08,0,0.16,0,0.24,0c1.766-0.395,4.007-0.313,6-0.479c0.333-0.548,1.67-0.09,2.4-0.24
			c2.403-0.083,4.585,0.055,6.72,0.24c0.73,0.149,1.227,0.532,1.2,1.439C148.176,674.62,147.834,675.079,146.88,674.925z
			 M143.28,702.045c-0.16,0-0.32,0-0.48,0c-0.08,0-0.16,0-0.24,0c-1.72,1.08-3.734,1.866-6,2.4c-0.08,0-0.16,0-0.24,0
			c-3.189,1.291-7.208,1.752-11.76,1.68c-1.683,0.399-5.037,0.399-6.72,0c-2,0-4,0-6,0c-0.571,0.398-2.79,0.398-3.36,0
			c-0.16,0-0.32,0-0.48,0c-2.082-0.158-4.798,0.318-6.48-0.24c-0.24,0-0.48,0-0.72,0c-1.363-0.156-3.356,0.316-4.32-0.239
			c-1.29,0.196-1.966-0.486-2.88,0c-0.899-0.875,2.619-1.34,4.08-1.44c0.882-0.078,2.125,0.205,2.64-0.24
			c0.726-0.554,2.475-0.084,3.6-0.239c0.8,0,1.6,0,2.4,0c2.162-0.4,5.998-0.4,8.16,0c4.24,0,8.48,0,12.72,0c0.08,0,0.16,0,0.24,0
			c1.122-0.398,2.742-0.298,4.08-0.48c0.08,0,0.16,0,0.24,0c3.238-0.922,6.665-1.654,9.36-3.12c0.985-0.694,2.106-1.254,3.36-1.68
			c0.387,0.067,0.413-0.227,0.72-0.24c0.876-0.724,2.109-1.091,3.12-1.68c0.08,0,0.16,0,0.24,0
			C153.884,697.059,145.332,701.553,143.28,702.045z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M542.881,730.125c0-0.72,0-1.439,0-2.16c0-0.319,0-0.64,0-0.96
			c0-0.88,0-1.76,0-2.64c-0.033-2.366-0.106-4.693-0.479-6.72c-1.074-10.687-0.964-22.558-5.521-29.761
			c-4.895-27.67-11.031-54.303-14.399-84c0.117-0.757-0.433-0.848-0.24-1.68c-0.233-0.007-0.187-0.294-0.24-0.48
			c-0.545-0.255-0.092-1.508-0.24-2.159c0-0.48,0-0.96,0-1.44c0.175-0.734-0.414-0.706-0.239-1.439c0-0.721,0-1.44,0-2.16
			c0.079,0,0.159,0,0.239,0c0-0.4,0-0.801,0-1.2c0-1.84,0-3.68,0-5.521c0-0.8,0-1.6,0-2.399c-0.153-0.887,0.312-2.391-0.239-2.88
			c-0.745-6.615-2.606-12.114-3.12-18.96c0.563-2.538-0.263-3.125-0.96-4.801c-3.07-22.53-6.02-45.181-9.601-67.2
			c-2.606-1.563-6.41-2.35-10.56-1.68c-0.813,0.067-1.379,0.382-2.4,0.24c-0.08,0-0.16,0-0.24,0c-1.021,0.419-2.239,0.641-3.6,0.72
			c-0.284,0.276-0.849,0.271-1.44,0.24c-0.717,0.482-1.898,0.502-2.88,0.72c-0.08,0-0.16,0-0.24,0
			c-2.704,1.376-6.619,1.541-10.8,1.44c-2.561,0.559-6.159,0.08-9.12,0.239c-5.141-0.181-9.692,0.229-14.399,0.48
			c-0.08,0-0.16,0-0.24,0c-1.309,0.372-2.909,0.45-4.56,0.48c-0.08,0-0.16,0-0.24,0c-8.407,1.223-17.419,2.471-27.12,1.68
			c-0.887-0.153-2.391,0.311-2.88-0.24c-0.307-0.013-0.333-0.308-0.721-0.24c-0.91,3.17-0.614,7.546-1.439,10.801
			c0,0.08,0,0.159,0,0.239c0,0.721,0,1.44,0,2.16c-0.155,1.045,0.313,2.713-0.24,3.36c0,3.279,0,6.56,0,9.84
			c0.559,2.401,0.081,5.839,0.24,8.64c0,2.48,0,4.96,0,7.44c0.685,1.015-0.064,2.648,0,3.84c0.275,2.685,1.106,4.813,1.439,7.44
			c0,0.08,0,0.159,0,0.239c0.492,0.469,0.309,1.611,0.721,2.16c0.431,0.85,0.921,1.639,1.199,2.641c0.521,0.04,0.109-0.852,0.24-1.2
			c0-0.24,0-0.48,0-0.72c-0.547-0.333-0.089-1.671-0.24-2.4c0-0.32,0-0.64,0-0.96c-0.554-0.806-0.084-2.636-0.239-3.84
			c0-0.96,0-1.92,0-2.88c0.159-2.642-0.319-5.919,0.239-8.16c0.001-0.4,0-0.801,0-1.2c0.152-0.808-0.309-2.229,0.24-2.64
			c0.038-0.283-0.041-0.682,0.24-0.721c0-0.08,0-0.16,0-0.239c0.15-0.729-0.308-2.067,0.24-2.4c0-0.08,0-0.16,0-0.24
			c-0.029-1.148,0.068-2.172,0.479-2.88c0-0.08,0-0.16,0-0.24c-0.157-1.678,0.878-2.162,0.721-3.84
			c0.139-1.792,2.068,0.138,0.96,0.96c0.108,4.349-0.829,7.651-0.721,12c-0.154,1.046,0.313,2.713-0.239,3.36c0,1.52,0,3.04,0,4.56
			c0,1.84,0,3.681,0,5.521c0,0.56,0,1.12,0,1.68c0.547,0.333,0.089,1.671,0.239,2.4c0.129,7.631,1.472,14.049,2.16,21.12
			c0,0.08,0,0.159,0,0.239c0.788,8.013,1.507,16.094,3.12,23.28c0,0.08,0,0.16,0,0.24c0.499,1.021,0.706,2.334,0.96,3.6
			c0.201,0.279,0.316,0.644,0.24,1.2c0.617,0.662,0.494,2.065,0.96,2.88c0.543,1.137,1.056,2.305,1.2,3.84c0,0.16,0,0.32,0,0.48
			c0.556,0.964,0.083,2.957,0.239,4.32c0,0.159,0,0.319,0,0.479c0.548,0.333,0.09,1.671,0.24,2.4c0.431,4.93,2.202,8.518,2.88,13.2
			c0.202,0.278,0.316,0.644,0.24,1.199c0.896,2.624,1.505,5.536,2.16,8.4c0,0.08,0,0.16,0,0.24c0.474,0.566,0.565,1.515,0.72,2.399
			c0,0.08,0,0.16,0,0.24c0.603,1.157,0.911,2.608,1.2,4.08c0,0.08,0,0.16,0,0.24c0.444,0.036,0.147,0.813,0.48,0.96
			c0.584,0.536,0.673,1.566,0.96,2.399c0.155,1.525,0.732,2.628,1.199,3.841c2.668,6.372,5.438,12.643,8.881,18.239
			c0,1.921,0,3.841,0,5.761c0,0.08,0,0.16,0,0.239c0.9,2.46,2.43,4.29,3.6,6.48c1.067-0.292,2.101-0.619,3.36-0.72
			c0.08,0,0.159,0,0.239,0c3.814-1.226,7.709-2.372,11.761-3.36c0.239,0,0.479,0,0.72,0c0.08,0,0.16,0,0.24,0
			c1.455-0.704,3.247-1.073,5.04-1.439c0.08,0,0.159,0,0.239,0c1.473-0.688,3.275-1.046,5.04-1.44c0.08,0,0.16,0,0.24,0
			c1.726-0.755,3.722-1.238,5.76-1.68c0.08,0,0.16,0,0.24,0c0.649-0.471,1.593-0.647,2.641-0.721
			c1.017,0.058,1.699-0.221,2.399-0.479c2.396-0.804,5.208-1.192,8.16-1.44c0.398-0.401,1.409-0.19,2.16-0.239
			c1.938-0.463,4.212-0.589,6.479-0.721c0.08,0,0.16,0,0.24,0c1.064-0.375,2.41-0.47,3.84-0.479c0.08,0,0.16,0,0.24,0
			c0.493-0.547,2.072-0.008,2.64-0.48c1.38-0.892,2.19,0.844,3.12,1.2c-3.04,0.888-10.984,1.494-15.84,1.92c-0.08,0-0.16,0-0.24,0
			c-0.992,0.367-2.279,0.44-3.6,0.48c-0.08,0-0.16,0-0.24,0c-4.807,0.952-9.628,1.892-14.16,3.119
			c-1.689,0.551-3.32,1.16-5.279,1.44c-2.313,0.968-5.053,1.508-7.681,2.16c-0.743-0.024-1.331,0.108-1.68,0.479
			c0.117-0.059,0.376,0.077,0.479,0c-1.351,0.995-4.86,1.447-6.96,2.16c-0.08,0-0.159,0-0.239,0c-0.296,0.345-0.826,0.455-1.44,0.48
			c0.255,0.545,1.509,0.091,2.16,0.24c0.24,0,0.479,0,0.72,0c1.096-0.585,3.406,0.046,4.561-0.48
			c1.107,0.147,1.381-0.539,2.399-0.48c0.08,0,0.16,0,0.24,0c1.455-0.704,3.247-1.072,5.04-1.439c0.08,0,0.16,0,0.24,0
			c2.676-1.004,5.648-1.711,8.64-2.4c0.08,0,0.16,0,0.24,0c0.566-0.474,1.515-0.565,2.399-0.72c1.115-0.646,3.206-0.314,4.32-0.96
			c1.685-0.665,6.115-1.148,6.24,1.2c-2.661,1.259-7.135,0.705-9.84,1.92c-0.08,0-0.16,0-0.24,0c-1.726,0.755-3.722,1.238-5.76,1.68
			c-0.08,0-0.16,0-0.24,0c-1.157,0.603-2.608,0.911-4.08,1.2c-0.08,0-0.16,0-0.24,0c-1.472,0.688-3.274,1.045-5.04,1.44
			c-0.08,0-0.159,0-0.239,0c-0.894,0.627-2.406,0.634-3.36,1.199c-2.149,0.971-4.61,1.63-6.96,2.4c-1.437,0.163-2.41,0.79-3.601,1.2
			c-0.494,0.705-1.915,0.484-2.64,0.96c0,0.72,0,1.439,0,2.16c0,0.159,0,0.319,0,0.479c0.688,1.812,0.042,4.359-0.72,5.521
			c0.906,1.334,1.222,3.258,2.88,3.84c0.08,0,0.16,0,0.24,0c4,0,8,0,12,0c1.439,0,2.88,0,4.319,0c0.08,0,0.16,0,0.24,0
			c3.76-0.48,7.752-0.729,11.76-0.96c0.08,0,0.16,0,0.24,0c1.15-0.37,2.594-0.446,4.08-0.48c0.08,0,0.16,0,0.24,0
			c0.835-0.365,1.967-0.434,3.12-0.479c0.309-0.331,1.042-0.237,1.68-0.24c0.596-0.364,1.457-0.464,2.4-0.48
			c1.017,0.058,1.699-0.22,2.399-0.479c0.698-0.423,1.693-0.547,2.641-0.72c0.08,0,0.159,0,0.239,0
			c1.116-0.564,2.471-0.89,3.841-1.2c0.08,0,0.159,0,0.239,0c2.106-0.854,4.215-1.706,6.721-2.16c0.08,0,0.159,0,0.239,0
			c0.655-0.58,2.29,0.527,2.88-0.48c0.588,0.027,0.052,0.794-0.239,0.721c-1.24,0.68-1.933,1.907-3.601,2.16
			c-0.08,0-0.159,0-0.239,0c-0.536,0.584-1.567,0.673-2.4,0.96c-1.912,0.328-3.368,1.111-5.28,1.439
			c-1.474,0.526-2.965,1.035-4.8,1.2c-1.285,0.555-2.876,0.805-4.56,0.96c-0.08,0-0.16,0-0.24,0c-1.879,0.602-4.106,0.854-6.48,0.96
			c-0.454,0.426-1.585,0.175-2.399,0.24c-1.449,0.392-3.192,0.488-5.04,0.479c-0.08,0-0.16,0-0.24,0
			c-1.079,0.441-2.84,0.2-4.08,0.48c-0.333,0.547-1.671,0.09-2.4,0.24c-0.479,0-0.96,0-1.439,0c-0.489,0.551-1.993,0.087-2.88,0.239
			c-0.48,0-0.96,0-1.44,0c-3.201,0.4-8.079,0.4-11.28,0c-0.239,0-0.479,0-0.72,0c-0.553,0.647-0.085,2.315-0.24,3.36
			c-0.157,1.683,0.317,3.997-0.239,5.28c0,0.72,0,1.439,0,2.16c0.029,0.036-0.038,0.214,0,0.239
			c3.107,2.164,9.208-1.566,12.479-1.439c0.24,0,0.48,0,0.72,0c0.08,0,0.16,0,0.24,0c1.566-0.834,3.614-1.187,5.28-1.92
			c0.536-0.585,1.566-0.674,2.4-0.96c1.781-0.219,2.884-1.116,4.56-1.44c0.08,0,0.16,0,0.24,0c3.712-1.728,7.717-3.163,11.76-4.56
			c0.663-0.297,1.22-0.701,2.16-0.721c0.988-0.69,2.348-1.012,3.6-1.439c0.08,0,0.16,0,0.24,0c2.86-1.3,5.699-2.621,9.12-3.36
			c0.08,0,0.16,0,0.24,0c0.04-0.279,0.379-0.261,0.72-0.24c0.742-0.021,0.715,0.726,0.72,1.44c-0.875,0.564-1.749,1.131-2.88,1.44
			c-0.949,0.011-1.392,0.528-2.16,0.72c-0.535,0.584-1.566,0.673-2.399,0.96c-0.418-0.019-0.747,0.054-0.96,0.24
			c-0.758,0.603-1.834,0.887-2.88,1.2c-0.679-0.199-0.622,0.338-1.2,0.239c-0.08,0-0.16,0-0.24,0
			c-2.471,1.369-5.347,2.333-8.16,3.36c-0.08,0-0.16,0-0.24,0c-1.773,1.026-3.92,1.68-6,2.4c-0.08,0-0.159,0-0.239,0
			c-1.351,0.89-3.241,1.238-4.801,1.92c-1.1,0.74-2.58,1.099-4.08,1.439c-0.733-0.174-0.705,0.414-1.439,0.24
			c-0.587,0.214-1.041,0.56-1.92,0.479c-0.348,0.533-1.429,0.332-1.92,0.721c-0.633,0.407-1.37,0.71-2.4,0.72c-0.08,0-0.16,0-0.24,0
			c-1.711,0.688-3.748,1.052-6,1.2c-0.08,0-0.159,0-0.239,0c-0.729,0.398-3.112,0.398-3.841,0c-2.384-0.516-2.054,3.685-2.88,5.52
			c0,0.08,0,0.16,0,0.24c-0.017,0.943-0.116,1.804-0.479,2.4c0,0.079,0,0.16,0,0.239c-0.444,3.636-0.954,7.206-1.681,10.561
			c-0.028,0.691,0.132,1.571-0.239,1.92c-0.202,2.198-0.408,4.393-0.96,6.24c0,0.08,0,0.16,0,0.24
			c-0.202,2.277-0.454,4.506-0.96,6.479c-0.064,1.937-0.351,3.649-0.721,5.28c-0.072,1.848-0.293,3.547-0.72,5.04
			c0,0.08,0,0.16,0,0.239c-0.383,3.857-0.638,7.843-1.2,11.521c0,0.08,0,0.16,0,0.24c-0.151,0.808,0.31,2.229-0.24,2.64
			c0,0.08,0,0.16,0,0.24c0.067,3.107-0.603,5.477-0.479,8.64c-0.153,0.887,0.311,2.391-0.24,2.88c0,0.4,0,0.801,0,1.2
			c-0.154,0.966,0.313,2.552-0.24,3.12c0,0.48,0,0.96,0,1.44c-0.157,1.603,0.317,3.836-0.239,5.04c0,0.479,0,0.96,0,1.439
			c0.397,0.336,0.397,2.304,0,2.641c0,0.159,0,0.319,0,0.479c0.063,0.702-0.543,0.737-0.48,1.44
			c25.967,1.115,53.767,0.162,80.16,0.479c4.205,0,8.411,0,12.615,0c0.001,0,0.001,0,0.002,0c0,0,0,0,0.001,0
			c0.22,0.012,0.435,0.021,0.644,0.029c0.045,0.002,0.087,0.003,0.132,0.004c0.159,0.005,0.314,0.009,0.465,0.011
			c0.056,0,0.109,0,0.164,0c0.14,0,0.273-0.002,0.402-0.006c0.046-0.001,0.094-0.002,0.138-0.004
			c0.166-0.008,0.323-0.018,0.465-0.034h0.001c0.351,0,0.701,0,1.052,0c0.276-0.283,0.271-0.849,0.24-1.439c0.08,0,0.16,0,0.24,0
			c-0.175-2.415,0.393-4.087,0.239-6.48c0.809-6.231,0.944-13.136,1.2-19.92c0-0.88,0-1.76,0-2.64c0-0.48,0-0.961,0-1.44
			c0-1.04,0-2.08,0-3.12c0.08,0,0.16,0,0.24,0c0-1.2,0-2.4,0-3.6c0-2.561,0-5.12,0-7.681c0-1.199,0-2.399,0-3.6
			c0-5.601,0-11.2,0-16.8c0-0.721,0-1.44,0-2.16c0-1.36,0-2.72,0-4.08c0-0.8,0-1.6,0-2.4
			C543.041,730.125,542.961,730.125,542.881,730.125z M533.041,805.245c0.898,0,2.021,0.081,3.131,0.156
			C535.062,805.326,533.939,805.246,533.041,805.245z"/>
			</g>
			<g id="skin">
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M290.881,250.604c-0.24,0-0.48,0-0.721,0
			c-0.08,0-0.159,0-0.239,0c-0.06,0.261-0.478,0.163-0.48,0.48c0.64,0,1.28,0,1.92,0
			C291.357,250.767,290.939,250.865,290.881,250.604z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M231.601,260.444c-0.579-0.781-1.038-1.682-1.92-2.16
			c-0.186,0.214-0.258,0.542-0.24,0.96c-2.076,1.568-2.806-1.177-4.08-2.16c-3.262-2.517-8.544-2.807-13.2-4.08
			c-0.649,0.398-2.95,0.398-3.6,0c-1.601-0.161-2.416,0.465-3.84,0.48c-3.749,2.811-3.946,9.174-4.56,15.12
			c-0.541,0.179-0.094,1.346-0.24,1.92c0,0.16,0,0.32,0,0.48c0,0.88,0,1.76,0,2.64c-0.029,6.989,2.715,11.205,6,14.88
			c1.38,1.18,2.66,2.46,3.84,3.84c0.612,0.909,1.634,1.406,2.4,2.16c0.343,0.058,0.405,0.395,0.72,0.48
			c0.86,1.061,1.82,2.021,2.88,2.88c0.058,0.343,0.396,0.405,0.48,0.72c4.992,4.448,7.574,11.306,11.52,16.8
			c1.153,0.447,2.279,0.921,4.08,0.72c1.49-0.271,2.673-0.847,4.32-0.96c1.078-0.682,0.938-2.582,3.12-2.16
			c0.046,0.194,0.241,0.239,0.24,0.48c1.638,1.699-1.411,2.488-1.44,4.08c0.41-0.01,0.622,0.178,0.72,0.48
			c0.153,0.887-0.311,2.391,0.24,2.88c0-0.8,0-1.6,0-2.4c0.398,0.649,0.398,2.951,0,3.6c0.039,1.321,0.112,2.607,0.48,3.6
			c0.058-0.368,0.395-0.036,0.48,0.24c0.267-4.196,1.858-8.244,1.92-12.48c0.132-8.989-3.438-17.706-5.04-26.64
			C235.204,277.495,235.064,268.299,231.601,260.444z M230.88,300.765c-0.655,0.175-0.483-0.477-0.96-0.48
			c0.667,1.326-3.372-2.922-1.44-1.44c-1.517-2.962-5.561-3.399-7.44-6c0.378,0.582,0.858,1.062,1.44,1.44
			c-0.898-0.146-2.222-1.935-3.12-2.88c-0.463-0.603,0.629,0.602,0.96,0.72c-1.624-1.737-3.089-3.631-3.12-6.96
			c-0.397-0.259-0.397-2.141,0-2.4c-0.079-0.879,0.267-1.333,0.48-1.92c-0.338-0.668,0.612-2.101,1.2-2.64
			c0.627-0.813,1.033-1.847,1.68-2.64c-0.011-0.73,0.507-0.933,0.72-1.44c-0.48,0-0.96,0-1.44,0c-0.08,0-0.16,0-0.24,0
			c-1.52,0-3.04,0-4.56,0c-3.79-0.37-6.868-1.453-9.12-3.36c-0.295,5.814,1.694,9.346,4.08,12.48
			c-0.001,0.241,0.194,0.286,0.24,0.48c0.944,0.576,1.456,1.583,2.4,2.16c0.384,0.896,1.493,1.067,1.68,2.16
			c0.409,1.468-1.301,1.762-2.16,1.2c-1.22-1.02-2.34-2.14-3.36-3.36c-2.734-3.345-5.402-6.758-5.28-12.96c-0.08,0-0.16,0-0.24,0
			c-0.309,1.48-0.44-3.372,0-2.64c0.08,0,0.16,0,0.24,0c1.029-2.157-0.861-3.511,0.24-5.28c1.857-0.109,1.854,1.878,2.64,2.88
			c1.914,2.441,4.828,3.418,8.88,3.84c0.964-0.556,2.957-0.083,4.32-0.24c0,0.08,0,0.16,0,0.24c0.96,0,1.92,0,2.88,0
			c1.656,0.184,2.6,1.08,3.84,1.68c0.005-4.866-4.308-7.22-7.92-9.36c-1.462-0.866-2.993-1.788-4.56-2.4
			c-1.51-0.59-3.884-0.474-4.32-2.4c3.197-2.22,7.131,0.907,9.84,2.4c2.672,1.473,6.414,3.597,8.16,6
			c2.132,2.934,0.522,7.7,3.36,10.32c-2.122,1.718-3.536,4.144-3.6,7.92c0.398,0.414,0.398,2.466,0,2.88
			c0.448,3.232,1.173,6.187,3.12,7.92c0.708,0.657,2.25,1.743,2.16,2.4c0,0.08,0,0.16,0,0.24
			C233.448,300.151,231.703,301.647,230.88,300.765z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M342.961,352.604c0.8,0,1.6,0,2.399,0
			c-0.332,0.547-1.67,0.089-2.399,0.24c-0.67,0.118,1.261,1.323,0,0.72c0.445,0.594,0.248,1.832,0.72,2.4
			c0.781-1.298,2.297-1.862,2.88-3.36c0.204-0.044,0.227,0.094,0.24,0.24C348.091,352.082,343.559,352.227,342.961,352.604z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M274.081,197.084c-0.7-0.901-1.5-1.701-2.4-2.4
			C272.38,195.585,273.18,196.385,274.081,197.084z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M305.041,253.244c-2.051-0.989-4.633-1.448-7.2-1.92
			c-0.283,0.038-0.682-0.041-0.721,0.24c3.052,0.309,5.708,1.012,7.921,2.16C305.064,253.455,304.691,253.351,305.041,253.244z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M206.16,289.004c0,0.08,0,0.16,0,0.24
			c0.901,0.699,1.701,1.5,2.4,2.4c0.194,0.045,0.239,0.241,0.48,0.24C208.181,290.824,207.221,289.864,206.16,289.004z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M344.16,355.725c0.98-0.78,1.861-1.66,2.641-2.64
			C345.82,353.864,344.94,354.744,344.16,355.725z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M321.601,206.204c6.015,0.13,10.053-7.968,5.04-11.76
			c0-0.08,0-0.16,0-0.24c-0.193-0.167-0.657-0.373-0.72,0c0,0.88,0,1.76,0,2.64c-0.518,2.683-1.31,5.091-2.641,6.96
			c-0.413,0.786-1.266,1.134-1.68,1.92c0.661-0.151,1.221-1.643,1.68-1.2C322.619,204.983,322.06,205.543,321.601,206.204z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M220.8,280.604c-1.19,1.508-1.595,4.555-0.48,6.48
			c0.634-0.774-0.139-5.354,0.48-4.56c0.08,0,0.16,0,0.24,0C220.894,281.951,221.342,280.783,220.8,280.604z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M341.041,352.604c-1.078-0.118-1.799,0.122-2.641,0.24
			c-0.808,0.152-2.229-0.31-2.64,0.24c-0.4,0-0.8,0-1.2,0c0.086,1.834,0.857,2.983,1.2,4.56c0,0.08,0,0.16,0,0.24
			c0.453,0.026,0.166,0.793,0.72,0.72c0.375-0.745,0.981-1.259,1.44-1.92c-0.001-0.241,0.194-0.286,0.239-0.48c0.08,0,0.16,0,0.24,0
			c0.576-0.944,1.584-1.456,2.16-2.4c0.241,0,0.286-0.194,0.48-0.24c0.269-0.051,0.373-0.266,0.479-0.48
			C341.518,352.768,341.1,352.865,341.041,352.604z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M304.08,391.245c-7.232-1.887-14.714-3.525-20.64-6.72
			c-0.64,0-1.28,0-1.92,0c-8.917-4.923-17.071-10.609-24.24-17.28c1.034,3.583,2.525,7.258,4.08,11.04
			c1.446,3.515,2.479,8.066,4.56,10.56c2.083,2.495,9.077,5.882,13.2,6.48c1.036,0.091,1.89,1.621,1.2,2.64
			c0.45,6.441,4.253,11.578,5.04,18.479c2.512-5.968,4.166-12.794,7.2-18.24c-0.214-0.906-1.681-0.559-1.44-1.92
			C295.044,394.208,300.135,393.299,304.08,391.245z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M308.881,388.125c-2.835-0.605-5.095-1.785-7.44-2.88
			c-1.313-0.526-2.686-0.995-3.6-1.92c-6.155-2.965-11.678-6.563-16.561-10.8c-0.241,0.001-0.285-0.194-0.48-0.24
			c-0.699-0.9-1.5-1.701-2.4-2.4c-0.057-0.343-0.395-0.405-0.48-0.72c-0.175-0.545-0.722-0.718-1.2-0.96
			c-0.014,0.146-0.036,0.284-0.24,0.24c3.38,4.3,8.74,8.7,12.72,13.2c-1.9-1.539-3.379-3.5-5.28-5.04c1.66,1.86,3.42,3.62,5.28,5.28
			c0.391,0.773,0.115,1.3,0,2.16c5.452,1.909,10.803,3.918,17.761,4.32c0.003,0.477,0.654,0.305,0.479,0.96
			c0.899-0.061,1.543-0.377,1.92-0.96C309.082,388.404,308.934,388.312,308.881,388.125z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M478.561,435.885c0.347,1.094,0.825,2.055,1.44,2.88
			C479.655,437.671,479.176,436.71,478.561,435.885z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M307.2,254.685c0.948,0.732,1.954,1.406,3.12,1.92
			c0.4-0.32,0.373-1.067,0.721-1.44c-1.111,0.063-2.79-0.501-3.841-1.2C306.912,254.28,307.107,254.342,307.2,254.685z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M383.041,243.884c0-0.475,0-0.949,0-1.423
			c0-1.045,0-2.091,0-3.137v-1.483c0.061-0.333,0.058-0.633,0-0.841v-2.057c0.064-0.506,0.032-1.108,0-1.71v-1.588
			c0-1.12,0-2.24,0-3.36c0-0.08,0-0.16,0-0.24c-0.08,0-0.16,0-0.24,0c0-1.04,0-2.08,0-3.12c0-0.56,0-1.12,0-1.68
			c0-0.16,0-0.32,0-0.48c-0.668,0.108,0.188-1.308-0.48-1.2c0-1.2,0-2.4,0-3.6c0.005-4.404-1.892-6.908-2.399-10.8
			c-0.629-2.091-1.276-4.164-2.16-6c-0.799-2.161-1.399-4.52-2.64-6.24c-1.217,2.144-1.469,5.251-2.641,7.44
			c-0.494,1.585-1.438,2.722-1.92,4.32c-2.52,4.2-6.222,7.218-12.96,7.2c-1.12,0-2.24,0-3.36,0c-0.239,0-0.479,0-0.72,0
			c0,0.08,0,0.16,0,0.24c-1.396,0.124-3.31-0.27-4.32,0.24c-0.761,0.485-1.156,1.311-1.199,1.92c-0.144,2.025,2.206,2.881,2.88,4.32
			c-2.39,1.384-5.95,0.237-7.681-1.44c-1.37-1.329-2.019-3.422-2.64-5.04c-0.279-0.039-0.427,0.053-0.479,0.24
			c-0.647,0.793-1.053,1.828-1.681,2.64c0,0.24,0,0.48,0,0.72c-0.397,0.259-0.397,2.142,0,2.4c0,0.4,0,0.8,0,1.2
			c0.559,1.361,0.931,2.91,1.681,4.08c1.421,1.778,3.131,3.269,5.76,3.84c0.336-0.397,2.304-0.397,2.64,0
			c0.085,0.315,0.423,0.377,0.48,0.72c0.153,1.514-1.566,1.153-2.88,1.2c0,0.08,0,0.16,0,0.24c1.228,0.417-6.304,0.337-4.32,0
			c0-0.08,0-0.16,0-0.24c-3.782,0.102-6.229-1.13-8.4-2.64c-0.08,0-0.16,0-0.239,0c-0.7-0.9-1.5-1.701-2.4-2.4c0-0.08,0-0.16,0-0.24
			c-1.664-2.176-3.25-4.431-3.12-8.4c-2.681,1.479-6.61,1.71-10.32,2.16c-0.729,0.15-2.067-0.308-2.399,0.24
			c-2.085-0.258-3.807,1.76-4.08,3.12c-1.695,8.417,7.086,11.203,13.68,11.52c0.344,1.409-0.856,1.48-0.72,3.12
			c-0.431-0.031-0.507,0.293-0.96,0.24c-1.351,0-2.701,0-4.052,0l-9.34-2.898c-0.202-0.323-1.11-1.071-1.249-1.422
			c-0.859-0.492-1.291-1.412-2.145-1.909l-0.931-1.4c-1.859-2.893-3.482-6.021-4.124-10.13c-0.376,0.861-0.376-2.782,0-1.92
			c0.511-1.729,0.968-3.512,1.92-4.8c1.06-0.86,2.021-1.82,2.88-2.88c-1.023,0.657-1.617,1.743-2.641,2.4
			c0.78-0.98,1.66-1.86,2.641-2.64c-0.648-1.581,0.749-2.445,0.96-3.84c0.595-3.929-2.176-7.087-3.601-9.6
			c-0.217-0.423-0.536-0.743-0.96-0.96c-0.488,0.231-0.518,0.921-0.479,1.68c-1.01,3.23-1.677,6.803-3.84,8.88
			c0.489-0.071,0.471-0.649,0.96-0.72c-1.002,1.241-2.98,2.777-3.12,4.08c-0.08,0-0.16,0-0.24,0
			c-0.001,1.039-0.557,1.523-0.479,2.64c-0.398,0.336-0.398,2.304,0,2.64c-0.203,1.002,0.466,1.133,0.479,1.92
			c0.701,2.182-2.013,0.15-2.399,0.96c-3.072-0.528-5.235-1.965-6.241-4.56c-0.821,1.111-1.904,2.114-1.92,3.36
			c-0.041,3.222,4.011,3.594,6.48,4.8c-1.68,2.742-8.301,2.089-10.56,0.48c-1.433-0.967-2.728-2.073-3.6-3.6
			c-0.834-1.646-1.529-3.431-1.44-6c-3.937,0.384-7.033,1.607-6.72,6.24c-2.428-0.415-2.556-4.248-2.16-6.24
			c0.767-3.854,6.177-5.896,9.12-7.2c0.552-1.448,2.574-1.426,2.88-3.12c-1.738-1.142-2.806-2.954-4.32-4.32
			c-1.3-1.1-2.5-2.3-3.6-3.6c-3.554-3.326-8.173-5.587-12.72-7.92c1.12,10.722,3.833,21.126,4.32,31.68
			c-0.693,0.31-1.239,0.054-1.68-0.48c-1.808,6.875-6.399,10.705-9.12,17.04c-1.933,4.5-3.009,8.828-4.32,14.64
			c1.528,4.279-1.511,9.493-1.2,14.88c0,0.16,0,0.32,0,0.48c0.398,0.492,0.398,2.628,0,3.12c0-0.32,0-0.64,0-0.96
			c-0.08,0-0.16,0-0.24,0c0.158,1.921-0.318,4.478,0.24,6c0.087,12.632,8.68,16.76,12.48,25.68c0.521,0.299,1.851,2.744,0.96,2.16
			c0.53,1.229,1.283,2.237,1.68,3.6c1.03-1.85,0.883-4.877,1.2-7.44c0-0.08,0-0.16,0-0.24c-0.398-0.414-0.398-2.466,0-2.88
			c0.342-0.058,0.405-0.395,0.72-0.48c0.08,0,0.16,0,0.24,0c1.979,2.261,1.9,6.58,2.4,10.32c0,0.88,0,1.76,0,2.64
			c-0.309,1.611-0.436,3.404-0.72,5.04c2.132-3.307,3.633-7.247,5.76-10.56c0.859,0.101,1.155,0.765,1.2,1.68c0,2.16,0,4.32,0,6.48
			c0,0.4,0,0.8,0,1.2c-0.131,0.349,0.28,1.241-0.24,1.2c0.192,2.672-0.698,4.262-1.2,6.24c1.463,0.1,3.564-1.233,5.28-1.68
			c0.664-0.536,1.466-0.934,2.4-1.2c0.788-0.413,1.644-0.757,2.88-0.72c1.436,3.832-1.516,7.228-2.64,10.08
			c-0.606,0.914-0.809,2.231-1.68,2.88c-0.074,1.126-0.726,1.674-0.72,2.88c2.567-1.641,5.955-1.32,8.64-2.4
			c2.013-0.809,2.997-3.018,5.28-2.88c0.983,5.125-2.93,9.344-5.04,12.96c0,0.241-0.194,0.286-0.24,0.48
			c-1.38,1.18-2.66,2.46-3.84,3.84c-0.489,0.071-0.471,0.649-0.96,0.72c-0.015,1.185-0.705,1.695-0.72,2.88
			c1.666,0.318,2.816-3.013,5.28-1.92c0.41,4.833-1.689,9.173-2.4,13.68c-0.398,0.57-0.398,2.789,0,3.36c0,0.4,0,0.8,0,1.2
			c4.95-2.41,8.585-6.134,14.4-7.68c1.294,0.545,1.984,1.695,2.88,2.64c0.378,0.583,0.896,1.025,0.72,2.16
			c4.384,1.112,7.186-1.243,10.32-2.16c-0.737,0.715,0.71,0.308,0.96,0.72c-0.314,2.725-1.71,4.37-2.88,6.24
			c-0.075,0.324-0.342,0.458-0.24,0.96c0.31,0.331,1.043,0.237,1.68,0.24c4.316-0.333,8.192-3.091,12.48-1.92
			c-0.588,1.972-1.984,3.135-2.88,4.8c5.392-0.607,6.267-5.733,6.96-11.04c0.283-1.083-1.063-0.536-0.72-1.68
			c0.667-0.867,2.691-0.546,2.64,0.72c0.963,0.238,0.729,2.681,1.92,1.92c-0.03-0.431,0.294-0.506,0.24-0.96
			c0.543-0.657,0.917-1.483,1.439-2.16c0.046-0.194,0.241-0.239,0.24-0.48c0.035-0.284,0.428-0.544,0-0.72
			c-2.082,0.4-5.838,0.4-7.92,0c0.4,0,0.8,0,1.2,0c0-0.08,0-0.16,0-0.24c-0.8,0-1.6,0-2.4,0c-2.281,0.358-4.61-1.557-2.399-2.88
			c0.336-0.397,2.304-0.397,2.64,0c-0.16,0-0.32,0-0.479,0c0.41,0.549,1.832,0.088,2.64,0.24c0.08,0,0.16,0,0.24,0
			c0-0.08,0-0.16,0-0.24c2.319,0,4.64,0,6.96,0c0,0.08,0,0.16,0,0.24c0.08,0,0.16,0,0.24,0c1.283-0.156,3.194,0.315,4.079-0.24
			c1.388,0.107,2.46-0.101,3.601-0.24c1.363-0.157,3.355,0.315,4.32-0.24c-0.641,0-1.28,0-1.921,0c0.571-0.398,2.79-0.398,3.36,0
			c3.112,0.218,6.499-1.532,9.12-0.24c0.42,3.3-3.563,2.196-5.76,2.88c-0.374,0.586-0.431,1.49-0.96,1.92
			c-0.399,0.729-0.399,3.112,0,3.84c0-0.56,0-1.12,0-1.68c0.397,0.414,0.397,2.466,0,2.88c0.334,2.386,1.342,4.099,2.399,5.76
			c2.277-0.808,0.292-4.547,2.16-5.52c1.896,0.104,2.158,1.842,3.36,2.64c1.518-3.122,2.119-7.161,3.84-10.08
			c3.053,0.067,1.048,5.192,2.88,6.48c0.22,0.42,0.083,1.197,0.48,1.44c0.159,0,0.319,0,0.479,0c0.781,0.055,1.485-2.03,1.68-3.36
			c0-0.4,0-0.8,0-1.2c0.343-1.011,0.471-2.142,0.24-3.12c0.434-2.127,0.795-4.325,1.681-6c0.691-1.548,1.602-2.878,2.159-4.56
			c1.072-2.368,1.277-5.604,1.681-8.64c0.067-0.813,0.382-1.378,0.239-2.4c1.229-6.76,3.04-13.334,4.32-19.92
			c1.298-6.675,3.428-12.77,4.561-19.44c0.547-0.333,0.089-1.67,0.239-2.4c0-0.48,0-0.96,0-1.44c0-0.64,0-1.28,0-1.92
			c0-0.72,0-1.44,0-2.16c-0.152-0.887,0.312-2.391-0.239-2.88c-0.229-2.812-0.894-5.187-0.96-8.16c-0.398-0.414-0.398-2.466,0-2.88
			c0-0.16,0-0.32,0-0.48c0.541-0.179,0.094-1.346,0.239-1.92c0-0.88,0-1.76,0-2.64c0-0.56,0-1.12,0-1.68
			c0.041-0.664-0.368-0.824-0.72-0.48c-0.399,0.32-0.08,1.36-0.479,1.68c-1.716,3.805-3.684,7.357-8.4,8.16c-1.28,0-2.561,0-3.84,0
			c-1.437-0.06-3.866-0.218-2.88-1.92c-2.722,0.726-7.206-1.795-9.601-2.16c3.575,10.105,7.218,20.143,11.28,29.76
			c0.156,1.363-0.315,3.355,0.24,4.32c0.167,0.881,0.414,1.825,0,2.64c-0.11,1.25-1.229,1.492-1.681,2.4c-0.08,0-0.159,0-0.239,0
			c-0.768,0.513-1.431,1.13-2.16,1.68c-3.06,2.621-6.244,5.116-10.8,6.24c-1.601,0-3.2,0-4.801,0c0,0.08,0,0.16,0,0.24
			c-0.808-0.152-2.229,0.309-2.64-0.24c0.24,0,0.479,0,0.72,0c-0.489-0.551-1.993-0.087-2.88-0.24
			c-1.045-0.075-1.963-0.277-2.88-0.48c-0.651,0.309-0.728,1.193-0.72,2.16c0.549,0.411,0.088,1.832,0.239,2.64
			c-0.079,0-0.159,0-0.239,0c0,0.08,0,0.16,0,0.24c0.155,1.125-0.314,2.874,0.239,3.6c-0.428,4.396,3.175,8.168,1.681,11.28
			c-1.752-0.523-2.103-3.168-2.641-5.04c-0.617-2.148-0.927-4.424-1.199-6.72c0-2.24,0-4.48,0-6.72
			c-0.118-0.757,0.433-0.848,0.239-1.68c-5.306-2.374-8.796-6.564-14.64-8.4c0.019-1.929,0.926-3.607,0.24-5.52
			c-0.155-1.045,0.313-2.713-0.24-3.36c0,0.48,0,0.96,0,1.44c-0.455-0.163-0.318-3.011,0-2.4c0.911-4.475,10.127-6.596,14.4-6.24
			c0.143,1.263-1.128,1.113-1.92,1.44c-2.979,2.941-7.783,4.058-9.36,8.4c0.146,0.014,0.284,0.036,0.24,0.24
			c0.259,0.701,2.141-0.221,2.399,0.48c0,0.08,0,0.16,0,0.24c0.641,0,1.28,0,1.92,0c0.337-0.398,2.305-0.398,2.641,0
			c2.896,0.623,5.779,1.26,8.16,2.4c5.816,1.943,11.327,4.192,15.84,7.44c1.145-0.855,2.444-1.555,3.359-2.64
			c1.546-1.174,3.383-2.058,4.32-3.84c0.399-1.125,0.399-3.915,0-5.04c-0.335-2.226-1.359-3.761-2.16-5.52
			c-0.426-1.254-0.985-2.375-1.68-3.36c-1.384-3.816-3.122-7.278-4.32-11.28c-0.285-0.915-0.638-1.762-0.96-2.64
			c-0.401-0.958-0.741-1.979-1.2-2.88c-0.438-1.561-0.986-3.013-1.439-4.56c-0.18-0.78-0.435-1.485-0.72-2.16
			c-0.266-1.015-0.592-1.969-0.961-2.88c-0.339-1.58-1.228-2.611-1.68-4.08c-0.426-1.253-0.985-2.375-1.68-3.36
			c-0.425-0.921-1.425-2.324-0.72-3.6c0.505-0.774,0.345-2.214,1.92-1.92c0.213,0.427,0.67,0.61,0.479,1.44
			c0.021,1.046,1.888,3.454,0.96,3.12c0,0.08,0,0.16,0,0.24c0.962,0.318,1.67-1.47,2.88-1.68c4.205-2.195,8.785-4.015,14.641-4.56
			c1.6,0,3.2,0,4.8,0c1.277,0.083,2.322,0.397,3.6,0.48c2.462,0.347,8.103,1.51,5.521,3.84c-3.532-0.468-6.145-1.855-10.08-1.92
			c-0.259,0.397-2.142,0.397-2.4,0c-6.975,0.705-13.121,2.239-16.8,6.24c4.419-1.741,9.13-5.27,16.32-5.04c0-0.08,0-0.16,0-0.24
			c-2.336-0.301,4.251-0.423,2.64,0c0,0.08,0,0.16,0,0.24c4.479,0.082,7.658,1.462,10.08,3.6c0.142,0.861-0.279,1.161-0.479,1.68
			c1.48,0.042,1.357-1.522,1.68-2.64c1.064-2.616,1.453-5.907,1.439-9.6C382.744,245.461,383.184,244.381,383.041,243.884z
			 M282,340.845c0.78-0.98,1.66-1.86,2.64-2.64C283.861,339.185,282.981,340.065,282,340.845z M364.081,277.245
			c-2.662-0.678-7.383-3.398-6.24-5.52c0.24,0,0.479,0,0.72,0c2.847,2.113,6.923,2.997,9.84,5.04
			C367.881,277.994,365.434,277.589,364.081,277.245z M346.561,318.524c0.32,0,0.64,0,0.96,0c0.73,4.476,3.034,8.638,3.36,12.72
			c-1.994-0.617-2.517-3.311-3.12-5.28c-0.638-2.079-1.105-4.524-1.44-6.72C346.253,318.857,346.548,318.831,346.561,318.524z
			 M308.637,234.747l-0.716-0.222c0.313-0.667-0.488-1.121-1.104-1.662l-0.363-0.547
			C307.28,233.046,308.625,234.068,308.637,234.747z M306.48,271.484c-3.867,2.373-7.452,5.028-12.72,6
			c0.803,0.378-2.963,0.378-2.16,0c-1.078-0.441-2.684-0.356-3.6-0.96c-1.109-0.171-1.587-0.973-2.16-1.68
			c-0.114-0.753,0.299-0.98,0.72-1.2c1.167-0.207,1.233,0.687,2.4,0.48c0.729-0.399,3.112-0.399,3.84,0
			c6.864-0.576,10.425-4.456,15.84-6.48C310.637,269.173,307.255,270.447,306.48,271.484z M309.601,282.524
			c-0.688-3.007,1.934-6.648,3.6-8.88C314.552,276.618,312.769,281.771,309.601,282.524z M318.001,255.404
			c-0.729,0.15-2.067-0.307-2.4,0.24c0.4,0,0.8,0,1.2,0c-0.941,0.424-3.873,0.12-3.84,0.72c-0.658,1.655-1.029,4.631-3.841,3.36
			c0.818,0.589,0.278,1.605,0.24,2.64c1.354-0.074,2.513,0.048,2.641,1.2c-0.091,1.218-1.454,0.939-2.4,0.72
			c-4.667,4.453-10.064,8.176-18.72,8.64c-1.601,0-3.2,0-4.8,0c-3.848-1.032-7.423-2.337-10.32-4.32
			c-0.957-0.511-2.812,0.954-3.84-0.24c-4.536-1.004-4.92-7.227-1.68-10.08c-2.663,2.238,2.446-3.071,0.96-0.96
			c4.146-3.693,10.575-5.104,17.28-6.24c-0.42-0.219-1.197-0.083-1.44-0.48c-1.095-0.135-1.495,0.425-2.64,0.24
			c-3.337,0.563-6.256,2.071-9.36,3.12c-2.904,0.982-5.97,2.972-9.12,2.16c1.277-2.896,5.803-3.711,8.88-4.8
			c3.863-1.367,7.426-2.791,12.24-2.88c0-0.08,0-0.16,0-0.24c-1.141-0.286,2.96-0.479,3.36,0c-0.32,0-0.64,0-0.96,0
			c0,0.08,0,0.16,0,0.24c2.16,0,4.32,0,6.48,0c4.892,0.388,8.873,1.687,12.72,3.12c0.772,0.667,1.945,0.934,2.64,1.68
			c0,0.4,0,0.8,0,1.2c0.657-0.224,1.023-0.736,1.681-0.96c1.279,0,2.56,0,3.84,0c0.034-0.754-0.615-0.825-1.2-0.96
			c-3.184-1.696-6.912-2.848-11.28-3.36c-1.168,0.044-1.693-1.851-0.479-2.16c2.713-0.135,6.628,0.559,8.88,1.68
			c0.664-0.57,1.466,0.763,2.399,0.72c0.045,0.204-0.093,0.227-0.239,0.24c2.06,1.22,4.69,1.869,6,3.84
			C320.745,254.949,319.421,255.225,318.001,255.404z M330.721,266.205c-2.717-4.748-6.786-8.969-7.92-15.6
			c-0.595-0.38-0.099-1.679,0-2.16c0.489,0.071,0.471,0.649,0.96,0.72c0,0.08,0,0.16,0,0.24s0,0.16,0,0.24
			c1.414,3.717,3.045,7.745,5.28,11.04c2.062,3.04,4.964,6.042,4.8,10.32C332.101,269.905,331.625,267.785,330.721,266.205z
			 M347.521,242.685c-0.08,0-0.16,0-0.24,0c-0.095,2.225-0.339,4.3-1.68,5.28c-1.975-0.377,1.068-8.755,0.96-10.08
			c0.809,0.151,0.625,1.295,0.72,2.16c0.08,0,0.16,0,0.24,0C347.903,239.37,347.903,243.359,347.521,242.685z M349.409,241.793
			c-0.194-0.438-0.162-0.924,0-1.414V241.793z M375.952,245.453c0.31-0.979,0.646-1.983,0.864-3.06v0.068h0.002
			C376.6,243.513,376.257,244.493,375.952,245.453z"/>
			</g>
			<g id="black">
				<path fillRule="evenodd" clipRule="evenodd" d="M347.521,240.044c-0.08,0-0.16,0-0.24,0c-0.095-0.865,0.089-2.009-0.72-2.16
			c0.108,1.325-2.935,9.703-0.96,10.08c1.341-0.979,1.585-3.055,1.68-5.28c0.08,0,0.16,0,0.24,0
			C347.903,243.359,347.903,239.37,347.521,240.044z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M282,340.845c0.98-0.78,1.86-1.66,2.64-2.64
			C283.66,338.984,282.78,339.864,282,340.845z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M303.841,137.324c0.405,1.521,1.413,3.042,2.399,4.08
			c4.625,4.866,11.729,9.523,17.761,12.48c1.072,1.007,2.607,1.552,4.08,2.16c1.07,0.45,1.915,1.125,3.119,1.44
			c0.664,0.536,1.467,0.934,2.4,1.2c3.156,1.724,6.16,3.601,8.88,5.76c1.26,1.46,2.62,2.82,4.08,4.08
			c0.675,1.189,1.615,2.126,2.4,3.36c0.74,1.165,1.068,2.911,2.399,3.6c-0.007-0.193-0.029-0.379-0.046-0.567
			c0.015,0.03,0.031,0.057,0.046,0.087c-0.015-0.043-0.034-0.082-0.049-0.125v0c-0.003-0.008-0.006-0.015-0.009-0.023
			c0.003,0.008,0.006,0.015,0.009,0.022c-0.462-4.98-4.441-8.571-7.631-11.395c-3.695-3.271-7.977-5.565-12.48-7.68
			c-9.145-4.294-17.513-9.777-24.239-16.08c-1.268-1.214-2.747-3.49-4.32-3.6C303.081,136.483,303.239,137.126,303.841,137.324z
			 M351.3,174.889c-7.423-15.31-26.654-18.812-39.333-28.866C324.646,156.077,343.877,159.579,351.3,174.889z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M319.681,181.004c2.008-2.792,4.308-5.292,4.8-9.6
			c0.397-0.259,0.397-2.141,0-2.4c0.066-1.426-0.219-2.502-1.439-2.64c-0.909,1.147,0.055,2.549,0,3.84
			c-0.017,0.379-0.058,0.753-0.113,1.125c-0.126,0.675-0.296,1.317-0.486,1.945c0.032-0.098,0.072-0.194,0.103-0.293
			c-0.095,0.333-0.2,0.656-0.312,0.976c0.077-0.222,0.14-0.455,0.209-0.683c-1.719,5.17-6.821,9.305-10.44,11.09
			c3.074-4.045,6.525-7.714,6.96-14.4c0.398-0.729,0.398-3.112,0-3.84c-0.106-0.694-0.506-1.094-1.2-1.2
			c-0.003,9.051-4.087,14.272-8.16,18.96c-1.137,1.308-2.645,2.56-3.12,4.32c3.756,0.579,7.754-2.605,10.561-4.56
			C318.021,182.865,318.901,181.985,319.681,181.004z M321.745,175.209c-0.413,0.947-0.902,1.833-1.457,2.664
			C320.842,177.042,321.332,176.157,321.745,175.209z M319.415,179.067c-0.548,0.687-1.143,1.331-1.78,1.933
			C318.271,180.397,318.867,179.755,319.415,179.067z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M356.4,190.604c0.109,0.2,0.196,0.405,0.291,0.608
			c0.135,0.347,0.275,0.684,0.418,1.017c-0.012-0.027-0.022-0.054-0.034-0.081c1.84,5.19-0.56,11.207-4.034,13.336
			c-2.571,1.576-6.246,1.412-8.16,4.08c6.912-0.698,13.552-4.258,14.399-11.52c0.398-0.414,0.398-2.466,0-2.88
			c0.09-1.689-0.739-2.46-1.199-3.6c-0.363-0.455-1.638-2.229-1.92-1.2C356.174,190.511,356.196,190.648,356.4,190.604z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M308.641,267.645c-5.415,2.024-8.976,5.904-15.84,6.48
			c-0.729-0.399-3.111-0.399-3.84,0c-1.167,0.207-1.233-0.687-2.4-0.48c-0.421,0.219-0.834,0.446-0.72,1.2
			c0.573,0.708,1.051,1.509,2.16,1.68c0.916,0.604,2.522,0.519,3.6,0.96c-0.803,0.378,2.963,0.378,2.16,0
			c5.268-0.972,8.853-3.627,12.72-6C307.255,270.447,310.637,269.173,308.641,267.645z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M377.521,694.125c-2.205,0.508-5.291,2.65-7.92,4.08
			c-2.678,1.457-5.302,3.108-6.96,4.8c1.166,0.046,1.951-0.288,2.64-0.72c0.989-0.291,1.743-0.817,2.881-0.96
			c1.5-0.499,2.923-1.077,4.079-1.92c0.08,0,0.16,0,0.24,0c14.434-6.687,28.039-14.201,39.12-24.24c0-0.08,0-0.16,0-0.24
			c1.188-0.572,1.841-1.679,2.88-2.399c0.558-0.163,0.643-0.798,1.2-0.96c0.98-0.78,1.86-1.66,2.64-2.641c0-0.079,0-0.159,0-0.239
			c-0.249-0.677-0.75-1.955-1.439-1.44c-0.489,0.07-0.471,0.649-0.96,0.72c-10.353,10.288-22.909,18.372-36.48,25.44
			c-0.214,0.106-0.429,0.211-0.479,0.479c-0.49,0.23-0.95,0.491-1.44,0.721C377.688,694.478,377.785,694.064,377.521,694.125z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M406.561,764.686c-0.08,0-0.16,0-0.24,0c0,0.08,0,0.16,0,0.24
			c-0.457,0.102-0.434,0.686-1.199,0.479c-0.574,0.306-1.131,0.626-1.701,0.937c-0.153,0.071-0.299,0.151-0.435,0.24
			c0.002-0.001,0.004-0.003,0.006-0.004c-13.609,7.45-26.015,16.104-39.631,23.547c-0.08,0-0.159,0-0.239,0
			c-1.391,0.851-3.196,1.284-4.32,2.4c-0.457,0.104-0.604,0.518-1.2,0.48c-1.492,0.299-4.834,2.825-1.92,3.84c0.16,0,0.32,0,0.48,0
			c2.132-1.149,5.402-2.436,6.72-3.601c0.553-0.567,1.472-0.768,2.16-1.199c-0.563,0.764,0.029,0.313,0.239,0.239
			c3.195-1.113,6.407-3.513,9.841-5.52c3.062-1.791,6.311-3.762,9.6-5.76c6.839-4.156,13.924-7.681,18.96-11.761
			c1.269-1.026,2.262-2.062,2.884-3.31c-0.027,0.049-0.065,0.101-0.096,0.15c0.13-0.21,0.23-0.412,0.285-0.603
			c-0.007,0.021-0.008,0.04-0.015,0.062c0.017-0.039,0.027-0.083,0.043-0.122c0.006-0.006,0.013-0.012,0.019-0.018
			c-0.004-0.001-0.008-0.001-0.013-0.001c0.095-0.233,0.186-0.468,0.253-0.719C406.881,764.686,406.721,764.686,406.561,764.686z
			 M354.962,796.123c-0.81-1.146,1.087-2.037,2.165-2.754C356.053,794.087,354.154,794.977,354.962,796.123z M406.764,765.439
			c-0.131,0.124-0.268,0.24-0.399,0.362C406.496,765.68,406.633,765.562,406.764,765.439L406.764,765.439z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M249.36,584.205c1.954-0.767,3.852-1.589,5.04-3.12
			c0.284-0.116,0.544-0.256,0.72-0.479c0.08,0,0.16,0,0.24,0c1.175,0.054,1.508-0.732,2.64-0.721c0.867-0.412,1.311-1.249,1.92-1.92
			c-0.4,0-0.8,0-1.2,0c-2.602-0.122-4.26,0.7-6,1.44c-1.578-0.138-1.895,0.985-3.36,0.96c1.316-0.85-1.542-0.033-2.64,0.24
			c-1.623,0.402-3.447,0.854-4.32,1.199c-0.146,0.415-1.138-0.018-1.2,0.48c-0.08,0-0.16,0-0.24,0
			c-2.652,0.388-5.308,0.772-7.68,1.439c-0.637,0.003-1.371-0.09-1.68,0.24c-3.443,0.396-6.797,0.884-9.84,1.68
			c-0.08,0-0.16,0-0.24,0c-1.867,0.214-3.757,0.404-5.28,0.961c-0.08,0-0.16,0-0.24,0c-0.943,0.017-1.804,0.115-2.4,0.479
			c-0.08,0-0.16,0-0.24,0c-3.761,0.56-7.409,1.231-10.56,2.4c-0.08,0-0.16,0-0.24,0c-1.185,0.015-1.695,0.704-2.88,0.72
			c-2.076-0.531-2.524,2.878-0.48,1.92c0.147-0.333,0.924-0.036,0.96-0.48c0.08,0,0.16,0,0.24,0c2.556-0.483,5.157-0.923,7.44-1.68
			c0.592,0.032,1.156,0.036,1.44-0.24c1.211-0.148,2.425-0.295,3.36-0.72c0.08,0,0.16,0,0.24,0c0.943-0.017,1.804-0.116,2.4-0.479
			c0.08,0,0.16,0,0.24,0c1.867-0.214,3.756-0.403,5.28-0.96c0.638-0.003,1.371,0.09,1.68-0.24c2.425-0.295,4.823-0.617,6.96-1.2
			c0.638-0.002,1.371,0.091,1.68-0.24c2.5-0.14,4.403-0.877,6.96-0.96c0.943-0.017,1.804-0.116,2.4-0.479c0.08,0,0.16,0,0.24,0
			c0.359,0.041,0.886-0.086,0.96,0.24c-1.891,0.509-3.732,1.067-5.28,1.92c-0.08,0-0.16,0-0.24,0
			c-1.064,0.375-2.354,0.525-3.12,1.199c-3.41,1.15-6.71,2.411-10.32,3.36c-1.35,0.25-2.553,0.646-3.6,1.2
			c-0.534,0.187-0.936,0.504-1.68,0.479c-1.109,0.171-2.076,0.485-2.88,0.96c-0.94,0.501-2.281,0.6-3.12,1.2c-0.08,0-0.16,0-0.24,0
			c-1.35,0.25-2.553,0.647-3.6,1.2c-0.08,0-0.16,0-0.24,0c-0.734-0.174-0.706,0.414-1.44,0.24c-2.709,0.73-5.318,1.563-7.68,2.64
			c-0.768,0.191-1.211,0.709-2.16,0.72c-1.167,0.273-2.191,0.689-3.12,1.2c-0.047,0.688-0.03,1.31,0.72,1.2
			c2.341-0.379,4.183-1.258,6.48-1.68c1.109-0.171,2.076-0.485,2.88-0.96c2.164-0.956,4.892-1.348,6.96-2.4c0.08,0,0.16,0,0.24,0
			c3.141-0.859,6.306-1.693,9.12-2.88c0.08,0,0.16,0,0.24,0c1.419-0.101,2.18-0.859,3.6-0.96c1.59-0.33,3.032-0.809,4.32-1.44
			c1.73-0.59,3.349-1.291,5.28-1.68c5.57-1.87,11.336-3.544,16.32-6C249.2,584.205,249.28,584.205,249.36,584.205z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M335.761,674.925c0.08,0,0.16,0,0.24,0c1.634-0.206,3.135-0.545,4.319-1.199
			c0.08,0,0.16,0,0.24,0c2.948-1.212,6.891-1.43,8.4-4.08c-0.123-0.021-0.243-0.026-0.365-0.043c0.04,0.017,0.087,0.024,0.125,0.043
			c-0.039-0.019-0.086-0.026-0.126-0.043h0.001c-0.001,0-0.002-0.001-0.004-0.001c-0.18-0.073-0.375-0.124-0.582-0.158
			c-0.014-0.002-0.027-0.003-0.04-0.006c-0.196-0.029-0.397-0.045-0.606-0.045c-0.023,0-0.046,0-0.07,0
			c-0.202,0.002-0.405,0.015-0.61,0.038c-0.025,0.003-0.05,0.005-0.075,0.009c-0.218,0.027-0.431,0.064-0.64,0.111
			c-0.006,0.001-0.012,0.002-0.017,0.003c0.001,0,0.002,0,0.003,0c-0.003,0.001-0.007,0.001-0.01,0.002
			c0.003-0.001,0.005-0.001,0.007-0.002c-4.176,0.39-8.063,2.728-12.345,3.513c-0.397,0.064-0.794,0.129-1.206,0.179
			c-0.146,0.014-0.284,0.036-0.24,0.24c-0.08,0-0.159,0-0.239,0c-1.153,0.047-2.285,0.114-3.12,0.479c-0.08,0-0.16,0-0.24,0
			c-2.89,0.23-5.67,0.57-8.16,1.2c-1.361-0.001-2.552,0.169-3.6,0.48c-1.647,0.112-3.265,0.256-4.561,0.72c-0.08,0-0.159,0-0.239,0
			c-2.788,0.252-5.432,0.648-7.921,1.2c-0.079,0-0.159,0-0.239,0c-4.18,0.22-7.737,1.063-12.24,0.96
			c-0.966,0.154-2.552-0.313-3.12,0.24c-0.988,0.311-0.397,2.011,0.48,1.92c1.045,0.398,3.754,0.398,4.8,0
			c1.031-0.328,2.705-0.016,3.6-0.48c0.08,0,0.16,0,0.24,0c2.84-0.119,5.536-0.384,7.92-0.96c0.08,0,0.16,0,0.24,0
			c1.212,0.012,2.307-0.094,3.12-0.479c0.691-0.029,1.57,0.131,1.92-0.24c6.805-0.715,12.975-2.065,19.92-2.641
			C332.602,675.766,334.362,675.526,335.761,674.925z M344.16,670.125c-2.572,0.795-5.037,1.697-7.738,2.363
			C339.121,671.82,341.588,670.92,344.16,670.125z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M147.36,672.766c-2.135-0.186-4.317-0.323-6.72-0.24
			c-0.729,0.15-2.067-0.308-2.4,0.24c-1.993,0.167-4.234,0.085-6,0.479c-0.08,0-0.16,0-0.24,0c-5.483,0.197-10.926,0.434-16.08,0.96
			c-0.08,0-0.16,0-0.24,0c-1.045,0.155-2.713-0.313-3.36,0.24c-0.08,0-0.16,0-0.24,0c-1.045,0.154-2.713-0.313-3.36,0.24
			c-0.08,0-0.16,0-0.24,0c-1.045,0.154-2.713-0.313-3.36,0.239c-0.08,0-0.16,0-0.24,0c-3.625,0.056-6.899,0.462-10.32,0.721
			c-0.94,0.033-0.701,1.076-0.48,1.68c1.36,0.323,4.181,0.454,5.52,0c0.48,0,0.96,0,1.44,0c1.045-0.155,2.713,0.313,3.36-0.24
			c0.56,0,1.12,0,1.68,0c0.808-0.151,2.229,0.31,2.64-0.239c0.48,0,0.96,0,1.44,0c0.729-0.151,2.067,0.307,2.4-0.24
			c0.48,0,0.96,0,1.44,0c0.729-0.15,2.067,0.307,2.4-0.24c0.56,0,1.12,0,1.68,0c0.808-0.152,2.229,0.31,2.64-0.24
			c0.72,0,1.44,0,2.16,0c0.887-0.153,2.391,0.311,2.88-0.24c0.574-0.146,1.741,0.302,1.92-0.239c0.08,0,0.16,0,0.24,0
			c1.2,0,2.4,0,3.6,0c0.966-0.154,2.552,0.312,3.12-0.24c0.789-0.491,2.431-0.13,3.6-0.24c1.045-0.154,2.713,0.313,3.36-0.24
			c1.76,0,3.52,0,5.28,0c0.954,0.154,1.295-0.305,1.68-0.72C148.587,673.298,148.091,672.915,147.36,672.766z"/>
				<polygon fillRule="evenodd" clipRule="evenodd" points="458.893,715.673 458.898,715.672 458.887,715.673 		" />
				<path fillRule="evenodd" clipRule="evenodd" d="M460.27,715.536c0.002,0,0.004-0.001,0.005-0.001
			c-0.008,0.001-0.016,0.003-0.023,0.004C460.257,715.538,460.264,715.537,460.27,715.536z"/>
				<polygon fillRule="evenodd" clipRule="evenodd" points="245.992,281.293 245.991,281.292 245.992,281.294 		" />
				<path fillRule="evenodd" clipRule="evenodd" d="M336.961,176.444c-2.917-4.665-3.56-11.302-6.721-15.6
			c-5.227-7.106-14.829-8.498-23.04-12.72c-0.292,0.027-0.568,0.072-0.479,0.48c6.589,6.043,17.525,6.426,22.32,14.16
			c2.518,4.062,3.372,9.976,5.76,14.16c0.941,1.649,2.884,4.207,5.52,3.84C339.943,178.499,338.045,178.178,336.961,176.444z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M370.32,184.364c-0.202,2.32-1.083,4.36-1.92,6
			c-0.85,1.665-2.542,2.902-2.64,5.04c9.152-3.943,7.376-21.607,0.741-27.583c0.002,0.002,0.004,0.005,0.006,0.007
			c-0.002-0.003-0.005-0.005-0.007-0.008c0,0-0.001-0.001-0.002-0.002c-0.241-0.264-0.486-0.514-0.738-0.735
			c-0.052,0.007-0.108,0.008-0.164,0.01c-0.26-0.18-0.524-0.346-0.796-0.49c0,0.16,0,0.32,0,0.48
			C367.197,171.921,370.88,177.956,370.32,184.364z"/>
				<polygon fillRule="evenodd" clipRule="evenodd" points="366.501,167.821 366.501,167.821 366.502,167.822 		" />
				<path fillRule="evenodd" clipRule="evenodd" d="M328.801,175.484c-1.037,4.083-2.794,7.446-5.04,10.32
			c-1.38,1.18-2.66,2.46-3.84,3.84c-1.321,0.519-2.036,1.644-3.12,2.4c-0.579,0.309-2.231,1.809-0.96,2.4
			c3.04-1.009,5.887-3.693,8.64-6.72c2.373-2.61,5.755-6.477,5.521-10.08C329.958,176.985,329.921,175.594,328.801,175.484z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M240.24,241.484c1.349-1.091,0.476-2.606,0.48-3.84
			c0.012-3.318,0.585-7.112,2.88-8.88c2.312-1.781,5.118-2.055,7.2-3.84c1.911-1.638,4.08-4.388,4.56-7.44
			c0.397-0.259,0.397-2.141,0-2.4c0.072-0.472-0.324-0.476-0.48-0.72c-1.612,0.477-1.045,2.518-1.44,3.6
			c-1.78,4.878-6.703,6.247-10.451,8.889h0c0,0,0,0-0.001,0s-0.001,0-0.001,0.001c-0.211,0.124-0.418,0.253-0.616,0.396
			c-0.241,0.168-0.475,0.348-0.691,0.553c-0.145,0.137-0.257,0.286-0.38,0.43c-1.304,1.269-2.313,2.86-2.74,5.09
			c0-0.08,0-0.16,0-0.24c0-0.96,0-1.92,0-2.88c0.794-3.594,2.287-6.488,4.107-9.055c-0.215,0.279-0.431,0.558-0.646,0.838
			c0.216-0.28,0.432-0.559,0.646-0.838v0c0,0,0.001-0.001,0.001-0.002c0.086-0.112,0.17-0.224,0.255-0.336
			c0.005-0.007,0.01-0.013,0.015-0.02c0.956-0.767,1.817-1.627,2.581-2.587c0.278-0.842,0.944-1.295,0.96-2.4
			c0.624-0.562,0.084-1.991-0.24-2.4c-0.877,0.082-1.098,0.822-1.44,1.44c-0.656,2.658-2.858,3.575-4.32,5.52
			c-2.006,2.668-4.221,7.11-4.32,11.04C236.069,235.029,237.46,240.606,240.24,241.484z M243.023,220.681
			c-0.005,0.006-0.01,0.013-0.015,0.02c0,0,0,0,0,0C243.013,220.694,243.019,220.687,243.023,220.681z"/>
				<polygon fillRule="evenodd" clipRule="evenodd" points="242.989,226.854 242.989,226.854 242.99,226.853 		" />
				<path fillRule="evenodd" clipRule="evenodd" d="M314.881,249.645c0.146-0.014,0.284-0.036,0.239-0.24
			c-0.934,0.043-1.735-1.29-2.399-0.72c-2.252-1.122-6.167-1.815-8.88-1.68c-1.214,0.309-0.688,2.205,0.479,2.16
			c4.368,0.512,8.097,1.664,11.28,3.36c0.585,0.135,1.234,0.206,1.2,0.96c-1.28,0-2.561,0-3.84,0
			c-0.657,0.224-1.023,0.736-1.681,0.96c0-0.4,0-0.8,0-1.2c-0.694-0.746-1.867-1.013-2.64-1.68c-3.847-1.434-7.828-2.732-12.72-3.12
			c-2.16,0-4.32,0-6.48,0c0-0.08,0-0.16,0-0.24c0.32,0,0.64,0,0.96,0c-0.399-0.479-4.501-0.286-3.36,0c0,0.08,0,0.16,0,0.24
			c-4.814,0.089-8.377,1.513-12.24,2.88c-3.077,1.089-7.603,1.904-8.88,4.8c3.15,0.812,6.216-1.178,9.12-2.16
			c3.104-1.049,6.023-2.557,9.36-3.12c1.146,0.186,1.545-0.375,2.64-0.24c0.243,0.397,1.02,0.261,1.44,0.48
			c-6.705,1.135-13.133,2.546-17.28,6.24c1.485-2.111-3.624,3.198-0.96,0.96c-3.24,2.853-2.856,9.076,1.68,10.08
			c1.028,1.194,2.883-0.271,3.84,0.24c2.897,1.982,6.472,3.288,10.32,4.32c1.6,0,3.2,0,4.8,0c8.655-0.464,14.053-4.187,18.72-8.64
			c0.946,0.219,2.31,0.498,2.4-0.72c-0.128-1.152-1.287-1.274-2.641-1.2c-0.054,0.032-0.192-0.039-0.24,0
			c-2.838,2.38-6.142,4.466-9.6,6.24c-0.08,0-0.16,0-0.24,0c-1.287,0.072-2.23,0.489-3.12,0.96c-1.363,0.157-3.355-0.316-4.319,0.24
			c-1.703,0.137-3.333,0.348-5.28,0.24c-0.08,0-0.16,0-0.24,0c-3.66-1.62-7.884-2.676-10.56-5.28c-0.62-0.82-1.34-1.541-2.16-2.16
			c0-0.08,0-0.16,0-0.24c0.427-0.853,1.437-1.124,1.92-1.92c0.414-0.947,1.747-0.973,2.16-1.92c0.08,0,0.16,0,0.24,0
			c0.854-0.506,1.789-0.931,2.4-1.68c0.08,0,0.16,0,0.24,0c1.498-0.583,2.696-1.464,3.84-2.4c0.08,0,0.16,0,0.24,0
			c3.134-0.386,6.475-0.565,10.08-0.48c2.455,0.265,4.525,0.915,6.96,1.2c0.08,0,0.16,0,0.24,0c2.372,1.548,5.5,2.339,7.199,4.56
			c2.812,1.271,3.183-1.705,3.841-3.36c0.008-0.044,0.026-0.071,0.036-0.111c0.328-0.45,2.927-0.214,3.804-0.609c-0.4,0-0.8,0-1.2,0
			c0.333-0.547,1.671-0.09,2.4-0.24c1.42-0.18,2.744-0.455,2.88-1.92C319.571,251.514,316.94,250.865,314.881,249.645z
			 M289.44,251.084c0.003-0.317,0.421-0.219,0.48-0.48c0.08,0,0.159,0,0.239,0c0.24,0,0.48,0,0.721,0
			c0.059,0.261,0.477,0.163,0.479,0.48C290.721,251.084,290.08,251.084,289.44,251.084z M305.041,253.725
			c-2.213-1.148-4.869-1.852-7.921-2.16c0.039-0.281,0.438-0.203,0.721-0.24c2.567,0.472,5.149,0.931,7.2,1.92
			C304.691,253.351,305.064,253.455,305.041,253.725z M310.32,256.604c-1.166-0.514-2.172-1.188-3.12-1.92
			c-0.093-0.343-0.288-0.405,0-0.72c1.051,0.699,2.729,1.263,3.841,1.2C310.693,255.537,310.721,256.284,310.32,256.604z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M333.838,270.515c-0.065-4.018-2.815-6.909-4.797-9.831
			c-2.235-3.295-3.866-7.323-5.28-11.04c0-0.08,0-0.16,0-0.24s0-0.16,0-0.24c-0.489-0.071-0.471-0.649-0.96-0.72
			c-0.099,0.481-0.595,1.78,0,2.16c1.134,6.631,5.203,10.852,7.92,15.6c0.904,1.581,1.38,3.701,3.12,4.8
			c0.006-0.164,0-0.322-0.003-0.482c0.001,0.001,0.002,0.002,0.003,0.002C333.84,270.521,333.839,270.518,333.838,270.515z
			 M330.961,265.725c0.466,0.803,0.78,1.709,1.181,2.562C331.741,267.435,331.427,266.528,330.961,265.725z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M232.561,299.084c0.089-0.657-1.452-1.743-2.16-2.4
			c-1.947-1.733-2.672-4.688-3.12-7.92c0.398-0.414,0.398-2.466,0-2.88c0.064-3.776,1.478-6.202,3.6-7.92
			c-2.837-2.62-1.228-7.386-3.36-10.32c-1.746-2.403-5.488-4.527-8.16-6c-2.709-1.493-6.643-4.621-9.84-2.4
			c0.436,1.927,2.81,1.81,4.32,2.4c1.567,0.612,3.098,1.534,4.56,2.4c3.612,2.14,7.925,4.494,7.92,9.36
			c-1.24-0.6-2.184-1.496-3.84-1.68c-0.96,0-1.92,0-2.88,0c0-0.08,0-0.16,0-0.24c-1.363,0.157-3.356-0.315-4.32,0.24
			c-4.052-0.422-6.966-1.399-8.88-3.84c-0.786-1.002-0.783-2.989-2.64-2.88c-1.101,1.769,0.789,3.123-0.24,5.28
			c-0.08,0-0.16,0-0.24,0c-0.44-0.731-0.309,4.121,0,2.64c0.08,0,0.16,0,0.24,0c-0.122,6.202,2.545,9.615,5.28,12.96
			c1.02,1.22,2.14,2.34,3.36,3.36c-0.18-0.215-0.38-0.411-0.567-0.62c0,0,0,0,0,0c0.186,0.209,0.387,0.404,0.566,0.62
			c0.859,0.562,2.569,0.268,2.16-1.2c-0.187-1.093-1.296-1.264-1.68-2.16c-0.944-0.576-1.456-1.583-2.4-2.16
			c-0.045-0.194-0.241-0.239-0.24-0.48c-2.386-3.134-4.375-6.666-4.08-12.48c2.252,1.907,5.33,2.99,9.12,3.36c1.52,0,3.04,0,4.56,0
			c0.08,0,0.16,0,0.24,0c0.48,0,0.96,0,1.44,0c-0.213,0.507-0.73,0.709-0.72,1.44c-0.647,0.793-1.053,1.828-1.68,2.64
			c-0.588,0.539-1.539,1.971-1.2,2.64c-0.213,0.587-0.559,1.041-0.48,1.92c-0.397,0.259-0.397,2.141,0,2.4
			c0.031,3.329,1.497,5.223,3.12,6.96c-0.331-0.118-1.423-1.323-0.96-0.72c0.898,0.945,2.222,2.734,3.12,2.88
			c-0.582-0.379-1.062-0.859-1.44-1.44c1.879,2.601,5.923,3.038,7.44,6c-1.932-1.482,2.106,2.766,1.44,1.44
			c0.477,0.003,0.305,0.655,0.96,0.48c0.822,0.883,2.568-0.613,1.68-1.44C232.561,299.245,232.561,299.165,232.561,299.084z
			 M210.053,287.03c0,0,0,0.001,0.001,0.001c-0.001-0.001-0.002-0.003-0.004-0.005C210.051,287.027,210.052,287.029,210.053,287.03z
			 M221.041,282.524c-0.08,0-0.16,0-0.24,0c-0.619-0.794,0.154,3.786-0.48,4.56c-1.115-1.925-0.71-4.972,0.48-6.48
			C221.342,280.783,220.894,281.951,221.041,282.524z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M368.4,276.765c-2.917-2.043-6.993-2.927-9.84-5.04c-0.24,0-0.479,0-0.72,0
			c-1.143,2.122,3.578,4.842,6.24,5.52C365.434,277.589,367.881,277.994,368.4,276.765z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M309.601,282.524c3.168-0.753,4.951-5.907,3.6-8.88
			C311.534,275.876,308.912,279.518,309.601,282.524z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M350.881,331.245c-0.326-4.083-2.63-8.245-3.36-12.72c-0.32,0-0.64,0-0.96,0
			c-0.013,0.307-0.308,0.333-0.24,0.72c0.335,2.196,0.803,4.641,1.44,6.72C348.364,327.934,348.887,330.628,350.881,331.245z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M500.881,672.285c4.396-0.386,11.313-0.92,14.84-1.676
			C510.601,670.994,505.683,671.582,500.881,672.285z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M391.921,641.325c-5.681,0-11.36,0-17.04,0c-3.761-0.399-9.199-0.4-12.96,0
			c-0.16,0-0.32,0-0.48,0c-0.468,0.187-0.974,0.335-1.483,0.479c0.511-0.144,1.016-0.293,1.483-0.479
			c-1.204,0.133-2.456,0.22-3.069,0.944c-0.017,0.006-0.034,0.01-0.051,0.016c0.005,0.007,0.015,0.01,0.021,0.017
			c-0.107,0.134-0.2,0.283-0.26,0.464c1.04,0,2.079,0,3.12,0c2.4-0.002,4.59,0.21,6.72,0.479c0.41,0.549,1.832,0.088,2.64,0.24
			c0.641,0,1.28,0,1.92,0c1.124,0.557,3.277,0.082,4.8,0.24c1.2,0,2.4,0,3.601,0c2.322,0.399,6.318,0.399,8.64,0
			c1.521,0,3.04,0,4.561,0c0.966-0.154,2.552,0.312,3.12-0.24c1.04,0,2.079,0,3.119,0c0.729-0.15,2.067,0.307,2.4-0.24
			c2.344-0.137,4.865-0.095,6.96-0.479c0.08,0,0.16,0,0.24,0c0.88,0,1.76,0,2.64,0c0.038,0.282-0.041,0.681,0.24,0.72
			c0,0.08,0,0.16,0,0.24c0.017,0.717,2.06,0.642,2.4,0.239c0.08,0,0.159,0,0.239,0c0.4,0,0.801,0,1.2,0
			c0.74,0.24-0.521,0.396-0.479,0.721c-0.146,0.414-1.139-0.019-1.2,0.479c-0.08,0-0.16,0-0.24,0
			c-2.672,0.528-4.408,1.991-6.96,2.64c-1.255,0.505-2.622,0.898-3.6,1.681c-0.08,0-0.16,0-0.24,0
			c-1.256,0.505-2.622,0.897-3.601,1.68c-0.08,0-0.159,0-0.239,0c-0.937,0.345-1.987,0.573-2.641,1.2
			c-0.502,0.138-0.685,0.595-1.439,0.479c-0.937,0.345-1.987,0.574-2.641,1.2c-2.337,1.184-5.215,1.824-7.439,3.12
			c-0.08,0-0.16,0-0.24,0c-3.498,1.303-7.11,2.489-10.32,4.08c-0.08,0-0.159,0-0.239,0c-2.466,0.895-5.023,1.696-7.2,2.88
			c-0.08,0-0.16,0-0.24,0c-1.455,0.465-2.912,0.928-4.08,1.68c-0.08,0-0.16,0-0.24,0c-0.578-0.098-0.521,0.438-1.199,0.24
			c-1.047,0.313-2.123,0.598-2.881,1.2c-0.08,0-0.159,0-0.239,0c-1.455,0.465-2.913,0.928-4.08,1.68c-0.08,0-0.16,0-0.24,0
			c-1.047,0.313-2.123,0.598-2.88,1.2c-0.502,0.139-0.686,0.596-1.44,0.48c-1.047,0.313-2.122,0.598-2.88,1.2
			c-0.502,0.138-0.685,0.595-1.439,0.479c-1.455,0.465-2.913,0.928-4.08,1.68c-0.502,0.139-0.686,0.596-1.44,0.48
			c-1.047,0.313-2.122,0.598-2.88,1.2c-0.08,0-0.16,0-0.24,0c-1.047,0.313-2.122,0.597-2.88,1.2c-0.08,0-0.16,0-0.24,0
			c-1.046,0.313-2.122,0.597-2.88,1.199c-0.08,0-0.16,0-0.24,0c-1.046,0.313-2.122,0.598-2.88,1.2
			c-1.159,0.761-3.025,0.814-4.08,1.68c-0.08,0-0.159,0-0.239,0c-2.482,1.119-6.003,1.197-7.681,3.12
			c-0.21,1.102,1.786,1.241,2.4,0.721c0.146-0.014,0.284-0.036,0.24-0.24c0.982,0.103,1.537-0.223,2.159-0.48c0.24,0,0.48,0,0.721,0
			c1.736-0.423,3.463-0.857,4.8-1.68c0.771-0.269,1.423-0.657,2.4-0.72c1.063-0.376,2.354-0.526,3.119-1.2c0.08,0,0.16,0,0.24,0
			c1.518-0.482,3.096-0.904,4.32-1.68c0.663-0.298,1.219-0.701,2.16-0.721c1.047-0.313,2.122-0.598,2.88-1.199c0.08,0,0.16,0,0.24,0
			c1.046-0.313,2.122-0.598,2.88-1.2c0.08,0,0.16,0,0.24,0c1.454-0.466,2.912-0.928,4.079-1.681c1.33-0.75,3.106-1.054,4.32-1.92
			c0.08,0,0.16,0,0.24,0c0.982-0.057,1.352-0.728,2.4-0.72c1.046-0.313,2.122-0.598,2.88-1.2c1.947-1.013,4.421-1.498,6.239-2.64
			c0.08,0,0.16,0,0.24,0c2.782-0.818,5.089-2.112,7.92-2.88c1.617-0.544,3.284-1.036,4.561-1.92c2.805-1.115,5.421-2.419,8.399-3.36
			c1.801-0.76,3.759-1.361,5.28-2.4c3.327-1.713,7.116-2.964,10.32-4.8c0.08,0,0.16,0,0.24,0c0.926-0.034,1.108-0.811,2.159-0.72
			c2.281-0.999,4.716-1.845,6.721-3.12c0.08,0,0.159,0,0.239,0c2.423-1.014,4.77-2.104,6.474-3.835
			c-0.189,0.135-0.392,0.258-0.586,0.389c0.194-0.131,0.396-0.254,0.587-0.39l-0.001,0.001c0.002-0.002,0.005-0.003,0.007-0.005
			l-0.001-0.001c0.079-0.081,0.165-0.155,0.241-0.239c-0.29-0.051-0.147-0.267,0-0.479c0.349-0.371,1.229-0.212,1.92-0.24
			c0.52-1.084,2.423-2.051,1.92-3.36c-9.657-0.138-18.038,1.003-27.601,0.96C393.591,641.235,392.254,640.778,391.921,641.325z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M420.48,651.646c-2.09,0.789-2.768,2.991-5.04,3.6
			c-0.038,0.281-0.438,0.202-0.72,0.24c-0.653,0.626-1.704,0.855-2.64,1.2c-1.305,0.535-2.594,1.086-3.84,1.68
			c-0.194,0.046-0.239,0.24-0.48,0.24c-0.039,0.281-0.438,0.202-0.72,0.239c-0.937,0.345-1.987,0.574-2.641,1.2
			c-0.676,0.205-0.984,0.775-1.92,0.721c-0.936,0.344-1.986,0.573-2.64,1.199c-0.676,0.205-0.984,0.775-1.92,0.721
			c-1.512,0.568-3.09,1.07-4.32,1.92c-1.544,0.696-3.018,1.462-4.8,1.92c-0.936,0.344-1.986,0.573-2.64,1.2c-0.08,0-0.16,0-0.24,0
			c-0.936,0.344-1.986,0.573-2.641,1.199c-0.856,0.344-1.547,0.854-2.64,0.96c-1.455,0.466-2.912,0.928-4.08,1.681
			c-4.389,1.931-9.409,3.23-13.92,5.04c-0.08,0-0.16,0-0.24,0c-1.369,0.31-2.78,0.579-3.84,1.2c-0.08,0-0.16,0-0.24,0
			c-1.118,0.241-2.278,0.44-3.12,0.96c-0.079,0-0.159,0-0.239,0c-1.188,0.251-2.491,0.389-3.36,0.96c-0.08,0-0.16,0-0.24,0
			c-1.5,0.339-3.139,0.541-4.319,1.199c-0.08,0-0.16,0-0.24,0c-0.955-0.074-1.206,0.555-2.16,0.48
			c-2.143,1.298-5.748,1.132-7.68,2.64c0.882,0.395,2.643-0.392,3.6-0.72c0.873-0.728,3.018-0.183,4.08-0.72
			c2.349,0.028,4.186-0.455,6-0.96c4.946-1.054,9.844-2.157,14.16-3.84c0.08,0,0.16,0,0.24,0c1.895-0.266,3.145-1.176,5.04-1.44
			c1.063-0.376,2.354-0.525,3.12-1.2c1.708-0.691,3.391-1.409,5.279-1.92c2.015-0.705,4.087-1.354,5.76-2.399
			c3.123-1.278,6.053-2.748,9.36-3.841c1.126-0.394,2.305-0.734,3.12-1.439c1.361-0.559,2.494-1.346,4.08-1.681
			c1.574-0.665,3.26-1.22,4.56-2.159c2.208-0.992,4.265-2.137,6.721-2.881c1.255-0.504,2.622-0.897,3.6-1.68
			c0.284-0.276,0.849-0.271,1.44-0.24c1.932-1.027,3.372-2.548,4.56-4.319C421.849,651.718,421.301,651.545,420.48,651.646z
			 M414.686,657.546c-0.01,0.004-0.019,0.008-0.028,0.012c0.013-0.006,0.026-0.011,0.039-0.017
			C414.692,657.543,414.689,657.545,414.686,657.546z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M416.881,675.405c-0.9,0.699-1.7,1.499-2.4,2.4
			c-0.085,0.314-0.422,0.377-0.479,0.72c-0.343,0.058-0.405,0.395-0.72,0.479c-11.123,13.357-25.666,23.294-38.641,34.801
			c0.209,0.591,0.56,1.04,1.44,0.96c12.679-8.201,23.623-18.137,34.319-28.32c0-0.08,0-0.16,0-0.24
			c2.948-3.772,6.956-6.484,8.4-11.76C417.734,674.339,417.531,675.096,416.881,675.405z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M96.48,705.646c0.964,0.556,2.957,0.083,4.32,0.239c0.24,0,0.48,0,0.72,0
			c1.682,0.559,4.398,0.082,6.48,0.24c0.16,0,0.32,0,0.48,0c0.571,0.398,2.79,0.398,3.36,0c2,0,4,0,6,0
			c1.683,0.399,5.037,0.399,6.72,0c4.552,0.072,8.571-0.389,11.76-1.68c0.08,0,0.16,0,0.24,0c2.266-0.534,4.28-1.32,6-2.4
			c0.08,0,0.16,0,0.24,0c0.16,0,0.32,0,0.48,0c2.051-0.492,10.604-4.986,5.28-5.52c-0.08,0-0.16,0-0.24,0
			c-1.011,0.589-2.244,0.956-3.12,1.68c-0.307,0.014-0.333,0.308-0.72,0.24c-1.254,0.426-2.375,0.985-3.36,1.68
			c-2.695,1.466-6.122,2.198-9.36,3.12c-0.08,0-0.16,0-0.24,0c-1.337,0.183-2.958,0.082-4.08,0.48c-0.08,0-0.16,0-0.24,0
			c-4.24,0-8.48,0-12.72,0c-2.162-0.4-5.998-0.4-8.16,0c-0.8,0-1.6,0-2.4,0c-1.125,0.155-2.874-0.314-3.6,0.239
			c-0.516,0.445-1.758,0.162-2.64,0.24c-1.461,0.101-4.979,0.565-4.08,1.44C94.514,705.159,95.19,705.842,96.48,705.646z
			 M149.407,698.628c0.447-0.396,0.674-0.79,0.531-1.163C150.082,697.838,149.854,698.231,149.407,698.628
			c-0.983,0.872-3.028,1.761-4.519,2.484C146.377,700.389,148.423,699.5,149.407,698.628z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M411.121,745.965c-0.987,0.693-2.524,0.836-3.36,1.681c0,0.08,0,0.16,0,0.24
			c-0.08,0-0.16,0-0.24,0c-1.774,0.465-2.942,1.537-4.8,1.92c-0.735,0.233-2.355,0.831-2.16,1.2c-0.588,0.451-1.174,0.905-2.16,0.96
			c-3.155,1.164-5.84,2.799-9.12,3.84c-1.255,0.505-2.622,0.897-3.6,1.68c-1.02,0.66-2.494,0.866-3.36,1.68c-0.08,0-0.159,0-0.239,0
			c-0.927,0.034-1.109,0.812-2.16,0.721c-1.256,0.505-2.622,0.897-3.601,1.68c-0.842,0.278-1.295,0.944-2.399,0.96
			c-0.935,0.266-1.736,0.664-2.4,1.2c-2.925,1.314-5.707,2.772-8.88,3.84c-1.094,0.346-2.055,0.825-2.88,1.44
			c-0.842,0.277-1.296,0.944-2.4,0.96c-0.934,0.266-1.736,0.663-2.399,1.199c-0.08,0-0.16,0-0.24,0
			c-1.575,0.666-3.26,1.22-4.561,2.16c-2.584,1.175-5.045,2.475-7.92,3.36c-0.936,0.344-1.986,0.573-2.64,1.2
			c-0.96,0.8-2.956,0.563-3.36,1.92c0.095,0.062,0.192,0.111,0.291,0.16c-0.016,0.028-0.037,0.051-0.051,0.079
			c0.067-0.001,0.133-0.007,0.199-0.009c1.883,0.808,4.382-0.461,6.281-1.19c2.256-0.868,4.404-2.139,6.479-2.641
			c1.126-0.394,2.306-0.734,3.12-1.439c0.08,0,0.16,0,0.24,0c1.436-0.564,2.944-1.056,4.08-1.92c0.842-0.278,1.295-0.944,2.399-0.96
			c0.935-0.267,1.736-0.664,2.4-1.2c0.08,0,0.16,0,0.24,0c0.934-0.267,1.736-0.664,2.399-1.2c0.08,0,0.16,0,0.24,0
			c1.575-0.665,3.261-1.22,4.561-2.16c0.08,0,0.159,0,0.239,0c0.935-0.266,1.736-0.663,2.4-1.2c2.926-1.314,5.707-2.772,8.88-3.84
			c1.436-0.564,2.944-1.056,4.08-1.92c1.322-0.598,2.484-1.355,4.08-1.68c1.255-0.505,2.622-0.898,3.6-1.68
			c0.843-0.278,1.296-0.945,2.4-0.961c0.735-0.232,2.355-0.831,2.16-1.199c0.588-0.452,1.174-0.906,2.16-0.96
			c0.737-0.063,1.079-0.521,1.68-0.721c0.431,0.03,0.506-0.293,0.96-0.239c0.882-0.399,1.843-0.718,2.64-1.2
			c0.85-0.431,1.639-0.922,2.641-1.2c0.658-0.381,1.563-0.518,2.16-0.96c2.985-1.414,5.934-2.865,8.88-4.32
			c0.025-0.985,0.039-1.959-0.48-2.399C415.413,743.857,413.441,745.086,411.121,745.965z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M546.961,740.205c0.039-0.279-0.053-0.427-0.24-0.479c0-0.08,0-0.16,0-0.24
			c0.545-0.255,0.092-1.509,0.24-2.16c-0.158-2.001,0.318-4.638-0.24-6.24c0-0.239,0-0.479,0-0.72
			c-0.156-1.443,0.316-3.516-0.239-4.56c0-0.16,0-0.32,0-0.48c-0.118-2.683,0.141-5.74-0.721-7.68
			c-0.025-0.482-0.061-0.954-0.09-1.433c-0.021-0.431-0.051-0.86-0.08-1.29c-0.007-0.093-0.015-0.184-0.021-0.277
			c-0.042-0.589-0.079-1.178-0.135-1.767c-0.686-8.803-2.016-17.056-4.714-24.994c-3.08-9.062-4.796-18.78-6.239-28.56
			c-0.706-4.777-2.199-9.519-3.12-14.4c-2.278-12.069-3.547-25.766-5.28-38.16c-0.401-2.871-1.065-4.632-1.92-7.68
			c0.281-0.039,0.202-0.438,0.24-0.72c0.559-2.881,0.08-6.8,0.239-10.08c0-0.4,0-0.8,0-1.2c0.398-0.336,0.398-2.304,0-2.64
			c0-0.24,0-0.48,0-0.721c-0.152-0.887,0.312-2.391-0.239-2.88c-4.674-26.689-9.007-56.006-12.96-83.52
			c-0.527-3.668-0.462-7.217-1.2-10.561c-2.07-9.377-5.672-18.293-8.641-27.36c-1.884-5.754-4.265-11.555-7.199-17.039
			c-2.81-5.25-5.738-11.111-9.12-14.881c-2.123-2.365-6.963-5.089-10.32-7.199c-9.927-6.242-22.044-11.293-34.32-15.36
			c-0.601-0.759-1.641-1.08-2.64-1.44c-4.396-3.09-9.261-5.572-15.12-6.96c-4.956-1.174-10.383-1.219-15.623-2.116
			c-0.002,0-0.003,0-0.004,0c-1.096-0.243-2.182-0.501-3.258-0.773c-0.001,0-0.002,0-0.002,0c-1.075-0.271-2.141-0.558-3.195-0.856
			c-0.002,0-0.004-0.001-0.007-0.002c-10.198-3.167-19.68-7.986-28.312-11.852c-0.601-0.679-1.82-0.739-2.64-1.2
			c-0.225-0.735-1.33-0.59-1.68-1.2c-0.419-2.941-0.385-6.335-1.2-8.88c1.417-2.423,3.396-4.284,4.56-6.96
			c0.679-1.309,1.366-1.782,2.308-2.013c0.061,0.032,0.121,0.059,0.183,0.077c0.013,0.003,0.025,0.002,0.038,0.004
			c0.051,0.011,0.102,0.021,0.154,0.016c0.027-0.003,0.057-0.019,0.085-0.026c0.04-0.011,0.08-0.016,0.122-0.039
			c0.073-0.04,0.149-0.097,0.23-0.179c-1.19-9.663,3.717-14.679,4.561-23.04c0.536-0.103,0.097-1.183,0.239-1.68c0-0.8,0-1.6,0-2.4
			c-0.131-0.349,0.28-1.24-0.239-1.2c-0.281-0.839-0.512-1.729-1.921-1.44c0-1.28,0-2.56,0-3.84c-0.169-1.609,0.247-2.633,0.24-4.08
			c0.146-0.575,0.346-1.095,0.24-1.92c1.182-11.058,4.695-19.785,6.72-30c0.271-1.953,0.678-6.115-0.24-7.68
			c-0.079-1.361-0.301-2.579-0.72-3.6c-0.146-0.574,0.302-1.741-0.24-1.92c0.284-3.164-2.271-5.778-0.72-8.64c0-0.56,0-1.12,0-1.68
			c0.537-0.104,0.098-1.183,0.24-1.68c0-1.04,0-2.08,0-3.12c0-0.72,0-1.44,0-2.16c0.897-1.663,1.593-3.527,2.16-5.52
			c0.337-1.183,0.655-2.384,0.72-3.84c0.163-0.877,0.335-1.745,0.24-2.88c0.746-0.141,0.746-2.738,0-2.88
			c-0.233-0.006-0.187-0.293-0.24-0.48c0-0.24,0-0.48,0-0.72c0.554-0.726,0.085-2.476,0.24-3.6c0-1.36,0-2.72,0-4.08
			c-0.08,0-0.16,0-0.24,0c0-0.88,0-1.76,0-2.64c0-0.08,0-0.16,0-0.24c0.554-0.726,0.085-2.476,0.24-3.6
			c-0.158-1.762,0.317-4.157-0.24-5.52c-0.02-0.38,0.115-0.916-0.24-0.96c0.087-2.167-0.229-3.931-0.479-5.76
			c-0.473-0.567,0.066-2.146-0.48-2.64c-0.478-2.803-0.942-5.617-0.96-8.88c-0.68,0.04,0.199-1.479-0.479-1.44c0-0.16,0-0.32,0-0.48
			c0-0.72,0-1.44,0-2.16c0.554-0.726,0.084-2.475,0.239-3.6c-0.155-1.204,0.315-3.035-0.239-3.84c0-0.16,0-0.32,0-0.48
			c0-1.12,0-2.24,0-3.36c-0.08,0-0.16,0-0.24,0c-0.153-0.887,0.311-2.391-0.24-2.88c0-1.92,0-3.84,0-5.76
			c-0.355-1.404-0.569-2.95-1.2-4.08c-1.421-2.898-3.76-4.88-5.04-7.92c-0.229-0.49-0.49-0.95-0.72-1.44
			c-0.978-1.502-1.632-3.328-2.88-4.56c0.117-0.696-0.994-1.38-0.48-1.68c0.773-0.053,1.016,0.424,1.921,0.24
			c0.006,0.233,0.293,0.186,0.479,0.24c0.965,0.555,2.957,0.083,4.32,0.24c4.164,0.252,8.02,0.891,10.56,2.88
			c0.844,0.661,1.417,1.966,2.641,1.92c0.657-2.369-0.937-4.481-1.921-6c0-0.08,0-0.16,0-0.24c-0.979-0.78-1.86-1.66-2.64-2.64
			c-0.889-0.792-1.699-1.66-2.64-2.4c-6.412-6.708-23.239-3.001-27.601-11.76c0-0.08,0-0.16,0-0.24
			c-0.699-0.9-1.499-1.701-2.399-2.4c-0.194-0.046-0.239-0.241-0.48-0.24c-2.358-2.441-5.439-4.16-9.12-5.28
			c3.143-1.498,6.438-2.843,8.4-5.52c1.449-1.432,2.737-4.301,1.92-7.2c-0.694-2.376-1.263-8.344-3.84-6.48
			c-0.759,0.602-0.857,1.863-1.44,2.64c-0.325,0.875-1.214,1.186-1.439,2.16c-0.9,0.699-1.701,1.499-2.4,2.4
			c-2.007,1.833-3.631,4.049-6.96,4.56c0,0.08,0,0.16,0,0.24c-0.651,0.149-1.904-0.305-2.16,0.24c-1.2,0-2.399,0-3.6,0
			c-0.284-0.276-0.849-0.272-1.44-0.24c0-0.08,0-0.16,0-0.24c-1.136-1.004-1.504,1.483-2.64,0.48
			c-6.356-2.684-12.553-5.527-18.48-8.64c-2.705-1.294-5.791-2.209-9.359-2.64c-0.733,0.173-0.875-0.245-1.44-0.24
			c-2.161,0.158-4.958-0.318-6.72,0.24c-0.08,0-0.16,0-0.24,0c-0.4,0-0.8,0-1.2,0c-7.233,1.083-14.324,4.252-22.08,4.32
			c-1.261,0.011-1.588,0.215-2.64-0.48c-0.966,0.154-2.552-0.313-3.12,0.24c-3.494,0.346-7.095,0.585-9.84,1.68
			c-0.08,0-0.16,0-0.24,0c-12.733,2.43-20.55,10.309-26.88,19.44c-4.766,6.875-7.814,15.241-12.24,22.32
			c-1.443,2.308-3.228,4.425-4.08,6.96c0.315,0.308,0.885,0.308,1.2,0c0.582-0.074,1.062-1.477,1.44-0.96
			c-3.522,4.878-6.163,10.637-6.24,18.96c-0.205-0.044-0.227,0.094-0.24,0.24c-0.553,0.647-0.085,2.315-0.24,3.36
			c0.08,0,0.16,0,0.24,0c0.146,0.574-0.301,1.741,0.24,1.92c0,0.16,0,0.32,0,0.48c-0.545,0.255-0.092,1.508-0.24,2.16
			c0,0.88,0,1.76,0,2.64c0.002,1.998-0.107,4.107,0.48,5.52c0.435,2.017,0.616,4.56,0.24,6.72c-0.39,0.65-0.31,1.771-0.48,2.64
			c0,0.48,0,0.96,0,1.44c-0.233,0.007-0.187,0.294-0.24,0.48c-0.549,0.411-0.088,1.832-0.24,2.64c0.08,0,0.16,0,0.24,0
			c0.146,0.574-0.301,1.741,0.24,1.92c0,0.4,0,0.8,0,1.2c-0.545,0.255-0.092,1.509-0.24,2.16c-0.025,2.505-0.044,5.004,0.24,7.2
			c0.08,0,0.16,0,0.24,0c-0.198,0.678,0.338,0.622,0.24,1.2c-0.537,0.104-0.097,1.183-0.24,1.68c0,1.2,0,2.4,0,3.6
			c-0.557,1.283-0.082,3.598-0.24,5.28c0.116,0.916-0.068,1.532-0.48,1.92c-1.123,0.397,0.198,3.237-1.2,3.36
			c-6.926,0.834-7.094,8.426-7.92,15.36c-0.881,0.798-0.664,2.696-0.72,4.32c0.15,0.729-0.307,2.067,0.24,2.4
			c-0.021,0.341-0.04,0.68,0.24,0.72c-0.633,7.631,2.896,12.429,6.24,16.8c0.686,0.754,1.227,1.653,2.16,2.16
			c1.082,1.398,2.439,2.521,3.84,3.6c0.194,0.045,0.239,0.241,0.48,0.24c0.861,1.379,2.458,2.022,3.12,3.6c0.08,0,0.16,0,0.24,0
			c4.586,4.293,7.451,10.309,10.8,15.84c0.848,1.632,2.508,2.452,4.56,2.88c0.16,0,0.32,0,0.48,0c0.007,0.233,0.294,0.186,0.48,0.24
			c-0.108,0.668,1.309-0.188,1.2,0.48c0,0.64,0,1.28,0,1.92c0.119,1.161-0.34,2.9,0.48,3.36c0.146,0.574-0.301,1.741,0.24,1.92
			c0.146,0.574-0.301,1.741,0.24,1.92c0.808,7.352,2.488,13.832,4.08,20.4c0.261,0.981-0.256,1.184-0.72,1.44
			c-1.965,0.836-1.586,4.015-2.64,5.76c-0.347,1.152-0.566,4.242,0.24,5.04c0.409,3.121-0.611,6.479-0.96,9.6
			c-0.491,3.109-0.7,6.5-0.72,10.08c-0.317,5.043-1.092,9.627-1.68,14.4c-4.426,1.285-7.385,6.221-11.52,7.44
			c-7.108,2.096-14.005,4.277-21.12,6.24c-3.701,1.021-7.139,1.894-11.04,2.16c-1.822,0.125-3.827,0.573-5.76,0.96
			c-1.86,0.373-3.781,0.331-5.52,0.72c-2.24,0.501-4.633,2.088-6.96,3.12c-15.517,6.879-34.43,12.071-48.24,20.64
			c0.044,0.204-0.094,0.227-0.24,0.24c-1.024,0.735-1.87,1.649-2.64,2.64c-0.106,0.214-0.211,0.43-0.48,0.48
			c-0.78,0.979-1.451,2.069-2.16,3.12c0,0.08,0,0.16,0,0.24c-0.489,0.07-0.471,0.649-0.96,0.72c-1.141,0.939-2.18,1.979-3.12,3.12
			c-0.08,0-0.16,0-0.24,0c0,0.08,0,0.159,0,0.239c-1.201,1.039-2.258,2.222-3.12,3.601c-0.769,0.432-1.024,1.376-1.44,2.16
			c-1.547,2.373-3.203,4.637-4.56,7.2c-0.536,0.663-0.934,1.466-1.2,2.399c-3.386,7.734-5.101,17.14-6.48,26.88
			c-0.475,5.65,0.067,12.334,0,17.761c0.059,0.232,0.1,0.466,0.148,0.698c0,0.001,0,0.003,0,0.004
			c0.019,0.328,0.045,0.654,0.091,0.978c0.041,0.286,0.117,0.564,0.208,0.838c0,0.001,0,0.002,0.001,0.003v-0.001
			c0,0.001,0,0.002,0.001,0.003c0,0,0-0.001-0.001-0.002c0.708,6.252-1.47,12.643-2.61,18.6c-0.295,1.181-0.641,4.477,0.24,5.279
			c1.532,8.489-3.933,13.714-5.28,20.88c-0.37,0.465-0.527,2.758,0.24,2.881c-0.222,1.502,0.289,2.271,0.48,3.359
			c0,1.601,0,3.2,0,4.8c-0.647,2.553-0.659,5.741-1.68,7.92c-1.111,5.529-0.166,13.114-0.48,19.44c0,0.88,0,1.76,0,2.64
			c0,0.881,0,1.761,0,2.641c-0.08,0-0.16,0-0.24,0c0,3.359,0,6.72,0,10.08c0,0.88,0,1.76,0,2.64c-0.156,1.284,0.315,3.195-0.24,4.08
			c-0.117,5.803-1.463,10.377-1.92,15.84c-0.468,2.332-0.953,4.647-1.2,7.2c-0.204-0.044-0.226,0.094-0.24,0.24
			c-0.56,3.28-0.081,7.6-0.24,11.28c0,0.239,0,0.479,0,0.72c-0.555,0.885-0.083,2.796-0.24,4.08c0,0.319,0,0.64,0,0.96
			c-0.553,0.646-0.085,2.314-0.24,3.359c0,0.32,0,0.641,0,0.96c-0.551,0.489-0.087,1.994-0.24,2.881c0,0.239,0,0.479,0,0.72
			c-0.407,3.513-0.625,7.214-0.72,11.04c-1.995,4.325-5.808,6.832-5.28,13.68c-1.009,0.671-0.239,3.121-0.48,4.561
			c-6.213,26.507-13.586,51.854-17.04,81.12c-0.542,0.179-0.094,1.346-0.24,1.92c-0.537,0.104-0.097,1.183-0.24,1.68
			c-0.542,0.179-0.094,1.347-0.24,1.92c-0.503,2.377-0.815,4.945-0.96,7.681c-0.544,0.255-0.091,1.508-0.24,2.159
			c-0.371,1.55-0.434,3.406-0.48,5.28c-0.895,2.065-0.519,5.401-0.72,8.16c0.035,1.801,0.211,2.399,0,4.08
			c-0.621,0.715-0.072,2.302,0.24,2.88c75.277,0.56,151.604,0.08,227.28,0.24c45.921,0,91.839,0,137.76,0c0.08,0,0.16,0,0.24,0
			c0,0.08,0,0.16,0,0.24c3.2,0,6.4,0,9.601,0c0-0.08,0-0.16,0-0.24c1.52-0.641,1.563-2.757,1.439-5.04
			c-0.234-1.366,0.52-3.72-0.479-4.32c0.029-0.99-0.417-1.503-0.24-2.64c0.325-0.074,0.199-0.602,0.24-0.96
			c0.399-5.041,0.399-11.76,0-16.801c0-0.239,0-0.479,0-0.72c-0.157-1.522,0.316-3.676-0.24-4.8c0-0.16,0-0.32,0-0.48
			c-0.157-1.442,0.316-3.516-0.24-4.56c0-0.24,0-0.479,0-0.72c-0.156-1.363,0.315-3.355-0.24-4.32
			c0.039-0.279-0.053-0.427-0.239-0.479c0-0.08,0-0.16,0-0.24c0.321-0.81,0.602-3.637-0.24-4.08c-0.195-1.805-0.901-3.1-0.72-5.28
			c-0.157-1.363,0.315-3.355-0.24-4.32c0.021-0.34,0.039-0.679-0.24-0.72c0.961-3.691-2.438-5.408-2.64-8.64
			c-0.077-1.227,0.705-2.981,0.96-4.561c0.787-4.889,1.072-9.746,1.439-15.12c0.87-0.569,0.331-2.55,0.48-3.84
			c0-0.239,0-0.479,0-0.72c0.558-1.522,0.082-4.078,0.24-6c-0.151-0.729,0.307-2.067-0.24-2.4c0-0.399,0-0.8,0-1.199
			c-0.158-1.683,0.316-3.997-0.24-5.28c0-0.16,0-0.32,0-0.48c-0.082-4.317-0.336-8.463-0.72-12.479c0-0.24,0-0.48,0-0.721
			c-0.158-2.081,0.317-4.798-0.24-6.479c0.039-0.279-0.053-0.427-0.24-0.48c0-0.079,0-0.159,0-0.239c0.399-1.126,0.399-3.915,0-5.04
			c-0.371-1.309-0.45-2.91-0.479-4.561c0-2.16,0-4.319,0-6.479c0-0.08,0-0.16,0-0.24c0.225-1.055,1.185-1.375,0.96-2.88
			c0.427-1.173,0.587-2.613,0.72-4.08c0.696-7.147,2.113-14.843,3.36-20.64c-0.156-1.204,0.314-3.035-0.24-3.841
			c-0.716-0.654,0.056-1.85,0.24-2.399c1.098,0.485,1.337,3.125,1.68,4.8c2.625,12.838,5.577,26.095,9.36,37.92
			c0.122,0.838,0.107,1.813,0.479,2.4c-0.185,0.904,0.293,1.146,0.24,1.92c-0.104,5.225,3.201,7.038,4.08,11.28
			c0.154,0.966-0.313,2.552,0.24,3.119c0,0.08,0,0.16,0,0.24c0,1.12,0,2.24,0,3.36c-0.923,1.317-2.412,2.067-2.641,4.08
			c-0.281,0.038-0.202,0.438-0.239,0.72c0.153,0.966-0.313,2.552,0.239,3.12c0,0.24,0,0.479,0,0.72
			c0.307,0.894,0.577,1.823,0.96,2.641c-0.098,0.578,0.438,0.521,0.24,1.199c-0.556,1.044-0.083,3.117-0.24,4.561
			c0,1.279,0,2.56,0,3.84c0,1.84,0,3.68,0,5.52c-0.555,0.806-0.084,2.637-0.239,3.841c0,1.6,0,3.199,0,4.8c0,0.32,0,0.64,0,0.96
			c-0.399,0.729-0.399,3.111,0,3.84c-0.021,0.341-0.04,0.68,0.239,0.72c-0.426,0.454-0.175,1.586-0.239,2.4
			c-0.522,1.317-0.205,3.476-0.96,4.56c-0.553,0.568-0.086,2.154-0.24,3.12c-0.398,0.729-0.398,3.111,0,3.84
			c-0.046,0.195-0.241,0.239-0.24,0.48c-0.51,7.237-1.566,15.015-1.466,22.369c-0.013,0.166-0.007,0.351,0.009,0.545
			c0.008,0.361,0.004,0.727,0.018,1.086c0,0.08,0,0.16,0,0.24c-0.895,4.386-0.81,9.751-0.961,14.88c0,0.24,0,0.479,0,0.72
			c-0.559,2.881-0.08,6.8-0.239,10.08c0.131,0.349-0.28,1.24,0.239,1.2c0,0.16,0,0.32,0,0.479c-0.541,0.18-0.094,1.347-0.239,1.921
			c-0.08,0-0.16,0-0.24,0c0.156,1.283-0.315,3.194,0.24,4.079c0.09,1.19,1.249,1.312,2.64,1.2c0,0.08,0,0.16,0,0.24
			c23.601,0.56,48.24,0.08,72.24,0.24c9.44-0.16,19.52,0.319,28.56-0.24c0-0.08,0-0.16,0-0.24c0.945-4.095,1.515-8.565,2.16-12.96
			c0.371-1.629,0.413-3.587,0.48-5.52c0-0.32,0-0.641,0-0.96c0.398-0.729,0.398-3.112,0-3.841c0-0.079,0-0.159,0-0.239
			c0.204,0.044,0.227-0.094,0.24-0.24c0.557-1.203,0.082-3.438,0.239-5.04c0-0.24,0-0.48,0-0.72c0.558-1.442,0.082-3.918,0.24-5.761
			c-0.02-0.38,0.115-0.915-0.24-0.96c0-0.159,0-0.319,0-0.479c0.521,0.04,0.109-0.852,0.24-1.2c0.08,0,0.16,0,0.24,0
			c0-0.96,0-1.92,0-2.88c0-8.4,0-16.8,0-25.2c0-1.04,0-2.08,0-3.12C547.121,740.205,547.041,740.205,546.961,740.205z
			 M522.685,603.63C522.685,603.631,522.685,603.631,522.685,603.63c0,0.002-0.001,0.003-0.001,0.004
			C522.684,603.633,522.684,603.632,522.685,603.63z M441.601,408.524c0.08,0,0.16,0,0.24,0c1.476,0.924,3.505,1.295,5.28,1.92
			c2.676,0.764,4.993,1.886,7.439,2.88c0.634,0.646,1.608,0.952,2.641,1.2c0.935-0.056,1.244,0.515,1.92,0.72
			c0,0.08,0,0.159,0,0.239c0.853,0.027,0.872,0.888,1.92,0.721c0.193,0.127,0.39,0.25,0.72,0.239
			c2.027,1.493,4.585,2.456,6.96,3.601c0.08,0,0.16,0,0.24,0c1.336,1.224,3.163,1.957,4.56,3.12
			c0.343,0.058,0.405,0.395,0.721,0.479c0.86-0.061,0.879,0.721,1.68,0.72c0.316,0.484,0.788,0.813,1.44,0.96
			c0.998,1.002,2.092,1.908,3.359,2.641c0.053-0.188,0.201-0.279,0.48-0.24c1.016,0.585,1.13,2.07,1.68,3.12
			c0.278,0.201,0.644,0.316,1.2,0.24c0.134,0.346,0.374,0.585,0.72,0.72c-0.011,0.41,0.179,0.622,0.48,0.72
			c-0.054,0.454,0.271,0.53,0.239,0.96c1.892,2.83,3.434,6.008,5.28,8.881c0.853,1.867,1.922,3.519,2.641,5.52
			c0.397,0.802,0.802,1.598,1.199,2.4c0.523,0.757,1.076,1.484,1.2,2.64c1.443,3.196,2.794,6.486,3.84,10.08
			c0.196,1.084,0.694,1.866,0.96,2.88c0.69,1.87,1.331,3.789,1.92,5.76c0.238,1.122,0.723,1.998,0.96,3.12
			c1.162,4.198,2.561,8.16,3.36,12.72c-1.466-0.054-2.166-0.874-3.84-0.72c-0.649-0.398-2.95-0.398-3.6,0
			c-2.494-0.094-4.43,0.371-6.48,0.72c-3.689,0.791-7.22,1.741-10.8,2.641c-0.068,0.066-0.152,0.115-0.246,0.156
			c-0.001,0-0.002,0-0.002,0c0.095-0.039,0.18-0.09,0.248-0.156c-1.284,0.156-3.195-0.315-4.08,0.24c-1.36,0-2.72,0-4.08,0
			c-5.396,0.059-12.464-0.169-17.04,0.239c-0.159,0.015-1.463,0.032-0.24,0.24c0,0.08,0,0.16,0,0.24c-0.08,0-0.16,0-0.24,0
			c-8.148,0.251-15.504,1.296-22.8,2.399c-4.986-0.89-14.954,1.378-17.04-0.72c0.159,0.16-0.148,0.522-0.24,0.48
			c-0.031-0.592-0.036-1.156,0.24-1.44c0.08,0,0.16,0,0.24,0c0.681-6.119,2.754-10.846,2.88-17.52
			c0.592-1.731,0.886-5.031,0.48-6.96c0-0.24,0-0.48,0-0.721c0-0.08,0-0.159,0-0.239c0.08,0,0.159,0,0.239,0
			c-0.009-2.25,0.602-3.88,0.48-6.24c1.01-3.551,0.798-8.322,0.96-12.721c0-0.64,0-1.279,0-1.92c0.399-2.082,0.399-5.837,0-7.92
			c0-0.399,0-0.8,0-1.2c0-0.079,0-0.159,0-0.239c0.96-4.4,1.666-9.054,3.36-12.721c0.645-0.715,0.929-1.79,1.439-2.64
			c2.915-5.404,6.088-10.552,11.04-13.92C439.076,407.369,439.948,408.337,441.601,408.524z M494.725,487.601
			c-4.289,0.751-8.495,2.038-12.795,2.678C486.229,489.638,490.435,488.35,494.725,487.601z M481.073,490.4
			c-0.675,0.086-1.352,0.159-2.032,0.204c0.68-0.045,1.355-0.118,2.03-0.204C481.072,490.4,481.072,490.4,481.073,490.4z
			 M400.79,392.776c-3.952-1.12-7.76-2.418-11.417-3.846C393.031,390.356,396.838,391.656,400.79,392.776z M370.081,400.604
			c-0.238-6.402-1.455-11.825-1.681-18.24c12.3,5.242,24.472,12.364,39.12,15.36c7.484,1.531,19.057,0.664,22.561,6.48
			c1.459-1.527,3.119,0.17,4.56,0.72c-6.927,8.685-14.159,17.363-15.12,31.68c-0.606,9.049-0.391,17.691-1.2,26.16
			c-0.38,3.979-0.549,7.921-0.884,11.793c0,0.002,0,0.005-0.001,0.008c-0.205,1.642-0.433,3.284-0.68,4.925
			c0-0.001,0-0.003,0.001-0.004c-0.141,0.934-0.286,1.873-0.44,2.812c0.151-0.938,0.299-1.874,0.439-2.808
			c-0.483,2.492-1.107,4.974-1.555,7.515c-2.811,15.956-3.607,33.249-2.4,51.12c-2.05-2.854-2.327-7-2.64-11.04
			c-0.959-12.384,1.279-26.892,1.92-37.68c-3.214,0.47-3.3,5.07-3.84,8.399c-1.299,7.994-1.349,18.275-0.96,27.12
			c-1.508-4.884-1.69-11.083-2.16-17.28c-0.451-5.943-0.076-12.434-2.4-16.8c-1.354,4.297-0.323,9.075,0,13.68
			c0.965,13.761,3,27.128,6.48,38.16c-3.449-2.718-5.75-6.524-8.16-10.319c-5.988-9.43-10.118-20.298-12-34.32
			c-0.338-2.518,0.012-5.325-1.44-7.68c-25.976,1.824-56.655-0.711-83.747,0.04c-1.03,0.019-2.04,0.056-3.052,0.091
			c-0.046,0.002-0.094,0.002-0.141,0.004c0.026-0.001,0.054-0.001,0.081-0.002c-0.85,0.029-1.699,0.058-2.533,0.101
			c0.025-0.001,0.05-0.004,0.075-0.005c-4.435,0.208-8.762,0.514-12.924,0.971c0.318-0.914,1.657,0.082,2.16-0.96
			c0.41-11.829,0.968-23.512,2.16-34.56c3.813-1.067,6.626-3.134,10.08-4.561c1.087,7.409,1.062,17.366,0.24,23.521
			c2.144,0.331,3.976-1.434,5.52-2.4c4.948-3.098,10.446-6.34,15.12-10.08c6.202-4.963,11.766-10.565,17.28-16.08
			c5.656-5.656,11.089-11.298,15.6-17.76c4.521-6.477,8.305-13.666,12.48-20.88C367.425,405.345,367.801,402.296,370.081,400.604z
			 M294.039,490.88c-1.404,0.12-2.779,0.267-4.118,0.445C291.26,491.146,292.637,491.002,294.039,490.88z M284.4,479.325
			c-0.155-3.11,0.285-6.813-0.202-9.592c0.042-0.286,0.084-0.571,0.135-0.841c0-0.004,0.001-0.007,0.002-0.011
			c0.023-0.038,0.041-0.08,0.065-0.117c0.667-0.933,0.78-2.42,1.68-3.12c0,0.48,0,0.96,0,1.44c-0.792,5.769-0.642,12.478-1.2,18.479
			c-0.08,0-0.16,0-0.24,0C284.106,483.938,284.542,481.344,284.4,479.325z M283.978,481.486c-0.176-3.82-0.273-8.157,0.183-11.52
			C283.705,473.328,283.803,477.669,283.978,481.486z M284.161,485.085c-0.057-1.095-0.124-2.302-0.181-3.567
			C284.038,482.782,284.104,483.992,284.161,485.085z M282.48,467.805c-0.549,0.571-0.79,1.45-1.92,1.44c-0.64,0-1.28,0-1.92,0
			c0.077-0.294-0.039-1.482-0.24-0.721c-1.087-3.372-0.5-7.265-0.96-10.319c1.913-0.567,4.624-0.337,6.24-1.2
			c0.309-0.331,1.042-0.237,1.68-0.24c2.736,0.097-0.169,2.572,0,3.84C284.595,463.199,283.383,465.348,282.48,467.805z
			 M282.721,499.484c-0.369,0.751-0.466,1.774-0.48,2.881c0,0.08,0,0.159,0,0.239c-0.369,0.751-0.466,1.774-0.48,2.88
			c-0.003,0.638,0.09,1.371-0.24,1.681c-0.386,0.813-0.492,1.908-0.48,3.12c0,0.08,0,0.16,0,0.239
			c-0.375,1.064-0.47,2.41-0.48,3.841c0,0.08,0,0.159,0,0.239c-0.368,0.993-0.441,2.279-0.48,3.601c0,0.08,0,0.16,0,0.24
			c-0.736,3.344-1.441,6.719-1.92,10.319c0,0.08,0,0.16,0,0.24c-0.364,0.596-0.463,1.457-0.48,2.4
			c0.031,0.591,0.036,1.156-0.24,1.439c-0.364,0.597-0.463,1.457-0.48,2.4c0,0.08,0,0.16,0,0.24c-0.418,1.021-0.641,2.239-0.72,3.6
			c0,0.08,0,0.16,0,0.24c-0.475,1.205-0.696,2.664-0.72,4.319c0,0.08,0,0.16,0,0.24c-0.549,0.411-0.088,1.832-0.24,2.641
			c0,0.159,0,0.319,0,0.479c-0.08,0-0.16,0-0.24,0c-0.067-1.631-0.382,0.275-0.24,0.96c0,0.8,0,1.601,0,2.4
			c-0.493,1.507-0.033,3.967-0.48,5.52c0,2.16,0,4.32,0,6.48c0,0.96,0,1.92,0,2.88c-0.534,6.506-0.037,14.043-0.48,20.64
			c0,0.88,0,1.76,0,2.641c0.188,1.229-0.336,1.743-0.24,2.88c-0.67,3.649-1.993,6.647-2.88,10.08c-0.461,1.06-1.002,2.038-0.96,3.6
			c0.076,0.557-0.039,0.922-0.24,1.2c-0.555,1.285-0.804,2.876-0.96,4.56c0,0.08,0,0.16,0,0.24c-0.412,0.708-0.509,1.731-0.48,2.88
			c0,0.08,0,0.16,0,0.24c-0.41,0.87-0.506,2.055-0.48,3.36c0,0.079,0,0.16,0,0.239c-0.549,0.411-0.088,1.832-0.24,2.641
			c0,0.08,0,0.16,0,0.24c-0.08,0-0.16,0-0.24,0c-0.353-1.78-0.223,2.259-0.24,3.119c0,0.561,0,1.12,0,1.681
			c-0.559,2.241-0.081,5.519-0.24,8.16c0,0.079,0,0.16,0,0.239c-0.376,1.064-0.47,2.41-0.48,3.841c-0.1,2.38-0.496,4.464-0.96,6.479
			c-0.467,1.134-0.653,2.548-0.72,4.08c-0.049,0.751,0.162,1.762-0.24,2.16c-0.388,0.972-0.49,2.229-0.48,3.6
			c-0.045,2.035-0.282,3.878-0.72,5.521c-0.666,2.774-1.517,5.363-1.92,8.399c-0.113,1.327-0.145,2.735-0.48,3.841
			c-0.08,0-0.16,0-0.24,0c-0.346-1.275-0.227,1.555-0.24,2.159c0.152,0.809-0.31,2.229,0.24,2.641c-0.006,1.366,0.61,2.11,0.48,3.6
			c0,2.72,0,5.44,0,8.16c-0.212,2.107-0.82,3.82-1.44,5.52c-0.08,0-0.16,0-0.24,0c-0.327,0.634-0.284,1.637-0.48,2.4
			c0,0.08,0,0.16,0,0.24c-0.08,0-0.16,0-0.24,0c-0.229,1.61-0.672,3.008-0.72,4.8c-0.002,0.638,0.091,1.371-0.24,1.68
			c-0.08,0-0.16,0-0.24,0c-0.185,1.255-0.395,2.485-0.48,3.841c-0.1,2.059-0.387,3.933-0.72,5.76
			c-0.551,0.489-0.087,1.993-0.24,2.88c0,0.08,0,0.16,0,0.24c-0.08,0-0.16,0-0.24,0c-0.469-0.793-0.146,3.159-0.24,4.319
			c0,0.641,0,1.28,0,1.92c-0.56,4.641-0.08,10.32-0.24,15.36c0,2.96,0,5.92,0,8.88c-0.373,4.107-0.631,8.33-0.72,12.721
			c0.151,2.071-0.416,3.423-0.24,5.52c0.018,1.778-0.136,3.385-0.48,4.8c-0.376,1.064-0.47,2.41-0.48,3.84
			c-0.027,1.734-0.146,3.375-0.48,4.801c-0.08,0-0.16,0-0.24,0c-0.464-0.701-0.182,1.577-0.24,2.399
			c-0.105,1.493-0.426,3.328-0.24,4.561c0,0.399,0,0.8,0,1.199c-0.528,5.233-0.048,11.473-0.48,16.801c0,0.8,0,1.6,0,2.399
			c0,0.08,0,0.16,0,0.24c-1.84,0-3.68,0-5.52,0c0-1.6,0-3.2,0-4.8c0-0.48,0-0.96,0-1.44c0.228-2.813,0.659-6.699,0.24-9.359
			c0-0.4,0-0.8,0-1.2c0-0.08,0-0.16,0-0.24c0.08,0,0.16,0,0.24,0c0.698-11.542,2.779-21.701,3.36-33.36
			c0.992-4.768,0.876-10.644,0.96-16.319c0-0.48,0-0.96,0-1.44c0.419-5.5,0.657-11.183,0.72-17.04c0-0.56,0-1.12,0-1.68
			c0.557-1.283,0.082-3.598,0.24-5.28c0-0.479,0-0.96,0-1.439c0.558-1.363,0.082-3.759,0.24-5.521c0-0.48,0-0.96,0-1.439
			c0.559-2.162,0.081-5.359,0.24-7.921c0-0.56,0-1.12,0-1.68c0.399-0.808,0.399-3.272,0-4.08c0-0.319,0-0.64,0-0.96
			c0-0.08,0-0.16,0-0.24c0.08,0,0.16,0,0.24,0c0.898-10.781,3.599-19.761,4.08-30.96c0.72-2.642,1.04-6.959,0.72-9.84
			c0-0.32,0-0.64,0-0.96c0-0.08,0-0.16,0-0.24c0.08,0,0.16,0,0.24,0c0.09-11.906,3.775-22.416,5.76-33.12
			c1.828-9.856,1.403-19.978,4.8-28.8c0.08,0,0.16,0,0.24,0c0.651-3.109,2.18-6.619,1.2-10.56c0.196-1.131-0.153-2.802-0.24-4.32
			c-0.056-0.979,0.229-3.458-0.24-2.88c-0.08,0-0.16,0-0.24,0c0-0.08,0-0.16,0-0.24c-0.159-2.801,0.319-6.239-0.24-8.64
			c-0.146-0.574,0.301-1.741-0.24-1.92c0-0.24,0-0.48,0-0.721c0.551-0.489,0.087-1.993,0.24-2.88
			c0.209-3.951,0.747-8.853,0.48-12.479c0-0.32,0-0.641,0-0.96c0-0.08,0-0.16,0-0.24c0.08,0,0.16,0,0.24,0
			c0.599-10.121,2.169-19.271,2.64-29.521c0.538-0.432,0.746-2.442,0.24-3.12c0-0.239,0-0.479,0-0.72c0-0.08,0-0.16,0-0.24
			c0.541-0.179,0.094-1.346,0.24-1.92c0.538-0.432,0.746-2.442,0.24-3.12c0-0.319,0-0.64,0-0.96c0-0.08,0-0.159,0-0.239
			c0.647-0.473,1.537-0.703,1.44-1.921c0.143-1.62,1.181-3.614,0.48-5.279c0-0.4,0-0.8,0-1.2c0-0.08,0-0.16,0-0.24
			c0.146-0.014,0.284-0.036,0.24-0.24c0.005-0.007,0.014-0.011,0.021-0.018c0.001-0.001,0.002-0.002,0.004-0.002
			c-0.007,0.008-0.018,0.011-0.024,0.02c0.808-0.151,2.229,0.31,2.64-0.239c2.306-0.175,4.157-0.804,6-1.44c0,1.12,0,2.24,0,3.36
			c0,0.08,0,0.16,0,0.239c-0.37,1.15-0.446,2.595-0.48,4.08C282.932,498.256,283.092,499.136,282.721,499.484z M245.521,771.165
			c-0.936,6.185-1.68,12.561-1.92,19.44c0,0.08,0,0.16,0,0.24c-0.394,2.886-0.486,6.074-0.48,9.359
			c-0.549,0.411-0.088,1.832-0.24,2.641c0,0.8,0,1.6,0,2.399c-28.4,0.4-58.48,0.4-86.88,0c-0.304-1.616-0.22-3.619-0.72-5.04
			c0-0.079,0-0.16,0-0.239c-0.399-6.082-1.028-11.932-0.96-18.48c-0.15-0.729,0.307-2.067-0.24-2.399
			c-0.224-3.185,0.673-5.247,0.48-8.4c0.555-0.806,0.084-2.636,0.24-3.84c-0.169-3.61,0.453-6.428,0.48-9.84c0-0.08,0-0.16,0-0.24
			c0.996-6.604,1.762-13.438,2.64-20.16c0.355-1.404,0.656-2.864,0.72-4.561c1.081-4.999,1.539-10.621,2.16-16.08
			c0.554-0.726,0.084-2.475,0.24-3.6c0-0.32,0-0.64,0-0.96c0.555-0.806,0.084-2.636,0.24-3.84c0-0.16,0-0.32,0-0.48
			c0.552-0.567,0.086-2.153,0.24-3.12c0-0.159,0-0.319,0-0.479c0.551-0.489,0.086-1.993,0.24-2.88c0-0.08,0-0.16,0-0.24
			c0.551-0.489,0.087-1.993,0.24-2.88c0-0.16,0-0.32,0-0.48c0.708-0.492-0.228-2.628,0.48-3.12c0.873,1.367,1.475,3.005,2.64,4.08
			c0,0.08,0,0.16,0,0.24c0.339,0.468,0.71,0.943,1.093,1.426c1.731,2.414,3.186,5.104,5.14,7.295
			c-0.064-0.082-0.121-0.18-0.182-0.267c0.302,0.412,0.626,0.784,0.999,1.067c-0.108-0.084-0.22-0.162-0.322-0.259
			c0.449,0.461,0.918,0.901,1.432,1.298c0.55-0.914,0.134-2.605-0.48-3.12c-0.51-0.85-0.795-1.926-1.44-2.641
			c0-0.079,0-0.159,0-0.239c-0.111-1.089-0.977-1.424-0.96-2.641c-0.505-1.255-0.898-2.622-1.68-3.6
			c-1.521-3.199-3.058-6.382-3.6-10.561c0.865-0.729,2.925,4.012,5.52,4.08c0.16,0,0.32,0,0.48,0
			c4.168,3.992,8.514,7.807,12.96,11.521c-0.313-0.412-0.634-0.816-0.954-1.224c0.238,0.167,0.476,0.35,0.714,0.504
			c-0.817-0.896-1.639-1.787-2.463-2.676c-6.722-8.125-14.337-15.355-19.617-24.925c0-0.08,0-0.16,0-0.24
			c-1.478-3.049,2.15-3.932,2.64-6.479c0-0.08,0-0.16,0-0.24c0.08,0,0.16,0,0.24,0c0.438-0.492,1.48-3.56,0.48-1.92
			c0.196-0.764,0.153-1.767,0.48-2.4c0.556-1.044,0.083-3.117,0.24-4.56c-0.157-1.443,0.316-3.517-0.24-4.561
			c-0.333-1.772,0.748-2.132,0.48-3.84c0.427-1.493,0.648-3.191,0.72-5.04c0-0.08,0-0.16,0-0.239c0.458-1.143,0.51-2.69,0.48-4.32
			c0-0.08,0-0.16,0-0.24c0.398-0.649,0.398-2.95,0-3.6c0-0.08,0-0.16,0-0.24c0-0.32,0-0.64,0-0.96
			c0.144-3.023-2.479-3.281-3.36-5.28c-0.207-0.193-0.143-0.657-0.48-0.72c0-0.08,0-0.16,0-0.24c-0.422-1.738-0.543-3.776-0.96-5.52
			c0-0.08,0-0.16,0-0.24c0-2.16,0-4.32,0-6.48c0-0.08,0-0.16,0-0.239c0.445-2.859,0.633-6.183,0-8.881
			c-0.989-3.811-2.929-6.671-4.56-9.84c-0.717-1.604-1.539-3.101-1.92-5.04c-0.671-1.969-1.373-3.907-2.16-5.76
			c-0.755-1.805-0.814-4.306-1.44-6.24c0-0.08,0-0.16,0-0.24c-0.069-1.371-0.112-2.768-0.48-3.84c0-0.08,0-0.159,0-0.239
			c-0.234-3.446-0.104-7.257-1.2-9.841c-0.065-0.814,0.186-1.945-0.24-2.399c-0.076-3.125-0.819-5.581-1.44-8.16
			c0-0.08,0-0.16,0-0.24c-0.168-1.048,0.408-1.352,0.24-2.399c0-0.08,0-0.16,0-0.24c0.437-1.563,0.557-3.442,0.72-5.28
			c0-0.08,0-0.16,0-0.24c0.364-0.596,0.463-1.456,0.48-2.399c0.455-1.625,0.899-3.261,1.2-5.04c1.532-3.748,2.612-7.948,4.32-11.521
			c0.588-0.852,1.01-1.87,1.2-3.12c0.088-1.831,0.872-2.968,0.96-4.8c0-0.08,0-0.16,0-0.24c0.422-0.697,0.547-1.692,0.72-2.64
			c0-0.08,0-0.16,0-0.24c0.869-1.69,1.194-3.926,1.68-6c0.343-0.777,0.604-1.636,0.72-2.64c0.517-0.764,1-1.561,0.96-2.88
			c0.353-0.35,0.353-1.571,0-1.92c0.601-0.76,2.226-0.495,3.36-0.721c0.947-0.173,1.942-0.297,2.64-0.72
			c0.204,0.044,0.227-0.094,0.24-0.24c0.691-0.028,1.571,0.132,1.92-0.239c2.257-0.303,4.662-0.458,6.72-0.96c0.08,0,0.16,0,0.24,0
			c4.583-0.298,8.94-0.82,13.2-1.44c0.08,0,0.16,0,0.24,0c4.689-0.271,9.225-0.695,13.44-1.44c0.08,0,0.16,0,0.24,0
			c1.43-0.01,2.775-0.104,3.84-0.479c0.815-0.065,1.946,0.186,2.4-0.24c1.651-0.029,3.252-0.108,4.56-0.479c0.08,0,0.16,0,0.24,0
			c2.1-0.061,4.211-0.109,6-0.48c0.08,0,0.16,0,0.24,0c3.92,0,7.84,0,11.76,0c0.411,0.549,1.832,0.088,2.64,0.24
			c0.96,0,1.92,0,2.88,0c0.333,0.547,1.67,0.089,2.4,0.24c0.96,0,1.92,0,2.88,0c0.333,0.547,1.671,0.089,2.4,0.239
			c1.12,0,2.24,0,3.36,0c0.411,0.55,1.832,0.089,2.64,0.24c1.2,0,2.4,0,3.6,0c0.887,0.399,3.433,0.399,4.32,0
			c2.295-0.265,4.475-0.646,7.2-0.479c0.967,0.232,3.081-0.682,2.64,0.96c0,0.72,0,1.439,0,2.159c0,0.08,0,0.16,0,0.24
			c-0.547,0.333-0.089,1.671-0.24,2.4c0,0.08,0,0.16,0,0.24c-0.809,6.87-1.266,14.094-1.92,21.12
			c-0.001,1.678-0.146,3.213-0.48,4.56c-0.461,2.099-0.658,4.462-0.72,6.96c0,0.08,0,0.16,0,0.24
			c-0.372,1.309-0.451,2.909-0.48,4.56c0,0.08,0,0.16,0,0.24c-0.547,0.333-0.09,1.671-0.24,2.4c0,0.08,0,0.159,0,0.239
			c-0.575,3.345-0.671,7.169-0.72,11.04c-0.4,1.604-0.4,4.877,0,6.48c0.157,1.522-0.316,3.677,0.24,4.8c0,0.08,0,0.16,0,0.24
			c0.15,0.729-0.307,2.067,0.24,2.399c0,0.08,0,0.16,0,0.24c0.155,1.125-0.314,2.874,0.24,3.601c0,0.08,0,0.159,0,0.239
			c-0.016,2.416,0.076,4.725,0.48,6.721c0,0.08,0,0.16,0,0.239c0.306,6.547-1.847,10.634-3.12,15.601c0,0.08,0,0.16,0,0.24
			c-1.377,4.05-1.912,8.868-1.92,13.68c0,0.08,0,0.16,0,0.24c-0.423,1.256-0.645,2.715-0.72,4.319c0,0.08,0,0.16,0,0.24
			c-0.661,2.14-1.048,4.552-1.44,6.96c0,0.08,0,0.16,0,0.24c-0.642,1.758-1.12,3.68-1.44,5.76c0,0.08,0,0.16,0,0.24
			c-0.412,0.788-0.635,1.765-0.72,2.88c0.008,1.048-0.208,1.872-0.48,2.64c-0.466,1.134-0.653,2.548-0.72,4.08c0,0.08,0,0.16,0,0.24
			c-0.499,1.742-0.658,3.822-0.72,6c0,0.08,0,0.16,0,0.24c-0.561,3.759-0.866,7.774-0.96,12c0,0.08,0,0.16,0,0.24
			c-0.682,6.758-1.342,13.538-2.4,19.92c0,0.08,0,0.16,0,0.24c-0.466,1.054-0.598,2.441-0.72,3.84c0,0.08,0,0.16,0,0.24
			c-0.797,2.882-1.229,6.131-1.44,9.6c0,0.08,0,0.16,0,0.24c-0.609,2.191-0.469,5.131-0.48,7.92c0,0.08,0,0.16,0,0.24
			c-0.559,2.641-0.081,6.319-0.24,9.359c0,0.08,0,0.16,0,0.24c-0.556,1.044-0.083,3.117-0.24,4.561c0,0.079,0,0.159,0,0.239
			c-0.429,3.171-0.504,6.696-0.48,10.32c0,0.08,0,0.16,0,0.24c-0.374,3.547-0.434,7.406-0.48,11.279c0,0.08,0,0.16,0,0.24
			c-0.556,1.044-0.083,3.117-0.24,4.561c-0.549,0.41-0.088,1.832-0.24,2.64c0.195,7.235-0.619,13.461-0.96,20.16
			c0,0.08,0,0.16,0,0.24c-0.483,2.557-0.781,5.298-0.96,8.16C245.711,769.756,245.922,770.768,245.521,771.165z M172.194,707.746
			c0.228,0.17,0.472,0.31,0.738,0.409c0.008,0.01,0.017,0.02,0.025,0.029C172.679,708.083,172.431,707.925,172.194,707.746z
			 M173.038,708.194c0.083,0.027,0.156,0.071,0.243,0.091c-0.084-0.019-0.162-0.049-0.242-0.075
			C173.038,708.205,173.038,708.2,173.038,708.194z M158.655,644.278c-0.028-0.011-0.061-0.015-0.09-0.023
			c0.011-0.001,0.022-0.001,0.034-0.002C158.62,644.26,158.636,644.271,158.655,644.278z M270.24,447.404
			c-0.818,4.462-0.654,11.985-1.44,17.28c-0.797-0.402-0.812-1.588-1.68-1.92c0-0.08,0-0.16,0-0.24
			c-0.003-0.477-0.655-0.305-0.48-0.96c-1.96-3.479-4.37-6.51-7.2-9.12c-0.248-0.631-0.952-0.808-1.2-1.439
			c-1.415-2.025-3.101-3.779-5.28-5.04c-0.217-0.423-0.537-0.743-0.96-0.96c-3.215-3.665-7.022-6.738-10.56-10.08
			c-0.489-0.07-0.471-0.649-0.96-0.72c-1.472-2.769-3.15-5.33-3.36-9.36c-0.156-1.204,0.314-3.034-0.24-3.84
			c-0.159-2.562,0.319-5.759-0.24-7.92c0-0.32,0-0.64,0-0.96c0.653-3.106,0.937-6.583,1.44-9.84c0.147-2.413,0.813-4.307,0.96-6.72
			c0-0.08,0-0.16,0-0.24c0.364-0.596,0.463-1.457,0.48-2.4c0-0.08,0-0.16,0-0.24c0.988-1.731,0.989-4.451,1.2-6.96
			c0-0.24,0-0.48,0-0.72c1.652-2.884-0.94-9.683,1.2-11.521c1.174,0.266,1.423,1.457,2.4,1.92c0.194,0.045,0.239,0.241,0.48,0.24
			c0.315,0.085,0.377,0.423,0.72,0.48c2.489,1.751,4.875,3.605,6.96,5.76c0.406,0.794,1.292,1.107,1.92,1.68
			c0.214,0.106,0.429,0.211,0.48,0.48c0.342,0.619,0.563,1.358,1.44,1.44c0.906-0.106,1.127,0.474,1.68,0.72c0.08,0,0.16,0,0.24,0
			c0.071,0.489,0.649,0.471,0.72,0.96c0.94,1.14,1.98,2.18,3.12,3.12c0.392,0.648,0.938,1.142,1.68,1.44
			c0.595,0.039,1.061,0.206,1.486,0.416c0.442,0.284,0.9,0.552,1.394,0.785c0.08,0,0.16,0,0.24,0c0,0.08,0,0.16,0,0.24
			c0.543,0.177,0.525,0.915,1.2,0.96c2.724,1.597,4.939,3.701,9.12,3.84c0.08,0,0.16,0,0.24,0c0.13,0.558,0.155,1.941-0.24,2.64
			c-0.729-0.003-0.819,2.696-0.72,4.32c-0.02,0.38,0.115,0.916-0.24,0.96c-0.522,0.125-0.108,3.247-0.24,4.56
			c-0.031,2.769-0.679,4.92-0.72,7.68c0,0.08,0,0.16,0,0.239c-0.08,0-0.16,0-0.24,0c-1.288,9.192-3.949,17.011-4.32,27.12
			c0,0.08,0,0.16,0,0.24C270.033,445.757,270.928,447.372,270.24,447.404z M243.795,437.695c0.024,0.026,0.053,0.048,0.077,0.073
			c0.359,0.33,0.726,0.669,1.089,1.002c-0.937-0.854-1.861-1.688-2.733-2.559C242.733,436.717,243.263,437.206,243.795,437.695z
			 M242.214,436.198C242.214,436.198,242.214,436.197,242.214,436.198c0.214,0.142,0.436,0.278,0.666,0.406
			C242.651,436.477,242.429,436.341,242.214,436.198z M241.559,373.082c0.343-0.104,0.681-0.039,1.013,0.121
			c0.894,0.431,1.736,1.605,2.458,2.419c-0.722-0.813-1.563-1.988-2.458-2.419C242.24,373.043,241.901,372.978,241.559,373.082z
			 M250.744,349.931c0.021,0.009,0.031,0.029,0.056,0.033C250.775,349.96,250.766,349.94,250.744,349.931
			C250.745,349.932,250.745,349.931,250.744,349.931z M281.521,384.524c0.64,0,1.28,0,1.92,0c5.926,3.195,13.407,4.833,20.64,6.72
			c-3.77,1.962-8.571,2.891-12.415,4.779c-0.021,0.007-0.043,0.014-0.064,0.021c-0.001,0.004,0,0.007-0.001,0.011
			c-0.158,0.078-0.325,0.148-0.479,0.229c-0.24,1.361,1.227,1.014,1.44,1.92c-3.034,5.446-4.688,12.272-7.2,18.24
			c-0.787-6.901-4.59-12.038-5.04-18.479c0.69-1.02-0.164-2.549-1.2-2.64c-4.123-0.598-11.118-3.985-13.2-6.48
			c-2.081-2.494-3.114-7.045-4.56-10.56c-1.555-3.782-3.046-7.458-4.08-11.04C264.45,373.915,272.604,379.601,281.521,384.524z
			 M275.288,367.496C275.288,367.496,275.288,367.496,275.288,367.496c0.085,0.133,0.182,0.255,0.27,0.386
			C275.47,367.751,275.373,367.629,275.288,367.496z M275.588,367.538C275.589,367.538,275.589,367.538,275.588,367.538
			c0.087,0.011,0.171,0.027,0.255,0.043C275.76,367.565,275.676,367.548,275.588,367.538z M306.961,388.365
			c-6.958-0.402-12.309-2.412-17.761-4.32c0.115-0.86,0.391-1.387,0-2.16c-1.86-1.66-3.62-3.42-5.28-5.28
			c1.901,1.54,3.379,3.501,5.28,5.04c-3.98-4.5-9.339-8.9-12.72-13.2c0.204,0.044,0.227-0.093,0.24-0.24
			c0.478,0.242,1.025,0.415,1.2,0.96c0.085,0.315,0.423,0.377,0.48,0.72c0.9,0.699,1.701,1.5,2.4,2.4
			c0.195,0.045,0.239,0.241,0.48,0.24c4.883,4.237,10.405,7.835,16.561,10.8c0.914,0.925,2.286,1.394,3.6,1.92
			c2.346,1.095,4.605,2.275,7.44,2.88c0.053,0.187,0.201,0.279,0.479,0.24c-0.377,0.583-1.021,0.899-1.92,0.96
			C307.615,388.67,306.964,388.841,306.961,388.365z M276.34,367.699c1.733,0.576,2.783,2.308,4.13,3.782
			C279.123,370.008,278.073,368.275,276.34,367.699z M286.32,452.685c0-0.08,0-0.16,0-0.24c0.917-0.604,0.583-2.456,1.2-3.359
			c0.477,0.003,0.305,0.654,0.96,0.479c0.211-1.153,0.528-3.464,0-4.56c0-0.08,0-0.16,0-0.24c-0.154-0.966,0.312-2.552-0.24-3.12
			c-0.03-1.65-0.108-3.251-0.48-4.56c-0.152-0.809,0.309-2.229-0.24-2.641c0-0.239,0-0.479,0-0.72c-0.134-1.39,0.285-4.599-0.24-4.8
			c-0.743-3.417-1.313-6.852,0-10.08c0-0.08,0-0.16,0-0.24c1.897-1.942,1.42-6.26,3.36-8.16c0.021,3.019,0.491,5.588,1.2,7.919
			c0.002,0.638-0.091,1.371,0.239,1.681c0,0.88,0,1.76,0,2.64c-0.041,9.887,1.338,20.776-0.239,30
			c-0.158,0.322-0.514,0.447-0.96,0.48c-0.826,0.73-1.496-0.718-2.16,0c-0.83,0.45-1.019,1.541-2.64,1.2
			C285.888,453.532,286.438,453.442,286.32,452.685z M274.081,437.805c0-0.08,0-0.16,0-0.24c0.881-4.238,2.01-8.229,2.4-12.96
			c0-0.08,0-0.16,0-0.24c0.956-1.284,0.866-3.613,1.2-5.52c0.076-0.563,0.379-0.901,0.24-1.68c0.25-1.189,0.425-2.455,0.48-3.84
			c0.319-0.721,0.244-1.836,0.24-2.88c0.87-0.57,0.33-2.55,0.48-3.84c0-0.08,0-0.16,0-0.24c0-0.32,0-0.64,0-0.96
			c1.166,0.434,0.432,2.768,1.68,3.12c0,0.08,0,0.16,0,0.24c1.392,3.409,1.687,7.914,2.64,11.76c0,0.08,0,0.16,0,0.24
			c0.03,1.65,0.108,3.252,0.48,4.56c-0.208,0.929,0.586,0.854,0.72,1.44c0.218,2.343-0.495,5.614,0.48,7.2
			c-0.001,4.366,1.166,8.692,0.96,12.479c-0.24,1.201-0.75,2.131-0.72,3.601c0,0.08,0,0.16,0,0.24
			c-0.71,1.369-1.103,3.057-1.68,4.56c-0.45,0.351-1.591,0.01-1.92,0.48c-0.08,0-0.16,0-0.24,0c-1.552,0.048-2.987,0.212-4.32,0.479
			c-2.335-1.345-4.077-3.283-4.32-6.72C273.399,445.443,273.78,441.664,274.081,437.805z M281.28,480.524
			c-0.158-2.001,0.318-4.638-0.24-6.239c-0.143-0.498,0.296-1.577-0.24-1.681c0-0.08,0-0.159,0-0.239
			c0.023-0.378-0.032-0.832,0.48-0.721c0.16,0,0.32,0,0.48,0c0.155,1.125-0.314,2.874,0.24,3.601c0,1.2,0,2.399,0,3.6
			c-0.151,3.129,0.309,6.868-0.24,9.601c-2.3,0.101-3.787,1.013-6.24,0.96c0.015-1.585,0.098-3.103,0.72-4.08
			c0.241-0.001,0.286,0.194,0.48,0.239c0.163,0.455,3.01,0.318,2.4,0c0.041-0.279,0.379-0.261,0.72-0.239
			C281.198,484.602,281.405,482.729,281.28,480.524z M279.464,490.632c-0.826,0.096-1.747,0.194-2.57,0.326
			C277.717,490.826,278.638,490.727,279.464,490.632z M275.619,491.225c-0.101,0.028-0.203,0.057-0.293,0.088
			c0-0.001,0.001-0.001,0.002-0.001C275.417,491.281,275.518,491.253,275.619,491.225z M279.601,480.285
			c-0.675,0.765,0.181,3.061-0.48,3.84c-0.08,0-0.16,0-0.24,0c-0.48,0-0.96,0-1.44,0c0.021-0.341,0.04-0.68-0.24-0.72
			c0.515-2.605,1.111-5.129,1.68-7.681c0.187-0.053,0.279-0.201,0.24-0.479c0.08,0,0.16,0,0.24,0
			c-0.039-0.279,0.053-0.428,0.24-0.48C279.601,476.604,279.601,478.445,279.601,480.285z M278.4,474.045c0,0.08,0,0.16,0,0.24
			c-0.08,0-0.16,0-0.24,0c-0.542-0.179-0.094-1.347-0.24-1.92c0-0.08,0-0.16,0-0.24c0.193-0.127,0.39-0.251,0.72-0.24
			C278.769,472.813,278.212,473.057,278.4,474.045z M277.193,459.479c0.331,3.279-0.141,7.416,1.302,9.983
			C277.051,466.895,277.522,462.76,277.193,459.479z M367.44,398.445c-3.098,3.623-4.598,8.842-7.439,12.72
			c-0.233,1.047-0.832,1.728-1.2,2.64c-2.067,3.133-3.929,6.472-6,9.6c-0.743,1.657-1.704,3.097-2.88,4.32
			c-0.848,1.232-1.697,2.463-2.641,3.6c-0.443,0.996-1.11,1.77-1.92,2.4c-0.058,0.343-0.395,0.405-0.479,0.72
			c-1.068,0.933-1.909,2.092-2.88,3.12c-1.62,1.42-3.141,2.94-4.561,4.561c-1.939,1.739-3.78,3.58-5.52,5.52
			c-1.62,1.42-3.141,2.94-4.561,4.561c-1.789,1.73-3.778,3.261-5.52,5.04c-3.135,3.265-7.19,5.609-10.561,8.64
			c-1.609,0.79-2.849,1.951-4.319,2.88c-1.243,0.678-2.12,1.721-3.601,2.16c0-0.4,0-0.8,0-1.2c0.807-2.192,0.756-7.398,0-9.6
			c-0.02-0.38,0.115-0.916-0.24-0.96c0-0.16,0-0.32,0-0.48c-0.154-1.045,0.313-2.713-0.239-3.359
			c-0.146-0.574,0.301-1.742-0.24-1.921c-0.846-7.475-1.807-14.833-2.88-22.08c-0.153-0.887,0.311-2.391-0.24-2.88
			c-0.131-0.349,0.28-1.24-0.24-1.199c0-0.08,0-0.16,0-0.24c-0.151-0.809,0.31-2.229-0.239-2.641c0-0.079,0-0.159,0-0.239
			c-0.151-0.729,0.307-2.067-0.24-2.4c0-0.16,0-0.32,0-0.479c-0.15-0.729,0.307-2.067-0.24-2.4c0-0.08,0-0.16,0-0.24
			c-0.15-0.729,0.308-2.067-0.24-2.399c0-0.08,0-0.16,0-0.24c-0.15-0.729,0.308-2.067-0.24-2.4c0-0.08,0-0.16,0-0.24
			c-0.15-0.729,0.308-2.067-0.239-2.4c0-0.08,0-0.16,0-0.24c0.025-2.106,0.007-4.167-0.48-5.76c-0.146-0.574,0.302-1.741-0.24-1.92
			c0.456-2.416-2.294-5.245-0.479-6c1.855-0.385,3.47-1.011,4.8-1.92c0.08,0,0.16,0,0.24,0c2.015-0.305,3.396-1.243,5.04-1.92
			c1.983-1.296,4.374-2.186,6.479-3.36c2.51,0.69,4.897,1.502,8.16,1.44c0.08,0,0.16,0,0.24,0c0.014,0.146,0.036,0.284,0.24,0.24
			c0.007,0.233,0.294,0.186,0.479,0.24c0.492,0.398,2.628,0.398,3.12,0c0.16,0,0.32,0,0.48,0c0.88,0,1.76,0,2.64,0
			c1.045-0.155,2.713,0.313,3.36-0.24c1.92,0,3.84,0,5.76,0c0.604-0.013,3.436,0.106,2.16-0.24c0.333-0.547,1.67-0.089,2.399-0.24
			c0.809-0.152,2.229,0.309,2.641-0.24c0.08,0,0.16,0,0.24,0c3.216-0.224,5.071-1.809,7.439-2.88c0.423-0.217,0.743-0.537,0.96-0.96
			c0.193-0.127,0.39-0.25,0.72-0.24c0.516-0.605,1.203-1.038,0.96-2.4c0-0.08,0-0.16,0-0.24c0.184-0.856,0.619-1.461,1.2-1.92
			c0.699,0.988,2.398,3.698,3.84,1.68c0.693-2.667,0.729-5.991,1.921-8.16c0.685-0.998,1.806-2.829,2.64-2.88c0,0.4,0,0.8,0,1.2
			c0.65,1.91,0.385,4.734,0.72,6.96c0.556,0.885,0.084,2.796,0.24,4.08c0.287,2.592,0.52,5.24,0.72,7.92
			c0.089,1.511-0.335,3.535,0.721,4.08C367.027,397.738,367.446,397.879,367.44,398.445z M358.841,379.525
			c0.627-2.067,2.067-3.607,1.886-5.689C360.908,375.918,359.469,377.458,358.841,379.525z M382.32,217.964c0,1.2,0,2.4,0,3.6
			c0.669-0.108-0.188,1.308,0.48,1.2c0,0.16,0,0.32,0,0.48c0,0.56,0,1.12,0,1.68c0,1.04,0,2.08,0,3.12c0.08,0,0.16,0,0.24,0
			c0,0.08,0,0.16,0,0.24c0,1.12,0,2.24,0,3.36c-0.156,1.283,0.314,3.195-0.24,4.08c0.322,1.057-1.196,1.506-0.72,1.92
			c0-0.08,0-0.16,0-0.24c0.933-1.615,1.317-0.099,0.72,1.2c0,0.08,0,0.16,0,0.24c0.188,0.053,0.279,0.201,0.24,0.48
			c0,1.52,0,3.04,0,4.56c0.143,0.497-0.297,1.577,0.239,1.68c0.014,3.693-0.375,6.984-1.439,9.6c-0.322,1.118-0.199,2.681-1.68,2.64
			c0.2-0.519,0.621-0.818,0.479-1.68c-2.422-2.138-5.602-3.519-10.08-3.6c0-0.08,0-0.16,0-0.24c1.611-0.423-4.976-0.301-2.64,0
			c0,0.08,0,0.16,0,0.24c-7.19-0.229-11.901,3.299-16.32,5.04c3.679-4.001,9.825-5.535,16.8-6.24c0.259,0.397,2.142,0.397,2.4,0
			c3.936,0.064,6.548,1.452,10.08,1.92c2.582-2.33-3.059-3.493-5.521-3.84c-1.277-0.083-2.322-0.397-3.6-0.48c-1.6,0-3.2,0-4.8,0
			c-5.855,0.545-10.436,2.365-14.641,4.56c-1.21,0.21-1.918,1.999-2.88,1.68c0-0.08,0-0.16,0-0.24
			c0.928,0.334-0.939-2.074-0.96-3.12c0.19-0.83-0.267-1.013-0.479-1.44c-1.575-0.294-1.415,1.146-1.92,1.92
			c-0.705,1.276,0.295,2.679,0.72,3.6c0.694,0.985,1.254,2.106,1.68,3.36c0.452,1.469,1.341,2.5,1.68,4.08
			c0.369,0.912,0.695,1.866,0.961,2.88c0.285,0.674,0.54,1.38,0.72,2.16c0.453,1.547,1.001,3,1.439,4.56
			c0.459,0.901,0.799,1.922,1.2,2.88c0.322,0.878,0.675,1.725,0.96,2.64c1.198,4.002,2.937,7.464,4.32,11.28
			c0.694,0.985,1.254,2.106,1.68,3.36c0.801,1.759,1.825,3.294,2.16,5.52c0.399,1.125,0.399,3.915,0,5.04
			c-0.938,1.782-2.774,2.666-4.32,3.84c-0.915,1.085-2.215,1.785-3.359,2.64c-4.513-3.248-10.023-5.497-15.84-7.44
			c-2.381-1.14-5.264-1.777-8.16-2.4c-0.336-0.398-2.304-0.398-2.641,0c-0.64,0-1.279,0-1.92,0c0-0.08,0-0.16,0-0.24
			c-0.259-0.701-2.141,0.221-2.399-0.48c0.044-0.204-0.094-0.227-0.24-0.24c1.577-4.342,6.381-5.458,9.36-8.4
			c0.792-0.327,2.063-0.177,1.92-1.44c-4.273-0.356-13.489,1.766-14.4,6.24c-0.318-0.611-0.455,2.237,0,2.4c0-0.48,0-0.96,0-1.44
			c0.129,0.151,0.198,0.361,0.237,0.603c0.008,0.204,0.02,0.408,0.038,0.611c-0.006,0.71-0.126,1.533-0.035,2.146
			c0.686,1.913-0.222,3.591-0.24,5.52c5.844,1.836,9.334,6.026,14.64,8.4c0.193,0.833-0.357,0.923-0.239,1.68c0,2.24,0,4.48,0,6.72
			c0.272,2.296,0.582,4.572,1.199,6.72c0.538,1.872,0.889,4.517,2.641,5.04c1.494-3.111-2.108-6.884-1.681-11.28
			c-0.554-0.727-0.084-2.476-0.239-3.6c0-0.08,0-0.16,0-0.24c0.08,0,0.16,0,0.239,0c-0.151-0.808,0.31-2.229-0.239-2.64
			c-0.008-0.967,0.068-1.851,0.72-2.16c0.917,0.203,1.835,0.405,2.88,0.48c0.334,0.058,0.755,0.028,1.177-0.002c0,0,0,0,0.001,0
			c0.515,0.053,1.018,0.087,1.516,0.112c0.001,0,0.003,0,0.004,0c0.067,0.036,0.134,0.075,0.183,0.13c-0.24,0-0.479,0-0.72,0
			c0.411,0.549,1.832,0.088,2.64,0.24c0-0.08,0-0.16,0-0.24c1.601,0,3.2,0,4.801,0c4.556-1.124,7.74-3.619,10.8-6.24
			c0.729-0.55,1.393-1.167,2.16-1.68c0.08,0,0.159,0,0.239,0c0.452-0.908,1.57-1.15,1.681-2.4c0.414-0.815,0.167-1.759,0-2.64
			c-0.042-0.073-0.068-0.163-0.1-0.248c-0.026-0.578-0.086-1.138-0.172-1.682c0.026-0.813,0.112-1.685,0.031-2.39
			c-4.063-9.618-7.705-19.655-11.28-29.76c-0.848-2.432-1.897-4.663-2.399-7.44c3.474-2.926,7.954-4.846,13.68-5.52
			c0.966-0.154,2.552,0.312,3.12-0.24c1.022,0.097,2.473-0.232,3.12,0.24c1.911,0.569,4.118,0.842,5.52,1.92
			c0.473,0.648,0.143,2.097,0.24,3.12c-0.286,0.994-0.472,2.088-0.72,3.12c0,0.08,0,0.16,0,0.24c-0.528,0.592-0.971,1.27-1.2,2.16
			c0,0.08,0,0.16,0,0.24c-1.497,1.222-2.48,2.96-4.32,3.84c-0.08,0-0.16,0-0.24,0c-1.842,0.158-4.317-0.317-5.76,0.24
			c-0.565-0.005-0.706,0.414-1.439,0.24c-0.986,1.702,1.443,1.86,2.88,1.92c1.279,0,2.56,0,3.84,0c4.717-0.803,6.685-4.355,8.4-8.16
			c0.399-0.32,0.08-1.359,0.479-1.68c0.352-0.344,0.761-0.184,0.72,0.48c0,0.56,0,1.12,0,1.68c0,0.88,0,1.76,0,2.64
			c-0.146,0.574,0.302,1.741-0.239,1.92c0,0.16,0,0.32,0,0.48c-0.398,0.414-0.398,2.466,0,2.88c0.066,2.973,0.731,5.348,0.96,8.16
			c0.551,0.489,0.087,1.993,0.239,2.88c0,0.72,0,1.44,0,2.16c0,0.64,0,1.28,0,1.92c0,0.48,0,0.96,0,1.44
			c-0.15,0.729,0.308,2.067-0.239,2.4c-1.133,6.67-3.263,12.765-4.561,19.44c-1.28,6.586-3.092,13.16-4.32,19.92
			c0.143,1.022-0.172,1.587-0.239,2.4c-0.403,3.037-0.608,6.272-1.681,8.64c-0.558,1.682-1.468,3.012-2.159,4.56
			c-0.886,1.675-1.247,3.873-1.681,6c0.082,0.347,0.104,0.716,0.103,1.09c-0.064,0.427-0.111,0.869-0.146,1.32
			c-0.054,0.24-0.118,0.479-0.196,0.71c0,0.4,0,0.8,0,1.2c-0.194,1.33-0.898,3.415-1.68,3.36c-0.16,0-0.32,0-0.479,0
			c-0.397-0.243-0.261-1.02-0.48-1.44c-1.832-1.288,0.173-6.413-2.88-6.48c-1.721,2.919-2.322,6.958-3.84,10.08
			c-1.202-0.797-1.465-2.535-3.36-2.64c-1.868,0.973,0.117,4.712-2.16,5.52c-1.058-1.662-2.065-3.375-2.399-5.76
			c0.397-0.414,0.397-2.466,0-2.88c0,0.56,0,1.12,0,1.68c-0.399-0.729-0.399-3.112,0-3.84c0.529-0.43,0.586-1.334,0.96-1.92
			c2.196-0.684,6.18,0.42,5.76-2.88c-2.621-1.292-6.008,0.458-9.12,0.24c-0.57-0.398-2.789-0.398-3.36,0c0.641,0,1.28,0,1.921,0
			c-0.965,0.556-2.957,0.083-4.32,0.24c-1.141,0.139-2.213,0.347-3.601,0.24c-0.885,0.555-2.796,0.084-4.079,0.24
			c-0.08,0-0.16,0-0.24,0c0-0.08,0-0.16,0-0.24c-2.32,0-4.641,0-6.96,0c0,0.08,0,0.16,0,0.24c-0.08,0-0.16,0-0.24,0
			c-0.808-0.152-2.229,0.309-2.64-0.24c0.159,0,0.319,0,0.479,0c-0.336-0.397-2.304-0.397-2.64,0
			c-2.211,1.323,0.118,3.238,2.399,2.88c0.801,0,1.601,0,2.4,0c0,0.08,0,0.16,0,0.24c-0.4,0-0.8,0-1.2,0c2.082,0.4,5.838,0.4,7.92,0
			c0.428,0.176,0.035,0.437,0,0.72c0.001,0.241-0.194,0.286-0.24,0.48c-0.522,0.677-0.896,1.503-1.439,2.16
			c0.054,0.454-0.271,0.53-0.24,0.96c-1.191,0.76-0.957-1.682-1.92-1.92c0.052-1.266-1.973-1.586-2.64-0.72
			c-0.344,1.144,1.003,0.596,0.72,1.68c-0.693,5.307-1.568,10.433-6.96,11.04c0.896-1.665,2.292-2.828,2.88-4.8
			c-4.288-1.171-8.164,1.587-12.48,1.92c-0.637-0.002-1.37,0.091-1.68-0.24c-0.102-0.502,0.165-0.636,0.24-0.96
			c1.17-1.871,2.565-3.515,2.88-6.24c-0.25-0.412-1.697-0.005-0.96-0.72c-3.135,0.917-5.937,3.272-10.32,2.16
			c0.176-1.135-0.342-1.578-0.72-2.16c-0.896-0.945-1.586-2.094-2.88-2.64c-0.264,0.07-0.502,0.166-0.757,0.245h-0.001
			c-0.133,0-0.275,0.025-0.422,0.061c-0.076,0.018-0.154,0.04-0.233,0.065c-0.079,0.026-0.16,0.06-0.241,0.093
			c-0.187,0.076-0.379,0.167-0.579,0.275c-0.003,0.001-0.006,0.003-0.009,0.004c0.001,0,0.001,0,0.002,0
			c-0.003,0.001-0.005,0.002-0.007,0.003c0.001-0.001,0.003-0.002,0.005-0.003c-4.574,1.792-7.877,4.853-12.158,6.937
			c0-0.4,0-0.8,0-1.2c-0.398-0.571-0.398-2.79,0-3.36c0.71-4.507,2.81-8.847,2.4-13.68c-2.464-1.093-3.614,2.238-5.28,1.92
			c0.016-1.185,0.705-1.695,0.72-2.88c0.489-0.071,0.471-0.649,0.96-0.72c1.18-1.38,2.46-2.66,3.84-3.84
			c0.046-0.194,0.241-0.239,0.24-0.48c2.109-3.616,6.023-7.835,5.04-12.96c-2.283-0.138-3.267,2.071-5.28,2.88
			c-2.685,1.08-6.072,0.759-8.64,2.4c-0.006-1.206,0.646-1.754,0.72-2.88c0.871-0.649,1.074-1.966,1.68-2.88
			c1.124-2.852,4.075-6.249,2.64-10.08c-1.236-0.037-2.092,0.307-2.88,0.72c-0.934,0.266-1.736,0.664-2.4,1.2
			c-1.716,0.447-3.816,1.78-5.28,1.68c0.502-1.978,1.392-3.568,1.2-6.24c0.521,0.041,0.109-0.851,0.24-1.2c0-0.4,0-0.8,0-1.2
			c0-2.16,0-4.32,0-6.48c-0.045-0.915-0.341-1.579-1.2-1.68c-2.127,3.313-3.627,7.253-5.76,10.56c0.284-1.636,0.411-3.429,0.72-5.04
			c0-0.88,0-1.76,0-2.64c-0.5-3.74-0.421-8.059-2.4-10.32c-0.08,0-0.16,0-0.24,0c-0.315,0.085-0.377,0.422-0.72,0.48
			c-0.398,0.414-0.398,2.466,0,2.88c0,0.08,0,0.16,0,0.24c-0.317,2.563-0.17,5.59-1.2,7.44c-0.396-1.363-1.149-2.371-1.68-3.6
			c0.891,0.584-0.439-1.861-0.96-2.16c-3.801-8.919-12.393-13.047-12.48-25.68c-0.558-1.522-0.082-4.079-0.24-6
			c0.08,0,0.16,0,0.24,0c0,0.32,0,0.64,0,0.96c0.398-0.492,0.398-2.628,0-3.12c0-0.16,0-0.32,0-0.48
			c-0.311-5.387,2.729-10.601,1.2-14.88c1.311-5.813,2.387-10.141,4.32-14.64c2.721-6.335,7.313-10.166,9.12-17.04
			c0.441,0.534,0.987,0.79,1.68,0.48c-0.488-10.554-3.201-20.958-4.32-31.68c4.547,2.333,9.166,4.594,12.72,7.92
			c1.1,1.3,2.3,2.5,3.6,3.6c1.514,1.366,2.582,3.178,4.32,4.32c-0.306,1.694-2.328,1.672-2.88,3.12
			c-2.943,1.304-8.354,3.346-9.12,7.2c-0.396,1.992-0.268,5.825,2.16,6.24c-0.313-4.633,2.784-5.856,6.72-6.24
			c-0.089,2.569,0.605,4.354,1.44,6c0.872,1.527,2.167,2.633,3.6,3.6c2.259,1.609,8.88,2.262,10.56-0.48
			c-2.469-1.206-6.521-1.579-6.48-4.8c0.016-1.246,1.099-2.249,1.92-3.36c1.005,2.595,3.168,4.032,6.241,4.56
			c0.387-0.81,3.101,1.222,2.399-0.96c-0.014-0.787-0.683-0.917-0.479-1.92c-0.398-0.336-0.398-2.304,0-2.64
			c-0.077-1.117,0.479-1.602,0.479-2.64c0.08,0,0.16,0,0.24,0c0.14-1.303,2.118-2.839,3.12-4.08c-0.489,0.071-0.471,0.649-0.96,0.72
			c2.163-2.077,2.83-5.65,3.84-8.88c-0.038-0.759-0.009-1.449,0.479-1.68c0.424,0.217,0.743,0.537,0.96,0.96
			c1.425,2.513,4.195,5.671,3.601,9.6c-0.211,1.395-1.608,2.259-0.96,3.84c-0.98,0.78-1.86,1.66-2.641,2.64
			c1.023-0.657,1.617-1.743,2.641-2.4c-0.859,1.06-1.82,2.02-2.88,2.88c-0.952,1.288-1.409,3.071-1.92,4.8
			c-0.376-0.861-0.376,2.782,0,1.92c0.745,4.774,2.813,8.226,5.04,11.52c0.692,0.396,1.115,1.063,1.698,1.571
			c0.212,0.222,0.431,0.421,0.648,0.627l3.893,3.082c1.5,0.261,2.718,0.803,4.32,0.96c2.08,0,4.16,0,6.24,0
			c0.453,0.054,0.529-0.271,0.96-0.24c-0.137-1.64,1.063-1.711,0.72-3.12c-6.594-0.317-15.375-3.103-13.68-11.52
			c0.273-1.36,1.995-3.378,4.08-3.12c0.332-0.547,1.67-0.089,2.399-0.24c3.71-0.45,7.64-0.681,10.32-2.16
			c-0.13,3.969,1.456,6.224,3.12,8.4c0,0.08,0,0.16,0,0.24c0.9,0.699,1.7,1.5,2.4,2.4c0.079,0,0.159,0,0.239,0
			c2.171,1.51,4.618,2.742,8.4,2.64c0,0.08,0,0.16,0,0.24c-1.983,0.337,5.548,0.417,4.32,0c0-0.08,0-0.16,0-0.24
			c1.313-0.046,3.033,0.314,2.88-1.2c-0.058-0.343-0.396-0.405-0.48-0.72c-0.336-0.397-2.304-0.397-2.64,0
			c-2.629-0.571-4.339-2.062-5.76-3.84c-0.75-1.17-1.122-2.719-1.681-4.08c0-0.4,0-0.8,0-1.2c-0.397-0.259-0.397-2.142,0-2.4
			c0-0.24,0-0.48,0-0.72c0.628-0.813,1.033-1.847,1.681-2.64c0.053-0.187,0.2-0.279,0.479-0.24c0.621,1.618,1.27,3.711,2.64,5.04
			c1.73,1.677,5.291,2.824,7.681,1.44c-0.674-1.439-3.023-2.295-2.88-4.32c0.043-0.609,0.438-1.435,1.199-1.92
			c1.011-0.51,2.924-0.116,4.32-0.24c0-0.08,0-0.16,0-0.24c0.24,0,0.48,0,0.72,0c1.12,0,2.24,0,3.36,0
			c6.738,0.018,10.44-3,12.96-7.2c0.481-1.598,1.426-2.735,1.92-4.32c1.172-2.188,1.424-5.296,2.641-7.44
			c1.24,1.72,1.841,4.079,2.64,6.24c0.884,1.836,1.531,3.909,2.16,6C380.429,211.056,382.325,213.56,382.32,217.964z
			 M340.626,314.407c-0.727-0.123-1.465-0.263-2.226-0.442C339.162,314.144,339.902,314.289,340.626,314.407z M346.561,352.604
			c-0.583,1.498-2.099,2.062-2.88,3.36c-0.472-0.568-0.274-1.806-0.72-2.4c1.261,0.604-0.67-0.602,0-0.72
			c0.729-0.151,2.067,0.307,2.399-0.24c-0.8,0-1.6,0-2.399,0c0.598-0.377,5.13-0.523,3.84,0.24
			C346.787,352.698,346.765,352.561,346.561,352.604z M346.801,353.084c-0.779,0.98-1.66,1.86-2.641,2.64
			C344.94,354.744,345.82,353.864,346.801,353.084z M334.561,353.084c0.4,0,0.8,0,1.2,0c0.411-0.549,1.832-0.088,2.64-0.24
			c0.842-0.119,1.563-0.358,2.641-0.24c0.059,0.261,0.477,0.163,0.479,0.48c-0.106,0.214-0.211,0.429-0.479,0.48
			c-0.194,0.046-0.239,0.241-0.48,0.24c-0.576,0.944-1.584,1.456-2.16,2.4c-0.08,0-0.16,0-0.24,0
			c-0.045,0.194-0.24,0.239-0.239,0.48c-0.459,0.661-1.065,1.175-1.44,1.92c-0.554,0.074-0.267-0.693-0.72-0.72
			c0-0.08,0-0.16,0-0.24C335.418,356.067,334.646,354.918,334.561,353.084z M271.681,194.684c0.9,0.7,1.7,1.5,2.4,2.4
			C273.18,196.385,272.38,195.585,271.681,194.684z M329.32,217.141c-0.004,0.015-0.01,0.026-0.014,0.042c0,0,0-0.001,0-0.002
			C329.311,217.165,329.316,217.155,329.32,217.141z M329.307,217.176c0.001-1.327,0.2-2.668,0.618-3.975
			C329.509,214.507,329.308,215.849,329.307,217.176z M330.728,223.162c0.317,0.627,0.686,1.225,1.102,1.789
			C331.413,224.388,331.045,223.789,330.728,223.162z M380.894,197.58c0-0.007,0.001-0.015,0.001-0.022
			c-0.001,0.031-0.001,0.063-0.002,0.094C380.893,197.628,380.893,197.604,380.894,197.58z M380.641,200.204
			c0.024-0.185,0.049-0.388,0.074-0.598C380.689,199.817,380.665,200.018,380.641,200.204z M380.782,198.985
			c0-0.003,0-0.006,0.001-0.009C380.782,198.979,380.782,198.982,380.782,198.985z M380.925,196.117
			c-0.005-0.212-0.014-0.42-0.027-0.623c0.014,0.203,0.022,0.41,0.027,0.621C380.925,196.116,380.925,196.116,380.925,196.117z
			 M309.932,123.149c-1.082-0.459-2.184-0.88-3.322-1.251C307.748,122.269,308.85,122.69,309.932,123.149z M218.88,216.524
			c0.81-1.431,1.372-3.108,2.16-4.56c1.277,1.584-1.213,2.519-1.44,4.08c-0.286,0.738-1.01,1.04-1.188,1.887
			c0.178-0.847,0.901-1.148,1.188-1.887C219.285,216.129,219.223,216.467,218.88,216.524z M216.24,238.125
			c0.104-5.629,2.711-10.867,2.16-15.6c0.941-1.299,1.94-2.54,2.64-4.08c1.164-0.124-0.073,2.152,0.72,2.4
			c-0.024,0.744,0.109,1.332,0.48,1.68c-0.254,0.626-1.258,0.502-1.44,1.2c0.087,6.647-1.744,11.376-4.08,15.6
			c-0.081,0.301-0.385,0.378-0.464,0.682c0.078-0.304,0.384-0.38,0.464-0.682C216.034,240.033,216.281,238.746,216.24,238.125z
			 M214.035,172.012C214.035,172.012,214.035,172.012,214.035,172.012c-0.036,0.063-0.072,0.125-0.108,0.188
			C213.963,172.137,213.999,172.074,214.035,172.012z M206.542,250.803c-0.591,0.581-1.484,0.649-2.25,0.749
			C205.057,251.451,205.95,251.384,206.542,250.803z M206.16,289.245c0-0.08,0-0.16,0-0.24c1.061,0.86,2.021,1.82,2.88,2.88
			c-0.241,0.001-0.286-0.194-0.48-0.24C207.861,290.744,207.061,289.944,206.16,289.245z M208.345,291.455
			c1.362,1.252,2.749,2.442,4.06,3.636C211.094,293.898,209.707,292.707,208.345,291.455z M236.161,315.165
			c-1.647,0.113-2.831,0.689-4.32,0.96c-1.801,0.201-2.927-0.273-4.08-0.72c-3.946-5.494-6.528-12.352-11.52-16.8
			c-0.084-0.315-0.422-0.377-0.48-0.72c-1.06-0.859-2.02-1.819-2.88-2.88c-0.315-0.085-0.377-0.422-0.72-0.48
			c-0.766-0.754-1.788-1.251-2.4-2.16c-1.18-1.38-2.459-2.66-3.84-3.84c-3.285-3.675-6.029-7.891-6-14.88c0-0.88,0-1.76,0-2.64
			c0-0.16,0-0.32,0-0.48c0.146-0.574-0.301-1.741,0.24-1.92c0.614-5.946,0.811-12.309,4.56-15.12c1.425-0.015,2.239-0.641,3.84-0.48
			c0.649,0.398,2.95,0.398,3.6,0c4.656,1.273,9.938,1.563,13.2,4.08c1.274,0.983,2.004,3.728,4.08,2.16
			c-0.018-0.418,0.054-0.746,0.24-0.96c-0.991-2.208-3.483-2.917-5.04-4.56c0.38-3.47,3.097-6.86,2.16-10.08
			c-3.163,0.836-3.182,4.818-4.32,7.68c-0.652,0.172,0.172-1.131-0.48-0.96c-4.231-12.723,4.923-17.82,5.28-28.56
			c0.233-0.006,0.187-0.293,0.24-0.48c0.668-0.108-0.188,1.308,0.48,1.2c-0.756,4.784,4.921,13.397,4.8,4.08
			c0.547-0.333,0.089-1.671,0.24-2.4c-0.107-4.971,4.719-6.679,6.48-9.36c0.441-0.672,1.87-3.279-0.48-3.84
			c-2.594,4.846-7.976,6.904-8.4,13.92c-0.748-1.733-0.666-4.295-0.96-6.48c0-1.6,0-3.2,0-4.8c0.348-5.181,6.008-9.6,3.12-14.88
			c-1.739,4.101-2.858,8.822-5.04,12.48c0.256-1.536-1.892-0.668-1.92-1.92c0.6-5.269,4.669-9.532,3.6-14.88
			c-1.771,0.65-1.011,1.73-2.64,1.92c-1.058,0.124-1.618-0.884-2.64-0.72c-1.54,0.248-1.342,1.751-2.88,2.16
			c-0.277-0.922-1.143-1.257-1.44-2.16c3.848-9.752,9.994-17.206,15.6-25.2c1.14-0.94,2.18-1.98,3.12-3.12
			c1.729-1.07,4.57-1.03,5.52-2.88c-7.323,3.077-14.574,6.226-17.76,13.44c-0.554-0.052-1.028,0.001-1.461,0.108
			c-0.065-0.019-0.131-0.021-0.198-0.008c-0.01,0.001-0.02,0-0.031,0.002c-0.073,0.021-0.149,0.06-0.23,0.137
			c-0.007,0.007-0.013,0.015-0.021,0.021c-3.246,1.335-2.975,7.266-5.259,9.819c1.056-7.504,5.438-11.682,7.2-18.48
			c3.153-3.327,7.407-5.553,9.84-9.6c-4.073,1.128-7.132,4.547-9.84,6.24c1.569-3.311,3.629-6.131,5.52-9.12
			c0.627-0.128,1.213-1.934,0-1.2c0-0.08,0-0.16,0-0.24c2.045-4.435,5.445-7.515,7.92-11.52c0.503-0.457,1.266-0.654,1.2-1.68
			c-1.007-0.709-1.657,0.643-2.16,1.2c-1.745,2.316-4.641,2.047-5.76,5.04c-0.701,0.033-0.762,1.94-1.2,1.44
			c1.21-6.55,4.989-10.53,7.44-15.84c3.601-2.743,7.548-5.493,12.72-6.96c5.187-1.471,11.145-1.451,16.56-3.12
			c0.576,0.224,0.635,0.965,1.68,0.72c0.571,0.398,2.79,0.398,3.36,0c8.448,0.048,13.257-3.543,21.12-4.08c1.12,0,2.24,0,3.359,0
			c1.367-0.167,1.994,0.407,3.36,0.24c0.08,0,0.16,0,0.24,0c1.848,0.232,3.563,0.597,5.04,1.2c1.109,0.171,2.075,0.484,2.88,0.96
			c0.241-0.001,0.285,0.194,0.479,0.24c0.569,0.122,1.083,0.299,1.562,0.511c-0.5-0.231-1-0.461-1.507-0.679
			c0.529,0.228,1.056,0.462,1.578,0.707c-0.022-0.01-0.048-0.018-0.071-0.028c0.136,0.063,0.273,0.12,0.408,0.184
			c0.521,0.271,0.987,0.596,1.391,0.985c1.034,0.486,1.947,1.093,2.88,1.68c1.363,0.396,2.371,1.149,3.601,1.68
			c3.051,1.428,6.381,2.579,9.359,4.08c0.932,0.349,1.918,0.643,2.641,1.2c2.095,0.545,3.502,1.778,6.479,1.44c0.08,0,0.16,0,0.24,0
			c0.051-0.269,0.267-0.374,0.48-0.48c0.156-2.157-2.341-1.66-3.12-2.88c4.966,0.246,7.886-1.554,10.319-3.84
			c0.98-0.779,1.86-1.659,2.641-2.64c1.436-1.364,2.471-3.129,3.84-4.56c0.086,1.194,0.672,1.888,0.72,3.12c0,0.08,0,0.16,0,0.24
			c0.398,0.571,0.398,2.79,0,3.36c-0.408,2.712-2.191,4.048-4.32,5.04c-0.342,0.058-0.404,0.396-0.72,0.48
			c-0.772,0.748-1.696,1.344-3.12,1.44c-1.109,0.49-2.986,0.213-3.84,0.96c0,0.24,0,0.48,0,0.72c0.364,0.916,1.481,1.079,1.92,1.92
			c0.531,0.589,1.377,0.864,2.16,1.2c2.709,0.731,4.645,2.235,6.48,3.84c1.539,1.901,3.5,3.379,5.04,5.28c0.08,0,0.159,0,0.239,0
			s0.16,0,0.24,0c-0.225-0.254-0.467-0.49-0.695-0.74c0.005,0.005,0.01,0.01,0.015,0.015c0.224,0.245,0.461,0.477,0.681,0.726
			c4.466,5.215,13.995,5.365,21.36,7.68c2.094,0.626,3.523,1.916,5.04,3.12c0.542,1.058,1.815,1.385,2.16,2.64
			c-0.16,0-0.32,0-0.48,0c-0.976-0.304-1.86-0.7-3.12-0.72c-1.718-0.282-3.396-0.603-5.52-0.48c-0.411-0.549-1.832-0.088-2.641-0.24
			c-2.354-0.165-4.576-2.621-6.72-1.44c0,0.16,0,0.32,0,0.48c0,0.32,0,0.64,0,0.96c0.854,1.227,1.865,2.295,2.4,3.84
			c0.544,0.896,1.215,1.665,1.68,2.64c0.364,0.755,1.069,1.171,1.2,2.16c0.921,1.479,1.978,2.822,2.88,4.32
			c0.775,0.825,1.573,1.626,1.92,2.88c0.405,0.955,1.132,1.588,1.44,2.64c0.249,0.951,0.7,1.699,0.72,2.88
			c0.536,0.104,0.097,1.183,0.24,1.68c0,0.8,0,1.6,0,2.4c0.08,0,0.159,0,0.239,0c0,0.4,0,0.8,0,1.2c0,1.44,0,2.88,0,4.32
			c0,0.56,0,1.12,0,1.68c0.08,0,0.16,0,0.24,0c0,0.32,0,0.64,0,0.96c0.058,0.183,0.103,0.385,0.142,0.595
			c-0.039-0.21-0.084-0.413-0.142-0.595c-0.272,1.451,0.662,5.242-0.72,4.8c-0.014-0.307-0.308-0.333-0.24-0.72
			c-0.393-0.808-0.624-1.776-0.96-2.64c-0.384-0.336-0.613-0.827-0.72-1.44c-1.09-0.43-1.247-1.793-1.92-2.64
			c0.557-0.716,1.106-2.473,0.479-3.6c-0.876-0.156-1.278,0.162-1.68,0.48c-0.664,0.616-0.994,1.566-1.44,2.4
			c-0.645,1.195-0.998,2.682-1.439,4.08c-0.371,2.11-1.096,3.865-1.681,5.76c-0.422,1.417-1.291,2.388-1.439,4.08
			c-0.188,0.053-0.279,0.201-0.24,0.48c-0.24-0.001-0.285,0.194-0.479,0.24c0.01,0.331-0.113,0.527-0.24,0.72
			c-2.262,4.938-8.057,6.344-15.601,6c-1.124,0.155-2.873-0.314-3.6,0.24c-0.24,0-0.48,0-0.72,0c-1.299,1.831-2.365,4.599-1.44,6.72
			c-2.155-1.058-4.591-3.341-4.08-6.24c0.234-1.327,2.242-1.761,1.2-3.36c-0.761-0.463-2.073,0.228-2.16,1.2
			c-0.74,0.228-1.381,1.807-1.92,1.44c-0.02-0.38,0.115-0.916-0.24-0.96c-0.521-0.907,0.227-1.166,0.48-1.92
			c1.14-0.94,2.18-1.98,3.12-3.12c2.973-4.147,7.314-6.925,7.92-13.44c0.53-0.03,0.102-1.018,0.24-1.44
			c0.314,0.085,0.377,0.423,0.72,0.48c0.793,1.746,1.854,3.774,2.399,6c0.292,1.188-0.052,3.138,1.44,3.6
			c1.447-2.081,0.21-5.692-1.2-8.64c-1.116-2.333-4.002-6.768-5.52-5.52c0,0.08,0,0.16,0,0.24c-0.262,1.381,1.103,1.138,0.72,2.64
			c-0.521-0.041-0.108,0.851-0.24,1.2c0,0.64,0,1.28,0,1.92c-0.289,3.311-1.985,5.214-3.6,7.2c-0.987,1.253-2.191,2.289-2.88,3.84
			c-0.98,0.779-1.86,1.66-2.641,2.64c-1.412,1.548-2.893,3.028-3.84,5.04c-0.342,1.063,0.813,0.628,0.72,1.44
			c-2.438,5.974-0.035,12.812,3.601,16.08c-6.367-0.753-9.76-4.48-11.28-10.08c0-0.8,0-1.6,0-2.4c0.022-3.272,2.683-5.138,2.4-7.92
			c-2.575,0.545-3.037,3.203-3.84,5.52c-1.979,2.981-7.201,2.719-11.521,3.36c-0.729,0.151-2.067-0.307-2.4,0.24
			c-3.41,0.451-5.348,2.935-5.52,6c-0.387,6.905,4.793,11.167,10.8,12c-3.096-0.304-5.258-0.322-7.2-1.44
			c-4.463-2.569-9.522-10.07-9.12-15.84c0.262-3.743,5.284-8.646,8.4-9.36c6.194-1.42,13.282-0.596,17.04-4.56
			c3.097-3.267,2.791-10.976-2.4-11.52c-0.046-0.837-1.555-0.442-0.72,0.24c-0.16,0-0.32,0-0.479,0
			c-1.643,5.718-2.352,12.369-9.841,12.24c-0.609,0.19-0.129,1.471-0.96,1.44c-2.13,0.35-4.073,0.887-6,1.44
			c0.103-0.858,0.64-1.281,0.48-2.4c0.397-0.259,0.397-2.141,0-2.4c-1.188-5.161-5.208-10.244-8.88-12.96
			c-1.59-1.176-4.851-3.407-6.24-2.16c0.31,2.732,5.82,2.338,6,6c0,0.8,0,1.6,0,2.4c-0.455,3.064-1.418,5.622-2.64,7.92
			c-0.9,0.699-1.701,1.5-2.4,2.4c0,0.24,0,0.48,0,0.72c0.727,1.024-1.096,1.206-0.96,2.4c-0.258,0.622-0.583,1.177-0.479,2.16
			c-0.281,0.039-0.203,0.438-0.24,0.72c-0.554,0.073-0.267-0.693-0.721-0.72c-0.68-1.205,0.476-2.918-0.479-3.6
			c-3.056,0.816-0.694,5.475,0.24,6.72c-2.288-0.752-3.444-2.636-3.84-5.28c-4.705,0.472-6.438,8.452-2.16,10.56
			c-4.65,0.544-8.142-3.396-8.16-7.92c-0.006-1.465,1.316-3.007,0-4.08c-0.9-0.631-1.16,0.766-1.726,1.298
			c-0.049,0.023-0.1,0.045-0.15,0.067c-0.063,0.028-0.126,0.058-0.19,0.085c0,0,0,0,0,0c-1.348,0.584-2.915,1.022-4.333,1.475
			c1.418-0.453,2.985-0.891,4.333-1.475c-2.85,0.138-4.822,1.154-6.814,2.148c0.487-2.553,2.713-3.367,4.56-4.56
			c1-0.36,2.039-0.681,2.64-1.44c2.027-1.813,5.717-1.963,5.52-6c0.397-0.336,0.397-2.304,0-2.64
			c-0.103-0.679-0.262-1.322-0.439-1.952c-0.041-0.167-0.089-0.333-0.137-0.499c0-0.001,0-0.002-0.001-0.003
			c0,0.001,0,0.001,0,0.002c-0.197-0.678-0.449-1.341-0.763-1.986c-3.525-7.808-12.521-11.906-20.26-15.963
			c-3.811-1.998-6.22-3.802-10.32-4.56c-0.062-0.498-1.055-0.065-1.2-0.48c0.084,0.796,0.719,1.042,1.2,1.44
			c0.562,1.358,2.074,1.766,3.36,2.4c-0.052,0.878-0.33,0.92,0,1.68c4.673,9.647,4.43,24.21,7.2,35.76
			c0.028,0.908-1.403,0.357-1.44,1.2c-0.154,5.841-4.107,9.121-6.48,12.96c-4.201,6.796-5.964,14.704-7.44,24.96
			c0.27,0.819,0.93-0.076,1.2,0.48c-0.579,3.742-1.306,7.334-1.44,11.52c-0.547,0.333-0.089,1.67-0.24,2.4
			c-0.812,5.486,0.063,9.96,1.671,13.888c-0.005-0.015-0.008-0.03-0.013-0.045c0.005,0.015,0.008,0.031,0.013,0.046
			c0.003,0.01,0.005,0.021,0.009,0.031c0.163,0.508,0.363,1,0.58,1.485c0.079,0.178,0.162,0.354,0.246,0.529
			c0.139,0.286,0.287,0.567,0.44,0.847c0.301,0.551,0.623,1.093,0.969,1.625c1.582,2.734,3.375,5.254,4.965,7.754
			c2.869,4.512,5.103,9.204,7.2,13.2c0,0.88,0,1.76,0,2.64c0,0.16,0,0.32,0,0.48c-0.397,0.259-0.397,2.142,0,2.4
			c2.881,3.868,2.098-6.142,3.36-8.16c0.547,0.333,0.09,1.67,0.24,2.4c-0.247,1.274-0.533,2.507-0.48,4.08
			c-0.398,0.571-0.398,2.789,0,3.36c0.014,1.346,0.484,2.236,1.44,2.64c1.605-0.941,1.914-3.284,2.64-5.04
			c0.749-1.812,1.36-3.641,2.4-5.04c0.544,0.255,0.091,1.509,0.24,2.16c-0.553,0.647-0.085,2.314-0.24,3.36
			c-0.309,3.691-2.202,5.799-3.36,8.64c1.674,1.368,4.252-0.252,6.24-0.96c2.132-0.76,4.355-1.774,6-2.64
			c-0.162,2.08-2.193,4.366-2.88,6.72c-0.759,0.601-1.08,1.64-1.44,2.64c-0.552,1.152-0.968,2.769-0.275,4.005
			c0,0.021,0.001,0.042,0.002,0.063c0,0.023,0.001,0.047,0.002,0.07c-0.53,1.023-1.327,1.921-0.208,2.822
			c1.838-0.297,3.109-1.446,5.04-2.16c3.003-1.109,6.56-0.687,8.4-2.88c-0.469,5.522-6.825,8.721-9.075,13.429
			c-0.105,0.186-0.198,0.376-0.275,0.574c-0.002,0.007-0.006,0.013-0.009,0.019c-0.079,0.204-0.144,0.413-0.188,0.63
			c-0.479,1.604-0.452,3.396,0.427,5.508c1.833-0.167,2.229-1.771,3.84-2.16c-0.619,5.767-4.725,13.456-0.24,18
			c4.05-3.15,9.362-5.038,13.68-7.92c2.577,0.704,1.989,4.571,5.52,4.32c0.492,0.398,2.628,0.398,3.12,0
			c1.671-0.01,2.721-0.64,3.84-1.2c0.303,2.319-3.629,3.708-2.64,6.72c4.076,1.457,8.568-0.669,12.48-0.96
			c0.941,0.545-1.487,1.653-0.96,3.36c2.812,2.471,6.293-0.927,8.88-1.68c1.324-1.795,1.96-4.28,2.399-6.96
			c0.625-0.599,1.068,1.546,1.92,0.72c1.172-0.429,1.455-1.746,1.92-2.88c0.854-0.187,0.451-1.629,1.44-1.68
			c0.697,1.882,0.708,5.263,2.4,5.52c1.604,0.244,1.985-1.96,2.88-3.36c0.053-0.038,0.113-0.093,0.177-0.157
			c0.819-0.658,1.56-1.395,2.206-2.225c-0.465,0.051-1.163,0.977-1.729,1.675c0.567-0.702,1.265-1.625,1.729-1.675
			c0.053-0.006,0.103,0.001,0.148,0.02c0.001,0,0.002,0.001,0.003,0.001c0.046,0.02,0.088,0.052,0.126,0.099
			c0.044,0.303-0.251,0.945,0.22,0.821c-0.156,1.036,0.414,1.345,0.72,1.92c1.135,0.175,1.578-0.342,2.16-0.72
			c0.343-0.938,0.982-1.578,1.92-1.92c0,0.64,0,1.28,0,1.92c0.525,0.435,0.041,1.879,0.48,2.4c0.195,1.084,0.693,1.866,0.96,2.88
			c0.317,0.002,0.219,0.42,0.479,0.48c0.054,0.186,0.007,0.473,0.24,0.48c0.474,0.166,0.64,0.64,0.72,1.2
			c0.466,1.055,0.802,2.238,2.16,2.4c1.594-1.046,2.3-2.98,3.36-4.56c0.866,0.733,1.014,2.186,2.399,2.4
			c2.14-0.981,1.64-4.601,2.88-6.48c0.085-0.716,0.139-1.461,0.721-1.68c0.597,2.204,2.354,3.246,3.359,5.04
			c0.004,0.477,0.655,0.305,0.48,0.96c0.581-0.378,1.062-0.858,1.439-1.44c0.356-1.084,0.852-2.029,1.2-3.12
			c0.564-7.515,3.177-12.983,5.761-18.48c0.52-1.88,0.63-4.17,0.96-6.24c0.57,0.389,0.381,1.538,1.68,1.2
			c0.235,0.736,0.641-0.276,0.96-0.24c0,0.88,0,1.76,0,2.64c-0.576,4.464-1.935,8.146-3.36,11.76c-0.241,1.118-0.44,2.279-0.96,3.12
			c-0.143,0.497,0.297,1.577-0.24,1.68c-0.183,1.303,0.297,1.944,0.24,3.12c-0.286,0.302-0.55,0.626-0.824,0.94
			c0.002-0.001,0.004-0.002,0.006-0.004c-0.002,0.002-0.005,0.003-0.007,0.005c0,0,0.001-0.001,0.001-0.001
			c-0.002,0.001-0.004,0.003-0.006,0.005c-0.211,0.157-0.415,0.321-0.609,0.495c-0.113,0.218-0.236,0.425-0.352,0.64
			c-3.415,4.255-6.059,9.28-9.729,13.28c-0.056,0.224-0.122,0.438-0.187,0.653c3.077-3.87,6.223-7.671,8.824-12.017
			c-2.602,4.346-5.747,8.147-8.824,12.017c-0.018,0.022-0.036,0.045-0.054,0.067c0.004,0.035,0.003,0.068,0.006,0.102
			c-0.697,2.231-1.813,4.042-2.406,6.378c-3.487-4.728-4.543,3.364-6.72,5.52c-1.038,1.028-3.852,2.027-5.28,2.4
			c-4.142,1.081-8.374,0.399-13.68,0.72c-1.443,0.157-3.516-0.316-4.56,0.24c-0.801,0-1.601,0-2.4,0c-1.44,0-2.88,0-4.32,0
			c-17.637-4.27-32.294-11.518-42.941-22.776c0.856,1.15,1.803,2.24,2.828,3.278c-1.025-1.039-1.973-2.129-2.829-3.279
			c0,0,0,0,0.001,0.001c-0.006-0.008-0.013-0.016-0.019-0.023c-0.001,0-0.003,0-0.004,0c-2.856,0.356-4.388-1.892-6.403-3.212
			c2.015,1.319,3.547,3.567,6.403,3.212c-0.077-0.082-0.159-0.158-0.235-0.24c-3.644,0.203-5.26-4.747-9.36-4.08
			c-1.291-2.469-4.285-3.235-4.8-6.48c-1.895-1.305-3.853-2.547-5.76-3.84c-1.34-1.54-2.78-2.98-4.32-4.32
			c-0.833-1.248-0.922-3.238-3.12-3.12c-0.814,1.061,0.085,1.815,0,3.36c0,0.64,0,1.28,0,1.92c-0.171-0.04-0.234-0.19-0.41-0.226
			c-0.065-0.118-0.12-0.243-0.189-0.358c-1.297-3.808-5.243-5.718-7.081-9.256c-1.752-3.372-2.102-7.313-3.36-11.76
			c0.417,0.844,0.52,0.74,0.48-0.48c0.402,0.318,0.804,0.636,1.68,0.48c1.52-1.878,1.09-4.578,1.44-6.96
			c0.343-2.337,1.144-4.656,1.2-6.96c0.266-10.941-4.688-20.056-6.24-30.72c-1.199-8.239-2.168-15.957-4.8-22.8
			c-0.654-1.701-0.938-4.045-2.442-5.216c0,0,0,0.001,0,0.001c-0.019-0.033-0.046-0.057-0.088-0.066
			c-0.087-0.064-0.163-0.14-0.26-0.196c0.074,0.042,0.124,0.107,0.149,0.197c-0.027-0.097-0.084-0.166-0.168-0.207
			c-0.176-0.1-0.346-0.204-0.552-0.273c-1.269,1.841,1.158,3.895,1.44,5.76c3.464,7.854,3.603,17.05,5.28,26.4
			c1.603,8.935,5.172,17.651,5.04,26.64c-0.062,4.236-1.653,8.284-1.92,12.48c-0.085-0.275-0.422-0.608-0.48-0.24
			c-0.368-0.993-0.441-2.279-0.48-3.6c0.398-0.649,0.398-2.951,0-3.6c0,0.8,0,1.6,0,2.4c-0.551-0.489-0.087-1.993-0.24-2.88
			c-0.098-0.302-0.31-0.49-0.72-0.48c0.029-1.592,3.078-2.381,1.44-4.08c0-0.241-0.194-0.286-0.24-0.48
			C237.099,312.583,237.238,314.482,236.161,315.165z M221.903,252.501C221.903,252.5,221.903,252.5,221.903,252.501
			c0.001,0.002,0.003,0.004,0.004,0.007C221.906,252.505,221.904,252.504,221.903,252.501z M380.872,195.223
			c-0.016-0.152-0.033-0.301-0.055-0.445C380.839,194.922,380.857,195.07,380.872,195.223z M321.601,205.724
			c0.414-0.786,1.267-1.134,1.68-1.92c1.331-1.869,2.123-4.277,2.641-6.96c0-0.88,0-1.76,0-2.64c0.063-0.373,0.526-0.167,0.72,0
			c0,0.08,0,0.16,0,0.24c5.013,3.792,0.975,11.89-5.04,11.76c0.459-0.661,1.019-1.221,1.68-1.68
			C322.821,204.081,322.262,205.573,321.601,205.724z M275.365,325.102c0,0.001-0.001,0.002-0.001,0.003c0,0,0,0,0,0
			C275.364,325.104,275.364,325.103,275.365,325.102z M187.68,415.005c1.223-0.549,2.33-1.516,3.36-1.92
			c2.407-0.946,5.313-1.254,7.92-1.92c7.6-1.942,16.419-4.659,23.52-7.2c0.373,2.062,2.038,3.156,3.502,4.275l0,0c0,0,0,0,0.001,0
			c0,0,0,0,0,0c0,0,0,0,0.001,0.001c0.526,0.556,1.083,1.082,1.677,1.57c0,0,0,0,0.001,0c0,0,0,0,0,0
			c0.266,0.218,0.537,0.431,0.819,0.633c-0.282-0.202-0.553-0.415-0.819-0.633c0.254,0.337,0.459,0.7,0.579,1.113
			c0.628,2.172-0.651,4.935-0.48,7.2c0.222,2.932,2.153,5.009,3.36,7.199c1.41,2.56,1.972,4.969,3.6,6.961
			c4.411,5.394,10.464,10.401,16.08,15.359c3.82,3.373,6.986,6.908,10.08,10.8c3.031,3.813,5.544,8.353,9.84,11.04
			c1.7-1.099,1.317-4.142,1.44-6.479c0.13-2.488,0.206-5.205,0.24-7.44c3.421,2.145,2.458,7.17,1.92,12.24
			c-0.86,8.109-1.803,17.32-2.16,25.44c-10.761,1.385-22.428,0.845-34.15,0.521c-1.63-0.077-3.256-0.145-4.874-0.194
			c-0.14-0.004-0.278-0.007-0.417-0.011c-1.582-0.045-3.158-0.076-4.719-0.076c-1.563,0-3.12,0.035-4.673,0.087
			c-0.225,0.007-0.448,0.018-0.672,0.026c-1.478,0.055-2.952,0.129-4.421,0.224c-0.009,0-0.017,0-0.025,0.001
			c0.004,0,0.008,0,0.011,0c-0.009,0-0.018,0.001-0.027,0.001c0.005,0,0.01-0.001,0.016-0.001c-1.786,0.095-3.565,0.212-5.328,0.383
			c-7.501,0.729-14.99,1.731-22.32,2.399c-10.583,0.965-20.346,2.414-30.48,3.36c-1.101,5.639-3.955,11.334-5.76,17.04
			c1.355-9.784,3.12-19.878,3.12-31.92c0-5.364-1.123-10.902-0.48-15.84c0.548-4.213,3.186-8.761,4.32-12.721
			c0.45-1.57,0.296-3.238,0.72-4.8c0.569-2.097,1.917-3.845,2.4-5.76c0.423-1.678,0.109-3.37,0.48-5.04
			c0.528-2.375,2.437-5.262,3.6-8.16c2.012-5.014,2.853-8.817,6.72-11.76C178.465,418.521,183.579,416.846,187.68,415.005z
			 M128.4,433.965c-0.036,0.269-0.105,0.499-0.19,0.71C128.298,434.467,128.365,434.233,128.4,433.965z M127.16,435.893
			c0.001-0.001,0.002-0.002,0.004-0.003c-0.164,0.15-0.332,0.297-0.5,0.442C126.831,436.188,126.998,436.042,127.16,435.893z
			 M119.163,442.288c-0.48,0.58-0.944,1.174-1.39,1.781c-0.001,0.001-0.002,0.003-0.003,0.004
			C118.217,443.466,118.682,442.87,119.163,442.288z M105.706,471.769c-1.241,3.604-2.58,7.32-3.69,11.071
			C103.125,479.088,104.465,475.373,105.706,471.769z M99.619,496.021c2.992-17.607,9.003-32.194,15.101-46.695
			c0.787-0.493,0.917-1.644,1.44-2.4c0.253-0.855,0.902-1.315,1.133-2.192c0.102-0.144,0.206-0.286,0.309-0.428
			c1.042-0.884,2.008-1.845,2.877-2.9c0.08,0,0.16,0,0.24,0c0.315-0.084,0.377-0.422,0.72-0.479c0.9-0.699,1.7-1.5,2.4-2.4
			c0-0.08,0-0.159,0-0.239c0.106-0.214,0.21-0.43,0.48-0.48c2.904-0.617,4.714-3.604,6.48-4.56c0.059-0.261,0.477-0.163,0.48-0.48
			c0.388,0.067,0.413-0.227,0.72-0.24c15.888-5.632,31.669-11.371,46.8-17.76c0.08,0,0.16,0,0.24,0
			c0.691-0.029,1.571,0.131,1.92-0.24c0,0.16,0,0.32,0,0.48c-0.935,0.585-2.283,0.757-2.88,1.68c-0.08,0-0.16,0-0.24,0
			c-0.863,0.257-1.535,0.706-1.92,1.44c-0.08,0-0.16,0-0.24,0c-0.16,0-0.32,0-0.48,0c-1.008,0.992-2.253,1.746-3.12,2.88
			c-0.08,0-0.16,0-0.24,0s-0.16,0-0.24,0c-0.713,0.891-2.792,1.682-2.16,3.12c0.091,1.051-0.686,1.233-0.72,2.16
			c-0.55,1.449-1.22,2.779-1.68,4.319c-0.278,1.482-0.93,2.59-1.44,3.84c-0.896,0.945-1.16,2.521-1.68,3.841
			c0.115,0.755-0.342,0.938-0.48,1.439c-1.107,1.612-1.745,3.695-2.4,5.76c-0.073,1.207-0.664,1.896-0.96,2.881
			c-0.924,1.476-1.295,3.505-1.92,5.279c0.024,0.744-0.293,1.146-0.48,1.681c-0.823,1.337-1.257,3.063-1.68,4.8
			c0,0.08,0,0.16,0,0.24c-0.548,0.731-0.598,1.962-0.96,2.88c-0.019,0.94-0.423,1.497-0.72,2.159
			c-0.714,1.047-0.991,2.529-0.96,4.32c0,0.08,0,0.16,0,0.24c-0.554,0.727-0.084,2.476-0.24,3.6c-0.4,0.566-0.265,2.191-0.24,3.601
			c0.018,0.995-0.179,3.273,0.24,2.399c0.08,0,0.16,0,0.24,0c0.028,0.691-0.131,1.571,0.24,1.92c0,0.24,0,0.48,0,0.721
			c-0.603,5.717,0.202,12.842-1.2,17.76c-1.523,0.157-3.677-0.316-4.8,0.24c-0.32,0-0.64,0-0.96,0c-0.08,0-0.16,0-0.24,0
			c0-0.08,0-0.16,0-0.24c-2.177-0.063-3.919-0.561-6.24-0.48c0-0.079,0-0.159,0-0.239c-4.66-0.419-11.073-0.186-16.32,0.239
			c0.445-0.035-1.617-0.159-0.48,0.24c0,0.08,0,0.16,0,0.24c-0.08,0-0.16,0-0.24,0c-8.139,0.101-14.692,1.788-22.32,2.4
			c-0.262-1.019-0.181-2.38-0.72-3.12c-0.253-0.929-0.062-2.301-0.463-3.08C99.618,496.117,99.618,496.068,99.619,496.021z
			 M152.16,503.308c-0.427-0.205-1.037-0.283-1.684-0.298C151.125,503.023,151.733,503.102,152.16,503.308z M79.221,673.693
			C79.221,673.693,79.221,673.693,79.221,673.693c-0.001-0.006-0.001-0.013-0.002-0.02C79.22,673.681,79.221,673.688,79.221,673.693
			z M79.03,674.405L79.03,674.405c-0.073,0.241-0.154,0.472-0.238,0.698C78.876,674.877,78.957,674.646,79.03,674.405z
			 M76.794,678.853c0.448-0.712,0.894-1.419,1.294-2.179C77.689,677.435,77.243,678.141,76.794,678.853
			c-0.379,0.603-0.761,1.209-1.118,1.857C76.033,680.062,76.415,679.455,76.794,678.853z M73.461,691.407
			c0.077-0.613,0.159-1.225,0.219-1.842C73.62,690.183,73.538,690.795,73.461,691.407L73.461,691.407z M61.44,800.926
			c0-0.08,0-0.16,0-0.24c0.666-1.654,0.553-4.087,0.72-6.24c0.56-4.32,0.08-9.68,0.24-14.4c0-1.439,0-2.88,0-4.319
			c0-0.08,0-0.16,0-0.24c0.549-0.411,0.088-1.832,0.24-2.64c0-0.08,0-0.16,0-0.24c0.37-1.391,0.428-3.092,0.48-4.8
			c0-0.08,0-0.16,0-0.24c0.437-2.043,0.581-4.379,0.72-6.72c0-0.08,0-0.16,0-0.24c0.595-1.166,0.452-3.068,1.2-4.08
			c0.568-0.632,0.79-1.61,0.96-2.64c-0.344,0.296-0.455,0.825-0.48,1.439c-0.08,0-0.16,0-0.24,0c0.063-0.977,0.451-1.628,0.72-2.399
			c0.465-0.335,0.358-1.242,0.72-1.681c0.674-0.766,0.824-2.056,1.2-3.12c0.764-3.236,2.074-5.926,2.64-9.359c0-0.08,0-0.16,0-0.24
			c0.783-2.258,1.354-4.726,1.68-7.44c0-0.079,0-0.16,0-0.239c0.684-1.717,0.685-4.115,1.2-6c1.669-1.212,3.151-2.609,3.6-5.04
			c-0.07-1.271,0.621-1.779,0.48-3.12c0-0.08,0-0.16,0-0.24c0.398-0.414,0.398-2.466,0-2.88c-0.049-0.751,0.162-1.762-0.24-2.16
			c0.113-2.434-0.688-3.952-0.72-6.24c1.413-1.227,2.996-2.284,4.56-3.359c0.867-0.573,2.648-0.232,3.6-0.721c0.08,0,0.16,0,0.24,0
			c1.938,0.178,3.271-0.248,3.84-1.439c-0.194-0.046-0.239-0.241-0.48-0.24c-0.494-0.554-1.982-0.257-1.2-1.44
			c0-0.319,0-0.64,0-0.96c-0.5-2.539-2.438-3.642-3.12-6c0.578-0.098,0.522,0.438,1.2,0.24c1.214,0.146,2.109,0.611,3.12,0.96
			c0.217,0.048,0.412,0.074,0.591,0.083c0.07,0.004,0.128-0.006,0.193-0.007c0.099-0.002,0.204,0.001,0.293-0.012
			c0.097-0.015,0.182-0.041,0.269-0.065c0.044-0.013,0.094-0.02,0.136-0.034c0.106-0.037,0.202-0.084,0.296-0.133
			c0.016-0.008,0.034-0.014,0.049-0.021c0,0-0.001,0-0.001,0c0.001,0,0.002-0.001,0.003-0.001c-0.001,0-0.001,0.001-0.002,0.001
			c1.775-0.173,3.231-0.166,5.374,0.909c0.502-0.102,0.636,0.165,0.96,0.24c0.536,0.585,1.567,0.674,2.4,0.96
			c1.657,0.184,2.663,1.018,4.32,1.2c0.08,0,0.16,0,0.24,0c1.078,0.603,2.398,0.962,3.84,1.2c0.08,0,0.16,0,0.24,0
			c0.98,0.7,2.705,0.655,3.84,1.2c1.648,0.751,3.789,1.011,6,1.199c0.48,0,0.96,0,1.44,0c0.08,0,0.16,0,0.24,0
			c3.191,0.729,6.804,1.037,10.8,0.96c0.85-0.029,1.366-0.394,1.92-0.72c-0.123-0.758-1.096-0.664-1.68-0.96
			c-2.959-0.24-5.515-0.885-8.64-0.96c-0.08,0-0.16,0-0.24,0c-0.38-0.5-1.679-0.081-2.16-0.479c-0.656-0.384-1.586-0.494-2.64-0.48
			c-0.08,0-0.16,0-0.24,0c-0.977-0.31-2.863-0.969-3.84-0.479c0.24,0,0.48,0,0.72,0c0.279-0.039,0.427,0.053,0.48,0.239
			c-2.056-0.104-3.306-1.014-5.28-1.199c1.078-0.207-1.314-0.645-1.92-0.721c-1.144,0.024-1.793-0.447-2.64-0.72
			c-1.669-0.812-3.65-1.31-5.52-1.92c-1.38-0.22-2.512-0.688-3.6-1.2c-0.942-0.737-2.441-0.919-3.6-1.439
			c-0.203-1.558-0.897-2.623-1.2-4.08c0.79,0.01,1.195,0.404,2.16,0.239c1.303-0.183,1.86,0.381,3.12,0.24c0.08,0,0.16,0,0.24,0
			c1.743,0.497,3.671,0.81,5.76,0.96c0.08,0,0.16,0,0.24,0c1.173,0.428,2.613,0.587,4.08,0.72c0.637,0.003,1.371-0.09,1.68,0.24
			c1.609,0.551,3.512,0.809,5.52,0.96c0.638,0.003,1.371-0.091,1.68,0.24c0.759,0.521,2.306,0.255,3.12,0.72
			c0.813,0.387,1.908,0.492,3.12,0.48c1.586-0.146,2.46,0.419,4.08,0.24c0.08,0,0.16,0,0.24,0c0.074,0.325,0.601,0.198,0.96,0.239
			c1.364,0.399,4.396,0.399,5.76,0c0.38-0.02,0.916,0.116,0.96-0.239c0.08,0,0.16,0,0.24,0c1.688,0.008,3.271-0.09,4.56-0.48
			c0.815-0.065,1.946,0.186,2.4-0.24c1.92,0.081,3.251-0.429,5.04-0.479c2.1-0.46,4.51-0.61,4.8-2.88
			c-1.773-1.125-4.249,0.749-6.24,0.96c-3.935,0.545-7.817,1.143-12.24,1.199c-1.6,0-3.2,0-4.8,0c-0.32,0-0.64,0-0.96,0
			c-2.24-0.479-4.666-0.773-7.2-0.96c-0.48,0-0.96,0-1.44,0c-0.08,0-0.16,0-0.24,0c-5.588-1.131-11.349-2.091-17.28-2.88
			c-0.08,0-0.16,0-0.24,0c-1.961-0.679-4.588-0.692-6.72-1.199c-1.385-0.535-3.242-0.599-4.56-1.2c-0.4,0-0.8,0-1.2,0
			c-0.42-0.38-1.278-0.321-1.92-0.48c0.336-0.464,1.14-0.46,1.2-1.2c0-0.079,0-0.16,0-0.239c0.651-0.709,1.037-1.684,1.44-2.641
			c-1.312-2.505-0.342-5.362,0.24-7.92c0-0.319,0-0.64,0-0.96c0-0.08,0-0.16,0-0.24c0.405-0.475,0.262-1.498,0.48-2.16
			c0.551-0.488,0.086-1.992,0.24-2.88c0-1.279,0-2.56,0-3.84c0.4-4.96,0.4-11.6,0-16.56c0-0.641,0-1.28,0-1.92
			c-0.156-1.284,0.315-3.195-0.24-4.08c0-0.48,0-0.96,0-1.44c-0.156-1.124,0.313-2.874-0.24-3.6c0-0.4,0-0.8,0-1.2
			c-0.155-1.045,0.313-2.713-0.24-3.36c-0.131-0.349,0.28-1.24-0.24-1.2c0-0.079,0-0.159,0-0.239c0.153-2.394-0.415-4.065-0.24-6.48
			c0-0.08,0-0.16,0-0.24c0.368-0.992,0.441-2.279,0.48-3.6c0-0.08,0-0.16,0-0.24c0.52,0.041,0.108-0.851,0.24-1.2
			c0.835-1.486,1.002-4.914,0.48-6.96c0-1.84,0-3.68,0-5.52c0-1.76,0-3.521,0-5.28c0-0.56,0-1.12,0-1.68
			c0.557-1.283,0.083-3.598,0.24-5.28c0-0.08,0-0.16,0-0.24c0.556-0.964,0.083-2.956,0.24-4.319c0-0.641,0-1.28,0-1.92
			c0-0.08,0-0.16,0-0.24c0.319-1.129,0.621,1.511,1.44,1.439c1.871-0.851,2.548-4.17,1.92-6.72c-0.443-1.236-0.08-3.28-0.72-4.32
			c0-0.079,0-0.159,0-0.239c-1.211-6.146,0.08-12.512,2.4-16.561c2.002-2.958,4.673-5.247,5.76-9.12
			c-0.075-0.954,0.554-1.205,0.48-2.16c0-0.079,0-0.159,0-0.239c0.534-0.746,0.649-1.911,0.72-3.12c0-0.08,0-0.16,0-0.24
			c0.621-1.779,0.468-4.332,0.72-6.479c0.398-0.65,0.398-2.951,0-3.601c0.04-1.4-0.419-2.301-0.24-3.84
			c-0.152-0.808,0.31-2.229-0.24-2.64c0-1.601,0-3.2,0-4.801c0.426-0.454,0.175-1.585,0.24-2.399c0.446-1.635,0.663-3.498,0.96-5.28
			c0.276-0.283,0.272-0.849,0.24-1.44c0.383-0.656,0.494-1.585,0.48-2.64c0-0.08,0-0.16,0-0.24c0.625-0.642,0.048-2.115,0-2.88
			c1.616-0.304,3.62-0.22,5.04-0.72c0.08,0,0.16,0,0.24,0c1.269-0.012,2.453-0.107,3.36-0.48c0.08,0,0.16,0,0.24,0
			c1.268-0.012,2.453-0.106,3.36-0.479c0.08,0,0.16,0,0.24,0c7.423-0.737,15.164-1.157,23.52-0.96c0.08,0,0.16,0,0.24,0
			c2.924,0.516,6.015,0.865,9.36,0.96c0.08,0,0.16,0,0.24,0c0.755,0.521,2.405,0.145,3.602,0.224
			c0.002,0.001,0.005,0.003,0.007,0.004c0.084,0.042,0.162,0.087,0.231,0.14c0,0.598,0,1.194,0,1.793c0,0.08,0,0.16,0,0.239
			c-0.6,0.601-0.2,2.2-0.72,2.881c-0.868-0.412-0.709-1.851-0.72-3.12c0.099-2.328-2.727,0.144-1.44,1.2
			c0.695,2.799-0.062,6.16-0.24,8.88c0,0.08,0,0.159,0,0.239c-0.364,0.597-0.463,1.457-0.48,2.4c0.032,0.592,0.036,1.156-0.24,1.44
			c-1.423,5.136-1.441,11.679-2.64,17.04c-0.194-0.046-0.239-0.241-0.48-0.24c0-0.08,0-0.16,0-0.24c0-2.56,0-5.12,0-7.68
			c0-0.24,0-0.48,0-0.721c0.556-1.123,0.083-3.277,0.24-4.8c0-0.08,0-0.16,0-0.239c0.554-0.727,0.084-2.476,0.24-3.601
			c0-0.16,0-0.32,0-0.479c0.554-0.727,0.084-2.476,0.24-3.601c-0.054-2.427,0.168-5.128-0.24-7.2c-1.131,0.069-1.065,1.335-1.2,2.4
			c0.057,1.018-0.221,1.699-0.48,2.4c-0.409,0.711-0.264,1.976-0.48,2.88c-0.514,0.846-0.503,2.217-0.48,3.6c0,0.08,0,0.16,0,0.24
			c-0.551,0.489-0.087,1.993-0.24,2.88c0,0.08,0,0.16,0,0.24c-0.556,1.044-0.083,3.117-0.24,4.56c0,0.16,0,0.32,0,0.48
			c-0.4,2.401-0.4,6.478,0,8.88c0,0.16,0,0.32,0,0.479c0.157,1.523-0.316,3.677,0.24,4.801c0,0.08,0,0.159,0,0.239
			c0.155,1.046-0.313,2.713,0.24,3.36c0,0.08,0,0.16,0,0.24c0.152,0.808-0.309,2.229,0.24,2.64c0,0.08,0,0.16,0,0.24
			c0.13,3.01-0.9,4.859-0.72,7.92c0,0.08,0,0.16,0,0.24c-0.4,2.162-0.4,5.998,0,8.16c0.155,1.045-0.313,2.713,0.24,3.359
			c0,0.08,0,0.16,0,0.24c-0.021,1.621,0.072,3.128,0.48,4.32c0,0.08,0,0.16,0,0.239c0.014,1.106,0.111,2.13,0.48,2.881
			c0,0.08,0,0.159,0,0.239c0.772,5.068,2.062,9.619,3.36,14.16c-0.053,1.333,0.462,2.098,0.72,3.12
			c0.367,1.793,0.736,3.585,1.44,5.04c0,0.08,0,0.16,0,0.24c0.938,4.182,2.075,8.165,3.36,12c-0.015,0.895,0.242,1.518,0.48,2.16
			c-0.07,1.27,0.621,1.778,0.48,3.12c0.696,1.612-0.968,2.036-1.2,3.359c-0.31,1.766,0.9,4.539,1.44,5.761
			c1.541,3.484,2.046,8.372,3.36,11.279c-3.455,0.865-5.533,3.107-7.2,5.76c-1.085,0.515-3.087,0.113-4.56,0.24c-2.4,0-4.8,0-7.2,0
			c-1.204,0.156-3.035-0.314-3.84,0.24c-0.08,0-0.16,0-0.24,0c-1.206-0.006-2.174,0.227-2.64,0.96
			c4.635,2.646,13.392,1.168,19.92,1.92c0.729-0.15,2.067,0.308,2.4-0.24c0.455-0.426,1.585-0.175,2.4-0.239
			c0.88,0.151,2.367-0.303,2.867,0.229c-0.051-0.094-0.108-0.18-0.172-0.258c0.064,0.078,0.123,0.164,0.174,0.259
			c0,0-0.001-0.001-0.001-0.001c0.004,0.008,0.009,0.015,0.013,0.022c0.357,0.683,0.387,1.736,0.284,2.774
			c0.102-1.036,0.073-2.091-0.284-2.774c0,1.517,0,3.031,0,4.548c0,0.08,0,0.16,0,0.24c-0.558,1.682-0.082,4.398-0.24,6.479
			c-1.81,0.11-3.23,0.609-4.56,1.2c-0.08,0-0.16,0-0.24,0c-1.263,0.257-2.496,0.544-3.36,1.2c-0.857,0.343-1.547,0.853-2.64,0.96
			c-0.833,0.286-1.864,0.376-2.4,0.96c-0.769,0.191-1.211,0.709-2.16,0.72c-1.769,0.631-3.332,1.468-4.08,3.12
			c0.254,0.895-0.505,0.774-0.72,1.2c-0.941,0.019-1.497,0.423-2.16,0.72c-2.749,0.771-5.175,1.865-8.16,2.4
			c-1.084,0.355-2.029,0.851-3.12,1.199c0.179,0.542,1.346,0.095,1.92,0.24c0.08,0,0.16,0,0.24,0c0.259,0.397,2.141,0.397,2.4,0
			c2.489-0.631,5.266-0.974,7.2-2.16c0.941-0.019,1.497-0.423,2.16-0.72c1.046-0.313,2.123-0.598,2.88-1.2c0.08,0,0.16,0,0.24,0
			c1.617-0.543,3.284-1.036,4.56-1.92c0.08,0,0.16,0,0.24,0c0.903-0.217,1.724-0.516,2.4-0.96c0.08,0,0.16,0,0.24,0
			c1.195-0.085,1.9-0.66,3.36-0.479c0.729-0.15,2.067,0.307,2.4-0.24c0.08,0,0.16,0,0.24,0c1.329-0.049,2.593-0.033,3.36,0.479
			c0,0.16,0,0.32,0,0.48c0,0.08,0,0.16,0,0.24c-0.656,0.864-0.943,2.097-1.2,3.359c0,0.08,0,0.16,0,0.24
			c-1.123,2.318-2.312,5.847-1.44,9.36c0,0.08,0,0.16,0,0.24c-1.004,0.756-1.422,2.098-1.44,3.84
			c0.221,1.101-0.507,1.253-1.44,1.199c-1.428-0.147-2.235,0.325-3.12,0.721c-2.022,0.377-1.109,3.026,1.2,2.399
			c0.303,0.816,2.805-0.564,2.4,0.96c-0.077,2.243-0.361,4.279-0.72,6.24c-0.547,0.333-0.089,1.671-0.24,2.4c0,0.08,0,0.16,0,0.24
			c-0.549,0.41-0.088,1.832-0.24,2.64c0,0.08,0,0.16,0,0.24c-0.551,0.489-0.087,1.993-0.24,2.88c0,0.08,0,0.16,0,0.24
			c-0.553,0.646-0.085,2.314-0.24,3.359c0,0.24,0,0.48,0,0.721c-0.554,0.805-0.084,2.636-0.24,3.84c0,0.16,0,0.319,0,0.479
			c-0.557,1.203-0.083,3.438-0.24,5.04c0,0.16,0,0.32,0,0.48c-0.558,1.521-0.082,4.078-0.24,6c-0.054,0.605,0.057,1.376-0.01,1.969
			c0.053-0.385,0.097-0.778,0.143-1.171c-0.048,0.407-0.09,0.819-0.145,1.218c0.002-0.015,0.001-0.032,0.002-0.047
			c-0.008,0.063-0.014,0.128-0.023,0.19c-0.038,0.191-0.101,0.357-0.207,0.48c-0.364,0.596-0.463,1.457-0.48,2.4
			c-0.002,0.638,0.091,1.37-0.24,1.68c-0.774,1.197-0.016,2.65-0.24,4.56c0,0.08,0,0.16,0,0.24c-0.368,0.992-0.44,2.279-0.48,3.601
			c0,0.56,0,1.12,0,1.68c0,0.08,0,0.16,0,0.24c-0.368,1.071-0.411,2.469-0.48,3.84c-0.029,0.691,0.131,1.571-0.24,1.92
			c-0.362,0.678-0.423,1.656-0.48,2.64c0.032,0.592,0.037,1.156-0.24,1.44c-0.64,2.159-1.104,4.496-1.2,7.2c0,0.08,0,0.16,0,0.239
			c-0.392,1.769-0.487,3.833-0.48,6c0,0.08,0,0.16,0,0.24c-0.378,2.021-0.668,4.132-0.72,6.48c0,0.08,0,0.16,0,0.239
			c-0.843,5.318-1.195,11.126-1.68,16.801c0,0.08,0,0.16,0,0.24c-1.057,6.783-2.041,13.639-3.12,20.399c-22.414,0-46.949,0-70.56,0
			c-1.577,0-3.368,0.149-5.066,0.241c-0.389,0-0.787-0.001-1.174-0.001c-0.143,0-0.302,0.008-0.466,0.018
			c-0.032,0.002-0.063,0.004-0.096,0.006c-0.165,0.011-0.336,0.025-0.513,0.04c0,0-0.001,0-0.002,0
			c-1.068-0.006-2.033-0.077-2.764-0.304c-0.268-0.083-0.213-0.403-0.24-0.72C60.569,803.095,61.321,802.326,61.44,800.926z
			 M153.397,644.545c-2.021,0.115-4.1,0.223-6.182,0.272C149.295,644.768,151.375,644.66,153.397,644.545z M152.931,703.932
			c-0.204,4.572-0.23,9.32-0.53,13.797C152.7,713.252,152.725,708.501,152.931,703.932z M398.4,636.525
			c0.494,0.475,2.397,0.79,3.12,0.24c0.81-0.392,2.419,0.019,3.12-0.48c0.08,0,0.16,0,0.24,0c1.848,0.008,3.591-0.089,5.04-0.48
			c0.08,0,0.16,0,0.24,0c1.688,0.009,3.271-0.089,4.56-0.479c0.08,0,0.16,0,0.24,0c1.37,0.011,2.628-0.092,3.6-0.48
			c0.08,0,0.16,0,0.24,0c1.576,0.137,2.323-0.557,3.84-0.479c1.262-0.019,2.257-0.303,3.12-0.72c0.08,0,0.16,0,0.24,0
			c0.595,0.312,1.164-0.536,1.2,0c-0.199,0.601-0.658,0.942-0.721,1.68c0.002,1.361-0.168,2.552-0.479,3.6
			c-0.372,1.309-0.857,2.503-0.72,4.32c0,0.56,0,1.12,0,1.68c0,0.08,0,0.16,0,0.24c-0.08,0-0.16,0-0.24,0
			c-0.372,2.108-0.544,4.417-0.96,6.48c-0.302,2.181-0.253,3.376-0.721,5.52c-0.056,2.664-0.509,4.931-0.96,7.2
			c-0.397,0.492-0.397,2.628,0,3.12c0,0.64,0,1.28,0,1.92c0.139,2.539-0.168,4.632-0.239,6.96c0,2.16,0,4.32,0,6.48
			c0,0.239,0,0.479,0,0.72c0,0.16,0,0.319,0,0.479c0.536,0.104,0.097,1.184,0.239,1.681c-0.156,1.283,0.315,3.194-0.239,4.08
			c0,0.079,0,0.159,0,0.239c-0.075,0.955,0.554,1.206,0.479,2.16c0.08,0,0.16,0,0.24,0c0,0.72,0,1.44,0,2.16
			c0.414,2.386,0.5,5.1,0.479,7.92c0,0.24,0,0.48,0,0.72c0,0.561,0,1.12,0,1.681c0.08,0,0.16,0,0.24,0c0,0.64,0,1.279,0,1.92
			c0,1.359,0,2.72,0,4.08c0,1.439,0,2.88,0,4.319c0,0.801,0,1.601,0,2.4c-0.041,2.039-0.108,4.052-0.479,5.76
			c0.065,5.346-1.336,9.225-1.681,14.16c-0.08,0-0.159,0-0.239,0c0,0.96,0,1.92,0,2.88c0.08,0,0.159,0,0.239,0
			c-0.15,3.352,1.099,5.303,2.16,7.44c0,0.8,0,1.6,0,2.399c0,0.24,0,0.48,0,0.721c0,0.64,0,1.279,0,1.92c0.08,0,0.16,0,0.24,0
			c0,0.64,0,1.279,0,1.92c0.308,2.172,0.497,4.462,1.2,6.24c0,0.399,0,0.8,0,1.199c0,1.44,0,2.881,0,4.32c0,0.4,0,0.8,0,1.2
			c0,0.64,0,1.28,0,1.92c0.08,0,0.16,0,0.24,0c-0.034,2.674,0.416,4.863,0.239,7.68c0,0.24,0,0.48,0,0.721c0,0.72,0,1.439,0,2.159
			c0.08,0,0.16,0,0.24,0c0,0.561,0,1.12,0,1.681c0.158,2.081-0.318,4.798,0.24,6.479c0,0.32,0,0.641,0,0.96
			c-0.559,2.002-0.081,5.039-0.24,7.44c0,1.2,0,2.4,0,3.6c-0.08,0-0.16,0-0.24,0c0,0.96,0,1.92,0,2.881
			c-0.138,0.422,0.29,1.41-0.239,1.439c0.186,0.826-0.134,1.146-0.961,0.96c-1.442-0.558-3.918-0.082-5.76-0.24
			c0,0.08,0,0.16,0,0.24c-0.319,0-0.64,0-0.96,0c-0.24,0-0.479,0-0.72,0c-1.36,0-2.72,0-4.08,0c-41.76,0-83.521,0-125.28,0
			c-11.12,0-22.24,0-33.36,0c-0.085-4.805,0.425-9.015,0.24-13.92c0.556-1.044,0.083-3.117,0.24-4.56c0-1.92,0-3.841,0-5.761
			c0-0.079,0-0.16,0-0.239c0.345-2.455,0.587-5.013,0.72-7.681c0-0.08,0-0.16,0-0.24c0.375-1.063,0.47-2.409,0.48-3.84
			c0-0.08,0-0.16,0-0.239c0.473-2.247,0.71-4.73,0.72-7.44c0-0.08,0-0.16,0-0.24c0.64-3.76,0.69-8.109,0.96-12.24
			c0.547-0.332,0.089-1.67,0.24-2.399c0-6.08,0-12.16,0-18.24c0.558-1.682,0.082-4.398,0.24-6.48c0-1.439,0-2.88,0-4.319
			c0.547-0.333,0.089-1.671,0.24-2.4c-0.135-3.654,0.716-6.324,0.96-9.6c0-0.08,0-0.16,0-0.24c0.79-2.729,1.069-5.971,1.68-8.88
			c0.313-1.047,0.725-1.995,0.72-3.36c0.474-0.566,0.565-1.515,0.72-2.399c0-0.08,0-0.16,0-0.24c0.417-0.863,0.702-1.858,0.72-3.12
			c0.445-0.516,0.162-1.758,0.24-2.64c0.233-0.007,0.187-0.294,0.24-0.48c-0.153-0.887,0.311-2.391-0.24-2.88
			c-0.122-0.838-0.108-1.813-0.48-2.4c0-0.079,0-0.16,0-0.239c-0.257-0.943-0.052-2.349-0.48-3.12c0-0.16,0-0.32,0-0.48
			c0.542-0.179,0.094-1.346,0.24-1.92c0.547-0.333,0.089-1.671,0.24-2.399c-0.183-1.304,0.296-1.944,0.24-3.12
			c0.276-0.284,0.271-0.849,0.24-1.44c0.486-1.114,0.799-2.401,0.96-3.84c0-0.08,0-0.16,0-0.24c0.521-0.999,0.653-2.388,0.72-3.84
			c0.402-0.398,0.191-1.409,0.24-2.16c0.864-6.816,2.205-13.154,2.88-20.16c0-0.08,0-0.16,0-0.24c0.5-1.42,0.416-3.423,0.72-5.039
			c0.551-0.489,0.087-1.994,0.24-2.881c0-0.72,0-1.439,0-2.16c0.552-0.567,0.086-2.153,0.24-3.119
			c-0.006-1.447-0.075-2.955,0.24-4.08c0.859,0.11,2.597,0.59,3.36,0c0.08,0,0.16,0,0.24,0c0.712-0.088,1.184-0.416,1.44-0.96
			c-0.176-0.793-1.573-1.614-2.4-0.96c-0.24,0-0.48,0-0.72,0c-1.469,0.236-1.927,0.352-1.68-1.44c0.371-0.349,0.211-1.229,0.24-1.92
			c0.57-0.15-0.037-1.478,0.48-1.68c0.326-0.074,0.199-0.602,0.24-0.96c2.825,0.774,4.747,2.452,7.44,3.359
			c3.564,1.076,6.743,2.537,10.8,3.12c0.075,0.326,0.601,0.199,0.96,0.24c-0.498-1.582-2.175-1.986-3.601-2.64
			c-2.735-0.705-5.044-1.836-7.44-2.881c-1.626-1.014-3.386-1.894-5.28-2.64c-0.735-0.306-1.74-0.341-2.4-0.72
			c-0.008-1.208,0.119-2.281,0.72-2.88c0.563,0.076,0.901,0.379,1.68,0.239c0.691,0.029,1.571-0.131,1.92,0.24
			c0.333,0.547,1.67,0.09,2.4,0.24c0.08,0,0.16,0,0.24,0c-0.041,0.521,0.851,0.108,1.2,0.24c0.333,0.547,1.671,0.089,2.4,0.239
			c0.72,0,1.44,0,2.16,0c2.722,0.4,7.119,0.4,9.84,0c2.666,0.187,4.61-0.349,7.2-0.239c2.239-1.176-1.289-1.806-2.881-1.44
			c-0.159,0-0.319,0-0.479,0c-2.081-0.559-5.199-0.081-7.68-0.24c-0.32,0-0.641,0-0.96,0c-0.411-0.549-1.832-0.088-2.64-0.239
			c-4.302-0.019-8.125-0.515-12.24-0.721c-0.08,0-0.16,0-0.24,0c-2.796-0.175-0.428-3.521-0.24-5.04
			c0.711-1.528,1.27-3.21,1.68-5.04c0-0.239,0-0.479,0-0.72c0.26-0.7,0.538-1.383,0.48-2.399c0.372-0.589,0.358-1.563,0.48-2.4
			c0.557-1.203,0.082-3.438,0.24-5.04c0-0.48,0-0.96,0-1.44c0.559-2.4,0.081-5.839,0.24-8.64c0-2.72,0-5.44,0-8.16
			c0.559-2.481,0.081-5.999,0.24-8.88c0-1.36,0-2.72,0-4.08c0.549-0.411,0.088-1.832,0.24-2.64c0-0.641,0-1.28,0-1.92
			c0.549-0.411,0.088-1.832,0.24-2.641c-0.186-1.466,0.314-2.245,0.24-3.6c0-0.08,0-0.16,0-0.24c0.364-0.596,0.463-1.457,0.48-2.4
			c0-0.079,0-0.159,0-0.239c0.418-1.021,0.641-2.239,0.72-3.601c0-0.08,0-0.159,0-0.239c0.419-1.021,0.641-2.239,0.72-3.601
			c0-0.08,0-0.16,0-0.24c0.557-1.523,0.747-3.413,0.96-5.279c0-0.08,0-0.16,0-0.24c0.364-0.597,0.463-1.457,0.48-2.4
			c0-0.08,0-0.16,0-0.239c0.511-1.249,0.655-2.865,0.72-4.561c0.402-0.398,0.191-1.409,0.24-2.16c0.39-1.13,0.489-2.551,0.48-4.08
			c0.318-1.122,0.448-2.432,0.48-3.84c1.037-4.243,1.474-9.086,2.16-13.68c0.264-0.45,1.904,0.494,2.4-0.24
			c2.328-0.552,5.245-0.515,8.16-0.48c1.204-0.155,3.034,0.315,3.84-0.239c2.8,0,5.6,0,8.4,0c1.203,0.557,3.438,0.082,5.04,0.239
			c3.618-0.178,6.599,0.282,10.08,0.24c0.41,0.55,1.832,0.088,2.64,0.24c1.92,0,3.84,0,5.76,0c0.727,0.554,2.476,0.085,3.601,0.24
			c4,0,8,0,12,0c1.283-0.156,3.194,0.314,4.08-0.24c4.8,0,9.6,0,14.399,0c0.08,0,0.16,0,0.24,0c0.489,0.551,1.993,0.087,2.88,0.24
			c0.333,0.547,1.671,0.089,2.4,0.24c2.896,0.066,9.817-0.093,12.479-0.48c1.559-0.227,2.353-0.774,2.4,1.2c0,0.08,0,0.16,0,0.24
			c-0.018,2.017,0.167,3.832,0.72,5.279c0,0.08,0,0.16,0,0.24c0.506,2.934,1.052,5.828,1.92,8.4c-0.015,0.895,0.242,1.518,0.48,2.16
			c0.403,2.636,1.408,4.671,1.92,7.199c0.287,0.834,0.376,1.864,0.96,2.4c0.343,0.856,0.853,1.547,0.96,2.64
			c2.433,6.033,6.171,11.597,10.189,16.929c-0.023-0.032-0.045-0.065-0.068-0.098c0.025,0.035,0.05,0.071,0.075,0.106
			c-0.003-0.003-0.005-0.006-0.007-0.009c0.004,0.005,0.008,0.01,0.012,0.016c0.304,0.42,0.611,0.834,0.922,1.245
			c0.464,0.619,0.934,1.23,1.41,1.828c0.162,0.203,0.327,0.401,0.491,0.602c0.609,0.747,1.226,1.479,1.855,2.182
			c0.176,0.195,0.385,0.392,0.605,0.587c0.001,0.001,0.002,0.002,0.003,0.003c0.007,0.006,0.014,0.012,0.02,0.018
			c-0.006-0.006-0.013-0.011-0.019-0.017c0.903,1.096,1.611,2.133,1.551,2.769c-0.341,1.18-0.139,2.901-0.48,4.08
			c0,0.08,0,0.16,0,0.24c-0.726,0.588,0.183,1.562,0,2.4c0,0.079,0,0.159,0,0.239c0.541,0.5,0.773,1.308,1.44,1.681
			c-5.566,6.913-14.797,10.162-22.32,15.119c-0.08,0-0.16,0-0.24,0c-1.01,0.59-2.243,0.957-3.119,1.681
			c-0.193,0.127-0.39,0.25-0.721,0.24c-1.094,0.346-2.055,0.824-2.88,1.439c-4.831,2.129-9.691,4.229-14.88,6
			c-0.769,0.915-3.832,0.803-3.36,2.16c2.715-0.806,6.353-0.688,8.641-1.92c0.08,0,0.159,0,0.239,0
			c2.565-0.556,4.956-1.284,6.961-2.4c0.08,0,0.159,0,0.239,0c1.283-0.237,1.989-1.051,3.36-1.2c0.934-0.266,1.736-0.663,2.4-1.199
			c0.08,0,0.159,0,0.239,0c8.384-3.776,15.729-8.591,23.28-13.2c0.616,1.144,1.663,1.857,3.12,2.16c0.349,0.131,1.24-0.28,1.2,0.239
			c-0.22,1.221-0.841,2.04-0.96,3.36c0,0.08,0,0.16,0,0.24c-0.533,0.347-0.332,1.429-0.721,1.92
			c-5.601,5.919-12.183,10.857-17.76,16.8c3.416-1.056,6.996-3.838,10.462-6.6c1.726-1.241,3.401-2.533,5.03-3.871
			c0.001,0,0.002-0.001,0.003-0.002s0.001-0.001,0.002-0.002c0.276-0.194,0.551-0.385,0.823-0.565c0.873-0.579,0.09,0.48,0.72,0.96
			c0.067,1.533,0.254,2.946,0.72,4.08c-0.031,0.592-0.036,1.156,0.24,1.44c-0.081,1.201,0.562,1.679,0.48,2.88
			c0.505,1.975,1.016,3.944,1.92,5.52c0.187,0.214,0.258,0.542,0.24,0.96c4.026,10.773,8.044,21.556,14.159,30.24
			c-1.111,1.688-1.508,4.092-2.399,6c-0.08,0-0.16,0-0.24,0c-1.048,0.073-1.99,0.25-2.64,0.72c-7.145,1.656-15.715,1.886-23.28,3.12
			C398.778,634.822,398.192,635.276,398.4,636.525z M417.538,553.193c0.3,0.622,0.686,1.16,0.955,1.813
			C418.224,554.354,417.837,553.816,417.538,553.193z M444.692,804.227c-0.012,0.021-0.03,0.043-0.041,0.063v-0.001
			C444.662,804.27,444.681,804.246,444.692,804.227z M543.121,732.525c0,1.36,0,2.72,0,4.08c0,0.72,0,1.439,0,2.16
			c0,5.6,0,11.199,0,16.8c0,1.2,0,2.4,0,3.6c0,2.561,0,5.12,0,7.681c0,1.199,0,2.399,0,3.6c-0.08,0-0.16,0-0.24,0
			c0,1.04,0,2.08,0,3.12c0,0.479,0,0.96,0,1.44c0,0.88,0,1.76,0,2.64c-0.256,6.784-0.392,13.688-1.2,19.92
			c0.153,2.394-0.414,4.065-0.239,6.48c-0.08,0-0.16,0-0.24,0c0.031,0.591,0.036,1.156-0.24,1.439c-5.359,0-10.72,0-16.08,0
			c-26.394-0.317-54.193,0.636-80.16-0.479c-0.063-0.703,0.543-0.738,0.48-1.44c0-0.16,0-0.32,0-0.479
			c0.397-0.337,0.397-2.305,0-2.641c0-0.479,0-0.96,0-1.439c0.557-1.204,0.082-3.438,0.239-5.04c0-0.48,0-0.96,0-1.44
			c0.553-0.568,0.086-2.154,0.24-3.12c0-0.399,0-0.8,0-1.2c0.551-0.489,0.087-1.993,0.24-2.88c-0.123-3.163,0.547-5.532,0.479-8.64
			c0-0.08,0-0.16,0-0.24c0.55-0.411,0.089-1.832,0.24-2.64c0-0.08,0-0.16,0-0.24c0.563-3.678,0.817-7.663,1.2-11.521
			c0-0.079,0-0.159,0-0.239c0.427-1.493,0.647-3.192,0.72-5.04c0.37-1.631,0.656-3.344,0.721-5.28
			c0.506-1.974,0.758-4.202,0.96-6.479c0-0.08,0-0.16,0-0.24c0.552-1.848,0.758-4.042,0.96-6.24c0.371-0.349,0.211-1.229,0.239-1.92
			c0.727-3.354,1.236-6.925,1.681-10.561c0-0.079,0-0.16,0-0.239c0.363-0.597,0.463-1.457,0.479-2.4c0-0.08,0-0.16,0-0.24
			c0.826-1.835,0.496-6.035,2.88-5.52c0.729,0.398,3.112,0.398,3.841,0c0.08,0,0.159,0,0.239,0c2.252-0.148,4.289-0.512,6-1.2
			c0.08,0,0.16,0,0.24,0c1.03-0.01,1.768-0.313,2.4-0.72c0.491-0.389,1.572-0.188,1.92-0.721c0.879,0.08,1.333-0.266,1.92-0.479
			c0.734,0.174,0.706-0.414,1.439-0.24c1.5-0.341,2.98-0.699,4.08-1.439c1.56-0.682,3.45-1.03,4.801-1.92c0.08,0,0.159,0,0.239,0
			c2.08-0.721,4.227-1.374,6-2.4c0.08,0,0.16,0,0.24,0c2.813-1.027,5.689-1.991,8.16-3.36c0.08,0,0.16,0,0.24,0
			c0.578,0.099,0.521-0.438,1.2-0.239c1.046-0.313,2.122-0.598,2.88-1.2c0.213-0.187,0.542-0.259,0.96-0.24
			c0.833-0.287,1.864-0.376,2.399-0.96c0.769-0.191,1.211-0.709,2.16-0.72c1.131-0.31,2.005-0.876,2.88-1.44
			c-0.005-0.715,0.022-1.462-0.72-1.44c-0.341-0.021-0.68-0.039-0.72,0.24c-0.08,0-0.16,0-0.24,0c-3.421,0.739-6.26,2.061-9.12,3.36
			c-0.08,0-0.16,0-0.24,0c-1.252,0.428-2.611,0.749-3.6,1.439c-0.94,0.02-1.497,0.424-2.16,0.721
			c-4.043,1.396-8.048,2.832-11.76,4.56c-0.08,0-0.16,0-0.24,0c-1.676,0.324-2.778,1.222-4.56,1.44
			c-0.834,0.286-1.864,0.375-2.4,0.96c-1.666,0.733-3.714,1.086-5.28,1.92c-0.08,0-0.16,0-0.24,0c-0.239,0-0.479,0-0.72,0
			c-2.146-0.084-5.504,1.483-8.438,1.969c-0.003,0-0.005,0.001-0.008,0.001c0,0,0,0,0.001,0c-0.002,0-0.004,0.001-0.006,0.001
			c-0.007,0.001-0.014,0.003-0.021,0.004c-0.002,0-0.003,0-0.004,0c-0.446,0.063-0.896,0.106-1.347,0.132c0.004,0,0.008,0,0.012,0
			c-0.006,0-0.012,0.001-0.018,0.001c-0.21,0.012-0.421,0.018-0.632,0.021c0.21-0.002,0.418-0.009,0.626-0.021
			c-0.998,0.021-1.908-0.154-2.646-0.668c-0.038-0.025,0.029-0.203,0-0.239c0-0.721,0-1.44,0-2.16
			c0.557-1.283,0.082-3.598,0.239-5.28c0.155-1.045-0.313-2.713,0.24-3.36c0.24,0,0.48,0,0.72,0c3.201,0.4,8.079,0.4,11.28,0
			c0.48,0,0.96,0,1.44,0c0.887-0.152,2.391,0.312,2.88-0.239c0.479,0,0.96,0,1.439,0c0.729-0.15,2.067,0.307,2.4-0.24
			c1.24-0.28,3.001-0.039,4.08-0.48c0.08,0,0.16,0,0.24,0c1.848,0.009,3.591-0.088,5.04-0.479c0.814-0.065,1.945,0.186,2.399-0.24
			c2.374-0.106,4.602-0.358,6.48-0.96c0.08,0,0.16,0,0.24,0c1.684-0.155,3.274-0.405,4.56-0.96c1.835-0.165,3.326-0.674,4.8-1.2
			c1.912-0.328,3.368-1.111,5.28-1.439c0.833-0.287,1.864-0.376,2.4-0.96c0.08,0,0.159,0,0.239,0c1.668-0.253,2.36-1.48,3.601-2.16
			c0.291,0.073,0.827-0.693,0.239-0.721c-0.59,1.008-2.225-0.1-2.88,0.48c-0.08,0-0.159,0-0.239,0
			c-2.506,0.454-4.614,1.306-6.721,2.16c-0.08,0-0.159,0-0.239,0c-1.37,0.311-2.725,0.636-3.841,1.2c-0.08,0-0.159,0-0.239,0
			c-0.947,0.173-1.942,0.297-2.641,0.72c-0.7,0.26-1.383,0.537-2.399,0.479c-0.943,0.017-1.805,0.116-2.4,0.48
			c-0.638,0.003-1.371-0.091-1.68,0.24c-1.153,0.046-2.285,0.114-3.12,0.479c-0.08,0-0.16,0-0.24,0
			c-1.486,0.034-2.93,0.11-4.08,0.48c-0.08,0-0.16,0-0.24,0c-4.008,0.231-8,0.479-11.76,0.96c-0.08,0-0.16,0-0.24,0
			c-1.439,0-2.88,0-4.319,0c-4,0-8,0-12,0c-0.08,0-0.16,0-0.24,0c-1.658-0.582-1.974-2.506-2.88-3.84
			c0.762-1.161,1.407-3.709,0.72-5.521c0-0.16,0-0.32,0-0.479c0-0.721,0-1.44,0-2.16c0.725-0.476,2.146-0.255,2.64-0.96
			c1.19-0.41,2.164-1.037,3.601-1.2c2.35-0.771,4.811-1.43,6.96-2.4c0.954-0.565,2.467-0.572,3.36-1.199c0.08,0,0.159,0,0.239,0
			c1.766-0.396,3.568-0.752,5.04-1.44c0.08,0,0.16,0,0.24,0c1.472-0.289,2.923-0.598,4.08-1.2c0.08,0,0.16,0,0.24,0
			c2.038-0.441,4.034-0.925,5.76-1.68c0.08,0,0.16,0,0.24,0c2.705-1.215,7.179-0.661,9.84-1.92c-0.125-2.349-4.556-1.865-6.24-1.2
			c-1.114,0.646-3.205,0.314-4.32,0.96c-0.885,0.154-1.833,0.246-2.399,0.72c-0.08,0-0.16,0-0.24,0
			c-2.991,0.689-5.964,1.396-8.64,2.4c-0.08,0-0.16,0-0.24,0c-1.793,0.367-3.585,0.735-5.04,1.439c-0.08,0-0.16,0-0.24,0
			c-1.019-0.059-1.292,0.628-2.399,0.48c-1.154,0.526-3.465-0.104-4.561,0.48c-0.24,0-0.479,0-0.72,0
			c-0.651-0.149-1.905,0.305-2.16-0.24c0.614-0.025,1.145-0.136,1.44-0.48c0.08,0,0.159,0,0.239,0c2.1-0.713,5.609-1.165,6.96-2.16
			c-0.104,0.077-0.362-0.059-0.479,0c0.349-0.371,0.937-0.504,1.68-0.479c2.628-0.652,5.367-1.192,7.681-2.16
			c1.959-0.28,3.59-0.89,5.279-1.44c4.532-1.228,9.354-2.167,14.16-3.119c0.08,0,0.16,0,0.24,0c1.32-0.04,2.607-0.113,3.6-0.48
			c0.08,0,0.16,0,0.24,0c4.803-0.703,9.722-1.292,14.844-1.677c-0.002,0.001-0.003,0.001-0.004,0.001
			c0.014-0.002,0.026-0.003,0.04-0.004c0.004-0.003,0.004-0.004,0.008-0.007c0.35-0.076,0.677-0.153,0.952-0.233
			c-0.93-0.356-1.74-2.092-3.12-1.2c-0.567,0.473-2.146-0.066-2.64,0.48c-0.08,0-0.16,0-0.24,0c-1.43,0.01-2.775,0.104-3.84,0.479
			c-0.08,0-0.16,0-0.24,0c-2.268,0.132-4.542,0.258-6.479,0.721c-0.751,0.049-1.762-0.162-2.16,0.239
			c-2.952,0.248-5.764,0.637-8.16,1.44c-0.7,0.259-1.383,0.537-2.399,0.479c-1.048,0.073-1.991,0.25-2.641,0.721
			c-0.08,0-0.16,0-0.24,0c-2.038,0.441-4.034,0.925-5.76,1.68c-0.08,0-0.16,0-0.24,0c-1.765,0.395-3.567,0.752-5.04,1.44
			c-0.08,0-0.159,0-0.239,0c-1.793,0.366-3.585,0.735-5.04,1.439c-0.08,0-0.16,0-0.24,0c-0.24,0-0.48,0-0.72,0
			c-4.052,0.988-7.946,2.135-11.761,3.36c-0.08,0-0.159,0-0.239,0c-1.26,0.101-2.293,0.428-3.36,0.72
			c-1.17-2.19-2.699-4.021-3.6-6.48c0-0.079,0-0.159,0-0.239c0.024-0.465,0.031-0.9,0.028-1.319
			c-0.001-0.183-0.01-0.354-0.016-0.529c-0.005-0.148-0.005-0.305-0.013-0.447c0,0.061,0,0.121,0,0.182
			c-0.006-0.124-0.01-0.252-0.019-0.373c0.005,0.066,0.015,0.124,0.019,0.191c0-1.155,0-2.31,0-3.465
			c-3.443-5.597-6.213-11.867-8.881-18.239c-0.467-1.213-1.044-2.315-1.199-3.841c-0.287-0.833-0.376-1.863-0.96-2.399
			c-0.333-0.147-0.036-0.924-0.48-0.96c0-0.08,0-0.16,0-0.24c-0.289-1.472-0.598-2.923-1.2-4.08c0-0.08,0-0.16,0-0.24
			c-0.154-0.885-0.246-1.833-0.72-2.399c0-0.08,0-0.16,0-0.24c-0.655-2.864-1.264-5.776-2.16-8.4
			c0.076-0.556-0.038-0.921-0.24-1.199c-0.678-4.683-2.449-8.271-2.88-13.2c-0.15-0.729,0.308-2.067-0.24-2.4c0-0.16,0-0.32,0-0.479
			c-0.156-1.363,0.316-3.356-0.239-4.32c0-0.16,0-0.32,0-0.48c-0.145-1.535-0.657-2.703-1.2-3.84
			c-0.466-0.814-0.343-2.218-0.96-2.88c0.076-0.557-0.039-0.921-0.24-1.2c-0.254-1.266-0.461-2.579-0.96-3.6c0-0.08,0-0.16,0-0.24
			c-1.613-7.187-2.332-15.268-3.12-23.28c0-0.08,0-0.159,0-0.239c-0.688-7.071-2.031-13.489-2.16-21.12
			c-0.15-0.729,0.308-2.067-0.239-2.4c0-0.56,0-1.12,0-1.68c0-1.84,0-3.681,0-5.521c0-1.52,0-3.04,0-4.56
			c0.553-0.647,0.085-2.314,0.239-3.36c-0.108-4.349,0.829-7.651,0.721-12c1.108-0.822-0.821-2.752-0.96-0.96
			c0.157,1.678-0.878,2.162-0.721,3.84c0,0.08,0,0.16,0,0.24c-0.411,0.708-0.509,1.731-0.479,2.88c0,0.08,0,0.16,0,0.24
			c-0.548,0.333-0.09,1.671-0.24,2.4c0,0.079,0,0.159,0,0.239c-0.281,0.039-0.202,0.438-0.24,0.721
			c-0.549,0.41-0.088,1.832-0.24,2.64c0,0.399,0.001,0.8,0,1.2c-0.559,2.241-0.08,5.519-0.239,8.16c0,0.96,0,1.92,0,2.88
			c0.155,1.204-0.314,3.034,0.239,3.84c0,0.32,0,0.64,0,0.96c0.151,0.729-0.307,2.067,0.24,2.4c0,0.239,0,0.479,0,0.72
			c-0.131,0.349,0.28,1.24-0.24,1.2c-0.278-1.002-0.769-1.791-1.199-2.641c-0.412-0.549-0.229-1.691-0.721-2.16
			c0-0.08,0-0.159,0-0.239c-0.333-2.627-1.164-4.756-1.439-7.44c-0.064-1.191,0.685-2.825,0-3.84c0-2.48,0-4.96,0-7.44
			c-0.159-2.801,0.318-6.238-0.24-8.64c0-3.28,0-6.561,0-9.84c0.553-0.647,0.085-2.315,0.24-3.36c0-0.72,0-1.439,0-2.16
			c0-0.08,0-0.159,0-0.239c0.825-3.255,0.529-7.631,1.439-10.801c0.388-0.067,0.414,0.228,0.721,0.24
			c0.489,0.551,1.993,0.087,2.88,0.24c9.701,0.791,18.713-0.457,27.12-1.68c0.08,0,0.16,0,0.24,0c1.65-0.03,3.251-0.108,4.56-0.48
			c0.08,0,0.16,0,0.24,0c4.707-0.252,9.259-0.661,14.399-0.48c2.961-0.159,6.56,0.319,9.12-0.239c4.181,0.101,8.096-0.064,10.8-1.44
			c0.08,0,0.16,0,0.24,0c0.981-0.218,2.163-0.237,2.88-0.72c0.592,0.031,1.156,0.036,1.44-0.24c1.36-0.079,2.579-0.301,3.6-0.72
			c0.08,0,0.16,0,0.24,0c1.021,0.142,1.588-0.173,2.4-0.24c4.149-0.67,7.953,0.117,10.56,1.68c3.581,22.02,6.53,44.67,9.601,67.2
			c0.697,1.676,1.523,2.263,0.96,4.801c0.514,6.846,2.375,12.345,3.12,18.96c0.551,0.489,0.086,1.993,0.239,2.88
			c0,0.8,0,1.6,0,2.399c0,1.841,0,3.681,0,5.521c0,0.399,0,0.8,0,1.2c-0.08,0-0.16,0-0.239,0c0,0.72,0,1.439,0,2.16
			c-0.175,0.733,0.414,0.705,0.239,1.439c0,0.48,0,0.96,0,1.44c0.148,0.651-0.305,1.904,0.24,2.159
			c0.054,0.187,0.007,0.474,0.24,0.48c-0.192,0.832,0.357,0.923,0.24,1.68c3.368,29.697,9.505,56.33,14.399,84
			c4.557,7.203,4.446,19.074,5.521,29.761c0.373,2.026,0.446,4.354,0.479,6.72c0,0.88,0,1.76,0,2.64c0,0.32,0,0.641,0,0.96
			c0,0.721,0,1.44,0,2.16c0.08,0,0.16,0,0.24,0C543.121,730.926,543.121,731.726,543.121,732.525z M449.47,678.82
			c0.355,0.622,0.749,1.206,1.146,1.786C450.219,680.026,449.825,679.443,449.47,678.82z M453.601,685.965
			c0.647-0.553,2.315-0.085,3.36-0.239c-0.602,0.678-1.799,0.761-2.4,1.439C453.913,687.094,454.131,686.155,453.601,685.965z
			 M460.703,715.459c1.322-0.209,2.626-0.506,3.883-0.735C463.329,714.954,462.026,715.25,460.703,715.459z"/>
			</g>
		</g>
		<g id="lighting">
			<g>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M346.402,196.449c-0.223,0.571-0.483,1.109-0.802,1.595
			c-1.051,1.602-2.915,1.676-3.12,3.36c1.021,1.231,2.548-0.677,3.36-1.2c1.614-1.985,3.311-3.889,3.6-7.2c0-0.64,0-1.28,0-1.92
			c0.132-0.349-0.28-1.24,0.24-1.2c0.383-1.502-0.981-1.259-0.72-2.64c0-0.08,0-0.16,0-0.24c-0.596-2.445-1.636-4.445-3.12-6
			c1.119,3.834,2.086,9.691,0.992,14.077c-0.122,0.465-0.264,0.913-0.421,1.347c-0.001,0.002-0.002,0.004-0.003,0.006
			c-0.001,0.002-0.002,0.004-0.003,0.007c0,0,0,0,0,0C346.405,196.443,346.403,196.446,346.402,196.449z M345.453,198.457
			c0.336-0.559,0.625-1.175,0.875-1.829C346.078,197.282,345.791,197.898,345.453,198.457z M347.5,188.815
			c0.055,2.168-0.156,4.333-0.666,6.264C347.343,193.149,347.552,190.98,347.5,188.815z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M379.921,207.164c-0.629-2.091-1.276-4.164-2.16-6
			c-0.799-2.161-1.399-4.52-2.64-6.24c-1.217,2.144-1.469,5.251-2.641,7.44c-0.494,1.585-1.438,2.722-1.92,4.32
			c-0.044,0.204,0.094,0.227,0.24,0.24c1.228,8.052,5.43,13.13,6,21.84c0.15,0.729-0.308,2.067,0.24,2.4
			c-0.16,1.36,0.172,2.228,0.96,2.64c1.174-0.519,1.973,0.727,3.359,0.72c0.839,0.042,1.208,0.552,1.44,1.2
			c0.555-0.885,0.084-2.796,0.24-4.08c0-1.12,0-2.24,0-3.36c0-0.08,0-0.16,0-0.24c-0.08,0-0.16,0-0.24,0c0-1.04,0-2.08,0-3.12
			c0-0.56,0-1.12,0-1.68c0-0.16,0-0.32,0-0.48c-0.668,0.108,0.188-1.308-0.48-1.2c0-1.2,0-2.4,0-3.6
			C382.325,213.56,380.429,211.056,379.921,207.164z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M350.4,272.924c0.689,2.779,1.884,5.62,2.16,7.68
			c0.129,0.96-0.206,1.817,0,2.64c0.798,3.179,3.738,5.586,5.521,8.16c1.718,2.482,3.996,6.204,3.6,10.08
			c-0.249,2.436-2.643,3.851-2.64,5.76c-0.045,0.204,0.094,0.226,0.239,0.24c1.546-1.174,3.383-2.058,4.32-3.84
			c0-0.24,0-0.48,0-0.72c0-1.44,0-2.88,0-4.32c-0.335-2.226-1.359-3.761-2.16-5.52c-0.426-1.254-0.985-2.375-1.68-3.36
			c-1.384-3.816-3.122-7.278-4.32-11.28c-0.285-0.915-0.638-1.762-0.96-2.64c-0.401-0.958-0.741-1.979-1.2-2.88
			c-0.438-1.561-0.986-3.013-1.439-4.56c-0.18-0.78-0.435-1.485-0.72-2.16c-0.266-1.015-0.592-1.969-0.961-2.88
			c-0.339-1.58-1.228-2.611-1.68-4.08c-0.426-1.253-0.985-2.375-1.68-3.36c-0.425-0.921-1.425-2.324-0.72-3.6
			c-1.481-0.358-1.005-3.251-0.48-4.32c-1.975-0.377,1.068-8.755,0.96-10.08c-1.838-3.777-4.439,6.034-4.56,8.4
			c-0.089,1.742,0.227,4.039,0.96,5.52c0.254,0.514,0.898,0.858,1.199,1.44c0.802,1.546,1.136,3.925,1.921,6.24
			C347.47,263.584,349.103,267.695,350.4,272.924z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M383.041,243.884c0-1.52,0-3.04,0-4.56
			c0.039-0.279-0.053-0.427-0.24-0.48c0,0.08,0,0.16,0,0.24c-0.576,0.223-0.636,0.964-1.68,0.72c-0.647,0.553-2.315,0.085-3.36,0.24
			c-0.08,0-0.16,0-0.24,0c0,0.08,0,0.16,0,0.24c-0.16,0-0.319,0-0.479,0c0.061,3.537-2.041,6.071-1.681,9.12
			c2.462,0.347,8.103,1.51,5.521,3.84c-0.041,0.28-0.38,0.261-0.72,0.24c-0.284,0.456,0.502,1.888,1.68,1.68
			c1.064-2.616,1.453-5.907,1.439-9.6C382.744,245.461,383.184,244.381,383.041,243.884z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M375.841,335.564c-1.299,0.338-1.109-0.811-1.68-1.2
			c-0.33,2.07-0.44,4.36-0.96,6.24c0.174,0.734-0.414,0.706-0.24,1.44c-2.292,5.228-2.5,12.541-5.521,17.04
			c-0.349,1.091-0.844,2.036-1.2,3.12c-0.378,0.582-0.858,1.062-1.439,1.44c0.175-0.655-0.477-0.483-0.48-0.96
			c-1.006-1.794-2.763-2.836-3.359-5.04c-0.582,0.219-0.636,0.964-0.721,1.68c0.85,3.361-1.547,13.549,0.48,13.68
			c0.08,0.005,0.16-0.004,0.24,0c4.287-4.672,7.167-10.752,11.52-15.36c0.057-1.176-0.423-1.817-0.24-3.12
			c0.537-0.103,0.098-1.183,0.24-1.68c0.52-0.841,0.719-2.002,0.96-3.12c1.426-3.615,2.784-7.296,3.36-11.76c0-0.88,0-1.76,0-2.64
			C376.481,335.289,376.076,336.3,375.841,335.564z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M367.201,397.005c-1.056-0.545-0.632-2.569-0.721-4.08
			c-0.2-2.68-0.433-5.328-0.72-7.92c-0.156-1.284,0.315-3.195-0.24-4.08c-0.335-2.226-0.069-5.05-0.72-6.96c0-0.4,0-0.8,0-1.2
			c-0.834,0.051-1.955,1.882-2.64,2.88c-1.191,2.169-1.228,5.493-1.921,8.16c-1.441,2.018-3.141-0.692-3.84-1.68
			c-0.581,0.459-1.017,1.063-1.2,1.92c0,0.08,0,0.16,0,0.24c0.243,1.362-0.444,1.795-0.96,2.4c-0.33-0.01-0.526,0.113-0.72,0.24
			c-0.217,0.423-0.537,0.743-0.96,0.96c-2.368,1.071-4.224,2.656-7.439,2.88c-0.104,4.943,0.251,12.406-0.24,17.76
			c-0.4,2.482-0.4,6.639,0,9.12c0.107,1.948-0.104,3.577-0.24,5.28c-0.399,1.604-0.399,4.877,0,6.479
			c0.566,1.645-1.089,3.132,0.48,4.08c-0.045,0.204,0.093,0.227,0.239,0.24c0.81-0.631,1.477-1.404,1.92-2.4
			c0.943-1.137,1.793-2.367,2.641-3.6c1.176-1.224,2.137-2.663,2.88-4.32c2.071-3.128,3.933-6.467,6-9.6
			c0.368-0.912,0.967-1.593,1.2-2.64c2.842-3.877,4.342-9.097,7.439-12.72C367.446,397.879,367.027,397.738,367.201,397.005z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M503.041,486.765c1.674-0.154,2.374,0.666,3.84,0.72
			c-0.8-4.56-2.198-8.521-3.36-12.72c-0.237-1.122-0.722-1.998-0.96-3.12c-0.589-1.971-1.229-3.89-1.92-5.76
			c-0.266-1.014-0.764-1.796-0.96-2.88c-1.046-3.594-2.396-6.884-3.84-10.08c-0.124-1.155-0.677-1.883-1.2-2.64
			c-0.397-0.803-0.802-1.599-1.199-2.4c-0.719-2.001-1.788-3.652-2.641-5.52c-1.847-2.873-3.389-6.051-5.28-8.881
			c0.031-0.43-0.293-0.506-0.239-0.96c-0.302-0.098-0.491-0.31-0.48-0.72c-0.346-0.135-0.586-0.374-0.72-0.72
			c-0.557,0.076-0.922-0.039-1.2-0.24c-0.55-1.05-0.664-2.535-1.68-3.12c-0.279-0.039-0.428,0.053-0.48,0.24
			c-1.268-0.732-2.361-1.639-3.359-2.641c-0.652-0.147-1.124-0.476-1.44-0.96c-0.801,0.001-0.819-0.78-1.68-0.72
			c-0.315-0.085-0.378-0.422-0.721-0.479c-0.192-0.127-0.39-0.251-0.72-0.24c1.161,5.079,4.562,7.918,5.76,12.96
			c0.615,0.825,1.095,1.786,1.44,2.88c5.004,12.996,9.99,26.01,12.24,41.76c0.156,1.284-0.315,3.195,0.239,4.08
			c0.139,0.422-0.29,1.41,0.24,1.44c0,0.479,0,0.96,0,1.439c0.08,0,0.16,0,0.24,0c2.051-0.349,3.986-0.813,6.48-0.72
			C500.091,486.366,502.392,486.366,503.041,486.765z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M542.881,730.125c0-0.72,0-1.439,0-2.16c0-0.319,0-0.64,0-0.96
			c0-0.88,0-1.76,0-2.64c-0.033-2.366-0.106-4.693-0.479-6.72c-1.074-10.687-0.964-22.558-5.521-29.761
			c-4.895-27.67-11.031-54.303-14.399-84c0.117-0.757-0.433-0.848-0.24-1.68c-0.233-0.007-0.187-0.294-0.24-0.48
			c-0.545-0.255-0.092-1.508-0.24-2.159c0-0.48,0-0.96,0-1.44c0.175-0.734-0.414-0.706-0.239-1.439c0-0.721,0-1.44,0-2.16
			c0.079,0,0.159,0,0.239,0c0-0.4,0-0.801,0-1.2c0-1.84,0-3.68,0-5.521c0-0.8,0-1.6,0-2.399c-0.153-0.887,0.312-2.391-0.239-2.88
			c-0.745-6.615-2.606-12.114-3.12-18.96c0.563-2.538-0.263-3.125-0.96-4.801c-3.07-22.53-6.02-45.181-9.601-67.2
			c-2.606-1.563-6.41-2.35-10.56-1.68c-0.121,1.241,0.68,1.561,0.479,2.88c0.15,0.729-0.307,2.067,0.24,2.4
			c-0.021,0.341-0.04,0.68,0.24,0.72c-0.152,2.632,0.563,4.397,0.479,6.96c0.152,0.809-0.309,2.229,0.24,2.64c0,0.24,0,0.48,0,0.721
			c0.153,0.887-0.311,2.391,0.24,2.88c-0.021,0.341-0.04,0.68,0.24,0.72c0.417,6.703,1.248,12.992,2.159,19.2
			c-0.012,1.212,0.094,2.307,0.48,3.12c0.028,0.691-0.131,1.571,0.24,1.92c-0.015,1.054,0.096,1.983,0.479,2.64
			c-0.057,1.018,0.221,1.7,0.48,2.4c0.017,0.943,0.116,1.804,0.479,2.4c-0.149,1.829,0.706,2.654,1.2,3.84
			c0.667,1.653,1.178,3.462,1.92,5.04c0.073,1.206,0.664,1.896,0.96,2.88c0.635,1.765,1.024,3.775,1.68,5.52
			c0.032,1.729,0.577,2.944,2.16,3.12c1.284,0.316,1.398,1.803,2.16,2.641c-0.076,0.557,0.039,0.921,0.24,1.199
			c-0.063,0.703,0.543,0.738,0.479,1.44c0.622,0.979,1.108,2.092,1.92,2.88c0.384,0.657,0.896,1.185,1.2,1.92
			c0.641,0.88,1.02,2.021,1.44,3.12c0,0.16,0,0.32,0,0.48c-0.494,0.626-2.409-0.17-2.88,0.479c-0.08,0-0.16,0-0.24,0
			c-1.609,0.07-2.586,0.773-3.6,1.44c-2.688,0.271-4.259,1.661-6.24,2.64c-1.383,0.857-3.142,1.338-4.561,2.16
			c-1.556,0.284-2.593,1.086-3.84,1.68c-0.08,0-0.16,0-0.24,0c-0.807,1.746,1.331,0.336,2.16,0.24c2.88,0,5.761,0,8.641,0
			c0.73,0.389,1.501,0.739,1.68,1.68c0.627,0.574,0.265,2.136,0.72,2.88c0,0.32,0,0.641,0,0.96c0.156,1.204-0.314,3.035,0.24,3.841
			c0,0.239,0,0.479,0,0.72c0.156,1.283-0.315,3.195,0.24,4.08c-0.021,0.341-0.04,0.68,0.24,0.72c0,0.24,0,0.48,0,0.72
			c-0.025,0.985-0.04,1.96,0.479,2.4c-0.076,0.557,0.039,0.922,0.24,1.2c0.122,0.838,0.107,1.812,0.479,2.399
			c0.065,1.455,0.153,2.888,0.48,4.08c0,1.92,0,3.84,0,5.761c-0.549,0.41-0.088,1.832-0.24,2.64c0,0.32,0,0.64,0,0.96
			c-0.555,0.885-0.084,2.796-0.24,4.08c0,0.24,0,0.479,0,0.72c-0.399,5.44-0.399,12.56,0,18c1.092,2.909,5.601,2.4,6.721,5.28
			c0,0.08,0,0.16,0,0.24c0.746,4.615-4.567,5.171-6.48,7.92c-0.08,0-0.16,0-0.24,0c-0.827,0.772-2.145,1.056-2.88,1.92
			c-0.08,0-0.16,0-0.24,0c-1.334,0.905-3.012,1.468-4.319,2.399c-0.936-0.055-1.244,0.516-1.92,0.721
			c-1.126,0.395-2.306,0.734-3.12,1.439c-0.08,0-0.16,0-0.24,0c-1.083,0.438-2.285,0.755-3.12,1.44c-0.08,0-0.16,0-0.24,0
			c-1.184,0.416-2.38,0.82-2.88,1.92c0,0.08,0,0.16,0,0.24c-0.08,0-0.16,0-0.239,0c0.258,0.972,0.258,1.668,0,2.64
			c2.007,0.53,5.154,0.223,6.96-0.24c2.863-0.176,5.653-0.426,8.159-0.96c3.99,0.39,5.611-1.588,7.92-2.88
			c1.201,0.003,2.799-1.619,3.601-0.479c-0.582,0.138,0.112,1.553-0.72,1.439c0.04-0.521-0.852-0.108-1.2-0.239
			c-0.003,0.477-0.655,0.305-0.48,0.959c-0.201,0.279-0.316,0.644-0.239,1.2c0.156,1.123,1.273,1.286,1.92,1.92
			c-0.067,0.548,0.058,0.902,0.479,0.96c0.4,0,0.8,0,1.2,0c0.537,2.423,4.859,1.061,6.48,2.4c0,0.24,0,0.48,0,0.72
			c0,0.08,0,0.16,0,0.24c-0.706,3.534-8.821-0.341-9.12,3.601c0,0.079,0,0.159,0,0.239c-0.804,2.797-1.526,5.674-2.4,8.4
			c-0.214,0.586-0.56,1.041-0.479,1.92c-0.39,0.41-0.727,0.873-0.721,1.68c0.717,0.004,0.816,0.624,1.44,0.721
			c0.08,0,0.16,0,0.239,0c4.145,0.863,7.848-0.793,9.601-3.12c0.501,0.102,0.636-0.165,0.96-0.24c1.369,0.071,0.842,2.038,1.68,2.64
			c-0.005,1.365,0.407,2.313,0.721,3.36c0.027,1.418,0.905,2.701,0.239,4.08c-0.187,0.213-0.258,0.542-0.239,0.96
			c-0.233,0.007-0.187,0.294-0.24,0.479c-0.561,12.801-0.08,26.641-0.24,39.841c-0.516,1.563-1.34,2.82-1.439,4.8
			c-0.233,0.007-0.187,0.294-0.24,0.479c-0.556,0.965-0.084,2.957-0.24,4.32c0.147,1.107-0.539,1.381-0.48,2.4
			c0,0.08,0,0.16,0,0.239c-0.232,0.007-0.186,0.294-0.239,0.48c-0.556,0.965-0.084,2.957-0.24,4.32c0,0.479,0,0.96,0,1.439
			c-0.281,0.039-0.202,0.438-0.24,0.721c-0.56,4.56-0.08,10.159-0.24,15.119c-0.044,1.316-0.984,1.736-0.96,3.12
			c0,0.08,0,0.16,0,0.24c-0.233,0.007-0.186,0.294-0.239,0.48c-0.4,5.68-0.4,13.039,0,18.72c-0.039,0.279,0.053,0.427,0.239,0.479
			c0,0.48,0,0.96,0,1.44c0.159,2.801-0.318,6.239,0.24,8.64c0,0.08,0,0.16,0,0.24c4.205,0,8.411,0,12.615,0c0.001,0,0.001,0,0.002,0
			c0,0,0,0,0.001,0c0.22,0.012,0.435,0.021,0.644,0.029c0.045,0.002,0.087,0.003,0.132,0.004c0.159,0.005,0.314,0.009,0.465,0.011
			c0.056,0,0.109,0,0.164,0c0.14,0,0.273-0.002,0.402-0.006c0.046-0.001,0.094-0.002,0.138-0.004
			c0.166-0.008,0.323-0.018,0.465-0.034h0.001c0.351,0,0.701,0,1.052,0c0.276-0.283,0.271-0.849,0.24-1.439c0.08,0,0.16,0,0.24,0
			c-0.175-2.415,0.393-4.087,0.239-6.48c0.809-6.231,0.944-13.136,1.2-19.92c0-0.88,0-1.76,0-2.64c0-0.48,0-0.961,0-1.44
			c0-1.04,0-2.08,0-3.12c0.08,0,0.16,0,0.24,0c0-1.2,0-2.4,0-3.6c0-2.561,0-5.12,0-7.681c0-1.199,0-2.399,0-3.6
			c0-5.601,0-11.2,0-16.8c0-0.721,0-1.44,0-2.16c0-1.36,0-2.72,0-4.08c0-0.8,0-1.6,0-2.4
			C543.041,730.125,542.961,730.125,542.881,730.125z M517.201,663.165c-0.08,0-0.16,0-0.24,0c-2.77,1.312-7.249,2.189-11.04,1.681
			c1.179-0.742,2.689-1.151,3.84-1.921c1.492-0.268,2.488-1.032,3.84-1.439c0.834-0.287,1.864-0.376,2.4-0.96
			c1.569-0.351,3.094-0.746,4.56-1.2C522.823,660.098,518.263,662.552,517.201,663.165z M533.041,805.245
			c0.898,0,2.021,0.081,3.131,0.156C535.062,805.326,533.939,805.246,533.041,805.245z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M354.721,376.845c1.008-3.012-1.04-6.737-2.874-8.631
			c-0.002-0.003-0.004-0.006-0.006-0.009c-0.001,0-0.002,0-0.003,0c-0.08-0.082-0.159-0.165-0.237-0.24
			c-1.358-0.162-1.694-1.346-2.16-2.4c-0.08-0.56-0.246-1.034-0.72-1.2c-0.233-0.007-0.187-0.294-0.24-0.48
			c-0.261-0.06-0.162-0.478-0.479-0.48c-0.267-1.014-0.765-1.796-0.96-2.88c-0.439-0.521,0.045-1.965-0.48-2.4c0-0.64,0-1.28,0-1.92
			c-0.938,0.342-1.577,0.982-1.92,1.92c-0.467,6.083,3.216,10.053,3.36,16.32c1.398-0.422,0.899-0.769,1.92,0.24
			c0.559-1.129,0.126-3.1,0-4.32C352.231,371.814,352.425,375.38,354.721,376.845z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M345.36,134.444c1.424-0.097,2.348-0.693,3.12-1.44
			c0.315-0.084,0.378-0.422,0.72-0.48c2.129-0.992,3.912-2.328,4.32-5.04c0.398-0.57,0.398-2.789,0-3.36c0-0.08,0-0.16,0-0.24
			c-1.497,4.786-5.488,7.874-8.64,10.56C344.974,134.365,345.252,134.505,345.36,134.444z"/>
				<polygon fillRule="evenodd" clipRule="evenodd" fill="#E8E735" points="372.669,178.877 372.669,178.875 372.668,178.87 		" />
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M384.001,159.164c-1.517-1.204-2.946-2.494-5.04-3.12
			c-7.365-2.315-16.895-2.465-21.36-7.68c-0.08,0-0.16,0-0.24,0s-0.159,0-0.239,0c-1.54-1.901-3.501-3.379-5.04-5.28
			c-1.836-1.604-3.771-3.109-6.48-3.84c-0.783-0.336-1.629-0.611-2.16-1.2c-0.438-0.841-1.556-1.004-1.92-1.92
			c-0.849-0.352-1.985-1.424-2.88-0.96c-0.08,0-0.16,0-0.24,0c-2.978,0.338-4.385-0.895-6.479-1.44
			c-0.723-0.558-1.709-0.851-2.641-1.2c-2.979-1.5-6.309-2.651-9.359-4.08c-1.229-0.531-2.237-1.284-3.601-1.68
			c-0.933-0.587-1.846-1.194-2.88-1.68c-0.854-0.825-1.976-1.384-3.36-1.68c-0.194-0.046-0.238-0.241-0.479-0.24
			c-0.805-0.476-1.771-0.789-2.88-0.96c-1.478-0.603-3.192-0.968-5.04-1.2c-0.08,0-0.16,0-0.24,0c0.714,1.229,4.168,3.11,6.72,4.56
			c4.588,2.606,9.362,5.67,14.16,7.92c2.931,1.375,6.261,3.812,8.641,4.32c4.286,0.916,6.811,2.115,10.319,3.84
			c2.529,1.243,5.936,2.748,6.233,5.72c-0.071-0.01-0.142-0.023-0.212-0.035c-3.705-0.608-6.76-3.032-10.25-4.655
			c3.491,1.621,6.545,4.047,10.25,4.655c-4.899-1.482-8.202-4.563-13.221-5.925c0.917,2.371,3.255,3.019,5.163,3.813
			c1.421,0.819,2.865,1.558,4.288,2.358c0.452,0.255,0.902,0.515,1.349,0.788c0.325,0.199,0.637,0.404,0.939,0.614
			c0.068,0.047,0.133,0.097,0.199,0.145c0.237,0.169,0.471,0.339,0.695,0.514c0.057,0.044,0.111,0.089,0.167,0.133
			c0.238,0.189,0.472,0.38,0.698,0.576c0.03,0.026,0.06,0.052,0.09,0.078c0.256,0.223,0.507,0.449,0.751,0.679
			c-0.001-0.001-0.003-0.002-0.004-0.004c1.428,1.343,2.671,2.788,4.003,4.233c-1.331-1.443-2.573-2.888-3.999-4.229
			c1.836,1.956,3.146,4.331,5.1,6.381c0.887,0.93,2.245,1.363,3.12,2.4c0.271,0.322,0.577,0.707,0.901,1.133
			c0.366,0.532,0.735,1.06,1.104,1.589c0.537,0.771,1.071,1.546,1.585,2.34c0.009,0.014,0.017,0.028,0.025,0.042
			c-0.005-0.009-0.011-0.019-0.017-0.028c0.28,0.434,0.555,0.874,0.82,1.323c-0.26-0.439-0.529-0.87-0.804-1.294
			c1.185,1.915,2.277,3.969,2.864,5.696c0.843,2.48-0.09,5.055,1.196,7.496c-0.001,0.063,0.004,0.121,0.003,0.184
			c0.031-0.006,0.057-0.019,0.087-0.027c0.051,0.089,0.097,0.178,0.153,0.267c1.832-0.467,1.743-3.94,1.68-5.52
			c-0.295-7.368-7.098-13.357-10.079-18.48c2.415,2.904,6.461,4.035,8.88,6.96c1.723,2.084,1.9,5.308,4.56,6.72
			c0.825,3.276,2.415,6.803,3.067,10.585c0-0.002-0.001-0.004-0.001-0.006c0.001,0.004,0.001,0.008,0.002,0.012
			c0.089,0.746,0.154,1.5,0.202,2.259c0.005,0.074,0.008,0.147,0.013,0.221c0.043,0.74,0.071,1.484,0.079,2.231
			c0,0.003,0,0.007,0,0.011c-0.188,7.919-2.781,15.626-5.522,20.207c-1.008,1.685-2.517,3.181-2.821,4.947
			c-0.02,0.031-0.039,0.062-0.059,0.093c0.017-0.003,0.028-0.012,0.045-0.015c-0.013,0.085-0.038,0.168-0.045,0.255
			c2.158-0.002,1.813-2.508,3.36-3.12c0.127-0.193,0.25-0.39,0.24-0.72c0.194-0.045,0.239-0.241,0.479-0.24
			c-0.039-0.279,0.053-0.427,0.24-0.48c0.148-1.692,1.018-2.663,1.439-4.08c0.585-1.895,1.31-3.65,1.681-5.76
			c0.441-1.398,0.795-2.885,1.439-4.08c0.446-0.833,0.776-1.784,1.44-2.4c0.833-1.487,0.185-4.455,0.96-6
			c1.608,3.032,2.559,6.722,2.16,11.76c0.106,0.613,0.336,1.104,0.72,1.44c0.336,0.864,0.567,1.833,0.96,2.64
			c-0.067,0.387,0.227,0.413,0.24,0.72c0.172,0.055,0.307,0.044,0.414-0.019c0.021,0.006,0.041,0.023,0.065,0.019
			c0-0.027,0-0.054,0-0.081c0.64-0.593,0.01-3.491,0.24-4.719c0-0.32,0-0.64,0-0.96c-0.08,0-0.16,0-0.24,0c0-0.56,0-1.12,0-1.68
			c0-1.44,0-2.88,0-4.32c0-0.4,0-0.8,0-1.2c-0.08,0-0.159,0-0.239,0c0-0.8,0-1.6,0-2.4c-0.144-0.497,0.296-1.577-0.24-1.68
			c-0.02-1.181-0.471-1.929-0.72-2.88c-0.309-1.052-1.035-1.686-1.44-2.64c-0.347-1.254-1.145-2.055-1.92-2.88
			c-0.902-1.498-1.959-2.841-2.88-4.32c-0.131-0.989-0.836-1.405-1.2-2.16c-0.465-0.976-1.136-1.744-1.68-2.64
			c-0.535-1.545-1.547-2.613-2.4-3.84c0-0.32,0-0.64,0-0.96c0-0.16,0-0.32,0-0.48c-0.867-1.774-3.179-3.379-3.6-4.8
			c6.168,2.152,13.311,3.33,18.239,6.72c0.146,0.014,0.284,0.036,0.24,0.24c1.26,0.021,2.145,0.416,3.12,0.72c0.16,0,0.32,0,0.48,0
			C385.816,160.549,384.543,160.222,384.001,159.164z M371.521,194.924c0.233-0.817,0.427-1.653,0.603-2.496
			C371.948,193.271,371.754,194.107,371.521,194.924z M372.648,189.27c0.229-1.877,0.333-3.781,0.314-5.671
			C372.981,185.49,372.881,187.393,372.648,189.27z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M363.601,358.365c0.22,0.42,0.083,1.197,0.48,1.44
			c0.159,0,0.319,0,0.479,0C364.413,359.152,364.085,358.681,363.601,358.365z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M382.081,237.404c0,0.08,0,0.16,0,0.24
			c0.493,0.067,0.479,0.641,0.72,0.96C383.398,237.306,383.014,235.789,382.081,237.404z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M425.761,781.726c0-0.561,0-1.12,0-1.681c-0.08,0-0.16,0-0.24,0
			c0-0.72,0-1.439,0-2.159c0-0.24,0-0.48,0-0.721c0.177-2.816-0.273-5.006-0.239-7.68c-0.08,0-0.16,0-0.24,0c0-0.64,0-1.28,0-1.92
			c0-0.4,0-0.8,0-1.2c0-1.439,0-2.88,0-4.32c0-0.399,0-0.8,0-1.199c-0.703-1.778-0.893-4.068-1.2-6.24c0-0.641,0-1.28,0-1.92
			c-0.08,0-0.16,0-0.24,0c0-0.641,0-1.28,0-1.92c0-0.24,0-0.48,0-0.721c0-0.8,0-1.6,0-2.399c-1.062-2.138-2.311-4.089-2.16-7.44
			c-0.08,0-0.159,0-0.239,0c0-0.96,0-1.92,0-2.88c0.08,0,0.159,0,0.239,0c0.345-4.936,1.746-8.814,1.681-14.16
			c0.371-1.708,0.438-3.721,0.479-5.76c0-0.8,0-1.6,0-2.4c0-1.439,0-2.88,0-4.319c0-1.36,0-2.721,0-4.08c0-0.641,0-1.28,0-1.92
			c-0.08,0-0.16,0-0.24,0c0-0.561,0-1.12,0-1.681c0-0.239,0-0.479,0-0.72c0.021-2.82-0.065-5.534-0.479-7.92c0-0.72,0-1.44,0-2.16
			c-0.08,0-0.16,0-0.24,0c0.074-0.954-0.555-1.205-0.479-2.16c0-0.08,0-0.16,0-0.239c0.555-0.886,0.083-2.797,0.239-4.08
			c-0.143-0.497,0.297-1.577-0.239-1.681c0-0.16,0-0.319,0-0.479c0-0.24,0-0.48,0-0.72c0-2.16,0-4.32,0-6.48
			c0.071-2.328,0.378-4.421,0.239-6.96c0-0.64,0-1.28,0-1.92c-0.397-0.492-0.397-2.628,0-3.12c0.451-2.27,0.904-4.536,0.96-7.2
			c0.468-2.144,0.419-3.339,0.721-5.52c0.416-2.063,0.588-4.372,0.96-6.48c0.039-0.279-0.053-0.427-0.24-0.479
			c-2.846,0.273-4.137,2.104-6.24,3.119c-0.08,0-0.16,0-0.24,0c-3.719,3.161-8.683,5.077-11.279,9.36
			c0.122,0.598-0.008,1.448,0.72,1.44c0.241,0,0.286-0.194,0.48-0.24c1.246-0.594,2.535-1.145,3.84-1.68
			c0.936-0.345,1.986-0.574,2.64-1.2c0.282-0.038,0.682,0.041,0.72-0.24c2.272-0.608,2.95-2.811,5.04-3.6
			c0.82-0.101,1.368,0.072,1.2,0.96c-1.188,1.771-2.628,3.292-4.56,4.319c-0.552,0.329-0.596,1.164-0.96,1.681
			c0,0.08,0,0.16,0,0.239c-1.019,0.902-1.803,2.038-2.641,3.12c-0.258,0.622-0.867,0.893-0.96,1.681
			c-0.522,0.757-1.075,1.484-1.2,2.64c-0.548,0.491,0.069,2.148-0.479,2.64c-0.521-0.04-0.108,0.852-0.24,1.2
			c0.702-0.178,1.18-0.58,1.68-0.96c0.636-0.404,1.525-0.555,2.16-0.96c0.734,0.174,0.706-0.414,1.44-0.24
			c0.489-0.07,0.471-0.649,0.96-0.72c0.689-0.515,1.19,0.764,1.439,1.44c0,0.08,0,0.16,0,0.239c-0.779,0.98-1.659,1.86-2.64,2.641
			c-0.558,0.162-0.643,0.797-1.2,0.96c-1.039,0.721-1.692,1.827-2.88,2.399c0,0.08,0,0.16,0,0.24
			c1.702,0.138,1.248,2.433,1.681,3.84c0.314-0.085,0.377-0.422,0.72-0.479c0.058-0.343,0.395-0.405,0.479-0.72
			c0.7-0.901,1.5-1.701,2.4-2.4c0.65-0.31,0.854-1.066,1.92-0.96c-1.444,5.275-5.452,7.987-8.4,11.76c0,0.08,0,0.16,0,0.24
			s0,0.16,0,0.24c-0.56,4.24-0.08,9.52-0.239,14.16c0.001,0.327-0.046,0.607-0.113,0.866c-0.045,0.138-0.092,0.274-0.131,0.418
			c-0.276,0.733-0.691,1.329-0.716,2.315c-0.56,2.641-0.081,6.319-0.24,9.36c0.188,1.067-0.319,1.44-0.24,2.399
			c-0.399,5.44-0.399,12.56,0,18c0.02,0.38-0.115,0.916,0.24,0.96c0,0.08,0,0.16,0,0.24c0.195,1.476-0.757,1.803-0.72,3.12
			c0,0.08,0,0.16,0,0.24c-0.339,1.102-0.988,1.893-0.96,3.359c-0.284,0.597-0.348,1.413-0.48,2.16
			c-0.57,0.979-0.282,3.155,0.72,3.36c0.08,0,0.16,0,0.24,0c0.836-0.845,2.373-0.987,3.36-1.681c2.32-0.879,4.292-2.107,6.479-3.119
			c0.52,0.44,0.506,1.414,0.48,2.399c-2.946,1.455-5.895,2.906-8.88,4.32c-0.598,0.442-1.502,0.579-2.16,0.96
			c-1.002,0.278-1.791,0.77-2.641,1.2c-0.797,0.482-1.758,0.801-2.64,1.2c-0.454-0.054-0.529,0.27-0.96,0.239
			c-0.204,0.676-0.775,0.985-0.72,1.92c0,0.08,0,0.16,0,0.24c-1.191,3.593,2.351,4.527,3.12,6.96c0,0.08,0,0.16,0,0.24
			c0.452,1.308,1.693,1.826,3.119,2.16c0.08,0,0.16,0,0.24,0c0.16,0,0.32,0,0.48,0c-0.045-0.204,0.094-0.227,0.24-0.24
			c1.651-0.108,2.044-1.476,3.6-1.68c0.138,0.422-0.29,1.41,0.24,1.439c0,0.08,0,0.16,0,0.24c0.07,1.449,0.513,2.527,0.479,4.08
			c0.153,0.887-0.311,2.391,0.24,2.88c-0.039,0.279,0.053,0.427,0.24,0.48c0,0.079,0,0.16,0,0.239
			c-0.158,0.878,0.519,0.921,0.479,1.681c1.45,0.63,0.611,3.549,1.2,5.04c-0.128,0.928,0.429,1.172,0.24,2.16
			c0.154,1.045-0.313,2.713,0.24,3.359c0.024,0.615,0.136,1.145,0.479,1.44c0,0.08,0,0.16,0,0.239
			c0.033,0.848,0.687,1.073,0.72,1.921c0.155,1.045-0.313,2.713,0.24,3.359c-0.051,1.491,0.737,2.143,0.72,3.601
			c0.159,3.04-0.318,6.719,0.24,9.359c-0.039,0.279,0.053,0.428,0.24,0.48c0,0.24,0,0.479,0,0.72c0.24,0,0.479,0,0.72,0
			c0.32,0,0.641,0,0.96,0c0-0.08,0-0.16,0-0.24c1.842,0.158,4.317-0.317,5.76,0.24c0.827,0.187,1.146-0.134,0.961-0.96
			c0.529-0.029,0.102-1.018,0.239-1.439c0-0.961,0-1.921,0-2.881c0.08,0,0.16,0,0.24,0c0-1.199,0-2.399,0-3.6
			c0.159-2.401-0.318-5.438,0.24-7.44c0-0.319,0-0.64,0-0.96C425.442,786.523,425.919,783.807,425.761,781.726z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M381.121,280.364c-0.229-2.812-0.894-5.187-0.96-8.16
			c-0.398-0.414-0.398-2.466,0-2.88c0-0.16,0-0.32,0-0.48c0.541-0.179,0.094-1.346,0.239-1.92c0-0.88,0-1.76,0-2.64
			c0-0.56,0-1.12,0-1.68c0.041-0.664-0.368-0.824-0.72-0.48c-0.399,0.32-0.08,1.36-0.479,1.68c-0.961,5.84-4.261,9.34-6.24,14.16
			c4.403-0.963,4.423,2.457,4.8,5.52c0,1.76,0,3.52,0,5.28c-0.477,3.844-2.229,6.41-2.88,10.08c-3.686,3.115-4.36,9.24-6.24,14.16
			c-1.271,0.649-1.276,2.563-3.12,2.64c-1.513-1.528-1.224-4.856-1.439-7.68c0.355-0.044,0.22-0.58,0.239-0.96
			c-0.08,0-0.159,0-0.239,0c-0.768,0.513-1.431,1.13-2.16,1.68c-0.173,0.255-0.938,1.105-0.24,1.44
			c1.533,7.107,2.389,13.262,4.8,19.68c0.702,1.868,2.314,3.969,2.4,5.52c0.142,2.57-2.547,7.101-2.88,10.08
			c-0.153,1.366,0.111,2.614,0,4.32c-0.26,1.225-0.691,4.688,0.239,5.52c0.343-1.011,0.471-2.142,0.24-3.12
			c0.434-2.127,0.795-4.325,1.681-6c0.691-1.548,1.602-2.878,2.159-4.56c1.072-2.368,1.277-5.604,1.681-8.64
			c0.067-0.813,0.382-1.378,0.239-2.4c1.229-6.76,3.04-13.334,4.32-19.92c1.298-6.675,3.428-12.77,4.561-19.44
			c0.547-0.333,0.089-1.67,0.239-2.4c0-0.48,0-0.96,0-1.44c0-0.64,0-1.28,0-1.92c0-0.72,0-1.44,0-2.16
			C381.208,282.357,381.672,280.854,381.121,280.364z"/>
			</g>
			<polygon fillRule="evenodd" clipRule="evenodd" fill="#E8E735" points="376.816,232.125 376.816,242.461 383.041,242.461 
		383.041,231.645 	"/>
		</g>
		<path id="mouth1" d="M355.681,337.725c-1.2-0.186-1.931,0.025-2.4,0c-3.28,0.354-6.48,0.791-9.024,1.882l-11.131-0.357
	c-0.338,0.029-0.67,0.063-0.995,0.101l-0.001,0c-0.746-0.292-3.432,0.304-6.378,0.765c-0.452-0.025-0.896-0.042-1.271,0.01
	c-0.08,0-0.16,0-0.24,0c0-0.08,0-0.16,0-0.24c-1.04,0-2.08,0-3.12,0c0,0.065,0,0.131,0,0.197c-0.604-0.099-0.964-0.197-0.964-0.197
	l-6.031-0.263c0,0,0.011,0.014,0.017,0.021c-2.818-0.023-3.87-0.283-5.981,0.401c-1.406,0.106-2.461,1.324-1.68,2.88
	c4.141,0.584,6.648-1.093,10.69-0.96c0.27,0.009,0.546,0.035,0.83,0.08h0.164c1.902,0.541,4.835,0.531,4.835,0.531
	c1.198,0.071,2.728-0.126,4.121-0.384c0,0.031,0,0.062,0,0.093c0.729-0.151,2.066,0.307,2.399-0.24c-0.24,0-0.479,0-0.72,0
	c0.079-0.106,0.204-0.166,0.347-0.208c0.246-0.016,0.493-0.038,0.74-0.063c0.53,0.022,1.126,0.111,1.553,0.031c0.24,0,0.48,0,0.72,0
	c0,0.08,0,0.16,0,0.24c3.841-0.16,9.217,0.078,12.657-0.481l2.526-0.5l2.5-0.218c2.906-0.72,7.271-1.119,8.237-0.72
	c2.212-0.544,3.213,0.904,4.8-0.52C362.936,336.189,358.728,338.038,355.681,337.725z"/>
		<g id="eyebrow1">
			<path fillRule="evenodd" clipRule="evenodd" d="M378.001,233.804c-2.505-0.456-4.91-1.01-7.681-1.2c-1.52,0-3.04,0-4.56,0
		c-5.377,0.383-10.661,0.858-13.44,3.84c1.542-2.083-3.283,2.742-1.199,1.2c-0.796,1.095-2.914,3.432-1.2,4.8
		c1.757,0.202,1.965-1.776,2.64-2.64c2.636-3.375,6.836-4.31,12.72-4.56c0.96,0,1.921,0,2.881,0c3.921,0.319,7.599,0.881,10.56,2.16
		c-0.72,0-1.44,0-2.16,0c-1.283,0.156-3.195-0.315-4.08,0.24c-1.279,0-2.56,0-3.84,0c-1.125,0.155-2.874-0.314-3.6,0.24
		c-6.424,0.217-11.887,1.394-14.16,5.76c0,0.08,0,0.16,0,0.24c0.217,0.423,0.537,0.743,0.96,0.96c1.437-0.403,2.369-1.31,3.359-2.16
		c2.363-1.317,5.377-1.984,8.881-2.16c0.492,0.398,2.628,0.398,3.12,0c-0.561,0-1.12,0-1.681,0c1.842-0.558,4.719-0.082,6.96-0.24
		c0,0.08,0,0.16,0,0.24c1.125-0.155,2.874,0.314,3.601-0.24c-0.48,0-0.96,0-1.44,0c0.489-0.551,1.993-0.087,2.88-0.24
		c0.08,0,0.16,0,0.24,0c1.045-0.155,2.713,0.313,3.36-0.24c1.044,0.245,1.104-0.497,1.68-0.72c0-0.08,0-0.16,0-0.24s0-0.16,0-0.24
		c-0.241-0.319-0.227-0.893-0.72-0.96c-0.477-0.415,1.042-0.864,0.72-1.92c-0.232-0.647-0.602-1.158-1.44-1.2
		C379.974,234.531,379.175,233.285,378.001,233.804z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#B2734F" d="M365.28,235.244c-5.884,0.25-10.084,1.186-12.72,4.56
		c-0.675,0.864-0.883,2.842-2.64,2.64c0.624,0.176,0.629,0.971,0.96,1.44c0-0.08,0-0.16,0-0.24c2.273-4.367,7.736-5.543,14.16-5.76
		c0.726-0.554,2.475-0.084,3.6-0.24c1.28,0,2.561,0,3.84,0c0.885-0.555,2.797-0.084,4.08-0.24c0.72,0,1.44,0,2.16,0
		c-2.961-1.279-6.639-1.841-10.56-2.16C367.201,235.244,366.24,235.244,365.28,235.244z"/>
			<g>
				<path d="M312.48,237.645c-0.623-0.143-0.609-0.924-1.348-0.952c-0.031-0.027-0.066-0.051-0.098-0.078
			c-0.037-0.217-0.311-0.313,0.006-0.41c0.199,0.156,0.477,0.236,0.725,0.344l-3.418-2.706c0.536,0.644,1.862,1.671,0.533,1.162
			c-0.204,0.044-0.227-0.094-0.24-0.24c0.012-0.737-1.613-1.902-2.4-2.64c0.329,0.944,2.124,1.456,1.681,2.4
			c-0.724-0.316-1.305-0.775-2.4-0.72c0-0.08,0-0.16,0-0.24c-0.427-0.565-5.643-0.155-3.12,0c0,0.08,0,0.16,0,0.24
			c-2.559-0.159-4.405,0.395-6.72,0.48c-0.492-0.398-2.628-0.398-3.12,0c0.48,0,0.96,0,1.44,0c-0.647,0.553-2.315,0.085-3.36,0.24
			c-0.48,0-0.96,0-1.44,0c-0.489,0.551-1.993,0.087-2.88,0.24c-5.213,0.146-8.991,1.729-14.16,1.92
			c-0.726,0.554-2.476,0.084-3.6,0.24c0-0.08,0-0.16,0-0.24c-1.697,0.274-3.518-0.852-4.56,0.24c0,0.16,0,0.32,0,0.48
			c0.367,1.077,1.035,1.33,0,2.16c2.893,1.267,7.259,1.061,10.56,1.92c0.12,0.37,3.889,0.421,4.8,0c-0.88,0-1.76,0-2.64,0
			c1.124-0.557,3.277-0.083,4.8-0.24c1.022-0.018,1.679-0.401,2.88-0.24c0.259,0.397,2.142,0.397,2.4,0c-0.32,0-0.64,0-0.96,0
			c0.568-0.552,2.154-0.086,3.12-0.24c0,0.08,0,0.16,0,0.24c2.001-0.158,4.638,0.318,6.24-0.24c-1.52,0-3.04,0-4.56,0
			c1.443-0.399,4.556-0.399,6,0c-0.4,0-0.8,0-1.2,0c0.259,0.397,2.142,0.397,2.4,0c6.307,0.973,12.501,2.059,16.56,5.28
			c1.014-0.267,1.823-0.737,2.4-1.44C316.43,241.216,314.011,239.874,312.48,237.645z M297.12,237.884
			c-1.683-0.399-5.036-0.399-6.72,0c-0.479,0-0.96,0-1.44,0c-0.966,0.154-2.552-0.312-3.12,0.24c-1.446-0.007-2.955-0.075-4.08,0.24
			c-0.729,0.15-2.067-0.307-2.4,0.24c-0.4,0-0.8,0-1.2,0c2.205-1.155,5.676-1.043,8.64-1.44c0.887-0.153,2.391,0.311,2.88-0.24
			c0.479,0,0.96,0,1.439,0c1.125-0.155,2.874,0.314,3.601-0.24c2.27,0.11,4.026-0.293,6-0.48c0.808-0.152,2.229,0.31,2.64-0.24
			c4.341,0.14,6.335,2.625,8.641,4.8C307.727,240.078,302.738,238.027,297.12,237.884z"/>
				<path d="M311.766,236.548l0.475,0.376C312.144,236.737,311.963,236.634,311.766,236.548z" />
				<path fill="#B2734F" d="M300.721,236.204c-1.974,0.187-3.73,0.59-6,0.48c-0.727,0.554-2.476,0.084-3.601,0.24
			c-0.479,0-0.96,0-1.439,0c-0.489,0.551-1.993,0.087-2.88,0.24c-2.963,0.396-6.435,0.285-8.64,1.44c0.4,0,0.8,0,1.2,0
			c0.333-0.547,1.671-0.09,2.4-0.24c1.125-0.314,2.633-0.247,4.08-0.24c0.568-0.552,2.154-0.086,3.12-0.24c0.48,0,0.96,0,1.44,0
			c1.684-0.399,5.037-0.399,6.72,0c5.618,0.143,10.606,2.194,14.881,2.88c-2.306-2.175-4.3-4.66-8.641-4.8
			C302.95,236.514,301.528,236.052,300.721,236.204z"/>
			</g>
		</g>
		<path id="mouth2" display="none" d="M356.681,338.725c-1.2-0.186-2.931-0.975-3.4-1c-3.28,0.354-6.48,0.791-9.024,1.882
	l-11.131-0.357c-0.338,0.029-0.67,0.063-0.995,0.101l-0.001,0c-0.746-0.292-3.432,0.304-6.378,0.765
	c-0.452-0.025-0.896-0.042-1.271,0.01c-0.08,0-0.16,0-0.24,0c0-0.08,0-0.16,0-0.24c-1.04,0-2.08,0-3.12,0c0,0.065,0,0.131,0,0.197
	c-0.604-0.099-0.964-0.197-0.964-0.197l-7.031,2.737c0,0,0.011,0.014,0.017,0.021c-2.818-0.023-3.87,1.717-5.981,2.401
	c-1.406,0.106-2.461,1.324-1.68,2.88c4.141,0.584,7.648-5.093,11.69-4.96c0.27,0.009,0.546,0.035,0.83,0.08l0.164-1
	c1.902,0.541,4.835,0.531,4.835,0.531c1.198,0.071,2.728-0.126,4.121-0.384c0,0.031,0,0.062,0,0.093
	c0.729-0.151,2.066,0.307,2.399-0.24c-0.24,0-0.479,0-0.72,0c0.079-0.106,0.204-0.166,0.347-0.208
	c0.246-0.016,0.493-0.038,0.74-0.063c0.53,0.022,1.126,0.111,1.553,0.031c0.24,0,0.48,0,0.72,0c0,0.08,0,0.16,0,0.24
	c4.009-0.167,9.233,0.075,12.657-0.481l2.526-0.5l2.5-0.218c2.906-0.72,8.271-0.119,9.237,0.28c2.212-0.544,3.213,2.904,4.8,1.48
	C363.936,339.189,359.728,339.038,356.681,338.725z"/>
		<g id="eyebrow2" display="none">
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M377.001,235.804c-2.505-0.456-4.91-1.01-7.681-1.2
		c-1.52,0-3.04,0-4.56,0c-5.377,0.383-10.661-4.142-13.44-1.16c1.542-2.083-3.283,2.742-1.199,1.2c-0.796,1.095-2.914,3.432-1.2,4.8
		c1.757,0.202,1.965-1.776,2.64-2.64c2.636-3.375,6.836,0.69,12.72,0.44c0.96,0,1.921,0,2.881,0c3.921,0.319,7.599,0.881,10.56,2.16
		c-0.72,0-1.44,0-2.16,0c-1.283,0.156-3.195-0.315-4.08,0.24c-1.279,0-2.56,0-3.84,0c-1.125,0.155-2.874-0.314-3.6,0.24
		c-6.424,0.217-11.887-3.606-14.16,0.76c0,0.08,0,0.16,0,0.24c0.217,0.423,0.537,0.743,0.96,0.96c1.437-0.403,2.369-0.31,3.359-1.16
		c2.363-1.317,5.377,2.016,8.881,1.84c0.492,0.398,2.628,0.398,3.12,0c-0.561,0-1.12,0-1.681,0c1.842-0.558,4.719-0.082,6.96-0.24
		c0,0.08,0,0.16,0,0.24c1.125-0.155,2.874,0.314,3.601-0.24c-0.48,0-0.96,0-1.44,0c0.489-0.551,1.993-0.087,2.88-0.24
		c0.08,0,0.16,0,0.24,0c1.045-0.155,2.713,0.313,3.36-0.24c1.044,0.245,1.104-0.497,1.68-0.72c0-0.08,0-0.16,0-0.24s0-0.16,0-0.24
		c-0.241-0.319-0.227-0.893-0.72-0.96c-0.477-0.415,1.042-0.864,0.72-1.92c-0.232-0.647-0.602-1.158-1.44-1.2
		C378.974,236.531,378.175,235.285,377.001,235.804z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" fill="#B2734F" d="M364.28,237.244
		c-5.884,0.25-10.084-3.814-12.72-0.44c-0.675,0.864-0.883,2.842-2.64,2.64c0.624,0.176,0.629,0.971,0.96,1.44c0-0.08,0-0.16,0-0.24
		c2.273-4.367,7.736-0.543,14.16-0.76c0.726-0.554,2.475-0.084,3.6-0.24c1.28,0,2.561,0,3.84,0c0.885-0.555,2.797-0.084,4.08-0.24
		c0.72,0,1.44,0,2.16,0c-2.961-1.279-6.639-1.841-10.56-2.16C366.201,237.244,365.24,237.244,364.28,237.244z"/>
			<g display="inline">
				<path d="M315.48,235.645c-0.623-0.143-0.609,0.076-1.348,0.048c-0.031-0.027-0.066-0.051-0.098-0.078
			c-0.037-0.217-0.311-0.313,0.006-0.41c0.199,0.156,0.477,0.236,0.725,0.344l-3.418-2.706c0.536,0.644,1.862,1.671,0.533,1.162
			c-0.204,0.044-0.227-0.094-0.24-0.24c0.012-0.737-1.613,0.098-2.4-0.64c0.329,0.944,2.124-0.544,1.681,0.4
			c-0.724-0.316-1.305,1.225-2.4,1.28c0-0.08,0-0.16,0-0.24c-0.427-0.565-5.643-1.155-3.12-1c0,0.08,0,0.16,0,0.24
			c-2.559-0.159-4.405,1.395-6.72,1.48c-0.492-0.398-2.628-0.398-3.12,0c0.48,0,0.96,0,1.44,0c-0.647,0.553-2.315,1.085-3.36,1.24
			c-0.48,0-0.96,0-1.44,0c-0.489,0.551-1.993,0.087-2.88,0.24c-5.213,0.146-9.991,1.729-15.16,1.92
			c-0.726,0.554-2.476,0.084-3.6,0.24c0-0.08,0-0.16,0-0.24c-1.697,0.274-3.518-0.852-4.56,0.24c0,0.16,0,0.32,0,0.48
			c0.367,1.077,1.035,1.33,0,2.16c2.893,1.267,7.259,1.061,10.56,1.92c0.12,0.37,4.889-0.579,5.8-1c-0.88,0-1.76,0-2.64,0
			c1.124-0.557,3.277,0.917,4.8,0.76c1.022-0.018,1.679-0.401,2.88-0.24c0.259,0.397,2.142,0.397,2.4,0c-0.32,0-0.641,0-0.96,0
			c0.568-0.552,2.154-0.086,3.121-0.24c0,0.08,0,0.16,0,0.24c2.001-0.158,4.638,0.318,6.239-0.24c-1.52,0-3.04,0-4.56,0
			c1.443-0.399,4.556-0.399,6,0c-0.4,0-0.8,0-1.2,0c0.259,0.397,2.142-0.603,2.4-1c6.307,0.973,12.501-0.941,16.56,2.28
			c1.014-0.267,1.823-0.737,2.4-1.44C319.43,239.216,317.011,237.874,315.48,235.645z M300.12,238.884
			c-1.681-0.399-5.037,0.601-6.72,1c-0.479,0-0.96,0-1.439,0c-0.967,0.154-2.553-0.312-3.121,0.24
			c-1.446-0.007-2.955-0.075-4.08,0.24c-0.729,0.15-2.067-1.307-2.4-0.76c-0.4,0-0.8,0-1.2,0c2.205-1.155,5.676-0.043,8.64-0.44
			c0.887-0.153,2.391,0.311,2.88-0.24c0.479,0,0.96,0,1.439,0c1.125-0.155,2.874-0.686,3.601-1.24c2.676,0.129,3.57-1.25,6-1.48
			c0.808-0.152,2.229,0.31,2.64-0.24c4.341,0.14,6.335,0.625,8.641,2.8C310.727,238.078,305.738,239.027,300.12,238.884z"/>
				<path d="M314.766,235.548l0.475-0.624C315.144,234.737,314.963,235.634,314.766,235.548z" />
				<path fill="#B2734F" d="M303.721,236.204c-2.43,0.23-3.324,1.61-6,1.48c-0.727,0.554-2.476,1.084-3.601,1.24
			c-0.479,0-0.96,0-1.439,0c-0.489,0.551-1.993,0.087-2.88,0.24c-2.964,0.396-6.435-0.715-8.64,0.44c0.4,0,0.8,0,1.2,0
			c0.333-0.547,1.671,0.91,2.4,0.76c1.125-0.314,2.633-0.247,4.08-0.24c0.568-0.552,2.154-0.086,3.121-0.24c0.479,0,0.96,0,1.439,0
			c1.683-0.399,5.039-0.399,6.72,0c5.618,0.143,10.606-1.806,14.881-1.12c-2.306-2.175-4.3-2.66-8.641-2.8
			C305.95,236.514,304.528,236.052,303.721,236.204z"/>
			</g>
		</g>
		<path id="mouth3" display="none" d="M355.681,335.725c-1.2-0.186-1.931,0.025-2.4,0c-3.28,0.354-6.48,0.791-9.024,1.882
	l-11.131-0.357c-0.338,0.029-0.67,0.063-0.995,0.101l-0.001,0c-0.746-0.292-3.432-0.696-6.378-0.235
	c-0.452-0.025-2.896-1.042-3.271-0.99c-0.08,0-0.16,0-0.24,0c0-0.08,0-0.16,0-0.24c-1.04,0-2.08,0-3.12,0c0,0.065,0,0.131,0,0.197
	c-0.604-0.099-0.964-0.197-0.964-0.197l-8.031-1.263c0,0,0.011,0.014,0.017,0.021c-2.818-0.023-3.87-1.283-5.981-0.599
	c-1.406,0.106-2.461,1.324-1.68,2.88c4.141,0.584,6.648,0.907,10.69,1.04c0.27,0.009,2.546,0.035,2.83,0.08h0.164
	c1.902,0.541,4.835,0.531,4.835,0.531c1.198,0.071,4.728,1.874,6.121,1.616c0,0.031,0,0.062,0,0.093
	c0.729-0.151,2.066,0.307,2.399-0.24c-0.24,0-0.479,0-0.72,0c0.079-0.106,0.204-0.166,0.347-0.208
	c0.246-0.016,0.493-0.038,0.74-0.063c0.53,0.022,1.126,0.111,1.553,0.031c0.24,0,0.48,0,0.72,0c0,0.08,0,0.16,0,0.24
	c3.841-0.16,9.217,0.078,12.657-0.481l2.526-0.5l2.5-0.218c2.906-0.72,7.271-2.119,8.237-1.72c2.212-0.544,5.213-1.096,6.8-2.52
	C364.936,331.188,358.726,336.038,355.681,335.725z"/>
		<g id="eyebrow3" display="none">
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M378.001,231.804c-2.505-0.456-4.91-1.01-7.681-1.2
		c-1.52,0-3.04,0-4.56,0c-5.377,0.383-10.661,0.858-13.44,3.84c1.542-2.083-3.283,2.742-1.199,1.2c-0.796,1.095-2.914,3.432-1.2,4.8
		c1.757,0.202,1.965-1.776,2.64-2.64c2.636-3.375,6.836-4.31,12.72-4.56c0.96,0,1.921,0,2.881,0c3.921,0.319,7.599,0.881,10.56,2.16
		c-0.72,0-1.44,0-2.16,0c-1.283,0.156-3.195-0.315-4.08,0.24c-1.279,0-2.56,0-3.84,0c-1.125,0.155-2.874-0.314-3.6,0.24
		c-6.424,0.217-11.887,1.394-14.16,5.76c0,0.08,0,0.16,0,0.24c0.217,0.423,0.537,0.743,0.96,0.96c1.437-0.403,2.369-1.31,3.359-2.16
		c2.363-1.317,5.377-1.984,8.881-2.16c0.492,0.398,2.628,0.398,3.12,0c-0.561,0-1.12,0-1.681,0c1.842-0.558,4.719-0.082,6.96-0.24
		c0,0.08,0,0.16,0,0.24c1.125-0.155,2.874,0.314,3.601-0.24c-0.48,0-0.96,0-1.44,0c0.489-0.551,1.993-0.087,2.88-0.24
		c0.08,0,0.16,0,0.24,0c1.045-0.155,2.713,0.313,3.36-0.24c1.044,0.245,1.104-0.497,1.68-0.72c0-0.08,0-0.16,0-0.24s0-0.16,0-0.24
		c-0.241-0.319-0.227-0.893-0.72-0.96c-0.477-0.415,1.042-0.864,0.72-1.92c-0.232-0.647-0.602-1.158-1.44-1.2
		C379.974,232.531,379.175,231.285,378.001,231.804z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" fill="#B2734F" d="M365.28,233.244
		c-5.884,0.25-10.084,1.186-12.72,4.56c-0.675,0.864-0.883,2.842-2.64,2.64c0.624,0.176,0.629,0.971,0.96,1.44c0-0.08,0-0.16,0-0.24
		c2.273-4.367,7.736-5.543,14.16-5.76c0.726-0.554,2.475-0.084,3.6-0.24c1.28,0,2.561,0,3.84,0c0.885-0.555,2.797-0.084,4.08-0.24
		c0.72,0,1.44,0,2.16,0c-2.961-1.279-6.639-1.841-10.56-2.16C367.201,233.244,366.24,233.244,365.28,233.244z"/>
			<g display="inline">
				<path d="M312.48,235.645c-0.623-0.143-0.609-0.924-1.348-0.952c-0.031-0.027-0.066-0.051-0.098-0.078
			c-0.037-0.217-0.311-0.313,0.006-0.41c0.199,0.156,0.477,0.236,0.725,0.344l-3.418-2.706c0.536,0.644,1.862,1.671,0.533,1.162
			c-0.204,0.044-0.227-0.094-0.24-0.24c0.012-0.737-1.613-1.902-2.4-2.64c0.329,0.944,2.124,1.456,1.681,2.4
			c-0.724-0.316-1.305-0.775-2.4-0.72c0-0.08,0-0.16,0-0.24c-0.427-0.565-5.643-0.155-3.12,0c0,0.08,0,0.16,0,0.24
			c-2.559-0.159-4.405,0.395-6.72,0.48c-0.492-0.398-2.628-0.398-3.12,0c0.48,0,0.96,0,1.44,0c-0.647,0.553-2.315,0.085-3.36,0.24
			c-0.48,0-0.96,0-1.44,0c-0.489,0.551-1.993,0.087-2.88,0.24c-5.213,0.146-8.991,1.729-14.16,1.92
			c-0.726,0.554-2.476,0.084-3.6,0.24c0-0.08,0-0.16,0-0.24c-1.697,0.274-3.518-0.852-4.56,0.24c0,0.16,0,0.32,0,0.48
			c0.367,1.077,1.035,1.33,0,2.16c2.893,1.267,7.259,1.061,10.56,1.92c0.12,0.37,3.889,0.421,4.8,0c-0.88,0-1.76,0-2.64,0
			c1.124-0.557,3.277-0.083,4.8-0.24c1.022-0.018,1.679-0.401,2.88-0.24c0.259,0.397,2.142,0.397,2.4,0c-0.32,0-0.64,0-0.96,0
			c0.568-0.552,2.154-0.086,3.12-0.24c0,0.08,0,0.16,0,0.24c2.001-0.158,4.638,0.318,6.24-0.24c-1.52,0-3.04,0-4.56,0
			c1.443-0.399,4.556-0.399,6,0c-0.4,0-0.8,0-1.2,0c0.259,0.397,2.142,0.397,2.4,0c6.307,0.973,12.501,2.059,16.56,5.28
			c1.014-0.267,1.823-0.737,2.4-1.44C316.43,239.216,314.011,237.874,312.48,235.645z M297.12,235.884
			c-1.683-0.399-5.036-0.399-6.72,0c-0.479,0-0.96,0-1.44,0c-0.966,0.154-2.552-0.312-3.12,0.24c-1.446-0.007-2.955-0.075-4.08,0.24
			c-0.729,0.15-2.067-0.307-2.4,0.24c-0.4,0-0.8,0-1.2,0c2.205-1.155,5.676-1.043,8.64-1.44c0.887-0.153,2.391,0.311,2.88-0.24
			c0.479,0,0.96,0,1.439,0c1.125-0.155,2.874,0.314,3.601-0.24c2.27,0.11,4.026-0.293,6-0.48c0.808-0.152,2.229,0.31,2.64-0.24
			c4.341,0.14,6.335,2.625,8.641,4.8C307.727,238.078,302.738,236.027,297.12,235.884z"/>
				<path d="M311.766,234.548l0.475,0.376C312.144,234.737,311.963,234.634,311.766,234.548z" />
				<path fill="#B2734F" d="M300.721,234.204c-1.974,0.187-3.73,0.59-6,0.48c-0.727,0.554-2.476,0.084-3.601,0.24
			c-0.479,0-0.96,0-1.439,0c-0.489,0.551-1.993,0.087-2.88,0.24c-2.963,0.396-6.435,0.285-8.64,1.44c0.4,0,0.8,0,1.2,0
			c0.333-0.547,1.671-0.09,2.4-0.24c1.125-0.314,2.633-0.247,4.08-0.24c0.568-0.552,2.154-0.086,3.12-0.24c0.48,0,0.96,0,1.44,0
			c1.684-0.399,5.037-0.399,6.72,0c5.618,0.143,10.606,2.194,14.881,2.88c-2.306-2.175-4.3-4.66-8.641-4.8
			C302.95,234.514,301.528,234.052,300.721,234.204z"/>
			</g>
		</g>
	</svg>
