import React from 'react'
export const Deepfake = () =>
	<svg version="1.1" className="character_svg" id="Deepfake" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		width="576px" height="828px" viewBox="0 0 576 828" enableBackground="new 0 0 576 828" space="preserve">
		<g>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E8E8" d="M298.32,291.06c-0.371-0.349-0.504-0.937-0.479-1.68
		c-1.365-0.476-2.766-0.916-4.561-0.96c-0.411-0.549-1.832-0.088-2.64-0.24c-1.2,0-2.4,0-3.6,0c-0.08,0-0.16,0-0.24,0
		c-0.32,0-0.64,0-0.96,0c0,0.08,0,0.16,0,0.24c-0.88,0-1.76,0-2.64,0c-3.055,0.065-5.22,1.02-7.44,1.92
		c-1.203,0.797-2.498,1.502-4.08,1.92c-0.104,0.295-0.307,0.493-0.72,0.48c-0.134,0.346-0.375,0.586-0.72,0.72
		c-0.925,1.075-1.975,2.025-2.88,3.12c0,0.08,0,0.16,0,0.24c-0.203,2.683,2.132,2.828,4.08,3.36c0.279-0.039,0.427,0.053,0.48,0.24
		c0.08,0,0.16,0,0.24,0c1.422,0.338,3.002,0.519,4.32,0.96c0.398,0.402,1.409,0.191,2.16,0.24c0.16,0,0.32,0,0.479,0
		c0.8,0,1.6,0,2.4,0c0.16,0,0.32,0,0.48,0c0-0.08,0-0.16,0-0.24c0.815-0.065,1.946,0.186,2.4-0.24c0.809-0.071,1.64-0.121,2.16-0.48
		c0.24,0,0.48,0,0.72,0c1.403,0.123,1.724-0.837,2.88-0.96c0.989,0.109,0.9-0.859,1.681-0.96c0.405-0.794,1.292-1.107,1.92-1.68
		c0.993-0.847,2.151-1.528,2.88-2.64c0.315-0.085,0.377-0.422,0.72-0.48c-0.041-0.601,0.369-0.751,0.72-0.96
		C298.226,292.404,298.426,291.884,298.32,291.06z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E8E8" d="M232.56,301.139c-1-0.36-2.039-0.681-2.64-1.44
		c-0.187-0.054-0.474-0.006-0.48-0.24c-2.67-1.095-5.865-1.463-8.88-0.48c-1.01,0.51-2.113,0.927-3.36,1.2
		c-0.664,0.537-1.466,0.934-2.4,1.2c-0.401,0.879-2.044,0.516-2.4,1.44c-0.085,0.315-0.423,0.377-0.48,0.72
		c-1.167,1.179-1.37,5.688,0,7.44c0.204-0.044,0.226,0.093,0.24,0.24c0.736,0.544,1.312,1.249,1.92,1.92
		c0.996,0.364,1.988,0.733,2.88,1.2c0.48,0,0.96,0,1.44,0c0.96,0,1.92,0,2.88,0c1.811,0.131,2.654-0.706,4.32-0.72
		c0-0.08,0-0.16,0-0.24c-0.24,0-0.48,0-0.72,0c2.383-0.896,4.143-2.417,6.24-3.6c1.694-0.306,1.892-2.108,3.6-2.4
		c0.074-0.326,0.601-0.199,0.96-0.24c0.557-0.492,0.724-1.515,0.48-2.16C235.419,303.24,233.634,302.545,232.56,301.139z"/>
		</g>
		<g id="eye1">
			<circle fillRule="evenodd" clipRule="evenodd" cx="284.84" cy="293.939" r="7.44" />
		</g>
		<circle id="eye2" fillRule="evenodd" clipRule="evenodd" cx="224.851" cy="304.973" r="7.44" />
		<g id="body">
			<g id="gold">
				<path fillRule="evenodd" clipRule="evenodd" fill="#B9AC43" d="M251.76,506.819c3.404-1.052,4.717-5.895,3.12-9.84
			c-0.233-0.007-0.186-0.294-0.24-0.479c-1.202-0.294-2.954-1.13-4.08-0.721c-1.275,0.808-3.231,2.206-2.4,4.32
			c0.03-0.85,0.395-1.365,0.72-1.92c-0.002-0.735,1.053-1.648,1.68-0.96c1.496,0.584,2.112,2.047,3.12,3.12
			c0.731,0.75,0.491,2.303-0.72,2.4c-0.843,0.517-1.863,0.856-2.88,1.199C250.304,505.236,250.939,506.121,251.76,506.819z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#B9AC43" d="M250.08,498.899c-0.218,1.303-0.685,2.355-0.96,3.601
			c1.019-0.501,2.5-0.54,3.12-1.44C251.406,500.454,251.234,499.187,250.08,498.899z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#B9AC43" d="M258.72,576.42c-0.378,0.581-0.858,1.062-1.44,1.44
			c-0.15,0.89-0.675,1.405-0.96,2.159c3.991-0.408,7.675-1.124,11.04-2.159C266.127,575.08,261.404,574.538,258.72,576.42z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#B9AC43" d="M262.32,708.18c-1.877-1.21-5.59-1.274-7.44,0
			c-0.625,0.815-1.406,1.474-1.92,2.4c0.102,0.502-0.165,0.636-0.24,0.96c0,0.96,0,1.92,0,2.88c0.462,3.059,2.042,4.998,4.8,5.76
			c1.014-0.006,1.55,0.465,2.4,0c1.391-0.368,2.46-1.06,3.12-2.159c0.615-0.585,0.881-1.519,1.44-2.16
			c0.509-1.772,0.027-3.392-0.24-5.04C263.848,709.692,262.98,709.04,262.32,708.18z M256.32,717.78
			c-1.665-0.384-2.452-2.317-1.44-4.08c0.1-1.846,0.506-3.261,1.68-3.6c1.563-0.452,5.436,1.971,5.52,3.84
			C262.187,716.306,258.885,717.169,256.32,717.78z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#B9AC43" d="M257.28,712.26c-0.291,1.069-0.429,2.291-0.72,3.36
			c1.16-0.359,2.023-1.017,2.88-1.68C258.667,713.434,258.296,712.524,257.28,712.26z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#B9AC43" d="M252.96,774.66c-0.187-0.214-0.258-0.542-0.24-0.96
			c-0.766-0.994-1.713-1.807-2.64-2.64c-0.591,0.031-1.156,0.036-1.44-0.24c-0.48,0-0.96,0-1.44,0
			c-0.533,0.187-0.936,0.504-1.68,0.48c-0.678,0.602-1.318,1.242-1.92,1.92c-1.029,2.663-2.619,7.481-0.48,9.84
			c0.69,0.761,3.52,1.726,4.56,1.68c3.042-0.134,6.144-3.543,5.76-7.2C253.167,776.693,253.175,775.565,252.96,774.66z
			 M248.16,781.86c-2.01,0.577-4.96-2.57-4.56-4.56c0.32-1.59,5.537-4.783,7.2-2.881C249.88,776.719,250.189,781.277,248.16,781.86z
			"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#B9AC43" d="M245.76,778.261c0.892-0.092,0.627,0.972,1.68,0.72
			c0.106-0.773,0.459-1.301,0.48-2.16C247.083,777.183,246.199,777.499,245.76,778.261z"/>
			</g>
			<g id="red_2">
				<path fillRule="evenodd" clipRule="evenodd" fill="#CC202C" d="M326.881,491.22c0,0.08,0,0.16,0,0.24
			c-1.011,3.069-1.91,6.25-3.12,9.12c0,0.08,0,0.159,0,0.239c-0.461,2.26-1.328,4.112-1.681,6.48c2.501-0.734,5.003,0.158,7.2,0.479
			c0.116-1.244,0.104-2.615,0.48-3.6c0-0.08,0-0.16,0-0.24c0.025-1.813,0.106-3.573,0.479-5.04c0-0.08,0-0.159,0-0.239
			c0.114-4.474,0.716-9.917-0.72-13.44C328.436,487.015,327.938,489.396,326.881,491.22z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#CC202C" d="M328.561,509.46c-0.08,0-0.16,0-0.24,0c-0.24,0-0.479,0-0.72,0
			c-0.22,0.671-0.877,2.149-0.24,2.88c0.101-0.539,1.273-0.007,1.44-0.48c0-0.08,0-0.159,0-0.239
			C328.751,510.869,328.962,509.857,328.561,509.46z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#CC202C" d="M320.16,531.54c-0.153,0.966,0.313,2.552-0.239,3.12
			c0,0.08,0,0.159,0,0.239c-0.347,1.466,0.68,1.561,1.439,1.921c1.102-0.26,0.645-2.076,1.2-2.881c0-0.08,0-0.159,0-0.239
			c-0.011-1.371,0.092-2.629,0.479-3.601c0-0.08,0-0.16,0-0.24c-0.012-1.211,0.094-2.306,0.48-3.119c0-0.08,0-0.16,0-0.24
			c-0.14-1.261,0.423-1.817,0.24-3.12c0.08,0,0.16,0,0.239,0c0.371-0.349,0.212-1.229,0.24-1.92c0.327-3.514,0.521-7.159,1.2-10.32
			c0-0.08,0-0.16,0-0.24c-0.139-0.778,0.163-1.116,0.24-1.68c-0.411-0.549-1.832-0.088-2.641-0.24c-0.079,0-0.159,0-0.239,0
			c-0.045,0.355-0.58,0.221-0.96,0.24c-0.42,0.221-0.53,0.75-0.48,1.44c0.075,0.564,1.138,0.142,0.96,0.96c0,0.08,0,0.159,0,0.239
			c0.34,0.592,0.014,2.566-0.24,3.36c0,0.08,0,0.16,0,0.24c-0.009,1.591-0.102,3.098-0.479,4.319c0,0.08,0,0.16,0,0.24
			c-0.011,1.43-0.104,2.775-0.48,3.84c-0.371,0.35-0.211,1.229-0.239,1.92c-0.177,1.904-0.306,3.854-0.721,5.521
			C320.16,531.38,320.16,531.46,320.16,531.54z"/>
			</g>
			<g id="red_1_">
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M328.08,379.62c0.47,1.01,2.18-0.628,2.641-1.2
			c0.654,0.175,0.482-0.477,0.96-0.48c0.982-0.857,2.592-1.088,2.64-2.88c0-0.08,0-0.16,0-0.24c0.188-0.053,0.279-0.201,0.24-0.48
			c-1.756,0.726-3.621,3.418-5.76,4.56c-0.08,0-0.16,0-0.24,0c-0.241,0-0.286,0.194-0.48,0.24c-4.925,2.944-10.676,5.438-16.08,7.44
			c-3.481,1.291-7.148,1.604-8.399,4.56c8.778-3.062,17.3-6.38,24.479-11.04C327.838,380.097,327.778,379.679,328.08,379.62z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M398.4,388.979c-9.172-2.41-26.23-5.016-33.84,0.72
			c5.916,0.164,11.236,0.924,15.84,2.4C384.892,389.55,391.847,389.465,398.4,388.979z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M144.96,539.46c7.081,1.377,13.585,4.861,19.92,5.76
			c-4.142-2.338-8.44-4.521-12.96-6.479c-1.689-2.537-5.596-4.01-7.92-7.2c-3.385-4.647-5.093-15.681,3.84-13.92
			c0.582,0.697,1.661,0.898,2.16,1.68c6.466,0.747,10.706,5.505,15.6,8.4c2.107,1.246,4.361,2.104,6.48,3.359
			c6.378,3.778,12.421,7.813,19.2,11.04c-1.418-2.716-3.226-6.874-5.52-8.399c-0.844-0.562-2.006-0.524-2.88-0.96
			c-1.611-0.805-2.909-2.324-4.56-3.12c-2.07-0.998-4.867-0.615-6-2.88c3.708-2.489,7.706,1.792,11.04,3.6
			c1.229,0.667,2.777,0.708,3.84,1.44c1.961,1.35,3.253,4.309,4.56,6c0.311,0.401,0.813,1.558,1.44,1.199
			c0.385-4.944,0.362-8.918,0.24-14.159c0.499-1.268,1.994-1.256,2.4-1.44c-0.392-0.568-1.409-0.511-1.92-0.96
			c-5.815-9.385-13.434-18.247-15.84-30.24c5.083,7.142,9.934,16.16,14.64,23.521c0.672,1.051,1.443,2.862,2.16,2.399
			c-1.159-6.965-2.252-12.779-4.08-18.72c-2.155-7.002-6.541-12.963-5.52-21.601c0.921,1.159,1.543,2.617,2.4,3.841
			c1.011-9.911,2.513-20.298,4.08-29.761c0.488-2.945,0.606-7.039,2.88-8.399c0.891,3.392-0.458,6.748-0.96,10.08
			c-1.073,7.115-2.308,13.979-3.12,20.88c-0.422,3.588-1.256,7.962-0.72,11.52c0.79,5.242,3.676,10.521,5.28,16.08
			c1.686,5.842,2.503,11.634,3.84,17.521c1.106-11.965,2.348-25.465,4.08-36.721c0.564-3.663,0.789-7.943,3.6-10.08
			c-1.686,25.379-6.47,48.027-5.52,75.36c9.661,8.339,18.302,17.698,25.44,28.561c1.601,1.195,3.935,2.265,5.76,3.84
			c1.015,0.875,1.558,2.335,2.88,2.64c1.685,0.39,3.391-0.664,5.76-0.479c0-2.08,0-4.16,0-6.24c2.188-1.864,0.732-5.956,2.16-8.4
			c0.388-0.411,1.004-0.596,1.92-0.479c7.581-9.3,16.281-17.479,23.52-27.12c-11.159-13.223-22.383-32.05-31.44-49.2
			c-3.013-5.706-7.389-12.285-7.92-18.96c-0.199-2.499,0.595-3.257,0.72-4.8c0.265-3.271-0.545-7.308-0.72-11.28
			c-0.419-9.526-0.458-19.908,0.96-27.6c2.602-2.759,3.929-6.792,6.24-9.841c-0.307-0.013-0.333-0.308-0.72-0.239
			c-4.024,3.066-5.793,8.362-10.32,11.04c-6.992,4.136-15.945,5.402-25.2,7.68c1.093,2.395-3.168,6.181-5.28,4.56
			c-1.128-2.147,3.518-2.981,2.64-4.08c-17.163,4.828-34.338,12.982-47.76,22.561c-6.139,4.381-11.97,9.117-15.36,15.84
			c-3.771,7.478-3.157,17.791-3.12,30c-0.027,1.013-1.374,0.706-1.2,1.92c-0.016,9.02,2.226,20.613-0.96,27.84
			C134,538.018,139.521,538.402,144.96,539.46z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M227.521,422.1c0.348-0.852,1.986-1.423,1.44-2.4
			c-3.745-0.047-8.05-0.069-11.04,1.2c-1.844,0.783-3.45,2.244-5.28,3.12c-13.624,6.525-30.483,9.825-43.44,16.8
			c16.494-2.313,29.686-7.234,42.48-12.96C217.013,425.473,221.576,421.862,227.521,422.1z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M242.16,490.02c1.412-1.708,3.298-2.942,3.84-5.52
			c5.56-4.666,10.656-9.12,16.56-14.16c4.136-3.531,7.962-7.205,12.48-10.561c1.23-0.913,4.124-2.625,4.32-3.6
			c0.303-1.503-1.95-5.967-2.64-8.16c-2.65-8.425-5.713-18.711-7.44-26.16c-1.018-0.374-2.403,0.736-2.88,1.681
			c1.757,7.836,4.776,15.076,7.2,22.56c0.691,2.136,2.553,6.102,2.16,7.92c-0.411,1.899-5.644,5.199-7.2,6.48
			c-2.699,2.222-5.624,4.016-7.92,6c-3.558,3.074-6.268,7.264-9.84,10.56c-3.745,3.456-7.844,6.201-10.8,9.84
			C240.965,487.694,240.806,489.614,242.16,490.02z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M166.32,442.02c-6.266,2.614-12.129,5.631-14.4,12.24
			c4.208-2.193,7.963-4.837,12.24-6.96C163.223,444.748,165.326,443.414,166.32,442.02z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M326.16,521.939c11.513-0.121,20.766-2.704,32.641-1.199
			c1.833-2.008,3.444-4.235,4.8-6.721c-12.608-0.049-24.885,0.235-36.96,0.721C326.737,517.396,326.063,519.283,326.16,521.939z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M319.44,521.939c0.482-1.941,0.889-5.157,0.72-6.96
			c-4.56,0-9.12,0-13.68,0c-0.44,2.28-0.87,4.57-0.96,7.2C309.802,521.741,314.795,522.014,319.44,521.939z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M421.2,520.5c4.514,0.134,8.999-0.604,12.001-1.44
			c-4.475,0.486-9.443,0.477-13.681,1.2C418.929,520.734,420.634,520.483,421.2,520.5z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M299.28,561.3c2.369,0.106,5.105-1.001,8.16-1.68
			c5.322-1.183,9.507-2.56,12.48,0.479c-0.264,1.12,0.841,2.318,1.439,3.12c2.313,3.098,4.143,6.52,5.76,10.08
			c2.768-1.232,6.465-1.536,9.12-2.88c0.943-5.777,3.537-9.903,5.04-15.12c3.597-4.598,5.634-11.945,7.681-18.24
			c1.873-5.765,4.818-10.063,7.92-14.399c-11.062-1.967-19.689,1.39-30.96,1.199c-1.938,2.979-0.203,13.111-3.12,14.641
			c-1.429,0.749-3.771-0.202-4.32-1.2c-1.94-3.521,1.42-9.925,0.72-13.44c-4.467-0.493-10.231-0.351-14.16,0.48
			C303.489,537.029,301.857,549.637,299.28,561.3z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M185.76,678.9c0.695-0.244,1.673-0.04,2.4-0.24
			c2.959-0.814,5.885-2.498,8.88-3.6c3.063-1.128,6.197-2.191,9.36-3.12c11.716-3.439,23.611-7.153,35.76-9.841
			c8.423-1.863,16.344-2.877,24-5.039c8.314-2.349,15.991-6.918,24.24-9.841c0.525-9.781-0.839-19.953-1.92-29.76
			c-0.595-5.397-2.152-10.688-3.12-15.84c-0.375-1.998-0.325-4.037-0.72-5.76c-0.618-2.695-2.855-5.712-4.32-8.16
			c-1.647-2.754-2.991-5.981-5.52-7.2c-21.691,5.101-47.087,7.4-69.12,11.28c-11.339,1.996-22.602,4.618-29.76,10.56
			c-5.516,4.579-9.746,10.839-13.68,16.561c-1.109,1.613-2.515,4.749-5.28,4.319c-0.366-2.396,1.366-4.616,2.64-6.479
			c3.236-4.732,7.043-9.337,10.56-13.44c0.652-0.76,1.792-1.443,1.68-2.399c-13.185,5.447-21.078,15.847-26.88,28.319
			c-0.8,1.721-1.498,4.584-4.08,4.8c-0.978-2.182,0.844-4.408,1.68-6.239c4.738-10.375,10.489-18.776,19.68-24.721
			c3.571-2.31,8.233-5.17,12-5.76c2.222-0.348,3.676,0.561,5.28-1.439c-17.45,0.13-28.884,6.563-37.68,14.88
			c-1.139,1.076-2.166,3.425-4.32,2.88c-1.817-1.664,0.537-3.417,1.44-4.32c9.765-9.766,23.349-16.568,41.52-16.8
			c-2.917-1.195-6.851-1.963-10.32-2.16c-2.057-0.117-3.765-1.361-5.76-1.68c-1.116-0.179-2.412,0.148-3.6,0
			c-3.849-0.481-7.921-2.766-11.28-4.561c-5.074-2.711-9.106-5.92-8.4-12.479c-3.25-2.652-7.854-3.336-11.52-5.28
			c-4.09-2.17-5.853-6.318-6.48-12c-2.106,6.881-4.393,12.727-7.68,18c-1.073,1.722-2.709,3.435-3.36,5.28
			c-0.833,2.361-1.213,5.64-1.44,8.399c-0.238,2.894,0.427,6.266-0.24,9.601c-0.416,2.078-2.422,4.147-2.4,7.2
			c3.184-0.514,5.839-4.864,9.6-4.801c-3.902,6.498-12.114,8.687-13.92,17.28c1.173-1.471,5.808-7.841,7.2-3.359
			c1.226,3.945-7.346,8.48-7.44,12.72c3.071-2.219,5.551-5.311,8.4-8.16c5.804-5.804,11.006-11.909,17.04-17.28
			c2.953-2.628,5.674-6.317,9.84-6.96c-1.836,3.801-5.682,6.323-8.88,9.12c-3.153,2.759-6.229,5.767-9.36,8.64
			c-6.377,5.854-13.392,11.468-17.04,19.2c-2.674,5.669-1.114,13.344-0.24,19.44c1.91,13.329,4.753,24.43,7.92,36
			c1.246,4.553,3.258,8.841,4.08,13.44c2.969,1.214,6.822,1.764,11.28,1.439c8.032-0.584,17.147-3.392,23.28-6.479
			c8.63-4.346,19.632-7.648,29.04-11.04C181.274,681.128,183.546,679.677,185.76,678.9z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M275.28,570.42c-0.708,1.852-0.55,4.57-1.44,6.24
			c1.708-0.532,3.863-0.617,5.04-1.681C277.496,573.645,277.174,571.246,275.28,570.42z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M194.4,693.061c-2.683-0.054-5.006,1.814-7.68,2.64
			c0,1.44,0,2.88,0,4.32c5.487-0.071,9.633,2.106,14.4,3.84c3.825,1.391,7.727,2.388,11.76,3.359
			c8.045,1.939,15.746,4.84,24.24,6.001c0.542-3.833,2.691-7.571,3.12-11.761c0.91-8.878-1.995-18.282-0.72-27.36
			c-0.014-0.146-0.036-0.283-0.24-0.239c-0.014-0.146-0.036-0.284-0.24-0.24c-9.124,3.355-17.125,7.835-26.64,10.8
			c2.785,0.42,6.255,1.262,9.84,1.44c2.562,0.127,6.024-0.796,7.68,0.96c-1.409,1.855-5.342,1.717-7.92,1.68
			c-10.394-0.149-20.797-4.731-32.16-4.32c-0.789,1.291-2.379,1.781-3.36,2.881c2.621,1.139,6.288,1.232,9.36,1.92
			c0.827,0.452,0.598,1.961,1.68,2.16c4.139,2.661,11.914,2.965,14.16,6.72C205.793,699.501,199.48,693.161,194.4,693.061z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M207.6,788.34c2.268-2.564,6.594-2.529,10.32-3.359
			c2.796-0.623,5.352-1.693,7.92-2.4c0.88-0.242,2.548,0.531,2.4-1.2c-6.235-0.462-12.458-0.528-18.48-0.96
			c-4.198-0.301-8.936,0.025-11.28-2.159c9.12-1.865,20.045,0.122,30-0.24c0.541-10.472-2.314-22.113,0.48-32.4
			c1.273-4.69,3.341-8.918,4.32-13.68c1.033-5.028,1.187-10.093,2.64-15.36c-15.604-3.516-30.345-7.895-44.4-12.96
			c6.151,6.099,15.468,13.557,24.48,18.48c3.27,1.786,6.934,4.063,10.08,4.8c1.819,0.425,4.923-0.411,5.52,2.16
			c-1.835,2.17-5.201,1.09-7.2,0.479c-6.6-2.016-12.459-6.59-17.52-9.84c-6.441-4.136-12.869-8.896-18-13.92
			c-0.599-0.586-1.087-1.867-2.16-1.68c0.501,13.19-4.134,19.237-10.08,25.68c-1.187,1.286-3.574,2.29-4.32,3.84
			c-1.008,2.095,0.098,4.74-0.96,6.72c5.524,9.702-1.841,19.229-2.64,29.761c-0.607,7.999,2.463,16.897,1.68,24.479
			c-0.34,3.29-2.111,5.383-1.92,7.92c17.708-0.739,36.935,1.071,55.44,0.48c0.834-6.286,2.369-11.871,3.6-17.76
			C222.068,786.094,214.183,790.169,207.6,788.34z M175.44,741.061c1.697-1.207,4.769,0.599,6.96,1.439
			c11.763,4.517,21.045,11.838,30.72,18.48c1.877,1.289,3.975,2.679,6,3.84c1.877,1.075,5.061,1.714,5.28,4.56
			c-4.233,0.281-7.637-2.769-10.8-4.8c-3.278-2.104-6.323-4.483-9.36-6.72c-6.264-4.613-13.106-8.637-20.16-12
			C181.296,744.533,176.921,743.434,175.44,741.061z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M173.76,804.42c-0.554,0.727,1.076,1.315,0.72,2.641
			c-0.832,2.048-3.255,2.505-4.8,3.84c17.433,0.553,35.516-0.324,53.52,0c0.298-1.862,0.333-3.986,0.48-6
			C206.91,805.858,190.147,805.106,173.76,804.42z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M439.201,399.54c-8.252-2.534-17.121-5.237-26.4-6.48
			c10.308,4.252,21.632,7.488,33.84,9.84C445.1,400.52,441.728,400.315,439.201,399.54z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M412.801,393.06C412.752,392.341,411.804,393.289,412.801,393.06
			L412.801,393.06z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M293.28,603.54c3.244,12.988,6.555,27.55,7.2,41.76
			c0.167,3.683,0.187,7.625-0.96,10.32c8.826-1.975,16.914-4.686,24.96-7.44c0.16,0,0.32,0,0.48,0
			c0.152-0.486,1.121-0.158,1.199-0.72c0.08,0,0.16,0,0.24,0c0.333-0.388,0.68-0.76,0.96-1.2c0.216-0.424,0.579-0.7,0.72-1.199
			c0.085-0.315,0.423-0.378,0.48-0.721c0.581-0.938,0.785-2.255,1.2-3.359c0.296-1.385,0.746-2.614,0.96-4.08
			c0.417-2.703,0.955-5.285,0.96-8.4c0.547-0.333,0.089-1.671,0.24-2.4c0-0.08,0-0.159,0-0.239c0.552-0.568,0.086-2.154,0.239-3.12
			c0-0.08,0-0.16,0-0.24c0.553-0.568,0.086-2.154,0.24-3.12c0-0.24,0-0.479,0-0.72c0-0.16,0-0.32,0-0.48
			c0.557-1.203,0.083-3.438,0.24-5.04c-0.08,0-0.16,0-0.24,0c0-0.159,0-0.319,0-0.479c0-1.44,0-2.88,0-4.32
			c0.021-0.341,0.04-0.68-0.24-0.72c-0.191-5.089-0.99-9.569-1.92-13.92c-0.085-1.115-0.308-2.092-0.72-2.88
			c-0.046-1.715-0.829-2.691-0.96-4.32c-0.758-1.242-0.93-3.07-1.68-4.32c0.039-0.759-0.639-0.802-0.48-1.68
			c-0.492-0.388-0.605-1.155-0.96-1.68c0.03-0.431-0.294-0.507-0.24-0.96c-0.538-0.263-0.422-1.179-0.96-1.44
			c0.037-0.597-0.377-0.743-0.479-1.2c-0.125-0.354-0.355-0.604-0.48-0.96c-0.046-0.272-0.24-0.133-0.24,0
			c-0.562-0.062-0.138-1.055,0-1.2c-0.303-1.303-1.627-1.774-2.159-2.88c-0.319-0.661-0.148-1.437-0.48-2.16
			c-1.046-2.276-4.016-3.762-3.84-6.96c-12.25,2.551-23.602,5.999-36.96,7.44C285.158,577.953,289.923,590.1,293.28,603.54z
			 M326.16,644.34c0.146-0.302,0.285-0.62,0.423-0.947C326.445,643.721,326.306,644.038,326.16,644.34z M330.296,611.436
			c0.009,0.243,0.016,0.488,0.023,0.733C330.312,611.924,330.305,611.679,330.296,611.436z M330.13,608.146
			c0.016,0.243,0.03,0.487,0.044,0.732C330.16,608.634,330.145,608.39,330.13,608.146z M329.871,604.865
			c0.026,0.273,0.053,0.545,0.076,0.82C329.923,605.41,329.897,605.139,329.871,604.865z M330.014,606.515
			c0.02,0.252,0.039,0.504,0.058,0.758C330.053,607.019,330.033,606.767,330.014,606.515z M329.644,602.668
			c0.056,0.48,0.109,0.967,0.159,1.46C329.752,603.636,329.699,603.148,329.644,602.668z M326.809,642.822
			c0.051-0.132,0.104-0.263,0.153-0.397C326.912,642.561,326.859,642.689,326.809,642.822z M327.26,641.575
			c0.016-0.044,0.03-0.089,0.045-0.134C327.29,641.486,327.274,641.53,327.26,641.575z M330.386,616.333
			c0-0.28-0.003-0.561-0.005-0.841c0.002,0.274,0.005,0.55,0.005,0.825C330.386,616.322,330.386,616.328,330.386,616.333z
			 M330.348,613.104c0.005,0.243,0.009,0.487,0.014,0.731C330.356,613.591,330.354,613.347,330.348,613.104z M330.258,610.516
			c-0.012-0.243-0.022-0.486-0.034-0.729C330.235,610.029,330.246,610.272,330.258,610.516z M329.569,602.07
			c-0.037-0.303-0.075-0.606-0.114-0.904C329.494,601.464,329.532,601.768,329.569,602.07z M328.653,596.396
			c-0.06-0.289-0.122-0.569-0.185-0.849C328.531,595.826,328.594,596.106,328.653,596.396z M329.367,600.543
			c-0.04-0.279-0.08-0.558-0.122-0.831C329.287,599.985,329.327,600.264,329.367,600.543z M329.148,599.099
			c-0.044-0.271-0.089-0.54-0.135-0.806C329.06,598.559,329.104,598.827,329.148,599.099z M328.911,597.719
			c-0.05-0.271-0.101-0.537-0.153-0.802C328.811,597.182,328.861,597.447,328.911,597.719z M328.377,595.137
			c-0.096-0.409-0.194-0.811-0.297-1.197C328.183,594.326,328.281,594.728,328.377,595.137z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M247.2,770.58c0,0.08,0,0.16,0,0.24c0.48,0,0.96,0,1.44,0
			c0-0.08,0-0.16,0-0.24C248.68,770.187,247.161,770.187,247.2,770.58z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M476.4,491.22c0-0.16,0-0.32,0-0.479
			c0.56-3.281,0.081-7.6,0.24-11.28c-0.131-0.349,0.28-1.24-0.24-1.2c0-0.16,0-0.32,0-0.48c0-0.319,0-0.64,0-0.96
			c0-0.8,0-1.6,0-2.399c0.039-0.279-0.053-0.428-0.239-0.48c0.037-1.877-0.195-3.484-0.48-5.04c-0.111-1.647-0.378-3.143-0.72-4.56
			c-0.235-2.085-0.783-3.857-1.2-5.76c-0.27-1.49-0.614-2.906-0.96-4.32c-0.376-2.264-1.028-4.252-1.44-6.48
			c-0.987-2.612-1.767-5.433-2.88-7.92c-0.04-0.68-0.439-1-0.479-1.68c-0.796-1.204-1.021-2.98-1.92-4.08
			c-0.085-0.315-0.423-0.377-0.48-0.72c-0.519-0.602-0.757-1.483-1.439-1.92c-1.896-3.704-3.861-7.34-6-10.801
			c-1.259-2.182-2.665-4.215-4.801-5.52c-0.314-0.085-0.377-0.422-0.72-0.48c-1.093-0.667-2.675-0.846-3.6-1.68
			c-1.506-0.254-2.65-0.87-2.641-2.64c0.521-0.759,1.145-1.416,2.641-1.2c1.011,0.349,1.905,0.814,3.12,0.96
			c0.157,0.322,0.513,0.447,0.96,0.48c0.476-0.338-0.933-0.812-1.2-1.2c-0.08,0-0.16,0-0.24,0c-0.461-0.819-1.438-1.121-2.4-1.44
			c-1.088-0.511-2.22-0.98-3.6-1.2c-1.274-0.646-2.887-0.954-4.56-1.2c-1.111-0.329-2.104-0.776-3.601-0.72c-0.08,0-0.16,0-0.24,0
			c-0.233,0.054-0.441,0.11-0.635,0.169c0.193-0.059,0.401-0.115,0.635-0.169c-4.413-1.952-9.295-1.706-14.159-1.92
			c-14.288-0.628-28.589-2.972-41.041-6.24c-4.357-1.144-8.547-2.577-12.72-2.88c-5.37-0.391-10.293,0.213-14.88-1.68
			c-13.102,11.842-21.765,30.894-28.56,50.64c-1.42,4.126-3.511,8.437-1.921,11.761c-0.303,2.047-1.757,2.604-2.399,4.08
			c-1.085,2.489-0.287,4.912-0.48,8.159c-0.309,5.206-2.991,13.128-5.279,17.28c-2.641,4.791-6.149,9.355-9.12,13.44
			c-15.81,21.73-31.512,42.834-50.16,62.64c-1.656,1.759-3.542,3.607-5.52,5.76c-1.603,1.744-4.771,4.262-5.28,5.761
			c-1.106,3.254,0.121,7.777-0.96,10.56c2.8-0.239,5.275-0.805,7.92-1.2c0.126-7.966,10.59-12.198,16.08-6.96
			c1.04,0.993,1.275,3.5,3.36,3.12c-1.723-2.794,0.407-8.451,1.44-11.52c8.52-0.44,15.831-2.089,23.28-3.601
			c1.083-8.025,3.113-15.82,4.079-24.239c0.952-8.295,1.907-16.664,3.36-24.721c3.976-0.904,9.674-0.087,13.92-0.72
			c1.818-8.091,4.035-17.027,7.44-25.2c0.736-1.767,2.33-5.247,4.08-5.28c3.5-0.065,3.354,7.702,3.359,11.04
			c0.01,5.235-0.918,9.985-1.439,14.16c-0.255,1.215,0.709,1.211,0.96,1.92c-0.095,1.266-1.768,0.953-1.44,2.641
			c9.739,0.738,22.825-0.729,33.36,0c1.532-3.317,3.207-6.885,4.32-11.04c1.405-5.251,2.234-11.465,5.279-14.641
			c-1.02-11.038,0.778-21.287,4.08-28.8c0.253,5.532-2.046,12.059-2.399,18.96c-0.163,3.18-0.049,6.514,0.239,9.84
			c1.966,1.314,3.579,2.981,4.08,5.761c0.744-7.006,4.466-13.877,5.761-20.16c0.616-2.991,0.382-5.795,0.96-8.4
			c2.534-11.431,8.631-22.269,14.64-30.96c2.415-3.493,4.906-7.285,8.64-9.6c-8.099,16.723-19.845,29.595-21.359,52.319
			c-0.319,0.642-1.605,0.315-2.16,0.721c-0.769,3.151-1.829,6.011-2.64,9.119c1.525-2.086,3.44-4.884,5.52-7.439
			c1.792-2.204,3.922-5.728,7.44-5.28c0.294,2.767-2.348,4.358-3.84,6c-3.241,3.563-5.279,7.703-7.681,12.48
			c-1.067,2.122-2.72,4.064-3.84,6.24c-2.981,5.79-3.636,12.78-6,19.439c1.749-0.198,2.909-2.386,4.56-3.12
			c2.191-0.974,5.388-0.821,8.4-1.2c7.9-0.992,16.276-3.499,22.08-6.239c0.68,0.039-0.199-1.479,0.48-1.44
			c4.946-0.338,9.909-3.603,14.399-5.76c1.771-0.851,3.374-2.814,5.521-2.4c0.352,2.699-2.909,3.803-5.04,4.8
			c-2.339,1.095-4.979,2.002-6.721,3.36c6.733,2.106,8.99-2.281,12.48-5.52c1.558-1.445,3.947-2.761,5.76-3.841
			c1.688-1.005,4.711-3.104,6.72-0.96c-0.553,1.646-2.618,1.577-3.84,2.16c-4.054,1.937-7.609,4.719-10.319,8.16
			c4.468,0.502,8.91-0.848,12.239,0.48c2.712,1.081,5.705,6.802,3.36,9.6c-1.554,1.854-8.153,3.438-11.04,4.32
			c7.933,1.188,21.91-5.098,25.2,1.92c0.54,1.151,0.211,2.97,0.72,4.32c-0.758,0.122-0.664,1.096-0.96,1.68
			c-0.391,0.729-1.058,1.183-0.96,2.399c0,0.16,0,0.32,0,0.48c-0.346-0.055-0.084-0.717-0.479-0.72
			c-3.889,5.312-11.683,6.718-18.721,8.88c-0.73-0.091-0.407,0.872-0.96,0.96c-7.267,1.373-15.545,1.734-23.28,2.64
			c7.984,0.339,16.054,5.362,25.44,4.8c5.632-0.337,14.731-3.026,15.84,2.881c0.505,2.687-1.286,5.993-2.64,7.439
			c-4.682,5.005-15.117,6.485-24,6.24c0.704,0.344,1.305,0.934,0.72,1.92c4.041,2.359,12.735,0.065,12.48,6.72
			c2.098,0.986,6.544,0.756,5.76,3.84c-2.688,0.743-4.113-2.182-6.96-1.68c-1.263,0.223-2.328,2.408-3.601,3.12
			c-4.39,2.458-10.589,1.854-16.079,1.44c3.844,3.836,9.106,6.253,13.92,9.119c-1.725,2.65-4.744,0.076-6.48-0.96
			c-6.216-3.708-12.473-8.866-18.479-12.479c-5.977,3.304-9.199,9.361-14.4,13.439c3.163,2.691,6.95,5.991,6.72,11.28
			c-2.4,1.141-3.035-1.68-3.84-2.88c-1.506-2.245-3.549-4.142-6-5.28c-2.571,1.189-4.398,3.121-6.24,5.04
			c18.842,8.119,32.247,21.674,37.681,43.2c-1.524-0.078-2.188-3.091-3.36-4.32c-0.261-0.059-0.163-0.477-0.48-0.479
			c-0.033-1.406-0.926-1.954-0.96-3.36c-0.224-0.176-0.363-0.437-0.479-0.72c0.03-0.431-0.294-0.506-0.24-0.96
			c-0.69-0.509-0.621-1.779-1.439-2.16c-1.445-3.035-3.623-5.338-5.521-7.92c-0.414-0.786-1.267-1.134-1.68-1.92
			c-1.067-0.772-2.125-1.555-2.88-2.64c-1.202-0.719-2.037-1.804-3.12-2.641c-1.084-0.756-1.799-1.881-3.12-2.399
			c-0.107-0.396-1.033-1.177-1.2-0.721c0.369,0.322,0.72,0.669,1.064,1.025c-0.348-0.353-0.695-0.703-1.064-1.025
			c-2.152-1.891-4.584-3.547-7.439-5.279c-2.263-1.373-6.142-4.031-8.641-3.84c-2.463,0.188-4.517,3.968-6.72,5.76
			c-3.148,2.561-7.236,4.125-10.8,6.479c-6.979,0.347-11.915,3.201-17.761,5.04c-4.357,1.371-8.563,2.532-12.96,4.08
			c-0.386,8.414-1.484,16.116-2.88,23.521c-2.26,7.065-11.256,8.726-18.72,11.04c-4.87,1.509-9.813,2.576-14.16,3.84
			c15.324,7.332,35.037,11.026,55.44,15.12c14.326,2.874,30.54,4.14,46.08,6.72c2.48,0.412,4.868,0.908,7.199,1.68
			c2.157,0.715,4.526,2.03,6.48,2.16c4.226,0.282,7.152-2.459,9.84-4.32c2.138,0.561,2.955,0.396,3.601-1.439
			c-0.558,0.162-0.643,0.798-1.2,0.96c0.664-0.775,1.308-1.572,1.92-2.4c1.274-1.524,2.17-3.43,3.12-5.279
			c0.37-0.51,0.69-1.069,0.96-1.681c0.127-0.192,0.25-0.39,0.24-0.72c0.787-0.493,0.916-1.644,1.439-2.4
			c1.651-3.148,3.673-5.927,5.04-9.359c0.838-1.242,2.032-2.128,2.64-3.601c1.602-1.758,1.587-5.133,2.641-7.439
			c0.336-1.024,0.53-2.189,0.96-3.12c0.22-1.221,0.841-2.039,0.96-3.36c0.204,0.045,0.227-0.094,0.24-0.24
			c0.18-0.779,0.435-1.485,0.72-2.159c0-0.08,0-0.16,0-0.24c0.127-0.193,0.25-0.39,0.24-0.72c0.491-0.389,0.604-1.155,0.96-1.681
			c0.449-1.07,1.124-1.915,1.439-3.12c0.257-0.543,0.545-1.055,0.96-1.439c1.788-2.612,3.117-5.684,3.601-9.601
			c0.412-0.788,0.635-1.765,0.72-2.88c0.582-1.658,0.984-3.495,1.44-5.279c0.267-1.174,0.523-2.357,0.72-3.601
			c0.42-2.38,0.885-4.715,1.2-7.2c0.514-4.205,1.292-8.147,1.439-12.72c0.551-0.489,0.087-1.993,0.24-2.88c0-0.08,0-0.16,0-0.24
			c0.553-0.646,0.085-2.314,0.24-3.359c0-0.16,0-0.32,0-0.48c0.552-0.568,0.086-2.154,0.24-3.12c0-0.08,0-0.16,0-0.24
			c0.556-1.044,0.082-3.116,0.239-4.56c0-0.16,0-0.32,0-0.48c0.551-0.489,0.087-1.993,0.24-2.88c0.586-2.774,0.737-5.982,1.44-8.64
			c0.094-0.865,0.311-1.609,0.479-2.4c0.211-0.829,0.505-1.575,1.2-1.92c0.283-0.276,0.849-0.271,1.44-0.24
			c0.632,0.073,0.787-0.332,0.72-0.96c0.191-0.448,0.4-0.879,0.479-1.439c0-0.24,0-0.48,0-0.72c0.398-0.414,0.398-2.467,0-2.881
			c0-0.159,0-0.319,0-0.479c0-1.04,0-2.08,0-3.12c-0.146-0.574,0.302-1.741-0.239-1.92c0-0.16,0-0.32,0-0.48c0-0.239,0-0.479,0-0.72
			c-0.155-1.045,0.313-2.713-0.24-3.36c-0.05-2.77,0.806-4.634,0.96-7.199c0.281-0.039,0.202-0.438,0.24-0.721
			c0.146-0.573-0.302-1.741,0.239-1.92c0.237-2.083,0.55-4.091,1.2-5.76c0.236-0.563,0.421-1.179,0.48-1.92
			c0.73-1.749,0.979-3.98,1.439-6c0.063-1.137,0.551-1.85,0.48-3.12c0.281-0.039,0.202-0.438,0.24-0.72
			c0.076-0.563,0.379-0.901,0.239-1.681c0.187-0.213,0.259-0.542,0.24-0.96c0.068-0.972,0.412-1.668,0.48-2.64
			c0.38-2.1,0.761-4.198,0.96-6.479c0.371-2.909,0.972-5.588,0.96-8.881C476.71,493.449,476.249,492.027,476.4,491.22z
			 M411.95,627.902c-0.012-0.026-0.022-0.053-0.033-0.078C411.928,627.85,411.938,627.876,411.95,627.902z M405.391,614.012
			c-0.248-0.402-0.504-0.793-0.765-1.18C404.886,613.219,405.143,613.609,405.391,614.012z M408.215,619.304
			c-0.188-0.396-0.375-0.789-0.566-1.178C407.84,618.514,408.027,618.908,408.215,619.304z M407.278,617.396
			c-0.173-0.339-0.348-0.676-0.525-1.008C406.931,616.72,407.105,617.057,407.278,617.396z M406.354,615.657
			c-0.187-0.334-0.377-0.661-0.569-0.986C405.977,614.996,406.167,615.323,406.354,615.657z M404.375,612.452
			c-0.005-0.007-0.011-0.014-0.016-0.021c0.003,0.003,0.006,0.007,0.009,0.01C404.37,612.445,404.373,612.448,404.375,612.452z
			 M408.608,620.146c0.224,0.485,0.444,0.974,0.662,1.467C409.053,621.119,408.832,620.631,408.608,620.146z M410.807,625.191
			c-0.081-0.193-0.162-0.386-0.244-0.578C410.645,624.806,410.726,624.998,410.807,625.191z M410.071,623.459
			c-0.129-0.303-0.26-0.604-0.39-0.904C409.812,622.855,409.942,623.156,410.071,623.459z M415.395,635.365
			c0.099,0.188,0.195,0.378,0.295,0.564C415.59,635.743,415.493,635.553,415.395,635.365z M412.794,629.868
			c0.039,0.089,0.079,0.177,0.117,0.266C412.872,630.045,412.833,629.957,412.794,629.868z M413.604,631.687
			c0.092,0.202,0.185,0.401,0.277,0.602C413.789,632.088,413.696,631.889,413.604,631.687z M414.462,633.514
			c0.104,0.216,0.209,0.431,0.315,0.644C414.671,633.944,414.566,633.729,414.462,633.514z M464.118,484.009
			c0.002,0.025,0.004,0.051,0.006,0.076C464.122,484.06,464.12,484.034,464.118,484.009z M463.356,479.436
			c0,0.001,0.001,0.002,0.001,0.003v0.001C463.357,479.438,463.357,479.438,463.356,479.436z M435.596,405.43 M436.486,405.04
			c-0.136,0.044-0.262,0.09-0.377,0.137C436.225,405.13,436.351,405.084,436.486,405.04z M435.979,405.232 M458.659,562.224
			c-0.03,0.191-0.051,0.394-0.055,0.611C458.608,562.617,458.629,562.415,458.659,562.224z M458.603,562.871
			c-0.004,0.244,0.006,0.501,0.019,0.76C458.608,563.372,458.599,563.115,458.603,562.871z M458.664,564.522
			c0,0.001,0,0.003,0,0.005c-0.001-0.027-0.003-0.055-0.004-0.081C458.661,564.472,458.663,564.497,458.664,564.522z
			 M438.256,629.52c-0.065-0.19-0.141-0.353-0.225-0.49C438.115,629.168,438.19,629.329,438.256,629.52z M438.511,631.184
			c-0.009-0.321-0.035-0.608-0.073-0.87C438.476,630.575,438.502,630.862,438.511,631.184z M438.43,630.247
			c-0.04-0.251-0.093-0.471-0.156-0.667C438.337,629.776,438.39,629.996,438.43,630.247z M438.008,628.987
			c-0.086-0.135-0.181-0.244-0.281-0.33C437.827,628.743,437.922,628.852,438.008,628.987z M437.359,628.454
			c-0.117-0.033-0.237-0.043-0.359-0.029C437.122,628.411,437.242,628.42,437.359,628.454z M437.703,628.637
			c-0.104-0.083-0.213-0.142-0.326-0.177C437.49,628.495,437.6,628.553,437.703,628.637z M436.616,628.541
			c0.124-0.061,0.25-0.1,0.374-0.115C436.866,628.441,436.74,628.48,436.616,628.541z M436.801,628.5
			c-0.07,0.007-0.126,0.028-0.188,0.043c0.001,0,0.002-0.001,0.003-0.001C436.677,628.527,436.732,628.507,436.801,628.5z
			 M435.766,629.346c0,0.002-0.001,0.003-0.002,0.005c-0.075,0.114-0.146,0.241-0.211,0.374
			C435.619,629.59,435.689,629.462,435.766,629.346z M435.055,632.002c0,0.193,0.005,0.39,0.023,0.593
			C435.06,632.392,435.055,632.195,435.055,632.002z M435.198,633.371c0.044,0.213,0.095,0.428,0.162,0.648
			C435.293,633.799,435.242,633.584,435.198,633.371z M435.08,632.606c0.018,0.196,0.049,0.398,0.087,0.604
			C435.129,633.005,435.098,632.803,435.08,632.606z M435.205,630.695c0.08-0.334,0.188-0.639,0.317-0.909
			C435.394,630.057,435.285,630.361,435.205,630.695z M435.057,631.867c0.005-0.188,0.015-0.372,0.036-0.549
			C435.071,631.494,435.062,631.68,435.057,631.867z M435.093,631.317c0.021-0.184,0.054-0.357,0.091-0.527
			C435.146,630.96,435.114,631.135,435.093,631.317z M412.561,576.42c2.505,7.098,11.992,8.485,14.16,15.12
			c-3.979-0.915-7.019-3.988-9.84-6.24c-2.848-2.272-6.245-4.848-7.2-8.88C410.448,575.467,411.684,575.745,412.561,576.42z
			 M405.841,593.46c0.388-1.132,1.373-1.667,2.16-2.4c7.409,1.552,15.252,2.668,19.68,7.2
			C420.442,597.575,414.699,593.324,405.841,593.46z M425.401,673.488c-0.656,2.283-0.928,4.508,0.101,5.551l0,0
			C424.473,677.994,424.739,675.777,425.401,673.488z M426.938,669.219c-0.045,0.11-0.092,0.227-0.138,0.341
			C426.848,669.444,426.894,669.33,426.938,669.219z M426.652,669.929c-0.061,0.152-0.122,0.308-0.184,0.466
			C426.53,670.236,426.591,670.082,426.652,669.929z M426.369,670.654c-0.344,0.896-0.688,1.863-0.965,2.823
			C425.684,672.516,426.023,671.551,426.369,670.654z M425.635,679.169c-0.007-0.003-0.014-0.003-0.021-0.005v-0.001
			C425.621,679.166,425.628,679.166,425.635,679.169z M425.615,679.164c0.063,0.054,0.144,0.093,0.215,0.139
			C425.758,679.257,425.679,679.218,425.615,679.164z M426.812,679.63c0.087,0.01,0.17,0.023,0.263,0.027
			C426.98,679.653,426.898,679.64,426.812,679.63z M426.124,679.461c0.081,0.034,0.17,0.061,0.26,0.086
			C426.294,679.521,426.205,679.495,426.124,679.461z M440.881,645.061c-0.842,2.678-1.12,5.919-1.92,8.64
			c-0.202,0.278-0.316,0.644-0.24,1.2c-1.113,1.286-0.903,3.896-3.36,3.84c-0.989-0.43-0.349-2.524,0-3.12
			c0.316-1.284,0.645-2.556,0.96-3.84c0.128-0.673,0.353-1.248,0.48-1.92c0.701-4.02,1.278-8.162,1.68-12.48c0-1.04,0-2.08,0-3.12
			c0-0.64,0-1.279,0-1.92c0.029-0.404,0.037-0.771,0.03-1.106c0.007,0.336-0.001,0.702-0.03,1.106
			c0.537,0.104,0.098,1.183,0.24,1.68c0.283-2.824,0.448-6.154,0.72-9.119c0.138-1.505-0.208-3.511,0.96-4.32
			c1.122,1.252,0.876,3.426,0.96,5.28c0.285,6.267-0.38,13.749-0.479,18.96C440.881,644.9,440.881,644.98,440.881,645.061z
			 M445.681,620.82c0.004-0.087,0.004-0.176,0.008-0.264l0,0C445.685,620.645,445.685,620.732,445.681,620.82z M445.702,614.892
			c-0.003-0.134-0.007-0.269-0.011-0.402c0.004,0.126,0.008,0.252,0.011,0.378C445.702,614.875,445.702,614.884,445.702,614.892z
			 M445.64,612.626c-0.162-5.381-0.331-10.647,0.95-14.806c-0.027,0.105-0.057,0.207-0.082,0.314
			C445.326,602.243,445.484,607.379,445.64,612.626z M447.388,595.795c0.114-0.235,0.233-0.466,0.361-0.69
			c-0.125,0.227-0.247,0.454-0.36,0.688C447.389,595.793,447.388,595.794,447.388,595.795z M458.641,565.62
			c0.019-0.154,0.027-0.319,0.031-0.489c0,0.001,0,0.001,0,0.002C458.668,565.302,458.659,565.466,458.641,565.62z M458.85,562.149
			C458.85,562.149,458.85,562.149,458.85,562.149c0-0.001,0.001-0.002,0.001-0.003C458.851,562.147,458.85,562.148,458.85,562.149z
			 M458.688,562.069c0.043-0.197,0.1-0.382,0.192-0.529C458.788,561.688,458.731,561.872,458.688,562.069z M461.761,558.9
			c0.394-1.578,0.756-3.187,1.2-4.713c0,0.003-0.001,0.007-0.002,0.01C462.515,555.721,462.153,557.325,461.761,558.9z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M451.921,404.819c0.716,0.404,1.289,0.951,2.16,1.2
			c0.275,0.844,1.36,0.879,1.68,1.68c0.315,0.084,0.378,0.422,0.72,0.48c1.405,0.995,2.425,2.376,4.08,3.12
			c-0.32-1.12-0.371-2.509-0.96-3.36c-0.814-1.185-2.089-1.911-3.6-2.4c-0.57-0.39-1.381-0.54-1.92-0.96
			c-0.76,0.039-0.803-0.638-1.681-0.48c-0.41-0.39-0.873-0.727-1.68-0.72C450.674,404.306,451.102,404.759,451.921,404.819z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M423.121,761.46c0-0.479,0-0.96,0-1.439
			c-0.753-0.112-0.677,0.604-0.96,0.96c-0.682,0.999-1.331,2.029-1.921,3.12c-0.411,0.548-0.707,1.212-1.199,1.68
			c-0.736,1.983-2.221,3.22-2.881,5.28c-0.759,0.601-1.079,1.64-1.439,2.64c-0.348,0.373-0.32,1.12-0.72,1.44
			c-0.001,0.959-0.479,1.44-0.48,2.399c0,1.12,0,2.24,0,3.36c0.112,2.527,0.746,4.534,0.72,7.2c0.326,0.074,0.199,0.601,0.24,0.96
			c0,1.52,0,3.04,0,4.56c-0.438,0.522-0.23,1.689-0.96,1.92c-0.026,0.613-0.804,0.477-1.439,0.48
			c-0.646-1.436,0.185-4.345-0.48-5.76c0.032-3.552-0.643-6.398-0.96-9.601c0-0.96,0-1.92,0-2.88
			c1.504-8.576,6.845-13.315,10.08-20.16c0.08,0,0.16,0,0.24,0c0.397-0.802,0.802-1.598,1.2-2.399
			c0.217-0.983,1.224-1.177,1.68-1.92c-0.017-1.217,0.412-1.988,0.24-3.36c0.399-1.364,0.399-4.396,0-5.76c0-0.08,0-0.16,0-0.24
			c-0.099-1.022-0.111-2.129-0.48-2.88c-0.078-0.882,0.205-2.125-0.24-2.641c-0.153-0.966,0.313-2.552-0.239-3.12
			c0.071-1.431-0.099-2.621-0.48-3.6c0-0.96-0.479-1.44-0.479-2.4c-0.315-0.725-0.165-1.915-0.48-2.64
			c-0.15-0.729,0.308-2.067-0.24-2.4c0-0.079,0-0.159,0-0.239c0-1.04,0-2.08,0-3.12c-0.131-0.349,0.28-1.24-0.24-1.2
			c0-0.16,0-0.32,0-0.479c0-0.4,0-0.801,0-1.2c-0.029-3.344,0.137-8.118-0.239-10.32c0-0.08,0-0.16,0-0.24
			c0.399-3.44,0.399-8.559,0-12c-0.279-0.039-0.428,0.053-0.48,0.24c-1.207,0.073-1.896,0.664-2.88,0.96
			c-0.73-0.011-0.934,0.507-1.44,0.72c-0.176-0.729-1.886,0.344-3.119,0.721c-8.845,2.701-18.484,5.475-26.881,8.64
			c-9.889,3.728-20.398,7.956-33.359,6.96c2.268-2.117,5.585-1.975,8.399-2.4c15.19-2.297,27.127-8.64,40.801-12.479
			c5.34-1.5,10.978-2.506,16.08-5.04c0.006,0.233,0.293,0.187,0.479,0.24c0-0.08,0-0.16,0-0.24c0.977-0.063,1.629-0.451,2.4-0.72
			c0.999-0.602,0.245-2.955,0.479-4.32c-0.16,0-0.319,0-0.479,0c0.106-0.214,0.211-0.43,0.479-0.48
			c-0.098-0.578,0.438-0.521,0.24-1.199c-8.479,2.771-15.321-1.822-23.521-2.881c-5.354-0.69-10.91-0.997-16.319-1.68
			c-10.325-1.302-20.665-3.847-30.48-5.76c-20.54-4.004-40.753-7.667-55.68-16.561c-1.637,0.191-2.568,2.209-4.32,2.16
			c-1.006-0.027-1.604-1.24-2.88-1.92c-1.125-0.599-2.573-0.563-3.36-1.2c-1.332-1.077-1.731-3.162-1.92-4.319
			c-10.756,5.964-25.289,8.15-39.12,11.04c-0.785,5.332-1.688,11.09-1.44,16.8c0.27,6.193,1.885,12.511,1.2,19.2
			c-0.375,3.661-1.983,7.61-3.12,11.52c-1.045,3.594-2.695,7.297-3.6,10.8c-1.299,5.032-0.561,10.997-1.92,16.08
			c-0.672,2.517-2.308,4.785-2.88,7.44c-2.264,10.515,0.655,21.663-0.48,32.88c-1.076,10.625-5.169,19.746-4.8,30
			c42.772,0.668,86.438,0.441,129.841,0.48c-22.642-16.256-65.227-9.839-102-9.84c-2.711-0.001-5.547-0.063-8.16,0
			c-2.232,0.053-5.155,1.142-6.96-0.721c1.006-2.154,4.763-1.439,7.68-1.439c30.92,0,65.776-5.091,92.88,1.68
			c4.661,1.164,8.564,3.127,12.721,5.04c4.021,1.851,8.463,4.651,12.479,5.28c6.721,1.052,14.453,0,21.6,0
			c7.347,0,14.624,0.196,21.36-0.24c0.204-0.044,0.227,0.094,0.24,0.24c2.56-0.269,6.464,0.35,9.6,0
			c0.577-0.064,2.636,0.406,1.681-0.96c0-0.08,0-0.16,0-0.24c0-1.44,0-2.88,0-4.32c0.33-0.31,0.237-1.042,0.239-1.68
			c0.551-0.489,0.087-1.993,0.24-2.88c0-0.24,0-0.48,0-0.72c0-0.16,0-0.32,0-0.48c0.397-0.336,0.397-2.304,0-2.64
			c0-0.08,0-0.16,0-0.24c0.068-0.388-0.227-0.413-0.24-0.72c-0.385-1.615-0.967-3.033-0.72-5.28c0.482-1.197,0.86-2.5,1.44-3.601
			c-0.063-0.702,0.542-0.737,0.479-1.439c0.225-0.176,0.364-0.437,0.48-0.72c0.199-0.602,0.657-0.943,0.72-1.681
			c0.501-0.938,1.086-1.794,1.68-2.64c0.215-3.226,1.077-5.804,1.44-8.88c0.586-0.837,0.044-2.597-0.48-3.12
			C424.588,766.394,423.802,763.979,423.121,761.46z M329.28,699.3c9.147-1.207,18.306-2.793,26.88-4.56
			c8.892-1.832,17.265-3.929,25.2-6.48c2.36-0.759,5.361-2.489,7.681-0.72c-0.24,1.836-2.519,1.397-3.601,1.68
			c-3.79,0.991-7.586,2.67-11.52,3.841c-16.059,4.776-33.804,7.086-52.08,9.359C322.964,699.549,326.644,699.648,329.28,699.3z
			 M251.28,706.98c3.388-4.007,11.069-4.355,14.64,0c4.097,4.997,1.649,13.898-3.36,16.319c-4.462,0.49-8.65-0.422-10.8-2.88
			C248.86,717.104,248.429,710.352,251.28,706.98z M234,751.141c1.166-1.924,3.915-1.389,5.76-1.44
			c16.012-0.445,33.796,0.326,48.96-0.479c5.889-0.313,11.458-0.281,16.8-0.24c-1.863,1.4-4.574,1.409-6.96,1.68
			c-7.337,0.833-15.569,1.681-23.76,1.68c-11.469,0-23.875-0.917-34.8-0.479C238.111,751.936,235.526,752.916,234,751.141z
			 M255.6,784.261c-1.943,2.821-6.403,4.42-9.36,3.84c-1.684-0.331-4.457-1.521-5.52-2.641c-4.993-5.253-0.516-16.36,4.8-17.76
			c0.149-0.039,0.328,0.032,0.48,0c3.699-0.771,7.922,0.56,9.6,3.601C257.422,774.601,258,780.774,255.6,784.261z M384.001,793.86
			c-10.613-0.744-21.75-3.805-32.881-5.76c-10.865-1.909-21.854-3.976-32.399-6.24c-16.544-3.553-33.377-5.252-48.96-9.12
			c-3.903-0.969-7.46-1.687-11.76-2.64c-0.68-0.151-3.013,0.014-2.64-1.681c-0.166-0.645,0.496-0.464,0.72-0.72
			c10.797,0.735,22.095,4.549,33.601,6.48c11.423,1.917,23.068,3.416,34.08,5.76c11.303,2.405,22.656,3.963,33.84,6
			c5.606,1.021,11.233,2.206,16.8,3.12c5.4,0.887,10.902,1.562,15.84,3.84C388.864,794.186,385.871,793.991,384.001,793.86z
			 M385.44,755.46c-17.912,1.612-33.884,6.163-53.04,6.24c-6.226,0.025-13.195-0.644-20.16-0.96c-6.599-0.3-14.011-0.429-20.16-0.72
			c-9.771-0.462-19.838-0.625-30.479,0c-0.726,0.042-8.677,1.46-8.88-0.96c-0.179-2.131,6.825-1.534,8.88-1.681
			c17.025-1.213,33.575-0.109,50.64,0.48c6.565,0.227,13.213,0.96,19.681,0.96c21.204,0,36.509-5.376,55.68-6.48
			c1.044-0.06,3.998-0.684,4.32,0.961C390.81,755.619,387.636,755.263,385.44,755.46z M382.08,714.42
			c-3.236,0.627-6.87,1.84-9.84,0.48c1.83-2.814,6.05-2.226,9.36-2.88c6.785-1.342,13.839-2.121,21.36-3.12
			c4.877-0.648,10.562-0.674,12.239-4.08c0.06-0.261,0.478-0.163,0.48-0.48c0.16,0,0.32,0,0.479,0c1.168,0.909,0.8,3.588-0.479,4.08
			c-0.709,0.571-1.69,0.869-2.4,1.44C404.682,711.402,391.794,712.541,382.08,714.42z M403.2,724.26
			c-0.907-3.062,6.521-5.251,7.921-6.479c0.806,0.007,1.27-0.331,1.68-0.72c0-0.08,0-0.16,0-0.24c0.54-0.66,1.698-0.702,2.399-1.2
			c0.096,0.011,0.187,0.011,0.273,0.004c-0.001,0-0.002,0.001-0.003,0.001c0.563,0.012,1.144,0.04,1.41-0.245
			c1.746,2.052-2.959,3.405-5.04,4.561c-2.402,1.333-4.302,2.216-5.521,3.12c1.135,1.346,5.346-0.386,6.48,0.96
			C411.689,726.663,403.808,726.308,403.2,724.26z"/>
			</g>
			<g id="blue">
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M347.28,394.02c-3.193,0-8.002-0.264-11.52-0.72
			c-2.896-0.88-0.955-6.128-1.92-9.36c-0.398-1.122-0.298-2.742-0.48-4.08c-1.55,0.45-2.274,1.726-3.84,2.16
			c-0.36,0.28-0.587,0.693-1.2,0.72c-4.455,2.482-8.709,4.777-13.439,6.72c-4.693,1.927-10.102,2.96-14.641,5.28
			c-3.65,1.866-7.404,7.166-10.8,10.32c-1.808,1.68-3.812,3.121-5.76,4.8c-1.979,1.706-3.325,4.157-5.04,5.521
			c-2.305,1.831-5.286,2.037-7.2,4.56c0.184,2.056,1.136,3.344,1.44,5.28c-0.024,1.144,0.447,1.793,0.72,2.64
			c0.776,1.544,1.21,3.43,1.68,5.28c0,0.08,0,0.16,0,0.24c1.415,4.024,2.798,8.081,4.08,12.24c0,0.079,0,0.159,0,0.239
			c0.119,3.098,4.355,7.497,3.36,11.521c-0.446,1.802-6.202,4.02-8.16,5.76c-5.361,4.765-9.456,8.44-14.88,12.96
			c-2.653,2.211-5.816,4.281-7.44,6.24c-0.892,1.076-1.452,2.563-2.64,4.08c-0.628,0.803-3.059,2.93-3.12,3.6
			c-0.14,1.544,2.882,2.637,1.2,4.32c2.141-0.685,3.474-2.042,5.76-1.68c4.855,0.769,5.92,10.143,2.88,13.92
			c-0.059,5.367,3.881,10.342,6.48,15.12c3.2,5.883,5.6,10.361,8.88,14.88c12.102-14.886,23.983-29.495,35.041-45.601
			c3.671-5.347,7.666-10.858,9.12-17.76c-1.924-3.057-0.022-9.313,1.199-13.92C323.599,434.563,333.404,410.703,347.28,394.02z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M256.561,466.979c4.948-5.213,11.271-8.942,16.56-13.68
			c-1.406-4.674-3.263-8.896-4.56-13.681c0.018-0.418-0.054-0.746-0.24-0.96c-1.28-2.64-2.147-5.692-2.88-8.88
			c0-0.08,0-0.159,0-0.239c-0.645-1.436-1.106-3.055-1.44-4.801c-1.954,0.767-3.328,2.112-5.04,3.12
			c-3.637-1.377-7.306-1.606-9.6-4.56c-0.177,2.985-1.355,5.783-1.44,8.64c-0.221,7.483,3.627,14.724,2.4,21.84
			c-0.279-0.039-0.427,0.054-0.48,0.24c-1.869,0.424-2.613-1.61-3.36-2.64c-2.445-3.37-5.262-6.622-8.4-9.601
			c-0.559,2.081,0.012,5.293-0.96,6.96c0,0.08,0,0.16,0,0.24c-0.373,12.453,1.946,22.215,4.08,32.16
			c1.682-0.312,2.859-1.814,4.08-2.88C249.372,474.69,252.909,470.827,256.561,466.979z"/>
			</g>
			<g id="gray">
				<path fillRule="evenodd" clipRule="evenodd" fill="#7A7C7D" d="M199.92,252.659c0,0.16,0,0.32,0,0.48
			c-0.16,5.041,0.319,10.72-0.24,15.36c0,0.8,0,1.6,0,2.4c0.158,1.603-0.317,3.836,0.24,5.04c0.071-0.809,0.121-1.64,0.48-2.16
			c0.134-1.786,0.497-3.343,0.96-4.8c0.528-3.072,1.539-5.662,1.68-9.12c0-0.08,0-0.16,0-0.24
			C204.532,257.205,201.354,253.579,199.92,252.659z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#7A7C7D" d="M229.2,349.14c2.521-2.2,6.914-2.526,8.88-5.28
			c-0.788-0.812-1.913-1.287-2.64-2.16c-1.598,0.643-3.901,0.579-5.52,1.2c-0.032,1.488-0.303,2.737-0.48,4.08
			C229.799,347.995,228.444,348.521,229.2,349.14z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#7A7C7D" d="M344.641,252.899c-0.261-0.859-0.375-1.866-0.72-2.64
			c-0.692-2.588-1.559-5.002-2.641-7.2c-0.266-0.934-0.663-1.736-1.2-2.4c-0.286-1.313-1.152-2.047-1.439-3.36
			c-0.787-0.493-0.917-1.644-1.44-2.4c0-0.08,0-0.16,0-0.24c-0.53-1.229-1.283-2.236-1.68-3.6c-0.256-1.264-1.154-1.886-1.2-3.36
			c-0.374-0.674-1.502-1.847-0.96-2.64c0.838,0.363,0.964,1.436,1.44,2.16c1.009,1.231,1.558,2.922,2.64,4.08
			c1.886,3.474,3.835,6.886,5.76,10.32c0.281,0.359,0.299,0.982,0.721,1.2c0.193,0.095,0.081-1.499-0.24-1.68
			c-0.698-5.622-2.134-10.506-3.36-15.6c-0.349-1.011-0.813-1.906-0.96-3.12c-0.422-0.218-0.438-0.841-0.72-1.2
			c-3.337-9.463-9.027-16.573-15.12-23.28c-0.489-0.07-0.471-0.649-0.96-0.72c-0.661-0.459-1.175-1.065-1.92-1.44
			c-0.477-0.003-0.306-0.655-0.96-0.48c0.073-0.554-0.693-0.267-0.72-0.72c-0.388,0.068-0.414-0.227-0.721-0.24
			c-0.262-0.539-1.178-0.422-1.439-0.96c-1.224-0.216-2.186-0.695-2.88-1.44c-0.846-0.274-1.713-0.527-2.16-1.2
			c-0.166-0.474-0.641-0.64-1.2-0.72c-5.096-3.304-11.129-5.671-16.32-8.88c-1.321-0.119-1.954-0.925-3.12-1.2
			c-2.654,0.308-6.732,0.466-9.36,0c-23.012,0.988-38.932,9.068-56.88,15.12c-4.244,1.861-8.149,4.886-12,8.16
			c-2.989,2.542-8.452,6.585-10.32,9.84c-1.405,2.449-1.593,5.467-3.36,7.92c-0.301,1.539-0.957,2.723-1.44,4.08
			c0.058,0.422,0.413,0.547,0.96,0.48c0.55,0.84-0.114-1.531,0.48-1.44c-0.079-0.879,0.267-1.333,0.48-1.92
			c0.203-1.048,1.768-2.291,2.64-1.2c0.132,1.148-1.529,2.43-1.44,4.32c0,0.24,0,0.48,0,0.72c0.475,3.205,1.71,5.65,3.12,7.92
			c0.017,0.704,0.549,0.891,0.96,1.2c0.555,0.485,1.282,0.798,1.44,1.68c2.558,2.401,5.16,4.76,7.2,7.68
			c0.76,0.52,1.393,1.168,1.68,2.16c0.453,0.347,1.064,0.536,0.96,1.44c-0.76-0.2-1.22-0.7-1.68-1.2c-0.08,0-0.16,0-0.24,0
			c-4.104-3.577-8.063-7.297-11.28-11.76c0.041-0.601-0.369-0.751-0.72-0.96c-0.772-0.428-1.217-1.183-1.44-2.16
			c-0.554,0.074-0.267-0.693-0.72-0.72c0,0.48,0,0.96,0,1.44c0.426,0.454,0.175,1.585,0.24,2.4c0.425,0.935,0.57,2.149,0.72,3.36
			c0.549,1.771,0.948,3.692,1.44,5.52c2.451,3.47,5.734,6.106,9.36,8.4c3.467,0.692,5.25,3.07,9.6,2.88
			c2.701,0.58,1.854,4.706,3.36,6.48c0-0.32,0-0.64,0-0.96c-0.603-1.798-0.95-3.85-0.96-6.24c-0.549-0.411-0.088-1.832-0.24-2.64
			c0-0.16,0-0.32,0-0.48c-0.554-0.727-0.084-2.476-0.24-3.6c-0.899-6.221-2.716-11.524-3.12-18.24c-0.4-2.642-0.4-6.958,0-9.6
			c0-0.24,0-0.48,0-0.72c0.199-4.09,0.974-8.446,1.92-12.24c0.939-3.768,1.886-7.84,4.56-9.36c-0.131,0.349,0.28,1.24-0.24,1.2
			c-1.075,3.005-1.823,6.337-2.64,9.6c-0.594,3.727-1.194,7.446-1.68,11.28c0,0.24,0,0.48,0,0.72
			c-0.059,5.899-0.078,11.758,1.2,16.32c0.038-0.283-0.042-0.681,0.24-0.72c0-0.16,0-0.32,0-0.48c0.153-0.887-0.311-2.391,0.24-2.88
			c0-0.16,0-0.32,0-0.48c0.572-8.948,2.721-16.318,7.68-20.88c0.352-0.209,0.761-0.359,0.72-0.96c2.282-3.493,5.01-7.419,7.44-10.56
			c0.803-1.039,1.651-2.805,3.12-2.64c-0.024,0.269,0.349,0.374,0,0.48c0.067,0.547-0.058,0.902-0.48,0.96
			c-0.184,0.696-0.38,1.38-0.96,1.68c-0.302,0.098-0.491,0.309-0.48,0.72c-0.392,0.968-0.89,1.831-1.68,2.4
			c-0.208,0.752-0.595,1.326-1.2,1.68c-0.613,1.306-1.412,2.428-2.4,3.36c-0.351,0.209-0.761,0.359-0.72,0.96
			c-0.653,1.427-1.588,2.573-2.64,3.6c-0.376,1.224-1.319,1.88-1.92,2.88c-1.288,1.912-2.384,4.016-3.12,6.48
			c-0.389,3.292-1.045,6.314-1.44,9.6c-0.152,0.808,0.309,2.229-0.24,2.64c0,0.16,0,0.32,0,0.48c-0.157,1.363,0.316,3.355-0.24,4.32
			c-0.245,3.463-0.315,6.877,0,10.32c0.549,0.411,0.088,1.832,0.24,2.64c0,0.16,0,0.32,0,0.48c0.21,4.119,1.631,8.277,1.44,12
			c0.993,1.727,2.966,2.474,4.08,4.08c0.209,0.351,0.359,0.761,0.96,0.72c1.132,0.388,1.667,1.373,2.4,2.16
			c0.279,0.039,0.427-0.053,0.48-0.24c0-0.16,0-0.32,0-0.48c0.269-0.771,0.657-1.423,0.72-2.4c0.803-1.037,1.251-2.429,1.92-3.6
			c0.213-0.507,0.731-0.709,0.72-1.44c1.198-1.282,2.032-2.928,3.12-4.32c1.6-2.24,3.53-4.15,5.04-6.48
			c2.166-1.754,3.492-4.348,5.28-6.48c0-0.08,0-0.16,0-0.24c1.305-1.015,2.042-2.598,3.12-3.84c0.196-0.459,0.403-0.908,0.619-1.347
			c-0.216,0.439-0.423,0.888-0.619,1.347c0.204,0.044,0.227-0.094,0.24-0.24c1.152-1.408,1.985-3.135,4.08-3.6
			c-0.56,4-3.667,5.453-5.04,8.64c0.16,0,0.32,0,0.48,0c0.349-0.371,1.229-0.211,1.92-0.24c0.333-0.547,1.67-0.09,2.4-0.24
			c1.338-0.262,2.515-0.685,4.08-0.72c0.123,1.477,0.912,2.288,1.2,3.6c1.877,3.244,5.021,6.499,8.16,7.68
			c-1.93-2.23-4.584-3.736-5.52-6.96c-0.458-1.062-0.938-2.103-0.96-3.6c-0.597-0.036-0.07-0.813,0-0.96
			c3.926,2.074,7.822,5.458,12.96,5.52c-0.525-0.355-1.292-0.468-1.68-0.96c-0.08,0-0.16,0-0.24,0
			c-2.984-1.492-5.601-3.352-7.567-5.863c0.343,0.214,1.239-0.126,1.327,0.343c2.622,0.479,4.146-0.561,6.96-0.48
			c0.568-0.552,2.154-0.086,3.12-0.24c0.568-0.552,2.154-0.086,3.12-0.24c5.923-1.197,10.775-3.465,17.52-3.84
			c0.826,0.854,2.136,1.224,2.64,2.4c0,0.08,0,0.16,0,0.24c0.193,1.573,0.873-0.071,1.44,0.96c-0.748,2.612-0.961,5.759-1.2,8.88
			c0,0.08,0,0.16,0,0.24c-0.031,0.592-0.036,1.156,0.24,1.44c1.947-0.213,3.044-1.276,4.8-1.68c0.676-0.205,0.984-0.775,1.92-0.72
			c2.108-1.614,3.549-3.912,7.2-3.36c0.436,0.685,1.176,1.064,0.96,2.4c0,0.16,0,0.32,0,0.48c0.399,0.887,0.399,3.433,0,4.32
			c-0.003,0.638,0.091,1.371-0.24,1.68c-0.495,1.265-1.763,1.757-2.16,3.12c0.608,2.432,1.438,4.642,1.921,7.2
			c0.371,0.349,0.503,0.937,0.479,1.68c0.349,1.252,0.874,2.326,0.96,3.84c0.705,1.456,1.073,3.247,1.44,5.04
			c0.31,0.97,0.655,1.904,0.72,3.12c0.85,1.471,0.559,4.081,1.68,5.28c0.014,0.146,0.036,0.284,0.24,0.24c0,0.08,0,0.16,0,0.24
			c-0.037,0.597,0.377,0.743,0.48,1.2c0.348,0.452,0.353,1.248,0.96,1.44c0-0.24,0-0.48,0-0.72c0.383-1.216,0.418-2.781,0.72-4.08
			c1.585-0.415,2.274-1.726,2.399-3.6c0.444-0.676,0.659-1.581,0.96-2.4c0.385-1.056,1.025-1.855,1.2-3.12
			c0.615-0.825,1.094-1.786,1.44-2.88c2.041-3.319,4.509-6.211,6.24-9.84c0.843-0.757,1.235-1.964,1.68-3.12c0-0.08,0-0.16,0-0.24
			c0.682-0.678,0.819-1.9,1.92-2.16c0-0.08,0-0.16,0-0.24c1.089-0.313,2.511-0.313,3.6,0
			C345.407,255.973,345.113,254.347,344.641,252.899z M324.59,217.479c1.63,4.105,3.378,8.25,4.92,12.594
			C327.968,225.729,326.219,221.585,324.59,217.479z M326.883,224.743c-0.168-0.45-0.33-0.904-0.482-1.364
			C326.553,223.839,326.715,224.293,326.883,224.743z M327.91,227.288c-0.076-0.177-0.15-0.355-0.226-0.534
			C327.76,226.933,327.834,227.111,327.91,227.288z M327.398,226.063c-0.108-0.268-0.213-0.538-0.317-0.808
			C327.186,225.525,327.29,225.795,327.398,226.063z M314.901,231.42L314.901,231.42L314.901,231.42L314.901,231.42z
			 M312.692,229.716c0.083,0.091,0.185,0.175,0.295,0.255C312.877,229.891,312.776,229.807,312.692,229.716z M314.34,231.136
			c-0.063-0.032-0.124-0.065-0.188-0.096C314.217,231.07,314.278,231.103,314.34,231.136z M313.034,230.006
			c0.104,0.072,0.216,0.14,0.332,0.205C313.25,230.146,313.138,230.078,313.034,230.006z M323.15,238.02
			c-0.009-0.007-0.018-0.014-0.026-0.021C323.133,238.006,323.142,238.013,323.15,238.02z M318.512,234.125
			c-0.726-0.627-1.452-1.253-2.191-1.866C317.06,232.873,317.786,233.498,318.512,234.125z M327.922,241.238
			c-0.001,0-0.001-0.001-0.002-0.001c0.004,0.002,0.007,0.004,0.011,0.006C327.928,241.241,327.925,241.24,327.922,241.238z
			 M331.032,234.692c-0.456-1.516-0.948-3.003-1.466-4.466c0.518,1.465,1.012,2.954,1.468,4.472
			C331.033,234.695,331.033,234.694,331.032,234.692z M311.521,209.459c3.146,1.975,5.986,4.254,8.4,6.96
			c-1.153,0.582-2.075-0.898-2.881-1.44c-1.227-0.453-2.292-1.068-3.119-1.92c-0.615-0.665-1.425-1.136-2.4-1.44
			c-5.234-3.515-17.533-5.721-25.92-2.88c-1.948,0.66-3.187,2.413-5.04,2.4c-0.854-1.946,1.191-2.931,2.4-3.6
			C290.759,203.529,305.595,204.706,311.521,209.459z M271.92,195.059c0.166-0.474,0.64-0.64,1.2-0.72
			c0.041-0.28,0.38-0.261,0.72-0.24c0.962,0.078,0.609,1.471,1.44,1.68c4.152-0.678,7.859,1.076,9.36,3.84
			c-3.666-0.152-5.896-2.825-10.08-1.68c-0.268-0.372-0.78-0.5-0.72-1.2c-3.853,2.067-6.05,5.79-9.12,8.64
			c-0.411,0.309-0.944,0.496-0.96,1.2c-0.506,1.014-1.074,1.966-1.92,2.64c-0.302,0.098-0.491,0.31-0.48,0.72
			c-0.325,1.115-0.956,1.924-1.68,2.64c-0.301,0.099-0.49,0.31-0.48,0.72c-0.208,0.752-0.595,1.325-1.2,1.68
			c-0.352,0.209-0.761,0.359-0.72,0.96c-0.786,1.455-1.749,2.732-2.88,3.84c-0.352,0.208-0.761,0.359-0.72,0.96
			c0,0.08,0,0.16,0,0.24c-2.467,2.173-3.221,6.059-3.6,10.32c0.047,0.687,0.03,1.31-0.72,1.2c-0.473-1.847-0.834-3.806-0.72-6.24
			c0.611-5.47,4.738-7.422,6.48-11.76c0.854-0.586,1.296-1.584,1.92-2.4c0.003-0.477,0.655-0.305,0.48-0.96
			c0.749-0.611,1.173-1.546,1.68-2.4c0.258-0.622,0.868-0.893,0.96-1.68c1.286-1.194,2.185-2.775,3.36-4.08c0-0.08,0-0.16,0-0.24
			c1.906-1.614,3.269-3.771,5.28-5.28c0.343-0.057,0.405-0.395,0.72-0.48C270.135,196.154,270.81,195.389,271.92,195.059z
			 M239.521,257.459c-0.066,0.056-0.114,0.104-0.151,0.146C239.406,257.563,239.454,257.516,239.521,257.459
			c0.75-3.63,2.019-6.74,2.819-10.32c0,0,0-0.001,0.001-0.002C241.54,250.718,240.27,253.829,239.521,257.459z M242.314,247.202
			c-0.343,0.813-0.664,1.678-0.964,2.568C241.651,248.879,241.971,248.015,242.314,247.202z M239.345,257.638
			c-0.021,0.028-0.039,0.055-0.048,0.076C239.306,257.693,239.323,257.667,239.345,257.638z M242.434,246.913
			C242.433,246.913,242.433,246.912,242.434,246.913L242.434,246.913C242.433,246.912,242.433,246.913,242.434,246.913z
			 M250.692,212.169c0.209-0.112,0.427-0.208,0.646-0.303c0,0-0.001,0-0.001,0.001C251.119,211.962,250.901,212.058,250.692,212.169
			z M257.24,206.912c0.005-0.006,0.011-0.011,0.017-0.017C257.251,206.9,257.246,206.906,257.24,206.912
			C257.24,206.912,257.24,206.912,257.24,206.912z M260.4,203.699c-0.479,0.469-0.958,0.955-1.437,1.444
			C259.442,204.654,259.921,204.168,260.4,203.699z M258.336,205.785c0.045-0.046,0.09-0.092,0.135-0.139
			C258.426,205.692,258.381,205.738,258.336,205.785z M235.2,232.499c-0.231-5.351,1.235-9.005,3.36-12
			c2.474-4.167,4.952-8.328,7.44-12.48c0.526-1.314,0.995-2.685,1.92-3.6c0.524-1.476,1.237-2.763,2.16-3.84
			c0.474-0.166,0.64-0.64,0.72-1.2c0.882-1.358,0.825-3.654,3.12-3.6c0.593,2.662-1.581,4.449-2.64,6.24
			c-0.215,0.984-0.734,1.666-1.2,2.4c-2.039,3.641-3.913,7.447-6.24,10.8c-0.474,0.166-0.64,0.64-0.72,1.2
			c-0.183,0.857-0.619,1.461-1.2,1.92c-0.295,0.104-0.493,0.307-0.48,0.72c-0.325,1.115-0.956,1.924-1.68,2.64
			c-2.919,4.44-2.953,11.767-3.12,18.96c-0.155,1.045,0.313,2.713-0.24,3.36c0,0.08,0,0.16,0,0.24
			c-0.152,0.808,0.309,2.229-0.24,2.64c-1.278-0.322-0.603-2.598-0.96-3.84c-0.333-0.76-0.333-2.119,0-2.88
			C234.801,238.177,234.801,234.501,235.2,232.499z M236.625,257.104c-0.024,0.068-0.047,0.138-0.071,0.206
			C236.578,257.242,236.6,257.172,236.625,257.104z M234.72,242.579c0.32,5.46,0.773,10.786,1.187,16.152l0,0
			C235.493,253.365,235.04,248.039,234.72,242.579z M237.789,253.164c0.002-0.007,0.004-0.015,0.005-0.022
			c-0.001,0.006-0.003,0.013-0.004,0.019C237.789,253.162,237.789,253.163,237.789,253.164z M238.539,250.094
			c0.236-0.978,0.477-1.965,0.742-2.955C239.016,248.129,238.774,249.117,238.539,250.094z M239.289,257.739
			c-0.004,0.017-0.003,0.03,0.001,0.041C239.285,257.77,239.285,257.756,239.289,257.739z M239.299,257.798
			c0.005,0.005,0.015,0.006,0.023,0.009C239.313,257.805,239.305,257.802,239.299,257.798z M239.626,257.653
			C239.626,257.652,239.627,257.652,239.626,257.653L239.626,257.653C239.627,257.652,239.626,257.652,239.626,257.653z
			 M239.826,257.206c0,0,0-0.001,0-0.001c0.374-0.862,0.72-1.754,1.041-2.67C240.546,255.451,240.2,256.343,239.826,257.206z
			 M239.833,257.179c0.791-3.412,1.986-6.419,2.808-9.8C241.819,250.76,240.624,253.767,239.833,257.179z M252.74,244.717
			c4.631-7.041,8.68-14.665,17.819-17.197C261.42,230.053,257.374,237.679,252.74,244.717z M270.562,227.52L270.562,227.52
			C270.562,227.52,270.561,227.52,270.562,227.52C270.561,227.52,270.562,227.52,270.562,227.52z M261.413,233.167
			c-0.17,0.154-0.338,0.31-0.504,0.467C261.074,233.477,261.242,233.321,261.413,233.167z M260.408,234.12
			c-0.166,0.166-0.328,0.336-0.49,0.506C260.08,234.456,260.242,234.286,260.408,234.12z M256.479,239.196
			c0.144-0.242,0.286-0.486,0.436-0.722C256.764,238.71,256.622,238.954,256.479,239.196z M255.732,240.546
			c0.146-0.284,0.291-0.567,0.445-0.843C256.023,239.979,255.878,240.263,255.732,240.546z M258.981,235.674
			c0.156-0.185,0.313-0.368,0.474-0.548C259.294,235.306,259.138,235.489,258.981,235.674z M257.264,237.95
			c0.146-0.217,0.291-0.436,0.442-0.647C257.554,237.514,257.409,237.732,257.264,237.95z M258.097,236.78
			c0.15-0.2,0.301-0.399,0.457-0.593C258.397,236.381,258.247,236.58,258.097,236.78z M263.038,231.812
			c0.174-0.134,0.352-0.266,0.529-0.396C263.39,231.546,263.212,231.678,263.038,231.812z M261.948,232.697
			c0.171-0.146,0.344-0.29,0.519-0.432C262.292,232.408,262.119,232.551,261.948,232.697z M264.177,230.978
			c0.177-0.123,0.357-0.243,0.537-0.362C264.534,230.735,264.354,230.854,264.177,230.978z M265.363,230.192
			c0.177-0.111,0.359-0.219,0.54-0.328C265.722,229.973,265.541,230.081,265.363,230.192z M269.196,228.12
			c0.142-0.065,0.288-0.127,0.431-0.191C269.484,227.993,269.338,228.055,269.196,228.12z M266.596,229.456
			c0.176-0.1,0.356-0.196,0.535-0.292C266.952,229.26,266.772,229.355,266.596,229.456z M267.874,228.765
			c0.168-0.085,0.34-0.167,0.51-0.251C268.213,228.598,268.041,228.68,267.874,228.765z M295.2,228.179
			c-7.081,0.919-13.48,2.52-19.68,4.32c-0.689,0.431-1.475,0.766-2.64,0.72c-0.08,0-0.16,0-0.24,0
			c-2.143,0.275,1.186-2.053,2.4-2.16c0.675-0.204,0.984-0.775,1.92-0.72c0.47-0.491,1.204-0.716,2.16-0.72
			c7.98-3.182,22.798-5.459,31.92-1.68c0.305,0.076,0.567,0.193,0.797,0.346c0.158,0.167,0.306,0.338,0.475,0.5
			c0.087,0.167,0.147,0.36,0.169,0.594c0.041,0.114,0.11,0.218,0.196,0.316c-0.086-0.098-0.155-0.202-0.196-0.316
			c-0.338,0.063-0.273,0.526-0.48,0.72c-0.399,0-0.8,0-1.199,0c-0.241,0.001-0.286-0.194-0.48-0.24
			c-0.014-0.146-0.036-0.284-0.24-0.24C306.075,228.184,300.789,228.031,295.2,228.179z M332.559,241.351
			c0.007,0.073,0.015,0.145,0.02,0.218C332.573,241.496,332.565,241.424,332.559,241.351z M332.368,239.759c0-0.001,0-0.002,0-0.004
			c0.133,0.586,0.262,1.175,0.38,1.772C332.627,240.934,332.501,240.345,332.368,239.759z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#7A7C7D" d="M325.681,337.379c-0.344,0.857-0.854,1.547-0.96,2.64
			c-0.536,0.664-0.935,1.466-1.2,2.4c-0.996,2.284-2.382,4.178-3.36,6.48c-0.947,0.973-1.561,2.28-2.64,3.12c-0.08,0-0.16,0-0.24,0
			s-0.16,0-0.24,0c-0.079,0-0.159,0-0.239,0c-3.978,3.942-8.977,6.864-10.561,13.2c0.077,0.556-0.038,0.921-0.24,1.2
			c-0.938,3.703-2.255,8.304-5.52,8.88c-2.517-1.906,2.005-3.506,1.92-6c-0.721,2.224-3.182,3.495-3.12,5.76
			c-1.868-5.972-1.674-14.006-4.32-19.2c2.116,6.287,3.089,14.988,4.08,21.6c0.11,0.75-0.513,0.767-1.199,0.72
			c-0.247-0.554-0.826-0.774-0.721-1.68c-0.385-1.294-0.672-2.688-0.72-4.32c-1.039-4.241-1.287-9.272-2.64-13.2
			c-0.41-2.31-0.92-4.521-1.2-6.96c-0.419-1.021-0.641-2.239-0.72-3.6c-0.268-0.692-0.433-1.488-0.48-2.4
			c-1.125-4.235-3.575-7.145-6-10.08c-0.652,0.308-0.691,1.229-1.44,1.44c-0.204-0.044-0.227,0.094-0.24,0.24
			c-1.769-0.621-4.571-0.67-6.48-0.72c-1.397-0.603-3.376-0.624-4.8-1.2c-1.217-0.063-2.31-0.25-3.36-0.48
			c-6.01-0.25-6.835,4.686-10.8,6.48c-1.358,1.122-2.437,2.523-3.36,4.08c-0.529,1.151-0.808,2.552-1.68,3.36
			c-0.711,0.569-1.358,1.201-2.16,1.68c-0.47,0.49-1.205,0.716-2.16,0.72c-0.751,0.579-2.394,0.003-2.88-0.48
			c-0.099-0.302-0.31-0.491-0.72-0.48c-1.296-0.625-2.167-1.673-2.88-2.88c-0.98-0.779-1.86-1.659-2.64-2.64c-0.16,0-0.32,0-0.48,0
			c-0.454,0.826-1.471,1.089-2.16,1.68c-1.124,0.477-1.845,1.355-3.12,1.68c-0.976,0.304-1.785,0.775-2.4,1.44
			c-0.332,0.628-1.557,0.363-1.92,0.96c0.519,4.202,0.375,9.065,1.2,12.96c-0.129-4.191-0.888-9.031-0.72-12.72
			c3.25-1.871,6.465-3.775,9.6-5.76c2.847,2.115,3.924,6.552,8.16,6.72c6.547,0.259,6.431-7.696,11.28-10.32
			c0.131,0.349-0.28,1.241,0.24,1.2c3.939,1.341,7.948,3.891,13.44,2.88c-2.811,0.17-5.105-0.174-7.2-0.72
			c-2.028-0.932-4.517-1.403-6.24-2.64c0-0.16,0-0.32,0-0.48c0.023-1.076,1.762-0.747,2.16-0.24c0.501-0.102,0.635,0.165,0.96,0.24
			c0.759,0.281,1.641,0.438,2.16,0.96c1.394,0.206,2.556,0.644,3.84,0.96c1.68,0,3.36,0,5.04,0c2.208-0.671,4.803-0.957,7.68-0.96
			c0.806-0.555,2.636-0.084,3.84-0.24c1.174,0.055,1.592-0.647,2.64-0.72c1.795,0.925,0.652,4.788,1.2,6.96
			c0.5,2.06,0.812,4.308,0.959,6.72c0.365,0.834,0.434,1.966,0.48,3.12c0.419,1.021,0.641,2.239,0.72,3.6
			c0.47,0.811,0.585,1.976,0.721,3.12c0.359,0.521,0.409,1.351,0.479,2.16c0.499,1.021,0.706,2.334,0.96,3.6
			c0.201,0.278,0.316,0.643,0.24,1.2c0.694,2.106,1.6,4,1.68,6.72c-1.667,0.813-2.944,2.016-5.04,2.4
			c-2.375,0.825-4.965,1.434-7.92,1.68c-0.179,0.541-1.346,0.094-1.92,0.24c-0.08,0-0.16,0-0.24,0
			c-0.346-0.134-0.586-0.375-0.72-0.72c-1.002-1.318-1.655-2.985-3.12-3.84c-0.193-0.127-0.39-0.25-0.72-0.24
			c-0.193-0.127-0.39-0.25-0.72-0.24c-0.664-0.536-1.466-0.934-2.4-1.2c-0.193-0.127-0.39-0.25-0.72-0.24
			c-1.306-0.854-2.746-1.574-4.32-2.16c-0.478-0.322-1.143-0.458-1.68-0.72c-0.16,0-0.32,0-0.48,0
			c-0.319-0.48-1.065-0.535-1.68-0.72c-0.06,0.069-0.095,0.156-0.12,0.25l0,0c0.025-0.094,0.061-0.18,0.12-0.25
			c-2.808-1.113-5.646-2.194-7.92-3.84c-0.493,0.067-0.479,0.641-0.72,0.96c-2.976,0.384-3.272,3.448-5.28,4.8
			c-4.275,1.405-5.475,5.885-10.08,6.96c-0.31-1.119,0.125-1.834-1.2-2.16c-0.139-0.939,0.207-1.394,0.48-1.92
			c-0.431-0.031-0.506,0.293-0.96,0.24c-1.441,0.639-2.936,1.225-4.56,1.68c-1.62,1.419-4.204,1.876-5.28,3.84
			c0.079,0.879-0.267,1.333-0.48,1.92c-0.1,1.021-0.42,1.82-0.96,2.4c6.382,7.538,13.434,14.406,19.92,21.84
			c-2.141-4.739-5.373-8.387-9.36-11.28c-0.076-0.556,0.039-0.921,0.24-1.2c-1.299-1.501-3.938-1.663-4.56-3.84
			c0.466-0.733,1.276-1.123,2.4-1.2c0.769-0.271,1.592-0.488,2.64-0.48c0.851-0.442,2.339,0.534,2.64,1.44
			c-1.635,0.125-2.898,0.622-4.56,0.72c1.6,1.92,4.512,2.527,6,4.56c0.067,0.628-0.088,1.033-0.72,0.96
			c6.992,6.849,10.314,17.366,16.56,24.96c14.744-9.575,29.179-19.461,40.32-32.64c2.887-5.753,7.676-9.605,12.24-13.68
			c0.558-0.163,0.643-0.797,1.2-0.96c0.162-0.558,0.798-0.643,0.96-1.2c2.528-1.151,4.303-3.058,6.48-4.56
			c0.908-0.692,2.011-1.189,2.64-2.16c3.209-0.872,3.938-4.223,3.84-8.4c0.541-0.179,0.094-1.346,0.24-1.92c0-0.24,0-0.48,0-0.72
			c0-0.56,0-1.12,0-1.68c0.08,0,0.16,0,0.24,0c0-0.08,0-0.16,0-0.24c0-1.52,0-3.04,0-4.56c0-0.48,0-0.96,0-1.44
			c0-0.32,0-0.64,0-0.96c0-0.32,0-0.64,0-0.96c0-0.8,0-1.6,0-2.4c0-0.48,0-0.96,0-1.44c0-0.32,0-0.64,0-0.96
			c-0.398-0.414-0.398-2.466,0-2.88c-0.057-1.176,0.423-1.817,0.239-3.12c0.364-2.116,1.412-3.548,1.92-5.52
			C327.009,332.791,326.426,335.802,325.681,337.379z M248.4,388.74c-1.679,1.943-2.822,3.523-4.08,4.08
			c-0.636-0.244-0.65-1.109-1.2-1.44c1.164-2.036,3.128-3.272,4.8-4.8c-1.293,0.288-2.565,1.397-4.08,0.72
			c-0.269-1.789,1.233-1.806,1.68-2.88c1.885,1.259,3.2-0.529,4.8-0.96c0.08,0,0.16,0,0.24,0
			C254.219,383.298,249.843,387.07,248.4,388.74z M265.307,377.841c-0.155-0.235-0.312-0.477-0.469-0.723
			C264.995,377.364,265.152,377.606,265.307,377.841z M265.793,378.577c-0.151-0.229-0.312-0.471-0.478-0.723
			C265.482,378.107,265.642,378.349,265.793,378.577z M263.627,374.914c-0.086-0.211-0.153-0.411-0.198-0.596
			C263.473,374.503,263.541,374.703,263.627,374.914z M264.828,377.102c-0.469-0.739-0.914-1.499-1.188-2.157
			C263.913,375.602,264.359,376.364,264.828,377.102z M263.373,373.783C263.373,373.783,263.373,373.783,263.373,373.783
			c-0.013,0.149,0.007,0.32,0.05,0.505C263.38,374.102,263.36,373.931,263.373,373.783z"/>
			</g>
			<g>
				<polygon fillRule="evenodd" clipRule="evenodd" fill="#D4BCB0" points="402.293,609.834 402.296,609.838 402.295,609.836 		" />
				<path fillRule="evenodd" clipRule="evenodd" fill="#D4BCB0" d="M200.64,324.899c-0.757-5.475,1.138-10.477-0.24-14.64
			c-0.64-1.934-2.956-4.058-4.32-5.76c-1.643-2.05-3.359-3.82-4.56-5.76c-2.164,0.161-3.221-2.055-4.8-1.68
			c4.011,8.388,9.29,15.51,13.68,23.52C199.465,321.79,199.756,323.659,200.64,324.899z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D4BCB0" d="M134.4,558.18c0.347-0.773,0.227-2.013,0.24-3.12
			c-0.147-0.332-0.924-0.036-0.96-0.479c-0.08,0-0.16,0-0.24,0c-1.109-0.171-2.075-0.484-2.88-0.96c-0.08,0-0.16,0-0.24,0
			c-0.733,0.173-0.875-0.246-1.44-0.24c-0.858,0.981-2.395,1.284-2.16,3.36C129.096,556.507,131.154,559.317,134.4,558.18z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D4BCB0" d="M210,309.54c-1.462-0.138-1.258-1.942-2.4-2.4
			c-0.16,0-0.32,0-0.48,0c-0.08,0-0.16,0-0.24,0c-2.11,3.49-4.13,0.305-5.28-1.92c0.151,2.729,1.708,4.052,2.4,6.24
			c1.252-0.908,1.634-2.686,3.6-2.88c0.702,0.818,1.246,1.794,1.92,2.64c-1.202,3.278-3.869,5.091-5.76,7.68c0,0.16,0,0.32,0,0.48
			c1.861,4.655,3.263,9.502,6.48,12.72c0.749,0.749,1.833,1.026,2.64,1.68c5.142,4.165,7.502,10.267,11.28,16.08
			c1.713-1.804,0.369-4.711,1.2-6.72c0.953-2.301,3.722-2.059,6.48-2.64c0.08,0,0.16,0,0.24,0c0.181-0.619,1.775,0.175,1.68-0.72
			c-3.271-1.082-3.223-3.886-1.68-6.72c0-0.08,0-0.16,0-0.24c0.666-2.694,1.348-5.372,2.4-7.68c0-0.08,0-0.16,0-0.24
			c0.241-1.118,0.441-2.279,0.96-3.12c0-0.08,0-0.16,0-0.24c0.951-4.809,1.951-9.569,2.88-14.4c-1.159-0.281-1.601-1.279-2.16-2.16
			c0.244,0.645,0.077,1.668-0.48,2.16c-0.359,0.041-0.886-0.085-0.96,0.24c1.631-0.449,2.033,0.953,1.2,1.68c0,0.08,0,0.16,0,0.24
			c-4.161,4.16-9.337,8.583-18.24,7.2c-0.278-0.202-0.644-0.317-1.2-0.24c-0.08,0-0.16,0-0.24,0c-2.476-0.306-6.938-2.993-4.32-5.28
			c-1.37-1.752-1.167-6.261,0-7.44c-0.08,0-0.16,0-0.24,0C208.729,304.47,212.221,308.502,210,309.54z M222.48,324.659
			c0.08,0,0.16,0,0.24,0c5.577-1.242,7.107-9.914,11.52-12.48c0.973,0.467,1.166,1.714,1.68,2.64
			c-2.534,4.427-5.402,8.518-9.6,11.28c-0.08,0-0.16,0-0.24,0c-3.561,1.882-8.152-0.989-8.4-3.84
			C219.87,321.547,220.78,324.092,222.48,324.659z M211.2,319.619c0.238,1.486-0.499,3.729-1.44,4.8
			C208.211,323.413,209.181,318.58,211.2,319.619z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D4BCB0" d="M231.12,309.779c-2.097,1.183-3.856,2.704-6.24,3.6
			c0.24,0,0.48,0,0.72,0c0.08,0,0.16,0,0.24,0C227.678,312.257,229.599,311.218,231.12,309.779z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D4BCB0" d="M292.32,375.06c-1.243-2.917-1.742-6.578-2.64-9.84
			c-2.117-1.963-4.877-3.284-5.28-6.96c1.907-0.117,2.912,2.666,4.56,3.6c-1.025-5.455-1.928-11.032-2.4-17.04
			c-0.411-0.549-1.832-0.088-2.64-0.24c-0.16,0-0.32,0-0.48,0c-0.489,0.551-1.993,0.087-2.88,0.24c-0.08,0-0.16,0-0.24,0
			c-0.333,0.547-1.67,0.089-2.4,0.24c-0.08,0-0.16,0-0.24,0c-1.369,0.391-2.889,0.63-4.56,0.72c-0.08,0-0.16,0-0.24,0
			c-5.492,1.011-9.5-1.539-13.44-2.88c-0.521,0.041-0.109-0.851-0.24-1.2c-4.196,2.271-4.682,8.525-8.978,10.002l-1.229,0.277
			c-0.338,0.038-0.692,0.057-1.074,0.042c-4.236-0.168-5.313-4.605-8.16-6.72c-3.135,1.985-6.351,3.889-9.6,5.76
			c-0.167,3.688,0.591,8.528,0.72,12.72c0,0.08,0,0.16,0,0.24c0.365,0.835,0.433,1.966,0.48,3.12c0,0.08,0,0.16,0,0.24
			c0.423,1.257,0.645,2.715,0.72,4.32c0,0.08,0,0.16,0,0.24c0.654,1.586,0.449,4.031,0.48,6.24c3.648-0.187,6.903-4.818,10.08-2.4
			c1.854-1.911,6.891-4.936,9.36-7.2c0.877-0.804,1.283-2.091,2.4-2.16c1.617-0.1,2.698,1.9,4.32,2.64
			c0.27,0.051,0.374,0.266,0.48,0.48c0.24,0,0.48,0,0.72,0c1.083,0.438,2.285,0.755,3.12,1.44c0.08,0,0.16,0,0.24,0
			c0.833,0.287,1.864,0.376,2.4,0.96c0.663,0.297,1.219,0.701,2.16,0.72c2.714,0.561,4.743,1.991,7.92,2.64c0.08,0,0.16,0,0.24,0
			c2.069,0.251,4.193,0.447,5.76,1.2c0.08,0,0.16,0,0.24,0c0.584,0.135,1.234,0.206,1.2,0.96c-0.732,0.867-2.864,0.336-4.32,0.48
			c0.105,0.78,1.353,2.483,2.16,3.36c2.06-0.66,4.462-0.977,6.72-1.44c0.08,0,0.16,0,0.24,0c0.705-0.496,1.731-0.669,2.64-0.96
			c0.08,0,0.16,0,0.24,0c0.426-0.614,1.541-0.538,2.159-0.96c-0.259-0.861-0.604-1.636-0.72-2.64
			C292.32,375.22,292.32,375.14,292.32,375.06z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D4BCB0" d="M456.961,522.66c-0.802-1.599-2.547-2.254-5.28-1.92
			c-0.882,0.078-2.125-0.205-2.64,0.239c-1.431,0.33-2.707,0.813-3.36,1.92c0,0.08,0,0.16,0,0.24c0.059,1.462,0.571,2.469,1.44,3.12
			c0.08,0,0.159,0,0.239,0c2.001-0.56,3.2-1.92,5.28-2.4c1.684,1.381-0.156,3.493-2.16,3.36c-0.186,0.054-0.473,0.007-0.479,0.24
			c-0.231,0.729-1.397,0.522-1.92,0.96c-2.232,0.869-3.885-0.913-4.32-2.641c0.018-0.418-0.054-0.746-0.24-0.959
			c-0.525-0.619-0.214-2.444,0-3.12c-1.76,0-3.52,0-5.28,0c-0.08,0-0.159,0-0.239,0c-1.338,0.342-2.791,0.569-4.32,0.72
			c-0.08,0-0.16,0-0.24,0c-1.097,0.423-2.344,0.695-3.84,0.72c-0.814,0.065-1.945-0.186-2.399,0.24
			c-0.411,0.549-1.832,0.088-2.641,0.24c-0.08,0-0.16,0-0.24,0c-2.576,0.623-6.589-0.189-8.88,0.72c-0.08,0-0.16,0-0.24,0
			c-5.015,0.825-10.18,1.5-15.119,2.4c-0.557-0.077-0.922,0.038-1.2,0.239c-0.869,0.571-2.172,0.709-3.36,0.96
			c-0.08,0-0.16,0-0.24,0c-2.83,1.512-6.423,1.167-9.12,2.4c-1.822,0.834-2.237,3.104-4.319,3.12
			c-2.768-3.799,2.472-7.064,6.479-7.2c0.08,0,0.16,0,0.24,0c1.185-0.655,2.687-0.994,4.32-1.2c0.08,0,0.16,0,0.24,0
			c0.868-0.571,2.171-0.708,3.359-0.96c0.08,0,0.16,0,0.24,0c7.629-1.331,11.103-6.817,20.16-6.72c0.08,0,0.16,0,0.24,0
			c0.992-0.368,2.279-0.44,3.6-0.48c0.08,0,0.16,0,0.24,0c0.835-0.365,1.966-0.433,3.12-0.479c0.08,0,0.159,0,0.239,0
			c0.936-0.425,2.149-0.57,3.36-0.72c0.08,0,0.16,0,0.24,0c1.529-0.471,2.964-1.036,4.8-1.2c0.691-0.029,1.571,0.131,1.92-0.24
			c1.285-0.555,2.876-0.805,4.561-0.96c0.079,0,0.159,0,0.239,0c1.484-0.356,2.675-1.005,3.84-1.68
			c-0.078-0.562-0.288-0.992-0.479-1.44c-0.575-0.704-1.085-1.476-1.68-2.16c-2.281-0.487-5.111-0.183-7.2,0.24
			c-0.048,0.927-0.009,1.769,0.24,2.4c0.579,0.46,1.928,0.151,2.88,0.239c0.08,0,0.159,0,0.239,0c2.563-0.331,1.013,3.438-1.92,2.4
			c-0.239,0-0.479,0-0.72,0c-2.525,0.124-3.469-2.608-3.12-4.56c0-0.08,0-0.16,0-0.24c-0.065-0.073,0.082-0.688,0-0.721
			c-2.554-0.998-7.695,0.739-10.8,0c-0.08,0-0.16,0-0.24,0c-2.805,0.396-5.991,0.409-8.399,1.2c-0.08,0-0.16,0-0.24,0
			c-2.317,0.963-4.973,1.588-7.2,2.641c-0.597-0.037-0.743,0.377-1.2,0.479c-0.571,0.629-1.481,0.919-2.399,1.2
			c-0.08,0-0.16,0-0.24,0c-0.704,0.576-2.076,0.483-3.36,0.479c-1.528-0.009-2.697,0.343-3.84,0.721
			c-1.983,0.736-4.255,1.185-6.48,1.68c-0.079,0-0.159,0-0.239,0c-0.96,0.561-2.242,0.799-3.601,0.96
			c-0.733-0.173-0.874,0.245-1.439,0.24c-0.457-0.104-0.604-0.518-1.2-0.48c-7.759,5.041-12.031,14.85-21.84,17.04
			c-0.08,0-0.16,0-0.24,0c-2.426-1.3,0.544-3.602,2.64-3.359c3.383-3.098,6.699-6.261,9.841-9.601c0.311-0.595-0.536-1.164,0-1.2
			c0.236-0.563,0.421-1.179,0.479-1.92c0.171-1.108,0.484-2.075,0.96-2.88c0.272-0.847,0.744-1.496,0.72-2.64
			c0.6-2.201,1.025-4.574,1.92-6.48c0-0.08,0-0.159,0-0.239c0.505-1.976,1.016-3.944,1.921-5.521c0-0.08,0-0.16,0-0.24
			c0.863-1.721,0.872-4.664,0.479-6.96c0.076-0.557-0.038-0.921-0.24-1.199c-0.551-0.97-0.885-2.155-1.92-2.641
			c-1.086,0.515-0.646,2.555-1.439,3.36c0,0.08,0,0.16,0,0.24c-0.001,0.959-0.479,1.44-0.48,2.399
			c0.571,0.398,0.473-1.918,1.92-1.439c1.207,2.954-1.566,5.471-3.359,6.96c0,0.08,0,0.159,0,0.239c-0.25,3.608-2.869,5.52-2.4,9.12
			c-4.514,10.847-13.834,16.886-18,28.08c0.008,4.55-2.557,8.222-4.08,12c0,0.08,0,0.16,0,0.24c-0.558,1.683-1.248,3.232-2.16,4.561
			c0,0.08,0,0.159,0,0.239c-1.204,2.956-2.74,5.58-4.08,8.4c0,0.08,0,0.16,0,0.24c-0.023,1.976-0.741,3.258-1.2,4.8
			c-0.276,0.283-0.271,0.849-0.239,1.439c0.429,3.469-2.595,3.486-4.08,5.04c2.761,2.097,5.658,5.275,7.92,9.12
			c1.406,2.392,3.254,5.229,2.64,7.92c-5.65-4.279-7.306-13.8-15.12-15.119c-0.08,0-0.16,0-0.239,0
			c-0.35,0.371-0.937,0.504-1.681,0.479c1.44,3.6,3.014,7.066,3.84,11.28c-0.076,0.557,0.039,0.921,0.24,1.2
			c0.412,0.788,0.636,1.765,0.72,2.88c0,0.08,0,0.16,0,0.239c0.436,1.086,0.813,2.229,0.96,3.601c0.042,1.079,0.191,2.049,0.48,2.88
			c0.628,1.173,0.41,2.613,0.24,4.08c0,0.08,0,0.16,0,0.24c0.466,1.533,0.508,3.491,0.479,5.52c0,0.08,0,0.16,0,0.24
			c0.559,1.842,0.082,4.719,0.24,6.96c0.68-0.04,1-0.439,1.68-0.48c0.744,0.024,1.146-0.293,1.681-0.479
			c1.575-0.904,3.545-1.415,5.52-1.92c0.502,0.102,0.636-0.165,0.96-0.24c0.82-0.54,1.952-0.768,3.12-0.96
			c0.895,0.015,1.518-0.242,2.16-0.479c1.827-0.894,4.064-1.376,6-2.16c0.08,0,0.16,0,0.24,0c1.013-0.747,2.216-1.305,3.84-1.44
			c0.691-0.028,1.571,0.131,1.92-0.24c4.21-1.094,7.359-4.314,10.56-6.96c2.517-2.08,5.152-4.191,7.2-6.239
			c5.741-5.742,11.257-11.067,18-15.12c1.843-2.398,4.144-4.337,6-6.721c-0.218-0.821-1.414-0.665-0.96-2.159
			c1.498-0.905,4.13-1.948,6.24-1.44c0.519,0.201,0.818,0.621,1.68,0.479c1.021,0.1,1.82,0.42,2.4,0.961c0.08,0,0.16,0,0.24,0
			c1.038,0.321,2.198,0.521,2.88,1.199c0.08,0,0.16,0,0.24,0c3.131,0.789,5.727,2.113,9.12,2.641c0,0.08,0,0.159,0,0.239
			c0.8,0,1.6,0,2.399,0c1.572,0.053,2.713-0.327,4.08-0.479c0.32-0.4,1.067-0.373,1.44-0.72c1.341-0.499,2.317-1.363,3.119-2.4
			c0-0.08,0-0.16,0-0.24c0-0.239,0-0.479,0-0.72c-0.582-1.098-2.058-1.303-3.119-1.92c-0.214-0.187-0.542-0.258-0.96-0.24
			c-0.681-0.04-1.001-0.439-1.681-0.479c0.486,0.711-0.241,2.264-0.72,3.359c0.412,0.788,1.874,0.526,1.68,1.92
			c-3.569,3.563-5.914-4.849-1.68-5.52c-0.359-0.041-0.886,0.086-0.96-0.24c-1.632-0.528-3.242-1.078-4.8-1.68
			c-1.464-0.057-1.815-1.225-2.88-1.681c-1.987-0.573-3.708-1.412-6-1.68c-1.729-0.191-3.149-0.69-4.561-1.2c-0.08,0-0.16,0-0.24,0
			c-2.256-0.304-4.367-0.752-6.479-1.2c-0.08,0-0.16,0-0.24,0c-3.526-0.793-8.628-0.012-10.56-2.399
			c0.675-1.404,2.251-1.908,4.319-1.92c0.08,0,0.16,0,0.24,0c1.364-0.399,4.396-0.399,5.76,0c0.08,0,0.16,0,0.24,0
			c0.966,0.154,2.552-0.313,3.12,0.24c0.08,0,0.16,0,0.24,0c1.522,0.157,3.676-0.316,4.8,0.239c0.08,0,0.16,0,0.24,0
			c2.862,0.018,5.26,0.5,7.68,0.96c0.08,0,0.16,0,0.24,0c1.268,0.013,2.452,0.107,3.359,0.48c0.08,0,0.16,0,0.24,0
			c3.613,0.227,6.755,0.925,10.8,0.72c0.08,0,0.16,0,0.24,0c2.18-0.461,4.707-0.573,6.96-0.96c0.08,0,0.16,0,0.24,0
			c3.752-1.848,8.269-2.932,8.64-8.16c-2.645-0.165-4.898,0.062-6.72,0.721c-0.08,0-0.16,0-0.24,0
			c-0.489,0.551-1.993,0.087-2.88,0.239c0.529,0.957-0.069,1.718,0,2.88c0.773,0.054,1.016-0.424,1.92-0.239c0.08,0,0.16,0,0.24,0
			c1.83-0.74,1.738,2.764-0.48,2.399c-0.079,0-0.159,0-0.239,0c-0.929-0.128-1.172,0.429-2.16,0.24c-0.16,0-0.32,0-0.48,0
			c-1.492-0.594-1.164-3.721-1.199-5.04c-8.39-0.251-14.045-3.235-21.12-4.8c-0.08,0-0.16,0-0.24,0
			c-0.983-0.057-1.963-0.118-2.641-0.48c-0.079,0-0.159,0-0.239,0c-1.199-0.081-2.448-0.111-3.36-0.479c-0.08,0-0.16,0-0.24,0
			c-0.966-0.154-2.552,0.312-3.12-0.24c-0.771-0.537-2.26-0.132-3.119,0c-0.886,0.555-2.797,0.084-4.08,0.24
			c-0.751,0.049-1.763-0.162-2.16,0.24c-1.124,0.556-3.277,0.082-4.8,0.239c-0.08,0-0.16,0-0.24,0
			c-2.617,0.894-4.913-0.326-4.8-1.92c0.181-2.547,6.946-3.278,9.12-4.08c0.079,0,0.159,0,0.239,0
			c2.417-0.703,4.972-1.268,8.16-1.199c0.08,0,0.16,0,0.24,0c1.362-0.558,3.758-0.083,5.52-0.24c0.08,0,0.16,0,0.24,0
			c2.656-0.464,5.557-0.684,8.641-0.72c0.079,0,0.159,0,0.239,0c1.917-0.403,3.961-0.68,6.24-0.721c0.08,0,0.16,0,0.24,0
			c0.41-0.549,1.832-0.088,2.64-0.24c0.08,0,0.16,0,0.24,0c0.333-0.547,1.671-0.089,2.4-0.239c0.079,0,0.159,0,0.239,0
			c0.66-0.38,1.255-0.825,2.4-0.721c0.08,0,0.16,0,0.24,0c4.275-0.924,8.265-2.135,11.76-3.84c0.08,0,0.16,0,0.24,0
			c2.634-1.605,6.209-2.271,7.68-5.04C457.162,525.332,457.488,523.551,456.961,522.66z M377.041,578.58c0,0.08,0,0.16,0,0.24
			c-2.544,3.686-5.658,6.617-8.881,9.84c-0.95,0.951-2.137,2.588-3.84,2.399c-0.333-3.176,2.456-5.404,5.28-8.399
			c0.853-0.904,2.227-2.028,3.84-3.36C374.057,578.791,378.305,575.638,377.041,578.58z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D4BCB0" d="M144.72,574.5c0.165,0.092,1.924-0.679,2.16,0.479
			c0.502,0.139,0.685,0.596,1.44,0.48c0.48,0,0.96,0,1.44,0c0.305-0.575,0.876-0.884,0.72-1.92c-0.359-0.041-0.886,0.086-0.96-0.24
			c-1.153-0.287-2.185-0.694-3.6-0.72c-0.24,0-0.48,0-0.72,0c-0.637,0.347,0.881,0.536,0.48,1.439
			C145.123,573.949,144.555,573.417,144.72,574.5z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D4BCB0" d="M206.88,346.5c0.077-0.557-0.039-0.921-0.24-1.2
			c-1.444-4.956-2.434-10.366-4.32-14.88c0.004,4.557,2.432,8.768,1.92,14.64c0.545,0.414,0.927,0.993,1.92,0.96
			c0,0.08,0,0.16,0,0.24C206.353,346.386,206.55,346.51,206.88,346.5z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D4BCB0" d="M149.76,526.979c0.278,0.202,0.643,0.317,1.2,0.24
			c0.4,0,0.8,0,1.2,0c1.048-0.312,0.935-1.785,1.68-2.399c-2.042-1.479-4.599-2.441-6.96-3.601c-0.08,0-0.16,0-0.24,0
			c-0.939-0.14-1.394,0.207-1.92,0.48C144.389,525.471,147.517,525.783,149.76,526.979z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D4BCB0" d="M210.96,562.74c-3.803-4.094-7.506-8.657-12.72-11.28
			c-4.255-6.065-13.104-7.536-18.72-12.24c-0.08,0-0.16,0-0.24,0c-3.993-0.679-6.241-2.836-9.36-5.04
			c-2.03-1.436-4.18-2.397-6.24-3.6c-0.887-0.519-1.482-1.461-2.4-1.92c-1.93-0.967-3.835-1.133-5.28-2.881
			c-0.619,3.403-4.598,4.797-7.92,2.881c2.09,4.635,7.586,6.413,12.72,8.88c2.41,1.157,4.628,3.186,6.96,4.8
			c2.654,1.838,4.926,3.324,7.44,4.56c3.041,1.495,5.784,2.203,9.36,2.881c0.279,0.201,0.644,0.316,1.2,0.239
			c4.443,1.738,9.568,2.809,12.72,5.761c0.624,0.584,0.865,1.524,1.44,2.159c1.896,2.095,5.545,3.578,5.76,6.961
			c-0.233,0.006-0.186,0.293-0.24,0.479c-5.171,1.299-6.019-5.132-9.36-7.2c-2.388-1.478-7.192-2.971-11.04-3.6
			c-0.08,0-0.16,0-0.24,0c-1.42-0.021-2.638-0.242-3.6-0.721c-1.153-0.287-2.185-0.694-3.6-0.72c-1.938-0.302-3.8-0.68-5.28-1.439
			c-0.08,0-0.16,0-0.24,0c-2.859-0.82-5.823-1.537-8.4-2.641c-0.586-0.213-1.041-0.559-1.92-0.479
			c-2.508-0.532-4.843-1.237-6.72-2.4c-0.08,0-0.16,0-0.24,0c-2.793-1.047-6.333-1.347-8.64-2.88c-0.08,0-0.16,0-0.24,0
			c-1.532-0.067-2.946-0.254-4.08-0.72c-0.08,0-0.16,0-0.24,0c-1.754-0.086-3.01-0.67-4.8-0.721
			c-0.693,2.749-2.505,5.654-6.24,4.561c-0.278-0.201-0.644-0.316-1.2-0.24c-1.619-0.062-2.987-1.994-1.44-3.12
			c2.413,1.055,5.96,1.631,6.24-1.68c-2.213-0.907-4.869-1.371-6.96-2.4c-3.986,2.351-0.175,9.398,2.88,10.801
			c0.862,0.396,1.906,0.138,2.88,0.479c0.673,0.236,1.039,0.973,1.68,1.2c1.059,0.376,3.045,0.027,4.08,0.72c0.08,0,0.16,0,0.24,0
			c0.943,0.017,1.804,0.116,2.4,0.48c0.31,0.33,1.042,0.237,1.68,0.239c5.317,0.604,10.533,1.308,15.12,2.641c0.08,0,0.16,0,0.24,0
			c4.079,0.722,8.132,1.468,12,2.399c0.08,0,0.16,0,0.24,0c3.53,0.47,6.795,1.205,9.84,2.16c0.08,0,0.16,0,0.24,0
			c2.857,0.185,5.226,1.913,7.2,3.12c2.699,1.651,7.163,1.938,7.68,5.521c-4.984,1.572-8.04-2.802-12-3.84
			c-0.663-0.298-1.219-0.701-2.16-0.721c-2.646-0.714-5.406-1.313-7.92-2.16c-0.284-0.276-0.848-0.271-1.44-0.239
			c-8.641-1.28-16.499-3.342-24.96-4.801c-1.547-0.372-3.234-0.605-5.04-0.72c-1.79-0.051-3.046-0.634-4.8-0.72
			c1.066,5.642-5.74,6.412-9.12,4.08c0.028,2.275,2.43,2.554,4.56,3.6c3.914,1.922,7.723,4.452,12,5.28c0.08,0,0.16,0,0.24,0
			c3.349,0.891,7.098,1.382,10.8,1.92c0.16,0,0.32,0,0.48,0c1.783-0.103,2.97,0.39,4.56,0.48c0.653,0.307,0.756,1.163,1.44,1.439
			c3.818,0.422,7.136,1.344,10.8,1.92c0.398,0.402,1.409,0.191,2.16,0.24c3.392,1.563,11.379-0.622,11.76,2.88
			c0.517,4.754-12.837,1.318-14.88,0.72c-0.08,0-0.16,0-0.24,0c-3.998-1.603-9.427-1.772-13.68-3.12
			c-0.279-0.201-0.644-0.316-1.2-0.239c-1.508-0.093-2.641-0.56-4.08-0.721c-0.326,3.81-5.99,4.216-8.16,1.681
			c2.491,4.948,8.295,6.585,14.4,7.92c0.08,0,0.16,0,0.24,0c2.042,0.277,4.315,0.324,5.76,1.2c0.279,0.201,0.644,0.316,1.2,0.239
			c7.506,2.095,17.44,1.761,21.6,7.2c2.654-0.546,4.717-1.683,7.44-2.16c0.08,0,0.16,0,0.24,0c3.952-1.407,8.73-1.99,13.2-2.88
			c1.456-0.063,2.657-0.383,3.84-0.72c0.596-0.364,1.457-0.463,2.4-0.48c0.638-0.002,1.371,0.091,1.68-0.239
			c2.896-0.704,6.151-1.05,9.36-1.44c0.691-0.028,1.571,0.131,1.92-0.24c-1.225-2.546-4.273-5.318-6.48-7.68
			C218.719,571.283,214.815,566.89,210.96,562.74z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D4BCB0" d="M335.04,305.939c0.08,0,0.16,0,0.24,0
			c2.919-0.842,3.54-3.98,3.12-8.16c-1.093,0.479-2.245,0.541-3.12-0.24c-0.154,1.045,0.313,2.713-0.24,3.36c0,0.08,0,0.16,0,0.24
			c-0.499,2.301-1.872,3.728-2.64,5.76C333.002,307.25,334.041,305.988,335.04,305.939z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D4BCB0" d="M332.881,372.419c0-0.48,0-0.96,0-1.44
			c-0.557-1.203-0.083-3.438-0.24-5.04c0.08,0,0.16,0,0.24,0c0-0.16,0-0.32,0-0.48c0-0.88,0-1.76,0-2.64c0-0.16,0-0.32,0-0.48
			c0-0.24,0-0.48,0-0.72c0-0.72,0-1.44,0-2.16c0.08,0,0.159,0,0.239,0c0-0.16,0-0.32,0-0.48c0-0.8,0-1.6,0-2.4c0-0.32,0-0.64,0-0.96
			c0-0.48,0-0.96,0-1.44c0.55-0.411,0.089-1.832,0.24-2.64c0.292-1.068,0.261-2.459,0.24-3.84c0.545-0.255,0.091-1.508,0.24-2.16
			c0-0.32,0-0.64,0-0.96c0-0.48,0-0.96,0-1.44c0.08,0,0.159,0,0.239,0c0-0.08,0-0.16,0-0.24c0-1.36,0-2.72,0-4.08
			c0-0.32,0-0.64,0-0.96c0-2.494,0-4.988,0-7.481c0,0,0,0,0-0.001v0c0.014-0.322,0.024-0.634,0.033-0.943
			c0.003-0.091,0.006-0.182,0.008-0.271c0.007-0.277,0.012-0.547,0.014-0.811c0-0.108,0-0.213,0-0.319
			c0-0.25-0.002-0.494-0.009-0.728c-0.002-0.097-0.007-0.187-0.011-0.281c-0.009-0.234-0.019-0.468-0.035-0.683
			c0-0.721,0-1.441,0-2.162c0-0.16,0-0.32,0-0.48c-0.159,0-0.319,0-0.479,0c-0.046,0.194-0.241,0.239-0.24,0.48
			c-0.451,0.349-0.029,1.571-0.479,1.92c-0.126,1.154-0.512,2.048-0.721,3.12c-0.541,1.379-0.479,3.362-0.96,4.8
			c-0.194,1.965-0.929,3.391-0.96,5.52c-0.547,0.333-0.089,1.67-0.24,2.4c0,1.36,0,2.72,0,4.08c0,0.88,0,1.76,0,2.64
			c-0.079,0-0.159,0-0.239,0c0,0.96,0,1.92,0,2.88c0,0.08,0,0.16,0,0.24c-0.554,0.726-0.085,2.476-0.24,3.6c0.08,0,0.16,0,0.24,0
			c0,1.2,0,2.4,0,3.6c-0.08,0-0.16,0-0.24,0c0,0.8,0,1.6,0,2.4c-0.397,2.643-0.637,5.443-1.68,7.44c0,0.241-0.194,0.286-0.24,0.48
			c-5.755,4.966-12.442,8.998-17.28,14.88c0.991-0.208,1.648-0.751,2.641-0.96c0.079,0,0.159,0,0.239,0
			c1.332-0.908,2.996-1.484,4.561-2.16c0.08,0,0.16,0,0.239,0c3.077-1.724,6.288-3.313,9.36-5.04c1.65-1.069,3.469-1.972,4.8-3.36
			C332.548,372.727,332.573,372.433,332.881,372.419z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D4BCB0" d="M316.801,348.66c0.961-2.239,2.516-3.884,3.359-6.24
			c1.331-1.709,2.189-3.891,3.12-6c1.213-2.787,2.532-5.468,3.601-8.4c0.536-0.664,0.934-1.466,1.199-2.4
			c0.125-0.355,0.355-0.605,0.48-0.96c0.526-1.473,1.162-2.837,1.439-4.56c0.532-0.834,0.794-3.199,2.4-2.16
			c0.315,0.085,0.378,0.422,0.72,0.48c-0.026,1.19,0.236,1.179,0,2.16c0.605,0.43,1.599-0.356,2.16-0.72
			c1.851-0.87,3.253-2.188,4.08-4.08c0.914-1.485,1.246-3.554,1.92-5.28c0.241-0.799,0.432-1.649,0.72-2.4
			c0.537-1.384,0.537-3.304,1.2-4.56c0.322-1.118,0.818-2.062,1.2-3.12c0.188-1.091,0.888-1.673,0.96-2.88
			c0.225-0.176,0.363-0.437,0.48-0.72c0.21-0.669,0.574-1.186,0.72-1.92c0.348-0.373,0.32-1.12,0.72-1.44
			c0.193-1.007,0.844-1.557,0.96-2.64c0.615-0.825,1.094-1.786,1.44-2.88c0.127-0.193,0.25-0.39,0.24-0.72
			c0.536-0.664,0.934-1.466,1.199-2.4c2.622-5.782,1.377-14.575,0-20.88c0.07-2.55-2.067-2.892-4.08-3.36
			c-0.192-0.127-0.39-0.25-0.72-0.24c0.041-0.521-0.851-0.109-1.2-0.24c-1.977-0.141-3.123,4.277-4.319,6.24
			c-2.858,4.69-5.463,7.714-7.681,12.96c-1.148,2.718-2.647,5.07-3.359,7.2c0,0.08,0,0.16,0,0.24c0.168,2.329-2.174,2.146-1.92,4.56
			c-0.445,0.515-0.162,1.758-0.24,2.64c-0.193,2.979,0.518,5.402-0.24,7.68c-0.08,0-0.16,0-0.24,0c0,0.4,0,0.8,0,1.2
			c-0.233,0.007-0.186,0.294-0.239,0.48c-0.524,0.127-1.553,0.581-1.681-0.24c-0.562-0.078-0.232-1.047-0.72-1.2
			c0.127-1.007-0.419-1.341-0.48-2.16c-0.235-1.364-0.559-2.641-0.96-3.84c-0.154-0.885-0.246-1.833-0.72-2.4
			c-0.704-3.776-1.695-7.265-2.64-10.8c-0.257-1.263-0.544-2.496-1.2-3.36c-0.683-5.718-3.78-9.02-3.84-15.36
			c-0.222-1.572-1.303-3.181-0.72-4.8c0.44-0.999,1.686-1.194,2.159-2.16c0.899-0.758,0.679-1.69,0.721-3.6c0-0.32,0-0.64,0-0.96
			c-0.843-0.043-0.79,0.811-1.44,0.96c-0.606,0.593-1.456,0.944-1.92,1.68c-0.08,0-0.16,0-0.24,0
			c-0.477,0.003-0.305,0.655-0.96,0.48c-0.262,0.539-1.178,0.422-1.439,0.96c-0.431-0.03-0.507,0.294-0.96,0.24
			c-0.457,0.103-0.604,0.517-1.2,0.48c0,0.08,0,0.16,0,0.24c-1.427,0.413-2.563,1.118-4.08,1.44c-0.932,0.268-1.229,1.171-2.16,1.44
			c0.044,0.204-0.094,0.226-0.24,0.24c-1.419,0.3-1.715-0.524-2.399-0.96c0-0.08,0-0.16,0-0.24c-0.171-1.691,0.896-2.145,1.439-3.12
			c-0.727-1.753-0.746-4.214-1.2-6.24c-0.555-0.885-0.083-2.796-0.239-4.08c0-0.64,0-1.28,0-1.92c-0.355-0.278-0.605-0.278-0.96,0
			c-1.424,0.417-2.961,0.719-4.561,0.96c-0.08,0-0.16,0-0.24,0c-0.435,0.525-1.879,0.041-2.399,0.48c-0.08,0-0.16,0-0.24,0
			c-3.427,2.253-8.876,2.483-13.68,3.36c3.005,0.803,6.525,2.503,9.6,3.36c1.051,0.293,2.835-0.355,2.88,1.44
			c-0.081,0.967-1.76,1.589-2.64,0.96c-0.32,0-0.64,0-0.96,0c-1.683-0.158-3.997,0.317-5.28-0.24
			c-0.349-0.371-1.229-0.211-1.92-0.24c-1.787-0.133-3.558-0.282-4.8-0.96c-0.08,0-0.16,0-0.24,0c-0.956,0.3-1.69-0.848-2.16-0.24
			c1.977,3.704,6.521,4.838,9.12,7.92c-0.512,0.528-1.517,0.563-2.4,0.72c-0.24,0-0.48,0-0.72,0c-1.233,0.033-2.365-0.035-3.12-0.48
			c-0.08,0-0.16,0-0.24,0c-5.783-2.137-10.527-5.313-12.48-11.28c-0.16,0-0.32,0-0.48,0c-0.08,0-0.16,0-0.24,0
			c-1.634,0.446-3.498,0.663-5.28,0.96c-1.048-0.008-1.872,0.208-2.64,0.48c-0.697,0.99-1.865-0.827-2.4,0
			c-0.09,4.396-4.406,7.53-6.96,11.04c-3.336,4.585-5.568,9.01-7.44,14.4c-5.044,1.294-5.952-5.517-10.56-6.24
			c-1.402-3.798-5.78-4.62-6.48-9.12c0-0.08,0-0.16,0-0.24c-0.53-0.03-0.102-1.018-0.24-1.44c-1.481-0.519-3.218-0.782-4.32-1.68
			c-0.08,0-0.16,0-0.24,0c-1.841-0.639-3.332-1.628-5.04-2.4c0.727,2.143,2.669,2.704,2.4,4.8c-0.224,1.747-2.504,2.299-1.68,4.56
			c0,0.08,0,0.16,0,0.24c0.802,3.008,0.461,6.999-0.72,9.36c0,0.08,0,0.16,0,0.24c-1.038,3.842-2.393,7.367-4.08,10.56
			c0,0.08,0,0.16,0,0.24c-0.722,2.078-1.705,3.895-2.64,5.76c0,0.08,0,0.16,0,0.24c0.18,1.619-0.386,2.494-0.24,4.08
			c0.386,0.456,1.637,1.585,2.429,1.352l0.932,0.329c0.601,1.399,1.04,2.96,2.16,3.84c0.727-1.807,1.902-3.166,3.346-4.257
			l14.643-5.152c0.534,0.022,1.03,0.115,1.211,0.289c0.08,0,0.16,0,0.24,0c1.608-0.342,2.105,2.629,0.24,2.4c-0.24,0-0.48,0-0.72,0
			c-0.8,0-1.6,0-2.4,0c-0.08,0-0.16,0-0.24,0c-0.906,0.374-2.08,0.48-3.12,0.72c-0.861-0.141-1.161,0.279-1.68,0.48
			c-0.319,0.481-1.065,0.535-1.68,0.72c0.999,0.352,2.546-0.809,4.08-0.72c0.08,0,0.16,0,0.24,0c1.119-0.472,3.716-0.361,4.56,0.24
			c0.08,0,0.16,0,0.24,0c5.228,1.572,9.514,4.086,12,8.4c-0.143,0.497,0.296,1.577-0.24,1.68c0.564,0.396,1.397,0.523,1.68,1.2
			c-0.155,1.045,0.313,2.713-0.24,3.36c0,0.08,0,0.16,0,0.24c0.294,4.806,0.804,12.059,0.48,17.76c-0.098,1.727-0.05,3.922-1.44,4.8
			c-1.59-0.009-1.502-1.697-1.68-3.12c-3.153,4.034-3.996,6.788,0,9.6c0.997,0.702,1.968,1.792,2.64,2.16
			c0.678,0.372,1.381,0.124,1.92,0.48c1.322,0.874,3.46,4.355,4.8,5.28c0.315,0.308,0.885,0.308,1.2,0
			c1.258-0.821,2.92-1.24,3.6-2.64c1.141-0.94,2.18-1.98,3.12-3.12c0.08,0,0.16,0,0.24,0c0.343-0.058,0.405-0.395,0.72-0.48
			c0.815-1.185,2.022-1.978,3.12-2.88c0.495-0.865,1.425-1.295,1.92-2.16c0.08,0,0.16,0,0.24,0c0.342-0.058,0.404-0.395,0.72-0.48
			c0.106-0.214,0.211-0.429,0.48-0.48c0.454-0.827,1.391-1.169,1.68-2.16c0.08,0,0.16,0,0.24,0c1.46-1.26,2.82-2.62,4.08-4.08
			c0.763,0.037,1.056,0.544,1.92,0.48c-0.627,1.693-2.168,2.472-3.12,3.84c0.8,0,1.6,0,2.4,0c0,0.08,0,0.16,0,0.24
			c0.08,0,0.16,0,0.24,0c0.943,0.017,1.804,0.116,2.4,0.48c0.08,0,0.16,0,0.24,0c2.128,0.297,3.73,0.602,5.52,0.72
			c0.663,0.297,1.219,0.701,2.16,0.72c0.959,0.08,1.332-0.428,2.4-0.24c2.262-6.656,8.013,4.515,9.12,6.96
			c2.47,4.331,7.039,6.561,10.32,10.08c0.034,0.754-0.615,0.825-1.2,0.96c0-0.08,0-0.16,0-0.24c-0.08,0-0.16,0-0.24,0
			c-2.884-1.196-5.056-3.104-7.199-5.04c0.224,2.656,0.943,4.816,1.199,7.44c0,0.08,0,0.16,0,0.24
			c2.646,5.194,2.452,13.229,4.32,19.2c-0.062-2.266,2.399-3.536,3.12-5.76c0.187-0.213,0.258-0.542,0.24-0.96
			c1.139-9.901,7.04-15.039,13.68-19.44C316.515,348.854,316.56,348.659,316.801,348.66z M328.801,307.859
			c0.368-1.472,1.312-2.368,1.68-3.84c0.826-0.293,0.613-1.627,1.44-1.92c0.07-0.49,0.649-0.471,0.72-0.96c0-0.24,0-0.48,0-0.72
			c0-0.24,0-0.48,0-0.72c0-0.16,0-0.32,0-0.48c-0.152-0.808,0.309-2.229-0.24-2.64c0-0.72,0-1.44,0-2.16
			c0.554-0.726,0.085-2.476,0.24-3.6c0-0.32,0-0.64,0-0.96c-0.021-0.341-0.04-0.68,0.24-0.72c-0.074-0.874,0.038-1.562,0.239-2.16
			c0-0.24,0-0.48,0-0.72c0.467-1.133,0.823-2.377,1.44-3.36c1.344-2.496,3.038-4.642,4.32-7.2c0.08,0,0.159,0,0.239,0
			c0.638-0.963,1.283-1.917,1.92-2.88c0-0.08,0-0.16,0-0.24c0.08,0,0.16,0,0.24,0c0.638-0.963,1.283-1.917,1.92-2.88
			c0-0.08,0-0.16,0-0.24c0.817-0.542,1.055-1.666,1.681-2.4c0.045-0.194,0.24-0.239,0.239-0.48c0.085-0.315,0.423-0.377,0.48-0.72
			c0.162-0.558,0.798-0.643,0.96-1.2c0.307-0.328,1.133-0.328,1.44,0c0.045,0.194,0.24,0.239,0.239,0.48c0,0.08,0,0.16,0,0.24
			c0,0.24,0,0.48,0,0.72c-1.625,2.643,0.708,7.415,0.96,11.76c0.096,1.639-0.07,3.19,0,5.04c-0.642-0.071,0.639,0.931-0.479,0.72
			c0.477,0.554-0.061,0.267-0.24,0.72c-0.646,0.714-1.152,1.568-1.44,2.64c-1.42,1.859-1.425,5.135-4.079,5.76
			c0.044-0.204-0.094-0.227-0.24-0.24c0-0.08,0-0.16,0-0.24c-0.123-1.403,0.564-1.995,0.72-3.12c0-0.08,0-0.16,0-0.24
			c2.985-4.063,3.932-11.246,1.92-17.28c-1.234,1.165-1.905,2.895-3.12,4.08c1.593,2.883,2.402,10.508,0,13.2
			c-0.34,0.021-0.68,0.04-0.72-0.24c-0.295-0.104-0.493-0.307-0.48-0.72c-0.551-0.489-0.086-1.993-0.239-2.88c0.08,0,0.16,0,0.239,0
			c0-0.08,0-0.16,0-0.24c0-1.04,0-2.08,0-3.12c-0.079,0-0.159,0-0.239,0c0-0.16,0-0.32,0-0.48c0.128-0.928-0.429-1.171-0.24-2.16
			c0-0.08,0-0.16,0-0.24c-1.22,0.224-1.902,3.056-3.12,4.08c-0.267,1.253-1.235,1.805-1.44,3.12c-0.127,0.193-0.25,0.39-0.239,0.72
			c-0.405,0.475-0.263,1.498-0.48,2.16c-0.035,0.595-0.045,1.165,0.48,1.2c1.349,0.572,2.232,1.607,3.359,2.4
			c0.046,0.194,0.241,0.239,0.24,0.48c0.229,0.49,0.49,0.95,0.72,1.44c0.362,0.438,0.255,1.345,0.721,1.68
			c0.109,1.25,0.219,2.501,0.479,3.6c-0.554,0.726-0.084,2.476-0.24,3.6c-0.351,0.689-0.324,1.755-0.96,2.16c0,0.08,0,0.16,0,0.24
			c-0.07,0.49-0.649,0.471-0.72,0.96c-1.793,1.087-3.618,2.142-5.76,2.88c-1.396,0.252-2.805,1.611-4.32,0.96
			C328.326,310.116,328.14,308.655,328.801,307.859z M304.32,262.499c-0.03-0.43,0.294-0.506,0.24-0.96
			c0.209-0.351,0.359-0.761,0.96-0.72c0.89,2.71-0.009,7.209,1.68,9.12c-0.435,0.524-0.807,1.113-1.92,0.96c0-0.08,0-0.16,0-0.24
			c-0.343-0.057-0.404-0.395-0.72-0.48c-0.397-0.803-0.849-1.551-0.72-2.88c-0.397-0.259-0.397-2.141,0-2.4
			C303.733,263.832,304.011,263.15,304.32,262.499z M291.36,318.899c-0.08,0-0.16,0-0.24,0c-1.528,0.563-3.848-0.148-5.04-0.72
			c-0.771-0.269-1.423-0.657-2.4-0.72c-1.927-0.727-4.177-1.435-6-2.4c-1.59-0.842-3.936-1.862-4.08-4.08
			c2.903-0.341,4.999,2.024,7.44,3.12c2.521,1.131,5.192,1.878,8.16,2.64c0.146,0.014,0.284,0.036,0.24,0.24
			c0.651-0.149,1.904,0.305,2.16-0.24c0.592,0.032,1.156,0.037,1.439-0.24c0.331,0.01,0.527-0.113,0.721-0.24
			c0.999-0.121,1.4-0.839,2.399-0.96c0.204-0.356,0.604-0.516,1.2-0.48c0.06-0.302,0.478-0.242,0.48,0
			C296.791,317.291,294.538,318.557,291.36,318.899z M311.04,307.619c-1.305-0.055-1.868-0.851-2.64-1.44
			c-0.584,0.196,0.476,1.266-0.24,1.68c-1.246-0.433-2.094-1.266-2.88-2.16c0.283-0.356,0.208-1.072,0.96-0.96
			c-0.213-0.507-0.73-0.709-0.72-1.44c0.193-0.207,0.657-0.143,0.72-0.48C308.01,304.107,311.287,305.11,311.04,307.619z
			 M313.44,292.739c-0.283,0.117-0.544,0.256-0.72,0.48c-0.32,0-0.641,0-0.96,0c-1.757,0.403-3.583,0.737-5.761,0.72
			c-0.984,0.025-1.959,0.039-2.399-0.48c-0.08,0-0.16,0-0.24,0c-0.857-0.103-1.317-0.603-1.68-1.2
			c-0.209-0.929,0.586-0.854,0.72-1.44c-0.733-0.173-0.875,0.246-1.44,0.24c0.284,0.596,0.348,1.412,0.48,2.16
			c-2.865,4.28-5.444,9.221-11.04,10.08c-1.24,0.19-2.422-0.289-3.84-0.24c-1.198,0.041-2.549,0.742-4.08,0.48
			c-0.08,0-0.16,0-0.24,0c-2.711,0.55-6.37-0.028-8.64-0.72c-0.08,0-0.16,0-0.24,0c-1.629,0.144-4.656-2.065-1.92-2.64
			c-1.948-0.532-4.283-0.677-4.08-3.36c-0.187,0.053-0.279,0.201-0.24,0.48c-0.68,3.279-4.024-0.308-1.92-1.92c0-0.08,0-0.16,0-0.24
			c1.837-3.603,5.338-5.542,9.12-7.2c0.08,0,0.16,0,0.24,0c0.937-0.743,2.156-1.204,3.6-1.44c0.08,0,0.16,0,0.24,0
			c0.717-0.483,1.694-0.707,2.88-0.72c0.08,0,0.16,0,0.24,0c2.844-0.76-1.976-0.395-3.36-0.48c-0.08,0-0.16,0-0.24,0
			c-0.651,0.389-1.771,0.309-2.64,0.48c-0.418-0.018-0.746,0.054-0.96,0.24c-2.454,0.906-3.22,3.5-6,4.08
			c-1.034-2.892,2.476-5.133,4.8-6.24c1.367-0.313,2.708-0.653,4.08-0.96c0.489-0.551,1.993-0.087,2.88-0.24c0.72,0,1.44,0,2.16,0
			c1.212-0.012,2.307,0.094,3.12,0.48c1.122,0.318,2.432,0.448,3.84,0.48c1.055-0.014,1.983,0.097,2.641,0.48
			c0.079,0,0.159,0,0.239,0c1.115,0.085,2.092,0.308,2.88,0.72c0.35,0.371,1.229,0.211,1.921,0.24c2.525,0.194,3.107,2.333,4.8,3.36
			c1.117,0.077,1.602-0.479,2.64-0.48c0.861,0.141,1.161-0.279,1.68-0.48c0.723-0.558,1.709-0.852,2.641-1.2c0.08,0,0.16,0,0.24,0
			c0.756-0.523,1.907-0.653,2.399-1.44c0.703,0.063,0.737-0.542,1.44-0.48c0.24,0.001,0.285-0.194,0.479-0.24
			c0.109,0.108,0.192,0.216,0.266,0.322c0.024,0.036,0.045,0.072,0.065,0.107c0.04,0.07,0.072,0.14,0.099,0.209
			c0.015,0.041,0.033,0.082,0.044,0.122c0.024,0.091,0.038,0.182,0.039,0.271c-0.009,0.054-0.016,0.106-0.032,0.168
			c-0.393,0.648-0.938,1.142-1.681,1.44c-0.714,0.646-1.567,1.152-2.64,1.44c0.001,0.241-0.194,0.286-0.24,0.48
			c-0.832,0.128-1.277,0.642-2.16,0.72c-0.977,0.063-1.628,0.452-2.399,0.72c-0.349,0.372-0.937,0.504-1.68,0.48
			c0.485,0.794,2.129,0.431,2.88,0.96c0.389,0.36,1.771,0.36,2.16,0c0.65-0.39,1.771-0.31,2.64-0.48c0.08,0,0.16,0,0.24,0
			s0.16,0,0.239,0c0.331,0.01,0.527-0.113,0.721-0.24C314.525,290.102,315.884,292.008,313.44,292.739z"/>
			</g>
			<g id="black">
				<path fillRule="evenodd" clipRule="evenodd" d="M303.841,267.299c-0.129,1.329,0.322,2.077,0.72,2.88
			c0.315,0.085,0.377,0.423,0.72,0.48c0,0.08,0,0.16,0,0.24c1.113,0.153,1.485-0.436,1.92-0.96c-1.688-1.911-0.79-6.41-1.68-9.12
			c-0.601-0.041-0.751,0.369-0.96,0.72c0.054,0.454-0.271,0.53-0.24,0.96c-0.31,0.651-0.587,1.333-0.479,2.4
			C303.443,265.158,303.443,267.041,303.841,267.299z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M297.36,314.819c-0.596-0.036-0.996,0.124-1.2,0.48
			c-0.999,0.121-1.4,0.839-2.399,0.96c-0.193,0.127-0.39,0.25-0.721,0.24c-0.283,0.276-0.848,0.271-1.439,0.24
			c-0.256,0.545-1.509,0.091-2.16,0.24c0.044-0.204-0.094-0.227-0.24-0.24c-2.967-0.761-5.639-1.508-8.16-2.64
			c-1.938-0.87-3.659-2.535-5.739-3.017c0,0,0.001,0,0.001,0.001c-0.196-0.069-0.41-0.108-0.625-0.109
			c-0.348-0.037-0.706-0.039-1.078,0.005c0.144,2.218,2.49,3.238,4.08,4.08c1.823,0.965,4.073,1.673,6,2.4
			c0.977,0.063,1.629,0.451,2.4,0.72c1.192,0.572,3.511,1.284,5.04,0.72c0.08,0,0.16,0,0.24,0c3.178-0.342,5.431-1.609,6.48-4.08
			C297.838,314.577,297.42,314.518,297.36,314.819z M276,311.699c-0.021-0.078-0.06-0.149-0.108-0.217
			C275.941,311.549,275.98,311.621,276,311.699z M275.813,311.396c-0.029-0.03-0.059-0.06-0.092-0.088
			C275.756,311.336,275.784,311.366,275.813,311.396z M275.573,311.209c-0.019-0.011-0.039-0.022-0.059-0.032
			C275.535,311.187,275.554,311.198,275.573,311.209z M274.27,311.025c-0.025,0.006-0.051,0.012-0.075,0.02
			C274.22,311.037,274.244,311.031,274.27,311.025z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M234.24,312.179c-4.413,2.566-5.943,11.238-11.52,12.48c-0.08,0-0.16,0-0.24,0
			c-1.7-0.567-2.61-3.112-4.8-2.4c0.248,2.851,4.839,5.722,8.4,3.84c0.08,0,0.16,0,0.24,0c4.198-2.762,7.066-6.854,9.6-11.28
			C235.406,313.894,235.213,312.646,234.24,312.179z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M209.76,324.419c0.941-1.071,1.678-3.314,1.44-4.8
			C209.181,318.58,208.211,323.413,209.76,324.419z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M364.32,591.06c1.703,0.188,2.89-1.448,3.84-2.399
			c3.223-3.223,6.337-6.154,8.881-9.84c0-0.08,0-0.16,0-0.24c1.264-2.942-2.984,0.211-3.601,0.72
			c-1.613,1.332-2.987,2.456-3.84,3.36c-2.059,2.183-4.092,3.96-4.918,5.991l0.001-0.001c-0.001,0.001-0.001,0.001-0.001,0.002
			v-0.001c-0.001,0.001-0.001,0.003-0.002,0.004c-0.137,0.195-0.241,0.427-0.305,0.68c0,0.001,0,0.002,0,0.003
			c-0.031,0.126-0.053,0.254-0.063,0.384c0,0.004,0,0.007,0,0.011c-0.009,0.129-0.008,0.257,0.007,0.384
			C364.293,590.423,364.286,590.735,364.32,591.06z M364.399,590.483c0.041,0.118,0.093,0.233,0.161,0.337
			C364.492,590.716,364.439,590.603,364.399,590.483z M364.959,588.352c-0.007,0.006-0.013,0.014-0.02,0.02
			C364.946,588.365,364.952,588.357,364.959,588.352z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M220.54,285.661c-0.019,0.015-0.035,0.029-0.054,0.044
			c0.031-0.023,0.061-0.049,0.093-0.072C220.566,285.642,220.553,285.651,220.54,285.661z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M243.12,391.379c0.55,0.331,0.564,1.196,1.2,1.44
			c1.258-0.556,2.401-2.137,4.08-4.08c1.292-1.495,4.918-4.667,3.039-5.2c0,0,0,0,0,0c0,0,0,0-0.001,0
			c-0.091-0.058-0.204-0.097-0.343-0.113c-0.013-0.002-0.028-0.001-0.041-0.002c-0.141-0.011-0.3-0.005-0.494,0.035
			c-0.08,0-0.16,0-0.24,0c-1.6,0.431-2.915,2.219-4.8,0.96c-0.447,1.074-1.949,1.091-1.68,2.88c1.515,0.678,2.787-0.432,4.08-0.72
			C246.248,388.107,244.284,389.343,243.12,391.379z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M256.32,717.78c2.564-0.611,5.866-1.475,5.76-3.84
			c-0.084-1.869-3.957-4.292-5.52-3.84c-1.174,0.339-1.581,1.754-1.68,3.6C253.869,715.463,254.655,717.396,256.32,717.78z
			 M257.28,712.26c1.016,0.265,1.387,1.174,2.16,1.681c-0.857,0.663-1.72,1.32-2.88,1.68
			C256.851,714.551,256.99,713.329,257.28,712.26z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M303.601,293.459c0.44,0.519,1.415,0.505,2.399,0.48
			c2.178,0.017,4.004-0.317,5.761-0.72c0.319,0,0.64,0,0.96,0c0.176-0.224,0.437-0.363,0.72-0.48c1.354-0.405,1.537-1.17,0.964-1.65
			c-0.003-0.011-0.001-0.018-0.004-0.029c-0.012-0.001-0.021-0.001-0.033-0.003c0,0,0,0,0,0c-0.013-0.002-0.023-0.001-0.036-0.003
			c0.013,0.001,0.023,0.001,0.036,0.002c-0.475-0.366-1.407-0.549-2.606-0.237c-0.193,0.127-0.39,0.25-0.721,0.24
			c-0.079,0-0.159,0-0.239,0s-0.16,0-0.24,0c-0.869,0.17-1.989,0.09-2.64,0.48c-0.39,0.36-1.771,0.36-2.16,0
			c-0.751-0.529-2.395-0.166-2.88-0.96c0.743,0.024,1.331-0.108,1.68-0.48c0.771-0.268,1.423-0.657,2.399-0.72
			c0.883-0.078,1.328-0.592,2.16-0.72c0.046-0.194,0.241-0.239,0.24-0.48c1.072-0.288,1.926-0.794,2.64-1.44
			c0.742-0.298,1.288-0.792,1.681-1.44c0.205-0.765-0.209-0.911-0.48-1.2c-0.194,0.046-0.239,0.241-0.479,0.24
			c-0.703-0.063-0.737,0.542-1.44,0.48c-0.492,0.787-1.644,0.917-2.399,1.44c-0.08,0-0.16,0-0.24,0
			c-0.932,0.349-1.918,0.643-2.641,1.2c-0.519,0.201-0.818,0.621-1.68,0.48c-1.038,0.001-1.522,0.557-2.64,0.48
			c-1.692-1.027-2.274-3.166-4.8-3.36c-0.691-0.029-1.571,0.131-1.921-0.24c-0.788-0.412-1.765-0.635-2.88-0.72
			c-0.08,0-0.16,0-0.239,0c-0.657-0.383-1.586-0.494-2.641-0.48c-1.408-0.032-2.718-0.162-3.84-0.48
			c-0.813-0.386-1.908-0.492-3.12-0.48c-0.72,0-1.44,0-2.16,0c-0.887,0.153-2.391-0.311-2.88,0.24
			c-1.373,0.307-2.713,0.646-4.08,0.96c-1.933,0.92-4.671,2.627-4.95,4.837c-0.071,0.273-0.076,0.556,0.005,0.828
			c0.03,0.189,0.076,0.38,0.146,0.575c2.78-0.58,3.546-3.174,6-4.08c0.214-0.187,0.542-0.258,0.96-0.24
			c0.869-0.171,1.989-0.091,2.64-0.48c0.08,0,0.16,0,0.24,0c1.385,0.085,6.205-0.28,3.36,0.48c-0.08,0-0.16,0-0.24,0
			c-1.186,0.014-2.163,0.237-2.88,0.72c-0.08,0-0.16,0-0.24,0c-1.444,0.236-2.663,0.697-3.6,1.44c-0.08,0-0.16,0-0.24,0
			c-3.782,1.658-7.283,3.597-9.12,7.2c0,0.08,0,0.16,0,0.24c-2.104,1.612,1.24,5.199,1.92,1.92c-0.039-0.279,0.053-0.427,0.24-0.48
			c0-0.08,0-0.16,0-0.24c0.905-1.094,1.955-2.045,2.88-3.12c0.346-0.134,0.586-0.375,0.72-0.72c0.413,0.013,0.615-0.185,0.72-0.48
			c1.582-0.418,2.877-1.123,4.08-1.92c2.22-0.9,4.385-1.855,7.44-1.92c0.88,0,1.76,0,2.64,0c0-0.08,0-0.16,0-0.24
			c0.32,0,0.64,0,0.96,0c0.08,0,0.16,0,0.24,0c1.2,0,2.4,0,3.6,0c0.808,0.152,2.229-0.309,2.64,0.24
			c1.795,0.044,3.195,0.484,4.561,0.96c-0.024,0.744,0.108,1.331,0.479,1.68c0.105,0.825-0.095,1.345-0.24,1.92
			c-0.351,0.208-0.761,0.359-0.72,0.96c-0.343,0.058-0.404,0.395-0.72,0.48c-0.729,1.112-1.887,1.793-2.88,2.64
			c-0.628,0.572-1.515,0.886-1.92,1.68c-0.78,0.101-0.691,1.069-1.681,0.96c-1.156,0.123-1.477,1.083-2.88,0.96
			c-0.24,0-0.48,0-0.72,0c-0.521,0.359-1.351,0.409-2.16,0.48c-0.454,0.426-1.585,0.175-2.4,0.24c0,0.08,0,0.16,0,0.24
			c-0.16,0-0.32,0-0.48,0c-0.8,0-1.6,0-2.4,0c-0.16,0-0.32,0-0.479,0c-0.751-0.049-1.762,0.162-2.16-0.24
			c-1.318-0.441-2.898-0.622-4.32-0.96c-0.08,0-0.16,0-0.24,0c-0.053-0.187-0.201-0.279-0.48-0.24
			c-2.736,0.575,0.291,2.784,1.92,2.64c0.08,0,0.16,0,0.24,0c2.27,0.692,5.928,1.27,8.64,0.72c0.08,0,0.16,0,0.24,0
			c1.128,0.192,2.156-0.136,3.099-0.339c0.073-0.006,0.146-0.014,0.219-0.02c0.461-0.037,0.923-0.072,1.383-0.11
			c-0.005,0-0.011,0-0.016,0c0.063-0.005,0.126-0.01,0.189-0.015c-0.058,0.005-0.115,0.011-0.173,0.016
			c1.15,0.07,2.164,0.39,3.218,0.228c3.966-0.609,6.414-3.269,8.525-6.29c-0.029,0.029-0.068,0.049-0.125,0.05
			c0.063-0.002,0.104-0.027,0.134-0.063v0c0.864-1.238,1.674-2.535,2.506-3.777c-0.133-0.748-0.196-1.564-0.48-2.16
			c0.565,0.005,0.707-0.414,1.44-0.24c-0.134,0.586-0.929,0.511-0.72,1.44c0.362,0.597,0.822,1.097,1.68,1.2
			C303.44,293.459,303.521,293.459,303.601,293.459z M268.964,287.452c-0.029,0.025-0.057,0.055-0.085,0.082
			C268.908,287.506,268.935,287.477,268.964,287.452z M268.364,288.218c-0.01,0.019-0.022,0.036-0.031,0.055
			C268.342,288.254,268.354,288.237,268.364,288.218z M268.653,287.779c-0.025,0.032-0.051,0.064-0.075,0.097
			C268.603,287.843,268.627,287.812,268.653,287.779z M284.105,303.333c0.286-0.029,0.577-0.053,0.867-0.079
			C284.683,303.279,284.392,303.304,284.105,303.333z M294.568,301.274c-0.044,0.029-0.092,0.053-0.137,0.081
			C294.477,301.327,294.524,301.303,294.568,301.274z M293.676,301.765c-0.093,0.043-0.186,0.088-0.281,0.129
			C293.49,301.853,293.583,301.808,293.676,301.765z M292.486,302.229c-0.113,0.036-0.233,0.065-0.35,0.098
			C292.254,302.295,292.372,302.265,292.486,302.229z M291.436,302.504c-0.142,0.032-0.282,0.065-0.428,0.093
			C291.153,302.569,291.294,302.536,291.436,302.504z M289.022,302.893c-0.38,0.042-0.763,0.083-1.15,0.117
			C288.26,302.975,288.643,302.936,289.022,302.893z M295.332,300.663c0.061-0.056,0.117-0.114,0.175-0.172
			C295.449,300.549,295.392,300.607,295.332,300.663z M299,296.809c0.012-0.078,0.02-0.16,0.04-0.229
			C299.02,296.649,299.013,296.731,299,296.809z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M329.28,310.5c1.516,0.651,2.925-0.708,4.32-0.96
			c2.142-0.738,3.967-1.793,5.76-2.88c0.07-0.489,0.649-0.47,0.72-0.96c0-0.08,0-0.16,0-0.24c0.636-0.405,0.609-1.471,0.96-2.16
			c0.156-1.125-0.313-2.874,0.24-3.6c-0.261-1.099-0.37-2.35-0.479-3.6c-0.466-0.334-0.358-1.242-0.721-1.68
			c-0.229-0.49-0.49-0.95-0.72-1.44c0.001-0.241-0.194-0.286-0.24-0.48c-1.127-0.793-2.011-1.829-3.359-2.4
			c-0.525-0.035-0.516-0.604-0.48-1.2c0.218-0.662,0.075-1.686,0.48-2.16c-0.011-0.33,0.112-0.527,0.239-0.72
			c0.205-1.315,1.174-1.867,1.44-3.12c1.218-1.024,1.9-3.856,3.12-4.08c0,0.08,0,0.16,0,0.24c-0.188,0.989,0.368,1.232,0.24,2.16
			c0,0.16,0,0.32,0,0.48c0.08,0,0.16,0,0.239,0c0,1.04,0,2.08,0,3.12c0,0.08,0,0.16,0,0.24c-0.079,0-0.159,0-0.239,0
			c0.153,0.887-0.312,2.391,0.239,2.88c-0.013,0.413,0.186,0.615,0.48,0.72c0.04,0.28,0.38,0.261,0.72,0.24
			c0.124-0.139,0.233-0.298,0.341-0.461l-0.001,0c0,0,0.001,0,0.001-0.001v0c0,0,0.001,0,0.001-0.001
			c0.335-0.246,0.494-0.666,0.565-1.175c0,0,0,0,0-0.001c0,0,0,0,0,0c0-0.001,0-0.001,0-0.001c0,0,0,0,0,0.001
			c1.208-3.379,0.431-9.14-0.907-11.562c1.215-1.185,1.886-2.915,3.12-4.08c1.567,4.701,1.333,10.092-0.256,14.169
			c0,0,0,0,0.001-0.001c-0.001,0-0.001,0.001-0.001,0.001v0c0,0.001-0.001,0.001-0.001,0.002c-0.303,0.625-0.591,1.264-0.907,1.875
			c-0.026,0.049-0.053,0.097-0.079,0.146c-0.205,0.384-0.423,0.754-0.677,1.088c0,0.08,0,0.16,0,0.24
			c-0.155,1.125-0.843,1.717-0.72,3.12c0,0.08,0,0.16,0,0.24c0.146,0.013,0.284,0.036,0.24,0.24c2.654-0.625,2.659-3.9,4.079-5.76
			c0.288-1.072,0.795-1.926,1.44-2.64c0.18-0.454,0.717-0.167,0.24-0.72c1.118,0.211-0.162-0.791,0.479-0.72
			c-0.07-1.85,0.096-3.401,0-5.04c-0.252-4.346-2.585-9.117-0.96-11.76c0-0.24,0-0.48,0-0.72c0-0.08,0-0.16,0-0.24
			c0.001-0.241-0.194-0.286-0.239-0.48c-0.308-0.328-1.134-0.328-1.44,0c-0.162,0.558-0.798,0.643-0.96,1.2
			c-0.058,0.342-0.396,0.405-0.48,0.72c0.001,0.241-0.194,0.286-0.239,0.48c-0.626,0.734-0.863,1.858-1.681,2.4
			c0,0.08,0,0.16,0,0.24c-0.637,0.963-1.282,1.917-1.92,2.88c-0.08,0-0.16,0-0.24,0c0,0.08,0,0.16,0,0.24
			c-0.637,0.963-1.282,1.917-1.92,2.88c-0.08,0-0.159,0-0.239,0c-1.282,2.559-2.977,4.704-4.32,7.2
			c-0.617,0.982-0.974,2.227-1.44,3.36c0,0.24,0,0.48,0,0.72c-0.201,0.598-0.313,1.286-0.239,2.16
			c-0.28,0.041-0.261,0.379-0.24,0.72c0,0.32,0,0.64,0,0.96c-0.155,1.125,0.313,2.874-0.24,3.6c0,0.72,0,1.44,0,2.16
			c0.549,0.411,0.088,1.832,0.24,2.64c0,0.16,0,0.32,0,0.48c0,0.24,0,0.48,0,0.72c0,0.24,0,0.48,0,0.72
			c-0.07,0.49-0.649,0.471-0.72,0.96c-0.827,0.293-0.614,1.626-1.44,1.92c-0.368,1.472-1.312,2.368-1.68,3.84
			C328.14,308.655,328.326,310.116,329.28,310.5z M342.118,288.635c0.008-0.002,0.018-0.002,0.026-0.004
			C342.136,288.633,342.126,288.633,342.118,288.635z M343.191,285.206c-0.002-0.214-0.001-0.424,0.009-0.627
			C343.19,284.782,343.189,284.993,343.191,285.206z M346.08,284.099c-0.231,0.305-0.428,0.645-0.617,0.992
			C345.651,284.742,345.849,284.404,346.08,284.099z M332.4,306.899c0.768-2.032,2.141-3.459,2.64-5.76c0-0.08,0-0.16,0-0.24
			c0.554-0.647,0.086-2.315,0.24-3.36c0.875,0.781,2.027,0.719,3.12,0.24c0.42,4.18-0.201,7.318-3.12,8.16c-0.08,0-0.16,0-0.24,0
			C334.041,305.988,333.002,307.25,332.4,306.899z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M280.561,211.139c1.853,0.013,3.092-1.74,5.04-2.4
			c8.387-2.841,20.686-0.635,25.92,2.88c0.976,0.304,1.785,0.775,2.4,1.44c0.827,0.852,1.893,1.467,3.119,1.92
			c0.806,0.542,1.728,2.021,2.881,1.44c-2.414-2.706-5.255-4.985-8.4-6.96c-5.926-4.753-20.762-5.93-28.56-1.92
			C281.751,208.208,279.706,209.193,280.561,211.139z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M427.201,597.78c-0.036-0.026-0.078-0.048-0.115-0.074
			c-4.511-4.066-11.992-5.161-19.085-6.646c-0.787,0.733-1.772,1.269-2.16,2.4c8.858-0.136,14.602,4.115,21.84,4.8
			c-0.163-0.166-0.339-0.319-0.511-0.477C427.181,597.781,427.19,597.782,427.201,597.78z"/>
				<polygon fillRule="evenodd" clipRule="evenodd" points="390.684,511.555 390.687,511.554 390.682,511.555 		" />
				<polygon fillRule="evenodd" clipRule="evenodd" points="190.84,499.515 190.837,499.507 190.836,499.503 		" />
				<polygon fillRule="evenodd" clipRule="evenodd" points="390.968,601.494 390.967,601.493 390.965,601.492 		" />
				<polygon fillRule="evenodd" clipRule="evenodd" points="319.818,567.048 319.817,567.047 319.819,567.05 		" />
				<polygon fillRule="evenodd" clipRule="evenodd" points="296.82,397.308 296.818,397.31 296.818,397.31 		" />
				<polygon fillRule="evenodd" clipRule="evenodd" points="248.678,487.407 248.678,487.407 248.677,487.408 		" />
				<path fillRule="evenodd" clipRule="evenodd" d="M235.2,232.499c-0.399,2.002-0.399,5.678,0,7.68c-0.333,0.761-0.333,2.12,0,2.88
			c0.357,1.243-0.318,3.518,0.96,3.84c0.549-0.411,0.088-1.832,0.24-2.64c0-0.08,0-0.16,0-0.24c0.553-0.647,0.085-2.314,0.24-3.36
			c0.167-7.193,0.201-14.52,3.12-18.96c0.724-0.716,1.355-1.525,1.68-2.64c-0.013-0.413,0.185-0.615,0.48-0.72
			c0.581-0.459,1.017-1.063,1.2-1.92c0.081-0.56,0.246-1.034,0.72-1.2c2.327-3.353,4.201-7.159,6.24-10.8
			c0.466-0.734,0.985-1.416,1.2-2.4c1.059-1.791,3.233-3.578,2.64-6.24c-2.295-0.055-2.238,2.242-3.12,3.6
			c-0.08,0.56-0.246,1.034-0.72,1.2c-0.923,1.077-1.636,2.364-2.16,3.84c-0.925,0.915-1.394,2.286-1.92,3.6
			c-2.488,4.152-4.967,8.313-7.44,12.48C236.435,223.494,234.969,227.148,235.2,232.499z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M249.36,232.499c0.75,0.11,0.767-0.513,0.72-1.2
			c0.313-3.523,0.899-6.773,2.481-9.027c0.13-0.182,0.265-0.358,0.399-0.536c0.225-0.267,0.458-0.527,0.72-0.757
			c0-0.08,0-0.16,0-0.24c-0.041-0.601,0.369-0.751,0.72-0.96c1.131-1.108,2.094-2.386,2.88-3.84c-0.041-0.601,0.369-0.751,0.72-0.96
			c0.605-0.354,0.992-0.928,1.2-1.68c-0.01-0.411,0.179-0.622,0.48-0.72c0.724-0.716,1.355-1.525,1.68-2.64
			c-0.011-0.41,0.178-0.622,0.48-0.72c0.846-0.674,1.414-1.626,1.92-2.64c0.016-0.704,0.549-0.891,0.96-1.2
			c3.07-2.851,5.268-6.573,9.12-8.64c-0.06,0.7,0.452,0.828,0.72,1.2c4.184-1.145,6.415,1.528,10.08,1.68
			c-0.088-0.162-0.185-0.32-0.288-0.475c0.016-0.002,0.032-0.002,0.048-0.005c-0.04-0.034-0.082-0.067-0.122-0.101c0,0,0,0,0,0
			c-0.007-0.006-0.015-0.012-0.022-0.018c0.007,0.006,0.015,0.012,0.021,0.018c-1.681-2.422-5.146-3.888-8.998-3.259
			c-0.831-0.209-0.478-1.603-1.44-1.68c-0.34-0.021-0.68-0.04-0.72,0.24c-0.56,0.08-1.034,0.246-1.2,0.72
			c-1.11,0.33-1.785,1.095-2.4,1.92c-0.315,0.085-0.377,0.423-0.72,0.48c-2.011,1.509-3.374,3.667-5.28,5.28c0,0.08,0,0.16,0,0.24
			c-1.176,1.305-2.075,2.886-3.36,4.08c-0.092,0.788-0.702,1.058-0.96,1.68c-0.506,0.854-0.931,1.789-1.68,2.4
			c0.175,0.655-0.477,0.483-0.48,0.96c-0.624,0.816-1.066,1.813-1.92,2.4c-1.742,4.338-5.869,6.291-6.48,11.76
			C248.526,228.693,248.888,230.652,249.36,232.499z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M310.98,306.611C310.98,306.611,310.98,306.611,310.98,306.611
			c-0.043-0.148-0.11-0.272-0.201-0.371c-0.001,0-0.001,0-0.001-0.001c0,0,0,0,0,0c-0.001-0.001-0.002-0.001-0.003-0.002
			c0.001,0.001,0.002,0.001,0.003,0.002c-0.598-1.174-2.078-1.945-3.374-2.69c0.001,0,0.002,0.002,0.003,0.002
			c-0.054-0.034-0.107-0.064-0.161-0.094c0.001,0.001,0.003,0.001,0.004,0.002c-0.363-0.211-0.709-0.422-1.01-0.641
			c-0.063,0.337-0.526,0.273-0.72,0.48c-0.011,0.731,0.507,0.933,0.72,1.44c-0.752-0.112-0.677,0.604-0.96,0.96
			c0.786,0.895,1.634,1.727,2.88,2.16c0.716-0.415-0.344-1.484,0.24-1.68c0.771,0.589,1.335,1.385,2.64,1.44
			c0.008-0.083,0-0.16-0.001-0.24c-0.775-0.064-1.367-0.311-1.791-0.728c0.424,0.417,1.016,0.663,1.791,0.728l0,0
			c0.001,0,0.001,0,0.001,0C311.073,307.07,311.042,306.825,310.98,306.611z M308.354,304.376c-0.008-0.008-0.016-0.016-0.022-0.024
			C308.34,304.36,308.347,304.368,308.354,304.376z M307.913,303.945c-0.012-0.01-0.023-0.021-0.035-0.032
			C307.89,303.924,307.901,303.935,307.913,303.945z M306.543,303.236c0.081,0.007,0.16,0.021,0.24,0.042
			C306.703,303.258,306.624,303.242,306.543,303.236z M306.4,303.231c-0.132,0.004-0.266,0.02-0.4,0.068
			C306.135,303.251,306.269,303.234,306.4,303.231z M310.496,306.035c-0.121-0.049-0.252-0.089-0.416-0.096
			C310.244,305.946,310.38,305.981,310.496,306.035z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M216.96,314.339c-0.893-0.467-1.884-0.836-2.88-1.2
			c-0.608-0.671-1.184-1.376-1.92-1.92c-0.118-0.024-0.213-0.024-0.297-0.015c0.083-0.01,0.179-0.009,0.297,0.015
			c-0.014-0.146-0.036-0.284-0.24-0.24c-0.106,0.093-0.183,0.188-0.267,0.281c0.001,0,0.002,0,0.003-0.001
			c-0.001,0-0.002,0.001-0.003,0.001l0,0h0c-0.153,0.064-0.277,0.158-0.453,0.199c-0.004,0.182,0.005,0.351,0.02,0.515
			c-0.672,2.01,2.898,4.022,5.021,4.285c0.08,0,0.16,0,0.24,0c0.556-0.077,0.921,0.039,1.2,0.24c8.903,1.382,14.08-3.04,18.24-7.2
			c0-0.08,0-0.16,0-0.24c0.833-0.727,0.431-2.129-1.2-1.68c-1.708,0.292-1.906,2.094-3.6,2.4c-1.521,1.439-3.442,2.478-5.28,3.6
			c-0.08,0-0.16,0-0.24,0c0,0.08,0,0.16,0,0.24c-1.666,0.014-2.509,0.852-4.32,0.72c-0.96,0-1.92,0-2.88,0
			C217.92,314.339,217.44,314.339,216.96,314.339z M213.479,314.989c-0.013-0.007-0.026-0.013-0.039-0.02
			C213.453,314.976,213.466,314.982,213.479,314.989z M213.113,314.791c-0.02-0.011-0.039-0.023-0.059-0.034
			C213.074,314.768,213.094,314.779,213.113,314.791z M212.757,314.567c-0.021-0.015-0.043-0.03-0.064-0.044
			C212.714,314.538,212.736,314.553,212.757,314.567z M212.423,314.317c-0.021-0.018-0.043-0.036-0.064-0.055
			C212.381,314.281,212.402,314.299,212.423,314.317z M211.288,312.44c0.003,0.016,0.004,0.033,0.008,0.048
			C211.292,312.474,211.291,312.456,211.288,312.44z M211.408,312.881c0.007,0.022,0.013,0.045,0.021,0.067
			C211.421,312.927,211.416,312.903,211.408,312.881z M211.58,313.285c0.012,0.023,0.022,0.047,0.035,0.07
			C211.602,313.333,211.591,313.308,211.58,313.285z M211.798,313.648c0.016,0.023,0.031,0.046,0.048,0.068
			C211.829,313.694,211.814,313.671,211.798,313.648z M212.06,313.973c0.019,0.021,0.038,0.042,0.058,0.062
			C212.098,314.015,212.079,313.994,212.06,313.973z M215.746,316.004c0.032,0.016,0.066,0.031,0.099,0.046
			C215.812,316.035,215.778,316.02,215.746,316.004z M215.368,315.828c0.019,0.009,0.039,0.017,0.058,0.026
			C215.406,315.845,215.387,315.837,215.368,315.828z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M246.604,489.71c-0.124,0.102-0.232,0.212-0.364,0.31
			c0.096,0.161,0.197,0.322,0.301,0.483c-0.048-0.157-0.076-0.317-0.061-0.483C246.488,489.937,246.533,489.831,246.604,489.71z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M440.651,620.938c-0.004-0.039-0.007-0.078-0.011-0.117
			c-0.016,0.014-0.024,0.031-0.039,0.045c-0.065-0.096-0.123-0.197-0.201-0.285c-1.168,0.81-0.822,2.815-0.96,4.32
			c-0.271,2.965-0.437,6.295-0.72,9.119c-0.143-0.497,0.297-1.576-0.24-1.68c0,0.641,0,1.28,0,1.92c0,1.04,0,2.08,0,3.12
			c-0.401,4.318-0.979,8.461-1.68,12.48c-0.128,0.672-0.353,1.247-0.48,1.92c-0.315,1.284-0.644,2.556-0.96,3.84
			c-0.349,0.596-0.989,2.69,0,3.12c2.457,0.057,2.247-2.554,3.36-3.84c-0.076-0.557,0.038-0.922,0.24-1.2
			c0.8-2.721,1.078-5.962,1.92-8.64c0-0.08,0-0.16,0-0.24c0.1-5.211,0.765-12.693,0.479-18.96
			C441.283,624.168,441.47,622.218,440.651,620.938z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M416.881,585.3c2.821,2.252,5.861,5.325,9.84,6.24
			c-2.168-6.635-11.655-8.022-14.16-15.12c-0.877-0.675-2.112-0.953-2.88,0C410.636,580.452,414.033,583.027,416.881,585.3z"/>
				<polygon fillRule="evenodd" clipRule="evenodd" points="411.853,707.444 411.854,707.444 411.855,707.443 		" />
				<path fillRule="evenodd" clipRule="evenodd" d="M415.681,708.42c1.279-0.492,1.647-3.171,0.479-4.08c-0.159,0-0.319,0-0.479,0
			c-0.003,0.317-0.421,0.22-0.48,0.48c-0.632,1.283-1.833,2.085-3.345,2.623c0.893-0.219,1.774-0.448,2.625-0.703
			c-0.852,0.255-1.732,0.484-2.627,0.704c-0.687,0.169-1.384,0.327-2.09,0.479c-0.016,0.003-0.029,0.006-0.044,0.009
			c-0.702,0.149-1.414,0.291-2.134,0.425c-0.006,0.001-0.012,0.002-0.017,0.003c-1.519,0.199-3.103,0.341-4.608,0.541
			c-7.521,0.999-14.575,1.778-21.36,3.12c-3.311,0.654-7.53,0.065-9.36,2.88c2.97,1.359,6.604,0.146,9.84-0.48
			c9.714-1.879,22.602-3.018,31.2-4.56C413.99,709.289,414.972,708.991,415.681,708.42z M391.002,710.674
			c5.518-0.72,11.251-1.328,16.562-2.313C402.253,709.346,396.521,709.956,391.002,710.674z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M412.801,724.021c-1.129-1.339-5.299,0.365-6.46-0.941
			c0.048-0.045,0.095-0.089,0.144-0.132c-0.001,0.001-0.002,0.001-0.003,0.002c0.698-0.636,1.475-1.202,2.299-1.732
			c0.823-0.53,1.694-1.025,2.58-1.517c-0.886,0.491-1.756,0.986-2.58,1.517c-0.823,0.53-1.599,1.096-2.296,1.73
			c1.222-0.865,3.055-1.729,5.356-3.007c2.081-1.155,6.786-2.509,5.04-4.561c-0.31,0.331-1.043,0.238-1.681,0.24
			c-0.701,0.498-1.859,0.54-2.399,1.2c0,0.08,0,0.16,0,0.24c-0.41,0.389-0.874,0.727-1.68,0.72c-1.4,1.229-8.828,3.418-7.921,6.479
			C403.808,726.308,411.689,726.663,412.801,724.021z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M382.174,690.218c-0.006,0.002-0.013,0.004-0.019,0.005
			c0.013-0.004,0.026-0.008,0.039-0.012C382.188,690.213,382.181,690.216,382.174,690.218c0.672-0.2,1.336-0.408,2.002-0.614
			c0.375-0.114,0.75-0.229,1.123-0.344c0.048-0.013,0.095-0.028,0.142-0.04c1.082-0.282,3.36,0.156,3.601-1.68
			c-2.319-1.77-5.32-0.039-7.681,0.72c-7.936,2.552-16.309,4.648-25.2,6.48c-8.574,1.767-17.732,3.353-26.88,4.56
			c-2.637,0.349-6.316,0.249-7.439,3.12c18.276-2.273,36.021-4.583,52.08-9.359C376.727,692.226,379.461,691.136,382.174,690.218z"
				/>
				<path fillRule="evenodd" clipRule="evenodd" d="M262.561,723.3c5.009-2.421,7.457-11.322,3.36-16.319
			c-3.571-4.355-11.252-4.007-14.64,0c-2.851,3.371-2.42,10.124,0.48,13.439C253.91,722.878,258.098,723.79,262.561,723.3z
			 M252.96,710.58c0.514-0.927,1.295-1.585,1.92-2.4c1.85-1.274,5.563-1.21,7.44,0c0.66,0.86,1.527,1.513,1.92,2.641
			c0.267,1.648,0.749,3.268,0.24,5.04c-0.559,0.642-0.825,1.575-1.44,2.16c-0.66,1.1-1.729,1.791-3.12,2.159
			c-0.85,0.465-1.386-0.006-2.4,0c-2.758-0.762-4.338-2.701-4.8-5.76c0-0.96,0-1.92,0-2.88
			C252.796,711.216,253.062,711.082,252.96,710.58z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M213.6,764.58c3.164,2.031,6.567,5.081,10.8,4.8
			c-0.219-2.846-3.403-3.484-5.28-4.56c-0.538-0.31-1.066-0.633-1.598-0.952c-1.509-0.93-3.016-1.936-4.402-2.888
			c-9.674-6.643-18.957-13.964-30.72-18.48c-1.79-0.687-4.167-2.016-5.902-1.822c-0.114-0.03-0.223-0.068-0.338-0.098
			c-0.241-0.001-0.286,0.194-0.48,0.24c0,0.034,0,0.067,0,0.102c-0.082,0.043-0.164,0.084-0.24,0.139
			c0.072,0.115,0.156,0.224,0.24,0.333c0,0.049,0,0.098,0,0.146c0.072,0.036,0.142,0.07,0.213,0.106c0,0,0,0,0-0.001
			c0.147,0.074,0.292,0.146,0.439,0.219c-0.147-0.073-0.292-0.144-0.439-0.218c1.78,1.977,5.644,3.002,8.187,4.214
			c7.054,3.363,13.896,7.387,20.16,12C207.277,760.097,210.322,762.476,213.6,764.58z M217.516,763.864
			c-5.482-3.296-10.646-7.094-15.985-10.732C206.872,756.767,212.034,760.568,217.516,763.864z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M274.8,752.34c8.191,0.001,16.423-0.847,23.76-1.68
			c2.061-0.233,4.361-0.276,6.151-1.185c0.028-0.006,0.06-0.01,0.089-0.016c0.003-0.012,0-0.018,0.003-0.028
			c0.25-0.133,0.49-0.28,0.717-0.451c-0.271-0.002-0.552-0.003-0.824-0.005c-0.064-0.045-0.146-0.071-0.238-0.082
			c-0.003-0.001-0.005-0.001-0.007-0.001c-0.185-0.021-0.396,0.004-0.567,0.036c-0.084,0.016-0.155,0.03-0.207,0.04
			c-0.003,0-0.005,0-0.007,0.001c-4.78-0.031-9.732-0.026-14.95,0.251c-15.164,0.806-32.948,0.034-48.96,0.479
			c-1.845,0.052-4.594-0.483-5.76,1.44c1.526,1.775,4.11,0.795,6,0.72C250.925,751.423,263.332,752.34,274.8,752.34z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M387.601,752.34c-19.171,1.104-34.476,6.48-55.68,6.48
			c-6.468,0-13.115-0.733-19.681-0.96c-17.064-0.59-33.614-1.693-50.64-0.48c-2.056,0.146-9.059-0.45-8.88,1.681
			c0.203,2.42,8.154,1.002,8.88,0.96c5.861-0.344,11.544-0.444,17.104-0.392c2.019,0.038,4.044,0.08,6.08,0.111
			c-0.019-0.001-0.039-0.001-0.058-0.001c2.473,0.07,4.922,0.166,7.354,0.281c6.149,0.291,13.562,0.42,20.16,0.72
			c6.965,0.316,13.935,0.985,20.16,0.96c19.156-0.077,35.128-4.628,53.04-6.24c2.195-0.197,5.369,0.159,6.48-2.159
			C391.599,751.656,388.645,752.28,387.601,752.34z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M246,767.7c-0.152,0.032-0.331-0.039-0.48,0
			c-5.316,1.399-9.793,12.507-4.8,17.76c1.063,1.119,3.836,2.31,5.52,2.641c2.957,0.58,7.417-1.019,9.36-3.84
			c2.4-3.486,1.822-9.66,0-12.96C253.922,768.26,249.699,766.93,246,767.7z M247.68,784.74c-1.04,0.046-3.87-0.919-4.56-1.68
			c-2.139-2.358-0.549-7.177,0.48-9.84c0.603-0.678,1.243-1.318,1.92-1.92c0.744,0.023,1.146-0.294,1.68-0.48c0-0.08,0-0.16,0-0.24
			c-0.039-0.394,1.479-0.394,1.44,0c0,0.08,0,0.16,0,0.24c0.284,0.276,0.849,0.271,1.44,0.24c0.927,0.833,1.875,1.646,2.64,2.64
			c-0.018,0.418,0.054,0.746,0.24,0.96c0.215,0.905,0.207,2.033,0.48,2.88C253.824,781.197,250.723,784.606,247.68,784.74z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M357.601,785.94c-11.184-2.037-22.537-3.595-33.84-6
			c-11.012-2.344-22.657-3.843-34.08-5.76c-11.505-1.932-22.803-5.745-33.601-6.48c-0.224,0.256-0.885,0.075-0.72,0.72
			c-0.373,1.694,1.96,1.529,2.64,1.681c4.3,0.953,7.856,1.671,11.76,2.64c2.392,0.594,4.821,1.124,7.266,1.631
			c-0.025-0.006-0.05-0.013-0.075-0.019c0.029,0.007,0.058,0.015,0.087,0.021c-0.004,0-0.009-0.001-0.013-0.002
			c0.016,0.004,0.031,0.007,0.046,0.011c1.381,0.321,2.77,0.633,4.166,0.932c0.175,0.038,0.35,0.078,0.525,0.115
			c1.511,0.319,3.027,0.628,4.557,0.911c1.579,0.293,3.166,0.563,4.756,0.824c0.595,0.099,1.191,0.192,1.787,0.287
			c0.992,0.158,1.986,0.314,2.982,0.467c3.77,0.575,7.559,1.124,11.353,1.729c-0.017-0.003-0.034-0.006-0.052-0.009
			c0.111,0.018,0.222,0.037,0.332,0.055c-0.094-0.015-0.187-0.031-0.28-0.046c3.844,0.674,7.69,1.391,11.522,2.214
			c10.545,2.265,21.534,4.331,32.399,6.24c11.131,1.955,22.268,5.016,32.881,5.76c1.87,0.131,4.863,0.325,6.239-0.96
			c-4.938-2.278-10.439-2.953-15.84-3.84C368.834,788.146,363.207,786.962,357.601,785.94z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M243.6,777.301c-0.4,1.989,2.55,5.137,4.56,4.56
			c2.029-0.583,1.72-5.142,2.64-7.44C249.137,772.518,243.92,775.711,243.6,777.301z M247.44,778.98
			c-1.053,0.252-0.789-0.812-1.68-0.72c0.439-0.762,1.323-1.078,2.16-1.44C247.9,777.68,247.547,778.207,247.44,778.98z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M311.04,227.939c-9.122-3.78-23.94-1.502-31.92,1.68
			c-0.956,0.004-1.69,0.229-2.16,0.72c-0.936-0.056-1.245,0.516-1.92,0.72c-1.214,0.107-4.543,2.435-2.4,2.16c0.08,0,0.16,0,0.24,0
			c1.166,0.045,1.951-0.289,2.64-0.72c6.2-1.8,12.599-3.401,19.68-4.32c5.589-0.148,10.875,0.005,14.88,1.44
			c0.204-0.044,0.227,0.094,0.24,0.24c0.194,0.045,0.239,0.241,0.48,0.24c0.399,0,0.8,0,1.199,0c0.207-0.193,0.143-0.657,0.48-0.72
			C312.401,228.498,311.809,228.131,311.04,227.939z"/>
			</g>
			<g>
				<path d="M230.533,405.293L230.533,405.293c-0.009-0.014-0.022-0.023-0.031-0.036C230.512,405.269,230.522,405.281,230.533,405.293
			z"/>
				<polygon points="199.108,308.031 199.107,308.031 199.108,308.032 		" />
				<path d="M480.48,473.22c-0.729-4.312-0.869-9.21-2.16-12.96c0.019-0.897-0.101-1.659-0.479-2.16
			c-0.424-3.097-1.063-5.978-1.92-8.64c0.181-1.701-1.078-1.962-0.96-3.601c-0.077-1.203-0.391-2.169-0.96-2.88
			c-0.68,0.04,0.199-1.479-0.48-1.439c-0.076-0.805-0.247-1.514-0.72-1.921c-0.279-0.04-0.261-0.379-0.24-0.72
			c-0.076-0.804-0.247-1.513-0.72-1.92c-0.803-1.598-1.454-3.346-1.92-5.28c-0.005-0.955-0.229-1.69-0.72-2.159
			c-0.53-0.83-1.001-1.721-1.2-2.881c-0.25-1.35-0.647-2.553-1.2-3.6c-0.668,0.108,0.188-1.309-0.48-1.2
			c-0.054-0.985-0.508-1.571-0.96-2.16c0.025-1.344-1.979-4.831-1.68-3.359c-0.474-0.166-0.64-0.641-0.72-1.2
			c-0.441-0.826,0.59-1.189,0.72-2.16c0.275-2.05-0.792-4.758-1.2-6.48c-0.295-1.705-1.823-2.177-3.12-2.88
			c-1.089-0.111-1.423-0.977-2.64-0.96c-1.591-0.569-3.257-1.063-4.56-1.92c-1.001-0.76-2.541-0.979-3.841-1.44
			c-0.692-1.067-2.467-1.053-3.359-1.92c-0.771-0.669-1.888-0.992-3.12-1.2c-0.282-0.038-0.682,0.041-0.72-0.24
			c-3.039-1.281-7.052-1.589-10.801-2.16c-0.862-0.417-1.858-0.701-3.119-0.72c-4.617-1.304-9.826-2.014-14.641-3.12
			c-1.716-1.004-3.928-1.512-6.479-1.68c-6.418-2.063-13.244-3.716-20.881-4.56c-0.038-0.281-0.438-0.203-0.72-0.24
			c-0.41-0.549-1.832-0.088-2.64-0.24c-1.28,0-2.561,0-3.84,0c-0.888,0.153-2.392-0.311-2.881,0.24
			c-0.34-0.021-0.679-0.04-0.72,0.24c-2.708,0.492-6.009,0.391-7.92,1.68c-0.986,0.054-1.571,0.508-2.16,0.96
			c-2.108,0.212-2.964,1.676-4.08,2.88c-0.399,0-0.8,0-1.199,0c-1.971,0.13-2.896-0.784-4.561-0.96
			c-0.053-0.188-0.201-0.279-0.479-0.24c-0.535-1.042-2.539-0.802-3.12,0c0,0.64,0,1.28,0,1.92c-0.716,0.484-1.185,1.216-1.92,1.68
			c-0.325-0.076-0.459-0.342-0.96-0.24c-0.08,0-0.16,0-0.24,0c0.044-0.204-0.094-0.226-0.24-0.24
			c-1.842-0.559-4.719-0.082-6.96-0.24c-0.16,0-0.32,0-0.48,0c-0.489-0.551-1.993-0.087-2.88-0.24
			c-0.131-0.349,0.28-1.241-0.239-1.2c-0.303-1.777-0.443-3.717-0.48-5.76c0-2.24,0-4.48,0-6.72c-0.201-1.862,1.438-3.403,0.24-4.8
			c0.013-0.413-0.185-0.615-0.48-0.72c-0.257-0.703-0.681-1.239-0.479-2.4c-0.391-1.93-0.198-4.442-0.24-6.72c0-0.48,0-0.96,0-1.44
			c0.557-1.124,0.083-3.277,0.24-4.8c0-0.32,0-0.64,0-0.96c0.552-0.568,0.086-2.154,0.239-3.12c0.32-0.721,0.244-1.836,0.24-2.88
			c0-0.16,0-0.32,0-0.48c0.551-0.489,0.087-1.993,0.24-2.88c0-0.32,0-0.64,0-0.96c0.549-0.411,0.088-1.832,0.24-2.64
			c0-0.88,0-1.76,0-2.64c0-0.64,0-1.28,0-1.92c0.399-4.001,0.399-9.679,0-13.68c0.039-0.279-0.053-0.427-0.24-0.48
			c-0.328-0.307-0.328-1.133,0-1.44c0.187-0.054,0.474-0.007,0.48-0.24c1.996-0.403,2.931-1.869,4.079-3.12c0-0.08,0-0.16,0-0.24
			s0-0.16,0-0.24c0.302-0.098,0.491-0.309,0.48-0.72c0.747-0.533,1.041-1.519,1.2-2.64c0.025-0.615,0.136-1.144,0.479-1.44
			c1.148-2.932,2.073-6.086,2.88-9.36c0.422-0.219,0.439-0.841,0.721-1.2c0.249-0.491,0.738-3.3,0-1.92
			c1.032-2.967,2.173-5.827,3.6-8.4c0.567-0.553,0.769-1.472,1.2-2.16c0.536-0.664,0.934-1.466,1.2-2.4
			c-0.04-0.759,0.638-0.802,0.479-1.68c0.188-0.053,0.279-0.201,0.24-0.48c0.54-0.58,0.86-1.379,0.96-2.4
			c0.973-1.268,0.666-3.813,1.2-5.52c0.552-0.568,0.086-2.154,0.24-3.12c-0.08,0-0.16,0-0.24,0c-0.132-0.349,0.28-1.24-0.24-1.2
			c0-0.64,0-1.28,0-1.92c-1.119-5.601-0.438-13.002-6.479-13.68c-0.734,0.174-0.706-0.414-1.44-0.24
			c0.299-0.968,0.952-1.211,0.48-2.4c-0.333-0.147-0.036-0.924-0.48-0.96c0.07-1.27-0.621-1.779-0.48-3.12c0.08,0,0.16,0,0.24,0
			c0.397-0.336,0.397-2.304,0-2.64c0.039-0.279-0.053-0.427-0.24-0.48c-1.388-9.012-2.733-18.065-5.52-25.68
			c0.31-0.95-0.79-0.49-0.48-1.44c-0.17-1.03-0.392-2.008-0.96-2.64c-0.706-1.214-1.283-2.557-1.68-4.08
			c0.158-0.878-0.52-0.921-0.479-1.68c-0.31-0.818-0.876-2.635-1.44-2.4c-1.536-2.704-3.271-5.209-5.28-7.44
			c1.864,1.535-0.22-0.611-0.72-1.44c-0.532-1.148-1.488-1.872-2.4-2.64c-0.859-1.061-1.819-2.021-2.88-2.88
			c-0.558-0.163-0.643-0.798-1.2-0.96c-0.159,0-0.319,0-0.479,0c-0.908-1.092-2.753-1.248-3.6-2.4c1.698,0.444-2.3-1.88-2.881-1.44
			c-1.404,0.205-1.202-1.197-2.399-1.2c-0.3-0.581-0.983-0.776-1.68-0.96c-2.264-1.097-4.021-2.7-6.24-3.84
			c-1.334-1.066-2.935-1.866-4.801-2.4c-0.053-0.187-0.2-0.279-0.479-0.24c-0.588-0.452-1.174-0.906-2.16-0.96
			c-0.158-0.322-0.513-0.447-0.96-0.48c-1.015-0.905-2.295-1.545-3.84-1.92c-1.742-0.337-2.677-1.483-4.561-1.68
			c-0.035-0.444-0.813-0.147-0.959-0.48c0-0.08,0-0.16,0-0.24c-1.52,0-3.04,0-4.56,0c0,0.08,0,0.16,0,0.24
			c-0.564,0.316-1.091,0.669-2.16,0.48c-1.522,0.157-3.676-0.316-4.8,0.24c-8.59-0.316-16.768,2.493-23.76,4.8
			c-0.039,0.281-0.438,0.203-0.72,0.24c-2.277,0.443-4.345,1.095-6.24,1.92c-0.039,0.281-0.438,0.203-0.72,0.24
			c-1.688,0.232-3.129,0.71-4.32,1.44c-2.603,0.677-4.97,1.59-7.2,2.64c-0.039,0.281-0.438,0.202-0.72,0.24
			c-2.434,0.606-4.732,1.348-6.72,2.4c-1.646-0.046-1.714,1.486-3.36,1.44c0-0.08,0-0.16,0-0.24c0.806-0.621-2.086,0.396-2.4,0.96
			c-0.027,0.453-0.793,0.167-0.72,0.72c-0.452,0.348-1.247,0.353-1.44,0.96c-0.279-0.039-0.427,0.053-0.48,0.24
			c-1.365,0.239-3.077,1.398-3.12,2.16c-0.286,0.593-0.821,0.938-1.44,1.2c-0.16,0-0.32,0-0.48,0
			c-1.103,1.057-2.468,1.852-3.36,3.12c-0.08,0-0.16,0-0.24,0c-2.5,1.82-4.93,3.709-6.72,6.24c-0.709,0.651-1.61,1.11-1.92,2.16
			c-1.345,1.456-2.77,2.831-3.6,4.8c-1.171,0.749-1.605,2.235-2.16,3.6c-0.288,1.312-1.077,2.123-1.2,3.6
			c-0.567,1.514-1.172,2.988-1.44,4.8c-0.758,1.241-0.524,3.476-0.96,5.04c-0.233,0.006-0.186,0.293-0.24,0.48
			c-0.399,1.763-0.399,5.197,0,6.96c-0.039,0.279,0.053,0.427,0.24,0.48c0.201,1.479,0.625,2.736,0.72,4.32
			c0.422,1.862-0.671,2.209-0.72,3.6c-1.008,5.312-1.369,11.271-2.16,16.8c-0.233,0.006-0.187,0.293-0.24,0.48
			c-0.553,0.647-0.085,2.315-0.24,3.36c0,0.72,0,1.44,0,2.16c-0.398,0.414-0.398,2.466,0,2.88c0,0.88,0,1.76,0,2.64
			c0,1.04,0,2.08,0,3.12c0.157,1.523-0.316,3.677,0.24,4.8c0,0.64,0,1.28,0,1.92c0,0.8,0,1.6,0,2.4c0.15,0.729-0.307,2.067,0.24,2.4
			c0.08,0,0.16,0,0.24,0c-0.098,0.578,0.438,0.521,0.24,1.2c-1.32,1.161-4.347,0.613-5.52,1.92c-0.105,0.295-0.307,0.493-0.72,0.48
			c-0.776,0.185-1.256,0.664-1.44,1.44c-0.667,0.292-0.446,1.475-0.48,2.4c0,0.16,0,0.32,0,0.48c1.162,1.478,1.72,3.56,2.88,5.04
			c0,0.08,0,0.16,0,0.24l1.2,1.92c0.965,2.075,2.098,3.982,3.36,5.76c0.266,0.934,0.664,1.736,1.2,2.4
			c0.054,0.986,0.508,1.572,0.96,2.16c-0.039,0.279,0.053,0.427,0.24,0.48c0.054,0.986,0.508,1.572,0.96,2.16
			c-0.039,0.279,0.053,0.427,0.24,0.479c0.054,0.986,0.508,1.572,0.96,2.16c0.143,0.978,0.462,1.778,0.96,2.4
			c-0.154,1.034,0.982,0.778,0.72,1.92c0.054,0.986,0.508,1.571,0.96,2.16c-0.002,1.442,1.4,1.479,1.68,2.64
			c0.658,0.862,0.642,2.398,1.2,3.36c1.196,4.564,0.28,11.24,3.6,13.68c0.454,0.746,1.471,0.929,2.4,1.2
			c0.333,0.627,2.037-0.117,2.4,0.48c0.505,0.855,0.5,2.22,1.2,2.88c0.356,0.204,0.516,0.604,0.48,1.2
			c0.183,1.657,1.017,2.664,1.2,4.32c0.082,0.735,0.704,3.162,0.96,2.16c1.226,3.094,2.504,6.135,3.84,9.12
			c0.82,3.101,1.923,5.917,1.92,9.84c0.175,1.425-0.429,3.629,0.48,4.32c0,0.16,0,0.32,0,0.48c0.15,0.729-0.307,2.067,0.24,2.4
			c0.127,1.073,0.396,2.004,1.2,2.4c0.148,0.652,0.476,1.124,0.96,1.44c0.77,1.791,1.471,3.649,2.64,5.04
			c0.423,1.097,0.92,2.12,1.68,2.88c0,0.16,0,0.32,0,0.48c1.805,2.294,3.729,4.47,5.622,6.677c-0.039-0.061-0.079-0.12-0.102-0.197
			c0.027,0.095,0.082,0.161,0.132,0.233c0.06,0.086,0.125,0.168,0.203,0.237c0.208,0.244,0.418,0.485,0.625,0.73
			c1.206,1.594,2.539,3.061,4.08,4.32c0.672,2.146,1.322,4.452-0.749,5.123c0.02-0.006,0.042-0.009,0.062-0.015
			c-0.037,0.01-0.071,0.022-0.108,0.032c0.017-0.005,0.03-0.013,0.046-0.018c-0.224,0.059-0.449,0.117-0.691,0.157
			c-0.411-0.011-0.622,0.179-0.72,0.48c-0.843-0.043-0.79,0.811-1.44,0.96c-0.825,0.104-1.345-0.095-1.92-0.24
			c-0.039-0.281-0.438-0.202-0.72-0.24c-0.966-0.398-3.594-0.398-4.56,0c-0.34-0.021-0.68-0.039-0.72,0.24
			c-2.144-0.063-3.917,0.243-5.28,0.96c-0.296,0.344-0.825,0.455-1.44,0.48c-2.164,0.396-3.536,1.584-4.8,2.88
			c-2.481,1.599-6.178,1.981-8.64,3.6c-2.554,0.726-4.996,1.564-7.2,2.64c-0.296,0.345-0.825,0.455-1.44,0.48
			c-1.03,0.17-2.008,0.392-2.64,0.96c-0.34-0.021-0.679-0.04-0.72,0.24c-0.804,0.076-1.513,0.247-1.92,0.72
			c-2.495,0.386-4.151,1.609-6.48,2.16c-1.263,0.257-2.496,0.544-3.36,1.2c-0.34-0.021-0.68-0.04-0.72,0.239
			c-0.804,0.076-1.513,0.248-1.92,0.721c-1.301,0.858-3.161,1.159-4.56,1.92c-0.986,0.054-1.572,0.508-2.16,0.96
			c-0.613,0.106-1.104,0.337-1.44,0.72c-0.986,0.055-1.572,0.509-2.16,0.96c-0.73,0.55-1.658,0.902-2.64,1.2
			c-1.384,0.296-2.505,0.854-3.36,1.68c-2.113,0.447-3.054,2.066-4.8,2.88c-2.265,0.615-3.705,2.056-4.32,4.32
			c-1.302,1.339-2.066,3.214-2.64,5.28c-0.808,0.793-0.19,3.01-1.44,3.36c-0.752,0.208-1.326,0.594-1.68,1.199
			c-1.504,1.216-3.379,2.062-4.56,3.601c-0.193,0.127-0.39,0.25-0.72,0.24c-1.2,0.88-2.558,1.602-3.36,2.88
			c-1.3,1.1-2.5,2.3-3.6,3.6c-1.14,0.94-2.245,1.915-2.64,3.6c-2.001,1.6-2.951,4.25-4.32,6.48c-0.332,0.868-1.935,0.465-1.92,1.68
			c-0.281,0.039-0.203,0.438-0.24,0.721c-3.699,16.86-4.133,36.987-7.44,54.239c-0.233,0.007-0.186,0.294-0.24,0.48
			c-0.555,0.885-0.083,2.796-0.24,4.08c0.048,1.729-0.528,2.832-0.48,4.56c-0.379,0.421-0.321,1.279-0.48,1.92
			c-0.664,0.536-1.062,1.339-1.2,2.4c-1.26,2.66-1.707,6.134-2.4,9.36c0.202,1.562-1.041,1.679-0.96,3.12
			c-1.066,1.124-1.051,2.467-1.2,4.079c0.164,1.364-1.266,1.135-0.96,2.641c-0.877,2.643-1.976,5.064-2.4,8.16
			c-0.281,0.038-0.202,0.438-0.24,0.72c-0.514,1.406-1.354,2.486-1.68,4.08c-0.356,0.204-0.516,0.604-0.48,1.2
			c-0.49,0.47-0.716,1.204-0.72,2.16c-2.459,9.786-4.096,20.527-5.25,31.47c-0.111,0.757-0.216,1.513-0.304,2.263
			c-0.09,0.765-0.163,1.522-0.207,2.268c-0.039,0.672-0.082,1.349-0.126,2.026c0-0.001,0-0.003,0-0.005
			c-0.085,1.311-0.178,2.632-0.273,3.96c0.095-1.327,0.188-2.646,0.272-3.955c-0.215,2.445-0.417,4.891-0.594,7.333
			c-0.547,0.333-0.09,1.671-0.24,2.4c0,0.24,0,0.479,0,0.72c-0.549,0.411-0.088,1.832-0.24,2.641c0,0.239,0,0.479,0,0.72
			c-0.521-0.041-0.109,0.852-0.24,1.2c-0.547,0.332-0.089,1.67-0.24,2.399c0,0.96,0,1.92,0,2.88c0,0.16,0,0.32,0,0.48
			c-0.08,0-0.16,0-0.24,0c0,3.04,0,6.08,0,9.12c0.08,0,0.16,0,0.24,0c0.402,1.758,0.465,3.854,1.2,5.28
			c0.355,0.044,0.22,0.579,0.24,0.96c0.437,2.603,1.067,5.013,1.92,7.199c0.306,1.774,1.107,3.053,1.44,4.801
			c0.312,1.928,0.871,3.609,1.68,5.04c0.279,0.04,0.26,0.379,0.24,0.72c0.375,2.265,1.145,4.135,1.92,6
			c0.038,0.282-0.041,0.681,0.24,0.72c0.072,1.288,0.489,2.231,0.96,3.12c-0.021,1.302,0.772,1.787,0.96,2.88
			c0.154,1.446,0.551,2.649,1.2,3.601c0.025,0.614,0.136,1.144,0.48,1.439c0.17,1.03,0.392,2.009,0.96,2.641
			c0.038,0.282-0.042,0.681,0.24,0.72c0.959,2.4,1.036,5.685,3.6,6.479c0.053,0.188,0.201,0.279,0.48,0.24
			c1.419,1.414,2.927,0.053,4.56-0.24c0.755,0.926,3.065,0.295,4.56,0.48c0.88,0,1.76,0,2.64,0c0.953-0.167,2.658,0.418,2.88-0.48
			c0.282-0.037,0.681,0.041,0.72-0.239c1.618,0.017,2.917-0.284,4.08-0.721c-0.041-0.521,0.852-0.108,1.2-0.239
			c0.897,0.018,1.659-0.102,2.16-0.48c1.464-0.456,3.023-0.816,4.56-1.2c1.031-0.009,1.767-0.313,2.4-0.72
			c1.247-0.913,3.45-0.869,4.8-1.68c0.899-0.062,1.543-0.377,1.92-0.96c3.307-1.894,6.099-4.301,9.6-6
			c0.804-0.076,1.513-0.247,1.92-0.721c0.559-0.401,1.293-0.627,2.16-0.72c1.78-0.22,3.179-0.82,4.56-1.44
			c0.296-0.344,0.825-0.454,1.44-0.479c1.499-0.341,2.98-0.7,4.08-1.44c0.282-0.037,0.681,0.042,0.72-0.239c0.292,0,0.583,0,0.875,0
			c0,0.001,0,0.002,0,0.002c0.043,0.15,0.087,0.299,0.115,0.465c0,0.001,0,0.002,0,0.003c-0.05,0.364-0.275,0.552-0.271,0.97
			c-0.281,0.039-0.203,0.438-0.24,0.72c-0.549,0.411-0.088,1.832-0.24,2.641c0,0.24,0,0.479,0,0.72
			c-0.549,0.411-0.088,1.832-0.24,2.64c0,0.4,0,0.801,0,1.2c-0.549,0.411-0.088,1.832-0.24,2.641c0,0.8,0,1.6,0,2.399
			c-0.556,1.044-0.083,3.117-0.24,4.561c0.089,1.609-0.515,2.525-0.48,4.08c-0.379,0.501-0.498,1.262-0.48,2.159
			c0.314,1.514-0.806,1.595-0.72,2.881c-1.143,2.937-1.61,6.55-2.64,9.6c-0.326,0.554-0.69,1.07-0.72,1.92
			c-0.615,0.825-1.094,1.786-1.44,2.88c-0.642,0.558-1.125,1.275-1.44,2.16c-0.966,0.794-1.263,2.257-1.68,3.6
			c-0.664,0.019-0.853,2.659,0,2.641c0.054,0.187,0.006,0.474,0.24,0.479c0.038,0.283-0.041,0.682,0.24,0.721
			c0.23,2.569,1.947,3.652,3.6,4.8c0.014,0.146,0.036,0.284,0.24,0.24c0.159,0.641,0.101,1.499,0.48,1.92
			c0.044,1.644,0.013,3.213-0.24,4.56c-0.097,1.663-1.09,2.43-1.68,3.6c-0.583,0.377-0.899,1.021-0.96,1.921
			c-0.348,2.052-1.38,3.419-1.92,5.279c-0.541,0.58-0.861,1.38-0.96,2.4c-0.258,1.102-1.085,1.635-0.96,3.12
			c-0.507,0.772-0.746,1.814-0.72,3.12c-0.326,0.074-0.199,0.601-0.24,0.96c-0.696,3.407-1.232,9.455-0.24,12.96
			c0.169,1.431,0.227,2.973,0.48,4.319c-0.385,0.401-0.504,2.573,0.24,2.641c0.2,1.319-0.601,1.639-0.48,2.88
			c-0.041,0.359,0.086,0.886-0.24,0.96c-0.555,1.285-0.804,2.876-0.96,4.561c-0.521-0.041-0.109,0.851-0.24,1.199
			c-1.01,2.75-0.659,6.861-2.64,8.641c-0.422,0.058-0.547,0.413-0.48,0.96c-0.567,0.313-0.462,1.297-0.72,1.92
			c-0.942,0.337-0.284,2.275-0.48,3.359c0.24,0,0.48,0,0.72,0c1.92,0,3.84,0,5.76,0c22.16,0,44.32,0,66.48,0
			c11.12,0,22.24,0,33.36,0c15.52,0,31.04,0,46.56,0c18.8,0,37.601,0,56.4,0c8.24,0,16.48,0,24.72,0c10.4,0,20.801,0,31.2,0
			c0.96,0,1.92,0,2.88,0c0-0.159,0-0.319,0-0.479c0-0.72,0-1.44,0-2.16c-0.155-1.204,0.314-3.034-0.239-3.84
			c0.11-1.17-0.252-2.812,0.239-3.6c0-0.32,0-0.641,0-0.961c0-1.039,0-2.08,0-3.119c0.399-0.729,0.399-3.112,0-3.841
			c-0.107-2.292-1.389-3.41-1.199-6c0.81-0.87,0.574-2.785,1.439-3.6c0.452-0.588,0.906-1.174,0.96-2.16
			c0.188-0.053,0.279-0.201,0.24-0.479c1.681-3.52,2.071-8.328,3.84-11.761c1.074,0.318,0.261-4.04,0.24-1.92
			c-0.528-0.271-0.017-1.583-0.72-1.68c-0.129-0.752-0.989-0.771-0.721-1.92c-0.176-0.448-0.351-0.928-0.528-1.394
			c0.014,0.04,0.021,0.083,0.035,0.123c-0.182-0.473-0.361-0.952-0.533-1.438c0.159,0.451,0.33,0.875,0.498,1.314
			c-1.828-5.382-1.34-12.26,0.049-18.047c0.93-0.135,0.437-7.461,0.24-5.52c-0.545-0.256-0.092-1.509-0.24-2.16
			c-0.191-2.499,0.023-6.396-0.24-8.4c-0.076-0.579-0.213-0.418-0.24,0c-0.668,0.108,0.188-1.308-0.479-1.199
			c0.018-0.898-0.102-1.659-0.48-2.16c0.057-1.177-0.423-1.817-0.24-3.12c-0.131-3.389-0.096-6.944-0.479-10.08
			c0-0.561,0-1.12,0-1.681c0.123-9.083-0.135-17.784-0.24-26.64c0-0.16,0-0.32,0-0.479c0.08,0,0.16,0,0.24,0
			c-0.809-1.491,1.864-1.647,0.72-1.92c0.337-0.384,0.827-0.613,1.44-0.721c1.551-0.208,2.265-1.255,2.88-2.399
			c2.672-3.009,4.231-7.129,6.479-10.561c0.581-0.3,0.776-0.983,0.96-1.68c0.188-0.053,0.279-0.201,0.24-0.48
			c0.581-0.3,0.776-0.983,0.96-1.68c0.841-1.639,1.67-3.29,2.641-4.8c0.58-0.3,0.776-0.984,0.96-1.681
			c0.383-0.336,0.613-0.826,0.72-1.439c0.759-0.602,1.08-1.641,1.44-2.64c0.321-0.158,0.446-0.514,0.479-0.961
			c1.707-3.082,4.639-6.72,3.84-10.8c0.401-0.559,0.627-1.293,0.72-2.16c0.498-0.622,0.818-1.422,0.961-2.399
			c0.527-0.272,0.017-1.584,0.72-1.68c0.99-1.73,2.049-3.391,2.64-5.521c0.188-0.053,0.279-0.201,0.24-0.48
			c0.54-0.579,0.86-1.379,0.96-2.399c0.939-1.461,1.522-3.278,2.16-5.04c0.568-0.632,0.79-1.61,0.96-2.64
			c0.037-0.283-0.041-0.682,0.24-0.721c0.406-0.633,0.71-1.369,0.72-2.399c-0.024-0.744,0.108-1.332,0.479-1.681
			c0.806-1.755,1.342-3.777,1.681-6c0.041-0.358-0.086-0.886,0.24-0.96c0.482-0.717,0.706-1.693,0.72-2.88
			c0.131-0.349-0.28-1.24,0.24-1.2c1.235-4.924,1.766-10.554,2.399-16.08c1.164-5.796,1.203-12.717,1.44-19.439
			c0.1-2.86,0.313-5.606,1.92-6.96c0.187-0.053,0.279-0.201,0.24-0.48c0.809-1.431,1.368-3.111,1.68-5.04
			c0.696-1.224,0.875-2.965,1.2-4.56c0.399-1.125,0.399-3.915,0-5.04c0-0.4,0-0.8,0-1.2c-0.157-1.443,0.315-3.516-0.24-4.56
			c-0.131-0.349,0.28-1.241-0.24-1.2c2.587-16.773,6.878-31.843,8.88-49.2c0.422,2.009-0.037-1.771,0.48-1.92c0-1.601,0-3.2,0-4.8
			c0-1.04,0-2.08,0-3.12c0-2.48,0-4.96,0-7.44C480.388,475.795,480.99,471.282,480.48,473.22z M97.206,631.227L97.206,631.227
			c0.003-0.052,0.007-0.103,0.011-0.154C97.213,631.124,97.21,631.175,97.206,631.227z M424.115,801.087c0,0.006,0,0.013,0,0.019
			c0,0.001-0.001,0.002-0.001,0.004C424.115,801.102,424.115,801.094,424.115,801.087z M452.4,404.1
			c0.878-0.158,0.921,0.519,1.681,0.48c0.539,0.42,1.35,0.57,1.92,0.96c1.511,0.489,2.785,1.215,3.6,2.4
			c0.589,0.852,0.64,2.24,0.96,3.36c-1.655-0.744-2.675-2.125-4.08-3.12c-0.342-0.058-0.404-0.396-0.72-0.48
			c-0.319-0.801-1.404-0.836-1.68-1.68c-0.871-0.249-1.444-0.796-2.16-1.2c-0.819-0.061-1.247-0.513-1.2-1.44
			C451.527,403.373,451.99,403.71,452.4,404.1z M412.801,393.06c9.279,1.243,18.148,3.946,26.4,6.48
			c2.461,0.756,5.713,0.985,7.304,3.196c-0.086,0.01-0.173,0.021-0.26,0.032c-0.053,0.004-0.105,0.017-0.157,0.02
			C434.093,400.444,422.951,397.247,412.801,393.06C411.804,393.289,412.752,392.341,412.801,393.06z M405.601,393.299
			c0.341-0.021,0.68-0.04,0.72,0.24c0,0.08,0,0.16,0,0.24c0.764,0.037,1.057,0.544,1.92,0.48c0.039,0.281,0.438,0.202,0.721,0.24
			c0,0.08,0,0.16,0,0.24c1.694,0.385,3.005,1.155,4.8,1.44c0.341-0.021,0.68-0.04,0.72,0.24c1.137,0.543,2.305,1.056,3.84,1.2
			c0.615,0.025,1.145,0.136,1.44,0.48c0,0.08,0,0.16,0,0.24c-0.773,0.347-2.014,0.227-3.12,0.24
			c-10.618-0.982-21.496-1.704-29.76-5.04c1.707-0.212,3.069-0.771,5.04-0.72c2.479,0,4.96,0,7.439,0
			C401.757,392.664,403.281,393.379,405.601,393.299z M398.4,388.979c-6.554,0.486-13.509,0.571-18,3.12
			c-4.604-1.476-9.924-2.236-15.84-2.4C372.17,383.963,389.229,386.569,398.4,388.979z M350.062,391.773
			c-0.405,0.382-0.842,0.662-1.175,0.703C349.219,392.436,349.657,392.153,350.062,391.773z M335.761,393.299
			c3.518,0.456,8.326,0.72,11.52,0.72c-13.876,16.683-23.682,40.543-30.24,65.28c-1.222,4.607-3.123,10.863-1.199,13.92
			c-0.259,1.229-0.602,2.413-1.006,3.561c-0.003,0.01-0.007,0.019-0.011,0.028c-0.316,0.817-0.65,1.621-1.001,2.413
			c-0.004,0.009-0.008,0.017-0.011,0.024c-0.351,0.791-0.718,1.569-1.101,2.336c-0.005,0.01-0.01,0.02-0.015,0.029
			c-1.758,3.318-3.918,6.369-5.977,9.368c-2.596,3.781-5.241,7.474-7.919,11.112c-1.142,1.492-2.271,2.984-3.361,4.488
			c-0.327,0.451-0.667,0.892-0.996,1.342c0.002-0.003,0.005-0.006,0.007-0.009c-7.222,9.871-14.81,19.366-22.531,28.907
			c7.719-9.538,15.304-19.031,22.524-28.898c-7.389,9.739-15.021,19.135-22.748,28.638c-0.058-0.067-0.117-0.135-0.175-0.204
			c-3.198-4.443-5.575-8.87-8.722-14.655c-2.576-4.736-6.464-9.667-6.475-14.98c0.038-0.111,0.083-0.221,0.125-0.331
			c0.241-0.633,0.533-1.259,0.792-1.938c-0.257,0.679-0.551,1.305-0.792,1.938c2.867-3.887,1.77-12.972-3.01-13.729
			c-1.503-0.238-2.597,0.268-3.776,0.837c-0.103,0.042-0.207,0.102-0.31,0.148c-0.521,0.249-1.063,0.499-1.674,0.694
			c1.096-1.097,0.197-1.942-0.525-2.826c-0.001-0.001-0.002-0.003-0.002-0.004c-0.186-0.346-0.399-0.675-0.606-0.998
			c-0.002-0.003-0.004-0.006-0.006-0.009l0,0.001c-0.104-0.162-0.206-0.323-0.301-0.484c0.132-0.099,0.241-0.208,0.365-0.311
			l0,0.001c0.003-0.003,0.006-0.006,0.009-0.008c0.383-0.318,0.732-0.654,1.047-1.009c0.035-0.038,0.07-0.075,0.104-0.113
			c0.126-0.146,0.25-0.292,0.368-0.442c0.14-0.175,0.273-0.354,0.403-0.536c0.046-0.064,0.096-0.127,0.141-0.192
			c0-0.001,0.001-0.001,0.001-0.002c0.004-0.006,0.007-0.012,0.011-0.018c-0.004,0.006-0.007,0.012-0.011,0.018
			c0.39-0.396,0.737-0.751,0.922-0.987c1.188-1.518,1.749-3.004,2.64-4.08c1.624-1.959,4.787-4.029,7.44-6.24
			c5.424-4.52,9.52-8.195,14.88-12.96c1.958-1.74,7.714-3.958,8.16-5.76c0.996-4.023-3.241-8.423-3.36-11.521c0-0.08,0-0.16,0-0.239
			c-1.282-4.159-2.665-8.216-4.08-12.24c0-0.08,0-0.16,0-0.24c-0.47-1.851-0.903-3.736-1.68-5.28
			c-0.273-0.847-0.744-1.496-0.72-2.64c-0.304-1.937-1.256-3.225-1.44-5.28c1.915-2.522,4.896-2.729,7.2-4.56
			c1.715-1.364,3.061-3.815,5.04-5.521c1.949-1.679,3.952-3.12,5.76-4.8c0.836-0.777,1.694-1.684,2.566-2.638
			c0.407-0.385,0.815-0.775,1.227-1.175c0.413-0.401,0.828-0.809,1.247-1.228c0.194-0.194,0.389-0.404,0.585-0.625
			c0.06-0.066,0.12-0.139,0.18-0.208c0.142-0.164,0.284-0.327,0.428-0.498c0.005-0.007,0.011-0.013,0.016-0.02
			c0.371-0.443,0.747-0.906,1.13-1.36c0.161-0.192,0.325-0.378,0.489-0.564c-0.163,0.186-0.327,0.371-0.487,0.563
			c1.141-1.076,2.286-1.988,3.42-2.568c4.539-2.32,9.947-3.353,14.641-5.28c4.73-1.942,8.984-4.238,13.439-6.72
			c0.613-0.027,0.84-0.44,1.2-0.72c1.565-0.434,2.29-1.71,3.84-2.16c0.183,1.338,0.082,2.958,0.48,4.08
			C334.806,387.171,332.865,392.419,335.761,393.299z M312.693,481.62c-1.885,3.772-4.117,7.274-6.5,10.634
			C308.573,488.893,310.809,485.393,312.693,481.62z M270.347,534.876c-2.737-3.653-5.438-8.632-7.786-12.937
			C264.908,526.244,267.61,531.222,270.347,534.876z M252.96,502.74c1.211-0.098,1.451-1.65,0.72-2.4
			c-1.007-1.073-1.624-2.536-3.12-3.12c-0.627-0.688-1.682,0.225-1.68,0.96c-0.326,0.555-0.69,1.07-0.72,1.92
			c-0.831-2.114,1.125-3.513,2.4-4.32c1.125-0.409,2.878,0.427,4.08,0.721c0.054,0.186,0.007,0.473,0.24,0.479
			c1.597,3.945,0.284,8.788-3.12,9.84c-0.821-0.698-1.457-1.583-1.68-2.88C251.097,503.597,252.118,503.257,252.96,502.74z
			 M249.12,502.5c0.275-1.245,0.742-2.298,0.96-3.601c1.154,0.287,1.326,1.555,2.16,2.16
			C251.62,501.96,250.139,501.999,249.12,502.5z M247.44,494.819c0.329-0.259,0.655-0.478,0.98-0.679
			C248.096,494.337,247.77,494.561,247.44,494.819z M246.726,451.75c0.012,0.018,0.024,0.033,0.036,0.05
			c-0.01-0.014-0.02-0.026-0.029-0.04c0.675,1.075,1.448,2.637,3.108,2.26c0.053-0.187,0.201-0.279,0.48-0.24
			c1.031-5.974-1.509-12.036-2.222-18.256c0-0.003,0-0.006-0.001-0.009c-0.108-1.359-0.16-2.708-0.14-4.063c0-0.005,0-0.011,0-0.016
			c0.194-2.683,1.236-5.327,1.403-8.137c0.869,1.118,1.936,1.846,3.123,2.393c0.177,0.14,0.361,0.272,0.558,0.394
			c0.2,0.125,0.412,0.237,0.64,0.334c0.152-0.047,0.291-0.063,0.435-0.092c1.534,0.516,3.192,0.905,4.845,1.531
			c1.712-1.008,3.086-2.354,5.04-3.12c0.334,1.746,0.795,3.365,1.44,4.801c0,0.08,0,0.159,0,0.239c0.733,3.188,1.6,6.24,2.88,8.88
			c0.187,0.214,0.258,0.542,0.24,0.96c1.295,4.781,3.15,9.001,4.557,13.672c-0.035,0.034-0.072,0.066-0.107,0.102
			c-0.005,0.005-0.012,0.01-0.017,0.016c0,0.001-0.001,0.001-0.001,0.002c0-0.001,0-0.001,0-0.001
			c-0.002,0.002-0.004,0.004-0.006,0.006c-5.262,4.682-11.519,8.395-16.425,13.564c-3.652,3.848-7.188,7.711-11.28,11.28
			c-1.221,1.065-2.398,2.568-4.08,2.88c-2.134-9.945-4.453-19.707-4.08-32.16c0-0.08,0-0.16,0-0.24
			c0.972-1.667,0.401-4.879,0.96-6.96c3.102,2.944,5.887,6.157,8.313,9.485c0.109,0.164,0.219,0.323,0.329,0.479
			c0.003,0.006,0.007,0.011,0.01,0.016C246.73,451.757,246.729,451.754,246.726,451.75z M248.098,435.527
			c0.113,1.418,0.286,2.85,0.501,4.317C248.383,438.377,248.211,436.945,248.098,435.527z M272.785,453.604
			c-4.261,4.157-9.362,7.293-13.756,11.235C263.421,460.894,268.523,457.76,272.785,453.604z M241.423,485.308
			c0.692-0.582,1.363-1.175,2.022-1.772c0.105-0.096,0.211-0.191,0.315-0.287c0.656-0.603,1.3-1.21,1.93-1.824
			c0.003-0.003,0.007-0.007,0.01-0.01c-0.001,0.001-0.002,0.002-0.003,0.003c0.015-0.015,0.029-0.028,0.043-0.043
			c-0.014,0.013-0.026,0.026-0.04,0.04c1.683-1.405,3.425-2.809,5.1-4.354c3.572-3.296,6.282-7.485,9.84-10.56
			c2.296-1.984,5.221-3.778,7.92-6c1.556-1.281,6.789-4.581,7.2-6.48c0.394-1.818-1.468-5.784-2.16-7.92
			c-2.424-7.483-5.443-14.724-7.2-22.56c0.446-0.884,1.685-1.896,2.676-1.719c0.101,0.243,0.2,0.487,0.296,0.731
			c0.058,0.143,0.119,0.285,0.174,0.429c0,0,0,0,0-0.001c0.189,0.498,0.364,1.001,0.536,1.504c-0.172-0.503-0.346-1.005-0.536-1.503
			c1.763,7.338,4.653,17.026,7.174,25.038c0.69,2.193,2.943,6.657,2.64,8.16c-0.196,0.975-3.09,2.687-4.32,3.6
			c-4.518,3.355-8.344,7.029-12.48,10.561c-5.904,5.04-11,9.494-16.56,14.16c-0.542,2.577-2.429,3.812-3.84,5.52
			c-1.354-0.405-1.195-2.325-2.16-3.12C240.448,486.35,240.928,485.823,241.423,485.308z M271.4,428.836
			c0.457,1.683,0.89,3.407,1.352,5.2C272.29,432.246,271.856,430.517,271.4,428.836z M329.521,355.379
			c0.155-1.125-0.313-2.874,0.24-3.6c0-0.08,0-0.16,0-0.24c0-0.96,0-1.92,0-2.88c0.08,0,0.16,0,0.239,0c0-0.88,0-1.76,0-2.64
			c0-1.36,0-2.72,0-4.08c0.151-0.729-0.307-2.067,0.24-2.4c0.031-2.129,0.766-3.555,0.96-5.52c0.481-1.438,0.419-3.421,0.96-4.8
			c0.209-1.071,0.595-1.965,0.721-3.12c0.45-0.349,0.028-1.571,0.479-1.92c-0.001-0.241,0.194-0.286,0.24-0.48
			c0.16,0,0.32,0,0.479,0c0,0.16,0,0.32,0,0.48c0,4.56,0,9.12,0,13.68c0,0.32,0,0.64,0,0.96c0,1.36,0,2.72,0,4.08
			c0,0.08,0,0.16,0,0.24c-0.08,0-0.159,0-0.239,0c0,0.48,0,0.96,0,1.44c0,0.32,0,0.64,0,0.96c-0.149,0.651,0.305,1.905-0.24,2.16
			c0.021,1.381,0.052,2.772-0.24,3.84c-0.151,0.808,0.31,2.229-0.24,2.64c0,0.48,0,0.96,0,1.44c0,0.32,0,0.64,0,0.96
			c0,0.8,0,1.6,0,2.4c0,0.16,0,0.32,0,0.48c-0.08,0-0.159,0-0.239,0c0,0.72,0,1.44,0,2.16c0,0.24,0,0.48,0,0.72
			c0,0.16,0,0.32,0,0.48c0,0.88,0,1.76,0,2.64c0,0.16,0,0.32,0,0.48c-0.08,0-0.16,0-0.24,0c0.157,1.603-0.316,3.837,0.24,5.04
			c0,0.48,0,0.96,0,1.44c-0.308,0.013-0.333,0.308-0.721,0.24c-1.331,1.388-3.149,2.291-4.8,3.36
			c-3.072,1.728-6.283,3.316-9.36,5.04c-0.079,0-0.159,0-0.239,0c-1.564,0.676-3.229,1.252-4.561,2.16c-0.08,0-0.16,0-0.239,0
			c-0.992,0.208-1.649,0.751-2.641,0.96c4.838-5.882,11.525-9.915,17.28-14.88c0.046-0.194,0.24-0.239,0.24-0.48
			c1.043-1.997,1.282-4.797,1.68-7.44c0-0.8,0-1.6,0-2.4c0.08,0,0.16,0,0.24,0c0-1.2,0-2.4,0-3.6
			C329.681,355.379,329.601,355.379,329.521,355.379z M312,386.58c5.404-2.002,11.155-4.496,16.08-7.44
			c0.194-0.046,0.239-0.241,0.48-0.24c0.08,0,0.16,0,0.24,0c2.139-1.142,4.004-3.834,5.76-4.56c0.039,0.279-0.053,0.427-0.24,0.48
			c0,0.08,0,0.16,0,0.24c-0.048,1.792-1.657,2.022-2.64,2.88c-0.478,0.003-0.306,0.655-0.96,0.48c-0.461,0.572-2.171,2.21-2.641,1.2
			c-0.302,0.059-0.242,0.477,0,0.48c-7.18,4.66-15.701,7.979-24.479,11.04C304.852,388.183,308.519,387.87,312,386.58z
			 M202.56,213.059c0.264-0.459,0.605-0.935,0.995-1.419c-0.334,0.311-0.654,0.635-0.995,0.939c0.341-0.304,0.661-0.629,0.995-0.94
			c0.001-0.001,0.002-0.002,0.003-0.003c1.295-1.205,2.544-2.455,3.738-3.761c1.974-1.736,4.088-3.384,5.583-4.656
			c3.851-3.274,7.756-6.299,12-8.16c17.948-6.052,33.868-14.132,56.88-15.12c2.628,0.466,6.706,0.308,9.36,0
			c1.166,0.275,1.799,1.082,3.12,1.2c5.191,3.208,11.225,5.576,16.32,8.88c0.56,0.08,1.034,0.246,1.2,0.72
			c0.447,0.672,1.314,0.925,2.16,1.2c0.694,0.745,1.656,1.224,2.88,1.44c0.262,0.539,1.178,0.422,1.439,0.96
			c0.307,0.013,0.333,0.308,0.721,0.24c0.026,0.454,0.793,0.167,0.72,0.72c0.654-0.175,0.483,0.477,0.96,0.48
			c0.745,0.375,1.259,0.981,1.92,1.44c0.489,0.07,0.471,0.649,0.96,0.72c6.093,6.707,11.783,13.817,15.12,23.28
			c0.281,0.359,0.298,0.981,0.72,1.2c0.146,1.214,0.611,2.109,0.96,3.12c1.227,5.094,2.662,9.979,3.36,15.6
			c0.321,0.181,0.434,1.775,0.24,1.68c-0.422-0.218-0.439-0.841-0.721-1.2c-1.925-3.434-3.874-6.846-5.76-10.32
			c-1.082-1.158-1.631-2.849-2.64-4.08c-0.477-0.724-0.603-1.797-1.44-2.16c-0.542,0.793,0.586,1.966,0.96,2.64
			c0.046,1.474,0.944,2.096,1.2,3.36c0.396,1.364,1.149,2.371,1.68,3.6c0,0.08,0,0.16,0,0.24c0.523,0.756,0.653,1.907,1.44,2.4
			c0.287,1.313,1.153,2.047,1.439,3.36c0.537,0.664,0.935,1.466,1.2,2.4c1.082,2.198,1.948,4.612,2.641,7.2
			c0.345,0.774,0.459,1.781,0.72,2.64c0.473,1.447,0.767,3.073,1.439,4.32c-1.089-0.313-2.511-0.313-3.6,0c0,0.08,0,0.16,0,0.24
			c-1.101,0.26-1.238,1.482-1.92,2.16c0,0.08,0,0.16,0,0.24c-0.444,1.156-0.837,2.363-1.68,3.12c-1.731,3.628-4.199,6.521-6.24,9.84
			c-0.347,1.094-0.825,2.055-1.44,2.88c-0.175,1.265-0.815,2.064-1.2,3.12c-0.301,0.819-0.516,1.724-0.96,2.4
			c-0.125,1.874-0.814,3.185-2.399,3.6c-0.302,1.299-0.337,2.864-0.72,4.08c0,0.24,0,0.48,0,0.72
			c-0.607-0.192-0.612-0.988-0.96-1.44c-0.104-0.457-0.518-0.603-0.48-1.2c0-0.08,0-0.16,0-0.24c-0.204,0.044-0.227-0.094-0.24-0.24
			c-1.121-1.199-0.83-3.81-1.68-5.28c-0.064-1.216-0.41-2.15-0.72-3.12c-0.367-1.793-0.735-3.584-1.44-5.04
			c-0.086-1.514-0.611-2.588-0.96-3.84c0.023-0.744-0.108-1.331-0.479-1.68c-0.482-2.558-1.313-4.768-1.921-7.2
			c0.397-1.363,1.665-1.855,2.16-3.12c0.331-0.309,0.237-1.042,0.24-1.68c0.399-0.887,0.399-3.433,0-4.32c0-0.16,0-0.32,0-0.48
			c0.216-1.335-0.524-1.715-0.96-2.4c-3.651-0.552-5.092,1.746-7.2,3.36c-0.936-0.055-1.244,0.516-1.92,0.72
			c-1.756,0.403-2.853,1.467-4.8,1.68c-0.276-0.284-0.271-0.848-0.24-1.44c0-0.08,0-0.16,0-0.24c0.204-2.665,0.41-5.327,0.926-7.68
			c0.011,0,0.022,0,0.034,0c0-0.053,0.001-0.108,0.002-0.163c0.079-0.346,0.144-0.707,0.238-1.038
			c-0.567-1.031-1.247,0.613-1.44-0.96c0-0.08,0-0.16,0-0.24c-0.504-1.176-1.813-1.546-2.64-2.4
			c-6.745,0.375-11.597,2.643-17.52,3.84c-0.966,0.154-2.552-0.312-3.12,0.24c-0.966,0.154-2.552-0.312-3.12,0.24
			c-2.813-0.081-4.338,0.958-6.96,0.48c-0.087-0.469-0.984-0.128-1.327-0.343c0.137,0.175,0.279,0.343,0.422,0.511
			c-0.017,0.025-0.047,0.037-0.055,0.071c0.083,0.041,0.152,0.095,0.23,0.141c1.886,2.15,4.279,3.793,6.97,5.139
			c0.08,0,0.16,0,0.24,0c0.388,0.492,1.155,0.605,1.68,0.96c-5.138-0.063-9.034-3.446-12.96-5.52c-0.07,0.147-0.597,0.924,0,0.96
			c0.022,1.498,0.502,2.538,0.96,3.6c0.936,3.224,3.59,4.73,5.52,6.96c-3.14-1.181-6.283-4.437-8.16-7.68
			c-0.288-1.312-1.077-2.123-1.2-3.6c-1.565,0.035-2.742,0.458-4.08,0.72c-0.729,0.15-2.067-0.307-2.4,0.24
			c-0.691,0.028-1.571-0.131-1.92,0.24c-0.16,0-0.32,0-0.48,0c1.373-3.187,4.48-4.64,5.04-8.64c-2.095,0.465-2.928,2.192-4.08,3.6
			c-0.013,0.146-0.036,0.284-0.24,0.24c-1.078,1.242-1.815,2.825-3.12,3.84c0,0.08,0,0.16,0,0.24
			c-1.788,2.132-3.114,4.726-5.28,6.48c-1.51,2.33-3.44,4.24-5.04,6.48c-1.088,1.392-1.922,3.038-3.12,4.32
			c0.011,0.73-0.507,0.933-0.72,1.44c-0.668,1.171-1.117,2.563-1.92,3.6c-0.063,0.977-0.451,1.628-0.72,2.4c0,0.16,0,0.32,0,0.48
			c-0.053,0.187-0.201,0.279-0.48,0.24c-0.733-0.787-1.269-1.772-2.4-2.16c-0.601,0.041-0.751-0.369-0.96-0.72
			c-1.114-1.606-3.087-2.354-4.08-4.08c0.008-0.155-0.002-0.316,0-0.473c0.015-0.049,0.011-0.118,0-0.192
			c-0.002-0.021,0.004-0.032-0.001-0.055c0-0.002-0.001-0.004-0.001-0.006c-0.003-3.535-1.242-7.419-1.438-11.273
			c0-0.16,0-0.32,0-0.48c-0.152-0.808,0.31-2.229-0.24-2.64c-0.315-3.443-0.245-6.857,0-10.32c0.556-0.964,0.083-2.957,0.24-4.32
			c0-0.16,0-0.32,0-0.48c0.549-0.411,0.088-1.832,0.24-2.64c0.395-3.286,1.051-6.309,1.44-9.6c0.736-2.464,1.832-4.568,3.12-6.48
			c0.601-1,1.544-1.656,1.92-2.88c1.052-1.027,1.987-2.173,2.64-3.6c-0.041-0.601,0.369-0.751,0.72-0.96
			c0.988-0.932,1.787-2.054,2.4-3.36c0.605-0.354,0.992-0.928,1.2-1.68c0.791-0.569,1.288-1.432,1.68-2.4
			c-0.011-0.411,0.178-0.622,0.48-0.72c0.58-0.3,0.776-0.984,0.96-1.68c0.422-0.058,0.547-0.413,0.48-0.96
			c0.349-0.106-0.024-0.211,0-0.48c-0.083-0.009-0.158,0.008-0.238,0.011c0-0.004-0.002-0.006-0.002-0.011
			c-0.013,0.002-0.02,0.01-0.033,0.012c0,0,0,0,0,0c-0.002,0-0.004,0.002-0.006,0.002c0.002,0,0.003-0.002,0.006-0.002
			c-1.3,0.071-2.096,1.657-2.847,2.628c-2.43,3.141-5.158,7.066-7.44,10.56c0.041,0.601-0.369,0.751-0.72,0.96
			c-4.958,4.562-7.108,11.932-7.68,20.88c0,0.16,0,0.32,0,0.48c-0.551,0.489-0.087,1.993-0.24,2.88c0,0.16,0,0.32,0,0.48
			c-0.281,0.039-0.202,0.438-0.24,0.72c-1.278-4.562-1.259-10.421-1.2-16.32c0-0.24,0-0.48,0-0.72
			c0.486-3.834,1.086-7.554,1.68-11.28c0.817-3.263,1.564-6.595,2.64-9.6c0.521,0.041,0.109-0.851,0.24-1.2
			c-0.136,0.077-0.264,0.166-0.392,0.255h0c-0.151,0.019-0.272,0.068-0.363,0.147c-0.003,0.002-0.005,0.006-0.008,0.009
			c-0.088,0.081-0.155,0.181-0.196,0.308c-1.526,2.778-2.604,6.004-3.418,9.495c0.814-3.49,1.892-6.716,3.417-9.494
			c-1.959,1.858-2.783,5.363-3.6,8.64c-0.946,3.794-1.721,8.15-1.92,12.24c0,0.24,0,0.48,0,0.72c-0.4,2.642-0.4,6.958,0,9.6
			c0.404,6.716,2.221,12.02,3.12,18.24c0.155,1.125-0.314,2.874,0.24,3.6c0,0.16,0,0.32,0,0.48c0.152,0.808-0.309,2.229,0.24,2.64
			c0.01,2.39,0.357,4.442,0.96,6.24c0,0.32,0,0.64,0,0.96c-1.506-1.774-0.66-5.9-3.36-6.48c-4.35,0.19-6.132-2.188-9.6-2.88
			c-3.626-2.294-6.91-4.931-9.36-8.4c-0.492-1.828-0.891-3.749-1.44-5.52c-0.149-1.21-0.295-2.425-0.72-3.36
			c-0.065-0.815,0.186-1.946-0.24-2.4c0-0.48,0-0.96,0-1.44c0.454,0.026,0.167,0.793,0.72,0.72c0.223,0.977,0.668,1.732,1.44,2.16
			c0.351,0.208,0.761,0.359,0.72,0.96c3.218,4.463,7.177,8.184,11.28,11.76c0.08,0,0.16,0,0.24,0c0.46,0.5,0.92,1,1.68,1.2
			c0.104-0.904-0.507-1.093-0.96-1.44c-0.288-0.992-0.92-1.64-1.68-2.16c-2.04-2.92-4.642-5.278-7.2-7.68
			c-0.158-0.882-0.885-1.195-1.44-1.68c-0.412-0.309-0.944-0.496-0.96-1.2c-1.41-2.27-2.645-4.716-3.12-7.92c0-0.24,0-0.48,0-0.72
			c-0.082-1.726,1.282-2.944,1.42-4.017c0.004-0.024,0.017-0.038,0.02-0.063c-0.004-0.001-0.01-0.006-0.015-0.007
			c-0.175-0.056-0.464-0.385-0.73-0.484c0.266,0.099,0.556,0.428,0.73,0.484c0.006-0.078,0.023-0.157,0.015-0.233
			c-0.873-1.09-2.437,0.152-2.64,1.2c-0.213,0.586-0.559,1.041-0.48,1.92c-0.594-0.091,0.07,2.28-0.48,1.44
			c-0.547,0.067-0.902-0.058-0.96-0.48c0.483-1.357,1.139-2.541,1.44-4.08C200.967,218.526,201.155,215.508,202.56,213.059z
			 M291.847,245.185c-1.057,0.346-2.035,0.694-2.886,0.994C289.812,245.879,290.79,245.53,291.847,245.185z M271.92,246.659
			c-0.683-0.473-1.201-1.11-1.928-1.538C270.72,245.549,271.238,246.186,271.92,246.659z M197.816,225.631
			c-0.046-0.044-0.083-0.094-0.118-0.145c0,0,0,0,0,0C197.733,225.537,197.772,225.585,197.816,225.631z M198.013,223.176
			c0.237-0.512,0.521-1.029,0.761-1.493C198.535,222.146,198.25,222.664,198.013,223.176c-0.237,0.512-0.427,1.019-0.478,1.46
			C197.585,224.194,197.775,223.688,198.013,223.176z M200.88,315.304c-0.154,2.956-0.712,6.184-0.24,9.596
			c-0.884-1.241-1.175-3.109-0.24-4.32c-0.191-0.348-0.391-0.686-0.585-1.031c0.004,0.011,0.006,0.022,0.01,0.034
			c-0.004-0.012-0.007-0.023-0.011-0.035c0,0,0.001,0.001,0.001,0.001c0-0.001-0.001-0.002-0.001-0.003
			c-0.056-0.156-0.122-0.31-0.196-0.463c-0.006-0.013-0.012-0.025-0.018-0.038c-0.072-0.145-0.148-0.287-0.23-0.427
			c-0.015-0.026-0.03-0.051-0.045-0.077c-0.078-0.129-0.156-0.255-0.239-0.379c-0.026-0.039-0.052-0.077-0.078-0.116
			c-0.081-0.118-0.159-0.233-0.24-0.345c-0.003-0.004-0.005-0.008-0.008-0.012c-0.566-0.967-1.141-1.925-1.721-2.877
			c-0.074-0.169-0.158-0.329-0.252-0.479c0,0,0,0,0,0c-0.094-0.15-0.195-0.292-0.305-0.427c-3.377-5.493-6.892-10.847-9.762-16.847
			c1.579-0.375,2.636,1.841,4.8,1.68c1.201,1.94,2.917,3.71,4.56,5.76c0.852,1.063,2.074,2.29,3.027,3.531
			c-0.054-0.132-0.107-0.266-0.147-0.412c0.041,0.146,0.094,0.28,0.148,0.412l0,0c0.163,0.396,0.386,0.732,0.683,0.994
			c0.256,0.416,0.476,0.829,0.609,1.233c0.281,0.848,0.421,1.732,0.48,2.647C200.88,313.705,200.88,314.504,200.88,315.304z
			 M200.4,273.779c-0.359,0.521-0.409,1.351-0.48,2.16c-0.557-1.204-0.083-3.438-0.24-5.04c0-0.8,0-1.6,0-2.4
			c0.56-4.641,0.081-10.32,0.24-15.36c0-0.16,0-0.32,0-0.48c1.434,0.919,4.611,4.546,3.12,6.96c0,0.08,0,0.16,0,0.24
			c-0.141,3.458-1.151,6.048-1.68,9.12C200.897,270.437,200.534,271.993,200.4,273.779z M206.88,346.5
			c-0.331,0.01-0.527-0.113-0.72-0.24c0-0.08,0-0.16,0-0.24c-0.993,0.033-1.375-0.546-1.92-0.96c0.224-2.57-0.115-4.82-0.568-6.921
			c-0.081-0.497-0.199-0.986-0.334-1.469c-0.504-2.139-1.016-4.159-1.018-6.25c1.886,4.514,2.876,9.924,4.32,14.88
			C206.842,345.578,206.957,345.943,206.88,346.5z M225.36,343.14c-0.832,2.009,0.513,4.916-1.2,6.72
			c-3.778-5.813-6.138-11.915-11.28-16.08c-0.807-0.653-1.891-0.931-2.64-1.68c-1.509-1.509-2.611-3.383-3.556-5.421
			c0,0,0,0.001,0,0.002c-0.088-0.276-0.206-0.523-0.342-0.751c0.001,0.001,0.002,0.002,0.002,0.003
			c-0.919-2.1-1.703-4.346-2.585-6.554c0-0.16,0-0.32,0-0.48c1.891-2.589,4.558-4.402,5.76-7.68c-0.674-0.846-1.218-1.822-1.92-2.64
			c-1.966,0.194-2.348,1.972-3.6,2.88c-0.692-2.188-2.249-3.511-2.4-6.24c1.15,2.225,3.17,5.41,5.28,1.92c0.08,0,0.16,0,0.24,0
			c0.16,0,0.32,0,0.48,0c1.142,0.458,0.938,2.263,2.4,2.4c0.146-0.068,0.266-0.152,0.366-0.245c0.042-0.001,0.071,0.008,0.115,0.004
			c0.038-0.08,0.049-0.157,0.074-0.236c0.696-1.085-0.451-3.151-0.019-4.48c0.008-0.008,0.015-0.017,0.023-0.025
			c0.208-0.21,0.396-0.44,0.609-0.646c0.026-0.025,0.053-0.05,0.08-0.074c0.128-0.115,0.267-0.22,0.432-0.297c0.08,0,0.16,0,0.24,0
			c0.057-0.343,0.395-0.405,0.48-0.72c0.355-0.924,1.999-0.561,2.4-1.44c0.934-0.266,1.736-0.664,2.4-1.2
			c1.247-0.273,2.35-0.69,3.36-1.2c3.016-0.983,6.21-0.615,8.88,0.48c0.006,0.233,0.293,0.186,0.48,0.24
			c0.601,0.759,1.64,1.08,2.64,1.44c1.074,1.406,2.859,2.101,3.6,3.84c0.559,0.881,1.001,1.878,2.16,2.16
			c-0.123,0.641-0.254,1.273-0.38,1.912c-0.002,0.012-0.004,0.024-0.007,0.037c-0.818,4.164-1.671,8.292-2.493,12.452
			c0,0.08,0,0.16,0,0.24c-0.519,0.841-0.719,2.002-0.96,3.12c0,0.08,0,0.16,0,0.24c-1.053,2.308-1.734,4.985-2.4,7.68
			c0,0.08,0,0.16,0,0.24c-0.655,1.203-1.015,2.392-0.991,3.455c-0.017,0.189-0.013,0.393,0.031,0.625
			c0.005,0.027,0.021,0.047,0.028,0.074v0c0,0,0,0.001,0,0.001c0,0,0,0,0-0.001c0.242,1.127,1.041,2.046,2.612,2.566
			c0.095,0.896-1.499,0.101-1.68,0.72c-0.08,0-0.16,0-0.24,0C229.082,341.081,226.313,340.838,225.36,343.14z M229.44,346.979
			c0.177-1.343,0.448-2.592,0.48-4.08c1.595-0.612,3.851-0.563,5.445-1.175c0.062,0.014,0.108,0.047,0.167,0.065
			c0.728,0.812,1.79,1.289,2.547,2.07c-1.966,2.754-6.359,3.081-8.88,5.28C228.444,348.521,229.799,347.995,229.44,346.979z
			 M230.603,342.463c0.545-0.134,1.14-0.294,1.744-0.443C231.742,342.168,231.148,342.33,230.603,342.463z M226.56,388.26
			c0.541-0.58,0.861-1.38,0.96-2.4c0.213-0.586,0.559-1.041,0.48-1.92c1.076-1.963,3.66-2.42,5.28-3.84
			c1.625-0.456,3.119-1.042,4.56-1.68c0.454,0.054,0.529-0.271,0.96-0.24c-0.273,0.526-0.619,0.98-0.48,1.92
			c1.326,0.326,0.89,1.041,1.2,2.16c4.605-1.075,5.805-5.555,10.08-6.96c2.008-1.352,2.304-4.416,5.28-4.8
			c0.241-0.319,0.227-0.893,0.72-0.96c2.274,1.646,5.113,2.727,7.92,3.84c0.615,0.186,1.36,0.24,1.68,0.72c0.16,0,0.32,0,0.48,0
			c0.538,0.262,1.203,0.397,1.68,0.72c1.574,0.586,3.014,1.306,4.32,2.16c0.331-0.01,0.527,0.113,0.72,0.24
			c0.934,0.266,1.736,0.664,2.4,1.2c0.331-0.01,0.527,0.113,0.72,0.24c0.33-0.011,0.527,0.113,0.72,0.24
			c1.465,0.855,2.118,2.522,3.12,3.84c0.134,0.345,0.375,0.585,0.72,0.72c0.08,0,0.16,0,0.24,0c0.574-0.146,1.741,0.301,1.92-0.24
			c2.955-0.246,5.545-0.855,7.92-1.68c2.096-0.384,3.373-1.587,5.04-2.4c-0.08-2.72-0.985-4.614-1.68-6.72
			c0.076-0.557-0.039-0.921-0.24-1.2c-0.254-1.266-0.461-2.579-0.96-3.6c-0.07-0.809-0.12-1.64-0.479-2.16
			c-0.136-1.145-0.251-2.309-0.721-3.12c-0.079-1.361-0.301-2.579-0.72-3.6c-0.047-1.153-0.115-2.285-0.48-3.12
			c-0.147-2.413-0.459-4.661-0.959-6.72c-0.548-2.172,0.595-6.035-1.2-6.96c-1.047,0.072-1.465,0.774-2.64,0.72
			c-1.204,0.156-3.035-0.314-3.84,0.24c-2.877,0.003-5.471,0.289-7.68,0.96c-1.68,0-3.36,0-5.04,0
			c-1.284-0.316-2.446-0.754-3.84-0.96c-0.519-0.521-1.401-0.679-2.16-0.96c-0.325-0.076-0.458-0.342-0.96-0.24
			c-0.398-0.506-2.137-0.836-2.16,0.24c0,0.16,0,0.32,0,0.48c1.723,1.237,4.212,1.708,6.24,2.64c2.095,0.545,4.39,0.89,7.2,0.72
			c0.08,0,0.16,0,0.24,0c1.671-0.089,3.191-0.329,4.56-0.72c0.08,0,0.16,0,0.24,0c0.729-0.151,2.067,0.307,2.4-0.24
			c0.08,0,0.16,0,0.24,0c0.887-0.153,2.391,0.311,2.88-0.24c0.16,0,0.32,0,0.48,0c0.808,0.152,2.229-0.31,2.64,0.24
			c0.465,5.919,1.351,11.419,2.356,16.797c0.005,0.066,0.013,0.134,0.018,0.2c0,0.01,0.002,0.019,0.002,0.028
			c-1.471-0.853-2.435-3.162-3.996-3.532c0.008,0.006,0.014,0.013,0.022,0.02c-0.008-0.006-0.015-0.014-0.022-0.02c0,0,0,0,0,0
			c-0.021-0.017-0.04-0.037-0.062-0.053c-0.01,0.01-0.016,0.024-0.025,0.035c-0.146-0.028-0.297-0.044-0.455-0.035
			c0.403,3.677,3.163,4.997,5.28,6.96c0.897,3.262,1.396,6.923,2.64,9.84c0,0.08,0,0.16,0,0.24c0.116,1.004,0.461,1.779,0.72,2.64
			c-0.618,0.422-1.733,0.346-2.159,0.96c-0.08,0-0.16,0-0.24,0c-0.909,0.291-1.936,0.464-2.64,0.96c-0.08,0-0.16,0-0.24,0
			c-2.257,0.463-4.66,0.78-6.72,1.44c-0.807-0.876-2.055-2.58-2.16-3.36c1.456-0.144,3.587,0.387,4.32-0.48
			c0.035-0.754-0.615-0.825-1.2-0.96c-0.08,0-0.16,0-0.24,0c-1.567-0.753-3.69-0.949-5.76-1.2c-0.08,0-0.16,0-0.24,0
			c-3.177-0.648-5.206-2.079-7.92-2.64c-0.941-0.02-1.497-0.423-2.16-0.72c-0.536-0.584-1.567-0.673-2.4-0.96c-0.08,0-0.16,0-0.24,0
			c-0.835-0.685-2.038-1.002-3.12-1.44c-0.24,0-0.48,0-0.72,0c-0.106-0.214-0.21-0.429-0.48-0.48c-1.622-0.74-2.703-2.74-4.32-2.64
			c-1.117,0.069-1.523,1.356-2.4,2.16c-2.469,2.264-7.506,5.289-9.36,7.2c-3.177-2.417-6.432,2.214-10.08,2.4
			c-0.031-2.209,0.174-4.654-0.48-6.24c0-0.08,0-0.16,0-0.24c-0.075-1.605-0.296-3.063-0.72-4.32c0-0.08,0-0.16,0-0.24
			c-0.047-1.154-0.115-2.285-0.48-3.12c0-0.08,0-0.16,0-0.24c-0.826-3.895-0.682-8.758-1.2-12.96c0.363-0.597,1.588-0.332,1.92-0.96
			c0.615-0.665,1.424-1.136,2.4-1.44c1.274-0.325,1.996-1.204,3.12-1.68c0.689-0.59,1.707-0.854,2.16-1.68c0.16,0,0.32,0,0.48,0
			c0.78,0.98,1.66,1.86,2.64,2.64c0.713,1.207,1.584,2.256,2.88,2.88c0.411-0.011,0.622,0.178,0.72,0.48
			c0.486,0.483,2.129,1.059,2.88,0.48c0.956-0.004,1.69-0.23,2.16-0.72c0.801-0.479,1.449-1.111,2.16-1.68
			c0.872-0.808,1.151-2.209,1.68-3.36c0.923-1.557,2.001-2.958,3.36-4.08c3.965-1.794,4.79-6.73,10.8-6.48
			c1.05,0.229,2.143,0.417,3.36,0.48c1.424,0.576,3.402,0.598,4.8,1.2c1.909,0.05,4.711,0.099,6.48,0.72
			c0.014-0.146,0.036-0.284,0.24-0.24c0.749-0.211,0.788-1.132,1.44-1.44c2.284,2.765,4.576,5.521,5.776,9.37
			c0.008,0.032,0.018,0.062,0.029,0.09c-0.001-0.001-0.002-0.002-0.002-0.003c0.064,0.209,0.139,0.407,0.196,0.623
			c0.048,0.912,0.213,1.708,0.48,2.4c0.079,1.361,0.301,2.579,0.72,3.6c0.28,2.439,0.79,4.65,1.2,6.96
			c1.353,3.928,1.601,8.959,2.64,13.2c0.048,1.632,0.335,3.025,0.72,4.32c-0.105,0.906,0.474,1.126,0.721,1.68
			c0.687,0.047,1.31,0.03,1.199-0.72c-0.991-6.612-1.964-15.313-4.08-21.6c0-0.08,0-0.16,0-0.24
			c-0.256-2.624-0.976-4.784-1.199-7.44c2.144,1.936,4.315,3.844,7.199,5.04c0.08,0,0.16,0,0.24,0
			c0.19,0.009,0.344-0.016,0.473-0.061c-0.128,0.045-0.281,0.07-0.473,0.061c0,0.08,0,0.16,0,0.24c0.585-0.135,1.234-0.206,1.2-0.96
			c-0.106-0.113-0.223-0.216-0.332-0.327c-0.091-0.29-0.291-0.554-0.556-0.716c-0.002-0.001-0.003-0.002-0.005-0.003
			c-0.089-0.054-0.183-0.091-0.284-0.114c-3.128-2.894-6.957-5.085-9.144-8.92c-0.18-0.397-0.484-1.026-0.873-1.77
			c0.087,0.227,0.179,0.449,0.271,0.671c-0.093-0.223-0.186-0.445-0.272-0.673c-0.11-0.288-0.22-0.576-0.325-0.869
			c-0.121-0.156-0.263-0.292-0.39-0.442c0,0,0,0,0,0c-0.001-0.002-0.003-0.004-0.005-0.006c0.001,0.002,0.003,0.003,0.005,0.005
			c-1.534-2.7-3.778-5.952-5.569-5.96c-0.096-0.023-0.178-0.062-0.282-0.078c-0.108,0.028-0.196,0.077-0.275,0.134
			c-0.062,0.041-0.115,0.089-0.165,0.142c0,0,0,0,0,0c-0.474,0.283-0.897,0.876-1.239,1.884c-1.068-0.188-1.44,0.32-2.4,0.24
			c-0.941-0.019-1.497-0.423-2.16-0.72c-1.79-0.118-3.392-0.423-5.52-0.72c-0.08,0-0.16,0-0.24,0c-0.596-0.364-1.457-0.463-2.4-0.48
			c-0.08,0-0.16,0-0.24,0c0-0.08,0-0.16,0-0.24c-0.8,0-1.6,0-2.4,0c0.952-1.368,2.493-2.147,3.12-3.84
			c-0.864,0.064-1.157-0.443-1.92-0.48c-1.26,1.46-2.62,2.82-4.08,4.08c-0.08,0-0.16,0-0.24,0c-0.29,0.991-1.227,1.333-1.68,2.16
			c-0.269,0.051-0.374,0.266-0.48,0.48c-0.315,0.085-0.377,0.422-0.72,0.48c-0.08,0-0.16,0-0.24,0
			c-0.495,0.865-1.425,1.295-1.92,2.16c-1.098,0.902-2.305,1.695-3.12,2.88c-0.315,0.085-0.377,0.422-0.72,0.48
			c-0.08,0-0.16,0-0.24,0c-0.94,1.14-1.979,2.18-3.12,3.12c-0.68,1.399-2.342,1.818-3.6,2.64c-0.315,0.308-0.884,0.308-1.2,0
			c-0.202-0.158-0.388-0.331-0.579-0.5c-1.321-1.313-3.077-4.023-4.222-4.78c-0.35-0.231-0.769-0.209-1.205-0.266
			c-0.431-0.272-0.896-0.511-1.396-0.715c-0.579-0.491-1.262-1.168-1.959-1.659c-3.996-2.813-3.153-5.566,0-9.6
			c0.178,1.423,0.09,3.111,1.68,3.12c1.39-0.878,1.342-3.073,1.44-4.8c0.324-5.702-0.186-12.955-0.48-17.76c0-0.08,0-0.16,0-0.24
			c0.553-0.647,0.085-2.315,0.24-3.36c-0.283-0.677-1.116-0.804-1.68-1.2c0.537-0.104,0.097-1.183,0.24-1.68
			c-2.486-4.314-6.772-6.828-12-8.4c-0.08,0-0.16,0-0.24,0c-0.844-0.602-3.441-0.712-4.56-0.24c-0.08,0-0.16,0-0.24,0
			c-1.534-0.088-3.082,1.072-4.08,0.72c0.615-0.186,1.36-0.239,1.68-0.72c0.519-0.201,0.819-0.621,1.68-0.48
			c1.04-0.24,2.214-0.346,3.12-0.72c0.08,0,0.16,0,0.24,0c0.8,0,1.6,0,2.4,0c0.24,0,0.48,0,0.72,0c1.865,0.229,1.369-2.742-0.24-2.4
			c-0.08,0-0.16,0-0.24,0c-0.414-0.398-2.466-0.398-2.88,0c-0.204-0.044-0.227,0.093-0.24,0.24c-1.137-0.177-1.65,0.27-2.64,0.24
			c-5.497,1.863-11.329,3.392-13.44,8.64c-1.12-0.88-1.559-2.441-2.16-3.84l-0.932-0.329c-0.792,0.234-2.043-0.896-2.429-1.352
			c-0.146-1.586,0.42-2.46,0.24-4.08c0-0.08,0-0.16,0-0.24c0.935-1.865,1.918-3.682,2.64-5.76c0-0.08,0-0.16,0-0.24
			c1.687-3.193,3.042-6.718,4.08-10.56c0-0.08,0-0.16,0-0.24c1.182-2.362,1.522-6.352,0.72-9.36c0-0.08,0-0.16,0-0.24
			c0.042-0.311,0.045-0.583,0.03-0.836c-0.006-0.1-0.023-0.188-0.035-0.283c-0.019-0.153-0.034-0.31-0.061-0.456
			c0.374-1.093,1.584-1.734,1.745-2.985c0.269-2.096-1.674-2.657-2.4-4.8c1.708,0.772,3.199,1.762,5.04,2.4c0.08,0,0.16,0,0.24,0
			c1.086,0.885,2.789,1.154,4.255,1.659c-0.932-0.732-2.384-0.945-3.605-1.388c1.224,0.441,2.673,0.656,3.606,1.388c0,0,0,0,0,0
			c0.017,0.013,0.032,0.027,0.048,0.041c0.008,0.006,0.015,0.013,0.023,0.02c0.105,0.439-0.282,1.372,0.234,1.4
			c0,0.08,0,0.16,0,0.24c0.7,4.5,5.078,5.322,6.48,9.12c0.812,0.127,1.505,0.451,2.13,0.878c0.092,0.065,0.181,0.133,0.273,0.198
			c-0.004-0.003-0.008-0.007-0.012-0.01c0.731,0.517,1.461,1.035,2.169,1.574c-0.704-0.536-1.431-1.05-2.157-1.564
			c1.956,1.485,3.236,3.994,5.268,4.941c0.528,0.473,1.122,0.556,1.691,0.367c0.376-0.001,0.767-0.034,1.198-0.145
			c0.156-0.45,0.319-0.884,0.481-1.322c0,0.001-0.001,0.001-0.001,0.002c0-0.001,0.001-0.002,0.002-0.003c0,0,0,0,0,0.001
			c0,0,0-0.001,0.001-0.001c0.332-0.526,0.58-1.137,0.688-1.774c0,0,0-0.001,0-0.002c0,0,0,0,0,0.001c0-0.001,0-0.001,0-0.002
			c0,0,0,0,0,0.001c1.638-4.047,3.597-7.626,6.27-11.3c1.998-2.746,5.064-5.265,6.352-8.334c0.127-0.25,0.235-0.509,0.319-0.781
			c0.003-0.01,0.006-0.021,0.009-0.031c0.08-0.266,0.139-0.542,0.166-0.832v0v0c0.062-0.347,0.107-0.698,0.115-1.061
			c0.535-0.827,1.703,0.99,2.4,0c0.769-0.271,1.592-0.488,2.64-0.48c1.783-0.297,3.646-0.514,5.28-0.96c0.08,0,0.16,0,0.24,0
			c0.16,0,0.32,0,0.48,0c1.953,5.967,6.697,9.143,12.48,11.28c0.08,0,0.16,0,0.24,0c0.755,0.445,1.887,0.513,3.12,0.48
			c0.24,0,0.48,0,0.72,0c0.883-0.156,1.888-0.191,2.4-0.72c-0.13-0.154-0.268-0.299-0.406-0.444
			c-0.096-0.182-0.225-0.332-0.369-0.466c-0.019-0.017-0.036-0.036-0.056-0.053c-0.15-0.13-0.318-0.243-0.502-0.34
			c-2.615-2.187-6.117-3.486-7.788-6.617c0.47-0.608,1.205,0.54,2.16,0.24c0.08,0,0.16,0,0.24,0c1.242,0.678,3.013,0.827,4.8,0.96
			c0.691,0.029,1.571-0.131,1.92,0.24c1.283,0.557,3.598,0.082,5.28,0.24c0.32,0,0.64,0,0.96,0c0.88,0.629,2.559,0.007,2.64-0.96
			c-0.045-1.795-1.829-1.147-2.88-1.44c-0.128-0.036-0.261-0.081-0.391-0.119c0.096,0.033,0.185,0.073,0.281,0.107
			c-0.449-0.159-0.908-0.308-1.373-0.451c0.009,0.002,0.018,0.005,0.027,0.008c-2.697-0.909-5.604-2.225-8.145-2.904
			c4.804-0.876,10.253-1.106,13.68-3.36c0.08,0,0.16,0,0.24,0c0.521-0.439,1.965,0.045,2.399-0.48c0.08,0,0.16,0,0.24,0
			c1.6-0.241,3.137-0.543,4.561-0.96c0.354-0.278,0.604-0.278,0.96,0c0,0.64,0,1.28,0,1.92c0.156,1.283-0.315,3.195,0.239,4.08
			c0.454,2.026,0.474,4.487,1.2,6.24c-0.544,0.976-1.61,1.429-1.439,3.12c0,0.08,0,0.16,0,0.24c0.685,0.436,0.98,1.26,2.399,0.96
			c0.146-0.014,0.284-0.036,0.24-0.24c0.932-0.269,1.229-1.172,2.16-1.44c1.518-0.322,2.653-1.027,4.08-1.44c0-0.08,0-0.16,0-0.24
			c0.597,0.037,0.743-0.377,1.2-0.48c0.453,0.054,0.529-0.271,0.96-0.24c0.262-0.538,1.178-0.421,1.439-0.96
			c0.655,0.175,0.483-0.477,0.96-0.48c0.08,0,0.16,0,0.24,0c0.464-0.736,1.313-1.086,1.92-1.68c0.65-0.149,0.598-1.003,1.44-0.96
			c0,0.32,0,0.64,0,0.96c-0.042,1.91,0.179,2.842-0.721,3.6c-0.474,0.966-1.719,1.161-2.159,2.16c-0.583,1.62,0.498,3.229,0.72,4.8
			c0.06,6.34,3.157,9.642,3.84,15.36c0.656,0.864,0.943,2.097,1.2,3.36c0.944,3.536,1.936,7.024,2.64,10.8
			c0.474,0.566,0.565,1.515,0.72,2.4c0.401,1.199,0.725,2.476,0.96,3.84c0.062,0.819,0.607,1.153,0.48,2.16
			c0.487,0.153,0.158,1.122,0.72,1.2c0.128,0.82,1.156,0.367,1.681,0.24c0.054-0.186,0.006-0.473,0.239-0.48c0-0.4,0-0.8,0-1.2
			c0.08,0,0.16,0,0.24,0c0.758-2.277,0.047-4.701,0.24-7.68c0.078-0.882-0.205-2.125,0.24-2.64c-0.118-1.078,0.121-1.798,0.239-2.64
			c-0.101,0.719-0.276,1.362-0.256,2.202c0.058-1.961,2.094-1.944,1.937-4.122c0-0.08,0-0.16,0-0.24
			c0.139-0.384,0.297-0.749,0.443-1.125c-0.022,0.052-0.052,0.107-0.073,0.159c0.079-0.206,0.168-0.402,0.249-0.606
			c-0.059,0.148-0.118,0.297-0.176,0.447c0.785-1.831,1.966-3.825,2.916-6.075c2.218-5.246,4.822-8.27,7.681-12.96
			c1.196-1.963,2.343-6.381,4.319-6.24c0.35,0.131,1.241-0.28,1.2,0.24c0.33-0.01,0.527,0.113,0.72,0.24
			c2.013,0.468,4.15,0.811,4.08,3.36c1.377,6.305,2.622,15.098,0,20.88c-0.266,0.934-0.663,1.736-1.199,2.4
			c0.01,0.331-0.113,0.527-0.24,0.72c-0.347,1.094-0.825,2.055-1.44,2.88c-0.116,1.083-0.767,1.633-0.96,2.64
			c-0.399,0.32-0.372,1.067-0.72,1.44c-0.146,0.734-0.51,1.25-0.72,1.92c-0.117,0.284-0.256,0.544-0.48,0.72
			c-0.072,1.207-0.771,1.789-0.96,2.88c-0.382,1.058-0.878,2.002-1.2,3.12c-0.663,1.256-0.663,3.176-1.2,4.56
			c-0.288,0.751-0.479,1.601-0.72,2.4c-0.674,1.726-1.006,3.794-1.92,5.28c-0.827,1.893-2.229,3.21-4.08,4.08
			c-0.562,0.364-1.555,1.15-2.16,0.72c0.236-0.981-0.026-0.97,0-2.16c-0.342-0.058-0.404-0.395-0.72-0.48
			c-1.606-1.039-1.868,1.326-2.4,2.16c-0.277,1.722-0.913,3.087-1.439,4.56c-0.125,0.355-0.355,0.605-0.48,0.96
			c-0.266,0.934-0.663,1.736-1.199,2.4c-1.068,2.932-2.388,5.613-3.601,8.4c-0.931,2.109-1.789,4.291-3.12,6
			c-0.844,2.356-2.398,4.001-3.359,6.24c-0.241-0.001-0.286,0.194-0.48,0.24c-6.64,4.401-12.541,9.539-13.68,19.44
			c0.018,0.418-0.054,0.747-0.24,0.96c0.085,2.494-4.437,4.094-1.92,6c0.073-0.021,0.161-0.029,0.242-0.042
			c0.073-0.012,0.143-0.028,0.187-0.07c2.95-0.89,4.196-5.233,5.091-8.768c0.202-0.278,0.317-0.644,0.24-1.2
			c1.584-6.336,6.583-9.258,10.561-13.2c0.08,0,0.16,0,0.239,0c0.08,0,0.16,0,0.24,0s0.16,0,0.24,0
			c1.079-0.84,1.692-2.147,2.64-3.12c0.979-2.302,2.364-4.196,3.36-6.48c0.266-0.934,0.664-1.736,1.2-2.4
			c0.106-1.093,0.616-1.783,0.96-2.64c0.745-1.577,1.328-4.589,2.399-5.04c-0.508,1.972-1.556,3.404-1.92,5.52
			c0.184,1.303-0.296,1.944-0.239,3.12c-0.398,0.414-0.398,2.466,0,2.88c0,0.32,0,0.64,0,0.96c0,0.48,0,0.96,0,1.44
			c0,0.8,0,1.6,0,2.4c0,0.32,0,0.64,0,0.96c0,0.32,0,0.64,0,0.96c0,0.48,0,0.96,0,1.44c0,1.52,0,3.04,0,4.56c0,0.08,0,0.16,0,0.24
			c-0.08,0-0.16,0-0.24,0c0,0.56,0,1.12,0,1.68c0,0.24,0,0.48,0,0.72c-0.146,0.574,0.301,1.741-0.24,1.92
			c0.098,4.177-0.631,7.528-3.84,8.4c-0.629,0.971-1.731,1.468-2.64,2.16c-2.178,1.502-3.952,3.409-6.48,4.56
			c-0.162,0.557-0.798,0.642-0.96,1.2c-0.558,0.163-0.643,0.797-1.2,0.96c-4.544,4.056-9.306,7.894-12.196,13.603
			c-0.034,0.043-0.068,0.087-0.103,0.13c-0.034,0.044-0.068,0.089-0.104,0.132c0.001-0.001,0.002-0.002,0.003-0.003
			c-0.9,1.129-1.866,2.193-2.869,3.22c1.001-1.026,1.967-2.088,2.866-3.216c-0.938,1.104-1.905,2.179-2.888,3.239
			c-0.007,0.007-0.014,0.014-0.021,0.021c-0.72,0.736-1.454,1.458-2.186,2.184c-0.917,0.905-1.826,1.819-2.706,2.762
			c-8.933,8.492-19.206,15.644-29.735,22.538c3.925-2.132,7.387-4.727,11.042-7.129c-3.652,2.404-7.116,4.997-11.042,7.129
			c0,0-0.001,0.001-0.001,0.001c-0.313,0.17-0.622,0.346-0.941,0.51c-0.139,0.045-0.251,0.118-0.353,0.2
			c-0.024,0.02-0.048,0.04-0.071,0.062c-0.09,0.084-0.173,0.177-0.245,0.278c-0.337,0.22-0.674,0.439-1.011,0.659
			c-6.246-7.594-9.568-18.111-16.56-24.96c0.632,0.073,0.788-0.332,0.72-0.96c-1.488-2.032-4.4-2.64-6-4.56
			c1.662-0.098,2.925-0.595,4.56-0.72c-0.301-0.906-1.789-1.882-2.64-1.44c-1.048-0.008-1.872,0.208-2.64,0.48
			c-1.124,0.077-1.934,0.466-2.4,1.2c0.623,2.178,3.261,2.339,4.56,3.84c-0.202,0.278-0.316,0.644-0.24,1.2
			c3.987,2.893,7.219,6.541,9.36,11.28C239.994,402.666,232.942,395.798,226.56,388.26z M270.744,373.883
			C270.745,373.883,270.745,373.883,270.744,373.883c2.054,2.019,6.392,1.751,9.582,2.631
			C277.132,375.637,272.796,375.901,270.744,373.883z M288.915,361.596c-0.299-3.83-1.18-8.066-1.714-12.447
			C287.737,353.532,288.616,357.765,288.915,361.596z M244.742,345.082c0.687,0.726,1.369,1.456,2.119,2.117
			C246.111,346.538,245.424,345.813,244.742,345.082z M271.331,334.409c3.23,0.56,6.211,1.228,9.416,1.45
			C277.541,335.637,274.561,334.973,271.331,334.409z M287.394,252.252c0.416,0.151,0.827,0.308,1.221,0.481c0,0,0,0-0.001,0
			C288.22,252.56,287.809,252.403,287.394,252.252z M212.64,424.02c1.229-0.588,2.357-1.437,3.524-2.17
			c-1.099,0.553-2.15,1.128-3.183,1.671c1.032-0.543,2.086-1.12,3.186-1.673c-0.001,0.001-0.002,0.001-0.003,0.002
			c0.539-0.271,1.089-0.534,1.654-0.783c0.007-0.003,0.014-0.006,0.021-0.009c0.561-0.246,1.135-0.478,1.727-0.688
			c0.004-0.001,0.008-0.003,0.012-0.004c2.805-0.715,6.281-0.705,9.383-0.666c0.546,0.978-1.092,1.549-1.44,2.4
			c-5.945-0.237-10.507,3.373-15.84,5.76c-12.794,5.726-25.986,10.646-42.48,12.96C182.157,433.845,199.017,430.545,212.64,424.02z
			 M224.88,425.22c-0.163,0.558-0.798,0.643-0.96,1.2c-1.094,0.346-2.055,0.824-2.88,1.439c-0.986,0.055-1.571,0.509-2.16,0.96
			c-0.82,0.221-1.017,1.063-2.16,0.96c-1.185,0.016-1.695,0.705-2.88,0.721c-0.32,0-0.64,0-0.96,0
			c-0.132-0.532,0.458-0.342,0.48-0.721c0.754-0.285,1.27-0.81,2.16-0.96c1.859-0.701,3.545-1.574,5.04-2.64
			c0.596,0.036,0.996-0.124,1.2-0.48C222.912,425.651,223.791,425.331,224.88,425.22z M168.48,443.699
			c0.587-0.213,1.041-0.559,1.92-0.479c0.349,0.131,1.24-0.28,1.2,0.24c0,0.08,0,0.159,0,0.239c-0.754,0.286-1.27,0.811-2.16,0.96
			c-0.847,0.033-1.073,0.688-1.92,0.721C166.974,444.895,168.629,444.582,168.48,443.699z M166.32,442.02
			c-0.994,1.395-3.097,2.729-2.16,5.28c-4.277,2.123-8.033,4.767-12.24,6.96C154.191,447.65,160.055,444.634,166.32,442.02z
			 M129.739,534.157c0.001-0.001,0.002-0.003,0.003-0.005c0.065-0.098,0.128-0.2,0.184-0.312c0.06-0.117,0.113-0.243,0.154-0.381
			c0.063-0.207,0.115-0.432,0.162-0.667c0.024-0.119,0.042-0.248,0.063-0.374c0.019-0.113,0.038-0.225,0.054-0.343
			c0.024-0.181,0.044-0.368,0.063-0.558c0.006-0.06,0.012-0.119,0.018-0.18c0.021-0.229,0.038-0.463,0.052-0.701
			c0-0.005,0-0.01,0.001-0.016c0,0.001,0,0.001,0,0.002c0-0.006,0.001-0.012,0.001-0.018c0,0.006,0,0.011,0,0.016
			c0.939-6.891-0.425-15.533-0.412-22.603c-0.174-1.214,1.173-0.907,1.2-1.92c-0.037-12.209-0.651-22.522,3.12-30
			c3.391-6.723,9.221-11.459,15.36-15.84c13.422-9.578,30.597-17.732,47.76-22.561c0.878,1.099-3.768,1.933-2.64,4.08
			c0.182,0.14,0.383,0.226,0.592,0.289c0.041,0.065,0.082,0.131,0.128,0.191c0.075-0.035,0.157-0.072,0.237-0.109h0
			c0.277-0.127,0.581-0.264,0.898-0.411c-0.315,0.147-0.622,0.284-0.897,0.411c2.21,0.245,5.268-2.859,4.323-4.931
			c9.255-2.277,18.208-3.544,25.2-7.68c4.526-2.678,6.296-7.974,10.32-11.04c0.349-0.062,0.414,0.162,0.646,0.219
			c0,0.001,0,0.003-0.001,0.004c-0.011,0.059-0.022,0.117-0.035,0.175c-0.337,1.45-1.259,2.733-2.172,4.082
			c0.912-1.349,1.835-2.633,2.172-4.082c-2.241,3.03-3.572,6.97-6.13,9.683c-1.417,7.691-1.379,18.073-0.96,27.6
			c0.175,3.973,0.985,8.009,0.72,11.28c-0.125,1.543-0.919,2.301-0.72,4.8c0.531,6.675,4.907,13.254,7.92,18.96
			c9.057,17.15,20.281,35.978,31.44,49.2c-7.239,9.641-15.939,17.82-23.52,27.12c-0.917-0.116-1.532,0.068-1.92,0.479
			c-1.428,2.444,0.029,6.536-2.16,8.4c0,2.08,0,4.16,0,6.24c-2.37-0.185-4.075,0.869-5.76,0.479c-1.322-0.305-1.865-1.765-2.88-2.64
			c-1.826-1.575-4.16-2.645-5.76-3.84c-7.138-10.862-15.779-20.222-25.44-28.561c-0.95-27.333,3.834-49.981,5.52-75.36
			c-2.811,2.137-3.036,6.417-3.6,10.08c-1.732,11.256-2.974,24.756-4.08,36.721c-1.336-5.887-2.154-11.679-3.84-17.521
			c-1.604-5.559-4.49-10.838-5.28-16.08c-0.537-3.558,0.297-7.932,0.72-11.52c0.812-6.901,2.047-13.765,3.12-20.88
			c0.502-3.332,1.851-6.688,0.96-10.08c-2.273,1.36-2.392,5.454-2.88,8.399c-1.567,9.463-3.069,19.85-4.08,29.761
			c-0.856-1.224-1.479-2.682-2.4-3.841c-0.882,7.464,2.272,12.931,4.536,18.797c0.002,0.006,0.004,0.012,0.006,0.018
			c0.254,0.688,0.502,1.376,0.737,2.066c0.096,0.282,0.184,0.564,0.277,0.847c0.252,0.761,0.496,1.521,0.731,2.286
			c-0.234-0.761-0.478-1.521-0.729-2.278c1.803,5.894,2.891,11.673,4.041,18.585c-0.154,0.1-0.312,0.078-0.469-0.003
			c-0.371-0.463-0.746-0.921-1.103-1.397c0,0,0,0,0,0.001c-0.65-0.867-1.276-1.758-1.889-2.662c0.613,0.903,1.239,1.795,1.889,2.661
			c-0.202-0.349-0.399-0.703-0.588-0.999c-4.706-7.36-9.557-16.379-14.64-23.521c0.025,0.123,0.058,0.241,0.084,0.364
			c-0.029-0.041-0.055-0.084-0.084-0.124c0.115,0.535,0.246,1.065,0.389,1.593c0.004,0.016,0.01,0.031,0.014,0.047
			c0.141,0.515,0.293,1.026,0.457,1.533c2.507,8.529,7.526,15.518,12.162,22.474c0.142,0.254,0.285,0.504,0.429,0.751
			c0.039,0.066,0.079,0.131,0.118,0.197c0.12,0.201,0.241,0.401,0.364,0.595c0.06,0.094,0.121,0.181,0.182,0.271
			c0.109,0.165,0.218,0.332,0.331,0.488c0.167,0.232,0.338,0.453,0.514,0.66c0.001,0.002,0.003,0.004,0.005,0.006
			c0.295,0.461,0.589,0.922,0.876,1.385c0.511,0.449,1.528,0.392,1.92,0.96c-0.171,0.078-0.538,0.126-0.94,0.244
			c-0.007-0.001-0.013-0.003-0.02-0.004c-0.006,0.005-0.01,0.011-0.016,0.015c0,0,0,0,0.001,0c-0.016,0.013-0.028,0.027-0.044,0.041
			c0.016-0.014,0.027-0.028,0.043-0.041c-0.542,0.166-1.142,0.469-1.424,1.186c0.122,5.241,0.145,9.215-0.24,14.159
			c-0.626,0.358-1.129-0.798-1.44-1.199c-1.307-1.691-2.599-4.65-4.56-6c-1.063-0.732-2.61-0.773-3.84-1.44
			c-3.334-1.808-7.333-6.089-11.04-3.6c1.106,2.211,3.795,1.902,5.849,2.814c-0.112-0.057-0.212-0.121-0.329-0.175
			c0.117,0.054,0.218,0.119,0.332,0.176c-0.001,0-0.002-0.001-0.003-0.001c0.166,0.083,0.324,0.171,0.485,0.257
			c0.104,0.058,0.216,0.109,0.317,0.169c1.356,0.835,2.516,2.063,3.91,2.76c0.874,0.436,2.036,0.398,2.88,0.96
			c2.294,1.525,4.102,5.684,5.52,8.399c-6.779-3.227-12.822-7.262-19.2-11.04c-1.191-0.705-2.424-1.288-3.65-1.883
			c0.035,0.021,0.068,0.04,0.103,0.06c-0.035-0.021-0.07-0.041-0.105-0.061c0.001,0,0.002,0,0.003,0.001
			c-0.787-0.455-1.576-0.922-2.364-1.392c-0.264-0.158-0.527-0.313-0.79-0.473c-0.941-0.572-1.877-1.157-2.796-1.772
			c-0.396-0.265-0.786-0.524-1.177-0.783c-3.335-2.441-6.772-4.934-11.303-5.457c-0.499-0.781-1.578-0.982-2.16-1.68
			c-2.953-0.582-4.738,0.238-5.689,1.76c-0.11,0.128-0.225,0.251-0.312,0.399c-0.015,0.083-0.019,0.162-0.032,0.244
			c-1.384,3.211,0.076,8.611,2.192,11.517c2.324,3.19,6.231,4.663,7.92,7.2c4.521,1.959,8.818,4.142,12.96,6.479
			c-2.503-0.354-5.033-1.115-7.614-1.988c-0.373-0.148-0.748-0.294-1.128-0.433c-0.44-0.163-0.885-0.318-1.338-0.459
			c-0.19-0.059-0.388-0.105-0.58-0.163c-2.995-1.048-6.069-2.097-9.261-2.717c-5.438-1.058-10.959-1.442-15.84-3.601
			C129.359,535.318,129.558,534.745,129.739,534.157z M189.812,496.563c-0.602-1.629-1.24-3.258-1.843-4.928
			C188.571,493.305,189.21,494.935,189.812,496.563z M189.282,510.745c-2.179-3.515-4.222-7.166-6.339-10.742
			C185.063,503.576,187.101,507.232,189.282,510.745z M181.219,539.959c-0.134-0.105-0.271-0.207-0.42-0.298
			c0.001,0.001,0.002,0.001,0.003,0.002C180.95,539.753,181.086,539.854,181.219,539.959z M180.737,539.626
			c-0.034-0.02-0.074-0.032-0.109-0.052c-0.468-0.251-0.947-0.491-1.431-0.729C179.716,539.1,180.235,539.354,180.737,539.626z
			 M177.934,538.237c-1.745-0.817-3.518-1.606-5.163-2.523C174.418,536.629,176.19,537.419,177.934,538.237z M170.674,534.4
			c-0.516-0.369-1.014-0.756-1.474-1.181C169.66,533.645,170.158,534.031,170.674,534.4z M153.84,524.82
			c-0.746,0.614-0.632,2.088-1.68,2.399c-0.4,0-0.8,0-1.2,0c-0.557,0.077-0.921-0.038-1.2-0.24c-2.243-1.196-5.371-1.509-5.04-5.279
			c0.526-0.273,0.98-0.62,1.92-0.48c0.08,0,0.16,0,0.24,0C149.241,522.379,151.798,523.342,153.84,524.82z M127.2,538.979
			c2.091,1.029,4.747,1.493,6.96,2.4c-0.28,3.311-3.827,2.734-6.24,1.68c-1.547,1.126-0.179,3.059,1.44,3.12
			c0.557-0.076,0.922,0.039,1.2,0.24c3.735,1.094,5.547-1.812,6.24-4.561c1.79,0.051,3.046,0.635,4.8,0.721c0.08,0,0.16,0,0.24,0
			c1.134,0.466,2.548,0.652,4.08,0.72c0.08,0,0.16,0,0.24,0c2.307,1.533,5.847,1.833,8.64,2.88c0.08,0,0.16,0,0.24,0
			c1.877,1.163,4.212,1.868,6.72,2.4c0.879-0.079,1.333,0.267,1.92,0.479c2.577,1.104,5.541,1.82,8.4,2.641c0.08,0,0.16,0,0.24,0
			c1.48,0.76,3.341,1.138,5.28,1.439c1.415,0.025,2.447,0.433,3.6,0.72c0.962,0.479,2.18,0.7,3.6,0.721c0.08,0,0.16,0,0.24,0
			c2.901,0.475,6.343,1.439,8.885,2.519c0.505,0.248,1.011,0.495,1.525,0.733c0.216,0.116,0.442,0.232,0.629,0.348
			c3.341,2.068,4.189,8.499,9.36,7.2c0.054-0.187,0.006-0.474,0.24-0.479c-0.215-3.383-3.864-4.866-5.76-6.961
			c-0.575-0.635-0.816-1.575-1.44-2.159c-3.151-2.952-8.277-4.022-12.72-5.761c-0.557,0.077-0.921-0.038-1.2-0.239
			c-3.576-0.678-6.319-1.386-9.36-2.881c-2.514-1.235-4.786-2.722-7.44-4.56c-2.332-1.614-4.55-3.643-6.96-4.8
			c-5.134-2.467-10.63-4.245-12.72-8.88c3.322,1.916,7.301,0.522,7.92-2.881c1.445,1.748,3.35,1.914,5.28,2.881
			c0.917,0.459,1.513,1.401,2.4,1.92c2.06,1.202,4.209,2.164,6.24,3.6c3.119,2.204,5.368,4.361,9.36,5.04c0.08,0,0.16,0,0.24,0
			c5.616,4.704,14.465,6.175,18.72,12.24c5.214,2.623,8.917,7.187,12.72,11.28c3.855,4.149,7.759,8.543,10.56,13.68
			c2.207,2.361,5.256,5.134,6.48,7.68c-0.349,0.371-1.229,0.212-1.92,0.24c-3.209,0.391-6.463,0.736-9.36,1.44
			c-0.309,0.33-1.042,0.237-1.68,0.239c-0.943,0.018-1.804,0.116-2.4,0.48c-1.183,0.337-2.384,0.656-3.84,0.72
			c-4.47,0.89-9.248,1.473-13.2,2.88c-0.08,0-0.16,0-0.24,0c-2.723,0.478-4.786,1.614-7.44,2.16c-4.16-5.439-14.094-5.105-21.6-7.2
			c-0.557,0.077-0.921-0.038-1.2-0.239c-1.444-0.876-3.718-0.923-5.76-1.2c-0.08,0-0.16,0-0.24,0
			c-6.105-1.335-11.909-2.972-14.4-7.92c2.169,2.535,7.833,2.129,8.16-1.681c1.439,0.161,2.572,0.628,4.08,0.721
			c0.557-0.077,0.921,0.038,1.2,0.239c4.253,1.348,9.683,1.518,13.68,3.12c0.08,0,0.16,0,0.24,0
			c2.042,0.599,15.397,4.034,14.88-0.72c-0.315-2.9-5.845-1.904-9.664-2.364c0.793,0.156,1.618,0.281,2.464,0.385
			c-0.847-0.106-1.679-0.229-2.475-0.386c-0.021-0.003-0.038-0.008-0.059-0.01c0.016,0.003,0.033,0.005,0.048,0.008
			c-0.238-0.048-0.471-0.102-0.705-0.153c-0.468-0.108-0.934-0.22-1.371-0.359c-0.751-0.049-1.762,0.162-2.16-0.24
			c-0.598-0.135-1.191-0.232-1.78-0.315c0.024,0.003,0.048,0.005,0.073,0.009c-3.01-0.559-5.879-1.258-9.093-1.613
			c-0.684-0.276-0.787-1.133-1.44-1.439c-1.59-0.091-2.777-0.583-4.56-0.48c-0.16,0-0.32,0-0.48,0
			c-3.703-0.538-7.451-1.029-10.8-1.92c-0.08,0-0.16,0-0.24,0c-4.277-0.828-8.086-3.358-12-5.28c-2.13-1.046-4.532-1.324-4.56-3.6
			c3.38,2.332,10.186,1.562,9.12-4.08c1.754,0.086,3.01,0.669,4.8,0.72c1.806,0.114,3.493,0.348,5.04,0.72
			c8.461,1.459,16.319,3.521,24.96,4.801c0.592-0.032,1.156-0.037,1.44,0.239c2.514,0.847,5.274,1.446,7.92,2.16
			c0.941,0.02,1.497,0.423,2.16,0.721c3.682,0.965,6.585,4.809,10.98,4.08c0.021-0.002,0.039,0,0.06-0.001
			c0.141,0.004,0.25-0.022,0.35-0.058c0.062-0.021,0.121-0.045,0.175-0.073c0.145-0.036,0.287-0.062,0.435-0.108
			c-0.517-3.582-4.98-3.869-7.68-5.521c-1.974-1.207-4.343-2.936-7.2-3.12c-0.08,0-0.16,0-0.24,0c-3.045-0.955-6.31-1.69-9.84-2.16
			c-0.08,0-0.16,0-0.24,0c-3.869-0.932-7.922-1.678-12-2.399c-0.08,0-0.16,0-0.24,0c-4.587-1.333-9.803-2.037-15.12-2.641
			c-0.638-0.002-1.371,0.091-1.68-0.239c-0.596-0.364-1.457-0.464-2.4-0.48c-0.08,0-0.16,0-0.24,0
			c-1.035-0.692-3.021-0.344-4.08-0.72c-0.641-0.228-1.007-0.964-1.68-1.2c-0.83-0.291-1.709-0.15-2.485-0.346
			c-0.181-0.073-0.361-0.146-0.543-0.217c-0.952-0.489-1.957-1.494-2.773-2.725c-0.146-0.303-0.31-0.589-0.507-0.841
			C125.24,543.558,124.782,540.405,127.2,538.979z M145.68,574.02c0.401-0.903-1.117-1.093-0.48-1.439c0.24,0,0.48,0,0.72,0
			c1.415,0.025,2.447,0.433,3.6,0.72c0.074,0.326,0.601,0.199,0.96,0.24c0.156,1.036-0.415,1.345-0.72,1.92c-0.48,0-0.96,0-1.44,0
			c-0.755,0.115-0.938-0.342-1.44-0.48c-0.236-1.158-1.995-0.388-2.16-0.479C144.555,573.417,145.123,573.949,145.68,574.02z
			 M139.856,567.389c6.035,2.183,12.973,3.461,20.664,3.987c0,0,0,0,0.001,0.001C152.829,570.851,145.891,569.571,139.856,567.389z
			 M160.6,571.419c0.346,0.233,0.659,0.523,0.995,0.776c-0.335-0.25-0.646-0.54-0.991-0.772
			C160.603,571.422,160.602,571.42,160.6,571.419z M136.817,566.177c-1.032-0.451-2.04-0.927-3.009-1.439
			C134.778,565.25,135.785,565.726,136.817,566.177z M126.72,556.74c-0.235-2.076,1.302-2.379,2.16-3.36
			c0.565-0.006,0.707,0.413,1.44,0.24c0.08,0,0.16,0,0.24,0c0.805,0.476,1.771,0.789,2.88,0.96c0.08,0,0.16,0,0.24,0
			c0.036,0.443,0.813,0.147,0.96,0.479c-0.013,1.107,0.106,2.347-0.24,3.12C131.154,559.317,129.096,556.507,126.72,556.74z
			 M149.76,693.061c-6.133,3.088-15.249,5.896-23.28,6.479c-4.458,0.324-8.311-0.226-11.28-1.439c-0.822-4.6-2.834-8.888-4.08-13.44
			c-3.167-11.57-6.01-22.671-7.92-36c-0.874-6.097-2.434-13.771,0.24-19.44c3.648-7.732,10.663-13.346,17.04-19.2
			c3.13-2.873,6.207-5.881,9.36-8.64c3.198-2.797,7.044-5.319,8.88-9.12c-4.166,0.643-6.887,4.332-9.84,6.96
			c-6.034,5.371-11.236,11.477-17.04,17.28c-2.849,2.85-5.329,5.941-8.4,8.16c0.094-4.239,8.666-8.774,7.44-12.72
			c-0.513-1.651-1.466-1.827-2.524-1.319c-0.577,0.136-1.061,0.525-1.491,1.055c-1.36,1.218-2.642,2.944-3.185,3.624
			c1.806-8.594,10.018-10.782,13.92-17.28c-3.761-0.063-6.416,4.287-9.6,4.801c-0.022-3.053,1.984-5.122,2.4-7.2
			c0.667-3.335,0.002-6.707,0.24-9.601c0.227-2.76,0.607-6.038,1.44-8.399c0.65-1.846,2.287-3.559,3.36-5.28
			c3.288-5.273,5.574-11.119,7.68-18c0.627,5.682,2.39,9.83,6.48,12c3.666,1.944,8.27,2.628,11.52,5.28
			c-0.707,6.56,3.326,9.769,8.4,12.479c0.314,0.172,0.631,0.341,0.95,0.509c-0.004-0.003-0.009-0.005-0.013-0.007
			c3.165,1.695,6.843,3.621,10.343,4.059c1.188,0.148,2.483-0.179,3.6,0c1.996,0.318,3.703,1.563,5.76,1.68
			c3.469,0.197,7.402,0.965,10.32,2.16c-18.171,0.231-31.755,7.034-41.52,16.8c-0.903,0.903-3.257,2.656-1.44,4.32
			c2.154,0.545,3.181-1.804,4.32-2.88c8.796-8.316,20.23-14.75,37.68-14.88c-1.604,2-3.058,1.092-5.28,1.439
			c-3.767,0.59-8.429,3.45-12,5.76c-9.191,5.944-14.942,14.346-19.68,24.721c-0.759,1.662-2.323,3.649-1.875,5.634
			c-0.014,0.042-0.03,0.084-0.044,0.126c0.026,0.006,0.049,0.005,0.075,0.01c0.043,0.156,0.095,0.313,0.165,0.47
			c2.582-0.216,3.28-3.079,4.08-4.8c5.803-12.473,13.695-22.872,26.88-28.319c0.111,0.956-1.028,1.64-1.68,2.399
			c-3.517,4.104-7.324,8.708-10.56,13.44c-1.274,1.863-3.006,4.084-2.64,6.479c2.765,0.43,4.171-2.706,5.28-4.319
			c3.934-5.722,8.164-11.981,13.68-16.561c7.158-5.941,18.421-8.563,29.76-10.56c22.033-3.88,47.429-6.18,69.12-11.28
			c2.529,1.219,3.873,4.446,5.52,7.2c1.035,1.729,2.446,3.741,3.408,5.725c0.001,0.002,0.001,0.004,0.002,0.005
			c0.265,0.692,0.49,1.42,0.678,2.217c0.011,0.048,0.023,0.095,0.034,0.143c0.085,0.375,0.162,0.766,0.23,1.176
			c0.01,0.064,0.02,0.13,0.03,0.195c0.067,0.426,0.128,0.866,0.177,1.34c0.064,0.617,0.16,1.239,0.265,1.862
			c0.057,0.348,0.118,0.697,0.184,1.048c0,0.002,0.001,0.004,0.001,0.007c0-0.001,0-0.002,0-0.003
			c0.002,0.009,0.004,0.019,0.005,0.027c-0.001-0.008-0.003-0.017-0.005-0.024c0.012,0.067,0.017,0.136,0.029,0.203
			c0.968,5.152,2.525,10.442,3.12,15.84c1.081,9.807,2.445,19.979,1.92,29.76c-8.25,2.923-15.926,7.492-24.24,9.841
			c-7.656,2.162-15.577,3.176-24,5.039c-12.148,2.688-24.044,6.401-35.76,9.841c-3.164,0.929-6.297,1.992-9.36,3.12
			c-2.995,1.102-5.92,2.785-8.88,3.6c-0.727,0.2-1.705-0.004-2.4,0.24c-2.214,0.776-4.486,2.228-6.96,3.12
			C169.393,685.412,158.39,688.715,149.76,693.061z M256.32,580.02c0.285-0.754,0.81-1.27,0.96-2.159
			c0.582-0.379,1.062-0.859,1.44-1.44c2.684-1.882,7.407-1.34,8.64,1.44C263.996,578.896,260.312,579.611,256.32,580.02z
			 M278.861,574.953c0.001,0.003,0.003,0.007,0.005,0.01c0,0,0,0,0,0c0.001,0.003,0.002,0.005,0.004,0.007
			c0.002,0.005,0.004,0.009,0.006,0.013c-1.178,1.061-3.33,1.146-5.036,1.678c0.89-1.67,0.732-4.389,1.44-6.24
			c1.887,0.823,2.214,3.205,3.585,4.543C278.864,574.96,278.862,574.956,278.861,574.953z M321.12,523.859
			c0.376-1.064,0.47-2.41,0.48-3.84c0-0.08,0-0.16,0-0.24c0.378-1.222,0.471-2.729,0.479-4.319c0-0.08,0-0.16,0-0.24
			c0.254-0.794,0.58-2.769,0.24-3.36c0-0.08,0-0.159,0-0.239c0.178-0.818-0.885-0.396-0.96-0.96c-0.05-0.69,0.061-1.22,0.48-1.44
			c0.38-0.02,0.915,0.115,0.96-0.24c0.08,0,0.16,0,0.239,0c0.809,0.152,2.229-0.309,2.641,0.24c-0.077,0.563-0.379,0.901-0.24,1.68
			c0,0.08,0,0.16,0,0.24c-0.679,3.161-0.873,6.807-1.2,10.32c-0.028,0.691,0.131,1.571-0.24,1.92c-0.079,0-0.159,0-0.239,0
			c0.183,1.303-0.38,1.859-0.24,3.12c0,0.08,0,0.16,0,0.24c-0.387,0.813-0.492,1.908-0.48,3.119c0,0.08,0,0.16,0,0.24
			c-0.388,0.972-0.49,2.229-0.479,3.601c0,0.08,0,0.159,0,0.239c-0.556,0.805-0.099,2.621-1.2,2.881
			c-0.76-0.36-1.786-0.455-1.439-1.921c0-0.08,0-0.159,0-0.239c0.552-0.568,0.086-2.154,0.239-3.12c0-0.08,0-0.16,0-0.24
			c0.415-1.666,0.544-3.616,0.721-5.521C320.909,525.089,320.749,524.209,321.12,523.859z M372.001,500.819c0-0.08,0-0.159,0-0.239
			c1.793-1.489,4.566-4.006,3.359-6.96c-1.447-0.479-1.349,1.838-1.92,1.439c0.001-0.959,0.479-1.44,0.48-2.399
			c0-0.08,0-0.16,0-0.24c0.794-0.806,0.354-2.846,1.439-3.36c1.035,0.485,1.369,1.671,1.92,2.641
			c0.202,0.278,0.316,0.643,0.24,1.199c0.393,2.296,0.384,5.239-0.479,6.96c0,0.08,0,0.16,0,0.24
			c-0.905,1.576-1.416,3.545-1.921,5.521c0,0.08,0,0.159,0,0.239c-0.895,1.906-1.32,4.279-1.92,6.48
			c0.011,0.492-0.079,0.885-0.197,1.249c-0.028,0.088-0.056,0.176-0.084,0.264c-0.143,0.379-0.312,0.732-0.438,1.127
			c-0.476,0.805-0.789,1.771-0.96,2.88c-0.059,0.741-0.243,1.357-0.479,1.92c-0.476,0.032,0.133,0.483,0.061,1
			c0.059-0.095,0.124-0.182,0.179-0.279c-0.055,0.099-0.121,0.186-0.179,0.28v-0.001c-0.036,0.059-0.074,0.116-0.111,0.175
			c-0.05,0.077-0.096,0.157-0.148,0.232c-2.98,3.153-6.115,6.153-9.313,9.089c-0.004,0.003-0.007,0.006-0.01,0.008
			c-0.129,0.101-0.262,0.198-0.397,0.293c-0.001,0-0.001,0.001-0.002,0.001c-2.09-0.178-4.955,2.079-2.56,3.362
			c0.08,0,0.16,0,0.24,0c0.316,0.02,0.622,0.027,0.697-0.194c0-0.001,0-0.001,0-0.001c0.407-0.109,0.812-0.223,1.201-0.356
			c0.521-0.153,1.02-0.328,1.489-0.533c0.004-0.001,0.007-0.003,0.011-0.004c0.45-0.197,0.871-0.426,1.254-0.69
			c6.645-3.638,10.671-10.882,16.927-15.077c0.003-0.001,0.005-0.001,0.008-0.001c0.165-0.028,0.327-0.038,0.485-0.031
			c0.014,0.001,0.026,0.002,0.04,0.003c0.158,0.011,0.313,0.038,0.461,0.089c0.002,0.001,0.003,0.001,0.005,0.002
			c0.137,0.096,0.271,0.192,0.462,0.235c0.565,0.005,0.706-0.413,1.439-0.24c1.358-0.161,2.641-0.399,3.601-0.96
			c0.08,0,0.16,0,0.239,0c2.226-0.495,4.497-0.943,6.48-1.68c1.143-0.378,2.312-0.729,3.84-0.721
			c1.284,0.004,2.656,0.097,3.36-0.479c0.08,0,0.16,0,0.24,0c0.918-0.281,1.828-0.571,2.399-1.2c0.457-0.103,0.604-0.517,1.2-0.479
			c2.228-1.053,4.883-1.678,7.2-2.641c0.08,0,0.16,0,0.24,0c2.408-0.791,5.595-0.805,8.399-1.2c0.08,0,0.16,0,0.24,0
			c1.928,0.459,4.641-0.036,7.032-0.239c0.931,0,1.862,0,2.793,0c0.354,0.051,0.683,0.125,0.975,0.239
			c0.082,0.032-0.065,0.647,0,0.721c0,0.08,0,0.16,0,0.24c-0.349,1.951,0.595,4.684,3.12,4.56c0.24,0,0.48,0,0.72,0
			c2.933,1.038,4.483-2.731,1.92-2.4c-0.08,0-0.159,0-0.239,0c-0.952-0.088-2.301,0.221-2.88-0.239
			c-0.249-0.632-0.288-1.474-0.24-2.4c2.089-0.423,4.919-0.728,7.2-0.24c0.595,0.685,1.104,1.456,1.68,2.16
			c0.191,0.448,0.401,0.879,0.479,1.44c-1.165,0.675-2.355,1.323-3.84,1.68c-0.08,0-0.16,0-0.239,0
			c-1.685,0.155-3.275,0.405-4.561,0.96c-0.349,0.371-1.229,0.211-1.92,0.24c-1.836,0.164-3.271,0.729-4.8,1.2
			c-0.08,0-0.16,0-0.24,0c-1.211,0.149-2.425,0.295-3.36,0.72c-0.08,0-0.159,0-0.239,0c-1.154,0.047-2.285,0.114-3.12,0.479
			c-0.08,0-0.16,0-0.24,0c-1.32,0.04-2.607,0.112-3.6,0.48c-0.08,0-0.16,0-0.24,0c-9.058-0.098-12.531,5.389-20.16,6.72
			c-0.08,0-0.16,0-0.24,0c-1.188,0.252-2.491,0.389-3.359,0.96c-0.08,0-0.16,0-0.24,0c-1.634,0.206-3.136,0.545-4.32,1.2
			c-0.08,0-0.16,0-0.24,0c-3.337,0.113-7.51,2.398-7.223,5.352c-0.002,0.152,0.007,0.301,0.028,0.45
			c0,0.002,0.001,0.004,0.001,0.007c0.021,0.145,0.053,0.28,0.095,0.41c0.002,0.006,0.004,0.01,0.006,0.016
			c0.044,0.129,0.097,0.243,0.161,0.346c0,0.001,0.001,0.002,0.002,0.003c0.066,0.105,0.143,0.19,0.23,0.255
			c0.073,0.121,0.131,0.24,0.22,0.362c2.018-0.017,2.48-2.137,4.165-3.027c-0.431,0.207-0.837,0.438-1.213,0.699
			c0.377-0.263,0.786-0.495,1.219-0.703c-0.002,0.001-0.004,0.003-0.006,0.004c0.167-0.081,0.341-0.155,0.516-0.229
			c0.368-0.155,0.764-0.282,1.157-0.411c-0.386,0.127-0.771,0.252-1.133,0.403c2.649-1.046,6.034-0.814,8.734-2.257
			c0.08,0,0.16,0,0.24,0c1.188-0.251,2.491-0.389,3.36-0.96c0.278-0.201,0.644-0.316,1.2-0.239c4.939-0.9,10.104-1.575,15.119-2.4
			c0.08,0,0.16,0,0.24,0c2.291-0.909,6.304-0.097,8.88-0.72c0.08,0,0.16,0,0.24,0c0.809-0.152,2.229,0.309,2.641-0.24
			c0.454-0.426,1.585-0.175,2.399-0.24c1.496-0.024,2.743-0.297,3.84-0.72c0.08,0,0.16,0,0.24,0c1.529-0.15,2.982-0.378,4.32-0.72
			c0.08,0,0.159,0,0.239,0c1.761,0,3.521,0,5.28,0c-0.214,0.676-0.525,2.501,0,3.12c0.187,0.213,0.258,0.541,0.24,0.959
			c0.436,1.728,2.088,3.51,4.32,2.641c0.522-0.438,1.688-0.23,1.92-0.96c0.007-0.233,0.294-0.187,0.479-0.24
			c2.004,0.133,3.844-1.979,2.16-3.36c-2.08,0.48-3.279,1.841-5.28,2.4c-0.08,0-0.159,0-0.239,0c-0.869-0.651-1.382-1.658-1.44-3.12
			c0-0.08,0-0.16,0-0.24c0.653-1.106,1.93-1.59,3.36-1.92c0.515-0.444,1.758-0.161,2.64-0.239c2.733-0.334,4.479,0.321,5.28,1.92
			c0.527,0.891,0.201,2.672-0.24,3.359c-1.471,2.77-5.046,3.435-7.68,5.04c-0.08,0-0.16,0-0.24,0
			c-3.495,1.705-7.484,2.916-11.76,3.84c-0.08,0-0.16,0-0.24,0c-1.146-0.104-1.74,0.341-2.4,0.721c-0.08,0-0.16,0-0.239,0
			c-0.729,0.15-2.067-0.308-2.4,0.239c-0.08,0-0.16,0-0.24,0c-0.808,0.152-2.229-0.309-2.64,0.24c-0.08,0-0.16,0-0.24,0
			c-2.279,0.041-4.323,0.317-6.24,0.721c-0.08,0-0.16,0-0.239,0c-3.084,0.036-5.984,0.256-8.641,0.72c-0.08,0-0.16,0-0.24,0
			c-1.762,0.157-4.157-0.317-5.52,0.24c-0.08,0-0.16,0-0.24,0c-3.188-0.068-5.743,0.496-8.16,1.199c-0.08,0-0.16,0-0.239,0
			c-2.174,0.802-8.939,1.533-9.12,4.08c-0.113,1.594,2.183,2.813,4.8,1.92c0.08,0,0.16,0,0.24,0c1.522-0.157,3.676,0.316,4.8-0.239
			c0.397-0.402,1.409-0.191,2.16-0.24c1.283-0.156,3.194,0.314,4.08-0.24c0.859-0.132,2.349-0.537,3.119,0
			c0.568,0.552,2.154,0.086,3.12,0.24c0.08,0,0.16,0,0.24,0c0.912,0.368,2.161,0.398,3.36,0.479c0.08,0,0.16,0,0.239,0
			c0.678,0.362,1.657,0.424,2.641,0.48c0.08,0,0.16,0,0.24,0c7.075,1.564,12.73,4.549,21.12,4.8c0.035,1.319-0.293,4.446,1.199,5.04
			c0.16,0,0.32,0,0.48,0c0.988,0.188,1.231-0.368,2.16-0.24c0.08,0,0.16,0,0.239,0c2.219,0.364,2.311-3.14,0.48-2.399
			c-0.08,0-0.16,0-0.24,0c-0.904-0.185-1.146,0.293-1.92,0.239c-0.069-1.162,0.529-1.923,0-2.88c0.887-0.152,2.391,0.312,2.88-0.239
			c0.08,0,0.16,0,0.24,0c1.755-0.635,3.92-0.859,6.439-0.728c-0.951-0.36-2.264-0.389-3.487-0.222
			c1.227-0.169,2.536-0.139,3.488,0.222h-0.001c0.093,0.034,0.181,0.072,0.266,0.113c0.001,0,0.001,0,0.001,0
			c-0.427,5.133-4.9,6.219-8.626,8.054c-0.08,0-0.16,0-0.24,0c-2.253,0.387-4.78,0.499-6.96,0.96c-0.08,0-0.16,0-0.24,0
			c-4.045,0.205-7.187-0.493-10.8-0.72c-0.08,0-0.16,0-0.24,0c-0.907-0.373-2.092-0.468-3.359-0.48c-0.08,0-0.16,0-0.24,0
			c-2.42-0.46-4.817-0.942-7.68-0.96c-0.08,0-0.16,0-0.24,0c-1.124-0.556-3.277-0.082-4.8-0.239c-0.08,0-0.16,0-0.24,0
			c-0.568-0.553-2.154-0.086-3.12-0.24c-0.08,0-0.16,0-0.24,0c-1.364-0.399-4.396-0.399-5.76,0c-0.08,0-0.16,0-0.24,0
			c-2.068,0.012-3.645,0.516-4.319,1.92c1.932,2.388,7.033,1.606,10.56,2.399c0.08,0,0.16,0,0.24,0
			c2.112,0.448,4.224,0.896,6.479,1.2c0.08,0,0.16,0,0.24,0c1.411,0.51,2.831,1.009,4.561,1.2c2.292,0.268,4.013,1.106,6,1.68
			c1.064,0.456,1.416,1.624,2.88,1.681c1.558,0.602,3.168,1.151,4.8,1.68c0.074,0.326,0.601,0.199,0.96,0.24
			c-4.234,0.671-1.89,9.083,1.68,5.52c0.194-1.394-1.268-1.132-1.68-1.92c0.479-1.096,1.206-2.648,0.72-3.359
			c0.68,0.04,1,0.439,1.681,0.479c0.418-0.018,0.746,0.054,0.96,0.24c1.062,0.617,2.537,0.822,3.119,1.92c0,0.24,0,0.48,0,0.72
			c0,0.08,0,0.16,0,0.24c-0.802,1.037-1.778,1.901-3.119,2.4c-0.373,0.347-1.12,0.319-1.44,0.72
			c-1.367,0.152-2.508,0.532-4.08,0.479c-0.8,0-1.6,0-2.399,0c0-0.08,0-0.159,0-0.239c-3.394-0.527-5.989-1.852-9.12-2.641
			c-0.08,0-0.16,0-0.24,0c-0.682-0.679-1.842-0.878-2.88-1.199c-0.08,0-0.16,0-0.24,0c-0.58-0.541-1.38-0.861-2.4-0.961
			c-0.861,0.142-1.161-0.278-1.68-0.479c-2.11-0.508-4.742,0.535-6.24,1.44c-0.454,1.494,0.742,1.338,0.96,2.159
			c-1.834,2.354-4.098,4.278-5.929,6.636c0.366-0.278,0.722-0.567,1.065-0.868c-0.344,0.301-0.7,0.59-1.066,0.869
			c0.001-0.001,0.001-0.001,0.001-0.001c-0.062,0.048-0.127,0.092-0.19,0.138c-0.094,0.069-0.189,0.137-0.284,0.205
			c-0.788,0.482-1.562,0.979-2.317,1.495c0,0-0.001,0-0.001,0.001c-0.33,0.187-0.647,0.387-0.946,0.606
			c-0.001,0.001-0.002,0.001-0.003,0.002c-0.296,0.218-0.586,0.441-0.862,0.677c-0.001,0.001-0.003,0.002-0.004,0.003
			c-0.013,0.011-0.025,0.022-0.038,0.033c0.012-0.01,0.022-0.021,0.034-0.03c-4.788,3.525-9.06,7.675-13.459,12.075
			c-2.048,2.048-4.684,4.159-7.2,6.239c-3.2,2.646-6.35,5.866-10.56,6.96c-0.349,0.371-1.229,0.212-1.92,0.24
			c-1.624,0.136-2.827,0.693-3.84,1.44c-0.08,0-0.16,0-0.24,0c-1.936,0.784-4.173,1.267-6,2.16c-0.643,0.237-1.266,0.494-2.16,0.479
			c-1.168,0.192-2.3,0.42-3.12,0.96c-0.324,0.075-0.458,0.342-0.96,0.24c-1.975,0.505-3.944,1.016-5.52,1.92
			c-0.534,0.187-0.937,0.504-1.681,0.479c-0.68,0.041-1,0.44-1.68,0.48c-0.158-2.241,0.318-5.118-0.24-6.96c0-0.08,0-0.16,0-0.24
			c0.028-2.028-0.014-3.986-0.479-5.52c0-0.08,0-0.16,0-0.24c0.17-1.467,0.388-2.907-0.24-4.08c-0.289-0.831-0.438-1.801-0.48-2.88
			c-0.147-1.372-0.524-2.515-0.96-3.601c0-0.079,0-0.159,0-0.239c-0.084-1.115-0.308-2.092-0.72-2.88
			c-0.201-0.279-0.316-0.644-0.24-1.2c-0.826-4.214-2.399-7.681-3.84-11.28c0.744,0.024,1.331-0.108,1.681-0.479
			c0.079,0,0.159,0,0.239,0c7.34,1.239,9.247,9.712,14.135,14.283c0.115,0.198,0.347,0.409,0.598,0.507
			c0.13,0.108,0.253,0.227,0.388,0.329c0.024-0.106,0.038-0.213,0.055-0.319c0.068-0.033,0.133-0.081,0.186-0.16
			c-0.052-0.093-0.096-0.193-0.135-0.298c-0.165-0.43-0.246-0.942-0.356-1.426c0.11,0.483,0.192,0.996,0.356,1.426
			c0.152-2.454-1.474-4.98-2.745-7.143c-2.262-3.845-5.159-7.023-7.92-9.12c1.485-1.554,4.509-1.571,4.08-5.04
			c-0.01-0.175-0.014-0.345-0.012-0.508c0.044-0.232,0.086-0.47,0.125-0.712c0.001-0.006,0.002-0.011,0.003-0.017
			c0,0.001,0,0.001-0.001,0.002c0.002-0.01,0.004-0.02,0.005-0.029c-0.001,0.01-0.002,0.019-0.004,0.027
			c0.034-0.074,0.069-0.148,0.123-0.203c0.459-1.542,1.177-2.824,1.2-4.8c0-0.08,0-0.16,0-0.24c1.34-2.82,2.876-5.444,4.08-8.4
			c0-0.08,0-0.159,0-0.239c0.912-1.328,1.603-2.878,2.16-4.561c0-0.08,0-0.16,0-0.24c0.379-0.765,0.721-1.54,1.039-2.323
			c1.353-2.838,2.865-5.731,3.021-9.095c0.002-0.005,0.003-0.011,0.005-0.017c0.308-0.966,0.636-1.922,0.982-2.869
			c-0.001,0.002-0.002,0.005-0.003,0.007c0.239-0.654,0.488-1.304,0.759-1.944c-0.268,0.64-0.518,1.285-0.756,1.938
			c4.504-9.767,12.839-15.7,17.032-25.776C369.132,506.339,371.751,504.428,372.001,500.819z M376.561,489.54
			c0.223,0.434,0.407,0.87,0.576,1.308C376.969,490.41,376.783,489.974,376.561,489.54z M370.319,521.87
			c-0.805,0.973-1.743,1.812-2.588,2.744C368.575,523.681,369.515,522.843,370.319,521.87z M377.221,519.059
			c0.361-0.362,0.758-0.712,1.171-1.016C377.979,518.348,377.583,518.695,377.221,519.059z M378.424,518.019
			c0.423-0.31,0.86-0.566,1.292-0.74C379.284,517.452,378.847,517.711,378.424,518.019z M433.201,519.06
			c-3.002,0.837-7.487,1.574-12.001,1.44c-0.566-0.017-2.271,0.234-1.68-0.24C423.758,519.536,428.727,519.546,433.201,519.06z
			 M423.069,516.729c0.006-0.001,0.013-0.002,0.019-0.003c-0.003,0-0.006,0.001-0.01,0.002
			C423.075,516.729,423.072,516.729,423.069,516.729z M393.285,528.435c-1.638,0.366-3.324,0.683-4.882,1.129
			C389.961,529.117,391.646,528.8,393.285,528.435z M384.785,531.138c-0.373,0.262-0.718,0.553-1.024,0.882
			C384.067,531.69,384.411,531.399,384.785,531.138z M423.33,563.939c-5.444-2.76-12.77-3.638-19.848-4.764
			C410.563,560.3,417.886,561.18,423.33,563.939z M358.801,520.74c-11.875-1.505-21.128,1.078-32.641,1.199
			c-0.097-2.656,0.577-4.543,0.48-7.199c12.075-0.485,24.352-0.77,36.96-0.721C362.245,516.505,360.634,518.732,358.801,520.74z
			 M328.801,511.62c0,0.08,0,0.159,0,0.239c-0.148,0.061-0.288,0.132-0.421,0.208c-0.001,0.001-0.003,0.002-0.005,0.003
			c0.001,0,0.001,0,0.002,0c-0.003,0.001-0.006,0.003-0.008,0.005c0.002-0.001,0.004-0.004,0.006-0.005
			c-0.404,0.023-0.947-0.091-1.015,0.27c-0.637-0.73,0.021-2.209,0.24-2.88c0.24,0,0.479,0,0.72,0c0.08,0,0.16,0,0.24,0
			C328.962,509.857,328.751,510.869,328.801,511.62z M330.24,498.66c0,0.08,0,0.159,0,0.239c-0.373,1.467-0.454,3.227-0.479,5.04
			c0,0.08,0,0.16,0,0.24c-0.376,0.984-0.364,2.355-0.48,3.6c-2.197-0.321-4.699-1.214-7.2-0.479c0.353-2.368,1.22-4.221,1.681-6.48
			c0-0.08,0-0.159,0-0.239c1.21-2.87,2.109-6.051,3.12-9.12c0-0.08,0-0.16,0-0.24c1.057-1.823,1.555-4.205,2.64-6
			C330.956,488.743,330.354,494.187,330.24,498.66z M319.44,521.939c-4.646,0.074-9.639-0.198-13.92,0.24
			c0.09-2.63,0.52-4.92,0.96-7.2c4.56,0,9.12,0,13.68,0C320.329,516.782,319.923,519.998,319.44,521.939z M319.2,523.859
			c0.7,3.516-2.66,9.92-0.72,13.44c0.55,0.998,2.892,1.949,4.32,1.2c2.917-1.529,1.182-11.662,3.12-14.641
			c11.271,0.19,19.898-3.166,30.96-1.199c-3.102,4.337-6.047,8.635-7.92,14.399c-2.047,6.295-4.084,13.643-7.681,18.24
			c-1.503,5.217-4.097,9.343-5.04,15.12c-2.655,1.344-6.353,1.647-9.12,2.88c-1.617-3.561-3.447-6.982-5.76-10.08
			c-0.599-0.802-1.703-2-1.439-3.12c-1.049-1.072-2.252-1.587-3.6-1.763c0.004,0.003,0.009,0.006,0.013,0.009
			c-0.005-0.003-0.009-0.006-0.014-0.009c0.001,0,0.001,0,0.001,0c-0.082-0.051-0.158-0.107-0.241-0.157
			c-0.182,0.026-0.362,0.063-0.544,0.092h0.001c-0.006,0.001-0.012,0.002-0.018,0.003c0.006-0.001,0.011-0.002,0.017-0.003
			c-2.308-0.083-5.009,0.663-8.096,1.349c-3.055,0.679-5.791,1.786-8.16,1.68c2.577-11.663,4.209-24.271,5.76-36.96
			C308.969,523.509,314.733,523.366,319.2,523.859z M316.561,561.06c-0.149,2.721,1.975,4.203,3.257,5.987
			c-0.165-0.286-0.338-0.57-0.512-0.854c0.175,0.284,0.347,0.569,0.513,0.855c0.21,0.362,0.417,0.727,0.619,1.094
			c0,0.001,0.001,0.003,0.002,0.004c0.262,0.688,0.141,1.411,0.441,2.034c0.358,0.744,1.071,1.205,1.594,1.822
			c0.186,0.351,0.371,0.702,0.565,1.058c-0.138,0.146-0.562,1.139,0,1.2c0-0.133,0.194-0.272,0.24,0
			c0.125,0.355,0.355,0.605,0.48,0.96c0.103,0.457,0.517,0.604,0.479,1.2c0.538,0.262,0.422,1.178,0.96,1.44
			c-0.054,0.453,0.271,0.529,0.24,0.96c0.354,0.524,0.468,1.292,0.96,1.68c-0.158,0.878,0.52,0.921,0.48,1.68
			c0.75,1.25,0.922,3.078,1.68,4.32c0.131,1.629,0.914,2.605,0.96,4.32c0.412,0.788,0.635,1.765,0.72,2.88
			c0.93,4.351,1.729,8.831,1.92,13.92c0.28,0.04,0.261,0.379,0.24,0.72c0,1.44,0,2.88,0,4.32c0,0.16,0,0.32,0,0.479
			c0.08,0,0.16,0,0.24,0c-0.157,1.603,0.316,3.837-0.24,5.04c0,0.16,0,0.32,0,0.48c0,0.24,0,0.479,0,0.72
			c-0.154,0.966,0.313,2.552-0.24,3.12c0,0.08,0,0.16,0,0.24c-0.153,0.966,0.313,2.552-0.239,3.12c0,0.08,0,0.159,0,0.239
			c-0.151,0.729,0.307,2.067-0.24,2.4c-0.005,3.115-0.543,5.697-0.96,8.4c-0.214,1.466-0.664,2.695-0.96,4.08
			c-0.415,1.104-0.619,2.421-1.2,3.359c-0.058,0.343-0.396,0.405-0.48,0.721c-0.141,0.499-0.504,0.775-0.72,1.199
			c-0.28,0.44-0.627,0.813-0.96,1.2c-0.08,0-0.16,0-0.24,0c-0.078,0.562-1.047,0.233-1.199,0.72c-0.16,0-0.32,0-0.48,0
			c-8.046,2.755-16.134,5.466-24.96,7.44c1.146-2.695,1.127-6.638,0.96-10.32c-0.646-14.21-3.956-28.771-7.2-41.76
			c-3.357-13.44-8.122-25.587-13.68-35.04C292.959,567.059,304.311,563.61,316.561,561.06z M283.728,593.423
			c-0.373-0.97-0.821-1.876-1.346-2.828C282.907,591.546,283.355,592.454,283.728,593.423z M197.52,691.141
			c-1.082-0.199-0.853-1.708-1.68-2.16c-3.073-0.688-6.74-0.781-9.36-1.92c0.981-1.1,2.571-1.59,3.36-2.881
			c11.363-0.411,21.767,4.171,32.16,4.32c2.578,0.037,6.511,0.176,7.92-1.68c-1.656-1.756-5.118-0.833-7.68-0.96
			c-3.584-0.179-7.055-1.021-9.84-1.44c9.515-2.965,17.516-7.444,26.64-10.8c0.205-0.044,0.227,0.094,0.24,0.24
			c0.204-0.044,0.227,0.094,0.24,0.239c-1.275,9.078,1.629,18.482,0.72,27.36c-0.429,4.189-2.578,7.928-3.12,11.761
			c-8.494-1.161-16.195-4.062-24.24-6.001c-4.034-0.972-7.936-1.969-11.76-3.359c-4.767-1.733-8.913-3.911-14.4-3.84
			c0-1.44,0-2.88,0-4.32c2.674-0.825,4.998-2.693,7.68-2.64c5.08,0.101,11.393,6.44,17.28,4.8
			C209.434,694.105,201.659,693.802,197.52,691.141z M175.953,687.546c0.275,1.682-0.172,4.113-0.405,6.062
			C175.778,691.662,176.227,689.229,175.953,687.546z M223.2,810.9c-18.004-0.324-36.087,0.553-53.52,0
			c1.545-1.335,3.968-1.792,4.8-3.84c0.321-1.195-0.957-1.792-0.809-2.431c0.019,0,0.038-0.001,0.056-0.002
			c0.993-0.037,1.994-0.066,3.002-0.084c0.003,0,0.006,0,0.009,0c15.438,0.646,31.185,1.257,46.942,0.356
			C223.534,806.914,223.498,809.038,223.2,810.9z M176.756,804.544c8.767-0.15,18.087,0.417,27.457,0.572
			C194.846,804.963,185.523,804.394,176.756,804.544z M168.48,802.5c-0.191-2.537,1.581-4.63,1.92-7.92
			c0.783-7.582-2.287-16.48-1.68-24.479c0.51-6.728,3.696-13.046,4.494-19.261c0.021-0.141,0.048-0.28,0.066-0.42
			c0.079-0.599,0.125-1.165,0.149-1.708c0.004-0.098-0.001-0.187,0.001-0.282c0.013-0.449,0.012-0.886-0.011-1.301
			c-0.003-0.06-0.012-0.114-0.016-0.174c-0.03-0.445-0.079-0.874-0.147-1.283c-0.003-0.02-0.008-0.038-0.011-0.058
			c-0.076-0.44-0.173-0.862-0.29-1.267c0-0.001,0-0.001,0-0.002v0.001c0-0.002-0.001-0.004-0.001-0.006
			c0,0.002,0.001,0.003,0.001,0.005c-0.323-1.334-0.832-2.668-1.594-4.006c1.058-1.979-0.048-4.625,0.96-6.72
			c0.746-1.55,3.133-2.554,4.32-3.84c5.946-6.442,10.581-12.489,10.08-25.68c1.073-0.188,1.561,1.094,2.16,1.68
			c5.131,5.023,11.559,9.784,18,13.92c5.061,3.25,10.92,7.824,17.52,9.84c1.999,0.61,5.364,1.69,7.2-0.479
			c-0.597-2.571-3.701-1.735-5.52-2.16c-3.146-0.736-6.811-3.014-10.08-4.8c-9.012-4.924-18.329-12.382-24.48-18.48
			c14.055,5.065,28.796,9.444,44.4,12.96c-1.453,5.268-1.607,10.332-2.64,15.36c-0.979,4.762-3.046,8.989-4.32,13.68
			c-2.794,10.287,0.061,21.929-0.48,32.4c-9.956,0.362-20.881-1.625-30,0.24c2.344,2.185,7.083,1.858,11.28,2.159
			c6.022,0.432,12.245,0.498,18.48,0.96c0.148,1.731-1.52,0.958-2.4,1.2c-2.568,0.707-5.124,1.777-7.92,2.4
			c-3.726,0.83-8.053,0.795-10.32,3.359c6.583,1.829,14.468-2.246,19.92-3.119c-1.231,5.889-2.766,11.474-3.6,17.76
			C205.415,803.571,186.188,801.761,168.48,802.5z M425.761,772.021c-0.363,3.076-1.226,5.654-1.44,8.88
			c-0.594,0.846-1.179,1.701-1.68,2.64c-0.063,0.737-0.521,1.079-0.72,1.681c-0.116,0.283-0.256,0.544-0.48,0.72
			c0.063,0.702-0.542,0.737-0.479,1.439c-0.58,1.101-0.958,2.403-1.44,3.601c-0.247,2.247,0.335,3.665,0.72,5.28
			c0.014,0.307,0.309,0.332,0.24,0.72c0,0.08,0,0.16,0,0.24c0.397,0.336,0.397,2.304,0,2.64c0,0.16,0,0.32,0,0.48
			c0,0.239,0,0.479,0,0.72c-0.153,0.887,0.311,2.391-0.24,2.88c-0.002,0.638,0.091,1.37-0.239,1.68c0,1.44,0,2.88,0,4.32
			c0,0.08,0,0.16,0,0.24c0.955,1.366-1.104,0.896-1.681,0.96c-3.136,0.35-7.04-0.269-9.6,0c-0.014-0.146-0.036-0.284-0.24-0.24
			c-6.736,0.437-14.014,0.24-21.36,0.24c-7.146,0-14.879,1.052-21.6,0c-4.017-0.629-8.458-3.43-12.479-5.28
			c-4.156-1.913-8.06-3.876-12.721-5.04c-27.104-6.771-61.959-1.68-92.88-1.68c-2.917,0-6.674-0.715-7.68,1.439
			c1.805,1.862,4.728,0.773,6.96,0.721c2.613-0.063,5.449-0.001,8.16,0c29.271,0.001,62.214-4.053,85.86,2.486
			c-2.639-0.779-5.382-1.457-8.21-2.036c2.829,0.579,5.571,1.257,8.21,2.036c0.005,0.002,0.01,0.003,0.015,0.005
			c1.258,0.371,2.489,0.767,3.698,1.183c0.072,0.024,0.144,0.05,0.215,0.075c1.174,0.405,2.326,0.83,3.455,1.274
			c0.043,0.018,0.085,0.036,0.129,0.053c2.99,1.267,5.773,2.768,8.311,4.552c-2.132,0.058-4.283,0.093-6.424,0.14
			c-1.06,0.021-2.114,0.049-3.175,0.069c0.003,0,0.007,0,0.01,0c-33.428,0.655-67.845,0.107-101.764,0.005
			c33.912,0.098,68.33,0.649,101.754-0.005c-40.13-0.005-80.408,0.14-119.924-0.478c-0.369-10.254,3.725-19.375,4.8-30
			c1.135-11.217-1.784-22.365,0.48-32.88c0.572-2.655,2.208-4.924,2.88-7.44c1.359-5.083,0.621-11.048,1.92-16.08
			c0.905-3.503,2.555-7.206,3.6-10.8c1.137-3.909,2.745-7.858,3.12-11.52c0.685-6.689-0.931-13.007-1.2-19.2
			c-0.249-5.71,0.655-11.468,1.44-16.8c13.763-2.875,28.216-5.058,38.956-10.954c0.056,0.256,0.124,0.5,0.204,0.731
			s0.17,0.452,0.271,0.662c0.276,1.02,0.745,2.141,1.609,2.84c0.59,0.478,1.551,0.577,2.469,0.85
			c-0.006-0.004-0.012-0.007-0.019-0.011c0.007,0.004,0.013,0.008,0.02,0.011c0,0-0.001,0-0.001,0
			c0.011,0.006,0.022,0.013,0.034,0.019c0.264,0.145,0.53,0.289,0.799,0.434c0.304,0.164,0.605,0.327,0.911,0.496
			c0.01,0.005,0.019,0.01,0.027,0.015c0.735,0.622,1.256,1.287,1.999,1.308c1.752,0.049,2.684-1.969,4.32-2.16
			c14.927,8.894,35.14,12.557,55.68,16.561c9.815,1.913,20.155,4.458,30.48,5.76c5.409,0.683,10.966,0.989,16.319,1.68
			c8.199,1.059,15.041,5.651,23.521,2.881c0.198,0.678-0.338,0.621-0.24,1.199c-0.269,0.051-0.373,0.267-0.479,0.48
			c0.16,0,0.319,0,0.479,0c-0.234,1.365,0.52,3.719-0.479,4.32c-0.771,0.269-1.424,0.656-2.4,0.72c0,0.08,0,0.16,0,0.24
			c-0.187-0.054-0.474-0.007-0.479-0.24c-1.813,0.9-3.694,1.603-5.605,2.208c-0.725,0.217-1.452,0.436-2.173,0.652
			c0.106-0.032,0.211-0.064,0.317-0.097c-2.874,0.819-5.788,1.481-8.619,2.276c-13.674,3.84-25.61,10.183-40.801,12.479
			c-2.814,0.426-6.132,0.283-8.399,2.4c12.961,0.996,23.471-3.232,33.359-6.96c8.396-3.165,18.036-5.938,26.881-8.64
			c1.233-0.377,2.943-1.45,3.119-0.721c0.507-0.213,0.71-0.73,1.44-0.72c0.984-0.296,1.673-0.887,2.88-0.96
			c0.053-0.188,0.201-0.279,0.48-0.24c0.399,3.441,0.399,8.56,0,12c0,0.08,0,0.16,0,0.24c0.376,2.202,0.21,6.977,0.239,10.32
			c0,0.399,0,0.8,0,1.2c0,0.159,0,0.319,0,0.479c0.521-0.04,0.109,0.852,0.24,1.2c0,1.04,0,2.08,0,3.12c0,0.08,0,0.16,0,0.239
			c0.548,0.333,0.09,1.671,0.24,2.4c0.315,0.725,0.165,1.915,0.48,2.64c0,0.96,0.479,1.44,0.479,2.4
			c0.382,0.979,0.552,2.169,0.48,3.6c0.552,0.568,0.086,2.154,0.239,3.12c0.445,0.516,0.162,1.759,0.24,2.641
			c0.369,0.751,0.382,1.857,0.48,2.88c0,0.08,0,0.16,0,0.24c0.399,1.363,0.399,4.396,0,5.76c0.172,1.372-0.257,2.144-0.24,3.36
			c-0.456,0.743-1.463,0.937-1.68,1.92c-0.398,0.802-0.803,1.598-1.2,2.399c-0.08,0-0.16,0-0.24,0
			c-3.235,6.845-8.576,11.584-10.08,20.16c0,0.96,0,1.92,0,2.88c0.317,3.202,0.992,6.049,0.96,9.601
			c0.665,1.415-0.165,4.324,0.48,5.76c0.636-0.004,1.413,0.133,1.439-0.48c0.729-0.23,0.522-1.397,0.96-1.92c0-1.52,0-3.04,0-4.56
			c-0.041-0.359,0.086-0.886-0.24-0.96c0.026-2.666-0.607-4.673-0.72-7.2c0-1.12,0-2.24,0-3.36c0.001-0.959,0.479-1.44,0.48-2.399
			c0.399-0.32,0.372-1.067,0.72-1.44c0.36-1,0.681-2.039,1.439-2.64c0.66-2.061,2.145-3.297,2.881-5.28
			c0.492-0.468,0.788-1.132,1.199-1.68c0.59-1.091,1.239-2.121,1.921-3.12c0.283-0.356,0.207-1.072,0.96-0.96
			c0,0.479,0,0.96,0,1.439c0.681,2.52,1.467,4.934,2.159,7.44C425.805,769.424,426.347,771.184,425.761,772.021z M476.161,493.859
			c0.012,3.293-0.589,5.972-0.96,8.881c-0.199,2.281-0.58,4.38-0.96,6.479c-0.068,0.972-0.412,1.668-0.48,2.64
			c0.019,0.418-0.054,0.747-0.24,0.96c0.14,0.779-0.163,1.117-0.239,1.681c-0.038,0.282,0.041,0.681-0.24,0.72
			c0.07,1.271-0.417,1.983-0.48,3.12c-0.46,2.02-0.709,4.251-1.439,6c-0.06,0.741-0.244,1.356-0.48,1.92
			c-0.65,1.669-0.963,3.677-1.2,5.76c-0.541,0.179-0.094,1.347-0.239,1.92c-0.038,0.283,0.041,0.682-0.24,0.721
			c-0.154,2.565-1.01,4.43-0.96,7.199c0.553,0.647,0.085,2.315,0.24,3.36c0,0.24,0,0.48,0,0.72c0,0.16,0,0.32,0,0.48
			c0.541,0.179,0.094,1.346,0.239,1.92c0,1.04,0,2.08,0,3.12c0,0.16,0,0.32,0,0.479c0.398,0.414,0.398,2.467,0,2.881
			c0,0.239,0,0.479,0,0.72c-0.079,0.561-0.288,0.991-0.479,1.439c0.067,0.628-0.088,1.033-0.72,0.96
			c-0.592-0.031-1.157-0.036-1.44,0.24c-0.695,0.345-0.989,1.091-1.2,1.92c-0.169,0.791-0.386,1.535-0.479,2.4
			c-0.703,2.657-0.854,5.865-1.44,8.64c-0.153,0.887,0.311,2.391-0.24,2.88c0,0.16,0,0.32,0,0.48
			c-0.157,1.443,0.316,3.516-0.239,4.56c0,0.08,0,0.16,0,0.24c-0.154,0.966,0.312,2.552-0.24,3.12c0,0.16,0,0.32,0,0.48
			c-0.155,1.045,0.313,2.713-0.24,3.359c0,0.08,0,0.16,0,0.24c-0.153,0.887,0.311,2.391-0.24,2.88
			c-0.147,4.572-0.926,8.515-1.439,12.72c-0.315,2.485-0.78,4.82-1.2,7.2c-0.196,1.243-0.453,2.427-0.72,3.601
			c-0.456,1.784-0.858,3.621-1.44,5.279c-0.085,1.115-0.308,2.092-0.72,2.88c-0.483,3.917-1.813,6.988-3.601,9.601
			c-0.415,0.385-0.703,0.896-0.96,1.439c-0.315,1.205-0.99,2.05-1.439,3.12c-0.355,0.525-0.469,1.292-0.96,1.681
			c0.01,0.33-0.113,0.526-0.24,0.72c0,0.08,0,0.16,0,0.24c-0.285,0.674-0.54,1.38-0.72,2.159c-0.014,0.146-0.036,0.285-0.24,0.24
			c-0.119,1.321-0.74,2.14-0.96,3.36c-0.43,0.931-0.624,2.096-0.96,3.12c-1.054,2.307-1.039,5.682-2.641,7.439
			c-0.607,1.473-1.802,2.358-2.64,3.601c-1.367,3.433-3.389,6.211-5.04,9.359c-0.523,0.757-0.652,1.907-1.439,2.4
			c0.01,0.33-0.113,0.527-0.24,0.72c-0.27,0.611-0.59,1.171-0.96,1.681c-0.95,1.85-1.846,3.755-3.12,5.279
			c-0.612,0.828-1.256,1.625-1.92,2.4c0.558-0.162,0.643-0.798,1.2-0.96c-0.518,1.472-1.158,1.859-2.488,1.67
			c-0.129-0.052-0.274-0.08-0.443-0.073c-0.209-0.043-0.428-0.094-0.669-0.157c-2.688,1.861-5.614,4.603-9.84,4.32
			c-1.954-0.13-4.323-1.445-6.48-2.16c-2.331-0.771-4.719-1.268-7.199-1.68c-15.54-2.58-31.754-3.846-46.08-6.72
			c-20.403-4.094-40.116-7.788-55.44-15.12c4.348-1.264,9.29-2.331,14.16-3.84c7.464-2.314,16.46-3.975,18.72-11.04
			c1.396-7.404,2.494-15.106,2.88-23.521c4.397-1.548,8.603-2.709,12.96-4.08c5.846-1.839,10.781-4.693,17.761-5.04
			c0.854-0.564,1.739-1.082,2.634-1.58c-0.01,0.006-0.02,0.01-0.029,0.016c0.073-0.041,0.146-0.081,0.22-0.122
			c2.777-1.536,5.636-2.891,7.976-4.793c2.203-1.792,4.257-5.572,6.72-5.76c0.921-0.07,2.027,0.247,3.173,0.742
			c0.023,0.012,0.047,0.024,0.07,0.036c0.474,0.229,0.943,0.477,1.399,0.724c0.113,0.062,0.229,0.122,0.341,0.184
			c0.064,0.035,0.125,0.068,0.188,0.104c0.462,0.256,0.893,0.495,1.288,0.709c0.008,0.005,0.018,0.01,0.026,0.015
			c0.076,0.041,0.161,0.089,0.233,0.127c-0.072-0.038-0.157-0.086-0.232-0.126c0.797,0.48,1.536,0.951,2.153,1.326
			c2.855,1.732,5.287,3.389,7.439,5.279c0.167-0.456,1.093,0.325,1.2,0.721c1.321,0.519,2.036,1.644,3.12,2.399
			c1.083,0.837,1.918,1.922,3.12,2.641c0.755,1.085,1.813,1.867,2.88,2.64c0.413,0.786,1.266,1.134,1.68,1.92
			c1.897,2.582,4.075,4.885,5.521,7.92c0.818,0.381,0.749,1.651,1.439,2.16c-0.054,0.454,0.271,0.529,0.24,0.96
			c0.116,0.283,0.256,0.544,0.479,0.72c0.034,1.406,0.927,1.954,0.96,3.36c0.317,0.003,0.22,0.421,0.48,0.479
			c1.173,1.229,1.836,4.242,3.36,4.32c-5.434-21.526-18.839-35.081-37.681-43.2c1.842-1.919,3.669-3.851,6.24-5.04
			c2.451,1.139,4.494,3.035,6,5.28c0.805,1.2,1.439,4.021,3.84,2.88c0.23-5.289-3.557-8.589-6.72-11.28
			c5.193-4.072,8.415-10.116,14.374-13.424c0.103,0.057,0.205,0.117,0.308,0.174c0.069,0.04,0.139,0.079,0.208,0.118
			c-0.002-0.001-0.004-0.002-0.006-0.004c5.03,2.819,9.921,6.443,15.042,9.752c-5.119-3.304-10.008-6.931-15.036-9.748
			c5.857,3.595,11.942,8.564,17.99,12.172c1.736,1.036,4.756,3.61,6.48,0.96c-4.813-2.866-10.076-5.283-13.92-9.119
			c5.49,0.414,11.689,1.018,16.079-1.44c1.272-0.712,2.338-2.897,3.601-3.12c2.847-0.502,4.271,2.423,6.96,1.68
			c0.784-3.084-3.662-2.854-5.76-3.84c0.255-6.654-8.439-4.36-12.48-6.72c0.585-0.986-0.016-1.576-0.72-1.92
			c8.883,0.245,19.318-1.235,24-6.24c1.354-1.446,3.145-4.753,2.64-7.439c-1.108-5.907-10.208-3.218-15.84-2.881
			c-1.852,0.111-3.65-0.001-5.409-0.246c0.191,0.038,0.386,0.07,0.578,0.106c-0.196-0.037-0.394-0.07-0.59-0.108
			c0.004,0.001,0.008,0.001,0.012,0.002c-0.634-0.124-1.262-0.259-1.887-0.398c-0.182-0.041-0.361-0.083-0.542-0.124
			c-0.42-0.097-0.838-0.194-1.255-0.294c-1.345-0.322-2.675-0.652-3.994-0.958c-0.028-0.007-0.057-0.014-0.085-0.021
			c0.003,0.001,0.005,0.002,0.008,0.002c-0.172-0.039-0.344-0.082-0.516-0.12c0.169,0.038,0.339,0.079,0.508,0.118
			c-4.216-1.233-8.253-2.588-12.269-2.759c7.735-0.905,16.014-1.267,23.28-2.64c0.553-0.088,0.229-1.051,0.96-0.96
			c0.575-0.177,1.156-0.348,1.739-0.517c-0.002,0.001-0.005,0.001-0.008,0.001c0.004,0,0.007-0.001,0.01-0.002
			c0,0.001-0.001,0.001-0.002,0.001c0.001,0,0.003-0.001,0.004-0.001c0.297-0.066,0.593-0.138,0.887-0.224
			c0.152-0.043,0.305-0.095,0.456-0.144c0.182-0.059,0.365-0.11,0.546-0.175c5.914-1.685,11.801-3.397,15.039-7.759
			c0.001-0.001,0.001-0.001,0.002-0.002l0.001-0.001c0.016-0.02,0.032-0.038,0.049-0.058c0.392,0.006,0.133,0.664,0.478,0.719
			c0-0.16,0-0.32,0-0.48c-0.041-0.505,0.054-0.875,0.202-1.19c0.093-0.15,0.181-0.305,0.265-0.461c-0.001,0-0.001,0.001-0.001,0.001
			c0.167-0.247,0.35-0.479,0.494-0.749c0.296-0.584,0.202-1.558,0.96-1.68c-0.509-1.351-0.18-3.169-0.72-4.32
			c-3.29-7.018-17.268-0.731-25.2-1.92c2.887-0.883,9.486-2.466,11.04-4.32c2.345-2.798-0.648-8.519-3.36-9.6
			c-3.329-1.328-7.771,0.021-12.239-0.48c2.71-3.441,6.266-6.224,10.319-8.16c1.222-0.583,3.287-0.515,3.84-2.16
			c-2.009-2.145-5.031-0.045-6.72,0.96c-1.813,1.08-4.202,2.396-5.76,3.841c-2.578,2.392-4.486,5.4-8.018,5.969
			c-0.001,0-0.002,0-0.003,0c-0.562,0.033-1.122,0.059-1.676,0.072c-0.005,0.001-0.01,0.001-0.015,0.001
			c-0.836-0.048-1.746-0.202-2.77-0.522c1.742-1.358,4.382-2.266,6.721-3.36c2.131-0.997,5.392-2.101,5.04-4.8
			c-2.146-0.414-3.75,1.55-5.521,2.4c-4.49,2.157-9.453,5.422-14.399,5.76c-0.68-0.039,0.199,1.479-0.48,1.44
			c-5.285,2.496-12.704,4.793-19.954,5.934c0.025-0.002,0.051-0.005,0.076-0.008c-0.026,0.003-0.053,0.006-0.079,0.009
			c-0.303,0.03-0.598,0.062-0.898,0.091c-1.413,0.139-2.803,0.28-4.142,0.468c-0.004,0.001-0.008,0.001-0.012,0.002
			c0.002,0,0.003,0,0.005,0c-0.021,0.003-0.041,0.006-0.062,0.009c0.02-0.003,0.038-0.006,0.057-0.009
			c-2.038,0.142-3.986,0.284-5.472,0.944c-1.65,0.734-2.811,2.922-4.56,3.12c2.364-6.659,3.019-13.649,6-19.439
			c1.12-2.176,2.772-4.118,3.84-6.24c2.401-4.777,4.439-8.917,7.681-12.48c1.492-1.642,4.134-3.233,3.84-6
			c-3.519-0.447-5.648,3.076-7.44,5.28c-2.079,2.556-3.994,5.354-5.52,7.439c0.811-3.108,1.871-5.968,2.64-9.119
			c0.555-0.405,1.841-0.079,2.16-0.721c1.515-22.725,13.261-35.597,21.359-52.319c-3.733,2.314-6.225,6.106-8.64,9.6
			c-6.009,8.691-12.105,19.529-14.64,30.96c-0.313,1.408-0.389,2.877-0.489,4.399c-0.001,0.012-0.003,0.023-0.003,0.035
			c-0.053,0.612-0.124,1.218-0.209,1.819c-0.021,0.146-0.045,0.292-0.067,0.438c-0.072,0.471-0.151,0.94-0.24,1.407
			c-0.031,0.164-0.063,0.328-0.097,0.491c-0.095,0.467-0.196,0.931-0.305,1.394c-0.032,0.136-0.063,0.272-0.096,0.408
			c-0.143,0.587-0.292,1.172-0.452,1.754c0.001-0.002,0.001-0.004,0.002-0.006c-0.189,0.69-0.39,1.377-0.596,2.063
			c0.205-0.685,0.405-1.369,0.594-2.058c-1.639,5.228-4.164,10.776-4.763,16.414c-0.501-2.779-2.114-4.446-4.08-5.761
			c-0.288-3.326-0.402-6.66-0.239-9.84c0.354-6.901,2.652-13.428,2.399-18.96c-3.302,7.513-5.1,17.762-4.08,28.8
			c-0.41,0.428-0.777,0.914-1.113,1.441c0.003-0.003,0.005-0.005,0.008-0.007c-0.003,0.002-0.006,0.005-0.008,0.008
			c0-0.001,0-0.001,0-0.001c-0.001,0.001-0.001,0.001-0.002,0.002c-0.089,0.087-0.174,0.182-0.257,0.28
			c-0.016,0.019-0.033,0.035-0.049,0.054c-0.093,0.117-0.182,0.242-0.259,0.383c-0.111,0.202-0.21,0.43-0.303,0.672
			c-0.014,0.034-0.024,0.073-0.037,0.109c-0.081,0.223-0.157,0.456-0.228,0.703c0,0.002-0.001,0.003-0.001,0.004s0,0.002,0,0.002
			c-1.355,3.235-2.056,7.344-3.031,10.99c-1.113,4.155-2.788,7.723-4.32,11.04c-10.535-0.729-23.621,0.738-33.36,0
			c-0.327-1.688,1.346-1.375,1.44-2.641c-0.251-0.709-1.215-0.705-0.96-1.92c0.521-4.175,1.449-8.925,1.439-14.16
			c-0.006-3.338,0.141-11.105-3.359-11.04c-1.75,0.033-3.344,3.514-4.08,5.28c-3.405,8.173-5.622,17.109-7.44,25.2
			c-4.246,0.633-9.944-0.185-13.92,0.72c-1.453,8.057-2.408,16.426-3.36,24.721c-0.966,8.419-2.996,16.214-4.079,24.239
			c-7.449,1.512-14.76,3.16-23.28,3.601c-0.063,0.186-0.128,0.38-0.198,0.583c-0.083,0.165-0.146,0.35-0.189,0.555
			c-1.081,3.18-2.582,7.904-1.054,10.382c-0.708,0.129-1.201-0.079-1.586-0.444c-0.001-0.002-0.002-0.004-0.003-0.006
			c-0.148-0.309-0.313-0.597-0.488-0.872c-0.016-0.024-0.032-0.047-0.047-0.071c-0.175-0.269-0.362-0.521-0.561-0.759
			c0-0.001,0-0.001,0-0.001c-0.202-0.371-0.413-0.717-0.674-0.967c-5.49-5.238-15.954-1.006-16.08,6.96
			c-2.645,0.396-5.12,0.961-7.92,1.2c1.081-2.782-0.146-7.306,0.96-10.56c0.51-1.499,3.677-4.017,5.28-5.761
			c1.978-2.152,3.864-4.001,5.52-5.76c18.648-19.806,34.351-40.909,50.16-62.64c2.971-4.085,6.479-8.649,9.12-13.44
			c2.288-4.152,4.971-12.074,5.279-17.28c0.193-3.247-0.604-5.67,0.48-8.159c0.643-1.476,2.097-2.033,2.399-4.08
			c-1.59-3.324,0.501-7.635,1.921-11.761c6.795-19.746,15.458-38.798,28.56-50.64c4.587,1.893,9.51,1.289,14.88,1.68
			c4.173,0.304,8.362,1.736,12.72,2.88c12.452,3.268,26.753,5.612,41.041,6.24c4.864,0.214,9.746-0.032,14.159,1.92
			c0.08,0,0.16,0,0.24,0c1.496-0.056,2.489,0.392,3.601,0.72c1.673,0.247,3.285,0.554,4.56,1.2c1.38,0.22,2.512,0.688,3.6,1.2
			c0.962,0.319,1.939,0.621,2.4,1.44c0.08,0,0.16,0,0.24,0c0.268,0.388,1.676,0.862,1.2,1.2c-0.447-0.033-0.803-0.158-0.96-0.48
			c-1.215-0.146-2.109-0.611-3.12-0.96c-1.496-0.216-2.119,0.441-2.641,1.2c-0.01,1.771,1.135,2.386,2.641,2.64
			c0.925,0.834,2.507,1.013,3.6,1.68c0.343,0.058,0.405,0.395,0.72,0.48c2.136,1.305,3.542,3.338,4.801,5.52
			c2.139,3.461,4.104,7.097,6,10.801c0.683,0.437,0.921,1.318,1.439,1.92c0.058,0.343,0.396,0.404,0.48,0.72
			c0.899,1.1,1.124,2.876,1.92,4.08c0.04,0.68,0.439,1,0.479,1.68c1.113,2.487,1.893,5.308,2.88,7.92
			c0.412,2.229,1.064,4.217,1.44,6.48c0.346,1.414,0.69,2.83,0.96,4.32c0.417,1.902,0.965,3.675,1.2,5.76
			c0.342,1.417,0.608,2.912,0.72,4.56c0.285,1.556,0.518,3.163,0.48,5.04c0.187,0.053,0.278,0.201,0.239,0.48c0,0.8,0,1.6,0,2.399
			c0,0.32,0,0.641,0,0.96c0,0.16,0,0.32,0,0.48c0.521-0.04,0.109,0.852,0.24,1.2c-0.159,3.681,0.319,7.999-0.24,11.28
			c0,0.159,0,0.319,0,0.479C476.249,492.027,476.71,493.449,476.161,493.859z"/>
				<path d="M177.286,575.015c0.003,0,0.007,0.001,0.01,0.001c-0.006-0.001-0.014-0.002-0.021-0.003
			C177.279,575.013,177.282,575.014,177.286,575.015z"/>
				<path d="M386.546,530.196c-0.008,0.003-0.017,0.005-0.024,0.008c-0.001,0.001-0.003,0.001-0.004,0.002
			C386.526,530.202,386.537,530.2,386.546,530.196z"/>
				<polygon points="291.206,340.648 291.207,340.65 291.206,340.647 		" />
			</g>
		</g>
		<g id="lighting_1_">
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M329.521,358.979c0,0.8,0,1.6,0,2.4
		c-0.397,2.643-0.637,5.443-1.68,7.44c0,0.241-0.194,0.286-0.24,0.48c0.567,1.691,0.152,4.469,0,6.24
		c0.001,0.241-0.194,0.286-0.24,0.48c1.65-1.069,3.469-1.972,4.8-3.36c0.388,0.067,0.413-0.227,0.721-0.24c0-0.48,0-0.96,0-1.44
		c-0.557-1.203-0.083-3.438-0.24-5.04c0.08,0,0.16,0,0.24,0c0-0.16,0-0.32,0-0.48c0-0.88,0-1.76,0-2.64c0-0.16,0-0.32,0-0.48
		c0-0.24,0-0.48,0-0.72c0-0.72,0-1.44,0-2.16c0.08,0,0.159,0,0.239,0c0-0.16,0-0.32,0-0.48c0-0.8,0-1.6,0-2.4c0-0.32,0-0.64,0-0.96
		c0-0.48,0-0.96,0-1.44c0.55-0.411,0.089-1.832,0.24-2.64c0.292-1.068,0.261-2.459,0.24-3.84c0.545-0.255,0.091-1.508,0.24-2.16
		c0-0.32,0-0.64,0-0.96c0-0.48,0-0.96,0-1.44c0.08,0,0.159,0,0.239,0c0-0.08,0-0.16,0-0.24c0-1.36,0-2.72,0-4.08
		c0-0.32,0-0.64,0-0.96c0-2.494,0-4.988,0-7.481c0,0,0,0,0-0.001v0c0.014-0.322,0.024-0.634,0.033-0.943
		c0.003-0.091,0.006-0.182,0.008-0.271c0.007-0.277,0.012-0.547,0.014-0.811c0-0.108,0-0.213,0-0.319
		c0-0.25-0.002-0.494-0.009-0.728c-0.002-0.097-0.007-0.187-0.011-0.281c-0.009-0.234-0.019-0.468-0.035-0.683
		c0-0.721,0-1.441,0-2.162c0-0.16,0-0.32,0-0.48c-0.159,0-0.319,0-0.479,0c-0.046,0.194-0.241,0.239-0.24,0.48
		c-0.451,0.349-0.029,1.571-0.479,1.92c-0.126,1.154-0.512,2.048-0.721,3.12c-0.541,1.379-0.479,3.362-0.96,4.8
		c-0.194,1.965-0.929,3.391-0.96,5.52c-0.547,0.333-0.089,1.67-0.24,2.4c0,1.36,0,2.72,0,4.08c0,0.88,0,1.76,0,2.64
		c-0.079,0-0.159,0-0.239,0c0,0.96,0,1.92,0,2.88c0,0.08,0,0.16,0,0.24c-0.554,0.726-0.085,2.476-0.24,3.6c0.08,0,0.16,0,0.24,0
		c0,1.2,0,2.4,0,3.6C329.681,358.979,329.601,358.979,329.521,358.979z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M476.4,491.22c0-0.16,0-0.32,0-0.479
		c0.56-3.281,0.081-7.6,0.24-11.28c-0.131-0.349,0.28-1.24-0.24-1.2c0-0.16,0-0.32,0-0.48c0-0.319,0-0.64,0-0.96
		c0-0.8,0-1.6,0-2.399c0.039-0.279-0.053-0.428-0.239-0.48c0.037-1.877-0.195-3.484-0.48-5.04c-0.111-1.647-0.378-3.143-0.72-4.56
		c-0.235-2.085-0.783-3.857-1.2-5.76c-0.27-1.49-0.614-2.906-0.96-4.32c-0.376-2.264-1.028-4.252-1.44-6.48
		c-0.987-2.612-1.767-5.433-2.88-7.92c-0.04-0.68-0.439-1-0.479-1.68c-0.796-1.204-1.021-2.98-1.92-4.08
		c-0.085-0.315-0.423-0.377-0.48-0.72c-0.519-0.602-0.757-1.483-1.439-1.92c-1.896-3.704-3.861-7.34-6-10.801
		c-1.259-2.182-2.665-4.215-4.801-5.52c-0.314-0.085-0.377-0.422-0.72-0.48c-1.093-0.667-2.675-0.846-3.6-1.68
		c-1.506-0.254-2.65-0.87-2.641-2.64c0.521-0.759,1.145-1.416,2.641-1.2c1.011,0.349,1.905,0.814,3.12,0.96
		c0.157,0.322,0.513,0.447,0.96,0.48c0.476-0.338-0.933-0.812-1.2-1.2c-0.08,0-0.16,0-0.24,0c-0.461-0.819-1.438-1.121-2.4-1.44
		c-1.088-0.511-2.22-0.98-3.6-1.2c-1.274-0.646-2.887-0.954-4.56-1.2c-1.111-0.329-2.104-0.776-3.601-0.72c-0.08,0-0.16,0-0.24,0
		c-2.052,0.47-2.515,1.14-2.155,1.872c0.001,0.019-0.006,0.03-0.004,0.049c0.015,0.008,0.027,0.018,0.042,0.026c0,0,0,0,0,0
		c1.637,0.905,3.061,2.024,4.614,3.013c-1.554-0.988-2.978-2.107-4.614-3.012c0.855,1.515,5.115,3.271,6.197,4.054
		c0.885,2.326,2.568,3.542,3.601,5.28c0.717,1.207,0.91,2.553,1.68,4.08c1.522,3.02,4.132,5.73,6,8.64
		c1.755,2.733,3.328,5.554,4.768,8.467c0.25,0.539,0.498,1.079,0.753,1.613c0.262,0.55,0.525,1.104,0.789,1.662
		c-0.008-0.018-0.017-0.035-0.024-0.053c0.147,0.313,0.296,0.631,0.443,0.947c-0.14-0.298-0.279-0.599-0.419-0.895
		c2.7,6.079,4.811,12.63,6.41,19.938c0.069,0.389,0.123,0.793,0.164,1.21c-0.041-0.417-0.095-0.821-0.164-1.21
		c-0.159,3.521,0.319,7.679-0.239,10.8c0.004,0.123,0.01,0.215,0.017,0.284c-0.007-0.069-0.013-0.161-0.017-0.284
		c-0.832-3.152-3.07-5.793-5.04-8.16c-1.959-2.354-4.161-4.886-6.48-6.24c2.606,6.92,7.386,14.807,9.757,22.539
		c0.002,0.006,0.003,0.012,0.005,0.019c0.209,0.859,0.395,1.746,0.547,2.664c0.001,0.008,0.003,0.015,0.004,0.023
		c0.227,1.647,0.369,3.589,0.248,4.755c-0.126,1.201-1.066,2.386-1.2,3.601c-0.671,6.084,1.638,12.371,1.2,19.439
		c0.151,0.809-0.31,2.229,0.239,2.641c0.164,2.884-0.488,4.951-0.72,7.439c-1.376,1.104-2.071,2.889-2.16,5.28
		c-0.354-0.125-0.604-0.355-0.96-0.479c-0.758,0.122-0.664,1.096-0.96,1.68c-0.391,0.729-1.058,1.183-0.96,2.399
		c0,0.16,0,0.32,0,0.48c1.098,8.209,5.583,14.22,4.561,23.76c0,0.08,0,0.16,0,0.24c-0.078,0.179-0.132,0.362-0.178,0.548
		c-0.023,0.089-0.047,0.179-0.064,0.27c-0.444,1.523-0.806,3.128-1.198,4.703c-1.547,0.293-2.661,1.019-2.88,2.64
		c-0.023,0.189-0.03,0.395-0.031,0.606c0.001-0.211,0.008-0.417,0.031-0.606c-0.411,0.655-0.26,1.873-0.217,2.982
		c0,0.202,0.003,0.406,0.008,0.61c-0.004,0.169-0.013,0.333-0.031,0.487c0.017-0.026,0.024-0.062,0.039-0.09l0,0
		c-0.015,0.028-0.022,0.063-0.039,0.09c-0.235,2.005-0.869,3.61-0.72,6c0,0.08,0,0.16,0,0.24c-0.537,0.103-0.098,1.183-0.24,1.68
		c-0.549,0.411-0.088,1.832-0.24,2.64c0,0.96,0,1.92,0,2.88c-0.549,0.411-0.088,1.832-0.239,2.641c0,0.8,0,1.6,0,2.399
		c0,0.08,0,0.16,0,0.24c-0.979,5.021-1.634,10.367-2.641,15.36c0,0.08,0,0.16,0,0.239c-1.521,4.641-2.287,10.033-4.32,14.16
		c-0.112-5.022,0.22-11.251-0.239-15.84c-0.143-1.419-0.24-3.539-1.681-4.08c-0.357,0.503-0.657,1.049-0.932,1.613
		c-0.387,0.631-0.672,1.442-0.881,2.342c-1.35,4.692-0.951,10.725-0.806,16.732c-0.001,0.132-0.001,0.264-0.001,0.395
		c-0.003,1.756-0.005,3.525-0.013,5.294v0.001c-0.004,0.088-0.004,0.176-0.008,0.264c-0.321,7.068-0.815,13.83-1.44,19.68
		c-0.874-0.074-1.562,0.038-2.159,0.24c-0.402,1.357-0.674,2.847-1.2,4.08c0,0.08,0,0.16,0,0.24c-0.842,2.678-1.12,5.919-1.92,8.64
		c-0.202,0.278-0.316,0.644-0.24,1.2c-1.113,1.286-0.903,3.896-3.36,3.84c-0.989-0.43-0.349-2.524,0-3.12
		c0.316-1.284,0.645-2.556,0.96-3.84c0.128-0.673,0.353-1.248,0.48-1.92c0.701-4.02,1.278-8.162,1.68-12.48c0-1.04,0-2.08,0-3.12
		c0-0.64,0-1.279,0-1.92c0.242-3.343-0.852-4.297-1.867-3.798c0.063-0.015,0.118-0.035,0.188-0.042
		c-0.07,0.007-0.126,0.028-0.188,0.043l0.001-0.001c-0.001,0-0.002,0.001-0.003,0.001c-0.222,0.053-0.397,0.15-0.534,0.288
		c-0.002,0.001-0.003,0.002-0.004,0.003c-0.066,0.068-0.126,0.144-0.176,0.229c-0.002,0.003-0.004,0.007-0.006,0.011
		c-0.05,0.085-0.093,0.177-0.127,0.277c-0.651,0.995-1.004,2.693-0.403,4.669c0,0.08,0,0.16,0,0.24
		c-0.577,2.542-0.48,5.76-0.479,8.88c-0.552,0.568-0.086,2.154-0.24,3.12c-0.2,4.68-0.205,9.556-0.96,13.681c-0.08,0-0.16,0-0.24,0
		c-0.636-0.621-1.698,0.369-2.399,0.479c-0.803,1.911-2.438,4.765-3.84,8.16c-1.243,3.008-3.529,8.604-1.699,10.459
		c0.008,0.034,0.011,0.067,0.019,0.102c0.033,0.011,0.062,0.013,0.094,0.023c0,0,0,0-0.001-0.001
		c0.008,0.003,0.015,0.003,0.021,0.006c-0.007-0.003-0.014-0.003-0.021-0.005c0.491,0.414,1.23,0.601,2.307,0.456
		c0.664-0.775,1.308-1.572,1.92-2.4c0.985-1.676,1.083-5.448,3.12-5.279c0.37-0.51,0.69-1.069,0.96-1.681
		c0.127-0.192,0.25-0.39,0.24-0.72c0.787-0.493,0.916-1.644,1.439-2.4c1.651-3.148,3.673-5.927,5.04-9.359
		c0.838-1.242,2.032-2.128,2.64-3.601c1.602-1.758,1.587-5.133,2.641-7.439c0.336-1.024,0.53-2.189,0.96-3.12
		c0.22-1.221,0.841-2.039,0.96-3.36c0.204,0.045,0.227-0.094,0.24-0.24c0.18-0.779,0.435-1.485,0.72-2.159c0-0.08,0-0.16,0-0.24
		c0.127-0.193,0.25-0.39,0.24-0.72c0.491-0.389,0.604-1.155,0.96-1.681c0.449-1.07,1.124-1.915,1.439-3.12
		c0.257-0.543,0.545-1.055,0.96-1.439c1.788-2.612,3.117-5.684,3.601-9.601c0.412-0.788,0.635-1.765,0.72-2.88
		c0.582-1.658,0.984-3.495,1.44-5.279c0.267-1.174,0.523-2.357,0.72-3.601c0.42-2.38,0.885-4.715,1.2-7.2
		c0.514-4.205,1.292-8.147,1.439-12.72c0.551-0.489,0.087-1.993,0.24-2.88c0-0.08,0-0.16,0-0.24c0.553-0.646,0.085-2.314,0.24-3.359
		c0-0.16,0-0.32,0-0.48c0.552-0.568,0.086-2.154,0.24-3.12c0-0.08,0-0.16,0-0.24c0.556-1.044,0.082-3.116,0.239-4.56
		c0-0.16,0-0.32,0-0.48c0.551-0.489,0.087-1.993,0.24-2.88c0.586-2.774,0.737-5.982,1.44-8.64c0.094-0.865,0.311-1.609,0.479-2.4
		c0.211-0.829,0.505-1.575,1.2-1.92c0.283-0.276,0.849-0.271,1.44-0.24c0.632,0.073,0.787-0.332,0.72-0.96
		c0.191-0.448,0.4-0.879,0.479-1.439c0-0.24,0-0.48,0-0.72c0.398-0.414,0.398-2.467,0-2.881c0-0.159,0-0.319,0-0.479
		c0-1.04,0-2.08,0-3.12c-0.146-0.574,0.302-1.741-0.239-1.92c0-0.16,0-0.32,0-0.48c0-0.239,0-0.479,0-0.72
		c-0.155-1.045,0.313-2.713-0.24-3.36c-0.05-2.77,0.806-4.634,0.96-7.199c0.281-0.039,0.202-0.438,0.24-0.721
		c0.146-0.573-0.302-1.741,0.239-1.92c0.237-2.083,0.55-4.091,1.2-5.76c0.236-0.563,0.421-1.179,0.48-1.92
		c0.73-1.749,0.979-3.98,1.439-6c0.063-1.137,0.551-1.85,0.48-3.12c0.281-0.039,0.202-0.438,0.24-0.72
		c0.076-0.563,0.379-0.901,0.239-1.681c0.187-0.213,0.259-0.542,0.24-0.96c0.068-0.972,0.412-1.668,0.48-2.64
		c0.38-2.1,0.761-4.198,0.96-6.479c0.371-2.909,0.972-5.588,0.96-8.881C476.71,493.449,476.249,492.027,476.4,491.22z
		 M457.471,465.146c2.214,4.511,4.565,8.884,5.886,14.288C462.036,474.029,459.681,469.659,457.471,465.146z M458.851,562.146
		c0,0.001-0.001,0.002-0.001,0.003c0,0,0,0,0-0.001C458.85,562.148,458.851,562.147,458.851,562.146z M465.543,461.979
		c0.027,0.335,0.054,0.673,0.068,1.022C465.597,462.652,465.57,462.314,465.543,461.979z M465.615,463.196
		c0.09,2.54-0.117,5.377-0.02,7.908C465.498,468.573,465.704,465.737,465.615,463.196z M465.147,471.639
		c0.005,0.032,0.01,0.069,0.017,0.086C465.157,471.708,465.152,471.671,465.147,471.639z M465.175,471.734
		c0.064,0.081,0.187-0.355,0.4-0.422l0,0C465.361,471.379,465.239,471.819,465.175,471.734z M465.597,471.146L465.597,471.146
		c0.09-0.763,0.157-1.512,0.206-2.253C465.754,469.634,465.687,470.385,465.597,471.146z M465.602,461.018
		c-0.421-3.63-1.263-7.003-2.341-10.175C464.342,454.014,465.182,457.388,465.602,461.018c0.207,1.778,0.32,3.612,0.296,5.524
		C465.921,464.631,465.808,462.796,465.602,461.018z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M429.36,574.74c0-0.08,0-0.16,0-0.24
		c-0.89,0.17-1.029-0.41-1.92-0.24c0,0.16,0,0.32,0,0.48c-0.08,0-0.16,0-0.239,0c0,0.08,0,0.159,0,0.239c0.8,0,1.6,0,2.399,0
		C429.645,574.775,429.507,574.754,429.36,574.74z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M332.4,618.66c0-0.16,0-0.32,0-0.48
		c0.557-1.203,0.083-3.438,0.24-5.04c-0.08,0-0.16,0-0.24,0c0-0.159,0-0.319,0-0.479c0-1.44,0-2.88,0-4.32
		c0.021-0.341,0.04-0.68-0.24-0.72c-0.191-5.089-0.99-9.569-1.92-13.92c-0.085-1.115-0.308-2.092-0.72-2.88
		c-0.046-1.715-0.829-2.691-0.96-4.32c-0.758-1.242-0.93-3.07-1.68-4.32c0.039-0.759-0.639-0.802-0.48-1.68
		c-0.492-0.388-0.605-1.155-0.96-1.68c0.03-0.431-0.294-0.507-0.24-0.96c-0.538-0.263-0.422-1.179-0.96-1.44
		c0.037-0.597-0.377-0.743-0.479-1.2c-0.125-0.354-0.355-0.604-0.48-0.96c-0.046-0.272-0.24-0.133-0.24,0
		c1.656,7.335,3.156,12.574,5.04,19.68c1.516,5.716,2.308,13.986,2.306,22.378c-0.001,0.041-0.002,0.083-0.003,0.125
		c-0.018,0.989-0.045,1.978-0.082,2.961c-0.007,0.193-0.017,0.385-0.024,0.578c-0.035,0.851-0.076,1.698-0.126,2.54
		c-0.012,0.206-0.025,0.41-0.038,0.616c-0.054,0.857-0.115,1.71-0.186,2.555c-0.013,0.155-0.025,0.311-0.039,0.466
		c-0.083,0.958-0.175,1.908-0.278,2.845c-0.003,0.029-0.006,0.06-0.01,0.09c-0.453,4.067-1.104,7.905-1.973,11.323
		c-0.001,0.002-0.001,0.004-0.002,0.006c-0.436,1.464-0.921,2.789-1.465,3.918c-0.789,1.641-3.408,3.325-1.68,3.84
		c0.16,0,0.32,0,0.48,0c0.152-0.486,1.121-0.158,1.199-0.72c0.08,0,0.16,0,0.24,0c0.333-0.388,0.68-0.76,0.96-1.2
		c0.216-0.424,0.579-0.7,0.72-1.199c0.085-0.315,0.423-0.378,0.48-0.721c0.581-0.938,0.785-2.255,1.2-3.359
		c0.296-1.385,0.746-2.614,0.96-4.08c0.417-2.703,0.955-5.285,0.96-8.4c0.547-0.333,0.089-1.671,0.24-2.4c0-0.08,0-0.159,0-0.239
		c0.552-0.568,0.086-2.154,0.239-3.12c0-0.08,0-0.16,0-0.24c0.553-0.568,0.086-2.154,0.24-3.12
		C332.4,619.14,332.4,618.9,332.4,618.66z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M336.961,229.859c-0.115,0.755,0.342,0.938,0.479,1.44
		c1.886,3.474,3.835,6.886,5.76,10.32c0.281,0.359,0.299,0.982,0.721,1.2c0.193,0.095,0.081-1.499-0.24-1.68
		c-0.698-5.622-2.134-10.506-3.36-15.6c-0.349-1.011-0.813-1.906-0.96-3.12c-0.422-0.218-0.438-0.841-0.72-1.2
		c-3.337-9.463-9.027-16.573-15.12-23.28c-0.489-0.07-0.471-0.649-0.96-0.72c-0.661-0.459-1.175-1.065-1.92-1.44
		c-0.477-0.003-0.306-0.655-0.96-0.48c0.073-0.554-0.693-0.267-0.72-0.72c-0.388,0.068-0.414-0.227-0.721-0.24
		c-0.506,0.02-0.432,0.142-0.24,0.48C323.831,205.081,333.525,216.188,336.961,229.859z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M322.32,242.339c0.489,0.594,1.412,0.668,1.92,1.2
		c0.567,0.593,0.979,1.828,1.681,2.64c1.335,1.546,3.369,3.04,3.6,5.76c0.276,3.267-1.252,7.803-1.92,12
		c-0.685,4.299-2.559,8.869-2.16,13.2c0.277,3.017,0.188,6.183-1.2,9.36c0.014,0.146,0.036,0.284,0.24,0.24c0,0.08,0,0.16,0,0.24
		c-0.037,0.597,0.377,0.743,0.48,1.2c0.348,0.452,0.353,1.248,0.96,1.44c0-0.24,0-0.48,0-0.72c0.383-1.216,0.418-2.781,0.72-4.08
		c1.585-0.415,2.274-1.726,2.399-3.6c0.444-0.676,0.659-1.581,0.96-2.4c0.385-1.056,1.025-1.855,1.2-3.12
		c0.615-0.825,1.094-1.786,1.44-2.88c2.041-3.319,4.509-6.211,6.24-9.84c0.843-0.757,1.235-1.964,1.68-3.12c0-0.08,0-0.16,0-0.24
		c0.682-0.678,0.819-1.9,1.92-2.16c0-0.08,0-0.16,0-0.24c1.089-0.313,2.511-0.313,3.6,0c-0.673-1.247-0.967-2.873-1.439-4.32
		c-0.261-0.859-0.375-1.866-0.72-2.64c-0.692-2.588-1.559-5.002-2.641-7.2c-0.266-0.934-0.663-1.736-1.2-2.4
		c-0.286-1.313-1.152-2.047-1.439-3.36c-0.787-0.493-0.917-1.644-1.44-2.4c0-0.08,0-0.16,0-0.24c-0.53-1.229-1.283-2.236-1.68-3.6
		c-0.08,0-0.16,0-0.24,0c0.067-0.388-0.227-0.413-0.24-0.72c-3.97-8.99-9.299-16.621-15.119-23.76
		c2.768,5.077,4.508,10.843,6.479,16.8c1.305,3.941,3.26,7.498,4.635,11.322c-0.457-1.52-0.95-3.009-1.469-4.476
		c0.519,1.468,1.014,2.96,1.471,4.481c-0.001-0.002-0.001-0.004-0.002-0.006c0.247,0.82,0.481,1.649,0.703,2.487
		c0.002,0.007,0.004,0.015,0.006,0.022c0.221,0.838,0.43,1.685,0.623,2.542c-0.001-0.003-0.001-0.007-0.002-0.01
		c0.134,0.59,0.264,1.184,0.383,1.785c-0.121-0.596-0.248-1.187-0.381-1.774c0.18,1.139,0.285,2.313,0.273,3.546
		c-1.708-0.551-3.265-1.254-4.719-2.061c-0.031-0.021-0.063-0.042-0.094-0.063c-0.329-0.219-0.655-0.438-0.982-0.652
		c-0.306-0.201-0.612-0.398-0.925-0.584c-0.112-0.066-0.24-0.109-0.362-0.16c-3.334-2.252-6.186-4.987-9.238-7.52
		c2.108,0.293,4.525,2.354,7.2,2.88c-0.752-1.014-2.751-2.925-5.04-4.32c-2.073-1.264-4.91-3.538-6.24-3.6
		c-0.484-0.022-1.887-0.167-1.2,0.72c0.305,0.076,0.567,0.193,0.797,0.346c0.158,0.167,0.306,0.338,0.475,0.5
		c0.087,0.167,0.147,0.36,0.169,0.594c0.344,0.957,2.479,1.261,2.421,2.041c-0.713-0.422-1.494-0.761-2.356-0.998
		c-0.001,0-0.002,0-0.003-0.001c-0.197-0.091-0.384-0.192-0.542-0.322c-0.399,0-0.8,0-1.199,0c-0.241,0.001-0.286-0.194-0.48-0.24
		c-0.24,0-0.479,0-0.72,0c-1.358-0.606-4.352-0.881-4.8,0.72c-0.073,1.593,1.817,1.223,2.88,1.68
		C313.138,234.536,318.674,237.909,322.32,242.339z M324.59,217.479c1.63,4.105,3.378,8.25,4.92,12.594
		C327.968,225.729,326.219,221.585,324.59,217.479z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M347.04,260.579c-0.192-0.127-0.39-0.25-0.72-0.24
		c-5.53,7.75-9.505,17.055-14.399,25.44c-2.588,4.939,1.082,16.146-2.641,18.96c0.022-1.702-1.554-1.806-1.92-3.12
		c-0.08,0-0.16,0-0.24,0c0,0.4,0,0.8,0,1.2c-0.233,0.007-0.186,0.294-0.239,0.48c-0.524,0.127-1.553,0.581-1.681-0.24
		c-0.562-0.078-0.232-1.047-0.72-1.2c0.127-1.007-0.419-1.341-0.48-2.16c-0.235-1.364-0.559-2.641-0.96-3.84
		c-0.154-0.885-0.246-1.833-0.72-2.4c-0.704-3.776-1.695-7.265-2.64-10.8c-0.257-1.263-0.544-2.496-1.2-3.36
		c-0.683-5.718-3.78-9.02-3.84-15.36c-0.222-1.572-1.303-3.181-0.72-4.8c0.44-0.999,1.686-1.194,2.159-2.16
		c0.899-0.758,0.679-1.69,0.721-3.6c0-0.32,0-0.64,0-0.96c-0.843-0.043-0.79,0.811-1.44,0.96c-0.606,0.593-1.456,0.944-1.92,1.68
		c-0.08,0-0.16,0-0.24,0c-0.477,0.003-0.305,0.655-0.96,0.48c-0.262,0.539-1.178,0.422-1.439,0.96
		c-0.431-0.03-0.507,0.294-0.96,0.24c-0.457,0.103-0.604,0.517-1.2,0.48c0,0.08,0,0.16,0,0.24c0.08,0,0.16,0,0.24,0
		c1.29,7.642-2.126,19.747,0.72,25.2c0.764,1.464-0.108,1.616-0.72,3.36c-0.205-0.044-0.227,0.093-0.24,0.24c0.08,0,0.16,0,0.24,0
		c0.756-0.523,1.907-0.653,2.399-1.44c0.703,0.063,0.737-0.542,1.44-0.48c0.24,0.001,0.285-0.194,0.479-0.24
		c0.109,0.108,0.192,0.216,0.266,0.322c0.024,0.036,0.045,0.072,0.065,0.107c0.04,0.07,0.072,0.14,0.099,0.209
		c0.015,0.041,0.033,0.082,0.044,0.122c0.024,0.091,0.038,0.182,0.039,0.271c-0.009,0.054-0.016,0.106-0.032,0.168
		c-0.393,0.648-0.938,1.142-1.681,1.44c-0.714,0.646-1.567,1.152-2.64,1.44c0.001,0.241-0.194,0.286-0.24,0.48c0.08,0,0.16,0,0.24,0
		c0.351,0.85,0.716,1.684,1.2,2.4c0.08,0,0.16,0,0.24,0s0.16,0,0.239,0c0.331,0.01,0.527-0.113,0.721-0.24
		c2.765-0.718,4.123,1.188,1.68,1.92c-0.283,0.117-0.544,0.256-0.72,0.48c-0.32,0-0.641,0-0.96,0c1.291,2.552,2.725,5.012,4.08,7.92
		c1.092,2.344,3.201,5.301,3.6,8.16c0.288,2.063-0.679,6.233-1.2,10.08c-0.809,5.968-1.128,11.373-1.439,18.24
		c-0.165,3.617-0.191,8.387-0.48,11.28c0.194-0.045,0.239-0.241,0.48-0.24c0.961-2.239,2.516-3.884,3.359-6.24
		c1.331-1.709,2.189-3.891,3.12-6c1.213-2.787,2.532-5.468,3.601-8.4c0.536-0.664,0.934-1.466,1.199-2.4
		c0.125-0.355,0.355-0.605,0.48-0.96c0.526-1.473,1.162-2.837,1.439-4.56c0.532-0.834,0.794-3.199,2.4-2.16
		c0.315,0.085,0.378,0.422,0.72,0.48c-0.026,1.19,0.236,1.179,0,2.16c0.605,0.43,1.599-0.356,2.16-0.72
		c1.851-0.87,3.253-2.188,4.08-4.08c0.914-1.485,1.246-3.554,1.92-5.28c0.241-0.799,0.432-1.649,0.72-2.4
		c0.537-1.384,0.537-3.304,1.2-4.56c0.322-1.118,0.818-2.062,1.2-3.12c0.188-1.091,0.888-1.673,0.96-2.88
		c0.225-0.176,0.363-0.437,0.48-0.72c0.21-0.669,0.574-1.186,0.72-1.92c0.348-0.373,0.32-1.12,0.72-1.44
		c0.193-1.007,0.844-1.557,0.96-2.64c0.615-0.825,1.094-1.786,1.44-2.88c0.127-0.193,0.25-0.39,0.24-0.72
		c0.536-0.664,0.934-1.466,1.199-2.4c2.622-5.782,1.377-14.575,0-20.88C351.19,261.39,349.053,261.047,347.04,260.579z
		 M349.2,282.899c-0.642-0.071,0.639,0.931-0.479,0.72c0.477,0.554-0.061,0.267-0.24,0.72c-0.791,3.05-2.663,7.097-4.32,10.08
		c-0.908,0.028-0.356-1.403-1.199-1.44c0-0.08,0-0.16,0-0.24c0.044-0.204-0.094-0.227-0.24-0.24c-0.497,0.188-0.9-2.852-0.48-3.6
		c-0.34,0.021-0.68,0.04-0.72-0.24c-0.295-0.104-0.493-0.307-0.48-0.72c-0.551-0.489-0.086-1.993-0.239-2.88c0.08,0,0.16,0,0.239,0
		c0-0.08,0-0.16,0-0.24c0-1.04,0-2.08,0-3.12c-0.079,0-0.159,0-0.239,0c0-0.16,0-0.32,0-0.48c-3.246-1.019-1.492,9.086-1.681,11.28
		c0.046,0.194,0.241,0.239,0.24,0.48c0.229,0.49,0.49,0.95,0.72,1.44c0.362,0.438,0.255,1.345,0.721,1.68
		c0.109,1.25,0.219,2.501,0.479,3.6c-0.554,0.726-0.084,2.476-0.24,3.6c-0.351,0.689-0.324,1.755-0.96,2.16c0,0.08,0,0.16,0,0.24
		c-0.07,0.49-0.649,0.471-0.72,0.96c-0.917,3.724-3.506,7.053-5.76,8.64c0-1.92,0-3.84,0-5.76c-1.396,0.252-2.805,1.611-4.32,0.96
		c-0.954-0.383-1.141-1.845-0.479-2.64c0.368-1.472,1.312-2.368,1.68-3.84c0.826-0.293,0.613-1.627,1.44-1.92
		c0.07-0.49,0.649-0.471,0.72-0.96c0-0.24,0-0.48,0-0.72c0-0.24,0-0.48,0-0.72c0-0.16,0-0.32,0-0.48
		c-0.152-0.808,0.309-2.229-0.24-2.64c0-0.72,0-1.44,0-2.16c0.554-0.726,0.085-2.476,0.24-3.6c0-0.32,0-0.64,0-0.96
		c-0.021-0.341-0.04-0.68,0.24-0.72c-0.074-0.874,0.038-1.562,0.239-2.16c0-0.24,0-0.48,0-0.72c0.467-1.133,0.823-2.377,1.44-3.36
		c1.344-2.496,3.038-4.642,4.32-7.2c0.08,0,0.159,0,0.239,0c0.638-0.963,1.283-1.917,1.92-2.88c0-0.08,0-0.16,0-0.24
		c0.08,0,0.16,0,0.24,0c0.638-0.963,1.283-1.917,1.92-2.88c0-0.08,0-0.16,0-0.24c0.817-0.542,1.055-1.666,1.681-2.4
		c0.045-0.194,0.24-0.239,0.239-0.48c0.085-0.315,0.423-0.377,0.48-0.72c0.162-0.558,0.798-0.643,0.96-1.2
		c-0.204-0.044-0.227,0.094-0.24,0.24c-0.303-0.319,0.71-1.358,0.96-1.92c0.476,0.181,0.989,1.549,0.721,1.68
		c0.045,0.194,0.24,0.239,0.239,0.48c0,0.08,0,0.16,0,0.24C350.827,268.949,350.673,278.128,349.2,282.899z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M337.44,282.899c-0.267,1.253-1.235,1.805-1.44,3.12
		c-0.127,0.193-0.25,0.39-0.239,0.72c-0.039,0.279,0.053,0.427,0.239,0.48c0.061-1.14,0.926-1.474,0.961-2.64
		c1.184-1.856,1.908-4.171,3.6-5.52c0-0.08,0-0.16,0-0.24C339.341,279.043,338.658,281.875,337.44,282.899z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M264.48,333.299c0.08,0,0.16,0,0.24,0
		c1.46-1.26,2.82-2.62,4.08-4.08c-0.08,0-0.16,0-0.24,0c0.38-1.419-0.842-1.238-0.72-2.4c0.108-5.438-1.529-9.961-3.36-16.32
		c-0.809-2.808-1.674-11.128-3.84-11.76c-1.333-0.389-2.626,0.548-3.6,0.96c-1.705-4.262-4.708-1.625-5.04,2.64
		c-0.694,8.917,1.111,16.811,3.12,23.76c0.491,1.697,1.491,3.678,1.44,4.8c-0.086,1.911-2.34,4.84-3.36,6.96
		c-1.012,2.104-3.566,4.331-1.44,6.72c0.007,0.233,0.294,0.186,0.48,0.24c0,0.08,0,0.16,0,0.24c1.141-0.94,2.18-1.98,3.12-3.12
		c0.08,0,0.16,0,0.24,0c0.343-0.058,0.405-0.395,0.72-0.48c0.815-1.185,2.022-1.978,3.12-2.88c0.495-0.865,1.425-1.295,1.92-2.16
		c0.08,0,0.16,0,0.24,0c0.342-0.058,0.404-0.395,0.72-0.48c0.106-0.214,0.211-0.429,0.48-0.48
		C263.254,334.633,264.191,334.29,264.48,333.299z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M284.4,388.74c-0.917-2.203-2.955-3.285-4.08-5.28
		c-0.08,0-0.16,0-0.24,0c-0.346-0.134-0.586-0.375-0.72-0.72c-1.002-1.318-1.655-2.985-3.12-3.84c-0.193-0.127-0.39-0.25-0.72-0.24
		c-0.193-0.127-0.39-0.25-0.72-0.24c-0.664-0.536-1.466-0.934-2.4-1.2c-0.193-0.127-0.39-0.25-0.72-0.24
		c-1.306-0.854-2.746-1.574-4.32-2.16c-0.478-0.322-1.143-0.458-1.68-0.72c-0.16,0-0.32,0-0.48,0c-0.319-0.48-1.065-0.535-1.68-0.72
		c-0.06,0.069-0.095,0.156-0.12,0.25c-0.043,0-0.074-0.013-0.12-0.009c0.031,0.054,0.062,0.109,0.092,0.164
		c-0.103,1.222,1.836,3.862,2.788,5.356c3.412,5.357,7.827,11.405,14.16,11.76c-3.02-0.775-5.774-3.519-4.8-6.24
		C279.181,385.168,281.401,388.366,284.4,388.74z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M451.921,404.819c0.716,0.404,1.289,0.951,2.16,1.2
		c0.275,0.844,1.36,0.879,1.68,1.68c0.315,0.084,0.378,0.422,0.72,0.48c1.405,0.995,2.425,2.376,4.08,3.12
		c-0.32-1.12-0.371-2.509-0.96-3.36c-0.814-1.185-2.089-1.911-3.6-2.4c-0.57-0.39-1.381-0.54-1.92-0.96
		c-0.76,0.039-0.803-0.638-1.681-0.48c-0.41-0.39-0.873-0.727-1.68-0.72C450.674,404.306,451.102,404.759,451.921,404.819z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M420.961,688.74c-0.851,1.79-2.741,2.539-2.88,5.04
		c0.006,0.233,0.293,0.187,0.479,0.24c0-0.08,0-0.16,0-0.24c0.977-0.063,1.629-0.451,2.4-0.72c0.999-0.602,0.245-2.955,0.479-4.32
		C421.28,688.74,421.121,688.74,420.961,688.74z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M251.76,246.179c1.305-1.015,2.042-2.598,3.12-3.84
		c3.041-7.126,8.506-11.828,15.681-14.82c0.371-0.103,0.731-0.215,1.119-0.3c-0.152,0.002-0.303,0.027-0.456,0.037
		c0.232-0.092,0.46-0.188,0.696-0.277c-3.394-0.08-6.585,1.873-9.36,3.36c-4.376,2.345-7.707,5.7-11.28,8.88
		c-0.941,2.099-2.066,4.014-2.88,6.24c-0.271-2.353,2.83-8.325-0.96-9.36c0.269-2.931,0.675-5.725,0.96-8.64
		c0.131-0.349-0.281-1.24,0.24-1.2c0.611-5.47,4.738-7.422,6.48-11.76c0.854-0.586,1.296-1.584,1.92-2.4
		c0.003-0.477,0.655-0.305,0.48-0.96c0.749-0.611,1.173-1.546,1.68-2.4c0.258-0.622,0.868-0.893,0.96-1.68
		c1.286-1.194,2.185-2.775,3.36-4.08c0-0.08,0-0.16,0-0.24c1.906-1.614,3.269-3.771,5.28-5.28c0.046-0.194,0.241-0.239,0.24-0.48
		c-2.599-1.097-6.381,4.509-8.64,6.72c-1.056,1.033-2.112,2.137-3.16,3.212c-0.797,0.688-1.591,1.369-2.36,2.067
		c-0.305,0.277-0.605,0.537-0.904,0.789c-0.095,0.08-0.191,0.157-0.287,0.235c-0.213,0.176-0.426,0.352-0.642,0.524
		c-0.071,0.057-0.145,0.114-0.216,0.171c-0.477,0.376-0.972,0.761-1.494,1.169c-0.445,0.191-0.884,0.39-1.257,0.711
		c-5.827,5.022-6.599,14.016-7.68,22.56c-0.519,4.098-2.076,8.092-3.12,12c-0.546,2.043-0.997,4.079-1.491,6.022
		c-0.23,0.787-0.451,1.579-0.657,2.379c-0.349,1.167-0.735,2.289-1.2,3.329c-0.008-0.046-0.017-0.091-0.025-0.138
		c-0.414-5.366-0.867-10.692-1.187-16.152c0-0.24,0-0.48,0-0.72c0-2.96,0-5.92,0-8.88c-2.806,10.322-3.402,24.695-0.24,35.041
		c-0.215,0.825-0.584,1.496-0.96,2.16c0.084,0.795,0.719,1.042,1.2,1.44c0.08,0,0.16,0,0.24,0c0-0.16,0-0.32,0-0.48
		c0.269-0.771,0.657-1.423,0.72-2.4c0.803-1.037,1.251-2.429,1.92-3.6c0.213-0.507,0.731-0.709,0.72-1.44
		c1.198-1.282,2.032-2.928,3.12-4.32c1.6-2.24,3.53-4.15,5.04-6.48c2.166-1.754,3.492-4.348,5.28-6.48
		C251.76,246.339,251.76,246.259,251.76,246.179z M270.56,227.52c-9.14,2.533-13.186,10.159-17.819,17.197
		C257.372,237.676,261.42,230.052,270.56,227.52z M242.314,247.202c-0.343,0.813-0.664,1.678-0.964,2.568
		C241.651,248.879,241.971,248.015,242.314,247.202z M239.826,257.205c0.375-0.863,0.721-1.754,1.041-2.671
		C240.546,255.451,240.2,256.343,239.826,257.205L239.826,257.205c-0.064,0.148-0.13,0.295-0.196,0.441
		c0,0.002-0.002,0.004-0.002,0.006c-0.25,0.265-0.579,0.204-0.106-0.193c0.75-3.63,2.019-6.74,2.819-10.32
		c0.003-0.007,0.006-0.015,0.009-0.022c0.028-0.067,0.055-0.137,0.083-0.202c0-0.001,0-0.002,0.001-0.003
		c0.187,0.038,0.157,0.292,0.208,0.467C241.817,250.769,240.617,253.782,239.826,257.205z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M304.561,270.179c-0.397-0.803-0.849-1.551-0.72-2.88
		c-0.397-0.259-0.397-2.141,0-2.4c-0.107-1.067,0.17-1.75,0.479-2.4c-0.03-0.43,0.294-0.506,0.24-0.96
		c-0.462-0.4-0.307-2.213,0-2.64c-0.932,0.268-1.229,1.171-2.16,1.44c0.044,0.204-0.094,0.226-0.24,0.24
		c-1.419,0.3-1.715-0.524-2.399-0.96c0-0.08,0-0.16,0-0.24c-0.171-1.691,0.896-2.145,1.439-3.12c-0.214-0.147-0.429-0.29-0.479,0
		c-3.413,3.438-1.763,13.09,0.479,17.04c1.86-0.1,3.021,0.499,4.32,0.96C306.05,272.205,303.387,272.079,304.561,270.179z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M301.92,301.859c-3.692-0.325-3.255,6.119-5.52,7.92
		c-2.588,2.052-7.008,2.272-7.2,6.72c0,0.08,0,0.16,0,0.24c0.146,0.014,0.284,0.036,0.24,0.24c0.651-0.149,1.904,0.305,2.16-0.24
		c0.592,0.032,1.156,0.037,1.439-0.24c0.331,0.01,0.527-0.113,0.721-0.24c0.999-0.121,1.4-0.839,2.399-0.96
		c0.204-0.356,0.604-0.516,1.2-0.48c0.06-0.302,0.478-0.242,0.48,0c0.301-0.098,0.49-0.31,0.479-0.72
		c1.189-2.309,7.154-3.923,6.96-7.68c-0.056-1.081-0.827-1.523-1.439-2.4C303.149,303.03,303.347,301.985,301.92,301.859z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M325.44,361.14c0.541-0.179,0.094-1.346,0.24-1.92
		c0-0.24,0-0.48,0-0.72c0-0.56,0-1.12,0-1.68c0.08,0,0.16,0,0.24,0c0-0.08,0-0.16,0-0.24c0-1.52,0-3.04,0-4.56c0-0.48,0-0.96,0-1.44
		c0-0.32,0-0.64,0-0.96c0-0.32,0-0.64,0-0.96c0-0.8,0-1.6,0-2.4c0-0.48,0-0.96,0-1.44c0-0.32,0-0.64,0-0.96
		c-0.398-0.414-0.398-2.466,0-2.88c-0.057-1.176,0.423-1.817,0.239-3.12c0.364-2.116,1.412-3.548,1.92-5.52
		c-1.071,0.451-1.654,3.463-2.399,5.04c-0.344,0.857-0.854,1.547-0.96,2.64c-0.536,0.664-0.935,1.466-1.2,2.4
		c-0.996,2.284-2.382,4.178-3.36,6.48c-0.947,0.973-1.561,2.28-2.64,3.12c-0.08,0-0.16,0-0.24,0s-0.16,0-0.24,0
		c-0.709,1.725,1.063,2.896,2.4,3.36c-0.667,5.813-1.938,11.022-3.6,15.84c-2.403,1.917-3.868,4.772-6,6.96
		c-0.014,0.449,0.185,0.508,0.479,0.24c0.558-0.163,0.643-0.797,1.2-0.96c0.162-0.558,0.798-0.643,0.96-1.2
		c2.528-1.151,4.303-3.058,6.48-4.56c0.908-0.692,2.011-1.189,2.64-2.16C324.81,368.668,325.538,365.317,325.44,361.14z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M328.561,378.899c-0.241,0-0.286,0.194-0.48,0.24
		c0,0.16,0,0.32,0,0.48c0.47,1.01,2.18-0.628,2.641-1.2c0.654,0.175,0.482-0.477,0.96-0.48c0.982-0.857,2.592-1.088,2.64-2.88
		c0-0.08,0-0.16,0-0.24c0.188-0.053,0.279-0.201,0.24-0.48c-1.756,0.726-3.621,3.418-5.76,4.56
		C328.721,378.899,328.641,378.899,328.561,378.899z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M335.761,393.299c-2.896-0.88-0.955-6.128-1.92-9.36
		c-0.398-1.122-0.298-2.742-0.48-4.08c-1.55,0.45-2.274,1.726-3.84,2.16c-0.36,0.28-0.587,0.693-1.2,0.72
		c-0.003,0.317-0.421,0.219-0.479,0.48c-0.553,0.568-0.086,2.154-0.24,3.12c0,1.28,0,2.56,0,3.84
		c-0.761,3.559-1.257,7.383-1.68,11.28c-0.08,0-0.16,0-0.24,0c-0.086,1.411,0.597,0.19,1.2,0.24c0.842-0.758,1.972-1.228,2.64-2.16
		c0.08,0,0.16,0,0.24,0c1.463-0.856,2.745-1.894,3.84-3.12c0.688-0.671,1.655-1.065,2.16-1.92c0.703-0.016,0.892-0.548,1.2-0.96
		C336.611,393.408,335.72,393.82,335.761,393.299z"/>
			<polygon fillRule="evenodd" clipRule="evenodd" fill="#E8E735" points="402.296,609.838 402.295,609.836 402.293,609.834 	" />
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M421.44,644.1c4.569,0.084,0.953-7.479,0.24-10.08
		c-0.261-0.059-0.163-0.477-0.48-0.479c-0.033-1.406-0.926-1.954-0.96-3.36c-0.224-0.176-0.363-0.437-0.479-0.72
		c0.03-0.431-0.294-0.506-0.24-0.96c-0.69-0.509-0.621-1.779-1.439-2.16c-1.445-3.035-3.623-5.338-5.521-7.92
		c-0.414-0.786-1.267-1.134-1.68-1.92c-1.067-0.772-2.125-1.555-2.88-2.64c-1.202-0.719-2.037-1.804-3.12-2.641
		c-1.084-0.756-1.799-1.881-3.12-2.399c-0.107-0.396-1.033-1.177-1.2-0.721c0.611,0.534,1.182,1.124,1.734,1.736
		c0.004,0.005,0.009,0.012,0.012,0.017c0.109,0.158,0.233,0.327,0.366,0.501c0.038,0.051,0.078,0.103,0.117,0.153
		c0.111,0.144,0.228,0.292,0.351,0.443c0.04,0.051,0.079,0.1,0.121,0.151c0.15,0.186,0.308,0.377,0.47,0.572
		c0.157,0.19,0.32,0.388,0.485,0.586c0.05,0.06,0.101,0.121,0.151,0.182c4.978,7.155,7.661,17.405,12.272,25.179
		C417.893,639.729,419.608,644.066,421.44,644.1z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M423.121,761.46c0-0.479,0-0.96,0-1.439
		c-0.753-0.112-0.677,0.604-0.96,0.96c-0.682,0.999-1.331,2.029-1.921,3.12c-0.411,0.548-0.707,1.212-1.199,1.68
		c-0.736,1.983-2.221,3.22-2.881,5.28c-0.759,0.601-1.079,1.64-1.439,2.64c-0.348,0.373-0.32,1.12-0.72,1.44
		c-0.001,0.959-0.479,1.44-0.48,2.399c0,1.12,0,2.24,0,3.36c0.112,2.527,0.746,4.534,0.72,7.2c0.326,0.074,0.199,0.601,0.24,0.96
		c0,1.52,0,3.04,0,4.56c-0.438,0.522-0.23,1.689-0.96,1.92c-0.026,0.613-0.804,0.477-1.439,0.48c-0.646-1.436,0.185-4.345-0.48-5.76
		c0.032-3.552-0.643-6.398-0.96-9.601c0-0.96,0-1.92,0-2.88c1.504-8.576,6.845-13.315,10.08-20.16c0.08,0,0.16,0,0.24,0
		c0.397-0.802,0.802-1.598,1.2-2.399c0.217-0.983,1.224-1.177,1.68-1.92c-0.017-1.217,0.412-1.988,0.24-3.36
		c0.399-1.364,0.399-4.396,0-5.76c0-0.08,0-0.16,0-0.24c-0.099-1.022-0.111-2.129-0.48-2.88c-0.078-0.882,0.205-2.125-0.24-2.641
		c-0.153-0.966,0.313-2.552-0.239-3.12c0.071-1.431-0.099-2.621-0.48-3.6c0-0.96-0.479-1.44-0.479-2.4
		c-0.315-0.725-0.165-1.915-0.48-2.64c-0.15-0.729,0.308-2.067-0.24-2.4c0-0.079,0-0.159,0-0.239c0-1.04,0-2.08,0-3.12
		c-0.131-0.349,0.28-1.24-0.24-1.2c0-0.16,0-0.32,0-0.479c0-0.4,0-0.801,0-1.2c-0.029-3.344,0.137-8.118-0.239-10.32
		c0-0.08,0-0.16,0-0.24c0.399-3.44,0.399-8.559,0-12c-0.279-0.039-0.428,0.053-0.48,0.24c-1.207,0.073-1.896,0.664-2.88,0.96
		c-0.73-0.011-0.934,0.507-1.44,0.72c-0.269,1.411-1.302,2.059-1.439,3.601c-0.398,0.414-0.398,2.466,0,2.88
		c-0.067,0.547,0.058,0.902,0.479,0.96c0.06-0.261,0.478-0.163,0.48-0.48c0.16,0,0.32,0,0.479,0c1.168,0.909,0.8,3.588-0.479,4.08
		c-0.709,0.571-1.69,0.869-2.4,1.44c-0.654,2.191-4.693,4.864-1.92,7.439c0.703,0.063,0.737-0.542,1.44-0.479
		c0.54-0.66,1.698-0.702,2.399-1.2c0.096,0.011,0.187,0.011,0.273,0.004c-0.001,0-0.002,0.001-0.003,0.001
		c0.563,0.012,1.144,0.04,1.41-0.245c-0.043-0.545,0.811-1.398,0.96-0.96c0.032,1.462,0.325,2.034,0,3.36c-0.4,5.44-0.4,12.56,0,18
		c0.226,4.814,0.431,9.648,1.439,13.68c-2.585,6.189-4.601,11.753-8.64,16.561c-1.361,1.62-3.277,3.331-3.6,5.04
		c-0.601,3.176,1.395,5.025,1.439,7.439c0.056,2.982-1.528,4.938-1.2,8.4c-0.552,0.568-0.086,2.154-0.239,3.12c0,1.2,0,2.399,0,3.6
		c0.15,0.729-0.308,2.067,0.239,2.4c0.156,2.644,0.933,4.668,1.2,7.2c0,1.76,0,3.52,0,5.279c0,0.801,0,1.601,0,2.4
		c0.204-0.044,0.227,0.094,0.24,0.24c2.56-0.269,6.464,0.35,9.6,0c0.577-0.064,2.636,0.406,1.681-0.96c0-0.08,0-0.16,0-0.24
		c0-1.44,0-2.88,0-4.32c0.33-0.31,0.237-1.042,0.239-1.68c0.551-0.489,0.087-1.993,0.24-2.88c0-0.24,0-0.48,0-0.72
		c0-0.16,0-0.32,0-0.48c0.397-0.336,0.397-2.304,0-2.64c0-0.08,0-0.16,0-0.24c0.068-0.388-0.227-0.413-0.24-0.72
		c-0.385-1.615-0.967-3.033-0.72-5.28c0.482-1.197,0.86-2.5,1.44-3.601c-0.063-0.702,0.542-0.737,0.479-1.439
		c0.225-0.176,0.364-0.437,0.48-0.72c0.199-0.602,0.657-0.943,0.72-1.681c0.501-0.938,1.086-1.794,1.68-2.64
		c0.215-3.226,1.077-5.804,1.44-8.88c0.586-0.837,0.044-2.597-0.48-3.12C424.588,766.394,423.802,763.979,423.121,761.46z"/>
		</g>
		<g id="mouth1" display="none">
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M269.198,360.368L269.198,360.368L269.198,360.368
		c0.027-0.063,0.06-0.121,0.082-0.188c-0.079-0.023-0.175-0.03-0.261-0.045c-0.001,0-0.002,0-0.003,0l0,0
		c-0.046-0.008-0.084-0.024-0.12-0.044c0.036,0.02,0.074,0.036,0.12,0.044c-0.062-0.063-0.132-0.128-0.216-0.194
		c0.002,0.064,0.027,0.105,0.064,0.134c-0.037-0.03-0.062-0.07-0.064-0.134c-0.928-0.128-1.171,0.428-2.16,0.24
		c-2.623,0.497-5.426,0.813-8.16,1.2c-0.08,0-0.16,0-0.24,0c-0.808,0.152-2.229-0.309-2.64,0.24c-0.08,0-0.16,0-0.24,0
		s-0.16,0-0.24,0c-2.492-0.256-5.348,0.146-4.32,2.64c0.577,0.55,2.762,0.749,3.6,0.24c0.08,0,0.16,0,0.24,0c0.08,0,0.16,0,0.24,0
		c2.017,0.017,4.089,0.089,5.28-0.72c0.08,0,0.16,0,0.24,0C261.99,362.696,270.32,362.22,269.198,360.368L269.198,360.368z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M254.4,355.14c-1.685,0.155-3.275,0.405-4.56,0.96
		c-0.08,0-0.16,0-0.24,0c-1.047,0.072-1.99,0.25-2.64,0.72c-0.08,0-0.16,0-0.24,0c-2.103,0.457-4.212,0.908-6,1.68
		c-0.08,0-0.16,0-0.24,0c-1.266,0.254-2.579,0.461-3.6,0.96c-0.08,0-0.16,0-0.24,0c-1.604,0.183-2.597,1.146-2.4,2.4
		c0.459,2.928,3.25,0.521,4.56,0c0.071-0.028,0.152-0.053,0.225-0.081c0.002-0.001,0.004-0.002,0.007-0.002
		c1.101-0.352,2.232-0.673,3.393-0.966c0.001,0,0.002,0,0.004-0.001c5.002-1.122,11.5-1.734,13.892-3.27
		c0.279-0.202,0.644-0.317,1.2-0.24c1.609-0.23,3.222-0.458,4.56-0.96c0.31-0.331,1.043-0.237,1.68-0.24
		c1.467-0.133,2.907-0.293,4.08-0.72c0.349-0.371,1.229-0.211,1.92-0.24c0.729-0.151,2.067,0.307,2.4-0.24
		c0.398-0.402,1.409-0.191,2.16-0.24c1.731-0.029,3.228-0.292,4.56-0.72c1.096-0.344,2.295-0.585,3.6-0.72
		c1.293-0.226,2.471-0.568,3.12-1.44c-0.155-1.506-2.289-2.552-3.6-1.44c-0.08,0-0.16,0-0.24,0c-3.228,0.852-6.894,1.266-10.32,1.92
		c-0.08,0-0.16,0-0.24,0c-0.808,0.152-2.229-0.309-2.64,0.24c-0.08,0-0.16,0-0.24,0c-2.316,0.164-4.324,0.636-6.24,1.2
		c-0.08,0-0.16,0-0.24,0c-2.566,0.314-4.969,0.792-7.2,1.44C254.561,355.14,254.48,355.14,254.4,355.14z M242.433,360.809"/>
		</g>
		<g id="eyebrows1" display="none">
			<path display="inline" fillRule="evenodd" clipRule="evenodd" fill="#7A7C7D" d="M265.68,282.179c4.929-1.871,9.078-4.522,14.4-6
		c2.044-0.755,4.345-1.255,7.2-1.2c0.64-0.642,5.117,0.196,2.64-0.72c-3.686-0.314-6.229-1.771-10.56-1.44
		c-6.532,0.748-10.877,3.683-13.68,8.16c0,0.08,0,0.16,0,0.24C265.91,281.667,264.961,282.021,265.68,282.179z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M307.681,275.699c-0.525-0.674-0.958-1.442-2.16-1.44
		c-1.299-0.461-2.46-1.06-4.32-0.96c-0.592,0.032-1.156,0.036-1.439-0.24c-0.333-0.547-1.671-0.089-2.4-0.24
		c-0.814-0.065-1.945,0.186-2.4-0.24c-0.711-0.408-1.976-0.264-2.88-0.48c-1.048,0.008-1.871-0.208-2.64-0.48
		c-1.162-0.519-2.618-0.742-4.08-0.96c-0.592,0.032-1.156,0.037-1.44-0.24c-2.503-0.501-6.433-0.354-8.4,0.48
		c-0.587,0.213-1.041,0.559-1.92,0.48c-6.359,1.961-10.007,6.633-12,12.96c0.146,0.666,1.433,1.431,2.16,0.72c0.08,0,0.16,0,0.24,0
		c2.49-0.63,5.294-0.946,6.96-2.4c0.08,0,0.16,0,0.24,0c2.374-0.746,4.301-1.939,6.24-3.12c0.08,0,0.16,0,0.24,0
		c1.765-0.635,3.776-1.024,5.52-1.68c1.082-0.358,2.34-0.54,3.84-0.48c4.72,0,9.44,0,14.16,0c0.08,0,0.16,0,0.24,0
		c1.046,0.399,3.754,0.399,4.8,0c0.08,0,0.16,0,0.24,0C307.28,277.219,307.783,276.762,307.681,275.699z M267.601,281.4
		c4.321-0.876,7.22-3.174,10.999-4.593C274.823,278.228,271.922,280.525,267.601,281.4z M287.28,274.979
		c-2.855-0.055-5.155,0.444-7.2,1.2c-4.622,1.284-8.36,3.451-12.491,5.224c-0.079,0.016-0.149,0.041-0.229,0.056
		c-0.097,0.018-0.199,0.058-0.303,0.107c-0.025,0.012-0.05,0.027-0.075,0.04c-0.089,0.047-0.18,0.098-0.271,0.156
		c-0.343,0.14-0.681,0.284-1.031,0.417c-0.719-0.158,0.229-0.513,0-0.96c0-0.08,0-0.16,0-0.24c2.803-4.478,7.148-7.412,13.68-8.16
		c4.331-0.331,6.874,1.126,10.56,1.44C292.397,275.176,287.92,274.338,287.28,274.979z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" fill="#7A7C7D" d="M231.84,286.02
		c1.762-0.158,4.157,0.317,5.52-0.24c-3.48-1.228-7.815-3.698-11.76-3.12c-2.04,0.299-5.045,3.01-6.96,4.56
		c-2.4,1.944-4.468,3.817-6.48,5.04c1.695-0.545,3.068-1.412,4.8-1.92c1.389-0.531,2.533-1.307,4.08-1.68
		c1.569-0.911,3.072-1.889,5.04-2.4C227.975,285.869,230.021,285.918,231.84,286.02z"/>
			<path display="inline" d="M209.037,291.193c0.029-0.016,0.056-0.035,0.085-0.051c-0.414,0.193-0.815,0.399-1.207,0.614
		c-0.565,0.267-1.145,0.521-1.755,0.743c-0.08,0-0.16,0-0.24,0c-1.126,0.394-1.461,1.579-1.68,2.88
		c-0.117,0.501,0.338,0.71,0.81,0.833l3.616,0.226c0.042-0.036,0.097-0.058,0.134-0.099c0.08,0,0.16,0,0.24,0
		c1.126-0.394,2.305-0.735,3.12-1.44c0.676-0.205,0.985-0.775,1.92-0.72c1.126-0.394,2.305-0.735,3.12-1.44c0.08,0,0.16,0,0.24,0
		c1.875-0.281,3.568-1.666,5.362-2.815c-0.171,0.091-0.339,0.185-0.508,0.278c0.169-0.093,0.337-0.188,0.509-0.279
		c0,0,0.001,0,0.001,0s0,0,0,0c0.205-0.109,0.414-0.213,0.637-0.304c0.119-0.125,0.271-0.218,0.41-0.324l0,0
		c0.903-0.684,2.203-0.971,3.677-1.084c3.266-0.268,6.555,0.712,10.553,0.208c0.08,0,0.16,0,0.24,0c0.8,0,1.6,0,2.4,0
		c0.655,0.175,0.483-0.477,0.96-0.48c-0.782-4.658-5.22-5.66-9.6-6.72c-1.017,0.057-1.699-0.221-2.4-0.48
		c-0.755-0.445-1.887-0.513-3.12-0.48c-0.4,0-0.8,0-1.2,0c-0.286,0.107-0.569,0.215-0.851,0.326
		c-0.04,0.016-0.079,0.031-0.118,0.047c-0.9,0.357-1.76,0.755-2.497,1.276c-4.641,2.694-8.031,6.64-12.772,9.234 M231.84,286.02
		c-1.819-0.102-3.866-0.151-5.76,0.24c-1.968,0.511-3.471,1.489-5.04,2.4c-1.546,0.374-2.69,1.149-4.08,1.68
		c-1.732,0.508-3.105,1.375-4.8,1.92c2.012-1.223,4.08-3.096,6.48-5.04c0.542-0.438,1.176-0.971,1.846-1.515
		c-0.137,0.104-0.28,0.201-0.406,0.315c0.144-0.129,0.303-0.243,0.46-0.359c0.083-0.067,0.172-0.129,0.256-0.196
		c-0.076,0.053-0.143,0.114-0.217,0.168c0.229-0.169,0.464-0.332,0.696-0.497c0.367-0.251,0.715-0.52,1.028-0.825
		c0.146-0.143,0.296-0.283,0.417-0.451c-0.121,0.168-0.27,0.308-0.416,0.451c1.169-0.833,2.339-1.511,3.295-1.651
		c3.945-0.578,8.28,1.893,11.76,3.12C235.998,286.336,233.603,285.861,231.84,286.02z"/>
		</g>
		<g id="mouth2" display="none">
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M269.198,361.368L269.198,361.368L269.198,361.368
		c0.027-0.063,0.06-0.121,0.082-0.188c-0.079-0.023-0.175-0.03-0.261-0.045c-0.001,0-0.002,0-0.003,0l0,0
		c-0.046-0.008-0.084-0.024-0.12-0.044c0.036,0.02,0.074,0.036,0.12,0.044c-0.062-0.063-0.132-0.128-0.216-0.194
		c0.002,0.064,0.027,0.105,0.064,0.134c-0.037-0.03-0.062-0.07-0.064-0.134c-0.928-0.128-1.171,0.428-2.16,0.24
		c-2.623,0.497-5.426-0.187-8.16,0.2c-0.08,0-0.16,0-0.24,0c-0.808,0.152-2.229-0.309-2.64,0.24c-0.08,0-0.16,0-0.24,0
		s-0.16,0-0.24,0c-2.492-0.256-8.348,3.146-7.32,5.64c0.577,0.55,5.762-2.251,6.6-2.76c0.08,0,0.16,0,0.24,0c0.08,0,0.16,0,0.24,0
		c2.017,0.017,4.089,0.089,5.28-0.72c0.08,0,0.16,0,0.24,0C261.99,362.696,270.32,363.22,269.198,361.368L269.198,361.368z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M254.4,354.14c-1.685,0.155-3.275,0.405-4.56,0.96
		c-0.08,0-0.16,0-0.24,0c-1.047,0.072-1.99,1.25-2.64,1.72c-0.08,0-0.16,0-0.24,0c-2.103,0.457-4.212,1.908-6,2.68
		c-0.08,0-0.16,0-0.24,0c-1.266,0.254-2.579,0.461-3.6,0.96c-0.08,0-0.16,0-0.24,0c-1.604,0.183-2.597,1.146-2.4,2.4
		c0.459,2.928,3.25,0.521,4.56,0c0.071-0.028,0.152-0.053,0.225-0.081c0.002-0.001,0.004-0.002,0.007-0.002
		c1.101-0.352,2.232-0.673,3.393-0.966c0.001,0,0.002,0,0.004-0.001c5.002-1.122,11.5-3.734,13.892-5.27
		c0.279-0.202,0.644-0.317,1.2-0.24c1.609-0.23,3.222-0.458,4.56-0.96c0.31-0.331,1.043-0.237,1.68-0.24
		c1.467-0.133,2.907-0.293,4.08-0.72c0.349-0.371,1.229-0.211,1.92-0.24c0.729-0.151,2.067,0.307,2.4-0.24
		c0.398-0.402,1.409-0.191,2.16-0.24c1.731-0.029,3.228,0.708,4.56,0.28c1.096-0.344,2.295,0.415,3.6,0.28
		c1.293-0.226,2.471-0.568,3.12-1.44c-0.155-1.506-2.289-2.552-3.6-1.44c-0.08,0-0.16,0-0.24,0c-3.228,0.852-6.894,0.266-10.32,0.92
		c-0.08,0-0.16,0-0.24,0c-0.808,0.152-2.229-0.309-2.64,0.24c-0.08,0-0.16,0-0.24,0c-2.316,0.164-4.324-0.364-6.24,0.2
		c-0.08,0-0.16,0-0.24,0c-2.566,0.314-4.969,0.792-7.2,1.44C254.561,354.14,254.48,354.14,254.4,354.14z M242.433,361.809"/>
		</g>
		<g id="eyebrows2" display="none">
			<path display="inline" fillRule="evenodd" clipRule="evenodd" fill="#7A7C7D" d="M262.239,280.067
		c5.266-0.248,10.034-1.478,15.552-1.229c2.178-0.083,4.52,0.157,7.216,1.096c0.807-0.411,4.803,1.776,2.732,0.135
		c-3.405-1.443-5.37-3.618-9.589-4.649c-6.441-1.318-11.483,0.121-15.539,3.506c-0.025,0.076-0.049,0.152-0.074,0.228
		C262.616,279.651,261.604,279.694,262.239,280.067z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M304.174,286.956c-0.289-0.804-0.462-1.668-1.605-2.04
		c-1.092-0.842-2.009-1.772-3.809-2.255c-0.572-0.153-1.11-0.325-1.293-0.675c-0.147-0.624-1.561-0.604-2.208-0.974
		c-0.753-0.315-1.906-0.428-2.207-0.974c-0.549-0.609-1.796-0.864-2.588-1.351c-0.998-0.317-1.714-0.779-2.36-1.276
		c-0.942-0.854-2.258-1.519-3.58-2.18c-0.572-0.154-1.11-0.324-1.294-0.675c-2.224-1.254-6.004-2.335-8.134-2.153
		c-0.624,0.021-1.163,0.208-1.974-0.14c-6.654-0.111-11.573,3.196-15.433,8.591c-0.068,0.678,0.917,1.806,1.829,1.355
		c0.076,0.025,0.152,0.05,0.229,0.075c2.563,0.175,5.326,0.746,7.361-0.12c0.076,0.025,0.151,0.05,0.228,0.075
		c2.488,0.028,4.691-0.507,6.901-1.027c0.076,0.025,0.152,0.05,0.228,0.075c1.875-0.055,3.907,0.2,5.769,0.118
		c1.14-0.004,2.393,0.214,3.799,0.737c4.486,1.466,8.973,2.933,13.459,4.399c0.076,0.025,0.152,0.05,0.229,0.075
		c0.87,0.704,3.444,1.545,4.563,1.491c0.076,0.025,0.152,0.05,0.229,0.075C303.321,288.276,303.941,287.998,304.174,286.956z
		 M264.307,279.924c4.379,0.51,7.849-0.774,11.881-0.949C272.157,279.152,268.686,280.434,264.307,279.924z M285.007,279.934
		c-2.697-0.939-5.038-1.179-7.216-1.096c-4.792-0.216-9.019,0.683-13.496,1.085c-0.08-0.009-0.154-0.007-0.235-0.018
		c-0.098-0.013-0.207-0.007-0.321,0.008c-0.027,0.003-0.056,0.01-0.084,0.015c-0.1,0.017-0.201,0.037-0.306,0.064
		c-0.37,0.026-0.735,0.058-1.109,0.076c-0.634-0.374,0.377-0.416,0.298-0.913c0.025-0.076,0.049-0.152,0.074-0.228
		c4.056-3.385,9.098-4.825,15.539-3.506c4.22,1.031,6.184,3.206,9.589,4.649C289.81,281.71,285.814,279.523,285.007,279.934z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" fill="#7A7C7D" d="M232.296,286.5
		c1.708-0.464,4.148-0.416,5.393-1.203c-3.642-0.6-8.342-2.274-12.125-1.015c-1.956,0.651-4.441,3.846-6.055,5.708
		c-2.023,2.334-3.731,4.54-5.499,6.096c1.573-0.833,2.773-1.927,4.391-2.73c1.275-0.766,2.266-1.729,3.723-2.368
		c1.386-1.171,2.694-2.397,4.542-3.245C228.464,287.028,230.487,286.719,232.296,286.5z"/>
			<path display="inline" d="M210.75,295.583c0.026-0.021,0.049-0.044,0.075-0.065c-0.374,0.263-0.732,0.536-1.081,0.816
		c-0.51,0.362-1.036,0.713-1.598,1.039c-0.079,0.014-0.158,0.028-0.236,0.042c-1.04,0.584-1.162,1.81-1.15,3.129
		c-0.027,0.514,0.458,0.64,0.943,0.678l3.6-0.41c0.035-0.042,0.085-0.074,0.115-0.121c0.079-0.014,0.158-0.028,0.236-0.042
		c1.04-0.585,2.141-1.127,2.82-1.964c0.629-0.32,0.834-0.936,1.764-1.045c1.04-0.585,2.141-1.127,2.82-1.963
		c0.079-0.014,0.158-0.028,0.236-0.042c1.797-0.605,3.222-2.265,4.787-3.71c-0.152,0.12-0.302,0.241-0.452,0.363
		c0.15-0.122,0.299-0.244,0.452-0.364c0,0,0.001,0,0.001,0s0,0,0,0c0.182-0.143,0.37-0.282,0.574-0.411
		c0.095-0.144,0.229-0.262,0.347-0.39l0,0c0.77-0.832,1.999-1.342,3.431-1.71c3.169-0.835,6.579-0.446,10.427-1.642
		c0.079-0.014,0.158-0.028,0.236-0.042c0.788-0.14,1.576-0.28,2.363-0.42c0.675,0.058,0.393-0.554,0.861-0.641
		c-1.584-4.449-6.13-4.659-10.627-4.937c-0.992,0.234-1.712,0.08-2.447-0.053c-0.821-0.306-1.947-0.175-3.156,0.073
		c-0.394,0.07-0.788,0.14-1.181,0.21c-0.263,0.155-0.523,0.312-0.781,0.47c-0.036,0.022-0.072,0.044-0.108,0.067
		c-0.824,0.509-1.601,1.051-2.235,1.693c-4.098,3.464-6.746,7.942-10.96,11.326 M232.296,286.5
		c-1.809,0.218-3.833,0.527-5.629,1.244c-1.849,0.848-3.157,2.073-4.542,3.245c-1.457,0.638-2.448,1.602-3.723,2.368
		c-1.617,0.803-2.817,1.897-4.391,2.73c1.768-1.556,3.476-3.762,5.499-6.096c0.457-0.526,0.988-1.162,1.553-1.814
		c-0.117,0.126-0.24,0.247-0.345,0.381c0.119-0.152,0.256-0.292,0.39-0.434c0.07-0.081,0.147-0.158,0.218-0.238
		c-0.065,0.065-0.121,0.137-0.185,0.204c0.196-0.206,0.399-0.408,0.599-0.611c0.318-0.312,0.613-0.637,0.868-0.993
		c0.119-0.166,0.242-0.33,0.331-0.517c-0.089,0.187-0.211,0.351-0.33,0.517c1.005-1.025,2.039-1.896,2.956-2.202
		c3.783-1.259,8.483,0.415,12.125,1.015C236.445,286.085,234.004,286.036,232.296,286.5z"/>
		</g>
		<g id="mouth3">
			<path fillRule="evenodd" clipRule="evenodd" d="M269.198,361.368L269.198,361.368L269.198,361.368
		c0.027-0.063,0.06-0.121,0.082-0.188c-0.079-0.023-0.175-0.03-0.261-0.045c-0.001,0-0.002,0-0.003,0l0,0
		c-0.046-0.008-0.084-0.024-0.12-0.044c0.036,0.02,0.074,0.036,0.12,0.044c-0.062-0.063-0.132-0.128-0.216-0.194
		c0.002,0.064,0.027,0.105,0.064,0.134c-0.037-0.03-0.062-0.07-0.064-0.134c-0.928-0.128-1.171,0.428-2.16,0.24
		c-2.623,0.497-5.426,0.813-8.16,1.2c-0.08,0-0.16,0-0.24,0c-0.808,0.152-2.229-0.309-2.64,0.24c-0.08,0-0.16,0-0.24,0
		s-0.16,0-0.24,0c-2.492-0.256-5.348,0.146-4.32,2.64c0.577,0.55,2.762,0.749,3.6,0.24c0.08,0,0.16,0,0.24,0c0.08,0,0.16,0,0.24,0
		c2.017,0.017,4.089,0.089,5.28-0.72c0.08,0,0.16,0,0.24,0C261.99,363.696,270.32,363.22,269.198,361.368L269.198,361.368z"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M234.88,354.459c-0.08,0-0.16,0-0.24,0c-1.604,0.183-2.597,1.146-2.4,2.4
		c0.459,2.928,3.25,0.521,4.56,0c0.071-0.028,0.152-0.053,0.225-0.081c0.002-0.001,0.004-0.002,0.007-0.002
		c1.101-0.352,4.232,1.327,5.393,1.034c0.001,0,0.002,0,0.004-0.001c5.002-1.122,11.5-0.734,13.892-2.27
		c0.279-0.202,0.644-0.317,1.2-0.24c1.609-0.23,3.222-0.458,4.56-0.96c0.31-0.331,1.043-0.237,1.68-0.24
		c1.467-0.133,2.907-0.293,4.08-0.72c0.349-0.371,1.229-0.211,1.92-0.24c0.729-0.151,2.067,0.307,2.4-0.24
		c0.398-0.402,1.409-0.191,2.16-0.24c1.731-0.029,3.228-0.292,4.56-0.72c1.096-0.344,2.295-1.585,3.6-1.72
		c1.293-0.226,2.471-0.568,3.12-1.44c-0.155-1.506-2.289-2.552-3.6-1.44c-0.08,0-0.16,0-0.24,0c-3.228,0.852-6.894,2.266-10.32,2.92
		c-0.08,0-0.16,0-0.24,0c-0.808,0.152-2.229-0.309-2.64,0.24c-0.08,0-0.16,0-0.24,0c-2.316,0.164-4.324,0.636-6.24,1.2
		c-0.08,0-0.16,0-0.24,0c-2.566,0.314-4.969,0.792-7.2,1.44c-0.08,0-0.16,0-0.24,0c-1.685,0.155-3.275,0.405-4.56,0.96
		c-0.08,0-0.16,0-0.24,0c-1.047,0.072-1.99,0.25-2.64,0.72c-0.08,0-0.16,0-0.24,0c-2.103,0.457-4.212-0.092-6,0.68 M242.433,357.809
		"/>
		</g>
		<g id="eyebrows3">
			<path fillRule="evenodd" clipRule="evenodd" fill="#7A7C7D" d="M265.68,282.819c4.929-1.871,9.078-4.522,14.4-6
		c2.044-0.755,4.345-1.255,7.2-1.2c0.64-0.642,5.117,0.196,2.64-0.72c-3.686-0.314-6.229-1.771-10.56-1.44
		c-6.532,0.748-10.877,3.683-13.68,8.16c0,0.08,0,0.16,0,0.24C265.91,282.306,264.961,282.661,265.68,282.819z"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M307.681,276.339c-0.525-0.674-0.958-1.442-2.16-1.44
		c-1.299-0.461-2.46-1.06-4.32-0.96c-0.592,0.032-1.156,0.036-1.439-0.24c-0.333-0.547-1.671-0.089-2.4-0.24
		c-0.814-0.065-1.945,0.186-2.4-0.24c-0.711-0.408-1.976-0.264-2.88-0.48c-1.048,0.008-1.871-0.208-2.64-0.48
		c-1.162-0.519-2.618-0.742-4.08-0.96c-0.592,0.032-1.156,0.037-1.44-0.24c-2.503-0.501-6.433-0.354-8.4,0.48
		c-0.587,0.213-1.041,0.559-1.92,0.48c-6.359,1.961-10.007,6.633-12,12.96c0.146,0.666,1.433,1.431,2.16,0.72c0.08,0,0.16,0,0.24,0
		c2.49-0.63,5.294-0.946,6.96-2.4c0.08,0,0.16,0,0.24,0c2.374-0.746,4.301-1.939,6.24-3.12c0.08,0,0.16,0,0.24,0
		c1.765-0.635,3.776-1.024,5.52-1.68c1.082-0.358,2.34-0.54,3.84-0.48c4.72,0,9.44,0,14.16,0c0.08,0,0.16,0,0.24,0
		c1.046,0.399,3.754,0.399,4.8,0c0.08,0,0.16,0,0.24,0C307.28,277.859,307.783,277.402,307.681,276.339z M267.601,282.04
		c4.321-0.876,7.22-3.174,10.999-4.593C274.823,278.868,271.922,281.165,267.601,282.04z M287.28,275.619
		c-2.855-0.055-5.155,0.444-7.2,1.2c-4.622,1.284-8.36,3.451-12.491,5.224c-0.079,0.016-0.149,0.041-0.229,0.056
		c-0.097,0.018-0.199,0.058-0.303,0.107c-0.025,0.012-0.05,0.027-0.075,0.04c-0.089,0.047-0.18,0.098-0.271,0.156
		c-0.343,0.14-0.681,0.284-1.031,0.417c-0.719-0.158,0.229-0.513,0-0.96c0-0.08,0-0.16,0-0.24c2.803-4.478,7.148-7.412,13.68-8.16
		c4.331-0.331,6.874,1.126,10.56,1.44C292.397,275.815,287.92,274.978,287.28,275.619z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#7A7C7D" d="M230.634,287.1c1.762-0.158,4.157,0.317,5.52-0.24
		c-3.48-1.228-7.815-3.698-11.76-3.12c-2.04,0.299-5.045,3.01-6.96,4.56c-2.4,1.944-4.468,3.817-6.48,5.04
		c1.695-0.545,3.068-1.412,4.8-1.92c1.389-0.531,2.533-1.307,4.08-1.68c1.569-0.911,3.072-1.889,5.04-2.4
		C226.769,286.949,228.815,286.999,230.634,287.1z"/>
			<path d="M207.831,292.273c0.029-0.016,0.056-0.035,0.085-0.051c-0.414,0.193-0.815,0.399-1.207,0.614
		c-0.565,0.267-1.145,0.521-1.755,0.743c-0.08,0-0.16,0-0.24,0c-1.126,0.394-1.461,1.579-1.68,2.88
		c-0.117,0.501,0.338,0.71,0.81,0.833l3.616,0.226c0.042-0.036,0.097-0.058,0.134-0.099c0.08,0,0.16,0,0.24,0
		c1.126-0.394,2.305-0.735,3.12-1.44c0.676-0.205,0.985-0.775,1.92-0.72c1.126-0.394,2.305-0.735,3.12-1.44c0.08,0,0.16,0,0.24,0
		c1.875-0.281,3.568-1.666,5.362-2.815c-0.171,0.091-0.339,0.185-0.508,0.278c0.169-0.093,0.337-0.188,0.509-0.279
		c0,0,0.001,0,0.001,0s0,0,0,0c0.205-0.109,0.414-0.213,0.637-0.304c0.119-0.125,0.271-0.218,0.41-0.324l0,0
		c0.903-0.684,2.203-0.971,3.677-1.084c3.266-0.268,6.555,0.712,10.553,0.208c0.08,0,0.16,0,0.24,0c0.8,0,1.6,0,2.4,0
		c0.655,0.175,0.483-0.477,0.96-0.48c-0.782-4.658-5.22-5.66-9.6-6.72c-1.017,0.057-1.699-0.221-2.4-0.48
		c-0.755-0.445-1.887-0.513-3.12-0.48c-0.4,0-0.8,0-1.2,0c-0.286,0.107-0.569,0.215-0.851,0.326
		c-0.04,0.016-0.079,0.031-0.118,0.047c-0.9,0.357-1.76,0.755-2.497,1.276c-4.641,2.694-8.031,6.64-12.772,9.234 M230.634,287.1
		c-1.819-0.102-3.866-0.151-5.76,0.24c-1.968,0.511-3.471,1.489-5.04,2.4c-1.546,0.374-2.69,1.149-4.08,1.68
		c-1.732,0.508-3.105,1.375-4.8,1.92c2.012-1.223,4.08-3.096,6.48-5.04c0.542-0.438,1.176-0.971,1.846-1.515
		c-0.137,0.104-0.28,0.201-0.406,0.315c0.144-0.129,0.303-0.243,0.46-0.359c0.083-0.067,0.172-0.129,0.256-0.196
		c-0.076,0.053-0.143,0.114-0.217,0.168c0.229-0.169,0.464-0.332,0.696-0.497c0.367-0.251,0.715-0.52,1.028-0.825
		c0.146-0.143,0.296-0.283,0.417-0.451c-0.121,0.168-0.27,0.308-0.416,0.451c1.169-0.833,2.339-1.511,3.295-1.651
		c3.945-0.578,8.28,1.893,11.76,3.12C234.792,287.417,232.396,286.942,230.634,287.1z"/>
		</g>
		<g id="glasses">
			<path fillRule="evenodd" clipRule="evenodd" d="M335.434,268.46c-0.133,1.467-0.182,3.017-0.479,4.32
		c-4.865,2.576-11.467,3.415-17.521,4.8c-0.807,0.839-0.553,1.683-1.439,2.4c-1.211,0.98-3.545,0.872-5.039,1.68
		c-0.182,1.34-1.227,1.814-1.682,2.88c-0.678,5.243-0.551,11.29-0.959,16.8c-3.246,1.718-7.078,2.095-10.801,2.88
		c-10.92,2.303-22.833,4.756-34.08,6.96c-0.896-1.751-2.224-3.182-3.36-5.28c-0.846-1.562-1.526-4.776-2.88-5.76
		c-1.572-1.144-8.787-1.254-11.76-0.72c-2.168,0.39-3.954,1.432-5.28,2.4c0.085,4.405-0.683,7.957-0.72,12.24
		c-3.222,2.659-8.094,2.643-12.48,3.6c-2.196,0.479-4.268,1.179-6.479,1.68c-4.555,1.033-9.386,1.06-13.44,2.64
		c-3.692-6.468-8.924-11.396-12.96-17.52c0.027-1.708-0.051-3.309-0.479-4.56c0.249-0.894,1.407-1.177,2.16-1.68
		c0.746-0.499,1.486-1.426,2.159-1.2c-0.109,1.39,0.57,1.99,0.96,2.88c6.902-2.092,13.508-3.756,20.641-6
		c3.327-1.047,6.208-2.336,11.04-1.92c1.42,0.122,2.752,0.539,4.08,0.96c1.198,0.379,2.464,1.167,3.6,1.2
		c1.65,0.047,2.547-1.18,4.32-1.92c1.595-0.666,3.705-1.063,5.28-1.2c2.245-0.196,4.466,0.417,5.76-0.24
		c0.79-0.402,1.222-1.758,2.16-2.64c2.254-2.119,6.876-3.378,11.279-4.56c8.765-2.352,16.95-3.676,25.681-6
		c4.354-1.159,9.193-2.805,12.959-2.88c0.842-0.017,1.664,0.306,2.16,0.24c1.422-0.188,2.875-1.386,4.561-1.68
		c0.66-0.115,1.508,0.148,2.16,0c1.414-0.322,3.01-1.167,4.559-1.68c3.07-1.016,5.975-1.958,9.361-2.88
		C331.408,267.902,333.441,266.853,335.434,268.46z M304.234,280.46c-7.313,2.107-15.838,3.834-24,5.52
		c-7.8,1.61-15.935,3.379-21.12,7.68c4.03,2.69,6.093,7.348,8.4,11.76c12.05-3.071,24.08-6.16,36.24-9.12
		c0.979-4.703,0.52-10.84,1.201-15.84c-0.16,0-0.32,0-0.48,0C304.395,280.46,304.314,280.46,304.234,280.46z M235.834,305.42
		c0.18-1.791,0.631-4.152,0.239-5.28c-0.506-1.46-2.106-1.559-2.88-2.88c-6.18-1.493-10.933,0.469-15.12,1.68
		c-6.114,1.768-11.792,3.381-17.76,5.04c2.631,4.329,5.698,8.222,8.88,12c8.461-2.02,17.402-3.558,25.92-5.52
		C235.12,308.911,235.655,307.196,235.834,305.42z"/>
		</g>
	</svg>
