import React from 'react'
export const Agent = () =>
	<svg version="1.1" className="character_svg" id="Agent" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		width="576px" height="828px" viewBox="0 0 576 828" enableBackground="new 0 0 576 828" space="preserve">
		<g id="eye_backing">
			<path fillRule="evenodd" clipRule="evenodd" fill="#EAEAEA" d="M322.772,246.262c-4.7-3.647-18.959-4.876-24.72-1.44
		c0.075,3.115-0.425,5.655-0.24,8.88c6.203,1.097,12.943,3.348,19.68,1.2c0.842-2.11,3.06-1.831,4.32-3.12
		C322.813,250.759,323.91,247.145,322.772,246.262z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#EAEAEA" d="M251.492,255.622c-2.665-0.733-4.712-4.043-7.44-4.32
		c-4.079-0.415-9.866,3.583-12.24,6.72c-1.047,1.383-1.785,3.705-2.4,5.28c2.688,2.014,6.745,3.902,10.8,3.36
		c2.938-0.393,7.207-3.702,10.32-5.76c2.038-1.348,4.415-2.319,3.6-5.04C253.168,255.111,252.407,255.873,251.492,255.622z"/>
		</g>
		<g id="eye1">
			<circle fillRule="evenodd" clipRule="evenodd" cx="243.316" cy="257.75" r="7.5" />
		</g>
		<g id="eye2">
			<circle fillRule="evenodd" clipRule="evenodd" cx="309.752" cy="247.653" r="7.5" />
		</g>
		<g id="body">
			<g id="blue">
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M262.292,511.942c-3.814-1.359-7.121-4.325-6.72-9.12
			c-2.093-1.348-3.355-3.524-5.52-4.8c0.11,1.71-0.135,3.065-0.48,4.319c14.389,21.852,27.829,44.651,47.28,61.44
			c1.65-1.389,3.152-2.928,4.319-4.8C286.517,544.998,273.899,528.976,262.292,511.942z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M249.332,516.982c7.414,17.226,18.104,32.457,29.76,44.64
			C267.722,548.192,258.472,532.643,249.332,516.982z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M289.412,573.862c-3.206-4.233-6.894-7.986-10.32-12
			C282.101,566.294,285.244,570.591,289.412,573.862z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M251.492,431.062c-5.928-2.314-13.487-4.269-16.32-8.88
			c4.665,0.887,7.732,2.593,11.76,4.32c1.747,0.749,5.117,2.586,6.48,2.4c1.566-0.215,6.079-5.503,7.92-6.96
			c6.079-4.813,11.311-7.566,17.521-12.24c2.952-2.222,6.888-4.918,7.44-7.44c0.647-2.958-0.06-7.783-0.24-11.76
			c-0.262-5.769-0.467-11.521-0.48-16.08c-0.397-0.259-0.397-2.141,0-2.4c0.117-1.477-0.427-2.293-0.24-3.84
			c-5.401-0.012-12.1-0.662-15.6-3.36c-2.194,1.209-5.65,1.751-7.92,0.96c-0.937,1.077-1.108,1.772-1.44,3.12
			c4.587,0.533,6.906,3.334,11.28,4.08c0.862,1.168,2.364,2.77,1.2,4.56c-4.403,0.176-8.351-1.922-11.52-3.84
			c-1.237-0.749-2.113-2.182-3.36-2.88c-1.64-0.918-3.76-0.916-5.52-1.92c0.934,4.908,3.042,10.507,4.08,15.36
			c0.424,1.985,1.253,4.704,0,6.24c-2.16-0.571-1.951-1.44-4.32-0.48c-2.38-1.14-2.1-4.94-3.84-6.72
			c-4.075,0.586-7.573,2.958-11.76,2.64c-1.52,1.394-3.571,2.168-5.76,3.12c-1.769,0.77-4.958,1.814-5.76,3.12
			c-0.842,1.37-0.051,2.863,0,4.8c0.184,7.057,0.82,15.259,0.96,24c-0.152,0.808,0.309,2.229-0.24,2.64
			c-0.037,5.264-0.591,11.328-0.72,15.6c-0.343,11.379,2.177,21.946,5.52,32.4c3.272,10.232,5.126,20.934,10.56,28.56
			c0.042-6.578,1.834-11.296,2.88-16.8c1.25-6.584,2.074-13.156,1.68-20.16c-0.201-3.57-0.61-7.19-0.72-10.8
			c-0.11-3.623,0.778-7.317,0.48-10.8c-0.079-0.923-0.58-1.712-0.48-2.64C245.407,435.306,250.268,434.255,251.492,431.062z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M369.093,389.062c-1.786-0.694-3.372-1.588-5.04-2.4
			c-4.069-1.611-8.624-2.736-12.24-4.8c-0.27-0.051-0.374-0.266-0.48-0.48c-1.129-1.079-0.84-2.096-0.96-4.08
			c0.153-0.887-0.311-2.391,0.24-2.88c0-1.04,0-2.08,0-3.12c0.154-0.966-0.312-2.552,0.24-3.12c-0.069-2.549,0.424-4.536,0.24-7.2
			c0-1.36,0-2.72,0-4.08c-0.558-1.442-0.082-3.918-0.24-5.76c-1.402,0.278-1.903,1.457-3.36,1.68
			c-0.502,0.458-1.146,0.774-1.92,0.96c-1.261,1.299-3.508,1.612-5.28,2.4c0,1.76,0,3.52,0,5.28
			c-0.557-1.283-0.082-3.598-0.239-5.28c-6.084,1.845-12.709,3.892-19.681,4.8c-2.443,0.318-5.182,0.043-7.68,0.48
			c-1.768,0.309-3.851,1.528-5.76,2.16c-2.286,0.756-4.413,0.965-6.24,1.92c0.021,10.214,1.728,23.777,0.96,34.32
			c-0.49,6.729-4,6.823-8.4,9.84c-15.361,10.532-31.419,20.883-41.52,35.76c1.597,5.712,2.335,12.177,2.88,18.48
			c0.546,6.316,0.352,12.506-0.96,18.239c1.788,3.652,3.829,7.051,5.76,10.561c4.586-5.608,14.745-2.284,15.36,4.8
			c0.639,1.125,1.045,3.75,0.48,5.28c-0.444,2.195-1.392,3.888-2.64,5.28c11.91,14.249,23.746,28.574,37.44,41.04
			c8.018-10.415,15.385-22.261,21.6-35.04c6.047-12.435,12.71-25.041,18.72-37.921c3.058-6.55,4.693-13.389,7.681-20.159
			c2.956-6.702,3.66-14.663,4.8-23.04c1.638-12.044,4.385-26.812,7.68-39.601C371.453,389.81,371.356,389.59,369.093,389.062z
			 M274.772,455.782c0.324,1.038,0.112,1.522,0,2.64c-5.819,4.966-16.582-0.248-16.8-7.2c-0.028-0.882-0.061-1.774,0.72-2.399
			c-1.001-5.583,2.351-8.833,6.96-9.601c0.333-0.547,1.67-0.089,2.4-0.239C276.916,438.853,282.127,450.125,274.772,455.782z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M256.292,499.462c-0.024-2.665,1.301-3.979,2.16-5.76
			c-3.546-0.774-3.451-5.189-5.76-7.2c-0.258,3.182-1.442,5.438-1.92,8.4C253.326,495.708,253.661,498.733,256.292,499.462z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M291.813,562.822c-1.318-1.21-2.615-2.255-3.6-3.12
			c-1.14-0.939-2.18-1.979-3.12-3.12c-13.867-14.374-24.268-32.211-35.76-48.96c-0.337,4.825,2.277,7.671,4.56,11.521
			c1.73,2.917,3.162,5.855,4.56,8.159c9.798,16.146,20.938,30.854,32.88,43.681c0.274-1.332,3.94-3.78,3.84-5.04
			C295.121,565.304,292.548,563.498,291.813,562.822z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M266.372,511.702c11.013,16.427,23.117,31.764,37.44,44.88
			c1.34-1.38,2.348-3.093,3.84-4.32c-12.408-13.271-25.089-26.271-36.24-40.8C269.612,511.174,268.513,510.728,266.372,511.702z"/>
			</g>
			<g id="red">
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M238.772,711.623c0-0.721,0-1.44,0-2.16
			c-0.307,0.013-0.332,0.308-0.72,0.239c-0.08,0-0.16,0-0.24,0c-0.644,1.356-3.518-0.255-4.32,0.48
			c1.886,2.033,3.299,4.541,5.28,6.479c0.158-1.522-0.723-3.27,0-4.56C238.772,711.942,238.772,711.782,238.772,711.623z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M300.212,763.703c-5.839-5.448-11.367-11.834-16.08-17.041
			c0-0.079,0-0.159,0-0.239c-5.751-5.18-11.496-11.432-17.04-17.521c-1.331-1.461-4.223-5.184-5.76-5.76
			c-3.759-1.41-8.138,0.433-12.24-1.2c-0.08,0-0.16,0-0.24,0c-2.396,0.236-3.561-0.759-6-0.479c0.197,1.485,2.806,2.632,3.6,4.319
			c0,0.08,0,0.16,0,0.24c13.453,14.548,26.553,29.447,41.04,42.96c5.84,0,11.68,0,17.521,0
			C305.208,767.522,301.809,765.192,300.212,763.703z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M177.332,763.942c0,0.08,0,0.16,0,0.24
			c0.517,1.243,0.471,3.05,0.96,4.32c1.12,0,2.24,0,3.36,0c-1.9-2.34-2.877-5.603-4.56-8.16
			C177.231,761.483,177.439,762.556,177.332,763.942z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M321.093,357.862c0.08,0,0.159,0,0.239,0
			c6.588-1.413,12.979-3.021,18.96-5.04c1.339,0.138,1.971-0.43,2.641-0.96c0.189-0.771,0.871-1.049,1.2-1.68
			c-1.736,0.264-2.801,1.2-4.08,1.92c-0.679-0.198-0.622,0.338-1.2,0.24c-1.832,1.048-4.249,1.512-6.48,2.16
			c-0.239,0-0.479,0-0.72,0c-1.835,0.807-5.147,1.41-7.44,0.96c-0.159,0-0.319,0-0.479,0c-0.754,0.113-0.634-0.646-1.2-0.72
			c-1.299,1.021-2.302,2.338-3.84,3.12C319.288,358.596,320.149,357.575,321.093,357.862z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M246.212,510.262c-0.143-1.439-0.238-2.757,0.24-4.08
			c0.514-1.672,0.217-2.942,0.24-4.8c0.122-0.838,0.108-1.812,0.48-2.399c0.258-1.502,0.783-2.737,0.72-4.561
			c0.095-1.905,0.667-3.332,1.2-4.8c0.359-0.681,0.5-1.579,0.72-2.4c-0.205-1.249,0.711-3.445,0.96-5.04c0-0.08,0-0.159,0-0.239
			c0.596-2.935,0.898-6.296,1.44-8.641c0.308-0.315,0.308-0.885,0-1.2c-0.001-2.504-0.028-5.589-0.24-8.399
			c0.046-1.967-0.012-3.828-0.48-5.28c-0.369-0.751-0.466-1.773-0.48-2.88c0-0.08,0-0.16,0-0.24c-0.369-0.751-0.466-1.773-0.48-2.88
			c-0.277-2.98-0.657-5.819-0.72-8.399c-0.558-0.81,0.467-2.085,0.96-2.641c0-0.08,0-0.16,0-0.24c2.35-3.33,4.87-6.489,7.92-9.12
			c0.014-0.146,0.036-0.283,0.24-0.239c2.116-2.444,4.699-4.421,7.2-6.48c0.08,0,0.16,0,0.24,0c0.879-1.521,2.592-2.208,3.84-3.359
			c0.08,0,0.16,0,0.24,0c6.169-5.352,13.277-9.764,19.92-14.641c1.142-0.298,1.668-1.212,2.641-1.68
			c1.742-1.617,4.525-2.195,4.319-5.76c-0.036-1.316-0.025-2.586,0.24-3.6c-0.214-3.387-0.753-7.726-0.24-11.04
			c0.184-2.023-0.371-3.308-0.239-5.28c-0.274-4.366-0.611-8.669-0.48-13.44c-2.001-0.001-3.301,0.699-5.52,0.48
			c-0.08,0-0.16,0-0.24,0c-0.561,0-1.12,0-1.681,0c-0.049,2.292,0.53,5.227,0.24,6.96c0,0.4,0,0.8,0,1.2
			c0.335,9.637,0.532,19.24,0.721,28.08c-0.104,0.457-0.518,0.603-0.48,1.2c-0.613,1.547-1.663,2.657-2.88,3.6
			c-0.08,0-0.16,0-0.24,0c-0.979,1.1-2.236,1.923-3.36,2.88c-0.08,0-0.16,0-0.24,0c-0.901,1.019-2.037,1.803-3.12,2.64
			c-0.876,0.164-1.17,0.91-1.92,1.2c-0.919,1.16-2.442,1.718-3.6,2.64c-0.716,0.004-0.816,0.624-1.44,0.72
			c-2.769,2.511-6.585,3.975-9.36,6.48c-1.011,0.109-1.105,1.135-1.92,1.439c-0.854,1.146-2.035,1.966-3.12,2.881
			c-0.08,0-0.16,0-0.24,0c-3.315,3.564-6.718,7.041-9.84,10.8c0,0.24,0,0.479,0,0.72c-0.15,0.729,0.308,2.067-0.24,2.4
			c-0.902,11.944,2.022,23.767,0,36c0.445,0.617-0.263,2.128-0.48,2.88c-0.299,0.741-0.374,1.706-0.48,2.64
			c-0.48,2.802-1.135,7.505-2.16,10.561c-0.268,1.412-0.468,2.892-0.48,4.56c0.166,0.567,0.555,2.147,0,2.641
			c-0.035,0.824-0.14,3.608,0.24,4.8C245.331,507.943,245.147,509.727,246.212,510.262z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M385.652,382.102c0.891-0.17,1.03,0.41,1.92,0.24
			c3.066,0.454,4.635,2.405,6.96,3.6c0.08,0,0.16,0,0.24,0c1.03,0.17,2.009,0.392,2.64,0.96c3.691,0.995,7.229,1.796,11.521,2.64
			c3.568,0.922,6.355,1.49,9.6,2.4c0.08,0,0.16,0,0.24,0c3.509,0.651,6.887,1.434,10.08,2.4c0.08,0,0.16,0,0.24,0
			c0.703-0.216,2.529,0.504,3.359,0.96c0.764,0.037,1.057,0.544,1.921,0.48c1.85,0.471,3.572,1.068,4.8,2.16c0.08,0,0.16,0,0.239,0
			c2.447,1.898,6.066,3.822,5.521,7.92c0.307,0.013,0.332,0.308,0.72,0.24c1.348,0.634,0.884-0.527,0.96-1.68
			c0.016,1.585,0.766,2.435,1.92,2.88c1.687,0.154,2.737,0.943,4.08,1.44c0.32,0,0.641,0,0.96,0
			c-0.001-1.039-0.557-1.523-0.479-2.64c-0.28-2.36-1.376-3.905-2.16-5.76c-0.735-0.464-1.204-1.196-1.92-1.68
			c-2.217-0.503-4.188-1.252-6.24-1.92c-0.597-0.523-2.086-0.154-2.64-0.72c-2.406-0.135,0.709,0.842,1.2,1.2
			c1.436,1.047,5.209,5.075,5.279,6.96c-1.78-4.54-5.762-6.878-9.84-9.12c-0.278-0.202-0.644-0.317-1.2-0.24
			c-1.733-0.586-3.866-0.773-5.279-1.68c-0.08,0-0.16,0-0.24,0c-3.028-0.892-6.092-1.748-8.641-3.12
			c-2.37-0.75-4.267-1.973-6.96-2.4c-0.776-0.343-1.808-0.432-2.399-0.96c-0.08,0-0.16,0-0.24,0c-1.935-0.786-4.381-1.059-6.24-1.92
			c-0.16,0-0.319,0-0.479,0c-3.952-0.688-7.819-1.461-11.04-2.88c-0.08,0-0.16,0-0.24,0c-1.162-0.278-2.228-0.652-3.12-1.2
			c-0.324-0.076-0.458-0.342-0.96-0.24c-1.437-0.324-2.736-0.784-4.08-1.2c-0.08,0-0.16,0-0.24,0
			c-3.062-0.342-3.65,1.789-5.279,2.88c0.945,0.814,2.458,1.062,3.359,1.92C384.128,383.297,384.237,382.047,385.652,382.102z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M197.012,390.022c1.36,0,2.72,0,4.08,0
			c7.07,0.35,11.613-1.827,15.6-4.56c-3.065-0.459-6.529-1.961-9.84-0.72c-3.578,0.742-6.202,2.438-9.36,3.6
			c-0.664,0.536-1.466,0.934-2.4,1.2c-0.418-0.018-0.747,0.054-0.96,0.24c0,0.241-0.195,0.286-0.24,0.48
			C194.81,390.06,195.818,389.948,197.012,390.022z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M268.772,597.862c-1.494,0.586-2.286,1.874-3.6,2.64
			c-3.167,2.673-6.563,5.118-10.08,7.44c-0.08,0-0.16,0-0.24,0c-2.53,2.589-6.196,4.044-8.88,6.479
			c0.363,3.864,0.005,9.068,0.72,13.2c0,0.4,0,0.8,0,1.2c0.768,2.019,0.399,6.855,0.48,10.32c0,1.279,0,2.56,0,3.84
			c0.18,13.112,0.16,26.688-0.72,39.36c-0.331,0.309-0.237,1.042-0.24,1.68c-0.189,3.731,0.001,7.842-0.72,11.04
			c0,0.08,0,0.16,0,0.24c-0.165,5.435-0.601,10.599-0.96,15.84c0,0.16,0,0.32,0,0.48c0.18,2.06,0,4.479,0.72,6
			c4.958,0.354,11.007,0.788,15.36,0.96c0.08,0,0.16,0,0.24,0c0.943,0.257,2.348,0.052,3.12,0.479c0.16,0,0.32,0,0.48,0
			c13.075,0.581,25.979,3.266,39.12,1.92c12.726-1.303,27.064-3.024,39.36-3.359c16.449-1.097,33.096-0.772,50.399-1.2
			c5.049-0.712,11.75,0.229,16.801-0.48c0.922,0.56,2.229-0.242,3.359-0.24c0.387-3.533,0.491-7.349,0.48-11.279
			c0.114-6.377,1.471-9.799,1.92-15.841c0.29-3.71-0.599-8.598,0.48-11.52c0-0.56,0-1.12,0-1.68c0.158-2.562-0.319-5.759,0.239-7.92
			c0-0.4,0-0.801,0-1.2c0.159-2.321-0.318-5.278,0.24-7.2c0-0.32,0-0.64,0-0.96c-0.36-1.723-0.247-5.189-0.24-6.48
			c0-0.319,0-0.64,0-0.96c0.088-1.527-0.427-2.453-0.239-4.08c0.183-1.303-0.381-1.859-0.24-3.119
			c-0.456-1.704-0.637-3.684-0.96-5.521c0-0.24,0-0.479,0-0.72c-1.033-3.447-1.981-6.979-2.641-10.801c0-0.079,0-0.159,0-0.239
			c-0.205-1.635-0.724-2.956-0.72-4.8c-1.272-1.608-2.487-3.272-2.88-5.761c0.923,1.718,1.173,4.107,2.88,5.04
			c-0.01-1.833-0.971-3.984-0.479-5.52c-0.202-0.599-0.314-1.286-0.24-2.16c-0.241-1.125-0.886-3.107-0.48-4.08
			c-0.145-0.575-0.345-1.095-0.239-1.92c-0.029-2.028,0.014-3.986,0.479-5.521c-0.016-1.216,0.412-1.987,0.24-3.359
			c0.421-1.579-0.04-4.04,0.479-5.521c0.158-1.603-0.316-3.836,0.24-5.04c0.157-1.522-0.316-3.676,0.24-4.8
			c0.165-0.799-0.496-0.387-0.72-0.24c0.586-1.174,0.703-2.815,1.68-3.6c0.061-0.899,0.377-1.543,0.96-1.92
			c-0.229,0.49-0.49,0.949-0.72,1.439c2.034-0.031,3.943,1.333,2.88,3.601c-0.158,1.762,0.317,4.157-0.24,5.52
			c-0.158,1.683,0.317,3.997-0.24,5.28c-0.154,0.966,0.313,2.552-0.24,3.12c-0.155,1.124,0.314,2.874-0.239,3.6
			c-0.011,1.431-0.104,2.776-0.48,3.841c0.053,1.866-0.167,4.007,0.24,5.52c0.839-0.281,0.462-1.778,1.2-2.16
			c1.029-3.051,1.939-6.22,2.64-9.6c0.224-2.336,0.57-4.551,1.44-6.24c0.014-1.187,0.236-2.163,0.72-2.88
			c-0.066-1.827,0.976-2.545,1.2-4.08c0.24-1.04,0.346-2.214,0.72-3.12c-0.057-1.656,0.75-2.449,0.96-3.84
			c1.36-5.68,1.032-13.048,3.6-17.521c0.28-0.04,0.261-0.379,0.24-0.72c0.005-0.956,0.229-1.69,0.721-2.16
			c0.826-1.813,1.104-4.175,2.159-5.76c0.467-1.934,1.182-3.619,1.92-5.28c0.145-1.535,0.657-2.703,1.2-3.84
			c0.094-0.867,0.318-1.602,0.721-2.16c0.101-1.339,0.936-1.944,0.96-3.359c0.436-2.175,0.475-3.283,1.199-5.04
			c0.28-0.041,0.261-0.38,0.24-0.721c-0.058-1.017,0.221-1.699,0.48-2.399c-0.335-0.429,0.151-2.078,0.479-2.641
			c0.041-0.358-0.086-0.886,0.24-0.96c0.188-2.834,0.866-5.811,1.2-8.64c0.234-2.289-0.029-3.594,0.479-5.521
			c0.148-0.651-0.305-1.904,0.24-2.159c-0.098-0.481-0.595-1.78,0-2.16c0.152-0.809-0.31-2.229,0.24-2.641c0-1.04,0-2.08,0-3.12
			c0-1.119,0-2.239,0-3.359c0-0.08,0-0.16,0-0.24c-0.157-1.917,0.212-3.308,0.24-5.04c-0.118-0.758,0.432-0.848,0.239-1.68
			c-0.371-1.019-0.002-3.357,0.24-4.561c0-0.88,0-1.76,0-2.64c0.011-1.43,0.104-2.775,0.48-3.84
			c-0.118-1.078,0.121-1.799,0.239-2.641c-0.454-0.47,0.025-2.043,0.24-2.64c-0.028-1.148,0.068-2.172,0.48-2.88
			c0-0.24,0-0.48,0-0.72c-0.16-2.312,1.615-4.734,0.479-6.48c1.348-0.053,0.982-3.788,1.681-5.28
			c0.275-0.283,0.271-0.848,0.239-1.439c0.493-2.707,1.085-5.315,1.681-7.92c-0.356-0.204-0.517-0.604-0.48-1.2
			c0.281,0.039,0.202,0.438,0.24,0.72c0.832-0.832,0.603-3.984,1.439-5.52c0.698-0.183-0.218-1.979,0.48-2.16
			c-0.033-0.993,0.066-1.854,0.479-2.4c0.041-0.358-0.085-0.886,0.24-0.96c0.391-2.741,0.52-5.092,1.44-7.68
			c0.386-0.813,0.491-1.908,0.479-3.12c0.176-3.825,0.337-7.663,0.721-11.28c-0.062-1.539-0.419-2.781-1.921-2.88
			c0-0.08,0-0.16,0-0.24c-0.159,0-0.319,0-0.479,0c-1.751-0.649-3.545-1.255-5.04-2.16c-0.08,0-0.16,0-0.24,0
			c-2.309-0.731-4.424-1.656-6.479-2.64c-0.325-0.075-0.459-0.342-0.96-0.24c-0.817-0.383-1.951-0.449-2.641-0.96
			c-0.578,0.098-0.521-0.438-1.2-0.24c-1.003-0.116-1.778-0.461-2.64-0.72c-0.278-0.202-0.644-0.317-1.2-0.24
			c-0.911-0.048-1.707-0.212-2.399-0.48c-0.08,0-0.16,0-0.24,0c-3.738-0.589-6.906-1.107-10.32-2.4c-0.08,0-0.16,0-0.24,0
			c-2.341-0.46-6.311-1.368-8.88-2.4c-0.08,0-0.159,0-0.239,0c-3.642-0.759-7.124-1.676-10.32-2.88c-0.08,0-0.16,0-0.24,0
			c-5.335-1.625-9.188-4.731-12.96-7.92c-0.08,0-0.16,0-0.24,0c-1.279-0.721-2.84-1.16-3.84-2.16
			c-0.307-0.013-0.332-0.308-0.72-0.24c-0.033,0.847-0.688,1.073-0.72,1.92c-2.504,7.999-4.37,15.842-6,24.24
			c-1.161,7.521-2.942,13.635-3.12,20.64c-0.328,1.433-0.268,3.254-0.721,4.561c0,0.08,0,0.16,0,0.24
			c-0.165,2.333-0.767,7.264-1.68,10.319c0,0.16,0,0.32,0,0.48c-0.275,4.42-2.335,8.768-4.08,12.479c0,0.08,0,0.16,0,0.24
			c-0.371,0.749-0.376,1.864-0.96,2.4c-0.187,0.533-0.504,0.936-0.479,1.68c-1.826,6.094-4.102,11.739-6.961,16.8
			c-1.36,3.04-3.171,5.629-4.319,8.88c-1.351,2.57-2.399,5.441-4.08,7.681c-0.642,1.679-1.717,2.923-2.16,4.8
			c-0.726,1.274-1.121,2.879-2.16,3.84c-0.013,0.307-0.308,0.332-0.24,0.72c-1.477,3.164-2.931,6.349-4.8,9.12
			c-0.83,1.33-1.591,2.729-2.16,4.32c-1.827,3.612-3.879,7.001-6.239,10.08c-0.639,1.361-1.699,2.301-2.16,3.84
			c-1.551,2.369-2.93,4.91-4.8,6.96c-0.004,0.477-0.655,0.306-0.48,0.96c-2.344,3.017-4.265,6.456-6.96,9.12c0,0.08,0,0.16,0,0.24
			c-2.071,2.488-3.984,5.136-6.48,7.2c0,0.08,0,0.159,0,0.239c-1.906,2.094-3.822,4.178-5.76,6.24c0,0.08,0,0.16,0,0.24
			c-0.391,1.449-1.605,2.074-2.64,2.88c-0.904,1.576-2.42,2.54-3.12,4.32C288.312,584.282,278.451,590.981,268.772,597.862z
			 M422.853,547.462c1.499-8.356,3.445-16.874,2.88-27.12c0.556,0.885,0.084,2.797,0.24,4.08c0.188-0.053,0.279-0.201,0.24-0.479
			c0.93-11.551,1.578-23.951,0.96-35.761c-0.201-3.836-1.594-7.972,0-11.04c1.661,2.34,1.62,7.659,1.68,10.801
			c0,0.08,0,0.159,0,0.239c1.385,12.521,0.859,26.638-0.24,38.4c-0.14,1.5-1.186,9.373-0.239,7.2
			c-0.989,2.676-1.075,6.282-1.921,10.32c-0.508,2.429-1.225,8.643-3.119,9.12c-0.075,0.019-0.164-0.016-0.24,0
			C421.814,551.664,422.548,549.159,422.853,547.462z M256.532,633.862c0.692-12.923,24.8-13.849,26.64-0.479
			c0,0.72,0,1.439,0,2.159c0.038,1.079-0.195,1.886-0.72,2.4c0,0.08,0,0.16,0,0.24c-0.396,1.604-1.856,2.144-1.44,4.56
			c-0.47,1.615-1.968,2.234-2.88,3.36c-0.696,0.859-0.818,2.377-2.16,2.88c-6.76,2.535-14.822-0.31-17.52-6c0-0.08,0-0.16,0-0.24
			C256.858,640.727,256.344,637.367,256.532,633.862z M280.052,691.223c-0.187,0.213-0.258,0.542-0.24,0.96
			c-0.775,3.465-4.391,4.089-5.04,7.68c-1.52,1.44-3.748,2.173-6.48,2.4c-1.578-0.059-2.618,0.423-4.32,0.24
			c-3.774-0.443-10.344-3.207-6.96-6.96c-2.527-3.622-1.839-11.062,0.72-14.16c0-0.08,0-0.16,0-0.24
			c2.188-7.695,16.195-9.6,20.16-1.44c0,0.08,0,0.16,0,0.24c0.952,0.968,1.477,2.363,1.92,3.84c0,0.08,0,0.16,0,0.24
			C280.735,685.437,280.912,689.554,280.052,691.223z M301.412,680.183c-0.422,1.129-4.119,0.408-5.279,0c-0.08,0-0.16,0-0.24,0
			c-0.943-0.017-1.804-0.116-2.4-0.48c-0.08,0-0.16,0-0.24,0c-2.086-0.343-5.458-0.94-7.919-1.68c-2.204-0.662-5.621-1.246-5.76-3.6
			c3.385-0.454,7.193,1.414,11.04,2.399C294.363,677.783,298.665,678.069,301.412,680.183z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M181.412,614.662c-0.309,7.718,0.612,13.393,0,20.88
			c-0.311,3.806-0.5,10.053,0.24,12.24c0.42,1.241,2.377,2.231,2.64,3.12c0.735,2.484,0.309,6.34,0.48,9.36
			c0.191,3.36,0.183,6.486,0.72,8.88c-0.165,2.085,0.235,3.605,0.24,5.52c1.04,5.681,1.143,13.578,1.2,19.44
			c0.383,2.817,0.601,5.799,0.96,8.64c1.544,1.176-0.577,6.018,2.88,5.28c1.169,0.191,1.67,0.133,2.64,0
			c1.233-0.454,2.946-0.28,4.08-0.24c2.078-0.207,3.401,0.161,5.04-0.239c3.357-0.563,7-0.84,10.8-0.961c0.08,0,0.16,0,0.24,0
			c0.568-0.552,2.154-0.086,3.12-0.239c0.08,0,0.16,0,0.24,0c5.562-0.68,13.518,0.245,18.72-1.44
			c0.673-0.416,0.226-0.629,0.24-1.439c1.112-0.089,2.23-0.17,3.12-0.48c0-0.56,0-1.12,0-1.68c-2.032-0.768-5.45-0.15-7.68-0.721
			c-0.08,0-0.16,0-0.24,0c-1.443,0.558-3.918,0.082-5.76,0.24c-0.08,0-0.16,0-0.24,0c-5.927,0.406-14.041,1.313-18.72,0.721
			c0.636-1.444,2.856-1.305,4.32-1.921c1.236-0.043,1.995-0.564,3.12-0.72c0.292-0.667,1.474-0.445,2.4-0.479
			c0.331,0.01,0.527-0.113,0.72-0.24c0.411-0.55,1.832-0.088,2.64-0.24c0.24,0,0.48,0,0.72,0c0.568-0.552,2.154-0.086,3.12-0.24
			c0.16,0,0.32,0,0.48,0c0.806-0.554,2.636-0.084,3.84-0.239c0.24,0,0.48,0,0.72,0c1.845-0.641,4.503-0.026,6.48,0
			c0.64,0,1.28,0,1.92,0c1.136-0.16,2.839,0.319,2.64-1.681c0.145-0.574,0.345-1.095,0.24-1.92c0.069-2.891,0.107-5.813,0.48-8.399
			c0.056-1.545,0.385-2.814,0.24-4.561c0.719-6.081-0.236-13.836,1.2-19.2c-0.037-1.876,0.608-3.072,0.48-5.04
			c0.414-4.706,0.583-9.656,0.48-14.88c0-0.88,0-1.76,0-2.64c-0.695-2.505,0.206-6.606-0.48-9.12c0.169-1.609-0.247-2.634-0.24-4.08
			c-0.772-2.268-0.347-5.732-0.72-8.4c0.577-2.516-1.31-3.513-0.48-5.52c5.073-2.207,9.164-5.396,13.44-8.4
			c1.185-0.415,1.814-1.385,2.88-1.92c1.447-1.593,3.536-2.544,5.04-4.08c-2.253,0.123-5.331-0.655-7.44-1.439
			c-0.08,0-0.16,0-0.24,0c-1.145-0.136-2.309-0.251-3.12-0.721c-0.284-0.276-0.849-0.271-1.44-0.239
			c-8.149-1.531-16.032-3.328-22.8-6.24c-0.08,0-0.16,0-0.24,0c-2.136-1.063-4.306-2.094-6-3.601
			c4.308-0.817,10.294,0.71,13.92,2.16c2.418,0.702,4.767,1.474,7.44,1.92c3.513,0.673,7.363,1.566,11.04,2.4
			c3.571,0.81,7.536,1,9.84,3.36c0.231,1.19-0.64,1.279-0.72,2.16c3.731-2.509,7.059-5.421,10.8-7.921
			c1.563-0.756,2.764-1.876,4.08-2.88c3.035-2.726,6.235-5.284,8.88-8.399c-1.656-2.424-4-4.16-5.76-6.48c0-0.08,0-0.16,0-0.24
			c-5.441-5.839-10.247-12.313-15.36-18.479c0-0.08,0-0.16,0-0.24c-1.964-1.556-3.133-3.907-4.8-5.76
			c-0.241-0.959-1.022-1.378-1.44-2.16c-2.058-2.343-3.69-5.11-5.28-7.92c0-0.08,0-0.16,0-0.24c-1.824-2.656-3.11-5.85-4.56-8.88
			c-0.48-1.439-1.319-2.521-1.92-3.84c-2.246-3.194-3.989-6.892-5.76-10.561c0-0.08,0-0.159,0-0.239
			c-1.828-3.053-3.1-6.66-4.8-9.841c0-0.08,0-0.159,0-0.239c-2.124-3.236-3.742-6.979-5.28-10.801c0-0.08,0-0.159,0-0.239
			c-0.657-1.184-1.153-2.527-1.44-4.08c0.024-0.744-0.293-1.146-0.48-1.681c-1.428-3.853-2.694-9.145-3.6-12.72
			c0-0.08,0-0.16,0-0.24c-0.64-0.641-0.756-1.804-0.96-2.88c0-0.08,0-0.16,0-0.24c-0.706-0.494-0.485-1.915-0.96-2.64
			c-0.128-2.112-1.208-3.272-1.44-5.28c-0.619-1.065-1.111-3.523-0.96-4.56c0-0.08,0-0.16,0-0.24
			c-2.656-11.635-0.376-24.313-0.24-36.96c0.114-10.603,0.059-20.607-0.72-30.48c0-0.32,0-0.64,0-0.96
			c-0.418-0.018-0.746,0.053-0.96,0.24c-1.684,1.437-4.011,2.229-6,3.36c-0.08,0-0.16,0-0.24,0c-1.118,0.881-2.724,1.276-4.08,1.92
			c-1.551,0.529-2.941,1.219-4.32,1.92c-11.526,4.874-22.808,9.993-34.8,14.4c-0.08,0-0.16,0-0.24,0
			c-0.881,0.638-2.213,0.826-3.36,1.2c-0.08,0-0.16,0-0.24,0c-0.748,0.292-1.343,0.737-2.4,0.72c-0.33-0.01-0.527,0.113-0.72,0.24
			c-0.75,0.69-1.613,1.268-2.88,1.44c-0.08,0-0.16,0-0.24,0c-2.733,1.107-5.415,2.265-8.4,3.12c-0.08,0-0.16,0-0.24,0
			c-0.983,0.696-2.457,0.902-3.84,1.2c-0.08,0-0.16,0-0.24,0c-3.122,0.717-6.336,0.628-9.84,1.199
			c-0.098,0.579,0.438,0.522,0.24,1.2c0.063,0.978,0.451,1.629,0.72,2.4c0.311,1.453,1.289,3.506,1.2,4.56
			c-0.198,0.679,0.338,0.622,0.24,1.2c1.388,3.572,2.909,8.291,3.6,11.76c-0.018,0.418,0.054,0.747,0.24,0.96
			c2.238,5.843,3.024,13.136,4.56,19.681c-0.104,0.982,0.222,1.537,0.48,2.16c0.571,0.868,0.708,2.171,0.96,3.359
			c0,0.08,0,0.16,0,0.24c0.185,0.97-0.23,2.191,0.48,3.6c-0.248,0.964,0.552,2.753,0.72,4.08c1.846,8.954,3.341,18.259,6,26.4
			c0.917,2.923,1.974,5.706,2.88,8.64c1.645,3.875,2.737,8.303,4.08,12.48c-0.035-15.502-0.594-34.547,0.96-48.96
			c0-0.88,0-1.76,0-2.641c0-0.375,0.128-2.134,1.44-2.399c0.558,8.481,0.081,17.999,0.24,26.88c0,0.08,0,0.16,0,0.24
			c0.553,0.646,0.085,2.314,0.24,3.359c0,0.08,0,0.16,0,0.24c0.492,3.908,0.109,8.691,0.48,12.72c0,0.08,0,0.16,0,0.24
			c0.437,1.804,0.507,3.974,0.48,6.24c0.12,1.32-0.265,3.145,0.24,4.08c0.555,0.885,0.084,2.796,0.24,4.08
			c1.226,4.879,2.051,11.355,2.88,15.84c1.208,4.952,2.392,9.928,3.84,14.641c0.164,1.436,0.79,2.41,1.2,3.6
			c0.331,0.31,0.237,1.042,0.24,1.68c0.379-0.74,1.064-1.176,2.4-0.96c1.13,2.31,1.684,5.196,2.4,7.92
			c-0.521,0.5,0.029,0.776,0.24,1.2c-0.176,3.952,3.331,9.766,2.88,15.6c-0.256,3.317-2.114,8.832-5.04,7.921
			c-2.249-0.701-0.549-3.721,0.24-5.28c0.202-0.278,0.317-0.644,0.24-1.2c1.226-2.608-2.031-6.204,0-8.88
			c-0.933-4.291-1.474-8.293-2.64-12.72c-0.555-0.527-0.795,0.615-1.92,0.239c-0.159,2.881,0.319,6.399-0.24,8.881
			c0,0.239,0,0.479,0,0.72c-0.135,4.296,0.459,9.14,0.24,12.72c0,0.24,0,0.48,0,0.72c0.093,4.547,1.051,8.229,1.92,12
			c0,0.08,0,0.16,0,0.24c0.517,0.203,0.387,1.053,0.72,1.44C183.303,601.854,181.642,608.918,181.412,614.662z M245.492,598.582
			c0.16,0,0.32,0,0.48,0c0.411-0.549,1.832-0.088,2.64-0.24c0.08,0,0.16,0,0.24,0c1.842-0.832,5.869-0.959,6,0.961
			c0.194,2.845-6.674,1.509-9.6,1.439c-0.08,0-0.16,0-0.24,0c-2.126-0.193-4.272-0.368-5.28-1.68
			C241.526,598.776,243.248,598.418,245.492,598.582z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M167.732,617.782c-3.503-5.585-3.878-13.196-5.04-20.88
			c-1.885-12.467-2.012-25.262-0.72-39.12c0.501-5.375,0.45-11.498,0.48-17.04c0.018-3.302-1.084-6.527,1.44-7.68
			c-2.26-6.62-5.299-13.741-6.72-20.4c-0.356-1.564-1.009-2.831-1.44-4.32c-1.97-5.71-4.774-12.873-5.28-18.479
			c-0.154-1.366-0.671-2.369-0.72-3.84c-1.539-5.901-2.312-12.568-3.84-18.48c0.064-0.864-0.443-1.156-0.48-1.92
			c-0.722-1.229-2.085-3.45-0.96-5.04c0.08,0,0.16,0,0.24,0c0.16,0,0.32,0,0.48,0c-0.211-3.31-2.385-6.734-3.12-10.32
			c-0.491-2.368-1.29-4.674-1.92-6.239c-0.308-1.453-1.043-2.478-1.2-4.08c-3.339-0.243-4.496-2.471-7.2-5.04
			c-0.653-0.621-4.059-4.337-4.56-4.32c-0.326,0.011-1.928,2.107-2.16,2.4c-2.31,2.909-3.687,5.945-5.28,8.64
			c-0.013,0.307-0.308,0.332-0.24,0.72c-0.586,1.654-1.109,3.371-1.68,5.04c0,0.08,0,0.16,0,0.24c-0.279,4.339-1.981,7.893-1.2,12
			c-0.45,0.457-0.026,0.603,0,1.2c0.4,1.922,0.4,5.518,0,7.439c0,0.08,0,0.16,0,0.24c0.939,17.106,0.817,35.923-2.88,50.88
			c-2.89,11.692-6.702,22.764-8.16,35.04c0,0.16,0,0.32,0,0.48c2.875-4.646,4.574-10.466,7.92-14.641
			c0.013-0.307,0.308-0.332,0.24-0.72c2.107-3.093,4.064-6.336,6.48-9.12c0.258-0.621,0.868-0.892,0.96-1.68
			c2.502-3.654,4.799-7.67,6.96-12c0.603-1.208,1.293-3.496,3.36-2.88c0.266,0.693,0.5,2.287-0.24,2.64c0,0.08,0,0.16,0,0.24
			c-0.379,0.82-0.587,1.813-1.2,2.4c0,0.08,0,0.159,0,0.239c-0.511,1.409-1.123,2.718-2.16,3.601c0,0.08,0,0.16,0,0.24
			c-2.215,3.784-4.487,7.513-7.2,10.8c0,0.08,0,0.159,0,0.239c-0.608,1.072-1.223,2.138-2.16,2.881c0,0.08,0,0.159,0,0.239
			c-0.765,1.235-1.455,2.545-2.4,3.601c0,0.08,0,0.16,0,0.24c-1.07,1.489-1.667,3.452-2.88,4.8c-0.285,0.755-0.81,1.27-0.96,2.16
			c-1.102,2.098-1.848,4.552-3.12,6.479c0,0.08,0,0.16,0,0.24c-2.563,5.438-4.342,11.657-6,18c-0.292,0.747-0.559,1.521-0.48,2.64
			c-0.125,1.958-0.93,5.309-1.92,7.2c0,0.08,0,0.16,0,0.24c-1.78,6.699-4.163,12.797-6.48,18.96c0,0.08,0,0.16,0,0.24
			c-2.986,8.063,1.196,15.966,0.72,24.479c0,0.08,0,0.16,0,0.24c2.159,12.284-4.324,20.017-5.52,30
			c-1.166,9.737,3.241,17.486,2.4,27.84c-4.252-1.988-3.767-8.713-5.04-13.68c-0.832,0.049-0.437,1.323-0.72,1.92
			c-0.08,0-0.16,0-0.24,0c-0.286,1.234-0.534,2.507-0.72,3.84c-0.086,1.837-0.592,5.326-0.72,7.92
			c-0.251,1.349-0.407,2.793-0.72,4.08c0.228,1.831-0.587,4.691-0.96,6.721c-0.211,0.424-0.761,0.7-0.24,1.199
			c-1.046,4.194-1.225,7.773-2.88,11.28c0,0.08,0,0.16,0,0.24c0.064,0.864-0.443,1.156-0.48,1.92c0.778-2.563,4.794-5.413,6.48-1.68
			c-0.348,0.852-0.34,2.06-1.2,2.399c-0.103,0.457-0.517,0.604-0.48,1.2c-1.284,1.756-2.125,3.954-3.36,5.76c0,0.08,0,0.16,0,0.24
			c-1.08,1.72-1.61,3.99-2.88,5.521c0,0.079,0,0.159,0,0.239c-2.033,4.767-4.44,9.16-6.72,13.681c0,0.079,0,0.16,0,0.239
			c-1.142,2.538-1.929,5.431-3.12,7.921c0,0.159,0,0.319,0,0.479c-0.316,1.924-0.809,3.672-1.44,5.28
			c-0.209,1.551-0.861,2.658-0.96,4.319c-0.914,4.526-2.166,8.715-2.64,13.681c0.56,0,1.12,0,1.68,0
			c3.88,0.038,8.298-0.661,11.76-0.24c0.24,0,0.48,0,0.72,0c9.76,0,19.52,0,29.28,0c0.4,0,0.8,0,1.2,0
			c12.32-0.552,28.477-0.167,41.04-1.2c0-0.08,0-0.16,0-0.24c0.341-3.818,1.259-7.061,2.4-10.079
			c0.404-3.036,1.218-5.662,1.44-8.881c0.25-2.869,0.84-5.399,1.2-8.16c0-0.079,0-0.159,0-0.239
			c0.507-5.093,0.781-10.419,1.44-15.36c0.201-0.278,0.316-0.644,0.24-1.2c-0.256-1.46,0.268-4.21,0.48-6c0-0.8,0-1.6,0-2.399
			c-0.185-1.865,0.312-3.049,0.24-4.801c0-0.399,0-0.8,0-1.199c0.022-2.618,0.27-5.01,0.72-7.2c0-0.32,0-0.641,0-0.96
			c0.164-1.597,0.332-3.188,0.96-4.32c0-0.08,0-0.16,0-0.24c0.213-0.907,0.18-2.061,0.72-2.64c0-0.08,0-0.16,0-0.24
			c0.251-1.188,0.389-2.491,0.96-3.359c0-0.08,0-0.16,0-0.24c2.559-11.361,5.78-22.061,8.88-32.88c0-0.08,0-0.16,0-0.24
			c0.18-1.66,0.715-2.966,1.2-4.32c-0.245-0.534-1.104,0.314-1.92,0c0-0.08,0-0.16,0-0.24c-0.08,0-0.16,0-0.24,0
			c-1.49-0.96-1.828-5.75-2.4-8.88c-1.498-8.201-4.854-19.327-7.68-26.16c0-0.08,0-0.159,0-0.239c-0.431-0.85-0.921-1.639-1.2-2.641
			c0.01-0.33-0.113-0.526-0.24-0.72c-1.345-2.176-3.674-4.645-2.64-8.4c0.325,0.076,0.458,0.342,0.96,0.24
			c0.814,0.386,2.071,0.329,2.16,1.44c0,0.08,0,0.16,0,0.239c0.842,1.078,1.568,2.272,2.16,3.601c0.112,0.848,0.606,1.313,0.96,1.92
			c3.931,7.829,6.417,17.103,8.88,26.4c-0.208,0.708,0.544,2.263,0.72,3.359c0.906,1.574,0.235,4.726,1.44,6
			c0.076-0.324,0.342-0.458,0.24-0.96c-0.031-0.995,0.599-3.397,1.68-4.08c0.121-4.681-0.649-8.471-1.44-12.239
			C169.149,628.004,169.979,621.091,167.732,617.782z M129.332,508.582c-0.497,0.843-0.981,2.421-2.4,1.92
			c0.305-3.77,2.981-7.028,4.8-10.32c1.886-3.412,3.47-6.871,5.04-10.319c0.462-1.015,0.33-2.509,2.16-2.641
			c0.367,4.329-2.775,8.773-4.8,12.48C132.533,502.63,131.093,505.596,129.332,508.582z M158.612,677.543c-0.08,0-0.16,0-0.24,0
			c-4.162,1.678-8.884,2.795-13.92,3.6c-0.08,0-0.16,0-0.24,0c-0.678,0.362-1.656,0.424-2.64,0.479
			c-0.691,0.029-1.571-0.131-1.92,0.24c-1.586,0.417-4.349,0.928-5.76,0.72c-0.08,0-0.16,0-0.24,0
			c-3.003,0.97-7.898,1.045-10.32-0.479c-0.162-1.122,1.028-0.892,1.68-1.2c0.08,0,0.16,0,0.24,0c0.537-0.663,1.65-0.75,2.4-1.2
			c10.59-0.85,20.357-2.523,29.28-5.04c0.08,0,0.16,0,0.24,0c0.559-0.401,1.293-0.627,2.16-0.72c0.16,0,0.32,0,0.48,0
			c0.915-0.664,2.635-0.083,2.88,0.72C162.557,676.848,160.374,676.983,158.612,677.543z M162.932,657.143
			c0.541,0.623-0.193,1.607,0.24,1.92c0.08,0,0.16,0,0.24,0c2.289-0.891,3.047,1.924,2.64,3.36c-0.844,2.979-8.411,2.746-11.04,3.84
			c-0.08,0-0.16,0-0.24,0c-2.439,0.841-5.349,1.211-8.4,1.439c-0.08,0-0.16,0-0.24,0c-6.435,1.251-14.999,1.09-20.88-0.72
			c-0.08,0-0.16,0-0.24,0c-7.072-1.809-14.332-3.429-20.4-6.24c-0.519-0.201-0.818-0.621-1.68-0.479
			c-1.115-0.165-2.032-0.528-2.4-1.44c0.2-1.452,2.305-1.023,3.12-0.96c3.271,0.253,7.054,2.331,10.8,3.36
			c3.871,1.063,7.698,1.818,11.28,2.88c0.08,0,0.16,0,0.24,0c1.967,0.112,3.871,0.289,5.52,0.72c0.08,0,0.16,0,0.24,0
			c1.711-0.031,3.395-0.034,4.8,0.24c0.08,0,0.16,0,0.24,0c6.089-0.303,14.274-1.438,20.88-2.88c1.104-0.241,4.901-0.551,4.56-1.92
			c-0.233-0.938-0.084-0.16-1.2-0.24c-0.08,0-0.16,0-0.24,0c-6.481,0.156-14.092-0.625-20.4-1.44c-0.16,0-0.32,0-0.48,0
			c-4.929-0.662-10.72,0.239-15.36-0.239c-0.08,0-0.16,0-0.24,0c-0.912-0.049-1.708-0.213-2.4-0.48
			c0.861-0.659,1.38-1.659,2.64-1.92c0.502,0.102,0.636-0.164,0.96-0.24c0.891-0.548,2.387-0.492,3.84-0.479c0.24,0,0.48,0,0.72,0
			c1.124-0.557,3.277-0.083,4.8-0.24c0.08,0,0.16,0,0.24,0c5.2,0.048,10.813,0.949,16.32,1.2
			C155.425,656.365,159.755,656.013,162.932,657.143z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M279.092,766.343c0-0.08,0-0.16,0-0.24
			c-6.099-5.661-11.66-11.86-17.28-18c0-0.08,0-0.16,0-0.24c-4.409-4.15-8.45-8.67-12.48-13.2c0-0.079,0-0.159,0-0.239
			c-2.849-2.591-5.173-5.707-7.68-8.641c0-0.079,0-0.16,0-0.239c-3.863-4.058-7.234-8.606-10.56-13.2
			c-0.285-0.916-0.879-1.521-1.2-2.4c-2.568,0.408-3.606-0.714-5.76-0.72c-1.76,0-3.52,0-5.28,0c-1.442-0.082-2.12,0.601-3.12,0.96
			c2.96,2.72,4.867,6.493,7.44,9.6c0.353,1.168,1.322,1.718,1.92,2.641c1.836,1.764,3.289,3.911,4.8,6
			c0.143,0.897,0.839,1.24,1.2,1.92c3.392,4.368,6.443,9.077,9.84,13.439c0.62,1.461,1.801,2.359,2.64,3.601
			c5.022,5.697,10.222,11.218,15.6,16.56c0.08,0,0.16,0,0.24,0c1.721,1.8,4.119,2.921,5.52,5.04c5.28,0,10.56,0,15.84,0
			C281.709,768.442,279.438,767.177,279.092,766.343z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M241.652,750.742c0-0.079,0-0.16,0-0.239
			c-3.458-3.503-6.105-7.815-9.12-11.761c0-0.079,0-0.16,0-0.239c-1.23-1.011-1.926-2.555-2.88-3.841
			c-1.099-2.261-2.697-4.023-4.08-6c-1.099-0.82-1.763-2.076-2.64-3.119c0-0.08,0-0.16,0-0.24c-3.537-3.743-6.401-8.159-9.36-12.48
			c-0.115-0.859-0.391-1.387,0-2.16c-0.32,0-0.64,0-0.96,0c-0.336,0.398-2.304,0.398-2.64,0c-3.203,0.171-5.984,0.29-8.64,0.48
			c-0.333,0.547-1.67,0.09-2.4,0.24c-0.08,0-0.16,0-0.24,0c-1.215,0.318-2.648,0.983-3.6,0.24c3.219,4.541,6.63,8.89,10.08,13.199
			c-0.043,0.843,0.811,0.79,0.96,1.44c5.616,6.384,10.845,13.154,16.32,19.68c0,0.08,0,0.16,0,0.24
			c5.457,6.383,11.763,11.917,18.72,16.8c0.08,0,0.16,0,0.24,0c2.188,1.616,5.61,2.908,3.84,6c0.039-0.068,0.412,0.053,0.48,0
			c4.484-0.202,10.603,0.38,13.92-0.24c-0.307-0.013-0.333-0.308-0.72-0.239C252.508,763.247,247.022,757.053,241.652,750.742z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M241.652,768.503c-1.619-1.582-3.786-2.615-5.28-4.32
			c-0.679-0.36-1.022-1.058-1.92-1.2c-4.246-3.033-7.995-6.564-11.28-10.56c0-0.08,0-0.16,0-0.24
			c-3.654-3.547-6.607-7.793-9.84-11.76c0-0.08,0-0.16,0-0.24c-2.955-3.125-5.443-6.717-8.16-10.08
			c0.042-0.843-0.811-0.789-0.96-1.44c-2.944-2.975-5.319-6.52-7.92-9.84c0-0.08,0-0.16,0-0.239c-2.105-1.896-3.371-4.63-5.28-6.721
			c-0.4,0-0.8,0-1.2,0c-1.493-0.41-3.268-0.171-4.56,0c-1.245-0.115-2.979,0.259-3.84-0.239c0.139,0.778-0.164,1.116-0.24,1.68
			c1.229,0.931,1.911,2.408,2.64,3.84c0.794,2.166,2.197,3.723,3.36,5.52c1.44,1.36,2.346,3.254,3.36,5.04c0,0.08,0,0.16,0,0.24
			c1.193,1.446,2.077,3.204,3.12,4.8c0.526,1.474,1.478,2.522,2.16,3.841c3.186,5.214,6.043,10.757,10.08,15.12
			c0,0.079,0,0.159,0,0.239c2.47,2.79,5.243,5.482,8.4,8.641c2.787,2.787,6.254,7.516,10.08,8.399c5.776-0.011,12.778,0.016,17.76,0
			C242.696,768.979,242.044,768.328,241.652,768.503z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M193.172,738.742c-0.951-0.969-1.417-2.423-2.16-3.6
			c0-0.08,0-0.16,0-0.24c-0.881-0.719-1.287-1.913-1.92-2.88c-0.617-1.623-1.57-2.91-2.4-4.32c-2.532-3.067-4.432-6.768-6.72-10.079
			c-0.429,0.879-0.56,1.832-0.24,2.88c0.447,2.833,1.203,5.356,1.92,7.92c0.96,3.04,2.278,5.722,2.88,9.12
			c0.727,2.873,1.869,5.331,3.36,7.439c5.491,7.949,11.68,15.2,17.52,22.8c0,0.08,0,0.16,0,0.24c1.871,1.601,8.229,0.293,11.52,0.72
			c-7.57-7.949-16.798-16.321-22.32-27.119C194.303,740.492,193.737,739.618,193.172,738.742z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M411.813,720.982c-2.715-0.154-4.706,0.414-7.44,0.24
			c-0.036,0.597,0.378,0.743,0.48,1.2c0.34,1.34,1.117,2.242,1.68,3.359c0.862,0.818,1.285,2.075,1.92,3.12
			c0.112,0.848,0.606,1.314,0.96,1.92c1.042,1.118,1.639,2.682,2.4,4.08c0.323,1.117,0.839,2.041,1.68,2.641
			c-0.027-1.403,1.114-0.152,1.92-0.721c1.632-1.425,0.945-6.393,0.721-8.88c-0.714-0.326-0.422-1.658-0.48-2.64
			c0.077-1.799-1.76-2.552-3.359-1.92c0.018-0.418-0.054-0.747-0.24-0.96C411.522,722.393,411.95,721.404,411.813,720.982z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M415.893,763.942c0.064-3.184-1.118-5.122-2.64-6.72
			c-2.203-2.998-4.081-6.32-6.24-9.36c0.175-0.654-0.478-0.482-0.48-0.96c-0.995-0.684-1.401-1.958-2.16-2.88c0-0.08,0-0.16,0-0.24
			c-3.604-4.556-6.881-9.438-10.319-14.159c0-0.08,0-0.16,0-0.24c-2.468-2.413-4.028-5.732-6.721-7.92c-4.239,0-8.479,0-12.72,0
			c-0.519,0.713-0.353,1.223-2.16,0.96c2.815,3.984,6.371,7.229,9.36,11.04c0,0.08,0,0.16,0,0.239
			c1.646,1.315,2.736,3.184,4.08,4.801c0.301,1.06,1.165,1.555,1.68,2.399c2.8,2.961,4.912,6.608,7.44,9.84c0,0.08,0,0.16,0,0.24
			c1.174,1.066,2.057,2.424,3.12,3.601c0,0.079,0,0.16,0,0.239c2.382,2.338,4.924,4.516,7.2,6.96
			c0.057,0.343,0.395,0.405,0.479,0.721c1.2,1.565,4.902,3.035,3.36,5.76c0,0.08,0,0.16,0,0.24c1.791,0.699,4.108-0.014,5.76-0.24
			c0.754,0.113,0.634-0.646,1.2-0.72c0-0.4,0-0.801,0-1.2C415.585,766.01,416.043,764.672,415.893,763.942z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M415.173,745.223c0-0.08,0-0.16,0-0.24
			c-0.674-0.927-1.148-2.052-1.92-2.88c0-0.08,0-0.16,0-0.24c-0.909-0.931-2.929-0.752-3.12-2.399c0-0.08,0-0.16,0-0.24
			c-1.193-1.207-1.783-3.017-2.641-4.561c-1.052-2.547-2.591-4.608-3.84-6.96c-0.953-0.807-1.231-2.288-1.92-3.359
			c0-0.08,0-0.16,0-0.24c-0.759-0.601-1.079-1.641-1.439-2.64c-0.961,0-1.921,0-2.881,0c-0.443,0.036-0.146,0.813-0.479,0.96
			c-0.88,0-1.76,0-2.64,0c-0.172-0.629-0.361-1.239-1.44-0.96c1.256,2.824,4.424,5.815,6.24,8.88c0,0.08,0,0.16,0,0.24
			c2.479,2.801,4.519,6.04,6.72,9.119c0.143,0.898,0.839,1.241,1.2,1.921c1.483,1.396,2.354,3.404,3.6,5.039
			c-0.01,0.331,0.113,0.527,0.24,0.721c0.646,0.715,0.93,1.79,1.44,2.64c0.523,4.51,7.064,1.904,4.08-1.92
			C415.774,747.341,415.304,746.451,415.173,745.223z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M405.093,768.263c-0.324-0.235-0.479-0.641-0.721-0.96
			c-1.113-2.7-5.036-5.675-7.199-7.92c-2.588-2.687-5.423-5.933-6.96-8.4c0-0.08,0-0.16,0-0.24c-1.321-1.238-2.269-2.852-3.36-4.319
			c0-0.08,0-0.16,0-0.24c-1.409-1.312-2.364-3.076-3.6-4.56c-0.669-1.571-1.994-2.486-2.881-3.841
			c-3.831-4.089-7.469-8.37-11.279-12.479c0-0.08,0-0.16,0-0.24c-1.112-0.728-1.793-1.887-2.641-2.88c0-0.08,0-0.16,0-0.24
			c-5.901-0.295-13.371-0.096-18.96,0.72c0.264,1.34,2.931,2.348,3.84,3.841c0.733,0.227,0.753,1.167,1.681,1.199
			c1.167,1.073,2.652,1.828,3.6,3.12c0.08,0,0.16,0,0.24,0c9.775,8.545,19.863,16.777,27.84,27.12
			c1.714,2.687,3.878,4.923,5.521,7.681c1.034,0.806,1.365,2.313,2.399,3.119c4.24,0,8.48,0,12.721,0
			C405.333,768.502,405.139,768.457,405.093,768.263z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M385.893,766.103c-5.539-7.501-11.605-14.475-18.24-20.88
			c-0.08,0-0.16,0-0.24,0c-8.231-8.009-17.977-14.504-25.92-22.8c-1.774-0.063-5.371,0.045-7.92,0
			c-0.646,0.553-2.314,0.085-3.359,0.239c5.878,6.682,11.726,13.395,17.52,20.16c0,0.08,0,0.16,0,0.24
			c1.529,1.191,2.527,2.913,3.84,4.32c0,0.08,0,0.16,0,0.24c5.103,5.697,11.906,9.693,17.28,15.119c0.97,0.631,1.74,1.46,2.88,1.921
			c1.333,1.387,3.41,2.029,4.08,4.079c3.761-0.159,8.159,0.32,11.52-0.239c-0.057-0.343-0.395-0.405-0.479-0.721
			C386.76,766.995,386.15,766.725,385.893,766.103z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M368.372,767.063c-0.08,0-0.159,0-0.239,0
			c-9.042-7.679-18.718-14.723-25.681-24.479c0-0.08,0-0.16,0-0.24c-1.946-1.733-3.544-3.815-5.279-5.76c0-0.08,0-0.16,0-0.24
			c-1.11-0.73-1.744-1.937-2.641-2.88c0-0.08,0-0.16,0-0.24c-1.733-1.479-3.075-3.436-5.04-5.521
			c-1.223-1.296-3.644-4.689-5.28-5.04c-1.984-0.425-5.049,0.653-7.439,0.961c-2.553,0.328-4.957,0.528-6.96,1.439
			c1.198,0.562,2.32,1.199,3.12,2.16c0.621,0.258,0.892,0.867,1.68,0.96c1.226,0.935,2.657,1.663,3.6,2.88
			c1.418,0.982,2.518,2.282,4.08,3.12c11.51,11.53,23.333,22.747,34.561,34.56c4.32,0,8.64,0,12.96,0
			C370.199,768.263,368.484,767.842,368.372,767.063z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M323.013,763.463c-13.755-13.057-30.812-24.099-41.28-39.12
			c-0.966-0.154-2.552,0.312-3.12-0.24c-1.21-0.197-2.339-0.244-3.6,0c-0.808-0.152-2.229,0.31-2.64-0.24
			c-1.305,0.071-2.377-0.479-4.08-0.239c13.851,15.589,28.658,30.222,42.959,45.359c4.665,0.017,11.343-0.012,16.801,0
			c0.021,0.019,0.232-0.022,0.239,0C327.837,767.499,324.841,765.198,323.013,763.463z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M342.692,760.343c-9.093-8.324-17.408-18.855-26.16-25.681
			c0-0.079,0-0.159,0-0.239c-1.355-0.964-2.446-2.193-4.08-2.88c-0.963-0.638-1.917-1.283-2.88-1.92c0-0.08,0-0.16,0-0.24
			c-1.444-0.796-2.47-2.01-4.08-2.641c-0.888-0.429-1.148-2.09-2.64-1.199c-0.08,0-0.16,0-0.24,0
			c-4.215-0.026-8.641,0.161-12.24-0.48c-0.08,0-0.16,0-0.239,0c-1.303,0.183-1.944-0.296-3.121-0.24
			c12.725,16.556,32.706,27.935,46.56,44.16c3.601,0,7.2,0,10.8,0c1.211-1.217,8.233,1.2,6-1.2
			C347.816,765.035,345.417,762.837,342.692,760.343z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M193.172,757.703c-1.01-0.75-1.658-1.862-2.4-2.881
			c-0.142-0.897-0.839-1.24-1.2-1.92c-1.323-1.157-2.036-2.924-3.12-4.319c0.175-0.655-0.477-0.483-0.48-0.96
			c-1.884-2.117-3.161-4.84-4.56-7.44c0-0.08,0-0.16,0-0.24c-1.663-1.777-2.162-4.718-4.08-6.24c0,0.24,0,0.48,0,0.721
			c0,0.16,0,0.319,0,0.479c0.471,0.649,0.647,1.593,0.72,2.641c-0.08,0.879,0.266,1.333,0.48,1.92
			c0.895,1.984,1.555,4.204,2.16,6.479c0,0.08,0,0.16,0,0.24c1.44,3.767,4.29,7.382,4.56,11.521c0.464,0.976,1.136,1.743,1.68,2.64
			c0.486,1.194,1.226,2.134,1.92,3.12c1.654,1.705,2.85,3.87,4.8,5.279c2.72,0,5.44,0,8.16,0c-1.624-2.297-4.454-5.466-6.48-8.159
			C194.854,759.382,193.807,758.748,193.172,757.703z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M186.692,763.942c-2.53-3.069-4.643-6.558-6.72-10.08
			c0-0.079,0-0.16,0-0.239c-0.781-0.66-1.153-1.728-1.68-2.641c0-0.08,0-0.16,0-0.24c-0.731-0.148-0.469-1.291-1.2-1.439
			c-0.018,0.418,0.053,0.746,0.24,0.96c0.476,0.805,0.79,1.771,0.96,2.88c-0.079,0.88,0.267,1.334,0.48,1.92
			c0.574,0.946,0.83,2.211,1.2,3.36c0.063,0.977,0.451,1.628,0.72,2.399c1.437,2.804,3.184,5.297,4.8,7.92c1.44,0,2.88,0,4.32,0
			c-0.453-1.307-1.576-1.943-2.16-3.119C187.56,764.835,186.95,764.564,186.692,763.942z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M495.093,570.502c0-0.8,0-1.6,0-2.399c0-0.16,0-0.32,0-0.48
			c-0.558-1.442-0.082-3.918-0.24-5.76c0-0.16,0-0.32,0-0.48c-0.557-1.203-0.082-3.438-0.24-5.04c0-0.159,0-0.319,0-0.479
			c-0.704-1.192,0.336-2.699,0-4.32c0-0.16,0-0.319,0-0.479c-0.67-1.079,0.034-2.84,0-4.08c0-0.16,0-0.32,0-0.48
			c-0.398-0.57-0.398-2.789,0-3.359c0-0.16,0-0.32,0-0.48c-0.553-0.646-0.085-2.314-0.239-3.36c0-0.079,0-0.159,0-0.239
			c0-0.88,0-1.761,0-2.641c0-0.08,0-0.16,0-0.239c-0.739-0.678,0.249-1.657,0-2.641c0-0.08,0-0.16,0-0.24
			c-0.342-1.656-0.366-3.577,0-5.279c0-0.08,0-0.16,0-0.24c-0.548-0.333-0.09-1.671-0.24-2.4c-0.685-0.546,0.337-1.406,0-2.399
			c-0.397-0.259-0.397-2.142,0-2.4c0-0.08,0-0.16,0-0.24c-0.77-2.54-0.063-5.172-0.72-7.92c0.08,0,0.159,0,0.239,0
			c0.007-1.446-0.409-2.471-0.239-4.08c-0.188-2.341-0.424-5.092-0.48-8.16c-0.683-4.356-0.655-9.424-1.2-13.92
			c-0.233-2.406-0.569-4.71-0.72-7.199c-0.41-0.95-0.266-2.455-0.479-3.601c-0.547-1.534-0.712-3.448-0.961-5.28
			c-1.029-5.449-1.915-11.044-3.119-16.319c-1.939-7.341-5.002-13.56-7.44-20.4c-0.39-0.41-0.727-0.873-0.72-1.68
			c-0.459-0.502-0.774-1.146-0.96-1.92c-0.492-0.389-0.605-1.155-0.96-1.681c-0.824-1.176-1.502-2.498-2.16-3.84
			c-0.399-0.48-0.871-0.889-0.96-1.68c-0.817-0.543-1.055-1.666-1.681-2.4c-0.85-0.43-1.021-1.538-2.159-1.68
			c-1.821-0.339-3.192-1.128-4.561-1.92c-2.673-0.928-5.565-1.634-9.12-1.68c0-0.08,0-0.16,0-0.24
			c-0.751,0.128-0.864,0.895-0.96,1.68c0.372,2.509,3.847,1.914,5.04,3.6c-1.436-0.884-3.284-1.355-4.8-2.159
			c0.12,1.88-0.128,3.392-0.24,5.04c-1.097,12.79-3.998,22.953-6.24,34.319c-0.276,0.284-0.271,0.849-0.239,1.44
			c-1.116,6.658-2.614,12.667-3.36,20.16c0,0.08,0,0.16,0,0.24c-0.034,1.485-0.11,2.93-0.479,4.08c0,0.079,0,0.159,0,0.239
			c0.114,5.57-0.339,11.062-0.721,16.8c0,0.16,0,0.32,0,0.48c0.301,4.803-0.052,9.351-0.479,14.16c0,0.08,0,0.16,0,0.24
			c0.141,0.787-0.177,3.179-0.48,4.319c0,0.08,0,0.16,0,0.24c0.384,0.714-0.242,2.471-0.479,3.36c-0.094,0.342-0.288,0.404,0,0.72
			c-0.07,1.29-0.252,2.469-0.721,3.36c0.118,0.757-0.432,0.848-0.239,1.68c0.005,1.365-0.407,2.313-0.721,3.36
			c0,0.08,0,0.159,0,0.239c-0.745,3.335-1.507,6.653-2.64,9.601c2.976,3.096-0.132,8.185-0.96,12c0,0.08,0,0.16,0,0.24
			c-1.004,4.516-1.604,9.436-2.64,13.92c2.449-0.912,5.826-2.973,9.119-3.84c2.313-0.648,4.408-1.513,6.721-2.16
			c2.135-0.794,5.809-2.35,8.16-0.721c-0.139,0.423,0.29,1.41-0.24,1.44c-0.916,0.124-0.734,1.346-1.68,1.44
			c-4.371,2.028-9.841,2.959-14.16,5.04c-1.068,0.371-2.23,0.649-3.12,1.199c-2.005,1.355-4.15,2.57-5.521,4.561
			c0,0.08,0,0.16,0,0.24c-1.292,1.763-0.394,4.195-0.96,6.239c-0.418,1.51-2.209,2.255-1.92,3.84c2.561,0.96,6.39,0.65,8.88,1.681
			c2.448,0.752,4.937,1.463,7.44,2.16c2.547,0.652,5.162,1.238,6.24,3.359c-6.992,1.944-13.313-3.829-22.08-3.359
			c-1.975-0.771-3.435-0.491-6.24-0.96c-0.298,1.622-0.476,3.364-1.2,4.56c0.006,0.565-0.413,0.707-0.24,1.44
			c-0.437,1.149,1.249,0.407,2.4,0.239c5.478-0.798,12.5-3.792,16.56,0c0,0.08,0,0.16,0,0.24c1.283,1.047-0.035,2.863-0.96,3.12
			c-1.793,0.499-2.966-1.486-4.56-1.68c-3.567-0.433-9.997,2.739-14.16,2.399c-0.474,0.566-0.565,1.515-0.72,2.4
			c1.004,0.676,2.554,0.806,3.84,1.2c0.424,0.21,0.7,0.761,1.2,0.239c0.917,0.99,12.004,1.021,11.04,4.561
			c-0.663,2.432-6.61,0.535-9.601-0.24c-2.688-0.697-5.3-1.441-7.2-2.16c-2.011,4.149-3.223,9.097-5.76,12.721
			c0,0.08,0,0.159,0,0.239c0.901,5.904,1.59,11.074,3.12,16.561c0,0.08,0,0.16,0,0.24c3.918,14.655,1.621,32.236,1.44,49.92
			c3.994-0.405,6.835-1.965,10.319-2.88c0.418,0.018,0.747-0.054,0.96-0.24c0.708-0.732,2.142-0.739,3.12-1.2
			c0.418,0.019,0.747-0.054,0.96-0.24c3.076-1.403,6.671-2.289,10.08-3.359c0.08,0,0.16,0,0.24,0c0.302-0.251,1.707-0.75,0.24-0.721
			c-0.08,0-0.16,0-0.24,0c-7.446,0.903-18.365,2.421-23.52-0.479c-0.566-1.192-0.473-2.119,0.479-2.88
			c0.051-0.27,0.267-0.374,0.48-0.48c11.284,2.568,24.03-2.928,35.04,0.24c7.71-2.37,14.363-5.797,22.56-7.68c0-0.08,0-0.16,0-0.24
			c0.105-0.825-0.095-1.346-0.24-1.92c0.555,0.245,0.497,1.104,0.48,1.92c1.368,0.003,2.978-1.016,4.08-0.48
			c0.341,0.021,0.68,0.04,0.72-0.239c-0.684-1.41,0.003-3.514,0.72-4.32c0.28-0.04,0.261-0.38,0.24-0.72
			c0.123-1.317,0.724-2.156,1.2-3.12c0.536-0.104,0.097-1.183,0.24-1.681c0.248-1.751,0.359-3.64,0.96-5.04
			c0.203-3.556,1.224-6.296,1.439-9.84c0.466-1.534,0.533-3.467,0.96-5.04c0.326-2.634,0.687-5.233,1.2-7.68
			c-0.015-2.335,0.497-4.144,0.72-6.24c0.358-1.081-0.002-2.882,0.48-3.84c0.549-0.411,0.088-1.832,0.24-2.64
			c0.064-0.815-0.187-1.946,0.239-2.4c0.156-1.125-0.313-2.874,0.24-3.6c0-0.32,0-0.641,0-0.96c0.369-0.831,0.212-2.188,0.24-3.36
			c0.246-7.674,0.318-15.521,0.479-23.28c0-1.28,0-2.56,0-3.84c-0.013-0.307-0.308-0.332-0.239-0.72
			C494.776,574.019,495.25,571.945,495.093,570.502z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M276.212,141.381c0.38-0.02,0.916,0.115,0.96-0.24
			c-0.96,0-1.92,0-2.88,0C274.471,141.683,275.639,141.235,276.212,141.381z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M203.012,210.262c0.541-0.179,0.094-1.346,0.24-1.92
			c0-0.16,0-0.32,0-0.48C202.705,208.194,203.163,209.532,203.012,210.262z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M499.893,598.822c0.08,0,0.16,0,0.24,0c0-3.6,0-7.2,0-10.8
			C499.573,591.143,500.052,595.302,499.893,598.822z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M457.173,773.063c0,0.08,0,0.16,0,0.24
			c0.887-0.153,2.391,0.311,2.88-0.24c-0.4,0-0.8,0-1.2,0C458.293,773.063,457.732,773.063,457.173,773.063z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M434.373,773.543c0.729-0.15,2.066,0.307,2.399-0.24
			c-0.8,0-1.6,0-2.399,0C434.373,773.383,434.373,773.463,434.373,773.543z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M64.772,773.543c-0.48,0-0.96,0-1.44,0
			c0.647,0.553,2.314,0.085,3.36,0.239C66.513,773.241,65.346,773.688,64.772,773.543z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M400.532,773.543c0,0.08,0,0.16,0,0.239
			c2.562-0.158,5.759,0.319,7.92-0.239c-0.72,0-1.439,0-2.159,0C404.372,773.543,402.452,773.543,400.532,773.543z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M111.092,774.022c0,0.08,0,0.16,0,0.24
			c0.887-0.153,2.391,0.311,2.88-0.24c-0.4,0-0.8,0-1.2,0C112.212,774.022,111.652,774.022,111.092,774.022z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M343.412,351.382c1.526,0.006,2.116-0.924,3.601-0.96
			c1.398-0.521,2.448-1.391,3.84-1.92c0.553-0.328,1.787-1.999,0-1.92c-0.927,0.673-1.497,1.702-2.88,1.92
			c-1.07,0.45-1.916,1.125-3.12,1.44C344.544,350.594,343.623,350.633,343.412,351.382z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M487.173,750.503c0-0.16,0-0.32,0-0.48
			c0.399-8,0.399-17.68,0-25.68c-0.384-2.111,0.105-4.298-0.48-5.76c-0.128-1.472-0.1-3.101-0.479-4.32c0-0.08,0-0.16,0-0.24
			c-0.336-3.344-1.53-5.829-2.4-8.64c-0.086-1.834-0.771-3.069-1.2-4.561c-0.168-1.512-0.249-3.11-0.72-4.319
			c-0.289-3.312-0.638-6.563-0.479-10.32c0.549-0.411,0.088-1.832,0.239-2.64c0.375-0.745,0.666-1.574,1.44-1.921
			c-0.019-0.418,0.054-0.746,0.24-0.96c-0.353,0.216-2.602,0.594-3.12,1.44c0.766-0.206,0.742,0.378,1.2,0.479
			c-1.339,2.791-4.441,6.907-7.681,7.2c-0.616-0.663-0.784-1.775-1.2-2.64c-0.336-2.416,0.889-3.271,1.44-4.8
			c1.819-2.661,3.091-5.869,4.8-8.641c0.896-1.984,2.469-3.292,3.12-5.52c0-0.08,0-0.16,0-0.24c-0.86-0.25-1.361,0.07-2.16,0.48
			c-2.062,0.657-4.17,1.27-6.24,1.92c-0.079,0-0.159,0-0.239,0c-1.06,0.62-2.471,0.89-3.84,1.2c-0.08,0-0.16,0-0.24,0
			c-6.722,2.479-13.53,4.869-20.641,6.96c-0.08,0-0.159,0-0.239,0c-9.186,2.975-17.818,6.501-27.601,8.88
			c-0.188,0.988,0.368,1.231,0.24,2.16c7.535,0.203,15.255-1.522,21.6-0.961c0.281,0.469,0.789,1.344,0.24,1.921
			c-0.08,0-0.16,0-0.24,0c-1.156,0.442-2.494,0.705-3.6,1.199c-3.668,1.052-8.563,0.877-12.96,1.2
			c-1.474,0.127-3.475-0.274-4.561,0.24c0.168,3.086,0.149,6.764,0.24,8.64c-0.403,4.13,0.929,8.556-0.479,11.521
			c0,3.12,0,6.24,0,9.359c-0.03,2.632-0.381,9.154,0.479,12.961c0.559,1.762,0.082,4.559,0.24,6.72c0.069,3.051-0.261,6.5,0.48,8.88
			c0.547,0.333,0.089,1.671,0.239,2.4c-0.086,1.205,0.148,2.092,0.48,2.88c0.558,1.442,0.082,3.918,0.24,5.76
			c-0.171,0.89,0.409,1.03,0.239,1.92c0.553,0.568,0.086,2.154,0.24,3.12c0,0.56,0,1.12,0,1.68c0.24,0,0.48,0,0.72,0
			c5.681-0.56,12.4-0.08,18.48-0.24c1.12,0,2.24,0,3.36,0c7.201-0.718,16.077,0.238,23.279-0.479c0.08,0,0.16,0,0.24,0
			c4.282-0.538,11.569-0.096,17.04-0.24c0.146-0.573-0.301-1.741,0.24-1.92c0-0.08,0-0.16,0-0.24c0.399-2.961,0.399-7.599,0-10.56
			c0-0.16,0-0.32,0-0.48C487.173,753.383,487.173,751.942,487.173,750.503z"/>

				<image overflow="visible" width="87" height="427" id="lighting" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFwAAAGxCAYAAADvQtGwAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABhtJREFUeNrs3UFyGzcQBVCRNYfJ
IofAHCsn0LGsQ2ih2yh2KmU7KdkiOUAPuvH+zgsXqafWR4Ok7KcnEZFuuaz4Rf/1x59ffv7z89vr
HvXY26LY7azHv8IGPjLtlorR4f06+3fT/RLR5ZcFkD+DDkXfCuLei3z079QH/wC4ZXnuW/GKAJ51
nVtmLayEnWUPL4O94sUH+APrXurHnH3CW7XHvJru2Me+mu6PH3sU+tV0x37Dr6Z78S1lkuleA7za
rTLDhJfGngq8epVMBT5rlYwYglkmfNYqaeXAV6mSKcAzbCW9B+Ja7Ud29ud4rTI5KsXOPQ/4qtN9
CnjG63vPATljwjNWSUsJvnKVnDXhDbjpLjvhDXcQeIHp7vaZ8UsQdurpfn57vWSacFUSBe6gjJ/w
CtO9pwAvMt371/5+yTLh6ae7N/YwcN3962yme3xvDwWvcMkZUSUjK6XBDgJPPt3DsUdMeNrpjsDu
Cp59uqMe6NIRO+t071HT3XPCVUkUuCqJn3DTHQXuCh8/4akvOanAs0/3GXVydMJNdxS46Y6f8Lb6
pIaBTzTdj94Q91Tgk0z3P9iPfPPPrJNv2YZO97dXat77V8hPaOkO7u1O7Pu+wPenHy+PvT8O/NF0
Zj24t6FVcjmG/MmPfzv6DZwW/KFpuvxv0jsdhEcm++z+vgn8oSq5DAXJfOG6aUs5hv3e78e9wotl
W/et5H7s/ZbprvKP12zdpvtB7FtqpBP2PjX4zdN9+cU6eOeaN7q3ZzgwP5vw1hF7f/SLrvYmx9b1
i3zvB11lK7l1wtsE0N1fEpgS/K7pnh/6abbns3X8Ed5ng56+Uh7s7imnOkuHt4JTPdW7QkfeRE5R
IbM9x+uDdaKvO0x4gz1fpWTD3jODp5vsGZ/vdWbsg6+jTPmZlevkk9IqTfet4Ok+3ZT50My6kewp
wbOufzM/76sqOQ98d7kJBP8XeIc9NluFzq58tRfgwAU4cOACHLgABy7AgQMvlpk/xF91whtwAQ58
gR6vPOENuAAH/t8c+mzMjD0+NXiHTxE04CqldmarlRUmvAFXKWoFeNFaWaZSZpnylTq8AXdoqhXg
fX/XqAH/JL1/K+PsKV+xwxvwhbp81S2lAbcWlt1UTq2VFOCDfn+0AVcpAhy4g7MkeJWDU6UABy7A
8x6cJjz44ASuUmJ3ceDxuzjwygcn8OCDE7hKqX1wpgPPfnCqlOCDMyv4iFppwAvWikoBXntbSQs+
olYiDk6VEnxwZgdPVyupwTNuKyoluMeBB/c4cJVS++BMD55tH1cpwT0OXKUAX/LgHNXjJcAzfXZc
paiU2vt4GfAsL2SpFOC1a6UUeIZaUSnAa9dKOfCetTLitlm1UnpNeQOe/PB0aAKvfXiWBZ+1VlQK
cOB6HHjeHlcpwOeqld7X+/Lgs/1/bipFpQC3HgLPux6qFODABTjw0ptKz9vmMuAHN5UGXKUIcOAC
HPicqyHw+NUQuEopnF63TeDBt03gKgW4AAcuwIG73q8IfuR63+Pyo1KCLz/AVQpwAZ53U1kS/Mw3
IlQKcODS8fIDPPjyA1ylABfgwAU4cAEef/kBHnz5Aa5SgFfNDjwwZ70JoVKAA5eOuzjw4F0cuEoB
LsCBC3DgAhw4cAEOXIADF+CH88hr4sCPpQFXKQL8R3bggTnjsykqBThwAQ5cgAMX4MDT5d5XDIEf
TwOuUgQ4cOACHLgABy7AgQMX4MAFOHABDhy43JV73tcE3icNuEoR4MCBC3DgAhy4AAcOXIADF+DA
BThw4AJ85tz6RjLwfmnAVYoABw5cgAMX4MAFePz1Hnjw9R64SgEuwOMOOeDBhxxwlSLAgQtw4MAF
OHABDlyAAwcuwIELcOACHHilfPbGNPD+acBVCnABDlyAAxfgwIELcOACHLgABw5cgAMX4MDle373
zj3wMWnAVQpwAQ5cgB/KDjwwz2+vL9G7uEoJ3sWBqxTgAhy4AAcuwIEDF+DABTjwFfPRS7TAx6YB
VynABThwAQ5cgAMHLsCHxf82GJ8GXKUIcOACHLgAP2HXBx686wNXKcAFOHABDly+Zlv869+NgIgk
zt8CDABwi8FaQ9YWUwAAAABJRU5ErkJggg==" transform="matrix(0.24 0 0 0.24 472.4756 665.7822)">
				</image>
			</g>
			<g id="hair">
				<path fillRule="evenodd" clipRule="evenodd" fill="#39261D" d="M355.173,216.981c-0.52-0.44-0.506-1.415-0.48-2.4
			c-0.061-0.819-0.606-1.153-0.479-2.16c-0.38-0.501-0.498-1.262-0.48-2.16c-0.296-1.145-0.917-1.963-0.96-3.36
			c-0.423-0.697-0.547-1.693-0.72-2.64c-0.525-0.515-0.759-1.322-0.721-2.4c-0.379-0.66-0.414-1.665-0.72-2.4
			c-0.021-0.78-0.124-1.476-0.479-1.92c-0.38-0.501-0.498-1.262-0.48-2.16c-0.866-0.813-0.312-3.048-1.2-3.84
			c-0.54-0.58-0.507-1.732-0.72-2.64c-0.857-0.502-0.212-2.508-0.96-3.12c-0.54-0.82-0.769-1.952-0.96-3.12
			c-0.723-0.557-0.875-1.685-1.2-2.64c-0.355-0.204-0.516-0.604-0.479-1.2c-1.086-1.475-1.753-3.367-2.641-5.04
			c-0.322-0.158-0.446-0.513-0.479-0.96c-1.047-0.953-1.783-2.217-2.4-3.6c-1.074-1.006-1.654-2.506-2.64-3.6
			c-1.707-1.573-3.394-3.167-5.04-4.8c-0.547-0.333-0.93-0.83-1.44-1.2c-1.54-1.18-3.212-2.228-4.56-3.6
			c0.343,0.058,0.404,0.395,0.72,0.48c-1.963-1.959-4.382-2.694-7.2-3.84c-3.248-1.321-7.879-2.769-11.04-2.88
			c-0.884-0.031-1.787,0.382-2.64,0.24c-2.041-0.339-3.879-1.904-5.76-2.64c-10.985-4.295-26.479-2.307-37.92,0
			c-4.715,0.951-9.722,1.625-13.92,3.36c-2.388,0.987-4.831,2.946-7.44,4.32c-2.614,1.376-5.189,2.735-7.44,4.32
			c-10.033,7.062-14.186,15.77-19.2,28.08c-2.173,5.334-5.298,11.744-5.52,17.28c-0.119,2.964,0.673,6.243,0.96,9.36
			c0.875,9.519,2.327,18.41,5.52,25.68c-0.126-7.568,1.436-12.198,3.6-16.8c1.125-2.392,2.93-4.479,3.84-6.72
			c1.344-3.31,1.513-8.426,1.2-12.72c-0.252-3.455-2.136-6.917-0.72-9.84c2.788-1.248,3.397,1.49,4.32,2.88
			c10.242,3.341,25.36,5.522,36.72,3.12c6.248-1.321,11.74-4.422,17.52-6.96c8.475-3.722,17.432-7.254,25.44-11.28
			c2.809-1.412,5.461-3.458,8.16-4.32c1.472-0.47,4.439-1.024,4.8,0.96c0.273,1.504-1.479,1.638-1.681,3.36
			c-0.289,2.479,1.874,6.311,2.881,8.88c3.488,8.908,8.674,15.094,15.84,19.92c1.703,0.776,3.271,1.688,4.8,2.64
			c3.013,0.391,4.402,2.705,7.92,1.92c0.854,0.506,1.176,1.545,1.92,2.16c0.411,0.309,0.944,0.497,0.96,1.2
			c0.723,0.334,0.511,1.747,0,2.16c-0.217,0.423-0.537,0.743-0.96,0.96c-1.997,2.812,0.425,8.4,0.72,12c0,0.4,0,0.8,0,1.2
			c0.548,0.333,0.09,1.67,0.24,2.4c0.038,0.283-0.041,0.682,0.24,0.72c0.013-0.307,0.308-0.333,0.24-0.72
			c0.128-2.032,0.571-3.748,1.199-5.28c0.385-2.255,1.36-3.92,1.44-6.48c1.663-0.817,1.924-3.036,2.88-4.56
			C355.772,220.381,355.029,219.125,355.173,216.981z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#39261D" d="M354.933,283.702c-0.55-0.2-0.657-1.475-0.24-1.92
			c0-3.36,0-6.72,0-10.08c-0.371-0.349-0.211-1.229-0.24-1.92c-0.654-0.946-0.224-2.976-0.72-4.08
			c-0.445-5.234-1.728-9.632-2.64-14.4c-0.421-0.46-0.509-1.251-0.721-1.92c-0.436-1.307-2.173-4.556-0.239-5.28
			c-0.041-0.521,0.851-0.109,1.199-0.24c0-0.32,0-0.64,0-0.96c0.036-0.658,0.045-1.237-0.479-0.72c0.369,1.04-1.617,0.676-2.16,0.96
			c-1.952,1.021-1.76,3.681-2.4,6.24c-0.471,1.882-0.842,5.016-0.479,8.16c-0.158,1.922,0.317,4.478-0.24,6
			c-0.017,4.114-0.483,7.014-0.96,11.04c0.592,1.955-0.215,4.583-0.72,6.24c0.15-2.027,0.774-3.581,0.729-5.804
			c-0.137,0.984-0.357,1.959-0.729,2.923c-0.882,2.289-3.153,3.892-4.32,6c-4.838,8.741-5.062,18.637-7.68,30
			c1.884,0.276,2.114,2.205,1.92,4.56c-2.096,1.307-2.652,3.852-4.32,5.52c-2.25,2.25-5.399,2.663-8.64,4.08
			c-0.861,1.695-2.528,3.635-4.32,5.28c-1.336,1.227-3.837,4.546-5.76,2.16c4.597-1.803,7.413-5.387,10.08-9.12
			c-3.19,3.13-5.209,7.431-10.32,8.64c-0.781,1.015,0.985,1.266,0.48,1.92c-1.541,0.4-2.75,0.319-3.724-0.098
			c0.562,0.351,0.956,0.794,0.844,1.538c-0.438,2.883-6.921-0.765-6.605-3.785c-0.197,0.216-0.545,0.145-0.595-0.295
			c1.438-0.012-1.211-1.271-0.721-2.64c0-0.16,0-0.32,0-0.48c-0.315-1.765-0.82-3.339-1.439-4.8
			c-5.317,2.042-12.624,2.097-15.84,6.24c2.669,6.587-0.992,14.084-6.48,15.6c-1.021,0.74-0.987,2.533-2.88,2.4
			c-0.346-0.134-0.585-0.374-0.72-0.72c0.449-4.352,5.638-3.962,6.96-7.44c-1.592,2.408-4.797,3.203-6.72,5.28
			c-0.204-0.356-0.604-0.516-1.2-0.48c0.297-3.258-3.892-3.889-6-5.52c1.35,2.205,6.292,1.982,5.52,5.52
			c-2.708,0.377-5.889-1.673-8.88-3.36c-2.909-1.641-6.3-2.68-8.4-4.32c-3.236-2.527-4.064-7.611-6-11.52
			c-1.311-2.646-2.859-5.753-4.56-7.92c-2.941-3.745-9.7-6.422-11.04-10.8c-0.18-0.586,0.103-1.336,0-1.92
			c-0.235-1.336-1.046-2.99-1.44-4.56c-0.648-2.585-1.482-5.308-1.92-7.2c-1.198-5.181-0.37-11.283-2.64-14.64
			c-2.242-3.315-5.739-6.088-7.92-8.64c2.002,9.358,6.145,17.854,6.48,28.08c2.509,4.734,2.477,12.286,4.8,18
			c1.776,4.367,6.19,8.628,9.6,12.72c8.745,10.494,17.018,19.586,28.8,26.88c2.377,1.471,5.533,3.738,7.2,4.08
			c1.245,0.255,2.507-0.091,3.84,0c4.152,0.283,8.17,1.034,12.24,0.72c9.883-0.761,20.343-4.281,26.4-9.6
			c3.393-2.98,5.519-7.535,8.399-11.04c0.638-0.002,1.371,0.091,1.681-0.24c2.267-3.493,4.862-6.657,7.199-10.08
			c-0.88,1.066-0.976-1.422-0.479-1.68c0.143,0.497-0.297,1.577,0.24,1.68c0.523-0.756,0.652-1.907,1.439-2.4
			c0.184-0.696,0.38-1.381,0.96-1.68c0.258-1.183,1.438-1.442,2.16-2.16c0.054-0.186,0.007-0.473,0.24-0.48
			c0.146-0.973,0.828-1.412,0.72-2.64c0.341,0.021,0.68,0.04,0.72-0.24c0.08,0,0.16,0,0.24,0c0.527-0.913,0.899-1.98,1.681-2.64
			c0.274-0.845,0.527-1.712,1.199-2.16c0.724-1.836,1.617-3.504,2.641-5.04c0.422-1.338,1.114-2.406,1.68-3.6
			c0.51-0.37,0.102-1.658,0.72-1.92c0.247-1.674,0.555-3.286,1.2-4.56c1.741-2.659,0.29-8.51,3.601-9.6c0-0.4,0-0.8,0-1.2
			C353.55,288.142,355.229,286.054,354.933,283.702z"/>
			</g>
			<g id="gold">
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M265.412,454.342c7.997,2.544,12.72-8.69,4.8-11.279
			c-0.502,0.102-0.636-0.165-0.96-0.24C260.268,440.616,259.825,452.564,265.412,454.342z M267.813,445.222
			c-0.041-0.521,0.851-0.108,1.2-0.239c0.336-0.398,2.304-0.398,2.64,0c0.08,0,0.16,0,0.24,0c3.512,1.845-0.562,6.172-1.92,7.68
			C266.848,452.103,262.331,446.001,267.813,445.222z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M269.732,448.822c0.103-0.457,0.517-0.604,0.48-1.2
			c-0.427,0.133-0.997,0.123-1.2,0.479C269.566,448.028,269.279,448.795,269.732,448.822z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M268.292,507.622c0.489-0.337-0.236-0.827,0.24-1.44
			c0.575,0.146,1.095,0.346,1.92,0.24c-0.86-1.768,0.718-3.672,1.92-4.8c-0.341-3.661-1.02-6.468-3.12-7.92
			c-6.202-4.288-13.223,4.99-9.36,11.04C261.302,506.949,264.943,508.101,268.292,507.622z M260.852,500.422
			c1.527-1.243,2.243-3.87,4.32-3.84c2.777,0.041,4.136,5.394,4.08,8.4C266.294,506.161,259.828,505.529,260.852,500.422z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M265.892,501.862c-0.494-0.466-0.466-1.454-0.96-1.92
			c-0.183,0.696-1.137,0.622-0.96,1.68C264.641,501.532,265.433,502.536,265.892,501.862z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M261.572,630.742c-4.011,5.521,1.908,14.257,8.16,13.681
			c3.415-0.315,8.008-5.418,9.6-8.4c0-0.08,0-0.16,0-0.24c0-0.8,0-1.6,0-2.399c0-0.08,0-0.16,0-0.24
			C277.769,627.549,265.775,624.957,261.572,630.742z M270.692,641.542c-0.16,0-0.32,0-0.48,0c-1.125,0.399-3.915,0.399-5.04,0
			c-0.08,0-0.16,0-0.24,0c-0.537,0.029-2.048-0.934-1.92-1.68c0.187-1.089-0.046-2.664,0-2.88c0.441-2.074,3.454-5.886,5.04-6.24
			c2.549-0.569,4.17,1.257,5.52,3.12C275.926,637.111,277.93,641.516,270.692,641.542z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M268.772,634.343c-0.514,1.246-1.569,1.95-2.16,3.12
			c1.473-0.127,3.475,0.273,4.56-0.24C270.522,636.112,270.265,634.609,268.772,634.343z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M263.492,679.702c-2.308,1.781-4.874,6.524-4.32,11.04
			c0.262,2.14,1.684,5.242,4.08,5.761c4.049,0.875,5.485-2.055,9.6-1.681C281.902,689.447,272.418,672.816,263.492,679.702z
			 M272.852,691.702c-0.527,3.492-6.155,2.951-10.56,3.12c-0.418-0.542-1.284-0.637-1.44-1.439c0-0.08,0-0.16,0-0.24
			c-0.761-1.17,0.533-2.341,1.44-2.64c0.019-6.531,4.89-9.799,8.4-4.08C271.638,687.963,273.107,690.012,272.852,691.702z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M266.612,688.103c-0.145,0.896-0.903,1.178-0.96,2.16
			c0.926-0.034,2.107,0.188,2.4-0.48C267.355,689.439,267.511,688.244,266.612,688.103z"/>
			</g>
			<g id="skin">
				<path fillRule="evenodd" clipRule="evenodd" fill="#805745" d="M329.732,303.862c0.419-1.021,0.641-2.239,0.72-3.6
			c0.021-1.42,0.242-2.638,0.721-3.6c0.217-2.103,0.685-3.955,1.439-5.52c0.104-1.496,0.542-2.658,1.2-3.6
			c0.054-0.186,0.007-0.473,0.24-0.48c0.509-2.371,1.736-4.023,2.64-6c0.729-1.512,1.864-2.615,2.88-3.84
			c1.792-7.994,2.093-16.187,2.16-26.16c0.016-2.368-0.733-5.296,0.96-7.2c1.054-0.066,0.788-1.452,1.44-1.92
			c0.756-0.924,1.15-2.209,2.64-2.4c0.135-0.346,0.374-0.585,0.72-0.72c0.001-0.429-0.646-1.396,0-1.68
			c0.129-0.928-0.428-1.172-0.24-2.16c-0.53-2.989-1.309-5.731-1.439-9.12c-0.611-1.229-0.469-3.211-0.48-5.04
			c-0.279-0.041-0.26-0.379-0.239-0.72c-0.688-0.432-1.608-0.632-2.16-1.2c-0.83-0.21-1.663-0.417-2.16-0.96
			c-1.002-0.278-1.791-0.769-2.64-1.2c-1.145-0.456-2.322-0.878-3.12-1.68c-1.792-0.928-3.307-2.134-5.04-3.12
			c-0.241,0.001-0.286-0.194-0.48-0.24c-6.194-6.846-11.926-14.154-14.88-24.24c-0.207-1.798-0.573-2.255-1.2-3.84
			c-1.098-0.706-4.019,1.227-6,2.16c-5.564,2.621-12.296,5.367-17.52,7.68c-3.265,1.136-6.456,2.345-9.36,3.84
			c-3.253,1.468-7.158,3.562-10.32,4.32c-1.175,0.425-2.643,0.557-3.6,1.2c-8.356,2.937-23.92,2.441-31.68,0
			c-0.536-0.664-1.883-0.517-2.88-0.72c-1.966-0.193-3.172-1.148-5.04-1.44c0.131,5.125,0.319,13.383-2.16,18.24
			c-0.487,0.955-1.389,1.539-1.92,2.4c-3.066,4.967-4.585,9.925-5.04,16.56c-0.097,0.714,0.232,3.096-0.24,2.64
			c-0.53,0.03-0.102,1.018-0.24,1.44c0.532,5.708,0.118,13.642,0.24,18.96c0.225,1.935,0.384,3.936,0.72,5.76
			c0.243,0.397,1.02,0.261,1.44,0.48c3.023,5.137,7.948,8.372,10.56,13.92c0.335,0.865,0.568,1.833,0.96,2.64
			c0.873,2.008,0.726,5.035,0.72,7.92c0.351,0.668-0.341,1.427,0.48,1.92c0-0.08,0-0.16,0-0.24c0.705,3.295,1.727,7.552,2.16,10.32
			c-0.036,0.596,0.124,0.996,0.48,1.2c1.842,7.277,9.796,8.443,12.72,14.64c0.054,0.186,0.007,0.473,0.24,0.48
			c1.152,1.647,2.299,3.301,3.12,5.28c0.281,0.759,0.438,1.642,0.96,2.16c1.389,2.131,2.159,4.881,3.12,7.44
			c2.771,2.829,6.798,4.402,10.08,6.72c0.053,0.187,0.201,0.279,0.48,0.24c2.108,1.631,6.297,2.262,6,5.52
			c0.596-0.036,0.996,0.124,1.2,0.48c1.923-2.077,5.128-2.872,6.72-5.28c0.502-0.218,0.731-0.708,0.72-1.44
			c1.562-4.288-1.438-7.236-0.24-10.8c-2.135-0.308-6.734,1.028-6.48-1.92c0.262-3.043,7.5-0.883,9.84-0.96
			c1.043-0.035,2.851-0.538,3.84-0.96c3.429-1.463,7.853-4.453,11.28-5.28c0.08,0,0.16,0,0.24,0
			c-0.001-0.241,0.194-0.286,0.239-0.48c0.622-0.258,0.893-0.868,1.681-0.96c0.208-0.351,0.359-0.761,0.96-0.72
			c0.666-0.773,1.418-1.462,2.64-1.68c0.359,0.041,0.886-0.086,0.96,0.24c0.08,0,0.16,0,0.24,0c2.103,2.206-2.26,3.687-3.84,4.56
			c-0.413-0.013-0.615,0.185-0.721,0.48c-0.166,0.474-0.64,0.64-1.199,0.72c-0.16,0-0.32,0-0.48,0
			c-0.073,0.633,0.501,0.619,0.96,0.72c3.489,4.064,2.503,15.052,10.561,12.96c0.505-0.654-1.262-0.906-0.48-1.92
			c5.111-1.209,7.13-5.51,10.32-8.64c0.474-0.166,0.64-0.64,0.72-1.2c3.12-1.2,6.087-2.553,7.68-5.28
			c-0.499-0.541-1.307-0.773-1.68-1.44c0.052-1.708,0.11-3.41,0.48-4.8c0.353-0.451,0.414-1.915,0.239-2.16
			c0.441-2.04,0.532-4.428,1.2-6.24C329.769,305.018,329.764,304.453,329.732,303.862z M298.053,244.822
			c5.761-3.437,20.02-2.207,24.72,1.44c1.138,0.883,0.04,4.497-0.96,5.52c-1.261,1.289-3.479,1.01-4.32,3.12
			c-6.736,2.148-13.477-0.103-19.68-1.2C297.628,250.477,298.128,247.937,298.053,244.822z M296.133,279.142
			c-0.135-0.346-0.375-0.585-0.721-0.72c-1.052-0.628-2.002-1.358-2.88-2.16c-0.911-0.128-1.058-1.022-1.2-1.92
			c0.88-1.924,3.701-0.463,4.561,0.48c7.111,2.998,3.378,15.252-2.88,16.56c-0.627,0.093-1.317,0.123-1.681,0.48
			c-1.805,0.5-4.945,0.54-4.56-1.68c0.343-1.973,4.523-0.873,6.72-2.64c1.783-1.434,1.954-3.431,2.88-3.6
			C296.752,282.549,297.756,279.796,296.133,279.142z M298.772,263.062c-0.193-0.127-0.39-0.25-0.72-0.24
			c-0.447-0.033-0.803-0.158-0.96-0.48c-1.828-0.653-3.329-1.632-4.561-2.88c-1.259-0.581-2.217-1.463-3.12-2.4
			c-1.473-0.927-2.867-1.933-3.12-4.08c0-0.4,0-0.8,0-1.2c0.037-1.803-0.067-3.747,0.72-4.8c2.373-1.622,3.988,3.215,1.68,4.56
			c-0.054,3.538,2.965,4.051,6,6C297.269,259.196,303.211,262.079,298.772,263.062z M284.372,272.422
			c1.117,1.308,2.271,3.776,1.68,6c-3.02-0.26-2.414-4.146-4.08-5.76c-0.295-0.104-0.493-0.307-0.48-0.72
			c-0.356-0.204-0.516-0.604-0.48-1.2c0.214-0.106,0.429-0.211,0.48-0.48c1.397-0.197,1.874,0.526,2.4,1.2c0,0.08,0,0.16,0,0.24
			C284.188,271.806,284.385,272.009,284.372,272.422z M278.372,291.382c2.284-0.356,2.606-2.674,2.88-5.04
			c1.446-0.954,2.082-2.718,4.32-2.88c0.902-0.538,2.026-0.854,3.36-0.96c1.489-0.289,1.859,0.542,2.4,1.2
			c-0.228,0.892-0.557,1.683-1.68,1.68c-0.38,0.02-0.916-0.116-0.96,0.24c-1.352,0.726-3.149,0.682-4.08,1.92
			c-1.159,1.542-0.792,3.859-2.16,5.28c-1.736,1.805-3.521,1.408-6.48,2.64c-2.264-1.256-5.771-1.269-7.68-2.88
			c-1.321,2.517-5.681,5.302-8.88,3.6c-0.118-1.238,0.331-1.91,1.44-1.92c0.69,0.05,1.219-0.061,1.44-0.48
			c0.049-2.233-4.146-2.238-4.08-5.28C265.327,284.702,270.491,293.765,278.372,291.382z M240.212,268.342
			c-0.234,2.6-13.792-0.122-14.4-2.4c-0.47-4.286,1.598-7.96,3.6-10.56c-1.218,0.225-2.468,2.49-3.36,3.84
			c-0.341,0.459-0.741,0.859-1.2,1.2c0.08,0,0.16,0,0.24,0c-3.57,0.341-0.251-4.789,1.2-6.24c2.396-2.395,6.919-3.937,8.88-5.04
			c1.044-0.245,1.104,0.497,1.68,0.72c2.324-1.141,5.295-2.91,8.64-2.4c2.327,0.354,4.104,2.728,5.76,4.08
			c1.274,1.041,3.818,1.402,2.4,3.6c-0.08,0-0.16,0-0.24,0c-4.138,0.546-5.669-3.853-9.12-4.32c-3.153-0.427-7.674,2.352-9.84,4.08
			c-2.81,2.242-4.282,5.245-5.52,8.16c1.753,2.887,5.814,3.466,10.08,3.84C240.05,267.071,240.3,267.366,240.212,268.342z
			 M254.132,255.862c0.815,2.721-1.562,3.692-3.6,5.04c-3.113,2.058-7.382,5.367-10.32,5.76c-4.056,0.542-8.112-1.346-10.8-3.36
			c0.615-1.576,1.353-3.897,2.4-5.28c2.375-3.137,8.162-7.134,12.24-6.72c2.728,0.277,4.775,3.587,7.44,4.32
			C252.407,255.873,253.168,255.111,254.132,255.862z M250.292,289.222c-0.561-4.606,4.348-7.351,2.88-12
			c-0.547-0.333-0.089-1.67-0.24-2.4c0.054-2.426-0.168-5.128,0.24-7.2c-2.37,1.88-4.075,5.673-7.44,5.76
			c-0.758-1.329,0.973-2.001,0.96-3.36c3.889-2.431,5.6-7.04,7.44-11.52c0.301-1.059,0.983-1.737,0.96-3.12
			c0.315-1.125,0.327-2.554,1.2-3.12c-1.74-2.26-4.219-3.781-5.76-6.24c-0.074-0.874,0.038-1.562,0.24-2.16
			c0.989-0.557,2.13-0.557,3.12,0c1.662,2.258,4.485,3.355,5.52,6.24c0,0.16,0,0.32,0,0.48c1.822,0.178,2.651,1.349,3.36,2.64
			c0.702,1.377,2.065,2.095,1.92,4.32c-0.066,3.614-1.147,6.212-2.16,8.88c0.411,2.915,0.021,6.825-0.96,9.12
			c-0.052,0.766,0.166,4.031-0.24,2.88c-0.544,0.255-0.091,1.508-0.24,2.16c0.402,2.831-2.621,1.43-4.56,0.96
			c-0.431,2.926-3.229,4.573-2.88,7.2c0.374,2.811,4.247,2.345,4.8,5.52C255.917,296.695,250.653,292.187,250.292,289.222z
			 M323.492,260.422c-0.131,2.933-7.522,6-9.12,3.12c1.639-1.722,5.44-1.28,6.96-3.12c0.692-0.108,0.751-0.85,1.681-0.72
			c0.269,0.051,0.373,0.266,0.479,0.48C323.492,260.262,323.492,260.342,323.492,260.422z M327.093,250.342c-0.08,0-0.16,0-0.24,0
			c-0.27,0.051-0.374,0.266-0.48,0.48c-0.507,1.813-1.26,3.379-2.88,4.08c-0.099,0.302-0.31,0.491-0.72,0.48
			c-0.597-0.037-0.743,0.377-1.2,0.48c-1.01,1.276-3.993-0.118-2.88-1.68c2.663-1.696,5.677-3.357,4.8-7.92
			c-3.789-1.651-7.106-3.773-12.479-3.84c-1.12,0-2.24,0-3.36,0c-3.924,0.236-7.468,0.853-10.08,2.4
			c0.187,2.906-0.339,5.101-0.24,7.92c0.496,0.589,0.391,2.62-0.479,2.64c-4.561-0.239-1.512-8.088-2.4-12
			c0.465-0.896,1.443-1.277,2.4-1.68c0.186-0.054,0.474-0.007,0.479-0.24c1.659-0.981,3.811-1.469,6-1.92
			c0.531-0.242-0.591-0.826-0.239-1.68c5.531-3.183,16.839-2.159,21.84,0.72c0.798,0.269,2.794,1.261,1.439,2.16
			c0,0.08,0,0.16,0,0.24c-1.496,0.056-2.488-0.391-3.6-0.72c-3.858-0.792-7.658-2.06-12.24-1.44c1.157,0.526,5.108,0.33,6.48,1.44
			c0.221,0.419,0.75,0.53,1.439,0.48c1.25,0.19,2.269,0.611,3.12,1.2c0.204,0.356,0.604,0.516,1.2,0.48
			C326.338,243.049,330.316,246.505,327.093,250.342z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#805745" d="M217.172,280.102c-0.534-3.626-1.796-6.524-2.64-9.84
			c0.482-0.489-0.106-0.471-0.24-0.96c-3.015-4.346-5.932-8.788-10.8-11.28c-1.823,0.577-3.682,1.119-4.56,2.64
			c-0.234,2.794,0.596,4.523,0.96,6.72c3.107-3.633,7.376,1.734,8.88,4.8C212.771,273.623,214.398,277.436,217.172,280.102z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#805745" d="M221.972,304.342c-0.677-2.251,0.309-4.693-0.24-6.96
			c-0.264-0.697-0.217-1.704-0.48-2.4c-1.234-2.685-1.45-6.39-2.64-9.12c-0.812,0.831-0.093,1.7-0.24,3.12
			C214.35,294.043,218.686,301.43,221.972,304.342z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#805745" d="M201.572,273.142c0.387,2.415,1.838,5.04,1.68,7.2
			c0.188,0.807,0.1,1.57,0.72,2.4c0.373,0.907,0.611,1.949,0.72,3.12c0.524-1.316,0.294-3.386,0.96-4.56
			c-0.032-0.592-0.037-1.156,0.24-1.44C204.994,277.08,203.411,274.982,201.572,273.142z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#805745" d="M222.692,306.021c-0.08,0-0.16,0-0.24,0
			c-3.792,2.938-6.233-2.777-7.68-5.28c-0.395-1.605-1.003-2.997-1.44-4.56c-0.472-1.437-0.361-4.358,0.24-5.52
			c-1.575-2.265-3.223-4.457-4.56-6.96c-1.011,0.669-0.403,2.957-0.96,4.08c-0.53,0.03-0.102,1.018-0.24,1.44
			c-0.05,1.79-0.307,3.373-0.96,4.56c0.235,1.525,0.69,2.83,1.2,4.08c1.793,7.327,6.482,13.837,15.6,14.64
			c0.537-0.476-0.235-1.671-0.24-2.4C223.308,308.606,222.683,307.631,222.692,306.021z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#805745" d="M346.532,320.422c0.039,0.678-0.721,0.559-0.72,1.2
			c-1.165,2.957-2.909,4.753-3.12,8.16c-0.447,1.713-1.47,2.85-1.92,4.56c-0.188,0.053-0.279,0.201-0.24,0.48
			c-1.411,3.468-2.899,6.86-4.8,9.84c-0.966,1.194-1.754,2.566-2.4,4.08c-0.605,1.074-1.293,2.066-2.64,2.4
			c-0.601-0.041-0.751,0.369-0.96,0.72c0.741-0.059,1.356-0.243,1.92-0.48c0.987-0.613,2.341-0.86,3.6-1.2
			c0.283-0.038,0.682,0.042,0.721-0.24l4.319-1.68c0.69,0.05,1.22-0.061,1.44-0.48c0.053-0.187,0.201-0.279,0.48-0.24
			c0.588-0.452,1.174-0.906,2.159-0.96c0.502-0.458,1.146-0.774,1.92-0.96c0.54-0.66,1.698-0.702,2.4-1.2
			c0.048-5.207,0.065-11.266,0.48-15.36c-0.399-1.284-0.399-4.235,0-5.52c0-1.6,0-3.2,0-4.8
			C347.698,317.218,347.577,320.045,346.532,320.422z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#805745" d="M375.572,247.461c-0.649-0.471,0.146-2.386-0.479-2.88
			c-0.254-2.227-0.562-4.399-0.96-6.48c-0.514-1.086-0.534-2.666-0.96-3.84c-1.161-3.479-2.393-6.888-3.601-10.32
			c-0.547-0.813-1.186-1.534-1.439-2.64c-0.937-0.824-1.468-2.053-3.36-1.92c-0.08,0-0.16,0-0.24,0
			c-2.943,2.657-4.61,6.589-6.24,10.56c-0.49,0.47-0.716,1.205-0.72,2.16c-1.405,5.249-2.052,12.071-1.92,17.52
			c0.003,0.638-0.091,1.371,0.24,1.68c0.925,5.395,1.496,11.144,2.16,16.8c0.078,2.657,0.307,5.164,0.426,7.78
			c0-0.003,0.001-0.006,0.001-0.009c-0.168,1.509-0.196,3.069,0.053,4.708c0,0.8,0,1.6,0,2.4c0,0.08,0,0.16,0,0.24
			c0.033,1.504-0.967,3.767,0.96,3.84c4.072,0.152,5.951-1.889,7.92-3.84c0.228-1.053,0.898-1.662,1.44-2.4
			c0.404-0.796,0.388-2.012,0.96-2.64c0.037-0.282-0.041-0.681,0.24-0.72c-0.029-2.345,0.518-4.297,1.2-6.48
			c0.504-1.415,0.846-2.993,1.68-4.08c0.308-2.093,1.356-3.443,1.68-5.52c-0.069-1.27,0.621-1.779,0.48-3.12
			c-0.118-0.758,0.433-0.848,0.239-1.68c-0.628-0.8,0.342-2.05,0-3.36c-0.173-0.733,0.246-0.875,0.24-1.44
			c-0.398-0.492-0.398-2.628,0-3.12C375.572,248.262,375.572,247.862,375.572,247.461z M372.692,242.662
			c-1.968-0.032-2.018-1.983-3.12-2.88c1.585-3.504-1.011-6.337-3.6-7.2c-0.9,0.62-0.939,2.101-1.44,3.12
			c0.627,1.534,2.704,1.616,3.12,3.36c0.295,0.105,0.493,0.307,0.48,0.72c0.759,0.601,1.079,1.64,1.439,2.64
			c1.082,1.958,1.964,4.116,2.641,6.48c0.573,1.637,0.629,4.155,0,5.76c-1.302,2.299-1.1,6.101-4.32,6.48
			c-2.849-3.619,2.794-7.301,1.2-12c-4.076-0.084-4.591-3.729-7.2-5.28c-2.222,4.929,0.392,7.694,2.16,12
			c1.004,2.445,1.771,5.285,1.92,7.92c0.193,3.431-0.648,9.529-3.36,9.36c-0.307-0.013-0.332-0.308-0.72-0.24c-0.08,0-0.16,0-0.24,0
			c-0.533-0.653-0.149-1.926,0.24-2.4c0.08,0,0.16,0,0.24,0c1.456-4.324,0.498-10.146-0.96-13.68c-0.484-0.637-0.895-1.346-1.2-2.16
			c-4.767-4.454-1.161-12.995,0.72-18.24c0.077-1.203,0.391-2.169,0.96-2.88c0.307-1.613,0.884-2.956,1.68-4.08
			c-0.01-0.41,0.179-0.622,0.48-0.72c0.042-1.478,2.875-0.165,2.4-2.16C368.913,231.101,373.686,236.041,372.692,242.662z"/>
			</g>
			<g id="black">
				<path fillRule="evenodd" clipRule="evenodd" d="M253.412,255.142c0.08,0,0.16,0,0.24,0c1.418-2.198-1.126-2.56-2.4-3.6
			c-0.306-0.25-0.619-0.54-0.936-0.84c0.172,0.26,0.326,0.539,0.456,0.84c-0.13-0.301-0.283-0.581-0.456-0.84h0l0,0
			c-0.017-0.025-0.036-0.045-0.053-0.07c-0.136-0.196-0.285-0.378-0.443-0.55c-0.078-0.085-0.158-0.166-0.241-0.246
			c-0.091-0.086-0.186-0.167-0.282-0.247c-0.253-0.212-0.523-0.404-0.812-0.573c-0.002-0.001-0.003-0.002-0.005-0.003l0,0
			c0,0-0.001-0.001-0.001-0.001c0,0,0,0,0.001,0c-0.903-0.754-1.882-1.383-2.988-1.552c-3.345-0.509-6.316,1.26-8.64,2.4
			c-0.576-0.224-0.635-0.965-1.68-0.72c-1.961,1.104-6.485,2.645-8.88,5.04c-1.451,1.451-4.77,6.581-1.2,6.24c-0.08,0-0.16,0-0.24,0
			c0.459-0.341,0.859-0.741,1.2-1.2c0.892-1.35,2.142-3.615,3.36-3.84c-2.002,2.599-4.07,6.273-3.6,10.56
			c0.608,2.279,14.167,5,14.4,2.4c0.087-0.976-0.162-1.271-1.2-1.44c-4.266-0.375-8.327-0.953-10.08-3.84
			c1.238-2.916,2.71-5.918,5.52-8.16c2.166-1.728,6.687-4.506,9.84-4.08C247.743,251.289,249.274,255.688,253.412,255.142z
			 M247.856,248.694c-0.017-0.007-0.034-0.015-0.051-0.022C247.822,248.68,247.839,248.687,247.856,248.694z M244.939,247.985
			c-0.037-0.003-0.074-0.006-0.111-0.009C244.865,247.979,244.902,247.982,244.939,247.985z M244.106,247.945
			c-0.034,0-0.067,0-0.102,0C244.039,247.946,244.073,247.945,244.106,247.945z M237.335,249.652
			c-0.251,0.144-0.495,0.292-0.723,0.449C236.84,249.944,237.084,249.796,237.335,249.652z M241.89,248.112
			c-0.125,0.02-0.251,0.039-0.375,0.062C241.64,248.151,241.765,248.131,241.89,248.112z M241.012,248.274
			c-0.16,0.035-0.317,0.074-0.475,0.115C240.695,248.349,240.853,248.31,241.012,248.274z M240.122,248.497
			c-0.284,0.082-0.564,0.17-0.837,0.268C239.558,248.667,239.837,248.579,240.122,248.497z M239.038,248.86
			c-0.21,0.08-0.418,0.162-0.62,0.251C238.62,249.022,238.827,248.939,239.038,248.86z M238.132,249.243
			c-0.19,0.089-0.376,0.183-0.557,0.28C237.756,249.426,237.941,249.332,238.132,249.243z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M299.537,283.499c0.042-0.151,0.08-0.303,0.114-0.455
			c0.025-0.117,0.054-0.233,0.075-0.351c0.044-0.243,0.076-0.486,0.1-0.732c0.009-0.096,0.013-0.193,0.019-0.29
			c0.013-0.218,0.016-0.437,0.01-0.656c-0.002-0.064,0-0.127-0.003-0.191c-0.015-0.277-0.049-0.556-0.096-0.834
			c-0.013-0.071-0.026-0.143-0.041-0.214c-0.058-0.291-0.127-0.583-0.223-0.874c-0.061-0.185-0.138-0.367-0.224-0.547
			c-0.566-1.53-1.649-2.806-3.376-3.533c-0.859-0.943-3.681-2.404-4.561-0.48c0.143,0.897,0.289,1.792,1.2,1.92
			c0.878,0.802,1.828,1.532,2.88,2.16c0.346,0.134,0.586,0.374,0.721,0.72c1.623,0.655,0.619,3.407,0.239,4.8
			c-0.926,0.168-1.097,2.166-2.88,3.6c-2.196,1.767-6.377,0.667-6.72,2.64c-0.386,2.22,2.755,2.181,4.56,1.68
			c0.363-0.357,1.054-0.387,1.681-0.48c3.092-0.646,5.565-3.966,6.421-7.52C299.472,283.741,299.503,283.62,299.537,283.499z
			 M298.067,276.68c-0.007-0.007-0.013-0.014-0.02-0.021C298.055,276.666,298.061,276.673,298.067,276.68z M297.266,275.958
			c-0.024-0.019-0.048-0.039-0.072-0.058C297.218,275.919,297.241,275.939,297.266,275.958z M296.342,275.307
			c-0.037-0.023-0.073-0.046-0.11-0.068C296.269,275.261,296.305,275.284,296.342,275.307z M295.327,274.75
			c-0.043-0.021-0.087-0.041-0.131-0.061C295.24,274.71,295.283,274.729,295.327,274.75z M294.252,274.311
			c-0.041-0.014-0.083-0.026-0.124-0.04C294.169,274.284,294.21,274.296,294.252,274.311z M293.149,274.007
			c-0.028-0.005-0.057-0.009-0.086-0.015C293.093,273.998,293.121,274.001,293.149,274.007z M296.809,288.312
			c-0.054,0.062-0.107,0.123-0.163,0.183C296.701,288.435,296.755,288.373,296.809,288.312z M295.816,289.344
			c0.013-0.012,0.024-0.026,0.037-0.038C295.841,289.318,295.829,289.332,295.816,289.344z M293.845,290.891
			c0.079-0.05,0.157-0.105,0.236-0.158C294.002,290.786,293.924,290.84,293.845,290.891z M294.748,290.249
			c0.068-0.052,0.136-0.103,0.204-0.157C294.884,290.146,294.816,290.196,294.748,290.249z M298.896,285.193
			c0.029-0.06,0.057-0.12,0.084-0.18C298.953,285.074,298.926,285.133,298.896,285.193z M297.513,287.454
			c0.023-0.03,0.048-0.06,0.07-0.09C297.561,287.395,297.536,287.424,297.513,287.454z M298.309,286.291
			c0.008-0.014,0.016-0.029,0.024-0.043C298.324,286.262,298.316,286.277,298.309,286.291z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M268.052,438.982c-0.729,0.15-2.067-0.308-2.4,0.239
			c-4.609,0.768-7.961,4.018-6.96,9.601c-0.781,0.625-0.748,1.518-0.72,2.399c0.218,6.952,10.981,12.166,16.8,7.2
			c0.112-1.117,0.324-1.602,0-2.64C282.127,450.125,276.916,438.853,268.052,438.982z M265.412,454.342
			c-4.979-1.584-5.163-11.236,1.213-11.76c0.387,0,0.774,0,1.161,0c0.462,0.038,0.949,0.113,1.467,0.24
			c0.324,0.075,0.458,0.342,0.96,0.24c0.119-0.011,0.214,0.002,0.301,0.023c-0.087-0.021-0.182-0.034-0.301-0.023
			c0.367,0.12,0.698,0.264,1.011,0.419c-0.001-0.001-0.002-0.002-0.004-0.002c0.004,0.002,0.008,0.004,0.013,0.006
			C277.674,446.678,273.036,456.767,265.412,454.342z M270.578,443.104c0.075,0.025,0.143,0.056,0.205,0.094
			C270.721,443.159,270.652,443.129,270.578,443.104z M270.797,443.207c0.073,0.047,0.141,0.099,0.211,0.147
			C270.938,443.306,270.87,443.254,270.797,443.207z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M258.452,642.982c2.698,5.69,10.76,8.535,17.52,6
			c1.342-0.503,1.464-2.021,2.16-2.88c0.912-1.126,2.41-1.745,2.88-3.36c-0.417-2.416,1.044-2.956,1.44-4.56c0-0.08,0-0.16,0-0.24
			c0.525-0.515,0.758-1.321,0.72-2.4c0-0.72,0-1.439,0-2.159c-0.111-0.924-0.364-1.787-0.737-2.585
			c-2.723-6.525-11.685-8.41-18.325-6.061c0.002-0.001,0.003-0.001,0.005-0.001c-0.158,0.051-0.311,0.107-0.466,0.163h0h0
			c-0.873,0.317-1.697,0.717-2.455,1.202c-0.005,0.004-0.01,0.007-0.015,0.011c-0.754,0.484-1.441,1.054-2.039,1.712
			c-0.001,0.001-0.002,0.002-0.003,0.003c0.001-0.001,0.002-0.002,0.002-0.003c-0.144,0.159-0.288,0.319-0.422,0.488
			c0.003-0.004,0.007-0.008,0.01-0.012c-0.011,0.015-0.025,0.027-0.036,0.042c-0.001,0.002-0.003,0.004-0.004,0.006
			c0-0.001,0.001-0.001,0.001-0.002c-0.038,0.049-0.069,0.099-0.105,0.147c0.036-0.048,0.066-0.098,0.104-0.146
			c-1.239,1.489-2.039,3.325-2.156,5.514c-0.188,3.505,0.326,6.864,1.92,8.88C258.452,642.822,258.452,642.902,258.452,642.982z
			 M279.332,633.143c0,0.08,0,0.16,0,0.24c0,0.8,0,1.6,0,2.399c0,0.08,0,0.16,0,0.24c-1.591,2.982-6.185,8.085-9.6,8.4
			c-4.254,0.392-8.351-3.527-9.273-7.813c-0.037-0.272-0.07-0.548-0.087-0.828c0-0.729,0-1.458,0-2.188
			c0.172-0.999,0.557-1.967,1.2-2.853C265.775,624.957,277.769,627.549,279.332,633.143z M269.027,644.726
			c-0.104,0.001-0.205-0.009-0.308-0.012C268.822,644.717,268.923,644.727,269.027,644.726z M268.114,644.688
			c-0.125-0.013-0.245-0.036-0.367-0.054C267.87,644.651,267.99,644.675,268.114,644.688z M267.237,644.553
			c-0.132-0.027-0.259-0.066-0.389-0.1C266.978,644.486,267.105,644.525,267.237,644.553z M266.398,644.327
			c-0.135-0.044-0.265-0.098-0.396-0.148C266.134,644.229,266.264,644.283,266.398,644.327z M265.602,644.016
			c-0.135-0.061-0.265-0.13-0.396-0.198C265.337,643.886,265.467,643.955,265.602,644.016z M264.851,643.625
			c-0.134-0.079-0.263-0.164-0.392-0.251C264.588,643.461,264.717,643.546,264.851,643.625z M264.149,643.159
			c-0.132-0.097-0.259-0.2-0.385-0.305C263.89,642.959,264.018,643.063,264.149,643.159z M263.498,642.625
			c-0.128-0.116-0.251-0.236-0.373-0.36C263.247,642.389,263.371,642.509,263.498,642.625z M262.902,642.028
			c-0.123-0.137-0.242-0.276-0.357-0.42C262.66,641.752,262.779,641.893,262.902,642.028z M262.364,641.373
			c-0.117-0.156-0.229-0.315-0.337-0.479C262.134,641.058,262.247,641.217,262.364,641.373z M261.884,640.665
			c-0.108-0.177-0.213-0.355-0.311-0.541C261.671,640.31,261.775,640.488,261.884,640.665z M261.466,639.911
			c-0.099-0.197-0.193-0.397-0.279-0.604C261.273,639.514,261.368,639.714,261.466,639.911z M261.112,639.115
			c-0.086-0.22-0.168-0.441-0.241-0.669C260.943,638.674,261.025,638.896,261.112,639.115z M260.825,638.288
			c-0.073-0.243-0.141-0.489-0.198-0.742C260.684,637.798,260.752,638.045,260.825,638.288z M260.606,637.439
			c-0.058-0.271-0.109-0.547-0.147-0.827C260.498,636.893,260.549,637.168,260.606,637.439z M278.742,626.669
			c0.025,0.017,0.052,0.032,0.077,0.05C278.793,626.701,278.767,626.686,278.742,626.669z M277.6,625.982
			c0.049,0.026,0.096,0.054,0.144,0.081C277.696,626.036,277.648,626.009,277.6,625.982z M275.061,624.896
			c0.06,0.021,0.12,0.04,0.18,0.061C275.18,624.937,275.121,624.917,275.061,624.896z M280.804,628.412
			c-0.036-0.038-0.073-0.075-0.109-0.113C280.731,628.337,280.768,628.374,280.804,628.412z M282.262,630.47
			c-0.026-0.052-0.053-0.103-0.081-0.153C282.209,630.367,282.235,630.418,282.262,630.47z M279.818,627.474
			c0.019,0.016,0.037,0.033,0.056,0.049C279.855,627.507,279.837,627.489,279.818,627.474z M269.615,623.967
			c-0.01,0-0.02,0.001-0.03,0C269.595,623.968,269.604,623.967,269.615,623.967z M272.489,624.249
			c-0.051-0.009-0.102-0.016-0.152-0.024C272.387,624.233,272.438,624.24,272.489,624.249z M271.099,624.059
			c-0.037-0.004-0.073-0.008-0.11-0.012C271.025,624.051,271.062,624.055,271.099,624.059z M265.505,624.362
			c-0.054,0.012-0.107,0.024-0.162,0.037C265.397,624.387,265.451,624.374,265.505,624.362z M268.286,623.991
			c-0.055,0.003-0.109,0.005-0.164,0.008C268.176,623.996,268.231,623.994,268.286,623.991z M258.291,628.907
			c-0.019,0.029-0.034,0.06-0.052,0.089C258.257,628.967,258.272,628.937,258.291,628.907z M256.997,638.557
			c0.007,0.35,0.013,0.704,0.015,1.065C257.01,639.261,257.004,638.906,256.997,638.557z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M125.252,666.982c5.881,1.81,14.445,1.971,20.88,0.72c0.08,0,0.16,0,0.24,0
			c3.051-0.229,5.961-0.599,8.4-1.439c0.08,0,0.16,0,0.24,0c2.629-1.094,10.196-0.861,11.04-3.84c0.407-1.437-0.351-4.251-2.64-3.36
			c-0.08,0-0.16,0-0.24,0c-0.433-0.313,0.301-1.297-0.24-1.92c-3.177-1.13-7.507-0.777-11.52-0.96
			c-5.507-0.251-11.121-1.152-16.32-1.2c-0.08,0-0.16,0-0.24,0c-1.523,0.157-3.676-0.316-4.8,0.24c-0.24,0-0.48,0-0.72,0
			c-1.453-0.013-2.949-0.068-3.84,0.479c-0.325,0.076-0.458,0.342-0.96,0.24c-1.259,0.261-1.779,1.261-2.64,1.92
			c0.692,0.268,1.488,0.432,2.4,0.48c0.08,0,0.16,0,0.24,0c4.641,0.479,10.431-0.423,15.36,0.239c0.16,0,0.32,0,0.48,0
			c6.308,0.815,13.919,1.597,20.4,1.44c0.08,0,0.16,0,0.24,0c1.115,0.08,0.966-0.698,1.2,0.24c0.341,1.369-3.456,1.679-4.56,1.92
			c-6.606,1.441-14.791,2.577-20.88,2.88c-0.08,0-0.16,0-0.24,0c-1.405-0.274-3.089-0.271-4.8-0.24c-0.08,0-0.16,0-0.24,0
			c-1.649-0.431-3.553-0.607-5.52-0.72c-0.08,0-0.16,0-0.24,0c-3.582-1.062-7.409-1.816-11.28-2.88
			c-3.747-1.029-7.53-3.107-10.8-3.36c-0.815-0.063-2.92-0.492-3.12,0.96c0.369,0.912,1.286,1.275,2.4,1.44
			c0.861-0.142,1.161,0.278,1.68,0.479c6.069,2.812,13.329,4.432,20.4,6.24C125.092,666.982,125.172,666.982,125.252,666.982z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M279.813,683.782c-0.443-1.477-0.968-2.872-1.92-3.84c0-0.08,0-0.16,0-0.24
			c-3.965-8.159-17.972-6.255-20.16,1.44c0,0.08,0,0.16,0,0.24c-2.56,3.099-3.247,10.538-0.72,14.16
			c-3.384,3.753,3.186,6.517,6.96,6.96c1.703,0.183,2.742-0.299,4.32-0.24c2.732-0.228,4.96-0.96,6.48-2.4
			c0.588-3.255,3.606-4.079,4.745-6.782c0.065-0.132,0.123-0.269,0.17-0.41c0.003-0.009,0.007-0.018,0.01-0.026
			c0.048-0.148,0.088-0.301,0.114-0.461c-0.018-0.418,0.053-0.747,0.24-0.96c0.86-1.669,0.683-5.786-0.24-7.2
			C279.813,683.942,279.813,683.862,279.813,683.782z M259.172,690.742c-0.554-4.516,2.012-9.259,4.32-11.04
			c8.926-6.886,18.41,9.745,9.36,15.12c-4.114-0.374-5.55,2.556-9.6,1.681C260.856,695.984,259.435,692.882,259.172,690.742z
			 M276.032,696.527c-0.173,0.162-0.34,0.329-0.496,0.502C275.692,696.856,275.859,696.689,276.032,696.527z M276.67,695.967
			c-0.174,0.145-0.345,0.293-0.511,0.443C276.325,696.26,276.496,696.111,276.67,695.967z M277.033,695.668
			c-0.011,0.009-0.022,0.019-0.033,0.027C277.011,695.687,277.022,695.677,277.033,695.668z M275.46,697.118
			c-0.163,0.188-0.316,0.38-0.448,0.584C275.145,697.498,275.298,697.306,275.46,697.118z M278.587,694.324
			c0.008-0.008,0.015-0.016,0.023-0.023C278.602,694.309,278.595,694.316,278.587,694.324z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M361.173,256.822c1.458,3.534,2.416,9.355,0.96,13.68c-0.08,0-0.16,0-0.24,0
			c-0.39,0.474-0.773,1.747-0.24,2.4c0.08,0,0.16,0,0.24,0c0.388-0.068,0.413,0.227,0.72,0.24c2.712,0.169,3.554-5.929,3.36-9.36
			c-0.148-2.635-0.916-5.475-1.92-7.92c-1.769-4.306-4.382-7.071-2.16-12c2.609,1.55,3.124,5.195,7.2,5.28
			c1.594,4.699-4.049,8.381-1.2,12c2.413-0.284,2.905-2.49,3.547-4.545c0.058-0.166,0.111-0.333,0.163-0.503
			c0.172-0.508,0.364-0.997,0.61-1.431c0.629-1.605,0.573-4.124,0-5.76c-0.677-2.364-1.559-4.522-2.641-6.48
			c-0.36-1-0.681-2.039-1.439-2.64c0.013-0.413-0.186-0.615-0.48-0.72c-0.416-1.744-2.493-1.826-3.12-3.36
			c0.501-1.019,0.54-2.5,1.44-3.12c2.589,0.863,5.185,3.696,3.6,7.2c1.103,0.897,1.152,2.848,3.12,2.88
			c0.993-6.621-3.779-11.561-6.479-16.08c0.475,1.996-2.358,0.683-2.4,2.16c-0.302,0.098-0.49,0.31-0.48,0.72
			c-0.796,1.124-1.373,2.467-1.68,4.08c-0.569,0.711-0.883,1.677-0.96,2.88c-1.881,5.246-5.486,13.787-0.72,18.24
			C360.278,255.476,360.688,256.185,361.173,256.822z M369.066,260.246c-0.042,0.028-0.086,0.051-0.129,0.076
			C368.98,260.296,369.024,260.273,369.066,260.246z M368.565,260.5c-0.049,0.019-0.097,0.039-0.146,0.055
			C368.469,260.539,368.517,260.519,368.565,260.5z M370.381,258.846c0.018-0.027,0.035-0.054,0.053-0.082
			C370.416,258.793,370.398,258.819,370.381,258.846z M369.96,259.426c0.025-0.031,0.051-0.065,0.076-0.097
			C370.011,259.361,369.985,259.395,369.96,259.426z M371.967,254.68c0.003-0.014,0.006-0.027,0.009-0.042
			C371.973,254.653,371.97,254.667,371.967,254.68z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M306.668,336.549c-3.492-2.251-3.544-9.415-6.216-12.527
			c-0.459-0.101-1.033-0.087-0.96-0.72c0.16,0,0.32,0,0.48,0c0.56-0.08,1.033-0.246,1.199-0.72c0.105-0.295,0.308-0.493,0.721-0.48
			c1.397-0.772,4.963-2.021,4.314-3.826c0.001-0.005,0.004-0.009,0.005-0.014c-0.021-0.08-0.059-0.143-0.104-0.199
			c-0.001,0-0.001,0-0.001-0.001c0,0,0,0,0,0.001c-0.046-0.057-0.099-0.107-0.151-0.157c0.053,0.05,0.105,0.1,0.151,0.156
			c-0.091-0.169-0.205-0.341-0.375-0.52c-0.08,0-0.16,0-0.24,0c-0.074-0.326-0.601-0.199-0.96-0.24
			c-1.222,0.218-1.974,0.907-2.64,1.68c-0.601-0.041-0.752,0.369-0.96,0.72c-0.788,0.092-1.059,0.702-1.681,0.96
			c-0.045,0.194-0.24,0.239-0.239,0.48c-0.08,0-0.16,0-0.24,0c-3.428,0.827-7.852,3.817-11.28,5.28
			c-0.989,0.422-2.797,0.925-3.84,0.96c-2.34,0.077-9.578-2.083-9.84,0.96c-0.254,2.948,4.345,1.612,6.48,1.92
			c-1.199,3.564,1.801,6.513,0.24,10.8c0.012,0.731-0.218,1.222-0.72,1.44c-1.323,3.478-6.512,3.088-6.96,7.44
			c0.135,0.346,0.375,0.585,0.72,0.72c1.893,0.133,1.859-1.66,2.88-2.4c5.488-1.516,9.149-9.013,6.48-15.6
			c3.217-4.143,10.523-4.197,15.84-6.24c0.619,1.461,1.124,3.035,1.439,4.8c0,0.16,0,0.32,0,0.48
			c-0.49,1.369,2.159,2.628,0.721,2.64c0.05,0.439,0.397,0.511,0.595,0.295c-0.315,3.021,6.167,6.668,6.605,3.785
			C308.284,337.423,307.521,336.966,306.668,336.549z M305.851,317.789c-0.013-0.016-0.025-0.033-0.037-0.05
			C305.825,317.756,305.838,317.772,305.851,317.789z M306.173,336.309c-0.116-0.057-0.23-0.116-0.343-0.177
			C305.942,336.193,306.057,336.252,306.173,336.309z M305.323,335.814c-0.113-0.084-0.22-0.173-0.311-0.272
			C305.104,335.641,305.21,335.729,305.323,335.814z M305.738,336.082c-0.123-0.069-0.241-0.143-0.352-0.221
			C305.497,335.939,305.615,336.013,305.738,336.082z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M323.013,259.702c-0.93-0.129-0.988,0.612-1.681,0.72
			c-1.52,1.84-5.321,1.398-6.96,3.12c1.598,2.88,8.989-0.187,9.12-3.12c0-0.08,0-0.16,0-0.24
			C323.386,259.968,323.281,259.752,323.013,259.702z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M322.772,242.421c-0.596,0.036-0.996-0.124-1.2-0.48
			c-0.852-0.588-1.87-1.01-3.12-1.2c-0.689,0.05-1.219-0.061-1.439-0.48c-1.372-1.11-5.323-0.914-6.48-1.44
			c4.582-0.62,8.382,0.647,12.24,1.44c1.111,0.329,2.104,0.776,3.6,0.72c0-0.08,0-0.16,0-0.24c1.354-0.899-0.642-1.891-1.439-2.16
			c-5.001-2.878-16.309-3.902-21.84-0.72c-0.352,0.854,0.771,1.438,0.239,1.68c-2.189,0.451-4.341,0.938-6,1.92
			c-0.006,0.233-0.294,0.187-0.479,0.24c-0.957,0.403-1.936,0.784-2.4,1.68c0.889,3.912-2.16,11.761,2.4,12
			c0.87-0.021,0.976-2.051,0.479-2.64c-0.099-2.819,0.427-5.014,0.24-7.92c2.612-1.547,6.156-2.164,10.08-2.4c1.12,0,2.24,0,3.36,0
			c5.373,0.067,8.69,2.189,12.479,3.84c0.877,4.563-2.137,6.224-4.8,7.92c-1.113,1.563,1.87,2.956,2.88,1.68
			c0.457-0.103,0.604-0.517,1.2-0.48c0.41,0.01,0.621-0.179,0.72-0.48c1.62-0.7,2.373-2.267,2.88-4.08
			c0.106-0.214,0.211-0.429,0.48-0.48c0.08,0,0.16,0,0.24,0C330.316,246.505,326.338,243.049,322.772,242.421z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M258.212,288.501c-0.065,3.042,4.129,3.047,4.08,5.28
			c-0.221,0.419-0.75,0.53-1.44,0.48c-1.109,0.01-1.558,0.682-1.44,1.92c3.186,1.695,7.521-1.06,8.861-3.568
			c-0.156-0.089-0.311-0.178-0.461-0.272c0.15,0.094,0.305,0.183,0.461,0.272l0,0c0.001,0,0.002,0.001,0.004,0.002
			c0.108,0.062,0.219,0.121,0.329,0.18c1.958,1.386,5.215,1.473,7.366,2.667c2.959-1.232,4.744-0.835,6.48-2.64
			c0.563-0.585,0.828-1.323,1.031-2.095c0.406-0.765,0.69-1.653,0.821-2.674c0,0,0-0.001,0-0.002v0
			c0.031-0.243,0.054-0.492,0.067-0.75c-0.013,0.257-0.037,0.507-0.067,0.749c0.091-0.176,0.187-0.349,0.308-0.509
			c0.931-1.238,2.728-1.194,4.08-1.92c0.044-0.355,0.58-0.22,0.96-0.24c1.123,0.003,1.452-0.788,1.68-1.68
			c-0.541-0.658-0.911-1.489-2.4-1.2c-1.334,0.106-2.458,0.422-3.36,0.96c-2.238,0.162-2.874,1.926-4.32,2.88
			c-0.274,2.366-0.596,4.684-2.88,5.04C270.491,293.765,265.327,284.702,258.212,288.501z M270.567,293.663
			c0.002,0,0.004,0.001,0.006,0.002C270.571,293.665,270.569,293.664,270.567,293.663z M273.73,294.451
			c0.015,0.002,0.031,0.004,0.046,0.006C273.761,294.455,273.746,294.453,273.73,294.451z M275.852,294.599c0.031,0,0.062,0,0.092,0
			C275.914,294.599,275.883,294.599,275.852,294.599z M282.266,292.343c0.025-0.024,0.051-0.047,0.076-0.072
			C282.317,292.295,282.291,292.318,282.266,292.343z M276.95,294.549c0.008,0,0.017-0.001,0.025-0.002
			C276.967,294.547,276.958,294.549,276.95,294.549z M277.935,294.417c0.024-0.004,0.048-0.007,0.072-0.012
			C277.983,294.41,277.959,294.413,277.935,294.417z M278.921,294.194c0.039-0.011,0.076-0.022,0.114-0.034
			C278.998,294.171,278.96,294.183,278.921,294.194z M280.72,293.472c0.039-0.021,0.079-0.042,0.118-0.065
			C280.798,293.43,280.759,293.451,280.72,293.472z M281.578,292.93c0.016-0.012,0.031-0.025,0.047-0.037
			C281.609,292.905,281.594,292.918,281.578,292.93z M282.917,291.608c0.026-0.036,0.052-0.072,0.078-0.108
			C282.968,291.536,282.943,291.573,282.917,291.608z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M258.452,294.262c-0.553-3.175-4.426-2.709-4.8-5.52
			c-0.35-2.627,2.449-4.274,2.88-7.2c1.939,0.47,4.962,1.871,4.56-0.96c0.148-0.651-0.305-1.905,0.24-2.16
			c0.406,1.151,0.188-2.115,0.24-2.88c0.919-2.152,1.311-5.715,1.018-8.556c-0.042,0.439-0.066,0.888-0.058,1.356
			c-0.008-0.468,0.017-0.917,0.058-1.356l0,0c0.037-0.393,0.097-0.772,0.165-1.147l0,0c0.331-1.847,0.956-3.525,1.352-5.354
			c-0.393,1.831-1.021,3.507-1.352,5.354c0.943-2.469,1.876-4.946,1.938-8.296c0.145-2.225-1.218-2.942-1.92-4.32
			c-0.709-1.292-1.538-2.462-3.36-2.64c0-0.16,0-0.32,0-0.48c-1.035-2.885-3.858-3.982-5.52-6.24c-0.989-0.557-2.13-0.557-3.12,0
			c-0.202,0.598-0.314,1.286-0.24,2.16c1.541,2.459,4.02,3.98,5.76,6.24c-0.873,0.566-0.885,1.995-1.2,3.12
			c0.023,1.383-0.659,2.061-0.96,3.12c-1.84,4.48-3.551,9.089-7.44,11.52c0.013,1.359-1.718,2.031-0.96,3.36
			c3.365-0.087,5.07-3.88,7.44-5.76c-0.408,2.072-0.187,4.774-0.24,7.2c0.151,0.729-0.307,2.067,0.24,2.4
			c0.146,0.462,0.214,0.9,0.246,1.329c-0.015,0.368-0.025,0.73-0.012,1.07c-0.392,3.324-3.571,5.844-3.114,9.601
			C250.653,292.187,255.917,296.695,258.452,294.262z M264.369,258.884c0.048-0.435,0.081-0.879,0.083-1.343
			C264.45,258.005,264.417,258.449,264.369,258.884z M264.157,260.233c0.066-0.325,0.119-0.657,0.165-0.995
			C264.276,259.576,264.222,259.908,264.157,260.233z M253.355,276.517c-0.042-0.184-0.1-0.353-0.183-0.496
			C253.255,276.165,253.313,276.333,253.355,276.517z M253.434,277.117c-0.008-0.131-0.029-0.249-0.047-0.37
			C253.405,276.868,253.426,276.985,253.434,277.117z M253.44,277.479c0,0.101,0.005,0.199,0.003,0.303
			C253.445,277.677,253.441,277.58,253.44,277.479z M253.475,280.289c-0.006-0.03-0.007-0.064-0.012-0.094
			C253.468,280.225,253.469,280.259,253.475,280.289z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M289.412,257.062c0.903,0.937,1.861,1.819,3.12,2.4
			c1.231,1.249,2.732,2.228,4.561,2.88c0.157,0.322,0.513,0.447,0.96,0.48c0.33-0.01,0.526,0.113,0.72,0.24
			c4.438-0.982-1.504-3.866-4.08-5.52c-3.035-1.949-6.054-2.462-6-6c2.308-1.345,0.692-6.182-1.68-4.56
			c-0.787,1.053-0.683,2.998-0.72,4.8c0,0.4,0,0.8,0,1.2C286.545,255.128,287.939,256.135,289.412,257.062z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M138.925,488.663c0.079-0.242,0.171-0.476,0.247-0.721
			c-0.078-0.021-0.127-0.071-0.17-0.129c-0.036-0.062-0.055-0.14-0.057-0.234l0,0c-0.001-0.042-0.02-0.066-0.013-0.117
			c-0.007,0.05,0.011,0.075,0.013,0.117c-0.005-0.119-0.002-0.238-0.013-0.357c-1.83,0.132-1.698,1.626-2.16,2.641
			c-1.57,3.448-3.154,6.907-5.04,10.319c-1.819,3.292-4.495,6.551-4.8,10.32c1.418,0.501,1.903-1.077,2.4-1.92
			c1.761-2.986,3.201-5.952,4.8-8.88C135.933,496.405,138.61,492.526,138.925,488.663z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M245.012,600.742c0.08,0,0.16,0,0.24,0c2.926,0.069,9.794,1.405,9.6-1.439
			c-0.131-1.92-4.158-1.793-6-0.961c-0.08,0-0.16,0-0.24,0c-0.808,0.152-2.229-0.309-2.64,0.24c-0.16,0-0.32,0-0.48,0
			c-2.245-0.164-3.966,0.194-5.76,0.48C240.74,600.374,242.886,600.549,245.012,600.742z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M159.303,677.416c0.205-0.062,0.415-0.14,0.627-0.24c0,0,0,0,0.001,0h0
			c0.124-0.059,0.247-0.123,0.369-0.192c-0.122,0.069-0.245,0.134-0.368,0.192c1.38-0.379,2.659-0.857,2.761-2.514
			c-0.245-0.803-1.965-1.384-2.88-0.72c0.283-0.002,0.528,0.011,0.753,0.032c-0.225-0.021-0.471-0.034-0.753-0.032
			c-0.16,0-0.32,0-0.48,0c-0.867,0.093-1.602,0.318-2.16,0.72c-0.08,0-0.16,0-0.24,0c-8.923,2.517-18.69,4.19-29.28,5.04
			c-0.75,0.45-1.863,0.537-2.4,1.2c-0.08,0-0.16,0-0.24,0c-0.652,0.309-1.842,0.078-1.68,1.2c2.421,1.524,7.316,1.449,10.32,0.479
			c0.08,0,0.16,0,0.24,0c1.411,0.208,4.173-0.303,5.76-0.72c0.349-0.371,1.229-0.211,1.92-0.24c0.984-0.056,1.962-0.117,2.64-0.479
			c0.08,0,0.16,0,0.24,0c5.036-0.805,9.758-1.922,13.92-3.6c0.08,0,0.16,0,0.24,0c0.176-0.003,0.372-0.041,0.573-0.094
			C159.225,677.438,159.263,677.429,159.303,677.416z M160.495,676.863c0.067-0.041,0.134-0.082,0.2-0.127
			C160.629,676.781,160.562,676.822,160.495,676.863z M160.88,676.604c0.064-0.048,0.127-0.096,0.189-0.145
			C161.008,676.509,160.945,676.557,160.88,676.604z M161.233,676.324c0.066-0.058,0.128-0.116,0.189-0.175
			C161.361,676.208,161.299,676.267,161.233,676.324z M161.546,676.028c0.092-0.098,0.178-0.196,0.251-0.295
			C161.723,675.832,161.639,675.931,161.546,676.028z M161.853,675.648c0.051-0.075,0.099-0.149,0.137-0.224
			C161.952,675.499,161.904,675.573,161.853,675.648z M162.038,675.315c0.028-0.066,0.05-0.131,0.065-0.195
			C162.087,675.185,162.066,675.249,162.038,675.315z M162.123,675.015c0.008-0.066,0.005-0.132-0.004-0.195
			C162.127,674.883,162.13,674.948,162.123,675.015z M162.108,674.738c-0.023-0.088-0.063-0.171-0.125-0.248
			C162.046,674.567,162.085,674.65,162.108,674.738z M161.16,674.069c-0.119-0.028-0.253-0.051-0.397-0.069
			C160.907,674.019,161.041,674.041,161.16,674.069z M161.956,674.465c-0.059-0.065-0.135-0.127-0.228-0.183
			C161.82,674.338,161.896,674.399,161.956,674.465z M161.612,674.226c-0.083-0.04-0.175-0.077-0.28-0.109
			C161.438,674.148,161.53,674.186,161.612,674.226z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M282.292,674.487c0.003,0.001,0.005,0.002,0.008,0.003
			c-0.004-0.001-0.007-0.002-0.011-0.003C282.29,674.487,282.291,674.487,282.292,674.487c-0.007-0.002-0.014-0.003-0.021-0.006
			c-0.124-0.037-0.247-0.069-0.37-0.097c-0.021-0.005-0.043-0.01-0.065-0.014c-0.12-0.025-0.238-0.045-0.354-0.057
			c-0.013-0.002-0.026-0.004-0.039-0.005c-0.266-0.025-0.519-0.013-0.749,0.055c-0.378-0.002-0.753,0.009-1.122,0.059
			c0.14,2.354,3.557,2.938,5.76,3.6c2.461,0.739,5.833,1.337,7.919,1.68c0.08,0,0.16,0,0.24,0c0.597,0.364,1.457,0.464,2.4,0.48
			c0.08,0,0.16,0,0.24,0c1.16,0.408,4.857,1.129,5.279,0c-2.747-2.113-7.049-2.399-10.8-3.36
			C287.765,676.093,284.94,674.885,282.292,674.487z M282.921,674.708c-0.001-0.001-0.003-0.002-0.005-0.002
			C282.918,674.706,282.92,674.707,282.921,674.708z M284.612,675.383c-0.144-0.046-0.302-0.107-0.467-0.174
			C284.31,675.275,284.469,675.337,284.612,675.383z M284.052,675.173c-0.111-0.046-0.226-0.094-0.344-0.144
			C283.825,675.078,283.942,675.128,284.052,675.173z M280.435,674.469c-0.001,0-0.001,0.001-0.002,0.001
			C280.433,674.47,280.434,674.469,280.435,674.469z M280.2,674.627c-0.019,0.017-0.036,0.039-0.055,0.057
			C280.165,674.666,280.181,674.644,280.2,674.627z M279.992,674.848c-0.065,0.087-0.125,0.184-0.179,0.295
			C279.866,675.031,279.926,674.935,279.992,674.848z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M319.271,736.933c-0.911-0.838-1.818-1.681-2.739-2.51c0,0.08,0,0.16,0,0.239
			C317.45,735.378,318.362,736.143,319.271,736.933z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M281.492,271.941c-0.013,0.413,0.185,0.616,0.48,0.72
			c1.666,1.614,1.06,5.5,4.08,5.76c0.59-2.224-0.563-4.692-1.68-6c0.013-0.413-0.185-0.616-0.48-0.72c0-0.08,0-0.16,0-0.24
			c-0.526-0.673-1.003-1.396-2.4-1.2c-0.051,0.269-0.266,0.374-0.48,0.48C280.976,271.338,281.136,271.738,281.492,271.941z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M271.892,444.982c-0.08,0-0.16,0-0.24,0c-0.336-0.398-2.304-0.398-2.64,0
			c-0.349,0.131-1.24-0.281-1.2,0.239c-4.78,0.68-1.957,5.406,0.917,6.976c0,0,0,0,0,0c0,0,0,0,0,0l0,0
			c0.095,0.074,0.177,0.161,0.283,0.225c0.062,0,0.123,0,0.185,0c0.264,0.111,0.524,0.195,0.775,0.24
			C271.331,451.154,275.404,446.827,271.892,444.982z M270.212,447.622c0.037,0.597-0.377,0.743-0.48,1.2
			c-0.454-0.027-0.167-0.794-0.72-0.721C269.216,447.745,269.785,447.755,270.212,447.622z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M265.172,496.582c-2.077-0.03-2.793,2.597-4.32,3.84
			c-1.024,5.107,5.442,5.739,8.4,4.561C269.309,501.976,267.95,496.623,265.172,496.582z M263.972,501.622
			c-0.177-1.058,0.777-0.983,0.96-1.68c0.494,0.466,0.466,1.454,0.96,1.92C265.433,502.536,264.641,501.532,263.972,501.622z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M268.052,630.742c-1.586,0.354-4.599,4.166-5.04,6.24
			c-0.046,0.216,0.187,1.791,0,2.88c-0.128,0.746,1.383,1.709,1.92,1.68c0.08,0,0.16,0,0.24,0c1.125,0.399,3.915,0.399,5.04,0
			c0.16,0,0.32,0,0.48,0c7.238-0.026,5.234-4.431,2.88-7.68C272.222,631.999,270.601,630.173,268.052,630.742z M266.612,637.463
			c0.591-1.17,1.646-1.874,2.16-3.12c1.492,0.267,1.75,1.77,2.4,2.88C270.087,637.736,268.085,637.336,266.612,637.463z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M483.333,679.942c-0.523,0.837-1.411,1.31-2.88,1.2
			c-0.046,0.835-0.693,1.066-1.2,1.439c-2.979,0.782-4.872,2.648-6.24,5.04c-0.261-0.059-0.163-0.477-0.48-0.479
			c0.416,0.864,0.584,1.977,1.2,2.64c3.239-0.293,6.342-4.409,7.681-7.2c-0.458-0.102-0.435-0.686-1.2-0.479
			c0.519-0.847,2.768-1.225,3.12-1.44c-0.187,0.214-0.259,0.542-0.24,0.96C483.36,681.388,483.917,680.237,483.333,679.942z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M272.852,691.702c0.255-1.69-1.214-3.739-2.16-5.279
			c-3.51-5.719-8.381-2.451-8.4,4.08c-0.908,0.299-2.201,1.47-1.44,2.64c0,0.08,0,0.16,0,0.24c0.157,0.803,1.022,0.897,1.44,1.439
			C266.697,694.653,272.325,695.194,272.852,691.702z M265.652,690.263c0.057-0.982,0.815-1.265,0.96-2.16
			c0.898,0.142,0.743,1.337,1.44,1.68C267.76,690.45,266.578,690.229,265.652,690.263z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M422.853,547.462c-0.251,1.396-0.773,3.329-0.246,4.842
			c-0.02,0.087-0.022,0.158-0.014,0.218c0.001,0.003,0.001,0.006,0.002,0.01c0.009,0.054,0.026,0.1,0.052,0.138
			c0.003,0.005,0.006,0.01,0.01,0.015c0.024,0.033,0.053,0.063,0.085,0.088c0.009,0.008,0.017,0.015,0.025,0.021
			c0.03,0.021,0.06,0.043,0.09,0.063c0.005,0.003,0.009,0.007,0.014,0.01c0,0,0,0,0-0.001c0.052,0.035,0.099,0.075,0.139,0.123
			c-0.04-0.048-0.087-0.087-0.139-0.122c0.072,0.12,0.132,0.245,0.223,0.356c0-0.088-0.027-0.149-0.063-0.203
			c0.035,0.054,0.062,0.115,0.063,0.203c0.076-0.016,0.165,0.019,0.24,0c1.895-0.478,2.611-6.691,3.119-9.12
			c0.846-4.038,0.932-7.645,1.921-10.32c-0.946,2.173,0.1-5.7,0.239-7.2c1.1-11.763,1.625-25.879,0.24-38.4c0-0.08,0-0.159,0-0.239
			c-0.085-0.414-0.15-0.849-0.204-1.294c0.054,0.445,0.119,0.88,0.204,1.294c-0.06-3.142-0.019-8.461-1.68-10.801
			c-0.061,0.116-0.108,0.237-0.161,0.357c-0.027-0.038-0.051-0.08-0.079-0.117c0.006,0.085,0.011,0.173,0.017,0.258
			c-1.221,3.01,0.033,6.909,0.224,10.542c0.618,11.81-0.03,24.21-0.96,35.761c0.039,0.278-0.053,0.427-0.24,0.479
			c-0.156-1.283,0.315-3.195-0.24-4.08C426.298,530.588,424.352,539.105,422.853,547.462z M427.672,499.986
			c0.003-0.924-0.001-1.848-0.007-2.771C427.671,498.139,427.675,499.063,427.672,499.986z M428.479,484.707
			c-0.009-0.131-0.018-0.262-0.025-0.394C428.461,484.445,428.47,484.576,428.479,484.707z M428.642,486.599
			c-0.049-0.415-0.086-0.842-0.119-1.273C428.556,485.756,428.593,486.184,428.642,486.599z M427.138,480.684
			c-0.046-0.806-0.097-1.604-0.148-2.398C427.041,479.08,427.092,479.877,427.138,480.684z M427.17,481.293
			c0.047,0.845,0.093,1.691,0.133,2.546C427.263,482.984,427.217,482.138,427.17,481.293z M427.324,484.32
			c0.041,0.897,0.081,1.797,0.115,2.702C427.405,486.117,427.365,485.218,427.324,484.32z M427.664,496.734
			c-0.008-0.916-0.021-1.83-0.036-2.744C427.644,494.904,427.656,495.818,427.664,496.734z M427.621,493.477
			c-0.017-0.928-0.039-1.853-0.064-2.775C427.582,491.624,427.604,492.549,427.621,493.477z M427.546,490.241
			c-0.025-0.913-0.057-1.822-0.09-2.729C427.489,488.419,427.521,489.327,427.546,490.241z M427.432,510.773
			c-0.03,0.718-0.06,1.438-0.096,2.15C427.372,512.211,427.401,511.491,427.432,510.773z M427.667,500.663
			c-0.005,0.86-0.01,1.721-0.021,2.581C427.657,502.385,427.662,501.523,427.667,500.663z M427.63,504.037
			c-0.014,0.817-0.028,1.636-0.049,2.451C427.602,505.673,427.616,504.854,427.63,504.037z M427.551,507.451
			c-0.022,0.757-0.044,1.515-0.072,2.268C427.507,508.966,427.528,508.208,427.551,507.451z M427.268,514.105
			c-0.037,0.664-0.072,1.329-0.115,1.987C427.195,515.435,427.23,514.77,427.268,514.105z M427.055,517.44
			c-0.043,0.595-0.082,1.191-0.13,1.78C426.973,518.632,427.012,518.035,427.055,517.44z M426.798,520.664
			c-0.048,0.545-0.092,1.095-0.144,1.634C426.706,521.759,426.75,521.209,426.798,520.664z M426.47,524.05
			c-0.045,0.42-0.085,0.847-0.133,1.263C426.385,524.896,426.425,524.47,426.47,524.05z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M499.893,576.263c-0.028-0.691,0.131-1.571-0.24-1.921
			c-0.157-1.682,0.317-3.996-0.239-5.279c0-0.4,0-0.8,0-1.2c-0.087-4.554-0.181-9.1-0.721-13.2c-0.01-0.33,0.113-0.526,0.24-0.72
			c-0.157-1.522,0.316-3.677-0.24-4.8c0-0.08,0-0.16,0-0.24c0.475-3.468,0.03-6.608,0-10.32c0-0.16,0-0.32,0-0.479
			c-0.153-0.966,0.313-2.553-0.239-3.12c-0.618-11.989-0.869-25.758-1.92-38.4c-0.031-3.93-0.641-9.84-1.2-14.64
			c-0.217-0.904-0.072-2.169-0.48-2.88c-0.413-7.107-2.182-12.858-3.359-19.2c0.215-0.824,1.037-1.043,0.96-2.16
			c-0.546-0.975-0.53-2.511-1.2-3.36c-0.28-0.04-0.261-0.379-0.24-0.72c-1.021-2.501-1.81-7.31-2.88-10.56
			c-0.355-0.045-0.22-0.58-0.24-0.961c0.127-1.007-0.419-1.34-0.479-2.159c-0.094-0.867-0.319-1.602-0.721-2.16
			c-0.187-0.053-0.278-0.201-0.239-0.48c0.215-0.782-0.697-2.497-1.44-3.12c-0.304-0.416-0.812-0.629-0.72-1.439
			c0.159-0.334-1.149-2.518-1.44-2.4c-2.015-3.985-5.045-6.954-6.72-11.28c-0.357-0.923-0.925-1.635-1.68-2.159
			c-0.776-0.665-1.573-1.308-2.4-1.92c-1.141-0.7-2.778-0.902-4.08-1.44c-0.519-0.201-0.818-0.622-1.68-0.48
			c-0.851-0.03-1.366-0.394-1.92-0.72c-1.22-0.863-4.582-1.657-6.721-2.4c0.404-1.565,0.238-3.637,0-4.8c0,0.08,0,0.16,0,0.24
			c-0.356-0.363-0.387-1.053-0.479-1.68c-0.221-10.179-11.815-11.065-20.4-13.68c-0.563-0.237-1.179-0.421-1.92-0.48
			c-0.808-0.393-1.775-0.625-2.64-0.96c0,0.08,0,0.16,0,0.24c-0.187-0.054-0.474-0.006-0.48-0.24
			c-0.618-0.342-1.145-0.775-2.16-0.72c-1.101-0.099-1.874-0.525-2.64-0.96c-0.491-0.557-2.255-1.094-2.64-0.96
			c-2.061-0.58-3.993-1.287-5.761-2.16c0.146-0.014,0.284-0.036,0.24-0.24c-2.792-0.808-5.586-1.615-8.88-1.92
			c-0.443-0.356-1.141-0.46-1.92-0.48c-3.975-1.005-6.802-1.679-10.8-2.64c-0.283-0.038-0.682,0.041-0.721-0.24
			c-0.889-0.471-1.832-0.888-3.12-0.96c-1.101-0.099-1.874-0.525-2.64-0.96c-0.601-0.679-1.964-0.596-2.88-0.96
			c-2.243-1.077-5.566-0.274-6.96,0.96c0.08,0,0.16,0,0.24,0c-0.063,0.337-0.526,0.273-0.721,0.48
			c-1.761,0.879-2.662,2.617-3.84,4.08c-1.25-0.155-3.801-1.145-4.8,0c-0.878,0.562-1.588,1.292-2.4,1.92
			c-0.3,1.125-1.737-0.172-1.92,0.24c-0.186-0.054-0.473-0.007-0.479-0.24c-3.646-1.875-7.786-3.253-12-4.56
			c-0.591-1.767,0.298-3.764,0-6c-0.185-0.904,0.293-1.147,0.24-1.92c0.441-3.079,0.506-6.534,0.479-10.08
			c0.182-3.941-0.296-7.224-0.24-11.04c-0.842-1.742,1.211-2.607,0.24-4.32c-0.459-1.461-2.227-1.614-3.36-2.4
			c-0.173-0.733,0.246-0.875,0.24-1.44c-0.158-8.105,0.857-18.809,0.48-27.12c-0.08-1.767-0.707-3.739-0.48-5.28
			c0.24-1.636,2.632-3.911,2.4-4.56c0.188-0.932,0.756-1.484,1.2-2.16c0.014,0.146,0.035,0.284,0.239,0.24
			c0.352-1.969,1.605-3.035,1.44-5.52c0.131-0.349-0.28-1.24,0.24-1.2c0.548-1.132,0.172-3.188,0.72-4.32
			c4.328,0.329,7.246-0.754,9.36-2.64c0.051-0.269,0.266-0.374,0.479-0.48c1.66-1.302,3.556-3.643,3.84-5.52
			c0.054-0.186,0.007-0.473,0.24-0.48c1.636-2.045,1.856-8.862,3.12-11.28c0.037-0.283-0.041-0.681,0.24-0.72
			c0.473-0.407,0.644-1.116,0.72-1.92c0.054-0.186,0.007-0.473,0.24-0.48c0.29-0.521,1.055-1.809,0.72-2.16
			c0.262-0.698,0.225-1.695,0.72-2.16c2.183-7.962,1.721-21.539-0.96-29.04c-0.296-0.664-0.635-1.285-0.72-2.16
			c-0.561-3.119-1.477-5.883-2.88-8.16c-0.633-1.527-1.609-2.71-2.16-4.32c-0.103-0.857-0.603-1.317-1.2-1.68
			c-0.302-0.098-0.49-0.31-0.479-0.72c-1.151-1.009-2.456-1.865-4.32-2.16c-1.99-0.944-4.698,0.736-5.52,0.96
			c-0.293-0.993-0.286-3.546-0.721-3.6c-0.419-0.221-0.529-0.75-0.479-1.44c0.033-0.993-0.066-1.854-0.48-2.4
			c-0.795-1.285-1.152-3.007-1.439-4.8c-0.489-1.352-0.515-3.166-1.2-4.32c-0.741-1.099-0.989-2.69-1.2-4.32
			c-0.071-0.809-0.12-1.64-0.479-2.16c-0.389-0.491-0.188-1.573-0.721-1.92c-0.085-1.115-0.308-2.092-0.72-2.88
			c0,0.08,0,0.16,0,0.24c-0.779-1.541-0.901-3.739-1.68-5.28c0.022-1.383-0.659-2.061-0.96-3.12c-0.945-4.095-2.927-7.154-5.28-9.84
			c-0.302-0.099-0.49-0.31-0.48-0.72c-0.345-0.862-1.252-2.418-1.68-2.4c-0.937-0.904-1.818-1.861-2.4-3.12
			c-0.022-0.937-1.171-0.749-1.199-1.68c-2.103-2.138-4.965-4.794-7.2-6c-1.701-1.098-3.244-2.356-4.8-3.6
			c-0.247-0.639-2.138-1.699-3.36-2.16c-1.294-0.546-2.736-0.944-3.84-1.68c0.146-0.014,0.284-0.036,0.24-0.24
			c-1.123-0.238-1.998-0.722-3.12-0.96c-0.769-0.272-1.592-0.488-2.641-0.48c-0.573-0.146-1.741,0.301-1.92-0.24
			c-2.401-0.479-5.571-0.188-7.439-1.2c-0.447-0.033-0.803-0.158-0.96-0.48c-0.054-0.187-0.201-0.279-0.48-0.24
			c-1.561-2.2-4.354-3.166-7.92-3.36c-0.08,0-0.16,0-0.24,0c-0.832-0.768-2.634-0.566-3.6-1.2c-0.08,0-0.16,0-0.24,0
			c-1.92,0-3.84,0-5.76,0c-0.08,0-0.16,0-0.24,0c-2.369,0.671-5.888,0.192-8.4,0.72c-0.044,0.355-0.58,0.22-0.96,0.24
			c-0.574-0.146-1.741,0.301-1.92-0.24c-0.586,0.534-1.714,0.526-2.88,0.48c-6.884,0.636-13.888,2.431-18.72,4.32
			c-0.074,0.326-0.601,0.199-0.96,0.24c-2.017,0.143-3.656,0.664-5.04,1.44c-0.48,0-0.96,0-1.44,0c-3.141,1.66-7.305,3.574-9.6,5.28
			c-2.349,1.491-4.671,3.009-6.96,4.56c-3.343,2.097-6.04,4.84-8.4,7.92c0.041,0.601-0.369,0.751-0.72,0.96
			c-0.822,0.698-1.498,1.543-1.92,2.64c-0.298,0.742-0.592,1.488-1.2,1.92c-0.333-0.238-1.056,1.174-1.2,1.92
			c-0.948,0.972-1.374,2.466-1.92,3.84c-0.03,0.85-0.394,1.366-0.72,1.92c-0.895,1.745-1.85,3.43-2.4,5.52
			c-0.217,0.903-0.516,1.724-0.96,2.4c-0.296-0.124-0.887,1.504-0.96,2.4c-0.211,0.669-0.299,1.46-0.72,1.92
			c-0.39,0.098-0.943,2.091-1.2,3.12c-0.02,0.38,0.115,0.916-0.24,0.96c-0.539,0.188-0.937,2.579-1.2,3.84
			c-0.02,0.38,0.115,0.915-0.24,0.96c-0.906,2.294-0.961,5.439-1.44,8.16c0,0.16,0,0.32,0,0.48c-0.146,0.574,0.301,1.742-0.24,1.92
			c-0.6,5.657,0.11,13.317,0.96,17.52c0.521,5.079,1.53,9.67,2.64,14.16c0.02,0.78,0.124,1.477,0.48,1.92
			c0.735,5.265,3.168,8.831,3.84,14.16c-1.609-0.076-2.161-1.821-3.36-2.64c-1.304-0.89-4.349-2.023-5.52-1.2
			c-2.004,0.796-3.813,1.787-5.28,3.12c-0.793,0.487-1.217,1.342-1.44,2.4c0.011,1.989-0.103,4.103,0.24,5.76
			c0.491,0.389-0.011,1.771,0.48,2.16c0.329,2.311,1.159,4.121,1.68,6.24c0.315,1.205,0.621,2.418,0.96,3.6
			c0.379,2.341,1.101,4.339,1.68,6.48c0.33,1.75,0.776,3.384,1.2,5.04c0.63,3.29,1.548,6.292,2.64,9.12
			c0.01,1.031,0.313,1.767,0.72,2.4c0.785,0.975,1.005,2.515,1.44,3.84c-0.176,0.745,1.231,3.24,1.68,3.36
			c0.28,0.36,0.693,0.587,0.72,1.2c0.389,1.132,1.373,1.667,2.16,2.4c0.204-0.044,0.227,0.094,0.24,0.24
			c0.43,0.689,1.049,1.19,1.92,1.44c0.193,0.206,0.657,0.143,0.72,0.48c0.3,0.58,0.984,0.776,1.68,0.96
			c0.279-0.039,0.427,0.053,0.48,0.24c0.432,0.608,1.265,0.815,2.16,0.96c2.101,0.827,3.897,0.487,6.48,0.72
			c0.088-0.016,0.167-0.008,0.243,0.01c0.441,1.245,0.74,3.884,1.437,4.07c0.384,0.336,0.613,0.827,0.72,1.44
			c0.983,2.617,2.849,4.351,4.32,6.48c1.436,1.844,2.967,3.593,4.56,5.28c1.534,1.267,2.604,2.997,3.84,4.56
			c0.701,1.461,3.012,3.386,4.32,5.04c0.149,0.651,1.002,0.598,0.96,1.44c-0.104,4.863-0.352,11.448,0.48,16.08
			c0.395,0.033,0.395,1.647,0,1.68c-0.959,3.556,1.273,11.427-1.92,13.44c-0.782,0.187-1.892,1.296-1.68,1.68
			c-1.104,1.376-2.902,2.058-4.32,3.12c-0.696,0.184-1.38,0.379-1.68,0.96c-1.023,1.056-2.422,1.737-3.84,2.4
			c-1.171,0.189-1.909,0.812-2.88,1.2c-0.818,0.178-0.396-0.884-0.96-0.96c-0.214-0.106-0.43-0.21-0.48-0.48
			c-1.183-1.429-3.278-0.563-5.52-0.72c-0.447-0.033-0.802-0.158-0.96-0.48c-0.944-0.416-1.718-1.001-3.12-0.96
			c-0.38-0.02-0.916,0.115-0.96-0.24c-0.989-0.375-3.672-1.125-5.76-1.2c-1.04,0-2.08,0-3.12,0c-4.825,0.375-8.248,2.152-11.76,3.84
			c-0.502,0.458-1.146,0.774-1.92,0.96c-1.553,0.127-2.482,0.878-3.6,1.44c-0.158,0.322-0.513,0.447-0.96,0.48
			c-0.967,0.082-2.784,0.566-2.64,0.96c-0.007,0.233-0.294,0.187-0.48,0.24c-2.494,0.467-5.359,1.839-6.72,2.64
			c-0.497,0.543-1.33,0.75-2.16,0.96c-1.308,0.216-4.647,1.749-5.52,2.4c-2.003,1.197-4.206,2.194-6.48,3.12
			c-0.958,0.091-2.504,0.846-2.4,1.2c-0.794,0.646-1.72,1.159-2.88,1.44c-0.67,0.056-2.441,1.075-3.36,1.68
			c-0.105,0.295-0.307,0.493-0.72,0.48c-0.305-0.133-1.753,0.638-2.16,1.2c-3.179,1.821-5.403,3.303-8.88,4.56
			c-0.567,0.081-3.484,1.147-3.6,1.68c-1.303,0.777-2.535,1.625-3.84,2.4c-0.822,0.698-1.666,1.374-1.92,2.64
			c-0.388,0.813-0.107,2.293-1.2,2.4c-1.708,1.012-3.453,1.986-5.04,3.119c-0.698,0.271-2.318,0.87-2.16,1.2
			c-2.917,1.724-5.613,3.667-8.16,5.761c-0.938,0.342-1.578,0.981-1.92,1.92c-0.378,0.741-0.827,1.412-1.44,1.92
			c-0.783,0.187-1.366,1.821-0.72,2.64c0,0.08,0,0.16,0,0.24c-0.4,0.72-0.93,1.31-0.96,2.399c-0.42,0.46-0.509,1.252-0.72,1.921
			c-0.155,0.885-0.247,1.833-0.72,2.399c-0.533,0.348-0.332,1.429-0.72,1.92c-0.632,1.288-1.11,2.73-1.44,4.32
			c-0.287,0.833-0.375,1.864-0.96,2.4c-0.444,0.676-0.743,1.496-0.96,2.399c-0.444,0.676-0.743,1.497-0.96,2.4
			c-0.037,0.763-0.544,1.056-0.48,1.92c-0.464,1.136-1.034,2.165-0.96,3.84c-0.854,5.819-0.397,12.973-1.2,17.76
			c-0.143,0.497,0.297,1.577-0.24,1.681c-0.7,7.643-1.027,16.037-0.72,23.76c0.139,0.779-0.164,1.116-0.24,1.68
			c-0.027,6.133-0.285,12.036-0.96,17.521c0.087,1.527-0.427,2.453-0.24,4.08c-0.169,1.351-0.64,2.399-0.48,4.08
			c-0.02,0.38,0.115,0.915-0.24,0.96c-0.479,0.8-0.51,2.049-0.48,3.359c-0.363,1.718-0.591,3.569-0.96,5.28
			c-0.482,2.959-1.369,7.59-1.2,12c-0.281,0.039-0.203,0.438-0.24,0.72c-0.535,4.506-0.494,9.586-0.48,14.641c0,0.8,0,1.6,0,2.399
			c-0.287,0.673-0.264,1.656-0.24,2.641c0.012,1.212-0.094,2.306-0.48,3.12c-0.466,1.621-0.674,3.25-1.2,5.279
			c-0.353,0.768-0.687,1.554-0.72,2.641c-0.368,2.032-0.928,3.872-1.68,5.52c-0.494,0.946-0.996,1.885-1.2,3.12
			c-0.02,0.38,0.116,0.915-0.24,0.96c-0.705,1.136-1.043,2.637-1.44,4.08c-1.099,2.422-2.359,5.96-2.4,8.64
			c-0.138,0.422,0.29,1.41-0.24,1.44c-0.318,0.808-1.168,4.426-0.48,6.24c-0.118,1.077,0.122,1.798,0.24,2.64
			c-0.053,2.214,0.423,3.897,0.48,6c-0.165,0.965,0.23,1.37,0.24,2.16c0.01,0.19-0.019,0.419-0.006,0.605
			c-0.048,5.373,0.226,12.953-1.194,16.675c-0.17,1.109-0.484,2.075-0.96,2.88c-0.813,1.907-1.441,3.999-1.92,6.24
			c-0.356,0.443-0.459,1.14-0.48,1.92c0.312,0.318,0.316,0.893-0.24,0.96c-0.372,0.588-0.358,1.562-0.48,2.399
			c-0.925,2.995-0.921,6.919-1.92,9.841c-0.806,2.074-1.367,6.472-1.92,9.6c-0.131,0.349,0.281,1.24-0.24,1.2
			c-1.008,3.138-0.442,6.546-0.96,10.319c-0.143,0.498,0.296,1.577-0.24,1.681c-0.57,1.401-0.181,2.485-0.48,4.319
			c-0.241,1.125-0.886,3.107-0.48,4.08c0.098,0.578-0.438,0.521-0.24,1.2c-0.365,0.762-0.916,2.594-0.72,3.12
			c-0.068,1.212-0.479,2.081-0.72,3.12c-0.475,0.805-0.789,1.771-0.96,2.88c-0.249,1.592-0.692,2.987-1.44,4.08
			c-0.358-0.497-0.843,2.176-0.24,2.64c1.016,1.196,1.939,1.096,1.92,3.12c-0.033,0.447-0.158,0.803-0.48,0.96
			c-0.776,0.747-1.834,3.284-2.4,5.04c0.039,0.279-0.053,0.428-0.24,0.48c-0.459,0.028-1.047,1.986-1.44,2.88
			c0.036,0.596-0.124,0.996-0.48,1.2c-0.858,0.905-2.6,4.276-2.88,5.76c-1.282,2.878-2.347,5.974-3.6,8.88
			c-0.695,0.828-1.398,3.72-1.68,5.76c-0.02,0.38,0.115,0.916-0.24,0.96c-1.286,3.835-2.904,10.696-3.6,15.12
			c-0.169,1.991-0.706,3.614-0.96,5.521c-1.172-0.133-1.203,0.877-1.68,1.439c0,0.32,0,0.641,0,0.96c0.08,0,0.16,0,0.24,0
			c0.27,0.69,0.75,1.17,1.44,1.44c1.572,0.858,3.332,0.043,5.52,0.24c0.48,0,0.96,0,1.44,0c0.574,0.146,1.741-0.302,1.92,0.239
			c0.801-0.404,0.819,0.152,1.68,0.24c13.861,0.459,28.488,0.152,42.72,0.24c0-0.08,0-0.16,0-0.24c0.56,0,1.12,0,1.68,0
			c0.4,0,0.8,0,1.2,0c1.28,0,2.56,0,3.84,0c9.437-0.246,21.962,0.896,31.68,0.24c0.779-0.053,3.426,0.599,2.64-1.44
			c0.704-0.336-0.224-2.304,0.48-2.64c0.507,0.017,0.333-1.622,0.24-2.64c0.164-0.957,0.523-1.718,0.48-2.88
			c0.041-0.359-0.086-0.886,0.24-0.96c0.591-0.769,0.604-2.115,0.96-3.12c0.523-1.241,1.034-3.762,0.96-4.801
			c0.138-0.422-0.29-1.409,0.24-1.439c0.494-2.548,1.45-5.908,1.2-8.4c0.143-0.497-0.297-1.576,0.24-1.68
			c0.896-2.784,0.958-6.402,1.2-9.84c-0.108-2.349,0.117-4.363,0.48-6.24c0.39-1.29,0.323-3.037,0.48-4.56c0-0.4,0-0.801,0-1.2
			c0.549-0.411,0.088-1.832,0.24-2.641c0.258-1.904,0.82-4.776,0.24-6.72c0-0.16,0-0.32,0-0.48c0.477-2.962,0.348-6.532,0.72-9.6
			c-0.183-1.303,0.38-1.859,0.24-3.12c0.064-1.615,0.44-2.92,0.96-4.08c0.014,0.146,0.036,0.284,0.24,0.24
			c0.883-4.717,2.256-8.943,3.36-13.44c0.552-1.048,0.408-2.791,0.96-3.84c0.613-2.907,1.593-5.447,2.4-8.16c-0.08,0-0.16,0-0.24,0
			c0.009-1.03,0.313-1.767,0.72-2.399c0.471-0.89,0.888-1.833,0.96-3.12c0.041-0.359-0.085-0.886,0.24-0.96
			c0.865-0.895,0.569-2.951,1.44-3.84c0.159,3.04-0.319,6.719,0.24,9.359c0,0.881,0,1.761,0,2.641
			c0.877,12.396-1.255,21.785-2.64,31.92c0.071,1.608-0.254,3.614,0.48,4.56c0.58,0.743,0.743,2.907,0,3.601
			c-0.803,2.485,0.19,5.248,0.24,7.68c0.148,1.748-0.418,2.782-0.24,4.561c-0.405,0.954-0.188,2.531-0.24,3.84c0,1.04,0,2.08,0,3.12
			c0.034,1.24-0.67,3,0,4.08c0.179,1.777-0.388,2.812-0.24,4.56c-0.235,7.125-0.294,14.426-0.24,21.84c0,1.04,0,2.08,0,3.12
			c0,0.561,0,1.12,0,1.68c0,0.561,0,1.12,0,1.681c-0.339,1.624-0.967,6.882,1.68,5.52c2.451-0.189,5.736,0.456,7.44-0.479
			c73.65,0.171,147.908-0.111,222.24,0.239c0-0.079,0-0.159,0-0.239c1.92,0,3.84,0,5.761,0c0.72,0,1.439,0,2.159,0
			c2.08,0,4.16,0,6.24,0c6.561,0,13.12,0,19.681,0c0-0.08,0-0.16,0-0.24c0.8,0,1.6,0,2.399,0c3.28,0,6.561,0,9.84,0
			c3.521,0,7.04,0,10.561,0c0-0.08,0-0.16,0-0.24c0.56,0,1.12,0,1.68,0c0.4,0,0.8,0,1.2,0c1.36,0,2.72,0,4.08,0
			c10.96-0.16,22.56,0.32,33.12-0.24c0.281-0.038,0.202-0.438,0.24-0.72c-0.17-1.184-0.175-2.438-0.24-3.12
			c-0.41-1.189,0.032-3.231-0.48-4.319c0-0.32,0-0.641,0-0.96c0.579-2.103,0.017-5.135-0.239-7.2c0.107-1.309-0.429-1.973-0.24-3.36
			c-0.065-6.814-0.531-13.229-0.96-19.68c0-1.2,0-2.4,0-3.601c-0.594-3.806-0.308-8.493-0.96-12.239
			c-0.028-1.263-0.057-3.928-0.48-5.521c-0.112-1.647-0.256-3.265-0.72-4.56c0,0.079,0,0.16,0,0.239
			c-0.777-0.789-0.255-1.463-0.48-2.88c0.202-0.295-0.069-2.159-0.479-2.64c-0.674-1.566-1.061-3.42-1.44-5.28
			c-0.072-2.407-1.053-3.907-1.68-5.76c-0.521,0.04-0.108-0.852-0.24-1.2c0.777-0.984-0.89-2.183-0.24-3.12
			c1.579-1.301,1.285-4.475,2.16-6.479c0.416-0.633,1.654-1.696,1.44-2.16c0.054-0.187,0.007-0.474,0.24-0.48
			c2.483-1.479,1.258-8.384-0.24-10.8c0.054-1.014-0.37-1.55-0.48-2.4c-0.716-1.164-1.998-2.355-1.199-4.319
			c0.147-0.652,0.476-1.124,0.96-1.44c0.717-1.842,1.227-3.893,1.439-6.24c0.985-4.294,1.891-8.669,2.4-13.439
			c0.367-1.713,0.838-3.322,0.72-5.521c0.054-0.186,0.007-0.473,0.24-0.479c0.298-1.465,1.041-4.557,0.96-7.2
			c0.493-1.986,0.218-4.742,0.96-6.48c0.554-0.726,0.084-2.475,0.24-3.6c0.054-0.187,0.006-0.474,0.239-0.479
			c0.437-4.204,0.979-8.302,0.96-12.961c0-1.039,0-2.079,0-3.119c0.233-0.007,0.187-0.294,0.24-0.48
			c0.159-3.521-0.319-7.68,0.24-10.8c0-0.16,0-0.32,0-0.48c0-1.279,0-2.56,0-3.84c0-2.479,0-4.96,0-7.439
			C500.053,576.263,499.973,576.263,499.893,576.263z M469.413,418.582c1.139,0.142,1.31,1.25,2.159,1.68
			c0.626,0.734,0.863,1.857,1.681,2.4c0.089,0.791,0.561,1.199,0.96,1.68c0.658,1.342,1.336,2.664,2.16,3.84
			c0.354,0.525,0.468,1.292,0.96,1.681c0.186,0.773,0.501,1.418,0.96,1.92c-0.007,0.807,0.33,1.27,0.72,1.68
			c2.438,6.841,5.501,13.06,7.44,20.4c1.204,5.275,2.09,10.87,3.119,16.319c0.249,1.832,0.414,3.746,0.961,5.28
			c0.214,1.146,0.069,2.65,0.479,3.601c0.15,2.489,0.486,4.793,0.72,7.199c0.545,4.496,0.518,9.563,1.2,13.92
			c0.057,3.068,0.293,5.819,0.48,8.16c-0.17,1.609,0.246,2.634,0.239,4.08c-0.08,0-0.159,0-0.239,0c0.656,2.748-0.05,5.38,0.72,7.92
			c0,0.08,0,0.16,0,0.24c-0.397,0.259-0.397,2.142,0,2.4c0.337,0.993-0.685,1.854,0,2.399c0.15,0.729-0.308,2.067,0.24,2.4
			c0,0.08,0,0.16,0,0.24c-0.366,1.702-0.342,3.623,0,5.279c0,0.08,0,0.16,0,0.24c0.249,0.983-0.739,1.963,0,2.641
			c0,0.079,0,0.159,0,0.239c0,0.88,0,1.761,0,2.641c0,0.08,0,0.16,0,0.239c0.154,1.046-0.313,2.714,0.239,3.36c0,0.16,0,0.32,0,0.48
			c-0.398,0.57-0.398,2.789,0,3.359c0,0.16,0,0.32,0,0.48c0.034,1.24-0.67,3.001,0,4.08c0,0.16,0,0.319,0,0.479
			c0.336,1.621-0.704,3.128,0,4.32c0,0.16,0,0.32,0,0.479c0.158,1.603-0.316,3.837,0.24,5.04c0,0.16,0,0.32,0,0.48
			c0.158,1.842-0.317,4.317,0.24,5.76c0,0.16,0,0.32,0,0.48c0,0.8,0,1.6,0,2.399c0.157,1.443-0.316,3.517,0.24,4.561
			c-0.068,0.388,0.227,0.413,0.239,0.72c0,1.28,0,2.56,0,3.84c-0.161,7.759-0.233,15.606-0.479,23.28
			c-0.028,1.172,0.129,2.529-0.24,3.36c0,0.319,0,0.64,0,0.96c-0.554,0.726-0.084,2.475-0.24,3.6
			c-0.426,0.454-0.175,1.585-0.239,2.4c-0.152,0.808,0.309,2.229-0.24,2.64c-0.482,0.958-0.122,2.759-0.48,3.84
			c-0.223,2.097-0.734,3.905-0.72,6.24c-0.514,2.446-0.874,5.046-1.2,7.68c-0.427,1.573-0.494,3.506-0.96,5.04
			c-0.216,3.544-1.236,6.284-1.439,9.84c-0.601,1.4-0.712,3.289-0.96,5.04c-0.144,0.498,0.296,1.577-0.24,1.681
			c-0.477,0.964-1.077,1.803-1.2,3.12c0.021,0.34,0.04,0.68-0.24,0.72c-0.717,0.807-1.403,2.91-0.72,4.32
			c-0.04,0.279-0.379,0.26-0.72,0.239c-1.103-0.535-2.712,0.483-4.08,0.48c0.017-0.816,0.074-1.675-0.48-1.92
			c0.146,0.574,0.346,1.095,0.24,1.92c0,0.08,0,0.16,0,0.24c-8.196,1.883-14.85,5.31-22.56,7.68
			c-11.01-3.168-23.756,2.328-35.04-0.24c-0.214,0.106-0.43,0.211-0.48,0.48c-0.952,0.761-1.046,1.688-0.479,2.88
			c5.154,2.9,16.073,1.383,23.52,0.479c0.08,0,0.16,0,0.24,0c1.467-0.029,0.062,0.47-0.24,0.721c-0.08,0-0.16,0-0.24,0
			c-3.409,1.07-7.004,1.956-10.08,3.359c-0.213,0.187-0.542,0.259-0.96,0.24c-0.979,0.461-2.412,0.468-3.12,1.2
			c-0.213,0.187-0.542,0.258-0.96,0.24c-3.484,0.915-6.325,2.475-10.319,2.88c0.181-17.684,2.478-35.265-1.44-49.92
			c0-0.08,0-0.16,0-0.24c-1.53-5.486-2.219-10.656-3.12-16.561c0-0.08,0-0.159,0-0.239c2.537-3.624,3.749-8.571,5.76-12.721
			c1.9,0.719,4.512,1.463,7.2,2.16c2.99,0.775,8.938,2.672,9.601,0.24c0.884-3.245-8.356-3.541-10.607-4.331
			c-0.155-0.074-0.305-0.15-0.433-0.229c-0.5,0.521-0.776-0.029-1.2-0.239c-1.286-0.395-2.836-0.524-3.84-1.2
			c0.154-0.886,0.246-1.834,0.72-2.4c4.163,0.34,10.593-2.832,14.16-2.399c1.594,0.193,2.767,2.179,4.56,1.68
			c0.925-0.257,2.243-2.073,0.96-3.12c0-0.08,0-0.16,0-0.24c-4.06-3.792-11.082-0.798-16.56,0c-1.151,0.168-2.837,0.91-2.4-0.239
			c-0.173-0.733,0.246-0.875,0.24-1.44c0.725-1.195,0.902-2.938,1.2-4.56c2.806,0.469,4.266,0.188,6.24,0.96
			c8.768-0.47,15.088,5.304,22.08,3.359c-1.078-2.121-3.693-2.707-6.24-3.359c-2.504-0.697-4.992-1.408-7.44-2.16
			c-2.49-1.03-6.319-0.721-8.88-1.681c-0.289-1.585,1.502-2.33,1.92-3.84c0.566-2.044-0.332-4.477,0.96-6.239c0-0.08,0-0.16,0-0.24
			c1.37-1.99,3.516-3.205,5.521-4.561c0.89-0.55,2.052-0.828,3.12-1.199c4.319-2.081,9.789-3.012,14.16-5.04
			c0.945-0.095,0.764-1.316,1.68-1.44c0.53-0.03,0.102-1.018,0.24-1.44c-2.352-1.629-6.025-0.073-8.16,0.721
			c-2.313,0.647-4.408,1.512-6.721,2.16c-3.195,0.842-6.463,2.798-8.892,3.747c0.004,0.271-0.006,0.558,0.012,0.813
			c-0.018-0.255-0.008-0.541-0.012-0.813l0,0c-0.005-0.333-0.013-0.662,0.002-1.017c0-0.002,0-0.004,0-0.006
			c0.893-4.181,1.487-8.656,2.41-12.805c0-0.08,0-0.16,0-0.24c0.828-3.815,3.936-8.904,0.96-12c1.133-2.947,1.895-6.266,2.64-9.601
			c0-0.08,0-0.159,0-0.239c0.313-1.047,0.726-1.995,0.721-3.36c-0.192-0.832,0.357-0.923,0.239-1.68
			c0.469-0.892,0.65-2.07,0.721-3.36c-0.288-0.315-0.094-0.378,0-0.72c0.237-0.89,0.863-2.646,0.479-3.36c0-0.08,0-0.16,0-0.24
			c0.304-1.141,0.621-3.532,0.48-4.319c0-0.08,0-0.16,0-0.24c0.428-4.81,0.78-9.357,0.479-14.16c0-0.16,0-0.32,0-0.48
			c0.382-5.738,0.835-11.229,0.721-16.8c0-0.08,0-0.16,0-0.239c0.369-1.15,0.445-2.595,0.479-4.08c0-0.08,0-0.16,0-0.24
			c0.746-7.493,2.244-13.502,3.36-20.16c-0.032-0.592-0.037-1.156,0.239-1.44c2.242-11.366,5.144-21.529,6.24-34.319
			c0.112-1.648,0.36-3.16,0.24-5.04c1.516,0.804,3.364,1.275,4.8,2.159c-1.193-1.686-4.668-1.091-5.04-3.6
			c0.096-0.785,0.209-1.552,0.96-1.68c0,0.08,0,0.16,0,0.24c3.555,0.046,6.447,0.752,9.12,1.68
			C466.221,417.454,467.592,418.243,469.413,418.582z M412.293,750.022c-0.511-0.85-0.795-1.925-1.44-2.64
			c-0.127-0.193-0.25-0.39-0.24-0.721c-1.245-1.635-2.116-3.644-3.6-5.039c-0.361-0.68-1.058-1.022-1.2-1.921
			c-2.201-3.079-4.241-6.318-6.72-9.119c0-0.08,0-0.16,0-0.24c-1.816-3.064-4.984-6.056-6.24-8.88
			c1.079-0.279,1.269,0.331,1.44,0.96c0.88,0,1.76,0,2.64,0c0.333-0.147,0.036-0.924,0.479-0.96c0.96,0,1.92,0,2.881,0
			c0.36,0.999,0.681,2.039,1.439,2.64c0,0.08,0,0.16,0,0.24c0.688,1.071,0.967,2.553,1.92,3.359c1.249,2.352,2.788,4.413,3.84,6.96
			c0.857,1.544,1.447,3.354,2.641,4.561c0,0.08,0,0.16,0,0.24c0.064,0.553,0.344,0.892,0.709,1.144
			c0.003,0.019,0.008,0.036,0.011,0.056c0.026,0,0.045,0.007,0.071,0.007c0.721,0.453,1.749,0.6,2.329,1.193c0,0.08,0,0.16,0,0.24
			c0.771,0.828,1.246,1.953,1.92,2.88c0,0.08,0,0.16,0,0.24c0.131,1.229,0.602,2.118,1.2,2.88
			C419.357,751.927,412.816,754.532,412.293,750.022z M386.853,746.183c0,0.08,0,0.16,0,0.24c1.092,1.468,2.039,3.081,3.36,4.319
			c0,0.08,0,0.16,0,0.24c1.537,2.468,4.372,5.714,6.96,8.4c2.163,2.245,6.086,5.22,7.199,7.92c0.241,0.319,0.396,0.725,0.721,0.96
			c0.026,0.11,0.098,0.175,0.157,0.252c-0.817,0.027-1.706,0.114-2.635,0.228c-3.334,0-6.669,0-10.003,0
			c-1.034-0.806-1.365-2.313-2.399-3.119c-1.643-2.758-3.807-4.994-5.521-7.681c-7.977-10.343-18.064-18.575-27.84-27.12
			c-0.08,0-0.16,0-0.24,0c-0.947-1.292-2.433-2.047-3.6-3.12c-0.928-0.032-0.947-0.973-1.681-1.199
			c-0.909-1.493-3.576-2.501-3.84-3.841c5.589-0.815,13.059-1.015,18.96-0.72c0,0.08,0,0.16,0,0.24
			c0.848,0.993,1.528,2.152,2.641,2.88c0,0.08,0,0.16,0,0.24c3.811,4.109,7.448,8.391,11.279,12.479
			c0.887,1.354,2.212,2.27,2.881,3.841C384.488,743.106,385.443,744.871,386.853,746.183z M245.373,768.965
			c-0.041,0.086-0.07,0.17-0.12,0.258C245.302,769.135,245.332,769.051,245.373,768.965L245.373,768.965
			C245.373,768.965,245.373,768.964,245.373,768.965c0.076-0.162,0.137-0.319,0.181-0.473c0.001-0.003,0.001-0.006,0.002-0.008
			c0.043-0.153,0.075-0.304,0.089-0.45c0.635-2.426-2.284-3.612-4.232-5.052c-0.08,0-0.16,0-0.24,0
			c-6.958-4.883-13.263-10.417-18.72-16.8c0-0.08,0-0.16,0-0.24c-5.475-6.525-10.704-13.296-16.32-19.68
			c-0.149-0.65-1.003-0.598-0.96-1.44c-3.45-4.31-6.861-8.658-10.08-13.199c0.951,0.743,2.385,0.078,3.6-0.24c0.08,0,0.16,0,0.24,0
			c0.729-0.15,2.067,0.307,2.4-0.24c2.656-0.19,5.437-0.31,8.64-0.48c0.336,0.398,2.304,0.398,2.64,0c0.32,0,0.64,0,0.96,0
			c-0.391,0.773-0.115,1.301,0,2.16c2.959,4.321,5.823,8.737,9.36,12.48c0,0.08,0,0.16,0,0.24c0.877,1.043,1.541,2.299,2.64,3.119
			c1.383,1.977,2.981,3.739,4.08,6c0.954,1.286,1.65,2.83,2.88,3.841c0,0.079,0,0.16,0,0.239c3.015,3.945,5.663,8.258,9.12,11.761
			c0,0.079,0,0.16,0,0.239c5.37,6.311,10.855,12.505,17.28,17.761c0.388-0.068,0.414,0.227,0.72,0.239
			c-3.317,0.62-9.436,0.038-13.92,0.24C245.686,769.019,245.5,768.976,245.373,768.965z M181.145,616.832
			c-0.004-0.622,0.002-1.261,0.027-1.93C181.147,615.572,181.141,616.211,181.145,616.832z M181.19,618.645
			c-0.016-0.389-0.025-0.79-0.033-1.196C181.165,617.854,181.174,618.256,181.19,618.645z M182.367,648.858
			c0.206,0.229,0.399,0.472,0.567,0.74C182.766,649.331,182.573,649.088,182.367,648.858z M181.194,645.274
			c0.011,0.251,0.027,0.5,0.043,0.749C181.221,645.775,181.206,645.525,181.194,645.274z M179.972,758.423
			c-0.371-1.149-0.626-2.414-1.2-3.36c-0.213-0.586-0.559-1.04-0.48-1.92c-0.17-1.109-0.484-2.075-0.96-2.88
			c-0.187-0.214-0.258-0.542-0.24-0.96c0.731,0.148,0.469,1.291,1.2,1.439c0,0.08,0,0.16,0,0.24c0.527,0.913,0.899,1.98,1.68,2.641
			c0,0.079,0,0.16,0,0.239c2.077,3.522,4.189,7.011,6.72,10.08c0.258,0.622,0.868,0.893,0.96,1.681
			c0.583,1.176,1.707,1.813,2.16,3.119c-1.44,0-2.88,0-4.32,0c-1.617-2.623-3.363-5.116-4.8-7.92
			C180.423,760.051,180.035,759.399,179.972,758.423z M186.932,760.343c-0.544-0.896-1.216-1.664-1.68-2.64
			c-0.271-4.139-3.12-7.754-4.56-11.521c0-0.08,0-0.16,0-0.24c-0.604-2.275-1.265-4.495-2.16-6.479
			c-0.214-0.587-0.56-1.041-0.48-1.92c-0.073-1.048-0.25-1.991-0.72-2.641c0-0.16,0-0.319,0-0.479c0-0.24,0-0.48,0-0.721
			c1.918,1.522,2.417,4.463,4.08,6.24c0,0.08,0,0.16,0,0.24c1.399,2.601,2.676,5.323,4.56,7.44c0.003,0.477,0.655,0.305,0.48,0.96
			c1.084,1.396,1.797,3.162,3.12,4.319c0.361,0.68,1.058,1.022,1.2,1.92c0.742,1.019,1.39,2.131,2.4,2.881
			c0.635,1.045,1.682,1.679,2.16,2.88c2.026,2.693,4.856,5.862,6.48,8.159c-2.72,0-5.44,0-8.16,0
			c-1.951-1.409-3.146-3.574-4.8-5.279C188.158,762.477,187.418,761.537,186.932,760.343z M205.412,767.782
			c-5.84-7.6-12.029-14.851-17.52-22.8c-1.491-2.108-2.633-4.566-3.36-7.439c-0.602-3.398-1.92-6.08-2.88-9.12
			c-0.717-2.563-1.473-5.087-1.92-7.92c-0.319-1.048-0.188-2.001,0.24-2.88c2.288,3.312,4.188,7.012,6.72,10.079
			c0.831,1.41,1.784,2.697,2.4,4.32c0.633,0.967,1.039,2.161,1.92,2.88c0,0.08,0,0.16,0,0.24c0.743,1.177,1.208,2.631,2.16,3.6
			c0.565,0.876,1.131,1.75,1.44,2.881c5.521,10.798,14.75,19.17,22.32,27.119c-3.291-0.427-9.649,0.881-11.52-0.72
			C205.412,767.942,205.412,767.862,205.412,767.782z M214.292,760.583c-3.157-3.158-5.93-5.851-8.4-8.641c0-0.08,0-0.16,0-0.239
			c-4.037-4.363-6.895-9.906-10.08-15.12c-0.682-1.318-1.633-2.367-2.16-3.841c-1.043-1.596-1.927-3.354-3.12-4.8
			c0-0.08,0-0.16,0-0.24c-1.014-1.786-1.92-3.68-3.36-5.04c-1.163-1.797-2.566-3.354-3.36-5.52c-0.729-1.432-1.411-2.909-2.64-3.84
			c0.077-0.563,0.379-0.901,0.24-1.68c0.861,0.498,2.595,0.124,3.84,0.239c1.292-0.171,3.067-0.41,4.56,0c0.4,0,0.8,0,1.2,0
			c1.909,2.091,3.175,4.825,5.28,6.721c0,0.079,0,0.159,0,0.239c2.601,3.32,4.976,6.865,7.92,9.84
			c0.149,0.651,1.003,0.598,0.96,1.44c2.716,3.363,5.205,6.955,8.16,10.08c0,0.08,0,0.16,0,0.24c3.233,3.967,6.187,8.213,9.84,11.76
			c0,0.08,0,0.16,0,0.24c3.285,3.995,7.034,7.526,11.28,10.56c0.897,0.143,1.241,0.84,1.92,1.2c1.495,1.705,3.662,2.738,5.28,4.32
			c0.392-0.175,1.044,0.477,0.48,0.479c-4.982,0.016-11.984-0.011-17.76,0C220.546,768.099,217.079,763.37,214.292,760.583z
			 M183.177,650.066c0.093,0.179,0.193,0.351,0.273,0.543C183.37,650.417,183.27,650.245,183.177,650.066z M183.647,651.174
			c0.065,0.19,0.134,0.375,0.189,0.574C183.782,651.549,183.712,651.364,183.647,651.174z M183.985,652.387
			c0.044,0.201,0.092,0.4,0.129,0.609C184.077,652.787,184.03,652.588,183.985,652.387z M184.219,653.697
			c0.029,0.211,0.059,0.421,0.083,0.638C184.278,654.118,184.248,653.908,184.219,653.697z M184.369,655.09
			c0.017,0.218,0.035,0.435,0.047,0.656C184.403,655.524,184.386,655.308,184.369,655.09z M184.455,656.556
			c0.009,0.217,0.017,0.434,0.023,0.652C184.472,656.989,184.463,656.772,184.455,656.556z M184.498,658.101
			c0.003,0.197,0.006,0.395,0.008,0.593C184.503,658.495,184.5,658.298,184.498,658.101z M184.519,660.396
			c0,0.104,0.001,0.208,0.002,0.312C184.52,660.604,184.519,660.5,184.519,660.396z M184.534,661.789
			c0.002,0.197,0.006,0.395,0.01,0.591C184.54,662.184,184.536,661.986,184.534,661.789z M184.572,663.276
			c0.008,0.207,0.015,0.415,0.025,0.619C184.586,663.691,184.58,663.483,184.572,663.276z M184.653,664.744
			c0.015,0.197,0.027,0.397,0.046,0.592C184.681,665.142,184.668,664.941,184.653,664.744z M184.8,666.167
			c0.023,0.179,0.042,0.361,0.07,0.535C184.842,666.528,184.823,666.346,184.8,666.167z M185.04,667.552
			c0.031,0.141,0.054,0.288,0.088,0.425C185.094,667.84,185.07,667.692,185.04,667.552z M241.751,763.273
			c0.034,0.028,0.069,0.057,0.104,0.084C241.82,763.33,241.785,763.302,241.751,763.273z M242.14,763.583
			c0.024,0.019,0.048,0.037,0.073,0.056C242.188,763.62,242.164,763.602,242.14,763.583z M259.412,763.942c-0.08,0-0.16,0-0.24,0
			c-5.378-5.342-10.578-10.862-15.6-16.56c-0.839-1.241-2.021-2.14-2.64-3.601c-3.397-4.362-6.448-9.071-9.84-13.439
			c-0.361-0.68-1.058-1.022-1.2-1.92c-1.511-2.089-2.964-4.236-4.8-6c-0.598-0.923-1.567-1.473-1.92-2.641
			c-2.573-3.106-4.48-6.88-7.44-9.6c1-0.359,1.678-1.042,3.12-0.96c1.76,0,3.52,0,5.28,0c2.154,0.006,3.191,1.128,5.76,0.72
			c0.321,0.879,0.916,1.484,1.2,2.4c3.326,4.594,6.697,9.143,10.56,13.2c0,0.079,0,0.16,0,0.239c2.506,2.934,4.831,6.05,7.68,8.641
			c0,0.08,0,0.16,0,0.239c4.031,4.53,8.071,9.05,12.48,13.2c0,0.08,0,0.16,0,0.24c5.62,6.14,11.181,12.339,17.28,18
			c0,0.08,0,0.16,0,0.24c0.346,0.834,2.617,2.1,1.68,2.64c-5.28,0-10.56,0-15.84,0C263.531,766.863,261.133,765.742,259.412,763.942
			z M238.772,709.463c0,0.72,0,1.439,0,2.16c0,0.159,0,0.319,0,0.479c-0.723,1.29,0.158,3.037,0,4.56
			c-1.797-1.759-3.143-3.972-4.783-5.888c0,0,0,0,0-0.001c-0.124-0.236-0.229-0.466-0.329-0.695c0-0.001-0.001-0.002-0.001-0.003
			c0.973-0.444,3.547,0.901,4.153-0.373c0.08,0,0.16,0,0.24,0C238.44,709.771,238.465,709.476,238.772,709.463z M238.231,716.207
			c-0.092-0.087-0.184-0.179-0.276-0.269C238.048,716.028,238.139,716.121,238.231,716.207z M237.462,715.447
			c-0.115-0.119-0.229-0.241-0.342-0.364C237.234,715.205,237.347,715.328,237.462,715.447z M236.724,714.646
			c-0.129-0.146-0.254-0.292-0.379-0.44C236.469,714.354,236.596,714.5,236.724,714.646z M236.031,713.822
			c-0.138-0.171-0.273-0.341-0.405-0.514C235.757,713.481,235.894,713.652,236.031,713.822z M235.412,713.019
			c-0.55-0.749-1.038-1.507-1.42-2.239C234.374,711.511,234.864,712.271,235.412,713.019z M342.578,717.853
			c0.08-0.004,0.16-0.023,0.224-0.061C342.738,717.829,342.658,717.849,342.578,717.853z M342.221,717.652
			c0.023,0.14,0.188,0.206,0.352,0.2C342.405,717.859,342.244,717.793,342.221,717.652z M367.412,745.223c0.08,0,0.16,0,0.24,0
			c6.635,6.405,12.701,13.379,18.24,20.88c0.258,0.622,0.867,0.893,0.96,1.68c0.082,0.306,0.396,0.379,0.468,0.692
			c0.002,0.008,0.004,0.016,0.004,0.023c0,0.002,0.001,0.004,0.001,0.006l0,0c0.003,0.057-0.007,0.107-0.028,0.153
			c0.021-0.046,0.031-0.097,0.028-0.153c-3.359,0.558-7.755,0.079-11.513,0.238c-0.67-2.05-2.747-2.692-4.08-4.079
			c-1.14-0.461-1.91-1.29-2.88-1.921c-5.374-5.426-12.178-9.422-17.28-15.119c0-0.08,0-0.16,0-0.24
			c-1.313-1.407-2.311-3.129-3.84-4.32c0-0.08,0-0.16,0-0.24c-5.794-6.766-11.642-13.479-17.52-20.16
			c1.045-0.154,2.713,0.313,3.359-0.239c2.549,0.045,6.146-0.063,7.92,0C349.436,730.719,359.181,737.214,367.412,745.223z
			 M387.1,768.047c0.027,0.033,0.052,0.065,0.074,0.097C387.152,768.112,387.126,768.08,387.1,768.047z M387.238,768.251
			c0.046,0.081,0.073,0.155,0.082,0.222C387.312,768.406,387.281,768.331,387.238,768.251z M328.523,768.932
			c0.014-0.017,0.025-0.034,0.029-0.057C328.549,768.897,328.537,768.915,328.523,768.932z M268.292,723.623
			c1.702-0.24,2.774,0.311,4.08,0.239c0.411,0.55,1.832,0.088,2.64,0.24c1.261-0.244,2.39-0.197,3.6,0
			c0.568,0.552,2.154,0.086,3.12,0.24c10.469,15.021,27.525,26.063,41.28,39.12c0.601,0.57,1.327,1.201,2.048,1.845
			c-8.702-8.473-18.835-16.195-27.92-24.46c9.092,8.266,19.225,15.992,27.928,24.467c-0.003-0.002-0.005-0.005-0.008-0.007
			c0.438,0.425,0.882,0.846,1.312,1.275c0.04,0.04,0.113,0.104,0.203,0.183c0.013,0.012,0.025,0.022,0.039,0.034
			c0.016,0.014,0.033,0.029,0.05,0.043c0.084,0.072,0.172,0.147,0.275,0.237l-0.001-0.001c0.054,0.047,0.109,0.095,0.167,0.145
			c-0.057-0.05-0.112-0.098-0.166-0.144c0.672,0.698,1.185,1.356,1.353,1.902c-0.005-0.017-0.121,0.001-0.19,0.005
			c-0.019-0.003-0.029-0.001-0.049-0.005c-5.458-0.012-12.136,0.017-16.801,0C296.95,753.845,282.143,739.212,268.292,723.623z
			 M288.763,732.64c-0.591-0.634-1.16-1.279-1.73-1.924C287.603,731.36,288.172,732.006,288.763,732.64z M294.146,738.063
			c-0.714-0.68-1.413-1.368-2.108-2.058C292.733,736.695,293.433,737.384,294.146,738.063z M291.392,735.369
			c-0.64-0.644-1.262-1.297-1.883-1.95C290.13,734.072,290.752,734.726,291.392,735.369z M281.732,724.103
			c0.702,0.986,1.433,1.958,2.188,2.916C283.165,726.061,282.434,725.089,281.732,724.103z M284.576,727.814
			c0.556,0.686,1.111,1.372,1.692,2.046C285.688,729.187,285.132,728.5,284.576,727.814z M294.603,738.491
			c0.801,0.757,1.607,1.51,2.428,2.256C296.21,740.001,295.403,739.248,294.603,738.491z M327.129,767.244
			c0.158,0.138,0.322,0.285,0.485,0.436C327.452,767.529,327.286,767.382,327.129,767.244z M327.688,767.749
			c0.045,0.041,0.087,0.082,0.13,0.124C327.776,767.831,327.733,767.79,327.688,767.749z M327.889,767.94
			c0.043,0.043,0.086,0.085,0.127,0.127C327.975,768.025,327.932,767.983,327.889,767.94z M328.051,768.105
			c0.083,0.086,0.158,0.168,0.225,0.248C328.209,768.274,328.134,768.191,328.051,768.105z M328.403,768.992
			c-0.065,0.013-0.155,0.013-0.272-0.002C328.248,769.005,328.338,769.005,328.403,768.992z M328.505,768.95
			c-0.018,0.015-0.039,0.026-0.066,0.035C328.467,768.978,328.487,768.965,328.505,768.95z M328.315,768.4
			c0.031,0.039,0.059,0.075,0.085,0.111C328.374,768.476,328.347,768.439,328.315,768.4z M328.433,768.559
			c0.024,0.036,0.046,0.071,0.063,0.104C328.479,768.63,328.457,768.595,328.433,768.559z M328.507,768.686
			c0.034,0.069,0.05,0.129,0.046,0.178C328.558,768.814,328.541,768.754,328.507,768.686z M287.012,724.822
			c1.176-0.056,1.817,0.423,3.121,0.24c0.079,0,0.159,0,0.239,0c3.6,0.642,8.025,0.454,12.24,0.48c0.08,0,0.16,0,0.24,0
			c1.491-0.891,1.752,0.771,2.64,1.199c1.61,0.631,2.636,1.845,4.08,2.641c0,0.08,0,0.16,0,0.24c0.963,0.637,1.917,1.282,2.88,1.92
			c1.634,0.687,2.725,1.916,4.08,2.88c0.924,0.833,1.835,1.678,2.75,2.52c-0.004-0.003-0.007-0.007-0.011-0.01
			c1.743,1.603,3.474,3.219,5.188,4.851c-0.001,0-0.001-0.001-0.002-0.002c5.461,5.199,10.788,10.532,16.046,15.934
			c-5.258-5.4-10.583-10.733-16.044-15.932c5.974,5.931,11.915,12.776,18.232,18.56c1.17,1.071,2.274,2.089,3.362,3.117
			c-1.666-1.736-3.337-3.468-5.014-5.193c1.678,1.727,3.35,3.458,5.017,5.196c-0.001-0.001-0.002-0.002-0.003-0.003
			c1.479,1.543,2.957,3.088,4.431,4.636c0.097,0.101,0.193,0.202,0.29,0.304c0.346,1.289-5.324-0.501-6.403,0.583
			c-3.6,0-7.199,0-10.8,0C319.718,752.757,299.737,741.378,287.012,724.822z M309.857,725.044c1.994-0.894,4.381-1.095,6.915-1.421
			c2.391-0.308,5.455-1.386,7.439-0.961c1.637,0.351,4.058,3.744,5.28,5.04c1.965,2.085,3.307,4.041,5.04,5.521
			c0,0.08,0,0.16,0,0.24c0.896,0.943,1.53,2.149,2.641,2.88c0,0.08,0,0.16,0,0.24c1.735,1.944,3.333,4.026,5.279,5.76
			c0,0.08,0,0.16,0,0.24c6.963,9.757,16.639,16.801,25.681,24.479c0.08,0,0.159,0,0.239,0c0.112,0.779,1.827,1.2,1.44,1.68
			c-4.32,0-8.64,0-12.96,0c-11.228-11.813-23.051-23.029-34.561-34.56c-1.563-0.838-2.662-2.138-4.08-3.12
			c-0.942-1.217-2.374-1.945-3.6-2.88c-0.788-0.093-1.059-0.702-1.68-0.96c-0.773-0.93-1.851-1.554-3.004-2.103
			c0.457,0.544,1.106,0.896,1.746,1.258c-0.642-0.36-1.289-0.714-1.747-1.258h0.001
			C309.906,725.094,309.879,725.072,309.857,725.044L309.857,725.044c-0.108-0.14-0.205-0.292-0.285-0.461
			C309.652,724.752,309.749,724.904,309.857,725.044z M312.261,726.728c-0.054-0.034-0.105-0.07-0.161-0.104
			C312.155,726.657,312.207,726.693,312.261,726.728z M284.132,746.423c0,0.08,0,0.16,0,0.239
			c4.712,5.207,10.241,11.593,16.08,17.041c1.597,1.489,4.996,3.819,4.801,5.279c-5.841,0-11.681,0-17.521,0
			c-14.487-13.513-27.587-28.412-41.04-42.96c0-0.08,0-0.16,0-0.24c-0.794-1.688-3.403-2.834-3.6-4.319
			c2.439-0.279,3.604,0.716,6,0.479c0.08,0,0.16,0,0.24,0c4.102,1.633,8.481-0.21,12.24,1.2c1.43,0.536,4.03,3.791,5.458,5.42
			c-0.097-0.112-0.197-0.23-0.3-0.354c0.213,0.256,0.416,0.491,0.603,0.693C272.637,734.991,278.381,741.243,284.132,746.423z
			 M263.459,724.581c0.059,0.063,0.118,0.127,0.177,0.191C263.578,724.708,263.519,724.644,263.459,724.581z M263.837,724.994
			c0.059,0.066,0.118,0.133,0.177,0.2C263.955,725.127,263.896,725.061,263.837,724.994z M264.218,725.43
			c0.057,0.066,0.114,0.134,0.171,0.201C264.332,725.563,264.275,725.496,264.218,725.43z M264.598,725.881
			c0.055,0.066,0.109,0.132,0.164,0.198C264.708,726.013,264.653,725.947,264.598,725.881z M264.977,726.341
			c0.052,0.063,0.104,0.127,0.155,0.19C265.08,726.468,265.028,726.404,264.977,726.341z M265.382,726.843
			c0.039,0.047,0.077,0.094,0.114,0.142C265.458,726.938,265.42,726.89,265.382,726.843z M265.823,727.391
			c0.055,0.068,0.108,0.134,0.162,0.2C265.931,727.524,265.877,727.459,265.823,727.391z M266.168,727.818
			c0.064,0.079,0.126,0.154,0.189,0.23C266.295,727.973,266.233,727.897,266.168,727.818z M263.26,724.373
			c-0.059-0.061-0.117-0.121-0.176-0.179C263.143,724.252,263.202,724.313,263.26,724.373z M262.887,724.004
			c-0.057-0.055-0.115-0.11-0.172-0.162C262.772,723.894,262.83,723.949,262.887,724.004z M262.516,723.668
			c-0.054-0.046-0.109-0.095-0.163-0.138C262.407,723.574,262.462,723.622,262.516,723.668z M262.149,723.375
			c-0.05-0.037-0.1-0.076-0.149-0.11C262.049,723.299,262.1,723.338,262.149,723.375z M261.779,723.127
			c-0.04-0.024-0.081-0.052-0.12-0.073C261.699,723.075,261.739,723.103,261.779,723.127z M383.314,769.006
			c-0.118-0.006-0.229-0.01-0.338-0.014C383.085,768.996,383.197,769,383.314,769.006z M382.882,768.988
			c-0.124-0.003-0.242-0.006-0.35-0.006C382.64,768.982,382.758,768.985,382.882,768.988z M387.295,768.661
			c-0.281,0.586-2.441,0.417-3.969,0.345C384.852,769.077,387.016,769.248,387.295,768.661z M392.853,768.982
			c0.295,0.096,0.608,0.165,0.93,0.225C393.461,769.147,393.147,769.078,392.853,768.982z M394.022,769.249
			c0.32,0.05,0.647,0.09,0.988,0.112C394.67,769.339,394.343,769.299,394.022,769.249z M395.096,769.364
			c0.321,0.019,0.652,0.023,0.988,0.021C395.748,769.388,395.417,769.383,395.096,769.364z M396.376,769.383
			c0.351-0.008,0.704-0.022,1.063-0.047C397.08,769.36,396.727,769.375,396.376,769.383z M397.657,769.319
			c0.307-0.023,0.614-0.051,0.924-0.082C398.271,769.269,397.964,769.296,397.657,769.319z M398.96,769.198
			c0.335-0.036,0.672-0.075,1.008-0.117C399.632,769.123,399.296,769.162,398.96,769.198z M400.803,768.975
			c0.008-0.001,0.016-0.002,0.023-0.003C400.818,768.973,400.811,768.974,400.803,768.975z M411.596,721.168
			c0.02,0.02,0.036,0.042,0.055,0.063c0.064,0.07,0.126,0.143,0.175,0.229l0,0c0.07,0.125,0.128,0.264,0.166,0.423
			c-0.039-0.158-0.096-0.298-0.166-0.423c-0.044,0.441-0.147,0.941,0.228,0.963c0.187,0.213,0.258,0.542,0.24,0.96
			c1.6-0.632,3.437,0.121,3.359,1.92c0.059,0.981-0.233,2.313,0.48,2.64c0.225,2.487,0.911,7.455-0.721,8.88
			c-0.806,0.568-1.947-0.683-1.92,0.721c-0.841-0.6-1.356-1.523-1.68-2.641c-0.762-1.398-1.358-2.962-2.4-4.08
			c-0.354-0.605-0.848-1.072-0.96-1.92c-0.635-1.045-1.058-2.302-1.92-3.12c-0.563-1.117-1.34-2.02-1.68-3.359
			c-0.102-0.452-0.506-0.604-0.479-1.186c0.138,0.008,0.283,0.009,0.44,0.001c0.004,0,0.006,0,0.01,0c-0.002,0-0.004,0-0.006,0
			c0.006,0,0.012-0.001,0.018-0.001c-0.004,0-0.008,0.001-0.012,0.001c2.346,0.083,4.192-0.334,6.521-0.269
			c-0.876-0.552-2.443-0.351-3.964-0.096c1.525-0.26,3.088-0.456,3.964,0.096l0,0C411.437,721.027,411.52,721.095,411.596,721.168z
			 M410.544,739.788c-0.037-0.052-0.069-0.108-0.116-0.15C410.475,739.68,410.507,739.736,410.544,739.788z M410.837,740.585
			c-0.02-0.123-0.044-0.242-0.075-0.353C410.793,740.343,410.817,740.462,410.837,740.585z M410.735,740.164
			c-0.03-0.093-0.063-0.184-0.105-0.263C410.674,739.98,410.705,740.071,410.735,740.164z M412.858,741.375
			c-0.445-0.43-1.06-0.69-1.922-0.704C411.799,740.684,412.415,740.943,412.858,741.375z M427.606,602.651
			c0.135,0.064,0.284,0.127,0.437,0.188C427.89,602.778,427.741,602.717,427.606,602.651z M428.148,602.884
			c0.147,0.058,0.304,0.112,0.464,0.167C428.452,602.996,428.296,602.941,428.148,602.884z M428.762,603.103
			c0.158,0.052,0.321,0.103,0.487,0.152C429.082,603.205,428.92,603.155,428.762,603.103z M429.428,603.309
			c0.164,0.047,0.331,0.094,0.5,0.14C429.758,603.402,429.592,603.355,429.428,603.309z M430.134,603.502
			c0.165,0.043,0.329,0.086,0.496,0.127C430.463,603.588,430.299,603.545,430.134,603.502z M430.874,603.688
			c0.153,0.037,0.308,0.074,0.46,0.11C431.181,603.762,431.028,603.725,430.874,603.688z M431.664,603.873
			c0.124,0.028,0.249,0.057,0.37,0.084C431.912,603.93,431.788,603.901,431.664,603.873z M434.607,566.914
			c0.04-0.888,0.137-1.836,0.271-2.813C434.745,565.077,434.647,566.026,434.607,566.914z M434.918,563.791
			c0.06-0.404,0.128-0.813,0.199-1.225C435.047,562.977,434.977,563.388,434.918,563.791z M435.217,561.979
			c0.07-0.381,0.144-0.76,0.221-1.143C435.361,561.217,435.286,561.599,435.217,561.979z M435.581,560.133
			c0.074-0.354,0.148-0.701,0.228-1.051C435.73,559.431,435.654,559.781,435.581,560.133z M435.993,558.273
			c0.071-0.309,0.142-0.612,0.215-0.914C436.136,557.659,436.064,557.967,435.993,558.273z M436.447,556.393
			c0.057-0.229,0.113-0.461,0.17-0.683C436.561,555.931,436.504,556.164,436.447,556.393z M421.412,390.022
			c2.549,1.372,5.612,2.228,8.641,3.12c0.08,0,0.16,0,0.24,0c1.413,0.907,3.546,1.094,5.279,1.68c0.557-0.077,0.922,0.039,1.2,0.24
			c4.078,2.242,8.06,4.581,9.84,9.12c-0.07-1.885-3.844-5.913-5.279-6.96c-0.491-0.358-3.606-1.334-1.2-1.2
			c0.554,0.566,2.043,0.197,2.64,0.72c2.053,0.668,4.023,1.417,6.24,1.92c0.716,0.484,1.185,1.216,1.92,1.68
			c0.784,1.855,1.88,3.4,2.16,5.76c-0.077,1.117,0.479,1.602,0.479,2.64c-0.319,0-0.64,0-0.96,0c-1.343-0.498-2.394-1.287-4.08-1.44
			c-1.154-0.445-1.904-1.295-1.92-2.88c-0.076,1.153,0.388,2.314-0.96,1.68c-0.388,0.067-0.413-0.227-0.72-0.24
			c0.546-4.098-3.073-6.022-5.521-7.92c-0.079,0-0.159,0-0.239,0c-1.228-1.092-2.95-1.689-4.8-2.16
			c-0.864,0.064-1.157-0.443-1.921-0.48c-0.83-0.456-2.656-1.176-3.359-0.96c-0.08,0-0.16,0-0.24,0
			c-3.193-0.966-6.571-1.749-10.08-2.4c-0.08,0-0.16,0-0.24,0c-3.244-0.91-6.031-1.478-9.6-2.4
			c-4.291-0.844-7.829-1.645-11.521-2.64c-0.631-0.568-1.609-0.79-2.64-0.96c-0.08,0-0.16,0-0.24,0
			c-2.325-1.195-3.894-3.146-6.96-3.6c-0.89,0.17-1.029-0.41-1.92-0.24c-1.415-0.055-1.524,1.195-2.16,1.92
			c-0.901-0.858-2.414-1.105-3.359-1.92c1.629-1.091,2.218-3.222,5.279-2.88c0.08,0,0.16,0,0.24,0c1.344,0.417,2.644,0.876,4.08,1.2
			c0.502-0.102,0.636,0.165,0.96,0.24c0.893,0.547,1.958,0.921,3.12,1.2c0.08,0,0.16,0,0.24,0c3.221,1.419,7.088,2.192,11.04,2.88
			c0.16,0,0.319,0,0.479,0c1.859,0.861,4.306,1.134,6.24,1.92c0.08,0,0.16,0,0.24,0c0.592,0.528,1.623,0.617,2.399,0.96
			C417.146,388.049,419.042,389.272,421.412,390.022z M434.853,402.262c-2.497-0.942-5.41-1.47-8.4-1.92
			c-1.47-0.21-2.757-0.602-4.319-0.72c-0.744,0.023-1.331-0.109-1.681-0.48c-0.793-0.49-3.566-1.071-5.279-1.44
			c-0.971-0.31-1.904-0.656-3.12-0.72c-5.414-1.706-11.054-3.187-16.32-5.04c-1.639-0.201-2.557-1.124-3.6-1.92
			c-0.241-0.479-1.062-0.379-0.96-1.2c1.404,0.676,3.227,0.933,4.56,1.68c-0.199,0.216,2.351,0.993,2.64,0.72
			c0.341-0.021,0.68-0.04,0.721,0.24c4.578,1.181,9.061,2.459,13.92,3.36c0.341-0.021,0.68-0.04,0.72,0.24
			c2.047,0.593,4.197,1.083,6.48,1.44c0.372-0.395,1.119-0.103,1.439,0.24c4.705,1.455,10.071,2.249,14.4,4.08
			c0.731-0.012,1.222,0.218,1.439,0.72c0.384,0.665,2.037,1.312,1.681,1.92c-1.022-0.343-2.92-1.073-3.601-0.96
			C435.232,402.522,434.893,402.542,434.853,402.262z M377.253,388.342c0.032-0.847,0.687-1.073,0.72-1.92
			c0.388-0.068,0.413,0.227,0.72,0.24c1,1,2.561,1.439,3.84,2.16c0.08,0,0.16,0,0.24,0c3.771,3.189,7.625,6.295,12.96,7.92
			c0.08,0,0.16,0,0.24,0c3.196,1.204,6.679,2.122,10.32,2.88c0.08,0,0.159,0,0.239,0c2.569,1.032,6.539,1.94,8.88,2.4
			c0.08,0,0.16,0,0.24,0c3.414,1.292,6.582,1.811,10.32,2.4c0.08,0,0.16,0,0.24,0c0.692,0.268,1.488,0.432,2.399,0.48
			c0.557-0.077,0.922,0.039,1.2,0.24c0.861,0.259,1.637,0.604,2.64,0.72c0.679-0.198,0.622,0.338,1.2,0.24
			c0.689,0.511,1.823,0.577,2.641,0.96c0.501-0.102,0.635,0.165,0.96,0.24c2.056,0.984,4.171,1.909,6.479,2.64c0.08,0,0.16,0,0.24,0
			c1.495,0.905,3.289,1.511,5.04,2.16c0.16,0,0.32,0,0.479,0c0,0.08,0,0.16,0,0.24c1.502,0.099,1.859,1.341,1.921,2.88
			c-0.384,3.617-0.545,7.455-0.721,11.28c0.012,1.212-0.094,2.307-0.479,3.12c-0.921,2.588-1.05,4.938-1.44,7.68
			c-0.325,0.074-0.199,0.602-0.24,0.96c-0.413,0.546-0.513,1.407-0.479,2.4c-0.698,0.182,0.218,1.978-0.48,2.16
			c-0.837,1.535-0.607,4.688-1.439,5.52c-0.038-0.282,0.041-0.681-0.24-0.72c-0.036,0.596,0.124,0.996,0.48,1.2
			c-0.596,2.604-1.188,5.213-1.681,7.92c0.032,0.592,0.036,1.156-0.239,1.439c-0.698,1.492-0.333,5.228-1.681,5.28
			c1.136,1.746-0.64,4.169-0.479,6.48c0,0.239,0,0.479,0,0.72c-0.412,0.708-0.509,1.731-0.48,2.88
			c-0.215,0.597-0.694,2.17-0.24,2.64c-0.118,0.842-0.357,1.563-0.239,2.641c-0.376,1.064-0.47,2.41-0.48,3.84c0,0.88,0,1.76,0,2.64
			c-0.242,1.203-0.611,3.542-0.24,4.561c0.192,0.832-0.357,0.922-0.239,1.68c-0.028,1.732-0.397,3.123-0.24,5.04
			c0,0.08,0,0.16,0,0.24c0,1.12,0,2.24,0,3.359c0,1.04,0,2.08,0,3.12c-0.55,0.411-0.088,1.832-0.24,2.641
			c-0.595,0.38-0.098,1.679,0,2.16c-0.545,0.255-0.092,1.508-0.24,2.159c-0.509,1.927-0.245,3.231-0.479,5.521
			c-0.334,2.829-1.013,5.806-1.2,8.64c-0.326,0.074-0.199,0.602-0.24,0.96c-0.328,0.563-0.814,2.212-0.479,2.641
			c-0.26,0.7-0.538,1.383-0.48,2.399c0.021,0.341,0.04,0.68-0.24,0.721c-0.725,1.757-0.764,2.865-1.199,5.04
			c-0.024,1.415-0.859,2.021-0.96,3.359c-0.402,0.559-0.627,1.293-0.721,2.16c-0.543,1.137-1.056,2.305-1.2,3.84
			c-0.738,1.661-1.453,3.347-1.92,5.28c-1.055,1.585-1.333,3.946-2.159,5.76c-0.491,0.47-0.716,1.204-0.721,2.16
			c0.021,0.341,0.04,0.68-0.24,0.72c-2.567,4.473-2.239,11.841-3.6,17.521c-0.21,1.391-1.017,2.184-0.96,3.84
			c-0.374,0.906-0.479,2.08-0.72,3.12c-0.225,1.535-1.267,2.253-1.2,4.08c-0.483,0.717-0.706,1.693-0.72,2.88
			c-0.87,1.689-1.217,3.904-1.44,6.24c-0.7,3.38-1.61,6.549-2.64,9.6c-0.738,0.382-0.361,1.879-1.2,2.16
			c-0.407-1.513-0.188-3.653-0.24-5.52c0.376-1.064,0.47-2.41,0.48-3.841c0.554-0.726,0.084-2.476,0.239-3.6
			c0.553-0.568,0.086-2.154,0.24-3.12c0.558-1.283,0.082-3.598,0.24-5.28c0.558-1.362,0.082-3.758,0.24-5.52
			c1.063-2.268-0.846-3.632-2.88-3.601c0.229-0.49,0.49-0.949,0.72-1.439c-0.583,0.377-0.899,1.021-0.96,1.92
			c-0.977,0.784-1.094,2.426-1.68,3.6c0.224-0.146,0.885-0.559,0.72,0.24c-0.557,1.124-0.083,3.277-0.24,4.8
			c-0.557,1.204-0.082,3.438-0.24,5.04c-0.52,1.48-0.059,3.941-0.479,5.521c0.172,1.372-0.256,2.144-0.24,3.359
			c-0.466,1.534-0.509,3.492-0.479,5.521c-0.105,0.825,0.095,1.345,0.239,1.92c-0.405,0.973,0.239,2.955,0.48,4.08
			c-0.074,0.874,0.038,1.562,0.24,2.16c-0.491,1.535,0.47,3.687,0.479,5.52c-1.707-0.933-1.957-3.322-2.88-5.04
			c0.393,2.488,1.607,4.152,2.88,5.761c-0.004,1.844,0.515,3.165,0.72,4.8c0,0.08,0,0.16,0,0.239
			c0.659,3.822,1.607,7.354,2.641,10.801c0,0.24,0,0.479,0,0.72c0.323,1.837,0.504,3.816,0.96,5.521
			c-0.141,1.26,0.423,1.816,0.24,3.119c-0.188,1.627,0.327,2.553,0.239,4.08c0,0.32,0,0.641,0,0.96
			c-0.007,1.291-0.12,4.758,0.24,6.48c0,0.32,0,0.64,0,0.96c-0.559,1.922-0.081,4.879-0.24,7.2c0,0.399,0,0.8,0,1.2
			c-0.559,2.161-0.081,5.358-0.239,7.92c0,0.56,0,1.12,0,1.68c-1.079,2.922-0.19,7.81-0.48,11.52
			c-0.449,6.042-1.806,9.464-1.92,15.841c0.011,3.931-0.094,7.746-0.48,11.279c-1.131-0.002-2.438,0.8-3.359,0.24
			c-5.051,0.709-11.752-0.231-16.801,0.48c-17.304,0.428-33.95,0.104-50.399,1.2c-0.013,0.071-0.061,0.124-0.123,0.163
			c0.063-0.038,0.11-0.092,0.123-0.163c-0.232,0.006-0.478,0.021-0.712,0.028c-0.001-0.01-0.008-0.018-0.008-0.028
			c-0.244,0.018-0.487,0.04-0.732,0.058c0.002,0,0.004,0,0.005,0c-0.073,0.005-0.146,0.012-0.221,0.018
			c0.072-0.006,0.144-0.013,0.216-0.018c-11.967,0.423-25.674,2.05-37.908,3.302c-13.141,1.346-26.045-1.339-39.12-1.92
			c-0.16,0-0.32,0-0.48,0c-0.772-0.428-2.177-0.223-3.12-0.479c-0.08,0-0.16,0-0.24,0c-4.353-0.172-10.402-0.606-15.36-0.96
			c-0.72-1.521-0.541-3.94-0.72-6c0-0.16,0-0.32,0-0.48c0.359-5.241,0.795-10.405,0.96-15.84c0-0.08,0-0.16,0-0.24
			c0.721-3.198,0.531-7.309,0.72-11.04c0.002-0.638-0.091-1.371,0.24-1.68c0.88-12.672,0.9-26.248,0.72-39.36c0-1.28,0-2.561,0-3.84
			c-0.081-3.465,0.288-8.302-0.48-10.32c0-0.4,0-0.8,0-1.2c-0.715-4.132-0.357-9.336-0.72-13.2c2.684-2.436,6.35-3.891,8.88-6.479
			c0.08,0,0.16,0,0.24,0c3.518-2.322,6.913-4.768,10.08-7.44c1.314-0.766,2.106-2.054,3.6-2.64c0.538-0.47,1.087-0.926,1.642-1.375
			c-0.554,0.449-1.104,0.905-1.642,1.375c5.13-3.647,10.306-7.248,14.993-11.338c-0.004,0.003-0.008,0.007-0.012,0.01
			c0.005-0.005,0.011-0.01,0.017-0.014c-0.001,0.001-0.003,0.003-0.005,0.004c0.004-0.004,0.009-0.008,0.014-0.012
			c0.64-0.52,1.269-1.05,1.884-1.596c0.011-0.009,0.022-0.019,0.033-0.028c0.605-0.537,1.196-1.089,1.771-1.658
			c0.062-0.061,0.122-0.125,0.183-0.187c0.47-0.471,0.926-0.955,1.371-1.452c0.128-0.143,0.257-0.286,0.383-0.431
			c0.499-0.576,0.986-1.163,1.448-1.779c0.018-0.022,0.031-0.048,0.048-0.071c1.363-1.637,2.636-3.364,3.792-5.208
			c0.7-1.78,2.216-2.744,3.12-4.32c1.034-0.806,2.249-1.431,2.64-2.88c0-0.08,0-0.16,0-0.24c1.938-2.063,3.854-4.146,5.76-6.24
			c0-0.08,0-0.159,0-0.239c2.496-2.064,4.409-4.712,6.48-7.2c0-0.08,0-0.16,0-0.24c2.695-2.664,4.616-6.104,6.96-9.12
			c-0.175-0.654,0.477-0.483,0.48-0.96c1.87-2.05,3.249-4.591,4.8-6.96c0.461-1.539,1.521-2.479,2.16-3.84
			c2.36-3.079,4.412-6.468,6.239-10.08c0.569-1.591,1.33-2.99,2.16-4.32c1.869-2.771,3.323-5.956,4.8-9.12
			c-0.067-0.388,0.228-0.413,0.24-0.72c1.039-0.961,1.435-2.565,2.16-3.84c0.443-1.877,1.519-3.121,2.16-4.8
			c1.681-2.239,2.729-5.11,4.08-7.681c1.148-3.251,2.959-5.84,4.319-8.88c2.859-5.061,5.135-10.706,6.961-16.8
			c-0.024-0.744,0.293-1.146,0.479-1.68c0.584-0.536,0.589-1.651,0.96-2.4c0-0.08,0-0.16,0-0.24c1.745-3.712,3.805-8.06,4.08-12.479
			c0-0.16,0-0.32,0-0.48c0.913-3.056,1.515-7.986,1.68-10.319c0-0.08,0-0.16,0-0.24c0.453-1.307,0.393-3.128,0.721-4.561
			c0.178-7.005,1.959-13.119,3.12-20.64C372.883,404.184,374.749,396.34,377.253,388.342z M389.981,723.681
			c-0.001-0.001-0.001-0.002-0.002-0.003C389.979,723.679,389.98,723.68,389.981,723.681z M389.565,723.145
			c-0.036-0.045-0.074-0.088-0.111-0.132C389.491,723.057,389.529,723.1,389.565,723.145z M389.137,722.647
			c-0.049-0.054-0.099-0.104-0.148-0.156C389.038,722.543,389.087,722.594,389.137,722.647z M388.692,722.201
			c-0.055-0.051-0.11-0.101-0.165-0.148C388.582,722.101,388.638,722.15,388.692,722.201z M388.23,721.814
			c-0.057-0.042-0.113-0.086-0.171-0.125C388.117,721.729,388.174,721.772,388.23,721.814z M387.734,721.495
			c-0.051-0.027-0.101-0.06-0.151-0.084C387.634,721.436,387.684,721.468,387.734,721.495z M270.982,596.033
			c0.371-0.294,0.743-0.586,1.118-0.874C271.725,595.447,271.352,595.739,270.982,596.033z M272.783,594.639
			c0.32-0.241,0.641-0.479,0.963-0.717C273.424,594.159,273.102,594.397,272.783,594.639z M247.892,494.422
			c0.063,1.823-0.461,3.059-0.72,4.561c-0.372,0.588-0.358,1.562-0.48,2.399c-0.023,1.857,0.274,3.128-0.24,4.8
			c-0.478,1.323-0.383,2.641-0.24,4.08c-1.065-0.535-0.881-2.318-1.44-3.359c-0.38-1.191-0.275-3.976-0.24-4.8
			c0.555-0.493,0.166-2.073,0-2.641c0.012-1.668,0.212-3.147,0.48-4.56c1.025-3.056,1.68-7.759,2.16-10.561
			c0.106-0.934,0.181-1.898,0.48-2.64c0.217-0.752,0.925-2.263,0.48-2.88c2.022-12.233-0.902-24.056,0-36
			c0.547-0.333,0.089-1.671,0.24-2.4c0-0.24,0-0.479,0-0.72c3.122-3.759,6.525-7.235,9.84-10.8c0.08,0,0.16,0,0.24,0
			c1.085-0.915,2.266-1.735,3.12-2.881c0.815-0.305,0.909-1.33,1.92-1.439c2.774-2.506,6.591-3.97,9.36-6.48
			c0.624-0.096,0.724-0.716,1.44-0.72c1.158-0.922,2.68-1.479,3.6-2.64c0.75-0.291,1.044-1.037,1.92-1.2
			c1.083-0.837,2.218-1.622,3.12-2.64c0.08,0,0.16,0,0.24,0c1.124-0.957,2.38-1.78,3.36-2.88c0.08,0,0.16,0,0.24,0
			c1.217-0.943,2.267-2.053,2.88-3.6c-0.037-0.597,0.377-0.743,0.48-1.2c-0.188-8.839-0.386-18.442-0.721-28.08c0-0.4,0-0.8,0-1.2
			c0.29-1.733-0.289-4.668-0.24-6.96c0.561,0,1.12,0,1.681,0c0.08,0,0.16,0,0.24,0c2.219,0.219,3.519-0.481,5.52-0.48
			c-0.131,4.771,0.206,9.074,0.48,13.44c-0.132,1.972,0.423,3.256,0.239,5.28c-0.513,3.314,0.026,7.653,0.24,11.04
			c-0.266,1.014-0.276,2.284-0.24,3.6c0.206,3.565-2.577,4.143-4.319,5.76c-0.973,0.467-1.499,1.381-2.641,1.68
			c-6.643,4.877-13.751,9.289-19.92,14.641c-0.08,0-0.16,0-0.24,0c-1.248,1.151-2.961,1.838-3.84,3.359c-0.08,0-0.16,0-0.24,0
			c-2.501,2.06-5.084,4.036-7.2,6.48c-0.204-0.044-0.226,0.094-0.24,0.239c-3.05,2.631-5.57,5.79-7.92,9.12c0,0.08,0,0.16,0,0.24
			c-0.493,0.556-1.519,1.831-0.96,2.641c0.063,2.58,0.443,5.419,0.72,8.399c0.014,1.106,0.111,2.129,0.48,2.88c0,0.08,0,0.16,0,0.24
			c0.014,1.106,0.111,2.129,0.48,2.88c0.468,1.452,0.526,3.313,0.48,5.28c0.212,2.811,0.239,5.896,0.24,8.399
			c0.308,0.315,0.308,0.885,0,1.2c-0.542,2.345-0.844,5.706-1.44,8.641c0,0.08,0,0.159,0,0.239c-0.249,1.595-1.166,3.791-0.96,5.04
			c-0.219,0.821-0.36,1.72-0.72,2.4C248.56,491.09,247.987,492.517,247.892,494.422z M303.813,556.582
			c-14.323-13.116-26.427-28.453-37.44-44.88c2.141-0.975,3.24-0.528,5.04-0.24c11.151,14.529,23.832,27.528,36.24,40.8
			C306.16,553.489,305.152,555.202,303.813,556.582z M296.853,563.782c-19.451-16.789-32.891-39.589-47.28-61.44
			c0.345-1.254,0.59-2.609,0.48-4.319c2.165,1.275,3.427,3.452,5.52,4.8c-0.401,4.795,2.906,7.761,6.72,9.12
			c11.606,17.033,24.224,33.056,38.879,47.04C300.005,560.854,298.503,562.394,296.853,563.782z M291.332,570.982
			c-11.942-12.827-23.082-27.534-32.88-43.681c-1.398-2.304-2.83-5.242-4.56-8.159c-2.283-3.85-4.897-6.695-4.56-11.521
			c11.492,16.749,21.894,34.586,35.76,48.96c0.94,1.141,1.98,2.181,3.12,3.12c0.985,0.865,2.282,1.91,3.6,3.12
			c0.735,0.676,3.309,2.481,3.359,3.12C295.272,567.202,291.606,569.65,291.332,570.982z M294.015,574.607
			c-0.052,0.046-0.094,0.099-0.144,0.146C293.921,574.706,293.963,574.653,294.015,574.607z M293.443,575.193
			c-0.034,0.042-0.063,0.088-0.098,0.13C293.38,575.281,293.409,575.235,293.443,575.193z M292.953,575.847
			c-0.003,0.006-0.007,0.012-0.01,0.017C292.946,575.858,292.95,575.853,292.953,575.847z M270.452,506.422
			c-0.825,0.105-1.345-0.095-1.92-0.24c-0.476,0.613,0.249,1.104-0.24,1.44c-3.35,0.479-6.991-0.673-8.4-2.88
			c-3.863-6.05,3.158-15.328,9.36-11.04c2.1,1.452,2.778,4.259,3.12,7.92C271.17,502.75,269.592,504.654,270.452,506.422z
			 M256.292,499.462c-2.631-0.729-2.966-3.754-5.52-4.56c0.478-2.962,1.662-5.219,1.92-8.4c2.309,2.011,2.214,6.426,5.76,7.2
			C257.593,495.483,256.268,496.797,256.292,499.462z M279.092,561.622c-11.657-12.183-22.347-27.414-29.76-44.64
			C258.472,532.643,267.722,548.192,279.092,561.622z M289.412,573.862c-4.168-3.271-7.312-7.568-10.32-12
			C282.519,565.876,286.206,569.629,289.412,573.862z M369.093,389.062c2.264,0.528,2.36,0.748,1.439,4.32
			c-3.295,12.789-6.042,27.557-7.68,39.601c-1.14,8.377-1.844,16.338-4.8,23.04c-2.987,6.771-4.623,13.609-7.681,20.159
			c-6.01,12.88-12.673,25.486-18.72,37.921c-6.215,12.779-13.582,24.625-21.6,35.04c-13.694-12.466-25.53-26.791-37.44-41.04
			c1.248-1.393,2.196-3.085,2.64-5.28c0.565-1.53,0.159-4.155-0.48-5.28c-0.616-7.084-10.774-10.408-15.36-4.8
			c-1.931-3.51-3.972-6.908-5.76-10.561c1.312-5.733,1.506-11.923,0.96-18.239c-0.545-6.304-1.283-12.769-2.88-18.48
			c10.101-14.877,26.159-25.228,41.52-35.76c4.4-3.017,7.91-3.111,8.4-9.84c0.768-10.543-0.938-24.106-0.96-34.32
			c1.827-0.955,3.954-1.164,6.24-1.92c1.909-0.632,3.992-1.852,5.76-2.16c2.498-0.437,5.236-0.162,7.68-0.48
			c6.972-0.909,13.597-2.956,19.681-4.8c0.157,1.683-0.317,3.997,0.239,5.28c0-1.76,0-3.52,0-5.28c1.772-0.788,4.02-1.101,5.28-2.4
			c0.774-0.186,1.418-0.501,1.92-0.96c1.457-0.224,1.958-1.402,3.36-1.68c0.158,1.842-0.317,4.318,0.24,5.76c0,1.36,0,2.72,0,4.08
			c0.184,2.664-0.31,4.65-0.24,7.2c-0.552,0.568-0.086,2.154-0.24,3.12c0,1.04,0,2.08,0,3.12c-0.551,0.489-0.087,1.993-0.24,2.88
			c0.12,1.984-0.169,3.001,0.96,4.08c0.106,0.214,0.211,0.429,0.48,0.48c3.616,2.064,8.171,3.189,12.24,4.8
			C365.721,387.474,367.307,388.368,369.093,389.062z M333.332,348.742c0.646-1.514,1.435-2.886,2.4-4.08
			c1.9-2.979,3.389-6.372,4.8-9.84c-0.039-0.279,0.053-0.427,0.24-0.48c0.45-1.71,1.473-2.847,1.92-4.56
			c0.211-3.407,1.955-5.204,3.12-8.16c-0.001-0.641,0.759-0.521,0.72-1.2c1.045-0.377,1.166-3.204,2.641-1.68c0,1.6,0,3.2,0,4.8
			c-0.399,1.284-0.399,4.235,0,5.52c-0.415,4.094-0.433,10.153-0.48,15.36c-0.702,0.498-1.86,0.54-2.4,1.2
			c-0.773,0.186-1.418,0.502-1.92,0.96c-0.985,0.054-1.571,0.508-2.159,0.96c-0.279-0.039-0.428,0.053-0.48,0.24
			c-0.221,0.419-0.75,0.53-1.44,0.48c-1.465,0.535-2.906,1.093-4.319,1.68c-0.039,0.281-0.438,0.202-0.721,0.24
			c-1.259,0.34-2.612,0.587-3.6,1.2c-0.563,0.237-1.179,0.421-1.92,0.48c0.209-0.351,0.359-0.761,0.96-0.72
			C332.039,350.808,332.727,349.816,333.332,348.742z M343.412,351.382c0.211-0.749,1.132-0.788,1.44-1.44
			c1.204-0.315,2.05-0.99,3.12-1.44c1.383-0.218,1.953-1.247,2.88-1.92c1.787-0.079,0.553,1.592,0,1.92
			c-1.392,0.529-2.441,1.398-3.84,1.92C345.528,350.458,344.938,351.388,343.412,351.382z M331.652,354.502c0.24,0,0.48,0,0.72,0
			c2.231-0.648,4.648-1.112,6.48-2.16c0.578,0.098,0.521-0.438,1.2-0.24c1.279-0.72,2.344-1.656,4.08-1.92
			c-0.329,0.631-1.011,0.91-1.2,1.68c-0.67,0.53-1.302,1.098-2.641,0.96c-5.98,2.02-12.372,3.627-18.96,5.04
			c-0.08,0-0.159,0-0.239,0c-0.943-0.287-1.805,0.734-2.4,0c1.538-0.782,2.541-2.099,3.84-3.12c0.566,0.074,0.446,0.833,1.2,0.72
			c0.16,0,0.32,0,0.479,0C326.505,355.912,329.817,355.309,331.652,354.502z M364.532,219.381c0.08,0,0.16,0,0.24,0
			c1.893-0.132,2.424,1.096,3.36,1.92c0.254,1.106,0.893,1.827,1.439,2.64c1.208,3.433,2.439,6.841,3.601,10.32
			c0.426,1.174,0.446,2.753,0.96,3.84c0.398,2.081,0.706,4.253,0.96,6.48c0.626,0.494-0.17,2.409,0.479,2.88c0,0.4,0,0.8,0,1.2
			c-0.398,0.492-0.398,2.628,0,3.12c0.006,0.565-0.413,0.707-0.24,1.44c0.342,1.31-0.628,2.56,0,3.36
			c0.193,0.833-0.357,0.922-0.239,1.68c0.141,1.341-0.55,1.85-0.48,3.12c-0.323,2.077-1.372,3.427-1.68,5.52
			c-0.834,1.086-1.176,2.665-1.68,4.08c-0.683,2.184-1.229,4.136-1.2,6.48c-0.281,0.039-0.203,0.438-0.24,0.72
			c-0.572,0.628-0.556,1.844-0.96,2.64c-0.542,0.738-1.213,1.347-1.44,2.4c-1.969,1.951-3.848,3.992-7.92,3.84
			c-1.927-0.073-0.927-2.336-0.96-3.84c0-0.08,0-0.16,0-0.24c0-0.8,0-1.6,0-2.4c0-0.08,0-0.16,0-0.24
			c0.021-0.714,0.021-1.407,0.014-2.093c-0.002-0.146-0.004-0.292-0.007-0.438c-0.062-3.337-0.39-6.408-0.486-9.709
			c-0.664-5.656-1.235-11.405-2.16-16.8c-0.331-0.31-0.237-1.042-0.24-1.68c-0.132-5.449,0.515-12.271,1.92-17.52
			c0.004-0.956,0.229-1.69,0.72-2.16C359.922,225.971,361.589,222.038,364.532,219.381z M213.092,187.461
			c5.014-12.31,9.167-21.018,19.2-28.08c2.251-1.584,4.827-2.944,7.44-4.32c2.609-1.374,5.052-3.333,7.44-4.32
			c4.198-1.735,9.205-2.409,13.92-3.36c11.441-2.307,26.935-4.295,37.92,0c1.881,0.736,3.719,2.301,5.76,2.64
			c0.853,0.142,1.756-0.271,2.64-0.24c3.161,0.112,7.792,1.559,11.04,2.88c2.818,1.146,5.237,1.88,7.2,3.84
			c-0.315-0.085-0.377-0.422-0.72-0.48c1.348,1.373,3.02,2.42,4.56,3.6c0.511,0.37,0.894,0.866,1.44,1.2
			c1.646,1.633,3.333,3.227,5.04,4.8c0.985,1.094,1.565,2.594,2.64,3.6c0.617,1.383,1.354,2.647,2.4,3.6
			c0.033,0.447,0.157,0.802,0.479,0.96c0.888,1.673,1.555,3.565,2.641,5.04c-0.036,0.596,0.124,0.996,0.479,1.2
			c0.325,0.956,0.478,2.083,1.2,2.64c0.191,1.168,0.42,2.3,0.96,3.12c0.748,0.612,0.103,2.618,0.96,3.12
			c0.213,0.908,0.18,2.061,0.72,2.64c0.889,0.792,0.334,3.027,1.2,3.84c-0.018,0.898,0.101,1.659,0.48,2.16
			c0.355,0.444,0.459,1.14,0.479,1.92c0.306,0.735,0.341,1.74,0.72,2.4c-0.038,1.079,0.195,1.886,0.721,2.4
			c0.173,0.947,0.297,1.942,0.72,2.64c0.043,1.397,0.664,2.216,0.96,3.36c-0.018,0.897,0.101,1.659,0.48,2.16
			c-0.127,1.007,0.419,1.341,0.479,2.16c-0.025,0.985-0.039,1.959,0.48,2.4c-0.144,2.144,0.6,3.4,2.399,3.6
			c-0.956,1.524-1.217,3.743-2.88,4.56c-0.08,2.56-1.056,4.225-1.44,6.48c-0.628,1.532-1.071,3.248-1.199,5.28
			c0.067,0.388-0.228,0.414-0.24,0.72c-0.281-0.039-0.202-0.438-0.24-0.72c-0.15-0.729,0.308-2.067-0.24-2.4c0-0.4,0-0.8,0-1.2
			c-0.295-3.6-2.717-9.188-0.72-12c0.423-0.217,0.743-0.537,0.96-0.96c0.511-0.414,0.723-1.826,0-2.16
			c-0.016-0.704-0.549-0.892-0.96-1.2c-0.744-0.615-1.065-1.654-1.92-2.16c-3.518,0.785-4.907-1.529-7.92-1.92
			c-1.528-0.952-3.097-1.864-4.8-2.64c-7.166-4.827-12.352-11.012-15.84-19.92c-1.007-2.569-3.17-6.401-2.881-8.88
			c0.202-1.722,1.954-1.855,1.681-3.36c-0.36-1.984-3.328-1.43-4.8-0.96c-2.699,0.861-5.352,2.908-8.16,4.32
			c-8.009,4.026-16.966,7.559-25.44,11.28c-5.78,2.538-11.272,5.639-17.52,6.96c-11.36,2.402-26.479,0.221-36.72-3.12
			c-0.923-1.39-1.532-4.128-4.32-2.88c-1.416,2.922,0.468,6.385,0.72,9.84c0.313,4.294,0.144,9.41-1.2,12.72
			c-0.91,2.241-2.715,4.328-3.84,6.72c-2.165,4.602-3.726,9.232-3.6,16.8c-3.193-7.271-4.645-16.161-5.52-25.68
			c-0.287-3.117-1.079-6.396-0.96-9.36C207.794,199.206,210.919,192.796,213.092,187.461z M226.918,209.24
			c-0.008,0.1-0.015,0.201-0.025,0.3C226.903,209.441,226.91,209.34,226.918,209.24z M226.666,211.215
			c-0.018,0.106-0.038,0.211-0.057,0.316C226.628,211.426,226.648,211.321,226.666,211.215z M226.257,213.143
			c-0.014,0.054-0.03,0.106-0.044,0.16C226.228,213.25,226.244,213.197,226.257,213.143z M225.766,214.816
			c-0.019,0.057-0.035,0.114-0.054,0.17C225.73,214.931,225.747,214.873,225.766,214.816z M225.164,216.483
			c-0.033,0.083-0.066,0.167-0.099,0.25C225.098,216.65,225.131,216.567,225.164,216.483z M224.45,218.176
			c-0.025,0.055-0.05,0.109-0.075,0.164C224.399,218.286,224.425,218.231,224.45,218.176z M217.652,240.262
			c-0.057-0.625-0.082-1.227-0.094-1.819C217.57,239.035,217.596,239.637,217.652,240.262z M217.552,238.06
			c-0.003-0.574,0.01-1.136,0.045-1.683C217.562,236.924,217.549,237.486,217.552,238.06z M217.773,234.665
			c-0.067,0.486-0.12,0.982-0.158,1.49C217.653,235.647,217.706,235.151,217.773,234.665z M221.972,304.342
			c-3.287-2.912-7.623-10.299-3.6-15.36c0.147-1.42-0.571-2.289,0.24-3.12c1.19,2.73,1.405,6.435,2.64,9.12
			c0.264,0.697,0.217,1.704,0.48,2.4C222.281,299.649,221.295,302.09,221.972,304.342z M213.812,290.422
			c-0.244-0.264-0.473-0.542-0.694-0.827C213.338,289.881,213.568,290.158,213.812,290.422z M212.983,289.412
			c-0.181-0.242-0.359-0.487-0.531-0.739C212.624,288.925,212.802,289.169,212.983,289.412z M212.431,288.639
			c-1.115-1.646-2.061-3.459-3.391-4.891C210.37,285.18,211.319,286.991,212.431,288.639z M221.972,306.021
			c-0.064,0.004-0.11,0.025-0.149,0.053C221.861,306.046,221.908,306.025,221.972,306.021z M217.172,280.102
			c-2.774-2.666-4.401-6.479-8.4-7.92c-1.503-3.065-5.772-8.433-8.88-4.8c-0.315-1.901-0.977-3.455-0.998-5.649
			c0-0.001,0-0.003,0.001-0.005c0.052-0.302,0.123-0.594,0.21-0.875c0.007-0.02,0.012-0.041,0.019-0.061
			c0.093-0.285,0.205-0.558,0.34-0.813l0,0c0.061-0.114,0.131-0.22,0.2-0.327c-0.069,0.107-0.14,0.213-0.2,0.327
			c0.981-1.014,2.513-1.478,4.028-1.957c4.868,2.492,7.785,6.935,10.8,11.28c0.134,0.489,0.722,0.471,0.24,0.96
			C215.376,273.577,216.638,276.476,217.172,280.102z M202.646,257.834c-0.084,0.011-0.164,0.028-0.246,0.042
			C202.481,257.862,202.561,257.845,202.646,257.834z M200.486,258.716c-0.082,0.067-0.155,0.142-0.23,0.214
			C200.332,258.858,200.405,258.783,200.486,258.716z M201.885,257.996c-0.085,0.025-0.17,0.05-0.25,0.079
			C201.715,258.046,201.8,258.021,201.885,257.996z M201.181,258.274c-0.063,0.032-0.131,0.058-0.191,0.093
			C201.05,258.332,201.118,258.306,201.181,258.274z M199.813,259.431c0.07-0.095,0.145-0.186,0.222-0.274
			C199.958,259.245,199.883,259.336,199.813,259.431z M198.805,262.481c-0.018,0.248-0.024,0.499-0.021,0.753
			C198.78,262.979,198.788,262.729,198.805,262.481z M198.894,261.736c-0.041,0.24-0.07,0.487-0.088,0.737
			C198.824,262.222,198.853,261.977,198.894,261.736z M199.567,267.118c-0.047-0.121-0.087-0.245-0.131-0.368
			C199.479,266.873,199.52,266.997,199.567,267.118z M199.296,266.35c-0.053-0.168-0.097-0.34-0.143-0.511
			C199.199,266.01,199.244,266.181,199.296,266.35z M199.082,265.57c-0.049-0.205-0.089-0.411-0.126-0.618
			C198.993,265.159,199.033,265.365,199.082,265.57z M198.924,264.787c-0.039-0.231-0.067-0.462-0.09-0.693
			C198.856,264.324,198.887,264.556,198.924,264.787z M198.825,264.007c-0.023-0.248-0.036-0.494-0.04-0.738
			C198.789,263.513,198.802,263.759,198.825,264.007z M205.652,281.302c-0.657,1.16-0.444,3.191-0.944,4.509
			c0.052,0.051,0.11,0.097,0.17,0.14c-0.063-0.041-0.119-0.088-0.17-0.14l0,0c-0.01-0.01-0.02-0.021-0.029-0.032l0,0
			c-0.512-0.544-0.536-1.576-0.638-2.529c0.105,0.951,0.127,1.985,0.638,2.529c-0.113-1.135-0.344-2.152-0.707-3.037
			c0.028,0.162,0.049,0.33,0.068,0.5c-0.019-0.171-0.04-0.339-0.068-0.5c-0.621-0.83-0.532-1.593-0.72-2.4
			c0.159-2.161-1.293-4.786-1.68-7.2c1.839,1.841,3.422,3.938,4.32,6.72C205.616,280.146,205.621,280.71,205.652,281.302z
			 M206.852,293.782c0.653-1.187,0.91-2.771,0.96-4.56c0.138-0.422-0.29-1.41,0.24-1.44c0.555-1.12-0.045-3.393,0.952-4.071
			c0.011,0.012,0.023,0.023,0.034,0.036c1.333,2.484,2.968,4.665,4.534,6.916c-0.602,1.162-0.712,4.083-0.24,5.52
			c0.437,1.563,1.045,2.955,1.44,4.56c1.269,2.195,3.303,6.858,6.342,5.99c0.047,0.001,0.089,0.009,0.138,0.01
			c0.1,0.013,0.167-0.007,0.221-0.04c0.011-0.006,0.022-0.012,0.032-0.02c0.047-0.038,0.082-0.089,0.106-0.15l0,0l0,0
			c0,0,0-0.001,0-0.001c0,0,0,0,0,0.001c0.273-0.128,0.552-0.287,0.841-0.511c0.08,0,0.16,0,0.24,0
			c-0.009,1.609,0.615,2.584,0.72,4.08c0.005,0.729,0.777,1.925,0.24,2.4c-9.118-0.803-13.807-7.313-15.6-14.64
			C207.542,296.612,207.087,295.307,206.852,293.782z M215.208,301.223c-0.021-0.026-0.04-0.054-0.061-0.079
			C215.168,301.169,215.187,301.197,215.208,301.223z M221.786,306.114c-0.021,0.022-0.04,0.045-0.054,0.074
			C221.747,306.159,221.766,306.136,221.786,306.114z M221.702,306.257c-0.009,0.024-0.018,0.048-0.025,0.073
			C221.684,306.305,221.693,306.281,221.702,306.257z M224.856,316.352c0.714,0.172,0.935,1.735,1.181,3.011
			C225.791,318.088,225.57,316.524,224.856,316.352z M226.146,319.885c0.004,0.017,0.008,0.032,0.012,0.049
			C226.154,319.917,226.149,319.901,226.146,319.885z M339.738,276.391c0.001-0.001,0.001-0.002,0.001-0.003
			c-0.156,0.422-0.326,0.831-0.508,1.227c-0.001,0.003-0.002,0.006-0.004,0.009c-0.921,1.07-1.888,2.094-2.535,3.438
			c-0.903,1.977-2.131,3.629-2.64,6c-0.233,0.007-0.187,0.294-0.24,0.48c-0.658,0.942-1.097,2.104-1.2,3.6
			c-0.755,1.565-1.223,3.417-1.439,5.52c-0.479,0.962-0.7,2.18-0.721,3.6c-0.079,1.361-0.301,2.579-0.72,3.6
			c0.031,0.591,0.036,1.156-0.24,1.44c-0.668,1.812-0.759,4.201-1.2,6.24c0.175,0.245,0.113,1.709-0.239,2.16
			c-0.37,1.39-0.429,3.092-0.48,4.8c0.373,0.667,1.181,0.899,1.68,1.44c-1.593,2.727-4.56,4.08-7.68,5.28
			c-0.08,0.56-0.246,1.034-0.72,1.2c-2.667,3.733-5.483,7.317-10.08,9.12c1.923,2.386,4.424-0.934,5.76-2.16
			c1.792-1.645,3.459-3.585,4.32-5.28c3.24-1.417,6.39-1.83,8.64-4.08c1.668-1.668,2.225-4.213,4.32-5.52
			c0.194-2.355-0.036-4.284-1.92-4.56c2.618-11.363,2.842-21.259,7.68-30c1.167-2.109,3.438-3.712,4.32-6
			c0.371-0.964,0.592-1.939,0.729-2.923c0.046,2.223-0.578,3.777-0.729,5.804c0.489-1.605,1.249-4.115,0.76-6.048
			c0.07-0.561,0.115-1.124,0.142-1.69c0.407-3.292,0.764-5.957,0.778-9.542c0.558-1.522,0.082-4.078,0.24-6
			c-0.362-3.144,0.009-6.278,0.479-8.16c0.641-2.56,0.448-5.219,2.4-6.24c0.543-0.284,2.529,0.08,2.16-0.96
			c0.524-0.518,0.516,0.062,0.479,0.72c0,0.32,0,0.64,0,0.96c-0.349,0.131-1.24-0.281-1.199,0.24
			c-1.934,0.725-0.196,3.974,0.239,5.28c0.212,0.668,0.3,1.46,0.721,1.92c0.912,4.768,2.194,9.166,2.64,14.4
			c0.496,1.104,0.065,3.134,0.72,4.08c0.029,0.691-0.131,1.571,0.24,1.92c0,3.36,0,6.72,0,10.08c-0.417,0.445-0.31,1.72,0.24,1.92
			c0.296,2.352-1.383,4.44-0.72,7.92c0,0.4,0,0.8,0,1.2c-3.311,1.09-1.859,6.941-3.601,9.6c-0.646,1.274-0.953,2.886-1.2,4.56
			c-0.618,0.262-0.21,1.55-0.72,1.92c-0.565,1.194-1.258,2.262-1.68,3.6c-1.023,1.536-1.917,3.204-2.641,5.04
			c-0.672,0.447-0.925,1.314-1.199,2.16c-0.781,0.66-1.153,1.728-1.681,2.64c-0.08,0-0.16,0-0.24,0
			c-0.04,0.28-0.379,0.261-0.72,0.24c0.108,1.228-0.573,1.667-0.72,2.64c-0.233,0.007-0.187,0.294-0.24,0.48
			c-0.722,0.718-1.902,0.977-2.16,2.16c-0.58,0.299-0.776,0.984-0.96,1.68c-0.787,0.493-0.916,1.644-1.439,2.4
			c-0.537-0.104-0.098-1.183-0.24-1.68c-0.496,0.258-0.4,2.746,0.479,1.68c-2.337,3.423-4.933,6.587-7.199,10.08
			c-0.31,0.331-1.043,0.237-1.681,0.24c-2.881,3.505-5.007,8.06-8.399,11.04c-6.058,5.319-16.518,8.839-26.4,9.6
			c-4.07,0.313-8.088-0.437-12.24-0.72c-1.333-0.091-2.595,0.255-3.84,0c-1.667-0.341-4.823-2.608-7.2-4.08
			c-11.783-7.294-20.055-16.386-28.8-26.88c-3.41-4.092-7.824-8.353-9.6-12.72c-2.323-5.714-2.291-13.266-4.8-18
			c-0.335-10.227-4.478-18.722-6.48-28.08c2.181,2.551,5.678,5.325,7.92,8.64c2.27,3.357,1.442,9.459,2.64,14.64
			c0.438,1.892,1.271,4.615,1.92,7.2c0.394,1.57,1.205,3.224,1.44,4.56c0.103,0.583-0.18,1.333,0,1.92
			c1.34,4.377,8.099,7.055,11.04,10.8c1.701,2.167,3.25,5.274,4.56,7.92c1.936,3.909,2.764,8.993,6,11.52
			c2.1,1.64,5.491,2.68,8.4,4.32c2.991,1.687,6.172,3.737,8.88,3.36c0.771-3.538-4.17-3.315-5.52-5.52
			c-0.279,0.039-0.427-0.053-0.48-0.24c-0.277-0.227-0.577-0.435-0.886-0.634c-0.054-0.035-0.106-0.07-0.161-0.104
			c-0.314-0.196-0.639-0.383-0.976-0.561c0,0-0.001,0-0.001-0.001c0,0,0,0,0.001,0c-0.002-0.001-0.004-0.002-0.006-0.003
			c0.002,0,0.003,0.001,0.005,0.002c-2.807-1.686-5.833-3.151-8.057-5.421c-0.96-2.559-1.731-5.309-3.12-7.44
			c-0.521-0.519-0.679-1.401-0.96-2.16c-0.821-1.979-1.968-3.632-3.12-5.28c-0.233-0.007-0.187-0.294-0.24-0.48
			c-2.923-6.197-10.877-7.363-12.72-14.64c-0.356-0.204-0.516-0.604-0.48-1.2c-0.434-2.768-1.455-7.024-2.16-10.32
			c0,0.08,0,0.16,0,0.24c-0.821-0.493-0.129-1.251-0.48-1.92c0.005-2.885,0.152-5.912-0.72-7.92c-0.392-0.808-0.625-1.775-0.96-2.64
			c-2.612-5.548-7.537-8.783-10.56-13.92c-0.42-0.219-1.197-0.083-1.44-0.48c-0.336-1.824-0.495-3.825-0.72-5.76
			c-0.122-5.319,0.292-13.252-0.24-18.96c0.138-0.422-0.29-1.41,0.24-1.44c0.472,0.457,0.143-1.925,0.24-2.64
			c0.297-4.34,1.062-7.958,2.405-11.335c-0.439,0.95-0.85,1.922-1.214,2.926c0.364-1.005,0.775-1.977,1.215-2.928
			c0,0,0,0.001-0.001,0.002c0.406-0.878,0.835-1.74,1.273-2.596c0.006-0.011,0.011-0.021,0.017-0.032
			c0.245-0.478,0.492-0.954,0.739-1.43c0.192-0.37,0.383-0.74,0.574-1.111c0.012-0.019,0.021-0.037,0.033-0.056
			c0.531-0.861,1.433-1.445,1.92-2.4c2.479-4.856,2.292-13.115,2.16-18.24c1.868,0.292,3.074,1.247,5.04,1.44
			c0.997,0.203,2.344,0.056,2.88,0.72c7.76,2.441,23.324,2.937,31.68,0c0.957-0.643,2.425-0.775,3.6-1.2
			c3.162-0.759,7.067-2.853,10.32-4.32c2.905-1.495,6.096-2.704,9.36-3.84c5.224-2.313,11.955-5.059,17.52-7.68
			c1.981-0.933,4.902-2.865,6-2.16c0.627,1.585,0.993,2.042,1.2,3.84c2.954,10.086,8.686,17.394,14.88,24.24
			c0.194,0.045,0.239,0.241,0.48,0.24c1.733,0.986,3.248,2.192,5.04,3.12c0.798,0.802,1.976,1.225,3.12,1.68
			c0.849,0.431,1.638,0.921,2.64,1.2c0.497,0.542,1.33,0.75,2.16,0.96c0.552,0.568,1.472,0.768,2.16,1.2
			c-0.021,0.341-0.04,0.68,0.239,0.72c0.012,1.828-0.131,3.811,0.48,5.04c0.131,3.389,0.909,6.131,1.439,9.12
			c-0.188,0.988,0.369,1.232,0.24,2.16c-0.646,0.284,0.001,1.251,0,1.68c-0.346,0.134-0.585,0.374-0.72,0.72
			c-1.489,0.191-1.884,1.476-2.64,2.4c-0.652,0.469-0.387,1.854-1.44,1.92c-1.693,1.903-0.944,4.832-0.96,7.2
			c-0.065,9.628-0.361,17.594-1.993,25.326 M255.092,335.062c0.189,0.3,0.411,0.572,0.646,0.833
			C255.503,335.634,255.281,335.362,255.092,335.062z M255.889,336.059c0.234,0.243,0.485,0.473,0.754,0.686
			C256.374,336.531,256.123,336.302,255.889,336.059z M256.8,336.861c0.248,0.188,0.506,0.366,0.776,0.534
			C257.307,337.227,257.048,337.049,256.8,336.861z M257.738,337.5c0.298,0.181,0.607,0.351,0.923,0.515
			C258.346,337.85,258.036,337.68,257.738,337.5z M258.95,338.162c0.274,0.137,0.552,0.272,0.834,0.403
			C259.502,338.434,259.225,338.299,258.95,338.162z M260.519,338.898c0.057,0.025,0.113,0.051,0.17,0.077
			C260.631,338.95,260.575,338.924,260.519,338.898z M217.807,234.408c0.079-0.527,0.174-1.042,0.284-1.547
			C217.981,233.366,217.886,233.881,217.807,234.408z M218.145,232.625c0.115-0.504,0.241-1.001,0.383-1.486
			C218.386,231.624,218.26,232.121,218.145,232.625z M218.534,231.117c0.15-0.51,0.315-1.008,0.491-1.5
			C218.85,230.108,218.684,230.607,218.534,231.117z M230.852,389.062c2.189-0.952,4.24-1.726,5.76-3.12
			c4.187,0.318,7.685-2.054,11.76-2.64c1.74,1.78,1.46,5.58,3.84,6.72c2.369-0.96,2.16-0.091,4.32,0.48
			c1.253-1.537,0.424-4.255,0-6.24c-1.038-4.853-3.146-10.452-4.08-15.36c1.76,1.004,3.88,1.002,5.52,1.92
			c1.247,0.698,2.123,2.131,3.36,2.88c3.169,1.918,7.117,4.017,11.52,3.84c1.164-1.791-0.337-3.392-1.2-4.56
			c-4.375-0.746-6.693-3.547-11.28-4.08c0.333-1.348,0.504-2.043,1.44-3.12c2.27,0.792,5.726,0.25,7.92-0.96
			c3.5,2.698,10.199,3.348,15.6,3.36c-0.187,1.547,0.356,2.364,0.24,3.84c-0.397,0.259-0.397,2.141,0,2.4
			c0.013,4.559,0.218,10.312,0.48,16.08c0.181,3.977,0.887,8.802,0.24,11.76c-0.552,2.522-4.488,5.218-7.44,7.44
			c-6.21,4.674-11.442,7.428-17.521,12.24c-1.841,1.457-6.354,6.745-7.92,6.96c-1.363,0.186-4.733-1.651-6.48-2.4
			c-4.027-1.728-7.095-3.434-11.76-4.32c2.833,4.611,10.392,6.565,16.32,8.88c-1.224,3.193-6.085,4.244-6.48,7.921
			c-0.1,0.928,0.401,1.717,0.48,2.64c0.298,3.482-0.59,7.177-0.48,10.8c0.11,3.609,0.519,7.229,0.72,10.8
			c0.394,7.004-0.43,13.576-1.68,20.16c-1.045,5.504-2.837,10.222-2.88,16.8c-5.434-7.626-7.288-18.327-10.56-28.56
			c-3.343-10.454-5.863-21.021-5.52-32.4c0.128-4.271,0.683-10.336,0.72-15.6c0.549-0.411,0.088-1.832,0.24-2.64
			c-0.14-8.742-0.776-16.944-0.96-24c-0.051-1.937-0.842-3.43,0-4.8C225.895,390.876,229.083,389.832,230.852,389.062z
			 M195.092,389.542c0.934-0.266,1.736-0.664,2.4-1.2c3.158-1.162,5.782-2.858,9.36-3.6c3.312-1.241,6.775,0.261,9.84,0.72
			c-3.987,2.733-8.53,4.91-15.6,4.56c-1.36,0-2.72,0-4.08,0c-1.194-0.074-2.202,0.038-3.12,0.24c0.045-0.194,0.241-0.239,0.24-0.48
			C194.346,389.596,194.674,389.524,195.092,389.542z M189.332,396.022c1.571-0.27,2.931-0.75,4.08-1.44
			c0.988,0.188,1.231-0.369,2.16-0.24c0.62-0.192,2.325-0.539,2.88,0c-0.649,0.231-1.372,0.388-1.92,0.72
			c-1.035,0.565-2.325,0.875-3.36,1.44c-0.858,0.261-1.606,0.634-2.4,0.96c0.08,0,0.16,0,0.24,0c-2.33,1.35-5.111,2.249-7.68,3.36
			c-1.219,0.146-2.906,1.086-3.12,1.44c-0.622,0.498-1.422,0.818-2.4,0.96c-0.223-0.049-2.735,0.932-2.64,1.2
			c-1.404,0.916-3.145,1.495-4.8,2.16c-1.122-0.082-1.562,0.518-2.4,0.72c-1.125,0.475-2.601,0.6-3.6,1.2
			c-1.008-0.063-2.403,1.277-2.88,0.24c0.447-0.033,0.802-0.158,0.96-0.48c1.401-0.999,3.304-1.495,5.04-2.16
			c1.758-0.562,3.433-1.207,4.8-2.16c0.729-0.711,1.69-1.19,2.88-1.44c2.03-0.69,3.794-1.646,5.52-2.64
			C183.365,398.375,186.265,397.115,189.332,396.022z M147.332,418.822c1.383-0.298,2.856-0.504,3.84-1.2c0.08,0,0.16,0,0.24,0
			c2.985-0.855,5.667-2.013,8.4-3.12c0.08,0,0.16,0,0.24,0c1.267-0.172,2.13-0.75,2.88-1.44c0.193-0.127,0.39-0.25,0.72-0.24
			c1.058,0.018,1.653-0.427,2.4-0.72c0.08,0,0.16,0,0.24,0c1.146-0.374,2.479-0.562,3.36-1.2c0.08,0,0.16,0,0.24,0
			c11.993-4.407,23.274-9.526,34.8-14.4c1.379-0.701,2.769-1.391,4.32-1.92c1.356-0.644,2.962-1.039,4.08-1.92c0.08,0,0.16,0,0.24,0
			c1.989-1.131,4.316-1.924,6-3.36c0.214-0.187,0.542-0.258,0.96-0.24c0,0.32,0,0.64,0,0.96c0.779,9.873,0.834,19.877,0.72,30.48
			c-0.137,12.646-2.417,25.325,0.24,36.96c0,0.08,0,0.16,0,0.24c-0.151,1.036,0.342,3.494,0.96,4.56
			c0.232,2.008,1.312,3.168,1.44,5.28c0.475,0.725,0.254,2.146,0.96,2.64c0,0.08,0,0.16,0,0.24c0.204,1.076,0.32,2.239,0.96,2.88
			c0,0.08,0,0.16,0,0.24c0.906,3.575,2.172,8.867,3.6,12.72c0.187,0.534,0.504,0.937,0.48,1.681c0.287,1.553,0.783,2.896,1.44,4.08
			c0,0.08,0,0.159,0,0.239c1.538,3.822,3.156,7.564,5.28,10.801c0,0.08,0,0.159,0,0.239c1.7,3.181,2.973,6.788,4.8,9.841
			c0,0.08,0,0.159,0,0.239c1.771,3.669,3.514,7.366,5.76,10.561c0.601,1.319,1.44,2.4,1.92,3.84c1.45,3.03,2.736,6.224,4.56,8.88
			c0,0.08,0,0.16,0,0.24c1.59,2.81,3.223,5.577,5.28,7.92c0.417,0.782,1.199,1.201,1.44,2.16c1.667,1.853,2.836,4.204,4.8,5.76
			c0,0.08,0,0.16,0,0.24c5.114,6.166,9.919,12.641,15.36,18.479c0,0.08,0,0.16,0,0.24c1.76,2.32,4.104,4.057,5.76,6.48
			c-2.645,3.115-5.845,5.674-8.88,8.399c-1.316,1.004-2.517,2.124-4.08,2.88c-3.741,2.5-7.068,5.412-10.8,7.921
			c0.08-0.881,0.951-0.97,0.72-2.16c-2.304-2.36-6.269-2.551-9.84-3.36c-3.677-0.834-7.527-1.728-11.04-2.4
			c-2.673-0.446-5.022-1.218-7.44-1.92c-3.626-1.45-9.612-2.978-13.92-2.16c1.694,1.507,3.864,2.537,6,3.601c0.08,0,0.16,0,0.24,0
			c6.768,2.912,14.65,4.709,22.8,6.24c0.592-0.032,1.157-0.037,1.44,0.239c0.811,0.47,1.975,0.585,3.12,0.721c0.08,0,0.16,0,0.24,0
			c2.109,0.784,5.187,1.563,7.44,1.439c-1.504,1.536-3.593,2.487-5.04,4.08c-1.065,0.535-1.695,1.505-2.88,1.92
			c-4.276,3.004-8.367,6.193-13.44,8.4c-0.83,2.007,1.057,3.004,0.48,5.52c0.373,2.668-0.052,6.133,0.72,8.4
			c-0.006,1.446,0.409,2.471,0.24,4.08c0.686,2.514-0.215,6.615,0.48,9.12c0,0.88,0,1.76,0,2.64
			c0.104,5.224-0.066,10.174-0.48,14.88c0.128,1.968-0.517,3.164-0.48,5.04c-1.436,5.364-0.481,13.119-1.2,19.2
			c0.146,1.746-0.184,3.016-0.24,4.561c-0.373,2.587-0.411,5.509-0.48,8.399c0.105,0.825-0.095,1.346-0.24,1.92
			c0,0.177,0,0.353,0,0.528c-0.145,1.375-1.619,1.009-2.64,1.152c-0.64,0-1.28,0-1.92,0c-1.977-0.026-4.635-0.641-6.48,0
			c-0.24,0-0.48,0-0.72,0c-1.204,0.155-3.035-0.314-3.84,0.239c-0.16,0-0.32,0-0.48,0c-0.966,0.154-2.552-0.312-3.12,0.24
			c-0.24,0-0.48,0-0.72,0c-0.808,0.152-2.229-0.31-2.64,0.24c-0.193,0.127-0.39,0.25-0.72,0.24c-0.926,0.034-2.107-0.188-2.4,0.479
			c-1.125,0.155-1.883,0.677-3.12,0.72c-1.464,0.616-3.684,0.477-4.32,1.921c4.679,0.592,12.793-0.314,18.72-0.721
			c0.08,0,0.16,0,0.24,0c1.842-0.158,4.317,0.317,5.76-0.24c0.08,0,0.16,0,0.24,0c2.23,0.57,5.648-0.047,7.68,0.721
			c0,0.56,0,1.12,0,1.68c-0.89,0.311-2.008,0.392-3.12,0.48c-0.014,0.811,0.433,1.023-0.24,1.439
			c-5.202,1.686-13.159,0.761-18.72,1.44c-0.08,0-0.16,0-0.24,0c-0.966,0.153-2.552-0.313-3.12,0.239c-0.08,0-0.16,0-0.24,0
			c-3.799,0.121-7.443,0.397-10.8,0.961c-1.639,0.4-2.962,0.032-5.04,0.239c-1.134-0.04-2.847-0.214-4.08,0.24
			c-0.97,0.133-1.471,0.191-2.64,0c-3.457,0.737-1.336-4.104-2.88-5.28c-0.359-2.841-0.577-5.822-0.96-8.64
			c-0.058-5.862-0.161-13.76-1.2-19.44c-0.005-1.914-0.405-3.435-0.24-5.52c-0.538-2.394-0.529-5.52-0.72-8.88
			c-0.171-3.021,0.255-6.876-0.48-9.36c-0.19-0.644-1.264-1.342-2.005-2.148c-0.01-0.01-0.02-0.021-0.029-0.03
			c-0.27-0.298-0.494-0.61-0.606-0.941c-0.387-1.146-0.519-3.409-0.512-5.849c-0.007,0.732,0,1.451,0.016,2.165
			c-0.06-2.442,0.034-5,0.163-7.698c-0.035,0.74-0.068,1.472-0.097,2.19c0.049-1.096,0.116-2.143,0.19-3.049
			c0.189-2.316,0.224-4.451,0.202-6.534c0,0.005,0,0.009,0,0.013c0-0.005,0-0.011,0-0.017c0,0.001,0,0.003,0,0.004
			c0.016-0.696,0.034-1.391,0.038-2.105c0.004-0.693-0.022-1.381-0.058-2.072c-0.007-0.135-0.013-0.273-0.021-0.409
			c-0.041-0.68-0.09-1.369-0.145-2.07c-0.078-2.421-0.127-4.921-0.017-7.688c0.229-5.744,1.891-12.809-3.84-11.52
			c-0.333-0.388-0.203-1.237-0.72-1.44c0-0.08,0-0.16,0-0.24c-0.869-3.771-1.827-7.453-1.92-12c0-0.239,0-0.479,0-0.72
			c0.219-3.58-0.375-8.424-0.24-12.72c0-0.24,0-0.48,0-0.72c0.559-2.481,0.081-6,0.24-8.881c0.975,0.326,1.285-0.485,1.712-0.365
			c-0.093-0.197-0.17-0.401-0.272-0.595c0.102,0.193,0.179,0.397,0.272,0.595c0,0,0,0,0,0c0.133,0.281,0.271,0.56,0.388,0.848l0,0
			c0.157,0.386,0.293,0.781,0.426,1.178c-0.133-0.396-0.269-0.792-0.426-1.178c1.04,4.142,1.579,7.943,2.46,11.998
			c-2.031,2.676,1.226,6.271,0,8.88c0.077,0.557-0.039,0.922-0.24,1.2c-0.789,1.56-2.488,4.579-0.24,5.28
			c2.926,0.911,4.784-4.604,5.04-7.921c0.45-5.834-3.057-11.647-2.88-15.6c-0.21-0.424-0.76-0.7-0.24-1.2
			c-0.716-2.724-1.27-5.61-2.4-7.92c-1.336-0.216-2.021,0.22-2.4,0.96c-0.002-0.638,0.091-1.37-0.24-1.68
			c-0.41-1.189-1.037-2.164-1.2-3.6c-1.448-4.713-2.632-9.688-3.84-14.641c-0.829-4.484-1.654-10.961-2.88-15.84
			c-0.156-1.284,0.315-3.195-0.24-4.08c-0.504-0.936-0.12-2.76-0.24-4.08c0.027-2.267-0.043-4.437-0.48-6.24c0-0.08,0-0.16,0-0.24
			c-0.371-4.028,0.012-8.812-0.48-12.72c0-0.08,0-0.16,0-0.24c-0.155-1.045,0.313-2.713-0.24-3.359c0-0.08,0-0.16,0-0.24
			c-0.159-8.881,0.318-18.398-0.24-26.88c-1.312,0.266-1.44,2.024-1.44,2.399c0,0.881,0,1.761,0,2.641
			c-1.55,14.378-0.999,33.363-0.961,48.844c0.226,0.453,0.46,0.897,0.721,1.316c-0.26-0.419-0.495-0.863-0.721-1.316l0,0
			c-0.009-0.019-0.02-0.037-0.03-0.055c-0.016-0.032-0.035-0.06-0.05-0.092c-1.304-4.102-2.388-8.423-3.999-12.218
			c0.195,0.541,0.376,1.095,0.552,1.655c-0.175-0.561-0.357-1.114-0.552-1.655c-0.907-2.934-1.963-5.717-2.88-8.64
			c-2.659-8.142-4.154-17.446-6-26.4c-0.168-1.327-0.967-3.116-0.72-4.08c-0.71-1.408-0.295-2.63-0.48-3.6c0-0.08,0-0.16,0-0.24
			c-0.252-1.188-0.389-2.491-0.96-3.359c-0.258-0.623-0.583-1.178-0.48-2.16c-1.536-6.545-2.322-13.838-4.56-19.681
			c-0.187-0.213-0.258-0.542-0.24-0.96c-0.691-3.469-2.212-8.188-3.6-11.76c0.099-0.578-0.438-0.521-0.24-1.2
			c0.089-1.054-0.89-3.106-1.2-4.56c-0.269-0.771-0.657-1.423-0.72-2.4c0.198-0.678-0.338-0.621-0.24-1.2
			c3.503-0.571,6.718-0.482,9.84-1.199C147.172,418.822,147.252,418.822,147.332,418.822z M181.223,619.326
			c0.019,0.358,0.039,0.715,0.063,1.062C181.262,620.04,181.242,619.686,181.223,619.326z M181.333,621.077
			c0.03,0.408,0.061,0.815,0.091,1.212C181.393,621.891,181.363,621.488,181.333,621.077z M181.375,635.193
			c-0.009,0.195-0.018,0.393-0.027,0.586C181.357,635.585,181.366,635.39,181.375,635.193z M179.732,579.143
			c-0.118-0.53-0.224-1.073-0.324-1.622C179.508,578.069,179.614,578.611,179.732,579.143z M179.333,577.089
			c-0.225-1.278-0.426-2.591-0.655-3.907C178.906,574.498,179.109,575.811,179.333,577.089z M178.635,572.938
			c-0.076-0.429-0.158-0.856-0.244-1.282C178.477,572.083,178.56,572.51,178.635,572.938z M178.307,571.251
			c-0.085-0.401-0.175-0.802-0.273-1.199C178.131,570.449,178.222,570.849,178.307,571.251z M177.92,569.627
			c-0.104-0.393-0.211-0.783-0.333-1.169C177.709,568.844,177.817,569.234,177.92,569.627z M162.452,546.502
			c0.015-0.481,0.018-0.996,0.015-1.527C162.47,545.505,162.467,546.021,162.452,546.502z M161.167,573.94
			c-0.001,0.389-0.013,0.777-0.011,1.165C161.154,574.718,161.166,574.328,161.167,573.94z M161.199,571.046
			c0.01-0.406,0.03-0.815,0.042-1.222C161.229,570.23,161.209,570.64,161.199,571.046z M161.348,566.93
			c0.016-0.33,0.036-0.664,0.052-0.995C161.384,566.266,161.364,566.6,161.348,566.93z M162.509,537.527
			c0.008-0.097,0.018-0.19,0.027-0.285C162.527,537.337,162.517,537.431,162.509,537.527z M162.609,536.594
			c0.014-0.105,0.03-0.209,0.046-0.312C162.639,536.385,162.624,536.488,162.609,536.594z M162.756,535.727
			c0.021-0.105,0.044-0.208,0.068-0.309C162.8,535.519,162.777,535.622,162.756,535.727z M162.958,534.937
			c0.029-0.096,0.058-0.192,0.091-0.283C163.016,534.744,162.987,534.841,162.958,534.937z M163.225,534.236
			c0.037-0.08,0.071-0.163,0.112-0.237C163.296,534.072,163.262,534.157,163.225,534.236z M163.575,533.634
			c0.041-0.056,0.077-0.119,0.12-0.17C163.652,533.515,163.616,533.578,163.575,533.634z M163.57,522.479
			c-0.068-0.142-0.127-0.292-0.192-0.436C163.443,522.187,163.502,522.337,163.57,522.479z M162.908,520.96
			c-0.093-0.234-0.178-0.477-0.266-0.716C162.729,520.483,162.814,520.726,162.908,520.96z M162.324,519.357
			c-0.091-0.269-0.177-0.541-0.264-0.814C162.147,518.816,162.233,519.089,162.324,519.357z M161.795,517.688
			c-0.09-0.302-0.18-0.604-0.268-0.91C161.615,517.083,161.705,517.386,161.795,517.688z M161.29,515.947
			c-0.151-0.539-0.304-1.077-0.456-1.616C160.986,514.87,161.139,515.408,161.29,515.947z M160.646,513.666
			c-0.114-0.396-0.232-0.789-0.35-1.181C160.414,512.877,160.532,513.27,160.646,513.666z M138.932,439.462
			c-0.122,0.031-0.229,0.055-0.327,0.074C138.703,439.518,138.81,439.493,138.932,439.462z M138.53,439.548
			c-0.066,0.011-0.132,0.022-0.189,0.028C138.398,439.57,138.463,439.559,138.53,439.548z M138.266,439.582
			c-0.053,0.004-0.107,0.008-0.156,0.008C138.159,439.59,138.212,439.586,138.266,439.582z M145.569,458.968
			c0.013,0.054,0.022,0.106,0.033,0.159C145.591,459.074,145.582,459.021,145.569,458.968z M145.716,460.134
			c0,0.057,0.002,0.114,0,0.17C145.719,460.248,145.716,460.19,145.716,460.134z M145.677,459.553
			c0.009,0.064,0.016,0.128,0.022,0.191C145.693,459.681,145.686,459.617,145.677,459.553z M145.167,457.707
			c-0.016-0.039-0.032-0.078-0.047-0.118C145.135,457.629,145.152,457.668,145.167,457.707z M144.881,457.019
			c-0.014-0.031-0.027-0.063-0.041-0.094C144.854,456.956,144.868,456.987,144.881,457.019z M95.012,635.542
			c-0.026,0.523-0.075,1.038-0.136,1.547C94.934,636.578,94.985,636.065,95.012,635.542z M89.784,624.853
			c-0.008-0.015-0.008-0.037-0.014-0.054C89.776,624.815,89.776,624.838,89.784,624.853z M89.136,661.888
			c-0.002,0.303-0.007,0.604,0.001,0.913C89.128,662.491,89.135,662.191,89.136,661.888z M89.149,660.943
			c0.014-0.409,0.038-0.811,0.068-1.21C89.187,660.132,89.164,660.535,89.149,660.943z M87.108,671.159
			c-0.019-0.102-0.036-0.204-0.055-0.305C87.073,670.955,87.09,671.058,87.108,671.159z M158.372,694.103
			c-0.541,0.579-0.507,1.732-0.72,2.64c0,0.08,0,0.16,0,0.24c-0.628,1.132-0.796,2.724-0.96,4.32c0,0.319,0,0.64,0,0.96
			c-0.45,2.19-0.698,4.582-0.72,7.2c0,0.399,0,0.8,0,1.199c0.072,1.752-0.425,2.936-0.24,4.801c0,0.8,0,1.6,0,2.399
			c-0.212,1.79-0.736,4.54-0.48,6c0.077,0.557-0.039,0.922-0.24,1.2c-0.659,4.941-0.933,10.268-1.44,15.36c0,0.08,0,0.16,0,0.239
			c-0.36,2.761-0.95,5.291-1.2,8.16c-0.222,3.219-1.036,5.845-1.44,8.881c-1.141,3.019-2.059,6.261-2.4,10.079c0,0.08,0,0.16,0,0.24
			c-12.563,1.033-28.72,0.648-41.04,1.2c-0.4,0-0.8,0-1.2,0c-9.76,0-19.52,0-29.28,0c-0.24,0-0.48,0-0.72,0
			c-3.462-0.421-7.88,0.278-11.76,0.24c-0.56,0-1.12,0-1.68,0c0.474-4.966,1.726-9.154,2.64-13.681
			c0.099-1.661,0.751-2.769,0.96-4.319c0.632-1.608,1.124-3.356,1.44-5.28c0-0.16,0-0.32,0-0.479c1.19-2.49,1.978-5.383,3.12-7.921
			c0-0.079,0-0.16,0-0.239c2.28-4.521,4.687-8.914,6.72-13.681c0-0.08,0-0.16,0-0.239c1.27-1.53,1.8-3.801,2.88-5.521
			c0-0.08,0-0.16,0-0.24c1.234-1.806,2.076-4.004,3.36-5.76c-0.037-0.597,0.377-0.743,0.48-1.2c0.579-0.559,0.844-1.325,0.804-2.046
			c0.04,0.721-0.226,1.487-0.804,2.046c0.86-0.34,0.853-1.548,1.2-2.399c-0.745-1.65-1.944-2.009-3.119-1.681c0,0-0.001,0-0.001,0
			c-0.006,0.001-0.012,0.004-0.018,0.004c0.004-0.001,0.008-0.002,0.012-0.003c-0.005,0.001-0.01,0.003-0.016,0.004
			c0.001,0,0.002-0.001,0.003-0.001c-0.003,0-0.005,0.002-0.008,0.002c-0.15,0.023-0.292,0.068-0.428,0.126
			c-0.024,0.01-0.047,0.021-0.071,0.031c-0.132,0.062-0.259,0.135-0.38,0.223c-0.001,0.001-0.002,0.001-0.003,0.002
			c0,0,0.001-0.001,0.002-0.001c-0.001,0.001-0.002,0.001-0.003,0.002c0,0,0-0.001,0.001-0.001
			c-1.129,0.651-2.107,1.839-2.451,2.973c0.037-0.764,0.544-1.056,0.48-1.92c0-0.08,0-0.16,0-0.24
			c1.655-3.507,1.833-7.086,2.88-11.28c-0.521-0.499,0.029-0.775,0.24-1.199c0.373-2.029,1.188-4.89,0.96-6.721
			c0.313-1.287,0.468-2.731,0.72-4.08c0.128-2.594,0.634-6.083,0.72-7.92c0.187-1.333,0.434-2.605,0.72-3.84c0.08,0,0.16,0,0.24,0
			c0.283-0.597-0.111-1.871,0.72-1.92c1.212,4.731,0.84,11.049,4.477,13.356c-0.002-0.002-0.003-0.004-0.005-0.006
			c0.002,0.002,0.004,0.004,0.006,0.006h0c0.029,0.026,0.054,0.058,0.083,0.084c0.005-0.01,0.008-0.02,0.013-0.028l0,0
			c0-0.001,0.001-0.002,0.001-0.004c0,0.002-0.001,0.003-0.001,0.004c0.153,0.092,0.3,0.189,0.467,0.268
			c0.051-0.628,0.081-1.246,0.096-1.856c0,0.001-0.001,0.003-0.001,0.004c0-0.001,0-0.003,0.001-0.005c0,0.001,0,0.001,0,0.001
			c0-0.001,0-0.001,0-0.002c0.064-0.303,0.104-0.606,0.126-0.911c0.001-0.017,0.002-0.033,0.003-0.051
			c0.04-0.599,0.007-1.199-0.072-1.795c-0.003-0.027-0.007-0.054-0.011-0.081c-0.041-0.295-0.091-0.589-0.151-0.882
			c0-0.001,0-0.003-0.001-0.004v0.001c0-0.002-0.001-0.004-0.001-0.007c0,0.002,0.001,0.004,0.001,0.006
			c-0.583-7.438-3.107-13.822-2.472-21.318c-0.063,0.466-0.112,0.94-0.155,1.42c0.043-0.48,0.093-0.956,0.156-1.423
			c0,0.001,0,0.002,0,0.003c0.481-3.574,1.508-6.767,2.555-9.861c1.076-3.161,2.16-6.227,2.707-9.488l0,0l0,0l0,0l0,0
			c0.777-3.498,1.101-7.258,0.339-11.59c0-0.08,0-0.16,0-0.24c0.476-8.514-3.706-16.417-0.72-24.479c0-0.08,0-0.16,0-0.24
			c2.317-6.163,4.7-12.261,6.48-18.96c0-0.08,0-0.16,0-0.24c0.99-1.892,1.795-5.242,1.92-7.2c-0.079-1.118,0.188-1.893,0.48-2.64
			c1.658-6.343,3.438-12.563,6-18c0-0.08,0-0.16,0-0.24c1.272-1.928,2.019-4.382,3.12-6.479c0.15-0.891,0.675-1.405,0.96-2.16
			c1.213-1.348,1.81-3.311,2.88-4.8c0-0.08,0-0.16,0-0.24c0.945-1.056,1.635-2.365,2.4-3.601c0-0.08,0-0.159,0-0.239
			c0.937-0.743,1.552-1.809,2.16-2.881c0-0.08,0-0.159,0-0.239c2.713-3.287,4.985-7.016,7.2-10.8c0-0.08,0-0.16,0-0.24
			c1.037-0.883,1.649-2.191,2.16-3.601c0-0.08,0-0.159,0-0.239c0.613-0.588,0.821-1.58,1.2-2.4c0-0.08,0-0.16,0-0.24
			c0.741-0.353,0.506-1.946,0.24-2.64c-2.067-0.616-2.757,1.672-3.36,2.88c-2.161,4.33-4.458,8.346-6.96,12
			c-0.092,0.788-0.702,1.059-0.96,1.68c-2.416,2.784-4.373,6.027-6.48,9.12c0.068,0.388-0.227,0.413-0.24,0.72
			c-3.346,4.175-5.046,9.994-7.92,14.641c0-0.16,0-0.32,0-0.48c1.458-12.276,5.27-23.348,8.16-35.04
			c3.697-14.957,3.819-33.773,2.88-50.88c0-0.08,0-0.16,0-0.24c0.4-1.922,0.4-5.518,0-7.439c-0.026-0.598-0.45-0.743,0-1.2
			c-0.781-4.107,0.921-7.661,1.2-12c0-0.08,0-0.16,0-0.24c0.571-1.669,1.094-3.386,1.68-5.04c-0.068-0.388,0.227-0.413,0.24-0.72
			c1.593-2.694,2.97-5.73,5.28-8.64c0.232-0.293,1.834-2.39,2.16-2.4c0.501-0.017,3.907,3.699,4.56,4.32
			c1.974,1.876,3.131,3.561,4.907,4.433c-0.391-0.369-0.777-0.743-1.166-1.114c0.39,0.371,0.775,0.745,1.167,1.114
			c0,0-0.001,0-0.001,0c0.045,0.042,0.089,0.085,0.133,0.127c0.07,0.004,0.125,0.01,0.187,0.015c0,0,0,0,0,0
			c0.527,0.041,0.799,0.107,1.104,0.112c-0.31-0.005-0.575-0.071-1.104-0.112c0.578,0.241,1.214,0.41,1.974,0.466
			c0.157,1.603,0.892,2.627,1.2,4.08c0.63,1.565,1.429,3.871,1.92,6.239c0.013,0.166,0.042,0.339,0.079,0.517
			c0.015,0.072,0.036,0.147,0.054,0.222c0.025,0.102,0.052,0.203,0.083,0.308c0.031,0.103,0.064,0.207,0.1,0.313
			c0.023,0.068,0.048,0.137,0.073,0.206c0.048,0.133,0.098,0.267,0.153,0.403c0.938,2.877,2.406,5.654,2.578,8.353
			c-0.16,0-0.32,0-0.48,0c-0.08,0-0.16,0-0.24,0c-1.125,1.59,0.238,3.812,0.96,5.04c0.037,0.764,0.544,1.056,0.48,1.92
			c1.528,5.912,2.301,12.579,3.84,18.48c0.049,1.471,0.566,2.474,0.72,3.84c0.505,5.606,3.31,12.77,5.28,18.479
			c0.431,1.489,1.084,2.756,1.44,4.32c1.421,6.659,4.46,13.78,6.72,20.4c-2.214,1.011-1.638,3.617-1.476,6.471
			c0-0.003,0-0.006,0-0.009c0,0.003,0,0.007,0,0.01v-0.001c0,0.001,0,0.001,0,0.002c-0.009,0.568-0.008,1.148-0.002,1.73
			c0,0.05,0.001,0.1,0.001,0.149c0.006,0.563,0.016,1.123,0.026,1.678c0,0.009,0,0.019,0,0.028c0-0.001,0-0.002,0-0.003
			c0.011,0.567,0.02,1.127,0.024,1.667c-0.004-0.54-0.013-1.097-0.024-1.664c-0.005,1.405-0.011,2.835-0.026,4.269
			c0,0.008,0,0.016-0.001,0.024c-0.135,3.787-0.357,7.438-0.579,11.009c-0.108,1.719-0.213,3.423-0.31,5.108
			c-0.765,11.676-0.455,22.648,1.166,33.371c1.162,7.684,1.537,15.295,5.04,20.88c2.247,3.309,1.417,10.222,3.12,13.681
			c0.135,0.344,0.261,0.711,0.383,1.087c-0.122-0.376-0.248-0.743-0.383-1.087c0.729,3.477,1.432,6.979,1.443,11.174
			c0,0.002,0,0.003,0,0.005c-0.047,0.411-0.108,0.816-0.182,1.211c0,0.001-0.001,0.003-0.001,0.005
			c-0.967,0.822-1.528,2.988-1.5,3.925c0.18-0.262,0.343-0.542,0.493-0.836c-0.151,0.293-0.313,0.574-0.493,0.836
			c0.102,0.502-0.165,0.636-0.24,0.96c-1.205-1.274-0.534-4.426-1.44-6c-0.176-1.097-0.929-2.651-0.72-3.359
			c-2.462-9.298-4.949-18.571-8.88-26.4c-0.354-0.606-0.848-1.072-0.96-1.92c-0.592-1.328-1.318-2.522-2.16-3.601
			c0-0.079,0-0.159,0-0.239c-0.089-1.111-1.346-1.055-2.16-1.44c-0.502,0.102-0.636-0.164-0.96-0.24
			c-1.034,3.756,1.295,6.225,2.64,8.4c0.127,0.193,0.25,0.39,0.24,0.72c0.278,1.002,0.769,1.791,1.2,2.641c0,0.08,0,0.159,0,0.239
			c2.827,6.833,6.183,17.959,7.68,26.16c0.572,3.13,0.91,7.92,2.4,8.88c0.08,0,0.16,0,0.24,0c0,0.08,0,0.16,0,0.24
			c0.816,0.314,1.675-0.534,1.92,0c-0.485,1.354-1.02,2.66-1.2,4.32c0,0.08,0,0.16,0,0.24c-3.1,10.819-6.321,21.519-8.88,32.88
			c0,0.08,0,0.16,0,0.24c-0.571,0.868-0.708,2.171-0.96,3.359C158.372,693.942,158.372,694.022,158.372,694.103z M78.932,711.143
			c0.085-0.074,0.169-0.175,0.253-0.287C79.101,710.968,79.017,711.068,78.932,711.143z M79.236,710.786
			c0.078-0.11,0.156-0.234,0.235-0.37C79.392,710.552,79.314,710.676,79.236,710.786z M79.548,710.279
			c0.054-0.096,0.108-0.195,0.163-0.299C79.656,710.084,79.602,710.183,79.548,710.279z M84.966,707.842
			c0.013,0.023,0.021,0.049,0.034,0.072C84.988,707.891,84.979,707.865,84.966,707.842z M85.113,708.16
			c0.017,0.046,0.026,0.095,0.041,0.143C85.14,708.255,85.13,708.206,85.113,708.16z M85.21,708.501
			c0.009,0.041,0.01,0.085,0.017,0.127C85.22,708.586,85.218,708.543,85.21,708.501z M81.161,707.633
			c0.005-0.005,0.009-0.01,0.014-0.015C81.17,707.623,81.166,707.628,81.161,707.633z M91.743,683.737
			c0.015-0.041,0.029-0.082,0.042-0.123C91.772,683.655,91.758,683.696,91.743,683.737z M90.932,675.383
			c0.132,0.587,0.3,1.195,0.468,1.816C91.232,676.578,91.064,675.97,90.932,675.383z M162.458,538.308
			c-0.003,0.068-0.008,0.136-0.011,0.204C162.451,538.443,162.455,538.376,162.458,538.308z M172.111,643.86
			c-0.069,0.363-0.15,0.718-0.244,1.063C171.96,644.578,172.042,644.224,172.111,643.86z M171.858,644.959
			c-0.087,0.319-0.187,0.628-0.297,0.928C171.67,645.587,171.771,645.279,171.858,644.959z M171.521,646
			c-0.104,0.271-0.219,0.531-0.342,0.783C171.302,646.53,171.419,646.272,171.521,646z M172.296,642.635
			c0.16-1.404,0.165-2.891,0.047-4.361C172.462,639.746,172.456,641.229,172.296,642.635z M171.331,632.865
			c0.091,0.297,0.177,0.601,0.258,0.913C171.508,633.466,171.422,633.162,171.331,632.865z M171.667,634.079
			c0.21,0.854,0.383,1.752,0.511,2.674C172.051,635.831,171.876,634.933,171.667,634.079z M172.186,636.813
			c0.063,0.46,0.113,0.924,0.152,1.392C172.299,637.738,172.248,637.273,172.186,636.813z M177.332,763.942
			c0.107-1.387-0.101-2.459-0.24-3.6c1.682,2.558,2.66,5.82,4.56,8.16c-1.12,0-2.24,0-3.36,0c-0.489-1.271-0.443-3.077-0.96-4.32
			C177.332,764.103,177.332,764.022,177.332,763.942z M409.173,768.263c1.542-2.725-2.16-4.194-3.36-5.76
			c-0.085-0.315-0.423-0.378-0.479-0.721c-2.276-2.444-4.818-4.622-7.2-6.96c0-0.079,0-0.16,0-0.239
			c-1.063-1.177-1.946-2.534-3.12-3.601c0-0.08,0-0.16,0-0.24c-2.528-3.231-4.641-6.879-7.44-9.84
			c-0.515-0.845-1.379-1.34-1.68-2.399c-1.344-1.617-2.435-3.485-4.08-4.801c0-0.079,0-0.159,0-0.239
			c-2.989-3.812-6.545-7.056-9.36-11.04c1.808,0.263,1.642-0.247,2.16-0.96c4.24,0,8.48,0,12.72,0
			c2.378,1.932,3.875,4.744,5.891,7.037c-0.014-0.018-0.028-0.037-0.042-0.056c0.015,0.019,0.029,0.039,0.043,0.057l-0.001-0.001
			c0.001,0.001,0.002,0.002,0.002,0.003c0.131,0.163,0.261,0.315,0.391,0.456c0.009,0.009,0.017,0.019,0.025,0.027
			c0.137,0.146,0.274,0.281,0.412,0.396c0,0.08,0,0.16,0,0.24c3.438,4.721,6.716,9.604,10.319,14.159c0,0.08,0,0.16,0,0.24
			c0.759,0.922,1.165,2.196,2.16,2.88c0.003,0.478,0.655,0.306,0.48,0.96c2.159,3.04,4.037,6.362,6.24,9.36
			c1.521,1.598,2.704,3.536,2.64,6.72c0.15,0.729-0.308,2.067,0.24,2.4c0,0.399,0,0.8,0,1.2c-0.566,0.074-0.446,0.833-1.2,0.72
			c-1.651,0.227-3.969,0.939-5.76,0.24C409.173,768.423,409.173,768.343,409.173,768.263z M490.772,724.822
			c0.168,2.393,0.186,4.935,0.48,7.2c0.157,1.443-0.316,3.517,0.24,4.561c0.132,1.628-0.282,3.802,0.239,5.04
			c0.152,0.808-0.309,2.229,0.24,2.64c0,1.04,0,2.08,0,3.12c0.154,0.966-0.312,2.552,0.24,3.12c0,0.88,0,1.76,0,2.64
			c0.151,0.808-0.31,2.229,0.24,2.64c0.248,0.632,0.287,1.474,0.239,2.4c0.154,0.966-0.312,2.552,0.24,3.12c0,0.56,0,1.12,0,1.68
			c0.154,0.966-0.312,2.553,0.24,3.12c0,0.64,0,1.28,0,1.92c-2,0-4,0-6,0c0-0.64,0-1.28,0-1.92c-0.541,0.179-0.094,1.347-0.24,1.92
			c-5.471,0.145-12.758-0.298-17.04,0.24c-0.08,0-0.16,0-0.24,0c-7.202,0.718-16.078-0.238-23.279,0.479c-1.12,0-2.24,0-3.36,0
			c-6.08,0.16-12.8-0.319-18.48,0.24c-0.239,0-0.479,0-0.72,0c0-0.56,0-1.12,0-1.68c-0.154-0.966,0.313-2.552-0.24-3.12
			c0.17-0.89-0.41-1.03-0.239-1.92c-0.158-1.842,0.317-4.317-0.24-5.76c-0.332-0.788-0.566-1.675-0.48-2.88
			c-0.15-0.729,0.308-2.067-0.239-2.4c-0.741-2.38-0.411-5.829-0.48-8.88c-0.158-2.161,0.318-4.958-0.24-6.72
			c-0.86-3.807-0.51-10.329-0.479-12.961c0-3.119,0-6.239,0-9.359c1.408-2.965,0.076-7.391,0.479-11.521
			c-0.091-1.876-0.072-5.554-0.24-8.64c1.086-0.515,3.087-0.113,4.561-0.24c4.396-0.323,9.292-0.148,12.96-1.2
			c1.105-0.494,2.443-0.757,3.6-1.199c0.08,0,0.16,0,0.24,0c0.549-0.577,0.041-1.452-0.24-1.921
			c-6.345-0.562-14.064,1.164-21.6,0.961c0.128-0.929-0.429-1.172-0.24-2.16c9.782-2.379,18.415-5.905,27.601-8.88
			c0.08,0,0.159,0,0.239,0c7.11-2.091,13.919-4.481,20.641-6.96c0.08,0,0.16,0,0.24,0c1.369-0.311,2.78-0.58,3.84-1.2
			c0.08,0,0.16,0,0.239,0c2.07-0.65,4.179-1.263,6.24-1.92c0.799-0.41,1.3-0.73,2.16-0.48c0,0.08,0,0.16,0,0.24
			c0.773-0.826,2.665-0.535,3.601-1.2c1.035-0.004,0.884-1.195,1.92-1.2c0.132,0.428,0.123,0.997,0.479,1.2
			c0.281,0.039,0.203,0.438,0.24,0.72c0.021,0.78,0.124,1.477,0.479,1.92c0.281,0.039,0.203,0.438,0.24,0.721
			c0.191,2.1,1.331,3.049,0.48,5.04c-0.322,0.157-0.447,0.513-0.48,0.96c-2.472,1.688-2.714,5.606-3.6,8.88
			c-1.68,3.295-0.245,7.489,0.96,10.56c0.51,0.851,0.804,1.917,0.96,3.12c0.648,3.03,1.007,5.407,1.68,7.44
			C489.277,711.763,490.023,718.928,490.772,724.822z"/>
			</g>
		</g>
		<g id="mouth1">
			<path fillRule="evenodd" clipRule="evenodd" d="M276.33,310.603c0,0,0.001,0,0.001,0c-0.203,0.023-0.4,0.045-0.602,0.068
		c-1.697,0.188-3.358,0.363-4.959,0.621c-1.252,0.073-2.36,0.149-3.438,0.491c-1.431,0.454-2.853,1.454-4.56,2.16
		c-2.811,1.164-10.759,3.708-7.2,6.72c6.136-2.344,10.871-6.089,18.72-6.72c1.287-0.313,2.506-0.694,4.08-0.72
		c5.545-0.102,9.506-2.787,14.257-4.226c0.659-0.181,1.33-0.35,2.01-0.51c0.64-0.13,1.293-0.24,1.974-0.304
		c3.41-0.323,7.949-0.2,11.76-0.72c0.08,0,0.16,0,0.24,0c0.24,0,0.48,0,0.72,0c0.952-0.089,2.301,0.22,2.88-0.24
		c1.443-0.739,0.695-2.34-0.239-3.12c-3.021,0.428-7.367,0.292-11.521,0.48c-1.825,0.083-3.592,0.336-5.327,0.693
		c-0.5,0.059-0.988,0.126-1.457,0.21c-0.478,0.084-0.938,0.182-1.376,0.297c-0.42,0.111-0.852,0.251-1.292,0.408
		c-0.05,0.018-0.104,0.041-0.153,0.059c-0.399,0.146-0.805,0.303-1.219,0.475c-4.347,1.337-8.647,2.956-13.296,3.878
		 M294.652,308.483"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M294.933,297.142c-0.336-0.397-2.304-0.397-2.641,0
		c-7.487-0.061-8.264,9.107-16.56,6c-2.281-0.304-4.078-0.593-6.24,0c-3.005,0.402-9.949,5.287-9.36,8.16
		c2.86-1.54,4.38-4.419,7.44-5.76c0.56,0,1.12,0,1.68,0c1.686-0.635,4.243-0.565,6.24-0.24c1.168,0.192,2.3,0.42,3.12,0.96
		c6.211,1.456,8.583-6.609,13.92-6.48c-0.039-0.394,1.479-0.394,1.44,0c1.765,0.974,3.122,0.627,3.764-0.084
		c0.121-0.1,0.229-0.229,0.316-0.396c0.002-0.031-0.002-0.056-0.001-0.085C298.452,298.195,297.631,296.908,294.933,297.142z
		 M294.315,297.249c-0.017-0.004-0.034-0.007-0.051-0.012C294.281,297.242,294.299,297.245,294.315,297.249z M294.576,299.78
		c-0.004,0-0.008-0.001-0.013-0.001C294.568,299.779,294.572,299.779,294.576,299.78z"/>
		</g>
		<g id="eyebrow1">
			<path fillRule="evenodd" clipRule="evenodd" fill="#39261D" d="M293.013,234.981c5.904-3.011,11.171-8.733,19.92-9.36
		c5.862-0.42,11.477,0.868,16.319,2.64c-2.361-3.317-5.559-7.136-9.84-7.92c-5.147-0.943-9.1,1.342-13.68,2.88
		c-5.195,1.744-9.908,3.194-12.48,6.24C293.82,231.725,292.216,234.053,293.013,234.981z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#39261D" d="M247.412,239.062c-0.441-3.626-4.515-3.061-8.4-2.88
		c-3.703,0.172-8.199-0.412-10.32,0.24c-3.802,1.167-4.432,5.798-5.04,9.36C227.773,240.548,236.565,237.717,247.412,239.062z"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M292.532,237.622c5.445,0.404,8.064-4.095,12.24-5.76
		c0.858-0.662,1.896-1.143,3.12-1.44c3.804-1.633,9.384-1.202,14.16-0.72c0.84-0.04,1.536,0.063,1.92,0.48
		c1.115,0.564,2.47,0.89,3.84,1.2c0.38,0.02,0.915-0.115,0.96,0.24c0.633,0.407,1.369,0.71,2.4,0.72
		c1.196,0.708,3.075,0.041,3.119-1.2c0.442-0.438,0.128-1.345-0.239-1.68c-0.344-0.936-0.846-1.714-1.44-2.4
		c0.067-0.388-0.227-0.413-0.24-0.72c-3.555-4.205-7.025-8.495-13.92-9.36c-0.8,0-1.6,0-2.399,0c-0.994-0.033-1.854,0.066-2.4,0.48
		c-0.349,0.372-0.937,0.504-1.68,0.48c-8.247,2.186-23.035,6.26-23.76,14.88c-0.173,2.049,0.273,4.254,1.68,5.52
		C291.283,238.612,291.638,237.847,292.532,237.622z M319.412,220.342c4.281,0.784,7.479,4.603,9.84,7.92
		c-4.843-1.772-10.457-3.06-16.319-2.64c-8.749,0.626-14.016,6.349-19.92,9.36c-0.797-0.929,0.808-3.257,0.239-5.52
		c2.572-3.046,7.285-4.496,12.48-6.24C310.313,221.684,314.265,219.399,319.412,220.342z"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M250.54,241.662c2.143-2.206-0.716-6.98-2.648-8.12
		c-3.215-1.896-8.681-1.279-13.416-0.891c0.041-0.001,0.081-0.003,0.122-0.004c-0.042,0.002-0.083,0.003-0.125,0.005
		c0.001,0,0.002,0,0.004,0c-0.029,0.001-0.058,0.002-0.087,0.003c-0.589,0.021-1.175,0.043-1.756,0.065
		c-0.214,0.008-0.432,0.016-0.645,0.024c-0.096,0.003-0.189,0.006-0.285,0.01c-0.863,0.03-1.711,0.057-2.531,0.068
		c-0.475,0.405-1.498,0.262-2.16,0.48c-0.314,0.119-0.613,0.27-0.904,0.436c-0.083,0.046-0.166,0.09-0.247,0.139
		c-0.223,0.139-0.438,0.293-0.648,0.456c-0.143,0.109-0.284,0.222-0.421,0.342c0,0,0,0-0.001,0.001c0,0,0,0,0,0
		c-0.001,0.001-0.003,0.003-0.005,0.004c0.001-0.001,0.003-0.002,0.004-0.004c-2.841,2.201-4.419,5.664-4.739,10.387
		c-0.398,0.336-0.398,2.304,0,2.64c0.78,2.029-0.569,3.368,0.72,4.8c2.374-0.126,3.578-2.417,4.8-3.84
		c2.652-3.09,5.083-5.672,9.84-6.48c4.446-1.547,8.983,0.252,13.92,0.24c0.444-0.166,0.787-0.391,1.07-0.647
		c0,0-0.001,0-0.001,0.001c0,0,0.001-0.001,0.002-0.001c0,0,0,0,0,0c0.043-0.024,0.092-0.043,0.13-0.073
		C250.535,241.688,250.537,241.675,250.54,241.662C250.54,241.662,250.54,241.662,250.54,241.662 M249.557,242.175
		c0.012-0.009,0.027-0.015,0.041-0.023C249.584,242.161,249.569,242.167,249.557,242.175z M228.692,236.421
		c2.121-0.651,6.617-0.067,10.32-0.24c3.885-0.181,7.959-0.747,8.4,2.88c-10.847-1.345-19.639,1.486-23.76,6.72
		C224.26,242.219,224.891,237.589,228.692,236.421z"/>
		</g>
		<g id="mouth2" display="none">
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M276.33,311.603c0,0,0.001,0,0.001,0
		c-0.203,0.023-0.4,0.045-0.602,0.068c-1.697,0.188-3.358,0.363-4.959,0.621c-1.252,0.073-2.36,0.149-3.438,0.491
		c-1.431,0.454-2.853,1.454-4.56,2.16c-2.811,1.164-10.759,6.708-7.2,9.72c6.136-2.344,10.871-9.089,18.72-9.72
		c1.287-0.313,2.506-0.694,4.08-0.72c5.545-0.102,9.506-2.787,14.257-4.226c0.659-0.181,1.33-0.35,2.01-0.51
		c0.64-0.13,1.293-0.24,1.974-0.304c3.737-0.354,12.469,1.865,16.76,1.28c0.08,0,0.16,0,0.24,0c0.24,0,0.48,0,0.72,0
		c0.952-0.089,2.301,0.22,2.88-0.24c1.443-0.739,0.695-2.34-0.239-3.12c-3.021,0.428-12.367-1.708-16.521-1.52
		c-1.825,0.083-3.592,0.336-5.327,0.693c-0.5,0.059-0.988,0.126-1.457,0.21c-0.478,0.084-0.938,0.182-1.376,0.297
		c-0.42,0.111-0.852,0.251-1.292,0.408c-0.05,0.018-0.104,0.041-0.153,0.059c-0.399,0.146-0.805,0.303-1.219,0.475
		c-4.347,1.337-8.647,2.956-13.296,3.878 M294.652,309.483"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M298.933,299.142c-0.336-0.397-6.304-2.397-6.641-2
		c-7.487-0.061-8.264,9.107-16.56,6c-2.281-0.304-4.078-0.593-6.24,0c-3.005,0.402-10.949,7.287-10.36,10.16
		c2.86-1.54,5.38-6.419,8.44-7.76c0.56,0,1.12,0,1.68,0c1.686-0.635,4.243-0.565,6.24-0.24c1.168,0.192,2.3,0.42,3.12,0.96
		c6.211,1.456,8.583-6.609,13.92-6.48c-0.039-0.394,1.479-0.394,1.44,0c1.765,0.974,7.122,2.627,7.764,1.916
		c0.121-0.1,0.229-0.229,0.316-0.396c0.002-0.031-0.002-0.056-0.001-0.085C302.452,300.195,301.631,298.908,298.933,299.142z
		 M298.315,299.249c-0.017-0.004-0.034-0.007-0.051-0.012C298.281,299.242,298.299,299.245,298.315,299.249z M298.576,301.78
		c-0.004,0-0.008-0.001-0.013-0.001C298.568,301.779,298.572,301.779,298.576,301.78z"/>
		</g>
		<g id="eyebrow2" display="none">
			<path display="inline" fillRule="evenodd" clipRule="evenodd" fill="#39261D" d="M285.846,236.288
		c5.904-3.011,16.338-7.04,25.087-7.666c5.862-0.42,11.477,0.868,16.319,2.64c-2.361-3.317-5.559-7.136-9.84-7.92
		c-5.147-0.943-9.1,1.342-13.68,2.88c-5.195,1.744-15.075,1.5-17.647,4.546C286.653,233.031,285.049,235.359,285.846,236.288z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" fill="#39261D" d="M253.688,240.119
		c-0.441-3.626-8.791-1.118-12.676-0.937c-3.703,0.172-8.199-0.412-10.32,0.24c-3.802,1.167-4.432,5.798-5.04,9.36
		C229.773,243.548,242.842,238.774,253.688,240.119z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M285.365,238.928c5.445,0.404,13.231-2.401,17.407-4.066
		c0.858-0.662,1.896-1.143,3.12-1.44c3.804-1.633,9.384-1.202,14.16-0.72c0.84-0.04,1.536,0.063,1.92,0.48
		c1.115,0.564,2.47,0.89,3.84,1.2c0.38,0.02,0.915-0.115,0.96,0.24c0.633,0.407,1.369,0.71,2.4,0.72
		c1.196,0.708,3.075,0.041,3.119-1.2c0.442-0.438,0.128-1.345-0.239-1.68c-0.344-0.936-0.846-1.714-1.44-2.4
		c0.067-0.388-0.227-0.413-0.24-0.72c-3.555-4.205-7.025-8.495-13.92-9.36c-0.8,0-1.6,0-2.399,0c-0.994-0.033-1.854,0.066-2.4,0.48
		c-0.349,0.372-0.937,0.504-1.68,0.48c-8.247,2.186-28.202,4.566-28.927,13.187c-0.173,2.049,0.273,4.254,1.68,5.52
		C284.116,239.918,284.471,239.153,285.365,238.928z M317.412,223.342c4.281,0.784,7.479,4.603,9.84,7.92
		c-4.843-1.772-10.457-3.06-16.319-2.64c-8.749,0.626-19.183,4.655-25.087,7.666c-0.797-0.929,0.808-3.257,0.239-5.52
		c2.572-3.046,12.452-2.802,17.647-4.546C308.313,224.684,312.265,222.399,317.412,223.342z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M256.816,242.719c2.143-2.206-0.716-6.98-2.648-8.12
		c-3.215-1.896-12.957,0.664-17.692,1.052c0.041-0.001,0.081-0.003,0.122-0.004c-0.042,0.002-0.083,0.003-0.125,0.005
		c0.001,0,0.002,0,0.004,0c-0.029,0.001-0.058,0.002-0.087,0.003c-0.589,0.021-1.175,0.043-1.756,0.065
		c-0.214,0.008-0.432,0.016-0.645,0.024c-0.096,0.003-0.189,0.006-0.285,0.01c-0.863,0.03-1.711,0.057-2.531,0.068
		c-0.475,0.405-1.498,0.262-2.16,0.48c-0.314,0.119-0.613,0.27-0.904,0.436c-0.083,0.046-0.166,0.09-0.247,0.139
		c-0.223,0.139-0.438,0.293-0.648,0.456c-0.143,0.109-0.284,0.222-0.421,0.342c0,0,0,0-0.001,0.001c0,0,0,0,0,0
		c-0.001,0.001-0.003,0.003-0.005,0.004c0.001-0.001,0.003-0.002,0.004-0.004c-2.841,2.201-4.419,5.664-4.739,10.387
		c-0.398,0.336-0.398,2.304,0,2.64c0.78,2.029-0.569,3.368,0.72,4.8c2.374-0.126,3.578-2.417,4.8-3.84
		c2.652-3.09,5.083-5.672,9.84-6.48c4.446-1.547,13.259-1.69,18.196-1.703c0.444-0.166,0.787-0.391,1.07-0.647
		c0,0-0.001,0-0.001,0.001c0,0,0.001-0.001,0.002-0.001c0,0,0,0,0,0c0.043-0.024,0.092-0.043,0.13-0.073
		C256.812,242.745,256.813,242.732,256.816,242.719C256.816,242.719,256.816,242.719,256.816,242.719 M255.833,243.232
		c0.012-0.009,0.027-0.015,0.041-0.023C255.861,243.218,255.846,243.224,255.833,243.232z M230.692,239.421
		c2.121-0.651,6.617-0.067,10.32-0.24c3.885-0.181,12.235-2.689,12.676,0.937c-10.847-1.345-23.915,3.429-28.036,8.663
		C226.26,245.219,226.891,240.589,230.692,239.421z"/>
		</g>
		<g id="mouth3" display="none">
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M276.33,310.603c0,0,0.001,0,0.001,0
		c-0.203,0.023-0.4,0.045-0.602,0.068c-1.697,0.188-3.358,0.363-4.959,0.621c-1.252,0.073-2.36,0.149-3.438,0.491
		c-1.431,0.454-2.735,0.871-4.56,1.16c-3.275,0.519-10.759-0.292-7.2,2.72c5.553,0,6.844,0.031,18.72-1.72
		c1.287-0.313,2.506-0.694,4.08-0.72c5.545-0.102,9.506-2.787,14.257-4.226c0.659-0.181,1.33-0.35,2.01-0.51
		c0.64-0.13,1.293-0.24,1.974-0.304c3.41-0.323,10.949-4.2,14.76-4.72c0.08,0,0.16,0,0.24,0c0.24,0,0.48,0,0.72,0
		c0.952-0.089,2.301-0.78,2.88-1.24c1.443-0.739,0.695-2.34-0.239-3.12c-3.021,0.428-10.367,5.292-14.521,5.48
		c-1.825,0.083-3.592,0.336-5.327,0.693c-0.5,0.059-0.988,0.126-1.457,0.21c-0.478,0.084-0.938,0.182-1.376,0.297
		c-0.42,0.111-0.852,0.251-1.292,0.408c-0.05,0.018-0.104,0.041-0.153,0.059c-0.399,0.146-0.805,0.303-1.219,0.475
		c-4.347,1.337-8.647,2.956-13.296,3.878 M294.652,308.483"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M294.933,295.142c-0.336-0.397-2.304-0.397-2.641,0
		c-7.487-0.061-8.264,9.107-16.56,6c-2.281-0.304-4.078-0.593-6.24,0c-3.004,0.402-9.949,3.287-9.36,6.16
		c3.051-1.642,4.144-2.316,7.44-3.76c0.56,0,1.12,0,1.68,0c1.686-0.635,4.243-0.565,6.24-0.24c1.168,0.192,2.3,0.42,3.12,0.96
		c6.211,1.456,8.583-6.609,13.92-6.48c-0.039-0.394,1.479-0.394,1.44,0c1.765,0.974,3.122,0.627,3.764-0.084
		c0.121-0.1,0.229-0.229,0.316-0.396c0.002-0.031-0.002-0.056-0.001-0.085C298.452,296.195,297.631,294.908,294.933,295.142z
		 M294.315,295.249c-0.017-0.004-0.034-0.007-0.051-0.012C294.281,295.242,294.299,295.245,294.315,295.249z M294.576,297.78
		c-0.004,0-0.008-0.001-0.013-0.001C294.568,297.779,294.572,297.779,294.576,297.78z"/>
		</g>
		<g id="eyebrow3" display="none">
			<path display="inline" fillRule="evenodd" clipRule="evenodd" fill="#39261D" d="M293.013,232.981
		c5.904-3.011,11.171-8.733,19.92-9.36c5.862-0.42,11.477,0.868,16.319,2.64c-2.361-3.317-5.559-7.136-9.84-7.92
		c-5.147-0.943-9.1,1.342-13.68,2.88c-5.195,1.744-9.908,3.194-12.48,6.24C293.82,229.725,292.216,232.053,293.013,232.981z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" fill="#39261D" d="M247.412,237.062
		c-0.441-3.626-4.515-3.061-8.4-2.88c-3.703,0.172-8.199-0.412-10.32,0.24c-3.802,1.167-4.432,5.798-5.04,9.36
		C227.773,238.548,236.565,235.717,247.412,237.062z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M292.532,235.622c5.445,0.404,8.064-4.095,12.24-5.76
		c0.858-0.662,1.896-1.143,3.12-1.44c3.804-1.633,9.384-1.202,14.16-0.72c0.84-0.04,1.536,0.063,1.92,0.48
		c1.115,0.564,2.47,0.89,3.84,1.2c0.38,0.02,0.915-0.115,0.96,0.24c0.633,0.407,1.369,0.71,2.4,0.72
		c1.196,0.708,3.075,0.041,3.119-1.2c0.442-0.438,0.128-1.345-0.239-1.68c-0.344-0.936-0.846-1.714-1.44-2.4
		c0.067-0.388-0.227-0.413-0.24-0.72c-3.555-4.205-7.025-8.495-13.92-9.36c-0.8,0-1.6,0-2.399,0c-0.994-0.033-1.854,0.066-2.4,0.48
		c-0.349,0.372-0.937,0.504-1.68,0.48c-8.247,2.186-23.035,6.26-23.76,14.88c-0.173,2.049,0.273,4.254,1.68,5.52
		C291.283,236.612,291.638,235.847,292.532,235.622z M319.412,218.342c4.281,0.784,7.479,4.603,9.84,7.92
		c-4.843-1.772-10.457-3.06-16.319-2.64c-8.749,0.626-14.016,6.349-19.92,9.36c-0.797-0.929,0.808-3.257,0.239-5.52
		c2.572-3.046,7.285-4.496,12.48-6.24C310.313,219.684,314.265,217.399,319.412,218.342z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M250.54,239.662c2.143-2.206-0.716-6.98-2.648-8.12
		c-3.215-1.896-8.681-1.279-13.416-0.891c0.041-0.001,0.081-0.003,0.122-0.004c-0.042,0.002-0.083,0.003-0.125,0.005
		c0.001,0,0.002,0,0.004,0c-0.029,0.001-0.058,0.002-0.087,0.003c-0.589,0.021-1.175,0.043-1.756,0.065
		c-0.214,0.008-0.432,0.016-0.645,0.024c-0.096,0.003-0.189,0.006-0.285,0.01c-0.863,0.03-1.711,0.057-2.531,0.068
		c-0.475,0.405-1.498,0.262-2.16,0.48c-0.314,0.119-0.613,0.27-0.904,0.436c-0.083,0.046-0.166,0.09-0.247,0.139
		c-0.223,0.139-0.438,0.293-0.648,0.456c-0.143,0.109-0.284,0.222-0.421,0.342c0,0,0,0-0.001,0.001c0,0,0,0,0,0
		c-0.001,0.001-0.003,0.003-0.005,0.004c0.001-0.001,0.003-0.002,0.004-0.004c-2.841,2.201-4.419,5.664-4.739,10.387
		c-0.398,0.336-0.398,2.304,0,2.64c0.78,2.029-0.569,5.368,0.72,6.8c2.374-0.126,3.578-4.417,4.8-5.84
		c2.652-3.09,5.083-5.672,9.84-6.48c4.446-1.547,8.983,0.252,13.92,0.24c0.444-0.166,0.787-0.391,1.07-0.647
		c0,0-0.001,0-0.001,0.001c0,0,0.001-0.001,0.002-0.001c0,0,0,0,0,0c0.043-0.024,0.092-0.043,0.13-0.073
		C250.535,239.688,250.537,239.675,250.54,239.662C250.54,239.662,250.54,239.662,250.54,239.662 M249.557,240.175
		c0.012-0.009,0.027-0.015,0.041-0.023C249.584,240.161,249.569,240.167,249.557,240.175z M228.692,234.421
		c2.121-0.651,6.617-0.067,10.32-0.24c3.885-0.181,7.959-0.747,8.4,2.88c-10.847-1.345-19.639,1.486-23.76,6.72
		C224.26,240.219,224.891,235.589,228.692,234.421z"/>
		</g>
		<g id="lighting_2_">
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M276.212,141.381c0.38-0.02,0.916,0.115,0.96-0.24
		c-0.96,0-1.92,0-2.88,0C274.471,141.683,275.639,141.235,276.212,141.381z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M203.252,208.341c0-0.16,0-0.32,0-0.48
		c-0.547,0.333-0.089,1.671-0.24,2.4C203.553,210.083,203.106,208.916,203.252,208.341z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M301.172,322.582c-0.166,0.474-0.64,0.64-1.199,0.72
		C300.158,324.473,301.881,322.972,301.172,322.582z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M300.212,331.702c-0.558-1.522-1.118-3.042-1.439-4.8
		c-0.638,0.002-1.371-0.091-1.68,0.24c0.158,2.579,1.572,5.935,3.84,7.2C302.371,334.33,299.722,333.071,300.212,331.702z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M364.053,386.662c-4.069-1.611-8.624-2.736-12.24-4.8
		c-0.27-0.051-0.374-0.266-0.48-0.48c-1.129-1.079-0.84-2.096-0.96-4.08c0.153-0.887-0.311-2.391,0.24-2.88c0-1.04,0-2.08,0-3.12
		c0.154-0.966-0.312-2.552,0.24-3.12c-0.069-2.549,0.424-4.536,0.24-7.2c0-1.36,0-2.72,0-4.08c-0.558-1.442-0.082-3.918-0.24-5.76
		c-1.402,0.278-1.903,1.457-3.36,1.68c-0.502,0.458-1.146,0.774-1.92,0.96c-1.261,1.299-3.508,1.612-5.28,2.4c0,1.76,0,3.52,0,5.28
		c0,0.32,0,0.64,0,0.96c0.156,1.125-0.313,2.874,0.24,3.6c-0.644,2.557-1.499,4.901-1.2,8.4c-0.56,5.28-0.08,11.6-0.239,17.28
		c0.286,1.349-0.125,2.319,0,4.08c0.152,0.887-0.312,2.391,0.239,2.88c2.196,4.056,4.623-1.558,6.96-2.4
		c2.585-3.071,4.548,0.601,6.48,2.16c0.08,0,0.16,0,0.24,0c0.756,1.164,2.095,1.746,3.12,2.64c1.173,0.426,1.938,1.262,3.119,1.68
		c0.715,0.646,1.791,0.93,2.641,1.44c1.779-1.079,2.711-3.91,4.08-6.96c1.382-3.078,3.627-6.226,3.12-8.16
		C367.307,388.368,365.721,387.474,364.053,386.662z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M411.333,582.502c0.114,0.756-0.342,0.938-0.48,1.44
		c-0.904,1.575-1.415,3.545-1.92,5.52c0.095,0.809-1.561-0.149-1.68,0.96c-0.261,0.06-0.163,0.478-0.48,0.48
		c0.16,6.08-0.319,12.8,0.24,18.48c0.284,2.436,1.62,3.819,1.92,6.239c0.923,1.718,1.173,4.107,2.88,5.04
		c-0.01-1.833-0.971-3.984-0.479-5.52c-0.202-0.599-0.314-1.286-0.24-2.16c-0.241-1.125-0.886-3.107-0.48-4.08
		c-0.145-0.575-0.345-1.095-0.239-1.92c-0.029-2.028,0.014-3.986,0.479-5.521c-0.016-1.216,0.412-1.987,0.24-3.359
		c0.421-1.579-0.04-4.04,0.479-5.521c0.158-1.603-0.316-3.836,0.24-5.04c0.157-1.522-0.316-3.676,0.24-4.8
		C412.218,581.943,411.557,582.355,411.333,582.502z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M499.893,598.822c0.08,0,0.16,0,0.24,0c0-3.6,0-7.2,0-10.8
		C499.573,591.143,500.052,595.302,499.893,598.822z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M457.173,773.063c0,0.08,0,0.16,0,0.24
		c0.887-0.153,2.391,0.311,2.88-0.24c-0.4,0-0.8,0-1.2,0C458.293,773.063,457.732,773.063,457.173,773.063z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M434.373,773.543c0.729-0.15,2.066,0.307,2.399-0.24
		c-0.8,0-1.6,0-2.399,0C434.373,773.383,434.373,773.463,434.373,773.543z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M64.772,773.543c-0.48,0-0.96,0-1.44,0
		c0.647,0.553,2.314,0.085,3.36,0.239C66.513,773.241,65.346,773.688,64.772,773.543z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M400.532,773.543c0,0.08,0,0.16,0,0.239
		c2.562-0.158,5.759,0.319,7.92-0.239c-0.72,0-1.439,0-2.159,0C404.372,773.543,402.452,773.543,400.532,773.543z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M111.092,774.022c0,0.08,0,0.16,0,0.24
		c0.887-0.153,2.391,0.311,2.88-0.24c-0.4,0-0.8,0-1.2,0C112.212,774.022,111.652,774.022,111.092,774.022z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M324.692,157.941c0,0.08,0,0.16,0,0.24
		c1.004,0.756,1.538,1.982,2.16,3.12c0,0.08,0,0.16,0,0.24c1.88,2.84,3.104,6.336,4.32,9.84c2.362,7.557,3.813,16.027,4.56,25.2
		c0.56,4,0.08,9.04,0.24,13.44c0,0.08,0,0.16,0,0.24c1.703,0.776,3.271,1.688,4.8,2.64c3.013,0.391,4.402,2.705,7.92,1.92
		c0.854,0.506,1.176,1.545,1.92,2.16c0.411,0.309,0.944,0.497,0.96,1.2c0.723,0.334,0.511,1.747,0,2.16
		c-0.217,0.423-0.537,0.743-0.96,0.96c-1.997,2.812,0.425,8.4,0.72,12c0,0.4,0,0.8,0,1.2c0.548,0.333,0.09,1.67,0.24,2.4
		c0.038,0.283-0.041,0.682,0.24,0.72c0.013-0.307,0.308-0.333,0.24-0.72c0.128-2.032,0.571-3.748,1.199-5.28
		c0.385-2.255,1.36-3.92,1.44-6.48c1.663-0.817,1.924-3.036,2.88-4.56c-1.8-0.2-2.543-1.457-2.399-3.6
		c-0.52-0.44-0.506-1.415-0.48-2.4c-0.061-0.819-0.606-1.153-0.479-2.16c-0.38-0.501-0.498-1.262-0.48-2.16
		c-0.296-1.145-0.917-1.963-0.96-3.36c-0.423-0.697-0.547-1.693-0.72-2.64c-0.525-0.515-0.759-1.322-0.721-2.4
		c-0.379-0.66-0.414-1.665-0.72-2.4c-0.021-0.78-0.124-1.476-0.479-1.92c-0.38-0.501-0.498-1.262-0.48-2.16
		c-0.866-0.813-0.312-3.048-1.2-3.84c-0.54-0.58-0.507-1.732-0.72-2.64c-0.857-0.502-0.212-2.508-0.96-3.12
		c-0.54-0.82-0.769-1.952-0.96-3.12c-0.723-0.557-0.875-1.685-1.2-2.64c-0.355-0.204-0.516-0.604-0.479-1.2
		c-1.086-1.475-1.753-3.367-2.641-5.04c-0.322-0.158-0.446-0.513-0.479-0.96c-1.047-0.953-1.783-2.217-2.4-3.6
		c-1.074-1.006-1.654-2.506-2.64-3.6c-1.707-1.573-3.394-3.167-5.04-4.8c-0.547-0.333-0.93-0.83-1.44-1.2
		c-1.54-1.18-3.212-2.228-4.56-3.6c-1.436-0.136-2.292-1.958-3.36-1.68c0,0.16,0,0.32,0,0.48
		C322.713,155.761,323.753,156.801,324.692,157.941z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M339.572,277.222c1.792-7.994,2.093-16.187,2.16-26.16
		c0.016-2.368-0.733-5.296,0.96-7.2c1.054-0.066,0.788-1.452,1.44-1.92c0.756-0.924,1.15-2.209,2.64-2.4
		c0.135-0.346,0.374-0.585,0.72-0.72c0.001-0.429-0.646-1.396,0-1.68c0.129-0.928-0.428-1.172-0.24-2.16
		c-0.53-2.989-1.309-5.731-1.439-9.12c-0.611-1.229-0.469-3.211-0.48-5.04c-0.279-0.041-0.26-0.379-0.239-0.72
		c-0.688-0.432-1.608-0.632-2.16-1.2c-0.83-0.21-1.663-0.417-2.16-0.96c-1.002-0.278-1.791-0.769-2.64-1.2
		c-1.145-0.456-2.322-0.878-3.12-1.68c-1.792-0.928-3.307-2.134-5.04-3.12c-0.241,0.001-0.286-0.194-0.48-0.24
		c-0.918-0.282-1.311-2.099-2.399-1.44c0.592,2.872,1.882,5.114,2.88,7.68c0.974,2.503,2.275,4.972,2.399,8.4
		c0.014,0.307,0.308,0.332,0.24,0.72c0.595,0.686,1.097,1.464,1.44,2.4c0.367,0.335,0.682,1.242,0.239,1.68
		c-0.044,1.241-1.923,1.909-3.119,1.2c-1.031-0.01-1.768-0.313-2.4-0.72c-0.16,0-0.32,0-0.48,0c-0.711,2.655-0.97,6.475-3.359,6.72
		c1.056,0.464,2.427,0.613,2.16,2.4c-0.308,0.013-0.333,0.308-0.721,0.24c-1.496,0.056-2.488-0.391-3.6-0.72
		c0.001,0.241-0.194,0.286-0.24,0.48c2.024,2.855,5.902,3.858,9.36,5.28c1.804-3.476,2.14-8.419,6.72-9.12
		c0.04,5.421-0.995,10.43-0.96,15.36c0.028,3.985-1.271,6.192-2.4,9.6c-0.822,2.486-1.958,4.899-2.64,7.2
		c-2.418,8.16-3.177,16.055-3.6,25.68c-0.146,3.314-0.096,6.858,0.239,10.56c0.22-0.421,0.083-1.198,0.48-1.44
		c0.419-1.021,0.641-2.239,0.72-3.6c0.021-1.42,0.242-2.638,0.721-3.6c0.217-2.103,0.685-3.955,1.439-5.52
		c0.104-1.496,0.542-2.658,1.2-3.6c0.054-0.186,0.007-0.473,0.24-0.48c0.509-2.371,1.736-4.023,2.64-6
		C337.421,279.55,338.557,278.446,339.572,277.222z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M309.332,307.462c-3.701,0.927-4.016,5.965-6.479,9.12
		c-1.245,1.594-4.083,3.279-3.84,4.08c0.007,0.022,0.218-0.018,0.239,0c0.622-0.258,0.893-0.868,1.681-0.96
		c0.208-0.351,0.359-0.761,0.96-0.72c0.666-0.773,1.418-1.462,2.64-1.68c0.217-0.58,1.354-0.103,1.44,0.24
		c2.886-2.634,4.733-6.306,6.239-10.32C311.633,307.682,310.284,307.373,309.332,307.462z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M327.093,250.342c-0.08,0-0.16,0-0.24,0
		c-0.27,0.051-0.374,0.266-0.48,0.48c-0.507,1.813-1.26,3.379-2.88,4.08c-0.099,0.302-0.31,0.491-0.72,0.48
		c-1.77,0.603-3.352,3.408-1.44,5.04c0.692-0.108,0.751-0.85,1.681-0.72c0.455-0.75,1.142,1.012,0.479,1.44
		c2.953-2.087,4.478-5.603,5.76-9.36C329.056,250.778,328.015,250.62,327.093,250.342z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M295.412,278.422c-1.052-0.628-2.002-1.358-2.88-2.16
		c-0.911-0.128-1.058-1.022-1.2-1.92c0.88-1.924,3.701-0.463,4.561,0.48c1.721,0.677,5.382-11.186,2.16-12
		c-0.447-0.033-0.803-0.158-0.96-0.48c-1.828-0.653-3.329-1.632-4.561-2.88c-1.259-0.581-2.217-1.463-3.12-2.4
		c-1.473-0.927-2.867-1.933-3.12-4.08c-0.861-4.142,0.911-7.728,0.72-12c-1.304-0.616-3.202-0.638-4.32-1.44
		c-4.158,3.084-6.623,6.059-7.2,11.52c-0.715,6.768,0.725,13.545,2.64,17.52c1.383,2.87,4.398,5.436,4.56,8.64
		c0.295,5.848-4.893,8.757-4.32,14.16c2.284-0.356,2.606-2.674,2.88-5.04c1.446-0.954,2.082-2.718,4.32-2.88
		c0.902-0.538,2.026-0.854,3.36-0.96c2.069-0.932,4.956-1.256,5.041,0.96c0.06,1.576-1.701,0.948-1.681,2.88
		c2.819-0.647,5.513-3.621,3.841-7.2C295.998,278.796,295.758,278.556,295.412,278.422z M286.052,278.422
		c-3.02-0.26-2.414-4.146-4.08-5.76c-0.295-0.104-0.493-0.307-0.48-0.72c-0.356-0.204-0.516-0.604-0.48-1.2
		c0.214-0.106,0.429-0.211,0.48-0.48c1.397-0.197,1.874,0.526,2.4,1.2c0,0.08,0,0.16,0,0.24c0.295,0.104,0.493,0.307,0.48,0.72
		C285.489,273.729,286.643,276.198,286.052,278.422z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M339.813,325.222c0.146-0.973,0.828-1.412,0.72-2.64
		c0.341,0.021,0.68,0.04,0.72-0.24c0.08,0,0.16,0,0.24,0c0.527-0.913,0.899-1.98,1.681-2.64c0.274-0.845,0.527-1.712,1.199-2.16
		c0.724-1.836,1.617-3.504,2.641-5.04c0.422-1.338,1.114-2.406,1.68-3.6c0.51-0.37,0.102-1.658,0.72-1.92
		c0.247-1.674,0.555-3.286,1.2-4.56c1.741-2.659,0.29-8.51,3.601-9.6c0-0.4,0-0.8,0-1.2c-0.663-3.48,1.016-5.568,0.72-7.92
		c-0.55-0.2-0.657-1.475-0.24-1.92c0-3.36,0-6.72,0-10.08c-0.371-0.349-0.211-1.229-0.24-1.92c-0.654-0.946-0.224-2.976-0.72-4.08
		c-0.445-5.234-1.728-9.632-2.64-14.4c-0.421-0.46-0.509-1.251-0.721-1.92c-0.436-1.307-2.173-4.556-0.239-5.28
		c-0.041-0.521,0.851-0.109,1.199-0.24c0-0.32,0-0.64,0-0.96c0.036-0.658,0.045-1.237-0.479-0.72c0.369,1.04-1.617,0.676-2.16,0.96
		c-1.952,1.021-1.76,3.681-2.4,6.24c-0.471,1.882-0.842,5.016-0.479,8.16c-0.158,1.922,0.317,4.478-0.24,6
		c-0.017,4.114-0.483,7.014-0.96,11.04c0.592,1.955-0.215,4.583-0.72,6.24c-0.561,1.732-0.083,3.053-0.72,4.56
		c-0.476,0.804-0.79,1.771-0.96,2.88c-0.332,2.389-1.075,4.365-1.44,6.72c-0.559,1.762-0.081,4.559-0.24,6.72c0,0.16,0,0.32,0,0.48
		c-0.547,0.333-0.089,1.67-0.24,2.4c-0.621,9.995-3.274,18.669-5.52,25.68c0.143,0.497-0.297,1.577,0.24,1.68
		c0.523-0.756,0.652-1.907,1.439-2.4c0.184-0.696,0.38-1.381,0.96-1.68c0.258-1.183,1.438-1.442,2.16-2.16
		C339.626,325.516,339.579,325.229,339.813,325.222z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M349.173,318.742c-1.475-1.524-1.596,1.303-2.641,1.68
		c0.039,0.678-0.721,0.559-0.72,1.2c-1.165,2.957-2.909,4.753-3.12,8.16c-0.447,1.713-1.47,2.85-1.92,4.56
		c0.055,4.394-2.093,10.846,0.96,13.44c0.053-0.187,0.201-0.279,0.48-0.24c0.588-0.452,1.174-0.906,2.159-0.96
		c0.502-0.458,1.146-0.774,1.92-0.96c0.54-0.66,1.698-0.702,2.4-1.2c0.048-5.207,0.065-11.266,0.48-15.36
		c-0.399-1.284-0.399-4.235,0-5.52C349.173,321.942,349.173,320.342,349.173,318.742z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M375.093,244.582c-0.254-2.227-0.562-4.399-0.96-6.48
		c-0.514-1.086-0.534-2.666-0.96-3.84c-1.161-3.479-2.393-6.888-3.601-10.32c-0.547-0.813-1.186-1.534-1.439-2.64
		c-0.937-0.824-1.468-2.053-3.36-1.92c-3.578,8.6-8.743,18.5-7.68,32.16c0.104,1.335,0.293,2.94,0.72,4.08
		c0.788,2.106,2.552,3.329,2.88,5.28c1.075,6.387-3.287,12.251-2.16,19.68c0,0.8,0,1.6,0,2.4c0,0.08,0,0.16,0,0.24
		c0.033,1.504-0.967,3.767,0.96,3.84c4.072,0.152,5.951-1.889,7.92-3.84c0.228-1.053,0.898-1.662,1.44-2.4
		c0.404-0.796,0.388-2.012,0.96-2.64c0.037-0.282-0.041-0.681,0.24-0.72c-0.029-2.345,0.518-4.297,1.2-6.48
		c0.504-1.415,0.846-2.993,1.68-4.08c0.308-2.093,1.356-3.443,1.68-5.52c-0.069-1.27,0.621-1.779,0.48-3.12
		c-0.118-0.758,0.433-0.848,0.239-1.68c-0.628-0.8,0.342-2.05,0-3.36c-0.173-0.733,0.246-0.875,0.24-1.44
		c-0.398-0.492-0.398-2.628,0-3.12c0-0.4,0-0.8,0-1.2C374.923,246.991,375.719,245.075,375.093,244.582z M372.692,242.662
		c-1.968-0.032-2.018-1.983-3.12-2.88c1.585-3.504-1.011-6.337-3.6-7.2c-0.9,0.62-0.939,2.101-1.44,3.12
		c0.627,1.534,2.704,1.616,3.12,3.36c0.295,0.105,0.493,0.307,0.48,0.72c0.759,0.601,1.079,1.64,1.439,2.64
		c1.082,1.958,1.964,4.116,2.641,6.48c0.573,1.637,0.629,4.155,0,5.76c0.116,7.957-3.854,11.827-5.521,18
		c-1.069,1.055-3.96,1.893-5.04,0.24c-0.533-0.653-0.149-1.926,0.24-2.4c0.08,0,0.16,0,0.24,0c1.456-4.324,0.498-10.146-0.96-13.68
		c-0.484-0.637-0.895-1.346-1.2-2.16c-4.767-4.454-1.161-12.995,0.72-18.24c0.077-1.203,0.391-2.169,0.96-2.88
		c0.307-1.613,0.884-2.956,1.68-4.08c-0.01-0.41,0.179-0.622,0.48-0.72c0.042-1.478,2.875-0.165,2.4-2.16
		C368.913,231.101,373.686,236.041,372.692,242.662z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M350.853,348.502c0.553-0.328,1.787-1.999,0-1.92
		c-0.927,0.673-1.497,1.702-2.88,1.92c-1.07,0.45-1.916,1.125-3.12,1.44c-0.309,0.652-1.229,0.691-1.44,1.44
		c1.526,0.006,2.116-0.924,3.601-0.96C348.411,349.9,349.461,349.031,350.853,348.502z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M453.093,406.102c-0.28-2.36-1.376-3.905-2.16-5.76
		c-0.735-0.464-1.204-1.196-1.92-1.68c-2.217-0.503-4.188-1.252-6.24-1.92c-0.597-0.523-2.086-0.154-2.64-0.72
		c-2.406-0.135,0.709,0.842,1.2,1.2c1.436,1.047,5.209,5.075,5.279,6.96c0,0.08,0,0.16,0,0.24c0.016,1.585,0.766,2.435,1.92,2.88
		c1.687,0.154,2.737,0.943,4.08,1.44c0.32,0,0.641,0,0.96,0C453.571,407.704,453.016,407.219,453.093,406.102z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M449.492,412.342c-0.551,0.489-0.086,1.993-0.239,2.88
		c0,0.08,0,0.16,0,0.24c-0.754,2.767-0.567,6.473-1.2,9.36c0,0.08,0,0.16,0,0.239c-0.744,2.137-0.8,4.96-0.96,7.681
		c0.063,1.262-0.164,2.235-0.48,3.12c-0.597,1.563-0.762,3.558-0.72,5.76c0,0.08,0,0.16,0,0.24c-0.399,1.284-0.399,4.235,0,5.52
		c0,0.08,0,0.16,0,0.24c0.281,0.039,0.202,0.438,0.24,0.72c0.832-0.832,0.603-3.984,1.439-5.52c0.698-0.183-0.218-1.979,0.48-2.16
		c-0.033-0.993,0.066-1.854,0.479-2.4c0.041-0.358-0.085-0.886,0.24-0.96c0.391-2.741,0.52-5.092,1.44-7.68
		c0.386-0.813,0.491-1.908,0.479-3.12c0.176-3.825,0.337-7.663,0.721-11.28C451.352,413.683,450.994,412.44,449.492,412.342z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M442.772,463.462c-0.24,0-0.479,0-0.72,0
		c-1.32,0.12-1.24,1.641-1.2,3.12c0,0.08,0,0.16,0,0.24c-1.36,3.6-1.792,8.127-2.64,12.24c-0.537,3.223-1.139,6.381-1.681,9.6
		c-0.372,0.907-0.468,2.092-0.479,3.36c0,0.08,0,0.159,0,0.239c-0.552,0.568-0.086,2.154-0.24,3.12c0,0.32,0,0.641,0,0.96
		c-0.557,1.124-0.083,3.277-0.24,4.801c0,0.08,0,0.159,0,0.239c-0.395,0.885-0.867,1.692-0.72,3.12
		c0.036,1.717-0.127,3.232-0.479,4.561c-1.182,5.698-2.677,11.084-3.601,17.04c-0.013,1.427-0.583,2.297-0.96,3.359
		c-0.74,1.101-1.099,2.581-1.439,4.08c0,0.08,0,0.16,0,0.24c-0.989,2.676-1.075,6.282-1.921,10.32
		c-0.508,2.429-1.225,8.643-3.119,9.12c-0.075,0.019-0.164-0.016-0.24,0c-1.278-1.559-0.545-4.063-0.24-5.761
		c1.499-8.356,3.445-16.874,2.88-27.12c0-0.08,0-0.159,0-0.239c-0.158-1.763,0.317-4.157-0.24-5.521c0-0.08,0-0.16,0-0.24
		c-0.155-1.124,0.314-2.873-0.239-3.6c-0.223-3.617-1.422-6.259-1.2-10.32c-0.071-1.935-2.636-0.32-1.68,0.721
		c0.131,0.859,0.536,2.348,0,3.119c-0.555,0.806-0.085,2.637-0.24,3.841c0,0.08,0,0.159,0,0.239
		c-1.127,13.594-0.771,28.67-1.681,42.48c-0.077,0.882,0.205,2.125-0.239,2.64c-0.378,1.223-0.472,2.729-0.48,4.32
		c0,0.08,0,0.16,0,0.24c-0.554,0.726-0.084,2.476-0.24,3.6c0,0.08,0,0.16,0,0.24c-0.547,0.333-0.089,1.671-0.239,2.4
		c-0.014,1.106,0.106,2.346-0.24,3.119c-0.988,3.813-3.607,5.993-5.04,9.36c0,0.08,0,0.16,0,0.24c-0.229,0.49-0.49,0.949-0.72,1.439
		c2.034-0.031,3.943,1.333,2.88,3.601c-0.158,1.762,0.317,4.157-0.24,5.52c-0.158,1.683,0.317,3.997-0.24,5.28
		c-0.154,0.966,0.313,2.552-0.24,3.12c-0.155,1.124,0.314,2.874-0.239,3.6c-0.011,1.431-0.104,2.776-0.48,3.841
		c0.053,1.866-0.167,4.007,0.24,5.52c0.839-0.281,0.462-1.778,1.2-2.16c1.029-3.051,1.939-6.22,2.64-9.6
		c0.224-2.336,0.57-4.551,1.44-6.24c0.014-1.187,0.236-2.163,0.72-2.88c-0.066-1.827,0.976-2.545,1.2-4.08
		c0.24-1.04,0.346-2.214,0.72-3.12c-0.057-1.656,0.75-2.449,0.96-3.84c1.36-5.68,1.032-13.048,3.6-17.521
		c0.28-0.04,0.261-0.379,0.24-0.72c0.005-0.956,0.229-1.69,0.721-2.16c0.826-1.813,1.104-4.175,2.159-5.76
		c0.467-1.934,1.182-3.619,1.92-5.28c0.145-1.535,0.657-2.703,1.2-3.84c0.094-0.867,0.318-1.602,0.721-2.16
		c0.101-1.339,0.936-1.944,0.96-3.359c0.436-2.175,0.475-3.283,1.199-5.04c0.28-0.041,0.261-0.38,0.24-0.721
		c-0.058-1.017,0.221-1.699,0.48-2.399c-0.335-0.429,0.151-2.078,0.479-2.641c0.041-0.358-0.086-0.886,0.24-0.96
		c0.188-2.834,0.866-5.811,1.2-8.64c0.234-2.289-0.029-3.594,0.479-5.521c0.148-0.651-0.305-1.904,0.24-2.159
		c-0.098-0.481-0.595-1.78,0-2.16c0.152-0.809-0.31-2.229,0.24-2.641c0-1.04,0-2.08,0-3.12c0-1.119,0-2.239,0-3.359
		c0-0.08,0-0.16,0-0.24c-0.157-1.917,0.212-3.308,0.24-5.04c-0.118-0.758,0.432-0.848,0.239-1.68
		c-0.371-1.019-0.002-3.357,0.24-4.561c0-0.88,0-1.76,0-2.64c0.011-1.43,0.104-2.775,0.48-3.84
		c-0.118-1.078,0.121-1.799,0.239-2.641c-0.454-0.47,0.025-2.043,0.24-2.64c-0.028-1.148,0.068-2.172,0.48-2.88
		c0-0.24,0-0.48,0-0.72C442.133,467.631,443.908,465.208,442.772,463.462z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M495.093,570.502c0-0.8,0-1.6,0-2.399c0-0.16,0-0.32,0-0.48
		c-0.558-1.442-0.082-3.918-0.24-5.76c0-0.16,0-0.32,0-0.48c-0.557-1.203-0.082-3.438-0.24-5.04c0-0.159,0-0.319,0-0.479
		c-0.704-1.192,0.336-2.699,0-4.32c0-0.16,0-0.319,0-0.479c-0.67-1.079,0.034-2.84,0-4.08c0-0.16,0-0.32,0-0.48
		c-0.398-0.57-0.398-2.789,0-3.359c0-0.16,0-0.32,0-0.48c-0.553-0.646-0.085-2.314-0.239-3.36c0-0.079,0-0.159,0-0.239
		c0-0.88,0-1.761,0-2.641c0-0.08,0-0.16,0-0.239c-0.739-0.678,0.249-1.657,0-2.641c0-0.08,0-0.16,0-0.24
		c-0.342-1.656-0.366-3.577,0-5.279c0-0.08,0-0.16,0-0.24c-0.548-0.333-0.09-1.671-0.24-2.4c-0.685-0.546,0.337-1.406,0-2.399
		c-0.397-0.259-0.397-2.142,0-2.4c0-0.08,0-0.16,0-0.24c-0.77-2.54-0.063-5.172-0.72-7.92c0.08,0,0.159,0,0.239,0
		c0.007-1.446-0.409-2.471-0.239-4.08c-0.188-2.341-0.424-5.092-0.48-8.16c-0.683-4.356-0.655-9.424-1.2-13.92
		c-0.233-2.406-0.569-4.71-0.72-7.199c-0.41-0.95-0.266-2.455-0.479-3.601c-0.547-1.534-0.712-3.448-0.961-5.28
		c-1.029-5.449-1.915-11.044-3.119-16.319c-1.939-7.341-5.002-13.56-7.44-20.4c-0.39-0.41-0.727-0.873-0.72-1.68
		c-0.459-0.502-0.774-1.146-0.96-1.92c-0.492-0.389-0.605-1.155-0.96-1.681c-0.824-1.176-1.502-2.498-2.16-3.84
		c-0.399-0.48-0.871-0.889-0.96-1.68c-0.817-0.543-1.055-1.666-1.681-2.4c-0.85-0.43-1.021-1.538-2.159-1.68
		c-1.821-0.339-3.192-1.128-4.561-1.92c-2.673-0.928-5.565-1.634-9.12-1.68c0-0.08,0-0.16,0-0.24
		c-0.751,0.128-0.864,0.895-0.96,1.68c0.372,2.509,3.847,1.914,5.04,3.6c0.08,0,0.16,0,0.24,0c2.61,1.95,5.272,3.848,7.2,6.48
		c2.64,3.439,2.703,9.456,3.84,14.4c0.161,1.358,0.399,2.64,0.96,3.6c0.953,3.606,2.089,7.031,2.16,11.521
		c-0.011,1.37,0.092,2.628,0.479,3.6c0,0.08,0,0.16,0,0.24c0.104,2.216,0.55,4.09,1.2,5.76c0.293,0.747,0.559,1.521,0.48,2.64
		c0.046,2.274,0.709,3.931,1.439,5.521c2.104,3.176,3.686,6.874,5.04,10.8c0.287,0.833,0.376,1.864,0.96,2.4c0,0.08,0,0.159,0,0.239
		c0.736,2.704,1.358,5.522,2.16,8.16c0.276,0.284,0.271,0.849,0.24,1.44c0.139,1.062,0.1,2.301,0.479,3.12
		c0.426,0.454,0.175,1.585,0.24,2.399c0.155,1.125-0.313,2.874,0.24,3.601c0.345,1.415,0.498,3.022,0.479,4.8
		c0.152,0.808-0.309,2.229,0.24,2.64c0,0.08,0,0.16,0,0.24c0.153,0.887-0.311,2.391,0.24,2.88c0,0.08,0,0.16,0,0.24
		c0.153,0.887-0.311,2.391,0.24,2.88c0,0.08,0,0.16,0,0.24c0.153,0.966-0.313,2.552,0.239,3.12c0,0.08,0,0.16,0,0.24
		c0.151,0.729-0.307,2.066,0.24,2.399c0.334,1.347,0.479,2.882,0.48,4.561c0.277,2.117-0.61,3.07-0.48,5.04
		c-0.002,0.637,0.091,1.37-0.24,1.68c-0.399,2.642-0.399,6.958,0,9.6c0,0.561,0,1.12,0,1.681c0,1.52,0,3.04,0,4.56
		c0,0.08,0,0.16,0,0.24c-0.291,0.988-0.574,1.985-0.96,2.88c0,0.08,0,0.16,0,0.24c-2.124,2.476-5.642,10.736-0.72,13.92
		c0,0.08,0,0.16,0,0.24c0.583,1.337,0.883,2.957,1.44,4.319c0.426,0.454,0.175,1.585,0.239,2.4c-0.56,3.92-0.08,8.88-0.239,13.2
		c0,1.12,0,2.239,0,3.359c-0.553,0.568-0.086,2.154-0.24,3.12c0,0.561,0,1.12,0,1.68c-0.549,0.411-0.088,1.832-0.24,2.641
		c0,0.479,0,0.96,0,1.439c-0.549,0.411-0.088,1.832-0.24,2.641c0,0.399,0,0.8,0,1.2c-0.552,0.567-0.086,2.153-0.239,3.12
		c0,0.399,0,0.8,0,1.199c-0.553,0.568-0.086,2.154-0.24,3.12c0,0.8,0,1.601,0,2.4c-0.558,1.682-0.082,4.398-0.24,6.479
		c0,0.32,0,0.641,0,0.96c-0.552,0.568-0.086,2.154-0.24,3.12c-0.116,1.004-0.461,1.779-0.72,2.641
		c-0.495,1.265-1.068,2.451-1.439,3.84c-0.201,1.158-0.497,2.223-0.961,3.12c-0.716,0.643-0.831,1.889-0.96,3.12
		c-1.829,4.41-2.864,9.614-2.399,16.319c0.153,0.887-0.311,2.392,0.24,2.88c-0.16,1.439,0.217,2.343,0.239,3.601
		c0.555,0.245,0.497,1.104,0.48,1.92c1.368,0.003,2.978-1.016,4.08-0.48c0.341,0.021,0.68,0.04,0.72-0.239
		c-0.684-1.41,0.003-3.514,0.72-4.32c0.28-0.04,0.261-0.38,0.24-0.72c0.123-1.317,0.724-2.156,1.2-3.12
		c0.536-0.104,0.097-1.183,0.24-1.681c0.248-1.751,0.359-3.64,0.96-5.04c0.203-3.556,1.224-6.296,1.439-9.84
		c0.466-1.534,0.533-3.467,0.96-5.04c0.326-2.634,0.687-5.233,1.2-7.68c-0.015-2.335,0.497-4.144,0.72-6.24
		c0.358-1.081-0.002-2.882,0.48-3.84c0.549-0.411,0.088-1.832,0.24-2.64c0.064-0.815-0.187-1.946,0.239-2.4
		c0.156-1.125-0.313-2.874,0.24-3.6c0-0.32,0-0.641,0-0.96c0.369-0.831,0.212-2.188,0.24-3.36c0.246-7.674,0.318-15.521,0.479-23.28
		c0-1.28,0-2.56,0-3.84c-0.013-0.307-0.308-0.332-0.239-0.72C494.776,574.019,495.25,571.945,495.093,570.502z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M481.652,683.543c-0.549,0.41-0.088,1.832-0.239,2.64
		C481.962,685.771,481.501,684.351,481.652,683.543z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M487.173,750.022c0.399-8,0.399-17.68,0-25.68
		C487.173,732.902,487.173,741.463,487.173,750.022z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M487.173,750.503c-0.399,0.887-0.399,3.433,0,4.319
		C487.173,753.383,487.173,751.942,487.173,750.503z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M487.173,765.862c0.399-2.961,0.399-7.599,0-10.56
		C487.173,758.822,487.173,762.343,487.173,765.862z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M486.692,718.583c-0.128-1.472-0.1-3.101-0.479-4.32
		C486.183,715.893,486.234,717.44,486.692,718.583z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M483.813,705.383c0.582,3.098,1.731,5.629,2.4,8.64
		C485.877,710.679,484.683,708.193,483.813,705.383z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M482.612,700.822c-0.168-1.512-0.249-3.11-0.72-4.319
		C482.002,698.073,482.104,699.65,482.612,700.822z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M473.973,682.343c1.819-2.661,3.091-5.869,4.8-8.641
		c-0.08,0-0.16,0-0.24,0C476.962,676.531,475.392,679.361,473.973,682.343z"/>

			<image overflow="visible" width="87" height="427" id="lighting_3_" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFwAAAGxCAYAAADvQtGwAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABmBJREFUeNrs3VFuWzcQBVBR8CKM
LCTf1Mqtby+k6C7UBAjapIgjWY8ccshz/4oCcXwyvhzqyfbpJCLSLGXHT/rvv76+/fzfr1/eL8D7
Ytf/gRfgQdjA+4LfPvhf16haKRtN9ul30x2NXjZAvgcdil4WxP0scmifl0WAa6s/G/ixigDecp0D
nhw7AvycYMDraaGcTwL8zrqX+mOWycFvIz5uzx4/m+7Yj11Md+w1v0w83cO3kx7VMmulLLUKTg0+
srsjUibEnma6d6iUelo858mme/mUibBnnO7mq+Es4LdZJ7J1j58nme5tUibAnv2gbForo8FvGaay
Za2cB0/3dhnZ4RW46V7v0ExyUHY7OEeA3zJOZquD8zxgurdOdIdX4KZ72QmvuIPAF5juPFf7pGtg
6qu9KokCd1DGT/gK053jic8K3f0d+1t/X7OA37KPdpq3Sejuj/Oiu/v3dtdKWaC7m/d2b/Ab7CDw
5NPdHbsHeNrpjvoRHufG0+2QjJhwVRIPrkqiKkWVBE+46Q6ccFf4+EpJfaMc8UHLwelOCz6iTo5O
uOmOmnDTHT/hdfdJDQOfaDN59oY49B+pPAF+mwX7mWobWSff85Jsui8/0K5ZD+6XT2LXEcA/Tec1
+6XrMxNeo5HvdHQ9+g84bYcHTvfdg/DBn5Q8ZX8/BD7bT8U8cmjPAH6esUoSrKR9OjzoE7w80Ner
vHXu7qHZ+xN86PLSCHuKW2kJnqgP17zel60Z+vvehNfo2li1t++CN/wkn4YecGgPnfA6AXToBjSs
ww92d3Polfr7owl/Gnuiic5RKU9292z1karD64JTPdVToSMPkVNUyGx/x/OTdaKvG0x4hT1fpWTD
vmQGTzfZM/59zzNjH3yJYcr3rJRHbnSjbmrZn+48O+Hp3t2U+dDMupFMOyR/rJTRX5bPVsqsdXJv
wlVJZ/CLy01gpfz4Eq4z7a8rVsqSv20wa4cLcOACHLgABw5cgAMX4MBlA/CZ38S/6oRX4AIc+AY9
vvKEV+ACHPivOfTemBl7vMw+EUe/bXC2J/gqBfja6+EOE16BqxS1AnzRWtmmUmaZ8p06vAJ3aKoV
4G2/12h4rZQkk9nyZ5ZfX7+8X0z4JofnlofmyC7fdUupwK2Fy24qQ2ulZJmMHr9dJd1vGxTgwCUv
+BIHZ8k0HSscnCpFpQAX4HkPThMe/EIWcJUSu4vbwxffxVVK8MEJPPjgBO7QXPvgLBn/0pkPTpUS
fHBmBe9RKyEHZ0k8kSlrRaXYUtbeVkrmaelQK93fWatSgg/O7ODpaqVkH8nWtdJ7U1EpwRcg4ME9
DtwevvbBWVaYmkz7uEoJ7nHgOhz4lgdnr328rDI5WV4fVykqZe19vKw0PRleyFIpKmXtWimrTVDL
WlEpKkWtbF8pjWul+auGq1ZKK6RqwoOnvPXB6dB0aK59eJaVp6lFragUlSLAJ+7xsvpEzfaLl1SK
SpmrVlo/TC47TNVMtaJSVApw66EOj+1xHa5SBDhw2Q386U2l5W2z7DRdM2wqKkWlABfgwAV429XQ
Whi8GloLVcryXxlvwGNTgasUAQ4cuAAH7nrvah9/vT81+L5NlRJ8+QGuw4EL8LybStl10kY9iFAp
KgW4/FpFb8ATXX6AqxTgAhy4AAcuwOMvP8CDLz/AVQrwVTPkIUTZedpGfKOsSlEpwKXhLg48eBcH
rlKAC3DgAhy4AAcOXIADF+DABfjhPPOaOPBjqcBVigD/L+HvTSm7T1z0rw1TKSoFuAAHLsCBC3Dg
GS9Ob8BjU4GrFAEOHLgABy7AgQtw4MAFOHABDlyAAwcun8pnnmsCb5MKXKUIcODABThwAQ5cgAMH
LsCBC3DgAhw4cAE+cx59kAy8XSpwlSLAgQMX4MAFOHABHn+9Bx58vQeuUoAL8LhDDnjwIQdcpQhw
4AIcOHABDlyAAxfgwIELcOACHLgAB75S7j2YBt4+FbhKAS7AgQtw4AIcOHABDlyAAxfgwIELcOAC
HLj8mz89uQfeJxW4SgEuwIEL8EO5RH/AYmf+euv0R19fv7xfTPjgXRy4DgcuwIELcOACHDhwAQ5c
gAPfMb97uwTwvqnAVQpwAQ5cgAMX4MCBC/Bu8dsG41OBqxQBDlyAAxfgA3Z94MG7PnCVAlyAAxfg
wOVbXjb//C9GQEQS5x8BBgAoHDowepzmiAAAAABJRU5ErkJggg==" transform="matrix(0.24 0 0 0.24 472.4756 665.7822)">
			</image>
		</g >
	</svg >
