import React from 'react'
export const Renegade = () =>
	<svg version="1.1" className="character_svg" id="Renegade" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		width="576px" height="828px" viewBox="0 0 576 828" enableBackground="new 0 0 576 828" space="preserve">
		<g id="eye_backing">
			<path fillRule="evenodd" clipRule="evenodd" fill="#EAEAEA" d="M284,277.62c-2.055-0.135-3.679,0.161-5.52,0.24
		c-4.019,0.622-7.064,2.216-10.08,3.84c-2.071,2.088-3.2,6.131-3.84,8.88c2.623-1.136,7.254-0.721,10.56-0.48c1.44,0,2.88,0,4.32,0
		c4.464-0.655,7.512-2.727,10.319-5.04c0.86-1.06,1.82-2.02,2.881-2.88c0.233-0.006,0.186-0.293,0.239-0.48
		C290.155,280.105,287.192,278.748,284,277.62z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#EAEAEA" d="M229.52,291.06c-1.04,0-2.08,0-3.12,0
		c-2.222,0.381-5.773,3.295-6,5.04c-0.402,3.094,3.733,8.145,6.48,8.16c3.541,0.02,6.69-5.97,11.04-5.76
		c0.075-1.612,0.503-3.303,0-4.8C235.564,292.376,232.856,291.404,229.52,291.06z"/>
		</g>
		<g id="eye1">
			<circle fillRule="evenodd" clipRule="evenodd" cx="279.685" cy="282.084" r="7.771" />
		</g>
		<g id="eye2">
			<circle fillRule="evenodd" clipRule="evenodd" cx="232.712" cy="294.994" r="6.894" />
		</g>
		<g id="body">
			<g id="blue">
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M240.32,458.341c0.856-0.903,2.022-1.498,3.12-2.16
			c1.237-0.283,1.88-1.16,2.88-1.68c0.611-0.749,1.546-1.174,2.4-1.681c0.871-0.249,1.444-0.796,2.16-1.2
			c0.874-0.966,2.19-1.489,3.36-2.159c3.232-1.408,5.806-3.474,8.4-5.521c1.096-1.445,3.285-2.713,5.04-4.32
			c1.664-1.523,4.744-3.975,4.32-5.279c-0.792-1.528-1.041-3.599-1.92-5.04c-0.475-0.805-0.789-1.771-0.96-2.88
			c-0.655-0.175-0.483,0.477-0.96,0.479c-4.017,1.983-7.256,4.744-12,6c-0.762,0.357-1.353,0.888-2.4,0.96c0-0.08,0-0.16,0-0.24
			c-2.286,0.034-3.992,0.648-4.8,2.16c-1.02,1.221-2.14,2.341-3.36,3.36c0,0.08,0,0.16,0,0.24c-0.647,1.032-1.415,1.944-1.92,3.12
			c-0.08,0-0.16,0-0.24,0c-2.495,5.265-4.694,10.825-6.24,17.04c0,0.159,0,0.319,0,0.479c0.716-0.004,0.816-0.624,1.44-0.72
			C239.428,459.208,239.698,458.599,240.32,458.341z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M336.08,398.34c-1.443-1.276-3.473-1.967-4.56-3.6
			c0.194,0.046,0.239,0.241,0.479,0.24c-0.505-0.775-1.064-1.495-1.439-2.4c-0.194-0.046-0.239-0.241-0.48-0.24
			c-2.105-4.134-2.192-10.287-4.08-14.64c-1.468,0.452-3.118,0.721-4.8,0.96c-0.08,0-0.16,0-0.24,0
			c-11.729,1.711-19.118,7.762-28.8,11.52c-0.715,0.645-1.79,0.929-2.64,1.44c-4.187,1.813-7.768,4.232-12,6
			c-0.631,0.568-1.61,0.79-2.64,0.96c-0.833-0.192-0.923,0.357-1.68,0.24c0.716,1.444,0.745,3.576,1.2,5.28
			c0.466,1.054,0.598,2.442,0.72,3.84c0.858,6.102,1.997,11.924,3.12,17.76c0.364,0.597,0.463,1.457,0.48,2.4
			c-0.107,3.287,3.367,7.425,0.24,9.36c-2.646,2.475-5.209,5.031-7.92,7.439c-0.08,0-0.16,0-0.24,0c-2.03,2.45-4.717,4.243-7.2,6.24
			c-0.08,0-0.16,0-0.24,0c-1.261,1.459-3.016,2.425-4.56,3.6c-0.08,0-0.16,0-0.24,0c-1.093,1.228-2.668,1.972-4.08,2.88
			c-3.442,1.999-6.966,3.914-10.08,6.24c3.185,0.156,3.501,4.138,2.64,6.96c-0.36,1-0.681,2.039-1.44,2.641
			c-0.204,1.453-2.578,2.358-4.08,3.6c-1.037,0.857-0.809,1.725-2.16,1.92c-1.888,0.273-3.344-1.121-4.56-2.64
			c-0.202-0.247-0.279-2.468-0.48-1.2c-0.146,0.014-0.284,0.036-0.24,0.24c0,0.08,0,0.16,0,0.24c-0.562,1.198-1.199,2.32-2.16,3.119
			c-0.566,0.874-1.342,1.538-1.68,2.641c-0.704,1.136-1.536,2.145-2.16,3.359c-0.08,0-0.16,0-0.24,0c0,0.08,0,0.16,0,0.24
			c-0.544,0.896-1.216,1.664-1.68,2.641c-0.08,0-0.16,0-0.24,0c-0.213,0.506-0.73,0.709-0.72,1.439
			c-0.669,1.171-1.117,2.563-1.92,3.601c-1.087,1.874-2.336,4.861-2.16,7.199c9.91-1.454,1.582,13.188-1.2,15.601
			c-0.309,0.33-1.042,0.237-1.68,0.24c-0.027,2.295-1.093,4.823-0.72,6.72c0.92-1.561,1.536-3.424,2.88-4.561
			c0.138-0.5,1.553-0.3,1.44,0.24c0.557-0.162,0.642-0.798,1.2-0.96c3.164-4.116,6.614-7.945,9.6-12.24
			c0.734-0.785,1.208-1.831,2.16-2.399c3.408-4.271,7.223-8.138,10.56-12.48c2.229-2.491,4.428-5.012,6.96-7.2
			c0-0.08,0-0.159,0-0.239c1.34-1.541,2.78-2.98,4.32-4.32c0-0.08,0-0.16,0-0.24c0.78-0.98,1.66-1.86,2.64-2.64
			c1.288-1.672,2.967-2.953,4.08-4.8c0.699-0.901,1.5-1.701,2.4-2.4c0-0.08,0-0.16,0-0.24c2.614-2.745,5.048-5.672,7.92-8.16
			c0.234-0.245,0.475-0.485,0.72-0.72c2.54-2.74,5.18-5.38,7.92-7.92c1.255-0.345,1.583-1.617,2.64-2.16
			c1.112-1.448,2.718-2.401,4.08-3.6c1.124-0.396,1.698-1.343,2.641-1.92c0.663-1.017,1.789-1.57,2.64-2.4c0.08,0,0.16,0,0.24,0
			c2.14-2.34,4.38-4.58,6.72-6.72c2.774-1.866,5.215-4.065,7.68-6.24c1.367-1.594,3.158-2.762,4.801-4.08
			c5.473-3.567,11.177-6.902,17.04-10.08c1.231-1.088,2.929-1.711,4.56-2.4c2.422-0.858,4.312-2.249,6.96-2.88
			c1.567,0.676,2.838,0.736,4.32-0.24c0.501-0.379,1.262-0.498,2.16-0.48C344.777,404.523,340.386,401.475,336.08,398.34z"/>
			</g>
			<path id="skin" fillRule="evenodd" clipRule="evenodd" fill="#BA8D81" d="M347.601,275.22c-0.795-0.726-1.393-1.647-1.92-2.64
		c-0.604,0.465,0.281,1.462,0.239,2.16c0.33,1.536,1.126,3.528,0.48,5.28c-0.187,0.054-0.474,0.007-0.48,0.24
		c-3.601-0.159-1.163-6.357-3.84-7.44c-1.321,0.119-2.678,0.202-3.6,0.72c5.354,5.096,2.674,14.438-1.68,18.72
		c-0.363,0.997-1.204,1.517-1.921,2.16c-0.079,0-0.159,0-0.239,0c-0.395,0.772-2.225,0.583-2.16-0.48
		c-0.198-0.679,0.338-0.622,0.24-1.2c0.754-0.766,1.251-1.789,2.159-2.4c0.802-0.798,1.443-1.757,1.921-2.88
		c0.346-1.094,0.824-2.055,1.439-2.88c0.662-0.858,0.808-2.232,0.72-3.84c-0.742,0.249-2.018,0.867-2.88,0.24c0-0.08,0-0.16,0-0.24
		c-0.714-0.806-1.707-1.333-3.359-1.2c-0.939,0.14-1.394-0.207-1.92-0.48c-0.055,0.454,0.271,0.53,0.239,0.96
		c0.518,1.163,1.006,2.355,1.681,3.36c0.804,1.334,2.232,5.442,0,6.24c-1.574,1.397-3.157,5.039-5.761,3.84c-0.08,0-0.159,0-0.239,0
		c-0.095-0.975,0.088-1.672,0.96-1.68c0.665-0.775,1.645-1.235,2.159-2.16c2.136-2.391,0.568-5.961-0.72-8.16
		c-0.595-0.605-1.243-1.157-1.439-2.16c0-0.08,0-0.16,0-0.24c-2.365-0.118-1.022-2.483-0.48-3.6c-0.054-0.454,0.271-0.53,0.24-0.96
		c-2.363,0.437-5.433,0.167-8.16,0.24c0.08,0.959-0.428,1.332-0.24,2.4c0,0.08,0,0.16,0,0.24c-0.212,3.068,0.409,6.969-1.2,8.64
		c-0.668,0.73-1.753-0.152-1.92-0.72c-2.462-3.618-5.252-6.907-7.92-10.32c-0.53-0.189-0.601-0.84-0.96-1.2
		c-0.966-1.114-1.927-2.233-3.12-3.12c-4.198-2.202-8.521-4.279-13.68-5.52c-0.24-0.021-0.46-0.061-0.688-0.093l-1.553-5.38
		l-26.75-4.167l-8.411,5.541c-0.698-0.189-1.356-0.417-1.959-0.7c-1.109-0.491-2.378-0.823-3.6-1.2
		c-1.104-0.016-1.558-0.682-2.4-0.96c0-0.08,0-0.16,0-0.24c-0.041-0.28-0.379-0.261-0.72-0.24c0.699,2.661,1.28,5.439,2.16,7.92
		c0.697,1.623,1.382,3.257,1.68,5.28c0.41,1.83,0.969,3.511,1.68,5.04c0.473,0.407,0.644,1.116,0.72,1.92
		c1.182,3.458,2.345,6.935,4.32,9.6c0.768,0.784-0.133,1.935-0.72,2.16c-2.322-1.118-3.848-3.032-5.52-4.8c0-0.08,0-0.16,0-0.24
		c-1.367-1.752-2.386-3.854-3.84-5.52c-0.605-0.354-0.992-0.928-1.2-1.68c-0.521-1.24-1.238-2.282-1.92-3.36
		c-0.745-0.695-1.224-1.656-1.44-2.88c-0.606-1.554-1.083-3.237-1.92-4.56c-0.498-0.622-0.817-1.422-0.96-2.4
		c-0.457-1.944-1.084-3.716-1.92-5.28c-0.473-0.407-0.644-1.116-0.72-1.92c-0.848-2.832-1.961-5.399-3.36-7.68
		c-0.581-0.3-0.776-0.984-0.96-1.68c-0.205-0.755-0.445-1.475-1.2-1.68c-2.524,6.285-5.367,12.826-8.16,18.72
		c-0.984,2.078-2.308,4.127-2.898,6.063l-8.898,3.693l3.5,16.644l3.737-1.34c0,0.127,0,0.253,0,0.38
		c0.559,1.281,0.583,3.097,2.88,2.64c0.423-0.217,0.743-0.537,0.96-0.96c0.263-0.857,0.823-1.417,1.68-1.68
		c0.892-0.388,0.805-1.754,1.92-1.92c0.688-0.432,1.607-0.632,2.16-1.2c1.528-0.748,5.157-1.563,6.96-0.48
		c0.587-0.298-0.719-0.698-0.72-1.2c-0.166-0.474-0.64-0.64-1.2-0.72c-0.308-0.009-0.531-0.104-0.727-0.225l2.838-1.142
		c1.698,0.294,3.221,0.763,3.889,2.087c0.837,2.922,2.338,5.182,3.84,7.44c0.556,0.954,0.347,2.796,0,4.08
		c-0.09,4.95,0.062,10.142-0.96,14.16c-2.17,3.59-5.105,6.415-6.48,10.8c1.352,1.448,2.362,3.238,3.84,4.56
		c0.82,0.62,1.541,1.339,2.16,2.16c0.658,0.702,1.407,1.312,2.64,1.44c1.115,0.085,2.092,0.308,2.88,0.72
		c2.077,0.323,4.091,0.708,6,1.2c0.817,0.612,0.292,1.4-0.24,1.92c-2.238,0.635-5.157-0.121-7.2-0.48
		c-1.385-0.055-2.572-0.308-3.36-0.96c-0.921-0.679-1.947-1.253-2.88-1.92c-1.163-0.197-1.339-1.381-2.16-1.92c0-0.08,0-0.16,0-0.24
		c-1.368-1.912-2.927-3.633-4.32-5.52c-0.498-0.643-0.843-2.816,0-3.36c2.202-3.238,4.469-6.411,6.24-10.08
		c0.049-2.431,0.108-4.852,0.48-6.96c0.28-1.802-1.133-0.188-1.92-0.24c-0.974,0.866-1.882,1.798-2.88,2.64c-0.08,0-0.16,0-0.24,0
		c-1.725,0.595-2.58,2.06-4.8,2.16c-2.828,0.389-4.979-0.821-6.48,0.24c0-0.4,0-0.8,0-1.2c0.08,0,0.16,0,0.24,0
		c0.62-0.406-0.527-1.054-0.96-1.2c0,0.32,0,0.64,0,0.96c0,0.16,0,0.32,0,0.48c0.041,5.16,0.867,9.534,0.96,14.64
		c0.179,1.98,0.306,4.014,0.96,5.52c0.407,0.633,0.71,1.37,0.72,2.4c2.489,8.311,7.014,14.586,11.04,21.36
		c0.581,0.299,0.776,0.983,0.96,1.68c3.691,8.869,8.858,16.262,14.64,23.04c0.633,0.647,1.584,0.976,2.4,1.44c0.8,0,1.6,0,2.4,0
		c1.558-0.362,3.175-0.666,5.04-0.72c0.977-0.304,1.954-0.606,3.12-0.72c2.724-0.476,5.089-1.312,7.2-2.4
		c0.407-0.473,1.116-0.644,1.92-0.72c5.181-1.779,9.934-3.986,14.16-6.72c0.863-0.816,2.009-1.351,3.12-1.92
		c2.435-1.325,4.658-2.862,6.72-4.56c-0.24-0.001-0.285,0.194-0.479,0.24c0.878-0.802,1.828-1.532,2.88-2.16
		c3.516-2.484,6.68-5.321,9.36-8.64c1.589-2.011,3.264-3.937,5.04-5.76c0.085-0.315,0.422-0.377,0.479-0.72
		c2.12-2.36,3.596-5.365,5.28-8.16c0.188-0.053,0.279-0.201,0.24-0.48c1.283-1.997,0.62-5.939,3.359-6.48
		c0.63,2.03-0.611,4.159-1.199,5.76c0-0.08,0-0.16,0-0.24c-1.249,2.432-2.451,4.909-3.841,7.2c-0.131,0.989-0.835,1.404-1.199,2.16
		c-0.08,0-0.16,0-0.24,0c-1.396,1.724-2.731,3.508-4.08,5.28c-0.758,1.402-1.99,2.33-2.88,3.6c-0.08,0-0.16,0-0.24,0
		c-0.935,0.985-1.854,1.986-2.64,3.12c-3.066,4.054-7.123,7.118-10.801,10.56c-0.079,0-0.159,0-0.239,0
		c-1.653,1.867-3.43,3.611-5.28,5.28c-0.08,0-0.16,0-0.24,0c-5.429,4.331-10.935,8.585-17.76,11.52
		c-1.635,0.685-3.66,0.98-5.52,1.44c-1.131-0.012-1.818,0.421-2.88,0.48c0.455,2.825,1.185,5.375,2.16,7.68
		c0.444,0.036,0.147,0.813,0.48,0.96c1.28,0,2.56,0,3.84,0c1.464-0.056,2.583-0.457,3.6-0.96c0.559-0.402,1.293-0.627,2.16-0.72
		c0.554-0.326,1.07-0.69,1.92-0.72c2.251-0.628,4.206-1.554,6-2.64c0.588-0.452,1.174-0.906,2.16-0.96
		c1.414-0.506,2.694-1.146,3.84-1.92c0.3-0.58,0.983-0.776,1.681-0.96c1.713-0.766,3.345-1.615,4.8-2.64
		c0.922-0.598,1.799-1.241,3.12-1.44c1.563-0.676,3.228-1.252,4.56-2.16c0.588-0.452,1.174-0.906,2.16-0.96
		c2.161-0.799,4.209-1.711,6.24-2.64c0.407-0.473,1.116-0.644,1.92-0.72c1.983-0.496,3.929-1.03,5.76-1.68
		c1.526-0.874,4.218-0.582,5.76-1.44c0.125-0.355,0.355-0.605,0.48-0.96c-0.552-0.568-0.086-2.154-0.24-3.12c0-0.64,0-1.28,0-1.92
		c-0.557-1.124-0.083-3.277-0.24-4.8c0-0.48,0-0.96,0-1.44c-0.553-0.647-0.085-2.315-0.239-3.36c0-0.48,0-0.96,0-1.44
		c-0.4-1.444-0.4-4.556,0-6c0-0.32,0-0.64,0-0.96c-0.129-0.929,0.428-1.172,0.239-2.16c0.15-0.729-0.307-2.067,0.24-2.4
		c0-0.16,0-0.32,0-0.48c0.155-1.125-0.313-2.874,0.24-3.6c0-0.16,0-0.32,0-0.48c0.104-8.535,2.378-14.902,3.6-22.32
		c0.067-0.813,0.382-1.378,0.24-2.4c-0.804-0.076-1.513-0.247-1.92-0.72c-1.044,0.004-1.338-0.742-2.64-0.48
		c0.175-0.655-0.478-0.483-0.48-0.96c-2.02-1.314,0.961-3.359,2.16-1.68c1.599,0.081,2.39,0.97,4.08,0.96c0,0.08,0,0.16,0,0.24
		c1.2,0,2.399,0,3.6,0c1.39-1.411,2.903-2.696,4.08-4.32c1.663-1.697,3.147-3.573,4.561-5.52c1.565-2.915,4.461-4.499,5.279-8.16
		C349.403,285.402,349.05,279.87,347.601,275.22z M249.68,325.62c-2.415-0.415-1.268,2.732-3.6,2.4
		c-1.164,0.037-1.555-1.956-0.96-2.88c1.152-2.768,4.704-3.136,7.92-3.84c1.345-0.51,4.49-1.336,5.52,0
		c-0.044,0.204,0.094,0.227,0.24,0.24c0.204,0.044,0.226-0.094,0.24-0.24c0.103-1.098,0.365-2.035,0.24-3.36
		c0.063-0.703-0.542-0.737-0.48-1.44c1.433,0.086,1.223,1.816,2.16,2.4c0.698,0.676,1.062,2.796,0.24,3.6
		c-0.381,0.659-0.972,1.108-1.2,1.92c-0.445,0.835-0.913,1.647-1.92,1.92c-1.003,0.831-2.127-0.263-2.16-1.2c-1.04,0-2.08,0-3.12,0
		c-0.08,0-0.16,0-0.24,0C251.809,325.509,250.786,325.606,249.68,325.62z M295.28,282.9c-0.741,0.539-1.381,1.179-1.92,1.92
		c-0.08,0-0.16,0-0.24,0c-0.194,0.045-0.239,0.241-0.479,0.24c-0.063,0.847,0.418,1.15,0.472,1.88
		c-0.006,0.02-0.016,0.038-0.021,0.058c0.002-0.002,0.004-0.002,0.006-0.004c-0.077,0.275-0.189,0.532-0.294,0.793
		c0.104-0.259,0.211-0.517,0.288-0.789c-0.339,0.254-0.586,0.252-0.932-0.018c-1.121-0.66-1.038,0.937-2.16,0.96
		c-0.711,0.328-0.266,1.813-0.72,2.4c-0.744,0.082-1.146-0.556-1.68,0c-1.815,1.625-3.859,3.021-6.24,4.08
		c-0.384,0.416-1.081,0.52-1.92,0.48c-0.333,0.471-2.037,0.292-2.4,0c0-0.08,0-0.16,0-0.24c-0.233-0.006-0.186-0.294-0.24-0.48
		c-0.214-1.574,2.219-0.501,2.88-1.2c0.039-0.281,0.438-0.203,0.72-0.24c0.04-0.521-0.852-0.109-1.2-0.24
		c-0.956,0.31-3.976,0.621-4.56-0.24c-0.672-0.534-0.07-1.814,0.48-1.92c0-0.08,0-0.16,0-0.24c-3.306-0.241-7.937-0.656-10.56,0.48
		c0.641-2.749,1.769-6.792,3.84-8.88c-1.132,0.388-1.667,1.373-2.4,2.16c-0.529,0.991-0.863,2.177-1.68,2.88
		c-0.214,0.106-0.429,0.211-0.48,0.48c-0.636,0.774-1.54-0.204-1.92-0.48c-0.104-0.983,0.222-1.538,0.48-2.16
		c0.116-0.603-1.101,0.579-1.2-0.24c-0.08,0-0.16,0-0.24,0c-0.723-2.488,1.465-3.914,2.16-5.76c0.661-1.259,1.621-2.219,2.88-2.88
		c1.255-0.905,2.729-1.591,4.08-2.4c0.717-0.483,1.694-0.707,2.88-0.72c1.679-0.555,4.507-0.19,6,0.24
		c1.334,0.105,2.458,0.422,3.36,0.96c3.328,1.472,7.365,2.235,11.04,3.36c1.539,0.301,1.074,2.606,2.64,2.88
		c0.591,0.535,0.384,1.109,0,1.68C296.113,282.454,295.354,282.334,295.28,282.9z"/>
			<g>
				<path fillRule="evenodd" clipRule="evenodd" fill="#BA8D81" d="M232.88,287.22c-0.282,0.038-0.681-0.041-0.72,0.24
			c-1.144,0.456-3.029,0.171-4.32,0.48c1.594,0.646,3.926,0.553,5.76,0.96c0,0.08,0,0.16,0,0.24c0.296,0.344,0.825,0.455,1.44,0.48
			c0.612-0.263,1.235,0.82,1.2-0.24c-0.08-0.56-0.246-1.034-0.72-1.2c-0.424-0.456-1.191-0.569-1.68-0.96
			c-0.454-0.026-0.167-0.793-0.72-0.72C233.389,286.846,233.379,287.086,232.88,287.22z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#BA8D81" d="M270.56,275.7c-1.158,0.361-2.182,0.858-2.88,1.68
			c-0.86,1.06-1.82,2.02-2.88,2.88c-0.295,0.104-0.493,0.307-0.48,0.72c0.039,0.279-0.053,0.427-0.24,0.48
			c0.587,0.336,1.204-1.349,2.16-1.44c2.036-1.244,4.219-2.341,6.48-3.36c0.732-0.868,2.57-0.63,3.36-1.44
			C274.016,275.156,272.04,275.18,270.56,275.7z"/>
			</g>
			<g id="red">
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M181.52,566.581c0,0.08,0,0.16,0,0.239
			c0.363,2.038,0.612,4.188,0.72,6.48c0.529,4.751,1.822,8.738,3.12,12.72c0.474,0.566,0.565,1.515,0.72,2.4
			c0.455,2.506,1.306,4.614,2.16,6.72c0.964,2.156,1.725,4.516,2.88,6.48c0.054,0.186,0.006,0.474,0.24,0.479
			c0.484,0.637,0.894,1.347,1.2,2.16c2.685,4.256,2.104,9.585,0.96,15.12c0.281,0.714,0.298-0.787,0.72-0.72
			c0.864-2.096,2.262-3.658,2.64-6.24c1.099-5.221-0.032-12.673,0.72-18.24c0-0.24,0-0.479,0-0.72
			c0.159-2.082-0.318-4.798,0.24-6.48c0.476-5.764,1.316-11.163,1.92-16.8c0.198-3.242,0.602-6.277,1.2-9.12
			c0.572-3.027,1.153-6.046,1.92-8.88c0.25-1.35,0.647-2.553,1.2-3.6c0.934-2.026,1.941-3.979,2.64-6.24
			c0.266-0.935,0.664-1.736,1.2-2.4c0.692-1.548,1.602-2.878,2.16-4.56c0.524-1.315,1.209-2.471,1.92-3.6
			c0.399-0.481,0.871-0.89,0.96-1.681c0.291-0.989,1.09-1.471,1.2-2.64c0.08,0,0.16,0,0.24,0c0.657-3.743,1.25-7.551,1.68-11.521
			c0.183-1.303-0.38-1.859-0.24-3.12c0-0.239,0-0.479,0-0.72c-0.398-0.492-0.398-2.628,0-3.12c0.184-1.896,0.467-3.693,1.2-5.04
			c0.362-1.318,1.41-1.95,1.68-3.359c-0.223-1.983,0.825-2.695,0.72-4.561c0.08-1.36,0.301-2.578,0.72-3.6
			c0.379-0.501,0.498-1.263,0.48-2.16c0.162-1.438,0.475-2.726,0.96-3.84c1.986-5.935,3.774-12.066,5.76-18
			c0.291-0.909,0.464-1.936,0.96-2.641c0.545-1.215,1.137-2.383,1.44-3.84c0.562-1.598,1.149-3.171,1.92-4.56
			c0.676-1.164,1.284-2.396,1.68-3.84c0.128-0.832,0.642-1.278,0.72-2.16c0.08,0,0.16,0,0.24,0c0.775-0.985,1.235-2.285,1.68-3.601
			c0.506-1.413,1.146-2.694,1.92-3.84c0.927-1.554,1.97-2.99,2.64-4.8c1.82-2.66,3.768-5.192,6.72-6.72
			c-0.179-0.542-1.346-0.094-1.92-0.24c-0.651,0.148-1.905-0.305-2.16,0.24c-1.972-0.08-3.57,1.002-5.28,1.199
			c-2.924,0.338-2.261,0.394-3.84,2.4c-1.756,2.233-4.157,3.106-4.56,5.76c-0.9,1.5-1.624,3.177-2.16,5.04
			c-0.343,1.017-0.683,2.037-1.2,2.88c-0.759,1.082-1.246,2.435-1.68,3.841c-0.281,0.759-0.438,1.641-0.96,2.159
			c-0.6,0.681-0.953,1.607-1.2,2.641c-0.266,0.934-0.664,1.736-1.2,2.399c-0.103,0.457-0.517,0.604-0.48,1.2
			c-0.586,1.574-1.306,3.015-2.16,4.32c-0.103,0.457-0.517,0.603-0.48,1.2c-0.681,1.959-1.53,3.749-2.4,5.52
			c-0.754,1.246-1.125,2.875-1.68,4.32c-0.506,1.414-1.146,2.694-1.92,3.84c-0.855,1.145-1.636,2.363-2.16,3.84
			c-1.209,2.311-2.251,4.788-3.6,6.96c-1.877,3.483-3.973,6.747-5.52,10.561c-0.413,1.107-0.838,2.201-1.44,3.12
			c-0.187,0.053-0.279,0.2-0.24,0.479c-1.467,3.334-3.066,6.533-4.8,9.601c-0.187,0.053-0.279,0.2-0.24,0.479
			c-1.063,2.138-2.159,4.241-3.36,6.24c-0.433,1.167-1.272,1.928-1.44,3.359c-0.343,1.018-0.683,2.038-1.2,2.881
			c-0.836,1.483-1.414,3.226-1.92,5.04c-0.273,1.246-0.69,2.35-1.2,3.359c-0.882,2.078-1.665,4.255-2.16,6.721
			c-0.28,1.56-0.387,3.293-0.96,4.56c-0.53,0.03-0.102,1.019-0.24,1.44c-0.153,0.887,0.311,2.391-0.24,2.88c0,0.159,0,0.319,0,0.479
			c0,3.2,0,6.4,0,9.601c0,0.08,0,0.159,0,0.239C181.834,563.707,181.365,565.456,181.52,566.581z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M308.24,730.741c-0.841,0.519-2.002,0.719-3.12,0.96
			c-1.515,0.005-2.324,0.715-3.84,0.72c0,0.08,0,0.16,0,0.24c0.251,1.509,1.402,2.117,1.92,3.36
			c2.504,4.696,5.457,8.943,8.16,13.439c0.637,0.244,0.65,1.11,1.2,1.44c1.215-6.822,0.838-11.691,0.479-19.2
			c-0.213-0.587-1.34-0.26-1.2-1.2c-0.054-0.453,0.271-0.529,0.24-0.96C310.711,729.852,309.3,730.121,308.24,730.741z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M313.521,760.501c-0.146-0.573,0.301-1.741-0.24-1.92
			c-0.547-0.333-0.09-1.67-0.24-2.399c-0.31-0.971-1.319-1.241-1.92-1.921c-0.722-0.878-1.357-1.842-1.92-2.88
			c-2.606-4.753-5.573-9.146-8.4-13.68c-0.903-0.856-1.497-2.022-2.159-3.12c0.179-0.738-0.659-0.461-0.48-1.2
			c-1.145,0.136-2.31,0.251-3.12,0.721c-1.285,0.555-2.875,0.804-4.56,0.96c-1.497,0.103-2.866,0.334-4.08,0.72
			c-0.041,0.279-0.379,0.261-0.72,0.24c0.638,1.842,1.628,3.332,2.4,5.04c0.583,1.656,1.455,3.024,2.4,4.319
			c0.725,1.036,1.339,2.182,1.92,3.36c0.692,1.708,1.548,3.252,2.64,4.56c1.099,1.302,1.85,2.951,2.88,4.32
			c0.732,1.508,1.695,2.785,2.88,3.84c3.021,3.38,5.771,7.029,8.881,10.32c0,0.08,0,0.16,0,0.24c0.722,0.916,2.065,3.642,2.88,2.88
			c0-0.16,0-0.32,0-0.48c0.069-1.13,0.4-1.999,0.72-2.88c0.521,0.041,0.108-0.851,0.24-1.2c0.019-1.261,0.303-2.257,0.72-3.12
			c0.491-1.146,0.143-2.841-0.24-3.84C313.631,762.63,313.534,761.607,313.521,760.501z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M231.2,776.341c-0.654-0.705-1.106-1.614-1.68-2.399
			c-0.092-0.788-0.702-1.059-0.96-1.68c-0.749-0.611-1.173-1.547-1.68-2.4c-1.376-2.784-2.974-5.346-4.56-7.92
			c-0.582-0.779-1.023-1.697-1.44-2.641c-0.746-1.894-1.626-3.653-2.64-5.279c-0.496-1.024-1.092-1.949-1.44-3.12
			c-1.951-5.09-4.262-9.818-6.24-14.88c-0.08,0-0.16,0-0.24,0c-2.097,0.176-3.449-0.392-5.52-0.24
			c0.139,1.301,0.456,2.424,0.96,3.36c0.405,0.954,0.773,1.946,0.96,3.119c0.474,1.767,1.201,3.279,1.92,4.801
			c0.51,1.01,0.927,2.113,1.2,3.359c0.542,2.178,1.324,4.116,2.16,6c0.57,1.03,1.005,2.194,1.44,3.36
			c1.707,5.093,5.159,8.441,6.24,14.16c0.815,0.944,1.425,2.095,2.16,3.12c-0.013,0.413,0.185,0.614,0.48,0.72
			c0.967,1.513,2.431,2.528,2.64,4.8c3.52,0,7.04,0,10.56,0c-0.61-1.39-1.706-2.294-2.4-3.6
			C232.614,777.968,232.046,777.015,231.2,776.341z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M208.88,778.501c-0.761-0.569-1.563-3.487-2.16-2.64
			c0.037,1.563,0.338,2.861,0.72,4.08c0.356,0.737,0.489,1.964,0.24,2.64c1.12,0,2.24,0,3.36,0c0.261-0.749-1.205-1.828-1.44-2.88
			C209.52,779.142,209.354,778.667,208.88,778.501z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M262.16,396.181c0.438,0.361,0.564,1.035,0.96,1.44
			c0.96,0,1.92,0,2.88,0c3.011-0.109,5.937-0.303,8.16-1.2c0.559-0.401,1.293-0.627,2.16-0.72c0.596,0.036,0.996-0.124,1.2-0.48
			c0.732-0.468,1.648-0.752,2.4-1.2c0.447-0.033,0.802-0.158,0.96-0.48c1.358-0.962,3.068-1.572,4.56-2.4
			c1.216-0.384,2.272-0.928,3.12-1.68c0.664-0.536,1.466-0.934,2.4-1.2c1.357-0.483,2.724-0.957,3.84-1.68
			c0.893-0.468,1.885-0.836,2.881-1.2c1.82-0.74,3.606-1.513,5.04-2.64c1.445-0.954,3.046-1.753,4.8-2.4
			c3.268-1.211,6.636-2.324,10.319-3.12c0-0.08,0-0.16,0-0.24c1.51-0.331,2.85-0.831,4.561-0.96c0.755-0.445,1.887-0.513,3.12-0.48
			c0,0.08,0,0.16,0,0.24c0.557,0.077,0.921-0.039,1.2-0.24c1.519-0.542,1.451-3.617,0.72-5.04c-0.463,0.098-0.189,0.93-0.96,0.72
			c-1.795,0.366-4.045,0.275-5.521,0.96c0.4,0,0.801,0,1.2,0c-0.333,0.547-1.67,0.089-2.399,0.24
			c-0.444,0.356-1.141,0.46-1.921,0.48c-1.699,0.3-3.327,0.673-4.56,1.44c-0.676,0.444-1.581,0.659-2.4,0.96
			c-2.025,0.614-3.894,1.386-5.52,2.4c-0.919,0.602-2.013,1.028-3.12,1.44c-1.315,0.445-2.615,0.905-3.6,1.68
			c-0.97,0.471-1.871,1.01-2.881,1.44c-0.999,0.36-2.038,0.681-2.64,1.44c-3.395,2.126-7.167,3.873-11.04,5.521
			c-1.057,0.303-1.981,0.739-2.88,1.2c-0.757,0.603-1.833,0.887-2.88,1.2c-3.524,0.956-6.903,2.057-10.8,2.64
			c-0.841,0.432-3.227,0.354-4.08,0c0.063,0.738,0.521,1.08,0.72,1.68C262.16,396.021,262.16,396.101,262.16,396.181z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M215.36,469.141c0.492-1.108,1.182-2.018,1.44-3.36
			c0.586-1.573,1.306-3.014,2.16-4.319c0.286-0.755,0.81-1.271,0.96-2.16c0.279-1.002,0.77-1.791,1.2-2.641
			c2.196-4.044,4.003-8.477,5.76-12.96c0.056-0.073-0.073-0.433,0-0.479c-2.517,1.633-6.238,7.325-7.92,10.56
			c-0.874,2.807-1.56,5.801-2.16,8.88c-0.195,1.245-0.499,2.382-0.96,3.36c-0.62,1.06-0.89,2.471-1.2,3.84
			C214.921,470.575,214.938,469.073,215.36,469.141z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M242,459.541c-0.676,0.443-1.228,1.012-2.16,1.199
			c-0.871,0.249-1.49,0.75-1.92,1.44c-0.759,0.681-1.729,1.15-2.4,1.92c-0.326,0.794-0.699,1.541-0.96,2.4
			c-1.212,1.107-1.639,3.001-2.64,4.319c-0.187,0.053-0.279,0.201-0.24,0.48c-1.79,3.65-4.33,6.551-6.24,10.08
			c-0.607,1.872-1.338,3.621-1.68,5.76c0,0.24,0,0.48,0,0.72c0.317-0.003,0.219-0.421,0.48-0.479c0.927-0.913,1.546-2.135,2.16-3.36
			c2.946-4.974,6.875-8.965,8.88-14.88c0.32-0.649,1.103-2.085,0.72-2.64c-0.355-0.045-0.22-0.58-0.24-0.96
			c1.078-0.923,2.569-1.431,3.6-2.4c4.497-2.783,9.227-5.333,13.44-8.4c0.878-0.722,1.842-1.357,2.88-1.92
			c0.752-0.208,1.325-0.595,1.68-1.2c0.903-0.776,1.866-1.493,2.88-2.159c0.601,0.04,0.751-0.369,0.96-0.721
			c4.006-3.193,7.906-6.494,11.76-9.84c0.883-0.157,1.195-0.885,1.68-1.439c0.764-0.517,1.215-1.346,2.16-1.681
			c0.349-0.131,1.24,0.28,1.2-0.239c-1.158-1.402-1.093-4.027-1.92-5.761c-0.564-1.996-1-4.12-1.2-6.479
			c-0.756-5.164-1.641-10.199-2.64-15.12c-0.369-0.751-0.466-1.774-0.48-2.88c0.004-1.204-0.185-2.214-0.48-3.12
			c-0.547-0.333-0.089-1.671-0.24-2.4c-0.03-0.53-1.018-0.102-1.44-0.24c-0.815,0.065-1.946-0.186-2.4,0.24
			c-0.041,0.28-0.379,0.261-0.72,0.24c0.138,0.502,0.595,0.685,0.48,1.44c0.131,0.349-0.28,1.241,0.24,1.2
			c0.57,1.83,0.966,3.834,1.2,6c0.456,3.544,1.26,6.74,1.92,10.08c0.61,2.19,0.968,4.632,1.44,6.96
			c0.368,2.272,0.592,4.688,1.44,6.48c3.97,4.057-2.462,7.332-5.28,9.84c-2.931,2.608-5.318,4.762-7.44,6.24c-0.08,0-0.16,0-0.24,0
			c-2.304,1.296-4.627,2.573-6.72,4.08c-0.995,0.685-1.973,1.387-3.12,1.92c-0.413-0.013-0.615,0.185-0.72,0.479
			c-1.181,0.9-2.472,1.688-3.84,2.4C244.336,457.717,243.167,458.627,242,459.541z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M191.36,692.821c0.02,1.02,1.529,0.551,1.92,1.2
			c0.053,0.187,0.201,0.278,0.48,0.239c-0.259-3.101-1.325-5.396-2.16-7.92c-0.187-0.053-0.279-0.2-0.24-0.479
			c-0.554,0.805-0.084,2.636-0.24,3.84C191.046,690.895,191.158,691.903,191.36,692.821z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M194.96,699.541c0.032-0.592,0.037-1.156-0.24-1.439
			c-0.305-1.216-2.063-0.978-2.88-1.681c-0.04-0.279-0.379-0.261-0.72-0.24c-0.763-0.036-1.056-0.544-1.92-0.479
			c0.509,0.943,0.524,3.519-0.24,5.04C190.566,700.904,194.958,701.782,194.96,699.541z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M202.16,776.821c0-0.08,0-0.16,0-0.24
			c-0.549-0.411-0.088-1.832-0.24-2.64c0-0.08,0-0.16,0-0.24c-0.551-0.489-0.087-1.993-0.24-2.88c0-0.16,0-0.32,0-0.48
			c-0.549-0.41-0.088-1.832-0.24-2.64c0-0.16,0-0.32,0-0.48c-0.551-0.488-0.087-1.992-0.24-2.88c0-0.239,0-0.479,0-0.72
			c-0.551-0.489-0.087-1.993-0.24-2.88c0-0.24,0-0.479,0-0.72c-0.188-4.759-1.061-9.767-1.2-14.88
			c-0.124-4.563,0.858-10.495-1.2-12.96c0-0.08,0-0.16,0-0.24c-0.535-1.306-0.67-3.01-0.96-4.561
			c-0.797-5.923-0.591-12.849-1.2-18.96c0.031-1.391-0.042-2.678-0.48-3.6c-0.521,0.04-0.108-0.852-0.24-1.2
			c-2.25,0.262-6.344,0.474-7.44-0.24c0.039,0.279-0.053,0.428-0.24,0.48c-0.789,2.491-1.531,5.028-1.92,7.92
			c-0.081,2.239-0.879,3.761-0.96,6c-0.08,0-0.16,0-0.24,0c-0.653,2.707-1.131,5.589-1.44,8.64c-0.142,4.098-1.154,7.326-1.44,11.28
			c-0.08,0-0.16,0-0.24,0c-1.101,5.779-1.704,12.057-2.64,18c-0.373,3.388-0.731,6.789-1.2,10.08c-0.392,2.088-0.814,4.146-1.2,6.24
			c-0.386,1.374-1.646,1.873-1.68,3.6c-0.499,2.142-0.861,4.419-0.96,6.96c9.44,0,18.88,0,28.32,0
			C202.535,780.446,201.977,779.004,202.16,776.821z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M308.48,776.102c0-0.08,0-0.16,0-0.24
			c-0.085-0.796-0.719-1.041-1.2-1.44c-1.361-1.438-2.585-3.015-3.84-4.56c-2.16-2.96-4.594-5.646-6.96-8.4
			c-1.177-1.383-2.171-2.949-3.12-4.56c-3.716-6.764-7.969-12.991-11.04-20.4c-3.422,0.258-6.684,0.677-9.84,1.2
			c-2.792,0.488-5.877,0.683-9.12,0.72c2.17,3.431,4.895,6.306,7.2,9.601c-0.041,0.601,0.369,0.751,0.72,0.96
			c0.586,0.693,1.219,1.341,1.68,2.16c0.017,0.703,0.549,0.891,0.96,1.199c0.678,0.762,1.321,1.559,1.92,2.4
			c0.016,0.704,0.548,0.892,0.96,1.2c6.758,8.041,13.897,15.703,22.08,22.32c0.799,0.801,1.758,1.442,2.88,1.92
			c1.56-0.133,0.323,1.87,1.2,2.399c1.783,1.076,5.76-0.786,8.16,0c-0.04-1.429,0.657-2.9-0.24-3.84
			C309.929,778.013,309.524,776.737,308.48,776.102z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M257.84,765.062c0-0.08,0-0.16,0-0.24
			c-0.362-0.919-0.994-1.566-1.68-2.16c-0.742-0.778-1.307-1.732-1.92-2.64c-1.562-2.759-3.31-5.33-5.04-7.92
			c-0.676-0.845-1.18-1.86-1.68-2.881c-1.586-3.613-3.654-6.746-5.04-10.56c-2.745,0.024-5.388-0.052-7.68-0.479
			c-0.08,0-0.16,0-0.24,0c-0.96,0-1.92,0-2.88,0c0.054,0.186,0.006,0.473,0.24,0.479c0.724,0.876,1.091,2.109,1.68,3.12
			c0.497,1.583,1.357,2.803,2.16,4.08c0.645,0.715,0.93,1.79,1.44,2.64c0.497,1.583,1.357,2.803,2.16,4.08
			c0.824,1.176,1.501,2.498,2.16,3.84c0.462,1.299,1.086,2.434,1.92,3.36c0.685,0.995,1.388,1.973,1.92,3.12
			c0.298,0.742,0.592,1.488,1.2,1.92c0.594,0.846,1.179,1.701,1.68,2.64c0.148,0.652,0.476,1.124,0.96,1.44
			c0.677,0.843,1.311,1.729,1.92,2.64c0.863,1.617,1.992,2.968,3.36,4.08c1.997,2.724,5.12,4.32,7.2,6.96c4.64,0,9.28,0,13.92,0
			C270.433,776.948,263.001,771.5,257.84,765.062z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M281.12,765.541c-0.233-0.967-1.048-1.352-1.68-1.92
			c-0.978-1.103-1.956-2.205-2.88-3.359c-3.31-4.291-6.611-8.589-10.08-12.721c-0.616-0.583-1.012-1.388-1.68-1.92
			c0-0.08,0-0.16,0-0.24c-0.879-1.521-1.881-2.918-3.12-4.08c-0.752-0.848-1.449-1.75-2.16-2.64c-1.47,0.53-3.898,0.102-5.76,0.24
			c-1.205,0.399-4.075,0.399-5.28,0c-0.73,0.229-2.434-0.515-2.4,0.479c0.62,1.78,1.474,3.326,2.4,4.801
			c0.646,0.714,0.93,1.79,1.44,2.64c0.747,1.894,1.702,3.578,2.88,5.04c1.25,1.789,2.337,3.743,3.6,5.52
			c0.799,1.681,1.884,3.076,3.12,4.32c6.011,7.989,14.222,13.778,21.12,20.88c6,0,12,0,18,0
			C292.786,777.875,286.53,771.491,281.12,765.541z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M251.84,777.541c0-0.08,0-0.16,0-0.24
			c-1.997-2.643-3.986-5.294-6-7.92c-0.595-0.846-1.179-1.7-1.68-2.64c-3.984-6.656-7.691-13.589-11.52-20.4
			c-1.227-1.973-2.302-4.098-3.36-6.239c-0.054-0.187-0.007-0.474-0.24-0.48c-0.458-0.502-0.774-1.146-0.96-1.92
			c-2.304-0.016-4.07-0.57-6.48-0.48c0-0.079,0-0.159,0-0.239c-0.08,0-0.16,0-0.24,0c-0.729-0.15-2.067,0.307-2.4-0.24
			c-0.773-0.347-2.013-0.227-3.12-0.24c-0.574,0.146-1.741-0.301-1.92,0.24c0.307,1.613,0.884,2.956,1.68,4.08
			c0.517,0.843,0.857,1.863,1.2,2.88c0.75,2.13,1.59,4.169,2.64,6c0.602,0.918,1.027,2.013,1.44,3.12
			c0.426,1.254,0.985,2.374,1.68,3.36c0.803,1.037,1.251,2.429,1.92,3.6c1.423,3.137,3.076,6.044,5.04,8.64
			c0.907,1.093,1.535,2.465,2.4,3.601c0.589,1.331,1.387,2.453,2.4,3.359c0.968,1.112,1.773,2.387,2.64,3.601
			c0.64,1.28,1.509,2.331,2.4,3.359c5.204,0.557,11.437,0.083,17.04,0.24C255.602,781.138,253.317,779.105,251.84,777.541z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M218.96,778.021c-1.041-1.359-1.999-2.801-2.88-4.32
			c-0.08-0.56-0.246-1.034-0.72-1.2c-0.925-1.314-1.658-2.821-2.4-4.319c-0.396-1.364-1.149-2.371-1.68-3.601
			c-0.912-1.328-1.603-2.878-2.16-4.56c-0.445-1.154-0.761-2.439-1.44-3.36c-0.562-0.878-0.891-1.989-1.2-3.12
			c-0.099-1.101-0.525-1.875-0.96-2.64c-0.371-0.578-0.504-1.026-0.48,0.24c0.156,1.684,0.405,3.274,0.96,4.56
			c0.469,0.811,0.585,1.976,0.72,3.12c0.495,2.944,1.249,5.631,2.16,8.16c0.728,1.753,1.252,3.708,1.92,5.52
			c0.347,1.413,0.905,2.615,1.44,3.84c0.357,0.763,0.887,1.353,0.96,2.4c0,0.48,0,0.96,0,1.44c-0.609,0.906,0.807,1.493,0.96,2.399
			c2.56,0,5.12,0,7.68,0c-0.114-1.01-1.763-2.554-2.4-3.84C219.453,778.328,219.255,778.126,218.96,778.021z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M360.561,418.021c-0.08,0-0.16,0-0.24,0
			c-1.153,0.047-2.285,0.115-3.12,0.48c-0.912,0.368-2.161,0.398-3.359,0.479c-2.086,0.155-4.011,0.47-5.521,1.2
			c-3.386,1.494-5.908,3.852-7.68,6.96c-0.08,0-0.16,0-0.24,0c-0.486,0.395-0.957,0.803-0.96,1.68
			c0.447-0.033,0.802-0.157,0.96-0.479c1.336-0.584,2.183-1.657,3.36-2.4c1.62-0.021,2.424,0.776,3.6,1.2
			c0.502,0.458,1.146,0.773,1.92,0.96c4.219,2.9,7.135,7.105,11.28,10.08c0.977,0.943,2.084,1.756,3.359,2.399
			c0.842,0.68,1.694,1.347,2.16,2.4c0.314,0.966,0.625,1.935,0.48,3.36c0.345,0.715-0.843,1.462,0.24,1.439
			c5.3-1.66,12.313-3.937,18.479-1.92c4.156-0.156,5.313,2.687,6.72,5.28c0.271,0.929,0.454,1.946,1.2,2.4
			c1,1.742,1.986-0.89,2.4-1.921c0.53-0.029,0.102-1.018,0.24-1.439c-0.08,0-0.16,0-0.24,0c0.625-2.181-1.201-3.893-1.2-6.24
			c-0.279-0.04-0.261-0.379-0.24-0.72c-3.178-4.022-6.479-7.921-10.08-11.521c-1.762-1.758-3.824-3.215-5.52-5.04
			c-1.994-1.525-3.694-3.346-5.28-5.279c-0.98-0.78-1.86-1.66-2.64-2.641c-0.241,0.001-0.286-0.194-0.48-0.239
			c-0.61-0.67-2.345-0.216-3.12-0.721c-1.04,0-2.08,0-3.12,0C363.273,418.334,361.605,417.865,360.561,418.021z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M401.12,482.581c-0.474-0.566-0.565-1.515-0.72-2.4
			c-0.87-1.69-1.523-3.597-2.16-5.521c-0.658-0.941-1.15-2.049-1.439-3.359c-2.032-4.128-4.429-7.893-6-12.48
			c-0.39-0.41-0.727-0.873-0.721-1.68c-0.849-1.23-1.352-2.809-1.92-4.32c0-0.08,0-0.16,0-0.24c-0.785-1.854-2.083-3.196-4.319-3.6
			c-3.542-1.551-9.087-0.35-12.24,0.72c-1.109,0.491-2.378,0.823-3.601,1.2c-0.806-0.006-1.27,0.331-1.68,0.72
			c-0.472,0.569-1.592,0.489-2.64,0.48c-0.569-1.03-1.379-1.821-1.2-3.6c0.08,0,0.16,0,0.24,0c0.48-0.959,0.964-1.916,0.96-3.36
			c-0.556,0.324-1.226,0.534-1.681,0.96c-2.068,1.052-4.168,2.071-6.239,3.12c-1.576,0.664-3.012,1.469-4.08,2.64
			c-8.139,6.263-13.704,15.097-20.16,23.04c-1.601,2-3.041,4.16-4.32,6.48c-0.42,0.859-0.872,1.688-1.439,2.399
			c-1.074,1.326-1.897,2.903-2.641,4.561c-0.405,0.954-1.132,1.588-1.439,2.64c-0.08,0-0.16,0-0.24,0
			c-1.534,2.146-2.99,4.37-4.08,6.96c-0.561,1.28-1.361,2.319-1.68,3.84c-0.08,0-0.16,0-0.24,0c-0.828,1.332-1.491,2.829-1.92,4.561
			c-0.534,1.946-1.031,3.929-1.92,5.52c-0.608,1.552-1.137,3.185-1.44,5.04c-0.689,2.99-0.865,6.494-2.16,8.88
			c0,0.24,0,0.48,0,0.721c-0.078,0.882,0.205,2.124-0.239,2.64c-1.848-0.393-0.592-3.889-0.961-5.76c0-0.72,0-1.44,0-2.16
			c0.018-0.943,0.116-1.804,0.48-2.4c1.457-5.822,3.344-11.216,5.28-16.56c0.193-1.007,0.844-1.557,0.96-2.64c0.08,0,0.16,0,0.24,0
			c1.748-3.372,3.805-6.436,5.52-9.841c0.405-0.954,1.132-1.588,1.44-2.64c0.08,0,0.159,0,0.239,0c2.72-4.16,5.222-8.539,7.92-12.72
			c0.649-1.271,1.488-2.353,2.4-3.36c1.247-1.313,2.469-2.651,3.36-4.32c0.461-0.818,1.093-1.466,1.68-2.159
			c1.457-1.423,2.74-3.02,3.84-4.801c0.666-1.014,1.383-1.977,2.16-2.88c0.302-0.099,0.49-0.31,0.48-0.72
			c2.278-3.082,5.219-5.501,8.399-7.68c2.315-1.125,4.332-2.548,6.72-3.601c0.219-0.422,0.842-0.438,1.2-0.72
			c-1.471-1.729-3.14-3.26-4.8-4.8c-1.573-1.707-3.282-3.278-5.28-4.561c-1.861-1.418-3.547-3.013-5.279-4.56
			c-0.756-0.115-0.938,0.342-1.44,0.479c-1.278,0.321-2.001,1.199-3.84,0.96c-1.301-0.059-1.439-1.28-2.16-1.92
			c-0.025-0.985-0.039-1.959,0.48-2.399c0.753-1.327,1.69-2.47,2.399-3.84c1.815-2.825,4.298-4.983,7.681-6.24
			c0.614-0.025,1.144-0.136,1.439-0.48c1.285-0.555,2.876-0.804,4.561-0.96c1.784-0.136,3.521-0.317,5.039-0.72
			c0.104-0.537,1.184-0.098,1.681-0.24c0.333-0.547,1.67-0.089,2.399-0.24c0.16,0,0.32,0,0.48,0c0.502-0.865-1.176-1.295-1.44-2.16
			c-0.627-0.572-1.514-0.886-1.92-1.68c-0.471-0.656-2.419-1.091-3.359-0.48c-0.471,0.342-2.044,0.821-2.641,0.24
			c-0.479,0-0.96,0-1.439,0c-0.732-0.148-0.683-1.077-1.92-0.72c-5.188,0.572-8.665,2.855-13.2,4.08
			c-1.286,1.354-3.381,1.899-5.04,2.88c-2.729,1.271-5.355,2.646-7.681,4.32c-1.121,0.799-2.329,1.51-3.6,2.16
			c-1.005,0.435-1.98,0.899-2.64,1.68c-0.856,0.824-1.96,1.4-2.88,2.16c-0.704,0.017-0.892,0.549-1.2,0.96
			c-5.721,4.999-11.807,9.634-17.04,15.12c-0.883,0.157-1.195,0.885-1.681,1.439c-1.112,1.128-2.55,1.93-3.6,3.12
			c-0.08,0-0.16,0-0.24,0c-1.16,0.6-2.091,1.429-2.88,2.4c-16.181,14.699-30.48,31.279-45.12,47.521c-0.08,0-0.16,0-0.24,0
			c-0.555,0.484-1.283,0.797-1.44,1.68c-2.084,2.476-4.12,5-6.24,7.439c-1.457,1.423-2.741,3.02-3.84,4.801
			c-2.48,3.359-5.289,6.391-7.68,9.84c-0.08,0-0.16,0-0.24,0c-2.067,2.173-3.967,4.514-5.52,7.2c-1.987,2.893-3.819,5.94-5.52,9.12
			c-0.187,0.053-0.279,0.2-0.24,0.479c-1,2.36-2.019,4.701-3.12,6.96c-0.852,1.548-1.41,3.39-1.92,5.28
			c-0.494,1.906-1.06,3.74-1.68,5.52c-1.147,4.454-1.73,9.471-2.16,14.641c-0.228,3.133-0.685,6.034-1.2,8.88
			c-0.385,2.655-0.731,5.348-0.72,8.399c-0.152,0.809,0.309,2.229-0.24,2.641c-0.241,9.57,0.726,17.911-1.44,24.96
			c-0.426,1.254-0.985,2.375-1.68,3.36c-0.492,1.107-1.182,2.018-1.44,3.359c-0.301,0.819-0.516,1.725-0.96,2.4
			c-1.413,2.747-1.898,6.422-3.6,8.88c-2.708,3.036-3.17,9.107-2.4,14.88c0.131,0.349-0.28,1.24,0.24,1.2
			c0.567,2.535-0.464,4.212,0,7.2c0.371,4.589,0.936,8.983,1.92,12.96c0.391,0.809,0.517,1.883,0.72,2.88
			c0.303,1.617,0.937,2.903,1.68,4.08c0.444,0.676,0.743,1.497,0.96,2.399c1.43,4.57,2.567,9.434,3.84,14.16
			c0.422,0.698,0.547,1.693,0.72,2.641c0.041,0.358-0.085,0.886,0.24,0.96c0.379,0.501,0.498,1.262,0.48,2.16
			c0.547,3.452,1.063,6.938,1.68,10.319c0.626,2.975,1.175,6.025,1.44,9.36c0.239,2.481,0.805,4.636,1.44,6.72
			c0.882,2.317,1.058,5.343,3.12,6.48c0.411,0.39,0.874,0.727,1.68,0.72c0.32,0,0.64,0,0.96,0c0,0.24,0,0.48,0,0.72
			c0.282,0.038,0.681-0.041,0.72,0.24c0.08,0,0.16,0,0.24,0c0.729,0.15,2.067-0.308,2.4,0.24c0.08,0,0.16,0,0.24,0
			c0.729,0.15,2.067-0.308,2.4,0.24c0.08,0,0.16,0,0.24,0c6.943,0.416,13.613,1.106,20.4,1.68c0.08,0,0.16,0,0.24,0
			c1.603,0.157,3.837-0.316,5.04,0.24c0.4,0,0.8,0,1.2,0c3.68,0,7.36,0,11.04,0c0.24,0,0.48,0,0.72,0
			c5.314-0.526,10.795-0.885,16.32-1.2c5.916-0.404,11.321-1.319,16.56-2.4c2.546-0.653,5.156-1.243,7.92-1.68
			c2.669-0.531,5.427-0.974,7.68-1.92c1.213-0.468,2.415-0.945,3.841-1.2c1.77-0.11,2.956-1.397,4.56-0.96
			c0.33-0.011,0.527,0.113,0.72,0.24c0.614-3.546,0.408-7.912,0.96-11.521c0-0.08,0-0.16,0-0.24c0.156-1.203-0.314-3.034,0.24-3.84
			c0-0.16,0-0.319,0-0.479c0.155-1.125-0.313-2.874,0.24-3.601c0-0.239,0-0.479,0-0.72c0-4.8,0-9.6,0-14.399c0-0.4,0-0.801,0-1.2
			c-0.558-1.283-0.082-3.598-0.24-5.28c0-0.56,0-1.12,0-1.68c-0.438-0.842-0.003-2.558-1.2-2.641
			c-0.782-0.737-1.898-1.142-2.16-2.399c0-0.24,0-0.48,0-0.721c0.454-0.476,1.134-0.116,1.681,0c0.504,0.296,0.936,0.665,1.439,0.96
			c0-0.64,0-1.279,0-1.92c0-0.08,0-0.16,0-0.239c-0.559-2.881-0.08-6.8-0.239-10.08c0-0.16,0-0.32,0-0.48
			c-0.687-2.034,0.193-5.634-0.48-7.68c-0.455-0.425-1.537-0.224-1.92-0.72c-1.09,0.35-1.481,1.398-2.4,1.92
			c-2.51,1.649-5.11,3.209-7.68,4.8c-1.358,0.962-3.068,1.572-4.56,2.399c-0.331-0.01-0.527,0.113-0.721,0.24
			c-0.65,0.39-1.771,0.31-2.64,0.48c0.419-1.021,1.096-1.784,1.68-2.641c0.08,0,0.16,0,0.24,0c0.413,0.014,0.615-0.185,0.72-0.479
			c0.995-0.686,1.973-1.388,3.12-1.92c1.433-0.567,2.76-1.241,3.84-2.16c1.104-0.736,2.168-1.513,3.36-2.16
			c0.923-0.356,1.636-0.925,2.16-1.68c0.379-0.582,0.858-1.062,1.439-1.44c0.423-0.058,0.548-0.413,0.48-0.96
			c0.319-0.561,0.407-1.353,0.72-1.92c0-0.16,0-0.32,0-0.479c0.152-0.809-0.309-2.229,0.24-2.641c0.091-2.068-0.843-3.363-0.24-5.28
			c-7.653-1.466-14.557-3.683-21.6-5.76c-1.042-0.157-1.854-0.546-2.64-0.96c-1.675-0.726-3.155-1.646-5.28-1.92
			c-2.777-0.563-6.989,0.689-9.6-0.48c-0.529-0.22-1.076-1.056-0.48-1.68c3.453-1.518,10.284-2.207,13.44,0
			c1.488,0.593,2.788,1.372,4.56,1.68c1.63,0.53,3.371,0.949,4.8,1.681c2.688,0.832,5.167,1.873,8.16,2.399
			c1.434,0.167,2.621,0.579,3.84,0.96c2.083,0.558,4.048,1.233,6.24,1.681c0.421-1.227,0.369-4.046,0-5.28
			c-0.773-0.106-1.301-0.46-2.16-0.479c-0.909-0.291-1.936-0.465-2.64-0.961c-0.411-0.389-0.874-0.727-1.681-0.72
			c-1.254-0.426-2.374-0.985-3.359-1.68c-0.158-0.322-0.514-0.447-0.961-0.48c-0.85-0.51-1.925-0.794-2.64-1.439
			c-0.714-0.646-1.567-1.152-2.64-1.44c-3.021-1.54-5.763-3.357-8.4-5.279c-0.354-0.605-0.928-0.992-1.68-1.2
			c-2.817-1.764-5.61-4.082-8.4-6.24c-2.623-2.029-6.584-3.889-6.48-7.92c2.312,0.33,3.749,2.332,5.28,3.6
			c1.665,1.378,3.67,3.051,5.28,4.08c0.316,0.484,0.788,0.813,1.44,0.96c3.263,2.178,6.309,4.571,9.6,6.721
			c0.099,0.301,0.31,0.49,0.72,0.479c0.86,0.42,1.688,0.872,2.4,1.44c1.077,0.923,2.364,1.636,3.84,2.159
			c0.943,0.418,1.861,0.859,2.641,1.44c0.157,0.322,0.513,0.447,0.96,0.48c1.971,1.068,4.129,1.95,6.239,2.88
			c0.385-1.047,0.087-3.177,0.721-4.561c0.426-0.454,0.175-1.585,0.239-2.399c0.058-1.258-0.537-1.863-1.199-2.4
			c-0.122-0.599-0.454-0.986-0.721-1.44c-2.446-2.033-5.721-3.239-7.92-5.52c0-0.08,0-0.16,0-0.24
			c-0.027-0.293-0.071-0.568-0.479-0.479c-0.525-0.035-0.516-0.605-0.48-1.2c1.238-0.269,2.885,0.674,4.08,1.2
			c1.156,0.684,2.211,1.469,3.601,1.92c1.02,0.499,2.035,1.004,2.88,1.68c0.166,0.474,0.64,0.64,1.199,0.72
			c0.558-1.442,0.082-3.918,0.24-5.76c0-0.16,0-0.32,0-0.479c-0.559-2.082-0.081-5.199-0.24-7.681c0-0.239,0-0.479,0-0.72
			c-0.697-13.018,0.135-26.586,0.24-39.6c-0.697-0.183,0.218-1.979-0.479-2.16c-0.24,0-0.48,0-0.721,0
			c-2.515-1.485-3.944-4.055-6.479-5.521c0-0.08,0-0.16,0-0.24c-1.554-1.646-3.312-3.088-5.28-4.319
			c-3.24-2.279-6.015-5.025-8.64-7.92c-0.589-1.974-3.269-3.13-2.64-5.521c0.929-0.209,0.854,0.586,1.44,0.72
			c1.711,2.449,3.807,4.514,6,6.48c2.064,1.855,4.244,3.596,6.72,5.04c3.124,2.636,6.937,4.584,9.36,7.92
			c0.355-0.045,0.22-0.58,0.24-0.96c0.061-1.501-0.26-2.62-0.721-3.6c0.209,1.248-0.377,1.702-1.439,1.68
			c-2.27-1.33-2.978-4.223-4.8-6c0-0.08,0-0.16,0-0.24c-0.209-0.752-0.595-1.325-1.2-1.68c-0.708-0.732-1.213-1.668-1.92-2.4
			c0-0.08,0-0.16,0-0.24c-0.208-0.752-0.595-1.325-1.2-1.68c-0.779-0.9-1.381-1.979-2.16-2.88c0-0.08,0-0.16,0-0.24
			c0.013-0.413-0.185-0.615-0.479-0.72c-0.723-0.878-1.358-1.843-1.921-2.88c-0.638-1.282-1.249-2.591-2.159-3.601
			c-0.927-1.474-1.78-3.02-2.4-4.8c-0.217-0.903-0.516-1.724-0.96-2.399c-0.794-1.606-1.299-3.501-1.44-5.761
			c-0.143-0.497,0.296-1.576-0.24-1.68c-0.408-1.192-0.501-2.699-0.48-4.32c-0.191-1.991,0.326-4.418,1.68-4.08
			c1.403,1.867,0.737,4.771,1.44,6.96c0.553,2.888,0.989,5.891,1.92,8.4c0.31,1.53,0.92,2.761,1.68,3.84
			c0.794,1.207,1.485,2.516,2.16,3.84c0.08,0.561,0.246,1.034,0.721,1.2c0.939,1.46,1.963,2.837,2.88,4.32
			c0.315,0.885,0.797,1.603,1.439,2.16c2.016,2.785,4.144,5.457,6,8.399c-0.013,0.413,0.185,0.615,0.48,0.721
			c0.751,0.928,1.444,1.915,1.92,3.119c0.187-0.053,0.279-0.2,0.24-0.479c-0.051-1.79-0.419-3.262-0.96-4.56
			c-0.423-0.698-0.547-1.693-0.721-2.641c-0.276-1.644-0.775-3.064-1.199-4.56c-0.56-2.241-0.081-5.52-0.24-8.16
			c0-1.521,0-3.04,0-4.561c0.033-0.687,1.001-0.438,1.68-0.479c0.203,0.917,0.405,1.835,0.48,2.88c0.562,1.998,0.866,4.253,1.68,6
			c0.68,1.961,1.286,3.993,1.68,6.24c0.488,2.792,1.799,4.762,2.4,7.439c0.538,9.511,0.137,19.021,0.24,28.561c0,1.2,0,2.399,0,3.6
			c-0.257,14.097,0.494,27.186,0.96,40.561c0,0.08,0,0.16,0,0.24c0.558,1.602,0.081,4.238,0.239,6.239c0,0.08,0,0.16,0,0.24
			c0.56,4.001,0.081,9.04,0.24,13.44c0,0.96,0,1.92,0,2.88c0.556,0.964,0.083,2.957,0.24,4.32c0,0.959,0,1.92,0,2.88
			c0,0.159,0,0.319,0,0.479c0.554,0.727,0.085,2.476,0.24,3.601c0,0.239,0,0.479,0,0.72c0.558,1.522,0.081,4.078,0.239,6
			c0,0.8,0,1.6,0,2.399c0.55,0.411,0.089,1.832,0.24,2.641c0,1.6,0,3.2,0,4.8c0,0.56,0,1.12,0,1.68
			c0.558,1.363,0.082,3.758,0.24,5.521c0,0.479,0,0.96,0,1.439c0.551,0.489,0.087,1.993,0.24,2.88
			c3.112,2.488,5.742,5.459,8.64,8.16c-0.057,1.063-0.526,1.714-1.439,1.92c-0.4,0-0.801,0-1.2,0
			c-2.632-0.808-4.123-2.757-5.521-4.8c-0.554,0.727-0.084,2.476-0.239,3.601c0.178,0.701,1.295,0.464,1.199,1.439
			c0.073,2.327,0.814,3.986,1.92,5.28c0.766,1.075,1.491,2.189,1.92,3.6c0.032,0.592,0.037,1.156-0.239,1.44
			c-1.067,0.106-1.271-0.65-1.92-0.96c-1.004-0.757-1.538-1.982-2.16-3.12c-0.033-0.447-0.158-0.802-0.48-0.96
			c-0.714,0.966,0.234,3.594-0.479,4.56c0,0.08,0,0.16,0,0.24c-0.156,1.204,0.314,3.035-0.24,3.84c0,0.24,0,0.48,0,0.721
			c-0.155,1.045,0.313,2.713-0.24,3.359c0,0.24,0,0.48,0,0.721c-0.155,1.124,0.314,2.873-0.24,3.6c0,0.24,0,0.48,0,0.72
			c-0.152,0.887,0.312,2.391-0.239,2.88c0,0.24,0,0.48,0,0.721c-0.157,1.442,0.315,3.516-0.24,4.56c0,0.32,0,0.64,0,0.96
			c0,3.12,0,6.24,0,9.36c-0.015,0.895,0.242,1.518,0.479,2.16c0.542,0.179,0.095,1.346,0.24,1.92
			c0.639-0.253,1.844-0.452,2.16,0.239c0.489,0.871,1.51,1.211,2.88,1.2c2.431,0.05,4.852,0.108,6.96,0.48
			c0.219,0.422,0.841,0.438,1.2,0.72c0.637,3.802-7.763,2.366-10.32,1.68c-1.292-0.548-2.39-1.289-3.12-2.399
			c-0.486-0.427-0.157-0.977-0.72-0.24c-0.55,1.131-0.776,2.583-0.72,4.32c0.47,4.337-1.645,10.618,1.2,12.96
			c1.008,4.501-1.091,7.398-1.44,11.28c-0.602,2.278,0.155,5.915-0.479,8.159c0,0.32,0,0.641,0,0.96
			c2.651,0.548,6.311,0.09,9.359,0.24c0.561,0,1.12,0,1.681,0c8.479-0.56,18-0.08,26.88-0.24c6.399,0,12.8,0,19.2,0
			c0.8,0,1.6,0,2.399,0c0-0.079,0-0.159,0-0.239c0.8,0,1.601,0,2.4,0c0.64,0,1.28,0,1.92,0c-0.231-10.329-1.584-19.537-5.521-26.16
			c-0.046-0.754-0.949-0.651-0.72-1.681c-1.033-1.926-1.824-4.095-2.399-6.479c-0.355-0.045-0.221-0.58-0.24-0.96
			c-0.355,0.045-0.221,0.58-0.24,0.96c-0.396-0.106-0.396-1.813,0-1.92c-0.371-1.949-0.67-3.97-0.96-6
			c-0.547-0.333-0.09-1.671-0.24-2.4c-0.097-1.022,0.232-2.473-0.24-3.12c-0.059-2.246-2.259-5.428-0.479-7.2
			c3.935,1.614,2.954,9.397,3.6,14.16c-0.017,1.617,0.284,2.917,0.721,4.08c0.664,1.576,1.164,3.316,1.68,5.04
			c0.336,1.665,0.945,3.056,1.68,4.32c0.943,1.217,1.322,2.998,2.16,4.32c0.256,0.224,0.075,0.885,0.72,0.72
			c0.106-0.214,0.211-0.43,0.48-0.48c0.731-0.228,0.426-1.494,0.479-2.399c-0.557-1.363-0.082-3.758-0.239-5.521
			c0.165-2.006-0.406-3.274-0.24-5.279c-0.552-0.568-0.086-2.154-0.24-3.12c-0.547-0.333-0.089-1.671-0.24-2.4c0-0.8,0-1.6,0-2.399
			c0-0.4,0-0.801,0-1.2c-0.559-3.281-0.08-7.6-0.239-11.28c0.128-3.712-0.276-7.956,0.239-11.28c0.263-0.698,0.225-1.695,0.721-2.16
			c0.033-1.087,0.367-1.872,0.72-2.64c0.239-2.081,0.562-4.079,1.2-5.76c-0.739-0.334-2.837,3.204-4.561,3.359
			c-0.08,0-0.16,0-0.239,0c-0.602,0.76-1.641,1.08-2.641,1.44c-7.138,2.552-16.473,5.163-26.16,3.6
			c-1.452-0.108-0.4-1.771,0.24-1.92c0.08,0,0.16,0,0.24,0c0.626-0.093,1.316-0.123,1.68-0.479c1.044-0.557,3.117-0.083,4.561-0.24
			c0.399,0,0.8,0,1.199,0c0.411-0.549,1.832-0.088,2.641-0.24c3.274-0.165,6.254-0.626,8.64-1.68c0.697-0.423,1.693-0.547,2.64-0.72
			c0.69,0.05,1.22-0.062,1.44-0.48c1.363-0.478,2.393-1.287,3.84-1.68c1.37-1.03,2.933-1.868,4.08-3.12
			c0.04-0.923,2.394-1.096,2.4,0c1.136-2.449,0.84-6.877-0.24-9.12c-1.25,0.11-2.501,0.22-3.6,0.48c0-0.08,0-0.16,0-0.24
			c-1.269,0.012-2.453,0.107-3.36,0.479c-1.558,0.363-3.175,0.666-5.04,0.721c-4.474,0.646-9.09,1.149-13.44,1.92
			c-1.492,0.427-3.191,0.647-5.04,0.72c-0.651,0.148-1.904-0.305-2.159,0.24c-2.438,0.256-10.141,1.712-10.08-1.2
			c0.039-1.913,2.781-1.352,4.56-1.44c3.148-0.156,7.287-0.756,10.08-0.96c4.675-0.524,9.258-1.143,13.68-1.92
			c3.728-0.833,7.669-1.451,12-1.68c0.24,0,0.48,0,0.721,0c0-0.08,0-0.16,0-0.24c3.019-0.821,2.533-5.146,4.08-7.439
			c0.172-1.404,0.063-1.64,0-3.12c-0.372-0.349-0.504-0.937-0.48-1.681c-0.521,0.041-0.108-0.851-0.24-1.199
			c-0.396-0.26-0.396-2.142,0-2.4c0.472-2.648,1.212-5.029,1.2-8.16c0.061-2.66,0.108-5.332,0.48-7.68c0-1.44,0-2.88,0-4.32
			c0.158-2.002-0.318-4.638,0.239-6.24c0-0.319,0-0.64,0-0.96c0-2,0-4,0-6c0.159-2.48-0.318-5.599,0.24-7.68c0-0.88,0-1.76,0-2.64
			c-0.231-9.832,0.163-19.037,1.44-27.36c0.08,0,0.16,0,0.24,0c0.012-1.269,0.106-2.453,0.479-3.36
			c0.353-3.487,0.961-6.719,1.68-9.84c-0.092-2.412,0.749-3.892,0.721-6.24c0.08,0,0.159,0,0.239,0
			c-0.144-1.984,0.712-2.969,0.721-4.8c0.186-3.894,0.914-7.246,0.96-11.28c0.153-0.966-0.313-2.552,0.239-3.12
			c0.036-4.604-0.21-9.49,0.48-13.439c0.08,0,0.16,0,0.24,0c-0.033-1.313-0.101-2.661,1.439-2.4c0.014,0.307,0.308,0.333,0.24,0.72
			c0.414,1.187,0.497,2.703,0.72,4.08c0,0.561,0,1.12,0,1.681c0.399,1.523,0.399,4.716,0,6.239c0,0.08,0,0.16,0,0.24
			c0,1.84,0,3.681,0,5.521c0.844-1.797,1.435-3.847,1.92-6c0.08,0,0.16,0,0.24,0c0.155-0.886,0.246-1.834,0.72-2.4
			c0.583-3.498,1.998-6.162,2.4-9.84c0.08,0,0.16,0,0.24,0c0.131-0.349-0.28-1.24,0.24-1.2c1.125-7.274,1.988-14.811,2.64-22.56
			c0-0.721,0-1.44,0-2.16c0.554-1.287-0.122-3.803,0.479-5.04c0-0.4,0-0.8,0-1.2c0-0.96,0-1.92,0-2.88c0-1.36,0-2.72,0-4.08
			c0-1.2,0-2.4,0-3.6c0-1.681,0-3.36,0-5.04c0-0.24,0-0.48,0-0.721C403.312,493.83,402.367,488.054,401.12,482.581z M300.8,676.741
			c1.504-2.447,4.009-4.521,6-6.96c1.662-2.034,2.843-4.121,4.561-6.24c1.045-0.244,1.104,0.496,1.68,0.72
			c-0.085,2.315-1.543,3.258-2.16,5.04c-1.751,2.729-3.908,5.052-6.479,6.96c-1.433,0.523-2.898,2.173-4.32,1.92
			C299.95,677.331,300.93,677.591,300.8,676.741z M326.96,730.741c-2.951,2.532-5.996-2.024-5.76-5.76
			c0.136-0.585,0.206-1.234,0.96-1.2c1.085,0.814,1.431,2.527,2.4,3.84C325.44,728.813,327.347,729.379,326.96,730.741z
			 M354.32,689.461c-0.076-2.001,2.896-0.739,3.84-2.16c0.555-0.325,1.07-0.689,1.92-0.72c0.045-0.204-0.094-0.227-0.239-0.24
			c0-0.08,0-0.16,0-0.239c-3.006-1.395-6.752-2.049-10.561-2.641c-1.469-0.132-2.756-0.444-3.84-0.96
			c-1.135-0.626-2.683-0.838-4.08-1.2c-1.168-0.271-1.889-0.991-2.16-2.16c0.244-0.155,0.248-0.552,0.72-0.479
			c2.427-0.266,3.475,0.846,5.521,0.96c1.309,0.211,2.481,0.559,3.6,0.96c3.279,1.041,7.304,1.337,10.561,2.4
			c1.545,0.215,2.688,0.831,3.84,1.439C370.723,687.386,354.433,692.415,354.32,689.461z M380.721,687.541
			c-2.104,0.264-3.066-0.613-4.801-0.72c-2.243-0.637-4.27-1.49-6-2.641c-0.501-0.458-1.146-0.773-1.92-0.96
			c-1.334-0.426-2.667-0.852-3.84-1.439c-2.375-0.665-4.741-1.339-7.439-1.681c-1.908-0.172-3.68-0.479-5.28-0.96
			c-4.153-0.886-8.526-1.554-12.72-2.399c-2.097-0.783-5.436-0.325-5.28-3.36c1.603-1.079,4.153,0.071,5.76,0.48
			c3.567,0.992,7.456,1.664,11.521,2.16c2.971,0.148,5.288,0.951,8.16,1.199c0,0.08,0,0.16,0,0.24c2.748,0.852,5.83,1.369,8.64,2.16
			c1.115,0.244,2.003,0.717,2.88,1.2c1.647,0.992,3.279,2.001,5.52,2.399c1.706,0.295,3.172,0.829,4.801,1.2c0,0.08,0,0.16,0,0.24
			c0.134,0.346,0.374,0.586,0.72,0.72c0.08,0,0.16,0,0.24,0C382.033,686.195,381.775,687.452,380.721,687.541z M381.44,670.981
			c-0.045,0.355-0.58,0.22-0.96,0.239c-0.59,0.45-1.645,0.437-2.4,0.721c-0.16,0-0.319,0-0.479,0c-1.866,0.613-3.818,1.142-6,1.439
			c-2.332,0.228-4.336,0.784-6.72,0.96c-0.647,0.554-2.315,0.085-3.36,0.24c-0.32,0-0.64,0-0.96,0
			c-1.363-0.157-3.355,0.315-4.32-0.24c-0.399,0-0.8,0-1.2,0c-1.124-0.155-2.874,0.314-3.6-0.24c-0.16,0-0.32,0-0.48,0
			c-2.903,0.024-5.705-0.054-8.159-0.479c-0.765-0.517-2.295-0.266-2.641-1.2c0.028-1.172,1.367-1.033,2.16-1.439
			c0.887-0.399,3.434-0.399,4.32,0c0.88,0,1.76,0,2.64,0c1.363,0.156,3.355-0.316,4.32,0.239c0.8,0,1.6,0,2.399,0
			c2.561,0,5.12,0,7.681,0c1.789,0.029,3.491-0.028,4.8-0.479c1.423-0.417,2.961-0.719,4.56-0.96c2.555-0.326,4.554-1.207,7.2-1.44
			c0-0.08,0-0.16,0-0.24c1.016,0.005,1.542-0.456,2.16,0.24C383.828,668.881,382.88,671.16,381.44,670.981z"/>
			</g>
			<g id="gold">
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M243.92,465.78c0-0.08,0-0.159,0-0.239
			c-0.349,0.131-1.24-0.281-1.2,0.239c-0.457,0.743-1.38,1.021-1.68,1.92c-0.08,0-0.16,0-0.24,0
			c-1.548,1.574-2.145,5.374-2.16,7.681c1.022,0.142,1.587-0.173,2.4-0.24c0.187-0.054,0.474-0.007,0.48-0.24
			c0.489-0.87,1.685-1.035,1.92-2.16c0.08,0,0.16,0,0.24,0c0.672-0.447,0.925-1.314,1.2-2.159
			C245.388,469.05,245.921,466.052,243.92,465.78z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M223.52,502.74c-1.068-0.188-1.44,0.32-2.4,0.24
			c-0.295,1.945-1.621,2.859-1.92,4.8c-0.113,1.247-0.541,2.18-0.48,3.601c0,0.32,0,0.64,0,0.96c1.896-2.345,4.217-4.264,5.04-7.68
			C223.469,503.696,224.396,502.904,223.52,502.74z"/>
			</g>
			<g>
				<path fillRule="evenodd" clipRule="evenodd" d="M382.16,361.141c-0.939-1.141-1.979-2.181-3.12-3.12
			C379.98,359.161,381.021,360.201,382.16,361.141z"/>
				<polygon fillRule="evenodd" clipRule="evenodd" points="225.827,444.068 225.826,444.069 225.825,444.07 		" />
				<path fillRule="evenodd" clipRule="evenodd" d="M310.641,212.34c0.711,0.889,1.326,1.874,1.68,3.12
			c0.349,0.931,0.643,1.917,1.2,2.64c0,0.08,0,0.16,0,0.24c0.905,2.054,0.868,5.051,3.12,5.76c0.225-0.203,0.513-1.53,0-1.68
			c0-0.24,0-0.48,0-0.72c-0.036-1.484-0.413-2.627-0.96-3.6c-0.593-1.888-1.47-3.49-2.16-5.28c-0.809-1.512-1.767-2.874-3.12-3.84
			c-0.08,0-0.16,0-0.24,0C307.071,208.24,309.453,211.82,310.641,212.34z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M382.4,668.341c-0.618-0.696-1.145-0.235-2.16-0.24c0,0.08,0,0.16,0,0.24
			c-2.646,0.233-4.646,1.114-7.2,1.44c-1.599,0.241-3.137,0.543-4.56,0.96c-1.309,0.451-3.011,0.509-4.8,0.479
			c-2.561,0-5.12,0-7.681,0c-0.8,0-1.6,0-2.399,0c-0.965-0.556-2.957-0.083-4.32-0.239c-0.88,0-1.76,0-2.64,0
			c-0.887-0.399-3.434-0.399-4.32,0c-0.793,0.406-2.132,0.268-2.16,1.439c0.346,0.935,1.876,0.684,2.641,1.2
			c2.454,0.426,5.256,0.504,8.159,0.479c0.16,0,0.32,0,0.48,0c0.726,0.555,2.476,0.085,3.6,0.24c0.4,0,0.801,0,1.2,0
			c0.965,0.556,2.957,0.083,4.32,0.24c0.32,0,0.64,0,0.96,0c1.045-0.155,2.713,0.313,3.36-0.24c2.384-0.176,4.388-0.732,6.72-0.96
			c2.182-0.298,4.134-0.826,6-1.439c0.16,0,0.319,0,0.479,0c0.756-0.284,1.811-0.271,2.4-0.721c0.38-0.02,0.915,0.116,0.96-0.239
			C382.88,671.16,383.828,668.881,382.4,668.341z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M324.688,727.766c-0.002-0.003-0.005-0.007-0.007-0.01
			c-0.172-0.254-0.346-0.505-0.519-0.757c-0.004-0.005-0.008-0.011-0.012-0.017c0.001,0.001,0.001,0.002,0.002,0.003
			c-0.004-0.005-0.007-0.01-0.011-0.015c0.003,0.004,0.006,0.008,0.009,0.012c-0.683-1.187-1.075-2.514-1.991-3.201
			c-0.754-0.034-0.824,0.615-0.96,1.2c-0.236,3.735,2.809,8.292,5.76,5.76C327.33,729.438,325.609,728.857,324.688,727.766z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M290.96,206.1c0.574,1.186,1.897,1.623,2.88,2.4
			c0.655-0.175,0.483,0.477,0.96,0.48c5.823,4.417,10.615,9.865,15.601,15.12c0,0.08,0,0.16,0,0.24
			c1.153,1.246,2.058,2.742,3.36,3.84c0,0.08,0,0.16,0,0.24c1.021,1.059,1.637,2.523,2.88,3.36c0.515,0.845,1.379,1.34,1.68,2.4
			c1.072,1.167,1.828,2.652,3.12,3.6c0,0.08,0,0.16,0,0.24c0.325,1,2.647,2.978,3.057,1.942c0.021-0.009,0.042-0.012,0.063-0.022
			c0-0.16,0-0.32,0-0.48c-0.076-0.324-0.342-0.458-0.24-0.96c-0.786-1.294-1.55-2.61-2.64-3.6c0-0.08,0-0.16,0-0.24
			c-1.302-1.819-2.448-3.792-4.08-5.28c0-0.08,0-0.16,0-0.24c-5.998-7.522-12.043-14.998-19.681-20.88c-0.08,0-0.16,0-0.239,0
			c-1.172-1.309-2.786-2.174-4.32-3.12c-0.08,0-0.16,0-0.24,0c-0.338-1.354-3.176-0.516-2.16,0.72
			C290.96,205.94,290.96,206.02,290.96,206.1z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M328.4,222.9c0,0.08,0,0.16,0,0.24c0.187,0.053,0.279,0.201,0.24,0.48
			c1.654,0.268,0.862-2.352,0.479-2.88c-0.609-2.271-1.295-4.465-1.92-6.72c-0.812-2.308-2.057-4.183-3.6-5.76c-0.4,0-0.8,0-1.2,0
			c0.011,0.33-0.113,0.527-0.24,0.72c0.559,1.361,1.549,2.292,2.4,3.36C326.341,215.359,327.553,218.948,328.4,222.9z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M304.4,676.261c2.571-1.908,4.729-4.231,6.479-6.96
			c0.617-1.782,2.075-2.725,2.16-5.04c-0.576-0.224-0.635-0.964-1.68-0.72c-1.718,2.119-2.898,4.206-4.561,6.24
			c-1.991,2.438-4.496,4.513-6,6.96c0.13,0.85-0.85,0.59-0.72,1.439C301.502,678.434,302.968,676.784,304.4,676.261z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M363.44,684.421c-1.151-0.608-2.295-1.225-3.84-1.439
			c-3.257-1.063-7.281-1.359-10.561-2.4c-1.118-0.401-2.291-0.749-3.6-0.96c-2.046-0.114-3.094-1.226-5.521-0.96
			c-0.472-0.072-0.476,0.324-0.72,0.479c0.271,1.169,0.992,1.889,2.16,2.16c1.397,0.362,2.945,0.574,4.08,1.2
			c1.084,0.516,2.371,0.828,3.84,0.96c3.809,0.592,7.555,1.246,10.561,2.641c0,0.079,0,0.159,0,0.239
			c0.146,0.014,0.284,0.036,0.239,0.24c-0.85,0.03-1.365,0.395-1.92,0.72c-0.944,1.421-3.916,0.159-3.84,2.16
			C354.433,692.415,370.723,687.386,363.44,684.421z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M261.2,284.34c0.1,0.818,1.316-0.363,1.2,0.24
			c-0.258,0.623-0.583,1.177-0.48,2.16c0.38,0.276,1.284,1.254,1.92,0.48c0.051-0.269,0.266-0.374,0.48-0.48
			c0.817-0.703,1.151-1.889,1.68-2.88c0.733-0.787,1.269-1.772,2.4-2.16c3.016-1.624,6.062-3.218,10.08-3.84
			c1.84-0.079,3.464-0.375,5.52-0.24c3.192,1.128,6.155,2.485,8.88,4.08c-0.054,0.187-0.006,0.474-0.239,0.48
			c-1.061,0.86-2.021,1.82-2.881,2.88c-2.807,2.313-5.855,4.385-10.319,5.04c-1.44,0-2.88,0-4.32,0c0,0.08,0,0.16,0,0.24
			c-0.55,0.106-1.152,1.386-0.48,1.92c0.584,0.86,3.604,0.55,4.56,0.24c0.349,0.131,1.24-0.28,1.2,0.24
			c-0.283,0.038-0.682-0.041-0.72,0.24c-0.661,0.698-3.094-0.375-2.88,1.2c0.054,0.186,0.006,0.474,0.24,0.48c0,0.08,0,0.16,0,0.24
			c0.363,0.292,2.067,0.471,2.4,0c0.839,0.04,1.536-0.064,1.92-0.48c2.38-1.06,4.425-2.456,6.24-4.08
			c0.534-0.556,0.936,0.082,1.68,0c0.454-0.586,0.009-2.072,0.72-2.4c1.122-0.023,1.039-1.62,2.16-0.96
			c0.355,0.278,0.605,0.278,0.96,0c-0.001-0.015-0.007-0.025-0.008-0.04c-0.054-0.73-0.535-1.033-0.472-1.88
			c0.24,0.001,0.285-0.194,0.479-0.24c0.08,0,0.16,0,0.24,0c0.539-0.741,1.179-1.381,1.92-1.92c0.074-0.566,0.833-0.447,0.72-1.2
			c0.384-0.571,0.591-1.145,0-1.68c-1.565-0.274-1.101-2.579-2.64-2.88c-3.675-1.125-7.712-1.888-11.04-3.36
			c-0.902-0.538-2.026-0.854-3.36-0.96c-1.493-0.43-4.321-0.795-6-0.24c-1.186,0.014-2.163,0.237-2.88,0.72
			c-1.351,0.809-2.825,1.495-4.08,2.4c-1.259,0.661-2.219,1.621-2.88,2.88c-0.695,1.847-2.883,3.272-2.16,5.76
			C261.04,284.34,261.12,284.34,261.2,284.34z M264.32,280.98c-0.013-0.413,0.185-0.616,0.48-0.72c1.06-0.86,2.02-1.82,2.88-2.88
			c0.698-0.822,1.722-1.319,2.88-1.68c1.48-0.521,3.456-0.544,5.52-0.48c-0.79,0.81-2.627,0.572-3.36,1.44
			c-2.261,1.019-4.444,2.116-6.48,3.36c-0.957,0.092-1.573,1.776-2.16,1.44C264.267,281.407,264.359,281.259,264.32,280.98z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M252.8,325.14c1.04,0,2.08,0,3.12,0c0.032,0.937,1.157,2.031,2.16,1.2
			c1.007-0.272,1.475-1.085,1.92-1.92c0.229-0.812,0.819-1.261,1.2-1.92c0.822-0.804,0.458-2.924-0.24-3.6
			c-0.937-0.583-0.727-2.313-2.16-2.4c-0.063,0.703,0.542,0.737,0.48,1.44c0.125,1.325-0.138,2.262-0.24,3.36
			c-0.014,0.146-0.036,0.284-0.24,0.24c-0.146-0.014-0.284-0.036-0.24-0.24c-1.03-1.336-4.175-0.51-5.52,0
			c-3.216,0.704-6.768,1.072-7.92,3.84c-0.595,0.925-0.204,2.917,0.96,2.88c2.332,0.332,1.185-2.815,3.6-2.4
			c1.106-0.014,2.129-0.111,2.88-0.48C252.64,325.14,252.72,325.14,252.8,325.14z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M381.44,685.381c-0.346-0.134-0.586-0.374-0.72-0.72c0-0.08,0-0.16,0-0.24
			c-1.629-0.371-3.095-0.905-4.801-1.2c-2.24-0.398-3.872-1.407-5.52-2.399c-0.877-0.483-1.765-0.956-2.88-1.2
			c-2.81-0.791-5.892-1.309-8.64-2.16c0-0.08,0-0.16,0-0.24c-2.872-0.248-5.189-1.051-8.16-1.199
			c-4.064-0.496-7.953-1.168-11.521-2.16c-1.606-0.409-4.157-1.56-5.76-0.48c-0.155,3.035,3.184,2.577,5.28,3.36
			c4.193,0.846,8.566,1.514,12.72,2.399c1.601,0.48,3.372,0.788,5.28,0.96c2.698,0.342,5.064,1.016,7.439,1.681
			c1.173,0.588,2.506,1.014,3.84,1.439c0.774,0.187,1.419,0.502,1.92,0.96c1.73,1.15,3.757,2.004,6,2.641
			c1.734,0.106,2.696,0.983,4.801,0.72c1.055-0.089,1.313-1.346,0.96-2.16C381.601,685.381,381.521,685.381,381.44,685.381z"/>
			</g>
			<g>
				<path fillRule="evenodd" clipRule="evenodd" fill="#3F3330" d="M315.681,274.5c0-0.08,0-0.16,0-0.24
			c-2.153-0.407-4.139-0.982-6.24-1.44c-0.022,1.067,1.689,2.467,2.399,3.6c0,0.08,0,0.16,0,0.24c1.21,0.87,1.932,2.229,2.881,3.36
			c-0.114,0.754,0.646,0.634,0.72,1.2c0.744,0.136,0.631,1.129,1.439,1.2c0-0.16,0-0.32,0-0.48c0.157-1.284-0.314-3.195,0.24-4.08
			c0-0.16,0-0.32,0-0.48c0-0.88,0-1.76,0-2.64C317.091,274.21,316.103,274.638,315.681,274.5z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#3F3330" d="M365.36,361.38c-1.257-2.183-2.218-4.662-2.88-7.44
			c-0.402-2.718-1.039-5.201-1.2-8.16c0-1.52,0-3.04,0-4.56c0-0.72,0-1.44,0-2.16c-0.615-1.385-0.817-3.183-0.96-5.04
			c0-0.08,0-0.16,0-0.24c-0.549-0.411-0.088-1.832-0.24-2.64c-0.078-0.882,0.205-2.125-0.239-2.64
			c-0.551-0.489-0.087-1.993-0.24-2.88c0-0.08,0-0.16,0-0.24c-0.552-0.568-0.086-2.154-0.24-3.12c0.001-3.841-0.69-6.989-1.68-9.84
			c-0.554-1.047-0.95-2.25-1.2-3.6c-0.143-0.978-0.463-1.777-0.96-2.4c-0.058,1.862-0.641,3.199-0.48,5.28c0,2.4,0,4.8,0,7.2
			c-0.039,3.8,0.476,7.045,0.96,10.32c0.14,0.86-0.71,0.73-1.439,0.72c-0.373-1.147-0.823-2.217-0.72-3.84
			c-0.05-0.75,0.161-1.762-0.24-2.16c-0.548-0.333-0.09-1.671-0.24-2.4c0-0.08,0-0.16,0-0.24c-0.553-0.647-0.085-2.315-0.24-3.36
			c0-0.08,0-0.16,0-0.24c-0.399-1.125-0.399-3.915,0-5.04c0-0.16,0-0.32,0-0.48c0.152-0.808-0.309-2.229,0.24-2.64
			c0.353-1.327,0.517-2.844,0.48-4.56c0-2.16,0-4.32,0-6.48c0-0.08,0-0.16,0-0.24c-0.551-0.489-0.087-1.993-0.24-2.88
			c-0.145-2.576-0.819-4.621-1.44-6.72c-0.233-0.006-0.186-0.293-0.24-0.48c-0.668-0.108,0.188,1.308-0.479,1.2
			c-0.201,0.278-0.316,0.644-0.24,1.2c-0.323,1.997-1.296,3.344-2.399,4.56c0.421,1.579,0.949,3.051,1.439,4.56
			c0.483,0.717,0.706,1.694,0.72,2.88c0.361,3.079,0.716,6.165,0.96,9.36c0.55,0.411,0.089,1.832,0.24,2.64c0,0.88,0,1.76,0,2.64
			c0.558,1.602,0.082,4.238,0.24,6.24c-0.101,6.094,0.326,11.019,1.44,15.6c0.599,2.464,3.062,5.943,0.96,7.68
			c-1.618-0.462-1.688-2.473-2.16-4.08c-0.953-3.687-1.585-7.695-2.16-11.76c-0.553-0.647-0.085-2.315-0.24-3.36
			c0-0.16,0-0.32,0-0.48c-0.399-2.163-0.399-5.998,0-8.16c0-0.32,0-0.64,0-0.96c-0.559-2.082-0.081-5.199-0.24-7.68
			c0-0.08,0-0.16,0-0.24c-0.549-0.411-0.088-1.832-0.239-2.64c-0.065-0.815,0.186-1.946-0.24-2.4
			c-0.362-0.678-0.424-1.657-0.48-2.64c0.054-1.333-0.462-2.098-0.72-3.12c-0.401-0.559-0.627-1.293-0.72-2.16
			c-2.079,1.841-3.295,4.545-5.521,6.24c0,0.08,0,0.16,0,0.24c-0.929,1.231-2.309,2.011-2.64,3.84c0,0.16,0,0.32,0,0.48
			c0,1.04,0,2.08,0,3.12c0,0.08,0,0.16,0,0.24c0.376,0.984,0.364,2.356,0.479,3.6c0.361,3.959,1.692,6.948,2.16,10.8
			c0.548,0.333,0.09,1.671,0.24,2.4c0,0.08,0,0.16,0,0.24c0.072,0.632-0.332,0.788-0.96,0.72c-0.44-0.76-0.628-1.772-0.96-2.64
			c0-0.08,0-0.16,0-0.24c-0.76-1.48-1.139-3.341-1.44-5.28c-0.164-1.596-0.616-2.903-0.96-4.32c-0.383-0.657-0.494-1.586-0.479-2.64
			c0-0.08,0-0.16,0-0.24c-0.188-0.053-0.279-0.201-0.24-0.48c-0.881,0.719-1.739,1.461-2.88,1.92c-0.08,0-0.16,0-0.24,0
			c-0.703,0.897-2.618,0.581-3.359,1.44c-0.132,0.909-0.581,1.499-0.48,2.64c-0.239,2.081-0.562,4.079-1.2,5.76
			c0,0.08,0,0.16,0,0.24c0.011,1.371-0.092,2.628-0.479,3.6c-0.455,2.266-1.003,4.438-0.96,7.2c-0.155,1.045,0.313,2.713-0.24,3.36
			c0,0.08,0,0.16,0,0.24c-0.021,2.539,0.118,5.238-0.24,7.44c0,0.16,0,0.32,0,0.48c0.558,1.363,0.082,3.758,0.24,5.52
			c0,0.24,0,0.48,0,0.72c0.553,0.647,0.085,2.315,0.24,3.36c0,0.08,0,0.16,0,0.24c0.558,1.602,0.081,4.238,0.239,6.24
			c0,0.16,0,0.32,0,0.48c0.554,0.647,0.086,2.314,0.24,3.36c2.398,1.071,3.756,7.827,0.72,9.6c-0.192,0.833,0.358,0.922,0.24,1.68
			c1.256,0.164,2.983-1.139,4.08-1.92c2.532-2.427,5.73-4.189,7.68-7.2c-0.256-3.264-0.751-6.289-1.199-9.36
			c-0.548-0.333-0.09-1.67-0.24-2.4c0-0.08,0-0.16,0-0.24c-0.333-1.298-0.586-4.583,0.24-5.52c0-0.16,0-0.32,0-0.48
			c-0.548-0.333-0.09-1.671-0.24-2.4c1.236,0.683,1.314,2.525,2.16,3.6c0.1,2.22,0.688,3.952,1.439,5.52
			c0.883,1.198,1.412,2.749,2.16,4.08c0,0.08,0,0.16,0,0.24c0.762,0.279,0.592,1.488,1.44,1.68c1.018-1.302,2.661-1.979,2.16-4.8
			c0-0.24,0-0.48,0-0.72c-0.548-0.333-0.09-1.67-0.24-2.4c0-0.08,0-0.16,0-0.24c-0.548-0.333-0.09-1.671-0.24-2.4
			c-0.004-1.044-0.079-2.159,0.24-2.88c2.597-2.118,5.296,3.632,6.96,5.76c2.282,2.919,4.171,5.046,5.52,6.96
			c2.104,0.023,3.313-0.848,4.801-1.44C365.503,362.038,365.566,361.574,365.36,361.38z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#3F3330" d="M381.44,335.94c-0.618-0.662-0.742-1.817-1.2-2.64
			c-2.918-5.02-2.719-15.467-0.479-21.12c0.085-1.115,0.308-2.092,0.72-2.88c1.672-7.448,1.545-17.733-0.24-24.96
			c-0.371-0.349-0.504-0.936-0.479-1.68c-1.089-2.432-2.2-4.84-3.12-7.44c-0.188-0.053-0.279-0.201-0.24-0.48
			c-1.222-1.498-1.949-3.491-2.88-5.28c-0.003-0.477-0.655-0.305-0.48-0.96c-9.354-18.405-23.923-31.597-33.359-49.92
			c-0.953-0.647-1.315-1.885-1.92-2.88c0-0.08,0-0.16,0-0.24c-0.882-0.798-1.323-2.037-1.921-3.12
			c0.011-0.33-0.112-0.527-0.239-0.72c-0.787-0.493-0.917-1.644-1.44-2.4c-1.941-4.219-4.294-8.026-6.72-11.76
			c-0.683-0.688-1.944-2.496-0.96-3.36c1.439,0.001,1.398,1.481,2.16,2.16c1.086,1.234,2.01,2.629,2.88,4.08
			c1.235,2.605,2.745,4.935,4.08,7.44c1.042,1.118,1.639,2.681,2.399,4.08c0.112,0.848,0.606,1.314,0.96,1.92
			c1.43,1.69,2.347,3.894,3.601,5.76c0.131,0.989,0.835,1.405,1.2,2.16c0.748,0.611,1.173,1.546,1.68,2.4
			c0.092,0.788,0.702,1.058,0.96,1.68c1.061,1.099,1.82,2.5,2.64,3.84c-0.175,0.655,0.478,0.483,0.48,0.96
			c0.996,0.923,1.564,2.275,2.399,3.36c0,0.08,0,0.16,0,0.24c1.542,1.579,2.668,3.573,4.08,5.28
			c3.088,4.513,6.528,8.672,9.841,12.96c0.854,0.586,1.296,1.584,1.92,2.4c1.46,2.06,2.852,4.188,4.08,6.48
			c0.249-3.13-1.01-6.83-1.44-10.08c-0.455-1.065-0.855-2.185-0.96-3.6c-1.198-3.122-2.014-6.626-2.88-10.08
			c-0.345-0.296-0.455-0.825-0.48-1.44c-0.818-1.741-1.559-3.562-2.16-5.52c-0.674-1.245-1.216-2.624-1.92-3.84
			c-0.759-0.601-1.079-1.641-1.439-2.64c-0.068-1.132-0.981-1.419-1.2-2.4c-1.009-1.231-1.729-2.751-2.4-4.32
			c0.001-0.241-0.194-0.285-0.239-0.48c-0.581-0.299-0.776-0.983-0.96-1.68c0-0.241-0.194-0.286-0.24-0.48
			c-2.5-3.9-5.067-7.733-7.92-11.28c-1.176-1.225-2.372-2.428-3.36-3.84c-1.46-1.26-2.82-2.62-4.08-4.08
			c-1.606-1.194-2.981-2.618-4.56-3.84c-0.146,0.014-0.284,0.036-0.24,0.24c-0.496-0.315-1.621-1.243-1.2-1.68
			c-1.775-1.504-3.568-2.992-5.76-4.08c-0.907-0.613-1.861-1.179-2.64-1.92c-2.736-1.184-5.458-2.382-8.16-3.6
			c-1.414-0.586-2.872-1.128-4.08-1.92c-2.324-0.956-4.842-1.719-7.44-2.4c-1.263-0.257-2.496-0.544-3.359-1.2
			c-6.573-1.827-12.347-4.454-19.92-5.28c-0.8,0-1.6,0-2.4,0c-3.853,0.068-7.104,0.736-10.08,1.68
			c-1.779,0.781-3.659,1.461-5.52,2.16c-1.432,0.167-2.192,1.007-3.36,1.44c-0.757,0.843-1.964,1.236-3.12,1.68
			c-1.342,0.258-2.252,0.948-3.36,1.44c-0.664,0.536-1.466,0.934-2.4,1.2c-1.487,0.273-2.444,1.076-3.6,1.68
			c-2.724,2.076-5.729,3.871-8.64,5.76c-0.981,0.299-1.652,0.908-2.4,1.44c-2.651,2.469-4.916,5.324-8.64,6.72
			c-0.418-0.018-0.747,0.054-0.96,0.24c-0.566,0.474-1.515,0.565-2.4,0.72c-0.08,0-0.16,0-0.24,0c-1.06,0.62-2.554,0.806-3.84,1.2
			c-3.457,1.597,0.761,9.77,1.2,12.72c0.553,1.047,0.95,2.25,1.2,3.6c0.477,2.083,1.146,3.974,1.92,5.76
			c0.738,1.022,1.166,2.354,1.68,3.6c0.334,1.586,1.122,2.719,1.68,4.08c0.847,1.153,1.325,2.675,1.92,4.08
			c0.016,1.104,0.682,1.558,0.96,2.4c0.843,1.157,1.351,2.65,2.16,3.84c0.131,0.989,0.835,1.404,1.2,2.16
			c0.78,0.66,1.152,1.728,1.68,2.64c0.25,1.431,1.573,1.788,1.44,3.6c-1.291,1.867-3.118-0.716-3.6-1.92c0-0.08,0-0.16,0-0.24
			c-0.781-0.659-1.153-1.727-1.68-2.64c0.011-0.731-0.507-0.934-0.72-1.44c-0.53,0.103-0.601-1.442-0.96-0.72
			c1.168,3.552,2.723,6.717,4.08,10.08c0.718,0.882,1.061,2.14,1.44,3.36c0.574,2.467,1.662,4.418,2.4,6.72
			c0.584,0.536,0.673,1.566,0.96,2.4c0.792,3.127,1.788,6.052,2.64,9.12c0.635,1.125,0.924,2.596,1.44,3.84c0,0.08,0,0.16,0,0.24
			c0.645,0.715,0.929,1.791,1.44,2.64c0,0.08,0,0.16,0,0.24c0.903,0.856,1.498,2.022,2.16,3.12c0.52-0.536-0.501-1.566-0.48-2.4
			c0-0.08,0-0.16,0-0.24c-0.823-1.337-1.256-3.063-1.68-4.8c-0.624-2.496-1.35-4.891-2.16-7.2c-0.571-0.869-0.708-2.172-0.96-3.36
			c-0.144-1.536-0.573-2.787-0.96-4.08c-0.891-1.909-1.47-4.13-2.16-6.24c-0.073-1.207-0.664-1.896-0.96-2.88
			c0.274-4.937,5.189,1.633,6.96,2.4c0,0.08,0,0.16,0,0.24c0.903,0.618,1.687,1.354,2.88,1.68c1.208,0.712,2.668,1.172,3.84,1.92
			c3.078,1.002,6.203,1.957,9.84,2.4c0.838,0.122,1.812,0.108,2.4,0.48c0.398,0.402,1.409,0.191,2.16,0.24
			c0.729,0.15,2.067-0.307,2.4,0.24c11.53,1.59,23.782,2.458,32.4,6.96c0.935,0.266,1.736,0.664,2.4,1.2
			c4.418,1.982,9.013,3.788,15.12,4.08c1.76,0,3.52,0,5.28,0c0.399,0,0.8,0,1.199,0c0.647-0.553,2.315-0.085,3.36-0.24
			c1.28,0,2.56,0,3.84,0c0.96,0,1.92,0,2.88,0c2.742-0.058,5.033-0.567,7.2-1.2c0.697-0.422,1.693-0.547,2.64-0.72
			c2.265-0.008,6.121-2.803,6.48-0.24c0.288,2.056-2.783,1.793-4.56,2.64c0.763,1.877,2.198,3.081,3.359,4.56
			c1.759,1.682,3.169,3.711,4.561,5.76c0,0.08,0,0.16,0,0.24c1.81,1.87,3.01,4.35,4.319,6.72c0,0.08,0,0.16,0,0.24
			c1.277,1.443,1.869,3.571,2.88,5.28c0.15,0.89,0.675,1.405,0.96,2.16c0.902,1.178,1.311,2.85,1.44,4.8
			c0.308,1.693,0.632,3.368,0.96,5.04c0.399,1.843,0.399,5.357,0,7.2c-0.612,6.107-1.783,11.657-2.16,18
			c-0.153,0.887,0.311,2.391-0.24,2.88c0,0.08,0,0.16,0,0.24c-0.155,1.204,0.315,3.035-0.239,3.84c-0.176,1.177-0.373,2.239,0,3.36
			c0.554,0.726,0.084,2.476,0.239,3.6c0,0.16,0,0.32,0,0.48c0.726,1.41-0.317,3.146,0.24,4.56c-0.076,0.557,0.039,0.921,0.24,1.2
			c0.359,0.681,0.592,1.488,0.72,2.4c0.12,1.48,0.75,2.45,1.2,3.6c1.139,1.981,2.014,4.226,3.12,6.24
			c-0.011,0.331,0.113,0.527,0.24,0.72c2.522,2.837,3.877,6.843,7.68,8.4c0.258-0.622,0.868-0.892,0.96-1.68
			c0.592-1.168,1.376-2.144,1.92-3.36c0.08,0,0.16,0,0.24,0c0.013-0.307,0.308-0.333,0.24-0.72c0.479-0.961,0.961-1.919,1.439-2.88
			c0.08,0,0.16,0,0.24,0c0.343-1.177,1.319-1.72,1.44-3.12c0-0.08,0-0.16,0-0.24c-0.283-1.237-1.161-1.88-1.681-2.88
			c-0.667-1.252-1.602-2.238-2.16-3.6c0.001-0.241-0.194-0.286-0.239-0.48c-1.178-1.463-2.171-3.11-2.88-5.04
			c-0.033-0.447-0.158-0.802-0.48-0.96c-1.145-2.55-4.686-7.965-1.92-10.56c1.552,1.408,2.212,3.708,4.08,4.8
			c0.976,0.304,1.785,0.775,2.399,1.44c2.39,1.131,4.736,2.304,6.961,3.6c0.157,0.322,0.513,0.447,0.96,0.48
			c1.155,0.125,1.883,0.677,2.64,1.2C388.298,345.402,383.617,341.284,381.44,335.94z M310.16,208.98c0.08,0,0.16,0,0.24,0
			c1.354,0.966,2.312,2.329,3.12,3.84c0.69,1.79,1.567,3.392,2.16,5.28c0.547,0.973,0.924,2.116,0.96,3.6c0,0.24,0,0.48,0,0.72
			c0.513,0.15,0.225,1.478,0,1.68c-2.252-0.708-2.215-3.706-3.12-5.76c0-0.08,0-0.16,0-0.24c-0.558-0.723-0.852-1.709-1.2-2.64
			c-0.354-1.246-0.969-2.231-1.68-3.12C309.453,211.82,307.071,208.24,310.16,208.98z M321.44,238.02c0-0.08,0-0.16,0-0.24
			c-1.292-0.948-2.048-2.433-3.12-3.6c-0.301-1.06-1.165-1.555-1.68-2.4c-1.243-0.837-1.859-2.301-2.88-3.36c0-0.08,0-0.16,0-0.24
			c-1.303-1.098-2.207-2.594-3.36-3.84c0-0.08,0-0.16,0-0.24c-4.985-5.255-9.777-10.703-15.601-15.12
			c-0.477-0.003-0.305-0.655-0.96-0.48c-0.982-0.777-2.306-1.214-2.88-2.4c0-0.08,0-0.16,0-0.24c-1.016-1.236,1.822-2.073,2.16-0.72
			c0.08,0,0.16,0,0.24,0c1.534,0.946,3.148,1.811,4.32,3.12c0.079,0,0.159,0,0.239,0c7.638,5.882,13.683,13.357,19.681,20.88
			c0,0.08,0,0.16,0,0.24c1.632,1.488,2.778,3.461,4.08,5.28c0,0.08,0,0.16,0,0.24c1.09,0.99,1.854,2.306,2.64,3.6
			c-0.102,0.502,0.164,0.636,0.24,0.96C324.682,241.499,321.805,239.141,321.44,238.02z M328.641,223.62
			c0.039-0.279-0.054-0.427-0.24-0.48c0-0.08,0-0.16,0-0.24c-0.848-3.952-2.06-7.541-3.84-10.56c-0.852-1.068-1.842-1.999-2.4-3.36
			c0.127-0.193,0.251-0.39,0.24-0.72c0.4,0,0.8,0,1.2,0c1.543,1.577,2.788,3.452,3.6,5.76c0.625,2.255,1.311,4.45,1.92,6.72
			C329.503,221.269,330.295,223.888,328.641,223.62z M382.16,361.141c-1.14-0.94-2.18-1.98-3.12-3.12
			C380.181,358.96,381.221,360,382.16,361.141z"/>
			</g>
			<path id="black" fillRule="evenodd" clipRule="evenodd" d="M407.36,514.261c0-0.08,0-0.16,0-0.24
		c0.556-0.885,0.084-2.796,0.24-4.08c-0.157-1.442,0.316-3.516-0.24-4.56c0-0.96,0-1.92,0-2.88c-0.154-0.966,0.313-2.553-0.24-3.12
		c0-0.16,0-0.32,0-0.48c-0.15-0.729,0.308-2.067-0.239-2.399c-1.15-16.53-7.723-27.639-13.44-39.601
		c2.601-1.196,4.978-3.039,5.521-6c0.551-3.009-1.854-5.711-1.681-8.88c-2.093-3.268-4.88-5.84-7.2-8.88
		c-0.162-0.558-0.797-0.643-0.96-1.2c-0.413-0.786-1.266-1.134-1.68-1.92c-0.992-0.768-1.783-1.736-2.88-2.4
		c-0.943-1.456-2.931-1.869-3.84-3.359c-0.623-1.379-2.897-3.181-4.32-4.561c-1.059-1.104-2.541-3.055-3.6-3.359
		c-1.295-1.106-2.882-1.919-5.521-1.68c-0.456-0.423-1.692-0.068-1.68-0.96c-0.046-0.194-0.241-0.239-0.24-0.48
		c-0.796-1.604-2.301-2.5-3.6-3.6c-1.269-0.53-1.844-2.057-3.12-2.4c-1.573-0.423-3.428,0.627-5.28,0.24
		c-0.959-0.201-3.47-2.229-5.04-3.36c-3.744-2.698-5.97-4.548-9.6-7.2c-1.492-1.09-3.891-2.299-4.801-3.36
		c-2.629-3.067-2.385-8.496-3.84-12.24c2.724-2.077,5.954-3.646,8.4-6c1.109-1.15,2.963-1.556,3.728-3.051
		c-0.316,0.175-0.593,0.391-0.862,0.614c0.27-0.223,0.545-0.439,0.862-0.614c0,0,0,0,0,0c0.039-0.021,0.072-0.049,0.112-0.069
		c0-0.053,0-0.106,0-0.16c0.122-0.304,0.216-0.638,0.24-1.041c0.204,0.044,0.227-0.094,0.24-0.24c1.97-0.864,0.252-4.17,0.239-6
		c-0.021-0.34-0.039-0.68,0.24-0.72c0.865,1.695,1.593,3.527,2.88,4.8c0.022-1.338,1.02-1.701,1.681-2.4
		c0.948-0.572,1.49-1.55,2.159-2.4c0.571-0.549,1.366-0.874,1.44-1.92c0.397-0.259,0.397-2.142,0-2.4
		c-0.028-1.652-0.278-3.082-0.48-4.56c1.508,1.105,2.988,3.173,4.801,5.52c1.502,1.944,2.77,5.14,4.8,5.521
		c2.041,0.382,3.9-1.072,6-1.68c1.992,1.608,2.858,4.342,4.56,6.24c0.665,1.338,2.142,3.135,3.12,3.36
		c0.877,0.723,2.122,1.078,2.88,1.92c3.235-2.618,5.507-6.885,7.681-11.28c0.605-1.224,1.969-3.324,1.92-4.32
		c-0.087-1.743-3.594-5.726-4.561-7.2c-1.714-2.613-2.589-5.051-4.08-7.44c0-0.24,0-0.48,0-0.72c0.388-0.068,0.413,0.227,0.721,0.24
		c6.636,3.124,12.287,7.233,20.399,8.88c0.101-2.035-0.705-4.028-2.16-5.28c-1.449-1.248-3.605-1.883-4.56-3.6
		c-0.08,0-0.16,0-0.24,0c-0.817-1.182-2.063-1.938-2.88-3.12c-0.194-0.045-0.239-0.241-0.479-0.24
		c-0.594-0.287-0.938-0.822-1.2-1.44c0.08,0,0.16,0,0.24,0c-0.636-0.645-0.944-1.616-1.681-2.16c0.011-0.411-0.178-0.622-0.479-0.72
		c-0.84-1.64-1.708-3.252-2.641-4.8c0-0.16,0-0.32,0-0.48c-0.224-0.176-0.363-0.437-0.479-0.72c0.021-0.34,0.039-0.68-0.24-0.72
		c-0.767-2.594-1.658-5.062-1.44-8.64c0.55-0.411,0.089-1.832,0.24-2.64c0.804-6.556,3.325-11.396,3.36-18.72
		c0.325-0.074,0.199-0.601,0.24-0.96c0-1.44,0-2.88,0-4.32c0.021-0.34,0.039-0.68-0.24-0.72c0-0.16,0-0.32,0-0.48
		c-0.153-0.887,0.311-2.391-0.24-2.88c-0.202-9.126-5.497-16.197-7.68-24.72c-2.219-8.662-3.406-17.614-6-26.4
		c-2.407-8.154-5.938-15.641-9.84-22.56c-3.91-6.933-8.225-13.449-13.2-18.96c-0.67-0.85-1.212-1.828-2.16-2.4
		c-0.539-0.582-0.962-1.278-1.68-1.68c-0.815-1.185-2.022-1.977-3.12-2.88c-3.97-4.271-9.546-6.935-13.92-10.8
		c-6.591-2.8-13.035-5.691-20.16-7.92c-6.909-2.162-13.998-4.858-21.84-6c0.041-0.521-0.851-0.109-1.2-0.24
		c-0.57-0.398-2.789-0.398-3.36,0c-0.422,0.138-1.41-0.29-1.44,0.24c-9.644,0.604-16.981,5.095-24.24,8.4
		c-7.244,3.298-13.508,8.118-19.68,12.72c-0.926,0.914-2.116,1.564-2.88,2.64c-3.195,3.524-11.13,2.31-13.68,6.48
		c-0.233,0.007-0.187,0.294-0.24,0.48c0,0.8,0,1.6,0,2.4c-0.044,0.204,0.094,0.227,0.24,0.24c0.035,1.235,0.842,2.627,0.72,3.84
		c-0.107,1.066-0.962,2.042-1.44,3.36c-1.193,3.292-2.285,7.467-3.6,10.32c-0.881,1.911-2.166,3.774-3.12,5.76
		c-2.758,5.74-6.721,10.593-9.12,16.8c-3.28,8.486-5.266,17.306-6.48,28.32c-0.542,0.179-0.094,1.346-0.24,1.92
		c-0.554,0.726-0.084,2.475-0.24,3.6c0,0.8,0,1.6,0,2.4c0.156,1.204-0.315,3.034,0.24,3.84c0,0.72,0,1.44,0,2.16
		c0.156,1.204-0.314,3.034,0.24,3.84c0,0.88,0,1.76,0,2.64c0.154,0.966-0.312,2.552,0.24,3.12c0.154,0.966-0.312,2.552,0.24,3.12
		c0,0.48,0,0.96,0,1.44c0.152,0.808-0.31,2.229,0.24,2.64c2.755,21.885,5.136,44.145,12,61.92
		c6.335,18.225,16.372,32.748,17.76,55.919c0.15,0.729-0.307,2.067,0.24,2.4c0.132,2.028-0.404,4.725,0.72,5.76
		c3.349-0.811,2.911-5.409,3.36-9.12c2.173,1.747,1.944,5.896,4.8,6.961c1.222-1.164,2.75-1.897,4.32-2.881
		c1.407-0.881,2.552-2.4,4.08-3.12c0.887-0.417,2.104-0.569,3.12-0.96c1.052-0.404,1.939-0.816,2.88-1.199
		c1.741-0.709,4.546-0.435,5.28-2.64c0.08,0,0.16,0,0.24,0c0.397-0.259,0.397-2.142,0-2.4c0.039-0.279-0.053-0.427-0.24-0.48
		c0-0.16,0-0.32,0-0.48c-0.152-0.808,0.31-2.229-0.24-2.64c0-0.24,0-0.479,0-0.72c0-0.48,0-0.96,0-1.44
		c0.033-0.997,0.158-0.048,0.48,0c1.673,2.322,2.975,5.266,4.8,7.44c0.619,0.737,1.075,1.309,2.16,1.44
		c0.039,0.281,0.438,0.203,0.72,0.24c-0.074,0.566-0.833,0.446-0.72,1.2c-0.598,0.282-0.449,1.312-0.96,1.68
		c-0.954,1.127-2.111,2.05-3.12,3.12c-1.646,1.38-3.879,2.438-6.24,3.601c-1.466,0.722-2.746,1.788-4.08,2.399
		c-1.375,0.631-2.922,0.795-4.32,1.44c-1.242,0.573-2.104,1.663-3.36,2.4c-2.797,1.64-6.064,2.226-7.92,5.039
		c-0.194,0.046-0.239,0.241-0.48,0.24c-0.9,0.7-1.701,1.5-2.4,2.4c-0.046,0.194-0.241,0.239-0.24,0.479
		c-0.106,0.214-0.21,0.43-0.48,0.48c-1.038,0.802-1.955,1.726-2.64,2.88c-2.685,3.314-5.653,6.347-8.16,9.84
		c-1.5,5.579-2.95,10.856-4.56,16.08c-1.592,5.165-2.921,10.4-5.28,15.12c-0.661,1.322-1.879,2.496-2.64,3.84
		c-0.777,1.373-1.164,2.98-1.92,4.32c-1.484,2.629-3.333,5.086-4.8,7.68c-3.086,5.456-5.595,11.169-8.64,16.561
		c-3.035,5.373-5.146,11.408-8.16,17.04c-3.004,5.612-5.814,11.393-6.96,18c-0.379,0.501-0.498,1.262-0.48,2.16
		c-0.556,1.044-0.083,3.116-0.24,4.56c0,0.08,0,0.16,0,0.24c-0.398,0.492-0.398,2.628,0,3.12c0,0.08,0,0.16,0,0.239
		c0.157,1.363-0.316,3.356,0.24,4.32c1.628,13.65,6.333,25.159,11.52,36.24c1.671,3.569,4.801,7.181,5.28,10.56
		c0.41,2.892-0.582,6.117-1.2,9.12c-1.75,8.497-3.526,17.029-4.08,26.88c-0.399,1.604-0.399,4.877,0,6.48
		c0.403,7.787,3.337,16.018,0.72,23.28c-0.537,0.104-0.097,1.183-0.24,1.68c-0.552,0.568-0.086,2.154-0.24,3.12
		c0,2.08,0,4.16,0,6.24c-3.132,6.708-4.234,15.445-5.76,23.76c-0.554,0.727-0.084,2.476-0.24,3.6c0,0.08,0,0.16,0,0.24
		c-0.551,0.489-0.087,1.993-0.24,2.88c0,0.08,0,0.16,0,0.24c-0.547,0.333-0.089,1.671-0.24,2.4
		c-1.109,12.971-3.992,24.168-5.52,36.72c-0.547,0.333-0.089,1.671-0.24,2.4c-0.501,7.658-2.068,14.252-2.88,21.6
		c-0.397,0.336-0.397,2.304,0,2.641c-0.144,0.943,0.71,0.89,0.96,1.439c1.386,1.015,4.565,0.235,6.72,0.48c42.32,0,84.64,0,126.96,0
		c2.881,0.559,6.8,0.08,10.08,0.239c19.04,0.16,38.721-0.319,57.36,0.24c1.439,0,2.88,0,4.32,0c2.72,0,5.439,0,8.159,0
		c-0.186-0.214-0.258-0.542-0.239-0.96c-0.155-1.045,0.313-2.713-0.24-3.36c-0.336-0.616,0.02-1.543,0-2.399
		c-0.13-5.507-3.657-12.29-1.68-17.76c0.325-0.075,0.198-0.602,0.239-0.961c0.559-2.241,0.081-5.519,0.24-8.159
		c0.399-0.888,0.399-3.434,0-4.32c0.089-2.809-0.749-4.691-0.72-7.44c0.552-0.567,0.086-2.153,0.24-3.119c0-7.921,0-15.841,0-23.761
		c1.419-5.878,3.801-12.697,3.119-19.68c-0.135-1.384-0.989-2.94-0.96-4.32c0.063-2.907,2.552-5.081,2.4-7.68
		c0.398-0.729,0.398-3.111,0-3.84c0.079-0.88-0.267-1.334-0.479-1.92c0-2.08,0-4.16,0-6.24c0.549-0.411,0.088-1.832,0.239-2.641
		c0.542-0.179,0.094-1.346,0.24-1.92c0.397-0.259,0.397-2.141,0-2.399c0-0.08,0-0.16,0-0.24c-0.482-0.718-0.136-2.265-0.24-3.36
		c0-0.079,0-0.16,0-0.239c0.558-1.283,0.082-3.599,0.24-5.28c0-0.16,0-0.32,0-0.48c0.559-2.401,0.081-5.839,0.24-8.64
		c0.547-0.333,0.089-1.671,0.24-2.4c0-1.76,0-3.52,0-5.279c0.554-0.806,0.084-2.637,0.239-3.841c0-1.92,0-3.84,0-5.76
		c0.559-2.241,0.081-5.519,0.24-8.16c0-2.08,0-4.159,0-6.239c0.98-12.061,3.728-22.353,5.04-34.08c0.471-0.41,0.076-1.685,0.72-1.92
		c1.067-0.162,0.944-0.87,1.2-1.44c2.911-6.493,4.621-14.044,6.72-21.36c2.134-7.436,3.79-15.309,4.08-24l0.721-6.24
		c0-1.04,0-2.08,0-3.119c0.233-0.007,0.186-0.294,0.239-0.48c0.555-0.806,0.084-2.636,0.24-3.84
		C407.36,516.341,407.36,515.301,407.36,514.261z M363.92,417.78c1.04,0,2.08,0,3.12,0c0.775,0.505,2.51,0.051,3.12,0.721
		c0.194,0.045,0.239,0.24,0.48,0.239c0.779,0.98,1.659,1.86,2.64,2.641c1.586,1.934,3.286,3.754,5.28,5.279
		c1.695,1.825,3.758,3.282,5.52,5.04c3.602,3.6,6.902,7.498,10.08,11.521c-0.021,0.341-0.039,0.68,0.24,0.72
		c-0.001,2.348,1.825,4.06,1.2,6.24c0.08,0,0.16,0,0.24,0c-0.139,0.422,0.29,1.41-0.24,1.439c-0.414,1.031-1.4,3.663-2.4,1.921
		c-0.746-0.454-0.929-1.472-1.2-2.4c-1.406-2.594-2.563-5.437-6.72-5.28c-6.167-2.017-13.18,0.26-18.479,1.92
		c-1.083,0.022,0.104-0.725-0.24-1.439c0.145-1.426-0.166-2.395-0.48-3.36c-0.466-1.054-1.318-1.721-2.16-2.4
		c-1.275-0.644-2.383-1.456-3.359-2.399c-4.146-2.975-7.062-7.18-11.28-10.08c-0.773-0.187-1.418-0.502-1.92-0.96
		c-0.216-0.131-0.422-0.237-0.624-0.333c-0.013-0.005-0.025-0.01-0.037-0.016c-0.196-0.09-0.386-0.163-0.569-0.224
		c-0.664-0.335-1.358-0.641-2.369-0.628c-0.869,0.55-1.561,1.276-2.399,1.854c-0.31,0.186-0.627,0.372-0.961,0.546
		c-0.158,0.322-0.513,0.446-0.96,0.479c0.003-0.877,0.474-1.285,0.96-1.68c0.08,0,0.16,0,0.24,0c1.771-3.108,4.294-5.466,7.68-6.96
		c1.51-0.73,3.435-1.045,5.521-1.2c1.198-0.081,2.447-0.111,3.359-0.479c0.835-0.365,1.967-0.434,3.12-0.48c0.08,0,0.16,0,0.24,0
		C361.605,417.865,363.273,418.334,363.92,417.78z M347.364,433.268c-1.06-1.162-2.169-2.486-3.376-2.803
		C345.194,430.78,346.306,432.103,347.364,433.268z M330.561,392.581c0.375,0.905,0.935,1.625,1.439,2.4
		c-0.24,0.001-0.285-0.194-0.479-0.24c1.087,1.633,3.116,2.324,4.56,3.6c4.306,3.134,8.697,6.183,12.721,9.6
		c-0.898-0.018-1.659,0.101-2.16,0.48c-1.482,0.976-2.753,0.916-4.32,0.24c-2.648,0.631-4.538,2.022-6.96,2.88
		c-1.631,0.689-3.328,1.312-4.56,2.4c-5.863,3.178-11.567,6.513-17.04,10.08c-1.643,1.318-3.434,2.486-4.801,4.08
		c-2.465,2.175-4.905,4.374-7.68,6.24c-2.34,2.14-4.58,4.38-6.72,6.72c-0.08,0-0.16,0-0.24,0c-0.851,0.83-1.977,1.384-2.64,2.4
		c-0.942,0.577-1.517,1.523-2.641,1.92c-1.361,1.198-2.968,2.151-4.08,3.6c-1.058,0.543-1.385,1.815-2.64,2.16
		c-2.74,2.54-5.38,5.18-7.92,7.92c-0.246,0.234-0.486,0.475-0.72,0.72c-2.872,2.488-5.306,5.415-7.92,8.16c0,0.08,0,0.16,0,0.24
		c-0.9,0.699-1.701,1.499-2.4,2.4c-1.113,1.847-2.792,3.128-4.08,4.8c-0.98,0.779-1.86,1.659-2.64,2.64c0,0.08,0,0.16,0,0.24
		c-1.54,1.34-2.98,2.779-4.32,4.32c0,0.08,0,0.159,0,0.239c-2.532,2.188-4.731,4.709-6.96,7.2c-3.337,4.343-7.152,8.209-10.56,12.48
		c-0.952,0.568-1.426,1.614-2.16,2.399c-2.986,4.295-6.436,8.124-9.6,12.24c-0.558,0.162-0.643,0.798-1.2,0.96
		c0.112-0.54-1.303-0.74-1.44-0.24c-1.344,1.137-1.96,3-2.88,4.561c-0.373-1.896,0.693-4.425,0.72-6.72
		c0.637-0.003,1.371,0.09,1.68-0.24c2.782-2.413,11.11-17.055,1.2-15.601c-0.176-2.338,1.073-5.325,2.16-7.199
		c0.803-1.038,1.251-2.43,1.92-3.601c-0.011-0.73,0.507-0.934,0.72-1.439c0.08,0,0.16,0,0.24,0c0.464-0.977,1.136-1.744,1.68-2.641
		c0-0.08,0-0.16,0-0.24c0.08,0,0.16,0,0.24,0c0.625-1.215,1.456-2.224,2.16-3.359c0.338-1.103,1.113-1.767,1.68-2.641
		c0.961-0.799,1.599-1.921,2.16-3.119c0-0.08,0-0.16,0-0.24c-0.044-0.204,0.094-0.227,0.24-0.24c0.201-1.268,0.278,0.953,0.48,1.2
		c1.216,1.519,2.672,2.913,4.56,2.64c1.351-0.195,1.123-1.063,2.16-1.92c1.502-1.241,3.876-2.146,4.08-3.6
		c0.759-0.602,1.08-1.641,1.44-2.641c0.861-2.822,0.545-6.804-2.64-6.96c3.113-2.326,6.638-4.241,10.08-6.24
		c1.412-0.908,2.987-1.652,4.08-2.88c0.08,0,0.16,0,0.24,0c1.544-1.175,3.299-2.141,4.56-3.6c0.08,0,0.16,0,0.24,0
		c2.483-1.997,5.17-3.79,7.2-6.24c0.08,0,0.16,0,0.24,0c2.711-2.408,5.275-4.965,7.92-7.439c3.127-1.936-0.347-6.073-0.24-9.36
		c-0.017-0.943-0.116-1.804-0.48-2.4c-1.124-5.836-2.262-11.658-3.12-17.76c-0.122-1.398-0.253-2.786-0.72-3.84
		c-0.456-1.705-0.484-3.836-1.2-5.28c0.757,0.118,0.848-0.432,1.68-0.24c1.03-0.17,2.008-0.392,2.64-0.96
		c4.232-1.768,7.814-4.187,12-6c0.85-0.511,1.925-0.795,2.64-1.44c9.682-3.758,17.071-9.809,28.8-11.52c0.08,0,0.16,0,0.24,0
		c1.682-0.239,3.332-0.508,4.8-0.96c1.888,4.353,1.975,10.506,4.08,14.64C330.321,392.34,330.366,392.535,330.561,392.581z
		 M223.76,504.661c-0.823,3.416-3.145,5.335-5.04,7.68c0-0.32,0-0.64,0-0.96c-0.061-1.421,0.367-2.354,0.48-3.601
		c0.299-1.94,1.625-2.854,1.92-4.8c0.959,0.08,1.332-0.428,2.4-0.24C224.396,502.904,223.469,503.696,223.76,504.661z
		 M273.2,432.181c-0.848-1.793-1.072-4.208-1.44-6.48c-0.472-2.328-0.83-4.77-1.44-6.96c-0.66-3.34-1.464-6.536-1.92-10.08
		c-0.234-2.166-0.63-4.17-1.2-6c-0.521,0.041-0.109-0.852-0.24-1.2c0.115-0.755-0.342-0.938-0.48-1.44
		c0.341,0.021,0.68,0.04,0.72-0.24c0.454-0.426,1.585-0.175,2.4-0.24c0.422,0.138,1.41-0.29,1.44,0.24
		c0.151,0.729-0.307,2.067,0.24,2.4c0.295,0.905,0.484,1.916,0.48,3.12c0.014,1.106,0.111,2.129,0.48,2.88
		c0.999,4.921,1.884,9.956,2.64,15.12c0.2,2.359,0.636,4.483,1.2,6.479c0.827,1.733,0.762,4.358,1.92,5.761
		c0.041,0.52-0.851,0.108-1.2,0.239c-0.945,0.335-1.396,1.164-2.16,1.681c-0.485,0.555-0.797,1.282-1.68,1.439
		c-3.854,3.346-7.754,6.646-11.76,9.84c-0.208,0.352-0.359,0.761-0.96,0.721c-1.014,0.666-1.977,1.383-2.88,2.159
		c-0.355,0.605-0.928,0.992-1.68,1.2c-1.038,0.563-2.002,1.198-2.88,1.92c-4.213,3.067-8.943,5.617-13.44,8.4
		c-1.031,0.97-2.522,1.478-3.6,2.4c0.02,0.38-0.116,0.915,0.24,0.96c0.383,0.555-0.4,1.99-0.72,2.64
		c-2.005,5.915-5.935,9.906-8.88,14.88c-0.614,1.226-1.233,2.447-2.16,3.36c-0.261,0.059-0.163,0.477-0.48,0.479
		c0-0.239,0-0.479,0-0.72c0.341-2.139,1.072-3.888,1.68-5.76c1.911-3.529,4.451-6.43,6.24-10.08c-0.039-0.279,0.053-0.428,0.24-0.48
		c1.001-1.318,1.428-3.212,2.64-4.319c0.261-0.859,0.634-1.606,0.96-2.4c0.671-0.77,1.641-1.239,2.4-1.92
		c0.43-0.69,1.049-1.191,1.92-1.44c0.932-0.188,1.484-0.756,2.16-1.199c1.167-0.914,2.336-1.824,3.84-2.4
		c1.368-0.712,2.659-1.5,3.84-2.4c0.105-0.295,0.307-0.492,0.72-0.479c1.147-0.533,2.125-1.235,3.12-1.92
		c2.093-1.507,4.416-2.784,6.72-4.08c0.08,0,0.16,0,0.24,0c2.122-1.479,4.51-3.632,7.44-6.24
		C270.738,439.513,277.17,436.237,273.2,432.181z M244.88,470.581c-0.275,0.845-0.528,1.712-1.2,2.159c-0.08,0-0.16,0-0.24,0
		c-0.235,1.125-1.431,1.29-1.92,2.16c-0.006,0.233-0.293,0.187-0.48,0.24c-0.813,0.067-1.378,0.382-2.4,0.24
		c0.016-2.307,0.612-6.106,2.16-7.681c0.08,0,0.16,0,0.24,0c0.3-0.899,1.223-1.177,1.68-1.92c-0.041-0.521,0.851-0.108,1.2-0.239
		c0,0.08,0,0.159,0,0.239C245.921,466.052,245.388,469.05,244.88,470.581z M361.04,363.541c-1.349-1.914-3.237-4.041-5.52-6.96
		c-1.346-1.721-3.368-5.805-5.465-6.188c-0.02-0.017-0.036-0.036-0.056-0.052c-0.17-0.026-0.307-0.018-0.427,0.006
		c-0.105,0.019-0.195,0.055-0.28,0.095c-0.001,0-0.003,0.001-0.004,0.001h0.001c-0.001,0-0.002,0-0.003,0.001
		c0.001,0,0.001-0.001,0.002-0.001c-0.243,0.07-0.486,0.179-0.729,0.377c-0.319,0.721-0.244,1.836-0.24,2.88
		c0.15,0.729-0.308,2.067,0.24,2.4c0,0.08,0,0.16,0,0.24c0.15,0.729-0.308,2.067,0.24,2.4c0,0.24,0,0.48,0,0.72
		c0.501,2.821-1.143,3.498-2.16,4.8c-0.849-0.191-0.679-1.401-1.44-1.68c0-0.08,0-0.16,0-0.24c-0.748-1.332-1.277-2.882-2.16-4.08
		c-0.751-1.568-1.34-3.3-1.439-5.52c-0.846-1.074-0.924-2.917-2.16-3.6c0.15,0.729-0.308,2.067,0.24,2.4c0,0.16,0,0.32,0,0.48
		c-0.826,0.938-0.573,4.222-0.24,5.52c0,0.08,0,0.16,0,0.24c0.15,0.729-0.308,2.067,0.24,2.4c0.448,3.072,0.943,6.096,1.199,9.36
		c-1.949,3.01-5.147,4.772-7.68,7.2c-1.097,0.781-2.824,2.084-4.08,1.92c0.118-0.758-0.433-0.848-0.24-1.68
		c3.036-1.773,1.679-8.529-0.72-9.6c-0.154-1.045,0.313-2.713-0.24-3.36c0-0.16,0-0.32,0-0.48c-0.158-2.002,0.318-4.638-0.239-6.24
		c0-0.08,0-0.16,0-0.24c-0.155-1.045,0.313-2.713-0.24-3.36c0-0.24,0-0.48,0-0.72c-0.158-1.762,0.317-4.157-0.24-5.52
		c0-0.16,0-0.32,0-0.48c0.358-2.202,0.219-4.901,0.24-7.44c0-0.08,0-0.16,0-0.24c0.553-0.647,0.085-2.315,0.24-3.36
		c-0.043-2.763,0.505-4.935,0.96-7.2c0.388-0.972,0.49-2.229,0.479-3.6c0-0.08,0-0.16,0-0.24c0.639-1.682,0.961-3.679,1.2-5.76
		c-0.101-1.141,0.349-1.731,0.48-2.64c0.741-0.859,2.656-0.543,3.359-1.44c0.08,0,0.16,0,0.24,0
		c0.057-0.021,0.118-0.051,0.183-0.089c1.021-0.451,1.825-1.118,2.635-1.78c-0.464-0.164-1.293,0.679-2.002,1.295
		c0.71-0.619,1.538-1.46,2.002-1.295c0.024,0.009,0.049,0.02,0.071,0.034c-0.004,0.213,0.068,0.349,0.231,0.395
		c0,0.08,0,0.16,0,0.24c-0.015,1.054,0.097,1.983,0.479,2.64c0.344,1.417,0.796,2.724,0.96,4.32c0.302,1.938,0.681,3.8,1.44,5.28
		c0,0.08,0,0.16,0,0.24c0.332,0.868,0.52,1.88,0.96,2.64c0.628,0.067,1.032-0.088,0.96-0.72c0-0.08,0-0.16,0-0.24
		c-0.15-0.729,0.308-2.067-0.24-2.4c-0.468-3.852-1.799-6.841-2.16-10.8c-0.115-1.244-0.104-2.616-0.479-3.6c0-0.08,0-0.16,0-0.24
		c0-1.04,0-2.08,0-3.12c0-0.16,0-0.32,0-0.48c0.331-1.829,1.711-2.609,2.64-3.84c0-0.08,0-0.16,0-0.24
		c2.224-1.693,3.439-4.395,5.516-6.236c0.001,0.001,0.003,0.002,0.004,0.004c0.001,0.001,0.002,0.002,0.003,0.003
		c0.093,0.862,0.318,1.593,0.718,2.149c-0.159-0.796-0.271-1.641-0.717-2.148c0.447,0.508,0.558,1.352,0.717,2.148
		c0.258,1.022,0.773,1.787,0.72,3.12c0.057,0.983,0.118,1.962,0.48,2.64c0.426,0.454,0.175,1.585,0.24,2.4
		c0.151,0.808-0.31,2.229,0.239,2.64c0,0.08,0,0.16,0,0.24c0.159,2.481-0.318,5.599,0.24,7.68c0,0.32,0,0.64,0,0.96
		c-0.399,2.162-0.399,5.998,0,8.16c0,0.16,0,0.32,0,0.48c0.155,1.045-0.313,2.713,0.24,3.36c0.575,4.064,1.207,8.073,2.16,11.76
		c0.473,1.607,0.542,3.618,2.16,4.08c2.102-1.737-0.361-5.215-0.96-7.68c-1.114-4.581-1.541-9.506-1.44-15.6
		c-0.158-2.002,0.317-4.638-0.24-6.24c0-0.88,0-1.76,0-2.64c-0.151-0.808,0.31-2.229-0.24-2.64c-0.244-3.196-0.599-6.281-0.96-9.36
		c-0.014-1.186-0.236-2.163-0.72-2.88c-0.49-1.509-1.019-2.981-1.439-4.56c1.104-1.216,2.076-2.563,2.399-4.56
		c-0.076-0.556,0.039-0.921,0.24-1.2c0.668,0.108-0.188-1.308,0.479-1.2c0.055,0.187,0.007,0.474,0.24,0.48
		c0.621,2.1,1.296,4.145,1.44,6.72c0.153,0.887-0.311,2.391,0.24,2.88c0,0.08,0,0.16,0,0.24c0,2.16,0,4.32,0,6.48
		c0.036,1.716-0.128,3.232-0.48,4.56c-0.549,0.411-0.088,1.832-0.24,2.64c0,0.16,0,0.32,0,0.48c-0.399,1.125-0.399,3.915,0,5.04
		c0,0.08,0,0.16,0,0.24c0.155,1.045-0.313,2.713,0.24,3.36c0,0.08,0,0.16,0,0.24c0.15,0.729-0.308,2.067,0.24,2.4
		c0.401,0.398,0.19,1.409,0.24,2.16c-0.104,1.624,0.347,2.693,0.72,3.84c0.729,0.01,1.579,0.14,1.439-0.72
		c-0.484-3.275-0.999-6.52-0.96-10.32c0-2.4,0-4.8,0-7.2c-0.16-2.081,0.423-3.418,0.48-5.28c0.497,0.623,0.817,1.422,0.96,2.4
		c0.25,1.35,0.646,2.553,1.2,3.6c0.989,2.851,1.681,5.999,1.68,9.84c0.154,0.966-0.312,2.552,0.24,3.12c0,0.08,0,0.16,0,0.24
		c0.153,0.887-0.311,2.391,0.24,2.88c0.444,0.515,0.161,1.758,0.239,2.64c0.152,0.808-0.309,2.229,0.24,2.64c0,0.08,0,0.16,0,0.24
		c0.143,1.857,0.345,3.655,0.96,5.04c0,0.72,0,1.44,0,2.16c0,1.52,0,3.04,0,4.56c0.161,2.959,0.798,5.442,1.2,8.16
		c0.662,2.778,1.623,5.257,2.88,7.44c0.206,0.194,0.143,0.657,0.48,0.72C364.353,362.692,363.144,363.563,361.04,363.541z
		 M221.84,197.22c1.286-0.395,2.78-0.58,3.84-1.2c0.08,0,0.16,0,0.24,0c0.886-0.155,1.834-0.246,2.4-0.72
		c0.213-0.187,0.542-0.258,0.96-0.24c3.724-1.396,5.988-4.251,8.64-6.72c0.748-0.532,1.419-1.141,2.4-1.44
		c2.911-1.889,5.916-3.684,8.64-5.76c1.156-0.604,2.113-1.407,3.6-1.68c0.934-0.266,1.736-0.664,2.4-1.2
		c1.108-0.492,2.018-1.182,3.36-1.44c1.156-0.444,2.363-0.837,3.12-1.68c1.167-0.433,1.928-1.272,3.36-1.44
		c1.861-0.699,3.741-1.379,5.52-2.16c2.976-0.944,6.228-1.612,10.08-1.68c0.8,0,1.6,0,2.4,0c7.574,0.826,13.347,3.453,19.92,5.28
		c0.863,0.656,2.097,0.943,3.359,1.2c2.599,0.681,5.116,1.444,7.44,2.4c1.208,0.792,2.666,1.333,4.08,1.92
		c2.702,1.218,5.424,2.416,8.16,3.6c0.778,0.741,1.732,1.307,2.64,1.92c2.191,1.088,3.984,2.576,5.76,4.08
		c-0.421,0.437,0.704,1.364,1.2,1.68c-0.044-0.204,0.094-0.226,0.24-0.24c1.578,1.222,2.953,2.646,4.56,3.84
		c1.26,1.46,2.62,2.82,4.08,4.08c0.988,1.412,2.185,2.615,3.36,3.84c2.853,3.547,5.42,7.38,7.92,11.28
		c0.046,0.194,0.24,0.239,0.24,0.48c0.184,0.696,0.379,1.38,0.96,1.68c0.045,0.195,0.24,0.239,0.239,0.48
		c0.672,1.569,1.392,3.089,2.4,4.32c0.219,0.981,1.132,1.268,1.2,2.4c0.36,1,0.681,2.039,1.439,2.64
		c0.704,1.216,1.246,2.595,1.92,3.84c0.602,1.958,1.342,3.779,2.16,5.52c0.025,0.615,0.136,1.144,0.48,1.44
		c0.866,3.454,1.682,6.958,2.88,10.08c0.104,1.415,0.505,2.535,0.96,3.6c0.431,3.25,1.689,6.95,1.44,10.08
		c-1.229-2.292-2.62-4.42-4.08-6.48c-0.624-0.816-1.066-1.814-1.92-2.4c-3.313-4.288-6.753-8.447-9.841-12.96
		c-1.412-1.707-2.538-3.701-4.08-5.28c0-0.08,0-0.16,0-0.24c-0.835-1.085-1.403-2.437-2.399-3.36
		c-0.003-0.477-0.655-0.305-0.48-0.96c-0.819-1.34-1.579-2.741-2.64-3.84c-0.258-0.622-0.868-0.893-0.96-1.68
		c-0.507-0.854-0.932-1.789-1.68-2.4c-0.365-0.755-1.069-1.171-1.2-2.16c-1.254-1.866-2.171-4.069-3.601-5.76
		c-0.354-0.606-0.848-1.072-0.96-1.92c-0.761-1.398-1.357-2.961-2.399-4.08c-1.335-2.506-2.845-4.835-4.08-7.44
		c-0.87-1.45-1.794-2.846-2.88-4.08c-0.762-0.679-0.721-2.159-2.16-2.16c-0.984,0.864,0.277,2.671,0.96,3.36
		c2.426,3.734,4.778,7.542,6.72,11.76c0.523,0.756,0.653,1.907,1.44,2.4c0.127,0.193,0.25,0.39,0.239,0.72
		c0.598,1.083,1.039,2.322,1.921,3.12c0,0.08,0,0.16,0,0.24c0.604,0.995,0.967,2.232,1.92,2.88
		c9.437,18.323,24.006,31.515,33.359,49.92c-0.175,0.655,0.478,0.483,0.48,0.96c0.931,1.79,1.658,3.782,2.88,5.28
		c-0.039,0.279,0.053,0.427,0.24,0.48c0.92,2.6,2.031,5.008,3.12,7.44c-0.024,0.744,0.108,1.332,0.479,1.68
		c1.785,7.227,1.912,17.512,0.24,24.96c-0.412,0.788-0.635,1.765-0.72,2.88c-2.239,5.653-2.438,16.1,0.479,21.12
		c0.458,0.823,0.582,1.978,1.2,2.64c2.177,5.343,6.857,9.462,10.08,12.96c-0.757-0.523-1.484-1.075-2.64-1.2
		c-0.447-0.033-0.803-0.158-0.96-0.48c-2.225-1.296-4.571-2.469-6.961-3.6c-0.614-0.665-1.424-1.136-2.399-1.44
		c-1.868-1.092-2.528-3.392-4.08-4.8c-2.766,2.595,0.775,8.01,1.92,10.56c0.322,0.158,0.447,0.513,0.48,0.96
		c0.709,1.93,1.702,3.577,2.88,5.04c0.045,0.194,0.24,0.239,0.239,0.48c0.559,1.362,1.493,2.348,2.16,3.6
		c0.52,1,1.397,1.643,1.681,2.88c0,0.08,0,0.16,0,0.24c-0.121,1.399-1.098,1.943-1.44,3.12c-0.08,0-0.16,0-0.24,0
		c-0.479,0.961-0.961,1.919-1.439,2.88c0.067,0.387-0.228,0.413-0.24,0.72c-0.08,0-0.16,0-0.24,0
		c-0.544,1.216-1.328,2.191-1.92,3.36c-0.092,0.788-0.702,1.058-0.96,1.68c-3.803-1.557-5.157-5.563-7.68-8.4
		c-0.127-0.193-0.251-0.39-0.24-0.72c-1.106-2.014-1.981-4.258-3.12-6.24c-0.45-1.15-1.08-2.12-1.2-3.6
		c-0.128-0.912-0.36-1.719-0.72-2.4c-0.201-0.279-0.316-0.644-0.24-1.2c-0.558-1.414,0.485-3.15-0.24-4.56c0-0.16,0-0.32,0-0.48
		c-0.155-1.125,0.314-2.874-0.239-3.6c-0.373-1.121-0.176-2.183,0-3.36c0.555-0.805,0.084-2.636,0.239-3.84c0-0.08,0-0.16,0-0.24
		c0.551-0.489,0.087-1.993,0.24-2.88c0.377-6.343,1.548-11.893,2.16-18c0.399-1.843,0.399-5.357,0-7.2
		c-0.328-1.672-0.652-3.347-0.96-5.04c-0.13-1.95-0.538-3.622-1.44-4.8c-0.285-0.755-0.81-1.27-0.96-2.16
		c-1.011-1.708-1.603-3.837-2.88-5.28c0-0.08,0-0.16,0-0.24c-1.31-2.37-2.51-4.85-4.319-6.72c0-0.08,0-0.16,0-0.24
		c-1.392-2.049-2.802-4.078-4.561-5.76c-1.161-1.479-2.597-2.683-3.359-4.56c1.776-0.847,4.848-0.584,4.56-2.64
		c-0.359-2.563-4.216,0.231-6.48,0.24c-0.946,0.173-1.942,0.298-2.64,0.72c-2.167,0.633-4.458,1.142-7.2,1.2c-0.96,0-1.92,0-2.88,0
		c-1.28,0-2.56,0-3.84,0c-1.045,0.155-2.713-0.313-3.36,0.24c-0.399,0-0.8,0-1.199,0c-1.761,0-3.521,0-5.28,0
		c-6.107-0.292-10.702-2.098-15.12-4.08c-0.664-0.536-1.466-0.934-2.4-1.2c-8.618-4.502-20.87-5.37-32.4-6.96
		c-0.333-0.547-1.67-0.09-2.4-0.24c-0.751-0.049-1.762,0.162-2.16-0.24c-0.588-0.372-1.562-0.358-2.4-0.48
		c-3.637-0.443-6.762-1.398-9.84-2.4c-1.172-0.748-2.632-1.208-3.84-1.92c-1.193-0.326-1.977-1.063-2.88-1.68c0-0.08,0-0.16,0-0.24
		c-1.771-0.767-6.686-7.337-6.96-2.4c0.296,0.984,0.887,1.673,0.96,2.88c0.69,2.11,1.269,4.331,2.16,6.24
		c0.387,1.292,0.816,2.543,0.96,4.08c0.251,1.188,0.389,2.491,0.96,3.36c0.811,2.309,1.536,4.704,2.16,7.2
		c0.423,1.737,0.857,3.463,1.68,4.8c0,0.08,0,0.16,0,0.24c-0.021,0.833,1,1.864,0.48,2.4c-0.662-1.098-1.256-2.263-2.16-3.12
		c0-0.08,0-0.16,0-0.24c-0.511-0.85-0.795-1.925-1.44-2.64c0-0.08,0-0.16,0-0.24c-0.517-1.244-0.805-2.715-1.44-3.84
		c-0.852-3.068-1.847-5.993-2.64-9.12c-0.287-0.833-0.376-1.864-0.96-2.4c-0.738-2.302-1.827-4.253-2.4-6.72
		c-0.379-1.22-0.722-2.478-1.44-3.36c-1.357-3.363-2.912-6.528-4.08-10.08c0.359-0.722,0.43,0.823,0.96,0.72
		c0.213,0.507,0.731,0.709,0.72,1.44c0.527,0.913,0.899,1.98,1.68,2.64c0,0.08,0,0.16,0,0.24c0.482,1.204,2.31,3.787,3.6,1.92
		c0.132-1.813-1.191-2.169-1.44-3.6c-0.527-0.913-0.899-1.98-1.68-2.64c-0.365-0.755-1.069-1.171-1.2-2.16
		c-0.81-1.19-1.317-2.683-2.16-3.84c-0.278-0.842-0.944-1.295-0.96-2.4c-0.595-1.405-1.073-2.927-1.92-4.08
		c-0.558-1.361-1.345-2.494-1.68-4.08c-0.515-1.245-0.942-2.578-1.68-3.6c-0.774-1.787-1.443-3.677-1.92-5.76
		c-0.25-1.35-0.647-2.553-1.2-3.6C222.602,206.99,218.383,198.817,221.84,197.22z M316.88,281.94c0,0.16,0,0.32,0,0.48
		c-0.809-0.071-0.695-1.064-1.439-1.2c-0.074-0.566-0.834-0.446-0.72-1.2c-0.949-1.131-1.671-2.49-2.881-3.36c0-0.08,0-0.16,0-0.24
		c-0.71-1.133-2.422-2.533-2.399-3.6c2.102,0.458,4.087,1.033,6.24,1.44c0,0.08,0,0.16,0,0.24c0.422,0.138,1.41-0.29,1.439,0.24
		c0,0.88,0,1.76,0,2.64c0,0.16,0,0.32,0,0.48C316.565,278.745,317.037,280.657,316.88,281.94z M233.12,286.5
		c0.554-0.073,0.267,0.694,0.72,0.72c0.489,0.391,1.256,0.504,1.68,0.96c0.474,0.166,0.64,0.64,0.72,1.2
		c0.035,1.06-0.588-0.023-1.2,0.24c-0.615-0.025-1.144-0.136-1.44-0.48c0-0.08,0-0.16,0-0.24c-1.833-0.407-4.166-0.314-5.76-0.96
		c1.291-0.309,3.176-0.024,4.32-0.48c0.039-0.281,0.438-0.203,0.72-0.24C233.379,287.086,233.389,286.846,233.12,286.5z
		 M229.52,291.06c3.336,0.344,6.044,1.316,8.4,2.64c0.503,1.498,0.075,3.188,0,4.8c-4.35-0.21-7.499,5.78-11.04,5.76
		c-2.747-0.016-6.882-5.066-6.48-8.16c0.227-1.745,3.778-4.659,6-5.04C227.44,291.06,228.48,291.06,229.52,291.06z M259.52,375.541
		c-1.865,0.054-3.482,0.357-5.04,0.72c-0.8,0-1.6,0-2.4,0c-0.816-0.464-1.767-0.793-2.4-1.44
		c-5.782-6.778-10.949-14.171-14.64-23.04c-0.184-0.696-0.379-1.38-0.96-1.68c-4.026-6.774-8.551-13.05-11.04-21.36
		c-0.009-1.03-0.313-1.767-0.72-2.4c-0.654-1.506-0.781-3.54-0.96-5.52c-0.094-5.106-0.919-9.48-0.96-14.64c0-0.16,0-0.32,0-0.48
		c0-0.32,0-0.64,0-0.96c0.433,0.146,1.581,0.794,0.96,1.2c-0.08,0-0.16,0-0.24,0c0,0.4,0,0.8,0,1.2
		c1.501-1.061,3.652,0.149,6.48-0.24c2.22-0.101,3.075-1.565,4.8-2.16c0.08,0,0.16,0,0.24,0c0.998-0.842,1.906-1.774,2.88-2.64
		c0.787,0.052,2.2-1.562,1.92,0.24c-0.372,2.108-0.431,4.529-0.48,6.96c-1.771,3.669-4.038,6.842-6.24,10.08
		c-0.843,0.543-0.498,2.717,0,3.36c1.393,1.887,2.952,3.608,4.32,5.52c0,0.08,0,0.16,0,0.24c0.821,0.539,0.998,1.723,2.16,1.92
		c0.933,0.667,1.958,1.241,2.88,1.92c0.788,0.652,1.975,0.905,3.36,0.96c2.043,0.359,4.962,1.115,7.2,0.48
		c0.533-0.52,1.058-1.308,0.24-1.92c-1.909-0.492-3.923-0.877-6-1.2c-0.788-0.412-1.765-0.635-2.88-0.72
		c-1.232-0.128-1.982-0.738-2.64-1.44c-0.62-0.821-1.34-1.541-2.16-2.16c-1.478-1.322-2.488-3.112-3.84-4.56
		c1.375-4.385,4.31-7.21,6.48-10.8c1.022-4.018,0.87-9.21,0.96-14.16c0.347-1.284,0.556-3.126,0-4.08
		c-1.502-2.258-3.003-4.518-3.84-7.44c-0.724-1.436-2.451-1.869-4.32-2.16c-1.167-0.457-2.981-0.636-4.32-0.24
		c-0.104-0.537-1.183-0.097-1.68-0.24c0.166,0.474,0.64,0.64,1.2,0.72c0.32,0,0.64,0,0.96,0c0,0.08,0,0.16,0,0.24
		c1.009,0.03,1.15,0.93,2.16,0.96c0.56,0.081,1.034,0.246,1.2,0.72c0,0.502,1.307,0.902,0.72,1.2
		c-1.803-1.083-5.432-0.267-6.96,0.48c-0.552,0.567-1.472,0.768-2.16,1.2c-1.115,0.166-1.028,1.532-1.92,1.92
		c-0.857,0.263-1.417,0.823-1.68,1.68c-0.217,0.423-0.537,0.743-0.96,0.96c-2.296,0.457-2.321-1.359-2.88-2.64c0-1.04,0-2.08,0-3.12
		c-0.087,0.068-0.167,0.114-0.245,0.148l0.84-11.708c0.101-0.408,0.093-0.924,0.365-1.161c0.383-0.657,0.494-1.586,0.48-2.64
		c0.388-2.195,1.982-4.559,3.12-6.96c2.793-5.894,5.636-12.436,8.16-18.72c0.755,0.206,0.995,0.925,1.2,1.68
		c0.184,0.696,0.379,1.38,0.96,1.68c1.399,2.281,2.512,4.848,3.36,7.68c0.076,0.804,0.247,1.513,0.72,1.92
		c0.836,1.564,1.463,3.336,1.92,5.28c0.143,0.978,0.462,1.778,0.96,2.4c0.837,1.323,1.314,3.006,1.92,4.56
		c0.216,1.224,0.695,2.185,1.44,2.88c0.682,1.078,1.399,2.121,1.92,3.36c0.208,0.751,0.595,1.325,1.2,1.68
		c1.454,1.667,2.473,3.768,3.84,5.52c0,0.08,0,0.16,0,0.24c1.672,1.768,3.198,3.682,5.52,4.8c0.587-0.225,1.488-1.375,0.72-2.16
		c-1.975-2.665-3.138-6.142-4.32-9.6c-0.076-0.804-0.248-1.513-0.72-1.92c-0.711-1.529-1.27-3.21-1.68-5.04
		c-0.297-2.023-0.983-3.657-1.68-5.28c-0.88-2.48-1.461-5.259-2.16-7.92c0.341-0.021,0.68-0.04,0.72,0.24c0,0.08,0,0.16,0,0.24
		c0.842,0.278,1.295,0.944,2.4,0.96c1.222,0.377,2.491,0.709,3.6,1.2c1.36,0.64,2.963,1.036,4.8,1.2c0.349,0.131,1.24-0.28,1.2,0.24
		c0.656,0.383,1.585,0.494,2.64,0.48c0.642-0.159,1.5-0.101,1.92-0.48c0.55-0.113,1.446,0.118,1.996,0.004l7.48,1.049l9.417,0.917
		l3.662-0.159c0.398,0.12,0.79,0.246,1.205,0.35c0.014,0.146,0.036,0.284,0.24,0.24c1.624,0.296,3.031,0.809,4.8,0.96
		c5.159,1.241,9.481,3.318,13.68,5.52c1.193,0.887,2.154,2.006,3.12,3.12c0.359,0.36,0.43,1.011,0.96,1.2
		c2.668,3.413,5.458,6.702,7.92,10.32c0.167,0.567,1.252,1.45,1.92,0.72c1.609-1.671,0.988-5.571,1.2-8.64c0-0.08,0-0.16,0-0.24
		c-0.188-1.068,0.32-1.44,0.24-2.4c2.728-0.073,5.797,0.197,8.16-0.24c0.03,0.431-0.294,0.506-0.24,0.96
		c-0.542,1.117-1.885,3.481,0.48,3.6c0,0.08,0,0.16,0,0.24c0.196,1.003,0.845,1.555,1.439,2.16c1.288,2.199,2.855,5.769,0.72,8.16
		c-0.515,0.925-1.494,1.385-2.159,2.16c-0.872,0.008-1.055,0.706-0.96,1.68c0.08,0,0.159,0,0.239,0
		c2.604,1.199,4.187-2.443,5.761-3.84c2.232-0.798,0.804-4.905,0-6.24c-0.675-1.005-1.163-2.197-1.681-3.36
		c0.031-0.43-0.294-0.506-0.239-0.96c0.526,0.273,0.98,0.62,1.92,0.48c1.652-0.132,2.646,0.395,3.359,1.2c0,0.08,0,0.16,0,0.24
		c0.862,0.627,2.138,0.009,2.88-0.24c0.088,1.607-0.058,2.981-0.72,3.84c-0.615,0.825-1.094,1.786-1.439,2.88
		c-0.478,1.123-1.119,2.082-1.921,2.88c-0.908,0.611-1.405,1.634-2.159,2.4c0.098,0.578-0.438,0.521-0.24,1.2
		c-0.064,1.063,1.766,1.252,2.16,0.48c0.08,0,0.16,0,0.239,0c0.717-0.644,1.558-1.163,1.921-2.16
		c4.354-4.282,7.033-13.624,1.68-18.72c0.922-0.518,2.278-0.601,3.6-0.72c2.677,1.083,0.239,7.281,3.84,7.44
		c0.007-0.233,0.294-0.186,0.48-0.24c0.646-1.751-0.15-3.744-0.48-5.28c0.042-0.698-0.843-1.695-0.239-2.16
		c0.527,0.993,1.125,1.915,1.92,2.64c1.449,4.649,1.803,10.182,0.479,14.88c-0.818,3.661-3.714,5.246-5.279,8.16
		c-1.413,1.947-2.897,3.823-4.561,5.52c-1.177,1.624-2.69,2.909-4.08,4.32c-1.2,0-2.399,0-3.6,0c0-0.08,0-0.16,0-0.24
		c-1.69,0.01-2.481-0.879-4.08-0.96c-0.615-0.862-1.696-0.74-2.333-0.243c-0.023,0.002-0.044,0-0.067,0.002
		c-0.003,0.021-0.001,0.037-0.003,0.058c-0.547,0.479-0.7,1.248,0.243,1.862c0.003,0.477,0.655,0.305,0.48,0.96
		c1.302-0.262,1.596,0.484,2.64,0.48c0.407,0.473,1.116,0.644,1.92,0.72c0.142,1.022-0.173,1.587-0.24,2.4
		c-1.222,7.418-3.495,13.785-3.6,22.32c0,0.16,0,0.32,0,0.48c-0.554,0.726-0.085,2.476-0.24,3.6c0,0.16,0,0.32,0,0.48
		c-0.547,0.333-0.09,1.67-0.24,2.4c0.188,0.988-0.368,1.231-0.239,2.16c0,0.32,0,0.64,0,0.96c-0.4,1.444-0.4,4.556,0,6
		c0,0.48,0,0.96,0,1.44c0.154,1.045-0.313,2.713,0.239,3.36c0,0.48,0,0.96,0,1.44c0.157,1.523-0.316,3.677,0.24,4.8
		c0,0.64,0,1.28,0,1.92c0.154,0.966-0.312,2.552,0.24,3.12c-0.125,0.355-0.355,0.604-0.48,0.96c-1.542,0.858-4.233,0.566-5.76,1.44
		c-1.831,0.649-3.776,1.184-5.76,1.68c-0.804,0.076-1.513,0.248-1.92,0.72c-2.031,0.929-4.079,1.841-6.24,2.64
		c-0.986,0.054-1.572,0.508-2.16,0.96c-1.332,0.908-2.996,1.484-4.56,2.16c-1.321,0.199-2.198,0.842-3.12,1.44
		c-1.455,1.024-3.087,1.874-4.8,2.64c-0.697,0.184-1.381,0.38-1.681,0.96c-1.146,0.774-2.426,1.414-3.84,1.92
		c-0.986,0.054-1.572,0.508-2.16,0.96c-1.794,1.086-3.749,2.011-6,2.64c-0.85,0.03-1.366,0.395-1.92,0.72
		c-0.867,0.093-1.602,0.318-2.16,0.72c-1.016,0.503-2.136,0.904-3.6,0.96c-1.28,0-2.56,0-3.84,0c-0.333-0.147-0.036-0.924-0.48-0.96
		c-0.975-2.305-1.705-4.855-2.16-7.68c1.062-0.059,1.749-0.492,2.88-0.48c1.86-0.459,3.885-0.755,5.52-1.44
		c6.825-2.935,12.331-7.189,17.76-11.52c0.08,0,0.16,0,0.24,0c1.851-1.669,3.627-3.413,5.28-5.28c0.08,0,0.16,0,0.239,0
		c3.678-3.442,7.734-6.506,10.801-10.56c0.786-1.133,1.705-2.134,2.64-3.12c0.08,0,0.16,0,0.24,0c0.89-1.271,2.122-2.198,2.88-3.6
		c1.349-1.772,2.684-3.556,4.08-5.28c0.08,0,0.16,0,0.24,0c0.364-0.755,1.068-1.171,1.199-2.16c1.39-2.291,2.592-4.769,3.841-7.2
		c0,0.08,0,0.16,0,0.24c0.588-1.601,1.829-3.73,1.199-5.76c-2.739,0.541-2.076,4.483-3.359,6.48c0.039,0.279-0.053,0.427-0.24,0.48
		c-1.685,2.795-3.16,5.8-5.28,8.16c-0.058,0.342-0.395,0.405-0.479,0.72c-1.776,1.824-3.451,3.749-5.04,5.76
		c-2.681,3.319-5.845,6.156-9.36,8.64c-1.052,0.628-2.002,1.357-2.88,2.16c0.194-0.045,0.239-0.241,0.479-0.24
		c-2.062,1.698-4.285,3.235-6.72,4.56c-1.11,0.569-2.256,1.104-3.12,1.92c-4.226,2.734-8.979,4.941-14.16,6.72
		c-0.804,0.076-1.513,0.248-1.92,0.72c-2.111,1.088-4.476,1.924-7.2,2.4C261.474,374.934,260.497,375.237,259.52,375.541z
		 M262.16,396.181c0-0.08,0-0.16,0-0.24c-0.199-0.601-0.657-0.942-0.72-1.68c0.853,0.354,3.239,0.432,4.08,0
		c3.897-0.583,7.276-1.684,10.8-2.64c1.046-0.313,2.123-0.598,2.88-1.2c0.899-0.461,1.823-0.897,2.88-1.2
		c3.873-1.647,7.646-3.395,11.04-5.521c0.602-0.759,1.641-1.08,2.64-1.44c1.01-0.43,1.911-0.969,2.881-1.44
		c0.984-0.775,2.284-1.235,3.6-1.68c1.107-0.413,2.201-0.839,3.12-1.44c1.626-1.014,3.494-1.786,5.52-2.4
		c0.819-0.301,1.725-0.516,2.4-0.96c1.232-0.767,2.86-1.14,4.56-1.44c0.78-0.02,1.477-0.124,1.921-0.48
		c0.729-0.15,2.066,0.307,2.399-0.24c-0.399,0-0.8,0-1.2,0c1.476-0.685,3.726-0.595,5.521-0.96c0.771,0.21,0.497-0.622,0.96-0.72
		c0.731,1.423,0.799,4.499-0.72,5.04c-0.279,0.201-0.644,0.316-1.2,0.24c0-0.08,0-0.16,0-0.24c-1.233-0.033-2.365,0.035-3.12,0.48
		c-1.711,0.129-3.051,0.629-4.561,0.96c0,0.08,0,0.16,0,0.24c-3.684,0.796-7.052,1.909-10.319,3.12
		c-1.754,0.646-3.354,1.446-4.8,2.4c-1.434,1.127-3.22,1.9-5.04,2.64c-0.996,0.364-1.988,0.732-2.881,1.2
		c-1.116,0.723-2.482,1.197-3.84,1.68c-0.934,0.266-1.736,0.664-2.4,1.2c-0.847,0.752-1.904,1.296-3.12,1.68
		c-1.492,0.828-3.202,1.438-4.56,2.4c-0.158,0.322-0.513,0.447-0.96,0.48c-0.752,0.448-1.668,0.732-2.4,1.2
		c-0.204,0.356-0.604,0.516-1.2,0.48c-0.867,0.093-1.602,0.318-2.16,0.72c-2.223,0.897-5.149,1.091-8.16,1.2c-0.96,0-1.92,0-2.88,0
		C262.725,397.216,262.599,396.542,262.16,396.181z M248.96,435.78c0.808-1.512,2.514-2.126,4.8-2.16c0,0.08,0,0.16,0,0.24
		c1.047-0.072,1.638-0.603,2.4-0.96c4.744-1.256,7.983-4.017,12-6c0.477-0.003,0.305-0.654,0.96-0.479
		c0.171,1.108,0.485,2.075,0.96,2.88c0.879,1.441,1.128,3.512,1.92,5.04c0.424,1.305-2.656,3.756-4.32,5.279
		c-1.755,1.607-3.944,2.875-5.04,4.32c-2.594,2.047-5.167,4.112-8.4,5.521c-1.169,0.67-2.486,1.193-3.36,2.159
		c-0.716,0.404-1.289,0.951-2.16,1.2c-0.854,0.507-1.789,0.932-2.4,1.681c-1,0.52-1.644,1.396-2.88,1.68
		c-1.098,0.662-2.263,1.257-3.12,2.16c-0.622,0.258-0.893,0.867-1.68,0.96c-0.624,0.096-0.724,0.716-1.44,0.72
		c0-0.16,0-0.32,0-0.479c1.545-6.215,3.745-11.775,6.24-17.04c0.08,0,0.16,0,0.24,0c0.505-1.176,1.273-2.088,1.92-3.12
		c0-0.08,0-0.16,0-0.24C246.82,438.121,247.94,437.001,248.96,435.78z M215.84,466.021c0.461-0.979,0.765-2.115,0.96-3.36
		c0.6-3.079,1.286-6.073,2.16-8.88c1.064-2.047,2.944-5.074,4.819-7.438c0.692-0.746,1.387-1.49,2.045-2.271
		c0-0.001,0.001-0.001,0.001-0.002c0.001-0.002,0.003-0.004,0.005-0.006c-0.001,0.001-0.002,0.003-0.004,0.005
		c0.367-0.342,0.721-0.632,1.053-0.848c-0.073,0.047,0.056,0.406,0,0.479c-1.757,4.483-3.564,8.916-5.76,12.96
		c-0.431,0.85-0.921,1.639-1.2,2.641c-0.15,0.89-0.674,1.405-0.96,2.16c-0.854,1.306-1.574,2.746-2.16,4.319
		c-0.258,1.343-0.948,2.252-1.44,3.36c-0.421-0.067-0.439,1.435-0.72,0.72C214.95,468.491,215.22,467.08,215.84,466.021z
		 M188.24,595.141c-0.854-2.105-1.706-4.214-2.16-6.72c-0.155-0.886-0.247-1.834-0.72-2.4c-1.298-3.981-2.591-7.969-3.12-12.72
		c-0.108-2.292-0.357-4.442-0.72-6.48c0-0.079,0-0.159,0-0.239c-0.155-1.125,0.314-2.874-0.24-3.601c0-0.08,0-0.159,0-0.239
		c0-3.2,0-6.4,0-9.601c0-0.16,0-0.32,0-0.479c0.55-0.489,0.086-1.993,0.24-2.88c0.138-0.422-0.29-1.41,0.24-1.44
		c0.573-1.267,0.68-3,0.96-4.56c0.495-2.466,1.277-4.643,2.16-6.721c0.51-1.01,0.927-2.113,1.2-3.359
		c0.506-1.814,1.083-3.557,1.92-5.04c0.517-0.843,0.857-1.863,1.2-2.881c0.167-1.432,1.007-2.192,1.44-3.359
		c1.201-1.999,2.297-4.103,3.36-6.24c-0.039-0.279,0.053-0.427,0.24-0.479c1.733-3.067,3.333-6.267,4.8-9.601
		c-0.039-0.279,0.053-0.427,0.24-0.479c0.602-0.919,1.027-2.013,1.44-3.12c1.547-3.813,3.643-7.077,5.52-10.561
		c1.348-2.172,2.39-4.649,3.6-6.96c0.524-1.477,1.305-2.695,2.16-3.84c0.774-1.146,1.414-2.426,1.92-3.84
		c0.555-1.445,0.926-3.074,1.68-4.32c0.87-1.771,1.719-3.561,2.4-5.52c-0.037-0.598,0.377-0.743,0.48-1.2
		c0.854-1.306,1.574-2.746,2.16-4.32c-0.037-0.597,0.377-0.743,0.48-1.2c0.536-0.663,0.934-1.466,1.2-2.399
		c0.247-1.033,0.6-1.96,1.2-2.641c0.521-0.519,0.679-1.4,0.96-2.159c0.435-1.406,0.921-2.759,1.68-3.841
		c0.517-0.843,0.857-1.863,1.2-2.88c0.536-1.863,1.26-3.54,2.16-5.04c0.123-0.495,0.285-0.952,0.473-1.381
		c0-0.002,0.001-0.003,0.001-0.004c0,0.001,0,0.001,0,0.002c0.056-0.128,0.129-0.239,0.191-0.362c0-0.001,0.001-0.001,0.001-0.002
		c1.165-2.332,3.181-3.813,4.829-5.662c-1.645,1.853-3.664,3.329-4.829,5.662c0.933-1.443,2.589-2.354,3.894-4.013
		c1.579-2.007,0.916-2.063,3.84-2.4c1.71-0.197,3.308-1.279,5.28-1.199c0.255-0.545,1.509-0.092,2.16-0.24
		c0.505,0.129,1.467-0.201,1.813,0.086c-0.108,0.103-0.217,0.205-0.326,0.308c-0.091,0.086-0.182,0.172-0.273,0.258
		c-2.56,1.548-4.347,3.869-6.015,6.309c-0.67,1.81-1.713,3.246-2.64,4.8c-0.774,1.146-1.414,2.427-1.92,3.84
		c-0.445,1.315-0.905,2.615-1.68,3.601c-0.08,0-0.16,0-0.24,0c-0.078,0.882-0.592,1.328-0.72,2.16
		c-0.396,1.443-1.004,2.676-1.68,3.84c-0.771,1.389-1.358,2.962-1.92,4.56c-0.303,1.457-0.895,2.625-1.44,3.84
		c-0.496,0.705-0.669,1.731-0.96,2.641c-1.986,5.934-3.774,12.065-5.76,18c-0.485,1.114-0.798,2.401-0.96,3.84
		c0.018,0.897-0.101,1.659-0.48,2.16c-0.419,1.021-0.641,2.239-0.72,3.6c0.105,1.865-0.942,2.577-0.72,4.561
		c-0.271,1.409-1.318,2.041-1.68,3.359c-0.733,1.347-1.016,3.145-1.2,5.04c-0.398,0.492-0.398,2.628,0,3.12c0,0.24,0,0.48,0,0.72
		c-0.14,1.261,0.423,1.817,0.24,3.12c-0.431,3.97-1.023,7.777-1.68,11.521c-0.08,0-0.16,0-0.24,0c-0.11,1.169-0.909,1.65-1.2,2.64
		c-0.089,0.791-0.561,1.199-0.96,1.681c-0.711,1.129-1.396,2.284-1.92,3.6c-0.558,1.682-1.468,3.012-2.16,4.56
		c-0.536,0.664-0.934,1.466-1.2,2.4c-0.699,2.261-1.707,4.214-2.64,6.24c-0.553,1.047-0.95,2.25-1.2,3.6
		c-0.767,2.834-1.348,5.853-1.92,8.88c-0.598,2.843-1.002,5.878-1.2,9.12c-0.604,5.637-1.444,11.036-1.92,16.8
		c-0.558,1.683-0.081,4.398-0.24,6.48c0,0.24,0,0.479,0,0.72c-0.752,5.567,0.379,13.02-0.72,18.24
		c-0.378,2.582-1.776,4.145-2.64,6.24c-0.422-0.067-0.439,1.434-0.72,0.72c1.145-5.535,1.725-10.864-0.96-15.12
		c-0.306-0.813-0.716-1.523-1.2-2.16c-0.233-0.006-0.186-0.294-0.24-0.479C189.965,599.656,189.205,597.297,188.24,595.141z
		 M189.2,695.701c0.864-0.064,1.157,0.443,1.92,0.479c0.34-0.021,0.68-0.039,0.72,0.24c0.817,0.703,2.576,0.465,2.88,1.681
		c0.276,0.283,0.271,0.848,0.24,1.439c-0.001,2.241-4.394,1.363-6,1.2C189.724,699.22,189.709,696.645,189.2,695.701z
		 M191.36,685.861c-0.039,0.279,0.053,0.427,0.24,0.479c0.835,2.524,1.901,4.819,2.16,7.92c-0.279,0.039-0.427-0.053-0.48-0.239
		c-0.392-0.649-1.9-0.181-1.92-1.2c-0.202-0.918-0.313-1.927-0.24-3.12C191.276,688.497,190.806,686.666,191.36,685.861z
		 M202.4,782.581c-9.44,0-18.88,0-28.32,0c0.099-2.541,0.461-4.818,0.96-6.96c0.034-1.727,1.294-2.226,1.68-3.6
		c0.386-2.095,0.808-4.152,1.2-6.24c0.469-3.291,0.828-6.692,1.2-10.08c0.937-5.943,1.539-12.221,2.64-18c0.08,0,0.16,0,0.24,0
		c0.286-3.954,1.298-7.183,1.44-11.28c0.309-3.051,0.787-5.933,1.44-8.64c0.08,0,0.16,0,0.24,0c0.081-2.239,0.879-3.761,0.96-6
		c0.389-2.892,1.131-5.429,1.92-7.92c0.187-0.053,0.279-0.201,0.24-0.48c1.096,0.714,5.189,0.502,7.44,0.24
		c0.131,0.349-0.281,1.24,0.24,1.2c0.438,0.922,0.511,2.209,0.48,3.6c0.609,6.111,0.403,13.037,1.2,18.96
		c0.29,1.551,0.425,3.255,0.96,4.561c0,0.08,0,0.16,0,0.24c2.058,2.465,1.076,8.396,1.2,12.96c0.139,5.113,1.012,10.121,1.2,14.88
		c0,0.24,0,0.479,0,0.72c0.153,0.887-0.311,2.391,0.24,2.88c0,0.24,0,0.48,0,0.72c0.153,0.888-0.311,2.392,0.24,2.88
		c0,0.16,0,0.32,0,0.48c0.152,0.808-0.309,2.229,0.24,2.64c0,0.16,0,0.32,0,0.48c0.153,0.887-0.311,2.391,0.24,2.88
		c0,0.08,0,0.16,0,0.24c0.152,0.808-0.309,2.229,0.24,2.64c0,0.08,0,0.16,0,0.24c-0.038,0.048-0.053,0.134-0.06,0.235
		c-0.001,0.023-0.002,0.047-0.003,0.071c0,0.111,0.008,0.238,0.03,0.39v0.001C202.095,779.329,202.52,780.683,202.4,782.581z
		 M211.04,782.581c-1.12,0-2.24,0-3.36,0c0.249-0.676,0.116-1.902-0.24-2.64c-0.382-1.219-0.683-2.518-0.72-4.08
		c0.597-0.848,1.398,2.07,2.16,2.64c0.474,0.166,0.64,0.641,0.72,1.2C209.835,780.753,211.301,781.832,211.04,782.581z
		 M214.16,782.581c-0.153-0.906-1.569-1.493-0.96-2.399c0-0.48,0-0.96,0-1.44c-0.073-1.048-0.603-1.638-0.96-2.4
		c-0.535-1.225-1.093-2.427-1.44-3.84c-0.668-1.812-1.193-3.767-1.92-5.52c-0.911-2.529-1.665-5.216-2.16-8.16
		c-0.135-1.145-0.251-2.31-0.72-3.12c-0.555-1.285-0.804-2.876-0.96-4.56c-0.024-1.267,0.109-0.818,0.48-0.24
		c0.435,0.765,0.861,1.539,0.96,2.64c0.31,1.131,0.639,2.242,1.2,3.12c0.679,0.921,0.995,2.206,1.44,3.36
		c0.558,1.682,1.248,3.231,2.16,4.56c0.53,1.229,1.283,2.236,1.68,3.601c0.742,1.498,1.475,3.005,2.4,4.319
		c0.474,0.166,0.64,0.641,0.72,1.2c0.881,1.52,1.839,2.961,2.88,4.32c0.295,0.104,0.493,0.307,0.48,0.72
		c0.637,1.286,2.286,2.83,2.4,3.84C219.28,782.581,216.72,782.581,214.16,782.581z M224.96,782.581
		c-0.208-2.271-1.673-3.287-2.64-4.8c-0.295-0.105-0.493-0.307-0.48-0.72c-0.735-1.025-1.345-2.176-2.16-3.12
		c-1.082-5.719-4.533-9.067-6.24-14.16c-0.435-1.166-0.87-2.33-1.44-3.36c-0.836-1.884-1.618-3.822-2.16-6
		c-0.273-1.246-0.69-2.35-1.2-3.359c-0.719-1.521-1.446-3.034-1.92-4.801c-0.187-1.173-0.555-2.165-0.96-3.119
		c-0.504-0.937-0.821-2.06-0.96-3.36c2.071-0.151,3.423,0.416,5.52,0.24c0.08,0,0.16,0,0.24,0c1.978,5.062,4.29,9.79,6.24,14.88
		c0.348,1.171,0.944,2.096,1.44,3.12c1.014,1.626,1.894,3.386,2.64,5.279c0.417,0.943,0.859,1.861,1.44,2.641
		c1.586,2.574,3.184,5.136,4.56,7.92c0.507,0.854,0.932,1.789,1.68,2.4c0.258,0.621,0.868,0.892,0.96,1.68
		c0.574,0.785,1.026,1.694,1.68,2.399c0.846,0.674,1.414,1.627,1.92,2.641c0.694,1.306,1.79,2.21,2.4,3.6
		C232,782.581,228.48,782.581,224.96,782.581z M239.36,782.341c-0.891-1.028-1.761-2.079-2.4-3.359
		c-0.866-1.214-1.671-2.488-2.64-3.601c-1.013-0.906-1.812-2.028-2.4-3.359c-0.865-1.136-1.493-2.508-2.4-3.601
		c-1.964-2.596-3.617-5.503-5.04-8.64c-0.668-1.171-1.117-2.563-1.92-3.6c-0.695-0.986-1.254-2.106-1.68-3.36
		c-0.413-1.107-0.838-2.202-1.44-3.12c-1.049-1.831-1.89-3.87-2.64-6c-0.343-1.017-0.683-2.037-1.2-2.88
		c-0.796-1.124-1.373-2.467-1.68-4.08c0.179-0.541,1.346-0.094,1.92-0.24c1.107,0.014,2.347-0.106,3.12,0.24
		c0.333,0.547,1.671,0.09,2.4,0.24c0.08,0,0.16,0,0.24,0c0,0.08,0,0.16,0,0.239c2.41-0.09,4.176,0.465,6.48,0.48
		c0.186,0.773,0.502,1.418,0.96,1.92c0.233,0.007,0.187,0.294,0.24,0.48c1.058,2.142,2.133,4.267,3.36,6.239
		c3.829,6.812,7.536,13.744,11.52,20.4c0.501,0.939,1.085,1.794,1.68,2.64c2.014,2.626,4.003,5.277,6,7.92c0,0.08,0,0.16,0,0.24
		c1.477,1.564,3.762,3.597,4.56,5.04C250.797,782.424,244.564,782.897,239.36,782.341z M261.68,782.581
		c-2.08-2.64-5.204-4.236-7.2-6.96c-1.368-1.112-2.497-2.463-3.36-4.08c-0.609-0.91-1.243-1.797-1.92-2.64
		c-0.484-0.316-0.812-0.788-0.96-1.44c-0.501-0.938-1.086-1.794-1.68-2.64c-0.608-0.432-0.902-1.178-1.2-1.92
		c-0.533-1.147-1.235-2.125-1.92-3.12c-0.833-0.927-1.458-2.062-1.92-3.36c-0.658-1.342-1.336-2.664-2.16-3.84
		c-0.803-1.277-1.664-2.497-2.16-4.08c-0.51-0.85-0.795-1.925-1.44-2.64c-0.803-1.277-1.663-2.497-2.16-4.08
		c-0.589-1.011-0.956-2.244-1.68-3.12c-0.233-0.007-0.186-0.294-0.24-0.479c0.96,0,1.92,0,2.88,0c0.08,0,0.16,0,0.24,0
		c2.292,0.428,4.935,0.504,7.68,0.479c1.386,3.813,3.454,6.946,5.04,10.56c0.5,1.021,1.004,2.036,1.68,2.881
		c1.73,2.59,3.479,5.161,5.04,7.92c0.613,0.907,1.178,1.861,1.92,2.64c0.686,0.594,1.318,1.241,1.68,2.16c0,0.08,0,0.16,0,0.24
		c5.161,6.438,12.593,11.887,17.76,17.52C270.96,782.581,266.32,782.581,261.68,782.581z M280.64,782.581
		c-6.898-7.102-15.109-12.891-21.12-20.88c-1.236-1.244-2.32-2.64-3.12-4.32c-1.263-1.776-2.35-3.73-3.6-5.52
		c-1.178-1.462-2.134-3.146-2.88-5.04c-0.51-0.85-0.794-1.926-1.44-2.64c-0.926-1.475-1.78-3.021-2.4-4.801
		c-0.034-0.994,1.67-0.25,2.4-0.479c1.205,0.399,4.075,0.399,5.28,0c1.862-0.139,4.29,0.29,5.76-0.24
		c0.711,0.89,1.408,1.792,2.16,2.64c1.239,1.162,2.241,2.56,3.12,4.08c0,0.08,0,0.16,0,0.24c0.667,0.532,1.063,1.337,1.68,1.92
		c3.469,4.132,6.77,8.43,10.08,12.721c0.925,1.154,1.903,2.257,2.88,3.359c0.631,0.568,1.447,0.953,1.68,1.92
		c5.41,5.95,11.666,12.334,17.521,17.04C292.641,782.581,286.64,782.581,280.64,782.581z M311.12,782.581
		c-1.447-0.474-3.465,0.013-5.231,0.239c0.428,0.012,0.854,0.023,1.278,0.031c-0.425-0.008-0.853-0.019-1.282-0.031
		c-0.01,0-0.02,0-0.029,0c-0.802-0.022-1.597-0.039-2.36-0.023c-0.192-0.054-0.375-0.119-0.535-0.216
		c-0.087-0.053-0.148-0.122-0.198-0.2v-0.001c0.013-0.378-0.048-0.683-0.151-0.945c-0.001-0.001-0.001-0.002-0.001-0.003v0.001
		c-0.001-0.003-0.002-0.005-0.003-0.008c0.001,0.003,0.002,0.005,0.003,0.007c0.018-0.666,0.069-1.328-0.85-1.25
		c-1.122-0.478-2.081-1.119-2.88-1.92c-8.183-6.617-15.321-14.279-22.08-22.32c-0.412-0.309-0.944-0.496-0.96-1.2
		c-0.599-0.842-1.242-1.639-1.92-2.4c-0.412-0.309-0.944-0.496-0.96-1.199c-0.461-0.819-1.093-1.467-1.68-2.16
		c-0.352-0.209-0.761-0.359-0.72-0.96c-2.305-3.295-5.03-6.17-7.2-9.601c3.243-0.037,6.328-0.231,9.12-0.72
		c3.157-0.523,6.418-0.942,9.84-1.2c3.071,7.409,7.324,13.637,11.04,20.4c0.949,1.61,1.943,3.177,3.12,4.56
		c2.366,2.754,4.8,5.44,6.96,8.4c1.255,1.545,2.479,3.121,3.84,4.56c0.481,0.399,1.115,0.645,1.2,1.44c0,0.08,0,0.16,0,0.24
		c1.044,0.636,1.448,1.911,2.399,2.64C311.777,779.681,311.08,781.152,311.12,782.581z M314.24,767.221
		c-0.417,0.863-0.701,1.859-0.72,3.12c-0.132,0.35,0.28,1.241-0.24,1.2c-0.319,0.881-0.65,1.75-0.72,2.88c0,0.16,0,0.32,0,0.48
		c-0.814,0.762-2.158-1.964-2.88-2.88c0-0.08,0-0.16,0-0.24c-3.109-3.291-5.86-6.94-8.881-10.32c-1.185-1.055-2.147-2.332-2.88-3.84
		c-1.03-1.369-1.781-3.019-2.88-4.32c-1.092-1.308-1.947-2.852-2.64-4.56c-0.581-1.179-1.195-2.324-1.92-3.36
		c-0.945-1.295-1.817-2.663-2.4-4.319c-0.772-1.708-1.762-3.198-2.4-5.04c0.341,0.021,0.68,0.039,0.72-0.24
		c1.214-0.386,2.583-0.617,4.08-0.72c1.685-0.156,3.274-0.405,4.56-0.96c0.811-0.47,1.976-0.585,3.12-0.721
		c-0.179,0.739,0.659,0.462,0.48,1.2c0.662,1.098,1.256,2.264,2.159,3.12c2.827,4.533,5.794,8.927,8.4,13.68
		c0.563,1.038,1.198,2.002,1.92,2.88c0.601,0.68,1.61,0.95,1.92,1.921c0.15,0.729-0.307,2.066,0.24,2.399
		c0.541,0.179,0.094,1.347,0.24,1.92c0.014,1.106,0.11,2.129,0.479,2.88C314.383,764.38,314.731,766.074,314.24,767.221z
		 M313.04,731.701c0.015,0.305,0.029,0.599,0.044,0.895c-0.094,1.137-0.007,2.195,0.154,3.2c0.185,4.031,0.26,7.425-0.128,11.192
		c-0.003,0.01-0.005,0.021-0.007,0.031c-0.05,0.208-0.097,0.418-0.14,0.631c-0.008,0.038-0.016,0.076-0.023,0.115
		c-0.039,0.202-0.074,0.407-0.105,0.615c-0.006,0.039-0.013,0.077-0.018,0.116c-0.07,0.495-0.112,1.004-0.111,1.531
		c-0.047,0.289-0.093,0.577-0.146,0.873c-0.55-0.33-0.563-1.196-1.2-1.44c-2.703-4.496-5.656-8.743-8.16-13.439
		c-0.518-1.243-1.669-1.852-1.92-3.36c0-0.08,0-0.16,0-0.24c1.516-0.005,2.325-0.715,3.84-0.72c1.118-0.241,2.279-0.441,3.12-0.96
		c1.06-0.62,2.471-0.89,3.84-1.2c0.03,0.431-0.294,0.507-0.24,0.96C311.7,731.441,312.827,731.114,313.04,731.701z M315.438,580.639
		c-0.016-5.717-0.001-12.152,0.005-18.544C315.438,568.486,315.424,574.923,315.438,580.639z M404,500.581c0,1.68,0,3.359,0,5.04
		c0,1.199,0,2.399,0,3.6c0,1.36,0,2.72,0,4.08c0,0.96,0,1.92,0,2.88c0,0.4,0,0.8,0,1.2c-0.602,1.237,0.074,3.753-0.479,5.04
		c0,0.72,0,1.439,0,2.16c-0.651,7.749-1.515,15.285-2.64,22.56c-0.521-0.04-0.109,0.852-0.24,1.2c-0.08,0-0.16,0-0.24,0
		c-0.402,3.678-1.817,6.342-2.4,9.84c-0.474,0.566-0.564,1.515-0.72,2.4c-0.08,0-0.16,0-0.24,0c-0.485,2.153-1.076,4.203-1.92,6
		c0-1.84,0-3.681,0-5.521c0-0.08,0-0.16,0-0.24c0.399-1.523,0.399-4.716,0-6.239c0-0.561,0-1.12,0-1.681
		c-0.223-1.377-0.306-2.894-0.72-4.08c0.067-0.387-0.227-0.413-0.24-0.72c-1.54-0.261-1.473,1.087-1.439,2.4c-0.08,0-0.16,0-0.24,0
		c-0.69,3.949-0.444,8.835-0.48,13.439c-0.552,0.568-0.086,2.154-0.239,3.12c-0.046,4.034-0.774,7.387-0.96,11.28
		c-0.009,1.831-0.864,2.815-0.721,4.8c-0.08,0-0.159,0-0.239,0c0.028,2.349-0.813,3.828-0.721,6.24
		c-0.719,3.121-1.327,6.353-1.68,9.84c-0.373,0.907-0.468,2.092-0.479,3.36c-0.08,0-0.16,0-0.24,0
		c-1.277,8.323-1.672,17.528-1.44,27.36c0,0.88,0,1.76,0,2.64c-0.559,2.081-0.081,5.199-0.24,7.68c0,2,0,4,0,6
		c0,0.32,0,0.641,0,0.96c-0.558,1.603-0.081,4.238-0.239,6.24c0,1.44,0,2.88,0,4.32c-0.372,2.348-0.42,5.02-0.48,7.68
		c0.012,3.131-0.729,5.512-1.2,8.16c-0.396,0.259-0.396,2.141,0,2.4c0.132,0.349-0.28,1.24,0.24,1.199
		c-0.023,0.744,0.108,1.332,0.48,1.681c0.063,1.48,0.172,1.716,0,3.12c-1.547,2.293-1.062,6.618-4.08,7.439c0,0.08,0,0.16,0,0.24
		c-0.24,0-0.48,0-0.721,0c-4.331,0.229-8.272,0.847-12,1.68c-4.422,0.777-9.005,1.396-13.68,1.92
		c-2.793,0.204-6.932,0.804-10.08,0.96c-1.778,0.089-4.521-0.473-4.56,1.44c-0.061,2.912,7.643,1.456,10.08,1.2
		c0.255-0.545,1.508-0.092,2.159-0.24c1.849-0.072,3.548-0.293,5.04-0.72c4.351-0.771,8.967-1.274,13.44-1.92
		c1.865-0.055,3.482-0.357,5.04-0.721c0.907-0.372,2.092-0.468,3.36-0.479c0,0.08,0,0.16,0,0.24c1.099-0.261,2.35-0.37,3.6-0.48
		c1.08,2.243,1.376,6.671,0.24,9.12c-0.007-1.096-2.36-0.923-2.4,0c-1.147,1.252-2.71,2.09-4.08,3.12
		c-1.447,0.393-2.477,1.202-3.84,1.68c-0.221,0.419-0.75,0.53-1.44,0.48c-0.946,0.173-1.942,0.297-2.64,0.72
		c-2.386,1.054-5.365,1.515-8.64,1.68c-0.809,0.152-2.229-0.309-2.641,0.24c-0.399,0-0.8,0-1.199,0
		c-1.443,0.157-3.517-0.316-4.561,0.24c-0.363,0.356-1.054,0.387-1.68,0.479c-0.08,0-0.16,0-0.24,0
		c-0.641,0.149-1.692,1.812-0.24,1.92c9.688,1.563,19.022-1.048,26.16-3.6c1-0.36,2.039-0.681,2.641-1.44c0.079,0,0.159,0,0.239,0
		c1.724-0.155,3.821-3.693,4.561-3.359c-0.639,1.681-0.961,3.679-1.2,5.76c-0.353,0.768-0.687,1.553-0.72,2.64
		c-0.496,0.465-0.458,1.462-0.721,2.16c-0.516,3.324-0.111,7.568-0.239,11.28c0.159,3.681-0.319,7.999,0.239,11.28
		c0,0.399,0,0.8,0,1.2c0,0.8,0,1.6,0,2.399c0.151,0.729-0.307,2.067,0.24,2.4c0.154,0.966-0.312,2.552,0.24,3.12
		c-0.166,2.005,0.405,3.273,0.24,5.279c0.157,1.763-0.317,4.157,0.239,5.521c-0.054,0.905,0.252,2.172-0.479,2.399
		c-0.27,0.051-0.374,0.267-0.48,0.48c-0.645,0.165-0.464-0.496-0.72-0.72c-0.838-1.322-1.217-3.104-2.16-4.32
		c-0.734-1.265-1.344-2.655-1.68-4.32c-0.516-1.724-1.016-3.464-1.68-5.04c-0.437-1.163-0.737-2.463-0.721-4.08
		c-0.614-4.533,0.234-11.791-3.074-13.873c-0.017-0.015-0.029-0.032-0.046-0.047c-0.007,0.004-0.011,0.009-0.018,0.013
		c-0.149-0.09-0.295-0.185-0.462-0.253c-1.779,1.772,0.421,4.954,0.479,7.2c0.473,0.647,0.144,2.098,0.24,3.12
		c0.15,0.729-0.307,2.067,0.24,2.4c0.29,2.03,0.589,4.051,0.96,6c-0.396,0.106-0.396,1.813,0,1.92c0.02-0.38-0.115-0.915,0.24-0.96
		c0.02,0.38-0.115,0.915,0.24,0.96c0.575,2.385,1.366,4.554,2.399,6.479c-0.229,1.029,0.674,0.927,0.72,1.681
		c3.937,6.623,5.289,15.831,5.521,26.16c-0.64,0-1.28,0-1.92,0c-0.8,0-1.601,0-2.4,0c0,0.08,0,0.16,0,0.239c-0.8,0-1.6,0-2.399,0
		c-6.4,0-12.801,0-19.2,0c-8.88,0.16-18.4-0.319-26.88,0.24c-0.561,0-1.12,0-1.681,0c-3.049-0.15-6.708,0.308-9.359-0.24
		c0-0.319,0-0.64,0-0.96c0.635-2.244-0.122-5.881,0.479-8.159c0.35-3.882,2.448-6.779,1.44-11.28c-2.845-2.342-0.73-8.623-1.2-12.96
		c-0.057-1.737,0.17-3.189,0.72-4.32c0.563-0.736,0.233-0.187,0.72,0.24c0.73,1.11,1.828,1.852,3.12,2.399
		c2.558,0.687,10.957,2.122,10.32-1.68c-0.359-0.281-0.981-0.298-1.2-0.72c-2.108-0.372-4.529-0.431-6.96-0.48
		c-1.37,0.011-2.391-0.329-2.88-1.2c-0.316-0.691-1.521-0.492-2.16-0.239c-0.146-0.574,0.302-1.741-0.24-1.92
		c-0.237-0.643-0.494-1.266-0.479-2.16c0-3.12,0-6.24,0-9.36c0-0.32,0-0.64,0-0.96c0.556-1.044,0.083-3.117,0.24-4.56
		c0-0.24,0-0.48,0-0.721c0.551-0.489,0.087-1.993,0.239-2.88c0-0.239,0-0.479,0-0.72c0.555-0.727,0.085-2.476,0.24-3.6
		c0-0.24,0-0.48,0-0.721c0.554-0.646,0.085-2.314,0.24-3.359c0-0.24,0-0.48,0-0.721c0.555-0.805,0.084-2.636,0.24-3.84
		c0-0.08,0-0.16,0-0.24c0.714-0.966-0.234-3.594,0.479-4.56c0.322,0.158,0.447,0.513,0.48,0.96c0.622,1.138,1.156,2.363,2.16,3.12
		c0.649,0.31,0.853,1.066,1.92,0.96c0.276-0.284,0.271-0.849,0.239-1.44c-0.429-1.41-1.154-2.524-1.92-3.6
		c-1.105-1.294-1.847-2.953-1.92-5.28c0.096-0.976-1.021-0.738-1.199-1.439c0.154-1.119-0.308-2.852,0.232-3.587
		c0.021,0.022,0.043,0.045,0.063,0.067c1.382,2.013,2.867,3.922,5.464,4.719c0.399,0,0.8,0,1.2,0
		c0.913-0.206,1.383-0.856,1.439-1.92c-2.177-2.029-4.203-4.211-6.396-6.226c0.17,0.187,0.346,0.391,0.523,0.6
		c-0.178-0.21-0.354-0.414-0.525-0.602c-0.001-0.001-0.003-0.003-0.004-0.004c-0.153-0.168-0.3-0.314-0.443-0.455
		c-0.033-0.033-0.07-0.075-0.104-0.106c-0.175-0.165-0.34-0.303-0.491-0.406c-0.035-0.024-0.101-0.051-0.177-0.078
		c-0.342-0.294-0.67-0.601-1.023-0.883c-0.153-0.887,0.311-2.391-0.24-2.88c0-0.479,0-0.96,0-1.439
		c-0.158-1.763,0.317-4.157-0.24-5.521c0-0.56,0-1.12,0-1.68c0-1.6,0-3.2,0-4.8c-0.151-0.809,0.31-2.229-0.24-2.641
		c0-0.8,0-1.6,0-2.399c-0.158-1.922,0.318-4.478-0.239-6c0-0.24,0-0.48,0-0.72c-0.155-1.125,0.313-2.874-0.24-3.601
		c0-0.16,0-0.32,0-0.479c0-0.96,0-1.921,0-2.88c-0.157-1.363,0.315-3.356-0.24-4.32c0-0.96,0-1.92,0-2.88
		c-0.159-4.4,0.319-9.439-0.24-13.44c0-0.08,0-0.16,0-0.24c-0.158-2.001,0.318-4.638-0.239-6.239c0-0.08,0-0.16,0-0.24
		c-0.466-13.375-1.217-26.464-0.96-40.561c0-1.2,0-2.399,0-3.6c-0.104-9.54,0.298-19.05-0.24-28.561
		c-0.602-2.678-1.912-4.647-2.4-7.439c-0.394-2.247-1-4.279-1.68-6.24c-0.813-1.747-1.118-4.002-1.68-6
		c-0.075-1.045-0.277-1.963-0.48-2.88c-0.679,0.041-1.646-0.207-1.68,0.479c0,1.521,0,3.04,0,4.561
		c0.159,2.641-0.319,5.919,0.24,8.16c0.424,1.495,0.923,2.916,1.199,4.56c0.174,0.947,0.298,1.942,0.721,2.641
		c0.541,1.298,0.909,2.77,0.96,4.56c0.039,0.279-0.054,0.427-0.24,0.479c-0.476-1.204-1.169-2.191-1.92-3.119
		c-0.296-0.105-0.493-0.308-0.48-0.721c-1.856-2.942-3.984-5.614-6-8.399c-0.643-0.558-1.124-1.275-1.439-2.16
		c-0.917-1.483-1.94-2.86-2.88-4.32c-0.475-0.166-0.641-0.64-0.721-1.2c-0.675-1.324-1.366-2.633-2.16-3.84
		c-0.76-1.079-1.37-2.31-1.68-3.84c-0.931-2.51-1.367-5.513-1.92-8.4c-0.703-2.189-0.037-5.093-1.44-6.96
		c-1.354-0.338-1.871,2.089-1.68,4.08c-0.021,1.621,0.072,3.128,0.48,4.32c0.537,0.104,0.097,1.183,0.24,1.68
		c0.141,2.26,0.646,4.154,1.44,5.761c0.444,0.676,0.743,1.496,0.96,2.399c0.62,1.78,1.474,3.326,2.4,4.8
		c0.91,1.01,1.521,2.318,2.159,3.601c0.563,1.037,1.198,2.002,1.921,2.88c0.295,0.104,0.492,0.307,0.479,0.72c0,0.08,0,0.16,0,0.24
		c0.779,0.9,1.381,1.979,2.16,2.88c0.605,0.354,0.992,0.928,1.2,1.68c0,0.08,0,0.16,0,0.24c0.707,0.732,1.212,1.668,1.92,2.4
		c0.605,0.354,0.991,0.928,1.2,1.68c0,0.08,0,0.16,0,0.24c1.822,1.777,2.53,4.67,4.8,6c1.063,0.022,1.648-0.432,1.439-1.68
		c0.461,0.979,0.781,2.099,0.721,3.6c-0.02,0.368,0.098,0.873-0.217,0.945c-0.052-0.057-0.104-0.111-0.155-0.168
		c-0.049-0.053-0.098-0.107-0.147-0.16c-2.431-3.122-6.069-5.036-9.081-7.577c-2.476-1.444-4.655-3.185-6.72-5.04
		c-2.193-1.967-4.289-4.031-6-6.48c-0.586-0.134-0.511-0.929-1.44-0.72c-0.628,2.391,2.051,3.547,2.64,5.521
		c2.625,2.895,5.399,5.641,8.64,7.92c1.969,1.231,3.727,2.674,5.28,4.319c0,0.08,0,0.16,0,0.24c2.535,1.466,3.965,4.035,6.479,5.521
		c0.24,0,0.48,0,0.721,0c0.697,0.182-0.218,1.978,0.479,2.16c-0.105,13.014-0.938,26.582-0.24,39.6c0,0.24,0,0.48,0,0.72
		c0.159,2.481-0.318,5.599,0.24,7.681c0,0.159,0,0.319,0,0.479c-0.158,1.842,0.317,4.317-0.24,5.76
		c-0.56-0.08-1.033-0.246-1.199-0.72c-0.845-0.676-1.86-1.181-2.88-1.68c-1.39-0.451-2.444-1.236-3.601-1.92
		c-1.195-0.526-2.842-1.469-4.08-1.2c-0.035,0.595-0.045,1.165,0.48,1.2c0.408-0.089,0.452,0.187,0.479,0.479c0,0.08,0,0.16,0,0.24
		c2.199,2.28,5.474,3.486,7.92,5.52c0.267,0.454,0.599,0.842,0.721,1.44c0.662,0.537,1.257,1.143,1.199,2.4
		c-0.064,0.814,0.187,1.945-0.239,2.399c-0.634,1.384-0.336,3.514-0.721,4.561c-2.11-0.93-4.269-1.812-6.239-2.88
		c-0.447-0.033-0.803-0.158-0.96-0.48c-0.779-0.581-1.697-1.022-2.641-1.44c-1.476-0.523-2.763-1.236-3.84-2.159
		c-0.712-0.568-1.54-1.021-2.4-1.44c-0.41,0.011-0.621-0.179-0.72-0.479c-3.291-2.149-6.337-4.543-9.6-6.721
		c-0.652-0.147-1.124-0.476-1.44-0.96c-1.61-1.029-3.615-2.702-5.28-4.08c-1.532-1.268-2.968-3.27-5.28-3.6
		c-0.096,3.711,3.251,5.581,5.83,7.438c-0.28-0.206-0.551-0.407-0.841-0.623c0.304,0.228,0.606,0.448,0.9,0.665
		c-0.02-0.014-0.04-0.028-0.059-0.042c0.216,0.159,0.444,0.326,0.65,0.481c2.791,2.158,5.583,4.477,8.4,6.24
		c0.752,0.208,1.325,0.595,1.68,1.2c2.638,1.922,5.38,3.739,8.4,5.279c1.072,0.288,1.926,0.795,2.64,1.44
		c0.715,0.646,1.79,0.93,2.64,1.439c0.447,0.033,0.803,0.158,0.961,0.48c0.985,0.694,2.105,1.254,3.359,1.68
		c0.807-0.007,1.27,0.331,1.681,0.72c0.704,0.496,1.73,0.67,2.64,0.961c0.859,0.02,1.387,0.373,2.16,0.479
		c0.369,1.234,0.421,4.054,0,5.28c-2.192-0.447-4.157-1.123-6.24-1.681c-1.219-0.381-2.406-0.793-3.84-0.96
		c-2.993-0.526-5.472-1.567-8.16-2.399c-1.429-0.731-3.17-1.15-4.8-1.681c-1.772-0.308-3.072-1.087-4.56-1.68
		c-3.156-2.207-9.987-1.518-13.44,0c-0.596,0.624-0.049,1.46,0.48,1.68c2.611,1.17,6.823-0.082,9.6,0.48
		c2.125,0.274,3.605,1.194,5.28,1.92c0.786,0.414,1.598,0.803,2.64,0.96c7.043,2.077,13.946,4.294,21.6,5.76
		c-0.603,1.917,0.331,3.212,0.24,5.28c-0.549,0.411-0.088,1.832-0.24,2.641c0,0.159,0,0.319,0,0.479
		c-0.313,0.567-0.4,1.359-0.72,1.92c0.067,0.547-0.058,0.902-0.48,0.96c-0.581,0.379-1.061,0.858-1.439,1.44
		c-0.524,0.755-1.237,1.323-2.16,1.68c-1.192,0.647-2.256,1.424-3.36,2.16c-1.08,0.919-2.407,1.593-3.84,2.16
		c-1.147,0.532-2.125,1.234-3.12,1.92c-0.104,0.295-0.307,0.493-0.72,0.479c-0.08,0-0.16,0-0.24,0
		c-0.584,0.856-1.261,1.619-1.68,2.641c0.869-0.171,1.989-0.091,2.64-0.48c0.193-0.127,0.39-0.25,0.721-0.24
		c1.491-0.827,3.201-1.438,4.56-2.399c2.569-1.591,5.17-3.15,7.68-4.8c0.919-0.521,1.311-1.57,2.4-1.92
		c0.383,0.496,1.465,0.295,1.92,0.72c0.674,2.046-0.206,5.646,0.48,7.68c0,0.16,0,0.32,0,0.48c0.159,3.28-0.319,7.199,0.239,10.08
		c0,0.079,0,0.159,0,0.239c0,0.641,0,1.28,0,1.92c-0.504-0.295-0.936-0.664-1.439-0.96c-0.547-0.116-1.227-0.476-1.681,0
		c0,0.24,0,0.48,0,0.721c0.262,1.258,1.378,1.662,2.16,2.399c1.197,0.083,0.762,1.799,1.2,2.641c0,0.56,0,1.12,0,1.68
		c0.158,1.683-0.317,3.997,0.24,5.28c0,0.399,0,0.8,0,1.2c0,4.8,0,9.6,0,14.399c0,0.24,0,0.48,0,0.72
		c-0.554,0.727-0.085,2.476-0.24,3.601c0,0.16,0,0.319,0,0.479c-0.555,0.806-0.084,2.637-0.24,3.84c0,0.08,0,0.16,0,0.24
		c-0.552,3.608-0.346,7.975-0.96,11.521c-0.192-0.127-0.39-0.251-0.72-0.24c-1.604-0.438-2.79,0.85-4.56,0.96
		c-1.426,0.255-2.628,0.732-3.841,1.2c-2.253,0.946-5.011,1.389-7.68,1.92c-2.764,0.437-5.374,1.026-7.92,1.68
		c-5.239,1.081-10.644,1.996-16.56,2.4c-5.525,0.315-11.006,0.674-16.32,1.2c-0.24,0-0.48,0-0.72,0c-3.68,0-7.36,0-11.04,0
		c-0.4,0-0.8,0-1.2,0c-1.203-0.557-3.438-0.083-5.04-0.24c-0.08,0-0.16,0-0.24,0c-6.787-0.573-13.457-1.264-20.4-1.68
		c-0.08,0-0.16,0-0.24,0c-0.333-0.548-1.67-0.09-2.4-0.24c-0.08,0-0.16,0-0.24,0c-0.333-0.548-1.67-0.09-2.4-0.24
		c-0.08,0-0.16,0-0.24,0c-0.039-0.281-0.438-0.202-0.72-0.24c0-0.239,0-0.479,0-0.72c-0.32,0-0.64,0-0.96,0
		c-0.807,0.007-1.27-0.33-1.68-0.72c-2.062-1.138-2.237-4.163-3.12-6.48c-0.636-2.084-1.202-4.238-1.44-6.72
		c-0.265-3.335-0.813-6.386-1.44-9.36c-0.617-3.382-1.133-6.867-1.68-10.319c0.018-0.898-0.101-1.659-0.48-2.16
		c-0.326-0.074-0.199-0.602-0.24-0.96c-0.173-0.947-0.297-1.942-0.72-2.641c-1.273-4.727-2.41-9.59-3.84-14.16
		c-0.217-0.902-0.516-1.724-0.96-2.399c-0.743-1.177-1.377-2.463-1.68-4.08c-0.203-0.997-0.329-2.071-0.72-2.88
		c-0.984-3.977-1.549-8.371-1.92-12.96c-0.464-2.988,0.567-4.665,0-7.2c-0.521,0.04-0.109-0.852-0.24-1.2
		c-0.77-5.772-0.308-11.844,2.4-14.88c1.702-2.458,2.188-6.133,3.6-8.88c0.444-0.676,0.659-1.581,0.96-2.4
		c0.258-1.342,0.948-2.252,1.44-3.359c0.695-0.985,1.254-2.106,1.68-3.36c2.166-7.049,1.199-15.39,1.44-24.96
		c0.549-0.411,0.088-1.832,0.24-2.641c-0.012-3.052,0.335-5.744,0.72-8.399c0.515-2.846,0.973-5.747,1.2-8.88
		c0.43-5.17,1.013-10.187,2.16-14.641c0.62-1.779,1.186-3.613,1.68-5.52c0.51-1.891,1.068-3.732,1.92-5.28
		c1.102-2.259,2.121-4.6,3.12-6.96c-0.039-0.279,0.053-0.427,0.24-0.479c1.701-3.18,3.533-6.228,5.52-9.12
		c1.553-2.687,3.453-5.027,5.52-7.2c0.08,0,0.16,0,0.24,0c2.391-3.449,5.2-6.48,7.68-9.84c1.099-1.781,2.383-3.378,3.84-4.801
		c2.12-2.439,4.156-4.964,6.24-7.439c0.157-0.883,0.885-1.195,1.44-1.68c0.08,0,0.16,0,0.24,0
		c14.64-16.241,28.939-32.821,45.12-47.521c0.789-0.972,1.72-1.801,2.88-2.4c0.08,0,0.16,0,0.24,0c1.05-1.19,2.487-1.992,3.6-3.12
		c0.485-0.555,0.798-1.282,1.681-1.439c5.233-5.486,11.319-10.121,17.04-15.12c0.309-0.411,0.496-0.943,1.2-0.96
		c0.92-0.76,2.023-1.336,2.88-2.16c0.659-0.78,1.635-1.245,2.64-1.68c1.271-0.65,2.479-1.361,3.6-2.16
		c2.325-1.675,4.951-3.05,7.681-4.32c1.659-0.98,3.754-1.526,5.04-2.88c4.535-1.225,8.012-3.508,13.2-4.08
		c1.237-0.357,1.188,0.572,1.92,0.72c0.479,0,0.96,0,1.439,0c0.597,0.581,2.17,0.102,2.641-0.24c0.94-0.611,2.889-0.176,3.359,0.48
		c0.406,0.794,1.293,1.108,1.92,1.68c0.265,0.865,1.942,1.295,1.44,2.16c-0.16,0-0.32,0-0.48,0c-0.729,0.15-2.066-0.307-2.399,0.24
		c-0.497,0.143-1.577-0.296-1.681,0.24c-1.518,0.402-3.255,0.584-5.039,0.72c-1.685,0.156-3.275,0.405-4.561,0.96
		c-0.296,0.345-0.825,0.455-1.439,0.48c-3.383,1.257-5.865,3.415-7.681,6.24c-0.709,1.37-1.646,2.513-2.399,3.84
		c-0.52,0.44-0.506,1.414-0.48,2.399c0.721,0.64,0.859,1.861,2.16,1.92c1.839,0.239,2.562-0.639,3.84-0.96
		c0.149-0.095,0.297-0.164,0.442-0.22c0.054-0.021,0.106-0.031,0.16-0.047c0.077-0.022,0.155-0.041,0.231-0.054
		c0.07-0.011,0.141-0.021,0.21-0.024c0.036-0.002,0.073,0.002,0.109,0.002c0.171,0.002,0.34,0.022,0.506,0.065
		c0.003,0,0.005,0.001,0.007,0.001c1.405,1.261,2.793,2.539,4.269,3.729l0.001,0.001c0,0,0,0,0.001,0.001
		c0.125,0.117,0.249,0.226,0.372,0.326c0.007,0.006,0.015,0.013,0.021,0.019c0.13,0.105,0.26,0.2,0.39,0.281
		c1.998,1.282,3.707,2.854,5.28,4.561c1.66,1.54,3.329,3.07,4.8,4.8c-0.358,0.281-0.981,0.298-1.2,0.72
		c-2.388,1.053-4.404,2.476-6.72,3.601c-3.181,2.179-6.121,4.598-8.399,7.68c0.01,0.41-0.179,0.621-0.48,0.72
		c-0.777,0.903-1.494,1.866-2.16,2.88c-1.1,1.781-2.383,3.378-3.84,4.801c-0.587,0.693-1.219,1.341-1.68,2.159
		c-0.892,1.669-2.113,3.007-3.36,4.32c-0.912,1.008-1.751,2.09-2.4,3.36c-2.698,4.181-5.2,8.56-7.92,12.72c-0.08,0-0.159,0-0.239,0
		c-0.309,1.052-1.035,1.686-1.44,2.64c-1.715,3.405-3.771,6.469-5.52,9.841c-0.08,0-0.16,0-0.24,0
		c-0.116,1.083-0.767,1.633-0.96,2.64c-1.937,5.344-3.823,10.737-5.28,16.56c-0.364,0.597-0.463,1.457-0.48,2.4
		c0,0.72,0,1.44,0,2.16c0.369,1.871-0.887,5.367,0.961,5.76c0.444-0.516,0.161-1.758,0.239-2.64c0-0.24,0-0.48,0-0.721
		c1.295-2.386,1.471-5.89,2.16-8.88c0.304-1.855,0.832-3.488,1.44-5.04c0.889-1.591,1.386-3.573,1.92-5.52
		c0.429-1.731,1.092-3.229,1.92-4.561c0.08,0,0.16,0,0.24,0c0.318-1.521,1.119-2.56,1.68-3.84c1.09-2.59,2.546-4.814,4.08-6.96
		c0.08,0,0.16,0,0.24,0c0.308-1.052,1.034-1.686,1.439-2.64c0.743-1.657,1.566-3.234,2.641-4.561c0.567-0.712,1.02-1.54,1.439-2.399
		c1.279-2.32,2.72-4.48,4.32-6.48c6.456-7.943,12.021-16.777,20.16-23.04c1.068-1.171,2.504-1.976,4.08-2.64
		c2.071-1.049,4.171-2.068,6.239-3.12c0.455-0.426,1.125-0.636,1.681-0.96c0.004,1.444-0.479,2.401-0.96,3.36c-0.08,0-0.16,0-0.24,0
		c-0.179,1.778,0.631,2.569,1.2,3.6c1.048,0.009,2.168,0.089,2.64-0.48c0.41-0.389,0.874-0.726,1.68-0.72
		c1.223-0.377,2.491-0.709,3.601-1.2c3.153-1.069,8.698-2.271,12.24-0.72c2.236,0.403,3.534,1.746,4.319,3.6c0,0.08,0,0.16,0,0.24
		c0.568,1.512,1.071,3.09,1.92,4.32c-0.006,0.807,0.331,1.27,0.721,1.68c1.571,4.588,3.968,8.353,6,12.48
		c0.289,1.311,0.781,2.418,1.439,3.359c0.637,1.924,1.29,3.83,2.16,5.521c0.154,0.886,0.246,1.834,0.72,2.4
		c1.247,5.473,2.191,11.249,2.88,17.279C404,500.101,404,500.341,404,500.581z"/>
		</g>
		<g id="mouth1">
			<path fillRule="evenodd" clipRule="evenodd" d="M264.08,356.581c-0.546-0.334-0.93-0.831-1.44-1.2
		c-1.713-1.352-5.857-1.132-7.92-0.24c-0.834,0.526-1.91,0.81-2.64,1.44c-0.633,0.258-0.633,1.662,0,1.92
		c0.27,0.051,0.374,0.266,0.48,0.48C254.948,360.203,265.82,361.728,264.08,356.581z"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M258.56,343.62c1.428-0.652,3.017-1.143,5.04-1.2
		c3.899-0.261,8.041-0.279,12.24-0.24c0.64,0,1.28,0,1.92,0c1.04,0,2.08,0,3.12,0c0.5-0.3,1.012-0.588,1.44-0.96
		c0.811-0.736,0.167-2.252-0.96-2.4c-1.443-0.558-3.918-0.082-5.76-0.24c-0.72,0-1.44,0-2.16,0c-0.8,0-1.6,0-2.4,0
		c-0.24,0-0.48,0-0.72,0c-3.585-0.146-6.571,0.309-9.6,0.72c-0.492,0.389-1.573,0.188-1.92,0.72
		c-4.381,1.779-9.674,2.646-13.92,4.56c-0.964,0.076-1.913,0.167-2.4,0.72c-0.387,0.333-0.76,0.68-1.2,0.96
		c-0.907,0.574-0.382,2.412,0.72,2.4c0.24,0,0.48,0,0.72,0c0.053,0.188,0.201,0.279,0.48,0.24c0.804-1.036,2.341-1.339,3.6-1.92
		c2.421-0.938,5.045-1.675,7.92-2.16C256.256,344.676,257.424,344.164,258.56,343.62z"/>
		</g>
		<g id="eyebrow1">
			<g>
				<path fillRule="evenodd" clipRule="evenodd" d="M216.56,275.94c-0.331-0.011-0.527,0.113-0.72,0.24
			c-1.026,3.036-3.369,8.033-0.48,10.8c0.355,0.278,0.605,0.278,0.96,0c2.48-4.8,8.954-5.606,15.84-6
			c-0.612-2.205-1.997-5.671-3.36-7.92C224.65,273.071,220.617,275.058,216.56,275.94z M214.64,284.1
			c0.137-2.263,0.628-4.171,1.68-5.52c3.703-0.01,7.381-1.6,11.04-2.4c0.106,0.613,0.336,1.104,0.72,1.44
			C222.844,278.864,217.014,280.793,214.64,284.1z"/>
				<g>
					<path fillRule="evenodd" clipRule="evenodd" d="M285.44,264.18c-0.204,0.044-0.227-0.094-0.24-0.24
				c-2.56-0.641-4.775-1.625-6.96-2.64c-3.289-1.335-7.971-0.484-11.52,0c-0.489,0.551-1.993,0.087-2.88,0.24
				c-0.333,0.547-1.671,0.09-2.4,0.24c-0.421,0.379-1.279,0.321-1.92,0.48c-3.338,0.718-4.15,2.881-5.52,6
				c-0.569,1.297-1.903,3.004-1.68,3.84c0.859,3.216,9.991-1.53,11.52-2.16c6.382-2.629,16.754-5.275,23.28-1.44
				C290.296,267.328,287.172,264.669,285.44,264.18z M257.36,268.26c1.621-2.643,9.918-4.97,13.92-6.24
				c0.943-0.299,4.208,0.863,5.04,1.2C268.256,263.156,263.361,266.261,257.36,268.26z"/>
				</g>
			</g>
			<path fillRule="evenodd" clipRule="evenodd" fill="#3F3330" d="M271.28,262.02c-4.002,1.271-12.299,3.597-13.92,6.24
		c6-2,10.896-5.104,18.96-5.04C275.488,262.883,272.223,261.721,271.28,262.02z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#3F3330" d="M216.32,278.58c-1.052,1.349-1.543,3.257-1.68,5.52
		c2.374-3.307,8.204-5.236,13.44-6.48c-0.383-0.336-0.613-0.827-0.72-1.44C223.702,276.98,220.023,278.57,216.32,278.58z"/>
		</g>
		<g id="mouth1_1_" display="none">
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M264.08,355.581c-0.546-0.334-0.93-0.831-1.44-1.2
		c-1.713-1.352-5.857-1.132-7.92-0.24c-0.834,0.526-1.91,0.81-2.64,1.44c-0.633,0.258-0.633,1.662,0,1.92
		c0.27,0.051,0.374,0.266,0.48,0.48C254.948,359.203,265.82,360.728,264.08,355.581z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M258.56,342.62c1.428-0.652,3.017-0.143,5.04-0.2
		c3.899-0.261,8.041-0.279,12.24-0.24c0.64,0,1.28,0,1.92,0c1.04,0,2.08,1,3.12,1c0.5-0.3,1.012,0.412,1.44,0.04
		c0.811-0.736,0.167-3.252-0.96-3.4c-3.392-1.311-3.322-1.031-5.76-1.24c-0.72,0-1.44,0-2.16,0c-0.8,0-1.6,0-2.4,0
		c-0.24,0-0.48,0-0.72,0c-3.585-0.146-6.571-0.691-9.6-0.28c-0.492,0.389-1.573,0.188-1.92,0.72
		c-4.381,1.779-9.674,2.646-13.92,4.56c-0.964,0.076-1.913,3.167-2.4,3.72c-0.387,0.333-0.76,2.68-1.2,2.96
		c-0.907,0.574-0.382,2.412,0.72,2.4c0.24,0,0.48,0,0.72,0c0.053,0.188,0.201,0.279,0.48,0.24c0.804-1.036,2.341-6.339,3.6-6.92
		c2.421-0.938,5.045-1.675,7.92-2.16C256.256,343.676,257.424,343.164,258.56,342.62z"/>
		</g>
		<g id="eyebrow1_1_" display="none">
			<g display="inline">
				<path fillRule="evenodd" clipRule="evenodd" d="M216.56,277.94c-0.331-0.011-0.527,0.113-0.72,0.24
			c-1.026,3.036-3.369,8.033-0.48,10.8c0.355,0.278,0.605,0.278,0.96,0c2.48-4.8,8.954-7.606,15.84-8
			c-0.612-2.205-1.997-5.671-3.36-7.92C224.65,273.071,220.617,277.058,216.56,277.94z M214.64,286.1
			c0.137-2.263,0.628-4.171,1.68-5.52c3.703-0.01,7.381-3.6,11.04-4.4c0.106,0.613,0.336,1.104,0.72,1.44
			C222.844,278.864,217.014,282.793,214.64,286.1z"/>
				<g>
					<path fillRule="evenodd" clipRule="evenodd" d="M285.44,266.18c-0.204,0.044-0.227-0.094-0.24-0.24
				c-2.56-0.641-4.775-1.625-6.96-2.64c-3.289-1.335-7.971-0.484-11.52,0c-0.489,0.551-1.993,0.087-2.88,0.24
				c-0.333,0.547-1.671,0.09-2.4,0.24c-0.421,0.379-2.279-0.679-2.92-0.52c-3.338,0.718-4.15,2.881-5.52,6
				c-0.569,1.297-1.903,3.004-1.68,3.84c0.859,3.216,10.991-0.53,12.52-1.16c6.382-2.629,16.754-5.275,23.28-1.44
				C290.296,269.328,287.172,266.669,285.44,266.18z M256.36,269.26c1.621-2.643,10.918-3.97,14.92-5.24
				c0.943-0.299,4.208,0.863,5.04,1.2C268.256,265.156,262.361,267.261,256.36,269.26z"/>
				</g>
			</g>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" fill="#3F3330" d="M271.28,264.02
		c-4.002,1.271-13.299,2.597-14.92,5.24c6-2,11.896-4.104,19.96-4.04C275.488,264.883,272.223,263.721,271.28,264.02z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" fill="#3F3330" d="M216.32,280.58
		c-1.052,1.349-1.543,3.257-1.68,5.52c2.374-3.307,8.204-7.236,13.44-8.48c-0.383-0.336-0.613-0.827-0.72-1.44
		C223.702,276.98,220.023,280.57,216.32,280.58z"/>
		</g>
		<g id="mouth1_2_" display="none">
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M264.08,355.581c-0.546-0.334-0.93-0.831-1.44-1.2
		c-1.713-1.352-5.857-1.132-7.92-0.24c-0.834,0.526-1.91,0.81-2.64,1.44c-0.633,0.258-0.633,1.662,0,1.92
		c0.27,0.051,0.374,0.266,0.48,0.48C254.948,359.203,265.82,360.728,264.08,355.581z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M258.56,342.62c1.428-0.652,3.017-1.143,5.04-1.2
		c3.899-0.261,8.041-1.279,12.24-1.24c0.64,0,2.28-1,2.92-1c1.443,0,1.677-1,3.12-1c0.5-0.3,1.012-1.588,1.44-1.96
		c0.811-0.736,0.167-2.252-0.96-2.4c-1.443-0.558-1.918,1.918-3.76,1.76c-0.72,0-4.44,2-5.16,2c-0.8,0-1.6,0-2.4,0
		c-0.24,0-0.48,0-0.72,0c-3.585-0.146-6.571,0.309-9.6,0.72c-0.492,0.389-1.573,0.188-1.92,0.72
		c-4.381,1.779-9.674,2.646-13.92,4.56c-0.964,0.076-1.913-0.833-2.4-0.28c-0.387,0.333-0.76,0.68-1.2,0.96
		c-0.907,0.574-0.382,2.412,0.72,2.4c0.24,0,0.48,0,0.72,0c0.053,0.188,0.201,0.279,0.48,0.24c0.804-1.036,2.341-0.339,3.6-0.92
		c2.421-0.938,5.045-1.675,7.92-2.16C256.256,343.676,257.424,343.164,258.56,342.62z"/>
		</g>
		<g id="eyebrow1_2_" display="none">
			<g display="inline">
				<path fillRule="evenodd" clipRule="evenodd" d="M216.56,278.94c-0.331-0.011-0.527,0.113-0.72,0.24
			c-1.026,3.036-3.369,8.033-0.48,10.8c0.355,0.278,0.605,0.278,0.96,0c2.48-4.8,8.954-5.606,15.84-6
			c-0.612-2.205-1.997-5.671-3.36-7.92C224.65,276.071,220.617,278.058,216.56,278.94z M214.64,287.1
			c0.137-2.263,0.628-4.171,1.68-5.52c3.703-0.01,7.381-1.6,11.04-2.4c0.106,0.613,0.336,1.104,0.72,1.44
			C222.844,281.864,217.014,283.793,214.64,287.1z"/>
				<g>
					<path fillRule="evenodd" clipRule="evenodd" d="M285.44,267.18c-0.204,0.044-0.227-0.094-0.24-0.24
				c-2.56-0.641-4.775-1.625-6.96-2.64c-3.289-1.335-7.971-0.484-11.52,0c-0.489,0.551-1.993,0.087-2.88,0.24
				c-0.333,0.547-1.671,0.09-2.4,0.24c-0.421,0.379-1.279,0.321-1.92,0.48c-3.338,0.718-4.15,2.881-5.52,6
				c-0.569,1.297-1.903,3.004-1.68,3.84c0.859,3.216,9.991-1.53,11.52-2.16c6.382-2.629,16.754-5.275,23.28-1.44
				C290.296,270.328,287.172,267.669,285.44,267.18z M257.36,271.26c1.621-2.643,9.918-4.97,13.92-6.24
				c0.943-0.299,4.208,0.863,5.04,1.2C268.256,266.156,263.361,269.261,257.36,271.26z"/>
				</g>
			</g>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" fill="#3F3330" d="M271.28,265.02
		c-4.002,1.271-12.299,3.597-13.92,6.24c6-2,10.896-5.104,18.96-5.04C275.488,265.883,272.223,264.721,271.28,265.02z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" fill="#3F3330" d="M216.32,281.58
		c-1.052,1.349-1.543,3.257-1.68,5.52c2.374-3.307,8.204-5.236,13.44-6.48c-0.383-0.336-0.613-0.827-0.72-1.44
		C223.702,279.98,220.023,281.57,216.32,281.58z"/>
		</g>
		<g id="lighting">
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M299.12,286.74c-0.05-1.761-1.812-3.176-3.84-3.84
		c-0.741,0.539-1.381,1.179-1.92,1.92c0.136,3.406-2.204,5.579-3.601,8.16C292.277,296.243,299.213,290.018,299.12,286.74z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M331.521,394.74c0.194,0.046,0.239,0.241,0.479,0.24
		c-0.505-0.775-1.064-1.495-1.439-2.4c-0.194-0.046-0.239-0.241-0.48-0.24c-2.105-4.134-2.192-10.287-4.08-14.64
		c-1.468,0.452-3.118,0.721-4.8,0.96c-0.062,0.498-1.055,0.065-1.2,0.48c1.021,3.938,2.011,7.909,2.641,12.24
		c0.15,0.729-0.308,2.067,0.239,2.4c0.547,2.253,0.463,5.137,0.96,7.44c0.431,0.03,0.507-0.294,0.961-0.24
		c1.763-0.399,5.196-0.399,6.96,0c5.166,1.874,9.401,4.678,13.199,7.92c0.349-0.371,0.937-0.504,1.681-0.48
		c0.501-0.379,1.262-0.498,2.16-0.48c-4.023-3.417-8.415-6.466-12.721-9.6C334.637,397.064,332.607,396.373,331.521,394.74z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M377.36,440.341c1.044,0.556,3.117,0.083,4.561,0.239
		c6.306,1.774,11.188,4.972,13.439,10.801c-0.076-0.557,0.039-0.922,0.24-1.2c0.625-2.181-1.201-3.893-1.2-6.24
		c-0.279-0.04-0.261-0.379-0.24-0.72c-3.178-4.022-6.479-7.921-10.08-11.521c-1.762-1.758-3.824-3.215-5.52-5.04
		c-1.994-1.525-3.694-3.346-5.28-5.279c-0.98-0.78-1.86-1.66-2.64-2.641c-0.241,0.001-0.286-0.194-0.48-0.239
		c-0.61-0.67-2.345-0.216-3.12-0.721c-1.04,0-2.08,0-3.12,0c-0.646,0.554-2.314,0.085-3.359,0.24
		c-0.106,0.214-0.211,0.429-0.48,0.48c1.673,3.449,4.345,6.284,6.721,9.119c3.329,3.975,6.452,7.953,9.359,12.48
		C376.738,440.002,376.682,440.539,377.36,440.341z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M377.12,707.221c-0.72,5.518-5.875,7.619-4.319,14.641
		c0.151,0.808-0.31,2.229,0.239,2.64c-0.053,0.772,0.425,1.016,0.24,1.92c0.153,0.887-0.311,2.391,0.24,2.88
		c1.885,7.507,4.169,16.693,2.64,25.44c0.943,1.217,1.322,2.998,2.16,4.32c0.256,0.224,0.075,0.885,0.72,0.72
		c0.106-0.214,0.211-0.43,0.48-0.48c0.731-0.228,0.426-1.494,0.479-2.399c-0.557-1.363-0.082-3.758-0.239-5.521
		c0.165-2.006-0.406-3.274-0.24-5.279c-0.552-0.568-0.086-2.154-0.24-3.12c-0.547-0.333-0.089-1.671-0.24-2.4c0-0.8,0-1.6,0-2.399
		c0-0.4,0-0.801,0-1.2c-0.559-3.281-0.08-7.6-0.239-11.28c0.128-3.712-0.276-7.956,0.239-11.28c0.263-0.698,0.225-1.695,0.721-2.16
		c0.033-1.087,0.367-1.872,0.72-2.64c0.239-2.081,0.562-4.079,1.2-5.76C380.941,703.527,378.844,707.065,377.12,707.221z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M387.44,430.021c0.414,0.786,1.267,1.134,1.68,1.92
		C390.011,432.425,388.278,430.121,387.44,430.021z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M353.12,240.42c-4.22-6.42-8.404-12.876-12.479-19.44
		c1.418,6.771,5.079,11.788,8.16,17.04c1.525,2.603,3.256,5.067,5.04,7.44c1.77,2.355,3.854,4.467,5.52,6.96
		c3.813,5.71,7.915,11.383,11.76,17.28c4.571,7.01,9.047,15.367,8.16,26.88c-0.51,6.621-2.321,12.487-4.56,17.52
		c-2.202,4.951-5.354,10.639-5.761,16.32c-0.854,11.939,4.521,19.378,10.08,27.6c1.141,0.939,2.181,1.979,3.12,3.12
		c0.449,1.082,1.288,1.068,1.681,0c0-0.08,0-0.16,0-0.24c-0.283-1.237-1.161-1.88-1.681-2.88c-0.667-1.252-1.602-2.238-2.16-3.6
		c0.001-0.241-0.194-0.286-0.239-0.48c-1.178-1.463-2.171-3.11-2.88-5.04c-0.033-0.447-0.158-0.802-0.48-0.96
		c-1.145-2.55-4.686-7.965-1.92-10.56c1.552,1.408,2.212,3.708,4.08,4.8c0.976,0.304,1.785,0.775,2.399,1.44
		c2.39,1.131,4.736,2.304,6.961,3.6c0.157,0.322,0.513,0.447,0.96,0.48c1.155,0.125,1.883,0.677,2.64,1.2
		c-3.223-3.498-7.903-7.617-10.08-12.96c-0.618-0.662-0.742-1.817-1.2-2.64c-2.918-5.02-2.719-15.467-0.479-21.12
		c0.085-1.115,0.308-2.092,0.72-2.88c1.672-7.448,1.545-17.733-0.24-24.96c-0.371-0.349-0.504-0.936-0.479-1.68
		c-1.089-2.432-2.2-4.84-3.12-7.44c-0.188-0.053-0.279-0.201-0.24-0.48c-1.222-1.498-1.949-3.491-2.88-5.28
		c-0.003-0.477-0.655-0.305-0.48-0.96C367.079,258.462,360.826,248.714,353.12,240.42z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M357.92,241.86c2.754,3.406,5.582,6.738,7.921,10.56
		c0.854,0.586,1.296,1.584,1.92,2.4c1.46,2.06,2.852,4.188,4.08,6.48c0.249-3.13-1.01-6.83-1.44-10.08
		c-0.455-1.065-0.855-2.185-0.96-3.6c-1.198-3.122-2.014-6.626-2.88-10.08c-0.345-0.296-0.455-0.825-0.48-1.44
		c-0.818-1.741-1.559-3.562-2.16-5.52c-0.674-1.245-1.216-2.624-1.92-3.84c-0.759-0.601-1.079-1.641-1.439-2.64
		c-0.068-1.132-0.981-1.419-1.2-2.4c-1.009-1.231-1.729-2.751-2.4-4.32c0.001-0.241-0.194-0.285-0.239-0.48
		c-0.581-0.299-0.776-0.983-0.96-1.68c0-0.241-0.194-0.286-0.24-0.48c-2.5-3.9-5.067-7.733-7.92-11.28
		c-1.176-1.225-2.372-2.428-3.36-3.84c-1.46-1.26-2.82-2.62-4.08-4.08c-1.606-1.194-2.981-2.618-4.56-3.84
		c-0.146,0.014-0.284,0.036-0.24,0.24C345.47,205.991,355.511,220.11,357.92,241.86z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M345.681,272.58c-0.604,0.465,0.281,1.462,0.239,2.16
		c0.33,1.536,1.126,3.528,0.48,5.28c-0.187,0.054-0.474,0.007-0.48,0.24c-3.601-0.159-1.163-6.357-3.84-7.44
		c-0.308,0.652-0.749,1.17-0.96,1.92c1.791,2.234,4.105,10.362,3.12,14.88c-0.408,1.874-3.136,4.063-4.8,6.24
		c-1.493,1.953-2.934,4.903-4.8,6.48c-3.219,2.718-8.001,2.108-10.561,3.12c0.338-3.686,0.771-6.443,0.721-10.08
		c0.295-0.985,1.996-0.563,1.92-1.92c-0.095-0.975,0.088-1.672,0.96-1.68c0.665-0.775,1.645-1.235,2.159-2.16
		c2.136-2.391,0.568-5.961-0.72-8.16c-0.595-0.605-1.243-1.157-1.439-2.16c-0.32,0-0.641,0-0.96,0
		c1.995,2.936,2.339,10.172-2.16,10.56c0.283-2.764-0.941-4.02-2.641-4.8c-0.582-3.018-2.006-5.194-2.399-8.4
		c-0.317,0.003-0.22,0.421-0.48,0.48c0,0.08,0,0.16,0,0.24c-0.212,3.068,0.409,6.969-1.2,8.64c-0.668,0.73-1.753-0.152-1.92-0.72
		c-2.462-3.618-5.252-6.907-7.92-10.32c-0.53-0.189-0.601-0.84-0.96-1.2c-0.194-0.046-0.239-0.241-0.479-0.24
		c-0.106-0.213-0.211-0.429-0.48-0.48c0.769,4.662,3.409,8.376,5.04,12.48c1.554,3.91,1.698,8.101,4.561,10.56
		c-3.398,15.722-2.98,35.26-9.841,47.52c-1.968,2.279-3.135,5.091-5.04,7.68c-1.859,2.528-4.661,4.725-5.76,6.96
		c-0.118,0.24,0.116,0.458,0.24,0.48c0.878-0.802,1.828-1.532,2.88-2.16c3.516-2.484,6.68-5.321,9.36-8.64
		c1.589-2.011,3.264-3.937,5.04-5.76c0.085-0.315,0.422-0.377,0.479-0.72c2.12-2.36,3.596-5.365,5.28-8.16
		c0.188-0.053,0.279-0.201,0.24-0.48c1.283-1.997,0.62-5.939,3.359-6.48c0.63,2.03-0.611,4.159-1.199,5.76
		c-1.888,10.882-4.489,25.46-1.92,37.68c0.24,0,0.285,0.194,0.479,0.24c1.526-0.874,4.218-0.582,5.76-1.44
		c0.125-0.355,0.355-0.605,0.48-0.96c-0.552-0.568-0.086-2.154-0.24-3.12c0-0.64,0-1.28,0-1.92c-0.557-1.124-0.083-3.277-0.24-4.8
		c0-0.48,0-0.96,0-1.44c-0.553-0.647-0.085-2.315-0.239-3.36c0-0.48,0-0.96,0-1.44c-0.4-1.444-0.4-4.556,0-6c0-0.32,0-0.64,0-0.96
		c-0.129-0.929,0.428-1.172,0.239-2.16c0.15-0.729-0.307-2.067,0.24-2.4c0-0.16,0-0.32,0-0.48c0.155-1.125-0.313-2.874,0.24-3.6
		c0-0.16,0-0.32,0-0.48c0.104-8.535,2.378-14.902,3.6-22.32c0.067-0.813,0.382-1.378,0.24-2.4c-0.804-0.076-1.513-0.247-1.92-0.72
		c-1.044,0.004-1.338-0.742-2.64-0.48c0.175-0.655-0.478-0.483-0.48-0.96c-2.02-1.314,0.961-3.359,2.16-1.68
		c1.599,0.081,2.39,0.97,4.08,0.96c0,0.08,0,0.16,0,0.24c1.2,0,2.399,0,3.6,0c1.39-1.411,2.903-2.696,4.08-4.32
		c1.663-1.697,3.147-3.573,4.561-5.52c1.565-2.915,4.461-4.499,5.279-8.16c1.323-4.698,0.97-10.23-0.479-14.88
		C346.806,274.495,346.208,273.573,345.681,272.58z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M325.521,375.541c0,0.08,0,0.16,0,0.24
		c0.557,0.077,0.921-0.039,1.2-0.24c1.519-0.542,1.451-3.617,0.72-5.04c-0.463,0.098-0.189,0.93-0.96,0.72
		c-0.963,0.797-3.092,0.428-4.32,0.96c-0.333,0.547-1.67,0.089-2.399,0.24c-0.444,0.356-1.141,0.46-1.921,0.48
		c-0.035,0.444-0.813,0.147-0.96,0.48c-0.061,1.421,0.367,2.353,0.48,3.6c0.16,0,0.32,0,0.479,0c1.51-0.331,2.85-0.831,4.561-0.96
		C323.155,375.576,324.287,375.507,325.521,375.541z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M362,414.661c0.16,0,0.32,0,0.48,0
		c0.502-0.865-1.176-1.295-1.44-2.16c-0.627-0.572-1.514-0.886-1.92-1.68c-0.471-0.656-2.419-1.091-3.359-0.48
		c-0.471,0.342-2.044,0.821-2.641,0.24c-0.479,0-0.96,0-1.439,0c-0.989,3.646,4.188,3.159,6.239,4.56
		c0.104-0.537,1.184-0.098,1.681-0.24C359.934,414.354,361.271,414.811,362,414.661z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M401.12,482.581c-0.474-0.566-0.565-1.515-0.72-2.4
		c-0.87-1.69-1.523-3.597-2.16-5.521c-0.658-0.941-1.15-2.049-1.439-3.359c-2.032-4.128-4.429-7.893-6-12.48
		c-0.39-0.41-0.727-0.873-0.721-1.68c-0.849-1.23-1.352-2.809-1.92-4.32c-0.838,0.369,1.213,5.821,1.92,8.16
		c0.939,3.105,1.627,5.824,2.4,8.16c1.849,5.585,3.011,11.581,4.32,16.56c0.45,1.714,1.338,3.047,1.439,4.801
		c0.222,3.793-0.903,8.142-1.439,12c-1.609,11.582-3.691,22.306-6.96,32.88c-0.193-4.76-3.284-8.391-4.801-12
		c-0.626-1.49-0.828-4.834-3.359-3.84c-2.326,0.913,0.112,8.811,0.479,10.8c0.767,4.154,1.572,7.894,2.16,11.04
		c1.752,9.377,1.822,16.813,1.68,25.68c0,1.2,0,2.4,0,3.601c-0.542,5.82,0.633,10.779-0.479,16.56
		c-0.254,1.319-1.076,2.537-1.44,3.84c-1.093,3.917-1.875,8.581-2.64,12.96c-1.036,5.928-2.328,11.975-2.64,18.24
		c-0.202,4.056-0.151,8.615,0,12.72c0.283,7.728,2.778,14.808,2.399,22.32c-0.12,2.378,0.793,4.958,0.48,6.96
		c-0.213,1.362-1.854,2.414-1.44,3.84c1.016,0.005,1.542-0.456,2.16,0.24c1.428,0.54,0.479,2.819-0.96,2.641
		c-0.045,0.355-0.58,0.22-0.96,0.239c-0.59,0.45-1.645,0.437-2.4,0.721c-0.16,0-0.319,0-0.479,0c-1.055,1.76,2.326,3.86,2.88,6.479
		c0.614,2.907-0.89,5.132,1.2,6.96c0.353,0.814,0.095,2.071-0.96,2.16c-0.883,0.317-1.964,0.437-1.681,1.92c0.24,0,0.48,0,0.721,0
		c3.019-0.821,2.533-5.146,4.08-7.439c0.172-1.404,0.063-1.64,0-3.12c-0.372-0.349-0.504-0.937-0.48-1.681
		c-0.521,0.041-0.108-0.851-0.24-1.199c-0.396-0.26-0.396-2.142,0-2.4c0.472-2.648,1.212-5.029,1.2-8.16
		c0.061-2.66,0.108-5.332,0.48-7.68c0-1.44,0-2.88,0-4.32c0.158-2.002-0.318-4.638,0.239-6.24c0-0.319,0-0.64,0-0.96c0-2,0-4,0-6
		c0.159-2.48-0.318-5.599,0.24-7.68c0-0.88,0-1.76,0-2.64c-0.231-9.832,0.163-19.037,1.44-27.36c0.08,0,0.16,0,0.24,0
		c0.012-1.269,0.106-2.453,0.479-3.36c0.353-3.487,0.961-6.719,1.68-9.84c-0.092-2.412,0.749-3.892,0.721-6.24
		c0.08,0,0.159,0,0.239,0c-0.144-1.984,0.712-2.969,0.721-4.8c0.186-3.894,0.914-7.246,0.96-11.28
		c0.153-0.966-0.313-2.552,0.239-3.12c0.036-4.604-0.21-9.49,0.48-13.439c0.08,0,0.16,0,0.24,0c-0.033-1.313-0.101-2.661,1.439-2.4
		c0.014,0.307,0.308,0.333,0.24,0.72c0.414,1.187,0.497,2.703,0.72,4.08c0,0.561,0,1.12,0,1.681c0.399,1.523,0.399,4.716,0,6.239
		c0,0.08,0,0.16,0,0.24c0,1.84,0,3.681,0,5.521c0.844-1.797,1.435-3.847,1.92-6c0.08,0,0.16,0,0.24,0
		c0.155-0.886,0.246-1.834,0.72-2.4c0.583-3.498,1.998-6.162,2.4-9.84c0.08,0,0.16,0,0.24,0c0.131-0.349-0.28-1.24,0.24-1.2
		c1.125-7.274,1.988-14.811,2.64-22.56c0-0.721,0-1.44,0-2.16c0.554-1.287-0.122-3.803,0.479-5.04c0-0.4,0-0.8,0-1.2
		c0-0.96,0-1.92,0-2.88c0-1.36,0-2.72,0-4.08c0-1.2,0-2.4,0-3.6c0-1.681,0-3.36,0-5.04c0-0.24,0-0.48,0-0.721
		C403.312,493.83,402.367,488.054,401.12,482.581z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M377.841,693.062c-0.628,0.565,0.392,1.515,0.239,2.399
		c-2.148,4.091-5.26,7.22-7.68,11.04c0.32,0,0.64,0,0.96,0c1.363-0.478,2.393-1.287,3.84-1.68c1.37-1.03,2.933-1.868,4.08-3.12
		c0.04-0.923,2.394-1.096,2.4,0c1.136-2.449,0.84-6.877-0.24-9.12C380.19,692.691,378.939,692.801,377.841,693.062z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M373.28,754.261c-1.033-1.926-1.824-4.095-2.399-6.479
		c-0.355-0.045-0.221-0.58-0.24-0.96c-0.355,0.045-0.221,0.58-0.24,0.96c1.002,9.398,4.685,16.115,4.32,26.88
		c0.152,0.887-0.312,2.391,0.239,2.88c0.157,1.443-0.315,3.517,0.24,4.561c0.8,0,1.601,0,2.4,0c0.64,0,1.28,0,1.92,0
		c-0.231-10.329-1.584-19.537-5.521-26.16C373.954,755.188,373.051,755.29,373.28,754.261z"/>
		</g>
	</svg>
