import React from 'react'
export const Confidant = () =>
	<svg version="1.1" className="character_svg" id="Confidant" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		width="576px" height="828px" viewBox="0 0 576 828" enableBackground="new 0 0 576 828" space="preserve">
		<g id="body">
			<g>
				<g id="dark_blue_jacket">
					<path fill="#344A5E" d="M244.08,447.761c-1.002,0.123-1.626-0.134-2.16-0.479c-11.976-10.424-15.925-28.875-17.28-49.92
				c-0.241,0.001-0.286-0.194-0.48-0.24c-5.467,1.842-11.918,3.882-19.2,3.12c-5.523,14.83-12.287,32.063-15.12,50.16
				c-1.534,9.798-2.275,19.456-4.08,28.561c-1.782,8.987-6.112,16.554-7.2,24.958c2.666-5.334,5.364-10.636,9.6-14.398
				c2.498,1.399,5.016-0.021,8.16-0.962c1.857-0.555,3.741-1.163,5.52-1.68c11.07-3.206,23.65-7.557,36.96-6.479
				c3.979,0.32,7.789,1.651,11.52,1.92c12.934,0.927,23.884-3.303,36-4.8c0.95-5.61,9.548-3.571,14.88-4.802
				c1.202-2.563,0.851-7.049-0.96-8.64C276.975,465.449,255.796,460.158,244.08,447.761z"/>
					<path fill="#344A5E" d="M398.88,467.921c-0.315-7.519,1.145-15.013,1.2-22.08c0.155-19.43,7.21-34.012,8.88-50.64
				c-2.554-0.887-5.104-1.774-7.92-2.4c-0.857-0.343-1.548-0.853-2.641-0.96c-0.653-0.627-1.704-0.856-2.642-1.2
				c-0.08,0-0.16,0-0.24,0c-0.216-0.422-0.838-0.439-1.197-0.721c-0.08,0-0.16,0-0.239,0c-1.504-1.217-3.326-2.115-5.28-2.88
				c-1.216-0.625-2.504-1.176-4.08-1.44c-0.556-0.414-1.989-1.197-2.641-0.72c0,0.08,0,0.16,0,0.24
				c-0.553,0.647-0.085,2.315-0.239,3.36c0,0.08,0,0.16,0,0.24c-0.395,1.046-0.549,2.332-0.721,3.6
				c-0.201,0.278-0.316,0.643-0.239,1.2c-0.444,0.676-0.659,1.581-0.96,2.4c0,0.08,0,0.16,0,0.24c-1.799,3.666-3.648,7.457-6,11.04
				c-2.095,3.191-4.885,6.141-6.96,9.6c-4.286,7.146-7.708,14.527-12.721,20.642c-4.877,5.946-11.313,10.976-18,15.118
				c-5.188,3.217-10.401,6.171-16.8,8.16c-1.857,0.578-4.559,2.014-6.479,0.48c0.329-0.232,0.665-0.44,1.004-0.639
				c0.125-0.071,0.25-0.143,0.375-0.212c0.202-0.107,0.406-0.214,0.611-0.313c0.413-0.204,0.83-0.395,1.251-0.563
				c-0.011,0.005-0.021,0.009-0.03,0.013c-1.411,0.512-2.808,1.004-4.172,1.476c0-0.721,0-1.438,0-2.16
				c-0.959,7.203-0.275,17.32-0.479,24.48c18.528-2.617,33.537-0.278,47.76,3.119c8.312,1.984,18.524,1.646,27.12,1.92
				c1.707,2.399,1.418,5.646,1.92,8.641c0.502,2.988,1.063,6.101,1.438,9.12c1.557,12.458,5.164,21.463,15.36,24.96
				c0.563-1.676,0.87-3.609,1.199-5.521c-4.454-6.125-7.588-18.707-8.16-29.039C397.608,486.534,399.281,477.445,398.88,467.921z"/>
				</g>
				<g id="blue_jacket">
					<path fill="#4F83B3" d="M135.572,470.535c0.146,0.182,0.285,0.371,0.428,0.557c0.305,0.396,0.608,0.793,0.9,1.202
				c0.156,0.22,0.308,0.443,0.461,0.667c0.271,0.396,0.537,0.792,0.796,1.198c0.146,0.228,0.291,0.458,0.434,0.688
				c0.263,0.429,0.521,0.859,0.774,1.297c0.123,0.214,0.248,0.426,0.368,0.641c0.288,0.511,0.568,1.028,0.844,1.553
				c0.076,0.146,0.154,0.286,0.23,0.431c3.256,6.276,5.791,13.332,8.851,19.914v0.001c0.965,2.037,1.994,4.046,3.081,6.013
				c0.002,0.004,0.004,0.008,0.006,0.011c1.312,2.307,2.798,4.64,4.396,6.924c0.012,0.019,0.023,0.032,0.035,0.049
				c0.515,0.735,1.037,1.464,1.57,2.186c0.065,0.088,0.132,0.174,0.197,0.261c0.484,0.648,0.97,1.293,1.464,1.924
				c0.147,0.188,0.296,0.37,0.445,0.558c0.419,0.527,0.837,1.057,1.259,1.566c0.274,0.332,0.55,0.649,0.825,0.975
				c0.304,0.356,0.607,0.726,0.911,1.07c0.586,0.671,1.171,1.322,1.752,1.947c0.335,0.36,1.321,2.481,2.16,1.2
				c0.148-1.325-1.031-2.166-1.68-3.12c-2.805-4.126-3.728-12.042-3.84-17.761c-0.06-3.046-2.245-7.168,0.48-8.881
				c0.191,0.078,0.36,0.181,0.519,0.289c0.047,0.033,0.091,0.069,0.136,0.105c0.111,0.089,0.215,0.185,0.311,0.289
				c0.038,0.041,0.076,0.08,0.112,0.123c0.12,0.146,0.231,0.304,0.327,0.476c0.007,0.016,0.013,0.028,0.02,0.041
				c0.088,0.163,0.166,0.338,0.235,0.521c0.023,0.06,0.043,0.121,0.064,0.182c0.051,0.151,0.098,0.311,0.139,0.47
				c0.015,0.062,0.032,0.117,0.045,0.18c0.05,0.221,0.093,0.444,0.128,0.682c0.003,0.021,0.005,0.045,0.008,0.066
				c0.031,0.22,0.057,0.443,0.077,0.675c0.006,0.07,0.012,0.141,0.017,0.212c0.016,0.203,0.029,0.408,0.039,0.617
				c0.002,0.054,0.006,0.104,0.008,0.16c0.037,0.884,0.035,1.806,0.046,2.712c0.003,0.191,0.007,0.386,0.011,0.578
				c0.001,0.039,0.001,0.079,0.002,0.118c0,0,0,0,0,0.002c0.122,1.329,0.287,2.616,0.616,3.739c0.001,0.002,0.002,0.004,0.003,0.006
				c0.161,0.278,0.386,0.594,0.638,0.902c0.011,0.014,0.021,0.027,0.033,0.041c0.121,0.147,0.246,0.292,0.375,0.432
				c0.014,0.017,0.027,0.029,0.041,0.044c0.131,0.143,0.263,0.276,0.392,0.399c0.002,0.001,0.004,0.003,0.006,0.005
				c0.127,0.12,0.25,0.229,0.368,0.321c0.012,0.009,0.024,0.02,0.036,0.029c0.111,0.086,0.214,0.152,0.309,0.207
				c0.017,0.01,0.034,0.019,0.05,0.025c0.09,0.046,0.17,0.073,0.235,0.077c0.01,0,0.019-0.003,0.028-0.003
				c0.064-0.004,0.117-0.025,0.146-0.085c0.29-4.206,0.031-8.161,0.24-12.24c0.333-6.486,2.779-11.478,4.8-16.32
				c0.606-1.45,0.913-3.828,2.88-3.84c0.735,6.319-4.281,11.712-4.8,18.96c-0.358,4.995,0.017,10.339-0.48,16.08
				c0.438-0.05,1.926-1.106,2.64-1.68c1.148-4.056,2.619-7.935,3.84-12c1.222-4.068,3.18-7.824,4.32-11.761
				c1.232-4.253,1.606-8.896,2.4-13.439c0.786-4.5,1.242-9.33,1.68-14.398c-11.013,2.072-21.863-0.55-31.44-2.16
				c-6.305-1.063-12.649-1.762-17.52-4.319c-0.053-0.188-0.201-0.279-0.48-0.24c-0.013,0.309-0.308,0.332-0.24,0.722
				c1.948,4.357,0.42,11.584-1.92,14.641c0.323,0.724,0.323,1.915,0,2.64C134.648,469.414,135.116,469.968,135.572,470.535z"/>
					<path fill="#4F83B3" d="M310.08,631.601c-3.646,1.155-8.741,2.938-13.92,3.359c-2.155-1.889-1.739-5.097-1.92-7.92
				c-0.186-2.885-0.422-5.975-0.721-8.88c-0.117-1.144-0.272-2.252-0.447-3.35c-0.033-0.218-0.07-0.434-0.106-0.646
				c-0.178-1.048-0.373-2.081-0.595-3.093c-0.002-0.009-0.003-0.021-0.005-0.027c-0.845-3.503-1.855-6.873-2.957-10.196
				c-0.001-0.002-0.001-0.004-0.003-0.006c-1.209-3.456-2.537-6.804-3.813-10.17c-0.071-0.188-0.141-0.376-0.212-0.563
				c-0.193-0.515-0.385-1.025-0.574-1.539c-0.132-0.357-0.26-0.718-0.39-1.076c-0.14-0.388-0.281-0.775-0.417-1.165
				c-0.166-0.472-0.325-0.947-0.486-1.423c-0.099-0.293-0.201-0.584-0.297-0.879c-0.255-0.776-0.502-1.56-0.737-2.348
				c-0.992-3.318-1.814-6.804-2.64-11.278c-0.541-2.933-1.704-9.936,0-12c2.478-3.007,10.593-0.354,14.399-2.4
				c-0.049-2.591,0.262-5.541-0.719-7.2c-3.305-0.647-11.198,1.271-12.722-1.438c-1.194-2.126,1.163-7.394,1.2-9.841
				c0.016-1.021,0.008-2.062-0.007-3.106c-0.004-0.312-0.009-0.625-0.016-0.938c-0.017-0.813-0.04-1.629-0.067-2.452
				c-0.032-0.938-0.068-1.884-0.107-2.834c-0.003-0.062-0.005-0.119-0.007-0.181c-0.121-1.465-0.229-2.938-0.275-4.408
				c-0.041-1.295-0.045-2.583-0.035-3.867c-0.012-0.485-0.016-0.974-0.024-1.458c-0.011-0.671-0.02-1.342-0.021-2.017
				c-0.001-0.469,0.003-0.937,0.007-1.404c0.008-0.746,0.024-1.493,0.048-2.239c0.013-0.396,0.023-0.793,0.041-1.19
				c0.052-1.144,0.119-2.285,0.223-3.424c0.912-10.039,3.161-18.7,4.08-26.641c-11.829,0.564-23.201,5.544-36.24,4.08
				c-3.816-0.43-7.445-1.61-11.52-1.92c-9.341-0.709-17.859,1.683-25.92,3.601c-8.101,1.927-15.771,4.128-23.04,6.479
				c-0.768,2.104-1.56,4.189-2.373,6.26c-0.392,0.997-0.803,1.979-1.204,2.97c-0.429,1.058-0.849,2.122-1.287,3.173
				c-0.669,1.605-1.355,3.199-2.042,4.791c-0.18,0.418-0.354,0.839-0.536,1.256c-0.87,2.001-1.753,3.994-2.643,5.983
				c-0.16,0.416-0.309,0.834-0.475,1.248c-1.12,2.794-2.441,5.519-3.815,8.237c-0.794,1.743-1.586,3.494-2.378,5.246
				c-0.408,0.9-0.815,1.803-1.222,2.705c-1.056,2.353-2.107,4.709-3.145,7.09c-2.786,6.396-4.78,14.688-5.52,22.562
				c-0.052,0.556-0.104,1.136-0.157,1.722c-0.021,0.237-0.042,0.477-0.063,0.717c-0.03,0.346-0.061,0.696-0.091,1.051
				c-0.053,0.615-0.105,1.239-0.159,1.873c-0.002,0.031-0.005,0.063-0.008,0.096c-0.114,3.14-0.237,6.354-0.722,9.664
				c-0.058,0.392-0.097,0.778-0.143,1.169c0,0.005-0.001,0.009-0.001,0.014c-0.032,0.407-0.065,0.823-0.096,1.218
				c-0.927,11.621,2.516,19.403,5.52,27.602c1.354,11.712,9.717,20.876,16.32,28.32c0.652,0.455,1.646,0.151,2.16,1.197
				c0.564,1.15-0.074,4.604,0,6.961c0.208,6.635,1.599,14.797,1.44,20.159c-0.204-0.044-0.226,0.095-0.24,0.24c0,0.08,0,0.16,0,0.24
				c0.158,0.388,0.309,0.785,0.454,1.192c0.05,0.143,0.096,0.289,0.145,0.434c0.093,0.271,0.187,0.543,0.274,0.822
				c0.05,0.16,0.096,0.33,0.145,0.492c0.081,0.272,0.163,0.541,0.239,0.82c0.047,0.171,0.09,0.349,0.135,0.521
				c0.073,0.279,0.148,0.559,0.217,0.845c0.042,0.176,0.081,0.354,0.122,0.533c0.067,0.294,0.136,0.585,0.2,0.885
				c0.037,0.172,0.07,0.348,0.105,0.521c0.064,0.313,0.129,0.627,0.189,0.946c0.031,0.163,0.059,0.331,0.088,0.495
				c0.061,0.337,0.122,0.673,0.179,1.015c0.026,0.157,0.049,0.317,0.075,0.477c0.057,0.356,0.115,0.716,0.168,1.078
				c0.021,0.143,0.04,0.286,0.06,0.43c0.055,0.387,0.11,0.772,0.161,1.168c0.016,0.118,0.029,0.237,0.044,0.354
				c0.053,0.422,0.106,0.845,0.155,1.271c0.012,0.102,0.022,0.205,0.034,0.308c0.051,0.451,0.101,0.904,0.148,1.361
				c0.006,0.063,0.012,0.124,0.018,0.185c0.051,0.504,0.101,1.01,0.147,1.519c0.002,0.018,0.003,0.035,0.005,0.052
				c1.005,11.001,1.07,23.414,2.62,32.945c0.001,0.005,0.002,0.011,0.002,0.016c0.292,1.073,0.621,2.09,0.989,3.039
				c0.001,0.003,0.003,0.005,0.004,0.007c0.159,0.313,0.33,0.625,0.507,0.937c0.042,0.07,0.084,0.144,0.127,0.216
				c0.14,0.239,0.281,0.473,0.424,0.702c0.051,0.082,0.103,0.164,0.154,0.245c0.139,0.219,0.274,0.426,0.408,0.628
				c0.045,0.065,0.091,0.139,0.135,0.204c0.17,0.252,0.333,0.488,0.481,0.7c36.88,0,73.761,0,110.64,0
				c0.122-3.959,0.73-7.429,1.438-10.802c-4.784-0.817-11.194-0.009-13.677-3.126c-0.104-12.273,1.2-29.335,1.2-42.96
				c0-7.068-1.042-15.135,1.681-19.2c2.721-4.063,9.076-2.822,12.96-6.479C310.391,639.112,309.928,635.049,310.08,631.601z"/>
					<path fill="#4F83B3" d="M157.92,682.481c3.192,0.088,5.566,0.991,8.16,1.68c0.035-0.253,0.042-0.479,0.032-0.688
				c-0.001-0.028-0.008-0.057-0.01-0.086c-0.041-0.552-0.189-0.997-0.247-1.529c0-0.006-0.001-0.009-0.002-0.013
				c-0.025-0.038-0.045-0.078-0.072-0.114c-2.968-1.407-5.849-3.063-8.763-4.698c-5.21-2.922-10.528-5.767-16.644-6.983
				c-0.044-0.01-0.088-0.021-0.133-0.029c0.124,0.109,0.246,0.22,0.373,0.33c0.047,0.036,0.095,0.072,0.143,0.109
				c1.454,1.123,2.934,2.222,4.455,3.277c0.239,0.166,0.477,0.334,0.718,0.497c0.384,0.261,0.774,0.515,1.165,0.769
				c0.269,0.175,0.539,0.349,0.812,0.521c0.391,0.244,0.787,0.486,1.186,0.727c0.282,0.169,0.568,0.333,0.854,0.498
				c0.405,0.232,0.811,0.465,1.225,0.689c0.288,0.153,0.583,0.304,0.874,0.453c1.079,0.561,2.182,1.096,3.331,1.583c0,0,0,0,0.001,0
				c0.402,0.115,0.782,0.268,1.139,0.453c0.544,0.215,1.083,0.438,1.645,0.636C158.833,681.459,157.369,681.802,157.92,682.481z"/>
					<path fill="#4F83B3" d="M468.001,506.321c-0.333,0.547-1.671,0.089-2.4,0.237c-0.078,0-0.158,0-0.238,0c-0.399,0-0.802,0-1.199,0
				c-1.64,0.44-3.963,0.198-5.521,0.723c-0.243,14.903-0.368,35.817,0.24,50.397c0.085,2.039-0.589,5.498,0,7.44
				c0.05,0.163,0.14,0.34,0.247,0.521c0.011,0.021,0.021,0.038,0.033,0.06c0.115,0.188,0.257,0.384,0.421,0.586
				c0.858,0.844,1.736,1.693,2.635,2.553c0.017,0.012,0.029,0.024,0.044,0.037c0.219,0.185,0.435,0.364,0.645,0.54
				c0.029,0.025,0.063,0.052,0.093,0.077c0.2,0.166,0.395,0.327,0.579,0.479c0.188,0.156,0.364,0.305,0.528,0.441
				c0.03,0.027,0.063,0.053,0.094,0.079c0.163,0.139,0.313,0.271,0.441,0.386c2.175,1.966,4.072,3.581,6,5.521
				c2.149,2.091,3.642,4.84,6,6.72c0-0.08,0-0.16,0-0.24c0.537-0.104,0.1-1.183,0.24-1.68c0-1.92,0-3.841,0-5.762
				c0-0.158,0-0.318,0-0.479c-0.557-1.124-0.083-3.277-0.24-4.8c0-0.08,0-0.16,0-0.24c-0.558-1.682-0.081-4.398-0.238-6.479
				c0-1.763,0-3.521,0-5.28c0.004-0.117,0.009-0.236,0.013-0.354c0.001-0.021,0.001-0.043,0.002-0.063
				c0.004-0.119,0.006-0.235,0.005-0.351c0-0.013,0-0.023,0-0.038c-0.003-0.231-0.021-0.45-0.074-0.633
				c-0.005-0.015-0.012-0.024-0.017-0.04c-0.108-0.175-0.239-0.327-0.408-0.439c-0.08,0-0.16,0-0.24,0
				c-1.656-0.502-2.664-1.656-4.078-2.4c-0.239-0.137-0.473-0.287-0.695-0.45c-0.077-0.055-0.147-0.12-0.225-0.179
				c-0.145-0.112-0.289-0.224-0.43-0.347c-0.077-0.068-0.146-0.146-0.223-0.22c-0.13-0.124-0.262-0.244-0.387-0.38
				c-0.069-0.074-0.137-0.158-0.203-0.235c-0.122-0.142-0.245-0.278-0.362-0.429c-0.063-0.078-0.118-0.165-0.178-0.246
				c-0.116-0.156-0.234-0.313-0.347-0.479c-0.052-0.078-0.099-0.162-0.146-0.242c-0.112-0.178-0.227-0.354-0.33-0.542
				c-0.042-0.073-0.079-0.149-0.119-0.226c-0.106-0.201-0.217-0.402-0.316-0.612c-0.029-0.063-0.06-0.132-0.09-0.197
				c-0.104-0.226-0.207-0.452-0.303-0.687c-0.021-0.06-0.044-0.118-0.066-0.177c-0.099-0.247-0.191-0.496-0.28-0.753
				c-0.018-0.047-0.029-0.096-0.046-0.144c-0.092-0.271-0.181-0.545-0.261-0.824c-0.01-0.03-0.018-0.064-0.024-0.099
				c-0.083-0.296-0.162-0.596-0.234-0.9c-0.005-0.021-0.009-0.045-0.015-0.065c-0.073-0.315-0.143-0.636-0.201-0.958
				c-0.003-0.015-0.004-0.025-0.007-0.037c-0.063-0.334-0.117-0.67-0.165-1.009c-0.002-0.009-0.002-0.017-0.003-0.021
				c-0.049-0.345-0.091-0.689-0.126-1.041c0-0.005,0-0.009-0.001-0.014c-0.035-0.354-0.063-0.705-0.083-1.063
				c0-0.001,0-0.003,0-0.005c-0.021-0.354-0.034-0.714-0.041-1.072c0-0.002,0-0.002,0-0.003c-0.002-0.085,0-0.172-0.002-0.258
				c-0.002-0.343,0.001-0.685,0.012-1.025c0.006-0.205,0.01-0.41,0.021-0.619c0.021-0.409,0.057-0.815,0.098-1.223
				c0.009-0.088,0.011-0.174,0.021-0.263c0.012-0.091,0.028-0.177,0.04-0.268c0-0.001,0-0.003,0.001-0.004v-0.001
				c0.13-1.07,0.325-2.116,0.588-3.117c0.003-0.006,0.005-0.014,0.007-0.02c0.129-0.487,0.271-0.968,0.434-1.432
				c0.01-0.029,0.021-0.06,0.031-0.087c0.152-0.441,0.32-0.87,0.506-1.283c0.009-0.021,0.018-0.043,0.027-0.063
				c0.184-0.409,0.383-0.804,0.595-1.181c0.035-0.063,0.071-0.123,0.105-0.185c0.203-0.35,0.415-0.685,0.645-0.997
				c0.017-0.023,0.031-0.048,0.048-0.07c0.235-0.321,0.488-0.619,0.75-0.899c0.06-0.064,0.12-0.129,0.182-0.188
				c0.271-0.275,0.55-0.536,0.846-0.767c1.78-2.585,5.259-2.722,4.08-8.643c0-0.56,0-1.12,0-1.68c-0.08,0-0.16,0-0.24,0
				c0-0.16,0-0.32,0-0.48c-0.399-1.683-0.399-5.034,0-6.72c0-0.398,0-0.8,0-1.2c-0.536-0.103-0.099-1.183-0.239-1.68
				c-0.721,0-1.44,0-2.16,0c-1.752-0.244-2.593,0.638-3.84,0.24C468.228,506.228,468.205,506.365,468.001,506.321z"/>
					<path fill="#4F83B3" d="M474.963,529.367c0-0.08,0-0.16,0-0.24c-0.002-1.126-0.002-2.246-0.002-3.366c0-0.08,0-0.16,0-0.239
				c-2.124,1.315-3.284,3.598-4.32,6c-2.821,4.436-2.379,13.974,0.96,17.521c0-0.08,0-0.16,0-0.24c0.08,0,0.16,0,0.24,0
				c0.929,1.791,2.655,2.784,4.32,3.84c0-0.317,0-0.64,0-0.96c0-0.08,0-0.158,0-0.237c-0.668-0.293-0.446-1.477-0.48-2.4
				c0-0.08,0-0.16,0-0.24c-0.559-2.721-0.081-6.479-0.238-9.6c0-0.16,0-0.32,0-0.48c-0.559-1.202-0.082-3.438-0.239-5.039
				c0-0.079,0-0.159,0-0.239C474.646,532.561,475.119,530.65,474.963,529.367z"/>
					<path fill="#4F83B3" d="M475.201,593.201c0.503-1.656,0.497-3.822,0.96-5.521c-0.146-0.014-0.284-0.035-0.24-0.237
				c-2.532-2.43-4.164-5.759-6.96-7.92c-4.012-4.551-8.97-8.15-12.96-12.723c-0.009-0.467-0.013-0.938-0.021-1.402
				c-0.508-2.285-0.315-5.732-0.141-8.976c-0.055-3.905-0.101-7.817-0.138-11.741c-0.002-0.264-0.005-0.525-0.007-0.791
				c-0.035-3.751-0.065-7.506-0.087-11.27c-0.007-0.765-0.01-1.527-0.014-2.291c-0.018-3.313-0.034-6.622-0.046-9.94
				c-0.014-4.206-0.022-8.415-0.031-12.629c-1.229-0.408-3.751,0.399-5.278-0.24c-1.089,4.813-0.722,10.479-0.722,16.08
				c0,16.978-0.469,33.931,0,50.4c6.567,4.553,11.936,10.307,17.763,15.6c0.146,0.069,0.3,0.158,0.452,0.263
				c0.018,0.012,0.034,0.025,0.054,0.039c0.137,0.096,0.272,0.196,0.414,0.313c0,0.001,0.001,0.001,0.002,0.002
				c0.002,0.002,0.005,0.004,0.007,0.007c0.218,0.195,0.426,0.396,0.641,0.597c-0.052-0.055-0.104-0.107-0.155-0.158
				c0.002,0.002,0.004,0.003,0.005,0.005c0.399,0.368,0.795,0.743,1.188,1.117c-0.336-0.316-0.674-0.63-1.014-0.94
				c-0.008-0.008-0.016-0.016-0.024-0.022c1.152,1.148,2.357,2.763,3.488,3.898c0.007,0.007,0.014,0.016,0.021,0.021
				c0.294,0.295,0.583,0.558,0.863,0.771c0.021,0.016,0.039,0.026,0.061,0.041c0.108,0.081,0.221,0.153,0.329,0.218
				c0.038,0.023,0.075,0.045,0.112,0.063c0.096,0.051,0.188,0.093,0.277,0.129c0.037,0.014,0.074,0.03,0.111,0.042
				c0.123,0.04,0.245,0.069,0.362,0.078c-0.058-0.004,0.104-0.38,0.24-0.24c0-0.08,0-0.16,0-0.24c0-0.317,0-0.64,0-0.96
				c0-0.078,0-0.159,0-0.237C475.389,594.509,474.532,593.093,475.201,593.201z"/>
					<path fill="#4F83B3" d="M401.041,496.961c2.381,9.618,2.234,21.765,8.88,27.12c0.379,0.929,0.016,2.359-0.479,3.12
				c1.091,0.989,2.626,1.534,3.84,2.397c7.296-5.968,11.913-14.476,18.239-21.357c4.723-5.14,9.92-9.791,14.399-15.36
				c2.226-2.766,4.038-5.556,6.96-7.68c2.709-1.451,4.481-3.839,7.439-5.04c0.08,0,0.158,0,0.238,0
				c1.225-0.621,4.254-0.702,5.762-0.24c0.08,0,0.158,0,0.238,0c1.184-0.062,2.027,0.213,2.16,1.2
				c0.281-0.039,0.202-0.438,0.24-0.721c0-0.08,0-0.159,0-0.239c-2.04-3.241-1.553-10.287-1.2-15.12
				c-0.067-2.092-0.513-3.808-0.72-5.761c-0.021,2.689-0.676-1.661-0.721-2.642c0-0.08,0-0.158,0-0.238
				c-0.78-3.142-1.189-6.649-1.682-10.08c0-0.08,0-0.159,0-0.239c-0.063-0.012-0.105-0.041-0.145-0.078
				c-0.011-0.011-0.02-0.021-0.026-0.036c-0.022-0.033-0.044-0.07-0.058-0.115c-0.004-0.012-0.007-0.022-0.01-0.035
				c-0.066-0.275,0.013-0.694-0.066-0.956c-0.238-0.018-0.48-0.018-0.729,0.008c-2.875,1.104-5.386,2.575-8.087,3.854
				c-0.08,0-0.16,0-0.238,0c-0.621,1.172-3.436,1.05-2.882,2.642c-0.224-0.178-0.361-0.438-0.479-0.722
				c-13.707,6.693-30.697,10.104-48.48,12.722c-0.079,0-0.158,0-0.238,0c-0.029,0.528-1.02,0.103-1.439,0.238
				c-0.159,2.161,0.318,4.958-0.24,6.722c0.145,0.767-0.448,0.743,0,1.2c-0.005,4.396,0.044,8.844-0.72,12.479c0,0.08,0,0.16,0,0.24
				c-0.057,4.296,0.009,8.471,0.24,12.479C401.041,496.801,401.041,496.881,401.041,496.961z"/>
					<path fill="#4F83B3" d="M155.76,589.841c-2.963-23.345,4.634-44.206,10.56-62.16c-0.958-0.854-1.881-1.741-2.771-2.665
				c-0.263-0.271-0.504-0.563-0.761-0.843c-0.615-0.664-1.234-1.324-1.819-2.019c-0.264-0.313-0.508-0.646-0.767-0.966
				c-0.554-0.686-1.112-1.363-1.642-2.072c-0.227-0.304-0.438-0.623-0.66-0.934c-0.541-0.747-1.083-1.493-1.601-2.267
				c-0.17-0.255-0.33-0.521-0.498-0.777c-0.553-0.846-1.104-1.691-1.633-2.563c-0.108-0.176-0.208-0.359-0.315-0.537
				c-0.581-0.967-1.155-1.939-1.711-2.931c-0.021-0.039-0.042-0.079-0.064-0.118c-6.405-11.435-11.352-24.323-18.059-35.453
				c-0.009-0.015-0.017-0.03-0.026-0.045c-0.134-0.135-0.271-0.267-0.417-0.379c-0.853,1.122-0.755,3.193-1.258,4.666
				c-0.011,0.029-0.018,0.063-0.029,0.096c-0.08,0.218-0.174,0.421-0.289,0.604c0.009,1.196,2.446,1.226,1.44,2.641
				c-0.081,1.644-2.032-0.049-2.4,0.479c-2.709,13.966-6.522,31.734-4.32,49.2c0.482,3.824,1.535,9.321,0.48,12
				c0,0.08,0,0.16,0,0.24c-1.324,10.146-2.295,20.86-3.36,30.96c0,0.08,0,0.16,0,0.24c-0.037,0.679-0.092,1.367-0.165,2.063
				c-0.021,0.205-0.054,0.416-0.079,0.622c-0.058,0.497-0.115,0.993-0.189,1.499c-0.026,0.179-0.06,0.36-0.088,0.539
				c-0.084,0.542-0.169,1.084-0.268,1.635c-0.014,0.078-0.031,0.158-0.045,0.236c-1.722,9.394-5.436,19.979-6.344,29.923
				c-0.002,0.025-0.006,0.053-0.009,0.078c-0.042,0.856-0.095,1.688-0.167,2.477c0,0.003,0,0.004,0,0.006
				c-0.019,0.302-0.04,0.599-0.065,0.892c-0.009,0.107-0.021,0.213-0.032,0.319c-0.022,0.223-0.048,0.439-0.079,0.654
				c-0.015,0.106-0.03,0.215-0.047,0.317c-0.043,0.263-0.095,0.519-0.158,0.764c-0.01,0.038-0.016,0.078-0.026,0.115
				c-0.074,0.274-0.167,0.532-0.272,0.779c-0.031,0.071-0.067,0.139-0.101,0.208c-0.088,0.181-0.188,0.352-0.298,0.513
				c-0.044,0.064-0.087,0.13-0.135,0.191c-0.163,0.207-0.34,0.399-0.553,0.565c-0.599,3.521,0.269,8.654-0.24,12.48
				c-0.121,0.906-0.621,1.659-0.72,2.398c-0.356,2.655,0.191,5.494,0,8.159c-0.38,5.306-2.813,10.211-3.12,15.603
				c-0.246,4.325-0.123,8.546-0.48,12.72c-0.322,3.762-1.598,7.351-1.92,11.04c-0.264,3.024,0.242,5.926,0,8.88
				c-0.639,7.785-1.744,15.87-4.32,22.32c-1.574,3.939-4.642,6.905-5.04,11.04c21.7-0.461,44.169-0.151,66.24-0.24
				c1.643-10.743,1.327-23.949,1.2-35.76c-8.193-2.153-19.632-3.361-31.92-3.36c-4.185,0.001-13.204,0.016-8.64-4.561
				c1.807-0.526,0.919,1.642,2.16,1.681c8.757,0.442,18.447-0.047,26.64,0.96c-3.666-1.296-7.159-3.491-10.32-5.76
				c-0.288-0.207-0.59-0.413-0.896-0.618c-0.076-0.053-0.151-0.104-0.228-0.154c-0.066-0.044-0.134-0.089-0.2-0.134
				c-2.27-1.507-4.779-3.088-5.87-5.444v-0.001c0-0.001-0.001-0.001-0.001-0.002c-0.013-0.024-0.025-0.052-0.037-0.078
				c-0.207-0.465-0.364-0.956-0.448-1.486c2.54-2.352,6.175-0.646,8.64,0.239c5.467,1.966,9.814,4.23,15.36,7.198
				c1.81,0.97,5.543,4.194,7.44,3.842c2.057-0.385,3.103-6.325,3.84-8.882c2.819-9.771,4.537-20.909,7.44-30.238
				c-5.828-5.871-11.792-12.914-15.12-22.08C160.903,609.432,157.122,600.566,155.76,589.841z M156.72,521.921
				c2.126,0.466,4.08,3.284,2.88,5.521C156.733,527.755,154.609,523.549,156.72,521.921z M135.84,490.48
				c-2.289,0.272-3.877-3.293-1.92-4.319C135.807,486.29,136.834,488.963,135.84,490.48z M139.2,500.801
				c2.118-0.798,3.178,1.216,3.36,3.12C140.708,505.413,138.383,502.743,139.2,500.801z M148.8,515.921
				c-1.828-0.323-3.896-2.453-2.64-4.32C148.293,511.65,150.876,514.645,148.8,515.921z"/>
					<path fill="#4F83B3" d="M387.12,509.681c0-0.08,0-0.158,0-0.238c-0.746-5.813-1.946-11.175-2.399-17.279
				c-17.029,0.631-29.939-2.859-42.96-6.24c-0.08,0-0.16,0-0.238,0c-9.589-1.04-20.618-0.196-30,0.479
				c-0.317,12.964-1.549,25.014-2.4,37.438c0,0.08,0,0.16,0,0.24c-0.298,5.462-0.626,10.894-1.439,15.84c0,0.08,0,0.16,0,0.24
				c0.021,5.338-0.117,10.839,0.24,15.84c0,0.08,0,0.16,0,0.24c1.663,18.177,7.134,32.546,11.76,47.76
				c3.544-0.838,11.411-0.806,12.722,2.88c-4.078-0.081-7.858-0.461-11.762-0.72c-0.235,1.938,2.178,2.076,3.359,2.64
				c3.63,1.729,7.52,4.035,11.761,5.762c5.464,2.223,12.132,3.386,13.439,9.358c-0.083,0.005-0.159-0.002-0.239-0.001v0.001
				c-0.001,0-0.003-0.001-0.004-0.001c-2.486,0.021-3.871-2.093-5.733-3.604c-0.007-0.006-0.014-0.014-0.021-0.019
				c-0.119-0.096-0.241-0.187-0.363-0.274c-0.033-0.024-0.063-0.053-0.099-0.074c-0.113-0.081-0.231-0.156-0.353-0.23
				c-0.043-0.025-0.084-0.057-0.127-0.083c-0.163-0.098-0.33-0.188-0.503-0.271c-3.267-1.572-7.05-2.528-10.561-4.08
				c-0.564-0.249-1.115-0.505-1.669-0.76c-0.002-0.001-0.004-0.001-0.006-0.002c-0.123-0.057-0.242-0.111-0.364-0.166
				c-1.291-0.6-2.559-1.207-3.802-1.827c-0.147-0.073-0.295-0.147-0.441-0.223c-1.201-0.602-2.39-1.209-3.558-1.822
				c0.092,4.708,0.542,11.138,0.479,16.8c3.445-1.473,12.52,1.201,17.04,2.64c2.199,0.699,4.572,1.564,6.72,2.4
				c1.969,0.765,5.496,1.328,4.562,4.08c-4.307-0.398-8.136-2.754-12.479-4.08c-4.443-1.357-9.019-1.969-13.681-3.12
				c-0.906-0.224-1.49-0.257-2.159-0.96c-0.234,4.725-0.295,9.626-0.24,14.64c0.421,0.061,0.846,0.104,1.271,0.142
				c0.141,0.012,0.282,0.018,0.423,0.025c0.291,0.021,0.583,0.039,0.876,0.05c0.158,0.008,0.316,0.01,0.477,0.013
				c0.283,0.007,0.567,0.012,0.854,0.012c0.151-0.001,0.304-0.003,0.456-0.006c0.304-0.004,0.607-0.01,0.912-0.021
				c0.128-0.006,0.257-0.011,0.386-0.017c0.354-0.016,0.703-0.033,1.056-0.055c0.071-0.005,0.146-0.009,0.217-0.016
				c4.335-0.28,8.714-0.98,12.53-0.784c0.003,0,0.008,0,0.011,0c0.001,0,0.001,0,0.002,0c0.32-0.02,0.628-0.006,0.897,0.063
				c0.336,0.031,0.669,0.066,0.994,0.115c0.029,0.282,0.026,0.541,0.023,0.8c0,0.001,0.001,0.001,0.001,0.002
				c0.23,0.674,0.024,1.351-0.373,1.957c-0.001,0-0.001,0.001-0.001,0.001c0,0.001,0,0.002-0.001,0.002
				c-0.069,0.163-0.155,0.313-0.242,0.461c-0.041,0.071-0.079,0.146-0.124,0.211c-0.092,0.142-0.193,0.271-0.301,0.399
				c-0.055,0.063-0.107,0.125-0.164,0.186c-0.115,0.124-0.235,0.244-0.365,0.356c-0.055,0.048-0.114,0.092-0.172,0.138
				c-0.483,0.384-1.046,0.703-1.677,0.963c-0.05,0.021-0.095,0.042-0.143,0.063c-0.187,0.071-0.38,0.139-0.576,0.202
				c-0.089,0.028-0.178,0.06-0.269,0.088c-0.19,0.057-0.384,0.108-0.581,0.159c-0.104,0.025-0.21,0.053-0.316,0.075
				c-0.195,0.045-0.396,0.091-0.6,0.129c-0.105,0.021-0.214,0.038-0.32,0.059c-0.432,0.074-0.872,0.141-1.322,0.191
				c-0.073,0.01-0.146,0.021-0.22,0.027c-0.188,0.021-0.385,0.037-0.578,0.055c-0.157,0.017-0.315,0.029-0.479,0.042
				c-0.182,0.016-0.363,0.026-0.545,0.038c-0.186,0.014-0.367,0.022-0.551,0.032c-0.166,0.008-0.331,0.018-0.498,0.023
				c-0.215,0.01-0.432,0.018-0.646,0.022c-0.136,0.004-0.271,0.009-0.406,0.014c-0.348,0.011-0.693,0.019-1.037,0.022
				c0.154-0.173,0.327-0.329,0.511-0.478c-0.011-0.002-0.021-0.002-0.03-0.004c0.037-0.021,0.079-0.039,0.117-0.061
				c1.537-1.191,3.879-1.578,6.018-2.168c0.329-0.091,0.651-0.188,0.968-0.291c0.072-0.024,0.145-0.05,0.217-0.075
				c0.344-0.12,0.677-0.25,0.992-0.397c0.035-0.019,0.069-0.036,0.105-0.056c0.335-0.164,0.653-0.343,0.942-0.553
				c-5.368-0.174-12.438,1.281-18.443,0.482c-0.012,0-0.025,0-0.037,0c-0.399,0.559-0.251,1.668-0.211,2.665
				c0.002,0.042,0.005,0.085,0.006,0.128c0.003,0.088-0.002,0.166-0.002,0.252c0,0.113,0.003,0.232-0.004,0.342
				c-0.003,0.04-0.014,0.072-0.017,0.108c-0.032,0.326-0.102,0.615-0.252,0.82c0,0.24,0,0.48,0,0.721c0,4.802,0,9.602,0,14.399
				c1.331,1.01,8.788-1.196,9.12,1.2c0.264,1.898-6.424,2.146-8.882,1.68c0.236,3.283,1.823,5.217,1.439,9.12c0,0.08,0,0.16,0,0.24
				c0.156,0.478,0.29,0.953,0.411,1.426c0.043,0.167,0.078,0.332,0.117,0.498c0.069,0.304,0.139,0.606,0.195,0.91
				c0.039,0.202,0.074,0.403,0.108,0.605c0.043,0.258,0.081,0.516,0.115,0.771c0.029,0.227,0.062,0.454,0.086,0.681
				c0.021,0.211,0.039,0.421,0.057,0.632c0.127,1.506,0.131,3.003,0.052,4.506c0.031,0.353,0.058,0.692,0.059,1.012
				c0.012,2.692-0.561,5.396-1.085,8.113c-0.001,0.003-0.001,0.005-0.001,0.008c-0.118,0.903-0.231,1.817-0.33,2.749
				c-0.009,0.082-0.019,0.166-0.025,0.248c-0.096,0.921-0.18,1.852-0.238,2.802c-0.188,3.059,0.323,6.608,0.722,9.84
				c0.323,2.646,1.284,5.297,0.96,7.2c-0.154,0.91-1.048,1.328-1.2,2.16c-0.231,1.268,0.78,1.826,0.479,3.119
				c19.741,0.658,40.955-0.154,60.722,0.479c2.976-7.766,0.705-21.986,1.681-30.96c-1.298,0.319-2.802,1.517-5.28,2.16
				c-1.768,0.459-5.833,1.137-5.278-1.681c0.306-1.555,2.771-1.008,4.318-1.439c2.434-0.681,4.286-2.414,6.24-3.12
				c0.545-3.443-0.903-6.112-0.479-8.64c-3.497,1.505-7.146,3.452-11.04,5.52c-3.688,1.957-7.228,4.725-11.28,5.28
				c-2.993,0.412-8.111-0.152-7.439-3.84c1.729-0.707,3.073,0.793,4.802,0.96c5.488,0.53,10.441-4.419,14.88-6.48
				c5.339-2.479,10.765-4.074,12.96-8.397c-1,0.192-2.003,0.418-3.007,0.652c-0.004,0.001-0.006,0.001-0.009,0.003
				c-0.994,0.286-2.009,0.533-3.032,0.764c-0.008,0.002-0.015,0.004-0.021,0.006c-1.2,0.322-2.402,0.666-3.609,1.025
				c-0.015,0.005-0.027,0.009-0.042,0.013c-3.851,1.147-7.735,2.453-11.677,3.727c-0.936,0.305-1.873,0.604-2.813,0.898
				c-0.199,0.063-0.398,0.124-0.6,0.187c-1.004,0.313-2.013,0.617-3.021,0.915c-0.239,0.07-0.479,0.144-0.718,0.211
				c-1.096,0.316-2.192,0.62-3.297,0.91c-0.219,0.059-0.438,0.111-0.654,0.168c-1.161,0.298-2.326,0.584-3.499,0.842
				c-3.56,0.781-7.704,2.434-11.521,0.96c0.435-2.278,3.237-2.279,4.801-2.641c9.987-2.3,19.229-4.988,28.319-8.16
				c3.939-1.374,8.242-1.701,11.521-3.119c2.236-0.97,3.669-3.629,6-4.318c4.847-16.595,6.058-36.823,10.561-53.762
				c-0.146-3.814,0.46-7.759,0.721-11.521c0-0.16,0-0.32,0-0.479c0-2,0-4,0-6c0-0.561,0-1.118,0-1.681
				c-0.289-16.534-0.887-32.827,0-48.72c-0.568-0.871-2.521-0.359-2.641-1.682c-0.068-1.828,2.332-1.188,3.119-2.158
				c-7.505-2.575-16.922-3.238-22.08-8.16c7.938,1.104,14.438,3.643,22.32,4.8c0.54-0.74,0.458-2.102,0.72-3.12
				C394.403,530.559,388.198,522.684,387.12,509.681z"/>
					<path fill="#4F83B3" d="M477.121,707.201c0-0.16,0-0.32,0-0.479c0-0.881,0-1.761,0-2.641c-0.388-8.013-2.364-14.438-3.84-21.36
				c0.057-1.017-0.223-1.697-0.48-2.397c-0.498-0.063-0.063-1.057-0.479-1.2c-0.413-0.546-0.515-1.406-0.479-2.4
				c0-0.079,0-0.158,0-0.237c-0.771-3.471-1.229-7.249-1.438-11.28c0-0.08,0-0.16,0-0.24c-0.428-2.454-0.504-5.256-0.479-8.16
				c0-0.16,0-0.317,0-0.479c-0.557-1.203-0.083-3.438-0.24-5.04c0-0.08,0-0.16,0-0.24c-0.554-0.727-0.085-2.476-0.238-3.6
				c0-0.08,0-0.16,0-0.24c-0.353-2.449-0.646-4.956-0.722-7.68c0-0.08,0-0.16,0-0.24c-0.552-0.568-0.086-2.154-0.238-3.12
				c0-0.08,0-0.16,0-0.24c-0.558-1.044-0.083-3.117-0.239-4.56c0-0.08,0-0.16,0-0.24c-0.561-1.603-0.082-4.238-0.24-6.24
				c0-0.08,0-0.158,0-0.238c-0.557-1.045-0.083-3.116-0.24-4.562c0-0.08,0-0.16,0-0.24c0-1.52,0-3.039,0-4.56c0-4.96,0-9.92,0-14.88
				c0.688-3.225-2.591-4.873-4.318-6.48c-1.735-1.611-2.856-3.078-4.319-4.08c-3.003-2.799-5.11-6.794-8.4-9.358
				c-0.139-0.108-0.291-0.2-0.442-0.289c-0.112-0.065-0.229-0.126-0.349-0.187c-0.447-0.135-0.837-0.308-1.177-0.514
				c0,0,0,0-0.001-0.001c-0.016-0.006-0.031-0.013-0.048-0.02c-0.151-0.063-0.302-0.13-0.45-0.196
				c-0.132-0.063-0.261-0.125-0.388-0.193c-0.044-0.021-0.089-0.046-0.132-0.068c-0.156-0.092-0.308-0.192-0.448-0.304
				c-0.033-0.024-0.066-0.053-0.1-0.079c-0.145-0.118-0.276-0.252-0.397-0.398c-0.021-0.026-0.039-0.056-0.06-0.081
				c-0.123-0.165-0.237-0.343-0.329-0.55c-0.238-22.648,0.91-46.438,0-68.16c0.63-7.205,8.896-3.414,14.641-5.04
				c2.08,0,4.16,0,6.24,0c0.397,0,0.8,0,1.199,0c0.072-0.874-0.038-1.563-0.239-2.159c-0.558-1.044-0.083-3.117-0.24-4.561
				c0-0.16,0-0.32,0-0.479c-0.003-0.638,0.091-1.371-0.24-1.681c-0.146-0.016-0.282-0.035-0.238-0.239
				c-0.301-3.66-0.792-9.592,0.721-12.24c-0.208-0.5-1.816,0.876-2.4,0c-0.159,0-0.318,0-0.479,0c-0.88,0-1.76,0-2.642,0
				c-0.638,0.002-1.369-0.091-1.68,0.24c-1.635,0.365-2.38,1.62-3.601,2.399c-5.509,3.729-9.602,9.517-14.16,14.64
				c-3.019,3.39-6.317,6.611-9.359,10.08c-2.771,3.16-5.454,7.277-8.641,10.801c-0.427,0.472-0.876,0.944-1.33,1.419
				c-0.588,0.614-1.182,1.24-1.764,1.884c-0.007,0.009-0.015,0.016-0.021,0.021c-0.527,0.646-1.063,1.295-1.605,1.93
				c-0.003,0.003-0.005,0.005-0.008,0.008c-0.644,0.854-1.222,1.681-1.812,2.442c-0.01,0.014-0.021,0.027-0.03,0.04
				c-0.104,0.135-0.209,0.267-0.313,0.396c-0.03,0.038-0.063,0.076-0.093,0.113c-0.094,0.113-0.188,0.225-0.28,0.333
				c-0.042,0.049-0.083,0.099-0.125,0.146c-0.088,0.1-0.179,0.193-0.27,0.291c-0.051,0.055-0.101,0.107-0.15,0.161
				c-0.085,0.089-0.174,0.174-0.263,0.259c-0.059,0.057-0.114,0.112-0.176,0.169c-0.085,0.079-0.175,0.152-0.263,0.229
				c-0.065,0.059-0.13,0.115-0.197,0.17c-0.086,0.07-0.178,0.139-0.268,0.205c-0.074,0.058-0.146,0.113-0.224,0.167
				c-0.091,0.063-0.186,0.123-0.277,0.186c-0.081,0.053-0.159,0.105-0.243,0.155c-0.099,0.062-0.202,0.113-0.305,0.17
				c-0.086,0.049-0.169,0.098-0.259,0.143c-0.106,0.055-0.225,0.104-0.338,0.155c-0.09,0.041-0.176,0.084-0.269,0.122
				c-0.129,0.054-0.27,0.101-0.404,0.148c-0.084,0.029-0.163,0.063-0.25,0.092c-0.228,0.074-0.463,0.145-0.709,0.205
				c-2.798,13.872-5.761,27.951-5.761,44.16c0,3.584,0.631,7.29,0.721,11.04c0.089,3.74,0.064,7.512,0.239,11.28
				c0.346,7.42,0.254,14.798,0.24,22.08c-0.018,8.557,2.482,16.522,2.641,24.238c0.105,5.308-0.799,11.177-2.16,15.842
				c-0.544,1.861-1.718,3.275-1.92,5.04c-0.417,3.628,1.205,6.495,1.439,9.6c0.657,1.82,1.979,2.979,2.398,5.04
				c5.355,2.547,0.376,13.282,1.2,19.2c0.024,0.175,0.072,0.347,0.127,0.518c0.02,0.062,0.039,0.119,0.062,0.178
				c0.039,0.106,0.088,0.212,0.137,0.316c0.079,0.171,0.167,0.342,0.264,0.512c0.001,0,0.001,0.001,0.001,0.001
				c0.063,0.108,0.129,0.212,0.188,0.323c0.001,0.001,0.001,0.002,0.002,0.003c0.175,0.283,0.36,0.568,0.55,0.855
				c0.074,0.112,0.146,0.229,0.22,0.346c0.066,0.104,0.131,0.213,0.194,0.32c0.088,0.146,0.168,0.297,0.246,0.446
				c0.043,0.083,0.089,0.166,0.129,0.251c0.11,0.237,0.211,0.479,0.283,0.729c1.631,5.608,0.194,12.22,0.96,18.24
				c17.452-0.469,35.692-0.146,53.521-0.24c0.174-0.734-0.414-0.706-0.24-1.44c0.346,0.296,0.455,0.826,0.48,1.44
				c2.64,0,5.279,0,7.92,0c0.157-1.685-0.317-3.997,0.239-5.28c0-0.318,0-0.64,0-0.96c0-1.68,0-3.359,0-5.04c0-0.16,0-0.318,0-0.479
				C476.805,710.557,477.277,708.564,477.121,707.201z M459.841,488.32c0.965-0.538,1.729,0.058,1.439,1.438c0,0.08,0,0.16,0,0.24
				c-0.577,0.382-0.795,1.124-1.439,1.438c-0.398,0-0.8,0-1.2,0c-0.104-0.214-0.211-0.43-0.479-0.479
				C458.06,489.42,458.771,488.691,459.841,488.32z M454.081,495.761c-0.361,2.695-2.31,5.028-5.04,4.562
				C448.906,498.132,451.417,494.368,454.081,495.761z M442.561,506.561c0.548,2.571-1.035,5.024-3.6,5.28
				C438.009,509.473,439.866,506.768,442.561,506.561z M431.761,519.761c0.215,2.714-1.393,5.634-4.318,6
				C426.646,523.383,429.032,519.36,431.761,519.761z M421.681,533.921c1.178,1.995-0.414,4.896-2.88,4.8
				C417.625,536.726,419.215,533.824,421.681,533.921z M439.921,692.081c-6.286,1.069-15.743,1.07-22.32,0.479
				c-0.601,0.675,0.771,3.101-0.72,3.359c-1.974-0.586-1.868-3.252-2.641-5.04c4.053-1.101,9.504-0.014,14.641-0.239
				c0.721-0.03,1.438-0.075,2.151-0.131c0.17-0.013,0.337-0.027,0.507-0.044c0.554-0.045,1.105-0.092,1.654-0.146
				c0.11-0.014,0.222-0.024,0.334-0.036c3.878-0.4,7.54-0.979,10.685-1.256c0.002,0,0.005-0.001,0.007-0.001
				c0.001,0,0.001,0,0.001,0c0.085-0.023,0.177-0.042,0.261-0.066c0.006,0.019,0.016,0.027,0.021,0.041
				c0.151-0.012,0.313-0.03,0.461-0.041C444.891,691.193,441.922,691.741,439.921,692.081z M442.081,687.761
				c-7.351-0.641-15.877,0.874-21.36-1.198c-1.79-0.678-4.644-2.487-5.521-3.602c-0.395-0.498-1.106-0.848-0.479-1.92
				c2.908-0.524,4.219,2.376,6.96,3.36c4.854,1.739,13.461,0.525,19.92,0.96c3.311,0.222,6.389,1.052,8.882,2.397
				c2.437,1.317,5.325,2.269,6.721,4.32C452.25,693.439,448.967,688.361,442.081,687.761z"/>
				</g>
				<g id="hair">
					<path fill="#A4A55D" d="M228.24,165.52c3.369-13.517,11.533-23.432,17.04-34.08c-0.014-0.146-0.036-0.284-0.24-0.24
				c-4.27,1.079-10.856,3.414-12.96,6c-1.678,2.063-1.873,4.864-4.08,6.96c-0.421,0.539-0.115,1.805-0.48,2.4
				c-0.384,0.52-0.75,1.073-1.105,1.645c-0.108,0.174-0.211,0.357-0.317,0.537c-0.247,0.418-0.488,0.846-0.72,1.288
				c-0.108,0.205-0.214,0.411-0.318,0.621c-0.236,0.474-0.463,0.961-0.683,1.459c-0.077,0.173-0.156,0.342-0.231,0.519
				c-0.294,0.692-0.575,1.401-0.839,2.134c-0.003,0.009-0.006,0.02-0.01,0.029c-0.258,0.717-0.499,1.458-0.73,2.21
				c-0.058,0.19-0.112,0.384-0.169,0.576c-0.176,0.6-0.345,1.209-0.504,1.828c-0.053,0.205-0.105,0.41-0.157,0.617
				c-0.17,0.69-0.33,1.391-0.479,2.103c-0.025,0.117-0.052,0.232-0.076,0.35c-0.178,0.868-0.342,1.747-0.49,2.641
				c0,0.003-0.001,0.007-0.001,0.011c-0.212,1.679-0.404,3.47-0.611,5.432c-0.208,1.97-0.37,3.98-0.475,6.01
				c0,0.003,0,0.006,0,0.009c-0.025,0.811-0.039,1.623-0.044,2.437c0,0.137,0.002,0.274,0.001,0.411
				c-0.001,0.676,0.002,1.354,0.014,2.029c0.004,0.212,0.012,0.424,0.017,0.636c0.015,0.601,0.032,1.201,0.058,1.8
				c0.011,0.252,0.026,0.504,0.039,0.756c0.028,0.558,0.058,1.114,0.095,1.669c0.019,0.277,0.041,0.553,0.062,0.83
				c0.04,0.526,0.082,1.051,0.129,1.573c0.027,0.293,0.057,0.585,0.087,0.877c0.051,0.502,0.104,1.003,0.162,1.501
				c0.036,0.303,0.074,0.604,0.112,0.905c0.061,0.48,0.125,0.958,0.193,1.433c0.044,0.31,0.091,0.618,0.138,0.925
				c0.071,0.461,0.146,0.918,0.224,1.373c0.053,0.311,0.107,0.62,0.164,0.928c0.081,0.444,0.166,0.884,0.254,1.321
				c0.062,0.309,0.124,0.617,0.189,0.922c0.091,0.427,0.186,0.847,0.283,1.266c0.07,0.304,0.141,0.609,0.214,0.909
				c0.101,0.412,0.207,0.815,0.314,1.219c0.078,0.295,0.155,0.592,0.237,0.882c0.111,0.398,0.229,0.787,0.346,1.175
				c0.085,0.282,0.169,0.567,0.257,0.844c0.124,0.388,0.254,0.765,0.384,1.142c0.091,0.263,0.179,0.531,0.272,0.789
				c0.139,0.383,0.285,0.752,0.43,1.123c0.093,0.237,0.182,0.48,0.278,0.712c0.164,0.398,0.335,0.78,0.507,1.162
				c0.084,0.188,0.165,0.385,0.252,0.57c0.261,0.559,0.53,1.1,0.809,1.621C224.539,195.844,224.826,179.219,228.24,165.52z"/>
					<path fill="#A4A55D" d="M218.4,162.4c1.451-10.313,7.584-17.397,10.32-24.72c-1.029,1.851-2.397,3.363-3.12,5.52
				c-5.091,3.243-9.744,7.359-12.72,13.2c-2.485,4.877-2.915,13.234-3.36,20.4c-0.941,15.146-1.114,31.471,1.44,44.64
				c1.346,6.939,4.162,12.796,5.52,18.24c2.575-7.189-3.17-13.356-1.92-19.92c0.203-1.064,1.169-1.814,1.44-2.88
				c0.052-0.206,0.088-0.429,0.125-0.651v-0.001c0.031-0.464,0.058-0.965,0.116-1.507c0.086-0.817,0.245-1.644,0.429-2.474
				c0,0,0,0,0-0.001s0.001-0.003,0.001-0.005c0.584-3.255,1.285-6.264,1.97-9.521c-0.163-1.283,1.44-0.799,1.44-1.92
				C217.153,189.996,216.668,174.709,218.4,162.4z"/>
					<path fill="#A4A55D" d="M367.441,221.439c-0.079,0-0.159,0-0.239,0c-2.87-1.13-4.761-3.24-6.479-5.52c0-0.08,0-0.16,0-0.24
				c-0.077-0.563-0.379-0.901-0.239-1.68c0.192-0.004,0.358,0.021,0.518,0.054c0.193,0.131,0.42,0.231,0.666,0.31
				c0.203,0.161,0.37,0.358,0.497,0.597c1.73,0.489,3.521-0.209,5.521,0.24c0.692,0.268,1.488,0.432,2.399,0.479
				c1.184,0.337,2.385,0.656,3.841,0.72c1.175,0.342,1.076-0.673,0.479-0.96c0-0.08,0-0.16,0-0.24
				c-0.225-0.656-0.735-1.023-0.96-1.68c-0.421-0.066-1.196,0.676-1.438,0c-0.08,0-0.16,0-0.24,0c-0.479,0-0.96,0-1.438,0
				c-0.278-0.201-0.646-0.316-1.2-0.24c-0.06-0.261-0.478-0.163-0.479-0.48c-0.08,0-0.16,0-0.239,0c-4.61-1.95-8.521-4.6-9.841-9.84
				c3.375,2.145,5.877,5.163,9.601,6.96c1.14,0.541,2.104,1.256,3.841,1.2c0,0.08,0,0.16,0,0.24c1.685,0.894,1.341-2.128,1.439-3.6
				c0-0.72,0-1.44,0-2.16c0-0.08,0-0.16,0-0.24c0.079,0,0.159,0,0.238,0c0-0.32,0-0.64,0-0.96c0.15-0.729-0.308-2.067,0.24-2.4
				c0-0.16,0-0.32,0-0.48c0-0.72,0-1.44,0-2.16c0-0.16,0-0.32,0-0.48c0.08,0,0.159,0,0.239,0c0-0.4,0-0.8,0-1.2
				c0.15-0.729-0.308-2.067,0.24-2.4c0-0.16,0-0.32,0-0.48c-1.568-1.632-3.874-2.526-6.479-3.12
				c-0.146-0.333-0.924-0.036-0.96-0.479c-0.449-0.191-0.879-0.401-1.439-0.48c-0.079,0-0.159,0-0.239,0
				c-0.936-0.266-1.736-0.664-2.399-1.2c-0.08,0-0.159,0-0.239,0c-0.093,0.022-0.167,0.028-0.231,0.023
				c-0.009,0-0.015-0.005-0.021-0.006c-0.41-0.055-0.326-0.603-0.893-0.502c0,0,0,0-0.001,0c-0.009-0.009-0.018-0.018-0.025-0.027
				c0,0,0.002,0,0.002-0.001c0.442-0.736,2.013-0.347,3.092-0.447c1.283-0.083,2.295,0.105,3.12,0.48
				c-0.173,0.652,1.133-0.171,0.96,0.48c0.588,0.214,1.041,0.56,1.92,0.48c0.755,0.126,1.34,0.421,1.921,0.72
				c-2.543-6.337-8.655-11.185-12.479-17.04c0.11,0.037,0.217,0.083,0.325,0.122c-0.028-0.041-0.058-0.081-0.086-0.122
				c0.163,0.081,0.313,0.182,0.476,0.27c0.306,0.121,0.604,0.252,0.898,0.394c0.003,0.001,0.007,0.003,0.011,0.005
				c0.29,0.14,0.574,0.289,0.854,0.448c0.01,0.005,0.019,0.01,0.026,0.016c0.275,0.157,0.547,0.322,0.813,0.496
				c0.014,0.009,0.026,0.018,0.041,0.026c0.263,0.172,0.521,0.352,0.773,0.54c0.02,0.013,0.034,0.025,0.053,0.038
				c0.251,0.186,0.499,0.379,0.742,0.58c0.02,0.016,0.037,0.032,0.058,0.047c0.241,0.199,0.479,0.404,0.713,0.616
				c0.021,0.019,0.043,0.039,0.063,0.058c0.23,0.211,0.461,0.427,0.688,0.649c0.021,0.021,0.043,0.043,0.063,0.064
				c0.225,0.222,0.444,0.447,0.664,0.679c0.022,0.026,0.049,0.052,0.071,0.078c0.215,0.229,0.428,0.461,0.638,0.699
				c0.026,0.031,0.055,0.062,0.08,0.093c0.206,0.235,0.411,0.473,0.612,0.716c0.029,0.035,0.06,0.071,0.088,0.106
				c0.198,0.24,0.396,0.482,0.591,0.728c0.031,0.041,0.063,0.082,0.097,0.122c0.189,0.243,0.382,0.486,0.567,0.734
				c0.037,0.048,0.073,0.097,0.109,0.146c0.182,0.241,0.363,0.483,0.543,0.728c0.046,0.063,0.092,0.126,0.138,0.19
				c0.169,0.231,0.338,0.464,0.506,0.697c0.067,0.097,0.138,0.194,0.204,0.291c0.144,0.201,0.285,0.401,0.427,0.603
				c0.212,0.302,0.422,0.604,0.631,0.907c0.646,0.935,2.1,3.541,2.398,2.4c0-0.08,0-0.16,0-0.24
				c-0.156-1.363,0.315-3.355-0.239-4.32c0-0.08,0-0.16,0-0.24c0.063-0.864-0.442-1.157-0.479-1.92
				c0.115-0.755-0.342-0.938-0.479-1.44c-4.366-8.354-9.939-15.5-15.841-22.32c8.679,3.563,12.081,12.398,16.801,19.92
				c0.536-0.104,0.098-1.183,0.239-1.68c-0.148-0.729,0.309-2.067-0.239-2.4c-0.595-1.566-0.208-4.112-0.721-5.76
				c-0.029-1.65-0.406-2.954-0.721-4.32c-0.668,0.108,0.188-1.308-0.479-1.2c0-0.08,0-0.16,0-0.24
				c0.128-0.928-0.43-1.172-0.239-2.16c-0.049-1.632-0.334-3.025-0.722-4.32c-0.668,0.108,0.188-1.308-0.479-1.2c0-0.4,0-0.8,0-1.2
				c0-0.08,0-0.16,0-0.24c-0.542-0.497-0.295-1.785-0.72-2.4c0-0.4,0-0.8,0-1.2c-0.313-4.088-1.331-7.468-2.16-11.04
				c-0.52-0.203-0.202-1.237-0.721-1.44c0.146-1.108-0.539-1.381-0.479-2.4c-0.075-0.325-0.342-0.458-0.239-0.96
				c-0.567-0.392-0.512-1.409-0.96-1.92c-0.091-1.35-1.121-1.759-2.16-2.16c-5.648-10.27-15.481-16.357-27.358-20.4
				c-0.464,0.024,0.372,0.894-0.24,1.2c-5.035-1.02-9.394-0.394-12.479,1.92c-1.651,1.238-2.158,3.38-4.319,3.6
				c-0.748-5.502,5.596-7.979,11.279-8.64c-10.614-5.629-29.632-4.485-43.681-2.64c-2.61,2.201-5.81,5.877-5.28,10.56
				c0.196,1.733,1.52,2.78,1.2,4.56c-6.198-0.967-3.589-11.049-0.96-13.92c-1.224,0.133-2.45,0.285-3.676,0.448
				c-0.167,0.022-0.333,0.043-0.499,0.066c-1.232,0.168-2.461,0.354-3.685,0.556c-0.09,0.015-0.179,0.03-0.268,0.045
				c-2.595,0.436-5.157,0.954-7.646,1.575c-1.32,0.391-2.643,0.745-3.934,1.093c-0.003,0.001-0.006,0.002-0.009,0.003
				c-0.664,0.204-1.319,0.417-1.967,0.639c-0.037,0.012-0.073,0.025-0.11,0.038c-0.615,0.212-1.223,0.432-1.823,0.661
				c-0.08,0.03-0.159,0.062-0.239,0.093c-0.556,0.215-1.106,0.437-1.647,0.667c-0.131,0.056-0.259,0.114-0.389,0.17
				c-0.489,0.213-0.975,0.43-1.45,0.657c-0.184,0.087-0.363,0.18-0.544,0.27c-0.418,0.207-0.835,0.413-1.241,0.63
				c-0.242,0.13-0.476,0.267-0.713,0.401c-0.339,0.191-0.682,0.379-1.011,0.578c-0.318,0.192-0.623,0.395-0.932,0.595
				c-0.241,0.156-0.488,0.306-0.723,0.466c-0.541,0.371-1.067,0.752-1.575,1.149c-6.763,5.282-13.055,10.927-17.28,18.72
				c3.819-2.901,8.679-4.762,13.92-6.24c1.125-0.245,1.365,0.395,1.92,0.72c1.643-1.158,3.52-2.08,6-2.4
				c-0.116-0.878-2.53-2.705-1.44-4.8c2.428,0.691,3.59,2.65,5.52,3.84c3.207-0.633,6.066-1.613,9.36-2.16
				c-2.607-1.053-5.962-4.448-3.84-7.92c2.274,1.566,2.144,5.537,6,5.52c0.742-0.022,0.715,0.725,0.72,1.44
				c2.033-0.606,4.019-1.261,6.24-1.68c-0.636-1.924-0.972-5.756,0.96-6.48c2.009,0.55,0.193,4.926,1.68,6
				c6.679,0.441,13.078,1.164,18.719,2.64c0.545-1.135,0.021-3.339,1.2-3.84c0.078,0.087,0.129,0.178,0.193,0.268
				c0.018-0.007,0.028-0.021,0.047-0.027c0,0.036,0.006,0.065,0.008,0.1v0.001c0.707,1.02,0.672,2.152,1.02,3.142
				c0.006,0.017,0.01,0.035,0.018,0.052c0.053,0.144,0.123,0.282,0.196,0.419c0.021,0.04,0.037,0.083,0.063,0.122
				c0.103,0.168,0.225,0.33,0.377,0.482c2.16,2.157,6.587,2.227,9.12,4.08c-1.415-3.632-0.39-7.927,1.199-10.56
				c0.197,6.595,1.188,13.616,6.96,15.6c0.764-3.078,1.538-6.142,4.562-6.96c0.119,3.561-2.796,5.363-1.681,9.12
				c0.887-0.448,1.158-1.985,1.92-3.12c1.341-2.001,3.195-4.305,6.479-3.84c-0.004,0.083-0.021,0.159-0.031,0.239
				c0.012,0.001,0.021,0,0.031,0.001c-0.012,0.028-0.029,0.05-0.041,0.078c0,0,0,0,0,0.001c-0.001,0.001-0.001,0.002-0.001,0.003
				c-0.313,2.281-2.583,3.754-4.076,5.64c-0.003,0.005-0.007,0.009-0.011,0.014c-0.109,0.138-0.211,0.279-0.312,0.422
				c-0.021,0.031-0.046,0.061-0.064,0.092c-0.085,0.127-0.162,0.258-0.237,0.39c-0.025,0.047-0.059,0.093-0.085,0.141
				c-0.069,0.131-0.131,0.268-0.188,0.404c-0.022,0.05-0.051,0.098-0.07,0.149c-0.075,0.189-0.141,0.384-0.19,0.585
				c-0.27,1.053,0.79,1.95-0.479,2.88c-0.32,0-0.641,0-0.96,0c-0.08,0-0.16,0-0.24,0c-3.163-1.716-5.766-3.994-8.64-6
				c-17.743-0.977-24.476,11.012-34.561,18.48c0.03-0.193,0.083-0.382,0.125-0.573c-0.042,0.031-0.083,0.063-0.125,0.093
				c0.062-0.136,0.128-0.263,0.19-0.396c0,0,0,0,0-0.001s0-0.001,0-0.002c0.082-0.327,0.182-0.65,0.294-0.969
				c0.009-0.026,0.017-0.052,0.026-0.078c0.11-0.306,0.233-0.607,0.367-0.906c0.018-0.04,0.036-0.08,0.054-0.12
				c0.132-0.285,0.272-0.566,0.423-0.843c0.029-0.054,0.059-0.107,0.089-0.161c0.146-0.262,0.297-0.521,0.458-0.776
				c0.044-0.071,0.09-0.14,0.136-0.21c0.152-0.235,0.307-0.468,0.468-0.696c0.068-0.095,0.138-0.188,0.207-0.281
				c0.147-0.2,0.292-0.399,0.444-0.592c0.109-0.139,0.221-0.272,0.332-0.408c0.119-0.145,0.235-0.292,0.355-0.433
				c0.237-0.277,0.476-0.547,0.715-0.809c2.806-3.061,6.727-5.699,10.56-8.16c3.787-2.432,8.09-5.289,13.44-5.04
				c-4.605-2.233-8.712-4.885-15.36-4.08c-8.862,1.073-13.988,9.081-18.96,14.88c-1.738,2.027-3.545,4.189-5.76,5.04
				c1.767-5.268,5.854-9.143,9.36-13.2c2.954-3.418,6.186-6.808,10.56-8.16c-7.88-2.567-14.163,0.404-20.88,2.16
				c-4.005,1.047-8.316,2.79-12.24,3.12c-2.042,0.172-3.778-0.615-5.04,0.96c-0.632,0.788-0.427,1.756-0.96,3.12
				c-1.564,4.001-4.162,7.779-6,12.24c-1.703,4.134-3.166,8.598-4.8,13.2c-3.331,9.379-8.065,19.203-8.88,29.52
				c-0.643,8.136,0.466,15.299,0.48,22.32c0.494-1.004,1.013-1.996,1.545-2.979c0.191-0.354,0.391-0.702,0.587-1.053
				c0.343-0.613,0.692-1.223,1.049-1.827c0.256-0.434,0.516-0.865,0.779-1.294c0.297-0.484,0.603-0.963,0.909-1.441
				c0.316-0.494,0.63-0.989,0.956-1.476c0.155-0.232,0.318-0.458,0.475-0.689c0.934-1.369,1.899-2.71,2.9-4.019
				c0.03-0.039,0.059-0.079,0.088-0.117c0.541-0.704,1.092-1.394,1.65-2.079c0.082-0.1,0.163-0.2,0.245-0.3
				c0.576-0.701,1.159-1.391,1.752-2.071c0,0,0,0,0.001,0v-0.001c0.869-1.025,1.772-2.015,2.713-2.967
				c0.002-0.001,0.003-0.003,0.005-0.005c0.46-0.481,0.915-0.972,1.383-1.441c1.548-1.549,2.333-3.616,5.04-4.08
				c-2.738,4.414-6.49,7.886-9.6,12c-3.079,4.073-5.768,8.333-8.16,13.2c0.7-0.489,1.412-0.981,2.128-1.476
				c0.226-0.156,0.455-0.313,0.683-0.47c0.588-0.404,1.177-0.809,1.772-1.217c-0.007,0.015-0.016,0.028-0.023,0.043
				c0.528-0.342,1.03-0.704,1.522-1.073c0.014-0.01,0.029-0.02,0.043-0.03c1.856-1.275,3.734-2.577,5.604-3.913
				c0.139-0.1,0.277-0.198,0.416-0.298c0.897-0.645,1.793-1.296,2.683-1.959c0.067-0.05,0.134-0.102,0.202-0.152
				c0.813-0.608,1.62-1.226,2.42-1.852c0.156-0.122,0.313-0.243,0.469-0.366c0.835-0.659,1.66-1.328,2.474-2.008
				c0.15-0.125,0.297-0.253,0.446-0.379c0.741-0.626,1.471-1.263,2.188-1.91c0.111-0.1,0.225-0.199,0.335-0.299
				c0.774-0.706,1.529-1.426,2.269-2.158c0.166-0.164,0.329-0.33,0.493-0.495c0.75-0.756,1.487-1.522,2.195-2.31
				c0.938-1.044,2.072-3.411,4.08-2.64c-0.371,3.074-2.833,5.585-4.56,8.16c-1.739,2.593-3.316,5.396-5.52,7.44
				c1.446,0.086,2.154-0.566,3.6-0.48c0.557,0.077,0.921-0.039,1.2-0.24c1.519-0.722,3.261-1.219,5.04-1.68
				c1.333,0.053,1.826-0.734,2.88-0.96c0.066-0.494,0.894-0.226,0.96-0.72c0.08,0,0.16,0,0.24,0c0.597,0.037,0.743-0.377,1.2-0.48
				c0.08,0,0.16,0,0.24,0c6.884-4.155,13.233-8.847,18.96-14.16c0.08,0,0.16,0,0.24,0c2.719-2.961,5.5-5.86,7.439-9.6
				c0-0.08,0-0.16,0-0.24s0-0.16,0-0.24s0-0.16,0-0.24c0.846-0.754,0.863-2.335,1.68-3.12c0-0.08,0-0.16,0-0.24
				c0.346-0.938,0.574-1.987,1.201-2.64c0-0.08,0-0.16,0-0.24c0.395-1.126,0.733-2.305,1.438-3.12
				c0.344-0.857,0.854-1.547,0.96-2.64c0.248-1.032,0.603-1.96,1.2-2.64c0-0.08,0-0.16,0-0.24c-0.03-0.431,0.294-0.506,0.238-0.96
				c0-0.08,0-0.16,0-0.24c0.52-0.203,0.203-1.237,0.722-1.44c0-0.08,0-0.16,0-0.24c0.049-1.312,0.803-1.917,0.96-3.12
				c0-0.08,0-0.16,0-0.24c0.493-0.066,0.227-0.894,0.721-0.96c0-0.08,0-0.16,0-0.24c1.046-1.914,1.191-4.729,4.08-4.8
				c0.719-0.149,2.056,0.309,2.389-0.239c0.574-0.146,1.741,0.301,1.92-0.24c1.269-0.012,2.453-0.107,3.358-0.48
				c0.729-0.15,2.067,0.307,2.4-0.24c0.239,0,0.479,0,0.72,0c0-0.08,0-0.16,0-0.24c0.24,0,0.479,0,0.722,0
				c2.396,0.318,3.946-0.212,4.8-1.44c3.086,0.754,5.251,2.429,8.16,3.36c0.199,0.519,0.621,0.819,0.479,1.68
				c0.425-0.557,0.84-1.123,1.253-1.691c0.003-0.004,0.007-0.008,0.011-0.013c0.627-1.142,1.491-2.256,2.493-3.3
				c0.001,0,0.001,0,0.001-0.001c0.001-0.001,0.002-0.002,0.003-0.003c0.315-0.387,0.64-0.767,0.974-1.135
				c0.641-0.707,1.32-1.371,2.063-1.978c0.077-0.063,0.155-0.124,0.233-0.186c0.298-0.234,0.604-0.458,0.924-0.672
				c0.084-0.056,0.166-0.115,0.251-0.17c0.4-0.256,0.814-0.499,1.251-0.717c0.021-0.011,0.044-0.019,0.065-0.029
				c0.4-0.199,0.826-0.376,1.266-0.54c0.136-0.051,0.277-0.096,0.418-0.143c0.354-0.119,0.719-0.225,1.096-0.319
				c0.152-0.038,0.303-0.078,0.457-0.111c0.525-0.113,1.067-0.21,1.646-0.272c-4.155,4.564-10.278,7.161-12.239,13.92
				c3.292-1.802,10.396-5.985,16.08-3.12c-0.095,0.081-0.212,0.138-0.319,0.206c0.026,0.013,0.055,0.021,0.079,0.034
				c-0.057,0.008-0.111,0.016-0.17,0.024h-0.001c-1.312,0.765-3.404,0.728-5.304,0.995c-0.043,0.006-0.087,0.01-0.13,0.017
				c-0.299,0.045-0.595,0.098-0.876,0.164c-2.222,0.521-4.007,1.464-5.762,2.16c1.259,0.823,4.645-0.483,5.521,0.72
				c-0.071,0.094-0.16,0.16-0.241,0.239c0.001,0,0.002,0.001,0.002,0.001c-0.001,0-0.003,0-0.004,0.001
				c-1.019,0.996-2.759,0.872-4.313,1.072c-0.008,0.001-0.015,0.001-0.021,0.002c-0.24,0.031-0.476,0.071-0.702,0.125
				c-0.39,0.092-0.766,0.213-1.134,0.344c-0.044,0.016-0.089,0.029-0.133,0.045c-0.299,0.11-0.591,0.228-0.88,0.347
				c-0.823,0.339-1.607,0.68-2.382,0.875c-0.161,0.123-0.313,0.26-0.456,0.4c-2.637,4.568,0.011,11.816,1.624,17.188
				c1.212,4.035,1.93,8.462,3.358,11.76c2.279,5.257,8.335,8.799,12,11.52c2.101,1.021,4.151,2.088,6.721,2.64
				c0.893,0.148,1.509,0.572,2.399,0.72c0.501,0.138,0.686,0.595,1.439,0.48c0.239,0.479,1.062,0.379,0.96,1.2
				c-1.504,0.063-2.737-0.143-3.602-0.72c-0.08,0-0.16,0-0.24,0c-1.185-0.015-1.693-0.705-2.88-0.72c-0.08,0-0.159,0-0.238,0
				c-5.533-1.746-10.094-4.468-13.682-8.16c2.005,6.155,6.99,11.41,10.562,16.8c-2.707,11.528,2.726,19.387,3.84,29.28
				c1.196-0.724,0.396-3.445,1.681-4.08c1.184-0.142,1.85,0.232,1.681,1.44c1.26-0.901,1.915-2.404,2.881-3.6
				c-3.201-0.889-5.603-3.826-5.762-6.96c2.768,0.754,3.495,3.545,6,4.56c0.08,0,0.16,0,0.24,0c0.08-0.007,0.142,0.006,0.207,0.013
				c-0.011-0.006-0.022-0.014-0.035-0.021c0.013,0.007,0.024,0.014,0.036,0.021h-0.001c0.003,0.002,0.006,0.003,0.009,0.005
				c0.148,0.086,0.284,0.162,0.409,0.228h0.001c0.034,0.057,0.063,0.122,0.077,0.196c0.02,0.08,0.025,0.17,0.018,0.278
				c0.105-0.088,0.225-0.182,0.335-0.273c0.063,0.022,0.115,0.037,0.146,0.033c0.091-0.012,0.18-0.039,0.268-0.074
				c0.03-0.012,0.062-0.029,0.09-0.043c0.02-0.009,0.035-0.02,0.056-0.029c0.306-0.37,0.719-0.756,1.178-1.145
				c0.021-0.027,0.042-0.053,0.063-0.082c0.076-0.104,0.152-0.211,0.23-0.32c0.002-0.002,0.003-0.004,0.005-0.006
				c-0.002,0.002-0.003,0.003-0.004,0.005c0.008-0.006,0.017-0.013,0.022-0.019c0.155-0.131,0.311-0.264,0.463-0.397
				c0.093-0.081,0.184-0.162,0.273-0.243c0.159-0.146,0.315-0.287,0.47-0.431c0.062-0.059,0.123-0.118,0.184-0.177
				c0.166-0.161,0.322-0.321,0.472-0.481c0.046-0.047,0.091-0.095,0.134-0.142c0.164-0.182,0.314-0.363,0.453-0.542
				c0.027-0.038,0.056-0.076,0.082-0.114c0.14-0.189,0.267-0.376,0.362-0.56c-0.769-0.272-1.592-0.488-2.641-0.48
				C367.923,221.439,367.683,221.439,367.441,221.439z"/>
					<path fill="#A4A55D" d="M235.2,156.64c0.519-1.46,1.676-5.427,1.44-4.8c-3.303,5.896-6.229,13.451-6.96,21.12
				C231.064,168.436,233.424,161.642,235.2,156.64z"/>
					<path fill="#A4A55D" d="M375.602,218.8c-0.159,0-0.319,0-0.479,0c-0.576-0.145-1.097-0.345-1.922-0.24c-0.319,0-0.642,0-0.96,0
				c-0.283-0.276-0.85-0.271-1.439-0.24c0.172-0.652-1.132,0.172-0.96-0.48c-0.769-0.271-1.592-0.488-2.641-0.48
				c-0.72,0-1.439,0-2.159,0c0.587,1.013,1.896,1.303,2.88,1.92c1.188,0.412,2.545,0.655,3.84,0.96
				c0.945,0.572,1.521,0.903,2.642,0.24c0.08,0,0.16,0,0.238,0c0.062-0.261,0.479-0.163,0.479-0.48c0.24,0,0.479,0,0.721,0
				C375.917,219.444,375.802,219.079,375.602,218.8z"/>
				</g>
				<g id="skin">
					<path fill="#C0B2A7" d="M252,198.88c0.779-0.98,1.659-1.86,2.64-2.64c-0.012-0.44,1.23-0.813,0.48-1.2
				c-2.343,2.457-5.287,4.313-7.92,6.48c0.563,0.237,1.179,0.421,1.92,0.48C249.773,200.653,251.333,200.212,252,198.88z"/>
					<path fill="#C0B2A7" d="M328.561,217.6c2.938,2.402,3.99,2.097,0.962,4.8c1.132-0.388,1.665-1.373,2.398-2.16
				C331.144,219.017,329.985,218.175,328.561,217.6z"/>
					<path fill="#C0B2A7" d="M307.2,220c-0.737,1.103-1.902,1.778-2.64,2.88c2.366-2.032,4.271-4.529,6.72-6.48
				C309.672,217.352,308.238,218.478,307.2,220z"/>
					<path fill="#C0B2A7" d="M240,231.28c0.16,0,0.32,0,0.48,0c-2.512-0.928-4.56-2.32-6.96-3.36c1.065,1.415,3.017,1.943,4.56,2.88
				C238.464,231.217,239.161,231.32,240,231.28z"/>
					<path fill="#C0B2A7" d="M231.12,220.96c1.338-0.982,2.212-2.428,3.36-3.6C232.85,218.05,232.241,219.761,231.12,220.96z" />
					<path fill="#C0B2A7" d="M381.12,237.04c-0.417-5.663-0.332-11.827-3.84-14.4c-0.825-0.105-1.347,0.095-1.92,0.24
				c-0.08,0-0.16,0-0.24,0c-0.271,0.45-0.93,0.51-1.199,0.96c-1.068,0.532-1.961,1.24-2.88,1.92c-4.131,3.069-6.745,7.655-9.601,12
				c-0.957,4.963-2.204,9.636-3.842,13.92c0.03,0.431-0.294,0.506-0.238,0.96c-0.702-0.063-0.737,0.543-1.439,0.48
				c-1.127-0.633-1.518-2.003-1.438-3.84c0-0.08,0-0.16,0-0.24c-0.539-0.101-0.009-1.274-0.479-1.44
				c-0.5-1.661-0.707-3.613-0.96-5.52c0.031-0.591,0.036-1.156-0.24-1.44c-0.454-0.986-0.707-2.173-0.96-3.36c0-0.08,0-0.16,0-0.24
				c-0.704-0.977-0.777-2.581-0.721-4.32c-0.138-0.422,0.29-1.41-0.239-1.44c0,0.32,0,0.64,0,0.96c-0.479-0.8-0.511-2.05-0.479-3.36
				c0-0.08,0-0.16,0-0.24c-0.399-2.642-0.399-6.958,0-9.6c0-0.4,0-0.8,0-1.2c0.155-1.603-0.316-3.837,0.238-5.04
				c0-0.16,0-0.32,0-0.48c0.396-2.006-0.146-4.945,0.722-6.48c-1.658-2.182-3.521-4.161-5.28-6.24
				c0.068-0.388-0.227-0.413-0.239-0.72c-3.605-5.673-7.146-11.415-8.641-19.2c-0.021-0.78-0.123-1.476-0.479-1.92
				c-1.716-6.684-4.46-12.34-5.04-20.16c-0.551-0.489-0.087-1.993-0.238-2.88c0-2.24,0-4.479,0-6.72
				c-0.098-1.135,0.509-1.578-0.24-2.16c-1.655-0.185-2.275-1.404-4.08-1.44c0.021,0.341,0.04,0.68-0.239,0.72
				c-1.134,1.827-4.623,1.298-6.24,2.64c-0.585,5.735-3.964,8.676-6.479,12.48c-0.085,0.315-0.422,0.377-0.479,0.72
				c-1.673,1.768-3.293,3.588-5.04,5.28c-0.734,0.625-1.401,1.318-1.682,2.4c-0.084,0.795-0.719,1.041-1.198,1.44
				c0,0.08,0,0.16,0,0.24c-1.181,1.38-2.461,2.66-3.842,3.84c0,0.08,0,0.16,0,0.24c-0.779,0.98-1.658,1.86-2.64,2.64
				c-0.08,0-0.16,0-0.24,0c-4.179,4.061-8.653,7.825-12.72,12c-0.08,0-0.16,0-0.24,0c-0.399,0.481-0.645,1.116-1.44,1.2
				c-7.768,6.072-17.162,10.518-29.28,12.24c-0.203,0.357-0.773,0.348-1.2,0.48c-0.003,0.396-0.666,0.135-0.72,0.48
				c2.147,0.813,4.375,1.545,4.8,4.08c-0.64,0-1.28,0-1.92,0c-0.808-0.152-2.229,0.31-2.64-0.24c-3.677,0.742-6.994-1.592-8.88,0.24
				c3.626,1.53,10.164,2.588,8.88,7.44c-2.511-0.929-3.71-3.17-6.72-3.6c-0.463,0.063-0.957,0.107-1.462,0.146c0,0,0,0-0.001,0
				c-0.155,0.046-0.308,0.084-0.457,0.094c-0.405,0.028-0.828,0.022-1.26,0.002c-0.307,0.012-0.62,0.022-0.935,0.031
				c-0.015,0-0.03,0.001-0.044,0.001c-0.82,0.023-1.657,0.041-2.501,0.063c-0.141,0.004-0.281,0.007-0.421,0.011
				c-0.15,0.004-0.3,0.011-0.451,0.016c-0.461,0.016-0.922,0.035-1.38,0.06c-0.083,0.004-0.167,0.008-0.25,0.013
				c-0.268,0.016-0.535,0.034-0.8,0.055c-0.081,0.006-0.161,0.012-0.241,0.019c-0.278,0.023-0.554,0.048-0.827,0.078
				c-0.04,0.004-0.078,0.009-0.117,0.013c-1.22,0.136-2.377,0.343-3.411,0.667c-0.032,0.01-0.064,0.02-0.095,0.03
				c-0.246,0.08-0.486,0.166-0.717,0.259c-0.021,0.008-0.04,0.018-0.06,0.026c-0.732,0.303-1.383,0.683-1.923,1.161
				c-0.008,0.011-0.016,0.021-0.024,0.032c-0.055,0.071-0.11,0.142-0.164,0.212c-0.269,1.733-1.326,2.398-2.547,2.29
				c-0.006,0-0.012-0.001-0.018-0.002c-0.116-0.011-0.232-0.028-0.351-0.052c-0.019-0.004-0.039-0.01-0.058-0.015
				c-0.105-0.024-0.211-0.05-0.317-0.084c-0.028-0.009-0.057-0.022-0.085-0.032c-0.097-0.033-0.195-0.067-0.292-0.109
				c-0.046-0.02-0.091-0.044-0.136-0.066c-0.08-0.038-0.16-0.073-0.24-0.116c-0.07-0.038-0.14-0.083-0.209-0.125
				c-0.054-0.033-0.109-0.062-0.163-0.097c-0.123-0.081-0.244-0.167-0.363-0.26c-0.966-3.593-2.575-6.544-3.6-10.08
				c-1.433,4.248-2.672,8.688-2.88,14.16c0,1.04,0,2.08,0,3.12c-0.003,3.763,1.362,6.158,1.68,9.6c0.398,0.492,0.398,2.627,0,3.12
				c-0.278,7.032-1.889,11.935-0.72,18.72c-0.15,0.729,0.308,2.067-0.24,2.4c0.576,10.305,3.084,18.676,4.56,28.08
				c0.557,1.283,0.082,3.598,0.24,5.28c0,0.88,0,1.76,0,2.64c0.108,1,0.187,1.977,0.255,2.943c0.022,0.316,0.043,0.627,0.063,0.94
				c0.04,0.642,0.075,1.276,0.105,1.904c0.111,2.279,0.182,4.479,0.315,6.6c0,0.002,0,0.004,0,0.006
				c0.065,0.669,0.115,1.338,0.155,2.003c0,0.002,0,0.004,0.001,0.006c0.082,0.889,0.175,1.77,0.305,2.637
				c0.055,0.364,0.133,0.734,0.225,1.107c0.014,0.056,0.029,0.111,0.044,0.167c0.094,0.36,0.199,0.723,0.32,1.087
				c0.523,1.166,1.067,2.321,1.631,3.461c0.001,0.001,0.001,0.003,0.002,0.005c0.563,1.14,1.147,2.266,1.751,3.376
				c0.258,0.424,0.519,0.842,0.777,1.253c0.001,0.003,0.003,0.005,0.005,0.008c0.541,0.858,1.074,1.682,1.571,2.46
				c0.218,0.34,0.431,0.675,0.632,0.996c5.025,8.015,11.598,14.307,19.68,18.96c0.349,0.131,1.24-0.281,1.2,0.24
				c0.349,0.372,0.936,0.504,1.68,0.48c0.99-0.03,1.503,0.417,2.64,0.24c1.2,0,2.4,0,3.6,0c1.44,0,2.88,0,4.32,0
				c8.629-1.966,20.063-1.717,29.04-0.24c2,0,4,0,6,0c6.095-0.706,11.941-1.659,16.08-4.32c0.08,0,0.16,0,0.24,0
				c6.572-4.048,12.598-7.937,18.238-13.2c2.962-2.762,6.207-6.565,8.16-9.84c1.396-2.341,2.181-5.191,3.602-7.68
				c1.425-2.496,3.065-4.68,4.079-7.44c0-0.08,0-0.16,0-0.24c1.102-2.66,1.914-5.607,2.881-8.4c0-0.08,0-0.16,0-0.24
				c-0.001-2.081,0.665-3.495,0.721-5.52c0-0.24,0-0.48,0-0.72c0.155-1.204-0.314-3.035,0.239-3.84c0,0.64,0,1.28,0,1.92
				c0.548-0.333,0.09-1.67,0.239-2.4c0.157-1.682-0.316-3.997,0.239-5.28c0-0.16,0-0.32,0-0.48c-0.041-1.88-0.323-4.003,1.92-3.6
				c0.085,0.315,0.424,0.377,0.479,0.72c0,0.08,0,0.16,0,0.24c0.28,0.04,0.261,0.379,0.24,0.72c-0.146,0.574,0.302,1.741-0.24,1.92
				c0,0.08,0,0.16,0,0.24c-0.157,1.682,0.316,3.997-0.239,5.28c0,0.08,0,0.16,0,0.24c0.177,1.225-0.263,3.086,0,3.6
				c0.16,0,0.319,0,0.479,0c0.158,0,0.318,0,0.479,0c-0.045-0.204,0.094-0.227,0.239-0.24c4.386-2.015,8.685-4.116,10.319-8.88
				c0.275-0.284,0.271-0.849,0.239-1.44c0.24-1.321,1.024-4.52,1.439-4.08c0.636-0.565,0.346-2.055,0.961-2.64
				c0-0.16,0-0.32,0-0.479c1.657-5.303,5.226-8.694,6.72-14.16c0-0.32,0-0.64,0-0.96c-0.38-1.344-0.516-4.512,0.24-5.52
				c0-0.08,0-0.16,0-0.24c0.865-2.095,2.079-3.841,2.88-6C381.363,239.683,381.017,238.136,381.12,237.04z M299.04,231.04
				c0.026-0.172,0.062-0.322,0.101-0.455c0.012-0.043,0.027-0.075,0.041-0.115c0.028-0.081,0.059-0.162,0.09-0.227
				c0.02-0.037,0.039-0.064,0.06-0.097c0.03-0.052,0.063-0.104,0.101-0.143c0.021-0.025,0.045-0.044,0.067-0.064
				c0.036-0.031,0.071-0.062,0.11-0.082c0.024-0.014,0.052-0.023,0.076-0.032c0.041-0.015,0.082-0.026,0.125-0.031
				c0.026-0.003,0.056-0.004,0.08-0.003c0.047,0,0.094,0.005,0.141,0.015c0.027,0.005,0.055,0.011,0.08,0.019
				c0.053,0.015,0.104,0.035,0.156,0.058c0.024,0.011,0.049,0.021,0.074,0.034c0.059,0.029,0.117,0.064,0.178,0.102
				c0.021,0.013,0.041,0.025,0.063,0.039c0.068,0.047,0.139,0.098,0.209,0.153c0.012,0.009,0.021,0.016,0.032,0.025
				c0.466,0.372,0.935,0.895,1.301,1.262c0.01,0.009,0.021,0.018,0.026,0.027c0.361,0.244,0.714,0.5,1.053,0.77
				c0.002,0.001,0.003,0.002,0.005,0.003c0.712,0.404,1.542,0.75,2.229,1.176c0.03,0.019,0.061,0.04,0.09,0.059
				c0.145,0.093,0.277,0.19,0.404,0.292c0.047,0.039,0.095,0.078,0.14,0.118c0.104,0.096,0.198,0.199,0.288,0.306
				c0.034,0.044,0.074,0.086,0.106,0.133c0.107,0.155,0.199,0.318,0.257,0.5C304.613,237.574,298.701,233.155,299.04,231.04z
				 M307.681,230.8c3.241,0.839,7.949,0.21,10.802,1.44c-1.426,3.039-7.63,1.79-10.562,0.96
				C306.946,233.05,306.487,231.089,307.681,230.8z M244.799,236.797c-1.266,3.234-8.026,1.354-9.359-0.477
				c-0.822-0.684,0.964-2.054,1.68-1.2c0.125,0.096,0.257,0.179,0.397,0.252c0.043,0.023,0.091,0.039,0.136,0.06
				c0.099,0.046,0.198,0.092,0.303,0.129c0.052,0.019,0.108,0.031,0.161,0.047c0.104,0.032,0.208,0.064,0.316,0.088
				c0.052,0.012,0.105,0.02,0.158,0.03c0.116,0.022,0.232,0.044,0.352,0.061c0.044,0.006,0.091,0.01,0.136,0.015
				c0.134,0.016,0.27,0.03,0.407,0.04c0.026,0.002,0.052,0.003,0.077,0.004c1.314,0.082,2.744-0.057,3.854,0.194c0,0,0,0,0.001,0
				c0.144,0.033,0.283,0.072,0.415,0.12c0.004,0.001,0.008,0.003,0.012,0.004c0.129,0.047,0.252,0.102,0.368,0.165
				c0.004,0.002,0.008,0.005,0.012,0.007c0.115,0.064,0.224,0.137,0.324,0.221c0,0,0,0,0.001,0c0.003,0,0.006,0,0.009,0
				c-0.001,0.002-0.002,0.004-0.003,0.006C244.641,236.635,244.726,236.709,244.799,236.797z M258.72,209.2
				c2.681-0.2,3.971,2.327,5.76,4.56c0.929,1.159,2.754,2.87,2.88,4.08c0.116,1.111-0.894,3.642-1.44,5.28
				c-0.615,1.844-1.414,3.624-1.68,5.04c-0.521-0.041-0.108,0.852-0.24,1.2c-0.254,2.534-1.058,5.421-1.68,8.16
				c-0.544,2.396-0.538,5.87-3.6,6c-1.366-2.07,0.645-3.905,0.96-6.72c0.741-6.611,1.734-14.326,4.08-19.44
				C262.381,214.491,259.09,212.283,258.72,209.2z M229.68,227.44c-0.125-0.355-0.355-0.605-0.48-0.96
				c-2.002,0.737-3.285-0.311-4.8-0.96c-0.192-1.631,1.117-1.762,2.16-2.16c-0.632-1.316-2.935-1.598-2.16-3.6
				c1.141-0.854,2.684-0.198,4.08,0c2.793-4.152,6.911-7.459,13.44-6.72c4.748,0.538,9.147,4.059,12.24,6
				c-0.056,1.552,1.518,2.566,1.2,3.6c-1.607-0.073-2.055-1.305-3.36-1.68c0.994,1.257,1.411,7.061-1.92,4.56
				c-0.147,1.268,1.507,0.733,2.16,1.2c0.729,3.383-1.886,4.426-4.362,4.448c0.001,0,0.002,0.001,0.003,0.001
				c0.162,0.057,0.313,0.125,0.436,0.222c0.133,0.098,0.245,0.217,0.324,0.369c-1.092,3.232-6.84,2.483-10.081,1.68
				c-2.671-0.662-4.5-2.289-6-3.12C231.5,229.46,230.54,228.5,229.68,227.44z M335.521,221.68c-0.355-0.278-0.604-0.278-0.962,0
				c-0.483,0.532-0.997,1.035-1.529,1.519c-0.189,0.172-0.394,0.33-0.586,0.496c-0.354,0.299-0.707,0.596-1.078,0.876
				c-0.253,0.191-0.519,0.372-0.779,0.555c-0.331,0.23-0.666,0.457-1.011,0.673c-0.302,0.189-0.61,0.374-0.923,0.553
				c-0.32,0.183-0.648,0.359-0.979,0.531c-0.351,0.183-0.703,0.364-1.064,0.534c-0.303,0.141-0.611,0.271-0.921,0.403
				c-0.409,0.176-0.816,0.354-1.24,0.515c-0.21,0.08-0.433,0.148-0.646,0.225c-1.173,0.419-2.39,0.793-3.667,1.107
				c-0.02,0.005-0.038,0.011-0.058,0.016c-0.034,0.009-0.068,0.018-0.104,0.027c-0.114,0.028-0.229,0.058-0.347,0.085
				c-0.004,0.001-0.008,0.001-0.011,0.002c-0.31,0.078-0.616,0.151-0.927,0.214c-0.089,0.018-0.177,0.031-0.264,0.047
				c-0.243,0.045-0.483,0.093-0.729,0.128c-0.001,0-0.001,0-0.001,0c-0.225,0.042-0.436,0.095-0.659,0.134
				c-0.57,0.398-2.789,0.398-3.359,0c-1.543,0.034-4.047-1.476-2.16-2.64c-2.635,0.128-6.063-0.622-8.16-1.2
				c-0.799-1.442,0.152-2.987,0.961-3.6c-0.344,0.058-0.403,0.395-0.721,0.48c-0.908,0.771-1.64,1.72-3.601,1.44
				c-0.72,0-1.438,0-2.159,0c-1.156,0.116-1.654-0.425-1.921-1.2c0.626-1.455,2.513-1.647,3.12-3.12
				c0.314-0.085,0.378-0.423,0.721-0.48c0.227-0.733,1.168-0.752,1.199-1.68c-0.591,0.032-1.156,0.036-1.439-0.24
				c-0.662-0.313-0.479-1.297-0.24-1.92c0.56-0.163,0.645-0.797,1.201-0.96c3.404-1.634,6.231-3.848,11.279-3.84
				c0.57-0.398,2.789-0.398,3.359,0c4.104,0.856,8.212,1.708,12,2.88c-2.644-2.639-7.851-4.791-13.439-5.28c-0.96,0-1.92,0-2.88,0
				c-3.855-0.404-5.181,2.962-8.399,2.4c-0.262-0.06-0.164-0.478-0.479-0.48c0.211-1.63,1.479-2.201,2.399-3.12
				c0.08,0,0.16,0,0.24,0c1.834-0.725,3.625-1.494,5.999-1.68c0.492-0.398,2.628-0.398,3.12,0c7.097,0.985,13.298,2.862,16.319,7.92
				c0.556,0.534-0.081,0.936,0,1.68c1.552,0.449,2.171,1.83,3.841,2.16c1.148,1.091,4.107,0.372,5.279,1.44
				C339.438,221.597,337.591,221.751,335.521,221.68z M345.841,258.64c-1.497,2.789-3.041,6.338-6,7.44
				c0-0.084,0.011-0.165,0.018-0.248c-0.008,0.003-0.013,0.006-0.018,0.008c0.005-0.019,0.013-0.035,0.021-0.053
				c0.147-2.313,2.037-4.04,3.282-6.05c0.014-0.02,0.023-0.036,0.034-0.054c0.077-0.125,0.148-0.252,0.222-0.38
				c0.021-0.04,0.046-0.078,0.065-0.118c0.064-0.121,0.123-0.244,0.184-0.368c0.021-0.046,0.047-0.091,0.065-0.138
				c0.076-0.17,0.146-0.343,0.207-0.519c0-0.08,0-0.16,0-0.24c1.096-2.184,1.598-4.962,2.64-7.2c0-0.08,0-0.16,0-0.24
				c0.164-1.092,1.563-1.49,2.642-0.96C350.227,252.845,347.287,255.945,345.841,258.64z M364.801,280
				c-1.809-0.215-1.755,0.111-3.358,0.24c-0.521-0.521-1.048-1.034-0.96-2.16c0.078,0,0.158,0,0.238,0c0-0.32,0-0.64,0-0.96
				c-0.198-3.076,2.191-8.453,5.762-7.44C370.136,270.718,367.284,278.988,364.801,280z M376.801,232
				c-0.313,0.084-0.378,0.422-0.721,0.48c-0.413,0.786-1.266,1.134-1.68,1.92c0.978,0.703,1.485,1.873,1.92,3.12
				c0.204-0.044,0.227,0.093,0.238,0.24c0.424,1.473,2.079,4.688-0.479,5.04c0-0.08,0-0.16,0-0.24
				c-1.105-0.494-1.395-1.807-1.92-2.88c-0.715-0.805-0.779-2.261-1.439-3.12c-0.225,0.176-0.361,0.437-0.479,0.72
				c-0.56,0.163-0.645,0.797-1.199,0.96c0,0.08,0,0.16,0,0.24c-0.273,0.527-0.803,0.797-0.961,1.44
				c0.146,0.014,0.285,0.036,0.24,0.24c1.428,1.532,2.296,3.625,3.12,5.76c0.079,0,0.158,0,0.238,0
				c0.295,1.305,0.581,2.619,0.479,4.32c0,0.08,0,0.16,0,0.24c0.396,0.414,0.396,2.466,0,2.88c0,0.08,0,0.16,0,0.24
				c-0.999,3.96-4.254,5.666-6.239,8.64c-0.563,0.077-0.901,0.379-1.681,0.24c-1.437,2.645-1.489,6.67-5.279,6.96
				c-0.135-0.346-0.375-0.586-0.721-0.72c1.235-3.549,4.401-7.384,3.601-12.24c-0.371-2.242-2.433-3.885-2.641-6.24
				c-0.021-0.34-0.039-0.68,0.239-0.72c0.614-2.105,1.379-4.063,2.159-6c0.226-0.657,0.736-1.024,0.96-1.68
				c0.258-0.387,0.546-0.79,0.853-1.201c0.091-0.123,0.188-0.249,0.283-0.373c0.231-0.307,0.476-0.618,0.729-0.936
				c0.103-0.125,0.201-0.25,0.305-0.378c0.317-0.389,0.646-0.784,0.985-1.184c0.033-0.039,0.064-0.077,0.098-0.117
				c1.318-1.55,2.775-3.171,4.188-4.78c0.008-0.009,0.016-0.018,0.021-0.026c0.636-0.771,1.271-1.543,1.896-2.286
				c0.194-0.229,0.394-0.482,0.599-0.738c0.255-0.32,0.521-0.646,0.805-0.96c0.001-0.001,0.001-0.001,0.002-0.002
				c0.005-0.006,0.01-0.013,0.015-0.02c0.042-0.056,0.085-0.112,0.127-0.168c0.042-0.055,0.085-0.109,0.127-0.164
				c0.038-0.048,0.075-0.096,0.113-0.144c0.063-0.079,0.128-0.157,0.19-0.233c0.028-0.033,0.058-0.065,0.086-0.097
				c0.068-0.08,0.141-0.157,0.213-0.232c0.027-0.031,0.061-0.061,0.091-0.091c0.058-0.058,0.116-0.112,0.176-0.166
				c0.031-0.028,0.063-0.056,0.095-0.083c0.069-0.058,0.142-0.112,0.211-0.162c0.021-0.015,0.041-0.032,0.063-0.045
				c0.091-0.061,0.183-0.113,0.273-0.158c0.025-0.012,0.053-0.021,0.076-0.032c0.071-0.031,0.145-0.057,0.215-0.077
				c0.031-0.009,0.063-0.017,0.097-0.023c0.074-0.016,0.146-0.024,0.225-0.028c0.026-0.001,0.052-0.005,0.078-0.005
				c0.102,0,0.204,0.012,0.308,0.036c0.021,0.004,0.037,0.013,0.058,0.019c0.087,0.024,0.174,0.057,0.263,0.099
				c0.034,0.016,0.067,0.035,0.104,0.054c0.077,0.043,0.156,0.095,0.236,0.154c0.034,0.024,0.067,0.047,0.103,0.074
				c0.11,0.09,0.226,0.191,0.341,0.313C379.485,230.206,377.396,230.355,376.801,232z"/>
					<path fill="#C0B2A7" d="M364.801,246.64c-0.683,4.776,2.078,6.867,2.16,11.52c1.04-0.799,1.998-1.681,2.64-2.88
				c0.949-1.051,1.592-2.41,1.44-4.562c-0.147-3.771-1.578-6.262-3.12-8.64C366.851,243.571,365.822,245.102,364.801,246.64z"/>
					<path fill="#C0B2A7" d="M365.041,382.48c0.105-1.067-0.171-1.75-0.48-2.4c-1.089-0.966-2.199-1.91-3.317-2.848
				c-0.001,0-0.001-0.001-0.002-0.001c-0.349-0.241-0.687-0.488-0.999-0.751c-0.375-0.313-0.717-0.627-1.044-0.938
				c-0.052-0.047-0.104-0.094-0.15-0.142c-0.004-0.003-0.008-0.007-0.012-0.011c-0.113-0.095-0.229-0.19-0.34-0.285
				c-0.318-0.266-0.64-0.533-0.955-0.8c-0.17-0.143-0.34-0.288-0.51-0.431c-0.314-0.266-0.63-0.533-0.94-0.801
				c-0.166-0.142-0.331-0.286-0.496-0.429c-0.314-0.273-0.629-0.547-0.939-0.823c-0.154-0.137-0.31-0.274-0.463-0.412
				c-0.323-0.291-0.646-0.585-0.968-0.88c-0.137-0.126-0.271-0.251-0.408-0.378c-0.345-0.322-0.684-0.648-1.021-0.977
				c-0.107-0.104-0.215-0.207-0.321-0.312c-0.391-0.386-0.773-0.779-1.155-1.176c-0.049-0.05-0.099-0.1-0.146-0.15
				c-0.885-0.933-1.737-1.899-2.546-2.908c-0.064-0.074-0.126-0.153-0.19-0.228c-0.161-0.186-0.318-0.375-0.477-0.567
				c-0.135-0.167-0.268-0.339-0.396-0.51c-0.137-0.179-0.273-0.357-0.405-0.542c-0.158-0.22-0.311-0.452-0.46-0.682
				c-0.102-0.152-0.203-0.298-0.302-0.455c-0.247-0.398-0.484-0.81-0.714-1.243c0-0.24,0-0.48,0-0.72
				c-0.523-1.475-0.758-3.243-0.721-5.28c-0.146-0.014-0.284-0.036-0.239-0.24c-0.375-1.625-0.457-3.543-0.479-5.52
				c0-0.16,0-0.32,0-0.48c-0.554-0.568-0.086-2.154-0.24-3.12c0-0.24,0-0.48,0-0.72c-0.398-0.966-0.398-3.594,0-4.56
				c0-1.28,0-2.56,0-3.84c0-4.96,0-9.92,0-14.88c-2.961,3.119-5.477,6.684-8.88,9.36c-0.343,0.058-0.404,0.396-0.722,0.48
				c-0.116,0.013-0.221,0.041-0.313,0.077c-0.012,0.012-0.023,0.023-0.036,0.036c-0.206,0.208-0.408,0.408-0.613,0.613
				c-0.036,0.038-0.073,0.076-0.111,0.114c-0.019,0.028-0.033,0.055-0.05,0.083c-0.063,0.108-0.125,0.215-0.189,0.319
				c-0.019,0.028-0.038,0.055-0.058,0.083c-0.091,0.132-0.187,0.257-0.308,0.357c-0.08,0-0.16,0-0.24,0
				c-0.293,0.027-0.566,0.072-0.479,0.48c-0.084,0.795-0.719,1.041-1.2,1.44c-0.411,0.309-0.942,0.496-0.96,1.2
				c-1.287,1.833-2.688,3.55-4.319,5.04c-0.854,1.387-1.974,2.507-3.358,3.36c-1.5,1.7-3.102,3.3-4.8,4.8
				c-0.315,0.085-0.379,0.423-0.722,0.48c-1.34,1.541-2.778,2.98-4.318,4.32c-0.085,0.315-0.424,0.377-0.48,0.72
				c-0.699,0.9-1.499,1.701-2.398,2.4c-0.079,0-0.159,0-0.239,0c-1.503,1.778-3.298,3.264-5.28,4.56
				c-0.703,0.017-0.893,0.549-1.199,0.96c-0.398,0.481-0.646,1.116-1.439,1.2c-5.936,3.329-11.127,8.257-18.239,10.8
				c-7.039,2.517-14.833,4.038-22.8,6.24c-0.04,0.066-0.096,0.116-0.163,0.155c-0.02,0.012-0.045,0.018-0.067,0.028
				c-0.052,0.023-0.106,0.045-0.168,0.059c-0.026,0.005-0.055,0.008-0.082,0.012c-0.069,0.01-0.141,0.019-0.218,0.021
				c-0.019,0-0.039,0-0.059,0c-0.448,0.005-0.991-0.085-1.419-0.059c0,0-0.001,0-0.002,0c-0.076,0.062-0.161,0.115-0.222,0.188
				c0,0.745,0,1.49,0,2.235c1.673,9.766,3.536,19.344,6.72,27.6c0.204-0.044,0.227,0.095,0.24,0.24c0.397,0.259,0.397,2.141,0,2.4
				c0,0.08,0,0.16,0,0.238c-1.366,0.086-2.62,0.062-3.12-0.72c0-0.08,0-0.16,0-0.24c-4.592-2.768-9.06-5.658-14.64-7.438
				c-0.728,4.9,1.839,10.882,2.4,16.08c0.549,0.411,0.088,1.832,0.24,2.641c0.411,2.068,1.629,3.331,2.64,4.8
				c0.98,0.779,1.86,1.659,2.64,2.642c3.496,1.979,6.223,5.734,10.32,7.438c0.474,0.197,0.962,0.364,1.457,0.521
				c0.161,0.051,0.324,0.098,0.486,0.145c0.348,0.103,0.701,0.194,1.058,0.28c0.178,0.043,0.354,0.088,0.534,0.128
				c0.443,0.098,0.891,0.188,1.345,0.272c0.023,0.004,0.047,0.01,0.071,0.014c0.075,0.014,0.149,0.028,0.225,0.042
				c0.849,0.151,1.718,0.287,2.595,0.422c0.41,0.063,0.82,0.129,1.235,0.194c0.336,0.053,0.67,0.106,1.009,0.165
				c0.48,0.081,0.962,0.171,1.445,0.265c0.252,0.048,0.503,0.094,0.756,0.147c0.727,0.15,1.455,0.319,2.184,0.523
				c0.96,0,1.92,0,2.88,0c5.837-0.563,11.417-1.385,17.52-1.681c0.414-0.397,2.466-0.397,2.88,0
				c4.349-0.544,7.636,1.736,10.563,0.479c0.827,0.371,1.084,1.316,1.438,2.16c5.428-1.611,10.074-4.006,13.92-7.2
				c1.182-1.38,2.46-2.659,3.841-3.84c6.44-6.069,10.4-14.015,16.08-20.64c4.824-5.628,10.604-10.977,14.159-17.76
				c0.845-1.61,1.414-3.613,3.12-4.56c0.503-2.103,0.539-4.336,0-6.48C365.2,382.48,365.12,382.48,365.041,382.48z M331.2,423.041
				c-1.442,0.557-3.918,0.082-5.76,0.239c-0.722,0-1.44,0-2.16,0c-0.489,0.551-1.993,0.087-2.88,0.238
				c-0.641,0.029-1.242,0.097-1.819,0.188c-0.203,0.032-0.387,0.085-0.583,0.124c-0.359,0.072-0.722,0.141-1.059,0.234
				c-0.233,0.063-0.449,0.146-0.675,0.224c-0.272,0.092-0.553,0.179-0.813,0.284c-0.242,0.098-0.47,0.211-0.7,0.317
				c-0.22,0.104-0.442,0.202-0.653,0.313c-0.243,0.131-0.479,0.271-0.714,0.415c-0.176,0.104-0.354,0.209-0.523,0.32
				c-0.252,0.163-0.496,0.339-0.738,0.515c-0.136,0.099-0.273,0.195-0.406,0.297c-0.27,0.206-0.532,0.417-0.792,0.633
				c-0.089,0.074-0.18,0.147-0.269,0.225c-0.313,0.267-0.622,0.535-0.929,0.812c-0.002,0.002-0.004,0.004-0.006,0.006
				c-0.189,0.207-0.391,0.414-0.604,0.616c-0.407,0.387-0.828,0.749-1.257,1.098c0,0.001-0.001,0.001-0.002,0.002
				c-0.012,0.011-0.021,0.02-0.029,0.027c-0.272,0.252-0.546,0.503-0.823,0.75c-0.155,0.14-0.313,0.274-0.472,0.411
				c-0.255,0.221-0.516,0.438-0.776,0.65c-0.248,0.2-0.498,0.399-0.756,0.593c-0.14,0.103-0.28,0.199-0.424,0.299
				c-0.235,0.165-0.474,0.328-0.72,0.483c-0.152,0.097-0.313,0.188-0.471,0.281c-0.25,0.147-0.509,0.288-0.771,0.423
				c-0.161,0.082-0.323,0.164-0.49,0.242c-0.294,0.139-0.603,0.262-0.912,0.379c-0.149,0.06-0.296,0.12-0.45,0.176
				c-0.476,0.16-0.967,0.309-1.486,0.425c-0.379,0.021-0.914-0.115-0.959,0.239c-5.86,2.205-14.821,1.103-18.96-1.921
				c-0.9-0.697-1.701-1.499-2.4-2.397c-0.184-0.566-0.473-1.659,0-2.16c2.263,0.015,3.063,1.966,4.8,2.88
				c2.885,1.521,7.391,2.14,11.52,1.68c0.511-0.057,1-0.143,1.481-0.239c0.103-0.021,0.205-0.041,0.306-0.064
				c0.463-0.104,0.914-0.224,1.354-0.361c0.076-0.022,0.149-0.053,0.228-0.075c0.404-0.137,0.803-0.281,1.189-0.441
				c0.063-0.026,0.129-0.05,0.191-0.077c0.423-0.18,0.834-0.375,1.24-0.581c0.084-0.043,0.166-0.086,0.248-0.129
				c0.832-0.437,1.633-0.922,2.411-1.44c0.063-0.041,0.123-0.081,0.187-0.122c0.39-0.266,0.772-0.533,1.156-0.812
				c0.032-0.022,0.065-0.05,0.101-0.074c0.394-0.284,0.784-0.575,1.173-0.873c0.004-0.003,0.007-0.006,0.012-0.009
				c1.202-0.958,2.42-1.933,3.721-2.84c0.21-0.154,0.421-0.307,0.633-0.458c0.04-0.028,0.081-0.058,0.121-0.086
				c0.188-0.135,0.383-0.269,0.574-0.4c0.063-0.043,0.128-0.087,0.19-0.13c0.18-0.121,0.359-0.238,0.542-0.356
				c0.079-0.053,0.159-0.104,0.239-0.152c0.174-0.109,0.348-0.22,0.522-0.327c0.087-0.053,0.177-0.103,0.264-0.152
				c0.175-0.104,0.349-0.206,0.522-0.307c0.089-0.05,0.181-0.096,0.27-0.144c0.385-0.21,0.775-0.41,1.178-0.599
				c0.051-0.022,0.101-0.051,0.151-0.072c0.138-0.063,0.277-0.12,0.419-0.183c0.148-0.063,0.3-0.131,0.452-0.189
				c0.136-0.057,0.272-0.104,0.41-0.156c0.161-0.063,0.322-0.121,0.486-0.177c0.139-0.048,0.279-0.091,0.42-0.136
				c0.168-0.055,0.337-0.104,0.509-0.153c0.144-0.039,0.284-0.076,0.43-0.113c0.178-0.045,0.354-0.088,0.538-0.128
				c0.143-0.031,0.285-0.062,0.433-0.09c0.188-0.037,0.38-0.069,0.572-0.102c0.144-0.021,0.287-0.046,0.434-0.063
				c0.204-0.027,0.413-0.049,0.623-0.069c0.14-0.014,0.278-0.027,0.42-0.04c0.236-0.017,0.479-0.023,0.719-0.034
				c0.122-0.004,0.241-0.012,0.365-0.014c0.368-0.005,0.743-0.001,1.126,0.016c0.728-0.554,2.477-0.085,3.602-0.238
				c1.118,0,2.238,0,3.358,0C331.271,419.703,333.338,421.988,331.2,423.041z"/>
					<path fill="#C0B2A7" d="M208.255,243.403c0.033,0.109,0.057,0.228,0.087,0.34c0.078,0.29,0.153,0.583,0.213,0.892
				c0.021,0.106,0.037,0.217,0.055,0.324c0.062,0.354,0.119,0.713,0.166,1.083c0.002,0.017,0.004,0.034,0.006,0.051
				c0.248,1.972,0.341,4.13,0.747,6.046c0.292,0.99,0.612,1.951,0.96,2.885c0.751,1.638,1.712,3.041,2.54,4.529
				c0.021,0.038,0.042,0.076,0.063,0.114c0.146,0.266,0.286,0.537,0.421,0.81c0.042,0.085,0.085,0.169,0.125,0.255
				c0.158,0.334,0.308,0.674,0.441,1.028c0.156-1.204-0.314-3.035,0.24-3.84c1.46-8.876-0.257-18.1-2.88-24.96
				c-2.196,0.524-4.34,1.101-4.8,3.36c-0.373,2.773,0.308,4.492,1.2,6C208.003,242.656,208.138,243.02,208.255,243.403z"/>
					<path fill="#C0B2A7" d="M238.56,216.16c1.28,0,2.56,0,3.84,0C241.672,215.762,239.289,215.762,238.56,216.16z" />
					<path fill="#C0B2A7" d="M317.28,227.44c-1.682,0-3.359,0-5.04,0C313.365,227.839,316.155,227.839,317.28,227.44z" />
					<path fill="#C0B2A7" d="M214.08,264.4c-0.32,0.342-1.359,0.342-1.68,0c-1.248-4.912-4.377-7.943-5.52-12.96c0,0.4,0,0.8,0,1.2
				c0.552,0.568,0.086,2.154,0.24,3.12c0.649,8.311,4.664,13.256,5.04,21.84c1.798,2.193,3.539,6.219,3.12,9.12
				c0.778,0.422,0.874,1.526,2.4,1.2c-1.017-7.143-3.065-13.255-3.36-21.12C213.773,266.468,214.231,265.13,214.08,264.4z"/>
				</g>
				<g id="light_gray">
					<path fill="#E6E7E8" d="M250.08,225.52c-1.636-3.243-4.174-5.585-5.76-8.88c-0.763-0.037-1.056-0.544-1.92-0.48
				c-1.28,0-2.56,0-3.84,0c-1.633,0.126-3.121,0.399-4.08,1.2c-1.148,1.172-2.022,2.618-3.36,3.6
				c-0.163,1.283-1.928,1.678-0.96,3.36c0.821,1.499,2.15,2.49,3.36,3.6c2.4,1.04,4.448,2.432,6.96,3.36
				c1.958,0.091,4.201,0.567,5.76-0.24c0.647-0.059,1.2-0.023,1.637,0.128l0,0c0.001,0,0.002,0.001,0.003,0.001
				c-0.001,0-0.002-0.001-0.003-0.001c2.477-0.022,5.091-1.065,4.362-4.448C251.587,226.253,249.933,226.788,250.08,225.52z"/>
					<path fill="#E6E7E8" d="M328.561,217.6c-2.047-1.633-6.061-1.299-8.64-2.4c-0.384-0.416-1.081-0.52-1.921-0.48
				c-2.973-0.5-5.226,0.115-6.72,1.68c-2.449,1.951-4.354,4.448-6.72,6.48c-0.08,0-0.16,0-0.24,0c-0.809,0.613-1.76,2.158-0.961,3.6
				c2.099,0.578,5.524,1.328,8.159,1.2c0.193-0.127,0.392-0.25,0.722-0.24c1.681,0,3.358,0,5.04,0c2.206,0.046,3.358-0.96,5.278-1.2
				c0.598,0.036,0.996-0.124,1.2-0.48c1.877-1.163,3.974-2.108,5.761-3.36C332.551,219.697,331.499,220.002,328.561,217.6z"/>
					<path fill="#E6E7E8" d="M365.521,272.32c-1.991,0.409-2.438,2.362-2.882,4.32c0,0.08,0,0.16,0,0.24c0,0.024,0,0.048,0,0.073
				c0,0.056,0,0.111,0,0.167v0.48c0,0.065,0,0.131,0,0.196c0,0.174,0,0.349,0,0.523c0.581-0.378,1.063-0.858,1.439-1.44
				c0.207-0.194,0.144-0.657,0.479-0.72c0.233-0.006,0.188-0.293,0.24-0.48C365.53,275.05,365.572,273.732,365.521,272.32z"/>
					<path fill="#E6E7E8" d="M213.099,284.364l-0.006-0.006c-0.039-0.245-0.11-0.456-0.246-0.604l0,0l-0.144-1.379
				c0,0-0.555-1.149-0.783-1.359c-0.025-0.023-0.062-0.039-0.092-0.059c-0.107-0.181-0.303-0.273-0.628-0.236
				c-0.386,0.943-0.454,2.584,0.033,3.492v0.013l0.012,0.009c0.339,0.611,0.935,0.877,1.875,0.326
				c-0.005-0.069-0.017-0.131-0.027-0.194L213.099,284.364z"/>
				</g>
			</g>
			<g id="gray">
				<path fill="#A4B1BD" d="M158.88,408.881c-1.14,0.939-2.18,1.979-3.12,3.12C156.9,411.061,157.94,410.021,158.88,408.881z" />
				<path fill="#A4B1BD" d="M304.561,222.88c0.737-1.103,1.902-1.777,2.642-2.88c-1.209,1.111-2.466,2.175-3.601,3.36
			c0.314-0.085,0.377-0.422,0.719-0.48C304.4,222.88,304.48,222.88,304.561,222.88z"/>
				<path fill="#A4B1BD" d="M364.561,395.201c-2.551,4.009-5.73,7.388-8.64,11.04c-1.493,1.788-2.77,3.791-4.561,5.28
			c-5.543,5.815-9.768,12.952-14.642,19.438c-1.66,1.859-3.42,3.62-5.278,5.279c-4.813,4.229-10.45,7.63-17.762,9.358
			c-0.146,0.574,0.302,1.741-0.238,1.92c0,0.08,0,0.16,0,0.24c0.011,1.37-0.094,2.628-0.479,3.602c0,0.08,0,0.158,0,0.238
			c-0.238,1.685-0.207,3.636-0.721,5.04c-0.401,0.398-0.19,1.409-0.24,2.16c0,0.72,0,1.439,0,2.16
			c1.364-0.472,2.761-0.964,4.172-1.476c0.012-0.004,0.023-0.011,0.035-0.015c0.58-0.234,1.164-0.45,1.75-0.645
			c0.007-0.003,0.015-0.006,0.021-0.009c0.638-0.237,1.272-0.479,1.911-0.729c0.021-0.008,0.038-0.015,0.06-0.021
			c3.938-1.528,7.884-3.25,11.505-5.238c0-0.001,0.001-0.001,0.001-0.001c0.6-0.329,1.188-0.665,1.769-1.01
			c0.007-0.003,0.013-0.008,0.018-0.011c1.436-0.918,2.838-1.848,4.205-2.795c0.004-0.002,0.007-0.005,0.011-0.008
			c0.235-0.176,0.477-0.35,0.706-0.529c0.764-0.995,1.989-1.529,2.88-2.397c0.334-0.161,0.652-0.339,0.963-0.524
			c0.032-0.019,0.063-0.039,0.094-0.059c0.288-0.175,0.568-0.358,0.841-0.55c0.003-0.001,0.004-0.002,0.005-0.003
			c1.5-1.193,2.954-2.421,4.351-3.703c0.798-0.946,1.667-1.821,2.63-2.604c3.581-4.178,7.376-8.144,9.84-13.438
			c0.625-2.254,1.862-3.897,3.12-5.521c0.785-1.455,1.904-2.574,2.399-4.318c0.046-0.192,0.237-0.239,0.238-0.475
			c0-0.002,0.001-0.003,0.001-0.005c0-0.001-0.001-0.001-0.001-0.002c0-0.078,0-0.155,0-0.233v-0.001
			c4.703-5.294,8.409-11.587,11.76-18.237c0-0.08,0-0.16,0-0.24c0.501-1.18,0.868-2.492,1.438-3.6
			c0.262-0.701,0.537-1.383,0.479-2.4c0.438-2.326-0.199-4.319,0.479-6.48c-3.129-3.27-7.674-5.126-12.718-6.481
			c0.317,2.004,1.832,4.886,1.68,8.16c0,0.08,0,0.16,0,0.24c0.398,0.887,0.398,3.433,0,4.32c0,0.08,0,0.16,0,0.24
			c0.146,1.346-0.456,1.944-1.198,2.4c-0.779,0.139-1.116-0.164-1.682-0.24c-0.054,0.186-0.007,0.473-0.239,0.48
			c0,0.08,0,0.16,0,0.24c0,0.001-0.001,0.001-0.001,0.002C364.978,393.541,364.771,394.372,364.561,395.201"/>
				<path fill="#A4B1BD" d="M200.88,396.4c-1.04,0-2.08,0-3.12,0c-0.806,0.555-2.636,0.084-3.84,0.238c-0.08,0-0.16,0-0.24,0
			c-0.678,0.362-1.656,0.424-2.64,0.48c-0.591-0.032-1.156-0.036-1.44,0.24c-0.788,0.41-1.765,0.635-2.88,0.72
			c-2.729,0.229-5.107,0.813-7.44,1.438c-5.752,1.368-10.646,3.596-13.2,8.16c-9.507,8.572-15.955,20.206-22.08,32.16
			c0,0.08,0,0.16,0,0.24c-1.02,2.897-2.403,5.437-3.12,8.64c0,0.08,0,0.16,0,0.24c0.206,0.766-0.377,0.742-0.48,1.2
			c0.078,0.562,1.047,0.23,1.2,0.72c0.08,0,0.16,0,0.24,0c0.982,0.218,2.163,0.237,2.88,0.72c0.08,0,0.16,0,0.24,0
			c0.943,0.019,1.804,0.116,2.4,0.48c0.08,0,0.16,0,0.24,0c0.943,0.017,1.804,0.116,2.4,0.479c0.08,0,0.16,0,0.24,0
			c1.467,0.135,2.907,0.293,4.08,0.722c0.349,0.371,1.229,0.212,1.92,0.238c1.106,0.017,2.129,0.111,2.88,0.48c0.08,0,0.16,0,0.24,0
			c2.407,0.313,4.749,0.69,6.96,1.2c0.454,0.426,1.585,0.175,2.4,0.239c0.8,0,1.6,0,2.4,0c0.729,0.148,2.067-0.309,2.4,0.238
			c0.729,0.15,2.067-0.308,2.4,0.24c0.56,0,1.12,0,1.68,0c0.411-0.55,1.832-0.088,2.64-0.24c2.28-0.04,4.123-0.517,6.24-0.72
			c0-0.24,0-0.479,0-0.721c0.08-1.36,0.301-2.578,0.72-3.602c0-0.08,0-0.16,0-0.238c0.229-1.53,0.397-3.122,0.96-4.319
			c0.202-0.279,0.316-0.646,0.24-1.2c0.423-1.814,0.697-3.783,1.44-5.28c0-0.08,0-0.158,0-0.238
			c1.376-5.426,2.694-10.905,4.56-15.842c0-0.079,0-0.158,0-0.238c0.241-1.118,0.441-2.278,0.96-3.12c0-0.079,0-0.159,0-0.239
			c1.573-6.667,4.188-12.292,6.48-18.24c0.019-0.897-1.035-0.726-0.72-1.92c0.354-1.484,2.159-1.521,2.88-2.64
			c-0.659-0.541-2.221-0.179-2.88-0.72C201.04,396.4,200.96,396.4,200.88,396.4z"/>
				<path fill="#A4B1BD" d="M318.48,620.321c0-0.48,0-0.96,0-1.44c-0.559-1.123-0.083-3.277-0.24-4.8c0-0.8,0-1.6,0-2.4
			c-0.028-0.689,0.131-1.568-0.24-1.92c-0.417-1.263-0.601-2.759-0.96-4.08c-0.063-0.977-0.451-1.628-0.72-2.397
			c-0.478-0.807-0.789-1.771-0.96-2.88c-0.292-1.788-0.831-3.329-1.439-4.803c-1.359-3.839-2.85-7.551-3.841-11.76
			c0-0.08,0-0.16,0-0.24c-0.796-1.844-1.318-3.961-1.68-6.237c0-0.08,0-0.16,0-0.24c-0.485-1.114-0.801-2.401-0.96-3.84
			c0.029-0.594,0.036-1.156-0.24-1.44c-0.511-1.567-0.825-3.335-0.96-5.28c-0.028-0.689,0.131-1.568-0.24-1.92
			c-0.388-0.972-0.49-2.229-0.479-3.6c0.043-1.725-0.213-3.148-0.48-4.563c-0.547-0.33-0.089-1.67-0.238-2.397c0-0.24,0-0.48,0-0.72
			c-0.549-0.333-0.091-1.671-0.24-2.4c-0.399-2.642-0.399-6.958,0-9.6c0.041-0.359-0.086-0.888,0.24-0.961
			c0.424-3.336,0.84-6.682,0.959-10.319c0-0.479,0-0.96,0-1.44c0-0.479,0-0.96,0-1.438c0-0.08,0-0.16,0-0.24
			c-0.185-2.021,0.371-3.309,0.239-5.28c0.151-0.729-0.308-2.065,0.24-2.397c0.21-2.353,0.069-5.051,0.479-7.2c0-0.08,0-0.16,0-0.24
			c0.007-2.233,0.099-4.383,0.479-6.239c0-0.079,0-0.159,0-0.239c-0.188-1.547,0.354-2.363,0.24-3.84
			c0.153-1.124-0.313-2.874,0.238-3.602c0-0.24,0-0.479,0-0.72c0.159-2.563-0.317-5.759,0.24-7.92
			c0.519-8.523-0.101-18.183,0.479-26.643c0-0.078,0-0.158,0-0.238c0.15-0.81-0.312-2.229,0.238-2.642
			c-0.044-0.204,0.096-0.227,0.24-0.238c0.248-0.633,0.287-1.475,0.239-2.399c0.504-2.938,0.968-5.913,1.2-9.12
			c0-0.88,0-1.761,0-2.641c0-0.08,0-0.159,0-0.239c0.08,0,0.16,0,0.238,0c0.713-1.367-1.215-1.484-2.64-1.44
			c-0.411-0.549-1.832-0.088-2.641-0.238c-0.641,0-1.28,0-1.92,0c-1.76,0-3.521,0-5.28,0c-1.679,0.002-3.213,0.146-4.56,0.479
			c-0.041,0.279-0.381,0.261-0.722,0.24c-0.409,0.549-1.832,0.088-2.641,0.239c-1.678-0.157-2.825,0.215-4.32,0.238
			c-2.511-0.028-4.312,0.648-6.96,0.48c-2.149-0.092-3.882-0.598-5.52-1.2c-0.701-0.26-1.383-0.537-2.4-0.479
			c-1.792-0.287-3.53-0.63-5.04-1.2c-0.279-0.201-0.644-0.316-1.2-0.239c-5.272-1.848-8.661-5.579-12.96-8.399
			c-0.98-0.778-1.86-1.658-2.64-2.641c-0.85-1.87-2.442-2.998-2.88-5.28c0.012-0.729-0.218-1.222-0.72-1.438c0,0.08,0,0.16,0,0.24
			c0.313,0.668-0.675-0.93-0.72-1.439c-5.875-6.978-11.874-13.831-14.802-23.754c-0.037-0.054-0.083-0.104-0.121-0.157
			c-0.249,0.48-0.381,1.077-0.676,1.51c-0.012,0.021-0.022,0.039-0.036,0.059c-0.05,0.065-0.109,0.124-0.169,0.18
			c-0.022,0.021-0.041,0.046-0.065,0.065c-0.064,0.051-0.141,0.089-0.218,0.126c-0.03,0.015-0.053,0.035-0.084,0.047
			c-0.114,0.044-0.242,0.074-0.388,0.085c0,0.72,0,1.438,0,2.16c0.092,2.868,0.471,5.447,0.96,7.92
			c0.427,1.173,0.587,2.613,0.72,4.08c0,0.08,0,0.16,0,0.239c0.362,0.679,0.423,1.655,0.48,2.641
			c-0.032,0.592-0.037,1.157,0.24,1.438c0.218,1.091,0.474,2.141,0.75,3.171c0.047,0.179,0.093,0.356,0.142,0.532
			c0.274,0.983,0.574,1.941,0.897,2.877c0.058,0.168,0.118,0.333,0.178,0.499c0.344,0.96,0.705,1.902,1.096,2.815
			c0.006,0.015,0.014,0.029,0.02,0.044c0.402,0.937,0.831,1.845,1.278,2.735c0.046,0.092,0.09,0.186,0.137,0.274
			c0.448,0.88,0.917,1.737,1.404,2.577c0.053,0.091,0.106,0.182,0.16,0.272c1.566,2.669,3.316,5.153,5.18,7.525
			c0.003,0.005,0.007,0.009,0.01,0.015c0.21,0.266,0.423,0.526,0.628,0.798c0.004,0.005,0.009,0.01,0.013,0.016
			c0.742,0.923,1.5,1.831,2.269,2.728c7.291,4.229,14.078,8.963,23.04,11.521c0.08,0,0.16,0,0.24,0
			c1.5,0.339,3.139,0.541,4.32,1.199c0.642,0.237,1.266,0.495,2.16,0.479c1.21,0.149,2.425,0.295,3.36,0.722
			c1.443,0.396,3.058,0.622,4.8,0.721c0.808-0.399,3.272-0.399,4.08,0c1.28,0,2.562,0,3.841,0c1.441-0.559,3.918-0.082,5.76-0.24
			c0.24,0,0.479,0,0.721,0c0.414-0.396,2.467-0.396,2.88,0c3.019,0.742,4.86,3.938,4.562,7.2c0,0.159,0,0.319,0,0.479
			c0.021,1.385,0.033,2.757-0.479,3.603c0,0.079,0,0.159,0,0.239c-0.173,1.667-1.156,2.522-2.642,2.88
			c-0.41,0.55-1.832,0.089-2.64,0.24c-1.438-0.159-2.343,0.218-3.601,0.238c-0.08,0-0.16,0-0.24,0
			c-0.708,0.412-1.73,0.51-2.88,0.479c-0.16,0-0.319,0-0.479,0c-0.649,0.549-2.455-0.057-2.16,1.438c-0.08,0-0.16,0-0.238,0
			c0,0.08,0,0.16,0,0.24c0.017,1.536-0.225,2.815-0.72,3.84c-0.561,1.682-0.89,3.591-0.96,5.76c-0.047,1.153-0.115,2.285-0.48,3.12
			c-0.389,1.691-0.563,3.6-0.72,5.521c-0.017,0.941-0.116,1.804-0.48,2.397c-0.331,0.312-0.237,1.043-0.24,1.682
			c-0.122,1.719-0.291,3.39-0.72,4.801c-0.402,0.396-0.191,1.409-0.24,2.16c0,1.279,0,2.56,0,3.84
			c-0.157,1.363,0.315,3.355-0.24,4.319c0,2.721,0,5.438,0,8.158c0.556,0.967,0.083,2.957,0.24,4.32c0,0.24,0,0.479,0,0.72
			c0.544,0.258,0.091,1.511,0.24,2.16c0.554,0.729,0.084,2.478,0.24,3.602c-0.157,1.361,0.315,3.354-0.24,4.318
			c-0.174,2.387-0.907,4.214-0.96,6.723c0.598,0.199,1.286,0.313,2.16,0.237c2.002-0.397,5.677-0.397,7.68,0
			c0.159,0,0.319,0,0.479,0c2.008,0.073,2.576,1.585,2.881,3.36c-0.047,0.927-0.008,1.769,0.24,2.4
			c0.529,0.027,0.102,1.018,0.24,1.438c0.396,0.57,0.396,2.789,0,3.36c-0.332,1.588-1.179,2.663-2.882,2.88
			c-0.079,0-0.159,0-0.239,0c-0.679,0.361-1.656,0.424-2.641,0.479c-1.386,0.057-2.65,0.229-3.84,0.48
			c-0.044,0.354-0.58,0.22-0.96,0.24c-0.411,0.549-1.832,0.088-2.64,0.237c-0.454,0.106-1.446-0.325-1.2,0.48c0,1.84,0,3.68,0,5.52
			c0.041,2.199,0.504,3.979,0.96,5.763c1.212,3.985,2.156,8.243,3.36,12.237c0.063,0.979,0.451,1.629,0.72,2.4
			c1.624,4.458,3.345,8.814,4.561,13.68c0,0.08,0,0.16,0,0.24c1.116,4.083,2.475,7.926,3.357,12.24c0,0.08,0,0.159,0,0.239
			c0.386,0.655,0.494,1.586,0.48,2.641c-0.074,2.075,0.245,3.755,0.479,5.521c0.548,0.332,0.09,1.67,0.239,2.399
			c0,0.08,0,0.16,0,0.24c0.278,0.039,0.262,0.379,0.239,0.72c0.556,0.806,0.085,2.636,0.24,3.84c0,0.642,0,1.28,0,1.92
			c1.347-0.095,2.176-0.704,3.601-0.72c0.08,0,0.159,0,0.239,0c3.236-0.844,5.999-2.161,9.84-2.399
			c0.835,0.687,1.326,1.715,1.92,2.642c0.399,3.601,0.399,8.879,0,12.479c-0.917,3.645-4.022,5.098-7.68,6
			c-0.592-0.03-1.155-0.036-1.438,0.24c-2.41,0.47-4.668,1.093-5.041,3.602c-0.362,0.678-0.58,1.5-0.479,2.641
			c0.56,1.762,0.081,4.559,0.239,6.72c-0.159,5.041,0.318,10.72-0.239,15.36c0,0.479,0,0.96,0,1.438
			c-0.159,2.563,0.317,5.759-0.239,7.92c-0.133,0.351,0.279,1.24-0.24,1.199c-0.019,1.021-0.4,1.679-0.239,2.881
			c0.399,1.523,0.399,4.716,0,6.239c-0.157,1.763,0.317,4.157-0.239,5.521c0,1.521,0,3.039,0,4.561
			c-0.145,0.497,0.296,1.577-0.24,1.681c0.06,0.422,0.413,0.547,0.961,0.479c1.039,0.081,2.515-0.273,2.879,0.479
			c1.599,0.403,3.291,0.708,5.28,0.721c0.336-0.396,2.304-0.396,2.642,0c0.078,0,0.158,0,0.238,0c1.65,1.507,1.998,4.512,0.96,6.96
			c0,0.08,0,0.159,0,0.239c0.011,1.851-0.28,3.397-0.72,4.802c0,0.079,0,0.158,0,0.238c0.074,0.874-0.038,1.563-0.24,2.16
			c2.32,0,4.642,0,6.96,0c0.16-7.521-0.318-15.681,0.24-22.801c0-0.562,0-1.12,0-1.68c0.156-1.443-0.314-3.518,0.239-4.562
			c0.041-0.358-0.085-0.886,0.24-0.961c0-0.159,0-0.319,0-0.479c0-0.801,0-1.601,0-2.399c0.15-0.808-0.311-2.229,0.238-2.64
			c0.041-0.359-0.086-0.888,0.24-0.962c0-0.158,0-0.318,0-0.479c0.15-0.729-0.308-2.066,0.239-2.399c0-0.08,0-0.158,0-0.238
			c0-0.801,0-1.602,0-2.4c0.16-2.96-0.318-6.559,0.24-9.119c0-2.561,0-5.12,0-7.681c-0.399-5.521-0.399-12.72,0-18.24
			c0-0.479,0-0.96,0-1.439c0.158-6.398-0.319-13.438,0.238-19.438c0.287-0.673,0.266-1.656,0.24-2.641
			C318.166,623.355,318.637,621.525,318.48,620.321z M304.555,519.001c0.002,0.894,0.006,1.793,0.006,2.68
			c0-0.884-0.004-1.778-0.006-2.671C304.555,519.007,304.555,519.004,304.555,519.001z"/>
				<path fill="#A4B1BD" d="M121.92,525.761c0-1.12,0-2.239,0-3.358c-0.08,0-0.16,0-0.24,0
			C121.835,523.446,121.367,525.114,121.92,525.761z"/>
				<path fill="#A4B1BD" d="M435.841,454.24c4.699-1.46,9.165-3.154,13.2-5.279c0-0.08,0-0.16,0-0.24
			c0.186-0.054,0.473-0.007,0.479-0.238c4.801-2.161,9.089-4.833,13.922-6.96c0.137-1.576-0.559-2.322-0.479-3.842
			c0-0.08,0-0.158,0-0.238c-0.362-0.68-0.424-1.656-0.479-2.642c0-0.08,0-0.16,0-0.24c-0.418-1.021-0.643-2.237-0.722-3.6
			c-0.003-0.638,0.091-1.371-0.238-1.681c-0.699-3.54-1.959-6.521-3.602-9.12c0-0.079,0-0.159,0-0.239
			c-4.959-7.594-11.599-13.507-19.047-18.612c-0.182-0.101-0.369-0.193-0.552-0.295c-0.098-0.027-0.192-0.058-0.321-0.054
			c-1-0.12-1.4-0.839-2.4-0.96c-0.191-0.127-0.39-0.25-0.72-0.24c-0.06-0.261-0.478-0.163-0.479-0.479c0-0.08,0-0.16,0-0.24
			c-0.08,0-0.16,0-0.239,0c-0.984-0.376-2.246-0.474-3.12-0.96c-1.295-0.385-2.688-0.672-4.32-0.72
			c-1.211-0.149-2.425-0.295-3.358-0.722c-2.068-0.409-4.005-0.955-6.239-1.2c-1.509-0.171-2.826-0.535-4.08-0.96
			c-0.563,0.077-0.537,0.743-0.96,0.96c0,0.24,0,0.48,0,0.72c0.524,0.619,0.214,2.443,0,3.12c-0.08,0-0.16,0-0.24,0
			c-0.271,0.768-0.486,1.592-0.479,2.64c-0.154,0.886-0.246,1.834-0.722,2.4c-0.236,0.642-0.492,1.265-0.479,2.16
			c-0.161,1.356-0.399,2.641-0.96,3.6c-0.383,1.377-0.896,2.624-0.96,4.32c-0.072,1.048-0.249,1.989-0.72,2.641
			c0,0.08,0,0.159,0,0.239c-0.175,0.945-0.3,1.94-0.722,2.641c0,0.08,0,0.16,0,0.239c-0.068,1.291-0.252,2.469-0.72,3.359
			c-0.411,1.351-0.924,2.598-0.96,4.319c-0.241,1.76-0.638,3.363-1.2,4.8c-0.274,0.283-0.271,0.851-0.24,1.44
			c-0.017,0.943-0.114,1.804-0.479,2.398c-0.335,1.104-0.366,2.515-0.479,3.841c0,0.96,0,1.92,0,2.881
			c-0.157,2.241,0.318,5.118-0.238,6.96c-0.132,0.349,0.278,1.24-0.24,1.199c0,0.079,0,0.159,0,0.238
			c-0.152,0.889,0.312,2.393-0.239,2.882c0,0.078,0,0.158,0,0.238c-0.152,0.81,0.311,2.229-0.24,2.642c0,0.08,0,0.159,0,0.238
			c-0.063,1.904-0.492,4.176,2.16,3.36c0.751-0.049,1.764,0.162,2.16-0.24c0.596-0.362,1.456-0.463,2.399-0.479
			c1.048,0.009,1.872-0.208,2.641-0.479c1.428-0.492,3.035-0.805,4.8-0.96c0.592,0.031,1.156,0.036,1.439-0.24
			c0.597-0.361,1.457-0.463,2.398-0.479c0.08,0,0.16,0,0.24,0c1.197-0.563,2.789-0.731,4.319-0.96c0.08,0,0.16,0,0.238,0
			c2.69-0.83,5.394-1.648,8.4-2.16c0.08,0,0.16,0,0.24,0c0.563-0.474,1.515-0.563,2.397-0.721
			C435.681,454.24,435.761,454.24,435.841,454.24z"/>
			</g>
		</g>
		<circle id="eye" fill="#080808" cx="319.855" cy="219.445" r="7.015" />
		<circle id="eye2" fill="#080808" cx="243.561" cy="221.408" r="7.015" />
		<g id="black_lines">
			<path fill="#080808" d="M360.961,120.64c1.143,1.337,2.418,2.542,3.6,3.84C363.697,122.863,362.224,121.857,360.961,120.64z" />
			<rect x="359.034" y="375.389" fill="#080808" width="0.001" height="0.001" />
			<polygon fill="#080808" points="359.044,375.399 359.035,375.391 359.039,375.395 	" />
			<path fill="#080808" d="M207.12,175.6c-0.555,0.885-0.083,2.796-0.24,4.08C207.435,178.795,206.963,176.884,207.12,175.6z" />
			<path fill="#080808" d="M376.801,197.92c-0.554,0.647-0.085,2.315-0.24,3.36c0.541-0.179,0.096-1.346,0.24-1.92
		c0.397-0.258,0.397-2.141,0-2.4C376.801,197.28,376.801,197.6,376.801,197.92z"/>
			<path fill="#080808" d="M258,263.68c0-2.24,0-4.48,0-6.72C257.601,258.644,257.601,261.997,258,263.68z" />
			<path fill="#080808" d="M403.2,634.001c0,0.88,0,1.76,0,2.64c0.08,0,0.16,0,0.24,0C403.289,635.833,403.75,634.412,403.2,634.001z"
			/>
			<path fill="#080808" d="M480.721,706.241c-0.146-0.574,0.304-1.741-0.238-1.92c0,0.397,0,0.8,0,1.2c0,0.72,0,1.438,0,2.158
		c0.078,0,0.158,0,0.238,0C480.721,707.201,480.721,706.722,480.721,706.241z"/>
			<path fill="#080808" d="M333.842,217.84c0.491,1.187,2.626,0.73,3.836,1.199C336.467,218.571,334.332,219.028,333.842,217.84
		c-1.671-0.33-2.29-1.711-3.842-2.16c-0.081-0.744,0.556-1.146,0-1.68c-3.021-5.058-9.225-6.935-16.319-7.92
		c-0.493-0.398-2.628-0.398-3.12,0c-2.372,0.186-4.163,0.955-6,1.68c-0.541,1.06-2.121,1.079-2.16,2.64
		c0.039-1.561,1.619-1.581,2.16-2.64c-0.08,0-0.16,0-0.24,0c-0.922,0.919-2.189,1.49-2.4,3.12c0.316,0.002,0.221,0.42,0.48,0.48
		c3.22,0.562,4.543-2.804,8.398-2.4c0.313-0.302,1.566-0.374,2.336-0.218c-0.77-0.155-2.021-0.083-2.334,0.218
		c0.959,0,1.919,0,2.878,0c5.593,0.489,10.798,2.641,13.439,5.28c-3.788-1.172-7.896-2.023-12-2.88
		c-0.568-0.398-2.787-0.398-3.357,0c0.001,0,0.001,0,0.002,0c-5.048-0.008-7.874,2.206-11.278,3.84
		c-0.362,0.758-1.299,0.943-1.2,2.16c0.002,0.958,1.46,0.46,1.92,0.96c-0.002,0-0.005,0-0.007,0c-0.457-0.5-1.915-0.001-1.918-0.96
		c-0.098-1.217,0.839-1.402,1.201-2.16c-0.56,0.163-0.645,0.797-1.201,0.96c-0.238,0.623-0.422,1.607,0.24,1.92
		c0.282,0.276,0.85,0.272,1.438,0.24c0.002,0,0.004,0,0.006,0c-0.034,0.928-0.978,0.947-1.203,1.68
		c-0.602,0.658-1.101,1.418-1.726,2.054c0.625-0.635,1.123-1.396,1.724-2.054c-0.343,0.058-0.403,0.396-0.721,0.48
		c-0.606,1.472-2.494,1.665-3.12,3.12c0.268,0.775,0.766,1.316,1.921,1.2c0.722,0,1.438,0,2.159,0
		c-0.275-0.163-0.659-0.219-1.078-0.24c0.419,0.021,0.803,0.077,1.08,0.24c1.961,0.28,2.691-0.669,3.602-1.44
		c1.135-1.186,2.392-2.25,3.601-3.36c1.038-1.522,2.472-2.648,4.08-3.6c1.493-1.564,3.744-2.179,6.715-1.681
		c0.001,0,0.002,0,0.003,0s0.001,0,0.002,0c0.839-0.039,1.535,0.065,1.919,0.48c0.001,0,0.001,0,0.002,0
		c2.579,1.101,6.592,0.767,8.64,2.4c1.426,0.575,2.582,1.417,3.359,2.64c-0.734,0.787-1.27,1.771-2.4,2.16
		c-1.787,1.251-3.883,2.197-5.76,3.359c-0.001,0-0.002,0-0.003,0.001c-0.204,0.356-0.604,0.516-1.2,0.48l-0.001,0
		c-1.918,0.241-3.072,1.245-5.277,1.199c-1.125,0.399-3.915,0.399-5.04,0c-0.33-0.011-0.526,0.113-0.722,0.24
		c-1.887,1.165,0.617,2.674,2.16,2.64c0.001,0,0.001,0,0.002,0c0.569,0.397,2.784,0.398,3.357,0.001
		c0.228-0.04,0.438-0.093,0.66-0.134c-0.279,0.04-0.561,0.069-0.837,0.091c-0.264,0.022-0.522,0.034-0.785,0.042
		c-0.006,0-0.011,0-0.017,0c-0.001,0-0.003,0-0.004,0c0.27-0.008,0.534-0.02,0.804-0.043c0.277-0.024,0.559-0.051,0.838-0.091
		c0.001,0,0.001,0,0.001,0c0.243-0.035,0.483-0.083,0.729-0.128c0.087-0.016,0.175-0.03,0.264-0.047
		c0.311-0.063,0.617-0.136,0.927-0.214l0.007-0.001l0.004-0.001c0.115-0.029,0.231-0.054,0.347-0.085
		c0.034-0.009,0.068-0.018,0.104-0.027c0.008-0.002,0.017-0.005,0.022-0.008c0,0,0.001,0,0.002,0c0.01-0.003,0.02-0.006,0.029-0.008
		c1.279-0.313,2.496-0.688,3.669-1.107c0.213-0.076,0.436-0.145,0.646-0.225c0.425-0.161,0.831-0.34,1.24-0.515
		c0.31-0.132,0.618-0.263,0.921-0.403c0.361-0.17,0.715-0.352,1.064-0.534c0.331-0.172,0.659-0.348,0.979-0.531
		c0.313-0.179,0.62-0.363,0.923-0.553c0.345-0.216,0.68-0.442,1.011-0.673c0.263-0.182,0.525-0.364,0.779-0.555
		c0.371-0.28,0.726-0.577,1.078-0.876c0.192-0.166,0.396-0.324,0.586-0.496c0.53-0.484,1.046-0.987,1.529-1.519
		c0.104-0.295,0.309-0.493,0.722-0.48c-0.055-0.187-0.201-0.278-0.478-0.24c0.278-0.039,0.428,0.053,0.479,0.24
		c0.08,0,0.16,0,0.239,0c1.228,0.147,2.125-0.037,2.78-0.46c-0.656,0.424-1.554,0.608-2.78,0.461c-0.08,0-0.16,0-0.24,0
		c-0.413-0.013-0.615,0.185-0.722,0.48c0.355-0.278,0.604-0.278,0.962,0c2.068,0.071,3.914-0.083,3.6-2.4
		C337.949,218.212,334.99,218.931,333.842,217.84z M302.4,210.64c0.224,0.176,0.363,0.437,0.479,0.72
		C302.764,211.076,302.622,210.816,302.4,210.64z M297.935,222.142c-0.179,0.174-0.361,0.345-0.566,0.493
		c-0.003,0.002-0.006,0.004-0.009,0.006C297.567,222.491,297.754,222.318,297.935,222.142z M296.16,223.36
		c0.463-0.098,0.189-0.931,0.96-0.72C296.35,222.43,296.623,223.263,296.16,223.36z M296.4,223.84
		c10.134,3.228,10.79-9.952,19.702-9.418C307.192,213.89,306.535,227.068,296.4,223.84z M314.639,211.36
		c0.715,0.501,3.514,0.417,3.363,1.107C318.151,211.777,315.352,211.861,314.639,211.36z M310.799,208.48
		c6.706-0.618,13.931,3.015,17.365,6.11C324.729,211.495,317.505,207.863,310.799,208.48z M329.28,215.2
		c0.521,0.043,0.764-0.196,0.72-0.72c-1.767-3.609-7.055-6.554-12.699-7.643c5.646,1.089,10.935,4.034,12.701,7.643
		c0.044,0.523-0.196,0.763-0.72,0.72c1.75,1.192,3.123,2.762,5.354,3.472C332.404,217.962,331.03,216.393,329.28,215.2z
		 M338.882,220.24c-0.071,0.734-0.95,1.151-1.859,1.22c0.909-0.069,1.787-0.487,1.857-1.22c-0.157,0.178-0.339,0.331-0.539,0.466
		c0.201-0.135,0.383-0.289,0.54-0.467c0-0.16,0-0.32,0-0.479l0.001,0C338.882,219.92,338.882,220.08,338.882,220.24z"/>
			<path fill="#080808" d="M252,198.88c0.98-0.78,1.86-1.66,2.64-2.64C253.66,197.02,252.78,197.9,252,198.88z" />
			<path fill="#080808" d="M229.68,227.44c0.86,1.06,1.82,2.02,2.88,2.88C231.701,229.26,230.741,228.3,229.68,227.44z" />
			<path fill="#080808" d="M360.961,269.44c3.79-0.29,3.845-4.315,5.279-6.96c0.777,0.139,1.117-0.164,1.681-0.24
		c1.985-2.974,5.24-4.68,6.239-8.64c0-0.08,0-0.16,0-0.24c0.398-0.414,0.398-2.466,0-2.88c0-0.08,0-0.16,0-0.24
		c0.102-1.701-0.187-3.015-0.479-4.32c-0.08,0-0.158,0-0.238,0c0.104,1.255,0.615,2.103,0.479,3.6c0,0.24,0,0.48,0,0.72
		c0.049,1.728,0.058,3.416-0.479,4.559c-0.001,0-0.001,0.001-0.002,0.001c0,0.08,0,0.16,0,0.24c-2.466,2.334-3.479,6.12-7.439,6.96
		c-0.104,0.457-0.518,0.603-0.479,1.2c-0.075,0.325-0.344,0.458-0.24,0.96c-0.039,0.126-0.082,0.261-0.129,0.402
		c-0.018,0.048-0.034,0.099-0.053,0.148c-0.029,0.094-0.063,0.188-0.098,0.288c-0.027,0.076-0.057,0.154-0.085,0.232
		c-0.028,0.077-0.056,0.153-0.086,0.231c-0.035,0.094-0.074,0.188-0.112,0.284c-0.023,0.063-0.05,0.126-0.075,0.189
		c-0.047,0.109-0.095,0.217-0.144,0.326c-0.021,0.05-0.044,0.1-0.067,0.15c-0.053,0.115-0.106,0.227-0.164,0.34
		c-0.021,0.044-0.045,0.088-0.064,0.131c-0.063,0.116-0.123,0.229-0.188,0.34c-0.021,0.037-0.042,0.074-0.063,0.11
		c-0.067,0.114-0.137,0.223-0.209,0.33c-0.021,0.03-0.04,0.061-0.063,0.091c-0.073,0.107-0.149,0.209-0.229,0.307
		c-0.021,0.025-0.04,0.049-0.062,0.073c-0.082,0.097-0.166,0.187-0.251,0.271c-0.02,0.02-0.038,0.038-0.059,0.056
		c-0.089,0.083-0.18,0.158-0.272,0.226c-0.019,0.014-0.035,0.026-0.057,0.039c-0.096,0.065-0.193,0.122-0.293,0.168
		c-0.018,0.008-0.036,0.015-0.054,0.022c-0.104,0.044-0.208,0.078-0.316,0.1c-0.016,0.003-0.032,0.004-0.048,0.007
		c-0.111,0.019-0.225,0.026-0.341,0.018c-0.015,0-0.027-0.004-0.04-0.005c-0.121-0.012-0.243-0.035-0.368-0.078
		c-0.008-0.002-0.017-0.007-0.024-0.01c-0.011-0.004-0.022-0.012-0.034-0.016c0.011,0.004,0.022,0.012,0.032,0.015
		s0.019,0.008,0.024,0.01c0.125,0.042,0.248,0.066,0.368,0.078c0.013,0.001,0.025,0.005,0.04,0.005c0.116,0.008,0.229,0,0.341-0.018
		c0.016-0.003,0.032-0.003,0.048-0.007c0.108-0.021,0.213-0.056,0.316-0.1c0.018-0.007,0.036-0.014,0.054-0.022
		c0.1-0.046,0.197-0.104,0.293-0.168c0.021-0.013,0.038-0.025,0.057-0.039c0.095-0.067,0.186-0.143,0.272-0.226
		c0.02-0.019,0.039-0.037,0.059-0.056c0.085-0.084,0.169-0.175,0.251-0.271c0.021-0.024,0.04-0.048,0.062-0.073
		c0.079-0.098,0.155-0.2,0.229-0.307c0.021-0.03,0.041-0.061,0.063-0.091c0.072-0.106,0.142-0.216,0.209-0.33
		c0.021-0.036,0.043-0.074,0.063-0.11c0.064-0.112,0.127-0.225,0.188-0.34c0.021-0.043,0.043-0.087,0.063-0.131
		c0.059-0.112,0.112-0.226,0.165-0.34c0.023-0.05,0.047-0.1,0.067-0.15c0.049-0.108,0.097-0.217,0.143-0.326
		c0.026-0.063,0.053-0.126,0.076-0.189c0.038-0.095,0.077-0.19,0.112-0.284c0.03-0.078,0.058-0.154,0.086-0.231
		c0.028-0.078,0.058-0.157,0.085-0.232c0.034-0.099,0.066-0.193,0.098-0.288c0.019-0.049,0.035-0.1,0.053-0.148
		c0.047-0.141,0.09-0.276,0.129-0.402c-0.104-0.501,0.165-0.635,0.238-0.96c-0.035-0.597,0.379-0.743,0.48-1.2
		c3.96-0.84,4.974-4.626,7.439-6.96c0-0.08,0-0.16,0-0.24c0.536-1.143,0.527-2.831,0.479-4.56c0-0.24,0-0.48,0-0.72
		c-0.222-0.938-0.43-1.89-0.664-2.815c0.234,0.926,0.443,1.879,0.665,2.818c0.137-1.498-0.375-2.345-0.48-3.6
		c-0.822-2.136-1.69-4.228-3.118-5.76c-0.282-0.038-0.683,0.042-0.722-0.24c0.483-0.477,0.784-1.135,1.44-1.44c0-0.08,0-0.16,0-0.24
		c0.001-0.001,0.002-0.002,0.003-0.003c0.557-0.164,0.643-0.796,1.199-0.958c0.117-0.284,0.256-0.544,0.479-0.72
		c0.659,0.858,0.727,2.311,1.438,3.117c0,0.002,0.001,0.003,0.001,0.005c0.525,1.073,0.813,2.386,1.92,2.88
		c0.001,0,0.002,0,0.003-0.001c0,0.08,0,0.159,0,0.239c2.56-0.352,0.902-3.567,0.479-5.04c-0.014-0.146-0.034-0.284-0.238-0.24
		c0.069,1.395,1.729,3.778,0.481,4.729c1.242-0.952-0.417-3.333-0.484-4.727c-0.435-1.247-0.942-2.417-1.921-3.12
		c0.413-0.786,1.268-1.134,1.681-1.92c0.756-1.725,2.86-2.099,2.881-4.56c0.076-0.555-0.69-0.268-0.718-0.722
		c-0.225,0.006-0.435,0.044-0.639,0.096c-0.042,0.01-0.084,0.02-0.125,0.032c-0.188,0.056-0.37,0.129-0.546,0.217l0.001,0
		c0.177-0.089,0.357-0.162,0.547-0.218c0.041-0.012,0.083-0.021,0.125-0.032c0.204-0.052,0.414-0.089,0.639-0.096
		c0.025,0.454,0.794,0.167,0.721,0.72c-0.019,2.461-2.125,2.835-2.881,4.56c0.343-0.058,0.405-0.396,0.721-0.48
		c0.596-1.645,2.687-1.795,2.399-4.32c-0.115-0.123-0.229-0.223-0.341-0.313c-0.033-0.027-0.066-0.05-0.103-0.074
		c-0.08-0.059-0.159-0.11-0.238-0.154c-0.032-0.019-0.065-0.038-0.102-0.054c-0.089-0.042-0.176-0.075-0.263-0.099
		c-0.021-0.005-0.037-0.014-0.058-0.019c-0.104-0.024-0.206-0.036-0.308-0.036c-0.026,0-0.052,0.003-0.078,0.005
		c-0.076,0.004-0.15,0.013-0.225,0.028c-0.031,0.007-0.063,0.015-0.097,0.023c-0.07,0.021-0.144,0.046-0.215,0.077
		c-0.023,0.011-0.051,0.02-0.076,0.032c-0.093,0.045-0.185,0.098-0.273,0.158c-0.021,0.014-0.041,0.031-0.063,0.045
		c-0.069,0.05-0.142,0.104-0.211,0.162c-0.031,0.027-0.063,0.055-0.095,0.083c-0.06,0.053-0.118,0.108-0.176,0.166
		c-0.03,0.03-0.063,0.06-0.091,0.091c-0.072,0.075-0.145,0.152-0.215,0.232c-0.026,0.032-0.056,0.063-0.084,0.097
		c-0.063,0.076-0.129,0.154-0.19,0.233c-0.038,0.047-0.076,0.096-0.113,0.144c-0.042,0.055-0.085,0.109-0.127,0.164
		c-0.042,0.056-0.085,0.112-0.127,0.168c-0.005,0.006-0.009,0.012-0.013,0.018c0.107-0.122,0.224-0.241,0.34-0.356
		c0.051-0.047,0.101-0.093,0.149-0.139c-0.05,0.046-0.1,0.093-0.147,0.141c-0.117,0.116-0.23,0.235-0.342,0.358l-0.001,0.001
		c-0.005,0.007-0.01,0.014-0.015,0.021c-0.277,0.307-0.536,0.627-0.789,0.941c-0.203,0.255-0.402,0.508-0.599,0.738
		c-0.62,0.734-1.248,1.498-1.873,2.26c-0.009,0.009-0.017,0.018-0.024,0.027c-0.55,0.669-1.103,1.353-1.655,2.038
		c0.551-0.684,1.103-1.366,1.65-2.033c-0.006,0.007-0.012,0.014-0.019,0.021c-1.413,1.608-2.87,3.23-4.188,4.78
		c-0.03,0.038-0.062,0.076-0.095,0.116c-0.341,0.4-0.668,0.795-0.983,1.184c-0.104,0.127-0.204,0.252-0.307,0.378
		c-0.255,0.317-0.497,0.629-0.729,0.936c-0.097,0.125-0.192,0.25-0.283,0.373c-0.306,0.411-0.595,0.814-0.853,1.201
		c-0.224,0.656-0.734,1.023-0.96,1.679c0,0-0.001,0.001-0.001,0.002c-0.78,1.937-1.545,3.895-2.158,6c0,0,0,0,0,0
		c-0.275,0.042-0.26,0.379-0.237,0.719c0.208,2.355,2.27,3.999,2.641,6.24c0.804,4.855-2.361,8.691-3.601,12.24
		C360.586,268.854,360.826,269.095,360.961,269.44z M366.963,258.88c0-0.004-0.001-0.008-0.001-0.013
		c0.001-0.036,0.013-0.061,0.021-0.089C366.975,258.81,366.963,258.839,366.963,258.88z M378.239,227.442
		c1.206,2.987-2.924,4.521-4.08,6.72c0-0.001-0.001-0.001-0.001-0.002C375.313,231.961,379.441,230.427,378.239,227.442z
		 M372.957,236.079c1.512,1.768,1.02,5.541,3.6,6.24c0.883-2.278-0.464-4.916-1.653-6.923c1.19,2.008,2.538,4.647,1.654,6.927
		c-2.581-0.699-2.088-4.472-3.6-6.24c-1.684,0.875-2.565,2.553-3.6,4.079c-0.001,0-0.001-0.001-0.002-0.001
		C370.389,238.633,371.272,236.954,372.957,236.079z M368.402,241.6c0.915,3.253,4.001,7.294,3.12,11.52
		c-0.485,2.327-2.954,3.573-4.32,5.28c-0.017,0.074-0.057,0.125-0.099,0.175c0.041-0.05,0.08-0.101,0.097-0.175
		c1.366-1.707,3.835-2.953,4.318-5.28c0.883-4.226-2.202-8.266-3.118-11.519C368.401,241.601,368.401,241.601,368.402,241.6z
		 M367.286,238.761c0.016-0.02,0.031-0.04,0.047-0.06c-0.016,0.021-0.032,0.041-0.048,0.061c-0.218,0.278-0.435,0.557-0.652,0.835
		C366.851,239.318,367.067,239.039,367.286,238.761z M364.801,246.64c1.021-1.538,2.05-3.069,3.12-4.56
		c1.542,2.378,2.973,4.868,3.12,8.64c0.149,2.15-0.491,3.509-1.44,4.562c-0.642,1.199-1.6,2.081-2.64,2.88
		C366.879,253.507,364.118,251.416,364.801,246.64z"/>
			<path fill="#080808" d="M307.921,233.2c2.932,0.83,9.136,2.079,10.56-0.96c-2.853-1.229-7.561-0.601-10.802-1.44
		C306.487,231.089,306.946,233.05,307.921,233.2z M307.44,231.76c-0.039-0.279,0.053-0.427,0.238-0.479
		C307.493,231.335,307.401,231.482,307.44,231.76z M317.541,232.78c-2.064,1.867-8.007,1.395-9.783-0.57
		C309.534,234.175,315.473,234.646,317.541,232.78z"/>
			<path fill="#080808" d="M237.12,235.12c-0.715-0.854-2.502,0.517-1.68,1.2C235.334,235.254,236.053,235.013,237.12,235.12z" />
			<path fill="#080808" d="M369.601,239.92c0.039,0.281,0.438,0.202,0.722,0.24c0.045-0.204-0.096-0.226-0.24-0.24
		c0.158-0.643,0.688-0.913,0.961-1.44C370.385,238.785,370.084,239.443,369.601,239.92z"/>
			<path fill="#080808" d="M366.482,269.681c-3.478-0.987-5.83,4.088-5.765,7.195c0,0.001-0.001,0.002-0.001,0.003
		c0,0.08,0,0.16,0,0.24c0,0.32,0,0.64,0,0.96c0,0,0.001,0.001,0.001,0.002c-0.079,0-0.158,0-0.235,0
		c-0.088,1.126,0.438,1.641,0.96,2.16c1.604-0.129,1.552-0.455,3.358-0.24C367.284,278.988,370.136,270.719,366.482,269.681z
		 M365.375,269.971c-2.615,0.674-3.717,2.86-4.355,5.508C361.657,272.83,362.76,270.644,365.375,269.971z M361.442,280.001
		c-0.06-0.343-0.396-0.405-0.479-0.719c0.001,0,0.002,0.001,0.003,0.001c0.085,0.312,0.421,0.376,0.479,0.718
		C361.443,280.001,361.443,280.001,361.442,280.001z M362.404,279.76c-0.208,0.048-0.376,0.055-0.517,0.035
		c0.14,0.019,0.306,0.012,0.513-0.036c0.651-0.579-0.592-2.165,0.238-2.88c0-0.08,0-0.16,0-0.24c0.442-1.958,0.891-3.911,2.88-4.32
		c0.054,1.412,0.012,2.73-0.72,3.36c-0.054,0.188-0.007,0.474-0.24,0.48c0,0.001,0,0.001,0,0.002
		c-0.334,0.065-0.271,0.525-0.477,0.718c-0.378,0.582-0.858,1.062-1.439,1.44c0-0.24,0-0.48,0-0.72c0-0.16,0-0.32,0-0.48
		c0-0.08,0-0.16,0-0.24C361.814,277.595,363.056,279.181,362.404,279.76z M367.202,276.161c0,0.002-0.001,0.003-0.001,0.005
		c-0.989,1.361-1.717,4.165-4.32,3.114c0,0,0,0,0-0.001c2.604,1.049,3.331-1.761,4.317-3.12c0.146-1.533,0.694-2.664,0.479-4.56
		c-0.198-0.401-0.459-0.74-0.78-1.019c0.321,0.279,0.583,0.619,0.782,1.02c-0.021-0.694-0.401-1.026-0.799-1.345
		c0.397,0.318,0.78,0.651,0.801,1.346C367.897,273.497,367.348,274.628,367.202,276.161z"/>
			<path fill="#080808" d="M370.32,279.521c-0.9,0.699-1.7,1.5-2.399,2.4C368.821,281.221,369.621,280.421,370.32,279.521z" />
			<path fill="#080808" d="M345.895,362.098c0.151,0.23,0.304,0.462,0.462,0.682c0.133,0.186,0.27,0.364,0.405,0.542
		c0.131,0.171,0.264,0.344,0.396,0.51c0.156,0.192,0.313,0.38,0.477,0.567c0.064,0.075,0.126,0.153,0.19,0.228
		c0.003,0.002,0.005,0.005,0.007,0.007c-0.021-0.023-0.034-0.047-0.053-0.07c-1.046-1.308-2.021-2.686-2.898-4.164
		c0.229,0.433,0.467,0.845,0.714,1.243C345.693,361.8,345.795,361.947,345.895,362.098z"/>
			<path fill="#080808" d="M365.037,382.481c0.083-0.001,0.163-0.001,0.243-0.001c0.021-0.182,0.025-0.355,0.023-0.524
		c-0.001-0.054-0.008-0.104-0.011-0.157c-0.007-0.113-0.016-0.224-0.032-0.331c-0.01-0.058-0.021-0.114-0.034-0.17
		c-0.021-0.098-0.044-0.194-0.071-0.288c-0.019-0.054-0.034-0.107-0.056-0.16c-0.032-0.093-0.071-0.184-0.113-0.273
		c-0.021-0.046-0.042-0.093-0.066-0.139c-0.052-0.099-0.109-0.195-0.171-0.29c-0.021-0.031-0.037-0.062-0.058-0.092
		c-0.174-0.251-0.375-0.486-0.602-0.709c-0.023-0.024-0.052-0.048-0.076-0.072c-0.091-0.087-0.184-0.172-0.278-0.256
		c-0.031-0.027-0.063-0.053-0.097-0.08c-0.098-0.082-0.195-0.164-0.301-0.243c-0.026-0.021-0.055-0.042-0.082-0.063
		c-0.121-0.092-0.244-0.184-0.369-0.273c-0.007-0.005-0.014-0.01-0.021-0.015c-0.521-0.372-1.075-0.729-1.61-1.101
		c-0.005-0.003-0.011-0.007-0.016-0.011h-0.001c1.118,0.938,2.229,1.881,3.317,2.848
		C364.868,380.731,365.145,381.414,365.037,382.481z"/>
			<rect x="361.237" y="377.231" fill="#080808" width="0.004" height="0.001" />
			<path fill="#080808" d="M359.196,375.543c0.327,0.312,0.669,0.625,1.044,0.938c0.313,0.263,0.65,0.509,0.997,0.75
		c-0.729-0.61-1.46-1.22-2.189-1.829C359.095,375.449,359.148,375.496,359.196,375.543z"/>
			<path fill="#080808" d="M367.904,412.125c0.519-0.681,1.024-1.374,1.523-2.074c0.436-0.606,0.859-1.224,1.282-1.843
		c0.001-0.002,0.002-0.003,0.003-0.005c1.062-1.614,2.127-3.228,3.206-4.845c0-0.08,0-0.16,0-0.24
		c0.806-1.515,1.542-3.099,2.64-4.32c0.016-0.307,0.31-0.333,0.24-0.72c0.042-0.677,0.441-0.997,0.481-1.678
		c-3.351,6.65-7.057,12.943-11.762,18.238c0,0.08,0,0.16,0,0.24c0.003,0.24-0.192,0.285-0.238,0.479
		c0.692-0.791,1.355-1.614,2.011-2.446C367.497,412.651,367.702,412.39,367.904,412.125z"/>
			<path fill="#080808" d="M276.96,431.201c0.699,0.898,1.5,1.7,2.4,2.397C278.661,432.7,277.861,431.9,276.96,431.201z" />
			<path fill="#080808" d="M466.801,457.841c-0.084,0.014-0.144,0.003-0.186-0.026c0.042,0.028,0.101,0.038,0.184,0.024
		c-0.057-0.981-0.117-1.963-0.479-2.64c0,0.001,0,0.001,0,0.002c-0.253-2.707-0.654-5.268-1.199-7.683
		c-0.019-0.941-0.116-1.804-0.48-2.397c0-0.08,0-0.16,0-0.24c-0.1-0.015-0.203-0.016-0.305-0.021c0.079,0.262,0,0.681,0.066,0.956
		c0.003,0.011,0.006,0.023,0.01,0.035c0.014,0.046,0.033,0.082,0.058,0.115c0.009,0.015,0.018,0.025,0.026,0.036
		c0.037,0.037,0.082,0.066,0.145,0.078c0,0.08,0,0.159,0,0.239c0.49,3.431,0.899,6.94,1.68,10.08c0,0.08,0,0.158,0,0.238
		c0.084-0.014,0.145-0.002,0.186,0.026c-0.041-0.027-0.101-0.038-0.184-0.024c0.043,0.979,0.699,5.331,0.719,2.64
		C467.072,458.689,467.077,458.124,466.801,457.841z M464.858,447.362c-0.049-0.231,0.093-0.653,0.045-0.885
		C464.952,446.709,464.81,447.131,464.858,447.362z"/>
			<path fill="#080808" d="M462.562,445.065c-0.324,0.088-0.653,0.197-0.985,0.328c-0.021,0.01-0.043,0.019-0.064,0.025
		c-1.035,0.413-2.088,1-3.097,1.6c-0.01,0.008-0.021,0.013-0.028,0.019c-0.358,0.215-0.714,0.43-1.061,0.639
		c-0.286,0.174-0.566,0.342-0.839,0.503c-0.04,0.023-0.079,0.045-0.118,0.068c-0.291,0.17-0.575,0.332-0.847,0.476
		c2.699-1.281,5.21-2.75,8.084-3.856c-0.321,0.03-0.648,0.095-0.98,0.182C462.605,445.053,462.583,445.058,462.562,445.065z"/>
			<path fill="#080808" d="M471.841,676.721c-0.033,0.994,0.066,1.854,0.479,2.4C472.198,678.283,472.213,677.308,471.841,676.721z" />
			<path fill="#080808" d="M477.121,723.281c-2.641,0-5.28,0-7.92,0c-0.025-0.614-0.137-1.146-0.48-1.44
		c-0.174,0.734,0.414,0.706,0.24,1.44c2.256,0.544,5.508,0.092,8.16,0.24c0.557-1.363,0.082-3.758,0.239-5.521
		C476.802,719.284,477.278,721.599,477.121,723.281z"/>
			<path fill="#080808" d="M305.761,526.961c0,0.08,0,0.16,0,0.24c0,0.479,0,0.96,0,1.438c0,0.48,0,0.96,0,1.44
		c0.552-0.566,0.087-2.152,0.239-3.118c0.202-0.918,0.313-1.927,0.24-3.12c0.487-1.991,0.506-4.455,0.479-6.96
		c0-0.24,0-0.479,0-0.72c0-1.36,0-2.721,0-4.08c-0.411,2.149-0.271,4.85-0.479,7.2c0,0.001,0,0.001,0,0.002
		c-0.547,0.33-0.089,1.668-0.24,2.397C306.132,523.654,305.576,524.939,305.761,526.961z"/>
			<path fill="#080808" d="M300.96,100.24c6.304,0.097,13.032-0.232,18.961,0.24c0.107-0.668-1.309,0.188-1.2-0.48
		c-5.92,0-11.84,0-17.761,0c-0.88,0-1.76,0-2.64,0c-1.28,0-2.562,0-3.84,0c0,0.08,0,0.16,0,0.24c-0.521,0.439-1.967-0.045-2.4,0.479
		c2.679-0.361,6.086,0.006,8.641-0.479C300.802,100.24,300.882,100.24,300.96,100.24z"/>
			<path fill="#080808" d="M402.721,443.201c0-0.961,0-1.921,0-2.881c-0.551,0.487-0.087,1.991-0.238,2.881c0,0.88,0,1.76,0,2.64
		c0,1.439,0,2.88,0,4.32C403.039,448.319,402.563,445.442,402.721,443.201z"/>
			<path fill="#080808" d="M117.84,570.401c0.802-4.079,1.506-8.255,1.68-12.96c-0.564,4.235-1.208,8.391-1.68,12.72
		c-0.281,0.038-0.202,0.438-0.24,0.72c0,1.2,0,2.4,0,3.6c0.08,0,0.16,0,0.24,0C117.84,573.121,117.84,571.761,117.84,570.401z"/>
			<path fill="#080808" d="M318.48,618.881c0,0.479,0,0.959,0,1.438c0.078,0,0.158,0,0.238,0c-0.156-1.521,0.318-3.675-0.238-4.798
		c0-0.479,0-0.961,0-1.438c-0.08,0-0.16,0-0.24,0C318.397,615.604,317.924,617.758,318.48,618.881z"/>
			<path fill="#080808" d="M318.24,646.241c0.08,0,0.16,0,0.24,0c0-0.642,0-1.28,0-1.92c0-5.84,0-11.683,0-17.521
		C317.921,632.802,318.4,639.841,318.24,646.241z"/>
			<path fill="#080808" d="M409.2,712.72c0.557-1.042,0.083-3.116,0.24-4.559c-0.08,0-0.16,0-0.24,0c0,1.36,0,2.72,0,4.08
		c0,0.001,0,0.001,0,0.002c-0.079,0-0.159,0-0.239,0c0,0.238,0,0.479,0,0.718c0,0.561,0,1.12,0,1.681c0.001,0,0.001,0,0.002,0
		c0,2.317,0,4.638,0,6.953c0.068-0.922,0.189-1.888,0.284-2.854c0.011-0.107,0.021-0.215,0.03-0.322
		c0.013-0.146,0.024-0.294,0.035-0.44c0.021-0.252,0.035-0.504,0.048-0.753c0.003-0.074,0.003-0.15,0.005-0.229
		c0.006-0.19,0.009-0.38,0.007-0.567c-0.001-0.092-0.004-0.183-0.007-0.272c-0.006-0.181-0.019-0.355-0.03-0.531
		c-0.009-0.085-0.015-0.167-0.022-0.247c-0.026-0.251-0.063-0.498-0.11-0.737c-0.001,0-0.001,0-0.002,0c0-0.641,0-1.278,0-1.92
		C409.2,712.721,409.2,712.721,409.2,712.72z"/>
			<path fill="#080808" d="M301.92,174.4c-0.98,0.78-1.859,1.66-2.641,2.64C300.259,176.261,301.141,175.38,301.92,174.4z" />
			<path fill="#080808" d="M301.92,362.32c-0.795,0.084-1.041,0.719-1.439,1.2C301.275,363.436,301.521,362.802,301.92,362.32z" />
			<path fill="#080808" d="M308.4,356.8c-1.906,1.374-3.641,2.92-5.28,4.56C305.104,360.064,306.897,358.578,308.4,356.8z" />
			<path fill="#080808" d="M308.641,356.8c0.9-0.699,1.7-1.499,2.399-2.4C310.14,355.1,309.34,355.9,308.641,356.8z" />
			<path fill="#080808" d="M315.84,349.36c-0.08,0-0.16,0-0.24,0c-0.002,0.002-0.004,0.004-0.006,0.006
		c-1.455,1.257-2.809,2.612-4.065,4.067c-0.002,0.002-0.005,0.004-0.007,0.006c0,0.08,0,0.16,0,0.24
		C313.06,352.34,314.5,350.9,315.84,349.36z"/>
			<path fill="#080808" d="M329.04,335.681c-1.667,1.453-3.052,3.188-4.319,5.04C326.351,339.231,327.753,337.513,329.04,335.681z" />
			<path fill="#080808" d="M331.2,333.041c-0.48,0.399-1.115,0.645-1.2,1.44C330.481,334.082,331.116,333.836,331.2,333.041z" />
			<path fill="#080808" d="M359.761,425.2c0.906-1.976,2.09-3.669,3.12-5.521C361.623,421.303,360.386,422.946,359.761,425.2z" />
			<path fill="#080808" d="M245.521,424.24c0.158-0.877-0.52-0.92-0.48-1.682c-4.792-4.996-9.224-10.954-12.48-17.04
		c-0.145-0.27-0.271-0.546-0.389-0.825c-0.035-0.083-0.065-0.167-0.098-0.251c-0.082-0.208-0.163-0.417-0.237-0.629
		c-0.023-0.068-0.046-0.139-0.069-0.205c-0.218-0.646-0.416-1.296-0.641-1.938c-0.02-0.059-0.042-0.117-0.063-0.175
		c-0.075-0.209-0.155-0.418-0.239-0.624c-0.032-0.078-0.068-0.155-0.102-0.232c-0.084-0.19-0.17-0.384-0.267-0.569
		c-0.041-0.079-0.09-0.156-0.134-0.234c-0.102-0.183-0.204-0.367-0.323-0.544l-0.001-0.001c2.928,9.923,8.927,16.775,14.802,23.753
		c0.045,0.51,1.034,2.105,0.721,1.438C245.521,424.4,245.521,424.32,245.521,424.24z"/>
			<path fill="#080808" d="M249.12,430.961c0.78,0.98,1.66,1.86,2.64,2.64C250.981,432.622,250.1,431.742,249.12,430.961z" />
			<path fill="#080808" d="M412.081,399.281c0.214-0.676,0.524-2.501,0-3.12c0.14,1.26-0.424,1.816-0.24,3.12
		C411.921,399.281,412.001,399.281,412.081,399.281z"/>
			<path fill="#080808" d="M170.88,727.602c-14.24,0-28.48,0-42.72,0c-7.562,0.045-14.536-0.086-21.6,0c-1.12,0-2.24,0-3.36,0
		c4.8,0.562,10.64,0.08,15.84,0.239c3.04,0,6.08,0,9.12,0c14.24,0,28.48,0,42.72,0c0.48,0,0.96,0,1.44,0
		c1.054,0.017,1.983-0.096,2.64-0.479C173.341,727.182,172.466,727.747,170.88,727.602z"/>
			<path fill="#080808" d="M192,729.281c-1.092-0.348-2.536-0.344-2.88-1.44c0.206,0.914,0.857,1.384,1.92,1.44c0,0.08,0,0.16,0,0.24
		c7.36,0,14.72,0,22.08,0c12.8,0,25.6,0,38.4,0c0-0.08,0-0.16,0-0.24c-12.8,0-25.6,0-38.4,0
		C206.08,729.281,199.04,729.281,192,729.281z"/>
			<path fill="#080808" d="M377.761,178c-0.148-0.729,0.309-2.067-0.239-2.4C377.671,176.33,377.213,177.667,377.761,178z" />
			<path fill="#080808" d="M377.761,181.6c0,0.72,0,1.44,0,2.16c0,0.16,0,0.32,0,0.48c0,1.12,0,2.24,0,3.36
		c0.552-0.647,0.085-2.315,0.24-3.36c0-0.32,0-0.64,0-0.96v0c0.45-0.819,0.328-5.545,0-4.8c-0.08,0-0.16,0-0.24,0
		C377.761,179.52,377.761,180.56,377.761,181.6z"/>
			<path fill="#080808" d="M376.32,204.4c0.121-0.04,0.188-0.135,0.228-0.257C376.509,204.265,376.441,204.36,376.32,204.4
		c0,0.24,0,0.48,0,0.72c-0.552,0.568-0.086,2.154-0.24,3.12c0,0.16,0,0.32,0,0.479c0.038,0.283-0.041,0.682,0.24,0.721
		c0.128-2.115,0.757-5.001,0.238-6.96c0-0.32,0-0.64,0-0.96C376.01,202.009,376.474,203.513,376.32,204.4z"/>
			<path fill="#080808" d="M383.521,233.92c-0.153-0.887,0.311-2.391-0.24-2.88C383.434,231.927,382.97,233.431,383.521,233.92z" />
			<path fill="#080808" d="M383.76,234.64c-0.079,0-0.159,0-0.238,0c0,1.6,0,3.2,0,4.8c0,0.16,0,0.32,0,0.48
		c-0.423,0.938-0.521,2.201-0.962,3.12c0.628-0.573,0.813-1.589,0.962-2.64c0.396-0.004,0.136-0.666,0.479-0.72
		C383.924,238.75,384.199,232.848,383.76,234.64c-0.087,0.852,0.019,1.898,0.035,2.854
		C383.778,236.538,383.672,235.492,383.76,234.64z"/>
			<path fill="#080808" d="M356.639,281.921c-0.547,0.41-0.086,1.831-0.238,2.64C356.95,284.15,356.488,282.729,356.639,281.921z" />
			<path fill="#080808" d="M298.32,343.601c-1.2,0-2.4,0-3.6,0C295.37,343.999,297.671,343.999,298.32,343.601z" />
			<path fill="#080808" d="M379.921,384.161c-0.556,0.964-0.084,2.957-0.24,4.32C380.236,387.516,379.764,385.524,379.921,384.161z" />
			<path fill="#080808" d="M310.559,445.121c-0.078,0-0.158,0-0.238,0c0,0.08,0,0.159,0,0.239c0,0.88,0,1.761,0,2.641
		C310.871,447.512,310.407,446.008,310.559,445.121z"/>
			<path fill="#080808" d="M402.48,450.161c0-1.44,0-2.881,0-4.32c-0.557,1.362-0.082,3.758-0.24,5.52
		C402.761,451.401,402.348,450.508,402.48,450.161z"/>
			<path fill="#080808" d="M402.24,451.599c-0.551,0.491-0.087,1.995-0.239,2.882C402.552,453.991,402.088,452.487,402.24,451.599z" />
			<path fill="#080808" d="M402.001,454.719c-0.55,0.413-0.089,1.834-0.24,2.642C402.31,456.95,401.849,455.528,402.001,454.719z" />
			<path fill="#080808" d="M308.641,461.921c0.547-0.333,0.091-1.671,0.24-2.398c-0.146,0.012-0.284,0.034-0.24,0.238
		C308.641,460.482,308.641,461.201,308.641,461.921z"/>
			<path fill="#080808" d="M467.761,495.521c0-3.279,0-6.562,0-9.842c-0.559,2.723-0.081,6.479-0.239,9.603
		C467.477,495.485,467.614,495.507,467.761,495.521z"/>
			<path fill="#080808" d="M468.241,502.241c-0.157-1.443,0.313-3.519-0.24-4.563C468.158,499.124,467.685,501.197,468.241,502.241z"
			/>
			<path fill="#080808" d="M306.96,511.841c0-0.24,0-0.479,0-0.72c0.548-0.333,0.09-1.671,0.24-2.4c0-0.238,0-0.479,0-0.72
		c0-0.001,0-0.001,0-0.002c0.55-0.409,0.088-1.829,0.24-2.638c0.001,0,0.001,0,0.002,0c0.249-0.631,0.285-1.475,0.238-2.399
		c0-0.001,0-0.001,0-0.002c0.648-1.831,0.451-4.508,0.479-6.959c0-0.24,0-0.479,0-0.72c0.56-2.804,0.081-6.642,0.24-9.841
		c-0.209,0.451-0.272,1.048-0.279,1.701c0.007-0.654,0.07-1.25,0.279-1.701c0-0.802,0-1.602,0-2.399
		c0.56-6.4,0.08-13.84,0.238-20.641c0-0.159,0-0.319,0-0.479c0-0.72,0-1.438,0-2.16c-0.548,0.411-0.086,1.832-0.238,2.64
		c0,0.08,0,0.16,0,0.24c-0.58,8.46,0.037,18.117-0.479,26.641c-0.56,2.161-0.081,5.358-0.24,7.92c0,0.239,0,0.479,0,0.72
		c0,0.64,0,1.279,0,1.919v0.001c0,0.561,0,1.12,0,1.681c-0.001,0-0.001,0-0.002,0c0-0.562,0-1.12,0-1.682c0-0.64,0-1.28,0-1.92
		c-0.552,0.727-0.083,2.477-0.238,3.602c0.001,0,0.001,0,0.002,0c0.114,1.477-0.429,2.293-0.24,3.839c-0.001,0-0.001,0-0.002,0
		c0,0.08,0,0.158,0,0.238c0,0.001,0,0.001,0,0.002c-0.383,1.857-0.477,4.007-0.479,6.24c0,0.08,0,0.16,0,0.24c0,1.359,0,2.72,0,4.08
		C307.278,515.196,306.804,513.204,306.96,511.841z M307.896,497.453c-0.038,0.717,0.2,1.711-0.065,2.202
		C308.095,499.163,307.857,498.17,307.896,497.453z"/>
			<path fill="#080808" d="M304.801,553.361c0-4.32,0-8.643,0-12.961c-0.326,0.073-0.199,0.602-0.24,0.961
		c-0.398,2.642-0.398,6.958,0,9.6C304.711,551.69,304.253,553.028,304.801,553.361z"/>
			<path fill="#080808" d="M304.801,554.081c0.15,0.729-0.308,2.067,0.239,2.399C304.891,555.752,305.348,554.414,304.801,554.081z" />
			<path fill="#080808" d="M318.24,611.681c0,0.803,0,1.603,0,2.4c0.08,0,0.16,0,0.24,0
		C318.33,613.352,318.788,612.014,318.24,611.681z"/>
			<path fill="#080808" d="M321.841,100.96c0.891,0.471,1.832,0.888,3.12,0.96C324.119,101.401,322.959,101.202,321.841,100.96z" />
			<path fill="#080808" d="M326.4,102.4c1.381,0.62,2.778,1.22,4.561,1.44C329.577,103.223,328.023,102.777,326.4,102.4z" />
			<path fill="#080808" d="M335.761,105.52c1.218,0.702,2.504,1.336,4.08,1.68C338.548,106.573,337.335,105.866,335.761,105.52z" />
			<path fill="#080808" d="M341.04,107.68c0.852,0.588,1.87,1.01,3.12,1.2C343.214,108.386,342.276,107.884,341.04,107.68z" />
			<path fill="#080808" d="M364.801,125.44c0.861-0.141,1.161,0.279,1.682,0.48c0.61,1.228,1.155,2.523,1.92,3.6
		c-0.484-1.436-1.177-2.665-1.92-3.84c-0.51-0.532-1.842-0.24-1.922-1.2c-1.182-1.298-2.457-2.502-3.6-3.84
		c-3.563-5.397-9.51-8.41-15.601-11.28C353.517,113.044,359.866,118.534,364.801,125.44z"/>
			<path fill="#080808" d="M369.601,132.64c-0.319-1.039-0.521-2.199-1.198-2.88C368.813,130.708,368.964,131.917,369.601,132.64z" />
			<path fill="#080808" d="M370.801,136.96c0.173,0.947,0.297,1.942,0.722,2.64C371.448,138.553,371.271,137.609,370.801,136.96z" />
			<path fill="#080808" d="M371.761,141.28c0.085,1.115,0.31,2.092,0.722,2.88C372.396,143.045,372.173,142.068,371.761,141.28z" />
			<path fill="#080808" d="M373.921,152.56c0.017,0.943,0.116,1.804,0.479,2.4C374.384,154.017,374.284,153.156,373.921,152.56z" />
			<path fill="#080808" d="M374.881,157.84c0.09,1.91,0.47,3.529,0.96,5.04C375.679,161.042,375.32,159.4,374.881,157.84z" />
			<path fill="#080808" d="M378.24,217.6c-0.457-2.102-1.084-4.035-2.399-5.28C376.944,213.776,377.436,215.846,378.24,217.6z" />
			<path fill="#080808" d="M378.297,218.514c0.016,0.118,0.037,0.229,0.061,0.341c0.016,0.085,0.028,0.172,0.049,0.254
		c0.032,0.147,0.07,0.289,0.113,0.427c0.012,0.033,0.021,0.066,0.03,0.098c0.052,0.16,0.108,0.315,0.171,0.465
		s0.128,0.297,0.201,0.439c0.552,0.183,0.788,0.68,1.131,1.072c-0.345-0.39-0.58-0.889-1.131-1.072h-0.001
		c0.504,0.988,1.215,1.771,1.89,2.589c0.066,0.081,0.133,0.163,0.196,0.244c0.064,0.08,0.13,0.159,0.192,0.24
		c0.137,0.176,0.268,0.357,0.394,0.543c0.031,0.046,0.063,0.094,0.091,0.141c0.104,0.155,0.197,0.315,0.288,0.481
		c0.029,0.052,0.061,0.105,0.086,0.159c0.11,0.213,0.215,0.432,0.303,0.667c0.001,0.001,0.001,0.002,0.002,0.004
		c-0.456-1.425-1.203-2.561-1.96-3.685c-0.457-1.143-1.603-1.6-1.682-3.12c-0.65,0.172,0.172-1.132-0.479-0.96
		C378.248,218.076,378.269,218.298,378.297,218.514z"/>
			<path fill="#080808" d="M346.08,353.92c0.047,2.034,0.264,3.897,0.96,5.28C346.64,357.521,346.521,355.561,346.08,353.92z" />
			<path fill="#080808" d="M347.985,360.954c0.015,0.021,0.027,0.043,0.044,0.064c-0.019-0.039-0.028-0.083-0.028-0.138
		c-0.052-0.025-0.093-0.061-0.134-0.097C347.906,360.841,347.945,360.897,347.985,360.954z"/>
			<path fill="#080808" d="M347.04,359.44c0.185,0.338,0.38,0.664,0.583,0.983C347.466,360.059,347.392,359.611,347.04,359.44z" />
			<path fill="#080808" d="M358.807,371.199c-0.01-0.007-0.018-0.016-0.026-0.024c-0.659-1.006-1.729-1.601-2.378-2.614
		c-0.235,0.001-0.285-0.185-0.47-0.236c-0.002-0.002-0.004-0.004-0.006-0.006c-0.313-0.084-0.378-0.422-0.721-0.48
		c-0.007-0.005-0.012-0.011-0.019-0.016c-0.417-0.772-1.258-1.122-1.669-1.903c-0.336-0.056-0.403-0.378-0.704-0.472
		c-0.002-0.003-0.005-0.006-0.008-0.009c-0.603-0.442-1.36-0.724-1.687-1.439c-0.194-0.045-0.239-0.241-0.479-0.24
		c-0.005-0.003-0.008-0.008-0.013-0.011c-0.674-0.867-1.446-1.639-2.31-2.319c-0.042-0.058-0.085-0.117-0.127-0.175
		c0.036,0.053,0.071,0.109,0.109,0.162c0.031,0.042,0.064,0.082,0.098,0.124c0.005,0.006,0.011,0.011,0.016,0.017
		c0.009,0.011,0.019,0.02,0.027,0.03c0.388,0.518,0.8,1.013,1.232,1.49c0.006,0.007,0.012,0.014,0.021,0.021
		c0.433,0.477,0.884,0.934,1.348,1.379c0.034,0.033,0.068,0.066,0.103,0.099c0.468,0.443,0.942,0.876,1.429,1.3
		c0.004,0.004,0.009,0.007,0.013,0.011c1.035,0.904,2.102,1.777,3.152,2.666c0.423,0.357,0.841,0.72,1.255,1.086
		c0.098,0.086,0.192,0.173,0.291,0.26c0.432,0.389,0.854,0.785,1.269,1.192c0.065,0.065,0.129,0.132,0.194,0.197
		c0.434,0.437,0.855,0.882,1.26,1.351c1.448-0.554,2.704,0.051,3.84,0.48c-0.721-1.078-2.828-0.801-3.84-0.96
		C359.449,371.996,359.364,371.361,358.807,371.199z"/>
			<path fill="#080808" d="M369.12,375.041c-1-0.76-2.54-0.98-3.84-1.44C366.281,374.36,367.821,374.58,369.12,375.041z" />
			<path fill="#080808" d="M384.721,382.721c-4.222-1.779-6.853-5.148-11.521-6.48C377.604,377.837,380.236,381.205,384.721,382.721z"
			/>
			<path fill="#080808" d="M385.681,382.96c0.768,0.674,2.058,0.824,3.12,1.2C388.035,383.486,386.745,383.336,385.681,382.96z" />
			<path fill="#080808" d="M390.721,384.88c1.523,0.957,3.193,1.767,4.802,2.64C394.159,386.402,392.521,385.561,390.721,384.88z" />
			<path fill="#080808" d="M408.721,391.841c-1.261-0.339-2.377-0.824-3.84-0.96C405.881,391.326,407.806,392.109,408.721,391.841z" />
			<path fill="#080808" d="M416.4,391.36c-1.774-0.525-4.254-0.973-6-0.24C412.554,391.047,414.522,391.158,416.4,391.36z" />
			<path fill="#080808" d="M426.48,393.281c0.546,0.414,1.405,0.513,2.398,0.48C428.284,393.396,427.424,393.297,426.48,393.281z" />
			<path fill="#080808" d="M431.761,394.241c0.697,0.422,1.693,0.547,2.642,0.72C433.751,394.49,432.809,394.313,431.761,394.241z" />
			<path fill="#080808" d="M478.801,693.041c-0.143-1.697-0.511-3.169-0.96-4.56C477.909,690.252,478.293,691.709,478.801,693.041z" />
			<path fill="#080808" d="M480.48,705.521c0-0.4,0-0.803,0-1.2c-0.374-2.985-0.387-6.335-1.199-8.88
		C479.704,698.777,479.929,702.313,480.48,705.521z"/>
			<path fill="#080808" d="M408.353,687.669c0.007-0.049,0.011-0.099,0.015-0.146c0.021-0.271,0.04-0.541,0.056-0.813
		c0.005-0.092,0.011-0.183,0.017-0.273c0.012-0.237,0.018-0.479,0.021-0.719c0.003-0.111,0.006-0.226,0.006-0.339
		c0.002-0.227-0.003-0.455-0.009-0.683c-0.003-0.121-0.004-0.241-0.009-0.362c-0.013-0.238-0.029-0.479-0.048-0.718
		c-0.011-0.108-0.016-0.217-0.024-0.325c-0.034-0.348-0.077-0.697-0.134-1.048c-1.035-1.364-1.453-3.347-2.642-4.562
		c0.464,1.777,1.722,2.762,2.16,4.562c0.399,2.322,0.399,6.316,0,8.64c-0.492,2.41-0.444,4.444-0.167,6.275
		C407.372,694.118,408.068,690.965,408.353,687.669z"/>
			<path fill="#080808" d="M409.44,704.56c-0.549,0.332-0.091,1.672-0.24,2.399c0,0.4,0,0.8,0,1.2c0.08,0,0.16,0,0.24,0
		c-0.171-1.095,0.271-2.433,0-2.878c0.07-0.146,0.129-0.288,0.178-0.432c0.016-0.047,0.024-0.092,0.04-0.138
		c0.025-0.096,0.053-0.19,0.07-0.286c0.01-0.051,0.018-0.101,0.022-0.148c0.013-0.093,0.021-0.184,0.025-0.271
		c0.002-0.046,0.005-0.093,0.005-0.14c0-0.103-0.006-0.201-0.018-0.302c-0.003-0.031-0.003-0.063-0.007-0.097
		c-0.017-0.135-0.04-0.269-0.071-0.399c0,0,0,0,0-0.001c-0.03-0.134-0.069-0.266-0.113-0.396c-0.008-0.023-0.02-0.049-0.026-0.073
		c-0.039-0.108-0.081-0.221-0.127-0.33c-0.009-0.021-0.018-0.041-0.026-0.063c-0.31-0.721-0.742-1.423-1.097-2.143
		c-0.002-0.003-0.004-0.008-0.006-0.012c-0.053-0.106-0.104-0.217-0.152-0.325c-0.001-0.003-0.001-0.004-0.002-0.006
		c0.443,1.684,0.979,3.238,1.305,4.837C409.44,704.559,409.44,704.559,409.44,704.56z"/>
			<polygon fill="#080808" points="408.138,699.724 408.135,699.718 408.138,699.725 	" />
			<path fill="#080808" d="M402.24,663.281c-0.08,0-0.159,0-0.239,0c0,1.278,0,2.56,0,3.84c0.543,3.937,2.101,6.857,3.359,10.08
		C404.282,672.6,401.576,669.625,402.24,663.281z"/>
			<path fill="#080808" d="M403.921,643.121c-0.256-5.635-0.109-11.501-1.188-16.215c0-0.001-0.001-0.002-0.001-0.003
		c0.276,2.242,0.429,4.614,0.47,7.098c0.55,0.411,0.089,1.832,0.24,2.64c0,1.843,0,3.683,0,5.521c0,0.16,0,0.32,0,0.479
		c0.558,1.363,0.082,3.761,0.238,5.521c0,0.16,0,0.32,0,0.479c0.554,0.568,0.086,2.154,0.24,3.12
		c0.221,4.144-0.979,6.861-1.439,10.32C404.638,656.967,404.227,649.864,403.921,643.121z"/>
			<path fill="#080808" d="M106.56,727.6c-2.881-0.558-6.799-0.078-10.08-0.238c0.068-2.651-0.19-5.631,0.24-7.92
		c-0.356,0.441-0.46,1.14-0.48,1.92c-0.08,0-0.16,0-0.24,0c0,1.6,0,3.198,0,4.8c0,0.32,0,0.64,0,0.96
		c0.187,0.053,0.279,0.201,0.24,0.479c3.507-0.008,5.079,0.313,6.96,0C104.32,727.6,105.44,727.6,106.56,727.6z"/>
			<path fill="#080808" d="M98.16,716.081c1.007-1.073,1.552-2.607,2.16-4.08C99.438,713.199,98.908,714.75,98.16,716.081z" />
			<path fill="#080808" d="M101.28,709.841c0.915-3.005,2.07-5.77,2.4-9.357C102.963,703.685,101.898,706.539,101.28,709.841z" />
			<path fill="#080808" d="M108.72,650.563c0.811-1.909,1.269-4.172,1.68-6.479C109.664,646.064,109.166,648.287,108.72,650.563z" />
			<path fill="#080808" d="M117.6,574.48c-0.155,1.125,0.314,2.872-0.24,3.601c-0.101,3.339-1.235,5.645-1.44,8.88
		c0.908-3.892,2.076-7.523,1.92-12.479C117.76,574.48,117.68,574.48,117.6,574.48z"/>
			<path fill="#080808" d="M125.28,486.641c0.508-1.251,0.823-2.694,0.96-4.318C125.731,483.572,125.417,485.018,125.28,486.641z" />
			<path fill="#080808" d="M126.96,479.201c0.412-0.788,0.635-1.768,0.72-2.881C127.268,477.108,127.045,478.086,126.96,479.201z" />
			<path fill="#080808" d="M128.4,473.44c0.471-0.891,0.888-1.832,0.96-3.12C128.82,471.141,128.592,472.272,128.4,473.44z" />
			<path fill="#080808" d="M171.12,399.041c-2.217,0.903-3.736,2.504-5.52,3.84c-0.998,0.842-1.85,1.83-2.88,2.641
		c-1.04,1.359-2.514,2.285-3.84,3.359c-0.939,1.141-1.979,2.18-3.12,3.12c-0.863,0.978-1.709,1.971-2.64,2.88
		c-7.17,8.352-13.706,17.334-17.52,29.04C142.955,424.476,155.829,410.549,171.12,399.041z"/>
			<path fill="#080808" d="M173.76,397.841c1.244-0.274,2.484-0.556,3.6-0.96C175.782,396.822,174.711,397.272,173.76,397.841z" />
			<path fill="#080808" d="M180.72,396.161c0.897,0.018,1.659-0.103,2.16-0.48C181.982,395.663,181.221,395.782,180.72,396.161z" />
			<path fill="#080808" d="M187.68,394.721c0.897,0.018,1.659-0.101,2.16-0.48C188.942,394.223,188.181,394.342,187.68,394.721z" />
			<path fill="#080808" d="M204.96,394.001c0.872,0.408,2.31,0.25,3.12,0.72C207.545,393.975,206.307,393.934,204.96,394.001z" />
			<path fill="#080808" d="M211.44,393.28c1.969-0.511,3.471-1.488,5.04-2.4C214.688,391.568,213.076,392.436,211.44,393.28z" />
			<path fill="#080808" d="M220.32,389.201c-0.931,0.349-1.917,0.643-2.64,1.2C218.835,390.276,219.563,389.724,220.32,389.201z" />
			<path fill="#080808" d="M220.8,388.96c0.986-0.054,1.572-0.508,2.16-0.96C222.201,388.282,221.319,388.439,220.8,388.96z" />
			<path fill="#080808" d="M226.32,386.561c4.402-1.357,7.685-3.835,10.8-6.48C233.732,382.453,230.391,384.871,226.32,386.561z" />
			<path fill="#080808" d="M208.32,165.76c0.566-3.274,1.236-6.444,2.16-9.36C209.301,159.061,208.404,162.004,208.32,165.76z" />
			<path fill="#080808" d="M219.84,144.16c1.526-1.272,3.504-2.043,4.8-3.6c2.348-2.821,3.63-6.521,5.52-9.6
		c0.461-0.751,0.947-1.475,1.444-2.186c0.12-0.171,0.242-0.341,0.364-0.51c0.459-0.639,0.931-1.264,1.417-1.872
		c0.043-0.054,0.084-0.109,0.128-0.163c1.174-1.455,2.425-2.826,3.737-4.132c0.001-0.001,0.002-0.003,0.003-0.004
		s0.001-0.002,0.002-0.002c0.042-0.041,0.082-0.083,0.123-0.124c0,0,0,0,0.001,0c0.004-0.004,0.009-0.01,0.013-0.014
		c1.798-1.777,3.712-3.434,5.715-5.011c0.041-0.032,0.082-0.065,0.123-0.096c1.958-1.537,3.996-3.002,6.091-4.431
		c0.154-0.105,0.309-0.211,0.464-0.316c0.532-0.361,1.068-0.72,1.606-1.078c0.169-0.112,0.337-0.225,0.507-0.337
		c0.67-0.442,1.343-0.884,2.021-1.324c-5.547,2.213-9.169,6.351-13.68,9.6c0,0.001-0.001,0.001-0.001,0.002
		c-2.095,1.583-4.009,3.348-5.518,5.518c-2.097,2.562-4.281,5.401-6,8.4c-1.498,2.613-2.655,5.839-4.56,7.92
		c-1.508,1.647-3.884,2.552-5.52,4.08c-2.747,2.566-4.216,5.536-6.48,8.4C214.938,150.358,216.615,146.848,219.84,144.16z"/>
			<path fill="#080808" d="M255.6,108.64c1.109-0.171,2.076-0.485,2.88-0.96C257.371,107.851,256.405,108.165,255.6,108.64z" />
			<path fill="#080808" d="M262.32,106.72c1.792-0.288,3.384-0.776,4.8-1.438C265.375,105.614,263.756,106.076,262.32,106.72z" />
			<polygon fill="#080808" points="243.657,189.281 243.666,189.272 243.661,189.277 	" />
			<rect x="216.668" y="212.242" fill="#080808" width="0.005" height="0" />
			<path fill="#080808" d="M264.48,197.2c-1.779,0.461-3.521,0.959-5.04,1.68C261.305,198.505,263.091,198.051,264.48,197.2z" />
			<path fill="#080808" d="M268.32,195.52c-0.066,0.494-0.894,0.226-0.96,0.72c0.837-0.363,1.962-0.438,2.4-1.2
		c-0.457,0.103-0.603,0.517-1.2,0.48C268.48,195.52,268.4,195.52,268.32,195.52z"/>
			<path fill="#080808" d="M288.96,180.88c-5.727,5.313-12.076,10.004-18.96,14.16C277.14,191.14,283.462,186.422,288.96,180.88z" />
			<path fill="#080808" d="M305.761,148.24c1.267-1.695,1.561-4.361,4.08-4.8C306.952,143.512,306.807,146.326,305.761,148.24z" />
			<path fill="#080808" d="M340.797,181.84c-1.431-3.298-2.146-7.725-3.359-11.76c-1.61-5.372-4.256-12.621-1.621-17.188
		c-0.106,0.103-0.205,0.215-0.304,0.328c-0.063,0.073-0.122,0.149-0.181,0.229c-0.106,0.141-0.206,0.288-0.298,0.444
		c-0.087,0.15-0.171,0.305-0.244,0.471c-0.045,0.102-0.085,0.211-0.125,0.319c-0.069,0.192-0.131,0.394-0.182,0.606
		c-0.019,0.07-0.039,0.133-0.056,0.205c-0.059,0.291-0.095,0.606-0.115,0.936c-0.008,0.09-0.012,0.184-0.015,0.277
		c-0.013,0.358-0.013,0.729,0.021,1.134c0.293,3.822,1.611,7.866,2.88,12c1.199,3.912,1.729,8.646,3.12,12
		c2.128,5.131,8.489,9.066,12.479,11.52C349.132,190.639,343.076,187.097,340.797,181.84z"/>
			<path fill="#080808" d="M227.103,315.588c0.563,1.14,1.147,2.266,1.751,3.376c-0.001-0.002-0.002-0.004-0.003-0.006
		C228.248,317.85,227.665,316.726,227.103,315.588z"/>
			<path fill="#080808" d="M225.47,312.122L225.47,312.122c0.524,1.167,1.068,2.32,1.631,3.461
		C226.538,314.442,225.994,313.288,225.47,312.122z"/>
			<path fill="#080808" d="M252.722,342.88c0.041-0.521-0.851-0.108-1.2-0.24c-8.083-4.653-14.655-10.945-19.68-18.96
		c-0.203-0.32-0.416-0.655-0.634-0.995c-0.498-0.778-1.03-1.602-1.571-2.46c-0.001-0.003-0.003-0.005-0.005-0.008
		c-0.259-0.411-0.519-0.829-0.777-1.253c0.001,0.002,0.002,0.004,0.003,0.006c0.285,0.524,0.577,1.043,0.872,1.56
		c0.063,0.109,0.126,0.219,0.189,0.328c0.292,0.506,0.586,1.009,0.888,1.506c0.027,0.044,0.054,0.088,0.081,0.132
		c0.623,1.025,1.266,2.031,1.929,3.017c0.043,0.064,0.086,0.131,0.129,0.195c0.274,0.404,0.554,0.802,0.835,1.199
		c0.152,0.215,0.305,0.43,0.458,0.643c0.263,0.363,0.529,0.724,0.798,1.081c0.189,0.25,0.381,0.496,0.573,0.743
		c0.292,0.376,0.586,0.752,0.886,1.12c0.377,0.464,0.759,0.922,1.148,1.373c0.22,0.255,0.446,0.503,0.67,0.753
		c0.277,0.31,0.555,0.618,0.838,0.921c0.224,0.239,0.449,0.477,0.677,0.711c0.316,0.328,0.637,0.649,0.961,0.967
		c0.198,0.195,0.393,0.392,0.594,0.583c0.513,0.489,1.034,0.969,1.564,1.435c0.135,0.119,0.274,0.231,0.411,0.348
		c0.427,0.367,0.858,0.727,1.297,1.078c0.195,0.156,0.393,0.31,0.591,0.462c0.41,0.317,0.825,0.627,1.245,0.931
		c0.186,0.134,0.371,0.271,0.559,0.402c0.601,0.42,1.209,0.831,1.831,1.222C249.899,342.319,251.378,342.997,252.722,342.88z"/>
			<path fill="#080808" d="M258.72,243.52c3.063-0.13,3.056-3.604,3.6-6c0.622-2.739,1.426-5.626,1.68-8.16
		c0.131-0.349-0.281-1.241,0.24-1.2c0.411-3.989,2.065-6.734,2.88-10.32c-0.814,3.585-2.469,6.331-2.88,10.32
		c0.266-1.416,1.065-3.196,1.68-5.04c0.546-1.638,1.556-4.169,1.44-5.28c-0.126-1.21-1.951-2.921-2.88-4.08
		c-1.789-2.233-3.079-4.76-5.76-4.56c0.37,3.083,3.661,5.291,5.04,8.16c-2.346,5.114-3.339,12.829-4.08,19.44
		C259.365,239.615,257.354,241.45,258.72,243.52z M258.596,241.783c0.066-1.512,1.194-3.052,1.324-4.503
		C259.79,238.731,258.662,240.272,258.596,241.783z"/>
			<path fill="#080808" d="M229.2,226.48c0.125,0.355,0.355,0.604,0.48,0.96c1.061,0.859,2.021,1.819,2.88,2.88
		c1.5,0.832,3.329,2.458,6,3.12c3.241,0.803,8.989,1.551,10.08-1.68c-0.079-0.151-0.194-0.267-0.324-0.369
		c-0.123-0.096-0.273-0.165-0.436-0.222c-0.001,0-0.002-0.001-0.003-0.001c-0.437-0.152-0.99-0.187-1.637-0.128
		c-1.559,0.807-3.802,0.331-5.76,0.24c-0.16,0-0.32,0-0.48,0c1.54,0.98,3.9,0.333,5.818,0.17c-1.918,0.164-4.278,0.811-5.818-0.17
		c-0.84,0.04-1.537-0.063-1.92-0.48c-0.001,0-0.001,0-0.002-0.001c-1.543-0.936-3.494-1.465-4.557-2.879
		c-1.21-1.11-2.539-2.101-3.36-3.6c-0.09-0.002-0.154-0.029-0.214-0.062c0.57,1.161,1.309,2.153,2.176,3.017
		c0.066,0.066,0.129,0.137,0.198,0.203c-0.069-0.065-0.132-0.137-0.199-0.204c-0.87-0.861-1.605-1.856-2.176-3.017
		c-0.09-0.183-0.177-0.368-0.258-0.559c-0.012-0.163-0.012-0.338-0.014-0.512c0-0.017-0.001-0.034-0.001-0.051
		c-0.001-0.081-0.007-0.158-0.014-0.234c-0.006-0.062-0.02-0.117-0.033-0.172c-0.01-0.041-0.017-0.085-0.031-0.121
		c-0.035-0.086-0.082-0.16-0.154-0.208c0.897-0.143,1.041-1.04,1.68-1.44c1.121-1.2,1.729-2.911,3.36-3.6
		c0.959-0.801,2.446-1.074,4.08-1.2c0.729-0.398,3.112-0.398,3.84,0c0.864-0.064,1.157,0.443,1.92,0.48
		c1.586,3.294,4.124,5.637,5.76,8.88c3.332,2.501,2.915-3.303,1.92-4.56c1.305,0.375,1.752,1.607,3.36,1.68
		c0.317-1.034-1.256-2.048-1.2-3.6c-3.093-1.941-7.492-5.462-12.24-6c-6.529-0.739-10.646,2.568-13.44,6.72
		c-1.397-0.197-2.94-0.853-4.081,0c-0.775,2.002,1.527,2.284,2.16,3.6c-1.042,0.397-2.352,0.528-2.16,2.16
		C225.915,226.169,227.197,227.217,229.2,226.48z M251.52,220.239c1.308,0.452,1.868,1.651,3.36,1.92c0,0,0,0,0,0.001
		C253.388,221.891,252.828,220.692,251.52,220.239c-0.025,0.097-0.036,0.199-0.037,0.305
		C251.484,220.438,251.495,220.336,251.52,220.239z M229.2,222.399c2.7-4.114,8.518-8.814,15.12-6c2.467,3.053,3.983,7.057,7.2,9.36
		c0.815-0.339,1.083-0.854,1.077-1.449c0.006,0.596-0.261,1.111-1.077,1.45c-3.217-2.303-4.733-6.307-7.2-9.36
		C237.718,213.586,231.9,218.286,229.2,222.399C229.2,222.399,229.2,222.399,229.2,222.399z M224.64,220
		c-0.25,2.17,1.856,1.983,2.4,3.359c0,0,0,0,0,0C226.495,221.984,224.389,222.17,224.64,220z M224.64,225.279
		c1.29,0.591,2.805,1.55,4.56,0.96c2.094,2.384,5.446,6.021,9.6,6.96c2.956,0.667,7.782,1.313,9.6-1.2c0,0,0,0,0,0
		c-1.817,2.512-6.645,1.867-9.6,1.2c-4.154-0.938-7.506-4.576-9.6-6.96c-1.755,0.59-3.271-0.369-4.56-0.96
		C224.64,225.28,224.64,225.279,224.64,225.279z"/>
			<path fill="#080808" d="M370.801,218.32c0.592-0.031,1.156-0.036,1.439,0.24c0.318,0,0.641,0,0.96,0
		c-0.937-0.425-2.149-0.57-3.359-0.72C369.669,218.492,370.973,217.668,370.801,218.32z"/>
			<path fill="#080808" d="M261.6,270.64c1.407,5.585,1.545,12.476,7.68,12.24c3.851-0.148,8.921-5.064,12.72-7.92
		c4.642-3.49,9.018-6.343,11.52-9.12c2.451-2.721,5.5-6.139,3.84-10.56c-0.866-2.311-2.918-7.358-5.52-5.76c0,0,0,0,0,0
		c-0.24,0-0.285,0.194-0.48,0.239c0.254,3.107,2.099,4.623,3.121,6.96c0,0.08,0,0.16,0,0.24c0.75,3.028-1.849,4.468-2.881,6.48
		c-2.479-3.833-5.682-6.942-10.063-8.872c-0.006-0.003-0.011-0.006-0.016-0.009c-0.08,0-0.16,0-0.24,0c-0.001,0-0.001,0-0.002,0
		c-5.572-1.949-8.787,11.527-11.998,5.76c-0.315-0.085-0.377-0.422-0.72-0.48c-2.478-3.873-6.212-3.494-8.4,0
		c-0.752,1.202-2.104,5.162-2.16,3.84c-0.4-1.683-0.4-5.037,0-6.72c-0.396-2.13,1.468-3.83,0.24-5.28
		c-1.864,1.417-2.064,4.496-2.16,7.68c-0.551,0.49-0.086,1.994-0.24,2.88C255.299,267.501,257.691,269.829,261.6,270.64z
		 M294.956,258.641c0-0.32,0-0.64,0-0.96c-0.343-0.004-0.186-0.511-0.372-0.674c0.188,0.164,0.03,0.67,0.376,0.673
		c-0.313-1.686-0.976-3.025-1.92-4.08c0-0.08,0-0.16,0-0.24c-0.001,0-0.001-0.001-0.002-0.002c-0.45-1.046-2.129-3.136-0.478-4.078
		c0,0,0.001,0,0.002,0.001c-1.65,0.942,0.031,3.034,0.479,4.079c0,0.08,0,0.16,0,0.24c0.945,1.055,1.604,2.394,1.92,4.08
		c0,0.32,0,0.64,0,0.96c-0.562,2.319-2.09,3.67-3.358,5.28c-0.001,0-0.001-0.001-0.002-0.002c1.271-1.609,2.797-2.959,3.356-5.277
		c-0.84,1.96-2.151,3.447-3.358,5.04c0,0,0,0-0.001-0.001C292.806,262.087,294.118,260.601,294.956,258.641z M281.951,254.946
		c0.001,0,0.001,0,0.002,0.001c-0.152-0.045-0.302-0.07-0.453-0.099C281.65,254.876,281.8,254.9,281.951,254.946z M271.68,262
		c0.08,0,0.16,0,0.24,0c0.001-0.001,0.003-0.003,0.004-0.004c3.311-2.138,4.638-7.792,9.355-7.196c0.001,0,0.003,0.001,0.004,0.001
		c-4.72-0.596-6.045,5.063-9.359,7.2c-0.08,0-0.16,0-0.24,0c-0.103,0.537-1.183,0.097-1.68,0.24c0,0-0.001-0.001-0.001-0.001
		C270.502,262.1,271.577,262.536,271.68,262z M263.76,257.2c0.393-0.04,0.757-0.021,1.096,0.045
		c-0.337-0.065-0.699-0.084-1.09-0.044c-3.401,0.342-4.933,5.798-5.28,8.4c-0.001-0.002-0.003-0.005-0.004-0.008
		C258.83,262.988,260.362,257.541,263.76,257.2z M256.077,262.24c0.001,0,0.002,0,0.003,0c0-0.08,0-0.16,0-0.24
		c0.588-3.413-0.068-8.069,2.16-9.84c0,0.002,0,0.004,0,0.006c-2.222,1.774-1.567,6.426-2.155,9.835c0,0.08,0,0.16,0,0.24
		c-0.315,4.79,1.413,7.542,5.516,7.919c1.435,4.366,1.734,12.288,7.014,12.442c-5.282-0.151-5.582-8.076-7.017-12.442
		C257.495,269.783,255.767,267.031,256.077,262.24z"/>
			<path fill="#080808" d="M339.859,265.788c0,0.001,0,0.003,0,0.005c-0.007,0.016-0.015,0.03-0.019,0.047
		c0.004-0.002,0.009-0.004,0.016-0.007c-0.007,0.083-0.018,0.164-0.018,0.247c2.961-1.101,4.505-4.651,6.002-7.44
		c1.446-2.695,4.386-5.795,3.359-9.12c-1.078-0.529-2.475-0.132-2.641,0.958c-0.001,0-0.002,0.001-0.003,0.002c0,0.08,0,0.16,0,0.24
		c0.001,0.003,0,0.005,0,0.009c-1.039,2.236-1.541,5.01-2.636,7.191c0,0.08,0,0.161,0,0.24c-0.062,0.176-0.131,0.349-0.207,0.519
		c-0.021,0.047-0.046,0.092-0.065,0.138c-0.061,0.124-0.117,0.247-0.184,0.368c-0.021,0.04-0.045,0.078-0.065,0.118
		c-0.071,0.128-0.145,0.255-0.222,0.38c-0.011,0.019-0.021,0.036-0.034,0.054c-1.241,2.009-3.13,3.734-3.281,6.043
		C339.861,265.783,339.86,265.786,339.859,265.788z M347.036,256.007c-0.979,1.245-1.731,2.714-2.511,4.158
		c0.78-1.447,1.534-2.919,2.515-4.165C347.039,256.002,347.037,256.005,347.036,256.007z M349.198,249.76
		c-0.289-0.164-0.561-0.238-0.815-0.248c0.255,0.01,0.527,0.083,0.817,0.248c0.793,2.633-1.197,4.344-2.16,6
		c0.001-0.002,0.002-0.005,0.003-0.007C348.007,254.099,349.99,252.389,349.198,249.76z M343.921,258.641
		c-0.143,0.327-0.3,0.639-0.469,0.939c-0.007,0.012-0.015,0.023-0.021,0.035c0.007-0.012,0.015-0.024,0.021-0.036
		C343.621,259.279,343.778,258.967,343.921,258.641c0-0.081,0-0.161,0-0.241c0.661-0.858,1.145-1.896,1.439-3.12
		c0.344-1.096,0.914-1.966,0.96-3.36c0.538-0.791,0.534-2.125,1.783-2.207c-1.247,0.083-1.242,1.417-1.781,2.207
		c-0.046,1.394-0.616,2.264-0.96,3.36c-0.297,1.224-0.778,2.262-1.439,3.12c0,0.08,0,0.16,0,0.24
		c-0.802,2.799-2.961,4.239-3.841,6.959c-0.001,0-0.001,0.001-0.002,0.001C340.96,262.88,343.119,261.439,343.921,258.641z"/>
			<path fill="#080808" d="M333.285,330.957c-0.012,0.013-0.023,0.024-0.035,0.036C333.263,330.981,333.274,330.97,333.285,330.957
		L333.285,330.957z"/>
			<path fill="#080808" d="M332.226,332.203c0.021-0.027,0.041-0.054,0.059-0.082c0.064-0.104,0.128-0.211,0.188-0.319
		c0.018-0.027,0.031-0.054,0.05-0.082c-0.28,0.281-0.565,0.565-0.844,0.84c0.08,0,0.16,0,0.24,0
		C332.04,332.46,332.136,332.334,332.226,332.203z"/>
			<path fill="#080808" d="M324.721,340.721c-1.221,1.02-2.341,2.14-3.358,3.36C322.747,343.228,323.867,342.107,324.721,340.721z" />
			<path fill="#080808" d="M316.57,348.87c1.697-1.497,3.294-3.093,4.79-4.789c-0.08,0-0.16,0-0.24,0
		c-0.741,0.935-1.602,1.75-2.456,2.573c0.854-0.823,1.715-1.638,2.456-2.573c-1.859,1.66-3.62,3.42-5.279,5.28
		c0.342-0.057,0.404-0.395,0.72-0.48C316.563,348.877,316.567,348.874,316.57,348.87z"/>
			<path fill="#080808" d="M311.04,354.4c0.058-0.342,0.396-0.405,0.479-0.72c-1.141,0.939-2.181,1.979-3.118,3.12
		c0.08,0,0.158,0,0.238,0C309.34,355.9,310.14,355.1,311.04,354.4z"/>
			<path fill="#080808" d="M256.69,381.965c-0.002,0.152,0.029,0.313,0.064,0.474c0.01,0.044,0.006,0.084,0.018,0.129
		c0.058,0.207,0.146,0.419,0.269,0.632c0-0.745,0-1.49,0-2.235c-0.215,0.259-0.331,0.591-0.344,0.958
		C256.697,381.938,256.69,381.95,256.69,381.965z"/>
			<path fill="#080808" d="M258.387,380.41c-0.092,0.002-0.185,0.001-0.27,0.013c-0.141,0.021-0.271,0.056-0.393,0.102
		c-0.029,0.01-0.058,0.02-0.085,0.032c-0.138,0.059-0.265,0.131-0.374,0.219c0.428-0.025,0.972,0.065,1.419,0.06
		c0.02,0,0.04,0,0.059,0c0.077-0.002,0.148-0.011,0.218-0.021c0.027-0.004,0.056-0.006,0.082-0.012
		c0.062-0.014,0.116-0.036,0.168-0.059c0.022-0.01,0.047-0.016,0.067-0.028c0.066-0.04,0.123-0.089,0.163-0.155
		c-0.258-0.083-0.498-0.127-0.725-0.151C258.599,380.399,258.494,380.407,258.387,380.41z"/>
			<path fill="#080808" d="M246.96,414.161c0.184,0.853,0.341,1.675,0.483,2.468c0.008,0.043,0.014,0.082,0.021,0.125
		c0.126,0.707,0.24,1.394,0.35,2.054c0.254,1.54,0.487,2.972,0.79,4.301c0,0.001,0,0.003,0.001,0.004c0-0.001,0-0.003,0-0.005
		c0.001-0.311,0.035-0.65,0.054-0.979c0.004-0.07,0.006-0.139,0.008-0.207c0.002-0.063,0.004-0.129,0.003-0.188
		c-0.002-0.133-0.01-0.259-0.031-0.369c-0.561-5.197-3.128-11.18-2.4-16.08c-1.276-0.639-0.506,0.759-0.479,1.438
		C245.86,409.225,246.608,412.528,246.96,414.161z"/>
			<path fill="#080808" d="M376.561,398.801c-1.098,1.224-1.834,2.808-2.64,4.32C374.987,401.868,375.709,400.269,376.561,398.801z" />
			<path fill="#080808" d="M376.801,398.081c0.67-0.93,0.943-2.256,1.439-3.36c0-0.08,0-0.16,0-0.24
		c0.037-0.763,0.545-1.056,0.479-1.92c-0.57,1.108-0.938,2.42-1.438,3.6c0,0.08,0,0.16,0,0.239c0,0,0,0,0,0.001
		C377.24,397.081,376.841,397.401,376.801,398.081z"/>
			<path fill="#080808" d="M379.681,388.961c-0.668-0.108,0.188,1.308-0.48,1.2c0.058,1.017-0.22,1.699-0.479,2.4
		c0.064,0.864-0.441,1.157-0.479,1.92C379.147,393.066,379.447,391.046,379.681,388.961z"/>
			<path fill="#080808" d="M410.639,404.32c0.476-0.566,0.567-1.515,0.722-2.4C410.885,402.486,410.795,403.435,410.639,404.32z" />
			<path fill="#080808" d="M409.2,410.081c0.561-0.959,0.799-2.242,0.96-3.6C409.662,407.501,409.455,408.814,409.2,410.081z" />
			<path fill="#080808" d="M408.24,414.4c-0.422,0.696-0.547,1.691-0.722,2.641C407.991,416.392,408.168,415.448,408.24,414.4z" />
			<path fill="#080808" d="M407.521,417.28c-0.473,0.647-0.647,1.593-0.722,2.641C407.223,419.223,407.348,418.228,407.521,417.28z" />
			<path fill="#080808" d="M406.801,420.16c-0.425,0.936-0.569,2.147-0.72,3.358C406.549,422.629,406.73,421.451,406.801,420.16z" />
			<path fill="#080808" d="M405.121,427.841c-0.563,1.438-0.96,3.04-1.2,4.8C404.483,431.204,404.88,429.601,405.121,427.841z" />
			<path fill="#080808" d="M403.681,434.081c-0.362,0.596-0.464,1.456-0.479,2.399C403.564,435.885,403.664,435.024,403.681,434.081z"
			/>
			<path fill="#080808" d="M141.84,450.881c0.67,0.53,1.813,0.588,2.88,0.72C144.003,451.118,142.822,451.099,141.84,450.881z" />
			<path fill="#080808" d="M144.96,451.601c0.596,0.364,1.457,0.464,2.4,0.48C146.764,451.717,145.903,451.617,144.96,451.601z" />
			<path fill="#080808" d="M147.6,452.082c0.596,0.362,1.457,0.462,2.4,0.479C149.404,452.199,148.543,452.098,147.6,452.082z" />
			<path fill="#080808" d="M150.24,452.561c1.133,0.469,2.547,0.652,4.08,0.722C153.147,452.854,151.707,452.693,150.24,452.561z" />
			<path fill="#080808" d="M156.24,453.522c0.751,0.367,1.774,0.464,2.88,0.479C158.369,453.632,157.346,453.535,156.24,453.522z" />
			<path fill="#080808" d="M159.36,454.001c2.082,0.638,4.388,1.053,6.96,1.2C164.109,454.691,161.767,454.312,159.36,454.001z" />
			<path fill="#080808" d="M195.36,420.16c-0.519,0.842-0.718,2.002-0.96,3.12C194.94,422.46,195.168,421.329,195.36,420.16z" />
			<path fill="#080808" d="M272.401,459.28c-1.182-0.658-2.82-0.86-4.32-1.199C269.262,458.739,270.9,458.941,272.401,459.28z" />
			<path fill="#080808" d="M277.921,460.482c-0.936-0.427-2.15-0.572-3.36-0.722C275.453,460.229,276.63,460.411,277.921,460.482z" />
			<path fill="#080808" d="M318.482,644.322c0.361-7.158,0.374-15.945,0.238-22.801c0-0.4,0-0.803,0-1.2c-0.08,0-0.16,0-0.238,0
		c0.154,1.204-0.316,3.034,0.238,3.84c0.023,0.981,0.047,1.967-0.238,2.64C318.482,632.641,318.482,638.483,318.482,644.322z"/>
			<path fill="#080808" d="M318,609.761c-0.025-1.653-0.333-3.026-0.96-4.08C317.399,607.002,317.583,608.498,318,609.761z" />
			<path fill="#080808" d="M316.32,603.281c-0.171-1.109-0.484-2.076-0.96-2.88C315.531,601.509,315.843,602.475,316.32,603.281z" />
			<path fill="#080808" d="M313.921,595.601c-1.065-4.135-2.545-7.855-3.841-11.76C311.071,588.05,312.559,591.762,313.921,595.601z"
			/>
			<path fill="#080808" d="M308.4,577.361c0.359,2.276,0.884,4.396,1.68,6.237C309.588,581.453,309.092,579.31,308.4,577.361z" />
			<path fill="#080808" d="M307.44,573.281c0.161,1.438,0.475,2.726,0.96,3.84C308.239,575.683,307.924,574.394,307.44,573.281z" />
			<path fill="#080808" d="M307.2,571.841c-0.172-1.907-0.479-3.68-0.96-5.28C306.375,568.506,306.689,570.271,307.2,571.841z" />
			<path fill="#080808" d="M306,564.641c-0.039-1.318-0.111-2.605-0.479-3.6C305.51,562.411,305.611,563.669,306,564.641z" />
			<path fill="#080808" d="M278.88,445.121c-1.79-0.45-3.581-0.898-5.52-1.2C274.998,444.525,276.731,445.029,278.88,445.121z" />
			<path fill="#080808" d="M265.92,442.24c1.51,0.568,3.248,0.913,5.04,1.2C269.483,442.838,267.768,442.473,265.92,442.24z" />
			<path fill="#080808" d="M246.24,425.681c0.438,2.282,2.03,3.41,2.88,5.28c0.981,0.779,1.861,1.659,2.64,2.64
		c4.299,2.821,7.688,6.555,12.96,8.4C257.35,437.772,250.019,433.501,246.24,425.681z"/>
			<path fill="#080808" d="M279.361,433.596c4.129,3.014,13.055,4.119,18.916,1.935c0.015-0.003,0.029-0.006,0.044-0.009
		c0.045-0.355,0.58-0.222,0.959-0.24c0.361-0.185,0.724-0.369,1.085-0.554c-0.362,0.186-0.724,0.37-1.085,0.555
		c0.521-0.116,1.012-0.265,1.486-0.425c0.156-0.056,0.301-0.118,0.452-0.176c0.312-0.117,0.618-0.24,0.912-0.379
		c0.167-0.078,0.329-0.16,0.49-0.242c0.264-0.135,0.521-0.273,0.772-0.423c0.156-0.093,0.314-0.186,0.471-0.281
		c0.244-0.155,0.48-0.318,0.718-0.483c0.142-0.1,0.285-0.195,0.424-0.299c0.259-0.191,0.509-0.393,0.756-0.593
		c0.265-0.215,0.521-0.432,0.776-0.65c0.157-0.137,0.314-0.271,0.472-0.411c0.277-0.247,0.551-0.498,0.823-0.75
		c0.009-0.009,0.018-0.017,0.026-0.025c0.002-0.001,0.003-0.002,0.005-0.003c0-0.001,0.001-0.001,0.002-0.001
		c0-0.001,0-0.001,0-0.001c0.429-0.349,0.85-0.711,1.257-1.098c0.213-0.202,0.412-0.409,0.604-0.616c0,0,0.001,0,0.001-0.001
		c0.004-0.001,0.006-0.003,0.007-0.005c0.305-0.274,0.612-0.545,0.927-0.812c0.089-0.075,0.18-0.148,0.269-0.225
		c0.262-0.216,0.522-0.427,0.792-0.633c0.133-0.102,0.271-0.197,0.406-0.297c0.242-0.176,0.486-0.352,0.738-0.517
		c0.17-0.109,0.35-0.214,0.523-0.318c0.234-0.144,0.471-0.284,0.714-0.415c0.212-0.111,0.437-0.21,0.653-0.313
		c0.23-0.106,0.458-0.222,0.7-0.317c0.26-0.105,0.539-0.192,0.813-0.284c0.226-0.075,0.439-0.158,0.675-0.225
		c0.337-0.095,0.697-0.161,1.059-0.233c0.196-0.039,0.38-0.092,0.583-0.124c0.577-0.091,1.18-0.156,1.818-0.188
		c-0.001,0.001-0.002,0.001-0.003,0.002c0.887-0.153,2.39,0.311,2.879-0.24c0.002,0,0.004,0,0.005,0c0.72,0,1.438,0,2.16,0
		c0.002-0.001,0.003-0.001,0.005-0.001c1.839-0.155,4.305,0.315,5.746-0.238c0.003,0,0.006,0,0.009,0
		c2.138-1.053,0.07-3.338-2.399-2.641c-0.242,0.208-0.63,0.271-1.074,0.278c0.441-0.007,0.827-0.071,1.068-0.277
		c-1.12,0-2.24,0-3.358,0c0-0.001,0-0.001,0-0.002c-1.125,0.153-2.874-0.313-3.602,0.238c1.199,0,2.399,0,3.602,0v0.001
		c-1.198,0-2.396,0-3.596,0c-0.383-0.017-0.758-0.021-1.126-0.016c-0.124,0.002-0.243,0.01-0.363,0.014
		c-0.241,0.011-0.482,0.02-0.721,0.034c-0.142,0.011-0.278,0.026-0.42,0.04c-0.21,0.021-0.419,0.042-0.623,0.069
		c-0.146,0.02-0.29,0.042-0.434,0.063c-0.192,0.03-0.384,0.063-0.572,0.102c-0.146,0.026-0.29,0.059-0.433,0.09
		c-0.184,0.04-0.36,0.083-0.538,0.128c-0.146,0.037-0.286,0.074-0.428,0.113c-0.174,0.049-0.343,0.101-0.511,0.152
		c-0.141,0.044-0.279,0.087-0.42,0.137c-0.164,0.056-0.325,0.114-0.484,0.177c-0.14,0.052-0.276,0.102-0.412,0.156
		c-0.152,0.061-0.304,0.127-0.452,0.189c-0.142,0.063-0.281,0.118-0.419,0.181c-0.051,0.023-0.103,0.052-0.151,0.074
		c-0.398,0.188-0.793,0.389-1.178,0.599c-0.089,0.048-0.179,0.094-0.27,0.144c-0.176,0.099-0.35,0.201-0.522,0.305
		c-0.087,0.054-0.176,0.104-0.262,0.154c-0.179,0.107-0.353,0.217-0.524,0.327c-0.08,0.051-0.16,0.101-0.239,0.152
		c-0.183,0.116-0.36,0.235-0.542,0.356c-0.063,0.043-0.128,0.087-0.189,0.13c-0.192,0.132-0.387,0.268-0.575,0.4
		c-0.04,0.028-0.081,0.058-0.121,0.086c-0.202,0.142-0.402,0.289-0.603,0.437c-0.009,0.006-0.018,0.012-0.026,0.018
		c-0.003,0.002-0.006,0.004-0.01,0.007c-1.301,0.907-2.519,1.882-3.72,2.84c-0.005,0.003-0.007,0.005-0.01,0.007
		c-0.001,0-0.002,0.001-0.003,0.002c-0.024,0.02-0.048,0.038-0.072,0.057c-0.366,0.278-0.729,0.55-1.098,0.817
		c-0.033,0.024-0.065,0.05-0.103,0.074c-0.381,0.276-0.767,0.546-1.154,0.812c-0.063,0.039-0.123,0.079-0.186,0.12
		c-0.781,0.521-1.582,1.008-2.414,1.442c-0.082,0.043-0.164,0.086-0.246,0.129c-0.405,0.206-0.817,0.401-1.242,0.581
		c-0.063,0.027-0.127,0.051-0.189,0.077c-0.389,0.16-0.785,0.307-1.189,0.439c-0.076,0.024-0.149,0.055-0.228,0.076
		c-0.438,0.141-0.894,0.261-1.354,0.362c-0.102,0.023-0.205,0.044-0.307,0.063c-0.482,0.1-0.973,0.186-1.48,0.24
		c-4.129,0.459-8.634-0.161-11.521-1.68c-1.736-0.914-2.537-2.865-4.8-2.88c-0.473,0.501-0.184,1.594,0,2.16
		C277.861,431.896,278.661,432.697,279.361,433.596z M320.63,423.358c0.537-0.243,1.533-0.028,2.296-0.046
		C322.164,423.331,321.169,423.117,320.63,423.358z M313.442,424.961c3.249-1.588,7.852-1.976,12.238-2.16
		C321.292,422.987,316.691,423.373,313.442,424.961z M311.268,423.624c0.396-0.205,0.801-0.402,1.219-0.586
		c2.361-1.036,5.794-1.938,8.88-2.16c3.84-0.279,8.196-0.459,10.561,0.72c-0.216,0.394-0.506,0.68-0.846,0.884
		c0.338-0.204,0.626-0.488,0.84-0.881c-2.364-1.179-6.721-0.999-10.561-0.72c-3.086,0.227-6.519,1.124-8.88,2.16
		c-0.416,0.183-0.818,0.38-1.215,0.584C311.267,423.625,311.267,423.624,311.268,423.624z M304.632,432.391
		c0.04-0.023,0.079-0.049,0.118-0.072C304.71,432.342,304.671,432.368,304.632,432.391c-0.166,0.102-0.332,0.199-0.499,0.297
		C304.3,432.59,304.468,432.492,304.632,432.391z M300.951,434.426c0.21-0.106,0.42-0.217,0.629-0.326
		c0.222-0.114,0.441-0.231,0.661-0.352c0.17-0.09,0.339-0.181,0.507-0.272c0.236-0.129,0.473-0.261,0.707-0.394
		c0.109-0.062,0.219-0.125,0.328-0.188c-0.109,0.063-0.22,0.127-0.329,0.189c-0.235,0.133-0.472,0.265-0.707,0.394
		c-0.169,0.094-0.337,0.185-0.507,0.272c-0.221,0.118-0.439,0.235-0.661,0.352C301.37,434.21,301.16,434.32,300.951,434.426
		c-0.18,0.095-0.355,0.184-0.534,0.274C300.595,434.609,300.773,434.52,300.951,434.426z M281.657,432.215
		c-1.654-0.994-2.621-2.987-4.696-2.698c0-0.001,0-0.001,0-0.001C279.039,429.227,280.005,431.22,281.657,432.215z"/>
			<polygon fill="#080808" points="420.559,526.463 420.556,526.467 420.565,526.456 	" />
			<path fill="#080808" d="M467.759,600.401c1.218-2.951-2.44-5.083-4.08-6.723c-4.36-4.36-9.564-9.517-12.72-13.438
		c-0.265-0.04-0.505-0.097-0.737-0.156c-0.1-0.025-0.197-0.05-0.294-0.078c0.118,0.061,0.234,0.119,0.349,0.187
		c0.152,0.088,0.305,0.181,0.442,0.288c3.29,2.565,5.397,6.563,8.4,9.359c1.465,1.001,2.586,2.467,4.321,4.08
		C465.169,595.528,468.449,597.178,467.759,600.401z"/>
			<path fill="#080808" d="M446.4,577.599c0.092,0.207,0.206,0.387,0.327,0.552c0.021,0.024,0.039,0.055,0.062,0.08
		c0.121,0.147,0.255,0.279,0.396,0.398c0.033,0.027,0.066,0.054,0.102,0.08c0.143,0.109,0.292,0.211,0.448,0.302
		c0.043,0.024,0.088,0.047,0.132,0.07c0.127,0.068,0.256,0.133,0.388,0.193c0.148,0.065,0.299,0.134,0.45,0.194
		c0.016,0.008,0.03,0.015,0.046,0.021c-0.154-0.095-0.295-0.197-0.429-0.308c-0.024-0.02-0.053-0.039-0.074-0.06
		c-0.128-0.108-0.247-0.224-0.356-0.346c-0.018-0.021-0.032-0.04-0.052-0.062c-0.59-0.686-0.917-1.566-1.087-2.617
		c-0.004-0.021-0.008-0.042-0.011-0.063c-0.031-0.204-0.061-0.414-0.08-0.631c-0.001-0.011-0.002-0.021-0.003-0.031
		c-0.146-1.514-0.066-3.313-0.031-5.327c0.002-0.08,0.003-0.158,0.005-0.239c0.005-0.355,0.009-0.722,0.009-1.092
		c0-19.275,1.02-41.645,0-59.279c0.776-1.225,0.692-3.309,2.16-3.841c5.446-0.724,11.708-0.475,18.48-1.199c-2.08,0-4.16,0-6.24,0
		c-5.742,1.631-14.011-2.16-14.641,5.045C447.309,531.164,446.162,554.952,446.4,577.599z"/>
			<polygon fill="#080808" points="448.751,579.492 448.752,579.492 448.75,579.491 	" />
			<path fill="#080808" d="M127.2,543.041c-1.751,9.71-2.75,20.452-3.36,30.96C124.905,563.901,125.877,553.188,127.2,543.041z" />
			<path fill="#080808" d="M116.93,606.629c-0.017,0.162-0.028,0.329-0.043,0.493c-0.03,0.331-0.061,0.663-0.084,0.998
		c-0.013,0.185-0.022,0.368-0.034,0.553c-0.021,0.349-0.042,0.695-0.06,1.042c-0.002,0.06-0.005,0.113-0.008,0.171
		c-0.016,0.319-0.031,0.636-0.047,0.949c0.002-0.025,0.006-0.05,0.008-0.075c0.908-9.941,4.622-20.53,6.344-29.923
		c0.014-0.078,0.031-0.158,0.045-0.236c0.099-0.551,0.184-1.093,0.268-1.635c0.028-0.179,0.062-0.361,0.088-0.539
		c0.074-0.506,0.131-1.002,0.189-1.499c0.024-0.206,0.057-0.417,0.079-0.622c0.073-0.695,0.128-1.386,0.165-2.063
		c-0.635,7.889-5.129,21.378-6.72,30.96C117.043,605.665,116.982,606.144,116.93,606.629z"/>
			<polygon fill="#080808" points="116.486,613.319 116.487,613.314 116.486,613.318 	" />
			<path fill="#080808" d="M458.641,491.44c0.4,0,0.802,0,1.2,0c0.646-0.315,0.862-1.06,1.439-1.439c0-0.08,0-0.16,0-0.24
		c0.289-1.383-0.477-1.979-1.439-1.438c-1.067,0.371-1.781,1.1-1.68,2.641C458.43,491.012,458.534,491.228,458.641,491.44z"/>
			<path fill="#080808" d="M335.04,616.721c-0.93-0.377-1.884-0.738-2.833-1.107c-0.898-0.351-1.792-0.703-2.656-1.081
		c-0.006-0.003-0.014-0.006-0.02-0.009c0.554,0.255,1.104,0.511,1.669,0.76c3.511,1.552,7.294,2.508,10.561,4.08
		c0.173,0.083,0.34,0.175,0.503,0.271c0.043,0.026,0.084,0.058,0.127,0.083c0.119,0.074,0.237,0.149,0.353,0.23
		c0.034,0.021,0.063,0.05,0.099,0.074c0.122,0.09,0.244,0.181,0.363,0.274c0.007,0.005,0.014,0.011,0.021,0.019
		c1.863,1.51,3.247,3.624,5.733,3.604c-2.765-0.384-3.877-3.008-6.236-4.318C340.489,618.36,337.564,617.744,335.04,616.721z"/>
			<path fill="#080808" d="M341.761,485.919c13.019,3.383,25.929,6.873,42.96,6.242c0.453,6.104,1.653,11.467,2.399,17.279
		c-0.396-6.245-1.639-11.643-2.399-17.521C367.647,492.674,355.169,488.831,341.761,485.919z"/>
			<path fill="#080808" d="M133.92,486.161c-1.957,1.026-0.369,4.594,1.92,4.319C136.834,488.961,135.807,486.29,133.92,486.161z" />
			<path fill="#080808" d="M454.081,495.761c-2.664-1.393-5.175,2.371-5.04,4.562C451.771,500.789,453.72,498.456,454.081,495.761z
		 M453.486,495.854c-1.095-0.345-2.287,0.365-3.088,1.423C451.199,496.219,452.393,495.509,453.486,495.854z M449.522,500.32
		L449.522,500.32c-0.085-0.383-0.069-0.797,0.021-1.216C449.453,499.523,449.438,499.938,449.522,500.32z"/>
			<path fill="#080808" d="M139.2,500.801c-0.817,1.942,1.508,4.612,3.36,3.12C142.378,502.017,141.318,500.003,139.2,500.801z
		 M139.44,501.041c1.724-0.688,2.478,1.249,2.88,2.64C141.917,502.29,141.164,500.354,139.44,501.041z"/>
			<path fill="#080808" d="M442.561,506.561c-2.692,0.207-4.552,2.912-3.6,5.28C441.523,511.585,443.106,509.132,442.561,506.561z" />
			<path fill="#080808" d="M462.961,573.281c-2.285-2.123-5.891-4.769-6.721-6.96c-0.104-0.277-0.188-0.595-0.263-0.925
		c0.011,0.468,0.015,0.938,0.021,1.402c3.99,4.57,8.948,8.172,12.96,12.723c2.796,2.161,4.428,5.49,6.96,7.92
		C472.313,582.063,467.759,577.738,462.961,573.281z"/>
			<path fill="#080808" d="M146.16,511.601c-1.256,1.867,0.812,3.997,2.64,4.32C150.876,514.645,148.293,511.65,146.16,511.601z
		 M145.92,512.321c1.542-1.066,3.362,1.106,3.36,3.119C147.537,515.864,146.27,514.058,145.92,512.321z"/>
			<path fill="#080808" d="M431.761,519.761c-2.729-0.398-5.114,3.622-4.318,6C430.368,525.395,431.976,522.475,431.761,519.761z
		 M427.681,525.521c2.258-0.64,4.462-3.569,3.6-5.76c-0.025,0.01-0.048,0.025-0.073,0.035c0.025-0.011,0.05-0.026,0.075-0.036
		C432.143,521.952,429.938,524.883,427.681,525.521C427.681,525.521,427.681,525.521,427.681,525.521z"/>
			<path fill="#080808" d="M159.6,527.44c1.2-2.235-0.753-5.056-2.88-5.521C154.609,523.549,156.733,527.755,159.6,527.44z
		 M159.657,525.543c-0.278-1.519-1.625-3.181-2.457-3.143C158.032,522.361,159.379,524.024,159.657,525.543z"/>
			<path fill="#080808" d="M421.681,533.921c-2.466-0.097-4.056,2.805-2.88,4.8C421.267,538.817,422.856,535.916,421.681,533.921z" />
			<path fill="#080808" d="M450.48,687.761c-2.493-1.348-5.571-2.178-8.882-2.397c-6.459-0.435-15.063,0.779-19.92-0.96
		c-2.743-0.984-4.052-3.887-6.96-3.36c-0.627,1.072,0.087,1.422,0.479,1.92c0.877,1.112,3.729,2.924,5.521,3.602
		c5.483,2.072,14.011,0.56,21.36,1.198c6.888,0.599,10.171,5.677,15.122,4.318C455.806,690.027,452.917,689.078,450.48,687.761z
		 M456.717,692.08c-1.403-1.991-4.289-2.803-6.716-4.079c-6.235-3.278-15.934-1.821-24.721-2.64c-4.107-0.385-6.648-2.511-9.12-4.08
		c-0.344-0.366-1.25-0.233-1.199,0.478c-0.052-0.713,0.855-0.846,1.199-0.479c2.472,1.569,5.013,3.696,9.12,4.08
		c8.787,0.816,18.483-0.641,24.721,2.64c2.428,1.276,5.314,2.089,6.72,4.08C456.72,692.08,456.719,692.08,456.717,692.08z"/>
			<path fill="#080808" d="M444.686,689.285c0.004,0.003,0.006,0.005,0.007,0.008c0.018,0.042,0.028,0.089,0.028,0.148
		c0.039,0.277-0.053,0.427-0.238,0.479c-3.89,2.135-9.868,2.74-15.964,2.778c6.095-0.039,12.071-0.645,15.959-2.777
		c0.188-0.053,0.278-0.2,0.239-0.479c0-0.06-0.012-0.105-0.028-0.146C444.689,689.29,444.688,689.288,444.686,689.285
		c-0.051-0.104-0.142-0.171-0.186-0.283c-0.006-0.014-0.016-0.024-0.02-0.041c-0.084,0.024-0.178,0.043-0.263,0.066
		c-0.209,0.058-0.43,0.105-0.643,0.159c0.214-0.054,0.437-0.103,0.646-0.16c-0.002,0-0.005,0.001-0.007,0.001
		c-3.144,0.274-6.812,0.855-10.685,1.256c-0.112,0.012-0.224,0.022-0.334,0.036c-0.548,0.055-1.102,0.102-1.654,0.146
		c-0.17,0.017-0.337,0.031-0.507,0.044c-0.713,0.056-1.433,0.101-2.151,0.131c-5.137,0.228-10.588-0.859-14.641,0.239
		c0.771,1.788,0.667,4.454,2.641,5.04c1.488-0.261,0.119-2.687,0.721-3.359c6.574,0.592,16.031,0.591,22.317-0.479
		c2.001-0.34,4.97-0.888,5.04-3.12c-0.148,0.011-0.31,0.029-0.461,0.041C444.545,689.114,444.636,689.179,444.686,689.285z
		 M417.123,695.44c-0.001,0-0.002,0-0.003,0c0.894-0.839-0.586-2.598-0.001-3.119c0.001,0,0.002,0,0.003,0
		C416.54,692.844,418.018,694.604,417.123,695.44z M416.527,690.848c-0.026,0-0.057,0.001-0.082,0.001
		c-0.586,0.008-1.16,0.019-1.723,0.03v-0.001c0.563-0.014,1.14-0.021,1.727-0.03c0.025,0,0.056-0.001,0.082-0.001
		c4.502-0.055,9.679,0.025,14.699-0.175C426.209,690.873,421.029,690.794,416.527,690.848z"/>
			<path fill="#080808" d="M285.84,101.68c1.719-0.122,3.389-0.291,4.801-0.72C288.83,100.99,287.16,101.159,285.84,101.68z" />
			<path fill="#080808" d="M274.32,103.36c2.945-0.094,5.837-0.243,8.16-0.96C279.758,102.717,276.866,102.866,274.32,103.36z" />
			<path fill="#080808" d="M206.64,190c0,3.84,0,7.68,0,11.52c0,0.24,0,0.48,0,0.72c0,1.44,0,2.88,0,4.32
		c0.155,1.045-0.313,2.713,0.24,3.36c0,0.16,0,0.32,0,0.48c0.552,0.568,0.086,2.154,0.24,3.12c0.59,3.811,0.751,8.049,1.92,11.28
		c-3.085-16.689-2.507-39.185-0.96-57.12c-0.587,2.373-0.502,5.417-0.96,7.92c-0.156,1.284,0.315,3.195-0.24,4.08
		c0.021,1.381,0.052,2.772-0.24,3.84c-0.399,1.444-0.399,4.556,0,6C206.64,189.68,206.64,189.84,206.64,190z"/>
			<path fill="#080808" d="M377.76,187.6c0-1.12,0-2.24,0-3.36c0-0.16,0-0.32,0-0.48c-0.409,3.431-0.601,7.08-0.72,10.8
		c0,0.16,0,0.32,0,0.48c0.055,0.187,0.007,0.474,0.24,0.48c0.292-1.067,0.261-2.457,0.238-3.836
		c0.001-0.001,0.002-0.002,0.003-0.003c0-0.08,0-0.16,0-0.24c0.076-0.522-0.002-1.201-0.03-1.829
		c0.028,0.628,0.107,1.307,0.03,1.829c0.553-0.647,0.085-2.315,0.238-3.36C377.76,187.92,377.76,187.76,377.76,187.6z"/>
			<path fill="#080808" d="M221.28,306.4c-0.432-2.847-0.8-5.759-1.44-8.4C220.057,301.063,220.495,303.905,221.28,306.4z" />
			<path fill="#080808" d="M132,460.48c0.829-3.813,1.313-7.969,1.92-12C132.925,452.126,132.452,456.293,132,460.48z" />
			<path fill="#080808" d="M129.84,468.881c1.242-1.798,1.727-4.354,1.92-7.2C131.14,464.101,130.766,466.767,129.84,468.881z" />
			<path fill="#080808" d="M124.08,492.881c0.383-0.656,0.494-1.586,0.48-2.64C124.176,490.897,124.066,491.827,124.08,492.881z" />
			<path fill="#080808" d="M121.68,515.202c0,2.397,0,4.799,0,7.198c0.08,0,0.16,0,0.24,0c0,0.001,0,0.001,0,0.002
		c0,1.119,0,2.238,0,3.358c0.202,1.238,0.313,2.567,0.24,4.08c0.399,1.763,0.399,5.197,0,6.96c0.04,2.6-0.457,4.663-0.48,7.2
		c2.294-14.582-1.372-33.898,1.92-48.24c-1.036,4.244-1.578,8.982-1.68,14.16c-0.398,0.649-0.398,2.95,0,3.601
		C121.755,514.414,121.804,514.15,121.68,515.202z"/>
			<path fill="#080808" d="M120.72,550.961c0.536-1.464,0.583-3.417,0.72-5.28C120.904,547.145,120.856,549.098,120.72,550.961z" />
			<path fill="#080808" d="M111.12,631.121c0.136-1.197,0.794-2.398,0.96-3.6c0.978-7.079-0.779-14.559,0.24-21.603
		c0.949-6.563,2.722-12.055,3.36-17.76c-1.634,6.286-2.613,13.228-3.84,19.92c0,5.12,0,10.24,0,15.358
		c-0.257,1.662,0.553,4.395-0.48,5.279c-0.473,0.167,0.059,1.34-0.48,1.44c0,1.84,0,3.68,0,5.52c0,0.001,0,0.003,0,0.004
		c-0.08,0-0.159,0-0.239,0c0,1.119,0,2.237,0,3.356c0,0.001,0,0.003,0,0.004c0,1.199,0,2.397,0,3.596
		C111.639,638.944,110.662,635.14,111.12,631.121z M110.848,636.098c-0.012,0.588,0.08,1.279,0.07,1.869
		C110.927,637.376,110.835,636.686,110.848,636.098z"/>
			<path fill="#080808" d="M108.24,652.721c-0.604,1.877-0.647,4.313-0.72,6.723c-0.02,0.27-0.03,0.55-0.033,0.836
		c0.003-0.287,0.013-0.567,0.033-0.838c-0.558,1.762-0.082,4.559-0.24,6.72c0.209-0.66,0.273-1.467,0.279-2.33
		c-0.006,0.864-0.07,1.671-0.279,2.332c0.168,3.448-0.688,5.872-0.96,8.88C108.403,669.044,107.312,659.873,108.24,652.721z"/>
			<path fill="#080808" d="M184.08,678.401c0.555,0.806,0.084,2.636,0.24,3.84c0,0.24,0,0.479,0,0.72c0,1.2,0,2.4,0,3.602
		c0.08,0,0.16,0,0.24,0c0,2,0,4,0,6c0,0.801,0,1.601,0,2.398c0,0.4,0,0.8,0,1.2c0.556,0.965,0.083,2.957,0.24,4.32
		c0.472,1.848,0.149,4.488,0.72,6.238c-1.117-10.244-0.037-22.684-1.68-32.398C183.752,675.849,184.267,676.774,184.08,678.401z"/>
			<path fill="#080808" d="M105.12,688.483c0-2.16,0-4.32,0-6.48c0-0.001,0-0.001,0-0.002c0.332-1.588,0.469-3.371,0.72-5.04
		c-0.528,1.313-0.835,2.845-0.72,4.8c0,0.001,0,0.001,0,0.002c-0.552,0.566-0.086,2.152-0.24,3.118c0,1.199,0,2.399,0,3.601
		c-0.15,0.729,0.308,2.065-0.24,2.397c-0.235,2.728-0.808,5.112-0.72,8.16C104.311,695.514,104.946,692.229,105.12,688.483z"/>
			<path fill="#080808" d="M186.96,717.521c-0.174-2.388-0.47-4.65-0.96-6.723C186.101,713.261,186.413,715.509,186.96,717.521z" />
			<path fill="#080808" d="M480.727,711.041c0-0.004,0-0.007,0-0.011c0-0.079,0-0.159,0-0.239c-0.003-0.003-0.004-0.007-0.006-0.009
		c0-1.033,0-2.067,0-3.101c-0.08,0-0.16,0-0.238,0c0.063,0.402,0.021,0.913-0.013,1.413c0.032-0.5,0.075-1.011,0.011-1.413
		c0-0.721,0-1.438,0-2.158c-0.554-3.209-0.776-6.744-1.199-10.08c-0.364-0.599-0.464-1.457-0.48-2.4
		c-0.508-1.332-0.892-2.788-0.96-4.56c-0.521,0.038-0.106-0.854-0.24-1.2c-0.095-0.126-0.174-0.268-0.237-0.425
		c0.063,0.156,0.142,0.298,0.236,0.423c0.019-0.896-0.1-1.657-0.478-2.158c-0.825-1.977-1.063-4.538-1.44-6.96
		c-0.001-0.001-0.001-0.003-0.002-0.004c-0.121-0.836-0.107-1.805-0.477-2.394c0-0.001,0-0.002,0-0.003c0-0.079,0-0.159,0-0.237
		c-0.001-0.002-0.001-0.004-0.002-0.006c-0.017-1.104-0.111-2.124-0.479-2.875c0,0,0,0,0-0.001c-0.357-1.961-0.643-3.997-0.72-6.238
		c-0.069-0.042-0.122-0.101-0.162-0.172c0.04,0.069,0.092,0.127,0.16,0.168c0-0.802,0-1.603,0-2.4
		c-0.149-0.731,0.307-2.067-0.24-2.399c0-0.001,0-0.001,0-0.002c0-0.398,0-0.801,0-1.198c0-0.88,0-1.76,0-2.641
		c-0.08,0-0.16,0-0.239,0c0-0.72,0-1.438,0-2.159c-0.001,0-0.002,0-0.003,0c0-0.561,0-1.118,0-1.681
		c-0.146-0.651,0.307-1.904-0.237-2.16c0-0.001,0-0.001,0-0.002c-0.139-0.419,0.286-1.4-0.236-1.437c0-0.238,0-0.478,0-0.715
		c-0.069-0.062-0.122-0.139-0.162-0.229c0.04,0.087,0.091,0.163,0.158,0.223c-0.152-0.885,0.307-2.381-0.236-2.875
		c0-0.001,0-0.001,0-0.002c-0.225-2.733-0.743-5.177-0.72-8.157c-0.069-0.042-0.122-0.1-0.162-0.171
		c0.04,0.068,0.091,0.125,0.158,0.166c0-0.802,0-1.602,0-2.399c-0.079,0-0.157,0-0.236,0c0-0.001,0-0.001,0-0.002
		c0.079,0,0.157,0,0.236,0c0-1.12,0-2.24,0-3.36c-0.08,0-0.16,0-0.24,0c0-0.479,0-0.96,0-1.438c-0.078,0-0.156,0-0.234,0
		c0-0.001,0-0.001,0-0.002c0.078,0,0.156,0,0.234,0c0-0.4,0-0.801,0-1.2c-0.157-1.363,0.315-3.355-0.24-4.32c0-0.397,0-0.8,0-1.2
		c-0.077,0-0.155,0-0.234,0c0-0.001,0-0.001,0-0.002c0.078,0,0.154,0,0.232,0c-0.152-0.964,0.314-2.55-0.238-3.117
		c0-0.479,0-0.962,0-1.438c-0.078,0-0.155,0-0.233,0c0-0.001,0-0.001,0-0.002c0.078,0,0.155,0,0.233,0c0-1.28,0-2.56,0-3.84
		c-0.08,0-0.159,0-0.239,0c0-1.44,0-2.88,0-4.32c0-3.76,0-7.52,0-11.28c-0.078,0-0.156,0-0.234,0c0-0.001,0-0.001,0-0.002
		c0.078,0,0.156,0,0.234,0c0-0.56,0-1.117,0-1.68c0.08,0,0.159,0,0.239,0c0.038-0.28-0.041-0.679,0.24-0.718
		c0.381,0.581,0.858,1.062,1.44,1.438c0.439,0.28,0.813,0.628,1.2,0.96c1.057,0.1,1.981,0.064,1.68-1.197c0.08,0,0.16,0,0.24,0
		c0.797-2.789,1.361-5.806,1.675-9.075c0.003-0.012,0.006-0.025,0.009-0.037c0.101-1.422,0.107-2.936,0.479-4.08
		c0-0.08,0-0.16,0-0.24c0-0.004,0-0.009,0.001-0.013c0.547-0.571,0.083-2.151,0.235-3.115c0-0.234,0-0.469,0-0.703
		c0.002-0.003,0.002-0.006,0.004-0.009c0.021-2.06,0.348-3.813,0.721-5.521c0-0.003,0-0.006,0.001-0.009
		c0.347-0.049,0.216-0.58,0.235-0.958c0.224-1.217,0.518-3.621,0.003-4.791c0,0,0-0.001,0-0.002c0-0.24,0-0.479,0-0.72
		c-0.069-0.111-0.123-0.238-0.164-0.378c0.04,0.137,0.093,0.262,0.161,0.371c0-1.36,0-2.72,0-4.08c-0.001,0-0.002,0-0.003,0
		c0-0.48,0-0.96,0-1.44c-0.157-6.24,0.322-13.119-0.237-18.96c0,0.001,0,0.001,0,0.002c-0.016-0.307-0.311-0.331-0.24-0.72
		c-0.061-0.131-0.106-0.275-0.143-0.433c-0.031-0.137-0.057-0.278-0.075-0.427c-0.001-0.007-0.001-0.015-0.001-0.022v-0.001
		c-0.02-0.404-0.029-0.815-0.051-1.216c0-0.002,0-0.007-0.002-0.01c0-0.005-0.001-0.011-0.001-0.016
		c0.012-0.524,0.041-1.069,0.059-1.599c0.002-0.052,0.001-0.102,0.003-0.149c0.004-0.173,0.007-0.345,0.005-0.512
		c-0.003-0.229-0.012-0.447-0.032-0.656c-0.08,0-0.16,0-0.238,0c0.002,0.023,0.003,0.048,0.005,0.071
		c-0.002-0.022-0.003-0.047-0.005-0.07v-0.001c0-0.001,0-0.001,0-0.002c0.079,0,0.157,0,0.236,0c0-0.479,0-0.96,0-1.44
		c0-0.88,0-1.76,0-2.64c-0.08,0-0.16,0-0.24,0c0-0.64,0-1.28,0-1.92c0-0.24,0-0.48,0-0.72c-0.146-0.651,0.307-1.905-0.238-2.16
		c0,0.64,0,1.28,0,1.92c0-0.002-0.001-0.003-0.002-0.004c0-0.639,0-1.277,0-1.916c0-0.48,0-0.96,0-1.44c-0.079,0-0.156,0-0.235,0
		c0-0.001,0-0.001,0-0.002c0.079,0,0.156,0,0.235,0c-0.15-0.728,0.308-2.065-0.239-2.397c0-0.479,0-0.959,0-1.438
		c-0.079,0-0.157,0-0.236,0c0-0.001,0-0.001,0-0.002c0.079,0,0.157,0,0.236,0c0-0.88,0-1.76,0-2.64c-0.08,0-0.16,0-0.24,0
		c0-1.602,0-3.2,0-4.8c0-0.24,0-0.48,0-0.721c0-1.84,0-3.68,0-5.52c0-0.24,0-0.48,0-0.72c0-1.2,0-2.4,0-3.603
		c-0.152-0.802,0.304-2.213-0.234-2.631c0-0.001,0-0.003,0-0.004c0-0.08,0-0.16,0-0.24c-0.002-0.008-0.008-0.013-0.01-0.021
		c-0.051-0.661-0.421-1.002-1.194-0.942c-0.562,0-1.119,0-1.682,0c-0.318,0-0.64,0-0.96,0c-1.621-0.167-1.621,0.407,0,0.24
		c0.32,0,0.642,0,0.96,0c0.896,0.005,1.932-0.129,2.496,0.207c-0.566-0.332-1.6-0.199-2.492-0.204c-0.318,0-0.64,0-0.96,0
		c-0.259,0.473-1.989,0.409-1.68-0.48c0-0.006,0-0.011,0-0.016c0.394-0.896,0.392-3.423-0.004-4.308v0.001
		c-0.144-0.497,0.292-1.57-0.236-1.679c-0.249-0.631-0.287-1.472-0.238-2.396c0-0.08,0-0.16,0-0.24
		c-0.002-0.014-0.004-0.03-0.006-0.044c0-1.986,0-3.974,0-5.961c0.08,0,0.16,0,0.24,0c0-1.04,0-2.08,0-3.12
		c-0.001,0-0.001,0-0.002,0c0.16-1.438,1.359-1.841,1.44-3.36c0.002-0.307,0.002-0.613,0.002-0.921c0,0.308,0,0.616,0,0.921
		c0.699-0.522-0.1-3.897,0-1.198v0.001c-0.53-0.189-0.09-1.347-0.234-1.916c-0.003-0.004-0.005-0.01-0.008-0.014
		c-0.169-1.022-0.391-1.991-0.95-2.621c-0.001-0.003-0.001-0.005-0.002-0.007c-0.237-0.563-0.421-1.179-0.479-1.92
		c0-0.08,0-0.16,0-0.24c-0.003-0.012-0.005-0.024-0.008-0.036c0-2.387,0-4.779,0-7.167c0-0.481,0-0.962,0-1.441
		c-0.146-0.574,0.304-1.741-0.238-1.92c0,0.56,0,1.119,0,1.679c-0.001-0.001-0.001-0.002-0.002-0.002c0-0.559,0-1.118,0-1.677
		c0.021-0.337,0.037-0.67-0.231-0.717c-0.297-1.782-0.597-3.562-0.721-5.519c-0.003-0.006-0.005-0.013-0.008-0.019
		c-0.018-0.928-0.116-1.775-0.471-2.368c-0.001-0.004-0.001-0.008-0.001-0.012c-1.106-6.973-2.555-13.605-3.601-20.642
		c-0.003-0.008-0.005-0.017-0.009-0.024c-0.098-1.4-0.335-2.66-0.709-3.782c0-0.012-0.002-0.021-0.002-0.033
		c-0.345-1.417-0.572-2.947-0.722-4.561c-0.003-0.005-0.004-0.011-0.007-0.015c-0.072-1.039-0.25-1.97-0.712-2.619
		c0-0.002,0-0.005-0.001-0.008c0-0.08,0-0.16,0-0.239c-0.006-0.014-0.013-0.026-0.02-0.04c-0.794-2.69-2.15-4.813-3.563-6.886
		c-0.006-0.012-0.012-0.022-0.018-0.034c0-0.079,0-0.159,0-0.239c-0.003-0.004-0.007-0.009-0.01-0.013
		c-0.619-1.375-1.835-2.156-2.394-3.594c-1.188-0.813-1.979-2.021-2.881-3.12c-0.854-0.505-1.248-1.471-1.92-2.158
		c-1.139-0.943-1.971-2.19-3.12-3.12c-4.576-2.864-7.986-6.895-13.682-8.64c2.182,1.011,4.226,2.159,6.165,3.411
		c-1.938-1.25-3.98-2.396-6.159-3.406c-0.94-0.019-1.497-0.423-2.16-0.72c-0.946-0.173-1.942-0.297-2.642-0.72
		c-1.104-0.014-2.129-0.111-2.88-0.48c-0.993,0.033-1.854-0.066-2.398-0.48c-3.567-0.432-6.938-1.064-10.08-1.92
		c-1.878-0.202-3.849-0.313-6-0.24c-0.458,0.342-1.042,0.558-1.682,0.72c-0.915,0.268-2.84-0.515-3.84-0.96
		c-1.066-0.132-2.211-0.189-2.88-0.72c-0.885-0.087-1.593-0.348-2.146-0.766c0.553,0.416,1.262,0.676,2.142,0.762
		c-0.676-0.445-1.581-0.66-2.4-0.96c0.001,0.001,0.003,0.002,0.005,0.003c-1.6-0.322-2.831-1.008-4.079-1.68
		c-1.606-0.873-3.276-1.684-4.802-2.64c-0.936,0.056-1.242-0.516-1.92-0.72c-1.063-0.376-2.354-0.526-3.12-1.2
		c-0.418,0.018-0.744-0.054-0.96-0.24c-4.482-1.516-7.115-4.883-11.521-6.48c-1.467-0.292-2.773-0.746-4.08-1.2
		c-1.299-0.46-2.839-0.681-3.84-1.44c-0.755,0.115-0.938-0.342-1.439-0.48c-1.136-0.429-2.392-1.034-3.84-0.48
		c-0.4-0.468-0.826-0.914-1.26-1.351c-0.065-0.065-0.129-0.132-0.194-0.197c-0.413-0.408-0.837-0.804-1.269-1.192
		c-0.098-0.087-0.193-0.174-0.291-0.26c-0.414-0.367-0.832-0.729-1.255-1.086c-1.052-0.889-2.117-1.761-3.152-2.666
		c-0.004-0.003-0.009-0.007-0.013-0.011c-0.485-0.424-0.961-0.856-1.428-1.3c-0.033-0.033-0.067-0.066-0.104-0.099
		c-0.464-0.446-0.915-0.903-1.348-1.379c-0.008-0.007-0.015-0.014-0.021-0.021c-0.441-0.49-0.868-0.998-1.268-1.532
		c-0.001-0.001-0.003-0.003-0.004-0.004c0,0-0.001-0.001-0.002-0.002c-0.031-0.042-0.064-0.082-0.098-0.124
		c-0.038-0.053-0.071-0.109-0.109-0.162c0,0,0,0,0-0.001c-0.014-0.016-0.021-0.032-0.033-0.047
		c-0.044-0.062-0.086-0.125-0.129-0.188c-0.017-0.021-0.029-0.043-0.044-0.064c-0.122-0.176-0.246-0.349-0.362-0.531
		c-0.203-0.319-0.397-0.645-0.583-0.983c0-0.001,0-0.003,0-0.005c0-0.079,0-0.156,0-0.235c-0.696-1.383-0.913-3.247-0.96-5.28
		c-0.401-0.398-0.19-1.409-0.239-2.16c0-0.08,0-0.16,0-0.24c-0.206-0.125-0.27-0.393-0.277-0.716
		c0.008,0.322,0.072,0.588,0.277,0.712c-0.15-0.729,0.306-2.065-0.239-2.399c0-0.159,0-0.318,0-0.477
		c-0.139-0.143-0.213-0.35-0.249-0.596c0.036,0.244,0.11,0.45,0.248,0.592c-0.152-0.966,0.313-2.552-0.238-3.12
		c0.02,0.122,0.028,0.253,0.031,0.391c-0.003-0.137-0.013-0.267-0.031-0.387c0-0.001,0-0.002,0-0.004c0-0.159,0-0.317,0-0.476
		c-0.001-0.006-0.001-0.013-0.002-0.019c0-2.555,0-5.111,0-7.666c0.08,0,0.158,0,0.238,0c0-1.28,0-2.56,0-3.84c0-0.4,0-0.8,0-1.2
		c0-2.64,0-5.28,0-7.92c0-0.24,0-0.48,0-0.72c-0.558-1.443-0.082-3.918-0.238-5.76c0.261-2.133,1.269-3.515,1.914-5.265
		c0.003-0.003,0.005-0.007,0.008-0.01c0.363-0.837,0.438-1.962,1.2-2.4c0.004-0.007,0.008-0.015,0.012-0.022
		c1.834-2.545,3.277-5.484,4.3-8.843c0.002-0.005,0.005-0.01,0.007-0.015c-0.036-0.596,0.124-0.996,0.479-1.2
		c0.243-0.75,0.464-1.522,0.686-2.294c-0.221,0.77-0.441,1.54-0.684,2.289c1.846-3.432,2.103-8.455,3.599-12.24
		c0.355-0.041,0.876,0.081,0.956-0.235c0.001,0,0.001,0,0.002,0c0-0.001,0.001-0.001,0.001-0.003c0-0.001,0.001-0.001,0.001-0.002
		h-0.001c-0.033-0.511,0.853-0.104,1.199-0.235c0.008-0.003,0.015-0.007,0.022-0.011c3.869-0.921,6.016-3.565,8.859-5.514
		c0.699-0.9,1.499-1.701,2.399-2.4c-0.056-0.451,0.264-0.53,0.237-0.952c0.001-0.001,0.002-0.002,0.003-0.003
		c0.095-2.146,0.561-3.919,1.198-5.52c0.127-0.574,0.269-1.134,0.428-1.676c-0.158,0.541-0.3,1.099-0.426,1.672
		c0.838-1.962,1.491-4.108,2.399-6c-0.001,0.001-0.001,0.002-0.002,0.002c0.325-1.356,0.953-2.406,1.439-3.599
		c0.002-0.003,0.004-0.007,0.005-0.01c1.784-2.772,3.66-5.453,4.798-8.874c0.657-2.061-0.155-5.596,0.479-7.68
		c-0.087,0.012-0.155,0.043-0.21,0.088c0.055-0.045,0.124-0.077,0.212-0.089c-0.037-0.594,0.373-0.742,0.478-1.195c0,0,0,0,0-0.001
		c0.001-0.001,0.002-0.002,0.002-0.004c0,0,0,0-0.001,0c0.232-1.043,0.832-1.725,1.198-2.636c0.439-0.919,0.539-2.181,0.96-3.12
		c0-0.16,0-0.32,0-0.48c0-1.6,0-3.2,0-4.8c0-0.24,0-0.48,0-0.72c-0.552-0.489-0.087-1.993-0.239-2.88
		c-0.276-1.563-0.444-3.235-0.721-4.8c-0.058-0.224-0.125-0.437-0.201-0.639c-0.089-0.234-0.19-0.454-0.302-0.667
		c-0.026-0.054-0.058-0.106-0.087-0.159c-0.091-0.166-0.187-0.326-0.287-0.481c-0.029-0.047-0.061-0.095-0.092-0.141
		c-0.126-0.186-0.257-0.367-0.394-0.543c-0.063-0.081-0.128-0.16-0.192-0.24c-0.063-0.082-0.13-0.164-0.195-0.244
		c-0.677-0.817-1.387-1.601-1.891-2.59c-0.072-0.141-0.138-0.288-0.2-0.438c-0.063-0.149-0.118-0.305-0.171-0.465
		c-0.011-0.032-0.021-0.065-0.028-0.098c-0.043-0.139-0.082-0.279-0.115-0.427c-0.021-0.082-0.033-0.168-0.049-0.254
		c-0.021-0.113-0.045-0.223-0.061-0.341c-0.027-0.216-0.049-0.438-0.057-0.674c0-0.08,0-0.16,0-0.24
		c-0.806-1.754-1.296-3.824-2.399-5.28c-0.495-0.925-0.016-2.655,0.239-3.6c0-0.16,0-0.32,0-0.479
		c0.154-0.966-0.313-2.552,0.24-3.12c0-0.24,0-0.48,0-0.72c0.152-0.887-0.313-2.391,0.239-2.88c0-0.08,0-0.16,0-0.24
		c0.154-1.045-0.313-2.713,0.239-3.36c0-0.32,0-0.64,0-0.96c0.146-0.574-0.302-1.741,0.24-1.92c0-0.16,0-0.32,0-0.48
		c0.119-3.72,0.312-7.369,0.72-10.8c0-0.72,0-1.44,0-2.16c0-1.04,0-2.08,0-3.12c0-0.16,0-0.32,0-0.48
		c-0.548-0.333-0.09-1.67-0.239-2.4c-0.516-4.285-1.103-8.499-1.681-12.72c-0.489-1.51-0.87-3.13-0.96-5.04
		c-0.29-0.831-0.438-1.801-0.479-2.88c-0.363-0.596-0.464-1.457-0.479-2.4c-0.75-2.531-1.11-5.449-1.438-8.4
		c-0.412-0.788-0.637-1.765-0.722-2.88c-0.331-0.31-0.235-1.042-0.239-1.68c-0.424-0.698-0.548-1.693-0.721-2.64
		c-0.489-1.35-0.935-2.746-1.199-4.32c-0.638-0.723-0.787-1.932-1.199-2.88c0-0.08,0-0.16,0-0.24c-0.765-1.077-1.31-2.373-1.92-3.6
		c-0.521-0.201-0.818-0.621-1.682-0.48c-4.935-6.906-11.284-12.396-19.438-16.08c-0.598,0.036-0.996-0.124-1.2-0.48
		c-1.25-0.19-2.271-0.611-3.12-1.2c-0.596,0.036-0.995-0.124-1.199-0.479c-1.576-0.344-2.86-0.979-4.08-1.68
		c-1.761-0.399-3.292-1.028-4.8-1.68c-1.78-0.22-3.18-0.82-4.561-1.44c-0.614-0.025-1.146-0.136-1.439-0.48
		c-1.288-0.072-2.229-0.489-3.12-0.96c-0.778-0.02-1.477-0.124-1.92-0.48c-5.929-0.472-12.657-0.143-18.961-0.24
		c-0.079,0-0.159,0-0.239,0c-2.555,0.486-5.961,0.119-8.641,0.48c-0.029,0.53-1.018,0.102-1.439,0.24
		c-1.41,0.429-3.082,0.599-4.799,0.72c-0.935,0.425-2.149,0.57-3.36,0.72c-2.323,0.717-5.215,0.866-8.16,0.96
		c-2.444,0.596-4.658,1.422-7.2,1.92c-1.416,0.665-3.008,1.152-4.8,1.44c-1.013,0.586-2.458,0.742-3.84,0.96
		c-0.805,0.475-1.771,0.789-2.88,0.96c-0.41,0.39-0.873,0.727-1.68,0.72c-0.678,0.44-1.351,0.882-2.021,1.324
		c-0.17,0.112-0.338,0.225-0.507,0.337c-0.539,0.358-1.074,0.717-1.606,1.078c-0.155,0.105-0.31,0.21-0.464,0.316
		c-2.095,1.429-4.134,2.895-6.091,4.431c-0.041,0.032-0.082,0.064-0.122,0.096c-2.007,1.581-3.927,3.242-5.729,5.024l-0.001,0.001
		c-0.041,0.041-0.082,0.083-0.123,0.124c-0.002,0.002-0.003,0.004-0.005,0.006c-1.312,1.306-2.563,2.677-3.737,4.132
		c-0.043,0.054-0.085,0.109-0.128,0.163c-0.486,0.608-0.958,1.233-1.417,1.872c-0.122,0.169-0.244,0.338-0.364,0.51
		c-0.497,0.711-0.982,1.435-1.444,2.186c-1.89,3.079-3.172,6.779-5.52,9.6c-1.295,1.557-3.273,2.328-4.8,3.6
		c-3.226,2.688-4.902,6.198-7.68,9.12c-0.858,0.741-1.042,2.157-1.68,3.12c-0.924,2.917-1.594,6.086-2.16,9.36
		c-0.146,0.574,0.301,1.741-0.24,1.92c-1.547,17.937-2.125,40.431,0.96,57.12c0.412,0.788,0.635,1.765,0.72,2.879
		c0-0.001-0.001-0.002-0.001-0.003c-0.006,1.206,0.646,1.754,0.72,2.88c-3.308,0.452-5.33,2.19-6.48,4.8c0,2.72,0,5.44,0,8.16
		c0,0.16,0,0.32,0,0.48c0,0.96,0,1.92,0,2.88c0.159,2.162-0.318,4.958,0.24,6.72c-0.02,0.341-0.039,0.68,0.24,0.72
		c-0.09,2.17,0.112,4.047,0.72,5.52c-0.242-1.918-0.407-3.914-0.72-5.76c0-1.92,0-3.84,0-5.76c-0.278-7.724-1.896-17.566,6.24-17.52
		c-0.018-0.322-0.058-0.622-0.114-0.905c0.057,0.285,0.097,0.586,0.115,0.909c-8.136-0.046-6.518,9.796-6.24,17.52
		c0,1.92,0,3.84,0,5.76c0.313,1.847,0.478,3.842,0.72,5.76c0.356,0.444,0.46,1.14,0.48,1.919c0,0,0-0.001-0.001-0.002
		c0.511,2.129,1.334,3.946,2.16,5.76c-0.242-1.077-0.561-2.077-0.929-3.027c0.369,0.951,0.688,1.952,0.93,3.03
		c0.279,0.04,0.261,0.378,0.24,0.719c0-0.001,0-0.002-0.001-0.003c0.451,2.589,1.215,4.865,1.92,7.2
		c-0.151-1.368-0.435-2.604-0.778-3.78c0.344,1.177,0.627,2.414,0.779,3.784c0.396,0.033,0.396,1.647,0,1.68
		c-0.02,0.138-0.005,0.243,0.035,0.321c-0.042-0.079-0.056-0.186-0.036-0.325c-0.08,0-0.16,0-0.24,0
		c0.695,1.86-1.061,2.579-1.2,4.32c0,0,0,0,0,0.001c-0.397,0.335-0.397,2.303,0,2.64c0,0,0,0,0-0.001
		c0.302,1.138,0.724,2.156,1.44,2.88c3.478-0.042,3.08,3.79,6.464,3.838c0.006,0.001,0.011,0.004,0.017,0.005
		c0.001,0,0.002,0,0.003,0c0.672,0.719,2.029-0.639,2.156,0.717c0.009-0.209-0.004-0.396-0.043-0.556
		c0.039,0.161,0.053,0.348,0.044,0.558c0,0,0-0.001-0.001-0.002c0.025,1.496,0.297,2.744,0.72,3.84
		c-0.018-1.12-0.176-2.101-0.431-2.984c0.256,0.884,0.414,1.866,0.432,2.988c0.383,0.656,0.494,1.586,0.48,2.64
		c0.64,2.64,1.007,5.553,1.44,8.4c0.52-0.041,0.111,0.846,0.239,1.197c0,0,0,0,0-0.001c0.033,1.088,0.368,1.873,0.72,2.64
		c-0.054-0.785-0.167-1.511-0.419-2.099c0.252,0.588,0.366,1.315,0.42,2.103c0.473,0.565,0.565,1.513,0.719,2.397c0,0,0,0,0-0.001
		c0,0,0,0.001,0,0.001s0,0.001,0,0.002c0.002,0.006,0.005,0.011,0.008,0.017c5.374,13.257,13.738,23.523,24.711,31.179
		c0.557,1.204,0.083,3.438,0.24,5.04c0,0.24,0,0.48,0,0.72c0.557,1.363,0.082,3.758,0.24,5.52c0.447,1.473,0.175,3.665,0.72,5.04
		c-0.352-5.328-0.751-10.607-0.72-16.317c0,0,0,0,0.001,0c-0.031,5.71,0.369,10.99,0.72,16.318c0,0,0-0.001-0.001-0.001
		c0.157,1.364-0.316,3.356,0.24,4.32c0,0,0,0,0,0.001c0,1.279,0,2.56,0,3.839c0,0,0,0,0,0.001c0,1.68,0,3.36,0,5.039
		c0.08,0,0.16,0,0.24,0c0,0,0,0,0,0.001c-0.08,0-0.16,0-0.24,0c-0.149,0.891,0.39,2.47-0.48,2.64c0,0,0,0,0-0.001
		c-0.737,0.063-1.079,0.521-1.68,0.72c-3.226-2.23-6.505,0.977-8.4,2.4c2.339-1.12,5.223-4.378,8.64-2.16
		c0.203-0.517,1.053-0.387,1.44-0.72c0,0,0,0,0,0.001c1.112,0.152,0.608-1.313,0.72-2.16c0.584-3.145,0.092-7.291-0.24-10.32
		c0,0,0,0,0-0.001c-0.116-0.844,0.118-2.038,0.009-2.889c0.11,0.851-0.125,2.048-0.008,2.893c0.332,3.029,0.824,7.175,0.24,10.32
		c-0.112,0.848,0.392,2.313-0.72,2.16c-0.387,0.333-1.237,0.203-1.44,0.72c-3.417-2.218-6.301,1.04-8.64,2.16
		c-0.316,0.484-0.788,0.812-1.44,0.96c-3.115,2.645-6.397,5.123-10.8,6.48c-0.941,0.659-2.049,1.15-3.36,1.44
		c-0.588,0.452-1.174,0.906-2.16,0.96c-0.053,0.187-0.201,0.279-0.48,0.24c-0.757,0.523-1.485,1.075-2.64,1.2
		c-0.204,0.356-0.604,0.516-1.2,0.48c-1.569,0.911-3.072,1.889-5.04,2.4c-0.865,0.58-2.671,1.482-3.36,1.44
		c-0.81-0.47-2.249-0.312-3.12-0.72c-1.106-0.014-2.129-0.111-2.88-0.48c-1.706,0.186-2.677-0.363-4.32-0.24
		c-0.647,0.553-2.315,0.085-3.36,0.24c-1.37,0.391-2.89,0.631-4.56,0.72c-0.501,0.379-1.263,0.498-2.16,0.48
		c-1.365,0.555-2.973,0.867-4.8,0.96c-0.501,0.377-1.263,0.498-2.16,0.48c-0.99,0.37-2.036,0.685-3.36,0.72
		c-1.116,0.404-2.356,0.686-3.6,0.96c-0.653,0.626-1.704,0.855-2.64,1.2c-15.291,11.509-28.166,25.435-35.52,44.88
		c-0.36,1.72-0.99,3.17-1.68,4.562c-0.606,4.031-1.091,8.188-1.92,12c-0.131,0.349,0.28,1.238-0.24,1.198
		c-0.194,2.849-0.678,5.402-1.92,7.2c-0.025,0.614-0.136,1.146-0.48,1.439c-0.072,1.288-0.489,2.229-0.96,3.12
		c-0.084,1.113-0.308,2.092-0.72,2.88c-0.085,1.114-0.308,2.093-0.72,2.881c-0.109,1.171-0.347,2.212-0.72,3.119
		c-0.137,1.624-0.452,3.067-0.96,4.318c-0.08,1.361-0.301,2.579-0.72,3.603c0.014,1.054-0.097,1.981-0.48,2.64
		c-0.014,1.106-0.111,2.129-0.48,2.88c-3.292,14.344,0.375,33.658-1.92,48.24c-0.143,0.497,0.296,1.576-0.24,1.68
		c-0.137,1.863-0.184,3.816-0.72,5.28c-0.225,2.336-0.661,4.459-1.2,6.48c-0.174,4.706-0.878,8.881-1.68,12.96
		c0,1.357,0,2.72,0,4.079c0.156,4.955-1.012,8.589-1.92,12.479c-0.131,0.349,0.281,1.24-0.24,1.2
		c-0.638,5.705-2.411,11.196-3.36,17.76c-1.019,7.044,0.738,14.521-0.24,21.603c-0.166,1.199-0.824,2.4-0.96,3.6
		c-0.458,4.019,0.519,7.823-0.48,11.521c-0.138,0.424,0.29,1.409-0.24,1.439c-0.411,2.311-0.869,4.57-1.68,6.479
		c-0.071,0.81-0.121,1.64-0.48,2.158c-0.928,7.152,0.163,16.323-1.92,22.32c-0.02,0.78-0.124,1.478-0.48,1.92
		c-0.251,1.669-0.388,3.452-0.72,5.04c0,2.16,0,4.32,0,6.48c-0.174,3.744-0.809,7.029-1.2,10.56c-0.138,0.422,0.29,1.41-0.24,1.44
		c-0.33,3.59-1.485,6.354-2.4,9.357c-0.054,0.986-0.508,1.572-0.96,2.16c-0.607,1.473-1.153,3.007-2.16,4.08
		c-0.423,1.177-0.939,2.261-1.44,3.359c-0.431,2.288-0.172,5.27-0.24,7.921c3.281,0.157,7.199-0.319,10.08,0.238
		c7.063-0.086,14.038,0.044,21.6,0c14.24,0,28.48,0,42.72,0c1.586,0.146,2.46-0.42,4.08-0.238c0.406-0.263,0.676-0.625,0.855-1.063
		c-0.179,0.439-0.45,0.802-0.856,1.064c1.085-0.035,1.029-1.211,1.44-1.92c0.158-1.685-0.317-3.997,0.24-5.28c0-0.24,0-0.479,0-0.72
		c0.157-1.363-0.315-3.355,0.24-4.32c0-0.32,0-0.64,0-0.96c0-1.04,0-2.08,0-3.12c0-4.64,0-9.279,0-13.92c0.08,0,0.16,0,0.24,0
		c0-0.641,0-1.28,0-1.92c0-0.8,0-1.602,0-2.4c0-0.96,0-1.92,0-2.88c0.08,0,0.16,0,0.24,0c-0.152-0.808,0.309-2.228-0.24-2.64
		c0,0,0-0.001,0.001-0.002c0.143-0.497-0.295-1.576,0.239-1.682c0,0.002,0,0.003,0,0.004c0.603-2.196,1.152-4.447,1.439-6.958
		c0.001-0.002,0.001-0.004,0.002-0.006c0.041-0.357-0.086-0.885,0.239-0.96c0,0.002,0,0.003,0,0.004
		c0.556-1.525,1.044-3.116,1.2-5.04c-0.201,0.532-0.366,1.101-0.518,1.681c0.152-0.582,0.318-1.151,0.519-1.685
		c0.131-0.35-0.28-1.24,0.239-1.2c0,0.001,0,0.003,0,0.004c0.786-1.615,0.474-4.327,1.44-5.762c0.203,0.996,0.329,2.07,0.72,2.88
		c-0.139-1.461-0.249-2.948-0.959-3.84c0-0.001,0-0.002,0-0.003c0.711,0.89,0.821,2.38,0.96,3.841
		c0.523,1.797,1.117,3.523,1.2,5.761c1.643,9.718,0.563,22.155,1.68,32.399c0.37,1.149,0.446,2.594,0.48,4.079
		c0.49,2.07,0.786,4.335,0.96,6.721c0.56,2.08,0.891,4.392,1.2,6.722c0.094,0.685,1.121,1.396,1.057,1.938
		c0.059-0.543-0.963-1.252-1.058-1.937c-0.315,1.354,0.952,1.129,0.96,2.16c0-0.001,0-0.001,0.001-0.002c0,0.479,0,0.96,0,1.438
		c0.344,1.097,1.788,1.093,2.88,1.44c7.04,0,14.08,0,21.12,0c12.8,0,25.6,0,38.4,0c2.56,0,5.12,0,7.68,0c15.92,0,31.84,0,47.76,0
		c1.2,0,2.4,0,3.603,0c15.357,0,30.72,0,46.079,0c1.521,0,3.04,0,4.561,0c9.369-0.871,21.028,0.548,30-0.722
		c0.197-0.298,0.384-0.65,0.557-1.045c-0.174,0.397-0.361,0.752-0.56,1.052c-8.97,1.267-20.62-0.15-29.987,0.719
		c-0.004,0-0.009,0-0.013,0c-1.52,0-3.04,0-4.56,0c-15.358,0-30.721,0-46.08,0c-1.198,0-2.398,0-3.601,0c-15.92,0-31.838,0-47.759,0
		c0,0,0,0-0.001,0c-2.56,0-5.12,0-7.679,0c0,0.08,0,0.16,0,0.24c2.56,0,5.12,0,7.679,0c0,0,0,0,0.001,0c15.919,0,31.839,0,47.759,0
		c1.2,0,2.4,0,3.601,0c15.359,0,30.722,0,46.08,0c6.722,0,13.439,0,20.16,0c4.319,0,8.642,0,12.96,0c0-0.08,0-0.159,0-0.239
		c3.058-0.064,2.578-3.662,3.36-6c0-1.602,0-3.2,0-4.8c-0.593-0.857-0.661-1.466-0.48-2.643c0.156-1.283-0.313-3.193,0.24-4.079
		c0-0.239,0-0.479,0-0.721c0.156-1.283-0.313-3.195,0.24-4.08c-0.188-2.182,0.329-3.662,0.239-5.748
		c0.001-0.005,0.002-0.011,0.003-0.017c0.146-0.573-0.298-1.735,0.237-1.919c0,0.002,0,0.003,0,0.005
		c0.001-0.002,0.001-0.004,0.001-0.006c0.001,0,0.001-0.001,0.002-0.001c0-0.003,0-0.007,0-0.01c0.494-1.82,0.503-4.128,0.478-6.464
		V692.8c0.396-0.259,0.396-2.137,0.001-2.396c0-0.003,0-0.007,0-0.01c0-0.001-0.001-0.002-0.001-0.003
		c0.027-1.141-0.069-2.156-0.477-2.861c-0.001-0.005-0.001-0.01-0.001-0.016c-0.355-0.045-0.222-0.58-0.24-0.96
		c-0.009-0.024-0.014-0.05-0.022-0.074c-2.112-7.839,1.146-15.653,1.697-23.647c0.002-0.013,0.004-0.026,0.006-0.039
		c-0.034-1.233,0.355-2.044,0.721-2.88c0.001-0.005,0.002-0.01,0.003-0.015c0.648-2.535,1.576-4.791,1.91-7.641
		c0.003-0.009,0.005-0.017,0.008-0.025c0.073-1.604,0.297-3.063,0.72-4.319c0-0.003,0-0.006,0.001-0.009
		c0.461-1.129,0.646-2.532,0.714-4.055c0.003-0.006,0.004-0.012,0.006-0.017c0.074-1.604,0.296-3.063,0.721-4.319
		c0-0.003,0-0.006,0-0.009c0.413-1.017,0.634-2.224,0.713-3.572c0.003-0.006,0.005-0.014,0.008-0.02
		c0.147-1.21,0.295-2.425,0.72-3.359c0-0.006,0.001-0.011,0.002-0.016c0.693-1.688,0.591-4.168,1.422-5.714
		c-0.075-0.326-0.141-0.659-0.228-0.972c0.001-0.007,0.003-0.013,0.004-0.02c0.089,0.313,0.153,0.653,0.229,0.979
		c0.006,0.021,0.012,0.04,0.017,0.063c0.001,0.007,0.003,0.015,0.005,0.021c0,0.001,0.001,0.002,0.001,0.003
		c1.08,4.716,0.934,10.58,1.188,16.217c0.308,6.741,0.719,13.846-1.438,18.96c-0.131,0.349,0.28,1.238-0.24,1.198
		c-0.664,6.346,2.042,9.318,3.12,13.921c0.188,0.054,0.279,0.2,0.24,0.479c1.187,1.213,1.604,3.195,2.64,4.561
		c0.058,0.352,0.101,0.699,0.134,1.049c0.013,0.106,0.018,0.217,0.024,0.323c0.019,0.239,0.037,0.479,0.048,0.72
		c0.005,0.12,0.006,0.24,0.009,0.36c0.006,0.229,0.013,0.456,0.01,0.685c0,0.111-0.004,0.226-0.007,0.339
		c-0.005,0.239-0.011,0.479-0.021,0.718c-0.004,0.092-0.01,0.183-0.017,0.274c-0.016,0.271-0.031,0.543-0.056,0.813
		c-0.004,0.05-0.008,0.102-0.013,0.148c-0.284,3.296-0.98,6.448-0.759,9.485c0.032,0.473,0.083,0.939,0.167,1.405
		c0.031,0.185,0.083,0.363,0.141,0.542c0.012,0.039,0.025,0.078,0.039,0.117c0.058,0.167,0.122,0.333,0.194,0.497
		c0.001,0.002,0.002,0.004,0.003,0.006s0.001,0.004,0.002,0.006c0.049,0.109,0.1,0.218,0.15,0.326
		c0.002,0.004,0.004,0.008,0.007,0.011c0.354,0.719,0.786,1.421,1.098,2.144c0.009,0.021,0.018,0.041,0.024,0.063
		c0.047,0.109,0.089,0.22,0.128,0.33c0.01,0.024,0.02,0.049,0.025,0.073c0.044,0.13,0.083,0.262,0.114,0.394
		c0,0.001,0,0.001,0,0.001c0.032,0.134,0.056,0.269,0.071,0.401c0.005,0.031,0.005,0.063,0.008,0.095
		c0.01,0.103,0.016,0.201,0.016,0.302c0,0.049-0.003,0.096-0.005,0.142c-0.005,0.09-0.013,0.181-0.025,0.271
		c-0.007,0.05-0.015,0.102-0.022,0.15c-0.02,0.095-0.043,0.189-0.07,0.286c-0.014,0.046-0.023,0.091-0.04,0.138
		c-0.049,0.143-0.104,0.284-0.178,0.43c0.271,0.445-0.171,1.783,0,2.881c-0.157,1.442,0.315,3.518-0.24,4.562c0,0.64,0,1.279,0,1.92
		c0.05,0.239,0.084,0.486,0.111,0.737c0.009,0.08,0.015,0.162,0.021,0.244c0.016,0.176,0.024,0.353,0.03,0.529
		c0.003,0.094,0.006,0.185,0.007,0.274c0.002,0.188-0.001,0.377-0.007,0.567c-0.002,0.074-0.002,0.15-0.005,0.229
		c-0.011,0.249-0.026,0.501-0.046,0.753c-0.012,0.146-0.024,0.294-0.037,0.44c-0.01,0.105-0.021,0.215-0.03,0.32
		c-0.095,0.969-0.216,1.935-0.284,2.854c-0.006,0.083-0.012,0.165-0.017,0.246c-0.045,0.7-0.053,1.373,0.017,1.989
		c0.022,0.198,0.054,0.394,0.092,0.578c0.002,0.003,0.002,0.005,0.002,0.006c0.039,0.188,0.088,0.37,0.146,0.547
		c0.552,1.616,2.495,1.564,3.12,2.88c22.005,0.556,45.035,0.084,67.437,0.239c-0.002,0.003-0.003,0.006-0.004,0.009
		c-22.401-0.156-45.433,0.315-67.438-0.24c-0.625-1.313-2.568-1.262-3.12-2.88c-0.062-0.175-0.107-0.357-0.147-0.546
		c0-0.001,0-0.003-0.001-0.005c-0.039-0.188-0.066-0.38-0.091-0.579c0,0.377,0,0.753,0,1.13c0,0.16,0,0.32,0,0.48
		c1.666,1.054,2.504,2.936,5.279,2.88c7.04,0,14.08,0,21.12,0c10.881,0,21.761,0,32.641,0c4,0,8,0,12,0
		c0.551-0.249-0.114-1.714,0.479-1.92c0-0.72,0-1.438,0-2.155c0.002-0.005,0.004-0.011,0.006-0.015
		c-0.252-0.248-0.493-1.704-0.004-1.917c0,0.002,0,0.005,0,0.007c0.54-0.179,0.092-1.347,0.238-1.92c0,0.001,0,0.001,0,0.002
		c0.08,0,0.16,0,0.24,0c0-1.2,0-2.4,0-3.603c0-1.92,0-3.84,0-5.76C480.883,711.041,480.805,711.041,480.727,711.041z
		 M209.053,284.963c-0.634-2.219,0.396-5.542,1.187-7.36c0.001,0,0.001,0.001,0.002,0.001
		C209.451,279.42,208.42,282.743,209.053,284.963z M394.081,692.32C394.081,692.321,394.081,692.321,394.081,692.32
		c-0.005,0.026-0.006,0.055-0.011,0.079C394.075,692.374,394.077,692.347,394.081,692.32z M473.761,663.281L473.761,663.281
		c-0.068-0.06-0.12-0.137-0.161-0.226C473.641,663.145,473.692,663.221,473.761,663.281z M474.721,596.319
		c-0.855,0.29-5.104-4.232-6.238-5.28c-6.101-5.629-12.911-12.406-19.2-16.8c-0.523-16.306,0-33.517,0-50.643
		c0-5.61-0.519-11.208,0.721-16.317c0.001,0,0.001,0,0.002,0c-1.237,5.109-0.721,10.706-0.721,16.317
		c0,17.125-0.523,34.337,0,50.642c6.289,4.394,13.102,11.171,19.2,16.8C469.619,592.085,473.862,596.603,474.721,596.319
		L474.721,596.319z M470.398,661.357L470.398,661.357c-0.479-0.429-0.188-1.628-0.207-2.515
		C470.212,659.729,469.921,660.928,470.398,661.357z M469.68,647.441L469.68,647.441c-0.151-0.808,0.309-2.225-0.237-2.639
		c0,0,0-0.001,0-0.002C469.989,645.215,469.529,646.632,469.68,647.441z M475.201,593.201c-0.669-0.108,0.188,1.308-0.48,1.2
		c0,0.079,0,0.158,0,0.237c0,0.32,0,0.643,0,0.96c0,0.08,0,0.16,0,0.24v0.002c-0.136-0.14-0.298,0.236-0.238,0.24
		c-0.119-0.009-0.241-0.038-0.364-0.078c-0.037-0.012-0.074-0.028-0.111-0.042c-0.091-0.036-0.184-0.078-0.276-0.129
		c-0.037-0.021-0.075-0.04-0.113-0.063c-0.108-0.063-0.219-0.137-0.329-0.218c-0.021-0.015-0.039-0.024-0.061-0.041
		c-0.28-0.213-0.569-0.476-0.863-0.771c-0.01-0.007-0.017-0.017-0.021-0.021c-1.125-1.132-2.324-2.733-3.472-3.88
		c-0.001-0.001-0.002-0.002-0.003-0.003c-0.004-0.004-0.009-0.01-0.014-0.015c-0.005-0.004-0.01-0.009-0.015-0.014
		c-0.047-0.047-0.094-0.096-0.142-0.142c-0.001-0.002-0.003-0.003-0.005-0.005c0.039,0.038,0.077,0.077,0.115,0.117
		c-0.198-0.185-0.393-0.371-0.595-0.554c-0.003-0.003-0.006-0.006-0.009-0.009c-0.141-0.116-0.277-0.219-0.414-0.313
		c-0.018-0.015-0.036-0.028-0.054-0.04c-0.152-0.104-0.306-0.191-0.452-0.262c-5.827-5.294-11.193-11.048-17.761-15.601
		c-0.471-16.472,0-33.425,0-50.399c0-5.604-0.369-11.269,0.72-16.079c1.529,0.64,4.052-0.168,5.28,0.238
		c0.007,4.214,0.018,8.423,0.029,12.629c0.013,3.317,0.027,6.631,0.046,9.94c0.006,0.764,0.009,1.527,0.014,2.291
		c0.021,3.763,0.051,7.52,0.087,11.27c0.002,0.266,0.005,0.527,0.007,0.791c0.038,3.913,0.083,7.817,0.138,11.709
		c-0.001,0.01-0.001,0.021-0.002,0.03c0,0.001,0,0.002,0,0.003c-0.177,3.241-0.367,6.688,0.14,8.974c0,0.003,0,0.003,0,0.003
		c0.072,0.329,0.155,0.646,0.262,0.924c0.83,2.191,4.436,4.837,6.721,6.96c4.798,4.457,9.353,8.782,12.96,14.16
		c-0.044,0.203,0.094,0.226,0.24,0.237C475.698,589.379,475.704,591.545,475.201,593.201z M475.921,549.763
		C475.921,549.764,475.921,549.764,475.921,549.763c-0.034,0.195-0.042,0.362-0.032,0.512
		C475.879,550.125,475.887,549.957,475.921,549.763z M475.44,534.881c0-0.001,0-0.001,0-0.002c0.209,0.362,0.272,0.869,0.279,1.433
		C475.713,535.749,475.648,535.242,475.44,534.881z M475.679,539.199c0,0.001,0,0.001,0,0.002c-0.02-0.171-0.028-0.352-0.032-0.539
		C475.65,538.849,475.659,539.029,475.679,539.199z M475.201,533.44c0,0.078,0,0.158,0,0.238c0.08,0,0.159,0,0.239,0
		c0,0.001,0,0.001,0,0.002c-0.08,0-0.159,0-0.239,0c0.157,1.603-0.317,3.837,0.239,5.04c0,0.16,0,0.32,0,0.48
		c0.001,0,0.001,0,0.002,0c0.157,3.12-0.32,6.879,0.238,9.6c0,0.08,0,0.16,0,0.24c0.034,0.926-0.188,2.106,0.479,2.4
		c0.001,0,0.001,0.001,0.001,0.002c0,0.078,0,0.157,0,0.237c0.001,0,0.001,0.001,0.002,0c-0.002,0.32-0.002,0.641-0.002,0.96
		c-1.665-1.056-3.394-2.049-4.32-3.84c-0.08,0-0.16,0-0.24,0c0,0.08,0,0.16,0,0.24c1.241,1.56,2.737,2.862,4.562,3.84
		c0,0,0,0-0.001,0c-1.823-0.978-3.319-2.28-4.563-3.84c-3.339-3.548-3.781-13.086-0.96-17.521c1.036-2.401,2.196-4.684,4.32-6
		c-6.815,3.306-8.648,18.912-2.546,25.202c-6.105-6.288-4.272-21.898,2.546-25.205c0,0.001,0,0.002,0,0.003c0,0.079,0,0.158,0,0.237
		c0.069,0.091,0.123,0.198,0.163,0.318c-0.04-0.118-0.093-0.225-0.161-0.314c0,1.12,0,2.24,0,3.36c0,0.08,0,0.16,0,0.24
		C475.117,530.645,474.646,532.556,475.201,533.44z M473.534,505.595c0.076-0.023,0.126-0.021,0.191-0.032
		c-0.067,0.013-0.122,0.012-0.202,0.036C473.527,505.598,473.53,505.597,473.534,505.595z M472.081,505.841L472.081,505.841
		c0.72-0.001,1.438-0.001,2.16-0.001c0.143,0.498-0.297,1.578,0.239,1.682c0,0.398,0,0.799,0,1.197
		c-0.399,1.686-0.399,5.039,0,6.722v0.001c0,0.158,0,0.318,0,0.479c0.078,0,0.158,0,0.238,0c0,0.001,0,0.002,0,0.003
		c0,0.562,0,1.12,0,1.681c0.516,0.966,0.155,2.803,0.221,4.217c-0.066-1.413,0.293-3.253-0.221-4.217
		c1.181,5.921-2.298,6.056-4.08,8.641c-0.296,0.229-0.572,0.491-0.844,0.767c-0.062,0.063-0.122,0.124-0.182,0.188
		c-0.264,0.282-0.517,0.58-0.75,0.899c-0.015,0.021-0.029,0.046-0.046,0.069c-0.229,0.314-0.44,0.648-0.645,0.997
		c-0.036,0.062-0.072,0.122-0.105,0.185c-0.212,0.38-0.412,0.771-0.597,1.181c-0.01,0.021-0.019,0.043-0.025,0.063
		c-0.185,0.413-0.354,0.842-0.506,1.283c-0.012,0.028-0.021,0.058-0.031,0.087c-0.16,0.464-0.308,0.942-0.436,1.434
		c-0.002,0.006-0.003,0.012-0.005,0.018c-0.18,0.687-0.314,1.398-0.435,2.122c1.146-5.937,5.382-9.829,8.926-13.372v0.001
		c-3.544,3.543-7.78,7.438-8.926,13.372c-0.062,0.308-0.104,0.626-0.146,0.945c-0.002,0.014-0.005,0.025-0.007,0.041
		c0,0.005-0.001,0.01-0.002,0.014c-0.012,0.091-0.03,0.177-0.04,0.268c-0.01,0.09-0.012,0.176-0.021,0.265
		c-0.042,0.411-0.075,0.817-0.099,1.221c-0.011,0.209-0.015,0.414-0.021,0.619c-0.011,0.346-0.019,0.688-0.013,1.026
		c0.003,0.086,0,0.173,0.003,0.259c0.004,0.138,0.019,0.271,0.024,0.409c0.008,0.221,0.012,0.441,0.025,0.66
		c0,0.002,0,0.004,0,0.005c0.021,0.355,0.049,0.709,0.083,1.063c0.001,0.005,0.001,0.009,0.001,0.014
		c0.035,0.352,0.077,0.696,0.126,1.041c0.001,0.007,0.001,0.015,0.003,0.021c0.048,0.339,0.104,0.676,0.165,1.009
		c0.003,0.012,0.004,0.023,0.007,0.037c0.061,0.32,0.128,0.643,0.201,0.958c0.006,0.021,0.01,0.046,0.015,0.065
		c0.072,0.307,0.151,0.604,0.234,0.9c0.009,0.032,0.017,0.066,0.024,0.099c0.08,0.279,0.168,0.556,0.261,0.824
		c0.017,0.048,0.028,0.097,0.046,0.146c0.088,0.255,0.184,0.504,0.279,0.751c0.023,0.059,0.045,0.117,0.067,0.177
		c0.096,0.232,0.198,0.461,0.303,0.687c0.03,0.065,0.061,0.134,0.089,0.197c0.103,0.21,0.209,0.411,0.317,0.612
		c0.04,0.073,0.077,0.15,0.119,0.226c0.104,0.188,0.218,0.364,0.33,0.542c0.05,0.08,0.097,0.164,0.146,0.242
		c0.11,0.166,0.229,0.322,0.347,0.479c0.06,0.081,0.114,0.168,0.178,0.245c0.116,0.148,0.24,0.286,0.362,0.429
		c0.066,0.078,0.134,0.162,0.201,0.235c0.125,0.136,0.259,0.256,0.389,0.381c0.075,0.07,0.146,0.148,0.223,0.219
		c0.139,0.123,0.285,0.232,0.43,0.347c0.075,0.06,0.146,0.124,0.225,0.179c0.225,0.163,0.456,0.313,0.693,0.45
		c1.396,0.734,2.399,1.864,4.016,2.377c0.019,0.009,0.035,0.021,0.054,0.029c0.08,0,0.16,0,0.24,0c0.169,0.114,0.3,0.267,0.408,0.44
		c0.012,0.018,0.018,0.041,0.028,0.06c0.05,0.177,0.066,0.383,0.069,0.606c0,0.014,0,0.024,0,0.037
		c0.001,0.113-0.001,0.229-0.005,0.351c-0.001,0.021-0.001,0.043-0.002,0.063c-0.004,0.118-0.009,0.237-0.013,0.354
		c0,1.76,0,3.52,0,5.279c0.075,0,0.153,0,0.23,0c0,0.001,0,0.003,0,0.004c-0.076,0-0.15,0-0.227,0
		c0.157,2.078-0.319,4.796,0.238,6.478c0,0.08,0,0.16,0,0.24c0.157,1.522-0.316,3.676,0.24,4.8c0,0.16,0,0.32,0,0.48
		c0,1.92,0,3.84,0,5.76c0.063-0.013,0.11-0.04,0.148-0.077c-0.04,0.042-0.092,0.071-0.159,0.084c-0.144,0.497,0.297,1.576-0.24,1.68
		c0,0.074,0,0.149,0,0.224c-2.352-1.879-3.843-4.622-5.989-6.71c-1.928-1.939-3.825-3.557-6-5.521
		c-0.128-0.115-0.278-0.246-0.441-0.386c-0.031-0.026-0.063-0.052-0.096-0.079c-0.162-0.139-0.339-0.285-0.526-0.441
		c-0.187-0.151-0.379-0.313-0.579-0.479c-0.031-0.026-0.063-0.051-0.093-0.077c-0.21-0.176-0.427-0.354-0.645-0.54
		c-0.013-0.012-0.023-0.021-0.038-0.032c0.034,0.033,0.067,0.064,0.104,0.1c1.467,1.397,2.966,2.815,4.441,4.252
		c0.014,0.011,0.023,0.021,0.035,0.033c0.669,0.648,1.333,1.303,1.988,1.96c0.075,0.075,0.149,0.151,0.226,0.229
		c0.53,0.535,1.055,1.072,1.566,1.611c0.175,0.184,0.347,0.367,0.519,0.552c0.436,0.462,0.856,0.926,1.272,1.394
		c0.166,0.186,0.334,0.369,0.497,0.558c0.555,0.635,1.097,1.271,1.609,1.912c0.541,0.671,1.262,1.865,2.149,1.676
		c0,0.003,0,0.005,0,0.007c-0.893,0.201-1.617-1.002-2.16-1.68c-0.513-0.643-1.054-1.277-1.607-1.912
		c-0.163-0.188-0.331-0.372-0.497-0.56c-0.416-0.466-0.839-0.93-1.272-1.392c-0.172-0.185-0.344-0.368-0.519-0.552
		c-0.517-0.539-1.036-1.076-1.566-1.611c-0.076-0.076-0.148-0.153-0.226-0.229c-0.655-0.656-1.319-1.313-1.988-1.961
		c-0.012-0.012-0.022-0.021-0.035-0.032c-1.479-1.438-2.977-2.854-4.441-4.252c-0.034-0.033-0.067-0.064-0.104-0.1
		c-0.002-0.002-0.004-0.003-0.006-0.005c-0.898-0.857-1.776-1.709-2.635-2.553c-0.313-0.311-0.637-0.619-0.941-0.926
		c-0.235-19.364-0.521-38.683-0.479-58.32c0.003,0,0.005-0.001,0.008-0.001c-0.041,19.64,0.243,38.955,0.479,58.319
		c0.308,0.307,0.628,0.615,0.941,0.926c-0.164-0.202-0.306-0.398-0.421-0.586c-0.013-0.021-0.022-0.038-0.033-0.059
		c-0.106-0.184-0.197-0.358-0.247-0.521c-0.589-1.942,0.085-5.401,0-7.44c-0.608-14.579-0.483-35.493-0.24-50.397
		c1.559-0.526,3.882-0.284,5.521-0.725c0.397,0,0.8,0,1.199,0c0.08,0,0.158,0,0.238,0c0-0.077,0-0.157,0-0.235
		c0.001,0,0.001,0,0.002,0c0,0.078,0,0.156,0,0.233c0.729-0.148,2.067,0.31,2.4-0.237c0.201,0.043,0.226-0.092,0.239-0.234
		C469.492,506.473,470.334,505.598,472.081,505.841z M465.835,506.321c0.043-0.068,0.103-0.121,0.175-0.161
		c-0.07,0.04-0.127,0.093-0.169,0.161C465.839,506.321,465.837,506.321,465.835,506.321z M463.679,440.324
		c0.001,0.001,0.001,0.001,0.002,0.001c0,0.478,0,0.958,0,1.436c-0.93,0.267-1.727,0.661-2.389,1.194
		c-0.002,0-0.003,0.001-0.005,0.002c0.663-0.534,1.463-0.932,2.392-1.196C463.679,441.283,463.679,440.803,463.679,440.324z
		 M410.16,406.48c-0.014-0.896,0.242-1.52,0.479-2.16c0.154-0.887,0.246-1.834,0.722-2.4c-0.01-1.048,0.209-1.872,0.479-2.64
		c-0.184-1.305,0.38-1.86,0.24-3.12c0-0.24,0-0.48,0-0.72c0.153-0.982,0.859-1.154,1.728-1.015
		c-0.867-0.138-1.572,0.034-1.726,1.015c0.423-0.217,0.396-0.883,0.96-0.96c1.252,0.424,2.568,0.788,4.074,0.959
		c0.002,0,0.003,0.001,0.004,0.001c2.234,0.246,4.171,0.789,6.239,1.2c0.484,0.026,0.953,0.068,1.396,0.136
		c-0.443-0.067-0.911-0.109-1.395-0.135c0.936,0.427,2.147,0.571,3.358,0.722c-0.192-0.102-0.403-0.184-0.621-0.26
		c0.217,0.076,0.428,0.158,0.619,0.259c1.633,0.048,3.025,0.335,4.32,0.72c0.002,0,0.004,0,0.006,0
		c0.871,0.485,2.128,0.583,3.11,0.957c0.001,0.001,0.003,0.001,0.004,0.002c0.079,0,0.159,0,0.239,0
		c0.001,0,0.003,0.001,0.004,0.002c0,0.079,0,0.159,0,0.238c0.003,0.316,0.421,0.219,0.48,0.479c0.33-0.011,0.526,0.113,0.72,0.24
		c1,0.121,1.4,0.84,2.4,0.96c0.128-0.004,0.224,0.024,0.318,0.053c0.183,0.101,0.369,0.194,0.551,0.294
		c0.004,0.002,0.009,0.005,0.013,0.008c7.445,5.104,14.081,11.015,19.038,18.604c-0.588-0.979-1.207-1.933-1.854-2.854
		c-0.327-0.468-0.684-0.904-1.022-1.355c-0.333-0.441-0.652-0.895-0.999-1.322c-0.479-0.59-0.985-1.15-1.487-1.718
		c-0.23-0.262-0.45-0.538-0.688-0.795c-0.598-0.646-1.223-1.266-1.854-1.882c-0.158-0.157-0.311-0.323-0.473-0.479
		c-0.708-0.676-1.438-1.328-2.183-1.97c-0.096-0.081-0.185-0.168-0.277-0.249c-0.806-0.687-1.633-1.352-2.477-2.001
		c-0.037-0.028-0.071-0.059-0.108-0.087c-0.877-0.672-1.773-1.324-2.688-1.959c-0.007-0.004-0.013-0.009-0.019-0.013
		c-0.913-0.636-1.845-1.251-2.791-1.85c0.012,0.007,0.024,0.015,0.036,0.022c0.933,0.592,1.855,1.201,2.755,1.827
		c0.006,0.004,0.012,0.009,0.021,0.013c0.911,0.635,1.81,1.287,2.687,1.959c0.037,0.028,0.071,0.059,0.108,0.087
		c0.844,0.649,1.671,1.314,2.477,2.001c0.095,0.081,0.184,0.167,0.277,0.249c0.743,0.644,1.475,1.293,2.183,1.97
		c0.162,0.155,0.313,0.322,0.473,0.479c0.631,0.616,1.256,1.235,1.854,1.882c0.238,0.257,0.458,0.533,0.688,0.795
		c0.504,0.566,1.011,1.127,1.487,1.718c0.347,0.429,0.666,0.884,0.999,1.322c0.341,0.451,0.695,0.89,1.022,1.355
		c0.646,0.922,1.266,1.873,1.854,2.854v0.001c0,0.08,0,0.159,0,0.238c0.002,0.002,0.004,0.005,0.006,0.007
		c1.638,2.6,2.896,5.578,3.595,9.114c0-0.315,0-0.635,0-0.949c0,0.001,0.001,0.001,0.001,0.002c0,0.314,0,0.632,0,0.947
		c0.329,0.31,0.235,1.043,0.238,1.681c0.079,1.358,0.304,2.573,0.72,3.594c0,0.002,0,0.004,0,0.006c0,0.08,0,0.16,0,0.24
		c0.001,0,0.001,0,0.002,0c0.055,0.983,0.116,1.962,0.479,2.64c0,0.079,0,0.158,0,0.238c0.665-0.106-0.178,1.295,0.473,1.201
		c0,0.001,0,0.001,0,0.002c-0.652,0.094,0.191-1.308-0.473-1.199c-0.077,1.52,0.616,2.266,0.479,3.841
		c-4.833,2.128-9.121,4.8-13.922,6.961c-0.007,0.231-0.294,0.187-0.479,0.238c0,0.08,0,0.159,0,0.238
		c-4.024,2.119-8.478,3.811-13.163,5.268c-0.015,0.004-0.029,0.008-0.044,0.012c-0.08,0-0.16,0-0.24,0
		c-0.001,0.001-0.002,0.001-0.003,0.002c-0.88,0.151-1.822,0.247-2.384,0.717c-0.004,0.001-0.007,0.001-0.011,0.002
		c-0.08,0-0.16,0-0.24,0c-0.004,0.001-0.008,0.002-0.011,0.003c-2.999,0.512-5.692,1.327-8.375,2.154
		c-0.005,0.001-0.009,0.002-0.014,0.003c-0.079,0-0.159,0-0.239,0c-0.001,0-0.002,0.001-0.003,0.001
		c-1.527,0.228-3.114,0.397-4.31,0.958c-0.003,0-0.005,0.001-0.007,0.001c-0.08,0-0.16,0-0.24,0
		c-0.001,0.001-0.002,0.001-0.003,0.001c-0.939,0.017-1.798,0.116-2.394,0.479c-0.001,0-0.001,0-0.002,0
		c-0.283,0.275-0.85,0.271-1.439,0.239c-0.002,0.001-0.004,0.001-0.006,0.002c-1.759,0.155-3.362,0.467-4.786,0.957
		c-0.002,0-0.005,0.001-0.008,0.001c-0.769,0.271-1.593,0.488-2.641,0.479c-0.001,0.001-0.002,0.001-0.003,0.001
		c-0.94,0.019-1.8,0.117-2.392,0.478c-0.002,0-0.003,0-0.005,0c-0.396,0.402-1.409,0.191-2.16,0.24
		c-0.001,0.002-0.003,0.003-0.005,0.004c-2.647,0.809-2.218-1.458-2.154-3.361c-0.553,0.726-0.085,2.473-0.238,3.598h-0.001
		c0.153-1.125-0.313-2.874,0.238-3.603c0-0.08,0-0.16,0-0.238c0.151-0.81-0.31-2.229,0.24-2.642c0-0.08,0-0.16,0-0.24
		c0.152-0.887-0.312-2.391,0.239-2.88c0-0.08,0-0.16,0-0.238c0.158-1.764-0.316-4.157,0.24-5.521c0-0.88,0-1.76,0-2.64
		c0.151-0.89-0.313-2.394,0.238-2.881c0.113-1.326,0.146-2.735,0.479-3.842c0.017-0.941,0.116-1.804,0.479-2.397
		c-0.029-0.592-0.034-1.157,0.24-1.44c0.24-1.76,0.637-3.361,1.2-4.8c0.036-1.724,0.549-2.971,0.96-4.32
		c0.148-1.211,0.295-2.425,0.72-3.358c0-0.079,0-0.159,0-0.239c0.072-1.048,0.249-1.991,0.722-2.641c0-0.08,0-0.159,0-0.239
		c0.173-0.947,0.298-1.942,0.72-2.642c0.063-1.695,0.577-2.942,0.96-4.319C409.455,408.814,409.662,407.501,410.16,406.48z
		 M459.174,446.26c-0.58,0.3-1.103,0.577-1.495,0.779c-0.007,0.004-0.014,0.007-0.021,0.011c0.01-0.005,0.018-0.01,0.027-0.015
		C458.076,446.834,458.596,446.558,459.174,446.26z M454.64,446.159c-0.986,0.481-1.918,1.016-2.862,1.536
		C452.722,447.174,453.654,446.641,454.64,446.159z M374.879,379.839c-0.001,0-0.002,0-0.003-0.001
		c-0.823-0.612-1.78-1.09-2.87-1.435c-0.001,0-0.002-0.001-0.002-0.001C373.096,378.748,374.055,379.226,374.879,379.839z
		 M367.2,376.96c-0.432,0.031-0.506-0.292-0.958-0.24C366.695,376.666,366.77,376.989,367.2,376.96z M365.765,392.082
		L365.765,392.082c0.192,0.062,0.443,0.133,0.711,0.186c0.028,0.006,0.059,0.013,0.088,0.018c0.038,0.007,0.079,0.011,0.12,0.016
		c0.063,0.009,0.121,0.019,0.184,0.024c0.03,0.003,0.059,0.001,0.088,0.003c0.375,0.022,0.735-0.03,0.968-0.249
		c0.2-0.518,0.618-0.819,0.479-1.674v0c0.139,0.857-0.278,1.157-0.479,1.675c-0.23,0.219-0.593,0.271-0.968,0.249
		c-0.029-0.001-0.059,0-0.088-0.003c-0.063-0.005-0.121-0.016-0.184-0.024c-0.041-0.005-0.082-0.009-0.122-0.016
		c-0.027-0.005-0.058-0.012-0.086-0.018c-0.269-0.053-0.521-0.125-0.713-0.187C365.764,392.082,365.764,392.082,365.765,392.082z
		 M365.761,392.082c-0.063,0.096-0.126,0.194-0.172,0.309C365.634,392.276,365.696,392.178,365.761,392.082L365.761,392.082z
		 M345.073,311.868c0.753-1.653,1.483-3.336,2.359-4.96C346.557,308.533,345.826,310.216,345.073,311.868z M349.433,303.772
		c-0.33,0.43-0.636,0.871-0.926,1.318C348.798,304.643,349.103,304.201,349.433,303.772L349.433,303.772z M352.553,295.615
		c-0.484,0.702-0.662,1.715-0.949,2.618c0,0-0.001,0.001-0.001,0.001C351.891,297.332,352.067,296.316,352.553,295.615
		L352.553,295.615z M354.227,296.974c0.822-2.998,1.513-6.128,2.174-9.289h0.001C355.74,290.845,355.049,293.976,354.227,296.974z
		 M354.963,274.708c-0.002,0.002-0.003,0.004-0.004,0.007c-0.038-0.998-0.08-1.999,0.481-2.395c0.001,0,0.001,0,0.002,0
		C354.881,272.715,354.921,273.711,354.963,274.708z M359.513,285.046c-0.81,0.309-3.729,0.94-3.12-0.24c0-0.08,0-0.16,0-0.24
		c0.151-0.808-0.31-2.229,0.239-2.64c0-0.16,0-0.32,0-0.48c0.157-1.603-0.314-3.837,0.24-5.04c0-0.16,0-0.32,0-0.48
		c-0.028-1.172,0.129-2.529-0.24-3.36c-0.477-0.159-0.786-0.151-0.992-0.04c0.206-0.117,0.519-0.128,1.003,0.034
		c0.369,0.831,0.213,2.188,0.24,3.36c-0.001,0-0.001,0-0.002,0c0,0.16,0,0.32,0,0.48c-0.557,1.203-0.083,3.438-0.24,5.04
		c0,0.16,0,0.32,0,0.48c-0.15,0.808,0.312,2.229-0.238,2.64c-0.002,0.08-0.002,0.16-0.002,0.24c-0.607,1.181,2.312,0.549,3.118,0.24
		c1.268-0.484,3.405-2.014,5.268-3.646C362.923,283.029,360.781,284.561,359.513,285.046z M370.071,270.658
		c-0.435,0.742-0.729,1.626-0.905,2.626C369.343,272.283,369.637,271.398,370.071,270.658
		C370.071,270.657,370.071,270.657,370.071,270.658z M372.976,263.642c-0.001,0.001-0.001,0.002-0.002,0.003
		c0.516-1.613,1.298-2.958,2.124-4.259C374.273,260.688,373.49,262.031,372.976,263.642z M380.16,248.56
		c0.134-0.069,0.2-0.206,0.237-0.373C380.361,248.354,380.295,248.492,380.16,248.56c0,0.321,0,0.641,0,0.961
		c0.594,4.915-0.928,7.714-2.563,10.399c1.635-2.684,3.153-5.483,2.562-10.396c0-0.32,0-0.64,0-0.96
		c-0.022-0.703,0.102-1.261,0.319-1.724C380.261,247.303,380.138,247.859,380.16,248.56z M379.85,225.673
		c-0.348-0.396-0.632-0.854-0.913-1.315C379.219,224.819,379.502,225.277,379.85,225.673z M375.599,218.8
		c0.001,0,0.001,0.001,0.003,0.001c0.2,0.278,0.315,0.644,0.239,1.199c-0.24,0-0.48,0-0.721,0c-0.002,0.317-0.42,0.22-0.479,0.48
		c0.563-0.079,0.991-0.289,1.439-0.48c-0.448,0.192-0.879,0.401-1.439,0.48c-0.08,0-0.158,0-0.238,0
		c-0.279,0.387-0.639,0.575-1.01,0.612c0.371-0.038,0.729-0.226,1.008-0.612c-1.119,0.662-1.693,0.333-2.638-0.237
		c-0.001-0.001-0.001-0.001-0.002-0.002c-1.295-0.305-2.65-0.548-3.84-0.96c-0.001,0-0.001,0-0.001,0
		c-0.983-0.616-2.292-0.907-2.879-1.919c0.72,0,1.439,0,2.159,0c1.049-0.008,1.872,0.208,2.641,0.48
		c1.21,0.149,2.425,0.295,3.359,0.72c0.825-0.105,1.346,0.095,1.92,0.24C375.28,218.8,375.44,218.8,375.599,218.8z M375.119,177.999
		c-4.525-6.354-7.586-14.176-14-18.642C367.535,163.822,370.594,171.644,375.119,177.999L375.119,177.999z M286.801,103.839
		c9.102-0.891,22.14-2.654,32.161-1.2c5.064,0.735,9.258,3.279,13.439,4.08c-0.001,0-0.003,0-0.004,0.001
		c-4.183-0.802-8.373-3.345-13.437-4.08C308.94,101.186,295.9,102.949,286.801,103.839c-2.435,2.179-5.513,5.65-5.355,10.131
		C281.287,109.488,284.365,106.017,286.801,103.839z M335.999,109.359c0.044-0.021,0.076-0.043,0.099-0.067
		C336.075,109.316,336.042,109.339,335.999,109.359c-5.595-0.563-10.133-0.053-13.2,2.641c0,0,0.001,0,0.001-0.001
		C325.867,109.305,330.405,108.795,335.999,109.359z M282.599,117.827c0.049,0.349,0.025,0.721-0.118,1.133
		c-2.388-0.911-3.152-3.146-3.052-5.629c-0.1,2.483,0.665,4.718,3.052,5.628C282.624,118.547,282.647,118.176,282.599,117.827z
		 M208.8,204.64c-0.432-9.512-0.364-20.773,0.48-30.24c0.613-6.871,1.051-14.555,4.08-19.44c3.309-5.337,7.321-8.98,12.24-12.24
		c4.751-9.594,10.899-17.71,18.96-24.24c4.02-3.256,7.988-6.32,13.44-8.16c0.658-0.222,1.344-0.431,2.049-0.635
		c0.31-0.09,0.634-0.177,0.951-0.265c0.421-0.117,0.838-0.235,1.269-0.352c0.006-0.002,0.014-0.003,0.02-0.005
		c0.001,0,0.002,0,0.003-0.001c1.291-0.348,2.614-0.702,3.934-1.093c0.059-0.018,0.117-0.032,0.175-0.05
		c4.901-1.466,9.643-2.795,16.08-3.12c0,0,0,0.001,0,0.001c-6.437,0.325-11.179,1.654-16.08,3.12
		c-0.058,0.018-0.116,0.032-0.174,0.05c2.49-0.621,5.051-1.139,7.646-1.575c0.089-0.015,0.178-0.031,0.268-0.045
		c1.224-0.202,2.453-0.388,3.685-0.556c0.167-0.023,0.333-0.044,0.499-0.066c1.226-0.163,2.452-0.314,3.676-0.448
		c-2.629,2.872-5.238,12.954,0.96,13.92c0.32-1.78-1.004-2.827-1.2-4.56c-0.53-4.683,2.67-8.359,5.28-10.56
		c14.049-1.845,33.063-2.989,43.68,2.64c-5.685,0.661-12.026,3.138-11.278,8.64c2.159-0.219,2.668-2.361,4.318-3.6
		c3.087-2.313,7.444-2.94,12.479-1.92c0.611-0.306-0.225-1.175,0.238-1.2c11.879,4.043,21.711,10.13,27.36,20.4
		c1.039,0.401,2.069,0.811,2.16,2.16c0.448,0.511,0.392,1.528,0.96,1.92c-0.247-1.273-0.899-2.141-1.2-3.36
		c-4.185-3.042-6.916-9.248-10.8-12.24c-5.705-4.395-12.027-6.461-19.681-9.6c-0.043,0.021-0.075,0.043-0.099,0.067
		c0.023-0.024,0.056-0.047,0.1-0.068c7.651,3.139,13.976,5.205,19.681,9.6c3.884,2.992,6.615,9.198,10.8,12.24
		c0.301,1.219,0.953,2.086,1.2,3.36c-0.103,0.501,0.164,0.635,0.239,0.96c0,0,0.001,0.001,0.001,0.002
		c-0.06,1.018,0.628,1.292,0.479,2.399c0.52,0.203,0.205,1.235,0.721,1.439c0.827,3.572,1.849,6.952,2.158,11.04
		c0.001,0.003,0.002,0.006,0.003,0.009c0,0.397,0,0.794,0,1.192c0.426,0.613,0.182,1.896,0.719,2.396c0,0.001,0,0.002,0,0.003
		c0,0.08,0,0.16,0,0.24c0,0,0.001,0.001,0.001,0.002c0,0.4,0,0.8,0,1.199c0.668-0.108-0.188,1.306,0.478,1.2
		c0.387,1.293,0.672,2.687,0.721,4.319c0.001,0.001,0.001,0.003,0.002,0.004c-0.187,0.984,0.368,1.229,0.24,2.157
		c0,0.08,0,0.16,0,0.24c0.668-0.108-0.187,1.307,0.479,1.2c0.313,1.365,0.688,2.668,0.721,4.319c0.512,1.648,0.126,4.194,0.72,5.76
		c0.549,0.333,0.092,1.67,0.24,2.4c0,0,0.001,0.001,0.001,0.001c-0.144,0.497,0.296,1.577-0.24,1.68
		c-4.719-7.522-8.123-16.357-16.8-19.92c5.897,6.819,11.472,13.963,15.837,22.314c0,0.001,0.001,0.003,0.002,0.005
		c0.14,0.502,0.597,0.685,0.479,1.44c0,0,0.001,0.001,0.001,0.002c0.037,0.762,0.545,1.055,0.48,1.919c0,0.08,0,0.16,0,0.24
		c0.557,0.963,0.084,2.952,0.24,4.315c-0.001,0.001-0.001,0.002-0.001,0.003c0,0.08,0,0.16,0,0.24
		c-0.001,0.004-0.003,0.007-0.004,0.012c-0.31,1.104-1.753-1.48-2.396-2.411c-0.209-0.303-0.421-0.605-0.632-0.907
		c-0.142-0.202-0.281-0.402-0.426-0.603c-0.066-0.097-0.137-0.194-0.205-0.291c-0.167-0.233-0.336-0.466-0.505-0.697
		c-0.046-0.063-0.092-0.127-0.138-0.19c-0.181-0.245-0.361-0.486-0.543-0.728c-0.036-0.048-0.072-0.097-0.109-0.146
		c-0.188-0.247-0.378-0.492-0.567-0.734c-0.031-0.041-0.063-0.082-0.097-0.122c-0.193-0.246-0.393-0.488-0.591-0.728
		c-0.029-0.036-0.059-0.071-0.088-0.106c-0.202-0.243-0.406-0.481-0.612-0.716c-0.025-0.031-0.054-0.062-0.081-0.093
		c-0.21-0.237-0.422-0.47-0.637-0.699c-0.024-0.026-0.049-0.052-0.072-0.078c-0.219-0.231-0.438-0.458-0.664-0.679
		c-0.021-0.021-0.043-0.043-0.063-0.064c-0.228-0.222-0.455-0.438-0.688-0.649c-0.021-0.02-0.043-0.039-0.063-0.058
		c-0.234-0.212-0.472-0.417-0.713-0.616c-0.021-0.016-0.038-0.031-0.059-0.047c-0.244-0.2-0.489-0.394-0.743-0.58
		c-0.017-0.013-0.031-0.025-0.051-0.038c-0.255-0.188-0.513-0.368-0.773-0.54c-0.016-0.009-0.027-0.018-0.041-0.026
		c-0.269-0.173-0.537-0.339-0.813-0.496c-0.01-0.005-0.019-0.01-0.027-0.016c-0.279-0.159-0.563-0.308-0.854-0.448
		c-0.004-0.002-0.008-0.003-0.011-0.005c-0.294-0.142-0.595-0.273-0.898-0.394c4.244,2.25,7.646,6.032,10.548,10.017
		c0.415,0.569,0.82,1.142,1.219,1.715c0.621,0.899,1.518,2.866,2.074,2.676c-0.557,0.192-1.455-1.776-2.075-2.675
		c-0.396-0.573-0.804-1.146-1.219-1.715c-2.902-3.985-6.304-7.766-10.548-10.017h-0.001c-0.16-0.085-0.312-0.188-0.475-0.268
		c0.026,0.041,0.058,0.081,0.086,0.122c1.608,2.353,3.453,4.473,5.285,6.604c0.39,0.454,0.776,0.908,1.164,1.365
		c0.105,0.127,0.215,0.255,0.319,0.383c0.522,0.624,1.039,1.252,1.543,1.896c0.025,0.033,0.053,0.067,0.076,0.101
		c0.522,0.67,1.032,1.354,1.521,2.059c0.013,0.018,0.022,0.038,0.037,0.056c0.494,0.72,0.972,1.461,1.417,2.231
		c0.005,0.008,0.01,0.017,0.014,0.025c0.451,0.781,0.873,1.591,1.258,2.439h-0.001c-0.385-0.848-0.807-1.658-1.258-2.439
		c-0.004-0.008-0.009-0.017-0.016-0.025c-0.443-0.771-0.921-1.511-1.416-2.231c-0.014-0.018-0.023-0.038-0.036-0.056
		c-0.487-0.705-0.997-1.388-1.521-2.059c-0.023-0.033-0.051-0.067-0.076-0.101c-0.504-0.643-1.021-1.272-1.543-1.896
		c-0.104-0.128-0.214-0.255-0.319-0.383c-0.388-0.457-0.774-0.911-1.164-1.365c-1.832-2.131-3.677-4.252-5.285-6.604
		c-0.107-0.038-0.213-0.084-0.323-0.121c3.824,5.855,9.938,10.703,12.479,17.04c-0.581-0.299-1.165-0.593-1.92-0.719
		c-0.001,0-0.001,0-0.001,0c-0.879,0.08-1.333-0.266-1.92-0.48c-0.429-0.058-0.842-0.133-1.251-0.21
		c0.409,0.078,0.822,0.152,1.252,0.21c0.172-0.651-1.132,0.172-0.96-0.48c-0.824-0.375-1.837-0.563-3.12-0.48
		c-1.078,0.1-2.646-0.289-3.092,0.447c0,0-0.001,0-0.001,0.001c0.009,0.009,0.019,0.018,0.025,0.027c0.001,0,0.001,0,0.001,0
		c0.564-0.101,0.481,0.445,0.892,0.502c0.009,0.001,0.016,0.005,0.022,0.006c0.063,0.005,0.141-0.001,0.23-0.023
		c-0.021-0.378-0.61-0.188-0.479-0.72c0.327-0.027,0.641-0.041,0.943-0.043c-0.302,0.002-0.615,0.016-0.942,0.043
		c-0.132,0.532,0.459,0.342,0.479,0.72c0.08,0,0.16,0,0.24,0c0.001,0,0.002,0,0.003,0.001c0.662,0.536,1.463,0.932,2.395,1.198
		c0.001,0,0.002,0,0.002,0.001c0.08,0,0.16,0,0.238,0c0.563,0.079,0.991,0.289,1.44,0.48c0.001,0,0.002,0,0.003,0.001
		c0.037,0.442,0.813,0.146,0.96,0.479c2.599,0.592,4.898,1.484,6.467,3.108c0.003,0.004,0.007,0.007,0.01,0.012
		c0,0.16,0,0.32,0,0.48c0,0.001,0,0.003,0,0.004c-0.542,0.336-0.09,1.665-0.237,2.393c-0.001,0.001-0.002,0.002-0.003,0.003
		c0,0.4,0,0.8,0,1.2c-0.079,0-0.157,0-0.236,0c0,0.16,0,0.32,0,0.48c0,0.719,0,1.438,0,2.157c-0.001,0.001-0.002,0.002-0.003,0.003
		c0,0.16,0,0.32,0,0.48c0,0.001,0,0.002,0,0.003c-0.541,0.337-0.089,1.665-0.237,2.393c-0.001,0.001-0.002,0.002-0.003,0.003
		c0,0.32,0,0.64,0,0.96c-0.08,0-0.159,0-0.237,0c0,0.08,0,0.16,0,0.24c0,0.719,0,1.439,0,2.159l-0.001,0.001
		c-0.101,1.472,0.242,4.494-1.439,3.6c-0.956-0.118-1.816-0.332-2.609-0.612c0.793,0.28,1.654,0.494,2.61,0.612
		c0-0.08,0-0.16,0-0.24c-1.736,0.056-2.701-0.659-3.841-1.2c-3.723-1.798-6.227-4.815-9.602-6.96c1.321,5.24,5.229,7.89,9.838,9.838
		c0.001,0,0.002,0.001,0.003,0.001c0.078,0,0.158,0,0.238,0c0,0,0.001,0,0.001,0c0.006,0.316,0.424,0.22,0.481,0.48
		c0.557-0.077,0.922,0.039,1.2,0.24c0.479,0,0.959,0,1.438,0h0.001c0.08,0,0.159,0,0.239,0c0.001,0,0.002,0,0.003,0
		c0.242,0.675,1.019-0.066,1.439,0c0.224,0.657,0.734,1.024,0.96,1.68c0,0.08,0,0.16,0,0.24c0.597,0.287,0.693,1.302-0.479,0.96
		c0.301,0.097,0.527,0.131,0.692,0.118c-0.166,0.015-0.394-0.02-0.697-0.118c-1.456-0.064-2.657-0.383-3.841-0.72
		c-0.911-0.048-1.707-0.212-2.399-0.479c-2-0.449-3.788,0.249-5.521-0.24c-0.505-0.212-0.709-0.727-1.434-0.719
		c-0.001-0.001-0.001-0.001-0.001-0.002c0.73-0.011,0.935,0.507,1.439,0.72c-0.127-0.238-0.294-0.436-0.497-0.597
		c-0.246-0.079-0.472-0.179-0.666-0.312c-0.157-0.033-0.323-0.058-0.517-0.054c-0.141,0.779,0.161,1.117,0.238,1.68
		c0,0.08,0,0.16,0,0.24c1.722,2.283,3.611,4.393,6.481,5.522c0.08,0,0.16,0,0.24,0c0.235,0,0.474,0,0.712,0
		c0.001,0,0.002,0,0.003,0.001c1.049-0.008,1.872,0.208,2.641,0.48c0.001,0.001,0.002,0.002,0.003,0.004
		c-0.098,0.182-0.223,0.368-0.359,0.555c-0.026,0.038-0.055,0.076-0.081,0.114c-0.14,0.179-0.289,0.36-0.454,0.542
		c-0.042,0.047-0.087,0.095-0.133,0.142c-0.148,0.16-0.307,0.32-0.473,0.481c-0.061,0.059-0.122,0.118-0.184,0.177
		c-0.152,0.144-0.311,0.287-0.471,0.431c-0.091,0.081-0.182,0.162-0.272,0.243c-0.153,0.133-0.308,0.266-0.464,0.397
		c-0.008,0.006-0.017,0.013-0.022,0.019h-0.001c-0.078,0.109-0.153,0.216-0.23,0.32c-0.019,0.024-0.036,0.047-0.055,0.071
		c-0.005,0.004-0.01,0.008-0.015,0.012c-0.459,0.388-0.872,0.774-1.178,1.146c-0.001,0.001-0.001,0.002-0.002,0.002
		c-0.017,0.008-0.031,0.018-0.048,0.025c-0.029,0.015-0.061,0.032-0.091,0.043c-0.088,0.035-0.177,0.062-0.268,0.074
		c-0.029,0.004-0.082-0.011-0.146-0.033c-0.111,0.091-0.229,0.185-0.336,0.273c0.01-0.107-0.003-0.194-0.018-0.278
		c-0.017-0.074-0.043-0.139-0.077-0.196h-0.001c-0.125-0.066-0.261-0.142-0.409-0.228c-0.003-0.002-0.006-0.003-0.009-0.005
		c-0.064-0.007-0.127-0.02-0.207-0.013c0,0.08,0,0.16,0,0.24c0.568,0.075,0.451,0.831,1.199,0.721c0,0,0,0,0,0.001
		c-0.754,0.114-0.635-0.646-1.2-0.72c0-0.08,0-0.16,0-0.24c-0.08,0-0.16,0-0.24,0c-2.556-0.803-3.49-3.225-5.756-4.318
		c0-0.001-0.001-0.002-0.001-0.003c2.269,1.093,3.202,3.517,5.76,4.32c-2.505-1.015-3.232-3.806-6-4.56
		c0.159,3.134,2.561,6.071,5.762,6.96c-0.965,1.196-1.621,2.699-2.88,3.6c0.168-1.208-0.498-1.582-1.682-1.44
		c-1.285,0.635-0.482,3.356-1.681,4.08c-1.114-9.894-6.547-17.752-3.84-29.28c-3.569-5.39-8.557-10.645-10.562-16.8
		c3.588,3.692,8.146,6.414,13.682,8.16c0.079,0,0.159,0,0.238,0c1.185,0.016,1.694,0.702,2.876,0.72c0.001,0,0.002,0,0.003,0.001
		c0.08,0,0.16,0,0.24,0c0.003,0,0.005,0.001,0.008,0.001c0.862,0.576,2.097,0.781,3.599,0.718c0.102-0.821-0.721-0.721-0.96-1.2
		c-0.755,0.115-0.938-0.342-1.439-0.48c-0.893-0.148-1.509-0.572-2.398-0.72c1.227,1.01,3.548,0.929,4.556,2.16
		c-0.002,0-0.004,0-0.006,0c-1.008-1.231-3.328-1.15-4.561-2.16c-2.566-0.552-4.621-1.619-6.72-2.64
		c-3.989-2.454-10.353-6.389-12.479-11.52c-1.392-3.354-1.922-8.088-3.119-12c-1.269-4.134-2.587-8.178-2.881-12
		c-0.03-0.405-0.03-0.776-0.02-1.134c0.003-0.094,0.007-0.187,0.013-0.277c0.022-0.33,0.061-0.646,0.117-0.936
		c0.017-0.072,0.037-0.135,0.054-0.205c0.053-0.213,0.112-0.414,0.184-0.606c0.039-0.109,0.08-0.217,0.125-0.319
		c0.073-0.166,0.157-0.321,0.244-0.471c0.092-0.157,0.189-0.304,0.298-0.444c0.059-0.078,0.117-0.154,0.181-0.227
		c0.098-0.113,0.195-0.225,0.302-0.328c0.146-0.141,0.297-0.277,0.457-0.4c0,0,0,0,0.001,0c0,0,0,0,0-0.001
		c0.283-0.214,0.593-0.402,0.918-0.571c0.001,0,0.002,0,0.002-0.001c-0.327,0.169-0.633,0.356-0.915,0.57
		c0.771-0.194,1.559-0.537,2.38-0.875c0.289-0.119,0.581-0.237,0.88-0.347c0.044-0.016,0.089-0.03,0.133-0.045
		c0.368-0.131,0.744-0.252,1.134-0.344c0.229-0.054,0.463-0.092,0.702-0.125c0.006,0,0.013-0.001,0.021-0.002
		c1.552-0.204,3.291-0.077,4.309-1.068c0.001,0,0.003,0,0.004-0.001c0,0-0.001,0-0.001-0.001c0-0.001,0.001-0.001,0.002-0.002
		c-0.001,0-0.003,0-0.004,0.001c-0.14-0.15-0.319-0.261-0.529-0.344c-0.009-0.003-0.018-0.005-0.024-0.008
		c-0.1-0.037-0.201-0.07-0.313-0.095c-0.031-0.007-0.067-0.011-0.1-0.017c-0.09-0.018-0.182-0.036-0.275-0.047
		c-0.026-0.003-0.055-0.003-0.082-0.006c-0.104-0.011-0.212-0.021-0.323-0.025c-0.041-0.002-0.085-0.001-0.126-0.002
		c-1.518-0.037-3.433,0.394-4.224,0.067c0.002-0.001,0.004-0.003,0.006-0.004c0.792,0.325,2.705-0.105,4.223-0.068
		c0.041,0.001,0.085,0,0.126,0.002c0.109,0.004,0.218,0.015,0.321,0.025c0.027,0.003,0.058,0.003,0.082,0.006
		c0.098,0.012,0.188,0.03,0.275,0.047c0.032,0.006,0.066,0.01,0.1,0.017c0.11,0.025,0.214,0.058,0.313,0.095
		c0.009,0.003,0.018,0.004,0.024,0.008c0.21,0.083,0.393,0.195,0.53,0.345c0.081-0.079,0.17-0.145,0.241-0.239
		c-0.877-1.203-4.263,0.104-5.521-0.72c1.755-0.696,3.54-1.639,5.762-2.16c0.285-0.065,0.579-0.118,0.878-0.163
		c0.043-0.006,0.087-0.011,0.13-0.017c1.89-0.269,3.978-0.23,5.29-0.988c0.004-0.001,0.008-0.001,0.012-0.002
		c0.057-0.008,0.113-0.016,0.17-0.024c-0.026-0.013-0.053-0.021-0.079-0.034c-5.533-2.79-12.091,1.311-16.001,3.395
		c0.244-1.332,0.66-2.491,1.199-3.527c-0.538,1.034-0.953,2.192-1.196,3.521c3.91-2.083,10.47-6.184,16.001-3.395
		c0.107-0.068,0.227-0.125,0.319-0.206c-5.686-2.865-12.788,1.318-16.08,3.12c1.961-6.759,8.084-9.356,12.239-13.92
		c-0.576,0.063-1.118,0.159-1.646,0.272c-0.154,0.034-0.305,0.074-0.457,0.111c-0.377,0.094-0.741,0.2-1.096,0.319
		c-0.141,0.047-0.282,0.092-0.418,0.143c-0.438,0.164-0.863,0.341-1.266,0.54c-0.021,0.011-0.044,0.019-0.065,0.029
		c-0.437,0.219-0.851,0.461-1.251,0.717c-0.085,0.055-0.167,0.114-0.251,0.17c-0.318,0.214-0.627,0.437-0.924,0.672
		c-0.078,0.062-0.156,0.123-0.233,0.186c-0.737,0.607-1.423,1.271-2.063,1.978c-0.334,0.369-0.656,0.748-0.974,1.135
		c-0.001,0-0.001,0.001-0.002,0.002c1.383-1.438,3.02-2.73,4.675-3.74c0.025-0.017,0.054-0.032,0.08-0.048
		c0.354-0.214,0.706-0.414,1.06-0.601c0.1-0.053,0.197-0.102,0.296-0.152c0.282-0.144,0.563-0.278,0.84-0.402
		c0.118-0.053,0.235-0.105,0.354-0.155c0.268-0.112,0.526-0.209,0.786-0.301c0.106-0.038,0.217-0.081,0.321-0.115
		c0.354-0.114,0.7-0.212,1.031-0.282c-0.003,0.004-0.007,0.007-0.01,0.011c-0.328,0.07-0.672,0.167-1.022,0.28
		c-0.104,0.034-0.216,0.077-0.322,0.115c-0.259,0.091-0.52,0.189-0.786,0.301c-0.115,0.049-0.233,0.102-0.354,0.155
		c-0.277,0.124-0.558,0.258-0.84,0.402c-0.101,0.05-0.197,0.1-0.296,0.152c-0.354,0.187-0.704,0.386-1.061,0.601
		c-0.025,0.016-0.054,0.031-0.081,0.048c-1.653,1.01-3.292,2.303-4.673,3.74c-0.001,0-0.001,0.001-0.002,0.002
		c-1,1.041-1.864,2.153-2.49,3.292c-0.004,0.004-0.008,0.007-0.011,0.012c-0.413,0.568-0.828,1.134-1.253,1.691
		c0.143-0.86-0.277-1.16-0.479-1.679c-2.909-0.931-5.074-2.605-8.16-3.36c-0.854,1.228-2.402,1.758-4.8,1.44
		c-0.24,0-0.479,0-0.722,0c0,0.08,0,0.16,0,0.24c1.439,0,2.88,0,4.32,0c0.439-0.601,0.737-1.342,1.68-1.44
		c2.515,1.487,5.935,2.067,7.92,4.08c0.204,1.843-0.979,4.727,0.24,6c0.152-0.122,0.267-0.254,0.351-0.393
		c-0.085,0.142-0.199,0.276-0.354,0.4c-1.222-1.273-0.036-4.157-0.24-6c-1.985-2.013-5.405-2.592-7.92-4.08
		c-0.94,0.098-1.24,0.839-1.68,1.44c-1.44,0-2.881,0-4.32,0c-0.24,0-0.479,0-0.72,0c-0.333,0.547-1.671,0.089-2.4,0.24
		c-0.905,0.373-2.092,0.468-3.358,0.48c-0.181,0.542-1.348,0.094-1.92,0.24c-0.333,0.547-1.671,0.089-2.399,0.24
		c-2.521,0.439-2.814,3.105-4.08,4.8c0,0.078,0,0.156,0,0.233c-0.487,0.07-0.226,0.893-0.718,0.958c0,0.08,0,0.16,0,0.24
		c-0.157,1.203-0.911,1.808-0.96,3.12c0.228-0.438,0.425-0.905,0.614-1.382c-0.19,0.479-0.389,0.95-0.617,1.39
		c0,0.078,0,0.156,0,0.234c-0.513,0.207-0.2,1.235-0.718,1.438c0,0.08,0,0.16,0,0.24c0.055,0.454-0.271,0.529-0.239,0.96
		c0.282-0.315,0.479-0.716,0.627-1.166c-0.147,0.453-0.345,0.856-0.63,1.173c0,0.079,0,0.158,0,0.236
		c-0.599,0.68-0.949,1.606-1.197,2.636c0.256-0.331,0.452-0.72,0.627-1.131c-0.176,0.414-0.373,0.806-0.63,1.139
		c-0.106,1.093-0.616,1.783-0.96,2.64c-0.117,0.29-0.227,0.588-0.336,0.886c0.11-0.301,0.221-0.601,0.338-0.894
		c-0.703,0.815-1.044,1.994-1.438,3.12c0.341-0.416,0.591-0.923,0.81-1.461c-0.22,0.541-0.471,1.052-0.813,1.47
		c0,0.079,0,0.158,0,0.237c-0.623,0.653-0.853,1.701-1.197,2.635c0.358-0.374,0.584-0.881,0.778-1.419
		c-0.195,0.541-0.42,1.051-0.78,1.427c0,0.078,0,0.157,0,0.234c-0.813,0.786-0.833,2.364-1.678,3.118c0,0.08,0,0.16,0,0.24
		s0,0.16,0,0.24c0.773-0.983,1.234-2.281,1.678-3.595c0,0.001,0,0.002,0,0.002c-0.443,1.315-0.904,2.615-1.68,3.6
		c0,0.079,0,0.157,0,0.236c-1.938,3.738-4.721,6.636-7.437,9.596c2.928-2.749,5.764-5.59,7.437-9.596c0,0.001,0,0.003,0,0.004
		c-1.674,4.007-4.511,6.85-7.441,9.6c-0.078,0-0.158,0-0.238,0c-5.498,5.542-11.821,10.259-18.96,14.16c-0.08,0-0.16,0-0.24,0
		c-0.438,0.762-1.563,0.837-2.4,1.2c-1.054,0.226-1.547,1.013-2.88,0.96c-1.389,0.851-3.175,1.305-5.04,1.68
		c-0.279,0.201-0.644,0.316-1.2,0.24c-0.929,0.356-2.982,0.85-3.6,0.72c-0.262-0.027-0.35-0.114-0.331-0.232
		c-0.014,0.114,0.075,0.197,0.332,0.224c0.618,0.13,2.671-0.364,3.6-0.72c-1.446-0.086-2.154,0.566-3.6,0.48
		c2.204-2.044,3.781-4.847,5.52-7.44c1.727-2.575,4.189-5.086,4.56-8.16c-2.008-0.771-3.142,1.596-4.08,2.64
		c-0.707,0.787-1.445,1.553-2.195,2.31c-0.164,0.165-0.327,0.331-0.493,0.495c-0.74,0.732-1.495,1.453-2.269,2.158
		c-0.111,0.101-0.224,0.199-0.335,0.299c-0.718,0.647-1.448,1.283-2.188,1.91c-0.149,0.126-0.296,0.253-0.446,0.379
		c-0.813,0.68-1.639,1.349-2.474,2.008c-0.156,0.123-0.313,0.244-0.469,0.366c-0.8,0.625-1.607,1.244-2.42,1.852
		c-0.067,0.05-0.134,0.102-0.202,0.152c-0.89,0.663-1.785,1.314-2.683,1.959c-0.139,0.1-0.277,0.198-0.416,0.298
		c-1.885,1.347-3.777,2.658-5.647,3.943c-0.492,0.369-0.994,0.73-1.522,1.073c0.007-0.015,0.016-0.028,0.023-0.043
		c-0.595,0.408-1.184,0.813-1.772,1.217c-0.228,0.157-0.456,0.314-0.683,0.47c-0.717,0.495-1.428,0.987-2.128,1.476
		c2.392-4.868,5.082-9.127,8.16-13.2c3.109-4.114,6.861-7.586,9.6-12c-2.706,0.464-3.491,2.531-5.04,4.08
		c-0.466,0.466-0.917,0.954-1.375,1.432c0.435-0.439,0.876-0.871,1.328-1.292c0.057-0.053,0.115-0.105,0.172-0.158
		c0.409-0.377,0.822-0.75,1.246-1.112c0.18-0.154,0.366-0.301,0.549-0.453c0.324-0.267,0.645-0.537,0.978-0.795
		c0.541-0.42,1.093-0.829,1.661-1.222c-0.004,0.004-0.007,0.009-0.011,0.013c-0.564,0.391-1.112,0.798-1.651,1.214
		c-0.333,0.258-0.654,0.528-0.978,0.795c-0.183,0.151-0.369,0.299-0.549,0.453c-0.424,0.362-0.837,0.735-1.246,1.112
		c-0.057,0.053-0.115,0.105-0.172,0.158c-0.453,0.423-0.897,0.855-1.333,1.296c-0.003,0.003-0.006,0.006-0.009,0.009
		c-0.941,0.953-1.845,1.942-2.713,2.967c0,0-0.001,0.001-0.002,0.002c-4.508,5.321-8.133,11.525-11.781,17.708
		c-0.464,0.787-0.929,1.573-1.396,2.357c-0.112-7.92-0.979-15.366-0.48-22.8c0.313-4.666,1.753-9.571,3.216-14.144
		c-1.461,4.57-2.9,9.474-3.213,14.137c-0.499,7.434,0.368,14.88,0.48,22.8c0.467-0.784,0.931-1.57,1.396-2.357
		c3.647-6.182,7.272-12.386,11.781-17.708l0.001-0.001c0,0,0,0-0.001,0c-0.593,0.679-1.176,1.371-1.752,2.071
		c-0.082,0.1-0.163,0.2-0.245,0.3c-0.559,0.685-1.11,1.376-1.65,2.079c-0.029,0.039-0.058,0.079-0.088,0.117
		c-1.001,1.307-1.966,2.651-2.9,4.019c-0.157,0.23-0.32,0.457-0.475,0.689c-0.326,0.486-0.64,0.982-0.956,1.476
		c-0.307,0.478-0.612,0.957-0.909,1.441c-0.264,0.429-0.523,0.861-0.779,1.294c-0.357,0.604-0.706,1.214-1.049,1.827
		c-0.196,0.351-0.396,0.7-0.587,1.053c-0.532,0.983-1.052,1.975-1.545,2.979c-0.014-7.021-1.123-14.184-0.48-22.32
		c0.814-10.317,5.549-20.141,8.88-29.52c1.634-4.602,3.097-9.066,4.8-13.2c1.838-4.46,4.436-8.239,6-12.24
		c0.533-1.364,0.328-2.332,0.96-3.12c1.262-1.575,2.998-0.788,5.04-0.96c3.924-0.331,8.235-2.073,12.24-3.12
		c6.717-1.756,13-4.727,20.88-2.16c-4.375,1.352-7.606,4.742-10.56,8.16c-3.506,4.057-7.593,7.933-9.36,13.2
		c2.215-0.851,4.021-3.013,5.76-5.04c4.972-5.8,10.098-13.808,18.96-14.88c6.648-0.805,10.755,1.847,15.36,4.08
		c-5.353-0.249-9.653,2.608-13.44,5.04c-3.833,2.461-7.754,5.1-10.56,8.16c-0.239,0.261-0.478,0.532-0.715,0.809
		c-0.12,0.141-0.237,0.288-0.355,0.433c-0.111,0.135-0.223,0.269-0.332,0.408c-0.151,0.193-0.298,0.393-0.444,0.592
		c-0.069,0.094-0.139,0.186-0.207,0.281c-0.161,0.228-0.316,0.46-0.468,0.696c-0.046,0.07-0.092,0.14-0.136,0.21
		c-0.16,0.255-0.313,0.514-0.458,0.776c-0.03,0.053-0.06,0.107-0.089,0.161c-0.15,0.277-0.292,0.558-0.423,0.843
		c-0.018,0.04-0.036,0.08-0.054,0.12c-0.133,0.298-0.257,0.6-0.367,0.906c-0.009,0.025-0.017,0.052-0.026,0.078
		c-0.112,0.318-0.211,0.641-0.293,0.968c-0.002,0.003-0.004,0.007-0.006,0.011c-0.063,0.134-0.129,0.261-0.19,0.396
		c0.04-0.03,0.079-0.06,0.119-0.089c-0.042,0.188-0.094,0.374-0.123,0.563c10.085-7.469,16.816-19.457,34.56-18.48
		c2.874,2.006,5.479,4.284,8.642,6c0.08,0,0.16,0,0.24,0c0.318,0,0.64,0,0.96,0c1.271-0.93,0.212-1.827,0.479-2.88
		c0.051-0.201,0.116-0.396,0.191-0.585c0.021-0.051,0.048-0.099,0.068-0.149c0.062-0.137,0.12-0.273,0.19-0.404
		c0.025-0.048,0.058-0.094,0.085-0.141c0.075-0.132,0.152-0.263,0.237-0.39c0.021-0.031,0.044-0.061,0.063-0.092
		c0.102-0.143,0.201-0.285,0.313-0.422c0.004-0.005,0.008-0.009,0.011-0.014c1.482-1.879,3.741-3.347,4.068-5.61
		c0.001-0.002,0.002-0.003,0.003-0.005c0.004-0.01,0.01-0.018,0.015-0.027c0.012-0.027,0.029-0.05,0.041-0.078
		c-0.011-0.001-0.021,0-0.031-0.001c-0.011,0-0.021,0-0.031,0c-3.818-0.304-5.752,2.774-7.188,5.766
		c-0.233,0.486-0.456,0.97-0.669,1.436c-0.358-0.249-0.611-0.532-0.781-0.844c0.17,0.31,0.422,0.592,0.778,0.839
		c0.213-0.466,0.436-0.95,0.669-1.436c1.437-2.991,3.368-6.069,7.188-5.766c0.013,0,0.021,0,0.031,0
		c0.01-0.08,0.027-0.157,0.031-0.239c-3.283-0.465-5.14,1.839-6.479,3.84c-0.761,1.135-1.032,2.672-1.92,3.12
		c-1.114-3.757,1.799-5.56,1.682-9.12c-3.021,0.818-3.8,3.882-4.562,6.96c-5.771-1.984-6.763-9.005-6.96-15.6
		c-1.589,2.633-2.615,6.928-1.2,10.56c-2.534-1.853-6.961-1.923-9.12-4.08c-0.152-0.153-0.273-0.315-0.377-0.482
		c-0.022-0.04-0.039-0.082-0.062-0.122c-0.075-0.137-0.144-0.275-0.197-0.419c-0.007-0.017-0.011-0.035-0.017-0.052
		c-0.352-0.989-0.313-2.122-1.018-3.142c-0.001-0.034-0.008-0.062-0.008-0.097c-0.018,0.006-0.027,0.02-0.046,0.027
		c-1.114,0.461-0.854,2.297-0.914,3.813c-6.06-1.301-12.467-2.253-19.44-2.64c-0.136-0.166-0.242-0.362-0.324-0.581
		c0.083,0.217,0.187,0.411,0.322,0.575c6.973,0.387,13.382,1.339,19.44,2.64c0.062-1.515-0.2-3.351,0.914-3.813
		c-0.065-0.089-0.116-0.181-0.194-0.268c-1.179,0.501-0.654,2.706-1.199,3.84c-5.645-1.477-12.042-2.199-18.719-2.64
		c-1.486-1.074,0.33-5.45-1.68-6c-1.932,0.724-1.596,4.556-0.96,6.48c-2.221,0.419-4.207,1.073-6.24,1.68
		c-0.005-0.715,0.022-1.462-0.72-1.44c0.206,0.124,0.295,0.286,0.329,0.46c-0.034-0.172-0.124-0.331-0.328-0.453
		c-3.967,0.287-4.137-3.224-5.76-5.28c-0.683,1.085-0.781,2.128-0.52,3.085c-0.264-0.958-0.165-2.003,0.519-3.091
		c1.624,2.056,1.793,5.566,5.76,5.28c-3.857,0.017-3.727-3.954-6-5.52c-2.122,3.472,1.233,6.868,3.84,7.92
		c-3.293,0.546-6.153,1.527-9.36,2.16c-1.93-1.19-3.092-3.148-5.52-3.84c-1.09,2.095,1.324,3.921,1.44,4.8
		c-2.48,0.32-4.357,1.243-6,2.4c-0.555-0.325-0.795-0.965-1.92-0.72c-5.242,1.479-10.102,3.339-13.92,6.24
		c4.225-7.793,10.517-13.438,17.28-18.72c0.508-0.397,1.035-0.779,1.575-1.149c0.235-0.161,0.482-0.311,0.723-0.466
		c0.309-0.2,0.613-0.403,0.932-0.595c0.329-0.199,0.671-0.387,1.011-0.578c0.238-0.133,0.471-0.271,0.713-0.401
		c0.406-0.217,0.823-0.424,1.241-0.63c0.182-0.09,0.36-0.183,0.544-0.27c0.475-0.227,0.96-0.444,1.45-0.657
		c0.13-0.057,0.258-0.115,0.389-0.17c0.541-0.23,1.091-0.452,1.647-0.667c0.08-0.031,0.159-0.063,0.239-0.093
		c0.6-0.229,1.208-0.448,1.823-0.661c0.037-0.012,0.073-0.025,0.11-0.038c0.648-0.222,1.303-0.435,1.967-0.639
		c0.002,0,0.004-0.001,0.006-0.002c-0.006,0.002-0.014,0.003-0.02,0.005c-0.431,0.116-0.847,0.234-1.269,0.352
		c-0.317,0.088-0.641,0.175-0.951,0.265c-0.705,0.204-1.391,0.413-2.049,0.635c-5.454,1.839-9.422,4.903-13.441,8.159
		c-8.061,6.53-14.209,14.646-18.96,24.24c-4.919,3.26-8.931,6.902-12.24,12.24c-3.029,4.886-3.467,12.569-4.08,19.44
		C208.437,183.866,208.369,195.128,208.8,204.64c0.653,14.356,4.018,25.625,7.681,36c0.592-1.967,0.771-3.779,0.71-5.513
		c0.062,1.736-0.117,3.55-0.71,5.519C212.817,230.265,209.452,218.996,208.8,204.64z M319.68,115.119
		c0.433-0.093,0.77-0.269,1.05-0.497C320.448,114.851,320.111,115.027,319.68,115.119c-0.648-1.412,0.101-2.833,1.025-3.913
		C319.78,112.287,319.032,113.706,319.68,115.119z M373.437,213.281c-0.422-0.002-0.607,0.231-0.983,0.274
		C372.828,213.51,373.014,213.276,373.437,213.281C373.436,213.28,373.437,213.281,373.437,213.281z M371.028,213.29
		c-0.609-0.128-1.291-0.185-1.879-0.334C369.737,213.104,370.419,213.161,371.028,213.29z M365.521,208.618
		c-2.095-1.552-3.881-3.412-6.241-4.698v0C361.641,205.206,363.427,207.066,365.521,208.618z M342.958,188.321
		c0.001,0,0.002,0,0.003,0c2.628,6.973,7.51,11.689,11.04,17.759c-2.707,11.566,2.907,19.604,4.079,29.519
		c-0.001,0-0.002,0.001-0.003,0.002c-1.172-9.917-6.786-17.953-4.079-29.52C350.468,200.011,345.585,195.294,342.958,188.321z
		 M357.355,251.686c2.213-4.574,2.177-9.65,3.84-13.92c1.17-3.002,3.727-6.451,6.612-9.393c-2.883,2.939-5.437,6.386-6.605,9.386
		c-1.665,4.271-1.629,9.346-3.842,13.92c-0.093,0.157-0.184,0.291-0.272,0.403C357.176,251.972,357.265,251.84,357.355,251.686z
		 M360.238,231.041c0,0,0.001-0.001,0.002-0.001c1.316-0.277,0.473,1.605,1.437,1.679c0,0-0.001,0.001-0.001,0.001
		C360.706,232.65,361.555,230.764,360.238,231.041z M335.531,145.03c0.001-0.002,0.001-0.003,0.002-0.005
		c0.043-0.108,0.084-0.217,0.132-0.325c0.118-0.266,0.247-0.53,0.392-0.794c0.002-0.002,0.003-0.005,0.005-0.008
		c-0.001,0.002-0.002,0.004-0.003,0.005c-0.002,0.003-0.003,0.006-0.005,0.01c-0.145,0.264-0.272,0.528-0.392,0.794
		C335.614,144.815,335.574,144.922,335.531,145.03z M331.944,146.659c-0.005,0.483-0.179,1.055-0.266,1.344c0-0.001,0-0.001,0-0.002
		C331.765,147.713,331.938,147.143,331.944,146.659z M312.762,161.738c-0.867,0.828-1.621,1.771-2.587,2.501
		C311.141,163.509,311.894,162.566,312.762,161.738z M292.898,182.233c-1.042,1.201-2.18,2.307-3.314,3.415
		C290.719,184.54,291.856,183.435,292.898,182.233z M249.12,202c-0.741-0.059-1.356-0.243-1.92-0.48
		c2.633-2.167,5.577-4.023,7.92-6.48c0.75,0.388-0.492,0.76-0.48,1.2c-0.779,0.979-1.659,1.86-2.64,2.64
		C251.333,200.212,249.773,200.653,249.12,202z M258,207.28C258,207.28,257.999,207.28,258,207.28
		c-1.019-2.74-4.169-3.346-6.958-4.314c0-0.002,0.001-0.003,0.002-0.004C253.834,203.931,256.985,204.537,258,207.28z
		 M231.839,210.168c0.001-0.002,0.002-0.005,0.003-0.007c9.31-7.438,20.138-13.986,28.318-22.321c1.29-1.314,1.859-3.792,4.32-3.6
		c-0.001,0.002-0.002,0.005-0.003,0.008c-2.459-0.19-3.029,2.287-4.318,3.6C251.978,196.183,241.149,202.731,231.839,210.168z
		 M244.325,207.761c3.058,0.984,7.945,2.315,9.034,4.806c-1.095-2.488-5.982-3.818-9.037-4.801
		C244.323,207.764,244.324,207.763,244.325,207.761z M256.56,193.84c0,0,0,0.001-0.001,0.001c-3.323,2.597-6.725,5.114-9.838,7.918
		c0.849,0.11,1.386,0.534,2.399,0.48c-1.014,0.054-1.55-0.37-2.4-0.48C249.835,198.955,253.237,196.437,256.56,193.84z
		 M226.32,213.28c0,0-0.001-0.001-0.001-0.002c-1.341-16.966-1.379-33.675,2.161-47.756c1.66-6.603,4.581-12.879,7.68-18.24
		c3.254-5.629,7.362-10.51,9.6-16.32c-4.415,1.168-9.977,2.242-13.088,5.15c3.111-2.911,8.675-3.985,13.092-5.153
		c-2.238,5.811-6.346,10.691-9.6,16.32c-3.099,5.361-6.02,11.637-7.68,18.24C224.94,179.603,224.979,196.313,226.32,213.28z
		 M224.231,208.256c-0.146-0.371-0.292-0.74-0.43-1.123c-0.093-0.258-0.181-0.526-0.272-0.789c-0.13-0.377-0.26-0.754-0.384-1.142
		c-0.088-0.277-0.172-0.563-0.257-0.844c-0.117-0.389-0.235-0.777-0.346-1.175c-0.082-0.291-0.159-0.586-0.237-0.882
		c-0.107-0.403-0.213-0.807-0.314-1.219c-0.073-0.3-0.144-0.604-0.214-0.909c-0.097-0.419-0.192-0.839-0.283-1.266
		c-0.065-0.305-0.127-0.613-0.189-0.922c-0.088-0.438-0.173-0.877-0.254-1.321c-0.056-0.308-0.11-0.617-0.164-0.928
		c-0.078-0.455-0.152-0.912-0.224-1.373c-0.047-0.308-0.094-0.616-0.138-0.925c-0.068-0.475-0.132-0.953-0.193-1.433
		c-0.039-0.301-0.077-0.602-0.112-0.905c-0.058-0.498-0.111-0.999-0.162-1.501c-0.03-0.292-0.06-0.584-0.087-0.877
		c-0.048-0.521-0.09-1.047-0.129-1.573c-0.021-0.277-0.043-0.552-0.062-0.83c-0.037-0.555-0.067-1.112-0.095-1.669
		c-0.013-0.252-0.028-0.503-0.039-0.756c-0.025-0.599-0.042-1.199-0.058-1.8c-0.005-0.212-0.013-0.424-0.017-0.636
		c-0.012-0.676-0.016-1.353-0.014-2.029c0-0.137-0.002-0.273-0.001-0.411c0.004-0.728,0.02-1.455,0.04-2.18
		c0.003-0.066,0.004-0.133,0.007-0.2c0.001-0.023,0.001-0.047,0.003-0.07c0.105-2.029,0.267-4.04,0.475-6.01
		c0.208-1.962,0.399-3.753,0.611-5.432c0.004-0.03,0.008-0.058,0.012-0.087c0.145-0.867,0.303-1.72,0.476-2.562
		c0.024-0.118,0.051-0.233,0.076-0.35c0.149-0.711,0.309-1.412,0.479-2.103c0.051-0.208,0.104-0.412,0.157-0.617
		c0.159-0.619,0.328-1.229,0.504-1.828c0.057-0.192,0.111-0.386,0.169-0.576c0.231-0.753,0.472-1.493,0.73-2.21
		c0.003-0.009,0.006-0.02,0.01-0.029c0.264-0.733,0.545-1.442,0.839-2.134c0.075-0.176,0.154-0.345,0.231-0.519
		c0.22-0.498,0.447-0.986,0.683-1.459c0.104-0.21,0.21-0.416,0.318-0.621c0.232-0.442,0.473-0.87,0.72-1.288
		c0.105-0.179,0.208-0.362,0.317-0.537c0.355-0.572,0.721-1.125,1.105-1.645c0.365-0.595,0.059-1.861,0.48-2.4
		c2.207-2.096,2.402-4.897,4.08-6.96c2.104-2.586,8.69-4.921,12.96-6c0.204-0.044,0.226,0.093,0.24,0.24
		c-5.507,10.648-13.671,20.563-17.04,34.08c-3.415,13.699-3.701,30.324-2.16,46.8c-0.278-0.521-0.547-1.062-0.809-1.621
		c-0.087-0.185-0.167-0.381-0.252-0.57c-0.171-0.382-0.343-0.764-0.507-1.162C224.413,208.736,224.324,208.492,224.231,208.256z
		 M224.311,213.745c-0.889-1.736-1.379-3.871-2.056-5.818C222.933,209.874,223.422,212.009,224.311,213.745z M219.188,195.109
		c-2.357-14.751-2.531-31.569,2.813-43.509c1.154-2.58,2.699-5.396,4.08-7.92c1.505-2.752,2.665-6.019,4.08-7.92
		c3.024-4.062,9.854-5.212,14.88-6.96c0.718,0.001,1.008,0.431,1.2,0.96c1.945-1.175,3.757-2.483,6.72-2.64
		c0.01-0.077,0.008-0.154-0.004-0.232c0.013,0.08,0.016,0.159,0.005,0.238c-2.963,0.157-4.775,1.465-6.72,2.64
		c-0.191-0.529-0.482-0.958-1.2-0.96c-5.027,1.748-11.856,2.898-14.88,6.96c-1.416,1.901-2.575,5.168-4.08,7.92
		c-1.381,2.524-2.925,5.34-4.08,7.92C216.659,163.546,216.832,180.36,219.188,195.109z M251.282,122.326
		c-0.218,0.419-0.29,0.806-0.263,1.167c-0.028-0.362,0.042-0.751,0.262-1.172c2.003,1.197,3.652,2.749,5.52,4.08
		c2.783-1.293,6.526-1.627,9.593-2.638c0.003,0.001,0.006,0.003,0.008,0.004c-3.068,1.012-6.815,1.345-9.6,2.64
		C254.934,125.074,253.285,123.523,251.282,122.326z M237.84,148.72c-1.563,5.404-3.571,11.296-5.551,17.141
		C234.269,160.016,236.276,154.124,237.84,148.72z M229.68,172.96c0.731-7.669,3.657-15.223,6.96-21.12
		c0.236-0.627-0.921,3.339-1.44,4.8C233.424,161.642,231.064,168.436,229.68,172.96z M275.282,121.366
		c-2.315,0.405-4.343,1.097-6.48,1.68c-0.233-0.024-0.342-0.112-0.388-0.237c0.047,0.122,0.156,0.208,0.386,0.231
		c2.137-0.583,4.165-1.275,6.48-1.68c-0.178-1.292-1.364-5.827,0.96-6.48c0.12,0.181,0.212,0.391,0.283,0.621
		c-0.071-0.228-0.162-0.436-0.281-0.615C273.918,115.539,275.104,120.074,275.282,121.366z M287.03,124.725
		c0.003,0,0.006,0.001,0.009,0.002c-9.03,4.261-14.678,11.904-19.769,20.102C272.358,136.631,278.004,128.988,287.03,124.725z
		 M308.882,128.326c-0.266-0.144-0.541-0.279-0.823-0.406c-0.013-0.005-0.024-0.01-0.038-0.016c-3.425-1.529-7.921-1.988-9.55-5.313
		c1.631,3.321,6.125,3.779,9.547,5.308c0.014,0.005,0.025,0.01,0.038,0.016c0.282,0.127,0.56,0.262,0.823,0.406
		c-0.705-2.94-1.208-7.123,0.72-9.12c0,0.002,0,0.005,0.001,0.008C307.675,121.207,308.177,125.386,308.882,128.326z
		 M307.438,130.487c-6.247,0.041-10.786,2.921-15.358,6c-0.387,0.26-0.768,0.519-1.143,0.776
		c-4.904,3.368-8.797,6.567-11.655,11.474c2.859-4.912,6.753-8.111,11.659-11.481c0.375-0.258,0.756-0.516,1.143-0.776
		c4.566-3.075,9.102-5.951,15.338-5.999C307.428,130.482,307.433,130.485,307.438,130.487z M311.889,129.202
		c1.158,2.025,2.867,3.499,5.392,4.159c0.085-3.276,1.76-6.239,3.84-6.72c0,0.002-0.001,0.004-0.001,0.006
		c-2.078,0.483-3.751,3.445-3.836,6.719C314.758,132.706,313.047,131.23,311.889,129.202z M211.68,232.72
		c-2.611,0.349-4.789,1.131-5.28,3.6C206.89,233.85,209.069,233.069,211.68,232.72z M206.64,236.32c0.46-2.259,2.604-2.836,4.8-3.36
		c2.623,6.86,4.34,16.083,2.88,24.96c0,0,0,0,0,0.001c-0.554,0.805-0.084,2.635-0.24,3.839c-0.133-0.354-0.284-0.694-0.441-1.028
		c-0.041-0.086-0.084-0.17-0.125-0.255c-0.135-0.273-0.275-0.544-0.421-0.81c-0.021-0.038-0.042-0.076-0.063-0.114
		c-0.828-1.488-1.789-2.892-2.54-4.529c0.809,2.175,1.748,4.221,2.765,6.188c0.349,0.669,0.702,1.333,1.064,1.987
		c0,0,0,0.001,0,0.001c-0.363-0.655-0.718-1.319-1.066-1.989c-1.021-1.962-1.956-4.012-2.765-6.188
		c-0.025-0.067-0.046-0.137-0.069-0.207c-0.085-0.251-0.166-0.507-0.252-0.757c-0.219-0.633-0.444-1.261-0.638-1.919v-0.001
		c-0.003-0.009-0.005-0.02-0.008-0.029c-0.401-1.909-0.495-4.055-0.742-6.018c0-0.016-0.002-0.033-0.004-0.05
		c-0.047-0.37-0.104-0.73-0.166-1.083c-0.019-0.107-0.035-0.218-0.055-0.324c-0.06-0.309-0.135-0.602-0.213-0.892
		c-0.03-0.112-0.054-0.231-0.087-0.34c-0.116-0.381-0.25-0.742-0.413-1.077c0-0.001,0-0.003,0-0.005
		c-0.001,0-0.001-0.001-0.002-0.002C206.948,240.811,206.267,239.092,206.64,236.32z M214.145,261.879
		c0.138,0.178,0.19-0.305,0.175-0.598c0,0,0,0,0-0.001c0.084,0.048,0.142,0.101,0.18,0.158c-0.038-0.057-0.095-0.109-0.179-0.157
		C214.336,261.575,214.283,262.057,214.145,261.879z M214.081,263.443c-0.035,0.505-0.252,0.826-0.688,0.928
		c0.436-0.103,0.654-0.425,0.688-0.931C214.08,263.441,214.08,263.442,214.081,263.443z M211.2,280.72
		c0.632-0.072,0.788,0.333,0.72,0.96c0.473,0.407,0.644,1.116,0.72,1.92c0.322,0.158,0.447,0.513,0.48,0.96
		C210.792,285.925,210.547,282.314,211.2,280.72z M214.8,286.48c0.744-2.987-1.318-6.668-2.88-8.88
		c-0.586-10.382-6.637-16.553-5.04-28.32c0,0.001,0,0.002,0,0.004c-1.595,11.765,4.455,17.935,5.041,28.316
		C213.483,279.812,215.545,283.493,214.8,286.48c0.657,0.544,0.954,1.447,1.909,1.692C215.753,287.927,215.457,287.024,214.8,286.48
		z M214.321,266.8c0.295,7.865,2.343,13.977,3.36,21.12c-1.526,0.326-1.622-0.778-2.4-1.2c0.418-2.901-1.322-6.927-3.12-9.12
		c-0.376-8.584-4.391-13.529-5.04-21.84c0,0,0-0.001-0.001-0.002c-0.154-0.965,0.311-2.547-0.239-3.116c0,0,0-0.001,0-0.002
		c0-0.4,0-0.8,0-1.2c1.143,5.017,4.272,8.048,5.52,12.96c0.32,0.342,1.356,0.342,1.678,0.001
		C214.23,265.131,213.773,266.468,214.321,266.8c-0.025-0.117-0.031-0.253-0.03-0.395c0-0.038,0.001-0.076,0.002-0.114
		c0.004-0.131,0.012-0.266,0.023-0.404c-0.048-0.456-0.091-0.917-0.133-1.379c-0.032-0.039-0.06-0.081-0.103-0.107
		c0,0,0,0,0.001-0.001c0.043,0.026,0.07,0.068,0.103,0.106c0,0,0,0,0,0.001c0.042,0.462,0.085,0.923,0.133,1.379c0,0,0,0,0,0.001
		c0.692,6.577,1.954,12.583,3.027,18.779c0.028,0.164,0.058,0.327,0.086,0.491c0.17,0.994,0.335,1.994,0.491,3.002
		c-0.156-1.009-0.321-2.008-0.491-3.001c-0.028-0.164-0.058-0.327-0.086-0.491c-1.073-6.195-2.335-12.202-3.027-18.779
		c-0.011,0.138-0.019,0.272-0.023,0.403c-0.001,0.038-0.002,0.076-0.002,0.114C214.292,266.547,214.297,266.683,214.321,266.8z
		 M216.671,212.25c0,0.001,0,0.002,0,0.002c-0.184,0.83-0.342,1.657-0.429,2.474c-0.058,0.542-0.084,1.043-0.116,1.507
		c-0.001,0.019-0.003,0.036-0.004,0.054c-0.013,0.185-0.027,0.363-0.043,0.536c-0.006,0.063-0.014,0.124-0.02,0.185
		c-0.016,0.139-0.035,0.273-0.057,0.404c-0.009,0.058-0.019,0.116-0.03,0.172c-0.035,0.177-0.076,0.35-0.13,0.514
		c-0.001,0.004-0.004,0.009-0.006,0.014c-0.053,0.157-0.121,0.306-0.198,0.452c-0.024,0.044-0.052,0.088-0.078,0.131
		c-0.066,0.108-0.141,0.214-0.226,0.316c-0.034,0.042-0.066,0.083-0.104,0.124c-0.125,0.136-0.263,0.267-0.429,0.391
		c0-0.002,0-0.004,0-0.006c0.166-0.125,0.304-0.255,0.429-0.391c0.037-0.04,0.07-0.082,0.104-0.124
		c0.084-0.102,0.16-0.208,0.226-0.316c0.026-0.044,0.054-0.087,0.078-0.131c0.078-0.146,0.145-0.294,0.198-0.452
		c0.001-0.005,0.004-0.009,0.006-0.014c0.055-0.164,0.095-0.337,0.13-0.514c0.011-0.056,0.021-0.115,0.03-0.172
		c0.022-0.131,0.041-0.266,0.057-0.404c0.007-0.061,0.014-0.122,0.02-0.185c0.017-0.173,0.031-0.351,0.043-0.536
		c0.001-0.018,0.002-0.034,0.004-0.052c-0.036,0.223-0.072,0.445-0.125,0.651c-0.271,1.066-1.237,1.815-1.44,2.88
		c-1.25,6.563,4.495,12.73,1.92,19.92c-1.358-5.443-4.174-11.3-5.52-18.24c-2.554-13.169-2.381-29.494-1.44-44.64
		c0.445-7.167,0.875-15.523,3.36-20.4c2.976-5.84,7.629-9.957,12.72-13.2c0.723-2.157,2.091-3.669,3.12-5.52
		c-2.735,7.323-8.869,14.407-10.32,24.72c-1.732,12.309-1.247,27.596,1.68,38.4c0,1.121-1.603,0.637-1.44,1.92
		c-0.685,3.256-1.385,6.264-1.97,9.52c0.486-2.194,1.208-4.414,1.704-6.588c0.023-0.103,0.046-0.206,0.068-0.308
		c0.08-0.365,0.148-0.728,0.21-1.089c0.022-0.13,0.047-0.26,0.067-0.39c0.071-0.465,0.129-0.927,0.161-1.385
		c0.069-0.81,1.363-0.396,1.44-1.199c0,0.001,0,0.003,0.001,0.005c-0.077,0.803-1.371,0.39-1.44,1.2
		c-0.031,0.458-0.089,0.92-0.161,1.385c-0.02,0.129-0.044,0.26-0.067,0.39c-0.063,0.361-0.131,0.724-0.21,1.089
		c-0.022,0.103-0.044,0.205-0.068,0.308C217.879,207.832,217.158,210.055,216.671,212.25z M224.64,310.242
		c0.019-0.575,0.01-1.149-0.003-1.724c-0.006-0.266-0.022-0.531-0.036-0.797c0.013,0.265,0.029,0.53,0.036,0.795
		c0.017,0.575,0.022,1.149,0.003,1.724c0.225,0.525,0.458,1.046,0.691,1.567C225.098,311.287,224.865,310.767,224.64,310.242z
		 M248.88,341.681c-0.622-0.391-1.23-0.802-1.831-1.222c-0.188-0.132-0.373-0.268-0.559-0.402c-0.42-0.303-0.834-0.613-1.245-0.931
		c-0.198-0.153-0.396-0.306-0.591-0.462c-0.438-0.351-0.87-0.711-1.297-1.078c-0.137-0.117-0.276-0.229-0.411-0.348
		c-0.531-0.466-1.051-0.945-1.564-1.435c-0.201-0.191-0.396-0.388-0.594-0.583c-0.324-0.318-0.645-0.64-0.961-0.967
		c-0.228-0.235-0.453-0.472-0.677-0.711c-0.283-0.303-0.561-0.611-0.838-0.921c-0.224-0.25-0.45-0.499-0.67-0.753
		c-0.389-0.45-0.771-0.909-1.148-1.373c-0.3-0.368-0.593-0.744-0.886-1.12c-0.192-0.247-0.384-0.493-0.573-0.743
		c-0.269-0.356-0.534-0.717-0.798-1.081c-0.154-0.213-0.307-0.428-0.458-0.643c-0.281-0.397-0.561-0.795-0.835-1.199
		c-0.043-0.064-0.086-0.131-0.129-0.195c-0.664-0.986-1.307-1.993-1.929-3.017c-0.027-0.044-0.054-0.087-0.081-0.132
		c-0.301-0.498-0.596-1-0.888-1.506c-0.063-0.109-0.126-0.219-0.189-0.328c-0.296-0.519-0.589-1.04-0.875-1.566
		c-0.001-0.002-0.002-0.004-0.003-0.006c-0.604-1.108-1.186-2.232-1.748-3.37c-0.001-0.002-0.001-0.003-0.002-0.005
		c-0.563-1.141-1.107-2.294-1.63-3.46v-0.001c-0.001-0.002-0.002-0.004-0.002-0.006c-0.121-0.364-0.226-0.725-0.319-1.083
		c-0.014-0.055-0.03-0.111-0.044-0.167c-0.092-0.373-0.17-0.743-0.225-1.107c-0.13-0.866-0.224-1.746-0.305-2.634
		c0-0.001,0-0.001,0-0.002s0-0.002,0-0.003c0,0,0,0,0-0.001c-0.04-0.666-0.09-1.334-0.155-2.003v-0.001
		c0-0.003-0.001-0.007-0.001-0.011c-0.133-2.12-0.204-4.317-0.315-6.597c-0.031-0.628-0.066-1.263-0.106-1.904
		c-0.02-0.313-0.04-0.624-0.063-0.94c-0.069-0.966-0.147-1.943-0.255-2.943c0-0.881,0-1.761,0-2.64c-0.08,0-0.16,0-0.24,0
		c0,0,0,0,0-0.001c0.08,0,0.16,0,0.24,0c-0.158-1.682,0.317-3.997-0.24-5.28c0,0,0,0,0,0.001c-1.476-9.404-3.984-17.775-4.56-28.08
		c0,0,0-0.001,0-0.001c0.548-0.332,0.09-1.67,0.24-2.4c-1.168-6.785,0.442-11.688,0.72-18.72c0.398-0.492,0.398-2.627,0-3.12
		c-0.318-3.442-1.683-5.837-1.68-9.6c0-1.04,0-2.08,0-3.12c0.208-5.473,1.447-9.913,2.88-14.16c1.025,3.536,2.634,6.487,3.6,10.08
		c0.119,0.093,0.241,0.18,0.363,0.26c0.054,0.035,0.109,0.064,0.163,0.097c0.07,0.042,0.139,0.087,0.209,0.125
		c0.079,0.043,0.16,0.079,0.24,0.116c0.045,0.021,0.09,0.046,0.136,0.066c0.097,0.042,0.195,0.076,0.292,0.109
		c0.029,0.01,0.057,0.022,0.085,0.032c0.106,0.034,0.212,0.06,0.317,0.084c0.02,0.004,0.039,0.011,0.058,0.015
		c0.118,0.024,0.235,0.042,0.351,0.052c0.006,0,0.012,0.002,0.018,0.002c0.056,0.005,0.108,0,0.162,0.001
		c0.002,0.001,0.004,0.003,0.007,0.004c0.003-0.001,0.005-0.003,0.008-0.004c1.141,0.03,2.114-0.641,2.37-2.285
		c0,0,0-0.001,0.001-0.001c0.054-0.071,0.108-0.142,0.164-0.212c0.008-0.01,0.016-0.021,0.024-0.032
		c0.003-0.004,0.007-0.008,0.011-0.013c0.538-0.475,1.185-0.852,1.913-1.153c0.021-0.008,0.04-0.018,0.06-0.026
		c0.231-0.094,0.471-0.18,0.717-0.259c0.031-0.01,0.063-0.02,0.095-0.03c1.034-0.324,2.191-0.531,3.411-0.667
		c0.039-0.004,0.078-0.009,0.117-0.013c0.272-0.029,0.549-0.055,0.827-0.078c0.08-0.006,0.16-0.013,0.241-0.019
		c0.265-0.021,0.532-0.039,0.8-0.055c0.083-0.005,0.167-0.009,0.25-0.013c0.458-0.024,0.918-0.043,1.38-0.06
		c0.15-0.005,0.3-0.011,0.451-0.016c0.141-0.004,0.281-0.007,0.421-0.011c0.843-0.023,1.682-0.04,2.501-0.063
		c0.015,0,0.03-0.001,0.044-0.001c0.288-0.008,0.574-0.018,0.856-0.028c0.025,0.001,0.051,0.004,0.076,0.005
		c0.001,0,0.002,0,0.003,0c0.433,0.02,0.855,0.025,1.26-0.002c0.148-0.01,0.302-0.048,0.456-0.094
		c0.008-0.002,0.017-0.006,0.025-0.009c0.496-0.037,0.981-0.081,1.435-0.143c3.009,0.43,4.209,2.671,6.72,3.6
		c1.284-4.853-5.253-5.911-8.88-7.44c1.889-1.832,5.205,0.502,8.882-0.24c0.411,0.549,1.832,0.088,2.64,0.24c0.64,0,1.28,0,1.92,0
		c-0.425-2.534-2.653-3.267-4.8-4.08c-0.864,0.064-1.157-0.443-1.92-0.48c0.118-0.133,0.276-0.18,0.464-0.181
		c-0.188,0-0.346,0.047-0.463,0.181c0.763,0.037,1.056,0.544,1.92,0.48c0.054-0.346,0.716-0.084,0.72-0.48
		c-0.131,0.04-0.264,0.061-0.396,0.068c0.132-0.008,0.265-0.029,0.396-0.068c0.42-0.13,0.976-0.127,1.186-0.467
		c0.005-0.003,0.011-0.004,0.016-0.007c12.118-1.722,21.512-6.167,29.28-12.24c0.002-0.002,0.004-0.005,0.006-0.008
		c0.785-0.087,1.033-0.712,1.427-1.19c0.002,0,0.004-0.001,0.007-0.002c0.002-0.002,0.004-0.004,0.006-0.006
		c0.077,0,0.154,0,0.232,0c4.054-4.164,8.517-7.919,12.686-11.967c0.012-0.008,0.022-0.019,0.035-0.027
		c0.002-0.002,0.004-0.004,0.005-0.006c0.078,0,0.156,0,0.233,0c0.779-0.98,1.66-1.86,2.641-2.64c0-0.078,0-0.155,0-0.233
		c0,0,0.001,0,0.001-0.001c0-0.002,0-0.004,0-0.007c1.38-1.18,2.66-2.459,3.84-3.839c-1.409,1.05-2.404,2.515-3.807,3.571
		c1.402-1.057,2.396-2.521,3.807-3.571c0-0.077,0-0.155,0-0.233c0,0,0.001,0,0.001-0.001c0-0.003,0.002-0.005,0.003-0.009
		c0.478-0.396,1.104-0.643,1.192-1.429c0.002-0.001,0.003-0.001,0.004-0.002c0.278-1.082,0.945-1.775,1.681-2.4
		c0-0.003,0.002-0.006,0.002-0.009c1.747-1.691,3.366-3.51,5.037-5.277c0.059-0.336,0.383-0.405,0.473-0.707
		c0.003-0.002,0.005-0.005,0.008-0.007c2.517-3.804,5.896-6.745,6.479-12.48c1.617-1.342,5.106-0.813,6.24-2.64
		c-0.039-0.279-0.029-0.51,0.016-0.706c-0.044,0.195-0.053,0.423-0.015,0.7c0.279-0.041,0.26-0.379,0.239-0.72
		c1.799,0.036,2.422,1.246,4.064,1.437c0.005,0.003,0.01,0.005,0.015,0.009c0.003,0,0.005,0.001,0.008,0
		c0.735,0.579,0.138,1.023,0.233,2.154c0,2.24,0,4.48,0,6.72c0.078,0,0.157,0,0.237,0v0c-0.079,0-0.156,0-0.235,0
		c0.151,0.887-0.313,2.391,0.238,2.88c0.578,7.792,3.303,13.436,5.021,20.088c0.005,0.026,0.012,0.051,0.017,0.078
		c0.356,0.444,0.46,1.14,0.479,1.92c0.005,0.018,0.012,0.034,0.017,0.052c1.502,7.755,5.028,13.483,8.627,19.142
		c1.838,2.402,3.979,4.502,5.76,6.96c-0.791,0.885-0.384,2.97-0.479,4.55c-0.001,0.002-0.001,0.004-0.002,0.006
		c0.098-1.581-0.313-3.669,0.479-4.556c-1.781-2.458-3.922-4.558-5.76-6.96c0.013,0.307,0.31,0.332,0.239,0.72
		c1.761,2.08,3.622,4.059,5.28,6.24c-0.863,1.535-0.323,4.474-0.718,6.48c0,0.16,0,0.32,0,0.48c-0.557,1.203-0.083,3.438-0.238,5.04
		c-0.001,0-0.001,0-0.002,0c0,0.4,0,0.8,0,1.2c-0.399,2.642-0.399,6.958,0,9.6c0,0.08,0,0.16,0,0.24c0.001,0,0.001,0,0.002,0
		c-0.03,1.31,0.001,2.56,0.479,3.36c0-0.318,0-0.636,0-0.953c0.523,0.034,0.101,1.018,0.236,1.439c0.001,0,0.002,0.001,0.003,0.001
		c-0.061,1.736,0.018,3.337,0.721,4.313c0,0.08,0,0.16,0,0.24c0.517,0.201,0.208,1.222,0.712,1.433c0,0,0,0,0,0.001
		c-0.504-0.211-0.195-1.233-0.712-1.433c0.253,1.187,0.506,2.374,0.96,3.36c0,0,0,0,0-0.001c0.276,0.284,0.271,0.849,0.24,1.44
		c0.253,1.907,0.46,3.859,0.96,5.52c0,0,0,0,0,0.001c0.47,0.165-0.053,1.324,0.473,1.437c0.001,0.003,0.001,0.005,0.001,0.008
		c0,0.08,0,0.16,0,0.24c0.002,0.008,0.004,0.017,0.006,0.024c-0.071,1.821,0.319,3.18,1.438,3.81
		c0.702,0.063,0.737-0.542,1.439-0.48c-0.056-0.454,0.271-0.529,0.238-0.96c0.001,0,0.001,0,0.002,0
		c1.638-4.284,2.885-8.957,3.842-13.92c2.854-4.345,5.47-8.931,9.601-12c0.832-1.261,2.448-1.737,3.496-2.783
		c-1.047,1.046-2.662,1.522-3.496,2.783c0.919-0.68,1.812-1.388,2.88-1.92c0.27-0.45,0.93-0.509,1.199-0.96c0.08,0,0.16,0,0.24,0
		c0.245-0.555,1.104-0.497,1.92-0.48c-0.816-0.016-1.675-0.074-1.92,0.48c0.573-0.146,1.095-0.345,1.92-0.24
		c3.508,2.573,3.423,8.737,3.84,14.4c-0.104,1.096,0.243,2.643-0.239,3.36c-0.801,2.16-2.015,3.906-2.88,6c0,0.08,0,0.16,0,0.24
		c-0.756,1.008-0.62,4.176-0.24,5.52c0,0.32,0,0.64,0,0.96c-1.494,5.466-5.063,8.857-6.72,14.16c0.793-0.884,1.075-2.28,1.82-3.213
		c-0.745,0.934-1.027,2.33-1.82,3.213c0,0.158,0,0.317,0,0.474c-0.003,0.004-0.004,0.008-0.007,0.012
		c-0.613,0.583-0.328,2.063-0.955,2.633c-0.416-0.433-1.198,2.761-1.438,4.081c0.031,0.591,0.036,1.156-0.238,1.44
		c-0.002,0.315-0.002,0.631-0.002,0.945c0-0.315,0-0.63,0-0.945c-1.638,4.764-5.937,6.865-10.32,8.88
		c-0.146,0.014-0.283,0.036-0.239,0.239c-0.001,0-0.001,0.001-0.002,0.001c-0.16,0-0.32,0-0.479,0
		c0.048,0.265-0.489,0.366-0.806,0.241c0.316,0.124,0.853,0.023,0.806-0.241c-0.16,0-0.319,0-0.479,0
		c-0.263-0.514,0.177-2.375,0-3.6c0-0.08,0-0.16,0-0.24c0.559-1.283,0.082-3.598,0.239-5.28c0.001,0,0.001,0,0.002,0
		c0-0.08,0-0.16,0-0.24c0,0,0-0.001,0-0.001c0.538-0.182,0.093-1.346,0.238-1.919c0.021-0.339,0.039-0.677-0.238-0.719
		c0,0,0,0,0-0.001c0-0.08,0-0.16,0-0.24c-0.002,0-0.003-0.001-0.005-0.002c-0.059-0.34-0.396-0.403-0.479-0.718
		c-2.242-0.404-1.961,1.719-1.92,3.6c0.001,0,0.001,0,0.002,0c0,0.16,0,0.32,0,0.48c-0.557,1.281-0.084,3.59-0.238,5.271
		c-0.004,0.004-0.006,0.01-0.01,0.014c-0.148,0.725,0.302,2.051-0.232,2.393c0-0.639,0-1.278,0-1.918
		c-0.557,0.805-0.084,2.636-0.239,3.84c0.065-0.021,0.114-0.059,0.153-0.106c-0.039,0.051-0.092,0.09-0.16,0.112
		c0,0.24,0,0.48,0,0.72c0.003,0.003,0.003,0.007,0.006,0.01c-0.059,2.017-0.721,3.429-0.72,5.504c0,0.08,0,0.16,0,0.24
		c0,0.002,0,0.004,0,0.006c-0.965,2.791-1.777,5.735-2.877,8.392c-0.001,0-0.002,0.002-0.003,0.002c0,0.08,0,0.16,0,0.24
		c0,0.001-0.001,0.002-0.001,0.004c-1.015,2.759-2.655,4.942-4.079,7.437c-1.42,2.489-2.203,5.339-3.602,7.68
		c-1.953,3.274-5.197,7.078-8.16,9.84c-5.624,5.247-11.627,9.127-18.174,13.161c-0.021,0.012-0.042,0.024-0.063,0.037
		c-0.08,0-0.16,0-0.24,0c-4.139,2.661-9.985,3.614-16.08,4.32c0.16,0,0.32,0,0.479,0c8.337-0.037,14.263-2.941,20.4-6.72
		c4.779-2.942,9.445-6.24,13.122-9.593c-3.677,3.356-8.347,6.655-13.13,9.6c-6.139,3.778-12.063,6.682-20.396,6.72
		c0.001-0.002,0.003-0.003,0.004-0.005c-0.16,0-0.32,0-0.48,0c-2,0-4,0-6,0c-8.977-1.477-20.41-1.726-29.04,0.24
		c-1.44,0-2.88,0-4.32,0c-1.2,0-2.4,0-3.6,0c0,0,0,0,0,0.001c-1.137,0.176-1.649-0.27-2.639-0.24c0.586,0.454,1.564,0.516,2.64,0.48
		c0,0,0,0,0-0.001c0.239,0,0.479,0,0.719,0c9.327,0.738,17.065-2.102,26.16-1.44c3.375,0.246,6.578,1.199,10.08,1.2
		c0.24,0,0.48,0,0.721,0c1.199,0,2.399,0,3.6,0c0.017-0.003,0.04,0,0.057-0.003c-0.021,0.004-0.045,0.004-0.064,0.008
		c-0.647,0.398-2.948,0.398-3.6,0c-0.24,0-0.479,0-0.721,0c-3.502-0.001-6.704-0.955-10.08-1.2
		c-9.096-0.662-16.833,2.177-26.16,1.44c-0.24,0-0.48,0-0.72,0c-1.076,0.036-2.054-0.026-2.64-0.48
		c-0.744,0.024-1.332-0.108-1.68-0.48C251.376,342.998,249.897,342.32,248.88,341.681z M351.812,236.648
		c-0.112-0.783-0.513-1.277-0.451-2.234c0.001,0.002,0.001,0.003,0.002,0.005C351.302,235.373,351.7,235.867,351.812,236.648z
		 M350.91,212.266c-0.013,0.015-0.015,0.042-0.029,0.055c0,0,0,0,0-0.001C350.896,212.306,350.897,212.281,350.91,212.266z
		 M350.563,221.756c-0.04,0.15-0.094,0.287-0.162,0.408c0-0.001,0-0.002,0-0.003C350.47,222.04,350.522,221.904,350.563,221.756z
		 M300.336,343.522c-0.328,0.224-1.032,0.069-1.599,0.054C299.301,343.589,300.002,343.74,300.336,343.522z M246.24,405.281
		c5.58,1.778,10.048,4.672,14.64,7.438c0.852,0.27,1.207,1.033,2.64,0.722c0.206-0.154,0.27-0.45,0.277-0.803
		c-0.007,0.353-0.071,0.65-0.277,0.805c-1.433,0.311-1.788-0.454-2.64-0.723c0,0.08,0,0.16,0,0.24c0.5,0.78,1.754,0.806,3.12,0.72
		c0-0.08,0-0.158,0-0.238c0,0,0-0.001,0-0.002c0.397-0.261,0.397-2.143,0-2.4c0,0,0,0.001,0,0.002
		c-0.014-0.146-0.036-0.284-0.24-0.24c-0.019,0.101-0.028,0.212-0.031,0.329c0.003-0.118,0.012-0.229,0.031-0.331
		c-3.184-8.256-5.046-17.834-6.72-27.6c-0.122-0.213-0.21-0.425-0.269-0.632c-0.012-0.044-0.008-0.085-0.018-0.129
		c-0.035-0.161-0.066-0.322-0.064-0.474c0-0.015,0.006-0.027,0.007-0.042c0.013-0.368,0.129-0.699,0.344-0.958
		c0.061-0.074,0.146-0.127,0.222-0.188c0,0,0.001,0,0.001-0.001c0.11-0.089,0.234-0.162,0.374-0.221
		c0.027-0.012,0.057-0.021,0.085-0.032c0.123-0.044,0.25-0.082,0.393-0.102c0.085-0.011,0.178-0.011,0.27-0.013
		c0.108-0.002,0.211-0.012,0.329,0c0.227,0.024,0.467,0.068,0.725,0.151c7.967-2.202,15.761-3.724,22.8-6.24
		c7.113-2.542,12.306-7.471,18.24-10.8c0.398-0.481,0.646-1.116,1.439-1.2c0.311-0.411,0.497-0.943,1.2-0.96
		c1.64-1.641,3.374-3.186,5.28-4.56c0.938-1.14,1.979-2.18,3.118-3.12c0-0.08,0-0.16,0-0.24c1.262-1.46,2.62-2.82,4.08-4.08
		c0.08,0,0.16,0,0.24,0c1.659-1.86,3.42-3.62,5.279-5.28c0.08,0,0.16,0,0.238,0c1.021-1.22,2.142-2.34,3.36-3.36
		c1.27-1.853,2.652-3.587,4.319-5.04c0.017-0.704,0.549-0.892,0.96-1.2c0.085-0.795,0.72-1.041,1.2-1.44
		c-0.09-0.408,0.188-0.453,0.479-0.48c0.276-0.274,0.563-0.56,0.843-0.841c0.038-0.038,0.076-0.075,0.113-0.113
		c0.205-0.205,0.407-0.405,0.613-0.613c0.012-0.012,0.021-0.023,0.035-0.035c0,0,0,0,0.001,0c0.446-0.451,0.898-0.908,1.354-1.37
		c0.169-0.171,0.338-0.345,0.507-0.518c0.314-0.32,0.628-0.64,0.942-0.963c0.197-0.203,0.393-0.406,0.59-0.609
		c0.288-0.296,0.574-0.593,0.86-0.889c0.196-0.207,0.395-0.411,0.592-0.617c0.282-0.294,0.563-0.588,0.842-0.881
		c0.191-0.201,0.381-0.401,0.57-0.601c0.288-0.304,0.571-0.604,0.854-0.904c0.168-0.179,0.337-0.358,0.503-0.536
		c0.313-0.334,0.617-0.662,0.919-0.987c0.131-0.14,0.264-0.281,0.391-0.419c0.424-0.459,0.837-0.91,1.23-1.345
		c0,0.003,0.001,0.007,0.001,0.01c-0.395,0.433-0.806,0.881-1.225,1.337c-0.127,0.138-0.26,0.279-0.389,0.419
		c-0.304,0.326-0.606,0.653-0.919,0.987c-0.166,0.177-0.335,0.356-0.503,0.536c-0.282,0.3-0.565,0.6-0.854,0.904
		c-0.189,0.2-0.379,0.4-0.57,0.601c-0.277,0.293-0.56,0.586-0.842,0.881c-0.197,0.206-0.394,0.411-0.592,0.617
		c-0.286,0.296-0.572,0.592-0.86,0.889c-0.195,0.203-0.393,0.406-0.59,0.609c-0.313,0.323-0.628,0.643-0.94,0.963
		c-0.171,0.173-0.34,0.346-0.509,0.518c-0.452,0.461-0.904,0.918-1.354,1.37c0.094-0.036,0.196-0.064,0.313-0.077
		c0.002-0.001,0.003-0.003,0.005-0.004c0.311-0.087,0.374-0.42,0.717-0.478c3.402-2.676,5.919-6.241,8.88-9.36
		c0,4.96,0,9.92,0,14.88c0,1.28,0,2.56,0,3.84c0,0,0,0,0,0.001c-0.398,0.966-0.398,3.593,0,4.56c0,0.24,0,0.479,0,0.719
		c0.077,0,0.154,0,0.231,0c0,0,0,0,0,0.001c-0.077,0-0.154,0-0.231,0c0.153,0.965-0.312,2.552,0.24,3.12c0,0.16,0,0.32,0,0.48
		c0.021,1.977,0.104,3.895,0.479,5.52c0-0.719,0-1.437,0-2.156c0.001,0,0.001,0.001,0.002,0.002c0,0.718,0,1.435,0,2.153
		c-0.044,0.2,0.088,0.225,0.23,0.238c-0.037,2.038,0.193,3.806,0.721,5.279c0.003,0.005,0.007,0.011,0.01,0.017
		c0,0.235,0,0.471,0,0.706c0.877,1.478,1.854,2.855,2.898,4.165c0.073,0.084,0.146,0.17,0.222,0.253
		c0.187,0.205,0.371,0.406,0.563,0.604c0.114,0.123,0.232,0.244,0.354,0.364c0.2,0.203,0.401,0.404,0.607,0.603
		c0.111,0.11,0.229,0.219,0.343,0.328c0.22,0.208,0.438,0.415,0.666,0.621c0.099,0.09,0.198,0.18,0.298,0.27
		c0.488,0.44,0.991,0.883,1.503,1.332c0.01,0.009,0.02,0.019,0.028,0.029c-0.522-0.459-1.035-0.91-1.533-1.36
		c-0.099-0.09-0.199-0.18-0.298-0.27c-0.228-0.207-0.446-0.414-0.666-0.621c-0.114-0.109-0.229-0.218-0.343-0.328
		c-0.206-0.199-0.407-0.4-0.607-0.603c-0.119-0.12-0.237-0.242-0.354-0.364c-0.19-0.198-0.379-0.4-0.563-0.604
		c-0.056-0.062-0.114-0.121-0.17-0.183c0.807,1.006,1.656,1.969,2.539,2.9c0.048,0.051,0.098,0.1,0.146,0.15
		c0.382,0.397,0.767,0.79,1.155,1.176c0.106,0.105,0.214,0.208,0.321,0.312c0.337,0.329,0.676,0.655,1.021,0.977
		c0.137,0.127,0.271,0.252,0.408,0.378c0.32,0.295,0.645,0.589,0.968,0.88c0.153,0.138,0.309,0.275,0.462,0.412
		c0.313,0.276,0.625,0.55,0.94,0.823c0.165,0.143,0.33,0.287,0.496,0.429c0.313,0.268,0.626,0.535,0.938,0.801
		c0.172,0.144,0.342,0.288,0.512,0.431c0.315,0.267,0.637,0.534,0.955,0.8c0.111,0.095,0.227,0.19,0.34,0.285
		c-0.001-0.001-0.002-0.002-0.003-0.003c-0.268-0.262-0.519-0.52-0.764-0.775c-0.35-0.363-0.679-0.715-0.99-1.054
		c-0.025-0.027-0.051-0.051-0.073-0.078c-0.231-0.25-0.459-0.49-0.682-0.719c-0.055-0.056-0.108-0.109-0.163-0.164
		c0.007,0.006,0.014,0.012,0.021,0.018c0,0,0,0,0.001,0.001c0,0,0.001,0,0.001,0.001c0.009,0.008,0.019,0.016,0.028,0.023
		c0.037,0.039,0.075,0.081,0.112,0.119c0.223,0.229,0.448,0.47,0.682,0.719c0.021,0.025,0.049,0.053,0.073,0.078
		c0.313,0.338,0.643,0.69,0.99,1.054c0.246,0.256,0.499,0.515,0.771,0.778c0.002,0.001,0.003,0.002,0.004,0.003
		c0.734,0.612,1.469,1.225,2.201,1.839c0.001,0,0.001,0,0.001,0c0.006,0.004,0.011,0.007,0.017,0.011
		c0.535,0.372,1.089,0.729,1.61,1.101c0.007,0.005,0.014,0.01,0.021,0.015c0.125,0.09,0.248,0.181,0.369,0.273
		c0.027,0.021,0.055,0.042,0.082,0.063c0.104,0.08,0.203,0.161,0.301,0.243c0.032,0.027,0.063,0.053,0.097,0.08
		c0.097,0.083,0.188,0.169,0.278,0.256c0.025,0.024,0.053,0.047,0.076,0.072c0.227,0.223,0.428,0.458,0.602,0.709
		c0.021,0.03,0.036,0.062,0.058,0.092c0.063,0.094,0.119,0.19,0.171,0.29c0.024,0.045,0.045,0.092,0.066,0.139
		c0.042,0.089,0.081,0.18,0.113,0.273c0.021,0.053,0.037,0.106,0.056,0.16c0.027,0.094,0.053,0.19,0.071,0.288
		c0.014,0.056,0.024,0.112,0.034,0.17c0.019,0.107,0.025,0.218,0.032,0.331c0.003,0.053,0.01,0.104,0.011,0.157
		c0.002,0.169-0.004,0.343-0.023,0.524c0.539,2.144,0.503,4.377,0,6.48c-1.706,0.947-2.275,2.95-3.12,4.56
		c-3.559,6.786-9.335,12.132-14.159,17.76c-5.68,6.625-9.64,14.568-16.08,20.64c-0.591,0.69-1.206,1.355-1.847,1.996
		c0.64-0.64,1.254-1.304,1.844-1.993c-1.38,1.179-2.658,2.459-3.839,3.838c-3.846,3.193-8.491,5.587-13.919,7.199
		c-0.355-0.844-0.611-1.789-1.439-2.16c-2.925,1.257-6.213-1.022-10.561-0.479c-0.002,0-0.003,0-0.005,0
		c-0.416-0.396-2.461-0.396-2.877,0c-6.104,0.296-11.681,1.117-17.52,1.681c-0.045,0.043-0.117,0.08-0.197,0.114
		c0.079-0.034,0.15-0.07,0.195-0.113c-0.959,0-1.917,0-2.876,0c0,0-0.001-0.001-0.002-0.001c-0.728-0.204-1.457-0.373-2.184-0.525
		c-0.253-0.054-0.503-0.1-0.756-0.146c-0.483-0.094-0.965-0.184-1.445-0.265c-0.338-0.059-0.673-0.112-1.009-0.165
		c-0.414-0.065-0.827-0.132-1.235-0.194c-0.867-0.133-1.727-0.269-2.566-0.417c-0.013-0.003-0.026-0.005-0.04-0.008
		c-0.076-0.016-0.15-0.028-0.225-0.042c0,0-0.001,0-0.001,0c-0.001,0-0.002-0.001-0.003-0.001c-0.003-0.001-0.007-0.001-0.011-0.002
		c0.001,0,0.003,0.001,0.004,0.001c-0.163-0.03-0.322-0.062-0.482-0.089c-0.147-0.026-0.297-0.057-0.442-0.081
		c-0.083-0.018-0.165-0.031-0.247-0.049c-0.118-0.022-0.232-0.046-0.349-0.068c0.12,0.023,0.236,0.047,0.358,0.069
		c0.083,0.018,0.164,0.031,0.247,0.049c0.145,0.024,0.295,0.055,0.442,0.081c0.156,0.026,0.31,0.058,0.468,0.086
		c-0.015-0.003-0.03-0.008-0.045-0.011c-0.454-0.084-0.902-0.175-1.345-0.271c-0.18-0.04-0.356-0.085-0.534-0.128
		c-0.356-0.086-0.709-0.182-1.058-0.28c-0.163-0.047-0.326-0.094-0.486-0.145c-0.495-0.155-0.983-0.323-1.457-0.521
		c-4.097-1.704-6.824-5.461-10.32-7.438c-0.003-0.002-0.005-0.005-0.008-0.008c-0.776-0.976-1.65-1.85-2.624-2.624
		c-0.002-0.004-0.006-0.007-0.008-0.01c-1.011-1.469-2.229-2.729-2.64-4.8c-0.151-0.808,0.308-2.228-0.241-2.639
		c0.021,0.11,0.029,0.235,0.031,0.367c0.001,0.063-0.001,0.128-0.003,0.19c-0.002,0.068-0.003,0.137-0.008,0.207
		c-0.02,0.328-0.053,0.67-0.054,0.979c0,0.003,0,0.005,0,0.007c0.061,0.266,0.125,0.526,0.192,0.787c0,0,0,0,0,0.001
		c0.043,0.163,0.091,0.324,0.138,0.487c0.036,0.125,0.07,0.251,0.107,0.374c0.045,0.146,0.094,0.29,0.142,0.436
		c0.046,0.137,0.091,0.271,0.14,0.406c0.049,0.138,0.102,0.271,0.154,0.401c0.055,0.141,0.112,0.28,0.172,0.419
		c0.054,0.125,0.11,0.249,0.168,0.373c0.067,0.146,0.137,0.285,0.209,0.426c0.06,0.116,0.12,0.231,0.183,0.349
		c0.082,0.146,0.169,0.292,0.257,0.438c0.063,0.104,0.125,0.209,0.193,0.313c0.105,0.16,0.219,0.317,0.334,0.479
		c0.061,0.083,0.117,0.168,0.18,0.251c0.184,0.239,0.378,0.479,0.586,0.71c2.096,2.342,7.44,6.449,11.28,8.642
		c0.281,0.158,0.572,0.3,0.868,0.43c0.087,0.039,0.177,0.075,0.265,0.111c0.226,0.093,0.456,0.179,0.69,0.258
		c0.083,0.027,0.165,0.058,0.25,0.084c0.31,0.099,0.624,0.188,0.946,0.271c0.054,0.016,0.109,0.025,0.163,0.038
		c0.284,0.07,0.572,0.136,0.866,0.197c0.046,0.009,0.093,0.02,0.139,0.029c-0.049-0.011-0.099-0.021-0.147-0.03
		c-0.294-0.063-0.582-0.127-0.866-0.197c-0.054-0.013-0.109-0.022-0.163-0.038c-0.322-0.082-0.637-0.173-0.946-0.271
		c-0.084-0.026-0.167-0.059-0.251-0.085c-0.234-0.079-0.464-0.165-0.69-0.258c-0.088-0.036-0.178-0.072-0.265-0.111
		c-0.295-0.13-0.586-0.271-0.868-0.43c-3.84-2.19-9.185-6.3-11.28-8.642c-0.208-0.231-0.402-0.471-0.586-0.71
		c-0.063-0.083-0.12-0.168-0.18-0.251c-0.115-0.159-0.229-0.315-0.334-0.479c-0.067-0.104-0.129-0.208-0.193-0.313
		c-0.088-0.146-0.176-0.29-0.257-0.438c-0.063-0.115-0.124-0.23-0.183-0.349c-0.072-0.141-0.142-0.28-0.209-0.426
		c-0.058-0.123-0.114-0.248-0.168-0.373c-0.06-0.139-0.117-0.277-0.172-0.419c-0.052-0.133-0.105-0.266-0.154-0.399
		c-0.049-0.137-0.094-0.271-0.14-0.408c-0.048-0.146-0.097-0.287-0.142-0.436c-0.038-0.123-0.072-0.249-0.107-0.374
		c-0.046-0.163-0.095-0.323-0.138-0.489c-0.067-0.261-0.131-0.521-0.192-0.787c0-0.001,0-0.001,0-0.001c0-0.001,0-0.003-0.001-0.004
		c-0.303-1.33-0.536-2.759-0.79-4.301c-0.109-0.661-0.224-1.347-0.35-2.054c-0.007-0.042-0.014-0.082-0.021-0.125
		c-0.142-0.793-0.299-1.615-0.483-2.468c-0.352-1.634-1.1-4.938-1.2-7.44C245.733,406.042,244.963,404.645,246.24,405.281z
		 M365.521,392.082c-3.426,7.467-9.354,13.585-14.881,19.919c-4.525,5.188-8.34,10.998-12.555,16.345
		c4.215-5.347,8.028-11.155,12.554-16.344c5.525-6.333,11.456-12.452,14.88-19.92C365.521,392.082,365.521,392.082,365.521,392.082z
		 M314.002,443.147c-0.696-0.284-0.362-1.598-1.123-1.819C313.647,441.54,313.313,442.854,314.002,443.147z M312.721,441.281
		c-0.004,0-0.006-0.003-0.01-0.003c-0.1-0.096-0.198-0.152-0.297-0.179C312.516,441.125,312.618,441.182,312.721,441.281z
		 M310.615,441.698c-0.1-0.026-0.199-0.082-0.299-0.178c0.002,0,0.004,0.001,0.006,0.001
		C310.42,441.616,310.518,441.671,310.615,441.698z M290.881,444.16L290.881,444.16c-0.241,0.001-0.48,0.001-0.721,0.001
		c-0.809-0.011-1.62-0.022-2.364,0.034c0.745-0.057,1.558-0.045,2.369-0.035C290.402,444.16,290.641,444.16,290.881,444.16z
		 M276.396,441.935c-1.178-0.302-2.414-0.671-3.657-0.989C273.982,441.263,275.218,441.632,276.396,441.935z M224.16,397.121
		c0.194,0.045,0.239,0.241,0.48,0.24c1.355,21.045,5.304,39.496,17.28,49.92c0.534,0.347,1.157,0.604,2.16,0.479
		c11.716,12.397,32.896,17.688,56.16,16.32c1.811,1.591,2.162,6.077,0.96,8.64c-5.332,1.229-13.93-0.811-14.88,4.802
		c-12.116,1.497-23.066,5.727-36,4.8c-3.731-0.269-7.541-1.6-11.52-1.92c-13.31-1.076-25.89,3.272-36.96,6.479
		c-1.779,0.517-3.663,1.125-5.52,1.68c-3.144,0.939-5.662,2.361-8.16,0.962c-4.236,3.765-6.934,9.064-9.6,14.398
		c1.088-8.404,5.418-15.971,7.2-24.96c1.805-9.104,2.546-18.763,4.08-28.561c2.833-18.098,9.597-35.33,15.12-50.16
		C212.242,401.002,218.693,398.963,224.16,397.121z M170.64,511.363c0-0.002,0-0.003,0-0.005c0.858,0.13,2.06-1.455,3.117-1.923
		c1.146-4.047,2.626-7.959,3.84-12c1.224-4.074,3.118-7.81,4.32-11.76c1.303-4.282,1.597-9.05,2.4-13.68
		c0.798-4.603,1.417-9.442,1.68-14.636c0.001,0,0.001,0,0.002,0c-0.262,5.192-0.882,10.036-1.68,14.641
		c-0.803,4.63-1.097,9.397-2.4,13.68c-1.202,3.95-3.096,7.686-4.32,11.762c-1.213,4.041-2.693,7.951-3.84,12
		C172.7,509.908,171.499,511.496,170.64,511.363z M171.858,523.948c0.007-0.103,0.002-0.217-0.019-0.348
		c-0.167,0.017-0.315,0.128-0.44,0.29c0.125-0.162,0.274-0.274,0.44-0.291C171.861,523.73,171.866,523.846,171.858,523.948z
		 M168.48,509.202c-0.303-0.092-0.568-0.219-0.819-0.362c-0.05-0.029-0.103-0.056-0.151-0.087c-0.221-0.141-0.42-0.304-0.602-0.483
		c-0.059-0.058-0.114-0.118-0.169-0.183c-0.138-0.153-0.263-0.318-0.379-0.494c-0.081-0.121-0.154-0.251-0.225-0.383
		c-0.067-0.124-0.131-0.252-0.191-0.385c0-0.001-0.001-0.003-0.001-0.004c0.06,0.133,0.124,0.26,0.19,0.384
		c0.071,0.132,0.145,0.261,0.225,0.383c0.116,0.176,0.241,0.341,0.379,0.494c0.055,0.063,0.11,0.124,0.169,0.183
		c0.182,0.182,0.381,0.345,0.602,0.483c0.048,0.031,0.101,0.058,0.151,0.087c0.25,0.145,0.516,0.271,0.819,0.362
		c0.495-5.684-0.062-10.994,0.48-16.08c0.34-3.192,1.82-6.265,2.88-9.12c1.086-2.927,1.823-6.568,4.32-7.92c0,0.002,0,0.004,0,0.007
		c-2.496,1.353-3.232,4.992-4.318,7.918c-1.061,2.856-2.54,5.928-2.88,9.12C168.418,498.208,168.975,503.519,168.48,509.202z
		 M204.479,396.882C204.479,396.882,204.479,396.882,204.479,396.882c-0.537,1.382-2.473,1.366-3.119,2.642
		c-0.027,0.126-0.043,0.243-0.05,0.35c0.007-0.107,0.023-0.224,0.05-0.352C202.005,398.249,203.941,398.263,204.479,396.882z
		 M140.162,450.401c0.5,0.139,0.684,0.595,1.438,0.478c-0.153-0.487-1.122-0.158-1.2-0.72c0.103-0.458,0.686-0.437,0.48-1.2
		c0-0.079,0-0.159,0-0.238c0.717-3.203,2.1-5.74,3.12-8.64c0-0.08,0-0.16,0-0.24c5.372-11.503,11.58-22.171,20.104-30.521
		c-8.524,8.351-14.731,19.021-20.104,30.521c6.125-11.954,12.573-23.588,22.08-32.16c2.554-4.564,7.448-6.792,13.2-8.158
		c2.333-0.629,4.711-1.21,7.44-1.44c0.001,0,0.002-0.001,0.003-0.001c1.112-0.085,2.087-0.307,2.874-0.718c0.001,0,0.002,0,0.003,0
		c0.284-0.276,0.849-0.271,1.44-0.24c0.001,0,0.002,0,0.002-0.001c0.982-0.057,1.958-0.119,2.635-0.479c0.001,0,0.001,0,0.002,0
		c0.08,0,0.16,0,0.24,0c0-0.001,0.002-0.002,0.002-0.003c1.204-0.153,3.034,0.316,3.838-0.238c1.036,0,2.073,0,3.109,0
		c0.003,0.001,0.008,0.002,0.011,0.003c0.08,0,0.16,0,0.24,0c0.001,0,0.002,0,0.003,0c0.661,0.539,2.218,0.178,2.876,0.718
		c-0.721,1.119-2.526,1.154-2.88,2.64c-0.314,1.194,0.739,1.021,0.72,1.92c-2.292,5.948-4.907,11.573-6.48,18.24
		c1.963-6.516,4.551-12.408,6.72-18.718c0,0,0,0,0,0c-2.168,6.311-4.757,12.203-6.72,18.72c0,0.079,0,0.159,0,0.237
		c0,0.001,0,0.001,0,0.002c-0.192,1.169-0.42,2.3-0.96,3.12c0,0.08,0,0.16,0,0.239c-1.866,4.935-3.184,10.416-4.56,15.84
		c0.424-1.276,0.823-2.577,1.208-3.893c-0.385,1.315-0.785,2.617-1.208,3.894c0,0.08,0,0.159,0,0.238
		c-0.743,1.497-1.016,3.464-1.44,5.28c0.076,0.559-0.039,0.921-0.24,1.2c-0.563,1.197-0.731,2.789-0.96,4.319
		c0,0.079,0,0.158,0,0.238c-0.419,1.023-0.64,2.241-0.72,3.602c0,0.239,0,0.479,0,0.721c-2.117,0.203-3.959,0.68-6.24,0.72
		c-0.808,0.152-2.229-0.31-2.64,0.24c0.707-0.134,1.883,0.204,2.442-0.078c-0.559,0.283-1.735-0.055-2.442,0.078
		c-0.56,0-1.12,0-1.68,0c-0.333-0.548-1.67-0.09-2.4-0.24c-0.333-0.547-1.67-0.09-2.4-0.238c-0.8,0-1.6,0-2.4,0
		c0.333,0.546,1.671,0.088,2.4,0.238c-0.729-0.148-2.067,0.31-2.4-0.238c-0.815-0.064-1.946,0.187-2.4-0.239
		c-2.573-0.147-4.878-0.563-6.96-1.2c-0.08,0-0.16,0-0.24,0c-1.106-0.015-2.129-0.111-2.88-0.479
		c-0.691-0.028-1.571,0.131-1.92-0.24c-1.533-0.067-2.947-0.253-4.08-0.721c-0.08,0-0.16,0-0.24,0
		c-0.943-0.018-1.804-0.115-2.4-0.479c-0.08,0-0.16,0-0.24,0c-0.943-0.017-1.804-0.116-2.4-0.48c-0.08,0-0.16,0-0.24,0
		c-1.068-0.132-2.21-0.188-2.88-0.72c-0.08,0-0.16,0-0.24,0c-0.755,0.115-0.938-0.342-1.44-0.479
		C140.161,450.403,140.161,450.402,140.162,450.401z M138,448.961c-0.577-0.303-0.483-1.276-0.96-1.681
		C137.517,447.683,137.423,448.658,138,448.961z M153.84,455.201c5.003,0.873,10.468,1.895,15.996,2.452
		C164.308,457.096,158.843,456.075,153.84,455.201c-6.506-1.137-13.062-2.072-17.76-4.801
		C140.779,453.129,147.334,454.064,153.84,455.201z M134.16,466.239c2.34-3.059,3.868-10.281,1.92-14.643
		c-0.067-0.388,0.227-0.413,0.24-0.72c0.279-0.039,0.427,0.053,0.48,0.24c4.871,2.56,11.215,3.259,17.52,4.317
		c9.577,1.612,20.427,4.234,31.44,2.16c-0.438,5.07-0.894,9.9-1.68,14.4c-0.794,4.544-1.168,9.187-2.4,13.438
		c-1.14,3.938-3.098,7.692-4.32,11.763c-1.221,4.063-2.692,7.942-3.84,12c-0.714,0.569-2.201,1.63-2.64,1.68
		c0.497-5.741,0.122-11.085,0.48-16.08c0.519-7.248,5.535-12.641,4.8-18.96c-1.967,0.01-2.274,2.388-2.88,3.84
		c-2.021,4.843-4.467,9.833-4.8,16.32c-0.209,4.079,0.05,8.034-0.24,12.24c-0.029,0.06-0.082,0.081-0.146,0.085
		c-0.01,0-0.018,0.003-0.028,0.003c-0.065-0.004-0.146-0.031-0.235-0.077c-0.017-0.009-0.033-0.02-0.05-0.027
		c-0.095-0.053-0.197-0.121-0.309-0.207c-0.012-0.01-0.024-0.021-0.036-0.027c-0.118-0.096-0.241-0.201-0.368-0.321
		c-0.002-0.002-0.004-0.004-0.006-0.005c-0.129-0.123-0.261-0.26-0.392-0.399c-0.014-0.015-0.027-0.027-0.041-0.044
		c-0.128-0.14-0.254-0.284-0.375-0.432c-0.011-0.016-0.022-0.027-0.033-0.041c-0.252-0.313-0.477-0.624-0.638-0.902
		c0-0.001-0.001-0.001-0.001-0.002c-0.329-1.123-0.494-2.409-0.616-3.739c-0.001-0.009-0.001-0.018-0.002-0.026
		c0-0.033,0-0.066-0.001-0.099c-0.004-0.19-0.008-0.385-0.011-0.578c-0.012-0.904-0.01-1.826-0.046-2.712
		c-0.002-0.054-0.005-0.104-0.008-0.158c-0.01-0.209-0.023-0.414-0.039-0.617c-0.005-0.071-0.011-0.144-0.017-0.212
		c-0.021-0.229-0.046-0.455-0.077-0.677c-0.003-0.021-0.005-0.045-0.008-0.064c-0.035-0.235-0.079-0.461-0.128-0.683
		c-0.014-0.062-0.03-0.117-0.045-0.179c-0.041-0.161-0.087-0.316-0.139-0.47c-0.021-0.063-0.041-0.122-0.064-0.182
		c-0.069-0.185-0.146-0.357-0.235-0.521c-0.007-0.013-0.012-0.027-0.02-0.041c-0.096-0.172-0.207-0.328-0.327-0.476
		c-0.036-0.043-0.074-0.082-0.112-0.123c-0.096-0.104-0.2-0.2-0.311-0.289c-0.045-0.036-0.088-0.072-0.136-0.105
		c-0.159-0.108-0.328-0.211-0.519-0.29c-2.725,1.712-0.54,5.834-0.48,8.88c0.112,5.721,1.035,13.637,3.84,17.763
		c0.649,0.954,1.828,1.795,1.68,3.12c-0.839,1.283-1.824-0.838-2.16-1.198c-0.581-0.625-1.166-1.276-1.752-1.947
		c-0.304-0.347-0.607-0.714-0.911-1.071c-0.275-0.323-0.55-0.642-0.825-0.974c-0.422-0.512-0.84-1.039-1.259-1.567
		c-0.148-0.187-0.297-0.365-0.445-0.557c-0.494-0.631-0.98-1.273-1.464-1.924c-0.065-0.087-0.132-0.173-0.197-0.261
		c-0.533-0.722-1.055-1.448-1.57-2.186c-0.012-0.017-0.023-0.032-0.035-0.049c-1.598-2.284-3.085-4.617-4.396-6.924
		c-0.001-0.002-0.003-0.005-0.004-0.008c0,0.001,0.001,0.002,0.001,0.003c0.181,0.326,0.37,0.646,0.553,0.971
		c0.349,0.615,0.701,1.229,1.06,1.835c0.232,0.393,0.468,0.78,0.705,1.168c0.342,0.563,0.689,1.117,1.04,1.669
		c0.225,0.354,0.447,0.707,0.675,1.059c0.557,0.854,1.121,1.697,1.697,2.525c0.118,0.17,0.24,0.333,0.359,0.502
		c0.495,0.703,0.996,1.397,1.503,2.08c0.209,0.28,0.42,0.559,0.631,0.834c0.449,0.591,0.902,1.172,1.359,1.745
		c0.198,0.248,0.396,0.498,0.595,0.743c0.647,0.793,1.299,1.575,1.96,2.331c0.652,0.745,1.732,2.086,2.88,1.92
		c0.467-0.788,0.413-1.424,0.114-2c0.301,0.577,0.356,1.214-0.111,2.005c-1.148,0.166-2.228-1.175-2.88-1.92
		c-0.661-0.756-1.313-1.538-1.96-2.331c-0.199-0.245-0.396-0.495-0.595-0.743c-0.457-0.573-0.91-1.154-1.359-1.745
		c-0.211-0.275-0.422-0.554-0.631-0.834c-0.508-0.683-1.008-1.377-1.503-2.08c-0.119-0.169-0.241-0.332-0.359-0.502
		c-0.576-0.828-1.14-1.674-1.697-2.525c-0.228-0.352-0.451-0.704-0.675-1.059c-0.351-0.552-0.697-1.105-1.04-1.669
		c-0.237-0.388-0.473-0.773-0.705-1.168c-0.359-0.604-0.711-1.22-1.06-1.835c-0.184-0.324-0.372-0.645-0.553-0.971
		c0-0.001,0-0.002-0.001-0.003s-0.001-0.002-0.002-0.003c-1.087-1.968-2.116-3.976-3.081-6.013
		c-0.004-0.009-0.009-0.018-0.013-0.026c-3.055-6.575-5.587-13.622-8.841-19.892c-0.076-0.145-0.154-0.285-0.23-0.431
		c-0.276-0.523-0.557-1.042-0.844-1.553c-0.121-0.215-0.245-0.427-0.368-0.641c-0.253-0.438-0.511-0.868-0.774-1.297
		c-0.143-0.229-0.288-0.462-0.434-0.688c-0.26-0.406-0.526-0.806-0.796-1.198c-0.153-0.224-0.305-0.447-0.461-0.667
		c-0.292-0.409-0.595-0.808-0.9-1.202c-0.143-0.186-0.282-0.375-0.428-0.557c-0.456-0.567-0.924-1.121-1.412-1.654
		C134.483,468.154,134.483,466.963,134.16,466.239z M162.482,502.244c0.114,4.49,0.55,10.847,2.291,15.271
		c-1.742-4.425-2.179-10.784-2.292-15.276c-0.07-2.777-1.878-6.356,0.24-8.4c0.232,0.207,0.437,0.441,0.618,0.699
		c0.054,0.076,0.092,0.169,0.142,0.25c0.117,0.188,0.237,0.38,0.334,0.592c0,0.001,0.001,0.003,0.001,0.004
		c-0.097-0.211-0.216-0.398-0.333-0.591c-0.05-0.081-0.088-0.174-0.142-0.25c-0.181-0.258-0.385-0.492-0.618-0.699
		C160.603,495.888,162.411,499.467,162.482,502.244z M135.164,470.662c-0.404-0.524-0.817-1.037-1.242-1.538c0,0,0,0,0-0.001
		C134.347,469.625,134.76,470.138,135.164,470.662z M133.683,466.244c0.103,0.089,0.183,0.182,0.244,0.279
		C133.865,466.427,133.785,466.333,133.683,466.244C133.682,466.244,133.682,466.244,133.683,466.244z M113.517,633.511
		c0.083-0.654,0.597-1.435,0.72-2.16c0.125-0.737,0.172-1.513,0.17-2.31c0.002,0.803-0.044,1.585-0.17,2.328
		c-0.124,0.726-0.637,1.505-0.72,2.16c-0.083,0.658-0.112,1.33-0.112,2.007C113.404,634.853,113.433,634.175,113.517,633.511z
		 M98.637,723.511c0.232-4.437,3.451-7.586,5.04-11.521c2.631-6.514,3.736-14.538,4.32-22.561c0.208-2.849-0.303-5.918,0-8.88
		c0.385-3.755,1.857-7.354,2.16-11.28c0.386-5.012-0.237-10.163,0.48-15.119c0.614-4.249,2.558-8.296,2.88-12.479
		c0.026-0.333,0.04-0.669,0.045-1.006c-0.005,0.343-0.02,0.684-0.045,1.022c-0.322,4.188-2.266,8.231-2.88,12.48
		c-0.714,4.95-0.091,10.103-0.477,15.114c-0.303,3.928-1.775,7.523-2.16,11.278c-0.303,2.964,0.208,6.031,0,8.882
		c-0.584,8.021-1.689,16.047-4.32,22.56c-1.588,3.932-4.802,7.08-5.039,11.51C98.64,723.511,98.639,723.511,98.637,723.511z
		 M158.159,680.567c-0.552-0.194-1.082-0.413-1.617-0.625c-0.01-0.005-0.018-0.013-0.027-0.018
		c-0.357-0.188-0.737-0.338-1.139-0.453c-0.016-0.004-0.032-0.008-0.048-0.013c-0.003-0.001-0.005-0.001-0.008-0.002
		c-1.129-0.483-2.215-1.009-3.277-1.559c-0.292-0.151-0.586-0.301-0.874-0.455c-0.412-0.231-0.818-0.463-1.223-0.696
		c-0.286-0.164-0.572-0.329-0.854-0.498c-0.399-0.238-0.794-0.479-1.186-0.727c-0.273-0.174-0.543-0.348-0.812-0.521
		c-0.391-0.254-0.781-0.508-1.165-0.769c-0.241-0.163-0.479-0.331-0.718-0.497c-1.521-1.058-3.001-2.154-4.455-3.277
		c-0.025-0.021-0.051-0.038-0.076-0.058c-0.001-0.001-0.003-0.003-0.005-0.004c-0.019-0.019-0.037-0.033-0.056-0.052
		c-0.002-0.002-0.003-0.003-0.005-0.004c-0.124-0.108-0.243-0.216-0.364-0.323c0.042,0.009,0.083,0.02,0.126,0.027
		c6.116,1.219,11.434,4.063,16.644,6.983c2.911,1.638,5.792,3.294,8.76,4.703c0.024,0.035,0.044,0.072,0.068,0.108
		c0.096,0.146,0.179,0.3,0.25,0.461c0.009,0.021,0.018,0.042,0.027,0.063c0.058,0.141,0.105,0.287,0.143,0.438
		c0.004,0.018,0.008,0.035,0.013,0.053c-0.004-0.013-0.007-0.026-0.01-0.04c-0.038-0.152-0.084-0.302-0.143-0.439
		c-0.009-0.021-0.018-0.042-0.027-0.063c-0.072-0.158-0.154-0.313-0.25-0.461c0,0.005,0.001,0.009,0.002,0.013
		c0.059,0.533,0.208,0.979,0.247,1.529c0.002,0.029,0.008,0.058,0.01,0.086c0.01,0.209,0.003,0.436-0.032,0.688
		c-2.594-0.688-4.968-1.592-8.16-1.68C157.368,681.806,158.833,681.465,158.159,680.567z M165.781,681.73
		c-0.121-0.168-0.26-0.325-0.408-0.477c-0.029-0.028-0.059-0.056-0.088-0.084c-0.127-0.122-0.263-0.239-0.405-0.35
		c-0.029-0.022-0.057-0.047-0.087-0.068c-0.173-0.131-0.353-0.254-0.54-0.371c-0.186-0.113-0.377-0.223-0.57-0.327
		c-0.036-0.02-0.072-0.039-0.108-0.058c-0.183-0.097-0.367-0.19-0.55-0.279c-0.005-0.003-0.011-0.006-0.016-0.008
		c-0.25-0.121-0.495-0.234-0.733-0.347c-0.103-0.048-0.203-0.095-0.301-0.144c-0.071-0.031-0.142-0.065-0.21-0.1
		c-0.169-0.082-0.332-0.162-0.482-0.242c-6.981-3.719-13.428-7.636-21.593-9.355c-0.003-0.003-0.007-0.006-0.01-0.009
		c8.169,1.724,14.617,5.642,21.6,9.359c0.15,0.08,0.313,0.16,0.482,0.242c0.069,0.032,0.139,0.064,0.21,0.1
		c0.098,0.047,0.199,0.094,0.301,0.144c0.238,0.109,0.484,0.226,0.733,0.347c0.005,0.002,0.011,0.005,0.016,0.008
		c0.184,0.089,0.367,0.184,0.55,0.279c0.036,0.019,0.072,0.038,0.108,0.058c0.193,0.104,0.384,0.212,0.57,0.327
		c0.187,0.117,0.367,0.24,0.54,0.371c0.03,0.021,0.058,0.046,0.087,0.067c0.142,0.109,0.277,0.229,0.405,0.351
		c0.029,0.028,0.06,0.056,0.088,0.084c0.148,0.147,0.287,0.306,0.407,0.475c0,0,0,0,0,0.001
		C165.778,681.727,165.779,681.729,165.781,681.73z M166.557,686.792c0,0.003,0,0.006,0,0.009
		c-5.809-1.158-13.905-2.283-22.078-2.879c-3.05-0.222-8.656-0.087-12.48-0.239c-2.188-0.088-6.458-0.27-7.058-1.729
		c0.615,1.451,4.873,1.632,7.055,1.72c3.824,0.151,9.43,0.019,12.48,0.239C152.651,684.508,160.748,685.633,166.557,686.792z
		 M177.583,683.721c0.248-1.618,0.626-3.105,1.058-4.539C178.211,680.616,177.832,682.104,177.583,683.721z M171.84,670.725
		c-0.738,2.558-1.783,8.497-3.84,8.882c-1.897,0.354-5.63-2.871-7.44-3.842c-5.545-2.968-9.893-5.232-15.36-7.197
		c-2.465-0.889-6.101-2.592-8.64-0.24c0.063,0.398,0.178,0.771,0.315,1.134c-0.046-0.155-0.074-0.317-0.089-0.484v-0.001
		c0.016,0.165,0.042,0.326,0.088,0.479c0.036,0.121,0.08,0.239,0.133,0.354c0.012,0.026,0.024,0.054,0.037,0.078
		c0.001,0.002,0.002,0.003,0.003,0.006s0.002,0.005,0.003,0.007c0.056,0.111,0.12,0.224,0.19,0.331
		c0.025,0.037,0.054,0.074,0.081,0.112c0.012,0.019,0.023,0.033,0.035,0.05c0.218,0.299,0.482,0.574,0.776,0.839
		c0.012,0.011,0.023,0.021,0.035,0.029c0.505,0.446,1.084,0.854,1.64,1.247c0.082,0.061,0.163,0.116,0.244,0.175
		c0.053,0.038,0.105,0.076,0.158,0.114c0.148,0.107,0.293,0.216,0.431,0.323c3.342,2.621,6.489,5.268,10.8,7.2
		c1.83,0.817,4.509,2.358,6.476,0.963c0.001,0.001,0.002,0.002,0.003,0.004c-1.968,1.402-4.649-0.14-6.48-0.96
		c-4.311-1.935-7.458-4.579-10.8-7.2c-0.138-0.107-0.283-0.216-0.431-0.323c-0.052-0.038-0.104-0.075-0.158-0.112
		c-0.081-0.061-0.162-0.116-0.244-0.177c-0.556-0.395-1.135-0.801-1.64-1.247c-0.012-0.011-0.023-0.021-0.035-0.029
		c-0.294-0.265-0.559-0.54-0.776-0.837c-0.012-0.019-0.023-0.033-0.035-0.052c-0.027-0.038-0.055-0.074-0.081-0.112
		c1.167,2.166,3.521,3.673,5.675,5.104c0.066,0.045,0.134,0.09,0.2,0.134c0.076,0.053,0.152,0.104,0.228,0.154
		c0.306,0.205,0.608,0.411,0.896,0.618c3.161,2.269,6.654,4.464,10.32,5.76c-8.193-1.007-17.883-0.517-26.64-0.96
		c-1.24-0.039-0.353-2.207-2.16-1.68c-4.564,4.575,4.455,4.561,8.64,4.56c12.288-0.001,23.727,1.207,31.92,3.36
		c0.126,11.811,0.442,25.017-1.2,35.76c-22.071,0.089-44.54-0.22-66.24,0.24c0.398-4.133,3.466-7.101,5.04-11.04
		c2.577-6.45,3.681-14.535,4.32-22.32c0.242-2.954-0.264-5.854,0-8.88c0.322-3.688,1.598-7.278,1.92-11.04
		c0.357-4.174,0.234-8.394,0.48-12.72c0.307-5.391,2.74-10.296,3.12-15.603c0.191-2.665-0.356-5.504,0-8.157
		c0.099-0.741,0.599-1.494,0.72-2.4c0.509-3.826-0.358-8.959,0.24-12.479c0.212-0.166,0.39-0.36,0.553-0.567
		c0.048-0.063,0.091-0.127,0.135-0.191c0.111-0.161,0.21-0.332,0.298-0.513c0.034-0.069,0.07-0.136,0.101-0.208
		c0.105-0.247,0.198-0.505,0.272-0.779c0.01-0.037,0.016-0.077,0.026-0.113c0.063-0.246,0.114-0.501,0.158-0.766
		c0.017-0.104,0.033-0.211,0.047-0.315c0.03-0.217,0.057-0.436,0.079-0.656c0.011-0.106,0.023-0.212,0.032-0.319
		c0.025-0.293,0.046-0.589,0.065-0.891c-0.037,0.404-0.082,0.795-0.136,1.171c-0.031,0.226-0.064,0.446-0.103,0.66
		c-0.023,0.125-0.048,0.248-0.075,0.368c-0.072,0.34-0.152,0.667-0.253,0.963c-0.293,0.861-1.46,1.564-1.68,2.397
		c-0.229,0.868-0.302,1.833-0.293,2.854c-0.01-1.026,0.063-1.997,0.293-2.87c0.219-0.833,1.387-1.536,1.68-2.399
		c0.101-0.296,0.18-0.625,0.253-0.963c0.026-0.12,0.051-0.243,0.075-0.368c0.039-0.212,0.072-0.436,0.103-0.659
		c0.054-0.378,0.099-0.771,0.136-1.177v-0.001c0.072-0.79,0.124-1.619,0.167-2.476c0-0.001,0-0.002,0-0.003
		c0.016-0.314,0.031-0.631,0.047-0.949c0.003-0.059,0.006-0.113,0.008-0.171c0.018-0.348,0.039-0.694,0.06-1.042
		c0.011-0.186,0.021-0.368,0.034-0.554c0.023-0.335,0.054-0.667,0.084-0.997c0.015-0.164,0.026-0.331,0.043-0.493
		c0.052-0.485,0.113-0.964,0.19-1.429c1.591-9.582,6.085-23.07,6.72-30.96c0-0.08,0-0.159,0-0.239
		c0.61-10.509,1.609-21.25,3.36-30.96c0-0.08,0-0.16,0-0.24c0.305-8.722-1.904-15.416-1.44-24.72
		c0.645-12.94,2.321-26.057,5.28-36.722c0.16-1.36,1.721,1.336,2.16-0.239c0.249-0.295,0.264-0.547,0.152-0.765
		c0.116,0.22,0.103,0.473-0.149,0.771c-0.439,1.575-2-1.122-2.16,0.239c-2.958,10.665-4.635,23.778-5.28,36.721
		c-0.464,9.304,1.745,15.998,1.44,24.72c1.055-2.679,0.002-8.176-0.48-12c-2.202-17.466,1.611-35.235,4.32-49.2
		c0.368-0.525,2.319,1.167,2.4-0.477c1.006-1.415-1.431-1.442-1.44-2.641c0.115-0.185,0.209-0.388,0.289-0.604
		c0.011-0.03,0.018-0.063,0.029-0.096c0.503-1.473,0.406-3.544,1.258-4.666c-0.193-0.152-0.397-0.287-0.616-0.396
		c-0.13,2.352-0.853,4.107-1.2,6.24c-0.041,0.061-0.034,0.121,0.005,0.182c-0.043-0.063-0.05-0.125-0.008-0.188
		c0.347-2.134,1.069-3.892,1.2-6.24c0.219,0.106,0.423,0.242,0.617,0.396c0.146,0.113,0.283,0.244,0.417,0.379
		c0,0.002,0,0.002,0,0.002s0,0.001,0.001,0.001c0.256,0.259,0.491,0.553,0.713,0.868c0.011,0.017,0.021,0.03,0.032,0.047
		c0.463,0.668,0.873,1.423,1.279,2.16c0.178,0.322,0.357,0.646,0.541,0.947c9.735,16.089,15.638,37.999,30,49.92
		c0,0.002-0.001,0.004-0.002,0.007c-14.359-11.924-20.261-33.83-29.995-49.916c-0.184-0.306-0.363-0.625-0.541-0.948
		c-0.407-0.737-0.815-1.494-1.279-2.161c-0.011-0.016-0.021-0.03-0.032-0.046c-0.222-0.315-0.458-0.61-0.713-0.869
		c0,0,0-0.001-0.001-0.001c0.009,0.015,0.017,0.029,0.025,0.044c6.707,11.13,11.654,24.021,18.059,35.453
		c0.021,0.039,0.042,0.079,0.064,0.118c0.557,0.989,1.131,1.964,1.711,2.931c0.107,0.178,0.208,0.361,0.315,0.537
		c0.529,0.869,1.08,1.717,1.633,2.563c0.168,0.259,0.327,0.522,0.498,0.777c0.517,0.771,1.06,1.52,1.601,2.268
		c0.223,0.31,0.433,0.629,0.66,0.933c0.529,0.709,1.087,1.389,1.642,2.072c0.259,0.317,0.503,0.65,0.767,0.966
		c0.585,0.693,1.204,1.354,1.819,2.019c0.257,0.277,0.498,0.571,0.761,0.844c0.891,0.923,1.813,1.813,2.771,2.665
		c-5.926,17.953-13.523,38.814-10.56,62.159c1.361,10.728,5.143,19.591,8.4,28.563c3.328,9.165,9.292,16.208,15.12,22.08
		C176.377,649.815,174.659,660.956,171.84,670.725z M175.2,635.679c-1.65-1.979-2.979-3.805-4.08-5.277
		c-5.734-7.683-7.826-15.991-11.52-25.44c-0.233-0.596-0.453-1.191-0.661-1.79c0.207,0.595,0.426,1.188,0.658,1.781
		c3.694,9.448,5.786,17.76,11.52,25.439c1.101,1.476,2.43,3.3,4.08,5.278c1.251,1.502,4.138,3.744,4.32,4.802
		C179.32,639.41,176.448,637.177,175.2,635.679z M182.926,639.129c0.393,0.154,1.049-0.112,1.479,0.063
		C183.974,639.018,183.318,639.284,182.926,639.129z M306.24,724.727c-36.879,0-73.76,0-110.64,0
		c-0.148-0.211-0.312-0.447-0.481-0.699c-0.044-0.066-0.089-0.138-0.135-0.204c-0.134-0.202-0.269-0.409-0.408-0.628
		c-0.052-0.082-0.102-0.161-0.154-0.245c-0.143-0.229-0.284-0.463-0.424-0.702c-0.043-0.072-0.085-0.143-0.127-0.216
		c-0.175-0.308-0.344-0.613-0.5-0.924c-0.002-0.005-0.004-0.01-0.006-0.015c-0.001-0.003-0.002-0.005-0.003-0.008
		c0-0.001,0-0.001,0-0.001c-0.369-0.949-0.697-1.966-0.989-3.039v-0.001c-0.001-0.002-0.001-0.004-0.001-0.006
		c0-0.004-0.001-0.007-0.001-0.011c-1.551-9.53-1.616-21.943-2.621-32.944c-0.001-0.017-0.003-0.034-0.005-0.052
		c-0.046-0.509-0.096-1.016-0.147-1.519c-0.006-0.061-0.012-0.123-0.018-0.185c-0.047-0.457-0.097-0.91-0.148-1.361
		c-0.012-0.103-0.022-0.206-0.034-0.308c-0.049-0.427-0.102-0.851-0.155-1.271c-0.015-0.117-0.029-0.236-0.044-0.354
		c-0.051-0.396-0.106-0.781-0.161-1.168c-0.02-0.145-0.039-0.287-0.06-0.431c-0.053-0.361-0.111-0.721-0.168-1.077
		c-0.025-0.157-0.049-0.317-0.075-0.477c-0.057-0.342-0.118-0.678-0.179-1.015c-0.03-0.164-0.058-0.332-0.088-0.495
		c-0.06-0.319-0.125-0.633-0.189-0.946c-0.035-0.174-0.068-0.35-0.105-0.521c-0.064-0.3-0.133-0.593-0.2-0.886
		c-0.041-0.178-0.08-0.357-0.122-0.532c-0.069-0.286-0.144-0.563-0.217-0.846c-0.045-0.174-0.088-0.352-0.135-0.521
		c-0.077-0.279-0.159-0.548-0.239-0.82c-0.049-0.163-0.095-0.332-0.145-0.493c-0.087-0.278-0.181-0.551-0.274-0.821
		c-0.049-0.145-0.094-0.291-0.145-0.434c-0.145-0.407-0.295-0.804-0.452-1.188c0-0.001,0-0.001,0-0.002c0-0.08,0-0.16,0-0.24
		c0-0.005,0-0.01,0-0.014c0.014-0.142,0.04-0.271,0.238-0.228c0.159-5.363-1.232-13.525-1.44-20.16c-0.074-2.354,0.564-5.81,0-6.96
		c-0.513-1.046-1.508-0.743-2.16-1.198c-6.603-7.443-14.966-16.607-16.32-28.319c-3.004-8.196-6.447-15.979-5.52-27.603
		c0.026-0.332,0.054-0.681,0.081-1.025c0.008-0.067,0.011-0.135,0.02-0.202c0.046-0.391,0.085-0.777,0.143-1.169
		c0.484-3.313,0.607-6.524,0.722-9.664v-0.001c0.001-0.041,0.003-0.081,0.005-0.122c0.053-0.627,0.104-1.243,0.157-1.851
		c0.03-0.354,0.061-0.704,0.091-1.052c0.021-0.239,0.042-0.479,0.063-0.716c0.052-0.587,0.104-1.166,0.157-1.722
		c0.74-7.873,2.734-16.166,5.52-22.562c1.037-2.381,2.088-4.737,3.145-7.09c0.406-0.903,0.814-1.804,1.222-2.705
		c0.764-1.689,1.527-3.377,2.292-5.06c0.03-0.059,0.061-0.118,0.09-0.177c0-0.002,0.001-0.003,0.001-0.005
		c1.375-2.719,2.696-5.441,3.815-8.235c0.166-0.414,0.315-0.832,0.475-1.248c0-0.001,0.001-0.002,0.001-0.004
		c0.008-0.021,0.016-0.043,0.023-0.063c0.881-1.968,1.753-3.939,2.616-5.92c0.181-0.416,0.355-0.837,0.535-1.255
		c0.688-1.592,1.373-3.186,2.042-4.791c0.438-1.051,0.858-2.115,1.287-3.173c0.401-0.989,0.812-1.973,1.204-2.97
		c0.813-2.068,1.605-4.153,2.373-6.262c7.269-2.352,14.939-4.553,23.04-6.479c8.061-1.918,16.579-4.312,25.92-3.603
		c4.075,0.312,7.704,1.492,11.52,1.922c13.04,1.464,24.411-3.516,36.24-4.08c-0.919,7.938-3.168,16.602-4.08,26.641
		c-0.104,1.138-0.171,2.28-0.223,3.424c-0.018,0.396-0.028,0.794-0.041,1.19c-0.024,0.746-0.041,1.493-0.048,2.237
		c-0.004,0.471-0.009,0.938-0.007,1.406c0.001,0.673,0.01,1.344,0.021,2.017c0.008,0.482,0.012,0.971,0.024,1.454
		c0.099-12.12,2.316-23.703,3.871-33.661c0.021-0.136,0.042-0.271,0.063-0.404c0.092-0.593,0.179-1.175,0.265-1.756
		c0.028-0.191,0.059-0.388,0.086-0.576c0.109-0.746,0.212-1.482,0.309-2.207c0.001,0,0.002,0,0.003,0
		c-0.096,0.726-0.2,1.464-0.309,2.211c-0.028,0.189-0.058,0.385-0.086,0.576c-0.086,0.581-0.173,1.163-0.265,1.756
		c-0.021,0.134-0.042,0.271-0.063,0.404c-1.555,9.958-3.772,21.541-3.871,33.662c0,0,0,0.002,0,0.003
		c-0.011,1.282-0.006,2.57,0.035,3.865c0.046,1.475,0.154,2.945,0.275,4.41c0,0.001,0,0.003,0,0.004
		c0.08,0.972,0.164,1.938,0.242,2.897c0.061,0.749,0.117,1.494,0.162,2.233c0.011,0.18,0.017,0.354,0.026,0.529
		c0.033,0.617,0.055,1.23,0.067,1.84c0.004,0.227,0.008,0.448,0.008,0.672c0.002,0.771-0.009,1.537-0.06,2.293
		c-0.133,1.985-2.207,6.847-1.2,8.642c1.614,2.877,9.916,0.474,12.959,1.68c0.511,1.914,0.761,5.286,0.24,7.44
		c-0.001,0-0.003,0-0.004,0.001c0.521-2.154,0.271-5.525-0.24-7.439c-3.044-1.206-11.346,1.197-12.959-1.68
		c-1.007-1.795,1.067-6.654,1.2-8.641c0.051-0.757,0.063-1.521,0.06-2.292c0-0.226-0.004-0.447-0.008-0.674
		c-0.012-0.607-0.034-1.223-0.067-1.84c-0.009-0.177-0.015-0.352-0.026-0.527c-0.045-0.741-0.101-1.486-0.162-2.235
		c-0.078-0.961-0.162-1.928-0.242-2.897c0.002,0.061,0.004,0.116,0.007,0.177c0.04,0.95,0.075,1.896,0.107,2.834
		c0.028,0.823,0.05,1.642,0.067,2.451c0.007,0.313,0.012,0.628,0.016,0.938c0.015,1.047,0.022,2.087,0.007,3.106
		c-0.038,2.447-2.395,7.715-1.2,9.841c1.523,2.71,9.417,0.79,12.721,1.438c0.979,1.659,0.67,4.609,0.721,7.2
		c-3.809,2.048-11.922-0.604-14.4,2.4c-1.704,2.064-0.541,9.067,0,12c0.827,4.476,1.649,7.959,2.64,11.278
		c0.235,0.789,0.483,1.57,0.737,2.349c0.097,0.295,0.198,0.586,0.297,0.879c0.161,0.476,0.32,0.951,0.486,1.423
		c0.137,0.39,0.277,0.777,0.417,1.165c0.129,0.357,0.258,0.719,0.39,1.076c0.189,0.514,0.381,1.024,0.574,1.539
		c0.071,0.188,0.141,0.376,0.212,0.563c1.275,3.366,2.603,6.714,3.813,10.17c-0.903-2.723-1.865-5.414-2.847-8.122
		c-3.792-10.462-8.15-21.532-6.48-34.317c0.001,0,0.002-0.001,0.004-0.001c-1.67,12.784,2.688,23.854,6.48,34.316
		c0.982,2.71,1.945,5.403,2.849,8.128c1.102,3.323,2.113,6.693,2.957,10.196c0,0.002,0.001,0.005,0.001,0.007
		c0.001,0.002,0.001,0.004,0.002,0.006c0.271,1.13,0.521,2.277,0.755,3.438c0.035,0.175,0.072,0.349,0.105,0.522
		c0.22,1.116,0.416,2.251,0.596,3.402c0.033,0.215,0.064,0.432,0.098,0.646c0.172,1.162,0.323,2.343,0.449,3.545
		c0.02,0.18,0.033,0.361,0.051,0.544c0.014,0.142,0.02,0.291,0.033,0.433c-0.014-0.142-0.024-0.287-0.038-0.429
		c-0.019-0.183-0.03-0.364-0.051-0.544c-0.126-1.202-0.278-2.381-0.449-3.543c-0.03-0.219-0.063-0.436-0.098-0.648
		c-0.181-1.15-0.376-2.286-0.597-3.4c-0.033-0.178-0.069-0.352-0.104-0.524c-0.233-1.16-0.481-2.308-0.755-3.438
		c-0.001-0.002-0.001-0.004-0.002-0.006c0.002,0.008,0.003,0.016,0.004,0.021c0.222,1.012,0.417,2.045,0.595,3.093
		c0.036,0.215,0.072,0.431,0.106,0.647c0.175,1.097,0.33,2.207,0.447,3.349c0.297,2.905,0.535,5.995,0.719,8.88
		c0.183,2.823-0.233,6.031,1.922,7.92c5.179-0.422,10.273-2.204,13.92-3.359c-0.152,3.446,0.311,7.512-0.239,10.562
		c-3.884,3.655-10.237,2.417-12.96,6.479c-2.723,4.065-1.682,12.132-1.682,19.2c0,13.625-1.303,30.687-1.199,42.96
		c2.482,3.117,8.893,2.311,13.681,3.12C306.971,717.299,306.362,720.769,306.24,724.727z M282.942,561.953
		c-0.069,0.848,0.102,1.934,0.028,2.776C283.043,563.887,282.873,562.8,282.942,561.953z M296.405,634.719
		c5.146-0.691,9.691-1.986,13.92-3.6c0.06,2.876-0.043,7.625,0,11.278c-0.002,0.002-0.003,0.003-0.005,0.004
		c-0.043-3.655,0.06-8.402,0-11.278c-4.229,1.613-8.772,2.907-13.92,3.6c-2.227-1.477-1.519-4.5-1.68-7.68
		c-0.007-0.125-0.018-0.246-0.024-0.371c0.007,0.125,0.021,0.242,0.028,0.367C294.887,630.219,294.18,633.242,296.405,634.719z
		 M307.52,716.349c0.161-0.896,0.308-1.785,0.401-2.661c-4.782-0.823-11.247,0.041-13.681-3.125c0-12.729,1.199-28.759,1.199-42.479
		c0-7.568-0.867-15.539,2.012-19.464c-2.873,3.929-2.008,11.896-2.008,19.46c0,13.723-1.198,29.751-1.198,42.479
		c2.435,3.166,8.896,2.304,13.681,3.12C307.83,714.558,307.682,715.45,307.52,716.349z M316.803,724.958c-0.001,0-0.001,0-0.002,0
		c0.558-7.761,0.081-16.557,0.239-24.717c0-0.642,0-1.28,0-1.92c0.058-0.075,0.098-0.167,0.135-0.263
		c-0.037,0.094-0.076,0.186-0.133,0.26c0,0.64,0,1.279,0,1.92C316.884,708.399,317.36,717.196,316.803,724.958z M317.476,693.978
		c0.186-0.477-0.025-1.349,0.027-1.958C317.453,692.631,317.664,693.503,317.476,693.978z M317.769,690.408
		c0.099-0.555-0.076-1.384-0.025-1.985C317.694,689.025,317.869,689.854,317.769,690.408z M317.969,685.66
		c-0.011,0.428,0.078,0.956,0.068,1.385C318.047,686.616,317.957,686.088,317.969,685.66z M318.462,652.873
		c0.158-2.059-0.107-4.541,0.019-6.63c-0.08,0-0.16,0-0.24,0c0,0.477,0,0.957,0,1.436c-0.399,5.522-0.399,12.722,0,18.242
		c0,2.563,0,5.12,0,7.681c-0.559,2.561-0.08,6.159-0.24,9.119c0,0.802,0,1.602,0,2.398c0,0.001,0,0.001,0,0.002
		c0,0.079,0,0.159,0,0.238c0,0.001,0,0.002,0,0.002c-0.546,0.333-0.09,1.667-0.239,2.396c0,0.16,0,0.32,0,0.48
		c0,0.001,0,0.003,0,0.004c-0.326,0.075-0.199,0.601-0.239,0.96c0,0.56,0,1.117,0,1.676c0,0.001,0,0.003,0,0.004
		c0,0.319,0,0.638,0,0.956c0,0.001,0,0.003,0,0.004c-0.001,0-0.002,0-0.003,0c0-0.317,0-0.64,0-0.96c0-0.56,0-1.119,0-1.68
		c-0.547,0.411-0.086,1.832-0.238,2.64h0.001c0,0.801,0,1.603,0,2.4l0.001-0.001c0,0.159,0,0.316,0,0.476
		c-0.136,0.985,0.206,2.449-0.079,3.287c0.284-0.838-0.059-2.3,0.077-3.284c-0.325,0.075-0.199,0.604-0.24,0.961
		c-0.556,1.046-0.083,3.118-0.239,4.562c0,0.56,0,1.12,0,1.68c-0.561,7.12-0.08,15.28-0.24,22.801c-2.318,0-4.64,0-6.96,0
		c0.202-0.599,0.314-1.286,0.24-2.158c0.001-0.001,0.001-0.002,0.002-0.003c0-0.08,0-0.16,0-0.24c0-0.004,0.001-0.007,0.001-0.01
		c0.435-1.394,0.723-2.933,0.716-4.767c0.002-0.008,0.003-0.016,0.005-0.023c0-0.08,0-0.16,0-0.24
		c0.001-0.007,0.002-0.013,0.003-0.02c1.028-2.445,0.679-5.438-0.967-6.939c-0.078,0-0.158,0-0.238,0c-0.001,0-0.001,0-0.002,0
		c-0.336-0.397-2.301-0.397-2.64-0.001c-1.986-0.013-3.681-0.316-5.275-0.72c-0.271,0.022-0.523,0.028-0.768,0.024
		c0.243,0.003,0.495-0.003,0.764-0.025c-0.365-0.754-1.842-0.397-2.881-0.479c-0.547,0.067-0.9-0.058-0.959-0.479
		c0.531-0.103,0.105-1.166,0.237-1.668c0.003-0.004,0.004-0.01,0.007-0.014c0-1.52,0-3.04,0-4.56c0-0.006,0-0.012,0-0.017
		c0.548-1.366,0.078-3.749,0.234-5.504v0.001c0.396-1.516,0.398-4.689,0.004-6.22c0-0.007,0-0.014,0-0.021
		c-0.161-1.2,0.223-1.856,0.24-2.881c0.513,0.035,0.105-0.851,0.236-1.197v0.001c0.561-2.162,0.082-5.359,0.24-7.922
		c0-0.48,0-0.96,0-1.44c0.561-4.643,0.08-10.319,0.239-15.36c-0.158-2.158,0.315-4.949-0.237-6.711c0-0.004,0-0.007,0-0.01
		c-0.101-1.14,0.116-1.963,0.479-2.64c0-0.007,0.002-0.011,0.003-0.018c0.383-2.491,2.632-3.113,5.035-3.582
		c-1.729,0.078-3.232,0.381-4.138,1.283c0.904-0.903,2.408-1.208,4.138-1.286c0.285-0.274,0.851-0.271,1.441-0.239
		c1.954-0.944,3.447-1.203,4.799-2.158c1.031-0.729,1.688-1.681,2.1-2.803c-0.412,1.123-1.068,2.075-2.1,2.804
		c-1.352,0.953-2.845,1.214-4.799,2.157c3.654-0.901,6.764-2.355,7.681-6c0-0.007,0-0.013,0-0.02c0.395-3.593,0.395-8.844,0-12.436
		c0-0.008,0-0.016,0-0.023c-0.596-0.926-1.085-1.955-1.921-2.641c-3.842,0.237-6.604,1.557-9.84,2.398
		c3.533-0.386,5.855-1.982,9.601-2.16c0.91,0.61,1.346,1.694,1.92,2.643c-0.574-0.946-1.01-2.03-1.92-2.643
		c-3.743,0.18-6.065,1.774-9.601,2.16c-0.08,0-0.16,0-0.24,0c-0.004,0.002-0.007,0.003-0.011,0.004
		c-1.417,0.02-2.249,0.624-3.59,0.719c0-0.64,0-1.277,0-1.92c-0.155-1.204,0.313-3.033-0.239-3.84c0.021-0.341,0.039-0.68-0.24-0.72
		c0-0.001,0-0.002,0-0.003c0-0.08,0-0.16,0-0.24c-0.001-0.001-0.002-0.002-0.003-0.003c-0.146-0.728,0.306-2.057-0.235-2.389
		c-0.001-0.002-0.001-0.004-0.001-0.006c-0.233-1.768-0.556-3.445-0.479-5.521c-0.001-0.002-0.001-0.004-0.002-0.005
		c0.013-1.047-0.098-1.971-0.477-2.625c0-0.003-0.001-0.007-0.001-0.01c0-0.08,0-0.16,0-0.24c-0.005-0.016-0.01-0.031-0.015-0.048
		c-0.883-4.272-2.222-8.086-3.33-12.13c-0.004-0.021-0.01-0.041-0.014-0.063c0-0.08,0-0.16,0-0.238
		c-0.007-0.021-0.014-0.041-0.021-0.061c-1.209-4.82-2.914-9.145-4.523-13.564c-0.005-0.02-0.012-0.037-0.017-0.057
		c-0.269-0.771-0.657-1.423-0.72-2.398c-0.004-0.01-0.007-0.021-0.011-0.031c-1.196-3.972-2.136-8.201-3.34-12.168
		c-0.003-0.014-0.006-0.026-0.009-0.04c-0.456-1.784-0.919-3.563-0.96-5.761c-0.001,0-0.002-0.001-0.003-0.002
		c0-1.839,0-3.678,0-5.518c-0.244-0.806,0.747-0.374,1.2-0.48c0-0.001,0-0.001,0-0.002c0.808-0.149,2.229,0.312,2.64-0.237
		c0.38-0.021,0.916,0.116,0.96-0.24c-1.045,0.154-2.713-0.313-3.36,0.24c-0.079,0-0.16,0-0.24,0c0,0.001,0,0.001,0,0.002
		c-0.4,0-0.799,0-1.199,0c0.001-0.001,0.001-0.003,0.002-0.004c0.4,0,0.8,0,1.2,0c0.08,0,0.16,0,0.24,0
		c0.646-0.555,2.314-0.086,3.36-0.24c1.188-0.251,2.455-0.426,3.84-0.479h0.001c0.983-0.055,1.96-0.117,2.637-0.479
		c0.001,0,0.003,0,0.004,0c0.08,0,0.16,0,0.24,0c0.362-0.066,0.678-0.18,0.952-0.333c-0.275,0.154-0.592,0.269-0.956,0.335
		c1.703-0.219,2.549-1.294,2.881-2.882c0.397-0.571,0.397-2.79,0-3.36c-0.14-0.421,0.287-1.406-0.238-1.437c0-0.001,0-0.002,0-0.003
		c-0.247-0.633-0.286-1.476-0.239-2.4c-0.002-0.005-0.004-0.01-0.007-0.015c-0.306-1.769-0.876-3.272-2.877-3.346v0.001
		c-0.159,0-0.317,0-0.475,0c-1.041,0-2.08,0-3.121,0c-0.506,0.249-1.659,0.343-2.65,0.28c0.99,0.062,2.141-0.031,2.646-0.281
		c1.041,0,2.08,0,3.12,0c-2.003-0.397-5.678-0.397-7.68,0c0.16,0,0.32,0,0.48,0c0,0,0.002,0.001,0.003,0.001c-0.16,0-0.32,0-0.48,0
		c-0.875,0.073-1.562-0.037-2.16-0.238c0.053-2.508,0.786-4.335,0.96-6.722c0-0.003,0-0.007,0-0.01
		c0.549-0.965,0.081-2.951,0.237-4.312c0,0-0.001,0-0.001,0c-0.155-1.126,0.313-2.873-0.24-3.6
		c-0.147-0.647,0.301-1.894-0.236-2.156c0-0.001,0-0.002,0-0.003c0-0.239,0-0.479,0-0.721c-0.002-0.001-0.002-0.003-0.004-0.005
		c-0.154-1.362,0.316-3.352-0.24-4.315V523.6c0-2.72,0-5.438,0-8.158c0-0.945,0-1.889,0-2.835c0-0.003,0-0.007,0.001-0.011
		c0,0.948,0,1.897,0,2.845c0.555-0.964,0.083-2.956,0.24-4.319c0-1.277,0-2.557,0-3.832c0.001-0.002,0.001-0.004,0.003-0.006
		c0.049-0.751-0.162-1.764,0.24-2.16c0-0.008,0.001-0.015,0.001-0.022c0.423-1.401,0.593-3.055,0.713-4.758
		c0.002-0.007,0.003-0.014,0.006-0.021c0.002-0.638-0.091-1.37,0.24-1.681c0-0.004,0-0.009,0-0.013
		c0.358-0.591,0.458-1.441,0.475-2.375c0.002-0.003,0.003-0.008,0.005-0.011c0.157-1.923,0.331-3.829,0.72-5.521
		c0-0.004,0-0.007,0-0.011c0.361-0.831,0.43-1.954,0.477-3.1c0.001-0.003,0.002-0.007,0.003-0.01c0.07-2.169,0.399-4.079,0.96-5.761
		c0.001-0.005,0.001-0.011,0.002-0.016c0.49-1.018,0.728-2.286,0.713-3.808c0.002-0.006,0.003-0.012,0.005-0.017
		c0-0.08,0-0.16,0-0.24c0.078,0,0.156,0,0.235,0c-0.295-1.495,1.51-0.892,2.16-1.438c-0.266-0.027-0.507-0.03-0.73-0.015
		c0.225-0.016,0.468-0.013,0.734,0.015c0.16,0,0.318,0,0.479,0c0.002-0.001,0.004-0.001,0.006-0.002
		c1.142,0.026,2.159-0.068,2.864-0.477c0.004,0,0.007-0.001,0.01-0.001c0.08,0,0.16,0,0.24,0c0.002-0.001,0.005-0.002,0.007-0.003
		c1.252-0.024,2.155-0.398,3.591-0.239c0-0.001,0-0.001,0-0.002c0.809-0.148,2.229,0.312,2.639-0.238c0.001,0,0.001,0,0.002,0
		c1.474-0.354,2.452-1.204,2.634-2.852c0.002-0.009,0.006-0.016,0.008-0.024c0-0.08,0-0.16,0-0.239c0-0.002,0-0.004,0.001-0.006
		c0.51-0.845,0.499-2.211,0.477-3.588c0.001-0.002,0.001-0.005,0.002-0.007c0-0.16,0-0.32,0-0.479c0-0.004-0.001-0.008-0.002-0.012
		c0.298-3.259-1.543-6.451-4.561-7.192c-0.413-0.398-2.466-0.398-2.88,0c-0.239,0-0.479,0-0.72,0
		c-1.842,0.158-4.317-0.317-5.76,0.24c-1.279,0-2.56,0-3.84,0c0,0,0,0-0.001,0c-0.807-0.399-3.272-0.399-4.08,0
		c0.24,0,0.48,0,0.72,0c0,0,0,0,0.001,0c3.601,0.399,8.877,0.399,12.479,0c0.161,0,0.321,0,0.48,0
		c4.049-0.585,6.151,0.777,7.232,3.162c-1.081-2.381-3.184-3.741-7.23-3.158c-0.159,0-0.319,0-0.479,0
		c-3.603,0.399-8.878,0.399-12.479,0c-0.24,0-0.48,0-0.72,0c-1.742-0.099-3.357-0.323-4.8-0.721
		c-1.291-0.069-2.468-0.252-3.36-0.722c-0.895,0.017-1.518-0.24-2.16-0.479c-1.501-0.339-3.139-0.541-4.32-1.199
		c-0.08,0-0.16,0-0.24,0c-9.425-2.095-15.669-7.372-23.28-11.28c0-0.001-0.001-0.002-0.002-0.004
		c7.61,3.908,13.854,9.188,23.279,11.28c-8.962-2.559-15.749-7.292-23.04-11.521c-0.769-0.896-1.527-1.804-2.269-2.727
		c-0.003-0.005-0.007-0.009-0.011-0.014c0.362,0.474,0.717,0.955,1.057,1.446c0.297,0.433,0.578,0.877,0.851,1.329
		c-0.272-0.448-0.552-0.892-0.848-1.32c-0.34-0.493-0.695-0.977-1.057-1.448c-0.001-0.001-0.001-0.002-0.002-0.002
		c-0.206-0.271-0.418-0.532-0.628-0.798c-0.005-0.008-0.01-0.015-0.016-0.021c-0.011-0.014-0.022-0.027-0.033-0.041
		c-1.85-2.358-3.588-4.828-5.146-7.48c-0.053-0.093-0.107-0.184-0.16-0.272c-0.487-0.84-0.956-1.697-1.404-2.577
		c-0.046-0.091-0.091-0.185-0.137-0.274c-0.447-0.893-0.876-1.801-1.278-2.735c-0.006-0.015-0.014-0.029-0.02-0.044
		c-0.391-0.913-0.752-1.855-1.096-2.815c-0.06-0.166-0.12-0.332-0.178-0.499c-0.323-0.936-0.623-1.894-0.897-2.877
		c-0.049-0.176-0.094-0.354-0.142-0.532c-0.244-0.913-0.469-1.847-0.669-2.806c-0.023-0.124-0.052-0.242-0.074-0.367
		c-0.276-0.281-0.271-0.849-0.24-1.438c-0.002-0.003-0.002-0.007-0.004-0.01c-0.056-0.979-0.117-1.949-0.476-2.625
		c0-0.001,0-0.003,0-0.005c0-0.08,0-0.16,0-0.24c-0.002-0.005-0.003-0.011-0.005-0.015c-0.132-1.458-0.292-2.886-0.714-4.053
		c0-0.004-0.001-0.008-0.001-0.012c-0.489-2.472-0.868-5.052-0.96-7.92c-0.001-0.001-0.002-0.003-0.004-0.004c0-0.722,0-1.44,0-2.16
		c0.146-0.012,0.274-0.042,0.388-0.085c0.032-0.013,0.055-0.033,0.085-0.048c0.077-0.038,0.154-0.075,0.218-0.126
		c0.024-0.021,0.042-0.044,0.065-0.065c0.06-0.057,0.12-0.113,0.169-0.18c0.013-0.02,0.023-0.038,0.036-0.059
		c0.295-0.433,0.427-1.028,0.676-1.51c-0.083-0.115-0.174-0.227-0.261-0.34c0.088,0.115,0.181,0.228,0.265,0.344
		c0.038,0.054,0.084,0.104,0.121,0.157c0,0,0,0,0,0.001c0,0,0,0.001,0.001,0.001c0.119,0.177,0.221,0.36,0.323,0.544
		c0.044,0.078,0.093,0.155,0.134,0.234c0.097,0.188,0.183,0.379,0.267,0.57c0.034,0.077,0.07,0.153,0.102,0.231
		c0.084,0.206,0.164,0.414,0.239,0.624c0.021,0.058,0.042,0.116,0.063,0.175c0.224,0.645,0.423,1.294,0.641,1.938
		c0.023,0.066,0.046,0.137,0.069,0.205c0.074,0.212,0.155,0.421,0.237,0.629c0.033,0.083,0.063,0.168,0.098,0.251
		c0.118,0.278,0.244,0.557,0.389,0.827c3.256,6.084,7.688,12.041,12.48,17.039c-0.04,0.761,0.638,0.804,0.48,1.681
		c0.502,0.219,0.731,0.709,0.72,1.438c3.779,7.82,11.11,12.092,18.48,16.32c0.557-0.077,0.921,0.038,1.2,0.239
		c1.848,0.23,3.563,0.598,5.04,1.2c1.017-0.059,1.699,0.221,2.4,0.479c1.938,0.302,3.73,0.75,5.52,1.2
		c2.647,0.168,4.448-0.51,6.958-0.479c-0.001,0-0.002,0.001-0.003,0.001c1.495-0.024,2.642-0.396,4.32-0.239
		c0.809-0.151,2.23,0.31,2.641-0.239c0.341,0.021,0.68,0.039,0.721-0.24c-0.607,0.113-1.555-0.116-2.166-0.009
		c0.61-0.11,1.563,0.121,2.172,0.008c1.347-0.334,2.882-0.479,4.56-0.479c0.002,0,0.004-0.001,0.006-0.001c1.757,0,3.513,0,5.267,0
		c0.641,0,1.281,0,1.92,0c-0.229-0.05-0.484-0.093-0.76-0.131c0.278,0.038,0.536,0.082,0.768,0.132
		c0.809,0.15,2.229-0.311,2.641,0.238c1.426-0.044,3.353,0.073,2.642,1.44c-0.153,0.887,0.311,2.391-0.24,2.88
		c-0.232,3.207-0.696,6.186-1.2,9.12c0.048,0.927,0.009,1.77-0.239,2.399c-0.149,0.729,0.309,2.065-0.238,2.398
		c0,0.16,0,0.32,0,0.479c-0.16,6.801,0.317,14.238-0.24,20.641c0,0.8,0,1.6,0,2.399c-0.159,3.199,0.319,7.038-0.24,9.841
		c0,0.238,0,0.479,0,0.72c-0.028,2.451,0.169,5.129-0.479,6.96c0.047,0.927,0.009,1.77-0.24,2.399
		c-0.152,0.809,0.31,2.229-0.24,2.641c0,0.24,0,0.479,0,0.72c-0.148,0.729,0.31,2.067-0.24,2.4c0,0.24,0,0.479,0,0.72
		c-0.154,1.363,0.316,3.355-0.237,4.32c0,0.238,0,0.479,0,0.72c0.023,2.505,0.009,4.969-0.48,6.96
		c0.073,1.193-0.038,2.202-0.24,3.12c-0.152,0.966,0.313,2.552-0.238,3.12c-0.119,3.64-0.535,6.982-0.959,10.318
		c0,4.319,0,8.642,0,12.962c0,0.237,0,0.479,0,0.72c0.547,0.333,0.088,1.671,0.238,2.398c0.27,1.411,0.523,2.836,0.479,4.562
		c0.366,0.992,0.438,2.278,0.479,3.6c0.371,0.352,0.212,1.229,0.24,1.92c0.48,1.603,0.788,3.373,0.96,5.28
		c0.276,0.284,0.271,0.849,0.24,1.44c0.484,1.112,0.799,2.399,0.96,3.84c0,0.08,0,0.16,0,0.239c0.69,1.947,1.188,4.093,1.68,6.238
		c0,0.08,0,0.16,0,0.24c1.296,3.904,2.775,7.625,3.841,11.76c0.608,1.474,1.147,3.014,1.439,4.802
		c0.476,0.805,0.789,1.771,0.96,2.881c0.269,0.771,0.657,1.423,0.72,2.397c0.627,1.055,0.937,2.428,0.96,4.08
		c0.371,0.352,0.212,1.229,0.24,1.92c0.548,0.333,0.09,1.671,0.24,2.4c0,0.479,0,0.96,0,1.438c0.557,1.123,0.083,3.277,0.239,4.802
		c0,0.397,0,0.8,0,1.198c0.137,6.855,0.122,15.645-0.239,22.802c0,0.64,0,1.278,0,1.92
		C318.357,648.331,318.621,650.814,318.462,652.873z M309.059,710.534c0.197-0.008,0.385-0.007,0.542,0.025c0,0,0,0.001,0.001,0.001
		C309.444,710.528,309.256,710.526,309.059,710.534z M320.168,605.914c3.047,0.165,7.876,0.584,11.75,0.721
		c0.001,0.002,0.002,0.004,0.003,0.006c-3.872-0.135-8.7-0.554-11.75-0.719C320.17,605.919,320.169,605.917,320.168,605.914z
		 M320.604,689.599c-0.011-0.47,0.08-1.04,0.068-1.509C320.684,688.559,320.594,689.128,320.604,689.599z M320.88,690.4
		c-0.037-0.115-0.051-0.238-0.048-0.365C320.829,690.163,320.843,690.286,320.88,690.4C320.881,690.401,320.881,690.4,320.88,690.4z
		 M320.641,687.761c0-0.001,0-0.001,0-0.002c0.378,0.088,0.513,0.289,0.528,0.552C321.152,688.049,321.018,687.848,320.641,687.761z
		 M321.365,667.833c2.215-0.348,7.289,0.536,8.635-1.433c0,0.001,0.001,0.002,0.002,0.003c-1.34,1.975-6.416,1.092-8.636,1.437
		C321.366,667.838,321.365,667.836,321.365,667.833z M384.725,724.952c-0.001,0-0.003,0-0.004,0
		c2.42-8.386,0.667-22.194,1.438-31.433c-2.188,0.693-9.192,5.766-10.562,0.722c0.002-0.002,0.005-0.003,0.007-0.005
		c1.368,5.04,8.372-0.028,10.561-0.724C385.393,702.753,387.147,716.569,384.725,724.952z M386.162,689.914
		c-0.002,0.001-0.004,0.002-0.006,0.003c0.509-3.727-0.876-6.226-0.479-9.116c-3.52,2.271-8.824,3.668-13.438,6.48
		c-3.271,1.99-5.747,4.149-9.602,4.56c-3.567,0.379-6.947-0.567-6.72-3.358c0.002,0,0.004,0,0.006-0.001
		c-0.222,2.785,3.153,3.731,6.72,3.353c3.854-0.408,6.33-2.567,9.602-4.56c4.615-2.813,9.921-4.212,13.438-6.48
		C385.287,683.688,386.672,686.188,386.162,689.914z M387.603,676.714c-0.002,0.001-0.004,0.002-0.005,0.004
		c0.035-0.956,0.383-1.6,0.842-2.132C387.983,675.117,387.638,675.76,387.603,676.714z M402.242,613.357
		c-0.279,2.103-1.257,4.294-1.682,6.479c-2.996,15.499-4.767,32.668-8.64,47.521c-0.001,0-0.002,0.001-0.003,0.001
		c3.872-14.854,5.641-32.018,8.639-47.516c0.424-2.186,1.4-4.377,1.682-6.479c0.354-2.644,0.354-6.014,0.401-8.746
		C402.593,607.35,402.593,610.716,402.242,613.357z M403.439,541.123c-7.149-2.291-16.683-3.479-21.359-7.44
		c0.002,0,0.004,0.001,0.007,0.001c4.681,3.958,14.207,5.146,21.354,7.436C403.44,541.12,403.439,541.121,403.439,541.123z
		 M404.147,536.526c0.104-0.608,0.21-1.214,0.253-1.884c-9.99-4.091-15.708-12.453-17.28-24.962
		c1.078,13.003,7.283,20.878,17.04,25.2c-0.262,1.021-0.18,2.38-0.72,3.12c-7.885-1.157-14.386-3.696-22.32-4.8
		c5.158,4.922,14.575,5.585,22.08,8.16c-0.787,0.972-3.188,0.33-3.119,2.157c0.118,1.321,2.07,0.812,2.64,1.683
		c-0.886,15.893-0.289,32.186,0,48.72c0,0.561,0,1.117,0,1.676c0.2,0.723,0.3,1.863,0.3,3.003c0-1.139-0.101-2.277-0.3-2.999
		c0,1.999,0,3.997,0,5.996c0,0.001,0,0.003,0,0.004c0,0.159,0,0.317,0,0.477c0,0.003,0,0.006,0,0.009
		c-0.26,3.76-0.867,7.701-0.72,11.515c-4.503,16.938-5.714,37.167-10.561,53.763c-2.331,0.689-3.764,3.351-6,4.318
		c-3.277,1.418-7.58,1.745-11.521,3.119c-9.09,3.172-18.332,5.86-28.32,8.16c-1.563,0.359-4.365,0.36-4.8,2.641
		c3.815,1.474,7.962-0.179,11.521-0.96c1.173-0.258,2.338-0.544,3.499-0.842c0.219-0.057,0.438-0.109,0.653-0.168
		c1.104-0.29,2.202-0.594,3.296-0.91c0.239-0.067,0.479-0.141,0.72-0.211c1.011-0.298,2.02-0.603,3.021-0.915
		c0.199-0.063,0.398-0.124,0.6-0.187c0.94-0.296,1.88-0.597,2.813-0.898c3.939-1.271,7.826-2.577,11.676-3.727
		c0.016-0.004,0.028-0.008,0.042-0.013c1.207-0.359,2.41-0.703,3.61-1.025c0.004-0.002,0.008-0.003,0.013-0.004
		c-0.849,0.188-1.703,0.374-2.559,0.563c-0.32,0.071-0.641,0.144-0.96,0.216c-0.21,0.051-0.42,0.104-0.63,0.153
		c-0.403,0.098-0.808,0.202-1.207,0.313c-0.187,0.05-0.369,0.1-0.555,0.15c-0.573,0.166-1.147,0.342-1.717,0.543
		c-8.71,3.094-18.135,6.021-27.601,7.92c-1.815,0.364-4.307,1.146-6-0.238c0.001-0.003,0.003-0.004,0.005-0.007
		c1.693,1.385,4.184,0.604,5.999,0.238c9.466-1.897,18.891-4.828,27.601-7.92c0.567-0.201,1.144-0.378,1.719-0.543
		c0.184-0.053,0.366-0.103,0.553-0.15c0.401-0.109,0.803-0.215,1.207-0.313c0.21-0.051,0.42-0.104,0.63-0.153
		c0.32-0.072,0.64-0.146,0.96-0.216c0.855-0.188,1.71-0.374,2.559-0.563c0.004,0,0.007-0.001,0.009-0.002
		c1.023-0.229,2.039-0.478,3.032-0.765c0.204-0.06,0.411-0.111,0.614-0.177c0.94-0.293,1.911-0.911,2.642-1.438
		c0.7-0.511,1.172-1.678,2.158-1.439c-0.18,0.482-0.459,0.863-0.778,1.205c0.317-0.34,0.595-0.72,0.772-1.198
		c-0.986-0.236-1.458,0.931-2.16,1.44c-0.729,0.526-1.697,1.146-2.64,1.438c-0.201,0.063-0.405,0.116-0.607,0.175
		c1.006-0.234,2.009-0.46,3.009-0.652c-2.195,4.322-7.621,5.92-12.96,8.397c-4.438,2.063-9.392,7.011-14.882,6.48
		c-1.727-0.167-3.068-1.667-4.8-0.96c-0.672,3.688,4.446,4.252,7.439,3.84c4.055-0.56,7.592-3.323,11.279-5.28
		c3.896-2.064,7.544-4.015,11.04-5.52c-0.424,2.525,1.024,5.194,0.479,8.64c-1.954,0.706-3.809,2.438-6.24,3.12
		c-1.549,0.434-4.015-0.113-4.318,1.438c-0.555,2.816,3.513,2.141,5.278,1.682c2.479-0.646,3.982-1.842,5.28-2.16
		c-0.976,8.972,1.294,23.188-1.679,30.955c-0.054,0.001-0.107,0.001-0.16,0.001c-19.715-0.623-40.867,0.184-60.555-0.474
		c0.3-1.294-0.714-1.854-0.48-3.12c0.152-0.832,1.046-1.25,1.2-2.16c0.324-1.903-0.636-4.557-0.96-7.2
		c-0.396-3.229-0.907-6.781-0.722-9.84c0.061-0.95,0.145-1.881,0.238-2.802c0.009-0.082,0.018-0.166,0.025-0.248
		c0.097-0.912,0.208-1.807,0.323-2.692c0.005-0.024,0.009-0.048,0.014-0.072c0.524-2.717,1.097-5.42,1.085-8.112
		c-0.001-0.317-0.025-0.659-0.059-1.013c0,0,0,0,0-0.002c-0.001-0.006-0.002-0.014-0.002-0.02c0.077-1.493,0.072-2.979-0.053-4.474
		c-0.018-0.212-0.033-0.422-0.057-0.633c-0.024-0.227-0.054-0.454-0.086-0.681c-0.034-0.257-0.072-0.514-0.115-0.771
		c-0.034-0.2-0.069-0.403-0.108-0.605c-0.059-0.303-0.126-0.606-0.195-0.91c-0.039-0.166-0.074-0.331-0.117-0.498
		c-0.12-0.47-0.253-0.941-0.408-1.416c0-0.006,0-0.013,0-0.019c0-0.08,0-0.16,0-0.24c0-0.016-0.002-0.029-0.003-0.045
		c0.363-3.866-1.206-5.8-1.441-9.067c2.458,0.469,9.146,0.222,8.882-1.68c-0.332-2.396-7.789-0.19-9.12-1.2c0-4.79,0-9.58,0-14.369
		c0.001-0.013,0.002-0.026,0.003-0.038c0-0.238,0-0.479,0-0.721c0-0.002,0-0.003,0-0.005c0.146-0.204,0.215-0.488,0.247-0.81
		c0.003-0.036,0.014-0.068,0.017-0.108c0.007-0.107,0.004-0.229,0.004-0.342c0-0.085,0.005-0.164,0.002-0.252
		c-0.001-0.042-0.004-0.086-0.006-0.127c-0.04-0.998-0.188-2.107,0.211-2.665c-0.156-0.003-0.319,0-0.475-0.003
		c0-0.003,0-0.005,0-0.008c0.154,0.004,0.319,0.002,0.479,0.004c0.014,0,0.025,0,0.037,0c0.001,0,0.003,0,0.004,0
		c5.905,0.08,12.93-0.47,18.92-0.964c-0.123,0.165-0.258,0.319-0.4,0.464c-0.042,0.044-0.094,0.078-0.138,0.118
		c-0.104,0.1-0.212,0.196-0.325,0.284c-0.053,0.038-0.107,0.071-0.159,0.107c-0.117,0.083-0.233,0.167-0.359,0.242
		c-0.05,0.027-0.104,0.055-0.155,0.082c-0.135,0.075-0.27,0.151-0.411,0.221c-0.03,0.016-0.063,0.028-0.095,0.042
		c0.028-0.013,0.057-0.024,0.085-0.038c0.145-0.067,0.277-0.146,0.412-0.221c0.052-0.027,0.105-0.055,0.155-0.082
		c0.126-0.075,0.242-0.159,0.359-0.242c0.052-0.036,0.106-0.069,0.157-0.107c0.115-0.088,0.223-0.187,0.327-0.284
		c0.044-0.04,0.094-0.075,0.136-0.118c0.146-0.145,0.279-0.299,0.402-0.464c-5.991,0.494-13.015,1.044-18.92,0.964
		c6.005,0.798,13.072-0.656,18.438-0.482c-0.288,0.21-0.606,0.389-0.941,0.553c-0.031,0.02-0.065,0.039-0.102,0.057
		c-0.315,0.146-0.648,0.276-0.992,0.396c-0.071,0.025-0.146,0.051-0.218,0.075c-0.313,0.104-0.638,0.2-0.967,0.291
		c-2.134,0.591-4.475,0.977-6.013,2.165c0.252-0.138,0.517-0.262,0.791-0.375c-0.271,0.112-0.533,0.235-0.782,0.371
		c-0.001,0.001-0.004,0.002-0.005,0.003c-0.038,0.021-0.08,0.038-0.117,0.06c0.01,0.002,0.021,0.002,0.03,0.005
		c1.482,0.281,3.222,0.245,4.886-0.022c0.096-0.016,0.188-0.038,0.28-0.055c0.319-0.06,0.639-0.115,0.948-0.188
		c0.192-0.046,0.379-0.104,0.566-0.157c0.201-0.055,0.408-0.104,0.604-0.166c0.295-0.094,0.571-0.199,0.847-0.309
		c0.012-0.005,0.023-0.009,0.035-0.013c-0.015,0.005-0.029,0.01-0.043,0.016c-0.274,0.105-0.552,0.215-0.847,0.309
		c-0.194,0.063-0.401,0.111-0.604,0.166c-0.188,0.055-0.373,0.111-0.566,0.157c-0.312,0.073-0.631,0.131-0.948,0.188
		c-0.094,0.017-0.187,0.04-0.28,0.055c-1.664,0.27-3.402,0.306-4.886,0.021c-0.184,0.147-0.354,0.306-0.511,0.479
		c0.344-0.006,0.689-0.014,1.039-0.023c0.135-0.004,0.271-0.009,0.404-0.013c0.215-0.007,0.432-0.015,0.646-0.024
		c0.166-0.007,0.332-0.016,0.498-0.021c0.184-0.01,0.365-0.021,0.551-0.032c0.182-0.012,0.364-0.022,0.545-0.038
		c0.161-0.013,0.319-0.025,0.479-0.042c0.193-0.018,0.39-0.034,0.578-0.055c0.074-0.008,0.146-0.021,0.22-0.027
		c0.449-0.054,0.893-0.117,1.322-0.191c0.106-0.021,0.217-0.036,0.32-0.059c0.203-0.039,0.402-0.084,0.6-0.129
		c0.106-0.022,0.214-0.05,0.316-0.076c0.197-0.05,0.392-0.104,0.581-0.158c0.091-0.028,0.18-0.06,0.269-0.089
		c0.196-0.063,0.393-0.13,0.576-0.201c0.048-0.021,0.093-0.041,0.143-0.063c0.63-0.261,1.192-0.578,1.677-0.963
		c0.056-0.047,0.115-0.091,0.17-0.139c0.13-0.112,0.25-0.232,0.365-0.356c0.057-0.061,0.109-0.123,0.164-0.186
		c0.105-0.128,0.209-0.262,0.301-0.399c0.045-0.067,0.083-0.14,0.124-0.211c0.083-0.143,0.164-0.286,0.231-0.44
		c0.005-0.007,0.011-0.014,0.016-0.021c0.002-0.002,0.003-0.004,0.005-0.006c0.397-0.607,0.604-1.283,0.373-1.957
		c-0.004-0.011-0.007-0.021-0.01-0.033c0.003-0.248,0.006-0.497-0.022-0.766c-0.316-0.049-0.641-0.082-0.968-0.112
		c-0.006-0.002-0.011-0.004-0.017-0.006c-0.001,0-0.001,0-0.002,0c-0.271-0.066-0.577-0.08-0.897-0.063c-0.001,0-0.002,0-0.003,0
		c-0.006,0-0.012,0.002-0.018,0.002c-3.816-0.194-8.197,0.506-12.53,0.786c-0.071,0.005-0.146,0.009-0.217,0.014
		c-0.353,0.021-0.702,0.039-1.056,0.057c-0.129,0.006-0.258,0.011-0.386,0.016c-0.305,0.01-0.608,0.019-0.912,0.021
		c-0.152,0.003-0.305,0.005-0.456,0.006c-0.286,0-0.57-0.005-0.854-0.014c-0.159-0.003-0.317-0.005-0.478-0.011
		c-0.293-0.011-0.585-0.03-0.876-0.05c-0.141-0.01-0.282-0.016-0.423-0.027c-0.427-0.035-0.853-0.08-1.271-0.14
		c-0.055-5.014,0.007-9.915,0.24-14.64c0.669,0.702,1.253,0.734,2.159,0.96c4.662,1.149,9.235,1.763,13.681,3.12
		c4.346,1.324,8.177,3.682,12.479,4.078c0.938-2.749-2.591-3.313-4.558-4.077c-2.146-0.836-4.521-1.701-6.72-2.4
		c-4.521-1.438-13.595-4.112-17.04-2.64c0.063-5.662-0.389-12.092-0.479-16.802c1.169,0.615,2.354,1.225,3.558,1.824
		c0.147,0.073,0.294,0.147,0.441,0.222c1.243,0.616,2.504,1.228,3.793,1.822c-0.53-0.238-1.056-0.482-1.554-0.749
		c-2.31-1.227-4.254-3.034-6.711-3.355c-0.001-0.002-0.001-0.003-0.001-0.005c2.462,0.32,4.405,2.132,6.721,3.359
		c0.497,0.265,1.021,0.509,1.553,0.748c0.003,0.002,0.006,0.004,0.009,0.005c0.122,0.055,0.241,0.109,0.364,0.166
		c0.009,0.004,0.018,0.007,0.023,0.011c0.864,0.378,1.76,0.731,2.656,1.081c0.95,0.368,1.903,0.73,2.833,1.107
		c2.524,1.023,5.449,1.642,7.681,2.883c2.357,1.31,3.473,3.93,6.23,4.314c0,0.003,0.001,0.006,0.002,0.009
		c0.08-0.001,0.156,0.006,0.239,0.001c-1.311-5.975-7.978-7.138-13.439-9.358c-4.243-1.727-8.131-4.031-11.761-5.762
		c-1.184-0.563-3.597-0.701-3.359-2.64c3.901,0.259,7.684,0.639,11.762,0.72c-1.311-3.686-9.178-3.718-12.722-2.88
		c-4.596-15.114-10.021-29.398-11.723-47.406c-0.008-0.122-0.021-0.238-0.029-0.36c0-0.08,0-0.16,0-0.24
		c-0.005-0.029-0.008-0.063-0.013-0.093c-0.348-4.941-0.217-10.364-0.235-15.635c0.003-0.036,0.005-0.076,0.008-0.112
		c0-0.08,0-0.16,0-0.24c0.004-0.041,0.007-0.082,0.01-0.122c0.792-4.875,1.123-10.213,1.415-15.587
		c0.005-0.043,0.009-0.088,0.015-0.131c0-0.08,0-0.16,0-0.24c0.002-0.063,0.005-0.123,0.007-0.186
		c0.852-12.359,2.07-24.353,2.387-37.25c9.382-0.678,20.411-1.521,30-0.479c-9.217-1.599-20.819-0.266-30.233,0.24
		c0-0.002,0-0.003,0-0.005c9.415-0.505,21.021-1.839,30.24-0.24c0.078,0,0.158,0,0.238,0c13.408,2.912,25.887,6.755,42.96,6
		c0.763,5.879,2.006,11.274,2.399,17.521c0,0.08,0,0.16,0,0.238c1.572,12.511,7.29,20.871,17.28,24.961
		C404.36,535.309,404.252,535.917,404.147,536.526z M402.961,544.717c0,0.001,0,0.003,0,0.004c-0.873-0.485-2.612-0.106-2.641-1.438
		c0-0.001,0-0.001,0-0.002C400.35,544.609,402.089,544.231,402.961,544.717z M323.392,628.507c3.308,0.835,6.61,1.54,9.846,2.369
		C330.002,630.048,326.699,629.344,323.392,628.507z M345.36,632.321c-2.33-0.897-5.064-1.824-7.911-2.647
		c2.848,0.823,5.582,1.75,7.913,2.647c1.862,0.718,4.942,1.521,4.559,3.84c-0.001,0-0.001,0-0.002,0
		C350.305,633.842,347.225,633.039,345.36,632.321z M405.121,531.041c-10.196-3.497-13.806-12.502-15.36-24.96
		c-0.377-3.021-0.938-6.132-1.438-9.12c-0.502-2.992-0.213-6.239-1.92-8.641c-8.596-0.272-18.811,0.064-27.12-1.92
		c-14.223-3.397-29.229-5.736-47.76-3.119c0.202-7.16-0.479-17.277,0.479-24.48c0.05-0.751-0.161-1.762,0.24-2.16
		c0-0.002,0-0.004,0-0.005c0.512-1.405,0.481-3.351,0.719-5.03c0.001-0.002,0.001-0.003,0.002-0.005c0-0.078,0-0.158,0-0.238
		c0-0.001,0-0.003,0-0.004c0.387-0.972,0.489-2.227,0.479-3.594c0-0.001,0.001-0.003,0.001-0.004c0-0.08,0-0.16,0-0.239
		c0-0.721,0-1.438,0-2.159h0.001c0,0.72,0,1.438,0,2.159c0.541-0.181,0.094-1.348,0.238-1.922c7.312-1.729,12.949-5.13,17.762-9.358
		c0.003-0.003,0.006-0.006,0.009-0.009c1.852-1.654,3.605-3.409,5.261-5.263c0.003-0.002,0.006-0.005,0.009-0.008
		c4.874-6.486,9.099-13.623,14.642-19.438c0.007-0.007,0.012-0.015,0.019-0.021c1.779-1.487,3.053-3.481,4.54-5.261
		c2.907-3.652,6.089-7.031,8.64-11.04c0.213-0.83,0.417-1.663,0.962-2.16c0-0.08,0-0.16,0-0.24c0.23-0.01,0.185-0.295,0.238-0.48
		c0.563,0.076,0.9,0.379,1.682,0.24c0.742-0.456,1.347-1.054,1.198-2.4c-0.08,0-0.157,0-0.237,0c0,0,0,0,0-0.001
		c0.08,0,0.157,0,0.237,0c0-0.079,0-0.16,0-0.239c0.398-0.887,0.398-3.434,0-4.32c0,0,0,0,0-0.001c0-0.08,0-0.16,0-0.24
		c-0.001,0-0.001,0-0.002,0c0.152-3.274-1.36-6.156-1.68-8.16c5.044,1.355,9.589,3.211,12.72,6.48
		c-0.68,2.161-0.042,4.154-0.479,6.48c0.669,0.108-0.188-1.308,0.479-1.2c0-0.16,0-0.32,0-0.48c0.156-1.363-0.313-3.355,0.24-4.32
		c0.002-0.079,0.002-0.16,0.002-0.239c0,0,0,0,0-0.001s-0.001-0.001-0.001-0.001c-0.336-1.667-2.975-3.098-4.796-4.076
		c0-0.001-0.001-0.001-0.002-0.002c1.822,0.979,4.466,2.412,4.8,4.08c0,0.08,0,0.16,0,0.24c-0.157,1.363,0.315,3.355-0.24,4.32
		c0,0.16,0,0.32,0,0.48c-0.233,2.085-0.533,4.105-1.438,5.52c0,0.08,0,0.16,0,0.24c-0.496,1.104-0.771,2.431-1.439,3.36
		c0.067,0.388-0.227,0.413-0.238,0.72c-0.854,1.468-1.573,3.067-2.642,4.32c0,0.08,0,0.16,0,0.24
		c-1.079,1.617-2.146,3.229-3.206,4.845c-0.004,0.006-0.008,0.012-0.012,0.018c-0.919,1.4-1.832,2.804-2.738,4.21
		c-0.082,0.127-0.164,0.256-0.246,0.385c-0.848,1.318-1.688,2.646-2.527,3.98c-0.063,0.097-0.121,0.192-0.184,0.29
		c-0.832,1.328-1.656,2.671-2.479,4.025c-0.176,0.29-0.354,0.582-0.527,0.873c-0.829,1.375-1.656,2.76-2.479,4.173
		c-0.396,0.681-0.806,1.346-1.22,2c-0.146,0.231-0.301,0.46-0.449,0.688c-0.27,0.411-0.537,0.819-0.813,1.223
		c-0.191,0.278-0.389,0.556-0.585,0.832c-0.235,0.332-0.474,0.661-0.715,0.985c-0.228,0.307-0.453,0.607-0.686,0.906
		c-0.211,0.275-0.425,0.546-0.64,0.815c-0.261,0.327-0.521,0.653-0.788,0.977c-0.03,0.037-0.062,0.073-0.093,0.109
		c0.03-0.036,0.062-0.071,0.092-0.108c0.266-0.32,0.527-0.646,0.788-0.977c0.215-0.271,0.429-0.54,0.64-0.815
		c0.23-0.299,0.458-0.602,0.686-0.906c0.241-0.323,0.479-0.653,0.715-0.985c0.196-0.276,0.393-0.554,0.585-0.832
		c0.274-0.401,0.544-0.812,0.812-1.223c0.15-0.229,0.305-0.457,0.451-0.688c0.414-0.654,0.821-1.319,1.219-2
		c0.821-1.413,1.648-2.798,2.479-4.173c0.176-0.291,0.352-0.583,0.527-0.873c0.821-1.354,1.647-2.697,2.479-4.025
		c0.063-0.098,0.121-0.191,0.184-0.29c0.839-1.337,1.682-2.663,2.527-3.98c0.082-0.127,0.164-0.257,0.246-0.384
		c0.906-1.407,1.819-2.812,2.738-4.211c0.003-0.004,0.006-0.009,0.009-0.013c-0.423,0.619-0.849,1.235-1.282,1.843
		c-0.498,0.701-1.008,1.394-1.523,2.074c-0.202,0.266-0.407,0.526-0.613,0.787c-0.651,0.833-1.314,1.655-2.011,2.446
		c-0.495,1.745-1.614,2.864-2.399,4.318c-1.029,1.853-2.214,3.546-3.12,5.521c-2.464,5.297-6.259,9.263-9.84,13.438
		c-0.141,0.174-0.297,0.333-0.444,0.496c-0.011,0.011-0.021,0.021-0.03,0.031c-0.018,0.016-0.031,0.033-0.047,0.049
		c0.171-0.193,0.354-0.374,0.519-0.576c-0.954,0.773-1.815,1.641-2.607,2.579c-0.008,0.008-0.016,0.015-0.023,0.022
		c-1.396,1.281-2.851,2.51-4.351,3.703c-0.013,0.01-0.025,0.019-0.037,0.028c-0.261,0.182-0.53,0.359-0.806,0.526
		c-0.029,0.02-0.062,0.04-0.094,0.059c-0.311,0.184-0.629,0.361-0.963,0.522c-0.162,0.252-0.354,0.474-0.563,0.679
		c-0.191,0.191-0.405,0.364-0.623,0.531c-0.003,0.002-0.006,0.004-0.009,0.006c0.221-0.168,0.435-0.343,0.631-0.537
		c0.208-0.206,0.401-0.427,0.563-0.679c-0.891,0.868-2.116,1.403-2.88,2.399c0.287-0.214,0.586-0.412,0.885-0.613
		c0.024-0.016,0.049-0.032,0.072-0.049c-0.008,0.006-0.016,0.011-0.023,0.017c-0.017,0.012-0.033,0.022-0.05,0.034
		c-0.298,0.201-0.598,0.398-0.885,0.613c-0.203,0.159-0.418,0.314-0.629,0.471c-0.029,0.021-0.058,0.042-0.087,0.063
		c-1.367,0.947-2.771,1.877-4.205,2.795c-0.001,0.001-0.003,0.003-0.005,0.004c-0.006,0.004-0.012,0.008-0.018,0.012
		c-0.576,0.342-1.163,0.677-1.762,1.006c-3.621,1.988-7.564,3.71-11.505,5.238c-0.021,0.008-0.039,0.015-0.06,0.021
		c-0.639,0.247-1.273,0.489-1.911,0.729c-0.001,0-0.001,0-0.002,0.001c-0.007,0.002-0.013,0.004-0.02,0.006
		c0,0-0.001,0.001-0.002,0.001c-0.586,0.192-1.17,0.408-1.75,0.645c-0.002,0-0.003,0.001-0.005,0.002
		c-0.421,0.171-0.838,0.359-1.251,0.563c-0.205,0.101-0.409,0.204-0.611,0.313c-0.125,0.069-0.25,0.141-0.375,0.211
		c-0.339,0.198-0.675,0.405-1.004,0.64c1.923,1.53,4.622,0.098,6.479-0.48c6.396-1.99,11.612-4.943,16.8-8.16
		c6.687-4.145,13.123-9.172,18-15.118c5.013-6.112,8.435-13.496,12.721-20.642c2.075-3.459,4.865-6.407,6.96-9.6
		c2.351-3.583,4.201-7.374,6-11.04c0-0.08,0-0.16,0-0.24c0.301-0.819,0.516-1.724,0.96-2.4c-0.077-0.557,0.038-0.921,0.239-1.2
		c0.172-1.269,0.326-2.554,0.721-3.6c0-0.08,0-0.16,0-0.24c0.154-1.045-0.313-2.713,0.239-3.36c0-0.08,0-0.16,0-0.24
		c0.647-0.477,2.085,0.307,2.641,0.72c1.576,0.264,2.864,0.815,4.08,1.44c1.954,0.765,3.776,1.663,5.28,2.88
		c0.079,0,0.159,0,0.239,0c0.357,0.281,0.979,0.298,1.198,0.72c0.08,0,0.16,0,0.24,0c0.938,0.344,1.986,0.573,2.642,1.2
		c1.093,0.107,1.781,0.617,2.641,0.96c2.813,0.625,5.366,1.513,7.92,2.4c-1.67,16.628-8.725,31.21-8.88,50.64
		c-0.059,7.067-1.519,14.563-1.2,22.08c0.401,9.522-1.271,18.611-0.721,28.561c0.571,10.331,3.706,22.915,8.16,29.04
		C405.991,527.432,405.686,529.365,405.121,531.041z M477.601,712.001c0,1.681,0,3.36,0,5.04v0.001c-0.078,0-0.158,0-0.238,0
		c0,0.32,0,0.641,0,0.96c-0.157,1.763,0.315,4.157-0.239,5.521c-2.652-0.148-5.904,0.304-8.16-0.24
		c-17.826,0.094-36.066-0.229-53.521,0.24c-0.766-6.021,0.671-12.633-0.96-18.24c-0.072-0.25-0.174-0.491-0.283-0.729
		c-0.04-0.085-0.086-0.168-0.129-0.251c-0.078-0.149-0.158-0.302-0.246-0.446c-0.063-0.107-0.128-0.217-0.194-0.32
		c-0.073-0.116-0.146-0.231-0.22-0.346c-0.188-0.285-0.372-0.567-0.546-0.849c-0.002-0.005-0.004-0.01-0.006-0.014
		c0-0.001,0-0.001,0-0.001c-0.061-0.111-0.126-0.215-0.188-0.323v-0.001c-0.146-0.259-0.302-0.513-0.47-0.751
		c-0.189-0.268-0.383-0.531-0.601-0.771c-0.285-4.453,2.65-12.022,1.44-16.563c-0.229-0.857-0.782-1.487-1.555-1.865
		c0.775,0.378,1.329,1.01,1.557,1.867c1.21,4.538-1.728,12.107-1.439,16.563c0.218,0.238,0.412,0.501,0.6,0.771
		c0.166,0.235,0.316,0.486,0.464,0.742c-0.095-0.167-0.181-0.335-0.259-0.503c-0.049-0.105-0.096-0.211-0.136-0.316
		c-0.021-0.059-0.042-0.118-0.063-0.178c-0.056-0.171-0.103-0.343-0.127-0.518c-0.825-5.919,4.154-16.653-1.201-19.2
		c-0.42-2.063-1.741-3.222-2.398-5.04c-0.234-3.104-1.856-5.972-1.439-9.602c0.202-1.763,1.376-3.177,1.92-5.039
		c1.361-4.665,2.269-10.533,2.16-15.842c-0.156-7.715-2.656-15.683-2.641-24.237c0.014-7.282,0.104-14.66-0.24-22.08
		c-0.175-3.771-0.15-7.54-0.239-11.28c-0.09-3.75-0.721-7.456-0.721-11.04c0-16.209,2.963-30.288,5.761-44.16
		c0.246-0.063,0.481-0.131,0.709-0.205c0.087-0.026,0.166-0.062,0.25-0.092c0.137-0.05,0.275-0.097,0.404-0.15
		c0.093-0.038,0.179-0.081,0.269-0.122c0.113-0.051,0.229-0.101,0.338-0.153c0.089-0.045,0.173-0.096,0.26-0.144
		c0.102-0.056,0.204-0.107,0.304-0.169c0.084-0.05,0.162-0.104,0.243-0.156c0.094-0.062,0.188-0.119,0.277-0.185
		c0.076-0.054,0.147-0.111,0.224-0.167c0.09-0.068,0.182-0.135,0.268-0.205c0.067-0.055,0.132-0.113,0.197-0.17
		c0.088-0.076,0.178-0.151,0.263-0.229c0.062-0.056,0.117-0.111,0.176-0.169c0.089-0.086,0.178-0.171,0.263-0.259
		c0.052-0.053,0.102-0.105,0.15-0.162c0.09-0.096,0.182-0.189,0.27-0.291c0.042-0.048,0.083-0.097,0.125-0.146
		c0.095-0.109,0.188-0.22,0.28-0.333c0.03-0.037,0.063-0.075,0.093-0.113c0.104-0.13,0.21-0.262,0.313-0.396
		c0.012-0.015,0.021-0.026,0.03-0.04c0.589-0.765,1.166-1.586,1.809-2.438c-0.56,0.65-1.128,1.288-1.714,1.899
		c-0.054,0.058-0.107,0.11-0.164,0.168c-0.228,0.232-0.456,0.466-0.688,0.69c-0.097,0.095-0.192,0.185-0.289,0.274
		c-0.196,0.186-0.396,0.368-0.594,0.546c-0.113,0.104-0.229,0.199-0.345,0.298c-0.188,0.163-0.383,0.322-0.575,0.479
		c-0.122,0.098-0.244,0.192-0.368,0.288c-0.197,0.148-0.396,0.294-0.601,0.436c-0.123,0.087-0.246,0.176-0.371,0.259
		c-0.221,0.146-0.444,0.284-0.672,0.419c-0.108,0.066-0.219,0.139-0.331,0.2c-0.341,0.192-0.687,0.373-1.041,0.537
		c-1.702,10.604-5.072,21.933-5.76,34.32c-0.479,8.654,0.306,18.01,0.72,27.12c0.43,9.409,0.411,18.741,0.479,27.84
		c0.077,10.098,3.145,19.367,2.4,28.56c-0.284,3.509-0.899,7.473-1.922,10.803c-0.561,1.824-1.685,2.979-1.92,4.8
		c-0.813,6.3,2.3,10.618,3.841,15.356c-0.001,0-0.002-0.001-0.003-0.001c-1.541-4.738-4.651-9.059-3.84-15.357
		c0.235-1.821,1.359-2.976,1.92-4.8c1.021-3.33,1.638-7.292,1.92-10.803c0.744-9.19-2.321-18.462-2.398-28.56
		c-0.068-9.097-0.052-18.429-0.479-27.84c-0.414-9.11-1.197-18.464-0.72-27.12c0.687-12.389,4.057-23.716,5.76-34.32
		c0.354-0.164,0.7-0.345,1.041-0.537c0.112-0.063,0.223-0.134,0.331-0.2c0.228-0.135,0.451-0.271,0.672-0.419
		c0.125-0.083,0.248-0.172,0.371-0.259c0.202-0.142,0.401-0.285,0.601-0.436c0.124-0.095,0.245-0.19,0.366-0.288
		c0.194-0.156,0.389-0.315,0.577-0.479c0.115-0.099,0.229-0.194,0.345-0.298c0.198-0.178,0.396-0.358,0.594-0.546
		c0.097-0.092,0.191-0.182,0.289-0.274c0.23-0.227,0.461-0.456,0.688-0.69c0.056-0.057,0.11-0.11,0.164-0.168
		c0.589-0.615,1.161-1.256,1.722-1.91c0.001,0,0.001-0.001,0.001-0.001c0.547-0.635,1.079-1.281,1.607-1.93
		c0.001-0.001,0.002-0.002,0.003-0.003c0.247-0.305,0.493-0.604,0.737-0.906c0.23-0.286,0.464-0.57,0.692-0.854
		c0.098-0.115,0.191-0.232,0.287-0.351c0.542-0.661,1.084-1.312,1.631-1.932c2.923-3.32,5.473-7.165,8.4-10.562
		c4.437-5.139,9.316-9.638,13.68-14.88c3.329-4.001,5.401-6.331,9.842-9.601c2.019-1.486,2.949-2.896,5.278-2.642
		c0.815-0.414,1.759-0.167,2.642,0c1.404,0.048,2.723,0.006,3.118-0.957c0,0.002,0,0.003,0,0.004
		c-0.396,0.966-1.713,1.008-3.118,0.96c-0.884-0.167-1.826-0.414-2.642,0c0,0.001,0,0.001,0,0.002
		c-2.33-0.255-3.263,1.151-5.28,2.638c-4.438,3.27-6.513,5.6-9.842,9.601c-4.361,5.242-9.243,9.741-13.68,14.88
		c-2.93,3.396-5.479,7.239-8.4,10.562c-0.547,0.621-1.089,1.271-1.631,1.932c-0.096,0.116-0.189,0.232-0.287,0.351
		c-0.229,0.282-0.463,0.567-0.692,0.854c-0.244,0.302-0.49,0.604-0.737,0.906c0.005-0.006,0.011-0.014,0.018-0.02
		c0.58-0.644,1.176-1.27,1.764-1.884c0.453-0.476,0.903-0.947,1.33-1.419c3.187-3.523,5.87-7.641,8.641-10.801
		c3.042-3.471,6.343-6.69,9.359-10.08c4.561-5.123,8.65-10.909,14.16-14.642c1.221-0.777,1.966-2.032,3.601-2.397
		c0.309-0.331,1.042-0.238,1.68-0.24c0-0.001,0-0.001,0-0.002c0.879,0,1.759,0,2.638,0c0.001,0,0.001,0,0.002,0
		c0.157,0,0.317,0,0.477,0c0.001,0,0.002,0,0.003,0c0.584,0.875,2.191-0.5,2.399,0c-1.515,2.648-1.021,8.58-0.723,12.24
		c0.159-3.121-0.316-6.88,0.24-9.603c0-0.08,0-0.16,0-0.238c-0.317-2.241,2.217-2.898,1.2-5.04
		c-0.036,0.283,0.043,0.682-0.238,0.721c-0.133-0.987-0.979-1.262-2.16-1.2c-0.078,0-0.158,0-0.238,0
		c-1.435,0.156-3.494-0.308-4.543,0.233c-0.001,0-0.002,0-0.002,0c1.051-0.544,3.108-0.077,4.543-0.233
		c-1.506-0.462-4.537-0.381-5.762,0.24c-0.078,0-0.157,0-0.237,0c-2.958,1.201-4.73,3.589-7.439,5.04
		c-2.922,2.124-4.733,4.914-6.96,7.68c-4.479,5.569-9.679,10.223-14.398,15.36c-6.327,6.884-10.944,15.392-18.24,21.357
		c-1.214-0.863-2.749-1.409-3.84-2.397c0.496-0.763,0.857-2.191,0.479-3.12c-6.636-5.348-6.5-17.466-8.87-27.078
		c-0.002-0.016-0.005-0.032-0.007-0.049c0-0.08,0-0.16,0-0.24c-0.002-0.013-0.004-0.028-0.006-0.042
		c-0.229-3.983-0.294-8.13-0.238-12.396c0.001-0.013,0.002-0.027,0.004-0.04c0-0.08,0-0.16,0-0.24
		c0.001-0.013,0.002-0.026,0.003-0.038c0.752-3.611,0.708-8.018,0.714-12.375c0.002-0.021,0.003-0.044,0.005-0.065
		c-0.45-0.457,0.143-0.436,0-1.2c0-0.01,0-0.02,0-0.029c0.545-1.766,0.074-4.54,0.232-6.688c0.424-0.136,1.412,0.292,1.441-0.238
		c-0.559,0-1.114,0-1.674,0c0-0.001,0-0.003,0-0.004c0.561,0,1.118,0,1.68,0c0.08,0,0.16,0,0.24,0
		c16.646-2.953,33.778-5.85,47.761-12.722c0.48-0.236,0.96-0.494,1.446-0.767c-0.489,0.273-0.971,0.533-1.453,0.771
		c-13.98,6.872-31.115,9.769-47.763,12.722c17.786-2.616,34.776-6.026,48.483-12.72h0.001c0.116,0.284,0.255,0.544,0.479,0.72
		c-0.555-1.591,2.253-1.47,2.878-2.639c0.001,0,0.001-0.001,0.002-0.001c0.08,0,0.16,0,0.238,0c0.271-0.144,0.558-0.306,0.849-0.476
		c0.039-0.023,0.078-0.045,0.118-0.068c0.271-0.161,0.553-0.329,0.839-0.503c0.347-0.209,0.7-0.424,1.061-0.639
		c0.01-0.006,0.021-0.011,0.028-0.019c1.01-0.601,2.062-1.186,3.097-1.6c0.021-0.009,0.043-0.018,0.064-0.025
		c0.332-0.131,0.661-0.239,0.985-0.33c0.021-0.006,0.043-0.011,0.064-0.016c0.332-0.087,0.66-0.149,0.979-0.182
		c0.188-0.072,0.363-0.157,0.556-0.228c0.051,0.02,0.087,0.053,0.115,0.092c0,0.001,0.001,0.002,0.001,0.002
		c-0.028-0.039-0.063-0.072-0.114-0.092c-0.19,0.068-0.366,0.153-0.554,0.228c0.248-0.023,0.488-0.023,0.729-0.008
		c0-0.001-0.001-0.001-0.001-0.002c0.102,0.007,0.204,0.008,0.304,0.021c-0.046-0.058,0.062-0.454,0-0.479
		c-0.437-0.195-1.284,0.004-2.264,0.375c0.982-0.373,1.834-0.574,2.271-0.379c0.062,0.024-0.046,0.423,0,0.479c0,0.08,0,0.16,0,0.24
		c0.364,0.596,0.466,1.456,0.48,2.398c0.545,2.414,0.946,4.975,1.199,7.682c0.361,0.677,0.424,1.656,0.479,2.64
		c0.276,0.283,0.271,0.851,0.24,1.439c0.207,1.953,0.65,3.669,0.72,5.761c0,0.239,0,0.479,0,0.72
		c0.562,2.961,0.081,6.959,0.24,10.32c0.275,1.244,0.742,2.298,0.96,3.6c0.556,0.81,0.558,1.548,0.332,2.258
		c0.224-0.708,0.22-1.444-0.335-2.252c0,0.001,0,0.001,0,0.002c-0.218-1.302-0.685-2.354-0.96-3.6
		c-0.159-3.361,0.319-7.359-0.24-10.32c0-0.239,0-0.479,0-0.72c-0.353,4.833-0.84,11.879,1.2,15.12c0,0.079,0,0.158,0,0.237
		c1.017,2.144-1.52,2.801-1.2,5.042c0.045-0.368,0.168-0.726,0.328-1.078c-0.158,0.351-0.28,0.706-0.325,1.072
		c0,0.079,0,0.158,0,0.238c0,3.28,0,6.563,0,9.841c0.331,0.311,0.237,1.044,0.24,1.682c0,0.159,0,0.318,0,0.479
		c0.556,1.045,0.083,3.117,0.24,4.563c0.201,0.598,0.313,1.285,0.239,2.159c-0.399,0-0.802,0-1.199,0
		c-6.772,0.726-13.034,0.477-18.48,1.199c-1.468,0.531-1.384,2.615-2.16,3.841c1.02,17.636,0,40.003,0,59.278
		c0,0.37-0.004,0.734-0.009,1.094c-0.002,0.08-0.003,0.157-0.005,0.237c-0.035,2.017-0.114,3.813,0.031,5.327
		c0.001,0.011,0.002,0.021,0.003,0.031c0.021,0.217,0.049,0.427,0.08,0.631c0.003,0.021,0.007,0.042,0.011,0.063
		c0.17,1.05,0.497,1.932,1.087,2.615c0.02,0.021,0.034,0.04,0.052,0.062c0.109,0.122,0.229,0.236,0.356,0.346
		c0.021,0.021,0.05,0.04,0.074,0.061c0.134,0.107,0.273,0.214,0.43,0.308c0.001,0,0.002,0.001,0.002,0.001
		c0.34,0.206,0.729,0.379,1.177,0.514c0.097,0.028,0.194,0.054,0.294,0.078c0.234,0.06,0.475,0.115,0.737,0.156
		c3.153,3.923,8.357,9.077,12.72,13.438c1.64,1.64,5.298,3.771,4.08,6.723c0,4.96,0,9.92,0,14.88c0.559,1.123,0.083,3.275,0.24,4.8
		c0,0.32,0,0.64,0,0.96c0.555,0.807,0.084,2.636,0.24,3.84c0,0.24,0,0.48,0,0.723c0.557,1.44,0.081,3.918,0.238,5.76
		c0,0.397,0,0.8,0,1.2c0.555,0.726,0.086,2.475,0.239,3.6c0,0.24,0,0.479,0,0.72c0.549,0.411,0.088,1.832,0.24,2.643h-0.001
		c-0.152-0.809,0.309-2.229-0.24-2.641c0-0.24,0-0.479,0-0.72c-0.001,0-0.001,0-0.002,0c-0.153-1.125,0.315-2.874-0.238-3.599
		c0-0.4,0-0.802,0-1.199c-0.158-1.844,0.317-4.319-0.239-5.762c0-0.001,0-0.001,0-0.002c0-0.238,0-0.479,0-0.721
		c-0.155-1.204,0.314-3.032-0.24-3.838c0-0.32,0-0.64,0-0.96c-0.157-1.522,0.316-3.677-0.24-4.8c0,1.52,0,3.04,0,4.56
		c0,0.08,0,0.16,0,0.24c0.157,1.443-0.316,3.516,0.24,4.56c0,0.08,0,0.16,0,0.24c0.158,2.004-0.318,4.639,0.24,6.24
		c0,0.001,0,0.001,0,0.002c0,0.08,0,0.16,0,0.24c0.156,1.44-0.316,3.514,0.239,4.558c0,0.08,0,0.16,0,0.24c0.001,0,0.001,0,0.002,0
		c0.152,0.966-0.313,2.552,0.238,3.12c0,0.08,0,0.16,0,0.24c0.076,2.719,0.368,5.219,0.72,7.665c0,0.005,0,0.01,0,0.015
		c0,0.08,0,0.16,0,0.24c0.001,0,0.001,0,0.002,0c0.153,1.124-0.315,2.873,0.238,3.6c0,0.08,0,0.16,0,0.24
		c0.157,1.603-0.316,3.837,0.24,5.04c0,0.159,0,0.317,0,0.479c0.04,0,0.08,0,0.12,0c-0.04,0-0.08,0.001-0.12,0.001
		c-0.022,2.904,0.054,5.706,0.479,8.16c0,0.08,0,0.16,0,0.24c0.001,0,0.001,0,0.002,0c0.207,4.031,0.666,7.812,1.438,11.28
		c0,0.078,0,0.158,0,0.237c0.372,0.588,0.357,1.563,0.479,2.4c0.414,0.146-0.018,1.135,0.477,1.199c0,0.002,0.001,0.003,0.001,0.005
		c0.26,0.7,0.537,1.383,0.48,2.398c1.476,6.925,3.45,13.349,3.84,21.359c0.001,0.001,0.002,0.003,0.003,0.005
		c0,0.878,0,1.757,0,2.636c0,0.159,0,0.319,0,0.479c0.156,1.363-0.316,3.355,0.239,4.32c0,0.001,0,0.001,0,0.002
		c0,0.158,0,0.316,0,0.478v0.001c0,1.681,0,3.359,0,5.039c0.08,0,0.158,0,0.238,0c0-1.68,0-3.359,0-5.04
		c-0.07-0.481-0.01-1.093,0.022-1.677C477.589,710.908,477.529,711.521,477.601,712.001z M467.056,480.163
		c2.472,0.101,1.763,2.828-0.271,2.983C468.815,482.99,469.524,480.266,467.056,480.163z"/>
			<path fill="#080808" d="M302.146,231.521c-0.008-0.007-0.017-0.016-0.022-0.023c-0.366-0.367-0.835-0.89-1.3-1.262
		c-0.012-0.009-0.021-0.017-0.033-0.025c-0.068-0.055-0.141-0.106-0.209-0.153c-0.021-0.014-0.041-0.025-0.063-0.039
		c-0.061-0.038-0.119-0.072-0.178-0.102c-0.025-0.013-0.05-0.023-0.074-0.034c-0.054-0.023-0.104-0.043-0.156-0.058
		c-0.025-0.008-0.053-0.014-0.078-0.019c-0.049-0.01-0.096-0.015-0.143-0.015c-0.024,0-0.054,0-0.08,0.003
		c-0.043,0.005-0.084,0.017-0.125,0.031c-0.024,0.009-0.052,0.019-0.076,0.032c-0.039,0.021-0.074,0.051-0.11,0.082
		c-0.022,0.021-0.048,0.039-0.067,0.064c-0.035,0.04-0.068,0.091-0.101,0.143c-0.021,0.032-0.04,0.06-0.06,0.097
		c-0.031,0.065-0.062,0.146-0.09,0.227c-0.014,0.04-0.029,0.071-0.041,0.115c-0.039,0.133-0.072,0.282-0.101,0.455
		c-0.339,2.115,5.573,6.534,7.681,3.84c-0.058-0.182-0.147-0.345-0.257-0.5c-0.032-0.046-0.072-0.088-0.106-0.133
		c-0.09-0.107-0.184-0.209-0.288-0.306c-0.045-0.04-0.093-0.079-0.142-0.118c-0.125-0.102-0.26-0.199-0.402-0.292
		c-0.029-0.019-0.06-0.039-0.09-0.059c-0.688-0.426-1.518-0.772-2.229-1.176c0,0,0.001,0,0.001,0.001
		c0.162,0.129,0.322,0.261,0.481,0.392c0.606,0.497,1.211,0.995,1.882,1.429c0.005,0.002,0.009,0.006,0.013,0.009
		c0.285,0.185,0.583,0.357,0.897,0.512c-0.155,0.154-0.314,0.284-0.478,0.391c0.162-0.106,0.321-0.236,0.476-0.39
		c-0.314-0.155-0.612-0.328-0.897-0.512c-0.003-0.003-0.007-0.006-0.012-0.009c-0.67-0.434-1.273-0.933-1.881-1.429
		c-0.162-0.131-0.321-0.263-0.483-0.393l-0.005-0.003c-0.338-0.27-0.689-0.525-1.053-0.77c0,0,0,0,0-0.001
		C302.147,231.522,302.147,231.522,302.146,231.521z M299.52,230.32c0.001,0.005-0.002,0.008-0.001,0.014
		C299.518,230.328,299.521,230.325,299.52,230.32c0.198,0.047,0.372,0.116,0.554,0.179
		C299.892,230.436,299.717,230.367,299.52,230.32z"/>
			<path fill="#080808" d="M244.801,236.801c-0.073-0.088-0.157-0.162-0.243-0.234c-0.002,0.006-0.006,0.01-0.008,0.017
		c-0.615,1.721-3.172,2.019-5.39,1.487c-0.016-0.003-0.031-0.009-0.046-0.013c-0.231-0.057-0.458-0.12-0.679-0.194
		c-0.048-0.016-0.092-0.037-0.139-0.054c-0.185-0.066-0.369-0.133-0.542-0.21c-0.027-0.012-0.05-0.027-0.077-0.04
		c-0.188-0.087-0.371-0.177-0.54-0.276c-0.115-0.067-0.214-0.143-0.319-0.216c-0.071-0.049-0.15-0.095-0.216-0.146
		c-0.114-0.09-0.208-0.187-0.304-0.283c-0.042-0.042-0.093-0.079-0.13-0.122v0c0.038,0.042,0.087,0.079,0.128,0.121
		c0.096,0.096,0.19,0.193,0.304,0.283c0.065,0.052,0.145,0.097,0.216,0.146c0.105,0.073,0.204,0.148,0.319,0.216
		c0.169,0.099,0.352,0.189,0.54,0.276c0.027,0.012,0.05,0.027,0.077,0.04c0.173,0.077,0.357,0.145,0.542,0.21
		c0.047,0.017,0.091,0.038,0.139,0.054c0.221,0.074,0.448,0.138,0.679,0.194c0.016,0.004,0.03,0.01,0.046,0.013
		c2.217,0.531,4.775,0.233,5.39-1.487c0.002-0.006,0.006-0.011,0.008-0.017c0-0.002,0.002-0.004,0.003-0.006
		c-0.003,0-0.006,0-0.009,0h0c-0.1-0.083-0.209-0.157-0.323-0.221c-0.004-0.002-0.008-0.005-0.012-0.007
		c-0.116-0.063-0.239-0.118-0.368-0.165c-0.004-0.001-0.008-0.003-0.012-0.004c-0.132-0.047-0.271-0.087-0.415-0.12c0,0,0,0-0.001,0
		c-1.11-0.251-2.542-0.111-3.854-0.194c-0.025-0.001-0.051-0.002-0.077-0.004c-0.138-0.01-0.273-0.021-0.407-0.04
		c-0.045-0.005-0.092-0.009-0.136-0.015c-0.12-0.016-0.236-0.038-0.352-0.061c-0.053-0.01-0.106-0.019-0.158-0.03
		c-0.108-0.024-0.212-0.056-0.316-0.088c-0.053-0.016-0.109-0.029-0.161-0.047c-0.105-0.037-0.204-0.083-0.303-0.129
		c-0.045-0.021-0.093-0.037-0.136-0.06c-0.14-0.074-0.272-0.156-0.397-0.252c-1.066-0.106-1.786,0.134-1.68,1.2
		C236.774,238.153,243.533,240.033,244.801,236.801z"/>
			<path fill="#080808" d="M145.92,512.321c0.35,1.734,1.617,3.543,3.36,3.119C149.282,513.43,147.462,511.255,145.92,512.321z" />
		</g>
		<g id="expression_1">
			<path id="eyebrow_1" fill="#080808" d="M310.554,199.122c0.002,0,0.005-0.001,0.007-0.001c0.959-0.561,2.241-0.798,3.602-0.96h0.001
	c4.425-0.532,8.003,0.857,12.237,1.2c-2.729-0.545-6.046-1.546-9.187-1.563c3.142,0.017,6.459,1.018,9.188,1.563
	c0.742-0.024,1.331,0.108,1.68,0.48c5.252,0.667,8.227,3.609,12.717,5.039c0,0,0,0.001,0,0.001
	c-4.492-1.429-7.467-4.374-12.721-5.04c2.068,0.931,4.677,1.45,6.721,2.4c2.073,0.963,3.621,2.823,6.239,2.88
	c1.364-3.909-2.829-7.888-7.199-8.4c-1.188,0.904,0.406,2.237,0.239,2.64c-5.006-1.087-10.331-5.14-16.8-5.28
	c-9.521-0.206-18.438,2.958-21.839,9.84C301.155,205.728,305.567,200.402,310.554,199.122z M333.839,197.2c0.001,0,0.003,0,0.004,0
	c-0.666,1.951,1.753,2.079,1.439,2.88c-0.001,0-0.003,0-0.004-0.001C335.589,199.279,333.173,199.15,333.839,197.2z"/>
			<g id="lip1">
				<path fill="#080808" d="M276.72,312.4c-0.808-0.399-3.272-0.399-4.08,0c0.16,0,0.32,0,0.48,0c0,0-0.001,0-0.001,0.001
		c-0.16,0-0.319,0-0.479,0c-0.8,0-1.6,0-2.4,0c-0.729,0.15-2.065-0.306-2.399,0.239c0,0,0,0-0.001,0
		c-2.279-0.153-3.22-1.003-5.76-2.16c-0.863-0.393-4.681-2.266-4.8,0c-0.078,1.482,4.857,2.848,7.2,3.84
		c2.551,1.08,5.89,3.057,8.16,3.12c2.267,0.063,12.032-1.21,11.28-3.84C283.346,311.594,279.715,312.744,276.72,312.4z"/>
				<path fill="#080808" d="M299.279,296.321c-5.295-3.753-8.053-9.751-15.839-10.56c-0.24,0-0.48,0-0.72,0c0,0.08,0,0.159,0,0.239
		c-0.001,0-0.001,0-0.002,0c0-0.08,0-0.159,0-0.239c0.001,0,0.001,0,0.002,0c0.24,0,0.48,0,0.72,0c-0.492-0.398-2.628-0.398-3.12,0
		v0.001c-7.062,1.207-14.89-0.88-19.2,2.88c-2.36,2.059-2.954,4.836-5.28,6.96c-0.86,1.06-1.82,2.02-2.88,2.88
		c-1.09,1.31-2.949,1.851-4.32,2.88c0.112,1.118-0.472,1.541,0,2.399c6.116,0.221,12.902-2.897,18.96-4.32
		c0.035-0.033,0.094-0.06,0.15-0.087v0.001c-0.056,0.027-0.115,0.054-0.149,0.086c0.96,0,1.92,0,2.88,0c0,0-0.002-0.001-0.003-0.001
		l1.203,0l0.023,0.002c-0.008,0-0.016,0-0.023,0c0.332,0.547,1.67,0.089,2.4,0.24c0,0,0,0,0-0.001c0.173,0-1.798,0.138,0.519,0
		c0.576-0.034,6.181-0.107,11.609-0.305c4.536-0.166,11.043,1.931,11.632,2.226c2.343,1.177,5.511,1.529,9.119,1.44
		c0.492,0.397,2.628,0.397,3.12,0c0.494-0.306,1.218-0.383,1.2-1.2C305.919,300.819,302.861,298.859,299.279,296.321z"/>
			</g>
		</g>
		{/* 
		<!-- <g id="expression_2">
			<path id="eyebrow_2" fill="#080808" d="M304.561,204c0.002,0,1.688-0.865,1.689-0.866c0.959-0.561,3.677-0.967,5.037-1.129h0.001
	c4.425-0.532,8.003-0.143,12.237,0.2c-2.729-0.545-6.046-0.546-9.187-0.563c3.142,0.017,6.459,0.018,9.188,0.563
	c0.742-0.024,1.331,0.108,1.68,0.48c5.252,0.667,8.227,3.609,12.717,5.039c0,0,0,0.001,0,0.001
	c-4.492-1.429-7.467-4.374-12.721-5.04c2.068,0.931,4.677,1.45,6.721,2.4c2.073,0.963,3.621,2.823,6.239,2.88
	c1.364-3.909-2.829-7.888-7.199-8.4c-1.188,0.904,0.406,2.237,0.239,2.64c-5.006-1.087-7.205-4.471-16.8-4.28
	c-11.569,0.23-18.485-1.885-22.685,4.81C297.435,204.542,299.574,205.28,304.561,204z M330.964,200.045c0.001,0,0.003,0,0.004,0
	c-0.666,1.951,1.753,2.079,1.439,2.88c-0.001,0-0.003,0-0.004-0.001C332.714,202.124,330.298,201.995,330.964,200.045z"/>
			<g id="lip2">
				<path fill="#080808" d="M277.441,309.42c-0.808-0.399-3.272-0.399-4.08,0c0.16,0,0.32,0,0.48,0c0,0-0.001,0-0.001,0.001
		c-0.16,0-0.319,0-0.479,0c-0.8,0-1.6,0-2.4,0c-0.729,0.15-2.065-0.306-2.399,0.239c0,0,0,0-0.001,0
		c-2.279-0.153-3.22-1.003-5.76-2.16c-0.863-0.393-4.681-2.266-4.8,0c-0.078,1.482,4.857,2.848,7.2,3.84
		c2.551,1.08,5.89,3.057,8.16,3.12c2.267,0.063,12.032-1.21,11.28-3.84C284.067,308.614,280.436,309.764,277.441,309.42z"/>
				<path fill="#080808" d="M298.667,296.917c-5.75-4.917-7.44-10.347-15.227-11.156c-0.24,0-0.48,0-0.72,0c0,0.08,0,0.159,0,0.239
		c-0.001,0-0.001,0-0.002,0c0-0.08,0-0.159,0-0.239c0.001,0,0.001,0,0.002,0c0.24,0,0.48,0,0.72,0c-0.492-0.398-2.628-0.398-3.12,0
		v0.001c-7.062,1.207-14.89-0.88-19.2,2.88c-2.36,2.059-3.341,8.128-5.667,10.252c-0.86,1.06-1.82,2.02-2.88,2.88
		c-1.09,1.31-2.949,1.851-4.32,2.88c0.112,1.118-0.472,1.541,0,2.399c6.116,0.221,13.289-6.189,19.347-7.612
		c0.035-0.033,0.094-0.06,0.15-0.087v0.001c-0.056,0.027-0.115,0.054-0.149,0.086c0.96,0,1.92,0,2.88,0c0,0-0.002-0.001-0.003-0.001
		l1.203,0l0.023,0.002c-0.008,0-0.016,0-0.023,0c0.332,0.547,1.67,0.089,2.4,0.24c0,0,0,0,0-0.001c0.173,0-1.798,0.138,0.519,0
		c0.576-0.034,6.181-0.107,11.609-0.305c4.536-0.166,10.286,2.913,10.875,3.208c2.343,1.177,5.882,3.839,9.49,3.75
		c0.492,0.397,2.628,0.397,3.12,0c0.494-0.306,1.218-0.383,1.2-1.2C305.532,304.111,302.004,299.77,298.667,296.917z"/>
			</g>
		</g>
		<g id="expression_3">
			<path id="eyebrow_3" fill="#080808" d="M310.741,196.828c0.002,0,0.005,0,0.007,0c1.039-0.394,2.343-0.414,3.711-0.348h0.001
		c4.452,0.211,7.749,2.176,11.868,3.218c-2.601-0.992-5.705-2.53-8.799-3.07c3.095,0.54,6.199,2.078,8.801,3.07
		c0.735,0.1,1.294,0.328,1.576,0.753c5.067,1.531,7.512,4.927,11.702,7.083c-0.001,0-0.001,0.001-0.001,0.001
		c-4.191-2.156-6.635-5.555-11.705-7.085c1.885,1.262,4.37,2.208,6.228,3.485c1.885,1.294,3.102,3.386,5.674,3.877
		c1.995-3.628-1.478-8.249-5.702-9.481c-1.322,0.694,0.028,2.274-0.203,2.644c-4.755-1.904-9.332-6.787-15.688-8
		c-9.354-1.787-18.673-0.149-23.171,6.071C300.375,201.779,305.611,197.262,310.741,196.828z M334.021,198.806
		c0.001,0,0.003,0,0.004,0c-0.98,1.813,1.383,2.341,0.94,3.079c-0.001,0-0.003-0.001-0.003-0.001
		C335.401,201.146,333.041,200.618,334.021,198.806z"/>
			<g id="lip3">
				<path fill="#080808" d="M276.72,312.4c-0.808-0.399-3.272-0.399-4.08,0c0.16,0,0.32,0,0.48,0c0,0-0.001,0-0.001,0.001
			c-0.16,0-0.319,0-0.479,0c-0.8,0-1.6,0-2.4,0c-0.729,0.15-2.065-0.306-2.399,0.239c0,0,0,0-0.001,0
			c-2.279-0.153-3.22-1.003-5.76-2.16c-0.863-0.393-4.681-2.266-4.8,0c-0.078,1.482,4.857,2.848,7.2,3.84
			c2.551,1.08,5.89,3.057,8.16,3.12c2.267,0.063,12.032-1.21,11.28-3.84C283.346,311.594,279.715,312.744,276.72,312.4z"/>
				<path fill="#080808" d="M299.026,294.875c-5.295-3.753-7.8-8.305-15.586-9.114c-0.24,0-0.48,0-0.72,0c0,0.08,0,0.159,0,0.239
			c-0.001,0-0.001,0-0.002,0c0-0.08,0-0.159,0-0.239c0.001,0,0.001,0,0.002,0c0.24,0,0.48,0,0.72,0c-0.492-0.398-2.628-0.398-3.12,0
			v0.001c-7.062,1.207-14.89-0.88-19.2,2.88c-2.36,2.059-2.954,4.836-5.28,6.96c-0.86,1.06-2.78,1.539-3.84,2.399
			c-1.09,1.31-3.242,0.772-4.613,1.801c0.112,1.118-0.472,1.541,0,2.399c6.116,0.221,14.155-1.337,20.213-2.76
			c0.035-0.033,0.094-0.06,0.15-0.087v0.001c-0.056,0.027-0.115,0.054-0.149,0.086c0.96,0,1.92,0,2.88,0c0,0-0.002-0.001-0.003-0.001
			l1.203,0l0.023,0.002c-0.008,0-0.016,0-0.023,0c0.332,0.547,1.67,0.089,2.4,0.24c0,0,0,0,0-0.001c0.173,0-1.798,0.138,0.519,0
			c0.576-0.034,6.181-0.107,11.609-0.305c4.536-0.166,10.27,1.123,10.917,1.25c3.125,0.617,6.233,0.339,9.842,0.25
			c0.492,0.397,2.863-1.028,3.355-1.425c0.494-0.306,1.936-1.633,1.918-2.45C306.879,295.978,302.608,297.413,299.026,294.875z"/>
			</g>
		</g> --> */}
		<g id="lighting">
			<path fill="#E8E735" d="M236.88,205.84c3.13-6.308,10.237-11.011,15.12-16.8c4.835-5.732,9.148-12.484,17.52-14.88
		c-0.192,1.81-2.429,3.65-3.84,5.04c-1.707,4.533-6.075,6.404-9.12,9.6c-4.724,0.764-8.155,5.389-11.04,8.88
		C242.813,200.956,240.243,203.661,236.88,205.84z"/>
			<path fill="#E8E735" d="M304.32,207.76c-0.922,0.919-2.189,1.49-2.4,3.12c-1.549,0.371-3.066,0.773-4.079,1.68
		c0.401,1.278,1.464,1.896,1.438,3.6c-0.238,0.623-0.422,1.607,0.24,1.92c-0.265,0.697-0.218,1.704-0.479,2.4
		c-0.606,1.472-2.494,1.665-3.12,3.12c0.266,0.775,0.764,1.316,1.921,1.2c-1.8,7-3.596,14.004-7.44,18.96
		c-0.233,2.555,0.396,4.246,0.961,6c0.252,3.107,2.099,4.623,3.119,6.96c0,0.08,0,0.16,0,0.24c0.75,3.028-1.847,4.468-2.879,6.48
		c-2.481-3.838-5.689-6.951-10.081-8.88c-0.08,0-0.16,0-0.24,0c-5.575-1.954-8.79,11.528-12,5.76c2.95-4.418,7.393-8.226,13.68-8.88
		c2.209-0.23,3.635,0.077,4.8-0.96c0.427-3.835-3.3-5.545-4.08-9.12c-0.321-1.474,0.191-3.443,0-5.28
		c-0.26-2.507-1.135-5.192-1.2-7.44c-0.207-7.148,2.161-13.187,5.76-17.28c4.94-1.494,9.812-8.415,15.84-4.32
		C304.094,207.347,304.389,207.373,304.32,207.76z"/>
			<path fill="#E8E735" d="M363.12,276.4c0.34-1.421,0.827-2.693,1.681-3.6c0.53,0.03,0.104,1.018,0.24,1.44
		c-0.37,0.51-0.867,0.894-1.2,1.44C363.706,276.026,363.466,276.266,363.12,276.4z"/>
			<path fill="#E8E735" d="M470.881,534.161c-0.323-4.323,1.754-6.246,3.84-8.16c0.154,0.966-0.312,2.552,0.24,3.12
		c0,0.08,0,0.16,0,0.24c0.156,1.283-0.313,3.192,0.24,4.079c0,0.078,0,0.158,0,0.238c0.157,1.604-0.317,3.837,0.239,5.04
		c0,0.16,0,0.32,0,0.48c0.157,3.12-0.318,6.879,0.238,9.6c0,0.08,0,0.16,0,0.24c0.034,0.926-0.188,2.105,0.48,2.398
		c0,0.079,0,0.159,0,0.239c0,0.32,0,0.643,0,0.96c-1.665-1.056-3.394-2.049-4.32-3.84c-0.159-3.041,0.319-6.719-0.24-9.359
		C471.577,537.465,471.103,535.938,470.881,534.161z"/>
			<path fill="#E8E735" d="M468.961,580.001c2.843,1.957,4.428,5.172,6.72,7.68c-0.305,2.256-0.727,4.396-0.96,6.723
		c0,0.077,0,0.158,0,0.237c-0.521-0.04-0.106,0.854-0.238,1.2c-2.515-1.888-4.592-4.21-6.96-6.24c0-0.237,0-0.479,0-0.72
		c0.341-1.26,0.713-2.486,0.721-4.08c0.363-0.596,0.463-1.456,0.479-2.397C469.146,581.947,468.896,580.815,468.961,580.001z"/>
			<path fill="#E8E735" d="M366.001,131.2c0.448,0.511,0.392,1.528,0.96,1.92c-0.103,0.501,0.164,0.635,0.239,0.96
		c-0.061,1.019,0.628,1.292,0.479,2.4c1.137,4.385,1.962,9.079,2.88,13.68c0.427,0.615,0.18,1.903,0.722,2.4c0,0.08,0,0.16,0,0.24
		c0,0.4,0,0.8,0,1.2c0.438,2.604,0.679,5.402,1.438,7.68c0,0.08,0,0.16,0,0.24c0.15,2.49,0.959,4.321,0.96,6.96
		c0.53-0.03,0.104-1.018,0.24-1.44c0.512,1.648,0.126,4.194,0.72,5.76c0,1.12,0,2.24,0,3.36c-4.309-6.651-7.771-14.146-14.398-18.48
		c1.05-11.955,0.615-25.868-2.16-35.52c1.869,1.173,4.484,3.675,5.521,6.48C364.599,129.563,365.565,130.115,366.001,131.2z"/>
			<path fill="#E8E735" d="M372.001,211.12c-1.736,0.056-2.701-0.659-3.841-1.2c-3.479-2.281-6.503-5.017-9.84-7.44
		c0.816,5.427,5.589,8.968,10.8,10.8c0.557-0.077,0.922,0.039,1.2,0.24c1.822,0.607,3.357,0.017,4.08,1.68c0,0.08,0,0.16,0,0.24
		c-0.039,0.279,0.053,0.427,0.238,0.48c-1.591,0.467-3.596-0.722-5.521-0.72c-0.171,0.652,1.132-0.172,0.96,0.48
		c-0.911-0.048-1.707-0.212-2.399-0.48c-1.88-1.08-6.095,0.174-7.198-1.68c-0.517,0.377-0.275,2.02,0.238,2.16c0,0.08,0,0.16,0,0.24
		c1.409,2.59,3.363,4.637,6.479,5.52c0.08,0,0.16,0,0.24,0c0.811,0.469,1.976,0.584,3.118,0.72c-1.818,0.675-3.018,4.348-4.56,4.56
		c-0.344,0.047-3.331-1.923-3.601-2.16c-0.604-0.53-0.829-1.314-1.439-1.92c-0.753-0.746-2.036-1.16-2.641-1.92
		c-2.895-3.64-1.688-10.302-2.399-14.16c-0.478-2.58-2.454-4.75-0.96-7.44c-0.399-1.521-1.742-2.098-2.4-3.36
		c1.403-0.359,3.021,1.137,4.802,1.2c0.08,0,0.158,0,0.238,0c2.062,0.821,4.031,1.729,6.96,1.68c0.438-0.555-0.493-1.282-1.198-1.44
		c-0.757,0.115-0.938-0.342-1.439-0.48c-3.22-1.661-7.49-2.271-10.08-4.56c1.376-11.904,7.981-18.578,8.399-31.44
		c5.734,6.503,10.976,13.504,14.398,22.32c0,0.08,0,0.16,0,0.24c0,1.52,0,3.04,0,4.56c-2.067-2.686-4.15-5.732-6.479-8.4
		c-2.255-2.585-4.901-5.332-8.399-6c1.454,3.138,4.299,5.508,6.479,8.16c2.256,2.743,4.465,5.61,5.761,8.64
		c-1.368-0.472-2.598-1.083-4.32-1.2c-0.825-0.375-1.837-0.563-3.12-0.48c-0.763-0.59-2.501-0.11-3.358,0
		c0.145,1.139,5.603,3.209,4.318,2.16c0.563,0.079,0.991,0.289,1.44,0.48c0.036,0.444,0.813,0.147,0.96,0.48
		c2.112,1.087,5.014,1.386,6.239,3.36c-0.498,0.862-0.124,2.596-0.239,3.84c0,0.16,0,0.32,0,0.48c-0.421,1.74-0.504,3.816-0.479,6
		c0,0.08,0,0.16,0,0.24C372.688,207.166,373.758,210.558,372.001,211.12z"/>
			<path fill="#E8E735" d="M351.601,201.04c-0.396,0.458-1.018-0.889-1.438-1.2C350.557,199.382,351.178,200.729,351.601,201.04z" />
			<path fill="#E8E735" d="M372.24,218.56c0.601,0.29,2.2,0.826,2.88,0.24c0.16,0,0.32,0,0.479,0c0.201,0.278,0.316,0.644,0.24,1.2
		c-0.24,0-0.479,0-0.721,0c-2.833,1.138-6.646-1.217-9.602-2.16c1.7-0.735,3.476,0.523,5.28,0.48
		C371.393,218.289,371.957,218.284,372.24,218.56z"/>
			<path fill="#E8E735" d="M361.2,250.24c0.208,2.354,2.27,3.999,2.641,6.24c0.803,4.855-2.363,8.691-3.601,12.24
		c0.346,0.134,0.586,0.375,0.721,0.72c3.79-0.29,3.845-4.315,5.279-6.96c0.777,0.139,1.117-0.164,1.681-0.24
		c1.985-2.974,5.24-4.68,6.239-8.64c0-0.08,0-0.16,0-0.24c0.396-0.414,0.396-2.466,0-2.88c0-0.08,0-0.16,0-0.24
		c0.102-1.701-0.187-3.015-0.479-4.32c-0.475-2.886-0.71-6.01-1.438-8.64c0.117-0.284,0.256-0.544,0.479-0.72
		c0.66,0.859,0.727,2.315,1.439,3.12c0.527,1.073,0.813,2.386,1.92,2.88c0,0.08,0,0.16,0,0.24c2.56-0.352,0.902-3.567,0.479-5.04
		c0.56-1.442,0.082-3.918,0.24-5.76c0.595-1.645,2.687-1.795,2.399-4.32c-2.034-2.154-3.518,0.853-4.8,2.4
		c-3.301,3.975-7.604,8.376-9.842,11.76c-0.973,0.602-0.229-0.508-0.238-0.48c0.502-1.714,2.029-4.676,4.08-7.68
		c2.172-3.185,8.729-6.804,5.521-9.84c0.271-0.45,0.93-0.51,1.199-0.96c0.08,0,0.16,0,0.24,0c0.573-0.146,1.095-0.345,1.92-0.24
		c3.508,2.573,3.423,8.737,3.84,14.4c-0.104,1.096,0.243,2.643-0.239,3.36c-0.801,2.159-2.015,3.905-2.88,6c0,0.08,0,0.16,0,0.24
		c-0.756,1.008-0.62,4.176-0.24,5.52c0,0.32,0,0.64,0,0.96c-1.494,5.466-5.063,8.857-6.72,14.16c0,0.16,0,0.32,0,0.479
		c-0.615,0.585-0.325,2.075-0.961,2.64c-0.415-0.44-1.199,2.759-1.439,4.08c0.031,0.591,0.036,1.156-0.238,1.44
		c-1.638,4.764-5.937,6.865-10.32,8.88c0-0.08,0-0.16,0-0.24c-0.492-0.515-1.143-0.125-1.199,0.48c-0.263-0.514,0.177-2.375,0-3.6
		c0-0.08,0-0.16,0-0.24c0.559-1.283,0.082-3.598,0.239-5.28c0-0.08,0-0.16,0-0.24c0.542-0.179,0.095-1.346,0.24-1.92
		c0.158,0,0.318,0,0.479,0c0.34-2.625,1.262-3.905,1.92-6c1.154-3.677,3.105-10.653,2.399-14.4
		C361.98,252.403,360.313,250.988,361.2,250.24z M360.721,278.081c-0.08,0-0.16,0-0.238,0c-0.088,1.126,0.438,1.641,0.96,2.16
		c1.604-0.129,1.552-0.455,3.358-0.24c2.483-1.013,5.335-9.282,1.682-10.32c-3.568-1.013-5.96,4.364-5.762,7.44
		C360.721,277.44,360.721,277.76,360.721,278.081z"/>
			<path fill="#E8E735" d="M379.2,390.161c0.058,1.017-0.22,1.699-0.479,2.4c-0.57,1.108-0.938,2.42-1.438,3.6c0,0.08,0,0.16,0,0.24
		c-3.351,6.65-7.057,12.943-11.76,18.238c-1.171-4.32-2.462-11.403-0.24-15.6c-0.438-2.083-0.617-4.202,0.24-6c0-0.08,0-0.16,0-0.24
		c0.231-0.007,0.187-0.294,0.238-0.48c0.563,0.076,0.9,0.379,1.682,0.24c0.742-0.456,1.347-1.054,1.198-2.4c0-0.08,0-0.16,0-0.24
		c0.398-0.887,0.398-3.433,0-4.32c0-0.08,0-0.16,0-0.24c0.152-3.274-1.36-6.156-1.68-8.16c5.044,1.355,9.589,3.211,12.72,6.48
		C379.001,385.841,379.639,387.835,379.2,390.161z"/>
			<path fill="#E8E735" d="M381.601,391.121c0.901-1.419,0.427-4.215,0.96-6c3.081,1.066,6.898,2.388,9.602,3.84
		c0.687,0.369,1.998,1.802,2.16,0.96c0.357,0.281,0.979,0.298,1.2,0.72c1.333,1.069,4.493,2.383,7.198,2.88c0.08,0,0.16,0,0.24,0
		c0.006,0.233,0.293,0.187,0.479,0.24c0.784,2.494,1.127,5.435,0.96,8.88c-11.646-2.407-25.417,1.899-31.92,7.92
		c1.575-3.701,4.943-7.692,6.72-12c0-0.078,0-0.158,0-0.238c0.68-0.921,0.995-2.206,1.438-3.36c-0.018-0.418,0.056-0.746,0.24-0.96
		c0.471-0.649,0.647-1.592,0.72-2.64C381.601,391.281,381.601,391.201,381.601,391.121z"/>
			<path fill="#E8E735" d="M468.241,506.081c1.247,0.396,2.088-0.484,3.84-0.24c0.72,0,1.438,0,2.16,0
		c0.143,0.497-0.297,1.577,0.239,1.682c0,0.398,0,0.8,0,1.198c-0.399,1.686-0.399,5.037,0,6.722c0,0.158,0,0.318,0,0.479
		c-0.375,2.349,0.521,4.275,0,6c-0.582,1.92-3.133,2.702-3.842,3.601c-0.154-1.282,0.315-3.193-0.238-4.079
		c-0.051-0.752,0.161-1.764-0.239-2.159c-0.154-0.968,0.312-2.555-0.24-3.12c0-0.16,0-0.32,0-0.48
		c-0.155-1.124,0.313-2.874-0.24-3.6c0-0.08,0-0.16,0-0.24c-0.204-1.075-0.844-1.716-1.92-1.92c-0.454-0.426-1.585-0.175-2.398-0.24
		c-0.08,0-0.159,0-0.239,0c0.008-1.048,0.088-2.168-0.48-2.64c0.057-0.346,0.719-0.084,0.722-0.479c0.08,0,0.16,0,0.238,0
		c0.729-0.149,2.067,0.309,2.4-0.238C468.205,506.365,468.228,506.228,468.241,506.081z"/>
			<path fill="#E8E735" d="M472.32,679.121c0.415,0.146-0.02,1.139,0.479,1.2c0.26,0.7,0.537,1.383,0.48,2.397
		c-0.521,0.041-0.109-0.851-0.24-1.197c0.795,8.004,3.658,16.021,4.08,25.198c0,0.159,0,0.319,0,0.479
		c0.156,1.361-0.316,3.354,0.239,4.318c0,0.16,0,0.319,0,0.479c0,1.681,0,3.36,0,5.04c0,0.32,0,0.641,0,0.96
		c-0.559,1.283-0.082,3.598-0.239,5.28c-2.641,0-5.28,0-7.92,0c-0.025-0.614-0.137-1.146-0.48-1.44
		c-2.159-7.521-5.172-14.188-8.16-20.88c0.478-6.729,0.347-14.46-0.479-20.64c0-0.801,0-1.603,0-2.4
		c0.421-1.019,1.178-1.701,1.438-2.88c0.205-0.676,0.775-0.984,0.723-1.92c0.538-0.263,0.421-1.178,0.96-1.439
		c-0.912-6.769-1.523-13.838-2.16-20.881c0-0.16,0-0.318,0-0.479c-1.005-7.638-0.438-16.846-0.96-24.961c0-1.521,0-3.039,0-4.562
		c-0.063-10.337-0.089-20.711-0.721-30.479c2.658,2.699,5.646,5.072,8.158,7.921c0.16,7.119-0.317,14.88,0.24,21.6
		c0,0.08,0,0.16,0,0.24c0.157,1.442-0.314,3.516,0.24,4.56c0,0.08,0,0.16,0,0.24c0.158,2.002-0.318,4.638,0.24,6.24
		c0,0.08,0,0.159,0,0.239c0.154,1.441-0.316,3.517,0.237,4.561c0,0.08,0,0.16,0,0.24c0.154,0.966-0.312,2.552,0.24,3.119
		c0,0.08,0,0.159,0,0.239c0.076,2.726,0.369,5.23,0.721,7.682c0,0.08,0,0.158,0,0.238c0.154,1.124-0.313,2.873,0.239,3.602
		c0,0.08,0,0.16,0,0.24c0.157,1.603-0.314,3.837,0.24,5.039c0,0.159,0,0.318,0,0.479c-0.024,2.904,0.054,5.706,0.479,8.16
		c0,0.08,0,0.16,0,0.239c0.209,4.03,0.668,7.813,1.439,11.279c0,0.08,0,0.16,0,0.239
		C471.808,677.715,471.907,678.575,472.32,679.121z"/>
			<path fill="#E8E735" d="M319.921,142.24c-0.333,0.547-1.671,0.089-2.398,0.24c-1.574-0.049-4.229,0.087-4.562,0.48
		c0.399,0,0.8,0,1.199,0c-0.179,0.542-1.346,0.094-1.92,0.24c-5.425-0.945-5.826,3.134-7.2,6.24c0,0.08,0,0.16,0,0.24
		c-0.877,1.283-1.289,3.031-1.681,4.8c0,0.08,0,0.16,0,0.24c-2.416,5.104-4.478,10.563-6.719,15.84c0,0.08,0,0.16,0,0.24
		c-6.433,11.169-17.167,18.035-28.082,24.72c-0.08,0-0.16,0-0.24,0c-1.305,0.14-2.563,1.591-3.84,0.96
		c3.184-2.749,7.405-4.186,9.6-7.92c0.313-0.533,0.133-1.361,0.48-1.92c1.131-1.825,3.008-3.321,4.32-5.28
		c1.256-1.876,2.462-4.096,3.6-6.24c4.737-8.93,8.205-17.038,13.201-25.92c1.852-3.291,5.521-9.991,8.398-11.28
		c2.558-1.145,5.995-0.857,8.881-1.2c1.039,1.705,3.123,0.446,4.562,0.72c1.572,0.301,2.116,1.958,3.6,1.92c0.08,0,0.16,0,0.24,0
		c0,0.08,0,0.16,0,0.24c0.731,0.173,0.875-0.246,1.438-0.24c0.688,0.515,1.12,1.28,1.199,2.4c-0.808,0.152-2.229-0.31-2.64,0.24
		c-0.24,0-0.479,0-0.722,0C318.931,141.813,317.285,142.328,319.921,142.24z M293.279,162.16c-3.108,4.269-6.433,10.497-7.919,14.64
		c5.125-5.278,10.394-11.684,12.72-18.96C296.165,158,294.523,160.453,293.279,162.16z"/>
			<path fill="#E8E735" d="M350.881,231.28c0.529,0.03,0.104,1.018,0.239,1.438c-0.061,1.739,0.017,3.343,0.721,4.32
		c0,0.08,0,0.16,0,0.24c0.253,1.187,0.506,2.374,0.96,3.36c0.276,0.284,0.271,0.849,0.24,1.44c0.253,1.907,0.46,3.859,0.96,5.52
		c0.473,0.167-0.06,1.339,0.479,1.44c0,0.08,0,0.16,0,0.24c-0.077,1.837,0.313,3.207,1.438,3.84c0.702,0.063,0.737-0.542,1.439-0.48
		c0.363,6.947-1.305,13.068-0.722,19.44c-2.244-0.404-1.961,1.719-1.92,3.6c0,0.16,0,0.32,0,0.48
		c-0.558,1.283-0.082,3.598-0.24,5.28c-0.148,0.729,0.31,2.067-0.238,2.4c0-0.64,0-1.28,0-1.92c-0.557,0.805-0.084,2.636-0.239,3.84
		c0,0.24,0,0.48,0,0.72c-0.056,2.025-0.722,3.439-0.721,5.52c0,0.08,0,0.16,0,0.24c-0.968,2.793-1.779,5.741-2.88,8.4
		c0,0.08,0,0.16,0,0.24c-1.015,2.76-2.655,4.944-4.08,7.44c-1.42,2.489-2.203,5.339-3.602,7.68c-1.953,3.274-5.199,7.078-8.16,9.84
		c-5.643,5.264-11.666,9.152-18.238,13.2c-0.08,0-0.16,0-0.24,0c-0.956,0.192-2.186,1.423-2.399-0.24
		c3.622-1.015,5.402-4.709,7.92-7.2c0.586-0.58,1.608-0.848,2.16-1.44c3.715-3.988,6.663-9.93,9.359-16.08
		c2.606-5.948,5.458-12.702,6.479-18.96c1.059-6.474,1.921-11.208,1.921-18.48c0-2.169,0.492-4.677-0.721-6.24
		c-3.15,0.937-8.125,3.167-10.319-0.24c5.188-9.771,9.956-19.964,14.399-30.48c-2.849-4.032-2.28-11.48-2.399-18.24
		c-1.694,1.196-5.327,2.253-6.96,0.24c2.069,0.071,3.916-0.083,3.601-2.4c-1.172-1.068-4.131-0.349-5.279-1.44
		c-0.315-4.029,3.283-4.249,5.279-5.76c3.99-3.02,3.376-8.665,6.96-12.48c1.761,2.08,3.622,4.059,5.28,6.24
		c-0.865,1.535-0.325,4.474-0.722,6.48c0,0.16,0,0.32,0,0.48c-0.557,1.203-0.083,3.438-0.238,5.04c0,0.4,0,0.8,0,1.2
		c-0.399,2.642-0.399,6.958,0,9.6c0,0.08,0,0.16,0,0.24c-0.03,1.31,0.001,2.56,0.479,3.36
		C350.881,231.92,350.881,231.6,350.881,231.28z M343.921,258.16c-0.996,2.855-4.082,4.864-4.08,7.92
		c2.959-1.102,4.503-4.651,6-7.44c1.446-2.695,4.386-5.795,3.359-9.12c-1.079-0.53-2.478-0.132-2.642,0.96c0,0.08,0,0.16,0,0.24
		c-1.042,2.238-1.544,5.016-2.64,7.2C343.921,258,343.921,258.081,343.921,258.16z"/>
			<path fill="#E8E735" d="M343.921,354.161c-0.045,0.204,0.093,0.226,0.239,0.24c-0.037,2.038,0.195,3.806,0.721,5.28
		c0,0.24,0,0.48,0,0.72c4.888,8.232,12.729,13.512,19.68,19.68c0.312,0.65,0.589,1.333,0.48,2.4
		c-0.323,4.947-2.874,7.379-5.04,10.32c-1.056,1.433-2.007,3.313-3.601,3.12c-2.138-0.257-4.693-6.227-5.762-7.44
		c-3.078-3.5-9.234-7.582-11.521-11.28c-6.813-2.339-7.645-11.467-8.159-21.84c-0.51-10.231-1.062-20.95,3.359-24.96
		c3.402-2.676,5.919-6.241,8.88-9.36c0,4.96,0,9.92,0,14.88c0,1.28,0,2.56,0,3.84c-0.398,0.966-0.398,3.593,0,4.56
		c0,0.24,0,0.48,0,0.72c0.152,0.966-0.313,2.552,0.24,3.12c0,0.16,0,0.32,0,0.48C343.464,350.617,343.546,352.535,343.921,354.161z"
			/>
			<path fill="#E8E735" d="M438.001,400.96c0.33-0.01,0.526,0.113,0.72,0.24c7.514,5.129,14.207,11.073,19.2,18.72
		c0,0.08,0,0.16,0,0.239c1.643,2.6,2.9,5.58,3.602,9.12c0.329,0.31,0.235,1.043,0.238,1.681c0.079,1.36,0.304,2.578,0.722,3.6
		c0,0.08,0,0.16,0,0.24c0.057,0.983,0.116,1.962,0.479,2.642c0,0.078,0,0.158,0,0.238c-0.077,1.52,0.616,2.266,0.479,3.84
		c-4.833,2.129-9.121,4.801-13.921,6.962c-0.567-5.438-2.153-10.393-3.601-15.602c-1.385-4.99-3.664-10.018-4.08-15.36
		c-0.23-2.986,0.285-6.643-0.479-9.84c-0.714-2.982-4.237-5.104-5.762-7.68C436.601,400.121,437.001,400.84,438.001,400.96z"/>
			<path fill="#E8E735" d="M309.12,457.121c0.048,0.927,0.009,1.769-0.239,2.397c-0.146,0.016-0.284,0.036-0.24,0.24
		c-0.55,0.411-0.088,1.832-0.238,2.642c0,0.08,0,0.158,0,0.238c-0.58,8.46,0.035,18.117-0.479,26.643
		c-0.56,2.161-0.081,5.356-0.24,7.92c0,0.239,0,0.479,0,0.72c-0.554,0.728-0.085,2.478-0.238,3.602
		c0.114,1.477-0.429,2.293-0.24,3.84c0,0.08,0,0.16,0,0.238c-0.383,1.857-0.477,4.007-0.479,6.24c0,0.08,0,0.16,0,0.24
		c-0.409,2.149-0.271,4.85-0.479,7.2c-0.549,0.332-0.089,1.67-0.24,2.397c0.132,1.974-0.424,3.259-0.238,5.28c0,0.08,0,0.16,0,0.24
		c-0.652-0.172,0.172,1.132-0.479,0.96c-2.524-18.702,1.728-38.278,1.68-56.88c-0.021-9.295-1.713-18.958,0.961-27.12
		c1.038,0.081,2.596-0.355,2.399,0.96c0,0.08,0,0.159,0,0.239C310.071,449.272,308.783,453.423,309.12,457.121z"/>
			<path fill="#E8E735" d="M467.041,459.28c0.207,1.953,0.65,3.669,0.72,5.761c-0.353,4.833-0.84,11.879,1.2,15.12
		c0,0.08,0,0.159,0,0.239c-0.038,0.281,0.041,0.682-0.24,0.721c-0.133-0.987-0.979-1.262-2.16-1.2c-0.078,0-0.158,0-0.238,0
		c-1.506-0.462-4.537-0.381-5.762,0.24c-0.078,0-0.158,0-0.238,0c-3.17,0.67-4.296,3.384-7.199,4.319
		c-1.348-0.894-2.86-1.62-2.4-4.319c0-0.08,0-0.16,0-0.24c0.556-0.727,0.085-2.476,0.24-3.601c0-0.239,0-0.479,0-0.722
		c0.556-0.964,0.083-2.957,0.24-4.317c0-0.16,0-0.32,0-0.48c0.549-0.411,0.088-1.832,0.239-2.64c0-0.16,0-0.32,0-0.48
		c0.556-0.964,0.084-2.957,0.238-4.318c0-0.239,0-0.479,0-0.722c0.547-0.333,0.092-1.671,0.24-2.398c0-0.159,0-0.319,0-0.479
		c0.554-0.727,0.084-2.476,0.24-3.6c0-0.08,0-0.16,0-0.24c0.552-0.568,0.086-2.154,0.239-3.12c0.216-0.703,0.216-0.736,0-1.438
		c-0.556-1.593,2.259-1.47,2.88-2.642c0.08,0,0.16,0,0.238,0c2.879-1.361,5.523-2.956,8.643-4.08c0.473,0.167-0.062,1.34,0.479,1.44
		c0,0.08,0,0.159,0,0.239c0.49,3.431,0.899,6.938,1.682,10.08c0,0.079,0,0.158,0,0.238
		C466.365,457.619,467.021,461.972,467.041,459.28z"/>
			<path fill="#E8E735" d="M467.521,495.281c-0.046,0.204,0.094,0.226,0.238,0.237c0.331,0.312,0.237,1.045,0.24,1.683
		c-0.657,2.021,0.005,4.792,0,6.96c-2.24,0-4.48,0-6.721,0c-1.665-2.81-2.027-11.431,0-14.16c0-0.08,0-0.16,0-0.24
		c0.528-0.028,0.103-1.018,0.238-1.438c-0.37-0.349-1.118-0.319-1.438-0.722c0.071-1.352,0.111-2.671-0.96-2.88
		c0.604-0.516,1.254-0.983,1.92-1.438c0.309-0.331,1.042-0.238,1.68-0.24c0.88,0,1.762,0,2.642,0c0.16,0,0.318,0,0.479,0
		c0.584,0.876,2.192-0.5,2.4,0C466.729,485.689,467.222,491.621,467.521,495.281z"/>
			<path fill="#E8E735" d="M476.881,575.441c0,1.92,0,3.84,0,5.76c-0.143,0.497,0.297,1.576-0.24,1.68
		c-0.131-0.349,0.28-1.24-0.238-1.2c-0.041,0.359,0.086,0.888-0.239,0.96c-1.043-1.876-5.258-5.318-4.801-6.479
		c1.022-0.176,1.66-0.74,1.681-1.92c0.398-0.492,0.398-2.628,0-3.12c-0.032-1.408-0.162-2.718-0.479-3.84
		c-0.157-1.685,0.315-3.997-0.24-5.28c0-0.16,0-0.32,0-0.48c-0.151-0.808,0.31-2.229-0.239-2.64c0-0.16,0-0.32,0-0.479
		c-0.155-1.125,0.313-2.874-0.24-3.603c0-0.08,0-0.16,0-0.24c1.169,0.115,2.958,1.682,4.32,2.4c0.131,0.349-0.281,1.24,0.239,1.2
		c0,1.76,0,3.52,0,5.28c0.158,2.081-0.317,4.798,0.238,6.479c0,0.08,0,0.16,0,0.24c0.157,1.521-0.314,3.676,0.24,4.8
		C476.881,575.121,476.881,575.281,476.881,575.441z"/>
		</g>
	</svg>
