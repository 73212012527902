import React from 'react'
export const Chancellor = () =>
	<svg version="1.1" className="character_svg" id="Chancellor" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		width="576px" height="828px" viewBox="0 0 576 828" enableBackground="new 0 0 576 828" space="preserve">
		<g id="eye_backing">
			<path fillRule="evenodd" clipRule="evenodd" fill="#EAEAEA" d="M269.242,204.851c-0.285,0.001-0.669,0.133-0.746-0.14
		c-0.475,0.065-0.951,0.13-1.426,0.196c-0.488,0.624-2.123,0.377-3.059,0.662c-8.343,2.189-14.367,6.14-19.071,11.092
		c0.042,0.529-0.995,0.239-1.394,0.433c0.677,4.388,4.557,2.747,9.222,1.885c4.923-0.909,8.789-0.718,11.283-2.516
		c0.685-0.687,1.779-1.064,2.149-1.99c0.9-1.021,1.721-2.103,2.462-3.245c0.578-0.769,1.144-1.546,1.339-2.605
		C269.915,207.269,271.404,204.654,269.242,204.851z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#EAEAEA" d="M204.601,222.561c-0.711-0.889-1.511-1.689-2.4-2.4
		c-0.256-0.224-0.075-0.885-0.72-0.72c-0.842-0.119-1.563-0.358-2.64-0.24c-0.08,0-0.16,0-0.24,0c-0.709,0.491-2.151,0.25-2.88,0.72
		c-3.647,0.593-7.982,0.498-12.24,0.48c-0.563,0.397-0.52,1.4-0.48,2.4c1.403,0.437,0.863,2.817,2.16,3.36
		c1.334,0.667,2.679,1.321,4.08,1.92c0.929-0.128,1.172,0.429,2.16,0.24c1.443,0.157,3.516-0.316,4.56,0.24
		c0.193,0.127,0.39,0.25,0.72,0.24c3.049-0.642,7.727-1.705,9.36-4.56C205.742,223.499,205.248,222.953,204.601,222.561z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#EAEAEA" d="M129.48,298.4c-0.59,0.45-1.645,0.436-2.4,0.72
		c-1.158,0.442-2.506,0.693-3.84,0.96c-0.955,0.405-1.946,0.773-3.12,0.96c-2.189,0.931-4.771,1.469-7.2,2.16
		c-0.59,0.45-1.645,0.436-2.4,0.72c-0.734-0.174-0.706,0.414-1.44,0.24c-0.841,0.519-2.001,0.718-3.12,0.96
		c-0.732,0.708-1.843,1.037-3.12,1.2c-0.599,0.602-1.719,0.681-2.4,1.2c-0.715,0.405-1.424,0.815-2.16,1.2
		c0.959,0.08,1.332-0.428,2.4-0.24c0.751-0.369,1.774-0.466,2.88-0.48c2.461-0.338,4.181-1.419,6.72-1.68
		c0.705-0.495,1.73-0.669,2.64-0.96c2.447-0.913,4.816-1.904,7.68-2.4c0.55-0.65,1.743-0.657,2.64-0.96
		c2.343-1.098,5.041-1.839,7.68-2.64c0.843-0.517,1.863-0.856,2.88-1.2c0.457-0.103,0.603-0.517,1.2-0.48
		c0.623-0.498,1.423-0.818,2.4-0.96c0-0.08,0-0.16,0-0.24s0-0.16,0-0.24c-1.581,0.099-2.518,0.842-4.08,0.96
		C132.26,297.821,130.766,298.006,129.48,298.4z"/>
		</g>
		<g id="eye1">
			<circle fillRule="evenodd" clipRule="evenodd" cx="281.984" cy="242.44" r="7.072" />
		</g>
		<circle id="eye2_1_" fillRule="evenodd" clipRule="evenodd" cx="219.409" cy="251.92" r="7.072" />
		<g id="body_1_">
			<g id="blue_1_">
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M370.4,394.241c0.16,0,0.32,0,0.48,0
			c1.124,0.155,2.874-0.314,3.6,0.24c0.16,0,0.32,0,0.48,0c0.808,0.152,2.229-0.309,2.64,0.24c0.599,0.202,1.286,0.314,2.16,0.24
			c0-0.08,0-0.16,0-0.24c0.32,0,0.64,0,0.96,0c-0.096-0.785-0.208-1.552-0.96-1.68c-0.001-0.241,0.194-0.286,0.24-0.48
			c-0.422-0.138-1.41,0.29-1.44-0.24c-0.16,0-0.32,0-0.479,0c-1.125-0.156-2.874,0.313-3.601-0.24
			c-3.004-0.356-6.265-0.455-9.359-0.72c-0.148,1.108,0.538,1.381,0.479,2.4c0.813,0.067,1.378,0.382,2.4,0.24
			C368.73,394.151,370.068,393.693,370.4,394.241z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M350.961,396.161c-0.438-1.618-0.688-3.829-2.16-4.32
			c0.342,3.579,2.15,7.769,1.92,12.72c0.56,0,1.12,0,1.68,0c-0.145-0.575-0.345-1.095-0.24-1.92
			C351.99,400.094,351.516,398.211,350.961,396.161z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M343.28,398.321c0.015-2.495-0.718-4.242-1.439-6
			c-0.754,0.206-2.481-0.562-2.16,0.72c0.632,3.208,1.224,6.457,1.68,9.84c0.537,0.103,0.098,1.183,0.24,1.68
			c0.691-0.029,1.571,0.131,1.92-0.24c0-0.08,0-0.16,0-0.24C342.963,402.638,343.438,400.163,343.28,398.321z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M337.041,404.561c0.72,0,1.439,0,2.159,0c0-0.88,0-1.76,0-2.64
			c-0.499-3.34-0.93-6.75-2.159-9.36C334.462,394.04,337.343,401.228,337.041,404.561z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M348.801,402.4c-0.083-3.517-1.016-6.185-1.68-9.12
			c-0.356-0.204-0.517-0.604-0.48-1.2c-0.8,0-1.6,0-2.4,0c0,0.24,0,0.48,0,0.72c0,0.08,0,0.16,0,0.24
			c0.143,2.178,0.693,3.947,0.96,6c0.81,1.351-0.464,4.784,0.721,5.76c0.239,0,0.479,0,0.72,0c0.72,0,1.44,0,2.16,0
			C348.801,404,348.801,403.201,348.801,402.4z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M383.36,433.601c-2.001-1.63-9.705-2.38-13.199-2.88
			c-0.751,0.049-1.763-0.162-2.16,0.24c0,0.08,0,0.16,0,0.24c0.396,0.258,0.396,2.141,0,2.399c2.083,1.103,6.813,1.481,9.84,2.16
			c1.251-0.051,2.276,0.123,2.64,0.96c0.48,0,0.96,0,1.44,0c0.08,0,0.16,0,0.24,0c0.846-0.354,1.141-1.26,0.96-2.641
			c0.08,0,0.159,0,0.239,0C383.301,433.994,383.439,433.664,383.36,433.601z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M224.72,411.761c0.043-0.843-0.811-0.79-0.96-1.44
			c-1.042-0.798-1.616-2.064-2.4-3.12c-1.534-2.786-3.45-5.19-4.8-8.16c-0.543-1.297-1.411-2.269-1.68-3.84
			c-0.528-1.771-1.617-3.787-1.92-5.04c-0.673-1.247-1.283-2.557-2.16-3.6c-1.32-2.84-3.144-5.177-4.56-7.92
			c-0.925-0.915-1.394-2.286-1.92-3.6c-0.589-0.771-0.97-1.75-1.44-2.64c-0.853-0.988-1.443-2.236-1.92-3.6
			c-0.387-1.214-1.104-2.097-1.68-3.12c-0.654-0.706-1.105-1.614-1.68-2.4c0-0.08,0-0.16,0-0.24c-0.791-0.729-1.745-1.294-2.64-1.92
			c-0.48,0-0.96,0-1.44,0c-0.715,0.645-1.791,0.93-2.64,1.44c-0.731-0.011-0.933,0.507-1.44,0.72
			c-0.008,1.048,0.208,1.872,0.48,2.64c0.516,3.804,1.366,7.274,1.68,11.28c0.36,4.405,1.293,8.531,1.92,12
			c0.663,1.497,0.802,3.518,1.2,5.28c0.386,3.375,1.462,6.058,1.92,9.36c0.82,2.46,1.411,5.149,1.92,7.92
			c0.367,2.192,1.036,4.083,1.44,6.239c0.741,1.9,1.403,3.878,1.92,6c0.457,1.863,1.103,3.538,1.68,5.281
			c0.525,1.154,1.089,2.271,1.44,3.6c0,0.16,0,0.32,0,0.48c1.291-1.029,1.67-2.971,2.64-4.32c0.782-1.059,1.109-2.572,1.92-3.601
			c0.625-0.815,1.349-1.53,1.68-2.64c0.734-0.785,1.208-1.832,2.16-2.4c0.55-0.25,0.667-0.933,1.2-1.199
			c0.731-0.874,2.871-1.604,4.32-0.961c1.318,0.363,2.317,1.043,3.36,1.68c1.375,0.706,2.864,1.297,3.6,2.641
			c2.615,3.697,4.945,1.314,6.48-1.68c-1.603-2.157-3.436-4.084-5.04-6.24C226.285,413.876,225.634,412.687,224.72,411.761z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M342.32,338.081c1.039,0.241,2.202,0.358,2.88,0.96
			c0.08,0,0.16,0,0.24,0c1.288,0.312,2.778,0.422,3.84,0.96c2.321,0.319,4.771,0.509,7.2,0.72c0.08,0,0.16,0,0.24,0
			c3.643,0.197,7.678,0.002,10.56,0.96c0.422,0.138,1.41-0.29,1.44,0.24c1.27-0.07,1.779,0.621,3.12,0.48
			c0.204,0.044,0.227-0.094,0.24-0.24c-1.056-0.145-1.532-0.868-2.4-1.2c-0.241,0.001-0.286-0.194-0.479-0.24
			c-1.365-0.956-2.94-1.7-4.08-2.88c-0.597-0.443-1.354-0.726-1.681-1.44c-1.562-0.679-2.559-1.921-3.6-3.12
			c-0.938-0.342-1.578-0.982-1.92-1.92c-0.146-0.014-0.284-0.036-0.24-0.24c-0.905-0.615-1.588-1.452-2.16-2.4
			c-1.371-1.429-2.656-2.944-3.6-4.8c-0.677-0.683-1.344-1.377-1.681-2.4c-0.95-1.05-1.753-2.247-2.159-3.84
			c-0.677-1.004-1.376-1.985-1.681-3.36c-0.53-0.83-1-1.72-1.2-2.88c-0.187-0.053-0.278-0.201-0.239-0.48
			c-0.825-1.735-1.743-3.377-2.4-5.28c-0.342-0.458-0.558-1.042-0.72-1.68c-0.881-1.679-1.854-3.267-2.641-5.04
			c-0.544-0.496-0.852-1.228-1.199-1.92c-0.626-0.895-1.191-1.849-1.92-2.64c-0.697-1.304-1.539-2.462-2.4-3.6
			c-0.5-0.46-1-0.92-1.2-1.68c-0.686-0.594-1.318-1.241-1.68-2.16c-0.146-0.014-0.284-0.036-0.24-0.24
			c-1.236-1.083-2.388-2.252-3.12-3.84c-1.106-1.133-2.28-2.199-3.12-3.6c-1.722-1.238-3.012-2.908-5.76-3.12
			c-0.214-0.186-0.542-0.258-0.96-0.24c0.329,1.609-0.735,1.825-0.48,3.36c-0.42,0.539-0.114,1.805-0.479,2.4c0,0.08,0,0.16,0,0.24
			c-0.514,3.727-1.235,7.245-1.92,10.8c-0.258,1.663-0.922,2.918-1.44,4.32c-0.542,1.538-1.218,2.942-1.68,4.56
			c-0.124,1.155-0.677,1.883-1.2,2.64c-0.818,1.021-1.555,2.126-2.16,3.36c-1.705,2.614-3.424,5.215-5.52,7.44
			c-0.343,0.058-0.404,0.395-0.721,0.48c0.066,3.506-2.855,4.023-3.84,6.48c-0.992,1.008-1.746,2.253-2.88,3.12
			c-0.298,0.502-0.698,0.902-1.2,1.2c-0.514,1.326-1.707,1.972-2.64,2.88c-0.94,0.66-1.821,1.379-2.399,2.4
			c-1.871,1.81-3.795,3.565-5.761,5.28c-0.08,0-0.16,0-0.24,0c-0.712,1.368-2.348,1.812-3.36,2.88
			c-2.397,1.283-4.302,3.059-6.96,4.08c-0.632,0.568-1.61,0.79-2.64,0.96c-0.08,0-0.16,0-0.24,0c-1.229,0.691-2.937,0.904-4.8,0.96
			c-0.018,0.418,0.054,0.746,0.24,0.96c1.058,1.345,1.763,4.314,1.68,6c0.626,2.014,1.054,4.226,1.68,6.24
			c0.917,3.803,1.849,7.591,1.92,12.24c0.188,5.892,1.257,10.902,1.44,16.8c0.739,4.301,0.979,9.102,1.92,13.2
			c0.425,2.295,1.069,4.371,1.68,6.48c0.738,2.542,1.387,5.173,1.92,7.92c0.38,2.18,1.041,4.079,1.68,6
			c0.483,1.437,1.08,2.761,1.44,4.32c0.308,1.852,1.095,3.226,1.68,4.8c0.941,1.379,1.433,3.207,1.92,5.04c0,0.399,0,0.8,0,1.2
			c0.904-0.057,1.016-0.904,1.92-0.961c0.333-0.547,1.67-0.089,2.4-0.239c0.391-1.93,1.152-3.488,1.68-5.28
			c-0.015-1.298,1.084-3.553,1.68-5.04c0.434-1.887,1.316-3.323,1.681-5.28c0.019-1.344,1.13-3.666,1.68-5.279
			c0.435-2.366,1.667-3.933,1.921-6.48c0.438-1.561,0.986-3.013,1.439-4.56c0.43-1.33,1.279-2.241,1.68-3.6
			c0.358-1.162,0.923-2.117,1.68-2.88c0.485-1.195,1.455-1.905,1.92-3.12c0.527-0.833,0.981-1.739,1.681-2.4
			c0.576-0.944,1.291-1.749,1.681-2.88c0.506-0.854,0.931-1.789,1.68-2.4c0.484-0.955,1.301-1.58,1.68-2.64
			c0.617-1.063,1.201-2.159,1.92-3.12c0.563-1.118,1.34-2.02,1.68-3.36c0.59-1.011,0.957-2.244,1.681-3.12
			c0.391-1.289,1.172-2.188,1.439-3.6c0.672-1.488,1.057-3.264,1.92-4.56c0.437-1.563,1.49-2.51,1.681-4.32
			c0.458-1.062,0.638-2.402,1.439-3.12c0.628-1.772,1.439-3.361,1.92-5.28c0.635-1.446,1.047-3.113,1.92-4.32
			c0.351-1.65,1.295-2.705,1.681-4.32c0.021-1.103,0.969-3.348,1.68-4.56c1.16-2.265,0.921-5.253,1.92-7.2
			c0.469-1.029,2.503-0.328,2.64,0.48c0.566,0.074,0.447,0.833,1.2,0.72c0.985,0.855,2.215,1.466,3.12,2.4
			C339.666,337.215,340.75,337.891,342.32,338.081z M290.24,422.801c-0.246,1.434-0.701,2.658-1.68,3.359
			c-0.106,0.215-0.211,0.43-0.48,0.48c-1.54,1.78-4.234-0.229-4.56-1.92c-1.058-2.347-1.151-5.956-0.72-9.12
			c-0.631-3.931,4.491-6.264,6.241-2.88c0.063,0.737,0.52,1.079,0.72,1.68c0.652,0.787,0.905,1.975,0.96,3.36
			C290.86,419.74,290.369,421.089,290.24,422.801z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M272.721,429.281c-0.379-0.902-1.136-1.426-1.44-2.4
			c-0.85-1.711-1.841-4.557-1.68-6.48c-0.542-2.338-1.058-4.702-1.68-6.96c-0.434-2.208-1.714-4.843-1.68-6.72
			c-0.661-2.059-1.395-4.045-1.92-6.24c-0.832-1.488-1.214-3.425-1.68-5.28c-0.484-2.636-1.369-4.871-1.68-7.68
			c-1.074-3.006-1.113-7.047-1.68-10.56c-0.415-1.905-1.545-3.095-2.64-4.32c-0.995,0.685-1.474,1.886-1.68,3.36
			c-0.59,1.811-1.12,3.68-1.68,5.52c-0.383,1.937-1.055,3.585-1.68,5.28c-0.655,1.666-1.336,3.304-1.68,5.28
			c-0.424,2.776-1.418,4.982-1.68,7.92c-1.284,5.436-1.591,11.849-1.44,18.72c1.294,0.226,0.939,2.101,0.96,3.6
			c0.754,0.206,1.013,0.908,1.92,0.961c1.07,1.089,2.9,1.419,3.84,2.64c0.878,0.321,1.484,0.915,2.4,1.2
			c1.513,0.566,2.761,1.398,4.08,2.16c0.632,0.248,0.808,0.951,1.44,1.199c1.085,0.755,1.867,1.813,2.64,2.881
			c0.385,1.131,1.443-0.86,2.16-0.961c1.669-1.332,5.52-0.982,7.44,0c0.768,0.271,1.592,0.488,2.64,0.48
			C275.393,431.408,273.668,430.733,272.721,429.281z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M313.28,386.801c-0.451,0.988-1.267,1.613-1.68,2.64
			c-0.662,1.098-1.257,2.264-2.16,3.12c-0.403,0.716-0.95,1.289-1.2,2.16c-0.562,0.798-0.817,1.902-1.68,2.4
			c-0.481,1.278-1.646,1.874-1.92,3.36c-0.483,1.197-1.09,2.27-1.68,3.36c-0.689,1.63-1.268,3.372-1.68,5.28
			c-0.41,1.83-0.969,3.511-1.681,5.04c-0.337,1.289-1.175,3.654-1.681,5.52c-0.486,1.753-0.835,3.645-1.68,5.04
			c-0.598,1.962-1.238,3.881-1.92,5.76c0.015,0.979-0.756,3.24-1.439,4.32c-0.295,0.905-0.484,1.916-0.48,3.12
			c1.375,0.624,2.145,1.855,3.12,2.88c0,0.08,0,0.16,0,0.24c1.142,0.938,1.753,2.407,2.399,3.84c1.043,2.953,1.982,4.556,5.041,5.52
			c1.066,0.773,2.125,1.555,2.879,2.641c-0.072,0.632,0.333,0.787,0.961,0.72c0.04-0.68,0.439-1,0.48-1.68
			c-0.07-1.673,1.373-3.905,1.68-6.001c0.564-1.754,1.263-3.377,1.68-5.279c0.033-1.41,1.184-3.774,1.68-5.52
			c1.07-2.771,1.577-6.104,1.92-9.601c0.364-2.917,0.648-5.913,1.2-8.64c0-0.08,0-0.16,0-0.24c0.026-1.814,0.106-3.573,0.48-5.04
			c0.737-10.302,1.093-20.987,1.439-31.68c-1,1-1.484,2.515-2.16,3.84C314.651,384.972,314.132,386.052,313.28,386.801z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M358.4,428.08c-0.373-1.146-0.381-2.658-0.479-4.08
			c0-0.479,0-0.959,0-1.439c-0.55-0.41-0.088-1.832-0.24-2.641c-0.545-0.254-0.092-1.508-0.24-2.159
			c-0.419-2.381-0.625-4.975-0.96-7.44c0-0.16,0-0.32,0-0.48c0.043-1.163-0.19-2.05-0.479-2.88c-0.673,0.288-1.656,0.264-2.641,0.24
			c-1.279,0-2.56,0-3.84,0c-0.24,0-0.479,0-0.72,0c0.164,4.476,0.843,8.436,0.96,12.959c0.399,3.842,0.399,9.359,0,13.201
			c0,0.719,0,1.439,0,2.159c0.808,0.152,2.229-0.31,2.64,0.24c0.32,0,0.641,0,0.96,0c0.96,0,1.92,0,2.88,0
			c0.378,0.382,1.286,0.067,1.681-0.24c-0.08,0-0.16,0-0.24,0c0.846-0.194,1.158-0.921,0.96-2.159
			C358.084,432.078,358.559,429.763,358.4,428.08z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M383.121,430.721c0-1.12,0-2.24,0-3.359c-0.32,0-0.641,0-0.96,0
			c-1.036,0.164-0.222,2.179-0.48,3.119C382.415,430.307,382.387,430.895,383.121,430.721z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M249.2,382c0.478-1.363,0.714-2.966,1.44-4.08
			c0.206-1.634,0.731-2.948,1.2-4.32c-0.009-1.529,0.841-2.199,0.72-3.84c0.203-0.997,0.329-2.071,0.72-2.88c0,0.08,0,0.16,0,0.24
			c1.402-4.904,1.476-10.172,2.4-15.84c-0.009-1.769,0.277-3.243,0.48-4.8c-2.541,0.38-3.59-0.73-5.76-0.72
			c-1.702-0.298-3.523-0.477-4.56-1.44c-1.072-0.288-1.677-1.043-2.64-1.44c-2.923-2.037-5.628-4.292-7.92-6.96
			c-0.146-0.014-0.284-0.036-0.24-0.24c-0.943-0.417-1.861-0.859-2.64-1.44c-8.972-4.948-19.624-8.216-26.88-14.88
			c-0.901-0.699-1.701-1.5-2.4-2.4c0-0.08,0-0.16,0-0.24c-0.96-0.639-1.577-1.623-2.16-2.64c-0.413-1.027-1.228-1.652-1.68-2.64
			c-0.843-0.757-1.236-1.964-1.68-3.12c-0.387-1.373-1.105-2.415-1.68-3.6c-0.618-1.305-1.655-3.461-1.68-4.56
			c-0.816-1.903-1.044-4.396-1.92-6.24c0.179,0.739-0.659,0.461-0.48,1.2c-0.43,0.93-0.939,1.78-1.68,2.4
			c-0.451,1.229-1.539,1.82-1.92,3.12c-0.342,1.498-1.087,2.592-1.68,3.84c-0.703,1.617-1.592,3.049-1.92,5.04
			c-0.511,1.889-0.97,3.83-1.68,5.52c-0.461,2.419-1.139,4.622-1.68,6.96c0.025,1.308-0.987,3.65-1.68,5.04
			c-0.445,1.075-0.957,2.083-1.44,3.12c-0.679,1.32-1.271,2.729-2.16,3.84c-0.652,1.188-1.493,2.187-1.92,3.6
			c-0.417,0.943-0.858,1.861-1.44,2.64c-0.611,1.389-1.442,2.558-1.92,4.08c-0.423,1.177-0.939,2.261-1.44,3.36
			c-0.697,1.863-1.253,3.866-1.68,6c0.815,1.384-1.035,3.436-0.24,4.8c2.151-1.449,4.011-3.188,6.24-4.56
			c0.75-1.17,2.202-1.639,3.36-2.4c3.433-1.687,6.698-3.542,10.56-4.8c1.167-0.753,2.625-1.215,4.08-1.68
			c1.18-0.34,2.133-0.907,3.12-1.44c2.414-2.899,7.03-0.84,7.2,2.64c0.421,1.259,1.163,2.198,1.44,3.6
			c0.833,1.81,1.763,4.795,1.92,6.48c0.598,1.882,1.221,3.739,1.68,5.76c0.924,1.476,1.295,3.505,1.92,5.28
			c0.332,1.908,1.157,3.323,1.68,5.04c0.818,1.182,1.347,2.653,1.68,4.32c0.597,1.643,1.08,3.4,1.68,5.04
			c0.661,1.339,1.393,2.607,1.68,4.32c0.336,1.824,1.124,3.196,1.68,4.8c0.62,0.98,0.934,2.266,1.44,3.36
			c1.265,3.935,3.09,7.31,5.28,10.32c0.84,0.84,1.551,1.809,2.16,2.88c0.032,0.928,0.974,0.947,1.2,1.68
			c1.11,0.73,1.744,1.936,2.64,2.88c1.555,1.806,2.801,3.919,4.56,5.52c0.597-0.683,2.423-0.136,2.64-1.2
			c-0.955-0.405-2.579-0.141-3.36-0.72c-0.982-0.618-1.345-1.855-1.92-2.88c-0.541-2.74-1.588-4.972-1.44-8.4
			c0.783-2.59,4.61-3.404,6.72-1.44c0.971,0.709,1.774,1.586,2.4,2.64c0.595,1.084,0.947,2.413,1.92,3.12c0,0.16,0,0.32,0,0.48
			c0.342,0.618,0.775,1.145,0.72,2.16c0.666,1.471-0.012,3.664-0.96,4.32c-0.5,0.46-1.479,0.44-1.68,1.2
			c0.563,0.396,1.396,0.523,1.68,1.199c0.546,0.334,0.93,0.831,1.44,1.201c0.307,0.013,0.333,0.307,0.72,0.239
			c-0.145,0.131,0.449,0.351,0.48,0.24c0.741-2.597-0.625-7.464-0.451-11.25c-0.15,2.333-0.029,5.056,0.018,7.461
			c-0.062-3.117-0.25-6.774,0.193-9.411c0.216-3.463,0.663-6.696,1.44-9.6c0.326-1.194,0.324-2.715,0.72-3.84
			c0.021-0.78,0.124-1.476,0.48-1.92c0.076-0.804,0.247-1.513,0.72-1.92C247.411,386.451,248.772,384.692,249.2,382z
			 M243.49,418.408c0.002-0.068,0.004-0.135,0.006-0.205C243.494,418.273,243.492,418.34,243.49,418.408z M243.503,417.133
			c0-0.108-0.001-0.219-0.002-0.329C243.502,416.914,243.503,417.025,243.503,417.133z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M355.041,404.801c0.502-1.866-0.763-4.036-0.721-6.24
			c-0.529-0.03-0.102-1.018-0.239-1.44c-0.279-2.282-0.258-4.862-2.4-5.28c-0.127,1.449,0.569,4.039,1.44,5.28
			c0.18,1.419,0.687,2.513,0.72,4.08c0.552,0.568,0.086,2.154,0.24,3.12C353.839,405.042,354.615,404.746,355.041,404.801z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#252E48" d="M380.48,400.48c0,0.08,0,0.16,0,0.24
			c0.349,0.131,1.24-0.281,1.2,0.24c1.111-0.019,0.053-1.087,0.24-1.68c-0.452-0.508-0.024-1.896-1.44-1.44
			C378.69,398.074,380.62,399.628,380.48,400.48z"/>
			</g>
			<g id="red_1_">
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M368.961,402.4c-1.644-0.363-2.283,0.277-1.92,1.92
			c-0.054,4.133,0.519,7.641,0.72,11.52c0.831,1.809-0.494,5.774,0.72,7.2c1.543,0.617,3.312,1.008,5.521,0.959
			c0.808,0.152,2.229-0.309,2.64,0.24c2.128,0.192,3.937,0.703,6.24,0.721c0.146-0.014,0.284-0.036,0.24-0.24c0-1.6,0-3.2,0-4.801
			c0-0.799,0-1.6,0-2.399c0.059-5.099-0.459-9.622-0.48-14.64c-0.808,0.151-1.23,0.689-2.4,0.479
			c-0.887-0.153-2.391,0.311-2.88-0.24c-0.08,0-0.159,0-0.239,0c-0.888-0.153-2.392,0.311-2.881-0.24c-0.159,0-0.319,0-0.479,0
			c-0.808-0.152-2.229,0.309-2.64-0.24C370.723,402.239,369.712,402.45,368.961,402.4z M373.761,412
			c0.274-1.271,2.712-1.736,3.12-0.24c0.469,1.531,0.997,3.003,1.68,4.319c0.34,0.709,1.666,1.684,0.96,2.641
			c-0.261,0.699-0.98,0.939-1.68,1.199c-0.259,0.398-2.142,0.398-2.4,0c-0.08,0-0.16,0-0.239,0
			c-0.984-0.056-1.963-0.117-2.641-0.479C368.953,417.825,373.195,414.438,373.761,412z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M88.64,534.161c-0.4,0-0.8,0-1.2,0
			c2.683,0.757,6.197,0.683,8.88,1.439c0.882-0.168,3.604,0.593,2.64,0C95.538,535.104,92.331,534.39,88.64,534.161z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M99.68,536.081c0.4,0,0.8,0,1.2,0
			C101.248,535.344,99.313,535.344,99.68,536.081z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M126.32,537.761c-4.662,0.021-9.267-0.013-13.2-0.72
			c-0.08,0-0.16,0-0.24,0c-3.674,0.232-7.092-1.067-10.32-0.48c1.27-0.069,1.779,0.621,3.12,0.48c2.687,0.434,5.287,0.953,7.44,1.92
			c1.684,0.637,3.6,1.04,5.52,1.439c1.576,0.486,2.226,0.325,3.84,0c1.98-0.26,3.38-1.1,5.28-1.439
			c1.065-0.455,2.185-0.855,3.6-0.96C130.157,537.444,127.923,537.918,126.32,537.761z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M255.92,567.281c0.016-1.104,0.682-1.559,0.96-2.4
			c0.08,0,0.16,0,0.24,0c0.826-1.174,1.634-2.366,2.16-3.84c1.598-4.56,1.746-11.739,1.44-16.8c-0.08,0-0.16,0-0.24,0
			c-0.315-0.245-0.885-0.235-1.2-0.48c0.048,3.63-0.209,8.866-1.2,12.24c-0.107,2.053-0.926,3.394-1.44,5.04
			c-0.607,1.473-1.023,3.137-2.16,4.08c-1.25,1.014-0.091,4.547,0.48,6C254.896,569.456,255.7,568.66,255.92,567.281z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M114.32,342.88c0.074-0.566,0.833-0.446,0.72-1.2
			c0.946-1.454,2.015-2.786,3.6-3.6c1.431-0.569,2.762-1.238,4.08-1.92c0.761-0.759,1.96-1.08,3.12-1.44
			c1.639-0.281,2.71-1.129,4.32-1.44c2.031-0.849,4.201-1.559,6.72-1.92c1.968-0.591,4.059-1.061,6-1.68
			c1.182-0.818,3.041-0.96,4.56-1.44c2.326-0.314,4.038-1.241,6.24-1.68c2.313-0.887,4.804-1.597,7.44-2.16
			c1.94-0.379,3.709-0.931,5.52-1.44c1.576-0.825,3.55-1.25,5.28-1.92c2.184-0.531,4.437-1.907,6.96-0.72
			c0.07-0.978,0.783-2.566,0.24-3.36c-2.351,2.609-7.842,2.078-11.76,3.12c-2.471,0.729-5.354,1.046-7.92,1.68
			c-2.405,0.236-4.126,1.154-6.48,1.44c-2.003,0.798-4.242,1.359-6.48,1.92c-2.375,0.665-4.77,1.31-6.96,2.16
			c-1.19,0.729-2.632,1.208-4.32,1.44c-1.826,0.334-3.244,1.076-5.04,1.44c-1.448,0.872-3.277,1.363-5.04,1.92
			c-5.265,1.695-10.871,3.049-12.48,8.4c-0.446,1.636-1.964,3.472-1.44,5.28C112.457,345.017,113.322,343.882,114.32,342.88z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M136.64,633.041c-2.416-0.544-5.842-0.078-7.68-1.2
			c-1.712-0.367-2.838-1.321-4.56-1.68c-1.498-0.583-2.956-1.204-4.32-1.92c-1.055-0.385-1.855-1.025-3.12-1.2
			c-1.34-1.062-4.87-1.207-3.84-3.84c0.054-0.747,1.194-0.405,1.92-0.48c1.564,0.677,3.229,1.252,4.56,2.16
			c1.156,0.205,1.725,0.996,2.88,1.2c2.23,0.729,4.433,1.487,6.72,2.16c3.991,1.452,9.406,0.796,13.68,0
			c1.777-0.543,3.417-1.224,4.8-2.16c0.848-0.112,1.314-0.606,1.92-0.96c0.979-1.021,2.265-1.735,3.36-2.64c0.08,0,0.16,0,0.24,0
			c1.057-1.424,2.498-2.462,3.84-3.601c0.754,0.113,0.634-0.646,1.2-0.72c0.803-1.117,2.01-1.83,3.12-2.64
			c0.595-0.605,1.636-0.764,2.16-1.44c0.898-0.622,1.782-1.258,2.88-1.68c0.916-0.284,1.521-0.879,2.4-1.2
			c1.131-0.949,2.548-1.611,3.84-2.4c1.047-0.233,1.729-0.832,2.64-1.2c1.491-0.748,2.871-1.608,4.56-2.159
			c1.507-0.653,3.114-1.206,4.56-1.92c0.825-0.615,1.786-1.095,2.88-1.44c1.175-0.345,1.787-1.253,2.88-1.68
			c-1.445-1.755-3.145-3.255-4.32-5.28c-0.887-0.873-1.553-1.967-1.92-3.36c-0.457-1.144-1.177-2.022-1.68-3.12
			c-0.644-1.275-1.288-2.552-1.68-4.08c-0.435-1.245-1.241-2.118-1.68-3.359c-0.903-1.337-1.453-3.027-1.92-4.8
			c-0.271-1.89-0.982-3.339-1.44-5.04c-0.334-1.753-1.147-4.014-0.48-6c1.224-0.413,2.669-0.282,2.88,0.96
			c0.509,1.647,1.147,3.912,1.44,5.04c0.852,1.389,1.103,3.377,1.68,5.04c0.416,2.169,1.378,4.158,1.92,5.52
			c0.839,1.241,1.137,3.022,2.16,4.08c0,0.08,0,0.16,0,0.24c1.627,1.333,2.915,3.006,4.56,4.319c1.418,0.662,2.966,1.194,4.8,1.44
			c-1.335-2.585-3.737-4.104-5.28-6.479c-0.9-0.62-1.336-1.704-1.92-2.641c-0.263-1.097-0.97-1.75-1.44-2.64
			c-0.715-1.046-1.294-2.226-1.92-3.36c-0.315-1.205-0.99-2.05-1.44-3.12c-0.804-1.116-1.34-2.5-1.92-3.84
			c-0.315-1.204-0.99-2.05-1.44-3.12c-0.683-1.399-1.993-3.443-1.92-4.8c-0.52-1.561-1.125-3.036-1.68-4.56
			c-0.601-2.121-1.727-4.992-1.68-6.961c-0.873-2.332-1.162-5.685-1.92-7.439c-0.498,8.027-0.336,18.489,0.72,24.96
			c0.136,4.744,1.723,9.316,1.68,13.44c0.535,2.505,0.856,5.224,1.68,7.439c0.409,1.913,1.607,4.311,1.68,5.76
			c0.445,1.636,1.006,3.154,1.68,4.561c0.755,0.957,0.787,1.946,0.24,3.12c-0.854,0.352-2.605,0.444-2.88-0.48
			c-0.239-1.041-1.133-1.427-1.2-2.64c-1.01-1.391-1.381-3.419-1.92-5.28c-0.458-1.861-0.931-3.709-1.44-5.52
			c-0.649-4.07-1.484-7.956-1.92-12.24c-0.471-5.688-1.694-10.626-1.44-17.04c-0.4-3.761-0.4-9.199,0-12.96
			c-0.004-4.004-0.207-8.207,0.72-11.28c0.058-0.422,0.413-0.547,0.96-0.48c-0.053-2.027-0.387-3.772-0.24-6c0-2,0-4,0-6
			c-1.413,0.348-2.104,1.416-3.36,1.921c-0.788,0.092-1.058,0.701-1.68,0.96c-0.547,0.893-1.683,1.197-2.4,1.92
			c-0.871,0.249-1.444,0.796-2.16,1.199c-1.324,1.076-2.799,2.002-4.56,2.641c-0.963,0.236-1.403,0.997-2.4,1.2
			c-1.081,0.919-2.407,1.593-3.84,2.159c-1.147,0.453-2.313,0.888-3.12,1.681c-1.032,0.647-2.013,1.347-3.36,1.68
			c-1.042,0.318-1.78,0.94-2.88,1.2c-1.895,1.145-4.524,1.556-6.96,2.16c-2.708,0.412-5.119,1.12-7.44,1.92
			c-1.402,0.678-3.126,1.033-4.8,1.439c-1.962,0.198-3.484,0.837-5.04,1.44c-1.452,0.788-3.191,1.289-4.8,1.92
			c-1.207,0.393-2.219,0.98-3.36,1.44c-1.162,0.758-2.593,1.247-3.84,1.92c-1.598,0.322-2.607,1.232-4.08,1.68
			c-0.493,0.787-1.644,0.916-2.4,1.44c-1.283,0.396-2.138,1.222-3.36,1.68c-0.968,0.951-2.423,1.417-3.6,2.16
			c-0.967,0.393-1.673,1.047-2.64,1.439c-0.914,0.927-2.134,1.546-3.36,2.16c-2.387,0.755-3.695,2.66-5.04,4.08
			c-1.588,1.676-4.021,2.473-4.8,4.8c-0.431,1.854-0.193,4.089,0,6c0.121,2.52,1.012,4.269,1.44,6.48
			c0.607,1.633,1.481,2.999,1.92,4.8c0.285,1.635,1.285,2.555,1.68,4.08c0.925,0.915,1.394,2.286,1.92,3.6c0,0.4,0,0.801,0,1.2
			c-0.847,2.405-4.315,0.779-4.32-1.2c-0.205-1.314-1.174-1.866-1.44-3.12c-0.986-1.014-1.527-2.472-1.92-4.079
			c-0.379-1.301-0.953-2.407-1.44-3.601c-0.787-2.493-1.506-5.054-1.92-7.92c-0.178-1.502-0.326-3.033-0.24-4.8
			c-1.473,1.327-1.826,3.774-2.64,5.76c-0.014,1.646-0.405,4.418,0.24,6.48c0.154,2.005,0.96,3.359,1.44,5.04
			c0.525,2.385-2.472,1.865-2.64,0.479c-0.205-0.915-0.713-1.526-0.96-2.399c-1.596-3.2-1.899-8.895-0.24-12.24
			c0.362-1.479,1.285-2.396,1.68-3.84c-0.008-1.209-0.706-0.238-1.68-0.48c-1.154-1.006-2.324-1.995-3.12-3.36
			c0-0.079,0-0.159,0-0.239c-1.079-1.001-1.612-2.549-2.4-3.84c0-0.08,0-0.16,0-0.24c-1.51-2.539-3.491-3.874-5.76-5.521
			c-1.317-0.956-3.528-1.78-3.6-3.84c0.343-0.058,0.405-0.396,0.72-0.479c1.44-0.161,1.76,0.799,2.88,0.96
			c2.858,1.781,5.699,3.581,7.44,6.479c0,0.08,0,0.16,0,0.24c1.951,2.688,3.443,5.837,5.76,8.16c0.076,0.557-0.039,0.921-0.24,1.2
			c1.071-0.05,0.843-1.397,1.68-1.681c0.574-0.785,1.025-1.694,1.68-2.399c0.341-1.02,1.109-1.612,1.68-2.4
			c0.417-0.782,1.199-1.201,1.44-2.16c0.811-0.949,1.193-2.326,2.16-3.12c0.417-0.782,1.199-1.201,1.44-2.16
			c-1.28-0.64-2.5-1.34-4.08-1.68c-1.714-0.526-3.393-1.087-4.8-1.92c-1.51-0.33-2.53-1.149-4.08-1.439
			c-1.459-0.222-2.541-0.82-3.6-1.44c-1.653-0.559-5.224-2.042-3.12-4.08c0.381-0.76,2.196-0.555,2.64,0
			c1.342,0.418,2.304,1.216,3.84,1.44c1.416,0.504,2.994,0.846,4.08,1.68c1.641,0.52,3.099,1.221,4.8,1.68
			c1.712,0.607,3.499,1.142,4.8,2.16c1.941,0.692,5.344,3.416,2.88,5.521c-0.734,0.785-1.208,1.831-2.16,2.399
			c-0.003,0.477-0.655,0.306-0.48,0.96c1.134-0.466,1.806-1.394,2.88-1.92c1.107-1.133,2.724-1.756,4.08-2.64
			c1.213-0.387,2.096-1.104,3.12-1.681c0.578-0.702,1.387-1.173,2.4-1.439c1.175-0.745,2.839-1,3.6-2.16
			c-1.483-0.437-3.251-0.589-5.04-0.72c-1.634-0.206-3.135-0.545-4.32-1.2c-1.786-0.774-3.86-1.26-5.76-1.92
			c-2.68-0.841-5.887-1.154-8.64-1.92c-2.07-0.304-4.811-0.86-7.44-1.2c-2.362-0.305-6.241,0.298-6.24-2.64
			c2.182-1.658,6.759-0.026,9.36,0.239c2.833,0.208,5.072,1.009,7.92,1.2c1.054-0.014,1.983,0.097,2.64,0.48c0.08,0,0.16,0,0.24,0
			c1.717,0.282,3.358,0.642,4.56,1.439c1.804,0.677,3.669,1.291,5.76,1.681c2.459,0.581,5.253,0.827,7.68,1.439
			c0.08,0,0.16,0,0.24,0c1.106,0.014,2.129,0.111,2.88,0.48c0.546,0.116,1.226,0.476,1.68,0c0.717-0.724,2.04-0.841,2.88-1.44
			c1.634-0.285,2.555-1.285,4.08-1.68c1.535-0.945,3.105-1.855,5.04-2.4c0.938-0.262,2.097-0.304,2.4-1.2
			c-2.021-0.378-3.575-1.225-5.76-1.439c-3.471-0.689-6.925-1.396-10.32-2.16c-4.16-0.32-7.735-1.225-12-1.44
			c-5.347-0.492-10.969-0.71-15.84-1.68c-3.004-0.618-10.474-0.264-8.4-3.6c0.571-0.398,2.79-0.398,3.36,0
			c4.42,0.06,7.749,1.211,12.24,1.199c0.808,0.152,2.229-0.309,2.64,0.24c0.08,0,0.16,0,0.24,0c1.865-0.185,3.048,0.313,4.8,0.24
			c-0.309-0.571-1.559-0.201-1.68-0.96c-0.367-0.764-0.084-1.056,0-1.92c3.385-0.885,8.454,0.434,12,0.96
			c4.312,0.488,8.246,1.354,12.96,1.439c8.257,0.544,16.396,1.204,25.44,0.96c0.122,0.679,1.532,0.068,1.2,1.2c0.24,0,0.48,0,0.72,0
			c0.263-0.696,0.767-1.152,1.2-1.68c1.018-0.902,1.553-2.287,2.4-3.36c0.363-2.037,1.188-3.612,1.92-5.279
			c0.444-1.477,0.958-2.882,1.44-4.32c0.188-1.495,1.16-4.278,1.92-6c0.435-1.725,1.039-3.281,1.44-5.04
			c0.123-1.72,1.15-4.607,1.68-6.72c0.662-1.241,1.061-3.795,1.68-5.761c0.811-3.35,1.242-7.078,2.16-10.319
			c0.479-3.601,0.94-7.221,1.44-10.801c-0.744-2.129,0.826-5.22,0.24-7.199c-0.48,0-0.96,0-1.44,0
			c-5.368-0.552-11.997,0.156-15.84-1.92c-1.534-0.227-2.335-1.186-3.84-1.44c-1.178-0.502-2.417-0.943-3.12-1.92
			c-0.9-0.3-1.543-0.857-2.4-1.2c-1.362-0.798-2.804-1.516-3.84-2.64c-0.622-0.259-0.892-0.868-1.68-0.96
			c-1.017-0.583-2-1.2-2.64-2.16c-0.657-0.224-1.023-0.736-1.68-0.96c-1.324-0.996-2.926-1.714-4.32-2.641
			c-0.835-0.524-1.916-0.804-2.88-1.199c-2.488-0.472-4.528-1.393-6.72-2.16c-2.013-0.442-7-2.027-4.32-4.08
			c1.057-0.569,3.208-0.175,3.84,0.479c1.667,0.493,3.222,1.099,5.04,1.44c1.704,0.616,3.799,0.841,5.04,1.92
			c1.416,0.425,2.408,1.272,3.84,1.68c1.138,0.623,2.364,1.157,3.12,2.16c2.292,1.468,4.15,3.37,6.72,4.561
			c1.235,0.765,2.545,1.455,3.6,2.399c0.671,0.369,1.354,0.727,1.92,1.2c1.414,0.506,2.694,1.146,3.84,1.92
			c1.744,0.496,3.053,1.427,5.28,1.44c4.556-0.365,9.813,1.025,13.2,0.239c-0.92-0.919-3.191-0.488-4.32-1.199
			c-0.08,0-0.16,0-0.24,0c-1.199-0.081-2.448-0.112-3.36-0.48c-3.395,0.114-5.547-1.013-8.64-1.2
			c-2.156-0.323-4.119-0.841-5.28-2.16c-0.603-0.357-1.322-0.598-1.68-1.199c-1.258-0.582-2.216-1.464-3.12-2.4
			c-0.679-0.36-1.022-1.058-1.92-1.2c-1.145-0.855-2.445-1.555-3.36-2.64c-2.012-1.429-3.844-3.036-6.24-4.08
			c-1.439-0.641-2.907-1.252-4.08-2.16c-1.345-0.334-2.104-1.256-3.6-1.439c-2.166-0.635-4.281-1.319-6.48-1.92
			c0.039-0.279-0.053-0.428-0.24-0.48c-0.145-0.945,0.354-1.246,0.72-1.68c1.089-0.361,1.968-0.156,3.12,0
			c2.197,0.283,3.883,1.076,5.52,1.92c1.29,0.391,2.188,1.172,3.6,1.439c1.231,0.77,2.875,1.125,3.84,2.16
			c2.547,1.293,4.478,3.202,6.96,4.561c1.019,0.741,2.13,1.39,2.88,2.399c1.906,1.374,3.118,3.442,5.28,4.561
			c1.429,1.131,3.448,1.672,5.52,2.159c2.813,0.547,5.518,1.203,8.88,1.2c2.476-0.367,5.733,1.687,6.96,0.24
			c0.624-0.653,0.663-2.062-0.48-2.16c-1.868-1.892-3.664-3.856-5.28-6c-1.411-1.868-2.821-3.738-4.32-5.52
			c-1.01-0.75-1.659-1.862-2.4-2.88c0.113-0.754-0.646-0.635-0.72-1.2c-1.198-1.042-1.875-2.605-2.88-3.841
			c-3.275-3.925-6.099-8.301-7.68-13.92c-0.728-1.191-1.482-2.357-1.92-3.84c-0.587-1.006-0.807-3.201,0.48-3.6
			c0.833-0.192,0.922,0.357,1.68,0.24c1.171,0.429,1.454,1.745,1.92,2.88c0.409,1.11,1.146,1.894,1.68,2.88
			c0.761,0.76,1.257,1.783,1.68,2.88c0.226,1.374,1.3,1.9,1.68,3.12c0.682,1.078,1.399,2.121,1.92,3.359
			c0.641,1.119,1.055,2.466,2.16,3.121c-0.271-1.729-0.805-3.195-0.96-5.041c-0.881-2.959-1.913-7.046-1.92-10.08
			c-0.718-3.521-1.23-7.249-1.68-11.04c-3.159-14.022,0.65-32.914-1.92-48c-0.044-6.68-2.859-15.083,0-19.68
			c0.361-1.158,0.858-2.182,1.68-2.88c0.791-0.405,1.535-0.142,2.4,0c0.318,0.912,0.596,1.331,0.24,2.4
			c-0.608,0.832-0.882,1.998-1.68,2.64c-1.247,1.458-0.475,4.644-0.24,6.72c0.206,6.834,1.99,13.369,1.68,19.92
			c0.548,4.812,0.089,10.631,0.24,15.84c-0.265,6.025-0.605,12.676-0.24,18c0.751,3.409,0.958,7.362,1.68,10.8
			c0.274,4.365,1.51,7.77,1.92,12c0.328,2.953,1.025,5.535,1.44,8.4c0.891,2.629,1.246,5.794,2.16,8.399
			c1.428,2.092,2.94,4.1,4.8,5.76c1.031,1.21,2.074,2.406,3.12,3.601c0,0.08,0,0.16,0,0.24c0.972,0.628,1.651,1.549,2.4,2.399
			c0.802-0.436,1.437-0.647,2.16,0c0.582,0.778,0.595,2.125,0.72,3.36c-0.457,0.583-1.14,0.94-1.92,1.2
			c-0.708,1.292-1.398,2.602-1.2,4.8c-0.133,4.187-0.752,7.888-1.2,11.76c0.32,0.4,1.067,0.373,1.44,0.72c0,0.08,0,0.16,0,0.24
			c-0.078,9.149,0.065,20.22,1.2,29.521c0.183,4.617,1.405,8.194,1.92,12.479c-0.165,0.966,0.23,1.37,0.24,2.16
			c1.102-0.338,1.766-1.113,2.64-1.68c1.099-1.062,2.5-1.82,3.84-2.641c1.111-0.408,1.893-1.146,2.88-1.68c0-1.6,0-3.2,0-4.8
			c-0.422-1.824,1.578-4.174,1.44-5.76c0.388,0.067,0.413-0.228,0.72-0.24c0.206-0.755,0.907-1.013,0.96-1.92
			c0.868-1.053,1.993-1.848,3.12-2.641c1.156-0.856,3.676-1.315,2.88-3.359c-0.555-0.885-0.083-2.797-0.24-4.08
			c0.028-2.027-0.115-3.885-0.48-5.521c-0.662-3.898-0.991-8.128-1.2-12.479c0-0.08,0-0.16,0-0.24
			c-0.551-0.489-0.087-1.993-0.24-2.88c0-0.16,0-0.32,0-0.48c-0.552-0.567-0.086-2.153-0.24-3.12c0-0.399,0-0.8,0-1.199
			c-0.551-0.489-0.087-1.993-0.24-2.881c0-0.8,0-1.6,0-2.399c-0.549-0.411-0.088-1.832-0.24-2.64c0-1.521,0-3.04,0-4.561
			c0.158-2.081-0.318-4.798,0.24-6.479c0.006-1.595,0.524-2.676,0.48-4.32c0-0.32,0-0.64,0-0.96c0.613-0.379,2.507-0.379,3.12,0
			c-0.2,1.32-0.345,2.694-0.72,3.84c0,0.08,0,0.16,0,0.24c-0.158,1.842,0.318,4.317-0.24,5.76c0,1.04,0,2.08,0,3.12
			c0,0.88,0,1.76,0,2.64c0,0.24,0,0.48,0,0.721c0.556,1.123,0.083,3.277,0.24,4.8c0,0.08,0,0.16,0,0.239
			c0.528,6.192,1.009,12.432,1.44,18.721c0.251,4.629,0.763,8.997,1.2,13.439c0.703-1.057,1.952-1.567,2.88-2.399
			c3.813-2.727,6.291-6.135,10.08-8.4c-0.553-0.246-0.774-0.826-1.68-0.72c-1.067-0.772-2.125-1.555-2.88-2.64
			c-0.757-0.203-0.683-1.237-1.44-1.44c-0.902-0.938-1.634-2.046-1.92-3.6c-0.271-1.489-1.136-2.384-1.44-3.841
			c-0.72-1.919-1.854-3.426-1.92-6c0-0.319,0-0.64,0-0.96c-0.045-1.404,0.493-2.227,1.2-2.88c0.573-0.707,1.051-1.509,2.16-1.68
			c2.262-1.829,7.575-1.795,10.08-0.24c0.186-0.054,0.473-0.007,0.48-0.24c-0.521,0.041-0.109-0.851-0.24-1.199
			c1.193-0.331,1.475-6.148,1.68-10.561c0.156-3.343-0.112-5.555,0.24-7.68c0.307-1.454,0.545-2.976,1.2-4.08
			c2.087-2.135,1.354-5.329,0.48-7.44c-0.563-1.517-1.201-2.958-1.68-4.56c-0.739-1.981-1.47-3.97-1.92-6.24
			c-0.343-1.896-0.847-3.633-1.44-5.28c-0.605-2.754-1.413-5.307-1.92-8.16c-0.459-2.581-0.957-5.124-1.68-7.44
			c-0.548-3.132-1.489-5.871-1.92-9.12c-0.164-2.636-1.101-4.499-1.2-7.2c-1.045-4.234-1.543-9.017-2.16-13.68
			c-0.249-3.111-0.941-5.779-1.44-8.64c-0.435-3.485-1.031-6.809-1.44-10.32c-0.89,0.149-1.405,0.674-2.16,0.96
			c-0.89,0.149-1.405,0.674-2.16,0.96c-0.985,1.015-2.504,1.495-3.6,2.4c-0.657,0.224-1.023,0.736-1.68,0.96
			c-1.051,1.029-2.428,1.732-3.6,2.64c-0.08,0-0.16,0-0.24,0c-0.709,0.97-1.586,1.773-2.64,2.4c-0.615,0.905-1.57,1.47-2.4,2.16
			c-0.945,0.772-2.337-0.054-2.64-0.72c1.5-4.23,0.792-9.291,2.16-13.44c0.197-1.803,1.043-2.957,1.44-4.56
			c0.338-1.502,1.003-2.677,1.68-3.84c0.478-1.202,1.375-1.986,1.68-3.36c0.426-1.254,0.985-2.375,1.68-3.36
			c0.477-1.043,1.377-1.663,1.68-2.88c0.575-1.185,1.088-2.433,1.92-3.36c0.476-1.284,1.434-2.086,1.68-3.6
			c-0.102-0.502,0.165-0.636,0.24-0.96c-2.046-1.304-4.232,0.702-6.24,0.96c-0.08,0-0.16,0-0.24,0c0.308,2.788-2.166,2.794-3.6,3.84
			c-1.047,0.233-1.728,0.832-2.64,1.2c-1.157,0.843-2.579,1.42-4.08,1.92c-1.497,0.022-1.955,1.085-3.36,1.2
			c-1.421,0.818-3.129,1.351-4.8,1.92c-1.881,0.438-3.585,1.055-5.28,1.68c-1.367,0.793-3.304,1.017-4.8,1.68
			c-2.315,0.165-3.567,1.393-5.76,1.68c-1.995,1.046-4.392,1.688-6.96,2.16c-3.359,0.456-8.437,2.305-11.52,0.72
			c-0.291,1.229-1.331,1.709-1.68,2.88c-1.092,1.308-2.203,2.597-4.08,3.12c-5.669-1.083-6.997,3.323-10.8,4.8
			c-0.793,0.967-2.17,1.35-3.12,2.16c-3.42,1.54-5.723,4.197-6.48,8.4c-0.75,1.33-1.054,3.106-1.92,4.32
			c-0.315,1.364-1.068,2.291-1.44,3.6c-0.131,1.551-1.188,4.25-1.92,6c-0.177,7.983-1.456,14.865-1.44,23.04c0,2.32,0,4.64,0,6.96
			c-0.988,3.923,2.489,13.104-1.44,13.2c-1.974,0.048-1.893-1.743-2.88-3.12c-0.845,4.206,0.924,7.875,0.96,12
			c0.283,2.678,0.196,5.725,2.16,6.721c1.56,0.84,3.016,1.783,4.56,2.64c-0.179,0.739,0.659,0.461,0.48,1.2
			c-0.6,1.558-2.604,0.453-3.6,0.72c-0.013,0.307-0.308,0.332-0.24,0.72c-0.43,1.41-1.155,2.525-1.92,3.6
			c-0.468,1.133-1.098,2.103-1.44,3.36c-0.545,1.615-1.467,2.854-2.16,4.32c-0.558,0.883-1.072,1.808-1.44,2.88
			c-0.491,1.43-1.288,2.553-1.92,3.84c-0.492,1.108-1.182,2.019-1.44,3.36c-0.723,1.356-1.236,2.924-1.92,4.32
			c-0.469,1.21-1.267,2.093-1.44,3.6c-0.672,1.968-1.302,3.979-2.16,5.76c-0.304,1.856-1.118,3.202-1.44,5.04
			c-0.081,2.002-1.2,5.039-1.68,7.44c-0.738,1.901-1.098,4.182-1.68,6.24c-0.267,2.694-1.254,6.745-1.92,9.84
			c-0.538,2.742-1.107,5.453-1.44,8.399c-1.095,1.306-1.933,2.868-1.68,5.521c-0.77,2.03-0.586,5.015-1.68,6.72
			c-0.662,1.498-1.745,2.575-2.88,3.601c0,0.08,0,0.159,0,0.239c-3.402,2.217-0.656,9.104-2.4,12.96
			c-0.333,1.507-1.087,2.593-1.68,3.841c-0.521,1.317-0.874,2.806-1.68,3.84c-0.615,1.227-1.36,3.321-1.92,5.279
			c0.825-0.104,1.345,0.096,1.92,0.24c1.335,1.306,2.866,2.414,4.08,3.84c0.08,0,0.16,0,0.24,0c1.029,0.972,2.188,1.813,3.12,2.881
			c0.566,0.073,0.446,0.833,1.2,0.72c1.201,0.799,2.544,1.455,3.36,2.64c2.313,1.861,0.503,5.234-1.44,6.24
			c-1.053,1.032-2.469,2.416-4.56,1.68c-0.988-2.308,1.12-3.273,2.4-4.08c0.758-0.232,1.836-1.048,1.44-1.68
			c-1.021-0.979-2.412-1.588-3.36-2.64c-0.566-0.074-0.447-0.834-1.2-0.721c-1.242-1.077-2.629-2.011-3.6-3.359
			c-0.08,0-0.16,0-0.24,0c-1.169-1.231-2.871-1.93-3.6-3.601c0-0.08,0-0.16,0-0.239c-0.867,0.813-0.719,2.642-1.44,3.6
			c-0.625,2.096-1.162,4.278-1.68,6.479c-0.175,3.426-1.5,7.779-1.92,11.761c-0.584,6.181-0.597,12.997,0.24,18.96
			c-0.303,1.855,1.321,3.843-0.24,4.8c-0.445,0.383,0.095,1.812,0.24,2.4c0.241,3.28,1.572,6.746,1.68,9.359
			c0.345,1.735,1.014,3.146,1.44,4.8c0.759,1.802,1.609,3.511,2.16,5.521c0.379,1.46,1.146,2.533,1.68,3.84
			c0.568,1.195,1.69,3.105,1.68,4.08c0.287,1.313,1.153,2.047,1.44,3.36c0.943,1.217,1.322,2.998,2.16,4.319
			c0.284,1.156,1.076,1.804,1.44,2.881c0.907,0.772,1.371,1.989,1.92,3.119c0.202,1.078,1.131,1.429,1.44,2.4
			c0.839,0.682,1.244,1.797,1.92,2.64c0.272,1.008,1.004,1.556,1.44,2.4c0.937,0.743,1.496,1.864,2.16,2.88
			c0.187,1.093,1.296,1.264,1.68,2.16c0.804,1.035,2.654,1.025,3.36,2.16c3.941,1.909,11.047,2.2,15.12,0.24
			c1.3-0.94,2.943-1.537,4.56-2.16c1.296-0.145,1.853-1.028,2.88-1.44c0.899-1.261,2.45-1.87,3.6-2.88
			c0.655,0.175,0.483-0.477,0.96-0.479c0.806-0.954,1.773-1.748,2.88-2.4c0.217-0.423,0.537-0.743,0.96-0.96
			c0.716-0.724,1.437-1.444,2.16-2.16c1.443-0.077,1.963-1.077,3.12-1.44c0.492-0.804,2.716-1.105,3.12,0c0.16,0,0.32,0,0.48,0
			c1.772-0.628,3.353-1.447,5.28-1.92c5.77-1.271,7.255-6.824,12-9.119c-1.956,0.195-3.185-0.336-4.8-0.48
			c-2.051-0.35-3.569-1.23-5.04-2.16c-0.912-0.368-1.593-0.967-2.64-1.2c-1.45-0.47-2.549-1.29-3.6-2.159
			c-0.769-0.111-0.846-0.914-1.68-0.961c-1.294-0.785-2.61-1.55-3.6-2.64c-2.499-1.581-4.744-3.416-7.68-4.56
			c-1.009-0.671-2.49-0.87-3.12-1.92c-1.135-0.386-1.912-1.128-3.12-1.44c-1.48-0.521-2.807-1.193-3.84-2.16
			c-0.736-0.304-1.263-0.817-1.92-1.2c-1.123-0.478-2.082-1.118-2.88-1.92c-0.01-0.793-0.98-1.35-0.24-2.16
			c0.104-0.536,1.183-0.097,1.68-0.239c1.885,0.194,2.839,1.32,4.08,2.159c0.677,0.523,1.503,0.897,2.16,1.44
			c1.414,0.506,2.694,1.146,3.84,1.92c1.24,0.44,2.005,1.355,3.36,1.68c1.021,0.5,2.036,1.005,2.88,1.681
			c2.572,1.429,4.907,3.093,7.44,4.56c1.075,0.766,2.216,1.464,3.12,2.4c0.756,0.364,1.171,1.069,2.16,1.2
			c1.139,0.541,2.237,1.122,3.12,1.92c1.063,0.537,2.114,1.086,3.36,1.439c2.911,1.89,8.121,1.479,12.24,2.16c0.08,0,0.16,0,0.24,0
			c1.146-0.774,2.426-1.414,3.84-1.92C136.48,633.041,136.56,633.041,136.64,633.041z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M368.961,583.841c0.729-0.79,1.335-1.705,2.16-2.399
			c0.332-0.388,0.68-0.761,0.96-1.2c0.666-0.774,1.137-1.743,1.92-2.4c0.551-0.649,1.116-1.283,1.439-2.16
			c0.784-1.056,1.357-2.322,2.4-3.12c0.36-0.679,1.058-1.022,1.2-1.92c0.664-1.016,1.223-2.137,2.16-2.88
			c0.376-1.223,1.333-1.866,1.68-3.12c0.328-1.431,0.927-2.593,1.68-3.6c0.461-1.779,1.296-3.184,1.68-5.04
			c0.522-2.357,1.266-4.495,1.921-6.72c0.383-3.218,1.324-5.876,1.439-9.36c0.556-7.039,0.846-17.108,0-23.28
			c-0.207-2.433-0.957-4.323-1.68-6.24c-0.64-1.2-1.104-2.575-1.681-3.84c-0.053-0.907-0.754-1.165-0.96-1.92
			c-1.636-0.203-2.779-0.9-4.319-1.2c-1.715-0.445-3.431-0.89-4.8-1.68c-1.832-0.568-3.553-1.248-5.521-1.68
			c-1.643-0.518-3.399-0.921-4.8-1.681c-1.984-0.655-3.986-1.294-6-1.92c-1.461-0.379-3.063-0.617-4.08-1.439
			c-2.113-0.606-4.133-1.308-6.24-1.92c-1.499-0.341-2.98-0.7-4.08-1.44c-0.623-0.188-1.948-0.916-2.399-0.479
			c0.415,0.384,0.703,0.896,0.96,1.439c0.776,0.668,1.779,2.374,1.68,3.12c0.558,1.603,1.227,3.093,1.68,4.8
			c2.221,8.441,1.57,20.599-0.239,29.04c0.068,2.389-1.024,3.616-1.2,5.761c-0.545,1.615-1.173,3.146-1.92,4.56
			c-0.448,1.152-1.169,2.031-1.681,3.12c-0.567,1.433-1.24,2.76-2.16,3.84c-0.273,0.926-1.034,1.366-1.199,2.4
			c-0.379,0.981-1.103,1.617-1.681,2.399c-0.172,0.628-0.864,0.736-0.96,1.44c-0.988,1.012-1.703,2.297-2.88,3.12
			c-0.08,0-0.16,0-0.24,0c-0.68,0.919-1.501,1.698-2.64,2.16c-0.389,0.73-1.218,1.021-1.92,1.439c-0.62,0.9-1.704,1.336-2.64,1.92
			c-0.871,0.249-1.444,0.796-2.16,1.2c-1.041,0.879-2.324,1.517-3.601,2.16c-3.931,0.729-4.388,3.453-5.279,7.2
			c-0.408,2.232-1.285,3.995-1.92,6c-0.497,1.583-1.297,2.864-1.681,4.56c0.014,1.218-1.242,3.234-1.68,4.8
			c-0.474,1.606-1.272,2.888-1.681,4.561c-0.102,1.342-1.228,3.729-1.92,5.28c-0.379,1.141-0.898,2.14-1.199,3.359
			c-0.23,0.49-0.49,0.95-0.721,1.44c1.342,0.339,2.841,0.52,3.601,1.439c2.035,0.845,3.968,1.792,6.24,2.4
			c1.252,0.428,2.61,0.749,3.6,1.439c1.746,0.174,2.552,1.289,4.32,1.44c0.833,0.446,2.056,0.504,2.64,1.2
			c1.99,0.89,4.253,1.507,6.48,2.16c2.159,0.48,4.046,1.233,6,1.92c2.318,0.562,4.325,1.435,6.96,1.68
			c2.682,0.598,5.425,1.136,7.92,1.92c1.137-0.078,2.363,0.699,3.12,0.24c1.04-1.28,1.437-3.203,2.159-4.8
			c0.583-1.737,1.19-3.45,1.44-5.521c0.692-2.507,1.127-5.273,2.16-7.439c0.266-2.295,1.107-4.013,1.439-6.24
			c0.508-1.974,0.891-4.07,1.681-5.76c0.12-0.761,0.779-0.981,1.439-1.2c0.695-0.825,1.61-1.431,2.4-2.16
			C368.455,584.935,368.854,584.535,368.961,583.841z M346.16,599.201c-0.201,0.278-0.316,0.644-0.239,1.2
			c-0.434,1.566-1.341,2.659-2.641,3.359c-0.08,0-0.159,0-0.239,0c-1.648,1.8-7.699,2.645-9.36,0.24c-0.08,0-0.16,0-0.24,0
			c-1.306-1.014-2.334-2.307-2.88-4.08c0.102-0.502-0.164-0.636-0.24-0.96c-1.141-1.023-1.813-4.387-0.479-5.76
			c0.834-1.486,2.2-2.439,3.84-3.12c5.837-2.792,12.161,0.724,12.479,6.479c0,0.08,0,0.16,0,0.24
			C346.558,597.06,346.558,598.942,346.16,599.201z M352.16,584.081c-1.419-0.42-2.528-1.151-4.079-1.44
			c-2.153-0.807-4.322-1.598-6.24-2.64c-2.416-1.425-6.704-0.976-8.16-3.36c3.914-1.249,8.242,0.962,11.76,1.681
			c1.221,0.38,2.479,0.722,3.36,1.439c1.658,0.742,3.207,1.593,5.28,1.92c1.77,0.79,4.828,0.292,6,1.681
			C359.918,587.026,353.898,584.673,352.16,584.081z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M350.001,314.081c3.545-0.458,6.66,1.369,9.359,0.24
			c0.716-0.084,1.462-0.139,1.681-0.72c-1.048-0.073-1.991-0.25-2.641-0.72c-2.201-0.279-4.038-0.921-6-1.44
			c-1.223-0.217-2.323-0.557-3.359-0.96c-0.609,0.319,0.352,1.065,0.239,1.68C349.313,313.007,349.968,313.233,350.001,314.081z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M365.121,316.24c3.319,0.28,6.195,1.004,9.359,1.44
			c3.33,0.43,6.46,1.06,9.36,1.92c3.108,0.251,5.43,1.29,8.64,1.44c2.755,0.445,5.424,0.977,7.92,1.68
			c2.901,0.62,6.072,0.969,8.881,1.68c3.084,0.195,5.781,0.778,8.159,1.68c0.58,0.621,1.729,0.671,2.16,1.44
			c1.335,0.745,2.128,2.032,3.84,2.4c1.587-0.146,2.461,0.419,4.08,0.24c-0.223-1.221-2.455-2.502-3.84-3.36
			c-2.333-0.534-2.241-2.186-5.52-1.92c-2.745-0.456-5.464-0.936-7.92-1.68c-1.889-0.431-3.493-1.147-5.521-1.44
			c-2.975-0.625-6.015-1.185-8.88-1.92c-2.732-0.307-5.008-1.072-7.68-1.44c-3.913-0.723-7.065-1.146-10.32-2.16
			c-3.807-0.522-7.056-1.615-10.561-1.2C365.393,313.869,361.918,315.464,365.121,316.24z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M311.36,462.641c0.323,1.037,0.789,1.932,1.2,2.88
			c0.081,0.291,2.532-1.489,2.64,0.24c1.729-0.166,5.172-0.336,6.721,0.96c0.424,0.216,0.7,0.579,1.199,0.72
			c0.901,0.7,1.701,1.5,2.4,2.4c0.432,0.928,0.868,1.852,1.2,2.88c0.158,0.969,0.562,2.948,0,3.84
			c-0.174,2.707-2.536,3.225-3.601,5.04c1.022,1.698,2.95,2.49,4.08,4.08c0.493,0.067,0.479,0.642,0.721,0.96
			c5.997-1.015,9.238-0.342,14.16,0.72c1.877,0.523,3.454,1.346,5.52,1.681c1.716,0.684,3.828,0.972,5.28,1.92
			c0.861,0.259,1.636,0.604,2.64,0.72c0.155-1.045-0.313-2.713,0.24-3.36c0-0.08,0-0.159,0-0.239
			c0.911-11.913,0.733-25.779,0.96-36.721c0.766-0.205,0.742,0.378,1.2,0.48c0,0.08,0,0.16,0,0.24s0,0.159,0,0.239
			c0.68,3.24,1.056,6.784,1.439,10.32c0,0.08,0,0.16,0,0.24c0.554,0.646,0.086,2.314,0.24,3.359c0,0.48,0,0.961,0,1.44
			c0.398,0.649,0.398,2.95,0,3.6c0,1.28,0,2.561,0,3.841c-0.157,1.682,0.317,3.997-0.24,5.279c0,0.24,0,0.48,0,0.721
			c-0.155,1.204,0.314,3.034-0.239,3.84c0,0.08,0,0.16,0,0.24c-0.03,2.529-0.157,4.963-0.48,7.199
			c1.059,1.021,3.098,1.063,4.32,1.92c1.877,0.523,3.454,1.346,5.52,1.681c1.401,0.679,3.382,0.778,4.561,1.68
			c1.727,0.593,3.515,1.125,5.279,1.68c1.323,0.198,2.244,0.797,3.601,0.96c1.058,0.862,2.975,0.865,4.08,1.681
			c0.691,0.028,1.571-0.131,1.92,0.24c1.407,0.593,1.317,2.682,2.64,3.359c0.833-0.192,0.923,0.357,1.68,0.24
			c0.825,0.695,1.431,1.609,2.16,2.4c0.546,0.414,1.341,0.579,2.4,0.479c0.527-0.313-0.284-1.297-0.24-1.92c0-0.08,0-0.16,0-0.24
			c-0.341-0.859-1.046-2.363-0.479-3.359c-1.583-8.167-1.743-19.956,0-28.08c-0.538-0.555,0.024-2.043,0.239-2.641
			c0.144-0.497-0.296-1.576,0.24-1.68c0-0.08,0-0.16,0-0.24c0.082-1.678,0.39-3.131,0.72-4.56c-0.133-1.857,0.891-3.69,0.24-5.28
			c0.045-1.466-0.651-3.266,0-4.56c1.002-0.519,0.815-2.225,1.2-3.36c-0.049-1.489,0.28-2.6,1.2-3.12
			c0.03-0.53,1.018-0.102,1.439-0.24c1.574-1.466,1.601-4.479,3.12-6c0.606-3.394,1.731-6.269,1.681-10.319
			c0.151-0.809-0.31-2.229,0.239-2.641c0-0.08,0-0.16,0-0.24c0.126-3.966-0.141-7.539-0.239-11.279
			c-0.106-4.534-0.85-8.431-0.96-12.96c-0.666-3.094-0.737-6.784-0.96-10.32c-0.522-3.638-0.722-7.599-0.721-11.76
			c-0.558-1.522-0.081-4.078-0.24-6c0-0.08,0-0.16,0-0.24c-0.558-1.922-0.081-4.879-0.239-7.2c0-0.08,0-0.16,0-0.24
			c-0.399-0.729-0.399-3.112,0-3.84c-0.507-5.494-1.311-10.69-1.681-16.32c-0.627-0.333,0.117-2.037-0.479-2.4
			c0.161-1.202-0.223-1.858-0.24-2.88c-0.154-1.445-0.35-2.85-0.72-4.08c-0.258-1.342-0.948-2.252-1.44-3.36
			c-0.86-0.819-1.438-1.922-2.16-2.88c-0.187-1.093-1.296-1.264-1.68-2.16c-0.372-2.108-3.791-1.168-5.28-2.16
			c-3.072-0.527-5.914-1.286-9.359-1.44c-6.656-0.544-13.344-1.057-20.4-1.2c1.259,2.263,4.583,4.536,6.72,6.72
			c0.08,0,0.16,0,0.24,0c1.095,0.905,2.381,1.62,3.36,2.64c0.656,0.224,1.023,0.737,1.68,0.96c1.511,0.89,3.27,1.531,4.561,2.64
			c0.802,0.398,1.598,0.802,2.399,1.2c1.138,0.622,2.363,1.156,3.12,2.16c1.203,1.038,2.636,1.844,3.12,3.6
			c-0.418,1.396-2.068,2.106-3.84,1.44c-1.754-0.566-3.711-0.929-5.28-1.68c-3.122-0.478-5.496-1.704-8.88-1.92
			c-4.909-1.091-11.138-0.862-16.32-1.68c-2.902-0.058-4.747-1.173-7.439-1.44c-1.788-0.613-3.887-0.914-5.28-1.92
			c-0.755-0.205-1.013-0.907-1.92-0.96c-1.548-1.67-1.742-0.067-2.4,1.44c-0.476,1.124-0.677,2.523-1.439,3.36
			c2.238,5.229-0.075,13.056,0,19.2c-0.37,5.791-0.623,13.778-1.92,19.44c-0.346,2.455-1.05,4.551-1.44,6.96
			c-0.217,2.105-1.063,5.655-1.92,7.92c-0.47,2.331-1.128,4.472-1.68,6.72c0.118,1.881-1.222,4.376-1.681,6.48
			c-0.512,1.488-1.289,2.71-1.439,4.561c-0.666,1.894-0.947,4.172-1.92,5.76c-0.57,1.83-1.101,3.699-1.681,5.52
			c-0.093,1.75-1.227,4.531-1.92,6.48c-0.457,1.782-1.213,3.268-1.68,5.039c0.077,1.601-1.235,3.883-1.68,5.761
			c-0.535,1.865-1.242,3.559-1.681,5.52c-0.069,1.613-1.331,4.107-1.92,6c-0.529-0.511-0.435-1.645-1.2-1.92
			c0.045,0.204-0.094,0.227-0.24,0.24c-0.807,2.719,0.963,3.467,1.681,5.28C310.369,459.957,311.483,461.712,311.36,462.641z
			 M374.48,389.681c1.204,0.156,3.035-0.314,3.84,0.24c0.08,0,0.16,0,0.24,0c3.671-0.551,4.509,1.732,5.04,4.32
			c0,0.08,0,0.16,0,0.24c0.471,0.649,0.647,1.593,0.72,2.64c0.202,2.198,0.473,4.328,0.721,6.48c0.555,0.885,0.083,2.796,0.239,4.08
			c0,0.16,0,0.32,0,0.48c0.679,9.175,1.135,19.979,0.24,29.28c0,0.64,0,1.28,0,1.921c0.121,0.932-1.939,0.924-2.399,0.479
			c-4.409-0.951-8.951-1.771-14.16-1.92c-1.598-0.003-3.395,0.194-3.84-0.96c0-0.08,0-0.16,0-0.24
			c-0.049-1.172,0.221-4.096,0.479-5.76c0.017-2.977,0.058-5.979-0.24-8.641c-0.558-1.922-0.081-4.879-0.239-7.199
			c0-0.08,0-0.161,0-0.24c-0.555-0.806-0.085-2.636-0.24-3.84c0-0.24,0-0.48,0-0.72c-0.557-1.124-0.083-3.277-0.24-4.8
			c0-0.48,0-0.96,0-1.44c-0.555-0.805-0.084-2.636-0.24-3.84c0.043-1.563-0.136-2.904-0.479-4.08c-0.427-2.064-3.307-7.008,0.72-7.2
			c1.586-0.146,2.461,0.419,4.08,0.24c1.204,0.156,3.034-0.314,3.84,0.24C372.909,389.812,373.789,389.652,374.48,389.681z
			 M346.641,368.561c0.25-1.03,1.482-1.078,1.68-2.16c1.039-2.401,2.862-4.018,5.28-5.04c1.182-0.258,2.242-0.638,3.36-0.96
			c1.412-0.765,4.28-0.548,5.76,0c1.623,0.377,3.182,0.818,4.56,1.44c1.447,0.793,2.295,2.186,2.881,3.84
			c0.211,1.468,0.802,2.558,0.96,4.08c0.397,0.336,0.397,2.304,0,2.64c0.005,0.565-0.414,0.707-0.24,1.44
			c-0.36,1-0.682,2.039-1.44,2.64c0,0.08,0,0.16,0,0.24c-2.31,2.25-3.312,5.809-6.72,6.96c-0.779-0.139-1.116,0.164-1.68,0.24
			c-0.729,0.399-3.112,0.399-3.841,0c-1.142-0.218-2.177-0.542-3.359-0.72c-3.052-0.948-5.156-2.844-6.48-5.52
			c0.115-0.755-0.342-0.938-0.479-1.44C346.002,374.356,345.391,370.495,346.641,368.561z M341.121,389.681c0.159,0,0.319,0,0.479,0
			c2.721-0.559,6.479-0.081,9.6-0.24c0.801,0,1.601,0,2.4,0c2.077,0.643,2.513,2.927,2.88,5.28c0.145,2.896,0.771,5.31,1.2,7.92
			c0.995,5.085,2.172,9.988,2.4,15.84c0.327,4.393,0.646,8.794,1.199,12.96c0.878,3.424,0.557,7.138-3.12,7.2
			c-1.817,0.182-3.676,0.324-5.76,0.24c-1.922-0.158-4.478,0.317-6-0.24c-0.96,0-1.92,0-2.88,0c-1.283-0.156-3.195,0.314-4.08-0.24
			c-0.192-0.127-0.39-0.25-0.72-0.24c-1.265-0.547-2.064,0.775-3.12-0.239c-0.434-12.127-0.09-25.03-1.2-36.48
			c-0.054-3.146-0.808-5.592-0.72-8.88C334.583,390.023,337.75,389.75,341.121,389.681z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M200.24,488.801c-0.769,0.671-1.617,1.263-2.4,1.92
			c-0.787,0.974-1.916,1.604-2.88,2.4c-0.753-0.114-0.634,0.646-1.2,0.72c-1.132,1.348-2.743,2.217-4.08,3.36
			c-1.818,0.981-3.214,2.385-4.8,3.6c-0.916,1.085-2.216,1.784-3.36,2.64c-0.876,0.164-1.17,0.91-1.92,1.2
			c-0.815,1.024-2.098,1.582-3.12,2.4c-0.686,0.754-2.032,0.849-2.64,1.68c0.16,0,0.32,0,0.48,0
			c-0.377,2.995,0.971,8.656-0.96,10.561c0.881,5.038,0.406,11.434,1.2,16.56c0.185,2.935,0.738,5.502,1.44,7.92
			c0.341,2.7,1.71,5.648,1.68,7.92c0.539,1.98,1.298,4.616,1.68,6c0.952,1.528,1.356,3.604,2.16,5.28
			c0.167,1.272,0.757,2.123,1.2,3.12c0.695,0.985,1.254,2.106,1.68,3.36c0.34,1.34,1.117,2.242,1.68,3.359
			c0.892,1.108,1.276,2.725,2.16,3.84c0.226,1.215,1.069,1.812,1.44,2.88c0.967,0.794,1.35,2.171,2.16,3.12
			c0.109,1.012,1.135,1.105,1.44,1.92c0.677,0.764,1.465,1.415,1.92,2.4c1.696,1.584,3.011,3.549,5.04,4.8
			c1.645,0.676,2.917,1.724,4.8,2.16c2.582,1.338,7.567,0.273,8.4,3.36c1.329,1.551,1.402,4.357,1.92,6.72
			c0.474,3.366,0.938,6.741,1.68,9.84c0.75,3.49,1.657,6.823,1.92,10.8c0.105,2.136,0.666,3.815,0.72,6
			c0.703,0.057,1.952-1.402,2.88-1.92c0.821-1.099,2.077-1.763,3.12-2.64c0.843,0.043,0.79-0.811,1.44-0.96
			c0.611-0.908,1.634-1.405,2.4-2.16c0.966-0.554,1.54-1.5,2.64-1.92c0.257-1.183,1.438-1.441,2.16-2.16
			c0.241,0.001,0.286-0.194,0.48-0.24c0.983-1.257,2.363-2.117,3.6-3.119c0.08,0,0.16,0,0.24,0c1.139-1.421,2.756-2.364,4.08-3.601
			c0.08,0,0.16,0,0.24,0c2.455-2.585,5.374-4.706,7.92-7.2c0.27-0.051,0.374-0.267,0.48-0.479c0.998-0.843,1.85-1.83,2.88-2.641
			c0.209-0.351,0.359-0.761,0.96-0.72c0.209-0.352,0.359-0.761,0.96-0.72c0.192-0.833-0.358-0.923-0.24-1.68
			c-0.404-1.196-0.912-2.289-1.44-3.36c-0.553-2.009-1.981-4.416-1.92-6.24c-0.376-1.784-1.169-3.151-1.44-5.04
			c-0.674-1.808-1.82-4.417-1.92-6c-0.551-1.368-1.01-2.829-1.68-4.08c-0.54-1.22-1.009-2.512-1.68-3.6c-0.56,0-1.12,0-1.68,0
			c0-0.08,0-0.16,0-0.24c-1.077-0.363-1.724-1.156-2.88-1.44c-1.011-0.748-2.19-1.329-2.88-2.399c-0.051-0.27-0.266-0.374-0.48-0.48
			c-1.061-0.859-2.021-1.819-2.88-2.88c0-0.08,0-0.16,0-0.24c-1.265-0.975-2.18-2.3-3.12-3.6c0.042-0.843-0.811-0.789-0.96-1.439
			c-1.09-0.99-1.854-2.307-2.64-3.601c-0.207-1.073-1.201-1.359-1.44-2.399c-0.797-0.803-1.332-1.868-1.68-3.12
			c-0.529-1.552-1.219-2.941-1.92-4.32c-0.546-1.934-1.403-3.557-1.68-5.76c-0.548-2.492-0.894-5.187-1.68-7.44
			c-0.43-3.09-1.45-5.59-1.68-8.88c-0.389-3.051-1.269-5.61-1.68-8.64c-0.651-3.909-1.344-7.777-1.68-12
			c-0.271-4.369-0.698-8.583-0.96-12.961c-1.034,0.487-1.947,1.094-2.88,1.681c-1.3,0.38-2.592,0.769-4.08,0.96
			c-0.259,0.397-2.141,0.397-2.4,0C202.897,490.464,201.217,489.984,200.24,488.801z M223.521,602.321
			c0.897-3.822,3.623-5.817,6.72-7.44c1.651-0.326,3.184-1.022,5.04-0.479c2.906,0.749,4.698,5.441,2.88,8.64
			c-0.285,0.755-0.81,1.27-0.96,2.16c-1.16,1.88-2.74,3.339-5.04,4.08c-0.556-0.077-0.921,0.038-1.2,0.24
			c-3.694,0.893-6.392-1.287-7.2-4.08C223.698,604.383,223.377,603.585,223.521,602.321z M203.36,499.121
			c-0.001-1.682,0.435-2.926,0.48-4.561c0.182-0.618,0.766-0.834,1.68-0.72c0.411,0.245,0.829,1.709,0.24,2.16
			c-0.34,2.459-0.637,4.963-0.72,7.68c-0.018,2.143-0.316,4.004-0.96,5.521c-0.758-0.362-0.736-1.505-0.72-2.641
			c0-0.64,0-1.279,0-1.92C202.961,503.356,202.961,500.405,203.36,499.121z M201.44,492.161c-1.238,2.842-2.588,5.571-3.36,8.88
			c-0.31,1.369-0.636,2.724-1.2,3.84c-1.037,3.924-2.016,7.903-2.4,12.48c-0.479,4.48-0.699,9.221-1.2,13.68
			c-0.504,1.336-0.069,3.611-1.2,4.32c-2.101-5.729-0.343-14.761,0.48-20.641c0.567-2.873,1.125-5.755,1.68-8.64
			c0.318-2.162,0.893-4.067,1.68-5.761c0.592-1.887,1.408-3.551,1.92-5.52c0.639-1.602,1.121-3.358,2.4-4.32
			C201.091,490.442,202.079,491.506,201.44,492.161z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M367.041,610.001c-1.34-0.82-2.741-1.579-3.841-2.64
			c-1.133,2.066-1.192,5.207-2.399,7.199c-0.166,1.195-0.836,1.885-0.96,3.12c-0.612,1.309-1.271,2.569-2.16,3.601
			c-0.08,0-0.16,0-0.24,0c-0.33-0.011-0.526,0.113-0.72,0.24c-0.915,0.585-2.784,0.07-3.6-0.24c-1.583,0.063-2.62-0.42-3.841-0.721
			c0.958,2.403,0.207,6.515,0.721,9.36c1.103,0.577,2.176,1.185,2.88,2.16c0.343,0.058,0.404,0.396,0.72,0.48
			c0.9,0.699,1.7,1.499,2.4,2.399c0.032,0.928,0.973,0.947,1.199,1.68c0.91,0.69,1.568,1.633,2.16,2.641
			c0.38,1.061,1.195,1.686,1.681,2.64c0.996,0.924,1.564,2.275,2.399,3.36c0.296,0.504,0.664,0.936,0.96,1.439
			c0.899,0.781,1.457,1.903,2.16,2.881c-0.113,0.753,0.646,0.634,0.72,1.199c1.085,0.916,1.785,2.216,2.641,3.36
			c0.506,1.014,1.188,1.851,2.16,2.4c0.939,0.74,1.738,1.621,2.64,2.399c1.185,0.816,2.56,1.441,4.08,1.92
			c1.22,0.62,2.742,0.938,3.84,1.681c2.868,1.548,6.848,0.654,10.32,0.239c0.759-1.013,3.535-1.993,4.08-0.479
			c0.943,1.424,0.136,3.116-1.2,3.6c-4.713,2.091-12.078,2.55-16.561,0c0-0.08,0-0.16,0-0.239c-1.38-0.061-1.869-1.011-3.119-1.2
			c-1.486-0.434-2.587-1.254-3.841-1.92c0-0.08,0-0.16,0-0.24c-1.951-1.329-3.538-3.022-5.04-4.8c-0.935-0.506-1.278-1.602-1.92-2.4
			c-0.092-0.788-0.701-1.059-0.96-1.68c-1.028-1.052-1.731-2.428-2.64-3.601c-0.367-1.153-1.106-1.934-1.68-2.88
			c-0.838-0.362-0.964-1.436-1.44-2.16c-0.061-1.059-0.976-1.264-1.2-2.16c-0.86-0.818-1.438-1.922-2.16-2.88
			c-1.432-2.167-2.886-4.313-5.279-5.52c-0.9,16.12-0.665,32.44,0.72,47.76c0.67,9.649,0.689,19.951,1.92,29.04
			c1.281,0.002,1.979-0.582,3.12-0.72c1.76-0.801,3.771-1.35,5.76-1.92c1.681-0.24,2.854-0.986,4.32-1.44
			c1.363-0.637,2.777-1.222,4.32-1.68c2.086-0.394,3.685-1.275,5.52-1.92c1.491-1.068,3.689-1.431,5.76-1.92
			c2.705-0.416,4.931-1.31,8.16-1.2c0.649-0.398,2.95-0.398,3.601,0c0.64,0,1.279,0,1.92,0c1.73,0.269,3.457,0.542,4.8,1.2
			c1.229,0.057,2.743,1.089,3.6,0.72c0-0.08,0-0.16,0-0.24c-0.673-1.727-1.403-3.396-2.399-4.8c-0.241-0.799-0.432-1.648-0.721-2.4
			c0.328-1.031,0.016-2.704,0.48-3.6c-0.188-0.988,0.368-1.231,0.24-2.16c-0.061-3.662,2.415-6.247,1.439-10.8
			c-0.206-0.51,0.05-1.662-0.24-2.64c0.288-2.608-0.643-3.997-0.72-6.24c-0.455-0.706,1.657-0.896,1.2-2.4
			c0.238-1.122,0.722-1.998,0.96-3.12c0.08,0,0.16,0,0.24,0c0.037-0.282-0.041-0.681,0.24-0.72c1.102-2.811,1.81-5.922,1.199-9.84
			c-0.724,0.01-2.056,0.646-2.64,0c-0.24,0-0.48,0-0.72,0c-0.978-0.143-1.778-0.463-2.4-0.96c-1.164-0.437-2.058-1.143-3.36-1.44
			c-1.057-0.303-1.98-0.738-2.88-1.199c0-0.08,0-0.16,0-0.24c-1.507-0.653-2.97-1.351-4.56-1.92c-1.658-0.423-2.878-1.283-4.08-2.16
			c-0.912-0.368-1.594-0.967-2.64-1.2c-1.573-0.508-2.768-1.393-4.08-2.16c-1.507-0.653-2.863-1.457-4.561-1.92
			c-1.185-0.575-2.62-0.899-3.6-1.68c-0.999-0.361-1.882-0.839-2.88-1.2c-1.815-0.505-3.339-1.302-5.04-1.92c0-0.08,0-0.16,0-0.24
			c-1.558-0.442-2.649-1.351-3.841-2.16c-0.684-0.195-1.373-0.386-1.199-1.439c0.319,0,0.64,0,0.96,0c0,0.08,0,0.16,0,0.24
			c2.901-0.103,4.746,0.854,6.96,1.439c1.66,0.579,3.43,1.051,4.8,1.92c1.289,0.391,2.188,1.172,3.6,1.44
			c0.956,0.324,2.083,0.477,2.641,1.2c1.528,0.871,3.309,1.491,5.04,2.159c1.525,0.555,2.77,1.391,4.08,2.16
			c1.63,0.449,2.689,1.471,4.319,1.92c0.858,0.582,2.082,0.798,2.88,1.44c1.212,0.468,2.291,1.069,3.601,1.439
			c1.473,0.447,2.482,1.358,4.08,1.681c0,0.08,0,0.16,0,0.239c1.163,0.437,2.013,1.188,3.6,1.2c0.48,0,0.96,0,1.44,0
			c-0.107-1.973,0.247-4.406-0.24-6c0.159-2.881-0.318-6.398,0.24-8.88c0.015-1.745,0.658-2.861,0.479-4.8
			c-0.773-0.187-1.418-0.502-1.92-0.96c-1.27-0.65-2.401-1.438-3.84-1.92c-0.85-0.511-1.925-0.795-2.64-1.44
			c-0.284-0.116-0.545-0.256-0.721-0.479c-1.129-0.711-2.284-1.396-3.6-1.92c-1.047-0.233-1.729-0.832-2.64-1.2
			c0-0.08,0-0.16,0-0.24c-1.319-0.601-2.4-1.439-3.841-1.92c-1.204-0.315-2.049-0.99-3.119-1.44c0-0.079,0-0.16,0-0.239
			c-1.229-0.531-2.237-1.284-3.601-1.681c-1.248-0.592-2.447-1.232-3.6-1.92c0-0.08,0-0.16,0-0.24
			c-1.066-0.213-1.579-0.981-2.641-1.199c-1.548-0.611-3.021-1.3-4.319-2.16c0-0.08,0-0.16,0-0.24
			C368.054,610.908,367.795,610.207,367.041,610.001z M399.681,663.041c-0.152,0.809,0.31,2.229-0.24,2.641
			C399.593,664.873,399.132,663.452,399.681,663.041z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M179.12,679.121c-1.919-12.721-0.739-28.541-1.44-42.48
			c0,0.08,0,0.16,0,0.24c-0.431,1.009-1.282,1.598-1.68,2.641c-0.08,0-0.16,0-0.24,0c-0.361,0.679-1.058,1.021-1.2,1.92
			c-0.529,0.83-1.266,1.454-1.68,2.399c-0.08,0-0.16,0-0.24,0c-0.551,0.329-0.771,0.988-1.2,1.44c-0.77,1.311-1.848,2.313-2.64,3.6
			c-0.08,0-0.16,0-0.24,0c-0.074,0.566-0.833,0.447-0.72,1.2c-1.073,1.327-1.962,2.838-3.36,3.84c0,0.08,0,0.16,0,0.24
			c-1.074,1.087-1.879,2.44-3.12,3.36c0,0.079,0,0.16,0,0.239c-3.005,3.556-6.442,6.679-9.36,10.32c-0.08,0-0.16,0-0.24,0
			c-0.423,0.217-0.743,0.537-0.96,0.96c-1.124,1.917-1.449,4.631-2.4,6.72c-0.071,1.449-0.513,2.528-0.48,4.08
			c0.588,0.452,1.266,0.814,2.16,0.96c1.389,0.611,3.026,0.975,4.08,1.92c1.523,0.478,2.589,1.411,4.32,1.681
			c1.124,0.636,2.475,1.045,3.36,1.92c1.076,0.363,1.724,1.156,2.88,1.439c0.995,0.605,2.232,0.968,2.88,1.921
			c0.976,0.304,1.392,1.168,2.4,1.439c1.252,0.588,2.373,1.308,3.36,2.16c1.152,0.448,1.917,1.283,3.12,1.68
			c0.786,0.574,1.694,1.025,2.4,1.681c0.755,0.364,1.171,1.068,2.16,1.199c0.313-0.486-0.847-2.818-0.48-3.359
			C179.665,689.776,179.293,684.548,179.12,679.121z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M426.081,334c-1.199-0.081-2.448-0.112-3.36-0.48
			c-6.141-0.339-11.895-1.065-18-1.44c-2.338,0.098-3.821-0.659-6-0.72c0,0.08,0,0.16,0,0.24c0.854,0.586,1.296,1.584,1.92,2.4
			c0.3,1.06,1.111,1.609,1.44,2.64c0.981,1.101,1.977,3.459,1.92,4.8c0.606,4.753,0.97,9.75,1.68,14.4c0,0.8,0,1.6,0,2.4
			c0,0.08,0,0.16,0,0.24c1.338,11.542,0.999,24.761,1.92,36.72c-0.084,6.724,0.839,12.441,1.44,18.48
			c0.523,7.271,1.346,15.815-0.24,22.56c-0.45,2.67-0.928,5.313-1.44,7.92c0.156,2.159-1.03,5.049-1.68,7.2
			c-1.578,2.021,0.146,6.787,0,10.32c0.569,1.75,0.388,4.253,1.2,5.76c1.503,1.137,1.712,3.568,1.92,6
			c0.687,2.755,1.737,6.422,1.92,8.88c0.736,2.329,1.213,5.284,1.92,6.961c0.43,2.291,1.572,5.146,1.68,6.96
			c0.258,1.662,0.922,2.918,1.44,4.319c0.709,1.371,1.354,2.806,2.16,4.08c0.226,1.215,1.069,1.812,1.439,2.88
			c0.931,0.91,1.435,2.246,2.16,3.36c0.414,0.946,0.995,1.725,1.681,2.4c0.932,1.147,1.909,2.25,2.88,3.359
			c0.251,0.549,0.152,1.448,0.479,1.92c1.665,0.016,2.503,0.857,3.84,1.2c1.841,0.479,3.4,1.24,4.801,2.16
			c1.273,0.406,2.122,1.237,3.6,1.44c1.646,0.354,2.833,1.166,4.08,1.92c1.65,0.669,2.884,1.756,4.8,2.159
			c0.681,0.52,1.802,0.599,2.4,1.2c2.671,1.657,5.148,0.795,7.92-0.24c1.96-0.679,3.993-1.286,6.24-1.68
			c5.178-0.957,12.289-1.531,17.279,0c2.598,0.362,4.811,1.11,6.96,1.92c1.609,0.312,2.772,1.068,4.32,1.44
			c2.11,0.529,4.086,1.194,5.76,2.16c1.407,0.192,2.192,1.008,3.601,1.199c1.393,0.208,2.318,0.882,3.6,1.2
			c0.534,0.187,1.302,0.139,1.44,0.721c1.819,0.74,4.141,0.979,5.76,1.92c0.867,0.093,1.602,0.318,2.16,0.72
			c0.56-0.205,1.034,0.839,1.2,0.24c-0.412-0.309-0.944-0.497-0.96-1.2c-0.594-0.287-0.938-0.822-1.2-1.44
			c-0.564-0.636-1.035-1.365-1.44-2.16c-0.545-1.455-1.664-2.336-1.92-4.079c-0.542-0.498-0.749-1.331-0.96-2.16
			c0-0.08,0-0.16,0-0.24c-0.627-0.573-0.811-1.589-0.96-2.64c-0.138-0.502-0.595-0.686-0.479-1.44c-0.463-0.4-0.308-2.213,0-2.64
			c0.08,0,0.159,0,0.239,0c0.596-0.95,1.069-0.819,0.721-2.4c-0.759-3.43-1.175-8.557-1.92-12.72
			c-0.027-1.733-0.146-3.375-0.48-4.801c0.024-1.863-0.418-3.262-0.72-4.8c-0.202-1.157-0.329-2.392-0.48-3.6
			c-0.407-0.633-0.71-1.369-0.72-2.4c-0.476-0.805-0.789-1.771-0.96-2.88c-0.72-0.88-0.873-2.327-1.44-3.359
			c0.29-1.01-0.388-1.053-0.479-1.681c-2.458-5.063-4.764-10.276-6.96-15.6c-0.69-1.07-1.364-2.156-1.92-3.36
			c-0.665-0.615-0.994-1.566-1.44-2.4c-0.332-0.867-1.056-1.344-1.2-2.399c-0.995-0.685-1.401-1.958-2.16-2.88c0-0.16,0-0.32,0-0.48
			c-0.798-0.642-1.071-1.809-1.68-2.64c0-0.08,0-0.16,0-0.24c-0.774-0.585-0.873-1.847-0.96-3.12c0.019-0.418-0.054-0.746-0.24-0.96
			c-0.982-1.337-2.032-2.607-3.12-3.84c-0.479-0.8-1.258-1.302-1.439-2.399c-1.071-1.09-1.456-2.865-1.92-4.561
			c-0.355-0.045-0.221-0.58-0.24-0.96c-0.48-0.866-0.786-2.963-0.48-3.84c-0.418-1.262-0.851-2.509-0.72-4.32
			c-0.581-2.3-0.45-5.31-0.96-7.681c-0.058-3.381-0.222-6.658-0.72-9.599c0.127-2.207-0.525-3.635-0.48-5.76
			c-0.365-0.835-0.433-1.966-0.479-3.12c0-0.48,0-0.96,0-1.44c-0.32-0.88-0.65-1.75-0.72-2.88c-0.454-0.986-0.707-2.174-0.96-3.36
			c-0.584-1.017-0.865-2.335-1.2-3.6c-0.772-1.228-0.8-3.201-1.44-4.56c-0.516-0.765-0.664-1.896-1.2-2.64c0-0.08,0-0.16,0-0.24
			c-0.401-0.559-0.627-1.293-0.72-2.16c-0.773-1.866-1.487-3.792-2.16-5.76c-0.357-0.763-0.888-1.353-0.96-2.4
			c-0.44-1.319-1.134-2.386-1.439-3.84c-0.653-0.707-0.854-1.867-1.44-2.64c0-0.08,0-0.16,0-0.24c-0.675-1.085-1.404-2.116-1.68-3.6
			c-0.188-0.053-0.279-0.201-0.24-0.48c-0.563-0.717-0.951-1.609-1.44-2.4c0.175-0.655-0.477-0.483-0.479-0.96
			c-0.444-0.676-1.013-1.228-1.2-2.16c-0.451-0.588-0.993-1.087-1.2-1.92c-0.801-0.799-1.442-1.758-1.92-2.88
			c-1.226-1.254-2.129-2.831-3.12-4.32c-0.399-0.481-0.87-0.889-0.96-1.68c-0.351-0.208-0.761-0.359-0.72-0.96
			c-0.827-0.933-1.845-1.675-2.4-2.88c-0.647-0.392-1.142-0.938-1.439-1.68c-0.422-0.138-1.41,0.291-1.44-0.24
			c-1.062-0.138-1.864-0.536-2.399-1.2c-1.063-0.778-2.592-1.088-3.36-2.16c-2.04-0.68-3.324-2.116-5.28-2.88
			c-0.407,0.472-0.736,1.024-1.439,1.2c-1.28,0.395-3.636-0.201-5.04-0.48C426.721,334,426.4,334,426.081,334z M440.721,450.161
			c2.223-0.018,4.171-0.31,6-0.721c2.927-0.673,6-1.2,8.88-1.92c2.473-0.327,4.688-0.911,6.721-1.68c1.3-0.7,2.928-1.072,4.56-1.44
			c2.067-0.333,3.768-1.033,5.76-1.439c1.208-0.721,2.983,0.051,2.88,1.439c0.021,0.341,0.04,0.68-0.239,0.721
			c-0.396,0.804-1.327,1.072-2.4,1.199c-0.837,0.443-2.075,0.484-3.12,0.721c-0.32,0-0.64,0-0.96,0c-0.08,0-0.16,0-0.24,0
			c-1.227,0.533-2.628,0.892-4.08,1.199c-2.843,0.518-5.549,1.171-8.159,1.921c-2.625,0.815-5.502,1.378-8.641,1.68
			c-2.818,0.221-5.342,0.737-8.16,0.96c-2.722,0.399-7.118,0.399-9.84,0c-0.772,0.053-1.016-0.425-1.92-0.24
			c-0.315-0.085-0.377-0.422-0.72-0.479c-0.006-0.886,0.107-1.653,0.96-1.681c3.04,0,6.08,0,9.12,0
			C437.847,449.847,439.597,450.316,440.721,450.161z M415.761,442.721c-1.119,2.08-2.082,4.317-3.12,6.48
			c-0.604,1.955-1.351,3.769-1.68,6c-0.54,1.86-0.353,4.446-1.44,5.76c-0.479,0-0.96,0-1.439,0c-2.102-2.207-0.071-6.807,0.72-9.12
			c0.35-1.011,0.814-1.906,0.96-3.12c0.88-2.479,1.955-4.766,3.36-6.721c0-0.079,0-0.159,0-0.239c0.597-1.083,1.038-2.321,1.92-3.12
			c0.47-0.89,1.177-1.543,1.439-2.641c0.948-1.531,1.766-3.193,2.88-4.56c0-0.079,0-0.159,0-0.239c1.957-2.924,3.34-6.421,6.24-8.4
			c0.16,0,0.32,0,0.48,0c1.787,0.224,0.743,3.057-0.24,3.359c0,0.08,0,0.16,0,0.24c-3.577,5.143-6.601,10.84-10.08,16.08
			C415.761,442.561,415.761,442.641,415.761,442.721z M438.32,479.921c-0.444,1.875-2.257,2.383-3.6,3.36
			c-0.73-0.011-0.934,0.506-1.439,0.72c-0.91,0.931-2.247,1.434-3.36,2.16c-0.655-0.175-0.483,0.477-0.96,0.479
			c-1.999,1.552-3.68,3.355-5.521,5.04c-1.729,1.583-3.432,3.715-6,4.32c-1.194-2.984,2.081-4.863,3.841-6.48
			c1.945-1.788,4.063-3.731,6-5.04c0.08,0,0.159,0,0.239,0c1.256-1.225,2.936-2.024,4.561-2.88c0.73,0.011,0.933-0.507,1.439-0.72
			c0.986-0.706,3.606-3.035,4.8-1.2C438.32,479.761,438.32,479.841,438.32,479.921z M481.521,453.521
			c0.423,0.058,0.548,0.413,0.48,0.96c1.264-0.304,1.297,0.623,1.2,1.681c-0.574-0.307,0.239-2-1.2-1.44
			c-0.155,1.605-1.235,2.285-2.88,2.4c-0.08,0-0.16,0-0.24,0c-1.718,0.682-3.883,0.917-6,1.199c-0.08,0-0.16,0-0.24,0
			c-0.596,0.364-1.456,0.464-2.399,0.48c-6.496,0.784-13.991,0.569-20.16,1.68c-2.356,0.604-4.886,1.034-7.2,1.681
			c-0.418-0.019-0.746,0.053-0.96,0.239c-0.946,0.574-2.211,0.83-3.36,1.2c-0.08,0-0.16,0-0.24,0c-1.35,0.81-2.94,1.379-4.319,2.16
			c-1.098,0.263-1.75,0.97-2.641,1.44c-2.953,1.767-5.817,4.901-8.88,5.76c-0.24,0.001-0.285-0.194-0.479-0.24c0-0.4,0-0.8,0-1.2
			c0.966-1.434,2.579-2.221,3.84-3.359c0.754,0.113,0.634-0.646,1.2-0.721c1.743-1.696,4.076-2.804,6.24-4.08
			c0.08,0,0.159,0,0.239,0c0.921-0.999,2.448-1.392,3.84-1.92c1.569-0.271,2.772-0.907,4.08-1.439c2.061-0.98,4.558-1.522,7.2-1.92
			c2.91-0.371,5.595-0.966,8.4-1.44c3.291-0.788,6.991-1.169,10.56-1.68c2.854-0.266,5.17-1.07,7.92-1.44c0-0.08,0-0.16,0-0.24
			c0.758,0.118,0.848-0.432,1.681-0.239C478.203,452.523,481.236,452.01,481.521,453.521z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M404.48,706.241c0-0.08,0-0.16,0-0.24
			c-0.89-0.31-2.008-0.392-3.12-0.479c-0.435-0.686-1.466-0.774-2.399-0.96c-0.972-0.229-1.987-0.413-2.641-0.96
			c-3.266-0.633-7.533-0.633-10.8,0c-2.021,0.698-4.339,1.101-6.479,1.68c-2.165,0.555-3.955,1.484-6,2.16
			c-1.274,0.806-2.842,1.318-4.561,1.68c-0.992,0.527-2.177,0.863-3.359,1.2c-1.887,0.914-4.003,1.598-6.24,2.16
			c-1.633,0.067-1.953,0.631-1.92,1.92c-0.678,1.242-2.75,1.09-4.32,1.439c-0.337,5.423,0.875,12.395-1.2,16.08
			c-0.814-0.064-1.945,0.187-2.399-0.239c-0.885-4.167,0.425-10.074,0-15.36c-1.124-0.557-3.277-0.083-4.801-0.24
			c-1.79,0.031-3.612,0.093-4.8-0.479c-0.53-0.03-0.102-1.019-0.24-1.44c1.989-1.531,6.214-0.826,9.36-1.2
			c0.763-1.807-0.201-3.956-0.24-5.76c-0.243-8.637-0.687-17.072-1.439-25.2c-1.309-16.475-0.753-34.46-0.721-51.12
			c-0.079,0-0.159,0-0.239,0c-1.723-1.478-4.045-2.355-4.8-4.8c0-0.24,0-0.479,0-0.72c1.489,0.11,2.376,0.823,3.6,1.199
			c0.657,0.383,1.184,0.896,1.92,1.2c0.158-2.081-0.318-4.798,0.24-6.479c-0.01-0.891,1.791,0.031,1.68-0.96
			c-0.89,0.17-1.03-0.41-1.92-0.24c-2.939-0.581-5.879-1.161-8.4-2.16c-2.059-0.341-3.757-1.043-5.76-1.439
			c-1.605-0.555-3.542-0.778-4.8-1.681c-1.728-0.512-3.316-1.163-5.04-1.68c-1.638-0.763-3.614-1.186-5.04-2.16
			c-1.165-0.274-1.799-1.081-3.12-1.2c-1.992-0.567-3.83-1.289-5.52-2.16c-1.33-0.511-2.246-1.434-3.84-1.68
			c-1.619-0.7-3.866-0.774-4.801-2.16c0.892-2.389,1.207-5.353,1.92-7.92c0.703-1.937,1.066-4.214,1.68-6.24
			c0.34-2.7,1.341-4.739,1.92-7.199c0.519-2.043,1.41-3.711,1.681-6c0.401-1.919,0.765-3.876,1.681-5.28c0-0.08,0-0.16,0-0.24
			c-0.529-1.312-1.785-1.895-2.4-3.12c0.113-0.753-0.646-0.634-0.721-1.2c-0.93-0.909-1.434-2.246-2.16-3.359
			c-0.417-1.263-1.059-2.302-1.439-3.601c-0.97-2.31-2.026-4.533-2.16-7.68c0.08,0,0.16,0,0.24,0c-0.461-1.299-0.15-3.369-0.24-5.04
			c-0.57-1.147-1.598-2.89-0.48-4.32c-2.982-0.216-4.938-1.461-7.92-1.68c-1.722-0.358-3.575-0.584-5.04-1.2
			c-0.421,0.219-0.439,0.841-0.72,1.2c-0.08,0-0.16,0-0.24,0c-0.561,0.398-0.58,1.34-1.68,1.2c-0.499,2.116-2.872,1.392-3.84,2.64
			c-1.072,0.368-1.901,0.979-3.12,1.2c-0.489,0.551-1.993,0.087-2.88,0.24c-0.08,0-0.16,0-0.24,0c-2.811-0.348-5.486,1.318-7.68,0
			c-0.774-0.346-1.781-0.46-2.64-0.72c0.229,3.682-0.063,7.186-0.24,11.039c-0.003,2.957-0.995,4.926-2.16,6.721
			c-0.364,0.755-1.069,1.171-1.2,2.16c-0.761,1.398-1.471,2.849-2.16,4.319c-0.563,2.878-0.782,6.098-0.96,9.36
			c0.475-0.726,0.337-2.063,0.96-2.64c0.206-0.755,0.907-1.013,0.96-1.921c0.944-1.456,2.203-2.597,3.84-3.359
			c1.264-0.337,2.144-1.057,3.6-1.2c0.414-0.397,2.466-0.397,2.88,0c3.06,0.54,4.397,2.804,6,4.8
			c1.868,2.121,3.022,8.473,0.48,10.801c-0.774,1.955-3.215,2.823-5.04,3.84c-0.889,0.495-0.926,1.037-1.68,1.199
			c-2.816,0.606-5.248-0.631-7.68-1.439c-0.885-0.235-1.189-1.051-1.92-1.44c0-0.08,0-0.159,0-0.239
			c-0.417-0.943-0.859-1.861-1.44-2.641c-0.396,0.373,0.082,0.936,0.24,1.44c0.512,2.529,1.774,5.583,1.68,7.92
			c0.282,1.878,0.978,3.342,0.96,5.52c-1.049,1.591-2.595,2.686-3.6,4.32c-1.138,0.462-1.777,1.423-2.4,2.4
			c-0.183,1.303,0.38,1.859,0.24,3.12c0.616,0.344,1.274,0.646,1.44,1.439c0.357,5.709,0.227,11.537,0,17.28
			c0.117,3.876-0.384,7.137-1.92,9.36c-0.59,1.489-1.418,2.741-1.68,4.56c-2.423,7.052-0.631,18.162,0,24.96
			c2.353,9.464,1.116,21.503,0,31.44c0.238,2.235-0.95,4.377-1.2,6.96c-0.787,8.132,2.306,18.517-0.48,24.72
			c-0.905,6.097-1.127,13.854-0.24,20.88c0.339,1.664,0.822,4.454,0.24,6.24c-0.534,1.909-4.172,0.933-3.84-0.96
			c0-0.88,0-1.76,0-2.64c0-0.08,0-0.16,0-0.24c-1.386-8.283-1.477-18.546,0-26.88c0.076-0.325,0.341-0.459,0.24-0.96
			c-0.468-0.893-1.821-0.899-2.4-1.681c-1.778-0.302-2.855-1.304-4.8-1.439c-3.001-0.599-6.181-1.02-8.88-1.92
			c-2.321-0.159-3.932-1.028-6.24-1.2c-2.592-0.608-5.121-1.279-7.44-2.16c-2.498-0.303-4.463-1.138-6.96-1.44
			c-2.046-0.354-3.824-0.976-5.28-1.92c0-0.079,0-0.16,0-0.239c-0.08,0-0.16,0-0.24,0c-0.77-0.578-1.346-1.987-0.48-2.881
			c0.08,0,0.16,0,0.24,0c1.613-0.492,1.247,0.993,2.4,0.961c2.414,0.865,5.261,1.299,7.68,2.159c2,0.321,3.664,0.976,5.76,1.2
			c2.981,0.458,5.865,1.015,8.4,1.92c3.325,0.516,6.32,1.36,9.84,1.681c0.993-0.034,1.854,0.065,2.4,0.479c0.08,0,0.16,0,0.24,0
			c1.723,0.197,3.232,0.608,4.32,1.44c0.882,0.077,1.328,0.592,2.16,0.72c0.091-7.184-1.773-15.976,0.72-22.561
			c-0.353-0.92,0.536-2.659,0.24-4.319c-9.575,0.689-22.733,1.506-30.96-1.2c-0.97-0.229-1.471-0.93-2.64-0.96
			c-0.241,0.001-0.286-0.194-0.48-0.24c0.956-1.364,3.924-0.716,6-0.96c0.56,0,1.12,0,1.68,0c7.494,0.17,15.376,0.464,22.8,0
			c1.826-0.2,3.887,0.431,4.08-0.96c0.318-2.285-7.662-1.233-9.84-1.92c-3.442-0.398-6.762-0.919-9.84-1.68
			c-3.917-0.483-7.628-1.173-11.76-1.44c-5.578-1.063-12.696-0.584-17.76-2.16c-0.701-0.259-0.777-1.143-0.72-2.16
			c3.328-0.775,8.761,0.307,12.96,0.24c5.708,0.532,11.666,0.813,16.8,1.92c3.482,0.278,6.327,1.193,9.84,1.44
			c3.6,0.24,6.486,1.193,9.84,1.68c0.08,0,0.16,0,0.24,0c1.938-6.053-0.074-14.083-0.72-19.92c-1.149-6.646-1.508-15.827,0.24-22.08
			c-0.285-2.207,1.238-4.681,1.92-6.72c0.527-1.474,1.163-2.838,1.44-4.561c0.44-5.288,0.435-12.714,0-18.96
			c-1.153-0.606-1.235-2.285-1.2-4.08c-0.332-0.393-1.108,0.798-1.44,1.2c-0.665,0.775-1.645,1.235-2.16,2.16c-0.08,0-0.16,0-0.24,0
			c-0.928,0.032-0.947,0.974-1.68,1.2c-0.645,1.035-1.751,1.608-2.64,2.399c-0.08,0-0.16,0-0.24,0
			c-1.112,1.448-2.718,2.402-4.08,3.601c-0.843-0.043-0.79,0.811-1.44,0.96c-0.441,0.999-1.686,1.194-2.16,2.16
			c-0.08,0-0.16,0-0.24,0c-2.102,1.418-3.961,3.078-5.76,4.8c-1.067,1.093-2.467,1.853-3.6,2.88c-0.08,0-0.16,0-0.24,0
			c-1.135,1.265-2.676,2.124-4.08,3.12c-1.176,0.184-1.297,1.423-1.68,2.399c-0.515,0.626-2.125,0.626-2.64,0
			c0.053-0.772-0.425-1.015-0.24-1.92c-0.554-4.486-1.294-8.785-1.92-13.199c-0.253-3.1-1.268-6.473-1.68-8.641
			c-0.88-2.8-1.278-6.081-1.92-9.12c0.008-1.849-0.754-2.925-0.96-4.56c-1.871-0.609-3.674-1.286-5.52-1.92
			c-2.07-0.606-2.191-0.689-2.88,0c-1.109,1.109,1.095,2.014-0.24,3.6c-0.453,1.228-1.068,2.292-1.92,3.12
			c-0.435,0.845-1.167,1.393-1.44,2.4c-0.464,0.976-1.136,1.743-1.68,2.64c-0.08,0-0.16,0-0.24,0
			c-0.405,0.795-0.875,1.524-1.44,2.16c-0.565,1.274-1.231,2.449-2.16,3.359c-0.258,0.622-0.868,0.893-0.96,1.681
			c-0.641,1.279-1.495,2.345-2.16,3.6c-0.08,0-0.16,0-0.24,0c-0.338,0.622-0.812,1.108-1.2,1.681
			c-0.589,1.09-1.295,2.064-1.92,3.119c-0.08,0-0.16,0-0.24,0c-0.309,0.972-1.238,1.322-1.44,2.4c-0.374,0.906-1.066,1.494-1.44,2.4
			c-0.08,0-0.16,0-0.24,0c-0.62,0.899-1.324,1.716-1.92,2.64c1.273,6.262,0.72,15.761,0.72,23.76c0,7.308,0.184,14.236,0.72,20.641
			c0.731,6.149,1.143,13.896,2.16,18.96c0.367,1.394,1.354,2.166,1.44,3.84c0.217,2.583,2.364,3.236,3.84,4.56
			c0.622,0.259,0.892,0.868,1.68,0.96c1.189,0.732,2.266,1.574,3.12,2.641c1.048,0.674,2.46,1.851,1.68,3.6
			c-1.042,1.969-3.688,0.303-4.32-0.96c-0.754-0.285-1.27-0.81-2.16-0.96c-1.523-0.478-2.691-1.309-4.08-1.92
			c-2.645,6.284-0.276,15.897,0,23.28c0.063,1.687,0.695,4.841-1.92,4.8c-1.977-1.463-0.34-6.54-1.44-8.88
			c-0.344-5.872-1.649-12.442-0.24-18c0.271-0.769,0.488-1.592,0.48-2.64c-0.457-0.104-0.603-0.518-1.2-0.48
			c-1.391-0.689-3.346-0.814-4.56-1.68c-2.005-0.395-3.521-1.28-5.52-1.681c-1.616-0.464-3.18-0.98-4.56-1.68
			c-2.019-0.541-3.591-1.529-5.76-1.92c-1.616-0.464-3.179-0.98-4.56-1.68c-1.165-0.275-1.798-1.082-3.12-1.2
			c-1.434-0.406-2.608-1.072-3.84-1.681c0-0.079,0-0.159,0-0.239c-3.367-0.648-2.73,4.74,0.48,4.56
			c1.168,0.752,2.749,1.092,3.84,1.92c1.879,0.601,3.456,1.504,5.52,1.92c1.4,0.601,2.883,1.117,4.32,1.681
			c2.012,0.708,4.093,1.347,4.56,3.6c-1.333,2.748-5.636,2.524-9.12,3.12c-1.895,0.348-4.937,0.82-6.96,0.24
			c-1.197-0.243-2.055-0.825-3.12-1.2c-1.604-0.796-2.499-2.301-3.6-3.6c-0.557-0.105-0.642-1.061-1.2-0.48
			c-0.539,3.221-0.561,6.959-0.96,10.32c0.348,3.148-1.131,6.548-1.68,9.6c-0.565,1.995-1.253,3.867-1.68,6
			c-0.479,1.92-1.079,3.721-1.68,5.521c-0.665,1.415-1.339,2.821-1.68,4.56c-0.32,1.841-0.947,3.373-1.2,5.28
			c0.146,0.014,0.284,0.036,0.24,0.239c2.57,0.311,6.552-0.791,7.68,0.961c0.49,0.777,0.528,1.636,0.24,2.64
			c-1,1.72-2.532,2.908-4.08,4.08c0.224,0.656,0.737,1.023,0.96,1.68c0.62,1.381,1.204,2.796,2.4,3.601c56.411,0,115.878,0,172.8,0
			c6.202,0,12.066,0.562,15.6-1.44c1.997-0.803,4.19-1.41,6.48-1.92c1.905-0.174,3.07-1.089,4.8-1.44
			c1.936-1.023,4.282-1.638,6.72-2.159c2.011-0.391,3.806-0.995,5.761-1.44c2.431-1.009,5.4-1.479,8.399-1.92
			c2.309-0.492,4.55-1.051,6.96-1.44c1.977-0.663,4.358-0.922,6.48-1.439c2.966-0.234,5.005-1.396,7.68-1.92
			c1.905-0.815,4.065-1.375,6.24-1.92c2.085,0.072,3.232-1.76,5.28-0.96c1.284,0.582,0.546,2.573-0.48,2.88c-0.08,0-0.16,0-0.24,0
			c-1.559,1.081-3.642,1.638-5.76,2.159c-2.159,0.48-4.275,1.005-6.24,1.681c-1.181,0.659-2.819,0.86-4.319,1.2
			c-3.297,0.623-6.437,1.403-9.84,1.92c-2.387,0.654-4.641,1.439-7.44,1.68c-3.45,0.391-6.242,1.438-9.36,2.16
			c-1.347,0.733-3.055,1.104-4.8,1.439c-2.045,0.195-3.396,1.085-5.28,1.44c-0.852,0.749-2.527,0.672-3.359,1.44
			c21.6,0,43.199,0,64.8,0c0-0.08,0-0.16,0-0.24c0.805-2.266,0.881-7.906-0.24-9.84c-0.398-0.571-0.398-2.79,0-3.36
			c0-0.08,0-0.16,0-0.24c0-0.96,0-1.92,0-2.88c0-0.08,0-0.16,0-0.24c-0.379-2.42-0.365-5.234-0.479-7.92
			c-0.27-3.757-0.591-8.187-0.96-12.479c-0.537-0.104-0.098-1.183-0.24-1.681c-0.376-0.984-0.364-2.355-0.48-3.6
			c-0.33-0.31-0.237-1.043-0.239-1.68c-4.271,0.11-7.81-0.511-11.28-1.2c-0.08,0-0.16,0-0.24,0c-2.912-0.112-4.952,0.647-7.2,1.2
			c-1.911,0.488-3.552,1.247-5.279,1.92c-1.207,0.953-2.886,1.435-4.561,1.92c-1.279,0.24-2.304,0.736-3.359,1.2
			c-1.246,0.754-2.78,1.22-4.08,1.92c-1.116,0.255-2.873,1.421-3.601,0c-0.023-0.744,0.108-1.331,0.48-1.681
			c1.466-0.934,3.066-1.733,4.8-2.399c1.084-0.355,2.028-0.852,3.12-1.2c1.064-0.775,2.602-1.078,3.84-1.68
			c1.896-0.425,3.239-1.401,5.04-1.92c1.458-0.941,3.305-1.496,5.28-1.92c5.962-0.81,12.714-0.108,18.479,0c0-0.08,0-0.16,0-0.24
			c1.242,0.118,3.133-0.412,3.36,0.72c0.08,0,0.16,0,0.24,0c0.468-0.973,0.333-2.547,0.96-3.36
			c-0.284-0.116-0.545-0.256-0.721-0.479C405.471,707.972,405.313,706.769,404.48,706.241z M240.8,736.481
			c-0.889,2.116-3.644,0.02-6,0c-3.938-0.942-8.623-1.137-13.2-1.44c-0.16,0-0.32,0-0.48,0c-3.121-0.159-6.879,0.319-9.6-0.24
			c-3.703-0.376-7.064-1.096-10.56-1.68c-2.5-0.46-5.06-0.86-7.2-1.68c-0.701-0.26-1.383-0.537-2.4-0.48
			c-1.01-0.245-3.08-1.804-2.16-2.399c2.622-0.935,4.715,0.245,7.44,0.479c2.773,0.508,5.628,0.933,8.16,1.681
			c2.178,0.542,4.492,0.948,7.2,0.96c1.762,0.157,4.157-0.317,5.52,0.239c2.08,0,4.16,0,6.24,0c1.284,0.156,3.195-0.314,4.08,0.24
			c0.454,0.426,1.585,0.175,2.4,0.24c2.827,0.293,5.477,0.763,7.68,1.68C239.597,734.624,241.388,735.082,240.8,736.481z
			 M259.28,621.521c1.428-3.053,4.015-4.945,7.44-6c1.238-0.202,2.566-0.313,4.08-0.24c3.739,0.82,5.604,3.516,6.96,6.72
			c-0.173,0.733,0.246,0.875,0.24,1.44c0.397,0.259,0.397,2.141,0,2.399c-0.284,0.597-0.348,1.413-0.48,2.16
			c-0.437,1.804-1.645,2.836-2.88,3.84c-0.584,0.136-1.235,0.206-1.2,0.96c-0.181,1.02-1.33,1.07-2.16,1.44
			c-2.331,0.535-3.857,0.116-6.48,0c-2.656-0.783-4.122-2.758-5.28-5.04C258.598,627.09,258.138,623.77,259.28,621.521z
			 M258.081,685.121c0.127-0.193,0.25-0.39,0.24-0.72c0.893-1.827,2.343-3.097,4.08-4.08c1.369-0.551,2.703-1.138,4.56-1.2
			c1.078-0.038,1.885,0.194,2.4,0.72c0.193,0.127,0.39,0.251,0.72,0.24c1.974,1.226,3.455,2.945,4.32,5.28
			c0.497,1.889,0.978,4.016,0.48,6.24c-0.364,1.875-1.425,3.055-2.88,3.84c-1.715,0.818-3.34,0.303-4.56,1.439
			c-0.336,0.398-2.304,0.398-2.64,0c-0.969-0.23-1.91-0.489-2.88-0.72c-1.623-0.457-2.563-1.598-3.36-2.88
			c-0.336-0.944-0.662-1.898-0.96-2.88C257.12,688.956,257.202,686.006,258.081,685.121z M274.161,735.762
			c-0.91,1.729-2.273,3.007-3.84,4.08c-1.296,0.543-2.335,1.344-3.84,1.68c-0.259,0.397-2.141,0.397-2.4,0
			c-1.19-0.25-1.996-0.884-3.12-1.2c-0.899-0.701-1.871-1.329-2.64-2.16c0.114-0.753-0.646-0.634-0.72-1.2
			c-2.859-4.106-0.205-11.027,3.12-12.72c0.08,0,0.16,0,0.24,0c1.181-1.14,2.911-1.729,5.28-1.68c0.4,0,0.8,0,1.2,0
			c3.68,0.24,5.836,2.004,7.2,4.56C275.814,729.676,275.343,733.587,274.161,735.762z M303.44,652.481
			c-3.669-0.491-7.2-1.12-10.56-1.92c-1.83-0.41-3.512-0.969-5.041-1.681c-1.626-0.533-3.195-1.125-5.04-1.439
			c-2.123-0.518-4.101-1.18-6-1.92c-1.614-0.467-3.184-0.977-5.04-1.2c-1.294-0.146-2.659-0.221-3.36-0.96c0-0.08,0-0.16,0-0.24
			c0-0.24,0-0.48,0-0.72c0.116-1.68,3.011-1.305,4.08-0.721c2.616,0.585,5.02,1.38,7.68,1.921c1.59,0.329,3.032,0.808,4.32,1.439
			c1.592,0.568,3.267,1.054,5.04,1.44c2.31,0.569,4.471,1.289,6.479,2.159c2.452,0.669,4.746,1.495,7.681,1.681
			c1.623,0.297,3.597,0.243,4.319,1.439C307.476,652.84,304.658,652.894,303.44,652.481z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M525.681,550.241c-0.328-0.872-0.74-1.659-1.199-2.4
			c0.175-0.654-0.478-0.483-0.48-0.96c-0.245-0.315-0.235-0.885-0.479-1.2c-0.16,0-0.32,0-0.48,0
			c-0.524,1.084-2.39,0.215-2.64-0.479c-1.092-0.908-2.504-1.497-3.601-2.4c-1.051-0.309-1.873-0.847-2.88-1.2
			c-0.533-0.187-0.936-0.504-1.68-0.479c-0.833-0.287-1.864-0.376-2.4-0.96c-1.503-0.337-2.914-0.767-4.08-1.44
			c-0.977-0.063-1.628-0.451-2.399-0.72c0-0.08,0-0.16,0-0.24c-0.045-0.355-0.58-0.22-0.96-0.24c-1.72-0.52-3.41-1.069-4.801-1.92
			c-1.409-0.271-2.294-1.065-3.84-1.2c-1.462-0.537-3.104-0.895-4.319-1.68c-1.891-0.75-4.015-1.266-6-1.92
			c-6.027-2.532-15.591-1.836-21.841,0c-0.592,0.208-1.661-0.062-1.68,0.72c1.622,0.778,3.738,1.062,5.28,1.92
			c1.407,0.593,2.606,1.394,3.6,2.4c1.984,1.455,3.875,3.005,5.76,4.56c0.661,0.459,1.222,1.02,1.681,1.681
			c6.308-1.053,12.799-1.921,20.399-1.681c-1.034-0.646-2.468-0.892-3.6-1.439c-2.131-0.51-4.329-0.951-6.72-1.2
			c-3.661-0.739-7.611-1.189-11.28-1.92c-0.08,0-0.16,0-0.24,0c-1.193,0.241-2.85,0.926-3.84,0c0-0.08,0-0.16,0-0.24
			c-0.185-0.904,0.293-1.146,0.24-1.92c3.763-1.763,9.561-0.66,13.68,0c2.764,0.517,5.784,0.776,8.16,1.681
			c2.051,0.349,3.689,1.109,5.52,1.68c1.947,0.453,3.747,1.053,5.04,2.16c0.657,0.224,1.023,0.736,1.681,0.96
			c0.798,0.642,1.895,0.985,2.399,1.92c1.137,2.77-2.26,2.931-4.56,2.399c-5.464-0.58-11.598-0.622-17.04,0
			c-2.597,0.204-5.22,0.381-7.44,0.961c0.857,0.982,1.483,2.196,2.16,3.359c0.782,1.139,1.503,2.337,1.92,3.84
			c0.396,1.172,0.153,2.352-0.72,2.88c0,0.08,0,0.16,0,0.24c-1.822,0.063-3.269-0.251-3.601-1.68
			c-0.187-0.053-0.278-0.201-0.239-0.48c-0.774-0.746-1.247-1.793-1.92-2.64c-0.634-2.247-2.302-3.459-3.36-5.28
			c-0.479-0.8-1.319-1.24-1.92-1.92c-0.785-0.734-1.832-1.208-2.4-2.16c-1.356-0.563-2.344-1.495-3.359-2.399
			c-1.426-0.734-2.729-1.591-4.08-2.4c-0.615-0.025-1.145-0.136-1.44-0.479c-1.315-0.284-2.512-0.688-3.6-1.2
			c-0.955,0.074-1.206-0.555-2.16-0.48c-1.465-0.375-2.962-0.718-4.08-1.439c-0.282-0.038-0.682,0.041-0.72-0.24
			c-1.371-0.229-2.364-0.836-3.36-1.44c-0.16,0-0.32,0-0.479,0c0.034-0.675-0.588-0.691-1.2-0.72c-1-0.36-2.039-0.681-2.641-1.439
			c-1.388-0.533-2.31-1.53-3.84-1.921c-1.156-0.443-2.362-0.837-3.12-1.68c-1.232-0.607-2.406-1.273-3.84-1.68
			c-1.951-0.609-3.745-1.375-5.52-2.16c-2.289-0.511-4.092-1.509-6.721-1.68c-2.17-0.47-4.37-0.91-6.239-1.681
			c-1.942-0.297-3.343-1.138-5.28-1.439c-1.152-0.208-2.127-0.594-2.88-1.2c-0.615-0.505-1.51-0.73-2.4-0.96
			c-0.391,0.253,0.658,1.086,0.72,1.68c0.639,1.762,1.364,3.437,1.681,5.521c0.327,1.992,0.642,3.998,1.439,5.52
			c0.727,1.113,1.595,2.086,2.4,3.12c0.227,0.972,1.168,1.689,0.479,2.88c-2.536,1.591-3.678-1.762-5.04-2.88
			c-0.532-1.579-1.414-2.266-1.68-3.6c-0.959-1.441-1.028-3.772-1.68-5.521c-0.365-1.715-1.128-3.032-1.681-4.56
			c-0.775-0.664-1.194-1.686-1.92-2.4c-0.621-0.258-0.892-0.867-1.68-0.96c-0.745-0.375-1.259-0.981-1.92-1.439
			c0.242,2,1.286,4.471,0.96,6.239c0.544,4.097,0.092,9.188,0.24,13.681c0.512,6.322-1.349,11.739-1.92,17.279
			c0.026-0.253-0.624,1.398,0.239,0.721c0.097-0.624,0.717-0.724,0.721-1.44c0.541-1.379,1.209-2.631,1.92-3.84
			c0.479-0.961,0.961-1.919,1.439-2.88c0.611-1.55,1.147-3.174,1.92-4.561c1.133-1.842,1.172-4.871,0-6.72
			c-0.535-1.864-0.986-3.813-1.68-5.52c-0.397-0.83-0.819-2.395,0-3.12c0.673-0.276,1.248-0.276,1.92,0
			c1.158,1.082,1.759,2.721,2.16,4.56c0.277,2.096,1.297,4.278,1.44,5.76c0.432,0.841,0.353,3.227,0,4.08
			c-0.469,1.451-1.249,2.592-1.681,4.08c-0.007,0.878-0.974,2.863-1.68,3.84c-0.452,0.988-1.197,1.684-1.44,2.881
			c-0.482,1.277-1.142,2.378-1.92,3.359c-0.5,0.779-0.995,1.564-1.439,2.4c-0.755,1.325-1.394,2.767-2.4,3.84
			c-0.364,0.756-1.068,1.171-1.2,2.16c-0.499,0.94-1.281,1.599-1.92,2.399c-0.449,1.07-1.124,1.916-1.439,3.12
			c-0.417,1.504-1.139,2.702-1.92,3.84c-0.519,0.922-1.346,1.535-1.681,2.641c-0.527,0.992-1.125,1.914-1.92,2.64
			c-0.417,0.782-1.198,1.201-1.439,2.16c-0.784,1.057-1.357,2.322-2.4,3.12c-0.359,1.001-1.47,1.25-1.68,2.4
			c0.753,0.113,0.634-0.646,1.2-0.721c1.364-1.435,2.875-2.725,4.319-4.08c0.146-0.014,0.284-0.035,0.24-0.239
			c1.615-1.346,2.933-2.988,4.56-4.32c0.717-0.644,1.558-1.163,1.921-2.16c1.392-1.408,2.547-3.053,4.079-4.32
			c0-0.08,0-0.159,0-0.239c1.028-1.133,1.788-2.533,2.881-3.601c0.315-0.645,0.644-1.275,0.96-1.92
			c0.64-1.601,1.377-3.103,2.159-4.56c0.423-1.498,1.333-2.508,1.681-4.08c0.157-1.686,1.522-4.235,1.92-6.48
			c0.277-0.923,0.571-1.828,0.96-2.64c0.55-0.656,2.373-0.677,2.64,0.24c0.468,2.891-1.008,4.571-1.439,7.199
			c-0.414,1.986-1.257,3.544-1.92,5.28c-0.45,1.07-1.125,1.915-1.44,3.12c-0.375,1.545-1.015,2.825-1.92,3.84
			c-0.122,1.238-1.259,1.461-1.44,2.641c-0.885,1.274-1.669,2.649-2.88,3.6c-0.074,0.565-0.833,0.446-0.72,1.2
			c-4.21,4.75-8.665,9.255-13.44,13.439c-0.842-0.042-0.789,0.811-1.439,0.96c-0.851,0.989-1.98,1.7-3.12,2.4
			c-1.096,0.345-1.691,1.188-2.88,1.44c-1.094,0.826-3.442,0.397-4.08,1.68c-0.845,0.436-1.87,0.689-1.68,2.16
			c1.577,1.622,3.622,2.777,5.76,3.84c1.202,0.558,2.7,0.819,3.6,1.68c1.915,0.565,3.571,1.389,5.521,1.92
			c1.445,0.635,3.113,1.047,4.319,1.92c1.23,0.37,2.166,1.035,3.36,1.44c1.406,0.435,2.759,0.921,3.84,1.68
			c1.728,0.593,3.516,1.125,5.28,1.681c1.828,0.651,3.885,1.074,5.28,2.159c0.873,0.247,1.484,0.756,2.399,0.96
			c1.585,0.896,3.382,1.579,4.801,2.641c0.982,0.057,1.265,0.815,2.159,0.96c1.471,0.529,3.088,0.912,4.08,1.92
			c1.346,0.415,2.353,1.167,3.601,1.68c1.313,0.526,2.686,0.995,3.6,1.92c0.899,0.222,1.262,0.979,2.16,1.2
			c1.275,0.805,2.558,1.603,3.6,2.641c0.75,0.29,1.044,1.036,1.92,1.199c0.786,0.574,1.695,1.026,2.4,1.681
			c0.503,0.697,1.447,0.952,2.16,1.439c1.301,0.779,2.611,1.549,3.84,2.4c0.835,0.525,1.657,1.063,2.64,1.439
			c0.979,0.622,2.093,1.108,2.881,1.921c0.621,0.258,0.892,0.867,1.68,0.96c1.386,0.854,2.85,1.63,4.08,2.64
			c0.755,0.285,1.27,0.811,2.16,0.96c1.381,0.619,3.15,0.849,4.319,1.68c1.746,0.895,4.645,0.636,6,1.92
			c2.05,1.312,5.498,1.223,7.92,2.16c2.619,0.102,4.636,0.806,6.961,1.2c2.365,0.434,4.342,1.258,6.239,2.16
			c2.394,0.646,4.663,1.417,7.681,1.439c3.985,0.711,6.648-0.085,8.16-2.399c0-0.08,0-0.16,0-0.24
			c1.104-1.137,1.873-2.606,3.119-3.6c2.317-2.803,4.341-5.9,6.24-9.12c-0.08,0-0.16,0-0.24,0c1.365-1.915,2.313-4.247,3.841-6
			c0.42-0.86,0.872-1.688,1.439-2.4c0.397-1.603,1.103-2.897,1.92-4.08c0.438-1.082,0.755-2.285,1.44-3.12
			c0.155-0.964,0.259-1.98,0.72-2.64c0.063-1.137,0.55-1.85,0.48-3.12c0.08,0,0.159,0,0.239,0c0.134-1.467,0.293-2.907,0.721-4.08
			c0.143-0.497-0.297-1.576,0.239-1.68c0.457-2.583-0.328-6.408,1.2-7.92c0.054-0.187,0.007-0.474,0.24-0.48
			c-0.326-0.396,0.196-1.4,0-2.399c0.402-0.398,0.19-1.409,0.24-2.16c-1.227-8.577-2.021-19.727-1.44-28.561
			c-0.025-2.266,1.011-3.47,1.2-5.52c0.519-0.602,0.488-1.752,1.2-2.16c0.013-0.307,0.308-0.332,0.24-0.72
			c0.505-0.615,0.51-1.729,0.96-2.4C525.302,551.142,525.604,550.805,525.681,550.241z M484.641,564.401
			c-0.543-0.258-1.055-0.545-1.439-0.96c0.614,0.185,1.36,0.239,1.68,0.72C484.734,564.175,484.597,564.197,484.641,564.401z
			 M456.081,538.721c0.9,0.699,1.7,1.5,2.399,2.4C457.58,540.422,456.78,539.621,456.081,538.721z M504.801,570.401
			c-0.318-0.241-0.893-0.228-0.96-0.721c-0.478-0.242-1.025-0.415-1.2-0.96c-1.394-0.606-2.338-1.662-3.6-2.399
			c-2.047-1.794-4.821-2.858-6.96-4.561c-1.162-0.358-2.117-0.923-2.88-1.68c-1.374-0.626-2.743-1.257-3.84-2.16
			c-0.727-0.154-1.386-0.375-1.44-1.2c-1.037-2.265,2.586-1.702,3.12-0.479c0.867,0.332,1.345,1.056,2.4,1.2
			c1.044,0.635,2.302,1.058,3.119,1.92c0.507,0.213,0.71,0.73,1.44,0.72c1.356,0.884,2.973,1.507,4.08,2.64
			c0.477,0.003,0.305,0.655,0.96,0.48c5.311,3.329,10.226,7.054,14.4,11.52c0,0.08,0,0.16,0,0.24
			c0.213,1.077-0.257,1.504-0.961,1.68C510.313,577.184,506.791,570.854,504.801,570.401z M517.521,602.801
			c-0.396-0.259-0.396-2.141,0-2.399C517.521,601.201,517.521,602.001,517.521,602.801z M517.761,597.761
			c-0.554-0.726-0.084-2.476-0.24-3.6C518.075,594.887,517.605,596.637,517.761,597.761z M519.681,556.001
			c-1.869,1.498-3.567-0.763-5.76-0.48c-2.072-0.567-4.463-0.817-6.72-1.199c-0.96,0-1.92,0-2.88,0c0-0.08,0-0.16,0-0.24
			c-0.721,0-1.44,0-2.16,0c-3.077,0.102-7.612-0.377-10.561-1.2c-1.571-0.348-2.976-0.864-4.56-1.2
			c-2.227-0.379-6.119-2.396-3.6-4.56c0.079,0,0.159,0,0.239,0c0.32,0,0.641,0,0.96,0c1.642,0.839,3.569,1.391,5.28,2.16
			c2.695,0.824,5.669,1.371,9.12,1.439c4.987,0.293,10.557,0.003,14.64,1.2c1.262,0.019,2.257,0.303,3.12,0.72
			c1.067,0.294,2.444,0.275,2.88,1.2C520.5,554.232,520.5,555.609,519.681,556.001z"/>
			</g>
			<g id="gold_1_">
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M360.32,370.241c-0.558-0.163-0.643-0.798-1.199-0.96
			c-0.733,0.227-0.753,1.167-1.681,1.2c-0.07,0.489-0.649,0.471-0.72,0.96c1.008,0.512,2.023,1.017,2.88,1.68
			c0.194,0.045,0.239,0.241,0.48,0.24c0.61-0.35,0.464-1.455,1.199-1.68c0.352-0.209,0.761-0.359,0.721-0.96
			C361.123,370.878,361.08,370.201,360.32,370.241z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M235.76,405.281c-0.342-0.778-1.164-1.077-2.4-0.96
			c-0.275,1.27-0.109,1.779,0,3.12c0.704-0.016,0.892-0.548,1.2-0.96c0.684-0.668,1.971,0.097,2.16,0.72
			c0.949,0.971,1.799,2.042,1.44,4.32c0.224,1.804-3.836,1.437-2.64-0.24c0.014-0.146,0.036-0.284,0.24-0.24
			c0.465-0.038,1.215-0.994,0.48-1.44c0.413,0.991-1.088,1.287-1.44,0.72c-0.553,0.073-0.267-0.693-0.72-0.72
			c0.106,0.773,0.459,1.3,0.48,2.16c0.187,0.053,0.279,0.201,0.24,0.48c0.833,1.654,2.528,1.33,4.56,1.44c0-0.88,0-1.76,0-2.64
			c-0.275-1.404-0.764-2.596-1.68-3.36C237.003,406.917,236.215,406.266,235.76,405.281z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M286.4,422.801c-1.896-1.834,0.079-6.342,1.44-6.48
			c0-0.16,0-0.32,0-0.479c-0.183-1.337-0.772-2.268-1.92-2.64c-0.847,1.073-1.214,2.625-1.2,4.56c0.034,1.806-0.213,3.893,0.48,5.04
			c0.054,0.186,0.006,0.473,0.24,0.48c0.484,0.315,0.812,0.787,0.96,1.439c0.315-0.085,0.377-0.422,0.72-0.48
			c0.187-0.053,0.279-0.201,0.24-0.479c0.402-1.839,1.065-3.415,0.96-5.761C287.14,419.06,287.8,421.961,286.4,422.801z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M265.76,587.201c1.791,0.028,3.705,0.615,5.04-0.24
			c0-0.08,0-0.16,0-0.24c0.271-0.289,0.685-0.435,0.48-1.199c1.329-1.393,2.728-2.713,2.4-5.761c-0.143-1.617-0.634-2.887-1.44-3.84
			c-1.032-0.968-1.659-2.341-3.36-2.64c0-0.08,0-0.16,0-0.24c-2.55,0.01-4.293,0.826-5.76,1.92c-0.805,0.875-1.502,1.857-2.16,2.88
			c-0.647,1.102-0.602,3.39-0.24,4.8C261.683,584.878,263.143,586.619,265.76,587.201z M262.641,577.361
			c0.157-0.883,0.885-1.195,1.44-1.681c1.313-0.714,3.824-1.06,5.04,0c0.006,0.233,0.293,0.187,0.48,0.24
			c0.776,0.185,1.256,0.664,1.44,1.44c0.033,0.446,0.158,0.802,0.48,0.96c0.492,0.742,0.382,2.934-0.48,3.12
			c-0.132,1.147-1.383,1.177-2.16,1.68c-0.332,0.707-1.557,0.528-1.92,0c0.029-0.611,0.349-0.931,0.96-0.96
			c1.122-0.318,2.091-0.789,2.4-1.92c0.105-0.825-0.095-1.346-0.24-1.92c-0.753-1.327-2.224-1.937-4.56-1.681
			c-0.158,0.322-0.513,0.447-0.96,0.48c-1.187,0.931-1.203,3.785-0.48,5.28c0.451,0.908,1.338,1.381,2.64,1.439
			c0.556-0.076,0.921,0.039,1.2,0.24c0,0.32,0,0.64,0,0.96c-0.913,0.55-2.605,0.135-3.12-0.48c-0.857-0.103-1.317-0.603-1.68-1.199
			C262.048,582.007,261.867,579.254,262.641,577.361z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M340.16,592.241c-1.097-0.103-2.034-0.365-3.359-0.24
			c-0.782,0.339-1.693,0.547-2.641,0.72c-0.856,0.104-1.316,0.604-1.68,1.2c-1.022,1.533-0.067,3.239,0.72,4.32
			c0.84,1,1.602,2.079,3.12,2.399c0.889,0.151,1.339,0.742,1.2,1.92c1.062-0.058,1.749-0.491,2.88-0.479
			c1.148-0.132,1.771-0.789,2.641-1.2c0-0.16,0-0.32,0-0.479c0.736-1.438,0.518-3.786,0-5.28
			C342.575,593.666,341.615,592.706,340.16,592.241z M342.32,598.961c-0.717,0.803-1.772,1.268-3.12,1.44
			c-0.089,0.802-1.755,0.539-1.92,0c-1.756-0.675-3.017-3.191-1.92-5.28c0.568-0.632,0.953-1.447,1.92-1.68
			c0.907-0.723,2.79-0.437,3.36,0.479c0.944,0.256,1.461,0.939,1.68,1.92C343.032,596.22,343.032,598.582,342.32,598.961z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M339.921,595.361c-1.12-0.711-2.392-0.401-2.88,0.72
			c0.059,0.261,0.477,0.163,0.479,0.479c1.307,0.275,1.66,2.303,3.36,1.44C341.612,597.018,340.67,595.66,339.921,595.361z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M234.561,597.281c0-0.08,0-0.16,0-0.24
			c-0.475-0.689-1.498,0.083-2.16,0c-0.166,0.474-0.64,0.64-1.2,0.72c0.053,0.188,0.201,0.279,0.48,0.24
			c1.145-0.265,1.454,0.307,1.92,0.72c0.809,0.16,0.997,2.19,0.48,2.88c-0.302,0.099-0.491,0.31-0.48,0.721
			c-0.343,0.938-0.982,1.577-1.92,1.92c-0.789,1.218-3.618,1.271-3.84-0.48c-0.023-0.377,0.032-0.832-0.48-0.72
			c-0.914-0.237-0.435-2.05,0.24-2.16c0.581-0.379,1.061-0.858,1.44-1.439c-0.024-0.27,0.349-0.374,0-0.48
			c-1.464,0.376-1.842,1.838-2.64,2.88c-0.067,0.813-0.382,1.378-0.24,2.4c0.559,0.642,0.825,1.575,1.44,2.16
			c0.987,0.577,2.81,0.615,4.08,0.239c1.503-0.417,2.504-1.336,3.12-2.64c0.618-1.539,1.734-3.325,0.96-5.52
			C235.128,598.313,235.253,597.389,234.561,597.281z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M231.44,603.041c0.839-0.911,1.374-1.433,0.96-3.12
			c0-0.08,0-0.16,0-0.24c-1.534-0.014-2.46,0.58-2.88,1.681c-0.184,0.566-0.473,1.658,0,2.16
			C230.523,603.724,230.654,603.055,231.44,603.041z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M271.76,619.361c-1.562-1.099-4.334-0.741-6,0
			c-0.56,0.08-1.034,0.246-1.2,0.72c-0.326,0.714-1.083,0.997-1.68,1.44c0.013,0.412-0.185,0.614-0.48,0.72
			c-0.989,1.565-0.798,4.134,0,5.76c0.08,0.56,0.246,1.034,0.72,1.2c0.661,0.459,1.221,1.019,1.68,1.68
			c1.365,0.546,5.088,2.232,6,0.24c0.342-0.938,0.982-1.578,1.92-1.92c0.481-0.399,1.115-0.645,1.2-1.44
			c0.671-1.601,0.973-3.765,0.24-5.52C273.732,620.909,272.715,620.166,271.76,619.361z M272,625.361
			c-0.184,0.775-0.664,1.256-1.44,1.439c-0.613,0.107-1.104,0.337-1.44,0.721c-0.362,0.961-2.097,1.552-2.88,0.72
			c-0.51-0.529-0.99-1.09-1.44-1.681c-0.808-0.742-0.719-3.845,0.24-4.319c0.049-1.471,2.025-1.016,2.64-1.92
			C270.76,619.156,273.609,622.609,272,625.361z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M265.76,623.201c-0.093,1.453,0.017,2.704,0.48,3.6
			c0.882-0.157,1.614-0.466,2.64-0.479c0.447-0.033,0.802-0.158,0.96-0.48c0.765-0.681,1.713-1.894,0.96-3.359
			C270.078,620.275,266.302,621.686,265.76,623.201z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M266.96,729.762c-1.078-0.118-1.798,0.121-2.64,0.239
			c-0.716,0.004-0.816,0.624-1.44,0.721c0,0.159,0,0.319,0,0.479c-0.908,2.085,0.71,3.352,3.12,2.88
			c0.859-0.181,1.525-0.555,1.68-1.439c-0.053-0.773,0.424-1.016,0.24-1.92C267.497,730.504,267.177,730.185,266.96,729.762z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M368.721,368.32c-0.49-2.31-1.343-4.257-3.36-5.04
			c-1.194-0.085-1.888-0.671-3.12-0.72c-1.237-0.202-2.566-0.313-4.08-0.24c-0.521,0.359-1.351,0.409-2.159,0.48
			c-1.317,0.203-2.412,0.629-3.12,1.44c-0.539,0.741-1.18,1.38-1.92,1.92c-1.211,0.39-0.697,2.502-1.2,3.6c0,0.96,0,1.92,0,2.88
			c0.419,2.061,1.111,3.848,2.399,5.04c0.661,0.459,1.222,1.019,1.681,1.68c0.166,0.474,0.64,0.64,1.2,0.72
			c1.914,0.389,4.042,1.076,6.239,0.48c1.608-0.392,3.031-0.969,3.841-2.16c0.298-0.742,0.792-1.288,1.439-1.68
			c0.714-0.487,1.196-1.204,1.44-2.16C368.965,372.994,369.152,370.664,368.721,368.32z M364.4,371.681
			c-0.437,0.683-1.402,0.837-1.92,1.44c-0.233,0.006-0.187,0.293-0.24,0.48c-0.262,1.018-0.18,2.379-0.72,3.12
			c-0.515,0.518-1.341,0.123-1.68-0.24c-0.69-0.43-1.191-1.049-1.44-1.92c-0.392-0.808-1.028-1.372-2.16-1.44
			c-0.874,0.074-1.562-0.038-2.159-0.24c-1.026-1.367-0.219-3.086,1.439-3.12c0.279,0.039,0.427-0.053,0.48-0.24
			c0.539-0.741,1.179-1.381,1.92-1.92c0.83-0.45,0.213-2.347,1.2-2.64c0.501-0.102,0.635,0.165,0.96,0.24
			c0.719,0.641,0.459,2.26,0.96,3.12c-0.072,0.633,0.332,0.788,0.96,0.72c1.017,0.343,2.624,0.096,3.6,0.48
			C366.196,370.619,365.555,371.636,364.4,371.681z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M265.041,690.161c0-0.16,0-0.32,0-0.479
			c0.755-0.365,1.524-0.717,2.4-0.961c3.492-1.814-2.776-5.014-3.6-1.92C263.494,688.091,263.253,690.324,265.041,690.161z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M261.44,691.602c0.82,0.779,1.75,1.449,2.88,1.92
			c0.863,0.417,1.858,0.701,3.12,0.72c0.283-0.357,0.603-0.678,0.96-0.96c0.407-0.473,1.116-0.644,1.92-0.72
			c0.611-0.029,0.931-0.35,0.96-0.96c1.076-2.123,0.061-4.791-0.72-6.48c-1.415-4.458-9.188-2.59-9.6,1.44
			c-0.344,1.405-0.181,2.625,0,4.08C260.994,691.088,261.118,691.443,261.44,691.602z M262.88,686.321
			c0.226-1.864,3.936-2.666,5.28-1.2c0.771-0.211,0.329,0.791,0.96,0.72c0.084,0.315,0.422,0.378,0.48,0.721
			c0.42,0.881,0.089,2.064-0.72,2.399c-0.671,0.369-1.354,0.727-1.92,1.2c-0.995,0.261-0.338,0.782-0.72,1.44
			c-1.189-0.011-2.586,0.186-2.88-0.721C262.233,690.335,262.068,687.336,262.88,686.321z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C7BA73" d="M270.081,726.642c-0.529-0.352-1.031-0.729-1.92-0.721
			c-1.088-0.6-3.13-0.096-4.32,0c-1.177,0.183-2.096,0.624-2.64,1.44c-0.635,0.645-0.944,1.615-1.68,2.16
			c-0.187,0.053-0.279,0.201-0.24,0.479c-0.561,1.612-0.755,4.435,0,6c1.04-0.239,1.332,0.269,1.92,0.48
			c0.828,0.292,1.447,0.793,1.92,1.439c1.632,1.085,4.84,0.416,5.52-0.96c0.33,0.011,0.527-0.112,0.72-0.239
			c0.54-0.741,1.179-1.381,1.92-1.921c0.013-0.307,0.308-0.332,0.24-0.72c0.604-0.675,0.546-2.014,0.96-2.88
			c0.262-1.302-0.484-1.596-0.48-2.64C271.276,728.006,270.943,727.059,270.081,726.642z M269.121,732.161
			c-0.079,0.562-0.289,0.991-0.48,1.44c-0.058,0.342-0.396,0.404-0.48,0.72c-0.74,0.461-1.206,1.194-2.4,1.2
			c-0.324,0.538-1.805,0.224-1.92-0.24c-2.257-0.239-3.278-2.955-1.92-4.8c0.08,0,0.16,0,0.24,0c0.128-0.912,0.936-1.145,1.68-1.44
			c0.475-0.573,2.263-1.086,3.12-0.479c0.385,0.415,0.897,0.703,1.44,0.96C269.029,729.862,269.781,731.328,269.121,732.161z"/>
			</g>
			<g id="skin_1_">
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M49.52,537.521c0-1.6,0-3.199,0-4.8
			C49.121,533.767,49.121,536.476,49.52,537.521z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M56.24,516.161c-0.018-0.898,0.101-1.659,0.48-2.16
			c0-0.24,0-0.48,0-0.72C56.368,513.898,55.66,515.409,56.24,516.161z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M192.561,211.12c0,0.08,0,0.16,0,0.24
			c0.552,0.568,0.086,2.154,0.24,3.12c0,0.08,0,0.16,0,0.24c0.371,1.629,0.413,3.587,0.48,5.52c0.149,0.651-0.305,1.905,0.24,2.16
			c0.982,2.312,0.163,4.481,0.72,6.48c0.198,0.602-0.466,2.066,0.48,1.92c0.637,1.533-0.035,4.794,0.24,6c0,0.4,0,0.8,0,1.2
			c0.535-0.351,2.777-1.06,4.08-1.44c1.031-0.409,2.376-0.503,3.84-0.48c0.887-0.399,3.433-0.399,4.32,0
			c1.311-0.03,2.56,0,3.36,0.48c0.204,0.356,0.604,0.516,1.2,0.48c0.943,0.017,1.804,0.116,2.4,0.48
			c0.349,0.131,1.241-0.281,1.2,0.24c1.897,0.103,3.636,0.364,5.76,0.24c1.243,0.037,2.805-0.245,3.12,0.72
			c-0.017,0.977,0.783,1.137,0.48,2.4c-0.613,0.427-1.097,0.983-1.92,1.2c-0.16,0-0.32,0-0.48,0c-0.014,0.146-0.036,0.284-0.24,0.24
			c0,0.08,0,0.16,0,0.24c1.604-0.091,2.056,1.73,1.44,3.12c-2.916,1.004-6.063,1.777-9.84,1.92c-0.333,0.627-2.037-0.117-2.4,0.48
			c-0.729,0.15-2.067-0.307-2.4,0.24c1.013,0.107,1.21,1.03,2.64,0.72c1.192-0.408,2.699-0.501,4.32-0.48c0.08,0,0.16,0,0.24,0
			c0.677-0.362,1.656-0.423,2.64-0.48c2.089,0.084,4.38-1.065,6,0c1.474-0.033,1.673,1.208,2.88,1.44
			c1.066,0.213,1.214,1.346,1.92,1.92c-0.001,0.241,0.194,0.286,0.24,0.48c1.016,0.664,1.57,1.79,2.4,2.64
			c0.668-0.453-0.612-0.969-0.24-1.92c-1.644-4.159-6.746-7.864-5.52-13.92c0.798,0.242,2.611-0.531,2.64,0.48
			c1.323,0.357,2.162,1.198,2.88,2.16c0.704,0.017,0.892,0.549,1.2,0.96c1.456,0.704,2.303,2.017,3.36,3.12
			c1.241,0.679,2.229,1.611,3.12,2.64c0.44,0.28,0.813,0.627,1.2,0.96c0.837,0.203,1.211,0.869,1.44,1.68
			c0.241,2.444,0.647,6.582-0.24,8.64c0.168,1.048-0.408,1.352-0.24,2.4c-0.6,1.16-0.689,2.831-1.44,3.84
			c0.077,0.557-0.039,0.921-0.24,1.2c0.247,0.735-0.07,2.964-0.72,3.6c0,0.32,0,0.64,0,0.96c-0.309,0.979-2.841,2.729-3.6,0.96
			c-0.422-2.298-1.279-4.161-1.44-6.72c-0.602-2.838-1.042-5.837-1.2-9.12c0-0.4,0-0.8,0-1.2c-1.087-0.367,0.127,1.567-0.96,1.2
			c0.724,0.898-1.641,0.313-2.4,0.48c0.068-0.627-0.087-1.032-0.72-0.96c-1.634,2.855-6.311,3.918-9.36,4.56c0,0.08,0,0.16,0,0.24
			c0.566,0.013,1.112,1.263,0.48,1.68c-2.141,3.516-10.342,2.391-12.24-0.48c-0.776-0.184-1.256-0.664-1.44-1.44
			c-0.729-1.591-2.53-2.11-2.16-4.8c0.101-0.859,0.765-1.155,1.68-1.2c-0.04-1-0.083-2.003,0.48-2.4
			c-2.78,0.14-5.109-0.171-7.68-0.24c0.202,0.279,0.316,0.644,0.24,1.2c0.71,1.129,1.025,2.655,1.68,3.84
			c0.019,1.101,0.61,1.63,0.48,2.88c-2.175,0.813-3.762-1.035-4.32-2.64c0.31-0.938-0.79-0.938-0.48,0
			c0.449,0.67,0.455,1.785,0.96,2.4c0.157,1.203,0.912,1.808,0.96,3.12c1.29,1.159,0.537,3.002,0.96,4.8
			c0.568,0.873,0.667,2.214,1.2,3.12c0.212,1.074,1.317,2.515,0.96,3.36c0.245,0.315,0.235,0.885,0.48,1.2
			c0.654,1.142-0.344,2.69,0,4.32c0.75,3.33,2.763,5.396,3.84,8.4c0.523,0.757,1.076,1.485,1.2,2.64
			c0.753,0.527,0.336,2.224,1.2,2.64c0.599,0.922,1.241,1.799,1.44,3.12c0.093,0.867,0.318,1.602,0.72,2.16
			c0.498,0.623,0.817,1.423,0.96,2.4c0.53,0.83,1.188,1.532,1.2,2.88c0.647,2.473,1.469,4.771,2.4,6.96
			c0.148,0.652,0.476,1.124,0.96,1.44c1.501,1.458,3.13,2.79,4.8,4.08c0.652,0.148,1.124,0.476,1.44,0.96
			c1.484,0.436,2.514,1.326,3.36,2.4c1.557,0.683,2.993,1.487,4.08,2.64c0.871,0.249,1.49,0.75,1.92,1.44
			c0.9-0.02,0.881,0.879,1.68,0.96c0.957,0.403,1.573,1.147,2.16,1.92c0.796,0.084,1.042,0.719,1.44,1.2
			c0.146,0.014,0.284,0.036,0.24,0.24c1.077,0.363,1.752,1.128,2.4,1.92c0.922,0.598,1.473,1.567,2.64,1.92
			c1.14,0.939,2.18,1.979,3.12,3.12c0.204-0.044,0.226,0.093,0.24,0.24c0.187,0.054,0.474,0.007,0.48,0.24
			c1.059,0.941,2.24,1.76,3.36,2.64c1.206,0.395,2.433,0.768,4.08,0.72c3.464,0.336,6.825,0.211,10.08-0.24
			c1.49,0.13,2.233-0.486,3.6-0.48c0.812-0.759,3.313-0.936,4.56-0.48c1.103-0.897,2.562-1.438,3.36-2.64c0-0.16,0-0.32,0-0.48
			c0.771-0.269,1.237-0.843,1.44-1.68c0.734-0.707,1.355-1.525,2.16-2.16c1.028-1.372,2.117-2.683,3.36-3.84
			c0.051-0.27,0.267-0.374,0.48-0.48c0.987-1.653,2.334-2.946,3.6-4.32c0.051-0.269,0.267-0.374,0.48-0.48
			c1.281-2.079,3.023-3.697,4.801-5.28c0.285-1.394,2.041-1.318,2.159-2.88c0.08,0,0.16,0,0.24,0c0.051-0.27,0.267-0.374,0.479-0.48
			c0.988-1.012,1.703-2.297,2.881-3.12c0.941-0.578,1.516-1.524,2.64-1.92c0.175-0.545,0.722-0.718,1.2-0.96
			c1.812-1.628,3.854-3.026,5.039-5.28c0.19-1.25,1.141-1.74,1.201-3.12c0.446-1.313,1.045-2.474,1.439-3.84
			c0.528-1.152,0.75-2.61,1.439-3.6c0.493-1.507,0.66-3.34,0.961-5.04c0.172-1.268,0.326-2.554,0.72-3.6
			c-0.012-1.211,0.094-2.306,0.479-3.12c0.122-1.719,0.291-3.389,0.721-4.8c0.002-0.637-0.091-1.371,0.24-1.68
			c0.17-0.869,0.09-1.99,0.479-2.64c-0.031-0.592-0.036-1.156,0.24-1.44c-0.147-0.876-0.124-1.936,0.24-2.88
			c-0.013-1.211,0.093-2.306,0.479-3.12c0.047-1.154,0.115-2.285,0.48-3.12c-0.218-0.503-1.355-0.085-1.44-0.72
			c-0.646-1.033-1.128-2.231-0.96-4.08c-0.53-1.63-0.553-3.768-0.721-5.76c-0.391-1.449-0.681-2.999-0.719-4.8c0-0.08,0-0.16,0-0.24
			c0.121-2.122-0.674-3.327-0.721-5.28c-0.425-0.695-0.402-1.838-0.72-2.64c-0.767-1.073-0.44-3.239-1.2-4.32c0,0.08,0,0.16,0,0.24
			c-0.473-0.407-0.644-1.116-0.72-1.92c-0.326-0.794-0.782-1.458-0.96-2.4c-0.536-1.704-2.288-3.36-2.16-4.32
			c-0.58-0.136-0.707-0.906-1.2-1.68c-0.655-1.345-1.394-2.606-2.159-3.84c-0.523-0.757-1.076-1.484-1.201-2.64
			c-0.356-0.204-0.347-0.773-0.479-1.2c-0.804-0.637-0.861-2.019-1.2-3.12c0.007-1.367-0.609-2.11-0.48-3.6
			c-0.401-0.398-0.19-1.409-0.239-2.16c-0.865-2.415,0.134-6.695-0.96-8.88c-0.584,0.646-1.916,0.01-2.641,0
			c0.011-0.33-0.113-0.527-0.24-0.72c-0.805-3.034-0.348-7.332-0.719-10.8c0.424-3.066-1.431-5.127-0.721-7.68
			c-0.455-0.048-0.81-1.4-0.24-1.68c-0.006-0.233-0.293-0.186-0.479-0.24c-0.077-0.557,0.038-0.922,0.239-1.2
			c-0.155-0.244-0.551-0.248-0.479-0.72c0.08,0,0.16,0,0.24,0c-0.082-1.864-1.657-5.462-2.4-8.16c-3.325,3.075-8.47,4.33-12.24,6.96
			c-0.9,0.78-1.875,1.485-3.12,1.92c-1.328,1.392-3.317,2.123-4.56,3.6c-0.134,0.346-0.374,0.585-0.72,0.72
			c-2.46,2.66-5.02,5.22-7.68,7.68c-0.662,0.618-1.102,1.458-1.92,1.92c-1.73,1.71-3.288,3.592-5.04,5.28
			c-1.144,1.416-2.599,2.521-4.08,3.6c-0.36,0.28-0.587,0.693-1.2,0.72c-1.032,0.567-1.119,2.081-1.92,2.88
			c-0.107,0.613-0.336,1.104-0.72,1.44c-0.061,0.899-0.377,1.543-0.96,1.92c-0.432,0.38-2.026,0.838-2.64,0.24
			c-1.391-0.369-2.063-1.457-2.88-2.4c-0.968-0.392-1.831-0.889-2.4-1.68c-0.431,0.369-0.193,1.407-0.96,1.44
			c-0.282,0.842-2.859,0.685-2.88-0.24c-0.548-0.412-1.212-0.708-1.68-1.2c-0.926-0.434-1.675-1.044-2.4-1.68
			c-1.285-0.396-2.143-1.218-2.88-2.16c-0.58-0.3-0.55-1.21-1.44-1.2c-1.137-0.543-1.994-1.366-2.88-2.16
			c-1.969-1.392-4.193-2.527-6-4.08c-3.039-2.241-5.819-4.742-7.92-7.92c-1.559-1.961-3.301-3.739-4.56-6
			c-0.493-0.067-0.479-0.641-0.72-0.96c-1.691-0.171-2.265,0.775-2.64,1.92c-1.774,3.105-3.285,6.474-5.04,9.6
			c-0.373,1.203-0.069,1.635-0.96,2.16c-0.431,1.409-0.753,2.926-1.44,4.08c-0.042,1.288-1.076,2.313-0.24,3.12
			C192.696,199.416,192.465,205.745,192.561,211.12z M228.8,269.92c-0.146,0.734-0.509,1.25-0.72,1.92
			c-0.6,0.84-0.717,2.163-1.44,2.88c-0.46,0.821-0.855,1.705-1.68,2.16c0.088,0.408-0.188,0.453-0.48,0.48
			c-0.423,1.028-2.335,0.343-2.64-0.24c0.043-4.037,3.221-4.939,5.04-7.2c0.435-1.166,1.486-1.714,2.16-2.64
			c0.923-1.637,1.299-3.821,2.4-5.28c-0.11-0.75,0.513-0.768,1.2-0.72c0.249,0.614,0.717,1.312,0,1.68
			C231.006,264.926,229.792,267.312,228.8,269.92z M262.641,329.92c-0.914,0.114-1.499-0.102-1.68-0.72
			c-0.906-0.988-1.543-5.802,0.72-5.76c0.336,1.424,0.523,2.997,0.72,4.56C263.131,328.02,262.963,329.569,262.641,329.92z
			 M265.28,312.4c-0.381,1.059-1.281,1.599-2.16,2.16c-0.711,0.489-1.543,0.857-2.88,0.72c-1.176,0.056-1.817-0.423-3.12-0.24
			c-1.521,0.001-3.141,0.101-4.08-0.48c-0.853-0.348-0.918-1.481-0.96-2.64c0.712-0.888,2.389-0.811,3.36-1.44
			c0.62-0.9,1.983-1.057,3.12-1.44c0.757,0.117,0.848-0.433,1.68-0.24c0.348-0.533,1.429-0.332,1.92-0.72
			c0.259-0.397,2.141-0.397,2.4,0c0.478,0.242,1.025,0.415,1.2,0.96c0.08,0,0.16,0,0.24,0c-0.15,0.729,0.307,2.067-0.24,2.4
			C265.338,311.498,265.213,311.854,265.28,312.4z M267.681,302.561c-0.788,0.412-1.765,0.635-2.88,0.72
			c-0.074,0.326-0.601,0.199-0.96,0.24c-1.606,0.633-3.515,0.965-5.76,0.96c-2.759,0.121-5.189,0.571-7.44,1.2
			c-3.324,1.076-6.963,1.837-10.56,2.64c-0.917,0.683-3.392-0.191-4.32,0.48c-1.102,0.738-1.833,1.847-3.12,2.4
			c-1.165,0.205-1.362-0.558-1.68-1.2c1.361-1.679,2.959-3.121,5.04-4.08c0.026,0.454,0.793,0.167,0.72,0.72c0.96,0,1.92,0,2.88,0
			c3.204-0.237,5.36-1.52,8.4-1.92c0.501-0.379,1.262-0.498,2.16-0.48c0.349-0.131,1.24,0.281,1.2-0.24
			c2.622-0.898,5.837-1.203,8.64-1.92c1.3,0.02,1.996-0.565,3.36-0.48c4.128-0.752,7.699-2.061,12.24-2.4
			c1.16-0.52,3.15-0.21,4.32-0.72c1.063,0.263,1.193-0.407,1.92-0.48c0.32,0,0.64,0,0.96,0c0.427-0.411,1.453,0.615,1.44,1.44
			c0,0.48,0,0.96,0,1.44c-0.733,0.867-2.865,0.336-4.32,0.48c-0.887,0.399-3.433,0.399-4.32,0
			C272.681,301.48,270.39,302.23,267.681,302.561z M277.521,259.6c-1.724-0.124-3.573-0.973-3.36-0.48c0,0.08,0,0.16,0,0.24
			c0.067,0.493,0.641,0.479,0.96,0.72c1.11,0.33,1.785,1.095,2.4,1.92c0.04,0.68,0.44,1,0.48,1.68
			c-2.829,0.109-3.735-1.705-5.52-2.64c-0.752-0.208-1.325-0.595-1.68-1.2c-0.748-0.612-1.683-1.037-2.4-1.68
			c-0.624-0.409-0.103-1.684,0.24-1.92c0.422,0.138,1.41-0.29,1.44,0.24c0.538-0.342,0.458-1.302,0.96-1.68
			c0.422,0.138,1.41-0.29,1.44,0.24c0.411-0.011,0.622,0.178,0.72,0.48c1.595,0.004,2.409,0.791,4.08,0.72c1.92,0,3.84,0,5.76,0
			c1.867,0.188,3.143-0.217,4.32-0.72c0.341,0.021,0.68,0.04,0.72-0.24c0.875-0.589,1.611,0.388,1.681,0.96
			C290.023,258.409,282.245,259.94,277.521,259.6z M300.561,232.96c-0.047,0.757-1.646,0.683-0.96,1.44c0,0.08,0,0.16,0,0.24
			c0.556,0.067,0.552,0.641,0.239,0.96c-2.613-0.214-2.533,2.267-3.84,3.36c-1.359,0-2.72,0-4.08,0
			c2.17,0.099,0.338,2.487,0.24,3.84c0.241,0,0.286-0.194,0.48-0.24c0.168-1.142,2.002-2.248,2.88-1.2
			c0.894-0.014,0.692,1.068,0.72,1.92c0,0.08,0,0.16,0,0.24c-0.08,0-0.16,0-0.24,0c-0.085,1.034-0.496,1.744-1.199,2.16
			c-0.545,0.175-0.719,0.722-0.961,1.2c-0.785,1.534-1.85,2.79-3.359,3.6c-1.482,1.078-3.081,2.04-5.28,2.4c-0.32,0-0.64,0-0.96,0
			c-0.374-0.361-1.226-1.476-0.48-2.16c-0.13-0.93,1.392-0.208,1.44-0.96c-2.715,1.442-6.52,0.728-11.52,0.96
			c-4.738,0.221-8.805,1.319-8.88-3.12c0.422-0.138,1.41,0.29,1.44-0.24c-2.942-0.831-1.782,3.616-4.56,2.16
			c-0.773-1.261,0.316-2.718,0.48-3.84c1.063-2.377,1.368-5.512,2.16-8.16c0.645-0.316,0.479-1.441,1.44-1.44
			c1.373-0.867,3.193-1.287,4.56-2.16c1.131-0.709,2.361-1.319,3.84-1.68c0.336-0.383,0.827-0.613,1.44-0.72
			c1.146-0.774,2.426-1.414,3.84-1.92c0.3-0.58,0.984-0.776,1.68-0.96c2.536-0.984,4.345-2.695,7.68-2.88
			c1.374,0.121,1.442,2.289,0.24,2.64c-0.619,0.342-1.358,0.562-1.44,1.44c-0.241-0.001-0.286,0.194-0.48,0.24
			c-0.586,0.854-1.584,1.296-2.4,1.92c-0.08,0-0.16,0-0.24,0c-0.741,0.378-1.413,0.827-1.92,1.44c0.029,0.647-2.181,1.148-0.96,1.44
			c0.566-0.474,1.515-0.565,2.4-0.72c0.449-0.191,0.879-0.401,1.44-0.48c0.366-0.754,1.841-0.399,2.88-0.48c0.561,0,1.12,0,1.68,0
			c0.729-0.473,2.017-0.384,2.881-0.72c0.818-0.061,1.152-0.607,2.16-0.48c0.242-0.398,1.02-0.261,1.439-0.48
			C297.605,230.143,301.9,230.137,300.561,232.96z M260.935,251.495c0,0.061,0.003,0.124,0.006,0.185
			C260.938,251.618,260.935,251.556,260.935,251.495z M265.503,259.474c-0.011-0.016-0.023-0.032-0.035-0.047
			C265.48,259.442,265.492,259.458,265.503,259.474z M262.722,255.745c-0.008-0.012-0.016-0.023-0.023-0.035
			C262.707,255.722,262.714,255.733,262.722,255.745z M262.408,255.278c-0.025-0.039-0.05-0.078-0.075-0.117
			C262.359,255.2,262.383,255.239,262.408,255.278z M262.114,254.813c-0.032-0.052-0.061-0.104-0.092-0.155
			C262.053,254.709,262.082,254.761,262.114,254.813z M261.844,254.351c-0.033-0.059-0.064-0.118-0.095-0.176
			C261.78,254.233,261.811,254.292,261.844,254.351z M261.601,253.892c-0.031-0.062-0.06-0.124-0.088-0.185
			C261.542,253.769,261.57,253.83,261.601,253.892z M261.39,253.437c-0.027-0.063-0.052-0.126-0.078-0.188
			C261.338,253.311,261.363,253.374,261.39,253.437z M261.215,252.988c-0.022-0.064-0.044-0.128-0.063-0.191
			C261.171,252.86,261.192,252.924,261.215,252.988z M261.079,252.546c-0.017-0.065-0.033-0.129-0.047-0.193
			C261.046,252.417,261.062,252.481,261.079,252.546z M260.986,252.11c-0.01-0.064-0.02-0.127-0.027-0.191
			C260.966,251.982,260.976,252.046,260.986,252.11z M260.947,251.257c0.005-0.06,0.008-0.12,0.017-0.179
			C260.956,251.138,260.952,251.198,260.947,251.257z M261.01,250.843c0.013-0.057,0.023-0.114,0.039-0.17
			C261.033,250.729,261.022,250.786,261.01,250.843z M261.134,250.435c0.021-0.053,0.037-0.106,0.061-0.158
			C261.171,250.329,261.154,250.382,261.134,250.435z M261.332,250.022c0.025-0.043,0.045-0.087,0.072-0.13
			C261.376,249.936,261.356,249.979,261.332,250.022z M258.8,234.64c0.342-0.938,0.982-1.578,1.92-1.92
			c1.048-1.271,2.246-2.394,3.84-3.12c4.55-3.529,11.575-4.584,16.56-7.68c0.447-0.673,1.314-0.926,2.16-1.2
			c0.053-0.187,0.201-0.279,0.48-0.24c0.757-0.843,1.964-1.236,3.12-1.68c1.116-0.644,2.088-1.432,3.84-1.44
			c1.552-0.508,4.531-0.374,4.8,1.2c0,0.4,0,0.8,0,1.2c-1.298,0.542-3.725-0.044-4.56,0.96c-0.502,0.458-1.146,0.774-1.92,0.96
			c-0.502,0.458-1.146,0.774-1.92,0.96c-0.448,0.672-1.314,0.926-2.16,1.2c-0.053,0.187-0.201,0.279-0.48,0.24
			c-0.447,0.673-1.314,0.926-2.16,1.2c-3.416,2.504-8.741,3.099-12.48,5.28c-0.299,0.581-0.983,0.776-1.68,0.96
			c-1.648,0.832-3.174,1.787-4.32,3.12c0.001,0.241-0.194,0.286-0.24,0.48c-1.103,0.737-1.777,1.903-2.88,2.64
			c-0.598,0.931-2.406,0.375-2.88-0.24c0.065-0.995-0.315-0.936,0-1.68C258.083,235.362,258.255,234.815,258.8,234.64z
			 M245.36,286.24c0.16,0,0.32,0,0.48,0c0.389-0.659,2.581-0.769,3.12-0.48c0.454,0.054,0.529-0.271,0.96-0.24
			c0.867-0.333,0.752-1.648,1.44-2.16c0.736-0.544,1.045-1.515,1.68-2.16c1.779-2.781,4.121-4.999,6.96-6.72
			c0.324-0.796,1.559-0.682,2.16-1.2c1.529-0.791,3.157-1.482,4.08-2.88c0-0.241,0.195-0.286,0.24-0.48
			c0.593-1.087,0.7-2.66,1.2-3.84c0-0.64,0-1.28,0-1.92c0.005-0.264-0.027-0.535-0.088-0.812c0.017-2.188,2.774-1.168,2.728,0.572
			c0.4,2.162,0.4,5.998,0,8.16c-0.317,2.162-0.809,4.151-2.16,5.28c-0.199,1.161-1.063,1.657-1.44,2.64
			c-1.089,1.791-0.822,4.938-2.4,6.24c-0.779,0.98-1.66,1.86-2.64,2.64c-0.08,0-0.16,0-0.24,0c-0.806,0.954-1.707,1.813-2.16,3.12
			c-0.354,1.485-0.908,2.771-2.16,3.36c-0.208,0.992-1.058,1.342-1.92,1.68c-0.768,0.191-1.211,0.709-2.16,0.72
			c-1.422,0.818-3.129,1.351-4.8,1.92c-0.169,0.871-1.749,0.331-2.64,0.48c-0.058-0.342-0.396-0.405-0.48-0.72
			c-0.787-1.293-1.055-3.105-1.68-4.56c-0.542-0.498-0.75-1.331-0.96-2.16c-0.975-0.945-1.172-2.667-2.16-3.6
			c-2.502-2.058-3.586-5.533-6-7.68c-0.774-0.346-1.544-0.696-1.68-1.68c-0.987-4.312,5.424-2.287,6.48-0.48
			c1.941,0.539,2.853,2.107,4.08,3.36c0,0.16,0,0.32,0,0.48C243.937,284.144,244.869,284.972,245.36,286.24z M265.775,259.851
			c0.024,0.034,0.048,0.068,0.073,0.103C265.824,259.919,265.8,259.885,265.775,259.851z M266.084,260.294
			c0.029,0.042,0.057,0.084,0.085,0.127C266.141,260.379,266.113,260.337,266.084,260.294z M266.381,260.744
			c0.029,0.045,0.058,0.091,0.086,0.136C266.439,260.835,266.411,260.79,266.381,260.744z M266.659,261.191
			c0.028,0.048,0.056,0.095,0.083,0.143C266.714,261.286,266.687,261.239,266.659,261.191z M266.912,261.635
			c0.026,0.048,0.05,0.096,0.075,0.145C266.962,261.731,266.938,261.683,266.912,261.635z M267.135,262.074
			c0.022,0.047,0.042,0.093,0.063,0.14C267.177,262.167,267.158,262.121,267.135,262.074z M267.327,262.507
			c0.017,0.043,0.031,0.085,0.047,0.128C267.358,262.592,267.344,262.55,267.327,262.507z M267.48,262.933
			c0.01,0.033,0.017,0.064,0.027,0.097C267.498,262.998,267.491,262.966,267.48,262.933z M197.84,203.68
			c0.245-2.475,0.615-4.825,0.96-7.2c0.394-1.286,0.524-2.836,1.2-3.84c0.781-0.579,0.023-2.697,1.68-2.4
			c1.334,0.004,0.603,2.384,0.24,2.88c-2.339,7.862-1.703,19.53-0.72,27.6c-3.896,0.079-2.565-6.993-3.36-10.8
			C197.441,208.396,197.441,205.204,197.84,203.68z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M188.72,239.68c-0.5-1.18-0.607-2.753-1.2-3.84
			c-0.32-0.88-0.568-1.833-0.72-2.88c0-0.4,0-0.8,0-1.2c-0.532-0.988-1.06-1.98-1.68-2.88c-0.146-0.014-0.284-0.036-0.24-0.24
			c-0.984-0.536-1.312-1.729-1.92-2.64c0-0.08,0-0.16,0-0.24c-0.974-0.443-0.681-0.726-1.2-1.44c-0.161-1.119-1.347-1.213-1.68-2.16
			c-0.9-0.699-1.7-1.5-2.4-2.4c-1.016-0.765-1.717,0.834-2.64,0.96c0.335,0.945,1.041,1.519,1.2,2.64
			c0.542,0.738,1.213,1.347,1.44,2.4c0.594,0.287,0.938,0.822,1.2,1.44c0.9,0.7,1.7,1.5,2.4,2.4c0.201,0.725,1.651,1.054,1.2,1.68
			c1.168,0.811,1.996,2.289,2.16,3.6c0.379,1.141,0.9,2.14,1.2,3.36c0.706,1.214,1.283,2.557,1.68,4.08
			c1.267,1.292,0.892,4.228,3.12,4.56C190.252,244.229,189.142,242.299,188.72,239.68z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M320.24,253.12c1.381,0.021,2.772,0.052,3.84-0.24
			c0.041-0.28,0.38-0.261,0.721-0.24c0.816-0.704,2.095-0.944,2.64-1.92c0.08,0,0.16,0,0.24,0c1.523-1.196,2.738-2.702,3.84-4.32
			c0.693-1.147,0.446-3.234,0.48-5.04c0.08,0,0.159,0,0.239,0c0.318-1.282,0.313-2.888,0.96-3.84c-0.144-0.428,0.704-0.997,0-1.2
			c-0.103,0.537-1.183,0.097-1.68,0.24c-0.021-0.378-0.612-0.188-0.479-0.72c-0.205,0.044-0.227-0.094-0.24-0.24
			c-0.709-1.355,0.347-3.008,1.2-3.6c3.476-3.354,0.785-9.436-1.2-12.72c-0.245,0.886-0.138,1.003-0.72,1.2
			c0.268,0.295,0.209,0.493-0.24,0.48c-3.511,2.88-0.712,8.729,0.24,10.8c0.309,0.248,0.617,2.524-0.24,2.4
			c0.207,2.183-2.099,4.789-3.12,7.2c-0.8,0-1.601,0-2.4,0c-0.489-0.071-0.471-0.649-0.96-0.72c-0.566-1.341-0.04-3.087,0.72-3.84
			c1.05-1.751,1.802-4.948,0.24-6.72c0.074-0.954-0.554-1.206-0.479-2.16c-0.895-1.346-0.425-4.065,0.239-5.28
			c-0.074-0.954,0.555-1.206,0.48-2.16c0.351-0.929,0.38-2.18,1.2-2.64c1.181-0.979,1.431-2.889,2.399-4.08
			c0.27-1.731,1.225-2.776,1.921-4.08c0.162-0.638,0.377-1.222,0.72-1.68c0.214-2.575,1.506-4.285,3.359-4.08
			c2.598,0.287,3.319,4.978,3.841,7.44c0.61,0.941,0.176,2.889-0.48,3.36c-1.436,0.076-2.186-0.535-2.399-1.68
			c-0.559-1.042-0.682-2.519-1.44-3.36c-0.05,1.044-0.935,1.519-0.48,2.4c0.397,0.259,0.397,2.141,0,2.4
			c-0.146,1.587,0.511,2.37,0.96,3.36c0.531,0.829,0.743,1.977,1.2,2.88c-0.063,0.864,0.443,1.157,0.48,1.92
			c0.628,1.131,1.166,2.354,1.2,4.08c0.316,0.003,0.219,0.421,0.479,0.48c-0.018-1.458,0.771-2.109,0.72-3.6
			c0.05-2.03,0.809-3.352,0.721-5.52c0.643-2.668,0.108-6.169-0.48-8.4c-0.569-0.15,0.037-1.477-0.479-1.68
			c-0.527-0.833-0.643-2.078-0.96-3.12c-0.662-0.858-1.144-1.897-1.44-3.12c-0.894-0.547-1.197-1.683-1.92-2.4
			c-0.298-0.502-0.698-0.902-1.2-1.2c0-0.08,0-0.16,0-0.24c-4.086-0.322-4.862,4.905-7.439,7.44c-0.468,2.172-1.925,3.355-2.16,5.76
			c0.048,1.729-0.528,2.832-0.48,4.56c0.001,0.241-0.194,0.286-0.24,0.48c0.395,0.629-0.034,2.095-0.239,2.88
			c-0.345,3.175-0.7,6.341-1.2,9.36c-0.06,1.541-0.111,3.089-0.48,4.32c-0.094,2.386-0.26,4.7-0.72,6.72
			c-0.148,0.651,0.305,1.904-0.24,2.16c0,0.72,0,1.44,0,2.16c-0.496,1.264-0.278,3.242-0.72,4.56
			c-0.098,1.808-0.665,2.779-0.24,4.56C319.225,252.696,319.468,253.173,320.24,253.12z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M186.561,247.36c-0.177,1.937,0.39,3.13,0.24,5.04
			c0.439,1.081,0.517,2.523,0.72,3.84c0.24,1.52,0.9,2.62,1.2,4.08c0.2-2.921,0.258-7.777-0.72-10.32
			C187.387,249.253,187.219,248.062,186.561,247.36z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M221.84,251.2c1.078-0.118,1.798,0.122,2.64,0.24
			C224.128,250.98,222.398,250.659,221.84,251.2z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M218.72,251.68c0,0.08,0,0.16,0,0.24
			c0.729-0.471,2.171-0.229,2.88-0.72C220.494,251.214,219.471,251.311,218.72,251.68z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M196.16,268.72c1.028-0.778-1.236-2.232-1.2-3.6
			c-0.317-0.002-0.22-0.42-0.48-0.48c-0.027,0.293-0.072,0.568-0.48,0.48c0.134,1.174-1.507,0.573-2.4,0.72
			c0.127,0.193,0.25,0.39,0.24,0.72C192.47,268.09,194.25,268.471,196.16,268.72z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M193.521,261.041c-0.221-0.66-0.596-1.165-0.96-1.68
			c0.105,1.414,0.221,2.819,1.2,3.36C193.695,262.282,192.896,261.375,193.521,261.041z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M204.081,468.161c0.24,0,0.48,0,0.72,0
			c0.412-0.549,0.228-1.692,0.72-2.16c0.909-2.371,1.493-5.067,2.64-7.2c0.217-1.304,0.796-2.244,1.2-3.36
			c0.155-0.885,0.246-1.833,0.72-2.399c0.124-0.916,0.67-1.41,0.96-2.16c-0.436-0.452,0.375-1.693,0.48-2.4
			c0.354-1.165,0.48-2.56,1.2-3.359c0.009-1.031,0.313-1.768,0.72-2.4c0.36-1.881,0.936-3.545,1.44-5.28
			c-0.439-0.611,0.344-2.047,0.72-2.64c1.065-2.215,1.403-5.156,3.6-6.24c0.3-0.58,0.984-0.776,1.68-0.96
			c1.34-0.58,2.697-1.143,4.08-1.68c-1.934-2.066-4.349-3.651-7.2-4.8c-1.174,0.666-2.04,1.64-2.88,2.64
			c-0.729,0.791-1.294,1.745-1.92,2.64c-0.529,0.512-0.435,1.646-1.2,1.92c-0.202,1.078-0.554,2.006-1.2,2.641
			c0.05,1.17-1.068,1.172-0.96,2.4c-0.08,0-0.16,0-0.24,0c-0.395,1.924-1.549,3.091-2.16,4.799c-0.266,0.935-0.664,1.736-1.2,2.4
			c-0.562,0.078-0.233,1.047-0.72,1.2c-0.254,1.106-0.893,1.827-1.44,2.64c-0.309,2.556-0.866,5.556-0.48,8.4
			c0.237-0.222,2.049,0.019,2.16,0.72c0.61,0.029,0.931,0.35,0.96,0.96c1.148,0.942,1.841,4.761,0.48,6
			c-0.066,0.938-1.775-0.183-2.16-0.479c-0.61-0.153,0.263-5.009-1.92-3.601c-0.131,0.349,0.28,1.241-0.24,1.2
			C203.072,461.679,201.866,466.159,204.081,468.161z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M227.36,458.32c-2.991-1.408-5.727-3.073-6.48-6.72
			c-0.798-2.241,0.927-4.03,2.64-4.56c5.907-1.213,9.869-4.371,14.88-6.48c-0.226-1.346,0.613-1.627,0.72-2.64
			c-0.147-0.794,0.346-3.009,1.44-3.36c0.863-0.143,1.354,0.086,1.44,0.721c0.637,0.456,0.303,2.148,0.48,2.399
			c0.014-0.146,0.036-0.284,0.24-0.24c0.957-0.724,1.948-1.412,2.88-2.159c1.505-0.975,2.886-2.074,4.56-2.881
			c0.978-0.381,1.62-1.1,3.12-0.96c0.855-0.604,2.402,0.15,2.88,0.72c1.516,0.244,2.349,1.171,3.6,1.681
			c-0.665-1.255-2.121-1.72-3.12-2.64c-1.339-0.342-2.571-0.789-3.36-1.681c-0.454-0.267-0.841-0.599-1.44-0.72
			c-1.522-0.317-2.364-1.316-3.36-2.16c-1.25-0.75-2.237-1.764-3.6-2.4c-1.057,0.018-1.506-0.574-1.92-1.199
			c-0.937-0.504-1.949-0.932-3.12-1.2c-1.46-0.181-2.165,0.396-3.36,0.479c-0.167,0.474-1.339-0.059-1.44,0.48
			c-0.573,0.627-0.886,1.514-1.68,1.92c-2.264,3.656-5.004,6.836-7.44,10.32c-0.168,0.871-0.773,1.307-1.2,1.92
			c-0.24,0.319-0.396,0.725-0.72,0.96c-0.772,0.747-1.197,1.843-1.92,2.64c-2.266,1.414-1.905,5.455-3.36,7.68
			c0.106,1.307-0.608,1.792-0.96,2.641c-0.589,1.011-0.956,2.243-1.68,3.12c-0.569,0.392-0.511,1.409-0.96,1.92
			c-0.201,1.639-1.243,2.437-2.16,3.359c0.01,0.331-0.113,0.527-0.24,0.721c-0.625,0.815-1.406,1.474-1.92,2.399
			c-0.404,1.161-2.057,1.369-1.44,2.88c0.413,0.468,1.243,0.518,2.16,0.48c0.563-0.494-0.474-1.218,0.72-1.2
			c0.78-0.98,1.66-1.86,2.64-2.64c0.054-0.187,0.006-0.474,0.24-0.48c0.158-1.362,1-2.041,1.68-2.88
			c-0.624-0.503-2.112-1.398-1.2-2.64c2.225-1.764,4.077,0.651,4.08,2.399c0.004,2.949-3.922,6.123-5.04,7.681
			c0.039,0.278-0.053,0.427-0.24,0.479c0.412,0.468,1.243,0.518,2.16,0.48c3.042-0.798,5.553-2.127,7.68-3.84
			c0.902-0.938,1.588-2.093,2.4-3.12C226.213,458.773,227.277,459.037,227.36,458.32z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M217.041,442.24c0.214-0.746,0.247-1.672,1.2-1.68
			c0.8-1.439,1.601-2.879,2.88-3.84c0.274-0.846,1.059-1.182,1.44-1.92c0.628-1.371,1.702-2.299,2.4-3.6
			c0.749-0.852,1.177-2.023,2.16-2.641c-1.593-0.149-3.535,1.424-5.28,1.92c-0.705,0.576-1.475,1.085-2.16,1.68
			c-0.011,0.949-0.528,1.393-0.72,2.16c0.17,0.265-0.364,1.866-0.72,2.4c-0.256,1.424-0.908,2.452-1.2,3.84
			C217.356,441.305,216.329,441.893,217.041,442.24z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M203.36,476.561c-0.032-0.592-0.036-1.156,0.24-1.439
			c0.017-0.943,0.116-1.805,0.48-2.4c0-0.16,0-0.32,0-0.479c-1.196-0.005-1.793-0.607-2.4-1.2c-1.316-0.524-2.102-1.578-3.12-2.4
			c-0.307-0.708-2.112,0.051-2.88-0.72c-0.348-0.908-2.613-0.438-3.36-0.24c-0.204,0.517-0.591,0.849-1.44,0.72
			c-0.745,0.695,0.016,1.898-1.2,2.4c0.03,0.99-0.417,1.503-0.24,2.64c0.496,2.064,1.297,3.823,1.92,5.761
			c0.752,1.248,2.003,1.996,3.12,2.88c1.106,0.254,1.827,0.893,2.64,1.439c1.027-0.066,1.187,0.733,2.4,0.48
			c0.053-0.188,0.201-0.279,0.48-0.24c-0.357-1.237,0.572-1.188,0.72-1.92c-0.477-0.649,0.306-2.085,0.72-2.64
			C202.056,478.297,202.089,476.81,203.36,476.561z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M218,470.561c-1.651,0.527-4.573,0.256-6-0.24
			c-1.004-0.116-1.863-0.377-2.64-0.72c0.07,1.671-0.715,2.484-0.72,4.08c0.679-0.038,0.559,0.721,1.2,0.72
			c0.544,0.656,1.875,0.525,1.68,1.92c0.16,0,0.32,0,0.48,0c0.611,0.596,1.059,2.613,0.48,3.601c-0.014,0.146-0.036,0.284-0.24,0.24
			c-0.925,1.954-1.851,3.909-3.12,5.52c0.874,0.977-2.15,1.862-2.64,0.72c-0.08,0-0.16,0-0.24,0
			c-0.668,0.108,0.188-1.308-0.48-1.199c1.052-0.309,1.101-1.62,1.92-2.16c0.054-0.187,0.007-0.474,0.24-0.48
			c0.801-1.604,2.254-4.16,0.72-6c-2.069-0.715-2.836,1.317-3.6,2.641c-0.425,2.266-1.853,3.369-2.64,5.76
			c0.402,0.398,0.191,1.409,0.24,2.16c5.994,1.634,10.472-1.65,12.48-5.521c0.306-1.374,1.132-2.228,1.92-3.12
			c0.657-3.022,2.592-4.768,2.88-8.16C219.147,470.268,218.905,470.745,218,470.561z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M343.041,500.081c-0.331,0.01-0.527-0.113-0.721-0.24
			c-1.241-0.118-2.262-0.458-2.88-1.2c-2.466-0.414-4.625-1.135-6.479-2.16c-0.08,0-0.16,0-0.24,0
			c-0.989-0.131-1.404-0.835-2.16-1.199c-0.558-0.163-0.643-0.798-1.2-0.961c-1.597-1.282-3.263-2.497-4.8-3.84
			c-1.97-0.771-2.459-4.234-4.8-3.84c-0.396,1.045-1.31,1.571-1.681,2.641c-0.079,0-0.159,0-0.239,0
			c-1.114,1.206-2.011,2.629-3.36,3.6c-0.905,1.095-2.259,1.74-3.12,2.88c-0.663,1.017-1.79,1.57-2.64,2.4
			c-0.928,0.032-0.947,0.973-1.68,1.199c-0.809,0.552-1.336,1.385-2.16,1.921c-0.733,0.787-1.269,1.771-2.4,2.159
			c-0.525,0.675-0.957,1.442-2.16,1.44c-0.404,0.795-0.875,1.524-1.439,2.16c-1.656,3.721-0.278,6.882,1.68,9.359
			c0.949,1.451,1.896,2.904,2.88,4.32c0.731,0.389,1.022,1.218,1.44,1.92c-0.041,0.601,0.368,0.752,0.72,0.96
			c0.919,0.681,1.698,1.502,2.16,2.641c0.146,0.014,0.284,0.035,0.239,0.239c1.008,0.992,2.254,1.747,3.121,2.881
			c0.9,1.658,3.476,1.644,4.8,2.88c1.693,0.626,3.844,0.796,5.279,1.68c1.628-0.132,3.802,0.282,5.04-0.24
			c2.477-0.165,4.954-1.604,6.96-1.439c-0.037,0.282,0.042,0.681-0.239,0.72c-1.616,2.864-6.386,2.575-9.601,3.84
			c-1.506,0.462-4.537,0.381-5.76-0.24c-2.772-0.428-5.169-1.23-6.96-2.64c-1.178-0.342-1.969-1.071-2.641-1.92
			c-0.051-0.27-0.266-0.374-0.479-0.48c-1.698-0.941-2.784-2.495-3.84-4.08c-0.352-0.208-0.761-0.358-0.72-0.96
			c-1.089-0.911-2.034-1.966-2.641-3.359c-0.903-0.856-1.498-2.022-2.16-3.12c-0.756-0.443-0.367-2.032-1.439-2.16
			c-0.683,1.237-0.27,3.571-1.2,4.56c0.063,0.864-0.443,1.157-0.479,1.921c-0.073,0.675-0.771,2.919-0.961,1.92
			c-1.469,3.65-3.694,6.545-5.279,10.08c1.717,0.123,2.895,0.785,4.56,0.96c1.653,0.025,2.971,0.39,4.08,0.96
			c0.521,0.359,1.351,0.409,2.16,0.479c3.387,0.453,6.97,0.71,10.319,1.2c6.552,1.208,10.909,4.611,15.601,7.68
			c1.259,0.262,2.054,0.987,2.88,1.681c1.094,0.587,2.277,1.082,3.36,1.68c1.39,0.841,1.555-0.788,2.399-1.2
			c0.004-0.716,0.624-0.815,0.721-1.439c0.42-0.86,0.872-1.688,1.439-2.4c2.174-3.347,3.981-7.059,5.521-11.04
			c0.317-1.442,0.711-2.809,1.2-4.08c0.056-0.983,0.117-1.962,0.479-2.64c-0.031-0.592-0.036-1.156,0.24-1.44
			c0.24-2.16,0.369-4.431,0.72-6.479c-0.397-0.414-0.397-2.467,0-2.88c0-0.08,0-0.16,0-0.24c0-2.561,0-5.12,0-7.681
			C344.467,505.854,343.7,503.021,343.041,500.081z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M39.2,606.881c-0.014,1.055,0.096,1.983,0.48,2.641
			c0-0.4,0-0.801,0-1.2C39,608.36,39.879,606.842,39.2,606.881z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M272.48,731.201c-0.414,0.866-0.355,2.205-0.96,2.88
			C272.523,733.805,272.465,732.466,272.48,731.201z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M200.96,243.521c4.98,0.53,11.477-0.316,15.6-0.48
			c0.819-0.38,2.059-0.341,3.12-0.48c0-0.08,0-0.16,0-0.24c-0.204,0.044-0.226-0.094-0.24-0.24c-2.611,0.051-5.019-0.101-6.96-0.72
			c-2.767-0.434-4.995-1.405-8.16-1.44c-1.512,0.408-3.43,0.41-5.04,0.72c-0.278,0.202-0.644,0.317-1.2,0.24
			c-0.212,0.6-2.152,0.711-2.64,0.24c-0.837,1.293-1.502,2.822-0.48,3.84C195.297,241.859,198.165,243.223,200.96,243.521z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M228.32,455.44c0.745,1.015,2.51,1.011,4.08,1.2
			c1.461-0.139,3.037-0.131,4.56-0.72c2.942-0.897,4.855-2.825,6.96-4.561c1.847-1.433,3.438-3.122,5.52-4.319
			c1.944-1.016,3.084-2.836,5.28-3.601c1.958-1.563,3.874-3.167,5.52-5.04c-1.983-1.937-4.201-3.639-8.16-3.6
			c-1.121,0.88-2.192,1.808-3.6,2.4c-0.363,0.597-0.823,1.097-1.68,1.199c-3.073,2.047-6.221,4.02-9.36,6
			c-0.588,0.452-1.174,0.906-2.16,0.961c-0.128,0.751-0.895,0.864-1.68,0.959c-0.566,0.475-1.249,0.832-1.92,1.2
			c-0.833,0.687-1.833,1.208-3.36,1.2c-1.732,0.428-3.35,0.97-4.56,1.92c0.23,1.724,1.386,0.146,2.88,0.48
			c1.754-0.006,2.527-0.993,3.6-1.681c0.124-0.688,1.816-1.048,2.4-0.479c1.296,3.18-3.362,4.446-6.24,5.04
			c0.054,0.186,0.007,0.474,0.24,0.479C227.337,454.664,228.021,454.86,228.32,455.44z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M292.4,520.001c-0.035-1.075,0.025-2.055,0.48-2.64
			c-0.174-0.734,0.245-0.875,0.24-1.44c0.172-0.947,0.297-1.942,0.719-2.64c0-0.08,0-0.16,0-0.24
			c0.125-1.396,0.094-2.947,0.721-3.84c0.023-0.378-0.032-0.832,0.48-0.721c0.057-0.422,0.412-0.547,0.959-0.479
			c0.139-0.422-0.289-1.41,0.24-1.44c0.055-0.186,0.007-0.473,0.24-0.479c0.056-1.225,0.672-1.888,1.2-2.641
			c0.356-0.923,0.925-1.635,1.68-2.159c0.051-0.27,0.267-0.374,0.479-0.48c0.573-0.708,1.052-1.509,2.16-1.68
			c1.276-0.484,1.785-1.735,2.881-2.4c0.505-0.935,1.602-1.278,2.4-1.92c0.079,0,0.159,0,0.239,0c1.188-1.453,2.814-2.466,4.56-3.36
			c0.309-0.411,0.497-0.943,1.2-0.96c0.657-0.783,1.249-1.631,1.92-2.399c0.34-1.021,0.407-2.313,1.681-2.4
			c0.398-1.121,1.431-1.609,1.68-2.88c0.836-1.244,1.84-2.32,2.64-3.6c0.305-0.736,0.817-1.264,1.2-1.92
			c-2.092,0.331-1.915-1.605-2.64-2.641c0-0.16,0-0.32,0-0.479c-0.531-0.189-0.601-0.84-0.96-1.2
			c-0.959-0.722-2.074-1.286-2.88-2.16c-0.269-1.237,1.049-2.139,1.92-1.44c1.995,0.965,3.511,2.41,4.319,4.561
			c0.805-0.075,0.242-1.519,0.96-1.68c-0.731-1.988-1.892-3.548-4.56-3.601c-1.264-0.144-2.062,0.178-3.12,0.24
			c-0.104,0.536-1.183,0.097-1.68,0.24c-1.04,2.08-2.586,3.654-4.32,5.04c0,0.08,0,0.159,0,0.239c1.546,1.655,3.251,3.149,4.32,5.28
			c0.703,0.337,0.738,1.342,0.72,2.4c-0.08,0-0.16,0-0.24,0c-0.029,0.53-1.018,0.102-1.439,0.239c-1.18-0.26-1.434-1.446-1.92-2.399
			c0.013-0.413-0.186-0.615-0.48-0.72c-1.061-0.86-2.02-1.82-2.88-2.881c-0.053-0.187-0.201-0.278-0.479-0.239
			c-0.689,0.59-1.838,0.723-2.4,1.439c-0.971,0.39-1.252,1.469-2.641,1.44c-2.303,1.856-4.002,4.318-6.479,6
			c-2.097,1.902-4.075,3.925-6,6c-0.942,1.697-2.498,2.782-3.84,4.08c-1.185,1.615-2.378,3.222-3.84,4.56
			c-0.344,0.696-0.89,1.19-1.68,1.44c-2.42,1.038-2.583-2.815-0.96-3.601c0.53-0.189,0.601-0.839,0.96-1.199
			c0.572-1.188,1.401-2.119,2.4-2.881c0.269-0.051,0.374-0.266,0.48-0.479c0.625-1.375,1.856-2.145,2.88-3.12
			c0.491-0.47,1.113-0.807,1.44-1.44c0.742-1.657,2.419-2.381,3.36-3.84c1.422-1.059,2.418-2.541,3.84-3.6
			c0.785-0.895,1.629-1.731,2.641-2.4c0.699-0.9,1.499-1.7,2.399-2.399c0.591-0.529,1.053-1.188,1.92-1.44
			c0.681-1.079,2.944-0.575,3.84-1.439c0.774-0.826,1.603-1.599,2.64-2.16c0.754-1.167,1.553-2.288,2.641-3.12
			c-0.506-1.414-1.474-2.366-1.68-4.08c-0.875-1.525-1.931-2.87-2.4-4.8c-0.021-0.379-0.612-0.188-0.48-0.721
			c-0.86-0.898-1.559-1.961-2.16-3.119c-1.221-0.939-2.115-2.204-3.6-2.881c0-0.08,0-0.159,0-0.239
			c-0.738-0.179-0.461,0.658-1.199,0.479c-0.721,0.96-1.79,1.57-2.641,2.4c-2.46,2.66-5.02,5.22-7.68,7.68c0,0.08,0,0.16,0,0.24
			c-0.896,0.944-1.531,2.149-2.64,2.88c0,0.08,0,0.16,0,0.24c-1.23,1.25-2.236,2.724-3.6,3.84c-3.296,3.024-6.148,6.492-9.6,9.36
			c-0.709,0.97-1.586,1.773-2.64,2.399c-0.886,0.795-1.688,1.673-2.88,2.16c-0.242,0.559-0.826,0.774-1.68,0.72
			c-1.107-1.106-0.109-3.188,1.2-3.359c0.269-0.051,0.374-0.267,0.48-0.48c0.814-0.865,1.427-1.933,2.64-2.399
			c0.269-0.052,0.374-0.267,0.48-0.48c0.651-0.709,1.11-1.609,2.16-1.92c0.013-0.146,0.036-0.284,0.24-0.24
			c1.98-2.18,4.06-4.26,6.24-6.24c0.269-0.051,0.374-0.266,0.48-0.479c0.233-0.967,1.048-1.352,1.68-1.92
			c-0.067-0.388,0.227-0.413,0.24-0.721c0.315-0.084,0.377-0.422,0.72-0.479c1.057-1.104,1.852-2.469,3.12-3.36
			c0.166-0.954,1.056-1.185,1.44-1.92c0.7-0.9,1.5-1.7,2.4-2.399c0.986-1.095,1.909-2.251,3.12-3.12c0-0.08,0-0.16,0-0.24
			c0.985-0.695,1.645-1.716,2.641-2.4c0.66-0.859,2.069-0.97,1.92-2.64c0.358-1.398-0.913-1.167-0.721-2.4
			c-0.59-0.77-1.041-1.679-1.68-2.399c0.111-1.471-1.4-1.32-1.439-2.641c-0.633-0.248-0.809-0.951-1.44-1.199
			c-0.401-0.559-1.061-0.859-1.44-1.44c-1.105-0.254-1.703-1.017-2.88-1.2c-0.04,0.279-0.379,0.261-0.72,0.24
			c-0.813,0.547-1.534,1.187-2.64,1.439c-2.735,1.346-5.046,3.114-7.68,4.561c-1.218,0.701-1.529,2.312-2.64,3.12
			c-0.427,1.014-1.192,1.688-1.92,2.4c-0.023,0.616-0.937,0.343-0.96,0.96c-2.5,3.1-5.3,5.899-8.4,8.399
			c-4.632,4.408-10.642,7.438-16.56,10.561c-1.32,1.063-3.013-1.262-1.68-2.16c0.624-0.096,0.724-0.717,1.44-0.72
			c1.192-0.729,2.358-1.482,3.84-1.921c1.116-0.804,2.326-1.514,3.6-2.159c0.55-0.49,0.907-1.173,1.92-1.2
			c1.218-0.303,1.739-1.302,2.64-1.92c1.45-1.51,3.291-2.629,4.8-4.08c0.423-0.217,0.743-0.537,0.96-0.96c0.7-0.9,1.5-1.701,2.4-2.4
			c0.876-0.484,1.298-1.422,1.92-2.16c0.411-0.229,0.483-0.796,0.96-0.96c0.779-0.98,1.659-1.86,2.64-2.64
			c0.2-0.76,0.7-1.221,1.2-1.68c0.33-1.111,1.095-1.785,1.92-2.4c0.067-0.493,0.641-0.479,0.96-0.721
			c0.5-0.379,0.978-0.781,1.68-0.959c0.501-0.459,1.146-0.774,1.92-0.961c-0.131-1.229-1.277-1.441-1.68-2.399
			c0.044-0.523-0.196-0.764-0.72-0.72c-0.809-0.392-1.024-1.376-2.16-1.44c-0.054-0.346-0.716-0.083-0.72-0.479
			c-1.027,0.066-1.187-0.733-2.4-0.48c-0.96,0-1.92,0-2.88,0c-0.811-0.091-1.024,0.416-1.44,0.721
			c-0.459,0.66-1.019,1.221-1.68,1.68c-0.171,0.651,1.132-0.172,0.96,0.479c0.972-0.331,0.759,0.521,1.44,0.48
			c1.277-0.078,1.718,1.139,1.2,2.159c-0.359-0.041-0.886,0.086-0.96-0.239c-1.262,0.142-1.753-0.487-2.64-0.72
			c-0.752,0.111-0.676-0.604-0.96-0.961c-0.884,0.557-1.505,1.375-1.92,2.4c-0.08,0-0.16,0-0.24,0
			c-1.265,0.016-1.274,1.287-2.4,1.439c-3.287,2.873-6.555,5.766-10.08,8.4c-1.157,1.323-2.559,2.401-4.08,3.36
			c-0.704,0.017-0.892,0.549-1.2,0.96c-0.354,0.605-0.928,0.992-1.68,1.2c-3.108,2.731-6.523,5.157-12.72,4.8
			c-0.372,0.428-0.66,0.94-0.96,1.44c-0.063,0.737-0.521,1.079-0.72,1.68c-0.646,0.633-0.976,1.584-1.44,2.399
			c-0.037,0.764-0.544,1.057-0.48,1.921c0.037,1.477,0.062,2.941-0.72,3.6c-0.146,0.79-2.474,0.669-2.64,0
			c-0.254,1.267-0.609,2.431-1.2,3.36c-0.51,1.649-0.776,3.544-1.92,4.56c-0.266,0.935-0.664,1.736-1.2,2.4
			c0.064,1.104-0.866,1.214-1.2,1.92c0.556,5.044-0.087,11.287,0.96,15.84c-0.117,1.477,0.427,2.293,0.24,3.84
			c0.396,2.805,1.608,4.793,2.88,6.72c0.908,0.612,1.405,1.635,2.16,2.4c0.039,1.081,1.485,0.755,1.44,1.92
			c0.941,0.099,0.539,1.542,1.44,1.68c1.167,0.557,1.884,2.696,1.68,3.841c-0.981-0.299-2.032-0.528-2.64-1.2
			c-0.881,0.001-0.919-0.841-1.44-1.2c-1.107-0.973-2.253-1.907-3.12-3.12c-0.991-0.075-0.164,1.395,0,1.68
			c0.379,0.501,0.498,1.263,0.48,2.16c0.383,0.656,0.494,1.586,0.48,2.641c0.135,2.424,1.014,4.106,1.2,6.479
			c0.401,1.52,0.805,3.035,0.96,4.8c0.131,0.35-0.28,1.241,0.24,1.2c0.419,1.185,1.183,3.292,0.96,4.32
			c0.245,1.194,0.954,1.927,0.96,3.36c0.743,1.576,1.348,3.291,2.16,4.8c0.259,0.701,2.141-0.222,2.4,0.479c0.08,0,0.16,0,0.24,0
			c0.8,0,1.6,0,2.4,0c1.61-0.149,2.544-0.975,4.08-1.199c0.936-0.585,1.773-1.268,2.4-2.16c0.855-0.506,1.249-1.472,1.92-2.16
			c-0.137-1.356,2.558-1.707,2.88-0.48c0.673,0.416,0.226,0.629,0.24,1.44c-2.422,1.498-3.021,4.818-6,5.76
			c-1.089,0.512-2.22,0.98-3.6,1.2c-0.392,1.129-1.306,1.734-2.4,2.16c-0.221,0.419-0.75,0.529-1.44,0.479
			c0.046,0.834,0.849,0.911,0.96,1.681c0.833-0.047,0.911-0.85,1.68-0.961c1.988-1.052,3.829-2.251,5.76-3.359
			c1.045-0.715,2.225-1.295,3.36-1.92c0.754-0.206,1.013-0.907,1.92-0.96c0.964-0.877,1.997-1.683,3.36-2.16
			c1.465-1.016,3.253-1.707,5.04-2.4c0.559-0.401,1.293-0.627,2.16-0.72c-0.01-0.33,0.113-0.527,0.24-0.72
			c0.051-1.332,2.899-0.728,3.12,0c3.17,1.149,6.074,2.565,10.08,2.88c2.08,0,4.16,0,6.24,0c1.048,0.008,1.417-0.663,2.4-0.72
			c0.71-0.569,1.189-1.371,2.16-1.681c0.014-0.146,0.036-0.284,0.24-0.24c0.563-0.877,1.449-1.43,2.16-2.159
			c4.257-4.063,6.779-9.861,9.12-15.841C291.83,521.51,292.111,520.752,292.4,520.001z M294.801,461.201
			c0.656-0.863,1.184-0.077,1.92,0.239c1.293,0.786,2.411,1.749,3.119,3.12c0.771,1.149,1.549,2.292,2.4,3.36
			c0.352,0.209,0.761,0.359,0.721,0.96c0.772,0.231,1.865,2.004,0.72,2.64c-0.359-0.041-0.886,0.086-0.96-0.24
			c-0.204,0.045-0.227-0.093-0.24-0.239c-0.639-0.642-1.268-1.292-1.68-2.16c-0.225-0.176-0.363-0.437-0.48-0.72
			c-0.543-0.896-1.215-1.665-1.68-2.641c-1.061-0.859-2.021-1.819-2.88-2.88C295.33,462.271,294.559,462.243,294.801,461.201z
			 M279.44,444.4c1.583,0.266,3.377,1.393,4.8,2.4c1.176,0.834,3.647,2.401,2.4,4.08c-1.992-1.208-3.937-2.464-5.28-4.32
			C280.657,445.904,279.147,446.054,279.44,444.4z M242.24,488.081c-1.027-0.573-1.948-1.252-2.4-2.4
			c-0.765-0.521-1.809-3.354,0-3.6c1.475,3.725,5.093,5.307,9.12,6.479C248.184,491.723,243.865,488.572,242.24,488.081z
			 M265.521,509.44c-1.453,0.014-2.948,0.068-3.84-0.479c-2.362-0.278-4.095-1.186-5.52-2.4c-0.776-0.184-1.256-0.664-1.44-1.439
			c-0.537-0.104-0.097-1.183-0.24-1.681c3.005,1.075,4.438,3.722,8.4,3.841c1.549-0.029,2.993,0.047,4.08,0.479
			c1.527,0.232,2.957,0.563,2.88,2.4C267.862,510.46,267.448,509.193,265.521,509.44z M286.161,517.841
			c-2.577,0.335-7.623,1.063-7.44-2.16c1.145-0.186,1.544,0.376,2.64,0.24c1.6,0,3.2,0,4.8,0c0.186,0.054,0.473,0.007,0.48,0.24
			c0.431-0.031,0.562,0.238,0.48,0.72C287.091,517.491,286.771,517.812,286.161,517.841z"/>
			</g>
			<g id="gray_1_">
				<path fillRule="evenodd" clipRule="evenodd" fill="#9FAABD" d="M325.521,195.52c0.316,0.744-0.711,1.332,0,1.68
			c0.07-0.489,0.649-0.471,0.72-0.96C325.688,196.313,325.974,195.547,325.521,195.52z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#9FAABD" d="M279.44,165.76c0.014,0.146,0.036,0.284,0.24,0.24
			c1.28-0.72,2.654-1.345,4.08-1.92c0.104-0.295,0.307-0.493,0.72-0.48c0.947-0.653,1.769-1.431,2.64-2.16c0.08,0,0.16,0,0.24,0
			c0.163-0.557,0.798-0.642,0.96-1.2c-0.679,0.361-1.022,1.058-1.92,1.2C284.117,162.917,281.662,164.221,279.44,165.76z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#9FAABD" d="M325.521,193.6c0.469-1.771-0.068-4.549,0.48-6.24
			c-0.154-3.687,0.312-7.992-0.24-11.28c0.148-3.188-0.463-5.617-0.48-8.64c-0.611-1.948-0.766-4.354-1.2-6.48c0-0.32,0-0.64,0-0.96
			c-0.588-1.572-1.185-3.135-1.439-5.04c-0.279-0.04-0.261-0.379-0.24-0.72c-0.523-1.157-0.861-2.499-1.2-3.84
			c-0.215-2.185-1.283-3.517-1.68-5.52c0.104-5.077-3.873-7.469-6.24-10.8c-1.459-1.821-3.341-3.219-4.8-5.04
			c-0.053-0.187-0.201-0.279-0.48-0.24c-0.187,0.053-0.278,0.201-0.239,0.48c0.251,0.502-0.83,1.722-1.2,2.4
			c-1.944,2.561-2.994,5.524-5.279,8.16c-0.832,1.649-1.943,3.018-3.361,4.08c0.231,0.871-1.078,0.202-0.959,0.96
			c-0.389,0.972-1.223,1.498-2.16,1.92c-1.326,0.434-2.016,1.504-3.12,2.16c-3.381,2.379-6.23,5.29-9.12,8.16
			c-0.307,0.013-0.333,0.308-0.72,0.24c-0.5,0.946-1.723,1.883-3.12,1.2c0.143-0.497-0.297-1.577,0.24-1.68
			c1.644-1.236,3.246-2.515,4.32-4.32c0.7-0.9,1.5-1.701,2.4-2.4c0.36-0.28,0.587-0.693,1.2-0.72c0.706-0.254,1.111-0.81,1.68-1.2
			c0.949-1.372,3.009-1.631,4.08-2.88c0.08,0,0.16,0,0.24,0c2.653-2.786,5.277-5.602,7.68-8.64c0.358-1.162,0.923-2.117,1.68-2.88
			c0.572-0.708,0.72-1.84,1.44-2.4c0.609-0.75,0.677-2.043,1.44-2.64c0.131-0.749,1.002-0.757,0.959-1.68
			c-0.393-0.566-1.236-0.684-1.68-1.2c-1.063-0.458-2.402-0.638-3.119-1.44c-0.916,0.036-1.156-0.604-1.921-0.72
			c-1.438-0.723-2.905-1.415-4.08-2.4c-2.413-1.906-5.546-3.093-7.92-5.04c-1.198-0.562-2.321-1.199-3.12-2.16
			c-1.168-0.512-2.077-1.283-3.36-1.68c-1.388-0.211-2.591-0.608-3.6-1.2c-0.04-0.279-0.379-0.26-0.72-0.24
			c-1.001-0.278-1.791-0.769-2.64-1.2c-1.139-0.381-2.21-0.83-3.84-0.72c-2.002-0.159-4.638,0.318-6.24-0.24
			c0.108-0.392-1.308-0.392-1.2,0c-0.489,0.551-1.993,0.087-2.88,0.24c-1.248,0.032-2.112,0.448-3.36,0.48
			c-0.695,0.425-1.837,0.402-2.64,0.72c-3.052,0.789-5.933,1.747-7.92,3.6c-0.509,0.851-0.875,1.845-2.16,1.92
			c-0.561-0.079-0.992-0.288-1.44-0.48c-0.354-1.392-1.989-0.201-3.12-0.24c-1.546,0.054-2.249,0.951-3.6,1.2
			c-1.058,0.382-1.603,1.277-2.88,1.44c-1.175,0.505-1.97,1.39-3.6,1.44c-0.988,0.452-1.683,1.197-2.88,1.44
			c-1.141,0.859-2.252,1.748-3.84,2.16c-1.517,0.483-2.474,1.526-4.08,1.92c-0.538,0.742-1.316,1.244-2.4,1.44
			c-0.613,0.027-0.84,0.44-1.2,0.72c-0.712,0.568-1.54,1.02-2.4,1.44c-1.186,0.895-2.306,1.854-3.6,2.64
			c-0.814,0.866-1.427,1.933-2.64,2.4c-0.586,1.174-1.444,2.077-2.16,3.12c-0.527,0.913-0.899,1.98-1.68,2.64
			c-0.427,0.453-0.791,0.969-0.72,1.92c0.146,2.405-0.28,4.341,0,6.24c0.038,0.282-0.042,0.681,0.24,0.72
			c0.547,0.333,0.09,1.67,0.24,2.4c-0.108,1.309,0.428,1.972,0.24,3.36c0.372,0.588,0.358,1.562,0.48,2.4
			c0.357,0.363,0.387,1.053,0.48,1.68c0.528,0.591,0.533,1.707,0.96,2.4c0.349,1.251,1.136,2.063,1.2,3.6
			c0.257,0.623,0.153,1.607,0.72,1.92c0.392,0.808,0.625,1.775,0.96,2.64c0.769,1.953,2.2,4.518,2.64,6c-0.08,0-0.16,0-0.24,0
			c-0.73,0.011-0.933-0.506-1.44-0.72c-0.955-0.165-0.57-1.67-1.68-1.68c0.268-0.295,0.209-0.493-0.24-0.48
			c0.147-0.213,0.29-0.429,0-0.48c-0.917-0.683-1.405-1.795-1.68-3.12c-0.08-0.56-0.246-1.034-0.72-1.2
			c-0.431-0.849-0.922-1.639-1.2-2.64c-0.807-1.193-1.262-2.738-1.44-4.56c-0.189-0.931-0.744-1.496-0.72-2.64
			c-0.97-1.672-0.425-6.133-0.96-7.44c0.044,0.204-0.094,0.227-0.24,0.24c-0.187,0.053-0.279,0.201-0.24,0.48
			c-0.826,2.054-1.786,3.974-2.88,5.76c-0.521,0.679-1.026,1.374-1.2,2.4c0.201,0.514,0.245,1.165,0.24,2.4
			c0.707,1.213,1.699,2.141,2.16,3.6c0.367,0.437,0.367,1.962,0,2.4c1.144,1.496,2.64,2.641,4.08,3.84
			c0.775-0.521,2.96-0.862,3.36,0.24c1.346,1.694,2.935,3.146,4.08,5.04c0.551,0.329,0.772,0.988,1.2,1.44
			c0.97,1.59,2.433,2.687,3.36,4.32c0.49,0.31,0.607,0.993,1.2,1.2c1.502,0.898,2.681,2.119,3.84,3.36
			c2.262,1.818,5.454,2.706,7.44,4.8c0.989,0.131,1.451,0.79,1.92,1.44c0.053,0.187,0.201,0.279,0.48,0.24
			c-1.102-3.54-3.426-7.934-4.32-12.48c-0.549-0.651-0.271-2.129-0.72-2.88c-0.272-2.688-0.971-4.95-0.96-7.92
			c0.177-1.938-0.39-3.13-0.24-5.04c-0.398-0.65-0.398-2.951,0-3.6c0-0.08,0-0.16,0-0.24c0-0.24,0-0.48,0-0.72
			c0.47-0.295,2.604-0.649,2.4,0.48c0.927,2.034,0.813,5.106,0.96,7.92c0,0.64,0,1.28,0,1.92c0.836,0.124,0.352,1.568,0.48,2.4
			c0,0.4,0,0.8,0,1.2c0.369,0.751,0.382,1.858,0.48,2.88c0.521-0.041,0.109,0.852,0.24,1.2c0.349,1.012,0.579,2.141,0.72,3.36
			c0.344,0.296,0.455,0.825,0.48,1.44c0.359,0.681,0.592,1.488,0.72,2.4c0.595,0.686,1.096,1.464,1.44,2.4
			c0.477,0.003,0.305,0.655,0.96,0.48c-0.795-0.421-0.045-0.898-0.48-1.68c-0.006-0.233-0.293-0.186-0.48-0.24
			c0.408-0.869-0.703-1.272-0.48-2.64c-0.91-2.05-0.304-5.616-0.48-8.4c0.654-2.966,0.906-5.391,1.92-6.48
			c0.193-1.238,0.729-2.533,1.44-3.84c0.148-1.212,0.788-1.932,1.44-2.64c0.757-0.923,1.635-1.725,2.16-2.88
			c-0.129-1.178,1.586-0.652,2.16-0.48c0.049,1.009,0.086,2.006-0.72,2.16c0,0.08,0,0.16,0,0.24c-0.217,0.903-0.516,1.725-0.96,2.4
			c0.31,0.95-0.79,0.49-0.48,1.44c-0.03,0.85-0.394,1.366-0.72,1.92c-1.544,4.354-3.36,9.396-2.4,14.4
			c0.158,0.882,0.466,1.614,0.48,2.64c0.371,0.349,0.503,0.936,0.48,1.68c0.495,0.705,0.669,1.731,0.96,2.64
			c0.53,0.831,1.188,1.532,1.2,2.88c0.148,0.652,0.476,1.124,0.96,1.44c0.671,0.751,2.124,2.246,0.96,3.36
			c0.681,0.919,1.501,1.699,2.64,2.16c0.788,0.092,1.058,0.702,1.68,0.96c1.181,1.076,1.868-1.568,2.4-2.64
			c0.261-0.06,0.163-0.477,0.48-0.48c0.214-0.826,0.584-1.496,0.96-2.16c0.764-1.476,1.411-3.069,2.16-4.56
			c0.347-0.373,0.32-1.12,0.72-1.44c0.217-1.224,0.695-2.185,1.44-2.88c0.952-1.448,1.249-3.551,2.88-4.32
			c0.583-0.568,2.276-0.208,2.4,0.48c0.027,0.292,0.072,0.568,0.48,0.48c0.319,0.587,0.893-0.264,0.96,0.24
			c-0.241,0.319-0.227,0.893-0.72,0.96c-0.456,0.984-1.045,1.834-1.68,2.64c-0.409,0.791-0.942,1.458-1.2,2.4
			c1.791-0.93,2.721-2.719,4.32-3.84c1.207-1.433,2.366-2.914,3.84-4.08c0.591-0.413-0.64-1.917,0.48-2.16
			c0.263-0.857,0.823-1.417,1.68-1.68c0.31-1.05,1.211-1.509,1.92-2.16c0.027-0.293,0.072-0.568,0.48-0.48
			c1.34-1.54,2.78-2.98,4.32-4.32c0.08,0,0.16,0,0.24,0c1.855-1.905,4.067-3.452,6.24-5.04c1.333-0.588,2.341-1.499,3.6-2.16
			c0.564-0.716,1.22-1.341,2.4-1.44c1.524-0.396,2.189-1.651,3.6-2.16c1.216-0.944,2.226-2.094,3.12-3.36
			c1.603-1.518,2.192-4.047,4.56-4.8c1.52-0.031,0.407,3.978-0.72,4.32c-0.378,0.741-0.827,1.413-1.44,1.92
			c0.039,0.281,0.438,0.202,0.721,0.24c0.683-0.51,2.752-0.361,2.64,0.72c0.994,2.756-1.931,3.667-2.88,5.28
			c-0.033,1.074,0.972,1.108,0.96,2.16c0.475,0.416,0.911,2.115,0.72,2.4c0.357,1.644,1.141,2.86,1.2,4.8
			c0.312,0.969,2.593-0.033,2.64,1.2c0.662,0.378,1.411,0.67,2.16,0.96c1.737,0.104,2.742,0.938,4.08,1.44
			c1.044,0.476,2.299,0.741,3.121,1.44c1.009-0.29,1.052,0.387,1.68,0.48c0.529,0.03,0.102,1.018,0.24,1.44
			c-0.145-0.054-0.379-0.017-0.604,0.01L308,178.48c-1.902-0.417-3.604-1.037-5.76-1.2c-2.322-0.078-4.082-0.718-5.52-1.68
			c-0.33,0.01-0.527-0.113-0.721-0.24c1.17,3.162,0.273,8.175,1.201,11.28c0.1,0.338,0.168,0.88,0.959,0.72c0.561,0,1.121,0,1.68,0
			c0.125,0.355,0.355,0.604,0.48,0.96c0.913,2.527,0.303,6.577,0.48,9.84c0.328,0.563,0.448,2.577,0,3.12
			c0.345,3.655,0.843,7.157,1.68,10.32c0.664,0.776,0.982,1.897,1.68,2.64c0.041,0.68,0.44,1,0.48,1.68
			c1.098,0.902,1.654,2.346,2.4,3.6c0.469,0.891,1.039,1.681,1.439,2.64c0,0.16,0,0.32,0,0.48c0.862,0.818,1.285,2.075,1.92,3.12
			c0,0.241,0.194,0.286,0.24,0.48c0.303,0.577,0.309,1.451,0.72,1.92c-0.018,0.418,0.054,0.747,0.24,0.96
			c0.568,0.632,0.79,1.61,0.96,2.64c0.269,0.771,0.657,1.423,0.72,2.4c0.533,0.347,0.332,1.429,0.721,1.92
			c0.154,1.445,0.737,2.462,0.72,4.08c0.261-0.059,0.163-0.477,0.479-0.48c-0.066-2.467,0.548-4.253,0.48-6.72
			c0.597-0.363-0.147-2.067,0.479-2.4c0-0.64,0-1.28,0-1.92c0.613-0.427-0.159-2.239,0.48-2.64c0.308-1.704-0.065-2.613,0.72-3.84
			c-0.459-1.185,0.241-3.275,0.48-4.56c-0.169-1.048,0.408-1.352,0.239-2.4c0.514-1.247,0.34-3.181,0.961-4.32
			c0.028-1.732,0.292-3.228,0.72-4.56c0.138-0.422-0.29-1.41,0.24-1.44c0.323-2.396,0.245-5.194,0.96-7.2
			c0.276-0.284,0.271-0.848,0.239-1.44c0.021-0.78,0.124-1.477,0.48-1.92c-0.226-1.659,2.385-2.241,3.36-1.2
			C325.357,193.123,325.26,193.541,325.521,193.6z M241.76,194.8c-0.279-0.039-0.427,0.053-0.48,0.24
			c-0.859-0.181-1.524-0.555-1.68-1.44c-0.583-0.376-0.899-1.021-0.96-1.92c-0.133-0.427-0.124-0.997-0.48-1.2
			c-1.135-2.191-1.337-6.728-0.24-9.12c0.99-2.21,1.711-4.689,3.12-6.48c-0.068-0.388,0.227-0.414,0.24-0.72
			c1.447,1.149-0.479,4.669-0.96,6.24c-0.483,0.717-0.706,1.693-0.72,2.88c-0.049,1.969-0.005,3.844,0.24,5.52
			c0.401,0.559,0.627,1.293,0.72,2.16c0.274,0.846,0.527,1.713,1.2,2.16C242.273,193.27,241.985,194.598,241.76,194.8z
			 M262.161,160.48c-2.291,3.79-4.732,7.428-5.28,12.96c0,0.96,0,1.92,0,2.88c-0.08,0-0.16,0-0.24,0
			c-0.402,0.318-0.804,0.636-1.68,0.48c-0.51-1.01-0.116-2.924-0.24-4.32c-0.283-1.403,0.601-1.639,0.48-2.88
			c0.408-1.033,0.76-2.121,1.2-3.12c0.08,0,0.16,0,0.24,0c0.951-0.969,1.417-2.423,2.16-3.6c0.713-1.207,1.584-2.256,2.88-2.88
			c0.317-0.003,0.219-0.42,0.48-0.48C262.873,158.083,263.241,160.535,262.161,160.48z M315.921,163.6
			c-0.054,0.187-0.201,0.279-0.48,0.24c-1.489,0.271-2.719,0.801-4.08,1.2c-1.192,0.408-2.698,0.501-4.319,0.48
			c-1.941,0.14-4.453-0.292-6,0.24c-1.645-0.044-3.213-0.013-4.561,0.24c-0.369-0.366-0.428-1.646,0.48-1.44
			c0.359-0.041,0.885,0.086,0.959-0.24c0.411-0.549,1.832-0.088,2.641-0.24c1.325,0.125,2.263-0.138,3.359-0.24c2,0,4,0,6,0
			c2.074,0.154,2.942-0.899,4.801-0.96c0.054-0.186,0.007-0.473,0.24-0.48c0.159,0,0.319,0,0.479,0c0.15-0.512,1.478-0.225,1.68,0
			C317.161,163.241,316.276,163.156,315.921,163.6z"/>
			</g>
			<g id="black_1_">
				<path fillRule="evenodd" clipRule="evenodd" d="M301.041,165.76c1.547-0.532,4.059-0.101,6-0.24
			c1.621,0.021,3.127-0.072,4.319-0.48c1.361-0.398,2.591-0.929,4.08-1.2c0.279,0.039,0.427-0.053,0.48-0.24
			c0.355-0.444,1.24-0.359,1.199-1.2c-0.202-0.225-1.529-0.512-1.68,0c-0.16,0-0.32,0-0.479,0c-0.233,0.007-0.187,0.294-0.24,0.48
			c-1.858,0.062-2.727,1.114-4.801,0.96c-2,0-4,0-6,0c-1.097,0.102-2.034,0.365-3.359,0.24c-0.809,0.152-2.229-0.309-2.641,0.24
			c-0.074,0.326-0.6,0.199-0.959,0.24c-0.908-0.206-0.85,1.074-0.48,1.44C297.828,165.747,299.396,165.716,301.041,165.76z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M201.68,190.24c-1.657-0.297-0.898,1.821-1.68,2.4
			c-0.676,1.004-0.806,2.554-1.2,3.84c-0.345,2.375-0.715,4.725-0.96,7.2c-0.399,1.523-0.399,4.716,0,6.24
			c0.794,3.807-0.536,10.879,3.36,10.8c-0.982-8.07-1.619-19.738,0.72-27.6C202.283,192.624,203.014,190.244,201.68,190.24z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M262.4,328c-0.197-1.563-0.383-3.136-0.72-4.56
			c-2.264-0.042-1.626,4.772-0.72,5.76c0.182,0.618,0.766,0.834,1.68,0.72C262.963,329.569,263.131,328.02,262.4,328z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M286.161,517.841c0.61-0.029,0.931-0.35,0.96-0.96
			c0.082-0.481-0.049-0.751-0.48-0.72c-0.007-0.233-0.294-0.187-0.48-0.24c-1.6,0-3.2,0-4.8,0c-1.095,0.136-1.495-0.426-2.64-0.24
			C278.538,518.904,283.584,518.176,286.161,517.841z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M238.16,190.48c0.357,0.204,0.348,0.773,0.48,1.2
			c0.061,0.899,0.376,1.543,0.96,1.92c0.155,0.885,0.821,1.259,1.68,1.44c0.053-0.187,0.201-0.279,0.48-0.24
			c0.225-0.203,0.513-1.53,0-1.68c-0.672-0.447-0.925-1.314-1.2-2.16c-0.093-0.867-0.319-1.601-0.72-2.16
			c-0.245-1.676-0.289-3.551-0.24-5.52c0.014-1.187,0.237-2.163,0.72-2.88c0.417-1.361,1.915-4.184,1.367-5.661
			c0.022-0.115,0.052-0.223,0.073-0.339c-0.066,0.033-0.13,0.067-0.191,0.104c-0.077-0.128-0.167-0.248-0.289-0.344
			c-0.013,0.307-0.308,0.333-0.24,0.72c-1.409,1.791-2.13,4.27-3.12,6.48C236.823,183.752,237.025,188.289,238.16,190.48z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M519.681,553.841c-0.436-0.925-1.813-0.906-2.88-1.2
			c-0.863-0.417-1.858-0.701-3.12-0.72c-4.083-1.197-9.652-0.907-14.64-1.2c-3.451-0.068-6.425-0.615-9.12-1.439
			c-1.711-0.77-3.639-1.321-5.28-2.16c-0.319,0-0.64,0-0.96,0c-0.08,0-0.16,0-0.239,0c-2.52,2.163,1.373,4.181,3.6,4.56
			c1.584,0.336,2.988,0.853,4.56,1.2c2.948,0.823,7.483,1.302,10.561,1.2c0.72,0,1.439,0,2.16,0c0,0.08,0,0.16,0,0.24
			c0.96,0,1.92,0,2.88,0c2.257,0.382,4.647,0.632,6.72,1.199c2.192-0.282,3.891,1.979,5.76,0.48
			C520.5,555.609,520.5,554.232,519.681,553.841z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M224.48,277.36c0.292-0.027,0.568-0.072,0.48-0.48
			c0.825-0.455,1.22-1.339,1.68-2.16c0.723-0.717,0.84-2.04,1.44-2.88c0.21-0.669,0.574-1.186,0.72-1.92
			c0.991-2.609,2.206-4.994,3.84-6.96c0.717-0.369,0.249-1.066,0-1.68c-0.688-0.047-1.31-0.03-1.2,0.72
			c-1.101,1.459-1.477,3.643-2.4,5.28c-0.674,0.926-1.726,1.475-2.16,2.64c-1.819,2.261-4.997,3.163-5.04,7.2
			C222.145,277.704,224.058,278.388,224.48,277.36z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M427.761,452.561c0.904-0.185,1.147,0.293,1.92,0.24
			c2.722,0.399,7.118,0.399,9.84,0c2.818-0.223,5.342-0.739,8.16-0.96c3.139-0.302,6.016-0.864,8.641-1.68
			c2.61-0.75,5.316-1.403,8.159-1.921c1.452-0.308,2.854-0.666,4.08-1.199c0.08,0,0.16,0,0.24,0c0.32,0,0.64,0,0.96,0
			c1.045-0.236,2.283-0.277,3.12-0.721c1.073-0.127,2.004-0.396,2.4-1.199c0.279-0.041,0.261-0.38,0.239-0.721
			c0.104-1.389-1.672-2.16-2.88-1.439c-1.992,0.406-3.692,1.106-5.76,1.439c-1.632,0.368-3.26,0.74-4.56,1.44
			c-2.032,0.769-4.248,1.353-6.721,1.68c-2.88,0.72-5.953,1.247-8.88,1.92c-1.829,0.411-3.777,0.703-6,0.721
			c-1.124,0.155-2.874-0.314-3.6,0.239c-3.04,0-6.08,0-9.12,0c-0.853,0.027-0.966,0.795-0.96,1.681
			C427.384,452.139,427.445,452.476,427.761,452.561z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M369.44,376.721c0-0.08,0-0.16,0-0.24c0.759-0.601,1.08-1.64,1.44-2.64
			c-0.174-0.733,0.245-0.875,0.24-1.44c0.397-0.336,0.397-2.304,0-2.64c-0.158-1.522-0.749-2.612-0.96-4.08
			c-0.586-1.654-1.434-3.047-2.881-3.84c-1.378-0.622-2.937-1.063-4.56-1.44c-1.479-0.548-4.348-0.765-5.76,0
			c-1.118,0.322-2.179,0.702-3.36,0.96c-2.418,1.022-4.241,2.639-5.28,5.04c-0.197,1.082-1.43,1.13-1.68,2.16
			c-1.25,1.935-0.639,5.795,0.24,7.68c0.138,0.501,0.595,0.685,0.479,1.44c1.324,2.676,3.429,4.572,6.48,5.52
			c1.183,0.177,2.218,0.501,3.359,0.72c0.729,0.399,3.112,0.399,3.841,0c0.563-0.076,0.9-0.379,1.68-0.24
			C366.129,382.529,367.131,378.971,369.44,376.721z M361.28,380.561c-2.197,0.596-4.325-0.091-6.239-0.48
			c-0.561-0.08-1.034-0.246-1.2-0.72c-0.459-0.661-1.02-1.221-1.681-1.68c-1.288-1.192-1.98-2.979-2.399-5.04c0-0.96,0-1.92,0-2.88
			c0.503-1.098-0.011-3.21,1.2-3.6c0.74-0.54,1.381-1.179,1.92-1.92c0.708-0.812,1.803-1.238,3.12-1.44
			c0.809-0.071,1.639-0.121,2.159-0.48c1.514-0.073,2.843,0.038,4.08,0.24c1.232,0.048,1.926,0.634,3.12,0.72
			c2.018,0.783,2.87,2.73,3.36,5.04c0.432,2.344,0.244,4.674-0.72,6.24c-0.244,0.956-0.727,1.673-1.44,2.16
			c-0.647,0.392-1.142,0.938-1.439,1.68C364.312,379.592,362.889,380.169,361.28,380.561z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M289.761,414.4c-0.2-0.601-0.657-0.942-0.72-1.68
			c-1.75-3.384-6.872-1.051-6.241,2.88c-0.431,3.164-0.337,6.773,0.72,9.12c0.325,1.69,3.02,3.7,4.56,1.92
			c0.27-0.051,0.374-0.266,0.48-0.48c0.979-0.701,1.434-1.926,1.68-3.359c0.129-1.712,0.62-3.061,0.48-5.04
			C290.666,416.375,290.413,415.188,289.761,414.4z M287.36,423.761c0.039,0.278-0.053,0.427-0.24,0.479
			c-0.343,0.059-0.405,0.396-0.72,0.48c-0.148-0.652-0.476-1.124-0.96-1.439c-0.233-0.008-0.186-0.295-0.24-0.48
			c-0.693-1.147-0.446-3.234-0.48-5.04c-0.014-1.935,0.353-3.487,1.2-4.56c1.148,0.372,1.737,1.303,1.92,2.64
			c0,0.159,0,0.319,0,0.479c-1.361,0.139-3.336,4.646-1.44,6.48c1.4-0.84,0.739-3.741,1.92-4.801
			C288.426,420.346,287.762,421.922,287.36,423.761z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M426.081,422.801c-0.16,0-0.32,0-0.48,0c-2.9,1.979-4.283,5.477-6.24,8.4
			c0,0.08,0,0.16,0,0.239c-1.114,1.366-1.932,3.028-2.88,4.56c-0.263,1.098-0.97,1.751-1.439,2.641
			c-0.882,0.799-1.323,2.037-1.92,3.12c0,0.08,0,0.16,0,0.239c-1.405,1.955-2.48,4.241-3.36,6.721
			c-0.146,1.214-0.61,2.109-0.96,3.12c-0.791,2.313-2.821,6.913-0.72,9.12c0.479,0,0.96,0,1.439,0c1.088-1.313,0.9-3.899,1.44-5.76
			c0.329-2.231,1.075-4.045,1.68-6c1.038-2.163,2.001-4.4,3.12-6.48c0-0.08,0-0.16,0-0.24c3.479-5.24,6.503-10.938,10.08-16.08
			c0-0.08,0-0.16,0-0.24C426.824,425.857,427.868,423.024,426.081,422.801z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M438.164,479.571c-0.02-0.041-0.034-0.085-0.043-0.137c0.001,0,0.001,0,0.001,0
			c-0.002-0.011-0.004-0.021-0.006-0.032c-0.009-0.069-0.018-0.141-0.035-0.201c-0.086-0.007-0.156,0.002-0.238-0.001
			c-1.312-0.824-3.452,1.058-4.322,1.681c-0.507,0.213-0.709,0.73-1.439,0.72c-1.625,0.855-3.305,1.655-4.561,2.88
			c-0.08,0-0.159,0-0.239,0c-1.937,1.309-4.055,3.252-6,5.04c-1.76,1.617-5.035,3.496-3.841,6.48c2.568-0.605,4.271-2.737,6-4.32
			c1.841-1.685,3.521-3.488,5.521-5.04c0.477-0.003,0.305-0.654,0.96-0.479c1.113-0.727,2.45-1.229,3.36-2.16
			c0.506-0.214,0.709-0.73,1.439-0.72c1.343-0.978,3.155-1.485,3.6-3.36c0-0.08,0-0.16,0-0.24c-0.062-0.095-0.13-0.172-0.198-0.246
			C438.13,479.486,438.146,479.53,438.164,479.571z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M193.28,531.041c0.501-4.459,0.721-9.199,1.2-13.68
			c0.384-4.577,1.363-8.557,2.4-12.48c0.564-1.116,0.89-2.471,1.2-3.84c0.772-3.309,2.122-6.038,3.36-8.88
			c0.639-0.655-0.35-1.719-1.2-1.681c-1.279,0.962-1.761,2.719-2.4,4.32c-0.512,1.969-1.328,3.633-1.92,5.52
			c-0.788,1.693-1.362,3.599-1.68,5.761c-0.555,2.885-1.113,5.767-1.68,8.64c-0.823,5.88-2.581,14.911-0.48,20.641
			C193.211,534.652,192.776,532.377,193.28,531.041z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M203.36,506.561c-0.016,1.136-0.038,2.278,0.72,2.641
			c0.644-1.517,0.942-3.378,0.96-5.521c0.083-2.717,0.379-5.221,0.72-7.68c0.589-0.451,0.171-1.915-0.24-2.16
			c-0.914-0.114-1.499,0.102-1.68,0.72c-0.045,1.635-0.481,2.879-0.48,4.561c-0.399,1.284-0.399,4.235,0,5.52
			C203.36,505.281,203.36,505.921,203.36,506.561z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M513.441,574.721c-0.622-0.665-1.272-1.302-1.928-1.934
			c-0.197-0.211-0.411-0.416-0.634-0.616c0.003,0.003,0.007,0.005,0.01,0.008c-3.578-3.364-7.582-6.303-11.849-8.978
			c-0.655,0.175-0.483-0.478-0.96-0.48c-1.107-1.133-2.724-1.756-4.08-2.64c-0.73,0.011-0.934-0.507-1.44-0.72
			c-0.817-0.862-2.075-1.285-3.119-1.92c-1.056-0.145-1.533-0.868-2.4-1.2c-0.534-1.223-4.157-1.785-3.12,0.479
			c0.055,0.825,0.714,1.046,1.44,1.2c1.097,0.903,2.466,1.534,3.84,2.16c0.763,0.757,1.718,1.321,2.88,1.68
			c2.139,1.702,4.913,2.767,6.96,4.561c1.262,0.737,2.206,1.793,3.6,2.399c0.175,0.545,0.723,0.718,1.2,0.96
			c0.067,0.493,0.642,0.479,0.96,0.721c1.99,0.452,5.513,6.782,7.68,6.239c0.704-0.176,1.174-0.603,0.961-1.68
			C513.441,574.881,513.441,574.801,513.441,574.721z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M230.96,609.521c0.278-0.202,0.644-0.317,1.2-0.24c2.3-0.741,3.88-2.2,5.04-4.08
			c0.15-0.891,0.675-1.405,0.96-2.16c1.818-3.198,0.026-7.891-2.88-8.64c-1.856-0.543-3.389,0.153-5.04,0.479
			c-3.097,1.623-5.822,3.618-6.72,7.44c-0.144,1.264,0.178,2.062,0.24,3.12C224.568,608.234,227.266,610.414,230.96,609.521z
			 M231.44,603.041c-0.787,0.014-0.917,0.683-1.92,0.48c-0.473-0.502-0.184-1.594,0-2.16c0.42-1.101,1.346-1.694,2.88-1.681
			c0,0.08,0,0.16,0,0.24C232.814,601.608,232.279,602.13,231.44,603.041z M226.4,601.841c0.798-1.042,1.176-2.504,2.64-2.88
			c0.349,0.106-0.024,0.211,0,0.48c-0.379,0.581-0.859,1.061-1.44,1.439c-0.675,0.11-1.154,1.923-0.24,2.16
			c0.512-0.112,0.457,0.343,0.48,0.72c0.222,1.752,3.051,1.698,3.84,0.48c0.938-0.343,1.578-0.982,1.92-1.92
			c-0.011-0.411,0.178-0.622,0.48-0.721c0.517-0.689,0.329-2.72-0.48-2.88c-0.467-0.413-0.776-0.984-1.92-0.72
			c-0.279,0.039-0.427-0.053-0.48-0.24c0.56-0.08,1.034-0.246,1.2-0.72c0.662,0.083,1.686-0.689,2.16,0c0,0.08,0,0.16,0,0.24
			c0.692,0.107,0.567,1.032,1.2,1.2c0.774,2.194-0.342,3.98-0.96,5.52c-0.616,1.304-1.617,2.223-3.12,2.64
			c-1.27,0.376-3.092,0.338-4.08-0.239c-0.616-0.585-0.882-1.519-1.44-2.16C226.019,603.219,226.333,602.653,226.4,601.841z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M264.8,634.241c2.623,0.116,4.149,0.535,6.48,0c0.83-0.37,1.979-0.421,2.16-1.44
			c-0.035-0.754,0.616-0.824,1.2-0.96c1.235-1.004,2.443-2.036,2.88-3.84c0.132-0.747,0.196-1.563,0.48-2.16
			c0.397-0.259,0.397-2.141,0-2.399c0.005-0.565-0.414-0.707-0.24-1.44c-1.355-3.204-3.221-5.899-6.96-6.72
			c-1.513-0.073-2.842,0.038-4.08,0.24c-3.426,1.055-6.013,2.947-7.44,6c-1.142,2.248-0.683,5.568,0.24,7.68
			C260.678,631.483,262.144,633.458,264.8,634.241z M262.4,622.241c0.295-0.105,0.493-0.308,0.48-0.72
			c0.597-0.443,1.354-0.727,1.68-1.44c0.166-0.474,0.64-0.64,1.2-0.72c1.666-0.741,4.438-1.099,6,0
			c0.955,0.805,1.972,1.548,2.4,2.88c0.733,1.755,0.431,3.919-0.24,5.52c-0.084,0.796-0.719,1.041-1.2,1.44
			c-0.938,0.342-1.578,0.982-1.92,1.92c-0.912,1.992-4.635,0.306-6-0.24c-0.459-0.661-1.019-1.221-1.68-1.68
			c-0.474-0.166-0.64-0.641-0.72-1.2C261.602,626.375,261.412,623.807,262.4,622.241z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M266.24,628.241c0.783,0.832,2.519,0.241,2.88-0.72
			c0.336-0.384,0.827-0.613,1.44-0.721c0.776-0.184,1.256-0.664,1.44-1.439c1.609-2.752-1.24-6.205-4.32-5.04
			c-0.615,0.904-2.591,0.449-2.64,1.92c-0.959,0.475-1.048,3.577-0.24,4.319C265.25,627.151,265.73,627.712,266.24,628.241z
			 M265.76,623.201c0.542-1.516,4.318-2.926,5.04-0.72c0.753,1.466-0.195,2.679-0.96,3.359c-0.158,0.322-0.513,0.447-0.96,0.48
			c-1.026,0.014-1.758,0.322-2.64,0.479C265.777,625.905,265.667,624.654,265.76,623.201z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M303.681,650.321c-2.935-0.186-5.229-1.012-7.681-1.681
			c-2.009-0.87-4.17-1.59-6.479-2.159c-1.773-0.387-3.448-0.872-5.04-1.44c-1.288-0.632-2.73-1.11-4.32-1.439
			c-2.66-0.541-5.064-1.336-7.68-1.921c-1.069-0.584-3.964-0.959-4.08,0.721c0,0.239,0,0.479,0,0.72c0,0.08,0,0.16,0,0.24
			c0.701,0.739,2.066,0.813,3.36,0.96c1.856,0.224,3.426,0.733,5.04,1.2c1.899,0.74,3.877,1.402,6,1.92
			c1.845,0.314,3.414,0.906,5.04,1.439c1.529,0.712,3.21,1.271,5.041,1.681c3.359,0.8,6.891,1.429,10.56,1.92
			c1.218,0.412,4.035,0.358,4.56-0.721C307.277,650.564,305.304,650.618,303.681,650.321z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M267.44,722.562c-0.4,0-0.8,0-1.2,0c-2.369-0.05-4.099,0.54-5.28,1.68
			c-0.08,0-0.16,0-0.24,0c-3.325,1.692-5.979,8.613-3.12,12.72c0.074,0.566,0.833,0.447,0.72,1.2c0.77,0.831,1.741,1.459,2.64,2.16
			c1.124,0.316,1.93,0.95,3.12,1.2c0.259,0.397,2.141,0.397,2.4,0c1.504-0.336,2.544-1.137,3.84-1.68
			c1.567-1.073,2.931-2.351,3.84-4.08c1.183-2.175,1.654-6.086,0.48-8.641C273.276,724.565,271.12,722.802,267.44,722.562z
			 M272.48,731.201c-0.015,1.265,0.043,2.604-0.96,2.88c0.067,0.388-0.227,0.413-0.24,0.72c-0.741,0.54-1.38,1.18-1.92,1.921
			c-0.193,0.127-0.39,0.25-0.72,0.239c-0.68,1.376-3.888,2.045-5.52,0.96c-0.473-0.646-1.092-1.147-1.92-1.439
			c-0.588-0.212-0.881-0.72-1.92-0.48c-0.755-1.565-0.561-4.388,0-6c-0.039-0.278,0.053-0.427,0.24-0.479
			c0.736-0.545,1.045-1.516,1.68-2.16c0.544-0.816,1.462-1.258,2.64-1.44c1.19-0.096,3.232-0.6,4.32,0
			c0.889-0.009,1.391,0.369,1.92,0.721c0.863,0.417,1.195,1.364,1.92,1.92C271.996,729.605,272.743,729.899,272.48,731.201z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M292.881,232.48c-0.864,0.336-2.152,0.247-2.881,0.72c-0.56,0-1.119,0-1.68,0
			c-1.039,0.081-2.514-0.274-2.88,0.48c-0.561,0.079-0.991,0.289-1.44,0.48c-0.885,0.155-1.833,0.247-2.4,0.72
			c-1.221-0.292,0.989-0.793,0.96-1.44c0.507-0.613,1.179-1.062,1.92-1.44c0.08,0,0.16,0,0.24,0c0.816-0.624,1.813-1.066,2.4-1.92
			c0.194-0.045,0.239-0.241,0.48-0.24c0.082-0.878,0.821-1.098,1.44-1.44c1.202-0.352,1.134-2.52-0.24-2.64
			c-3.335,0.185-5.144,1.896-7.68,2.88c-0.696,0.184-1.38,0.38-1.68,0.96c-1.414,0.506-2.694,1.146-3.84,1.92
			c-0.613,0.107-1.104,0.337-1.44,0.72c-1.479,0.361-2.709,0.971-3.84,1.68c-1.367,0.873-3.188,1.292-4.56,2.16
			c-0.961-0.001-0.795,1.125-1.44,1.44c-0.792,2.647-1.097,5.783-2.16,8.16c-0.164,1.122-1.253,2.58-0.48,3.84
			c2.778,1.456,1.618-2.991,4.56-2.16c5.333-4.268,11.837-7.363,20.4-8.4c0.966-0.154,2.552,0.312,3.12-0.24c0.479,0,0.96,0,1.44,0
			c0.038,0.281,0.438,0.203,0.719,0.24c1.36,0,2.721,0,4.08,0c1.307-1.094,1.227-3.574,3.84-3.36
			c0.313-0.319,0.316-0.893-0.239-0.96c0-0.08,0-0.16,0-0.24c-0.687-0.757,0.913-0.683,0.96-1.44c1.34-2.823-2.955-2.817-4.08-1.44
			c-0.42,0.22-1.197,0.083-1.439,0.48C294.033,231.874,293.699,232.419,292.881,232.48z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M288.08,255.28c-0.04,0.28-0.379,0.261-0.72,0.24
			c-1.177,0.503-2.453,0.907-4.32,0.72c-1.92,0-3.84,0-5.76,0c-1.67,0.071-2.484-0.715-4.08-0.72c-0.099-0.302-0.31-0.491-0.72-0.48
			c-0.03-0.53-1.018-0.102-1.44-0.24c-0.502,0.378-0.422,1.338-0.96,1.68c-0.03-0.53-1.018-0.102-1.44-0.24
			c-0.343,0.236-0.864,1.511-0.24,1.92c0.717,0.644,1.652,1.068,2.4,1.68c0.355,0.605,0.928,0.992,1.68,1.2
			c1.785,0.935,2.691,2.749,5.52,2.64c-0.04-0.68-0.44-1-0.48-1.68c-0.615-0.825-1.29-1.59-2.4-1.92
			c-0.319-0.241-0.893-0.227-0.96-0.72c0-0.08,0-0.16,0-0.24c-0.213-0.493,1.636,0.356,3.36,0.48
			c4.724,0.34,12.503-1.191,12.24-3.36C289.691,255.668,288.955,254.691,288.08,255.28z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M475.521,453.521c-2.75,0.37-5.065,1.175-7.92,1.44
			c-3.568,0.511-7.269,0.892-10.56,1.68c-2.806,0.475-5.49,1.069-8.4,1.44c-2.643,0.397-5.14,0.939-7.2,1.92
			c-1.308,0.532-2.511,1.169-4.08,1.439c-1.392,0.528-2.919,0.921-3.84,1.92c-0.08,0-0.159,0-0.239,0
			c-2.164,1.276-4.497,2.384-6.24,4.08c-0.566,0.074-0.446,0.834-1.2,0.721c-1.261,1.139-2.874,1.926-3.84,3.359c0,0.4,0,0.8,0,1.2
			c0.194,0.046,0.239,0.241,0.479,0.24c3.063-0.858,5.927-3.993,8.88-5.76c0.891-0.471,1.543-1.178,2.641-1.44
			c1.379-0.781,2.97-1.351,4.319-2.16c0.08,0,0.16,0,0.24,0c1.149-0.37,2.414-0.626,3.36-1.2c0.214-0.187,0.542-0.258,0.96-0.239
			c2.314-0.646,4.844-1.077,7.2-1.681c6.169-1.11,13.664-0.896,20.16-1.68c0.943-0.017,1.804-0.116,2.399-0.48c0.08,0,0.16,0,0.24,0
			c2.117-0.282,4.282-0.518,6-1.199c0.08,0,0.16,0,0.24,0c1.645-0.115,2.725-0.795,2.88-2.4c1.439-0.56,0.626,1.134,1.2,1.44
			c0.097-1.058,0.063-1.984-1.2-1.681c0.067-0.547-0.058-0.902-0.48-0.96c-0.284-1.511-3.317-0.997-4.319-0.479
			c-0.833-0.192-0.923,0.357-1.681,0.239C475.521,453.36,475.521,453.44,475.521,453.521z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M264.081,575.681c-0.555,0.485-1.283,0.798-1.44,1.681
			c-0.774,1.893-0.593,4.646,0.48,6c0.363,0.597,0.823,1.097,1.68,1.199c0.515,0.615,2.207,1.03,3.12,0.48c0-0.32,0-0.64,0-0.96
			c-0.278-0.201-0.644-0.316-1.2-0.24c-1.302-0.059-2.189-0.531-2.64-1.439c-0.723-1.495-0.707-4.35,0.48-5.28
			c0.447-0.033,0.802-0.158,0.96-0.48c2.336-0.256,3.807,0.354,4.56,1.681c0.145,0.574,0.345,1.095,0.24,1.92
			c-0.309,1.131-1.278,1.602-2.4,1.92c-0.611,0.029-0.931,0.349-0.96,0.96c0.363,0.528,1.588,0.707,1.92,0
			c0.777-0.503,2.028-0.532,2.16-1.68c0.862-0.187,0.972-2.378,0.48-3.12c-0.322-0.158-0.447-0.514-0.48-0.96
			c-0.184-0.776-0.664-1.256-1.44-1.44c-0.187-0.054-0.474-0.007-0.48-0.24C267.905,574.621,265.393,574.967,264.081,575.681z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M341.841,580.001c1.918,1.042,4.087,1.833,6.24,2.64
			c1.551,0.289,2.66,1.021,4.079,1.44c1.738,0.592,7.758,2.945,7.921-0.72c-1.172-1.389-4.23-0.891-6-1.681
			c-2.073-0.327-3.622-1.178-5.28-1.92c-0.882-0.718-2.14-1.06-3.36-1.439c-3.518-0.719-7.846-2.93-11.76-1.681
			C335.137,579.025,339.425,578.576,341.841,580.001z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M330.561,599.921c0.546,1.773,1.574,3.066,2.88,4.08c0.08,0,0.16,0,0.24,0
			c1.661,2.404,7.712,1.56,9.36-0.24c0.08,0,0.159,0,0.239,0c1.3-0.7,2.207-1.793,2.641-3.359c-0.077-0.557,0.038-0.922,0.239-1.2
			c0.397-0.259,0.397-2.142,0-2.4c0-0.08,0-0.16,0-0.24c-0.318-5.756-6.643-9.271-12.479-6.479c-1.64,0.681-3.006,1.634-3.84,3.12
			c-1.333,1.373-0.661,4.736,0.479,5.76C330.396,599.285,330.662,599.419,330.561,599.921z M332.48,593.921
			c0.363-0.597,0.823-1.097,1.68-1.2c0.947-0.173,1.858-0.381,2.641-0.72c1.325-0.125,2.263,0.138,3.359,0.24
			c1.455,0.465,2.415,1.425,2.881,2.88c0.518,1.494,0.736,3.842,0,5.28c0,0.159,0,0.319,0,0.479c-0.869,0.411-1.492,1.068-2.641,1.2
			c-1.131-0.012-1.818,0.422-2.88,0.479c0.139-1.178-0.312-1.769-1.2-1.92c-1.519-0.32-2.28-1.399-3.12-2.399
			C332.413,597.16,331.458,595.454,332.48,593.921z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M237.92,734.081c-2.203-0.917-4.853-1.387-7.68-1.68
			c-0.815-0.065-1.946,0.186-2.4-0.24c-0.885-0.555-2.796-0.084-4.08-0.24c-2.08,0-4.16,0-6.24,0
			c-1.363-0.557-3.758-0.082-5.52-0.239c-2.708-0.012-5.022-0.418-7.2-0.96c-2.532-0.748-5.387-1.173-8.16-1.681
			c-2.725-0.234-4.818-1.414-7.44-0.479c-0.92,0.596,1.15,2.154,2.16,2.399c1.017-0.057,1.699,0.221,2.4,0.48
			c2.141,0.819,4.7,1.22,7.2,1.68c3.496,0.584,6.857,1.304,10.56,1.68c2.721,0.56,6.479,0.081,9.6,0.24c0.16,0,0.32,0,0.48,0
			c4.577,0.304,9.262,0.498,13.2,1.44c2.356,0.02,5.111,2.116,6,0C241.388,735.082,239.597,734.624,237.92,734.081z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M232.641,279.76c0.136,0.984,0.906,1.334,1.68,1.68
			c2.414,2.146,3.498,5.622,6,7.68c0.988,0.933,1.185,2.655,2.16,3.6c0.21,0.83,0.417,1.663,0.96,2.16
			c0.625,1.455,0.894,3.267,1.68,4.56c0.084,0.315,0.422,0.377,0.48,0.72c0.891-0.149,2.47,0.391,2.64-0.48
			c1.671-0.569,3.378-1.102,4.8-1.92c0.949-0.011,1.392-0.528,2.16-0.72c0.863-0.338,1.712-0.688,1.92-1.68
			c1.251-0.588,1.805-1.875,2.16-3.36c0.454-1.307,1.354-2.166,2.16-3.12c0.08,0,0.16,0,0.24,0c0.98-0.78,1.86-1.66,2.64-2.64
			c1.579-1.302,1.312-4.449,2.4-6.24c0.377-0.983,1.241-1.479,1.44-2.64c1.351-1.129,1.842-3.118,2.16-5.28
			c0.4-2.163,0.4-5.998,0-8.16c0.051-1.927-3.35-2.986-2.64,0.24c0,0.64,0,1.28,0,1.92c-0.5,1.181-0.607,2.753-1.2,3.84
			c-0.045,0.194-0.241,0.239-0.24,0.48c-0.922,1.397-2.55,2.089-4.08,2.88c-0.602,0.519-1.836,0.404-2.16,1.2
			c-2.839,1.721-5.181,3.939-6.96,6.72c-0.635,0.645-0.944,1.616-1.68,2.16c-0.688,0.512-0.573,1.827-1.44,2.16
			c-0.431-0.031-0.506,0.293-0.96,0.24c-0.54-0.289-2.731-0.179-3.12,0.48c-0.16,0-0.32,0-0.48,0
			c-0.492-1.268-1.423-2.096-2.16-3.12c0-0.16,0-0.32,0-0.48c-1.227-1.252-2.139-2.821-4.08-3.36
			C238.064,277.474,231.653,275.449,232.641,279.76z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M372.561,419.44c0.678,0.362,1.656,0.424,2.641,0.479c0.079,0,0.159,0,0.239,0
			c0.259,0.398,2.142,0.398,2.4,0c0.699-0.26,1.419-0.5,1.68-1.199c0.706-0.957-0.62-1.932-0.96-2.641
			c-0.683-1.316-1.211-2.789-1.68-4.319c-0.408-1.496-2.846-1.031-3.12,0.24C373.195,414.438,368.953,417.825,372.561,419.44z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M281.36,446.561c1.343,1.856,3.288,3.112,5.28,4.32
			c1.248-1.679-1.224-3.246-2.4-4.08c-1.423-1.008-3.218-2.135-4.8-2.4C279.147,446.054,280.657,445.904,281.36,446.561z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M295.761,462.641c0.859,1.061,1.819,2.021,2.88,2.88
			c0.465,0.976,1.137,1.744,1.68,2.641c0.117,0.283,0.256,0.544,0.48,0.72c0.412,0.868,1.041,1.519,1.68,2.16
			c0.014,0.146,0.036,0.284,0.24,0.239c0.074,0.326,0.601,0.199,0.96,0.24c1.146-0.636,0.053-2.408-0.72-2.64
			c0.04-0.601-0.369-0.751-0.721-0.96c-0.852-1.068-1.629-2.211-2.4-3.36c-0.708-1.371-1.826-2.334-3.119-3.12
			c-0.736-0.316-1.264-1.103-1.92-0.239C294.559,462.243,295.33,462.271,295.761,462.641z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M266.96,507.761c-1.087-0.433-2.531-0.509-4.08-0.479
			c-3.961-0.119-5.395-2.766-8.4-3.841c0.143,0.498-0.297,1.577,0.24,1.681c0.185,0.775,0.665,1.256,1.44,1.439
			c1.425,1.215,3.158,2.122,5.52,2.4c0.892,0.548,2.387,0.493,3.84,0.479c1.928-0.247,2.341,1.02,4.32,0.721
			C269.917,508.323,268.488,507.993,266.96,507.761z"/>
				<polygon fillRule="evenodd" clipRule="evenodd" points="224.328,251.31 224.326,251.309 224.329,251.31 		" />
				<polygon fillRule="evenodd" clipRule="evenodd" points="111.224,345.744 111.225,345.743 111.224,345.744 		" />
				<path fillRule="evenodd" clipRule="evenodd" d="M263.36,690.881c0.294,0.906,1.69,0.71,2.88,0.721
			c0.382-0.658-0.274-1.18,0.72-1.44c0.566-0.474,1.249-0.831,1.92-1.2c0.809-0.335,1.14-1.519,0.72-2.399
			c-0.058-0.343-0.396-0.405-0.48-0.721c-0.631,0.071-0.189-0.931-0.96-0.72c-1.344-1.466-5.054-0.664-5.28,1.2
			C262.068,687.336,262.233,690.335,263.36,690.881z M267.44,688.721c-0.876,0.244-1.645,0.596-2.4,0.961c0,0.159,0,0.319,0,0.479
			c-1.788,0.163-1.547-2.07-1.2-3.36C264.664,683.707,270.933,686.906,267.44,688.721z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M254.72,172.48c0.124,1.396-0.27,3.31,0.24,4.32
			c0.876,0.156,1.278-0.162,1.68-0.48c0.08,0,0.16,0,0.24,0c0-0.96,0-1.92,0-2.88c0.548-5.532,2.99-9.17,5.28-12.96
			c1.08,0.055,0.712-2.397,0-0.96c-0.261,0.06-0.163,0.477-0.48,0.48c-1.296,0.624-2.167,1.673-2.88,2.88
			c-0.743,1.177-1.208,2.631-2.16,3.6c-0.08,0-0.16,0-0.24,0c-0.44,1-0.792,2.087-1.2,3.12
			C255.321,170.841,254.437,171.077,254.72,172.48z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M295.521,241.36c-0.878-1.048-2.712,0.058-2.88,1.2
			c-0.194,0.046-0.239,0.241-0.48,0.24c-0.336,1.023-1.003,1.717-1.68,2.4c-0.89,1.031-1.85,1.991-2.88,2.88
			c-0.493,0.868-1.628,1.092-2.4,1.68c-0.048,0.752-1.57,0.03-1.44,0.96c-0.746,0.684,0.106,1.799,0.48,2.16c0.32,0,0.64,0,0.96,0
			c2.199-0.36,3.797-1.322,5.28-2.4c1.51-0.81,2.574-2.066,3.359-3.6c0.242-0.478,0.416-1.025,0.961-1.2
			c0.703-0.416,1.114-1.125,1.199-2.16c0.08,0,0.16,0,0.24,0c0-0.08,0-0.16,0-0.24C296.213,242.428,296.414,241.347,295.521,241.36z
			"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M207.92,262.24c1.897,2.871,10.099,3.996,12.24,0.48
			c0.632-0.417,0.086-1.667-0.48-1.68c0-0.08,0-0.16,0-0.24c-0.33,0.01-0.527-0.113-0.72-0.24c-1.044-0.556-3.117-0.083-4.56-0.24
			c-0.988,0.188-1.231-0.368-2.16-0.24c-1.401-0.599-2.746-1.253-4.08-1.92c-1.296-0.543-0.756-2.923-2.16-3.36
			c-0.915,0.045-1.579,0.341-1.68,1.2c-0.37,2.689,1.431,3.209,2.16,4.8C206.665,261.576,207.145,262.056,207.92,262.24z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M385.28,407.681c-0.156-1.284,0.315-3.195-0.239-4.08
			c-0.248-2.152-0.519-4.282-0.721-6.48c-0.072-1.047-0.249-1.991-0.72-2.64c0-0.08,0-0.16,0-0.24
			c-0.531-2.588-1.369-4.871-5.04-4.32c-0.08,0-0.16,0-0.24,0c-0.805-0.554-2.636-0.084-3.84-0.24
			c-0.691-0.029-1.571,0.131-1.92-0.24c-0.806-0.554-2.636-0.084-3.84-0.24c-1.619,0.179-2.494-0.386-4.08-0.24
			c-4.026,0.192-1.146,5.136-0.72,7.2c0.344,1.176,0.522,2.518,0.479,4.08c0.156,1.204-0.314,3.035,0.24,3.84c0,0.48,0,0.96,0,1.44
			c0.157,1.523-0.316,3.677,0.24,4.8c0,0.24,0,0.48,0,0.72c0.155,1.204-0.314,3.035,0.24,3.84c0,0.08,0,0.16,0,0.24
			c0.158,2.32-0.318,5.277,0.239,7.199c0.298,2.662,0.257,5.664,0.24,8.641c-0.259,1.664-0.528,4.588-0.479,5.76
			c0,0.08,0,0.16,0,0.24c0.445,1.154,2.242,0.957,3.84,0.96c5.209,0.149,9.751,0.969,14.16,1.92c0.46,0.444,2.521,0.452,2.399-0.479
			c0-0.641,0-1.281,0-1.921c0.895-9.301,0.438-20.104-0.24-29.28C385.28,408,385.28,407.841,385.28,407.681z M380.48,397.841
			c1.416-0.457,0.988,0.932,1.44,1.44c-0.188,0.592,0.871,1.661-0.24,1.68c0.04-0.521-0.852-0.108-1.2-0.24c0-0.08,0-0.16,0-0.24
			C380.62,399.628,378.69,398.074,380.48,397.841z M365.601,393.761c0.059-1.019-0.628-1.292-0.479-2.4
			c3.095,0.265,6.355,0.364,9.359,0.72c0.727,0.554,2.476,0.084,3.601,0.24c0.159,0,0.319,0,0.479,0
			c0.03,0.53,1.019,0.102,1.44,0.24c-0.046,0.194-0.241,0.239-0.24,0.48c0.752,0.128,0.864,0.896,0.96,1.68c-0.32,0-0.64,0-0.96,0
			c0,0.08,0,0.16,0,0.24c-0.874,0.074-1.562-0.038-2.16-0.24c-0.411-0.549-1.832-0.088-2.64-0.24c-0.16,0-0.32,0-0.48,0
			c-0.726-0.554-2.476-0.084-3.6-0.24c-0.16,0-0.32,0-0.48,0c-0.332-0.547-1.67-0.089-2.399-0.24
			C366.979,394.143,366.413,393.828,365.601,393.761z M367.761,415.841c-0.201-3.879-0.773-7.387-0.72-11.52
			c-0.363-1.644,0.276-2.283,1.92-1.92c0.751,0.049,1.762-0.162,2.16,0.24c0.41,0.549,1.832,0.088,2.64,0.24c0.16,0,0.32,0,0.479,0
			c0.489,0.551,1.993,0.086,2.881,0.24c0.08,0,0.159,0,0.239,0c0.489,0.551,1.993,0.087,2.88,0.24c1.17,0.209,1.593-0.328,2.4-0.479
			c0.021,5.018,0.539,9.541,0.48,14.64c0,0.8,0,1.601,0,2.399c0,1.601,0,3.201,0,4.801c0.008,0.033,0.004,0.057,0.001,0.081
			c0.002-0.003,0.004-0.006,0.006-0.009c-0.002,0.004-0.004,0.01-0.006,0.014c0-0.002,0-0.004,0-0.005
			c-0.067,0.119-0.146,0.183-0.241,0.159c-2.304-0.018-4.112-0.528-6.24-0.721c-0.411-0.549-1.832-0.088-2.64-0.24
			c-2.209,0.049-3.978-0.342-5.521-0.959C367.267,421.615,368.592,417.649,367.761,415.841z M382.161,427.361c0.319,0,0.64,0,0.96,0
			c0,1.119,0,2.239,0,3.359c-0.734,0.174-0.706-0.414-1.44-0.24C381.939,429.54,381.125,427.525,382.161,427.361z M383.36,434.08
			c-0.08,0-0.159,0-0.239,0c0.181,1.381-0.114,2.287-0.96,2.641c-0.08,0-0.16,0-0.24,0c-0.48,0-0.96,0-1.44,0
			c-0.363-0.837-1.389-1.011-2.64-0.96c-3.027-0.679-7.757-1.058-9.84-2.16c0.396-0.259,0.396-2.142,0-2.399c0-0.08,0-0.16,0-0.24
			c0.397-0.402,1.409-0.191,2.16-0.24c3.494,0.5,11.198,1.25,13.199,2.88C383.439,433.664,383.301,433.994,383.36,434.08z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M360.081,418.48c-0.229-5.852-1.405-10.754-2.4-15.84
			c-0.43-2.61-1.056-5.024-1.2-7.92c-0.367-2.353-0.803-4.638-2.88-5.28c-0.8,0-1.6,0-2.4,0c-3.12,0.159-6.879-0.319-9.6,0.24
			c-0.16,0-0.32,0-0.479,0c-3.371,0.069-6.538,0.342-7.44,2.88c-0.088,3.288,0.666,5.734,0.72,8.88
			c1.11,11.45,0.767,24.354,1.2,36.48c1.056,1.015,1.855-0.308,3.12,0.239c0.33-0.01,0.527,0.113,0.72,0.24
			c0.885,0.555,2.797,0.084,4.08,0.24c0.96,0,1.92,0,2.88,0c1.522,0.558,4.078,0.082,6,0.24c2.084,0.084,3.942-0.059,5.76-0.24
			c3.677-0.063,3.998-3.776,3.12-7.2C360.727,427.274,360.408,422.873,360.081,418.48z M339.2,404.362
			c-0.007,0.01-0.016,0.018-0.023,0.028c-0.044,0.06-0.09,0.118-0.142,0.17c-0.664,0-1.329,0-1.994,0c0.302-3.333-2.579-10.521,0-12
			c1.229,2.61,1.66,6.02,2.159,9.36C339.2,402.734,339.2,403.548,339.2,404.362z M351.681,391.841c2.143,0.417,2.121,2.998,2.4,5.28
			c0.138,0.422-0.29,1.41,0.239,1.44c-0.042,2.205,1.223,4.374,0.721,6.24c-0.426-0.055-1.202,0.242-0.96-0.48
			c-0.154-0.966,0.312-2.552-0.24-3.12c-0.033-1.567-0.54-2.661-0.72-4.08C352.25,395.88,351.554,393.29,351.681,391.841z
			 M350.961,396.161c0.555,2.05,1.029,3.933,1.199,6.48c-0.104,0.825,0.096,1.345,0.24,1.92c-0.56,0-1.12,0-1.68,0
			c0.23-4.951-1.578-9.141-1.92-12.72C350.272,392.332,350.523,394.542,350.961,396.161z M343.521,404.321
			c-0.349,0.371-1.229,0.211-1.92,0.24c-0.143-0.497,0.297-1.577-0.24-1.68c-0.456-3.383-1.048-6.632-1.68-9.84
			c-0.321-1.281,1.406-0.514,2.16-0.72c0.722,1.758,1.454,3.505,1.439,6c0.158,1.842-0.317,4.317,0.24,5.76
			C343.521,404.161,343.521,404.241,343.521,404.321z M348.639,404.801c-0.666,0-1.332,0-1.998,0c-0.24,0-0.48,0-0.72,0
			c-1.185-0.976,0.089-4.41-0.721-5.76c-0.267-2.053-0.817-3.822-0.96-6c0-0.08,0-0.16,0-0.24c0-0.24,0-0.48,0-0.72
			c0.801,0,1.601,0,2.4,0c-0.036,0.596,0.124,0.996,0.48,1.2c0.664,2.935,1.597,5.603,1.68,9.12c0,0.742,0,1.484,0,2.226
			c0.168-0.213,0.288-0.473,0.344-0.753c-0.055,0.281-0.176,0.54-0.344,0.753c-0.048,0.061-0.1,0.115-0.155,0.167
			C348.644,404.795,348.641,404.798,348.639,404.801z M357.681,435.521c0.08,0,0.16,0,0.24,0c-0.395,0.308-1.303,0.622-1.681,0.24
			c-0.96,0-1.92,0-2.88,0c-0.319,0-0.64,0-0.96,0c-0.41-0.55-1.832-0.088-2.64-0.24c0-0.72,0-1.44,0-2.159
			c0.399-3.842,0.399-9.359,0-13.201c-0.117-4.523-0.796-8.484-0.96-12.959c0.24,0,0.479,0,0.72,0c1.28,0,2.561,0,3.84,0
			c0.984,0.024,1.968,0.047,2.641-0.24c0.289,0.831,0.522,1.717,0.479,2.88c0,0.16,0,0.32,0,0.48c0.335,2.465,0.541,5.059,0.96,7.44
			c0.148,0.651-0.305,1.905,0.24,2.159c0.152,0.809-0.31,2.23,0.24,2.641c0,0.48,0,0.96,0,1.439c0.099,1.422,0.106,2.934,0.479,4.08
			c0.158,1.683-0.316,3.998,0.24,5.281C358.839,434.6,358.526,435.326,357.681,435.521z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M239.84,482.081c-1.809,0.245-0.765,3.078,0,3.6
			c0.452,1.148,1.373,1.827,2.4,2.4c1.625,0.491,5.944,3.642,6.72,0.479C244.934,487.388,241.315,485.806,239.84,482.081z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M258.561,693.281c0.797,1.282,1.737,2.423,3.36,2.88
			c0.97,0.23,1.911,0.489,2.88,0.72c0.336,0.398,2.304,0.398,2.64,0c1.22-1.137,2.845-0.621,4.56-1.439
			c1.455-0.785,2.516-1.965,2.88-3.84c0.498-2.225,0.017-4.352-0.48-6.24c-0.865-2.335-2.346-4.055-4.32-5.28
			c-0.331,0.011-0.527-0.113-0.72-0.24c-0.515-0.525-1.322-0.758-2.4-0.72c-1.857,0.063-3.191,0.649-4.56,1.2
			c-1.737,0.983-3.188,2.253-4.08,4.08c0.011,0.33-0.113,0.526-0.24,0.72c-0.879,0.885-0.96,3.835-0.48,5.28
			C257.898,691.383,258.225,692.337,258.561,693.281z M260.96,686.562c0.413-4.03,8.186-5.898,9.6-1.44
			c0.78,1.689,1.795,4.357,0.72,6.48c-0.029,0.61-0.349,0.931-0.96,0.96c-0.804,0.076-1.513,0.247-1.92,0.72
			c-0.357,0.282-0.677,0.603-0.96,0.96c-1.262-0.019-2.257-0.303-3.12-0.72c-1.129-0.471-2.06-1.141-2.88-1.92
			c-0.322-0.158-0.447-0.514-0.48-0.96C260.779,689.187,260.617,687.967,260.96,686.562z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M381.681,404.321c0,1.92,0,3.84,0,5.76
			C382.08,408.716,382.08,405.685,381.681,404.321z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M517.521,594.161c0.156,1.124-0.313,2.874,0.24,3.6
			C517.605,596.637,518.075,594.887,517.521,594.161z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M517.521,602.801c0-0.8,0.001-1.6,0-2.399
			C517.124,600.66,517.124,602.542,517.521,602.801z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M399.681,663.041c-0.549,0.411-0.088,1.832-0.24,2.641
			C399.99,665.271,399.528,663.85,399.681,663.041z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M456.081,538.721c0.699,0.9,1.499,1.701,2.399,2.4
			C457.781,540.221,456.981,539.42,456.081,538.721z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M484.641,564.401c-0.044-0.204,0.094-0.227,0.24-0.24
			c-0.319-0.48-1.065-0.535-1.68-0.72C483.586,563.856,484.098,564.144,484.641,564.401z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M355.28,416.32c0.157,1.443-0.315,3.517,0.24,4.561
			C355.363,419.438,355.837,417.365,355.28,416.32z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M381.681,436c0-0.879,0-1.76,0-2.639
			C381.283,433.697,381.283,435.664,381.681,436z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M356.48,431.201c0.155,1.124-0.313,2.873,0.24,3.6
			C356.565,433.676,357.034,431.927,356.48,431.201z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M365.601,369.521c-0.976-0.384-2.583-0.137-3.6-0.48
			c-0.628,0.068-1.032-0.087-0.96-0.72c-0.501-0.859-0.241-2.479-0.96-3.12c-0.325-0.076-0.459-0.342-0.96-0.24
			c-0.987,0.293-0.37,2.19-1.2,2.64c-0.741,0.539-1.381,1.179-1.92,1.92c-0.054,0.187-0.201,0.279-0.48,0.24
			c-1.658,0.034-2.466,1.752-1.439,3.12c0.598,0.202,1.285,0.314,2.159,0.24c1.132,0.068,1.769,0.632,2.16,1.44
			c0.249,0.871,0.75,1.49,1.44,1.92c0.339,0.363,1.165,0.758,1.68,0.24c0.54-0.741,0.458-2.102,0.72-3.12
			c0.054-0.187,0.007-0.474,0.24-0.48c0.518-0.603,1.483-0.757,1.92-1.44C365.555,371.636,366.196,370.619,365.601,369.521z
			 M361.28,371.681c-0.735,0.225-0.589,1.33-1.199,1.68c-0.241,0.001-0.286-0.194-0.48-0.24c-0.856-0.664-1.872-1.168-2.88-1.68
			c0.07-0.489,0.649-0.471,0.72-0.96c0.928-0.032,0.947-0.973,1.681-1.2c0.557,0.162,0.642,0.797,1.199,0.96
			c0.76-0.04,0.803,0.638,1.681,0.48C362.041,371.321,361.632,371.472,361.28,371.681z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M337.28,600.401c0.165,0.539,1.831,0.802,1.92,0
			c1.348-0.173,2.403-0.638,3.12-1.44c0.712-0.379,0.712-2.741,0-3.12c-0.219-0.98-0.735-1.664-1.68-1.92
			c-0.57-0.916-2.453-1.202-3.36-0.479c-0.967,0.232-1.352,1.048-1.92,1.68C334.264,597.21,335.524,599.727,337.28,600.401z
			 M339.921,595.361c0.749,0.299,1.691,1.656,0.96,2.64c-1.7,0.862-2.054-1.165-3.36-1.44c-0.003-0.316-0.421-0.219-0.479-0.479
			C337.529,594.96,338.801,594.65,339.921,595.361z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M268.4,729.521c-0.543-0.257-1.055-0.545-1.44-0.96
			c-0.857-0.606-2.646-0.094-3.12,0.479c-0.744,0.296-1.552,0.528-1.68,1.44c-0.08,0-0.16,0-0.24,0
			c-1.358,1.845-0.337,4.561,1.92,4.8c0.115,0.464,1.596,0.778,1.92,0.24c1.195-0.006,1.661-0.739,2.4-1.2
			c0.084-0.315,0.422-0.378,0.48-0.72c0.191-0.449,0.401-0.879,0.48-1.44C269.781,731.328,269.029,729.862,268.4,729.521z
			 M266,734.081c-2.41,0.472-4.028-0.795-3.12-2.88c0-0.16,0-0.32,0-0.479c0.624-0.097,0.724-0.717,1.44-0.721
			c0.842-0.118,1.563-0.357,2.64-0.239c0.217,0.423,0.537,0.742,0.96,0.96c0.185,0.904-0.293,1.146-0.24,1.92
			C267.525,733.526,266.859,733.9,266,734.081z"/>
			</g>
			<path fillRule="evenodd" clipRule="evenodd" fill="#C5AE9D" d="M313.521,259.12c-0.646-1.033-1.128-2.231-0.96-4.08
		c-0.53-1.63-0.553-3.768-0.721-5.76c-0.391-1.449-0.681-2.999-0.719-4.8c0-0.08,0-0.16,0-0.24c0.121-2.122-0.674-3.327-0.721-5.28
		c-0.425-0.695-0.402-1.838-0.72-2.64c-0.767-1.073-0.44-3.239-1.2-4.32c0,0.08,0,0.16,0,0.24c-0.473-0.407-0.644-1.116-0.72-1.92
		c-0.326-0.794-0.782-1.458-0.96-2.4c-0.536-1.704-2.288-3.36-2.16-4.32c-0.58-0.136-0.707-0.906-1.2-1.68
		c-0.655-1.345-1.394-2.606-2.159-3.84c-0.523-0.757-1.076-1.484-1.201-2.64c-0.356-0.204-0.347-0.773-0.479-1.2
		c-0.804-0.637-0.861-2.019-1.2-3.12c0.007-1.367-0.609-2.11-0.48-3.6c-0.401-0.398-0.19-1.409-0.239-2.16
		c-0.865-2.415,0.134-6.695-0.96-8.88c-0.584,0.646-1.916,0.01-2.641,0c0.011-0.33-0.113-0.527-0.24-0.72
		c-0.805-3.034-0.348-7.332-0.719-10.8c0.424-3.066-1.431-5.127-0.721-7.68c-0.455-0.048-0.81-1.4-0.24-1.68
		c-0.006-0.233-0.293-0.186-0.479-0.24c-0.077-0.557,0.038-0.922,0.239-1.2c-0.155-0.244-0.551-0.248-0.479-0.72
		c0.08,0,0.16,0,0.24,0c-0.082-1.864-1.657-5.462-2.4-8.16c-3.325,3.075-8.47,4.33-12.24,6.96c-0.9,0.78-1.875,1.485-3.12,1.92
		c-1.328,1.392-3.317,2.123-4.56,3.6c-0.134,0.346-0.374,0.585-0.72,0.72c-2.46,2.66-5.02,5.22-7.68,7.68
		c-0.662,0.618-1.102,1.458-1.92,1.92c-1.73,1.71-3.288,3.592-5.04,5.28c-1.144,1.416-2.599,2.521-4.08,3.6
		c-0.36,0.28-0.587,0.693-1.2,0.72c-1.032,0.567-1.119,2.081-1.92,2.88c-0.107,0.613-0.336,1.104-0.72,1.44
		c-0.061,0.899-0.377,1.543-0.96,1.92c-0.432,0.38-2.026,0.838-2.64,0.24c-1.391-0.369-2.063-1.457-2.88-2.4
		c-0.968-0.392-1.831-0.889-2.4-1.68c-0.431,0.369-0.193,1.407-0.96,1.44c-0.282,0.842-2.859,0.685-2.88-0.24
		c-0.548-0.412-1.212-0.708-1.68-1.2c-0.926-0.434-1.675-1.044-2.4-1.68c-1.285-0.396-2.143-1.218-2.88-2.16
		c-0.58-0.3-0.55-1.21-1.44-1.2c-1.137-0.543-1.994-1.366-2.88-2.16c-1.969-1.392-4.193-2.527-6-4.08
		c-3.039-2.241-5.819-4.742-7.92-7.92c-1.559-1.961-3.301-3.739-4.56-6c-0.493-0.067-0.479-0.641-0.72-0.96
		c-1.691-0.171-2.265,0.775-2.64,1.92c-1.774,3.105-3.285,6.474-5.04,9.6c-0.373,1.203-0.069,1.635-0.96,2.16
		c-0.431,1.409-0.753,2.926-1.44,4.08c-0.042,1.288-1.076,2.313-0.24,3.12c0.135,4.616-0.095,10.944,0,16.32c0,0.08,0,0.16,0,0.24
		c0.552,0.568,0.086,2.154,0.24,3.12c0,0.08,0,0.16,0,0.24c0.371,1.629,0.413,3.587,0.48,5.52c0.149,0.651-0.305,1.905,0.24,2.16
		c0.982,2.312,0.163,4.481,0.72,6.48c0.198,0.602-0.466,2.066,0.48,1.92c0.637,1.533-0.035,4.794,0.24,6c0,0.4,0,0.8,0,1.2
		c0.01-0.006,0.029-0.015,0.04-0.021v4.461h12.061l12.251,0.122l3.404,0.329c1.07,0.366,1.327,1.847,0.804,3.029
		c-2.916,1.004-6.063,1.777-9.84,1.92c-0.333,0.627-2.037-0.117-2.4,0.48c-0.729,0.15-2.067-0.307-2.4,0.24
		c1.013,0.107,1.21,1.03,2.64,0.72c1.192-0.408,2.699-0.501,4.32-0.48c0.08,0,0.16,0,0.24,0c0.677-0.362,1.656-0.423,2.64-0.48
		c2.089,0.084,4.38-1.065,6,0c1.474-0.033,1.673,1.208,2.88,1.44c1.066,0.213,1.214,1.346,1.92,1.92
		c-0.001,0.241,0.194,0.286,0.24,0.48c1.016,0.664,1.57,1.79,2.4,2.64c0.668-0.453-0.612-0.969-0.24-1.92
		c-1.644-4.159-6.746-7.864-5.52-13.92c0.798,0.242,2.611-0.531,2.64,0.48c1.323,0.357,2.162,1.198,2.88,2.16
		c0.704,0.017,0.892,0.549,1.2,0.96c1.456,0.704,2.303,2.017,3.36,3.12c1.241,0.679,2.229,1.611,3.12,2.64
		c0.44,0.28,0.813,0.627,1.2,0.96c0.837,0.203,1.211,0.869,1.44,1.68c0.241,2.444,0.647,6.582-0.24,8.64
		c0.168,1.048-0.408,1.352-0.24,2.4c-0.6,1.16-0.689,2.831-1.44,3.84c0.077,0.557-0.039,0.921-0.24,1.2
		c0.247,0.735-0.07,2.964-0.72,3.6c0,0.32,0,0.64,0,0.96c-0.309,0.979-2.841,2.729-3.6,0.96c-0.422-2.298-1.279-4.161-1.44-6.72
		c-0.602-2.838-1.042-5.837-1.2-9.12c0-0.4,0-0.8,0-1.2c-1.087-0.367,0.127,1.567-0.96,1.2c0.724,0.898-1.641,0.313-2.4,0.48
		c0.068-0.627-0.087-1.032-0.72-0.96c-1.634,2.855-6.311,3.918-9.36,4.56c0,0.08,0,0.16,0,0.24c0.566,0.013,1.112,1.263,0.48,1.68
		c-2.141,3.516-10.342,2.391-12.24-0.48c-0.776-0.184-1.256-0.664-1.44-1.44c-0.729-1.591-2.53-2.11-2.16-4.8
		c0.101-0.859,0.765-1.155,1.68-1.2c-0.04-1-0.083-2.003,0.48-2.4c-2.78,0.14-5.109-0.171-7.68-0.24
		c0.202,0.279,0.316,0.644,0.24,1.2c0.71,1.129,1.025,2.655,1.68,3.84c0.019,1.101,0.61,1.63,0.48,2.88
		c-2.175,0.813-3.762-1.035-4.32-2.64c0.31-0.938-0.79-0.938-0.48,0c0.449,0.67,0.455,1.785,0.96,2.4
		c0.157,1.203,0.912,1.808,0.96,3.12c1.29,1.159,0.537,3.002,0.96,4.8c0.568,0.873,0.667,2.214,1.2,3.12
		c0.212,1.074,1.317,2.515,0.96,3.36c0.245,0.315,0.235,0.885,0.48,1.2c0.654,1.142-0.344,2.69,0,4.32
		c0.75,3.33,2.763,5.396,3.84,8.4c0.523,0.757,1.076,1.485,1.2,2.64c0.753,0.527,0.336,2.224,1.2,2.64
		c0.599,0.922,1.241,1.799,1.44,3.12c0.093,0.867,0.318,1.602,0.72,2.16c0.498,0.623,0.817,1.423,0.96,2.4
		c0.53,0.83,1.188,1.532,1.2,2.88c0.647,2.473,1.469,4.771,2.4,6.96c0.148,0.652,0.476,1.124,0.96,1.44
		c1.501,1.458,3.13,2.79,4.8,4.08c0.652,0.148,1.124,0.476,1.44,0.96c1.484,0.436,2.514,1.326,3.36,2.4
		c1.557,0.683,2.993,1.487,4.08,2.64c0.871,0.249,1.49,0.75,1.92,1.44c0.9-0.02,0.881,0.879,1.68,0.96
		c0.957,0.403,1.573,1.147,2.16,1.92c0.796,0.084,1.042,0.719,1.44,1.2c0.146,0.014,0.284,0.036,0.24,0.24
		c1.077,0.363,1.752,1.128,2.4,1.92c0.922,0.598,1.473,1.567,2.64,1.92c1.14,0.939,2.18,1.979,3.12,3.12
		c0.204-0.044,0.226,0.093,0.24,0.24c0.187,0.054,0.474,0.007,0.48,0.24c1.059,0.941,2.24,1.76,3.36,2.64
		c1.206,0.395,2.433,0.768,4.08,0.72c3.464,0.336,6.825,0.211,10.08-0.24c1.49,0.13,2.233-0.486,3.6-0.48
		c0.812-0.759,3.313-0.936,4.56-0.48c1.103-0.897,2.562-1.438,3.36-2.64c0-0.16,0-0.32,0-0.48c0.771-0.269,1.237-0.843,1.44-1.68
		c0.734-0.707,1.355-1.525,2.16-2.16c1.028-1.372,2.117-2.683,3.36-3.84c0.051-0.27,0.267-0.374,0.48-0.48
		c0.987-1.653,2.334-2.946,3.6-4.32c0.051-0.269,0.267-0.374,0.48-0.48c1.281-2.079,3.023-3.697,4.801-5.28
		c0.285-1.394,2.041-1.318,2.159-2.88c0.08,0,0.16,0,0.24,0c0.051-0.27,0.267-0.374,0.479-0.48c0.988-1.012,1.703-2.297,2.881-3.12
		c0.941-0.578,1.516-1.524,2.64-1.92c0.175-0.545,0.722-0.718,1.2-0.96c1.812-1.628,3.854-3.026,5.039-5.28
		c0.19-1.25,1.141-1.74,1.201-3.12c0.446-1.313,1.045-2.474,1.439-3.84c0.528-1.152,0.75-2.61,1.439-3.6
		c0.493-1.507,0.66-3.34,0.961-5.04c0.172-1.268,0.326-2.554,0.72-3.6c-0.012-1.211,0.094-2.306,0.479-3.12
		c0.122-1.719,0.291-3.389,0.721-4.8c0.002-0.637-0.091-1.371,0.24-1.68c0.17-0.869,0.09-1.99,0.479-2.64
		c-0.031-0.592-0.036-1.156,0.24-1.44c-0.147-0.876-0.124-1.936,0.24-2.88c-0.013-1.211,0.093-2.306,0.479-3.12
		c0.047-1.154,0.115-2.285,0.48-3.12C314.743,259.337,313.605,259.755,313.521,259.12z M201.92,193.12
		c-2.339,7.862-1.703,19.53-0.72,27.6c-3.896,0.079-2.565-6.993-3.36-10.8c-0.399-1.524-0.399-4.717,0-6.24
		c0.245-2.475,0.615-4.825,0.96-7.2c0.394-1.286,0.524-2.836,1.2-3.84c0.781-0.579,0.023-2.697,1.68-2.4
		C203.014,190.244,202.283,192.624,201.92,193.12z M239.121,279.28c1.941,0.539,2.853,2.107,4.08,3.36c0,0.16,0,0.32,0,0.48
		c0.737,1.024,1.668,1.852,2.16,3.12c0.16,0,0.32,0,0.48,0c0.389-0.659,2.581-0.769,3.12-0.48c0.454,0.054,0.529-0.271,0.96-0.24
		c0.867-0.333,0.752-1.648,1.44-2.16c0.736-0.544,1.045-1.515,1.68-2.16c1.779-2.781,4.121-4.999,6.96-6.72
		c0.324-0.796,1.559-0.682,2.16-1.2c1.529-0.791,3.157-1.482,4.08-2.88c0-0.241,0.195-0.286,0.24-0.48
		c0.593-1.087,0.7-2.66,1.2-3.84c0-0.64,0-1.28,0-1.92c0.005-0.264-0.027-0.535-0.088-0.812c0.017-2.188,2.774-1.168,2.728,0.572
		c0.4,2.162,0.4,5.998,0,8.16c-0.317,2.162-0.809,4.151-2.16,5.28c-0.199,1.161-1.063,1.657-1.44,2.64
		c-1.089,1.791-0.822,4.938-2.4,6.24c-0.779,0.98-1.66,1.86-2.64,2.64c-0.08,0-0.16,0-0.24,0c-0.806,0.954-1.707,1.813-2.16,3.12
		c-0.354,1.485-0.908,2.771-2.16,3.36c-0.208,0.992-1.058,1.342-1.92,1.68c-0.768,0.191-1.211,0.709-2.16,0.72
		c-1.422,0.818-3.129,1.351-4.8,1.92c-0.169,0.871-1.749,0.331-2.64,0.48c-0.058-0.342-0.396-0.405-0.48-0.72
		c-0.787-1.293-1.055-3.105-1.68-4.56c-0.542-0.498-0.75-1.331-0.96-2.16c-0.975-0.945-1.172-2.667-2.16-3.6
		c-2.502-2.058-3.586-5.533-6-7.68c-0.774-0.346-1.544-0.696-1.68-1.68C231.653,275.449,238.064,277.474,239.121,279.28z
		 M277.521,259.6c-1.724-0.124-3.573-0.973-3.36-0.48c0,0.08,0,0.16,0,0.24c0.067,0.493,0.641,0.479,0.96,0.72
		c1.11,0.33,1.785,1.095,2.4,1.92c0.04,0.68,0.44,1,0.48,1.68c-2.829,0.109-3.735-1.705-5.52-2.64
		c-0.752-0.208-1.325-0.595-1.68-1.2c-0.748-0.612-1.683-1.037-2.4-1.68c-0.624-0.409-0.103-1.684,0.24-1.92
		c0.422,0.138,1.41-0.29,1.44,0.24c0.538-0.342,0.458-1.302,0.96-1.68c0.422,0.138,1.41-0.29,1.44,0.24
		c0.411-0.011,0.622,0.178,0.72,0.48c1.595,0.004,2.409,0.791,4.08,0.72c1.92,0,3.84,0,5.76,0c1.867,0.188,3.143-0.217,4.32-0.72
		c0.341,0.021,0.68,0.04,0.72-0.24c0.875-0.589,1.611,0.388,1.681,0.96C290.023,258.409,282.245,259.94,277.521,259.6z
		 M221.84,277.12c0.043-4.037,3.221-4.939,5.04-7.2c0.435-1.166,1.486-1.714,2.16-2.64c0.923-1.637,1.299-3.821,2.4-5.28
		c-0.11-0.75,0.513-0.768,1.2-0.72c0.249,0.614,0.717,1.312,0,1.68c-1.634,1.966-2.849,4.351-3.84,6.96
		c-0.146,0.734-0.509,1.25-0.72,1.92c-0.6,0.84-0.717,2.163-1.44,2.88c-0.46,0.821-0.855,1.705-1.68,2.16
		c0.088,0.408-0.188,0.453-0.48,0.48C224.058,278.388,222.145,277.704,221.84,277.12z M262.641,329.92
		c-0.914,0.114-1.499-0.102-1.68-0.72c-0.906-0.988-1.543-5.802,0.72-5.76c0.336,1.424,0.523,2.997,0.72,4.56
		C263.131,328.02,262.963,329.569,262.641,329.92z M300.561,232.96c-0.047,0.757-1.646,0.683-0.96,1.44c0,0.08,0,0.16,0,0.24
		c0.556,0.067,0.552,0.641,0.239,0.96c-2.613-0.214-2.533,2.267-3.84,3.36c-1.359,0-2.72,0-4.08,0c2.17,0.099,0.338,2.487,0.24,3.84
		c0.241,0,0.286-0.194,0.48-0.24c0.168-1.142,2.002-2.248,2.88-1.2c0.894-0.014,0.692,1.068,0.72,1.92c0,0.08,0,0.16,0,0.24
		c-0.08,0-0.16,0-0.24,0c-0.085,1.034-0.496,1.744-1.199,2.16c-0.545,0.175-0.719,0.722-0.961,1.2c-0.785,1.534-1.85,2.79-3.359,3.6
		c-1.482,1.078-3.081,2.04-5.28,2.4c-0.32,0-0.64,0-0.96,0c-0.374-0.361-1.226-1.476-0.48-2.16c-0.13-0.93,1.392-0.208,1.44-0.96
		c-2.715,1.442-6.52,0.728-11.52,0.96c-4.738,0.221-8.805,1.319-8.88-3.12c0.422-0.138,1.41,0.29,1.44-0.24
		c-2.942-0.831-1.782,3.616-4.56,2.16c-0.773-1.261,0.316-2.718,0.48-3.84c1.063-2.377,1.368-5.512,2.16-8.16
		c0.645-0.316,0.479-1.441,1.44-1.44c1.373-0.867,3.193-1.287,4.56-2.16c1.131-0.709,2.361-1.319,3.84-1.68
		c0.336-0.383,0.827-0.613,1.44-0.72c1.146-0.774,2.426-1.414,3.84-1.92c0.3-0.58,0.984-0.776,1.68-0.96
		c2.536-0.984,4.345-2.695,7.68-2.88c1.374,0.121,1.442,2.289,0.24,2.64c-0.619,0.342-1.358,0.562-1.44,1.44
		c-0.241-0.001-0.286,0.194-0.48,0.24c-0.586,0.854-1.584,1.296-2.4,1.92c-0.08,0-0.16,0-0.24,0c-0.741,0.378-1.413,0.827-1.92,1.44
		c0.029,0.647-2.181,1.148-0.96,1.44c0.566-0.474,1.515-0.565,2.4-0.72c0.449-0.191,0.879-0.401,1.44-0.48
		c0.366-0.754,1.841-0.399,2.88-0.48c0.561,0,1.12,0,1.68,0c0.729-0.473,2.017-0.384,2.881-0.72c0.818-0.061,1.152-0.607,2.16-0.48
		c0.242-0.398,1.02-0.261,1.439-0.48C297.605,230.143,301.9,230.137,300.561,232.96z"/>
			<g>
				<path fillRule="evenodd" clipRule="evenodd" d="M529.521,550.001c-0.014-2.467-1.479-3.482-2.16-5.28
			c-0.507-2.373-1.245-4.515-2.641-6c-1.361-1.759-4.039-2.2-5.279-4.08c-1.046-1.194-1.777-2.702-2.881-3.84
			c0.021-0.341,0.04-0.68-0.239-0.72c-0.493-1.667-0.864-3.457-1.44-5.04c-0.131-0.349,0.28-1.24-0.24-1.2
			c-0.235-1.044-0.276-2.283-0.72-3.12c0-0.4,0-0.8,0-1.2c-0.291-1.148-0.062-2.818-0.96-3.359c-0.15-0.729,0.308-2.067-0.24-2.4
			c0.168-1.207-0.407-1.673-0.24-2.88c-0.649-2.39-0.587-5.493-1.199-7.92c0-0.08,0-0.16,0-0.24c0.186-1.706-0.363-2.677-0.24-4.32
			c0.035-1.075-0.026-2.054-0.48-2.64c0-0.08,0-0.16,0-0.24c-0.017-0.943-0.115-1.804-0.479-2.399c-0.08,0-0.16,0-0.24,0
			c-0.133-2.35-0.985-4.768-1.92-6.96c-0.291-0.909-0.465-1.936-0.96-2.641c-0.126-0.754-0.421-1.339-0.72-1.92
			c-0.055-0.985-0.509-1.571-0.96-2.16c-0.345-0.936-0.573-1.986-1.2-2.64c-3.08-8.2-6.815-15.744-11.28-22.561
			c-0.428-1.651-1.916-2.244-2.16-4.079c-0.595-1.646-0.416-4.063-1.68-5.04c-0.205-0.436-0.618-0.662-0.72-1.2
			c-0.734-0.786-1.209-1.831-2.16-2.4c-0.413-0.946-0.995-1.725-1.68-2.399c0.01-0.73-0.507-0.934-0.721-1.44
			c-0.311-0.97-0.528-2.031-0.96-2.88c-0.152-0.808-0.17-1.75-0.72-2.16c-0.024-1.656-0.246-3.114-0.72-4.32
			c-0.11-3.17-0.739-5.82-0.721-9.119c-0.155-1.205,0.314-3.035-0.239-3.84c0-0.08,0-0.16,0-0.24
			c-0.004-2.557-0.096-5.025-0.48-7.201c-0.219-3.301-0.261-6.779-1.2-9.36c-0.235-1.445-0.75-2.609-0.96-4.08
			c-0.335-1.265-0.616-2.583-1.2-3.6c0.175-0.734-0.414-0.706-0.239-1.44c-0.331-0.789-0.188-2.051-0.96-2.4c0-0.08,0-0.16,0-0.24
			c-0.484-2.396-1.513-4.248-2.4-6.24c-0.804-2.796-1.786-5.414-2.88-7.92c-0.451-0.829-0.573-1.987-1.2-2.64
			c0.021-0.341,0.04-0.68-0.24-0.72c0.175-1.215-0.658-1.421-0.72-2.4c-0.835-1.005-1.025-2.655-1.92-3.6
			c-0.106-0.613-0.337-1.103-0.72-1.44c-0.505-2.056-1.736-3.384-2.641-5.04c-0.026-0.613-0.439-0.84-0.72-1.2
			c-0.044-1.077-0.759-1.481-1.2-2.16c-1.037-1.763-2.256-3.344-3.359-5.04c-0.507-0.854-0.932-1.789-1.681-2.4
			c-1.024-2.015-2.357-3.722-3.84-5.28c0-0.08,0-0.16,0-0.24c-0.801-0.319-0.836-1.404-1.68-1.68c-0.045,0.355-0.58,0.22-0.96,0.24
			c-1.104-0.897-2.195-1.805-3.601-2.4c-0.413-0.477-1.266-1.76-1.68-1.44c-1.38-0.541-2.318-1.522-3.6-2.16
			c-0.615-0.665-1.425-1.136-2.4-1.44c-0.007-0.233-0.294-0.187-0.48-0.24c-1.833-0.887-3.185-2.254-5.76-2.4
			c0.011-0.411-0.178-0.622-0.479-0.72c-0.51-1.49-1.6-2.401-2.88-3.12c-0.105-0.295-0.308-0.493-0.721-0.48
			c-0.958-1.122-2.849-1.312-4.08-2.16c-0.301-0.659-1.298-0.622-2.159-0.72c-1.309-0.292-2.712-0.488-4.08-0.72
			c-0.838-0.443-2.076-0.484-3.12-0.72c0.04-0.521-0.852-0.108-1.2-0.24c-0.788-0.412-1.765-0.635-2.88-0.72
			c-0.279,0.039-0.428-0.053-0.48-0.24c-0.697-0.422-1.692-0.547-2.64-0.72c-1.012-0.348-2.142-0.579-3.36-0.72
			c-0.811-0.47-1.976-0.585-3.12-0.72c0.041-0.521-0.851-0.109-1.199-0.24c-1.138-0.382-2.418-0.623-3.841-0.72
			c-0.656-0.384-1.586-0.494-2.64-0.48c-0.697-0.422-1.693-0.547-2.64-0.72c-0.695-0.425-1.838-0.402-2.641-0.72
			c-0.757,0.117-0.848-0.433-1.68-0.24c-0.697-0.422-1.693-0.547-2.64-0.72c-1.343-0.418-2.649-0.871-4.32-0.96
			c-0.708-0.412-1.731-0.509-2.88-0.48c-1.443,0.323-1.438-0.803-2.88-0.48c0.04-0.52-0.852-0.108-1.2-0.24
			c-0.678-0.362-1.656-0.423-2.64-0.48c-1.096,0.135-1.495-0.425-2.641-0.24c-0.278-0.201-0.644-0.316-1.2-0.24
			c-1.054-0.466-2.441-0.598-3.84-0.72c-3.389,0.118-6.611-1.171-9.84-1.92c0.172-0.652-1.132,0.172-0.96-0.48
			c-0.333-0.547-1.671-0.089-2.4-0.24c-0.08,0-0.159,0-0.239,0c0.04-0.521-0.852-0.109-1.2-0.24c-0.186-0.615-0.239-1.36-0.721-1.68
			c-0.915-0.924-1.004-2.676-1.92-3.6c-0.287-1.072-0.794-1.926-1.439-2.64c-0.089-0.791-0.561-1.199-0.96-1.68
			c-0.363-1.236-0.971-2.229-1.92-2.88c0-0.08,0-0.16,0-0.24c-0.435-0.926-1.045-1.675-1.681-2.4
			c-0.922-1.798-1.974-3.466-3.359-4.8c-0.411-1.029-1.077-1.803-1.92-2.4c-0.986-1.574-2.215-2.906-3.601-4.08
			c0-0.08,0-0.16,0-0.24c-0.736-1.023-1.608-1.911-2.399-2.88c-0.499-1.262-1.964-1.556-2.641-2.64c-0.24,0-0.479,0-0.72,0
			c-2.371-0.749-3.476-2.764-5.521-3.84c-0.021-0.341-0.039-0.68,0.24-0.72c0.384-0.656,0.494-1.586,0.48-2.64
			c0.33-0.31,0.237-1.042,0.24-1.68c0.466-2.253,0.945-4.494,0.96-7.2c-0.045-0.204,0.093-0.226,0.239-0.24
			c1.005,0.361,3.566,0.427,4.561,0c0.08,0,0.16,0,0.24,0c1.03-0.009,1.767-0.313,2.399-0.72c1.633-0.527,2.909-1.411,4.08-2.4
			c1.263-0.657,2.092-1.749,2.88-2.88c0.396-1.045,1.364-1.516,1.44-2.88c0.555-0.805,0.084-2.636,0.24-3.84
			c0.281-0.039,0.202-0.438,0.239-0.72c0.493-1.987,0.248-4.712,1.2-6.24c0.28-1.08,0.934-1.786,0.96-3.12
			c0.558-0.723,0.852-1.708,1.2-2.64c1.585-2.078,2.152-6.242,3.12-8.16c0.08,0,0.16,0,0.24,0c-0.338-0.994,0.685-1.854,0-2.4
			c-0.204,0.044-0.227-0.094-0.24-0.24c1.11-2.984,1.282-10.315-0.24-12.96c0.266-0.763-0.042-1.062-0.479-1.68
			c0.222-0.525-0.012-1.701-0.48-1.92c-0.21-0.83-0.417-1.663-0.96-2.16c0.106-0.906-0.474-1.126-0.72-1.68
			c-0.543-0.978-0.906-2.134-1.681-2.88c-0.604-0.755-0.896-1.823-1.68-2.4c-0.982-1.178-2.158-2.161-4.08-2.4
			c-0.701-0.776-2.3,0.455-2.64,0c0.897-1.402,0.269-5.135,0.479-6.48c0.56-2.801,0.081-6.639,0.24-9.84
			c-0.613-2.68,0.128-5.924-0.96-7.68c0.887-0.371-0.382-1.813,0-1.68c0.606-1.094-0.477-2.557-0.48-3.84
			c-0.188-1.251-0.244-2.635-0.72-3.6c-0.08,0-0.16,0-0.24,0c-0.164-2.635-0.926-4.673-1.68-6.72c-0.241-1.119-0.44-2.279-0.96-3.12
			c-1.659-4.661-1.971-10.669-5.04-13.92c-1.435-2.325-3.352-4.168-5.28-6c-0.213-0.106-0.429-0.211-0.479-0.48
			c-0.049-0.752-0.619-0.981-0.96-1.44c-1.413-1.547-2.767-3.153-4.56-4.32c-5.193-2.807-10.499-5.501-15.361-8.64
			c-1.032-1.047-2.592-1.568-3.84-2.4c-2.741-2.219-5.684-4.235-9.839-5.04c-1.697-0.463-2.852-1.469-4.8-1.68
			c-0.574-0.146-1.741,0.301-1.92-0.24c-1.363-0.557-3.758-0.082-5.52-0.24c0-0.08,0-0.16,0-0.24c-2.48,0-4.96,0-7.44,0
			c-0.08,0-0.16,0-0.24,0c-0.751,0.528-2.405,0.154-3.12,0.72c-4.233,0.727-8.143,1.777-11.28,3.6c-1.235,0.921-1.624,0.366-3.12,0
			c-0.006-0.233-0.293-0.186-0.48-0.24c-1.586-0.976-5.003-0.408-6.48,0.24c-1.03,0.17-2.008,0.392-2.64,0.96
			c-3.566,1.714-7.502,3.058-11.04,4.8c-2.575-0.015-5.006,0.114-6.48,1.2c-1.168,0.192-2.3,0.42-3.12,0.96
			c-4.281,1.479-8.323,3.197-11.52,5.76c-0.851,0.83-1.92,1.44-2.64,2.4c-0.134,0.346-0.375,0.586-0.72,0.72
			c-2.076,1.443-3.261,3.779-5.28,5.28c-0.183,0.377-0.761,0.359-0.72,0.96c-1.194,0.806-1.922,2.078-2.88,3.12
			c-2.605,2.915-3.96,7.08-5.52,11.04c-0.524,1.041-0.896,2.53-1.2,3.12c0,0.24,0,0.48,0,0.72c-0.809,0.151-0.625,1.295-0.72,2.16
			c0.03,0.99-0.417,1.503-0.24,2.64c-0.687,0.033,0.208,1.647-0.48,1.68c-0.408,1.192-0.501,2.699-0.48,4.32
			c-0.331,2.231-0.823,5.574-0.24,7.92c0.51,0.851-0.008,2.729,0.72,3.36c-0.012,0.733,0.865,0.576,0.72,1.44
			c-0.708,2.013-0.65,6.068-0.24,8.4c0.339,0.123-0.439,2.416,0.24,2.88c0.263,2.009-0.042,5.011,0.96,7.2
			c-0.167,2.249,0.702,5.537,1.2,7.92c0,0.24,0,0.48,0,0.72c0.832,2.448,0.731,5.829,1.2,8.64c0,0.48,0,0.96,0,1.44
			c-0.826,0.054-0.771,0.989-1.2,1.44c-0.558,1.522-0.082,4.079-0.24,6c-0.977-0.463-1.226-1.654-2.4-1.92
			c-1.621-2.198-7.427-1.358-8.16,0.96c-0.981,0.464-1.882,2.272-1.2,3.6c0.033,0.447,0.158,0.802,0.48,0.96
			c0.522,0.998,2.233,0.807,1.68,2.88c0.151,1.049-0.127,2.528,0.72,2.88c0,0.16,0,0.32,0,0.48c-0.287,0.148,0.137,1.607,0.48,1.92
			c0.512,3.008,1.707,5.333,2.88,7.68c0.097,1.423,0.693,2.347,1.44,3.12c0.601,1,1.2,2,1.92,2.88c0.05,0.99,0.98,1.1,1.2,1.92
			c-0.551,0.489-0.087,1.993-0.24,2.88c0.96,1.68,0.486,4.794,1.2,6.72c-0.176,1.616,0.656,2.224,0.48,3.84
			c0.116,1.004,0.461,1.779,0.72,2.64c0.354,0.846,0.458,1.941,0.96,2.64c0.893,1.267,1.458,2.863,2.64,3.84
			c0.233,0.006,0.186,0.293,0.24,0.48c0.612,0.828,1.281,1.599,2.16,2.16c0.588,0.452,1.174,0.906,2.16,0.96
			c0.336,0.397,2.304,0.397,2.64,0c0.24,0,0.48,0,0.72,0c0.362,2.357,0.625,4.815,1.2,6.96c-0.42,1.419-1.178,2.502-2.16,3.36
			c-0.677,1.103,1.034,1.677-0.48,3.12c-1.579,1.221-2.346,3.254-3.6,4.8c-0.802,0.799-1.442,1.758-1.92,2.88
			c-2.432,3.168-4.367,6.833-5.76,11.04c-0.589,0.852-1.01,1.87-1.2,3.12c-0.447,0.522-1.033,2.155-0.72,2.64
			c-0.187,0.213-0.258,0.542-0.24,0.96c-0.272,0.038-0.208,0.414-0.238,0.694c0.041-0.073,0.075-0.153,0.108-0.233
			c-0.032,0.082-0.067,0.161-0.108,0.234c0,0,0,0,0,0c-0.004,0.008-0.01,0.016-0.015,0.023c-0.004,0.006-0.008,0.012-0.012,0.018
			c-1.04,0.627-2.276,1.056-3.094,1.906c-0.676,0.524-2.501-0.101-3.12,0.48c-1.106,0.014-2.129,0.111-2.88,0.48
			c-1.632,0.047-3.025,0.334-4.32,0.72c-0.983,0.057-1.962,0.118-2.64,0.48c-0.592-0.031-1.156-0.036-1.44,0.24
			c-0.943,0.017-1.804,0.116-2.4,0.48c-0.32,0-0.64,0-0.96,0c-0.997,0.203-2.071,0.329-2.88,0.72c-0.885,0.154-1.833,0.246-2.4,0.72
			c-1.725,0.196-3.202,0.638-4.56,1.2c-1.188,0.251-2.491,0.389-3.36,0.96c-2.605,0.435-4.811,1.269-6.96,2.16
			c-0.909,0.291-1.936,0.465-2.64,0.96c-0.734-0.174-0.706,0.414-1.44,0.24c-1.039,0.241-2.202,0.358-2.88,0.96
			c-1.058-0.018-1.653,0.427-2.4,0.72c-0.279-0.039-0.427,0.053-0.48,0.24c-1.263,0.257-2.496,0.544-3.36,1.2
			c-1.397,0.203-2.401,0.799-3.6,1.2c-1.03,0.17-2.008,0.392-2.64,0.96c-0.597-0.037-0.743,0.377-1.2,0.48
			c-1.125,0.394-2.305,0.735-3.12,1.44c-0.279-0.039-0.427,0.053-0.48,0.24c-1.034,0.085-1.744,0.496-2.16,1.2
			c-1.104,0.176-1.252,1.308-1.92,1.92c-0.695,0.345-0.989,1.09-1.2,1.92c-0.665,0.536-1.062,1.338-1.2,2.4
			c-0.468,0.732-0.751,1.648-1.2,2.4c-0.187,0.533-0.504,0.936-0.48,1.68c-0.108,3.413-3.025,4.016-4.8,5.76
			c-2.322,1.357-4.748,2.612-6.72,4.32c-1.331,0.75-2.441,1.72-3.6,2.64c-0.44,0.28-0.813,0.627-1.2,0.96
			c-0.749,0.611-1.173,1.546-1.68,2.4c-0.274,1.166-0.764,2.117-0.96,3.36c-0.467,0.893-0.836,1.884-1.2,2.88
			c-1.209,2.311-2.16,4.88-2.88,7.68c-0.553,1.047-0.95,2.25-1.2,3.6c-0.875,2.166-1.574,4.506-2.16,6.96
			c-0.474,0.566-0.565,1.515-0.72,2.4c-0.187,0.214-0.258,0.542-0.24,0.96c-0.352,0.768-0.687,1.553-0.72,2.64
			c-0.326,0.074-0.199,0.601-0.24,0.96c-0.534,0.747-0.649,1.911-0.72,3.12c0.02,1.3-0.565,1.996-0.48,3.36
			c-0.364,0.596-0.463,1.457-0.48,2.4c-0.276,0.284-0.271,0.848-0.24,1.44c-0.629,3.21-0.931,6.749-1.2,10.32
			c-0.389,2.571-0.747,5.173-0.96,7.92c0,0.4,0,0.8,0,1.2c-0.848,0.352-0.569,1.831-0.72,2.88c0,0.479,0,0.96,0,1.44
			c-0.427,1.894-0.9,3.74-0.96,6c0,0.08,0,0.16,0,0.24c-0.45,2.75-0.419,5.98-0.72,8.879c-0.364,0.597-0.463,1.457-0.48,2.4
			c-0.496,0.465-0.458,1.463-0.72,2.16c-0.535,0.824-0.645,2.074-0.96,3.12c0.193,0.832-0.357,0.923-0.24,1.68
			c-0.474,0.566-0.565,1.515-0.72,2.4c-0.704,2.176-1.384,4.376-1.68,6.96c-0.412,0.788-0.635,1.765-0.72,2.88
			c-0.326,0.074-0.199,0.601-0.24,0.96c-0.391,0.81-0.517,1.883-0.72,2.88c-0.368,1.072-0.677,2.203-0.72,3.601
			c-0.478,0.962-0.7,2.18-0.72,3.6c-0.24,2.48-1.026,4.414-1.2,6.96c-0.956,3.124-0.746,7.414-1.68,10.561
			c0.086,1.446-0.566,2.153-0.48,3.6c-0.536,0.665-0.028,2.372-0.72,2.88c-0.364,0.596-0.463,1.457-0.48,2.4c0,0.319,0,0.64,0,0.96
			c-0.719,2.081-0.897,4.702-1.44,6.96c-0.207,0.993-0.644,1.756-0.72,2.88c-0.562,0.878-0.891,1.989-1.2,3.12c0,0.08,0,0.16,0,0.24
			c0,0.239,0,0.479,0,0.72c-0.379,0.501-0.498,1.262-0.48,2.16c0,0.08,0,0.159,0,0.239s0,0.16,0,0.24
			c-0.398,2.162-0.569,4.551-0.96,6.721c-0.444,0.676-0.66,1.58-0.96,2.399c-0.555,0.485-1.282,0.798-1.44,1.68
			c-0.942,0.738-1.765,1.596-2.16,2.881c-0.295,0.104-0.493,0.307-0.48,0.72c-0.565,0.234-0.215,1.385-0.72,1.68
			c0,1.601,0,3.2,0,4.8c0.08,0,0.16,0,0.24,0c0,0.08,0,0.16,0,0.24c0.185,1.146-0.375,1.545-0.24,2.64
			c-0.276,0.284-0.271,0.849-0.24,1.44c-0.548,0.732-0.597,1.963-0.96,2.88c-0.766,0.994-1.356,2.163-1.68,3.601
			c-0.752,1.007-1.12,2.4-1.68,3.6c-0.398,0.802-0.562,1.839-0.96,2.64c-0.369,0.912-0.695,1.865-0.96,2.881c0,0.239,0,0.479,0,0.72
			c-0.461,0.979-0.765,2.115-0.96,3.359c-0.497,2.063-1.391,3.729-1.44,6.24c-0.885,0.636-0.637,2.403-0.96,3.601
			c0,0.319,0,0.64,0,0.96c-0.527,1.873-0.924,3.876-0.96,6.239c0,0.08,0,0.16,0,0.24c-0.599,1.481-0.19,3.97-0.72,5.521
			c0,2.64,0,5.279,0,7.92c0.553,4.727,0.5,10.06,1.2,14.64c0.679-0.039-0.2,1.479,0.48,1.44c0,0.399,0,0.8,0,1.2
			c0,0.079,0,0.159,0,0.239c0.221,1.3,0.067,2.973,0.96,3.601c0.143,0.497-0.297,1.576,0.24,1.68
			c-0.034,1.233,0.355,2.045,0.72,2.88c0.251,1.669,0.859,2.981,1.44,4.32c0.818,2.781,1.732,5.468,3.12,7.68
			c-0.054,0.454,0.271,0.529,0.24,0.96c0.125,1.155,0.677,1.883,1.2,2.641c0.501,1.019,0.658,2.382,1.44,3.119c0,0.08,0,0.16,0,0.24
			c0.498,0.702,0.54,1.86,1.2,2.4c0.132,0.427,0.123,0.996,0.48,1.2c0.138,1.062,0.536,1.864,1.2,2.399
			c0.384,1.216,0.928,2.272,1.68,3.12c0.033,0.447,0.158,0.802,0.48,0.96c0.36,1,0.681,2.039,1.44,2.641
			c1.861,2.858,3.402,6.038,5.52,8.64c0.649,1.591,1.699,2.781,2.88,3.84c3.641,2.599,5.953,3.614,11.28,4.56
			c2.859,0.965,7.016,0.287,9.6-0.479c2.903-0.137,4.355-1.725,6.72-2.4c0.897-0.303,2.09-0.31,2.64-0.96
			c0.716-0.004,0.816-0.624,1.44-0.72c2.682-1.397,5.029-3.131,7.2-5.04c1.715-1.645,3.631-3.089,5.28-4.8
			c0.557,0.076,0.921-0.039,1.2-0.24c0.912-0.128,1.719-0.36,2.4-0.72c4.367-1.394,8.178-3.342,11.52-5.76c0.08,0,0.16,0,0.24,0
			c1.05-1.271,2.332-2.309,3.36-3.601c0.687-0.673,1.729-0.991,2.4-1.68c1.27-0.33,2.014-1.187,3.36-1.44
			c0.557,0.077,0.921-0.038,1.2-0.239c-0.455,1.481-1.17,4.477,1.44,4.079c-0.606,4.75-0.605,10.577-0.24,15.601
			c0.073,1.048,0.25,1.99,0.72,2.64c0.43,1.811,0.68,3.801,1.2,5.521c0.256,3.503,0.655,6.864,0.48,10.8
			c-0.591,1.329-0.94,2.9-1.68,4.08c-0.471,0.649-0.647,1.593-0.72,2.64c0,0.32,0,0.641,0,0.96c-0.355,0.045-0.22,0.58-0.24,0.96
			c-0.335,1.429-1.243,3.553-0.72,5.04c0,1.04,0,2.08,0,3.12c0.5,0.62-0.085,2.325,0.48,2.88c-0.141,0.861,0.279,1.161,0.48,1.681
			c0.311,0.97,0.529,2.031,0.96,2.88c-0.077,0.557,0.039,0.921,0.24,1.2c-0.08,0-0.16,0-0.24,0c-0.432,0.528-0.21,1.71-0.48,2.399
			c0,0.32,0,0.641,0,0.96c-0.69,0.99-0.912,2.448-1.44,3.601c-0.272,0.847-0.744,1.496-0.72,2.64c0,0.16,0,0.32,0,0.48
			c-0.709,0.331-0.553,1.526-0.72,2.399c0.087,1.848-0.591,2.929-0.72,4.561c-0.539,1.142-0.761,2.599-1.44,3.6
			c-0.259,1.501-0.783,2.737-0.72,4.56c0,0.4,0,0.801,0,1.2c-0.08,0-0.16,0-0.24,0c-0.389,0.972-0.491,2.229-0.48,3.601
			c-0.148,0.651,0.305,1.904-0.24,2.159c-0.552,0.568-0.086,2.154-0.24,3.12c0,0.48,0,0.96,0,1.44c-0.52-0.04-0.108,0.852-0.24,1.2
			c-0.499,1.021-0.706,2.334-0.96,3.6c-0.455,1.065-0.855,2.185-0.96,3.601c-0.131,0.908-0.581,1.499-0.48,2.64
			c-0.483,0.717-0.502,1.898-0.72,2.88c-0.146,2.095-2.027,2.452-2.16,4.561c-0.703,0.337-0.738,1.342-0.72,2.399
			c-0.736,0.086-1.274,2.468,0,2.4c0.054,0.186,0.006,0.473,0.24,0.479c1.442,0.958,4.527,0.273,6.72,0.48c1.04,0,2.08,0,3.12,0
			c92.075,0,184.162,0,276.24,0c-0.396-6.409,0.688-13.855-0.24-19.2c-1.33-2.125-0.229-4.541-0.72-7.68
			c0.054-2.374-0.504-4.136-0.96-6c0.447-2.368-0.667-3.174-0.72-5.04c-0.126-2.738-1.197-6.835-0.285-9.209
			c0.051-0.053,0.101-0.105,0.158-0.151c0.122,0,0.245,0,0.367,0c0.613,0.585,1.312-0.099,1.68-0.48
			c0.184,0.001,0.33-0.013,0.46-0.033c0.372,0.047,0.559,0.278,0.98,0.273c0-0.64,0-1.28,0-1.92c0.748-0.691,1.655-1.225,1.68-2.64
			c0.08,0,0.16,0,0.24,0c0.397-0.336,0.397-2.304,0-2.641c-0.08,0-0.16,0-0.24,0c-0.267-1.493-0.624-2.896-1.44-3.84
			c-0.237-0.855-1.233-1.219-0.72-2.16c-0.418,0.019-0.746-0.054-0.96-0.239c-0.913-2.768-1.71-5.65-3.36-7.681
			c-0.001-1.361,0.169-2.551,0.48-3.6c0.397-0.336,0.397-2.304,0-2.641c0-0.159,0-0.319,0-0.479c0.013-0.307,0.308-0.332,0.24-0.72
			c0.489-0.871,0.346-2.375,0.72-3.36c0.572-0.396,1.259-1.929,0.96-2.4c0.054-0.186,0.007-0.473,0.24-0.479
			c0.727-0.633,0.413-2.307,0.479-3.6c1.003-1.809-1.294-2.383-1.68-3.601c-0.202-2.842,0.462-4.818,1.2-6.72
			c0.368-0.912,0.694-1.865,0.96-2.88c0-0.24,0-0.48,0-0.721c0.491-1.108,0.822-2.377,1.2-3.6c1.003-1.614,0.209-4.381,0.239-6.48
			c-0.998-6.611-1.37-15.443-0.239-22.079c0.74-4.78,0.806-11.514,0.72-16.32c1.405-0.202,2.995,1.482,4.8,1.68
			c0.664,0.536,1.466,0.934,2.4,1.2c3.75,2.169,7.714,4.126,11.04,6.72c0.569,0.791,1.432,1.288,2.399,1.68
			c2.316,2.244,5.609,3.512,8.16,5.521c0.459,0.581,1.063,1.017,1.92,1.2c1.301,1.339,3.243,2.037,4.561,3.359
			c0.891,0.869,1.867,1.652,3.119,2.16c1.083,0.838,2.249,1.592,3.841,1.92c0.97,0.711,2.31,1.051,3.6,1.44
			c0.649,0.471,1.593,0.647,2.64,0.72c0.527,0.594,1.964,0.276,2.641,0.721c1.138,0.302,2.372,0.508,3.6,0.72c0.32,0,0.641,0,0.96,0
			c0.811,0.469,1.976,0.585,3.12,0.72c0.788,0.332,1.674,0.566,2.88,0.48c1.134,0.466,2.548,0.652,4.08,0.72
			c0.866,0.493,2.269,0.451,3.36,0.72c0.823,0.377,1.683,0.717,2.64,0.96c1.639,0.682,3.207,1.434,5.28,1.681
			c0.546,0.413,1.406,0.513,2.4,0.479c0.567,0.552,2.153,0.086,3.119,0.24c0,0.08,0,0.16,0,0.24c0.568,0.552,2.154,0.086,3.12,0.239
			c0.08,0,0.16,0,0.24,0c0.729,0.399,3.111,0.399,3.84,0c0-0.08,0-0.16,0-0.239c0.282-0.038,0.682,0.041,0.72-0.24
			c1.758-0.723,2.863-2.098,3.841-3.601c0.91-0.688,1.647-1.552,2.159-2.64c1.814-1.146,2.329-3.591,3.841-5.04
			c0.722-0.878,1.357-1.842,1.92-2.88c2.87-4.489,5.912-8.808,8.16-13.92c0.626-0.653,0.855-1.704,1.199-2.641
			c0.247-0.553,0.826-0.773,0.721-1.68c0.497-0.622,0.817-1.422,0.96-2.399c0.086-1.194,0.838-1.723,0.72-3.12
			c1.134-3.107,1.288-7.192,1.92-10.801c0.893-10.025,0.516-19.581,0-29.76c-0.595-2.844-0.455-6.005-0.24-9.12
			c0.405-0.475,0.263-1.498,0.48-2.16c0.474-0.566,0.565-1.515,0.72-2.399c0.322-0.158,0.447-0.513,0.48-0.96
			c0.705-0.815,1.045-1.994,1.439-3.12c0.696-0.664,0.456-2.265,0.96-3.12c0-0.32,0-0.64,0-0.96
			C529.681,550.001,529.601,550.001,529.521,550.001z M188.72,260.32c-0.3-1.46-0.96-2.56-1.2-4.08
			c-0.203-1.317-0.281-2.759-0.72-3.84c0.15-1.91-0.417-3.103-0.24-5.04c0.658,0.702,0.827,1.893,1.44,2.64
			C188.979,252.543,188.92,257.399,188.72,260.32z M187.521,242.32c-0.397-1.523-0.974-2.866-1.68-4.08
			c-0.3-1.22-0.821-2.219-1.2-3.36c-0.164-1.311-0.991-2.789-2.16-3.6c0.451-0.626-1-0.955-1.2-1.68c-0.7-0.9-1.5-1.7-2.4-2.4
			c-0.262-0.618-0.606-1.153-1.2-1.44c-0.227-1.053-0.897-1.662-1.44-2.4c-0.159-1.121-0.865-1.695-1.2-2.64
			c0.923-0.125,1.624-1.725,2.64-0.96c0.7,0.9,1.5,1.701,2.4,2.4c0.333,0.947,1.52,1.041,1.68,2.16
			c0.519,0.714,0.227,0.997,1.2,1.44c0,0.08,0,0.16,0,0.24c0.608,0.912,0.936,2.104,1.92,2.64c-0.044,0.204,0.094,0.227,0.24,0.24
			c0.621,0.899,1.148,1.892,1.68,2.88c0,0.4,0,0.8,0,1.2c0.152,1.047,0.4,2,0.72,2.88c0.593,1.087,0.7,2.66,1.2,3.84
			c0.421,2.619,1.532,4.548,1.92,7.2C188.412,246.548,188.788,243.613,187.521,242.32z M432.081,334.48
			c0.703-0.176,1.032-0.728,1.439-1.2c1.956,0.765,3.24,2.2,5.28,2.88c0.769,1.071,2.298,1.382,3.36,2.16
			c0.535,0.665,1.338,1.062,2.399,1.2c0.03,0.53,1.019,0.102,1.44,0.24c0.298,0.742,0.792,1.288,1.439,1.68
			c0.556,1.205,1.573,1.947,2.4,2.88c-0.041,0.601,0.369,0.751,0.72,0.96c0.09,0.791,0.561,1.199,0.96,1.68
			c0.991,1.489,1.895,3.066,3.12,4.32c0.478,1.123,1.119,2.082,1.92,2.88c0.207,0.833,0.749,1.332,1.2,1.92
			c0.188,0.932,0.756,1.484,1.2,2.16c0.003,0.477,0.654,0.305,0.479,0.96c0.489,0.791,0.877,1.683,1.44,2.4
			c-0.039,0.279,0.053,0.427,0.24,0.48c0.275,1.484,1.005,2.515,1.68,3.6c0,0.08,0,0.16,0,0.24c0.587,0.773,0.787,1.933,1.44,2.64
			c0.306,1.454,0.999,2.521,1.439,3.84c0.072,1.048,0.603,1.638,0.96,2.4c0.673,1.968,1.387,3.894,2.16,5.76
			c0.093,0.867,0.318,1.602,0.72,2.16c0,0.08,0,0.16,0,0.24c0.536,0.745,0.685,1.875,1.2,2.64c0.641,1.359,0.668,3.332,1.44,4.56
			c0.335,1.265,0.616,2.583,1.2,3.6c0.253,1.186,0.506,2.374,0.96,3.36c0.069,1.13,0.399,2,0.72,2.88c0,0.48,0,0.96,0,1.44
			c0.047,1.154,0.114,2.285,0.479,3.12c-0.045,2.125,0.607,3.553,0.48,5.76c0.498,2.941,0.662,6.218,0.72,9.599
			c0.51,2.371,0.379,5.381,0.96,7.681c-0.131,1.812,0.302,3.059,0.72,4.32c-0.306,0.877,0,2.974,0.48,3.84
			c0.02,0.38-0.115,0.915,0.24,0.96c0.464,1.695,0.849,3.471,1.92,4.561c0.182,1.098,0.96,1.6,1.439,2.399
			c1.088,1.232,2.138,2.503,3.12,3.84c0.187,0.214,0.259,0.542,0.24,0.96c0.087,1.273,0.186,2.535,0.96,3.12c0,0.08,0,0.16,0,0.24
			c0.608,0.831,0.882,1.998,1.68,2.64c0,0.16,0,0.32,0,0.48c0.759,0.922,1.165,2.195,2.16,2.88c0.145,1.056,0.868,1.532,1.2,2.399
			c0.446,0.834,0.775,1.785,1.44,2.4c0.556,1.204,1.229,2.29,1.92,3.36c2.196,5.323,4.502,10.537,6.96,15.6
			c0.092,0.628,0.77,0.671,0.479,1.681c0.567,1.032,0.721,2.479,1.44,3.359c0.171,1.109,0.484,2.075,0.96,2.88
			c0.01,1.031,0.313,1.768,0.72,2.4c0.151,1.208,0.278,2.442,0.48,3.6c0.302,1.538,0.744,2.937,0.72,4.8
			c0.335,1.426,0.453,3.067,0.48,4.801c0.745,4.163,1.161,9.29,1.92,12.72c0.349,1.581-0.125,1.45-0.721,2.4
			c-0.08,0-0.159,0-0.239,0c-0.308,0.427-0.463,2.239,0,2.64c-0.115,0.755,0.342,0.938,0.479,1.44
			c0.149,1.051,0.333,2.066,0.96,2.64c0,0.08,0,0.16,0,0.24c0.211,0.829,0.418,1.662,0.96,2.16c0.256,1.743,1.375,2.624,1.92,4.079
			c0.405,0.795,0.876,1.524,1.44,2.16c0.262,0.618,0.606,1.153,1.2,1.44c0.016,0.703,0.548,0.892,0.96,1.2
			c-0.166,0.599-0.641-0.445-1.2-0.24c-0.559-0.401-1.293-0.627-2.16-0.72c-1.619-0.941-3.94-1.18-5.76-1.92
			c-0.139-0.582-0.906-0.534-1.44-0.721c-1.281-0.318-2.207-0.992-3.6-1.2c-1.408-0.191-2.193-1.007-3.601-1.199
			c-1.674-0.966-3.649-1.631-5.76-2.16c-1.548-0.372-2.711-1.129-4.32-1.44c-2.149-0.81-4.362-1.558-6.96-1.92
			c-4.99-1.531-12.102-0.957-17.279,0c-2.247,0.394-4.28,1.001-6.24,1.68c-2.771,1.035-5.249,1.897-7.92,0.24
			c-0.599-0.602-1.72-0.681-2.4-1.2c-1.916-0.403-3.149-1.49-4.8-2.159c-1.247-0.754-2.434-1.566-4.08-1.92
			c-1.478-0.203-2.326-1.034-3.6-1.44c-1.4-0.92-2.96-1.681-4.801-2.16c-1.337-0.343-2.175-1.185-3.84-1.2
			c-0.327-0.472-0.229-1.371-0.479-1.92c-0.971-1.109-1.948-2.212-2.88-3.359c-0.686-0.676-1.267-1.454-1.681-2.4
			c-0.726-1.114-1.229-2.45-2.16-3.36c-0.37-1.068-1.214-1.665-1.439-2.88c-0.806-1.274-1.451-2.709-2.16-4.08
			c-0.519-1.401-1.183-2.657-1.44-4.319c-0.107-1.814-1.25-4.669-1.68-6.96c-0.707-1.677-1.184-4.632-1.92-6.961
			c-0.183-2.458-1.233-6.125-1.92-8.88c-0.208-2.432-0.417-4.863-1.92-6c-0.813-1.507-0.631-4.01-1.2-5.76
			c0.146-3.533-1.578-8.299,0-10.32c0.649-2.151,1.836-5.041,1.68-7.2c0.513-2.607,0.99-5.25,1.44-7.92
			c1.586-6.744,0.764-15.289,0.24-22.56c-0.602-6.039-1.524-11.756-1.44-18.48c-0.921-11.959-0.582-25.178-1.92-36.72
			c0-0.08,0-0.16,0-0.24c0-0.8,0-1.6,0-2.4c-0.71-4.65-1.073-9.646-1.68-14.4c0.057-1.341-0.938-3.699-1.92-4.8
			c-0.329-1.031-1.141-1.58-1.44-2.64c-0.624-0.816-1.066-1.813-1.92-2.4c0-0.08,0-0.16,0-0.24c2.179,0.062,3.662,0.818,6,0.72
			c6.105,0.375,11.859,1.101,18,1.44c0.912,0.368,2.161,0.399,3.36,0.48c0.319,0,0.64,0,0.96,0
			C428.445,334.28,430.801,334.875,432.081,334.48z M397.521,460.721c0.65,1.59-0.373,3.423-0.24,5.28
			c-0.33,1.429-0.638,2.882-0.72,4.56c0,0.08,0,0.16,0,0.24c-0.536,0.104-0.097,1.183-0.24,1.68
			c0.007,0.038,0.006,0.071,0.011,0.107c0,0.001,0,0.002,0,0.003c-0.005-0.038-0.004-0.07-0.011-0.11
			c-0.208,0.579-0.733,1.986-0.277,2.578c-0.018,0.165-0.035,0.329-0.053,0.494c-0.003,0.025-0.005,0.051-0.008,0.076
			c-1.633,8.101-1.452,19.57,0.099,27.572c-0.566,0.996,0.139,2.5,0.479,3.359c0,0.08,0,0.16,0,0.24
			c-0.044,0.623,0.768,1.607,0.24,1.92c-1.06,0.1-1.854-0.065-2.4-0.479c-0.729-0.791-1.335-1.705-2.16-2.4
			c-0.757,0.117-0.847-0.433-1.68-0.24c-1.322-0.678-1.232-2.767-2.64-3.359c-0.349-0.371-1.229-0.212-1.92-0.24
			c-1.105-0.815-3.022-0.818-4.08-1.681c-1.356-0.163-2.277-0.762-3.601-0.96c-1.765-0.555-3.553-1.087-5.279-1.68
			c-1.179-0.901-3.159-1.001-4.561-1.68c-2.065-0.335-3.643-1.157-5.52-1.681c-1.223-0.857-3.262-0.898-4.32-1.92
			c0.323-2.236,0.45-4.67,0.48-7.199c0-0.08,0-0.16,0-0.24c0.554-0.806,0.084-2.636,0.239-3.84c0-0.24,0-0.48,0-0.721
			c0.558-1.282,0.083-3.598,0.24-5.279c0-1.28,0-2.561,0-3.841c0.398-0.649,0.398-2.95,0-3.6c0-0.479,0-0.96,0-1.44
			c-0.154-1.045,0.313-2.713-0.24-3.359c0-0.08,0-0.16,0-0.24c-0.384-3.536-0.76-7.08-1.439-10.32c0-0.08,0-0.159,0-0.239
			s0-0.16,0-0.24c-0.458-0.103-0.435-0.686-1.2-0.48c-0.003,0.159-0.006,0.324-0.009,0.484c-0.148,0.247-0.186,0.687-0.172,1.203
			c0,0.003,0,0.005,0,0.008c0.003,0.124,0.009,0.252,0.018,0.383c0,0.007,0,0.014,0.001,0.021c0.008,0.129,0.018,0.259,0.029,0.391
			c0,0.009,0.001,0.018,0.002,0.025c0.023,0.277,0.051,0.556,0.075,0.821c-0.146,10.337-0.083,22.65-0.904,33.385
			c0,0.08,0,0.159,0,0.239c-0.553,0.647-0.085,2.315-0.24,3.36c-1.004-0.116-1.778-0.461-2.64-0.72
			c-1.452-0.948-3.564-1.236-5.28-1.92c-2.065-0.335-3.643-1.157-5.52-1.681c-4.922-1.062-8.163-1.734-14.16-0.72
			c-0.241-0.318-0.228-0.893-0.721-0.96c-1.13-1.59-3.058-2.382-4.08-4.08c1.064-1.815,3.427-2.333,3.601-5.04
			c0.562-0.892,0.158-2.871,0-3.84c-0.332-1.028-0.769-1.952-1.2-2.88c-0.699-0.9-1.499-1.7-2.4-2.4
			c-0.499-0.141-0.775-0.504-1.199-0.72c-1.549-1.296-4.992-1.126-6.721-0.96c-0.107-1.729-2.559,0.051-2.64-0.24
			c-0.411-0.948-0.877-1.843-1.2-2.88c0.123-0.929-0.991-2.684-1.68-3.6c-0.718-1.813-2.487-2.562-1.681-5.28
			c0.146-0.014,0.285-0.036,0.24-0.24c0.766,0.275,0.671,1.409,1.2,1.92c0.589-1.893,1.851-4.387,1.92-6
			c0.438-1.961,1.146-3.654,1.681-5.52c0.444-1.878,1.757-4.16,1.68-5.761c0.467-1.771,1.223-3.257,1.68-5.039
			c0.693-1.949,1.827-4.73,1.92-6.48c0.58-1.82,1.11-3.689,1.681-5.52c0.973-1.588,1.254-3.866,1.92-5.76
			c0.15-1.85,0.928-3.073,1.439-4.561c0.459-2.104,1.799-4.599,1.681-6.48c0.552-2.248,1.21-4.39,1.68-6.72
			c0.856-2.265,1.703-5.814,1.92-7.92c0.391-2.409,1.095-4.505,1.44-6.96c1.297-5.663,1.55-13.65,1.92-19.44
			c-0.075-6.144,2.238-13.971,0-19.2c0.763-0.836,0.964-2.236,1.439-3.36c0.658-1.507,0.853-3.11,2.4-1.44
			c0.907,0.053,1.165,0.755,1.92,0.96c1.394,1.006,3.492,1.308,5.28,1.92c2.692,0.267,4.537,1.382,7.439,1.44
			c5.183,0.818,11.411,0.589,16.32,1.68c3.384,0.216,5.758,1.442,8.88,1.92c1.569,0.751,3.526,1.113,5.28,1.68
			c1.771,0.666,3.422-0.043,3.84-1.44c-0.484-1.756-1.917-2.563-3.12-3.6c-0.757-1.003-1.982-1.538-3.12-2.16
			c-0.802-0.398-1.598-0.802-2.399-1.2c-1.291-1.109-3.05-1.75-4.561-2.64c-0.656-0.223-1.023-0.736-1.68-0.96
			c-0.979-1.021-2.266-1.735-3.36-2.64c-0.08,0-0.16,0-0.24,0c-2.137-2.184-5.461-4.457-6.72-6.72
			c7.057,0.143,13.744,0.656,20.4,1.2c3.445,0.154,6.287,0.913,9.359,1.44c1.489,0.991,4.908,0.052,5.28,2.16
			c0.384,0.896,1.493,1.067,1.68,2.16c0.723,0.958,1.3,2.061,2.16,2.88c0.492,1.108,1.183,2.018,1.44,3.36
			c0.37,1.229,0.565,2.634,0.72,4.08c0.018,1.022,0.401,1.679,0.24,2.88c0.597,0.363-0.147,2.067,0.479,2.4
			c0.37,5.63,1.174,10.826,1.681,16.32c-0.399,0.729-0.399,3.112,0,3.84c0,0.08,0,0.16,0,0.24c0.158,2.321-0.318,5.278,0.239,7.2
			c0,0.08,0,0.16,0,0.24c0.159,1.922-0.317,4.478,0.24,6c-0.001,4.161,0.198,8.122,0.721,11.76c0.223,3.536,0.294,7.226,0.96,10.32
			c0.11,4.529,0.854,8.426,0.96,12.96c0.099,3.74,0.365,7.313,0.239,11.279c0,0.08,0,0.16,0,0.24
			c-0.549,0.411-0.088,1.832-0.239,2.641c0.051,4.051-1.074,6.926-1.681,10.319c-1.52,1.521-1.546,4.534-3.12,6
			c-0.422,0.139-1.409-0.29-1.439,0.24c-0.92,0.521-1.249,1.631-1.2,3.12c-0.385,1.136-0.198,2.842-1.2,3.36
			C396.869,457.455,397.565,459.255,397.521,460.721z M396.065,474.842c-0.006-0.288,0.032-0.533,0.087-0.76
			C396.099,474.309,396.061,474.553,396.065,474.842z M396.07,474.806c-0.002,0.013-0.003,0.025-0.005,0.038v-0.001
			C396.066,474.831,396.068,474.817,396.07,474.806z M396.153,474.079c0-0.002,0.001-0.003,0.001-0.005
			c-0.003,0.028-0.007,0.056-0.01,0.084C396.147,474.132,396.15,474.105,396.153,474.079z M174.32,508.721c-0.16,0-0.32,0-0.48,0
			c0.608-0.831,1.954-0.926,2.64-1.68c1.022-0.818,2.304-1.376,3.12-2.4c0.75-0.29,1.044-1.036,1.92-1.2
			c1.144-0.855,2.444-1.555,3.36-2.64c1.585-1.215,2.981-2.618,4.8-3.6c1.337-1.144,2.948-2.013,4.08-3.36
			c0.566-0.074,0.447-0.834,1.2-0.72c0.964-0.796,2.093-1.427,2.88-2.4c0.783-0.657,1.631-1.249,2.4-1.92
			c0.977,1.184,2.657,1.663,4.32,2.16c0.259,0.397,2.141,0.397,2.4,0c1.488-0.191,2.78-0.58,4.08-0.96
			c0.933-0.587,1.846-1.193,2.88-1.681c0.262,4.378,0.688,8.592,0.96,12.961c0.336,4.223,1.029,8.091,1.68,12
			c0.411,3.029,1.291,5.589,1.68,8.64c0.23,3.29,1.25,5.79,1.68,8.88c0.786,2.254,1.132,4.948,1.68,7.44
			c0.276,2.203,1.134,3.826,1.68,5.76c0.701,1.379,1.391,2.769,1.92,4.32c0.348,1.252,0.883,2.317,1.68,3.12
			c0.239,1.04,1.233,1.326,1.44,2.399c0.786,1.294,1.55,2.61,2.64,3.601c0.149,0.65,1.002,0.597,0.96,1.439
			c0.94,1.3,1.855,2.625,3.12,3.6c0,0.08,0,0.16,0,0.24c0.859,1.061,1.819,2.021,2.88,2.88c0.214,0.106,0.429,0.211,0.48,0.48
			c0.69,1.07,1.869,1.651,2.88,2.399c1.156,0.284,1.804,1.077,2.88,1.44c0,0.08,0,0.16,0,0.24c0.56,0,1.12,0,1.68,0
			c0.671,1.088,1.141,2.38,1.68,3.6c0.669,1.251,1.128,2.712,1.68,4.08c0.1,1.583,1.246,4.192,1.92,6
			c0.271,1.889,1.064,3.256,1.44,5.04c-0.062,1.824,1.367,4.231,1.92,6.24c0.528,1.071,1.036,2.164,1.44,3.36
			c-0.118,0.757,0.433,0.847,0.24,1.68c-0.601-0.041-0.751,0.368-0.96,0.72c-0.601-0.041-0.751,0.369-0.96,0.72
			c-1.03,0.811-1.882,1.798-2.88,2.641c-0.106,0.213-0.21,0.429-0.48,0.479c-2.546,2.494-5.465,4.615-7.92,7.2
			c-0.08,0-0.16,0-0.24,0c-1.323,1.236-2.94,2.18-4.08,3.601c-0.08,0-0.16,0-0.24,0c-1.237,1.002-2.617,1.862-3.6,3.119
			c-0.194,0.046-0.239,0.241-0.48,0.24c-0.722,0.719-1.903,0.978-2.16,2.16c-1.1,0.42-1.674,1.366-2.64,1.92
			c-0.766,0.755-1.789,1.252-2.4,2.16c-0.651,0.149-0.598,1.003-1.44,0.96c-1.043,0.877-2.299,1.541-3.12,2.64
			c-0.928,0.518-2.177,1.977-2.88,1.92c-0.054-2.185-0.615-3.864-0.72-6c-0.263-3.977-1.17-7.31-1.92-10.8
			c-0.742-3.099-1.207-6.474-1.68-9.84c-0.518-2.362-0.591-5.169-1.92-6.72c-0.833-3.087-5.818-2.022-8.4-3.36
			c-1.884-0.437-3.156-1.484-4.8-2.16c-2.029-1.251-3.344-3.216-5.04-4.8c-0.455-0.985-1.243-1.637-1.92-2.4
			c-0.305-0.814-1.331-0.908-1.44-1.92c-0.811-0.949-1.193-2.326-2.16-3.12c-0.371-1.068-1.214-1.665-1.44-2.88
			c-0.884-1.115-1.269-2.731-2.16-3.84c-0.563-1.117-1.34-2.02-1.68-3.359c-0.426-1.254-0.985-2.375-1.68-3.36
			c-0.443-0.997-1.032-1.848-1.2-3.12c-0.804-1.677-1.208-3.752-2.16-5.28c-0.382-1.384-1.142-4.02-1.68-6
			c0.03-2.271-1.338-5.22-1.68-7.92c-0.703-2.418-1.255-4.985-1.44-7.92c-0.793-5.126-0.318-11.521-1.2-16.56
			C175.292,517.377,173.943,511.716,174.32,508.721z M199.28,365.681c0.576,1.023,1.293,1.906,1.68,3.12
			c0.477,1.363,1.067,2.612,1.92,3.6c0.47,0.89,0.851,1.869,1.44,2.64c0.526,1.314,0.995,2.686,1.92,3.6
			c1.417,2.743,3.24,5.08,4.56,7.92c0.877,1.043,1.487,2.354,2.16,3.6c0.303,1.253,1.392,3.269,1.92,5.04
			c0.269,1.571,1.137,2.543,1.68,3.84c1.35,2.97,3.266,5.374,4.8,8.16c0.784,1.056,1.357,2.322,2.4,3.12
			c0.149,0.65,1.003,0.597,0.96,1.44c0.914,0.926,1.564,2.116,2.64,2.88c1.604,2.156,3.437,4.083,5.04,6.24
			c-1.535,2.994-3.865,5.377-6.48,1.68c-0.736-1.344-2.226-1.935-3.6-2.641c-1.043-0.637-2.042-1.316-3.36-1.68
			c-1.449-0.643-3.589,0.087-4.32,0.961c-0.533,0.267-0.65,0.949-1.2,1.199c-0.952,0.568-1.426,1.615-2.16,2.4
			c-0.331,1.109-1.055,1.824-1.68,2.64c-0.812,1.028-1.139,2.542-1.92,3.601c-0.97,1.35-1.349,3.291-2.64,4.32c0-0.16,0-0.32,0-0.48
			c-0.351-1.329-0.915-2.445-1.44-3.6c-0.578-1.743-1.224-3.418-1.68-5.281c-0.517-2.122-1.179-4.1-1.92-6
			c-0.404-2.156-1.073-4.047-1.44-6.239c-0.509-2.771-1.101-5.46-1.92-7.92c-0.458-3.303-1.534-5.986-1.92-9.36
			c-0.397-1.762-0.537-3.783-1.2-5.28c-0.627-3.469-1.561-7.595-1.92-12c-0.314-4.006-1.164-7.477-1.68-11.28
			c-0.271-0.768-0.488-1.592-0.48-2.64c0.507-0.213,0.709-0.73,1.44-0.72c0.85-0.51,1.925-0.795,2.64-1.44c0.48,0,0.96,0,1.44,0
			c0.895,0.625,1.849,1.191,2.64,1.92c0,0.08,0,0.16,0,0.24C198.175,364.066,198.626,364.975,199.28,365.681z M236.24,409.601
			c0.735,0.446-0.015,1.402-0.48,1.44c-0.204-0.044-0.226,0.094-0.24,0.24c-1.197,1.676,2.864,2.043,2.64,0.24
			c0.359-2.278-0.491-3.349-1.44-4.32c-0.188-0.624-1.476-1.389-2.16-0.72c-0.309,0.412-0.497,0.944-1.2,0.96
			c-0.109-1.341-0.275-1.85,0-3.12c1.236-0.117,2.058,0.182,2.4,0.96c0.455,0.985,1.243,1.637,1.92,2.4
			c0.917,0.764,1.405,1.956,1.68,3.36c0,0.787,0,1.575,0,2.362c-0.109,0.1-0.233,0.187-0.372,0.261
			c-0.002,0.001-0.004,0.003-0.006,0.004c-1.862-0.061-3.402,0.122-4.183-1.427c0.039-0.279-0.053-0.427-0.24-0.48
			c-0.021-0.86-0.374-1.387-0.48-2.16c0.453,0.027,0.167,0.793,0.72,0.72C235.152,410.887,236.653,410.592,236.24,409.601z
			 M239.84,421.841c1.171,0.269,2.184,0.696,3.12,1.2c0.414,0.625,0.863,1.217,1.92,1.199c1.363,0.637,2.35,1.65,3.6,2.4
			c0.996,0.844,1.837,1.843,3.36,2.16c0.599,0.121,0.986,0.453,1.44,0.72c0.789,0.892,2.021,1.339,3.36,1.681
			c0.999,0.92,2.455,1.385,3.12,2.64c-1.25-0.51-2.083-1.437-3.6-1.681c-0.479-0.569-2.025-1.324-2.88-0.72
			c-1.5-0.14-2.142,0.579-3.12,0.96c-1.674,0.807-3.055,1.906-4.56,2.881c-0.933,0.747-1.924,1.436-2.88,2.159
			c-0.204-0.044-0.226,0.094-0.24,0.24c-0.177-0.251,0.157-1.943-0.48-2.399c-0.086-0.635-0.577-0.863-1.44-0.721
			c-1.094,0.352-1.587,2.566-1.44,3.36c-0.107,1.013-0.946,1.294-0.72,2.64c-5.011,2.109-8.973,5.268-14.88,6.48
			c-1.713,0.529-3.438,2.318-2.64,4.56c0.753,3.646,3.489,5.312,6.48,6.72c-0.083,0.717-1.147,0.453-0.72,1.681
			c-0.812,1.027-1.499,2.182-2.4,3.12c-2.127,1.713-4.637,3.042-7.68,3.84c-0.917,0.037-1.748-0.013-2.16-0.48
			c0.187-0.053,0.279-0.201,0.24-0.479c1.118-1.558,5.044-4.731,5.04-7.681c-0.002-1.748-1.854-4.163-4.08-2.399
			c-0.912,1.241,0.576,2.137,1.2,2.64c-0.681,0.839-1.522,1.518-1.68,2.88c-0.233,0.007-0.186,0.294-0.24,0.48
			c-0.98,0.779-1.86,1.659-2.64,2.64c-1.194-0.018-0.157,0.706-0.72,1.2c-0.917,0.037-1.748-0.013-2.16-0.48
			c-0.617-1.511,1.036-1.719,1.44-2.88c0.514-0.926,1.295-1.584,1.92-2.399c0.127-0.193,0.25-0.39,0.24-0.721
			c0.917-0.923,1.959-1.721,2.16-3.359c0.449-0.511,0.392-1.528,0.96-1.92c0.724-0.877,1.09-2.109,1.68-3.12
			c0.352-0.849,1.066-1.334,0.96-2.641c1.456-2.225,1.094-6.266,3.36-7.68c0.723-0.797,1.148-1.893,1.92-2.64
			c0.324-0.235,0.479-0.641,0.72-0.96c0.427-0.613,1.032-1.049,1.2-1.92c2.436-3.484,5.176-6.664,7.44-10.32
			c0.794-0.406,1.107-1.293,1.68-1.92c0.101-0.539,1.273-0.007,1.44-0.48C237.676,422.236,238.38,421.66,239.84,421.841z
			 M271.28,426.881c0.305,0.975,1.062,1.498,1.44,2.4c0.947,1.452,2.672,2.127,3.6,3.6c-1.048,0.008-1.872-0.209-2.64-0.48
			c-1.92-0.982-5.771-1.332-7.44,0c-0.717,0.101-1.775,2.092-2.16,0.961c-0.773-1.068-1.555-2.126-2.64-2.881
			c-0.632-0.248-0.808-0.951-1.44-1.199c-1.319-0.762-2.567-1.594-4.08-2.16c-0.916-0.285-1.521-0.879-2.4-1.2
			c-0.94-1.221-2.77-1.551-3.84-2.64c-0.908-0.053-1.166-0.755-1.92-0.961c-0.021-1.499,0.334-3.374-0.96-3.6
			c-0.151-6.872,0.156-13.284,1.44-18.72c0.262-2.938,1.256-5.144,1.68-7.92c0.344-1.976,1.025-3.614,1.68-5.28
			c0.625-1.695,1.297-3.343,1.68-5.28c0.56-1.84,1.09-3.709,1.68-5.52c0.206-1.474,0.685-2.675,1.68-3.36
			c1.095,1.225,2.225,2.415,2.64,4.32c0.567,3.513,0.606,7.554,1.68,10.56c0.311,2.809,1.196,5.044,1.68,7.68
			c0.465,1.854,0.848,3.792,1.68,5.28c0.525,2.195,1.259,4.182,1.92,6.24c-0.034,1.876,1.247,4.512,1.68,6.72
			c0.623,2.258,1.138,4.622,1.68,6.96C269.439,422.324,270.43,425.17,271.28,426.881z M232.641,448.961
			c-0.584-0.568-2.276-0.209-2.4,0.479c-1.073,0.688-1.846,1.675-3.6,1.681c-1.494-0.334-2.65,1.243-2.88-0.48
			c1.21-0.95,2.828-1.492,4.56-1.92c1.527,0.008,2.527-0.514,3.36-1.2c0.671-0.368,1.354-0.726,1.92-1.2
			c0.785-0.095,1.551-0.208,1.68-0.959c0.986-0.055,1.572-0.509,2.16-0.961c3.139-1.98,6.287-3.953,9.36-6
			c0.857-0.103,1.317-0.603,1.68-1.199c1.408-0.593,2.479-1.521,3.6-2.4c3.959-0.039,6.177,1.663,8.16,3.6
			c-1.646,1.873-3.563,3.478-5.52,5.04c-2.195,0.765-3.335,2.585-5.28,3.601c-2.083,1.197-3.673,2.887-5.52,4.319
			c-2.105,1.735-4.018,3.663-6.96,4.561c-1.523,0.589-3.099,0.581-4.56,0.72c-1.57-0.189-3.335-0.186-4.08-1.2
			c-0.299-0.58-0.983-0.776-1.68-0.96c-0.233-0.006-0.187-0.294-0.24-0.479C229.278,453.407,233.937,452.141,232.641,448.961z
			 M217.505,492.217c0.188,1.96,0.435,3.893,0.59,5.524C217.938,496.109,217.692,494.176,217.505,492.217
			c-0.204-2.131-0.338-4.294-0.204-6.141C217.167,487.924,217.301,490.086,217.505,492.217z M217.041,478.48
			c-0.788,0.893-1.614,1.746-1.92,3.12c-2.008,3.87-6.486,7.154-12.48,5.521c-0.049-0.751,0.162-1.762-0.24-2.16
			c0.787-2.391,2.215-3.494,2.64-5.76c0.763-1.323,1.531-3.355,3.6-2.641c1.535,1.84,0.082,4.396-0.72,6
			c-0.233,0.007-0.186,0.294-0.24,0.48c-0.819,0.54-0.868,1.852-1.92,2.16c0.668-0.108-0.188,1.308,0.48,1.199c0.08,0,0.16,0,0.24,0
			c0.49,1.143,3.513,0.257,2.64-0.72c1.27-1.61,2.195-3.565,3.12-5.52c0.204,0.044,0.227-0.094,0.24-0.24
			c0.579-0.987,0.131-3.005-0.48-3.601c-0.16,0-0.32,0-0.48,0c0.195-1.395-1.136-1.264-1.68-1.92c-0.641,0.001-0.521-0.758-1.2-0.72
			c0.005-1.596,0.791-2.409,0.72-4.08c0.777,0.343,1.636,0.604,2.64,0.72c1.427,0.496,4.349,0.768,6,0.24
			c0.904,0.185,1.147-0.293,1.92-0.24C219.633,473.713,217.697,475.458,217.041,478.48z M217.041,440.561
			c0.292-1.388,0.944-2.416,1.2-3.84c0.356-0.534,0.89-2.136,0.72-2.4c0.191-0.768,0.709-1.211,0.72-2.16
			c0.685-0.595,1.456-1.104,2.16-1.68c1.746-0.496,3.688-2.069,5.28-1.92c-0.983,0.617-1.412,1.789-2.16,2.641
			c-0.698,1.301-1.771,2.229-2.4,3.6c-0.381,0.738-1.166,1.074-1.44,1.92c-1.279,0.961-2.08,2.4-2.88,3.84
			c-0.953,0.008-0.986,0.934-1.2,1.68C216.329,441.893,217.356,441.305,217.041,440.561z M202.88,456.4
			c2.183-1.408,1.31,3.447,1.92,3.601c0.385,0.297,2.094,1.417,2.16,0.479c1.361-1.239,0.668-5.058-0.48-6
			c-0.029-0.61-0.35-0.931-0.96-0.96c-0.111-0.701-1.923-0.941-2.16-0.72c-0.386-2.845,0.171-5.845,0.48-8.4
			c0.547-0.813,1.186-1.533,1.44-2.64c0.487-0.153,0.158-1.122,0.72-1.2c0.536-0.664,0.934-1.466,1.2-2.4
			c0.611-1.708,1.765-2.875,2.16-4.799c0.08,0,0.16,0,0.24,0c-0.108-1.229,1.01-1.23,0.96-2.4c0.646-0.635,0.998-1.563,1.2-2.641
			c0.765-0.274,0.671-1.408,1.2-1.92c0.625-0.895,1.191-1.849,1.92-2.64c0.84-1,1.706-1.974,2.88-2.64
			c2.852,1.148,5.266,2.733,7.2,4.8c-1.383,0.537-2.74,1.1-4.08,1.68c-0.696,0.184-1.38,0.38-1.68,0.96
			c-2.196,1.084-2.535,4.025-3.6,6.24c-0.376,0.593-1.16,2.028-0.72,2.64c-0.504,1.735-1.08,3.399-1.44,5.28
			c-0.407,0.633-0.711,1.369-0.72,2.4c-0.719,0.8-0.846,2.194-1.2,3.359c-0.105,0.707-0.916,1.948-0.48,2.4
			c-0.29,0.75-0.836,1.244-0.96,2.16c-0.474,0.566-0.565,1.515-0.72,2.399c-0.404,1.116-0.983,2.057-1.2,3.36
			c-1.147,2.133-1.731,4.829-2.64,7.2c-0.492,0.468-0.309,1.611-0.72,2.16c-0.24,0-0.48,0-0.72,0
			c-2.214-2.002-1.009-6.482-1.44-10.561C203.161,457.642,202.749,456.749,202.88,456.4z M204.081,472.241c0,0.159,0,0.319,0,0.479
			c-0.364,0.596-0.463,1.457-0.48,2.4c-0.276,0.283-0.272,0.848-0.24,1.439c-1.271,0.249-1.304,1.736-1.92,2.641
			c-0.414,0.555-1.197,1.99-0.72,2.64c-0.147,0.732-1.077,0.683-0.72,1.92c-0.279-0.039-0.427,0.053-0.48,0.24
			c-1.213,0.253-1.373-0.547-2.4-0.48c-0.813-0.547-1.534-1.186-2.64-1.439c-1.117-0.884-2.368-1.632-3.12-2.88
			c-0.623-1.938-1.424-3.696-1.92-5.761c-0.177-1.137,0.27-1.649,0.24-2.64c1.216-0.502,0.455-1.705,1.2-2.4
			c0.849,0.129,1.236-0.203,1.44-0.72c0.747-0.197,3.012-0.668,3.36,0.24c0.768,0.771,2.573,0.012,2.88,0.72
			c1.018,0.822,1.804,1.876,3.12,2.4C202.287,471.634,202.885,472.236,204.081,472.241z M255.92,567.281
			c-0.221,1.379-1.025,2.175-0.96,3.84c-0.571-1.453-1.73-4.986-0.48-6c1.137-0.943,1.553-2.607,2.16-4.08
			c0.514-1.646,1.333-2.987,1.44-5.04c0.991-3.374,1.248-8.61,1.2-12.24c0.315,0.245,0.885,0.235,1.2,0.48c0.08,0,0.16,0,0.24,0
			c0.305,5.061,0.157,12.24-1.44,16.8c-0.526,1.474-1.333,2.666-2.16,3.84c-0.08,0-0.16,0-0.24,0
			C256.603,565.723,255.936,566.177,255.92,567.281z M259.28,540.161c-0.22-0.728-3.069-1.332-3.12,0
			c-0.127,0.192-0.25,0.39-0.24,0.72c-0.867,0.093-1.602,0.318-2.16,0.72c-1.787,0.693-3.575,1.385-5.04,2.4
			c-1.363,0.478-2.396,1.283-3.36,2.16c-0.907,0.053-1.166,0.754-1.92,0.96c-1.135,0.625-2.315,1.205-3.36,1.92
			c-1.932,1.108-3.772,2.308-5.76,3.359c-0.769,0.111-0.846,0.914-1.68,0.961c-0.111-0.77-0.914-0.847-0.96-1.681
			c0.69,0.05,1.219-0.061,1.44-0.479c1.094-0.426,2.008-1.031,2.4-2.16c1.38-0.22,2.511-0.688,3.6-1.2
			c2.979-0.941,3.578-4.262,6-5.76c-0.015-0.812,0.433-1.024-0.24-1.44c-0.322-1.227-3.017-0.876-2.88,0.48
			c-0.671,0.688-1.065,1.654-1.92,2.16c-0.627,0.893-1.465,1.575-2.4,2.16c-1.535,0.225-2.47,1.05-4.08,1.199c-0.8,0-1.6,0-2.4,0
			c-0.08,0-0.16,0-0.24,0c-0.259-0.701-2.141,0.222-2.4-0.479c-0.812-1.509-1.417-3.224-2.16-4.8
			c-0.006-1.434-0.715-2.166-0.96-3.36c0.223-1.028-0.541-3.136-0.96-4.32c-0.521,0.041-0.109-0.851-0.24-1.2
			c-0.155-1.765-0.559-3.28-0.96-4.8c-0.186-2.373-1.064-4.056-1.2-6.479c0.014-1.055-0.097-1.984-0.48-2.641
			c0.018-0.897-0.101-1.659-0.48-2.16c-0.164-0.285-0.991-1.755,0-1.68c0.867,1.213,2.013,2.147,3.12,3.12
			c0.521,0.359,0.559,1.201,1.44,1.2c0.608,0.672,1.659,0.901,2.64,1.2c0.205-1.145-0.513-3.284-1.68-3.841
			c-0.902-0.138-0.499-1.581-1.44-1.68c0.045-1.165-1.401-0.839-1.44-1.92c-0.754-0.766-1.251-1.788-2.16-2.4
			c-1.272-1.927-2.485-3.915-2.88-6.72c0.187-1.547-0.356-2.363-0.24-3.84c-1.028-4.468-0.434-10.558-0.937-15.551
			c0.006-0.029,0.015-0.056,0.021-0.084c0.035-0.154,0.071-0.308,0.113-0.453c0,0,0,0,0,0c0.091-0.313,0.197-0.606,0.323-0.874
			c-0.124,0.27-0.232,0.56-0.322,0.874c0.402-0.503,1.098-0.712,1.042-1.672c0.537-0.664,0.934-1.466,1.2-2.4
			c1.144-1.016,1.41-2.91,1.92-4.56c0.591-0.93,0.946-2.094,1.2-3.36c0.166,0.669,2.494,0.79,2.64,0
			c0.782-0.658,0.757-2.123,0.72-3.6c-0.064-0.864,0.443-1.157,0.48-1.921c0.464-0.815,0.793-1.767,1.44-2.399
			c0.199-0.601,0.658-0.942,0.72-1.68c0.3-0.5,0.588-1.013,0.96-1.44c6.197,0.357,9.611-2.068,12.72-4.8
			c0.752-0.208,1.326-0.595,1.68-1.2c0.309-0.411,0.496-0.943,1.2-0.96c1.521-0.959,2.923-2.037,4.08-3.36
			c3.524-2.635,6.792-5.527,10.08-8.4c1.126-0.152,1.136-1.424,2.4-1.439c0.08,0,0.16,0,0.24,0c0.415-1.025,1.036-1.844,1.92-2.4
			c0.284,0.357,0.208,1.072,0.96,0.961c0.887,0.232,1.378,0.861,2.64,0.72c0.074,0.325,0.601,0.198,0.96,0.239
			c0.519-1.021,0.077-2.237-1.2-2.159c-0.681,0.04-0.469-0.812-1.44-0.48c0.172-0.651-1.131,0.172-0.96-0.479
			c0.661-0.459,1.221-1.02,1.68-1.68c0.416-0.305,0.629-0.812,1.44-0.721c0.96,0,1.92,0,2.88,0c1.213-0.253,1.373,0.547,2.4,0.48
			c0.003,0.396,0.666,0.134,0.72,0.479c1.136,0.064,1.352,1.049,2.16,1.44c0.523-0.044,0.764,0.196,0.72,0.72
			c0.403,0.958,1.549,1.171,1.68,2.399c-0.774,0.187-1.418,0.502-1.92,0.961c-0.703,0.178-1.18,0.58-1.68,0.959
			c-0.319,0.242-0.893,0.228-0.96,0.721c-0.825,0.615-1.59,1.289-1.92,2.4c-0.5,0.459-1,0.92-1.2,1.68
			c-0.98,0.779-1.86,1.659-2.64,2.64c-0.477,0.164-0.549,0.731-0.96,0.96c-0.622,0.738-1.044,1.676-1.92,2.16
			c-0.9,0.699-1.7,1.5-2.4,2.4c-0.217,0.423-0.537,0.743-0.96,0.96c-1.509,1.451-3.35,2.57-4.8,4.08
			c-0.901,0.618-1.422,1.617-2.64,1.92c-1.013,0.027-1.37,0.71-1.92,1.2c-1.274,0.646-2.484,1.355-3.6,2.159
			c-1.482,0.438-2.648,1.192-3.84,1.921c-0.716,0.003-0.816,0.624-1.44,0.72c-1.333,0.898,0.359,3.223,1.68,2.16
			c5.918-3.123,11.928-6.152,16.56-10.561c3.1-2.5,5.9-5.3,8.4-8.399c0.023-0.617,0.937-0.344,0.96-0.96
			c0.729-0.712,1.494-1.387,1.92-2.4c1.111-0.809,1.421-2.419,2.64-3.12c2.634-1.446,4.945-3.215,7.68-4.561
			c1.106-0.253,1.827-0.893,2.64-1.439c0.34,0.021,0.68,0.039,0.72-0.24c1.177,0.184,1.774,0.946,2.88,1.2
			c0.38,0.581,1.039,0.882,1.44,1.44c0.632,0.248,0.808,0.951,1.44,1.199c0.039,1.32,1.551,1.17,1.439,2.641
			c0.639,0.721,1.09,1.63,1.68,2.399c-0.192,1.233,1.079,1.002,0.721,2.4c0.149,1.67-1.26,1.78-1.92,2.64
			c-0.996,0.685-1.655,1.705-2.641,2.4c0,0.08,0,0.16,0,0.24c-1.211,0.869-2.134,2.025-3.12,3.12c-0.9,0.699-1.7,1.499-2.4,2.399
			c-0.384,0.735-1.274,0.966-1.44,1.92c-1.269,0.892-2.063,2.257-3.12,3.36c-0.342,0.058-0.405,0.396-0.72,0.479
			c-0.013,0.308-0.308,0.333-0.24,0.721c-0.631,0.568-1.447,0.953-1.68,1.92c-0.106,0.214-0.211,0.429-0.48,0.479
			c-2.18,1.98-4.26,4.061-6.24,6.24c-0.204-0.044-0.227,0.094-0.24,0.24c-1.05,0.311-1.509,1.211-2.16,1.92
			c-0.106,0.214-0.211,0.429-0.48,0.48c-1.213,0.467-1.826,1.534-2.64,2.399c-0.106,0.214-0.211,0.43-0.48,0.48
			c-1.31,0.171-2.308,2.253-1.2,3.359c0.854,0.055,1.438-0.161,1.68-0.72c1.192-0.487,1.994-1.365,2.88-2.16
			c1.054-0.626,1.931-1.43,2.64-2.399c3.452-2.868,6.304-6.336,9.6-9.36c1.364-1.116,2.37-2.59,3.6-3.84c0-0.08,0-0.16,0-0.24
			c1.109-0.73,1.744-1.936,2.64-2.88c0-0.08,0-0.16,0-0.24c2.66-2.46,5.22-5.02,7.68-7.68c0.851-0.83,1.92-1.44,2.641-2.4
			c0.738,0.179,0.461-0.658,1.199-0.479c0,0.08,0,0.159,0,0.239c1.484,0.677,2.379,1.941,3.6,2.881
			c0.602,1.158,1.3,2.221,2.16,3.119c-0.132,0.532,0.459,0.342,0.48,0.721c0.47,1.93,1.525,3.274,2.4,4.8
			c0.206,1.714,1.174,2.666,1.68,4.08c-1.088,0.832-1.887,1.953-2.641,3.12c-1.037,0.562-1.865,1.334-2.64,2.16
			c-0.896,0.864-3.159,0.36-3.84,1.439c-0.867,0.253-1.329,0.911-1.92,1.44c-0.9,0.699-1.7,1.499-2.399,2.399
			c-1.012,0.669-1.855,1.506-2.641,2.4c-1.422,1.059-2.418,2.541-3.84,3.6c-0.941,1.459-2.618,2.183-3.36,3.84
			c-0.327,0.634-0.949,0.971-1.44,1.44c-1.024,0.976-2.255,1.745-2.88,3.12c-0.106,0.214-0.211,0.429-0.48,0.479
			c-0.999,0.762-1.828,1.692-2.4,2.881c-0.359,0.36-0.43,1.01-0.96,1.199c-1.623,0.785-1.46,4.639,0.96,3.601
			c0.79-0.25,1.335-0.744,1.68-1.44c1.462-1.338,2.656-2.944,3.84-4.56c1.342-1.298,2.897-2.383,3.84-4.08
			c1.925-2.075,3.903-4.098,6-6c2.478-1.682,4.177-4.144,6.479-6c1.389,0.028,1.67-1.051,2.641-1.44
			c0.563-0.717,1.711-0.85,2.4-1.439c0.278-0.039,0.427,0.053,0.479,0.239c0.86,1.061,1.819,2.021,2.88,2.881
			c0.295,0.104,0.493,0.307,0.48,0.72c0.486,0.953,0.74,2.14,1.92,2.399c0.422-0.138,1.41,0.291,1.439-0.239c0.08,0,0.16,0,0.24,0
			c0.019-1.059-0.017-2.063-0.72-2.4c-1.069-2.131-2.774-3.625-4.32-5.28c0-0.08,0-0.159,0-0.239c1.734-1.386,3.28-2.96,4.32-5.04
			c0.497-0.144,1.576,0.296,1.68-0.24c1.059-0.063,1.856-0.384,3.12-0.24c2.668,0.053,3.828,1.612,4.56,3.601
			c-0.718,0.161-0.155,1.604-0.96,1.68c-0.809-2.15-2.324-3.596-4.319-4.561c-0.871-0.698-2.188,0.203-1.92,1.44
			c0.806,0.874,1.921,1.438,2.88,2.16c0.359,0.36,0.429,1.011,0.96,1.2c0,0.159,0,0.319,0,0.479c0.725,1.035,0.548,2.972,2.64,2.641
			c-0.383,0.656-0.896,1.184-1.2,1.92c-0.8,1.279-1.804,2.355-2.64,3.6c-0.249,1.271-1.281,1.759-1.68,2.88
			c-1.273,0.087-1.341,1.379-1.681,2.4c-0.671,0.769-1.263,1.616-1.92,2.399c-0.703,0.017-0.892,0.549-1.2,0.96
			c-1.745,0.895-3.372,1.907-4.56,3.36c-0.08,0-0.16,0-0.239,0c-0.799,0.642-1.896,0.985-2.4,1.92
			c-1.096,0.665-1.604,1.916-2.881,2.4c-1.108,0.171-1.587,0.972-2.16,1.68c-0.213,0.106-0.429,0.211-0.479,0.48
			c-0.755,0.524-1.323,1.236-1.68,2.159c-0.528,0.753-1.145,1.416-1.2,2.641c-0.233,0.007-0.186,0.294-0.24,0.479
			c-0.529,0.03-0.102,1.019-0.24,1.44c-0.547-0.067-0.902,0.058-0.959,0.479c-0.513-0.111-0.457,0.343-0.48,0.721
			c-0.627,0.893-0.596,2.443-0.721,3.84c0,0.08,0,0.16,0,0.24c-0.422,0.697-0.547,1.692-0.719,2.64
			c0.005,0.565-0.414,0.706-0.24,1.44c-0.455,0.585-0.516,1.564-0.48,2.64c-0.289,0.751-0.57,1.509-0.72,2.399
			c-2.341,5.979-4.863,11.777-9.12,15.841c-0.711,0.729-1.597,1.282-2.16,2.159c-0.204-0.044-0.226,0.094-0.24,0.24
			c-0.971,0.31-1.45,1.111-2.16,1.681c-0.983,0.057-1.352,0.728-2.4,0.72c-2.08,0-4.16,0-6.24,0
			C265.354,542.727,262.451,541.311,259.28,540.161z M292.4,437.921c-0.004-1.204,0.186-2.215,0.48-3.12
			c0.684-1.08,1.454-3.341,1.439-4.32c0.682-1.879,1.322-3.798,1.92-5.76c0.845-1.396,1.193-3.287,1.68-5.04
			c0.506-1.866,1.344-4.231,1.681-5.52c0.712-1.529,1.271-3.21,1.681-5.04c0.412-1.908,0.99-3.65,1.68-5.28
			c0.59-1.09,1.196-2.164,1.68-3.36c0.273-1.486,1.438-2.082,1.92-3.36c0.862-0.498,1.118-1.602,1.68-2.4
			c0.25-0.871,0.797-1.444,1.2-2.16c0.903-0.856,1.498-2.022,2.16-3.12c0.413-1.027,1.229-1.652,1.68-2.64
			c0.852-0.749,1.371-1.829,1.92-2.88c0.676-1.325,1.16-2.84,2.16-3.84c-0.347,10.693-0.702,21.378-1.439,31.68
			c-0.374,1.467-0.454,3.226-0.48,5.04c0,0.08,0,0.16,0,0.24c-0.552,2.727-0.836,5.723-1.2,8.64c-0.343,3.497-0.85,6.83-1.92,9.601
			c-0.496,1.745-1.646,4.109-1.68,5.52c-0.417,1.902-1.115,3.525-1.68,5.279c-0.307,2.096-1.75,4.328-1.68,6.001
			c-0.041,0.68-0.44,1-0.48,1.68c-0.628,0.067-1.033-0.088-0.961-0.72c-0.754-1.086-1.813-1.867-2.879-2.641
			c-3.059-0.964-3.998-2.566-5.041-5.52c-0.646-1.433-1.258-2.902-2.399-3.84c0-0.08,0-0.16,0-0.24
			C294.545,439.776,293.775,438.545,292.4,437.921z M294.08,526.241c0.189,0.999,0.888-1.245,0.961-1.92
			c0.036-0.764,0.543-1.057,0.479-1.921c0.931-0.988,0.518-3.322,1.2-4.56c1.072,0.128,0.684,1.717,1.439,2.16
			c0.662,1.098,1.257,2.264,2.16,3.12c0.606,1.394,1.552,2.448,2.641,3.359c-0.041,0.602,0.368,0.752,0.72,0.96
			c1.056,1.585,2.142,3.139,3.84,4.08c0.214,0.106,0.429,0.211,0.479,0.48c0.672,0.849,1.463,1.578,2.641,1.92
			c1.791,1.409,4.188,2.212,6.96,2.64c1.223,0.621,4.254,0.702,5.76,0.24c3.215-1.265,7.984-0.976,9.601-3.84
			c0.281-0.039,0.202-0.438,0.239-0.72c-2.006-0.165-4.483,1.274-6.96,1.439c-1.238,0.522-3.412,0.108-5.04,0.24
			c-1.436-0.884-3.586-1.054-5.279-1.68c-1.324-1.236-3.899-1.222-4.8-2.88c-0.867-1.134-2.113-1.889-3.121-2.881
			c0.045-0.204-0.094-0.226-0.239-0.239c-0.462-1.139-1.241-1.96-2.16-2.641c-0.352-0.208-0.761-0.359-0.72-0.96
			c-0.418-0.702-0.709-1.531-1.44-1.92c-0.983-1.416-1.931-2.869-2.88-4.32c-1.958-2.478-3.336-5.639-1.68-9.359
			c0.564-0.636,1.035-1.365,1.439-2.16c1.203,0.002,1.635-0.766,2.16-1.44c1.132-0.388,1.667-1.372,2.4-2.159
			c0.824-0.536,1.352-1.369,2.16-1.921c0.732-0.227,0.752-1.167,1.68-1.199c0.85-0.83,1.977-1.384,2.64-2.4
			c0.861-1.14,2.215-1.785,3.12-2.88c1.35-0.971,2.246-2.394,3.36-3.6c0.08,0,0.16,0,0.239,0c0.371-1.069,1.285-1.596,1.681-2.641
			c2.341-0.395,2.83,3.069,4.8,3.84c1.537,1.343,3.203,2.558,4.8,3.84c0.558,0.163,0.643,0.798,1.2,0.961
			c0.756,0.364,1.171,1.068,2.16,1.199c0.08,0,0.16,0,0.24,0c1.854,1.025,4.014,1.746,6.479,2.16c0.618,0.742,1.639,1.082,2.88,1.2
			c0.193,0.127,0.39,0.25,0.721,0.24c0.659,2.94,1.426,5.773,1.439,9.359c0,2.561,0,5.12,0,7.681c0,0.08,0,0.16,0,0.24
			c-0.397,0.413-0.397,2.466,0,2.88c-0.351,2.049-0.479,4.319-0.72,6.479c-0.276,0.284-0.271,0.849-0.24,1.44
			c-0.362,0.678-0.424,1.656-0.479,2.64c-0.489,1.271-0.883,2.638-1.2,4.08c-1.539,3.981-3.347,7.693-5.521,11.04
			c-0.567,0.712-1.02,1.54-1.439,2.4c-0.097,0.624-0.717,0.724-0.721,1.439c-0.845,0.412-1.01,2.041-2.399,1.2
			c-1.083-0.598-2.267-1.093-3.36-1.68c-0.826-0.693-1.621-1.419-2.88-1.681c-4.691-3.068-9.049-6.472-15.601-7.68
			c-3.35-0.49-6.933-0.747-10.319-1.2c-0.81-0.07-1.64-0.12-2.16-0.479c-1.109-0.57-2.427-0.935-4.08-0.96
			c-1.665-0.175-2.843-0.837-4.56-0.96C290.386,532.786,292.611,529.892,294.08,526.241z M311.36,582.881
			c0.384-1.695,1.184-2.977,1.681-4.56c0.635-2.005,1.512-3.768,1.92-6c0.892-3.747,1.349-6.471,5.279-7.2
			c1.276-0.644,2.56-1.281,3.601-2.16c0.716-0.404,1.289-0.951,2.16-1.2c0.936-0.584,2.02-1.02,2.64-1.92
			c0.702-0.418,1.531-0.709,1.92-1.439c1.139-0.462,1.96-1.241,2.64-2.16c0.08,0,0.16,0,0.24,0c1.177-0.823,1.892-2.108,2.88-3.12
			c0.096-0.704,0.788-0.813,0.96-1.44c0.578-0.782,1.302-1.418,1.681-2.399c0.165-1.034,0.926-1.475,1.199-2.4
			c0.92-1.08,1.593-2.407,2.16-3.84c0.512-1.089,1.232-1.968,1.681-3.12c0.747-1.413,1.375-2.944,1.92-4.56
			c0.176-2.145,1.269-3.372,1.2-5.761c1.81-8.441,2.46-20.599,0.239-29.04c-0.453-1.707-1.122-3.197-1.68-4.8
			c0.1-0.746-0.903-2.452-1.68-3.12c-0.257-0.543-0.545-1.056-0.96-1.439c0.451-0.437,1.776,0.291,2.399,0.479
			c1.1,0.74,2.581,1.1,4.08,1.44c2.107,0.612,4.127,1.313,6.24,1.92c1.018,0.822,2.619,1.061,4.08,1.439
			c2.014,0.626,4.016,1.265,6,1.92c1.4,0.76,3.157,1.163,4.8,1.681c1.968,0.432,3.688,1.111,5.521,1.68
			c1.369,0.79,3.085,1.234,4.8,1.68c1.54,0.3,2.684,0.997,4.319,1.2c0.206,0.755,0.907,1.013,0.96,1.92
			c0.576,1.265,1.041,2.64,1.681,3.84c0.723,1.917,1.473,3.808,1.68,6.24c0.846,6.172,0.556,16.241,0,23.28
			c-0.115,3.484-1.057,6.143-1.439,9.36c-0.655,2.225-1.398,4.362-1.921,6.72c-0.384,1.856-1.219,3.261-1.68,5.04
			c-0.753,1.007-1.352,2.169-1.68,3.6c-0.347,1.254-1.304,1.897-1.68,3.12c-0.938,0.743-1.496,1.864-2.16,2.88
			c-0.143,0.897-0.84,1.241-1.2,1.92c-1.043,0.798-1.616,2.064-2.4,3.12c-0.323,0.877-0.889,1.511-1.439,2.16
			c-0.783,0.657-1.254,1.626-1.92,2.4c-0.28,0.439-0.628,0.813-0.96,1.2c-0.825,0.694-1.431,1.609-2.16,2.399
			c-0.106,0.694-0.506,1.094-1.2,1.2c-0.79,0.729-1.705,1.335-2.4,2.16c-0.66,0.219-1.319,0.439-1.439,1.2
			c-0.79,1.689-1.173,3.786-1.681,5.76c-0.332,2.228-1.174,3.945-1.439,6.24c-1.033,2.166-1.468,4.933-2.16,7.439
			c-0.25,2.07-0.857,3.783-1.44,5.521c-0.723,1.597-1.119,3.52-2.159,4.8c-0.757,0.459-1.983-0.318-3.12-0.24
			c-2.495-0.784-5.238-1.322-7.92-1.92c-2.635-0.245-4.642-1.118-6.96-1.68c-1.954-0.687-3.841-1.439-6-1.92
			c-2.228-0.653-4.49-1.271-6.48-2.16c-0.584-0.696-1.807-0.754-2.64-1.2c-1.769-0.151-2.574-1.267-4.32-1.44
			c-0.989-0.69-2.348-1.012-3.6-1.439c-2.272-0.608-4.205-1.556-6.24-2.4c-0.76-0.92-2.259-1.101-3.601-1.439
			c0.23-0.49,0.49-0.95,0.721-1.44c0.301-1.22,0.82-2.219,1.199-3.359c0.692-1.551,1.818-3.938,1.92-5.28
			c0.408-1.673,1.207-2.954,1.681-4.561C310.118,586.115,311.374,584.099,311.36,582.881z M367.28,315.041
			c3.505-0.415,6.754,0.677,10.561,1.2c3.255,1.015,6.407,1.438,10.32,2.16c2.672,0.368,4.947,1.133,7.68,1.44
			c2.865,0.735,5.905,1.294,8.88,1.92c2.027,0.293,3.632,1.009,5.521,1.44c2.456,0.744,5.175,1.225,7.92,1.68
			c3.278-0.266,3.187,1.386,5.52,1.92c1.385,0.858,3.617,2.14,3.84,3.36c-1.619,0.179-2.493-0.386-4.08-0.24
			c-1.712-0.368-2.505-1.655-3.84-2.4c-0.432-0.769-1.58-0.819-2.16-1.44c-2.378-0.902-5.075-1.485-8.159-1.68
			c-2.809-0.711-5.979-1.061-8.881-1.68c-2.496-0.703-5.165-1.235-7.92-1.68c-3.21-0.15-5.531-1.188-8.64-1.44
			c-2.9-0.86-6.03-1.49-9.36-1.92c-3.164-0.437-6.04-1.16-9.359-1.44C361.918,315.464,365.393,313.869,367.28,315.041z
			 M362.48,319.36c0.08,0,0.16,0,0.24,0c1.296,0.464,2.912,0.608,4.56,0.72c0.498,0.143,1.577-0.297,1.681,0.24
			c0.813,0.387,1.908,0.492,3.12,0.48c0.573,0.146,1.741-0.301,1.92,0.24c1.699,0.54,3.743,0.737,5.76,0.96
			c1.223-0.022,1.897,0.502,3.12,0.48c-0.041,0.521,0.852,0.109,1.2,0.24c0.751,0.369,1.857,0.382,2.88,0.48
			c1.29,0.311,2.845,0.355,4.08,0.72c0.788,0.412,1.765,0.635,2.88,0.72c1.151,0.048,1.758,0.642,3.12,0.48
			c1.801,0.519,3.871,0.769,6,0.96c0.309,0.331,1.042,0.237,1.68,0.24c1.22,0.38,2.849,0.352,4.32,0.48
			c2.025,1.015,5.008,1.072,6.479,2.64c-0.422-0.138-1.41,0.29-1.439-0.24c-1.866-0.453-4.132-0.508-6.48-0.479
			c-0.41-0.549-1.832-0.088-2.64-0.24c-1.189-0.411-2.934-0.267-4.32-0.48c-0.08,0-0.16,0-0.24,0
			c-0.678-0.362-1.656-0.423-2.64-0.48c-0.48,0-0.96,0-1.44,0c-2.166-0.473-4.448-0.831-6.96-0.96
			c-1.037-0.402-2.451-0.429-3.6-0.72c-0.566-0.474-1.515-0.565-2.4-0.72c-0.778-0.501-2.013-0.547-2.88-0.96
			c-0.757,0.117-0.848-0.433-1.68-0.24c-0.811-0.469-1.976-0.584-3.12-0.72c-0.751-0.369-1.774-0.466-2.88-0.48
			c-5.175-1.225-12.14-0.66-18-1.2c1.646-0.833,3.273-1.686,4.8-2.64C360.558,319.044,361.317,319.403,362.48,319.36z
			 M349.041,310.48c1.036,0.403,2.137,0.743,3.359,0.96c1.962,0.519,3.799,1.161,6,1.44c0.649,0.471,1.593,0.647,2.641,0.72
			c-0.219,0.581-0.965,0.635-1.681,0.72c-2.699,1.129-5.814-0.698-9.359-0.24c-0.033-0.847-0.688-1.073-0.721-1.92
			C349.393,311.546,348.432,310.8,349.041,310.48z M318.561,276.16c2.748,0.212,4.038,1.882,5.76,3.12
			c0.84,1.401,2.014,2.467,3.12,3.6c0.732,1.588,1.884,2.756,3.12,3.84c-0.044,0.204,0.094,0.227,0.24,0.24
			c0.361,0.918,0.994,1.566,1.68,2.16c0.2,0.76,0.7,1.22,1.2,1.68c0.861,1.138,1.703,2.296,2.4,3.6
			c0.729,0.791,1.294,1.745,1.92,2.64c0.348,0.692,0.655,1.424,1.199,1.92c0.787,1.773,1.76,3.361,2.641,5.04
			c0.162,0.638,0.378,1.222,0.72,1.68c0.657,1.902,1.575,3.545,2.4,5.28c-0.039,0.279,0.053,0.427,0.239,0.48
			c0.2,1.16,0.67,2.05,1.2,2.88c0.305,1.375,1.004,2.356,1.681,3.36c0.406,1.593,1.209,2.79,2.159,3.84
			c0.337,1.023,1.004,1.717,1.681,2.4c0.943,1.856,2.229,3.371,3.6,4.8c0.572,0.948,1.255,1.785,2.16,2.4
			c-0.044,0.204,0.094,0.226,0.24,0.24c0.342,0.938,0.982,1.578,1.92,1.92c1.041,1.199,2.038,2.441,3.6,3.12
			c0.326,0.714,1.084,0.997,1.681,1.44c1.14,1.18,2.715,1.925,4.08,2.88c0.193,0.045,0.238,0.241,0.479,0.24
			c0.868,0.333,1.345,1.056,2.4,1.2c-0.014,0.146-0.036,0.284-0.24,0.24c-1.341,0.141-1.851-0.55-3.12-0.48
			c-0.03-0.53-1.019-0.102-1.44-0.24c-2.882-0.958-6.917-0.763-10.56-0.96c-0.08,0-0.16,0-0.24,0c-2.429-0.211-4.879-0.401-7.2-0.72
			c-1.062-0.538-2.552-0.648-3.84-0.96c-0.08,0-0.16,0-0.24,0c-0.678-0.602-1.841-0.719-2.88-0.96c-1.57-0.19-2.654-0.866-3.84-1.44
			c-0.905-0.935-2.135-1.545-3.12-2.4c-0.753,0.114-0.634-0.646-1.2-0.72c-0.137-0.808-2.171-1.509-2.64-0.48
			c-0.999,1.947-0.76,4.935-1.92,7.2c-0.711,1.212-1.658,3.458-1.68,4.56c-0.386,1.615-1.33,2.67-1.681,4.32
			c-0.873,1.207-1.285,2.874-1.92,4.32c-0.48,1.919-1.292,3.508-1.92,5.28c-0.802,0.718-0.981,2.058-1.439,3.12
			c-0.19,1.81-1.244,2.757-1.681,4.32c-0.863,1.296-1.248,3.072-1.92,4.56c-0.268,1.412-1.049,2.311-1.439,3.6
			c-0.724,0.876-1.091,2.109-1.681,3.12c-0.34,1.34-1.117,2.243-1.68,3.36c-0.719,0.961-1.303,2.057-1.92,3.12
			c-0.379,1.061-1.195,1.686-1.68,2.64c-0.749,0.611-1.174,1.546-1.68,2.4c-0.39,1.131-1.104,1.937-1.681,2.88
			c-0.699,0.661-1.153,1.567-1.681,2.4c-0.465,1.214-1.435,1.925-1.92,3.12c-0.757,0.763-1.321,1.718-1.68,2.88
			c-0.4,1.359-1.25,2.27-1.68,3.6c-0.453,1.546-1.001,2.999-1.439,4.56c-0.254,2.547-1.486,4.115-1.921,6.48
			c-0.55,1.613-1.661,3.936-1.68,5.279c-0.364,1.957-1.247,3.394-1.681,5.28c-0.596,1.487-1.694,3.742-1.68,5.04
			c-0.527,1.792-1.289,3.351-1.68,5.28c-0.729,0.15-2.067-0.308-2.4,0.239c-0.904,0.057-1.016,0.904-1.92,0.961c0-0.4,0-0.801,0-1.2
			c-0.488-1.833-0.979-3.661-1.92-5.04c-0.585-1.574-1.372-2.948-1.68-4.8c-0.36-1.56-0.957-2.884-1.44-4.32
			c-0.64-1.921-1.3-3.82-1.68-6c-0.533-2.747-1.182-5.378-1.92-7.92c-0.611-2.109-1.255-4.185-1.68-6.48
			c-0.941-4.099-1.181-8.899-1.92-13.2c-0.183-5.897-1.252-10.908-1.44-16.8c-0.071-4.649-1.003-8.437-1.92-12.24
			c-0.626-2.014-1.054-4.226-1.68-6.24c0.083-1.686-0.623-4.655-1.68-6c-0.186-0.214-0.258-0.542-0.24-0.96
			c1.863-0.057,3.571-0.27,4.8-0.96c0.08,0,0.16,0,0.24,0c1.03-0.17,2.008-0.392,2.64-0.96c2.658-1.021,4.563-2.797,6.96-4.08
			c1.012-1.068,2.648-1.512,3.36-2.88c0.08,0,0.16,0,0.24,0c1.966-1.714,3.89-3.47,5.761-5.28c0.578-1.021,1.459-1.741,2.399-2.4
			c0.933-0.908,2.126-1.554,2.64-2.88c0.502-0.298,0.902-0.698,1.2-1.2c1.134-0.867,1.888-2.112,2.88-3.12
			c0.984-2.457,3.906-2.974,3.84-6.48c0.316-0.085,0.378-0.422,0.721-0.48c2.096-2.225,3.814-4.826,5.52-7.44
			c0.605-1.234,1.342-2.339,2.16-3.36c0.523-0.757,1.076-1.484,1.2-2.64c0.462-1.618,1.138-3.022,1.68-4.56
			c0.519-1.402,1.183-2.658,1.44-4.32c0.685-3.555,1.406-7.073,1.92-10.8c0-0.08,0-0.16,0-0.24c0.365-0.595,0.06-1.861,0.479-2.4
			c-0.255-1.535,0.81-1.75,0.48-3.36C318.019,275.902,318.347,275.974,318.561,276.16z M332.48,197.92c0,0.08,0,0.16,0,0.24
			c0.502,0.298,0.902,0.698,1.2,1.2c0.723,0.717,1.026,1.853,1.92,2.4c0.297,1.223,0.778,2.262,1.44,3.12
			c0.317,1.042,0.433,2.287,0.96,3.12c0.517,0.203-0.09,1.53,0.479,1.68c0.589,2.23,1.123,5.731,0.48,8.4
			c0.088,2.168-0.671,3.49-0.721,5.52c0.051,1.491-0.737,2.143-0.72,3.6c-0.261-0.059-0.163-0.477-0.479-0.48
			c-0.034-1.726-0.572-2.949-1.2-4.08c-0.037-0.763-0.544-1.056-0.48-1.92c-0.457-0.903-0.669-2.051-1.2-2.88
			c-0.449-0.99-1.106-1.773-0.96-3.36c0.397-0.259,0.397-2.142,0-2.4c-0.454-0.881,0.431-1.356,0.48-2.4
			c0.759,0.841,0.882,2.318,1.44,3.36c0.214,1.146,0.964,1.756,2.399,1.68c0.656-0.471,1.091-2.419,0.48-3.36
			c-0.521-2.462-1.243-7.153-3.841-7.44c-1.854-0.206-3.146,1.504-3.359,4.08c-0.343,0.458-0.558,1.042-0.72,1.68
			c-0.696,1.304-1.651,2.349-1.921,4.08c-0.969,1.191-1.219,3.101-2.399,4.08c-0.82,0.46-0.85,1.711-1.2,2.64
			c0.074,0.954-0.555,1.206-0.48,2.16c-0.664,1.215-1.134,3.934-0.239,5.28c-0.074,0.954,0.554,1.206,0.479,2.16
			c1.562,1.772,0.81,4.969-0.24,6.72c-0.76,0.752-1.286,2.499-0.72,3.84c0.489,0.071,0.471,0.649,0.96,0.72c0.8,0,1.601,0,2.4,0
			c1.021-2.411,3.327-5.017,3.12-7.2c0.857,0.124,0.549-2.152,0.24-2.4c-0.952-2.071-3.751-7.92-0.24-10.8
			c0.449,0.013,0.508-0.185,0.24-0.48c0.582-0.197,0.475-0.314,0.72-1.2c1.985,3.284,4.676,9.366,1.2,12.72
			c-0.854,0.592-1.909,2.245-1.2,3.6c0.014,0.146,0.035,0.284,0.24,0.24c-0.133,0.532,0.458,0.342,0.479,0.72
			c0.497-0.143,1.577,0.297,1.68-0.24c0.704,0.203-0.144,0.772,0,1.2c-0.647,0.952-0.642,2.558-0.96,3.84c-0.08,0-0.159,0-0.239,0
			c-0.034,1.806,0.213,3.893-0.48,5.04c-1.102,1.618-2.316,3.124-3.84,4.32c-0.08,0-0.16,0-0.24,0
			c-0.545,0.976-1.823,1.216-2.64,1.92c-0.341-0.021-0.68-0.04-0.721,0.24c-1.067,0.292-2.459,0.261-3.84,0.24
			c-0.772,0.053-1.016-0.424-1.92-0.24c-0.425-1.781,0.143-2.752,0.24-4.56c0.441-1.318,0.224-3.296,0.72-4.56c0-0.72,0-1.44,0-2.16
			c0.545-0.255,0.092-1.508,0.24-2.16c0.46-2.02,0.626-4.334,0.72-6.72c0.369-1.231,0.421-2.779,0.48-4.32
			c0.5-3.02,0.855-6.185,1.2-9.36c0.205-0.785,0.634-2.251,0.239-2.88c0.046-0.194,0.241-0.239,0.24-0.48
			c-0.048-1.728,0.528-2.832,0.48-4.56c0.235-2.405,1.692-3.587,2.16-5.76C327.618,202.826,328.395,197.599,332.48,197.92z
			 M325.521,197.2c-0.711-0.349,0.316-0.936,0-1.68c0.453,0.027,0.167,0.793,0.72,0.72
			C326.17,196.729,325.591,196.711,325.521,197.2z M195.92,155.92c1.094-1.786,2.054-3.706,2.88-5.76
			c-0.039-0.279,0.053-0.427,0.24-0.48c0.146-0.014,0.284-0.036,0.24-0.24c0.535,1.307-0.01,5.768,0.96,7.44
			c-0.024,1.144,0.531,1.709,0.72,2.64c0.178,1.822,0.633,3.367,1.44,4.56c0.278,1.001,0.769,1.791,1.2,2.64
			c0.474,0.166,0.64,0.64,0.72,1.2c0.275,1.325,0.763,2.438,1.68,3.12c0.29,0.051,0.147,0.267,0,0.48
			c0.449-0.013,0.508,0.185,0.24,0.48c1.11,0.01,0.725,1.515,1.68,1.68c0.507,0.213,0.709,0.73,1.44,0.72c0.08,0,0.16,0,0.24,0
			c-0.44-1.482-1.872-4.047-2.64-6c-0.335-0.864-0.568-1.832-0.96-2.64c-0.567-0.313-0.463-1.297-0.72-1.92
			c-0.063-1.537-0.851-2.349-1.2-3.6c-0.427-0.693-0.432-1.809-0.96-2.4c-0.093-0.627-0.123-1.317-0.48-1.68
			c-0.122-0.838-0.108-1.812-0.48-2.4c0.188-1.388-0.348-2.052-0.24-3.36c-0.15-0.729,0.307-2.067-0.24-2.4
			c-0.281-0.039-0.202-0.438-0.24-0.72c-0.28-1.899,0.146-3.835,0-6.24c-0.071-0.951,0.293-1.467,0.72-1.92
			c0.781-0.66,1.153-1.728,1.68-2.64c0.716-1.043,1.574-1.946,2.16-3.12c1.213-0.467,1.826-1.534,2.64-2.4
			c1.294-0.786,2.415-1.746,3.6-2.64c0.86-0.42,1.688-0.872,2.4-1.44c0.36-0.28,0.587-0.693,1.2-0.72
			c1.084-0.196,1.862-0.698,2.4-1.44c1.606-0.394,2.563-1.438,4.08-1.92c1.588-0.412,2.699-1.301,3.84-2.16
			c1.197-0.243,1.892-0.988,2.88-1.44c1.63-0.05,2.425-0.935,3.6-1.44c1.277-0.163,1.822-1.058,2.88-1.44
			c1.351-0.249,2.054-1.146,3.6-1.2c1.13,0.039,2.766-1.152,3.12,0.24c0.449,0.192,0.879,0.401,1.44,0.48
			c1.285-0.075,1.65-1.069,2.16-1.92c1.987-1.853,4.868-2.812,7.92-3.6c0.803-0.317,1.945-0.295,2.64-0.72
			c1.248-0.032,2.112-0.448,3.36-0.48c0.887-0.153,2.391,0.311,2.88-0.24c-0.108-0.392,1.308-0.392,1.2,0
			c1.603,0.558,4.238,0.082,6.24,0.24c1.63-0.11,2.701,0.339,3.84,0.72c0.849,0.431,1.639,0.922,2.64,1.2
			c0.34-0.021,0.68-0.04,0.72,0.24c1.009,0.592,2.212,0.989,3.6,1.2c1.283,0.397,2.192,1.168,3.36,1.68
			c0.799,0.961,1.921,1.598,3.12,2.16c2.374,1.947,5.507,3.134,7.92,5.04c1.175,0.985,2.643,1.678,4.08,2.4
			c0.765,0.116,1.005,0.755,1.921,0.72c0.717,0.802,2.057,0.982,3.119,1.44c0.443,0.517,1.287,0.634,1.68,1.2
			c0.043,0.922-0.828,0.931-0.959,1.68c-0.764,0.597-0.831,1.89-1.44,2.64c-0.721,0.56-0.868,1.692-1.44,2.4
			c-0.757,0.763-1.321,1.718-1.68,2.88c-2.402,3.038-5.026,5.854-7.68,8.64c-0.08,0-0.16,0-0.24,0
			c-1.071,1.249-3.131,1.509-4.08,2.88c-0.569,0.39-0.975,0.945-1.68,1.2c-0.613,0.027-0.84,0.44-1.2,0.72
			c-0.901,0.699-1.701,1.5-2.4,2.4c-1.074,1.805-2.676,3.083-4.32,4.32c-0.537,0.104-0.097,1.183-0.24,1.68
			c1.397,0.684,2.62-0.254,3.12-1.2c0.388,0.067,0.414-0.227,0.72-0.24c2.89-2.87,5.739-5.781,9.12-8.16
			c1.104-0.655,1.794-1.726,3.12-2.16c0.938-0.422,1.771-0.949,2.16-1.92c-0.119-0.758,1.19-0.089,0.959-0.96
			c1.418-1.062,2.529-2.431,3.361-4.08c2.285-2.635,3.335-5.599,5.279-8.16c0.37-0.678,1.451-1.898,1.2-2.4
			c-0.039-0.279,0.053-0.427,0.239-0.48c0.279-0.039,0.428,0.053,0.48,0.24c1.459,1.821,3.341,3.219,4.8,5.04
			c2.367,3.331,6.345,5.723,6.24,10.8c0.396,2.003,1.465,3.335,1.68,5.52c0.339,1.341,0.677,2.684,1.2,3.84
			c-0.021,0.34-0.039,0.68,0.24,0.72c0.255,1.905,0.852,3.468,1.439,5.04c0,0.32,0,0.64,0,0.96c0.435,2.125,0.589,4.532,1.2,6.48
			c0.018,3.023,0.629,5.452,0.48,8.64c0.552,3.289,0.086,7.594,0.24,11.28c-0.548,1.688-0.015,4.457-0.479,6.229
			c0.428-0.052,0.496-1.906,0.494-3.586c0-0.763-0.016-1.491-0.018-1.997c0.002,0.505,0.018,1.233,0.018,1.997
			c0.002,1.68-0.066,3.535-0.494,3.586c-0.002,0-0.004,0-0.006,0.001c-0.004,0-0.007,0.001-0.01,0.001
			c-0.238-0.075-0.157-0.468-0.466-0.471c-0.976-1.041-3.586-0.459-3.36,1.2c-0.356,0.443-0.46,1.14-0.48,1.92
			c0.032,0.592,0.037,1.156-0.239,1.44c-0.715,2.006-0.637,4.804-0.96,7.2c-0.53,0.03-0.103,1.018-0.24,1.44
			c-0.428,1.333-0.691,2.828-0.72,4.56c-0.621,1.139-0.447,3.073-0.961,4.32c0.169,1.048-0.408,1.352-0.239,2.4
			c-0.239,1.285-0.939,3.375-0.48,4.56c-0.785,1.227-0.412,2.136-0.72,3.84c-0.64,0.401,0.133,2.213-0.48,2.64c0,0.64,0,1.28,0,1.92
			c-0.627,0.333,0.117,2.037-0.479,2.4c0.067,2.467-0.547,4.253-0.48,6.72c-0.316,0.003-0.219,0.421-0.479,0.48
			c0.018-1.618-0.565-2.635-0.72-4.08c-0.389-0.491-0.188-1.573-0.721-1.92c-0.063-0.977-0.451-1.628-0.72-2.4
			c-0.17-1.03-0.392-2.008-0.96-2.64c-0.187-0.213-0.258-0.542-0.24-0.96c-0.411-0.469-0.417-1.343-0.72-1.92
			c-0.046-0.194-0.24-0.239-0.24-0.48c-0.635-1.045-1.058-2.302-1.92-3.12c0-0.16,0-0.32,0-0.48c-0.4-0.959-0.971-1.75-1.439-2.64
			c-0.746-1.254-1.303-2.697-2.4-3.6c-0.04-0.68-0.439-1-0.48-1.68c-0.697-0.743-1.016-1.864-1.68-2.64
			c-0.837-3.163-1.335-6.665-1.68-10.32c0.448-0.543,0.328-2.558,0-3.12c-0.178-3.263,0.433-7.313-0.48-9.84
			c-0.125-0.355-0.355-0.605-0.48-0.96c-0.559,0-1.119,0-1.68,0c-0.791,0.16-0.859-0.382-0.959-0.72
			c-0.105-0.35-0.18-0.731-0.244-1.125c0.01-0.139,0.017-0.273,0.02-0.401c0-0.017-0.001-0.032,0-0.049
			c0-0.125-0.002-0.243-0.016-0.346c-0.025-0.192-0.053-0.393-0.08-0.6c-0.043-0.317-0.086-0.646-0.133-0.989c0,0,0,0.001,0,0.001
			c-0.046-0.341-0.093-0.691-0.141-1.049c0.048,0.356,0.095,0.708,0.141,1.047c-0.104-2.666,0.042-5.636-0.748-7.771
			c0.193,0.127,0.391,0.25,0.721,0.24c1.438,0.962,3.197,1.602,5.52,1.68c2.156,0.163,3.857,0.782,5.76,1.2
			c-0.04,0.521,0.852,0.109,1.201,0.24c-0.139-0.422,0.289-1.41-0.24-1.44c-0.628-0.093-0.671-0.77-1.68-0.48
			c-0.822-0.699-2.077-0.964-3.121-1.44c-1.338-0.502-2.343-1.337-4.08-1.44c-0.749-0.29-1.498-0.582-2.16-0.96
			c-0.047-1.232-2.328-0.231-2.64-1.2c-0.06-1.94-0.843-3.157-1.2-4.8c0.191-0.285-0.245-1.984-0.72-2.4
			c0.012-1.052-0.993-1.086-0.96-2.16c0.949-1.613,3.874-2.523,2.88-5.28c0.112-1.081-1.957-1.23-2.64-0.72
			c-0.283-0.038-0.682,0.042-0.721-0.24c0.613-0.507,1.063-1.179,1.44-1.92c1.127-0.343,2.239-4.351,0.72-4.32
			c-2.367,0.753-2.957,3.282-4.56,4.8c-0.895,1.266-1.904,2.416-3.12,3.36c-1.411,0.509-2.076,1.764-3.6,2.16
			c-1.18,0.099-1.835,0.724-2.4,1.44c-1.259,0.661-2.268,1.572-3.6,2.16c-2.172,1.587-4.385,3.135-6.24,5.04c-0.08,0-0.16,0-0.24,0
			c-1.54,1.34-2.98,2.78-4.32,4.32c-0.408-0.088-0.453,0.187-0.48,0.48c-0.709,0.651-1.61,1.11-1.92,2.16
			c-0.857,0.263-1.417,0.823-1.68,1.68c-1.12,0.243,0.111,1.747-0.48,2.16c-1.474,1.166-2.633,2.647-3.84,4.08
			c-1.599,1.121-2.53,2.91-4.32,3.84c0.258-0.942,0.792-1.609,1.2-2.4c0.635-0.806,1.225-1.656,1.68-2.64
			c0.493-0.067,0.479-0.641,0.72-0.96c-0.067-0.504-0.641,0.348-0.96-0.24c-0.408,0.088-0.453-0.188-0.48-0.48
			c-0.124-0.688-1.816-1.048-2.4-0.48c-1.631,0.769-1.928,2.872-2.88,4.32c-0.745,0.695-1.224,1.656-1.44,2.88
			c-0.4,0.32-0.373,1.067-0.72,1.44c-0.749,1.491-1.396,3.084-2.16,4.56c-0.376,0.664-0.746,1.334-0.96,2.16
			c-0.317,0.003-0.219,0.42-0.48,0.48c-0.532,1.072-1.219,3.716-2.4,2.64c-0.622-0.258-0.893-0.868-1.68-0.96
			c-1.139-0.461-1.959-1.241-2.64-2.16c1.164-1.114-0.289-2.609-0.96-3.36c-0.484-0.316-0.812-0.788-0.96-1.44
			c-0.012-1.348-0.67-2.049-1.2-2.88c-0.291-0.909-0.465-1.936-0.96-2.64c0.023-0.744-0.109-1.331-0.48-1.68
			c-0.014-1.026-0.322-1.758-0.48-2.64c-0.96-5.003,0.856-10.046,2.4-14.4c0.326-0.554,0.69-1.07,0.72-1.92
			c-0.31-0.95,0.79-0.49,0.48-1.44c0.444-0.676,0.743-1.497,0.96-2.4c0-0.08,0-0.16,0-0.24c0.807-0.154,0.77-1.151,0.72-2.16
			c-0.574-0.172-2.29-0.698-2.16,0.48c-0.524,1.155-1.403,1.957-2.16,2.88c-0.652,0.708-1.292,1.428-1.44,2.64
			c-0.71,1.307-1.247,2.602-1.44,3.84c-1.014,1.089-1.266,3.514-1.92,6.48c0.176,2.784-0.43,6.35,0.48,8.4
			c-0.223,1.367,0.888,1.771,0.48,2.64c0.187,0.054,0.474,0.007,0.48,0.24c0.435,0.782-0.315,1.259,0.48,1.68
			c-0.655,0.175-0.483-0.477-0.96-0.48c-0.344-0.937-0.845-1.715-1.44-2.4c-0.127-0.912-0.36-1.719-0.72-2.4
			c-0.025-0.615-0.136-1.144-0.48-1.44c-0.142-1.219-0.372-2.348-0.72-3.36c-0.131-0.349,0.28-1.241-0.24-1.2
			c-0.098-1.022-0.111-2.129-0.48-2.88c0-0.4,0-0.8,0-1.2c-0.128-0.832,0.356-2.276-0.48-2.4c0-0.64,0-1.28,0-1.92
			c-0.146-2.813-0.033-5.886-0.96-7.92c0.204-1.129-1.93-0.775-2.4-0.48c0,0.24,0,0.48,0,0.72c0,0.08,0,0.16,0,0.24
			c-0.398,0.649-0.398,2.95,0,3.6c-0.15,1.91,0.417,3.103,0.24,5.04c-0.011,2.97,0.688,5.231,0.96,7.92
			c0.449,0.75,0.171,2.229,0.72,2.88c0.895,4.546,3.219,8.94,4.32,12.48c-0.279,0.039-0.427-0.053-0.48-0.24
			c-0.47-0.651-0.931-1.309-1.92-1.44c-1.986-2.094-5.178-2.981-7.44-4.8c-1.159-1.241-2.338-2.462-3.84-3.36
			c-0.593-0.207-0.71-0.89-1.2-1.2c-0.927-1.633-2.39-2.729-3.36-4.32c-0.428-0.452-0.649-1.111-1.2-1.44
			c-1.145-1.895-2.734-3.346-4.08-5.04c-0.4-1.103-2.585-0.762-3.36-0.24c-1.44-1.199-2.936-2.344-4.08-3.84
			c0.367-0.438,0.367-1.963,0-2.4c-0.461-1.459-1.453-2.387-2.16-3.6c0.005-1.235-0.04-1.886-0.24-2.4
			C194.894,157.294,195.399,156.599,195.92,155.92z M296.181,178.589c-0.069-0.646-0.124-1.248-0.157-1.774
			C296.057,177.342,296.111,177.943,296.181,178.589z M288.32,160.24c-0.162,0.558-0.797,0.643-0.96,1.2c-0.08,0-0.16,0-0.24,0
			c-0.871,0.729-1.693,1.507-2.64,2.16c-0.413-0.013-0.615,0.185-0.72,0.48c-1.426,0.575-2.8,1.2-4.08,1.92
			c-0.204,0.044-0.227-0.093-0.24-0.24c2.221-1.539,4.676-2.844,6.96-4.32C287.298,161.298,287.642,160.601,288.32,160.24z
			 M255.995,185.509c-0.09,0.088-0.178,0.178-0.268,0.266c-0.187,0.177-0.363,0.35-0.536,0.522
			C255.456,186.032,255.725,185.771,255.995,185.509z M196.16,268.72c-1.91-0.25-3.69-0.63-4.32-2.16
			c0.01-0.331-0.113-0.527-0.24-0.72c0.894-0.147,2.534,0.454,2.4-0.72c0.408,0.088,0.453-0.187,0.48-0.48
			c0.26,0.06,0.163,0.478,0.48,0.48C194.924,266.488,197.188,267.942,196.16,268.72z M193.76,262.72
			c-0.979-0.541-1.094-1.946-1.2-3.36c0.364,0.516,0.739,1.02,0.96,1.68C192.896,261.375,193.695,262.282,193.76,262.72z
			 M196.4,257.44c-0.31-0.938,0.79-0.938,0.48,0c0.558,1.604,2.145,3.452,4.32,2.64c0.13-1.25-0.461-1.779-0.48-2.88
			c-0.655-1.185-0.969-2.711-1.68-3.84c0.076-0.557-0.039-0.921-0.24-1.2c2.571,0.069,4.9,0.38,7.68,0.24
			c4.257,0.018,8.593,0.113,12.24-0.48c0-0.08,0-0.16,0-0.24c0.751-0.369,1.774-0.466,2.88-0.48c0.08,0,0.16,0,0.24,0
			c0.318-0.309,1.014-0.332,1.621-0.213c-0.172-0.05-0.347-0.091-0.524-0.126c0.179,0.035,0.355,0.077,0.529,0.127
			c-0.001,0-0.003,0-0.004-0.001c0.297,0.086,0.585,0.194,0.865,0.322c0,0,0,0,0,0c0.001,0,0.002,0,0.002,0.001
			c0.046,0.021,0.091,0.043,0.137,0.064c-0.045-0.021-0.091-0.043-0.137-0.063c0.059,0.042,0.117,0.084,0.152,0.13
			c0.04-0.01,0.067-0.008,0.101-0.013c0,0,0,0,0.001,0c0.135,0.068,0.267,0.14,0.398,0.217c0,0,0.001,0.001,0.002,0.001
			c0.07,0.174,0.088,0.4,0.218,0.514c0.889,0.711,1.689,1.511,2.4,2.4c0.647,0.392,1.142,0.938,1.44,1.68
			c0.281-0.032,0.456,0.042,0.571,0.177c-0.132-0.183-0.265-0.368-0.404-0.557c0.139,0.188,0.275,0.375,0.406,0.558
			c0,0-0.001,0-0.001-0.001c0.022,0.031,0.047,0.064,0.069,0.096c0.086,0.169,0.11,0.401,0.08,0.688
			c0.759-0.167,3.124,0.418,2.4-0.48c1.087,0.367-0.127-1.567,0.96-1.2c0,0.4,0,0.8,0,1.2c0.157,3.283,0.598,6.282,1.2,9.12
			c0.161,2.559,1.018,4.422,1.44,6.72c0.759,1.769,3.291,0.019,3.6-0.96c0-0.32,0-0.64,0-0.96c0.65-0.636,0.967-2.865,0.72-3.6
			c0.201-0.279,0.316-0.644,0.24-1.2c0.751-1.009,0.84-2.68,1.44-3.84c-0.168-1.048,0.408-1.352,0.24-2.4
			c0.887-2.058,0.48-6.195,0.24-8.64c-0.229-0.811-0.603-1.477-1.44-1.68c-0.387-0.333-0.76-0.68-1.2-0.96
			c-0.891-1.029-1.879-1.961-3.12-2.64c-1.057-1.104-1.904-2.416-3.36-3.12c-0.309-0.411-0.497-0.943-1.2-0.96
			c-0.718-0.961-1.557-1.803-2.88-2.16c-0.029-1.011-1.842-0.238-2.64-0.48c-1.226,6.056,3.876,9.761,5.52,13.92
			c-0.372,0.951,0.908,1.467,0.24,1.92c-0.83-0.851-1.384-1.976-2.4-2.64c-0.045-0.194-0.241-0.239-0.24-0.48
			c-0.706-0.574-0.854-1.707-1.92-1.92c-1.208-0.232-1.407-1.473-2.88-1.44c-1.62-1.065-3.911,0.084-6,0
			c-0.983,0.057-1.962,0.118-2.64,0.48c-0.08,0-0.16,0-0.24,0c-1.621-0.021-3.128,0.072-4.32,0.48c-1.43,0.31-1.627-0.613-2.64-0.72
			c0.333-0.547,1.67-0.089,2.4-0.24c0.363-0.597,2.067,0.147,2.4-0.48c3.778-0.143,6.924-0.916,9.84-1.92
			c0.616-1.39,0.164-3.211-1.44-3.12l-3.323-0.151c-0.801,0.062-1.608,0.118-2.197,0.391c-4.123,0.165-10.62,1.011-15.6,0.48
			c-2.796-0.298-5.663-1.662-6,1.44c-0.876-0.873-0.511-2.118,0.138-3.271l-0.133-3.691c-0.001,0.001-0.003,0.002-0.004,0.002
			c0-0.043,0-0.087,0-0.131l-0.056-1.547c-0.004-0.067-0.006-0.139-0.008-0.211l-0.006-0.182l0.006-0.002
			c-0.001-1.497,0.343-3.878-0.177-5.128c-0.946,0.146-0.282-1.318-0.48-1.92c-0.557-1.999,0.262-4.168-0.72-6.48
			c-0.545-0.255-0.091-1.509-0.24-2.16c-0.067-1.933-0.109-3.891-0.48-5.52c0-0.08,0-0.16,0-0.24
			c-0.154-0.966,0.312-2.552-0.24-3.12c0-0.08,0-0.16,0-0.24c-0.095-5.375,0.135-11.704,0-16.32c-0.836-0.807,0.197-1.832,0.24-3.12
			c0.687-1.154,1.009-2.671,1.44-4.08c0.891-0.525,0.587-0.958,0.96-2.16c1.755-3.125,3.266-6.494,5.04-9.6
			c0.375-1.145,0.949-2.091,2.64-1.92c0.241,0.319,0.227,0.893,0.72,0.96c1.258,2.261,3,4.039,4.56,6
			c2.102,3.178,4.881,5.679,7.92,7.92c1.807,1.553,4.031,2.688,6,4.08c0.886,0.794,1.743,1.616,2.88,2.16
			c0.89-0.01,0.86,0.9,1.44,1.2c0.737,0.942,1.595,1.765,2.88,2.16c0.725,0.635,1.474,1.246,2.4,1.68
			c0.468,0.493,1.132,0.788,1.68,1.2c0.021,0.924,2.598,1.082,2.88,0.24c0.767-0.033,0.529-1.071,0.96-1.44
			c0.569,0.791,1.432,1.288,2.4,1.68c0.816,0.943,1.489,2.031,2.88,2.4c0.614,0.599,2.208,0.141,2.64-0.24
			c0.583-0.377,0.899-1.021,0.96-1.92c0.383-0.336,0.613-0.827,0.72-1.44c0.801-0.799,0.888-2.313,1.92-2.88
			c0.613-0.027,0.84-0.44,1.2-0.72c1.481-1.079,2.937-2.184,4.08-3.6c1.752-1.688,3.31-3.57,5.04-5.28
			c0.818-0.462,1.258-1.302,1.92-1.92c2.66-2.46,5.22-5.02,7.68-7.68c0.346-0.134,0.585-0.374,0.72-0.72
			c1.243-1.477,3.232-2.208,4.56-3.6c1.245-0.435,2.22-1.14,3.12-1.92c3.77-2.63,8.915-3.885,12.24-6.96
			c0.743,2.697,2.318,6.296,2.4,8.16c-0.08,0-0.16,0-0.24,0c-0.071,0.472,0.324,0.476,0.479,0.72
			c-0.201,0.278-0.316,0.644-0.239,1.2c0.187,0.054,0.474,0.006,0.479,0.24c-0.569,0.28-0.215,1.632,0.24,1.68
			c-0.71,2.553,1.145,4.613,0.721,7.68c0.371,3.468-0.086,7.766,0.719,10.8c0.127,0.193,0.251,0.39,0.24,0.72
			c0.725,0.01,2.057,0.646,2.641,0c1.094,2.186,0.095,6.465,0.96,8.88c0.049,0.751-0.162,1.762,0.239,2.16
			c-0.129,1.49,0.487,2.233,0.48,3.6c0.339,1.101,0.396,2.483,1.2,3.12c0.133,0.427,0.123,0.997,0.479,1.2
			c0.125,1.155,0.678,1.883,1.201,2.64c0.766,1.234,1.504,2.496,2.159,3.84c0.493,0.773,0.62,1.543,1.2,1.68
			c-0.128,0.96,1.624,2.616,2.16,4.32c0.178,0.942,0.634,1.606,0.96,2.4c0.076,0.804,0.247,1.513,0.72,1.92c0-0.08,0-0.16,0-0.24
			c0.76,1.081,0.434,3.247,1.2,4.32c0.317,0.802,0.295,1.945,0.72,2.64c0.047,1.953,0.842,3.158,0.721,5.28c0,0.08,0,0.16,0,0.24
			c0.038,1.801,0.328,3.351,0.719,4.8c0.168,1.993,0.19,4.13,0.721,5.76c-0.168,1.848,0.313,3.046,0.96,4.08
			c0.085,0.635,1.223,0.217,1.44,0.72c-0.365,0.835-0.434,1.966-0.48,3.12c-0.387,0.813-0.492,1.908-0.479,3.12
			c-0.364,0.944-0.388,2.004-0.24,2.88c-0.276,0.284-0.271,0.848-0.24,1.44c-0.39,0.65-0.31,1.771-0.479,2.64
			c-0.331,0.309-0.238,1.042-0.24,1.68c-0.43,1.411-0.599,3.082-0.721,4.8c-0.386,0.813-0.491,1.908-0.479,3.12
			c-0.394,1.046-0.548,2.332-0.72,3.6c-0.301,1.7-0.468,3.533-0.961,5.04c-0.689,0.99-0.911,2.448-1.439,3.6
			c-0.395,1.366-0.993,2.527-1.439,3.84c-0.061,1.38-1.011,1.87-1.201,3.12c-1.186,2.254-3.228,3.652-5.039,5.28
			c-0.479,0.242-1.025,0.415-1.2,0.96c-1.124,0.396-1.698,1.342-2.64,1.92c-1.178,0.823-1.893,2.108-2.881,3.12
			c-0.213,0.106-0.429,0.21-0.479,0.48c-0.08,0-0.16,0-0.24,0c-0.118,1.562-1.874,1.486-2.159,2.88
			c-1.777,1.583-3.52,3.201-4.801,5.28c-0.213,0.106-0.429,0.211-0.48,0.48c-1.266,1.374-2.613,2.667-3.6,4.32
			c-0.213,0.106-0.429,0.21-0.48,0.48c-1.243,1.157-2.332,2.468-3.36,3.84c-0.805,0.635-1.426,1.454-2.16,2.16
			c-0.203,0.837-0.669,1.411-1.44,1.68c0,0.16,0,0.32,0,0.48c-0.798,1.202-2.257,1.743-3.36,2.64
			c-1.247-0.456-3.749-0.279-4.56,0.48c-1.367-0.006-2.11,0.61-3.6,0.48c-3.255,0.452-6.616,0.577-10.08,0.24
			c-1.647,0.047-2.875-0.325-4.08-0.72c-1.12-0.88-2.301-1.699-3.36-2.64c-0.006-0.233-0.293-0.187-0.48-0.24
			c-0.014-0.146-0.036-0.284-0.24-0.24c-0.94-1.141-1.98-2.181-3.12-3.12c-1.167-0.353-1.718-1.322-2.64-1.92
			c-0.648-0.792-1.323-1.557-2.4-1.92c0.044-0.204-0.094-0.226-0.24-0.24c-0.398-0.481-0.644-1.116-1.44-1.2
			c-0.587-0.772-1.203-1.517-2.16-1.92c-0.799-0.081-0.78-0.98-1.68-0.96c-0.43-0.69-1.049-1.191-1.92-1.44
			c-1.087-1.153-2.523-1.957-4.08-2.64c-0.846-1.074-1.875-1.964-3.36-2.4c-0.316-0.484-0.788-0.812-1.44-0.96
			c-1.669-1.291-3.298-2.622-4.8-4.08c-0.484-0.316-0.812-0.788-0.96-1.44c-0.931-2.189-1.752-4.487-2.4-6.96
			c-0.012-1.348-0.67-2.05-1.2-2.88c-0.143-0.978-0.462-1.778-0.96-2.4c-0.401-0.558-0.627-1.293-0.72-2.16
			c-0.2-1.321-0.842-2.198-1.44-3.12c-0.864-0.416-0.447-2.113-1.2-2.64c-0.125-1.155-0.677-1.883-1.2-2.64
			c-1.077-3.003-3.089-5.07-3.84-8.4c-0.344-1.63,0.654-3.178,0-4.32c-0.245-0.315-0.235-0.885-0.48-1.2
			c0.357-0.845-0.748-2.286-0.96-3.36c-0.534-0.906-0.632-2.248-1.2-3.12c-0.423-1.797,0.33-3.641-0.96-4.8
			c-0.048-1.312-0.803-1.917-0.96-3.12C196.855,259.226,196.85,258.111,196.4,257.44z M224.985,251.648
			c1.439,0.843,2.648,2.16,3.667,3.475C227.633,253.812,226.424,252.492,224.985,251.648z M181.28,322
			c0.542-2.338,1.219-4.541,1.68-6.96c0.709-1.69,1.169-3.631,1.68-5.52c0.329-1.991,1.217-3.423,1.92-5.04
			c0.592-1.248,1.337-2.342,1.68-3.84c0.381-1.3,1.469-1.891,1.92-3.12c0.741-0.62,1.25-1.47,1.68-2.4
			c-0.179-0.739,0.659-0.461,0.48-1.2c0.875,1.844,1.104,4.336,1.92,6.24c0.025,1.099,1.062,3.255,1.68,4.56
			c0.575,1.185,1.293,2.227,1.68,3.6c0.444,1.156,0.837,2.363,1.68,3.12c0.452,0.988,1.267,1.613,1.68,2.64
			c0.583,1.017,1.2,2,2.16,2.64c0,0.08,0,0.16,0,0.24c0.7,0.9,1.5,1.701,2.4,2.4c7.256,6.664,17.908,9.932,26.88,14.88
			c0.779,0.582,1.697,1.023,2.64,1.44c-0.044,0.204,0.093,0.226,0.24,0.24c2.292,2.668,4.997,4.923,7.92,6.96
			c0.963,0.397,1.568,1.152,2.64,1.44c1.037,0.963,2.858,1.142,4.56,1.44c2.17-0.01,3.22,1.1,5.76,0.72
			c-0.203,1.557-0.489,3.031-0.48,4.8c-0.924,5.668-0.999,10.936-2.4,15.84c0-0.08,0-0.16,0-0.24c-0.391,0.81-0.517,1.883-0.72,2.88
			c0.121,1.641-0.729,2.311-0.72,3.84c-0.468,1.372-0.994,2.686-1.2,4.32c-0.727,1.114-0.963,2.717-1.44,4.08
			c-0.428,2.692-1.79,4.451-2.16,7.2c-0.473,0.407-0.644,1.116-0.72,1.92c-0.356,0.444-0.459,1.14-0.48,1.92
			c-0.396,1.125-0.394,2.646-0.72,3.84c-0.777,2.904-1.224,6.137-1.44,9.6c-0.052,0.308-0.092,0.633-0.127,0.966
			c-0.521,3.884,1.174,9.406,0.367,12.234c-0.031,0.11-0.625-0.109-0.48-0.24c-0.388,0.067-0.413-0.227-0.72-0.239
			c-0.51-0.37-0.894-0.867-1.44-1.201c-0.283-0.676-1.116-0.803-1.68-1.199c0.2-0.76,1.179-0.74,1.68-1.2
			c0.948-0.656,1.625-2.849,0.96-4.32c0.055-1.015-0.378-1.542-0.72-2.16c0-0.16,0-0.32,0-0.48c-0.973-0.707-1.325-2.036-1.92-3.12
			c-0.626-1.054-1.43-1.931-2.4-2.64c-2.11-1.964-5.937-1.15-6.72,1.44c-0.148,3.428,0.899,5.66,1.44,8.4
			c0.575,1.025,0.938,2.262,1.92,2.88c0.781,0.579,2.405,0.314,3.36,0.72c-0.216,1.064-2.042,0.518-2.64,1.2
			c-1.759-1.601-3.005-3.714-4.56-5.52c-0.896-0.944-1.53-2.149-2.64-2.88c-0.227-0.733-1.168-0.752-1.2-1.68
			c-0.609-1.071-1.319-2.04-2.16-2.88c-2.19-3.01-4.015-6.385-5.28-10.32c-0.506-1.094-0.82-2.38-1.44-3.36
			c-0.556-1.604-1.344-2.976-1.68-4.8c-0.288-1.713-1.02-2.981-1.68-4.32c-0.601-1.64-1.083-3.397-1.68-5.04
			c-0.333-1.667-0.862-3.138-1.68-4.32c-0.523-1.717-1.348-3.132-1.68-5.04c-0.625-1.775-0.996-3.804-1.92-5.28
			c-0.459-2.021-1.082-3.878-1.68-5.76c-0.157-1.685-1.087-4.67-1.92-6.48c-0.277-1.402-1.019-2.341-1.44-3.6
			c-0.17-3.479-4.786-5.539-7.2-2.64c-0.987,0.533-1.94,1.1-3.12,1.44c-1.455,0.465-2.912,0.927-4.08,1.68
			c-3.862,1.258-7.127,3.113-10.56,4.8c-1.159,0.761-2.611,1.229-3.36,2.4c-2.229,1.372-4.089,3.111-6.24,4.56
			c-0.795-1.364,1.055-3.416,0.24-4.8c0.427-2.134,0.983-4.137,1.68-6c0.501-1.099,1.017-2.183,1.44-3.36
			c0.478-1.522,1.309-2.691,1.92-4.08c0.582-0.778,1.023-1.697,1.44-2.64c0.427-1.413,1.268-2.412,1.92-3.6
			c0.889-1.11,1.48-2.52,2.16-3.84c0.483-1.038,0.995-2.045,1.44-3.12C180.293,325.65,181.305,323.309,181.28,322z M180.773,312.656
			c-0.52,0.859-1.661,1.096-2.565,1.569C179.109,313.749,180.254,313.515,180.773,312.656z M112.64,340.48
			c1.609-5.351,7.215-6.705,12.48-8.4c1.763-0.557,3.592-1.048,5.04-1.92c1.796-0.364,3.214-1.106,5.04-1.44
			c1.688-0.233,3.129-0.711,4.32-1.44c2.19-0.85,4.585-1.495,6.96-2.16c2.239-0.562,4.478-1.123,6.48-1.92
			c2.354-0.286,4.075-1.204,6.48-1.44c2.566-0.634,5.449-0.95,7.92-1.68c3.917-1.042,9.409-0.511,11.76-3.12
			c0.543,0.794-0.169,2.382-0.24,3.36c-2.523-1.187-4.776,0.189-6.96,0.72c-1.73,0.67-3.705,1.095-5.28,1.92
			c-1.811,0.509-3.58,1.061-5.52,1.44c-2.636,0.563-5.126,1.273-7.44,2.16c-2.202,0.438-3.914,1.366-6.24,1.68
			c-1.52,0.48-3.378,0.623-4.56,1.44c-1.941,0.619-4.032,1.088-6,1.68c-2.519,0.361-4.689,1.071-6.72,1.92
			c-1.609,0.311-2.681,1.159-4.32,1.44c-1.16,0.36-2.359,0.681-3.12,1.44c-1.318,0.682-2.649,1.351-4.08,1.92
			c-1.585,0.814-2.654,2.146-3.6,3.6c0.114,0.753-0.646,0.634-0.72,1.2c-0.991,0.995-1.854,2.119-3.096,2.863
			c0.096-0.017,0.189-0.055,0.283-0.087c-0.094,0.032-0.188,0.07-0.283,0.087c-0.009,0.001-0.018,0-0.026,0.001
			C110.687,343.941,112.195,342.112,112.64,340.48z M160.4,328.24c0,0.08,0,0.16,0,0.24s0,0.16,0,0.24
			c-0.978,0.142-1.778,0.462-2.4,0.96c-0.597-0.037-0.743,0.377-1.2,0.48c-1.017,0.343-2.037,0.683-2.88,1.2
			c-2.64,0.801-5.337,1.542-7.68,2.64c-0.897,0.303-2.09,0.31-2.64,0.96l-7.68,2.4c-0.91,0.291-1.936,0.465-2.64,0.96
			c-2.54,0.261-4.259,1.342-6.72,1.68c-1.106,0.014-2.129,0.111-2.88,0.48c-1.068-0.188-1.44,0.32-2.4,0.24
			c0.735-0.385,1.445-0.795,2.16-1.2c0.681-0.519,1.802-0.598,2.4-1.2c1.277-0.163,2.388-0.492,3.12-1.2
			c1.119-0.242,2.279-0.441,3.12-0.96c0.734,0.174,0.706-0.414,1.44-0.24c0.756-0.284,1.811-0.27,2.4-0.72
			c2.429-0.691,5.011-1.229,7.2-2.16c1.174-0.187,2.166-0.555,3.12-0.96c1.333-0.267,2.682-0.518,3.84-0.96
			c0.756-0.284,1.811-0.27,2.4-0.72c1.286-0.394,2.78-0.58,3.84-1.2C157.882,329.083,158.819,328.339,160.4,328.24z M129.2,628.241
			c-2.287-0.673-4.49-1.431-6.72-2.16c-1.155-0.204-1.724-0.995-2.88-1.2c-1.332-0.908-2.996-1.483-4.56-2.16
			c-0.726,0.075-1.866-0.267-1.92,0.48c-1.03,2.633,2.5,2.778,3.84,3.84c1.265,0.175,2.065,0.815,3.12,1.2
			c1.364,0.716,2.823,1.337,4.32,1.92c1.721,0.358,2.848,1.313,4.56,1.68c1.838,1.122,5.264,0.656,7.68,1.2c-0.08,0-0.16,0-0.24,0
			c-1.414,0.506-2.694,1.146-3.84,1.92c-0.08,0-0.16,0-0.24,0c-4.119-0.681-9.33-0.271-12.24-2.16
			c-1.246-0.354-2.297-0.902-3.36-1.439c-0.883-0.798-1.981-1.379-3.12-1.92c-0.989-0.131-1.404-0.836-2.16-1.2
			c-0.903-0.937-2.045-1.635-3.12-2.4c-2.533-1.467-4.869-3.131-7.44-4.56c-0.844-0.676-1.859-1.181-2.88-1.681
			c-1.355-0.324-2.12-1.239-3.36-1.68c-1.146-0.774-2.426-1.414-3.84-1.92c-0.657-0.543-1.483-0.917-2.16-1.44
			c-1.241-0.839-2.195-1.965-4.08-2.159c-0.497,0.143-1.577-0.297-1.68,0.239c-0.74,0.811,0.23,1.367,0.24,2.16
			c0.798,0.802,1.757,1.442,2.88,1.92c0.657,0.383,1.184,0.896,1.92,1.2c1.034,0.967,2.36,1.64,3.84,2.16
			c1.208,0.313,1.985,1.055,3.12,1.44c0.63,1.05,2.111,1.249,3.12,1.92c2.937,1.144,5.181,2.979,7.68,4.56
			c0.99,1.09,2.306,1.854,3.6,2.64c0.833,0.047,0.911,0.85,1.68,0.961c1.051,0.869,2.15,1.689,3.6,2.159
			c1.047,0.233,1.728,0.832,2.64,1.2c1.471,0.93,2.989,1.811,5.04,2.16c1.615,0.145,2.844,0.676,4.8,0.48
			c-4.745,2.295-6.23,7.849-12,9.119c-1.927,0.473-3.508,1.292-5.28,1.92c-0.16,0-0.32,0-0.48,0c-0.404-1.105-2.628-0.804-3.12,0
			c-1.157,0.363-1.677,1.363-3.12,1.44c-0.724,0.716-1.444,1.437-2.16,2.16c-0.423,0.217-0.743,0.537-0.96,0.96
			c-1.107,0.652-2.074,1.446-2.88,2.4c-0.477,0.003-0.305,0.654-0.96,0.479c-1.15,1.01-2.701,1.619-3.6,2.88
			c-1.027,0.412-1.584,1.296-2.88,1.44c-1.617,0.623-3.26,1.22-4.56,2.16c-4.073,1.96-11.179,1.669-15.12-0.24
			c-0.706-1.135-2.556-1.125-3.36-2.16c-0.384-0.896-1.493-1.067-1.68-2.16c-0.664-1.016-1.223-2.137-2.16-2.88
			c-0.436-0.845-1.168-1.393-1.44-2.4c-0.677-0.843-1.082-1.958-1.92-2.64c-0.309-0.972-1.238-1.322-1.44-2.4
			c-0.549-1.13-1.013-2.347-1.92-3.119c-0.364-1.077-1.156-1.725-1.44-2.881c-0.838-1.321-1.217-3.103-2.16-4.319
			c-0.287-1.313-1.153-2.047-1.44-3.36c0.01-0.975-1.112-2.885-1.68-4.08c-0.533-1.307-1.3-2.38-1.68-3.84
			c-0.551-2.01-1.401-3.719-2.16-5.521c-0.426-1.653-1.095-3.064-1.44-4.8c-0.108-2.613-1.439-6.079-1.68-9.359
			c-0.145-0.589-0.685-2.018-0.24-2.4c1.561-0.957-0.063-2.944,0.24-4.8c-0.836-5.963-0.824-12.779-0.24-18.96
			c0.42-3.981,1.745-8.335,1.92-11.761c0.518-2.201,1.056-4.384,1.68-6.479c0.721-0.958,0.573-2.787,1.44-3.6
			c0,0.079,0,0.159,0,0.239c0.729,1.671,2.431,2.369,3.6,3.601c0.08,0,0.16,0,0.24,0c0.971,1.349,2.358,2.282,3.6,3.359
			c0.753-0.113,0.634,0.646,1.2,0.721c0.948,1.052,2.338,1.661,3.36,2.64c0.396,0.632-0.682,1.447-1.44,1.68
			c-1.28,0.807-3.388,1.772-2.4,4.08c2.09,0.736,3.506-0.647,4.56-1.68c1.944-1.006,3.754-4.379,1.44-6.24
			c-0.816-1.185-2.16-1.841-3.36-2.64c-0.753,0.113-0.634-0.646-1.2-0.72c-0.932-1.068-2.091-1.909-3.12-2.881
			c-0.08,0-0.16,0-0.24,0c-1.214-1.426-2.745-2.534-4.08-3.84c-0.575-0.145-1.095-0.345-1.92-0.24
			c0.56-1.958,1.305-4.053,1.92-5.279c0.806-1.034,1.159-2.522,1.68-3.84c0.593-1.248,1.347-2.334,1.68-3.841
			c1.744-3.855-1.002-10.743,2.4-12.96c0-0.08,0-0.159,0-0.239c1.135-1.025,2.218-2.103,2.88-3.601
			c1.094-1.705,0.91-4.689,1.68-6.72c-0.252-2.652,0.585-4.215,1.68-5.521c0.333-2.946,0.902-5.657,1.44-8.399
			c0.666-3.095,1.653-7.146,1.92-9.84c0.582-2.059,0.941-4.339,1.68-6.24c0.48-2.401,1.6-5.438,1.68-7.44
			c0.322-1.838,1.136-3.184,1.44-5.04c0.858-1.781,1.488-3.792,2.16-5.76c0.173-1.507,0.971-2.39,1.44-3.6
			c0.684-1.396,1.197-2.964,1.92-4.32c0.258-1.342,0.948-2.252,1.44-3.36c0.633-1.287,1.43-2.41,1.92-3.84
			c0.368-1.072,0.882-1.997,1.44-2.88c0.693-1.467,1.614-2.705,2.16-4.32c0.342-1.258,0.972-2.228,1.44-3.36
			c0.765-1.074,1.491-2.189,1.92-3.6c-0.068-0.388,0.227-0.413,0.24-0.72c0.997-0.267,3,0.838,3.6-0.72
			c0.179-0.739-0.659-0.461-0.48-1.2c-1.544-0.856-3-1.8-4.56-2.64c-1.964-0.996-1.877-4.043-2.16-6.721
			c-0.036-4.125-1.805-7.794-0.96-12c0.988,1.377,0.906,3.168,2.88,3.12c3.929-0.096,0.452-9.277,1.44-13.2c0-2.32,0-4.64,0-6.96
			c-0.016-8.175,1.263-15.057,1.44-23.04c0.732-1.75,1.789-4.449,1.92-6c0.372-1.309,1.125-2.235,1.44-3.6
			c0.866-1.214,1.169-2.991,1.92-4.32c0.757-4.203,3.06-6.86,6.48-8.4c0.95-0.811,2.327-1.193,3.12-2.16
			c3.804-1.477,5.131-5.882,10.8-4.8c1.876-0.523,2.988-1.812,4.08-3.12c0.35-1.17,1.389-1.651,1.68-2.88
			c3.083,1.585,8.161-0.265,11.52-0.72c2.568-0.472,4.965-1.114,6.96-2.16c2.193-0.287,3.445-1.515,5.76-1.68
			c1.496-0.664,3.433-0.887,4.8-1.68c1.695-0.625,3.399-1.241,5.28-1.68c1.67-0.569,3.378-1.102,4.8-1.92
			c1.405-0.115,1.863-1.177,3.36-1.2c1.501-0.5,2.923-1.077,4.08-1.92c0.912-0.368,1.593-0.967,2.64-1.2
			c1.435-1.045,3.908-1.052,3.6-3.84c0.08,0,0.16,0,0.24,0c2.008-0.258,4.194-2.264,6.24-0.96c-0.076,0.324-0.342,0.458-0.24,0.96
			c-0.246,1.514-1.204,2.316-1.68,3.6c-0.833,0.927-1.345,2.175-1.92,3.36c-0.303,1.217-1.203,1.836-1.68,2.88
			c-0.695,0.985-1.254,2.106-1.68,3.36c-0.306,1.374-1.202,2.158-1.68,3.36c-0.677,1.163-1.342,2.337-1.68,3.84
			c-0.397,1.603-1.243,2.757-1.44,4.56c-1.368,4.149-0.661,9.209-2.16,13.44c0.303,0.667,1.695,1.493,2.64,0.72
			c0.83-0.69,1.785-1.255,2.4-2.16c1.054-0.626,1.931-1.43,2.64-2.4c0.08,0,0.16,0,0.24,0c1.172-0.908,2.549-1.611,3.6-2.64
			c0.657-0.224,1.023-0.736,1.68-0.96c1.095-0.905,2.615-1.385,3.6-2.4c0.755-0.286,1.27-0.811,2.16-0.96
			c0.755-0.286,1.27-0.811,2.16-0.96c0.409,3.511,1.005,6.835,1.44,10.32c0.499,2.861,1.191,5.529,1.44,8.64
			c0.617,4.663,1.115,9.445,2.16,13.68c0.099,2.701,1.036,4.564,1.2,7.2c0.431,3.249,1.372,5.988,1.92,9.12
			c0.723,2.316,1.22,4.859,1.68,7.44c0.507,2.853,1.314,5.405,1.92,8.16c0.593,1.647,1.097,3.384,1.44,5.28
			c0.45,2.271,1.181,4.259,1.92,6.24c0.479,1.602,1.117,3.043,1.68,4.56c0.875,2.111,1.607,5.306-0.48,7.44
			c-0.655,1.104-0.893,2.626-1.2,4.08c-0.352,2.125-0.084,4.337-0.24,7.68c-0.205,4.412-0.487,10.229-1.68,10.561
			c0.131,0.349-0.28,1.24,0.24,1.199c-0.007,0.233-0.294,0.187-0.48,0.24c-2.505-1.555-7.818-1.589-10.08,0.24
			c-1.109,0.171-1.587,0.973-2.16,1.68c-0.707,0.653-1.245,1.476-1.2,2.88c0,0.32,0,0.641,0,0.96c0.066,2.574,1.2,4.081,1.92,6
			c0.304,1.457,1.169,2.352,1.44,3.841c0.286,1.554,1.018,2.662,1.92,3.6c0.757,0.203,0.683,1.237,1.44,1.44
			c0.755,1.085,1.813,1.867,2.88,2.64c0.906-0.106,1.126,0.474,1.68,0.72c-3.789,2.266-6.267,5.674-10.08,8.4
			c-0.928,0.832-2.177,1.343-2.88,2.399c-0.437-4.442-0.949-8.811-1.2-13.439c-0.431-6.289-0.912-12.528-1.44-18.721
			c0-0.079,0-0.159,0-0.239c-0.157-1.522,0.316-3.677-0.24-4.8c0-0.24,0-0.48,0-0.721c0-0.88,0-1.76,0-2.64c0-1.04,0-2.08,0-3.12
			c0.558-1.442,0.082-3.918,0.24-5.76c0-0.08,0-0.16,0-0.24c0.375-1.146,0.521-2.52,0.72-3.84c-0.613-0.379-2.507-0.379-3.12,0
			c0,0.32,0,0.64,0,0.96c0.044,1.645-0.474,2.726-0.48,4.32c-0.558,1.682-0.082,4.398-0.24,6.479c0,1.521,0,3.04,0,4.561
			c0.152,0.808-0.31,2.229,0.24,2.64c0,0.8,0,1.6,0,2.399c0.153,0.888-0.311,2.392,0.24,2.881c0,0.399,0,0.8,0,1.199
			c0.154,0.967-0.312,2.553,0.24,3.12c0,0.16,0,0.32,0,0.48c0.153,0.887-0.311,2.391,0.24,2.88c0,0.08,0,0.16,0,0.24
			c0.208,4.352,0.538,8.581,1.2,12.479c0.365,1.636,0.508,3.493,0.48,5.521c0.157,1.283-0.315,3.195,0.24,4.08
			c0.796,2.044-1.724,2.503-2.88,3.359c-1.127,0.793-2.252,1.588-3.12,2.641c-0.053,0.907-0.754,1.165-0.96,1.92
			c-0.307,0.013-0.333,0.308-0.72,0.24c0.138,1.586-1.862,3.936-1.44,5.76c0,1.6,0,3.2,0,4.8c-0.987,0.533-1.769,1.271-2.88,1.68
			c-1.34,0.82-2.741,1.579-3.84,2.641c-0.874,0.566-1.538,1.342-2.64,1.68c-0.01-0.79-0.405-1.194-0.24-2.16
			c-0.515-4.285-1.737-7.862-1.92-12.479c-1.135-9.301-1.278-20.371-1.2-29.521c0-0.08,0-0.16,0-0.24
			c-0.373-0.347-1.12-0.319-1.44-0.72c0.448-3.872,1.067-7.573,1.2-11.76c-0.198-2.198,0.493-3.508,1.2-4.8
			c0.78-0.26,1.463-0.617,1.92-1.2c-0.125-1.235-0.138-2.582-0.72-3.36c-0.723-0.647-1.358-0.436-2.16,0
			c-0.749-0.851-1.428-1.771-2.4-2.399c0-0.08,0-0.16,0-0.24c-1.046-1.194-2.089-2.391-3.12-3.601c-1.859-1.66-3.372-3.668-4.8-5.76
			c-0.915-2.605-1.27-5.771-2.16-8.399c-0.415-2.865-1.112-5.447-1.44-8.4c-0.41-4.23-1.646-7.635-1.92-12
			c-0.722-3.438-0.929-7.392-1.68-10.8c-0.365-5.324-0.024-11.975,0.24-18c-0.151-5.209,0.308-11.028-0.24-15.84
			c0.311-6.551-1.474-13.085-1.68-19.92c-0.235-2.077-1.007-5.262,0.24-6.72c0.798-0.642,1.072-1.809,1.68-2.64
			c0.355-1.069,0.078-1.488-0.24-2.4c-0.865-0.142-1.609-0.405-2.4,0c-0.822,0.698-1.318,1.722-1.68,2.88
			c-2.859,4.598-0.044,13,0,19.68c2.57,15.086-1.239,33.978,1.92,48c0.449,3.791,0.961,7.518,1.68,11.04
			c0.008,3.034,1.04,7.121,1.92,10.08c0.155,1.846,0.688,3.313,0.96,5.041c-1.105-0.655-1.519-2.002-2.16-3.121
			c-0.521-1.238-1.238-2.281-1.92-3.359c-0.38-1.22-1.454-1.746-1.68-3.12c-0.423-1.097-0.919-2.12-1.68-2.88
			c-0.533-0.986-1.271-1.77-1.68-2.88c-0.466-1.135-0.749-2.451-1.92-2.88c-0.758,0.117-0.848-0.433-1.68-0.24
			c-1.287,0.398-1.067,2.594-0.48,3.6c0.438,1.482,1.192,2.648,1.92,3.84c1.581,5.619,4.405,9.995,7.68,13.92
			c1.005,1.235,1.682,2.799,2.88,3.841c0.074,0.565,0.833,0.446,0.72,1.2c0.741,1.018,1.39,2.13,2.4,2.88
			c1.499,1.781,2.909,3.651,4.32,5.52c1.617,2.144,3.412,4.108,5.28,6c1.143,0.099,1.104,1.507,0.48,2.16
			c-1.227,1.446-4.484-0.607-6.96-0.24c-3.363,0.003-6.067-0.653-8.88-1.2c-2.072-0.487-4.091-1.028-5.52-2.159
			c-2.162-1.118-3.374-3.187-5.28-4.561c-0.75-1.01-1.861-1.658-2.88-2.399c-2.482-1.358-4.413-3.268-6.96-4.561
			c-0.965-1.035-2.609-1.391-3.84-2.16c-1.412-0.268-2.311-1.049-3.6-1.439c-1.637-0.844-3.323-1.637-5.52-1.92
			c-1.151-0.156-2.031-0.361-3.12,0c-0.367,0.434-0.865,0.734-0.72,1.68c0.187,0.053,0.279,0.201,0.24,0.48
			c2.199,0.601,4.314,1.285,6.48,1.92c1.496,0.184,2.254,1.105,3.6,1.439c1.173,0.908,2.641,1.52,4.08,2.16
			c2.396,1.044,4.229,2.651,6.24,4.08c0.915,1.085,2.215,1.784,3.36,2.64c0.897,0.143,1.241,0.84,1.92,1.2
			c0.904,0.937,1.862,1.818,3.12,2.4c0.358,0.602,1.078,0.842,1.68,1.199c1.161,1.319,3.124,1.837,5.28,2.16
			c3.093,0.188,5.246,1.314,8.64,1.2c0.912,0.368,2.161,0.399,3.36,0.48c0.08,0,0.16,0,0.24,0c1.129,0.711,3.4,0.28,4.32,1.199
			c-3.387,0.786-8.644-0.604-13.2-0.239c-2.227-0.014-3.536-0.944-5.28-1.44c-1.146-0.774-2.426-1.414-3.84-1.92
			c-0.566-0.474-1.249-0.831-1.92-1.2c-1.055-0.944-2.365-1.635-3.6-2.399c-2.57-1.19-4.428-3.093-6.72-4.561
			c-0.756-1.003-1.982-1.537-3.12-2.16c-1.432-0.407-2.424-1.255-3.84-1.68c-1.241-1.079-3.336-1.304-5.04-1.92
			c-1.818-0.342-3.373-0.947-5.04-1.44c-0.632-0.654-2.783-1.049-3.84-0.479c-2.681,2.053,2.307,3.638,4.32,4.08
			c2.192,0.768,4.232,1.688,6.72,2.16c0.964,0.396,2.045,0.675,2.88,1.199c1.394,0.927,2.996,1.645,4.32,2.641
			c0.657,0.224,1.023,0.736,1.68,0.96c0.64,0.96,1.623,1.577,2.64,2.16c0.788,0.092,1.058,0.701,1.68,0.96
			c1.036,1.124,2.478,1.842,3.84,2.64c0.857,0.343,1.5,0.9,2.4,1.2c0.703,0.977,1.942,1.418,3.12,1.92
			c1.504,0.255,2.306,1.214,3.84,1.44c3.843,2.076,10.472,1.368,15.84,1.92c0.48,0,0.96,0,1.44,0c0.586,1.979-0.984,5.07-0.24,7.199
			c-0.5,3.58-0.96,7.2-1.44,10.801c-0.918,3.241-1.35,6.97-2.16,10.319c-0.619,1.966-1.018,4.52-1.68,5.761
			c-0.53,2.112-1.557,5-1.68,6.72c-0.401,1.759-1.005,3.315-1.44,5.04c-0.76,1.722-1.732,4.505-1.92,6
			c-0.482,1.438-0.996,2.844-1.44,4.32c-0.733,1.667-1.558,3.242-1.92,5.279c-0.847,1.073-1.382,2.458-2.4,3.36
			c-0.433,0.527-0.937,0.983-1.2,1.68c-0.24,0-0.48,0-0.72,0c0.332-1.132-1.078-0.521-1.2-1.2c-9.043,0.244-17.183-0.416-25.44-0.96
			c-4.714-0.086-8.648-0.951-12.96-1.439c-3.546-0.526-8.615-1.845-12-0.96c-0.084,0.864-0.367,1.156,0,1.92
			c0.122,0.759,1.371,0.389,1.68,0.96c-1.752,0.072-2.936-0.425-4.8-0.24c-0.08,0-0.16,0-0.24,0c-0.411-0.549-1.832-0.088-2.64-0.24
			c-4.491,0.012-7.82-1.14-12.24-1.199c-0.57-0.398-2.789-0.398-3.36,0c-2.074,3.336,5.396,2.981,8.4,3.6
			c4.871,0.97,10.493,1.188,15.84,1.68c4.265,0.216,7.84,1.12,12,1.44c3.395,0.765,6.849,1.471,10.32,2.16
			c2.186,0.215,3.739,1.062,5.76,1.439c-0.304,0.896-1.462,0.938-2.4,1.2c-1.935,0.545-3.504,1.455-5.04,2.4
			c-1.525,0.395-2.446,1.395-4.08,1.68c-0.841,0.6-2.163,0.717-2.88,1.44c-0.454,0.476-1.134,0.116-1.68,0
			c-0.751-0.369-1.774-0.467-2.88-0.48c-0.08,0-0.16,0-0.24,0c-2.427-0.612-5.221-0.858-7.68-1.439
			c-2.09-0.39-3.956-1.004-5.76-1.681c-1.202-0.798-2.843-1.157-4.56-1.439c-0.08,0-0.16,0-0.24,0
			c-0.656-0.384-1.586-0.494-2.64-0.48c-2.849-0.191-5.087-0.992-7.92-1.2c-2.601-0.266-7.178-1.897-9.36-0.239
			c-0.001,2.938,3.878,2.335,6.24,2.64c2.629,0.34,5.37,0.896,7.44,1.2c2.753,0.766,5.96,1.079,8.64,1.92
			c1.899,0.66,3.974,1.146,5.76,1.92c1.185,0.655,2.686,0.994,4.32,1.2c1.788,0.131,3.557,0.283,5.04,0.72
			c-0.76,1.16-2.425,1.415-3.6,2.16c-1.014,0.267-1.823,0.737-2.4,1.439c-1.023,0.576-1.906,1.294-3.12,1.681
			c-1.356,0.884-2.973,1.507-4.08,2.64c-1.074,0.526-1.746,1.454-2.88,1.92c-0.175-0.654,0.477-0.483,0.48-0.96
			c0.951-0.568,1.425-1.614,2.16-2.399c2.464-2.104-0.938-4.828-2.88-5.521c-1.301-1.019-3.087-1.553-4.8-2.16
			c-1.701-0.459-3.16-1.16-4.8-1.68c-1.086-0.834-2.665-1.176-4.08-1.68c-1.536-0.225-2.498-1.022-3.84-1.44
			c-0.444-0.555-2.259-0.76-2.64,0c-2.104,2.038,1.467,3.521,3.12,4.08c1.06,0.62,2.141,1.219,3.6,1.44
			c1.55,0.29,2.57,1.109,4.08,1.439c1.407,0.833,3.086,1.394,4.8,1.92c1.58,0.34,2.8,1.04,4.08,1.68
			c-0.241,0.959-1.022,1.378-1.44,2.16c-0.967,0.794-1.35,2.171-2.16,3.12c-0.241,0.959-1.022,1.378-1.44,2.16
			c-0.571,0.788-1.339,1.381-1.68,2.4c-0.654,0.705-1.105,1.614-1.68,2.399c-0.837,0.283-0.609,1.631-1.68,1.681
			c0.202-0.279,0.316-0.644,0.24-1.2c-2.317-2.323-3.809-5.472-5.76-8.16c0-0.08,0-0.16,0-0.24c-1.741-2.898-4.582-4.698-7.44-6.479
			c-1.12-0.161-1.439-1.121-2.88-0.96c-0.315,0.084-0.377,0.422-0.72,0.479c0.072,2.06,2.283,2.884,3.6,3.84
			c2.269,1.646,4.25,2.981,5.76,5.521c0,0.08,0,0.16,0,0.24c0.789,1.291,1.321,2.839,2.4,3.84c0,0.08,0,0.16,0,0.239
			c0.795,1.365,1.966,2.354,3.12,3.36c0.975,0.242,1.672-0.729,1.68,0.48c-0.395,1.444-1.318,2.361-1.68,3.84
			c-1.659,3.346-1.356,9.04,0.24,12.24c0.247,0.873,0.755,1.484,0.96,2.399c0.168,1.386,3.166,1.905,2.64-0.479
			c-0.479-1.681-1.286-3.035-1.44-5.04c-0.646-2.063-0.254-4.834-0.24-6.48c0.814-1.985,1.167-4.433,2.64-5.76
			c-0.086,1.767,0.062,3.298,0.24,4.8c0.415,2.866,1.134,5.427,1.92,7.92c0.487,1.193,1.061,2.3,1.44,3.601
			c0.393,1.607,0.934,3.065,1.92,4.079c0.266,1.254,1.235,1.806,1.44,3.12c0.004,1.979,3.473,3.605,4.32,1.2c0-0.399,0-0.8,0-1.2
			c-0.526-1.313-0.995-2.685-1.92-3.6c-0.395-1.525-1.395-2.445-1.68-4.08c-0.439-1.801-1.313-3.167-1.92-4.8
			c-0.428-2.212-1.319-3.961-1.44-6.48c-0.193-1.911-0.431-4.146,0-6c0.779-2.327,3.212-3.124,4.8-4.8
			c1.345-1.42,2.653-3.325,5.04-4.08c1.226-0.614,2.446-1.233,3.36-2.16c0.967-0.393,1.673-1.047,2.64-1.439
			c1.177-0.743,2.631-1.209,3.6-2.16c1.222-0.458,2.077-1.283,3.36-1.68c0.756-0.524,1.907-0.653,2.4-1.44
			c1.473-0.447,2.482-1.357,4.08-1.68c1.247-0.673,2.678-1.162,3.84-1.92c1.141-0.46,2.153-1.048,3.36-1.44
			c1.609-0.631,3.348-1.132,4.8-1.92c1.556-0.604,3.078-1.242,5.04-1.44c1.673-0.406,3.397-0.762,4.8-1.439
			c2.321-0.8,4.732-1.508,7.44-1.92c2.436-0.604,5.065-1.016,6.96-2.16c1.101-0.26,1.838-0.882,2.88-1.2
			c1.347-0.333,2.328-1.032,3.36-1.68c0.808-0.793,1.973-1.228,3.12-1.681c1.433-0.566,2.759-1.24,3.84-2.159
			c0.997-0.203,1.437-0.964,2.4-1.2c1.761-0.639,3.236-1.564,4.56-2.641c0.716-0.403,1.289-0.95,2.16-1.199
			c0.717-0.723,1.853-1.027,2.4-1.92c0.622-0.259,0.893-0.868,1.68-0.96c1.255-0.505,1.947-1.573,3.36-1.921c0,2,0,4,0,6
			c-0.147,2.228,0.188,3.973,0.24,6c-0.547-0.066-0.902,0.059-0.96,0.48c-0.927,3.073-0.724,7.276-0.72,11.28
			c-0.4,3.761-0.4,9.199,0,12.96c-0.254,6.414,0.969,11.352,1.44,17.04c0.436,4.284,1.271,8.17,1.92,12.24
			c0.509,1.811,0.981,3.658,1.44,5.52c0.54,1.861,0.91,3.89,1.92,5.28c0.067,1.213,0.96,1.599,1.2,2.64
			c0.274,0.925,2.025,0.832,2.88,0.48c0.547-1.174,0.516-2.163-0.24-3.12c-0.674-1.406-1.235-2.925-1.68-4.561
			c-0.073-1.449-1.271-3.847-1.68-5.76c-0.824-2.216-1.146-4.935-1.68-7.439c0.043-4.124-1.544-8.696-1.68-13.44
			c-1.056-6.471-1.217-16.933-0.72-24.96c0.758,1.755,1.047,5.107,1.92,7.439c-0.046,1.969,1.079,4.84,1.68,6.961
			c0.555,1.523,1.16,2.999,1.68,4.56c-0.073,1.356,1.237,3.4,1.92,4.8c0.45,1.07,1.125,1.916,1.44,3.12
			c0.581,1.34,1.116,2.724,1.92,3.84c0.45,1.07,1.125,1.915,1.44,3.12c0.625,1.135,1.205,2.314,1.92,3.36
			c0.47,0.89,1.177,1.543,1.44,2.64c0.584,0.937,1.02,2.021,1.92,2.641c1.543,2.376,3.945,3.895,5.28,6.479
			c-1.834-0.246-3.382-0.778-4.8-1.44c-1.646-1.313-2.933-2.986-4.56-4.319c0-0.08,0-0.16,0-0.24
			c-1.022-1.058-1.321-2.839-2.16-4.08c-0.542-1.361-1.504-3.351-1.92-5.52c-0.577-1.663-0.828-3.651-1.68-5.04
			c-0.293-1.128-0.931-3.393-1.44-5.04c-0.211-1.242-1.657-1.373-2.88-0.96c-0.667,1.986,0.146,4.247,0.48,6
			c0.458,1.701,1.169,3.15,1.44,5.04c0.467,1.772,1.017,3.463,1.92,4.8c0.439,1.241,1.245,2.114,1.68,3.359
			c0.393,1.528,1.036,2.805,1.68,4.08c0.503,1.098,1.224,1.977,1.68,3.12c0.367,1.394,1.033,2.487,1.92,3.36
			c1.175,2.025,2.875,3.525,4.32,5.28c-1.093,0.427-1.705,1.335-2.88,1.68c-1.094,0.346-2.055,0.825-2.88,1.44
			c-1.446,0.714-3.053,1.267-4.56,1.92c-1.689,0.551-3.069,1.411-4.56,2.159c-0.912,0.368-1.593,0.967-2.64,1.2
			c-1.292,0.789-2.709,1.451-3.84,2.4c-0.879,0.321-1.484,0.916-2.4,1.2c-1.098,0.422-1.982,1.058-2.88,1.68
			c-0.523,0.677-1.565,0.835-2.16,1.44c-1.11,0.81-2.317,1.522-3.12,2.64c-0.566,0.074-0.446,0.833-1.2,0.72
			c-1.342,1.139-2.783,2.177-3.84,3.601c-0.08,0-0.16,0-0.24,0c-1.095,0.904-2.381,1.619-3.36,2.64
			c-0.606,0.354-1.072,0.848-1.92,0.96c-1.383,0.937-3.023,1.617-4.8,2.16C138.606,629.037,133.191,629.693,129.2,628.241z
			 M131.36,538.001c-1.416,0.104-2.535,0.505-3.6,0.96c-1.9,0.34-3.3,1.18-5.28,1.439c-1.614,0.325-2.264,0.486-3.84,0
			c-1.92-0.399-3.836-0.803-5.52-1.439c-2.153-0.967-4.753-1.486-7.44-1.92c-1.341,0.141-1.85-0.55-3.12-0.48
			c3.229-0.587,6.646,0.713,10.32,0.48c0.08,0,0.16,0,0.24,0c3.933,0.707,8.539,0.741,13.2,0.72
			C127.923,537.918,130.157,537.444,131.36,538.001z M100.88,536.081c-0.4,0-0.8,0-1.2,0
			C99.313,535.344,101.248,535.344,100.88,536.081z M96.32,535.601c-2.683-0.757-6.197-0.683-8.88-1.439c0.4,0,0.8,0,1.2,0
			c3.691,0.229,6.897,0.942,10.32,1.439C99.924,536.193,97.202,535.433,96.32,535.601z M181.04,697.841
			c-0.989-0.131-1.404-0.835-2.16-1.199c-0.706-0.655-1.614-1.106-2.4-1.681c-1.204-0.396-1.968-1.231-3.12-1.68
			c-0.987-0.853-2.107-1.572-3.36-2.16c-1.009-0.271-1.424-1.136-2.4-1.439c-0.647-0.953-1.885-1.315-2.88-1.921
			c-1.156-0.283-1.804-1.076-2.88-1.439c-0.885-0.875-2.236-1.284-3.36-1.92c-1.731-0.27-2.797-1.203-4.32-1.681
			c-1.054-0.945-2.691-1.309-4.08-1.92c-0.894-0.146-1.572-0.508-2.16-0.96c-0.033-1.552,0.409-2.631,0.48-4.08
			c0.951-2.089,1.276-4.803,2.4-6.72c0.217-0.423,0.537-0.743,0.96-0.96c0.08,0,0.16,0,0.24,0c2.918-3.642,6.355-6.765,9.36-10.32
			c0-0.079,0-0.16,0-0.239c1.241-0.92,2.046-2.273,3.12-3.36c0-0.08,0-0.16,0-0.24c1.398-1.002,2.287-2.513,3.36-3.84
			c-0.114-0.753,0.646-0.634,0.72-1.2c0.08,0,0.16,0,0.24,0c0.792-1.287,1.871-2.289,2.64-3.6c0.428-0.452,0.649-1.111,1.2-1.44
			c0.08,0,0.16,0,0.24,0c0.414-0.945,1.15-1.569,1.68-2.399c0.143-0.898,0.839-1.241,1.2-1.92c0.08,0,0.16,0,0.24,0
			c0.397-1.043,1.249-1.632,1.68-2.641c0-0.08,0-0.16,0-0.24c0.701,13.939-0.479,29.76,1.44,42.48
			c0.173,5.427,0.545,10.655,1.44,15.36C180.193,695.022,181.353,697.354,181.04,697.841z M405.583,723.914
			c-0.28-2.029-0.919-4.663-0.483-6.357C404.664,719.252,405.305,721.886,405.583,723.914z M403.708,711.756
			c0.913-0.025,1.825-0.05,2.196,0.469C405.532,711.707,404.62,711.731,403.708,711.756z M406.4,708.401
			c0.176,0.224,0.437,0.363,0.721,0.479c-0.627,0.813-0.492,2.388-0.96,3.36c-0.033-0.001-0.097-0.005-0.164-0.01
			c-0.023-0.002-0.046-0.003-0.071-0.005c-0.003-0.001-0.008-0.001-0.012-0.001c-0.245-1.107-2.118-0.587-3.354-0.704
			c0,0.08,0,0.16,0,0.24c-5.766-0.108-12.518-0.81-18.479,0c-1.976,0.424-3.822,0.979-5.28,1.92c-1.801,0.519-3.145,1.495-5.04,1.92
			c-1.238,0.602-2.775,0.904-3.84,1.68c-1.092,0.349-2.036,0.845-3.12,1.2c-1.733,0.666-3.334,1.466-4.8,2.399
			c-0.372,0.35-0.504,0.937-0.48,1.681c0.728,1.421,2.484,0.255,3.601,0c1.3-0.7,2.834-1.166,4.08-1.92
			c1.056-0.464,2.08-0.96,3.359-1.2c1.675-0.485,3.354-0.967,4.561-1.92c1.728-0.673,3.368-1.432,5.279-1.92
			c2.248-0.553,4.288-1.313,7.2-1.2c0.08,0,0.16,0,0.24,0c3.471,0.689,7.009,1.311,11.28,1.2c0.002,0.637-0.091,1.37,0.239,1.68
			c0.116,1.244,0.104,2.615,0.48,3.6c0.143,0.498-0.297,1.577,0.24,1.681c0.369,4.293,0.69,8.723,0.96,12.479
			c0.114,2.686,0.101,5.5,0.479,7.92c0,0.08,0,0.16,0,0.24c0,0.96,0,1.92,0,2.88c0,0.08,0,0.16,0,0.24
			c-0.398,0.57-0.398,2.789,0,3.36c1.121,1.934,1.045,7.574,0.24,9.84c0,0.08,0,0.16,0,0.24c-21.601,0-43.2,0-64.8,0
			c0.832-0.769,2.508-0.691,3.359-1.44c1.885-0.355,3.235-1.245,5.28-1.44c1.745-0.335,3.453-0.706,4.8-1.439
			c3.118-0.722,5.91-1.77,9.36-2.16c2.8-0.24,5.054-1.025,7.44-1.68c3.403-0.517,6.543-1.297,9.84-1.92
			c1.5-0.34,3.139-0.541,4.319-1.2c1.965-0.676,4.081-1.2,6.24-1.681c2.118-0.521,4.201-1.078,5.76-2.159c0.08,0,0.16,0,0.24,0
			c1.026-0.307,1.765-2.298,0.48-2.88c-2.048-0.8-3.195,1.032-5.28,0.96c-2.175,0.545-4.335,1.104-6.24,1.92
			c-2.675,0.524-4.714,1.686-7.68,1.92c-2.122,0.518-4.504,0.776-6.48,1.439c-2.41,0.39-4.651,0.948-6.96,1.44
			c-2.999,0.44-5.969,0.911-8.399,1.92c-1.955,0.445-3.75,1.05-5.761,1.44c-2.438,0.521-4.784,1.136-6.72,2.159
			c-1.729,0.352-2.895,1.267-4.8,1.44c-2.29,0.51-4.483,1.117-6.48,1.92c-3.533,2.002-9.397,1.44-15.6,1.44
			c-56.922,0-116.39,0-172.8,0c-1.196-0.805-1.781-2.22-2.4-3.601c-0.223-0.656-0.736-1.023-0.96-1.68
			c1.548-1.172,3.08-2.36,4.08-4.08c0.288-1.004,0.25-1.862-0.24-2.64c-1.128-1.752-5.11-0.65-7.68-0.961
			c0.044-0.203-0.094-0.226-0.24-0.239c0.253-1.907,0.88-3.439,1.2-5.28c0.341-1.738,1.015-3.145,1.68-4.56
			c0.601-1.8,1.2-3.601,1.68-5.521c0.427-2.133,1.115-4.005,1.68-6c0.549-3.052,2.028-6.451,1.68-9.6
			c0.399-3.361,0.421-7.1,0.96-10.32c0.558-0.58,0.643,0.375,1.2,0.48c1.101,1.299,1.996,2.804,3.6,3.6
			c1.065,0.375,1.923,0.957,3.12,1.2c2.023,0.58,5.065,0.107,6.96-0.24c3.484-0.596,7.788-0.372,9.12-3.12
			c-0.467-2.253-2.548-2.892-4.56-3.6c-1.437-0.563-2.92-1.08-4.32-1.681c-2.064-0.416-3.641-1.319-5.52-1.92
			c-1.091-0.828-2.672-1.168-3.84-1.92c-3.21,0.181-3.847-5.208-0.48-4.56c0,0.08,0,0.16,0,0.239
			c1.232,0.608,2.407,1.274,3.84,1.681c1.321,0.118,1.955,0.925,3.12,1.2c1.381,0.699,2.944,1.216,4.56,1.68
			c2.169,0.391,3.741,1.379,5.76,1.92c1.38,0.699,2.944,1.216,4.56,1.68c2,0.4,3.515,1.286,5.52,1.681
			c1.214,0.865,3.169,0.99,4.56,1.68c0.597-0.037,0.743,0.377,1.2,0.48c0.008,1.048-0.208,1.871-0.48,2.64
			c-1.409,5.558-0.104,12.128,0.24,18c1.1,2.34-0.537,7.417,1.44,8.88c2.615,0.041,1.983-3.113,1.92-4.8
			c-0.276-7.383-2.645-16.996,0-23.28c1.389,0.611,2.557,1.442,4.08,1.92c0.89,0.15,1.405,0.675,2.16,0.96
			c0.632,1.263,3.279,2.929,4.32,0.96c0.78-1.749-0.632-2.926-1.68-3.6c-0.854-1.066-1.931-1.908-3.12-2.641
			c-0.788-0.092-1.058-0.701-1.68-0.96c-1.476-1.323-3.623-1.977-3.84-4.56c-0.086-1.674-1.073-2.446-1.44-3.84
			c-1.017-5.063-1.429-12.811-2.16-18.96c-0.536-6.404-0.72-13.333-0.72-20.641c0-7.999,0.553-17.498-0.72-23.76
			c0.597-0.924,1.3-1.74,1.92-2.64c0.08,0,0.16,0,0.24,0c0.374-0.906,1.066-1.494,1.44-2.4c0.202-1.078,1.131-1.429,1.44-2.4
			c0.08,0,0.16,0,0.24,0c0.625-1.055,1.331-2.029,1.92-3.119c0.388-0.572,0.862-1.059,1.2-1.681c0.08,0,0.16,0,0.24,0
			c0.666-1.255,1.52-2.32,2.16-3.6c0.092-0.788,0.702-1.059,0.96-1.681c0.929-0.91,1.595-2.085,2.16-3.359
			c0.564-0.636,1.035-1.365,1.44-2.16c0.08,0,0.16,0,0.24,0c0.544-0.896,1.216-1.664,1.68-2.64c0.272-1.008,1.005-1.556,1.44-2.4
			c0.852-0.828,1.467-1.893,1.92-3.12c1.335-1.586-0.869-2.49,0.24-3.6c0.689-0.689,0.81-0.606,2.88,0
			c1.846,0.634,3.649,1.311,5.52,1.92c0.206,1.635,0.968,2.711,0.96,4.56c0.642,3.039,1.04,6.32,1.92,9.12
			c0.412,2.168,1.427,5.541,1.68,8.641c0.626,4.414,1.366,8.713,1.92,13.199c-0.185,0.905,0.293,1.147,0.24,1.92
			c0.515,0.626,2.125,0.626,2.64,0c0.383-0.977,0.504-2.216,1.68-2.399c1.404-0.996,2.945-1.855,4.08-3.12c0.08,0,0.16,0,0.24,0
			c1.133-1.027,2.533-1.787,3.6-2.88c1.799-1.722,3.658-3.382,5.76-4.8c0.08,0,0.16,0,0.24,0c0.474-0.966,1.719-1.161,2.16-2.16
			c0.65-0.149,0.597-1.003,1.44-0.96c1.362-1.198,2.968-2.152,4.08-3.601c0.08,0,0.16,0,0.24,0c0.889-0.791,1.996-1.364,2.64-2.399
			c0.733-0.227,0.752-1.168,1.68-1.2c0.08,0,0.16,0,0.24,0c0.515-0.925,1.495-1.385,2.16-2.16c0.332-0.402,1.108-1.593,1.44-1.2
			c-0.035,1.795,0.047,3.474,1.2,4.08c0.435,6.246,0.44,13.672,0,18.96c-0.277,1.723-0.913,3.087-1.44,4.561
			c-0.683,2.039-2.205,4.513-1.92,6.72c-1.748,6.253-1.389,15.434-0.24,22.08c0.646,5.837,2.659,13.867,0.72,19.92
			c-0.08,0-0.16,0-0.24,0c-3.354-0.486-6.24-1.439-9.84-1.68c-3.514-0.247-6.358-1.162-9.84-1.44
			c-5.134-1.106-11.092-1.388-16.8-1.92c-4.199,0.066-9.632-1.016-12.96-0.24c-0.058,1.018,0.019,1.901,0.72,2.16
			c5.064,1.576,12.182,1.098,17.76,2.16c4.132,0.268,7.843,0.957,11.76,1.44c3.079,0.761,6.398,1.281,9.84,1.68
			c2.178,0.687,10.158-0.365,9.84,1.92c-0.193,1.391-2.254,0.76-4.08,0.96c-7.424,0.464-15.306,0.17-22.8,0c-0.56,0-1.12,0-1.68,0
			c-1.925,0.227-4.595-0.293-5.739,0.714c-0.006,0.003-0.015,0.004-0.021,0.006c0.001,0.004,0.003,0.006,0.004,0.009
			c-0.083,0.076-0.179,0.139-0.244,0.231c0.194,0.046,0.239,0.241,0.48,0.24c1.169,0.03,1.67,0.73,2.64,0.96
			c8.227,2.706,21.385,1.89,30.96,1.2c0.295,1.66-0.593,3.399-0.24,4.319c-2.493,6.585-0.628,15.377-0.72,22.561
			c-0.832-0.128-1.278-0.643-2.16-0.72c-1.088-0.832-2.597-1.243-4.32-1.44c-0.08,0-0.16,0-0.24,0
			c-0.546-0.414-1.407-0.514-2.4-0.479c-3.52-0.32-6.515-1.165-9.84-1.681c-2.535-0.905-5.418-1.462-8.4-1.92
			c-2.096-0.225-3.761-0.879-5.76-1.2c-2.419-0.86-5.266-1.294-7.68-2.159c-1.153,0.032-0.787-1.453-2.4-0.961
			c-0.08,0-0.16,0-0.24,0c-0.866,0.894-0.29,2.303,0.48,2.881c0.08,0,0.16,0,0.24,0c0,0.079,0,0.16,0,0.239
			c1.457,0.944,3.234,1.566,5.28,1.92c2.497,0.303,4.462,1.138,6.96,1.44c2.319,0.881,4.848,1.552,7.44,2.16
			c2.308,0.172,3.919,1.041,6.24,1.2c2.7,0.9,5.879,1.321,8.88,1.92c1.944,0.136,3.022,1.138,4.8,1.439
			c0.579,0.781,1.932,0.788,2.4,1.681c0.102,0.501-0.164,0.635-0.24,0.96c-1.477,8.334-1.386,18.597,0,26.88c0,0.08,0,0.16,0,0.24
			c0,0.88,0,1.76,0,2.64c-0.333,1.893,3.306,2.869,3.84,0.96c0.582-1.786,0.1-4.576-0.24-6.24c-0.887-7.025-0.666-14.783,0.24-20.88
			c2.786-6.203-0.307-16.588,0.48-24.72c0.25-2.583,1.438-4.725,1.2-6.96c1.116-9.938,2.353-21.977,0-31.44
			c-0.631-6.798-2.423-17.908,0-24.96c0.261-1.818,1.089-3.07,1.68-4.56c1.537-2.224,2.037-5.484,1.92-9.36
			c0.227-5.743,0.357-11.571,0-17.28c-0.166-0.794-0.824-1.096-1.44-1.439c0.14-1.261-0.423-1.817-0.24-3.12
			c0.623-0.978,1.262-1.938,2.4-2.4c1.005-1.635,2.551-2.729,3.6-4.32c0.018-2.178-0.678-3.642-0.96-5.52
			c0.094-2.337-1.168-5.391-1.68-7.92c-0.158-0.505-0.635-1.067-0.24-1.44c0.581,0.779,1.022,1.697,1.44,2.641
			c0,0.08,0,0.159,0,0.239c0.731,0.39,1.035,1.205,1.92,1.44c2.432,0.809,4.864,2.046,7.68,1.439
			c0.753-0.162,0.791-0.704,1.68-1.199c1.825-1.017,4.266-1.885,5.04-3.84c2.542-2.328,1.388-8.68-0.48-10.801
			c-1.603-1.996-2.94-4.26-6-4.8c-0.414-0.397-2.466-0.397-2.88,0c-1.456,0.144-2.336,0.863-3.6,1.2
			c-1.637,0.763-2.896,1.903-3.84,3.359c-0.053,0.908-0.754,1.166-0.96,1.921c-0.623,0.577-0.485,1.914-0.96,2.64
			c0.178-3.263,0.397-6.482,0.96-9.36c0.689-1.471,1.399-2.921,2.16-4.319c0.131-0.989,0.836-1.405,1.2-2.16
			c1.165-1.795,2.157-3.764,2.16-6.721c0.176-3.854,0.469-7.357,0.24-11.039c0.859,0.26,1.866,0.374,2.64,0.72
			c2.194,1.318,4.869-0.348,7.68,0c0.08,0,0.16,0,0.24,0c0.887-0.153,2.391,0.311,2.88-0.24c1.219-0.222,2.048-0.832,3.12-1.2
			c0.969-1.248,3.341-0.523,3.84-2.64c1.1,0.14,1.119-0.802,1.68-1.2c0.08,0,0.16,0,0.24,0c0.281-0.359,0.298-0.981,0.72-1.2
			c1.464,0.616,3.318,0.842,5.04,1.2c2.982,0.219,4.938,1.464,7.92,1.68c-1.117,1.431-0.09,3.173,0.48,4.32
			c0.09,1.671-0.221,3.741,0.24,5.04c-0.08,0-0.16,0-0.24,0c0.134,3.146,1.19,5.37,2.16,7.68c0.381,1.299,1.022,2.338,1.439,3.601
			c0.727,1.113,1.23,2.45,2.16,3.359c0.074,0.566,0.834,0.447,0.721,1.2c0.615,1.226,1.871,1.809,2.4,3.12c0,0.08,0,0.16,0,0.24
			c-0.916,1.404-1.279,3.361-1.681,5.28c-0.271,2.289-1.162,3.957-1.681,6c-0.579,2.46-1.58,4.499-1.92,7.199
			c-0.613,2.026-0.977,4.304-1.68,6.24c-0.713,2.567-1.028,5.531-1.92,7.92c0.935,1.386,3.182,1.46,4.801,2.16
			c1.594,0.246,2.51,1.169,3.84,1.68c1.689,0.871,3.527,1.593,5.52,2.16c1.321,0.119,1.955,0.926,3.12,1.2
			c1.426,0.975,3.402,1.397,5.04,2.16c1.724,0.517,3.313,1.168,5.04,1.68c1.258,0.902,3.194,1.126,4.8,1.681
			c2.003,0.396,3.701,1.099,5.76,1.439c2.521,0.999,5.461,1.579,8.4,2.16c0.89-0.17,1.03,0.41,1.92,0.24
			c0.111,0.991-1.689,0.069-1.68,0.96c-0.559,1.682-0.082,4.398-0.24,6.479c-0.736-0.304-1.263-0.817-1.92-1.2
			c-1.224-0.376-2.11-1.089-3.6-1.199c0,0.24,0,0.479,0,0.72c0.755,2.444,3.077,3.322,4.8,4.8c0.08,0,0.16,0,0.239,0
			c-0.032,16.66-0.588,34.646,0.721,51.12c0.753,8.128,1.196,16.563,1.439,25.2c0.039,1.804,1.003,3.953,0.24,5.76
			c-3.146,0.374-7.371-0.331-9.36,1.2c0.139,0.422-0.29,1.41,0.24,1.44c1.188,0.572,3.01,0.511,4.8,0.479
			c1.523,0.157,3.677-0.316,4.801,0.24c0.425,5.286-0.885,11.193,0,15.36c0.454,0.426,1.585,0.175,2.399,0.239
			c2.075-3.686,0.863-10.657,1.2-16.08c1.57-0.35,3.643-0.197,4.32-1.439c-0.033-1.289,0.287-1.853,1.92-1.92
			c2.237-0.563,4.354-1.246,6.24-2.16c1.183-0.337,2.367-0.673,3.359-1.2c1.719-0.361,3.286-0.874,4.561-1.68
			c2.045-0.676,3.835-1.605,6-2.16c2.141-0.579,4.458-0.981,6.479-1.68c3.267-0.633,7.534-0.633,10.8,0
			c0.653,0.547,1.669,0.731,2.641,0.96c0.934,0.186,1.965,0.274,2.399,0.96c1.112,0.088,2.23,0.17,3.12,0.479c0,0.08,0,0.16,0,0.24
			C405.313,706.769,405.471,707.972,406.4,708.401z M272.24,575.921c0.807,0.953,1.297,2.223,1.44,3.84
			c0.328,3.048-1.072,4.368-2.4,5.761c0.205,0.765-0.209,0.91-0.48,1.199c0,0.08,0,0.16,0,0.24c-1.335,0.855-3.25,0.269-5.04,0.24
			c-2.618-0.582-4.077-2.323-5.04-4.561c-0.362-1.41-0.408-3.698,0.24-4.8c0.658-1.022,1.355-2.005,2.16-2.88
			c1.466-1.094,3.21-1.91,5.76-1.92c0,0.08,0,0.16,0,0.24C270.581,573.58,271.208,574.953,272.24,575.921z M404.241,663.041
			c-0.281,0.039-0.203,0.438-0.24,0.72c-0.08,0-0.16,0-0.24,0c-0.238,1.122-0.722,1.998-0.96,3.12c0.457,1.505-1.655,1.694-1.2,2.4
			c0.077,2.243,1.008,3.632,0.72,6.24c0.29,0.978,0.034,2.13,0.24,2.64c0.976,4.553-1.5,7.138-1.439,10.8
			c0.128,0.929-0.429,1.172-0.24,2.16c-0.465,0.896-0.152,2.568-0.48,3.6c0.289,0.752,0.479,1.602,0.721,2.4
			c0.996,1.403,1.727,3.073,2.399,4.8c0,0.08,0,0.16,0,0.24c-0.856,0.369-2.371-0.663-3.6-0.72c-1.343-0.658-3.069-0.932-4.8-1.2
			c-0.641,0-1.28,0-1.92,0c-0.65-0.398-2.951-0.398-3.601,0c-3.229-0.109-5.455,0.784-8.16,1.2c-2.07,0.489-4.269,0.852-5.76,1.92
			c-1.835,0.645-3.434,1.526-5.52,1.92c-1.543,0.458-2.957,1.043-4.32,1.68c-1.466,0.454-2.64,1.2-4.32,1.44
			c-1.989,0.57-4,1.119-5.76,1.92c-1.142,0.138-1.839,0.722-3.12,0.72c-1.23-9.089-1.25-19.391-1.92-29.04
			c-1.385-15.319-1.62-31.64-0.72-47.76c2.394,1.206,3.848,3.353,5.279,5.52c0.723,0.958,1.3,2.062,2.16,2.88
			c0.225,0.896,1.14,1.102,1.2,2.16c0.477,0.725,0.603,1.798,1.44,2.16c0.573,0.946,1.313,1.727,1.68,2.88
			c0.908,1.173,1.611,2.549,2.64,3.601c0.259,0.621,0.868,0.892,0.96,1.68c0.642,0.799,0.985,1.895,1.92,2.4
			c1.502,1.777,3.089,3.471,5.04,4.8c0,0.08,0,0.16,0,0.24c1.254,0.666,2.354,1.486,3.841,1.92c1.25,0.189,1.739,1.14,3.119,1.2
			c0,0.079,0,0.159,0,0.239c4.482,2.55,11.848,2.091,16.561,0c1.336-0.483,2.144-2.176,1.2-3.6c-0.545-1.514-3.321-0.533-4.08,0.479
			c-3.473,0.415-7.452,1.309-10.32-0.239c-1.098-0.742-2.62-1.061-3.84-1.681c-1.521-0.479-2.896-1.104-4.08-1.92
			c-0.901-0.778-1.7-1.659-2.64-2.399c-0.972-0.55-1.654-1.387-2.16-2.4c-0.855-1.145-1.556-2.444-2.641-3.36
			c-0.073-0.565-0.833-0.446-0.72-1.199c-0.703-0.978-1.261-2.1-2.16-2.881c-0.296-0.504-0.664-0.936-0.96-1.439
			c-0.835-1.085-1.403-2.437-2.399-3.36c-0.485-0.954-1.301-1.579-1.681-2.64c-0.592-1.008-1.25-1.95-2.16-2.641
			c-0.227-0.732-1.167-0.752-1.199-1.68c-0.7-0.9-1.5-1.7-2.4-2.399c-0.315-0.085-0.377-0.423-0.72-0.48
			c-0.704-0.976-1.777-1.583-2.88-2.16c-0.514-2.846,0.237-6.957-0.721-9.36c1.221,0.301,2.258,0.783,3.841,0.721
			c0.815,0.311,2.685,0.825,3.6,0.24c0.193-0.127,0.39-0.251,0.72-0.24c0.08,0,0.16,0,0.24,0c0.889-1.031,1.548-2.292,2.16-3.601
			c0.124-1.235,0.794-1.925,0.96-3.12c1.204-1.988,1.268-5.119,2.393-7.186c0.021,0.012,0.043,0.023,0.064,0.035
			c1.091,1.033,2.465,1.783,3.783,2.591c0.754,0.206,1.013,0.907,1.92,0.96c0,0.08,0,0.16,0,0.24c1.299,0.86,2.771,1.549,4.319,2.16
			c1.062,0.218,1.574,0.986,2.641,1.199c0,0.08,0,0.16,0,0.24c1.152,0.688,2.352,1.328,3.6,1.92
			c1.363,0.396,2.371,1.149,3.601,1.681c0,0.079,0,0.16,0,0.239c1.07,0.45,1.915,1.125,3.119,1.44c1.44,0.48,2.521,1.319,3.841,1.92
			c0,0.08,0,0.16,0,0.24c0.911,0.368,1.593,0.967,2.64,1.2c1.315,0.524,2.471,1.209,3.6,1.92c0.176,0.224,0.437,0.363,0.721,0.479
			c0.715,0.646,1.79,0.93,2.64,1.44c1.438,0.481,2.57,1.27,3.84,1.92c0.502,0.458,1.146,0.773,1.92,0.96
			c0.179,1.938-0.465,3.055-0.479,4.8c-0.559,2.481-0.081,5.999-0.24,8.88c0.487,1.594,0.133,4.027,0.24,6c-0.48,0-0.96,0-1.44,0
			c-1.587-0.013-2.437-0.764-3.6-1.2c0-0.079,0-0.159,0-0.239c-1.598-0.322-2.607-1.233-4.08-1.681
			c-1.31-0.37-2.389-0.972-3.601-1.439c-0.798-0.643-2.021-0.858-2.88-1.44c-1.63-0.449-2.689-1.471-4.319-1.92
			c-1.311-0.77-2.555-1.605-4.08-2.16c-1.731-0.668-3.512-1.288-5.04-2.159c-0.558-0.724-1.685-0.876-2.641-1.2
			c-1.411-0.269-2.311-1.05-3.6-1.44c-1.37-0.869-3.14-1.341-4.8-1.92c-2.214-0.586-4.059-1.542-6.96-1.439c0-0.08,0-0.16,0-0.24
			c-0.32,0-0.641,0-0.96,0c-0.174,1.054,0.516,1.244,1.199,1.439c1.191,0.81,2.283,1.718,3.841,2.16c0,0.08,0,0.16,0,0.24
			c1.701,0.618,3.225,1.415,5.04,1.92c0.998,0.361,1.881,0.839,2.88,1.2c0.979,0.78,2.415,1.104,3.6,1.68
			c1.697,0.463,3.054,1.267,4.561,1.92c1.313,0.768,2.507,1.652,4.08,2.16c1.046,0.233,1.728,0.832,2.64,1.2
			c1.202,0.877,2.422,1.737,4.08,2.16c1.59,0.569,3.053,1.267,4.56,1.92c0,0.08,0,0.16,0,0.24c0.899,0.461,1.823,0.896,2.88,1.199
			c1.303,0.298,2.196,1.004,3.36,1.44c0.622,0.497,1.423,0.817,2.4,0.96c0.239,0,0.479,0,0.72,0c0.584,0.646,1.916,0.01,2.64,0
			C406.051,657.119,405.343,660.23,404.241,663.041z M525.441,551.921c-0.45,0.671-0.455,1.785-0.96,2.4
			c0.067,0.388-0.228,0.413-0.24,0.72c-0.712,0.408-0.682,1.559-1.2,2.16c-0.189,2.05-1.226,3.254-1.2,5.52
			c-0.581,8.834,0.214,19.983,1.44,28.561c-0.05,0.751,0.162,1.762-0.24,2.16c0.196,0.999-0.326,2.003,0,2.399
			c-0.233,0.007-0.187,0.294-0.24,0.48c-1.528,1.512-0.743,5.337-1.2,7.92c-0.536,0.104-0.097,1.183-0.239,1.68
			c-0.428,1.173-0.587,2.613-0.721,4.08c-0.08,0-0.159,0-0.239,0c0.069,1.271-0.417,1.983-0.48,3.12
			c-0.461,0.659-0.564,1.676-0.72,2.64c-0.686,0.835-1.003,2.038-1.44,3.12c-0.817,1.183-1.522,2.478-1.92,4.08
			c-0.567,0.713-1.02,1.54-1.439,2.4c-1.527,1.753-2.476,4.085-3.841,6c0.08,0,0.16,0,0.24,0c-1.899,3.22-3.923,6.317-6.24,9.12
			c-1.246,0.993-2.016,2.463-3.119,3.6c0,0.08,0,0.16,0,0.24c-1.512,2.314-4.175,3.11-8.16,2.399
			c-3.018-0.022-5.287-0.793-7.681-1.439c-1.897-0.902-3.874-1.727-6.239-2.16c-2.325-0.395-4.342-1.099-6.961-1.2
			c-2.422-0.938-5.87-0.849-7.92-2.16c-1.355-1.284-4.254-1.025-6-1.92c-1.169-0.831-2.938-1.061-4.319-1.68
			c-0.891-0.149-1.405-0.675-2.16-0.96c-1.23-1.01-2.694-1.786-4.08-2.64c-0.788-0.093-1.059-0.702-1.68-0.96
			c-0.788-0.813-1.902-1.299-2.881-1.921c-0.982-0.376-1.805-0.914-2.64-1.439c-1.229-0.852-2.539-1.621-3.84-2.4
			c-0.713-0.487-1.657-0.742-2.16-1.439c-0.705-0.654-1.614-1.106-2.4-1.681c-0.876-0.163-1.17-0.909-1.92-1.199
			c-1.042-1.038-2.324-1.836-3.6-2.641c-0.898-0.222-1.261-0.979-2.16-1.2c-0.914-0.925-2.286-1.394-3.6-1.92
			c-1.248-0.513-2.255-1.265-3.601-1.68c-0.992-1.008-2.609-1.391-4.08-1.92c-0.895-0.145-1.177-0.903-2.159-0.96
			c-1.419-1.062-3.216-1.745-4.801-2.641c-0.915-0.204-1.526-0.713-2.399-0.96c-1.396-1.085-3.452-1.508-5.28-2.159
			c-1.765-0.556-3.553-1.088-5.28-1.681c-1.081-0.759-2.434-1.245-3.84-1.68c-1.194-0.405-2.13-1.07-3.36-1.44
			c-1.206-0.873-2.874-1.285-4.319-1.92c-1.949-0.531-3.605-1.354-5.521-1.92c-0.899-0.86-2.397-1.122-3.6-1.68
			c-2.138-1.063-4.183-2.218-5.76-3.84c-0.19-1.471,0.835-1.725,1.68-2.16c0.638-1.282,2.986-0.854,4.08-1.68
			c1.188-0.252,1.784-1.096,2.88-1.44c1.14-0.7,2.27-1.411,3.12-2.4c0.65-0.149,0.598-1.002,1.439-0.96
			c4.775-4.185,9.23-8.689,13.44-13.439c-0.113-0.754,0.646-0.635,0.72-1.2c1.211-0.95,1.995-2.325,2.88-3.6
			c0.182-1.18,1.318-1.402,1.44-2.641c0.905-1.015,1.545-2.295,1.92-3.84c0.315-1.205,0.99-2.05,1.44-3.12
			c0.663-1.736,1.506-3.294,1.92-5.28c0.432-2.628,1.907-4.309,1.439-7.199c-0.267-0.917-2.09-0.896-2.64-0.24
			c-0.389,0.812-0.683,1.717-0.96,2.64c-0.397,2.245-1.763,4.795-1.92,6.48c-0.348,1.572-1.258,2.582-1.681,4.08
			c-0.782,1.457-1.52,2.959-2.159,4.56c-0.316,0.645-0.645,1.275-0.96,1.92c-1.093,1.067-1.853,2.468-2.881,3.601
			c0,0.08,0,0.159,0,0.239c-1.532,1.268-2.688,2.912-4.079,4.32c-0.363,0.997-1.204,1.517-1.921,2.16
			c-1.627,1.332-2.944,2.975-4.56,4.32c0.044,0.204-0.094,0.226-0.24,0.239c-1.444,1.355-2.955,2.646-4.319,4.08
			c-0.566,0.074-0.447,0.834-1.2,0.721c0.21-1.15,1.32-1.399,1.68-2.4c1.043-0.798,1.616-2.063,2.4-3.12
			c0.241-0.959,1.022-1.378,1.439-2.16c0.795-0.726,1.393-1.647,1.92-2.64c0.335-1.105,1.162-1.719,1.681-2.641
			c0.781-1.138,1.503-2.336,1.92-3.84c0.315-1.204,0.99-2.05,1.439-3.12c0.639-0.801,1.421-1.459,1.92-2.399
			c0.132-0.989,0.836-1.404,1.2-2.16c1.007-1.073,1.646-2.515,2.4-3.84c0.444-0.836,0.939-1.621,1.439-2.4
			c0.778-0.981,1.438-2.082,1.92-3.359c0.243-1.197,0.988-1.893,1.44-2.881c0.706-0.977,1.673-2.962,1.68-3.84
			c0.432-1.488,1.212-2.629,1.681-4.08c0.353-0.854,0.432-3.239,0-4.08c-0.144-1.481-1.163-3.664-1.44-5.76
			c-0.401-1.839-1.002-3.478-2.16-4.56c-0.672-0.276-1.247-0.276-1.92,0c-0.819,0.726-0.397,2.29,0,3.12
			c0.693,1.706,1.145,3.655,1.68,5.52c1.172,1.849,1.133,4.878,0,6.72c-0.772,1.387-1.309,3.011-1.92,4.561
			c-0.479,0.961-0.961,1.919-1.439,2.88c-0.711,1.209-1.379,2.461-1.92,3.84c-0.004,0.717-0.624,0.816-0.721,1.44
			c-0.863,0.678-0.213-0.974-0.239-0.721c0.571-5.54,2.432-10.957,1.92-17.279c-0.148-4.492,0.304-9.584-0.24-13.681
			c0.326-1.769-0.718-4.239-0.96-6.239c0.661,0.458,1.175,1.064,1.92,1.439c0.788,0.093,1.059,0.702,1.68,0.96
			c0.726,0.715,1.145,1.736,1.92,2.4c0.553,1.527,1.315,2.845,1.681,4.56c0.651,1.748,0.721,4.079,1.68,5.521
			c0.266,1.334,1.147,2.021,1.68,3.6c1.362,1.118,2.504,4.471,5.04,2.88c0.688-1.19-0.253-1.908-0.479-2.88
			c-0.806-1.034-1.674-2.007-2.4-3.12c-0.798-1.521-1.112-3.527-1.439-5.52c-0.316-2.084-1.042-3.759-1.681-5.521
			c-0.062-0.594-1.11-1.427-0.72-1.68c0.891,0.229,1.785,0.455,2.4,0.96c0.753,0.606,1.728,0.992,2.88,1.2
			c1.938,0.302,3.338,1.143,5.28,1.439c1.869,0.771,4.069,1.211,6.239,1.681c2.629,0.171,4.432,1.169,6.721,1.68
			c1.774,0.785,3.568,1.551,5.52,2.16c1.434,0.406,2.607,1.072,3.84,1.68c0.758,0.843,1.964,1.236,3.12,1.68
			c1.53,0.391,2.452,1.388,3.84,1.921c0.602,0.759,1.641,1.079,2.641,1.439c0.612,0.028,1.234,0.045,1.2,0.72
			c0.159,0,0.319,0,0.479,0c0.996,0.604,1.989,1.211,3.36,1.44c0.038,0.281,0.438,0.202,0.72,0.24
			c1.118,0.722,2.615,1.064,4.08,1.439c0.954-0.074,1.205,0.555,2.16,0.48c1.088,0.512,2.284,0.916,3.6,1.2
			c0.296,0.344,0.825,0.454,1.44,0.479c1.351,0.81,2.654,1.666,4.08,2.4c1.016,0.904,2.003,1.837,3.359,2.399
			c0.568,0.952,1.615,1.426,2.4,2.16c0.601,0.68,1.44,1.12,1.92,1.92c1.059,1.821,2.727,3.033,3.36,5.28
			c0.673,0.847,1.146,1.894,1.92,2.64c-0.039,0.279,0.053,0.428,0.239,0.48c0.332,1.429,1.778,1.742,3.601,1.68
			c0-0.08,0-0.16,0-0.24c0.873-0.528,1.115-1.708,0.72-2.88c-0.417-1.503-1.138-2.701-1.92-3.84
			c-0.677-1.163-1.303-2.377-2.16-3.359c2.221-0.58,4.844-0.757,7.44-0.961c5.442-0.622,11.576-0.58,17.04,0
			c2.3,0.531,5.696,0.37,4.56-2.399c-0.505-0.935-1.602-1.278-2.399-1.92c-0.657-0.224-1.023-0.736-1.681-0.96
			c-1.293-1.107-3.093-1.707-5.04-2.16c-1.83-0.57-3.469-1.331-5.52-1.68c-2.376-0.904-5.396-1.164-8.16-1.681
			c-4.119-0.66-9.917-1.763-13.68,0c0.053,0.773-0.425,1.016-0.24,1.92c0,0.08,0,0.16,0,0.24c0.99,0.926,2.646,0.241,3.84,0
			c0.08,0,0.16,0,0.24,0c3.669,0.73,7.619,1.181,11.28,1.92c2.391,0.249,4.589,0.69,6.72,1.2c1.132,0.548,2.565,0.794,3.6,1.439
			c-7.601-0.24-14.092,0.628-20.399,1.681c-0.459-0.661-1.02-1.222-1.681-1.681c-1.885-1.555-3.775-3.104-5.76-4.56
			c-0.993-1.007-2.192-1.808-3.6-2.4c-1.542-0.858-3.658-1.142-5.28-1.92c0.019-0.781,1.088-0.512,1.68-0.72
			c6.25-1.836,15.813-2.532,21.841,0c1.985,0.654,4.109,1.17,6,1.92c1.215,0.785,2.857,1.143,4.319,1.68
			c1.546,0.135,2.431,0.93,3.84,1.2c1.391,0.851,3.081,1.4,4.801,1.92c0.38,0.021,0.915-0.115,0.96,0.24c0,0.08,0,0.16,0,0.24
			c0.771,0.269,1.423,0.657,2.399,0.72c1.166,0.674,2.577,1.104,4.08,1.44c0.536,0.584,1.567,0.673,2.4,0.96
			c0.744-0.024,1.146,0.293,1.68,0.479c1.007,0.354,1.829,0.892,2.88,1.2c1.097,0.903,2.509,1.492,3.601,2.4
			c0.25,0.694,2.115,1.563,2.64,0.479c0.16,0,0.32,0,0.48,0c0.244,0.315,0.234,0.885,0.479,1.2c0.003,0.477,0.655,0.306,0.48,0.96
			c0.459,0.741,0.871,1.528,1.199,2.4C525.604,550.805,525.302,551.142,525.441,551.921z"/>
			</g>
		</g>
		<g id="mouth_1_1_">
			<path fillRule="evenodd" clipRule="evenodd" d="M282.8,298c-0.32,0-0.64,0-0.96,0c-0.727,0.073-0.857,0.743-1.92,0.48
		c-1.17,0.51-3.16,0.2-4.32,0.72c-4.541,0.339-8.112,1.648-12.24,2.4c-1.364-0.085-2.06,0.5-3.36,0.48
		c-2.803,0.717-6.019,1.021-8.64,1.92c0.041,0.521-0.851,0.109-1.2,0.24c-0.898-0.018-1.659,0.101-2.16,0.48
		c-3.04,0.4-5.196,1.684-8.4,1.92c-0.96,0-1.92,0-2.88,0c0.074-0.554-0.693-0.267-0.72-0.72c-2.081,0.959-3.679,2.401-5.04,4.08
		c0.318,0.642,0.515,1.405,1.68,1.2c1.287-0.553,2.018-1.662,3.12-2.4c0.929-0.671,3.403,0.203,4.32-0.48
		c3.597-0.802,7.236-1.563,10.56-2.64c2.251-0.629,4.681-1.079,7.44-1.2c2.245,0.005,4.153-0.327,5.76-0.96
		c0.359-0.041,0.886,0.085,0.96-0.24c1.115-0.084,2.092-0.308,2.88-0.72c2.709-0.331,5-1.08,7.92-1.2
		c0.887,0.399,3.433,0.399,4.32,0c1.456-0.144,3.587,0.387,4.32-0.48c0-0.48,0-0.96,0-1.44
		C284.253,298.616,283.227,297.59,282.8,298z"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M266,309.041c-0.08,0-0.16,0-0.24,0c-0.175-0.545-0.722-0.718-1.2-0.96
		c-0.259-0.397-2.141-0.397-2.4,0c-0.492,0.388-1.573,0.187-1.92,0.72c-0.832-0.192-0.922,0.357-1.68,0.24
		c-1.137,0.383-2.5,0.54-3.12,1.44c-0.971,0.629-2.648,0.552-3.36,1.44c0.042,1.159,0.107,2.292,0.96,2.64
		c0.939,0.581,2.559,0.481,4.08,0.48c1.303-0.183,1.944,0.296,3.12,0.24c1.337,0.137,2.169-0.231,2.88-0.72
		c0.879-0.561,1.778-1.102,2.16-2.16c-0.067-0.547,0.058-0.902,0.48-0.96C266.308,311.107,265.85,309.77,266,309.041z"/>
		</g>
		<g id="eyebrow_1_1_">
			<path fillRule="evenodd" clipRule="evenodd" d="M290.721,217.36c-1.752,0.008-2.724,0.796-3.84,1.44
		c-1.156,0.444-2.363,0.837-3.12,1.68c-0.279-0.039-0.427,0.053-0.48,0.24c-0.845,0.274-1.712,0.527-2.16,1.2
		c-4.985,3.095-12.01,4.15-16.56,7.68c-1.594,0.727-2.792,1.849-3.84,3.12c-0.938,0.342-1.578,0.982-1.92,1.92
		c-0.545,0.175-0.718,0.722-0.96,1.2c-0.315,0.745,0.065,0.685,0,1.68c0.475,0.615,2.282,1.17,2.88,0.24
		c1.103-0.737,1.777-1.903,2.88-2.64c0.045-0.194,0.241-0.239,0.24-0.48c1.146-1.333,2.672-2.288,4.32-3.12
		c0.696-0.184,1.38-0.379,1.68-0.96c3.739-2.181,9.064-2.776,12.48-5.28c0.846-0.274,1.713-0.527,2.16-1.2
		c0.279,0.039,0.427-0.053,0.48-0.24c0.846-0.274,1.712-0.528,2.16-1.2c0.774-0.186,1.418-0.501,1.92-0.96
		c0.773-0.186,1.418-0.501,1.92-0.96c0.835-1.004,3.262-0.418,4.56-0.96c0-0.4,0-0.8,0-1.2
		C295.252,216.986,292.272,216.853,290.721,217.36z"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M221.121,238c-2.125,0.124-3.863-0.137-5.76-0.24
		c0.041-0.521-0.851-0.109-1.2-0.24c-0.596-0.364-1.457-0.463-2.4-0.48c-0.596,0.036-0.996-0.124-1.2-0.48
		c-0.801-0.479-2.05-0.51-3.36-0.48c-0.887-0.399-3.433-0.399-4.32,0c-1.463-0.023-2.809,0.071-3.84,0.48
		c-1.303,0.381-3.545,1.09-4.08,1.44l-0.519,3.75c0.996-0.193,1.591-0.875,3.638-0.87c0.557,0.077,0.922-0.039,1.2-0.24
		c1.61-0.31,3.528-0.312,5.04-0.72c3.165,0.035,5.394,1.006,8.16,1.44c1.941,0.619,4.349,0.771,6.96,0.72l2.64,0.48
		c0.204,0.044,0.226-0.093,0.24-0.24c0.16,0,0.32,0,0.48,0c0.823-0.217,1.307-0.773,1.92-1.2c0.303-1.263-0.497-1.423-0.48-2.4
		C223.925,237.755,222.364,238.037,221.121,238z"/>
		</g>
		<g id="mouth_2" display="none">
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M263.81,268.285c-0.32,0-0.64,0-0.96,0
		c-0.727,0.073-1.323-0.232-2.385-0.495c-1.17,0.51-3.16,0.2-4.32,0.72c-4.541,0.339-11.656,0.338-15.784,1.091
		c-1.364-0.085-2.06,0.5-3.36,0.48c-2.803,0.717-6.019,1.021-8.64,1.92c0.041,0.521-0.851,0.109-1.2,0.24
		c-0.898-0.018-1.659,0.101-2.16,0.48c-3.04,0.4-2.99,1.985-6.194,2.222c-0.96,0-2.687,2.136-3.646,2.136
		c0.074-0.554-0.693-0.267-0.72-0.72c-2.081,0.959-3.679,2.401-5.04,4.08c0.318,0.642,0.515,1.405,1.68,1.2
		c1.287-0.553,2.018-1.662,3.12-2.4c0.929-0.671,4.169-1.933,5.086-2.616c3.597-0.802,5.03-1.865,8.354-2.941
		c2.251-0.629,4.681-1.079,7.44-1.2c2.245,0.005,4.153-0.327,5.76-0.96c0.359-0.041,0.886,0.085,0.96-0.24
		c1.115-0.084,2.092-0.308,2.88-0.72c2.709-0.331,8.544,0.229,11.464,0.109c0.887,0.399,3.433,0.399,4.32,0
		c1.456-0.144,4.053,1.362,4.786,0.495c0-0.48,0-0.96,0-1.44C265.262,268.9,264.236,267.875,263.81,268.285z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M243,275.853c-0.08,0-0.16,0-0.24,0
		c-0.175-0.545-0.722-0.718-1.2-0.96c-0.259-0.397-2.141-0.397-2.4,0c-0.492,0.388-1.573,0.187-1.92,0.72
		c-0.832-0.192-0.922,0.357-1.68,0.24c-1.137,0.383-2.5,0.54-3.12,1.44c-0.971,0.629-2.648,0.552-3.36,1.44
		c0.042,1.159,0.107,2.292,0.96,2.64c0.939,0.581,2.559,0.481,4.08,0.48c1.303-0.183,1.944,0.296,3.12,0.24
		c1.337,0.137,2.169-0.231,2.88-0.72c0.879-0.561,1.778-1.102,2.16-2.16c-0.067-0.547,0.058-0.902,0.48-0.96
		C243.308,277.92,242.85,276.583,243,275.853z"/>
		</g>
		<g id="eyebrow_2" display="none">
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M265.528,186.586c-1.735,0.246-2.591,1.158-3.609,1.948
		c-1.085,0.597-2.227,1.15-2.863,2.088c-0.282,0-0.416,0.11-0.443,0.303c-0.8,0.387-1.625,0.755-1.977,1.482
		c-4.519,3.744-11.335,5.743-15.364,9.857c-1.48,0.936-2.515,2.21-3.381,3.613c-0.883,0.466-1.43,1.188-1.642,2.163
		c-0.516,0.247-0.613,0.813-0.788,1.319c-0.211,0.781,0.157,0.67,0.228,1.665c0.554,0.544,2.42,0.85,2.886-0.154
		c0.992-0.88,1.502-2.126,2.495-3.007c0.019-0.199,0.206-0.27,0.172-0.508c0.955-1.477,2.336-2.63,3.856-3.678
		c0.665-0.276,1.316-0.563,1.534-1.179c3.408-2.668,8.604-3.981,11.647-6.925c0.8-0.387,1.625-0.755,1.977-1.482
		c0.281,0.001,0.416-0.11,0.443-0.303c0.801-0.387,1.625-0.755,1.977-1.482c0.742-0.29,1.337-0.689,1.772-1.212
		c0.741-0.29,1.337-0.689,1.772-1.212c0.691-1.108,3.175-0.857,4.387-1.57c-0.054-0.396-0.108-0.792-0.163-1.189
		C269.966,185.6,266.996,185.872,265.528,186.586z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M198.17,209.663c-2.12-0.182-3.804-0.69-5.666-1.064
		c0.115-0.509-0.827-0.229-1.153-0.409c-0.538-0.446-1.375-0.667-2.306-0.82c-0.595-0.05-0.968-0.266-1.119-0.647
		c-0.724-0.589-1.956-0.799-3.257-0.957c-0.82-0.522-3.34-0.887-4.275-0.62c-1.445-0.232-2.791-0.332-3.869-0.076
		c-1.344,0.19-3.665,0.57-4.245,0.84l-1.051,3.637c1.013-0.049,1.7-0.637,3.725-0.339c0.54,0.156,0.918,0.094,1.222-0.066
		c1.638-0.076,3.536,0.197,5.091,0.011c3.127,0.488,5.193,1.77,7.869,2.595c1.833,0.891,4.193,1.387,6.785,1.71l2.544,0.854
		c0.195,0.073,0.237-0.06,0.271-0.203c0.159,0.023,0.316,0.046,0.475,0.069c0.845-0.097,1.404-0.578,2.072-0.913
		c0.481-1.207-0.288-1.479-0.131-2.444C200.981,209.822,199.395,209.877,198.17,209.663z"/>
		</g>
		<g id="mouth_3" display="none">
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M259.8,263c-0.32,0-0.64,1-0.96,1
		c-0.727,0.073-0.857,1.743-1.92,1.48c-1.17,0.51-3.177,1-4.337,1.52c-4.541,0.339-8.095,1.848-12.223,2.601
		c-1.364-0.085-2.06,0.5-3.36,0.48c-2.803,0.717-6.019,1.021-8.64,1.92c0.041,0.521-0.851,0.109-1.2,0.24
		c-0.898-0.018-1.659,0.101-2.16,0.48c-3.04,0.4-5.196-0.316-8.4-0.08c-0.96,0-4.226-1.729-5.186-1.729
		c0.074-0.554-0.693-0.267-0.72-0.72c-2.081,0.959-3.679,0.401-5.04,2.08c0.318,0.642,0.515,1.405,1.68,1.2
		c1.287-0.553,2.018,0.338,3.12-0.4c0.929-0.671,5.708,1.932,6.625,1.249c3.597-0.802,7.236,0.437,10.56-0.64
		c2.251-0.629,4.681-1.079,7.44-1.2c2.245,0.005,4.153-0.327,5.76-0.96c0.359-0.041,0.886,0.085,0.96-0.24
		c1.115-0.084,2.092-0.308,2.88-0.72c2.709-0.331,5-2.08,7.92-2.2c0.887,0.399,3.433-0.601,4.32-1
		c1.456-0.144,3.587-0.613,4.32-1.48c0-0.48,0-0.96,0-1.44C261.253,263.616,260.227,262.59,259.8,263z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M243,277.041c-0.08,0-0.16,0-0.24,0
		c-0.175-0.545-0.722-0.718-1.2-0.96c-0.259-0.397-2.141-0.397-2.4,0c-0.492,0.388-1.573,0.187-1.92,0.72
		c-0.832-0.192-0.922,0.357-1.68,0.24c-1.137,0.383-2.5,0.54-3.12,1.44c-0.971,0.629-2.648,0.552-3.36,1.44
		c0.042,1.159,0.107,2.292,0.96,2.64c0.939,0.581,2.559,0.481,4.08,0.48c1.303-0.183,1.944,0.296,3.12,0.24
		c1.337,0.137,2.169-0.231,2.88-0.72c0.879-0.561,1.778-1.102,2.16-2.16c-0.067-0.547,0.058-0.902,0.48-0.96
		C243.308,279.107,242.85,277.77,243,277.041z"/>
		</g>
		<g id="eyebrow_3" display="none">
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M265.528,184.586c-1.735,0.246-2.591,1.158-3.609,1.948
		c-1.085,0.597-2.227,1.15-2.863,2.088c-0.282,0-0.416,0.11-0.443,0.303c-0.8,0.387-1.625,0.755-1.977,1.482
		c-4.519,3.744-11.335,5.743-15.364,9.857c-1.48,0.936-2.515,2.21-3.381,3.613c-0.883,0.466-1.43,1.188-1.642,2.163
		c-0.516,0.247-0.613,0.813-0.788,1.319c-0.211,0.781,0.157,0.67,0.228,1.665c0.554,0.544,2.42,0.85,2.886-0.154
		c0.992-0.88,1.502-2.126,2.495-3.007c0.019-0.199,0.206-0.27,0.172-0.508c0.955-1.477,2.336-2.63,3.856-3.678
		c0.665-0.276,1.316-0.563,1.534-1.179c3.408-2.668,8.604-3.981,11.647-6.925c0.8-0.387,1.625-0.755,1.977-1.482
		c0.281,0.001,0.416-0.11,0.443-0.303c0.801-0.387,1.625-0.755,1.977-1.482c0.742-0.29,1.337-0.689,1.772-1.212
		c0.741-0.29,1.337-0.689,1.772-1.212c0.691-1.108,3.175-0.857,4.387-1.57c-0.054-0.396-0.108-0.792-0.163-1.189
		C269.966,183.6,266.996,183.872,265.528,184.586z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M198.17,207.663c-2.12-0.182-3.804-0.69-5.666-1.064
		c0.115-0.509-0.827-0.229-1.153-0.409c-0.538-0.446-1.375-0.667-2.306-0.82c-0.595-0.05-0.968-0.266-1.119-0.647
		c-0.724-0.589-1.956-0.799-3.257-0.957c-0.82-0.522-3.34-0.887-4.275-0.62c-1.445-0.232-2.791-0.332-3.869-0.076
		c-1.344,0.19-3.665,0.57-4.245,0.84l-1.051,3.637c1.013-0.049,1.7-0.637,3.725-0.339c0.54,0.156,0.918,0.094,1.222-0.066
		c1.638-0.076,3.536,0.197,5.091,0.011c3.127,0.488,5.193,1.77,7.869,2.595c1.833,0.891,4.193,1.387,6.785,1.71l2.544,0.854
		c0.195,0.073,0.237-0.06,0.271-0.203c0.159,0.023,0.316,0.046,0.475,0.069c0.845-0.097,1.404-0.578,2.072-0.913
		c0.481-1.207-0.288-1.479-0.131-2.444C200.981,207.822,199.395,207.877,198.17,207.663z"/>
		</g>
		<g id="lighting_1_">
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M325.521,195.52c0.316,0.744-0.711,1.332,0,1.68
		c0.07-0.489,0.649-0.471,0.72-0.96C325.688,196.313,325.974,195.547,325.521,195.52z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M296.24,243.28c0,0.08,0,0.16,0,0.24
		c-0.648,4.71-2.346,8.373-3.84,12.24c4.74,0.108,12.043-13.454,4.08-13.44C296.461,242.7,296.596,243.236,296.24,243.28z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M271.76,305.92c-2.578,0.382-3.171,2.749-5.76,3.12
		c-0.15,0.729,0.307,2.067-0.24,2.4c-0.026,0.597-0.45,0.743,0,1.2C268.463,311.308,273.554,310.194,271.76,305.92z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M380.721,394.721c-0.096-0.785-0.208-1.552-0.96-1.68
		c-0.347,0.373-0.505,0.935-0.72,1.44c0.192,0.127,0.39,0.25,0.72,0.24C380.081,394.721,380.4,394.721,380.721,394.721z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M382.4,424.721c0.24,0,0.48,0,0.721,0c0-1.6,0-3.2,0-4.801
		c-0.739-3.829-0.023-10.26-0.721-14.639c-0.105-0.667,0.147-2.521-0.96-1.44c0.233,0.006,0.187,0.293,0.24,0.48
		c0.399,1.364,0.399,4.396,0,5.76c0.104,3.259-0.136,7.986,0,11.76C381.711,422.686,381.079,424.52,382.4,424.721z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M358.4,428.08c-0.373-1.146-0.381-2.658-0.479-4.08
		c0-0.479,0-0.959,0-1.439c-0.55-0.41-0.088-1.832-0.24-2.641c-0.545-0.254-0.092-1.508-0.24-2.159
		c-0.419-2.381-0.625-4.975-0.96-7.44c-0.822-0.698,0.274-3.315-1.68-2.88c-0.479,2.641,0.59,5.809,0.479,8.88
		c0.557,1.045,0.083,3.117,0.24,4.561c0.648,3.111,0.447,7.072,0.96,10.32c0.554,0.726,0.085,2.475,0.24,3.6
		c-0.241,0.801,0.333,0.787,0.96,0.72c0.846-0.194,1.158-0.921,0.96-2.159C358.084,432.078,358.559,429.763,358.4,428.08z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M381.681,430.48c0.734-0.174,0.706,0.414,1.44,0.24
		c0-1.12,0-2.24,0-3.359c-0.32,0-0.641,0-0.96,0C381.125,427.525,381.939,429.54,381.681,430.48z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M382.161,436.721c0.846-0.354,1.141-1.26,0.96-2.641
		c0-0.08,0-0.159,0-0.239c-0.449-0.192-0.879-0.401-1.44-0.479c0,0.879,0,1.76,0,2.639c-0.067,0.389,0.227,0.414,0.24,0.721
		C382.001,436.721,382.081,436.721,382.161,436.721z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M330.561,495.281c-0.558-0.163-0.643-0.798-1.2-0.961
		c-1.597-1.282-3.263-2.497-4.8-3.84c-1.97-0.771-2.459-4.234-4.8-3.84c-0.396,1.045-1.31,1.571-1.681,2.641
		c-0.253,3.077,2.122,7.548,4.08,12.239c2.437,5.837,4.396,12.231,12.721,10.561c0.351-6.271-0.219-11.621-2.16-15.601
		C331.731,496.35,331.316,495.646,330.561,495.281z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M268.88,688.961c-1.009,1.674,0.661,3.715,2.4,2.641
		c1.076-2.123,0.061-4.791-0.72-6.48c-0.51-0.616-1.07-0.423-1.68,0c-0.067,0.388,0.227,0.413,0.24,0.72
		c0.084,0.315,0.422,0.378,0.48,0.721C270.021,687.442,269.689,688.626,268.88,688.961z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M403.521,746.321c0-0.08,0-0.16,0-0.24c0-0.96,0-1.92,0-2.88
		c0-0.08,0-0.16,0-0.24c-0.379-2.42-0.365-5.234-0.479-7.92c-0.27-3.757-0.591-8.187-0.96-12.479
		c-0.537-0.104-0.098-1.183-0.24-1.681c-0.376-0.984-0.364-2.355-0.48-3.6c-2.697,0.822-5.882,1.158-8.159,2.4
		c-0.345,4.213,2.863,6.875,3.84,10.56c0.11,0.862-0.941,1.343-0.721,2.64c0.134,0.782,1.321,1.46,1.681,2.16
		c1.227,2.393,1.952,6.419,3.12,10.32c1.446,4.837,2.682,8.514,2.64,14.16c0.805-2.266,0.881-7.906-0.24-9.84
		C403.122,749.11,403.122,746.892,403.521,746.321z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M279.681,166c1.28-0.72,2.654-1.345,4.08-1.92
		c0.104-0.295,0.307-0.493,0.72-0.48c0.947-0.653,1.769-1.431,2.64-2.16c0.08,0,0.16,0,0.24,0c0.163-0.557,0.798-0.642,0.96-1.2
		c-0.679,0.361-1.022,1.058-1.92,1.2c-2.284,1.477-4.739,2.781-6.96,4.32C279.454,165.907,279.477,166.044,279.681,166z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M244.88,195.76c0.214-0.826,0.584-1.496,0.96-2.16
		c0.764-1.476,1.411-3.069,2.16-4.56c0.347-0.373,0.32-1.12,0.72-1.44c0.217-1.224,0.695-2.185,1.44-2.88
		c0.952-1.448,1.249-3.551,2.88-4.32c0.583-0.568,2.276-0.208,2.4,0.48c0.027,0.292,0.072,0.568,0.48,0.48
		c0.319,0.587,0.893-0.264,0.96,0.24c-0.241,0.319-0.227,0.893-0.72,0.96c-0.456,0.984-1.045,1.834-1.68,2.64
		c-0.409,0.791-0.942,1.458-1.2,2.4c1.791-0.93,2.721-2.719,4.32-3.84c1.207-1.433,2.366-2.914,3.84-4.08
		c0.591-0.413-0.64-1.917,0.48-2.16c0.263-0.857,0.823-1.417,1.68-1.68c0.31-1.05,1.211-1.509,1.92-2.16
		c0.027-0.293,0.072-0.568,0.48-0.48c1.34-1.54,2.78-2.98,4.32-4.32c0.08,0,0.16,0,0.24,0c1.855-1.905,4.067-3.452,6.24-5.04
		c-4.558-0.499-8.538,3.472-12,6.48c-2.737,2.378-5.492,4.604-7.92,6.72c-0.757-0.117-0.848,0.433-1.68,0.24
		c-0.959,0.561-1.395,1.645-1.92,2.64c-0.173-2.175,1.268-4.811,1.44-7.44c-0.283-1.403,0.601-1.639,0.48-2.88
		c0.408-1.033,0.76-2.121,1.2-3.12c0.263-2.137,1.481-3.318,2.16-5.04c-1.235-0.795-2.24,0.178-3.12,0.96
		c-4.742,4.215-5.651,16.798-8.4,24.24c-1.331,3.603-3.826,6.424-2.64,9.6C244.661,196.181,244.563,195.763,244.88,195.76z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M287.601,229.84c-0.241-0.001-0.286,0.194-0.48,0.24
		c-0.586,0.854-1.584,1.296-2.4,1.92c-0.644,0.282-1.409,1.296-0.72,2.16c0.449-0.191,0.879-0.401,1.44-0.48
		c0.366-0.754,1.841-0.399,2.88-0.48c0.561,0,1.12,0,1.68,0c0.729-0.473,2.017-0.384,2.881-0.72c0.818-0.061,1.152-0.607,2.16-0.48
		c0.242-0.398,1.02-0.261,1.439-0.48c1.125-1.377,5.42-1.383,4.08,1.44c-0.047,0.757-1.646,0.683-0.96,1.44
		c2.415-0.352,8.266,0.78,8.88-2.16c-0.473-0.407-0.644-1.116-0.72-1.92c-0.326-0.794-0.782-1.458-0.96-2.4
		c-0.536-1.704-2.288-3.36-2.16-4.32c-0.58-0.136-0.707-0.906-1.2-1.68c-0.655-1.345-1.394-2.606-2.159-3.84
		c-0.523-0.757-1.076-1.484-1.201-2.64c-0.356-0.204-0.347-0.773-0.479-1.2c-0.804-0.637-0.861-2.019-1.2-3.12
		c0.007-1.367-0.609-2.11-0.48-3.6c-0.401-0.398-0.19-1.409-0.239-2.16c-0.409,4.49-0.388,10.626,0,15.12
		C294.488,223.769,292.211,227.971,287.601,229.84z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M266.48,269.92c0.593-1.087,0.7-2.66,1.2-3.84
		c0-0.64,0-1.28,0-1.92c0.08-4.346-9.438-10.621-6-14.64c-0.773-1.261,0.316-2.718,0.48-3.84c1.063-2.377,1.368-5.512,2.16-8.16
		c-0.01-0.981,0.187-3.072-0.72-2.4c-1.103,0.737-1.777,1.903-2.88,2.64c-0.598,0.931-2.406,0.375-2.88-0.24
		c0.065-0.995-0.315-0.936,0-1.68c-0.982,0.858-2.076,1.604-2.88,2.64c-0.28,7.822-3.835,12.659-3.12,22.08
		c0.513,6.757,0.217,13.839,1.2,20.64c1.779-2.781,4.121-4.999,6.96-6.72c0.324-0.796,1.559-0.682,2.16-1.2
		c1.529-0.791,3.157-1.482,4.08-2.88C266.24,270.16,266.435,270.115,266.48,269.92z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M369.201,340.721c-1.365-0.956-2.94-1.7-4.08-2.88
		c-0.597-0.443-1.354-0.726-1.681-1.44c-1.562-0.679-2.559-1.921-3.6-3.12c-0.938-0.342-1.578-0.982-1.92-1.92
		c-0.146-0.014-0.284-0.036-0.24-0.24c-0.905-0.615-1.588-1.452-2.16-2.4c-1.371-1.429-2.656-2.944-3.6-4.8
		c-0.677-0.683-1.344-1.377-1.681-2.4c-0.95-1.05-1.753-2.247-2.159-3.84c-0.677-1.004-1.376-1.985-1.681-3.36
		c-0.53-0.83-1-1.72-1.2-2.88c-0.187-0.053-0.278-0.201-0.239-0.48c-0.825-1.735-1.743-3.377-2.4-5.28
		c-0.342-0.458-0.558-1.042-0.72-1.68c-0.881-1.679-1.854-3.267-2.641-5.04c-0.544-0.496-0.852-1.228-1.199-1.92
		c-0.626-0.895-1.191-1.849-1.92-2.64c-0.697-1.304-1.539-2.462-2.4-3.6c-0.5-0.46-1-0.92-1.2-1.68
		c-0.686-0.594-1.318-1.241-1.68-2.16c-0.146-0.014-0.284-0.036-0.24-0.24c-1.236-1.083-2.388-2.252-3.12-3.84
		c-1.106-1.133-2.28-2.199-3.12-3.6c-1.722-1.238-3.012-2.908-5.76-3.12c-0.214-0.186-0.542-0.258-0.96-0.24
		c0.329,1.609-0.735,1.825-0.48,3.36c-0.42,0.539-0.114,1.805-0.479,2.4c4.968,3.85,8.269,8.636,11.28,13.92
		c3.141,5.512,3.816,12.03,6.72,18c1.848,3.799,5.204,7.362,7.92,11.28c4.385,6.324,7.795,12.359,14.16,15.84
		c3.643,0.197,7.678,0.002,10.56,0.96c0.422,0.138,1.41-0.29,1.44,0.24c1.27-0.07,1.779,0.621,3.12,0.48
		c0.204,0.044,0.227-0.094,0.24-0.24c-1.056-0.145-1.532-0.868-2.4-1.2C369.439,340.961,369.395,340.766,369.201,340.721z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M455.121,370c0.059,1.541,0.111,3.089,0.479,4.32
		c0.663,7.577,1.441,15.039,2.641,22.08c-0.374,0.782-0.001,2.875,0.239,3.84c0,0.08,0,0.16,0,0.24c0,2.56,0,5.12,0,7.68
		c0,0.08,0,0.16,0,0.24c-0.358,0.69-0.817,2.532-0.239,3.36c0.396,1.363,1.317,2.202,2.399,2.88
		c0.486,11.206-2.215,19.225-7.2,24.96c3.28,0.513,5.978-1.362,8.641-2.88c1.013-0.577,2.361-2.059,3.359-2.16
		c1.36-0.139,4.558,1.791,6.24,2.641c3.142,1.586,4.884,3.315,6.24,6c-1.11,0.089-1.36,1.039-2.4,1.199
		c0.021,0.341,0.04,0.68-0.239,0.721c-0.396,0.804-1.327,1.072-2.4,1.199c-0.837,0.443-2.075,0.484-3.12,0.721
		c1.197,2.802,3.624,4.375,5.76,6.239c0.758,0.118,0.848-0.432,1.681-0.239c1.002-0.518,4.035-1.031,4.319,0.479
		c0.423,0.058,0.548,0.413,0.48,0.96c1.264-0.304,1.297,0.623,1.2,1.681c5.379,7.563,10.168,16.231,15.359,25.199
		c1.978,3.416,4.46,6.579,4.561,10.801c0.074,3.116-0.646,6.665-0.96,10.08c-0.312,3.39-0.48,6.852-0.48,10.319
		c0,6.546-0.093,13.765,2.16,19.44c0.452,1.14,0.9,1.926,1.92,2.64c0.534,0.187,1.302,0.139,1.44,0.721
		c1.819,0.74,4.141,0.979,5.76,1.92c0.867,0.093,1.602,0.318,2.16,0.72c0.56-0.205,1.034,0.839,1.2,0.24
		c-0.412-0.309-0.944-0.497-0.96-1.2c-0.594-0.287-0.938-0.822-1.2-1.44c-0.564-0.636-1.035-1.365-1.44-2.16
		c-0.545-1.455-1.664-2.336-1.92-4.079c-0.542-0.498-0.749-1.331-0.96-2.16c0-0.08,0-0.16,0-0.24
		c-0.627-0.573-0.811-1.589-0.96-2.64c-0.138-0.502-0.595-0.686-0.479-1.44c-0.463-0.4-0.308-2.213,0-2.64c0.08,0,0.159,0,0.239,0
		c0.596-0.95,1.069-0.819,0.721-2.4c-0.759-3.43-1.175-8.557-1.92-12.72c-0.027-1.733-0.146-3.375-0.48-4.801
		c0.024-1.863-0.418-3.262-0.72-4.8c-0.202-1.157-0.329-2.392-0.48-3.6c-0.407-0.633-0.71-1.369-0.72-2.4
		c-0.476-0.805-0.789-1.771-0.96-2.88c-0.72-0.88-0.873-2.327-1.44-3.359c0.29-1.01-0.388-1.053-0.479-1.681
		c-2.458-5.063-4.764-10.276-6.96-15.6c-0.69-1.07-1.364-2.156-1.92-3.36c-0.665-0.615-0.994-1.566-1.44-2.4
		c-0.332-0.867-1.056-1.344-1.2-2.399c-0.995-0.685-1.401-1.958-2.16-2.88c0-0.16,0-0.32,0-0.48c-0.798-0.642-1.071-1.809-1.68-2.64
		c0-0.08,0-0.16,0-0.24c-0.774-0.585-0.873-1.847-0.96-3.12c0.019-0.418-0.054-0.746-0.24-0.96c-0.982-1.337-2.032-2.607-3.12-3.84
		c-0.479-0.8-1.258-1.302-1.439-2.399c-1.071-1.09-1.456-2.865-1.92-4.561c-0.355-0.045-0.221-0.58-0.24-0.96
		c-0.48-0.866-0.786-2.963-0.48-3.84c-0.418-1.262-0.851-2.509-0.72-4.32c-0.581-2.3-0.45-5.31-0.96-7.681
		c-0.058-3.381-0.222-6.658-0.72-9.599c0.127-2.207-0.525-3.635-0.48-5.76c-0.365-0.835-0.433-1.966-0.479-3.12
		c0-0.48,0-0.96,0-1.44c-0.32-0.88-0.65-1.75-0.72-2.88c-0.454-0.986-0.707-2.174-0.96-3.36c-0.584-1.017-0.865-2.335-1.2-3.6
		c-0.772-1.228-0.8-3.201-1.44-4.56c-0.516-0.765-0.664-1.896-1.2-2.64c0-0.08,0-0.16,0-0.24c-0.401-0.559-0.627-1.293-0.72-2.16
		c-0.773-1.866-1.487-3.792-2.16-5.76c-0.357-0.763-0.888-1.353-0.96-2.4c-0.44-1.319-1.134-2.386-1.439-3.84
		c-0.653-0.707-0.854-1.867-1.44-2.64c0-0.08,0-0.16,0-0.24c-0.675-1.085-1.404-2.116-1.68-3.6c-0.188-0.053-0.279-0.201-0.24-0.48
		c-0.563-0.717-0.951-1.609-1.44-2.4c0.175-0.655-0.477-0.483-0.479-0.96c-0.444-0.676-1.013-1.228-1.2-2.16
		c-0.451-0.588-0.993-1.087-1.2-1.92c-0.801-0.799-1.442-1.758-1.92-2.88c-1.226-1.254-2.129-2.831-3.12-4.32
		c-0.399-0.481-0.87-0.889-0.96-1.68c-0.351-0.208-0.761-0.359-0.72-0.96c-0.827-0.933-1.845-1.675-2.4-2.88
		c-0.647-0.392-1.142-0.938-1.439-1.68c-0.422-0.138-1.41,0.291-1.44-0.24c-1.062-0.138-1.864-0.536-2.399-1.2
		c-1.063-0.778-2.592-1.088-3.36-2.16c-2.04-0.68-3.324-2.116-5.28-2.88c-0.407,0.472-0.736,1.024-1.439,1.2
		c-1.28,0.395-3.636-0.201-5.04-0.48c-0.32,0-0.641,0-0.96,0c0.574,1.339,2.06,0.94,3.12,1.44c4.549,2.143,9.772,6.708,13.92,9.6
		c0.754,0.766,1.251,1.789,2.16,2.4C449.822,353.699,454.838,359.483,455.121,370z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M396.801,508.721c0.527-0.313-0.284-1.297-0.24-1.92
		c0-0.08,0-0.16,0-0.24c-0.341-0.859-1.046-2.363-0.479-3.359c-1.583-8.167-1.743-19.956,0-28.08
		c-0.538-0.555,0.024-2.043,0.239-2.641c0.144-0.497-0.296-1.576,0.24-1.68c0-0.08,0-0.16,0-0.24c0.082-1.678,0.39-3.131,0.72-4.56
		c-0.133-1.857,0.891-3.69,0.24-5.28c0.045-1.466-0.651-3.266,0-4.56c1.002-0.519,0.815-2.225,1.2-3.36
		c-0.049-1.489,0.28-2.6,1.2-3.12c0.03-0.53,1.018-0.102,1.439-0.24c1.574-1.466,1.601-4.479,3.12-6
		c0.606-3.394,1.731-6.269,1.681-10.319c0.151-0.809-0.31-2.229,0.239-2.641c0-0.08,0-0.16,0-0.24
		c0.126-3.966-0.141-7.539-0.239-11.279c-0.106-4.534-0.85-8.431-0.96-12.96c-0.666-3.094-0.737-6.784-0.96-10.32
		c-0.522-3.638-0.722-7.599-0.721-11.76c-0.558-1.522-0.081-4.078-0.24-6c0-0.08,0-0.16,0-0.24c-0.558-1.922-0.081-4.879-0.239-7.2
		c0-0.08,0-0.16,0-0.24c-0.399-0.729-0.399-3.112,0-3.84c-0.507-5.494-1.311-10.69-1.681-16.32c-0.627-0.333,0.117-2.037-0.479-2.4
		c0.161-1.202-0.223-1.858-0.24-2.88c-1.403-0.756-1.522-2.797-3.6-2.88c-0.808,2.418,1.048,3.814,1.68,6
		c0.896,3.096,0.248,6.323,0.72,9.84c0.096,0.711,0.607,1.428,0.721,2.16c0.32,2.08-0.102,4.41,0,6.72
		c0.49,11.161,0.471,23.631,2.159,33.12c0.635,3.565,1.954,6.892,2.16,9.84c0.252,3.593-0.533,7.851-0.72,11.761
		c-0.198,4.168-0.654,8.191-0.72,12c-0.553,0.567-0.086,2.153-0.24,3.119c-0.427,2.961-1.803,4.356-3.12,6.96
		c-0.876,1.731-1.766,4.129-2.64,5.28c-0.646,0.849-1.851,1.197-2.4,2.16c-0.286,0.5-0.179,1.477-0.479,2.16
		c-0.692,1.568-2.226,3.105-2.16,5.279c-1.093-0.364-1.783,0.029-2.641,0.48c-2.052,14.268-2.466,30.174-7.439,41.52
		c1.058,0.862,2.975,0.865,4.08,1.681c0.691,0.028,1.571-0.131,1.92,0.24c1.407,0.593,1.317,2.682,2.64,3.359
		c0.833-0.192,0.923,0.357,1.68,0.24c0.825,0.695,1.431,1.609,2.16,2.4C394.946,508.655,395.741,508.82,396.801,508.721z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M241.521,408.641c0,0.16,0,0.32,0,0.48
		c0.342,0.618,0.775,1.145,0.72,2.16c0.666,1.471-0.012,3.664-0.96,4.32c0,0.8,0,1.601,0,2.399c0.546,0.334,0.93,0.831,1.44,1.201
		c0.307,0.013,0.333,0.307,0.72,0.239c0.259-3.381-0.391-9.208,0.24-12.96c0.216-3.463,0.663-6.696,1.44-9.6
		c0.326-1.194,0.324-2.715,0.72-3.84c0.021-0.78,0.124-1.476,0.48-1.92c0.076-0.804,0.247-1.513,0.72-1.92
		c0.37-2.75,1.732-4.508,2.16-7.2c0.478-1.363,0.714-2.966,1.44-4.08c0.206-1.634,0.731-2.948,1.2-4.32
		c-0.009-1.529,0.841-2.199,0.72-3.84c0.203-0.997,0.329-2.071,0.72-2.88c-0.185-0.904,0.293-1.147,0.24-1.92
		C249.232,379.232,241.946,390.506,241.521,408.641z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M277.28,437.681c0.044-0.523-0.196-0.764-0.72-0.72
		c-0.809-0.392-1.024-1.376-2.16-1.44c-2.739,0.534-2.66,2.853-3.36,6c-0.713,3.21-3.794,6.069-3.36,8.88
		c0.411-0.229,0.483-0.796,0.96-0.96c0.779-0.98,1.659-1.86,2.64-2.64c0.2-0.76,0.7-1.221,1.2-1.68c0.33-1.111,1.095-1.785,1.92-2.4
		c0.067-0.493,0.641-0.479,0.96-0.721c0.5-0.379,0.978-0.781,1.68-0.959c0.501-0.459,1.146-0.774,1.92-0.961
		C278.83,438.852,277.683,438.639,277.28,437.681z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M321.2,479.201c0.305-0.736,0.817-1.264,1.2-1.92
		c-2.092,0.331-1.915-1.605-2.64-2.641c-3.205,3.467-3.818,7.701-6.48,11.28c-2.362,3.177-6.96,5.125-10.56,7.439
		c-3.764,2.421-7.173,5.057-10.32,7.681c-1.002-0.123-1.626,0.134-2.16,0.479c-1.545,0.861-0.408,1.962-0.72,3.601
		c-0.241,1.268-1.745,3.626-2.64,5.279c-0.69,1.274-3.184,4.26-2.64,5.04c0.511,0.734,1.973-0.294,1.92,0.48
		c0.186,0.054,0.473,0.007,0.48,0.24c0.431-0.031,0.562,0.238,0.48,0.72c1.158-0.202,1.713-1.007,2.16-1.92
		c0.489,2.951,0.381,6.499,1.44,8.88c0.581-0.139,0.533-0.906,0.72-1.44c0.08,0,0.16,0,0.24,0c0.149-0.891,0.431-1.648,0.72-2.399
		c-0.035-1.075,0.025-2.055,0.48-2.64c-0.174-0.734,0.245-0.875,0.24-1.44c0.172-0.947,0.297-1.942,0.719-2.64c0-0.08,0-0.16,0-0.24
		c0.125-1.396,0.094-2.947,0.721-3.84c0.023-0.378-0.032-0.832,0.48-0.721c0.057-0.422,0.412-0.547,0.959-0.479
		c0.139-0.422-0.289-1.41,0.24-1.44c0.055-0.186,0.007-0.473,0.24-0.479c0.056-1.225,0.672-1.888,1.2-2.641
		c0.356-0.923,0.925-1.635,1.68-2.159c0.051-0.27,0.267-0.374,0.479-0.48c0.573-0.708,1.052-1.509,2.16-1.68
		c1.276-0.484,1.785-1.735,2.881-2.4c0.505-0.935,1.602-1.278,2.4-1.92c0.079,0,0.159,0,0.239,0c1.188-1.453,2.814-2.466,4.56-3.36
		c0.309-0.411,0.497-0.943,1.2-0.96c0.657-0.783,1.249-1.631,1.92-2.399c0.34-1.021,0.407-2.313,1.681-2.4
		c0.398-1.121,1.431-1.609,1.68-2.88C319.396,481.557,320.4,480.48,321.2,479.201z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M504.801,570.401c-0.318-0.241-0.893-0.228-0.96-0.721
		c-0.478-0.242-1.025-0.415-1.2-0.96c-1.394-0.606-2.338-1.662-3.6-2.399c-2.047-1.794-4.821-2.858-6.96-4.561
		c-1.162-0.358-2.117-0.923-2.88-1.68c-1.374-0.626-2.743-1.257-3.84-2.16c-0.727-0.154-1.386-0.375-1.44-1.2
		c-0.606-0.354-1.072-0.848-1.92-0.96c-1.822,0.063-3.269-0.251-3.601-1.68c-0.187-0.053-0.278-0.201-0.239-0.48
		c-0.774-0.746-1.247-1.793-1.92-2.64c-0.634-2.247-2.302-3.459-3.36-5.28c-0.479-0.8-1.319-1.24-1.92-1.92
		c-0.785-0.734-1.832-1.208-2.4-2.16c-1.356-0.563-2.344-1.495-3.359-2.399c-1.426-0.734-2.729-1.591-4.08-2.4
		c-0.615-0.025-1.145-0.136-1.44-0.479c-1.315-0.284-2.512-0.688-3.6-1.2c-0.955,0.074-1.206-0.555-2.16-0.48
		c-1.465-0.375-2.962-0.718-4.08-1.439c-0.282-0.038-0.682,0.041-0.72-0.24c-1.371-0.229-2.364-0.836-3.36-1.44
		c-0.16,0-0.32,0-0.479,0c1.693,3.348,8.058,4.103,10.8,7.2c0.9,0.699,1.7,1.5,2.399,2.4c6.54,2.764,7.139,10.658,10.08,17.04
		c0.55,1.191,1.766,2.199,2.641,3.6c0.805,1.29,1.017,2.794,1.92,3.601c1.928,1.721,5.102,1.922,8.399,2.399
		c6.08,5.185,12.016,11.142,18,16.8c1.367,1.292,2.358,3.563,4.561,3.841c1.423-1.115,0.009-3.126-0.48-4.08
		c-2.568-5.012-6.785-8.934-11.04-12.721c-2.55-2.27-5.229-4.934-7.92-7.199c-0.543-0.258-1.055-0.545-1.439-0.96
		c0.614,0.185,1.36,0.239,1.68,0.72C492.291,565.312,499.033,568.408,504.801,570.401z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M404.48,706.241c0-0.08,0-0.16,0-0.24
		c-0.89-0.31-2.008-0.392-3.12-0.479c-0.895,0.161-1.728-0.974-2.159-0.48c2.044,1.235,1.095,5.466,3.359,6.48
		c1.242,0.118,3.133-0.412,3.36,0.72c0.08,0,0.16,0,0.24,0c0.468-0.973,0.333-2.547,0.96-3.36c-0.284-0.116-0.545-0.256-0.721-0.479
		C405.471,707.972,405.313,706.769,404.48,706.241z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M308.48,212.56c-0.123,0.836-1.568,0.352-2.4,0.48
		c-0.756,0.283-1.333,0.747-1.92,1.2c0.041,0.68,0.44,1,0.48,1.68c1.098,0.902,1.654,2.346,2.4,3.6
		c0.469,0.891,1.039,1.681,1.439,2.64c0,0.16,0,0.32,0,0.48c0.862,0.818,1.285,2.075,1.92,3.12c0,0.241,0.194,0.286,0.24,0.48
		c0.303,0.577,0.309,1.451,0.72,1.92c-0.018,0.418,0.054,0.747,0.24,0.96c0.568,0.632,0.79,1.61,0.96,2.64
		c0.269,0.771,0.657,1.423,0.72,2.4c0.533,0.347,0.332,1.429,0.721,1.92c0.154,1.445,0.737,2.462,0.72,4.08
		c0.261-0.059,0.163-0.477,0.479-0.48c-0.066-2.467,0.548-4.253,0.48-6.72c0.597-0.363-0.147-2.067,0.479-2.4c0-0.64,0-1.28,0-1.92
		c0.613-0.427-0.159-2.239,0.48-2.64c0.308-1.704-0.065-2.613,0.72-3.84c-0.459-1.185,0.241-3.275,0.48-4.56
		c-0.169-1.048,0.408-1.352,0.239-2.4c0.514-1.247,0.34-3.181,0.961-4.32c0.028-1.732,0.292-3.228,0.72-4.56
		c0.138-0.422-0.29-1.41,0.24-1.44c0.323-2.396,0.245-5.194,0.96-7.2c0.276-0.284,0.271-0.848,0.239-1.44
		c0.021-0.78,0.124-1.477,0.48-1.92c-0.226-1.659,2.385-2.241,3.36-1.2c0.316,0.003,0.219,0.421,0.479,0.48
		c0.469-1.771-0.068-4.549,0.48-6.24c-0.154-3.687,0.312-7.992-0.24-11.28c0.148-3.188-0.463-5.617-0.48-8.64
		c-0.611-1.948-0.766-4.354-1.2-6.48c0-0.32,0-0.64,0-0.96c-0.588-1.572-1.185-3.135-1.439-5.04c-0.279-0.04-0.261-0.379-0.24-0.72
		c-0.523-1.157-0.861-2.499-1.2-3.84c-0.215-2.185-1.283-3.517-1.68-5.52c0.104-5.077-3.873-7.469-6.24-10.8
		c-0.786-0.205-1.134-1.751-1.92-1.2c0.711,4.649,3.418,7.302,3.36,12.72c0.56,5.041,0.08,11.12,0.24,16.56c0,0.08,0,0.16,0,0.24
		c0.159,0,0.319,0,0.479,0c0.15-0.512,1.478-0.225,1.68,0c0.041,0.841-0.844,0.756-1.199,1.2c-0.054,0.187-0.201,0.279-0.48,0.24
		c-0.98,3.104-1.717,10.745-2.64,14.88c-0.924,1.505-2.348,1.519-3.84,1.44c-0.74,1.197,2.252,2.447,2.879,3.84
		c0.707,0.733,0.435,2.447,0.48,3.84c-3.087-0.094-5.291,0.745-7.439,0.96c0.498,1.181,1.947,1.413,2.88,2.16
		c-0.057,1.977,0.99,2.85,2.159,3.6c0,0.16,0,0.32,0,0.48c-0.686,1.234,0.215,4.054-0.479,5.28c-1.086,5.098,1.384,8.253,1.92,12.48
		C310.773,212.243,309.332,212.17,308.48,212.56z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M322.16,216.16c0.395,0.629-0.034,2.095-0.239,2.88
		c-0.345,3.175-0.7,6.341-1.2,9.36c-0.06,1.541-0.111,3.089-0.48,4.32c-0.094,2.386-0.26,4.7-0.72,6.72
		c-0.148,0.651,0.305,1.904-0.24,2.16c0,0.72,0,1.44,0,2.16c2.052,1.479,3.719-1.334,4.08-3.12c-0.566-1.341-0.04-3.087,0.72-3.84
		c1.05-1.751,1.802-4.948,0.24-6.72c0.074-0.954-0.554-1.206-0.479-2.16c-0.895-1.346-0.425-4.065,0.239-5.28
		c-0.074-0.954,0.555-1.206,0.48-2.16c0.351-0.929,0.38-2.18,1.2-2.64c1.181-0.979,1.431-2.889,2.399-4.08
		c0.27-1.731,1.225-2.776,1.921-4.08c0.162-0.638,0.377-1.222,0.72-1.68c0.214-2.575,1.506-4.285,3.359-4.08
		c2.598,0.287,3.319,4.978,3.841,7.44c0.61,0.941,0.176,2.889-0.48,3.36c-1.436,0.076-2.186-0.535-2.399-1.68
		c-0.559-1.042-0.682-2.519-1.44-3.36c-0.05,1.044-0.935,1.519-0.48,2.4c0.397,0.259,0.397,2.141,0,2.4
		c-0.146,1.587,0.511,2.37,0.96,3.36c0.531,0.829,0.743,1.977,1.2,2.88c-0.063,0.864,0.443,1.157,0.48,1.92
		c0.628,1.131,1.166,2.354,1.2,4.08c0.316,0.003,0.219,0.421,0.479,0.48c-0.018-1.458,0.771-2.109,0.72-3.6
		c0.05-2.03,0.809-3.352,0.721-5.52c0.643-2.668,0.108-6.169-0.48-8.4c-0.569-0.15,0.037-1.477-0.479-1.68
		c-0.527-0.833-0.643-2.078-0.96-3.12c-0.662-0.858-1.144-1.897-1.44-3.12c-0.894-0.547-1.197-1.683-1.92-2.4
		c-0.298-0.502-0.698-0.902-1.2-1.2c-4.798,4.402-6.407,11.992-10.08,17.52C322.401,215.921,322.206,215.966,322.16,216.16z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M282.32,326.561c0.987-1.653,2.334-2.946,3.6-4.32
		c0.051-0.269,0.267-0.374,0.48-0.48c1.281-2.079,3.023-3.697,4.801-5.28c0.285-1.394,2.041-1.318,2.159-2.88c0.08,0,0.16,0,0.24,0
		c0.051-0.27,0.267-0.374,0.479-0.48c0.988-1.012,1.703-2.297,2.881-3.12c0.941-0.578,1.516-1.524,2.64-1.92
		c0.175-0.545,0.722-0.718,1.2-0.96c1.812-1.628,3.854-3.026,5.039-5.28c0.19-1.25,1.141-1.74,1.201-3.12
		c0.446-1.313,1.045-2.474,1.439-3.84c0.528-1.152,0.75-2.61,1.439-3.6c0.493-1.507,0.66-3.34,0.961-5.04
		c0.172-1.268,0.326-2.554,0.72-3.6c-0.012-1.211,0.094-2.306,0.479-3.12c0.122-1.719,0.291-3.389,0.721-4.8
		c0.002-0.637-0.091-1.371,0.24-1.68c0.17-0.869,0.09-1.99,0.479-2.64c-0.031-0.592-0.036-1.156,0.24-1.44
		c-0.147-0.876-0.124-1.936,0.24-2.88c-0.013-1.211,0.093-2.306,0.479-3.12c0.047-1.154,0.115-2.285,0.48-3.12
		c-0.218-0.503-1.355-0.085-1.44-0.72c-0.646-1.033-1.128-2.231-0.96-4.08c-0.53-1.63-0.553-3.768-0.721-5.76
		c-0.391-1.449-0.681-2.999-0.719-4.8c-0.542-0.179-0.094-1.346-0.24-1.92c-3.311,0.734-4.506,6.968-5.521,10.8
		c-1.806,6.827-2.523,16.681-1.92,25.68c0.551,8.214-0.779,14.883-4.08,20.4c-0.916,1.532-1.838,2.98-2.64,4.32
		c-2.994,5.005-5.116,8.429-8.4,12.72c-0.286-3.109,0.513-6.012,1.92-9.6c0.77-1.962,2.472-4.19,2.4-5.52
		c-0.07-1.307-1.356-2.644-2.641-2.4c-1.537,0.292-3.438,6.368-4.319,8.16c-3.01,6.123-4.595,11.516-7.44,17.52
		c-1.493,3.149-4.09,7.35-3.36,10.08c0.771-0.269,1.237-0.843,1.44-1.68c0.734-0.707,1.355-1.525,2.16-2.16
		c1.028-1.372,2.117-2.683,3.36-3.84C281.891,326.771,282.107,326.667,282.32,326.561z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M355.041,404.801c0.502-1.866-0.763-4.036-0.721-6.24
		c-0.529-0.03-0.102-1.018-0.239-1.44c-0.51-0.611-0.403-1.837-0.721-2.64c-1.451,0.11-0.256,1.834-0.239,2.64
		c0.18,1.419,0.687,2.513,0.72,4.08c0.552,0.568,0.086,2.154,0.24,3.12C353.839,405.042,354.615,404.746,355.041,404.801z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M381.681,400.96c1.111-0.019,0.053-1.087,0.24-1.68
		c-0.452-0.508-0.024-1.896-1.44-1.44c-1.79,0.233,0.14,1.787,0,2.64c0,0.08,0,0.16,0,0.24
		C380.829,400.852,381.721,400.44,381.681,400.96z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M285.44,462.4c0.166-0.954,1.056-1.185,1.44-1.92
		c0.7-0.9,1.5-1.7,2.4-2.399c0.986-1.095,1.909-2.251,3.12-3.12c0-0.08,0-0.16,0-0.24c0.985-0.695,1.645-1.716,2.641-2.4
		c0.66-0.859,2.069-0.97,1.92-2.64c0.358-1.398-0.913-1.167-0.721-2.4c-0.59-0.77-1.041-1.679-1.68-2.399
		c0.111-1.471-1.4-1.32-1.439-2.641c-0.633-0.248-0.809-0.951-1.44-1.199c-1.651,0.588-2.267,2.213-3.36,3.359
		c-0.379,1.924,0.643,3.61,0.48,5.28c-0.292,2.991-3.295,5.857-4.56,9.36c-1.046,2.895-1.167,5.021-2.64,7.199
		c0.315-0.084,0.377-0.422,0.72-0.479C283.377,464.657,284.172,463.292,285.44,462.4z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M303.681,471.521c-0.359-0.041-0.886,0.086-0.96-0.24
		c-0.204,0.045-0.227-0.093-0.24-0.239c-0.639-0.642-1.268-1.292-1.68-2.16c-0.225-0.176-0.363-0.437-0.48-0.72
		c-2.984,1.9-5.645,5.622-7.68,9.359c-1.224,2.247-4.031,5.49-1.92,6.96c1.422-1.059,2.418-2.541,3.84-3.6
		c0.785-0.895,1.629-1.731,2.641-2.4c0.699-0.9,1.499-1.7,2.399-2.399c0.591-0.529,1.053-1.188,1.92-1.44
		c0.681-1.079,2.944-0.575,3.84-1.439c0.774-0.826,1.603-1.599,2.64-2.16c0.754-1.167,1.553-2.288,2.641-3.12
		c-0.506-1.414-1.474-2.366-1.68-4.08c-0.875-1.525-1.931-2.87-2.4-4.8c-0.021-0.379-0.612-0.188-0.48-0.721
		c-0.86-0.898-1.559-1.961-2.16-3.119c-1.221-0.939-2.115-2.204-3.6-2.881c-2.944,1.747,0.176,3.438,0.961,5.28
		c1.25,2.936-0.78,7.396,0.959,10.32c0.352,0.209,0.761,0.359,0.721,0.96C303.733,469.112,304.826,470.885,303.681,471.521z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M525.681,550.241c-0.328-0.872-0.74-1.659-1.199-2.4
		c0.175-0.654-0.478-0.483-0.48-0.96c-0.245-0.315-0.235-0.885-0.479-1.2c-0.16,0-0.32,0-0.48,0c-0.524,1.084-2.39,0.215-2.64-0.479
		c-1.092-0.908-2.504-1.497-3.601-2.4c-1.051-0.309-1.873-0.847-2.88-1.2c-0.533-0.187-0.936-0.504-1.68-0.479
		c-0.833-0.287-1.864-0.376-2.4-0.96c-1.503-0.337-2.914-0.767-4.08-1.44c-0.977-0.063-1.628-0.451-2.399-0.72
		c-0.99,0.845,3.026,1.737,4.319,2.399c3.046,1.562,5.598,4.066,7.681,5.521c-0.026,2.534-1.384,3.737-1.681,6
		c1.262,0.019,2.257,0.303,3.12,0.72c1.067,0.294,2.444,0.275,2.88,1.2c0.819,0.392,0.819,1.769,0,2.16
		c-1.869,1.498-3.567-0.763-5.76-0.48c-2.072-0.567-4.463-0.817-6.72-1.199c-0.96,0-1.92,0-2.88,0c-0.721,0-1.44,0-2.16,0
		c0.466,2.733,4.06,2.34,6,3.6c1.822,5.538,3.566,11.153,5.28,16.8c0,0.08,0,0.16,0,0.24c1.427,6.333,3.203,12.316,4.079,19.2
		c0.555,0.726,0.085,2.476,0.24,3.6c0.118,1.078-0.121,1.799-0.24,2.641c0.001,0.8,0,1.6,0,2.399
		c-0.658,4.599-0.935,11.713-2.64,16.08c-0.687,1.759-1.852,3.799-2.64,6c-0.743,2.075-2.479,5.651-1.681,6.48
		c1.365-1.915,2.313-4.247,3.841-6c0.42-0.86,0.872-1.688,1.439-2.4c0.397-1.603,1.103-2.897,1.92-4.08
		c0.438-1.082,0.755-2.285,1.44-3.12c0.155-0.964,0.259-1.98,0.72-2.64c0.063-1.137,0.55-1.85,0.48-3.12c0.08,0,0.159,0,0.239,0
		c0.134-1.467,0.293-2.907,0.721-4.08c0.143-0.497-0.297-1.576,0.239-1.68c0.457-2.583-0.328-6.408,1.2-7.92
		c0.054-0.187,0.007-0.474,0.24-0.48c-0.326-0.396,0.196-1.4,0-2.399c0.402-0.398,0.19-1.409,0.24-2.16
		c-1.227-8.577-2.021-19.727-1.44-28.561c-0.025-2.266,1.011-3.47,1.2-5.52c0.519-0.602,0.488-1.752,1.2-2.16
		c0.013-0.307,0.308-0.332,0.24-0.72c0.505-0.615,0.51-1.729,0.96-2.4C525.302,551.142,525.604,550.805,525.681,550.241z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M405.44,630.161c-0.773-0.187-1.418-0.502-1.92-0.96
		c-1.27-0.65-2.401-1.438-3.84-1.92c-0.85-0.511-1.925-0.795-2.64-1.44c-0.284-0.116-0.545-0.256-0.721-0.479
		c-0.589,7.312,3.699,14.884,3.601,23.04c0,0.08,0,0.16,0,0.239c1.163,0.437,2.013,1.188,3.6,1.2c0.48,0,0.96,0,1.44,0
		c-0.107-1.973,0.247-4.406-0.24-6c0.159-2.881-0.318-6.398,0.24-8.88C404.976,633.216,405.619,632.1,405.44,630.161z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M404.001,663.761c0.037-0.282-0.041-0.681,0.24-0.72
		c1.102-2.811,1.81-5.922,1.199-9.84c-0.724,0.01-2.056,0.646-2.64,0c-0.24,0-0.48,0-0.72,0c0.332,0.147,0.036,0.924,0.479,0.96
		c-0.162,2.813-2.857,5.131-2.88,8.88c-0.152,0.809,0.31,2.229-0.24,2.641c-1.099,2.502,0.704,4.23,0.96,6
		c0.109,0.75-0.27,1.777-0.239,2.64c0.036,1.051,0.738,2.089,0.72,2.88c-0.035,1.445-1.082,4.143-1.68,6.24
		c-0.979,3.43-2.557,5.744-3.601,8.399c-0.247,0.629-0.151,1.724-0.479,2.641c-0.646,1.8-1.691,3.514,0,5.76
		c1.73,0.269,3.457,0.542,4.8,1.2c1.229,0.057,2.743,1.089,3.6,0.72c0-0.08,0-0.16,0-0.24c-0.673-1.727-1.403-3.396-2.399-4.8
		c-0.241-0.799-0.432-1.648-0.721-2.4c0.328-1.031,0.016-2.704,0.48-3.6c-0.188-0.988,0.368-1.231,0.24-2.16
		c-0.061-3.662,2.415-6.247,1.439-10.8c-0.206-0.51,0.05-1.662-0.24-2.64c0.288-2.608-0.643-3.997-0.72-6.24
		c-0.455-0.706,1.657-0.896,1.2-2.4c0.238-1.122,0.722-1.998,0.96-3.12C403.841,663.761,403.921,663.761,404.001,663.761z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M270.081,726.642c-0.529-0.352-1.031-0.729-1.92-0.721
		c-1.601,0.641-0.9,3.067,0.24,3.601c0.628,0.341,1.381,1.807,0.72,2.64c-0.079,0.562-0.289,0.991-0.48,1.44
		c-0.058,0.342-0.396,0.404-0.48,0.72c-0.351,0.585,0.054,2.075,0.48,2.64c0.33,0.011,0.527-0.112,0.72-0.239
		c0.54-0.741,1.179-1.381,1.92-1.921c0.013-0.307,0.308-0.332,0.24-0.72c0.604-0.675,0.546-2.014,0.96-2.88
		c0.262-1.302-0.484-1.596-0.48-2.64C271.276,728.006,270.943,727.059,270.081,726.642z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M333.2,236.32c-0.103,0.537-1.183,0.097-1.68,0.24
		c-3.446,3.674-4.641,9.6-7.68,13.68c-1.544,0.617-2.905,1.415-3.601,2.88c1.381,0.021,2.772,0.052,3.84-0.24
		c0.041-0.28,0.38-0.261,0.721-0.24c0.816-0.704,2.095-0.944,2.64-1.92c0.08,0,0.16,0,0.24,0c1.523-1.196,2.738-2.702,3.84-4.32
		c0.693-1.147,0.446-3.234,0.48-5.04c0.08,0,0.159,0,0.239,0c0.318-1.282,0.313-2.888,0.96-3.84
		C333.057,237.093,333.904,236.523,333.2,236.32z"/>
		</g>
	</svg>
