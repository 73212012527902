import React from 'react'
export const Assassin = () =>
	<svg version="1.1" className="character_svg" id="Assassin" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		width="576px" height="828px" viewBox="0 0 576 828" enableBackground="new 0 0 576 828" space="preserve">
		<g id="eye_backing">
			<path fillRule="evenodd" clipRule="evenodd" fill="#DADBD9" d="M312.714,186.479c-0.898-0.51-1.282-1.536-2.349-1.879
		c0-0.079,0-0.157,0-0.235c-1.155-0.332-1.982-0.993-3.053-1.409c-0.655-0.207-1.43-0.293-1.879-0.705
		c-0.547-0.393-1.266-0.614-2.114-0.705c-1.941-0.486-3.846-1.009-5.637-1.644c-1.738-0.611-3.508-1.189-5.872-1.174
		c-0.646,0.449-1.194,0.997-1.644,1.644c-1.842,2.385-3.537,4.918-5.402,7.281c6.772,1.448,12.938,3.503,21.843,2.818
		c0.078,0,0.156,0,0.235,0c0.943-0.544,2.893-0.082,4.228-0.235c0.234,0,0.47,0,0.704,0c0.633-0.541,2.266-0.083,3.288-0.235
		C314.594,188.514,313.324,187.827,312.714,186.479z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#DADBD9" d="M250.76,178.789c-0.635-0.087-1.051,0.044-1.369,0.274
		c-1.45,0.557-3.009,1.005-4.654,1.368c-1.539,0.104-1.94,1.345-3.559,1.369c-0.573,0.523-1.308,0.883-2.191,1.095
		c-1.258,0.567-2.39,1.26-2.463,3.011c-0.242,0.762-0.342,1.666-0.821,2.19c-0.423,0.398-0.575,1.067-0.547,1.916
		c0.133,0.864-0.493,0.967-0.274,1.917c0.598,0.679,1.592,0.964,2.19,1.643c0.456,0,0.913,0,1.369,0
		c0.763-0.241,1.666-0.341,2.19-0.822c2.691-1.013,6.034-1.9,7.938-4.379c0.36-0.735,0.734-1.456,1.095-2.189
		c0.148-1.312,1.279-1.642,1.643-2.738c0.315-0.324,0.31-0.967,0.273-1.643C251.279,180.824,252.324,178.503,250.76,178.789z"/>
		</g>
		<g id="eye1">
			<circle fillRule="evenodd" clipRule="evenodd" cx="300.522" cy="185.771" r="7.084" />
		</g>
		<g id="eye2">
			<circle fillRule="evenodd" clipRule="evenodd" cx="246.951" cy="187.312" r="7.084" />
		</g>
		<g id="body">
			<g id="gold">
				<path fillRule="evenodd" clipRule="evenodd" fill="#B2AB3B" d="M164.907,561.519c-0.573-0.836,0.082-2.9-0.939-3.289
			c-2.458-0.479-4.856,2.331-5.168,3.994c4.84,0.16,1.403,9.342-1.41,8.927c-0.463,2.202,2.2,5.304,4.933,5.872
			c0.592-0.426,0.583-1.453,1.175-1.879c0.816-2.237,1.211-4.896,1.409-7.752C165.05,565.566,164.834,563.828,164.907,561.519z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#B2AB3B" d="M158.095,566.922c-0.021-0.334-0.039-0.666,0.235-0.705
			c0.199-0.912,0.577-2.351-0.705-2.35c0.039,0.273-0.052,0.419-0.235,0.471c0.063,0.846-0.434,1.132-0.47,1.879
			c0.03,0.421-0.288,0.495-0.235,0.939C157.4,566.806,157.599,567.233,158.095,566.922z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#B2AB3B" d="M160.444,600.983c-1.853,0.653-3.789,1.224-4.463,3.054
			c0.372,0.02,0.896-0.113,0.939,0.235c0.273-0.038,0.418,0.052,0.47,0.234c1.565,0.305,2.314,2.306,1.41,3.759
			c-0.738,2.786-0.542,6.505-3.289,7.282c-0.76,0.271-1.229-1.656-1.41-0.47c0,0.078,0,0.156,0,0.234
			c0.104,0.758,0.45,1.273,0.47,2.114c1.555,4.338,5.082,3.651,6.578-0.234c0.607-2.212,1.527-4.111,1.645-6.813
			c0-1.097,0-2.192,0-3.289c-0.181-2.246-0.558-4.297-1.175-6.107C161.227,600.983,160.835,600.983,160.444,600.983z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#B2AB3B" d="M156.215,608.501c0-0.235,0-0.47,0-0.705
			c-0.096-0.566,0.429-0.511,0.235-1.175c-0.078,0-0.157,0-0.235,0c-0.742,0.115-0.83-0.423-1.645-0.234
			c-0.125,0.658-0.345,1.221-0.47,1.879c0,0.078,0,0.156,0,0.235c0.731,0.208,1.159,0.72,1.879,0.939
			C156,609.068,155.867,608.544,156.215,608.501z"/>
			</g>
			<path id="blue" fillRule="evenodd" clipRule="evenodd" fill="#262F48" d="M215.178,510.778c0.02,1.662,0.023,2.734-0.705,4.228
		c8.167-5.157,15.197-12.102,23.491-17.383c8.372-5.331,17.62-9.214,25.84-15.034c7.516-22.555,16.946-44.113,26.31-65.07
		c4.738-10.605,9.13-21.045,14.564-31.008c5.409-9.917,11.479-19.266,16.679-29.128c-6.38,1.141-11.714,4.915-16.914,8.222
		c-12.851,8.171-25.12,16.7-38.055,25.135c-2.367,1.544-5.722,4.801-8.222,3.994c-2.295-0.741-3.512-5.538-4.698-8.222
		c-4.17-9.435-7.596-19.075-9.631-29.833c-0.014-0.143-0.035-0.278-0.235-0.235c-4.675,10.926-10.736,21.58-14.33,33.592
		c-6.961,23.267-15.54,48.685-16.444,77.521c-0.22,7.02,0.279,14.028,0.705,21.377C213.942,495.984,215.088,503.364,215.178,510.778
		z"/>
			<g id="brown">
				<g>
					<path fillRule="evenodd" clipRule="evenodd" fill="#2D2328" d="M308.202,129.989c4.705,1.952,12.138,4.462,18.558,4.698
				C322.334,131.515,316.535,128.472,308.202,129.989z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#2D2328" d="M375.856,298.654c-4.502-7.635-8.467-15.807-10.102-26.31
				c-0.998,2.76-0.07,7.446-1.645,9.631C367.055,288.506,370.446,294.589,375.856,298.654z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#2D2328" d="M386.897,310.634c-6.398-6.443-16.456-9.228-20.203-18.323
				c1.241,5.209,1.35,10.125,3.759,13.625c2.064,2.998,6.017,5.637,9.162,7.752c5.026,3.38,10.887,5.229,15.034,8.691
				c-1.377-4.574-4.849-7.053-7.518-10.336C387.302,311.326,386.892,311.188,386.897,310.634z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#2D2328" d="M373.037,259.424c-0.078,0-0.156,0-0.234,0
				c0-0.078,0-0.156,0-0.235c0.078,0,0.156,0,0.234,0c0.726-0.528,1.352-1.154,1.88-1.879c-0.352-0.04-0.867,0.083-0.94-0.235
				c-0.27-0.278-0.266-0.831-0.234-1.41c0.271-0.278,0.266-0.831,0.234-1.41c0.552-1.406,0.939-2.976,1.175-4.698
				c0.413-1.466,0.728-3.031,0.939-4.698c0.717-1.398,1.126-3.103,1.645-4.698c0.615-1.734,1.419-3.279,1.88-5.168
				c1.048-3.023,3.836-4.307,4.698-7.517c1.37-3.171,2.802-6.281,3.758-9.866c0.643-0.846,0.923-2.052,1.175-3.289
				c0.69-1.972,1.416-3.909,1.409-6.578c0.391-0.791,0.391-3.203,0-3.993c-0.065-4.084-0.839-7.461-0.939-11.511
				c-0.533-0.25-0.089-1.477-0.234-2.114c-1.711-10.191-4.317-19.487-6.343-29.364c-0.133-1.12-0.245-2.26-0.705-3.054
				c-1.263-7.272-2.925-14.145-5.168-20.437c-0.555-2.029-2.076-3.092-3.054-4.698c0-0.079,0-0.157,0-0.235
				c-1.689-2.853-4.224-4.86-6.107-7.518c-2.971-3.66-6.848-6.146-10.571-8.926c-6.729-5.024-12.299-10.46-24.195-10.101
				c-1.624,0.049-3.388,0.525-5.168,0.235c-2.891-0.471-5.489-2.043-8.223-2.584c-14.886-2.944-29.806,0.279-42.753,3.993
				c-4.964,1.424-10.27,2.954-14.799,4.933c-6.655,2.909-10.871,9.858-14.33,15.739c-3.998,6.797-8.051,12.988-10.806,20.437
				c5.093-7.905,9.954-16.042,15.974-23.021c1.851,1.281,2.039,4.225,2.114,7.282c-0.163,0.776-1.874,0.005-1.879,0.939
				c0.475,1.248,0.428,3.018,1.409,3.759c2.566-1.741,6.417-2.197,7.987-4.933c6.337-3.156,15.659-3.938,25.136-4.463
				c1.863-0.104,3.989,0.164,5.873-0.235c7.414-1.57,17.689-0.57,25.84-0.94c1.279-0.058,2.368-0.41,3.523-0.235
				c4.73,0.716,6.946,6.99,10.336,10.101c0.903,0.829,2.459,1.662,3.994,2.584c1.25,0.751,3.515,1.413,3.758,3.054
				c0.206,1.392-1.024,1.882-0.939,3.054c2.127,6.335,5.404,11.421,8.927,16.914c3.44,5.366,8.425,9.671,10.571,15.269
				c0.756,1.974,1.077,4.612,1.409,7.517c0.268,2.338,1.552,5.646-0.47,7.047c-2.166-4.129-1.698-9-2.819-12.685
				c-1.198-3.938-3.519-6.635-6.107-9.631c-3.617-4.186-7.196-8.536-10.571-12.685c-1.174-1.444-1.951-3.406-3.758-4.229
				c0.221,2.207,0.713,4.142,1.879,5.403c0-0.078,0-0.157,0-0.235c0.481,0.38,0.592,1.13,0.939,1.645
				c0.586,0.511,0.686,1.507,1.409,1.879c0.147,0.871,0.661,1.375,0.94,2.114c-0.066,0.379,0.222,0.405,0.234,0.705
				c0.062,0.722,0.51,1.056,0.705,1.644c0.19,0.045,0.234,0.236,0.47,0.235c0.423,0.674,0.619,1.574,1.175,2.114
				c0,0.079,0,0.157,0,0.235c0.737,1.689,1.361,3.493,2.349,4.933c-0.201,0.75,0.37,0.727,0.47,1.175
				c0.594,0.894,0.792,2.184,1.645,2.819c0,0.078,0,0.157,0,0.235c0.225,0.48,0.479,0.93,0.705,1.41
				c-0.19-0.045-0.234-0.236-0.47-0.235c0.339,0.522,0.59,1.133,0.47,2.114c-0.38,0.403-0.983,0.583-1.88,0.47
				c-0.078,0-0.156,0-0.234,0c-0.481,0.881,0.293,2.519,0.47,3.523c0.199,1.054,0.313,2.192,0.939,2.819c0,0.079,0,0.157,0,0.235
				c0.524,1.825,1.762,2.937,2.819,4.229c0.614,1.421,2.022,2.049,2.818,3.289c0,0.079,0,0.157,0,0.235
				c0.504,1.451,3.758,1.912,3.289,3.523c-0.16,0.551-0.857,0.999-1.88,0c-0.078,0-0.156,0-0.234,0s-0.156,0-0.235,0
				c-3.088-2.236-5.071-5.578-7.752-8.222c-0.032-0.829-0.672-1.051-0.704-1.879c-0.144-0.013-0.278-0.035-0.235-0.235
				c-0.217-0.41,0.008-1.261-0.705-1.175c0,0.392,0,0.783,0,1.175c-0.43,0.51,0.045,1.923-0.47,2.349
				c0.183,0.209,0.253,0.53,0.235,0.939c-0.39,0.482-0.39,2.572,0,3.054c-0.157,1.332,0.527,1.821,0.705,2.819
				c-0.066,0.379,0.222,0.404,0.234,0.705c0.728,2.248,2.743,3.208,3.523,5.403c-0.3-0.013-0.325-0.301-0.704-0.235
				c-0.079,0-0.157,0-0.235,0c-2.014-1.354-3.057-3.678-4.463-5.638c0,0.783,0,1.566,0,2.349c0.154,1.725-0.311,4.069,0.234,5.403
				c0,0.157,0,0.313,0,0.47c0,1.645,0,3.289,0,4.933c0.06,1.077-1.259,0.777-1.175,1.879c-0.561,0.926-0.812,2.164-1.174,3.289
				c-0.136,0.491-0.583,0.67-0.47,1.41c-0.745,0.743-0.821,2.154-1.88,2.584c-0.616,0.714-1.906,0.755-3.288,0.705
				c-0.079,0-0.157,0-0.235,0c0-0.078,0-0.157,0-0.235c-0.783,0-1.566,0-2.349,0c-0.235,0-0.471,0-0.705,0
				c-0.25-0.533-1.477-0.089-2.114-0.235c-1.018,0-2.036,0-3.054,0c-0.156,0-0.313,0-0.47,0c-0.783,0-1.566,0-2.35,0
				c0-0.078,0-0.157,0-0.235c-0.861,0-1.723,0-2.584,0c-0.783,0-1.565,0-2.349,0c-0.313,0-0.627,0-0.939,0c-0.157,0-0.313,0-0.47,0
				c0.043,0.2-0.092,0.222-0.235,0.235c-1.101,0.152-2.813-0.307-3.523,0.235c-0.235,0-0.47,0-0.705,0
				c-2.076,1.134-4.626,1.794-6.813,2.819c-0.156,0-0.313,0-0.47,0c-0.078,0-0.156,0-0.234,0c-0.079,0-0.157,0-0.235,0
				c-2.128,1.16-4.69,1.887-7.282,2.584c-1.521,6.49-5.046,15.451-7.047,22.551c-0.587,2.08-1.032,6.773-1.88,8.222
				c-1.765,3.018-4.459-0.653-5.403-1.645c-1.238,1.737-3.259,2.692-5.168,3.759c-0.75,2.793,1.486,13.669-1.174,14.094
				c-1.518,0.243-2.799-3.477-3.289-4.698c-0.988-2.462-1.156-4.022-2.349-5.638c-3.343,1.424-7.827,6.884-11.275,2.584
				c-2.02-4.245-5.74-6.789-8.457-10.336c-2.604,1.37-3.791,6.714-7.047,6.107c-3.057-0.569-2.708-6.8-6.107-7.752
				c-1.887,6.1-2.894,13.081-5.403,18.558c-0.485,0.533-1.377,0.659-2.114,0.939c-0.047,7.083-2.35,13.15-3.994,17.854
				c27.847-1.674,57.445-1.596,79.165-9.396c-1.811-6.92-4.275-12.628-7.282-18.793c-0.796-1.632-3.025-4.34-2.819-5.873
				c0.585-4.343,9.619,0.121,11.511,0.939c9.075,3.929,18.438,7.718,25.84,11.746c0.434-1.994,1.619-3.236,2.35-4.933
				c3.44-1.335,3.836-5.717,4.933-9.396c-0.078,0-0.156,0-0.234,0c0.036-1.132-1.093-1.1-0.705-2.584
				c-0.043-0.2,0.092-0.222,0.235-0.235c0,0.235,0,0.47,0,0.705c2.293-9.034,4.091-21.54,6.107-31.713
				c0.563-2.843,1.139-8.37,3.054-9.631c2.193-1.444,6.877-0.737,8.927-0.939c3.592-0.355,9.783-3.107,14.095-1.645
				c0.465,0.158,0.888,0.375,1.287,0.625c-0.005-0.004-0.008-0.009-0.013-0.013c0.744,0.716,2.076,1.106,2.719,1.738
				c0.197-0.154,0.36-0.342,0.565-0.488c0.195-0.016,0.399-0.063,0.61-0.139c0.044,1.731-0.268,3.816,0.703,4.621
				c0.067,1.186,0.47,2.037,0.705,3.054c-0.229,0.006-0.183,0.288-0.234,0.47c0.278,6.688-1.502,10.652-4.229,15.269
				c-4.125,6.984-8.175,10.689-16.444,12.686c0.29,7.014-3.622,12.925-3.993,19.262C364.53,266.421,368.787,262.926,373.037,259.424
				z M340.249,156.315c0.006,0.008,0.013,0.016,0.019,0.024c-0.005-0.007-0.011-0.014-0.017-0.021
				C340.25,156.317,340.25,156.316,340.249,156.315z M341.865,158.331c0.132,0.161,0.263,0.324,0.396,0.483
				C342.128,158.654,341.997,158.492,341.865,158.331z M331.256,255.306c0.003,0.009,0.005,0.018,0.008,0.026
				C331.261,255.324,331.259,255.314,331.256,255.306C331.256,255.306,331.256,255.306,331.256,255.306z M331.188,255.217
				c-0.487-0.591-1.09-1.033-1.664-1.531C330.1,254.184,330.701,254.626,331.188,255.217z M308.474,225.494
				c0.398,1.442,1.115,2.911,1.768,4.446c-0.655-1.538-1.37-3.006-1.77-4.451C308.473,225.491,308.473,225.493,308.474,225.494z
				 M310.292,230.061c0.132,0.312,0.256,0.626,0.378,0.943C310.548,230.688,310.424,230.372,310.292,230.061z M311.204,232.584
				c0.114,0.406,0.214,0.816,0.287,1.235C311.418,233.4,311.318,232.99,311.204,232.584z M310.803,231.355
				c0.104,0.284,0.196,0.571,0.286,0.86C310.999,231.927,310.906,231.639,310.803,231.355z M338.271,265.297
				c0.13-0.418,0.121-0.976,0.47-1.175c0.037,0.277-0.04,0.667,0.235,0.705c0.05-0.264,0.261-0.366,0.47-0.47
				C339.202,264.681,338.514,265.75,338.271,265.297z M339.68,264.357c0.646-0.644,0.707-2.464,1.88-1.879
				C340.795,262.727,340.547,263.671,339.68,264.357z M356.979,208.189c-0.056,0.581-0.111,1.147-0.151,1.669
				C356.868,209.336,356.923,208.77,356.979,208.189z M358.003,171.098c-1.256-1.86-0.839-4.226-1.175-6.107
				c-1.817-10.187-6.933-16.989-12.685-23.726c-2.257-2.644-4.302-5.699-6.813-7.752c-1.268-1.037-3.431-1.015-3.759-3.054
				c0.19-0.045,0.234-0.236,0.47-0.235c3.667,0.248,6.966,4.214,9.866,7.517c6.185,7.042,13.942,17.38,15.034,27.954
				C359.143,167.629,359.545,169.583,358.003,171.098z M376.326,208.214c-0.065,0.153-0.108,0.295-0.141,0.432
				C376.218,208.509,376.261,208.367,376.326,208.214z M376.165,208.742c-0.024,0.127-0.039,0.25-0.036,0.363
				C376.127,208.991,376.141,208.869,376.165,208.742z M376.128,209.117c0.004,0.126,0.023,0.245,0.055,0.357
				C376.151,209.362,376.131,209.244,376.128,209.117z M376.324,209.811c-0.056-0.096-0.099-0.199-0.132-0.307
				C376.226,209.612,376.27,209.714,376.324,209.811z M376.382,209.904c0.064,0.096,0.14,0.185,0.223,0.271
				C376.521,210.089,376.446,209.999,376.382,209.904z M381.259,186.837c-0.069-1.417-0.539-2.437-0.939-3.524
				c-0.414-1.7-0.839-3.389-1.645-4.698c-1.163-4.004-2.793-7.542-4.228-11.275c-0.281-0.815-0.368-1.825-0.94-2.349
				c-2.025-7.214-4.581-13.898-5.402-22.316c-0.085-1.154-1.053-3.446-0.235-3.994c0.938-0.077,0.496,1.227,0.939,1.645
				c0.363,1.125,0.613,2.362,1.175,3.289c0,0.313,0,0.626,0,0.94c0.2-0.043,0.222,0.091,0.235,0.235
				c0.332,1.469,0.529,3.072,1.174,4.228c-0.074,0.545,0.038,0.902,0.235,1.175c0.151,0.867,0.241,1.795,0.705,2.349
				c0.451,2.758,1.285,5.135,2.114,7.517c0.015,1.16,0.689,1.659,0.704,2.819c0.255,0.058,0.159,0.467,0.47,0.47
				c-0.1,0.491,0.161,0.622,0.235,0.94c0.182,0.602,0.234,1.332,0.704,1.644c0-0.313,0-0.626,0-0.939
				c-0.14-0.487,0.291-1.543-0.234-1.645c0-0.235,0-0.47,0-0.705c-0.15-0.945,0.306-2.498-0.235-3.054c0-0.235,0-0.47,0-0.705
				c-0.149-0.868,0.305-2.34-0.234-2.819c0-0.156,0-0.313,0-0.47c-0.149-0.791,0.303-2.182-0.235-2.584
				c-0.18-5.458-1.188-10.087-2.114-14.799c-0.199,0.043-0.222-0.092-0.234-0.235c-0.517-2.322,1.391-1.655,1.645-0.47
				c0.195,1.449,0.561,2.728,0.939,3.993c0,0.392,0,0.783,0,1.175c0.653-0.106-0.185,1.28,0.47,1.174
				c0.21,2.452,0.561,4.764,0.939,7.047c0,0.783,0,1.566,0,2.349c0.078,0,0.156,0,0.235,0c0,0.078,0,0.157,0,0.235
				c0.148,0.791-0.303,2.182,0.234,2.584c0,0.079,0,0.157,0,0.235c0,0.079,0,0.157,0,0.235c0.151,0.946-0.306,2.498,0.235,3.054
				c0,0.156,0,0.313,0,0.47c0,1.488,0,2.976,0,4.463c0.078,0,0.156,0,0.234,0c0,0.313,0,0.626,0,0.939
				c0.108,2.006-0.246,4.475,0.235,6.108c0.078,0,0.156,0,0.234,0c-0.112,0.739,0.335,0.918,0.47,1.409
				c0.473,1.485,0.886,3.03,1.645,4.229c1.859,6.206,3.759,12.372,4.698,19.498c0.153,1.179-0.308,2.97,0.235,3.758
				c0,0.157,0,0.313,0,0.47c-0.541,0.633-0.084,2.266-0.235,3.289c0,0.078,0,0.157,0,0.235c-0.399,0.696-0.258,1.934-0.47,2.819
				c0,0.079,0,0.157,0,0.235c-0.74,0.904-0.483,2.805-1.879,3.054c-0.078,0-0.156,0-0.235,0c-0.674-1.743,0.878-3.157,0.47-5.403
				c0.538-0.402,0.087-1.793,0.235-2.584c0-0.235,0-0.47,0-0.705c0.389-0.253,0.389-2.096,0-2.349
				c-0.095-1.001,0.228-2.42-0.235-3.054c0.014-1.032-0.094-1.941-0.47-2.584c0.077-1.095-0.183-1.853-0.47-2.584
				C382.192,189.662,381.892,188.084,381.259,186.837z M375.151,239.457c-0.035,1.296-0.719,1.943-0.704,3.289
				c-0.464,0.554-0.554,1.482-0.705,2.349c0,0.078,0,0.156,0,0.235c-0.522,0.73-0.636,1.87-0.705,3.054
				c-0.323,0.303-0.232,1.021-0.234,1.645c-0.561,0.692-0.38,2.126-0.939,2.819C372.744,248.169,373.523,243.388,375.151,239.457z
				 M371.158,255.9c0,0.783,0,1.566,0,2.349C370.77,257.996,370.77,256.154,371.158,255.9z M365.99,246.269c0.156,0,0.313,0,0.47,0
				c1.75,0.418,1.021,2.29,1.175,3.524c0.242,1.953,0.759,3.847,1.409,5.638c0.624,1.718,1.935,3.507,1.175,4.933
				c-1.628,0.636-2.339-2.026-3.054-3.759C366.101,254.027,364.684,249.569,365.99,246.269z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#2D2328" d="M404.985,263.417c-0.955-1.159-2.063-2.165-3.523-2.819
				c-2.746-1.953-6.211-3.186-9.632-4.463c0,0.079,0,0.157,0,0.235c0.024,0.92,1.974,1.938,2.584,3.054
				c-0.835-0.921-3.344-2.563-2.818-3.289c-1.62-0.503,1.955,2.959,2.349,3.289c2.815,2.353,6.105,4.146,8.927,6.813
				c8.137,7.692,12.632,19.146,15.739,31.713C420.044,282.247,412.998,271.213,404.985,263.417z M410.226,269.984
				c0.026,0.035,0.054,0.068,0.079,0.104c-0.424-0.602-0.85-1.202-1.284-1.793c0.864,1.177,1.695,2.386,2.542,3.58
				C411.13,271.231,410.682,270.603,410.226,269.984z M408.913,268.15c-0.381-0.517-0.771-1.023-1.169-1.522
				C408.143,267.127,408.532,267.634,408.913,268.15z M407.545,266.377c-0.416-0.511-0.847-1.006-1.292-1.488
				C406.698,265.371,407.129,265.867,407.545,266.377z M405.974,264.596c-0.467-0.49-0.947-0.967-1.458-1.414
				C405.026,263.629,405.507,264.106,405.974,264.596z M400.113,260.346c-0.001-0.001-0.003-0.002-0.004-0.003c0,0,0,0,0.001,0
				C400.111,260.344,400.112,260.345,400.113,260.346z M401.227,260.599c-0.232-0.018-0.442-0.057-0.642-0.108
				C400.787,260.538,400.994,260.581,401.227,260.599z M398.3,259.266c-0.086-0.063-0.175-0.124-0.263-0.186
				C398.125,259.142,398.214,259.203,398.3,259.266z M397.731,258.878c-0.073-0.047-0.147-0.093-0.224-0.138
				C397.584,258.785,397.658,258.831,397.731,258.878z M397.14,258.551c-0.067-0.032-0.132-0.066-0.202-0.095
				C397.008,258.485,397.072,258.52,397.14,258.551z M396.363,260.595c0.001,0.001,0.002,0.001,0.002,0.002
				c-0.001-0.001-0.002-0.002-0.004-0.004C396.362,260.594,396.362,260.594,396.363,260.595z M395.457,259.886
				c-0.002-0.001-0.003-0.002-0.004-0.003c0,0,0.001,0.001,0.002,0.001S395.456,259.886,395.457,259.886z M395.003,259.64
				c-0.02-0.01-0.039-0.021-0.06-0.029C394.964,259.619,394.983,259.63,395.003,259.64z M396.881,261.095
				c0.074,0.074,0.149,0.147,0.224,0.22C397.03,261.242,396.955,261.168,396.881,261.095z M397.308,261.511
				c0.132,0.126,0.266,0.249,0.402,0.365C397.573,261.76,397.439,261.637,397.308,261.511z M397.791,261.941
				c0.114,0.094,0.232,0.183,0.353,0.266C398.023,262.124,397.905,262.035,397.791,261.941z M398.286,262.301
				c0.122,0.077,0.248,0.146,0.379,0.208C398.534,262.447,398.408,262.378,398.286,262.301z M398.771,262.562
				c0.164,0.071,0.338,0.126,0.521,0.168C399.108,262.688,398.935,262.633,398.771,262.562z M399.434,262.752
				c0.146,0.026,0.3,0.04,0.461,0.043C399.733,262.792,399.58,262.778,399.434,262.752z M400.076,262.796
				c0.215-0.008,0.438-0.03,0.681-0.083C400.515,262.766,400.291,262.788,400.076,262.796z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#2D2328" d="M411.093,305.231c-0.078,0-0.156,0-0.235,0
				c0-0.078,0-0.157,0-0.235c0-1.253,0-2.506,0-3.758c0.039-0.273-0.052-0.418-0.234-0.47c0.182-1.435-0.308-2.198-0.235-3.523
				c0-0.392,0-0.783,0-1.174c-0.049-1.752-0.409-3.193-0.939-4.463c0,0.235,0,0.47,0,0.705c-0.686-1.664-0.749-3.949-1.645-5.403
				c0.1-0.491-0.161-0.622-0.234-0.94c-0.65-2.09-1.372-4.109-2.35-5.873c0.113-0.739-0.334-0.918-0.47-1.41
				c-0.631-1.718-1.455-3.243-2.584-4.463c0-0.079,0-0.157,0-0.235c-1.152-1.745-2.033-3.76-3.523-5.168c0-0.078,0-0.156,0-0.235
				c-0.045-0.19-0.235-0.234-0.235-0.47c0-0.078,0-0.157,0-0.235c-0.531-1.113-1.241-2.047-2.114-2.819c0,0.078,0,0.157,0,0.235
				c-0.919-1.351-2.264-2.277-3.054-3.759c-0.685-0.881-1.468-1.664-2.349-2.349c-0.987-0.265-1.394-1.112-2.35-1.409
				c-0.313-0.235-0.709-0.387-0.939-0.705c-0.745,0.429-0.818,1.53-1.174,2.349c-0.752,0.58-1.012,1.651-1.645,2.349
				c0,0.079,0,0.157,0,0.235c-0.589,0.194-0.743,0.823-1.175,1.174c-0.339,0.131-0.573,0.366-0.705,0.705
				c2.65,3.692,4.816,7.869,7.048,11.98c-2.224-4.097-4.384-8.258-7.021-11.94c-0.234,0.302-0.477,0.602-0.73,0.9
				c-1.184,1.391-3.972,2.573-4.464,4.228c-0.575,1.936,0.283,5.637,0.705,7.752c1.438,7.223,4.349,13.486,7.517,18.088
				c3.649,5.3,7.902,9.292,10.807,14.799c0.928,1.76,2.082,4.047,1.644,6.108c-1.817-0.97-2.356-3.149-3.288-4.698
				c-0.983-1.634-2.119-3.186-3.289-4.698c-7.472-9.658-16.531-19.163-15.974-36.646c-0.863,0.076-1.3,0.58-2.114,0.705
				c-2.866,8.902,0.995,19.325,5.402,25.605c1.696,2.416,6.248,4.506,3.289,7.282c3.111,3.388,8.665,4.333,9.632,9.867
				c4.184,3.254,6.522,8.354,8.222,14.094c2.402,0.417,4.629,1.009,7.282,1.175c3.938-6.006,4.005-17.137,3.523-26.78
				C411.093,302.882,411.093,304.057,411.093,305.231z M384.317,265.836c0.271,0.441,0.587,0.899,0.917,1.358
				C384.902,266.735,384.589,266.278,384.317,265.836z M383.63,264.459C383.63,264.459,383.631,264.459,383.63,264.459
				c0.794,1.019,1.55,2.073,2.256,3.179C385.18,266.532,384.423,265.477,383.63,264.459z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#2D2328" d="M381.964,236.873c-0.289,1.12-1.234,1.584-1.409,2.819
				c-0.66,0.75-0.807,2.013-1.175,3.054c-0.263,0.755-0.643,1.393-0.705,2.349c-0.463,0.554-0.553,1.482-0.704,2.349
				c-0.183,0.522-0.494,0.916-0.47,1.644c-0.461,0.636-0.634,1.559-0.705,2.584c-0.233,0.628-0.484,1.239-0.47,2.114
				c-0.604,0.648-0.484,2.021-0.939,2.819c1.373,0.039,2.38-2.315,3.758-3.054c0-0.235,0-0.47,0-0.705c0-0.157,0-0.313,0-0.47
				c0.414-0.683,0.536-1.657,0.705-2.584c0.512-1.681,1.319-3.066,1.645-4.933c0.59-0.742,0.868-1.795,1.175-2.819
				c0.701-1.1,0.605-2.997,1.174-4.228c0.044-2.697,1.453-4.028,1.88-6.343c0.426-0.755,1.336-2.258,0.939-3.054
				c-0.343,0.801-0.732,1.555-1.146,2.287c0,0-0.001,0.001-0.001,0.001c-0.072,0.076-0.146,0.171-0.222,0.279
				c-0.016,0.021-0.031,0.048-0.046,0.071c-0.063,0.093-0.126,0.192-0.19,0.304c-0.016,0.025-0.03,0.055-0.046,0.081
				c-0.067,0.119-0.136,0.243-0.205,0.377c-0.009,0.017-0.019,0.036-0.027,0.054c-0.077,0.15-0.155,0.307-0.234,0.471
				c0,0-0.001,0.001-0.001,0.002c-0.001,0.001-0.001,0.003-0.002,0.004c0.001-0.001,0.001-0.002,0.001-0.002
				C383.642,233.812,382.716,235.255,381.964,236.873z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#2D2328" d="M415.321,296.305c0.031-0.579,0.035-1.132-0.235-1.41
				c-0.013-1.161-0.231-2.117-0.704-2.819c0-0.079,0-0.157,0-0.235c-0.494-1.934-0.994-3.861-1.88-5.403
				c0.113-0.739-0.334-0.918-0.47-1.41c-0.337-0.916-0.561-1.944-1.175-2.584c-0.316-1.562-1.059-2.699-1.644-3.994
				c0-0.078,0-0.157,0-0.235c0.065-0.379-0.223-0.404-0.235-0.705c-0.18-0.682-0.371-1.351-0.939-1.645c0,0.079,0,0.157,0,0.235
				c-1.104-1.715-2.3-3.338-3.523-4.933c-0.789-1.482-2.125-2.417-3.289-3.523c-0.447,0.164,0.317,1.069,0.705,1.174
				c0.409,0.688,0.693,1.499,1.409,1.879c0.09,0.771,0.687,1.036,0.939,1.645c0.662,0.826,1.059,1.917,1.88,2.584
				c0.616,1.575,1.447,2.937,2.113,4.463c-0.061,0.688,0.531,0.722,0.471,1.409c0,0.079,0,0.157,0,0.235
				c-0.037,0.584,0.368,0.728,0.47,1.175c2.261,4.865,3.798,10.453,4.463,16.914c0-0.626,0-1.253,0-1.879c0.078,0,0.156,0,0.235,0
				c0,0.313,0,0.626,0,0.939c0.048,0.735-0.159,1.725,0.234,2.114c0,0.705,0,1.41,0,2.114c0.078,0,0.156,0,0.235,0
				c0,0.078,0,0.157,0,0.235c0.225,0.714-0.503,2.382,0.47,2.349c-0.194-0.664,0.331-0.608,0.234-1.174
				c0.303-0.637,0.575-1.305,0.47-2.349c0.229-0.007,0.183-0.288,0.235-0.47c0-1.018,0-2.036,0-3.054
				C415.285,297.751,415.879,296.452,415.321,296.305z"/>
				</g>
			</g>
			<g id="dark_red">
				<path fillRule="evenodd" clipRule="evenodd" fill="#3B1315" d="M186.284,476.951c2.036,5.268,4.682,9.711,7.282,14.095
			c5.528,9.318,11.092,18.092,14.799,27.954c1.646-0.781,3.257-1.598,4.229-3.054c-1.815,0.088-1.868-0.878-2.349-1.645
			c-2.564-4.084-4.336-9.13-6.813-13.859c-2.288-4.372-4.999-8.665-7.517-13.155c-5.336-9.516-8.364-20.191-8.222-33.357
			c0.102-9.444,1.621-19.055,1.174-28.659c-0.423-9.103-2.891-18.175-2.584-27.25c0.285-8.418,1.67-17.43,4.229-24.196
			c1.103-2.917,3.158-5.767,4.463-8.691c3.529-7.911,7.496-14.658,12.45-20.438c0.917-1.069,2.691-1.905,2.819-3.523
			c-0.052-0.183-0.197-0.273-0.47-0.235c-4.705,5.625-10.495,12.1-14.799,19.732c-4.075,7.224-7.478,15.894-9.162,24.431
			c-1.942,9.842-0.624,21.125-0.705,31.712c-0.04,5.113-0.515,10.406-1.175,15.505c-0.701,5.412-0.604,10.572-0.939,15.974
			c-0.235,3.787-1.217,7.837-0.94,11.745C182.483,466.069,184.355,471.961,186.284,476.951z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#3B1315" d="M363.406,489.401c-4.026-2.945-6.762-7.584-10.336-10.806
			c-20.057,7.351-38.766,16.047-55.674,26.544c-0.574,0.458-0.593-1.063-1.645-0.234c-7.521,4.573-15.087,7.927-21.612,13.155
			c-3.277,2.625-6.819,4.637-9.866,7.517c-8.994,8.503-16.954,17.367-26.78,25.37c-2.877,2.344-5.902,5.433-8.927,8.457
			c-2.812,2.812-6.276,6.915-9.161,8.457c-5.205,2.782-14.923,2.783-22.316,2.349c-7.947-0.467-16.28,0.686-23.726-0.47
			c-0.792-9.067,2.754-17.722,2.819-27.484c1.215-1.486,1.31-3.221,3.054-4.229c2.142-1.238,5.934-1.296,9.162-1.879
			c3.398-0.614,6.502-1.308,9.162-1.88c11.092-7.111,20.966-15.066,30.773-23.726c4.631-4.09,10.576-7.539,16.444-10.806
			c5.794-3.226,11.207-7.471,16.679-10.806c11.168-6.807,23.495-11.793,35.002-19.498c7.87-5.271,15.248-11.959,22.081-18.793
			c2.276-2.275,5.958-4.807,6.813-7.047c0.985-2.584,1.155-5.597,1.879-8.222c0.731-2.649,1.706-5.245,2.584-7.752
			c5.345-15.263,12.053-29,18.793-43.224c4.601-9.708,8.226-19.493,11.746-29.599c2.799-8.035,5.174-15.275,9.631-21.377
			c1.716-2.349,4.42-4.589,5.403-7.517c-0.039-0.292,0.452-0.514,0.47-0.705c0.141-1.526-5.344-2.992-7.048-3.523
			c1.056,3.324,0.882,7.18,2.114,9.866c-11.923,12.657-15.107,31.083-22.081,47.687c-2.249,5.353-5.195,10.434-7.518,15.739
			c-4.979,11.375-8.771,22.566-13.625,32.887c-4.792,10.191-12.446,18.319-20.437,26.311c-7.722,7.722-15.717,15.714-24.901,21.611
			c-4.706,3.021-9.979,5.309-15.034,8.222c-4.919,2.836-9.714,6.006-14.799,8.691c-15.743,8.314-27.574,18.625-41.344,28.425
			c-5.79,4.12-12.823,10.392-20.202,12.215c-11.283,2.789-25.28,1.233-33.592,6.813c-0.167,6.803-1.391,12.547-1.409,19.498
			c2.499-0.998,4.099-5.569,8.457-4.934c3.455,0.504,3.697,6.976,3.524,11.98c-0.15,4.341-0.834,10.548-3.524,12.216
			c-4.191,2.598-7.645-1.531-9.162-3.759c-0.275-0.405-0.369-1.774-1.174-1.409c1.017,14.45-4.086,23.551-5.403,35.236
			c-0.881,7.816-1.125,15.549-0.94,25.135c5.508,7.66,10.771,14.215,17.854,19.968c7.061,5.735,15.29,9.714,22.786,15.034
			c7.371,5.231,14.184,11.102,21.847,15.974c5.862,3.728,11.645,7.453,17.383,11.041c1.85,1.157,4.19,2.461,4.933,4.698
			c-0.325,1.302-1.273,0.87-1.645,2.114c-0.536,1.798,0.072,5.442,0.235,7.987c1.158,18.079,2.814,37.602,3.054,54.733
			c1.801,0,3.602,0,5.403,0c-2.099-22.488-2.804-46.371-3.289-70.473c-10.111-9.001-21.342-16.143-33.592-23.726
			c-8.587-5.316-17.181-11.363-26.31-16.679c-2.719-1.584-8.104-3.409-8.691-5.873c-0.504-2.113,0.847-6.084,1.174-8.927
			c0.369-3.203,0.47-6.645,0.47-9.631c0-20.166-0.17-40.465,3.289-57.084c14.873-1.101,32.283,0.336,45.807-2.113
			c23.811-23.252,46.928-47.192,78.46-62.722c3.535-0.691,5.652-2.435,8.456-3.993c14.874-8.269,30.696-15.137,46.747-21.847
			c2.856,1.762,5.623,3.683,8.223,6.107c2.351,2.193,4.165,6.494,8.456,4.464C367.915,491.265,365.122,490.656,363.406,489.401z
			 M369.983,322.615c0.353,0.353,0.707,0.703,1.175,0.94c0.452,0.331,0.945,0.621,1.645,0.705c0.372,0.724,1.45,0.742,1.879,1.41
			C373.596,325.263,370.059,324.031,369.983,322.615z M238.904,764.48c0.707-0.236,0.416,0.523,0.47,0.94c-0.235,0-0.47,0-0.705,0
			C238.569,764.93,238.83,764.799,238.904,764.48z M223.87,693.538c-0.591-0.427-1.609-0.427-1.645-1.409
			C223.065,692.306,223.717,692.672,223.87,693.538z M160.209,623.535c-1.453,0.545-3.972,0.093-5.638-0.939
			c-5.552-3.442-5.81-18.754,0.94-23.021c2.056-1.301,4.886-1.875,7.987-1.41C168.049,604.057,166.333,621.234,160.209,623.535z
			 M358.942,486.817c0.483,0.299,1.191,0.374,1.175,1.174C359.634,487.692,358.926,487.617,358.942,486.817z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#3B1315" d="M367.869,495.979c2.126,3.082,5.173,7.38,10.102,4.698
			C375.039,498.523,373.016,493.861,367.869,495.979z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#3B1315" d="M380.085,501.852c-2.071,0.122-3.948,0.437-5.403,1.175
			c2.078,1.68,3.833,3.685,5.873,5.402c1.074-0.413,2.368-0.606,3.523-0.939C382.724,505.633,381.139,504.008,380.085,501.852z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#3B1315" d="M381.964,511.248c2.428,1.499,6.586,5.167,9.632,3.054
			c-1.988-1.379-3.616-3.118-5.403-4.698C385.072,510.441,382.745,510.071,381.964,511.248z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#3B1315" d="M397.468,517.825c-1.339-0.817-2.695-1.878-3.993-2.349
			c-0.328,0.455-1.216,0.35-1.645,0.704c2.359,1.947,5.362,3.251,8.222,4.698C400.831,519.063,398.559,518.491,397.468,517.825z
			 M398.173,518.765c0.844,0.331,1.676,0.674,1.879,1.645C399.105,520.251,397.444,519.85,398.173,518.765z"/>
			</g>
			<g id="red">
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M358.942,486.817c-0.017,0.8,0.691,0.875,1.175,1.174
			C360.134,487.191,359.426,487.116,358.942,486.817z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M342.029,516.886c-3.884,0.457-8.328,0.123-10.806,2.818
			c2.821,2.622,7.417,3.729,11.745,4.698c4.449,0.998,9.685,2.231,14.564,1.88c9.952-0.717,19.453-7.519,27.72-9.866
			c-2.961-0.954-5.048-2.783-7.282-4.464C367.04,514.023,353.411,515.546,342.029,516.886z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M530.896,523.463c-20.012,0.347-39.57,1.147-58.727,2.35
			c5.217,1.229,10.201,3.79,15.974,4.229C503.818,531.229,518.695,526.429,530.896,523.463z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M174.068,754.85c0.048,0.735-0.158,1.725,0.235,2.114
			c17.907,2.25,38.144,2.437,56.613,0.705c-1.691-0.134-0.135-1.732,0-2.819c0.795-6.406-1.852-18.305-1.644-25.84
			c-20.067,0.825-43.962,2.969-64.835,3.288c-1.135,0.018-3.12,0.578-3.993-0.704c1.591-2.165,4.774-1.693,7.282-1.88
			c19.726-1.466,42.226-1.765,61.546-3.523c-0.501-8.444-1.635-17.445-1.175-26.075c-17.813-10.376-33.62-22.758-50.505-34.062
			c-3.454,0.128-5.412-1.669-8.222-3.289c-5.364-3.092-11.73-6.986-15.739-10.336c-1.092-0.912-2.615-2.687-3.524-2.349
			c-2.408,15.779-3.282,34.15-3.289,50.74c-0.001,2.965,1.127,7.581,2.114,10.102c0.999,2.549,2.82,4.127,2.819,6.342
			c-0.003,6.358-7.584,9.134-10.806,12.216c-2.099,6.123-4.687,11.757-7.517,17.148c1.798,1.092,2.857,3.5,4.933,4.463
			c1.941,0.9,4.646,0.483,7.517,0.705C154.873,752.489,165.499,755.026,174.068,754.85z M141.416,730.654
			c3.076-0.919,6.21,2.709,9.396,4.228c14.925,7.117,35.633,9.893,57.318,10.807c5.483,0.23,10.917,0.18,16.444,0.47
			c1.226,0.064,3.528-0.221,4.229,1.409c-1.059,2.011-3.91,1.175-5.638,1.175c-24.136,0.001-47.064-2.026-65.07-8.222
			c-5.002-1.722-9.486-3.696-13.625-6.343C143.259,733.403,141.058,732.564,141.416,730.654z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M222.225,692.129c0.035,0.982,1.053,0.982,1.645,1.409
			C223.717,692.672,223.065,692.306,222.225,692.129z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M230.917,760.957c-14.736,0.596-29.995,1.24-44.633-0.47
			c-7.056-0.824-14.068-2.025-20.907-2.349c-7.528-0.356-14.091-0.748-20.907-2.584c-0.619-0.167-1.637-0.518-2.349-0.47
			c-0.534,2.362-0.088,5.705-0.235,8.456c28.034,2.348,58.871,1.893,89.266,1.88C231.027,763.979,231.42,762.02,230.917,760.957z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M238.904,764.48c-0.074,0.318-0.334,0.449-0.235,0.94
			c0.235,0,0.47,0,0.705,0C239.32,765.004,239.61,764.244,238.904,764.48z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M379.38,262.948c1.46-0.654,2.092-2.137,3.054-3.289
			c0.634-0.776,1.03-1.789,1.645-2.584c-0.17-0.719,0.405-0.691,0.235-1.41c0.517-0.533,0.319-2.344-0.471-2.349
			c-0.078,0-0.156,0-0.234,0c-0.84,0.413-1.227,1.279-1.645,2.114c-1.169,0.789-1.882,2.034-2.819,3.054c-0.078,0-0.156,0-0.234,0
			c-0.725,0.528-1.352,1.154-1.879,1.879c-3.437,2.671-6.629,5.586-10.571,7.752C371.45,269.458,376.797,266.233,379.38,262.948z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M372.803,324.259c-0.699-0.084-1.192-0.374-1.645-0.705
			c-0.468-0.237-0.822-0.587-1.175-0.94c0.075,1.417,3.612,2.648,4.698,3.054C374.253,325.001,373.175,324.983,372.803,324.259z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M144.705,601.453c0.319-1.035,1.056-1.922,1.41-3.054
			c0.942-3.017,1.288-6.879,1.879-10.571c0.529-3.301,1.795-6.535,2.349-9.866c0.705-4.232,0.156-9.169,0.47-13.859
			c0.243-3.622,1.401-6.631,1.409-11.041c0.011-5.745-0.92-12.415-1.644-18.793c-0.792-6.98-0.342-13.852-0.94-19.263
			c-0.154-1.396-1.062-2.628-1.409-3.993c-1.101-4.314-0.868-8.922-1.879-13.155c-1.541-6.449-2.427-11.877-3.759-17.853
			c-1.066-0.536-2.943-0.338-2.584-2.819c-2.802-0.627-9.52-1.169-8.926-3.993c0.649-3.092,7.533,0.825,10.571,0.235
			c0.265-3.878-0.803-8.43-0.47-13.155c-0.333-0.427-1.758-0.395-1.41-2.114c-4.057-3.385-7.211-8.915-7.752-15.034
			c2.486,0.665,3.009,3.809,3.994,5.872c1.076,2.257,2.206,4.241,3.993,5.639c-1.922-7.107-5.613-13.294-8.926-19.263
			c-0.991-1.785-3.019-4.135-1.645-6.343c4.691,6.114,7.985,13.626,11.041,21.377c2.656-9.403,5.016-20.355,10.336-26.311
			c-0.409,1.549-1.099,2.816-1.879,3.994c0.862,4.18-2.633,9.389-3.759,13.39c-2.689,9.557-2.394,24.755,0.235,34.062
			c0.507,1.794,1.595,3.203,2.349,5.168c1.386,3.609,1.413,8.448,2.114,12.686c1.356,8.188,3.851,15.782,5.873,23.726
			c1.03,4.049,1.542,8.364,2.819,11.98c0.616,1.745,2.409,3.768,0.47,6.108c8.35-2.901,17.922-2.486,26.545-4.229
			c2.435-0.492,5.281-0.558,7.282-1.175c2.228-0.687,4.818-3.325,6.813-4.698c2.513-1.73,4.542-3.44,6.812-4.698
			c-4.082-10.246-9.368-19.153-15.034-28.424c-5.438-8.897-11.101-17.859-12.215-31.243c-0.378-4.541,0.899-8.951,1.175-13.39
			c0.303-4.874,0.193-9.684,0.939-14.564c0.702-4.589,1.396-9.248,1.645-13.625c0.499-8.777-0.559-16.976-0.235-24.666
			c0.94-22.3,12.068-38.745,22.786-51.68c-7.795,3.174-17.329,7.875-28.189,7.987c-1.557,0.199,0.358-0.636-0.47-0.94
			c-6.971,1.206-12.067,5.054-16.678,9.866c-2.621,2.735-4.636,6.112-7.047,8.457c-0.779,0.757-1.829,1.147-2.349,1.879
			c-1.456,2.045-1.873,5.513-3.054,8.457c-5.095,12.694-14.739,24.399-18.792,37.82c-0.875,2.896-1.64,5.926-2.349,8.927
			c-0.706,2.985-0.88,6.365-1.879,9.396c-0.297,0.902-1.263,1.574-1.645,2.584c-0.527,1.396-0.811,3.518-0.939,4.934
			c-0.833,9.17-0.755,19.89-3.054,28.188c-0.359,1.295-0.286,2.756-0.705,3.759c-1.022,2.445-4.09,3.661-5.638,6.343
			c-0.947,1.642-1.1,3.726-1.879,5.403c-1.232,2.652-3.006,4.732-4.228,7.047c0.116,0.824,0.35,1.529,0.235,2.584
			c-4.809,9.051-7.627,20.092-10.101,31.478c0.609,1.583,1.772,2.612,2.819,3.759c12.942,1.34,22.357,7.096,30.538,13.625
			c2.516,2.008,6.299,4.188,6.578,7.987c-0.414,0.212-0.728,0.525-0.939,0.939c-3.546-0.241-5.416-3.47-7.752-5.403
			c-7.025-5.815-15.469-10.454-26.31-12.92c-0.045,4.431,5.056,5.749,7.282,8.691c3.176,0.343,5.856,2.168,9.161,4.229
			c3.042,1.896,5.938,3.048,6.578,6.578c4.096,4.706,8.019,10.342,11.276,16.208c0.948,1.709,2.883,3.839,1.879,6.108
			c-3.044,0.295-3.413-3.562-4.463-5.403c-2.381-4.173-5.613-8.085-8.692-11.98c-2.9-3.669-5.253-6.936-9.866-8.927
			c5.7,9.316,14.142,20.449,15.269,36.881c0.125,1.819,0.472,5.521-1.174,5.638c-2.058,0.146-1.728-6.903-1.879-8.456
			c-0.37-3.786-0.884-5.988-2.114-8.222c-0.288-1.748-0.995-3.077-1.645-4.464c-0.26-0.914-0.649-1.699-1.174-2.349
			c0.066-0.38-0.222-0.404-0.235-0.705c-0.607-1.586-1.372-3.013-2.349-4.229c0-0.078,0-0.156,0-0.234
			c-0.656-1.146-1.168-2.434-2.114-3.289c-0.401-1.321-1.24-2.205-1.879-3.289c-1.046-1.615-1.93-3.395-3.289-4.698
			c-1.285-2.316-2.86-4.343-4.698-6.107c0.054,1.59,0.505,2.784,0.939,3.993c0.151,0.867,0.241,1.795,0.705,2.35
			c-0.078,0.86,0.261,1.305,0.47,1.879c0.167,1.086,0.474,2.031,0.939,2.819c-0.1,0.491,0.161,0.622,0.235,0.939
			c-0.194,0.664,0.331,0.608,0.235,1.175c0.508,0.666,0.586,1.764,1.175,2.349c-0.1,0.491,0.161,0.622,0.235,0.939
			c0.39,1.567,0.742,3.174,1.41,4.464c0,0.078,0,0.156,0,0.234c0,0.235,0,0.47,0,0.705c0.541,0.633,0.083,2.266,0.235,3.289
			c-0.394,0.39-0.187,1.379-0.235,2.114c-0.542,0.633-0.083,2.266-0.235,3.288c0,0.078,0,0.157,0,0.235
			c-0.541,0.633-0.083,2.266-0.235,3.288c0,0.079,0,0.157,0,0.235c-0.539,0.479-0.085,1.951-0.235,2.819c0,0.078,0,0.156,0,0.234
			c-0.078,0-0.156,0-0.235,0c0,0.783,0,1.566,0,2.35c0,0.078,0,0.156,0,0.234c-0.536,0.326-0.087,1.635-0.235,2.35
			c0,0.078,0,0.156,0,0.234c-0.538,0.402-0.086,1.793-0.235,2.584c-0.663,1.217-0.361,3.398-1.645,3.994
			c-0.726-0.136-0.826-0.897-0.705-1.88c0-0.156,0-0.313,0-0.47c0.536-0.325,0.087-1.635,0.235-2.349
			c0.339-0.758,0.222-1.971,0.235-3.054c0.229-0.007,0.183-0.288,0.235-0.471c0-0.939,0-1.879,0-2.818c0-0.078,0-0.157,0-0.235
			c0.079,0,0.157,0,0.235,0c0-0.47,0-0.939,0-1.409c0-1.175,0-2.35,0-3.523c0.135-0.413-0.284-1.381,0.235-1.41
			c0-0.391,0-0.782,0-1.174c-0.536-0.326-0.088-1.636-0.235-2.35c0.079,0,0.157,0,0.235,0c-0.151-0.945,0.305-2.498-0.235-3.054
			c0-0.783,0-1.565,0-2.349c-0.228-0.007-0.182-0.288-0.235-0.47c0.148-1.715-1.146-1.987-1.174-3.524
			c0.038-0.272-0.052-0.418-0.235-0.47c-0.214-2.213-0.809-4.046-1.41-5.872c-0.333-1.468-0.685-2.917-1.409-3.994
			c-0.326-1.789-1.036-3.192-1.645-4.698c-0.372-1.194-0.707-2.426-1.41-3.288c0-0.079,0-0.157,0-0.235
			c-0.962-3.657-3.913-5.327-5.638-8.222c0.073-0.935-0.542-1.18-0.47-2.114c-0.517-0.579-0.522-1.671-0.94-2.35
			c0-0.156,0-0.313,0-0.47c-0.748-0.348-1.874-0.318-2.114-1.174c-0.079,0-0.157,0-0.235,0c-0.525-1.434-1.033-2.883-1.645-4.229
			c-0.597-1.83-1.085-3.77-1.879-5.403c0-0.078,0-0.156,0-0.234c-0.151-0.867-0.241-1.795-0.705-2.35c0-0.078,0-0.156,0-0.234
			c-0.249-1.239-0.451-2.524-0.94-3.524c-0.488-2.644-1.022-5.242-1.644-7.752c-0.237-3.051-1.083-5.494-1.175-8.691
			c-0.544-1.1-0.081-3.208-0.235-4.698c0-0.078,0-0.156,0-0.235c0.079,0,0.157,0,0.235,0c0-1.174,0-2.349,0-3.523
			c0-0.078,0-0.156,0-0.234c0.543-0.866,0.082-2.737,0.235-3.994c0.512-0.491-0.117-0.67,0-1.409
			c0.275-0.038,0.198-0.428,0.235-0.705c0-2.349,0-4.698,0-7.047c0-0.626,0-1.253,0-1.879c-0.228-0.007-0.182-0.288-0.235-0.47
			c0-0.157,0-0.313,0-0.471c0-0.939,0-1.879,0-2.818c-0.078,0-0.156,0-0.235,0c0-0.705,0-1.409,0-2.114
			c-0.451-2.603-0.994-5.114-1.409-7.752c-0.611-0.485-0.329-1.864-0.94-2.35c0-0.078,0-0.156,0-0.234
			c-1.618-5.664-3.558-11.007-5.873-15.974c-0.272-1.451-0.91-2.535-1.41-3.759c-0.439-1.91-1.454-3.244-1.879-5.168
			c-1.268-0.639-1.845,1.228-2.819,1.645c-2.491,2.834-6.658,3.99-9.866,6.107c-0.869-0.164-1.038,0.371-0.939,1.175
			c-0.533-1.187-1.923-0.157-2.584,0c-14.874,3.524-33.496,6.64-52.385,4.933c0.953,6.292,1.412,13.373,3.054,19.732
			c1.825,7.068,5.877,13.926,8.222,21.377c1.172,3.726,2.131,7.662,3.289,11.511c1.124,3.734,2.599,7.507,3.289,11.275
			c0.792,4.323,0.615,9.034,1.174,13.625c1.062,8.71,2.05,17.415,3.524,26.075c0.7,4.115,1.981,8.21,2.349,12.215
			c1.603,17.48,3.811,32.084,9.866,45.573c5.77,12.854,14.692,22.333,22.316,33.356c5.921,0.617,4.786,7.663,8.457,10.102
			c3.201,2.126,11.321,3.022,16.209,2.114c2.205-0.41,5.616-2.934,8.222-4.464c17.731-10.413,35.729-24.878,46.277-41.344
			C143.569,607.622,143.784,604.447,144.705,601.453z M111.583,506.784c0.302,1.167,2.375,3.866-0.235,4.229
			c-2.706,0.376-3.196-5.894-3.054-8.222c0.217-3.568,1.436-7.629,2.584-10.571c0.588-1.505,1.229-3.091,2.114-3.993
			C113.008,493.441,110.239,501.604,111.583,506.784z M124.972,594.406c-1.523-0.314-0.625-2.166-0.939-3.289
			c-0.232-0.828-0.978-1.495-1.175-2.349c-0.699-3.031-0.211-6.476-1.644-8.927c-2.839,11.021-3.292,24.428-6.108,35.471
			c-0.916,0.729-1.48,1.809-2.114,2.819c-0.589-4.107,0.859-6.749,1.41-10.336c1.176-7.665,2.114-17.145,2.349-24.196
			c0.047-1.127,0.628-1.72,0.47-3.054c0.538-0.401,0.086-1.793,0.235-2.584c0-0.078,0-0.156,0-0.234
			c0.771-2.361,0.14-6.124,2.584-6.813c0.313,0,0.626,0,0.939,0c1.429,0.529,1.665,2.25,2.349,3.523c0,0.078,0,0.157,0,0.235
			c0.249,1.239,0.452,2.523,0.94,3.523c0,0.078,0,0.156,0,0.234c-0.018,0.409,0.052,0.73,0.235,0.94
			c0.278,2.2,1.149,4.927,1.409,7.752C126.142,589.623,126.928,593.4,124.972,594.406z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M382.669,501.146c0.755,2.456,2.354,4.066,4.229,5.403
			c2.08-0.589,5.146-1.353,7.986-1.88c2.517-0.466,6.463-2.019,7.282,0.705c-3.185,2.375-8.979,2.14-12.92,3.759
			c1.045,2.009,3.188,2.92,4.934,4.229c1.86-0.638,3.936-1.3,5.872-2.114c1.659-0.698,3.55-2.666,5.168-1.175
			c-1.545,2.762-5.578,3.034-8.456,4.463c0.537,1.343,2.089,1.67,3.054,2.584c1.468-1.664,3.034-3.229,4.698-4.698
			c-1.313,1.82-3.347,2.918-4.464,4.934c1.21,1.062,2.507,2.035,3.759,3.054c1.798-1.178,3.029-2.921,4.463-4.463
			c0.57-0.526,1.047-1.384,1.68-1.827c0.368-0.161,0.753-0.243,1.158-0.229c0.003,0,0.006-0.001,0.009-0.001
			c0.727,0.237,0.607,1.185,1.382,1.117c0-0.234,0-0.47,0-0.704c0.428-1.374,0.996-2.605,1.175-4.229
			c0.464-0.555,0.554-1.482,0.705-2.349c0-0.079,0-0.157,0-0.235c0.352-0.666,0.579-1.456,0.704-2.35c0-0.078,0-0.156,0-0.234
			c0.51,0.039,0.106-0.833,0.235-1.175c0.435-0.035,0.145-0.795,0.47-0.939c-2.258-1.889-1.288-6.689-0.235-9.161
			C404.959,496.501,393.908,498.918,382.669,501.146z M406.027,518.162c0.523-0.661,1.042-1.366,1.577-2.013
			C407.07,516.797,406.551,517.501,406.027,518.162z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M381.729,764.011c-0.152-1.022,0.306-2.655-0.235-3.288
			c0.053-1.228-0.004-2.346-0.235-3.289c0-1.096,0-2.192,0-3.289c-0.078,0-0.156,0-0.234,0c0.126-2.553-0.339-4.516-1.409-5.872
			c-0.36-0.893-0.946-1.56-1.175-2.584c-0.598-0.343-0.455-1.425-1.175-1.645c0.111-0.737-0.632-0.621-0.705-1.175
			c-0.891-1.145-1.72-2.352-1.409-4.698c0.197-0.272,0.31-0.63,0.235-1.175c0.518-0.891,0.905-1.913,1.409-2.818
			c0.304-0.637,1.044-0.835,0.939-1.88c0.574-0.513,0.06-1.925-0.234-2.349c-0.151-1.571-0.311-3.135-0.705-4.463
			c0.023-0.964,0.046-1.926-0.235-2.584c-0.539-4.707-0.974-9.519-1.879-13.86c-11.491-1.357-23.602-5.471-36.176-2.818
			c-3.703,2.718-7.099,5.743-10.571,8.691c7.575,7.597,24.093,10.354,39.23,10.101c2.144-0.035,4.7-1.004,6.107,0
			c-0.271,0.278-0.267,0.831-0.235,1.41c-0.212,0.414-0.525,0.728-0.939,0.939c-0.945,0.15-2.498-0.306-3.054,0.234
			c-0.235,0-0.47,0-0.705,0c-17.263-0.433-33.107-2.285-42.049-11.04c-5.671,3.446-10.81,11.378-18.558,13.39
			c-7.021,1.822-14.191-6.862-8.691-12.45c0.893-0.907,2.162-1.052,3.288-1.645c4.177-2.195,8.097-6.082,11.746-9.396
			c3.698-3.358,7.233-6.968,10.336-10.571c-1.92-3.883-6.307-0.423-8.457,0.471c-3.052,1.268-6.382,2.332-8.927,3.523
			c-12.746,5.967-25.675,14.33-40.169,18.793c0.473-1.438,2.173-1.541,3.289-2.114c7.643-3.931,16.114-9.058,23.961-13.155
			c-8.048,2.188-20.849,5.121-24.196-2.584c-1.313-3.023,0.103-5.654,0.47-8.927c-3.228-1.128-6.639-1.669-7.987-4.229
			c-0.858-1.63-1.221-5.709-0.47-7.282c1.379-2.888,8.114-4.997,11.511-6.577c4.458-2.074,8.642-3.83,11.98-5.873
			c8.62-5.275,13.885-11.134,20.672-15.034c-7.542-0.186-15.703-1.428-23.726-2.349c-7.742-0.889-15.938-1.868-20.437-5.638
			c-3.558-2.981-5.881-12.366,1.41-13.155c1.702-0.185,3.796,0.449,5.873,0.704c5.67,0.698,12.169,1.188,18.088,1.41
			c8.764,0.328,16.399-2.807,24.431-3.054c4.182-0.129,7.589-2.759,11.276-2.819c4.851-0.08,9.276,2.294,13.624,2.35
			c3.35-1.89,7.749-4.54,7.518-8.692c-0.077-1.384-1.434-2.819-2.35-4.229c-2.419-3.72-4.815-9.067-5.638-13.859
			c-10.348,3.277-17.215,10.035-25.605,15.27c2.038-4.886,6.579-8.073,10.807-10.806c4.331-2.802,9.393-5.256,14.564-7.048
			c0.036-1.687-0.141-3.585,0.234-4.933c-13.554,4.458-33.808,6.75-52.385,5.402c3.246-2.618,8.304-2.337,12.92-2.584
			c14.243-0.763,28.784-2.252,40.17-5.638c1.062-3.545,1.882-8.301,4.229-10.806c0.56-0.598,3.568-2.853,4.698-2.819
			c1.914,0.056,4.174,4.149,4.933,6.107c1.79,4.616,1.585,10.2,4.229,13.391c2.432-0.395,7.247,0.469,7.752,3.523
			c-1.849,1.611-4.31-0.787-6.343-1.175c2.423,6.426,6.141,13.592,10.807,18.558c0.638,0.169-0.169-1.107,0.47-0.939
			c-0.147,1.009,0.209,1.514,0.704,1.88c1.348,0.842,2.43,2.948,3.759,3.054c0.742,1.607,1.941,2.757,3.054,3.993
			c1.128,0.517,1.203,2.086,2.819,2.114c0.348,0.272,0.592,0.272,0.939,0c0.918-0.179,0.907-1.286,1.879-1.409
			c0.079,0,0.157,0,0.235,0c0.421,0.029,0.495-0.288,0.939-0.235c0-0.939,0-1.879,0-2.818c0-0.157,0-0.313,0-0.47
			c0.53-0.176,0.092-1.318,0.235-1.88c0.136-1.116,0.339-2.166,0.234-3.523c0-0.078,0-0.157,0-0.235
			c0.536-0.325,0.088-1.635,0.235-2.349c0-0.078,0-0.157,0-0.235c0.535-0.325,0.087-1.635,0.234-2.349
			c-0.029-0.422,0.288-0.496,0.235-0.939c0-0.47,0-0.94,0-1.41c0.533-0.25,0.089-1.477,0.234-2.114c0.139-1-0.169-1.554-0.234-2.349
			c-0.043-0.2,0.092-0.222,0.234-0.235c0.534-3.537,1.836-6.308,2.114-10.101c0.843-2.29,1.264-5.001,1.88-7.518
			c0.197-0.272,0.31-0.63,0.234-1.174c1.104-2.813,1.977-5.854,2.819-8.927c0.342-0.989,0.797-1.866,0.939-3.054
			c0.85-1.5,1.305-3.394,1.879-5.168c0-0.079,0-0.157,0-0.235c0.825-1.211,1.113-2.959,1.645-4.463
			c0.183-0.21,0.253-0.531,0.235-0.94c0.824-1.211,1.112-2.959,1.645-4.463c0.183-0.522,0.493-0.916,0.47-1.645
			c0.69-0.327,0.326-1.71,0.939-2.114c-0.419-0.629-0.636,0.912-1.175,0.939c-1.148,2.376-2.449,4.598-4.933,5.639
			c-2.043-2.713,1.58-4.898,2.349-7.048c0-0.078,0-0.156,0-0.234c1.241-1.343,2.112-3.057,3.054-4.698c0-0.079,0-0.157,0-0.235
			c1.116-1.233,1.761-2.938,2.584-4.463c0.732-1.304,1.251-2.821,2.114-3.994c0.06-0.802,0.594-1.128,0.47-2.114
			c0-0.078,0-0.156,0-0.234c0.473-0.702,0.491-1.858,0.705-2.819c0.801-1.234,2.379-1.692,2.349-3.759
			c0.557-0.618,0.773-1.575,0.94-2.584c0.218-1.235-0.794-1.241-0.94-2.113c-0.199,0.043-0.221-0.092-0.234-0.235
			c-0.674-1.676-1.674-3.024-3.054-3.993c-1.655,0.302-2.267,1.648-3.289,2.584c-0.083,0.309-0.413,0.369-0.47,0.704
			c0.043,0.2-0.092,0.222-0.235,0.235c0.123,0.348,0.348,0.592,0.471,0.939c0.848,1.684,0.398,4.324-0.235,5.873
			c-0.42,1.537-1.253,2.662-1.409,4.463c-0.528,0.803-0.752,1.911-0.94,3.054c0,0.079,0,0.157,0,0.235
			c-0.397,0.619-0.695,1.34-0.704,2.349c-0.384,1.262-0.934,2.355-1.175,3.759c-1.636,4.002-3.901,7.374-6.577,10.336
			c-0.968,1.146-1.457,2.771-2.35,3.994c-0.039,0.665-0.431,0.979-0.47,1.644c0,0.079,0,0.157,0,0.235
			c-0.571,0.524-0.659,1.533-0.939,2.349c-0.183,0.21-0.253,0.531-0.235,0.94c-0.541,1.024-0.93,2.202-1.174,3.523
			c-0.183,0.521-0.494,0.916-0.47,1.645c-0.541,0.712-0.652,1.854-0.94,2.818c-0.209,0.574-0.547,1.019-0.47,1.88
			c-0.451,0.958-0.748,2.07-0.939,3.288c-0.254,0.686-0.525,1.354-0.47,2.35c-0.54,1.025-0.521,2.611-0.939,3.759
			c0,0.234,0,0.47,0,0.704c-0.324,0.303-0.232,1.021-0.235,1.645c-0.375,0.643-0.483,1.553-0.47,2.584
			c-0.462,1.652-0.628,3.601-0.704,5.638c-0.079,0-0.157,0-0.235,0c-0.108,1.146,0.246,2.752-0.235,3.523c0,1.019,0,2.036,0,3.055
			c0,0.156,0,0.313,0,0.47c-0.078,0-0.156,0-0.234,0c0,0.783,0,1.565,0,2.349c0,0.078,0,0.157,0,0.235
			c-0.401,2.339-0.519,4.962-0.705,7.517c-0.446,0.572-0.07,1.966-0.47,2.584c0.106,1.28-0.419,1.931-0.234,3.289
			c-0.53,0.331-0.193,1.53-1.175,1.409c-0.916,0.824-1.735-0.446-2.114-0.939c0-0.47,0-0.939,0-1.409
			c0.367-1.042,0.46-2.359,0.47-3.759c0.436-0.504,0.158-1.721,0.235-2.584c0.537-0.402,0.086-1.793,0.234-2.584
			c0-0.156,0-0.313,0-0.47c0.539-0.479,0.085-1.951,0.235-2.819c0-0.156,0-0.313,0-0.47c0.529-0.175,0.092-1.317,0.234-1.879
			c0-0.627,0-1.253,0-1.88c0-0.234,0-0.47,0-0.704c0.541-0.556,0.084-2.108,0.235-3.054c0-0.235,0-0.471,0-0.705
			c0.078,0,0.156,0,0.234,0c0-0.235,0-0.47,0-0.705c0-0.548,0-1.096,0-1.645c0.197-0.272,0.311-0.629,0.235-1.174
			c0.109-2.631,0.83-4.651,0.939-7.282c0.444-1.672,1.16-4.318,0.939-5.873c-0.1-0.491,0.161-0.622,0.235-0.939
			c1.088-3.141,1.788-6.668,2.818-9.866c0-0.156,0-0.313,0-0.47c-0.685-0.207-1.468,1.502-2.349,1.879
			c0.643-1.001,1.706-1.582,2.349-2.584c0.339,0.054,0.082,0.701,0.47,0.705c0.099-1.39,0.842-2.134,0.94-3.523
			c0.183-0.522,0.493-0.916,0.47-1.645c0.866-1.482,1.232-3.466,2.114-4.934c0.602-1.042,0.946-2.342,1.879-3.054
			c0.836-1.748,2.279-2.889,3.289-4.463c0-0.079,0-0.157,0-0.235c1.595-1.615,2.494-3.927,3.288-6.343
			c0.8-1.706,1.232-3.779,1.645-5.872c0.884-2.249,1.58-4.685,2.35-7.048c0.263-0.755,0.643-1.393,0.704-2.349
			c0.606-0.323,0.053-1.439,0-1.88c-0.229-0.006-0.182-0.287-0.234-0.47c-0.968,0.128-1.375,0.818-2.114,1.175
			c-0.589,0.508-1.451,0.741-1.879,1.409c0.043,0.2-0.092,0.222-0.235,0.235c-7.221,3.598-15.582,10.555-26.31,9.866
			c2.872-3.013,9.61-3.37,12.215-5.873c0.491,0.627,1.122-0.585,1.879-0.47c0.7-0.632,1.753-0.91,2.585-1.409
			c4.393-2.576,8.813-5.124,12.92-7.987c1.029-0.85,1.97-1.789,2.818-2.818c0.403-0.537,0.692-1.188,1.175-1.645
			c-0.171-0.534-0.707-0.703-1.175-0.939c-0.541-0.242-0.757-0.809-1.645-0.705c-0.104-0.342-0.206,0.023-0.47,0
			c-1.232,1.429-2.563,2.76-3.993,3.993c1.233-1.429,2.564-2.76,3.993-3.993c-1.078-0.455-5.348-3.645-7.282-3.759
			c-1.917-0.113-5.733,1.852-7.986,2.819c-6.752,2.899-15.038,8.12-23.257,8.456c-8.342,0.342-17.049-2.044-23.49-4.698
			c-1.69-0.696-4.956-1.667-5.403-3.758c-0.976-4.558,4.813-5.755,8.691-6.343c13.144-1.993,27.28-2.828,39.7-5.168
			c-0.634-2.03-3.74-3.62-5.403-5.403c-1.838,0.377-4.077,0.844-6.342,1.175c-2.068,0.303-4.779,1.146-6.343-0.235
			c2.949-1.435,7.498-1.271,11.041-2.114c-2.538-3.1-5.403-5.872-8.223-8.691c-2.765-2.765-5.341-5.827-8.927-7.752
			c-20.291,7.819-39.313,16.909-56.613,27.72c-8.82,2.803-16.331,8.072-23.491,13.39c-3.325,2.469-6.067,4.831-9.631,7.282
			c-3.815,2.623-7.409,6.114-11.041,9.161c-10.77,9.036-21.129,19.558-30.773,29.834c-13.641,2.172-30.228,2.131-45.808,1.645
			c-3.066,16.479-2.314,34.995-3.054,54.733c-0.096,2.577-0.152,5.968-0.47,9.162c-0.267,2.677-1.401,6.624-0.939,8.457
			c0.545,2.168,5.992,3.787,8.691,5.402c8.856,5.299,17.361,11.276,25.84,16.444c8.31,5.064,16.646,11.541,25.135,17.148
			c3.852,2.543,7.161,4.829,9.162,8.456c0.17,23.478,0.934,48.397,3.289,70.474c15.035,0.548,31.086,0.078,46.512,0.234
			c-2.243-1.28-7.234,0.188-8.457-2.114c0.408-2.093,2.97-1.402,4.228-1.409c5.097-0.026,10.78,0.723,16.209,0.47
			c13.999-0.651,26.846-3.101,39.23-5.402c6.39-1.188,13.176-3.009,19.262-2.819c3.254,0.101,7.444,1.002,6.813,4.698
			c-2.506-0.141-3.426-1.998-6.813-2.114c-4.159-0.144-8.85,1.298-13.39,2.114c-12.242,2.201-26.095,5.819-39.465,6.343
			c22.507,0.424,47.797,0.346,69.533,0c0.157,0,0.313,0,0.47,0c1.049,0.415,3.641,0.356,4.698,0c0.057,0.605,0.404-0.323,0.94,0
			C382.247,764.745,382.14,764.228,381.729,764.011z M242.897,582.896c5.991-0.499,12.658-0.63,19.028-1.175
			c15.983-1.368,32.064-3.924,46.982-7.047c7.965-1.668,16.069-3.544,23.726-3.994c7.12-0.418,12.279,1.988,18.323,3.289
			c2.416,0.521,5.601,1.205,8.691,2.114c2.714,0.798,6.9,1.538,7.282,3.523c0.671,3.493-5.316,8.324-7.987,7.518
			c-0.578-3.103,3.862-3.172,4.229-6.108c-3.796-1.601-8.009-2.521-12.215-3.523c-4.174-0.995-8.295-2.593-12.216-3.288
			c-8.199-1.455-18.521,0.866-26.779,2.584c-21.104,4.388-43.41,7.225-68.359,8.222c-1.952,0.078-4.337,0.527-6.108-0.705
			C238.64,582.62,241.224,583.035,242.897,582.896z M220.346,606.151c-12.126-2.749-22.288-7.883-32.887-12.45
			c-1.828-0.788-3.864-1.72-6.343-2.818c-2.172-0.964-6.64-1.762-5.403-4.229c-0.043-0.2,0.091-0.222,0.235-0.235
			c4.615,0.401,8.548,2.946,12.685,4.698c10.266,4.347,20.45,9.158,32.652,11.98c1.172,0.271,6.436,0.647,6.108,2.819
			C227.059,608.133,221.687,606.455,220.346,606.151z M242.192,593.701c-3.192-0.273-6.628-0.542-9.866-0.939
			c-7.205-0.884-14.25-2.563-21.847-2.584c-9.411-0.025-18.873-2.164-28.424-3.993c-3.308-0.634-8.97-0.946-7.047-3.994
			c1.051-1.406,3.884,0.002,6.107,0.235c1.231,0.129,2.451-0.19,3.524,0c0.793,0.141,1.579,0.737,2.349,0.939
			c4.867,1.277,10.461,1.698,15.974,2.584c13.183,2.117,26.436,4.485,40.875,5.638c2.417,0.193,5.599-0.189,7.517,1.645
			C249.587,595.168,245.384,593.975,242.192,593.701z M367.399,740.755c-25.563,12.931-64.956,15.658-104.535,13.86
			c-1.953-0.089-5.245,0.461-6.107-1.88c1.706-1.746,4.354-1.175,6.578-1.175c7.878,0,15.521,0.235,23.021,0.235
			c31.224,0,58.408-4.329,79.165-14.095c0.129-1.695,0.029-3.162-0.939-3.759c1.423,0.222,0.97,2.318,1.409,3.523
			c0.078,0,0.156,0,0.234,0c1.171-0.396,2.24-0.892,3.289-1.409c0.719-0.299,1.703-0.333,2.35-0.704
			c0.879,0.06,1.51,0.368,1.879,0.939c0.436,1.924-1.126,1.849-1.879,2.584c-0.723,0.061-1.057,0.51-1.645,0.704
			c-0.873,0.381-1.999,0.508-2.35,1.41C367.634,740.99,367.59,740.8,367.399,740.755z M375.621,756.964
			c0.547,2.037,0.08,5.089,0.235,7.517C375.31,762.443,375.777,759.393,375.621,756.964z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M569.422,543.665c0.281-0.658,0.259-1.62,0.235-2.584
			c0-0.078,0-0.156,0-0.234c-0.152-1.101,0.308-2.813-0.235-3.523c0.184-1.515-0.349-2.313-0.234-3.759
			c-0.2,0.043-0.222-0.092-0.235-0.235c0.033-0.972-0.064-1.814-0.47-2.349c0.005-1.336-0.397-2.265-0.704-3.289
			c-0.377-2.051-1.041-3.814-1.88-5.402c0-0.079,0-0.157,0-0.235c0.141-1.159-0.61-1.426-0.47-2.584c-0.078,0-0.156,0-0.234,0
			c0-0.078,0-0.156,0-0.235c0.004-1.414-0.146-2.672-0.705-3.523c0-0.078,0-0.156,0-0.234c-0.015-1.16-0.689-1.66-0.705-2.819
			c-0.59-0.741-0.867-1.794-1.175-2.819c-0.785-1.015-1.225-2.377-1.879-3.523c-0.341-0.756-0.876-1.316-1.175-2.114
			c-0.808-1.149-1.548-2.367-2.349-3.523c-5.337-7.035-9.911-14.832-15.27-21.847c0-0.078,0-0.156,0-0.234
			c-3.05-3.841-5.613-8.168-8.456-12.216c0.01-0.715-0.496-0.913-0.705-1.409c-0.814-1.222-1.324-2.748-2.35-3.759
			c0-0.078,0-0.156,0-0.235c-0.512-0.74-0.639-1.866-1.409-2.349c-0.211-0.964-0.719-1.631-1.175-2.349
			c-0.665-1.214-1.148-2.61-2.114-3.524c0-0.078,0-0.156,0-0.234c-0.816-1.688-1.941-3.069-2.818-4.698c-0.078,0-0.157,0-0.235,0
			c0-0.078,0-0.157,0-0.235c-0.495-0.992-1.149-1.826-1.644-2.818c-0.586-0.511-0.686-1.508-1.41-1.88c0-0.078,0-0.156,0-0.234
			c-4.456-6.82-9.512-13.04-14.799-19.028c-2.976-3.289-6.261-6.268-9.396-9.396c-4.012-2.749-7.174-7.139-11.428-9.459
			c0.014,0.011,0.027,0.023,0.041,0.034c-0.014-0.011-0.027-0.023-0.042-0.035l0.001,0c-0.266-0.211-0.521-0.432-0.788-0.642
			c-0.095-0.047-0.18-0.082-0.258-0.106c-0.052-0.016-0.096-0.022-0.142-0.03c-0.024-0.004-0.052-0.014-0.075-0.016
			c-0.05-0.005-0.096-0.003-0.143-0.001c-0.016,0.001-0.033-0.001-0.05,0c-0.052,0.005-0.103,0.014-0.154,0.024
			c-0.009,0.001-0.017,0.002-0.025,0.004c-0.337-0.129-0.681-0.245-1.032-0.344c-2.067-1.377-2.724-4.167-4.229-6.107
			c-0.705-1.8-1.869-3.143-2.584-4.933c-0.411-0.998-0.575-2.244-1.409-2.819c0-0.079,0-0.157,0-0.235
			c-0.726-1.292-1.651-2.383-2.606-3.445c-0.466-0.547-0.945-1.08-1.437-1.602c0.001,0.001,0.001,0.001,0.002,0.002
			c-0.305-0.347-0.605-0.698-0.893-1.063c-1.172-1.334-2.425-2.587-3.759-3.758c-3.683-3.051-7.16-6.308-10.806-9.396
			c-0.288-0.224-0.549-0.475-0.816-0.72c1.234,1.334,2.544,2.593,3.892,3.813c-1.349-1.22-2.658-2.479-3.893-3.814
			c0.001,0,0.001,0,0.001,0c-0.865-0.935-1.691-1.907-2.47-2.929c-0.006-0.007-0.012-0.014-0.018-0.022
			c-0.405-0.714-0.779-1.461-1.395-1.966c0-0.079,0-0.157,0-0.235c-0.887-1.383-1.484-3.057-2.349-4.463c0-0.079,0-0.157,0-0.235
			c-0.353-0.978-0.667-1.996-1.41-2.584c0-0.078,0-0.156,0-0.235c-2.709-7.861-7.686-13.456-13.859-17.853
			c-1.01-0.637-2.274-1.019-3.479-1.46c0.002,0,0.003,0.001,0.004,0.002c-0.002-0.001-0.004-0.002-0.007-0.003
			c0.001,0,0.002,0,0.003,0.001c-0.003-0.001-0.006-0.002-0.009-0.004c-0.641-0.314-1.299-0.61-1.972-0.893
			c-0.23-0.14-0.447-0.293-0.648-0.461c-0.156,0-0.313,0-0.47,0c-0.748-0.113-0.983-0.74-1.88-0.705
			c-0.754-0.656-1.847-0.972-3.054-1.175c-1.884-0.778-4.164-1.161-6.577-1.409c-1.841-0.587-4.064-0.79-6.107-1.175
			c-0.078,0-0.157,0-0.235,0c-0.325-0.536-1.635-0.087-2.349-0.235c-0.463-0.321-1.342-0.224-1.88-0.47
			c-3.579-0.492-7.171-0.973-10.806-1.41c-2.024-0.011-3.613-0.458-5.638-0.47c-3.261-0.733-5.612-2.375-8.457-3.523
			c-0.197,0.272-0.31,0.629-0.234,1.174c0,0.078,0,0.157,0,0.235c-0.229,0.006-0.183,0.288-0.235,0.47
			c-1.466,2.854-5.423,6.112-7.282,9.162c-4.699,7.71-7.392,17.152-10.336,26.31c-2.801,8.711-6.811,17.409-10.806,25.84
			c-5.73,12.092-11.836,24.723-16.679,38.291c-2.108,5.906-4.589,10.744-4.464,17.148c-13.602,14.384-28.167,26.834-46.747,36.176
			c-5.959,2.996-12.162,5.945-18.088,9.396c-5.325,3.101-10.38,7.895-16.443,11.275c-5.893,3.285-12.382,6.526-16.914,10.571
			c-4.826,4.307-9.418,8.988-14.799,12.92c-2.608,1.905-5.464,3.707-8.222,5.638c-3.533,2.474-6.331,5.232-10.571,6.577
			c-2.051,0.651-5.069,1.188-7.752,1.645c-3.51,0.598-7.162,0.33-8.692,2.114c-0.45,0.525-1.229,2.305-1.409,3.054
			c-1.698,7.075-1.67,18.21-3.289,24.196c10.036-0.345,21.995,0.962,32.653,0.234c3.648-0.248,7.512-0.118,10.101-1.174
			c2.119-0.865,4.981-4.925,7.282-7.048c2.695-2.487,5.058-4.98,7.282-7.047c5.402-5.02,10.599-8.955,15.034-13.39
			c6.833-6.833,14.269-14.489,22.316-20.673c2.683-2.061,5.397-4.591,7.987-6.107c12.476-7.305,24.955-13.401,37.351-19.968
			c6.257-3.313,12.817-6.115,19.498-9.161c6.462-2.946,13.351-6.386,21.142-7.517c0.899,0.721,1.162,1.789,1.645,2.584
			c2.354,3.875,6.011,7.691,10.336,10.336c1.674,1.022,3.643,2.849,5.168,3.054c2.395,0.321,6.853-1.329,9.632-2.114
			c6.19-1.749,11.879-3.45,18.088-5.403c2.97-0.935,6.253-2.664,9.161-1.175c-0.638,3.264-4.637,3.427-7.282,4.229
			c-8.437,2.558-18.226,5.147-27.015,7.282c1.267,1.366,3.584,4.613,6.107,4.934c1.706,0.216,4.963-1.034,7.518-1.645
			c6.743-1.611,15.377-3.881,20.907-6.107c0.364-2.377,1.99-3.491,3.054-5.168c-1.694-3.396-3.051-7.129-4.464-10.806
			c-6.171-0.656-12.229-2.322-17.618-4.229c-5.329-1.887-11.307-3.036-14.564-7.282c10.406,0.949,18.062,6.681,29.129,7.752
			c-1.362-3.548-3.664-5.883-6.107-8.457c-2.393-2.521-5.622-4.436-7.752-7.047c-6.487-7.955-6.592-26.595-4.933-39.936
			c0.36-2.9,0.871-5.988,1.644-9.866c0.57-2.861,1.536-7.759,3.759-7.517c2.898,0.315,1.688,5.996,1.175,9.161
			c-0.756,4.666-1.343,7.456-1.645,10.336c-0.932,8.893-3.081,17.572-1.409,26.075c1.008-19.138,6.673-34.787,12.92-49.331
			c4.605-10.721,10.305-20.773,16.443-30.068c1.937-2.932,3.542-6.788,7.048-7.987c-3.184,7.695-8.303,13.619-12.216,20.437
			c-3.842,6.694-7.426,13.961-10.57,21.847c-4.574,11.468-9.519,23.301-11.041,37.82c-0.514,4.895-0.729,11.086,0.47,15.27
			c0.69,2.411,3.857,5.172,6.107,7.282c4.658,4.368,9.252,8.688,13.155,13.625c1.971,2.493,3.585,5.319,5.638,7.517
			c3.64,3.897,8.683,6.804,12.686,10.806c3.879,3.879,7.159,8.442,11.04,12.45c3.813,3.937,7.975,7.448,13.155,10.336
			c5.117,2.854,10.709,6.378,15.739,7.753c4.969,1.357,13.885-0.142,19.732-0.705c13.378-1.29,24.304-0.707,38.29-1.645
			c2.809-0.188,5.79-0.459,9.396-0.705c2.543-0.173,7.653-0.666,7.987,1.41c0.35,2.178-3.505,3.171-5.168,3.523
			c-2.279,0.482-5.475,0.866-7.518,1.175c-14.468,2.182-27.862,7.733-45.103,6.577c-6.888-0.462-12.622-3.386-19.028-4.463
			c0.173,3.43,2.551,6.688,3.994,9.631c6.127,2.888,12.675,5.991,19.967,7.987c7.113,1.946,14.437,4.543,22.552,5.168
			c3.76,0.289,7.902-0.339,11.511,0.47c-0.495,2.347-3.859,2.182-6.107,2.349c-8.105,0.603-16.749-1.387-23.021-3.288
			c-4.826-1.463-9.594-2.337-13.86-3.994c-4.403-1.71-8.155-4.183-12.685-4.933c-1.185-1.399-2.995-2.173-4.464-3.289
			c-3.498,1.67-4.191,6.145-8.456,7.048c-6.141,9.326-9.999,19.418-16.914,27.954c-6.624,8.178-14.936,14.936-22.316,22.316
			c-3.706,3.706-6.923,8.613-11.275,12.686c-1.848,1.728-3.725,3.81-6.107,5.872c-1.885,1.631-5.335,4.228-6.108,5.873
			c-1.661,3.539-0.756,12.214-0.234,16.679c1.306,11.178,3.9,19.086,8.457,27.015c2.493,4.339,4.97,10.104,9.631,11.511
			c3.93,7.032,13.217,8.708,23.021,9.866c6.629-6.761,12.271-14.509,19.263-20.907c4.841-2.436,8.752-6.065,13.625-8.691
			c3.481-1.877,7.076-3.629,10.336-5.873c6.271-4.317,11.944-9.476,18.088-14.095c6.236-4.688,12.779-8.874,19.263-13.155
			c6.245-4.124,12.493-8.765,17.383-14.329c-0.01,0.601,0.175,0.286,0.47,0.235c0.998-1.195,2.095-2.291,3.289-3.289
			c2.525-3.034,5.18-5.939,7.282-9.396c0-0.078,0-0.156,0-0.235c1.45-1.76,2.4-4.021,3.523-6.107c0.101-0.447,0.507-0.59,0.47-1.175
			c1.512-1.698,2.151-4.27,3.055-6.577c0-0.078,0-0.156,0-0.235c0.209-0.104,0.42-0.206,0.47-0.47
			c0.499-0.831,0.777-1.884,1.409-2.584c-0.03-0.421,0.287-0.495,0.234-0.939c0-0.078,0-0.156,0-0.234
			c0.525-0.65,0.915-1.436,1.175-2.35c1.347-2.882,2.669-5.788,3.759-8.927c0.948-1.479,1.613-3.241,2.349-4.933
			c0-0.078,0-0.157,0-0.235c0.484-0.064,0.222-0.875,0.705-0.939c0.652-1.697,1.435-3.263,2.35-4.698
			c0.389-0.785,0.785-1.563,1.174-2.349c0-0.079,0-0.157,0-0.235c1.172-1.334,1.965-3.047,2.819-4.698
			c0.271-0.277,0.266-0.83,0.234-1.409C569.811,545.761,569.812,543.919,569.422,543.665z M405.689,429.969
			c-2.713,4.347-5.311,8.621-7.282,13.859c-0.543,1.444-0.464,3.849-2.349,4.698c-1.918-3.151,0.317-6.978,1.645-9.866
			c2.729-5.938,6.043-11.16,9.866-16.443c2.796-3.864,4.877-7.463,8.927-9.631C414.105,419.228,409.272,424.23,405.689,429.969z
			 M477.572,551.652c-3.402-2.104-7.327-4.794-9.396-7.752c-0.426-0.609-0.813-1.216-0.704-2.114
			c2.839-0.867,3.598,1.852,5.168,3.289c2.414,2.21,5.244,4.1,8.222,5.638c2.915,1.506,6.746,2.099,8.927,4.933
			C485.518,556.547,481.089,553.826,477.572,551.652z M559.279,514.505c-0.002-0.011-0.003-0.021-0.005-0.03
			C559.276,514.484,559.278,514.494,559.279,514.505C559.279,514.504,559.279,514.504,559.279,514.505z M558.972,514.995
			c0,0-0.001,0.001-0.002,0.002c-0.582,0.339-1.138,0.703-1.667,1.096C557.828,515.695,558.39,515.334,558.972,514.995z
			 M554.323,524.669c-0.002,0.003-0.004,0.005-0.006,0.008C554.318,524.675,554.32,524.672,554.323,524.669
			C554.322,524.67,554.322,524.67,554.323,524.669z M553.815,525.26c-0.054,0.048-0.112,0.092-0.175,0.133
			C553.703,525.352,553.762,525.308,553.815,525.26z M553.546,525.452c-0.1,0.054-0.206,0.099-0.332,0.125
			C553.34,525.551,553.446,525.505,553.546,525.452z M554.043,525.021c-0.044,0.054-0.09,0.105-0.138,0.155
			C553.954,525.126,553.999,525.074,554.043,525.021z M559.376,514.194c0.043-0.271-0.053-0.519-0.313-0.726
			c-0.021-0.018-0.037-0.035-0.061-0.052c-0.001-0.001-0.002-0.002-0.003-0.003c0.025,0.017,0.041,0.037,0.063,0.055
			C559.324,513.676,559.421,513.922,559.376,514.194z M515.393,449.937c-0.28-0.401-0.535-0.827-0.805-1.237
			c-0.008-0.013-0.016-0.026-0.023-0.039C514.832,449.095,515.105,449.521,515.393,449.937z M512.424,444.783
			c-1.348-2.626-2.634-5.314-4.26-7.662C509.79,439.469,511.078,442.154,512.424,444.783z M514.582,448.691
			c-2.38-3.617-4.426-7.568-6.42-11.572l0,0C510.156,441.123,512.202,445.074,514.582,448.691z M508.034,436.933
			c-0.655-0.927-1.37-1.793-2.162-2.583C506.665,435.139,507.379,436.006,508.034,436.933z M508.036,436.936
			C508.035,436.936,508.035,436.935,508.036,436.936c-0.021-0.023-0.042-0.044-0.062-0.066
			C507.994,436.892,508.017,436.912,508.036,436.936z M508.06,436.97L508.06,436.97c-1.252-1.064-2.411-2.222-3.473-3.478
			C505.648,434.748,506.807,435.904,508.06,436.97z M427.435,351.463c-2.795,2.082-5.341,4.688-7.898,7.168
			C422.093,356.149,424.64,353.545,427.435,351.463z M409.276,366.698c0.027,0.032,0.055,0.06,0.083,0.089
			C409.332,366.758,409.305,366.729,409.276,366.698z M410.238,367.275c0.025,0.002,0.051,0.005,0.076,0.006
			C410.289,367.28,410.264,367.277,410.238,367.275z M409.599,367.006c0.02,0.015,0.039,0.033,0.059,0.047
			C409.638,367.039,409.618,367.021,409.599,367.006z M409.943,367.208c0.023,0.009,0.046,0.014,0.068,0.021
			C409.989,367.222,409.967,367.217,409.943,367.208z M411.478,366.705c0.028-0.027,0.058-0.056,0.086-0.084
			C411.535,366.648,411.506,366.678,411.478,366.705z M410.59,367.25c0.013-0.003,0.025-0.009,0.037-0.013
			C410.614,367.241,410.603,367.247,410.59,367.25z M410.867,367.144c0.027-0.014,0.055-0.028,0.082-0.043
			C410.922,367.116,410.895,367.13,410.867,367.144z M411.167,366.961c0.019-0.013,0.036-0.024,0.055-0.038
			C411.203,366.938,411.186,366.948,411.167,366.961z M412.731,365.176c-0.074,0.103-0.149,0.205-0.226,0.308
			C412.584,365.385,412.656,365.278,412.731,365.176z M412.438,365.576c-0.043,0.058-0.086,0.115-0.13,0.172
			C412.352,365.691,412.395,365.634,412.438,365.576z M412.168,365.929c-0.034,0.044-0.069,0.089-0.104,0.133
			C412.099,366.019,412.134,365.974,412.168,365.929z M411.832,366.336c-0.029,0.034-0.059,0.065-0.088,0.097
			C411.773,366.401,411.803,366.37,411.832,366.336z M413.34,364.359c0.02-0.025,0.039-0.052,0.06-0.076
			C413.38,364.308,413.359,364.334,413.34,364.359z M426.338,352.603c0.021-0.017,0.037-0.036,0.058-0.053
			C426.375,352.567,426.358,352.586,426.338,352.603z M525.729,548.363c-3.985-0.181-7.075-0.774-9.161-0.939
			c-3.271-0.258-6.053-0.165-9.396-0.705c-2.391-0.386-6.853-1.354-7.047-3.054c-0.324-2.831,6.864-4.406,10.336-5.168
			c4.346-0.952,8.792-1.766,12.92-2.584c9.809-1.944,16.499-4.594,23.961-7.047c1.13-0.372,2.022-1.196,3.289,0.47
			c-0.276,2.284-2.975,2.475-4.698,3.054c-5.339,1.792-10.977,4.19-17.384,5.403c-8.4,1.589-17.332,2.876-24.9,5.402
			c6.936,1.951,17.405,2.201,25.84,2.584c2.146,0.098,5.995-0.413,5.403,1.88C534.475,549.271,528.588,548.493,525.729,548.363z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M408.744,525.107c0.375-0.329,0.6-0.81,0.704-1.409
			c0-0.078,0-0.157,0-0.235c0.806-1.309,1.23-2.998,1.645-4.698c0-0.078,0-0.156,0-0.234c0.517-0.579,0.521-1.671,0.939-2.35
			c-1.771,1.285-3.58,4.563-5.872,6.108c0,0.078,0,0.156,0,0.234c0.881,0.685,1.664,1.468,2.349,2.35
			C408.709,524.829,408.73,524.964,408.744,525.107z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M398.173,518.765c-0.729,1.085,0.933,1.486,1.879,1.645
			C399.849,519.438,399.017,519.096,398.173,518.765z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M393.005,764.716c0-0.078,0-0.156,0-0.235
			c-0.506-0.512-0.264-1.772-0.705-2.349c0.032-1.285-0.166-2.34-0.47-3.289c-0.186-1.224-0.239-2.579-0.705-3.523
			c-0.118-1.839-0.507-3.407-1.174-4.698c-0.332-0.843-1.017-1.333-1.41-2.114c-0.881-0.841-1.223-2.222-1.879-3.288
			c-0.69-2.961,0.269-5.257,1.175-7.282c0.318-0.073,0.194-0.589,0.234-0.94c0-0.548,0-1.096,0-1.644c-0.078,0-0.156,0-0.234,0
			c0-0.94,0-1.88,0-2.819c-0.535-0.482-0.008-2.028-0.47-2.584c-0.153-1.179,0.308-2.971-0.235-3.759c0-0.234,0-0.47,0-0.704
			c-0.151-1.023,0.307-2.656-0.234-3.289c0-0.235,0-0.47,0-0.705c0-0.939,0-1.879,0-2.818c-0.079,0-0.157,0-0.235,0
			c0-0.313,0-0.627,0-0.94c0-0.704,0-1.409,0-2.114c-0.527-0.358-1.154,1.187-1.879,1.41c0.527-0.726,1.154-1.352,1.879-1.88
			c0.142-1.473-0.178-2.484-0.234-3.758c-3.132,0.077-5.392-0.718-7.987-1.175c0.493,2.415-0.041,9.649,1.409,11.041
			c0.041,0.039,0.187-0.031,0.235,0c-0.46,0.258-0.78,1.863-0.235,2.349c-0.059,1.468,0.119,2.7,0.47,3.759
			c-0.032,0.973,0.065,1.814,0.47,2.349c0.311,1.606,0.211,2.861-0.234,4.229c-0.88,0.922-1.722,1.88-2.114,3.289
			c-0.252,0.609-0.571,1.151-0.47,2.114c-0.078,0-0.157,0-0.235,0c0.515,0.974,0.94,2.035,1.88,2.584
			c-0.172,0.641,0.467,0.473,0.47,0.939c0.279,0.738,0.793,1.243,0.939,2.114c0.837,1.513,1.869,2.829,2.349,4.698
			c-0.043,0.2,0.092,0.222,0.235,0.234c-0.039,1.371,0.41,2.253,0.234,3.759c0.319,0.073,0.195,0.589,0.235,0.94
			c0.15,0.945-0.306,2.497,0.235,3.054c0,0.234,0,0.47,0,0.704c0,1.487,0.152,2.823,0.47,3.994c-0.062,1.471,0.31,2.509,0.939,3.288
			c2.662,0,5.324,0,7.986,0C393.914,764.902,393.348,764.921,393.005,764.716z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#77282A" d="M407.804,526.986c-0.994-0.884-2.315-3.685-3.523-2.818
			c1.154,0.725,1.8,1.959,2.819,2.818c0.078,0.549,0.24,1.013,0.704,1.175C407.665,527.412,408.245,527.435,407.804,526.986z"/>
			</g>
			<g id="gray">
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M31.478,214.556c3.484,11.823,7.27,23.542,10.101,35.472
			c0.99,4.172,2.756,8.513,3.054,12.215c0.058,0.714-0.269,1.445-0.235,2.114c0.108,2.095,0.801,4.028,1.41,5.873
			c1.976-5.541,6.911-8.123,11.98-10.571c1.501,2.74,1.845,6.757,1.879,10.336c-4.247,1.201-5.524,7.18-3.523,11.98
			c3.401,0.425,3.624-2.327,6.107-2.819c1.618,2.361,3.429,7.275,2.349,10.101c1.894,0.925,3.95,1.688,6.108,2.349
			c-10.756-45.468-22.23-90.212-33.357-135.309c-4.439,1.816-9.439,6.513-14.094,8.692c-2.043,0.957-3.605,0.883-4.229,2.114
			c-0.671,1.324,1.695,8.17,2.584,11.51C24.823,190.678,28.174,203.345,31.478,214.556z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M239.374,294.426c-4.706-0.239-10.645,1.204-13.39-1.41
			c0.368-2.842,1.376-5.045,2.114-7.517c-1.256-0.261-2.358-0.907-3.289-1.645c-7.795-6.179-14.019-13.731-22.551-19.027
			c-0.014-0.144-0.035-0.278-0.235-0.235c-0.338,0.131-0.573,0.366-0.705,0.705c-0.607,1.148,0.625,5.06,1.175,7.517
			c4.148,18.54,6.98,38.953,10.101,57.318c0.161,8.281,2.927,17.192,4.463,25.605c0.435,2.379,1.593,6.03,0.705,7.987
			c-0.747,1.646-3.813,1.793-5.638,2.819c-4.047,2.276-8.066,7.159-14.095,7.517c1.753,4.556,0.736,11.142,0.705,16.914
			c-0.075,14.008,0.404,26.666,1.175,39.465c0.757,12.568,0.448,24.894,2.584,36.411c0.764,4.123,0.746,8.241,1.409,12.216
			c0.144,0.858,0.766,1.743,0.94,2.584c0.417,2.007,0.4,4.333,0.705,6.577c0.608,4.486,0.34,9.249,2.114,12.216
			c0.429,0.718,1.312,2.055,1.645,1.644c-2.864-18.498-2.982-37.465,0-53.794c6.06-33.188,15.683-62.087,28.659-88.326
			c3.003-6.073,6.252-13.396,9.866-19.498c1.623-2.74,3.947-5.535,6.108-8.457c3.785-5.118,7.917-9.64,5.873-17.853
			C252.236,308.343,248.314,298.875,239.374,294.426z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M56.613,285.029c0.311,0.941,0.325,2.181,0.705,3.054
			c1.804-0.075,3.626-0.132,5.168-0.47c0.078-1.222-0.393-3.801-1.41-4.698C60.306,284.337,58.391,284.614,56.613,285.029z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M41.344,343.052c-1.319,2.361-2.823,4.538-3.994,7.047
			c4.027-0.906,7.687-2.18,11.98-2.819c0.217-1.739-1.183-3.803-1.879-5.403C45.135,341.988,43.641,342.921,41.344,343.052z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M45.103,363.959c-0.09-1.692-1.094-2.533-0.235-3.993
			c-3.021,0.424-5.284,1.606-7.752,2.584c0.147,0.871,0.661,1.375,0.94,2.114C40.065,364.875,42.96,364.354,45.103,363.959z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M46.747,359.966c1.547,3.786,2.322,5.997-3.054,6.812
			c-4.803,0.729-8.568,0.74-8.927-3.289c-0.94,0.313-1.898,0.607-2.349,1.41c2.888,8.337,6.157,19.595,8.927,29.364
			c0.684,2.411,2.076,8.275,4.229,8.691c0.803,0.155,1.758-0.56,2.349-0.705c4.534-1.111,9.406-2.586,13.859-4.229
			c-4.084-8.535-6.483-20.398-9.396-30.303C51.482,364.649,50.716,360.168,46.747,359.966z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M196.62,434.433c-0.049-0.264-0.26-0.366-0.47-0.471
			c-0.057,8.936-3.902,18.219-3.289,27.015c0.618,8.858,2.917,16.259,6.813,23.257C199.03,466.962,195.32,453.106,196.62,434.433z"
				/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M417.905,683.672c-16.022-12.088-25.588-30.635-25.84-58.492
			c-0.157,0-0.313,0-0.47,0c-3.587,2.6-6.754,5.617-11.276,7.281c0.658,5.779,0.283,11.901,1.88,17.148
			c0.571,1.88,1.567,3.513,2.114,5.403c1.697,5.873,3.189,12.844,5.638,17.618c0.747,1.459,1.958,2.733,2.818,4.229
			c2.601,4.518,5.366,8.774,9.162,11.98c4.669,0.813,8.152,2.81,12.92,3.523c1.612-0.932,2.911-2.273,4.229-3.523
			c1.357-1.289,2.901-2.435,3.993-3.993C421.286,684.52,420.302,683.39,417.905,683.672z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M74.702,300.063c0.994,1.355,1.549,3.149,2.349,4.698
			c1.288-1.139,1.533-3.322,2.349-4.933c0.083-0.309,0.414-0.37,0.47-0.705c0.072-0.554,0.815-0.437,0.705-1.175
			c0.554-0.072,0.437-0.815,1.174-0.705c0.644-0.296,0.422-1.458,1.175-1.645c0-0.235,0-0.47,0-0.705
			c-0.326-0.144-0.035-0.904-0.47-0.939c0.48-2.016-0.101-3.623-0.705-5.168c-0.151-0.867-0.241-1.795-0.705-2.349
			c0-0.079,0-0.157,0-0.235c-0.151-0.866-0.241-1.794-0.705-2.349c0.078-0.86-0.261-1.305-0.47-1.879
			c-0.151-0.867-0.241-1.795-0.705-2.349c-0.229-1.65-0.688-3.071-1.174-4.463c-0.151-0.867-0.241-1.795-0.705-2.349
			c-0.229-1.65-0.688-3.071-1.175-4.463c-0.151-0.867-0.241-1.795-0.705-2.349c-0.229-1.65-0.688-3.07-1.175-4.463
			c-0.151-0.866-0.241-1.794-0.705-2.349c0-0.078,0-0.156,0-0.235c-0.151-0.866-0.241-1.794-0.705-2.349c0-0.078,0-0.156,0-0.235
			c-1.316-2.6-4.279-3.551-4.933-6.813c-0.297-2.13-0.876-3.979-1.41-5.873c-0.313-2.036-0.781-3.917-1.409-5.638
			c-0.454-2.756-1.189-5.231-1.879-7.752c-0.392-2.349-0.938-4.543-1.645-6.578c-0.375-2.443-1.033-4.605-1.645-6.813
			c-0.313-2.036-0.781-3.917-1.409-5.638c-0.375-2.443-1.033-4.605-1.645-6.813c-0.392-2.349-0.938-4.543-1.644-6.578
			c-0.454-2.756-1.189-5.231-1.879-7.752c-0.47-2.662-1.095-5.169-1.879-7.517c-0.846-4.322-1.973-8.363-3.054-12.45
			c-0.626-3.289-1.409-6.421-2.349-9.396c-0.219-1.817-0.718-3.354-1.175-4.933c0.112-1.398-0.704-0.647-1.644-0.705
			c-0.079,0-0.157,0-0.235,0c-0.079,0-0.157,0-0.235,0c-0.3,0.875-2.215,0.134-2.819,0.705
			c11.608,47.353,23.309,94.62,35.002,141.886C74.982,298.622,74.945,299.445,74.702,300.063z M68.359,256.605
			c0.078,0,0.157,0,0.235,0c0-0.078,0-0.156,0-0.235c0.313,0,0.626,0,0.939,0c1.569,0.702,1.637,2.905,2.114,4.698
			c-0.055,1.276-0.493,2.169-2.114,1.879C67.634,262.542,65.884,257.73,68.359,256.605z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M316.894,297.714c2.737,9.879,5.155,20.965,8.692,30.773
			c1.181,3.275,3.04,6.579,3.523,9.631c0.749,4.731-0.136,8.972-0.939,12.92c1.681-1.356,2.775-3.714,4.463-5.638
			c2.874-3.277,6.959-5.897,9.161-8.927c4.156-2.265,7.255-5.587,11.041-8.222c0.667-0.6,1.305-1.229,1.943-1.856
			c-0.638,0.628-1.276,1.256-1.943,1.856c1.46-0.722,3.315-3.645,4.934-1.41c1.278-1.305,2.489-2.679,2.349-5.403
			c0-1.096,0-2.192,0-3.289c0-2.271,0-4.542,0-6.812c0-0.313,0-0.626,0-0.94c0.078,0,0.156,0,0.235,0c0-1.174,0-2.349,0-3.523
			c0-0.157,0-0.313,0-0.47c0.541-0.633,0.083-2.266,0.234-3.289c0-0.235,0-0.47,0-0.705c0.538-0.402,0.086-1.793,0.235-2.584
			c0-0.157,0-0.313,0-0.47c0.537-0.402,0.086-1.793,0.234-2.584c0-0.157,0-0.313,0-0.47c0.538-0.402,0.086-1.793,0.235-2.584
			c0-0.078,0-0.156,0-0.235c-0.188-4.745-4.845-7.057-7.518-10.101c-0.053-0.092-0.132-0.144-0.221-0.176l0,0
			c-4.211-3.656-10.42-5.63-15.987-8.28c-10.803-5.143-21.684-12.28-34.063-16.209c0.897,3.076,2.565,5.923,3.993,8.927
			C311.694,276.476,313.934,287.029,316.894,297.714z M356.876,324.396L356.876,324.396c-2.713,2.148-5.259,4.461-7.901,6.677
			C351.615,328.854,354.164,326.543,356.876,324.396z M352.64,283.908c0.039,0.063,0.1,0.125,0.195,0.182
			C352.738,284.033,352.679,283.972,352.64,283.908z M352.912,283.306c0.018-0.01,0.036-0.017,0.054-0.026
			C352.948,283.289,352.929,283.296,352.912,283.306z M352.806,283.383c-0.03,0.024-0.061,0.049-0.086,0.077
			C352.746,283.432,352.774,283.408,352.806,283.383z M352.663,283.533c-0.024,0.037-0.041,0.075-0.054,0.113
			C352.622,283.607,352.639,283.57,352.663,283.533z M352.594,283.716c-0.004,0.044,0.008,0.089,0.024,0.133
			C352.601,283.805,352.589,283.761,352.594,283.716z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M360.587,328.253c-2.462,1.185-3.669,3.994-5.638,6.107
			c-4.017,4.312-8.409,8.144-12.686,11.98c-2.018,1.811-3.951,5.105-7.047,4.463c0.797-2.25,3.084-3.45,4.698-4.933
			c5.091-4.679,9.964-9.321,14.329-14.564c-3.888,3.062-8.528,6.256-12.92,10.336c-3.382,3.142-9.354,8.312-10.806,12.685
			c-0.467,1.406-0.524,3.189-0.939,4.698c-0.424,1.539-1.143,3.143-1.175,4.698c-0.071,3.502,1.99,7.603,3.289,10.806
			c1.266,3.124,4.201,7.262,3.523,10.806c-0.226,1.178-2.341,4.044-3.523,5.873c-1.363,2.107-2.747,4.127-3.759,5.638
			c-17.68,26.4-36.11,52.328-51.68,79.399c11.961-7.338,22.439-16.73,31.712-26.779c4.865-5.271,9.374-10.329,13.155-16.209
			c7.538-11.723,11.663-27.356,17.618-40.875c2.959-6.717,6.542-13.273,9.162-20.202c2.718-7.188,4.824-14.599,7.752-21.612
			c2.821-6.76,5.958-13.181,9.866-19.028C363.274,331.046,361.235,330.345,360.587,328.253z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M299.98,432.318c9.172-13.755,19.129-27.565,28.189-41.814
			c1.307-2.056,3.508-5.131,3.523-6.578c0.015-1.372-1.378-3.218-2.114-4.933c-2.041-4.75-5.123-10.786-4.698-16.443
			c0.245-3.263,2.52-5.542,2.349-9.396c-4.821,10.566-11.798,19.654-17.383,29.833c-5.493,10.012-10.377,20.4-15.034,31.243
			c-9.207,21.435-19.173,42.729-27.015,65.775c4.88-1.767,6.703-6.863,9.161-10.806C284.683,456.811,291.788,444.604,299.98,432.318
			z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M55.204,362.08c-1.124-2.735-2.674-6.374-5.873-6.812
			c-2.774-0.38-7.222,1.703-9.631,2.349c-4.34,1.165-10.52,1.991-10.806,7.047c-0.148,2.625,1.501,6.764,2.349,9.866
			c2.441,8.937,5.775,20.07,8.926,27.719c0.833,2.02,1.767,3.99,3.289,5.403c5.251-0.933,12.429-3.093,18.323-5.168
			c1.806-0.636,4.538-1.449,4.698-3.054c0.154-1.538-1.989-2.385-1.409-3.994c0-0.078,0-0.157,0-0.235
			c-0.404-0.093-0.603,0.349-0.705,0c0.017-3.463-1.768-7.158-2.349-10.806c-0.228,0.006-0.182,0.287-0.235,0.47
			c-0.523-0.24,0.307-1.08,0-1.879c0-0.078,0-0.157,0-0.235C59.254,376.704,57.778,368.349,55.204,362.08z M63.661,395.672
			c0.038,0.742,0.008,1.418-0.47,1.645c0.461,0.713,1.542,0.808,1.409,2.114c-2.207,1.808-5.347,2.405-8.222,3.523
			c-4.057,1.578-8.485,1.742-11.511,3.759c-1.018-1.097-1.769-2.46-3.054-3.289c-3.882-9.498-7.015-23.074-10.571-34.297
			c-0.506-1.596-1.587-3.998-1.41-4.933c0.424-2.227,11.341-6.511,13.86-6.812c1.268-0.152,2.516,0.386,3.758,0.235
			c0.707,0.237,0.417-0.523,0.47-0.94c4.856,1.807,6.147,7.583,7.752,13.155C58.132,378.368,60.184,388.246,63.661,395.672z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M50.271,169.454c0.47,2.662,1.095,5.169,1.879,7.517
			c0.376,2.443,1.033,4.605,1.645,6.813c0.47,2.662,1.095,5.169,1.879,7.517c0.454,2.756,1.189,5.231,1.879,7.752
			c0.391,2.349,0.938,4.543,1.644,6.578c0.454,2.756,1.189,5.231,1.879,7.752c0.392,2.349,0.938,4.543,1.645,6.577
			c0.532,3.07,1.346,5.858,2.114,8.692c0.47,2.662,1.096,5.169,1.879,7.517c0.532,3.07,1.346,5.858,2.114,8.692
			c-0.053,0.992,0.362,1.517,0.47,2.349c0.164,1.794,1.549,2.366,2.114,3.758c0.189,0.829,1.434,0.602,1.41,1.645
			c0.619,0.083,1.033,0.37,1.378,0.727c0.343,0.371,0.671,0.75,0.971,1.152c0,0.079,0,0.157,0,0.235c0,0.626,0,1.252,0,1.879
			c0.024,0.602,0.133,1.12,0.47,1.41c0.063,1.19,0.401,2.104,0.705,3.054c0.247,1.163,0.381,2.438,0.94,3.289
			c-0.014,0.875,0.237,1.485,0.47,2.114c0,0.235,0,0.47,0,0.705c0.517,0.181,1.17-2.253,2.114-0.47
			c0.063,1.425,0.375,2.601,0.705,3.759c0.233,1.333,0.266,2.867,1.174,3.523c0.22-0.172,0.356-0.427,0.47-0.705
			c0.558-0.913,0.025-2.444-0.235-3.289c-0.068-1.263-0.247-2.416-0.705-3.289c0.008-1.025-0.204-1.832-0.47-2.584
			c0.083-0.935-0.293-2.329,0.47-2.584c0.949-0.087,0.919,0.803,1.41,1.175c0.042,1.368,0.344,2.475,0.705,3.523
			c0.132,1.121,0.246,2.26,0.705,3.054c-0.037,1.446,0.507,2.312,0.47,3.758c-0.715,1.007-1.285,2.161-2.349,2.819
			c-0.313,0-0.626,0-0.94,0c-0.078,0-0.156,0-0.235,0c-0.352-0.274-0.574-0.678-1.174-0.705c0.456,0.797,0.335,2.171,0.939,2.819
			c0.141,1.504,0.561,2.728,0.939,3.994c0.058,0.725,0.238,1.328,0.47,1.879c0.778-0.866,0.995-2.294,1.409-3.523
			c0.29-0.963,0.868-1.638,0.94-2.819c0.985-1.338,1.185-2.521,1.644-3.994c-0.063-1.19-0.401-2.104-0.705-3.054
			c-0.495-2.089-0.836-4.332-1.645-6.107c-0.297-2.13-0.875-3.979-1.409-5.873c-0.415-1.778-0.682-3.703-1.41-5.168
			c-0.219-1.817-0.718-3.354-1.174-4.933c-0.495-2.089-0.836-4.332-1.645-6.108c-0.297-2.13-0.875-3.979-1.41-5.873
			c-0.495-2.089-0.836-4.332-1.644-6.107c-0.141-1.504-0.561-2.729-0.94-3.994c-0.495-2.089-0.836-4.332-1.644-6.108
			c-0.297-2.13-0.876-3.979-1.41-5.873c-0.495-2.089-0.836-4.332-1.645-6.107c-0.141-1.504-0.561-2.729-0.939-3.994
			c-0.495-2.089-0.836-4.332-1.645-6.108c-0.297-2.13-0.875-3.979-1.409-5.873c-0.332-1.469-0.53-3.072-1.175-4.229
			c-0.219-1.817-0.718-3.353-1.174-4.933c-0.415-1.778-0.682-3.703-1.41-5.168c-0.219-1.817-0.718-3.354-1.174-4.933
			c-0.415-1.778-0.682-3.703-1.41-5.168c-0.219-1.817-0.718-3.354-1.174-4.933c-0.332-1.469-0.53-3.072-1.175-4.229
			c-0.119-1.604-0.663-2.782-0.939-4.228c-0.506-0.389-1.446,0.293-2.114,0.47c-2.349,0-4.698,0-7.047,0
			c0.38,2.83,1.107,5.313,1.879,7.752C49.002,165.084,49.659,167.246,50.271,169.454z M74.194,253.332
			c-0.552-0.597-1.145-1.169-1.729-1.764C73.05,252.162,73.642,252.735,74.194,253.332z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M109.703,335.535c-0.441-1.36-0.771-2.831-1.41-3.994
			c-0.537-2.595-1.627-4.637-2.349-7.047c-0.368-1.042-0.515-2.304-1.174-3.054c-1.582-4.682-3.422-9.106-4.463-14.33
			c-0.417-1.384-1.004-2.598-1.41-3.994c-0.162-2.03-0.883-3.501-1.409-5.168c-0.167-1.008-0.383-1.966-0.94-2.584
			c0.018-0.409-0.052-0.73-0.235-0.939c-0.481-1.711-1.755-2.63-2.819-3.759c0-0.078,0-0.156,0-0.235
			c-0.74-1.531-2.764-1.778-3.758-3.054c-0.219-1.817-0.719-3.354-1.175-4.933c-0.527-1.9-0.647-4.208-1.644-5.638
			c0.194,0.664-0.332,0.608-0.235,1.174c0,0.078,0,0.157,0,0.235c-0.824,1.211-1.112,2.959-1.644,4.463
			c-0.074,0.317-0.334,0.449-0.235,0.939c-0.557,0.619-0.773,1.576-0.94,2.584c-0.196,0.648-1.086,1.381-0.47,2.114
			c0.332,1.469,0.53,3.072,1.175,4.229c-0.075,0.545,0.038,0.902,0.235,1.174c-0.194,0.664,0.331,0.609,0.235,1.175
			c4.172-0.413,5.381,2.136,6.813,4.463c-0.075,0.544,0.038,0.902,0.235,1.174c0.083,1.092,0.301,2.048,0.705,2.819
			c-0.075,0.545,0.038,0.902,0.235,1.175c0.146,1.185,0.289,2.374,0.705,3.289c0,0.079,0,0.157,0,0.235
			c0.155,1.647-0.31,3.913,0.235,5.168c0,0.391,0,0.783,0,1.174c-0.079,1.567,0.223,2.753,0.705,3.758
			c0.534-1.971,1.327-3.684,1.644-5.873c0-0.078,0-0.157,0-0.235c0.602-1.157,0.065-3.191-0.47-3.994
			c-0.642-4.134-1.479-8.074-2.819-11.51c-0.224-1.32,0.655-1.538,0.939-2.349c0.715,0.068,0.324,1.242,0.94,1.409
			c0.669,3.012,1.452,5.908,2.349,8.692c0.169,0.927,0.291,1.901,0.705,2.584c-0.075,0.545,0.038,0.902,0.235,1.174
			c0.167,1.086,0.475,2.032,0.94,2.819c-0.018,0.41,0.052,0.731,0.235,0.94c0.822,2.702,1.42,5.627,2.819,7.752
			c0,0.078,0,0.157,0,0.235c0.424,0.829,0.279,2.227,0.939,2.819c0,0.079,0,0.157,0,0.235c0.472,1.485,0.885,3.03,1.645,4.229
			c-0.018,0.409,0.052,0.73,0.235,0.94c0.419,1.226,0.733,2.556,1.409,3.523c0.302,1.656,0.911,3.004,1.41,4.463
			c0.139,1.27,0.913,1.906,1.41,2.819c0,0.078,0,0.157,0,0.235c0.375,1.191,0.379,2.753,1.174,3.523
			c0.197-0.272,0.31-0.629,0.235-1.174c0.014-0.143,0.035-0.278,0.235-0.235c0.198-0.585,0.308-1.259,0.235-2.114
			C110.815,337.711,110.345,336.537,109.703,335.535z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M57.083,267.411c-0.16-0.937-0.512-1.681-0.47-2.819
			c-0.119,0.079-0.244,0.164-0.367,0.246l0,0c0.123-0.083,0.248-0.167,0.367-0.246c0.066-0.379-0.222-0.405-0.235-0.705
			c-4.852,1.726-6.538,6.617-9.631,10.101c0.166,6.804,0.763,13.176,2.349,18.558c0.4-1.739,2.462-0.631,3.524-1.41
			c-0.79-4.77-1.391-9.728-2.584-14.094c0.472-2.348,0.532-5.106,1.879-6.578c0.026-0.6,0.787-0.466,1.41-0.47
			c0.106,1.125-0.17,1.866-0.705,2.349c-0.159,1.329-0.757,2.218-0.705,3.758c0.079,0,0.157,0,0.235,0c0,0.235,0,0.47,0,0.705
			c-0.05,1.381,0.145,2.518,0.47,3.523c0.106,1.225,0.344,2.318,0.705,3.289c0.04,1.056,0.187,2.006,0.47,2.819
			c0.128,0.341-0.274,1.214,0.235,1.174c-0.031,0.579-0.036,1.132,0.235,1.41c0.128,0.341-0.274,1.214,0.235,1.174
			c1.036,0.14,0.534-0.991,0.235-1.409c0.014-0.876-0.237-1.486-0.47-2.114c-0.38-2.752-1.296-4.968-1.409-7.987
			c-0.533-0.25-0.09-1.477-0.235-2.114c0-0.078,0-0.156,0-0.235c0.275-0.038,0.198-0.428,0.235-0.705
			c-0.792-2.282,0.959-4.049,1.879-5.403C55.111,268.885,56.579,268.629,57.083,267.411z M55.675,265.231
			c-0.178,0.125-0.358,0.255-0.54,0.389C55.317,265.486,55.497,265.356,55.675,265.231z M55.023,265.704
			c-0.159,0.119-0.318,0.241-0.479,0.367C54.705,265.945,54.864,265.823,55.023,265.704z M54.373,266.21
			c-0.146,0.118-0.292,0.237-0.437,0.362C54.081,266.448,54.227,266.329,54.373,266.21z M53.727,266.758
			c-0.133,0.118-0.266,0.235-0.397,0.359C53.461,266.993,53.594,266.875,53.727,266.758z M53.075,267.37
			c-0.146,0.144-0.289,0.29-0.43,0.441C52.786,267.659,52.93,267.514,53.075,267.37z M49.808,274.352
			c-0.005,0.135-0.014,0.268-0.014,0.407C49.793,274.62,49.803,274.487,49.808,274.352z M49.831,275.504
			c0.009,0.122,0.01,0.24,0.023,0.365C49.841,275.744,49.84,275.626,49.831,275.504z M50,272.72c0.028-0.132,0.06-0.262,0.093-0.391
			C50.06,272.458,50.028,272.588,50,272.72z M49.846,273.709c0.014-0.138,0.035-0.271,0.054-0.405
			C49.881,273.438,49.86,273.571,49.846,273.709z M50.247,271.789c0.04-0.125,0.082-0.248,0.126-0.369
			C50.329,271.541,50.287,271.664,50.247,271.789z M50.578,270.912c0.05-0.116,0.101-0.232,0.155-0.345
			C50.679,270.68,50.628,270.796,50.578,270.912z M50.983,270.089c0.059-0.107,0.116-0.215,0.179-0.32
			C51.099,269.874,51.042,269.982,50.983,270.089z M51.454,269.316c0.065-0.098,0.127-0.197,0.195-0.292
			C51.581,269.119,51.519,269.219,51.454,269.316z M51.985,268.586c0.067-0.085,0.131-0.174,0.2-0.256
			C52.116,268.412,52.052,268.501,51.985,268.586z M52.392,268.38c1.074-1.086,2.367-2.021,3.287-3.151l0,0
			C54.759,266.36,53.467,267.294,52.392,268.38z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M107.824,346.576c-0.338-0.053-0.082-0.701-0.47-0.705
			c0-0.079,0-0.157,0-0.235c0.17-0.719-0.405-0.691-0.235-1.41c-0.573-0.523-0.444-1.749-0.94-2.349
			c-0.611-3.382-1.624-6.363-2.584-9.396c-0.151-0.866-0.241-1.794-0.705-2.349c-0.019-1.156-0.46-1.888-0.705-2.819
			c-0.58-1.769-0.583-4.116-2.584-4.463c-0.217-2.21-1.247-3.608-1.645-5.638c-0.67,0.426-0.757,1.435-0.939,2.349
			c-0.183,0.209-0.253,0.531-0.235,0.939c-0.578,0.675-0.708,1.798-1.41,2.349c0.205,1.205,1.65,1.168,1.41,2.819
			c0.349,0.199,0.34,0.756,0.47,1.174c0.529-0.098-0.035-1.288,0.705-1.174c0.875-0.249,0.758,0.494,1.175,0.705
			c0.199,1.602,1.26,2.341,1.409,3.994c1.371,3.64,2.202,7.82,3.524,11.51c0.303,1.107,0.625,2.194,1.174,3.054
			c-0.023,1.354,0.645,2.018,0.939,3.054c-0.307,1.012,0.667,0.743,0.94,1.174c-1.128,3.155-1.83,5.697-2.349,8.457
			c0,0.078,0,0.156,0,0.235c-0.219,0.172-0.356,0.427-0.47,0.705c0.212,0.414,0.525,0.728,0.939,0.939
			c0.841-0.881,1.057-2.388,1.41-3.758c0.182-0.209,0.252-0.53,0.235-0.939c0.573-0.524,0.444-1.749,0.939-2.35
			c0.45-1.899,0.983-3.715,1.645-5.403C108.973,346.814,108.079,345.635,107.824,346.576z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M101.011,339.528c-0.221-1.658-1.188-2.57-1.409-4.229
			c-0.219-0.172-0.356-0.427-0.47-0.705c-0.033-0.438-0.155-0.785-0.47-0.939c-0.506,0.198-0.198,1.211-0.705,1.409
			c-0.127,1.537,0.145,2.674,0.235,3.994c0.079,0,0.157,0,0.235,0c0,0.078,0,0.157,0,0.235c-0.125,0.909,0.419,1.147,0.235,2.114
			c0.338,0.053,0.082,0.701,0.47,0.705c-0.075,0.545,0.038,0.902,0.235,1.175c-0.188,0.814,0.35,0.903,0.235,1.644
			C100.522,343.735,101.382,341.233,101.011,339.528z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M102.421,344.227c-0.084,1.169-0.658,1.848-0.705,3.054
			c0,0.079,0,0.157,0,0.235c-0.142,1.111-1.621,0.885-1.41,2.349c0.078,0,0.157,0,0.235,0c0,0.079,0,0.157,0,0.235
			c0.121,1.367-0.264,3.239,0.235,4.228c0.545,0.075,0.902-0.038,1.175-0.235c1.098-1.019,2.186-3.292,1.644-5.168
			c-0.024-1.385-0.423-2.396-0.705-3.523C102.925,344.818,102.935,344.261,102.421,344.227z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M101.481,359.966c-0.37-0.021-0.184-0.6-0.705-0.47
			c-0.032,0.829-0.672,1.051-0.705,1.879c0.863,1.173,2.157,1.915,3.758,2.349c0.003-0.467,0.641-0.299,0.47-0.939
			C103.262,361.943,102.323,361.003,101.481,359.966z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M71.178,361.14c0,0.157,0,0.313,0,0.47
			c-0.232,1.329,0.844,1.349,1.41,1.879C72.761,362.305,71.805,359.984,71.178,361.14z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M83.628,352.448c-0.028-0.676,0.128-1.538-0.235-1.879
			c0.028-1.125-0.066-2.126-0.47-2.819c0-0.079,0-0.157,0-0.235c-0.009-1.4-0.618-2.202-0.939-3.289
			c-0.276-1.212-0.458-2.518-0.705-3.758c-0.348-0.272-0.592-0.272-0.939,0c-0.235,0-0.47,0-0.705,0
			c-0.25,0.533-1.477,0.089-2.114,0.235c-0.303-0.324-1.021-0.232-1.645-0.235c0.043,0.2-0.092,0.221-0.235,0.235
			c-0.951,0.075-2.411,0.867-1.645,1.879c0.631,2.631-0.842,3.418-0.939,5.873c-0.349,0.434-0.45,1.116-0.47,1.879
			c-0.313,0.236-0.874,0.222-0.94,0.705c-0.078,0-0.156,0-0.235,0c-0.63,0.309-1.249,0.631-1.879,0.939
			c-0.271,0.59-0.936,0.787-0.939,1.645c-1.193,2.174-2.311,4.423-1.879,8.222c0.851-0.073,0.426,0.711,0.47,2.114
			c-0.054,0.916,0.505,1.218,0.705,1.879c0.461,1.184,1.563,1.726,2.584,2.349c1.072,0.729,2.369,1.233,3.993,1.41
			c1.294,0.35,3.087,0.202,2.584,2.349c-0.209,0.104-0.42,0.206-0.47,0.47c-0.078,0-0.156,0-0.235,0c-0.626,0-1.253,0-1.879,0
			c-0.156,0-0.313,0-0.47,0c-0.078,0-0.156,0-0.235,0c-1.344-0.613-3.038-0.877-4.229-1.645c-0.078,0-0.156,0-0.235,0
			c-1.23-0.962-2.372-2.013-3.523-3.054c0-0.079,0-0.157,0-0.235c-0.65-0.916-0.804-2.328-1.41-3.289c0-0.078,0-0.156,0-0.235
			c0-0.235,0-0.47,0-0.705c-1.212-0.011-1.111,2.035-0.47,2.584c0.876,4.057,2.178,7.689,3.054,11.746
			c1.262,3.906,2.393,7.943,3.054,12.45c1.396-1.58,2.012-3.939,2.819-6.108c0.704-0.627,0.45-2.212,0.939-3.054
			c0.697-1.809,1.629-3.382,2.114-5.403c0.349-0.2,0.505-0.591,0.47-1.175c0.079,0,0.157,0,0.235,0
			c0.04-0.665,0.431-0.979,0.47-1.644c0-0.079,0-0.157,0-0.235c0.47-0.313,0.523-1.043,0.705-1.645c0-0.078,0-0.157,0-0.235
			c0.604-1.432,0.856-3.215,1.409-4.698c0-0.078,0-0.157,0-0.235c0.708-0.857,1.068-2.064,1.645-3.054
			c0.101-0.447,0.506-0.59,0.47-1.175c1.134-2.233,2.689-4.045,2.819-7.282C83.592,353.895,84.186,352.595,83.628,352.448z
			 M74.937,364.194c-0.544,1.072-1.669,2.381-3.289,1.645c-0.078,0-0.156,0-0.235,0c-1.339-0.54-2.374-1.384-2.349-3.289
			c-0.078,0-0.156,0-0.235,0c0-0.079,0-0.157,0-0.235c-0.061-1.314,0.043-2.463,0.705-3.054c0.528-0.604,1.082-0.879,1.626-0.934
			c0.204-0.009,0.412,0.001,0.62,0.027c1.573,0.329,2.94,2.395,3.158,3.96C74.937,362.941,74.937,363.567,74.937,364.194z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M67.654,389.33c0.056-0.996-0.216-1.664-0.47-2.349
			c-0.241-1.638-0.542-3.216-1.174-4.463c0-0.079,0-0.157,0-0.235c-0.151-0.867-0.241-1.795-0.705-2.349
			c-0.112-1.375-0.559-2.417-0.94-3.524c-0.151-0.866-0.241-1.794-0.705-2.349c0-0.078,0-0.156,0-0.235
			c-0.151-0.866-0.241-1.794-0.705-2.349c-0.034-1.141-0.371-1.979-0.705-2.819c0-0.235,0-0.47,0-0.705
			c-1.089-3.218-2.02-6.594-2.584-10.336c-1.259-1.386-2.696-1.5-4.228-3.054c-1.48-1.502-2.593-4.729-4.698-5.168
			c-1.707-0.355-4.932,0.881-7.047,1.41c-4.466,1.115-8.114,1.879-9.396,6.107c2.091-0.926,5.16-1.252,8.927-2.114
			c2.143-0.491,4.03-1.449,5.403-1.41c8.287,0.236,8.789,12.382,11.98,18.323c0.167,1.086,0.474,2.032,0.939,2.819
			c0,0.078,0,0.156,0,0.235c0.167,1.085,0.475,2.031,0.94,2.819c0.114,0.982,0.369,1.823,0.705,2.584
			c0.151,0.867,0.241,1.795,0.705,2.349c-0.075,0.544,0.038,0.902,0.235,1.174c0.151,0.867,0.241,1.795,0.705,2.349
			c0.063,1.19,0.401,2.104,0.705,3.054c0.332,1.469,0.53,3.072,1.174,4.228c-0.075,0.545,0.038,0.902,0.235,1.175
			c-0.17,0.718,0.405,0.691,0.235,1.409c0.16,0.046,0.301,0.029,0.43-0.028c0.015,0.008,0.023,0.022,0.04,0.028
			c0.008-0.019,0.015-0.04,0.022-0.059c0.694-0.4,0.962-2.146,1.387-2.995c0-0.078,0-0.157,0-0.235
			c0.059-1.077-1.063-0.973-0.939-2.114C67.47,390.61,68.308,389.224,67.654,389.33z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#584A51" d="M68.124,432.318c-0.001-1.017-0.545-1.491-0.47-2.584
			c-0.525-0.102-0.095-1.158-0.235-1.645c-0.673-0.032,0.203-1.612-0.47-1.645c-0.028-0.677,0.128-1.538-0.235-1.879
			c-0.011-1.242-0.105-2.401-0.47-3.289c0.111-1.11-0.293-0.438-0.705-0.235c-1.048,0.676-1.979,1.466-3.054,2.114
			c-4.483,3.793-11.733,3.63-18.793,4.464c-11.941,1.41-25.39,4.236-36.881,0.234c0.592,6.769,1.915,12.806,3.993,18.088
			c10.374,0.634,20.175-0.367,29.834-1.879c9.131-1.43,17.271-2.683,23.256-6.577c0.572-0.126,1.113-0.285,1.634-0.485
			c0.003,0.006,0.007,0.009,0.01,0.016c0.182-0.068,0.348-0.151,0.505-0.243c1.073-0.492,2.061-1.205,3.018-2.341
			c-0.026-0.601-0.431-0.822-0.705-1.175C68.458,432.767,68.198,432.636,68.124,432.318z"/>
			</g>
			<g id="skin">
				<path fillRule="evenodd" clipRule="evenodd" fill="#AE8377" d="M361.762,237.342c1.972-1.083,3.449-2.658,4.229-4.933
			c0-0.078,0-0.156,0-0.235c0.151-1.023-0.307-2.655,0.234-3.289c0-0.157,0-0.313,0-0.47c0.154-1.568-0.31-3.755,0.235-4.933
			c0-0.079,0-0.157,0-0.235c-1.811,0.306-3.681,2.583-4.934,4.228c-0.129,0.732-0.191,1.531-0.47,2.114
			c-0.161,1.483-0.479,2.809-1.174,3.759c0.036,0.584-0.369,0.727-0.471,1.174c-0.524,1.59-2.032,2.196-2.818,3.524
			C358.681,238.177,360.067,237.606,361.762,237.342z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#AE8377" d="M338.976,270.465c0,0.078,0,0.157,0,0.235s0,0.157,0,0.235
			c0.749,0.739,2.07,0.905,2.818,1.645c0.664-0.194,0.609,0.331,1.175,0.235c0.9,0.901,2.484,1.118,3.759,1.645
			c1.633,0.011,2.335,0.953,2.818,2.114c0.448,0.101,0.591,0.506,1.175,0.47c1.525-7.558,4.355-13.811,5.873-21.377
			c0.464-0.554,0.553-1.482,0.705-2.349c0.36-1.049,0.662-2.156,0.704-3.523c0.229-0.007,0.183-0.288,0.235-0.47
			c-0.588-0.11-2.231,0.738-2.819,1.41c-1.012,0.162-1.442,0.906-2.349,1.174c-0.101,0.448-0.672,0.425-0.47,1.175
			c-0.566,0.096-0.511-0.429-1.175-0.235c-0.078,0-0.156,0-0.235,0c-1.162,0.482-2.545,0.744-3.993,0.939
			c-0.545,1.569-0.78,3.448-1.409,4.933c-1.014,4.155-2.71,7.626-4.934,10.571C339.89,269.343,339.312,269.783,338.976,270.465z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#AE8377" d="M268.737,386.041c3.057-2.268,6.408-4.241,9.631-6.342
			c0.371-0.491,0.163-1.56,0.47-2.114c5.435-5.91,13.129-10.295,19.733-15.504c0.001-0.001,1.355-0.576,0.47-0.939
			c-1.802,0-3.603,0-5.403,0c-0.47,0-0.939,0-1.409,0c-1.669,0.445-4.057,0.171-5.873,0.47c-0.235,0-0.47,0-0.705,0
			c-4.03,0.904-6.484,3.382-8.692,6.108c-0.229,1.024-0.814,1.691-1.174,2.584c-0.388,1.413-0.465,3.137-2.349,3.054
			c-0.2,0.043-0.222-0.092-0.235-0.235c-0.079,0-0.157,0-0.235,0c-1.259-0.613-0.582-3.15,0-3.994
			c-0.054-0.915,0.504-1.218,0.705-1.879c2.047-4.452,5.692-7.306,10.101-9.396c3.672-1.261,8.382-1.485,12.45-2.349
			c0.524-0.572,1.533-0.659,2.35-0.94c1.804-0.623,3.263-1.591,5.168-2.114c0.807-0.602,1.748-1.07,2.818-1.409
			c3.669-1.969,8.045-3.23,12.216-4.698c2.805-2.285,5.927-4.253,7.047-8.222c-0.721-2.333-1.756-4.352-2.349-6.813
			c-0.76-1.198-1.173-2.743-1.645-4.229c0-0.078,0-0.157,0-0.235c-0.886-1.542-1.386-3.47-1.88-5.403
			c-0.469-1.41-1.008-2.75-1.174-4.463c-0.464-0.554-0.554-1.482-0.705-2.349c-2.302-8.034-4.532-16.14-6.577-24.431
			c-0.454-0.131-1.607,1.048-2.819,0.939c-7.224,7.732-14.164,15.749-23.726,21.142c-0.02,0.372,0.113,0.896-0.235,0.94
			c0,0.078,0,0.156,0,0.235c-1.015,1.569-2.222,2.946-3.289,4.463c-0.456,1.581-1.081,2.992-1.645,4.463
			c-0.418,1.226-0.733,2.556-1.409,3.524c-1.228,4.567-2.22,9.369-3.759,13.625c-0.889,3.105-1.773,6.213-3.054,8.927
			c0.113,0.739-0.334,0.918-0.47,1.409c-0.417,1.228-0.964,2.325-1.645,3.289c-0.068,1.028-0.411,1.782-1.644,1.645
			c-2.225-1.115-0.21-6.095-0.47-8.927c0-0.079,0-0.157,0-0.235c0-3.367,0-6.734,0-10.101c-2.173,1.429-2.501,4.702-4.228,6.577
			c-0.11,0.83-0.594,1.286-0.94,1.879c-1.692,2.693-3.714,5.056-5.403,7.752c-0.141,1.237,0.174,2.019,0.235,3.054
			c0.624,3.168,5.368,3.938,5.403,6.812c0.03,2.39-3.419,4.057-4.463,6.343c0,0.078,0,0.157,0,0.235
			c-1.607,2.465-3.002,5.141-4.463,7.752c-0.709,1.466-0.15,2.293,0.235,3.759c0.613,0.64,0.837,1.668,1.174,2.584
			c1.198,2.874,2.529,5.614,3.759,8.457c0.464,0.163,0.626,0.626,0.705,1.175c2.622-1.372,4.691-3.296,7.282-4.698
			C267.362,386.858,267.769,386.169,268.737,386.041z M274.845,350.569c1.366-1.976,2.924-4.272,4.229-5.638
			c0-0.078,0-0.156,0-0.235c5.529-6.239,11.315-18.408,17.854-23.256c1.086-0.806,1.144,0.08,0.704,1.645
			c-0.535,1.657-1.57,2.814-2.349,4.228c-1.474,2.755-3.06,5.398-4.934,7.752c0,0.079,0,0.157,0,0.235
			c-0.212,0.415-0.525,0.728-0.939,0.94c-3.873,5.177-7.607,10.534-11.51,15.974c-1.167,1.625-2.208,4.004-4.463,4.463
			C271.045,354.82,273.63,352.326,274.845,350.569z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#AE8377" d="M391.596,682.497c0-0.078,0-0.156,0-0.234
			c-0.966-0.914-1.449-2.311-2.114-3.524c-2.917-3.707-3.181-9.847-5.168-14.799c-0.62-1.543-1.626-2.732-2.114-4.229
			c-1.078-3.303-2.261-6.974-2.819-10.336c-0.39-2.35-0.397-5.717-1.409-7.752c-0.669-0.897,0.188-3.319-0.47-4.229
			c-5.703-1.893-12.054-3.138-18.793-3.993c-0.075-0.552-0.371-0.882-0.235-1.645c-0.385,0.038-0.385-1.448,0-1.409
			c0.342,0.128,1.215-0.275,1.175,0.234c5.664,0.365,10.505,1.554,15.27,2.819c0.801-0.304-2.223-3.57-3.289-4.698
			c-3.844-4.066-9-8.261-11.745-12.92c-0.553-1.014-1.334-1.799-1.645-3.055c-1.931-2.924-3.395-6.315-4.934-9.631
			c-0.124-0.188-0.245-0.382-0.234-0.705c-0.603-0.807-1.071-1.748-1.41-2.818c-0.434-0.976-1.01-1.809-1.174-3.054
			c-0.488-0.717-1.607,0.561-2.114-0.235c-0.079,0-0.157,0-0.235,0c-1.796-0.475-2.652-1.889-3.289-3.523
			c-0.435-0.817-0.773-1.731-0.704-3.054c-1.351-1.729,1.381-4.581,2.114-2.114c0,0.078,0,0.156,0,0.234
			c0.573,0.68,0.521,1.985,0.704,3.055c0,0.078,0,0.156,0,0.234c0.885,0.76,0.703,2.587,2.35,2.584
			c0.096-0.566-0.429-0.511-0.235-1.175c-0.266-0.752-0.478-1.558-0.47-2.584c-0.413-0.683-0.535-1.657-0.704-2.584
			c0-0.078,0-0.156,0-0.234c-0.979-1.527-1.735-3.276-3.524-3.994c-1.264,1.398-2.013,3.312-3.054,4.934
			c-1.377,4.429-3.275,9.444-2.114,15.034c0.451,1.272,0.725,2.721,1.175,3.993c0.682,2.06,1.615,3.866,2.35,5.873
			c1.427,2.175,2.949,4.255,4.228,6.577c0.603,0.645,0.67,2.834,0.235,3.759c-1.014,3.294-2.738,5.876-5.403,7.518
			c-0.078,0-0.156,0-0.234,0c-1.676,0.284-1.908,3.256-3.523,2.818c-6.548,0.158-11.882-3.272-17.854-2.349
			c-2.021,0.313-4.049,1.27-6.107,1.645c-6.233,1.132-12.336,2.772-19.028,3.523c-0.633,0.541-2.266,0.083-3.288,0.234
			c-0.392,0-0.783,0-1.175,0c-0.481,0.391-2.572,0.391-3.054,0c-0.392,0-0.783,0-1.175,0c-0.945-0.15-2.498,0.306-3.054-0.234
			c-2.333,0.062-4.271-0.271-6.343-0.47c-0.47,0-0.939,0-1.409,0c-0.128,0.341,0.274,1.214-0.235,1.174
			c-0.394,3.287-2.396,4.965-6.578,4.464c-1.261-0.07-3,0.337-3.054-0.939c-0.098-0.803,0.071-1.339,0.939-1.175
			c0.711-0.542,2.423-0.083,3.523-0.235c1.934,0.212,2.296-1.149,2.819-2.349c0-0.313,0-0.627,0-0.939
			c-0.356-0.584-2.023,0.144-2.349-0.47c-2.936,0.116-4.685-0.954-7.752-0.705c-0.103,0.289-0.3,0.482-0.705,0.47
			c-0.477,0.776-0.878,1.627-0.705,3.054c0.197,0.272,0.31,0.63,0.235,1.175c0.915,1.669,2.236,2.933,3.758,3.993
			c6.6,2.719,15.157,3.48,23.491,4.464c1.801,0,3.602,0,5.403,0c0.234,0,0.47,0,0.704,0c0.402-0.538,1.793-0.087,2.584-0.235
			c3.111-0.334,5.965-0.927,9.396-0.939c0.944-0.544,2.895-0.082,4.229-0.235c5.89,1.524,6.184-3.137,11.98-2.818
			c2.898-0.548,6.812-0.079,10.102-0.235c1.614-0.231,3.992-1.537,4.463,0.235c0.649,2.444-5.012,2.746-8.222,2.584
			c-2.192,0-4.385,0-6.577,0c-0.971,0.126-1.849,0.344-2.819,0.47c-1.275,0.839-2.52,1.709-3.759,2.584
			c-1.463,0.886-2.689,2.009-4.463,2.584c-4.241,3.198-8.873,6.004-13.155,9.161c-0.078,0-0.156,0-0.234,0
			c-3.366,3.134-6.983,6.016-11.041,8.457c-0.079,0-0.157,0-0.235,0c-0.588,0.821-1.684,1.136-2.584,1.645c-0.078,0-0.156,0-0.235,0
			c-1.517,1.224-3.494,1.986-5.403,2.819c-1.252,0.548-2.27,1.332-3.758,1.644c0.043,0.2-0.092,0.222-0.235,0.235
			c0.345,0.829,1.039,1.311,1.175,2.349c0.232,0.805,0.785,2.213,0.235,3.054c-0.531,2.132-1.998,3.327-3.759,4.229
			c-0.593,0.313-1.866,0.781-2.584,0.235c-1.104-0.224-1.28-2.171,0-2.35c0.815,0.188,0.903-0.35,1.645-0.234
			c0.778-0.709,1.604-1.371,2.114-2.35c0-0.392,0-0.782,0-1.174c-0.288-0.887-0.787-1.563-0.705-2.819
			c-3.817,1.704-12.462,3.852-7.752,9.632c0.572,0.602,1.487,0.862,2.114,1.409c1.301,0.5,2.573,1.029,4.229,1.175
			c1.096,0,2.192,0,3.289,0c0.079,0,0.157,0,0.235,0c0.782-0.69,2.025,0.156,2.114,0.939c0,0.078,0,0.156,0,0.234
			c0.102,2.686-2.893,2.274-4.463,3.289c0.194,0.664-0.331,0.608-0.235,1.175c0.355,0.663,0.415,1.621,0.47,2.584
			c0.331,0.765,0.535,1.657,0.705,2.584c1.41,0.86,2.457,2.085,3.759,3.054c1.659,0.474,2.493-0.751,4.228-0.939
			c7.619-0.828,17.271-2.604,23.021-5.638c0.741-0.825,1.923-1.21,3.054-1.645c8.493-1.843,14.065-6.607,22.552-8.457
			c0.235,0,0.47,0,0.705,0c0.776,0.555,1.677,0.985,1.879,2.114c0.197,0.272,0.31,0.63,0.235,1.175
			c0.838,0.939-1.736,1.583-0.47,2.349c-5.592,5.984-11.825,12.179-18.323,18.088c-1.909,1.736-5.362,4.556-6.107,5.873
			c1.724,0.803,3.23,3.004,2.584,5.403c-1.173,2.507-2.992,4.368-5.873,5.168c-0.078,0-0.156,0-0.235,0
			c-1.218,0.139-1.225-1.989-0.234-2.114c0.496-0.209,0.694-0.716,1.409-0.705c0.901-1.213,2.511-1.717,2.584-3.759
			c-0.401-1.164-1.584-1.547-1.645-3.054c-2.493,1.108-4.579,2.625-6.577,4.229c0.188,0.815-0.35,0.903-0.235,1.645
			c0.763,1.587,1.761,2.938,3.055,3.993c1.512,0.486,3.381,0.161,4.697-0.234c11.861-6.776,19.83-17.442,31.243-24.666
			c1.979,1.144,2.983,0.91,6.108,0.705c0.636-0.391,2.888-0.391,3.523,0c2.415-0.145,4.138,0.403,6.343,0.47
			c1.644,0,3.288,0,4.933,0c1.036-0.335,1.472,0.107,2.819,0c1.723,0,3.445,0,5.168,0c0.313,0,0.626,0,0.939,0
			c0.325-0.536,1.635-0.088,2.349-0.235c7.56-1.054,16.391-0.837,22.787-3.054c2.248-2.058,4.926-3.688,6.812-6.107
			C396.855,690.861,394.403,686.502,391.596,682.497z M338.976,663c-0.895,0.201-1.557,0.636-2.819,0.47
			c-0.405,0.39-2.413,0.39-2.818,0c-1.575-0.166-2.578,0.241-3.994,0.234c-4.707,2.263-7.862,6.076-11.98,8.927
			c-0.078,0-0.156,0-0.234,0c-3.207,2.666-6.573,5.173-10.806,6.813c-4.526,1.896-9.099,3.744-13.625,5.638
			c-1.905,1.149-3.941,2.167-6.108,3.054c-0.348,0.123-0.592,0.348-0.939,0.471c0.657,1.3,1.158,2.757,1.409,4.463
			c0.792,1.893-0.842,3.384-2.114,3.993c-1.27,0.845-2.635,1.594-4.698,1.645c-1.079,0.5-3.385,0.878-3.524-0.705
			c-0.462-1.953,3.242-1.768,5.168-2.114c0.882-0.918,2.705-0.896,2.819-2.584c-0.33-1.471-0.75-2.852-1.409-3.993
			c-1.531,0.17-3.663,0.649-3.994-0.939c0.758-2.218,4.14-1.812,6.108-2.819c0.857-0.708,2.064-1.067,3.054-1.645
			c5.053-2.543,10.265-4.926,15.974-6.812c1.486-1.02,3.316-1.696,4.934-2.584c0.608-0.253,0.873-0.85,1.645-0.94
			c2.351-2.111,4.988-3.938,7.752-5.638c0.078,0,0.156,0,0.234,0c3.18-2.302,5.544-5.419,9.396-7.047
			c0.272-0.197,0.63-0.31,1.175-0.235c1.803-0.546,4.618-0.079,6.813-0.234C338.357,659.57,340.241,661.271,338.976,663z
			 M358.942,668.168c-1.101,0.391-3.831,0.391-4.933,0c-3.535-0.537-7.259-0.885-8.691-3.523c-0.874-0.903,1.665-1.754,2.114-0.705
			c2.447,0.763,4.804,1.616,7.752,1.879c0.714,0.147,2.023-0.301,2.349,0.235c1.468-0.058,2.659,0.16,2.584,1.645
			C359.67,667.799,359.527,668.204,358.942,668.168z M366.46,648.905c-0.444,0.417-1.552,0.171-2.35,0.234
			c-0.713,0.391-3.045,0.391-3.758,0c-6.683-0.129-12.377-1.247-18.089-2.349c-1.058,0.032-1.058-1.133-0.704-1.879
			c0.72-0.229,2.617-0.503,3.288,0c4.38,0.318,8.382,1.015,12.686,1.409c0.791,0.148,2.182-0.303,2.584,0.234
			c2.31,0.319,4.539-0.585,6.343,0C367.823,646.651,367.507,648.739,366.46,648.905z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#AE8377" d="M314.779,189.891c1.057-0.391-0.955-1.464-0.939-2.349
			c-2.418-3.414-6.456-4.906-11.98-6.578c-3.467-1.049-6.726-2.478-9.217-2.2c2.576-0.171,6.826,1.536,9.687,2.436
			C307.864,182.94,312.953,184.987,314.779,189.891z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#AE8377" d="M238.434,203.516c2.589-4.334,4.95-8.574,7.987-11.511
			c0-0.078,0-0.157,0-0.235c0.45-0.725,0.656-1.692,0.939-2.584l-8.691,3.758c1.319-0.247,2.234-0.898,3.523-1.174
			c0.079,0.548,0.241,1.012,0.705,1.174c2.008-0.11,0.612,0.984-0.235,1.175c-0.189,0.124-0.381,0.245-0.705,0.235
			c-0.567,0.529-1.351,0.842-2.349,0.939c-0.078,0-0.157,0-0.235,0c-0.101,0.525-1.158,0.095-1.645,0.235c0,0.235,0,0.47,0,0.705
			c0.3,1.09,0.98,2.853,0.47,3.993c-0.336,1.938-3.496,1.194-3.054-0.939c-0.563,0.142-1.072,0.338-1.879,0.235
			c-0.013,0.143-0.035,0.278-0.235,0.235c-0.012,1.946-0.181,3.734-0.47,5.403c0.025,1.306-0.187,2.849,0.47,3.524
			C235.348,210.418,237.366,205.303,238.434,203.516z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#AE8377" d="M348.137,249.558c0.552-0.231,1.154-0.412,1.88-0.47
			c1.729-0.932,3.251-2.073,5.168-2.819c0.199,0.043,0.222-0.091,0.234-0.235c0.716,0.01,0.914-0.496,1.409-0.705
			c0.915-0.26,1.7-0.65,2.35-1.175c0.739,0.113,0.918-0.334,1.409-0.47c0.916-0.337,1.944-0.561,2.584-1.174
			c1.07-0.105,1.745-0.604,2.584-0.94c0.979-0.353,1.996-0.667,2.584-1.409c0.079,0,0.157,0,0.235,0
			c0.769-0.719,1.793-1.183,2.349-2.114c0-0.079,0-0.157,0-0.235c1.383-1.123,2.337-2.675,3.289-4.229
			c1.474-2.677,3.342-4.959,3.759-8.692c0.275-0.038,0.198-0.428,0.234-0.705c0-1.409,0-2.819,0-4.229c-0.078,0-0.156,0-0.234,0
			c0.025-1.279-0.358-2.147-0.705-3.054c-0.53-1.662-1.372-3.013-3.054-3.523c-1.125-0.355-2.974-0.732-4.229-0.235
			c-5.04,0.597-9.173,2.103-13.155,3.758c-1.449,0.856-2.747-1.312-1.879-2.819c-0.078,0-0.156,0-0.234,0c-0.079,0-0.157,0-0.235,0
			c0.039,0.509-0.833,0.106-1.175,0.235c-0.418,0.679-0.422,1.77-0.939,2.349c-0.21,2.217-0.602,4.253-1.175,6.108
			c-0.198,1.289-0.274,2.701-0.704,3.758c0,0.078,0,0.157,0,0.235c-0.459,2.674-1.021,5.243-1.645,7.752
			c0.042,1.139-0.187,2.006-0.47,2.819c-0.078,1.332-0.295,2.524-0.705,3.524c-0.375,3.227-0.991,6.213-1.879,8.927
			C346.523,250.411,347.354,249.62,348.137,249.558z M358.183,222.551c0.296,0.201,0.573,0.437,0.798,0.623
			C358.755,222.988,358.48,222.754,358.183,222.551L358.183,222.551z M359.232,223.374c-0.018-0.013-0.035-0.025-0.054-0.039
			C359.198,223.35,359.214,223.36,359.232,223.374z M357.248,222.145c-0.063-0.007-0.125-0.011-0.186-0.006
			C357.123,222.134,357.186,222.138,357.248,222.145z M357.766,222.306c-0.077-0.038-0.154-0.071-0.232-0.097
			C357.611,222.235,357.688,222.269,357.766,222.306z M357.508,222.202c-0.071-0.023-0.143-0.042-0.212-0.053
			C357.365,222.16,357.437,222.178,357.508,222.202z M356.996,222.152c-0.054,0.009-0.106,0.019-0.157,0.039
			C356.89,222.171,356.942,222.162,356.996,222.152z M356.756,222.238c-0.043,0.023-0.087,0.045-0.127,0.079
			C356.669,222.283,356.713,222.262,356.756,222.238z M356.533,222.419c-0.033,0.035-0.066,0.065-0.098,0.108
			C356.467,222.484,356.5,222.454,356.533,222.419z M356.33,222.716c-0.021,0.041-0.044,0.072-0.063,0.118
			C356.286,222.788,356.31,222.756,356.33,222.716z M351.66,236.638c0.079,0,0.157,0,0.235,0c0.097-0.295,0.303-0.48,0.705-0.47
			c0-0.078,0-0.157,0-0.235c1.028,0.01,1.449-0.586,2.114-0.94c1.1-0.779,1.971-1.787,2.349-3.289
			c0.472-1.172,0.904-2.385,0.939-3.993c0.505-0.749,0.65-1.855,1.175-2.584c0-0.078,0-0.156,0-0.235
			c0.913-0.653,1.324-1.807,2.349-2.349c0.683-0.649,1.546-1.117,2.114-1.879c1.417-1.167,3.093-2.075,4.229-3.524
			c1.622-0.805,2.535-2.32,5.403-1.879c0.918,0.57,1.829,1.146,2.584,1.879c0.577,1.38,1.112,2.803,1.409,4.463
			c0.008,1.261,0.179,2.684-0.705,3.054c-0.078,0-0.156,0-0.234,0c-0.856-0.24-1.295-0.897-1.409-1.879
			c0.005-0.554-0.405-0.692-0.235-1.41c-0.61-1.034-0.759-2.529-1.879-3.054c-0.235,0-0.47,0-0.705,0
			c-1.092,0.709-2.089,1.512-3.054,2.349c0.194,0.677,0.918,2.044,0.235,2.819c0,0.313,0,0.626,0,0.939
			c-0.155,2.038,0.311,4.696-0.235,6.343c0,0.235,0,0.47,0,0.705c0.103,0.808-0.093,1.317-0.235,1.879
			c0.062,0.688-0.53,0.722-0.47,1.409c-1.911,2.866-4.415,5.138-7.986,6.343c-0.545-0.075-0.902,0.038-1.175,0.235
			c-0.156,0-0.313,0-0.47,0c-1.488,0-2.976,0-4.464,0c-1.038-0.371-1.07-1.748-1.879-2.349
			C351.2,238.872,350.993,237.323,351.66,236.638z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#AE8377" d="M252.293,253.786c-0.39,0.394-1.379,0.187-2.114,0.235
			c-0.868,0.698-3.36-0.228-4.228,0.47c1.019,1.173,1.459,2.926,2.584,3.994c1.087-2.123,3.188-3.232,4.933-4.698
			C253.077,253.786,252.685,253.786,252.293,253.786z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#AE8377" d="M248.444,250.76c1.235-0.057,2.507-0.078,3.849-0.028
			c0.636-0.39,2.888-0.39,3.524,0c0.078,0,0.156,0,0.235,0c1.352-0.064,1.073,2.063,0.235,2.349
			c0.124,0.189,0.245,0.381,0.235,0.705c0,0.079,0,0.157,0,0.235c1.392,2.322,3.208,3.509,5.403,6.108
			c1.667,1.973,2.67,4.75,5.168,4.463c0.209-0.183,0.531-0.253,0.94-0.235c0.968-0.677,2.276-1.013,3.289-1.645
			c0.569-0.293,1.141-0.582,1.879-0.705c-0.04-1.546,0.824-1.706,0.705-2.819c0.044-0.661,0.409-1,1.174-0.939
			c0.197,0.272,0.31,0.63,0.235,1.174c0.393,0.39,0.187,1.379,0.235,2.114c1.293,1.213,1.943,3.069,2.819,4.698
			c1.002-2.521,0.105-6.942,1.409-9.161c0.73-0.209,1.095-0.785,1.879-0.94c0,0.235,0,0.47,0,0.705
			c1.947-0.248,3.259-3.16,5.638-3.758c0.943,0.545,1.455,1.521,2.35,2.114c-0.018-0.879,0.099-1.624,0.47-2.114
			c0-0.078,0-0.157,0-0.235c0.009-1.009,0.306-1.729,0.704-2.349c0.325-1.79,0.831-3.398,1.41-4.933
			c0.167-1.086,0.474-2.031,0.939-2.819c0.616-2.595,1.537-4.884,2.349-7.282c0.494-1.934,0.994-3.861,1.88-5.403
			c0.373-1.742,0.932-3.297,1.409-4.934c0.119-1.055,1.478-0.871,2.35-1.174c0.755-0.263,1.393-0.643,2.349-0.705
			c0.524-0.572,1.533-0.659,2.35-0.94c0.209-0.182,0.53-0.252,0.939-0.235c0.618-0.556,1.576-0.773,2.584-0.939
			c0.605-0.413,1.697-0.339,2.114-0.94c0.49,0.1,0.622-0.161,0.939-0.235c1.024-0.307,2.077-0.584,2.818-1.174
			c1.017-0.001,1.491-0.545,2.584-0.47c0.052-0.183,0.197-0.273,0.471-0.235c1.096,0,2.192,0,3.288,0c0.392,0,0.783,0,1.175,0
			c0,0.078,0,0.156,0,0.235c0.872-0.146,2.418,0.382,2.584-0.47c1.233-1.429,2.564-2.76,3.993-3.993
			c1.4-1.341,2.903-2.579,4.229-3.994c0.885-0.055,0.995-0.885,1.88-0.939c0.013-0.144,0.034-0.278,0.234-0.235
			c2.523-0.313,0.932,2.1,0,2.349c0,0.078,0,0.156,0,0.235c-0.078,0-0.156,0-0.234,0c-2.138,2.442-6.17,4.53-7.987,6.813
			c-0.109,0.136,0.38,0.255,0.47,0.235c0.392,0,0.783,0,1.175,0c0.25,0.533,1.477,0.089,2.114,0.235c0.939,0,1.879,0,2.818,0
			c0.392,0,0.783,0,1.175,0c0,0.078,0,0.156,0,0.235c0.392,0,0.783,0,1.175,0s0.783,0,1.175,0c0.626,0,1.252,0,1.879,0
			c0.709-0.152,0.655-1.067,1.175-1.41c0.039-0.666,0.431-0.979,0.47-1.644c0-0.079,0-0.157,0-0.235
			c0.529-0.175,0.092-1.318,0.234-1.879c0-0.235,0-0.47,0-0.705c0.325-2.65,0.978-4.973,0.939-7.987
			c0.539-0.479,0.086-1.951,0.235-2.819c-0.166-0.871,0.401-1.008,0.235-1.879c0.237-5.713,1.594-10.308,1.644-16.208
			c0.52-0.029,0.101-0.997,0.235-1.41c0-1.253,0-2.506,0-3.758c-0.618-0.243-0.791-0.932-1.409-1.175c-0.079,0-0.157,0-0.235,0
			c-0.045-0.19-0.235-0.234-0.234-0.47c-1.586-1.39-3.074-2.877-4.464-4.463c-0.668-0.428-0.856-1.335-1.645-1.645
			c0-0.078,0-0.156,0-0.235c-1.285-1.534-2.334-3.304-3.758-4.698c0-0.078,0-0.156,0-0.235c-0.634-0.698-0.894-1.77-1.645-2.349
			c-0.497-1.618-1.394-2.835-1.645-4.698c-0.728-2.013-1.14-4.341-1.175-7.047c-0.381-1.106-0.479-2.497-0.47-3.993
			c-0.229-0.006-0.183-0.288-0.235-0.47c0-0.47,0-0.94,0-1.41c-0.471-0.467,0.074-1.118,0.235-1.645
			c-0.061-0.488-1.032-0.064-1.175-0.47c-6.639-0.799-13.152-1.725-19.497-2.819c-1.092-0.083-2.048-0.301-2.819-0.705
			c-2.287-0.296-4.37-0.797-6.343-1.41c-1.024-0.071-1.948-0.244-2.584-0.705c-0.544,0.075-0.901-0.038-1.174-0.235
			c-0.927-0.169-1.901-0.292-2.584-0.705c-1.704-0.098-3.376-0.226-4.934-0.47c-2.271,0-4.542,0-6.813,0
			c-3.364,0.16-6.213,0.833-9.396,1.175c-0.735,0.361-1.736,0.456-2.819,0.47c-2.584,0.626-5.435,0.986-8.222,1.41
			c-4.452,3.406-8.588-0.218-8.222-5.638c0-0.078,0-0.156,0-0.235c-0.525-0.101-0.095-1.158-0.235-1.644
			c-1.228,1.042-2.11,2.431-2.114,4.698c-3.292,3.833-5.684,8.567-7.517,13.86c0.099,0.491-0.161,0.622-0.235,0.939
			c-0.063,0.406-0.152,0.786-0.254,1.153L239,153.479l-6.452,14.354l-1.048,11.941l19.896-4.062
			c0.732,0.769,1.412,1.591,1.838,2.667c0.226,0.561,0.775,1.505,0.235,2.114c-0.43,0.51,0.044,1.924-0.47,2.349
			c-0.127,2.456-0.773,4.395-1.409,6.343c-0.098,0.999-0.411,1.782-0.94,2.349c0.066,0.379-0.222,0.404-0.235,0.705
			c-1.896,3.585-4.796,6.166-7.047,9.396c0,0.078,0,0.157,0,0.235c-1.045,1.852-1.758,4.037-3.054,5.638
			c2.581,2.51,5.082,5.098,7.517,7.752c0.771,0.09,1.036,0.687,1.645,0.94c0.708,0.31,1.277,0.759,2.349,0.705
			c0.043-0.2-0.092-0.222-0.235-0.235c-0.148-1.604-0.279-2.656,0.47-3.759c2.301-1.168,4.111,0.864,6.343,1.175
			c0.857,0.708,2.064,1.067,3.054,1.645c0.078,0,0.157,0,0.235,0c0.646,0.764,1.691,1.128,2.584,1.644c0.079,0,0.157,0,0.235,0
			c0.635,0.853,1.925,1.051,2.819,1.645c0.079,0,0.157,0,0.235,0c0.104,0.209,0.207,0.42,0.47,0.47
			c0.533-0.25,0.089-1.477,0.235-2.114c0.013-0.143,0.035-0.278,0.235-0.235c-0.066-0.614,0.085-1.01,0.705-0.939
			c0.447-0.44,0.59-0.026,1.175,0c0.934,1.82,0.48,5.621-0.235,7.282c-0.741,0.825-1.439,1.693-3.054,1.644
			c-3.509,0.876-7.004,1.766-10.336,2.819c-0.375,0.291-0.805,0.527-1.248,0.752l-20.674-1.433L238,246.151l1.954,5.182l1.798-0.122
			c-0.007,0.078-0.024,0.146-0.03,0.225c0.392,0,0.783,0,1.174,0c1.182-0.258,2.445-0.434,3.762-0.556L248.444,250.76z
			 M283.771,200.461c-0.467,0.003-0.299,0.641-0.939,0.47c-0.079,0-0.157,0-0.235,0c-0.2,0.043-0.222-0.092-0.235-0.235
			c-0.414-0.212-0.727-0.525-0.939-0.939c-0.403-0.302-0.924-0.486-0.939-1.174c-1.156-0.802-1.828-2.087-2.349-3.524
			c-0.123-0.348-0.348-0.592-0.47-0.939c-1.155-2.369-2.472-4.576-3.054-7.517c-0.078,0-0.156,0-0.235,0c0-0.079,0-0.157,0-0.235
			c0-0.783,0-1.566,0-2.349c0.053-0.183,0.006-0.463,0.235-0.47c0-0.548,0-1.096,0-1.644c0-0.157,0-0.313,0-0.47
			c0.032-0.751-0.231-1.798,0.705-1.645c0.339,0.757,0.222,1.97,0.235,3.054c0.262,0.678,0.422,1.457,0.47,2.349
			c1.199,3.108,2.285,6.329,3.993,8.927c0,0.078,0,0.156,0,0.235C281.318,196.337,283.128,197.816,283.771,200.461z
			 M276.724,175.561c0.254-0.227,0.541-0.443,0.84-0.653C277.265,175.118,276.979,175.334,276.724,175.561z M295.517,199.992
			c-0.699-0.012-1.191,0.691-1.644,0.235c-1.025-0.071-1.948-0.244-2.584-0.705c-0.491,0.1-0.622-0.161-0.94-0.235
			c-3.739-1.194-6.56-3.306-8.222-6.578c-0.323-0.303-0.232-1.02-0.235-1.644c0.317-0.231,0.47-0.627,0.705-0.94
			c3.567,0.896,8.227,0.7,10.335,3.054c-1.141,2.384-5.113,0.176-7.282,0c0.449,1.98,3.834,3.055,5.873,4.229
			C293.149,197.974,295.704,197.612,295.517,199.992z M307.732,201.871c-1.072,1.032-4.968,0.979-6.343,0.235
			c0.215-0.412,0.437-0.816,1.175-0.705c0.156,0,0.313,0,0.47,0c1.317-0.484,3.572-0.029,4.698-0.705c0.078,0,0.156,0,0.234,0
			c0.656-0.44,2.005-0.188,2.35-0.939c0.718-0.169,0.856,0.24,1.409,0.235c0,0.079,0,0.157,0,0.235
			c-0.703,1.098-2.001,1.601-3.759,1.645C307.889,201.871,307.811,201.871,307.732,201.871z M326.724,146.924
			c-0.078,0.082-0.15,0.168-0.228,0.251C326.573,147.092,326.646,147.005,326.724,146.924z M325.803,147.947
			c-0.076,0.089-0.147,0.183-0.222,0.273C325.655,148.13,325.727,148.036,325.803,147.947z M324.93,149.028
			c-0.062,0.08-0.119,0.164-0.181,0.245C324.811,149.192,324.869,149.108,324.93,149.028z M324.1,150.163
			c-0.043,0.061-0.084,0.124-0.126,0.186C324.016,150.287,324.057,150.224,324.1,150.163z M323.304,151.349
			c-0.021,0.033-0.042,0.067-0.063,0.1C323.262,151.415,323.282,151.381,323.304,151.349z M321.153,155.004
			c-0.013,0.024-0.025,0.049-0.039,0.073l-0.001-0.001C321.127,155.052,321.141,155.028,321.153,155.004z M288.406,174.204
			l3.144-0.419c0.092,0.038,0.165,0.082,0.209,0.133c0.078,0,0.156,0,0.234,0c1.278-0.025,2.298,0.208,3.054,0.705
			c6.356,1.396,12.077,3.427,17.148,6.107c3.741,2.054,5.97,5.62,8.223,9.162c0.863,1.329,2.597,1.788,3.993,2.584
			c0.911,0.341,1.877,0.629,2.584,1.175c1.014,0.082,1.563,0.63,1.645,1.644c-0.691,1.407-2.462,0.646-3.524,0
			c-1.646-0.546-2.947-1.437-4.463-2.114c0.309,2.199,3.204,3.842,4.934,5.403c-1.19,1.198-2.735-0.497-3.524-1.175
			c-0.829-0.11-1.285-0.593-1.879-0.939c-0.926-0.406-1.536-1.126-2.35-1.645c-0.068-0.479-0.635-0.46-0.704-0.939
			c-0.546-0.159-0.629-0.781-1.175-0.94c0.105-1.482-1.572-0.271-2.349-0.47c-1.722-0.037-2.652,0.356-3.994-0.235
			c-0.714-0.147-2.023,0.301-2.349-0.235c-2.004,0.186-1.183-2.39,0.704-1.645c0-0.078,0-0.156,0-0.235
			c-8.303,0.728-16.205-0.821-22.786-2.584c0.335-1.461,1.78-2.758,3.054-4.463c0.818-1.096,1.995-3.472,3.439-4.097
			c-0.677,0.233-1.283,0.634-1.795,1.278c-0.471,0.391-1.092,0.631-1.174,1.41c-0.789,1.247-1.525,2.546-2.584,3.523
			c-0.289,0.103-0.482,0.301-0.47,0.705c-0.146,0.715-0.522,1.201-1.41,1.175c-0.909,0.422-1.565,1.097-3.054,0.939
			c-0.647-0.415-0.719-2.227,0.235-2.349c0.189-0.124,0.381-0.245,0.705-0.235c1.238-0.544,0.34-1.293-0.235-1.879
			c0-0.078,0-0.156,0-0.235C282.603,179.709,285.003,175.97,288.406,174.204z M273.376,174.188c-0.584,0.41-1.315,0.674-1.912,1.071
			C272.06,174.86,272.792,174.597,273.376,174.188L273.376,174.188z M271.404,175.295c-0.208,0.144-0.396,0.307-0.553,0.501
			C271.008,175.602,271.197,175.439,271.404,175.295z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#AE8377" d="M70.943,343.052c-0.118-1.604-0.764-2.682-1.174-3.994
			c0.075-0.545-0.038-0.902-0.235-1.174c-0.605,0.256-1.129,0.593-1.409,1.174c-0.84,1.195-1.065,3.007-1.41,4.698
			c0.218,1.919-2.397,0.535-2.349,1.174c-0.216,1.391,0.402,1.947,0.47,3.054c0.793,0.381,1.402,0.947,2.114,1.409
			c0.626,0,1.253,0,1.879,0c1.017-0.392,1.417-1.401,2.114-2.114c-0.139-1,0.169-1.554,0.235-2.349
			C71.035,344.37,71.473,343.227,70.943,343.052z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#AE8377" d="M96.489,353.407c-0.028-0.375-0.02-0.77,0.059-1.194
			c0-0.861,0-1.723,0-2.584c-0.665,0.039,0.195-1.448-0.47-1.409c0-0.079,0-0.157,0-0.235c0-0.392,0-0.783,0-1.175
			c-0.452-1.27-0.718-2.727-0.939-4.229c-0.638,0.168,0.168-1.107-0.47-0.939c0-0.392,0-0.783,0-1.174
			c-0.51,0.04-0.106-0.833-0.235-1.175c-0.536-0.326-0.088-1.635-0.235-2.349c-0.815-2.346-0.009-5.104-0.705-7.282
			c0-0.47,0-0.939,0-1.409c-0.374-0.722-0.27-1.923-0.705-2.584c-0.197-0.273-0.31-0.63-0.235-1.175
			c-0.338-0.053-0.082-0.701-0.47-0.705c0-0.079,0-0.157,0-0.235c0-0.313,0-0.626,0-0.94c-0.679-0.104,0.208-1.775-0.47-1.879
			c0-0.47,0-0.94,0-1.41c-0.228-0.006-0.182-0.288-0.235-0.47c0-1.175,0-2.349,0-3.524c0-1.331,0-2.662,0-3.993
			c-0.078,0-0.156,0-0.235,0c0-0.47,0-0.94,0-1.41c0-0.705,0-1.409,0-2.114c0.018-0.878-0.099-1.624-0.47-2.114
			c0-0.078,0-0.156,0-0.235c-0.068-1.263-0.247-2.416-0.705-3.289c-0.112-1.532-0.777-2.512-1.41-3.523
			c-0.159-0.546-0.781-0.629-0.939-1.175c-0.481,0.38-1.54,0.183-1.879,0.705c-0.491-0.099-0.622,0.161-0.939,0.235
			c-0.189,0.125-0.381,0.245-0.705,0.235c-1.847,0.971-2.192,3.445-3.523,4.933c-2.367,2.881-0.865,7.162-0.47,10.806
			c0.575,5.301,1.008,10.689,2.114,15.504c0,0.861,0,1.723,0,2.584c0.37,1.509,0.422,3.336,0.705,4.933
			c0.505,1.14,1.318,1.971,1.645,3.289c1.14,3.401,1.147,7.936,2.114,11.51c0.307,1.444,0.315,3.079,0,4.463
			c-0.069,2.671-0.751,4.73-1.879,6.343c-0.727,1.466-1.485,2.9-2.349,4.229c0.066,0.379-0.222,0.404-0.235,0.705
			c-0.261,0.914-0.65,1.699-1.175,2.349c0,0.078,0,0.156,0,0.235c-0.583,1.375-1.05,2.865-1.879,3.994
			c-1.412,2.972-2.55,6.22-3.758,9.396c-0.532,1.583-1.014,3.214-1.879,4.463c-1.509,4.521-3.479,8.579-4.933,13.155
			c-0.831,1.205-1.611,2.461-2.819,3.289c-8.116,0.568-15.896,6.797-25.135,4.698c-4.367,1.476-9.204,3.476-14.33,2.819
			c0.168,0.381-1.108,0.381-0.94,0c0.485-0.533,1.202-0.834,1.879-1.174c3.242-2.161,7.319-3.487,11.041-5.168
			c-2.302-5.346-4.312-12.255-6.578-19.262c-2.184-6.754-5.322-13.871-5.638-20.203c-0.176-3.514,0.85-6.336,3.289-7.517
			c-0.681-1.824,1.137-3.057,1.645-4.463c0.666-1.213,1.148-2.61,2.114-3.523c1.281-2.869,2.934-5.366,4.463-7.987
			c0.094-0.611,0.701-0.708,0.705-1.41c-0.235,0-0.47,0-0.705,0c-7.093-1.44,1.038-5.179,4.698-4.933
			c1.203-0.99,3.036-1.349,4.229-2.349c0-0.078,0-0.157,0-0.235c0.164-1.693,3.096-1.425,2.584,0.705
			c1.52,4.275,2.195,9.394,4.463,12.92c1.263,1.634,1.842,3.952,3.523,5.168c1.49,0.546,3.4,0.672,4.463,1.644
			c0.417-0.444,0.171-1.551,0.235-2.349c-0.143-0.562,0.295-1.704-0.235-1.879c0.033-1.756-0.261-3.185-0.705-4.463
			c-0.017-0.923-0.114-1.766-0.47-2.349c0-0.079,0-0.157,0-0.235c-0.017-0.923-0.114-1.766-0.47-2.349
			c-0.262-2.635-0.937-4.857-1.644-7.047c-0.251-1.236-0.533-2.443-1.175-3.289c0-0.079,0-0.157,0-0.235
			c0.062-0.688-0.531-0.722-0.47-1.41c-0.693-0.325-0.951-1.085-1.645-1.41c-0.677-0.967-1.789-1.5-2.584-2.349
			c-9.656,1.542-17.554,4.841-27.484,6.108c-0.481,0.39-2.572,0.39-3.054,0c-1.154,0.608-1.869-0.104-1.645-1.175
			c0.325-1.551,6.218-1.204,7.987-1.879c0.966-0.369,1.822-1.36,3.054-1.879c2.266-0.957,5.988-1.302,9.162-2.114
			c4.251-1.088,6.937-2.17,10.336-2.349c0.25-0.533,1.477-0.089,2.114-0.235c0.827-1.679,1.934-3.077,3.523-3.994
			c0.209-0.182,0.53-0.252,0.939-0.235c1.087,1.489-0.026,4.184-0.705,5.403c-0.225,0.48-0.48,0.93-0.705,1.41
			c0.569,0.762,1.432,1.23,2.114,1.879c-0.043,0.2,0.091,0.222,0.235,0.235c1.086,1.187,3.731,2.06,2.819,4.463
			c0.479,4.532,1.353,8.67,2.584,12.45c0.255,0.058,0.159,0.467,0.47,0.47c0.135-0.413-0.284-1.38,0.235-1.41
			c-0.124-1.22,0.31-1.882,0.47-2.819c0.272-1.372,1.146-2.144,1.879-3.054c0.079,0,0.157,0,0.235,0
			c0.007-0.229,0.288-0.182,0.47-0.235c-0.072-0.542,0.678-0.261,0.705-0.705c0.313,0,0.626,0,0.939,0
			c0.073-0.935-0.542-1.18-0.47-2.114c-0.516-1.128-0.704-2.585-1.174-3.759c-0.083-1.091-0.302-2.047-0.705-2.819
			c-0.333-2.564-1.014-4.781-1.644-7.047c-0.322-1.949-0.696-3.845-1.41-5.403c-0.176-1.625-0.7-2.902-1.174-4.229
			c-0.124-0.738-0.412-1.311-0.705-1.879c-0.993-2.325-4.577-1.036-6.813-0.705c-2.458,1.145-5.006,2.198-7.752,3.054
			c-4.571,2.085-9.667,3.644-15.974,3.994c-0.636,0.39-2.888,0.39-3.524,0c-1.23,0.021-1.409-0.39-1.644-0.939
			c-1.165-2.727,4.782-2.483,7.047-2.819c3.453-0.511,6.136-1.439,8.222-2.114c7.103-2.296,10.513-4.74,16.914-5.403
			c0.253-0.389,2.096-0.389,2.349,0c1.523,0.349,1.128-1.22,1.644-1.879c0.242-0.933,0.594-1.756,1.879-1.645
			c0,0.078,0,0.157,0,0.235c0.157,0,0.313,0,0.47,0c0.828,1.601-0.299,3.096-0.939,3.994c0.263,1.538,1.69,1.912,1.879,3.523
			c2.634,6.997,3.883,15.379,6.577,22.316c0.157,0,0.313,0,0.47,0c-0.151-3.518,1.16-5.574,2.819-7.282
			c0.338-0.131,0.573-0.366,0.705-0.705c0.379,0.066,0.404-0.222,0.705-0.235c0.491,0.1,0.622-0.161,0.94-0.235
			c-0.169-0.927-0.292-1.901-0.705-2.584c0-0.078,0-0.157,0-0.235c-0.071-1.025-0.245-1.948-0.705-2.584c0-0.079,0-0.157,0-0.235
			c-0.188-1.144-0.411-2.251-0.939-3.054c-0.045-1.364-0.603-2.216-0.94-3.289c-0.731-2.636-1.461-5.273-2.584-7.517
			c0-0.078,0-0.157,0-0.235c-0.515-1.286-0.973-2.628-1.879-3.524c0-0.078,0-0.156,0-0.235c-0.53-1.114-1.099-2.19-1.879-3.054
			c-0.238-1.407-1.3-1.989-2.584-2.349c-0.705,0-1.41,0-2.114,0c-1.045-0.184-1.41,0.313-2.349,0.235
			c-2.547,1.133-5.239,2.122-7.987,3.054c-0.761,0.336-1.602,0.591-2.584,0.705c-0.683,0.413-1.657,0.535-2.584,0.705
			c-3.515,1.132-20.497,1.826-14.564-3.289c0.253-0.389,2.096-0.389,2.349,0c1.174,0,2.349,0,3.523,0c0.705,0,1.41,0,2.114,0
			c1.829-1.068,2.133-3.661,3.994-4.698c-0.557-3.202-1.174-6.343-1.175-10.101c-0.538-0.402-0.086-1.793-0.235-2.584
			c0.2-3.355-1.433-6.865-2.349-10.336c-3.332-12.626-6.107-26.336-10.101-38.76c-0.774-0.396-1.502-0.139-2.349,0
			c-0.996,0.649-2.014,1.275-3.054,1.879c1.214,7.634,3.473,14.223,3.758,22.787c1.049,1.535,0.191,4.977,0.47,7.282
			c0,1.488,0,2.976,0,4.463c0.436,1.444,0.147,3.612,0.705,4.933c0.437,5.514,1.839,10.063,1.879,15.974
			c0.391,0.636,0.391,2.888,0,3.523c0.084,1.18-0.062,2.13-0.47,2.819c-2.406,2.998-5.185,5.621-7.752,8.457
			c-0.435,1.581,0.155,2.783,0,4.698c0.397,1.067,0.303,3.694,0.235,4.463c-0.078,0-0.157,0-0.235,0
			c-0.093,1.111,0.075,1.96,0.235,2.819c0.53,0.175,0.092,1.317,0.235,1.879c-0.535,0.065-0.883-0.057-0.939-0.47
			c-6.709,1.071-4.072,12.674-4.698,17.854c0.653,1.364-1.385,2.056-1.879,3.054c-3.153,3.823-0.838,11.686,0,16.444
			c0.752,0.893,0.866,2.422,1.175,3.758c4.545,16.874-2.958,33.128-3.054,48.861c-0.03,4.995,1.611,9.481,1.879,14.564
			c0.408,1.393,0.704,2.898,0.94,4.463c0.919,0.726,0.053,3.236,1.409,3.524c1.566,0,3.132,0,4.698,0c0.392,0,0.783,0,1.175,0
			c0.402-0.538,1.793-0.086,2.584-0.235c0.156,0,0.313,0,0.47,0c0.402-0.537,1.793-0.086,2.584-0.234
			c5.453-0.577,10.299-1.76,15.974-2.114c0.326-0.536,1.635-0.088,2.349-0.235c4.08-0.462,7.656-1.427,10.336-3.289
			c0.72-0.297,1.236-0.8,1.879-1.174c0.461-0.4,1.174-0.549,1.41-1.175c-0.083-0.856-0.415-1.465-0.705-2.114
			c0-0.078,0-0.157,0-0.235c0-0.861,0-1.723,0-2.584c0.014-1.346-0.672-1.991-0.47-3.524c0.637-0.303,0.835-1.044,1.879-0.939
			c1.112-1.158,1.941-2.601,3.759-3.054c0.649-0.291,1.193-0.686,2.114-0.705c1.038-0.45,1.412-1.563,2.349-2.114
			c0.078,0,0.156,0,0.235,0c4.212-2.601,7.046-6.579,10.101-10.336c0-0.078,0-0.156,0-0.235c0.998-1.43,1.472-3.383,2.349-4.933
			c0.318-2.187,0.873-4.138,1.644-5.873c0.442-1.437,0.833-2.926,1.645-3.994c0-0.078,0-0.156,0-0.235
			c0.212-0.806,0.518-1.518,0.939-2.114c-0.043-0.2,0.092-0.221,0.235-0.235c2.075-4.346,4.201-8.641,5.873-13.39
			c-0.043-0.2,0.092-0.222,0.235-0.235c0.033-0.906,0.794-1.085,0.705-2.114c1.448-1.136,0.591-2.333,0.267-3.988
			C96.551,353.871,96.521,353.641,96.489,353.407z M58.728,343.992c0.062-0.073,0.134-0.106,0.205-0.146c0.001,0,0.002,0,0.004,0
			c0.222-0.036,0.459-0.038,0.711-0.012c0,0,0.001,0,0.001,0c0.594,0.272,1.058,1.087,0.489,1.568
			c-0.637,1.085-0.944,2.501-1.645,3.523c-2.014,0.253-2.162-2.367-1.41-3.523C57.8,345.1,57.712,343.994,58.728,343.992z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#AE8377" d="M77.051,324.494c-0.123,0.348-0.348,0.592-0.47,0.939
			c-0.078,0-0.156,0-0.235,0c-0.032,0.438-0.154,0.785-0.47,0.94c-1.932,2.226,1.102,6.929-1.174,8.457
			c-1.679-0.279-1.931-1.984-1.879-3.993c-0.255-0.058-0.16-0.467-0.47-0.47c0,0.391,0,0.783,0,1.174
			c0.544,1.335,0.051,3.707,1.174,4.463c0.708,0.466,1.759,0.59,2.114,1.409c0.913,0.153,2.766,0.614,3.289-0.235
			c0.75-0.268,1.1-0.936,1.175-1.879c0-0.078,0-0.156,0-0.235c0.2,0.043,0.222-0.091,0.235-0.235c0-0.783,0-1.566,0-2.349
			c0.074-1.327-0.062-2.444-0.47-3.289c0.07-1.401-0.853-1.81-0.94-3.054c-0.326-0.145-0.035-0.904-0.47-0.94
			c0.1-0.491-0.161-0.622-0.235-0.939C77.561,324.065,77.617,324.59,77.051,324.494z"/>
			</g>
			<g id="black">
				<path fillRule="evenodd" clipRule="evenodd" d="M371.158,258.25c0-0.783,0-1.566,0-2.349
			C370.77,256.154,370.77,257.996,371.158,258.25z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M284.241,187.072c0.888,0.026,1.263-0.459,1.41-1.175
			c-0.013-0.404,0.181-0.602,0.47-0.705c1.059-0.977,1.795-2.277,2.584-3.523c0.083-0.779,0.704-1.019,1.174-1.41
			c0.512-0.645,1.119-1.045,1.798-1.278c0.307-0.105,0.628-0.179,0.964-0.217c-0.001,0-0.001,0-0.002,0c0.001,0,0.002,0,0.004,0
			c-0.001,0-0.001,0-0.002,0c2.49-0.279,5.75,1.15,9.219,2.2c5.524,1.672,9.563,3.164,11.98,6.578
			c-0.016,0.885,1.996,1.958,0.939,2.349c-0.078,0-0.156,0-0.234,0c-1.881,0.155-4.383-0.311-5.873,0.235c-0.234,0-0.47,0-0.705,0
			c0,0.079,0,0.157,0,0.235c-1.887-0.745-2.708,1.83-0.704,1.645c0.325,0.536,1.635,0.087,2.349,0.235
			c1.342,0.591,2.272,0.198,3.994,0.235c0.776,0.198,2.454-1.012,2.349,0.47c0.546,0.159,0.629,0.781,1.175,0.94
			c0.069,0.479,0.636,0.46,0.704,0.939c0.813,0.519,1.424,1.239,2.35,1.645c0.594,0.346,1.05,0.83,1.879,0.939
			c0.789,0.678,2.334,2.373,3.524,1.175c-1.729-1.562-4.625-3.204-4.934-5.403c1.516,0.677,2.816,1.568,4.463,2.114
			c1.063,0.646,2.833,1.407,3.524,0c-0.082-1.014-0.631-1.562-1.645-1.644c-0.707-0.546-1.673-0.833-2.584-1.175
			c-1.396-0.796-3.13-1.255-3.993-2.584c-2.253-3.542-4.481-7.107-8.223-9.162c-5.071-2.681-10.792-4.711-17.148-6.107
			c-0.756-0.497-1.775-0.73-3.054-0.705c-0.078,0-0.156,0-0.234,0c-0.329-0.39-2.256-0.39-2.584,0c-0.079,0-0.157,0-0.235,0
			c-0.256,0.102-0.492,0.225-0.72,0.356c-0.154,0.085-0.302,0.176-0.446,0.271c0,0-0.001,0-0.001,0c0,0-0.001,0.001-0.002,0.001
			c0,0,0,0,0.001-0.001c-2.678,1.644-4.647,4.522-5.548,7.477c0.045-0.04,0.082-0.088,0.138-0.118
			c-0.056,0.029-0.093,0.078-0.138,0.118v0c0,0,0,0.001-0.001,0.001c-0.071,0.063-0.13,0.138-0.177,0.226
			c0,0.001,0,0.001-0.001,0.002c-0.045,0.086-0.083,0.18-0.107,0.287c-0.004,0.017-0.005,0.037-0.009,0.054
			c-0.018,0.095-0.036,0.19-0.041,0.298c-0.007,0.132-0.006,0.273,0.004,0.423c0,0.079,0,0.157,0,0.235
			c0.575,0.586,1.473,1.335,0.235,1.879c-0.323-0.01-0.516,0.111-0.705,0.235c-0.954,0.123-0.882,1.935-0.235,2.349
			C282.676,188.169,283.333,187.494,284.241,187.072z M324.597,198.143C324.597,198.143,324.597,198.143,324.597,198.143
			c-0.007-0.004-0.013-0.009-0.02-0.014C324.584,198.134,324.591,198.139,324.597,198.143z M323.99,197.524
			c-0.483-0.448-1.003-0.86-1.509-1.286C322.986,196.665,323.508,197.075,323.99,197.524z M322.451,196.539
			c-1.088-0.915-2.001-1.979-2.208-3.268C320.45,194.56,321.366,195.625,322.451,196.539z M320.2,192.995
			c0.079,0.323,0.186,0.62,0.313,0.895C320.386,193.614,320.279,193.318,320.2,192.995L320.2,192.995z"/>
				<polygon fillRule="evenodd" clipRule="evenodd" points="287.773,174.544 287.771,174.545 287.772,174.544 		" />
				<path fillRule="evenodd" clipRule="evenodd" d="M310.316,199.757c-0.345,0.752-1.693,0.5-2.35,0.939c-0.078,0-0.156,0-0.234,0
			c-1.126,0.676-3.381,0.221-4.698,0.705c-0.156,0-0.313,0-0.47,0c-0.738-0.111-0.96,0.293-1.175,0.705
			c1.375,0.744,5.271,0.797,6.343-0.235c0.078,0,0.156,0,0.234,0c1.758-0.043,3.056-0.547,3.759-1.645c0-0.078,0-0.156,0-0.235
			C311.173,199.997,311.034,199.587,310.316,199.757z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M352.601,236.168c-0.402-0.01-0.608,0.175-0.705,0.47c-0.078,0-0.156,0-0.235,0
			c-0.667,0.685-0.46,2.234,0.705,2.349c0.809,0.601,0.841,1.979,1.879,2.349c1.488,0,2.976,0,4.464,0c0.156,0,0.313,0,0.47,0
			c0.272-0.197,0.63-0.31,1.175-0.235c3.571-1.205,6.075-3.477,7.986-6.343c-0.061-0.688,0.531-0.722,0.47-1.409
			c0.143-0.563,0.338-1.072,0.235-1.879c0-0.235,0-0.47,0-0.705c0.546-1.646,0.08-4.305,0.235-6.343c0-0.313,0-0.626,0-0.939
			c0.683-0.775-0.041-2.142-0.235-2.819c0.965-0.837,1.962-1.64,3.054-2.349c0.235,0,0.47,0,0.705,0
			c1.12,0.524,1.269,2.02,1.879,3.054c-0.17,0.718,0.24,0.856,0.235,1.41c0.114,0.981,0.553,1.639,1.409,1.879
			c0.078,0,0.156,0,0.234,0c0.884-0.37,0.713-1.792,0.705-3.054c-0.297-1.661-0.832-3.083-1.409-4.463
			c-0.755-0.733-1.666-1.309-2.584-1.879c-2.868-0.44-3.781,1.074-5.403,1.879c-1.136,1.449-2.812,2.356-4.229,3.524
			c-0.568,0.762-1.432,1.23-2.114,1.879c-1.024,0.542-1.436,1.696-2.349,2.349c0,0.079,0,0.157,0,0.235
			c-0.524,0.729-0.67,1.836-1.175,2.584c-0.035,1.608-0.468,2.821-0.939,3.993c-0.378,1.502-1.249,2.509-2.349,3.289
			c-0.665,0.354-1.086,0.95-2.114,0.94C352.601,236.011,352.601,236.09,352.601,236.168z M359.412,234.523
			c0.102-0.447,0.507-0.59,0.471-1.174c0.694-0.95,1.013-2.276,1.174-3.759c0.278-0.583,0.341-1.382,0.47-2.114
			c1.253-1.646,3.123-3.922,4.934-4.228c0,0.078,0,0.156,0,0.235c-0.545,1.178-0.081,3.365-0.235,4.933c0,0.157,0,0.313,0,0.47
			c-0.541,0.633-0.083,2.266-0.234,3.289c0,0.079,0,0.157,0,0.235c-0.779,2.275-2.257,3.851-4.229,4.933
			c-1.694,0.264-3.081,0.834-5.168,0.705C357.38,236.72,358.888,236.113,359.412,234.523z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M372.803,250.028c0.002-0.624-0.089-1.342,0.234-1.645
			c0.069-1.184,0.183-2.323,0.705-3.054c0-0.079,0-0.157,0-0.235c0.151-0.867,0.241-1.795,0.705-2.349
			c-0.015-1.346,0.669-1.993,0.704-3.289c-1.628,3.931-2.407,8.712-3.288,13.39C372.423,252.154,372.242,250.72,372.803,250.028z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M341.56,262.478c-1.173-0.584-1.233,1.236-1.88,1.879
			C340.547,263.671,340.795,262.727,341.56,262.478z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M339.445,264.357c-0.209,0.104-0.42,0.206-0.47,0.47
			c-0.275-0.038-0.198-0.428-0.235-0.705c-0.349,0.199-0.34,0.756-0.47,1.175C338.514,265.75,339.202,264.681,339.445,264.357z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M273.436,356.677c2.255-0.459,3.297-2.838,4.463-4.463
			c3.903-5.439,7.637-10.797,11.51-15.974c0.414-0.212,0.728-0.525,0.939-0.94c0-0.078,0-0.156,0-0.235
			c1.874-2.354,3.46-4.997,4.934-7.752c0.778-1.414,1.813-2.571,2.349-4.228c0.439-1.565,0.382-2.45-0.704-1.645
			c-0.186,0.138-0.368,0.301-0.553,0.45c0.007-0.005,0.014-0.009,0.021-0.014c-0.438,0.32-0.896,0.744-1.252,1.097
			c0.002-0.002,0.004-0.004,0.006-0.006c-0.004,0.004-0.008,0.008-0.012,0.012c-0.012,0.011-0.022,0.022-0.033,0.033
			c-4.551,4.343-8.747,11.733-12.763,17.455c0,0,0,0,0,0.001c-0.301,0.317-0.57,0.665-0.825,1.027
			c-0.026,0.037-0.053,0.072-0.079,0.109c-0.248,0.356-0.486,0.721-0.726,1.084c-0.001,0.001-0.002,0.003-0.003,0.005c0,0,0,0,0,0
			c-0.227,0.343-0.46,0.68-0.708,1.001c0.248-0.322,0.481-0.658,0.708-1.001c-0.547,0.712-1.092,1.394-1.633,2.005
			c0,0.079,0,0.157,0,0.235c-1.304,1.366-2.863,3.663-4.229,5.638C273.63,352.326,271.045,354.82,273.436,356.677z M289.145,336.431
			c0.012-0.013,0.024-0.024,0.037-0.037c-0.013,0.013-0.025,0.025-0.039,0.039C289.144,336.432,289.144,336.431,289.145,336.431z
			 M287.025,338.983c-0.004,0.005-0.007,0.01-0.011,0.015c-0.757,0.94-1.503,1.897-2.242,2.863
			C285.514,340.891,286.265,339.928,287.025,338.983z M280.553,348.185c-0.754,1.131-1.494,2.279-2.267,3.373
			C279.058,350.462,279.799,349.316,280.553,348.185z M275.762,354.627c-0.001,0.001-0.001,0.002-0.002,0.002
			s-0.001,0.001-0.001,0.001c0,0,0,0,0,0C275.76,354.63,275.761,354.629,275.762,354.627z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M366.46,646.556c-1.804-0.585-4.033,0.319-6.343,0
			c-0.402-0.537-1.793-0.086-2.584-0.234c-4.304-0.395-8.306-1.091-12.686-1.409c-0.671-0.503-2.568-0.229-3.288,0
			c-0.354,0.746-0.354,1.911,0.704,1.879c5.712,1.102,11.406,2.22,18.089,2.349c0.713,0.391,3.045,0.391,3.758,0
			c0.798-0.063,1.905,0.183,2.35-0.234C367.507,648.739,367.823,646.651,366.46,646.556z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M391.36,525.577c1.43-1.233,2.761-2.564,3.993-3.993
			C393.925,522.817,392.594,524.148,391.36,525.577z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M375.621,756.964c0.156,2.429-0.312,5.479,0.235,7.517
			C375.701,762.053,376.168,759.001,375.621,756.964z"/>
				<polygon fillRule="evenodd" clipRule="evenodd" points="172.883,649.896 172.884,649.896 172.885,649.896 		" />
				<polygon fillRule="evenodd" clipRule="evenodd" points="142.765,724.479 142.763,724.481 142.764,724.48 		" />
				<path fillRule="evenodd" clipRule="evenodd" d="M422.579,485.581c0.034,0.026,0.068,0.053,0.103,0.08
			c-0.213-0.171-0.425-0.349-0.638-0.521C422.223,485.285,422.399,485.438,422.579,485.581z"/>
				<polygon fillRule="evenodd" clipRule="evenodd" points="402.666,613.236 402.665,613.237 402.669,613.234 		" />
				<path fillRule="evenodd" clipRule="evenodd" d="M16.683,392.417c-0.001,0.011-0.002,0.021-0.004,0.032
			c0.003-0.026,0.008-0.053,0.011-0.079C16.688,392.385,16.686,392.401,16.683,392.417z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M15.752,402.307c0,0.003,0,0.006,0,0.01c-0.001,0.053-0.002,0.105-0.003,0.158
			C15.75,402.419,15.75,402.363,15.752,402.307z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M519.423,620.68c0.004-0.003,0.007-0.005,0.011-0.008
			c-0.006,0.005-0.013,0.009-0.019,0.013C519.418,620.683,519.42,620.682,519.423,620.68z"/>
				<polygon fillRule="evenodd" clipRule="evenodd" points="29.071,363.582 29.07,363.586 29.072,363.578 		" />
				<path fillRule="evenodd" clipRule="evenodd" d="M277.664,194.119c0.122,0.348,0.347,0.592,0.47,0.939
			c0.521,1.437,1.193,2.722,2.349,3.524c0.016,0.688,0.537,0.872,0.939,1.174c0.212,0.414,0.525,0.728,0.939,0.939
			c0.014,0.144,0.035,0.278,0.235,0.235c0.078,0,0.156,0,0.235,0c0.641,0.171,0.473-0.467,0.939-0.47
			c-0.643-2.646-2.453-4.124-3.759-6.107c0-0.079,0-0.157,0-0.235c-1.708-2.598-2.794-5.819-3.993-8.927
			c-0.047-0.893-0.208-1.671-0.47-2.349c-0.013-1.083,0.104-2.296-0.235-3.054c-0.937-0.153-0.673,0.893-0.705,1.645
			c0,0.156,0,0.313,0,0.47c0,0.548,0,1.096,0,1.644c-0.229,0.007-0.182,0.288-0.235,0.47c0,0.783,0,1.566,0,2.349
			c0,0.078,0,0.156,0,0.235c0.079,0,0.157,0,0.235,0C275.192,189.543,276.509,191.75,277.664,194.119z M283.352,199.508
			c0.1,0.338,0.084,0.625-0.104,0.834C283.437,200.133,283.452,199.846,283.352,199.508z M282.025,197.363
			c0.36,0.463,0.7,0.923,0.954,1.348C282.725,198.286,282.385,197.826,282.025,197.363s-0.739-0.929-1.071-1.365
			C281.287,196.435,281.666,196.9,282.025,197.363z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M291.523,197.408c-2.038-1.173-5.424-2.249-5.873-4.229
			c2.168,0.176,6.141,2.384,7.282,0c-2.108-2.354-6.768-2.158-10.335-3.054c-0.235,0.313-0.388,0.709-0.705,0.94
			c0.002,0.624-0.088,1.341,0.235,1.644c1.662,3.272,4.482,5.384,8.222,6.578c0.318,0.074,0.449,0.334,0.94,0.235
			c0.636,0.46,1.559,0.634,2.584,0.705c0.452,0.456,0.944-0.247,1.644-0.235C295.704,197.612,293.149,197.974,291.523,197.408z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M69.533,256.37c-0.313,0-0.626,0-0.939,0c0,0.079,0,0.157,0,0.235
			c-0.078,0-0.157,0-0.235,0c-2.475,1.125-0.725,5.938,1.174,6.343c1.622,0.29,2.06-0.603,2.114-1.879
			C71.17,259.275,71.103,257.072,69.533,256.37z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M58.493,348.925c0.7-1.022,1.007-2.438,1.645-3.523
			c0.872-0.736-0.673-2.282-1.41-1.41c-1.016,0.002-0.928,1.108-1.645,1.41C56.331,346.558,56.479,349.178,58.493,348.925z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M56.378,402.954c2.875-1.118,6.015-1.716,8.222-3.523
			c0.132-1.307-0.948-1.401-1.409-2.114c0.478-0.227,0.507-0.902,0.47-1.645c-3.477-7.426-5.529-17.304-7.987-25.84
			c-0.775-2.69-1.48-5.423-2.545-7.739c-0.092-0.316-0.181-0.639-0.274-0.953c-0.158-0.285-0.333-0.542-0.517-0.788
			c-0.015-0.02-0.031-0.038-0.046-0.058c-0.179-0.234-0.371-0.449-0.572-0.648c-0.969-1.324-2.183-2.37-3.797-2.97
			c-0.054,0.417,0.236,1.177-0.47,0.94c-1.243,0.151-2.49-0.387-3.758-0.235c-2.519,0.301-13.436,4.585-13.86,6.812
			c-0.178,0.936,0.904,3.337,1.41,4.933c3.556,11.223,6.688,24.799,10.571,34.297c1.285,0.829,2.036,2.192,3.054,3.289
			C47.893,404.696,52.322,404.532,56.378,402.954z M44.868,359.966c-0.859,1.46,0.145,2.301,0.235,3.993
			c-2.143,0.396-5.038,0.916-7.047,0.705c-0.279-0.739-0.793-1.243-0.94-2.114C39.584,361.572,41.847,360.39,44.868,359.966z
			 M41.344,394.263c-2.77-9.769-6.039-21.026-8.927-29.364c0.451-0.802,1.409-1.097,2.349-1.41c0.359,4.029,4.124,4.017,8.927,3.289
			c1.983-0.301,3.125-0.793,3.704-1.482c0.096-0.054,0.203-0.107,0.289-0.162c0-0.094-0.002-0.189-0.003-0.284
			c0.542-1.162-0.076-2.778-0.936-4.884c3.969,0.202,4.735,4.684,5.638,7.752c2.914,9.905,5.313,21.769,9.396,30.303
			c-0.167,0.062-0.344,0.119-0.513,0.18c0,0,0,0,0,0c-0.662,0.148-1.333,0.405-1.996,0.701c0,0,0,0,0,0s0,0,0,0c0,0-0.001,0-0.001,0
			c0,0,0,0,0.001,0c-3.738,1.284-7.651,2.44-11.35,3.347c-0.591,0.145-1.546,0.86-2.349,0.705
			C43.42,402.538,42.028,396.674,41.344,394.263z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M69.533,359.261c-0.662,0.591-0.766,1.74-0.705,3.054c0,0.078,0,0.156,0,0.235
			c0.079,0,0.157,0,0.235,0c-0.025,1.904,1.01,2.749,2.349,3.289c0.079,0,0.157,0,0.235,0c1.62,0.736,2.745-0.573,3.289-1.645
			c0-0.626,0-1.253,0-1.879C74.617,360.018,71.825,356.635,69.533,359.261z M72.587,363.489c-0.566-0.531-1.642-0.551-1.41-1.879
			c0-0.156,0-0.313,0-0.47C71.805,359.984,72.761,362.305,72.587,363.489z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M407.569,422.217c-3.823,5.283-7.138,10.506-9.866,16.443
			c-1.327,2.889-3.563,6.715-1.645,9.866c1.885-0.85,1.806-3.254,2.349-4.698c1.972-5.238,4.569-9.513,7.282-13.859
			c3.583-5.738,8.416-10.741,10.807-17.383C412.446,414.753,410.365,418.353,407.569,422.217z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M371.863,735.353c-0.646,0.371-1.631,0.405-2.35,0.704
			c-1.049,0.518-2.118,1.014-3.289,1.409c-0.078,0-0.156,0-0.234,0c-0.439-1.205,0.014-3.302-1.409-3.523
			c0.969,0.597,1.068,2.063,0.939,3.759c-0.888,0.418-1.805,0.812-2.716,1.21c0.049-0.019,0.097-0.037,0.146-0.057
			c-0.049,0.02-0.098,0.038-0.147,0.058c-0.009,0.004-0.018,0.007-0.026,0.011c-1.157,0.46-2.32,0.927-3.495,1.398
			c-1.176,0.47-2.363,0.941-3.566,1.404c-19.232,6.93-42.829,10.07-69.359,10.07c-7.5,0-15.143-0.235-23.021-0.235
			c-2.223,0-4.872-0.571-6.578,1.175c0.863,2.341,4.155,1.791,6.107,1.88c39.579,1.798,78.972-0.93,104.535-13.86
			c0.19,0.045,0.234,0.235,0.47,0.235c0.351-0.902,1.477-1.029,2.35-1.41c0.588-0.194,0.922-0.644,1.645-0.704
			c0.753-0.735,2.314-0.66,1.879-2.584C373.373,735.721,372.742,735.412,371.863,735.353z"/>
				<polygon fillRule="evenodd" clipRule="evenodd" points="362.795,738.915 362.803,738.912 362.805,738.911 		" />
				<path fillRule="evenodd" clipRule="evenodd" d="M110.877,492.22c-1.148,2.942-2.367,7.003-2.584,10.571
			c-0.142,2.328,0.349,8.598,3.054,8.222c2.61-0.362,0.537-3.062,0.235-4.229c-1.343-5.181,1.426-13.343,1.409-18.558
			C112.106,489.129,111.465,490.715,110.877,492.22z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M547.341,528.866c-7.462,2.453-14.152,5.103-23.961,7.047
			c-4.128,0.818-8.574,1.632-12.92,2.584c-3.472,0.762-10.66,2.337-10.336,5.168c0.194,1.699,4.656,2.668,7.047,3.054
			c2.479,0.4,4.65,0.453,6.94,0.559c1.324,0.119,2.652,0.226,3.977,0.31c1.969,0.244,4.527,0.635,7.641,0.776
			c2.859,0.13,8.746,0.907,9.162-0.704c0.592-2.293-3.257-1.782-5.403-1.88c-8.397-0.381-18.809-0.632-25.744-2.56
			c0.275-0.105,0.553-0.209,0.832-0.311c7.368-2.332,15.909-3.591,23.973-5.116c6.407-1.213,12.045-3.611,17.384-5.403
			c1.724-0.579,4.422-0.77,4.698-3.054C549.363,527.67,548.471,528.494,547.341,528.866z M504.578,542.908
			c7.267-2.642,15.962-3.834,24.069-5.543C520.543,539.076,511.845,540.267,504.578,542.908z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M480.861,550.713c-2.978-1.538-5.808-3.428-8.222-5.638
			c-1.57-1.438-2.329-4.156-5.168-3.289c-0.108,0.898,0.278,1.505,0.704,2.114c2.069,2.958,5.994,5.648,9.396,7.752
			c3.262,2.017,7.309,4.498,11.286,4.128c0.155,0.032,0.304,0.07,0.46,0.101c-0.017-0.056-0.048-0.097-0.066-0.15
			c0.179-0.025,0.357-0.047,0.536-0.085C487.607,552.812,483.776,552.219,480.861,550.713z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M243.602,585.01c24.949-0.997,47.254-3.834,68.359-8.222
			c8.258-1.718,18.58-4.039,26.779-2.584c3.921,0.695,8.042,2.293,12.216,3.288c4.206,1.003,8.419,1.923,12.215,3.523
			c-0.366,2.937-4.807,3.006-4.229,6.108c2.671,0.807,8.658-4.024,7.987-7.518c-0.382-1.985-4.568-2.726-7.282-3.523
			c-3.091-0.909-6.275-1.594-8.691-2.114c-6.044-1.301-11.203-3.707-18.323-3.289c-7.656,0.45-15.761,2.326-23.726,3.994
			c-14.918,3.123-30.999,5.679-46.982,7.047c-6.37,0.545-13.037,0.676-19.028,1.175c-1.673,0.14-4.257-0.275-5.403,1.409
			C239.265,585.537,241.65,585.088,243.602,585.01z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M124.502,579.372c-0.183-0.21-0.252-0.531-0.235-0.94c0-0.078,0-0.156,0-0.234
			c-0.488-1-0.691-2.284-0.94-3.523c0-0.078,0-0.157,0-0.235c-0.684-1.273-0.92-2.994-2.349-3.523c-0.313,0-0.626,0-0.939,0
			c-2.444,0.688-1.813,4.451-2.584,6.813c0,0.078,0,0.156,0,0.234c-0.149,0.791,0.303,2.183-0.235,2.584
			c0.159,1.334-0.423,1.927-0.47,3.054c-0.235,7.052-1.173,16.531-2.349,24.196c-0.55,3.587-1.999,6.229-1.41,10.336
			c0.634-1.011,1.199-2.09,2.114-2.819c0.212-0.831,0.388-1.699,0.575-2.556c0,0.001,0,0.001,0,0.001c0-0.001,0-0.002,0.001-0.004
			c0,0.001,0,0.002,0,0.003c0-0.003,0.001-0.005,0.002-0.008c0.131-0.454,0.239-0.924,0.334-1.4
			c0.018-0.087,0.034-0.174,0.051-0.261c0.087-0.459,0.167-0.921,0.237-1.386c1.814-9.774,2.535-20.644,4.909-29.86
			c1.433,2.451,0.945,5.896,1.644,8.927c0.197,0.854,0.942,1.521,1.175,2.349c0.315,1.123-0.583,2.975,0.939,3.289
			c1.956-1.006,1.169-4.783,0.939-7.282C125.652,584.299,124.781,581.572,124.502,579.372z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M250.552,592.63c-0.158-0.115-0.335-0.197-0.526-0.251
			c-1.87-0.831-4.266-0.638-6.188-0.792c-14.439-1.152-27.691-3.521-40.875-5.638c-3.204-0.515-6.431-0.876-9.532-1.336
			c2.306,0.452,4.612,0.909,6.948,1.336c-2.337-0.427-4.646-0.885-6.953-1.337c0.001,0,0.003,0.001,0.005,0.001
			c-0.009-0.002-0.018-0.003-0.026-0.005c-1.903-0.373-3.807-0.738-5.723-1.08c-0.009-0.002-0.017-0.003-0.026-0.005
			c0.003,0.001,0.007,0.001,0.01,0.002c-0.051-0.009-0.102-0.018-0.153-0.026c0.047,0.009,0.095,0.016,0.143,0.024
			c-0.221-0.054-0.449-0.101-0.667-0.158c-0.77-0.202-1.555-0.799-2.349-0.939c-1.073-0.19-2.293,0.129-3.524,0
			c-2.224-0.233-5.056-1.642-6.107-0.235c-1.923,3.048,3.739,3.36,7.047,3.994c9.552,1.829,19.013,3.968,28.424,3.993
			c7.597,0.021,14.642,1.7,21.847,2.584c3.238,0.397,6.674,0.666,9.866,0.939c3.047,0.261,7.011,1.354,8.901-0.232
			c0.008-0.001,0.017-0.001,0.025-0.002c-0.002-0.006-0.005-0.009-0.007-0.015c0.083-0.071,0.167-0.14,0.242-0.221
			C251.107,592.995,250.837,592.799,250.552,592.63z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M221.285,603.098c-12.203-2.822-22.387-7.634-32.652-11.98
			c-3.708-1.57-7.257-3.766-11.276-4.495c-0.16-0.054-0.318-0.094-0.472-0.121c-0.008-0.001-0.015-0.002-0.022-0.003
			c-0.153-0.026-0.299-0.03-0.434-0.01c-0.161-0.02-0.317-0.055-0.48-0.069c-0.144,0.014-0.278,0.035-0.235,0.235
			c-1.237,2.467,3.23,3.265,5.403,4.229c2.479,1.099,4.515,2.03,6.343,2.818c10.599,4.567,20.761,9.701,32.887,12.45
			c1.341,0.304,6.713,1.981,7.047-0.234C227.721,603.745,222.457,603.368,221.285,603.098z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M336.392,660.416c-2.194,0.155-5.01-0.312-6.813,0.234
			c-0.545-0.074-0.902,0.038-1.175,0.235c-3.853,1.628-6.217,4.745-9.396,7.047c-0.078,0-0.156,0-0.234,0
			c-2.764,1.7-5.401,3.526-7.752,5.638c-0.771,0.091-1.036,0.688-1.645,0.94c-1.617,0.888-3.447,1.564-4.934,2.584
			c-5.709,1.886-10.921,4.269-15.974,6.812c-0.989,0.577-2.196,0.937-3.054,1.645c-1.968,1.008-5.35,0.602-6.108,2.819
			c0.331,1.589,2.462,1.109,3.994,0.939c0.66,1.142,1.08,2.522,1.409,3.993c-0.114,1.688-1.937,1.666-2.819,2.584
			c-1.926,0.347-5.63,0.161-5.168,2.114c0.139,1.583,2.445,1.205,3.524,0.705c2.063-0.051,3.429-0.8,4.698-1.645
			c1.272-0.609,2.906-2.101,2.114-3.993c-0.251-1.706-0.752-3.163-1.409-4.463c0.348-0.123,0.592-0.348,0.939-0.471
			c2.167-0.887,4.203-1.904,6.108-3.054c4.526-1.894,9.099-3.742,13.625-5.638c4.232-1.64,7.599-4.146,10.806-6.813
			c0.078,0,0.156,0,0.234,0c4.118-2.851,7.273-6.664,11.98-8.927c1.416,0.007,2.419-0.4,3.994-0.234c0.405,0.39,2.413,0.39,2.818,0
			c1.263,0.166,1.925-0.269,2.819-0.47C340.241,661.271,338.357,659.57,336.392,660.416z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M357.533,666.054c-0.325-0.536-1.635-0.088-2.349-0.235
			c-2.948-0.263-5.305-1.116-7.752-1.879c-0.449-1.049-2.988-0.198-2.114,0.705c1.433,2.639,5.156,2.986,8.691,3.523
			c1.102,0.391,3.832,0.391,4.933,0c0.585,0.036,0.728-0.369,1.175-0.47C360.192,666.214,359.001,665.996,357.533,666.054z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M223.165,748.742c1.729,0,4.58,0.836,5.638-1.175
			c-0.7-1.63-3.002-1.345-4.229-1.409c-5.526-0.29-10.961-0.239-16.444-0.47c-18.856-0.795-36.97-3.001-51.189-8.241
			c0.003,0.001,0.006,0.002,0.01,0.004c-0.055-0.021-0.112-0.039-0.167-0.06c0.052,0.02,0.105,0.036,0.157,0.056
			c-2.361-0.893-4.634-1.863-6.808-2.921c-2.389-1.343-4.748-3.599-7.075-3.964c0.106,0.068,0.217,0.134,0.324,0.202
			c-0.107-0.068-0.218-0.133-0.324-0.202h0c-0.076-0.049-0.158-0.094-0.233-0.144c-0.156,0-0.313,0-0.47,0
			c-0.062,0.036-0.124,0.071-0.187,0.107c-0.251,0.019-0.502,0.053-0.753,0.128c-0.358,1.91,1.843,2.749,3.054,3.523
			c4.139,2.646,8.623,4.621,13.625,6.343C176.1,746.716,199.029,748.743,223.165,748.742z M173.054,742.069
			c-2.847-0.6-5.609-1.28-8.283-2.042C167.444,740.79,170.208,741.469,173.054,742.069z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M343.908,137.741c-2.9-3.303-6.199-7.269-9.866-7.517
			c-0.235-0.001-0.279,0.19-0.47,0.235c0.328,2.039,2.491,2.017,3.759,3.054c2.511,2.053,4.556,5.108,6.813,7.752
			c5.752,6.737,10.867,13.54,12.685,23.726c0.336,1.881-0.081,4.247,1.175,6.107c1.542-1.514,1.14-3.468,0.939-5.403
			C357.851,155.121,350.093,144.782,343.908,137.741z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M373.507,164.99c0.572,0.524,0.659,1.534,0.94,2.349
			c1.435,3.733,3.064,7.271,4.228,11.275c0.806,1.309,1.23,2.999,1.645,4.698c0.4,1.087,0.87,2.106,0.939,3.524
			c0.633,1.247,0.934,2.825,1.175,4.463c0.287,0.731,0.547,1.489,0.47,2.584c0.376,0.643,0.483,1.552,0.47,2.584
			c0.463,0.634,0.141,2.053,0.235,3.054c0.389,0.253,0.389,2.096,0,2.349c0,0.235,0,0.47,0,0.705
			c-0.148,0.791,0.303,2.182-0.235,2.584c0.408,2.246-1.144,3.66-0.47,5.403c0.079,0,0.157,0,0.235,0
			c1.396-0.25,1.139-2.15,1.879-3.054c0-0.078,0-0.156,0-0.235c0.212-0.885,0.07-2.123,0.47-2.819c0-0.078,0-0.157,0-0.235
			c0.151-1.023-0.306-2.656,0.235-3.289c0-0.157,0-0.313,0-0.47c-0.543-0.788-0.082-2.58-0.235-3.758
			c-0.939-7.125-2.839-13.292-4.698-19.498c-0.759-1.198-1.172-2.743-1.645-4.229c-0.135-0.491-0.582-0.67-0.47-1.409
			c-0.078,0-0.156,0-0.234,0c-0.481-1.633-0.127-4.102-0.235-6.108c0-0.313,0-0.626,0-0.939c-0.078,0-0.156,0-0.234,0
			c0-1.488,0-2.976,0-4.463c0-0.157,0-0.313,0-0.47c-0.541-0.556-0.084-2.108-0.235-3.054c0-0.078,0-0.156,0-0.235
			c0-0.078,0-0.156,0-0.235c-0.537-0.402-0.086-1.793-0.234-2.584c0-0.078,0-0.157,0-0.235c-0.079,0-0.157,0-0.235,0
			c0-0.783,0-1.566,0-2.349c-0.379-2.284-0.729-4.595-0.939-7.047c-0.654,0.106,0.184-1.28-0.47-1.174c0-0.392,0-0.783,0-1.175
			c-0.379-1.265-0.744-2.544-0.939-3.993c-0.254-1.185-2.161-1.853-1.645,0.47c0.013,0.143,0.035,0.278,0.234,0.235
			c0.926,4.712,1.935,9.341,2.114,14.799c0.538,0.402,0.086,1.793,0.235,2.584c0,0.157,0,0.313,0,0.47
			c0.539,0.479,0.085,1.951,0.234,2.819c0,0.235,0,0.47,0,0.705c0.541,0.556,0.085,2.108,0.235,3.054c0,0.235,0,0.47,0,0.705
			c0.525,0.101,0.095,1.158,0.234,1.645c0,0.313,0,0.626,0,0.939c-0.47-0.313-0.522-1.042-0.704-1.644
			c-0.074-0.318-0.335-0.449-0.235-0.94c-0.311-0.002-0.215-0.412-0.47-0.47c-0.015-1.16-0.689-1.659-0.704-2.819
			c-0.829-2.382-1.663-4.759-2.114-7.517c-0.464-0.554-0.554-1.482-0.705-2.349c-0.197-0.273-0.31-0.63-0.235-1.175
			c-0.645-1.156-0.842-2.759-1.174-4.228c-0.014-0.144-0.035-0.278-0.235-0.235c0-0.313,0-0.626,0-0.94
			c-0.562-0.926-0.812-2.164-1.175-3.289c-0.443-0.417-0.001-1.721-0.939-1.645c-0.817,0.548,0.15,2.839,0.235,3.994
			C368.926,151.092,371.481,157.776,373.507,164.99z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M367.635,249.793c-0.154-1.234,0.575-3.105-1.175-3.524c-0.156,0-0.313,0-0.47,0
			c-1.307,3.3,0.11,7.758,1.175,10.336c0.715,1.733,1.426,4.395,3.054,3.759c0.76-1.426-0.551-3.215-1.175-4.933
			C368.394,253.64,367.877,251.746,367.635,249.793z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M155.511,599.574c-6.75,4.268-6.492,19.579-0.94,23.021
			c1.667,1.032,4.186,1.484,5.638,0.939c6.125-2.301,7.84-19.479,3.289-25.371C160.397,597.699,157.567,598.273,155.511,599.574z
			 M154.101,608.266c0.125-0.658,0.345-1.221,0.47-1.879c0.815-0.188,0.903,0.35,1.645,0.234c0.078,0,0.157,0,0.235,0
			c0.194,0.664-0.331,0.608-0.235,1.175c0,0.235,0,0.47,0,0.705c-0.348,0.043-0.215,0.567-0.235,0.939
			c-0.721-0.22-1.148-0.731-1.879-0.939C154.101,608.422,154.101,608.344,154.101,608.266z M162.793,610.38
			c-0.118,2.701-1.037,4.601-1.645,6.813c-1.496,3.886-5.022,4.572-6.578,0.234c-0.02-0.841-0.365-1.356-0.47-2.114
			c0-0.078,0-0.156,0-0.234c0.18-1.187,0.65,0.74,1.41,0.47c2.746-0.777,2.551-4.496,3.289-7.282
			c0.904-1.453,0.156-3.454-1.41-3.759c-0.052-0.183-0.197-0.272-0.47-0.234c-0.043-0.349-0.567-0.216-0.939-0.235
			c0.675-1.83,2.61-2.4,4.463-3.054c0.391,0,0.783,0,1.174,0c0.617,1.811,0.994,3.861,1.175,6.107
			C162.793,608.188,162.793,609.283,162.793,610.38z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M574.355,534.738c-0.549-3.366-1.699-6.13-3.523-8.222
			c-0.185-1.46-0.623-2.665-0.939-3.993c-0.152-0.866-0.241-1.795-0.705-2.349c-0.59-1.524-1.004-3.225-1.409-4.934
			c-0.252-1.236-0.533-2.443-1.175-3.289c-0.336-0.838-0.835-1.514-0.939-2.584c-0.944-2.188-1.86-4.404-3.289-6.107
			c0-0.078,0-0.156,0-0.234c-1.029-1.634-1.913-3.412-3.289-4.698c0-0.079,0-0.157,0-0.235c-4.862-6.805-9.455-13.879-14.329-20.672
			c-0.235,0.001-0.279-0.19-0.47-0.235c-0.094-0.61-0.701-0.708-0.705-1.409c-4.543-5.245-8.108-11.467-11.745-17.618
			c-1.344-2.415-2.823-4.694-3.994-7.282c-1.01-1.104-1.669-2.56-2.584-3.759c-0.779-1.178-1.352-2.563-2.349-3.523
			c-0.143-0.875-0.498-1.538-0.939-2.114c-1.396-2.206-2.713-4.491-4.464-6.343c-0.072-0.554-0.815-0.437-0.704-1.175
			c-1.463-1.904-2.78-3.953-4.464-5.638c0-0.078,0-0.156,0-0.234c-4.09-5.307-8.74-10.053-13.39-14.8
			c-0.235,0.001-0.279-0.19-0.47-0.235c-0.693-0.325-0.951-1.084-1.645-1.409c-0.604-0.414-1.015-1.022-1.645-1.41
			c-1.079-0.957-2.158-1.914-3.288-2.819c-0.053-0.183-0.197-0.273-0.471-0.235c-0.483-0.769-1.215-1.29-1.879-1.879
			c0-0.078,0-0.157,0-0.235c-1.772-1.281-3.313-2.795-5.403-3.759c-1.664-0.449-3.058-1.17-3.288-3.054
			c-0.73-1.854-1.592-3.576-2.584-5.168c-0.105-1.07-0.604-1.745-0.939-2.584c-0.746-1.917-1.593-3.732-3.055-4.933
			c0-0.078,0-0.157,0-0.235c-3.201-3.924-6.742-7.509-10.57-10.806c-0.078,0-0.157,0-0.235,0c-1.342-1.164-2.633-2.378-3.993-3.524
			c-0.045-0.19-0.235-0.234-0.235-0.47c-0.657-1.051-2.408-1.452-3.054-2.584c-0.518-0.908-0.294-2.306-0.704-3.289
			c-1.322-3.167-4.666-5.476-5.873-8.692c-0.354-0.665-0.95-1.086-0.939-2.114c-0.339-1.071-0.808-2.012-1.41-2.819
			c0-0.078,0-0.157,0-0.235c-3.702-8.435-9.876-14.398-18.792-17.618c-0.079,0-0.157,0-0.235,0c-0.851-0.559-2.126-0.693-3.289-0.94
			c-0.078,0-0.156,0-0.234,0c-0.555-0.463-1.482-0.553-2.35-0.705c-0.544,0.075-0.901-0.038-1.174-0.235
			c-0.636-0.46-1.56-0.634-2.584-0.705c-1.819-0.139-3.38-0.536-4.934-0.94c-0.643-0.375-1.552-0.483-2.584-0.47
			c-0.017-1.191,0.831-1.518,0.939-2.584c0-0.078,0-0.157,0-0.235c0.823-1.212,1.147-2.924,1.409-4.698
			c-0.181-1.121,0.368-1.512,0.235-2.584c0.435-0.662-0.005-2.198,0.47-2.819c2.094-5.189,5.587-8.978,6.578-15.27
			c0.323-0.303,0.232-1.02,0.234-1.644c0.684-2.293,0.643-6.56,0.235-9.162c-0.732-4.671-2.03-8.776-3.524-12.685
			c-0.595-1.441-1.128-2.943-2.114-3.994c-0.104-0.209-0.206-0.42-0.47-0.47c-0.464-0.163-0.626-0.626-0.704-1.174
			c0.06-0.843-0.706-0.861-0.705-1.645c-3.331-5.126-7.075-9.838-12.45-12.92c-3.906-1.966-8.548-3.198-12.45-5.168
			c-0.796-0.145-1.814-0.065-1.645-1.175c-0.724-0.372-0.741-1.451-1.409-1.879c0.129-0.99-1.837,0.114-1.645-0.939
			c0.337-0.29,0.445-0.808,0.47-1.41c1.254-3.993,2.786-7.706,3.993-11.746c0.466-0.787,0.772-1.733,0.94-2.819
			c-0.078-0.86,0.261-1.305,0.47-1.879c0.648-1.074,0.885-2.561,1.174-3.994c-0.014-0.875,0.237-1.486,0.471-2.114
			c0.402-0.771,0.621-1.728,0.704-2.819c0-0.078,0-0.157,0-0.235c0.668-1.534-0.054-3.701,0-5.403
			c0.252-0.609,0.571-1.152,0.47-2.114c0.46-0.793,0.572-1.934,0.705-3.054c0.2-1.679,0.384-3.375,0.705-4.933
			c0.39-0.559,0.39-2.73,0-3.289c-0.156-1.411-0.304-2.829-0.454-4.246c-0.166-2.19-0.421-4.387-0.747-6.583
			c-0.459-3.598-1.022-7.087-1.854-10.312c-0.221-1.502-0.486-2.958-0.939-4.229c-0.304-0.871-0.383-1.966-0.47-3.054
			c-0.071-1.025-0.244-1.949-0.704-2.584c-0.424-1.534-0.937-2.979-1.175-4.698c-0.167-1.086-0.475-2.032-0.939-2.819
			c-2.216-9.216-3.787-19.078-6.813-27.484c-0.577-1.615-1.576-2.809-2.819-3.758c-0.09-0.771-0.687-1.036-0.939-1.645
			c-0.146-0.218-0.312-0.42-0.464-0.633c-0.002-0.003-0.004-0.006-0.005-0.01c-0.061-0.112-0.119-0.226-0.179-0.338
			c-0.116-0.222-0.243-0.434-0.404-0.611c-0.004-0.004-0.007-0.008-0.011-0.011c-0.082-0.088-0.174-0.166-0.279-0.229c0,0,0,0,0,0
			c-1.428-1.807-3.022-3.468-4.531-5.214c-1.951-2.258-4.159-4.312-6.577-6.107c-0.156,0-0.313,0-0.47,0
			c-0.588,0.04-0.735-0.361-0.939-0.705c-0.751-1.209-3.217-2.733-4.698-3.993c-1.079-0.331-1.729-1.09-2.584-1.645
			c-1.862-1.192-3.541-2.566-5.403-3.759c-0.309-0.083-0.369-0.414-0.705-0.47c-1.075-1.116-2.666-1.719-4.229-2.349
			c-0.866-0.151-1.794-0.241-2.349-0.705c-0.939,0.078-1.304-0.419-2.349-0.235c-0.763,0.136-1.094-0.16-1.645-0.235
			c-1.334-0.545-3.679-0.081-5.403-0.235c-0.868,0.15-2.34-0.304-2.819,0.235c-5.343-1.234-10.594-2.561-15.504-4.229
			c0-0.078,0-0.157,0-0.235c-2.114,0-4.229,0-6.342,0c-1.566,0-3.133,0-4.698,0c0,0.078,0,0.157,0,0.235c-0.313,0-0.627,0-0.94,0
			c-0.945,0.151-2.497-0.305-3.054,0.235c-0.714,0.147-2.023-0.301-2.349,0.235c-0.562,0.143-1.704-0.295-1.88,0.235
			c-2.982,0.15-4.853,1.412-7.751,1.645c-0.927,0.169-1.901,0.292-2.584,0.705c-3.242,0.909-6.519,1.781-9.866,2.584
			c-1.934,0.494-3.861,0.994-5.403,1.879c-0.667-0.056-0.921,0.301-1.292,0.541c6.307-1.694,12.412-3.59,18.814-5.189
			c-6.4,1.601-12.507,3.496-18.814,5.189c-0.201,0.054-0.404,0.106-0.606,0.159c-0.045,0.012-0.091,0.024-0.136,0.036
			c-0.098-0.003-0.195-0.006-0.314-0.03c-0.868,0.15-2.34-0.304-2.819,0.235c-1.294,0.193-2.196,0.779-3.523,0.939
			c-3.585,1.426-6.275,3.748-8.457,6.578c-0.078,0-0.156,0-0.235,0c-3.84,2.894-6.082,7.387-8.222,11.98c0,0.078,0,0.156,0,0.235
			c-0.863,0.703-1.259,1.873-1.879,2.819c-0.211,0.415-0.567,0.686-0.705,1.174c-1.073,1.668-2.197,3.284-3.054,5.168
			c0.01,0.324-0.111,0.516-0.235,0.705c-0.933,0.79-1.206,2.24-1.879,3.289c-0.834,1.828-1.881,3.444-2.584,5.403
			c-0.603,0.808-1.071,1.748-1.41,2.819c-0.825,1.994-1.971,3.667-2.584,5.873c-0.525,0.649-0.914,1.435-1.174,2.349
			c-0.256,1.075-1.148,1.514-1.175,2.819c-0.556,0.618-0.773,1.576-0.939,2.584c-0.012,1.163-0.277,2.072-0.47,3.054
			c-0.623,1.255,0.216,3.068,1.879,2.819c0.014,0.144,0.035,0.278,0.235,0.235c0.175,2.186-1.068,3.522,0.235,4.933
			c0.152,1.023-0.306,2.656,0.235,3.289c0,0.156,0,0.313,0,0.47c0.148,0.791-0.303,2.182,0.235,2.584c0,0.078,0,0.157,0,0.235
			c0.147,0.714-0.301,2.023,0.235,2.349c0,0.157,0,0.313,0,0.47c0.147,0.714-0.301,2.023,0.235,2.349c0,0.079,0,0.157,0,0.235
			c0.148,0.791-0.303,2.182,0.235,2.584c0,0.626,0,1.253,0,1.879c-0.544,0.944-0.082,2.894-0.235,4.229
			c-0.405,3.51-1.43,6.4-1.645,10.101c-0.462,2.122-1.383,3.785-1.879,5.873c0.023,1.12-0.438,1.755-0.705,2.584
			c-0.078,0-0.157,0-0.235,0c0.217,1.271,0.452,2.524,0.705,3.759c0,0.157,0,0.313,0,0.47c0.152,1.023-0.306,2.656,0.235,3.289
			c0.396,3.049,0.933,5.958,1.645,8.691c0.151,1.023-0.306,2.656,0.235,3.289c0.011,1.32,0.248,2.415,0.47,3.524
			c0.155,1.881-0.311,4.383,0.235,5.873c0.147,0.714-0.3,2.023,0.235,2.349c0.147,0.714-0.301,2.023,0.235,2.349
			c0,0.235,0,0.47,0,0.705c0.147,0.714-0.301,2.023,0.235,2.349c0.759,1.355,0.495,3.734,0.939,5.403
			c0.058,1.508,0.109,3.023,0.47,4.228c0,0.157,0,0.313,0,0.47c0,2.819,0,5.638,0,8.457c0,0.157,0,0.313,0,0.47
			c-0.542,0.711-0.083,2.423-0.235,3.524c-0.131,3.236-0.605,6.129-1.174,8.926c-0.494,0.289-0.152,1.415-0.705,1.645
			c-1.749-1.618-3.683-3.051-5.403-4.698c-0.078,0-0.157,0-0.235,0c-2.08-2.305-4.51-4.26-6.813-6.342
			c-0.323,0.01-0.516-0.111-0.705-0.235c-0.689-0.016-0.873-0.537-1.174-0.939c-1.409-2.116-4.998-4.085-7.282-6.107
			c-0.157,0-0.313,0-0.47,0c-0.81-0.286-1.416-0.776-1.879-1.41c0.008-0.643-1.858-2.676-3.289-1.879c-0.078,0-0.156,0-0.235,0
			c-0.601,0.443-2.985,2.243-1.879,2.114c-0.099,0.449-0.085,1.011-0.705,0.939c-0.819,0.121-0.344,1.535-0.47,2.349
			c0.016,0.923,0.114,1.766,0.47,2.349c3.716,15.938,6.045,33.264,8.457,50.506c0.038,1.292,0.11,2.552,0.47,3.523
			c0.233,1.891,1.381,5.077,0.235,6.813c-0.815,0.751-2.576,0.556-3.289,1.41c-4.356,1.282-7.193,4.083-10.806,6.107
			c-1.519,0.517-2.806,1.266-3.758,2.349c-0.079,0-0.157,0-0.235,0c-0.891,0.597-1.758,1.217-2.584,1.879
			c-1-0.139-1.554,0.169-2.349,0.235c-1.018,0-2.036,0-3.054,0c-0.078,0-0.157,0-0.235,0c-1.563-0.307-5.142-0.603-6.342,0.235
			c-0.887-0.104-1.103,0.463-1.645,0.705c-4.083,2.181-7.345,5.184-10.571,8.222c-0.979,0.588-1.621,1.511-2.819,1.879
			c-4.404,3.19-9.063,6.127-11.745,11.041c-0.258,1.074-0.95,1.713-1.41,2.584c-0.771,0.482-0.897,1.609-1.41,2.349
			c0,0.079,0,0.157,0,0.235c-0.771,0.482-0.897,1.609-1.409,2.349c-0.549,1.409-1.586,2.33-1.879,3.994
			c-0.68,0.964-1.227,2.062-1.645,3.289c-0.015,1.081-0.667,1.525-0.939,2.349c-0.525,0.65-0.914,1.435-1.174,2.349
			c0,0.079,0,0.157,0,0.235c-0.603,0.808-1.071,1.749-1.41,2.819c-1.164,1.89-1.999,4.109-3.054,6.108c0,0.078,0,0.157,0,0.235
			c-0.853,0.635-1.051,1.925-1.645,2.819c-0.469,1.253-0.913,2.533-1.645,3.524c-2.052,2.959-3.446,6.576-4.698,10.336
			c-0.155,1.254-0.538,2.281-0.939,3.289c-0.479,1.244-0.863,2.582-1.174,3.994c0.041,1.294-0.855,1.65-0.705,3.054
			c-0.507,1.137-0.726,2.563-0.939,3.993c-0.334,2.094-1.05,3.806-1.645,5.638c-0.548,1.018-0.855,2.277-0.939,3.759
			c0,0.078,0,0.157,0,0.235c-0.184,1.226-0.963,1.855-1.41,2.818c0,0.079,0,0.157,0,0.235c-1.104,4.69-0.596,10.992-0.705,16.679
			c0,0.392,0,0.783,0,1.175c-0.544,1.1-0.081,3.207-0.235,4.698c-0.434,1.993-0.324,4.53-0.939,6.342
			c-0.414,0.684-0.536,1.657-0.705,2.584c0,0.079,0,0.157,0,0.235c-0.932,2.514-2.284,4.606-3.758,6.577c0,0.078,0,0.157,0,0.235
			c-3.558,3.411-6.008,7.93-7.987,12.92c0,0.078,0,0.156,0,0.234c-0.572,0.524-0.659,1.534-0.939,2.35
			c-0.105,1.069-0.604,1.745-0.939,2.584c-0.665,0.667-0.86,1.803-1.175,2.819c0,0.078,0,0.156,0,0.234
			c-2.791,3.474-6.27,6.259-9.162,9.632c-0.038-1.293-0.11-2.553-0.47-3.524c0-0.078,0-0.156,0-0.234
			c-0.029-1.615-0.106-3.183-0.47-4.464c0-0.078,0-0.156,0-0.234c0-1.331,0-2.662,0-3.993c0-0.079,0-0.157,0-0.235
			c0.536-0.326,0.087-1.635,0.235-2.349c0.541-0.557,0.084-2.108,0.235-3.055c0-0.078,0-0.156,0-0.234
			c0.543-0.788,0.083-2.58,0.235-3.759c0-0.156,0-0.313,0-0.47c0.541-4.144,0.11-9.35-0.47-13.155c0-0.078,0-0.156,0-0.234
			c-0.205-2.536-0.621-4.86-1.409-6.813c0-0.078,0-0.157,0-0.235c-0.233-1.411-0.585-2.703-1.175-3.758c0-0.079,0-0.157,0-0.235
			c-0.167-1.008-0.383-1.966-0.939-2.584c0-0.078,0-0.156,0-0.235c-0.281-0.815-0.368-1.824-0.94-2.349c0-0.078,0-0.156,0-0.235
			c-1.146-3.396-2.432-6.651-3.993-9.631c0-0.078,0-0.156,0-0.235c-0.428-1.06-0.739-2.236-1.41-3.054c0-0.078,0-0.156,0-0.234
			c-0.428-1.06-0.739-2.237-1.409-3.054c0-0.079,0-0.157,0-0.235c-1.315-2.522-2.114-6.812-5.873-6.107c-0.078,0-0.157,0-0.235,0
			c-0.701,0.004-0.799,0.61-1.41,0.704c-0.584-0.355,0.145-2.023-0.47-2.349c-0.052-0.183-0.006-0.464-0.235-0.47
			c0-0.705,0-1.409,0-2.114c-0.138-1.977-0.25-3.978-0.705-5.638c0-0.078,0-0.157,0-0.235c0.018-1.035-0.017-2.019-0.705-2.349
			c-0.184-0.89,0.336-1.534,0-3.054c0.137-1.156-0.772-1.264-1.879-1.175c-0.936-0.239-1.813-0.536-2.114-1.41
			c0-0.157,0-0.313,0-0.47c0-0.783,0-1.566,0-2.349c2-1.211,4.024-2.397,5.638-3.994c0.079,0,0.157,0,0.235,0
			c4.355-3.319,9.389-5.958,12.45-10.571c1.396-2.597,2.233-5.754,3.289-8.692c1.178-2.346,1.917-5.13,3.054-7.517
			c0-0.078,0-0.156,0-0.235c1.047-1.634,1.861-3.501,2.754-5.29c0.417-0.5,0.87-0.966,1.362-1.393
			c0.001-0.002,0.003-0.003,0.005-0.005c1.816-0.767,3.333-1.833,5.041-2.708c0.078,0,0.157,0,0.235,0
			c1.405-0.553,2.882-1.033,3.994-1.879c0.078,0,0.156,0,0.235,0c2.229-0.59,2.842-2.796,3.289-5.168c0-0.079,0-0.157,0-0.235
			c0.725-1.076,1.076-2.526,1.41-3.993c-0.018-0.409,0.052-0.73,0.235-0.94c0.668-1.29,1.019-2.896,1.409-4.463
			c0.395-2.111,0.915-4.097,1.41-6.108c0.635-1.558,0.866-3.519,1.174-5.403c-0.075-0.545,0.038-0.902,0.235-1.175
			c1.611-2.657-0.941-5.5-1.879-7.752c0-0.078,0-0.156,0-0.235c-0.669-1.602-1.208-3.334-2.114-4.698
			c-0.508-1.841-1.369-3.33-1.879-5.168c-0.451-1.272-0.666-2.779-1.41-3.758c0.018-0.409-0.052-0.731-0.235-0.94
			c-0.245-1.321-0.633-2.499-1.174-3.523c-0.363-0.341-0.493-0.917-0.47-1.645c-0.619-1.261-1.087-2.672-1.41-4.229
			c-0.745-1.604-1.33-3.369-1.645-5.403c-0.925-2.441-1.365-5.369-1.879-8.222c0.037-1.29-0.177-2.329-0.94-2.819
			c0-0.079,0-0.157,0-0.235c-1.606-2.231-3.304-4.37-5.403-6.108c-0.3-0.013-0.325-0.301-0.705-0.235
			c-0.247-1.163-0.381-2.438-0.94-3.289c-0.303-0.95-0.642-1.864-0.705-3.054c-0.246-1.163-0.381-2.438-0.939-3.289
			c-0.379-1.265-0.799-2.49-0.94-3.994c-0.151-0.866-0.241-1.794-0.705-2.349c-0.379-1.265-0.799-2.49-0.939-3.993
			c-0.247-1.163-0.381-2.438-0.94-3.289c-0.303-0.949-0.642-1.863-0.705-3.054c-0.247-1.163-0.381-2.438-0.939-3.289
			c-0.379-1.266-0.799-2.49-0.94-3.994c-0.246-1.163-0.381-2.438-0.939-3.289c-0.304-0.95-0.642-1.864-0.705-3.054
			c-0.247-1.163-0.381-2.438-0.94-3.289c-0.379-1.265-0.799-2.49-0.939-3.993c-0.151-0.867-0.241-1.795-0.705-2.349
			c-0.14-1.504-0.956-2.333-0.939-3.994c-0.246-1.163-0.381-2.438-0.939-3.289c-0.304-0.95-0.643-1.864-0.705-3.054
			c-0.246-1.163-0.38-2.438-0.939-3.289c-0.379-1.265-0.799-2.49-0.939-3.994c-0.151-0.866-0.241-1.794-0.705-2.349
			c-0.141-1.504-0.956-2.333-0.939-3.994c-0.247-1.163-0.381-2.438-0.939-3.289c-0.141-1.504-0.957-2.333-0.94-3.994
			c-0.246-1.163-0.381-2.438-0.939-3.289c-0.304-0.95-0.642-1.864-0.705-3.054c-0.247-1.163-0.381-2.438-0.94-3.289
			c-0.141-1.504-0.956-2.333-0.939-3.994c-0.247-1.163-0.381-2.438-0.94-3.289c-0.303-0.949-0.642-1.863-0.705-3.054
			c-0.246-1.163-0.381-2.438-0.939-3.289c-0.141-1.503-0.957-2.332-0.94-3.993c-0.246-1.163-0.38-2.438-0.939-3.289
			c-0.141-1.503-0.957-2.332-0.939-3.993c-0.328-1.552-0.609-3.149-1.175-4.463c-0.365-0.888-0.598-1.908-0.705-3.054
			c-0.559-0.851-0.693-2.126-0.94-3.289c-0.456-1.58-0.956-3.116-1.174-4.933c-0.324-0.636-0.678-1.188-1.154-1.568
			c-0.007-0.025-0.014-0.05-0.021-0.076c-0.022,0.003-0.046,0.004-0.068,0.008c-0.625-0.454-1.462-0.616-2.751-0.243
			c-1.566,0-3.132,0-4.698,0c0,0.078,0,0.156,0,0.235c-0.783,0-1.566,0-2.349,0c-0.235,0-0.47,0-0.705,0c-1.331,0-2.662,0-3.994,0
			c0-0.079,0-0.157,0-0.235c-1.023,0.151-2.655-0.307-3.289,0.235c-0.661,0.2-0.964,0.759-1.879,0.705
			c-2.289,0.843-3.996,2.268-6.107,3.289c-0.078,0-0.157,0-0.235,0c-1.435,1.071-3.348,1.664-4.698,2.819
			c-1.904,0.367-2.56,1.982-4.463,2.349c-1.049,0.674-2.499,0.946-3.289,1.879c-1.194,0.455-3.841,0.696-3.289,2.114
			c-1.161,1.252,0.258,2.285,0.705,3.289c0.107,0.832,0.522,1.357,0.47,2.349c0.151,0.867,0.241,1.795,0.705,2.349
			c0.233,0.628,0.484,1.239,0.47,2.114c0.151,0.867,0.241,1.795,0.705,2.349c0.197,0.273,0.31,0.63,0.235,1.175
			c0.151,0.866,0.241,1.794,0.705,2.349c0.197,0.272,0.31,0.629,0.235,1.174c0.151,0.867,0.241,1.795,0.705,2.349
			c0.197,0.272,0.31,0.63,0.235,1.175c0.152,0.866,0.241,1.794,0.705,2.349c0.197,0.272,0.31,0.629,0.235,1.174
			c0.151,0.867,0.241,1.795,0.705,2.349c0.233,0.628,0.484,1.239,0.47,2.114c0.151,0.866,0.241,1.795,0.705,2.349
			c0.197,0.272,0.31,0.63,0.235,1.174c0.151,0.867,0.241,1.795,0.705,2.349c0.197,0.273,0.31,0.63,0.235,1.175
			c0.151,0.866,0.241,1.794,0.705,2.349c0.197,0.272,0.31,0.629,0.235,1.174c0.152,0.867,0.241,1.795,0.705,2.349
			c0.377,1.972,1.051,3.647,1.409,5.638c0.151,0.867,0.241,1.795,0.705,2.349c0.197,0.273,0.31,0.63,0.235,1.175
			c0.151,0.867,0.241,1.795,0.705,2.349c0.382,1.028,0.584,2.235,0.939,3.289c0.378,2.022-1.934,1.355-3.054,1.879
			c-0.272,0.197-0.63,0.31-1.174,0.235c-0.971,0.438-1.676,1.144-2.114,2.114c0.01,0.323-0.111,0.516-0.235,0.705
			c-0.514,0.504-0.742,1.294-0.705,2.349c-0.449,0.805-0.012,2.431,0.235,3.289c1.613,5.277,2.81,10.972,3.289,17.384
			c0.15,0.868-0.304,2.34,0.235,2.819c0,0.078,0,0.156,0,0.235c0.156,2.507-0.312,5.636,0.235,7.752
			c-0.17,0.718,0.24,0.856,0.235,1.41c0.153,1.334-0.309,3.284,0.235,4.228c0,0.157,0,0.313,0,0.47
			c0.147,0.714-0.301,2.023,0.235,2.349c0.333,2.721,0.786,5.322,1.41,7.752c0.155,2.115-0.312,4.853,0.235,6.577
			c0.199,1.642,0.446,3.817,0,5.403c0,0.078,0,0.156,0,0.235c-0.078,0-0.157,0-0.235,0c-0.079,1.017-1.372,0.821-1.41,1.879
			c-2.501,2.588-5.459,4.72-6.577,8.692c0,0.548,0,1.096,0,1.645c0.055,0.962,0.115,1.92,0.47,2.584c0,0.078,0,0.156,0,0.235
			c0,1.331,0,2.662,0,3.993c-0.063,0.846,0.434,1.132,0.47,1.879c-0.143,0.013-0.278,0.035-0.235,0.235
			c-1.516,1.147-3.015,2.31-3.994,3.994c-0.671,1.443-0.702,3.526-1.174,5.168c-0.375,0.643-0.484,1.552-0.47,2.584
			c-0.417,0.444-0.171,1.552-0.235,2.349c-0.541,0.556-0.084,2.108-0.235,3.054c0.086,1.292,0.24,1.604,0,2.819
			c-0.855,0.868-1.282,2.164-2.114,3.054c0,0.078,0,0.156,0,0.235c-1.005,1.031-1.547,2.525-1.879,4.229c0,0.078,0,0.156,0,0.235
			c-0.39,0.481-0.39,2.572,0,3.054c-0.009,1.497,0.088,2.887,0.47,3.993c0.499,5.71,2.707,10.887,2.819,16.209
			c-0.542,0.789-0.082,2.58-0.235,3.759c0,0.078,0,0.156,0,0.235c-0.536,0.326-0.087,1.635-0.235,2.349
			c-0.077,0.863,0.2,2.08-0.235,2.584c-0.539,0.479-0.085,1.951-0.235,2.819c0,0.235,0,0.47,0,0.705
			c-0.546,1.881-0.08,4.775-0.235,7.047c0,0.235,0,0.47,0,0.705c-0.544,0.944-0.082,2.894-0.235,4.229c0,0.157,0,0.313,0,0.47
			c-0.541,0.556-0.084,2.108-0.235,3.054c-0.203,5.121-0.985,9.664-1.879,14.094c-0.536,0.326-0.088,1.635-0.235,2.349
			c0,0.079,0,0.157,0,0.235c-0.538,0.402-0.086,1.793-0.235,2.584c0,0.079,0,0.157,0,0.235c-0.547,2.351-0.08,5.715-0.235,8.457
			c-0.167,0.694-0.064,1.658-0.705,1.879c-3.234,1.699-7.81,3.309-7.282,7.987c0.377,1.267,0.658,2.631,0.705,4.229
			c0.014,1.082,0.109,2.084,0.47,2.818c-0.031,0.579-0.036,1.132,0.235,1.41c-0.204,2.851-3.173,2.935-3.759,5.402
			c0,0.079,0,0.157,0,0.235c-0.391,1.569-0.391,4.773,0,6.343c0.02,2.643,0.363,4.962,0.94,7.047c0,0.078,0,0.156,0,0.235
			c0.068,1.263,0.246,2.416,0.705,3.288c0.729,3.265,1.453,6.534,2.114,9.866c0.016,0.924,0.113,1.766,0.47,2.35
			c-0.032,1.441,0.552,2.267,0.705,3.523c0.167,1.086,0.475,2.031,0.94,2.819c1.131,3.019,1.626,6.674,2.349,10.101
			c0.083,1.092,0.301,2.048,0.705,2.819c0.233,0.628,0.484,1.238,0.47,2.114c0.158,1.329,0.391,2.584,0.939,3.523
			c0.326,0.3,0.144,1.109,0.47,1.409c0.271,0.981,0.375,2.13,0.705,3.054c0,0.079,0,0.157,0,0.235
			c0.354,1.368,0.562,2.883,1.174,3.993c0,0.078,0,0.156,0,0.235c0.151,0.866,0.241,1.795,0.705,2.349
			c0.197,0.272,0.31,0.63,0.235,1.175c0.563,2.49,1.08,5.027,1.879,7.282c2.773,12.34,5.713,24.512,7.752,37.586
			c0.055,0.962,0.115,1.92,0.47,2.584c0.299,0.953,0.435,2.07,0.47,3.288c-0.014,1.032,0.094,1.941,0.47,2.584
			c0,0.079,0,0.157,0,0.235c0.146,1.185,0.289,2.373,0.705,3.289c1.778,7.617,2.72,16.072,3.993,24.195
			c-0.014,1.032,0.095,1.941,0.47,2.584c0.271,0.277,0.266,0.83,0.235,1.409c0.083,1.092,0.302,2.048,0.705,2.819
			c0.209,0.574,0.547,1.019,0.47,1.879c0.188,1.144,0.411,2.251,0.939,3.054c0,0.079,0,0.157,0,0.235
			c0.334,1.467,0.685,2.917,1.41,3.993c0.265,1.692,1.067,2.849,1.645,4.229c0.26,0.914,0.649,1.699,1.174,2.35
			c0.013,0.3,0.301,0.325,0.235,0.704c0.911,2.064,1.752,4.199,3.054,5.873c-0.01,0.323,0.111,0.516,0.235,0.705
			c0.509,0.9,0.823,1.995,1.644,2.584c0,0.078,0,0.156,0,0.234c0.803,1.312,1.545,2.683,2.584,3.759c0,0.078,0,0.156,0,0.235
			c1.635,2.357,3.165,4.821,5.168,6.812c0,0.079,0,0.157,0,0.235c3.268,3.937,6.106,8.302,9.396,12.215
			c0.073,0.555,0.816,0.438,0.705,1.175c4.254,3.969,5.592,10.853,11.511,13.155c0.079,0,0.157,0,0.235,0
			c1.852,1.046,4.204,1.591,6.813,1.879c0.39,0.394,1.379,0.188,2.114,0.235c0.157,0,0.313,0,0.47,0
			c1.569,0.391,4.773,0.391,6.343,0c0.078,0,0.156,0,0.235,0c2.353-0.286,4.834-1.965,7.215-3.443
			c0.008-0.004,0.014-0.008,0.022-0.012c0.338-0.183,0.666-0.361,0.984-0.536c0.167-0.092,0.322-0.178,0.482-0.266
			c0.159-0.089,0.313-0.174,0.464-0.258c0.427-0.238,0.83-0.463,1.169-0.653c1.433-0.803,2.853-1.629,4.264-2.468
			c0.333-0.198,0.662-0.398,0.992-0.599c1.2-0.724,2.389-1.459,3.566-2.208c0.207-0.132,0.414-0.262,0.62-0.394
			c2.927-1.879,5.769-3.833,8.496-5.855c0.003-0.003,0.007-0.005,0.011-0.008c-0.002,0.002-0.005,0.004-0.007,0.005
			c0.027-0.021,0.055-0.041,0.083-0.062c-0.025,0.019-0.05,0.038-0.076,0.057c10.874-7.868,20.424-16.801,28.329-27.229
			c0,0.392,0,0.783,0,1.175c-0.392,4.62-0.392,10.884,0,15.504c0,0.235,0,0.47,0,0.705c-0.155,1.8,1.734,1.554,1.879,3.054
			c0,0.078,0,0.156,0,0.235c0.066,0.795,0.374,1.349,0.235,2.349c0,0.235,0,0.47,0,0.705c-0.386,2.589-0.302,5.648-0.705,8.222
			c-0.513,5.595-1.211,11.004-1.879,16.443c-0.536,0.326-0.088,1.636-0.235,2.35c0,0.078,0,0.156,0,0.234
			c-0.536,0.326-0.088,1.636-0.235,2.35c-0.028,0.677,0.128,1.537-0.235,1.879c-0.538,0.402-0.086,1.793-0.235,2.584
			c0,0.078,0,0.157,0,0.235c-0.54,0.556-0.084,2.108-0.235,3.054c0,0.156,0,0.313,0,0.47c-0.542,0.788-0.082,2.58-0.235,3.759
			c0,0.078,0,0.156,0,0.234c-0.544,0.944-0.082,2.895-0.235,4.229c0,0.939,0,1.88,0,2.819s0,1.879,0,2.818c0,0.078,0,0.157,0,0.235
			c-0.389,0.329-0.389,2.255,0,2.584c0.147,0.714-0.301,2.023,0.235,2.349c-0.023,0.963-0.046,1.926,0.235,2.584
			c0.153,1.335-0.309,3.285,0.235,4.229c0.14,0.487-0.29,1.543,0.235,1.645c0.053,2.14,0.314,4.071,0.705,5.873
			c0,2.506,0,5.011,0,7.517c-0.197,0.272-0.31,0.63-0.235,1.175c-0.867,0.689-0.185,2.454-0.47,3.289
			c-0.08,0.233-0.183,0.44-0.296,0.638c0.1-0.115,0.191-0.241,0.271-0.375c-0.081,0.135-0.171,0.261-0.272,0.376
			c0,0.001-0.001,0.002-0.002,0.003c0-0.001,0.001-0.001,0.001-0.002c-0.109,0.127-0.227,0.244-0.348,0.358c0,0.001,0,0.001,0,0.001
			c-0.044,0.041-0.088,0.082-0.133,0.123c-0.325,0.3-0.653,0.595-0.886,0.985c-1.516,1.138-3.434,1.879-3.739,4.47
			c-0.516,2.304-1.47,4.168-2.349,6.107c-1.101,1.875-1.908,4.043-2.819,6.108c0,0.078,0,0.156,0,0.234
			c-0.888,1.696-1.944,3.225-2.819,4.934c0,0.234,0,0.47,0,0.704c2.171,2.37,4.718,4.365,8.222,5.403c0.079,0,0.157,0,0.235,0
			c0.998,1.038,0.229,3.844,0.47,5.638c-0.066,0.796-0.374,1.349-0.235,2.349c0,0.471,0,0.94,0,1.41
			c-4.405,0.168-2.309,5.657,2.114,4.229c0.157,0,0.313,0,0.47,0c1.1,0.544,3.208,0.08,4.698,0.234c0.078,0,0.156,0,0.235,0
			c1.021,0.545,3.051,0.081,4.463,0.235c0.079,0,0.157,0,0.235,0c0.944,0.544,2.894,0.081,4.228,0.234c0.157,0,0.313,0,0.47,0
			c0.944,0.544,2.894,0.082,4.229,0.235c0.157,0,0.313,0,0.47,0c0.944,0.544,2.894,0.081,4.229,0.234c0.157,0,0.313,0,0.47,0
			c1.178,0.545,3.365,0.081,4.933,0.235c0.157,0,0.313,0,0.47,0c1.568,0.546,4.148,0.08,6.107,0.234c0.235,0,0.47,0,0.705,0
			c62.015,0.705,125.678-0.234,187.694,0.471c0.052,0.183,0.196,0.272,0.47,0.234c0.078,0,0.156,0,0.234,0
			c0.634,0.541,2.266,0.083,3.289,0.235c0.47,0,0.939,0,1.409,0c5.862,0.518,12.608,0.151,18.942,0.197
			c1.99,0.124,3.899,0.243,5.546,0.202c0.001,0,0.001,0,0.002,0h0.001c0.369,0.025,0.752,0.038,1.114,0.07c0.47,0,0.939,0,1.409,0
			c0.763-0.726,2.044-0.932,2.114-2.35c0-0.156,0-0.313,0-0.47c-0.333-1.389-1.089-2.356-1.175-3.993
			c-0.363-0.342-0.207-1.203-0.234-1.88c0.318-2.98-2.255-3.069-2.35-5.638c-0.259-2.873-1.139-5.125-1.879-7.517
			c-0.782-1.488-1.872-2.67-2.819-3.994c-0.3-0.736-0.789-2.208-0.234-3.054c0.634-1.01,1.455-1.833,1.645-3.288
			c0-0.078,0-0.157,0-0.235c0.391-0.945,0.391-3.518,0-4.463c0-0.078,0-0.157,0-0.235c-0.023-1.935-0.104-3.812-0.47-5.402
			c0-0.079,0-0.157,0-0.235c-0.153-1.257,0.308-3.127-0.235-3.993c0-0.078,0-0.157,0-0.235c-0.073-2.667-0.104-5.376-0.47-7.752
			c0-0.078,0-0.156,0-0.235c-0.156-3.523,0.313-7.673-0.235-10.806c0-0.078,0-0.156,0-0.234c0-0.157,0-0.313,0-0.47
			c1.285-0.203,2.183-0.793,3.524-0.94c1.356-0.443,2.453-1.148,3.288-2.114c0.078,0,0.157,0,0.235,0
			c2.226-1.924,4.63-3.669,6.577-5.872c0.894,0.124,1.546,0.49,2.35,0.704c1.567,1.017,3.897,1.271,5.872,1.88
			c0.664,0.494,2.632,0.291,3.054-0.235c3.145,0.012,5.482-0.782,7.048-2.349c1.474-1.021,2.865-2.204,4.698-3.759
			c2.114-1.793,3.069-4.729,7.047-3.759c0.235,0,0.47,0,0.705,0c0.633,0.542,2.266,0.084,3.289,0.235
			c0.575-0.42,1.131-0.86,1.672-1.314c-0.001,0.001-0.002,0.001-0.003,0.002c0.001-0.001,0.003-0.002,0.004-0.003l-0.001,0.001
			c0.001-0.001,0.002-0.001,0.003-0.002c0.619-0.39,1.168-0.851,1.601-1.428c3.162-2.974,5.872-6.404,8.939-9.472
			c3.628-3.627,7.361-7.014,11.291-10.33c0.223-0.144,0.415-0.318,0.596-0.505c0.269-0.224,0.529-0.452,0.799-0.676
			c0.078,0,0.156,0,0.234,0c1.531-1.522,3.759-2.349,5.168-3.993c1.132-0.2,1.688-0.975,2.819-1.175
			c1.517-0.91,3.442-1.412,4.698-2.584c0.853-0.321,1.705-0.644,2.349-1.174c5.523-2.855,9.579-7.179,14.564-10.571
			c0.078,0,0.157,0,0.235,0c2.319-1.518,4.806-2.868,6.813-4.698c0.078,0,0.156,0,0.234,0c3.753-2.668,7.899-4.943,11.511-7.752
			c0.078,0,0.157,0,0.235,0c0.972-0.516,1.874-1.102,2.584-1.879c0.078,0,0.156,0,0.234,0c9.831-6.788,19.771-13.465,26.745-23.108
			c0.004-0.003,0.006-0.007,0.01-0.011c0.184-0.177,0.311-0.364,0.401-0.561c0-0.001,0.001-0.001,0.001-0.002
			c0,0,0,0.001-0.001,0.001c0.003-0.007,0.006-0.014,0.009-0.021c-0.003,0.006-0.005,0.013-0.008,0.02
			c0.6-0.854,1.186-1.725,1.737-2.629c0-0.078,0-0.156,0-0.234c2.74-3.054,4.761-6.828,6.813-10.571c0-0.078,0-0.156,0-0.235
			c0.771-0.481,0.896-1.608,1.409-2.349c0-0.078,0-0.157,0-0.235c1.538-2.142,2.611-4.748,3.759-7.282c0-0.078,0-0.156,0-0.234
			c0.602-0.808,1.07-1.748,1.409-2.819c0.985-2.93,2.31-5.521,3.523-8.222c0.613-0.64,0.838-1.668,1.175-2.584
			c0-0.078,0-0.156,0-0.234c0.766-0.958,1.15-2.296,1.645-3.524c-0.066-0.379,0.222-0.404,0.234-0.704
			c0.525-0.65,0.914-1.436,1.175-2.35c0-0.078,0-0.156,0-0.234c1.975-3.429,3.595-7.212,5.638-10.571
			c0.052-0.888,0.738-1.141,0.939-1.879c1.354-1.778,2.479-3.786,3.054-6.343c0-0.078,0-0.157,0-0.235
			c-0.074-0.545,0.038-0.901,0.235-1.174c0-0.783,0-1.566,0-2.35C575.014,537.683,574.395,536.5,574.355,534.738z M561.759,507.889
			c0.282,0.494,0.572,0.982,0.851,1.479C562.331,508.871,562.041,508.383,561.759,507.889
			C561.759,507.889,561.759,507.889,561.759,507.889z M557.924,501.425c0,0.001,0.001,0.001,0.001,0.002
			c-1.103-1.781-2.234-3.539-3.377-5.289C555.69,497.886,556.821,499.645,557.924,501.425z M455.466,366.43
			C455.466,366.43,455.466,366.43,455.466,366.43c-0.001,0.002-0.001,0.003-0.002,0.005
			C455.465,366.433,455.465,366.431,455.466,366.43z M455.275,365.347c-0.731-2.814-3.063-4.615-4.574-7.004
			C452.216,360.73,454.544,362.533,455.275,365.347z M455.275,365.347c-5.336-6.065-7.563-13.949-11.641-20.051
			C447.715,351.397,449.939,359.282,455.275,365.347z M415.791,301.003c-0.053,0.183-0.007,0.463-0.235,0.47
			c0.105,1.044-0.167,1.712-0.47,2.349c0.097,0.566-0.429,0.51-0.234,1.174c-0.973,0.033-0.245-1.635-0.47-2.349
			c0-0.078,0-0.157,0-0.235c-0.079,0-0.157,0-0.235,0c0-0.705,0-1.41,0-2.114c-0.394-0.39-0.187-1.379-0.234-2.114
			c0-0.313,0-0.626,0-0.939c-0.079,0-0.157,0-0.235,0c0,0.626,0,1.253,0,1.879c-0.665-6.46-2.202-12.049-4.463-16.914
			c-0.102-0.447-0.507-0.59-0.47-1.175c0-0.078,0-0.156,0-0.235c0.061-0.688-0.531-0.722-0.471-1.409
			c-0.666-1.526-1.497-2.888-2.113-4.463c-0.821-0.667-1.218-1.758-1.88-2.584c-0.253-0.609-0.85-0.874-0.939-1.645
			c-0.716-0.38-1-1.192-1.409-1.879c-0.356-0.097-1.021-0.864-0.778-1.115c0.127,0.066,0.251,0.14,0.376,0.214
			c1.087,0.991,2.264,1.893,2.986,3.25c1.224,1.595,2.42,3.218,3.523,4.933c0-0.078,0-0.156,0-0.235
			c0.568,0.293,0.76,0.963,0.939,1.645c0.013,0.3,0.301,0.325,0.235,0.705c0,0.078,0,0.157,0,0.235
			c0.585,1.294,1.327,2.432,1.644,3.994c0.614,0.64,0.838,1.668,1.175,2.584c0.136,0.491,0.583,0.67,0.47,1.41
			c0.886,1.542,1.386,3.469,1.88,5.403c0,0.078,0,0.156,0,0.235c0.473,0.702,0.691,1.658,0.704,2.819
			c0.271,0.278,0.267,0.831,0.235,1.41c0.558,0.146-0.036,1.446,0.47,1.644C415.791,298.967,415.791,299.985,415.791,301.003z
			 M403.439,269.427c0.896,0.876,1.724,1.921,2.482,3.021C405.162,271.349,404.336,270.303,403.439,269.427z M407.946,263.149
			c0,0,0.001,0.001,0.001,0.001c-0.002-0.002-0.005-0.004-0.007-0.006C407.942,263.146,407.944,263.148,407.946,263.149z
			 M391.596,256.135c-0.525,0.726,1.983,2.368,2.818,3.289c-0.61-1.116-2.56-2.134-2.584-3.054c0-0.078,0-0.156,0-0.235
			c3.421,1.277,6.886,2.511,9.632,4.463c1.46,0.654,2.568,1.66,3.523,2.819c8.013,7.795,15.059,18.83,13.625,34.532
			c-3.107-12.566-7.603-24.021-15.739-31.713c-2.821-2.667-6.111-4.459-8.927-6.813
			C393.551,259.095,389.976,255.632,391.596,256.135z M384.783,261.773c0.633-0.698,0.893-1.769,1.645-2.349
			c0.355-0.819,0.429-1.92,1.174-2.349c0.23,0.317,0.627,0.469,0.939,0.705c0.956,0.297,1.362,1.144,2.35,1.409
			c0.881,0.685,1.664,1.468,2.349,2.349c0.79,1.481,2.135,2.408,3.054,3.759c0-0.078,0-0.157,0-0.235
			c0.873,0.772,1.583,1.706,2.114,2.819c0,0.078,0,0.157,0,0.235c0,0.236,0.19,0.28,0.235,0.47c0,0.079,0,0.157,0,0.235
			c1.49,1.408,2.371,3.423,3.523,5.168c0,0.079,0,0.157,0,0.235c1.129,1.22,1.953,2.745,2.584,4.463
			c0.136,0.491,0.583,0.671,0.47,1.41c0.978,1.764,1.699,3.782,2.35,5.873c0.073,0.318,0.334,0.449,0.234,0.94
			c0.896,1.454,0.959,3.739,1.645,5.403c0-0.235,0-0.47,0-0.705c0.53,1.271,0.891,2.711,0.939,4.463c0,0.391,0,0.783,0,1.174
			c-0.072,1.326,0.417,2.089,0.235,3.523c0.183,0.052,0.273,0.197,0.234,0.47c0,1.252,0,2.505,0,3.758c0,0.078,0,0.157,0,0.235
			c0.079,0,0.157,0,0.235,0c0-1.174,0-2.349,0-3.523c0.481,9.643,0.415,20.774-3.523,26.78c-2.653-0.166-4.88-0.758-7.282-1.175
			c-1.699-5.74-4.038-10.84-8.222-14.094c-0.967-5.533-6.521-6.479-9.632-9.867c2.959-2.775-1.593-4.866-3.289-7.282
			c-4.407-6.28-8.269-16.703-5.402-25.605c0.814-0.125,1.251-0.628,2.114-0.705c-0.558,17.483,8.502,26.988,15.974,36.646
			c1.17,1.512,2.306,3.064,3.289,4.698c0.932,1.549,1.471,3.728,3.288,4.698c0.438-2.061-0.716-4.348-1.644-6.108
			c-2.904-5.507-7.157-9.5-10.807-14.799c-3.168-4.602-6.078-10.865-7.517-18.088c-0.422-2.116-1.28-5.816-0.705-7.752
			c0.492-1.655,3.28-2.837,4.464-4.228c0.419-0.493,0.805-0.992,1.175-1.495c0.077-0.051,0.142-0.113,0.234-0.149
			c0.432-0.352,0.586-0.98,1.175-1.174C384.783,261.93,384.783,261.852,384.783,261.773z M297.869,459.61
			c-0.759,0.744-1.524,1.48-2.295,2.213c0.766-0.727,1.525-1.459,2.278-2.197c-6.584,6.118-13.708,11.762-21.465,16.534
			c-0.04,0.021-0.08,0.042-0.12,0.063c15.567-27.063,33.992-52.983,51.667-79.376c1.012-1.511,2.396-3.531,3.759-5.638
			c1.183-1.829,3.298-4.695,3.523-5.873c0.678-3.543-2.258-7.682-3.523-10.806c-1.299-3.203-3.36-7.304-3.289-10.806
			c0.032-1.556,0.751-3.159,1.175-4.698c0.415-1.509,0.473-3.292,0.939-4.698c1.452-4.373,7.424-9.543,10.806-12.685
			c4.388-4.076,9.023-7.269,12.909-10.328c0,0-0.001,0.001-0.001,0.001c0,0.002-0.001,0.003-0.002,0.005c0,0,0,0,0.001-0.001
			c-0.073,0.178-0.161,0.352-0.249,0.526c0.088-0.173,0.176-0.347,0.248-0.525c-4.361,5.237-9.229,9.875-14.315,14.549
			c-1.614,1.483-3.901,2.684-4.698,4.933c3.096,0.642,5.029-2.653,7.047-4.463c4.276-3.836,8.669-7.668,12.686-11.98
			c1.969-2.113,3.176-4.923,5.638-6.107c0.648,2.092,2.688,2.793,4.934,3.289c-3.908,5.847-7.045,12.268-9.866,19.028
			c-2.928,7.013-5.034,14.424-7.752,21.612c-2.62,6.929-6.203,13.485-9.162,20.202c-5.955,13.518-10.08,29.152-17.618,40.875
			c-3.755,5.839-8.228,10.866-13.053,16.098c-0.27,0.271-0.535,0.544-0.807,0.815c-1.491,1.491-2.98,3.005-4.478,4.521
			c-1.629,1.65-3.268,3.302-4.933,4.934C297.858,459.621,297.863,459.615,297.869,459.61z M292.879,464.325
			c-5.03,4.552-10.404,8.697-16.485,11.832C282.474,473.022,287.847,468.873,292.879,464.325z M215.178,510.778
			c-0.09-7.414-1.235-14.794-1.645-21.847c-0.426-7.349-0.925-14.357-0.705-21.377c0.904-28.836,9.483-54.254,16.444-77.521
			c3.594-12.012,9.654-22.667,14.33-33.592c0.2-0.043,0.221,0.092,0.235,0.235c2.035,10.759,5.461,20.398,9.631,29.833
			c1.186,2.685,2.403,7.481,4.698,8.222c2.5,0.807,5.854-2.45,8.222-3.994c12.936-8.435,25.205-16.964,38.055-25.135
			c5.2-3.307,10.534-7.081,16.914-8.222c-5.199,9.862-11.27,19.211-16.679,29.128c-5.435,9.963-9.826,20.403-14.564,31.008
			c-9.364,20.957-18.794,42.516-26.31,65.07c-8.221,5.82-17.469,9.703-25.84,15.034c-8.293,5.281-15.323,12.226-23.491,17.383
			C215.201,513.513,215.198,512.44,215.178,510.778z M257.931,368.188c0-0.078,0-0.157,0-0.235c0.223-0.402,0.474-0.776,0.733-1.141
			c0-0.001,0.001-0.001,0.001-0.002c0,0,0,0,0.001-0.001c0,0,0,0,0.001-0.001c0.575-0.808,1.21-1.557,1.806-2.345
			c-0.595,0.789-1.231,1.537-1.806,2.345c1.407-1.743,3.751-3.225,3.727-5.198c-0.03-2.42-3.391-3.354-4.826-5.479
			c0,0,0-0.001-0.001-0.001c-0.1-0.171-0.19-0.351-0.277-0.535c-0.112-0.253-0.222-0.509-0.299-0.797
			c-0.061-1.036-0.375-1.817-0.235-3.054c1.688-2.696,3.711-5.059,5.403-7.752c0.346-0.593,0.83-1.049,0.94-1.879
			c1.727-1.875,2.055-5.148,4.228-6.577c0,3.367,0,6.734,0,10.101c0,0.078,0,0.156,0,0.235c0,0.397,0,0.794,0,1.192
			c-0.139,2.08-0.845,4.704-0.481,6.358c0,0,0,0,0,0c-0.006,0.123,0.022,0.247,0.07,0.37c0.003,0.007,0.007,0.015,0.01,0.022
			c0.049,0.119,0.116,0.233,0.196,0.342c0,0.001,0.001,0.002,0.001,0.002c0,0,0-0.001,0-0.001c0,0.001,0.001,0.002,0.002,0.003
			c0,0-0.001-0.001-0.001-0.001c0.001,0.001,0.002,0.002,0.003,0.004c0.038,0.05,0.074,0.099,0.115,0.146
			c0.102,0.137,0.225,0.255,0.368,0.355c0.068,0.054,0.132,0.099,0.188,0.133c1.233,0.137,1.576-0.617,1.644-1.645
			c0.68-0.964,1.228-2.062,1.645-3.289c0.135-0.491,0.583-0.67,0.47-1.409c1.28-2.713,2.165-5.822,3.054-8.927
			c1.539-4.256,2.531-9.058,3.759-13.625c0.676-0.968,0.991-2.298,1.409-3.524c0.564-1.472,1.189-2.883,1.645-4.463
			c1.067-1.517,2.274-2.894,3.289-4.463c0-0.079,0-0.157,0-0.235c0.297-0.038,0.247-0.421,0.237-0.764
			c0.03-0.056,0.06-0.111,0.094-0.164c0.02-0.035,0.036-0.072,0.06-0.104c9.479-5.393,16.386-13.36,23.569-21.05
			c1.212,0.108,2.365-1.07,2.819-0.939c2.045,8.291,4.275,16.396,6.577,24.431c0.151,0.867,0.241,1.795,0.705,2.349
			c0.166,1.713,0.705,3.053,1.174,4.463c0.494,1.933,0.994,3.861,1.88,5.403c0,0.078,0,0.157,0,0.235
			c0.472,1.485,0.885,3.03,1.645,4.229c0.593,2.461,1.628,4.479,2.349,6.813c-1.12,3.969-4.242,5.937-7.047,8.222
			c-4.171,1.468-8.547,2.729-12.216,4.698c-1.07,0.339-2.012,0.807-2.818,1.409c-1.905,0.523-3.364,1.492-5.168,2.114
			c-0.816,0.281-1.825,0.368-2.35,0.94c-4.068,0.864-8.778,1.088-12.45,2.349c-0.551,0.336-1.1,0.674-1.645,1.016
			c-0.002,0.001-0.004,0.002-0.006,0.004c0.547-0.343,1.098-0.683,1.651-1.02c-4.409,2.09-8.054,4.944-10.101,9.396
			c-0.2,0.661-0.759,0.964-0.705,1.879c-0.582,0.843-1.259,3.38,0,3.994c0.078,0,0.156,0,0.235,0
			c0.013,0.143,0.035,0.278,0.235,0.235c1.884,0.083,1.961-1.641,2.349-3.054c0.36-0.893,0.946-1.56,1.174-2.584
			c2.208-2.726,4.662-5.204,8.692-6.108c0.235,0,0.47,0,0.705,0c1.816-0.298,4.204-0.024,5.873-0.47c0.47,0,0.939,0,1.409,0
			c1.801,0,3.602,0,5.403,0c0.196,0.08,0.27,0.171,0.284,0.264c-0.285,0.221-0.575,0.436-0.861,0.656
			c-1.851,1.42-3.717,2.826-5.588,4.227c0.082-0.058,0.162-0.118,0.244-0.176c-0.906,0.679-1.813,1.357-2.715,2.041
			c0.822-0.623,1.645-1.246,2.471-1.865c-5.017,3.56-10.115,7.032-14.038,11.297c-0.307,0.554-0.099,1.624-0.47,2.114
			c-3.224,2.101-6.575,4.074-9.631,6.342c-0.968,0.128-1.375,0.818-2.114,1.175c-2.591,1.402-4.661,3.326-7.282,4.698
			c-0.079-0.548-0.241-1.012-0.705-1.175c-1.229-2.842-2.561-5.583-3.759-8.457c-0.337-0.916-0.561-1.944-1.174-2.584
			c-0.385-1.466-0.944-2.293-0.235-3.759C254.929,373.329,256.324,370.652,257.931,368.188z M257.568,356.132
			c0.779,1.336,2.004,2.224,3.079,3.264C259.568,358.36,258.347,357.468,257.568,356.132z M286.333,311.174
			c0.438-0.265,0.921-0.518,1.402-0.793C287.254,310.656,286.772,310.91,286.333,311.174z M356.828,216.905
			c3.982-1.656,8.115-3.161,13.155-3.758c1.255-0.498,3.104-0.121,4.229,0.235c1.682,0.511,2.523,1.861,3.054,3.523
			c0.347,0.906,0.73,1.775,0.705,3.054c0.078,0,0.156,0,0.234,0c0,1.41,0,2.819,0,4.229c-0.036,0.276,0.041,0.667-0.234,0.705
			c-0.417,3.733-2.285,6.015-3.759,8.692c-0.952,1.553-1.906,3.105-3.289,4.229c0,0.078,0,0.156,0,0.235
			c-0.556,0.931-1.58,1.395-2.349,2.114c-0.078,0-0.156,0-0.235,0c-0.588,0.743-1.605,1.057-2.584,1.409
			c-0.839,0.336-1.514,0.835-2.584,0.94c-0.64,0.613-1.668,0.837-2.584,1.174c-0.491,0.135-0.67,0.583-1.409,0.47
			c-0.649,0.525-1.435,0.915-2.35,1.175c-0.495,0.208-0.693,0.715-1.409,0.705c-0.013,0.144-0.035,0.278-0.234,0.235
			c-1.917,0.746-3.438,1.887-5.168,2.819c-0.726,0.058-1.328,0.238-1.88,0.47c-0.782,0.063-1.613,0.853-2.349,0.235
			c0.888-2.714,1.504-5.7,1.879-8.927c0.41-1,0.627-2.192,0.705-3.524c0.283-0.813,0.512-1.68,0.47-2.819
			c0.623-2.509,1.186-5.078,1.645-7.752c0-0.078,0-0.157,0-0.235c0.43-1.058,0.506-2.469,0.704-3.758
			c0.573-1.855,0.965-3.891,1.175-6.108c0.518-0.579,0.521-1.67,0.939-2.349c0.342-0.128,1.214,0.274,1.175-0.235
			c0.078,0,0.156,0,0.235,0c0.078,0,0.156,0,0.234,0C354.081,215.594,355.379,217.761,356.828,216.905z M354.696,246.636
			c-0.048,0.026-0.095,0.054-0.143,0.081c0.02-0.012,0.038-0.024,0.057-0.036C354.639,246.666,354.668,246.652,354.696,246.636z
			 M354.352,246.83c-0.016,0.009-0.031,0.018-0.047,0.026c0.005-0.003,0.011-0.006,0.016-0.01
			C354.331,246.841,354.342,246.835,354.352,246.83z M347.197,253.786c1.448-0.196,2.831-0.458,3.993-0.939c0.079,0,0.157,0,0.235,0
			c0.664-0.194,0.608,0.331,1.175,0.235c-0.202-0.75,0.369-0.727,0.47-1.175c0.906-0.268,1.337-1.012,2.349-1.174
			c0.588-0.671,2.231-1.52,2.819-1.41c-0.053,0.183-0.007,0.463-0.235,0.47c-0.042,1.368-0.344,2.474-0.704,3.523
			c-0.152,0.867-0.241,1.795-0.705,2.349c-1.518,7.566-4.348,13.819-5.873,21.377c-0.584,0.036-0.727-0.369-1.175-0.47
			c-0.483-1.161-1.186-2.103-2.818-2.114c-1.274-0.526-2.858-0.744-3.759-1.645c-0.565,0.096-0.511-0.429-1.175-0.235
			c-0.748-0.739-2.069-0.905-2.818-1.645c0-0.078,0-0.157,0-0.235s0-0.157,0-0.235c0.336-0.682,0.914-1.122,1.879-1.174
			c2.224-2.945,3.92-6.417,4.934-10.571C346.417,257.234,346.652,255.355,347.197,253.786z M358.238,279.626
			c-0.536,0.326-0.088,1.635-0.235,2.349c-0.273,0.04-0.255,0.371-0.234,0.705c-1.44-1.223-3.13-2.195-3.759-4.229
			c0.743-2.623,1-5.734,1.879-8.222c0.785-0.396,2.863-0.73,3.523-0.47c-0.031,1.378-0.158,2.66-0.47,3.758
			c-0.399,1.009-0.492,2.327-0.47,3.759C358.057,277.722,358.302,278.829,358.238,279.626z M357.477,282.989
			c-1.035-0.183-2.04-1.458-2.969-2.564C355.438,281.529,356.44,282.806,357.477,282.989z M353.554,283.208
			c0.07,0.061,0.151,0.114,0.221,0.176c2.673,3.044,7.329,5.356,7.518,10.101c0,0.079,0,0.157,0,0.235
			c-0.149,0.791,0.303,2.182-0.235,2.584c0,0.156,0,0.313,0,0.47c-0.148,0.791,0.303,2.182-0.234,2.584c0,0.157,0,0.313,0,0.47
			c-0.149,0.791,0.303,2.182-0.235,2.584c0,0.235,0,0.47,0,0.705c-0.151,1.023,0.307,2.656-0.234,3.289c0,0.156,0,0.313,0,0.47
			c0,1.174,0,2.349,0,3.523c-0.079,0-0.157,0-0.235,0c0,0.313,0,0.626,0,0.94c0,2.271,0,4.542,0,6.812c0,1.097,0,2.193,0,3.289
			c0.141,2.724-1.07,4.098-2.349,5.403c-1.618-2.235-3.474,0.688-4.934,1.41c-3.786,2.635-6.885,5.957-11.041,8.222
			c-2.202,3.03-6.287,5.65-9.161,8.927c-1.688,1.924-2.782,4.281-4.463,5.638c0.804-3.948,1.688-8.189,0.939-12.92
			c-0.483-3.053-2.343-6.356-3.523-9.631c-3.537-9.808-5.955-20.894-8.692-30.773c-2.96-10.686-5.199-21.239-9.396-30.068
			c-1.428-3.004-3.096-5.851-3.993-8.927c12.379,3.929,23.26,11.066,34.063,16.209C343.135,277.579,349.344,279.553,353.554,283.208
			z M275.238,371.537c-0.04,0.213-0.096,0.41-0.166,0.593c0.07-0.183,0.125-0.379,0.165-0.591
			C275.237,371.538,275.237,371.537,275.238,371.537z M294.813,414.23c3.225-7.508,6.563-14.792,10.133-21.89
			c0-0.001,0.001-0.001,0.001-0.002c3.106-5.893,6.347-11.667,9.673-17.425c0.002-0.004,0.004-0.007,0.007-0.011
			c4.432-7.126,9.089-14.046,12.604-21.748c0.171,3.855-2.104,6.133-2.349,9.396c-0.425,5.657,2.657,11.693,4.698,16.443
			c0.736,1.715,2.129,3.562,2.114,4.933c-0.016,1.446-2.217,4.521-3.523,6.578c-1.702,2.678-3.443,5.335-5.198,7.984
			c-0.014,0.021-0.028,0.042-0.042,0.063c-3.812,5.697-7.713,11.407-11.568,17.053c-0.072,0.106-0.145,0.213-0.218,0.319
			c-3.774,5.489-7.53,10.947-11.163,16.395c-8.192,12.285-15.298,24.492-23.021,36.881c-2.458,3.942-4.281,9.039-9.161,10.806
			C275.639,456.959,285.605,435.665,294.813,414.23z M363.641,315.098c0-0.313,0-0.626,0-0.939c0.18-0.554,0.463-1.624,0-2.114
			c0.544-0.944,0.082-2.894,0.235-4.229c0-0.078,0-0.156,0-0.235c1.109-1.822-1.18-3.511-0.235-5.168
			c3.889,6.448,9.396,11.276,15.739,15.269c0.078,0,0.156,0,0.235,0c2.143,1.694,4.877,2.796,7.517,3.994c0.078,0,0.156,0,0.235,0
			c1.593,1.384,4.374,2.828,4.933,4.463c-0.626,0-1.253,0-1.879,0c-1.342,0.01-2.572-0.09-3.523-0.47
			c-1.417,0.085-1.927-0.736-3.055-0.94c-0.999-0.488-2.114-0.861-3.054-1.409c-0.957-0.766-2.295-1.15-3.523-1.645
			c-1.825-0.524-3.397-1.301-4.933-2.114c-1.866-0.953-3.769-1.87-5.639-2.819c-0.078,0-0.156,0-0.234,0
			C365.824,315.89,364.535,315.691,363.641,315.098z M379.615,313.688c-3.146-2.115-7.098-4.754-9.162-7.752
			c-2.409-3.5-2.518-8.416-3.759-13.625c3.747,9.095,13.805,11.879,20.203,18.323c-0.006,0.554,0.404,0.692,0.234,1.41
			c2.669,3.283,6.141,5.762,7.518,10.336C390.502,318.917,384.642,317.069,379.615,313.688z M364.11,281.976
			c1.574-2.185,0.646-6.871,1.645-9.631c1.635,10.503,5.6,18.675,10.102,26.31C370.446,294.589,367.055,288.506,364.11,281.976z
			 M384.313,255.666c0.17,0.718-0.405,0.691-0.235,1.41c-0.614,0.795-1.011,1.808-1.645,2.584c-0.962,1.152-1.594,2.634-3.054,3.289
			c-2.583,3.286-7.93,6.51-12.92,5.168c3.942-2.166,7.135-5.081,10.571-7.752c0.527-0.725,1.154-1.352,1.879-1.879
			c0.078,0,0.156,0,0.234,0c0.938-1.02,1.65-2.265,2.819-3.054c0.418-0.835,0.805-1.701,1.645-2.114c0.078,0,0.156,0,0.234,0
			C384.633,253.322,384.83,255.133,384.313,255.666z M385.723,231.47c-0.427,2.314-1.836,3.646-1.88,6.343
			c-0.568,1.231-0.473,3.128-1.174,4.228c-0.307,1.024-0.585,2.078-1.175,2.819c-0.325,1.867-1.133,3.252-1.645,4.933
			c-0.169,0.927-0.291,1.901-0.705,2.584c0,0.156,0,0.313,0,0.47c0,0.235,0,0.47,0,0.705c-1.378,0.738-2.385,3.093-3.758,3.054
			c0.455-0.797,0.335-2.17,0.939-2.819c-0.015-0.875,0.236-1.486,0.47-2.114c0.071-1.025,0.244-1.948,0.705-2.584
			c-0.024-0.728,0.287-1.122,0.47-1.644c0.151-0.867,0.241-1.795,0.704-2.349c0.063-0.957,0.442-1.594,0.705-2.349
			c0.368-1.042,0.515-2.305,1.175-3.054c0.175-1.234,1.12-1.699,1.409-2.819c1.392-2.994,3.385-5.385,4.698-8.457
			C387.059,229.212,386.148,230.715,385.723,231.47z M390.337,178.328c-0.001-0.001-0.001-0.001-0.001-0.002s0-0.002-0.001-0.003
			C390.336,178.324,390.336,178.326,390.337,178.328z M383.376,160.313c-0.398-2.037-0.787-4.141-1.198-6.251
			C382.589,156.172,382.979,158.276,383.376,160.313z M314.075,99.685c-0.457-0.084-0.911-0.135-1.363-0.172
			C313.164,99.555,313.617,99.601,314.075,99.685z M241.253,239.691c-1.884-1.418-1.542-12.848,1.879-8.222c0,0.157,0,0.313,0,0.47
			c-1.487,0.999-0.401,2.988,0.47,3.289c2.701,0.933,6.302-4.179,8.222-4.698c0.715,0.01,0.914-0.496,1.41-0.705
			c1.146-0.654,2.508-1.093,3.523-1.879c3.332-1.053,6.827-1.943,10.336-2.819c1.615,0.049,2.313-0.819,3.054-1.644
			c0.715-1.662,1.169-5.462,0.235-7.282c-0.584-0.026-0.728-0.44-1.175,0c-0.619-0.071-0.771,0.325-0.705,0.939
			c-0.2-0.043-0.222,0.092-0.235,0.235c-0.146,0.638,0.298,1.864-0.235,2.114c-0.264-0.05-0.366-0.261-0.47-0.47
			c-0.078,0-0.156,0-0.235,0c-0.894-0.594-2.184-0.792-2.819-1.645c-0.078,0-0.156,0-0.235,0c-0.893-0.516-1.938-0.88-2.584-1.644
			c-0.078,0-0.157,0-0.235,0c-0.989-0.577-2.196-0.936-3.054-1.645c-2.232-0.311-4.042-2.343-6.343-1.175
			c-0.749,1.103-0.618,2.154-0.47,3.759c0.143,0.013,0.278,0.035,0.235,0.235c-1.072,0.054-1.641-0.395-2.349-0.705
			c-0.608-0.253-0.874-0.85-1.645-0.94c-2.436-2.654-4.937-5.242-7.517-7.752c1.296-1.601,2.009-3.786,3.054-5.638
			c0-0.078,0-0.157,0-0.235c2.25-3.231,5.151-5.811,7.047-9.396c0.013-0.3,0.301-0.325,0.235-0.705
			c0.529-0.567,0.842-1.351,0.94-2.349c0.636-1.948,1.282-3.887,1.409-6.343c0.514-0.425,0.04-1.839,0.47-2.349
			c0.54-0.609-0.009-1.554-0.235-2.114c-0.739-1.866-2.231-2.978-3.463-4.351l-7.604,1.637l-5.423,1.503l2.877-19.903h-0.005
			c0.265-0.838,0.487-1.718,0.696-2.612c0.465-0.788,0.772-1.733,0.94-2.819c0.074-0.317,0.334-0.448,0.235-0.939
			c1.833-5.292,4.225-10.026,7.517-13.86c0.001-0.809,0.119-1.501,0.319-2.11c-0.001,0.002-0.001,0.003-0.002,0.005
			c0.224-0.535,0.437-1.081,0.623-1.654c-0.104,0.323-0.221,0.634-0.338,0.944c0.374-0.758,0.9-1.364,1.513-1.884
			c0.14,0.486-0.291,1.543,0.235,1.644c0,0.079,0,0.157,0,0.235c-0.133,1.968,0.333,3.692,1.187,4.936
			c-0.002,0.154-0.004,0.308-0.012,0.468c0.134,0.032,0.252,0.08,0.361,0.137c0.009,0.005,0.016,0.012,0.025,0.017
			c0.725,0.404,0.986,1.271,2.135,1.251c0.001,0,0.002,0,0.003,0c1.333,0.368,2.906,0.067,4.524-1.17
			c2.787-0.424,5.638-0.783,8.222-1.41c1.083-0.014,2.083-0.108,2.819-0.47c3.183-0.341,6.032-1.015,9.396-1.175
			c2.271,0,4.542,0,6.813,0c1.557,0.244,3.229,0.373,4.934,0.47c0.683,0.413,1.657,0.535,2.584,0.705
			c0.272,0.197,0.63,0.31,1.174,0.235c0.636,0.46,1.56,0.634,2.584,0.705c1.973,0.612,4.056,1.113,6.343,1.41
			c0.771,0.403,1.728,0.622,2.819,0.705c6.345,1.094,12.858,2.02,19.497,2.819c0.143,0.406,1.114-0.018,1.175,0.47
			c-0.161,0.526-0.706,1.177-0.235,1.645c0,0.47,0,0.94,0,1.41c0.053,0.182,0.007,0.463,0.235,0.47
			c-0.009,1.497,0.089,2.887,0.47,3.993c0.035,2.706,0.447,5.035,1.175,7.047c0.251,1.863,1.147,3.081,1.645,4.698
			c0.751,0.58,1.011,1.651,1.645,2.349c0,0.079,0,0.157,0,0.235c1.424,1.395,2.473,3.165,3.758,4.698c0,0.079,0,0.157,0,0.235
			c0.788,0.309,0.977,1.217,1.645,1.645c1.39,1.586,2.878,3.074,4.464,4.463c-0.001,0.236,0.189,0.279,0.234,0.47
			c0.078,0,0.156,0,0.235,0c0.618,0.243,0.791,0.932,1.409,1.175c0,1.252,0,2.505,0,3.758c-0.135,0.413,0.284,1.38-0.235,1.41
			c-0.05,5.901-1.406,10.496-1.644,16.208c0.166,0.871-0.401,1.008-0.235,1.879c-0.149,0.868,0.304,2.34-0.235,2.819
			c0.038,3.014-0.614,5.336-0.939,7.987c0,0.235,0,0.47,0,0.705c-0.143,0.562,0.295,1.704-0.234,1.879c0,0.078,0,0.156,0,0.235
			c-0.039,0.666-0.431,0.979-0.47,1.644c-0.52,0.343-0.466,1.258-1.175,1.41c-0.627,0-1.253,0-1.879,0c-0.392,0-0.783,0-1.175,0
			s-0.783,0-1.175,0c0-0.079,0-0.157,0-0.235c-0.392,0-0.783,0-1.175,0c-0.939,0-1.879,0-2.818,0
			c-0.638-0.146-1.864,0.298-2.114-0.235c-0.392,0-0.783,0-1.175,0c-0.09,0.021-0.579-0.099-0.47-0.235
			c0.123-0.155,0.279-0.308,0.423-0.462c0.231-0.243,0.48-0.47,0.717-0.708c-0.083,0.075-0.175,0.15-0.254,0.225
			c0.516-0.524,1.045-1.035,1.583-1.537c-0.457,0.424-0.891,0.871-1.329,1.312c2.034-1.845,5.08-3.623,6.848-5.643
			c0.078,0,0.156,0,0.234,0c0-0.079,0-0.157,0-0.235c0.932-0.25,2.523-2.662,0-2.349c-0.2-0.043-0.222,0.091-0.234,0.235
			c-0.885,0.055-0.995,0.885-1.88,0.939c-1.325,1.415-2.828,2.653-4.229,3.994c-1.429,1.233-2.76,2.564-3.993,3.993
			c-0.166,0.852-1.712,0.324-2.584,0.47c0-0.079,0-0.157,0-0.235c-0.392,0-0.783,0-1.175,0c-1.096,0-2.192,0-3.288,0
			c-0.273-0.039-0.419,0.052-0.471,0.235c-1.093-0.076-1.567,0.468-2.584,0.47c-0.741,0.59-1.794,0.868-2.818,1.174
			c-0.317,0.074-0.449,0.334-0.939,0.235c-0.417,0.601-1.509,0.527-2.114,0.94c-1.008,0.167-1.966,0.383-2.584,0.939
			c-0.409-0.018-0.73,0.053-0.939,0.235c-0.816,0.281-1.825,0.368-2.35,0.94c-0.956,0.062-1.594,0.442-2.349,0.705
			c-0.872,0.303-2.23,0.12-2.35,1.174c-0.478,1.637-1.036,3.192-1.409,4.934c-0.886,1.542-1.386,3.469-1.88,5.403
			c-0.812,2.398-1.732,4.688-2.349,7.282c-0.466,0.788-0.772,1.733-0.939,2.819c-0.579,1.535-1.085,3.144-1.41,4.933
			c-0.398,0.62-0.695,1.34-0.704,2.349c0,0.078,0,0.157,0,0.235c-0.371,0.49-0.487,1.235-0.47,2.114
			c-0.895-0.593-1.407-1.569-2.35-2.114c-2.378,0.598-3.691,3.511-5.638,3.758c0-0.235,0-0.47,0-0.705
			c-0.785,0.155-1.149,0.73-1.879,0.94c-1.304,2.219-0.407,6.64-1.409,9.161c-0.876-1.629-1.526-3.485-2.819-4.698
			c-0.048-0.735,0.158-1.724-0.235-2.114c0.075-0.544-0.038-0.902-0.235-1.174c-0.766-0.061-1.13,0.279-1.174,0.939
			c0.12,1.114-0.744,1.273-0.705,2.819c-0.738,0.123-1.311,0.412-1.879,0.705c-1.013,0.632-2.321,0.968-3.289,1.645
			c-0.409-0.018-0.731,0.052-0.94,0.235c-2.498,0.287-3.501-2.49-5.168-4.463c-2.195-2.599-4.011-3.786-5.403-6.108
			c0-0.078,0-0.156,0-0.235c0.01-0.323-0.111-0.516-0.235-0.705c0.838-0.286,1.117-2.414-0.235-2.349c-0.079,0-0.157,0-0.235,0
			c-0.636-0.39-2.888-0.39-3.524,0c-3.496-0.129-6.633,0.102-9.396,0.705c-0.391,0-0.783,0-1.174,0 M252.293,137.271
			c0.093,0.237,0.149,0.513,0.189,0.804C252.443,137.783,252.387,137.508,252.293,137.271z M308.202,129.989
			c8.333-1.517,14.132,1.526,18.558,4.698C320.34,134.451,312.907,131.94,308.202,129.989z M279.073,265.062
			c-0.008,0.13-0.018,0.247-0.028,0.359c0-0.002,0-0.004,0-0.006C279.044,265.287,279.05,265.165,279.073,265.062z M265.448,264.827
			c-0.219-0.235-0.434-0.475-0.651-0.711c0,0,0.001,0,0.001,0C265.015,264.353,265.229,264.592,265.448,264.827z M262.146,261.167
			c0.466,0.526,0.926,1.059,1.397,1.58c-0.001,0-0.001-0.001-0.002-0.002C263.071,262.225,262.612,261.693,262.146,261.167
			c-1.427-1.611-2.834-3.242-4.199-4.915C259.313,257.926,260.72,259.556,262.146,261.167z M257.942,256.246
			C257.941,256.246,257.941,256.245,257.942,256.246c0.007,0.007,0.016,0.015,0.024,0.022
			C257.958,256.261,257.95,256.253,257.942,256.246z M251.847,255.041c-0.106,0.042-0.21,0.088-0.315,0.142
			c-0.141,0.069-0.279,0.147-0.413,0.248c-0.119,0.089-0.235,0.21-0.35,0.344c-0.047,0.054-0.092,0.116-0.138,0.176
			c-0.04,0.051-0.08,0.096-0.119,0.151c0.006-0.005,0.013-0.01,0.019-0.015c-0.032,0.045-0.064,0.085-0.097,0.132
			c0.026-0.038,0.052-0.08,0.078-0.117c-0.774,0.667-1.472,1.41-1.963,2.358c-0.006,0.006-0.013,0.013-0.019,0.019
			c-1.043-0.994-1.503-2.571-2.38-3.729c0.004,0.009,0.008,0.019,0.012,0.028c-0.004-0.011-0.009-0.02-0.014-0.031
			c0,0.001,0.001,0.002,0.002,0.003c-0.01-0.021-0.019-0.038-0.028-0.059c-0.037-0.08-0.073-0.162-0.108-0.23
			c0.912-0.622,3.314,0.246,4.165-0.439c0.735-0.048,1.725,0.159,2.114-0.235c0.392,0,0.783,0,1.175,0
			C252.947,254.224,252.395,254.631,251.847,255.041z M253.071,254.651c-0.754,0.821-1.661,1.491-2.565,2.162
			C251.408,256.139,252.317,255.472,253.071,254.651z M247.204,257.313c0-0.001-0.001-0.002-0.001-0.002
			c-0.001-0.002-0.002-0.004-0.003-0.007C247.201,257.307,247.203,257.31,247.204,257.313z M239.326,202.658
			c1.27-2.076,2.404-3.754,2.939-5.067C241.73,198.904,240.599,200.58,239.326,202.658z M242.801,196.832
			C242.801,196.832,242.801,196.832,242.801,196.832C242.801,196.832,242.801,196.832,242.801,196.832
			c0.812-0.741,1.531-1.572,2.201-2.453C244.332,195.26,243.612,196.092,242.801,196.832z M232.561,205.16
			c0.289-1.669,0.458-3.457,0.47-5.403c0.2,0.043,0.222-0.092,0.235-0.235c0.808,0.103,1.317-0.093,1.879-0.235
			c-0.442,2.134,2.717,2.877,3.054,0.939c0.511-1.14-0.169-2.903-0.47-3.993c0-0.235,0-0.47,0-0.705
			c0.487-0.14,1.543,0.291,1.645-0.235c0.078,0,0.157,0,0.235,0c0.999-0.097,1.782-0.411,2.349-0.939
			c0.323,0.01,0.516-0.111,0.705-0.235c0.847-0.19,2.243-1.285,0.235-1.175c-0.464-0.162-0.626-0.626-0.705-1.174
			c-1.29,0.276-2.205,0.927-3.523,1.174c-1.188,0.17-1.777-0.258-2.349-0.705c-0.206-0.166-0.35-0.351-0.468-0.545
			c-0.078-0.129-0.141-0.263-0.185-0.403v0c-0.609-1.902,1.69-4.74,1.827-6.804c3.744-1.743,8.149-4.059,12.685-4.229
			c0.022,1.354,0.034,2.696-0.47,3.524c0,0.078,0,0.156,0,0.235c-0.38,2.125-2.247,2.764-2.349,5.168
			c-0.283,0.892-0.489,1.859-0.939,2.584c0,0.078,0,0.157,0,0.235c-0.58,0.725-1.167,1.485-1.755,2.269
			c0.588-0.782,1.176-1.544,1.755-2.269c-3.037,2.937-5.398,7.177-7.987,11.511c1.18-1.977,2.485-3.987,3.832-5.926
			c-1.347,1.939-2.652,3.95-3.832,5.926c-1.068,1.787-3.086,6.902-5.403,5.168C232.375,208.009,232.586,206.466,232.561,205.16z
			 M234.206,273.519c0.737-0.28,1.629-0.407,2.114-0.939c2.509-5.478,3.516-12.458,5.403-18.558c3.4,0.952,3.051,7.183,6.107,7.752
			c3.257,0.606,4.443-4.738,7.047-6.107c2.717,3.547,6.437,6.091,8.457,10.336c3.449,4.3,7.932-1.16,11.275-2.584
			c1.193,1.615,1.361,3.175,2.349,5.638c0.49,1.221,1.771,4.941,3.289,4.698c2.661-0.425,0.424-11.301,1.174-14.094
			c1.909-1.066,3.93-2.021,5.168-3.759c0.943,0.992,3.638,4.663,5.403,1.645c0.848-1.449,1.293-6.143,1.88-8.222
			c2.001-7.101,5.525-16.062,7.047-22.551c2.592-0.697,5.154-1.424,7.282-2.584c0.078,0,0.156,0,0.235,0c0.078,0,0.156,0,0.234,0
			c0.156,0,0.313,0,0.47,0c2.187-1.024,4.736-1.685,6.813-2.819c0.235,0,0.47,0,0.705,0c0.711-0.542,2.423-0.083,3.523-0.235
			c0.144-0.013,0.278-0.035,0.235-0.235c0.156,0,0.313,0,0.47,0c0.313,0,0.626,0,0.939,0c0.783,0,1.565,0,2.349,0
			c0.861,0,1.723,0,2.584,0c0,0.078,0,0.157,0,0.235c0.783,0,1.566,0,2.35,0c0.156,0,0.313,0,0.47,0c1.018,0,2.036,0,3.054,0
			c0.638,0.146,1.864-0.298,2.114,0.235c0.234,0,0.47,0,0.705,0c0.782,0,1.565,0,2.349,0c0,0.078,0,0.157,0,0.235
			c0.078,0,0.156,0,0.235,0c1.382,0.051,2.672,0.01,3.288-0.705c1.059-0.43,1.135-1.841,1.88-2.584
			c-0.113-0.739,0.334-0.918,0.47-1.41c0.362-1.125,0.613-2.362,1.174-3.289c-0.084-1.103,1.234-0.803,1.175-1.879
			c0-1.645,0-3.289,0-4.933c0-0.157,0-0.313,0-0.47c-0.545-1.334-0.08-3.678-0.234-5.403c0-0.783,0-1.566,0-2.349
			c1.406,1.96,2.449,4.284,4.463,5.638c0.078,0,0.156,0,0.235,0c0.379-0.066,0.404,0.222,0.704,0.235
			c-0.78-2.194-2.796-3.155-3.523-5.403c-0.013-0.3-0.301-0.325-0.234-0.705c-0.178-0.998-0.862-1.487-0.705-2.819
			c-0.39-0.481-0.39-2.572,0-3.054c0.018-0.409-0.053-0.73-0.235-0.939c0.515-0.426,0.04-1.839,0.47-2.349c0-0.392,0-0.783,0-1.175
			c0.713-0.086,0.488,0.765,0.705,1.175c-0.043,0.2,0.092,0.222,0.235,0.235c0.032,0.829,0.672,1.05,0.704,1.879
			c2.681,2.644,4.664,5.985,7.752,8.222c0.079,0,0.157,0,0.235,0s0.156,0,0.234,0c1.022,0.999,1.72,0.551,1.88,0
			c0.469-1.612-2.785-2.073-3.289-3.523c0-0.079,0-0.157,0-0.235c-0.796-1.239-2.204-1.867-2.818-3.289
			c-1.058-1.292-2.295-2.404-2.819-4.229c0-0.078,0-0.156,0-0.235c-0.626-0.627-0.74-1.765-0.939-2.819
			c-0.177-1.004-0.951-2.643-0.47-3.523c0.078,0,0.156,0,0.234,0c0.896,0.114,1.5-0.067,1.88-0.47
			c0.12-0.981-0.131-1.592-0.47-2.114c0.235-0.001,0.279,0.19,0.47,0.235c-0.226-0.48-0.48-0.93-0.705-1.41c0-0.078,0-0.157,0-0.235
			c-0.853-0.635-1.051-1.925-1.645-2.819c-0.1-0.448-0.671-0.425-0.47-1.175c-0.987-1.44-1.611-3.244-2.349-4.933
			c0-0.078,0-0.156,0-0.235c-0.556-0.541-0.752-1.44-1.175-2.114c-0.235,0.001-0.279-0.19-0.47-0.235
			c-0.195-0.588-0.644-0.922-0.705-1.644c-0.013-0.3-0.301-0.326-0.234-0.705c-0.279-0.738-0.793-1.243-0.94-2.114
			c-0.724-0.372-0.823-1.369-1.409-1.879c-0.348-0.514-0.458-1.265-0.939-1.645c0,0.078,0,0.157,0,0.235
			c-1.166-1.261-1.658-3.196-1.879-5.403c1.807,0.822,2.584,2.785,3.758,4.229c3.375,4.149,6.954,8.5,10.571,12.685
			c2.589,2.996,4.909,5.693,6.107,9.631c1.121,3.685,0.653,8.556,2.819,12.685c2.021-1.402,0.737-4.709,0.47-7.047
			c-0.332-2.905-0.653-5.543-1.409-7.517c-2.146-5.598-7.131-9.903-10.571-15.269c-3.522-5.493-6.8-10.578-8.927-16.914
			c-0.085-1.172,1.146-1.662,0.939-3.054c-0.243-1.641-2.508-2.303-3.758-3.054c-1.535-0.922-3.091-1.755-3.994-2.584
			c-3.39-3.111-5.605-9.385-10.336-10.101c-1.155-0.175-2.244,0.177-3.523,0.235c-8.15,0.37-18.426-0.63-25.84,0.94
			c-1.884,0.398-4.01,0.131-5.873,0.235c-9.477,0.525-18.798,1.308-25.136,4.463c-1.57,2.736-5.421,3.192-7.987,4.933
			c-0.981-0.741-0.934-2.511-1.409-3.759c0.005-0.934,1.716-0.163,1.879-0.939c-0.075-3.058-0.263-6.001-2.114-7.282
			c-6.02,6.979-10.881,15.116-15.974,23.021c2.754-7.449,6.808-13.64,10.806-20.437c3.458-5.881,7.674-12.83,14.33-15.739
			c4.529-1.979,9.835-3.509,14.799-4.933c12.948-3.714,27.868-6.937,42.753-3.993c2.733,0.541,5.332,2.113,8.223,2.584
			c1.78,0.29,3.544-0.186,5.168-0.235c11.896-0.359,17.466,5.077,24.195,10.101c3.724,2.78,7.601,5.266,10.571,8.926
			c1.884,2.658,4.418,4.665,6.107,7.518c0,0.078,0,0.156,0,0.235c0.718,1.179,1.724,2.068,2.43,3.259
			c-0.01-0.017-0.019-0.036-0.028-0.053c0.022,0.039,0.048,0.074,0.069,0.114c-0.013-0.021-0.028-0.04-0.041-0.061
			c0.073,0.13,0.141,0.267,0.212,0.4c-0.051-0.101-0.096-0.206-0.15-0.302c0.132,0.24,0.256,0.492,0.382,0.74
			c-0.075-0.148-0.153-0.293-0.231-0.438c0.162,0.322,0.308,0.66,0.412,1.04c2.243,6.292,3.905,13.165,5.168,20.437
			c0.46,0.793,0.572,1.934,0.705,3.054c2.025,9.876,4.632,19.173,6.343,29.364c0.146,0.638-0.299,1.864,0.234,2.114
			c0.101,4.049,0.874,7.427,0.939,11.511c0.391,0.791,0.391,3.203,0,3.993c0.007,2.669-0.719,4.606-1.409,6.578
			c-0.252,1.236-0.532,2.443-1.175,3.289c-0.956,3.585-2.388,6.695-3.758,9.866c-0.511,1.901-1.695,3.128-2.795,4.439
			c-0.007,0.009-0.018,0.015-0.024,0.024c-0.002,0.004-0.004,0.008-0.006,0.012c-0.746,0.893-1.451,1.826-1.873,3.042
			c-0.461,1.889-1.265,3.435-1.88,5.168c-0.519,1.595-0.928,3.3-1.645,4.698c-0.212,1.667-0.526,3.232-0.939,4.698
			c-0.235,1.722-0.623,3.292-1.175,4.698c0.031,0.579,0.036,1.132-0.234,1.41c-0.031,0.579-0.035,1.132,0.234,1.41
			c0.073,0.318,0.589,0.195,0.94,0.235c-0.528,0.725-1.154,1.351-1.88,1.879c-0.078,0-0.156,0-0.234,0c0,0.079,0,0.157,0,0.235
			c0.078,0,0.156,0,0.234,0c-4.25,3.502-8.507,6.997-15.974,7.282c0.371-6.337,4.283-12.248,3.993-19.262
			c8.27-1.996,12.319-5.702,16.444-12.686c2.727-4.617,4.507-8.581,4.229-15.269c0.052-0.182,0.006-0.463,0.234-0.47
			c-0.235-1.017-0.638-1.868-0.705-3.054c-0.985-0.816-0.648-2.953-0.704-4.698c-0.601,0.026-0.822,0.431-1.175,0.705
			c-0.447-0.441-1.232-0.763-1.919-1.161c0.001,0.001,0.002,0.002,0.003,0.002c-0.002-0.001-0.005-0.003-0.007-0.005
			c0.002,0.001,0.003,0.001,0.004,0.002c-0.003-0.003-0.007-0.005-0.011-0.007c-0.611-0.508-1.285-0.917-2.063-1.181
			c-4.312-1.462-10.503,1.289-14.095,1.645c-2.05,0.203-6.733-0.504-8.927,0.939c-1.915,1.261-2.49,6.789-3.054,9.631
			c-2.017,10.173-3.814,22.679-6.107,31.713c0-0.235,0-0.47,0-0.705c-0.144,0.013-0.278,0.035-0.235,0.235
			c-0.388,1.484,0.741,1.452,0.705,2.584c0.078,0,0.156,0,0.234,0c-1.097,3.679-1.492,8.061-4.933,9.396
			c-0.73,1.697-1.916,2.939-2.35,4.933c-7.402-4.027-16.765-7.816-25.84-11.746c-1.892-0.819-10.926-5.282-11.511-0.939
			c-0.206,1.533,2.023,4.241,2.819,5.873c3.007,6.165,5.472,11.873,7.282,18.793c-21.72,7.801-51.318,7.723-79.165,9.396
			C231.855,286.668,234.159,280.601,234.206,273.519z M244.072,294.426c-0.039-0.665,1.448,0.195,1.409-0.47c0.157,0,0.313,0,0.47,0
			c0.945-0.151,2.498,0.305,3.054-0.235c0.235,0,0.47,0,0.705,0c1.179-0.152,2.97,0.308,3.759-0.235c0.235,0,0.47,0,0.705,0
			c1.253,0,2.506,0,3.758,0c-0.335,0.691-0.43,2.849,0,3.524c0,0.157,0,0.313,0,0.47c0.149,0.791-0.302,2.182,0.235,2.584
			c0.145,0.638-0.298,1.864,0.235,2.114c0.104,2.01,0.331,3.897,0.705,5.638c0,0.626,0,1.253,0,1.879
			c-1.623-0.726-2.331-2.368-3.759-3.289c0-0.079,0-0.157,0-0.235C252.227,301.618,248.511,297.66,244.072,294.426z
			 M202.493,272.814c-0.55-2.457-1.782-6.369-1.175-7.517c0.132-0.338,0.367-0.573,0.705-0.705c0.2-0.043,0.221,0.091,0.235,0.235
			c8.532,5.296,14.756,12.848,22.551,19.027c0.93,0.738,2.033,1.384,3.289,1.645c-0.738,2.472-1.746,4.675-2.114,7.517
			c2.746,2.613,8.684,1.171,13.39,1.41c8.94,4.449,12.862,13.917,20.437,19.732c2.045,8.213-2.088,12.735-5.873,17.853
			c-2.161,2.922-4.485,5.717-6.108,8.457c-3.614,6.102-6.863,13.424-9.866,19.498c-12.976,26.239-22.599,55.139-28.659,88.326
			c-2.982,16.329-2.864,35.296,0,53.794c-0.333,0.411-1.215-0.926-1.645-1.644c-1.774-2.967-1.506-7.729-2.114-12.216
			c-0.304-2.244-0.288-4.57-0.705-6.577c-0.174-0.841-0.796-1.726-0.94-2.584c-0.066-0.399-0.123-0.801-0.176-1.203
			c0.004,0.04,0.009,0.08,0.014,0.119c-0.008-0.063-0.017-0.123-0.024-0.188c0.003,0.022,0.007,0.046,0.01,0.068
			c-0.089-0.746-0.176-1.511-0.262-2.287c0.001,0.009,0.001,0.018,0.002,0.026c-0.091-0.835-0.185-1.688-0.282-2.546
			c0.096,0.854,0.188,1.691,0.279,2.52c-0.278-2.873-0.428-5.798-0.971-8.726c-2.136-11.518-1.827-23.843-2.584-36.411
			c-0.771-12.799-1.25-25.457-1.175-39.465c0.031-5.771,1.048-12.358-0.705-16.914c6.028-0.358,10.048-5.241,14.095-7.517
			c1.824-1.026,4.891-1.173,5.638-2.819c0.571-1.258,0.295-3.214-0.089-5.084c-0.222-1.582-0.594-3.292-1-4.894
			c-0.001-0.004-0.002-0.009-0.003-0.013c0,0.001,0,0.002,0,0.003c-0.001-0.005-0.002-0.011-0.003-0.016
			c0.001,0.004,0.002,0.008,0.003,0.012c-1.572-7.814-3.928-15.975-4.076-23.602C209.473,311.767,206.641,291.354,202.493,272.814z
			 M199.674,484.233c-3.896-6.998-6.195-14.398-6.813-23.257c-0.613-8.796,3.231-18.079,3.289-27.015
			c0.209,0.104,0.42,0.207,0.47,0.471C195.32,453.106,199.03,466.962,199.674,484.233z M209.775,340.938
			c0.273-0.038,0.418,0.052,0.47,0.235c-0.127,1.619-1.902,2.454-2.819,3.523c-4.954,5.779-8.921,12.526-12.45,20.438
			c-1.305,2.925-3.36,5.774-4.463,8.691c-2.559,6.766-3.944,15.777-4.229,24.196c-0.307,9.074,2.161,18.147,2.584,27.25
			c0.447,9.604-1.073,19.215-1.174,28.659c-0.142,13.166,2.886,23.842,8.222,33.357c2.518,4.49,5.229,8.783,7.517,13.155
			c2.476,4.729,4.249,9.775,6.813,13.859c0.481,0.767,0.534,1.732,2.349,1.645c-0.971,1.456-2.583,2.272-4.229,3.054
			c-3.707-9.862-9.271-18.636-14.799-27.954c-2.6-4.384-5.247-8.827-7.282-14.095c-1.928-4.99-3.801-10.882-4.229-16.914
			c-0.277-3.908,0.705-7.958,0.94-11.745c0.335-5.401,0.239-10.562,0.939-15.974c0.66-5.099,1.135-10.392,1.175-15.505
			c0.081-10.588-1.237-21.871,0.705-31.712c1.684-8.537,5.086-17.207,9.162-24.431C199.28,353.038,205.07,346.563,209.775,340.938z
			 M135.078,390.677c0.309-0.53,0.616-1.063,0.935-1.583c-0.32,0.52-0.627,1.054-0.936,1.584
			C135.077,390.679,135.078,390.678,135.078,390.677z M132.883,394.691c0.001-0.001,0.002-0.003,0.003-0.005
			c-0.002,0.005-0.005,0.01-0.008,0.016C132.879,394.698,132.881,394.694,132.883,394.691z M106.444,494.271
			c0.015-0.369,0.011-0.739-0.03-1.11C106.455,493.531,106.458,493.901,106.444,494.271z M74.466,434.902
			c-0.145,0.019-0.274,0.055-0.399,0.094c0,0,0,0,0,0C74.192,434.957,74.321,434.921,74.466,434.902z M73.154,435.549
			c-1.011,0.68-1.94,1.492-2.896,2.287C71.212,437.038,72.143,436.229,73.154,435.549z M73.714,401.112
			c0.002-0.002,0.004-0.003,0.007-0.005c0,0,0,0,0.001,0C73.719,401.108,73.716,401.11,73.714,401.112z M77.058,398.489
			c0.033-0.032,0.062-0.066,0.094-0.098c0.133-0.119,0.267-0.237,0.399-0.357C77.385,398.185,77.222,398.337,77.058,398.489z
			 M99.602,344.931c0.115-0.741-0.423-0.83-0.235-1.644c-0.197-0.273-0.31-0.63-0.235-1.175c-0.388-0.003-0.132-0.651-0.47-0.705
			c0.185-0.967-0.36-1.206-0.235-2.114c0-0.078,0-0.157,0-0.235c-0.078,0-0.156,0-0.235,0c-0.09-1.319-0.362-2.457-0.235-3.994
			c0.506-0.198,0.198-1.211,0.705-1.409c0.315,0.154,0.438,0.502,0.47,0.939c0.114,0.278,0.25,0.533,0.47,0.705
			c0.221,1.659,1.188,2.57,1.409,4.229C101.382,341.233,100.522,343.735,99.602,344.931z M103.595,348.925
			c0.542,1.875-0.546,4.149-1.644,5.168c-0.273,0.197-0.63,0.31-1.175,0.235c-0.499-0.989-0.114-2.861-0.235-4.228
			c0-0.079,0-0.157,0-0.235c-0.078,0-0.157,0-0.235,0c-0.211-1.464,1.268-1.238,1.41-2.349c0-0.079,0-0.157,0-0.235
			c0.047-1.206,0.621-1.885,0.705-3.054c0.514,0.035,0.504,0.592,0.47,1.175C103.172,346.529,103.571,347.54,103.595,348.925z
			 M103.285,361.221c0.313,0.311,0.581,0.632,0.765,0.965C103.866,361.854,103.598,361.531,103.285,361.221z M104.272,362.753
			C104.272,362.753,104.272,362.753,104.272,362.753c-0.082-0.1-0.176-0.188-0.26-0.288
			C104.097,362.564,104.19,362.652,104.272,362.753z M103.281,353.082c0.215-0.346,0.378-0.73,0.496-1.143
			C103.66,352.352,103.497,352.735,103.281,353.082z M101.81,354.411c-0.039,0.02-0.067,0.047-0.107,0.065
			c0.003-0.002,0.006-0.006,0.01-0.008C101.747,354.451,101.775,354.428,101.81,354.411z M103.83,363.724
			c-1.602-0.434-2.896-1.176-3.758-2.349c0.032-0.829,0.672-1.05,0.705-1.879c0.521-0.129,0.334,0.449,0.705,0.47
			c0.834,1.028,1.765,1.959,2.792,2.794c0.024,0.123,0.034,0.247,0.027,0.372C104.195,363.38,103.833,363.373,103.83,363.724z
			 M107.824,352.448c-0.496,0.601-0.367,1.826-0.939,2.35c0.018,0.409-0.053,0.73-0.235,0.939c-0.353,1.37-0.568,2.877-1.41,3.758
			c-0.414-0.212-0.728-0.525-0.939-0.939c0.114-0.277,0.25-0.533,0.47-0.705c0-0.079,0-0.157,0-0.235
			c0.52-2.76,1.221-5.302,2.349-8.457c-0.273-0.432-1.247-0.163-0.94-1.174c-0.294-1.037-0.962-1.7-0.939-3.054
			c-0.55-0.859-0.872-1.947-1.174-3.054c-1.322-3.69-2.153-7.87-3.524-11.51c-0.149-1.652-1.21-2.392-1.409-3.994
			c-0.417-0.21-0.3-0.954-1.175-0.705c-0.74-0.114-0.176,1.077-0.705,1.174c-0.13-0.418-0.121-0.976-0.47-1.174
			c0.241-1.651-1.205-1.614-1.41-2.819c0.702-0.551,0.832-1.674,1.41-2.349c-0.018-0.409,0.052-0.73,0.235-0.939
			c0.182-0.914,0.269-1.923,0.939-2.349c0.398,2.03,1.428,3.427,1.645,5.638c2.001,0.348,2.004,2.694,2.584,4.463
			c0.244,0.931,0.686,1.663,0.705,2.819c0.464,0.555,0.553,1.483,0.705,2.349c0.96,3.034,1.973,6.014,2.584,9.396
			c0.496,0.601,0.367,1.826,0.94,2.349c-0.17,0.719,0.405,0.691,0.235,1.41c0,0.078,0,0.156,0,0.235
			c0.388,0.003,0.131,0.651,0.47,0.705c0.255-0.941,1.149,0.239,1.645,0.47C108.807,348.733,108.273,350.549,107.824,352.448z
			 M86.917,276.808c0.997,1.43,1.117,3.737,1.644,5.638c0.456,1.58,0.956,3.116,1.175,4.933c0.995,1.276,3.019,1.523,3.758,3.054
			c0,0.079,0,0.157,0,0.235c1.063,1.129,2.337,2.048,2.819,3.759c0.183,0.209,0.253,0.53,0.235,0.939
			c0.557,0.618,0.773,1.576,0.94,2.584c0.526,1.667,1.248,3.138,1.409,5.168c0.405,1.396,0.993,2.609,1.41,3.994
			c1.042,5.223,2.881,9.647,4.463,14.33c0.66,0.75,0.807,2.012,1.174,3.054c0.722,2.41,1.813,4.452,2.349,7.047
			c0.639,1.163,0.969,2.633,1.41,3.994c0.642,1.002,1.112,2.176,1.409,3.523c0.073,0.855-0.037,1.529-0.235,2.114
			c-0.2-0.043-0.221,0.092-0.235,0.235c0.075,0.545-0.038,0.902-0.235,1.174c-0.795-0.771-0.8-2.332-1.174-3.523
			c0-0.078,0-0.157,0-0.235c-0.497-0.913-1.271-1.549-1.41-2.819c-0.499-1.459-1.108-2.808-1.41-4.463
			c-0.676-0.968-0.99-2.298-1.409-3.523c-0.183-0.209-0.252-0.531-0.235-0.94c-0.759-1.198-1.172-2.743-1.645-4.229
			c0-0.078,0-0.156,0-0.235c-0.66-0.592-0.516-1.99-0.939-2.819c0-0.078,0-0.157,0-0.235c-1.399-2.125-1.997-5.05-2.819-7.752
			c-0.183-0.209-0.253-0.53-0.235-0.94c-0.465-0.787-0.773-1.733-0.94-2.819c-0.197-0.272-0.31-0.629-0.235-1.174
			c-0.414-0.683-0.535-1.657-0.705-2.584c-0.897-2.784-1.68-5.68-2.349-8.692c-0.616-0.167-0.225-1.341-0.94-1.409
			c-0.285,0.811-1.163,1.029-0.939,2.349c1.34,3.437,2.177,7.376,2.819,11.51c0.316,0.474,0.626,1.379,0.709,2.278
			c-0.172-2.581-0.791-5.21-1.414-7.916c0.624,2.706,1.241,5.335,1.414,7.916c0,0.002,0,0.005,0.001,0.007
			c0.005,0.088,0.012,0.175,0.017,0.263c0.019,0.528-0.046,1.041-0.257,1.445c0,0.078,0,0.157,0,0.235
			c-0.317,2.189-1.11,3.901-1.644,5.873c-0.482-1.005-0.784-2.191-0.705-3.758c0-0.392,0-0.783,0-1.174
			c-0.545-1.256-0.08-3.521-0.235-5.168c0-0.078,0-0.156,0-0.235c-0.416-0.915-0.559-2.104-0.705-3.289
			c-0.197-0.272-0.31-0.63-0.235-1.175c-0.403-0.771-0.622-1.727-0.705-2.819c-0.197-0.272-0.31-0.63-0.235-1.174
			c-1.431-2.328-2.641-4.876-6.813-4.463c0.096-0.566-0.429-0.511-0.235-1.175c-0.197-0.272-0.31-0.629-0.235-1.174
			c-0.645-1.156-0.843-2.759-1.175-4.229c-0.616-0.733,0.273-1.466,0.47-2.114c0.167-1.008,0.383-1.965,0.94-2.584
			c-0.1-0.491,0.161-0.622,0.235-0.939c0.532-1.504,0.82-3.252,1.644-4.463c0-0.078,0-0.157,0-0.235
			C86.585,277.416,87.111,277.471,86.917,276.808z M55.909,154.419c0.277,1.446,0.821,2.625,0.939,4.228
			c0.645,1.156,0.843,2.76,1.175,4.229c0.456,1.58,0.956,3.116,1.174,4.933c0.728,1.465,0.995,3.39,1.41,5.168
			c0.456,1.58,0.956,3.116,1.174,4.933c0.728,1.465,0.995,3.39,1.41,5.168c0.456,1.58,0.956,3.116,1.174,4.933
			c0.645,1.156,0.843,2.76,1.175,4.229c0.534,1.894,1.112,3.743,1.409,5.873c0.808,1.776,1.149,4.019,1.645,6.108
			c0.379,1.265,0.799,2.49,0.939,3.994c0.808,1.776,1.149,4.019,1.645,6.107c0.534,1.894,1.112,3.743,1.41,5.873
			c0.808,1.776,1.149,4.019,1.644,6.108c0.379,1.265,0.799,2.49,0.94,3.994c0.808,1.776,1.149,4.019,1.644,6.107
			c0.534,1.894,1.112,3.743,1.41,5.873c0.808,1.776,1.149,4.019,1.645,6.108c0.456,1.58,0.956,3.116,1.174,4.933
			c0.728,1.465,0.995,3.39,1.41,5.168c0.534,1.894,1.112,3.743,1.409,5.873c0.808,1.776,1.149,4.019,1.645,6.107
			c0.303,0.949,0.642,1.863,0.705,3.054c-0.459,1.473-0.659,2.656-1.644,3.994c-0.072,1.181-0.65,1.855-0.94,2.819
			c-0.415,1.229-0.631,2.657-1.409,3.523c-0.232-0.551-0.412-1.154-0.47-1.879c-0.379-1.266-0.799-2.49-0.939-3.994
			c-0.604-0.648-0.484-2.021-0.939-2.819c0.6,0.026,0.822,0.431,1.174,0.705c0.079,0,0.157,0,0.235,0c0.313,0,0.626,0,0.94,0
			c1.064-0.658,1.634-1.812,2.349-2.819c0.037-1.446-0.507-2.312-0.47-3.758c-0.459-0.794-0.572-1.934-0.705-3.054
			c-0.36-1.049-0.663-2.156-0.705-3.523c-0.49-0.372-0.46-1.262-1.41-1.175c-0.763,0.255-0.387,1.649-0.47,2.584
			c0.266,0.752,0.478,1.559,0.47,2.584c0.458,0.873,0.637,2.026,0.705,3.289c0.26,0.844,0.792,2.375,0.235,3.289
			c-0.114,0.278-0.25,0.533-0.47,0.705c-0.909-0.657-0.941-2.191-1.174-3.523c-0.33-1.158-0.642-2.333-0.705-3.759
			c-0.944-1.783-1.598,0.651-2.114,0.47c0-0.235,0-0.47,0-0.705c-0.233-0.628-0.484-1.238-0.47-2.114
			c-0.559-0.851-0.693-2.126-0.94-3.289c-0.303-0.949-0.642-1.863-0.705-3.054c-0.337-0.29-0.445-0.808-0.47-1.41
			c0-0.626,0-1.252,0-1.879c0-0.079,0-0.157,0-0.235c-0.688-0.721-1.105-1.713-2.349-1.879c0.024-1.042-1.22-0.816-1.41-1.645
			c-0.565-1.393-1.951-1.964-2.114-3.758c-0.107-0.832-0.522-1.357-0.47-2.349c-0.768-2.834-1.582-5.622-2.114-8.692
			c-0.784-2.348-1.409-4.855-1.879-7.517c-0.768-2.834-1.582-5.622-2.114-8.692c-0.706-2.034-1.253-4.228-1.645-6.577
			c-0.69-2.521-1.425-4.996-1.879-7.752c-0.706-2.035-1.253-4.229-1.644-6.578c-0.69-2.521-1.426-4.996-1.879-7.752
			c-0.784-2.348-1.41-4.855-1.879-7.517c-0.612-2.208-1.269-4.369-1.645-6.813c-0.784-2.348-1.409-4.855-1.879-7.517
			c-0.612-2.208-1.269-4.369-1.645-6.813c-0.772-2.439-1.499-4.922-1.879-7.752c2.349,0,4.698,0,7.047,0
			C54.463,154.713,55.403,154.031,55.909,154.419z M55.394,152.496c-4.33,0.279-9.497-0.278-13.746,0.083
			C45.896,152.217,51.064,152.775,55.394,152.496z M42.753,155.124c0.079,0,0.157,0,0.235,0c0.078,0,0.156,0,0.235,0
			c0.94,0.058,1.756-0.693,1.644,0.705c0.457,1.58,0.956,3.116,1.175,4.933c0.94,2.975,1.723,6.107,2.349,9.396
			c1.081,4.087,2.208,8.128,3.054,12.45c0.784,2.348,1.41,4.855,1.879,7.517c0.69,2.521,1.425,4.996,1.879,7.752
			c0.706,2.035,1.252,4.229,1.644,6.578c0.612,2.207,1.269,4.369,1.645,6.813c0.628,1.721,1.096,3.602,1.409,5.638
			c0.612,2.207,1.269,4.369,1.645,6.813c0.706,2.035,1.253,4.229,1.645,6.578c0.69,2.521,1.425,4.996,1.879,7.752
			c0.628,1.721,1.096,3.602,1.409,5.638c0.534,1.894,1.112,3.743,1.41,5.873c0.654,3.262,3.617,4.213,4.933,6.813
			c0,0.079,0,0.157,0,0.235c0.463,0.555,0.553,1.483,0.705,2.349c0,0.079,0,0.157,0,0.235c0.464,0.555,0.553,1.483,0.705,2.349
			c0.487,1.393,0.945,2.813,1.175,4.463c0.463,0.554,0.553,1.482,0.705,2.349c0.487,1.393,0.945,2.813,1.175,4.463
			c0.463,0.554,0.553,1.482,0.705,2.349c0.486,1.393,0.945,2.813,1.174,4.463c0.464,0.554,0.554,1.482,0.705,2.349
			c0.209,0.574,0.547,1.019,0.47,1.879c0.463,0.555,0.553,1.483,0.705,2.349c0,0.079,0,0.157,0,0.235
			c0.464,0.554,0.553,1.482,0.705,2.349c0.604,1.545,1.186,3.152,0.705,5.168c0.435,0.035,0.144,0.795,0.47,0.939
			c0,0.235,0,0.47,0,0.705c-0.589,0.146-0.586,0.886-0.864,1.344c-0.113,0.09-0.22,0.187-0.311,0.3
			c-0.737-0.111-0.621,0.632-1.174,0.705c0.111,0.738-0.632,0.621-0.705,1.175c-0.057,0.335-0.387,0.396-0.47,0.705
			c-0.816,1.611-1.061,3.794-2.349,4.933c-0.8-1.549-1.355-3.343-2.349-4.698c0.243-0.618,0.281-1.441,0.235-2.349
			c-11.693-47.266-23.394-94.533-35.002-141.886C40.538,155.258,42.454,155.999,42.753,155.124z M83.393,350.569
			c0.363,0.341,0.207,1.203,0.235,1.879c0.558,0.147-0.036,1.446,0.47,1.645c-0.13,3.237-1.685,5.049-2.819,7.282
			c0.036,0.584-0.369,0.728-0.47,1.175c-0.577,0.989-0.936,2.196-1.645,3.054c0,0.078,0,0.157,0,0.235
			c-0.553,1.483-0.805,3.267-1.409,4.698c0,0.078,0,0.157,0,0.235c-0.182,0.602-0.234,1.332-0.705,1.645c0,0.078,0,0.156,0,0.235
			c-0.04,0.666-0.431,0.979-0.47,1.644c-0.078,0-0.156,0-0.235,0c0.035,0.583-0.121,0.975-0.47,1.175
			c-0.485,2.021-1.417,3.594-2.114,5.403c-0.49,0.841-0.236,2.427-0.939,3.054c-0.807,2.169-1.423,4.528-2.819,6.108
			c-0.661-4.507-1.792-8.544-3.054-12.45c-0.876-4.057-2.177-7.689-3.054-11.746c-0.641-0.549-0.742-2.595,0.47-2.584
			c0,0.235,0,0.47,0,0.705c0,0.079,0,0.157,0,0.235c0.605,0.961,0.759,2.374,1.41,3.289c0,0.078,0,0.156,0,0.235
			c1.152,1.041,2.293,2.092,3.523,3.054c0.079,0,0.157,0,0.235,0c1.19,0.767,2.884,1.031,4.229,1.645c0.079,0,0.157,0,0.235,0
			c0.157,0,0.313,0,0.47,0c0.626,0,1.253,0,1.879,0c0.079,0,0.157,0,0.235,0c0.05-0.264,0.261-0.366,0.47-0.47
			c0.503-2.147-1.29-1.999-2.584-2.349c-1.625-0.177-2.921-0.681-3.993-1.41c-1.021-0.624-2.123-1.166-2.584-2.349
			c-0.2-0.661-0.759-0.964-0.705-1.879c-0.043-1.403,0.381-2.187-0.47-2.114c-0.432-3.799,0.687-6.047,1.879-8.222
			c0.003-0.858,0.668-1.055,0.939-1.645c0.631-0.309,1.249-0.63,1.879-0.939c0.079,0,0.157,0,0.235,0
			c0.066-0.482,0.627-0.469,0.94-0.705c0.02-0.763,0.121-1.445,0.47-1.879c0.097-2.455,1.571-3.242,0.939-5.873
			c-0.767-1.012,0.694-1.804,1.645-1.879c0.143-0.014,0.278-0.035,0.235-0.235c0.624,0.002,1.342-0.089,1.645,0.235
			c0.637-0.146,1.864,0.298,2.114-0.235c0.235,0,0.47,0,0.705,0c0.348-0.272,0.592-0.272,0.939,0
			c0.247,1.241,0.429,2.546,0.705,3.758c0.322,1.087,0.93,1.889,0.939,3.289c0,0.078,0,0.156,0,0.235
			C83.327,348.443,83.421,349.445,83.393,350.569z M66.949,394.498c-0.197-0.273-0.31-0.63-0.235-1.175
			c-0.645-1.156-0.842-2.759-1.174-4.228c-0.304-0.95-0.642-1.864-0.705-3.054c-0.463-0.554-0.553-1.482-0.705-2.349
			c-0.197-0.272-0.31-0.63-0.235-1.174c-0.463-0.554-0.553-1.482-0.705-2.349c-0.335-0.761-0.591-1.602-0.705-2.584
			c-0.465-0.788-0.773-1.733-0.94-2.819c0-0.079,0-0.157,0-0.235c-0.465-0.788-0.772-1.733-0.939-2.819
			c-3.192-5.941-3.693-18.087-11.98-18.323c-1.373-0.039-3.26,0.919-5.403,1.41c-3.767,0.861-6.836,1.188-8.927,2.114
			c1.282-4.228,4.931-4.992,9.396-6.107c2.116-0.529,5.341-1.765,7.047-1.41c2.105,0.438,3.218,3.666,4.698,5.168
			c1.532,1.555,2.969,1.668,4.228,3.054c0.564,3.742,1.495,7.118,2.584,10.336c0,0.235,0,0.47,0,0.705
			c0.334,0.84,0.671,1.678,0.705,2.819c0.463,0.555,0.553,1.483,0.705,2.349c0,0.079,0,0.157,0,0.235
			c0.464,0.555,0.553,1.483,0.705,2.349c0.381,1.107,0.828,2.148,0.94,3.524c0.463,0.554,0.553,1.482,0.705,2.349
			c0,0.078,0,0.156,0,0.235c0.632,1.247,0.934,2.825,1.174,4.463c0.254,0.686,0.526,1.353,0.47,2.349
			c0.654-0.106-0.184,1.28,0.47,1.174c-0.124,1.141,0.999,1.037,0.939,2.114c0,0.078,0,0.157,0,0.235
			c-0.513,1.024-0.794,3.365-1.879,3.054C67.354,395.188,66.779,395.216,66.949,394.498z M41.007,404.11
			c-0.083-0.199-0.171-0.39-0.274-0.564c0,0,0,0,0,0c-0.195-0.428-0.384-0.86-0.564-1.297c-0.572-1.388-1.149-2.894-1.728-4.478
			c-0.846-2.662-1.764-5.379-2.705-8.143c-0.259-0.764-0.514-1.519-0.775-2.289c0.001,0.003,0.002,0.006,0.003,0.009
			c-0.294-0.867-0.585-1.736-0.876-2.609c0.29,0.869,0.581,1.737,0.873,2.601c-1.354-4.371-2.626-8.811-3.719-12.811
			c-0.741-2.714-2.096-6.22-2.325-8.813c-0.003-0.092-0.005-0.185-0.006-0.276c-0.004-0.316,0.007-0.629,0.033-0.938
			c0-0.001,0-0.003,0-0.005c0.026-0.307,0.068-0.611,0.125-0.912c-0.001,0.003-0.002,0.006-0.002,0.01
			c0.001-0.004,0.001-0.009,0.003-0.014c0.048-0.253,0.108-0.503,0.18-0.75c-0.072,0.245-0.131,0.495-0.179,0.746
			c1.059-4.091,6.622-4.886,10.627-5.961c2.409-0.646,6.857-2.729,9.631-2.349c3.199,0.438,4.75,4.077,5.873,6.812
			c2.574,6.27,4.05,14.625,6.577,20.672c0,0.078,0,0.157,0,0.235c0.307,0.799-0.523,1.639,0,1.879
			c0.053-0.183,0.007-0.463,0.235-0.47c0.582,3.647,2.366,7.342,2.349,10.806c0.103,0.349,0.301-0.093,0.705,0
			c0,0.078,0,0.157,0,0.235c-0.58,1.609,1.563,2.456,1.409,3.994c-0.16,1.605-2.892,2.418-4.698,3.054
			c-5.894,2.075-13.071,4.235-18.323,5.168c-1.045-0.971-1.804-2.212-2.453-3.544C41.006,404.108,41.006,404.109,41.007,404.11z
			 M30.125,371.72c-0.369-1.264-0.717-2.523-0.944-3.756C29.408,369.196,29.756,370.456,30.125,371.72z M41.344,343.052
			c2.296-0.131,3.791-1.064,6.107-1.175c0.696,1.6,2.096,3.664,1.879,5.403c-4.294,0.639-7.953,1.913-11.98,2.819
			C38.521,347.59,40.025,345.413,41.344,343.052z M38,342.761c0.031-0.066,0.067-0.128,0.098-0.194c0,0,0,0,0,0.001
			C38.067,342.634,38.032,342.695,38,342.761z M55.104,351.098c0.223,0.266,0.453,0.522,0.695,0.763c0,0,0,0,0,0
			C55.558,351.62,55.328,351.363,55.104,351.098z M66.714,343.757c0.345-1.691,0.569-3.503,1.41-4.698
			c0.28-0.581,0.804-0.918,1.409-1.174c0.197,0.272,0.31,0.629,0.235,1.174c0.41,1.312,1.056,2.389,1.174,3.994
			c0.53,0.175,0.092,1.317,0.235,1.879c-0.066,0.795-0.374,1.349-0.235,2.349c-0.697,0.713-1.097,1.722-2.114,2.114
			c-0.626,0-1.253,0-1.879,0c-0.712-0.462-1.321-1.028-2.114-1.409c-0.068-1.107-0.686-1.664-0.47-3.054
			C64.317,344.292,66.933,345.676,66.714,343.757z M64.339,357.539c0,0.004-0.001,0.01-0.001,0.015c0-0.004,0-0.008,0-0.012
			C64.338,357.541,64.339,357.54,64.339,357.539z M80.339,332.481c0,0.783,0,1.566,0,2.349c-0.013,0.144-0.035,0.278-0.235,0.235
			c0,0.079,0,0.157,0,0.235c-0.075,0.944-0.424,1.612-1.175,1.879c-0.523,0.849-2.375,0.388-3.289,0.235
			c-0.355-0.819-1.406-0.943-2.114-1.409c-1.123-0.756-0.63-3.128-1.174-4.463c0-0.392,0-0.783,0-1.174
			c0.31,0.002,0.214,0.412,0.47,0.47c-0.051,2.009,0.201,3.714,1.879,3.993c2.276-1.527-0.757-6.23,1.174-8.457
			c0.315-0.155,0.438-0.502,0.47-0.94c0.079,0,0.157,0,0.235,0c0.123-0.347,0.348-0.592,0.47-0.939
			c0.566,0.096,0.51-0.429,1.174-0.235c0.074,0.317,0.334,0.449,0.235,0.939c0.435,0.036,0.144,0.795,0.47,0.94
			c0.087,1.244,1.01,1.652,0.94,3.054C80.277,330.037,80.413,331.154,80.339,332.481z M38.995,161.702
			c-0.325-1.277-0.578-2.979-1.185-4.234C38.419,158.721,38.67,160.424,38.995,161.702z M28.94,205.427
			c-1.681-6.058-3.365-12.129-5.042-18.23c-0.008-0.027-0.015-0.055-0.022-0.082c-0.761-2.849-1.517-5.691-2.264-8.5
			c-0.889-3.34-3.255-10.186-2.584-11.51c0.624-1.231,2.185-1.158,4.229-2.114c4.563-2.136,9.457-6.685,13.83-8.574
			c0.154,0.149,0.293,0.321,0.419,0.51c0,0.001,0.001,0.002,0.002,0.003c11.078,44.882,22.496,89.422,33.201,134.678
			c-2.158-0.662-4.214-1.424-6.108-2.349c1.08-2.826-0.731-7.74-2.349-10.101c-2.483,0.492-2.707,3.244-6.107,2.819
			c-2.001-4.801-0.724-10.779,3.523-11.98c-0.034-3.579-0.378-7.596-1.879-10.336c-5.069,2.448-10.004,5.03-11.98,10.571
			c-0.609-1.844-1.302-3.778-1.41-5.873c-0.034-0.669,0.292-1.4,0.235-2.114c-0.298-3.702-2.063-8.043-3.054-12.215
			c-2.832-11.929-6.617-23.648-10.101-35.472c-0.025-0.084-0.05-0.173-0.075-0.258c0.032,0.116,0.063,0.23,0.096,0.346
			c-0.034-0.121-0.067-0.242-0.101-0.363c0.001,0.006,0.003,0.012,0.005,0.017c-0.082-0.296-0.165-0.594-0.247-0.89
			C30.419,210.752,29.681,208.091,28.94,205.427z M62.486,287.613c-1.542,0.338-3.364,0.395-5.168,0.47
			c-0.38-0.873-0.394-2.112-0.705-3.054c1.777-0.416,3.693-0.692,4.463-2.114C62.094,283.812,62.564,286.392,62.486,287.613z
			 M52.855,275.633c-0.037,0.276,0.04,0.667-0.235,0.705c0,0.079,0,0.157,0,0.235c0.145,0.638-0.298,1.865,0.235,2.114
			c0.113,3.019,1.029,5.235,1.409,7.987c0.233,0.628,0.484,1.238,0.47,2.114c0.299,0.418,0.801,1.549-0.235,1.409
			c-0.509,0.04-0.106-0.833-0.235-1.174c-0.271-0.278-0.266-0.831-0.235-1.41c-0.509,0.04-0.106-0.833-0.235-1.174
			c-0.283-0.813-0.43-1.763-0.47-2.819c-0.361-0.97-0.598-2.064-0.705-3.289c-0.325-1.006-0.52-2.143-0.47-3.523
			c0-0.235,0-0.47,0-0.705c-0.078,0-0.156,0-0.235,0c-0.052-1.54,0.546-2.429,0.705-3.758c0.535-0.483,0.811-1.225,0.705-2.349
			c-0.623,0.003-1.383-0.13-1.41,0.47c-1.348,1.471-1.408,4.23-1.879,6.578c1.193,4.367,1.794,9.325,2.584,14.094
			c-1.059,0.776-3.111-0.322-3.52,1.396c-0.004-0.004-0.007-0.008-0.011-0.012c0,0,0,0,0,0c-0.652-0.686-0.985-1.628-1.187-2.664
			c0.202,1.036,0.535,1.978,1.187,2.664c-1.581-5.377-2.177-11.739-2.342-18.532c3.093-3.484,4.779-8.375,9.631-10.101
			c0.013,0.3,0.301,0.326,0.235,0.705c-0.042,1.138,0.31,1.882,0.47,2.819c-0.504,1.218-1.972,1.474-2.349,2.819
			C53.814,271.583,52.063,273.351,52.855,275.633z M29.569,294.094C29.57,294.093,29.57,294.093,29.569,294.094
			c-0.009,0.01-0.02,0.02-0.03,0.031C29.549,294.114,29.559,294.104,29.569,294.094z M20.907,311.104
			c-0.134,0.169-0.289,0.318-0.429,0.481c-0.008,0.008-0.015,0.017-0.022,0.024C20.604,311.439,20.766,311.282,20.907,311.104z
			 M19.62,312.531c0,0,0.001-0.001,0.002-0.001C19.622,312.53,19.621,312.531,19.62,312.531
			C19.62,312.531,19.62,312.531,19.62,312.531z M15.752,402.317c0-0.01,0.001-0.02,0.001-0.03c0,0.007,0,0.014,0,0.02
			c0.029-0.776,0.073-1.552,0.126-2.329c0.007-0.111,0.016-0.222,0.024-0.333c0.05-0.692,0.108-1.384,0.175-2.077
			c0.011-0.111,0.021-0.221,0.031-0.332c0.158-1.594,0.352-3.189,0.571-4.788c-0.013,0.098-0.029,0.196-0.042,0.293
			c0.016-0.109,0.029-0.217,0.046-0.326c0.029-0.214,0.063-0.429,0.093-0.644c0.571-4.071,1.268-8.16,1.85-12.279
			c-0.582,4.119-1.278,8.208-1.85,12.279c-0.028,0.199-0.059,0.398-0.086,0.597c1.889-12.415,5.636-25.159,2.103-38.277
			c-0.309-1.336-0.423-2.866-1.175-3.758c-0.838-4.758-3.153-12.621,0-16.444c0.495-0.998,2.533-1.69,1.879-3.054
			c0.626-5.18-2.011-16.782,4.698-17.854c0.057,0.414,0.404,0.536,0.939,0.47c-0.143-0.562,0.295-1.705-0.235-1.879
			c-0.022-0.119-0.043-0.239-0.065-0.359c-0.046-0.614-0.11-1.231-0.188-1.854v0c-0.05-0.392-0.103-0.785-0.158-1.178
			c0.055,0.394,0.108,0.786,0.158,1.178c0-0.196,0.001-0.392,0.019-0.605c0.078,0,0.157,0,0.235,0
			c0.068-0.77,0.162-3.396-0.235-4.463c0.155-1.915-0.435-3.117,0-4.698c2.567-2.836,5.346-5.459,7.752-8.457
			c0.408-0.688,0.554-1.639,0.47-2.819c0.391-0.636,0.391-2.888,0-3.523c-0.04-5.911-1.442-10.46-1.879-15.974
			c-0.558-1.321-0.269-3.489-0.705-4.933c0-1.488,0-2.976,0-4.463c-0.278-2.305,0.579-5.747-0.47-7.282
			c-0.285-8.563-2.544-15.152-3.758-22.787c1.04-0.604,2.058-1.23,3.054-1.879c0.847-0.139,1.575-0.396,2.349,0
			c3.994,12.424,6.77,26.134,10.101,38.76c0.916,3.471,2.549,6.981,2.349,10.336c0.149,0.791-0.303,2.182,0.235,2.584
			c0,3.758,0.618,6.899,1.175,10.101c-1.86,1.037-2.165,3.63-3.994,4.698c-0.705,0-1.41,0-2.114,0c-1.174,0-2.349,0-3.523,0
			c-0.253-0.389-2.096-0.389-2.349,0c-5.933,5.114,11.049,4.42,14.564,3.289c0.927-0.169,1.901-0.292,2.584-0.705
			c0.982-0.114,1.823-0.369,2.584-0.705c2.748-0.932,5.439-1.921,7.987-3.054c0.939,0.078,1.304-0.419,2.349-0.235
			c0.705,0,1.409,0,2.114,0c1.284,0.36,2.346,0.942,2.584,2.349c0.78,0.864,1.35,1.939,1.879,3.054c0,0.079,0,0.157,0,0.235
			c0.906,0.896,1.364,2.238,1.879,3.524c0,0.078,0,0.157,0,0.235c1.123,2.244,1.853,4.881,2.584,7.517
			c0.337,1.073,0.895,1.924,0.94,3.289c0.528,0.803,0.751,1.911,0.939,3.054c0,0.078,0,0.156,0,0.235
			c0.46,0.636,0.634,1.559,0.705,2.584c0,0.078,0,0.157,0,0.235c0.413,0.683,0.535,1.657,0.705,2.584
			c-0.318,0.074-0.449,0.334-0.94,0.235c-0.3,0.013-0.325,0.301-0.705,0.235c-0.131,0.338-0.366,0.573-0.705,0.705
			c-1.659,1.708-2.97,3.765-2.819,7.282c-0.157,0-0.313,0-0.47,0c-2.694-6.937-3.943-15.319-6.577-22.316
			c-0.189-1.611-1.617-1.985-1.879-3.523c0.64-0.898,1.767-2.393,0.939-3.994c-0.156,0-0.313,0-0.47,0c0-0.078,0-0.157,0-0.235
			c-1.286-0.111-1.638,0.711-1.879,1.645c-0.516,0.659-0.121,2.228-1.644,1.879c-0.253-0.389-2.096-0.389-2.349,0
			c-6.401,0.664-9.811,3.107-16.914,5.403c-2.086,0.674-4.769,1.603-8.222,2.114c-2.265,0.336-8.212,0.092-7.047,2.819
			c0.235,0.55,0.414,0.961,1.644,0.939c0.636,0.39,2.888,0.39,3.524,0c6.306-0.35,11.403-1.909,15.974-3.994
			c2.746-0.856,5.294-1.909,7.752-3.054c2.236-0.332,5.819-1.621,6.813,0.705c0.293,0.569,0.582,1.142,0.705,1.879
			c0.475,1.326,0.998,2.604,1.174,4.229c0.713,1.558,1.087,3.454,1.41,5.403c0.63,2.267,1.312,4.483,1.644,7.047
			c0.403,0.771,0.622,1.728,0.705,2.819c0.47,1.174,0.658,2.63,1.174,3.759c-0.073,0.934,0.542,1.18,0.47,2.114
			c-0.313,0-0.626,0-0.939,0c-0.026,0.443-0.777,0.163-0.705,0.705c-0.183,0.053-0.463,0.006-0.47,0.235c-0.078,0-0.156,0-0.235,0
			c-0.734,0.91-1.607,1.682-1.879,3.054c-0.16,0.937-0.594,1.599-0.47,2.819c-0.519,0.029-0.1,0.997-0.235,1.41
			c-0.311-0.003-0.215-0.412-0.47-0.47c-1.231-3.78-2.104-7.918-2.584-12.45c0.912-2.403-1.733-3.276-2.819-4.463
			c-0.144-0.013-0.278-0.035-0.235-0.235c-0.682-0.649-1.545-1.117-2.114-1.879c0.225-0.48,0.48-0.93,0.705-1.41
			c0.679-1.219,1.792-3.914,0.705-5.403c-0.409-0.018-0.73,0.053-0.939,0.235c-1.589,0.917-2.697,2.314-3.523,3.994
			c-0.638,0.146-1.864-0.298-2.114,0.235c-3.399,0.179-6.085,1.261-10.336,2.349c-3.173,0.813-6.896,1.158-9.162,2.114
			c-1.232,0.52-2.087,1.51-3.054,1.879c-1.769,0.675-7.663,0.328-7.987,1.879c-0.224,1.07,0.491,1.783,1.645,1.175
			c0.482,0.39,2.572,0.39,3.054,0c9.931-1.267,17.829-4.566,27.484-6.108c0.795,0.85,1.907,1.382,2.584,2.349
			c0.693,0.325,0.951,1.085,1.645,1.41c-0.061,0.688,0.531,0.722,0.47,1.41c0,0.078,0,0.156,0,0.235
			c0.642,0.846,0.923,2.052,1.175,3.289c0.708,2.19,1.382,4.412,1.644,7.047c0.356,0.583,0.454,1.426,0.47,2.349
			c0,0.078,0,0.156,0,0.235c0.356,0.583,0.453,1.426,0.47,2.349c0.278,0.799,0.483,1.671,0.6,2.632
			c-0.036-0.369-0.068-0.731-0.106-1.104c0.038,0.381,0.076,0.761,0.112,1.139c-0.001-0.012-0.004-0.023-0.006-0.036
			c0.026,0.272,0.051,0.54,0.078,0.812c0.019,0.33,0.034,0.664,0.027,1.02c0.032,0.011,0.05,0.035,0.075,0.052
			c0.045,0.487,0.09,0.969,0.134,1.445c0,0,0,0,0-0.001c0.011,0.114,0.021,0.226,0.032,0.339c-0.011-0.113-0.021-0.224-0.032-0.338
			c-0.004,0.141-0.001,0.273,0.026,0.382c-0.062,0.779,0.167,1.849-0.211,2.313c-0.025-0.002-0.05-0.004-0.076-0.006
			c-0.001,0-0.003,0-0.005,0c-1.073-0.929-2.939-1.063-4.404-1.601c-0.002-0.001-0.004-0.002-0.006-0.004
			c-1.678-1.217-2.258-3.532-3.52-5.165c-2.268-3.527-2.943-8.646-4.463-12.92c0.512-2.13-2.42-2.398-2.584-0.705
			c0,0.078,0,0.157,0,0.235c-1.192,1-3.025,1.359-4.229,2.349c-1.785-0.12-4.627,0.708-6.255,1.754c0.001,0,0.002-0.001,0.003-0.001
			c-0.094,0.053-0.179,0.112-0.258,0.175c-1.482,1.064-1.634,2.306,1.812,3.006c0.235,0,0.47,0,0.705,0
			c-0.004,0.701-0.611,0.799-0.705,1.41c-1.529,2.621-3.182,5.118-4.463,7.987c-0.966,0.914-1.449,2.31-2.114,3.523
			c-0.508,1.407-2.325,2.639-1.645,4.463c-2.439,1.181-3.464,4.003-3.289,7.517c0.316,6.332,3.454,13.448,5.638,20.203
			c2.266,7.007,4.276,13.917,6.578,19.262c-3.722,1.681-7.799,3.007-11.041,5.168c-0.678,0.34-1.394,0.642-1.879,1.174
			c-0.168,0.381,1.108,0.381,0.94,0c5.126,0.657,9.963-1.343,14.33-2.819c9.239,2.099,17.019-4.13,25.135-4.698
			c1.208-0.828,1.988-2.084,2.819-3.289c1.454-4.576,3.424-8.634,4.933-13.155c0.865-1.249,1.347-2.881,1.879-4.463
			c1.208-3.176,2.346-6.424,3.758-9.396c0.83-1.128,1.297-2.619,1.879-3.994c0-0.079,0-0.157,0-0.235
			c0.525-0.65,0.914-1.435,1.175-2.349c0.013-0.3,0.301-0.325,0.235-0.705c0.864-1.329,1.623-2.762,2.349-4.229
			c1.128-1.613,1.81-3.671,1.879-6.343c0.315-1.385,0.307-3.02,0-4.463c-0.967-3.575-0.974-8.109-2.114-11.51
			c-0.327-1.318-1.14-2.149-1.645-3.289c-0.282-1.597-0.334-3.424-0.705-4.933c0-0.861,0-1.723,0-2.584
			c-1.106-4.815-1.539-10.203-2.114-15.504c-0.396-3.644-1.897-7.924,0.47-10.806c1.331-1.488,1.676-3.962,3.523-4.933
			c0.324,0.01,0.516-0.111,0.705-0.235c0.317-0.074,0.448-0.334,0.939-0.235c0.34-0.521,1.398-0.324,1.879-0.705
			c0.159,0.546,0.781,0.629,0.939,1.175c0.633,1.012,1.297,1.991,1.41,3.523c0.458,0.873,0.636,2.026,0.705,3.289
			c0,0.079,0,0.157,0,0.235c0.371,0.491,0.487,1.236,0.47,2.114c0,0.705,0,1.41,0,2.114c0,0.47,0,0.939,0,1.41
			c0.079,0,0.157,0,0.235,0c0,1.331,0,2.662,0,3.993c0,1.175,0,2.349,0,3.524c0.053,0.182,0.007,0.463,0.235,0.47
			c0,0.47,0,0.939,0,1.41c0.679,0.104-0.208,1.775,0.47,1.879c0,0.313,0,0.626,0,0.94c0,0.078,0,0.156,0,0.235
			c0.388,0.003,0.131,0.651,0.47,0.705c-0.075,0.545,0.038,0.902,0.235,1.175c0.435,0.661,0.331,1.862,0.705,2.584
			c0,0.47,0,0.939,0,1.409c0.696,2.179-0.11,4.936,0.705,7.282c0.147,0.714-0.301,2.023,0.235,2.349
			c0.128,0.341-0.275,1.214,0.235,1.175c0,0.391,0,0.783,0,1.174c0.638-0.168-0.168,1.108,0.47,0.939
			c0.221,1.502,0.487,2.958,0.939,4.229c0,0.392,0,0.783,0,1.175c0,0.078,0,0.156,0,0.235c0.665-0.039-0.195,1.448,0.47,1.409
			c0,0.861,0,1.723,0,2.584c-0.505,2.713,1.71,4.346-0.235,5.873c0.089,1.029-0.671,1.208-0.705,2.114
			c-0.143,0.013-0.278,0.035-0.235,0.235c-1.672,4.749-3.798,9.044-5.873,13.39c-0.144,0.014-0.278,0.035-0.235,0.235
			c-0.421,0.597-0.728,1.308-0.939,2.114c0,0.079,0,0.157,0,0.235c-0.812,1.067-1.202,2.557-1.645,3.994
			c-0.771,1.734-1.326,3.686-1.644,5.873c-0.877,1.55-1.352,3.503-2.349,4.933c0,0.079,0,0.157,0,0.235
			c-3.055,3.758-5.889,7.736-10.101,10.336c-0.079,0-0.157,0-0.235,0c-0.937,0.551-1.311,1.665-2.349,2.114
			c-0.921,0.019-1.465,0.414-2.114,0.705c-1.818,0.453-2.646,1.896-3.759,3.054c-1.044-0.104-1.243,0.636-1.879,0.939
			c-0.202,1.533,0.484,2.178,0.47,3.524c0,0.861,0,1.723,0,2.584c0,0.078,0,0.157,0,0.235c0.29,0.649,0.622,1.258,0.705,2.114
			c-0.235,0.626-0.948,0.774-1.41,1.175c-0.643,0.374-1.159,0.877-1.879,1.174c-2.68,1.862-6.257,2.827-10.336,3.289
			c-0.714,0.147-2.023-0.301-2.349,0.235c-5.674,0.354-10.521,1.537-15.974,2.114c-0.791,0.148-2.182-0.303-2.584,0.234
			c-0.157,0-0.313,0-0.47,0c-0.791,0.149-2.182-0.303-2.584,0.235c-0.392,0-0.783,0-1.175,0c-1.566,0-3.132,0-4.698,0
			c-1.356-0.288-0.49-2.799-1.409-3.524c-0.236-1.564-0.532-3.07-0.94-4.463c-0.268-5.083-1.91-9.569-1.879-14.564
			c0.001-0.16,0.006-0.319,0.01-0.479c-0.021,0.614-0.037,1.229-0.042,1.842C15.712,403.651,15.727,402.984,15.752,402.317z
			 M86.613,355.014c0-0.001,0-0.001,0-0.002c0.009,0.096,0.019,0.192,0.028,0.293C86.632,355.206,86.623,355.108,86.613,355.014z
			 M43.693,427.62c7.06-0.834,14.31-0.671,18.793-4.464c1.074-0.648,2.006-1.438,3.054-2.114c0.412-0.202,0.816-0.875,0.705,0.235
			c0.365,0.888,0.458,2.047,0.47,3.289c0.363,0.341,0.207,1.202,0.235,1.879c0.673,0.032-0.203,1.612,0.47,1.645
			c0.14,0.486-0.291,1.543,0.235,1.645c-0.076,1.093,0.468,1.567,0.47,2.584c0.074,0.317,0.334,0.448,0.235,0.939
			c0.274,0.353,0.678,0.574,0.705,1.175c-1.562,1.854-3.191,2.618-5.168,3.054c-5.985,3.895-14.125,5.147-23.256,6.577
			c-9.659,1.512-19.46,2.513-29.834,1.879c-2.078-5.282-3.401-11.319-3.993-18.088C18.304,431.856,31.752,429.03,43.693,427.62z
			 M3.14,424.917C3.14,424.917,3.14,424.917,3.14,424.917c0.89-2.873,3.515-4.013,6.022-5.27
			C6.657,420.906,4.03,422.044,3.14,424.917z M87.622,660.65c0.236-0.051,0.501-0.127,0.78-0.216c-0.001,0-0.003,0-0.004,0
			c0.002,0,0.004,0,0.006-0.001c0,0.001-0.001,0.001-0.002,0.001c0.001,0,0.003,0,0.004-0.001
			C88.125,660.523,87.859,660.6,87.622,660.65z M138.597,623.535c1.7-2.118,3.53-4.107,4.827-6.628
			C142.127,619.429,140.297,621.417,138.597,623.535z M146.114,598.399c-0.354,1.132-1.091,2.019-1.41,3.054
			c-0.921,2.994-1.135,6.169-2.349,8.927c-10.549,16.466-28.546,30.931-46.277,41.344c-2.606,1.53-6.017,4.054-8.222,4.464
			c-4.888,0.908-13.008,0.012-16.209-2.114c-3.671-2.438-2.535-9.484-8.457-10.102c-7.624-11.023-16.546-20.503-22.316-33.356
			c-6.055-13.489-8.263-28.093-9.866-45.573c-0.368-4.005-1.649-8.1-2.349-12.215c-1.368-8.039-2.319-16.117-3.297-24.202
			c0.156,1.695,0.314,3.395,0.483,5.092c-0.169-1.699-0.328-3.399-0.484-5.097c0,0.002,0,0.003,0,0.005
			c-0.001-0.011-0.002-0.021-0.003-0.033c-0.178-1.928-0.358-3.85-0.559-5.753c-0.2-3.312-0.272-6.612-0.84-9.712
			c-0.69-3.769-2.165-7.541-3.289-11.275c-1.158-3.849-2.117-7.785-3.289-11.511c-2.344-7.451-6.397-14.309-8.222-21.377
			c-1.642-6.359-2.101-13.44-3.054-19.732c18.89,1.707,37.512-1.408,52.385-4.933c0.661-0.157,2.051-1.187,2.584,0
			c-0.098-0.804,0.071-1.339,0.939-1.175c3.208-2.117,7.375-3.273,9.866-6.107c0.975-0.417,1.551-2.283,2.819-1.645
			c0.425,1.924,1.44,3.258,1.879,5.168c0.5,1.224,1.138,2.308,1.41,3.759c2.315,4.967,4.254,10.31,5.873,15.974
			c0,0.078,0,0.156,0,0.234c0.611,0.485,0.329,1.864,0.94,2.35c0.416,2.638,0.958,5.149,1.409,7.752c0,0.705,0,1.409,0,2.114
			c0.079,0,0.157,0,0.235,0c0,0.939,0,1.879,0,2.818c0,0.157,0,0.313,0,0.471c0.053,0.182,0.007,0.463,0.235,0.47
			c0,0.626,0,1.253,0,1.879c0,2.349,0,4.698,0,7.047c-0.037,0.277,0.041,0.667-0.235,0.705c-0.117,0.739,0.512,0.918,0,1.409
			c-0.153,1.257,0.309,3.128-0.235,3.994c0,0.078,0,0.156,0,0.234c0,1.175,0,2.35,0,3.523c-0.078,0-0.156,0-0.235,0
			c0,0.079,0,0.157,0,0.235c0.154,1.49-0.31,3.599,0.235,4.698c0.092,3.197,0.938,5.641,1.175,8.691
			c0.622,2.51,1.156,5.108,1.644,7.752c0.489,1,0.691,2.285,0.94,3.524c0,0.078,0,0.156,0,0.234c0.463,0.555,0.553,1.482,0.705,2.35
			c0,0.078,0,0.156,0,0.234c0.794,1.634,1.282,3.573,1.879,5.403c0.612,1.346,1.12,2.795,1.645,4.229c0.078,0,0.156,0,0.235,0
			c0.24,0.855,1.366,0.826,2.114,1.174c0,0.157,0,0.313,0,0.47c0.418,0.679,0.423,1.771,0.94,2.35
			c-0.073,0.935,0.542,1.18,0.47,2.114c1.725,2.895,4.675,4.564,5.638,8.222c0,0.078,0,0.156,0,0.235
			c0.703,0.862,1.038,2.094,1.41,3.288c0.609,1.506,1.319,2.909,1.645,4.698c0.724,1.077,1.076,2.526,1.409,3.994
			c0.601,1.826,1.195,3.659,1.41,5.872c0.183,0.052,0.273,0.197,0.235,0.47c0.029,1.537,1.322,1.81,1.174,3.524
			c0.053,0.182,0.007,0.463,0.235,0.47c0,0.783,0,1.565,0,2.349c0.541,0.556,0.084,2.108,0.235,3.054c-0.079,0-0.157,0-0.235,0
			c0.147,0.714-0.3,2.023,0.235,2.35c0,0.392,0,0.783,0,1.174c-0.519,0.029-0.1,0.997-0.235,1.41c0,1.174,0,2.349,0,3.523
			c0,0.47,0,0.939,0,1.409c-0.078,0-0.156,0-0.235,0c0,0.078,0,0.157,0,0.235c0,0.939,0,1.879,0,2.818
			c-0.052,0.183-0.006,0.464-0.235,0.471c-0.013,1.083,0.104,2.296-0.235,3.054c-0.147,0.714,0.301,2.023-0.235,2.349
			c0,0.156,0,0.313,0,0.47c-0.121,0.982-0.021,1.744,0.705,1.88c1.284-0.596,0.982-2.777,1.645-3.994
			c0.149-0.791-0.303-2.182,0.235-2.584c0-0.078,0-0.156,0-0.234c0.147-0.715-0.301-2.023,0.235-2.35c0-0.078,0-0.156,0-0.234
			c0-0.783,0-1.566,0-2.35c0.079,0,0.157,0,0.235,0c0-0.078,0-0.156,0-0.234c0.15-0.868-0.304-2.341,0.235-2.819
			c0-0.078,0-0.156,0-0.235c0.152-1.022-0.306-2.655,0.235-3.288c0-0.078,0-0.157,0-0.235c0.152-1.022-0.306-2.655,0.235-3.288
			c0.048-0.735-0.159-1.725,0.235-2.114c-0.152-1.023,0.306-2.656-0.235-3.289c0-0.235,0-0.47,0-0.705c0-0.078,0-0.156,0-0.234
			c-0.668-1.29-1.02-2.896-1.41-4.464c-0.074-0.317-0.334-0.448-0.235-0.939c-0.589-0.585-0.667-1.683-1.175-2.349
			c0.096-0.566-0.429-0.511-0.235-1.175c-0.074-0.317-0.334-0.448-0.235-0.939c-0.465-0.788-0.772-1.733-0.939-2.819
			c-0.209-0.574-0.547-1.019-0.47-1.879c-0.464-0.555-0.554-1.482-0.705-2.35c-0.435-1.209-0.885-2.403-0.939-3.993
			c1.838,1.765,3.413,3.791,4.698,6.107c1.359,1.304,2.242,3.083,3.289,4.698c0.639,1.084,1.478,1.968,1.879,3.289
			c0.946,0.855,1.458,2.144,2.114,3.289c0,0.078,0,0.156,0,0.234c0.977,1.216,1.742,2.643,2.349,4.229
			c0.013,0.301,0.301,0.325,0.235,0.705c0.525,0.649,0.914,1.435,1.174,2.349c0.649,1.387,1.356,2.716,1.645,4.464
			c1.23,2.233,1.745,4.436,2.114,8.222c0.151,1.553-0.178,8.602,1.879,8.456c1.646-0.116,1.299-3.818,1.174-5.638
			c-1.127-16.432-9.569-27.564-15.269-36.881c4.613,1.991,6.966,5.258,9.866,8.927c3.079,3.896,6.311,7.808,8.692,11.98
			c1.05,1.842,1.419,5.698,4.463,5.403c1.004-2.27-0.931-4.399-1.879-6.108c-3.155-5.683-6.937-11.141-10.894-15.755
			c0-0.001-0.001-0.003-0.002-0.004c-0.193-0.344-0.378-0.699-0.553-1.071c-0.001-0.004-0.003-0.007-0.005-0.011
			c-0.131-0.458-0.315-0.862-0.524-1.241c-0.001-0.002-0.002-0.004-0.002-0.006c0,0,0-0.001-0.001-0.001
			c-1.171-2.129-3.461-3.193-5.872-4.697c-1.024-0.639-1.984-1.247-2.921-1.803c0.412,0.24,0.818,0.492,1.219,0.752
			c-0.487-0.316-0.98-0.623-1.485-0.909c-1.989-1.162-3.875-2.042-5.974-2.269c-2.226-2.942-7.327-4.261-7.282-8.691
			c10.84,2.466,19.284,7.104,26.31,12.92c2.336,1.934,4.206,5.162,7.752,5.403c0.212-0.414,0.525-0.728,0.939-0.939
			c-0.279-3.799-4.062-5.979-6.578-7.987c-6.166-4.922-13.037-9.399-21.587-11.882c-0.023-0.008-0.046-0.015-0.069-0.021
			c-0.519-0.168-1.033-0.318-1.542-0.461c-0.156-0.043-0.31-0.082-0.464-0.123c-0.389-0.104-0.774-0.198-1.155-0.284
			c-0.15-0.034-0.3-0.069-0.449-0.102c-0.511-0.108-1.017-0.206-1.512-0.282c-0.237-0.036-0.499-0.053-0.767-0.063
			c-0.979-0.158-1.97-0.302-2.991-0.407c-1.046-1.146-2.209-2.176-2.819-3.759c2.086-9.604,4.434-18.947,7.999-27.073
			c-0.003,0.006-0.006,0.013-0.008,0.019c0.175-0.385,0.34-0.768,0.5-1.148c-0.001,0.003-0.002,0.007-0.004,0.01
			c0.514-1.119,1.048-2.218,1.615-3.284c0.115-1.055-0.119-1.76-0.235-2.584c1.222-2.314,2.996-4.395,4.228-7.047
			c0.779-1.678,0.932-3.762,1.879-5.403c1.548-2.682,4.616-3.897,5.638-6.343c0.304-0.727,0.351-1.693,0.489-2.663
			c0.139-0.528,0.271-1.069,0.394-1.624c0.003-0.015,0.006-0.028,0.009-0.042c0.121-0.548,0.235-1.105,0.342-1.675
			c0.001-0.004,0.001-0.008,0.002-0.012c0,0.002-0.001,0.005-0.001,0.007c0.001-0.007,0.002-0.014,0.004-0.021
			c-0.001,0.005-0.001,0.009-0.002,0.014c1.74-7.931,1.763-17.565,2.522-25.932c0.128-1.416,0.413-3.538,0.939-4.934
			c0.381-1.01,1.347-1.682,1.645-2.584c1-3.031,1.174-6.411,1.879-9.396c0.709-3.001,1.475-6.03,2.349-8.927
			c0.161-0.532,0.344-1.056,0.521-1.583c0.334-0.88,0.679-1.753,1.033-2.619c0.265-0.648,0.54-1.289,0.814-1.93
			c0.108-0.251,0.213-0.504,0.322-0.755c0.313-0.718,0.632-1.43,0.956-2.139c0.061-0.133,0.121-0.267,0.182-0.399
			c0.36-0.783,0.725-1.561,1.096-2.334c0.001-0.002,0.002-0.004,0.003-0.006c0.001-0.002,0.002-0.005,0.004-0.007
			c3.092-6.439,6.542-12.557,9.868-18.532c-3.326,5.976-6.775,12.092-9.868,18.532c4.54-8.814,10.306-17.192,13.861-26.049
			c1.182-2.943,1.598-6.411,3.054-8.457c0.521-0.732,1.57-1.123,2.349-1.879c2.411-2.345,4.427-5.722,7.047-8.457
			c4.611-4.813,9.708-8.66,16.678-9.866c0.829,0.304-1.087,1.139,0.47,0.94c0.423-0.004,0.84-0.028,1.258-0.045
			c0,0-0.001,0-0.002-0.001c0.001,0,0.001,0,0.002,0.001h0c0.051,0.015,0.096,0.036,0.151,0.045c0.193,0.034,0.4,0.042,0.614,0.038
			c0.067-0.001,0.136-0.006,0.205-0.011c0.157-0.01,0.318-0.027,0.484-0.052c0.073-0.011,0.146-0.021,0.22-0.034
			c0.21-0.038,0.425-0.084,0.645-0.139c0.024-0.006,0.046-0.01,0.07-0.016c0,0,0,0,0,0c2.535-0.258,4.989-0.741,7.36-1.364
			c-0.001,0-0.002,0-0.003,0c0.001,0,0.003,0,0.004,0c0,0-0.001,0-0.001,0c0.17-0.019,0.345-0.03,0.502-0.067
			c0.442-0.102,0.905-0.239,1.375-0.389c0.116-0.037,0.233-0.075,0.351-0.114c0.455-0.152,0.917-0.316,1.387-0.497
			c0.005-0.001,0.009-0.003,0.014-0.005c0,0-0.001,0-0.002,0c0.002-0.001,0.004-0.001,0.007-0.002c-0.001,0-0.003,0.001-0.005,0.002
			c4.95-1.618,9.489-3.683,13.552-5.337c-10.718,12.935-21.846,29.38-22.786,51.68c-0.324,7.689,0.734,15.889,0.235,24.666
			c-0.249,4.377-0.942,9.036-1.645,13.625c-0.747,4.881-0.637,9.69-0.939,14.564c-0.276,4.438-1.553,8.849-1.175,13.39
			c1.115,13.384,6.777,22.346,12.215,31.243c5.661,9.262,10.942,18.162,15.022,28.396c-0.017,0.015-0.035,0.028-0.051,0.043
			c-0.034,0.028-0.068,0.057-0.102,0.085c0,0,0.001-0.001,0.002-0.001c-2.293,1.977-4.858,3.681-7.4,5.409
			c2.539-1.73,5.105-3.432,7.398-5.408c-2.203,1.249-4.196,2.91-6.647,4.598c-0.962,0.662-2.063,1.618-3.195,2.509
			c-0.451,0.323-0.897,0.651-1.337,0.985c0.001-0.001,0.002-0.002,0.003-0.003c-0.777,0.534-1.55,0.981-2.283,1.207
			c-0.416,0.128-0.875,0.229-1.353,0.318c0,0,0,0-0.001,0s0,0-0.001,0c-0.953,0.083-1.908,0.226-2.866,0.403
			c-0.009,0.002-0.019,0.003-0.028,0.005c0.003,0,0.005-0.001,0.008-0.001c-0.011,0.002-0.021,0.004-0.033,0.006
			c0.008-0.002,0.017-0.003,0.024-0.005c-1.023,0.123-2.061,0.252-3.033,0.448c-8.623,1.742-18.195,1.327-26.545,4.229
			c1.939-2.341,0.146-4.363-0.47-6.108c-1.277-3.616-1.789-7.932-2.819-11.98c-2.022-7.943-4.517-15.538-5.873-23.726
			c-0.702-4.237-0.729-9.076-2.114-12.686c-0.754-1.965-1.842-3.374-2.349-5.168c-2.629-9.307-2.924-24.505-0.235-34.062
			c0.91-3.233,3.364-7.254,3.819-10.885c0.06-0.129,0.114-0.262,0.175-0.391c-0.051-0.132-0.075-0.254-0.112-0.38
			c-0.359-1.215-0.139-2.228,0.31-3.163c-0.448,0.935-0.668,1.948-0.31,3.163c0.025-0.593-0.007-1.174-0.123-1.734
			c0.78-1.178,1.47-2.445,1.879-3.994c-5.294,5.926-7.657,16.797-10.297,26.168c-0.091-0.176-0.181-0.354-0.271-0.532
			c-0.061-0.12-0.123-0.239-0.183-0.359c-2.941-7.383-6.145-14.502-10.626-20.343c-1.374,2.208,0.653,4.558,1.645,6.343
			c3.313,5.969,7.004,12.155,8.926,19.263c-1.788-1.397-2.917-3.382-3.993-5.639c-0.984-2.063-1.507-5.207-3.994-5.872
			c0.52,5.886,3.461,11.222,7.294,14.632c0.14,0.222,0.281,0.443,0.427,0.658c0.002,0.003,0.004,0.006,0.006,0.009
			c-0.142,1.434,1.121,1.448,1.435,1.85c-0.333,4.726,0.734,9.277,0.47,13.155c-3.038,0.59-9.921-3.327-10.571-0.235
			c-0.574,2.73,5.677,3.328,8.625,3.931c0,0,0,0.001,0.001,0.001c0.1,0.103,0.194,0.21,0.274,0.331
			c0.336,0.498,0.56,1.109,0.957,1.548c-0.398-0.437-0.621-1.05-0.957-1.548c-0.183,2.19,1.585,2.034,2.61,2.55
			c1.332,5.976,2.218,11.403,3.759,17.853c1.011,4.233,0.779,8.841,1.879,13.155c0.348,1.365,1.255,2.597,1.409,3.993
			c0.598,5.411,0.147,12.282,0.94,19.263c0.724,6.378,1.655,13.048,1.644,18.793c-0.008,4.41-1.167,7.419-1.409,11.041
			c-0.314,4.69,0.235,9.627-0.47,13.859c-0.554,3.331-1.82,6.565-2.349,9.866C147.402,591.521,147.056,595.383,146.114,598.399z
			 M125.951,556.275c0.601,0.902,1.25,1.757,1.917,2.652C127.201,558.033,126.552,557.177,125.951,556.275z M140.059,449.367
			c-1.945-3.876-3.663-7.979-5.62-11.842C136.399,441.387,138.114,445.491,140.059,449.367z M137.362,753.072
			c0.428,0.155,0.817,0.337,1.131,0.567C138.18,753.409,137.79,753.228,137.362,753.072z M138.362,751.091
			c-2.076-0.963-3.135-3.371-4.933-4.463c2.83-5.392,5.418-11.025,7.517-17.148c3.221-3.082,10.803-5.857,10.806-12.216
			c0.001-2.215-1.82-3.793-2.819-6.342c-0.987-2.521-2.115-7.137-2.114-10.102c0.007-16.59,0.881-34.961,3.289-50.74
			c0.909-0.338,2.432,1.437,3.524,2.349c4.008,3.35,10.375,7.244,15.739,10.336c2.811,1.62,4.769,3.417,8.222,3.289
			c16.638,11.138,32.238,23.313,49.732,33.595c-6.73-4.715-13.764-9.084-21.04-13.856c7.277,4.772,14.31,9.142,21.041,13.856
			c0,0,0,0-0.001,0c0.108,0.076,0.216,0.151,0.325,0.227c0.148,0.104,0.299,0.206,0.447,0.311
			c-0.451,8.608,0.677,17.583,1.177,26.005c-19.321,1.759-41.821,2.058-61.546,3.523c-2.508,0.187-5.691-0.285-7.282,1.88
			c0.874,1.282,2.858,0.722,3.993,0.704c20.873-0.319,44.768-2.463,64.835-3.288c-0.208,7.535,2.439,19.434,1.644,25.84
			c-0.133,1.077-1.659,2.655-0.041,2.813c0.001,0.003,0.001,0.006,0.003,0.009c-18.459,1.728-38.681,1.54-56.576-0.708
			c-0.393-0.39-0.187-1.379-0.235-2.114c-8.569,0.177-19.195-2.36-28.189-3.054C143.008,751.574,140.304,751.991,138.362,751.091z
			 M231.151,765.421c-30.395,0.013-61.232,0.468-89.266-1.88c0.146-2.751-0.299-6.094,0.235-8.456
			c0.712-0.048,1.73,0.303,2.349,0.47c6.816,1.836,13.379,2.228,20.907,2.584c6.839,0.323,13.851,1.524,20.907,2.349
			c14.638,1.71,29.897,1.065,44.633,0.47C231.42,762.02,231.027,763.979,231.151,765.421z M234.206,765.655
			c-0.24-17.132-1.896-36.654-3.054-54.733c-0.163-2.545-0.771-6.189-0.235-7.987c0.371-1.244,1.32-0.813,1.645-2.114
			c-0.743-2.237-3.083-3.541-4.933-4.698c-5.738-3.588-11.521-7.313-17.383-11.041c-7.663-4.872-14.476-10.742-21.847-15.974
			c-7.496-5.32-15.725-9.299-22.786-15.034c-7.082-5.753-12.345-12.308-17.854-19.968c-0.185-9.586,0.059-17.318,0.94-25.135
			c1.316-11.686,6.42-20.786,5.403-35.236c0.805-0.365,0.899,1.004,1.174,1.409c1.517,2.228,4.971,6.356,9.162,3.759
			c2.689-1.668,3.374-7.875,3.524-12.216c0.173-5.005-0.069-11.477-3.524-11.98c-4.358-0.636-5.958,3.936-8.457,4.934
			c0.018-6.951,1.243-12.695,1.409-19.498c8.312-5.579,22.31-4.023,33.592-6.813c7.379-1.823,14.412-8.095,20.202-12.215
			c13.77-9.8,25.602-20.11,41.344-28.425c5.085-2.686,9.88-5.855,14.799-8.691c5.055-2.913,10.329-5.2,15.034-8.222
			c9.184-5.897,17.179-13.89,24.901-21.611c7.99-7.991,15.645-16.119,20.437-26.311c4.854-10.321,8.646-21.513,13.625-32.887
			c2.322-5.305,5.269-10.386,7.518-15.739c6.974-16.604,10.158-35.03,22.081-47.687c-1.232-2.687-1.059-6.542-2.114-9.866
			c1.704,0.531,7.188,1.997,7.048,3.523c-0.018,0.191-0.509,0.414-0.47,0.705c-0.983,2.928-3.688,5.168-5.403,7.517
			c-4.457,6.102-6.832,13.342-9.631,21.377c-3.521,10.106-7.146,19.891-11.746,29.599c-6.74,14.223-13.448,27.96-18.793,43.224
			c-0.878,2.507-1.853,5.103-2.584,7.752c-0.724,2.625-0.894,5.638-1.879,8.222c-0.854,2.24-4.536,4.771-6.813,7.047
			c-6.833,6.834-14.211,13.522-22.081,18.793c-11.507,7.705-23.834,12.691-35.002,19.498c-5.472,3.335-10.885,7.58-16.679,10.806
			c-5.868,3.267-11.813,6.716-16.444,10.806c-9.807,8.659-19.681,16.614-30.773,23.726c-2.659,0.572-5.764,1.266-9.162,1.88
			c-3.228,0.583-7.02,0.641-9.162,1.879c-1.744,1.008-1.839,2.742-3.054,4.229c-0.065,9.763-3.611,18.417-2.819,27.484
			c7.446,1.155,15.779,0.003,23.726,0.47c7.394,0.435,17.112,0.434,22.316-2.349c2.885-1.542,6.35-5.646,9.161-8.457
			c3.024-3.024,6.05-6.113,8.927-8.457c9.826-8.003,17.786-16.867,26.78-25.37c3.047-2.88,6.589-4.892,9.866-7.517
			c6.524-5.229,14.091-8.582,21.612-13.155c1.052-0.828,1.07,0.692,1.645,0.234c16.908-10.497,35.617-19.193,55.674-26.544
			c3.574,3.222,6.31,7.86,10.336,10.806c1.716,1.255,4.509,1.863,5.402,3.759c-4.291,2.03-6.105-2.271-8.456-4.464
			c-2.6-2.425-5.366-4.346-8.223-6.107c-16.051,6.71-31.873,13.578-46.747,21.847c-2.804,1.559-4.921,3.302-8.456,3.993
			c-31.533,15.529-54.65,39.47-78.46,62.722c-13.524,2.449-30.934,1.013-45.807,2.113c-3.459,16.619-3.289,36.918-3.289,57.084
			c0,2.986-0.101,6.428-0.47,9.631c-0.327,2.843-1.679,6.813-1.174,8.927c0.587,2.464,5.972,4.289,8.691,5.873
			c9.129,5.315,17.723,11.362,26.31,16.679c12.25,7.583,23.481,14.725,33.592,23.726c0.485,24.102,1.189,47.984,3.289,70.473
			C237.808,765.655,236.006,765.655,234.206,765.655z M158.799,562.224c0.312-1.663,2.71-4.473,5.168-3.994
			c1.021,0.389,0.366,2.453,0.939,3.289c-0.073,2.31,0.143,4.048,0,5.873c-0.198,2.855-0.593,5.515-1.409,7.752
			c-0.592,0.426-0.583,1.453-1.175,1.879c-2.733-0.568-5.396-3.67-4.933-5.872C160.203,571.565,163.64,562.384,158.799,562.224z
			 M158.33,566.217c-0.274,0.039-0.255,0.371-0.235,0.705c-0.496,0.312-0.694-0.116-1.41,0.234
			c-0.053-0.444,0.265-0.519,0.235-0.939c0.036-0.747,0.533-1.033,0.47-1.879c0.183-0.052,0.273-0.197,0.235-0.471
			C158.907,563.866,158.529,565.305,158.33,566.217z M236.79,506.314c-0.9,0.551-1.77,1.138-2.622,1.747
			C235.021,507.454,235.89,506.865,236.79,506.314z M229.017,512.162c-2.321,2.015-4.555,4.121-6.818,6.162
			C224.46,516.281,226.696,514.177,229.017,512.162z M415.556,493.63c-1.053,2.472-2.022,7.272,0.235,9.161
			c-0.325,0.145-0.035,0.904-0.47,0.939c-0.129,0.342,0.274,1.214-0.235,1.175c0,0.078,0,0.156,0,0.234
			c-0.125,0.894-0.353,1.684-0.704,2.35c0,0.078,0,0.156,0,0.235c-0.151,0.866-0.241,1.794-0.705,2.349
			c-0.179,1.623-0.747,2.854-1.175,4.229c0,0.234,0,0.47,0,0.704c-0.859,0.075-0.607-1.109-1.645-1.174
			c-1.158-0.072-1.772,1.365-2.584,2.114c-1.434,1.542-2.665,3.285-4.463,4.463c-1.252-1.019-2.549-1.992-3.759-3.054
			c1.117-2.016,3.151-3.113,4.464-4.934c-1.664,1.469-3.23,3.034-4.698,4.698c-0.965-0.914-2.517-1.241-3.054-2.584
			c2.878-1.429,6.911-1.701,8.456-4.463c-1.618-1.491-3.509,0.477-5.168,1.175c-1.937,0.814-4.012,1.477-5.872,2.114
			c-1.746-1.309-3.889-2.22-4.934-4.229c3.94-1.619,9.735-1.384,12.92-3.759c-0.819-2.724-4.766-1.171-7.282-0.705
			c-2.841,0.527-5.906,1.291-7.986,1.88c-1.849-1.319-3.427-2.907-4.194-5.309c0.288-0.084,0.584-0.16,0.874-0.243
			c0.166-0.045,0.331-0.092,0.496-0.138c0.002-0.001,0.004-0.001,0.006-0.002C394.831,498.708,405.404,496.381,415.556,493.63z
			 M385.322,500.527c7.09-1.9,14.697-3.283,21.947-5.022C400.023,497.248,392.413,498.628,385.322,500.527z M420.489,498.093
			c0.743-0.979,0.959-2.486,1.409-3.759c-0.018-0.409,0.053-0.73,0.235-0.939c0.144-0.014,0.278-0.035,0.234-0.234
			c1.833-0.03,2.196,3.439,3.994,4.228c0.322-0.535-0.605-0.883,0-0.939c7.975,8.781,15.358,18.155,26.075,24.196
			c1.034,0.688,2.279,1.165,3.523,1.645c2.333,0.799,3.658,2.605,6.107,3.288c0.447,0.101,0.59,0.506,1.175,0.47
			c0.078,0,0.156,0,0.234,0c0.54,1.387-1.306,2.249-0.939,4.229c-0.002,0.624,0.089,1.342-0.235,1.645
			c-0.72,0.688,0.354,3.172-0.704,3.523c-0.078,0-0.156,0-0.235,0c-4.941,5.081-8.019,12.027-11.745,18.323c0,0.078,0,0.156,0,0.234
			c-1.422,1.867-2.386,4.192-3.523,6.343c0,0.078,0,0.157,0,0.235c-6.271,10.402-15.202,19.025-23.798,27.783
			c0.024-0.02,0.046-0.044,0.071-0.063c-0.025,0.021-0.047,0.044-0.072,0.064l0.001-0.001c-0.008,0.006-0.014,0.014-0.021,0.02
			c-0.92,0.743-1.754,1.573-2.491,2.501c-0.029,0.059-0.066,0.109-0.099,0.165c-1.074,1.112-2.142,2.23-3.189,3.358
			c-3.408,3.668-7.444,6.61-10.807,10.101c-1.745,1.813-3.598,4.387-5.872,6.578c-1.925,1.854-5.509,3.816-6.578,5.872
			c-0.462,0.891-0.334,2.01-0.704,2.819c-1.049,2.292-2.906,3.121-4.698,4.933c-0.078,0-0.157,0-0.235,0
			c-0.338-0.125-0.573,0.916-0.704,0.235c0-1.175,0-2.349,0-3.523c0-0.078,0-0.157,0-0.235c0.542-0.788,0.082-2.58,0.234-3.759
			c0-0.078,0-0.156,0-0.234c0.536-0.326,0.088-1.635,0.235-2.35c0-0.313,0-0.626,0-0.939c0.543-0.788,0.082-2.58,0.234-3.759
			c0-0.078,0-0.156,0-0.234c0.542-0.711,0.083-2.423,0.235-3.523c0-0.392,0-0.783,0-1.175c0.591-4.264,1.32-8.39,2.349-12.216
			c0.414-0.683,0.535-1.657,0.705-2.584c1.033-3.116,1.679-6.621,2.818-9.631c1.186-2.809,2.065-5.922,3.055-8.927
			c-0.094-1.111,0.964-1.071,0.704-2.349c1.063-1.912,1.579-4.372,2.35-6.578c0.467-1.49,0.763-3.152,1.409-4.463
			c0.466-0.788,0.772-1.733,0.939-2.819c0.616-2.124,1.101-4.381,1.88-6.343c0.463-0.554,0.553-1.482,0.704-2.349
			c0-0.078,0-0.156,0-0.235c0.5-1.301,0.836-2.766,1.41-3.993c0.675-1.831,1.401-3.609,2.113-5.402
			c0.557-0.619,0.773-1.576,0.94-2.585c-0.021-1.586,0.985-2.146,0.939-3.758c1.007-2.282,1.969-4.608,2.584-7.282
			c0-0.078,0-0.157,0-0.235c1.009-2.437,1.869-5.021,2.584-7.752c0.77-1.736,0.82-4.191,1.645-5.873
			c0.464-0.554,0.553-1.482,0.704-2.349c0.179-1.623,0.748-2.854,1.175-4.229c0.466-0.787,0.772-1.733,0.939-2.818
			c-0.018-0.409,0.053-0.731,0.235-0.94C420.547,499.403,420.025,498.256,420.489,498.093z M426.403,495.92
			c-0.61-0.587-1.238-1.158-1.875-1.72c0.639,0.56,1.265,1.134,1.877,1.721C426.404,495.921,426.404,495.921,426.403,495.92z
			 M427.302,496.918c-0.604-0.238-0.789-0.896-1.375-1.151C426.513,496.021,426.697,496.68,427.302,496.918z M429.496,499.07
			c1.79,1.922,3.515,3.908,5.252,5.882C433.01,502.979,431.286,500.992,429.496,499.07z M429.604,581.521
			c-0.757,0.723-1.522,1.438-2.293,2.151c0,0,0,0,0.001-0.001C428.081,582.959,428.847,582.243,429.604,581.521z M420.262,590.457
			c-0.004,0.003-0.007,0.006-0.01,0.009c0.001-0.001,0.002-0.002,0.003-0.003C420.257,590.461,420.259,590.459,420.262,590.457z
			 M419.68,591.057c-0.457,0.785-1.173,1.311-1.723,2.004C418.505,592.366,419.223,591.842,419.68,591.057z M405.273,544.328
			c0-0.001,0-0.002,0-0.002c0.002-0.005,0.005-0.009,0.006-0.014C405.277,544.317,405.275,544.322,405.273,544.328z
			 M412.032,516.181c-0.418,0.679-0.423,1.771-0.939,2.35c0,0.078,0,0.156,0,0.234c-0.414,1.7-0.839,3.39-1.645,4.698
			c0,0.078,0,0.157,0,0.235c-0.104,0.6-0.329,1.08-0.704,1.409c-0.014-0.144-0.035-0.278-0.235-0.234
			c-0.685-0.882-1.468-1.665-2.349-2.35c0-0.078,0-0.156,0-0.234C408.452,520.743,410.262,517.466,412.032,516.181z M407.63,520.632
			c0.926-0.912,2.079-2.019,2.899-3.094C409.709,518.614,408.556,519.72,407.63,520.632z M410.857,514.066
			c0.104,0.067,0.192,0.14,0.272,0.214c-0.001,0-0.001,0-0.001,0C411.05,514.205,410.961,514.134,410.857,514.066z M411.321,514.498
			c0.38,0.527,0.29,1.192-0.07,1.916C411.61,515.69,411.701,515.025,411.321,514.498z M407.804,526.986
			c0.441,0.448-0.139,0.426,0,1.175c-0.464-0.162-0.626-0.626-0.704-1.175c-1.02-0.859-1.665-2.094-2.819-2.818
			C405.488,523.302,406.81,526.103,407.804,526.986z M412.962,475.089c1.013,1.382,2.002,2.759,3.031,4.058
			C414.964,477.848,413.975,476.473,412.962,475.089z M400.052,520.879c-2.859-1.447-5.862-2.751-8.222-4.698
			c0.429-0.354,1.316-0.249,1.645-0.704c1.298,0.471,2.654,1.531,3.993,2.349C398.559,518.491,400.831,519.063,400.052,520.879z
			 M384.502,516.138c0.084,0.033,0.168,0.065,0.252,0.093c-0.002,0-0.003,0-0.005-0.001c0.169,0.062,0.33,0.131,0.504,0.187
			c-8.267,2.348-17.768,9.149-27.72,9.866c-4.88,0.352-10.115-0.882-14.564-1.88c-4.328-0.97-8.924-2.076-11.745-4.698
			c2.478-2.695,6.922-2.361,10.806-2.818c11.382-1.34,25.011-2.862,35.941-4.934c1.932,1.454,3.768,3.004,6.141,4.017
			c0.127,0.063,0.255,0.114,0.383,0.166c0.003,0.001,0.006,0.003,0.01,0.004C384.503,516.139,384.503,516.138,384.502,516.138z
			 M381.964,511.248c0.781-1.177,3.108-0.807,4.229-1.645c1.787,1.58,3.415,3.319,5.403,4.698
			C388.55,516.415,384.392,512.747,381.964,511.248z M384.078,507.489c-1.155,0.333-2.449,0.526-3.523,0.939
			c-2.04-1.718-3.795-3.723-5.873-5.402c1.455-0.738,3.332-1.053,5.403-1.175C381.139,504.008,382.724,505.633,384.078,507.489z
			 M367.869,495.979c5.146-2.117,7.17,2.545,10.102,4.698C373.042,503.358,369.995,499.061,367.869,495.979z M173.303,650.157
			c3.108,1.983,6.368,3.729,9.604,5.6C179.669,653.89,176.411,652.142,173.303,650.157z M260.107,765.861
			c39.589-0.018,80.463,0.231,118.188-0.163c-0.689-0.028-1.328-0.116-1.734-0.277c-0.156,0-0.313,0-0.47,0
			c-21.736,0.346-47.026,0.424-69.533,0c13.37-0.523,27.223-4.142,39.465-6.343c4.54-0.816,9.23-2.258,13.39-2.114
			c3.387,0.116,4.307,1.974,6.813,2.114c0.632-3.696-3.559-4.598-6.813-4.698c-6.086-0.189-12.872,1.631-19.262,2.819
			c-12.385,2.302-25.231,4.751-39.23,5.402c-5.429,0.253-11.112-0.496-16.209-0.47c-1.258,0.007-3.82-0.684-4.228,1.409
			c1.222,2.302,6.214,0.834,8.457,2.114c-15.426-0.156-31.478,0.313-46.512-0.234c-2.354-22.076-3.118-46.996-3.289-70.474
			c-1.166-2.114-2.794-3.765-4.699-5.285c-0.164-0.138-0.333-0.274-0.502-0.411c-0.002-0.002-0.004-0.003-0.006-0.005
			c-0.001-0.001-0.003-0.003-0.005-0.004c-1.664-1.335-3.565-2.584-5.518-3.808c-7.948-5.354-15.769-11.34-23.566-16.092
			c-8.479-5.168-16.984-11.146-25.84-16.444c-1.604-0.96-4.174-1.922-6.114-3c0,0,0,0-0.001,0c0,0,0-0.001-0.001-0.001
			c0-0.001-0.001-0.001-0.001-0.001s-0.001,0-0.001-0.001h0c-0.572-0.37-1.137-0.756-1.696-1.147
			c0.002,0.002,0.005,0.004,0.007,0.006c-0.374-0.262-0.752-0.516-1.119-0.788c0.365,0.271,0.741,0.522,1.112,0.782
			c-0.449-0.398-0.767-0.813-0.877-1.252c-0.069-0.273-0.094-0.606-0.1-0.963c0.03-0.309,0.059-0.618,0.087-0.928
			c0.056-0.567,0.113-1.134,0.162-1.709c0,0.001,0,0.002,0,0.003c0-0.004,0.001-0.007,0.001-0.01c0,0.002,0,0.005,0,0.007
			c0.262-1.64,0.647-3.427,0.79-4.857c0.318-3.194,0.374-6.585,0.47-9.162c0.739-19.738-0.012-38.254,3.054-54.733
			c15.58,0.486,32.167,0.527,45.808-1.645c9.645-10.276,20.004-20.798,30.773-29.834c3.631-3.047,7.226-6.538,11.041-9.161
			c1.196-0.823,2.296-1.635,3.36-2.445c0.292-0.188,0.583-0.381,0.868-0.608c0.897-0.716,1.804-1.439,2.714-2.164
			c0.159-0.127,0.317-0.253,0.477-0.38c0.718-0.558,1.449-1.118,2.212-1.685c7.16-5.317,14.67-10.587,23.491-13.39
			c17.301-10.811,36.322-19.9,56.613-27.72c3.586,1.925,6.162,4.987,8.927,7.752c2.819,2.819,5.685,5.592,8.223,8.691
			c-3.257,0.774-7.346,0.715-10.279,1.812c-0.001,0-0.001,0-0.002,0c-0.175,0.022-0.35,0.046-0.525,0.068
			c0.052,0.031,0.105,0.05,0.157,0.078c-0.128,0.055-0.269,0.097-0.392,0.156c1.563,1.381,4.274,0.538,6.343,0.235
			c2.265-0.331,4.504-0.798,6.342-1.175c1.663,1.783,4.77,3.373,5.403,5.403c-12.42,2.34-26.557,3.175-39.7,5.168
			c-3.879,0.588-9.667,1.785-8.691,6.343c0.447,2.091,3.713,3.062,5.403,3.758c6.441,2.654,15.148,5.04,23.49,4.698
			c8.219-0.336,16.505-5.557,23.257-8.456c2.253-0.968,6.069-2.933,7.986-2.819c1.935,0.114,6.204,3.304,7.282,3.759
			c0.264,0.023,0.366-0.342,0.47,0c0.888-0.104,1.104,0.463,1.645,0.705c0.46,0.232,0.982,0.403,1.162,0.917
			c-0.015,0.027-0.029,0.054-0.044,0.08c-0.003,0.006-0.007,0.012-0.01,0.018c0,0.001-0.001,0.001-0.001,0.002
			c-0.44,0.452-0.725,1.059-1.107,1.567c-0.849,1.029-1.789,1.969-2.818,2.818c-4.106,2.863-8.527,5.411-12.92,7.987
			c-0.832,0.499-1.885,0.777-2.585,1.409c-0.757-0.115-1.388,1.097-1.879,0.47c-2.604,2.503-9.343,2.86-12.215,5.873
			c10.728,0.688,19.089-6.269,26.31-9.866c0.144-0.014,0.278-0.035,0.235-0.235c0.428-0.668,1.29-0.901,1.879-1.409
			c0.739-0.356,1.146-1.047,2.114-1.175c0.053,0.183,0.006,0.464,0.234,0.47c0.053,0.44,0.606,1.557,0,1.88
			c-0.062,0.956-0.441,1.594-0.704,2.349c-0.77,2.363-1.466,4.799-2.35,7.048c-0.412,2.093-0.845,4.166-1.645,5.872
			c-0.794,2.416-1.693,4.728-3.288,6.343c0,0.078,0,0.156,0,0.235c-1.01,1.574-2.453,2.715-3.289,4.463
			c-0.933,0.712-1.277,2.012-1.879,3.054c-0.882,1.468-1.248,3.451-2.114,4.934c0.023,0.729-0.287,1.122-0.47,1.645
			c-0.099,1.39-0.842,2.134-0.94,3.523c-0.388-0.004-0.131-0.651-0.47-0.705c-0.643,1.002-1.706,1.583-2.349,2.584
			c0.881-0.377,1.664-2.086,2.349-1.879c0,0.156,0,0.313,0,0.47c-1.03,3.198-1.73,6.726-2.818,9.866
			c-0.074,0.317-0.335,0.448-0.235,0.939c0.221,1.555-0.495,4.201-0.939,5.873c-0.109,2.631-0.83,4.651-0.939,7.282
			c0.075,0.545-0.038,0.901-0.235,1.174c0,0.549,0,1.097,0,1.645c0,0.235,0,0.47,0,0.705c-0.078,0-0.156,0-0.234,0
			c0,0.234,0,0.47,0,0.705c-0.151,0.945,0.306,2.498-0.235,3.054c0,0.234,0,0.47,0,0.704c0,0.627,0,1.253,0,1.88
			c-0.143,0.562,0.295,1.704-0.234,1.879c0,0.157,0,0.313,0,0.47c-0.15,0.868,0.304,2.34-0.235,2.819c0,0.156,0,0.313,0,0.47
			c-0.148,0.791,0.303,2.182-0.234,2.584c-0.077,0.863,0.2,2.08-0.235,2.584c-0.004,0.633-0.03,1.244-0.087,1.826
			c0-0.002,0.001-0.005,0.001-0.008c-0.009,0.08-0.013,0.153-0.021,0.231c-0.07,0.62-0.184,1.198-0.363,1.709
			c0,0.47,0,0.939,0,1.409c0.379,0.493,1.198,1.764,2.114,0.939c0.981,0.121,0.645-1.078,1.175-1.409
			c-0.185-1.358,0.341-2.009,0.234-3.289c0.399-0.618,0.023-2.012,0.47-2.584c0.187-2.555,0.304-5.178,0.705-7.517
			c0-0.078,0-0.157,0-0.235c0-0.783,0-1.565,0-2.349c0.078,0,0.156,0,0.234,0c0-0.157,0-0.313,0-0.47c0-1.019,0-2.036,0-3.055
			c0.481-0.771,0.127-2.378,0.235-3.523c0.078,0,0.156,0,0.235,0c0.076-2.037,0.242-3.985,0.704-5.638
			c-0.014-1.031,0.095-1.941,0.47-2.584c0.003-0.624-0.089-1.342,0.235-1.645c0-0.234,0-0.47,0-0.704
			c0.419-1.147,0.399-2.733,0.939-3.759c-0.056-0.996,0.216-1.664,0.47-2.35c0.191-1.218,0.488-2.33,0.939-3.288
			c-0.077-0.861,0.261-1.306,0.47-1.88c0.288-0.965,0.399-2.106,0.94-2.818c-0.024-0.729,0.287-1.123,0.47-1.645
			c0.244-1.321,0.633-2.499,1.174-3.523c-0.018-0.409,0.053-0.73,0.235-0.94c0.28-0.815,0.368-1.824,0.939-2.349
			c0-0.078,0-0.156,0-0.235c0.039-0.665,0.431-0.979,0.47-1.644c0.893-1.223,1.382-2.848,2.35-3.994
			c2.676-2.962,4.941-6.334,6.577-10.336c0.241-1.403,0.791-2.497,1.175-3.759c0.009-1.009,0.307-1.729,0.704-2.349
			c0-0.078,0-0.156,0-0.235c0.188-1.143,0.412-2.251,0.94-3.054c0.156-1.801,0.989-2.926,1.409-4.463
			c0.634-1.549,1.083-4.189,0.235-5.873c-0.123-0.348-0.348-0.592-0.471-0.939c0.144-0.014,0.278-0.035,0.235-0.235
			c0.057-0.335,0.387-0.396,0.47-0.704c1.022-0.936,1.634-2.282,3.289-2.584c1.38,0.969,2.38,2.317,3.054,3.993
			c0.014,0.144,0.035,0.278,0.234,0.235c0.146,0.872,1.158,0.878,0.94,2.113c-0.167,1.009-0.384,1.966-0.94,2.584
			c0.03,2.066-1.548,2.524-2.349,3.759c-0.214,0.961-0.232,2.117-0.705,2.819c0,0.078,0,0.156,0,0.234
			c0.124,0.986-0.41,1.313-0.47,2.114c-0.863,1.173-1.382,2.69-2.114,3.994c-0.823,1.525-1.468,3.229-2.584,4.463
			c0,0.078,0,0.156,0,0.235c-0.941,1.642-1.813,3.355-3.054,4.698c0,0.078,0,0.156,0,0.234c-0.769,2.149-4.392,4.335-2.349,7.048
			c2.483-1.041,3.784-3.263,4.933-5.639c0.539-0.027,0.756-1.568,1.175-0.939c-0.613,0.404-0.249,1.787-0.939,2.114
			c0.023,0.729-0.287,1.122-0.47,1.645c-0.532,1.504-0.82,3.252-1.645,4.463c0.018,0.409-0.053,0.73-0.235,0.94
			c-0.531,1.504-0.819,3.252-1.645,4.463c0,0.078,0,0.156,0,0.235c-0.574,1.774-1.029,3.668-1.879,5.168
			c-0.143,1.188-0.598,2.064-0.939,3.054c-0.843,3.072-1.716,6.114-2.819,8.927c0.075,0.544-0.037,0.901-0.234,1.174
			c-0.616,2.517-1.037,5.228-1.88,7.518c-0.278,3.793-1.58,6.563-2.114,10.101c-0.143,0.014-0.277,0.035-0.234,0.235
			c0.065,0.795,0.373,1.349,0.234,2.349c-0.146,0.638,0.299,1.864-0.234,2.114c0,0.47,0,0.94,0,1.41
			c0.053,0.443-0.265,0.518-0.235,0.939c-0.147,0.714,0.301,2.023-0.234,2.349c0,0.078,0,0.157,0,0.235
			c-0.147,0.714,0.301,2.023-0.235,2.349c0,0.078,0,0.157,0,0.235c0.104,1.357-0.099,2.407-0.234,3.523
			c-0.144,0.562,0.295,1.704-0.235,1.88c0,0.156,0,0.313,0,0.47c0,0.939,0,1.879,0,2.818c-0.444-0.053-0.519,0.265-0.939,0.235
			c-0.078,0-0.156,0-0.235,0c-0.972,0.123-0.961,1.23-1.879,1.409c-0.348,0.272-0.592,0.272-0.939,0
			c-1.616-0.028-1.691-1.598-2.819-2.114c-1.112-1.236-2.312-2.386-3.054-3.993c-1.329-0.105-2.411-2.212-3.759-3.054
			c-0.495-0.366-0.852-0.871-0.704-1.88c-0.639-0.168,0.168,1.108-0.47,0.939c-4.666-4.966-8.384-12.132-10.807-18.558
			c2.033,0.388,4.494,2.786,6.343,1.175c-0.505-3.055-5.32-3.918-7.752-3.523c-2.644-3.19-2.438-8.774-4.229-13.391
			c-0.759-1.958-3.019-6.052-4.933-6.107c-1.13-0.033-4.139,2.222-4.698,2.819c-2.347,2.505-3.167,7.261-4.229,10.806
			c-11.386,3.386-25.927,4.875-40.17,5.638c-4.432,0.237-9.266-0.005-12.518,2.291c0.017-0.006,0.035-0.012,0.052-0.018
			c-0.018,0.007-0.036,0.012-0.053,0.019c0-0.001,0-0.001,0.001-0.001c-0.055,0.021-0.113,0.037-0.167,0.059
			c0.027,0.002,0.054,0.003,0.081,0.004c-0.105,0.077-0.214,0.148-0.316,0.23c18.577,1.348,38.831-0.944,52.385-5.402
			c-0.375,1.348-0.198,3.246-0.234,4.933c-5.172,1.792-10.233,4.246-14.564,7.048c-4.228,2.732-8.769,5.92-10.807,10.806
			c8.37-5.222,15.226-11.958,25.531-15.244c0.052,0.163,0.105,0.329,0.159,0.495c0.022,0.069,0.044,0.138,0.066,0.207
			c0-0.001,0-0.001,0-0.002c0.002,0.006,0.003,0.012,0.006,0.019c-0.002-0.006-0.004-0.012-0.006-0.017
			c0.928,4.601,3.188,9.599,5.486,13.132c0.916,1.409,2.272,2.845,2.35,4.229c0.231,4.152-4.168,6.803-7.518,8.692
			c-4.348-0.056-8.773-2.43-13.624-2.35c-1.532,0.025-3.017,0.495-4.528,1.044c0.001,0,0.003-0.001,0.004-0.001
			c-0.002,0-0.004,0.001-0.006,0.002c0.001,0,0.001-0.001,0.002-0.001c-0.096,0.025-0.19,0.054-0.285,0.08
			c-1.586,0.436-3.16,0.963-4.832,1.316c-0.097,0.021-0.195,0.037-0.293,0.057c-0.333,0.065-0.672,0.121-1.014,0.169
			c-0.125,0.018-0.25,0.035-0.377,0.05c-0.443,0.052-0.894,0.092-1.356,0.104c-0.522,0.013-1.051,0.065-1.582,0.135
			c-0.076,0.011-0.152,0.024-0.229,0.035c-0.496,0.071-0.994,0.16-1.495,0.267h-0.001c-6.37,0.914-12.68,2.881-19.715,2.617
			c-5.919-0.222-12.418-0.712-18.088-1.41c-2.077-0.255-4.17-0.889-5.873-0.704c-7.291,0.789-4.967,10.174-1.41,13.155
			c4.499,3.77,12.695,4.749,20.437,5.638c8.023,0.921,16.184,2.163,23.726,2.349c-6.787,3.9-12.052,9.759-20.672,15.034
			c-3.338,2.043-7.522,3.799-11.98,5.873c-3.397,1.58-10.131,3.689-11.511,6.577c-0.751,1.573-0.389,5.652,0.47,7.282
			c1.348,2.56,4.759,3.101,7.987,4.229c-0.368,3.272-1.783,5.903-0.47,8.927c3.347,7.705,16.147,4.771,24.196,2.584
			c-6.349,3.315-13.099,7.295-19.496,10.779c0.028-0.015,0.056-0.029,0.084-0.044c-0.633,0.336-1.26,0.68-1.888,1.021
			c0.012-0.006,0.023-0.013,0.034-0.019c-0.907,0.485-1.808,0.961-2.695,1.417c-1.115,0.573-2.816,0.676-3.289,2.114
			c14.495-4.463,27.423-12.826,40.169-18.793c2.545-1.191,5.875-2.256,8.927-3.523c2.15-0.894,6.537-4.354,8.457-0.471
			c-2.956,3.434-6.311,6.865-9.818,10.087c0.02-0.018,0.039-0.034,0.059-0.052c-0.021,0.019-0.042,0.037-0.063,0.057
			c0.002-0.002,0.003-0.003,0.005-0.005c-0.094,0.084-0.189,0.166-0.283,0.25c-0.647,0.581-1.281,1.175-1.91,1.771
			c-0.003,0.002-0.006,0.005-0.009,0.008c-0.005,0.005-0.011,0.01-0.016,0.015c-3.159,2.91-6.51,5.979-10.046,7.838
			c-1.126,0.593-2.396,0.737-3.288,1.645c-5.5,5.588,1.671,14.272,8.691,12.45c7.748-2.012,12.887-9.943,18.558-13.39
			c8.941,8.755,24.786,10.607,42.049,11.04c0.235,0,0.47,0,0.705,0c0.556-0.54,2.108-0.084,3.054-0.234
			c0.414-0.212,0.728-0.525,0.939-0.939c-0.031-0.579-0.035-1.132,0.235-1.41c-1.407-1.004-3.964-0.035-6.107,0
			c-1.604,0.027-3.226,0.012-4.849-0.031c0.207,0.01,0.413,0.022,0.62,0.031c-0.219-0.01-0.437-0.022-0.655-0.032
			c0.012,0,0.023,0.001,0.035,0.001c-1.764-0.082-3.521-0.185-5.261-0.315c-0.003,0-0.007-0.001-0.011-0.001
			c-1.733-0.131-3.449-0.29-5.136-0.486c-0.011-0.001-0.021-0.002-0.031-0.004c-9.91-1.31-18.906-4.212-23.943-9.263
			c3.473-2.948,6.868-5.974,10.571-8.691c12.574-2.652,24.685,1.461,36.176,2.818c0.905,4.342,1.34,9.153,1.879,13.86
			c0.281,0.658,0.259,1.62,0.235,2.584c0.395,1.328,0.554,2.892,0.705,4.463c0.294,0.424,0.809,1.836,0.234,2.349
			c0.104,1.045-0.636,1.243-0.939,1.88c-0.504,0.905-0.892,1.928-1.409,2.818c0.074,0.545-0.038,0.902-0.235,1.175
			c-0.311,2.347,0.519,3.554,1.409,4.698c0.073,0.554,0.816,0.438,0.705,1.175c0.72,0.22,0.577,1.302,1.175,1.645
			c0.229,1.024,0.814,1.691,1.175,2.584c1.07,1.356,1.535,3.319,1.409,5.872c0.078,0,0.156,0,0.234,0c0,1.097,0,2.193,0,3.289
			c0.231,0.943,0.288,2.062,0.235,3.289c0.541,0.633,0.083,2.266,0.235,3.288c0.41,0.217,0.518,0.734,0.47,1.41
			c-0.536-0.323-0.884,0.605-0.94,0c-0.392,0.132-0.995,0.223-1.65,0.264c0.939-0.01,1.89-0.019,2.825-0.029
			C343.575,766.114,301.146,765.844,260.107,765.861z M397.37,525.128c-0.121,0.16-0.232,0.325-0.359,0.481
			c0-0.001,0.001-0.003,0.002-0.004C397.139,525.45,397.25,525.287,397.37,525.128z M383.166,535.249
			c4.514-2.409,9.475-4.986,12.882-8.549C392.642,530.263,387.682,532.841,383.166,535.249z M394.981,530.689
			c-0.003,0.001-0.005,0.002-0.008,0.002c0.001,0,0.002-0.001,0.003-0.001C394.979,530.689,394.979,530.689,394.981,530.689z
			 M396.025,526.718c0.01-0.009,0.021-0.018,0.031-0.026c-0.002,0.002-0.004,0.003-0.005,0.005
			C396.043,526.704,396.033,526.71,396.025,526.718z M349.503,593.747L349.503,593.747c-0.001,0.002-0.001,0.003-0.002,0.004
			C349.502,593.75,349.502,593.748,349.503,593.747z M349.453,593.153c0,0.001,0,0.002,0,0.003
			c-0.003-0.008-0.006-0.016-0.009-0.023C349.447,593.14,349.45,593.146,349.453,593.153z M275.315,635.796
			c-0.334,0.386-0.396,1.164-0.525,1.991C274.918,636.957,274.98,636.182,275.315,635.796z M305.908,695.886
			c-0.213,0.097-0.43,0.188-0.641,0.288c0.003-0.002,0.006-0.003,0.009-0.005c0.004-0.002,0.007-0.003,0.011-0.005
			c0.613-0.316,1.269-0.591,1.869-0.92c-0.599,0.331-1.256,0.604-1.869,0.92C305.492,696.068,305.702,695.979,305.908,695.886z
			 M391.125,755.319c0.466,0.944,0.52,2.3,0.705,3.523c0.304,0.949,0.502,2.004,0.47,3.289c0.441,0.576,0.199,1.837,0.705,2.349
			c0,0.079,0,0.157,0,0.235c0.343,0.205,0.909,0.187,0.704,0.939c-2.662,0-5.324,0-7.986,0c-0.63-0.779-1.001-1.817-0.939-3.288
			c-0.317-1.171-0.47-2.507-0.47-3.994c0-0.234,0-0.47,0-0.704c-0.541-0.557-0.085-2.108-0.235-3.054
			c-0.04-0.352,0.084-0.867-0.235-0.94c0.176-1.506-0.273-2.388-0.234-3.759c-0.144-0.013-0.278-0.034-0.235-0.234
			c-0.479-1.869-1.512-3.186-2.349-4.698c-0.146-0.871-0.66-1.376-0.939-2.114c-0.003-0.467-0.642-0.299-0.47-0.939
			c-0.939-0.549-1.365-1.61-1.88-2.584c0.078,0,0.157,0,0.235,0c-0.102-0.963,0.218-1.505,0.47-2.114
			c0.393-1.409,1.234-2.367,2.114-3.289c0.445-1.367,0.545-2.622,0.234-4.229c-0.184-0.242-0.297-0.554-0.371-0.905
			c-0.021-0.142-0.039-0.28-0.062-0.423c-0.034-0.318-0.049-0.656-0.037-1.021c-0.351-1.059-0.528-2.291-0.47-3.759
			c-0.545-0.485-0.225-2.091,0.235-2.349c-0.049-0.031-0.194,0.039-0.235,0c-1.45-1.392-0.916-8.626-1.409-11.041
			c2.596,0.457,4.855,1.252,7.987,1.175c0.057,1.273,0.376,2.285,0.234,3.758c-0.725,0.528-1.352,1.154-1.879,1.88
			c0.725-0.224,1.352-1.769,1.879-1.41c0,0.705,0,1.41,0,2.114c0,0.313,0,0.627,0,0.94c0.078,0,0.156,0,0.235,0
			c0,0.939,0,1.879,0,2.818c0,0.235,0,0.47,0,0.705c0.541,0.633,0.083,2.266,0.234,3.289c0,0.234,0,0.47,0,0.704
			c0.543,0.788,0.082,2.58,0.235,3.759c0.462,0.556-0.065,2.102,0.47,2.584c0,0.939,0,1.879,0,2.819c0.078,0,0.156,0,0.234,0
			c0,0.548,0,1.096,0,1.644c-0.04,0.352,0.084,0.867-0.234,0.94c-0.906,2.025-1.865,4.321-1.175,7.282
			c0.656,1.066,0.998,2.447,1.879,3.288c0.394,0.781,1.078,1.271,1.41,2.114C390.618,751.912,391.007,753.48,391.125,755.319z
			 M400.633,694.453c-1.551,1.186-2.907,2.564-4.428,3.778c1.52-1.216,2.878-2.595,4.43-3.78
			C400.634,694.452,400.634,694.452,400.633,694.453z M400.879,693.67c-1.879,2.346-4.493,3.957-6.699,5.976
			c-6.396,2.217-15.228,2-22.787,3.054c-0.714,0.147-2.023-0.301-2.349,0.235c-0.313,0-0.626,0-0.939,0c-1.723,0-3.445,0-5.168,0
			c-1.348,0.107-1.783-0.335-2.819,0c-1.645,0-3.289,0-4.933,0c-2.205-0.066-3.928-0.614-6.343-0.47
			c-0.636-0.391-2.888-0.391-3.523,0c-2.679,0.176-3.801,0.366-5.313-0.302c0.001,0.001,0.001,0.001,0.002,0.002
			c-0.092-0.077-0.201-0.137-0.34-0.166c-0.15-0.076-0.297-0.146-0.458-0.239c-11.413,7.224-19.382,17.89-31.243,24.666
			c-1.316,0.396-3.186,0.721-4.697,0.234c-1.294-1.056-2.292-2.406-3.055-3.993c-0.114-0.741,0.424-0.829,0.235-1.645
			c1.998-1.604,4.084-3.12,6.577-4.229c0.061,1.507,1.243,1.89,1.645,3.054c-0.073,2.042-1.683,2.546-2.584,3.759
			c-0.715-0.011-0.913,0.496-1.409,0.705c-0.99,0.125-0.983,2.253,0.234,2.114c0.079,0,0.157,0,0.235,0
			c2.881-0.8,4.7-2.661,5.873-5.168c0.646-2.399-0.86-4.601-2.584-5.403c0.745-1.317,4.198-4.137,6.107-5.873
			c6.498-5.909,12.731-12.104,18.323-18.088c-0.12-0.072-0.196-0.144-0.254-0.215c0.006-0.007,0.013-0.013,0.019-0.02
			c-0.021-0.007-0.033-0.015-0.052-0.021c-0.629-0.241-0.262-0.523,0.103-0.847c-0.362,0.323-0.731,0.605-0.103,0.847
			c-0.435-0.659,1.5-1.26,0.757-2.093c0.074-0.545-0.038-0.902-0.235-1.175c-0.202-1.129-1.103-1.56-1.879-2.114
			c-0.235,0-0.47,0-0.705,0c-8.486,1.85-14.059,6.614-22.552,8.457c-1.117,0.43-2.28,0.813-3.022,1.618
			c-0.514,0.245-1.025,0.493-1.536,0.744c-0.02,0.01-0.04,0.02-0.061,0.029c0.002-0.001,0.005-0.002,0.007-0.003
			c-0.38,0.187-0.763,0.372-1.149,0.557c0.384-0.184,0.765-0.368,1.143-0.554c-5.829,2.58-14.488,4.134-21.456,4.891
			c-1.735,0.188-2.569,1.413-4.228,0.939c-1.302-0.969-2.349-2.193-3.759-3.054c-0.169-0.927-0.374-1.819-0.705-2.584
			c-0.056-0.963-0.115-1.921-0.47-2.584c-0.096-0.566,0.429-0.511,0.235-1.175c1.57-1.015,4.564-0.604,4.463-3.289
			c0-0.078,0-0.156,0-0.234c-0.089-0.783-1.332-1.63-2.114-0.939c-0.079,0-0.157,0-0.235,0c-1.096,0-2.192,0-3.289,0
			c-1.655-0.146-2.927-0.675-4.229-1.175c-0.627-0.547-1.542-0.808-2.114-1.409c-4.709-5.78,3.936-7.928,7.752-9.632
			c-0.083,1.257,0.417,1.933,0.705,2.819c0,0.392,0,0.782,0,1.174c-0.51,0.979-1.336,1.641-2.114,2.35
			c-0.741-0.115-0.83,0.423-1.645,0.234c-1.28,0.179-1.104,2.126,0,2.35c0.718,0.546,1.991,0.077,2.584-0.235
			c1.761-0.901,3.228-2.097,3.759-4.229c0.55-0.841-0.002-2.249-0.235-3.054c-0.136-1.038-0.83-1.52-1.175-2.349
			c0.144-0.014,0.278-0.035,0.235-0.235c1.489-0.312,2.506-1.096,3.758-1.644c1.909-0.833,3.886-1.596,5.403-2.819
			c0.079,0,0.157,0,0.235,0c0.9-0.509,1.996-0.823,2.584-1.645c0.079,0,0.157,0,0.235,0c4.058-2.441,7.675-5.323,11.041-8.457
			c0.078,0,0.156,0,0.234,0c4.282-3.157,8.914-5.963,13.155-9.161c1.773-0.575,3-1.698,4.463-2.584
			c1.239-0.875,2.483-1.745,3.759-2.584c0.971-0.126,1.849-0.344,2.819-0.47c2.192,0,4.385,0,6.577,0
			c3.21,0.162,8.871-0.14,8.222-2.584c-0.471-1.772-2.849-0.467-4.463-0.235c-3.29,0.156-7.203-0.313-10.102,0.235
			c-5.069-0.278-5.931,3.251-10.007,3.122c-0.321-0.058-0.66-0.097-1.028-0.107c-0.301-0.049-0.611-0.109-0.945-0.196
			c-1.334,0.153-3.284-0.309-4.229,0.235c-3.432,0.013-6.285,0.605-9.396,0.939c-0.791,0.148-2.182-0.303-2.584,0.235
			c-0.234,0-0.47,0-0.704,0c-1.802,0-3.603,0-5.403,0c-8.334-0.983-16.891-1.745-23.491-4.464c-1.522-1.061-2.844-2.324-3.758-3.993
			c0.075-0.545-0.038-0.902-0.235-1.175c-0.174-1.427,0.228-2.277,0.705-3.054c0.404,0.013,0.602-0.181,0.705-0.47
			c3.068-0.249,4.816,0.821,7.752,0.705c0.326,0.613,1.993-0.114,2.349,0.47c0,0.313,0,0.626,0,0.939
			c-0.523,1.199-0.885,2.561-2.819,2.349c-1.101,0.152-2.813-0.307-3.523,0.235c-0.869-0.164-1.038,0.372-0.939,1.175
			c0.054,1.276,1.792,0.869,3.054,0.939c4.181,0.501,6.184-1.177,6.578-4.464c0.509,0.04,0.106-0.833,0.235-1.174
			c0.47,0,0.939,0,1.409,0c2.072,0.198,4.01,0.531,6.343,0.47c0.556,0.54,2.108,0.084,3.054,0.234c0.392,0,0.783,0,1.175,0
			c0.481,0.391,2.572,0.391,3.054,0c0.392,0,0.783,0,1.175,0c1.022-0.151,2.655,0.307,3.288-0.234
			c6.692-0.751,12.795-2.392,19.028-3.523c2.059-0.375,4.087-1.332,6.107-1.645c5.972-0.924,11.306,2.507,17.854,2.349
			c1.615,0.438,1.848-2.534,3.523-2.818c0.078,0,0.156,0,0.234,0c2.665-1.642,4.39-4.224,5.403-7.518
			c0.435-0.925,0.367-3.114-0.235-3.759c-1.278-2.322-2.801-4.402-4.228-6.577c-0.734-2.007-1.668-3.813-2.35-5.873
			c-0.45-1.272-0.724-2.721-1.175-3.993c-1.161-5.59,0.737-10.605,2.114-15.034c1.041-1.622,1.79-3.535,3.054-4.934
			c1.789,0.718,2.546,2.467,3.524,3.994c0,0.078,0,0.156,0,0.234c0.169,0.927,0.291,1.901,0.704,2.584
			c-0.008,1.026,0.204,1.832,0.47,2.584c-0.193,0.664,0.331,0.608,0.235,1.175c-1.646,0.003-1.465-1.824-2.35-2.584
			c0-0.078,0-0.156,0-0.234c-0.184-1.069-0.131-2.375-0.704-3.055c0-0.078,0-0.156,0-0.234c-0.733-2.467-3.465,0.385-2.114,2.114
			c-0.069,1.322,0.27,2.236,0.704,3.054c0.637,1.635,1.493,3.049,3.289,3.523c0.078,0,0.156,0,0.235,0
			c0.507,0.796,1.626-0.481,2.114,0.235c0.164,1.245,0.74,2.078,1.174,3.054c0.339,1.07,0.808,2.012,1.41,2.818
			c-0.011,0.323,0.11,0.517,0.234,0.705c1.539,3.315,3.003,6.707,4.934,9.631c0.311,1.256,1.092,2.041,1.645,3.055
			c2.745,4.659,7.901,8.854,11.745,12.92c1.066,1.128,4.09,4.395,3.289,4.698c-4.765-1.266-9.605-2.454-15.27-2.819
			c0.04-0.51-0.833-0.106-1.175-0.234c-0.385-0.039-0.385,1.447,0,1.409c-0.136,0.763,0.16,1.093,0.235,1.645
			c6.739,0.855,13.09,2.101,18.793,3.993c0.657,0.909-0.199,3.331,0.47,4.229c1.012,2.035,1.02,5.402,1.409,7.752
			c0.559,3.362,1.741,7.033,2.819,10.336c0.488,1.496,1.494,2.686,2.114,4.229c1.987,4.952,2.251,11.092,5.168,14.799
			c0.664,1.211,1.147,2.604,2.108,3.518c0.002,0.003,0.004,0.007,0.006,0.01c0,0.077,0,0.154,0,0.231
			c2.572,3.669,4.861,7.619,8.404,10.316c-0.021-0.021-0.041-0.045-0.063-0.067c0.029,0.029,0.059,0.057,0.087,0.085
			c-0.008-0.006-0.017-0.012-0.024-0.018C400.288,693.104,400.583,693.388,400.879,693.67L400.879,693.67
			c0.079,0.074,0.154,0.152,0.233,0.227C401.035,693.82,400.957,693.744,400.879,693.67z M390.069,679.482
			c0.002,0.003,0.003,0.006,0.004,0.008l-0.001-0.001C390.071,679.487,390.07,679.484,390.069,679.482z M419.08,688.84
			c-1.317,1.25-2.616,2.592-4.229,3.523c-4.768-0.714-8.251-2.711-12.92-3.523c-3.796-3.206-6.562-7.463-9.162-11.98
			c-0.86-1.495-2.071-2.77-2.818-4.229c-2.448-4.774-3.94-11.745-5.638-17.618c-0.547-1.891-1.543-3.523-2.114-5.403
			c-1.597-5.247-1.222-11.369-1.88-17.148c4.522-1.664,7.689-4.682,11.276-7.281c0.088,0,0.175,0,0.263,0
			c0.052,1.128,0.121,2.239,0.202,3.338c0.071,1.01,0.158,2.004,0.252,2.992c0,0.009,0.001,0.019,0.002,0.027
			c0-0.003,0-0.006,0-0.009c0.001,0.013,0.002,0.026,0.004,0.039c-0.002-0.01-0.003-0.021-0.004-0.03
			c1.562,24.347,10.824,40.994,25.591,52.135c2.396-0.282,3.381,0.848,5.168,1.175C421.981,686.405,420.438,687.551,419.08,688.84z
			 M533.951,615.548c0.292-0.338,0.607-0.654,0.911-0.981c0.001-0.001,0.003-0.002,0.004-0.004
			C534.562,614.892,534.244,615.208,533.951,615.548z M537.918,611.579c0-0.001,0.001-0.001,0.002-0.002
			c0.008-0.007,0.016-0.015,0.024-0.022C537.936,611.563,537.927,611.571,537.918,611.579z M569.188,547.424
			c-0.854,1.651-1.647,3.364-2.819,4.698c0,0.078,0,0.156,0,0.235c-0.389,0.785-0.785,1.563-1.174,2.349
			c-0.915,1.436-1.697,3.001-2.35,4.698c-0.483,0.064-0.221,0.875-0.705,0.939c0,0.078,0,0.157,0,0.235
			c-0.735,1.691-1.4,3.453-2.349,4.933c-1.09,3.139-2.412,6.045-3.759,8.927c-0.26,0.914-0.649,1.699-1.175,2.35
			c0,0.078,0,0.156,0,0.234c0.053,0.444-0.265,0.519-0.234,0.939c-0.632,0.7-0.91,1.753-1.409,2.584
			c-0.05,0.264-0.261,0.366-0.47,0.47c0,0.079,0,0.157,0,0.235c-0.903,2.308-1.543,4.879-3.055,6.577
			c0.037,0.585-0.369,0.728-0.47,1.175c-1.123,2.087-2.073,4.348-3.523,6.107c0,0.079,0,0.157,0,0.235
			c-2.103,3.457-4.757,6.362-7.282,9.396c-1.194,0.998-2.291,2.094-3.289,3.289c-0.295,0.051-0.479,0.365-0.47-0.235
			c-2.572,2.928-5.525,5.593-8.651,8.067c0.096-0.071,0.189-0.146,0.285-0.218c-0.105,0.08-0.207,0.162-0.313,0.241
			c0.01-0.008,0.019-0.016,0.028-0.023c-0.125,0.094-0.248,0.189-0.371,0.284c-0.056,0.041-0.11,0.082-0.164,0.124
			c0.008-0.006,0.017-0.012,0.025-0.019c-0.011,0.008-0.021,0.016-0.031,0.023c0.002-0.002,0.004-0.003,0.006-0.005
			c-2.009,1.485-4.021,2.959-6.035,4.4c0.011-0.008,0.022-0.016,0.033-0.023c-0.015,0.011-0.029,0.021-0.044,0.031
			c-1.039,0.744-2.079,1.478-3.119,2.2c1.037-0.721,2.075-1.453,3.111-2.195c-0.713,0.486-1.428,0.969-2.143,1.44
			c-6.483,4.281-13.026,8.467-19.263,13.155c-6.144,4.619-11.817,9.777-18.088,14.095c-3.26,2.244-6.854,3.996-10.336,5.873
			c-4.873,2.626-8.784,6.256-13.625,8.691c-6.991,6.398-12.634,14.146-19.263,20.907c-7.802-0.922-15.265-2.181-19.973-6.195
			c0.151,0.146,0.309,0.29,0.467,0.433c-0.163-0.145-0.322-0.293-0.479-0.443c0.004,0.004,0.008,0.008,0.012,0.011
			c-0.518-0.498-1.005-1.013-1.499-1.517c-0.054-0.056-0.108-0.11-0.162-0.166c-0.512-0.614-0.984-1.267-1.388-1.988
			c-4.661-1.407-7.138-7.172-9.631-11.511c-4.557-7.929-7.151-15.837-8.457-27.015c-0.521-4.465-1.427-13.14,0.234-16.679
			c0.773-1.646,4.224-4.242,6.108-5.873c0.257-0.223,0.488-0.444,0.733-0.666c-0.003,0.002-0.006,0.005-0.01,0.007
			c0.004-0.003,0.008-0.005,0.011-0.008c0.005-0.004,0.009-0.007,0.013-0.011c0.216-0.173,0.424-0.346,0.626-0.521
			c0.04-0.035,0.081-0.069,0.121-0.104c0.216-0.19,0.426-0.382,0.62-0.576c0.159-0.159,0.301-0.339,0.45-0.51
			c1.26-1.234,2.415-2.429,3.543-3.483c4.353-4.072,7.569-8.979,11.275-12.686c7.381-7.381,15.692-14.139,22.316-22.316
			c6.87-8.48,10.726-18.495,16.797-27.771c0.003-0.001,0.006-0.003,0.008-0.004c0.277-0.108,0.539-0.232,0.789-0.368
			c3.667-1.211,4.475-5.282,7.776-6.859c1.469,1.116,3.279,1.89,4.464,3.289c4.529,0.75,8.281,3.223,12.685,4.933
			c4.267,1.657,9.034,2.531,13.86,3.994c6.272,1.901,14.916,3.891,23.021,3.288c2.248-0.167,5.612-0.002,6.107-2.349
			c-3.608-0.809-7.751-0.181-11.511-0.47c-8.115-0.625-15.438-3.222-22.552-5.168c-7.292-1.996-13.84-5.1-19.967-7.987
			c-1.443-2.942-3.821-6.201-3.994-9.631c6.406,1.077,12.141,4.001,19.028,4.463c17.24,1.156,30.635-4.396,45.103-6.577
			c2.043-0.309,5.238-0.692,7.518-1.175c1.663-0.353,5.518-1.346,5.168-3.523c-0.334-2.076-5.444-1.583-7.987-1.41
			c-3.606,0.246-6.588,0.517-9.396,0.705c-13.986,0.938-24.912,0.354-38.29,1.645c-5.848,0.563-14.764,2.063-19.732,0.705
			c-5.03-1.375-10.622-4.899-15.739-7.753c-5.181-2.888-9.343-6.399-13.155-10.336c-3.881-4.008-7.161-8.571-11.04-12.45
			c-2.436-2.435-5.254-4.465-7.909-6.559c0.02,0.016,0.039,0.032,0.059,0.048c-0.054-0.043-0.107-0.085-0.161-0.128
			c-0.479-0.379-0.941-0.766-1.404-1.153c0.288,0.242,0.579,0.475,0.869,0.712c-0.364-0.299-0.73-0.596-1.093-0.903
			c-0.129-0.109-0.258-0.221-0.386-0.331c-0.542-0.468-1.079-0.947-1.609-1.451c0.457,0.434,0.918,0.852,1.384,1.258
			c-0.489-0.429-0.976-0.858-1.438-1.306c0.018,0.017,0.036,0.031,0.054,0.048c-0.021-0.019-0.041-0.037-0.062-0.056
			c-0.07-0.067-0.139-0.139-0.208-0.207c0.054,0.053,0.104,0.11,0.158,0.162c-0.057-0.055-0.11-0.115-0.166-0.17
			c0.002,0.002,0.005,0.005,0.008,0.008c-0.567-0.554-1.109-1.145-1.636-1.759c-0.001-0.001-0.001-0.001-0.001-0.001
			c-0.004-0.005-0.008-0.011-0.012-0.016c-1.66-2.017-3.086-4.387-4.771-6.519c-3.903-4.938-8.497-9.257-13.155-13.625
			c-2.25-2.11-5.417-4.871-6.107-7.282c-1.198-4.184-0.983-10.375-0.47-15.27c1.522-14.52,6.467-26.352,11.041-37.82
			c3.145-7.886,6.729-15.153,10.57-21.847c3.913-6.818,9.032-12.742,12.216-20.437c-3.506,1.199-5.111,5.055-7.048,7.987
			c-6.139,9.295-11.838,19.347-16.443,30.068c-6.247,14.544-11.912,30.193-12.92,49.331c-1.672-8.503,0.478-17.183,1.409-26.075
			c0.302-2.88,0.889-5.67,1.645-10.336c0.513-3.165,1.724-8.846-1.175-9.161c-2.223-0.242-3.188,4.656-3.759,7.517
			c-0.772,3.878-1.283,6.966-1.644,9.866c-1.659,13.341-1.555,31.98,4.933,39.936c2.13,2.611,5.359,4.525,7.752,7.047
			c2.443,2.574,4.745,4.909,6.107,8.457c-11.067-1.071-18.723-6.803-29.129-7.752c3.258,4.246,9.235,5.396,14.564,7.282
			c5.39,1.906,11.447,3.572,17.618,4.229c1.413,3.677,2.77,7.41,4.464,10.806c-1.063,1.677-2.689,2.791-3.054,5.168
			c-5.53,2.227-14.164,4.496-20.907,6.107c-2.555,0.61-5.812,1.86-7.518,1.645c-2.523-0.32-4.841-3.567-6.107-4.934
			c8.789-2.135,18.578-4.725,27.015-7.282c2.646-0.802,6.645-0.965,7.282-4.229c-2.908-1.489-6.191,0.24-9.161,1.175
			c-6.209,1.953-11.897,3.654-18.088,5.403c-0.95,0.268-2.099,0.637-3.291,0.994c-0.101,0.025-0.2,0.051-0.301,0.076
			c-1.154,0.294-2.309,0.586-3.463,0.873c0.005-0.001,0.01-0.003,0.015-0.003c-0.864,0.216-1.729,0.432-2.592,0.644
			c0.857-0.211,1.718-0.426,2.577-0.641c-0.972,0.177-1.869,0.266-2.577,0.171c-1.525-0.205-3.494-2.031-5.168-3.054
			c-4.325-2.645-7.981-6.461-10.336-10.336c-0.482-0.795-0.745-1.863-1.645-2.584c-7.791,1.131-14.68,4.57-21.142,7.517
			c-6.681,3.046-13.241,5.848-19.498,9.161c-12.396,6.566-24.875,12.663-37.351,19.968c-2.589,1.517-5.304,4.047-7.987,6.107
			c-8.047,6.184-15.484,13.84-22.316,20.673c-4.435,4.435-9.632,8.37-15.034,13.39c-2.224,2.066-4.587,4.56-7.282,7.047
			c-0.5,0.462-1.027,1.017-1.566,1.607c-0.261,0.25-0.521,0.491-0.783,0.742c-0.172,0.165-0.346,0.354-0.521,0.551
			c-0.051,0.058-0.102,0.117-0.152,0.177c-0.131,0.152-0.263,0.31-0.395,0.474c-0.052,0.063-0.103,0.127-0.155,0.192
			c-0.146,0.185-0.293,0.371-0.439,0.561c-0.03,0.039-0.06,0.076-0.089,0.114c-1.131,1.21-2.231,2.242-3.182,2.63
			c-2.589,1.056-6.453,0.926-10.101,1.174c-10.658,0.728-22.617-0.579-32.653-0.234c1.081-3.998,1.429-10.289,2.046-16.136
			c-0.001,0.01-0.003,0.02-0.004,0.028c0.142-1.082,0.272-2.177,0.391-3.283c0.233-1.719,0.507-3.35,0.856-4.806
			c0.18-0.749,0.959-2.528,1.409-3.054c1.53-1.784,5.182-1.517,8.692-2.114c2.683-0.456,5.701-0.993,7.752-1.645
			c4.24-1.345,7.038-4.104,10.571-6.577c2.758-1.931,5.614-3.732,8.222-5.638c5.198-3.798,9.661-8.293,14.309-12.476
			c0.827-0.718,1.667-1.42,2.522-2.104c4.324-3.295,9.818-6.088,14.882-8.911c6.063-3.381,11.118-8.175,16.443-11.275
			c5.926-3.451,12.129-6.4,18.088-9.396c17.292-8.694,31.107-20.084,43.904-33.217c-0.007,0.006-0.014,0.012-0.02,0.019
			c0.008-0.008,0.017-0.017,0.024-0.024c-0.001,0.002-0.003,0.004-0.005,0.006c0.004-0.004,0.008-0.008,0.012-0.012
			c0.506-0.482,0.966-0.954,1.349-1.4c0.495-0.515,0.99-1.027,1.482-1.547c-0.125-6.404,2.355-11.242,4.464-17.148
			c4.843-13.568,10.948-26.199,16.679-38.291c3.995-8.431,8.005-17.129,10.806-25.84c2.944-9.158,5.637-18.6,10.336-26.31
			c1.859-3.05,5.816-6.308,7.282-9.162c0.053-0.182,0.007-0.463,0.235-0.47c0-0.078,0-0.157,0-0.235
			c-0.075-0.545,0.037-0.902,0.234-1.174c2.845,1.149,5.196,2.79,8.457,3.523c2.024,0.012,3.613,0.458,5.638,0.47
			c3.635,0.437,7.227,0.917,10.806,1.41c0.538,0.246,1.417,0.149,1.88,0.47c0.714,0.147,2.023-0.301,2.349,0.235
			c0.078,0,0.157,0,0.235,0c2.043,0.385,4.267,0.588,6.107,1.175c2.413,0.249,4.693,0.631,6.577,1.409
			c1.207,0.203,2.3,0.519,3.054,1.175c0.896-0.035,1.132,0.591,1.88,0.705c0.156,0,0.313,0,0.47,0
			c1.617,1.358,4.282,1.668,6.107,2.819c6.174,4.397,11.15,9.992,13.859,17.853c0,0.079,0,0.157,0,0.235
			c0.743,0.588,1.058,1.606,1.41,2.584c0,0.079,0,0.157,0,0.235c0.864,1.406,1.462,3.08,2.349,4.463c0,0.079,0,0.157,0,0.235
			c1.032,0.847,1.382,2.376,2.35,3.289c0.685,0.881,1.468,1.665,2.349,2.349c3.646,3.089,7.123,6.345,10.806,9.396
			c1.334,1.171,2.587,2.424,3.759,3.758c1.622,2.059,3.61,3.75,4.934,6.108c0,0.078,0,0.156,0,0.235
			c0.834,0.575,0.998,1.821,1.409,2.819c0.715,1.791,1.879,3.133,2.584,4.933c1.505,1.94,2.161,4.73,4.229,6.107
			c5.709,1.618,9.289,7.279,14.095,10.571c3.136,3.128,6.421,6.107,9.396,9.396c5.287,5.988,10.343,12.208,14.799,19.028
			c0,0.078,0,0.156,0,0.234c0.725,0.372,0.824,1.369,1.41,1.88c0.494,0.992,1.148,1.826,1.644,2.818c0,0.078,0,0.157,0,0.235
			c0.078,0,0.157,0,0.235,0c0.877,1.629,2.002,3.01,2.818,4.698c0,0.078,0,0.156,0,0.234c0.966,0.914,1.449,2.311,2.114,3.524
			c0.456,0.718,0.964,1.385,1.175,2.349c0.771,0.482,0.897,1.608,1.409,2.349c0,0.079,0,0.157,0,0.235
			c1.025,1.011,1.535,2.537,2.35,3.759c0.209,0.496,0.715,0.694,0.705,1.409c2.843,4.048,5.406,8.375,8.456,12.216
			c0,0.078,0,0.156,0,0.234c5.358,7.015,9.933,14.812,15.27,21.847c0.801,1.156,1.541,2.374,2.349,3.523
			c-0.042-0.108-0.096-0.207-0.141-0.313c0,0.001,0.001,0.001,0.001,0.002c0.045,0.105,0.098,0.203,0.14,0.311
			c0.299,0.798,0.834,1.358,1.175,2.114c0.097,0.068,0.178,0.151,0.259,0.235v0.001c-0.08-0.085-0.162-0.168-0.259-0.236
			c0.654,1.146,1.094,2.509,1.879,3.523c0.308,1.025,0.585,2.078,1.175,2.819c0.016,1.159,0.69,1.659,0.705,2.819
			c0,0.078,0,0.156,0,0.234c0.559,0.852,0.709,2.109,0.705,3.523c0,0.079,0,0.157,0,0.235c0.078,0,0.156,0,0.234,0
			c-0.141,1.158,0.61,1.425,0.47,2.584c0,0.078,0,0.156,0,0.235c0.839,1.588,1.503,3.352,1.88,5.402
			c0.307,1.024,0.709,1.953,0.704,3.289c0.405,0.534,0.503,1.377,0.47,2.349c0.014,0.144,0.035,0.278,0.235,0.235
			c-0.114,1.445,0.418,2.244,0.234,3.759c0.543,0.71,0.083,2.423,0.235,3.523c0,0.078,0,0.156,0,0.234
			c0.023,0.964,0.046,1.926-0.235,2.584c0.39,0.254,0.389,2.096,0,2.35C569.453,546.594,569.458,547.146,569.188,547.424z
			 M472.17,525.813c19.156-1.202,38.715-2.003,58.727-2.35c-12.201,2.966-27.078,7.767-42.753,6.578
			C482.371,529.603,477.387,527.042,472.17,525.813z"/>
			</g>
		</g>
		<g id="lighting_1_">
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M569.422,543.665c0.281-0.658,0.259-1.62,0.235-2.584
		c0-0.078,0-0.156,0-0.234c-0.152-1.101,0.308-2.813-0.235-3.523c0.184-1.515-0.349-2.313-0.234-3.759
		c-0.2,0.043-0.222-0.092-0.235-0.235c0.033-0.972-0.064-1.814-0.47-2.349c0.005-1.336-0.397-2.265-0.704-3.289
		c-0.377-2.051-1.041-3.814-1.88-5.402c0-0.079,0-0.157,0-0.235c0.141-1.159-0.61-1.426-0.47-2.584c-0.078,0-0.156,0-0.234,0
		c0-0.078,0-0.156,0-0.235c0.004-1.414-0.146-2.672-0.705-3.523c0-0.078,0-0.156,0-0.234c-0.015-1.16-0.689-1.66-0.705-2.819
		c-0.59-0.741-0.867-1.794-1.175-2.819c-0.785-1.015-1.225-2.377-1.879-3.523c-0.341-0.756-0.876-1.316-1.175-2.114
		c-0.808-1.149-1.548-2.367-2.349-3.523c-5.337-7.035-9.911-14.832-15.27-21.847c0-0.078,0-0.156,0-0.234
		c-3.05-3.841-5.613-8.168-8.456-12.216c0.01-0.715-0.496-0.913-0.705-1.409c-0.814-1.222-1.324-2.748-2.35-3.759
		c0-0.078,0-0.156,0-0.235c-0.512-0.74-0.639-1.866-1.409-2.349c-0.211-0.964-0.719-1.631-1.175-2.349
		c-0.665-1.214-1.148-2.61-2.114-3.524c0-0.078,0-0.156,0-0.234c-0.816-1.688-1.941-3.069-2.818-4.698c-0.078,0-0.157,0-0.235,0
		c0-0.078,0-0.157,0-0.235c-0.495-0.992-1.149-1.826-1.644-2.818c-0.586-0.511-0.686-1.508-1.41-1.88c0-0.078,0-0.156,0-0.234
		c-4.456-6.82-9.512-13.04-14.799-19.028c-2.976-3.289-6.261-6.268-9.396-9.396c-4.012-2.749-7.174-7.139-11.428-9.459
		c0.014,0.011,0.027,0.023,0.041,0.034c-0.014-0.011-0.027-0.023-0.042-0.035l0.001,0c-0.266-0.211-0.521-0.432-0.788-0.642
		c-0.095-0.047-0.18-0.082-0.258-0.106c-0.052-0.016-0.096-0.022-0.142-0.03c-0.024-0.004-0.052-0.014-0.075-0.016
		c-0.05-0.005-0.096-0.003-0.143-0.001c-0.016,0.001-0.033-0.001-0.05,0c-0.052,0.005-0.103,0.014-0.154,0.024
		c-0.009,0.001-0.017,0.002-0.025,0.004c-0.337-0.129-0.681-0.245-1.032-0.344c-2.067-1.377-2.724-4.167-4.229-6.107
		c-0.705-1.8-1.869-3.143-2.584-4.933c-0.411-0.998-0.575-2.244-1.409-2.819c0-0.079,0-0.157,0-0.235
		c-0.726-1.292-1.651-2.383-2.606-3.445c-0.466-0.547-0.945-1.08-1.437-1.602c0.001,0.001,0.001,0.001,0.002,0.002
		c-0.305-0.347-0.605-0.698-0.893-1.063c-1.172-1.334-2.425-2.587-3.759-3.758c-3.683-3.051-7.16-6.308-10.806-9.396
		c-0.288-0.224-0.549-0.475-0.816-0.72c1.234,1.334,2.544,2.593,3.892,3.813c-1.349-1.22-2.658-2.479-3.893-3.814
		c0.001,0,0.001,0,0.001,0c-0.865-0.935-1.691-1.907-2.47-2.929c-0.006-0.007-0.012-0.014-0.018-0.022
		c-0.405-0.714-0.779-1.461-1.395-1.966c0-0.079,0-0.157,0-0.235c-0.887-1.383-1.484-3.057-2.349-4.463c0-0.079,0-0.157,0-0.235
		c-0.353-0.978-0.667-1.996-1.41-2.584c0-0.078,0-0.156,0-0.235c-2.709-7.861-7.686-13.456-13.859-17.853
		c-1.01-0.637-2.274-1.019-3.479-1.46c0.002,0,0.003,0.001,0.004,0.002c-0.002-0.001-0.004-0.002-0.007-0.003
		c0.001,0,0.002,0,0.003,0.001c-0.003-0.001-0.006-0.002-0.009-0.004c-0.641-0.314-1.299-0.61-1.972-0.893
		c-0.23-0.14-0.447-0.293-0.648-0.461c-0.156,0-0.313,0-0.47,0c-0.748-0.113-0.983-0.74-1.88-0.705
		c-0.754-0.656-1.847-0.972-3.054-1.175c-1.884-0.778-4.164-1.161-6.577-1.409c-1.841-0.587-4.064-0.79-6.107-1.175
		c-0.078,0-0.157,0-0.235,0c-0.325-0.536-1.635-0.087-2.349-0.235c-0.463-0.321-1.342-0.224-1.88-0.47
		c-3.579-0.492-7.171-0.973-10.806-1.41c-2.024-0.011-3.613-0.458-5.638-0.47c-3.261-0.733-5.612-2.375-8.457-3.523
		c-0.197,0.272-0.31,0.629-0.234,1.174c0,0.078,0,0.157,0,0.235c-0.229,0.006-0.183,0.288-0.235,0.47
		c2.1,1.737,4.524,3.149,7.282,4.229c1.149,0.495,2.229,1.061,3.759,1.175c0.69,0.876,1.987,1.144,3.289,1.409
		c1.804,0.624,3.499,1.355,5.638,1.645c1.004,0.64,2.583,0.706,3.758,1.174c0.079,0,0.157,0,0.235,0
		c1.113,0.531,1.822,1.467,2.584,2.349c0,0.079,0,0.157,0,0.235c-0.046,1.933-1.183,2.284,0.235,3.524
		c1.071,0.132,1.463-0.417,2.584-0.235c3.211,0.547,7.438,0.079,11.04,0.235c0.549,0,1.097,0,1.645,0
		c0.004,0.388,0.651,0.131,0.705,0.47c-2.469,2.856-4.563,6.085-6.107,9.866c-0.436,0.504-0.159,1.721-0.235,2.584
		c-0.354,0.9-0.567,1.939-0.939,2.819c-0.377,1.033-1.003,1.816-1.175,3.054c-0.953,1.318-2.374,2.167-2.584,4.229
		c1.268,1.92,2.435,0.652,3.476-0.753c0.224-0.276,0.434-0.573,0.639-0.873c0.199-0.269,0.395-0.521,0.584-0.723
		c1.833-1.965,4.514-3.197,5.403-4.463c0.881-0.685,1.664-1.467,2.349-2.349c0.77-0.405,1.11-1.239,1.88-1.644
		c0.959-0.763,1.82-1.625,2.584-2.584c0.635-0.37,1.063-0.945,1.551-1.462c0,0,0.002-0.001,0.003-0.002
		c0.46-0.343,0.93-0.667,1.405-0.979c0.004-0.002,0.007-0.005,0.011-0.007c0.171-0.058,0.352-0.107,0.554-0.134
		c0.757,0.652,0.969,1.85,1.879,2.349c0,0.078,0,0.156,0,0.235c0.609,0.957,1.085,2.047,1.88,2.819c0,0.078,0,0.156,0,0.235
		c1.035,3.103,7.453,7.613,5.402,11.041c-0.335,0.313-0.335,1.331,0,1.645c2.684,4.677,8.108,6.612,11.98,10.101
		c0.467,0.003,0.299,0.641,0.939,0.47c1.713,1.732,3.837,3.054,5.638,4.698c-0.685,0.881-1.467,1.665-2.349,2.349
		c-1.664,1.468-3.23,3.034-4.698,4.698c-1.325,1.807-2.759,3.506-4.229,5.168c-0.96,0.763-1.821,1.625-2.584,2.584
		c-1.224,1.134-2.413,2.771-3.759,4.229c-1.179,1.278-3.767,2.622-3.523,3.759c1.071-0.495,2.586-0.546,3.523-1.175
		c0.545,0.075,0.902-0.038,1.175-0.235c0.866-0.151,1.795-0.241,2.35-0.705c1.588-0.213,3.039-0.563,4.463-0.94
		c1.12-0.132,2.261-0.245,3.054-0.705c0.078,0,0.156,0,0.235,0c1.329-0.158,2.584-0.391,3.523-0.939c0.078,0,0.156,0,0.234,0
		c1.569-0.154,2.557-0.89,4.464-0.705c0.078,0,0.156,0,0.234,0c1.433,0.216,1.86,2.676,2.819,2.584
		c1.277-0.132,2.199-0.62,3.759-0.47c0.454,0.329,0.696,0.87,0.939,1.41c0.442,1.44,1.999,3.008,3.759,2.349
		c0.078,0,0.156,0,0.234,0c0.566,0.096,0.511-0.429,1.175-0.235c0.358,0.582,0.482,1.397,1.175,1.644
		c-0.172,0.641,0.467,0.473,0.47,0.94c1.707,2.521,4.023,4.433,5.872,6.812c1.155,1.352,2.408,2.604,3.759,3.759
		c1.299,1.051,1.944,2.754,2.819,4.229c0,0.078,0,0.156,0,0.235c0.417,1.227,0.964,2.324,1.645,3.288
		c-0.055,0.916,0.504,1.218,0.704,1.88c0.435,1.131,0.819,2.313,1.645,3.054c0,0.078,0,0.156,0,0.234
		c0.648,1.075,1.23,2.216,2.114,3.054c1.012,2.356,2.307,4.428,3.523,6.578c0.585,1.06,1.017,2.271,1.88,3.054
		c-0.011,0.715,0.496,0.913,0.704,1.409c0.51,0.9,0.823,1.996,1.645,2.584c0.664,1.45,1.575,2.653,2.35,3.993
		c2.935,4.113,4.898,9.196,9.866,11.276c0.419-0.488,0.045-1.607-0.235-2.114c0.005-1.336-0.397-2.265-0.705-3.289
		c-0.082-1.092-0.301-2.048-0.704-2.818c-0.034-1.142-0.37-1.979-0.705-2.819c-0.151-0.866-0.241-1.795-0.704-2.349
		c0-0.079,0-0.157,0-0.235c-0.789-2.813-1.561-5.644-2.819-7.987c-0.704-2.819-2.218-4.829-3.289-7.282
		c-0.389-0.785-0.831-1.518-0.704-2.818c0-0.078,0-0.157,0-0.235c0.183-0.052,0.272-0.196,0.234-0.47
		c1.001-0.771,1.397,0.687,2.114,0.939c1.062,1.256,2.22,2.412,3.473,3.478c0.034,0.05,0.068,0.101,0.103,0.149
		c1.996,4.009,4.045,7.965,6.429,11.586c-0.009-0.015-0.018-0.03-0.026-0.045c0.268,0.435,0.541,0.861,0.828,1.276
		c0,0.078,0,0.156,0,0.234c0.957,1.471,1.598,3.258,2.819,4.464c0.676,1.908,1.919,3.248,2.819,4.933
		c0.749,1.208,1.335,2.58,2.349,3.523c0,0.079,0,0.157,0,0.235c1.176,1.878,2.284,3.823,3.759,5.403c0,0.47,0,0.939,0,1.409
		c-0.184,0.052-0.273,0.196-0.235,0.47c-0.537,0.402-0.086,1.793-0.234,2.584c-0.055,1.355-1.058,1.762-0.94,3.289
		c-0.548,0.665-0.033,1.773,0.235,2.349c0.057,1.118-0.298,2.647,0.705,2.819c1.519-1.144,3.428-1.896,4.463-3.524
		c0.313,0,0.627,0,0.939,0c0.941,0.313,1.567,0.939,1.88,1.88c0,0.156,0,0.313,0,0.47c-0.891,2.398-2.51,4.068-4.464,5.403
		c-0.078,0-0.156,0-0.234,0c-0.624,0.605-3.208,1.572-2.114,2.349c0.303,0.323,1.021,0.232,1.645,0.235
		c0.683,0.413,1.657,0.535,2.584,0.704c0.755,0.263,1.393,0.644,2.349,0.705c1.569,0.703,3.548,2.243,5.873,1.409
		c1.772,0.342,4.391-0.163,4.933,1.409c-0.158,0.938-1.021,1.172-1.879,1.41c-0.002,1.358,3.771,2.146,3.523,3.758
		c-3.068,0.143-5.112,1.309-7.517,2.114c-0.825,0.351-2.05,0.3-2.819,0.705c-0.677,0.028-1.538-0.128-1.879,0.235
		c-0.924,0.016-1.766,0.113-2.35,0.47c-3.014,0.274-5.589,0.988-8.457,1.409c-1.39,0.02-2.582,0.237-3.523,0.705
		c-2.275,0.151-4.035,0.819-6.107,1.174c-0.931-0.069-1.436,0.287-1.879,0.705c1.706,0.173,2.839,0.92,4.698,0.939
		c0.078,0,0.156,0,0.234,0c0.073,0.319,0.589,0.195,0.939,0.235c0.479,0.539,1.951,0.085,2.819,0.234c1.096,0,2.192,0,3.289,0
		c1.702,0.256,3.437,0.479,5.168,0.705c0.793,0.459,1.933,0.572,3.054,0.705c0.628,0.232,1.238,0.483,2.114,0.47
		c0.449,0.411,1.225,0.498,1.879,0.704c1.646,0.547,4.306,0.08,6.343,0.235c1.355,0.054,1.762,1.057,3.288,0.939
		c0.053,0.184,0.197,0.273,0.471,0.235c0.559,0.39,2.729,0.39,3.288,0c1.488-0.235,1.636,0.87,2.819,0.939
		c1.31,0.256,1.82,1.312,3.289,1.409c0.404,0.39,2.413,0.39,2.818,0c0.078,0,0.157,0,0.235,0c0.144,0.042,0.258,0.092,0.369,0.144
		c0.09,0.045,0.172,0.091,0.244,0.141l-0.001-0.001c0.001,0.001,0.002,0.001,0.003,0.002c0.026,0.018,0.042,0.038,0.065,0.057
		c0.262,0.207,0.358,0.453,0.313,0.726c0.043-0.271-0.053-0.519-0.313-0.726c-0.022-0.019-0.039-0.038-0.065-0.056
		c0.188,0.27,0.227,0.688,0.282,1.091c0,0.001-0.001,0.002-0.001,0.003c-0.074,0.158-0.174,0.321-0.306,0.487
		c-0.001,0.001-0.002,0.002-0.003,0.003c-1.165,0.679-2.228,1.46-3.172,2.358c-0.079,0-0.157,0-0.235,0
		c-1.353,0.684-2.549,1.522-3.523,2.584c-0.078,0-0.157,0-0.235,0c-1.438,0.754-3.019,1.366-3.523,3.054c0,0.078,0,0.156,0,0.235
		c-0.723,0.738,0.276,1.747,0.47,2.349c0.946,0.391,3.518,0.391,4.464,0c0.556-0.116,0.813-0.531,1.108-0.907
		c0.079-0.052,0.153-0.109,0.223-0.171c0.117-0.098,0.227-0.202,0.313-0.331c0.078,0,0.157,0,0.235,0
		c3.357,0.929,3.396-3.494,6.107-3.993c0.653,1.304,0.798,3.117,1.645,4.228c0.276,1.446,0.687,2.759,1.175,3.994
		c0.169,0.927,0.291,1.901,0.704,2.584c-0.131,2.402,0.563,3.979,1.175,5.638c0.236,1.095,0.432,2.23,0.939,3.054
		c-0.008,1.025,0.204,1.832,0.47,2.584c0.271,1.68-0.83,1.988-1.174,3.054c0,0.078,0,0.157,0,0.235
		c-1.335,1.641-2.448,3.503-3.524,5.402c-0.929,1.655-1.7,3.468-2.584,5.168c-0.793,0.538-0.941,1.721-1.174,2.819
		c-0.19,0.827-0.946,1.09-0.939,2.114c-0.626,0.627-0.741,1.766-0.94,2.818c-0.612,1.502-1.386,2.843-1.879,4.464
		c-0.825,0.741-1.21,1.922-1.645,3.054c-0.58,1.848-1.379,3.477-1.879,5.403c-0.69,0.798-1.023,1.951-1.41,3.054
		c0,0.078,0,0.156,0,0.234c-0.589,0.741-0.867,1.795-1.174,2.819c-1.223,3.084-2.412,6.202-3.759,9.161
		c-2.86,4.97-6.273,9.388-9.396,14.095c0,0.078,0,0.157,0,0.235c-0.623,0.238-0.637,1.086-1.175,1.409
		c-0.01,0.601,0.175,0.286,0.47,0.235c0.998-1.195,2.095-2.291,3.289-3.289c2.525-3.034,5.18-5.939,7.282-9.396
		c0-0.078,0-0.156,0-0.235c1.45-1.76,2.4-4.021,3.523-6.107c0.101-0.447,0.507-0.59,0.47-1.175c1.512-1.698,2.151-4.27,3.055-6.577
		c0-0.078,0-0.156,0-0.235c0.209-0.104,0.42-0.206,0.47-0.47c0.499-0.831,0.777-1.884,1.409-2.584
		c-0.03-0.421,0.287-0.495,0.234-0.939c0-0.078,0-0.156,0-0.234c0.525-0.65,0.915-1.436,1.175-2.35
		c1.347-2.882,2.669-5.788,3.759-8.927c0.948-1.479,1.613-3.241,2.349-4.933c0-0.078,0-0.157,0-0.235
		c0.484-0.064,0.222-0.875,0.705-0.939c0.652-1.697,1.435-3.263,2.35-4.698c0.389-0.785,0.785-1.563,1.174-2.349
		c0-0.079,0-0.157,0-0.235c1.172-1.334,1.965-3.047,2.819-4.698c0.271-0.277,0.266-0.83,0.234-1.409
		C569.811,545.761,569.812,543.919,569.422,543.665z M419.536,358.631c2.557-2.481,5.104-5.086,7.898-7.168
		C424.64,353.545,422.094,356.151,419.536,358.631z M505.872,434.35c0.793,0.789,1.507,1.656,2.162,2.583
		C507.379,436.006,506.664,435.14,505.872,434.35z M508.035,436.935c-0.02-0.022-0.041-0.043-0.061-0.065
		C507.994,436.892,508.017,436.912,508.035,436.935C508.035,436.936,508.035,436.935,508.035,436.935z M508.164,437.121
		c1.626,2.348,2.914,5.033,4.26,7.662C511.076,442.157,509.79,439.469,508.164,437.121z"/>
			<polygon fillRule="evenodd" clipRule="evenodd" fill="#E8E735" points="558.995,513.411 558.997,513.413 558.997,513.412 	" />
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M343.204,108.377c9.687,4.094,14.546,13.017,18.558,22.786
		c0.2-0.043,0.222,0.092,0.234,0.235c0.146,0.873,0.561,1.476,0.705,2.349c0.043,0.2-0.092,0.222-0.234,0.235
		c-0.368,1.042-0.46,2.359-0.471,3.759c-0.338-0.053-0.081-0.701-0.47-0.705c-0.199,0.043-0.222-0.091-0.234-0.235
		c-0.12-1.446-0.893-2.24-1.175-3.523c-1.074-4.25-3.934-6.715-6.107-9.866c-0.677,1.682,1.545,4.357,2.349,6.107
		c0.256,0.058,0.159,0.467,0.47,0.47c-0.053,1.149,0.717,1.476,0.705,2.584c0.2,1.287,0.41,2.565,0.939,3.523
		c0.494,0.289,0.152,1.415,0.705,1.645c0.019,1.156,0.461,1.888,0.705,2.819c0.136,1.039,0.097,2.251,0.47,3.054
		c0,0.078,0,0.157,0,0.235c-0.141,1.394,0.412,2.094,0.234,3.524c0.154,1.49-0.31,3.598,0.235,4.698c0.078,0,0.156,0,0.234,0
		c0,0.078,0,0.156,0,0.235c-0.247,1.422,0.543,1.806,0.705,2.819c0.573,2.167,1.149,4.332,2.114,6.107
		c0.628,0.86,0.68,2.296,1.409,3.054c0,0.078,0,0.156,0,0.235c0.039,0.666,0.431,0.979,0.47,1.644c0.25,1.395,0.72,2.569,1.41,3.524
		c0.349,0.199,0.34,0.756,0.47,1.174c0.292,1.431,0.753,2.692,1.409,3.759c1.313,3.62,2.409,7.457,3.289,11.511
		c0.659,4.039,1.818,7.578,3.054,11.041c0.199-0.043,0.222,0.092,0.234,0.235c0.285,1.124,0.312,2.508,0.705,3.524
		c0,0.078,0,0.156,0,0.235c-0.012,1.186,0.092,2.257,0.47,3.054c-0.244,1.244-0.458,2.517-0.47,3.994
		c-0.291,0.679-0.238,1.188,0,1.601c0,0.015,0,0.029,0,0.043c0.012,0.006,0.022,0.014,0.035,0.021
		c0.614,0.959,2.277,1.364,3.019,2.094c0.197-0.154,0.36-0.342,0.565-0.488c0.195-0.016,0.399-0.063,0.61-0.139
		c0.044,1.731-0.268,3.816,0.703,4.621c0.067,1.186,0.47,2.037,0.705,3.054c0.147,0.714-0.301,2.023,0.235,2.349
		c0,0.078,0,0.157,0,0.235c-0.536,0.326-0.088,1.635-0.235,2.349c0,0.157,0,0.313,0,0.47c-0.364,0.576-0.351,1.529-0.47,2.349
		c-0.35,0.981-0.722,1.94-0.939,3.054c-0.709,0.858-1.067,2.065-1.645,3.054c0,0.078,0,0.156,0,0.235
		c-0.764,0.646-1.128,1.69-1.645,2.584c-0.836,1.122-1.557,2.358-2.114,3.758c-0.035,1.296-0.719,1.943-0.704,3.289
		c-0.464,0.554-0.554,1.482-0.705,2.349c0,0.078,0,0.156,0,0.235c-0.522,0.73-0.636,1.87-0.705,3.054
		c-0.323,0.303-0.232,1.021-0.234,1.645c-0.561,0.692-0.38,2.126-0.939,2.819c0.079,1.176-0.55,1.643-0.471,2.819
		c0,0.078,0,0.157,0,0.235c-0.078,0-0.156,0-0.234,0c0,0.783,0,1.566,0,2.349c0.445,0.461,0.859,1.788,1.645,1.174
		c0-0.078,0-0.156,0-0.235c0.078,0,0.156,0,0.234,0c0.726-0.528,1.352-1.154,1.88-1.879c-0.352-0.04-0.867,0.083-0.94-0.235
		c-0.27-0.278-0.266-0.831-0.234-1.41c0.271-0.278,0.266-0.831,0.234-1.41c0.552-1.406,0.939-2.976,1.175-4.698
		c0.413-1.466,0.728-3.031,0.939-4.698c0.717-1.398,1.126-3.103,1.645-4.698c0.615-1.734,1.419-3.279,1.88-5.168
		c1.048-3.023,3.836-4.307,4.698-7.517c1.37-3.171,2.802-6.281,3.758-9.866c0.643-0.846,0.923-2.052,1.175-3.289
		c0.69-1.972,1.416-3.909,1.409-6.578c0.391-0.791,0.391-3.203,0-3.993c-0.065-4.084-0.839-7.461-0.939-11.511
		c-0.533-0.25-0.089-1.477-0.234-2.114c-1.711-10.191-4.317-19.487-6.343-29.364c-0.133-1.12-0.245-2.26-0.705-3.054
		c-1.263-7.272-2.925-14.145-5.168-20.437c-0.555-2.029-2.076-3.092-3.054-4.698c0-0.079,0-0.157,0-0.235
		c-1.689-2.853-4.224-4.86-6.107-7.518c-1.47-1.114-2.524-2.644-3.994-3.758c-2.65-1.578-4.726-3.73-7.282-5.403
		c-0.078,0-0.156,0-0.234,0c-1.264-1.32-2.987-2.181-4.464-3.289c-0.078,0-0.156,0-0.234,0c-1.266-1.318-2.873-2.294-4.463-3.289
		c-0.594-0.346-1.05-0.83-1.88-0.939c-0.479-0.539-1.951-0.085-2.818-0.235C342.792,108.162,343.201,108.066,343.204,108.377z
		 M367.869,138.68c0.938-0.077,0.496,1.227,0.939,1.645c0.363,1.125,0.613,2.362,1.175,3.289c0,0.313,0,0.626,0,0.94
		c0.2-0.043,0.222,0.091,0.235,0.235c0.332,1.469,0.529,3.072,1.174,4.228c-0.074,0.545,0.038,0.902,0.235,1.175
		c0.151,0.867,0.241,1.795,0.705,2.349c0.451,2.758,1.285,5.135,2.114,7.517c0.015,1.16,0.689,1.659,0.704,2.819
		c0.255,0.058,0.159,0.467,0.47,0.47c-0.1,0.491,0.161,0.622,0.235,0.94c0.182,0.602,0.234,1.332,0.704,1.644
		c0-0.313,0-0.626,0-0.939c-0.14-0.487,0.291-1.543-0.234-1.645c0-0.235,0-0.47,0-0.705c-0.15-0.945,0.306-2.498-0.235-3.054
		c0-0.235,0-0.47,0-0.705c-0.149-0.868,0.305-2.34-0.234-2.819c0-0.156,0-0.313,0-0.47c-0.149-0.791,0.303-2.182-0.235-2.584
		c-0.18-5.458-1.188-10.087-2.114-14.799c-0.199,0.043-0.222-0.092-0.234-0.235c-0.517-2.322,1.391-1.655,1.645-0.47
		c0.195,1.449,0.561,2.728,0.939,3.993c0,0.392,0,0.783,0,1.175c0.653-0.106-0.185,1.28,0.47,1.174
		c0.21,2.452,0.561,4.764,0.939,7.047c0,0.783,0,1.566,0,2.349c0.078,0,0.156,0,0.235,0c0,0.078,0,0.157,0,0.235
		c0.148,0.791-0.303,2.182,0.234,2.584c0,0.079,0,0.157,0,0.235c0,0.079,0,0.157,0,0.235c0.151,0.946-0.306,2.498,0.235,3.054
		c0,0.156,0,0.313,0,0.47c0,1.488,0,2.976,0,4.463c0.078,0,0.156,0,0.234,0c0,0.313,0,0.626,0,0.939
		c0.108,2.006-0.246,4.475,0.235,6.108c0.078,0,0.156,0,0.234,0c-0.112,0.739,0.335,0.918,0.47,1.409
		c0.473,1.485,0.886,3.03,1.645,4.229c1.859,6.206,3.759,12.372,4.698,19.498c0.153,1.179-0.308,2.97,0.235,3.758
		c0,0.157,0,0.313,0,0.47c-0.541,0.633-0.084,2.266-0.235,3.289c0,0.078,0,0.157,0,0.235c-0.399,0.696-0.258,1.934-0.47,2.819
		c0,0.079,0,0.157,0,0.235c-0.74,0.904-0.483,2.805-1.879,3.054c-0.078,0-0.156,0-0.235,0c-0.674-1.743,0.878-3.157,0.47-5.403
		c0.538-0.402,0.087-1.793,0.235-2.584c0-0.235,0-0.47,0-0.705c0.389-0.253,0.389-2.096,0-2.349
		c-0.095-1.001,0.228-2.42-0.235-3.054c0.014-1.032-0.094-1.941-0.47-2.584c0.077-1.095-0.183-1.853-0.47-2.584
		c-0.241-1.638-0.542-3.216-1.175-4.463c-0.069-1.417-0.539-2.437-0.939-3.524c-0.414-1.7-0.839-3.389-1.645-4.698
		c-1.163-4.004-2.793-7.542-4.228-11.275c-0.281-0.815-0.368-1.825-0.94-2.349c-2.025-7.214-4.581-13.898-5.402-22.316
		C368.02,141.52,367.052,139.228,367.869,138.68z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M338.271,154.654c0.052,1.436,0.644,2.332,1.175,3.289
		c0,0.078,0,0.157,0,0.235c0.481,0.38,0.592,1.13,0.939,1.645c0.586,0.511,0.686,1.507,1.409,1.879
		c0.147,0.871,0.661,1.375,0.94,2.114c-0.066,0.379,0.222,0.405,0.234,0.705c0.062,0.722,0.51,1.056,0.705,1.644
		c0.19,0.045,0.234,0.236,0.47,0.235c0.423,0.674,0.619,1.574,1.175,2.114c0,0.079,0,0.157,0,0.235
		c0.737,1.689,1.361,3.493,2.349,4.933c-0.201,0.75,0.37,0.727,0.47,1.175c0.594,0.894,0.792,2.184,1.645,2.819
		c0,0.078,0,0.157,0,0.235c0.225,0.48,0.479,0.93,0.705,1.41c-0.19-0.045-0.234-0.236-0.47-0.235c0.339,0.522,0.59,1.133,0.47,2.114
		c-0.38,0.403-0.983,0.583-1.88,0.47c-0.078,0-0.156,0-0.234,0c-0.481,0.881,0.293,2.519,0.47,3.523
		c0.199,1.054,0.313,2.192,0.939,2.819c0,0.079,0,0.157,0,0.235c0.524,1.825,1.762,2.937,2.819,4.229
		c0.614,1.421,2.022,2.049,2.818,3.289c0,0.079,0,0.157,0,0.235c0.718,0.222,0.736,1.143,1.645,1.174
		c0.678-2.325-0.229-5.093-0.939-6.813c-0.078,0-0.157,0-0.235,0c0.023-1.119-0.438-1.754-0.704-2.584
		c-0.146-0.873-0.561-1.476-0.705-2.349c-0.304-1.498-0.9-2.702-1.645-3.758c-0.375-0.33-0.6-0.81-0.705-1.41
		c-0.358-0.667-0.412-2.866-0.704-2.819c-0.078,0-0.156,0-0.235,0c0.174-1.113-0.264-1.615-0.234-2.584
		c-1.123-4.75-3.231-8.514-5.873-11.746c-0.338-0.053-0.082-0.701-0.47-0.705c0.186-0.813-0.261-0.992-0.47-1.41
		c-1.36-1.496-2.636-3.078-3.893-4.678c-0.485-0.735-0.968-1.404-1.481-1.896c-0.244-0.314-0.488-0.629-0.733-0.943
		C338.164,153.821,337.762,154.694,338.271,154.654z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M48.626,162.641c0.375,2.443,1.033,4.605,1.645,6.813
		c0.47,2.662,1.095,5.169,1.879,7.517c0.376,2.443,1.033,4.605,1.645,6.813c0.47,2.662,1.095,5.169,1.879,7.517
		c0.454,2.756,1.189,5.231,1.879,7.752c0.391,2.349,0.938,4.543,1.644,6.578c0.454,2.756,1.189,5.231,1.879,7.752
		c0.392,2.349,0.938,4.543,1.645,6.577c0.532,3.07,1.346,5.858,2.114,8.692c0.47,2.662,1.096,5.169,1.879,7.517
		c0.532,3.07,1.346,5.858,2.114,8.692c-0.053,0.992,0.362,1.517,0.47,2.349c0.164,1.794,1.549,2.366,2.114,3.758
		c0.189,0.829,1.434,0.602,1.41,1.645c0.619,0.083,1.033,0.37,1.378,0.727c0.343,0.371,0.671,0.75,0.971,1.152
		c0,0.079,0,0.157,0,0.235c0,0.626,0,1.252,0,1.879c0.024,0.602,0.133,1.12,0.47,1.41c0.063,1.19,0.401,2.104,0.705,3.054
		c0.247,1.163,0.381,2.438,0.94,3.289c-0.014,0.875,0.237,1.485,0.47,2.114c0,0.235,0,0.47,0,0.705
		c0.517,0.181,1.17-2.253,2.114-0.47c0.063,1.425,0.375,2.601,0.705,3.759c0.233,1.333,0.266,2.867,1.174,3.523
		c0.22-0.172,0.356-0.427,0.47-0.705c0.558-0.913,0.025-2.444-0.235-3.289c-0.068-1.263-0.247-2.416-0.705-3.289
		c0.008-1.025-0.204-1.832-0.47-2.584c0.083-0.935-0.293-2.329,0.47-2.584c0.949-0.087,0.919,0.803,1.41,1.175
		c0.042,1.368,0.344,2.475,0.705,3.523c0.132,1.121,0.246,2.26,0.705,3.054c-0.037,1.446,0.507,2.312,0.47,3.758
		c-0.715,1.007-1.285,2.161-2.349,2.819c-0.313,0-0.626,0-0.94,0c-0.078,0-0.156,0-0.235,0c-0.352-0.274-0.574-0.678-1.174-0.705
		c0.456,0.797,0.335,2.171,0.939,2.819c0.141,1.504,0.561,2.728,0.939,3.994c0.058,0.725,0.238,1.328,0.47,1.879
		c0.778-0.866,0.995-2.294,1.409-3.523c0.29-0.963,0.868-1.638,0.94-2.819c0.985-1.338,1.185-2.521,1.644-3.994
		c-0.063-1.19-0.401-2.104-0.705-3.054c-0.495-2.089-0.836-4.332-1.645-6.107c-0.297-2.13-0.875-3.979-1.409-5.873
		c-0.415-1.778-0.682-3.703-1.41-5.168c-0.219-1.817-0.718-3.354-1.174-4.933c-0.495-2.089-0.836-4.332-1.645-6.108
		c-0.297-2.13-0.875-3.979-1.41-5.873c-0.495-2.089-0.836-4.332-1.644-6.107c-0.141-1.504-0.561-2.729-0.94-3.994
		c-0.495-2.089-0.836-4.332-1.644-6.108c-0.297-2.13-0.876-3.979-1.41-5.873c-0.495-2.089-0.836-4.332-1.645-6.107
		c-0.141-1.504-0.561-2.729-0.939-3.994c-0.495-2.089-0.836-4.332-1.645-6.108c-0.297-2.13-0.875-3.979-1.409-5.873
		c-0.332-1.469-0.53-3.072-1.175-4.229c-0.219-1.817-0.718-3.353-1.174-4.933c-0.415-1.778-0.682-3.703-1.41-5.168
		c-0.219-1.817-0.718-3.354-1.174-4.933c-0.415-1.778-0.682-3.703-1.41-5.168c-0.219-1.817-0.718-3.354-1.174-4.933
		c-0.332-1.469-0.53-3.072-1.175-4.229c-0.119-1.604-0.663-2.782-0.939-4.228c-0.506-0.389-1.446,0.293-2.114,0.47
		c-2.349,0-4.698,0-7.047,0C47.127,157.719,47.854,160.202,48.626,162.641z M74.194,253.332c-0.552-0.597-1.145-1.169-1.729-1.764
		C73.05,252.162,73.642,252.735,74.194,253.332z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M51.68,192.709c0.682,3.234,1.224,6.607,2.114,9.631
		c0.151,0.867,0.241,1.795,0.705,2.349c0,0.079,0,0.157,0,0.235c0.188,1.144,0.412,2.251,0.94,3.054
		c-0.023,1.12,0.438,1.755,0.705,2.584c0.249,1.239,0.451,2.524,0.939,3.523c0,0.078,0,0.157,0,0.235
		c0.213,1.431,0.432,2.857,0.94,3.994c0.252,2.332,0.97,4.198,1.644,6.107c0.818,1.609,0.956,3.899,1.645,5.638
		c0,0.078,0,0.156,0,0.235c0.188,1.143,0.411,2.251,0.939,3.054c0.733,3.652,2.157,6.613,2.584,10.571
		c0.182,3.342,1.162,5.885,2.114,8.457c0,0.078,0,0.156,0,0.235c0.465,1.335,0.406,3.196,1.645,3.758c0.313,0,0.626,0,0.939,0
		c1.569,0.702,1.637,2.905,2.114,4.698c-0.055,1.276-0.493,2.169-2.114,1.879c-0.573,0.666-0.049,2.231,0.235,2.819
		c1.815,10.713,4.141,20.917,7.752,29.833c-0.179,2.215,0.428,3.644,1.879,4.229c0.083-0.309,0.414-0.37,0.47-0.705
		c0.072-0.554,0.815-0.437,0.705-1.175c0.554-0.072,0.437-0.815,1.174-0.705c0.644-0.296,0.422-1.458,1.175-1.645
		c0-0.235,0-0.47,0-0.705c-0.326-0.144-0.035-0.904-0.47-0.939c0.48-2.016-0.101-3.623-0.705-5.168
		c-0.151-0.867-0.241-1.795-0.705-2.349c0-0.079,0-0.157,0-0.235c-0.151-0.866-0.241-1.794-0.705-2.349
		c0.078-0.86-0.261-1.305-0.47-1.879c-0.151-0.867-0.241-1.795-0.705-2.349c-0.229-1.65-0.688-3.071-1.174-4.463
		c-0.151-0.867-0.241-1.795-0.705-2.349c-0.229-1.65-0.688-3.071-1.175-4.463c-0.151-0.867-0.241-1.795-0.705-2.349
		c-0.229-1.65-0.688-3.07-1.175-4.463c-0.151-0.866-0.241-1.794-0.705-2.349c0-0.078,0-0.156,0-0.235
		c-0.151-0.866-0.241-1.794-0.705-2.349c0-0.078,0-0.156,0-0.235c-1.316-2.6-4.279-3.551-4.933-6.813
		c-0.297-2.13-0.876-3.979-1.41-5.873c-0.313-2.036-0.781-3.917-1.409-5.638c-0.454-2.756-1.189-5.231-1.879-7.752
		c-0.392-2.349-0.938-4.543-1.645-6.578c-0.375-2.443-1.033-4.605-1.645-6.813c-0.313-2.036-0.781-3.917-1.409-5.638
		c-0.375-2.443-1.033-4.605-1.645-6.813c-0.392-2.349-0.938-4.543-1.644-6.578c-0.454-2.756-1.189-5.231-1.879-7.752
		c-0.47-2.662-1.095-5.169-1.879-7.517c-0.846-4.322-1.973-8.363-3.054-12.45c-0.626-3.289-1.409-6.421-2.349-9.396
		c-0.219-1.817-0.718-3.354-1.175-4.933c0.112-1.398-0.704-0.647-1.644-0.705c-0.062,0.33-0.516,0.268-0.705,0.47
		c0.04,0.352-0.083,0.867,0.235,0.939c0,0.079,0,0.157,0,0.235c-0.044,1.141,0.283,1.91,0.705,2.584
		c0.426,1.61,0.42,3.651,0.939,5.168c0.698,3.217,2.108,5.723,3.054,8.691c0.951,5.471,2.17,10.672,3.289,15.974
		C50.899,190.516,51.132,191.771,51.68,192.709z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M285.181,203.75c-0.079,0-0.157,0-0.235,0
		c-0.68-0.965-1.309-1.98-2.114-2.819c-0.079,0-0.157,0-0.235,0c-0.2,0.043-0.222-0.092-0.235-0.235
		c-0.414-0.212-0.727-0.525-0.939-0.939c-0.403-0.302-0.924-0.486-0.939-1.174c-1.156-0.802-1.828-2.087-2.349-3.524
		c-0.123-0.348-0.348-0.592-0.47-0.939c-1.155-2.369-2.472-4.576-3.054-7.517c-0.078,0-0.156,0-0.235,0c0-0.079,0-0.157,0-0.235
		c0-0.783,0-1.566,0-2.349c0.053-0.183,0.006-0.463,0.235-0.47c0-0.548,0-1.096,0-1.644c-0.412-0.45-0.498-1.225-0.705-1.879
		c2.411-0.017,0.766-4.089,2.819-4.463c1.646-1.469,4.382-2.578,6.108-3.523c-0.121,0.066-0.341-0.051-0.47,0
		c-1.149,0.356-2.595,0.259-3.523-0.235c0-0.235,0-0.47,0-0.705c-0.979,0.353-1.996,0.667-2.584,1.41
		c-1.21,0.277-1.84,1.136-2.819,1.645c-0.814,0.595-1.956,0.863-2.584,1.644c-0.83,0.11-1.286,0.594-1.879,0.94
		c-1.428,1.077-3.035,1.977-3.524,3.993c-1.392,4.95-1.266,11.419-3.054,15.974c-0.391,0.946-0.391,3.518,0,4.463
		c0,0.078,0,0.156,0,0.235c-0.37,1.744-1.92,2.308-3.289,3.054c-1.239,0.718-2.571,1.345-3.994,1.879
		c-1.697,0.652-3.263,1.435-4.698,2.349c-0.409-0.018-0.73,0.052-0.939,0.235c-1.485,0.476-3.371,1.793-2.819,3.523
		c0.009,1.401,0.542,2.277,1.644,2.584c0.352,0.432,0.98,0.586,1.175,1.175c0.708,0.31,1.277,0.759,2.349,0.705
		c0.043-0.2-0.092-0.222-0.235-0.235c-0.148-1.604-0.279-2.656,0.47-3.759c2.301-1.168,4.111,0.864,6.343,1.175
		c0.857,0.708,2.064,1.067,3.054,1.645c0.078,0,0.157,0,0.235,0c0.646,0.764,1.691,1.128,2.584,1.644c0.079,0,0.157,0,0.235,0
		c0.635,0.853,1.925,1.051,2.819,1.645c0.079,0,0.157,0,0.235,0c0.104,0.209,0.207,0.42,0.47,0.47
		c0.533-0.25,0.089-1.477,0.235-2.114c0.013-0.143,0.035-0.278,0.235-0.235c-0.066-0.614,0.085-1.01,0.705-0.939
		c0.704-1.288,0.704-4.585,0-5.873c-0.593-1.051-1.266-2.022-2.584-2.349c-0.531-0.156-1.177-0.706-0.705-1.174
		c0.619-0.725,1.423-0.15,2.349,0c0.882,0.762,1.664,1.625,2.584,2.349c4.826-0.114,10.801-1.096,14.799-2.819
		c0.48-0.225,0.93-0.48,1.409-0.705C286.725,204.712,286.099,204.085,285.181,203.75z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M364.815,210.093c1.332-0.077,2.524-0.295,3.523-0.705
		c0.079,0,0.157,0,0.235,0c0.392,0,0.783,0,1.175,0c1.805-0.937,1.222-5.51,0.939-7.752c-0.078,0-0.157,0-0.235,0
		c0-0.079,0-0.157,0-0.235c-0.167-1.086-0.474-2.032-0.939-2.819c0-0.235,0-0.47,0-0.705c-0.154-1.02-0.535-1.814-0.939-2.584
		c-0.352-0.509-0.4-1.322-0.47-2.114c-1.215-6.146-3.999-10.722-7.048-15.034c0.136,0.413-0.283,1.38,0.235,1.41
		c0.638-0.168-0.168,1.107,0.47,0.939c0,0.079,0,0.157,0,0.235c0.017,0.923,0.113,1.766,0.47,2.349
		c-0.004,2.353,0.369,4.329,0.939,6.108c0.133,1.12,0.245,2.26,0.705,3.054c0,0.078,0,0.157,0,0.235
		c0.151,0.867,0.241,1.795,0.705,2.349c0.489,3.739,1.47,6.986,1.644,11.041c-0.078,0-0.156,0-0.234,0
		c-0.405,0.77-0.785,1.563-0.939,2.584C365.239,209.263,364.7,209.352,364.815,210.093z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M342.029,260.833c0.554-1.248,0.844-2.758,1.175-4.229
		c0.435-0.035,0.144-0.795,0.47-0.939c0.036-1.132-1.093-1.1-0.705-2.584c-0.043-0.2,0.092-0.222,0.235-0.235
		c1.711-7.45,2.766-15.558,4.229-23.256c0.364-0.888,0.598-1.907,0.704-3.054c1.635-5.021,0.778-12.533,4.934-15.034
		c1.137-0.351,3.041,0.065,3.758-0.705c0.797-3.196,0.957-8.189,0-11.276c-0.078,0-0.156,0-0.234,0s-0.156,0-0.235,0
		c-3.088-2.236-5.071-5.578-7.752-8.222c-0.032-0.829-0.672-1.051-0.704-1.879c-0.144-0.013-0.278-0.035-0.235-0.235
		c-0.217-0.41,0.008-1.261-0.705-1.175c0,0.392,0,0.783,0,1.175c-0.43,0.51,0.045,1.923-0.47,2.349
		c0.183,0.209,0.253,0.53,0.235,0.939c-0.39,0.482-0.39,2.572,0,3.054c-0.157,1.332,0.527,1.821,0.705,2.819
		c-0.066,0.379,0.222,0.404,0.234,0.705c0.728,2.248,2.743,3.208,3.523,5.403c-0.3-0.013-0.325-0.301-0.704-0.235
		c-0.079,0-0.157,0-0.235,0c-2.014-1.354-3.057-3.678-4.463-5.638c0,0.783,0,1.566,0,2.349c0.154,1.725-0.311,4.069,0.234,5.403
		c0,0.157,0,0.313,0,0.47c0,1.645,0,3.289,0,4.933c0.06,1.077-1.259,0.777-1.175,1.879c-0.561,0.926-0.812,2.164-1.174,3.289
		c-0.136,0.491-0.583,0.67-0.47,1.41c-0.745,0.743-0.821,2.154-1.88,2.584c-0.616,0.714-1.906,0.755-3.288,0.705
		c-0.079,0-0.157,0-0.235,0c0-0.078,0-0.157,0-0.235c-0.783,0-1.566,0-2.349,0c-0.235,0-0.471,0-0.705,0
		c-0.25-0.533-1.477-0.089-2.114-0.235c-1.018,0-2.036,0-3.054,0c-0.156,0-0.313,0-0.47,0c-0.783,0-1.566,0-2.35,0
		c0-0.078,0-0.157,0-0.235c-0.861,0-1.723,0-2.584,0c-0.783,0-1.565,0-2.349,0c-0.313,0-0.627,0-0.939,0c-0.157,0-0.313,0-0.47,0
		c0.043,0.2-0.092,0.222-0.235,0.235c-1.101,0.152-2.813-0.307-3.523,0.235c-0.235,0-0.47,0-0.705,0
		c-2.076,1.134-4.626,1.794-6.813,2.819c-0.156,0-0.313,0-0.47,0c-0.078,0-0.156,0-0.234,0c-0.079,0-0.157,0-0.235,0
		c0.009,0.077,0.032,0.153,0.044,0.23c-0.018-0.001-0.025,0.007-0.044,0.005c0.012,0.218,0.047,0.412,0.097,0.592
		c0.045,0.173,0.105,0.33,0.175,0.479c0.721,2.608,2.488,5.292,3.018,8.325c0.392,0.313,0.364,1.044,0.704,1.41
		c-0.029,0.421,0.288,0.495,0.235,0.939c0.346,1.769,1.555,2.673,2.114,4.229c0.494,0.289,0.151,1.414,0.704,1.644
		c0,0.313,0,0.626,0,0.94c0.799,4.447,3.249,7.244,5.169,10.571c0.078,0,0.156,0,0.234,0c0,0.235,0,0.47,0,0.705
		c0.03,1.379,0.512,2.308,1.409,2.819c0.68-0.104,1.071-0.496,1.175-1.175c0.02-0.841,0.365-1.357,0.47-2.114
		c-0.043-0.2,0.092-0.222,0.235-0.235c0.398-0.62,0.695-1.34,0.704-2.349c1.099,0.077,1.017,1.333,2.114,1.41
		c0.528-0.099,0.007-1.247,0.47-1.41c1.027,1.636,2.074,3.251,3.289,4.698c0.51-0.04,0.106,0.833,0.235,1.175
		c0.678,0.316,1.192,0.824,1.409,2.114c0.548,0.47,0.392,1.645,0.939,2.114c-0.017,0.8,0.691,0.875,1.175,1.175
		c1.129-0.124,1.519-0.987,1.645-2.114c0.145-0.873,0.56-1.476,0.704-2.349c0-0.078,0-0.157,0-0.235
		c0.551-0.077,0.229-1.025,0.705-1.175c0.686-0.646,0.68-1.982,1.175-2.819c0.471,2.348,0.142,5.497,0.704,7.752
		c0,0.548,0,1.097,0,1.645c-0.638-0.168,0.168,1.108-0.47,0.939c0.037,0.277-0.04,0.667,0.235,0.705
		c0.05-0.264,0.261-0.366,0.47-0.47c0.078,0,0.156,0,0.234,0c0.646-0.644,0.707-2.464,1.88-1.879
		C341.405,261.619,342.067,261.577,342.029,260.833z M356.979,208.189c-0.056,0.581-0.111,1.147-0.151,1.669
		C356.868,209.336,356.923,208.77,356.979,208.189z M329.524,253.687c0.575,0.497,1.177,0.94,1.664,1.531
		C330.701,254.626,330.099,254.184,329.524,253.687z M331.256,255.306C331.256,255.306,331.256,255.306,331.256,255.306
		c0.003,0.008,0.005,0.017,0.008,0.026C331.261,255.324,331.259,255.314,331.256,255.306z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M368.339,240.162c0.079,0,0.157,0,0.235,0
		c0.769-0.719,1.793-1.183,2.349-2.114c0-0.079,0-0.157,0-0.235c1.383-1.123,2.337-2.675,3.289-4.229
		c1.474-2.677,3.342-4.959,3.759-8.692c0.275-0.038,0.198-0.428,0.234-0.705c0-1.409,0-2.819,0-4.229c-0.078,0-0.156,0-0.234,0
		c0.025-1.279-0.358-2.147-0.705-3.054c-0.53-1.662-1.372-3.013-3.054-3.523c-1.125-0.355-2.974-0.732-4.229-0.235
		c-0.678,0.575-1.339,1.167-1.645,2.114c-1.727,1.641-3.187,3.548-4.698,5.403c1.417-1.167,3.093-2.075,4.229-3.524
		c1.622-0.805,2.535-2.32,5.403-1.879c0.918,0.57,1.829,1.146,2.584,1.879c0.577,1.38,1.112,2.803,1.409,4.463
		c0.008,1.261,0.179,2.684-0.705,3.054c-0.078,0-0.156,0-0.234,0c-0.856-0.24-1.295-0.897-1.409-1.879
		c-0.533,0.25-0.09,1.477-0.235,2.114c0,0.157,0,0.313,0,0.47c-0.821,0.667-1.217,1.758-1.879,2.584
		c-0.003,0.467-0.642,0.298-0.47,0.939c-1.323,1.183-2.367,2.645-3.289,4.229c-0.014,0.143-0.035,0.278-0.235,0.235
		c0.062,0.688-0.53,0.722-0.47,1.409c-1.911,2.866-4.415,5.138-7.986,6.343c-0.545-0.075-0.902,0.038-1.175,0.235
		c-0.156,0-0.313,0-0.47,0c-1.669,0.734-5.54,2.306-3.289,4.698c0.716,0.01,0.914-0.496,1.409-0.705c0.915-0.26,1.7-0.65,2.35-1.175
		c0.739,0.113,0.918-0.334,1.409-0.47c0.916-0.337,1.944-0.561,2.584-1.174c1.07-0.105,1.745-0.604,2.584-0.94
		C366.733,241.218,367.751,240.904,368.339,240.162z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M357.063,231.705c0.472-1.172,0.904-2.385,0.939-3.993
		c0.505-0.749,0.65-1.855,1.175-2.584c0-0.078,0-0.156,0-0.235c0.913-0.653,1.324-1.807,2.349-2.349
		c0.083-0.309,0.414-0.369,0.47-0.705c-0.718,0.613-1.817,0.844-2.349,1.645c-0.078,0-0.156,0-0.235,0
		c-0.201-0.067-0.685-0.561-1.229-0.932c0,0,0,0,0,0c-0.054-0.063-0.111-0.114-0.173-0.155c-0.012-0.008-0.024-0.015-0.037-0.022
		c-0.063-0.035-0.128-0.061-0.199-0.065c-0.001,0-0.001,0-0.002,0c-0.652-0.321-1.316-0.297-1.647,0.939
		c-0.209,1.592-0.973,2.629-0.939,4.463c-0.4,0.774-0.208,2.141-0.705,2.819c-0.356,0.739-0.783,1.409-0.939,2.349
		c-0.644,1.119-2.561,2.083-1.88,3.758c0.079,0,0.157,0,0.235,0c0.097-0.295,0.303-0.48,0.705-0.47c0-0.078,0-0.157,0-0.235
		c1.028,0.01,1.449-0.586,2.114-0.94C355.814,234.214,356.686,233.207,357.063,231.705z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M55.909,324.259c0.569,0.762,1.432,1.23,2.114,1.879
		c-0.018-1.548-0.485-2.648-1.41-3.289C56.389,323.33,56.133,323.779,55.909,324.259z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M384.543,232.344c-0.901,1.468-1.827,2.911-2.579,4.528
		c-0.289,1.12-1.234,1.584-1.409,2.819c-0.66,0.75-0.807,2.013-1.175,3.054c-0.263,0.755-0.643,1.393-0.705,2.349
		c-0.463,0.554-0.553,1.482-0.704,2.349c-0.183,0.522-0.494,0.916-0.47,1.644c-0.461,0.636-0.634,1.559-0.705,2.584
		c-0.233,0.628-0.484,1.239-0.47,2.114c-0.604,0.648-0.484,2.021-0.939,2.819c1.373,0.039,2.38-2.315,3.758-3.054
		c0-0.235,0-0.47,0-0.705c0-0.157,0-0.313,0-0.47c0.414-0.683,0.536-1.657,0.705-2.584c0.512-1.681,1.319-3.066,1.645-4.933
		c0.59-0.742,0.868-1.795,1.175-2.819c0.701-1.1,0.605-2.997,1.174-4.228c0.044-2.697,1.453-4.028,1.88-6.343
		c0.426-0.755,1.336-2.258,0.939-3.054c-0.343,0.801-0.732,1.555-1.146,2.287c0,0-0.001,0.001-0.001,0.001
		c-0.072,0.076-0.146,0.171-0.222,0.279c-0.016,0.021-0.031,0.048-0.046,0.071c-0.063,0.093-0.126,0.192-0.19,0.304
		c-0.016,0.025-0.03,0.055-0.046,0.081c-0.067,0.119-0.136,0.243-0.205,0.377c-0.009,0.017-0.019,0.036-0.027,0.054
		c-0.077,0.15-0.155,0.307-0.234,0.471c0,0-0.001,0.001-0.001,0.002c-0.001,0.001-0.001,0.003-0.002,0.004
		C384.543,232.346,384.543,232.345,384.543,232.344z"/>
			<polygon fillRule="evenodd" clipRule="evenodd" fill="#E8E735" points="384.543,232.344 384.544,232.343 384.544,232.342 	" />
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M348.372,262.243c-0.351,1.137-0.995,1.98-1.175,3.289
		c-0.928,2.126-2.829,3.279-3.523,5.638c-0.316,0.467-0.448,1.119-0.705,1.645c0.9,0.901,2.484,1.118,3.759,1.645
		c1.633,0.011,2.335,0.953,2.818,2.114c0.448,0.101,0.591,0.506,1.175,0.47c1.525-7.558,4.355-13.811,5.873-21.377
		c0.464-0.554,0.553-1.482,0.705-2.349c0.36-1.049,0.662-2.156,0.704-3.523c0.229-0.007,0.183-0.288,0.235-0.47
		c-0.588-0.11-2.231,0.738-2.819,1.41c-1.012,0.162-1.442,0.906-2.349,1.174c-0.101,0.448-0.672,0.425-0.47,1.175
		c-0.566,0.096-0.511-0.429-1.175-0.235c-0.078,0-0.156,0-0.235,0c-0.482,0.378-0.545,1.178-0.47,2.114
		c-0.573,1.462-1.215,2.856-1.409,4.698C348.826,260.349,348.656,261.353,348.372,262.243z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M379.38,262.948c1.46-0.654,2.092-2.137,3.054-3.289
		c0.634-0.776,1.03-1.789,1.645-2.584c-0.17-0.719,0.405-0.691,0.235-1.41c0.517-0.533,0.319-2.344-0.471-2.349
		c-0.078,0-0.156,0-0.234,0c-0.84,0.413-1.227,1.279-1.645,2.114c-1.169,0.789-1.882,2.034-2.819,3.054c-0.078,0-0.156,0-0.234,0
		c-0.725,0.528-1.352,1.154-1.879,1.879C377.853,261.186,378.396,262.288,379.38,262.948z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M394.414,259.424c0.386,0.098,0.726,0.262,1.041,0.461
		c0.295,0.24,0.598,0.478,0.908,0.71c1.242,1.15,2.236,2.592,4.394,2.118c0.2-0.043,0.222,0.092,0.234,0.235
		c6.012,4.09,9.568,10.635,13.155,17.148c0.481,0.38,0.593,1.13,0.939,1.645c0.749,2.227,2.117,3.834,2.584,6.342
		c0.574-1.333-0.126-2.696-0.704-2.584c0.385,0.039,0.385-1.448,0-1.409c0-0.079,0-0.157,0-0.235
		c-0.151-1.415-0.539-2.593-1.175-3.523c0.112-0.739-0.335-0.918-0.47-1.409c-0.5-1.614-1.111-3.118-2.114-4.229
		c-0.319-1.168-1.04-1.936-1.645-2.819c-0.433-0.644-0.881-1.271-1.337-1.891c0.026,0.035,0.054,0.068,0.079,0.104
		c-1.747-2.484-3.531-4.932-5.789-6.905c0.189,0.045,0.233,0.236,0.47,0.235c-0.955-1.159-2.063-2.165-3.523-2.819
		c-0.079,0-0.157,0-0.235,0c-0.426-0.032-0.784-0.131-1.117-0.256c0.002,0.001,0.003,0.002,0.004,0.003
		c-0.002-0.001-0.004-0.002-0.006-0.004c0.001,0,0.001,0,0.002,0.001c-0.004-0.002-0.007-0.004-0.011-0.006
		c-0.58-0.367-1.17-0.724-1.782-1.059c-0.588-0.429-1.191-0.843-2.023-1.028c-1.137-0.351-1.979-0.995-3.288-1.174
		c-0.076-0.55-1.024-0.229-1.175-0.705C391.854,257.29,393.804,258.308,394.414,259.424z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M86.682,277.982c0,0.078,0,0.157,0,0.235
		c-0.824,1.211-1.112,2.959-1.644,4.463c-0.074,0.317-0.334,0.449-0.235,0.939c-0.557,0.619-0.773,1.576-0.94,2.584
		c-0.196,0.648-1.086,1.381-0.47,2.114c0.332,1.469,0.53,3.072,1.175,4.229c-0.075,0.545,0.038,0.902,0.235,1.174
		c-0.194,0.664,0.331,0.609,0.235,1.175c4.172-0.413,5.381,2.136,6.813,4.463c-0.075,0.544,0.038,0.902,0.235,1.174
		c0.083,1.092,0.301,2.048,0.705,2.819c-0.075,0.545,0.038,0.902,0.235,1.175c0.146,1.185,0.289,2.374,0.705,3.289
		c0,0.079,0,0.157,0,0.235c0.155,1.647-0.31,3.913,0.235,5.168c0,0.391,0,0.783,0,1.174c-0.079,1.567,0.223,2.753,0.705,3.758
		c0.534-1.971,1.327-3.684,1.644-5.873c0-0.078,0-0.157,0-0.235c0.602-1.157,0.065-3.191-0.47-3.994
		c-0.642-4.134-1.479-8.074-2.819-11.51c-0.224-1.32,0.655-1.538,0.939-2.349c0.715,0.068,0.324,1.242,0.94,1.409
		c0.669,3.012,1.452,5.908,2.349,8.692c0.169,0.927,0.291,1.901,0.705,2.584c-0.075,0.545,0.038,0.902,0.235,1.174
		c0.167,1.086,0.475,2.032,0.94,2.819c-0.018,0.41,0.052,0.731,0.235,0.94c0.822,2.702,1.42,5.627,2.819,7.752
		c0,0.078,0,0.157,0,0.235c0.424,0.829,0.279,2.227,0.939,2.819c0,0.079,0,0.157,0,0.235c0.472,1.485,0.885,3.03,1.645,4.229
		c-0.018,0.409,0.052,0.73,0.235,0.94c0.419,1.226,0.733,2.556,1.409,3.523c0.302,1.656,0.911,3.004,1.41,4.463
		c0.139,1.27,0.913,1.906,1.41,2.819c0,0.078,0,0.157,0,0.235c0.375,1.191,0.379,2.753,1.174,3.523
		c0.197-0.272,0.31-0.629,0.235-1.174c0.014-0.143,0.035-0.278,0.235-0.235c0.198-0.585,0.308-1.259,0.235-2.114
		c-0.297-1.347-0.767-2.521-1.409-3.523c-0.441-1.36-0.771-2.831-1.41-3.994c-0.537-2.595-1.627-4.637-2.349-7.047
		c-0.368-1.042-0.515-2.304-1.174-3.054c-1.582-4.682-3.422-9.106-4.463-14.33c-0.417-1.384-1.004-2.598-1.41-3.994
		c-0.162-2.03-0.883-3.501-1.409-5.168c-0.167-1.008-0.383-1.966-0.94-2.584c0.018-0.409-0.052-0.73-0.235-0.939
		c-0.481-1.711-1.755-2.63-2.819-3.759c0-0.078,0-0.156,0-0.235c-0.74-1.531-2.764-1.778-3.758-3.054
		c-0.219-1.817-0.719-3.354-1.175-4.933c-0.527-1.9-0.647-4.208-1.644-5.638C87.111,277.471,86.585,277.416,86.682,277.982z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M54.734,288.788c0.014-0.876-0.237-1.486-0.47-2.114
		c-0.38-2.752-1.296-4.968-1.409-7.987c-0.533-0.25-0.09-1.477-0.235-2.114c0-0.078,0-0.156,0-0.235
		c0.275-0.038,0.198-0.428,0.235-0.705c-0.792-2.282,0.959-4.049,1.879-5.403c0.377-1.345,1.845-1.601,2.349-2.819
		c-0.16-0.937-0.512-1.681-0.47-2.819c-0.119,0.079-0.244,0.164-0.367,0.246c-0.036-0.081-0.057-0.176-0.103-0.246
		c-0.139,0.221-0.297,0.431-0.465,0.636c-0.92,1.131-2.212,2.065-3.287,3.151c1.074-1.086,2.367-2.021,3.287-3.151
		c-3.11,2.181-6.898,5.712-5.643,11.814c0.472-2.348,0.532-5.106,1.879-6.578c0.026-0.6,0.787-0.466,1.41-0.47
		c0.106,1.125-0.17,1.866-0.705,2.349c-0.159,1.329-0.757,2.218-0.705,3.758c0.079,0,0.157,0,0.235,0c0,0.235,0,0.47,0,0.705
		c-0.05,1.381,0.145,2.518,0.47,3.523c0.106,1.225,0.344,2.318,0.705,3.289c0.04,1.056,0.187,2.006,0.47,2.819
		c0.128,0.341-0.274,1.214,0.235,1.174c-0.031,0.579-0.036,1.132,0.235,1.41c0.128,0.341-0.274,1.214,0.235,1.174
		C55.535,290.337,55.033,289.206,54.734,288.788z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M415.791,301.003c0-1.018,0-2.036,0-3.054
		c-0.506-0.198,0.088-1.498-0.47-1.644c0.031-0.579,0.035-1.132-0.235-1.41c-0.013-1.161-0.231-2.117-0.704-2.819
		c0-0.079,0-0.157,0-0.235c-0.494-1.934-0.994-3.861-1.88-5.403c0.113-0.739-0.334-0.918-0.47-1.41
		c-0.337-0.916-0.561-1.944-1.175-2.584c-0.316-1.562-1.059-2.699-1.644-3.994c0-0.078,0-0.157,0-0.235
		c0.065-0.379-0.223-0.404-0.235-0.705c-0.18-0.682-0.371-1.351-0.939-1.645c0,0.079,0,0.157,0,0.235
		c-1.104-1.715-2.3-3.338-3.523-4.933c-0.789-1.482-2.125-2.417-3.289-3.523c-0.447,0.164,0.317,1.069,0.705,1.174
		c0.409,0.688,0.693,1.499,1.409,1.879c0.09,0.771,0.687,1.036,0.939,1.645c0.662,0.826,1.059,1.917,1.88,2.584
		c0.616,1.575,1.447,2.937,2.113,4.463c-0.061,0.688,0.531,0.722,0.471,1.409c0,0.079,0,0.157,0,0.235
		c-0.037,0.584,0.368,0.728,0.47,1.175c1.28,1.851,2.008,4.256,3.288,6.107c0.605,0.256,0.206,1.517,0.705,1.879
		c0.086,1.088,0.166,2.183,0.47,3.054c0,1.332,0,2.663,0,3.994c0.078,0,0.156,0,0.235,0c0,0.313,0,0.626,0,0.939
		c0.048,0.735-0.159,1.725,0.234,2.114c0,0.705,0,1.41,0,2.114c0.078,0,0.156,0,0.235,0c0,0.078,0,0.157,0,0.235
		c0.225,0.714-0.503,2.382,0.47,2.349c-0.194-0.664,0.331-0.608,0.234-1.174c0.303-0.637,0.575-1.305,0.47-2.349
		C415.784,301.466,415.738,301.186,415.791,301.003z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M68.124,294.896c0.025,0.602,0.133,1.12,0.47,1.41
		c2.054,8.517,4.29,16.852,5.873,25.84c-0.18,1.561,0.332,0.976,0.94,0.47c0.338-0.131,0.573-0.366,0.705-0.705
		c0.379,0.066,0.404-0.222,0.705-0.235c0.491,0.1,0.622-0.161,0.94-0.235c-0.169-0.927-0.292-1.901-0.705-2.584
		c0-0.078,0-0.157,0-0.235c-0.071-1.025-0.245-1.948-0.705-2.584c0-0.079,0-0.157,0-0.235c-0.188-1.144-0.411-2.251-0.939-3.054
		c-0.045-1.364-0.603-2.216-0.94-3.289c-0.731-2.636-1.461-5.273-2.584-7.517c0-0.078,0-0.157,0-0.235
		c-0.515-1.286-0.973-2.628-1.879-3.524c0-0.078,0-0.156,0-0.235C69.474,296.835,68.904,295.759,68.124,294.896z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M66.714,326.843c-0.333-2.564-1.014-4.781-1.644-7.047
		c-0.322-1.949-0.696-3.845-1.41-5.403c-0.176-1.625-0.7-2.902-1.174-4.229c-0.124-0.738-0.412-1.311-0.705-1.879
		c-0.39,0.791-0.39,3.203,0,3.994c1.347,7.814,3.7,14.624,4.698,22.786c0.135,0.413-0.284,1.38,0.235,1.41c0.079,0,0.157,0,0.235,0
		c0.007-0.229,0.288-0.182,0.47-0.235c-0.072-0.542,0.678-0.261,0.705-0.705c0.313,0,0.626,0,0.939,0
		c0.073-0.935-0.542-1.18-0.47-2.114c-0.516-1.128-0.704-2.585-1.174-3.759C67.336,328.571,67.118,327.615,66.714,326.843z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M105.24,359.496c0.841-0.881,1.057-2.388,1.41-3.758
		c0.182-0.209,0.252-0.53,0.235-0.939c0.573-0.524,0.444-1.749,0.939-2.35c0.45-1.899,0.983-3.715,1.645-5.403
		c-0.495-0.231-1.389-1.411-1.645-0.47c-0.338-0.053-0.082-0.701-0.47-0.705c0-0.079,0-0.157,0-0.235
		c0.17-0.719-0.405-0.691-0.235-1.41c-0.573-0.523-0.444-1.749-0.94-2.349c-0.611-3.382-1.624-6.363-2.584-9.396
		c-0.151-0.866-0.241-1.794-0.705-2.349c-0.019-1.156-0.46-1.888-0.705-2.819c-0.58-1.769-0.583-4.116-2.584-4.463
		c-0.217-2.21-1.247-3.608-1.645-5.638c-0.67,0.426-0.757,1.435-0.939,2.349c-0.183,0.209-0.253,0.531-0.235,0.939
		c-0.578,0.675-0.708,1.798-1.41,2.349c0.205,1.205,1.65,1.168,1.41,2.819c0.349,0.199,0.34,0.756,0.47,1.174
		c0.529-0.098-0.035-1.288,0.705-1.174c0.875-0.249,0.758,0.494,1.175,0.705c0.199,1.602,1.26,2.341,1.409,3.994
		c1.371,3.64,2.202,7.82,3.524,11.51c0.303,1.107,0.625,2.194,1.174,3.054c-0.023,1.354,0.645,2.018,0.939,3.054
		c-0.307,1.012,0.667,0.743,0.94,1.174c-1.128,3.155-1.83,5.697-2.349,8.457c0,0.078,0,0.156,0,0.235
		c-0.219,0.172-0.356,0.427-0.47,0.705C104.512,358.97,104.826,359.284,105.24,359.496z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M58.493,348.925c0,0.079,0,0.157,0,0.235
		c0.165,1.558,0.565,2.88,1.879,3.289c0.327,0.683,1.159-0.066,1.409-0.235c-0.143-0.562,0.295-1.704-0.235-1.879
		c0.033-1.756-0.261-3.185-0.705-4.463c-0.017-0.923-0.114-1.766-0.47-2.349c0-0.079,0-0.157,0-0.235
		c-0.017-0.923-0.114-1.766-0.47-2.349c-0.262-2.635-0.937-4.857-1.644-7.047c-0.251-1.236-0.533-2.443-1.175-3.289
		c0-0.079,0-0.157,0-0.235c0.062-0.688-0.531-0.722-0.47-1.41c-0.693-0.325-0.951-1.085-1.645-1.41
		c0.02,0.372-0.113,0.896,0.235,0.94c0.056,2.863,1.175,5.196,1.409,7.987c0.247,2.933-1.416,5.621,0.235,8.222
		c-0.066,0.379,0.222,0.405,0.235,0.705c0.717-0.302,0.629-1.408,1.645-1.41c0.062-0.073,0.134-0.106,0.205-0.146
		c0.001,0,0.002,0,0.004,0c0.222-0.036,0.459-0.038,0.711-0.012c0,0,0.001,0,0.001,0c0.594,0.272,1.058,1.087,0.489,1.568
		C59.5,346.487,59.193,347.902,58.493,348.925z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M101.011,339.528c-0.221-1.658-1.188-2.57-1.409-4.229
		c-0.219-0.172-0.356-0.427-0.47-0.705c-0.033-0.438-0.155-0.785-0.47-0.939c-0.506,0.198-0.198,1.211-0.705,1.409
		c-0.127,1.537,0.145,2.674,0.235,3.994c0.079,0,0.157,0,0.235,0c0,0.078,0,0.157,0,0.235c-0.125,0.909,0.419,1.147,0.235,2.114
		c0.338,0.053,0.082,0.701,0.47,0.705c-0.075,0.545,0.038,0.902,0.235,1.175c-0.188,0.814,0.35,0.903,0.235,1.644
		C100.522,343.735,101.382,341.233,101.011,339.528z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M70.943,343.052c-0.118-1.604-0.764-2.682-1.174-3.994
		c0.075-0.545-0.038-0.902-0.235-1.174c-0.605,0.256-1.129,0.593-1.409,1.174c0.553,1.091,1.521,1.767,1.645,3.289
		c0,0.626,0,1.253,0,1.879c0.52,0.332,1.039,1.583,1.409,0.705C71.035,344.37,71.473,343.227,70.943,343.052z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M102.421,344.227c-0.084,1.169-0.658,1.848-0.705,3.054
		c0,0.079,0,0.157,0,0.235c-0.142,1.111-1.621,0.885-1.41,2.349c0.078,0,0.157,0,0.235,0c0,0.079,0,0.157,0,0.235
		c0.121,1.367-0.264,3.239,0.235,4.228c0.545,0.075,0.902-0.038,1.175-0.235c1.098-1.019,2.186-3.292,1.644-5.168
		c-0.024-1.385-0.423-2.396-0.705-3.523C102.925,344.818,102.935,344.261,102.421,344.227z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M103.83,363.724c0.003-0.467,0.641-0.299,0.47-0.939
		c-1.038-0.841-1.978-1.781-2.819-2.819c-0.37-0.021-0.184-0.6-0.705-0.47c-0.032,0.829-0.672,1.051-0.705,1.879
		C100.935,362.548,102.229,363.29,103.83,363.724z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M71.178,361.14c0,0.157,0,0.313,0,0.47
		c-0.232,1.329,0.844,1.349,1.41,1.879C72.761,362.305,71.805,359.984,71.178,361.14z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M61.781,384.866c0.796,3.511,1.424,7.189,2.584,10.336
		c0.017-3.463-1.768-7.158-2.349-10.806C61.788,384.403,61.834,384.684,61.781,384.866z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M63.426,417.049c-0.083-0.856-0.415-1.465-0.705-2.114
		c0-0.078,0-0.157,0-0.235c0-0.861,0-1.723,0-2.584c-1.296,0.74-2.362,1.71-3.289,2.819c0.152,1.023-0.306,2.655,0.235,3.289
		c-0.034,0.582-0.043,1.14,0.47,1.174c0.72-0.297,1.236-0.8,1.879-1.174C62.478,417.823,63.19,417.675,63.426,417.049z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M415.321,503.73c-1.328-0.769-2.493,0.418-2.584,1.645
		c0,0.078,0,0.156,0,0.235c-0.595,1.127-1.619,1.826-2.35,2.818c0,0.078,0,0.156,0,0.235c-1.53,1.288-3.484,2.152-4.933,3.523
		c-0.078,0-0.156,0-0.235,0c-0.323-0.01-0.516,0.11-0.704,0.234c-1.313,1.82-3.347,2.918-4.464,4.934
		c1.21,1.062,2.507,2.035,3.759,3.054c1.798-1.178,3.029-2.921,4.463-4.463c0.57-0.526,1.047-1.384,1.68-1.827
		c0.368-0.161,0.753-0.243,1.158-0.229c0.003,0,0.006-0.001,0.009-0.001c0.727,0.237,0.607,1.185,1.382,1.117
		c0-0.234,0-0.47,0-0.704c0.428-1.374,0.996-2.605,1.175-4.229c0.464-0.555,0.554-1.482,0.705-2.349c0-0.079,0-0.157,0-0.235
		c0.352-0.666,0.579-1.456,0.704-2.35c0-0.078,0-0.156,0-0.234C415.596,504.944,415.192,504.072,415.321,503.73z M406.027,518.162
		c0.523-0.661,1.042-1.366,1.577-2.013C407.07,516.797,406.551,517.501,406.027,518.162z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M406.16,522.289c0,0.078,0,0.156,0,0.234
		c0.881,0.685,1.664,1.468,2.349,2.35c0.2-0.044,0.222,0.091,0.235,0.234c0.375-0.329,0.6-0.81,0.704-1.409c0-0.078,0-0.157,0-0.235
		c0.806-1.309,1.23-2.998,1.645-4.698c0-0.078,0-0.156,0-0.234c0.517-0.579,0.521-1.671,0.939-2.35
		C410.262,517.466,408.452,520.743,406.16,522.289z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M398.173,518.765c-0.729,1.085,0.933,1.486,1.879,1.645
		C399.849,519.438,399.017,519.096,398.173,518.765z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M373.507,575.378c0.235,0,0.47,0,0.705,0
		c1.094,0.076,1.308-0.729,2.114-0.939c0.643-1.001,1.706-1.582,2.349-2.584c0.339,0.054,0.082,0.701,0.47,0.705
		c0.099-1.39,0.842-2.134,0.94-3.523c0.183-0.522,0.493-0.916,0.47-1.645c0.866-1.482,1.232-3.466,2.114-4.934
		c0.602-1.042,0.946-2.342,1.879-3.054c0.836-1.748,2.279-2.889,3.289-4.463c0-0.079,0-0.157,0-0.235
		c1.595-1.615,2.494-3.927,3.288-6.343c0.8-1.706,1.232-3.779,1.645-5.872c0.884-2.249,1.58-4.685,2.35-7.048
		c0.263-0.755,0.643-1.393,0.704-2.349c0.606-0.323,0.053-1.439,0-1.88c-0.229-0.006-0.182-0.287-0.234-0.47
		c-0.968,0.128-1.375,0.818-2.114,1.175c-0.589,0.508-1.451,0.741-1.879,1.409c0.043,0.2-0.092,0.222-0.235,0.235
		c-0.36,0.971-0.94,1.722-1.175,2.818c-0.524,0.65-0.914,1.436-1.174,2.35c-1.605,3.015-2.773,6.467-3.994,9.866
		c-0.613,0.64-0.838,1.668-1.175,2.584c-0.429,1.137-1.124,2.007-1.174,3.523c-0.466,0.788-0.772,1.733-0.939,2.819
		c-0.209,0.574-0.548,1.019-0.471,1.879c-0.977,2.703-3.126,4.233-4.463,6.577c0,0.079,0,0.157,0,0.235
		c-1.491,1.641-2.847,3.418-3.993,5.403c-0.209,0.417-0.656,0.597-0.47,1.409C371.733,573.874,372.39,575.471,373.507,575.378z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M393.005,764.716c0-0.078,0-0.156,0-0.235
		c-0.506-0.512-0.264-1.772-0.705-2.349c0.032-1.285-0.166-2.34-0.47-3.289c-0.186-1.224-0.239-2.579-0.705-3.523
		c-0.118-1.839-0.507-3.407-1.174-4.698c-0.332-0.843-1.017-1.333-1.41-2.114c-0.881-0.841-1.223-2.222-1.879-3.288
		c-0.69-2.961,0.269-5.257,1.175-7.282c0.318-0.073,0.194-0.589,0.234-0.94c0-0.548,0-1.096,0-1.644c-0.078,0-0.156,0-0.234,0
		c0-0.94,0-1.88,0-2.819c-0.535-0.482-0.008-2.028-0.47-2.584c-0.153-1.179,0.308-2.971-0.235-3.759c0-0.234,0-0.47,0-0.704
		c-0.151-1.023,0.307-2.656-0.234-3.289c0-0.235,0-0.47,0-0.705c0-0.939,0-1.879,0-2.818c-0.079,0-0.157,0-0.235,0
		c0-0.313,0-0.627,0-0.94c0-0.704,0-1.409,0-2.114c-0.527-0.358-1.154,1.187-1.879,1.41c-1.984,0.991-2.405,3.546-4.698,4.229
		c-0.46,0.258-0.78,1.863-0.235,2.349c-0.059,1.468,0.119,2.7,0.47,3.759c-0.032,0.973,0.065,1.814,0.47,2.349
		c0.311,1.606,0.211,2.861-0.234,4.229c-0.88,0.922-1.722,1.88-2.114,3.289c-0.252,0.609-0.571,1.151-0.47,2.114
		c-0.078,0-0.157,0-0.235,0c0.515,0.974,0.94,2.035,1.88,2.584c-0.172,0.641,0.467,0.473,0.47,0.939
		c0.279,0.738,0.793,1.243,0.939,2.114c0.837,1.513,1.869,2.829,2.349,4.698c-0.043,0.2,0.092,0.222,0.235,0.234
		c-0.039,1.371,0.41,2.253,0.234,3.759c0.319,0.073,0.195,0.589,0.235,0.94c0.15,0.945-0.306,2.497,0.235,3.054
		c0,0.234,0,0.47,0,0.704c0,1.487,0.152,2.823,0.47,3.994c-0.062,1.471,0.31,2.509,0.939,3.288c2.662,0,5.324,0,7.986,0
		C393.914,764.902,393.348,764.921,393.005,764.716z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M381.494,760.723c0.053-1.228-0.004-2.346-0.235-3.289
		c0-1.096,0-2.192,0-3.289c-0.078,0-0.156,0-0.234,0c0.126-2.553-0.339-4.516-1.409-5.872c-0.36-0.893-0.946-1.56-1.175-2.584
		c-0.598-0.343-0.455-1.425-1.175-1.645c0.111-0.737-0.632-0.621-0.705-1.175c-0.891-1.145-1.72-2.352-1.409-4.698
		c0.197-0.272,0.31-0.63,0.235-1.175c0.518-0.891,0.905-1.913,1.409-2.818c0.304-0.637,1.044-0.835,0.939-1.88
		c0.574-0.513,0.06-1.925-0.234-2.349c-0.151-1.571-0.311-3.135-0.705-4.463c0.023-0.964,0.046-1.926-0.235-2.584
		c-1.379,0.421-2.035,1.565-3.288,2.113c-0.271,0.278-0.267,0.831-0.235,1.41c-0.212,0.414-0.525,0.728-0.939,0.939
		c-0.945,0.15-2.498-0.306-3.054,0.234c-0.235,0-0.47,0-0.705,0c-0.19,0.045-0.233,0.236-0.47,0.235
		c-0.94,0.313-1.898,0.607-2.349,1.409c-1.216,0.137-1.881,1.472-1.41,2.584c-0.21,1.072,0.392,1.332,0.471,2.114
		c1.423,0.222,0.97,2.318,1.409,3.523c0.078,0,0.156,0,0.234,0c1.171-0.396,2.24-0.892,3.289-1.409
		c0.719-0.299,1.703-0.333,2.35-0.704c0.879,0.06,1.51,0.368,1.879,0.939c0.436,1.924-1.126,1.849-1.879,2.584
		c-0.723,0.061-1.057,0.51-1.645,0.704c-0.873,0.381-1.999,0.508-2.35,1.41c0.534,1.502,1.695,2.376,2.114,3.993
		c-0.101,1.196,0.36,1.832,0.705,2.584c0.5,2.005,3.755,1.256,3.759,3.759c0.188,2.082,1.313,3.228,1.174,5.638
		c0.547,2.037,0.08,5.089,0.235,7.517c0.183,0.209,0.252,0.531,0.234,0.94c0.157,0,0.313,0,0.47,0c1.049,0.415,3.641,0.356,4.698,0
		c0.188-0.439,0.393-0.86,0.471-1.41C381.577,762.988,382.035,761.355,381.494,760.723z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M104.3,312.279c-0.277-1.681-0.628-3.287-1.41-4.463
		c-0.456-1.815-0.83-3.711-1.645-5.168c0.315,2.034,0.9,3.798,1.645,5.403C103.213,309.607,103.682,311.018,104.3,312.279z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M311.726,199.992c3.117,0.43,7.176-1.413,6.107-5.168
		c-0.068-0.479-0.635-0.46-0.704-0.939c-0.546-0.159-0.629-0.781-1.175-0.94c0.105-1.482-1.572-0.271-2.349-0.47
		c-1.061,0.506-2.318,0.814-3.289,1.41c-1.107,0.693-2.41,1.191-2.819,2.584c-0.053,0.182-0.006,0.463-0.234,0.47
		c-0.067,1.964,1.892,2.83,3.054,2.819C311.034,199.587,311.173,199.997,311.726,199.992z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M410.857,310.165c0.545-1.178,0.081-3.365,0.235-4.933
		c-0.078,0-0.156,0-0.235,0c0-0.078,0-0.157,0-0.235c0-1.253,0-2.506,0-3.758c0.039-0.273-0.052-0.418-0.234-0.47
		c0.182-1.435-0.308-2.198-0.235-3.523c0-0.392,0-0.783,0-1.174c-0.049-1.752-0.409-3.193-0.939-4.463c0,0.235,0,0.47,0,0.705
		c-0.686-1.664-0.749-3.949-1.645-5.403c0.1-0.491-0.161-0.622-0.234-0.94c-0.65-2.09-1.372-4.109-2.35-5.873
		c0.113-0.739-0.334-0.918-0.47-1.41c-0.631-1.718-1.455-3.243-2.584-4.463c0-0.079,0-0.157,0-0.235
		c-1.152-1.745-2.033-3.76-3.523-5.168c0-0.078,0-0.156,0-0.235c-0.045-0.19-0.235-0.234-0.235-0.47c0-0.078,0-0.157,0-0.235
		c-0.531-1.113-1.241-2.047-2.114-2.819c0,0.078,0,0.157,0,0.235c-0.919-1.351-2.264-2.277-3.054-3.759
		c-0.685-0.881-1.468-1.664-2.349-2.349c-0.987-0.265-1.394-1.112-2.35-1.409c-0.313-0.235-0.709-0.387-0.939-0.705
		c-0.745,0.429-0.818,1.53-1.174,2.349c-0.752,0.58-1.012,1.651-1.645,2.349c0,0.079,0,0.157,0,0.235
		c-0.589,0.194-0.743,0.823-1.175,1.174c-0.339,0.131-0.573,0.366-0.705,0.705c2.65,3.692,4.816,7.869,7.048,11.98
		c0.55,0.86,0.871,1.947,1.174,3.054c0.542,2.434,1.406,4.545,2.584,6.342c0.35,0.199,0.341,0.756,0.471,1.175
		c0.696,2.513,1.093,5.328,2.818,6.812c0.513-0.054,0.753-1.571,0.235-1.879c-0.079,0-0.157,0-0.235,0c0-0.079,0-0.157,0-0.235
		c0-0.391,0-0.783,0-1.174c-0.378-3.459-1.072-6.602-2.114-9.396c-0.199,0.043-0.222-0.091-0.234-0.235
		c-0.001-0.939-0.47-1.41-0.47-2.349c-0.585-2.939-1.442-5.605-2.584-7.987c-0.507-0.825-0.839-1.824-1.175-2.819
		c-0.04-1.526-1.255-1.877-1.175-3.524c2.858,0.131,3.811,4.167,5.168,6.578c0.078,0,0.156,0,0.235,0
		c0.847,3.147,2.378,5.609,3.288,8.692c0.285,0.89,0.455,1.894,0.94,2.584c0,0.078,0,0.156,0,0.235
		c0.459,1.811,0.884,3.657,1.644,5.168c-0.1,0.491,0.161,0.622,0.235,0.939c0.167,1.086,0.475,2.031,0.939,2.819
		c-0.1,0.491,0.161,0.622,0.235,0.94c0.167,1.085,0.474,2.031,0.939,2.819c0,0.078,0,0.157,0,0.235c0,0.235,0,0.47,0,0.705
		c0.401,1.792,0.948,3.437,1.645,4.933c0.338,0.053,0.082,0.701,0.47,0.705c0.25,1.942,0.673,3.712,1.175,5.403
		c0.157,1.408,0.464,2.667,0.939,3.758c-0.165,1.026,0.399,1.323,0.234,2.349c0.15,0.868-0.304,2.34,0.235,2.819
		c0.078,0,0.156,0,0.234,0c0,0.079,0,0.157,0,0.235c0,0.861,0,1.723,0,2.584c0,0.079,0,0.157,0,0.235
		c-0.665-0.039,0.195,1.448-0.47,1.409c-0.351,2.177-0.999,5.235-1.174,6.813c0.104,0.538,1.421,1.063,1.879,0.47
		c0.003-0.467,0.641-0.299,0.47-0.939c0-0.079,0-0.157,0-0.235c1.373-3.247,1.896-7.344,2.114-11.746c0-0.078,0-0.157,0-0.235
		c0.54-0.556,0.084-2.108,0.234-3.054C410.857,310.634,410.857,310.399,410.857,310.165z M384.317,265.836
		c0.271,0.441,0.587,0.899,0.917,1.358C384.902,266.735,384.589,266.278,384.317,265.836z M383.63,264.459
		C383.63,264.459,383.631,264.459,383.63,264.459c0.794,1.019,1.55,2.073,2.256,3.179
		C385.18,266.532,384.423,265.477,383.63,264.459z M408.744,299.359c-0.079,0-0.157,0-0.235,0c-0.223-1.422-0.754-2.535-1.175-3.759
		c-0.167-1.085-0.474-2.031-0.939-2.819c0.1-0.491-0.161-0.622-0.234-0.939c-0.168-1.086-0.475-2.032-0.94-2.819
		c0-0.235,0-0.47,0-0.705c-0.07-1.26-0.479-2.184-0.939-3.054c-0.078,0-0.156,0-0.234,0c0-0.078,0-0.157,0-0.235
		c0-0.235,0-0.47,0-0.705s0-0.47,0-0.705c1.823,2.718,2.455,6.627,3.758,9.866c0.275,0.038,0.198,0.429,0.235,0.705
		c0.083,1.091,0.302,2.047,0.705,2.819c0.078,0,0.156,0,0.234,0c0,0.392,0,0.783,0,1.174
		C408.851,298.525,409.253,299.398,408.744,299.359z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M95.608,360.201c0.033-0.906,0.794-1.085,0.705-2.114
		c1.448-1.136,0.591-2.333,0.267-3.988c-0.028-0.228-0.059-0.458-0.091-0.691c-0.028-0.375-0.02-0.77,0.059-1.194
		c0-0.861,0-1.723,0-2.584c-0.665,0.039,0.195-1.448-0.47-1.409c0-0.079,0-0.157,0-0.235c0-0.392,0-0.783,0-1.175
		c-0.452-1.27-0.718-2.727-0.939-4.229c-0.638,0.168,0.168-1.107-0.47-0.939c0-0.392,0-0.783,0-1.174
		c-0.51,0.04-0.106-0.833-0.235-1.175c-0.536-0.326-0.088-1.635-0.235-2.349c-0.815-2.346-0.009-5.104-0.705-7.282
		c0-0.47,0-0.939,0-1.409c-0.374-0.722-0.27-1.923-0.705-2.584c-0.197-0.273-0.31-0.63-0.235-1.175
		c-0.338-0.053-0.082-0.701-0.47-0.705c0-0.079,0-0.157,0-0.235c0-0.313,0-0.626,0-0.94c-0.679-0.104,0.208-1.775-0.47-1.879
		c0-0.47,0-0.94,0-1.41c-0.228-0.006-0.182-0.288-0.235-0.47c0-1.175,0-2.349,0-3.524c0-1.331,0-2.662,0-3.993
		c-0.078,0-0.156,0-0.235,0c0-0.47,0-0.94,0-1.41c0-0.705,0-1.409,0-2.114c0.018-0.878-0.099-1.624-0.47-2.114
		c0-0.078,0-0.156,0-0.235c-0.068-1.263-0.247-2.416-0.705-3.289c-0.112-1.532-0.777-2.512-1.41-3.523
		c-0.159-0.546-0.781-0.629-0.939-1.175c-0.481,0.38-1.54,0.183-1.879,0.705c-0.491-0.099-0.622,0.161-0.939,0.235
		c1.4,4.209,1.393,9.687,2.349,14.33c0.349,1.694,1.976,3.256,0.705,5.403c2.15,6.698,2.987,14.709,4.463,22.082
		c0.154,1.646-0.311,3.912,0.235,5.168c0.683,2.605,0.002,6.575,1.174,8.691c1.027,0.812-0.036,1.872-0.235,2.819
		c-1.329,5.562-3.917,9.864-4.933,15.739c0.005,0.7-0.022,1.431,0.705,1.409c-0.043-0.2,0.092-0.221,0.235-0.235
		c2.075-4.346,4.201-8.641,5.873-13.39C95.331,360.235,95.465,360.214,95.608,360.201z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M374.682,324.494c-1.316-0.722-3.319-2.002-4.698-1.879
		c0.353,0.353,0.707,0.703,1.175,0.94c0.452,0.331,0.945,0.621,1.645,0.705c0.372,0.724,1.45,0.742,1.879,1.41
		c0.663,0.036,0.589-0.664,0.939-0.94c-0.3-0.013-0.325-0.301-0.704-0.235C374.838,324.494,374.76,324.494,374.682,324.494z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M78.225,324.259c-0.664-0.194-0.608,0.331-1.174,0.235
		c-0.123,0.348-0.348,0.592-0.47,0.939c1.141,2.618,1.513,6.004,2.114,9.162c0.149,0.791-0.303,2.182,0.235,2.584
		c0.75-0.268,1.1-0.936,1.175-1.879c0-0.078,0-0.156,0-0.235c0.2,0.043,0.222-0.091,0.235-0.235c0-0.783,0-1.566,0-2.349
		c0.074-1.327-0.062-2.444-0.47-3.289c0.07-1.401-0.853-1.81-0.94-3.054c-0.326-0.145-0.035-0.904-0.47-0.94
		C78.56,324.708,78.299,324.577,78.225,324.259z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M72.822,383.926c0.704-0.627,0.45-2.212,0.939-3.054
		c0.697-1.809,1.629-3.382,2.114-5.403c0.349-0.2,0.505-0.591,0.47-1.175c0.079,0,0.157,0,0.235,0
		c0.04-0.665,0.431-0.979,0.47-1.644c0-0.079,0-0.157,0-0.235c0.47-0.313,0.523-1.043,0.705-1.645c0-0.078,0-0.157,0-0.235
		c0.604-1.432,0.856-3.215,1.409-4.698c0-0.078,0-0.157,0-0.235c0.708-0.857,1.068-2.064,1.645-3.054
		c0.101-0.447,0.506-0.59,0.47-1.175c1.134-2.233,2.689-4.045,2.819-7.282c-0.506-0.198,0.088-1.498-0.47-1.645
		c-0.028-0.676,0.128-1.538-0.235-1.879c0.028-1.125-0.066-2.126-0.47-2.819c0-0.079,0-0.157,0-0.235
		c-0.009-1.4-0.618-2.202-0.939-3.289c-0.276-1.212-0.458-2.518-0.705-3.758c-0.348-0.272-0.592-0.272-0.939,0
		c-0.235,0-0.47,0-0.705,0c-0.25,0.533-1.477,0.089-2.114,0.235c-0.303-0.324-1.021-0.232-1.645-0.235
		c0.043,0.2-0.092,0.221-0.235,0.235c-0.951,0.075-2.411,0.867-1.645,1.879c0.631,2.631-0.842,3.418-0.939,5.873
		c-0.349,0.434-0.45,1.116-0.47,1.879c-0.313,0.236-0.874,0.222-0.94,0.705c-0.078,0-0.156,0-0.235,0
		c-0.63,0.309-1.249,0.631-1.879,0.939c-0.271,0.59-0.936,0.787-0.939,1.645c-1.193,2.174-2.311,4.423-1.879,8.222
		c0.851-0.073,0.426,0.711,0.47,2.114c-0.054,0.916,0.505,1.218,0.705,1.879c0.461,1.184,1.563,1.726,2.584,2.349
		c1.072,0.729,2.369,1.233,3.993,1.41c1.294,0.35,3.087,0.202,2.584,2.349c-0.209,0.104-0.42,0.206-0.47,0.47
		c-0.078,0-0.156,0-0.235,0c-0.626,0-1.253,0-1.879,0c-0.156,0-0.313,0-0.47,0c-0.078,0-0.156,0-0.235,0
		c-1.344-0.613-3.038-0.877-4.229-1.645c-0.078,0-0.156,0-0.235,0c-1.23-0.962-2.372-2.013-3.523-3.054c0-0.079,0-0.157,0-0.235
		c-0.65-0.916-0.804-2.328-1.41-3.289c0-0.078,0-0.156,0-0.235c0-0.235,0-0.47,0-0.705c-1.212-0.011-1.111,2.035-0.47,2.584
		c0.876,4.057,2.178,7.689,3.054,11.746c1.262,3.906,2.393,7.943,3.054,12.45C71.399,388.455,72.015,386.095,72.822,383.926z
		 M74.937,364.194c-0.544,1.072-1.669,2.381-3.289,1.645c-0.078,0-0.156,0-0.235,0c-1.339-0.54-2.374-1.384-2.349-3.289
		c-0.078,0-0.156,0-0.235,0c0-0.079,0-0.157,0-0.235c-0.061-1.314,0.043-2.463,0.705-3.054c0.528-0.604,1.082-0.879,1.626-0.934
		c0.204-0.009,0.412,0.001,0.62,0.027c1.573,0.329,2.94,2.395,3.158,3.96C74.937,362.941,74.937,363.567,74.937,364.194z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M69.063,392.618c0.059-1.077-1.063-0.973-0.939-2.114
		c-0.654,0.106,0.184-1.28-0.47-1.174c0.056-0.996-0.216-1.664-0.47-2.349c-0.241-1.638-0.542-3.216-1.174-4.463
		c0-0.079,0-0.157,0-0.235c-0.151-0.867-0.241-1.795-0.705-2.349c-0.112-1.375-0.559-2.417-0.94-3.524
		c-0.151-0.866-0.241-1.794-0.705-2.349c0-0.078,0-0.156,0-0.235c-0.151-0.866-0.241-1.794-0.705-2.349
		c-0.034-1.141-0.371-1.979-0.705-2.819c0-0.235,0-0.47,0-0.705c-0.665-1.103-0.86,1.19-1.174,1.879
		c-0.268,0.515-0.606,0.959-0.47,1.879c0.167,1.086,0.474,2.032,0.939,2.819c0,0.078,0,0.156,0,0.235
		c0.167,1.085,0.475,2.031,0.94,2.819c0.114,0.982,0.369,1.823,0.705,2.584c0.151,0.867,0.241,1.795,0.705,2.349
		c-0.075,0.544,0.038,0.902,0.235,1.174c0.151,0.867,0.241,1.795,0.705,2.349c0.063,1.19,0.401,2.104,0.705,3.054
		c0.332,1.469,0.53,3.072,1.174,4.228c-0.075,0.545,0.038,0.902,0.235,1.175c-0.17,0.718,0.405,0.691,0.235,1.409
		c0.16,0.046,0.301,0.029,0.43-0.028c0.015,0.008,0.023,0.022,0.04,0.028c0.008-0.019,0.015-0.04,0.022-0.059
		c0.694-0.4,0.962-2.146,1.387-2.995C69.063,392.775,69.063,392.696,69.063,392.618z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M124.972,576.318c-0.288-1.748-0.995-3.077-1.645-4.464
		c-0.26-0.914-0.649-1.699-1.174-2.349c0.066-0.38-0.222-0.404-0.235-0.705c-0.607-1.586-1.372-3.013-2.349-4.229
		c0-0.078,0-0.156,0-0.234c-0.656-1.146-1.168-2.434-2.114-3.289c-0.401-1.321-1.24-2.205-1.879-3.289
		c-1.046-1.615-1.93-3.395-3.289-4.698c-1.285-2.316-2.86-4.343-4.698-6.107c0.054,1.59,0.505,2.784,0.939,3.993
		c0.151,0.867,0.241,1.795,0.705,2.35c-0.078,0.86,0.261,1.305,0.47,1.879c0.167,1.086,0.474,2.031,0.939,2.819
		c-0.1,0.491,0.161,0.622,0.235,0.939c-0.194,0.664,0.331,0.608,0.235,1.175c0.508,0.666,0.586,1.764,1.175,2.349
		c-0.1,0.491,0.161,0.622,0.235,0.939c0.39,1.567,0.742,3.174,1.41,4.464c0,0.078,0,0.156,0,0.234c0,0.235,0,0.47,0,0.705
		c0.541,0.633,0.083,2.266,0.235,3.289c-0.394,0.39-0.187,1.379-0.235,2.114c-0.542,0.633-0.083,2.266-0.235,3.288
		c0,0.078,0,0.157,0,0.235c-0.541,0.633-0.083,2.266-0.235,3.288c0,0.079,0,0.157,0,0.235c-0.539,0.479-0.085,1.951-0.235,2.819
		c0,0.078,0,0.156,0,0.234c-0.078,0-0.156,0-0.235,0c0,0.783,0,1.566,0,2.35c0,0.078,0,0.156,0,0.234
		c-0.536,0.326-0.087,1.635-0.235,2.35c0,0.078,0,0.156,0,0.234c-0.538,0.402-0.086,1.793-0.235,2.584
		c-0.663,1.217-0.361,3.398-1.645,3.994c-0.726-0.136-0.826-0.897-0.705-1.88c0-0.156,0-0.313,0-0.47
		c0.536-0.325,0.087-1.635,0.235-2.349c0.339-0.758,0.222-1.971,0.235-3.054c0.229-0.007,0.183-0.288,0.235-0.471
		c0-0.939,0-1.879,0-2.818c0-0.078,0-0.157,0-0.235c0.079,0,0.157,0,0.235,0c0-0.47,0-0.939,0-1.409c0-1.175,0-2.35,0-3.523
		c0.135-0.413-0.284-1.381,0.235-1.41c0-0.391,0-0.782,0-1.174c-0.536-0.326-0.088-1.636-0.235-2.35c0.079,0,0.157,0,0.235,0
		c-0.151-0.945,0.305-2.498-0.235-3.054c0-0.783,0-1.565,0-2.349c-0.228-0.007-0.182-0.288-0.235-0.47
		c0.148-1.715-1.146-1.987-1.174-3.524c0.038-0.272-0.052-0.418-0.235-0.47c-0.214-2.213-0.809-4.046-1.41-5.872
		c-0.333-1.468-0.685-2.917-1.409-3.994c-0.326-1.789-1.036-3.192-1.645-4.698c-0.372-1.194-0.707-2.426-1.41-3.288
		c0-0.079,0-0.157,0-0.235c-0.962-3.657-3.913-5.327-5.638-8.222c0.073-0.935-0.542-1.18-0.47-2.114
		c-0.517-0.579-0.522-1.671-0.94-2.35c0-0.156,0-0.313,0-0.47c-0.748-0.348-1.874-0.318-2.114-1.174c-0.079,0-0.157,0-0.235,0
		c-0.525-1.434-1.033-2.883-1.645-4.229c-0.597-1.83-1.085-3.77-1.879-5.403c0-0.078,0-0.156,0-0.234
		c-0.151-0.867-0.241-1.795-0.705-2.35c0-0.078,0-0.156,0-0.234c-0.249-1.239-0.451-2.524-0.94-3.524
		c-0.488-2.644-1.022-5.242-1.644-7.752c-0.237-3.051-1.083-5.494-1.175-8.691c-0.544-1.1-0.081-3.208-0.235-4.698
		c0-0.078,0-0.156,0-0.235c0.079,0,0.157,0,0.235,0c0-1.174,0-2.349,0-3.523c0-0.078,0-0.156,0-0.234
		c0.543-0.866,0.082-2.737,0.235-3.994c0.512-0.491-0.117-0.67,0-1.409c0.275-0.038,0.198-0.428,0.235-0.705
		c0-2.349,0-4.698,0-7.047c0-0.626,0-1.253,0-1.879c-0.228-0.007-0.182-0.288-0.235-0.47c0-0.157,0-0.313,0-0.471
		c0-0.939,0-1.879,0-2.818c-0.078,0-0.156,0-0.235,0c0-0.705,0-1.409,0-2.114c-0.451-2.603-0.994-5.114-1.409-7.752
		c-0.611-0.485-0.329-1.864-0.94-2.35c0-0.078,0-0.156,0-0.234c-1.618-5.664-3.558-11.007-5.873-15.974
		c-0.272-1.451-0.91-2.535-1.41-3.759c-0.439-1.91-1.454-3.244-1.879-5.168c-1.268-0.639-1.845,1.228-2.819,1.645
		c-2.491,2.834-6.658,3.99-9.866,6.107c-0.869-0.164-1.038,0.371-0.939,1.175c1.191,2.41,2.1,5.104,3.289,7.517
		c0.585,1.06,1.017,2.272,1.879,3.054c0.211,0.964,0.719,1.631,1.175,2.35c0.353,0.979,0.667,1.995,1.409,2.584
		c0,0.078,0,0.156,0,0.234c1.385,1.982,1.666,5.069,2.584,7.518c-0.075,0.545,0.038,0.901,0.235,1.175
		c0.223,2.204,0.669,4.186,1.41,5.872c-0.1,0.491,0.161,0.622,0.235,0.939c-0.044,0.19-0.236,0.234-0.235,0.471
		c-1.624,0.292-2.133-0.53-2.819-1.175c0.014-0.876-0.237-1.486-0.47-2.114c-1.179-0.701-1.055-2.704-2.114-3.523
		c-0.709-0.362-0.618,1.215-0.235,1.174c0.156,3.604,0.523,6.994,1.644,9.632c0.281,0.815,0.368,1.824,0.94,2.349
		c-0.1,0.491,0.161,0.622,0.235,0.939c0.284,0.891,0.455,1.895,0.939,2.585c1.243,5.412,2.608,10.703,4.229,15.738
		c0.573,2.167,1.149,4.332,2.114,6.108c0.042,1.054,0.488,1.703,0.939,2.349c0.803,1.312,1.545,2.683,2.584,3.759
		c0,0.078,0,0.156,0,0.234c0.813,1.224,1.37,2.702,2.349,3.759c-0.066,0.38,0.222,0.404,0.235,0.705
		c0.99,1.672,1.569,3.756,2.819,5.168c-0.066,0.379,0.222,0.404,0.235,0.704c0.811,1.461,1.408,3.135,2.349,4.464
		c-0.036,0.584,0.369,0.728,0.47,1.175c0.26,0.914,0.649,1.699,1.174,2.349c4.089,10.084,6.251,22.095,8.692,33.827
		c0.016,0.923,0.113,1.766,0.47,2.349c1.127,6.859,3.822,12.152,4.933,19.028c0,0.939,0,1.879,0,2.818
		c0.147,0.715-0.301,2.023,0.235,2.35c0,0.548,0,1.096,0,1.645c0.156,3.367-0.313,7.359,0.235,10.336
		c1.384-0.132,1.246,1.26,2.349,1.409c-0.043-0.2,0.092-0.222,0.235-0.234c1.131-0.122,1.1-1.407,1.645-2.114
		c0.619-1.261,1.941-1.817,1.879-3.759c0.584-0.668,0.636-1.87,0.939-2.819c0.271-0.277,0.266-0.83,0.235-1.409
		c0.714-1.009,1.227-2.219,1.645-3.523c0-0.078,0-0.157,0-0.235c0.536-0.716,0.584-1.921,0.939-2.818c0-0.078,0-0.157,0-0.235
		c0.634-0.932,0.629-2.503,0.939-3.759c0.354-0.429,0.25-1.316,0.705-1.645c0.047-1.127,0.628-1.72,0.47-3.054
		c0.538-0.401,0.086-1.793,0.235-2.584c0-0.078,0-0.156,0-0.234c0.771-2.361,0.14-6.124,2.584-6.813c0.313,0,0.626,0,0.939,0
		c1.429,0.529,1.665,2.25,2.349,3.523c0,0.078,0,0.157,0,0.235c0.249,1.239,0.452,2.523,0.94,3.523c0,0.078,0,0.156,0,0.234
		c-0.018,0.409,0.052,0.73,0.235,0.94c1.072,1.022,0.83-2.205,0.47-2.584C124.972,576.631,124.972,576.475,124.972,576.318z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M395.823,521.584c-0.104-0.342-0.206,0.023-0.47,0
		c-1.232,1.429-2.563,2.76-3.993,3.993c-3.083,2.946-6.559,5.501-9.631,8.457c-0.38-0.066-0.405,0.222-0.705,0.234
		c-1.434,0.786-4.297,1.305-3.759,3.289c0.491,0.627,1.122-0.585,1.879-0.47c0.7-0.632,1.753-0.91,2.585-1.409
		c4.393-2.576,8.813-5.124,12.92-7.987c1.029-0.85,1.97-1.789,2.818-2.818c0.403-0.537,0.692-1.188,1.175-1.645
		c-0.171-0.534-0.707-0.703-1.175-0.939C396.927,522.047,396.711,521.48,395.823,521.584z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M407.804,526.986c-0.994-0.884-2.315-3.685-3.523-2.818
		c1.154,0.725,1.8,1.959,2.819,2.818c0.078,0.549,0.24,1.013,0.704,1.175C407.665,527.412,408.245,527.435,407.804,526.986z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M405.455,529.806c-0.674-1.676-1.674-3.024-3.054-3.993
		c-1.655,0.302-2.267,1.648-3.289,2.584c-0.083,0.309-0.413,0.369-0.47,0.704c0.043,0.2-0.092,0.222-0.235,0.235
		c0.123,0.348,0.348,0.592,0.471,0.939c0.848,1.684,0.398,4.324-0.235,5.873c-0.42,1.537-1.253,2.662-1.409,4.463
		c-0.528,0.803-0.752,1.911-0.94,3.054c0,0.079,0,0.157,0,0.235c-0.397,0.619-0.695,1.34-0.704,2.349
		c-0.384,1.262-0.934,2.355-1.175,3.759c-1.636,4.002-3.901,7.374-6.577,10.336c-0.968,1.146-1.457,2.771-2.35,3.994
		c-0.039,0.665-0.431,0.979-0.47,1.644c0,0.079,0,0.157,0,0.235c-0.571,0.524-0.659,1.533-0.939,2.349
		c-0.183,0.21-0.253,0.531-0.235,0.94c-0.541,1.024-0.93,2.202-1.174,3.523c-0.183,0.521-0.494,0.916-0.47,1.645
		c-0.541,0.712-0.652,1.854-0.94,2.818c-0.209,0.574-0.547,1.019-0.47,1.88c-0.451,0.958-0.748,2.07-0.939,3.288
		c-0.254,0.686-0.525,1.354-0.47,2.35c-0.54,1.025-0.521,2.611-0.939,3.759c0,0.234,0,0.47,0,0.704
		c-0.324,0.303-0.232,1.021-0.235,1.645c-0.375,0.643-0.483,1.553-0.47,2.584c-0.462,1.652-0.628,3.601-0.704,5.638
		c-0.079,0-0.157,0-0.235,0c-0.108,1.146,0.246,2.752-0.235,3.523c0,1.019,0,2.036,0,3.055c0,0.156,0,0.313,0,0.47
		c-0.078,0-0.156,0-0.234,0c0,0.783,0,1.565,0,2.349c0,0.078,0,0.157,0,0.235c-0.401,2.339-0.519,4.962-0.705,7.517
		c-0.446,0.572-0.07,1.966-0.47,2.584c0.106,1.28-0.419,1.931-0.234,3.289c-0.53,0.331-0.193,1.53-1.175,1.409
		c-0.916,0.824-1.735-0.446-2.114-0.939c0-0.47,0-0.939,0-1.409c0.367-1.042,0.46-2.359,0.47-3.759
		c0.436-0.504,0.158-1.721,0.235-2.584c0.537-0.402,0.086-1.793,0.234-2.584c0-0.156,0-0.313,0-0.47
		c0.539-0.479,0.085-1.951,0.235-2.819c0-0.156,0-0.313,0-0.47c0.529-0.175,0.092-1.317,0.234-1.879c0-0.627,0-1.253,0-1.88
		c0-0.234,0-0.47,0-0.704c0.541-0.556,0.084-2.108,0.235-3.054c0-0.235,0-0.471,0-0.705c0.078,0,0.156,0,0.234,0
		c0-0.235,0-0.47,0-0.705c0-0.548,0-1.096,0-1.645c0.197-0.272,0.311-0.629,0.235-1.174c0.109-2.631,0.83-4.651,0.939-7.282
		c0.444-1.672,1.16-4.318,0.939-5.873c-0.584,2.548-1.537,4.728-1.879,7.518c-0.578,1.85-1.056,3.799-1.175,6.107
		c-0.356,0.583-0.453,1.426-0.47,2.349c-1.369,5.287-2.389,10.923-3.523,16.444c-0.464,0.554-0.554,1.482-0.705,2.349
		c0,0.078,0,0.157,0,0.235c-0.199-0.044-0.222,0.091-0.234,0.234c-0.147,1.009,0.209,1.514,0.704,1.88
		c1.348,0.842,2.43,2.948,3.759,3.054c0.742,1.607,1.941,2.757,3.054,3.993c1.128,0.517,1.203,2.086,2.819,2.114
		c0.348,0.272,0.592,0.272,0.939,0c0.918-0.179,0.907-1.286,1.879-1.409c0.079,0,0.157,0,0.235,0
		c0.421,0.029,0.495-0.288,0.939-0.235c0-0.939,0-1.879,0-2.818c0-0.157,0-0.313,0-0.47c0.53-0.176,0.092-1.318,0.235-1.88
		c0.136-1.116,0.339-2.166,0.234-3.523c0-0.078,0-0.157,0-0.235c0.536-0.325,0.088-1.635,0.235-2.349c0-0.078,0-0.157,0-0.235
		c0.535-0.325,0.087-1.635,0.234-2.349c-0.029-0.422,0.288-0.496,0.235-0.939c0-0.47,0-0.94,0-1.41
		c0.533-0.25,0.089-1.477,0.234-2.114c0.139-1-0.169-1.554-0.234-2.349c-0.043-0.2,0.092-0.222,0.234-0.235
		c0.534-3.537,1.836-6.308,2.114-10.101c0.843-2.29,1.264-5.001,1.88-7.518c0.197-0.272,0.31-0.63,0.234-1.174
		c1.104-2.813,1.977-5.854,2.819-8.927c0.342-0.989,0.797-1.866,0.939-3.054c0.85-1.5,1.305-3.394,1.879-5.168
		c0-0.079,0-0.157,0-0.235c0.825-1.211,1.113-2.959,1.645-4.463c0.183-0.21,0.253-0.531,0.235-0.94
		c0.824-1.211,1.112-2.959,1.645-4.463c0.183-0.522,0.493-0.916,0.47-1.645c0.69-0.327,0.326-1.71,0.939-2.114
		c-0.419-0.629-0.636,0.912-1.175,0.939c-1.148,2.376-2.449,4.598-4.933,5.639c-2.043-2.713,1.58-4.898,2.349-7.048
		c0-0.078,0-0.156,0-0.234c1.241-1.343,2.112-3.057,3.054-4.698c0-0.079,0-0.157,0-0.235c1.116-1.233,1.761-2.938,2.584-4.463
		c0.732-1.304,1.251-2.821,2.114-3.994c0.06-0.802,0.594-1.128,0.47-2.114c0-0.078,0-0.156,0-0.234
		c0.473-0.702,0.491-1.858,0.705-2.819c0.801-1.234,2.379-1.692,2.349-3.759c0.557-0.618,0.773-1.575,0.94-2.584
		c0.218-1.235-0.794-1.241-0.94-2.113C405.49,530.084,405.469,529.949,405.455,529.806z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M361.057,296.305c0.538-0.402,0.086-1.793,0.235-2.584
		c0-0.078,0-0.156,0-0.235c-0.188-4.745-4.845-7.057-7.518-10.101c-0.349-0.616-1.755,0.227-0.939,0.705c0,0.078,0,0.156,0,0.235
		c0.207,2.242-0.812,3.26-3.054,3.054c-0.078,0-0.156,0-0.235,0c-0.213-0.413-0.822-0.43-1.174-0.705
		c-0.007,0.789,0.323,1.242,0.704,1.644c-0.054,0.916,0.505,1.218,0.705,1.879c0.852,2.936-0.468,5.391-1.175,7.517
		c0,0.079,0,0.157,0,0.235c-0.439,1.44-0.489,3.269-0.704,4.933c0,0.079,0,0.157,0,0.235c-0.356,0.583-0.454,1.426-0.47,2.349
		c-0.271,0.278-0.267,0.831-0.235,1.41c-0.41,1-0.627,2.192-0.705,3.523c0,0.079,0,0.157,0,0.235
		c-0.355,0.583-0.453,1.426-0.47,2.349c-0.323,0.303-0.232,1.021-0.234,1.645c-0.356,0.583-0.454,1.426-0.47,2.349
		c-0.743,4.269-1.979,8.043-2.35,12.685c-0.354,0.663-0.414,1.622-0.47,2.584c-0.283,0.813-0.512,1.681-0.47,2.819
		c-0.519,0.029-0.1,0.997-0.235,1.41c4.156-2.265,7.255-5.587,11.041-8.222c1.386-1.248,2.653-2.613,4.042-3.857
		c-2.713,2.147-5.26,4.461-7.902,6.678c2.642-2.22,5.191-4.532,7.905-6.68c-0.001,0.001-0.002,0.001-0.003,0.002
		c0.385-0.305,0.775-0.604,1.166-0.903c0.605-0.458,1.212-0.913,1.84-1.348c0-0.235,0-0.47,0-0.705c0.078,0,0.156,0,0.234,0
		c0-1.096,0-2.192,0-3.289c0-2.271,0-4.542,0-6.812c0-0.313,0-0.626,0-0.94c0.078,0,0.156,0,0.235,0c0-1.174,0-2.349,0-3.523
		c0-0.157,0-0.313,0-0.47c0.541-0.633,0.083-2.266,0.234-3.289c0-0.235,0-0.47,0-0.705c0.538-0.402,0.086-1.793,0.235-2.584
		c0-0.157,0-0.313,0-0.47c0.537-0.402,0.086-1.793,0.234-2.584C361.057,296.618,361.057,296.461,361.057,296.305z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M63.896,437.486c0.572-0.126,1.113-0.285,1.634-0.485
		c0.003,0.006,0.007,0.009,0.01,0.016c0.182-0.068,0.348-0.151,0.505-0.243c1.073-0.492,2.061-1.205,3.018-2.341
		c-0.026-0.601-0.431-0.822-0.705-1.175c0.1-0.491-0.161-0.622-0.235-0.939c-0.001-1.017-0.545-1.491-0.47-2.584
		c-0.525-0.102-0.095-1.158-0.235-1.645c-0.673-0.032,0.203-1.612-0.47-1.645c-0.028-0.677,0.128-1.538-0.235-1.879
		c-0.011-1.242-0.105-2.401-0.47-3.289c0.111-1.11-0.293-0.438-0.705-0.235c-1.048,0.676-1.979,1.466-3.054,2.114
		c0,0.236-0.19,0.279-0.235,0.47c-0.391,2.116-0.391,5.871,0,7.987c0,0.705,0,1.409,0,2.114c0.15,0.868-0.304,2.34,0.235,2.818
		c-0.021,0.334-0.039,0.666,0.235,0.705c0,0.078,0,0.156,0,0.235C62.671,438.518,63.559,437.512,63.896,437.486z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M314.075,176.266c2.407,2.839,5.276,5.216,8.222,7.518
		c0.198,0.981-0.457,1.109-0.47,1.879c-0.741,0.596-2.15,1.758-1.645,2.819c0,0.078,0,0.156,0,0.235
		c0.202,1.05,0.91,1.595,2.349,1.409c0.491,0.135,0.671,0.583,1.41,0.47c1.734,1.085,3.239,2.399,4.933,3.524
		c1.947,1.654,5.016,2.188,6.813,3.993c-0.183,0.052-0.272,0.197-0.234,0.47c-0.582,3.333-2.567,5.264-4.934,6.812
		c-2.766,0.837-5.785,1.419-8.691,2.114c-4.205,1.825-6.818,5.241-9.632,8.457c0,0.078,0,0.156,0,0.235
		c-0.888,1.562-3.113,2.963-2.818,4.933c0.605-0.413,1.697-0.339,2.114-0.94c0.49,0.1,0.622-0.161,0.939-0.235
		c1.024-0.307,2.077-0.584,2.818-1.174c1.017-0.001,1.491-0.545,2.584-0.47c0.052-0.183,0.197-0.273,0.471-0.235
		c1.096,0,2.192,0,3.288,0c0.392,0,0.783,0,1.175,0c0,0.078,0,0.156,0,0.235c0.872-0.146,2.418,0.382,2.584-0.47
		c1.233-1.429,2.564-2.76,3.993-3.993c1.4-1.341,2.903-2.579,4.229-3.994c0.885-0.055,0.995-0.885,1.88-0.939
		c0.013-0.144,0.034-0.278,0.234-0.235c2.523-0.313,0.932,2.1,0,2.349c0,0.078,0,0.156,0,0.235c-0.078,0-0.156,0-0.234,0
		c-2.138,2.442-6.17,4.53-7.987,6.813c-0.109,0.136,0.38,0.255,0.47,0.235c0.392,0,0.783,0,1.175,0
		c0.25,0.533,1.477,0.089,2.114,0.235c0.939,0,1.879,0,2.818,0c0.392,0,0.783,0,1.175,0c0,0.078,0,0.156,0,0.235
		c0.392,0,0.783,0,1.175,0s0.783,0,1.175,0c0.626,0,1.252,0,1.879,0c0.709-0.152,0.655-1.067,1.175-1.41
		c0.039-0.666,0.431-0.979,0.47-1.644c0-0.079,0-0.157,0-0.235c0.529-0.175,0.092-1.318,0.234-1.879c0-0.235,0-0.47,0-0.705
		c0.325-2.65,0.978-4.973,0.939-7.987c0.539-0.479,0.086-1.951,0.235-2.819c-0.166-0.871,0.401-1.008,0.235-1.879
		c0.237-5.713,1.594-10.308,1.644-16.208c0.52-0.029,0.101-0.997,0.235-1.41c0-1.253,0-2.506,0-3.758
		c-0.618-0.243-0.791-0.932-1.409-1.175c-0.079,0-0.157,0-0.235,0c-0.045-0.19-0.235-0.234-0.234-0.47
		c-1.586-1.39-3.074-2.877-4.464-4.463c-0.668-0.428-0.856-1.335-1.645-1.645c0-0.078,0-0.156,0-0.235
		c-1.285-1.534-2.334-3.304-3.758-4.698c0-0.078,0-0.156,0-0.235c-0.634-0.698-0.894-1.77-1.645-2.349
		c-0.497-1.618-1.394-2.835-1.645-4.698c-0.728-2.013-1.14-4.341-1.175-7.047c-0.381-1.106-0.479-2.497-0.47-3.993
		c-0.229-0.006-0.183-0.288-0.235-0.47c0-0.47,0-0.94,0-1.41c-2.628,2.426-4.602,5.505-6.397,8.763
		c-0.542,0.982-1.067,1.981-1.589,2.983c0,0.078,0,0.157,0,0.235c-0.61,0.866-1.099,1.856-1.497,2.935l-0.735,0.956l-6.153,10.458
		c-0.287,0.2-0.477,0.498-0.541,0.92c0.915,1.121,2.329,1.742,3.289,2.819C314.075,176.109,314.075,176.188,314.075,176.266z"/>
		</g>
		<path id="mouth1" fillRule="evenodd" clipRule="evenodd" d="M241.723,251.437c0.243-3.75,2.337-5.649,5.168-6.813
	c5.767,0.483,11.093-2.087,16.444-2.114c5.253-0.026,10.14,1.837,15.269,2.584c0.972,0.52,1.738,2.484,3.289,1.644
	c1.013-0.161,1.51-0.839,1.41-2.114c-0.286-1.671-1.254-2.661-2.819-3.054c-4.251-1.7-9.722-2.18-15.035-2.819
	c-0.868-0.391-3.36-0.391-4.228,0c-3.396,0.284-6.22,1.141-9.396,1.645c-1.006-0.794-1.775-1.827-3.289-2.114
	c-0.3-0.013-0.326-0.301-0.705-0.235c-0.524-0.572-1.533-0.659-2.349-0.94c-0.604-0.022-0.336-0.917-0.939-0.939
	c-0.05,0.264-0.261,0.366-0.47,0.47c-1.119,0.604-0.841,2.604-2.114,3.054c-0.235,0-0.47,0-0.705,0c0,0-4.065-0.379-4.69,0.496
	s-1.938,3.688-2.063,5.688s0.438,5.563,1.688,6.063s4.875,0.375,4.875,0.375L241.723,251.437z"/>
		<g id="eyebrow1">
			<g>
				<path fillRule="evenodd" clipRule="evenodd" fill="#2D2328" d="M281.657,169.219c4.221-0.612,7.802-2.809,12.216-3.054
			c5.096-0.282,10.269,2.52,15.738,2.349c-0.246-1.555-0.258-3.343-0.47-4.933c-6.166-2.447-13.107-4.119-20.202-5.638
			C285.735,160.925,283.185,164.56,281.657,169.219z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#2D2328" d="M327.465,172.742c-2.083-4.104-5.856-6.515-11.511-7.047
			c-0.07,1.715,0.544,2.745,0.705,4.229C320.531,170.593,323.796,171.87,327.465,172.742z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M314.545,169.454c0,0.156,0,0.313,0,0.47c0,0.861,0,1.723,0,2.584
			c1.726,0.31,3.661,0.41,4.933,1.174c2.465,0.433,4.611,1.184,6.813,1.879c0.894,0.125,1.683,0.353,2.35,0.705
			c1.196,0.101,2.088-0.104,2.584-0.705c0.208-0.417,0.655-0.597,0.47-1.409c-0.847-0.563-0.938-1.88-1.645-2.584
			c-0.045-0.19-0.235-0.234-0.235-0.47c0.296-1.235-0.45-1.428-1.174-1.644c-0.229-0.007-0.183-0.288-0.235-0.47
			c-1.801-1.801-2.921-4.283-5.402-5.403c-1.654-0.381-3.121-0.951-4.934-1.174c-1.456-0.267-2.747-0.698-4.229-0.94
			c0.028,0.677-0.128,1.538,0.235,1.879C312.94,164.978,314.188,167.799,314.545,169.454z M327.465,172.742
			c-3.669-0.873-6.934-2.149-10.806-2.819c-0.161-1.484-0.775-2.513-0.705-4.229C321.608,166.227,325.382,168.639,327.465,172.742z"
				/>
				<path fillRule="evenodd" clipRule="evenodd" d="M282.362,172.038c0.052-0.183,0.197-0.273,0.47-0.235
			c1.669-0.288,3.159-0.756,4.463-1.41c1.924-0.269,3.661-0.724,5.403-1.174c1.6-0.112,2.861,0.114,4.229,0.235
			c1.157,0.33,2.333,0.642,3.758,0.705c1.11,0.457,2.494,0.639,3.994,0.705c1.85,0.421,3.739,0.802,5.872,0.94
			c0.044,0.348,0.568,0.215,0.94,0.235c0.51-1.211,0.207-3.442-0.235-4.463c-0.126-2.827,0.108-4.916-0.705-7.282
			c-0.49-0.135-0.67-0.583-1.409-0.47c-1.076-0.725-2.526-1.076-3.993-1.41c-4.813-1.535-10.576-3.356-15.739-3.758
			c-2.576,2.122-5.559,3.837-6.812,7.282c0.19-0.399,0.402-0.776,0.633-1.135c-0.23,0.359-0.443,0.736-0.633,1.135
			c-0.291,0.649-0.686,1.193-0.705,2.114c-0.994,1.589-1.613,3.555-2.349,5.403c-0.315,0.467-0.448,1.118-0.705,1.644
			c0,0.235,0,0.47,0,0.705C279.767,172.296,281.212,172.394,282.362,172.038z M285.692,157.96c-0.244,0.227-0.479,0.462-0.712,0.699
			C285.214,158.422,285.449,158.187,285.692,157.96z M284.802,158.834c-0.247,0.258-0.481,0.528-0.71,0.804
			C284.321,159.363,284.555,159.092,284.802,158.834z M283.973,159.774c-0.215,0.267-0.414,0.551-0.607,0.84
			C283.559,160.325,283.757,160.041,283.973,159.774z M288.939,157.943c7.095,1.519,14.036,3.191,20.202,5.638
			c0.212,1.59,0.224,3.378,0.47,4.933c-5.47,0.17-10.643-2.631-15.738-2.349c-4.414,0.245-7.995,2.442-12.216,3.054
			C283.185,164.56,285.735,160.925,288.939,157.943z"/>
			</g>
			<path fillRule="evenodd" clipRule="evenodd" fill="#2D2328" d="M248.3,167.574c0.644-1.787-0.32-3.873,0-6.108
		c-7.633,0.2-12.326,5.369-14.329,11.98C239.672,172.303,240.608,165.368,248.3,167.574z"/>
			<path d="M237.5,174.333l8.667-4.167l2.033,1.283c-0.042-0.121-0.1-0.225-0.135-0.352c0.157,0,0.313,0,0.47,0
		c0.668,1.004,3.079,0.898,3.054-0.705c0-0.078,0-0.157,0-0.235c0.391-2.351,0.391-6.341,0-8.692c0-0.078,0-0.156,0-0.235
		c-0.16-1.484-1.548-1.741-2.584-2.349c-0.079,0-0.157,0-0.235,0c-1.341,0.304-2.667,0.623-3.759,1.175
		c-1.156,0.019-1.888,0.46-2.819,0.705c-1.863,0.251-2.64,1.588-4.229,2.114c0-0.021,0-0.043,0-0.064
		c-2.212,2.265-4.464,5.022-4.464,5.022l-2.5,6.5C231,174.333,232.833,177.167,237.5,174.333z M249.543,159.321
		c0.002,0.001,0.004,0.003,0.006,0.005c-0.17-0.084-0.346-0.161-0.545-0.209C249.201,159.165,249.376,159.239,249.543,159.321z
		 M248.3,161.466c-0.32,2.235,0.644,4.321,0,6.108c-7.691-2.207-8.627,4.729-14.329,5.873
		C235.974,166.835,240.667,161.667,248.3,161.466z"/>
		</g>
		<path id="mouth2" display="none" fillRule="evenodd" clipRule="evenodd" d="M241.723,251.437c0.243-3.75,2.337-5.649,5.168-6.813
	c5.767,0.483,11.093-0.087,16.444-0.114c5.253-0.026,11.14,4.837,16.269,5.584c0.972,0.52,1.738,2.484,3.289,1.644
	c1.013-0.161,1.51-0.839,1.41-2.114c-0.286-1.671-1.254-2.661-2.819-3.054c-4.251-1.7-10.722-5.18-16.035-5.819
	c-0.868-0.391-3.36-0.391-4.228,0c-3.396,0.284-6.22-0.859-9.396-0.355c-1.006-0.794-1.775-1.827-3.289-2.114
	c-0.3-0.013-0.326-0.301-0.705-0.235c-0.524-0.572-1.533-0.659-2.349-0.94c-0.604-0.022-0.336-0.917-0.939-0.939
	c-0.05,0.264-0.261,0.366-0.47,0.47c-1.119,0.604-0.841,2.604-2.114,3.054c-0.235,0-0.47,0-0.705,0c0,0-4.065-0.379-4.69,0.496
	s-1.938,3.688-2.063,5.688s0.438,5.563,1.688,6.063s4.875,0.375,4.875,0.375L241.723,251.437z"/>
		<g id="eyebrow2" display="none">
			<g display="inline">
				<path fillRule="evenodd" clipRule="evenodd" fill="#2D2328" d="M275.246,176.613c4.054-1.326,7.206-4.103,11.513-5.099
			c4.972-1.15,10.548,0.726,15.909-0.378c-0.509-1.49-0.826-3.25-1.308-4.78c-6.493-1.356-13.618-1.815-20.868-2.098
			C277.845,167.743,275.954,171.761,275.246,176.613z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#2D2328" d="M320.981,172.247c-2.755-3.687-6.885-5.417-12.547-4.974
			c0.225,1.702,1.006,2.611,1.418,4.045C313.782,171.315,317.217,172.015,320.981,172.247z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M307.689,171.217c0.026,0.154,0.054,0.309,0.08,0.463
			c0.147,0.849,0.295,1.697,0.442,2.546c1.753,0.01,3.677-0.222,5.061,0.313c2.503,0.005,4.746,0.377,7.033,0.687
			c0.902-0.03,1.719,0.06,2.436,0.292c1.196-0.105,2.04-0.459,2.426-1.136c0.134-0.447,0.543-0.701,0.222-1.469
			c-0.931-0.41-1.246-1.692-2.063-2.265c-0.077-0.18-0.271-0.19-0.313-0.423c0.08-1.268-0.688-1.33-1.438-1.419
			c-0.227,0.032-0.229-0.252-0.313-0.423c-2.082-1.466-3.61-3.72-6.247-4.399c-1.695-0.092-3.237-0.403-5.062-0.313
			c-1.48-0.014-2.826-0.218-4.327-0.203c0.144,0.662,0.138,1.537,0.554,1.811C305.343,167.082,307.054,169.648,307.689,171.217z
			 M320.981,172.247c-3.765-0.232-7.199-0.932-11.129-0.929c-0.412-1.434-1.193-2.343-1.418-4.045
			C314.097,166.83,318.227,168.561,320.981,172.247z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M276.423,179.27c0.02-0.189,0.147-0.303,0.423-0.312
			c1.596-0.569,2.983-1.285,4.156-2.152c1.849-0.594,3.483-1.34,5.123-2.082c1.557-0.384,2.839-0.377,4.207-0.492
			c1.196,0.127,2.408,0.234,3.822,0.051c1.173,0.26,2.567,0.203,4.057,0.011c1.894,0.098,3.821,0.15,5.946-0.079
			c0.103,0.335,0.597,0.115,0.967,0.07c0.295-1.281-0.386-3.426-0.996-4.357c-0.607-2.764-0.734-4.862-1.94-7.054
			c-0.506-0.049-0.76-0.459-1.469-0.222c-1.185-0.53-2.673-0.627-4.176-0.706c-5.004-0.688-10.994-1.498-16.15-1.01
			c-2.175,2.532-4.82,4.732-5.466,8.34c0.119-0.426,0.264-0.833,0.429-1.227c-0.166,0.393-0.31,0.801-0.429,1.227
			c-0.175,0.689-0.472,1.293-0.333,2.204c-0.708,1.736-0.981,3.778-1.39,5.725c-0.23,0.515-0.25,1.178-0.413,1.741
			c0.04,0.231,0.081,0.463,0.121,0.694C273.911,179.968,275.351,179.817,276.423,179.27z M277.296,164.83
			c-0.202,0.265-0.393,0.537-0.582,0.81C276.903,165.366,277.094,165.094,277.296,164.83z M276.568,165.843
			c-0.199,0.296-0.384,0.603-0.563,0.914C276.185,166.446,276.369,166.14,276.568,165.843z M275.912,166.911
			c-0.167,0.3-0.313,0.614-0.455,0.931C275.599,167.525,275.745,167.211,275.912,166.911z M280.492,164.257
			c7.25,0.283,14.375,0.742,20.868,2.098c0.481,1.53,0.799,3.29,1.308,4.78c-5.36,1.104-10.937-0.771-15.909,0.378
			c-4.307,0.997-7.459,3.773-11.513,5.099C275.954,171.761,277.845,167.743,280.492,164.257z"/>
			</g>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" fill="#2D2328" d="M248.052,172.957
		c1.026-1.599,0.552-3.847,1.363-5.954c-7.485-1.508-13.213,2.483-16.641,8.481C238.587,175.641,241.046,169.089,248.052,172.957z"
			/>
			<path display="inline" d="M236.016,177.136l9.378-2.128l1.695,1.705c-0.014-0.127-0.047-0.241-0.053-0.373
		c0.153,0.035,0.306,0.07,0.458,0.104c0.427,1.128,2.801,1.563,3.134-0.005c0.018-0.076,0.035-0.153,0.052-0.229
		c0.906-2.205,1.796-6.094,1.939-8.473c0.018-0.076,0.035-0.152,0.053-0.229c0.175-1.482-1.121-2.042-1.995-2.866
		c-0.077-0.018-0.152-0.035-0.229-0.053c-1.375-0.003-2.738,0.012-3.926,0.307c-1.131-0.24-1.943,0.028-2.905,0.058
		c-1.872-0.171-2.927,0.959-4.593,1.117c0.004-0.021,0.009-0.042,0.014-0.063c-2.662,1.714-5.472,3.899-5.472,3.899l-3.888,5.778
		C229.68,175.686,230.835,178.856,236.016,177.136z M251.105,165.189c0.002,0.002,0.003,0.004,0.005,0.006
		c-0.146-0.12-0.301-0.234-0.484-0.325C250.807,164.96,250.96,165.072,251.105,165.189z M249.415,167.002
		c-0.811,2.107-0.337,4.355-1.363,5.954c-7.005-3.867-9.465,2.684-15.278,2.527C236.202,169.486,241.929,165.495,249.415,167.002z"
			/>
		</g>
		<path id="mouth3" display="none" fillRule="evenodd" clipRule="evenodd" d="M241.723,250.437c0.243-3.75,2.337-5.649,5.168-6.813
	c5.767,0.483,11.093-2.087,16.444-2.114c5.253-0.026,12.063-2.566,17.192-1.819c1.307-0.108,1.589,0.023,3.14-0.817
	c0.917-0.583,1.25-0.208,1.558-2.653c-0.017-0.597-0.206-3.163-3.568-1.054c-4.569,0.206-10.896,4.223-16.209,3.584
	c-0.868-0.391-3.36-0.391-4.228,0c-3.396,0.284-6.22,0.141-9.396,0.645c-1.006-0.794-1.775-1.827-3.289-2.114
	c-0.3-0.013-0.326-0.301-0.705-0.235c-0.524-0.572-1.533-0.659-2.349-0.94c-0.604-0.022-0.336-0.917-0.939-0.939
	c-0.05,0.264-0.261,0.366-0.47,0.47c-1.119,0.604-0.841,2.604-2.114,3.054c-0.235,0-0.47,0-0.705,0c0,0-4.065-0.379-4.69,0.496
	s-1.938,3.688-2.063,5.688s0.438,5.563,1.688,6.063s4.875,0.375,4.875,0.375L241.723,250.437z"/>
		<g id="eyebrow3" display="none">
			<g display="inline">
				<path fillRule="evenodd" clipRule="evenodd" fill="#2D2328" d="M281.946,170.984c4.221-0.612,7.802-2.809,12.216-3.054
			c5.096-0.282,10.269,2.52,15.738,2.349c-0.246-1.555-0.258-3.343-0.47-4.933c-6.166-2.447-13.107-4.119-20.202-5.638
			C286.024,162.69,283.474,166.325,281.946,170.984z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#2D2328" d="M327.754,174.507c-2.083-4.104-5.856-6.515-11.511-7.047
			c-0.07,1.715,0.544,2.745,0.705,4.229C320.82,172.358,324.085,173.635,327.754,174.507z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M314.834,171.219c0,0.156,0,0.313,0,0.47c0,0.861,0,1.723,0,2.584
			c1.726,0.31,3.661,0.41,4.933,1.174c2.465,0.433,4.611,1.184,6.813,1.879c0.894,0.125,1.683,0.353,2.35,0.705
			c1.196,0.101,2.088-0.104,2.584-0.705c0.208-0.417,0.655-0.597,0.47-1.409c-0.847-0.563-0.938-1.88-1.645-2.584
			c-0.045-0.19-0.235-0.234-0.235-0.47c0.296-1.235-0.45-1.428-1.174-1.644c-0.229-0.007-0.183-0.288-0.235-0.47
			c-1.801-1.801-2.921-4.283-5.402-5.403c-1.654-0.381-3.121-0.951-4.934-1.174c-1.456-0.267-2.747-0.698-4.229-0.94
			c0.028,0.677-0.128,1.538,0.235,1.879C313.229,166.743,314.477,169.564,314.834,171.219z M327.754,174.507
			c-3.669-0.873-6.934-2.149-10.806-2.819c-0.161-1.484-0.775-2.513-0.705-4.229C321.897,167.992,325.671,170.404,327.754,174.507z"
				/>
				<path fillRule="evenodd" clipRule="evenodd" d="M282.651,173.803c0.052-0.183,0.197-0.273,0.47-0.235
			c1.669-0.288,3.159-0.756,4.463-1.41c1.924-0.269,3.661-0.724,5.403-1.174c1.6-0.112,2.861,0.114,4.229,0.235
			c1.157,0.33,2.333,0.642,3.758,0.705c1.11,0.457,2.494,0.639,3.994,0.705c1.85,0.421,3.739,0.802,5.872,0.94
			c0.044,0.348,0.568,0.215,0.94,0.235c0.51-1.211,0.207-3.442-0.235-4.463c-0.126-2.827,0.108-4.916-0.705-7.282
			c-0.49-0.135-0.67-0.583-1.409-0.47c-1.076-0.725-2.526-1.076-3.993-1.41c-4.813-1.535-10.576-3.356-15.739-3.758
			c-2.576,2.122-5.559,3.837-6.812,7.282c0.19-0.399,0.402-0.776,0.633-1.135c-0.23,0.359-0.443,0.736-0.633,1.135
			c-0.291,0.649-0.686,1.193-0.705,2.114c-0.994,1.589-1.613,3.555-2.349,5.403c-0.315,0.467-0.448,1.118-0.705,1.644
			c0,0.235,0,0.47,0,0.705C280.056,174.062,281.501,174.159,282.651,173.803z M285.981,159.725
			c-0.244,0.227-0.479,0.462-0.712,0.699C285.503,160.187,285.738,159.952,285.981,159.725z M285.091,160.6
			c-0.247,0.258-0.481,0.528-0.71,0.804C284.61,161.128,284.844,160.857,285.091,160.6z M284.262,161.539
			c-0.215,0.267-0.414,0.551-0.607,0.84C283.848,162.09,284.046,161.806,284.262,161.539z M289.229,159.708
			c7.095,1.519,14.036,3.191,20.202,5.638c0.212,1.59,0.224,3.378,0.47,4.933c-5.47,0.17-10.643-2.631-15.738-2.349
			c-4.414,0.245-7.995,2.442-12.216,3.054C283.474,166.325,286.024,162.69,289.229,159.708z"/>
			</g>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" fill="#2D2328" d="M248.589,169.339
		c0.644-1.787-0.32-3.873,0-6.108c-7.633,0.2-12.326,5.369-14.329,11.98C239.961,174.068,240.897,167.133,248.589,169.339z"/>
			<path display="inline" d="M237.789,176.099l8.667-4.167l2.033,1.283c-0.042-0.121-0.1-0.225-0.135-0.352c0.157,0,0.313,0,0.47,0
		c0.668,1.004,3.079,0.898,3.054-0.705c0-0.078,0-0.157,0-0.235c0.391-2.351,0.391-6.341,0-8.692c0-0.078,0-0.156,0-0.235
		c-0.16-1.484-1.548-1.741-2.584-2.349c-0.079,0-0.157,0-0.235,0c-1.341,0.304-2.667,0.623-3.759,1.175
		c-1.156,0.019-1.888,0.46-2.819,0.705c-1.863,0.251-2.64,1.588-4.229,2.114c0-0.021,0-0.043,0-0.064
		c-2.212,2.265-4.464,5.022-4.464,5.022l-2.5,6.5C231.289,176.099,233.123,178.932,237.789,176.099z M249.833,161.086
		c0.002,0.001,0.004,0.003,0.006,0.005c-0.17-0.084-0.346-0.161-0.545-0.209C249.49,160.93,249.665,161.004,249.833,161.086z
		 M248.589,163.231c-0.32,2.235,0.644,4.321,0,6.108c-7.691-2.207-8.627,4.729-14.329,5.873
		C236.263,168.6,240.956,163.432,248.589,163.231z"/>
		</g>
	</svg>
