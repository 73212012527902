import React from 'react'
export const Intelligencer = () =>
	<svg version="1.1" className="character_svg" id="Intelligencer" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px"
		y="0px" width="576px" height="828px" viewBox="0 0 576 828" enableBackground="new 0 0 576 828" space="preserve">
		<g id="eye_backing_-_light_gray">
			<path fillRule="evenodd" clipRule="evenodd" fill="#D1D8DC" d="M235.68,219.08c0,1.28,0,2.56,0,3.84
		c0.038,0.282-0.041,0.681,0.24,0.72c4.625,2.246,13.908,2.938,18.96,1.2c3.549-0.451,5.636-2.364,7.92-4.08
		c0.223-1.262-0.811-1.269-0.96-2.16C256.44,213.372,237.55,205.087,235.68,219.08z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#D1D8DC" d="M327.601,220.521c-0.16,0-0.32,0-0.48,0
		c-2.199-3.721-3.994-7.846-8.88-8.88c-1.279,0-2.56,0-3.84,0c-2.245,0.075-3.752,0.888-5.04,1.92c0,0.08,0,0.16,0,0.24
		c-0.939,1.14-1.979,2.18-3.12,3.12c-0.194,0.045-0.239,0.241-0.479,0.24c-0.869,0.491-1.493,1.227-2.641,1.44
		c0.454,2.427,3.524,2.236,4.561,4.08c-0.147-0.213-0.29-0.429,0-0.48c5.784,1.968,13.42,1.623,20.16,0.48
		c0.855,0.68,0.503-1.451,0.96-1.92C328.452,220.629,327.561,221.041,327.601,220.521z"/>
		</g>
		<circle id="eye1_1_" cx="246.686" cy="218.913" r="8.222" />
		<circle id="eye1" cx="314.648" cy="219.17" r="7.484" />
		<g id="main_body">
			<g>
				<path fillRule="evenodd" clipRule="evenodd" fill="#7D8083" d="M270.72,277.16c3.41-1.39,7.775-1.824,10.56-3.84
			c0.045-2.285-0.521-3.959-1.68-5.04c-2.95-0.018-3.646,2.562-5.76,2.4c-2.285-0.174-5.893-5.848-7.2-7.44
			c-0.292,0.508-0.903,0.697-0.96,1.44c0.279-0.039,0.427,0.053,0.48,0.24c-3.476,7.564-9.993,12.087-13.68,19.44
			c3.054-1.42,5.7-3.519,8.88-5.04c2.985-1.428,5.911-3.642,9.36-3.84C271.051,276.239,270.126,276.929,270.72,277.16z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#7D8083" d="M241.2,142.28c-1.14-0.94-2.18-1.98-3.12-3.12
			c-1.454-2.146-3.101-4.099-4.32-6.48c-0.819-0.381-0.749-1.651-1.44-2.16c0,0.24,0,0.48,0,0.72c0.351,1.648-0.129,4.129,1.2,4.8
			c0.59,0.77,1.041,1.679,1.68,2.4c1.858,2.302,4.473,3.847,7.2,5.28c0.382,0.312,0.966,0.316,0.48-0.24
			C242.147,143.253,242.128,142.313,241.2,142.28z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#7D8083" d="M463.201,575.721c0.539,0.741,1.179,1.381,1.92,1.92
			C464.746,576.736,464.105,576.096,463.201,575.721z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#7D8083" d="M343.2,202.28c-0.707-0.492,0.229-2.628-0.479-3.12
			c-0.43,2.051-0.926,4.034-0.96,6.48c0.389,4.624,2.16,0.709,1.439-1.92C343.2,203.24,343.2,202.76,343.2,202.28z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#7D8083" d="M487.921,644.121c0.157,1.443-0.316,3.516,0.24,4.561
			c0-0.641,0-1.28,0-1.921C488.009,645.953,488.47,644.532,487.921,644.121z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#7D8083" d="M316.801,283.88c-0.671-4.849-3.077-7.963-5.521-11.04
			c-1.173-1.947-2.492-3.748-4.319-5.04c-0.871-1.05-1.795-2.045-2.641-3.12c-1.188,1.452-1.905,3.375-3.359,4.56
			c-3.803-0.362-5.983,0.897-6.721,3.6c0.13,1.231,1.483,1.237,1.2,2.88c6.516,2.445,13.836,4.084,18.72,8.16
			c0.993,0.24,2.668,3.882,2.88,1.2C316.909,284.731,317.321,283.84,316.801,283.88z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#7D8083" d="M338.881,120.68c-5.977-9.765-9.941-21.507-18.48-27.84
			c-2.168,0.088-2.587-1.573-3.6-2.64c-2.635-0.969-4.311-2.815-6.721-4.08c-2.688-1.412-5.604-1.357-8.88-2.16
			c-5.565-1.364-11.498-4.162-18-4.08c1.518,2.803,5.204,5.517,7.44,8.4c-6.375,1.949-7.468-8.06-14.64-8.88
			c-6.914-0.791-16.227,1.774-24.72,2.16c-14.278,0.648-26.332,3.59-31.68,12.96c0,0.88,0,1.76,0,2.64
			c-1.308,3.058-4.416,4.424-6.72,6.48c-1.048,0.935-2.586,2.399-2.4,3.12c1.782,0.057,3.746-0.066,4.08,1.44
			c-7.38-0.479-10.755,2.933-14.64,6.48c-2.256,2.06-5.286,4.889-6.24,6.72c-2.507,4.811-3.002,13.094-3.6,20.4
			c-1.145,13.992-0.142,30.38-0.96,43.92c1.553,1.007,1.432,3.688,1.92,5.76c1.373,3.488,4.619,6.889,6.24,10.56
			c1.528,3.459,0.14,8.308,0.72,13.44c2.056,5.304,3.097,11.624,4.08,18c0.547,0.333,0.09,1.67,0.24,2.4
			c0.149,2.41,0.447,4.673,0.48,7.2c0.4,2.242,0.4,6.158,0,8.4c2.905,4.334,2.786,10.709,3.84,17.04
			c0.449,2.698,1.152,5.659,1.92,8.4c0.813,2.898,1.26,6.032,2.16,8.16c0.744,1.76,3.292,5.512,5.04,6.48
			c2.789,1.543,5.111,0.959,5.76,3.36c0.354,1.308-0.452,3.217,0.48,4.08c0.403,0.476,1.377,0.383,1.68,0.96
			c3.731,4.485,4.131,10.154,12,10.8c4.229,0.348,11.179-0.734,11.52,2.16c0.217,1.843-1.986,2.533-3.12,3.36
			c4.235,1.551,6.421,4.276,10.08,7.2c2.583,2.064,5.527,5.046,7.92,6c2.5,0.997,11.397-0.647,12,1.44
			c0.609,2.11-2.892,3.194-2.88,4.08c3.002,2.278,7.596,2.964,9.12,6.72c3.713-3.328,9.839-4.241,16.561-4.56
			c0.979,0.38,0.945,1.774,1.68,2.4c1.429-1.932,3.112-3.607,5.04-5.04c1.462-0.47,1.245,1.64,2.399,0.72
			c2.428-3.835,5.508-10.819,7.921-15.84c0.806-1.679,1.504-4.919,2.88-5.28c1.13-0.297,2.958,0.649,3.359,1.92
			c0.491-1.029,1.515-1.526,2.4-2.16c1.266-0.647,2.027,1.302,2.16,0.24c0.547-1.853,0.051-4.749,1.68-5.52
			c1.231-0.55,1.924,1.587,2.16,0.48c1.23-6.049,3.825-10.735,6.479-15.36c0.352-5.208-1.432-11.153-0.96-17.28
			c0.315-4.094,1.49-7.752,0.48-11.76c-3.072,7.728-9.991,11.609-9.84,22.56c0.106,0.135,0.192,0.29,0.271,0.453
			c-0.078-0.163-0.164-0.318-0.271-0.453c-0.128,0.832,0.356,2.276-0.48,2.4c-0.912,0.108-1.707-0.622-2.399,0
			c-0.127,2.278-0.542,4.808-0.96,7.44c-0.516,3.242-0.515,7.517-2.4,8.4c-2.767-0.513-2.714-3.846-3.6-6.24
			c0.074,0.955-0.555,1.206-0.48,2.16c-2.06,5.46-3.897,11.143-6.479,16.08c-0.299,1.382,0.089,3.449-2.16,2.88
			c-0.716-0.404-0.609-1.631-0.721-2.64c0.483-0.718,0.136-2.265,0.24-3.36c-0.389-1.014-0.758-2.048-1.405-2.802
			c0.648,0.754,1.017,1.788,1.405,2.802c0.439-1.64,0.288-3.872,0.96-5.28c-0.394-0.935-1.474,1.334-1.68,2.16
			c-0.717-0.803-0.483-2.557-1.2-3.36c-0.167,1.367,0.407,1.994,0.24,3.36c-0.078-0.011-0.163,0.014-0.24,0
			c-0.904-0.161-1.51-0.852-1.979-1.776c-0.205-0.552-0.421-1.092-0.649-1.62c0,0-0.001-0.001-0.001-0.002c0,0,0,0.001,0,0.001
			c0-0.001-0.001-0.002-0.001-0.003c0,0,0.001,0.001,0.001,0.002c-0.676-2.051-1.146-4.413-2.411-5.241
			c-1.388,0.188-2.052-0.348-3.36-0.24c-4.005,0.155-8.632-0.313-12.24,0.24c-2.252,5.347-4.892,10.308-9.36,13.44
			c0.001,0.801-0.781,0.819-0.72,1.68c-0.197,1.844-3.163,1.844-3.36,0c-1.218-2.462-2.662-4.698-3.84-7.2
			c-0.536-0.664-0.934-1.466-1.2-2.4c-0.016-1.104-0.682-1.558-0.96-2.4c-0.453-0.18-0.167-0.717-0.72-0.24
			c-1.692,1.327-2.341-1.565-3.36-2.4c-0.903-0.856-1.498-2.022-2.16-3.12c-0.705-1.695-1.956-2.845-2.64-4.56
			c-3.1,0.42-2.563,4.478-5.76,4.8c-0.85-1.28,0.485-2.154,0.72-3.12c-0.421,0.207-0.834,0.894-0.72-0.24
			c-0.556-0.964-0.083-2.957-0.24-4.32c-0.175-1.425-1.172-2.027-1.44-3.36c-0.108,2.212-1.372,3.268-1.92,5.04
			c-0.309,0.412-0.497,0.944-1.2,0.96c-0.819-0.621-0.367-2.513-0.48-3.84c0-0.56,0-1.12,0-1.68c-0.557-1.124-0.083-3.277-0.24-4.8
			c0-0.24,0-0.48,0-0.72c-0.547-0.333-0.089-1.671-0.24-2.4c0-0.16,0-0.32,0-0.48c-0.688-0.032,0.208-1.647-0.48-1.68
			c-0.048,0.832-0.2,1.56-1.2,1.44c-0.861-2.899-1.239-6.281-2.88-8.4c-0.548,1.186-0.711,1.295-1.2,2.4
			c-1.662-0.978-2.222-3.058-3.36-4.56c-0.997-0.923-1.565-2.275-2.4-3.36c-1.243-2.197-2.791-4.09-4.32-6
			c-1.14-0.94-2.18-1.98-3.12-3.12c-5.433-6.647-10.622-13.539-13.68-22.56c-0.399-1.205-0.399-4.075,0-5.28
			c0.184-3.565,2.232-6.677,2.4-10.32c0.126-2.746-0.872-6.007-1.44-9.12c-1.107-6.066-2.097-12.147-3.6-17.52
			c-0.785-2.806-2.146-5.542-1.68-8.16c1.631,1.569,1.608,4.792,2.64,6.96c0.528,0.591,0.533,1.707,0.96,2.4
			c0.454,0.026,0.167,0.793,0.72,0.72c1.003-2.997,0.285-7.714,2.4-9.6c1.267,2.114-0.651,5.279,0,7.44
			c1.383-2.789,4.165-5.057,5.52-8.4c1.84-4.539,0.623-10.308,4.08-13.68c0.965,0.315,0.683,1.876,0.72,3.12
			c0.553,0.647,0.085,2.315,0.24,3.36c0.396-0.004,0.134-0.666,0.48-0.72c2.2-11.56,6.862-20.658,7.68-33.6c0-1.52,0-3.04,0-4.56
			c-0.698,0.183,0.218,1.978-0.48,2.16c-0.331,0.31-0.238,1.042-0.24,1.68c-0.067,0.813-0.382,1.378-0.24,2.4
			c-0.009,0.791,0.098,1.698-0.72,1.68c-0.529-0.912-0.238-2.642-0.72-3.6c-0.688,0.032,0.208,1.647-0.48,1.68
			c-0.202,0.598-0.314,1.286-0.24,2.16c-0.07,1.291-0.251,2.468-0.72,3.36c-1.844,13.036-10.617,19.144-18.48,26.16
			c-0.479,1.352-2.52-0.285-1.44-1.2c2.495-7.986,10.938-10.023,11.04-20.4c0-1.36,0-2.72,0-4.08c0.054-1.254-0.004-2.396-0.24-3.36
			c-0.698-0.182,0.218-1.978-0.48-2.16c-2.015,6.865-6.309,11.451-8.88,17.76c-0.27,0.69-0.048,1.872-0.48,2.4
			c-0.283,0.356-0.208,1.072-0.96,0.96c-1.287-2.841,1.016-5.941,1.92-8.16c0.374-0.906,1.066-1.494,1.44-2.4c0.08,0,0.16,0,0.24,0
			c2.757-3.852,4.892-9.427,6.24-14.64c0.383-1.48,0.472-4.425,2.4-4.32c0.551,0.489,0.087,1.993,0.24,2.88c0,0.8,0,1.6,0,2.4
			c0,0.08,0,0.16,0,0.24c0.559,2.082,0.081,5.199,0.24,7.68c0,0.08,0,0.16,0,0.24c0.399,1.444,0.399,4.556,0,6
			c-0.401,7.599-6.529,10.751-8.88,15.6c1.471-1.489,3.242-2.678,4.8-4.08c0.86-1.06,1.82-2.02,2.88-2.88
			c4.115-4.765,6.783-10.977,7.92-18.72c0.151-0.729-0.307-2.067,0.24-2.4c0-0.24,0-0.48,0-0.72c-0.398-0.649-0.398-2.951,0-3.6
			c0-0.16,0-0.32,0-0.48c-0.018-0.418,0.054-0.747,0.24-0.96c1.073-0.194,1.333,0.427,1.44,1.2c-0.018,0.418,0.053,0.747,0.24,0.96
			c0.454,0.027,0.167,0.794,0.72,0.72c0-0.96,0-1.92,0-2.88c0-1.44,0-2.88,0-4.32c0.564-3.26-1.718-6.778-0.24-8.64
			c1.371,0.526,0.974,1.05,2.64,0.48c1.274,2.726,3.186,4.814,5.04,6.96c1.38,1.18,2.66,2.46,3.84,3.84
			c4.67,3.811,9.694,7.266,17.76,7.68c0.808,0.152,2.229-0.309,2.64,0.24c0.48,0,0.96,0,1.44,0c2,0,4,0,6,0
			c1.855-0.064,3.546-0.294,4.56-1.2c-1.752-0.728-2.951-2.009-4.56-2.88c-0.865-0.895-2.193-1.327-2.16-3.12c0.08,0,0.16,0,0.24,0
			c1.269-2.458,3.252,0.654,4.56,1.44c8.082,4.856,23.576,5.895,29.76,12.48c1.103,0.737,1.777,1.903,2.88,2.64
			c0.07,0.489,0.649,0.471,0.72,0.96c0.741,0.939,1.622,1.739,2.4,2.64c0.538,0.742,0.971,1.59,1.68,2.16
			c-0.376-1.064-0.525-2.354-1.199-3.12c-0.979-3.102-2.49-5.67-4.08-8.16c-0.645-1.276-1.457-2.384-2.4-3.36
			c-1.707-2.293-4.269-3.731-6.24-5.76c0.099-0.578-0.438-0.521-0.24-1.2c0.08,0,0.16,0,0.24,0c-0.029-1.146,0.601-1.082,1.2-1.2
			c4.2-0.825,7.777,5.303,11.04,6.24c0.352-0.004,0.761,1.012,0.72,0c0.514-6.502,10.101,2.521,11.04,3.36
			c0.135,0.345,0.375,0.585,0.721,0.72c1.047,1.033,1.433,2.728,2.64,3.6c-0.082-2.798-0.968-4.792-1.68-6.96
			c-0.431-1.25-1.109-2.251-1.681-3.36c-0.546-0.893-1.051-1.829-1.68-2.64c-0.61-1.07-1.43-1.931-2.16-2.88
			c-0.857-4.644,4.104-1.042,4.32,0.96c3.59,2.09,6.491,4.869,9.84,7.2c0.849,0.112,0.844,1.076,1.92,0.96
			c0.04-0.839-0.063-1.536-0.479-1.92c-0.274-2.206-0.533-4.427-1.44-6c-0.334-1.187-0.893-2.147-1.2-3.36
			c0.028-1.228-0.709-1.691-0.96-2.64c-0.694-1.465-1.446-2.873-2.16-4.32c-1.404-2.275-2.812-4.548-4.56-6.48c0-0.08,0-0.16,0-0.24
			c-0.64-0.24-0.791-0.969-1.2-1.44c-1.291-1.27-2.808-2.313-4.08-3.6c-0.641-0.879-1.85-1.19-2.64-1.92
			c-2.634-2.167-5.745-3.855-9.12-5.28c-1.363-0.397-2.371-1.149-3.601-1.68c0-0.08,0-0.16,0-0.24
			c-0.674-0.525-1.442-0.958-1.439-2.16c1.585-1.753,3.883,0.107,5.28,0.72c11.349,4.977,19.277,13.173,24.479,23.52
			c0.897,1.786,1.146,3.994,2.16,6c1.358,2.69,4.254,4.526,5.28,7.2c0.843,0.757,1.236,1.964,1.68,3.12
			c3.104,7.135,4.327,16.152,6,24.72c0.511,3.889,1.107,7.693,1.2,12c1.068-5.682-0.141-12.943-0.48-18.96
			c-0.137-2.423-0.4-6.885,2.16-6.72c0.407,2.673-0.817,6.349,0.48,8.88c2.435-11.571,2.078-22.916-1.44-31.68
			C343.766,127.028,341.129,124.353,338.881,120.68z M325.586,290.35c0.009,0.059,0.021,0.115,0.029,0.173
			C325.606,290.465,325.595,290.409,325.586,290.35z M325.709,291.025c0.027,0.125,0.063,0.243,0.096,0.363
			C325.772,291.268,325.736,291.151,325.709,291.025z M325.891,291.691c0.074,0.212,0.16,0.412,0.27,0.589
			C326.052,292.103,325.965,291.903,325.891,291.691z M325.44,289.396c0-0.004-0.001-0.008-0.002-0.012
			c0.001,0.005,0.002,0.011,0.003,0.016C325.441,289.399,325.441,289.398,325.44,289.396z M325.364,288.944
			c-0.033-0.177-0.07-0.349-0.113-0.516c0.042,0.165,0.079,0.334,0.112,0.509C325.363,288.939,325.364,288.942,325.364,288.944z
			 M325.19,288.227c-0.037-0.126-0.08-0.249-0.128-0.366C325.11,287.979,325.153,288.1,325.19,288.227z M301.402,326.329
			L301.402,326.329c-0.059,0.254-0.121,0.506-0.202,0.751C301.281,326.835,301.344,326.583,301.402,326.329z M301.267,319.874
			c0.03,0.173,0.059,0.345,0.087,0.518C301.325,320.219,301.297,320.047,301.267,319.874z M301.428,320.892
			c0.025,0.171,0.05,0.343,0.071,0.514C301.478,321.235,301.453,321.063,301.428,320.892z M301.444,326.168
			c0.02-0.09,0.028-0.185,0.045-0.276c-0.017,0.093-0.025,0.188-0.045,0.28C301.444,326.171,301.444,326.17,301.444,326.168z
			 M301.659,324.341c0.006-0.155,0.003-0.314,0.004-0.472C301.662,324.027,301.665,324.186,301.659,324.341z M301.589,325.276
			c0.018-0.146,0.023-0.295,0.035-0.442C301.612,324.981,301.606,325.13,301.589,325.276z M301.555,321.902
			c0.019,0.167,0.036,0.334,0.051,0.5C301.591,322.236,301.573,322.069,301.555,321.902z M301.637,322.894
			c0.009,0.164,0.02,0.328,0.024,0.489C301.657,323.221,301.646,323.057,301.637,322.894z M303.874,311.558
			c0.228,0.548,0.481,1.07,0.702,1.625C304.355,312.627,304.102,312.105,303.874,311.558
			C303.874,311.557,303.874,311.557,303.874,311.558L303.874,311.558z M299.823,311.741c-0.029-0.33-0.051-0.657-0.063-0.981
			C299.772,311.084,299.794,311.411,299.823,311.741z M301.176,319.362c-0.033-0.175-0.065-0.351-0.1-0.527
			C301.11,319.011,301.143,319.187,301.176,319.362z M300.975,318.315c-0.037-0.185-0.074-0.37-0.112-0.554
			C300.9,317.945,300.938,318.13,300.975,318.315z M300.731,317.119c-0.052-0.252-0.104-0.504-0.155-0.756
			C300.628,316.615,300.68,316.867,300.731,317.119z M300.471,315.841c-0.038-0.188-0.074-0.376-0.111-0.564
			C300.396,315.465,300.433,315.652,300.471,315.841z M300.265,314.783c-0.034-0.186-0.066-0.371-0.099-0.556
			C300.198,314.412,300.23,314.597,300.265,314.783z M300.085,313.756c-0.032-0.195-0.06-0.389-0.088-0.583
			C300.025,313.367,300.053,313.561,300.085,313.756z M299.935,312.742c-0.028-0.213-0.05-0.425-0.071-0.636
			C299.885,312.317,299.906,312.528,299.935,312.742z M238.08,84.68c0.106-0.214,0.211-0.429,0.48-0.48
			c0.333-0.547,1.67-0.09,2.4-0.24c6.782-0.418,13.165-1.235,19.2-2.4c1.444-0.399,4.557-0.399,6,0c2.266,0.454,4.08,1.36,6,2.16
			c1.123,0.755,3.002,1.906,2.4,3.6c-0.315-0.084-0.377-0.422-0.72-0.48c0-0.08,0-0.16,0-0.24c-2.118-1.802-4.585-3.255-8.16-3.6
			c-1.52,0-3.04,0-4.56,0c-6.597,0.763-12.958,1.762-19.68,2.4C240.536,86.052,237.425,85.588,238.08,84.68z M285.601,137.96
			c-0.96,0-1.92,0-2.88,0c-6.906,0.945-10.551-4.55-16.32-5.28c-3.014-0.381-5.49,0.185-7.92-0.48c-2.744-0.751-4.45-2.143-6.96-3.6
			c-1.141-0.94-2.181-1.979-3.12-3.12c-1.705-2.695-3.385-5.415-4.32-8.88c-0.398-0.571-0.398-2.789,0-3.36c0.08,0,0.16,0,0.24,0
			c0.697,0.903,1.272,1.927,1.68,3.12c0.24,0.96,0.535,1.865,0.96,2.64c0.645,0.715,0.93,1.791,1.44,2.64
			c2.708,4.732,6.535,8.345,13.2,9.12c0.808,0.152,2.229-0.31,2.64,0.24c6.506,0.454,9.447,4.473,15.6,5.28c0.8,0,1.6,0,2.4,0
			c2.779,0.101,5.026,0.733,6.48,2.16C287.826,139.084,287.022,137.586,285.601,137.96z M328.499,139.688
			c-0.005-0.014-0.012-0.027-0.017-0.042c-0.064-0.199-0.125-0.396-0.188-0.589C328.36,139.262,328.43,139.476,328.499,139.688z
			 M326.712,135.537c-0.035-0.077-0.069-0.155-0.104-0.231C326.643,135.382,326.677,135.46,326.712,135.537z M324.203,130.281
			c-0.047-0.099-0.095-0.198-0.142-0.296C324.108,130.083,324.156,130.183,324.203,130.281z M323.221,128.194
			c-0.117-0.252-0.234-0.504-0.35-0.759C322.986,127.689,323.104,127.941,323.221,128.194z M322.153,125.793
			c-0.108-0.254-0.221-0.505-0.326-0.761C321.933,125.289,322.045,125.54,322.153,125.793z M308.641,122.36
			c-0.704-0.016-0.892-0.548-1.2-0.96c-1.7-1.5-3.3-3.1-4.8-4.8c-2.824-2.296-5.202-5.038-10.32-5.04
			c-1.415-0.083-1.113-2.491,0-2.64c0.08,0,0.16,0,0.24,0c0.414-0.398,2.466-0.398,2.88,0
			C302.101,110.982,306.916,116.161,308.641,122.36z"/>
			</g>
			<g>
				<path fillRule="evenodd" clipRule="evenodd" fill="#344A5E" d="M276.48,420.44c0.205-3.008,1.257-9.142-0.96-9.36
			c-1.237-0.122-3.352,7.773-3.84,9.121c-1.33,3.668-2.464,6.646-4.08,9.359c-4.658-1.387-9.145-4.046-13.2-6.96
			c-4.023-2.891-8.227-5.567-12-8.64c-7.643-6.225-15.649-12.54-20.88-20.64c-2.671-4.136-5.163-8.376-8.4-12.24
			c-7.518-8.974-14.9-21.087-17.76-33.84c-0.629-2.803-0.169-5.939-1.68-8.88c-0.014-0.146-0.036-0.284-0.24-0.24
			c-2.344,1.435-3.894,4.194-6.48,5.28c-3.232,1.358-6.347,2.079-9.36,3.84c-8.467,4.951-17.595,9.28-25.2,14.88
			c10.78,0.571,16.418,7.702,19.92,14.88c1.376,2.822,1.493,6.429,3.36,9.36c0.874,1.373,2.901,2.271,4.32,3.36
			c2.021,1.55,4.111,3.212,5.28,4.8c3.389,4.602,5.922,11.157,8.88,17.04c3.004,5.976,5.374,12.408,8.16,18
			c2.244,4.504,5.5,9.31,6.48,14.16c2.021,10.004-0.069,20.445-0.96,29.28c-0.376,3.731-1.444,7.59-1.68,11.28
			c-0.147,2.3,0.767,5.357,0,8.159c-0.819,2.992-4.365,5.627-2.88,10.561c5.7-8.551,6.479-22.177,7.44-35.04
			c0.328-4.386-0.214-8.986,1.2-12.96c4.725,0.523,10.619,0.24,16.8,0.24c4.061-0.001,8.19-0.469,12.24-0.721
			c8.658-0.538,17.515,0.257,24.96-0.72c5.663-0.743,11.294-4.068,17.52-3.6c0-1.36,0-2.721,0-4.08
			c-2.458-3.769-6.396-8.826-7.68-14.4C275.054,428.65,276.232,424.079,276.48,420.44z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#344A5E" d="M383.761,471.561c-0.632-8.205-4.889-19.911-8.88-24.72
			c-0.576-0.693-1.884-1.314-2.16-2.16c-0.673-2.059,0.257-6.7-0.24-9.36c-0.321-1.718-0.115-2.737,0-4.56
			c0.385-6.077-1.292-10.432-2.4-15.12c-2.146-9.083-6.011-17.14-9.359-24.72c-0.054-0.186-0.007-0.473-0.24-0.48
			c-1.101-2.579-2.355-5.004-4.32-6.72c-0.771-1.389-2.307-2.014-2.64-3.84c-11.872-5.568-20.832-14.048-31.68-20.64
			c0.02,2.98-0.556,5.364-0.48,8.4c-0.226,0.052-0.23,0.203-0.24,0.48c-0.445,13.545-3.082,25.711-7.92,35.28
			c-0.709,4.421-2.059,8.017-3.359,11.28c-2.6,6.518-7.948,12.775-13.2,16.8c-1.119,0.857-2.923,1.665-3.36,2.641
			c-1.539,3.435,0.393,7.561-1.2,11.04c0.913,2.526,2.04,4.84,2.88,7.439c9.664-1.297,22.65-0.55,32.4-0.96
			c-0.166,1.435,0.095,3.295-2.16,2.641c0.333,0.547,1.671,0.089,2.4,0.239c0,2.32,0,4.641,0,6.96
			c1.105-7.877-2.046-24.037,6.72-23.279c2.416,0.208,3.479,2.273,4.561,4.319c4.473-7.064,11.616-1.763,11.04,6.48
			c2.079-0.081,4.111-0.209,6-0.48c0.08,0,0.159,0,0.239,0c4.6-0.28,9.194-0.565,14.16-0.479
			c4.717,10.803,11.411,19.629,13.44,33.12C383.59,478.015,383.988,474.515,383.761,471.561z"/>
			</g>
			<g>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M315.36,113.48c1.272,1.288,2.789,2.331,4.08,3.6
			C318.242,115.718,317.087,114.313,315.36,113.48z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M314.4,211.64c1.28,0,2.561,0,3.84,0
			C317.512,211.242,315.129,211.242,314.4,211.64z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M202.32,235.88c-0.15-0.729,0.307-2.067-0.24-2.4
			c-0.983-6.376-2.024-12.696-4.08-18c1.656,5.185-3.406,6.402-5.28,3.6c-0.982-1.469-0.422-4.662-1.2-6.96
			c-0.611-1.804-1.696-2.271-1.92-3.12c-1.1-1.3-2.3-2.5-3.6-3.6c-1.505-1.539-6.015,1.306-7.2,2.88
			c-2.901,3.854-2.378,17.468-3.36,22.56c0.001,3.046,0.146,1.514,0.48-0.24c1.558-0.74,2.379,1.486,2.64,0.48
			c2.843-5.944,2.3-15.27,1.68-22.32c2.31-0.97,3.434,1.598,4.32,2.88c3.253,4.706,5.988,10.251,6.96,16.56
			c1.007,1.904,6.439,2.726,6.96,5.76c0.496,2.886-2.919,6.127-3.6,9.12c-0.221,0.967-0.618,2.967-0.48,3.84
			c0.307,1.942,3.939,3.203,3.36,5.04c-0.55,1.745-2.604,0.318-3.84,0c-3.531-0.908-7.507-0.833-10.32-2.4
			c2.201,7.239,5.624,13.256,9.84,18.48c1.26,1.46,2.62,2.82,4.08,4.08c0.293,0.027,0.568,0.072,0.48,0.48
			c3.8-1.494,3.132-6.868,2.88-11.76c-0.196-3.8-1.757-8.039-0.24-10.32c0.573-0.502,1.739-0.143,1.92,0.48
			c0,0.241,0.194,0.286,0.24,0.48c0.4-2.242,0.4-6.158,0-8.4C202.767,240.554,202.47,238.291,202.32,235.88z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M183.36,225.08c-0.39-1.569,1.185-2.342-0.72-3.12
			c-0.261,7.434-4.739,14.972-1.2,21.84c2.015-3.585,4.667-6.532,6.96-9.84C188.633,229.778,184.104,228.078,183.36,225.08z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M425.521,589.161c-0.034-5.715,2.316-9.044,3.601-13.44
			c-0.857-0.743-1.829-1.371-2.641-2.16c-0.246-1.766-1.32-3.671-0.72-5.52c-0.341-3.539,1.295-6.116,0.96-8.88
			c-13.393-0.048-17.363-9.517-22.32-18c-1.327-0.054-2.877-0.547-4.08,0c-3.528,1.149-7.134,1.843-11.04,3.12
			c-2.624,0.857-7.87,2.547-9.12,5.04c-1.115,2.226-0.942,5.221-1.92,7.68c-2.681,6.745-7.614,13.27-7.68,21.6
			c-0.348,0.772-1.673,0.567-2.4,0.96c6.246,0.234,10.262,2.699,14.16,5.28c0.98,0.78,1.86,1.66,2.641,2.641
			c8.324,10.955,11.289,27.271,12,45.84c2.612-1.63,4.46-3.726,8.399-4.8c6.355-1.733,13.06-2.118,15.841-6.721
			c1.419-1.62,2.939-3.14,4.56-4.56c1.511-1.997,3.428-5.614,3.84-8.16C430.618,602.798,425.352,596.251,425.521,589.161z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M236.16,600.921c6.554,5.133,14.24,8.828,20.4,13.2
			c0.198-2.729,0.5-5.604-0.96-7.2c-0.084-0.075-0.161-0.159-0.242-0.238c0-0.001,0.001-0.001,0.001-0.002
			c-0.004-0.002-0.007-0.005-0.011-0.008c-1.327-1.307-2.22-3.047-4.069-3.832c-1.894-1.065-3.721-2.198-5.76-3.12
			c-0.502-0.697-1.447-0.952-2.16-1.439c-5.229-3.651-9.781-7.979-14.4-12.24c-0.217-0.423-0.537-0.743-0.96-0.96
			c-0.49-0.07-0.471-0.649-0.96-0.72c-1.737-1.623-3.309-3.411-6.48-3.601c-1.259,2.679,0.023,6.349,0.96,9.12
			c0.975,2.884,2.565,5.824,4.8,6.96C230.93,595.025,233.348,598.719,236.16,600.921z M254.663,606.205
			c-1.345-1.063-2.176-2.642-3.863-3.364C252.487,603.563,253.318,605.142,254.663,606.205z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M258.72,620.841c-0.014-0.146-0.036-0.284-0.24-0.239
			c-5.308-6.372-13.5-9.861-20.4-14.641c-0.384-0.415-0.896-0.703-1.44-0.96c-2.926-1.642-4.922-5.467-8.64-5.52
			c-0.335,6.335,3.182,10.897,6,14.88c0.699,0.9,1.5,1.7,2.4,2.399c3.302,3.099,9.082,3.719,10.8,8.4c0.08,0,0.16,0,0.24,0
			c-0.078,1.255,1.713,0.559,2.16,0.24c2.021-0.14,3.398-0.922,5.76-0.721c0.622,0.419,1.116,0.965,1.44,1.681
			c0.131,0.349-0.28,1.24,0.24,1.2c0.514-1.809,3.536-2.381,2.88-4.561C259.646,622.155,259.393,621.289,258.72,620.841z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M350.16,205.4c-4.573-2.628-6.054,8.122-7.68,11.28
			c1.351,2.889,2.339,6.141,2.88,9.84c0.155,1.045-0.313,2.713,0.24,3.36c0.868-3.084,2.246-6.714,3.84-10.32
			C351.103,215.8,354.002,207.514,350.16,205.4z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M350.155,225.506c0,0.005-0.001,0.01-0.001,0.014
			C350.154,225.516,350.155,225.511,350.155,225.506c-0.002-0.063,0.009-0.124,0.005-0.186c-0.036,0.134-0.084,0.271-0.124,0.407
			c0,0,0,0,0.001-0.001c-0.506,1.708-1.299,3.486-1.863,5.245c0.562-1.757,1.356-3.536,1.862-5.244
			c-1.777,3.461-3.218,8.013-3.476,12.073c1.098-4.662,3.635-7.885,4.08-13.2C350.476,224.887,350.315,225.2,350.155,225.506
			L350.155,225.506z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M342.001,248.36c-1.598,6.482-3.378,12.782-4.08,20.16
			c0.563,0.715,1.097,1.463,1.92,1.92c0.807-0.633,1.526-1.354,2.16-2.16C341.668,261.467,342.581,254.622,342.001,248.36z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M316.561,319.161c-0.063,0.737-0.521,1.079-0.72,1.68
			c-1.044,2.375-2.408,5.116-3.841,8.16c-1.482,3.154-2.732,7.899-5.52,8.64c-2.447,0.599-1.746,2.983-2.88,4.56
			c-2.155,2.996-7.143,5.812-10.8,7.92c-4.123,2.376-8.925,3.801-12.72,6c3.078,1.642,7.776,1.663,9.839,4.32
			c-1.008,3.073-1.813,7.625-2.399,10.32c2.005-3.254,3.005-6.791,5.52-9.36c2.88-2.941,7.428-4.245,9.601-7.92
			c1.82-1.761,3.38-4.3,5.085-6.685c-0.047,0.054-0.094,0.107-0.142,0.161c0.048-0.054,0.095-0.108,0.143-0.163
			c0,0-0.001,0.001-0.001,0.001c0.005-0.005,0.01-0.011,0.015-0.017c0.504-0.577,1.007-1.158,1.498-1.739
			c0,0,0.001-0.001,0.001-0.001c0.49-0.581,0.963-1.157,1.408-1.726c0.004-0.005,0.009-0.011,0.013-0.016
			c0.005-0.006,0.01-0.013,0.015-0.019c-0.005,0.005-0.009,0.011-0.013,0.016c1.012-1.14,1.964-2.287,2.538-3.495
			c2.331-4.904,1.696-12.009,3.36-18.96C316.361,320.214,317.286,319.524,316.561,319.161z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M246.48,596.121c0.735,0.785,1.828,1.212,2.88,1.68
			c0.024,0.616,0.895,0.386,1.2,0.721c-1.062-1.98-4.1-4.061-5.76-6.24c-0.217-0.424-0.537-0.743-0.96-0.96
			c-0.913-1.007-1.672-2.168-2.64-3.12c-0.562-1.038-1.198-2.002-1.92-2.88c-1.52-3.12-2.8-6.479-4.32-9.601
			c-0.319-1.121-0.789-2.091-1.92-2.399c-1.523,2.557-2.37,5.79-2.16,10.08c3.936,3.904,8.17,7.51,12.72,10.8
			C244.481,594.92,245.339,595.662,246.48,596.121z"/>
			</g>
			<g>
				<path fillRule="evenodd" clipRule="evenodd" fill="#DD2427" d="M335.04,473.001c0,1.04,0,2.08,0,3.12
			c0.558,1.362,0.083,3.758,0.24,5.52c0,0.8,0,1.601,0,2.4c0.4,2.162,0.4,5.998,0,8.16c0,0.72,0,1.439,0,2.16
			c0,1.119,0,2.239,0,3.359c0.025,1.016,0.646,1.434,1.44,1.68c0-0.159,0-0.319,0-0.479c0.157-1.603-0.317-3.837,0.24-5.04
			c0-0.48,0-0.96,0-1.44c0.159-6-0.32-12.64,0.239-18.239c-0.173-0.733,0.246-0.875,0.24-1.44
			C336.373,472.573,336,473.08,335.04,473.001z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#DD2427" d="M337.44,452.36c-0.013-3.268-0.439-6.12-1.439-8.399
			c-0.288-0.992-0.921-1.641-1.681-2.16c-2.566-0.565-2.896,3.182-2.88,6c-0.15,0.729,0.308,2.067-0.24,2.4c0,0.239,0,0.479,0,0.72
			c-0.157,1.522,0.316,3.677-0.239,4.8c0,0.08,0,0.16,0,0.24c-0.154,0.966,0.312,2.552-0.24,3.12c0,0.239,0,0.479,0,0.72
			c-0.157,1.363,0.315,3.355-0.24,4.32c0,0.16,0,0.319,0,0.479c-0.154,1.046,0.313,2.713-0.24,3.36c0,0.16,0,0.32,0,0.479
			c0.085,0.956-0.299,2.38,0.48,2.641c0.578-0.099,0.521,0.438,1.2,0.239c0.436-0.283,0.188-1.251,0.479-1.68c0-2.88,0-5.76,0-8.64
			c-0.362-1.76,1.73-3.559,2.4-1.44c0.559,2.881,0.08,6.8,0.239,10.08c1.022-0.142,1.588,0.173,2.4,0.24c0-0.24,0-0.48,0-0.72
			c0.158-1.763-0.317-4.157,0.24-5.521c0-2.08,0-4.16,0-6.24c0-0.159,0-0.319,0-0.479
			C337.523,455.478,337.996,453.404,337.44,452.36z"/>
			</g>
			<g>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1D8DC" d="M316.801,334.041c-0.124,8.357-5.539,11.421-8.641,16.8
			c-1.275,1.524-2.526,3.074-3.6,4.8c-0.699,0.9-1.5,1.7-2.4,2.4c-2.063,1.458-4.042,2.998-6,4.56
			c1.562-0.251,2.593,1.113,3.12,2.16c2.243,4.454,1.42,12.896,2.88,18.24c0,1.36,0,2.72,0,4.08c0.014,0.146,0.036,0.284,0.24,0.24
			c0,4.72,0,9.44,0,14.16c-0.146,0.014-0.284,0.036-0.24,0.24c0,1.76,0,3.52,0,5.28c-0.412,4.468-2.824,8.214-3.6,11.52
			c5.105-6.415,10.812-12.228,14.4-20.16c1.572-2.507,1.437-6.723,3.359-8.88c0.491-11.316,3.098-22.919,2.16-34.08
			C320.268,348.608,319.299,339.311,316.801,334.041z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1D8DC" d="M231.84,331.88c-1.061-1.819-2.581-3.179-3.84-4.8
			c-0.057-0.743-0.668-0.932-0.96-1.44c0.107,0.773,0.46,1.3,0.48,2.16c1.85,5.209,2.965,10.49,5.28,14.88
			c0.815,1.546,2.399,2.808,2.16,4.8c-3.098,1.347-4.072-2.388-6.24-3.36c-2.012-0.902-4.469-0.813-6.96-0.96
			c4.287,6.513,9.187,12.414,14.4,18c0.7,0.9,1.5,1.701,2.4,2.4c0.396,0.644,1.017,1.063,1.44,1.68
			c-0.025,0.745,0.755,0.685,0.48,1.68c-1.336-0.104-2.187-0.693-3.12-1.2c-1.02-1.22-2.14-2.34-3.36-3.36
			c-3.387-4.374-7.676-7.844-12-11.28c-0.522-1.138-1.438-2.626-2.4-3.84c-0.745-0.94-2.492-2.979-2.88-2.64
			c-0.57,0.15,0.037,1.477-0.48,1.68c0,0.88,0,1.76,0,2.64c-0.146,0.014-0.284,0.036-0.24,0.24c0,1.44,0,2.88,0,4.32
			c0.48,1.04,0.164,2.876,0.72,3.84c0,1.04,0,2.08,0,3.12c0.014,0.146,0.036,0.284,0.24,0.24c0,3.6,0,7.2,0,10.8
			c0.013,0.146,0.036,0.284,0.24,0.24c0,2.4,0,4.8,0,7.2c0.313,1.004,0.448,2.792,0.24,3.6c4.351,5.809,7.409,12.911,12.96,17.52
			c0.779,0.98,1.66,1.86,2.64,2.64c10.457,8.583,20.604,17.477,33.36,23.76c0.602-1.639,1.307-3.173,1.92-4.8
			c7.114-17.846,12.422-37.498,18-56.88c0.229-1.051,0.251-2.31,0.48-3.36c-20.08-6.32-37.105-15.695-52.32-26.88
			C233.5,333.741,232.62,332.861,231.84,331.88z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#D1D8DC" d="M282.48,390.44c-1.283,0.394-2.541,5.959-3.36,8.4
			c-1.182,3.519-2.232,6.358-2.88,8.88c3.106-0.385,4.194,2.524,6,2.64c0-3.6,0-7.2,0-10.8c0.146-0.014,0.284-0.036,0.24-0.24
			C282.48,396.361,282.48,393.401,282.48,390.44z"/>
			</g>
			<g>
				<g>
					<path fillRule="evenodd" clipRule="evenodd" fill="#1B4E6D" d="M346.08,464.601c-0.361,0.679-1.632,0.448-2.159,0.96
				c2.336,7.728-1.081,19.681,0.239,27.601c1.961-8.2,0.083-20.238,2.16-28.32C346.307,464.694,346.285,464.557,346.08,464.601z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#1B4E6D" d="M346.801,456.201c0-1.681,0-3.36,0-5.04c0-0.32,0-0.641,0-0.96
				c-0.152-0.809,0.309-2.229-0.24-2.641c0-0.08,0-0.16,0-0.24c-0.028-0.691,0.131-1.57-0.24-1.92
				c-0.436-6.527-5.617-1.842-6.24,1.44c1.142,5.71,0.614,14.01,0.721,20.64c0.02,1.211-0.677,3.804,1.439,3.601
				c-0.615-3.192,1.169-5.554,0-7.2c0.547-1.992,3.726-1.244,4.32-1.44c0.16-1.76,0.336-3.504,0.24-5.52
				C346.801,456.681,346.801,456.44,346.801,456.201z"/>
				</g>
				<g>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M488.161,646.761c0.006-0.407,0.007-0.813,0.011-1.219
				C488.141,645.977,488.098,646.422,488.161,646.761z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M488.18,644.722c-0.002,0.273-0.005,0.547-0.008,0.82
				C488.194,645.25,488.205,644.967,488.18,644.722z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M484.081,605.241c-2.76-9.846-6.55-18.787-10.561-28.08
				c-6.744-15.628-14.466-30.891-21.359-46.32c-0.284-0.636-0.613-1.286-0.951-1.938c-0.219-0.475-0.441-0.951-0.669-1.432
				c-0.546-1.161-1.096-2.351-1.561-3.63c0,0,0,0,0,0.001c-0.142-0.39-0.273-0.787-0.396-1.194c0.123,0.406,0.254,0.805,0.396,1.193
				c-0.501-2.134,0.044-4.288-1.38-5.96c-0.365-0.072-1.743,0.913-2.88,0.24c-0.652-3.559-2.731-4.617-4.56-7.2
				c-3.63-5.128-5.284-13.729-8.641-18.24c-5.81-1.649-6.114-6.844-7.2-12.96c-2.933-16.521-6.875-33.673-11.52-48
				c-4.025-0.025-7.955,0.046-11.521,0.48c-0.399,0-0.8,0-1.199,0c-8.552,0.488-16.867,1.213-24.721,2.399
				c-0.099,2.819,0.427,5.014,0.24,7.92c6.793,9.643,11.929,23.936,11.04,40.08c0.099-2.302,0.825-3.975,1.44-5.76
				c0.196-0.764,0.152-1.767,0.479-2.4c0.95,0.25,0.168,2.232,0.72,2.88c0.493,5.49,0.014,11.986-3.359,14.4
				c-0.306,2.239-1.072,4.469-1.681,6.72c-0.624,2.314-0.834,5.054-1.68,6.96c-0.465,1.05-2.109,1.457-1.68,2.881
				c5.826-4.33,10.143-12.772,12.96-20.4c0.396-1.072,0.188-3,1.68-3.36c0.762,2.379,1.218,5.246,0.72,7.44
				c-1.935,8.522-9.945,16.232-15.6,20.64c4.826,0.854,9.864,1.496,10.56,6.48c-4.813,0.093-6.67-2.77-11.04-3.12
				c-0.728,3.031-0.04,7.479-0.96,10.32c-0.179,0.86-2.203-0.124-1.92,1.199c0.42,0.838,1.435,1.057,1.92,2.16
				c1.489,3.388,2.255,9.021,2.16,14.16c8.103-1.324,16.53-8.056,25.2-5.28c1.291,0.414,3.069,4.213,3.84,5.521
				c3.469,5.881,5.31,9.054,12.48,10.8c1.539,0.375,3.895,0.188,5.52,0.96c4.953,2.351-0.984,11.102,1.44,15.601
				c1.82,1.139,3.608,2.312,3.12,5.76c0.85-0.67,1.828-1.212,2.399-2.16c0.08,0,0.16,0,0.24,0s0.16,0,0.24,0
				c0.645-1.035,1.752-1.608,2.64-2.4c2.236-0.738,7.612-7.66,8.88-2.88c-0.248,1.432-1.577,1.783-2.399,2.641
				c-2.537,1.383-4.363,3.477-6.721,5.04c-0.74,0.539-1.38,1.179-1.92,1.92c-0.08,0-0.159,0-0.239,0c-0.94,1.14-1.98,2.18-3.12,3.12
				c0,0.079,0,0.16,0,0.239c-0.979,1.183-1.98,2.34-2.88,3.601c-0.08,0-0.16,0-0.24,0c0.28,1.174,5.114-0.379,7.439,0
				c0.729,0.15,2.067-0.308,2.4,0.24c4.475,1.125,11.163,0.036,13.44,3.359c0.072,0.69-2.139,1.191-0.96,1.44
				c9.387,1.493,16.829,4.93,23.52,9.12c0,0.08,0,0.16,0,0.239c0,0.32,0,0.641,0,0.96c-1.439,1.906-3.824-0.363-5.28-0.72
				c-0.802-0.397-1.598-0.802-2.399-1.2c0-0.08,0-0.16,0-0.239c-6.302-2.979-13.011-5.55-22.08-5.761
				c-1.204-0.155-3.035,0.314-3.84-0.239c-4.321-0.081-8.511-0.03-11.521,1.199c0.796,4.294,2.706,6.479,3.12,10.32
				c0.589,5.467-1.635,9.902-3.36,12.72c-2.372,3.876-7.579,9.752-12.479,12c-3.847,1.766-8.561,1.119-12.72,2.881
				c-3.147,1.332-5.583,4.67-8.16,6.239c-0.438,8.362-3.154,14.446-6.48,19.92c-9.664,3.223-16.303,9.112-23.28,14.881
				c-4.132,3.415-7.129,7.745-13.92,7.92c-0.523,3.156-1.765,5.595-2.64,8.399c0.088,0.632-1.197,1.566,0.24,1.44
				c31.435-4.585,65.358-7.985,91.2-18.24c8.805-3.494,16.681-8.317,24.96-11.76c0.492-0.787,1.644-0.917,2.399-1.44
				c6.376-3.384,12.265-7.256,18.721-10.56c0-0.24,0-0.48,0-0.72c-0.557-1.045-0.083-3.117-0.24-4.561
				c0.164,0.123,0.232,0.341,0.259,0.601C488.281,630.146,487.16,616.227,484.081,605.241z M437.761,516.201c0,0.16,0,0.319,0,0.479
				c0,0.641,0,1.28,0,1.92c-0.188,0.054-0.279,0.201-0.24,0.48c-0.24-0.001-0.285,0.194-0.479,0.24
				c-2.419,6.134-5.38,12.624-7.681,18.96c-0.679,1.87-1.127,4.836-3.6,5.279c-1.531-1.253,0.279-2.564,0.72-3.6
				c1.322-3.106,2.459-7.46,4.32-11.76c1.888-4.36,4.326-8.557,4.56-12.96c0-0.8,0-1.601,0-2.4c0.086-2.006-0.61-3.229-0.479-5.28
				c-0.039-0.918-0.144-1.903,0.72-1.92c-0.001,0.241,0.194,0.286,0.24,0.48c0.54,0.819,1.211,1.509,1.439,2.64
				c0.063,1.217,0.251,2.31,0.48,3.36C438.159,512.929,438.159,515.394,437.761,516.201z M445.921,525.081
				c-0.307,1.054-0.886,1.835-1.44,2.64c-0.771,0.801-1.914,2.336-3.6,1.44c0.257-2.304,2.242-2.878,2.88-4.8
				c0.439-0.761,0.813-1.588,1.92-1.681C446.106,523.135,445.855,524.266,445.921,525.081z M463.201,575.721
				c0.904,0.375,1.545,1.016,1.92,1.92C464.38,577.102,463.74,576.462,463.201,575.721z M469.466,588.389
				c0.001,0.001,0.001,0.002,0.002,0.003c0.076,0.148,0.148,0.299,0.224,0.448C469.616,588.689,469.543,588.538,469.466,588.389z
				 M476.987,604.176c0,0.001,0,0.002,0.001,0.002c0,0.002,0.001,0.004,0.002,0.006
				C476.989,604.181,476.988,604.178,476.987,604.176z M478.014,607.038c-0.001-0.001-0.001-0.002-0.001-0.003
				c0.003,0.008,0.006,0.016,0.009,0.023C478.019,607.052,478.016,607.045,478.014,607.038z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M381.841,707.961c0-0.239,0-0.479,0-0.72
				c-0.558-1.362-0.082-3.758-0.24-5.52c-0.025-3.895-0.56-7.28-1.44-10.32c-0.578-0.099-0.521,0.438-1.199,0.24
				c-0.885,0.555-2.797,0.083-4.08,0.239c-0.24,0-0.48,0-0.721,0c-0.41,0.55-1.832,0.088-2.64,0.24
				c-5.654,0.666-10.853,1.787-16.32,2.641c-1.789,0.983-3.72-0.852-5.04,0.239c-4.79,0.25-8.032,2.048-12.239,2.88
				c-1.231,0.93-2.193,2.127-3.601,2.881c-1.917,0.963-4.221,1.539-6.72,1.92c-0.568,0.552-2.154,0.086-3.12,0.239
				c0.317,1.683,0.72,3.281,1.2,4.801c0.468,0.892,0.65,2.069,0.72,3.359c0.036,1.244,0.175,2.386,0.48,3.36
				c1.802,9.575,1.271,22.309,0.72,32.88c1.362,0.558,3.758,0.082,5.52,0.24c0.483-8.079-0.011-15.776-0.239-23.76
				c0-0.08,0-0.16,0-0.24c-0.553-0.568-0.086-2.154-0.24-3.12c-0.228-5.772-1.495-10.505-2.16-15.84
				c0.606-1.873,3.444-0.688,3.12,1.199c-0.076,0.557,0.038,0.922,0.24,1.2c0.55,1.689,0.862,3.618,0.96,5.761
				c0.146,2.813,0.687,5.233,0.96,7.92c6.42-2.002,13.701-4.349,21.12-4.561c1.694-0.398,0.215,1.588-0.721,1.92
				c-5.663,2.977-14.223,3.057-19.92,6c-0.016,3.776,0.655,6.865,0.48,10.801c0.324-0.076,0.458-0.342,0.96-0.24
				c3.881-0.707,6.845-2.998,9.84-4.8c3.173-1.909,6.613-2.983,9.84-5.04c-0.278-0.202-0.644-0.317-1.2-0.24
				c-1.802-0.198-4.467,0.467-5.52-0.48c0-0.08,0-0.16,0-0.239c0.696-2.786,9.021-2.255,9.12,0.96
				c0.091,2.952-5.277,3.748-7.681,5.04c-3.299,1.773-5.261,4.052-7.68,5.04c-0.757,0.843-1.964,1.235-3.12,1.68
				c-1.623,0.297-2.185,1.655-3.6,2.16c-1.036-0.146-1.32,1.706-0.72,2.399c7.503-3.385,16.394-5.964,24.479-8.64
				c2.553-0.845,5.462-3.132,8.16-1.68c0.975,0.675-0.044,2.176-0.96,2.399c-10.441,3.719-22.226,6.095-31.92,10.561
				c0.09,1.449-0.068,2.652-0.24,3.84c11.521,0.16,23.68-0.32,34.8,0.24c0.16,0,0.32,0,0.48,0c0.08,0,0.16,0,0.24,0
				c0.41,0.549,1.832,0.088,2.64,0.239c1.76,0,3.52,0,5.28,0c0-0.399,0-0.8,0-1.199c0.959-5.121,2.183-9.978,2.88-15.36
				c0.556-0.965,0.083-2.957,0.24-4.32c0.171-1.211-0.328-1.752-0.48-2.64c-1.396-0.116-1.403,1.156-3.12,0.72
				c-1.314-4.021,4.463-4.711,5.76-7.68c-1.667-1.772-3.104-3.776-3.119-7.2C381.93,709.632,382.388,708.294,381.841,707.961z
				 M373.44,710.602c-1.495,0.505-2.897,1.102-4.56,1.439c-0.723,0.629-2.183-0.047-2.16-0.96c0-0.239,0-0.479,0-0.72
				c1.256-1.624,3.806-1.954,6.479-2.16C374.786,708.164,374.554,710.285,373.44,710.602z M382.627,716.386
				c-0.818-0.782-1.801-1.481-2.513-2.326c0.714,0.845,1.696,1.545,2.516,2.328C382.629,716.387,382.628,716.387,382.627,716.386z"
					/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M133.92,613.641c-1.943-3.896-3.723-7.957-5.76-11.76
				c-0.022,15.258-1.39,30.45-2.4,43.92c3.712-7.728,7.354-15.525,11.28-23.04C135.648,620.2,134.046,615.942,133.92,613.641z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M142.8,648.682c2.305-2.095,3.159-5.641,4.8-8.4
				c-2.628-4.172-4.644-8.956-7.44-12.96c-4.349,7.571-7.549,16.291-12,23.76c0.42,0.848,0.189,1.976,0,2.88
				C132.782,651.943,136.979,649.5,142.8,648.682z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M158.4,677.241c-6.171-2.544-12.634,1.142-18.48,1.92
				c0.223,1.777,0.024,3.977,0.72,5.28C145.874,681.355,154.869,682.03,158.4,677.241z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M297.841,464.36c-0.144,0.674-1.102,1.455-1.2,2.4
				c-0.371,3.533,2.172,5.368,3.359,7.44c3.426,5.976,5.136,13.209,6,20.64c0.972,8.34-0.18,17.332,0.48,26.4
				c0.432,5.933,3.857,10.872,4.32,15.84c0.604,6.494-0.444,13.906-1.44,19.68c-0.389,2.254-1.427,4.394-1.2,6
				c0.201,1.426,1.922,2.808,2.641,4.561c0.411,1.002,0.36,2.11,0.72,2.88c0.91,1.947,2.721,3.563,3.84,5.52
				c3.939,6.885,7.192,14.93,7.2,25.44c2.803-1.144,1.927-4.466,2.88-6.48c2.083-4.402,8.512-4.417,13.68-5.76
				c3.983-1.034,6.907-3.03,9.841-4.8c0.527-0.271,0.017-1.583,0.72-1.68c4.502-2.539,10.802-3.278,18-3.12
				c-2.926-7.106,4.279-17.126,6.72-23.04c1.025-2.483,1.161-4.918,1.92-7.44c0.276-0.917,1.025-1.657,1.2-2.64
				c0.851-4.778-1.033-10.887-2.4-14.88c-0.345,3.174-0.165,6.874-0.72,9.84c0,0.08,0,0.16,0,0.24
				c-0.154,0.966,0.313,2.552-0.24,3.119c-0.308,2.412-1.313,4.128-1.68,6.48c-0.17,1.03-0.392,2.009-0.96,2.64
				c-1.305,4.056-3.708,7.012-5.76,10.32c-2.179,2.702-4.171,5.589-6.72,7.92c0,0.08,0,0.16,0,0.24c-1.261,1.46-2.62,2.82-4.08,4.08
				c-1.175,0.266-1.424,1.457-2.4,1.92c-0.939,1.141-1.979,2.18-3.12,3.12c-0.563,0.076-0.9,0.379-1.68,0.24
				c-0.233,1.618-3.13,2.855-4.08,0.96c-0.806-1.675-2.289-2.672-3.36-4.08c-2.02-1.82-3.939-3.74-5.76-5.761c-0.08,0-0.16,0-0.24,0
				c-1.38-1.18-2.66-2.459-3.84-3.84c-0.08,0-0.16,0-0.24,0c-1.7-1.5-3.3-3.1-4.8-4.8c0-0.08,0-0.16,0-0.24
				c-1.22-1.02-2.34-2.14-3.36-3.359c0-0.08,0-0.16,0-0.24c-2.313-2.246-4.816-4.303-6.72-6.96c-0.728-1.272-1.476-2.525-1.92-4.08
				c-1.039-7.281-0.218-16.422-0.479-24.48c0-0.08,0-0.159,0-0.239c-0.553-0.568-0.086-2.154-0.24-3.12
				c-0.065-0.815,0.186-1.946-0.24-2.4c-0.549-0.411-0.088-1.832-0.24-2.64c-0.164-3.365,0.709-5.691,0.721-8.88
				c0.152-0.888-0.312-2.392,0.239-2.881c0.327-1.272,0.451-2.749,0.48-4.319c-0.171-1.932,0.92-2.601,0.96-4.32
				c0.272-2.752-0.784-4.176-0.72-6.72c-0.4-1.604-0.4-4.877,0-6.48c0.356,0.203,0.347,0.772,0.479,1.2
				c0.025,0.614,0.136,1.145,0.48,1.44c0.011-1.611-0.303-2.897-0.48-4.32c-0.547-0.333-0.09-1.671-0.24-2.4c0-0.72,0-1.439,0-2.16
				c-0.032-1.552,0.479-2.561,1.44-3.119c0.799-0.241,1.648-0.432,2.399-0.721c0.414-0.397,2.467-0.397,2.881,0c2,0,4,0,6,0
				c-0.074-0.874,0.038-1.562,0.239-2.16c0.151-0.729-0.307-2.066,0.24-2.399c0-0.08,0-0.16,0-0.24c0.158-1.922-0.317-4.478,0.24-6
				c0-0.08,0-0.16,0-0.24c0-2.319,0-4.64,0-6.96c-0.729-0.15-2.067,0.308-2.4-0.239c-9.229,0.733-20.672,0.389-29.52,0.72
				C296.271,457.488,298.703,460.311,297.841,464.36z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M202.56,508.521c-0.052,1.229-0.626,1.935-1.2,2.641
				c5.671,9.625,7.98,23.361,12,34.319c1.23,3.354,2.236,7.033,3.84,9.841c1.751,3.064,5.041,5.316,6.96,8.399
				c2.214,3.556,3.015,6.957,4.8,10.801c0.545-2.695,2.405-5.221,6-4.08c1.077,0.522,1.877,1.322,2.4,2.399
				c2.45,6.11,4.85,12.27,9.36,16.32c1.582,1.538,3.427,2.813,4.8,4.56c0.08,0,0.16,0,0.24,0c0.98,0.78,1.86,1.66,2.64,2.641
				c1.246,0.914,2.545,1.775,3.12,3.359c1.001,1.022-0.71,2.358-0.24,2.881c0.08,0,0.16,0,0.24,0c0.98,0.779,1.86,1.659,2.64,2.64
				c0.063,0.737,0.521,1.079,0.72,1.68c0.397,0.336,0.397,2.304,0,2.641c0,0.079,0,0.159,0,0.239
				c-0.157,1.443,0.316,3.517-0.24,4.561c-1.887,3.776,1.166,4.474,1.92,7.92c3.453-1.068,6.135-4.431,10.08-5.04
				c3.152-0.487,6.431-0.023,10.08-0.96c0.411-0.55,1.832-0.088,2.64-0.24c0.4,0,0.8,0,1.2,0c0.489-0.551,1.993-0.087,2.88-0.24
				c6.559-0.241,11.06-2.54,15.6-4.8c0.493-0.787,1.644-0.916,2.4-1.439c1.217-0.464,2.475-0.886,3.12-1.921
				c1.552-9.361-0.845-17.938-4.561-23.52c-8.199,2.016-18.538,5.61-27.359,4.56c0.477-1.698,2.491-0.925,3.6-1.199
				c1.779-0.442,3.339-1.286,5.28-1.681c6.018-1.222,12.309-2.291,17.28-4.319c-2.006-4.314-4.445-8.195-6.96-12
				c-1.859,3.731-6.502,4.412-10.8,5.76c-2.279,0.714-4.696,2.562-6.72,1.439c1.828-2.289,5.089-2.78,7.44-4.319
				c2.883-1.889,5.264-4.286,7.2-6.96c-1.033-1.898-2.467-3.362-2.159-5.521c0.301-2.114,3.21-3.629,4.08-5.04
				c2.234-3.626,1.149-8.57,0.479-13.439c-1.23-8.94-2.728-16.884-3.36-26.16c-0.764-11.204-0.586-26.354-4.8-34.561
				c-0.815-1.588-2.23-2.716-2.4-4.32c-0.331-3.125,2.231-7.054,0.48-9.84c-5.765,0.165-3.358-7.842-3.84-12.96
				c-6.359-0.267-11.961,3.179-18.24,3.601c-3.913,0.262-7.841-0.179-11.76,0c-12.956,0.588-26.051,2.007-39.6,1.439
				C212.529,477.689,214.139,499.699,202.56,508.521z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M318.961,476.121c-0.853,0.348-0.349,2.052-0.48,3.12
				c0,0.159,0,0.319,0,0.479c1.238,1.722-1.444,7.364,1.68,7.2c0.08,0,0.16,0,0.24,0c0.411-0.549,1.832-0.088,2.64-0.24
				c0.08,0,0.16,0,0.24,0c0.568-0.552,2.154-0.086,3.12-0.24c0.08,0,0.16,0,0.24,0c1.857-0.382,4.13-0.35,6.24-0.479
				c0-0.08,0-0.16,0-0.24c-0.559-2.241-0.081-5.519-0.24-8.16c0-0.24,0-0.479,0-0.72c-0.763-0.117,0.403-2.163-0.72-1.92
				C327.955,475.907,323.161,474.677,318.961,476.121z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M341.761,597.081c3.537,1.743,6.338,4.223,8.88,6.96
				c1.642,1.318,2.994,2.926,4.32,4.561c1.528,2.312,3.253,4.427,4.8,6.72c-2.296-10.024-5.219-19.421-16.561-20.4
				C343.43,596.351,342.407,596.527,341.761,597.081z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M324.48,721.401c-0.363-6.117-1.01-11.95-2.88-16.561
				c-1.027,3.178-1.492,6.148-4.561,6.96c-2.894,0.767-6.313-0.689-10.079-0.479c-0.729-0.15-2.067,0.308-2.4-0.24
				c-0.72,0-1.44,0-2.16,0c-1.283-0.156-3.195,0.315-4.08-0.239c-0.32,0-0.64,0-0.96,0c-0.729-0.151-2.067,0.307-2.4-0.24
				c-0.56,0-1.119,0-1.68,0c-0.729-0.15-2.067,0.307-2.399-0.24c-1.207-0.233-2.39-0.49-3.601-0.72
				c-6.621,2.929-14.316,5.713-22.32,8.399c-7.854,2.637-16.223,5.746-24.48,6.24c-3.01,0.181-6.244-0.004-8.88-0.479
				c-1.845-0.334-4.079-2.147-6.24-3.36c-14.993-8.42-31.752-14.188-43.2-25.44c-6.879-6.761-11.235-14.911-16.56-22.56
				c-5.491-7.889-10.755-16.082-14.4-24.721c-3.479,1.984-5.139,6.347-7.68,9.601c-1.92,2.459-4.075,4.862-6,6.96
				c-0.42,0.458-1.808,1.33-1.2,2.16c4.276-0.52,6.787-2.376,10.08-4.561c1.02-0.676,8.442-6.726,9.36-2.64
				c0.381,1.694-1.996,3.499-2.88,4.32c-2.874,2.67-4.888,4.598-7.44,6.96c-0.98,0.906-3.229,3.279-2.64,3.12
				c5.893-2.528,20.988-4.352,19.68,4.56c-0.561,3.822-5.814,5.115-9.84,6c-4.462,0.98-8.655,2.278-11.76,4.08
				c1.909,4.392,4.726,7.938,9.36,9.6c1.84,0.66,4.089,0.473,6,1.44c1.642,0.832,3.306,3.229,4.8,4.8
				c1.604,1.685,3.227,3.541,5.04,4.8c3.094,2.148,5.995,2.112,10.32,3.601c1.801,0.619,5.914,1.713,5.28,4.08
				c-0.538,2.008-5.714,0.988-7.2,0.72c-8.31-1.502-13.731-4.278-20.64-6.24c-1.089,1.761-6.274,6.105-6,8.641
				c0.232,2.146,3.233,2.636,4.8,4.08c0.283,0.893,0.786,1.582,1.44,2.159c3.702,3.271,10.509,4.104,16.56,5.04
				c2.162,0.159,4.958-0.317,6.72,0.24c9.583,0.372,17.615,2.386,25.2,5.521c3.461,1.43,7.016,3.272,10.56,4.8
				c4.137,1.782,10.618,2.814,13.44,5.52c2.477,0.846,8.043,0.48,12.24,0.48c28.676,0,58.319,0.197,85.44-0.24c0-0.08,0-0.16,0-0.24
				c0.153-0.887-0.311-2.391,0.24-2.88c0-0.08,0-0.16,0-0.24c0.157-1.602-0.316-3.836,0.24-5.04c0-0.079,0-0.159,0-0.239
				c0-4.641,0-9.28,0-13.92c0-0.08,0-0.16,0-0.24C324.168,723.953,324.635,722.367,324.48,721.401z M212.16,728.602
				c-2.964,0.35-5.172-2.651-6.72-0.24c-1.009,0.835-4.388,0.567-5.76,0.24c-0.56,0-1.12,0-1.68,0c-1.2,0-2.4,0-3.6,0
				c-0.4,0-0.8,0-1.2,0c-0.568,0.552-2.154,0.086-3.12,0.24c-0.32,0-0.64,0-0.96,0c-0.333,0.547-1.67,0.089-2.4,0.239
				c-1.92,0-3.84,0-5.76,0c-1.283-0.156-3.195,0.315-4.08-0.239c-2.271-0.37-5.211-0.069-5.52-2.4
				c0.315-0.085,0.377-0.423,0.72-0.48c0.492-0.397,2.628-0.397,3.12,0c0.56,0,1.12,0,1.68,0c2.48,0,4.96,0,7.44,0
				c2.535,0.056,4.644-0.315,6.96-0.479c0.647-0.553,2.315-0.085,3.36-0.24c0.64,0,1.28,0,1.92,0c-1.503-0.497-3.362-0.638-4.8-1.2
				c-1.263-0.417-2.409-0.951-3.12-1.92c-0.08,0-0.16,0-0.24,0c-2.058-1.702-4.301-3.219-5.28-6c0.102-0.501-0.165-0.636-0.24-0.96
				c-0.456-0.425-0.745-1.015-1.2-1.439c0.085-0.315,0.422-0.378,0.48-0.721c0.857,0.104,1.317,0.604,1.68,1.2
				c0.669,0.211,1.186,0.574,1.92,0.72c1.477,1.084,2.817,2.303,4.08,3.601c5.397,4.043,15.916,2.964,22.08,6.24
				C213.612,724.858,213.977,728.336,212.16,728.602z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M324.24,699.321c3.261,0.14,5.816-0.424,7.92-1.44
				c1.746-1.773,3.999-3.041,5.28-5.279c0.47-3.211,0.916-6.444,1.92-9.12c0.315-1.205,0.99-2.05,1.44-3.12
				c-6.498,2.782-12.497,6.063-17.521,10.32c0.141,3.021-0.173,5.586-0.24,8.399C323.39,699.213,324.281,698.801,324.24,699.321z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M347.041,690.441c1.173-2.667,2.265-5.415,3.119-8.4
				c-2.138,0.475-4.768,0.182-6.479-0.479c-1.15,2.289-2.162,4.718-2.4,7.92c0.759-0.362,1.027-1.214,2.16-1.2
				c2.083,1.508-1.981,2.279-1.439,4.56c1.184-0.336,2.655-0.384,3.84-0.72c0.044-0.355,0.58-0.22,0.96-0.24
				C346.938,691.459,346.511,690.471,347.041,690.441z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M204,742.041c-7.383-4.137-15.823-7.216-27.12-7.439
				c-1.603-0.158-3.837,0.316-5.04-0.24c-12.732,0.766-17.991-8.87-30-6.24c-0.085,6.938,3.106,12.981,2.4,18.96
				c5.52,0,11.04,0,16.56,0c6.56,0.16,13.76-0.319,19.92,0.24c1.44,0,2.88,0,4.32,0c3.041,0.159,6.719-0.319,9.36,0.24
				c1.68,0,3.36,0,5.04,0c5.28-0.16,11.2,0.319,16.08-0.24c-2.589-1.091-5.077-2.283-7.68-3.36
				C206.426,743.455,205.146,742.815,204,742.041z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M132.72,601.641c4.694,10.311,9.779,20.228,15.12,29.041
				c7.694,12.697,15.184,24.945,22.8,36.239c3.769,5.59,8.43,11.203,12.96,16.32c7.07,7.986,14.39,15.689,24,20.16
				c10.163,4.729,18.901,10.458,28.08,16.32c5.259,1.332,10.286,0.206,15.6-1.2c9.917-2.624,19.61-6.338,28.32-9.84
				c9.336-3.754,16.938-8.394,24.24-13.92c2.467-1.868,5.007-4.232,7.439-5.28c2.565-1.105,5.34-0.84,7.44-1.681
				c1.769-0.707,3.27-2.583,5.04-3.84c1.767-1.254,3.793-2.354,5.52-3.359c11.758-6.846,24.412-10.9,35.761-17.761
				c0.553-13.235-0.989-26.339-3.36-37.439c-0.759-0.602-1.08-1.641-1.44-2.641c-0.638-2.161-1.785-3.813-2.88-5.52
				c-0.839-0.682-1.243-1.797-1.92-2.64c-1.158-2.362-2.995-4.046-4.56-6c-2.101-1.9-4.101-3.9-6-6
				c-2.927-2.432-8.799-5.591-12.48-2.4c0,0.16,0,0.32,0,0.479c6.208,6.587,9.142,15.727,12.48,24.721
				c3.336,8.987,7.113,18.573,8.16,29.28c0.24,0.965,0.613,3.058,0.239,3.84c-0.293,3.067-3.714,1.766-3.359-0.96
				c-0.556-0.965-0.084-2.957-0.24-4.32c-1.653-16.187-7.691-27.988-12.72-40.8c-0.725-0.877-1.091-2.109-1.681-3.12
				c-1.059-2.701-2.955-4.565-4.319-6.96c-0.563-0.062-0.139-1.055,0-1.2c-0.454-0.054-0.53,0.271-0.961,0.24
				c-1.93-0.171-0.593,2.926-2.159,3.12c-1.616,0.495-1.104-1.138-2.4-0.96c-1.402,0.677-2.919,1.241-3.6,2.64
				c-0.874-0.074-1.563,0.038-2.16,0.24c-8.462,5.139-16.155,11.044-28.08,12.72c-0.333,0.547-1.671,0.09-2.4,0.24
				c-0.56,0-1.12,0-1.68,0c-0.647,0.553-2.315,0.085-3.36,0.239c-9.26,0.955-13.813,1.784-19.68,5.521
				c-8.042,5.121-12.687,13.958-16.32,24c-0.756,2.09-2.464,9.84-4.56,4.8c0.159-2.721-0.318-6.078,0.24-8.399
				c1.708-1.181,1.872-3.521,2.64-6c0.942-3.04,1.984-6.297,3.36-8.4c0.687-1.051,2.311-1.739,1.92-3.12
				c-2.126,0.354-3.723,1.237-6,1.44c-0.595-0.286-0.89-0.87-0.72-1.92c-1.474-0.927-1.906-2.895-3.36-3.841
				c-2.491-1.429-5.091-2.748-7.44-4.319c-1.06-0.86-2.021-1.82-2.88-2.88c-0.783-1.537-1.846-2.794-2.64-4.32
				c-1.007-1.073-1.552-2.607-2.16-4.08c-0.574-2.146-1.435-4.006-1.92-6.24c-0.639-0.4,0.133-2.213-0.48-2.64
				c-0.08,0-0.16,0-0.24,0c-1.107-0.893-2.132-1.868-2.88-3.12c-0.597-1.403-1.428-2.571-1.92-4.08
				c-0.568-0.632-0.79-1.61-0.96-2.64c-0.426-1.895-0.748-3.893-1.2-5.761c-0.397-0.259-0.397-2.141,0-2.399
				c0.58-2.238,2.499-4.085,5.28-3.36c0.325,0.076,0.458,0.342,0.96,0.24c1.29,0.55,2.444,1.236,3.6,1.92
				c0.134,0.346,0.375,0.586,0.72,0.72c0-0.16,0-0.319,0-0.479c0.783-1.113,0.157-2.871-1.68-2.16
				c-2.265-5.976-4.063-12.417-8.16-16.561c0.134,0.596-0.105,0.843-0.46,0.924c0.002,0,0.003,0.001,0.005,0.002
				c-0.002-0.001-0.004-0.001-0.006-0.002c0,0,0.001,0,0.001,0c-0.002,0-0.003,0-0.005-0.001c-0.151-0.037-0.306-0.062-0.463-0.071
				c-0.012-0.001-0.025,0-0.038-0.001c-0.155-0.008-0.312-0.005-0.472,0.016h0c-0.158,0.021-0.317,0.044-0.48,0.095
				c0.164-0.05,0.322-0.074,0.48-0.095c-0.181-0.045-0.351-0.097-0.48-0.146c-3.351-1.609-6.279-3.642-9.6-5.28
				c-1.606-0.794-3.179-1.621-4.8-2.4c-0.204-0.355-0.604-0.516-1.2-0.479c-0.036-0.444-0.813-0.147-0.96-0.48
				c-4.411-2.148-9.232-3.888-13.68-6c-1.346,0.096-1.616-1.884,0-1.68c0,0.08,0,0.16,0,0.24c7.118,1.922,13.663,4.417,19.68,7.439
				c0.053,0.188,0.201,0.279,0.48,0.24c2.583,1.258,4.846,2.834,7.44,4.08c0.158,0.322,0.513,0.447,0.96,0.48
				c-4.137-7.579-8.149-19.873-11.28-30.24c-1.167-3.863-1.628-7.947-2.88-11.28c-0.741-1.972-2.379-3.629-2.88-5.521
				c-0.817-3.087,0.472-4.765,1.435-6.687c-0.212,0.174-0.409,0.37-0.59,0.586c0.181-0.216,0.378-0.413,0.591-0.587
				c0,0.001,0,0.001,0,0.001c0.25-0.205,0.521-0.376,0.811-0.513c0.291,0,0.583,0,0.874,0c0.585-4.146-0.042-7.012,1.2-9.84
				c0.784-1.787,3.194-3.285,2.4-6.48c-0.684-0.356-1.799-0.28-2.16-0.96c-2.82-10.78-8.004-20.476-10.56-30.72
				c0.08,0,0.16,0,0.24,0c2.344,1.976,3.743,4.896,5.28,7.68c2.486,5.674,4.597,11.724,6.96,17.52
				c1.002-11.871,4.215-23.555,1.92-36c-3.796-1.724-7.802-3.237-11.76-4.8c-0.053-0.187-0.201-0.279-0.48-0.24
				c-5.809-0.3-11.076-1.609-16.32-3.12c-5.275-1.519-11.021-1.996-17.04-2.399c-27.343-1.833-47.38,9.222-58.8,24
				c-0.523,0.438,0.043,1.963-0.48,2.4c-4.299,3.621-6.392,9.447-9.36,14.399c2.144,6.116,2.252,11.923,3.84,17.521
				c0.802,2.829,1.825,5.375,2.88,7.92c2.122,5.116,5.792,9.572,5.52,16.8c6.505,3.575,20.939-0.779,21.84,8.4
				c-2.647-0.406-4.297-3.303-7.44-3.84c-5.32-0.911-11.095,0.4-14.88-2.4c0.118,4.486,2.613,7.7,4.56,11.04
				c2.254,3.865,3.686,8.756,5.52,13.2c3.824,9.267,6.844,18.326,10.08,27.6c2.024,5.8,3.075,12.875,5.52,17.28
				C129.228,593.98,130.808,597.441,132.72,601.641z M232.561,634.041c3.488-1.632,7.035-3.205,11.76-3.6
				c0.652-0.48,2.752-0.362,3.12,0.24c-0.106,0.693-0.506,1.094-1.2,1.199c-4.29,1.711-8.925,3.075-12.96,5.04
				C231.6,637.929,230.448,634.285,232.561,634.041z M188.839,520.297c1.703,3.718,4.146,7.364,6.125,10.642
				C192.982,527.661,190.542,524.015,188.839,520.297z M196.723,507.643c0.147-0.574,0.362-1.11,0.639-1.591
				C197.085,506.531,196.871,507.069,196.723,507.643z M181.68,465.561c0.08,0,0.16,0,0.24,0c0.98,0.78,1.86,1.66,2.64,2.641
				c2.286,3.554,4.613,7.066,7.44,10.08c0.671,0.688,1.065,1.654,1.92,2.159c0.051,0.27,0.266,0.374,0.48,0.48
				c1.073,1.087,1.879,2.44,3.12,3.36c0.359,0.36,0.43,1.01,0.96,1.199c0.558,1.122,1.351,2.01,1.92,3.12
				c0.283,1.477,1.485,2.034,1.2,4.08c-0.093,0.656-1.015,1.117-1.44,0.72c-1.035-0.085-0.095-2.145-0.96-2.399
				c-0.998-2.522-2.365-4.675-4.08-6.48c-0.248-0.632-0.952-0.808-1.2-1.439c-0.7-0.9-1.5-1.701-2.4-2.4c0-0.08,0-0.16,0-0.24
				c-1.24-1.399-2.522-2.757-3.6-4.319c-0.854-0.587-1.296-1.584-1.92-2.4c-1.402-2.598-3.238-4.762-4.56-7.439
				c-0.741-0.779-1.471-1.57-1.92-2.641C180.62,463.901,181.05,464.831,181.68,465.561z M172.56,467.001
				c1.256,0.504,1.894,1.626,3.12,2.16c1.22,1.02,2.34,2.14,3.36,3.359c0.315,0.085,0.377,0.423,0.72,0.48c1.3,1.1,2.5,2.3,3.6,3.6
				c1.211,1.108,2.417,2.224,2.88,4.08c-0.333,0.388-0.68,0.76-0.96,1.2c-2.118-0.248-4.302-1.158-6.24-1.92
				c-1.689-0.664-4.348-1.098-4.8-3.12c2.829-0.725,4.874,1.348,7.2,1.92c0.507-0.074-0.252-0.833-0.72-0.72
				c-1.94-1.74-3.78-3.58-5.52-5.521c-0.949-1.69-2.42-2.859-3.12-4.8c-0.604-0.436-1.146-0.934-1.2-1.92
				C171.906,465.734,172.104,466.497,172.56,467.001z M120.72,535.641c-2.832-1.088-5.127-2.713-7.68-4.08
				c-1.213-0.146-2.453-0.267-2.64-1.439c2.205-4.046,9.515,2.415,10.8,5.04c0.351,0.208,0.761,0.359,0.72,0.96
				C121.56,536.504,120.91,536.114,120.72,535.641z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M350.641,470.36c2.119,1.857,6.899,0.274,9.84,0
				c0.179-0.541,1.347-0.094,1.92-0.239c0.16,0,0.32,0,0.48,0c0.239,0,0.479,0,0.72,0c0-0.08,0-0.16,0-0.24
				c1.603,0.157,3.837-0.316,5.04,0.24c5.547-0.587,5.002,4.918,6.72,8.16c0.444,1.636,0.74,3.42,1.2,5.04
				c0.449,3.71,1.209,7.11,1.68,10.8c0.029,0.691-0.131,1.571,0.24,1.92c0.682,1.566-0.055,3.781,0,5.52
				c2.132-0.964,2.78-4.678,2.16-6.96c0.961-19.842-6.803-30.957-12.48-44.16c-3.753,1.018-9.104-1.023-12,0.721
				c-1.991,0.168-3.666,0.653-5.76,0.72c0,0.08,0,0.16,0,0.24c-0.104,2.456-0.118,5.398-0.24,8.16
				C349.973,464.554,348.796,469.91,350.641,470.36z M357.057,454.762c0.001,0.002,0.001,0.004,0.001,0.006
				C357.058,454.766,357.058,454.764,357.057,454.762C357.057,454.762,357.057,454.762,357.057,454.762z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M341.521,485.721c0-0.479,0-0.96,0-1.439
				c0.154-0.967-0.312-2.553,0.24-3.12c0-0.24,0-0.48,0-0.721c0.156-1.283-0.315-3.194,0.24-4.08
				c-0.14-0.778,0.163-1.116,0.239-1.68c-0.832,0.192-0.922-0.357-1.68-0.24c-0.151,2.463-0.905,10.12,0.48,11.04
				C341.281,485.48,341.326,485.675,341.521,485.721z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M332.641,488.361c-0.08,0-0.16,0-0.24,0
				c-0.399,0-0.8-0.001-1.2,0c-0.805,0.554-2.636,0.084-3.84,0.239c-0.32,0-0.64,0-0.96,0c-0.411,0.55-1.832,0.088-2.64,0.24
				c-1.45-0.09-2.652,0.067-3.84,0.24c-0.045,0.355-0.58,0.22-0.96,0.24c-0.925,2.915,0.056,7.736-1.2,10.319
				c-0.26,0.701-0.537,1.383-0.48,2.4c-0.099,1.421-0.106,2.933-0.479,4.08c0,0.08,0,0.16,0,0.24
				c-0.152,0.808,0.309,2.229-0.24,2.64c-0.306,0.975-0.444,2.116-0.48,3.36c0.27,11.154,0.5,24.254,0.721,36.239
				c-0.115,0.755,0.342,0.938,0.479,1.44c2.631,3.129,6.014,5.507,8.641,8.64c0,0.08,0,0.16,0,0.24c1.859,1.66,3.62,3.42,5.279,5.28
				c0.08,0,0.16,0,0.24,0c0.98,0.779,1.86,1.659,2.64,2.64c0.08,0,0.16,0,0.24,0c0.9,0.699,1.701,1.5,2.4,2.4
				c0.24-0.001,0.285,0.194,0.479,0.24c2.181,1.979,4.261,4.06,6.24,6.239c0.77,1.551,2.261,2.38,3.12,3.84
				c2.064-1.387,4.307-4.645,6.72-6.479c0.529-0.431,1.27-0.651,1.44-1.44c1.305-1.334,2.414-2.865,3.84-4.08
				c1.025-1.374,2.313-2.487,3.12-4.08c1.33-1.469,2.152-3.447,3.6-4.8c2.668-2.219,2.774-8.015,4.561-11.279
				c0.658-1.204,2.91-1.583,1.68-3.36c-0.339-1.411-0.498-3.493-0.24-4.8c0-0.32,0-0.641,0-0.96c0-0.4,0-0.801,0-1.2
				c0.08,0,0.16,0,0.24,0c0-0.88,0-1.76,0-2.64c0-0.32,0-0.641,0-0.961c0.556-0.964,0.083-2.956,0.24-4.319c0-0.24,0-0.48,0-0.72
				c0.551-0.49,0.087-1.993,0.24-2.881c0.796-8.403,2.248-16.15,3.119-24.479c0.554-0.647,0.086-2.315,0.24-3.36
				c-0.22-4.9-1.063-9.176-1.92-13.439c-0.085-1.115-0.308-2.093-0.72-2.881c-0.57-3.029-1.601-5.6-2.641-8.159
				c-0.64,0-1.279,0-1.92,0c-0.805,0.554-2.636,0.084-3.84,0.239c-0.24,0-0.479,0-0.72,0c-0.887,0.153-2.391-0.311-2.88,0.24
				c-0.874-0.074-1.563,0.038-2.16,0.24c-0.779-0.14-1.116,0.163-1.68,0.24c-1.489,0.031-2.737,0.303-4.08,0.479
				c-0.492,0.398-2.628,0.398-3.12,0c-0.307-0.013-0.332-0.308-0.72-0.24c-0.343-0.057-0.405-0.395-0.721-0.479
				c0.183,4.103-0.373,7.466-0.239,11.52c0.376,0.516,1.021-0.723,1.92-0.479c2.813-0.548,5.947-0.772,9.12-0.96
				c0.08,0,0.159,0,0.239,0c0.88,0,1.761,0,2.641,0c0.729,0.15,2.067-0.308,2.399,0.24c3.248,0.127,4.639-1.603,7.681-1.681
				c1.278,0.365,0.526,2.256-0.48,2.4c-1.95,0.529-3.345,1.615-5.76,1.68c-1.261-0.14-1.817,0.423-3.12,0.24
				c-4.559,0.001-9.321-0.201-12.72,0.96c-0.511,0.648-1.768,0.022-2.16-0.24c0,0.08,0,0.16,0,0.24
				c-0.153,0.887,0.311,2.391-0.24,2.88c0.719,4.349-4.345,6.816-6.24,3.12c-0.558-1.603-0.081-4.238-0.239-6.24
				c-0.08,0-0.16,0-0.24,0c-1.737,1.543,0.004,6.564-0.96,8.88c0,0.08,0,0.16,0,0.24c-0.152,0.808,0.309,2.229-0.24,2.64
				c-0.137,4.809-6.241,2.446-6.72-0.479c-0.4-1.843-0.4-5.357,0-7.2c0-0.32,0-0.64,0-0.96
				C332.73,490.031,333.188,488.693,332.641,488.361z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M444.001,590.121c-1.564-0.196-2.972-0.549-4.8-0.48
				c-0.809-0.151-2.229,0.31-2.641-0.239c-0.56,0-1.12,0-1.68,0c-0.333,0.547-1.671,0.089-2.4,0.239
				c-0.983-0.023-1.967-0.047-2.64,0.24c0,0.561,0,1.12,0,1.68c1.438,0.117,3.708-0.9,5.76-0.96
				C437.843,589.883,441.759,590.839,444.001,590.121z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M319.2,695.961c0-1.199,0-2.399,0-3.6
				c-1.984,0.696-4.261,0.47-6,1.2c-1.707,0.717-3.394,2.286-5.04,3.359c-5.331,3.478-9.992,6.812-15.359,10.08
				c2.655,0.225,4.819,0.94,7.92,0.721c1.682,0.157,3.997-0.317,5.279,0.239c3.257,0.174,6.783,1.122,10.08,0.48
				c1.102-1.699,1.94-3.66,2.16-6.24c-1.002-1.436-0.03-2.623,0.721-3.6C319.248,697.929,319.225,696.945,319.2,695.961z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#4F83B3" d="M388.32,655.401c1.21-2.471,2.49-4.871,3.36-7.681
				c1.87-4.189,2.199-11.136,1.92-17.279c-0.893-19.641-5.491-42.519-20.16-47.04c-1.835-0.566-4.982-0.772-6.72-0.721
				c-5.537-0.438-11.244,0.873-15.36,2.641c-1.625,3.334-5.652,4.268-8.88,6c16.18,0.486,20.221,15.084,22.8,29.28
				c1.917,10.551,3.84,22.776,3.84,35.279c0,4.072,0.455,8.328-0.72,12C375.093,663.773,379.682,657.562,388.32,655.401z"/>
				</g>
			</g>
			<g>
				<path fillRule="evenodd" clipRule="evenodd" d="M172.08,467.721c0.7,1.94,2.171,3.109,3.12,4.8c1.74,1.94,3.58,3.78,5.52,5.521
			c0.468-0.113,1.228,0.646,0.72,0.72c-2.326-0.572-4.371-2.645-7.2-1.92c0.452,2.022,3.11,2.456,4.8,3.12
			c1.938,0.762,4.123,1.672,6.24,1.92c0.28-0.44,0.627-0.813,0.96-1.2c-0.463-1.856-1.668-2.972-2.88-4.08c-1.1-1.3-2.3-2.5-3.6-3.6
			c-0.343-0.058-0.405-0.396-0.72-0.48c-1.02-1.22-2.14-2.34-3.36-3.359c-1.227-0.534-1.864-1.656-3.12-2.16
			c-0.457-0.504-0.654-1.267-1.68-1.2C170.934,466.787,171.476,467.285,172.08,467.721z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M121.2,535.161c-1.286-2.625-8.595-9.086-10.8-5.04
			c0.188,1.173,1.427,1.293,2.64,1.439c2.553,1.367,4.848,2.992,7.68,4.08c0.189,0.474,0.839,0.863,1.2,0.48
			C121.961,535.521,121.551,535.369,121.2,535.161z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M233.28,636.921c4.035-1.965,8.67-3.329,12.96-5.04
			c0.694-0.105,1.094-0.506,1.2-1.199c-0.368-0.603-2.468-0.721-3.12-0.24c-4.725,0.395-8.272,1.968-11.76,3.6
			C230.448,634.285,231.6,637.929,233.28,636.921z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M373.2,708.201c-2.674,0.206-5.224,0.536-6.479,2.16c0,0.24,0,0.48,0,0.72
			c-0.022,0.913,1.438,1.589,2.16,0.96c1.662-0.338,3.064-0.935,4.56-1.439C374.554,710.285,374.786,708.164,373.2,708.201z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M211.92,724.762c-6.165-3.276-16.683-2.197-22.08-6.24
			c-1.263-1.298-2.604-2.517-4.08-3.601c-0.734-0.146-1.25-0.509-1.92-0.72c-0.363-0.597-0.823-1.097-1.68-1.2
			c-0.058,0.343-0.395,0.405-0.48,0.721c0.455,0.425,0.745,1.015,1.2,1.439c0.075,0.324,0.341,0.459,0.24,0.96
			c0.979,2.781,3.222,4.298,5.28,6c0.08,0,0.16,0,0.24,0c0.711,0.969,1.857,1.503,3.12,1.92c1.438,0.563,3.297,0.703,4.8,1.2
			c-0.64,0-1.28,0-1.92,0c-1.045,0.155-2.713-0.313-3.36,0.24c-2.316,0.164-4.425,0.535-6.96,0.479c-2.48,0-4.96,0-7.44,0
			c-0.56,0-1.12,0-1.68,0c-0.492-0.397-2.628-0.397-3.12,0c-0.342,0.058-0.405,0.396-0.72,0.48c0.309,2.331,3.25,2.03,5.52,2.4
			c0.885,0.555,2.796,0.083,4.08,0.239c1.92,0,3.84,0,5.76,0c0.729-0.15,2.067,0.308,2.4-0.239c0.32,0,0.64,0,0.96,0
			c0.966-0.154,2.552,0.312,3.12-0.24c0.4,0,0.8,0,1.2,0c1.2,0,2.4,0,3.6,0c0.56,0,1.12,0,1.68,0c1.372,0.327,4.751,0.595,5.76-0.24
			c1.548-2.411,3.755,0.59,6.72,0.24C213.977,728.336,213.612,724.858,211.92,724.762z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M261.601,130.76c-6.666-0.775-10.493-4.388-13.2-9.12
			c-0.51-0.85-0.795-1.925-1.44-2.64c-0.425-0.775-0.72-1.68-0.96-2.64c-0.408-1.192-0.983-2.216-1.68-3.12c-0.08,0-0.16,0-0.24,0
			c-0.398,0.571-0.398,2.789,0,3.36c0.936,3.465,2.615,6.185,4.32,8.88c0.939,1.141,1.979,2.18,3.12,3.12
			c2.51,1.457,4.216,2.849,6.96,3.6c2.43,0.665,4.906,0.099,7.92,0.48c5.769,0.73,9.414,6.225,16.32,5.28c0.96,0,1.92,0,2.88,0
			c1.422-0.374,2.225,1.124,3.12,0.48c-1.454-1.426-3.701-2.059-6.48-2.16c-0.8,0-1.6,0-2.4,0c-6.153-0.807-9.094-4.827-15.6-5.28
			C263.83,130.451,262.408,130.912,261.601,130.76z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M247.2,229.64c0.766,0.206,0.742-0.377,1.2-0.48
			c0.597-1.487-1.777-1.249-1.2-1.92c2.828-0.532,6.858,0.139,7.68-2.4c-5.052,1.738-14.335,1.045-18.96-1.2
			c-1.261,4.359,4.649,5.548,8.88,6C245.059,230.038,246.941,230.038,247.2,229.64z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M241.44,85.4c6.722-0.638,13.083-1.637,19.68-2.4c1.52,0,3.04,0,4.56,0
			c3.575,0.345,6.042,1.798,8.16,3.6c0,0.08,0,0.16,0,0.24c0.343,0.058,0.405,0.396,0.72,0.48c0.602-1.693-1.278-2.844-2.4-3.6
			c-1.92-0.8-3.734-1.706-6-2.16c-1.443-0.399-4.556-0.399-6,0c-6.035,1.165-12.418,1.982-19.2,2.4
			c-0.729,0.15-2.067-0.307-2.4,0.24c-0.27,0.051-0.374,0.266-0.48,0.48C237.425,85.588,240.536,86.052,241.44,85.4z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M292.32,111.56c5.118,0.002,7.496,2.744,10.32,5.04c1.5,1.7,3.1,3.3,4.8,4.8
			c0.309,0.412,0.496,0.944,1.2,0.96c-1.725-6.199-6.54-11.378-13.2-13.44c-0.414-0.398-2.466-0.398-2.88,0c-0.08,0-0.16,0-0.24,0
			C291.207,109.069,290.905,111.477,292.32,111.56z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M257.76,233.48c-0.498-2.578,0.685-3.476,0.96-5.28
			c-1.515-0.65-3.402,0.23-4.56,0.72c-0.859,1.06-1.819,2.02-2.88,2.88c-1.362,1.118-3.104,1.857-5.04,2.4
			c-1.061-0.299-1.309-1.411-2.88-1.2c-0.795,0.792-1.489,0.62-1.68,1.68C240.618,240.562,257.833,236.267,257.76,233.48z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M186,473.721c0.624,0.816,1.066,1.813,1.92,2.4c1.077,1.563,2.36,2.92,3.6,4.319
			c0,0.08,0,0.16,0,0.24c0.901,0.699,1.701,1.5,2.4,2.4c0.248,0.632,0.952,0.808,1.2,1.439c1.715,1.806,3.082,3.958,4.08,6.48
			c0.865,0.255-0.075,2.314,0.96,2.399c0.425,0.397,1.347-0.063,1.44-0.72c0.286-2.046-0.917-2.604-1.2-4.08
			c-0.569-1.11-1.362-1.998-1.92-3.12c-0.531-0.189-0.601-0.839-0.96-1.199c-1.24-0.92-2.046-2.273-3.12-3.36
			c-0.214-0.106-0.429-0.211-0.48-0.48c-0.855-0.505-1.249-1.471-1.92-2.159c-2.827-3.014-5.154-6.526-7.44-10.08
			c-0.779-0.98-1.66-1.86-2.64-2.641c-0.08,0-0.16,0-0.24,0c-0.63-0.729-1.061-1.659-2.16-1.92c0.45,1.07,1.18,1.861,1.92,2.641
			C182.762,468.959,184.598,471.123,186,473.721z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M437.28,508.761c-0.229-1.131-0.899-1.82-1.439-2.64
			c-0.046-0.194-0.241-0.239-0.24-0.48c-0.863,0.017-0.759,1.002-0.72,1.92c-0.131,2.051,0.565,3.274,0.479,5.28
			c0,0.8,0,1.601,0,2.4c-0.233,4.403-2.672,8.6-4.56,12.96c-1.861,4.3-2.998,8.653-4.32,11.76c-0.44,1.035-2.251,2.347-0.72,3.6
			c2.473-0.443,2.921-3.409,3.6-5.279c2.301-6.336,5.262-12.826,7.681-18.96c0.194-0.046,0.239-0.241,0.479-0.24
			c-0.039-0.279,0.053-0.427,0.24-0.48c0-0.64,0-1.279,0-1.92c0-0.16,0-0.319,0-0.479c0.398-0.808,0.398-3.272,0-4.08
			C437.531,511.07,437.344,509.978,437.28,508.761z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M232.561,187.88c0.162,2.547-2.154,5.581,0,7.2
			c2.757-0.529,2.688-4.542,2.64-6.96c-0.088-4.41-1.95-9.663-3.84-11.76c-0.215,3.174,1.276,4.644,1.2,7.68
			C232.959,184.769,232.959,187.152,232.561,187.88z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M303.12,292.28c-5.911-1.152-12.845,2.882-17.28,2.88
			c0.528-0.891,0.202-2.672-0.24-3.36c-3.147-0.187-2.955,2.966-4.32,4.56c0.005-1.125,0.21-2.45-0.96-2.4
			c-2.162,0.64-2.951,3.532-3.36,5.52c-1.067,5.195-2.969,10.452-5.04,14.64c0.029,0.691-0.131,1.571,0.24,1.92
			c-0.82,0.459-0.822,1.738-1.68,2.16c-1.262-2.545-2.889-5.193-4.08-8.16c-1.131-2.818-1.538-6.481-4.32-7.92
			c-1.085,0.63,0.203,2.047-0.24,2.64c-2.599-3.401-5.168-6.831-6.96-11.04c-2.061,0.788-1.059-1.271-3.12-0.96
			c-1.116,1.125-1.329,3.151-2.4,4.32c-0.235,0.965-1.57,1.839-0.72,3.12c3.198-0.322,2.66-4.38,5.76-4.8
			c0.685,1.715,1.935,2.865,2.64,4.56c0.662,1.098,1.256,2.264,2.16,3.12c1.019,0.834,1.668,3.727,3.36,2.4
			c0.553-0.477,0.267,0.06,0.72,0.24c0.278,0.842,0.944,1.295,0.96,2.4c0.266,0.934,0.664,1.736,1.2,2.4
			c1.178,2.502,2.622,4.738,3.84,7.2c0.197,1.844,3.163,1.844,3.36,0c-0.061-0.861,0.721-0.879,0.72-1.68
			c4.468-3.132,7.107-8.093,9.36-13.44c3.607-0.553,8.235-0.085,12.24-0.24c1.309-0.108,1.973,0.428,3.36,0.24
			c2.303,1.507,1.966,8.092,5.04,8.64c0.077,0.014,0.162-0.011,0.24,0c0.167-1.366-0.407-1.993-0.24-3.36
			c-0.285-2.034-0.625-4.015-0.479-6.48C306.045,299.623,308.49,293.327,303.12,292.28z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M445.681,522.681c-1.107,0.093-1.48,0.92-1.92,1.681
			c-0.638,1.922-2.623,2.496-2.88,4.8c1.686,0.896,2.829-0.64,3.6-1.44c0.555-0.805,1.134-1.586,1.44-2.64
			C445.855,524.266,446.106,523.135,445.681,522.681z"/>
				<g>
					<path fill="none" d="M246.48,596.121c0.735,0.785,1.828,1.212,2.88,1.68c0.024,0.616,0.895,0.386,1.2,0.721
				c-1.062-1.98-4.1-4.061-5.76-6.24c-0.217-0.424-0.537-0.743-0.96-0.96c-0.913-1.007-1.672-2.168-2.64-3.12
				c-0.562-1.038-1.198-2.002-1.92-2.88c-1.52-3.12-2.8-6.479-4.32-9.601c-0.319-1.121-0.789-2.091-1.92-2.399
				c-1.523,2.557-2.37,5.79-2.16,10.08c3.936,3.904,8.17,7.51,12.72,10.8C244.481,594.92,245.339,595.662,246.48,596.121z"/>
					<path fill="none" d="M262.561,622.281c3.453-1.068,6.135-4.431,10.08-5.04c3.152-0.487,6.431-0.023,10.08-0.96
				c0.411-0.55,1.832-0.088,2.64-0.24c0.4,0,0.8,0,1.2,0c0.489-0.551,1.993-0.087,2.88-0.24c6.559-0.241,11.06-2.54,15.6-4.8
				c0.493-0.787,1.644-0.916,2.4-1.439c1.217-0.464,2.475-0.886,3.12-1.921c1.552-9.361-0.845-17.938-4.561-23.52
				c-8.199,2.016-18.538,5.61-27.359,4.56c0.477-1.698,2.491-0.925,3.6-1.199c1.779-0.442,3.339-1.286,5.28-1.681
				c6.018-1.222,12.309-2.291,17.28-4.319c-2.006-4.314-4.445-8.195-6.96-12c-1.859,3.731-6.502,4.412-10.8,5.76
				c-2.279,0.714-4.696,2.562-6.72,1.439c1.828-2.289,5.089-2.78,7.44-4.319c2.883-1.889,5.264-4.286,7.2-6.96
				c-1.033-1.898-2.467-3.362-2.159-5.521c0.301-2.114,3.21-3.629,4.08-5.04c2.234-3.626,1.149-8.57,0.479-13.439
				c-1.23-8.94-2.728-16.884-3.36-26.16c-0.764-11.204-0.586-26.354-4.8-34.561c-0.815-1.588-2.23-2.716-2.4-4.32
				c-0.331-3.125,2.231-7.054,0.48-9.84c-5.765,0.165-3.358-7.842-3.84-12.96c-6.359-0.267-11.961,3.179-18.24,3.601
				c-3.913,0.262-7.841-0.179-11.76,0c-12.956,0.588-26.051,2.007-39.6,1.439c-1.312,19.089,0.298,41.099-11.28,49.92
				c-0.052,1.229-0.626,1.935-1.2,2.641c5.671,9.625,7.98,23.361,12,34.319c1.23,3.354,2.236,7.033,3.84,9.841
				c1.751,3.064,5.041,5.316,6.96,8.399c2.214,3.556,3.015,6.957,4.8,10.801c0.545-2.695,2.405-5.221,6-4.08
				c1.077,0.522,1.877,1.322,2.4,2.399c2.45,6.11,4.85,12.27,9.36,16.32c1.582,1.538,3.427,2.813,4.8,4.56c0.08,0,0.16,0,0.24,0
				c0.98,0.78,1.86,1.66,2.64,2.641c1.246,0.914,2.545,1.775,3.12,3.359c1.001,1.022-0.71,2.358-0.24,2.881c0.08,0,0.16,0,0.24,0
				c0.98,0.779,1.86,1.659,2.64,2.64c0.063,0.737,0.521,1.079,0.72,1.68c0.397,0.336,0.397,2.304,0,2.641c0,0.079,0,0.159,0,0.239
				c-0.157,1.443,0.316,3.517-0.24,4.561C258.753,618.138,261.806,618.835,262.561,622.281z"/>
					<path fill="none" d="M228,585.081c-0.49-0.07-0.471-0.649-0.96-0.72c-1.737-1.623-3.309-3.411-6.48-3.601
				c-1.259,2.679,0.023,6.349,0.96,9.12c0.975,2.884,2.565,5.824,4.8,6.96c4.61-1.815,7.027,1.878,9.84,4.08
				c6.554,5.133,14.24,8.828,20.4,13.2c0.198-2.729,0.5-5.604-0.96-7.2c-1.476-1.324-2.354-3.245-4.32-4.08
				c-1.894-1.065-3.721-2.198-5.76-3.12c-0.502-0.697-1.447-0.952-2.16-1.439c-5.229-3.651-9.781-7.979-14.4-12.24
				C228.744,585.618,228.423,585.298,228,585.081z"/>
					<path fill="none" d="M319.921,602.602c0.234-6.006-1.163-11.472-3.12-16.561c-2.139-5.561-4.953-11.342-8.4-14.16
				c0.282-3.149-3.142-5.055-3.6-8.16c-0.561-3.792,1.291-8.437,1.92-12.72c0.622-4.237,1.619-8.978,0.96-13.44
				c-0.361-2.444-2.119-5.115-3.12-8.399c-2.833-9.294-0.59-19.542-1.2-28.8c-0.581-8.82-2.047-17.321-6.24-24.48
				c-1.536-2.624-4.2-4.755-3.6-9.36c-1.12,0-2.24,0-3.36,0c0.422,3.053,0.899,7.061-0.479,9.36
				c6.188,7.836,6.046,22.529,6.96,36.72c0.657,10.218,2.166,19.474,3.6,29.04c0.525,3.506,1.307,6.982,0.96,10.08
				c-0.499,4.463-3.063,6.462-5.04,9.12c1.795,3.354,4.194,6.204,6.24,9.36c2.172,3.35,3.524,6.877,5.28,10.56
				c1.739,3.649,4.213,6.883,5.28,11.28c1.009,4.162,0.785,8.465,0.72,13.92C315.88,604.96,317.879,603.76,319.921,602.602z"/>
					<path fill="none" d="M341.761,597.081c3.537,1.743,6.338,4.223,8.88,6.96c1.642,1.318,2.994,2.926,4.32,4.561
				c1.528,2.312,3.253,4.427,4.8,6.72c-2.296-10.024-5.219-19.421-16.561-20.4C343.43,596.351,342.407,596.527,341.761,597.081z"/>
					<path fill="none" d="M282,427.641c-1.083-3.984-0.802-9.378,0-13.44c-0.614-0.346-1.693-0.227-2.16-0.72
				c-0.21,5.23-0.924,11.235-0.96,15.84C279.097,427.937,281.175,428.416,282,427.641z"/>
					<path fill="none" d="M230.4,400.041c0.779,0.98,1.66,1.86,2.64,2.64c10.457,8.583,20.604,17.477,33.36,23.76
				c0.602-1.639,1.307-3.173,1.92-4.8c7.114-17.846,12.422-37.498,18-56.88c0.229-1.051,0.251-2.31,0.48-3.36
				c-20.08-6.32-37.105-15.695-52.32-26.88c-0.78-0.98-1.66-1.86-2.64-2.64c-1.061-1.819-2.581-3.179-3.84-4.8
				c-0.057-0.743-0.668-0.932-0.96-1.44c0.107,0.773,0.46,1.3,0.48,2.16c1.85,5.209,2.965,10.49,5.28,14.88
				c0.815,1.546,2.399,2.808,2.16,4.8c-3.098,1.347-4.072-2.388-6.24-3.36c-2.012-0.902-4.469-0.813-6.96-0.96
				c4.287,6.513,9.187,12.414,14.4,18c0.7,0.9,1.5,1.701,2.4,2.4c0.396,0.644,1.017,1.063,1.44,1.68
				c-0.025,0.745,0.755,0.685,0.48,1.68c-1.336-0.104-2.187-0.693-3.12-1.2c-1.02-1.22-2.14-2.34-3.36-3.36
				c-3.387-4.374-7.676-7.844-12-11.28c-0.522-1.138-1.438-2.626-2.4-3.84c-0.745-0.94-2.492-2.979-2.88-2.64
				c-0.57,0.15,0.037,1.477-0.48,1.68c0,0.88,0,1.76,0,2.64c-0.146,0.014-0.284,0.036-0.24,0.24c0,1.44,0,2.88,0,4.32
				c0.48,1.04,0.164,2.876,0.72,3.84c0,1.04,0,2.08,0,3.12c0.014,0.146,0.036,0.284,0.24,0.24c0,3.6,0,7.2,0,10.8
				c0.013,0.146,0.036,0.284,0.24,0.24c0,2.4,0,4.8,0,7.2c0.313,1.004,0.448,2.792,0.24,3.6
				C221.792,388.33,224.85,395.432,230.4,400.041z"/>
					<path fill="none" d="M388.32,655.401c1.21-2.471,2.49-4.871,3.36-7.681c1.87-4.189,2.199-11.136,1.92-17.279
				c-0.893-19.641-5.491-42.519-20.16-47.04c-1.835-0.566-4.982-0.772-6.72-0.721c-5.537-0.438-11.244,0.873-15.36,2.641
				c-1.625,3.334-5.652,4.268-8.88,6c16.18,0.486,20.221,15.084,22.8,29.28c1.917,10.551,3.84,22.776,3.84,35.279
				c0,4.072,0.455,8.328-0.72,12C375.093,663.773,379.682,657.562,388.32,655.401z"/>
					<path fill="none" d="M286.32,462.681c1.222,0.778,3.136,0.864,5.04,0.96c1.372,0.173,2.144-0.256,3.36-0.24
				c0.141-1.341-0.55-1.85-0.48-3.119c-0.64,0-1.28,0-1.92,0c-1.706-0.614-4.818,0.178-6-0.961
				C286.32,460.44,286.32,461.561,286.32,462.681z"/>
					<path fill="none" d="M282.24,410.361c0-3.6,0-7.2,0-10.8c0.146-0.014,0.284-0.036,0.24-0.24c0-2.96,0-5.92,0-8.88
				c-1.283,0.394-2.541,5.959-3.36,8.4c-1.182,3.519-2.232,6.358-2.88,8.88C279.347,407.336,280.435,410.245,282.24,410.361z"/>
					<path fill="none" d="M290.4,450.201c-0.32,0-0.64,0-0.96,0c-0.16,0-0.32,0-0.48,0c-0.611,2.293,0.78,4.658,0.48,7.439
				c0.988-0.188,1.231,0.368,2.16,0.24c-0.096-1.345-0.704-2.176-0.72-3.6C290.814,452.826,291.139,450.982,290.4,450.201z"/>
					<path fill="none" d="M316.561,319.161c-0.063,0.737-0.521,1.079-0.72,1.68c-1.044,2.375-2.408,5.116-3.841,8.16
				c-1.482,3.154-2.732,7.899-5.52,8.64c-2.447,0.599-1.746,2.983-2.88,4.56c-2.155,2.996-7.143,5.812-10.8,7.92
				c-4.123,2.376-8.925,3.801-12.72,6c3.078,1.642,7.776,1.663,9.839,4.32c-1.008,3.073-1.813,7.625-2.399,10.32
				c2.005-3.254,3.005-6.791,5.52-9.36c2.88-2.941,7.428-4.245,9.601-7.92c1.891-1.828,3.498-4.498,5.28-6.96
				c1.644-2.272,4.168-4.381,5.279-6.72c2.331-4.904,1.696-12.009,3.36-18.96C316.361,320.214,317.286,319.524,316.561,319.161z"/>
					<path fill="none" d="M346.32,445.4c-0.436-6.527-5.617-1.842-6.24,1.44c1.142,5.71,0.614,14.01,0.721,20.64
				c0.02,1.211-0.677,3.804,1.439,3.601c-0.615-3.192,1.169-5.554,0-7.2c0.547-1.992,3.726-1.244,4.32-1.44
				c0.16-1.76,0.336-3.504,0.24-5.52c0-0.24,0-0.48,0-0.72c0-1.681,0-3.36,0-5.04c0-0.32,0-0.641,0-0.96
				c-0.152-0.809,0.309-2.229-0.24-2.641c0-0.08,0-0.16,0-0.24C346.532,446.629,346.691,445.75,346.32,445.4z"/>
					<path fill="none" d="M330.721,471.081c0.578-0.099,0.521,0.438,1.2,0.239c0.436-0.283,0.188-1.251,0.479-1.68
				c0-2.88,0-5.76,0-8.64c-0.362-1.76,1.73-3.559,2.4-1.44c0.559,2.881,0.08,6.8,0.239,10.08c1.022-0.142,1.588,0.173,2.4,0.24
				c0-0.24,0-0.48,0-0.72c0.158-1.763-0.317-4.157,0.24-5.521c0-2.08,0-4.16,0-6.24c0-0.159,0-0.319,0-0.479
				c-0.157-1.443,0.315-3.517-0.24-4.561c-0.013-3.268-0.439-6.12-1.439-8.399c-0.288-0.992-0.921-1.641-1.681-2.16
				c-2.566-0.565-2.896,3.182-2.88,6c-0.15,0.729,0.308,2.067-0.24,2.4c0,0.239,0,0.479,0,0.72c-0.157,1.522,0.316,3.677-0.239,4.8
				c0,0.08,0,0.16,0,0.24c-0.154,0.966,0.312,2.552-0.24,3.12c0,0.239,0,0.479,0,0.72c-0.157,1.363,0.315,3.355-0.24,4.32
				c0,0.16,0,0.319,0,0.479c-0.154,1.046,0.313,2.713-0.24,3.36c0,0.16,0,0.32,0,0.479
				C330.325,469.396,329.941,470.82,330.721,471.081z"/>
					<path fill="none" d="M375.12,501.081c0.554-0.647,0.086-2.315,0.24-3.36c-0.22-4.9-1.063-9.176-1.92-13.439
				c-0.085-1.115-0.308-2.093-0.72-2.881c-0.57-3.029-1.601-5.6-2.641-8.159c-0.64,0-1.279,0-1.92,0
				c-0.805,0.554-2.636,0.084-3.84,0.239c-0.24,0-0.479,0-0.72,0c-0.887,0.153-2.391-0.311-2.88,0.24
				c-0.874-0.074-1.563,0.038-2.16,0.24c-0.779-0.14-1.116,0.163-1.68,0.24c-1.489,0.031-2.737,0.303-4.08,0.479
				c-0.492,0.398-2.628,0.398-3.12,0c-0.307-0.013-0.332-0.308-0.72-0.24c-0.343-0.057-0.405-0.395-0.721-0.479
				c0.183,4.103-0.373,7.466-0.239,11.52c0.376,0.516,1.021-0.723,1.92-0.479c2.813-0.548,5.947-0.772,9.12-0.96
				c0.08,0,0.159,0,0.239,0c0.88,0,1.761,0,2.641,0c0.729,0.15,2.067-0.308,2.399,0.24c3.248,0.127,4.639-1.603,7.681-1.681
				c1.278,0.365,0.526,2.256-0.48,2.4c-1.95,0.529-3.345,1.615-5.76,1.68c-1.261-0.14-1.817,0.423-3.12,0.24
				c-4.559,0.001-9.321-0.201-12.72,0.96c-0.511,0.648-1.768,0.022-2.16-0.24c0,0.08,0,0.16,0,0.24
				c-0.153,0.887,0.311,2.391-0.24,2.88c0.719,4.349-4.345,6.816-6.24,3.12c-0.558-1.603-0.081-4.238-0.239-6.24
				c-0.08,0-0.16,0-0.24,0c-1.737,1.543,0.004,6.564-0.96,8.88c0,0.08,0,0.16,0,0.24c-0.152,0.808,0.309,2.229-0.24,2.64
				c-0.137,4.809-6.241,2.446-6.72-0.479c-0.4-1.843-0.4-5.357,0-7.2c0-0.32,0-0.64,0-0.96c-0.15-0.729,0.307-2.067-0.24-2.399
				c-0.08,0-0.16,0-0.24,0c-0.399,0-0.8-0.001-1.2,0c-0.805,0.554-2.636,0.084-3.84,0.239c-0.32,0-0.64,0-0.96,0
				c-0.411,0.55-1.832,0.088-2.64,0.24c-1.45-0.09-2.652,0.067-3.84,0.24c-0.045,0.355-0.58,0.22-0.96,0.24
				c-0.925,2.915,0.056,7.736-1.2,10.319c-0.26,0.701-0.537,1.383-0.48,2.4c-0.099,1.421-0.106,2.933-0.479,4.08
				c0,0.08,0,0.16,0,0.24c-0.152,0.808,0.309,2.229-0.24,2.64c-0.306,0.975-0.444,2.116-0.48,3.36
				c0.27,11.154,0.5,24.254,0.721,36.239c-0.115,0.755,0.342,0.938,0.479,1.44c2.631,3.129,6.014,5.507,8.641,8.64
				c0,0.08,0,0.16,0,0.24c1.859,1.66,3.62,3.42,5.279,5.28c0.08,0,0.16,0,0.24,0c0.98,0.779,1.86,1.659,2.64,2.64
				c0.08,0,0.16,0,0.24,0c0.9,0.699,1.701,1.5,2.4,2.4c0.24-0.001,0.285,0.194,0.479,0.24c2.181,1.979,4.261,4.06,6.24,6.239
				c0.77,1.551,2.261,2.38,3.12,3.84c2.064-1.387,4.307-4.645,6.72-6.479c0.529-0.431,1.27-0.651,1.44-1.44
				c1.305-1.334,2.414-2.865,3.84-4.08c1.025-1.374,2.313-2.487,3.12-4.08c1.33-1.469,2.152-3.447,3.6-4.8
				c2.668-2.219,2.774-8.015,4.561-11.279c0.658-1.204,2.91-1.583,1.68-3.36c-0.339-1.411-0.498-3.493-0.24-4.8
				c0-0.32,0-0.641,0-0.96c0-0.4,0-0.801,0-1.2c0.08,0,0.16,0,0.24,0c0-0.88,0-1.76,0-2.64c0-0.32,0-0.641,0-0.961
				c0.556-0.964,0.083-2.956,0.24-4.319c0-0.24,0-0.48,0-0.72c0.551-0.49,0.087-1.993,0.24-2.881
				C372.797,517.157,374.249,509.41,375.12,501.081z"/>
					<path fill="none" d="M309.841,414.681c-2.6,6.518-7.948,12.775-13.2,16.8c-1.119,0.857-2.923,1.665-3.36,2.641
				c-1.539,3.435,0.393,7.561-1.2,11.04c0.913,2.526,2.04,4.84,2.88,7.439c9.664-1.297,22.65-0.55,32.4-0.96
				c-0.166,1.435,0.095,3.295-2.16,2.641c-9.229,0.733-20.672,0.389-29.52,0.72c0.591,2.487,3.022,5.31,2.16,9.359
				c-0.144,0.674-1.102,1.455-1.2,2.4c-0.371,3.533,2.172,5.368,3.359,7.44c3.426,5.976,5.136,13.209,6,20.64
				c0.972,8.34-0.18,17.332,0.48,26.4c0.432,5.933,3.857,10.872,4.32,15.84c0.604,6.494-0.444,13.906-1.44,19.68
				c-0.389,2.254-1.427,4.394-1.2,6c0.201,1.426,1.922,2.808,2.641,4.561c0.411,1.002,0.36,2.11,0.72,2.88
				c0.91,1.947,2.721,3.563,3.84,5.52c3.939,6.885,7.192,14.93,7.2,25.44c2.803-1.144,1.927-4.466,2.88-6.48
				c2.083-4.402,8.512-4.417,13.68-5.76c3.983-1.034,6.907-3.03,9.841-4.8c0.527-0.271,0.017-1.583,0.72-1.68
				c4.502-2.539,10.802-3.278,18-3.12c-2.926-7.106,4.279-17.126,6.72-23.04c1.025-2.483,1.161-4.918,1.92-7.44
				c0.276-0.917,1.025-1.657,1.2-2.64c0.851-4.778-1.033-10.887-2.4-14.88c-0.345,3.174-0.165,6.874-0.72,9.84c0,0.08,0,0.16,0,0.24
				c-0.154,0.966,0.313,2.552-0.24,3.119c-0.308,2.412-1.313,4.128-1.68,6.48c-0.17,1.03-0.392,2.009-0.96,2.64
				c-1.305,4.056-3.708,7.012-5.76,10.32c-2.179,2.702-4.171,5.589-6.72,7.92c0,0.08,0,0.16,0,0.24c-1.261,1.46-2.62,2.82-4.08,4.08
				c-1.175,0.266-1.424,1.457-2.4,1.92c-0.939,1.141-1.979,2.18-3.12,3.12c-0.563,0.076-0.9,0.379-1.68,0.24
				c-0.233,1.618-3.13,2.855-4.08,0.96c-0.806-1.675-2.289-2.672-3.36-4.08c-2.02-1.82-3.939-3.74-5.76-5.761c-0.08,0-0.16,0-0.24,0
				c-1.38-1.18-2.66-2.459-3.84-3.84c-0.08,0-0.16,0-0.24,0c-1.7-1.5-3.3-3.1-4.8-4.8c0-0.08,0-0.16,0-0.24
				c-1.22-1.02-2.34-2.14-3.36-3.359c0-0.08,0-0.16,0-0.24c-2.313-2.246-4.816-4.303-6.72-6.96c-0.728-1.272-1.476-2.525-1.92-4.08
				c-1.039-7.281-0.218-16.422-0.479-24.48c0-0.08,0-0.159,0-0.239c-0.553-0.568-0.086-2.154-0.24-3.12
				c-0.065-0.815,0.186-1.946-0.24-2.4c-0.549-0.411-0.088-1.832-0.24-2.64c-0.164-3.365,0.709-5.691,0.721-8.88
				c0.152-0.888-0.312-2.392,0.239-2.881c0.327-1.272,0.451-2.749,0.48-4.319c-0.171-1.932,0.92-2.601,0.96-4.32
				c0.272-2.752-0.784-4.176-0.72-6.72c-0.4-1.604-0.4-4.877,0-6.48c0.356,0.203,0.347,0.772,0.479,1.2
				c0.025,0.614,0.136,1.145,0.48,1.44c0.011-1.611-0.303-2.897-0.48-4.32c-0.547-0.333-0.09-1.671-0.24-2.4c0-0.72,0-1.439,0-2.16
				c-0.032-1.552,0.479-2.561,1.44-3.119c0.799-0.241,1.648-0.432,2.399-0.721c0.414-0.397,2.467-0.397,2.881,0c2,0,4,0,6,0
				c-0.074-0.874,0.038-1.562,0.239-2.16c0.151-0.729-0.307-2.066,0.24-2.399c0-0.08,0-0.16,0-0.24c0.158-1.922-0.317-4.478,0.24-6
				c0-0.08,0-0.16,0-0.24c1.105-7.877-2.046-24.037,6.72-23.279c2.416,0.208,3.479,2.273,4.561,4.319
				c4.473-7.064,11.616-1.763,11.04,6.48c2.079-0.081,4.111-0.209,6-0.48c0.08,0,0.159,0,0.239,0c4.6-0.28,9.194-0.565,14.16-0.479
				c4.717,10.803,11.411,19.629,13.44,33.12c-0.171-3.146,0.228-6.646,0-9.601c-0.632-8.205-4.889-19.911-8.88-24.72
				c-0.576-0.693-1.884-1.314-2.16-2.16c-0.673-2.059,0.257-6.7-0.24-9.36c-0.321-1.718-0.115-2.737,0-4.56
				c0.385-6.077-1.292-10.432-2.4-15.12c-2.146-9.083-6.011-17.14-9.359-24.72c-0.054-0.186-0.007-0.473-0.24-0.48
				c-1.101-2.579-2.355-5.004-4.32-6.72c-0.771-1.389-2.307-2.014-2.64-3.84c-11.872-5.568-20.832-14.048-31.68-20.64
				c0.02,2.98-0.556,5.364-0.48,8.4c-0.226,0.052-0.23,0.203-0.24,0.48c-0.445,13.545-3.082,25.711-7.92,35.28
				C312.491,407.822,311.142,411.418,309.841,414.681z"/>
					<path fill="none" d="M426.721,559.161c-13.393-0.048-17.363-9.517-22.32-18c-1.327-0.054-2.877-0.547-4.08,0
				c-3.528,1.149-7.134,1.843-11.04,3.12c-2.624,0.857-7.87,2.547-9.12,5.04c-1.115,2.226-0.942,5.221-1.92,7.68
				c-2.681,6.745-7.614,13.27-7.68,21.6c-0.348,0.772-1.673,0.567-2.4,0.96c6.246,0.234,10.262,2.699,14.16,5.28
				c0.78,0.98,1.66,1.86,2.641,2.641c8.324,10.955,11.289,27.271,12,45.84c2.612-1.63,4.46-3.726,8.399-4.8
				c6.355-1.733,13.06-2.118,15.841-6.721c1.62-1.42,3.14-2.939,4.56-4.56c1.511-1.997,3.428-5.614,3.84-8.16
				c1.018-6.283-4.249-12.83-4.08-19.92c-0.034-5.715,2.316-9.044,3.601-13.44c-0.857-0.743-1.829-1.371-2.641-2.16
				c-0.246-1.766-1.32-3.671-0.72-5.52C425.42,564.502,427.056,561.925,426.721,559.161z"/>
					<path fill="none" d="M375.36,431.24c6.741-1.339,14.366-1.793,21.84-2.399c4.539-0.501,9.817-0.264,14.4-0.72
				c-1.536-5.236-4.148-10.32-7.2-15.121c-2.868-4.511-5.533-9.902-9.359-13.2c-1.929-1.663-4.433-3.099-6.721-4.8
				c-7.522-5.594-16.676-10.079-27.359-12c0.362,1.4,2.225,3.374,2.88,5.28C369.88,400.401,374.426,414.016,375.36,431.24z"/>
					<path fill="none" d="M380.641,494.601c0.961-19.842-6.803-30.957-12.48-44.16c-3.753,1.018-9.104-1.023-12,0.721
				c-1.991,0.168-3.666,0.653-5.76,0.72c0,0.08,0,0.16,0,0.24c-0.104,2.456-0.118,5.398-0.24,8.16
				c-0.188,4.272-1.364,9.629,0.48,10.079c2.119,1.857,6.899,0.274,9.84,0c0.179-0.541,1.347-0.094,1.92-0.239c0.16,0,0.32,0,0.48,0
				c0.239,0,0.479,0,0.72,0c0-0.08,0-0.16,0-0.24c1.603,0.157,3.837-0.316,5.04,0.24c5.547-0.587,5.002,4.918,6.72,8.16
				c0.444,1.636,0.74,3.42,1.2,5.04c0.449,3.71,1.209,7.11,1.68,10.8c0.029,0.691-0.131,1.571,0.24,1.92
				c0.682,1.566-0.055,3.781,0,5.52C380.612,500.597,381.261,496.883,380.641,494.601z"/>
					<path fill="none" d="M340.561,474.44c-0.151,2.463-0.905,10.12,0.48,11.04c0.24,0,0.285,0.194,0.479,0.24c0-0.479,0-0.96,0-1.439
				c0.154-0.967-0.312-2.553,0.24-3.12c0-0.24,0-0.48,0-0.721c0.156-1.283-0.315-3.194,0.24-4.08
				c-0.14-0.778,0.163-1.116,0.239-1.68C341.408,474.873,341.318,474.323,340.561,474.44z"/>
					<path fill="none" d="M344.16,493.161c1.961-8.2,0.083-20.238,2.16-28.32c-0.014-0.146-0.035-0.284-0.24-0.24
				c-0.361,0.679-1.632,0.448-2.159,0.96C346.257,473.288,342.84,485.241,344.16,493.161z"/>
					<path fill="none" d="M318.961,476.121c-0.853,0.348-0.349,2.052-0.48,3.12c0,0.159,0,0.319,0,0.479
				c1.238,1.722-1.444,7.364,1.68,7.2c0.08,0,0.16,0,0.24,0c0.411-0.549,1.832-0.088,2.64-0.24c0.08,0,0.16,0,0.24,0
				c0.568-0.552,2.154-0.086,3.12-0.24c0.08,0,0.16,0,0.24,0c1.857-0.382,4.13-0.35,6.24-0.479c0-0.08,0-0.16,0-0.24
				c-0.559-2.241-0.081-5.519-0.24-8.16c0-0.24,0-0.479,0-0.72c-0.763-0.117,0.403-2.163-0.72-1.92
				C327.955,475.907,323.161,474.677,318.961,476.121z"/>
					<path fill="none" d="M335.04,473.001c0,1.04,0,2.08,0,3.12c0.558,1.362,0.083,3.758,0.24,5.52c0,0.8,0,1.601,0,2.4
				c0.4,2.162,0.4,5.998,0,8.16c0,0.72,0,1.439,0,2.16c0,1.119,0,2.239,0,3.359c0.025,1.016,0.646,1.434,1.44,1.68
				c0-0.159,0-0.319,0-0.479c0.157-1.603-0.317-3.837,0.24-5.04c0-0.48,0-0.96,0-1.44c0.159-6-0.32-12.64,0.239-18.239
				c-0.173-0.733,0.246-0.875,0.24-1.44C336.373,472.573,336,473.08,335.04,473.001z"/>
					<path fill="none" d="M287.28,444.44c0,0.08,0,0.16,0,0.24c0.72,0,1.44,0,2.16,0c1.319-4.12,1.051-9.829,1.68-14.64
				c-0.722-0.238-1.07-0.85-2.16-0.721c-2.679,0.281-5.447,0.473-7.92,0.96c0.446,2.15,1.52,4.419,2.64,7.44
				C284.398,439.658,285.303,444.633,287.28,444.44z"/>
					<path fill="none" d="M257.041,627.562c0.514-1.809,3.536-2.381,2.88-4.561c-0.274-0.846-0.527-1.712-1.2-2.16
				c-0.014-0.146-0.036-0.284-0.24-0.239c-5.308-6.372-13.5-9.861-20.4-14.641c-0.384-0.415-0.896-0.703-1.44-0.96
				c-2.926-1.642-4.922-5.467-8.64-5.52c-0.335,6.335,3.182,10.897,6,14.88c0.699,0.9,1.5,1.7,2.4,2.399
				c3.302,3.099,9.082,3.719,10.8,8.4c0.08,0,0.16,0,0.24,0c-0.078,1.255,1.713,0.559,2.16,0.24c2.021-0.14,3.398-0.922,5.76-0.721
				c0.622,0.419,1.116,0.965,1.44,1.681C256.932,626.71,256.52,627.602,257.041,627.562z"/>
					<path fill="none" d="M204,742.041c-7.383-4.137-15.823-7.216-27.12-7.439c-1.603-0.158-3.837,0.316-5.04-0.24
				c-12.732,0.766-17.991-8.87-30-6.24c-0.085,6.938,3.106,12.981,2.4,18.96c5.52,0,11.04,0,16.56,0
				c6.56,0.16,13.76-0.319,19.92,0.24c1.44,0,2.88,0,4.32,0c3.041,0.159,6.719-0.319,9.36,0.24c1.68,0,3.36,0,5.04,0
				c0.076-0.021,0.163-0.04,0.259-0.056c0.029-0.004,0.065-0.007,0.097-0.012c0.07-0.009,0.141-0.019,0.22-0.025
				c0.035-0.003,0.075-0.005,0.112-0.007c0.083-0.006,0.167-0.012,0.259-0.015c0.034-0.002,0.073-0.002,0.108-0.003
				c0.101-0.002,0.203-0.005,0.313-0.005c0.031,0,0.065,0.001,0.098,0.002c0.119,0,0.241,0.002,0.369,0.005
				c0.028,0.001,0.059,0.002,0.087,0.003c0.139,0.004,0.28,0.009,0.428,0.016c0.012,0.001,0.025,0.002,0.038,0.002
				c0.166,0.008,0.336,0.018,0.513,0.027c0.003,0.001,0.008,0.001,0.011,0.001c0.186,0.012,0.376,0.024,0.571,0.038
				c4.3,0.029,8.768,0.228,12.599-0.212c-2.589-1.091-5.077-2.283-7.68-3.36C206.426,743.455,205.146,742.815,204,742.041z"/>
					<path fill="none" d="M158.4,677.241c-6.171-2.544-12.634,1.142-18.48,1.92c0.223,1.777,0.024,3.977,0.72,5.28
				C145.874,681.355,154.869,682.03,158.4,677.241z"/>
					<path fill="none" d="M324.721,738.921c0-0.079,0-0.159,0-0.239c0-4.641,0-9.28,0-13.92c0-0.08,0-0.16,0-0.24
				c-0.553-0.568-0.086-2.154-0.24-3.12c-0.363-6.117-1.01-11.95-2.88-16.561c-1.027,3.178-1.492,6.148-4.561,6.96
				c-2.894,0.767-6.313-0.689-10.079-0.479c-0.729-0.15-2.067,0.308-2.4-0.24c-0.72,0-1.44,0-2.16,0
				c-1.283-0.156-3.195,0.315-4.08-0.239c-0.32,0-0.64,0-0.96,0c-0.729-0.151-2.067,0.307-2.4-0.24c-0.56,0-1.119,0-1.68,0
				c-0.729-0.15-2.067,0.307-2.399-0.24c-1.207-0.233-2.39-0.49-3.601-0.72c-6.621,2.929-14.316,5.713-22.32,8.399
				c-7.854,2.637-16.223,5.746-24.48,6.24c-3.01,0.181-6.244-0.004-8.88-0.479c-1.845-0.334-4.079-2.147-6.24-3.36
				c-14.993-8.42-31.752-14.188-43.2-25.44c-6.879-6.761-11.235-14.911-16.56-22.56c-5.491-7.889-10.755-16.082-14.4-24.721
				c-3.479,1.984-5.139,6.347-7.68,9.601c-1.92,2.459-4.075,4.862-6,6.96c-0.42,0.458-1.808,1.33-1.2,2.16
				c4.276-0.52,6.787-2.376,10.08-4.561c1.02-0.676,8.442-6.726,9.36-2.64c0.381,1.694-1.996,3.499-2.88,4.32
				c-2.874,2.67-4.888,4.598-7.44,6.96c-0.98,0.906-3.229,3.279-2.64,3.12c5.893-2.528,20.988-4.352,19.68,4.56
				c-0.561,3.822-5.814,5.115-9.84,6c-4.462,0.98-8.655,2.278-11.76,4.08c1.909,4.392,4.726,7.938,9.36,9.6
				c1.84,0.66,4.089,0.473,6,1.44c1.642,0.832,3.306,3.229,4.8,4.8c1.604,1.685,3.227,3.541,5.04,4.8
				c3.094,2.148,5.995,2.112,10.32,3.601c1.801,0.619,5.914,1.713,5.28,4.08c-0.538,2.008-5.714,0.988-7.2,0.72
				c-8.31-1.502-13.731-4.278-20.64-6.24c-1.089,1.761-6.274,6.105-6,8.641c0.232,2.146,3.233,2.636,4.8,4.08
				c0.283,0.893,0.786,1.582,1.44,2.159c3.702,3.271,10.509,4.104,16.56,5.04c2.162,0.159,4.958-0.317,6.72,0.24
				c9.583,0.372,17.615,2.386,25.2,5.521c3.461,1.43,7.016,3.272,10.56,4.8c4.137,1.782,10.618,2.814,13.44,5.52
				c2.477,0.846,8.043,0.48,12.24,0.48c28.676,0,58.319,0.197,85.44-0.24c0-0.08,0-0.16,0-0.24c0.153-0.887-0.311-2.391,0.24-2.88
				c0-0.08,0-0.16,0-0.24C324.638,742.359,324.164,740.125,324.721,738.921z"/>
					<path fill="none" d="M336.724,734.695c-0.371-1.04-0.513-4.039-0.546-6.6C336.21,730.665,336.353,733.654,336.724,734.695z" />
					<path fill="none" d="M337.44,692.602c0.47-3.211,0.916-6.444,1.92-9.12c0.315-1.205,0.99-2.05,1.44-3.12
				c-6.498,2.782-12.497,6.063-17.521,10.32c0.141,3.021-0.173,5.586-0.24,8.399c0.35,0.132,1.241-0.28,1.2,0.24
				c3.261,0.14,5.816-0.424,7.92-1.44C333.906,696.107,336.159,694.84,337.44,692.602z"/>
					<path fill="none" d="M147.6,640.281c-2.628-4.172-4.644-8.956-7.44-12.96c-4.349,7.571-7.549,16.291-12,23.76
				c0.42,0.848,0.189,1.976,0,2.88c4.622-2.018,8.818-4.461,14.64-5.279C145.105,646.587,145.959,643.041,147.6,640.281z"/>
					<path fill="none" d="M319.2,695.961c0-1.199,0-2.399,0-3.6c-1.984,0.696-4.261,0.47-6,1.2c-1.707,0.717-3.394,2.286-5.04,3.359
				c-5.331,3.478-9.992,6.812-15.359,10.08c2.655,0.225,4.819,0.94,7.92,0.721c1.682,0.157,3.997-0.317,5.279,0.239
				c3.257,0.174,6.783,1.122,10.08,0.48c1.102-1.699,1.94-3.66,2.16-6.24c-1.002-1.436-0.03-2.623,0.721-3.6
				C319.248,697.929,319.225,696.945,319.2,695.961z"/>
					<path fill="none" d="M308.16,350.84c-1.275,1.524-2.526,3.074-3.6,4.8c-0.9,0.699-1.7,1.5-2.4,2.4
				c-2.063,1.458-4.042,2.998-6,4.56c1.562-0.251,2.593,1.113,3.12,2.16c2.243,4.454,1.42,12.896,2.88,18.24c0,1.36,0,2.72,0,4.08
				c0.014,0.146,0.036,0.284,0.24,0.24c0,4.72,0,9.44,0,14.16c-0.146,0.014-0.284,0.036-0.24,0.24c0,1.76,0,3.52,0,5.28
				c-0.412,4.468-2.824,8.214-3.6,11.52c5.105-6.415,10.812-12.228,14.4-20.16c1.572-2.507,1.437-6.723,3.359-8.88
				c0.491-11.316,3.098-22.919,2.16-34.08c1.787-6.792,0.818-16.09-1.68-21.36C316.677,342.397,311.262,345.462,308.16,350.84z"/>
					<path fill="none" d="M381.841,707.961c0-0.239,0-0.479,0-0.72c-0.558-1.362-0.082-3.758-0.24-5.52
				c-0.025-3.895-0.56-7.28-1.44-10.32c-0.578-0.099-0.521,0.438-1.199,0.24c-0.885,0.555-2.797,0.083-4.08,0.239
				c-0.24,0-0.48,0-0.721,0c-0.41,0.55-1.832,0.088-2.64,0.24c-5.654,0.666-10.853,1.787-16.32,2.641
				c-1.789,0.983-3.72-0.852-5.04,0.239c-4.79,0.25-8.032,2.048-12.239,2.88c-1.231,0.93-2.193,2.127-3.601,2.881
				c-0.707,0.355-1.48,0.643-2.283,0.902l0.212,2.083c0.804,0.254,1.523,1.052,1.352,2.054c-0.076,0.557,0.038,0.922,0.24,1.2
				c0.55,1.689,0.862,3.618,0.96,5.761c0.146,2.813,0.687,5.233,0.96,7.92c6.42-2.002,13.701-4.349,21.12-4.561
				c1.694-0.398,0.215,1.588-0.721,1.92c-5.663,2.977-14.223,3.057-19.92,6c-0.016,3.729,0.635,6.79,0.483,10.654
				c0.016,0.044,0.03,0.09,0.047,0.127c0.287-0.091,0.438-0.316,0.91-0.221c3.881-0.707,6.845-2.998,9.84-4.8
				c3.173-1.909,6.613-2.983,9.84-5.04c-0.278-0.202-0.644-0.317-1.2-0.24c-1.802-0.198-4.467,0.467-5.52-0.48
				c0-0.08,0-0.16,0-0.239c0.696-2.786,9.021-2.255,9.12,0.96c0.091,2.952-5.277,3.748-7.681,5.04
				c-3.299,1.773-5.261,4.052-7.68,5.04c-0.757,0.843-1.964,1.235-3.12,1.68c-1.623,0.297-2.185,1.655-3.6,2.16
				c-1.036-0.146-1.32,1.706-0.72,2.399c7.503-3.385,16.394-5.964,24.479-8.64c2.553-0.845,5.462-3.132,8.16-1.68
				c0.975,0.675-0.044,2.176-0.96,2.399c-10.441,3.719-22.226,6.095-31.92,10.561c0.09,1.449-0.068,2.652-0.24,3.84
				c11.521,0.16,23.68-0.32,34.8,0.24c0.16,0,0.32,0,0.48,0c0.08,0,0.16,0,0.24,0c0.41,0.549,1.832,0.088,2.64,0.239
				c1.76,0,3.52,0,5.28,0c0-0.399,0-0.8,0-1.199c0.959-5.121,2.183-9.978,2.88-15.36c0.556-0.965,0.083-2.957,0.24-4.32
				c0.171-1.211-0.328-1.752-0.48-2.64c-1.396-0.116-1.403,1.156-3.12,0.72c-1.314-4.021,4.463-4.711,5.76-7.68
				c-1.667-1.772-3.104-3.776-3.119-7.2C381.93,709.632,382.388,708.294,381.841,707.961z"/>
					<path fill="none" d="M473.521,577.161c-6.744-15.628-14.466-30.891-21.359-46.32c-0.993-2.223-2.529-4.619-3.12-6.72
				c-0.626-2.224,0.046-4.495-1.44-6.24c-0.365-0.072-1.743,0.913-2.88,0.24c-0.652-3.559-2.731-4.617-4.56-7.2
				c-3.63-5.128-5.284-13.729-8.641-18.24c-5.81-1.649-6.114-6.844-7.2-12.96c-2.933-16.521-6.875-33.673-11.52-48
				c-4.025-0.025-7.955,0.046-11.521,0.48c-0.399,0-0.8,0-1.199,0c-8.552,0.488-16.867,1.213-24.721,2.399
				c-0.099,2.819,0.427,5.014,0.24,7.92c6.793,9.643,11.929,23.936,11.04,40.08c0.099-2.302,0.825-3.975,1.44-5.76
				c0.196-0.764,0.152-1.767,0.479-2.4c0.95,0.25,0.168,2.232,0.72,2.88c0.493,5.49,0.014,11.986-3.359,14.4
				c-0.306,2.239-1.072,4.469-1.681,6.72c-0.624,2.314-0.834,5.054-1.68,6.96c-0.465,1.05-2.109,1.457-1.68,2.881
				c5.826-4.33,10.143-12.772,12.96-20.4c0.396-1.072,0.188-3,1.68-3.36c0.762,2.379,1.218,5.246,0.72,7.44
				c-1.935,8.522-9.945,16.232-15.6,20.64c4.826,0.854,9.864,1.496,10.56,6.48c-4.813,0.093-6.67-2.77-11.04-3.12
				c-0.728,3.031-0.04,7.479-0.96,10.32c-0.179,0.86-2.203-0.124-1.92,1.199c0.42,0.838,1.435,1.057,1.92,2.16
				c1.489,3.388,2.255,9.021,2.16,14.16c8.103-1.324,16.53-8.056,25.2-5.28c1.291,0.414,3.069,4.213,3.84,5.521
				c3.469,5.881,5.31,9.054,12.48,10.8c1.539,0.375,3.895,0.188,5.52,0.96c4.953,2.351-0.984,11.102,1.44,15.601
				c1.82,1.139,3.608,2.312,3.12,5.76c0.85-0.67,1.828-1.212,2.399-2.16c0.08,0,0.16,0,0.24,0s0.16,0,0.24,0
				c0.645-1.035,1.752-1.608,2.64-2.4c2.236-0.738,7.612-7.66,8.88-2.88c-0.248,1.432-1.577,1.783-2.399,2.641
				c-2.537,1.383-4.363,3.477-6.721,5.04c-0.74,0.539-1.38,1.179-1.92,1.92c-0.08,0-0.159,0-0.239,0c-0.94,1.14-1.98,2.18-3.12,3.12
				c0,0.079,0,0.16,0,0.239c-0.979,1.183-1.98,2.34-2.88,3.601c-0.08,0-0.16,0-0.24,0c0.28,1.174,5.114-0.379,7.439,0
				c0.729,0.15,2.067-0.308,2.4,0.24c4.475,1.125,11.163,0.036,13.44,3.359c0.072,0.69-2.139,1.191-0.96,1.44
				c9.387,1.493,16.829,4.93,23.52,9.12c0,0.08,0,0.16,0,0.239c0,0.32,0,0.641,0,0.96c-1.439,1.906-3.824-0.363-5.28-0.72
				c-0.802-0.397-1.598-0.802-2.399-1.2c0-0.08,0-0.16,0-0.239c-6.302-2.979-13.011-5.55-22.08-5.761
				c-1.204-0.155-3.035,0.314-3.84-0.239c-4.321-0.081-8.511-0.03-11.521,1.199c0.796,4.294,2.706,6.479,3.12,10.32
				c0.589,5.467-1.635,9.902-3.36,12.72c-2.372,3.876-7.579,9.752-12.479,12c-3.847,1.766-8.561,1.119-12.72,2.881
				c-3.147,1.332-5.583,4.67-8.16,6.239c-0.438,8.362-3.154,14.446-6.48,19.92c-9.664,3.223-16.303,9.112-23.28,14.881
				c-4.132,3.415-7.129,7.745-13.92,7.92c-0.523,3.156-1.765,5.595-2.64,8.399c0.088,0.632-1.197,1.566,0.24,1.44
				c31.435-4.585,65.358-7.985,91.2-18.24c8.805-3.494,16.681-8.317,24.96-11.76c0.492-0.787,1.644-0.917,2.399-1.44
				c6.376-3.384,12.265-7.256,18.721-10.56c0-0.24,0-0.48,0-0.72c0-0.641,0-1.28,0-1.921c0.224-15.313-0.857-30.022-4.08-41.52
				C481.321,595.396,477.531,586.454,473.521,577.161z"/>
					<path fill="none" d="M444.001,590.121c-1.564-0.196-2.972-0.549-4.8-0.48c-0.809-0.151-2.229,0.31-2.641-0.239
				c-0.56,0-1.12,0-1.68,0c-0.333,0.547-1.671,0.089-2.4,0.239c-0.983-0.023-1.967-0.047-2.64,0.24c0,0.561,0,1.12,0,1.68
				c1.438,0.117,3.708-0.9,5.76-0.96C437.843,589.883,441.759,590.839,444.001,590.121z"/>
					<path fill="none" d="M133.92,613.641c-1.943-3.896-3.723-7.957-5.76-11.76c-0.022,15.258-1.39,30.45-2.4,43.92
				c3.712-7.728,7.354-15.525,11.28-23.04C135.648,620.2,134.046,615.942,133.92,613.641z"/>
					<path fill="none" d="M350.641,224.6c-2.049,3.552-3.793,8.687-4.08,13.2C347.658,233.138,350.195,229.916,350.641,224.6z" />
					<path fill="none" d="M345.36,226.521c0.155,1.045-0.313,2.713,0.24,3.36c0.868-3.084,2.246-6.714,3.84-10.32
				c1.662-3.76,4.562-12.046,0.72-14.16c-4.573-2.628-6.054,8.122-7.68,11.28C343.831,219.569,344.819,222.821,345.36,226.521z"/>
					<path fill="none" d="M347.041,690.441c1.173-2.667,2.265-5.415,3.119-8.4c-2.138,0.475-4.768,0.182-6.479-0.479
				c-1.15,2.289-2.162,4.718-2.4,7.92c0.759-0.362,1.027-1.214,2.16-1.2c2.083,1.508-1.981,2.279-1.439,4.56
				c1.184-0.336,2.655-0.384,3.84-0.72c0.044-0.355,0.58-0.22,0.96-0.24C346.938,691.459,346.511,690.471,347.041,690.441z"/>
					<path fill="none" d="M183.36,225.08c-0.39-1.569,1.185-2.342-0.72-3.12c-0.261,7.434-4.739,14.972-1.2,21.84
				c2.015-3.585,4.667-6.532,6.96-9.84C188.633,229.778,184.104,228.078,183.36,225.08z"/>
					<path fill="none" d="M343.2,203.72c0-0.48,0-0.96,0-1.44c-0.707-0.492,0.229-2.628-0.479-3.12c-0.43,2.051-0.926,4.034-0.96,6.48
				C342.149,210.264,343.921,206.35,343.2,203.72z"/>
					<path fill="none" d="M208.56,276.92c0.813,2.898,1.26,6.032,2.16,8.16c0.744,1.76,3.292,5.512,5.04,6.48
				c2.789,1.543,5.111,0.959,5.76,3.36c0.354,1.308-0.452,3.217,0.48,4.08c0.403,0.476,1.377,0.383,1.68,0.96
				c3.731,4.485,4.131,10.154,12,10.8c4.229,0.348,11.179-0.734,11.52,2.16c0.217,1.843-1.986,2.533-3.12,3.36
				c4.235,1.551,6.421,4.276,10.08,7.2c2.583,2.064,5.527,5.046,7.92,6c2.5,0.997,11.397-0.647,12,1.44
				c0.609,2.11-2.892,3.194-2.88,4.08c3.002,2.278,7.596,2.964,9.12,6.72c3.713-3.328,9.839-4.241,16.561-4.56
				c0.979,0.38,0.945,1.774,1.68,2.4c1.429-1.932,3.112-3.607,5.04-5.04c1.462-0.47,1.245,1.64,2.399,0.72
				c2.428-3.835,5.508-10.819,7.921-15.84c0.806-1.679,1.504-4.919,2.88-5.28c1.13-0.297,2.958,0.649,3.359,1.92
				c0.491-1.029,1.515-1.526,2.4-2.16c1.266-0.647,2.027,1.302,2.16,0.24c0.547-1.853,0.051-4.749,1.68-5.52
				c1.231-0.55,1.924,1.587,2.16,0.48c1.23-6.049,3.825-10.735,6.479-15.36c0.352-5.208-1.432-11.153-0.96-17.28
				c0.315-4.094,1.49-7.752,0.48-11.76c-3.072,7.728-9.991,11.609-9.84,22.56c-0.128,0.832,0.356,2.276-0.48,2.4
				c-0.912,0.108-1.707-0.622-2.399,0c-0.127,2.278-0.542,4.808-0.96,7.44c-0.516,3.242-0.515,7.517-2.4,8.4
				c-2.767-0.513-2.714-3.846-3.6-6.24c0.074,0.955-0.555,1.206-0.48,2.16c-2.06,5.46-3.897,11.143-6.479,16.08
				c-0.299,1.382,0.089,3.449-2.16,2.88c-0.716-0.404-0.609-1.631-0.721-2.64c0.483-0.718,0.136-2.265,0.24-3.36
				c0.439-1.64,0.288-3.872,0.96-5.28c0.717-0.004-0.338-1.779,0.721-1.44c0.454,0.985,0.441,2.438,0.96,3.36
				c0.833-2.418,1.896-5.14,3.119-8.88c0.863-2.636,1.717-8.83,5.04-8.4c1.295,0.786,1.216,2.944,1.681,4.56
				c1.589-3.771-0.239-10.958,4.319-11.76c1.627-4.454,1.742-10.418,4.801-13.44c3.143-3.498,5.209-8.071,7.68-12.24
				c1.033-0.533,1.937,1.011,1.92,0c2.237-10.323,5.87-20.53,5.521-32.64c-1.069-4.372-2.339-8.542-2.641-13.68
				c0-1.76,0-3.52,0-5.28c0.541-3.459,1.128-6.872,1.44-10.56c0.541-0.179,0.094-1.346,0.24-1.92
				c0.549-0.411,0.088-1.832,0.239-2.64c0-0.08,0-0.16,0-0.24c0-0.88,0-1.76,0-2.64c0-2.72,0-5.44,0-8.16c0-0.08,0-0.16,0-0.24
				c-0.08,0-0.16,0-0.239,0c0-0.08,0-0.16,0-0.24c0-1.12,0-2.24,0-3.36c0.013-0.307,0.308-0.333,0.239-0.72
				c0.194-0.045,0.239-0.241,0.48-0.24c0.009-7.891-2.565-14.065-2.4-21.36c-0.43-3.01-1.094-5.786-1.92-8.4
				c-0.291-0.909-0.465-1.936-0.96-2.64c-0.427-0.933-0.583-2.137-1.2-2.88c-0.004-0.716-0.624-0.816-0.72-1.44
				c0-0.16,0-0.32,0-0.48c-0.55-0.33-0.563-1.196-1.2-1.44c0.291,0.909,0.465,1.936,0.96,2.64c0.025,0.615,0.136,1.144,0.48,1.44
				c0,0.08,0,0.16,0,0.24c-0.323,2.182,2.952,3.166,0.96,4.8c-0.733,0.173-0.875-0.246-1.44-0.24
				c-0.053-0.187-0.201-0.279-0.479-0.24c-4.02-2.381-7.576-5.224-11.28-7.92c1.527,3.913,2.479,8.4,3.12,13.2c0,1.04,0,2.08,0,3.12
				c-0.76,1.801,0.164,5.284-2.4,5.28c-1.361-1.077-0.113-2.62-0.24-4.08c-0.249-2.876-2.508-6.57-4.319-8.88
				c-2.049-2.612-4.8-4.791-7.44-5.76c0.229,1.56,1.688,4.523-0.479,5.04c-2.887-1.354-3.714-4.767-6.48-6.24
				c-0.813-0.631-1.919-2.234-3.12-1.68c1.2,0.879,2.095,2.065,3.12,3.12c3.334,4.7,7.587,13.341,8.641,19.92
				c0.336,2.102,0.304,4.426-1.44,4.56c-1.81,0.139-2.486-3.441-3.36-5.04c-1.56-2.855-3.256-4.231-4.319-5.76
				c-1.26-1.46-2.62-2.82-4.08-4.08c-5.234-5.965-15.81-6.591-23.76-9.84c-0.441,2.519-3.811,2.109-6,2.88c-2,0-4,0-6,0
				c-10.481-0.079-17.732-3.388-23.52-8.16c-1.07-1.25-2.42-2.22-3.6-3.36c0.423,1.097,1.098,1.942,1.92,2.64
				c2.596,3.283,6.461,6.063,9.6,8.64c0.931,0.764,2.955,1.563,2.64,3.12c-3.361-0.304-6.541-1.982-9.36-3.6
				c-2.832-1.625-5.381-3.799-7.68-5.76c-1.107-1.972-0.624,1.912-0.72,3.12c-0.998,13.843-7.224,24.775-8.64,37.92
				c-0.152,1.413,0.41,3.068-0.96,4.32c-1.9-2.179-1.612-6.547-2.4-9.84c-0.095,1.345-0.704,2.176-0.72,3.6
				c-0.52-0.041-0.108,0.851-0.24,1.2c-0.152,0.808,0.309,2.229-0.24,2.64c0,0.48,0,0.96,0,1.44c-2.048,5.631-7.317,8.042-8.4,14.64
				c-0.011,0.789-1.026,0.574-0.96,1.44c1.438,5.842,2.862,11.699,3.36,18.48c0.042,1.272,0.156,1.225,0,2.4
				c-1.063,5.234-3.582,10.627-1.92,16.08c0.78,2.558,3.198,5.596,5.04,8.64c2.235,3.693,4.1,7.062,6.24,9.12
				c0.459,0.661,1.019,1.221,1.68,1.68c1.212,1.268,2.29,2.67,3.6,3.84c2.836,3.804,5.405,7.875,7.92,12
				c0.782-0.738-0.424-3.464,0.72-3.84c1.98,1.059,3.219,8.15,4.32,9.84c0.338-1.262,0.361-2.839,1.68-3.12
				c1.439,1.281,0.701,4.74,1.44,6.72c0.075,2.162-0.11,5.05,0,6.48c0.149,0.714-0.39,2.91,0.48,2.16c0-0.08,0-0.16,0-0.24
				c0.57-0.39,0.382-1.538,1.68-1.2c-0.108,2.212-1.372,3.268-1.92,5.04c-0.309,0.412-0.497,0.944-1.2,0.96
				c-0.819-0.621-0.367-2.513-0.48-3.84c0-0.56,0-1.12,0-1.68c-0.557-1.124-0.083-3.277-0.24-4.8c0-0.24,0-0.48,0-0.72
				c-0.547-0.333-0.089-1.671-0.24-2.4c0-0.16,0-0.32,0-0.48c-0.688-0.032,0.208-1.647-0.48-1.68c-0.048,0.832-0.2,1.56-1.2,1.44
				c-0.861-2.899-1.239-6.281-2.88-8.4c-0.548,1.186-0.711,1.295-1.2,2.4c-1.662-0.978-2.222-3.058-3.36-4.56
				c-0.997-0.923-1.565-2.275-2.4-3.36c-1.243-2.197-2.791-4.09-4.32-6c-1.14-0.94-2.18-1.98-3.12-3.12
				c-5.433-6.647-10.622-13.539-13.68-22.56c-0.399-1.205-0.399-4.075,0-5.28c0.184-3.565,2.232-6.677,2.4-10.32
				c0.126-2.746-0.872-6.007-1.44-9.12c-1.107-6.066-2.097-12.147-3.6-17.52c-0.785-2.806-2.146-5.542-1.68-8.16
				c1.631,1.569,1.608,4.792,2.64,6.96c0.528,0.591,0.533,1.707,0.96,2.4c0.454,0.026,0.167,0.793,0.72,0.72
				c1.003-2.997,0.285-7.714,2.4-9.6c1.267,2.114-0.651,5.279,0,7.44c1.383-2.789,4.165-5.057,5.52-8.4
				c1.84-4.539,0.623-10.308,4.08-13.68c0.965,0.315,0.683,1.876,0.72,3.12c0.553,0.647,0.085,2.315,0.24,3.36
				c0.396-0.004,0.134-0.666,0.48-0.72c2.2-11.56,6.862-20.658,7.68-33.6c0-1.52,0-3.04,0-4.56c-0.698,0.183,0.218,1.978-0.48,2.16
				c-0.331,0.31-0.238,1.042-0.24,1.68c-0.067,0.813-0.382,1.378-0.24,2.4c-0.009,0.791,0.098,1.698-0.72,1.68
				c-0.529-0.912-0.238-2.642-0.72-3.6c-0.688,0.032,0.208,1.647-0.48,1.68c-0.202,0.598-0.314,1.286-0.24,2.16
				c-0.07,1.291-0.251,2.468-0.72,3.36c-1.844,13.036-10.617,19.144-18.48,26.16c-0.479,1.352-2.52-0.285-1.44-1.2
				c2.495-7.986,10.938-10.023,11.04-20.4c0-1.36,0-2.72,0-4.08c0.054-1.254-0.004-2.396-0.24-3.36
				c-0.698-0.182,0.218-1.978-0.48-2.16c-2.015,6.865-6.309,11.451-8.88,17.76c-0.27,0.69-0.048,1.872-0.48,2.4
				c-0.283,0.356-0.208,1.072-0.96,0.96c-1.287-2.841,1.016-5.941,1.92-8.16c0.374-0.906,1.066-1.494,1.44-2.4c0.08,0,0.16,0,0.24,0
				c2.757-3.852,4.892-9.427,6.24-14.64c0.383-1.48,0.472-4.425,2.4-4.32c0.551,0.489,0.087,1.993,0.24,2.88c0,0.8,0,1.6,0,2.4
				c0,0.08,0,0.16,0,0.24c0.559,2.082,0.081,5.199,0.24,7.68c0,0.08,0,0.16,0,0.24c0.399,1.444,0.399,4.556,0,6
				c-0.401,7.599-6.529,10.751-8.88,15.6c1.471-1.489,3.242-2.678,4.8-4.08c0.86-1.06,1.82-2.02,2.88-2.88
				c4.115-4.765,6.783-10.977,7.92-18.72c0.151-0.729-0.307-2.067,0.24-2.4c0-0.24,0-0.48,0-0.72c-0.398-0.649-0.398-2.951,0-3.6
				c0-0.16,0-0.32,0-0.48c-0.018-0.418,0.054-0.747,0.24-0.96c1.073-0.194,1.333,0.427,1.44,1.2c-0.018,0.418,0.053,0.747,0.24,0.96
				c0.454,0.027,0.167,0.794,0.72,0.72c0-0.96,0-1.92,0-2.88c0-1.44,0-2.88,0-4.32c0.564-3.26-1.718-6.778-0.24-8.64
				c1.371,0.526,0.974,1.05,2.64,0.48c1.274,2.726,3.186,4.814,5.04,6.96c1.38,1.18,2.66,2.46,3.84,3.84
				c4.67,3.811,9.694,7.266,17.76,7.68c0.808,0.152,2.229-0.309,2.64,0.24c0.48,0,0.96,0,1.44,0c2,0,4,0,6,0
				c1.855-0.064,3.546-0.294,4.56-1.2c-1.752-0.728-2.951-2.009-4.56-2.88c-0.865-0.895-2.193-1.327-2.16-3.12c0.08,0,0.16,0,0.24,0
				c1.269-2.458,3.252,0.654,4.56,1.44c8.082,4.856,23.576,5.895,29.76,12.48c1.103,0.737,1.777,1.903,2.88,2.64
				c0.07,0.489,0.649,0.471,0.72,0.96c0.741,0.939,1.622,1.739,2.4,2.64c0.538,0.742,0.971,1.59,1.68,2.16
				c-0.376-1.064-0.525-2.354-1.199-3.12c-0.979-3.102-2.49-5.67-4.08-8.16c-0.645-1.276-1.457-2.384-2.4-3.36
				c-1.707-2.293-4.269-3.731-6.24-5.76c0.099-0.578-0.438-0.521-0.24-1.2c0.08,0,0.16,0,0.24,0c-0.029-1.146,0.601-1.082,1.2-1.2
				c4.2-0.825,7.777,5.303,11.04,6.24c0.352-0.004,0.761,1.012,0.72,0c0.514-6.502,10.101,2.521,11.04,3.36
				c0.135,0.345,0.375,0.585,0.721,0.72c1.047,1.033,1.433,2.728,2.64,3.6c-0.082-2.798-0.968-4.792-1.68-6.96
				c-0.431-1.25-1.109-2.251-1.681-3.36c-0.546-0.893-1.051-1.829-1.68-2.64c-0.61-1.07-1.43-1.931-2.16-2.88
				c-0.857-4.644,4.104-1.042,4.32,0.96c3.59,2.09,6.491,4.869,9.84,7.2c0.849,0.112,0.844,1.076,1.92,0.96
				c0.04-0.839-0.063-1.536-0.479-1.92c-0.274-2.206-0.533-4.427-1.44-6c-0.334-1.187-0.893-2.147-1.2-3.36
				c0.028-1.228-0.709-1.691-0.96-2.64c-0.694-1.465-1.446-2.873-2.16-4.32c-1.404-2.275-2.812-4.548-4.56-6.48
				c0-0.08,0-0.16,0-0.24c-0.64-0.24-0.791-0.969-1.2-1.44c-1.198-1.362-2.354-2.767-4.08-3.6c-0.641-0.879-1.85-1.19-2.64-1.92
				c-2.634-2.167-5.745-3.855-9.12-5.28c-1.363-0.397-2.371-1.149-3.601-1.68c0-0.08,0-0.16,0-0.24
				c-0.674-0.525-1.442-0.958-1.439-2.16c1.585-1.753,3.883,0.107,5.28,0.72c11.349,4.977,19.277,13.173,24.479,23.52
				c0.897,1.786,1.146,3.994,2.16,6c1.358,2.69,4.254,4.526,5.28,7.2c0.843,0.757,1.236,1.964,1.68,3.12
				c3.104,7.135,4.327,16.152,6,24.72c0.511,3.889,1.107,7.693,1.2,12c1.068-5.682-0.141-12.943-0.48-18.96
				c-0.137-2.423-0.4-6.885,2.16-6.72c0.407,2.673-0.817,6.349,0.48,8.88c2.435-11.571,2.078-22.916-1.44-31.68
				c-1.595-3.972-4.231-6.647-6.479-10.32c-5.977-9.765-9.941-21.507-18.48-27.84c-2.168,0.088-2.587-1.573-3.6-2.64
				c-2.635-0.969-4.311-2.815-6.721-4.08c-2.688-1.412-5.604-1.357-8.88-2.16c-5.565-1.364-11.498-4.162-18-4.08
				c1.518,2.803,5.204,5.517,7.44,8.4c-6.375,1.949-7.468-8.06-14.64-8.88c-6.914-0.791-16.227,1.774-24.72,2.16
				c-14.278,0.648-26.332,3.59-31.68,12.96c0,0.88,0,1.76,0,2.64c-1.308,3.058-4.416,4.424-6.72,6.48
				c-1.048,0.935-2.586,2.399-2.4,3.12c1.782,0.057,3.746-0.066,4.08,1.44c-7.38-0.479-10.755,2.933-14.64,6.48
				c-2.256,2.06-5.286,4.889-6.24,6.72c-2.507,4.811-3.002,13.094-3.6,20.4c-1.145,13.992-0.142,30.38-0.96,43.92
				c1.553,1.007,1.432,3.688,1.92,5.76c1.373,3.488,4.619,6.889,6.24,10.56c1.528,3.459,0.14,8.308,0.72,13.44
				c1.656,5.185-3.406,6.402-5.28,3.6c-0.982-1.469-0.422-4.662-1.2-6.96c-0.611-1.804-1.696-2.271-1.92-3.12
				c-1.1-1.3-2.3-2.5-3.6-3.6c-1.505-1.539-6.015,1.306-7.2,2.88c-2.901,3.854-2.378,17.468-3.36,22.56
				c0.001,3.046,0.146,1.514,0.48-0.24c1.558-0.74,2.379,1.486,2.64,0.48c2.843-5.944,2.3-15.27,1.68-22.32
				c2.31-0.97,3.434,1.598,4.32,2.88c3.253,4.706,5.988,10.251,6.96,16.56c1.007,1.904,6.439,2.726,6.96,5.76
				c0.496,2.886-2.919,6.127-3.6,9.12c-0.221,0.967-0.618,2.967-0.48,3.84c0.307,1.942,3.939,3.203,3.36,5.04
				c-0.55,1.745-2.604,0.318-3.84,0c-3.531-0.908-7.507-0.833-10.32-2.4c2.201,7.239,5.624,13.256,9.84,18.48
				c1.26,1.46,2.62,2.82,4.08,4.08c0.293,0.027,0.568,0.072,0.48,0.48c3.8-1.494,3.132-6.868,2.88-11.76
				c-0.196-3.8-1.757-8.039-0.24-10.32c0.573-0.502,1.739-0.143,1.92,0.48c0,0.241,0.194,0.286,0.24,0.48
				c2.905,4.334,2.786,10.709,3.84,17.04C207.089,271.219,207.792,274.179,208.56,276.92z"/>
					<path fill="none" d="M132.72,601.641c4.694,10.311,9.779,20.228,15.12,29.041c7.694,12.697,15.184,24.945,22.8,36.239
				c3.769,5.59,8.43,11.203,12.96,16.32c7.07,7.986,14.39,15.689,24,20.16c10.163,4.729,18.901,10.458,28.08,16.32
				c5.259,1.332,10.286,0.206,15.6-1.2c9.917-2.624,19.61-6.338,28.32-9.84c9.336-3.754,16.938-8.394,24.24-13.92
				c2.467-1.868,5.007-4.232,7.439-5.28c2.565-1.105,5.34-0.84,7.44-1.681c1.769-0.707,3.27-2.583,5.04-3.84
				c1.767-1.254,3.793-2.354,5.52-3.359c11.758-6.846,24.412-10.9,35.761-17.761c0.553-13.235-0.989-26.339-3.36-37.439
				c-0.759-0.602-1.08-1.641-1.44-2.641c-0.638-2.161-1.785-3.813-2.88-5.52c-0.839-0.682-1.243-1.797-1.92-2.64
				c-1.158-2.362-2.995-4.046-4.56-6c-2.101-1.9-4.101-3.9-6-6c-2.927-2.432-8.799-5.591-12.48-2.4c0,0.16,0,0.32,0,0.479
				c6.208,6.587,9.142,15.727,12.48,24.721c3.336,8.987,7.113,18.573,8.16,29.28c0.24,0.965,0.613,3.058,0.239,3.84
				c-0.293,3.067-3.714,1.766-3.359-0.96c-0.556-0.965-0.084-2.957-0.24-4.32c-1.653-16.187-7.691-27.988-12.72-40.8
				c-0.725-0.877-1.091-2.109-1.681-3.12c-1.059-2.701-2.955-4.565-4.319-6.96c-0.563-0.062-0.139-1.055,0-1.2
				c-0.454-0.054-0.53,0.271-0.961,0.24c-1.93-0.171-0.593,2.926-2.159,3.12c-1.616,0.495-1.104-1.138-2.4-0.96
				c-1.402,0.677-2.919,1.241-3.6,2.64c-0.874-0.074-1.563,0.038-2.16,0.24c-8.462,5.139-16.155,11.044-28.08,12.72
				c-0.333,0.547-1.671,0.09-2.4,0.24c-0.56,0-1.12,0-1.68,0c-0.647,0.553-2.315,0.085-3.36,0.239
				c-9.26,0.955-13.813,1.784-19.68,5.521c-8.042,5.121-12.687,13.958-16.32,24c-0.756,2.09-2.464,9.84-4.56,4.8
				c0.159-2.721-0.318-6.078,0.24-8.399c1.708-1.181,1.872-3.521,2.64-6c0.942-3.04,1.984-6.297,3.36-8.4
				c0.687-1.051,2.311-1.739,1.92-3.12c-2.126,0.354-3.723,1.237-6,1.44c-0.595-0.286-0.89-0.87-0.72-1.92
				c-1.474-0.927-1.906-2.895-3.36-3.841c-2.491-1.429-5.091-2.748-7.44-4.319c-1.06-0.86-2.021-1.82-2.88-2.88
				c-0.783-1.537-1.846-2.794-2.64-4.32c-1.007-1.073-1.552-2.607-2.16-4.08c-0.574-2.146-1.435-4.006-1.92-6.24
				c-0.639-0.4,0.133-2.213-0.48-2.64c-0.08,0-0.16,0-0.24,0c-1.107-0.893-2.132-1.868-2.88-3.12
				c-0.597-1.403-1.428-2.571-1.92-4.08c-0.568-0.632-0.79-1.61-0.96-2.64c-0.426-1.895-0.748-3.893-1.2-5.761
				c-0.397-0.259-0.397-2.141,0-2.399c0.58-2.238,2.499-4.085,5.28-3.36c0.325,0.076,0.458,0.342,0.96,0.24
				c1.29,0.55,2.444,1.236,3.6,1.92c0.134,0.346,0.375,0.586,0.72,0.72c0-0.16,0-0.319,0-0.479c0.783-1.113,0.157-2.871-1.68-2.16
				c-2.265-5.976-4.063-12.417-8.16-16.561c0.304,1.353-1.275,0.964-1.92,0.721c-3.351-1.609-6.279-3.642-9.6-5.28
				c-1.606-0.794-3.179-1.621-4.8-2.4c-0.204-0.355-0.604-0.516-1.2-0.479c-0.036-0.444-0.813-0.147-0.96-0.48
				c-4.411-2.148-9.232-3.888-13.68-6c-1.346,0.096-1.616-1.884,0-1.68c0,0.08,0,0.16,0,0.24c7.118,1.922,13.663,4.417,19.68,7.439
				c0.053,0.188,0.201,0.279,0.48,0.24c2.583,1.258,4.846,2.834,7.44,4.08c0.158,0.322,0.513,0.447,0.96,0.48
				c-4.137-7.579-8.149-19.873-11.28-30.24c-1.167-3.863-1.628-7.947-2.88-11.28c-0.741-1.972-2.379-3.629-2.88-5.521
				c-0.889-3.358,0.716-5.048,1.68-7.199c0.48,0,0.96,0,1.44,0c0.585-4.146-0.042-7.012,1.2-9.84c0.784-1.787,3.194-3.285,2.4-6.48
				c-0.684-0.356-1.799-0.28-2.16-0.96c-2.82-10.78-8.004-20.476-10.56-30.72c0.08,0,0.16,0,0.24,0
				c2.344,1.976,3.743,4.896,5.28,7.68c2.486,5.674,4.597,11.724,6.96,17.52c1.002-11.871,4.215-23.555,1.92-36
				c-3.796-1.724-7.802-3.237-11.76-4.8c-0.053-0.187-0.201-0.279-0.48-0.24c-5.809-0.3-11.076-1.609-16.32-3.12
				c-5.275-1.519-11.021-1.996-17.04-2.399c-27.343-1.833-47.38,9.222-58.8,24c-0.523,0.438,0.043,1.963-0.48,2.4
				c-4.299,3.621-6.392,9.447-9.36,14.399c2.144,6.116,2.252,11.923,3.84,17.521c0.802,2.829,1.825,5.375,2.88,7.92
				c2.122,5.116,5.792,9.572,5.52,16.8c6.505,3.575,20.939-0.779,21.84,8.4c-2.647-0.406-4.297-3.303-7.44-3.84
				c-5.32-0.911-11.095,0.4-14.88-2.4c0.118,4.486,2.613,7.7,4.56,11.04c2.254,3.865,3.686,8.756,5.52,13.2
				c3.824,9.267,6.844,18.326,10.08,27.6c2.024,5.8,3.075,12.875,5.52,17.28C129.228,593.98,130.808,597.441,132.72,601.641z"/>
					<path fill="none" d="M342.001,248.36c-1.598,6.482-3.378,12.782-4.08,20.16c0.563,0.715,1.097,1.463,1.92,1.92
				c0.807-0.633,1.526-1.354,2.16-2.16C341.668,261.467,342.581,254.622,342.001,248.36z"/>
					<path fill="none" d="M97.68,458.121c6.419-8.829,15.5-14.923,25.68-19.681c9.161-4.281,19.083-5.739,30.96-6.239
				c11.544-0.487,21.992,0.603,31.44,1.92c0.578-0.099,0.521,0.438,1.2,0.239c5.115,0.726,9.437,2.244,13.92,3.601
				c0.799,0.17,1.868,1.319,2.64,0.72c-0.302-0.817-1.583-0.657-1.92-1.44c-4.221-12.499-9.715-23.725-15.6-34.56
				c-0.204-1.156-0.995-1.725-1.2-2.88c-3.283-0.647-4.302-3.438-6.72-4.8c-6.024-3.393-17.643-5.854-25.92-6.24
				c-1.402-0.065-3.133,1.001-4.08-0.96c2.066-2.461,6.017-1.392,8.88-0.96c3.157,0.476,6.523,1.156,9.12,1.92
				c5.815,1.71,10.521,4.911,15.36,6.72c-1.853-3.157-6.566-4.307-8.4-7.68c-2.45-4.506-3.005-9.707-6-13.92
				c-2.57-3.617-7.963-8.209-13.68-8.88c-8.774-1.03-12.804,4.039-18.48,7.44c-8.11,4.86-14.809,9.323-22.32,14.16
				c-9.378,6.04-15.307,14.066-19.68,24.96c-4.347,10.829-5.962,23.743-4.32,37.681c2.217,3.383,4.372,6.827,7.2,9.6
				C95.747,457.947,96.828,458.148,97.68,458.121z"/>
					<path fill="none" d="M172.32,377c1.376,2.822,1.493,6.429,3.36,9.36c0.874,1.373,2.901,2.271,4.32,3.36
				c2.021,1.55,4.111,3.212,5.28,4.8c3.389,4.602,5.922,11.157,8.88,17.04c3.004,5.976,5.374,12.408,8.16,18
				c2.244,4.504,5.5,9.31,6.48,14.16c2.021,10.004-0.069,20.445-0.96,29.28c-0.376,3.731-1.444,7.59-1.68,11.28
				c-0.147,2.3,0.767,5.357,0,8.159c-0.819,2.992-4.365,5.627-2.88,10.561c5.7-8.551,6.479-22.177,7.44-35.04
				c0.328-4.386-0.214-8.986,1.2-12.96c4.725,0.523,10.619,0.24,16.8,0.24c4.061-0.001,8.19-0.469,12.24-0.721
				c8.658-0.538,17.515,0.257,24.96-0.72c5.663-0.743,11.294-4.068,17.52-3.6c0-1.36,0-2.721,0-4.08
				c-2.458-3.769-6.396-8.826-7.68-14.4c-0.707-3.07,0.472-7.642,0.72-11.28c0.205-3.008,1.257-9.142-0.96-9.36
				c-1.237-0.122-3.352,7.773-3.84,9.121c-1.33,3.668-2.464,6.646-4.08,9.359c-4.658-1.387-9.145-4.046-13.2-6.96
				c-4.023-2.891-8.227-5.567-12-8.64c-7.643-6.225-15.649-12.54-20.88-20.64c-2.671-4.136-5.163-8.376-8.4-12.24
				c-7.518-8.974-14.9-21.087-17.76-33.84c-0.629-2.803-0.169-5.939-1.68-8.88c-0.014-0.146-0.036-0.284-0.24-0.24
				c-2.344,1.435-3.894,4.194-6.48,5.28c-3.232,1.358-6.347,2.079-9.36,3.84c-8.467,4.951-17.595,9.28-25.2,14.88
				C163.18,362.691,168.818,369.822,172.32,377z"/>
					<path fill="none" d="M232.32,130.52c0,0.24,0,0.48,0,0.72c0.351,1.648-0.129,4.129,1.2,4.8c0.59,0.77,1.041,1.679,1.68,2.4
				c1.858,2.302,4.473,3.847,7.2,5.28c0.382,0.312,0.966,0.316,0.48-0.24c-0.733-0.227-0.752-1.167-1.68-1.2
				c-1.14-0.94-2.18-1.98-3.12-3.12c-1.454-2.146-3.101-4.099-4.32-6.48C232.941,132.299,233.011,131.029,232.32,130.52z"/>
					<path d="M489.601,611.961c-1.903-11.343-6.022-21.498-10.319-31.439c-6.225-14.4-13.077-28.273-19.921-42.961
				c-2.219-4.763-5.469-9.589-6.72-14.16c-0.512-1.869-0.281-3.945-0.96-5.76c-0.621-1.658-1.835-2.799-3.12-3.84
				c-1.172-0.949-2.945-1.446-4.08-2.64c-1.761-1.854-2.894-6.013-4.319-9.36c-0.675-1.582-1.488-3.184-2.16-4.8
				c-1.424-3.422-1.799-6.388-4.561-8.4c-1.263-0.92-2.532-0.865-3.359-1.92c-1.501-1.911-1.905-8.647-2.641-12.24
				c-4.317-21.112-8.104-40.766-16.56-57.359c-3.286-6.449-7.58-12.32-11.28-18.24c-1.685-2.695-6.131-4.841-9.36-7.2
				c-8.967-6.55-20.414-11.774-33.6-13.92c-12.164-6.796-23.384-14.537-34.32-22.56c-0.556-0.964-0.083-2.957-0.24-4.32
				c0.599-3.52,0.017-7.392-0.239-10.8c-0.521,0.041-0.109-0.851-0.24-1.2c-1.931-5.883-3.809-10.373-2.4-18.48
				c2.771-0.288,1.871-2.977,3.84-3.6c0.747-0.236,1.638,0.793,2.641,0.479c1.596-0.499,1.678-2.391,1.68-4.8
				c0.349-0.368,2.017,0.403,2.64-0.24c1.454-2.467,1.094-6.747,3.36-8.4c-0.036-0.596,0.124-0.996,0.48-1.2
				c1.348-3.11,3.47-5.627,3.84-9.6c0.437-4.688-1.569-9.973-1.2-15.12c0.109-1.521,0.473-3.143,0.72-4.32c0-0.56,0-1.12,0-1.68
				c0.241,0,0.286,0.194,0.48,0.24c10.257,3.356,5.981-13.785,7.2-21.84c-0.3-1.019,0.798-0.642,0.96-1.2
				c0.489-3.577,1.896-7.208,3.359-11.28c1.193-3.321,3.337-6.993,3.841-10.8c0.296-2.236-0.846-4.429-0.721-6.72
				c0.192-3.518,3.115-8.612,2.4-12.96c-0.546-3.317-5.026-6.946-8.641-4.56c-0.146,0.014-0.284,0.036-0.239,0.24
				c0.173,0.733-0.246,0.875-0.24,1.44c-0.56-3.281-0.081-7.6-0.24-11.28c0-0.32,0-0.64,0-0.96c-0.08,0-0.16,0-0.24,0
				c0.009-0.871-0.134-1.895,0.48-2.16c-0.185-0.904,0.293-1.147,0.24-1.92c2.109-5.549,0.585-11.371,1.439-17.76
				c0.488-3.65,2.227-7.701,2.88-12c0.681-4.473,1.214-9.342,0.96-13.92c-0.8-14.446-8.183-21.632-13.68-32.16
				c-0.322-0.158-0.447-0.513-0.479-0.96c-0.833-0.928-1.346-2.175-1.921-3.36c-4.802-9.678-12.005-16.955-21.119-22.32
				c-1.948-1.332-4.175-2.386-6.721-3.12c-2.134,0.054-3.454-0.706-5.279-0.96c-2.014-0.467-3.81-1.15-5.521-1.92
				c-5.52-1.68-12.552-1.848-18.96-2.64c-2,0-4,0-6,0c-11.758,2.067-27.819,0.945-38.4,4.56c-4.382,1.497-7.702,2.224-11.04,5.04
				c-3.02,2.547-5.407,5.511-5.76,10.08c-0.886,2.074-2.174,3.746-4.8,4.08c-6.5,6.94-15.063,11.817-20.64,19.68
				c-0.235,1.525-0.69,2.831-1.2,4.08c-0.724,2.716-1.329,5.551-1.92,8.4c-1.681,21.456-1.837,46.941-1.44,68.88
				c-10.227,0.745-11.69,8.342-12.72,18.48c-0.459,4.525-1.88,9.298-0.96,13.68c1.622,7.718,6.092,15.243,9.84,22.8
				c3.872,7.806,7.934,15.447,12.24,20.16c1.164,0.597,3.107,0.413,4.08,1.2c1.454,8,2.278,16.258,3.36,24.96
				c0.354,2.849,0.964,6.001,0.72,8.64c-0.322,3.479-2.228,7.119-3.36,10.32c-1.23,3.476-2.682,6.623-3.6,9.84
				c-4.031,1.755-6.304,6.643-10.08,8.64c-1.642,0.869-3.816,1.149-5.52,1.92c-4.67,2.113-9.679,5.052-14.4,7.68
				c-3.106,1.729-6.311,3.65-9.36,5.52c-3.052,1.871-5.92,4.413-8.88,5.76c-1.458,0.664-2.984,0.792-4.32,1.68
				c-6.064,4.032-11.562,8.443-18,11.76c-0.053,0.187-0.201,0.279-0.48,0.24c-0.481,0.719-1.389,1.011-2.16,1.44
				c-0.868,0.333-1.345,1.056-2.4,1.2c-10.607,6.193-20.118,12.149-25.92,23.04c-5.533,10.386-9.954,22.638-10.32,38.4
				c0,2.319,0,4.64,0,6.96c0.789,5.466,0.43,12.006,1.2,17.76c0.132,0.987,0.759,2.097,0.96,3.12c0.803,4.09-0.028,8.229,2.64,9.359
				c1.662,11.699,3.723,22.998,8.64,31.44c-1.256,3.274-0.332,8.229,0,12.72c1.141,15.432,0.521,32.258,2.16,47.761
				c3.176,30.055,7.927,58.548,14.88,84.96c-1.012,5.812-1.528,12.191,0.72,17.28c0.968,2.189,3.357,3.989,4.08,5.76
				c1.141,2.797,1.079,6.013,1.68,8.64c2.254,9.854,7.456,19.022,14.4,24.721c-1.156,3.262,2.685,7.192,2.16,9.6
				c-0.304,1.394-3.466,2.582-4.08,4.561c-1.16,3.736,0.843,9.229,1.68,13.199c0.676,3.208,1.211,6.218,2.16,9.36
				c0.229,0.76,1.479,1.468,0.24,2.16c-0.08,0-0.16,0-0.24,0c0,0.08,0,0.16,0,0.24c-0.627,0.861-0.009,2.137,0.24,2.88
				c0.424,0.456,1.191,0.568,1.68,0.96c3.28,0,6.56,0,9.84,0c4.56,0,9.12,0,13.68,0c0.8,0,1.6,0,2.4,0
				c3.601-0.56,8.24-0.081,12.24-0.24c1.92,0,3.84,0,5.76,0c0.88,0,1.76,0,2.64,0c1.36,0,2.72,0,4.08,0c0.08,0,0.16,0,0.24,0
				c0.333-0.547,1.671-0.09,2.4-0.24c1.68,0,3.36,0,5.04,0c2.48,0,4.96,0,7.44,0c0.726-0.554,2.475-0.084,3.6-0.24
				c57.696-0.144,115.979,0.3,173.281-0.239c0.053-0.188,0.201-0.279,0.479-0.24c1.399-3.814,2.234-9.374,3.12-14.4
				c0.295-0.985,0.033-2.526,0.48-3.359c0-0.48,0-0.96,0-1.44c0.389-0.892,0.199-2.36,0.239-3.6c0-0.96,0-1.92,0-2.88
				c-0.067-1.612-0.966-2.395-1.92-3.12c1.845-1.592,4.353-2.121,4.561-4.561c0.252-2.956-3.364-4.545-2.641-8.16
				c-0.384-3.136-0.349-6.69-0.479-10.08c-0.366-2.755-0.99-6.527-1.2-8.64c21.769-2.764,40.957-6.807,58.32-13.44
				c17.332-6.621,32.366-15.818,48-23.76c1.248-0.271,1.955-1.085,2.4-2.16c0-0.479,0-0.96,0-1.439
				C492.563,636.154,491.563,623.657,489.601,611.961z M388.32,395c2.288,1.702,4.792,3.138,6.721,4.8
				c3.826,3.298,6.491,8.689,9.359,13.2c3.052,4.8,5.664,9.884,7.2,15.121c-4.583,0.456-9.861,0.219-14.4,0.72
				c-7.474,0.606-15.099,1.061-21.84,2.399c-0.935-17.224-5.48-30.839-11.52-42.959c-0.655-1.907-2.518-3.88-2.88-5.28
				C371.645,384.922,380.798,389.407,388.32,395z M321.36,367.641c-0.075-3.036,0.5-5.42,0.48-8.4
				c10.848,6.592,19.808,15.072,31.68,20.64c0.333,1.826,1.868,2.451,2.64,3.84c1.965,1.715,3.22,4.141,4.32,6.72
				c0.233,0.007,0.187,0.294,0.24,0.48c3.349,7.58,7.213,15.637,9.359,24.72c1.108,4.688,2.785,9.043,2.4,15.12
				c-0.115,1.822-0.321,2.842,0,4.56c0.497,2.66-0.433,7.302,0.24,9.36c0.276,0.846,1.584,1.467,2.16,2.16
				c3.991,4.809,8.248,16.515,8.88,24.72c0.228,2.954-0.171,6.454,0,9.601c-2.029-13.491-8.724-22.317-13.44-33.12
				c-4.966-0.086-9.561,0.199-14.16,0.479c-0.08,0-0.159,0-0.239,0c-1.889,0.271-3.921,0.399-6,0.48
				c0.576-8.243-6.567-13.545-11.04-6.48c-1.081-2.046-2.145-4.111-4.561-4.319c-8.766-0.758-5.614,15.402-6.72,23.279
				c0,0.08,0,0.16,0,0.24c-0.558,1.522-0.082,4.078-0.24,6c0,0.08,0,0.16,0,0.24c-0.547,0.333-0.089,1.67-0.24,2.399
				c-0.201,0.599-0.313,1.286-0.239,2.16c-2,0-4,0-6,0c-0.414-0.397-2.467-0.397-2.881,0c-0.751,0.289-1.601,0.479-2.399,0.721
				c-0.962,0.559-1.473,1.567-1.44,3.119c0,0.721,0,1.44,0,2.16c0.15,0.729-0.307,2.067,0.24,2.4c0.178,1.423,0.491,2.709,0.48,4.32
				c-0.345-0.296-0.455-0.826-0.48-1.44c-0.133-0.428-0.123-0.997-0.479-1.2c-0.4,1.604-0.4,4.877,0,6.48
				c-0.064,2.544,0.992,3.968,0.72,6.72c-0.04,1.72-1.131,2.389-0.96,4.32c-0.029,1.57-0.153,3.047-0.48,4.319
				c-0.551,0.489-0.087,1.993-0.239,2.881c-0.012,3.188-0.885,5.515-0.721,8.88c0.152,0.808-0.309,2.229,0.24,2.64
				c0.426,0.454,0.175,1.585,0.24,2.4c0.154,0.966-0.313,2.552,0.24,3.12c0,0.08,0,0.159,0,0.239
				c0.262,8.059-0.56,17.199,0.479,24.48c0.444,1.555,1.192,2.808,1.92,4.08c1.903,2.657,4.406,4.714,6.72,6.96
				c0,0.08,0,0.16,0,0.24c1.021,1.22,2.141,2.34,3.36,3.359c0,0.08,0,0.16,0,0.24c1.5,1.7,3.1,3.3,4.8,4.8c0.08,0,0.16,0,0.24,0
				c1.18,1.381,2.46,2.66,3.84,3.84c0.08,0,0.16,0,0.24,0c1.82,2.021,3.74,3.94,5.76,5.761c1.071,1.408,2.555,2.405,3.36,4.08
				c0.95,1.896,3.847,0.658,4.08-0.96c0.779,0.139,1.116-0.164,1.68-0.24c1.141-0.94,2.181-1.979,3.12-3.12
				c0.977-0.463,1.226-1.654,2.4-1.92c1.46-1.26,2.819-2.62,4.08-4.08c0-0.08,0-0.16,0-0.24c2.549-2.331,4.541-5.218,6.72-7.92
				c2.052-3.309,4.455-6.265,5.76-10.32c0.568-0.631,0.79-1.609,0.96-2.64c0.367-2.353,1.372-4.068,1.68-6.48
				c0.553-0.567,0.086-2.153,0.24-3.119c0-0.08,0-0.16,0-0.24c0.555-2.966,0.375-6.666,0.72-9.84
				c1.367,3.993,3.251,10.102,2.4,14.88c-0.175,0.982-0.924,1.723-1.2,2.64c-0.759,2.522-0.895,4.957-1.92,7.44
				c-2.44,5.914-9.646,15.934-6.72,23.04c-7.198-0.158-13.498,0.581-18,3.12c-0.703,0.097-0.192,1.408-0.72,1.68
				c-2.934,1.77-5.857,3.766-9.841,4.8c-5.168,1.343-11.597,1.357-13.68,5.76c-0.953,2.015-0.077,5.337-2.88,6.48
				c-0.008-10.511-3.261-18.556-7.2-25.44c-1.119-1.956-2.93-3.572-3.84-5.52c-0.359-0.77-0.309-1.878-0.72-2.88
				c-0.719-1.753-2.439-3.135-2.641-4.561c-0.227-1.606,0.812-3.746,1.2-6c0.996-5.773,2.045-13.186,1.44-19.68
				c-0.463-4.968-3.889-9.907-4.32-15.84c-0.66-9.068,0.491-18.061-0.48-26.4c-0.864-7.431-2.574-14.664-6-20.64
				c-1.188-2.072-3.73-3.907-3.359-7.44c0.099-0.945,1.057-1.727,1.2-2.4c0.862-4.05-1.569-6.872-2.16-9.359
				c8.848-0.331,20.29,0.014,29.52-0.72c2.255,0.654,1.994-1.206,2.16-2.641c-9.75,0.41-22.736-0.337-32.4,0.96
				c-0.84-2.6-1.967-4.913-2.88-7.439c1.593-3.479-0.339-7.605,1.2-11.04c0.438-0.976,2.241-1.783,3.36-2.641
				c5.252-4.024,10.601-10.282,13.2-16.8c1.301-3.263,2.65-6.859,3.359-11.28c4.838-9.569,7.475-21.735,7.92-35.28
				C321.13,367.843,321.135,367.692,321.36,367.641z M378.48,496.041c-0.371-0.349-0.211-1.229-0.24-1.92
				c-0.471-3.689-1.23-7.09-1.68-10.8c-0.46-1.62-0.756-3.404-1.2-5.04c-1.718-3.242-1.173-8.747-6.72-8.16
				c-1.203-0.557-3.438-0.083-5.04-0.24c0,0.08,0,0.16,0,0.24c-0.24,0-0.48,0-0.72,0c-0.16,0-0.32,0-0.48,0
				c-0.573,0.146-1.741-0.302-1.92,0.239c-2.94,0.274-7.721,1.857-9.84,0c-1.845-0.45-0.668-5.807-0.48-10.079
				c0.122-2.762,0.137-5.704,0.24-8.16c0-0.08,0-0.16,0-0.24c2.094-0.066,3.769-0.552,5.76-0.72c2.896-1.744,8.247,0.297,12-0.721
				c5.678,13.203,13.441,24.318,12.48,44.16c0.62,2.282-0.028,5.996-2.16,6.96C378.426,499.822,379.162,497.607,378.48,496.041z
				 M371.761,528.441c0,0.239,0,0.479,0,0.72c-0.157,1.363,0.315,3.355-0.24,4.319c0,0.32,0,0.641,0,0.961c0,0.88,0,1.76,0,2.64
				c-0.08,0-0.16,0-0.24,0c0,0.399,0,0.8,0,1.2c0,0.319,0,0.64,0,0.96c-0.258,1.307-0.099,3.389,0.24,4.8
				c1.23,1.777-1.021,2.156-1.68,3.36c-1.786,3.265-1.893,9.061-4.561,11.279c-1.447,1.353-2.27,3.331-3.6,4.8
				c-0.808,1.593-2.095,2.706-3.12,4.08c-1.426,1.215-2.535,2.746-3.84,4.08c-0.171,0.789-0.911,1.01-1.44,1.44
				c-2.413,1.835-4.655,5.093-6.72,6.479c-0.859-1.46-2.351-2.289-3.12-3.84c-1.979-2.18-4.06-4.26-6.24-6.239
				c-0.194-0.046-0.239-0.241-0.479-0.24c-0.699-0.9-1.5-1.701-2.4-2.4c-0.08,0-0.16,0-0.24,0c-0.779-0.98-1.659-1.86-2.64-2.64
				c-0.08,0-0.16,0-0.24,0c-1.659-1.86-3.42-3.62-5.279-5.28c0-0.08,0-0.16,0-0.24c-2.627-3.133-6.01-5.511-8.641-8.64
				c-0.138-0.502-0.595-0.686-0.479-1.44c-0.221-11.985-0.451-25.085-0.721-36.239c0.036-1.244,0.175-2.386,0.48-3.36
				c0.549-0.411,0.088-1.832,0.24-2.64c0-0.08,0-0.16,0-0.24c0.373-1.147,0.381-2.659,0.479-4.08c-0.057-1.018,0.221-1.699,0.48-2.4
				c1.256-2.583,0.275-7.404,1.2-10.319c0.38-0.021,0.915,0.115,0.96-0.24c1.188-0.173,2.39-0.33,3.84-0.24
				c0.808-0.152,2.229,0.31,2.64-0.24c0.32,0,0.64,0,0.96,0c1.204-0.155,3.035,0.314,3.84-0.239c0.4-0.001,0.801,0,1.2,0
				c0.08,0,0.16,0,0.24,0c0.547,0.332,0.09,1.67,0.24,2.399c0,0.32,0,0.64,0,0.96c-0.4,1.843-0.4,5.357,0,7.2
				c0.479,2.926,6.583,5.288,6.72,0.479c0.549-0.41,0.088-1.832,0.24-2.64c0-0.08,0-0.16,0-0.24c0.964-2.315-0.777-7.337,0.96-8.88
				c0.08,0,0.16,0,0.24,0c0.158,2.002-0.318,4.638,0.239,6.24c1.896,3.696,6.959,1.229,6.24-3.12c0.551-0.489,0.087-1.993,0.24-2.88
				c0-0.08,0-0.16,0-0.24c0.393,0.263,1.649,0.889,2.16,0.24c3.398-1.161,8.161-0.959,12.72-0.96c1.303,0.183,1.859-0.38,3.12-0.24
				c2.415-0.064,3.81-1.15,5.76-1.68c1.007-0.145,1.759-2.035,0.48-2.4c-3.042,0.078-4.433,1.808-7.681,1.681
				c-0.332-0.548-1.67-0.09-2.399-0.24c-0.88,0-1.761,0-2.641,0c-0.08,0-0.159,0-0.239,0c-3.173,0.188-6.308,0.412-9.12,0.96
				c-0.899-0.243-1.544,0.995-1.92,0.479c-0.134-4.054,0.422-7.417,0.239-11.52c0.315,0.085,0.378,0.423,0.721,0.479
				c0.388-0.067,0.413,0.228,0.72,0.24c0.492,0.398,2.628,0.398,3.12,0c1.343-0.177,2.591-0.448,4.08-0.479
				c0.563-0.077,0.9-0.38,1.68-0.24c0.598-0.202,1.286-0.314,2.16-0.24c0.489-0.551,1.993-0.087,2.88-0.24c0.24,0,0.479,0,0.72,0
				c1.204-0.155,3.035,0.314,3.84-0.239c0.641,0,1.28,0,1.92,0c1.04,2.56,2.07,5.13,2.641,8.159c0.412,0.788,0.635,1.766,0.72,2.881
				c0.856,4.264,1.7,8.539,1.92,13.439c-0.154,1.045,0.313,2.713-0.24,3.36c-0.871,8.329-2.323,16.076-3.119,24.479
				C371.848,526.448,372.312,527.951,371.761,528.441z M330.24,468.44c0-0.159,0-0.319,0-0.479c0.554-0.647,0.086-2.314,0.24-3.36
				c0-0.16,0-0.319,0-0.479c0.556-0.965,0.083-2.957,0.24-4.32c0-0.24,0-0.48,0-0.72c0.552-0.568,0.086-2.154,0.24-3.12
				c0-0.08,0-0.16,0-0.24c0.556-1.123,0.082-3.277,0.239-4.8c0-0.24,0-0.48,0-0.72c0.548-0.333,0.09-1.671,0.24-2.4
				c-0.017-2.818,0.313-6.565,2.88-6c0.76,0.52,1.393,1.168,1.681,2.16c1,2.279,1.427,5.132,1.439,8.399
				c0.556,1.044,0.083,3.117,0.24,4.561c0,0.16,0,0.32,0,0.479c0,2.08,0,4.16,0,6.24c-0.558,1.363-0.082,3.758-0.24,5.521
				c0,0.239,0,0.479,0,0.72c-0.813-0.067-1.378-0.382-2.4-0.24c-0.159-3.28,0.319-7.199-0.239-10.08
				c-0.67-2.118-2.763-0.319-2.4,1.44c0,2.88,0,5.76,0,8.64c-0.291,0.429-0.044,1.396-0.479,1.68
				c-0.679,0.199-0.622-0.338-1.2-0.239C329.941,470.82,330.325,469.396,330.24,468.44z M337.2,474.201
				c-0.56,5.6-0.08,12.239-0.239,18.239c0,0.48,0,0.96,0,1.44c-0.558,1.203-0.083,3.438-0.24,5.04c0,0.16,0,0.32,0,0.479
				c-0.794-0.246-1.415-0.664-1.44-1.68c0-1.12,0-2.24,0-3.359c0-0.721,0-1.44,0-2.16c0.4-2.162,0.4-5.998,0-8.16
				c0-0.8,0-1.601,0-2.4c-0.157-1.762,0.317-4.157-0.24-5.52c0-1.04,0-2.08,0-3.12c0.96,0.079,1.333-0.428,2.4-0.24
				C337.446,473.326,337.027,473.468,337.2,474.201z M331.921,474.921c1.123-0.243-0.043,1.803,0.72,1.92c0,0.24,0,0.479,0,0.72
				c0.159,2.642-0.318,5.919,0.24,8.16c0,0.08,0,0.16,0,0.24c-2.11,0.13-4.383,0.098-6.24,0.479c-0.08,0-0.16,0-0.24,0
				c-0.966,0.154-2.552-0.312-3.12,0.24c-0.08,0-0.16,0-0.24,0c-0.808,0.152-2.229-0.309-2.64,0.24c-0.08,0-0.16,0-0.24,0
				c-3.124,0.164-0.441-5.479-1.68-7.2c0-0.16,0-0.32,0-0.479c0.132-1.068-0.372-2.772,0.48-3.12
				C323.161,474.677,327.955,475.907,331.921,474.921z M346.801,450.201c0,0.319,0,0.64,0,0.96c0,1.68,0,3.359,0,5.04
				c0,0.239,0,0.479,0,0.72c0.096,2.016-0.08,3.76-0.24,5.52c-0.595,0.196-3.773-0.552-4.32,1.44c1.169,1.646-0.615,4.008,0,7.2
				c-2.116,0.203-1.42-2.39-1.439-3.601c-0.106-6.63,0.421-14.93-0.721-20.64c0.623-3.282,5.805-7.968,6.24-1.44
				c0.371,0.35,0.212,1.229,0.24,1.92c0,0.08,0,0.16,0,0.24C347.109,447.972,346.648,449.393,346.801,450.201z M343.921,465.561
				c0.527-0.512,1.798-0.281,2.159-0.96c0.205-0.044,0.227,0.094,0.24,0.24c-2.077,8.082-0.199,20.12-2.16,28.32
				C342.84,485.241,346.257,473.288,343.921,465.561z M342.001,476.36c-0.556,0.886-0.084,2.797-0.24,4.08c0,0.24,0,0.48,0,0.721
				c-0.552,0.567-0.086,2.153-0.24,3.12c0,0.479,0,0.96,0,1.439c-0.194-0.046-0.239-0.24-0.479-0.24
				c-1.386-0.92-0.632-8.577-0.48-11.04c0.758-0.117,0.848,0.433,1.68,0.24C342.164,475.244,341.861,475.582,342.001,476.36z
				 M425.761,568.041c-0.601,1.849,0.474,3.754,0.72,5.52c0.812,0.789,1.783,1.417,2.641,2.16c-1.284,4.396-3.635,7.726-3.601,13.44
				c-0.169,7.09,5.098,13.637,4.08,19.92c-0.412,2.546-2.329,6.163-3.84,8.16c-1.42,1.62-2.939,3.14-4.56,4.56
				c-2.781,4.603-9.485,4.987-15.841,6.721c-3.939,1.074-5.787,3.17-8.399,4.8c-0.711-18.569-3.676-34.885-12-45.84
				c-0.98-0.78-1.86-1.66-2.641-2.641c-3.898-2.581-7.914-5.046-14.16-5.28c0.728-0.393,2.053-0.188,2.4-0.96
				c0.065-8.33,4.999-14.854,7.68-21.6c0.978-2.459,0.805-5.454,1.92-7.68c1.25-2.493,6.496-4.183,9.12-5.04
				c3.906-1.277,7.512-1.971,11.04-3.12c1.203-0.547,2.753-0.054,4.08,0c4.957,8.483,8.928,17.952,22.32,18
				C427.056,561.925,425.42,564.502,425.761,568.041z M369.12,655.881c0-12.503-1.923-24.729-3.84-35.279
				c-2.579-14.196-6.62-28.794-22.8-29.28c3.228-1.732,7.255-2.666,8.88-6c4.116-1.768,9.823-3.079,15.36-2.641
				c1.737-0.052,4.885,0.154,6.72,0.721c14.669,4.521,19.268,27.399,20.16,47.04c0.279,6.144-0.05,13.09-1.92,17.279
				c-0.87,2.81-2.15,5.21-3.36,7.681c-8.639,2.16-13.228,8.372-19.92,12.479C369.575,664.209,369.12,659.953,369.12,655.881z
				 M343.2,594.921c11.342,0.979,14.265,10.376,16.561,20.4c-1.547-2.293-3.271-4.408-4.8-6.72c-1.326-1.635-2.679-3.242-4.32-4.561
				c-2.542-2.737-5.343-5.217-8.88-6.96C342.407,596.527,343.43,596.351,343.2,594.921z M312.961,592.041
				c-1.067-4.397-3.541-7.631-5.28-11.28c-1.756-3.683-3.108-7.21-5.28-10.56c-2.046-3.156-4.445-6.006-6.24-9.36
				c1.977-2.658,4.541-4.657,5.04-9.12c0.347-3.098-0.435-6.574-0.96-10.08c-1.434-9.566-2.942-18.822-3.6-29.04
				c-0.914-14.19-0.771-28.884-6.96-36.72c1.379-2.3,0.901-6.308,0.479-9.36c1.12,0,2.24,0,3.36,0
				c-0.601,4.605,2.063,6.736,3.6,9.36c4.193,7.159,5.659,15.66,6.24,24.48c0.61,9.258-1.633,19.506,1.2,28.8
				c1.001,3.284,2.759,5.955,3.12,8.399c0.659,4.463-0.338,9.203-0.96,13.44c-0.629,4.283-2.48,8.928-1.92,12.72
				c0.458,3.105,3.882,5.011,3.6,8.16c3.447,2.818,6.262,8.6,8.4,14.16c1.957,5.089,3.354,10.555,3.12,16.561
				c-2.042,1.158-4.041,2.358-6.24,3.359C313.746,600.506,313.97,596.203,312.961,592.041z M260.88,609.801c0-0.08,0-0.16,0-0.239
				c0.397-0.337,0.397-2.305,0-2.641c-0.199-0.601-0.658-0.942-0.72-1.68c-0.779-0.98-1.659-1.86-2.64-2.64c-0.08,0-0.16,0-0.24,0
				c-0.47-0.522,1.242-1.858,0.24-2.881c-0.575-1.584-1.875-2.445-3.12-3.359c-0.78-0.98-1.66-1.86-2.64-2.641
				c-0.08,0-0.16,0-0.24,0c-1.374-1.746-3.219-3.021-4.8-4.56c-4.51-4.051-6.91-10.21-9.36-16.32
				c-0.523-1.077-1.323-1.877-2.4-2.399c-3.595-1.141-5.455,1.385-6,4.08c-1.786-3.844-2.586-7.245-4.8-10.801
				c-1.919-3.083-5.209-5.335-6.96-8.399c-1.604-2.808-2.61-6.487-3.84-9.841c-4.02-10.958-6.329-24.694-12-34.319
				c0.574-0.706,1.147-1.412,1.2-2.641c11.579-8.821,9.969-30.831,11.28-49.92c13.549,0.567,26.645-0.852,39.6-1.439
				c3.919-0.179,7.847,0.262,11.76,0c6.279-0.422,11.881-3.867,18.24-3.601c0.482,5.118-1.925,13.125,3.84,12.96
				c1.751,2.786-0.811,6.715-0.48,9.84c0.17,1.604,1.585,2.732,2.4,4.32c4.214,8.207,4.036,23.356,4.8,34.561
				c0.633,9.276,2.13,17.22,3.36,26.16c0.67,4.869,1.755,9.813-0.479,13.439c-0.87,1.411-3.779,2.926-4.08,5.04
				c-0.308,2.158,1.126,3.622,2.159,5.521c-1.936,2.674-4.316,5.071-7.2,6.96c-2.351,1.539-5.612,2.03-7.44,4.319
				c2.024,1.122,4.441-0.726,6.72-1.439c4.298-1.348,8.941-2.028,10.8-5.76c2.515,3.805,4.954,7.686,6.96,12
				c-4.972,2.028-11.263,3.098-17.28,4.319c-1.941,0.395-3.501,1.238-5.28,1.681c-1.108,0.274-3.123-0.499-3.6,1.199
				c8.821,1.051,19.16-2.544,27.359-4.56c3.716,5.582,6.112,14.158,4.561,23.52c-0.646,1.035-1.903,1.457-3.12,1.921
				c-0.757,0.523-1.907,0.652-2.4,1.439c-4.54,2.26-9.041,4.559-15.6,4.8c-0.887,0.153-2.391-0.311-2.88,0.24c-0.4,0-0.8,0-1.2,0
				c-0.808,0.152-2.229-0.31-2.64,0.24c-3.649,0.937-6.927,0.473-10.08,0.96c-3.945,0.609-6.627,3.972-10.08,5.04
				c-0.755-3.446-3.807-4.144-1.92-7.92C261.196,613.317,260.723,611.244,260.88,609.801z M256.8,626.361
				c-0.324-0.716-0.818-1.262-1.44-1.681c-2.362-0.201-3.739,0.581-5.76,0.721c-0.447,0.318-2.238,1.015-2.16-0.24
				c-0.08,0-0.16,0-0.24,0c-1.718-4.682-7.498-5.302-10.8-8.4c-0.9-0.699-1.701-1.499-2.4-2.399c-2.818-3.982-6.335-8.545-6-14.88
				c3.718,0.053,5.713,3.878,8.64,5.52c0.543,0.257,1.056,0.545,1.44,0.96c6.901,4.779,15.092,8.269,20.4,14.641
				c0.204-0.045,0.226,0.093,0.24,0.239c0.673,0.448,0.926,1.314,1.2,2.16c0.656,2.18-2.366,2.752-2.88,4.561
				C256.52,627.602,256.932,626.71,256.8,626.361z M243.36,598.281c0.713,0.487,1.658,0.742,2.16,1.439
				c2.039,0.922,3.866,2.055,5.76,3.12c1.966,0.835,2.844,2.756,4.32,4.08c1.459,1.597,1.158,4.471,0.96,7.2
				c-6.161-4.372-13.847-8.067-20.4-13.2c-2.813-2.202-5.23-5.896-9.84-4.08c-2.235-1.136-3.825-4.076-4.8-6.96
				c-0.938-2.771-2.219-6.441-0.96-9.12c3.171,0.189,4.743,1.978,6.48,3.601c0.49,0.07,0.471,0.649,0.96,0.72
				c0.423,0.217,0.743,0.537,0.96,0.96C233.579,590.303,238.132,594.63,243.36,598.281z M233.041,573.321
				c1.131,0.309,1.601,1.278,1.92,2.399c1.52,3.121,2.8,6.48,4.32,9.601c0.722,0.878,1.358,1.842,1.92,2.88
				c0.968,0.952,1.727,2.113,2.64,3.12c0.423,0.217,0.743,0.536,0.96,0.96c1.661,2.18,4.699,4.26,5.76,6.24
				c-0.306-0.335-1.176-0.104-1.2-0.721c-1.052-0.468-2.145-0.895-2.88-1.68c-1.141-0.459-2-1.201-2.88-1.92
				c-4.55-3.29-8.784-6.896-12.72-10.8C230.67,579.111,231.517,575.878,233.041,573.321z M217.2,378.92c0-2.4,0-4.8,0-7.2
				c-0.204,0.044-0.227-0.094-0.24-0.24c0-3.6,0-7.2,0-10.8c-0.204,0.044-0.227-0.094-0.24-0.24c0-1.04,0-2.08,0-3.12
				c-0.556-0.963-0.239-2.8-0.72-3.84c0-1.44,0-2.88,0-4.32c-0.044-0.204,0.093-0.227,0.24-0.24c0-0.88,0-1.76,0-2.64
				c0.517-0.203-0.09-1.53,0.48-1.68c0.388-0.339,2.135,1.7,2.88,2.64c0.962,1.213,1.878,2.702,2.4,3.84
				c4.324,3.436,8.613,6.906,12,11.28c1.22,1.02,2.34,2.14,3.36,3.36c0.934,0.506,1.784,1.096,3.12,1.2
				c0.275-0.995-0.505-0.935-0.48-1.68c-0.423-0.618-1.044-1.037-1.44-1.68c-0.901-0.699-1.701-1.5-2.4-2.4
				c-5.213-5.586-10.113-11.487-14.4-18c2.491,0.147,4.948,0.058,6.96,0.96c2.168,0.972,3.143,4.707,6.24,3.36
				c0.239-1.992-1.345-3.254-2.16-4.8c-2.315-4.391-3.43-9.671-5.28-14.88c-0.021-0.859-0.374-1.387-0.48-2.16
				c0.292,0.508,0.903,0.697,0.96,1.44c1.259,1.621,2.779,2.98,3.84,4.8c0.98,0.78,1.86,1.66,2.64,2.64
				c15.215,11.185,32.24,20.56,52.32,26.88c-0.229,1.051-0.25,2.31-0.48,3.36c-5.578,19.382-10.886,39.034-18,56.88
				c-0.613,1.627-1.318,3.161-1.92,4.8c-12.756-6.283-22.903-15.176-33.36-23.76c-0.98-0.78-1.861-1.66-2.64-2.64
				c-5.551-4.609-8.609-11.711-12.96-17.52C217.648,381.712,217.514,379.924,217.2,378.92z M316.561,320.84
				c-1.664,6.952-1.029,14.057-3.36,18.96c-1.111,2.339-3.636,4.448-5.279,6.72c-1.782,2.462-3.39,5.132-5.28,6.96
				c-2.173,3.675-6.721,4.979-9.601,7.92c-2.515,2.569-3.515,6.106-5.52,9.36c0.586-2.695,1.392-7.248,2.399-10.32
				c-2.063-2.657-6.761-2.678-9.839-4.32c3.795-2.199,8.598-3.624,12.72-6c3.657-2.108,8.645-4.924,10.8-7.92
				c1.134-1.577,0.433-3.961,2.88-4.56c2.787-0.741,4.037-5.486,5.52-8.64c1.433-3.044,2.797-5.785,3.841-8.16
				c0.199-0.601,0.657-0.942,0.72-1.68C317.286,319.524,316.361,320.214,316.561,320.84z M289.44,457.641
				c0.3-2.781-1.091-5.146-0.48-7.439c0.16,0,0.32,0,0.48,0c0.32,0,0.64,0,0.96,0c0.738,0.781,0.414,2.625,0.48,4.08
				c0.016,1.424,0.624,2.255,0.72,3.6C290.672,458.009,290.429,457.452,289.44,457.641z M292.32,460.281c0.64,0,1.28,0,1.92,0
				c-0.069,1.27,0.621,1.778,0.48,3.119c-1.217-0.016-1.988,0.413-3.36,0.24c-1.904-0.096-3.818-0.182-5.04-0.96
				c0-1.12,0-2.24,0-3.36C287.502,460.459,290.614,459.667,292.32,460.281z M279.121,398.841c0.819-2.441,2.077-8.006,3.36-8.4
				c0,2.96,0,5.92,0,8.88c0.044,0.204-0.094,0.226-0.24,0.24c0,3.6,0,7.2,0,10.8c-1.806-0.116-2.894-3.025-6-2.64
				C276.888,405.199,277.939,402.36,279.121,398.841z M279.84,413.48c0.467,0.493,1.546,0.375,2.16,0.72
				c-0.802,4.062-1.083,9.456,0,13.44c-0.825,0.775-2.904,0.296-3.12,1.68C278.916,424.716,279.63,418.711,279.84,413.48z
				 M288.96,429.32c1.089-0.129,1.438,0.482,2.16,0.721c-0.629,4.811-0.36,10.52-1.68,14.64c-0.72,0-1.44,0-2.16,0
				c0-0.08,0-0.16,0-0.24c-1.978,0.192-2.882-4.782-3.6-6.72c-1.12-3.021-2.194-5.29-2.64-7.44
				C283.513,429.793,286.281,429.602,288.96,429.32z M318.48,355.4c0.938,11.161-1.669,22.764-2.16,34.08
				c-1.923,2.158-1.787,6.373-3.359,8.88c-3.589,7.932-9.295,13.745-14.4,20.16c0.775-3.306,3.188-7.052,3.6-11.52
				c0-1.76,0-3.52,0-5.28c-0.044-0.204,0.094-0.227,0.24-0.24c0-4.72,0-9.44,0-14.16c-0.204,0.044-0.227-0.094-0.24-0.24
				c0-1.36,0-2.72,0-4.08c-1.46-5.343-0.637-13.786-2.88-18.24c-0.527-1.047-1.559-2.411-3.12-2.16c1.958-1.563,3.938-3.103,6-4.56
				c0.7-0.9,1.5-1.701,2.4-2.4c1.073-1.727,2.324-3.276,3.6-4.8c3.102-5.378,8.517-8.443,8.641-16.8
				C319.299,339.311,320.268,348.608,318.48,355.4z M342.001,268.28c-0.634,0.807-1.354,1.527-2.16,2.16
				c-0.823-0.457-1.356-1.205-1.92-1.92c0.702-7.378,2.482-13.678,4.08-20.16C342.581,254.622,341.668,261.467,342.001,268.28z
				 M341.761,205.64c0.034-2.446,0.53-4.429,0.96-6.48c0.708,0.492-0.228,2.628,0.479,3.12c0,0.48,0,0.96,0,1.44
				C343.921,206.35,342.149,210.264,341.761,205.64z M346.561,237.8c0.287-4.514,2.031-9.648,4.08-13.2
				C350.195,229.916,347.658,233.138,346.561,237.8z M350.16,205.4c3.842,2.113,0.942,10.4-0.72,14.16
				c-1.594,3.606-2.972,7.236-3.84,10.32c-0.553-0.647-0.085-2.315-0.24-3.36c-0.541-3.699-1.529-6.951-2.88-9.84
				C344.106,213.522,345.587,202.772,350.16,205.4z M181.44,243.8c-3.54-6.868,0.938-14.406,1.2-21.84
				c1.905,0.777,0.331,1.551,0.72,3.12c0.744,2.998,5.272,4.698,5.04,8.88C186.108,237.268,183.455,240.215,181.44,243.8z
				 M202.8,251.48c-0.046-0.194-0.241-0.239-0.24-0.48c-0.181-0.623-1.347-0.982-1.92-0.48c-1.517,2.281,0.044,6.52,0.24,10.32
				c0.251,4.892,0.919,10.266-2.88,11.76c0.088-0.408-0.187-0.453-0.48-0.48c-1.46-1.26-2.82-2.62-4.08-4.08
				c-4.216-5.224-7.64-11.241-9.84-18.48c2.813,1.567,6.789,1.492,10.32,2.4c1.236,0.318,3.29,1.745,3.84,0
				c0.579-1.837-3.053-3.098-3.36-5.04c-0.138-0.873,0.259-2.873,0.48-3.84c0.681-2.993,4.096-6.234,3.6-9.12
				c-0.521-3.034-5.953-3.856-6.96-5.76c-0.972-6.309-3.707-11.854-6.96-16.56c-0.886-1.282-2.01-3.85-4.32-2.88
				c0.62,7.05,1.163,16.375-1.68,22.32c-0.26,1.006-1.082-1.22-2.64-0.48c-0.334,1.754-0.479,3.286-0.48,0.24
				c0.982-5.092,0.458-18.706,3.36-22.56c1.185-1.574,5.694-4.419,7.2-2.88c1.3,1.1,2.5,2.3,3.6,3.6
				c0.224,0.848,1.309,1.316,1.92,3.12c0.778,2.298,0.218,5.491,1.2,6.96c1.874,2.802,6.936,1.585,5.28-3.6
				c-0.58-5.133,0.809-9.981-0.72-13.44c-1.622-3.67-4.868-7.072-6.24-10.56c-0.488-2.072-0.367-4.753-1.92-5.76
				c0.818-13.54-0.185-29.928,0.96-43.92c0.598-7.307,1.093-15.589,3.6-20.4c0.954-1.831,3.984-4.66,6.24-6.72
				c3.885-3.547,7.259-6.959,14.64-6.48c-0.333-1.506-2.297-1.383-4.08-1.44c-0.187-0.721,1.352-2.185,2.4-3.12
				c2.304-2.056,5.412-3.422,6.72-6.48c0-0.88,0-1.76,0-2.64c5.348-9.37,17.402-12.312,31.68-12.96
				c8.493-0.386,17.806-2.951,24.72-2.16c7.172,0.82,8.265,10.829,14.64,8.88c-2.237-2.883-5.922-5.597-7.44-8.4
				c6.502-0.082,12.435,2.716,18,4.08c3.276,0.803,6.191,0.749,8.88,2.16c2.41,1.265,4.086,3.111,6.721,4.08
				c1.013,1.067,1.432,2.728,3.6,2.64c8.539,6.333,12.504,18.076,18.48,27.84c2.248,3.673,4.885,6.348,6.479,10.32
				c3.519,8.765,3.875,20.109,1.44,31.68c-1.298-2.531-0.073-6.207-0.48-8.88c-2.561-0.166-2.297,4.296-2.16,6.72
				c0.34,6.017,1.549,13.278,0.48,18.96c-0.093-4.307-0.689-8.111-1.2-12c-1.673-8.567-2.896-17.584-6-24.72
				c-0.443-1.156-0.837-2.363-1.68-3.12c-1.026-2.674-3.922-4.51-5.28-7.2c-1.014-2.006-1.263-4.214-2.16-6
				c-5.202-10.347-13.131-18.543-24.479-23.52c-1.397-0.612-3.695-2.473-5.28-0.72c-0.003,1.202,0.766,1.634,1.439,2.16
				c0,0.08,0,0.16,0,0.24c1.229,0.531,2.237,1.283,3.601,1.68c3.375,1.424,6.486,3.113,9.12,5.28c0.79,0.73,1.999,1.041,2.64,1.92
				c1.727,0.833,2.882,2.238,4.08,3.6c0.409,0.471,0.561,1.2,1.2,1.44c0,0.08,0,0.16,0,0.24c1.748,1.932,3.155,4.205,4.56,6.48
				c0.714,1.447,1.466,2.854,2.16,4.32c0.251,0.949,0.988,1.412,0.96,2.64c0.308,1.213,0.866,2.173,1.2,3.36
				c0.907,1.573,1.166,3.794,1.44,6c0.416,0.384,0.52,1.081,0.479,1.92c-1.076,0.116-1.071-0.848-1.92-0.96
				c-3.349-2.331-6.25-5.109-9.84-7.2c-0.216-2.002-5.178-5.604-4.32-0.96c0.73,0.949,1.55,1.81,2.16,2.88
				c0.629,0.811,1.134,1.747,1.68,2.64c0.571,1.109,1.25,2.11,1.681,3.36c0.712,2.167,1.598,4.162,1.68,6.96
				c-1.207-0.873-1.593-2.567-2.64-3.6c-0.346-0.134-0.586-0.375-0.721-0.72c-0.939-0.839-10.526-9.862-11.04-3.36
				c0.041,1.012-0.368-0.004-0.72,0c-3.263-0.937-6.84-7.065-11.04-6.24c-0.6,0.118-1.229,0.054-1.2,1.2c-0.08,0-0.16,0-0.24,0
				c-0.198,0.679,0.339,0.622,0.24,1.2c1.972,2.028,4.533,3.467,6.24,5.76c0.943,0.977,1.756,2.084,2.4,3.36
				c1.59,2.49,3.102,5.058,4.08,8.16c0.674,0.766,0.823,2.056,1.199,3.12c-0.709-0.57-1.142-1.418-1.68-2.16
				c-0.778-0.901-1.659-1.7-2.4-2.64c-0.07-0.489-0.649-0.471-0.72-0.96c-1.103-0.737-1.777-1.903-2.88-2.64
				c-6.185-6.585-21.678-7.624-29.76-12.48c-1.308-0.786-3.292-3.898-4.56-1.44c-0.08,0-0.16,0-0.24,0
				c-0.033,1.793,1.295,2.225,2.16,3.12c1.609,0.872,2.808,2.152,4.56,2.88c-1.014,0.906-2.705,1.135-4.56,1.2c-2,0-4,0-6,0
				c-0.48,0-0.96,0-1.44,0c-0.411-0.549-1.832-0.088-2.64-0.24c-8.066-0.414-13.09-3.87-17.76-7.68c-1.18-1.38-2.459-2.66-3.84-3.84
				c-1.854-2.146-3.766-4.234-5.04-6.96c-1.666,0.57-1.269,0.046-2.64-0.48c-1.478,1.862,0.804,5.379,0.24,8.64
				c0,1.44,0,2.88,0,4.32c0,0.96,0,1.92,0,2.88c-0.554,0.074-0.267-0.693-0.72-0.72c-0.187-0.213-0.258-0.542-0.24-0.96
				c-0.107-0.773-0.367-1.394-1.44-1.2c-0.187,0.213-0.258,0.542-0.24,0.96c0,0.16,0,0.32,0,0.48c-0.398,0.649-0.398,2.951,0,3.6
				c0,0.24,0,0.48,0,0.72c-0.547,0.333-0.089,1.671-0.24,2.4c-1.137,7.743-3.805,13.955-7.92,18.72c-1.06,0.86-2.02,1.82-2.88,2.88
				c-1.558,1.402-3.329,2.591-4.8,4.08c2.351-4.849,8.479-8.001,8.88-15.6c0.399-1.444,0.399-4.556,0-6c0-0.08,0-0.16,0-0.24
				c-0.159-2.481,0.318-5.599-0.24-7.68c0-0.08,0-0.16,0-0.24c0-0.8,0-1.6,0-2.4c-0.153-0.887,0.311-2.391-0.24-2.88
				c-1.928-0.105-2.017,2.84-2.4,4.32c-1.349,5.212-3.483,10.788-6.24,14.64c-0.08,0-0.16,0-0.24,0
				c-0.374,0.906-1.066,1.494-1.44,2.4c-0.904,2.219-3.207,5.318-1.92,8.16c0.752,0.112,0.677-0.604,0.96-0.96
				c0.432-0.528,0.21-1.71,0.48-2.4c2.571-6.309,6.865-10.895,8.88-17.76c0.698,0.183-0.218,1.978,0.48,2.16
				c0.236,0.964,0.294,2.106,0.24,3.36c0,1.36,0,2.72,0,4.08c-0.103,10.377-8.545,12.415-11.04,20.4
				c-1.08,0.915,0.96,2.551,1.44,1.2c7.863-7.017,16.636-13.124,18.48-26.16c0.468-0.892,0.65-2.069,0.72-3.36
				c-0.074-0.874,0.038-1.562,0.24-2.16c0.688-0.032-0.208-1.647,0.48-1.68c0.481,0.958,0.191,2.688,0.72,3.6
				c0.818,0.018,0.711-0.89,0.72-1.68c-0.142-1.022,0.172-1.587,0.24-2.4c0.002-0.638-0.09-1.371,0.24-1.68
				c0.698-0.182-0.218-1.978,0.48-2.16c0,1.52,0,3.04,0,4.56c-0.818,12.942-5.48,22.041-7.68,33.6
				c-0.346,0.054-0.083,0.716-0.48,0.72c-0.155-1.045,0.313-2.713-0.24-3.36c-0.037-1.243,0.245-2.805-0.72-3.12
				c-3.458,3.372-2.24,9.141-4.08,13.68c-1.355,3.344-4.137,5.612-5.52,8.4c-0.651-2.161,1.267-5.326,0-7.44
				c-2.115,1.885-1.396,6.603-2.4,9.6c-0.553,0.074-0.267-0.693-0.72-0.72c-0.427-0.693-0.432-1.809-0.96-2.4
				c-1.032-2.168-1.009-5.391-2.64-6.96c-0.466,2.618,0.895,5.354,1.68,8.16c1.503,5.373,2.493,11.454,3.6,17.52
				c0.568,3.113,1.566,6.374,1.44,9.12c-0.168,3.643-2.216,6.755-2.4,10.32c-0.399,1.205-0.399,4.075,0,5.28
				c3.059,9.021,8.247,15.913,13.68,22.56c0.939,1.14,1.979,2.18,3.12,3.12c1.53,1.91,3.077,3.803,4.32,6
				c0.835,1.085,1.403,2.437,2.4,3.36c1.138,1.502,1.698,3.582,3.36,4.56c0.489-1.104,0.652-1.214,1.2-2.4
				c1.641,2.119,2.019,5.501,2.88,8.4c1,0.12,1.152-0.608,1.2-1.44c0.688,0.032-0.208,1.647,0.48,1.68c0,0.16,0,0.32,0,0.48
				c0.15,0.729-0.307,2.067,0.24,2.4c0,0.24,0,0.48,0,0.72c0.158,1.523-0.316,3.676,0.24,4.8c0,0.56,0,1.12,0,1.68
				c0.113,1.327-0.339,3.22,0.48,3.84c0.704-0.017,0.892-0.549,1.2-0.96c0.548-1.772,1.812-2.828,1.92-5.04
				c-1.298-0.338-1.11,0.81-1.68,1.2c0,0.08,0,0.16,0,0.24c-0.87,0.75-0.331-1.446-0.48-2.16c-0.11-1.43,0.075-4.318,0-6.48
				c-0.739-1.98-0.001-5.439-1.44-6.72c-1.319,0.281-1.342,1.858-1.68,3.12c-1.101-1.689-2.34-8.781-4.32-9.84
				c-1.145,0.375,0.062,3.102-0.72,3.84c-2.515-4.125-5.083-8.196-7.92-12c-1.31-1.17-2.388-2.572-3.6-3.84
				c-0.661-0.459-1.221-1.019-1.68-1.68c-2.141-2.058-4.005-5.427-6.24-9.12c-1.842-3.044-4.26-6.082-5.04-8.64
				c-1.662-5.452,0.857-10.845,1.92-16.08c0.156-1.175,0.042-1.128,0-2.4c-0.498-6.781-1.922-12.638-3.36-18.48
				c-0.066-0.866,0.949-0.651,0.96-1.44c1.083-6.598,6.352-9.009,8.4-14.64c0-0.48,0-0.96,0-1.44c0.549-0.411,0.088-1.832,0.24-2.64
				c0.131-0.349-0.28-1.24,0.24-1.2c0.016-1.424,0.625-2.255,0.72-3.6c0.788,3.292,0.5,7.661,2.4,9.84
				c1.37-1.251,0.808-2.907,0.96-4.32c1.417-13.145,7.643-24.077,8.64-37.92c0.096-1.208-0.387-5.092,0.72-3.12
				c2.299,1.96,4.848,4.135,7.68,5.76c2.819,1.618,6,3.296,9.36,3.6c0.315-1.557-1.709-2.356-2.64-3.12
				c-3.139-2.577-7.004-5.357-9.6-8.64c-0.822-0.698-1.497-1.543-1.92-2.64c1.18,1.14,2.53,2.11,3.6,3.36
				c5.788,4.772,13.039,8.081,23.52,8.16c2,0,4,0,6,0c2.189-0.771,5.559-0.361,6-2.88c7.951,3.249,18.526,3.875,23.76,9.84
				c1.46,1.26,2.82,2.62,4.08,4.08c1.063,1.529,2.76,2.905,4.319,5.76c0.874,1.599,1.551,5.179,3.36,5.04
				c1.744-0.134,1.776-2.458,1.44-4.56c-1.054-6.58-5.307-15.221-8.641-19.92c-1.025-1.055-1.92-2.24-3.12-3.12
				c1.201-0.555,2.307,1.048,3.12,1.68c2.767,1.474,3.594,4.887,6.48,6.24c2.167-0.517,0.709-3.48,0.479-5.04
				c2.641,0.969,5.392,3.148,7.44,5.76c1.812,2.31,4.07,6.004,4.319,8.88c0.127,1.46-1.121,3.002,0.24,4.08
				c2.564,0.004,1.641-3.479,2.4-5.28c0-1.04,0-2.08,0-3.12c-0.641-4.799-1.593-9.287-3.12-13.2c3.704,2.696,7.261,5.539,11.28,7.92
				c0.278-0.039,0.427,0.053,0.479,0.24c0.565-0.005,0.707,0.414,1.44,0.24c1.992-1.635-1.283-2.619-0.96-4.8c0-0.08,0-0.16,0-0.24
				c-0.345-0.296-0.455-0.825-0.48-1.44c-0.495-0.705-0.669-1.731-0.96-2.64c0.637,0.244,0.65,1.11,1.2,1.44c0,0.16,0,0.32,0,0.48
				c0.096,0.624,0.716,0.724,0.72,1.44c0.617,0.744,0.773,1.947,1.2,2.88c0.495,0.704,0.669,1.73,0.96,2.64
				c0.826,2.614,1.49,5.39,1.92,8.4c-0.165,7.295,2.409,13.469,2.4,21.36c-0.241-0.001-0.286,0.194-0.48,0.24
				c0.068,0.388-0.227,0.414-0.239,0.72c0,1.12,0,2.24,0,3.36c0,0.08,0,0.16,0,0.24c0.079,0,0.159,0,0.239,0c0,0.08,0,0.16,0,0.24
				c0,2.72,0,5.44,0,8.16c0,0.88,0,1.76,0,2.64c0,0.08,0,0.16,0,0.24c-0.151,0.808,0.31,2.229-0.239,2.64
				c-0.146,0.574,0.301,1.741-0.24,1.92c-0.313,3.688-0.899,7.101-1.44,10.56c0,1.76,0,3.52,0,5.28
				c0.302,5.138,1.571,9.308,2.641,13.68c0.35,12.11-3.283,22.317-5.521,32.64c0.017,1.011-0.887-0.533-1.92,0
				c-2.471,4.169-4.537,8.743-7.68,12.24c-3.059,3.021-3.174,8.986-4.801,13.44c-4.559,0.801-2.73,7.989-4.319,11.76
				c-0.465-1.616-0.386-3.774-1.681-4.56c-3.323-0.429-4.177,5.765-5.04,8.4c-1.224,3.74-2.286,6.461-3.119,8.88
				c-0.519-0.922-0.506-2.375-0.96-3.36c-1.059-0.339-0.004,1.436-0.721,1.44c-0.672,1.408-0.521,3.64-0.96,5.28
				c-0.104,1.096,0.243,2.643-0.24,3.36c0.111,1.009,0.005,2.235,0.721,2.64c2.249,0.569,1.861-1.498,2.16-2.88
				c2.582-4.938,4.42-10.62,6.479-16.08c-0.074-0.954,0.555-1.206,0.48-2.16c0.886,2.394,0.833,5.727,3.6,6.24
				c1.886-0.883,1.885-5.158,2.4-8.4c0.418-2.632,0.833-5.162,0.96-7.44c0.692-0.622,1.487,0.108,2.399,0
				c0.837-0.124,0.353-1.568,0.48-2.4c-0.151-10.951,6.768-14.832,9.84-22.56c1.01,4.008-0.165,7.666-0.48,11.76
				c-0.472,6.127,1.312,12.071,0.96,17.28c-2.654,4.625-5.249,9.311-6.479,15.36c-0.236,1.107-0.929-1.03-2.16-0.48
				c-1.629,0.771-1.133,3.667-1.68,5.52c-0.133,1.062-0.895-0.888-2.16-0.24c-0.886,0.634-1.909,1.131-2.4,2.16
				c-0.401-1.271-2.229-2.217-3.359-1.92c-1.376,0.361-2.074,3.601-2.88,5.28c-2.413,5.021-5.493,12.005-7.921,15.84
				c-1.154,0.919-0.938-1.19-2.399-0.72c-1.928,1.433-3.611,3.108-5.04,5.04c-0.734-0.626-0.7-2.02-1.68-2.4
				c-6.722,0.319-12.848,1.232-16.561,4.56c-1.524-3.756-6.118-4.442-9.12-6.72c-0.011-0.886,3.489-1.97,2.88-4.08
				c-0.603-2.087-9.5-0.443-12-1.44c-2.393-0.954-5.337-3.936-7.92-6c-3.659-2.924-5.845-5.649-10.08-7.2
				c1.134-0.827,3.337-1.517,3.12-3.36c-0.341-2.894-7.291-1.813-11.52-2.16c-7.869-0.646-8.269-6.314-12-10.8
				c-0.303-0.577-1.276-0.484-1.68-0.96c-0.932-0.863-0.126-2.772-0.48-4.08c-0.649-2.401-2.971-1.816-5.76-3.36
				c-1.748-0.968-4.296-4.72-5.04-6.48c-0.9-2.128-1.348-5.262-2.16-8.16c-0.768-2.741-1.471-5.702-1.92-8.4
				C205.586,262.19,205.706,255.814,202.8,251.48z M233.76,132.68c1.219,2.381,2.866,4.333,4.32,6.48c0.94,1.14,1.98,2.18,3.12,3.12
				c0.928,0.032,0.947,0.973,1.68,1.2c0.486,0.557-0.098,0.552-0.48,0.24c-2.727-1.433-5.342-2.979-7.2-5.28
				c-0.639-0.721-1.09-1.63-1.68-2.4c-1.33-0.67-0.849-3.151-1.2-4.8c0-0.24,0-0.48,0-0.72
				C233.011,131.029,232.941,132.299,233.76,132.68z M177.6,347.241c3.013-1.762,6.127-2.482,9.36-3.84
				c2.586-1.086,4.137-3.845,6.48-5.28c0.204-0.044,0.226,0.094,0.24,0.24c1.511,2.941,1.051,6.077,1.68,8.88
				c2.859,12.753,10.242,24.866,17.76,33.84c3.238,3.864,5.729,8.104,8.4,12.24c5.231,8.101,13.237,14.416,20.88,20.64
				c3.773,3.072,7.977,5.749,12,8.64c4.056,2.914,8.542,5.573,13.2,6.96c1.616-2.713,2.75-5.691,4.08-9.359
				c0.488-1.348,2.603-9.242,3.84-9.121c2.217,0.218,1.165,6.352,0.96,9.36c-0.248,3.639-1.427,8.21-0.72,11.28
				c1.284,5.574,5.222,10.632,7.68,14.4c0,1.359,0,2.72,0,4.08c-6.226-0.469-11.857,2.856-17.52,3.6
				c-7.445,0.977-16.302,0.182-24.96,0.72c-4.05,0.252-8.18,0.72-12.24,0.721c-6.181,0-12.075,0.283-16.8-0.24
				c-1.415,3.974-0.873,8.574-1.2,12.96c-0.961,12.863-1.74,26.489-7.44,35.04c-1.485-4.934,2.061-7.568,2.88-10.561
				c0.767-2.802-0.147-5.859,0-8.159c0.236-3.69,1.304-7.549,1.68-11.28c0.891-8.835,2.981-19.276,0.96-29.28
				c-0.98-4.851-4.236-9.656-6.48-14.16c-2.786-5.592-5.156-12.024-8.16-18c-2.958-5.883-5.491-12.438-8.88-17.04
				c-1.169-1.587-3.259-3.25-5.28-4.8c-1.418-1.089-3.446-1.987-4.32-3.36c-1.867-2.932-1.983-6.538-3.36-9.36
				c-3.502-7.178-9.14-14.309-19.92-14.88C160.005,356.521,169.133,352.191,177.6,347.241z M88.56,449.241
				c-1.642-13.938-0.027-26.852,4.32-37.681c4.373-10.894,10.302-18.92,19.68-24.96c7.511-4.837,14.209-9.3,22.32-14.16
				c5.677-3.401,9.706-8.47,18.48-7.44c5.716,0.671,11.11,5.264,13.68,8.88c2.995,4.213,3.55,9.414,6,13.92
				c1.834,3.373,6.548,4.523,8.4,7.68c-4.839-1.809-9.545-5.01-15.36-6.72c-2.597-0.764-5.963-1.444-9.12-1.92
				c-2.863-0.432-6.813-1.501-8.88,0.96c0.947,1.961,2.678,0.895,4.08,0.96c8.277,0.386,19.896,2.847,25.92,6.24
				c2.418,1.362,3.437,4.153,6.72,4.8c0.205,1.155,0.996,1.724,1.2,2.88c5.885,10.835,11.379,22.061,15.6,34.56
				c0.337,0.783,1.618,0.623,1.92,1.44c-0.771,0.6-1.841-0.55-2.64-0.72c-4.484-1.356-8.806-2.875-13.92-3.601
				c-0.678,0.198-0.622-0.338-1.2-0.239c-9.448-1.317-19.896-2.407-31.44-1.92c-11.878,0.5-21.799,1.958-30.96,6.239
				c-10.18,4.758-19.26,10.852-25.68,19.681c-0.853,0.027-1.934-0.174-1.92,0.72C92.932,456.068,90.777,452.624,88.56,449.241z
				 M113.04,544.281c-1.834-4.444-3.266-9.335-5.52-13.2c-1.947-3.34-4.442-6.554-4.56-11.04c3.785,2.801,9.56,1.489,14.88,2.4
				c3.144,0.537,4.793,3.434,7.44,3.84c-0.901-9.18-15.335-4.825-21.84-8.4c0.272-7.228-3.398-11.684-5.52-16.8
				c-1.055-2.545-2.078-5.091-2.88-7.92c-1.587-5.598-1.696-11.404-3.84-17.521c2.968-4.952,5.061-10.778,9.36-14.399
				c0.523-0.438-0.043-1.963,0.48-2.4c11.42-14.778,31.458-25.833,58.8-24c6.019,0.403,11.765,0.881,17.04,2.399
				c5.244,1.511,10.511,2.82,16.32,3.12c0.279-0.039,0.427,0.054,0.48,0.24c3.958,1.563,7.964,3.076,11.76,4.8
				c2.295,12.445-0.918,24.129-1.92,36c-2.363-5.796-4.474-11.846-6.96-17.52c-1.537-2.783-2.936-5.704-5.28-7.68
				c-0.08,0-0.16,0-0.24,0c2.556,10.244,7.74,19.939,10.56,30.72c0.361,0.68,1.476,0.604,2.16,0.96
				c0.794,3.195-1.616,4.693-2.4,6.48c-1.242,2.828-0.615,5.694-1.2,9.84c-0.48,0-0.96,0-1.44,0
				c-0.964,2.151-2.569,3.841-1.68,7.199c0.501,1.892,2.14,3.549,2.88,5.521c1.252,3.333,1.713,7.417,2.88,11.28
				c3.131,10.367,7.143,22.661,11.28,30.24c-0.447-0.033-0.802-0.158-0.96-0.48c-2.594-1.246-4.857-2.822-7.44-4.08
				c-0.279,0.039-0.427-0.053-0.48-0.24c-6.018-3.022-12.563-5.518-19.68-7.439c0-0.08,0-0.16,0-0.24
				c-1.616-0.204-1.346,1.775,0,1.68c4.448,2.112,9.27,3.852,13.68,6c0.147,0.333,0.924,0.036,0.96,0.48
				c0.596-0.036,0.996,0.124,1.2,0.479c1.621,0.779,3.194,1.606,4.8,2.4c3.321,1.639,6.249,3.671,9.6,5.28
				c0.645,0.243,2.224,0.632,1.92-0.721c4.097,4.144,5.896,10.585,8.16,16.561c1.837-0.711,2.463,1.047,1.68,2.16
				c0,0.16,0,0.319,0,0.479c-0.346-0.134-0.586-0.374-0.72-0.72c-1.156-0.684-2.31-1.37-3.6-1.92
				c-0.501,0.102-0.635-0.164-0.96-0.24c-2.781-0.725-4.7,1.122-5.28,3.36c-0.397,0.259-0.397,2.141,0,2.399
				c0.452,1.868,0.774,3.866,1.2,5.761c0.17,1.029,0.392,2.008,0.96,2.64c0.492,1.509,1.323,2.677,1.92,4.08
				c0.748,1.252,1.773,2.228,2.88,3.12c0.08,0,0.16,0,0.24,0c0.613,0.427-0.159,2.239,0.48,2.64c0.485,2.234,1.346,4.095,1.92,6.24
				c0.608,1.473,1.153,3.007,2.16,4.08c0.794,1.526,1.857,2.783,2.64,4.32c0.859,1.06,1.82,2.02,2.88,2.88
				c2.349,1.571,4.949,2.891,7.44,4.319c1.454,0.946,1.887,2.914,3.36,3.841c-0.17,1.05,0.125,1.634,0.72,1.92
				c2.277-0.203,3.874-1.086,6-1.44c0.391,1.381-1.233,2.069-1.92,3.12c-1.375,2.104-2.418,5.36-3.36,8.4
				c-0.769,2.479-0.932,4.819-2.64,6c-0.559,2.321-0.081,5.679-0.24,8.399c2.096,5.04,3.804-2.71,4.56-4.8
				c3.634-10.042,8.278-18.879,16.32-24c5.867-3.736,10.42-4.565,19.68-5.521c1.045-0.154,2.713,0.313,3.36-0.239
				c0.56,0,1.12,0,1.68,0c0.729-0.15,2.067,0.307,2.4-0.24c11.925-1.676,19.618-7.581,28.08-12.72
				c0.598-0.202,1.286-0.314,2.16-0.24c0.681-1.398,2.197-1.963,3.6-2.64c1.297-0.178,0.784,1.455,2.4,0.96
				c1.566-0.194,0.229-3.291,2.159-3.12c0.431,0.03,0.507-0.294,0.961-0.24c-0.139,0.146-0.563,1.139,0,1.2
				c1.364,2.395,3.261,4.259,4.319,6.96c0.59,1.011,0.956,2.243,1.681,3.12c5.028,12.812,11.066,24.613,12.72,40.8
				c0.156,1.363-0.315,3.355,0.24,4.32c-0.354,2.726,3.066,4.027,3.359,0.96c0.374-0.782,0.001-2.875-0.239-3.84
				c-1.047-10.707-4.824-20.293-8.16-29.28c-3.339-8.994-6.272-18.134-12.48-24.721c0-0.159,0-0.319,0-0.479
				c3.682-3.19,9.554-0.031,12.48,2.4c1.899,2.1,3.899,4.1,6,6c1.564,1.954,3.401,3.638,4.56,6c0.677,0.843,1.081,1.958,1.92,2.64
				c1.095,1.706,2.242,3.358,2.88,5.52c0.36,1,0.682,2.039,1.44,2.641c2.371,11.101,3.913,24.204,3.36,37.439
				c-11.349,6.86-24.003,10.915-35.761,17.761c-1.727,1.005-3.753,2.105-5.52,3.359c-1.771,1.257-3.271,3.133-5.04,3.84
				c-2.101,0.841-4.875,0.575-7.44,1.681c-2.433,1.048-4.973,3.412-7.439,5.28c-7.302,5.526-14.904,10.166-24.24,13.92
				c-8.71,3.502-18.404,7.216-28.32,9.84c-5.314,1.406-10.341,2.532-15.6,1.2c-9.179-5.862-17.917-11.592-28.08-16.32
				c-9.61-4.471-16.93-12.174-24-20.16c-4.53-5.117-9.191-10.73-12.96-16.32c-7.616-11.294-15.105-23.542-22.8-36.239
				c-5.341-8.813-10.426-18.73-15.12-29.041c-1.912-4.199-3.492-7.66-4.08-12.479c-2.445-4.405-3.496-11.48-5.52-17.28
				C119.884,562.607,116.864,553.548,113.04,544.281z M346.801,691.881c-0.38,0.021-0.916-0.115-0.96,0.24
				c-1.185,0.336-2.656,0.384-3.84,0.72c-0.542-2.28,3.522-3.052,1.439-4.56c-1.133-0.014-1.401,0.838-2.16,1.2
				c0.238-3.202,1.25-5.631,2.4-7.92c1.712,0.661,4.342,0.954,6.479,0.479c-0.854,2.985-1.946,5.733-3.119,8.4
				C346.511,690.471,346.938,691.459,346.801,691.881z M324.24,699.321c0.041-0.521-0.851-0.108-1.2-0.24
				c0.067-2.813,0.381-5.379,0.24-8.399c5.023-4.258,11.022-7.538,17.521-10.32c-0.45,1.07-1.125,1.915-1.44,3.12
				c-1.004,2.676-1.45,5.909-1.92,9.12c-1.281,2.238-3.534,3.506-5.28,5.279C330.057,698.897,327.501,699.461,324.24,699.321z
				 M318.24,702.201c-0.22,2.58-1.059,4.541-2.16,6.24c-3.297,0.642-6.823-0.307-10.08-0.48c-1.282-0.557-3.598-0.082-5.279-0.239
				c-3.101,0.22-5.265-0.496-7.92-0.721c5.367-3.269,10.028-6.603,15.359-10.08c1.646-1.073,3.333-2.643,5.04-3.359
				c1.739-0.73,4.016-0.504,6-1.2c0,1.2,0,2.4,0,3.6c0.024,0.984,0.048,1.968-0.239,2.641
				C318.21,699.578,317.238,700.766,318.24,702.201z M128.16,601.881c2.037,3.803,3.817,7.863,5.76,11.76
				c0.126,2.302,1.729,6.56,3.12,9.12c-3.926,7.515-7.568,15.313-11.28,23.04C126.77,632.331,128.138,617.139,128.16,601.881z
				 M128.16,653.961c0.189-0.904,0.42-2.032,0-2.88c4.451-7.469,7.651-16.188,12-23.76c2.796,4.004,4.812,8.788,7.44,12.96
				c-1.641,2.76-2.495,6.306-4.8,8.4C136.979,649.5,132.782,651.943,128.16,653.961z M139.92,679.161
				c5.846-0.778,12.31-4.464,18.48-1.92c-3.531,4.789-12.526,4.114-17.76,7.2C139.944,683.138,140.143,680.938,139.92,679.161z
				 M202.922,747.533c-0.195-0.014-0.386-0.026-0.571-0.038c-0.003,0-0.008,0-0.011-0.001c-0.176-0.01-0.347-0.02-0.513-0.027
				c-0.012,0-0.025-0.001-0.038-0.002c-0.147-0.007-0.289-0.012-0.428-0.016c-0.028-0.001-0.059-0.002-0.087-0.003
				c-0.128-0.003-0.25-0.005-0.369-0.005c-0.032-0.001-0.066-0.002-0.098-0.002c-0.11,0-0.212,0.003-0.313,0.005
				c-0.036,0.001-0.074,0.001-0.108,0.003c-0.092,0.003-0.175,0.009-0.259,0.015c-0.037,0.002-0.077,0.004-0.112,0.007
				c-0.079,0.007-0.149,0.017-0.22,0.025c-0.031,0.005-0.067,0.008-0.097,0.012c-0.096,0.016-0.183,0.034-0.259,0.056
				c-1.68,0-3.36,0-5.04,0c-2.641-0.56-6.319-0.081-9.36-0.24c-1.44,0-2.88,0-4.32,0c-6.16-0.56-13.36-0.08-19.92-0.24
				c-5.52,0-11.04,0-16.56,0c0.706-5.979-2.485-12.021-2.4-18.96c12.009-2.63,17.268,7.006,30,6.24
				c1.203,0.557,3.438,0.082,5.04,0.24c11.296,0.224,19.737,3.303,27.12,7.439c1.146,0.774,2.426,1.414,3.84,1.92
				c2.603,1.077,5.091,2.27,7.68,3.36C211.689,747.761,207.222,747.563,202.922,747.533z M324.48,743.961c0,0.08,0,0.16,0,0.24
				c-0.551,0.489-0.087,1.993-0.24,2.88c0,0.08,0,0.16,0,0.24c-27.121,0.438-56.764,0.24-85.44,0.24
				c-4.197,0-9.763,0.365-12.24-0.48c-2.822-2.705-9.303-3.737-13.44-5.52c-3.544-1.527-7.099-3.37-10.56-4.8
				c-7.584-3.135-15.616-5.148-25.2-5.521c-1.762-0.558-4.559-0.081-6.72-0.24c-6.051-0.937-12.858-1.77-16.56-5.04
				c-0.654-0.577-1.157-1.267-1.44-2.159c-1.567-1.444-4.568-1.934-4.8-4.08c-0.274-2.535,4.911-6.88,6-8.641
				c6.908,1.962,12.33,4.738,20.64,6.24c1.486,0.269,6.662,1.288,7.2-0.72c0.634-2.367-3.479-3.461-5.28-4.08
				c-4.326-1.488-7.227-1.452-10.32-3.601c-1.813-1.259-3.437-3.115-5.04-4.8c-1.494-1.57-3.158-3.968-4.8-4.8
				c-1.911-0.968-4.16-0.78-6-1.44c-4.634-1.662-7.451-5.208-9.36-9.6c3.105-1.802,7.298-3.1,11.76-4.08
				c4.026-0.885,9.279-2.178,9.84-6c1.308-8.911-13.787-7.088-19.68-4.56c-0.59,0.159,1.66-2.214,2.64-3.12
				c2.552-2.362,4.566-4.29,7.44-6.96c0.884-0.821,3.261-2.626,2.88-4.32c-0.918-4.086-8.341,1.964-9.36,2.64
				c-3.293,2.185-5.803,4.041-10.08,4.561c-0.608-0.83,0.779-1.702,1.2-2.16c1.925-2.098,4.08-4.501,6-6.96
				c2.542-3.254,4.202-7.616,7.68-9.601c3.645,8.639,8.909,16.832,14.4,24.721c5.325,7.648,9.681,15.799,16.56,22.56
				c11.448,11.252,28.207,17.021,43.2,25.44c2.161,1.213,4.395,3.026,6.24,3.36c2.636,0.476,5.871,0.66,8.88,0.479
				c8.257-0.494,16.626-3.604,24.48-6.24c8.003-2.687,15.699-5.471,22.32-8.399c1.21,0.229,2.394,0.486,3.601,0.72
				c0.332,0.547,1.67,0.09,2.399,0.24c0.561,0,1.12,0,1.68,0c0.333,0.547,1.671,0.089,2.4,0.24c0.32,0,0.64,0,0.96,0
				c0.885,0.555,2.797,0.083,4.08,0.239c0.72,0,1.44,0,2.16,0c0.333,0.548,1.671,0.09,2.4,0.24c3.767-0.21,7.186,1.246,10.079,0.479
				c3.068-0.812,3.533-3.782,4.561-6.96c1.87,4.61,2.517,10.443,2.88,16.561c0.154,0.966-0.313,2.552,0.24,3.12
				c0,0.08,0,0.16,0,0.24c0,4.64,0,9.279,0,13.92c0,0.08,0,0.16,0,0.239C324.164,740.125,324.638,742.359,324.48,743.961z
				 M385.2,717.562c-1.297,2.969-7.074,3.659-5.76,7.68c1.717,0.437,1.724-0.836,3.12-0.72c0.152,0.888,0.651,1.429,0.48,2.64
				c-0.157,1.363,0.315,3.355-0.24,4.32c-0.697,5.383-1.921,10.239-2.88,15.36c0,0.399,0,0.8,0,1.199c-1.761,0-3.521,0-5.28,0
				c-0.808-0.151-2.229,0.31-2.64-0.239c-0.08,0-0.16,0-0.24,0c-0.16,0-0.32,0-0.48,0c-11.12-0.561-23.279-0.08-34.8-0.24
				c0.172-1.188,0.33-2.391,0.24-3.84c9.694-4.466,21.479-6.842,31.92-10.561c0.916-0.224,1.935-1.725,0.96-2.399
				c-2.698-1.452-5.607,0.835-8.16,1.68c-8.086,2.676-16.977,5.255-24.479,8.64c-0.601-0.693-0.316-2.545,0.72-2.399
				c1.415-0.505,1.977-1.863,3.6-2.16c1.156-0.444,2.363-0.837,3.12-1.68c2.419-0.988,4.381-3.267,7.68-5.04
				c2.403-1.292,7.771-2.088,7.681-5.04c-0.099-3.215-8.424-3.746-9.12-0.96c0,0.079,0,0.159,0,0.239
				c1.053,0.947,3.718,0.282,5.52,0.48c0.557-0.077,0.922,0.038,1.2,0.24c-3.227,2.057-6.667,3.131-9.84,5.04
				c-2.995,1.802-5.959,4.093-9.84,4.8c-0.473-0.096-0.623,0.13-0.91,0.221c-0.017-0.037-0.031-0.083-0.047-0.127
				c-0.371-1.041-0.514-4.03-0.546-6.6c0.033,2.561,0.175,5.56,0.546,6.6c0.151-3.864-0.499-6.926-0.483-10.654
				c5.697-2.943,14.257-3.023,19.92-6c0.936-0.332,2.415-2.318,0.721-1.92c-7.419,0.212-14.7,2.559-21.12,4.561
				c-0.273-2.687-0.813-5.106-0.96-7.92c-0.098-2.143-0.41-4.071-0.96-5.761c-0.202-0.278-0.316-0.644-0.24-1.2
				c0.172-1.002-0.548-1.8-1.352-2.054c-0.709-0.224-1.484-0.024-1.769,0.854c0.665,5.335,1.933,10.067,2.16,15.84
				c0.154,0.966-0.313,2.552,0.24,3.12c0,0.08,0,0.16,0,0.24c0.229,7.983,0.723,15.681,0.239,23.76
				c-1.762-0.158-4.157,0.317-5.52-0.24c0.552-10.571,1.082-23.305-0.72-32.88c-0.306-0.975-0.444-2.116-0.48-3.36
				c-0.069-1.29-0.252-2.468-0.72-3.359c-0.48-1.52-0.883-3.118-1.2-4.801c0.966-0.153,2.552,0.313,3.12-0.239
				c1.578-0.24,3.063-0.573,4.437-1.018c0.803-0.26,1.576-0.547,2.283-0.902c1.407-0.754,2.369-1.951,3.601-2.881
				c4.207-0.832,7.449-2.63,12.239-2.88c1.32-1.091,3.251,0.744,5.04-0.239c5.468-0.854,10.666-1.975,16.32-2.641
				c0.808-0.152,2.229,0.31,2.64-0.24c0.24,0,0.48,0,0.721,0c1.283-0.156,3.195,0.315,4.08-0.239
				c0.678,0.198,0.621-0.339,1.199-0.24c0.881,3.04,1.415,6.426,1.44,10.32c0.158,1.762-0.317,4.157,0.24,5.52c0,0.24,0,0.48,0,0.72
				c0.547,0.333,0.089,1.671,0.24,2.4C382.097,713.785,383.533,715.789,385.2,717.562z M488.161,646.761c0,0.641,0,1.28,0,1.921
				c0,0.239,0,0.479,0,0.72c-6.456,3.304-12.345,7.176-18.721,10.56c-0.756,0.523-1.907,0.653-2.399,1.44
				c-8.279,3.442-16.155,8.266-24.96,11.76c-25.842,10.255-59.766,13.655-91.2,18.24c-1.438,0.126-0.152-0.809-0.24-1.44
				c0.875-2.805,2.116-5.243,2.64-8.399c6.791-0.175,9.788-4.505,13.92-7.92c6.978-5.769,13.616-11.658,23.28-14.881
				c3.326-5.474,6.042-11.558,6.48-19.92c2.577-1.569,5.013-4.907,8.16-6.239c4.159-1.762,8.873-1.115,12.72-2.881
				c4.9-2.248,10.107-8.124,12.479-12c1.726-2.817,3.949-7.253,3.36-12.72c-0.414-3.842-2.324-6.026-3.12-10.32
				c3.01-1.229,7.199-1.28,11.521-1.199c0.805,0.554,2.636,0.084,3.84,0.239c9.069,0.211,15.778,2.782,22.08,5.761
				c0,0.079,0,0.159,0,0.239c0.802,0.398,1.598,0.803,2.399,1.2c1.456,0.356,3.841,2.626,5.28,0.72c0-0.319,0-0.64,0-0.96
				c0-0.079,0-0.159,0-0.239c-6.69-4.19-14.133-7.627-23.52-9.12c-1.179-0.249,1.032-0.75,0.96-1.44
				c-2.277-3.323-8.966-2.234-13.44-3.359c-0.333-0.548-1.671-0.09-2.4-0.24c-2.325-0.379-7.159,1.174-7.439,0c0.08,0,0.16,0,0.24,0
				c0.899-1.261,1.901-2.418,2.88-3.601c0-0.079,0-0.16,0-0.239c1.14-0.94,2.18-1.98,3.12-3.12c0.08,0,0.159,0,0.239,0
				c0.54-0.741,1.18-1.381,1.92-1.92c2.357-1.563,4.184-3.657,6.721-5.04c0.822-0.857,2.151-1.209,2.399-2.641
				c-1.268-4.78-6.644,2.142-8.88,2.88c-0.888,0.792-1.995,1.365-2.64,2.4c-0.08,0-0.16,0-0.24,0s-0.16,0-0.24,0
				c-0.571,0.948-1.55,1.49-2.399,2.16c0.488-3.448-1.3-4.621-3.12-5.76c-2.425-4.499,3.513-13.25-1.44-15.601
				c-1.625-0.771-3.98-0.585-5.52-0.96c-7.171-1.746-9.012-4.919-12.48-10.8c-0.771-1.308-2.549-5.106-3.84-5.521
				c-8.67-2.775-17.098,3.956-25.2,5.28c0.095-5.139-0.671-10.772-2.16-14.16c-0.485-1.104-1.5-1.322-1.92-2.16
				c-0.283-1.323,1.741-0.339,1.92-1.199c0.92-2.841,0.232-7.289,0.96-10.32c4.37,0.351,6.228,3.213,11.04,3.12
				c-0.695-4.984-5.733-5.626-10.56-6.48c5.654-4.407,13.665-12.117,15.6-20.64c0.498-2.194,0.042-5.062-0.72-7.44
				c-1.492,0.36-1.283,2.288-1.68,3.36c-2.817,7.628-7.134,16.07-12.96,20.4c-0.43-1.424,1.215-1.831,1.68-2.881
				c0.846-1.906,1.056-4.646,1.68-6.96c0.608-2.251,1.375-4.48,1.681-6.72c3.373-2.414,3.853-8.91,3.359-14.4
				c-0.552-0.647,0.23-2.63-0.72-2.88c-0.327,0.634-0.283,1.637-0.479,2.4c-0.615,1.785-1.342,3.458-1.44,5.76
				c0.889-16.145-4.247-30.438-11.04-40.08c0.187-2.906-0.339-5.101-0.24-7.92c7.854-1.187,16.169-1.911,24.721-2.399
				c0.399,0,0.8,0,1.199,0c3.565-0.435,7.495-0.506,11.521-0.48c4.645,14.327,8.587,31.479,11.52,48
				c1.086,6.116,1.391,11.311,7.2,12.96c3.356,4.512,5.011,13.112,8.641,18.24c1.828,2.583,3.907,3.642,4.56,7.2
				c1.137,0.673,2.515-0.313,2.88-0.24c1.486,1.745,0.814,4.017,1.44,6.24c0.591,2.101,2.127,4.497,3.12,6.72
				c6.894,15.43,14.615,30.692,21.359,46.32c4.011,9.293,7.801,18.234,10.561,28.08
				C487.304,616.738,488.385,631.448,488.161,646.761z M429.841,591.561c0-0.56,0-1.119,0-1.68c0.673-0.287,1.656-0.264,2.64-0.24
				c0.729-0.15,2.067,0.308,2.4-0.239c0.56,0,1.12,0,1.68,0c0.411,0.549,1.832,0.088,2.641,0.239c1.828-0.068,3.235,0.284,4.8,0.48
				c-2.242,0.718-6.158-0.238-8.4,0.479C433.549,590.66,431.279,591.678,429.841,591.561z"/>
				</g>
				<path fillRule="evenodd" clipRule="evenodd" d="M261.84,218.6c0.671,0.254,1.566,1.139,2.4,0.48
			c-3.874-4.56-11.006-7.981-17.76-9.36c-0.014-0.146-0.036-0.284-0.24-0.24c-0.88,0-1.76,0-2.64,0
			c-0.146,0.013-0.284,0.036-0.24,0.24c-1.534-0.094-2.528,0.352-3.84,0.48c-2.561,0.88-4.417,2.463-6.48,3.84
			c1.455-1.825,3.487-3.073,5.76-4.08c1.83-1.385,6.031-1.629,8.64-1.44c0.96,0,1.92,0,2.88,0c4.024-0.813,6.958,2.342,9.84,3.12
			c2.821,0.762,5.512-0.571,8.16-0.72c-2.204,5.919-1.077,12.589-3.12,19.92c-0.661,2.374-2.669,6.729-3.12,9.84
			c0-0.003-0.852,3.029,0.72,3.12c1.52,0.088,1.127-2.596,1.44-3.84c0.552-2.192,2.36-5.043,3.6-8.88
			c0.766-2.368,1.662-8.331,4.08-8.4c1.321-0.038,4.073,3.367,4.8,4.8c0.427,0.841,0.817,2.108,0.96,3.36
			c0.488,4.27-1.132,10.182,0.48,13.92c2.829,0.783,3.159-2.355,3.6-4.56c0.474-2.365,0.857-5.127,0.96-7.44
			c0.184-4.126-0.573-4.817-1.68-8.16c-1.749-5.284-2.406-8.214-4.08-13.2c-1.416-4.218-3.612-9.414-6.96-5.04
			c-2.162,0.399-5.998,0.399-8.16,0c-0.08,0-0.16,0-0.24,0c-1.36,0-2.72,0-4.08,0c-9.971-0.188-18.658,0.318-24.24,5.04
			c-1.166,0.986-1.923,2.969-3.36,3.36c-2.424,0.66-4.094-1.552-5.76-0.24c0.349,2.62,4.88,2.036,6.96,2.16
			c-2.046,6.692-9.009,0.997-14.64,1.92c0.687,1.895,3.683,1.325,5.76,1.92c1.377,0.394,2.371,1.39,3.6,1.44
			c1.622,0.066,2.779-1.062,4.08-0.96c-0.16,1.279,0.186,2.054,0.72,2.64c1.246,0.59,2.655-0.044,4.08,0
			c1.079-0.993,0.317-2.848,0.96-4.56C237.55,205.087,256.44,213.372,261.84,218.6z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M328.08,233.96c-1.615,0.944-3.135,1.984-5.52,2.16c-1.521,0-3.04,0-4.561,0
			c-5.261-1.458-9.822-3.617-11.76-8.4c2.63,1.291,5.495,2.345,8.88,2.88c0.888,0.399,3.434,0.399,4.32,0
			c4.911-0.529,8.462-2.418,9.12-7.2c-0.346-0.134-0.586-0.375-0.72-0.72c-6.74,1.143-14.376,1.488-20.16-0.48
			c-0.29,0.051-0.147,0.267,0,0.48c1.106,1.533,3.582,1.698,5.76,2.16c-0.076,0.563-0.379,0.901-0.24,1.68
			c-4.971,0.265-6.951-4.36-12.96-3.84c-0.627,1.127-0.077,2.884,0.48,3.6c1.748,2.012,3.521,3.998,5.04,6.24
			c0.9,0.699,1.7,1.5,2.399,2.4c3.013,1.45,5.509,3.933,9.36,3.84c1.12,0,2.24,0,3.36,0c3.39-0.77,6.156-2.164,8.88-3.6
			c0-0.56,0-1.12,0-1.68C328.751,233.191,328.708,233.868,328.08,233.96z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M269.76,255.561c-0.037-2.877,1.768-7.072,0-9.36
			c-1.85-0.055-2.258,2.57-2.88,4.32c-1.324,3.729-2.77,8.587-0.24,12.72c1.307,1.592,4.915,7.266,7.2,7.44
			c2.115,0.162,2.81-2.417,5.76-2.4c1.159,1.081,1.725,2.755,1.68,5.04c-2.785,2.016-7.15,2.45-10.56,3.84
			c-0.594-0.231,0.331-0.921,0-1.68c-3.449,0.198-6.375,2.412-9.36,3.84c-3.18,1.521-5.826,3.62-8.88,5.04
			c3.687-7.353,10.204-11.876,13.68-19.44c-0.053-0.187-0.201-0.279-0.48-0.24c-3.065,2.523-6.103,7.132-8.88,10.56
			c-2.705,3.339-5.668,6.794-6.72,11.04c3.861,0.51,6.117-2.744,8.88-4.32c2.965-1.692,6.194-2.503,9.12-4.32
			c-0.329,0.307-0.329,1.133,0,1.44c0.874,0.074,1.562-0.038,2.16-0.24c0.465-0.495,1.462-0.458,2.16-0.72
			c0.281,1.861,3.058,0.857,6,0.72c5.779-0.269,10.544,1.199,11.76-2.16c7.934,1.827,15.535,3.985,21.36,7.92
			c0.472,0.409,1.199,0.561,1.44,1.2c1.079,0.761,1.809,1.871,2.88,2.64c0.718-0.002,1.008-0.432,1.199-0.96
			c0.388-0.068,0.414,0.227,0.721,0.24c0.766,0.206,0.742-0.377,1.2-0.48c-0.216-5.145-1.877-8.844-3.841-12.24
			c-0.62-0.66-1.021-1.538-1.68-2.16c0.329-0.543,0.988,0.593,1.44,0.72c2.696,1.543,3.263,5.217,5.76,6.96
			c0.753,0.113,0.634-0.646,1.2-0.72c-1.586-7.534-9.354-8.887-13.92-13.44c-1.065-0.775-1.924-1.757-2.881-2.64
			c1.13-2.79,4.064-3.775,4.561-7.2c0.123-1.099-0.959-0.82-1.44-1.44c-1.548-1.995-1.387-5.583-3.6-6.96
			c-0.928-0.047-1.769-0.008-2.4,0.24c-0.235,0.324-0.64,0.479-0.96,0.72c-1.125,0.315-1.758,1.122-2.16,2.16
			c-0.877,2.883-3.718,3.802-7.68,3.6c-4.574-0.386-6.052-3.868-9.12-5.76C277.3,250.46,274.03,253.51,269.76,255.561z
			 M300.961,269.24c1.454-1.186,2.172-3.108,3.359-4.56c0.846,1.075,1.77,2.07,2.641,3.12c1.827,1.292,3.146,3.093,4.319,5.04
			c2.443,3.077,4.85,6.191,5.521,11.04c0.521-0.041,0.108,0.851,0.239,1.2c-0.212,2.682-1.887-0.96-2.88-1.2
			c-4.884-4.076-12.204-5.715-18.72-8.16c0.283-1.643-1.07-1.649-1.2-2.88C294.978,270.137,297.158,268.878,300.961,269.24z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M299.04,209c1.126-0.399,3.915-0.399,5.04,0c0.08,0,0.16,0,0.24,0
			c0.859,0.11,3.439-0.251,3.12,0.24c-0.995,2.125-3.14,3.101-5.52,3.84c-0.343,1.063,0.812,0.628,0.72,1.44
			c-0.681,1.239-1.566,2.273-1.92,3.84c0.152,0.853,1.348-0.071,2.399,0.24c1.147-0.213,1.771-0.949,2.641-1.44
			c0.24,0.001,0.285-0.194,0.479-0.24c0.939-1.141,1.979-2.181,3.12-3.12c0-0.08,0-0.16,0-0.24c1.288-1.032,2.795-1.845,5.04-1.92
			c0.729-0.398,3.111-0.398,3.84,0c4.886,1.034,6.681,5.16,8.88,8.88c0.16,0,0.32,0,0.48,0c1.583,0.303,1.921-0.639,3.36-0.48
			c2.465-0.495,5.947,0.028,6.239-2.64c-1.066-0.816-2.027,0.524-3.359,0.24c-0.179,0.542-1.347,0.094-1.92,0.24
			c-1.684,0.627-1.525-2.101-1.681-2.64c-1.68-5.844-7.899-9.91-15.84-8.88c-2.481,0.4-6.639,0.4-9.12,0c-0.479,0-0.96,0-1.439,0
			c-1.36,0-2.721,0-4.08,0c-1.957,0.363-3.976,0.664-3.84,3.12C297.041,210.238,298.094,209.182,299.04,209z"/>
			</g>
			<g>
				<path fill="#A4B1BD" d="M395.041,399.801c-1.929-1.663-4.433-3.099-6.721-4.8c-7.522-5.594-16.676-10.079-27.359-12
			c0.362,1.4,2.225,3.374,2.88,5.28c6.039,12.121,10.585,25.735,11.52,42.959c6.741-1.339,14.366-1.793,21.84-2.399
			c4.539-0.501,9.817-0.264,14.4-0.72c-1.536-5.236-4.148-10.32-7.2-15.121C401.532,408.49,398.867,403.099,395.041,399.801z"/>
				<path fill="#A4B1BD" d="M201.6,437.24c-4.221-12.499-9.715-23.725-15.6-34.56c-0.204-1.156-0.995-1.725-1.2-2.88
			c-3.283-0.647-4.302-3.438-6.72-4.8c-6.024-3.393-17.643-5.854-25.92-6.24c-1.402-0.065-3.133,1.001-4.08-0.96
			c2.066-2.461,6.017-1.392,8.88-0.96c3.157,0.476,6.523,1.156,9.12,1.92c5.815,1.71,10.521,4.911,15.36,6.72
			c-1.853-3.157-6.566-4.307-8.4-7.68c-2.45-4.506-3.005-9.707-6-13.92c-2.57-3.617-7.963-8.209-13.68-8.88
			c-8.774-1.03-12.804,4.039-18.48,7.44c-8.11,4.86-14.809,9.323-22.32,14.16c-9.378,6.04-15.307,14.066-19.68,24.96
			c-4.347,10.829-5.962,23.743-4.32,37.681c2.217,3.383,4.372,6.827,7.2,9.6c-0.014-0.894,1.067-0.692,1.92-0.72
			c6.419-8.829,15.5-14.923,25.68-19.681c9.161-4.281,19.083-5.739,30.96-6.239c11.544-0.487,21.992,0.603,31.44,1.92
			c0.578-0.099,0.521,0.438,1.2,0.239c5.115,0.726,9.437,2.244,13.92,3.601c0.799,0.17,1.868,1.319,2.64,0.72
			C203.218,437.863,201.938,438.023,201.6,437.24z"/>
				<path fill="#A4B1BD" d="M287.28,444.681c0.72,0,1.44,0,2.16,0c0.198-0.618,0.352-1.281,0.486-1.962v0.001
			c0.084-0.309,0.141-0.645,0.175-1.001c0,0.003,0,0.007,0,0.01c0.574-3.66,0.529-7.941,1.019-11.688
			c-0.722-0.238-1.07-0.85-2.16-0.721c-2.679,0.281-5.447,0.473-7.92,0.96c0.446,2.15,1.52,4.419,2.64,7.44
			c0.718,1.938,1.622,6.912,3.6,6.72C287.28,444.521,287.28,444.601,287.28,444.681z"/>
				<path fill="#A4B1BD" d="M294.721,463.4c0.141-1.341-0.55-1.85-0.48-3.119c-0.64,0-1.28,0-1.92,0c-1.706-0.614-4.818,0.178-6-0.961
			c0,1.12,0,2.24,0,3.36c1.222,0.778,3.136,0.864,5.04,0.96C292.732,463.813,293.504,463.385,294.721,463.4z"/>
				<path fill="#A4B1BD" d="M235.68,222.92c0-1.28,0-2.56,0-3.84c-0.643,1.712,0.119,3.567-0.96,4.56
			C235.209,223.57,235.191,222.991,235.68,222.92z"/>
				<path fill="#A4B1BD" d="M309.36,213.8c-1.141,0.939-2.181,1.979-3.12,3.12C307.381,215.98,308.421,214.94,309.36,213.8z" />
				<path fill="#A4B1BD" d="M231.84,331.88c0.78,0.98,1.66,1.86,2.64,2.64C233.701,333.54,232.821,332.66,231.84,331.88z" />
				<path fill="#A4B1BD" d="M304.561,355.641c-0.9,0.699-1.7,1.5-2.4,2.4C303.061,357.341,303.861,356.541,304.561,355.641z" />
				<path fill="#A4B1BD" d="M308.4,571.881c0.282-3.149-3.142-5.055-3.6-8.16c-0.561-3.792,1.291-8.437,1.92-12.72
			c0.622-4.237,1.619-8.978,0.96-13.44c-0.361-2.444-2.119-5.115-3.12-8.399c-2.833-9.294-0.59-19.542-1.2-28.8
			c-0.581-8.82-2.047-17.321-6.24-24.48c-1.536-2.624-4.2-4.755-3.6-9.36c-1.12,0-2.24,0-3.36,0c0.422,3.053,0.899,7.061-0.479,9.36
			c6.188,7.836,6.046,22.529,6.96,36.72c0.657,10.218,2.166,19.474,3.6,29.04c0.525,3.506,1.307,6.982,0.96,10.08
			c-0.499,4.463-3.063,6.462-5.04,9.12c1.795,3.354,4.194,6.204,6.24,9.36c2.172,3.35,3.524,6.877,5.28,10.56
			c1.739,3.649,4.213,6.883,5.28,11.28c1.009,4.162,0.785,8.465,0.72,13.92c2.199-1.001,4.198-2.201,6.24-3.359
			c0.234-6.006-1.163-11.472-3.12-16.561C314.662,580.48,311.848,574.699,308.4,571.881z"/>
				<path fill="#A4B1BD" d="M382.32,584.841c0.78,0.98,1.66,1.86,2.641,2.641C384.181,586.501,383.301,585.621,382.32,584.841z" />
				<path fill="#A4B1BD" d="M421.201,621.801c1.62-1.42,3.14-2.939,4.56-4.56C424.141,618.661,422.62,620.181,421.201,621.801z" />
				<path fill="#A4B1BD" d="M291.601,457.881c-0.096-1.345-0.704-2.176-0.72-3.6c-0.066-1.455,0.258-3.299-0.48-4.08
			c-0.32,0-0.64,0-0.96,0c-0.16,0-0.32,0-0.48,0c-0.611,2.293,0.78,4.658,0.48,7.439
			C290.429,457.452,290.672,458.009,291.601,457.881z"/>
				<path fill="#A4B1BD" d="M282,414.201c-0.614-0.346-1.693-0.227-2.16-0.72c-0.21,5.23-0.924,11.235-0.96,15.84
			c0.216-1.384,2.295-0.904,3.12-1.68C280.917,423.656,281.199,418.263,282,414.201z"/>
				<path fill="#A4B1BD" d="M332.881,723.562c-0.553-0.568-0.086-2.154-0.24-3.12c-0.228-5.772-1.495-10.505-2.16-15.84
			c0.284-0.879,1.06-1.078,1.769-0.854l-0.212-2.083c-1.374,0.444-2.858,0.777-4.437,1.018c-0.568,0.552-2.154,0.086-3.12,0.239
			c0.317,1.683,0.72,3.281,1.2,4.801c0.468,0.892,0.65,2.069,0.72,3.359c0.036,1.244,0.175,2.386,0.48,3.36
			c1.802,9.575,1.271,22.309,0.72,32.88c1.362,0.558,3.758,0.082,5.52,0.24c0.483-8.079-0.011-15.776-0.239-23.76
			C332.881,723.722,332.881,723.642,332.881,723.562z"/>
			</g>
			<path fillRule="evenodd" clipRule="evenodd" fill="#D1BFB3" d="M342.001,228.2c-1.069-4.372-2.339-8.542-2.641-13.68
		c0-1.76,0-3.52,0-5.28c0.541-3.459,1.128-6.872,1.44-10.56c0.541-0.179,0.094-1.346,0.24-1.92c0.549-0.411,0.088-1.832,0.239-2.64
		c0-0.08,0-0.16,0-0.24c0-0.88,0-1.76,0-2.64c0-2.72,0-5.44,0-8.16c0-0.08,0-0.16,0-0.24c-0.08,0-0.16,0-0.239,0
		c0-0.08,0-0.16,0-0.24c0-1.12,0-2.24,0-3.36c0.013-0.307,0.308-0.333,0.239-0.72c0.194-0.045,0.239-0.241,0.48-0.24
		c0.009-7.891-2.565-14.065-2.4-21.36c-0.43-3.01-1.094-5.786-1.92-8.4c-0.291-0.909-0.465-1.936-0.96-2.64
		c-0.427-0.933-0.583-2.137-1.2-2.88c-0.004-0.716-0.624-0.816-0.72-1.44c0-0.16,0-0.32,0-0.48c-0.55-0.33-0.563-1.196-1.2-1.44
		c0.291,0.909,0.465,1.936,0.96,2.64c0.025,0.615,0.136,1.144,0.48,1.44c0,0.08,0,0.16,0,0.24c-0.323,2.182,2.952,3.166,0.96,4.8
		c-0.733,0.173-0.875-0.246-1.44-0.24c-0.053-0.187-0.201-0.279-0.479-0.24c-4.02-2.381-7.576-5.224-11.28-7.92
		c1.527,3.913,2.479,8.4,3.12,13.2c0,1.04,0,2.08,0,3.12c-0.76,1.801,0.164,5.284-2.4,5.28c-1.361-1.077-0.113-2.62-0.24-4.08
		c-0.249-2.876-2.508-6.57-4.319-8.88c-2.049-2.612-4.8-4.791-7.44-5.76c0.229,1.56,1.688,4.523-0.479,5.04
		c-2.887-1.354-3.714-4.767-6.48-6.24c-0.813-0.631-1.919-2.234-3.12-1.68c1.2,0.879,2.095,2.065,3.12,3.12
		c3.334,4.7,7.587,13.341,8.641,19.92c0.336,2.102,0.304,4.426-1.44,4.56c-1.81,0.139-2.486-3.441-3.36-5.04
		c-1.56-2.855-3.256-4.231-4.319-5.76c-1.26-1.46-2.62-2.82-4.08-4.08c-5.234-5.965-15.81-6.591-23.76-9.84
		c-0.441,2.519-3.811,2.109-6,2.88c-2,0-4,0-6,0c-10.481-0.079-17.732-3.388-23.52-8.16c-1.07-1.25-2.42-2.22-3.6-3.36
		c0.423,1.097,1.098,1.942,1.92,2.64c2.596,3.283,6.461,6.063,9.6,8.64c0.931,0.764,2.955,1.563,2.64,3.12
		c-3.361-0.304-6.541-1.982-9.36-3.6c-2.832-1.625-5.381-3.799-7.68-5.76c-1.107-1.972-0.624,1.912-0.72,3.12
		c-0.998,13.843-7.224,24.775-8.64,37.92c-0.152,1.413,0.41,3.068-0.96,4.32c-1.9-2.179-1.612-6.547-2.4-9.84
		c-0.095,1.345-0.704,2.176-0.72,3.6c-0.52-0.041-0.108,0.851-0.24,1.2c-0.152,0.808,0.309,2.229-0.24,2.64c0,0.48,0,0.96,0,1.44
		c-2.048,5.631-7.317,8.042-8.4,14.64c-0.011,0.789-1.026,0.574-0.96,1.44c1.438,5.842,2.862,11.699,3.36,18.48
		c0.042,1.272,0.156,1.225,0,2.4c-1.063,5.234-3.582,10.627-1.92,16.08c0.78,2.558,3.198,5.596,5.04,8.64
		c2.235,3.693,4.1,7.062,6.24,9.12c0.459,0.661,1.019,1.221,1.68,1.68c1.212,1.268,2.29,2.67,3.6,3.84
		c2.836,3.804,5.405,7.875,7.92,12c0.782-0.738-0.424-3.464,0.72-3.84c1.98,1.059,3.219,8.15,4.32,9.84
		c0.338-1.262,0.361-2.839,1.68-3.12c1.439,1.281,0.701,4.74,1.44,6.72c0.075,2.162-0.11,5.05,0,6.48
		c0.149,0.714-0.39,2.91,0.48,2.16c0-0.08,0-0.16,0-0.24c0.57-0.39,0.382-1.538,1.68-1.2c0.268,1.333,1.265,1.935,1.44,3.36
		c0.157,1.363-0.316,3.355,0.24,4.32c-0.114,1.134,0.299,0.447,0.72,0.24c1.071-1.169,1.284-3.196,2.4-4.32
		c2.061-0.312,1.059,1.748,3.12,0.96c1.792,4.209,4.361,7.639,6.96,11.04c0.443-0.593-0.845-2.009,0.24-2.64
		c2.782,1.439,3.189,5.102,4.32,7.92c1.191,2.967,2.818,5.615,4.08,8.16c0.858-0.422,0.859-1.701,1.68-2.16
		c-0.371-0.349-0.211-1.229-0.24-1.92c2.071-4.188,3.973-9.445,5.04-14.64c0.409-1.988,1.198-4.88,3.36-5.52
		c1.17-0.05,0.965,1.275,0.96,2.4c1.366-1.594,1.173-4.747,4.32-4.56c0.441,0.688,0.768,2.469,0.24,3.36
		c4.435,0.002,11.369-4.032,17.28-2.88c5.37,1.047,2.925,7.343-0.239,9.12c-0.146,2.465,0.194,4.446,0.479,6.48
		c0.717,0.803,0.483,2.557,1.2,3.36c0.206-0.826,1.286-3.094,1.68-2.16c0.717-0.004-0.338-1.779,0.721-1.44
		c0.454,0.985,0.441,2.438,0.96,3.36c0.833-2.418,1.896-5.14,3.119-8.88c0.863-2.636,1.717-8.83,5.04-8.4
		c1.295,0.786,1.216,2.944,1.681,4.56c1.589-3.771-0.239-10.958,4.319-11.76c1.627-4.454,1.742-10.418,4.801-13.44
		c3.143-3.498,5.209-8.071,7.68-12.24c1.033-0.533,1.937,1.011,1.92,0C338.718,250.518,342.351,240.311,342.001,228.2z
		 M231.36,176.36c1.89,2.097,3.751,7.35,3.84,11.76c0.048,2.417,0.117,6.431-2.64,6.96c-2.154-1.619,0.162-4.652,0-7.2
		c0.398-0.729,0.398-3.112,0-3.84C232.637,181.004,231.146,179.535,231.36,176.36z M235.92,223.64
		c-0.281-0.039-0.203-0.438-0.24-0.72c-0.489,0.07-0.471,0.649-0.96,0.72c-1.425-0.044-2.834,0.59-4.08,0
		c-0.534-0.585-0.879-1.36-0.72-2.64c-1.301-0.102-2.458,1.026-4.08,0.96c-1.229-0.05-2.223-1.046-3.6-1.44
		c-2.077-0.595-5.073-0.026-5.76-1.92c5.631-0.923,12.594,4.772,14.64-1.92c-2.08-0.124-6.611,0.46-6.96-2.16
		c1.667-1.312,3.336,0.9,5.76,0.24c1.437-0.391,2.194-2.374,3.36-3.36c5.582-4.722,14.27-5.228,24.24-5.04c1.36,0,2.72,0,4.08,0
		c0.08,0,0.16,0,0.24,0c2.162,0.399,5.998,0.399,8.16,0c3.348-4.374,5.544,0.822,6.96,5.04c1.674,4.986,2.331,7.916,4.08,13.2
		c1.106,3.343,1.863,4.034,1.68,8.16c-0.103,2.313-0.486,5.075-0.96,7.44c-0.441,2.205-0.771,5.343-3.6,4.56
		c-1.612-3.738,0.008-9.65-0.48-13.92c-0.143-1.252-0.533-2.519-0.96-3.36c-0.727-1.433-3.479-4.838-4.8-4.8
		c-2.418,0.069-3.314,6.032-4.08,8.4c-1.24,3.837-3.048,6.688-3.6,8.88c-0.313,1.244,0.08,3.928-1.44,3.84
		c-1.571-0.091-0.719-3.123-0.72-3.12c0.45-3.111,2.458-7.466,3.12-9.84c2.043-7.331,0.916-14,3.12-19.92
		c-2.648,0.149-5.339,1.482-8.16,0.72c-2.881-0.778-5.815-3.932-9.84-3.12c-0.96,0-1.92,0-2.88,0c-2.609-0.189-6.81,0.055-8.64,1.44
		c-2.273,1.007-4.305,2.255-5.76,4.08c2.063-1.376,3.919-2.96,6.48-3.84c1.312-0.128,2.306-0.574,3.84-0.48
		c-0.044-0.204,0.094-0.227,0.24-0.24c0.88,0,1.76,0,2.64,0c0.204-0.044,0.227,0.093,0.24,0.24c6.754,1.379,13.886,4.8,17.76,9.36
		c-0.834,0.659-1.729-0.226-2.4-0.48c0.149,0.891,1.183,0.898,0.96,2.16c-2.284,1.716-4.371,3.629-7.92,4.08
		c-0.822,2.539-4.852,1.868-7.68,2.4c-0.577,0.671,1.797,0.433,1.2,1.92c-0.458,0.103-0.435,0.686-1.2,0.48
		c-0.259,0.397-2.141,0.397-2.4,0C240.57,229.188,234.66,227.999,235.92,223.64z M241.68,234.68c0.191-1.06,0.885-0.887,1.68-1.68
		c1.571-0.211,1.819,0.901,2.88,1.2c1.937-0.543,3.678-1.282,5.04-2.4c1.061-0.86,2.021-1.82,2.88-2.88
		c1.158-0.49,3.045-1.371,4.56-0.72c-0.275,1.805-1.458,2.702-0.96,5.28C257.833,236.267,240.618,240.562,241.68,234.68z
		 M320.641,280.521c-2.497-1.743-3.063-5.417-5.76-6.96c-0.452-0.127-1.111-1.264-1.44-0.72c0.658,0.622,1.06,1.5,1.68,2.16
		c1.964,3.396,3.625,7.095,3.841,12.24c-0.458,0.103-0.435,0.686-1.2,0.48c-0.307-0.013-0.333-0.308-0.721-0.24
		c-0.191,0.528-0.481,0.958-1.199,0.96c-1.071-0.77-1.801-1.879-2.88-2.64c-0.241-0.639-0.969-0.791-1.44-1.2
		c-0.14-0.095-0.294-0.176-0.436-0.269l-0.038-0.045l-0.092-0.035c-5.749-3.706-13.108-5.802-20.795-7.572
		c-1.216,3.358-5.981,1.891-11.76,2.16c-2.942,0.137-5.719,1.142-6-0.72c-0.698,0.262-1.695,0.225-2.16,0.72
		c-0.598,0.202-1.286,0.314-2.16,0.24c-0.329-0.307-0.329-1.133,0-1.44c-2.926,1.817-6.155,2.628-9.12,4.32
		c-2.763,1.576-5.019,4.83-8.88,4.32c1.052-4.246,4.015-7.701,6.72-11.04c2.777-3.428,5.815-8.037,8.88-10.56
		c0.057-0.743,0.668-0.932,0.96-1.44c-2.53-4.133-1.084-8.99,0.24-12.72c0.622-1.75,1.03-4.375,2.88-4.32
		c1.768,2.289-0.037,6.483,0,9.36c4.27-2.05,7.54-5.1,12.48-6.48c3.068,1.892,4.546,5.374,9.12,5.76
		c3.962,0.202,6.803-0.717,7.68-3.6c0.402-1.038,1.035-1.845,2.16-2.16c0.32-0.241,0.725-0.396,0.96-0.72
		c0.632-0.249,1.473-0.287,2.4-0.24c2.213,1.377,2.052,4.966,3.6,6.96c0.481,0.62,1.563,0.341,1.44,1.44
		c-0.496,3.424-3.431,4.41-4.561,7.2c0.957,0.883,1.815,1.865,2.881,2.64c4.566,4.553,12.334,5.906,13.92,13.44
		C321.274,279.875,321.394,280.634,320.641,280.521z M330.961,220.04c-1.439-0.159-1.777,0.783-3.36,0.48
		c-0.04,0.52,0.852,0.108,1.2,0.24c-0.457,0.469-0.104,2.6-0.96,1.92c0.134,0.346,0.374,0.586,0.72,0.72
		c-0.658,4.782-4.209,6.671-9.12,7.2c-0.887,0.399-3.433,0.399-4.32,0c-3.385-0.535-6.25-1.589-8.88-2.88
		c1.938,4.783,6.499,6.941,11.76,8.4c1.521,0,3.04,0,4.561,0c2.385-0.175,3.904-1.215,5.52-2.16c0.628-0.092,0.671-0.77,1.681-0.48
		c0,0.56,0,1.12,0,1.68c-2.724,1.437-5.49,2.831-8.88,3.6c-1.12,0-2.24,0-3.36,0c-3.852,0.093-6.348-2.39-9.36-3.84
		c-0.699-0.9-1.499-1.701-2.399-2.4c-1.519-2.242-3.292-4.228-5.04-6.24c-0.558-0.716-1.107-2.473-0.48-3.6
		c6.009-0.52,7.989,4.105,12.96,3.84c-0.139-0.779,0.164-1.117,0.24-1.68c-2.178-0.462-4.653-0.627-5.76-2.16
		c-1.036-1.844-4.106-1.653-4.561-4.08c-1.052-0.311-2.247,0.613-2.399-0.24c0.354-1.566,1.239-2.601,1.92-3.84
		c0.092-0.813-1.063-0.378-0.72-1.44c2.38-0.739,4.524-1.715,5.52-3.84c0.319-0.491-2.261-0.129-3.12-0.24c-0.08,0-0.16,0-0.24,0
		c-1.125-0.399-3.914-0.399-5.04,0c-0.946,0.182-1.999,1.238-3.119,0.48c-0.136-2.456,1.883-2.757,3.84-3.12c1.359,0,2.72,0,4.08,0
		c0.479,0,0.96,0,1.439,0c2.481,0.4,6.639,0.4,9.12,0c7.94-1.03,14.16,3.036,15.84,8.88c0.155,0.539-0.003,3.267,1.681,2.64
		c0.573-0.146,1.741,0.301,1.92-0.24c1.332,0.285,2.293-1.056,3.359-0.24C336.908,220.068,333.426,219.545,330.961,220.04z"/>
		</g>
		<g id="lighting">
			<g>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M328.561,309.081c1.23-6.049,3.825-10.735,6.479-15.36
			c0.352-5.208-1.432-11.153-0.96-17.28c0.315-4.094,1.49-7.752,0.48-11.76c-3.072,7.728-9.991,11.609-9.84,22.56
			c0.345,0.435,0.519,1.04,0.643,1.697c0.023,0.153,0.051,0.309,0.078,0.463c0-0.003-0.001-0.006-0.002-0.01
			c0.162,1.042,0.263,2.145,0.721,2.89c-0.008,1.048,0.209,1.872,0.48,2.64c0.229,1.291,0.298,2.742,0.24,4.32c0,0.56,0,1.12,0,1.68
			c-0.556,0.964-0.084,2.957-0.24,4.32c0,0.24,0,0.48,0,0.72c-0.55,0.411-0.088,1.832-0.24,2.64
			C327.632,308.051,328.324,310.188,328.561,309.081z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M336.48,260.84c2.237-10.323,5.87-20.53,5.521-32.64
			c-1.069-4.372-2.339-8.542-2.641-13.68c0-1.76,0-3.52,0-5.28c0.541-3.459,1.128-6.872,1.44-10.56
			c0.541-0.179,0.094-1.346,0.24-1.92c0.549-0.411,0.088-1.832,0.239-2.64c0-0.08,0-0.16,0-0.24c0-0.88,0-1.76,0-2.64
			c0-2.72,0-5.44,0-8.16c0-0.08,0-0.16,0-0.24c-0.08,0-0.16,0-0.239,0c0-0.08,0-0.16,0-0.24c0-1.12,0-2.24,0-3.36
			c0.013-0.307,0.308-0.333,0.239-0.72c0.194-0.045,0.239-0.241,0.48-0.24c0.009-7.891-2.565-14.065-2.4-21.36
			c-0.43-3.01-1.094-5.786-1.92-8.4c-0.291-0.909-0.465-1.936-0.96-2.64c-0.427-0.933-0.583-2.137-1.2-2.88
			c-0.004-0.716-0.624-0.816-0.72-1.44c-0.279,0.041-0.261,0.379-0.24,0.72c0.025,0.615,0.136,1.144,0.48,1.44c0,0.08,0,0.16,0,0.24
			c-0.323,2.182,2.952,3.166,0.96,4.8c-0.733,0.173-0.875-0.246-1.44-0.24c-0.053-0.187-0.201-0.279-0.479-0.24
			c-1.071,0.529-0.196,3.004-0.48,4.32c0,1.36,0,2.72,0,4.08c-0.856,1.818-2.408,4.265-1.2,6.72c0,0.08,0,0.16,0,0.24
			c0.16,6.24-0.319,13.12,0.24,18.96c0,1.04,0,2.08,0,3.12c0.159,2.801-0.318,6.239,0.24,8.64c-0.004,1.044-0.079,2.159,0.24,2.88
			c0.152,0.887-0.312,2.391,0.239,2.88c0.05,0.751-0.161,1.762,0.24,2.16c-0.188,0.988,0.368,1.231,0.24,2.16
			c0.003,0.477,0.654,0.305,0.479,0.96c0.453,0.347,1.064,0.536,0.96,1.44c-0.697,3.062-1.785,5.735-2.64,8.64
			c0.151,0.994-2.829,2.183-0.479,2.4c0.573-0.146,1.741,0.301,1.92-0.24c1.332,0.285,2.293-1.056,3.359-0.24
			c-0.292,2.667-3.774,2.145-6.239,2.64c-0.041,0.28-0.38,0.261-0.721,0.24c0.018,1.833,1.881,3.688,1.44,5.52
			c-0.332,1.379-1.215,0.844-1.92,2.16c-0.685,1.897-2.16,4.21-2.16,6c0.16,0,0.32,0,0.479,0c0.628-0.092,0.671-0.77,1.681-0.48
			c-0.044-0.204,0.094-0.227,0.239-0.24c-0.133,1.979,1.366-0.872,1.921-1.2c0.47-0.89,1.177-1.543,1.439-2.64
			c1.529,1.351,1.987,3.772,3.12,5.52c-0.7,3.06-1.262,6.258-1.68,9.6c-1.91,0.25-2.796,1.524-3.36,3.12c0,0.08,0,0.16,0,0.24
			c-0.536,0.664-0.934,1.466-1.2,2.4c-0.604,1.315-1.277,2.563-1.68,4.08c-0.774,1.146-1.414,2.426-1.92,3.84
			c-1.882,3.739-6.304,11.563-0.48,14.16c0.135,0.346,0.375,0.585,0.721,0.72c3.143-3.498,5.209-8.071,7.68-12.24
			C335.594,260.307,336.497,261.852,336.48,260.84z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M317.04,312.681c0.556-1.285,0.805-2.876,0.96-4.56
			c0-0.08,0-0.16,0-0.24c0.364-0.596,0.464-1.457,0.48-2.4c-2.767-0.513-2.714-3.846-3.6-6.24c0.074,0.955-0.555,1.206-0.48,2.16
			c-0.039,0.279,0.053,0.427,0.24,0.48c-0.077,0.557,0.038,0.921,0.24,1.2c0.564,2.075,1.136,4.144,1.68,6.24c0,0.08,0,0.16,0,0.24
			C316.61,310.71,316.322,312.199,317.04,312.681z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M328.483,139.65c-0.064-0.2-0.126-0.399-0.189-0.593
			c0.192,0.591,0.383,1.224,0.64,1.817c0,0-0.001-0.001-0.001-0.002c0.199,0.546,0.405,1.085,0.588,1.648
			c0.849,0.112,0.844,1.076,1.92,0.96c0.04-0.839-0.063-1.536-0.479-1.92c-0.274-2.206-0.533-4.427-1.44-6
			c-0.334-1.187-0.893-2.147-1.2-3.36c0.028-1.228-0.709-1.691-0.96-2.64c-0.694-1.465-1.446-2.873-2.16-4.32
			c-1.404-2.275-2.812-4.548-4.56-6.48c0-0.08,0-0.16,0-0.24c-0.64-0.24-0.791-0.969-1.2-1.44c-1.291-1.27-2.808-2.313-4.08-3.6
			c-0.641-0.879-1.85-1.19-2.64-1.92c2.683,3.717,5.322,7.477,8.16,11.04C323.06,128.64,326.17,133.746,328.483,139.65z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M292.32,212.84c0,0.64,0,1.28,0,1.92
			c0.152,0.808-0.31,2.229,0.24,2.64c0,0.32,0,0.64,0,0.96c0.15,0.729-0.308,2.067,0.24,2.4c0.606,8.354,3.446,14.473,5.52,21.36
			c-0.397,1.602-1.345,2.655-1.439,4.56c-0.712,1.689-1.878,2.923-2.881,4.32c0,0.08,0,0.16,0,0.24
			c-1.033,0.726-1.827,1.693-2.399,2.88c-0.127,0.193-0.251,0.39-0.24,0.72c3.962,0.202,6.803-0.717,7.68-3.6
			c0.402-1.038,1.035-1.845,2.16-2.16c0-0.16,0-0.32,0-0.48c0.386-0.415,0.116-1.484,0.721-1.68
			c-0.019,0.418,0.053,0.746,0.239,0.96c0,0.16,0,0.32,0,0.48c0.632-0.249,1.473-0.287,2.4-0.24c2.213,1.377,2.052,4.966,3.6,6.96
			c0.481,0.62,1.563,0.341,1.44,1.44c0.395-0.033,0.395-1.647,0-1.68c-1.373-4.627-1.486-10.514-4.32-13.68
			c-0.302-1.217-1.301-1.738-1.92-2.64c-1.083-3.717-2.19-7.41-2.64-11.76c0-0.16,0-0.32,0-0.48c-0.558-0.716-1.107-2.473-0.48-3.6
			c-0.013-1.107,0.106-2.346-0.24-3.12c-0.152-0.887,0.312-2.391-0.239-2.88c0-0.4,0-0.8,0-1.2c0.097-1.217-0.523-1.717-1.2-2.16
			c-0.186-1.146,0.375-1.545,0.24-2.64c0.687-0.033,0.438-1.001,0.479-1.68c-0.08,0-0.16,0-0.24,0
			c-0.946,0.182-1.999,1.238-3.119,0.48c-0.136-2.456,1.883-2.757,3.84-3.12c0.885-0.555,2.796-0.083,4.08-0.24
			c0,0.08,0,0.16,0,0.24c0.479,0,0.96,0,1.439,0c0.24,0,0.48,0,0.72,0c-0.295-0.344-0.825-0.455-1.439-0.48
			c-3.086-0.914-5.938-2.063-8.64-3.36c-2.074,1.127-3.638,2.763-4.801,4.8c0,0.08,0,0.16,0,0.24
			C289.672,209.214,290.793,212.077,292.32,212.84z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M345.36,226.521c0.155,1.045-0.313,2.713,0.24,3.36
			c0.868-3.084,2.246-6.714,3.84-10.32c1.662-3.76,4.562-12.046,0.72-14.16c0,0.08,0,0.16,0,0.24
			c-1.315,7.165-2.682,14.278-4.56,20.88C345.521,226.521,345.44,226.521,345.36,226.521z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M350.036,225.727
			C350.036,225.727,350.036,225.727,350.036,225.727c-0.505,1.708-1.298,3.485-1.862,5.244
			C348.735,229.214,349.53,227.435,350.036,225.727c-1.777,3.461-3.218,8.013-3.476,12.073c1.098-4.662,3.635-7.885,4.08-13.2
			c-0.165,0.287-0.325,0.6-0.485,0.906v0c0,0.005-0.001,0.01-0.001,0.014c0-0.005,0.001-0.01,0.001-0.015
			c-0.002-0.062,0.009-0.123,0.005-0.186C350.124,225.455,350.076,225.592,350.036,225.727z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M342.001,248.36c-1.598,6.482-3.378,12.782-4.08,20.16
			c0.563,0.715,1.097,1.463,1.92,1.92c0.807-0.633,1.526-1.354,2.16-2.16C341.668,261.467,342.581,254.622,342.001,248.36z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M306.961,267.8c2.061,4.739,4.915,10.764,7.199,16.08
			c0.993,0.24,2.668,3.882,2.88,1.2c-0.131-0.349,0.281-1.241-0.239-1.2c-0.671-4.849-3.077-7.963-5.521-11.04
			C310.107,270.893,308.788,269.092,306.961,267.8z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M303.36,321.8c1.016-0.824,0.824-2.855,1.2-4.32
			c0.194,0.045,0.239,0.241,0.479,0.24c0.483-0.718,0.136-2.265,0.24-3.36c-0.389-1.014-0.758-2.049-1.406-2.803
			c0.228,0.548,0.481,1.07,0.702,1.625c-0.221-0.555-0.475-1.077-0.703-1.626c0.001,0,0.001,0,0.001,0
			c-0.011-0.026-0.022-0.051-0.033-0.077c-0.012,0.002-0.021,0-0.032,0.001c-0.071-0.078-0.129-0.17-0.208-0.241
			c-0.078-0.011-0.163,0.014-0.24,0c-0.904-0.161-1.51-0.852-1.979-1.776c-0.205-0.552-0.421-1.092-0.649-1.62
			c0,0-0.001-0.001-0.001-0.002c0,0,0,0.001,0,0.001c0-0.001-0.001-0.002-0.001-0.003c0,0,0.001,0.001,0.001,0.002
			c-0.676-2.051-1.146-4.413-2.411-5.241c-1.388,0.188-2.052-0.348-3.36-0.24c-0.201,0.598-0.313,1.286-0.239,2.16
			c0.004,0.795,1.356,0.244,1.68,0.72c1.342,7.539,0.769,16.992,4.8,21.84c0.081-0.246,0.144-0.498,0.202-0.751
			c0.014,0.009,0.025,0.022,0.038,0.031c0.003-0.065,0.002-0.127,0.004-0.192c0.974-4.602-1.505-10.404-1.684-15.408
			C301.05,314.352,301.991,318.29,303.36,321.8z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M307.584,346.957c0.048-0.054,0.095-0.108,0.143-0.163
			c0,0-0.001,0.001-0.001,0.001c0.005-0.005,0.01-0.011,0.015-0.017c0.504-0.577,1.007-1.158,1.498-1.739
			c0,0,0.001-0.001,0.001-0.001c0.49-0.581,0.963-1.157,1.408-1.726c0.004-0.005,0.009-0.011,0.013-0.016
			c0.005-0.006,0.01-0.013,0.015-0.019c-0.005,0.005-0.009,0.011-0.013,0.016c1.012-1.14,1.964-2.287,2.538-3.495
			c2.331-4.904,1.696-12.009,3.36-18.96c-0.199-0.626,0.726-1.316,0-1.68c-0.063,0.737-0.521,1.079-0.72,1.68
			c-1.044,2.375-2.408,5.116-3.841,8.16c-1.482,3.154-2.732,7.899-5.52,8.64c0,0.16,0,0.32,0,0.48
			c-0.259,5.821-2.127,10.033-3.6,14.64c-0.28,0.04-0.261,0.379-0.24,0.72c1.82-1.761,3.38-4.3,5.085-6.685
			C307.679,346.85,307.632,346.903,307.584,346.957z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M316.801,334.041c-0.124,8.357-5.539,11.421-8.641,16.8
			c-0.106,0.472-0.89,1.484-0.239,1.92c0.979,0.78,2.702,0.817,3.84,1.44c0.503,1.896,0.12,4.679,0.239,6.96c0,0.72,0,1.44,0,2.16
			c-0.383,11.423,0.56,21.521,0.721,32.4c0.151,0.808-0.31,2.229,0.24,2.64c1.572-2.507,1.437-6.723,3.359-8.88
			c0.491-11.316,3.098-22.919,2.16-34.08C320.268,348.608,319.299,339.311,316.801,334.041z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M370.32,448.041c4.717,10.803,11.411,19.629,13.44,33.12
			c-0.171-3.146,0.228-6.646,0-9.601c-0.632-8.205-4.889-19.911-8.88-24.72c-0.576-0.693-1.884-1.314-2.16-2.16
			c-0.673-2.059,0.257-6.7-0.24-9.36c-0.321-1.718-0.115-2.737,0-4.56c0.385-6.077-1.292-10.432-2.4-15.12
			c-2.146-9.083-6.011-17.14-9.359-24.72c-0.054-0.186-0.007-0.473-0.24-0.48c-1.33,0.673-2.492,2.784-1.68,4.8
			c1.287,3.193,1.58,7.379,2.399,11.04c0,0.16,0,0.32,0,0.48c0.519,14.679-1.085,27.234-3.12,39.36
			c-0.944,0.575-1.852,1.188-2.159,2.399c0.08,0,0.159,0,0.239,0C360.76,448.24,365.354,447.955,370.32,448.041z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M353.521,379.881c-11.872-5.568-20.832-14.048-31.68-20.64
			c0.02,2.98-0.556,5.364-0.48,8.4c9.201,5.519,17.813,11.627,30,14.16c1.011,0.349,1.906,0.814,3.12,0.96
			c0.388,0.492,1.155,0.605,1.68,0.96C355.389,382.332,353.854,381.707,353.521,379.881z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M411.601,428.121c-1.536-5.236-4.148-10.32-7.2-15.121
			c-2.868-4.511-5.533-9.902-9.359-13.2c-1.929-1.663-4.433-3.099-6.721-4.8c-7.522-5.594-16.676-10.079-27.359-12
			c0.362,1.4,2.225,3.374,2.88,5.28c0.08,0,0.16,0,0.239,0c0.815-0.065,1.946,0.186,2.4-0.24c0.88,0,1.76,0,2.64,0
			c0.96-0.08,1.333,0.428,2.4,0.24c1.325,0.515,2.97,0.71,4.32,1.2c0.08,0,0.16,0,0.24,0c5.334,2.265,9.172,6.028,12.96,9.84
			c0,0.08,0,0.16,0,0.24c3.126,8.474,5.761,17.438,7.92,26.88c0,0.08,0,0.16,0,0.24c-0.188,0.988,0.368,1.231,0.239,2.16
			C401.739,428.34,407.018,428.577,411.601,428.121z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M181.68,429.801c0.98,0.779,1.86,1.659,2.64,2.64
			c0.332,0.709,1.108,0.972,1.44,1.681c0.578-0.099,0.521,0.438,1.2,0.239c5.115,0.726,9.437,2.244,13.92,3.601
			c0.799,0.17,1.868,1.319,2.64,0.72c-0.302-0.817-1.583-0.657-1.92-1.44c-4.221-12.499-9.715-23.725-15.6-34.56
			c-0.16,0-0.32,0-0.48,0c-0.08,0-0.16,0-0.24,0c-0.661-0.779-1.307-1.573-2.4-1.92c-0.964,0.316-1.284,1.276-1.92,1.92
			c0,0.08,0,0.16,0,0.24c-4.098,4.942-8.691,9.389-13.68,13.439C173.027,419.894,177.413,424.788,181.68,429.801z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M287.28,444.681c0.72,0,1.44,0,2.16,0
			c0.198-0.618,0.352-1.281,0.486-1.962v0.001c0.084-0.309,0.141-0.645,0.175-1.001c0,0.003,0,0.007,0,0.01
			c0.574-3.66,0.529-7.941,1.019-11.688c-0.722-0.238-1.07-0.85-2.16-0.721c0,1.12,0,2.24,0,3.36c0,0.16,0,0.32,0,0.48
			c-0.53,0.029-0.102,1.018-0.24,1.439c0,0.64,0,1.28,0,1.92c-0.038,0.282,0.042,0.682-0.24,0.72
			c-0.002,1.998-1.003,2.997-0.96,5.04c-0.545,0.256-0.091,1.509-0.24,2.16C287.28,444.521,287.28,444.601,287.28,444.681z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M488.161,646.761c0.006-0.407,0.007-0.813,0.011-1.219
			C488.141,645.977,488.098,646.422,488.161,646.761z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M484.081,605.241c-2.76-9.846-6.55-18.787-10.561-28.08
			c-6.744-15.628-14.466-30.891-21.359-46.32c-0.284-0.636-0.613-1.286-0.951-1.938c-0.219-0.475-0.441-0.951-0.669-1.432
			c-0.546-1.161-1.096-2.351-1.561-3.63c0,0,0,0,0,0.001c-0.142-0.39-0.273-0.787-0.396-1.194c0.123,0.406,0.254,0.805,0.396,1.193
			c-0.501-2.134,0.044-4.288-1.38-5.96c-0.365-0.072-1.743,0.913-2.88,0.24c-0.652-3.559-2.731-4.617-4.56-7.2
			c-3.63-5.128-5.284-13.729-8.641-18.24c-5.81-1.649-6.114-6.844-7.2-12.96c-2.933-16.521-6.875-33.673-11.52-48
			c-4.025-0.025-7.955,0.046-11.521,0.48c0.422,0.138,1.41-0.291,1.44,0.239c0.838,0.122,1.812,0.108,2.4,0.48
			c3.48,11.879,9.484,21.235,11.52,34.56c0.003,0.638-0.091,1.371,0.24,1.681c0.293,5.787,1.204,10.955,2.399,15.84
			c0.08,0,0.16,0,0.24,0c0,0.16,0,0.32,0,0.479c0,0.96,0,1.92,0,2.881c0,2.479,0,4.96,0,7.439c-0.08,0-0.16,0-0.24,0
			c0.024,0.744-0.108,1.331-0.479,1.68c-0.165,0.966,0.229,1.37,0.24,2.16c0.546,0.334,0.93,0.83,1.439,1.2
			c1.218,0.018,2.457,0.057,3.12-0.479c0.08,0,0.16,0,0.24,0c3.394-0.367,5.546-1.974,8.16-3.12
			c0.106,0.213,0.211,0.429,0.479,0.479c0.952,2.648,2.417,4.784,3.12,7.68c0,0.08,0,0.16,0,0.24
			c-0.001,0.241,0.194,0.286,0.24,0.48c0.54,0.819,1.211,1.509,1.439,2.64c0.063,1.217,0.251,2.31,0.48,3.36
			c0.398,0.808,0.398,3.272,0,4.08c0,0.16,0,0.319,0,0.479c0,0.641,0,1.28,0,1.92c-0.188,0.054-0.279,0.201-0.24,0.48
			c-0.674,3.376-0.634,6.709-1.92,9.6c-0.552,1.242-1.961,2.11-1.68,3.84c0.2,1.234,1.204,2.249,3.12,1.921
			c1.541-0.265,2.255-2.598,4.08-3.841c1.973-1.344,3.584-1.936,4.56-2.64c2.238,13.202,5.638,25.242,8.64,37.68
			c-2.301,1.139-4.57,2.31-6.72,3.601c0.039,0.279-0.053,0.427-0.24,0.479c-0.248,1.432-1.577,1.783-2.399,2.641
			c-2.537,1.383-4.363,3.477-6.721,5.04c-0.74,0.539-1.38,1.179-1.92,1.92c0.675-0.446,1.115-1.126,1.92-1.44
			c0.146-0.414,1.139,0.019,1.2-0.479c1.018,0.057,1.699-0.221,2.4-0.48c2.231-0.408,4.438-0.841,6.479-1.439
			c2.51-0.131,4.49-0.79,6.48-1.44c3.231-0.667,6.787-0.025,8.4,1.68c0.904,0.375,1.545,1.016,1.92,1.92
			c0.73,0.149,0.469,1.291,1.199,1.44c0,0.08,0,0.16,0,0.24c0.44,1.4,0.899,2.781,0.721,4.8c0.283,0.437,0.548,0.891,0.815,1.344
			c0.437,0.985,1.004,1.963,1.611,2.927c2.061,4.028,3.829,8.347,6.213,12.05c0,0.08,0,0.16,0,0.239
			c0.451,1.15,0.878,2.327,1.308,3.499c0-0.002-0.001-0.003-0.001-0.004c0.001,0.003,0.002,0.006,0.003,0.009
			c-0.001-0.001-0.001-0.003-0.002-0.005c0.001,0.004,0.003,0.008,0.004,0.012c0.151,0.479,0.312,0.953,0.482,1.423
			c0.002,0.006,0.004,0.012,0.007,0.018c0.17,0.468,0.347,0.935,0.529,1.398c0.001,0.001,0.001,0.002,0.001,0.003
			c0.003,0.009,0.007,0.017,0.01,0.025c-0.003-0.008-0.006-0.015-0.009-0.022c4.045,11.62,7.157,24.172,9.908,37.085
			c0.164,0.123,0.232,0.341,0.259,0.601C488.281,630.146,487.16,616.227,484.081,605.241z M445.921,525.081
			c-0.307,1.054-0.886,1.835-1.44,2.64c-0.771,0.801-1.914,2.336-3.6,1.44c0.257-2.304,2.242-2.878,2.88-4.8
			c0.439-0.761,0.813-1.588,1.92-1.681C446.106,523.135,445.855,524.266,445.921,525.081z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M488.18,644.722c-0.002,0.273-0.005,0.547-0.008,0.82
			C488.194,645.25,488.205,644.967,488.18,644.722z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M290.4,450.201c-0.32,0-0.64,0-0.96,0
			c-0.038,0.758-0.009,1.448,0.479,1.68c0,0.08,0,0.16,0,0.24c0.281,0.759,0.439,1.641,0.961,2.16
			C290.814,452.826,291.139,450.982,290.4,450.201z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M292.32,460.281c-0.727,0.619-1.724,2.024-0.96,3.359
			c1.372,0.173,2.144-0.256,3.36-0.24c0.141-1.341-0.55-1.85-0.48-3.119C293.601,460.281,292.96,460.281,292.32,460.281z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M205.2,553.641c0.053,0.188,0.201,0.279,0.48,0.24
			c2.583,1.258,4.846,2.834,7.44,4.08c0.158,0.322,0.513,0.447,0.96,0.48c-4.137-7.579-8.149-19.873-11.28-30.24
			c-1.167-3.863-1.628-7.947-2.88-11.28c-0.741-1.972-2.379-3.629-2.88-5.521c-0.817-3.087,0.472-4.765,1.435-6.687
			c-0.212,0.174-0.409,0.37-0.59,0.586c0.181-0.216,0.378-0.413,0.591-0.587c0,0.001,0,0.001,0,0.001
			c0.25-0.205,0.521-0.376,0.811-0.513c0.291,0,0.583,0,0.874,0c0.585-4.146-0.042-7.012,1.2-9.84c0.784-1.787,3.194-3.285,2.4-6.48
			c-0.684-0.356-1.799-0.28-2.16-0.96c-2.82-10.78-8.004-20.476-10.56-30.72c0.08,0,0.16,0,0.24,0
			c-2.025-3.415-4.599-6.281-7.44-8.881c-0.402-0.317-0.804-0.636-1.68-0.479c-0.213,0.427-0.67,0.61-0.48,1.44
			c2.471,6.408,5.792,11.968,8.4,18.239c-0.076,0.557,0.039,0.922,0.24,1.2c0.421,1.898,0.988,3.651,1.92,5.04
			c0.2,1.48,0.902,2.458,1.2,3.84c-0.01,0.87-1.273,0.487-1.68,0.96c-0.068,0.388,0.227,0.413,0.24,0.721
			c0.671,0.688,1.065,1.654,1.92,2.159c0.051,0.27,0.266,0.374,0.48,0.48c1.073,1.087,1.879,2.44,3.12,3.36
			c0.359,0.36,0.43,1.01,0.96,1.199c0.558,1.122,1.351,2.01,1.92,3.12c0.283,1.477,1.485,2.034,1.2,4.08
			c-0.093,0.656-1.015,1.117-1.44,0.72c-1.035-0.085-0.095-2.145-0.96-2.399c-0.998-2.522-2.365-4.675-4.08-6.48
			c-0.248-0.632-0.952-0.808-1.2-1.439c-0.7-0.9-1.5-1.701-2.4-2.4c0-0.08,0-0.16,0-0.24c-1.203,0.176,0.079,1.343,0,1.92
			c-0.607,0.193-0.612,0.988-0.96,1.44c0,0.08,0,0.16,0,0.24c-0.747,1.013-1.304,2.216-1.44,3.84c0,0.08,0,0.16,0,0.24
			c-0.837,1.643-1.05,3.909-1.68,5.76c-2.956-2.564-6.163-4.877-11.04-5.52c-0.08,0-0.16,0-0.24,0
			c0.044-0.205-0.094-0.227-0.24-0.24c-0.317,0-0.634,0-0.952,0c-0.292-0.027-0.567-0.027-0.826,0c-0.368,0-0.735,0-1.103,0
			c-0.69,0.27-1.416,0.504-1.92,0.96c-0.58,0.996-0.58,2.363,0,3.359c0.679,0.361,1.109,0.971,2.16,0.96c0.08,0,0.16,0,0.24,0
			c0.167,0.633,1.093,0.508,1.2,1.2c1.42,1.62,2.94,3.141,4.56,4.561c0.64,0.24,0.791,0.969,1.2,1.439
			c1.085,0.755,1.868,1.813,2.64,2.88c1.519,2.788,5.973,4.976,5.28,8.4c-0.08,0-0.16,0-0.24,0c-0.111,1.169-1.051,1.509-1.2,2.64
			c0.063,0.39,0.145,0.78,0.237,1.172c-0.005-0.153-0.009-0.306,0.003-0.451c-0.012,0.146-0.008,0.299-0.003,0.452
			c0,0.001,0,0.001,0,0.002c0.006,0.149,0.022,0.304,0.043,0.46c0.003,0.028,0.006,0.056,0.01,0.084
			c0.022,0.147,0.052,0.298,0.086,0.45c0.009,0.04,0.018,0.08,0.027,0.121c0.036,0.145,0.076,0.292,0.121,0.441
			c0.016,0.052,0.032,0.104,0.049,0.156c0.046,0.142,0.094,0.284,0.147,0.428c0.023,0.064,0.049,0.128,0.074,0.192
			c0.053,0.136,0.107,0.271,0.166,0.409c0.034,0.08,0.07,0.16,0.106,0.239c0.056,0.125,0.111,0.249,0.17,0.375
			c0.05,0.105,0.104,0.212,0.157,0.318c0.05,0.102,0.099,0.202,0.151,0.304c0.106,0.207,0.217,0.414,0.331,0.62
			c2.202,4.809,5.652,9.506,7.723,13.427c3.394,6.428,6.25,12.389,8.16,19.681C205,553.362,205.147,553.454,205.2,553.641z
			 M197.362,506.052c-0.277,0.479-0.492,1.018-0.639,1.591C196.87,507.068,197.085,506.532,197.362,506.052z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M426.24,574.521c-0.731,3.112-1.014,6.538-0.72,10.079
			c0.111,1.446-1.272,3.5,0,4.561c-0.034-5.715,2.316-9.044,3.601-13.44c-0.857-0.743-1.829-1.371-2.641-2.16
			C426.294,573.774,426.223,574.104,426.24,574.521z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M426.721,559.161c-13.393-0.048-17.363-9.517-22.32-18
			c-1.327-0.054-2.877-0.547-4.08,0c-0.151,0.968-1.028,1.211-0.96,2.399c0,0.08,0,0.16,0,0.24
			c-0.514,0.846-0.503,2.217-0.479,3.601c0,0.72,0,1.439,0,2.159c-0.369,1.254-0.686,4.522,0.72,4.801
			c1.306,0.693,2.21,1.79,3.6,2.399c0.72,0.96,1.79,1.57,2.641,2.4c1.787,1.013,2.425,3.175,5.28,3.12
			c0.492,0.398,2.627,0.398,3.119,0c2.545-0.176,4.652-0.788,6.961-1.2c0.824-0.025,1.724-0.124,1.92,0.479
			c-0.128,2.079,0.769,3.227,1.439,5.04c-0.004,0.885,0.699,1.062,1.2,1.44C425.42,564.502,427.056,561.925,426.721,559.161z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M243.6,594.201c0.881,0.719,1.739,1.461,2.88,1.92
			c0.735,0.785,1.828,1.212,2.88,1.68c0.024,0.616,0.895,0.386,1.2,0.721c-1.062-1.98-4.1-4.061-5.76-6.24
			c-0.217-0.424-0.537-0.743-0.96-0.96c-0.913-1.007-1.672-2.168-2.64-3.12c-0.562-1.038-1.198-2.002-1.92-2.88
			c-1.52-3.12-2.8-6.479-4.32-9.601c-1.585,4.493,1.481,8.418,2.88,11.521C238.969,590.353,242.412,591.149,243.6,594.201z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M373.44,583.401c-1.835-0.566-4.982-0.772-6.72-0.721
			c3.055,3.078,6.829,4.886,9.84,7.92c4.636,4.674,7.024,11.201,10.8,16.801c-0.058,1.257,0.584,1.815,0.721,2.88
			c-0.015,1.054,0.096,1.983,0.479,2.64c0,0.08,0,0.16,0,0.24c-0.029,1.148,0.068,2.172,0.48,2.88c0,0.08,0,0.16,0,0.24
			c0.108,1.171,0.347,2.213,0.72,3.12c0,0.16,0,0.319,0,0.479c-0.035,1.075,0.025,2.055,0.479,2.641c0,0.079,0,0.16,0,0.239
			c0.163,1.438,0.209,2.992,0.721,4.08c-0.178,2.098,0.704,3.136,0.479,5.28c0.552,0.568,0.086,2.154,0.24,3.12c0,1.2,0,2.399,0,3.6
			c0,0.08,0,0.16,0,0.24c-0.08,0-0.16,0-0.24,0c0,1.2,0,2.4,0,3.601c0,0.399,0,0.8,0,1.199c0,0.24,0,0.48,0,0.721
			c0,0.319,0,0.64,0,0.96c0.402,0.397,0.191,1.409,0.24,2.159c1.87-4.189,2.199-11.136,1.92-17.279
			C392.708,610.801,388.109,587.923,373.44,583.401z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M258.96,621.801c0.102-0.501-0.165-0.635-0.24-0.96
			c-0.014-0.146-0.036-0.284-0.24-0.239c-5.308-6.372-13.5-9.861-20.4-14.641c-0.384-0.415-0.896-0.703-1.44-0.96
			c2.375,4.105,6.083,6.878,9.6,9.84c1.56,0.841,2.888,1.912,4.56,2.641c1.418,1.381,2.095,3.504,4.08,4.319
			c0.502,0.139,0.685,0.596,1.44,0.48C257.083,622.95,258.467,622.198,258.96,621.801z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M328.561,109.16c0.122,0.838,0.107,1.812,0.479,2.4
			c0.003,0.638-0.09,1.371,0.24,1.68c0.412,3.243,2.578,6.372,4.32,9.12c1.61,2.54,4.079,4.503,5.28,6.72
			c1.785,3.298,1.735,7.338,3.84,11.04c0,0.08,0,0.16,0,0.24c1.072,3.913,0.229,9.095,0,13.2c0,0.08,0,0.16,0,0.24
			c-0.367,1.157-0.423,3.891,0,5.04c-0.212,3.332,1.163,5.076,1.2,8.16c-0.261,0.06-0.163,0.478-0.48,0.48
			c0.511,3.889,1.107,7.693,1.2,12c1.068-5.682-0.141-12.943-0.48-18.96c-0.137-2.423-0.4-6.885,2.16-6.72
			c0.407,2.673-0.817,6.349,0.48,8.88c2.435-11.571,2.078-22.916-1.44-31.68c-1.595-3.972-4.231-6.647-6.479-10.32
			c-5.977-9.765-9.941-21.507-18.48-27.84c2.298,2.342,3.803,5.478,5.521,8.4C326.714,103.966,327.918,106.283,328.561,109.16z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M322.561,150.2c-0.082-2.798-0.968-4.792-1.68-6.96
			c-0.431-1.25-1.109-2.251-1.681-3.36c-0.546-0.893-1.051-1.829-1.68-2.64c-0.61-1.07-1.43-1.931-2.16-2.88
			c-0.693-0.587-1.367-1.193-2.399-1.44c0.729,4.716,4.598,8.357,6.239,12.96c0.135,0.345,0.375,0.585,0.721,0.72
			C320.968,147.633,321.354,149.328,322.561,150.2z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M304.32,153.32c0.07,0.489,0.649,0.471,0.72,0.96
			c0.741,0.939,1.622,1.739,2.4,2.64c0.538,0.742,0.971,1.59,1.68,2.16c-0.376-1.064-0.525-2.354-1.199-3.12
			c-0.979-3.102-2.49-5.67-4.08-8.16c-0.645-1.276-1.457-2.384-2.4-3.36c-1.707-2.293-4.269-3.731-6.24-5.76
			c0.099-0.578-0.438-0.521-0.24-1.2c-0.914-1.965-2.868-2.891-4.319-4.32c3.886,6.194,9.053,13.188,13.68,19.44
			C304.32,152.84,304.32,153.08,304.32,153.32z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M357.12,453.561c-0.035,0.217-0.056,0.423-0.066,0.623
			c0,0.004,0,0.007,0,0.01c-0.01,0.195-0.008,0.382,0.003,0.563c0,0,0,0.001,0,0.002c0,0.003,0.001,0.006,0.001,0.01
			c0-0.003-0.001-0.005-0.001-0.008c-0.072,2.494,0.768,4.076,2.464,4.801c1.329,3.151,2.029,6.932,2.88,10.561
			c0.16,0,0.32,0,0.48,0c0.239,0,0.479,0,0.72,0c0-0.08,0-0.16,0-0.24c1.603,0.157,3.837-0.316,5.04,0.24
			c5.547-0.587,5.002,4.918,6.72,8.16c0.444,1.636,0.74,3.42,1.2,5.04c0.449,3.71,1.209,7.11,1.68,10.8
			c0.146,0.014,0.284,0.035,0.24,0.24c0,0.239,0,0.479,0,0.72c0.395,1.266,1.734-0.225,2.16-0.48
			c0.961-19.842-6.803-30.957-12.48-44.16c-3.753,1.018-9.104-1.023-12,0.721c-0.035,0.596,0.124,0.996,0.48,1.199
			C356.604,452.958,357.018,453.104,357.12,453.561z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M243.6,601.881c0,0.08,0,0.16,0,0.24
			c1.053,0.787,2.091,1.589,3.36,2.16c2.057,0.983,4.653,1.427,6.48,2.64c0.182,0.396,1.978,0.396,2.16,0
			c-0.084-0.075-0.161-0.159-0.242-0.238c0-0.001,0.001-0.001,0.001-0.002c-0.004-0.002-0.007-0.005-0.011-0.008
			c-1.327-1.307-2.22-3.047-4.069-3.832c-0.16,0-0.32,0-0.48,0c1.687,0.722,2.518,2.301,3.863,3.364
			c-1.345-1.063-2.176-2.642-3.863-3.364c-0.08,0-0.16,0-0.24,0c-0.904-0.775-2.36-1-3.36-1.68c-0.597-0.443-1.354-0.726-1.68-1.44
			c-0.502-0.697-1.447-0.952-2.16-1.439c-5.229-3.651-9.781-7.979-14.4-12.24c-0.217-0.423-0.537-0.743-0.96-0.96
			c-0.49-0.07-0.471-0.649-0.96-0.72c-0.315-0.288-0.377-0.094-0.72,0c0.156,2.483,1.839,3.44,3.12,4.8c0,0.08,0,0.16,0,0.24
			c3.832,4.968,9.013,8.587,13.92,12.479C243.44,601.881,243.521,601.881,243.6,601.881z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M286.081,364.521c0.064-1.193-2.296-3.401-2.64-1.2
			c-5.764,18.796-11.044,38.076-16.32,57.36c-1.037,0.372-0.049,1.359,0.72,0.72c0.08,0,0.16,0,0.24,0
			c-0.044,0.204,0.093,0.227,0.24,0.24c7.114-17.846,12.422-37.498,18-56.88c-0.08,0-0.16,0-0.24,0
			C286.081,364.681,286.081,364.601,286.081,364.521z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M227.521,580.761c0-0.16,0-0.319,0-0.479
			c0.783-1.113,0.157-2.871-1.68-2.16c-2.265-5.976-4.063-12.417-8.16-16.561c0.134,0.596-0.105,0.843-0.46,0.924
			c0.002,0,0.003,0.001,0.005,0.002c-0.002-0.001-0.004-0.001-0.006-0.002c0,0,0.001,0,0.001,0c-0.002,0-0.003,0-0.005-0.001
			c-0.151-0.037-0.306-0.062-0.463-0.071c-0.012-0.001-0.025,0-0.038-0.001c-0.155-0.008-0.312-0.005-0.472,0.016h0
			c-0.158,0.021-0.317,0.044-0.48,0.095c0.164-0.05,0.322-0.074,0.48-0.095c-0.181-0.045-0.351-0.097-0.48-0.146
			c-3.351-1.609-6.279-3.642-9.6-5.28c-1.606-0.794-3.179-1.621-4.8-2.4c-0.204-0.355-0.604-0.516-1.2-0.479
			c-0.036-0.444-0.813-0.147-0.96-0.48c-0.24,0-0.48,0-0.72,0c-1.028,3.396,2.712,3.415,4.32,4.801
			c2.21,1.904,3.939,4.898,6.24,7.199c4.084,4.085,8.691,7.94,12.96,12c0.204-0.044,0.226,0.094,0.24,0.24
			c0.325,0.076,0.458,0.342,0.96,0.24c1.29,0.55,2.444,1.236,3.6,1.92C226.935,580.387,227.175,580.627,227.521,580.761z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M193.2,440.36c0.155,0.955-0.635,0.965-0.48,1.92
			c0.08,0,0.16,0,0.24,0c-0.151,0.729,0.307,2.067-0.24,2.4c-0.08,0-0.16,0-0.24,0c-0.01,0.803-0.919,1.945-0.24,2.64
			c1.441,3.6,3.096,6.984,4.32,10.801c0,0.16,0,0.319,0,0.479c0,1.76,0,3.521,0,5.28c2.486,5.674,4.597,11.724,6.96,17.52
			c1.002-11.871,4.215-23.555,1.92-36c-3.796-1.724-7.802-3.237-11.76-4.8C193.627,440.414,193.479,440.321,193.2,440.36z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M234,614.361c0.699,0.9,1.5,1.7,2.4,2.399
			C235.701,615.86,234.901,615.061,234,614.361z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M256.8,626.361c0.131,0.349-0.28,1.24,0.24,1.2
			c0.514-1.809,3.536-2.381,2.88-4.561C259.851,624.877,257.642,626.816,256.8,626.361z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M381.841,707.961c-0.321,1.679-2.935,1.066-3.12,2.881
			c-0.192,1.231,0.257,1.822,0.479,2.64c0.901,0.699,1.701,1.499,2.4,2.399c0.439,0.161,0.777,0.33,1.027,0.506
			c-0.818-0.783-1.802-1.482-2.514-2.327c0.714,0.846,1.697,1.546,2.517,2.329c-0.001,0-0.002-0.001-0.003-0.002
			c0.16,0.152,0.313,0.309,0.456,0.47c0.972,1.651-3.933,3.772-4.844,5.745c-1.392,0.287-2.817,0.542-4.08,0.96
			c-0.43-0.03-0.506,0.294-0.96,0.24c-0.305,0.814-1.331,0.908-1.439,1.92c0,0.079,0,0.16,0,0.239c-0.08,0-0.16,0-0.24,0
			c0.08,0.96-0.428,1.333-0.24,2.4c1.961,4.979,2.152,14.269,0.48,19.44c0.08,0,0.16,0,0.24,0c0.41,0.549,1.832,0.088,2.64,0.239
			c1.76,0,3.52,0,5.28,0c0-0.399,0-0.8,0-1.199c0.959-5.121,2.183-9.978,2.88-15.36c0.556-0.965,0.083-2.957,0.24-4.32
			c0.171-1.211-0.328-1.752-0.48-2.64c-1.396-0.116-1.403,1.156-3.12,0.72c-1.314-4.021,4.463-4.711,5.76-7.68
			c-1.667-1.772-3.104-3.776-3.119-7.2C381.93,709.632,382.388,708.294,381.841,707.961z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M346.561,447.561c0-0.08,0-0.16,0-0.24
			c-0.028-0.691,0.131-1.57-0.24-1.92c-0.233-0.006-0.186-0.293-0.24-0.479c-1.441-0.566-2.17,1.157-1.68,2.64
			c0.92,2.121,1.162,4.918,1.68,7.44c0.295,1.466,0.26,3.261,0.24,5.04c-0.08,0-0.16,0-0.24,0c0,0.08,0,0.16,0,0.24
			c0.009,0.871-0.134,1.895,0.48,2.159c0.16-1.76,0.336-3.504,0.24-5.52c0-0.24,0-0.48,0-0.72c0-1.681,0-3.36,0-5.04
			c0-0.32,0-0.641,0-0.96C346.648,449.393,347.109,447.972,346.561,447.561z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M366.961,512.841c0.433,3.647,0.165,7.994,0.96,11.28
			c0,0.08,0,0.16,0,0.24c0.012,1.268,0.106,2.452,0.479,3.359c0,0.08,0,0.16,0,0.24c-0.088,1.527,0.427,2.453,0.24,4.08
			c0.157,1.603-0.316,3.837,0.24,5.04c0.258,1.163,1.185,2.732,2.399,2.16c0-0.32,0-0.641,0-0.96c0-0.4,0-0.801,0-1.2
			c0.08,0,0.16,0,0.24,0c0-0.88,0-1.76,0-2.64c0-0.32,0-0.641,0-0.961c0.556-0.964,0.083-2.956,0.24-4.319c0-0.24,0-0.48,0-0.72
			c0.551-0.49,0.087-1.993,0.24-2.881c0.796-8.403,2.248-16.15,3.119-24.479c0.554-0.647,0.086-2.315,0.24-3.36
			c-0.22-4.9-1.063-9.176-1.92-13.439c-0.085-1.115-0.308-2.093-0.72-2.881c-0.57-3.029-1.601-5.6-2.641-8.159
			c-0.64,0-1.279,0-1.92,0c-0.805,0.554-2.636,0.084-3.84,0.239c-0.24,0-0.479,0-0.72,0c-0.887,0.153-2.391-0.311-2.88,0.24
			c-0.874-0.074-1.563,0.038-2.16,0.24c-0.779-0.14-1.116,0.163-1.68,0.24c-0.254,0.249-0.496,1.713,0,1.92c0,0.08,0,0.16,0,0.239
			c0.479,2.802,0.999,5.561,2.16,7.681c0.08,0,0.159,0,0.239,0c0.88,0,1.761,0,2.641,0c0.729,0.15,2.067-0.308,2.399,0.24
			c3.248,0.127,4.639-1.603,7.681-1.681c1.278,0.365,0.526,2.256-0.48,2.4c-1.95,0.529-3.345,1.615-5.76,1.68
			c-1.261-0.14-1.817,0.423-3.12,0.24c0.041,0.359-0.086,0.886,0.24,0.96c0,0.08,0,0.16,0,0.24c0.492,3.827,1.199,7.44,2.399,10.56
			c0,0.08,0,0.16,0,0.24c0.399,3.921,1.269,7.372,1.44,11.52C366.871,511.17,366.413,512.508,366.961,512.841z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M247.44,625.161c-0.078,1.255,1.713,0.559,2.16,0.24
			C248.726,625.476,248.039,625.363,247.44,625.161z"/>
				<path fillRule="evenodd" clipRule="evenodd" fill="#E8E735" d="M337.44,452.36c-0.013-3.268-0.439-6.12-1.439-8.399
			c-0.288-0.992-0.921-1.641-1.681-2.16c-0.24,0-0.479,0-0.72,0c-1.194,2.033-0.277,5.36,1.2,6.24
			c0.856,2.344,1.607,4.792,1.439,8.16c0.157,1.442-0.315,3.516,0.24,4.56c0.044,1.236-0.276,2.837,0.96,2.88
			c0.158-2.002-0.317-4.638,0.24-6.24c0-0.159,0-0.319,0-0.479C337.523,455.478,337.996,453.404,337.44,452.36z"/>
			</g>
		</g>
		<g id="lip1">
			<path fillRule="evenodd" clipRule="evenodd" d="M303.841,282.44c-1.761,0-3.521,0-5.28,0c-0.861,0.499-2.596,0.125-3.84,0.24
		c-1.683,0.558-4.398,0.081-6.48,0.24c-0.08,0-0.16,0-0.24,0c-2.877,0.243-6.063,0.177-8.88,0.48
		c-4.262,0.122-9.076,0.979-13.68,1.44c-4.213,0.421-9.359,0.994-9.84,4.8c1.899,1.606,4.19-0.415,6.48-0.96
		c4.587-1.094,10.43-0.834,15.36-1.68c0.726-0.554,2.476-0.084,3.6-0.24c1.325,0.125,2.262-0.137,3.36-0.24
		c1.865,0.185,2.992-0.368,4.8-0.24c0.885-0.555,2.797-0.083,4.08-0.24c0.516-0.445,1.758-0.162,2.641-0.24
		c1.282-0.557,3.598-0.082,5.279-0.24c4.615-1.357,7.617,3.35,11.521,1.92c0-0.4,0-0.8,0-1.2c-0.644-0.396-1.063-1.018-1.681-1.44
		C308.725,283.956,306.579,282.902,303.841,282.44z"/>
		</g>
		<g id="eyebrow1">
			<path fillRule="evenodd" clipRule="evenodd" fill="#7D8083" d="M256.32,203c1.536,0.605,5.396,0.342,7.2,0.24
		c3.668-0.208,7.599-0.537,7.44-4.32c-1.443-0.637-3.399-0.761-5.52-0.72c-0.729-0.15-2.067,0.307-2.4-0.24
		c-2.516-0.205-4.838-0.602-7.68-0.48c-2,0-4,0-6,0c-1.557,0.043-2.906,0.294-3.84,0.96c2.762,0.32,4.658-0.332,6.96,0.72
		C254.147,199.921,254.9,202.44,256.32,203z"/>
			<path fillRule="evenodd" clipRule="evenodd" fill="#7D8083" d="M312.48,197.96c-1.682,0.558-4.398,0.082-6.48,0.24
		c-1.228-0.188-1.743,0.337-2.88,0.24c-0.219,1.499,0.592,1.968,0.721,3.12c6.162,1.906,10.114-2.294,14.88-3.36
		C316.636,198.125,314.777,197.823,312.48,197.96z"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M234.24,203.72c2.898-0.914,5.739-2.26,8.88-2.64c1.125-0.399,3.915-0.399,5.04,0
		c4.333-1.065,4.021,2.597,6.48,3.84c4.245,2.146,15.824,0.346,17.52-2.16c5.966-8.81-11.868-6.692-17.04-7.68
		c-0.96,0-1.92,0-2.88,0c-8.923,0.011-15.035,3.915-21.12,6.96c-2.021,1.012-5.138,1.086-5.28,3.84
		C228.611,206.425,231.389,204.619,234.24,203.72z M249.36,197.48c2,0,4,0,6,0c2.842-0.122,5.165,0.275,7.68,0.48
		c0.333,0.547,1.67,0.089,2.4,0.24c2.121-0.041,4.077,0.083,5.52,0.72c0.159,3.783-3.771,4.111-7.44,4.32
		c-1.805,0.103-5.665,0.365-7.2-0.24c-1.42-0.56-2.173-3.079-3.84-3.84c-2.302-1.051-4.198-0.399-6.96-0.72
		C246.455,197.775,247.804,197.523,249.36,197.48z"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M302.641,203.48c2.466,1.563,9.237,0.703,12.72-0.96
		c2.092-0.999,2.793-2.351,6-1.92c1.32,0.177,3.337,0.768,4.8,1.44c3.398,1.562,6.424,6.813,7.92,3.36
		c0.175-0.655-0.477-0.483-0.479-0.96c-4.492-5.188-12.146-7.214-20.16-8.88c-0.8,0-1.6,0-2.4,0
		c-4.22,1.169-11.801-0.468-10.56,5.28C300.652,201.635,302.468,203.371,302.641,203.48z M303.12,198.44
		c1.137,0.097,1.652-0.429,2.88-0.24c2.082-0.158,4.799,0.318,6.48-0.24c2.297-0.137,4.155,0.165,6.24,0.24
		c-4.766,1.065-8.718,5.266-14.88,3.36C303.712,200.409,302.901,199.939,303.12,198.44z"/>
		</g>
		<g id="lip2" display="none">
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M304.37,283.36c-1.761,0-4.134-1.652-5.894-1.652
		c-0.861,0.499-2.596,0.125-3.84,0.24c-1.683,0.558-4.398,0.081-6.48,0.24c-0.08,0-0.16,0-0.24,0
		c-2.877,0.243-6.063,0.177-8.88,0.48c-4.262,0.122-6.685,3.417-11.289,3.877c-4.213,0.421-8.787,2.563-9.268,6.369
		c1.899,1.606,4.19-0.415,6.48-0.96c4.587-1.094,7.55-4.107,12.48-4.953c0.726-0.554,2.392-0.817,3.516-0.973
		c1.325,0.125,2.262-0.137,3.36-0.24c1.865,0.185,2.992-0.368,4.8-0.24c0.885-0.555,2.797-0.083,4.08-0.24
		c0.516-0.445,1.758-0.162,2.641-0.24c1.282-0.557,3.598,0.918,5.279,0.76c4.615-1.357,8.73,6.269,12.634,4.839
		c0-0.4,0-1.333-0.5-2.467c-0.644-0.396-1.063-1.018-1.681-1.44C309.254,285.876,307.108,283.822,304.37,283.36z"/>
		</g>
		<g id="eyebrow2" display="none">
			<path display="inline" fillRule="evenodd" clipRule="evenodd" fill="#7D8083" d="M263.05,205.527
		c1.536,0.605,5.396,0.342,7.2,0.24c3.668-0.208,7.599-0.537,7.44-4.32c-1.443-0.637-3.399-0.761-5.52-0.72
		c-0.729-0.15-2.067,0.307-2.4-0.24c-2.516-0.205-4.838-0.602-7.68-0.48c-2,0-4,0-6,0c-1.557,0.043-2.906,0.294-3.84,0.96
		c2.762,0.32,4.658-0.332,6.96,0.72C260.877,202.448,261.63,204.967,263.05,205.527z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" fill="#7D8083" d="M309.653,202.375
		c-1.682,0.558-4.398,0.082-6.48,0.24c-1.228-0.188-1.743,0.337-2.88,0.24c-0.219,1.499,0.592,1.968,0.721,3.12
		c6.162,1.906,10.114-2.294,14.88-3.36C313.809,202.54,311.95,202.238,309.653,202.375z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M240.97,206.247c2.898-0.914,5.739-2.26,8.88-2.64
		c1.125-0.399,3.915-0.399,5.04,0c4.333-1.065,4.021,2.597,6.48,3.84c4.245,2.146,15.824,0.346,17.52-2.16
		c5.966-8.81-11.868-6.692-17.04-7.68c-0.96,0-1.92,0-2.88,0c-8.923,0.011-15.035,3.915-21.12,6.96
		c-2.021,1.012-5.138,1.086-5.28,3.84C235.341,208.951,238.119,207.145,240.97,206.247z M256.09,200.007c2,0,4,0,6,0
		c2.842-0.122,5.165,0.275,7.68,0.48c0.333,0.547,1.67,0.089,2.4,0.24c2.121-0.041,4.077,0.083,5.52,0.72
		c0.159,3.783-3.771,4.111-7.44,4.32c-1.805,0.103-5.665,0.365-7.2-0.24c-1.42-0.56-2.173-3.079-3.84-3.84
		c-2.302-1.051-4.198-0.399-6.96-0.72C253.185,200.301,254.534,200.05,256.09,200.007z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M299.813,207.895c2.466,1.563,9.237,0.703,12.72-0.96
		c2.092-0.999,2.793-2.351,6-1.92c1.32,0.177,3.337,0.768,4.8,1.44c3.398,1.562,6.424,6.813,7.92,3.36
		c0.175-0.655-0.477-0.483-0.479-0.96c-4.492-5.188-12.146-7.214-20.16-8.88c-0.8,0-1.6,0-2.4,0
		c-4.22,1.169-11.801-0.468-10.56,5.28C297.825,206.049,299.641,207.785,299.813,207.895z M300.293,202.855
		c1.137,0.097,1.652-0.429,2.88-0.24c2.082-0.158,4.799,0.318,6.48-0.24c2.297-0.137,4.155,0.165,6.24,0.24
		c-4.766,1.065-8.718,5.266-14.88,3.36C300.885,204.823,300.074,204.354,300.293,202.855z"/>
		</g>
		<g id="lip3" display="none">
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M303.841,283.44c-1.761,0-3.521,0-5.28,0
		c-0.861,0.499-2.596,0.125-3.84,0.24c-1.683,0.558-4.398,0.081-6.48,0.24c-0.08,0-0.16,0-0.24,0
		c-2.877,0.243-6.063,0.177-8.88,0.48c-4.262,0.122-10.876,0.075-15.48,0.536c-4.213,0.421-7.679-4.069-8.16-0.263
		c1.899,1.606,4.164,2.62,6.48,3.04c5.739,1.041,10.55,1.133,15.48,0.287c0.726-0.554,2.476-0.084,3.6-0.24
		c1.325,0.125,2.262-0.137,3.36-0.24c1.865,0.185,2.992-0.368,4.8-0.24c0.885-0.555,2.797-0.083,4.08-0.24
		c0.516-0.445,1.758-0.162,2.641-0.24c1.282-0.557,3.598-0.082,5.279-0.24c4.615-1.357,7.479,0.132,11.382-1.298c0-0.4,0-0.8,0-1.2
		c-0.644-0.396-1.063-1.018-1.681-1.44C308.586,281.738,306.579,283.902,303.841,283.44z"/>
		</g>
		<g id="eyebrow3" display="none">
			<path display="inline" fillRule="evenodd" clipRule="evenodd" fill="#7D8083" d="M256.32,196c1.536,0.605,5.396,0.342,7.2,0.24
		c3.668-0.208,7.599-0.537,7.44-4.32c-1.443-0.637-3.399-0.761-5.52-0.72c-0.729-0.15-2.067,0.307-2.4-0.24
		c-2.516-0.205-4.838-0.602-7.68-0.48c-2,0-4,0-6,0c-1.557,0.043-2.906,0.294-3.84,0.96c2.762,0.32,4.658-0.332,6.96,0.72
		C254.147,192.921,254.9,195.44,256.32,196z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" fill="#7D8083" d="M312.48,190.96
		c-1.682,0.558-4.398,0.082-6.48,0.24c-1.228-0.188-1.743,0.337-2.88,0.24c-0.219,1.499,0.592,1.968,0.721,3.12
		c6.162,1.906,10.114-2.294,14.88-3.36C316.636,191.125,314.777,190.823,312.48,190.96z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M234.24,196.72c2.898-0.914,5.739-2.26,8.88-2.64
		c1.125-0.399,3.915-0.399,5.04,0c4.333-1.065,4.021,2.597,6.48,3.84c4.245,2.146,15.824,0.346,17.52-2.16
		c5.966-8.81-11.868-6.692-17.04-7.68c-0.96,0-1.92,0-2.88,0c-8.923,0.011-15.035,3.915-21.12,6.96
		c-2.021,1.012-5.138,1.086-5.28,3.84C228.611,199.425,231.389,197.619,234.24,196.72z M249.36,190.48c2,0,4,0,6,0
		c2.842-0.122,5.165,0.275,7.68,0.48c0.333,0.547,1.67,0.089,2.4,0.24c2.121-0.041,4.077,0.083,5.52,0.72
		c0.159,3.783-3.771,4.111-7.44,4.32c-1.805,0.103-5.665,0.365-7.2-0.24c-1.42-0.56-2.173-3.079-3.84-3.84
		c-2.302-1.051-4.198-0.399-6.96-0.72C246.455,190.775,247.804,190.523,249.36,190.48z"/>
			<path display="inline" fillRule="evenodd" clipRule="evenodd" d="M302.641,196.48c2.466,1.563,9.237,0.703,12.72-0.96
		c2.092-0.999,2.793-2.351,6-1.92c1.32,0.177,3.337,0.768,4.8,1.44c3.398,1.562,6.424,6.813,7.92,3.36
		c0.175-0.655-0.477-0.483-0.479-0.96c-4.492-5.188-12.146-7.214-20.16-8.88c-0.8,0-1.6,0-2.4,0
		c-4.22,1.169-11.801-0.468-10.56,5.28C300.652,194.635,302.468,196.371,302.641,196.48z M303.12,191.44
		c1.137,0.097,1.652-0.429,2.88-0.24c2.082-0.158,4.799,0.318,6.48-0.24c2.297-0.137,4.155,0.165,6.24,0.24
		c-4.766,1.065-8.718,5.266-14.88,3.36C303.712,193.409,302.901,192.939,303.12,191.44z"/>
		</g>
		<g id="glasses">
			<g>
				<path fillRule="evenodd" clipRule="evenodd" d="M255.699,202.632c0.4,0,0.8,0,1.2,0c0,0.08,0,0.16,0,0.24
			c2.992,0.092,5.902-0.125,8.64,0c5.143,0.233,8.793,0.307,10.08,4.32c0.217,0.677,0.062,1.573,0.24,2.4
			c0.529,2.459,1.708,4.183,1.2,7.68c1.131-0.989,2.208-2.67,4.08-3.84c1.998-1.249,5.441-2.833,7.92-2.88
			c2.677-0.05,4.787,1.487,7.44,1.92c1.108,1.132,1.984,2.496,3.359,3.36c1.18-0.972,1.057-2.613,1.681-3.84
			c0.51-1.003,1.579-2.008,2.399-3.12c0.846-1.147,1.338-2.493,2.16-3.12c1.473-1.124,3.737-1.142,6.24-1.44
			c2.256-0.27,4.626-0.792,6.72-0.72c6.687,0.229,14.749,2.775,20.16,3.12c0.116,1.084,0.815,1.584,0.96,2.64
			c0.268-0.482,1.856-0.275,2.16,0c-0.408,1.432-0.161,3.519-0.479,5.04c0.076,0.636-1.345-0.225-0.961,0.72
			c0.359,3.807,1.021,7.673,1.681,11.76c0,0.32,0,0.64,0,0.96c-0.08,0-0.16,0-0.24,0c-0.663,1.739,0.266,3.8,0,5.52
			c-0.191,1.243-2.071,4.384-2.88,5.76c-0.508,0.863-1.152,1.808-1.68,2.64c-2.137,3.369-5.952,6.727-9.841,7.92
			c-2.56,0-5.119,0-7.68,0c-2.563-1.02-5.832-3.116-7.92-5.04c-2.465-2.271-3.92-5.413-6.48-7.2c-0.582-1.018-1.182-2.018-1.68-3.12
			c-0.51,0.285-2.45,0.759-3.36-0.24c-0.751-2.929-1.563-5.797-2.159-8.88c-1.043-0.499-2.007-0.307-2.641-1.68
			c-0.433-0.937,0.087-2.268-0.239-3.36c-0.396-1.321-1.751-1.586-1.681-2.64c-2.942-2.514-9.269-2-11.52,0.48
			c-0.959,1.841-1.116,4.484-2.88,5.52c-1.461,0.181-2.176-0.383-3.6-0.24c-0.378,1.099,0.997,0.444,0.96,1.2
			c1.038,1.338-1.448,8.953-3.36,9.12c-1.146,0.1-1.432-0.578-2.16-1.68c-3.776,5.184-8.374,9.545-13.2,13.68
			c-2.812,0.564-4.102,2.711-6.72,3.12c-1.204,0.188-3.768,0.103-5.76,0c-10.967-0.568-20.99-7.912-24.48-17.76
			c-0.488-1.377-1.293-3.054-1.44-4.32c-0.371-3.187,1.438-6.114,1.68-9.36c-0.438-0.024-1.884-0.092-2.64-0.48
			c-0.829,0.025-0.421-1.909-1.2-1.2c-7.497,0.503-14.228,1.772-21.84,2.16c-1.045-1.835-2.328-3.433-3.12-5.52
			c8.543-2.657,20.155-2.245,29.76-3.84c0.958-0.961,1.608-2.232,2.64-3.12C233.126,202.387,244.818,203.052,255.699,202.632z
			 M256.659,205.272c-6.886-0.462-11.704,0.682-16.8,1.68c-3.104,0.608-6.906,1.097-8.4,2.16c-0.988,0.703-2.483,3.467-3.36,4.8
			c-0.03,0.045-0.25,0.262-0.24,0.24c-2.162,4.899-1.341,10.524-0.48,15.12c0.637,3.401,1.133,5.485,2.88,7.44
			c0.963,1.078,2.768,2.555,4.32,3.84c1.704,1.411,3.347,3.164,4.56,3.6c2.691,0.967,7.569,0.979,10.08,0.48
			c0.684-0.136,2.149-0.932,3.12-1.44c2.474-1.295,3.751-2.441,5.28-4.08c0.781-0.837,1.371-1.348,2.16-1.92
			c-0.16,0.116-0.485,0.628-0.24,0.48c0.809-0.489,2.755-2.537,3.36-3.36c0.406-0.552,0.789-1.768,1.44-2.88
			c3.107-5.307,6.134-11.215,5.28-20.16c-0.581-0.779-0.829-1.892-1.2-2.88C265.539,206.156,261.188,205.576,256.659,205.272z
			 M323.859,207.432c-1.959,0.168-4.141,0.032-6.001,0.24c-2.896,0.324-5.353,1.814-7.439,2.64c-2.787,2.977-5.986,7.072-5.28,12.96
			c0.159,1.328,1.227,2.462,0.48,4.08c1.413,3.918,4.54,8.896,7.439,12c1.146,1.227,2.15,2.894,3.12,3.6
			c1.227,0.892,4.147,1.838,6,2.16c1.434,0.249,3.825,0.343,5.04,0c2.993-0.846,4.267-3.878,7.2-5.28
			c1.491-2.483,3.316-5.264,4.08-8.4c0.753-3.091,0.429-4.97,0-7.92c-0.69-4.742-1.27-9.043-1.92-13.2
			C333.864,207.972,328.289,207.052,323.859,207.432z"/>
			</g>
		</g>
	</svg>
